import * as Yup from 'yup';

export interface Numeral {
	numeroRg: string;
	digitoRg: string;
	RGempty: boolean;
}

export const initialValues: Numeral = {
	numeroRg: '',
	digitoRg: '',
	RGempty: false,
};

export const schema = Yup.object<Numeral>().shape({
	//	numeroRg: Yup.string().required(`Campo Obrigatório: RG.`),
	validacaoRG: Yup.string().when(['numeroRg'], {
		is: numeroRg => numeroRg.length !== 8,
		then: Yup.string().required(`Campo Obrigatório: RG.`),
	}),
});
