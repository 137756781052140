import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	LISTA_PAISES = '@cnh/LISTA_PAISES',
	LISTA_PAISES_SUCCESS = '@cnh/LISTA_PAISES_SUCCESS',
	LISTA_PAISES_FAILURE = '@cnh/LISTA_PAISES_FAILURE',
}

export interface listaPaises {
	status: number;
	data: null | OptionProps[];
}
