import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, IDadosAluno } from './types';

export const INITIAL_STATE: IDadosAluno = {
	data: {
		aluno: {
			digitoRA: '',
			numeroRA: '',
			siglaUFRA: '',
		},
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IDadosAluno {
	return produce(state, draft => {
		switch (action.type) {
			case Types.MANTEM_DADOS_ALUNO_REQUEST: {
				break;
			}
			case Types.MANTEM_DADOS_ALUNO_SUCCESS: {
				draft.data = action.payload;
				break;
			}
			case Types.MANTEM_DADOS_ALUNO_FAILURE: {
				draft.data = action.payload;
				break;
			}
			default:
		}
		return draft;
	});
}
