import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RelatoriosState } from './types';

import queimaDare from './queimaDare/reducer';
import estatistica from './estatisticas/reducer';
import relacaoUsuario from './relacaoUsuario/reducer';
import relatorioMensalDare from './relatorioMensalDare/reducer';

const allReducers = combineReducers({
	queimaDare,
	estatistica,
	relacaoUsuario,
	relatorioMensalDare,
});

const combineReducer = (state: RelatoriosState, action: ReducerAction) => {
	if (action.type === Types.RELATORIOS_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
