import produce from 'immer';

// TYPES
import { Types, ConsultaAluno } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultaAluno = {
	status: 0,
	data: null,
	request: null,
};

export default function consultarAluno(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaAluno {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_ALUNO_REQUEST: {
				draft.request = action.payload;
				break;
			}
			case Types.CONSULTA_ALUNO_SUCCESS: {
				const alunos = { ...action.payload.data };
				draft.data = alunos;
				draft.status = action.payload.status;
				break;
			}
			case Types.CONSULTA_ALUNO_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CONSULTA_ALUNO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.request = INITIAL_STATE.request;
				break;
			}
			default:
		}
	});
}
