import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarUsuarioMainframeRequest } from './types';

// ACTIONS
import {
	cadastrarUsuarioMainframeSuccess,
	cadastrarUsuarioMainframeFailure,
} from './actions';

function* cadastrarUsuarioMainframe(request: ReducerAction) {
	const { payload }: { payload: CadastrarUsuarioMainframeRequest } = request;
	const { id, ...rest } = payload;

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU,
		`usuarios/${id}`,
		rest,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarUsuarioMainframeSuccess(response));
	} else {
		yield put(cadastrarUsuarioMainframeFailure());
	}
}

export default all([
	takeLatest(
		Types.CADASTRAR_USUARIO_MAINFRAME_REQUEST,
		cadastrarUsuarioMainframe,
	),
]);
