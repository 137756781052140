import React, { useState, useEffect } from 'react';

import { Radio as RadioAntDesign } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import { CSSProperties } from 'styled-components';
import { Container, Title, SubTitle, Content } from './styled';

interface RadioGroupProps {
	display?: 'vertical' | 'horizontal';
	wrap?: boolean;
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	titleQuestion?: boolean;
	subtitle?: string;
	name: string;
	value?: string;
	defaultValue?: string;
	options: RadioProps[];
	required?: boolean;
	disabled?: boolean;
	size?: number;
	error?: boolean;
	directionRow?: boolean;
	strong?: boolean;
	onChange: (e: RadioChangeEvent) => void;
	maxOptionsWidth?: number;
	customChange?: (e: RadioChangeEvent) => void;
	customWidth?: string;
	noTitleQuestion?: boolean;
	noTextTransform?: boolean;
	styleContainer?: CSSProperties;
}

export interface RadioProps {
	label: string;
	value: string | number;
}

const Radio: React.FC<RadioGroupProps> = ({
	display = 'horizontal',
	wrap = false,
	title,
	titleSize = 'md',
	titleQuestion = false,
	subtitle,
	name,
	defaultValue = '',
	options,
	required = false,
	disabled = false,
	size = 100,
	error,
	directionRow = false,
	strong = true,
	value,
	onChange,
	customChange = null,
	maxOptionsWidth = 0,
	customWidth = '',
	noTitleQuestion = false,
	noTextTransform = false,
	styleContainer,
	...rest
}) => {
	const [errors, setErrors] = useState<boolean>(!!error);

	const [radioStyle, setRadioStyle] = useState({});

	useEffect(() => {
		if (error) {
			setErrors(true);
		} else {
			setErrors(false);
		}
	}, [error]);

	useEffect(() => {
		if (display === 'vertical') {
			setRadioStyle({
				display: 'block',
				height: '30px',
				lineHeight: '30px',
			});
			return;
		}
		setRadioStyle({});
	}, [display]);

	useEffect(() => {
		if (maxOptionsWidth > 0) {
			setRadioStyle({
				maxWidth: `${maxOptionsWidth}px`,
			});
		}
	}, [maxOptionsWidth]);

	const handleChange = (e: RadioChangeEvent) => {
		onChange(e);
		if (customChange) {
			customChange(e);
		}
	};

	const getTitleQuestion = () => {
		if (noTitleQuestion) {
			return '';
		}
		if (titleQuestion) {
			return '?';
		}
		if (titleQuestion) {
			return ':';
		}
		return '';
	};

	return (
		<Container
			directionRow={directionRow}
			style={styleContainer && styleContainer}
		>
			{title && (
				<Title customWidth={customWidth} size={titleSize} required={required}>
					<label>{`${title}${getTitleQuestion()}`}</label>
				</Title>
			)}
			<Content
				size={size}
				display={display}
				flexWrap={wrap}
				strong={strong}
				isErrored={errors}
				noTextTransform={noTextTransform}
			>
				<RadioAntDesign.Group
					name={name}
					id={name}
					defaultValue={defaultValue}
					value={value}
					onChange={(e: RadioChangeEvent) => handleChange(e)}
					disabled={disabled}
				>
					{options.map(option => (
						<RadioAntDesign
							key={option.value}
							value={option.value}
							defaultChecked={option.value === defaultValue}
							disabled={disabled}
							style={radioStyle}
							onKeyDown={e => {
								e.key === 'Enter' && e.preventDefault();
							}}
							{...rest}
						>
							{option.label}
						</RadioAntDesign>
					))}
				</RadioAntDesign.Group>

				{subtitle && (
					<SubTitle>
						<span>{subtitle.toUpperCase()}</span>
					</SubTitle>
				)}
			</Content>
		</Container>
	);
};

export default Radio;
