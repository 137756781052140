import { takeLatest, put, all } from 'redux-saga/effects';

import { ReducerAction } from 'store/modules/types';
import { Types, IMantemDados } from './types';
import {
	mantemDadosConsultaSuccess,
	mantemDadosConsultaFailure,
} from './actions';

function* mantemDadosConsultaRequest(request: ReducerAction) {
	const { payload }: { payload: IMantemDados } = request;
	const response = {
		data: {
			dataNascimento: payload.dataNascimento,
			filtrosNomes: {
				nomeAluno: payload.filtrosNomes.nomeAluno,
				nomeMae: payload.filtrosNomes.nomeMae,
				nomePai: payload.filtrosNomes.nomePai,
			},
		},
	};

	if (payload.dataNascimento !== undefined) {
		yield put(mantemDadosConsultaSuccess(response));
	}
	if (payload === null) {
		yield put(mantemDadosConsultaFailure());
	}
}

export default all([
	takeLatest(Types.MANTEM_DADOS_CONSULTA_REQUEST, mantemDadosConsultaRequest),
]);
