import { ReducerAction } from 'store/modules/types';
import { ConsultarUnidadesRequest, Types } from './types';

export function consultarUnidadesRequest(
	payload: ConsultarUnidadesRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_UNIDADES_REQUEST,
		payload,
	};
}
export function consultarUnidadesSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_UNIDADES_SUCCESS,
		payload,
	};
}
export function consultarUnidadesFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTAR_UNIDADES_FAILURE,
		payload,
	};
}
export function consultarUnidadesClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_UNIDADES_CLEAR,
		payload: null,
	};
}
