// ROUTES
import { ROUTE_PREFEITURA } from 'routes/paths';

// HOME
export const ROUTE_PREFEITURA_RECUPERAR_ATENDIMENTO = `${ROUTE_PREFEITURA}/recuperar-atendimento`;
export const ROUTE_PREFEITURA_JUSTIFICATIVA_ATENDIMENTO = `${ROUTE_PREFEITURA}/recuperar-atendimento/justificativa`;

// JALES
export const ROUTE_PREFEITURA_JALES_TRANSPORTE = `${ROUTE_PREFEITURA}/jales/transporte`;

export const ROUTE_PREFEITURA_LISTA_INFRACOES = `${ROUTE_PREFEITURA}/jales/lista/infracoes`;

export const ROUTE_PREFEITURA_CONSULTA_INFRACOES = `${ROUTE_PREFEITURA}/jales/consulta/infracoes`;
export const ROUTE_PREFEITURA_CONSULTA_INFRACOES_RECURSO = `${ROUTE_PREFEITURA}/jales/consulta/infracoes/recurso`;
export const ROUTE_PREFEITURA_CONSULTA_INFRACOES_TRANSFERENCIA = `${ROUTE_PREFEITURA}/jales/consulta/infracoes/transferencia`;
export const ROUTE_PREFEITURA_CONSULTA_INFRACOES_RESULTADO_RECURSO = `${ROUTE_PREFEITURA}/jales/consulta/infracoes/resultado/recurso`;
export const ROUTE_PREFEITURA_CONSULTA_INFRACOES_RESULTADO_TRANSFERENCIA = `${ROUTE_PREFEITURA}/jales/consulta/infracoes/resultado/transferencia`;
