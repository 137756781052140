export enum Types {
	RESPOSTA_NAO_RESOLVIDA_REQUEST = '@procon/RESPOSTA_NAO_RESOLVIDA_REQUEST',
	RESPOSTA_NAO_RESOLVIDA_SUCCESS = '@procon/RESPOSTA_NAO_RESOLVIDA_SUCCESS',
	RESPOSTA_NAO_RESOLVIDA_FAILURE = '@procon/RESPOSTA_NAO_RESOLVIDA_FAILURE',
	RESPOSTA_NAO_RESOLVIDA_CLEAR = '@procon/RESPOSTA_NAO_RESOLVIDA_CLEAR',
}

export interface RespostaNaoResolvida {
	status: null | number;
	data: null | RespostaNaoResolvidaData;
}

interface RespostaNaoResolvidaData {}

interface Evento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	idAtendimento?: string;
	idCidadao?: string;
	identificacao: string | number;
	canal?: {
		id: number;
		desc: string;
		estacao?: {
			id: string;
			desc: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface RespostaNaoResolvidaRequest {
	evento: Evento;
	atendimentoId: string;
	protocolo: string;
	textoDaJustificativa: string;
	anexos: Anexos[];
}

interface Anexos {
	nome: string;
	base64: string;
}

export interface RespostaNaoResolvidaForm {
	justificativa: string;
	anexos: Anexos[];
	anexosTipo?: string;
}
