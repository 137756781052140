import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { ApplicationState } from 'store';
import PDFViewer from 'components/Common/PDFViewer';

const RecursoResultado = () => {
	const [documenteVisible, setDocumenteVisible] = useState(false);

	const { protocolarRecurso } = useSelector(
		(state: ApplicationState) => state.api.prefeituras.jalesTransito,
	);

	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section title="Resultado do Pedido de Recurso">
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Input
									name="numero"
									title="Número"
									value={protocolarRecurso.data?.numero}
									readOnly
								/>
							</Col>
						</Row>
						<Row gutter={[0, 10]} justify="center">
							<Col>
								<ButtonImage
									src="imprimir"
									onClick={() => setDocumenteVisible(true)}
								/>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
			<ButtonVoltar />
			{documenteVisible && protocolarRecurso.data && (
				<PDFViewer
					title=""
					source={protocolarRecurso.data.documento}
					onClose={() => setDocumenteVisible(false)}
				/>
			)}
		</>
	);
};

export default RecursoResultado;
