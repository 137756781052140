import { all } from 'redux-saga/effects';

import atualizarMensagemErroSaga from './atualizarMensagemErro/saga';
import cadastrarMensagemErroSaga from './cadastrarMensagemErro/saga';
import consultarMensagensErroSaga from './consultarMensagensErro/saga';
import excluirMensagemErroSaga from './excluirMensagemErro/saga';

export default all([
	atualizarMensagemErroSaga,
	cadastrarMensagemErroSaga,
	consultarMensagensErroSaga,
	excluirMensagemErroSaga,
]);
