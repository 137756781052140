import { Types, IRequestWithHeader } from './types';

export function primeiroEmplacamentoEmissaoRequest(
	payload: IRequestWithHeader,
) {
	return {
		type: Types.PRIMEIRO_EMPLACAMENTO_EMISSAO_REQUEST,
		payload,
	};
}
export function primeiroEmplacamentoEmissaoSuccess(payload: any) {
	return {
		type: Types.PRIMEIRO_EMPLACAMENTO_EMISSAO_SUCCESS,
		payload,
	};
}
export function primeiroEmplacamentoEmissaoFailure(payload: []): any {
	return {
		type: Types.PRIMEIRO_EMPLACAMENTO_EMISSAO_FAILURE,
		payload,
	};
}
