import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarParametroSistemaRedisRequest } from './types';

export function atualizarParametroSistemaRedisRequest(
	data: AtualizarParametroSistemaRedisRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_PARAMETRO_SISTEMA_REDIS,
		payload: data,
	};
}
export function atualizarParametroSistemaRedisSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_PARAMETRO_SISTEMA_REDIS_SUCCESS,
		payload,
	};
}
export function atualizarParametroSistemaRedisFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_PARAMETRO_SISTEMA_REDIS_FAILURE,
		payload: null,
	};
}
export function atualizarParametroSistemaRedisClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_PARAMETRO_SISTEMA_REDIS_CLEAR,
		payload: null,
	};
}
