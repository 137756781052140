import { all } from 'redux-saga/effects';

import atualizarFuncionalidadeSaga from './atualizarFuncionalidade/saga';
import cadastrarFuncionalidadeSaga from './cadastrarFuncionalidade/saga';
import consultarFuncionalidadeSaga from './consultarFuncionalidade/saga';
import consultarUmaFuncionalidadeSaga from './consultarUmaFuncionalidade/saga';
import excluirFuncionalidadeSaga from './excluirFuncionalidade/saga';
import excluirFuncionalidadesRelacionadasLocalidadeSaga from './excluirFuncionalidadeRelacionadaLocalidade/saga';

export default all([
	atualizarFuncionalidadeSaga,
	cadastrarFuncionalidadeSaga,
	consultarFuncionalidadeSaga,
	consultarUmaFuncionalidadeSaga,
	excluirFuncionalidadeSaga,
	excluirFuncionalidadesRelacionadasLocalidadeSaga,
]);
