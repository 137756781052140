import React, { /* useState, */ useCallback } from 'react';

import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { v4 } from 'uuid';

import { Container, Title, Content, Box, SubTitle } from '../styled';

interface InputProps {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	titleQuestion?: boolean;
	subtitle?: string;
	name: string;
	defaultValue?: number;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	size?: number;
	error?: boolean;
	onChange: (values: NumberFormatValues) => void;
	maxLength?: number;
}

const InputCurrency: React.FC<InputProps> = ({
	title,
	titleSize = 'md',
	titleQuestion = false,
	subtitle,
	name,
	defaultValue,
	required = false,
	disabled = false,
	readOnly = false,
	size = 100,
	error,
	onChange,
	maxLength,
}) => {
	// const [isFocused, setIsFocused] = useState(false);

	/* const handleInputFocus = useCallback(() => {
		setIsFocused(true);
	}, []);

	const handleInputBlur = useCallback(() => {
		setIsFocused(false);
	}, []); */

	const handleValueChange = useCallback(
		(values: NumberFormatValues) => {
			onChange(values);
		},
		[onChange],
	);

	return (
		<Container>
			{title && (
				<Title size={titleSize} required={required}>
					<label htmlFor={name}>{`${title}${titleQuestion ? '?' : ':'}`}</label>
				</Title>
			)}

			<Content>
				<Box
					size={size}
					isErrored={!!error}
					isFocused={false}
					isDisabled={disabled}
					isReadOnly={readOnly}
				>
					<NumberFormat
						maxLength={maxLength}
						name={name}
						id={`${name}-${v4()}`}
						defaultValue={defaultValue}
						decimalSeparator=","
						thousandSeparator="."
						decimalScale={2}
						allowNegative={false}
						fixedDecimalScale
						onValueChange={handleValueChange}
					/>

					{subtitle && (
						<SubTitle>
							<span>{subtitle}</span>
						</SubTitle>
					)}
				</Box>
			</Content>
		</Container>
	);
};

export default InputCurrency;
