import { ReducerActionRotaSP } from 'store/modules/types';
import { Types } from './types';

export function comboPaisesRequest(token: string): ReducerActionRotaSP {
	return {
		type: Types.COMBO_PAISES_REQUEST,
		token,
		payload: null,
	};
}

export function comboPaisesSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.COMBO_PAISES_SUCCESS,
		payload,
	};
}

export function comboPaisesFailure(payload: object): ReducerActionRotaSP {
	return {
		type: Types.COMBO_PAISES_FAILURE,
		payload,
	};
}
