import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AtualizarContato } from './types';

export const INITIAL_STATE: AtualizarContato = {
	status: 0,
	form: {
		cpf: '',
		idTipoContato: '',
		valor: '',
		id: 0,
	},
};

export default function alteraEnderecoCartao(
	state = INITIAL_STATE,
	action: ReducerAction,
): AtualizarContato {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ATUALIZAR_CONTRATO_REQUEST: {
				draft.form = action.payload;
				break;
			}

			case Types.ATUALIZAR_CONTRATO_SUCCESS: {
				draft.status = 200;
				break;
			}

			case Types.ATUALIZAR_CONTRATO_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.LIMPAR_ATUALIZAR_CONTRATO: {
				draft.status = 0;
				break;
			}

			default:
		}
	});
}
