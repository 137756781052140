import * as Yup from 'yup';

import { letrasNumerosPontosHifen } from 'utils/stringFunctions';
import { completaZeroEsquerda } from 'utils/genericFunctions';

export interface IDataEndereco {
	cep: string;
	numero: string;
	bairro: string;
	logradouro: string;
	complemento: string;
	nomeMunicipio: string;
	codigoMunicipio: string;
	tipo: string;
}

export function treatValues(values: IDataEndereco): IDataEndereco {
	return {
		...values,
		logradouro: letrasNumerosPontosHifen(values.logradouro),
		cep: completaZeroEsquerda(values.cep, 8),
	};
}

export const schema = Yup.object<IDataEndereco>().shape({
	cep: Yup.string().required('Campo Obrigatório: CEP.'),
	numero: Yup.string().required('Campo Obrigatório: Número.'),
	bairro: Yup.string().required('Campo Obrigatório: Bairro.'),
	logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
	// codigoMunicipio: Yup.string().required('Campo Obrigatório: Municipio.'),
});
