import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export interface IPrefeiturasServicosValidacoes {
	codigoPefeitura: number;
	body?: any;
}

export function cadastraPrefeiturasServicosValidacoes(
	data: IPrefeiturasServicosValidacoes,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES,
		payload: data,
	};
}
export function cadastraPrefeiturasServicosValidacoesSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_SUCCESS,
		payload,
	};
}
export function cadastraPrefeiturasServicosValidacoesFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_FAILURE,
		payload,
	};
}

export function removePrefeiturasServicos(): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS,
		payload: null,
	};
}
