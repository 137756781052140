import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarAcompanhamento } from './types';

export const INITIAL_STATE: ConsultarAcompanhamento = {
	status: 0,
	data: null,
	mensagem: '',
	form: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarAcompanhamento {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_ACOMPANHAMENTO_REQUEST: {
				draft.form = action.payload;
				break;
			}

			case Types.CONSULTAR_ACOMPANHAMENTO_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_ACOMPANHAMENTO_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				draft.mensagem = action.payload.mensagem;
				break;
			}
			case Types.CONSULTAR_ACOMPANHAMENTO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.form = INITIAL_STATE.form;
				draft.mensagem = INITIAL_STATE.mensagem;
				break;
			}

			default:
		}
		return draft;
	});
}
