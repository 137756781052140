import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../types';
import { Types, DetranCnhState } from './types';

// RENOVACAO
import renovacao from './renovacao/combineReducers';

// SEGUNDA VIA
import segundaVia from './segundaVia/combineReducers';

// CNH DEFINITIVA
import cnhDefinitiva from './cnhDefinitiva/combineReducers';

// PRIMEIRA HABILITAÇÃO
import primeiraHabilitacao from './primeiraHabilitacao/combineReducers';

// PERMISSIONÁRIO PONTUADO
import permissionarioPontuado from './permissionarioPontuado/combineReducers';

// ADIÇÃO CATEGORIA
import adicaoCategoria from './adicaoCategoria/combineReducers';

// MUDANÇA CATEGORIA
import mudancaCategoria from './mudancaCategoria/combineReducers';

// PERMISSAO INTERNACIONAL AUTOMATIZADO - PID
import permissaoInternacional from './permissaoInternacional/combineReducers';

// PERMISSAO INTERNACIONAL - PID
import permissaoInternacionalAutomatizado from './permissaoInternacionalAutomatizado/combineReducers';

// REABILITAÇÃO
import reabilitacao from './reabilitacao/combineReducers';

// CONDUTOR
import condutor from './condutor/combineReducers';

// DIVISÃO EQUITATIVA
import divisaoEquitativa from './divisaoEquitativa/combineReducers';

// CNH DIGITAL
import cnhDigital from './cnhDigital/combineReducers';

// TRANSFERÊNCIA MUNICIPAL
import transferenciaMunicipal from './transferenciaMunicipal/combineReducers';

// ENUM
import enumServices from './enumServices/combineReducers';

// BIOMETRIA
import biometria from './biometria/combineReducers';

// DADOS DOMÍNIO
import dadosDominio from './dadosDominio/combineReducers';

// AGENDAMENTO MÉDICO E PSICOLÓGICO
import agendamentoMedicoPsicologico from './agendamentoMedicoPsicologico/combineReducers';

// IMPRIMIR
import imprimir from './imprimir/combineReducers';

// CONSULTA
import consulta from './consulta/combineReducers';

// SOCKETS
import sockets from './sockets/combineReducers';

// PORTAL DETRAN
import portalDetran from './portalDetran/combineReducers';

// EPORTAL
import eportal from './eportal/combineReducers';

// POSTO ATENDIMENTO
import postoAtendimento from './postoAtendimento/combineReducers';

// RELATÓRIO
import relatorio from './relatorio/combineReducers';

// IIRGDDetran
import IIRGDDetran from './IIRGDDetran/combineReducers';

// CIDADAO
import cidadao from './cidadao/combineReducers';

// EVENTOS SESSAO
import evento from './evento/combineReducers';

// RENACH INTEGRADO
import renachIntegrado from './renachIntegrado/combineReducers';

// UTILS
import utils from './utils/combineReducers';

// ALTERAÇÃO ENDEREÇO
import alteracaoEndereco from './alteracaoEndereco/combineReducers';

// CONSULTAR SOLICITAÇÃO EMISSÃO PID
import solicitacaoEmissaoPID from './solicitacaoEmissaoPID/combineReducers';

// NOME SOCIAL
import nomeSocial from './nomeSocial/combineReducers';

const allReducers = combineReducers({
	renovacao,
	segundaVia,
	cnhDefinitiva,
	primeiraHabilitacao,
	permissionarioPontuado,
	adicaoCategoria,
	mudancaCategoria,
	permissaoInternacional,
	permissaoInternacionalAutomatizado,
	reabilitacao,
	divisaoEquitativa,
	condutor,
	cnhDigital,
	transferenciaMunicipal,
	enumServices,
	biometria,
	dadosDominio,
	agendamentoMedicoPsicologico,
	imprimir,
	consulta,
	sockets,
	portalDetran,
	eportal,
	postoAtendimento,
	relatorio,
	IIRGDDetran,
	cidadao,
	evento,
	renachIntegrado,
	utils,
	alteracaoEndereco,
	solicitacaoEmissaoPID,
	nomeSocial,
});

const combineReducer = (state: DetranCnhState, action: ReducerAction) => {
	if (action.type === Types.DETRAN_CNH_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
