import { combineReducers } from 'redux';

import atualizarFuncionalidade from './atualizarFuncionalidade/reducer';
import cadastrarFuncionalidade from './cadastrarFuncionalidade/reducer';
import consultarFuncionalidade from './consultarFuncionalidade/reducer';
import consultarUmaFuncionalidade from './consultarUmaFuncionalidade/reducer';
import excluirFuncionalidade from './excluirFuncionalidade/reducer';
import excluirFuncionalidadesRelacionadasLocalidade from './excluirFuncionalidadeRelacionadaLocalidade/reducer';

export default combineReducers({
	atualizarFuncionalidade,
	cadastrarFuncionalidade,
	consultarFuncionalidade,
	consultarUmaFuncionalidade,
	excluirFuncionalidade,
	excluirFuncionalidadesRelacionadasLocalidade,
});
