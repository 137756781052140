// ROUTES
import { ROUTE_IIRGD } from 'routes/paths';
import CadastroRG from '../CadastroRg';
import ConferenciaDados from '../ConferenciaDados';
import KitFamilia from '../Dare/KitFamilia';
import PesquisaDARE from '../Dare/Pesquisa';
import Sucesso from '../Dare/Sucesso';
import InclusaoObservacoes from '../InclusaoObservacoes';
import LeituraDigitais from '../LeituraDigitais';
import PesquisaRg from '../PesquisaRg/Formulario';
import ResultadoRg from '../PesquisaRg/Resultado';
import PesquisaRgMae from '../PesquisaRg/RgMae';
import RestricoesCriminais from '../RestricoesCriminais';
import StatusCadastro from '../StatusCadastro';
import {
	ROUTE_IIRGD_DARE_KIT_FAMILIA,
	ROUTE_IIRGD_DARE_PESQUISA,
	ROUTE_IIRGD_DARE_SUCESSO,
	ROUTE_IIRGD_PESQUISAR_RG,
	ROUTE_IIRGD_PESQUISAR_RG_MAE,
	ROUTE_IIRGD_RESULTADO_PESQUISA,
	ROUTE_IIRGD_RESULTADO_RESTRICOES,
	ROUTE_IIRGD_CADASTRO,
	ROUTE_IIRGD_EDICAO,
	ROUTE_IIRGD_INCLUSAO_OBSERVACOES,
	ROUTE_IIRGD_CONFERENCIA_DADOS,
	ROUTE_IIRGD_LEITURA_DIGITAIS,
	ROUTE_IIRGD_STATUS,
} from './paths';

// DARE
// importa PesquisaDARE = import('../Dare/Pesquisa');
// const KitFamilia = import('../Dare/KitFamilia');
// const Sucesso = import('../Dare/Sucesso');

// Cadastro RG
// const PesquisaRg = import('../PesquisaRg/Formulario');
// const PesquisaRgMae = import('../PesquisaRg/RgMae');
// const ResultadoRg = import('../PesquisaRg/Resultado');
// const RestricoesCriminais = import('../RestricoesCriminais');
// const CadastroRG = import('../CadastroRg');
// const InclusaoObservacoes = import('../InclusaoObservacoes');
// const ConferenciaDados = import('../ConferenciaDados');
// const LeituraDigitais = import('../LeituraDigitais');
// const StatusCadastro = import('../StatusCadastro');

const iirgdRoute = {
	name: 'IIRGD',
	route: ROUTE_IIRGD,
};

export default [
	{
		path: ROUTE_IIRGD_DARE_PESQUISA,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'DARE',
			},
		],
		Component: PesquisaDARE,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_DARE_KIT_FAMILIA,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'DARE',
			},
			{
				name: 'Gerar Dare',
			},
		],
		Component: KitFamilia,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_DARE_SUCESSO,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'DARE',
			},
		],
		Component: Sucesso,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_PESQUISAR_RG,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Pesquisar RGE/CIN',
			},
		],
		Component: PesquisaRg,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_PESQUISAR_RG_MAE,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Pesquisar RGE/CIN - Mãe',
			},
		],
		Component: PesquisaRgMae,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_RESULTADO_PESQUISA,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Resultado da Pesquisa',
			},
		],
		Component: ResultadoRg,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_RESULTADO_RESTRICOES,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Resultado da Pesquisa de Restrições',
			},
		],
		Component: RestricoesCriminais,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_CADASTRO,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Cadastrar Carteira de Identidade',
			},
		],
		Component: CadastroRG,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_EDICAO,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Editar Cadastro',
			},
		],
		Component: CadastroRG,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_INCLUSAO_OBSERVACOES,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Inclusão de Observações',
			},
		],
		Component: InclusaoObservacoes,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_CONFERENCIA_DADOS,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Confirmação',
			},
		],
		Component: ConferenciaDados,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_LEITURA_DIGITAIS,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Leitura de Digitais',
			},
		],
		Component: LeituraDigitais,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_STATUS,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Status do Cadastro',
			},
		],
		Component: StatusCadastro,
		isAuthenticated: true,
		exact: true,
	},
];
