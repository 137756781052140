export enum Types {
	CONSULTAR_VEICULO_V110_REQUEST = '@DETRAN-CRV/CONSULTAR_VEICULO_V110_REQUEST',
	CONSULTAR_VEICULO_V110_SUCCESS = '@DETRAN-CRV/CONSULTAR_VEICULO_V110_SUCCESS',
	CONSULTAR_VEICULO_V110_FAILURE = '@DETRAN-CRV/CONSULTAR_VEICULO_V110_FAILURE',
	CONSULTAR_VEICULO_V110_CLEAR = '@DETRAN-CRV/CONSULTAR_VEICULO_V110_CLEAR',
}

export interface consultarVeiculoV110 {
	status: number;
	data: ICaracteristicaVeiculo | null;
	message: string;
	numeroEspelho?: string;
}

export interface ConsultarVeiculoV110Request {
	chassi: string;
	placa: string;
	selecao?: 'chassi' | 'placa';
	numeroEspelho?: string;
	tipoConsulta?:
		| 'ALTERACAO_MODIFICACAO'
		| 'LICENCIAMENTO'
		| 'TRANSFERENCIA'
		| 'AUTORIZACAO_PREVIA'
		| 'BAIXA_CRV'
		| 'EMPLACAMENTO'
		| 'TRANSFERENCIA_MUNICIPIO';
}

export type IProcedencia = 'IMPORTADA' | 'NACIONAL';

export interface ICaracteristicaVeiculo {
	caracteristicaVeiculo: {
		placa: string;
		placaAnterior: string;
		ufPlacaAnterior: string;
		municipioPlacaAnterior: {
			codigo: number;
			nome: string;
		};
		ufEspelhoAnterior: string;
		renavam: string | number;
		chassi: string;
		chassiRemarcado: boolean | null;
		anoFabricacao: number;
		anoModelo: number;
		capacidadePassageiro: number;
		capacidadeCarga: number;
		potencia: number;
		cilindrada: number;
		cmt: number;
		pbt: number;
		procedencia: IProcedencia;
		eixos: number;
		numeroMotor: string;
		municipio: {
			codigo: number;
			nome: string;
		};
		marca: {
			longId: number;
			descricao: string;
		};
		tipo: {
			longId: number;
			descricao: string;
		};
		carroceria: {
			longId: number;
			descricao: string;
		};
		categoria: {
			descricao: string;
			longId: number;
		};
		cor: {
			longId: number;
			descricao: string;
		};
		combustivel: {
			longId: number;
			descricao: string;
		};
		especie: {
			longId: number;
			descricao: string;
		};
		proprietario: IProprietario;
		gravame: {
			restricao: string;
			codigoFinanceira: number;
			arrendatarioFinanceira: string;
			numeroContrato: string;
			dataVigencia: string;
		};
		dataVenda: string;
		comunicacaoVenda: {
			dataVenda: string;
		};
		indicadorRenave: string | number;
		tipoDocumentoLojaComprador: string | number;
		documentoLojaComprador: string | number;
		nomeLojaComprador: string;
		logradouroLojaComprador: string;
		numeroLojaComprador: number;
		complementoLojaComprador: string;
		bairroLojaComprador: string;
		municipioLojaComprador: number;
		ufLojaComprador: string;
		cepLojaComprador: number;
		indicadorIntencaoVenda: number;
		indicadorCrvDigital: number;
		origemComunicacaoVenda: string;
		dataInclusaoCV: string;
	};
}

export interface IProprietario {
	nomeProprietario: string;
	identificacao: 'FISICA' | 'JURIDICA';
	cpfCnpjProprietario: number;
	nomeProprietarioAnterior: string;
	cpfCnpjProprietarioAnterior: number;
	// rgProprietario: string;
	bairroProprietario: string;
	cepProprietario: string;
	complementoLogradouroProprietario: string;
	enderecoProprietario: string;
	numeroLogradouroProprietario: string;
	municipioProprietario: {
		codigo: number;
		nome: string;
	};
	tipoDocumento: string;
}
