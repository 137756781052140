export enum Types {
	CADASTRAR_MENUS = '@sgu/CADASTRAR_MENUS',
	CADASTRAR_MENUS_SUCCESS = '@sgu/CADASTRAR_MENUS_SUCCESS',
	CADASTRAR_MENUS_FAILURE = '@sgu/CADASTRAR_MENUS_FAILURE',
	CADASTRAR_MENUS_CLEAR = '@sgu/CADASTRAR_MENUS_CLEAR',
}

export interface CadastrarMenus {
	status: number;
	data: null | LocalidadeData;
}

interface LocalidadeData {
	codigo: number;
	mensagem: string;
}

export interface CadastrarMenusRequest {
	idOrgao: number;
	descricao: string;
	nrOrdem: number | null;
	siglaMenu: string;
	idUsuarioCadastro: number;
	localidades: number[];
}
