export enum Types {
	ENVIAR_SOLICITACOES_PORTAL_REQUEST = '@MP/ENVIAR_SOLICITACOES_PORTAL_REQUEST',
	ENVIAR_SOLICITACOES_PORTAL_SUCCESS = '@MP/ENVIAR_SOLICITACOES_PORTAL_SUCCESS',
	ENVIAR_SOLICITACOES_PORTAL_FAILURE = '@MP/ENVIAR_SOLICITACOES_PORTAL_FAILURE',
	ENVIAR_SOLICITACOES_PORTAL_CLEAR = '@MP/ENVIAR_SOLICITACOES_PORTAL_CLEAR',
}

export interface EnviarSolicitacoesPortal {
	status: number;
	data: EnviarSolicitacoesPortalData | null;
}

export interface EnviarSolicitacoesPortalData {
	idSolicitacao: string;
	mensagem: string;
}

export interface EnviarSolicitacoesPortalRequest {
	filho: {
		nome: string;
		dataNascimento: string;
		cpf: string;
		email: string;
		sexo: string;
		endereco: {
			bairro: string;
			cep: string;
			cidade: string;
			complementoEndereco: string;
			logradouro: string;
			numero: string;
			uf: string;
		};
		rg: {
			numero: string;
			digito: string;
			uf: string;
		};
		telefones: Telefones[];
		genitor: {
			cidadeNascimento: string;
			cpf: string;
			dataNascimento: string;
			email: string;
			endereco: {
				bairro: string;
				cep: string;
				cidade: string;
				complementoEndereco: string;
				logradouro: string;
				numero: string;
				uf: string;
			};
			nome: string;
			nomeMae: string;
			outrasInformacoes: string;
			rg: {
				numero: string;
				digito: string;
				uf: string;
			};
			sexo: string;
			telefones: Telefones[];
			ufNascimento: string;
		};
	};
	responsavel?: {
		cpf: string;
		dataNascimento: string;
		email: string;
		endereco: {
			bairro: string;
			cep: string;
			cidade: string;
			complementoEndereco: string;
			logradouro: string;
			numero: string;
			uf: string;
		};
		nome: string;
		rg: {
			numero: string;
			digito: string;
			uf: string;
		};
		sexo: string;
		telefones: Telefones[];
	};
	destinatarioSolicitacao: string;
	aceiteTermoDeclaracaoPobreza: boolean;
	aceiteTermo: boolean;
	aceiteAcrescNomePai: boolean;
	idAtendente: string;
	dataCriacao?: string;
	dataModificacao?: string;
	status: string;
	origem: string;
	protocolo: string;
	idCidadao?: string;
	idAtendimento: string;
	emailContato?: string;
	idPosto?: number;
	nomePosto?: string;
}

interface Telefones {
	ddd: string;
	numero: string;
	tipo: number;
}
