import produce from 'immer';
// import { ReducerAction } from 'store/modules/types';

// TYPES
// import { toSelect } from 'utils/genericFunctions';
import { Types, ConsultarParametrosSistemaRedis } from './types';
import { ReducerAction } from '../../../../../../types';

export const INITIAL_STATE: ConsultarParametrosSistemaRedis = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarParametrosSistemaRedis {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_PARAMETROS_SISTEMA_REDIS: {
				draft.status = INITIAL_STATE.status;
				break;
			}

			case Types.CONSULTAR_PARAMETROS_SISTEMA_REDIS_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_PARAMETROS_SISTEMA_REDIS_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTAR_PARAMETROS_SISTEMA_REDIS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
