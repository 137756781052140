import { ILogin } from 'store/modules/api/sgu/loginUnico/types';

import { IFormCadastroRg } from 'pages/iirgd/RgFicDigital/CadastroRg/form';

// UTILS
import {
	calcularIdade,
	limparMascara,
	unformatDate,
} from 'utils/genericFunctions';

interface TipoReuso {
	reusoParcial: boolean;
	reusoTotal: boolean;
}

export interface IRequestCadastrarRG {
	// Principal
	argumento: string;
	numeroRg: string;
	digitoRg: string;
	ufRg: string;
	numeroPidt: string;
	digitoPidt: string;
	// pidt: string;
	nome: string;
	nomeSocial: string;
	nomePai: string;
	nomeMae: string;
	sexo: string;
	dataNascimento: string;
	codigoNaturalidade: string;
	municipioNaturalidade: string;
	digitoNaturalidade: string;
	ufNaturalidade: string;
	multifiliacao: boolean;
	filiacaoTres: string;
	filiacaoQuatro: string;
	filiacaoCinco: string;
	filiacaoSeis: string;

	// Documento apresentado
	docBase: string;
	// dbas: string;
	comarcaCertidao: string;
	// comarca: string;
	// cartorio?: string;
	cartorioCertidao: string;
	folhaCertidao: string;
	// folha: string;
	livroCertidao: string;
	// livro: string;
	numeroCertidao: string;
	// numero: string;
	dataDocumento: string;
	pMin: string;
	// pMinLei1: string;
	lei: string;
	// pMinLei2: string;

	// Dados adicionais - Cidadão
	estadoCivil: string;
	cutis: string;
	cabelos: string;
	olhos: string;
	alturaM: string;
	alturaCm: string;
	grauInstrucao: string;
	profissao: string;
	cepResidencia: string;
	enderecoResidencia: string;
	numeroResidencia: string;
	complementoResidencia: string;
	bairroResidencia: string;
	codigoMunicipioResidencia: string;
	digitoMunicipioResidencia: string;
	descMunicipioResidencia: string;
	ufResidencia: string;

	// Dados adicionais - Trabalho
	cepTrabalho: string;
	cepTrabalhoComplemento: string;
	enderecoTrabalho: string;
	numeroTrabalho: string;
	complementoTrabalho: string;
	bairroTrabalho: string;
	codigoMunicipioTrabalho: string;
	digitoMunicipioTrabalho: string;
	descMunicipioTrabalho: string;

	// Dados adicionais - Contato
	solicitacao: string;
	foneComercialDdd: string;
	foneComercialPrefixo: string;
	foneComercialSufixo: string;
	// foneComercial: string;
	foneResidenciaDdd: string;
	foneResidenciaPrefixo: string;
	foneResidenciaSufixo: string;
	// foneResidencia: string;
	celularDdd: string;
	celularPrefixo: string;
	celularSufixo: string;
	// celular: string;
	operadora: string;
	emailIdentificador: string;
	emailDominio: string;
	// email: string;

	// Informações adicionais
	identificadoAssina: boolean | string;
	motivoFaltaAssinatura: string;
	carimboMaior65: boolean | string;
	tipoSanguineo: string;
	cid1: string;
	cid2: string;
	cid3: string;
	cid4: string;
	dataValidade?: string;

	// Dados adicionais - RG
	inscricaoCpf?: string | boolean;
	numeroCpf: string;
	digitoCpf: string;
	// cpf: string;
	pis: string;
	numeroPasep: string;
	digitoPasep: string;
	// pasep: string;
	dni: string | null;
	tituloEleitor: string;
	ctpsNumero: string;
	// ctpsNum: string;
	ctpsSerie: string;
	ctpsSerieUf: string;
	certidaoMilitar: string;
	cnh: string;
	categoriaCnh: string;
	doador: boolean;
	nascSaude: string;
	identProf1: string;
	orgaoEmissor1: string;
	identProf2: string;
	orgaoEmissor2: string;
	identProf3: string;
	orgaoEmissor3: string;

	// Entrega RG
	entrega: string | number;
	dataHora: string;
	// data: string;
	// hora: string;

	// Usuário da sessão
	usuario: string;
	senha: string;
	cpfAtendente?: string;

	// Novos campos

	cepResidenciaComplemento: string;
	eventoEstatisticasSGU?: {
		token: string;
		idCidadao: string;
		descricaoSistemOrigem: string;
		tipoDocumento: string;
		numeroDocumento: string;
		codigoLocalidade: number;
		idOrgao: number;
		cpf: string;
		descricaoServico: string;
		flagConfronto: string;
		descricaoDedosEnviados: string;
		idMotivo: number;
		observacao: string;
		numeroVia: number;
		numeroProcesso: string;
		totalizador: string;
	};
}

export interface IRequestAtualizarCITA {
	// Principal
	// argumento: string;
	numeroRg: string;
	digitoRg: string;
	ufRg: string;
	numeroPidt: string;
	digitoPidt: string;
	// pidt: string;
	nome: string;
	nomeSocial: string;
	nomePai: string;
	nomeMae: string;
	sexo: string;
	dataNascimento: string;
	codigoNaturalidade: string;
	municipioNaturalidade: string;
	digitoNaturalidade: string;
	ufNaturalidade: string;
	multifiliacao: boolean;
	filiacaoTres: string;
	filiacaoQuatro: string;
	filiacaoCinco: string;
	filiacaoSeis: string;

	// Documento apresentado
	docBase: string;
	comarcaCertidao: string;
	cartorioCertidao: string;
	livroCertidao: string;
	folhaCertidao: string;
	numeroCertidao: string;
	dataDocumento: string;
	pMin: string;
	lei: string;

	// Dados adicionais - Cidadão
	estadoCivil: string;
	cutis: string;
	cabelos: string;
	olhos: string;
	alturaM: string;
	alturaCm: string;
	grauInstrucao: string;
	profissao: string;
	cepResidencia: string;
	enderecoResidencia: string;
	numeroResidencia: string;
	complementoResidencia: string;
	bairroResidencia: string;
	codigoMunicipioResidencia: string;
	cepResidenciaComplemento: string;
	digitoMunicipioResidencia: string;
	descMunicipioResidencia: string;
	ufResidencia: string;

	// Dados adicionais - Trabalho
	cepTrabalho: string;
	cepTrabalhoComplemento: string;
	enderecoTrabalho: string;
	numeroTrabalho: string;
	complementoTrabalho: string;
	bairroTrabalho: string;
	codigoMunicipioTrabalho: string;
	digitoMunicipioTrabalho: string;
	descMunicipioTrabalho: string;

	// Dados adicionais - Contato
	solicitacao: string;
	foneComercialDdd: string;
	foneComercialPrefixo: string;
	foneComercialSufixo: string;
	foneResidenciaDdd: string;
	foneResidenciaPrefixo: string;
	foneResidenciaSufixo: string;
	celularDdd: string;
	celularPrefixo: string;
	celularSufixo: string;
	operadora: string;
	emailIdentificador: string;
	emailDominio: string;

	// Informações adicionais
	identificadoAssina: boolean | string;
	motivoFaltaAssinatura: string;
	carimboMaior65: boolean | string;
	tipoSanguineo: string;
	cid1: string;
	cid2: string;
	cid3: string;
	cid4: string;
	dataValidade?: string;

	// Dados adicionais - RG
	inscricaoCpf?: string | boolean;
	numeroCpf: string;
	digitoCpf: string;
	pis: string;
	numeroPasep: string;
	digitoPasep: string;
	dni: string | null;
	tituloEleitor: string;
	ctpsNumero: string;
	ctpsSerie: string;
	ctpsSerieUf: string;
	certidaoMilitar: string;
	cnh: string;
	categoriaCnh: string;
	doador: boolean;
	nascSaude: string;
	identProf1: string;
	orgaoEmissor1: string;
	identProf2: string;
	orgaoEmissor2: string;
	identProf3: string;
	orgaoEmissor3: string;

	// Entrega RG
	entrega: string | number;
	dataHora: string;

	// Usuário da sessão
	usuario: string;
	senha: string;
	cpfAtendente?: string;

	eventoEstatisticasSGU?: {
		token: string;
		idCidadao: string;
		descricaoSistemOrigem: string;
		tipoDocumento: string;
		numeroDocumento: string;
		codigoLocalidade: number;
		idOrgao: number;
		cpf: string;
		descricaoServico: string;
		flagConfronto: string;
		descricaoDedosEnviados: string;
		idMotivo: number;
		observacao: string;
		numeroVia: number;
		numeroProcesso: string;
		totalizador: string;
	};
}

function reusoParcial(
	original: Omit<
		IFormCadastroRg,
		| 'pidt'
		| 'naturalidadeEstrangeira'
		| 'inscricaoCpf'
		| 'entrega'
		| 'qtdKitFamilia'
		| 'data'
		| 'hora'
	>,
	formulario: Omit<
		IFormCadastroRg,
		| 'pidt'
		| 'naturalidadeEstrangeira'
		| 'inscricaoCpf'
		| 'entrega'
		| 'qtdKitFamilia'
		| 'data'
		| 'hora'
	>,
): boolean {
	/**
	 * Campos a serem validados:
	 * Principal: Nome
	 * Principal: Nome do Pai
	 * Principal: Nome do Mãe
	 * Principal: Sexo
	 * Principal: Data de nascimento
	 * Principal: Município
	 *
	 * Documento apresentado: Tipo de documento
	 * Documento apresentado: Comarca
	 * Documento apresentado: Cartório
	 * Documento apresentado: Livro
	 * Documento apresentado: Folha
	 * Documento apresentado: Número
	 * Documento apresentado: Data do documento
	 * Documento apresentado: Port. Min-Lei
	 *
	 * Dados adicionais - Cidadão: Estado Cívil
	 * Dados adicionais - Cidadão: Cutis
	 * Dados adicionais - Cidadão: Cabelos
	 * Dados adicionais - Cidadão: Olhos
	 * Dados adicionais - Cidadão: Profissão
	 *
	 * Dados adicionais - RG: CPF
	 * Dados adicionais - RG: PIS/NIS
	 * Dados adicionais - RG: PASEP
	 */

	if (
		formulario.nome !== original.nome ||
		formulario.nomePai !== original.nomePai ||
		formulario.nomeMae !== original.nomeMae ||
		formulario.sexo !== original.sexo ||
		formulario.dataNascimento !== original.dataNascimento ||
		formulario.codigoNaturalidade !== original.codigoNaturalidade ||
		formulario.dbas !== original.dbas ||
		formulario.comarca !== original.comarca ||
		formulario.cartorio !== original.cartorio ||
		formulario.livro !== original.livro ||
		formulario.folha !== original.folha ||
		formulario.numero !== original.numero ||
		formulario.dataDocumento !== original.dataDocumento ||
		formulario.pMinLei1 !== original.pMinLei1 ||
		formulario.pMinLei2 !== original.pMinLei2 ||
		formulario.estadoCivil !== original.estadoCivil ||
		formulario.cutis !== original.cutis ||
		formulario.cabelos !== original.cabelos ||
		formulario.olhos !== original.olhos ||
		formulario.profissao !== original.profissao ||
		formulario.cpf !== original.cpf ||
		formulario.pis !== original.pis ||
		formulario.pasep !== original.pasep
	)
		return true;

	return false;
}

function reusoTotal(
	original: Omit<
		IFormCadastroRg,
		| 'pidt'
		| 'naturalidadeEstrangeira'
		| 'inscricaoCpf'
		| 'entrega'
		| 'qtdKitFamilia'
		| 'data'
		| 'hora'
	>,
	formulario: Omit<
		IFormCadastroRg,
		| 'pidt'
		| 'naturalidadeEstrangeira'
		| 'inscricaoCpf'
		| 'entrega'
		| 'qtdKitFamilia'
		| 'data'
		| 'hora'
	>,
): boolean {
	// Se o formulário não sofreu alterações.
	if (JSON.stringify(formulario) === JSON.stringify(original)) return true;

	/**
	 * Campos a serem validados:
	 *
	 * Principal: Nome social
	 *
	 * Documento apresentado: Data do documento
	 *
	 * Dados adicionais - Cidadão: Altura
	 * Dados adicionais - Cidadão: Grau de instrução
	 * Dados adicionais - Cidadão: CEP
	 * Dados adicionais - Cidadão: Logradouro
	 * Dados adicionais - Cidadão: Número
	 * Dados adicionais - Cidadão: Complemento
	 * Dados adicionais - Cidadão: Bairro
	 * Dados adicionais - Cidadão: Munícipio
	 *
	 * Dados adicionais - Trabalho: CEP
	 * Dados adicionais - Trabalho: Logradouro
	 * Dados adicionais - Trabalho: Número
	 * Dados adicionais - Trabalho: Complemento
	 * Dados adicionais - Trabalho: Bairro
	 * Dados adicionais - Trabalho: Munícipio
	 */

	if (
		formulario.nomeSocial !== original.nomeSocial ||
		formulario.dataDocumento !== original.dataDocumento ||
		formulario.altura !== original.altura ||
		formulario.grauInstrucao !== original.grauInstrucao ||
		formulario.cepResidencia !== original.cepResidencia ||
		formulario.enderecoResidencia !== original.enderecoResidencia ||
		formulario.numeroResidencia !== original.numeroResidencia ||
		formulario.complementoResidencia !== original.complementoResidencia ||
		formulario.bairroResidencia !== original.bairroResidencia ||
		formulario.codigoMunicipioResidencia !==
			original.codigoMunicipioResidencia ||
		formulario.cepTrabalho !== original.cepTrabalho ||
		formulario.enderecoTrabalho !== original.enderecoTrabalho ||
		formulario.numeroTrabalho !== original.numeroTrabalho ||
		formulario.complementoTrabalho !== original.complementoTrabalho ||
		formulario.bairroTrabalho !== original.bairroTrabalho ||
		formulario.codigoMunicipioTrabalho !== original.codigoMunicipioTrabalho ||
		formulario.descMunicipioTrabalho !== original.descMunicipioTrabalho ||
		formulario.solicitacao !== original.solicitacao ||
		formulario.foneResidencia !== original.foneResidencia ||
		formulario.foneComercial !== original.foneComercial ||
		formulario.celular !== original.celular ||
		formulario.operadora !== original.operadora ||
		formulario.email !== original.email ||
		formulario.tituloEleitor !== original.tituloEleitor ||
		formulario.ctpsNum !== original.ctpsNum ||
		formulario.ctpsSerie !== original.ctpsSerie ||
		formulario.ctpsSerieUf !== original.ctpsSerieUf ||
		formulario.certidaoMilitar !== original.certidaoMilitar ||
		formulario.cnh !== original.cnh ||
		formulario.categoriaCnh !== original.categoriaCnh ||
		formulario.doador !== original.doador ||
		formulario.nascSaude !== original.nascSaude ||
		formulario.identProf1 !== original.identProf1 ||
		formulario.orgaoEmissor1 !== original.orgaoEmissor1 ||
		formulario.identProf2 !== original.identProf2 ||
		formulario.orgaoEmissor2 !== original.orgaoEmissor2 ||
		formulario.identProf3 !== original.identProf3 ||
		formulario.orgaoEmissor3 !== original.orgaoEmissor3
	)
		return true;

	return false;
}

function omitFields(
	values: IFormCadastroRg,
): Omit<
	IFormCadastroRg,
	| 'pidt'
	| 'naturalidadeEstrangeira'
	| 'inscricaoCpf'
	| 'entrega'
	| 'qtdKitFamilia'
	| 'data'
	| 'hora'
> {
	return {
		// Principal
		argumento: values.argumento,
		numeroRg: values.numeroRg,
		nome: values.nome,
		nomeSocial: values.nomeSocial,
		nomePai: values.nomePai,
		nomeMae: values.nomeMae,
		sexo: values.sexo,
		dataNascimento: values.dataNascimento,
		codigoNaturalidade: values.codigoNaturalidade,
		municipioNaturalidade: values.municipioNaturalidade,
		multifiliacao: values.multifiliacao,
		filiacaoTres: values.filiacaoTres,
		filiacaoQuatro: values.filiacaoQuatro,
		filiacaoCinco: values.filiacaoCinco,
		filiacaoSeis: values.filiacaoSeis,

		// Documento apresentado
		dbas: values.dbas,
		comarca: values.comarca,
		cartorio: '',
		cartorioCertidao: values.cartorio,
		livro: values.livro,
		folha: values.folha,
		numero: values.numero,
		dataDocumento: values.dataDocumento,
		pMinLei1: values.pMinLei1,
		pMinLei2: values.pMinLei2,

		// Dados adicionais - Cidadão
		estadoCivil: values.estadoCivil,
		cutis: values.cutis,
		cabelos: values.cabelos,
		olhos: values.olhos,
		altura: values.altura,
		grauInstrucao: values.grauInstrucao,
		profissao: values.profissao,
		cepResidencia: values.cepResidencia,
		enderecoResidencia: values.enderecoResidencia,
		numeroResidencia: values.numeroResidencia,
		complementoResidencia: values.complementoResidencia,
		bairroResidencia: values.bairroResidencia,
		codigoMunicipioResidencia: values.codigoMunicipioResidencia,
		descMunicipioResidencia: values.descMunicipioResidencia,
		ufResidencia: values.ufResidencia,

		// Dados adicionais - Trabalho
		cepTrabalho: values.cepTrabalho,
		enderecoTrabalho: values.enderecoTrabalho,
		numeroTrabalho: values.numeroTrabalho,
		complementoTrabalho: values.complementoTrabalho,
		bairroTrabalho: values.bairroTrabalho,
		codigoMunicipioTrabalho: values.codigoMunicipioTrabalho,
		descMunicipioTrabalho: values.descMunicipioTrabalho,

		// Dados adicionais - Contato
		solicitacao: values.solicitacao,
		foneResidencia: values.foneResidencia,
		foneComercial: values.foneComercial,
		celular: values.celular,
		operadora: values.operadora,
		email: values.email,
		naoPossuiEmail: values.naoPossuiEmail,

		// Informações adicionais
		identificadoAssina: String(values.identificadoAssina),
		motivoFaltaAssinatura: !values.identificadoAssina
			? values.motivoFaltaAssinatura
			: '',
		carimboMaior65: values.carimboMaior65,
		tipoSanguineo: values.tipoSanguineo,
		cid1: values.cid1,
		cid2: values.cid2,
		cid3: values.cid3,
		cid4: values.cid4,

		// Dados adicionais - RG
		cpf: values.cpf,
		pis: values.pis,
		pasep: values.pasep,
		dni: values.dni,
		tituloEleitor: values.tituloEleitor,
		ctpsNum: values.ctpsNum,
		ctpsSerie: values.ctpsSerie,
		ctpsSerieUf: values.ctpsSerieUf,
		certidaoMilitar: values.certidaoMilitar,
		cnh: values.cnh,
		categoriaCnh: values.categoriaCnh,
		doador: values.doador,
		nascSaude: values.nascSaude,
		identProf1: values.identProf1,
		orgaoEmissor1: values.orgaoEmissor1,
		identProf2: values.identProf2,
		orgaoEmissor2: values.orgaoEmissor2,
		identProf3: values.identProf3,
		orgaoEmissor3: values.orgaoEmissor3,
		sedex: values.sedex,
	};
}

export function verificaTipoReuso(
	original: IFormCadastroRg,
	formulario: IFormCadastroRg,
): TipoReuso {
	const omitOriginal = omitFields(original);
	const omitFormulario = omitFields(formulario);

	const tipo = {
		reusoParcial: false,
		reusoTotal: false,
	};

	if (reusoTotal(omitOriginal, omitFormulario)) {
		tipo.reusoParcial = false;
		tipo.reusoTotal = true;
	} else if (reusoParcial(omitOriginal, omitFormulario)) {
		tipo.reusoParcial = true;
		tipo.reusoTotal = false;
	}

	return tipo;
}

const getUfNaturalidade = (municipioNaturalidade: string): string => {
	if (
		municipioNaturalidade &&
		municipioNaturalidade.split('-').length &&
		municipioNaturalidade.split('-').pop()
	) {
		return (
			municipioNaturalidade.split('-').pop()?.replaceAll(' ', '').trim() || ''
		);
	}
	return '';
};

export function formatarCadastrarRG(
	formValues: IFormCadastroRg,
	login: ILogin,
): IRequestCadastrarRG {
	const ufResidencia = (): string => {
		if (formValues.ufResidencia) {
			return formValues.ufResidencia.trim();
		}
		if (
			formValues?.descMunicipioResidencia &&
			formValues?.descMunicipioResidencia.split('-').length &&
			formValues.descMunicipioResidencia.split('-').pop()
		) {
			return (
				formValues.descMunicipioResidencia
					.split('-')
					.pop()
					?.replaceAll(' ', '')
					.trim() || ''
			);
		}
		return '';
	};

	const rg = (type: 'numero' | 'digito'): string => {
		if (formValues.numeroRg !== '') {
			if (type === 'numero') {
				return limparMascara(formValues.numeroRg.split('-')[0]);
			}
			return formValues.numeroRg.split('-')[1];
		}
		return '';
	};

	return {
		// Principal
		argumento: formValues.argumento,
		numeroRg: formValues.argumento === 'acervo' ? rg('numero') : '',
		digitoRg: formValues.argumento === 'acervo' ? rg('digito') : '',
		ufRg: '',
		numeroPidt: formValues.pidt ? formValues.pidt.split('-')[0] : '',
		digitoPidt: formValues.pidt ? formValues.pidt.split('-')[1] : '',

		nome: formValues.nome.toUpperCase().trim(),
		nomeSocial: formValues.nomeSocial.toUpperCase().trim(),
		nomePai: formValues.nomePai.toUpperCase().trim(),
		nomeMae: formValues.nomeMae.toUpperCase().trim(),
		sexo: formValues.sexo,
		dataNascimento: unformatDate(formValues.dataNascimento),
		municipioNaturalidade: formValues.municipioNaturalidade
			? formValues.municipioNaturalidade.split('-')[0].trim()
			: '',
		codigoNaturalidade: formValues.codigoNaturalidade
			? limparMascara(formValues.codigoNaturalidade).slice(0, 5)
			: '',
		digitoNaturalidade: formValues.codigoNaturalidade
			? limparMascara(formValues.codigoNaturalidade).slice(5, 6)
			: '',
		ufNaturalidade: getUfNaturalidade(formValues.municipioNaturalidade),
		multifiliacao: formValues.multifiliacao,
		filiacaoTres: formValues.filiacaoTres,
		filiacaoQuatro: formValues.filiacaoQuatro,
		filiacaoCinco: formValues.filiacaoCinco,
		filiacaoSeis: formValues.filiacaoSeis,

		// Documento apresentado
		docBase: formValues.dbas,
		comarcaCertidao: formValues.comarca
			? formValues.comarca.toUpperCase().trim()
			: '',
		cartorioCertidao: formValues?.cartorio
			? formValues?.cartorio.toUpperCase().trim()
			: '',
		livroCertidao: formValues.livro
			? limparMascara(formValues.livro).trim()
			: '',
		folhaCertidao: formValues.folha
			? limparMascara(formValues.folha).trim()
			: '',
		numeroCertidao: formValues.numero
			? limparMascara(formValues.numero).trim()
			: '',
		dataDocumento: unformatDate(formValues.dataDocumento).trim(),
		pMin: formValues.pMinLei1.trim(),
		lei: formValues.pMinLei2,

		// Dados adicionais do cidadão
		estadoCivil: formValues.estadoCivil,
		cutis: formValues.cutis,
		cabelos: formValues.cabelos,
		olhos: formValues.olhos,
		alturaM: formValues.altura.substring(0, 1),
		alturaCm: formValues.altura.substring(2, 5),
		grauInstrucao: formValues.grauInstrucao,
		profissao: formValues.profissao ? formValues.profissao.toUpperCase() : '',
		cepResidencia: formValues.cepResidencia
			? limparMascara(formValues.cepResidencia).substring(0, 5)
			: '',
		cepResidenciaComplemento: formValues.cepResidencia
			? limparMascara(formValues.cepResidencia).substring(5, 8)
			: '',
		enderecoResidencia: formValues.enderecoResidencia
			? formValues.enderecoResidencia.toUpperCase()
			: '',
		numeroResidencia: formValues.numeroResidencia,
		complementoResidencia: formValues.complementoResidencia
			? formValues.complementoResidencia.toUpperCase().trim()
			: '',
		bairroResidencia: formValues.bairroResidencia
			? formValues.bairroResidencia.toUpperCase()
			: '',
		codigoMunicipioResidencia: formValues.codigoMunicipioResidencia
			? formValues.codigoMunicipioResidencia.split('-')[0]
			: '',
		digitoMunicipioResidencia: formValues.codigoMunicipioResidencia
			? formValues.codigoMunicipioResidencia.split('-')[1]
			: '',
		descMunicipioResidencia: formValues.descMunicipioResidencia.substring(
			0,
			16,
		),
		ufResidencia: ufResidencia(),

		// Dados adicionais do trabalho
		cepTrabalho: formValues.cepTrabalho
			? limparMascara(formValues.cepTrabalho).substring(0, 5)
			: '',
		cepTrabalhoComplemento: formValues.cepTrabalho
			? limparMascara(formValues.cepTrabalho).substring(5, 8)
			: '',
		enderecoTrabalho: formValues.enderecoTrabalho
			? formValues.enderecoTrabalho.toUpperCase()
			: '',
		numeroTrabalho: formValues.numeroTrabalho,
		complementoTrabalho: formValues.complementoTrabalho
			? formValues.complementoTrabalho.toUpperCase().trim()
			: '',
		bairroTrabalho: formValues.bairroTrabalho
			? formValues.bairroTrabalho.toUpperCase()
			: '',
		codigoMunicipioTrabalho: formValues.codigoMunicipioTrabalho
			? formValues.codigoMunicipioTrabalho.split('-')[0]
			: '',
		digitoMunicipioTrabalho: formValues.codigoMunicipioTrabalho
			? formValues.codigoMunicipioTrabalho.split('-')[1]
			: '',
		descMunicipioTrabalho: formValues.descMunicipioTrabalho
			? formValues.descMunicipioTrabalho.substring(0, 16)
			: '',

		// Dados adicionais de contato
		solicitacao: formValues.solicitacao,
		celularDdd: formValues.celular
			? limparMascara(formValues.celular).substr(0, 2)
			: '',
		celularPrefixo: formValues.celular
			? limparMascara(formValues.celular).substr(2, 5)
			: '',
		celularSufixo: formValues.celular
			? limparMascara(formValues.celular).substr(7, 4)
			: '',
		foneComercialDdd: limparMascara(formValues.foneComercial).substr(0, 2),
		foneComercialPrefixo: limparMascara(formValues.foneComercial).substr(2, 4),
		foneComercialSufixo: limparMascara(formValues.foneComercial).substr(7, 4),
		foneResidenciaDdd: limparMascara(formValues.foneResidencia).substr(0, 2),
		foneResidenciaPrefixo: limparMascara(formValues.foneResidencia).substr(
			2,
			4,
		),
		foneResidenciaSufixo: limparMascara(formValues.foneResidencia).substr(7, 4),
		emailIdentificador: formValues.email
			? formValues.email.split('@')[0].trim()
			: '',
		emailDominio: formValues.email ? formValues.email.split('@')[1].trim() : '',
		operadora: formValues.operadora,

		// Informações adicionais
		identificadoAssina: String(formValues.identificadoAssina),
		motivoFaltaAssinatura: !formValues.identificadoAssina
			? formValues.motivoFaltaAssinatura
			: '',
		carimboMaior65:
			calcularIdade(formValues.dataNascimento) < 65
				? ''
				: formValues.carimboMaior65,
		tipoSanguineo: formValues.tipoSanguineo,
		cid1: formValues.cid1,
		cid2: formValues.cid2,
		cid3: formValues.cid3,
		cid4: formValues.cid4,
		dataValidade:
			formValues.dataValidade !== ''
				? unformatDate(formValues.dataValidade)
				: '',

		// Dados adicionais para constar no RG
		inscricaoCpf:
			formValues.inscricaoCpf && formValues.inscricaoCpf === ''
				? 'false'
				: formValues.inscricaoCpf,
		numeroCpf: limparMascara(formValues.cpf).substr(0, 9),
		digitoCpf: limparMascara(formValues.cpf).substr(9, 2),
		pis: formValues.pis,
		numeroPasep: formValues.pasep ? formValues.pasep.split('-')[0] : '',
		digitoPasep: formValues.pasep ? formValues.pasep.split('-')[1] : '',
		dni: formValues.dni,
		tituloEleitor: formValues.tituloEleitor
			? limparMascara(formValues.tituloEleitor)
			: '',
		ctpsNumero: formValues.ctpsNum ? limparMascara(formValues.ctpsNum) : '',
		ctpsSerie: formValues.ctpsSerie,
		ctpsSerieUf: formValues.ctpsSerieUf,
		certidaoMilitar: formValues.certidaoMilitar
			? limparMascara(formValues.certidaoMilitar)
			: '',
		cnh: formValues.cnh ? limparMascara(formValues.cnh) : '',
		categoriaCnh: formValues.categoriaCnh,
		doador: formValues.doador,
		nascSaude: formValues.nascSaude ? limparMascara(formValues.nascSaude) : '',
		identProf1: formValues.identProf1
			? limparMascara(formValues.identProf1).trim()
			: '',
		orgaoEmissor1: formValues.orgaoEmissor1
			? limparMascara(formValues.orgaoEmissor1).trim()
			: '',
		identProf2: formValues.identProf2
			? limparMascara(formValues.identProf2).trim()
			: '',
		orgaoEmissor2: formValues.orgaoEmissor2
			? limparMascara(formValues.orgaoEmissor2).trim()
			: '',
		identProf3: formValues.identProf3
			? limparMascara(formValues.identProf3).trim()
			: '',
		orgaoEmissor3: formValues.orgaoEmissor3
			? limparMascara(formValues.orgaoEmissor3).trim()
			: '',

		eventoEstatisticasSGU: formValues.eventoEstatisticasSGU,

		// Entrega do RG
		entrega: formValues.entrega ? formValues.entrega : '',
		dataHora:
			formValues.entrega === 'P' && formValues.data
				? new Date(
						`${unformatDate(formValues.data)} ${formValues.hora}`,
				  ).toISOString()
				: '',

		// Usuário da sessão
		usuario: login.mainframe.idUsuario,
		senha: login.mainframe.senhaMainFrame,
		cpfAtendente: login.user.cpf,
	};
}

export function formatarAtualizarCITA(
	formValues: IFormCadastroRg,
	login: ILogin,
): IRequestAtualizarCITA {
	const ufResidencia = (): string => {
		if (formValues.ufResidencia) {
			return formValues.ufResidencia.trim();
		}
		if (
			formValues?.descMunicipioResidencia &&
			formValues?.descMunicipioResidencia.split('-').length &&
			formValues.descMunicipioResidencia.split('-').pop()
		) {
			return (
				formValues.descMunicipioResidencia
					.split('-')
					.pop()
					?.replaceAll(' ', '')
					.trim() || ''
			);
		}
		return '';
	};

	const rg = (type: 'numero' | 'digito'): string => {
		if (formValues.numeroRg !== '') {
			if (type === 'numero') {
				return limparMascara(formValues.numeroRg.split('-')[0]);
			}
			return formValues.numeroRg.split('-')[1];
		}
		return '';
	};

	return {
		// Principal
		// argumento: formValues.argumento,
		numeroRg: rg('numero'),
		digitoRg: rg('digito'),
		ufRg: '',
		numeroPidt: formValues.pidt ? formValues.pidt.split('-')[0] : '',
		digitoPidt: formValues.pidt ? formValues.pidt.split('-')[1] : '',
		// pidt: formValues.pidt,
		nome: formValues.nome.toUpperCase().trim(),
		nomeSocial: formValues.nomeSocial.toUpperCase().trim(),
		nomePai: formValues.nomePai.toUpperCase().trim(),
		nomeMae: formValues.nomeMae.toUpperCase().trim(),
		sexo: formValues.sexo,
		dataNascimento: unformatDate(formValues.dataNascimento),
		municipioNaturalidade: formValues.municipioNaturalidade
			? formValues.municipioNaturalidade.split('-')[0].trim()
			: '',
		codigoNaturalidade: formValues.codigoNaturalidade
			? limparMascara(formValues.codigoNaturalidade).slice(0, 5)
			: '',
		digitoNaturalidade: formValues.codigoNaturalidade
			? limparMascara(formValues.codigoNaturalidade).slice(5, 6)
			: '',
		ufNaturalidade: getUfNaturalidade(formValues.municipioNaturalidade),
		multifiliacao:
			!!formValues.filiacaoTres.trim() ||
			!!formValues.filiacaoQuatro.trim() ||
			!!formValues.filiacaoCinco.trim() ||
			!!formValues.filiacaoSeis.trim(),
		filiacaoTres: formValues.filiacaoTres.toUpperCase().trim(),
		filiacaoQuatro: formValues.filiacaoQuatro.toUpperCase().trim(),
		filiacaoCinco: formValues.filiacaoCinco.toUpperCase().trim(),
		filiacaoSeis: formValues.filiacaoSeis.toUpperCase().trim(),

		// Documento apresentado
		docBase: formValues.dbas,
		// dbas: '',
		comarcaCertidao: formValues.comarca
			? formValues.comarca.toUpperCase().trim()
			: '',
		// comarca: '',
		cartorioCertidao: formValues?.cartorio
			? formValues?.cartorio.toUpperCase().trim()
			: '',
		// cartorio: '',
		livroCertidao: limparMascara(formValues.livro),
		// livro: formValues.livro,
		folhaCertidao: limparMascara(formValues.folha),
		// folha: formValues.folha,
		numeroCertidao: limparMascara(formValues.numero),
		// numero: formValues.numero,
		dataDocumento: unformatDate(formValues.dataDocumento),
		pMin: formValues.pMinLei1,
		lei: formValues.pMinLei2,
		// pMinLei1: formValues.pMinLei1,
		// pMinLei2: formValues.pMinLei2,

		// Dados adicionais do cidadão
		estadoCivil: formValues.estadoCivil,
		cutis: formValues.cutis,
		cabelos: formValues.cabelos,
		olhos: formValues.olhos,
		alturaM: formValues.altura.substring(0, 1),
		alturaCm: formValues.altura.substring(2, 5),
		grauInstrucao: formValues.grauInstrucao,
		profissao: formValues.profissao ? formValues.profissao.toUpperCase() : '',
		cepResidencia: formValues.cepResidencia
			? limparMascara(formValues.cepResidencia).substring(0, 5)
			: '',
		cepResidenciaComplemento: formValues.cepResidencia
			? limparMascara(formValues.cepResidencia).substring(5, 8)
			: '',
		enderecoResidencia: formValues.enderecoResidencia
			? formValues.enderecoResidencia.toUpperCase()
			: '',
		numeroResidencia: formValues.numeroResidencia,
		complementoResidencia: formValues.complementoResidencia
			? formValues.complementoResidencia.toUpperCase()
			: '',
		bairroResidencia: formValues.bairroResidencia
			? formValues.bairroResidencia.toUpperCase()
			: '',
		codigoMunicipioResidencia: formValues.codigoMunicipioResidencia
			? formValues.codigoMunicipioResidencia.split('-')[0]
			: '',
		digitoMunicipioResidencia: formValues.codigoMunicipioResidencia
			? formValues.codigoMunicipioResidencia.split('-')[1]
			: '',
		descMunicipioResidencia: formValues.descMunicipioResidencia.substring(
			0,
			16,
		),
		ufResidencia: ufResidencia(),

		// Dados adicionais do trabalho
		cepTrabalho: formValues.cepTrabalho
			? limparMascara(formValues.cepTrabalho).substring(0, 5)
			: '',
		cepTrabalhoComplemento: formValues.cepTrabalho
			? limparMascara(formValues.cepTrabalho).substring(5, 8)
			: '',
		enderecoTrabalho: formValues.enderecoTrabalho
			? formValues.enderecoTrabalho.toUpperCase()
			: '',
		numeroTrabalho: formValues.numeroTrabalho,
		complementoTrabalho: formValues.complementoTrabalho
			? formValues.complementoTrabalho.toUpperCase()
			: '',
		bairroTrabalho: formValues.bairroTrabalho
			? formValues.bairroTrabalho.toUpperCase()
			: '',
		codigoMunicipioTrabalho: formValues.codigoMunicipioTrabalho
			? formValues.codigoMunicipioTrabalho.split('-')[0]
			: '',
		digitoMunicipioTrabalho: formValues.codigoMunicipioTrabalho
			? formValues.codigoMunicipioTrabalho.split('-')[1]
			: '',
		descMunicipioTrabalho: formValues.descMunicipioTrabalho
			? formValues.descMunicipioTrabalho.substring(0, 16)
			: '',

		// Dados adicionais de contato
		solicitacao: formValues.solicitacao,
		celularDdd: limparMascara(formValues.celular).substr(0, 2),
		celularPrefixo: limparMascara(formValues.celular).substr(2, 5),
		celularSufixo: limparMascara(formValues.celular).substr(7, 4),
		foneComercialDdd: limparMascara(formValues.foneComercial).substr(0, 2),
		foneComercialPrefixo: limparMascara(formValues.foneComercial).substr(2, 4),
		foneComercialSufixo: limparMascara(formValues.foneComercial).substr(7, 4),
		foneResidenciaDdd: limparMascara(formValues.foneResidencia).substr(0, 2),
		foneResidenciaPrefixo: limparMascara(formValues.foneResidencia).substr(
			2,
			4,
		),
		foneResidenciaSufixo: limparMascara(formValues.foneResidencia).substr(7, 4),
		emailIdentificador: formValues.email ? formValues.email.split('@')[0] : '',
		emailDominio: formValues.email ? formValues.email.split('@')[1] : '',
		// foneResidencia: patternFormat(formValues.foneResidencia, '(##)####-####'),
		// foneComercial: patternFormat(formValues.foneComercial, '(##)####-####'),
		// celular: patternFormat(formValues.celular, '(##)#####-####'),
		// email: formValues.email.toUpperCase(),
		operadora: formValues.operadora,

		// Informações adicionais
		identificadoAssina: String(formValues.identificadoAssina),
		motivoFaltaAssinatura: !formValues.identificadoAssina
			? formValues.motivoFaltaAssinatura
			: '',
		carimboMaior65:
			calcularIdade(formValues.dataNascimento) >= 65
				? formValues.carimboMaior65
				: '',
		tipoSanguineo: formValues.tipoSanguineo,
		cid1: formValues.cid1,
		cid2: formValues.cid2,
		cid3: formValues.cid3,
		cid4: formValues.cid4,
		dataValidade:
			formValues.dataValidade !== ''
				? unformatDate(formValues.dataValidade)
				: '',

		// Dados adicionais para constar no RG
		inscricaoCpf:
			formValues.inscricaoCpf && formValues.inscricaoCpf === ''
				? 'false'
				: formValues.inscricaoCpf,
		numeroCpf: limparMascara(formValues.cpf).substr(0, 9),
		digitoCpf: limparMascara(formValues.cpf).substr(9, 2),
		// cpf: formValues.cpf,
		pis: formValues.pis,
		numeroPasep: formValues.pasep ? formValues.pasep.split('-')[0] : '',
		digitoPasep: formValues.pasep ? formValues.pasep.split('-')[1] : '',
		// pasep: formValues.pasep,
		dni: formValues.dni,
		tituloEleitor: formValues.tituloEleitor
			? limparMascara(formValues.tituloEleitor)
			: '',
		ctpsNumero: formValues.ctpsNum ? limparMascara(formValues.ctpsNum) : '',
		// ctpsNum: formValues.ctpsNum,
		ctpsSerie: formValues.ctpsSerie,
		ctpsSerieUf: formValues.ctpsSerieUf,
		certidaoMilitar: formValues.certidaoMilitar
			? limparMascara(formValues.certidaoMilitar)
			: '',
		cnh: formValues.cnh ? limparMascara(formValues.cnh) : '',
		categoriaCnh: formValues.categoriaCnh,
		doador: formValues.doador,
		nascSaude: formValues.nascSaude ? limparMascara(formValues.nascSaude) : '',
		identProf1: formValues.identProf1
			? limparMascara(formValues.identProf1)
			: '',
		orgaoEmissor1: formValues.orgaoEmissor1
			? limparMascara(formValues.orgaoEmissor1)
			: '',
		identProf2: formValues.identProf2
			? limparMascara(formValues.identProf2)
			: '',
		orgaoEmissor2: formValues.orgaoEmissor2
			? limparMascara(formValues.orgaoEmissor2)
			: '',
		identProf3: formValues.identProf3
			? limparMascara(formValues.identProf3)
			: '',
		orgaoEmissor3: formValues.orgaoEmissor3
			? limparMascara(formValues.orgaoEmissor3)
			: '',

		// SGU
		eventoEstatisticasSGU: formValues.eventoEstatisticasSGU,

		// Entrega do RG
		entrega: formValues.entrega ? formValues.entrega : '',
		dataHora:
			formValues.entrega === 'P' && formValues.data
				? new Date(
						`${unformatDate(formValues.data)} ${formValues.hora}`,
				  ).toISOString()
				: '',

		// Usuário da sessão
		usuario: login.mainframe.idUsuario,
		senha: login.mainframe.senhaMainFrame,
		cpfAtendente: login.user.cpf,
	};
}
