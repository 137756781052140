export enum Types {
	REGISTRA_SOLICITACOES_REQUEST = '@DETRAN-CRV/REGISTRA_SOLICITACOES_REQUEST',
	REGISTRA_SOLICITACOES_SUCCESS = '@DETRAN-CRV/REGISTRA_SOLICITACOES_SUCCESS',
	REGISTRA_SOLICITACOES_FAILURE = '@DETRAN-CRV/REGISTRA_SOLICITACOES_FAILURE',
	REGISTRA_SOLICITACOES_CLEAR = '@DETRAN-CRV/REGISTRA_SOLICITACOES_CLEAR',
}

export interface RegistraSolicitacoes {
	status: number;
	responseData: RegistraSolicitacoesData | null;
	requestData: RegistraSolicitacoesRequest | null;
}

export interface RegistraSolicitacoesRequest {
	request: {
		evento: {
			id_atendimento: string;
			id_cidadao: string;
			cpf: string;
			identificacao: string;
			ip: string;
			canal: {
				id: number;
				desc: string;
				localidade: {
					id: number;
					desc: string;
				};
			};
		};
		placa: string;
		renavam: string;
		cpfCnpj: string;
		opcao: 16 | 17 | 18;
		documentos?: UploadDocumentRegistraSolicitacoes[];
		servicoDigital: boolean;
		cpfSolicitante: string;
		nomeCidadao: string;
		emailCidadao: string;
		dddTelefoneCidadao: string;
		telefoneCidadao: string;
		ipAcesso: string;
		codUnidadeTransito: string;
		cpfUsuarioBU: string;
	};
	requestDate: string;
}

export interface RegistraSolicitacoesDataSearch {
	placa: string;
	renavam: string;
	cpfCnpj: string;
	opcao: 16 | 17 | 18;
	documentos?: UploadDocumentRegistraSolicitacoes[];
	servicoDigital: boolean;
	cpfSolicitante: string;
	nomeCidadao: string;
	emailCidadao: string;
	dddTelefoneCidadao: string;
	telefoneCidadao: string;
}

interface RegistraSolicitacoesData {
	codigo: number;
	mensagem: string;
	ano: number;
	ficha: number;
}

interface UploadDocumentRegistraSolicitacoes {
	conteudo: string;
	tipoArquivo: string;
}
