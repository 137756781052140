import React, { Suspense, useEffect } from 'react';

import { Col, Row, Skeleton } from 'antd';
import { Form, Formik, FormikProps } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultarFichaCadastralRequest } from 'store/modules/api/detranCrv/Processos/ConsultarFichaCadastral/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import Placa from 'components/Common/Form/Fields/Placa';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { initialValues, schema } from './form';

const ConsultarFichaCadastral: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { consultarFichaCadastral } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.processos,
	);

	useEffect(() => {
		if (consultarFichaCadastral.status === 200) {
			history.push('/detran-crv/consultar-ficha-cadastral/resultado');
		}
	}, [consultarFichaCadastral, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						dispatch(consultarFichaCadastralRequest(values.placa));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={8}>
										<Placa
											title="Placa"
											name="placa"
											defaultValue={initialValues.placa}
											required
											formik={formik}
										/>
									</Col>
								</Row>

								<br />

								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={formik.handleReset}
										/>
									</Col>

									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>

			<ButtonVoltar route="/detran-crv" />
		</Suspense>
	);
};

export default ConsultarFichaCadastral;
