import { GetCredentialsRequest, Types } from './types';

export function getCredentialsRequest(payload: GetCredentialsRequest) {
	return {
		type: Types.GET_CREDENTIALS_REQUEST,
		payload,
	};
}
export function getCredentialsSuccess(payload: object) {
	return {
		type: Types.GET_CREDENTIALS_SUCCESS,
		payload,
	};
}
export function getCredentialsFailure(payload: []): any {
	return {
		type: Types.GET_CREDENTIALS_FAILURE,
		payload,
	};
}
export function getCredentialsClear() {
	return {
		type: Types.GET_CREDENTIALS_CLEAR,
	};
}
