import React, { useEffect } from 'react';

import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch } from 'react-redux';
import { enderecosClear } from 'store/modules/api/utils/enderecos/actions';

// COMPONENTS
import ServiceList from 'components/Common/ServiceList';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { ROUTE_MINISTERIO_PUBLICO_RECONHECIMENTO_DE_PATERNIDADE } from '../routes/paths';

const Mp: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const services = [
		{
			title: '',
			services: [
				{
					name: 'Reconhecimento de Paternidade',
					path: ROUTE_MINISTERIO_PUBLICO_RECONHECIMENTO_DE_PATERNIDADE,
				},
			],
		},
	];

	useEffect(() => {
		dispatch(enderecosClear());
	}, [dispatch]);

	return (
		<Row>
			<Col span={24}>
				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h1>Escolha o Serviço</h1>
					</Col>
				</Row>

				<Row>
					<Col span={24}>{services && <ServiceList data={services} />}</Col>
				</Row>

				<Row>
					<Col span={24}>
						<ButtonVoltar
							navigate={false}
							onClick={() => {
								history.push('/menu-orgaos');
							}}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default Mp;
