import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarMunicipioIbgeRequest } from './types';

export function cadastrarMunicipioIbgeRequest(
	data: CadastrarMunicipioIbgeRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_MUNICIPIO_IBGE_REQUEST,
		payload: data,
	};
}
export function cadastrarMunicipioIbgeSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_MUNICIPIO_IBGE_SUCCESS,
		payload,
	};
}
export function cadastrarMunicipioIbgeFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_MUNICIPIO_IBGE_FAILURE,
		payload: null,
	};
}
export function cadastrarMunicipioIbgeClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_MUNICIPIO_IBGE_CLEAR,
		payload: null,
	};
}
