import { IConsultarAutorizacaoPrevia } from 'pages/DetranCrv/SolicitarDocumentos/ConsultarAutorizacaoPrevia/form';
import { Types } from './types';

export function consultarAutorizacaoPreviaRequest(
	payload: IConsultarAutorizacaoPrevia,
) {
	return {
		type: Types.CONSULTAR_AUTORIZACAO_PREVIA_REQUEST,
		payload,
	};
}
export function consultarAutorizacaoPreviaSuccess(payload: any) {
	return {
		type: Types.CONSULTAR_AUTORIZACAO_PREVIA_SUCCESS,
		payload,
	};
}
export function consultarAutorizacaoPreviaFailure(payload: []): any {
	return {
		type: Types.CONSULTAR_AUTORIZACAO_PREVIA_FAILURE,
		payload,
	};
}
export function consultarAutorizacaoPreviaClear(): any {
	return {
		type: Types.CONSULTAR_AUTORIZACAO_PREVIA_CLEAR,
	};
}
