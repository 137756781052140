import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100%;
`;

export const EnderecoRua = styled.div`
	width: 100%;
`;

export const Numero = styled.div`
	width: 80px;
`;

export const Divider = styled.div`
	margin: auto 3px;

	::before {
		content: '-';
	}
`;
