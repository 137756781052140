import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ExcluirSiglaRequest } from './types';

// ACTIONS
import { excluirSiglaSuccess, excluirSiglaFailure } from './actions';

function* excluirSigla(request: ReducerAction) {
	const { payload }: { payload: ExcluirSiglaRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_SGU,
		`siglas/${payload.id}`,
	);

	if (response.status === 200) {
		yield put(excluirSiglaSuccess(response));
	} else {
		yield put(excluirSiglaFailure());
	}
}

export default all([takeLatest(Types.EXCLUIR_SIGLA_REQUEST, excluirSigla)]);
