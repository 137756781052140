import axios from 'axios';

// REDUX
import { setLoader } from 'store/modules/app/loader/actions';
import { clearNotificationsValidate } from 'store/modules/app/notifications/actions';

// UTILS
import handleFailed from 'utils/getValidations';

const { REACT_APP_BASE_URL } = process.env;

const api = axios.create({
	baseURL: REACT_APP_BASE_URL,
});

export interface ApiResponse {
	status: number;
	data?: any;
	error?: boolean;
	message?: any;
}

export async function getApiECpf(
	project: string,
	token: string,
): Promise<ApiResponse> {
	clearNotificationsValidate();

	try {
		const response = await api.get(`${project}`, {
			headers: {
				Authorization: token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers': 'Authorization',
				'Access-Control-Allow-Methods':
					'GET, POST, OPTIONS, PUT, PATCH, DELETE',
				'Content-Type': 'application/json;charset=UTF-8',
			},
		});

		return response;
	} catch (error) {
		const errorResponse = await handleFailed(error);

		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			setLoader(false);
		}, 1000);
	}
}

export default api;
