import React from 'react';
import { Col, Row } from 'antd';

// COMPONENTS
import ColorBox from 'components/Common/ColorBox';

// UTILS
import { formatDate } from 'utils/genericFunctions';

// TYPES
import { ConsultaToxicologico } from 'store/modules/api/detranCnh/cidadao/consultaToxicologico/types';

interface AlertInvalidDrugTestProps {
	consultaToxicologicoResponse: ConsultaToxicologico;
}

export const AlertInvalidDrugTest = ({
	consultaToxicologicoResponse,
}: AlertInvalidDrugTestProps) => {
	return (
		<div style={{ marginTop: '20px' }}>
			<ColorBox bgColor="vermelho">
				<Row>
					<Col>
						<strong>DATA DA COLETA: </strong>
						{(consultaToxicologicoResponse.data &&
							formatDate(consultaToxicologicoResponse.data.dataColeta)) ||
							''}
					</Col>
				</Row>
				<Row>
					<Col>
						<strong>DATA DA VALIDADE: </strong>
						{(consultaToxicologicoResponse.data &&
							formatDate(consultaToxicologicoResponse.data.dataValidade)) ||
							''}
					</Col>
				</Row>
				<Row>
					<Col>
						<strong>DATA LIMITE EXAME MÉDICO: </strong>
						{(consultaToxicologicoResponse.data &&
							formatDate(
								consultaToxicologicoResponse.data.dataLimiteExameMedico,
							)) ||
							''}
					</Col>
				</Row>
				<Row>
					<Col>
						<strong>RESULTADO: </strong>
						{consultaToxicologicoResponse.data?.resultado || ''}
					</Col>
				</Row>
			</ColorBox>
		</div>
	);
};
