import produce from 'immer';
import { validOrgaos } from 'utils/validOrgaos';
import { ReducerAction } from '../../../types';
import { Types, ILoginUnicoState } from './types';

const { REACT_APP_ENVIRONMENT } = process.env;

export const INITIAL_STATE: ILoginUnicoState = {
	user: {
		idUsuario: '',
		cpf: '',
		nome: '',
		orgaos: null,
		login: true,
		parametrosSistema: [],
		perfil: {},
	},
	renovacaoMainframe: '',
	resetSenha: { status: false, message: '' },
	login: {
		mainframe: {
			idUsuario: '',
			senhaMainFrame: '',
		},
		user: {
			cpf: '',
			idLocal: 0,
			idOrgao: 0,
			idCanal: 0,
			descCanal: '',
			posto: '',
			digito_posto: '',
			nomePosto: '',
			ip: '',
			parametrosLocalidade: [],
			idLocalidade: 0,
		},
	},
	reload: false,
	tempoLogado: 0,
	lockedUrl: false,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ILoginUnicoState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.LOCKED_URL_REQUEST: {
				draft.lockedUrl = action.payload;
				break;
			}

			case Types.LOG_IN_REQUEST: {
				draft.renovacaoMainframe = action.payload.senha;
				break;
			}

			case Types.EXTENDER_TEMPO_PARA_INATIVIDADE: {
				draft.tempoLogado = new Date().getTime();
				break;
			}

			case Types.LOG_IN_SUCCESS: {
				draft.tempoLogado = new Date().getTime();
				draft.resetSenha = INITIAL_STATE.resetSenha;
				const newData: any = {
					idUsuario: action.payload.id,
					cpf: action.payload.cpf,
					nome: action.payload.nome,
					parametrosSistema: action.payload.parametrosSistema,
					permisaoUsuario: action.payload.permisaoUsuario,
					perfil: action.payload.perfil,
					orgaos: [],
				};

				action.payload?.localidades?.map((item: any) => {
					const findIndex = newData.orgaos.findIndex(
						(element: any) => element?.id === item.orgao.id,
					);

					if (findIndex !== -1) {
						if (item.orgao.id === 1 || item.orgao.id === 2) {
							newData.orgaos[findIndex].select.push({
								value: item.id,
								label: item.nome,
								posto: item.codigo,
								digito_posto: item.digito,
								nomePosto: item.nome,
								parametrosLocalidade: item.parametrosLocalidade,
							});
						} else {
							newData.orgaos[findIndex].select.push({
								value: item.id,
								label: item.nome,
								posto: item.codigo,
								digito_posto: item.digito,
								nomePosto: item.nome,
								parametrosLocalidade: item.parametrosLocalidade,
							});
						}
					} else if (item.orgao.id === 1 || item.orgao.id === 2) {
						newData.orgaos.push({
							...item.orgao,
							mainframe: true,
							localidades: [item],
							idOrgaoMainframe: item.id,
							select: [
								{
									value: item.id,
									label: item.nome,
									posto: item.codigo,
									digito_posto: item.digito,
									nomePosto: item.nome,
									parametrosLocalidade: item.parametrosLocalidade,
								},
							],
						});
					} else {
						newData.orgaos.push({
							...item.orgao,
							localidades: [item],
							mainframe: false,
							posto: item.codigo,
							digito_posto: item.digito,
							idOrgaoMainframe: item.id,
							select: [
								{
									value: item.id,
									label: item.nome,
									posto: item.codigo,
									digito_posto: item.digito,
									nomePosto: item.nome,
									parametrosLocalidade: item.parametrosLocalidade,
								},
							],
						});
					}
				});

				/* VALIDANDO SE O USUÁRIO TEM ACESSO AO ORGÃO 12 - INICIO */
				// REACT_APP_ENVIRONMENT DEV // HOMOLOG // PROD
				if (REACT_APP_ENVIRONMENT === 'PROD') {
					const newOrgaos: any[] = [];
					newData.orgaos.forEach((item: any) => {
						const repeatIndex = validOrgaos.findIndex((orgao: number) => {
							return item.id === orgao;
						});
						if (repeatIndex > -1) {
							newOrgaos.push({ ...item });
						}
					});

					const filteredData = {
						...newData,
						orgaos: [...newOrgaos],
					};

					if (newOrgaos.length < 1) {
						draft.user = { ...INITIAL_STATE.user, login: false };

						return;
					}
					draft.user = filteredData;
				} else {
					draft.user = newData;
				}
				/* VALIDANDO SE O USUÁRIO TEM ACESSO AO ORGÃO 12 - INICIO */
				break;
			}
			case Types.LOG_IN_FAILURE: {
				draft.login = INITIAL_STATE.login;
				draft.user = INITIAL_STATE.user;
				draft.resetSenha = INITIAL_STATE.resetSenha;
				break;
			}

			case Types.LOG_IN_CHANGE_PASSWORD: {
				draft.resetSenha = {
					status: true,
					message: action.payload.message,
				};
				break;
			}

			case Types.LOG_OFF_REQUEST: {
				draft.reload = INITIAL_STATE.reload;
				draft.login = INITIAL_STATE.login;
				draft.user = INITIAL_STATE.user;
				draft.resetSenha = INITIAL_STATE.resetSenha;
				break;
			}

			case Types.LOG_OFF_AND_RELOAD_REQUEST: {
				draft.reload = true;
				draft.login = INITIAL_STATE.login;
				draft.user = INITIAL_STATE.user;
				draft.resetSenha = INITIAL_STATE.resetSenha;
				break;
			}

			case Types.FIX_LOG_IN: {
				draft.login = action.payload;
				draft.resetSenha = INITIAL_STATE.resetSenha;
				break;
			}

			case Types.LIMPA_LOGIN: {
				draft.login.mainframe.idUsuario =
					INITIAL_STATE.login.mainframe.idUsuario;
				draft.login.mainframe.senhaMainFrame =
					INITIAL_STATE.login.mainframe.senhaMainFrame;
				draft.login.user.idLocal = INITIAL_STATE.login.user.idLocal;
				draft.login.user.idOrgao = INITIAL_STATE.login.user.idOrgao;
				draft.resetSenha = INITIAL_STATE.resetSenha;
				draft.renovacaoMainframe = INITIAL_STATE.renovacaoMainframe;
				break;
			}

			default:
		}
	});
}
