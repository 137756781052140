import { all } from 'redux-saga/effects';

import consultaTransferenciaEnderecoSaga from './consultaTransferenciaEndereco/saga';
import alteraTransferenciaEnderecoSaga from './alteraTransferenciaEndereco/saga';
import consultaFazendaSefazCep from './consultaFazendaSefazCep/saga';

export default all([
	consultaTransferenciaEnderecoSaga,
	alteraTransferenciaEnderecoSaga,
	consultaFazendaSefazCep,
]);
