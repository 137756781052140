import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100%;
`;

export const Latitude = styled.div`
	width: 300px;
`;

export const Longitude = styled.div`
	width: 170px;
`;

export const DividerBarra = styled.div`
	margin: auto 3px;

	::before {
		content: '/';
	}
`;

export const Cidade = styled.div`
	width: 100%;
`;

export const Uf = styled.div`
	width: 40px;
`;

export const Divider = styled.div`
	margin: auto 3px;

	::before {
		content: '-';
	}
`;
