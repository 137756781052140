import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_UTILS, CHANNEL } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import { listaCepSuccess, listaCepFailure } from './actions';

function* listaCepRequest(request: ReducerAction) {
	const { payload }: { payload: any } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_UTILS,
		`endereco/${CHANNEL}/lista-cep`,
		payload,
	);

	if (response.status === 200 || response.status === 202) {
		yield put(listaCepSuccess(response.data));
	} else {
		yield put(listaCepFailure([]));
	}
}

export default all([takeLatest(Types.LISTA_CEP, listaCepRequest)]);
