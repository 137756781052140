import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, DesbloqueiaCartao } from './types';

export const INITIAL_STATE: DesbloqueiaCartao = {
	status: 0,
	data: null,
};

export default function desbloqueiaCartao(
	state = INITIAL_STATE,
	action: ReducerAction,
): DesbloqueiaCartao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.DESBLOQUEIO_CARTAO: {
				break;
			}

			case Types.DESBLOQUEIO_CARTAO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload;
				break;
			}

			case Types.DESBLOQUEIO_CARTAO_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.LIMPAR_DESBLOQUEIO_CARTAO: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
