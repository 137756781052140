// ENTITIES
import { IFormCadastroRg } from 'pages/iirgd/RgFicDigital/CadastroRg/form';
// eslint-disable-next-line import/no-cycle
import {
	IFormInclusaoObsPrimeiraViaMenor,
	IFormInclusaoObsPrimeiraViaMaior,
	IFormInclusaoObsSegundaVia,
} from 'pages/iirgd/RgFicDigital/InclusaoObservacoes/Formularios/form';
import { IConsultarDare } from 'store/modules/api/dare/consultarDare/types';
import { IConsultaPesquisaCidadaoSelecionado } from '../consultaPesquisaCidadao/types';

export enum Types {
	CADASTRO_RG_CFG_NOVO = '@iirgd/CADASTRO_RG_CFG_NOVO',
	CADASTRO_RG_CFG_ACERVO = '@iirgd/CADASTRO_RG_CFG_ACERVO',
	CADASTRO_RG_CFG_EDICAO = '@iirgd/CADASTRO_RG_CFG_EDICAO',
	CADASTRO_RG_CFG_REEDICAO = '@iirgd/CADASTRO_RG_CFG_REEDICAO',

	CADASTRO_RG_PRIMEIRA_VIA = '@iirgd/CADASTRO_RG_PRIMEIRA_VIA',
	CADASTRO_RG_SEGUNDA_VIA = '@iirgd/CADASTRO_RG_SEGUNDA_VIA',

	CADASTRO_RG_DATA_NASCIMENTO = '@iirgd/CADASTRO_RG_DATA_NASCIMENTO',

	CADASTRO_RG_CONFIRMACAO_ATENDIMENTO = '@iirgd/CADASTRO_RG_CONFIRMACAO_ATENDIMENTO',

	CADASTRO_RG_ORIGINAL = '@iirgd/CADASTRO_RG_ORIGINAL',

	CADASTRAR_RG_REQUEST = '@iirgd/CADASTRAR_RG_REQUEST',
	CADASTRAR_RG_SUCCESS = '@iirgd/CADASTRAR_RG_SUCCESS',
	CADASTRAR_RG_FAILURE = '@iirgd/CADASTRAR_RG_FAILURE',

	ATUALIZAR_RG_REQUEST = '@iirgd/ATUALIZAR_RG_REQUEST',
	ATUALIZAR_RG_SUCCESS = '@iirgd/ATUALIZAR_RG_SUCCESS',
	ATUALIZAR_LOTE_SUCCESS = '@iirgd/ATUALIZAR_LOTE_SUCCESS',

	CADASTRO_RG_PRINCIPAL = '@iirgd/CADASTRO_RG_PRINCIPAL',

	CADASTRO_RG_OBS_PRIMEIRA_VIA_MENOR = '@iirgd/CADASTRO_RG_OBS_PRIMEIRA_VIA_MENOR',
	CADASTRO_RG_OBS_PRIMEIRA_VIA_MAIOR = '@iirgd/CADASTRO_RG_OBS_PRIMEIRA_VIA_MAIOR',
	CADASTRO_RG_OBS_SEGUNDA_VIA = '@iirgd/CADASTRO_RG_OBS_SEGUNDA_VIA',

	CADASTRO_RG_VINCULAR_DARE = '@iirgd/CADASTRO_RG_VINCULAR_DARE',

	CADASTRO_RG_CONFRONTO_DIGITAL = '@iirgd/CADASTRO_RG_CONFRONTO_DIGITAL',

	CADASTRO_RG_FINALIZADO = '@iirgd/CADASTRO_RG_FINALIZADO',

	CADASTRO_RG_PORTARIA_606 = '@iirgd/CADASTRO_RG_PORTARIA_606',
	CADASTRO_RG_PORTARIA_606_CLEAR = '@iirgd/CADASTRO_RG_PORTARIA_606_CLEAR',

	CADASTRO_RG_CLEAR = '@iirgd/CADASTRO_RG_CLEAR',
}

export interface ICadastroRgObservacoes {
	primeiraVia: {
		menor: IFormInclusaoObsPrimeiraViaMenor | null;
		maior: IFormInclusaoObsPrimeiraViaMaior | null;
	};
	segundaVia: IFormInclusaoObsSegundaVia | null;
}

export interface ICadastroRgState {
	config: {
		novo: boolean;
		acervo: boolean;
		edicao: boolean;
		reedicao: boolean;
	};
	portaria606: boolean;
	tipo: {
		primeiraVia: boolean;
		segundaVia: boolean;
		reusoParcial: boolean;
		reusoTotal: boolean;
	};
	info: {
		dataNascimento: string;
		idade: number;
		maior: boolean;
		lote: null | string;
	};
	perguntas: {
		todosDedos: boolean;
		coletarAssinatura: boolean;
		justificativaAssinatura: string;
		coletarFoto: boolean;
		justificativaFoto: string;
		teveAlteracao: boolean;
	};
	original: IFormCadastroRg | null;
	form: {
		principal: IFormCadastroRg | null;
		observacoes: ICadastroRgObservacoes;
	};
	dare: IConsultarDare | null;
	cadastrarRgSuccess: boolean;
	atualizarRgSuccess: boolean;
	finalizado: boolean;
	consultaPesquisaCidadaoSelecionado: IConsultaPesquisaCidadaoSelecionado | null;
	cadastrarRgFailure?: boolean;
	numRg?: number | null;
}
