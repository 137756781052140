import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types } from './types';
import { consultaCorSuccess, consultaCorFailure } from './actions';

function* consultaCorRequest() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consulta-cor`,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultaCorSuccess(response));
	} else if (response.error) {
		yield put(consultaCorFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTA_COR_REQUEST, consultaCorRequest),
]);
