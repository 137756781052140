import * as Yup from 'yup';

import { IRequestConsultaRenainfPlaca } from 'store/modules/api/detranCrv/MultasRenainf/multasRenainfPlaca/types';

export const initialValues: IRequestConsultaRenainfPlaca = {
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
	},
	placa: '',
	ufPlaca: '',
	dataInicio: '',
	dataFim: '',
	indicador: '',
};

export const schema = Yup.object<IRequestConsultaRenainfPlaca>().shape({
	placa: Yup.string().required(`Campo Obrigatório: Placa.`),
	dataInicio: Yup.string().required(
		`Campo Obrigatório: Período de Consulta - Início.`,
	),
	dataFim: Yup.string().required(
		`Campo Obrigatório: Período de Consulta - Fim.`,
	),
	indicador: Yup.string().required(
		`Campo Obrigatório: Indicador de Exigibilidade.`,
	),
});
