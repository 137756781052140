export enum Types {
	CONFRONTAR_DIGITAIS_REQUEST = '@cnh/CONFRONTAR_DIGITAIS_REQUEST',
	CONFRONTAR_DIGITAIS_SUCCESS = '@cnh/CONFRONTAR_DIGITAIS_SUCCESS',
	CONFRONTAR_DIGITAIS_FAILURE = '@cnh/CONFRONTAR_DIGITAIS_FAILURE',
	CONFRONTAR_DIGITAIS_CLEAR = '@cnh/CONFRONTAR_DIGITAIS_CLEAR',
}

export interface IConfrontarDigitaisState {
	qtdeFalha: number;
	resultado: boolean;
}

export interface IRequestConfrontarDigitais {
	cpfCidadao: string;
	imagemBiometria: string;
	numeroDedo: string;
}
