export enum Types {
	EDITA_CADASTRA_ENDERECO = '@MP/EDITA_CADASTRA_ENDERECO',
	EDITA_CADASTRA_ENDERECO_SUCCESS = '@MP/EDITA_CADASTRA_ENDERECO_SUCCESS',
	EDITA_CADASTRA_ENDERECO_FAILURE = '@MP/EDITA_CADASTRA_ENDERECO_FAILURE',
	LIMPAR_EDITA_CADASTRA_ENDERECO = '@MP/LIMPAR_EDITA_CADASTRA_ENDERECO',
}

export interface EditaCadastraEndereco {
	status: number;
	data: EditaCadastraEndereco | null;
}

interface Errors {
	idErro: string;
	dsErro: string;
	idControle: string;
	indiceControle: string;
}

export interface EditarCadastrarEndereco {
	cabecalho: {
		nomeTabela: string;
		versao: string;
		dataProcessamento: string;
		descricao: string;
		observacao: string;
		autor: string;
		qtdRegistros: string;
	};
	retorno: {
		idRetorno: string;
		dsRetorno: string;
	};
	erros: Errors[];
	tabelas: {};
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string | number;
	ip: string;
	canal: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface IRequestEditarCadastrarEndereco {
	cpf: string;
	idTipoEndereco: string;
	cep: string;
	tipoLogradouro: string;
	logradouro: string;
	numero: string;
	referencia: string;
	semNumero: string;
	complemento: string;
	bairro: string;
	municipio: string;
	uf: string;
	dataAtualizacao: string;
	email: string;
	foneCelular: string;
	foneFixo: string;
	latitude: string | null;
	longitude: string | null;
	tipo: string | null;
	idIbge: string | null;
	ip1: string;
	ip2: string;
	agentBroseSo: string;
	pagina: string;
	so: string;
	bro: string;
	usuarioAtualizacao: string | null;
	evento?: IEvento;
}
