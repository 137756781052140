import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarCadastroCidadaoRequest } from './types';

// ACTIONS
import {
	consultarCadastroCidadaoSuccess,
	consultarCadastroCidadaoFailure,
} from './actions';

function* cadastroCidadao(request: ReducerAction) {
	const { payload }: { payload: ConsultarCadastroCidadaoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`sockets/${CHANNEL}/consulta/cadastro-cidadao`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultarCadastroCidadaoSuccess(response));
	} else {
		yield put(consultarCadastroCidadaoFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_CADASTRO_CIDADAO_REQUEST, cadastroCidadao),
]);
