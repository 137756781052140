import React, { useCallback, useEffect, useState } from 'react';

import { Col, Row } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';
import {
	ROUTE_IIRGD_DARE_PESQUISA,
	ROUTE_IIRGD_CONFERENCIA_DADOS,
} from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import Section from 'components/Common/Section';
import PDFViewer from 'components/Common/PDFViewer';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// STYLED
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { NumeroDocumento } from './styled';

const Sucesso: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [PDFTitle, setPDFTitle] = useState<string>('');
	const [protocoloBase64, setProtocoloBase64] = useState<string>('');

	const { gerarDare } = useSelector((state: ApplicationState) => state.api);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	// ESTATÍSTICA DARE GERADA
	useEffect(() => {
		if (gerarDare.status === 200 && gerarDare?.data) {
			const payload: IncluirRequest = {
				descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
				tipoDocumento: 'RG',
				numeroDocumento: cadastroRg.form.principal?.numeroRg ?? '',
				codigoLocalidade: Number(login.user.posto),
				idOrgao: login.user.idOrgao,
				cpf: login.user.cpf,
				descricaoServico: 'VINCULACAO_DARE',
				idMotivo: 16,
				numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
				observacao: gerarDare.data.numeroControleGuiaMae.toString() ?? '',
			};
			dispatch(estatisticaIncluirRequest(payload));
		}
	}, [cadastroRg, dispatch, gerarDare, login, atendimento]);

	useEffect(() => {
		if (!cadastroRg.form.principal || !gerarDare.data) {
			history.push(ROUTE_IIRGD);
		}
	}, [cadastroRg.form.principal, gerarDare.data, history]);

	const handleImprimir = useCallback(() => {
		if (gerarDare.data) {
			const { numeroControleGuiaMae, boletoRes } = gerarDare.data;

			setPDFTitle(`Número do documento: ${numeroControleGuiaMae}`);
			setProtocoloBase64(boletoRes.boletoBase64);
		}
	}, [gerarDare.data]);

	const handleOnClose = useCallback(() => {
		setPDFTitle('');
		setProtocoloBase64('');
	}, []);

	const handleAvancar = useCallback(() => {
		history.push(ROUTE_IIRGD_CONFERENCIA_DADOS);
	}, [history]);

	const handleButtonVoltar = useCallback(() => {
		history.push(ROUTE_IIRGD_DARE_PESQUISA);
	}, [history]);

	const getTitle = useCallback(() => {
		const hasTaxaCorreio = cadastroRg.dare?.taxasRes.filter(
			(item: any) =>
				item.servicoTipo === 1300058 || item.servicoTipo === 1200058,
		);
		if (hasTaxaCorreio && !cadastroRg.tipo.segundaVia) {
			return 'Entrega via correio - Sedex';
		}
		return 'Dare';
	}, [cadastroRg]);

	return (
		<>
			<Section title={getTitle()}>
				{gerarDare.data && (
					<>
						<Row>
							<Col span={24}>
								<Row gutter={[0, 10]}>
									<Col span={24} />
								</Row>
								<Row gutter={[0, 10]} justify="center">
									<Col style={{ textAlign: 'center' }}>
										<h1>DARE gerada com sucesso!</h1>

										<NumeroDocumento>
											{`Número do documento: ${gerarDare.data.numeroControleGuiaMae}`}
										</NumeroDocumento>
									</Col>
								</Row>

								<Row gutter={[0, 10]} justify="end">
									<Col>
										<ButtonImage src="imprimir" onClick={handleImprimir} />
									</Col>

									<Col style={{ marginLeft: '16px' }}>
										<ButtonImage src="avancar" onClick={handleAvancar} />
									</Col>
								</Row>
							</Col>
						</Row>

						{PDFTitle && protocoloBase64 && (
							<PDFViewer
								title={PDFTitle}
								source={protocoloBase64}
								onClose={handleOnClose}
							/>
						)}
					</>
				)}
			</Section>

			<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
		</>
	);
};

export default Sucesso;
