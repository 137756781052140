export enum Types {
	CONFIRMA_PRE_CADASTRO_RENOVACAO = '@cnh/CONFIRMA_PRE_CADASTRO_RENOVACAO',
	CONFIRMA_PRE_CADASTRO_RENOVACAO_SUCCESS = '@cnh/CONFIRMA_PRE_CADASTRO_RENOVACAO_SUCCESS',
	CONFIRMA_PRE_CADASTRO_RENOVACAO_FAILURE = '@cnh/CONFIRMA_PRE_CADASTRO_RENOVACAO_FAILURE',
}

export interface confirmaPreCadastroRenovacao {
	status: number;
	// data: IDadosRenovacaoCnh | null;
}

export interface IRequestPreCadastroRenovacaoCnh {
	autorizaEmail: string;
	autorizaSMS: string;
	bairro: string;
	categoriaAtual: string;
	categoriaPretendida: string;
	telefone: string;
	celular: string;
	cep: string;
	cnhDigital: string;
	codigoEntidade: string;
	// codigoPoupatempo: string | number;
	codigoVerificador: string;
	complemento: string;
	cpf: string;
	// cpfUsuario: string;
	dataNascimento: string;
	dataPrimeiraHabilitacao: string;
	dddCelular: string;
	dddTelefone: string;
	email: string;
	logradouro: string;
	nacionalidade: string;
	naturalidade: string;
	necessidadeVeiculoAdaptado: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	numCarteiraMilitar: string;
	numDocumento: string;
	numPGU: string;
	// numRNE: string;
	numRegistro: string;
	numero: string;
	orgaoDocumento: string;
	orgaoRNE: string;
	pretendeAtividadeRemunerada: string;
	sexo: string;
	tipoDocumento: string;
	ufDocumento: string;
	ufRNE: string;
}
