import { ReducerAction } from 'store/modules/types';

import { Types, LogonRestRequest } from './types';

export function logonRestRequest(data: LogonRestRequest): ReducerAction {
	return {
		type: Types.LOGON_REST_REQUEST,
		payload: data,
	};
}
export function logonRestSuccess(payload: object): ReducerAction {
	return {
		type: Types.LOGON_REST_SUCCESS,
		payload,
	};
}
export function logonRestFailure(payload: object): ReducerAction {
	return {
		type: Types.LOGON_REST_FAILURE,
		payload,
	};
}

export function logonRestClear(payload: object): ReducerAction {
	return {
		type: Types.LOGON_REST_CLEAR,
		payload,
	};
}
