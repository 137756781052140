import React, { useEffect, useState } from 'react';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { PrestacoesAtField } from 'store/modules/api/cdhu/consultarPrestacoesAtrasadas/types';

// COMPONENTS
import SimpleTable from 'components/Common/Table';

// UTILS
import { formatCurrency, formatStringNumber } from 'utils/numberFunctions';

interface Props {
	enabled: boolean;
	maxRows: number | undefined;
}

const TablePrestacoes: React.FC<Props> = ({ enabled, maxRows }) => {
	const [prestacoes, setPrestacoes] = useState<PrestacoesAtField[]>([]);
	const [totalPrestacoes, setTotalPrestacoes] = useState<number>(0);

	const { consultarPrestacoesAtrasadas, gerarOcorrencia } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	const columns = [
		{
			dataIndex: 'numeroPrestacaoField',
			title: 'Prestação nº',
			align: 'center',
			render: (text: PrestacoesAtField, record: PrestacoesAtField) => {
				return record.numeroPrestacaoField;
			},
		},
		{
			dataIndex: 'dataVencimentoField',
			title: 'Vencimento',
			align: 'center',
			render: (text: PrestacoesAtField, record: PrestacoesAtField) => {
				return record.dataVencimentoField;
			},
		},
		{
			dataIndex: 'valorAtualizadoField',
			title: 'Valor',
			align: 'center',
			render: (text: PrestacoesAtField, record: PrestacoesAtField) => {
				return formatCurrency(record.valorAtualizadoField);
			},
		},
	];

	useEffect(() => {
		if (
			enabled &&
			maxRows &&
			consultarPrestacoesAtrasadas.data &&
			consultarPrestacoesAtrasadas.data.prestacoesPRD !== undefined &&
			consultarPrestacoesAtrasadas.data.prestacoesAtField !== undefined &&
			gerarOcorrencia.calculoBoletoAgrupado
		) {
			const { prestacoesPRD, prestacoesAtField } =
				consultarPrestacoesAtrasadas.data;

			const { prds, parcelasEmAtraso } = gerarOcorrencia.calculoBoletoAgrupado;

			let tempPrestacoes: PrestacoesAtField[] = [];

			if (prds) {
				tempPrestacoes = tempPrestacoes.concat(prestacoesPRD);
			}

			if (parcelasEmAtraso) {
				tempPrestacoes = tempPrestacoes.concat(prestacoesAtField);
			}

			setPrestacoes(tempPrestacoes.slice(0, maxRows));
			return;
		}

		setPrestacoes([]);
	}, [
		enabled,
		maxRows,
		gerarOcorrencia.calculoBoletoAgrupado,
		consultarPrestacoesAtrasadas,
	]);

	useEffect(() => {
		if (enabled && maxRows && prestacoes.length > 0) {
			const valoresAtualizados = prestacoes.map(prestacao =>
				formatStringNumber(prestacao.valorAtualizadoField),
			);

			const valorTotal = valoresAtualizados.slice(0, maxRows).reduce((a, b) => {
				return a + b;
			});

			setTotalPrestacoes(valorTotal);

			return;
		}

		setTotalPrestacoes(0);
	}, [enabled, maxRows, prestacoes]);

	return (
		<>
			{prestacoes.length > 0 && (
				<>
					<SimpleTable
						headers={columns}
						body={prestacoes}
						size="sm"
						striped
						bold
						hover={false}
					/>

					<div style={{ textAlign: 'center' }}>
						<span>Valor das prestações atrasadas selecionadas:</span>
						<strong style={{ marginLeft: '8px' }}>
							{formatCurrency(totalPrestacoes)}
						</strong>
					</div>
				</>
			)}
		</>
	);
};

export default TablePrestacoes;
