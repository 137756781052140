// import { number } from 'yup';

export enum Types {
	CADASTRO_EVENTO_REQUEST = '@contabilizacao/CADASTRO_EVENTO_REQUEST',
	CADASTRO_EVENTO_SUCCESS = '@contabilizacao/CADASTRO_EVENTO_SUCCESS',
	CADASTRO_EVENTO_FAILURE = '@contabilizacao/CADASTRO_EVENTO_FAILURE',
	CADASTRO_EVENTO_CLEAR = '@contabilizacao/CADASTRO_EVENTO_CLEAR',
}

export interface ICadastroEvento {
	status: null | number;
	data: any;
	dadosEvento: null | ICadastroEventoRequest;
}

export interface IDadosRequest {
	data_hora: string;
	id_servico: string | number;
	desc_servico: string;
	identificacao: string | number;
	cpf: string;
	ip: string;
	id_orgao: number;
	desc_orgao: string;
	id_localidade: number;
	desc_localidade: string;
}

export interface ICadastroEventoRequest {
	id_atendimento: string;
	tipo: string;
	id_cidadao: string;
	data: number;
	dados: IDadosRequest | string;
	canal: {
		id: string;
		descricao: string;
	};
}
