import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types } from './types';
import {
	consultarDetalhesAutorizacaoPreviaSuccess,
	consultarDetalhesAutorizacaoPreviaFailure,
} from './actions';

function* consultarDetalhesAutorizacaoPreviaRequest(request: {
	type: string;
	payload: any;
}) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/detalhes-autorizacoes-previas`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(consultarDetalhesAutorizacaoPreviaSuccess(response));
	} else if (response.error) {
		yield put(consultarDetalhesAutorizacaoPreviaFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_REQUEST,
		consultarDetalhesAutorizacaoPreviaRequest,
	),
]);
