export enum Types {
	VERIFICAR_VERSAO_SERVICO_BIOMETRIA_REQUEST = '@biometria/VERIFICAR_VERSAO_SERVICO_BIOMETRIA_REQUEST',
	VERIFICAR_VERSAO_SERVICO_BIOMETRIA_OLD = '@biometria/VERIFICAR_VERSAO_SERVICO_BIOMETRIA_OLD',
	VERIFICAR_VERSAO_SERVICO_BIOMETRIA_NEW = '@biometria/VERIFICAR_VERSAO_SERVICO_BIOMETRIA_NEW',
	VERIFICAR_VERSAO_SERVICO_BIOMETRIA_CLEAR = '@biometria/VERIFICAR_VERSAO_SERVICO_BIOMETRIA_CLEAR',
}

export interface VerificarVersaoServicoBiometria {
	status: number;
	data: VersaoServicoBiometria;
}

interface VersaoServicoBiometria {
	versao: '' | 'OLD' | 'NEW';
}
