export const customEnums = {
	tipo: [
		{
			label: 'Residencial',
			value: '1',
		},
		{
			label: 'Comercial',
			value: '2',
		},
		{
			label: 'Celular',
			value: '3',
		},
		{
			label: 'Recados',
			value: '4',
		},
	],
};
