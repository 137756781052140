export enum Types {
	CONSULTAR_UMA_FUNCIONALIDADE = '@SGU/CONSULTAR_UMA_FUNCIONALIDADE',
	CONSULTAR_UMA_FUNCIONALIDADE_SUCCESS = '@SGU/CONSULTAR_UMA_FUNCIONALIDADE_SUCCESS',
	CONSULTAR_UMA_FUNCIONALIDADE_FAILURE = '@SGU/CONSULTAR_UMA_FUNCIONALIDADE_FAILURE',
	CONSULTAR_UMA_FUNCIONALIDADE_CLEAR = '@SGU/CONSULTAR_UMA_FUNCIONALIDADE_CLEAR',
}

export interface ConsultarUmaFuncionalidade {
	status: number;
	data: null | ConsultarUmaFuncionalidadeData;
}

export interface LocalidadeAssociado {
	id: number;
	idLocalidadeFuncionalidade: number;
	nome: string;
	orgao: string;
}

export interface ConsultarUmaFuncionalidadeData {
	id?: number | string;
	descricao: string;
	localidadesAssociados?: LocalidadeAssociado[];
}

export interface ConsultarUmaFuncionalidadeRequest {
	id?: number;
}
