import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { cleanEmpty } from 'utils/genericFunctions';
import { ReducerAction } from 'store/modules/types';
import { RequestConsultarMenus, Types } from './types';

// ACTIONS
import { consultarMenusSuccess, consultarMenusFailure } from './action';

function* consultarMenus(request: ReducerAction) {
	const { payload }: { payload: RequestConsultarMenus } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as RequestConsultarMenus;

	const response: ApiResponse = yield call(getApi, PATH_SGU, `menu`, body);

	if (response.status === 200) {
		yield put(consultarMenusSuccess(response));
	} else if (response.error) {
		yield put(consultarMenusFailure());
	}
}

export default all([takeLatest(Types.CONSULTAR_MENUS, consultarMenus)]);
