import produce from 'immer';

// TYPES
import { Types, ExcluirTipos } from './types';
import { ReducerAction } from '../../../../../../types';

export const INITIAL_STATE: ExcluirTipos = {
	status: 0,
	data: null,
};

export default function excluirTiposReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ExcluirTipos {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EXCLUIR_TIPOS: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.EXCLUIR_TIPOS_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.EXCLUIR_TIPOS_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.EXCLUIR_TIPOS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
