export enum Types {
	LOGON_REST_REQUEST = '@cnh/LOGON_REST_REQUEST',
	LOGON_REST_SUCCESS = '@cnh/LOGON_REST_SUCCESS',
	LOGON_REST_FAILURE = '@cnh/LOGON_REST_FAILURE',
	LOGON_REST_CLEAR = '@cnh/LOGON_REST_CLEAR',
}

export interface LogonRest {
	status: number;
	mensagem: string;
}

export interface LogonRestRequest {
	usuario: string;
	senha: string;
	enderecoIp: string;
}
