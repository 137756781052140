// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	ConsultaPesquisaCivilRequest,
	Types,
	ConsultaPesquisaCivilData,
} from './types';

export function consultaPesquisaCivilRequest(
	payload: ConsultaPesquisaCivilRequest,
) {
	return {
		type: Types.CONSULTA_PESQUISA_CIVIL_REQUEST,
		payload,
	};
}
export function consultaPesquisaCivilSuccess(
	payload: ConsultaPesquisaCivilData[],
) {
	return {
		type: Types.CONSULTA_PESQUISA_CIVIL_SUCCESS,
		payload,
	};
}
export function consultaPesquisaCivilFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIVIL_FAILURE,
		payload: null,
	};
}

export function consultaPesquisaCivilClear(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIVIL_CLEAR,
		payload: null,
	};
}
