import { ReducerAction } from '../types';

import { Types, ConsultaMainframeRequest } from './types';

export function pesquisaNaturalidadeRequest(data: any): ReducerAction {
	return {
		type: Types.PESQUISA_NATURALIDADE_REQUEST,
		payload: data,
	};
}
export function pesquisaNaturalidadeSuccess(payload: []): ReducerAction {
	return {
		type: Types.PESQUISA_NATURALIDADE_SUCCESS,
		payload,
	};
}
export function pesquisaNaturalidadeFailure(payload: []): ReducerAction {
	return {
		type: Types.PESQUISA_NATURALIDADE_FAILURE,
		payload,
	};
}
export function pesquisaNaturalidadeClear(): ReducerAction {
	return {
		type: Types.PESQUISA_NATURALIDADE_CLEAR,
		payload: null,
	};
}

export function listaCep(data: any): ReducerAction {
	return {
		type: Types.LISTA_CEP,
		payload: data,
	};
}
export function listaCepSuccess(payload: []): ReducerAction {
	return {
		type: Types.LISTA_CEP_SUCCESS,
		payload,
	};
}
export function listaCepFailure(payload: []): ReducerAction {
	return {
		type: Types.LISTA_CEP_FAILURE,
		payload,
	};
}

export function consultaMainframe(
	data: ConsultaMainframeRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_MAINFRAME,
		payload: data,
	};
}
export function consultaMainframeSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_MAINFRAME_SUCCESS,
		payload,
	};
}
export function consultaMainframeFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_MAINFRAME_FAILURE,
		payload,
	};
}
export function limpaMainframe(): ReducerAction {
	return {
		type: Types.LIMPA_MAINFRAME,
		payload: null,
	};
}
