import { CertificateValidateRequest, Types } from './types';

export function certificateValidateRequest(
	payload: CertificateValidateRequest,
) {
	return {
		type: Types.CERTIFICATE_VALIDATE_REQUEST,
		payload,
	};
}
export function certificateValidateSuccess(payload: object) {
	return {
		type: Types.CERTIFICATE_VALIDATE_SUCCESS,
		payload,
	};
}
export function certificateValidateFailure(payload: []): any {
	return {
		type: Types.CERTIFICATE_VALIDATE_FAILURE,
		payload,
	};
}
export function certificateValidateClear() {
	return {
		type: Types.CERTIFICATE_VALIDATE_CLEAR,
	};
}
