import {
	IConsultaResumoExtratoForm,
	IConsultaResumoExtratoOutrasBasesRequestType,
} from 'store/modules/api/detranCrv/ConsultarResumoExtrato/types';

import * as Yup from 'yup';

export const initialValues: IConsultaResumoExtratoForm = {
	placa: '',
	chassi: '',
	documento: '',
	renavam: '',
	selecao: 'placa',
	ufVeiculo: 'SP',
};

export function treatValues(
	values: IConsultaResumoExtratoForm,
): IConsultaResumoExtratoOutrasBasesRequestType {
	const { placa, chassi, renavam, selecao, ufVeiculo } = values;

	const tipoSelecao = {
		placa: { ptres: 1, pebe: 1 },
		renavam: {
			ptres: 3,
			pebe: 3,
		},
		chassi: {
			ptres: 2,
			pebe: 2,
		},
		none: { ptres: 0, pebe: 0 },
	};

	const tipoSelecionado = tipoSelecao[selecao || 'none'];

	return {
		ptres: {
			tipoPesquisa: tipoSelecionado.ptres,
			chassi,
			placa,
			renavam: renavam || '',
			ufVeiculo,
			marcaVeiculo: null,
			anoFabricacao: null,
		},
		pebe: {
			tipoPesquisa: tipoSelecionado.pebe,
			chassi,
			placa,
			ufVeiculo,
		},
	};
}

export const schema = Yup.object<IConsultaResumoExtratoForm>().shape({
	validatePlaca: Yup.string().when(['placa', 'selecao'], {
		is: (placa, selecao) =>
			selecao === 'placa' &&
			(placa === '' || (placa !== '' && placa.length !== 7)),
		then: Yup.string().required(`Campo Obrigatório: Placa.`),
	}),
	validateChassi: Yup.string().when(['chassi', 'selecao'], {
		is: (chassi, selecao) =>
			selecao === 'chassi' &&
			(chassi === '' || (chassi !== '' && chassi.length < 17)),
		then: Yup.string().required(`Campo Obrigatório: Chassi.`),
	}),
});
