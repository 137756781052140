// TYPES
import { ReducerAction } from 'store/modules/types';
import { PesquisaNominal } from '../pesquisaNominal/types';
import { DataConsultaAtestado, Types } from './types';

export function consultaAtestadoNominalRequest(
	payload: PesquisaNominal,
): ReducerAction {
	return {
		type: Types.CONSULTA_ATESTADO_NOMINAL_REQUEST,
		payload,
	};
}

export function consultaAtestadoNominalSuccess(
	payload: DataConsultaAtestado,
): ReducerAction {
	return {
		type: Types.CONSULTA_ATESTADO_NOMINAL_SUCCESS,
		payload,
	};
}

export function consultaAtestadoNominalFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_ATESTADO_NOMINAL_FAILURE,
		payload: null,
	};
}

export function limpaConsultaNominal(): ReducerAction {
	return {
		type: Types.LIMPA_CONSULTA_NOMINAL,
		payload: null,
	};
}
