import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Col, Row } from 'antd';

import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import SimpleTable from 'components/Common/Table';
import Select from 'components/Common/Form/Select';
import Alert from 'components/Common/Notifications/Alert';

import { useHistory } from 'react-router-dom';
import { ROUTE_SECRETARIA_EDUCACAO_CANCELAMENTO_FICHA_ALUNO } from 'pages/EducacaoUpdate/routes/paths';

import { useDispatch, useSelector } from 'react-redux';
import data from 'store/modules/enum';
import {
	consultarInscricaoRequest,
	selecionarInscricao,
	consultarInscricaoClear,
} from 'store/modules/api/educacaoUpdate/inscricao/consultarInscricao/actions';
import {
	consultaFichaAlunoClean,
	consultaFichaAlunoRequest,
} from 'store/modules/api/educacaoUpdate/fichaAluno/consultaFichaAluno/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { ApplicationState } from 'store';
import { FaSistrix } from 'react-icons/fa';
import { IConsultaInscricao, initialValues, schema } from './form';

import { Container, DividerBarra, Divider } from './styled';

interface dataConsultaInscricaoFicha {
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
}

const PesquisarAluno: React.FC = () => {
	const [dataConsulta, setDataConsulta] = useState<dataConsultaInscricaoFicha>({
		digitoRA: '',
		numeroRA: '',
		siglaUFRA: '',
	});

	const [notificationError, setNotificationError] = useState<boolean>(false);

	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);

	const { consultaFichaAluno } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.fichaAluno,
	);

	const { consultarInscricao } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.inscricao,
	);

	const handleSubmit = (values: IConsultaInscricao) => {
		const { digitoRA, numeroRA, siglaUF, anoLetivo } = values;
		dispatch(
			consultarInscricaoRequest(token, {
				digitoRA: Number(digitoRA),
				numeroRA: Number(numeroRA),
				siglaUFRA: siglaUF,
				anoLetivo: Number(anoLetivo),
			}),
		);
		setDataConsulta({
			digitoRA,
			numeroRA,
			siglaUFRA: siglaUF,
		});

		setNotificationError(false);
	};

	const tratamentoRetornoInscricao = useCallback(
		(index: number) => {
			if (consultarInscricao.data?.inscricoes) {
				dispatch(
					selecionarInscricao(consultarInscricao.data.inscricoes[index]),
				);
				history.push(ROUTE_SECRETARIA_EDUCACAO_CANCELAMENTO_FICHA_ALUNO);
			}
		},
		[consultarInscricao, dispatch, history],
	);

	useEffect(() => {
		if (consultarInscricao.status === 204) {
			setNotificationError(true);
			dispatch(consultarInscricaoClear());
		}
		if (consultarInscricao.status === 200 && consultaFichaAluno.status === 0) {
			dispatch(consultaFichaAlunoRequest(token, dataConsulta));
		}

		if (consultaFichaAluno.status === 200) {
			if (consultarInscricao.data) {
				if (consultarInscricao.data?.inscricoes?.length === 1) {
					tratamentoRetornoInscricao(0);
				}
			}
		}
	}, [
		dispatch,
		consultarInscricao,
		dataConsulta,
		consultaFichaAluno,
		tratamentoRetornoInscricao,
		token,
	]);

	const filtrarIncricaoAluno = (index: number) => {
		tratamentoRetornoInscricao(index);
	};

	const handleCleanNotification = useCallback(() => {
		setNotificationError(false);
	}, []);

	const headers = [
		{
			title: 'Tipo Inscrição',
			dataIndex: 'descricaoTipo',
		},
		{
			title: 'Situação',
			dataIndex: 'situacao',
		},
		{
			title: 'Data Inscrição',
			dataIndex: 'data',
		},
		{
			title: 'Visualizar',
			dataIndex: 'type',
			render: (text: any, record: any, index: number) => {
				return (
					<>
						<FaSistrix size={20} onClick={() => filtrarIncricaoAluno(index)} />
					</>
				);
			},
		},
	];

	useEffect(() => {
		dispatch(consultaFichaAlunoClean());
	}, [dispatch]);

	return (
		<>
			<Section title="Pesquisar Inscrição">
				{notificationError && (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Alert
								type="warning"
								message="INSCRIÇÕES NÃO ENCONTRADAS"
								onClose={handleCleanNotification}
							/>
						</Col>
					</Row>
				)}
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => handleSubmit(values)}
				>
					{formik => (
						<Form autoComplete="off">
							<Row gutter={[0, 10]}>
								<Container>
									<Col span={6}>
										<Field
											as={Input}
											maxLength="12"
											title="RA"
											name="numeroRA"
											error={hasError(formik.errors, 'numeroRA')}
										/>
									</Col>
									<Divider />
									<Col span={1}>
										<Field
											as={Input}
											maxLength="2"
											name="digitoRA"
											error={hasError(formik.errors, 'digitoRA')}
										/>
									</Col>
									<DividerBarra />
									<Col span={3}>
										<Field
											as={Select}
											defaultFirstOption={false}
											name="siglaUF"
											options={data.uf}
											onChange={(v: string) => {
												formik.setFieldValue('siglaUF', v);
											}}
											error={hasError(formik.errors, 'siglaUF')}
										/>
									</Col>
								</Container>
							</Row>
							<Row gutter={[0, 10]}>
								<Col span={5}>
									<Field
										as={InputMask}
										mask="9999"
										title="Ano Letivo"
										name="anoLetivo"
										error={hasError(formik.errors, 'anoLetivo')}
									/>
								</Col>
							</Row>

							<Row gutter={[0, 10]} justify="center">
								<Col span={4}>
									<ButtonImage
										onClick={() => {
											formik.handleReset();
											setNotificationError(false);
											dispatch(clearNotifications());
										}}
										type="button"
										src="limpar"
									/>
								</Col>
								<Col span={4}>
									<ButtonImage type="submit" src="pesquisar" />
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</Section>
			<ButtonVoltar />
			{consultarInscricao.data?.inscricoes &&
				consultarInscricao.data.inscricoes.length > 1 && (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<SimpleTable
								headers={headers}
								body={consultarInscricao.data.inscricoes}
							/>
						</Col>
					</Row>
				)}
		</>
	);
};

export default PesquisarAluno;
