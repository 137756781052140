export enum Types {
	ALTERAR_OBSERVACOES_VALUES_TEMP = '@iirgd/ALTERAR_OBSERVACOES_VALUES_TEMP',

	ALTERAR_OBSERVACOES_REQUEST = '@iirgd/ALTERAR_OBSERVACOES_REQUEST',
	ALTERAR_OBSERVACOES_SUCCESS = '@iirgd/ALTERAR_OBSERVACOES_SUCCESS',
	ALTERAR_OBSERVACOES_FAILURE = '@iirgd/ALTERAR_OBSERVACOES_FAILURE',
	ALTERAR_OBSERVACOES_CLEAR = '@iirgd/ALTERAR_OBSERVACOES_CLEAR',
}

export interface IAlterarObservacoesState {
	status: number;
	temp: RequestAlterarObservacoes | null;
	cpfCadastrado: boolean;
	mensagemCPF?: null | string;
}

export interface RequestAlterarObservacoes {
	numeroRg: string;
	lote: string;
	rgForaSp: string | boolean;
	declaracaoPobreza: string | boolean;
	solicitarBloqueio: string | boolean;
	termoRecolhimentoRg: string | boolean;
	rgRecolhidoEmissao: string;
	rgRecolhidoIdentific: string;
	rgRecolhidoPor: string;
	pagto2Via: string;
	isento2Via062: string | boolean;
	isento2Via10952: string | boolean;
	ctpsObs: string;
	srObs: string;
	estObs: string;
	termoTutela: string | boolean;
	tutelaProc: string;
	tutelaAno: string;
	tutelaData: string;
	observacao: string;
	resideSpAnos: string;
	resideSpMeses: string;
	moraCom: string;
	temParentesSp: string | boolean;
	grauParentescoSp: string;
	ocupacaoPrincipalSp: string;
	rgForaSpUf: string;
	rgForaSpNum: string;
	rgForaSpEmissao: string;
	cptsNum: string;
	ctpsSerie: string;
	ctpsSerieUf: string;
	cnh: string;
	tituloEleitor: string;
	reservista: string;
	classeProfissional: string;
	carteiraFuncional: string;
	usuario: string;
	senha: string;
	cr: boolean;
	tipoDocumentoResponsavel: string;
	numeroDocumentoResponsavel: string;
	ufDocumentoResponsavel: string;
	dataExpedicaoDocumentoResponsavel: string;
	inscricaoCpf: boolean;
	cpfAtendente: string;
	entrega: string | number | undefined;
	isencaoPrimeiraViaCin: boolean;
	temCin?: boolean;
}
