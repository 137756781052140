import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, putApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { Types, IReenviarDocumentosRequest } from './types';
import {
	reenviarDocumentosSuccess,
	reenviarDocumentosFailure,
} from './actions';

function* reenviarDocumentosRequest(request: ReducerAction) {
	const { payload }: { payload: IReenviarDocumentosRequest } = request;

	const response: ApiResponse = yield call(
		putApi,
		PATH_DETRAN_CRV,
		`fichas/${CHANNEL}/reenviar-documentos`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(reenviarDocumentosSuccess(response));
	} else if (response.error) {
		yield put(reenviarDocumentosFailure(response));
	}
}

export default all([
	takeLatest(Types.REENVIAR_DOCUMENTOS_REQUEST, reenviarDocumentosRequest),
]);
