// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function verificarVersaoServicoBiometriaRequest(): ReducerAction {
	return {
		payload: null,
		type: Types.VERIFICAR_VERSAO_SERVICO_BIOMETRIA_REQUEST,
	};
}
export function verificarVersaoServicoBiometriaOld(): ReducerAction {
	return {
		type: Types.VERIFICAR_VERSAO_SERVICO_BIOMETRIA_OLD,
		payload: null,
	};
}
export function verificarVersaoServicoBiometriaNew(): ReducerAction {
	return {
		type: Types.VERIFICAR_VERSAO_SERVICO_BIOMETRIA_NEW,
		payload: null,
	};
}
export function verificarVersaoServicoBiometriaClear(): ReducerAction {
	return {
		type: Types.VERIFICAR_VERSAO_SERVICO_BIOMETRIA_CLEAR,
		payload: null,
	};
}
