import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RequestConsultaNomeSocialFiliacao } from './types';

// ACTIONS
import {
	consultaNomeSocialFiliacaoSuccess,
	consultaNomeSocialFiliacaoFailure,
} from './actions';

function* consultaNomeSocialFiliacao(request: ReducerAction) {
	const { payload }: { payload: RequestConsultaNomeSocialFiliacao } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`eportal/${CHANNEL}/consulta/pnsf`,
		payload,
		undefined,
		true,
	);

	if (response.status === 200) {
		yield put(consultaNomeSocialFiliacaoSuccess(response));
	} else if (response.error) {
		yield put(consultaNomeSocialFiliacaoFailure(response.error));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_NOME_SOCIAL_FILIACAO_REQUEST,
		consultaNomeSocialFiliacao,
	),
]);
