/* eslint-disable no-new */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Col, Modal, Row, Input } from 'antd';

import Button from 'components/Common/Button';
import TextArea from 'components/Common/Form/Input/TextArea';

import left from 'assets/images/button/btn_rotate_left.png';
import right from 'assets/images/button/btn_rotate_right.png';
import camera from 'assets/images/button/btn_take_photo.png';
import pdf from 'assets/images/button/btn_download_pdf.png';
import close from 'assets/images/button/btn_close.png';

import PDFMaker from '../PDFMaker';
import { StyledButton } from '../Eloam/styled';

interface Props {
	setModalScanner: (a: boolean) => void;
}

const ScannerCzur: React.FC<Props> = ({ setModalScanner }) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const imageRef: HTMLImageElement = useMemo(() => new Image(), []);

	// command channel information
	const cmd_svc_port = 25014;
	const mc_svc_port = 9999;

	const [photos, setPhotos] = useState<string[]>([]);
	const [modalPdf, setModalPdf] = useState(false);
	const [pdfLabel, setPdfLabel] = useState('');
	const [output, setOutput] = useState<string[]>([]);
	const [visibleImage, setVisibleImage] = useState(false);
	const [wsCmdGlobal, setWsCmdGlobal] = useState<any>(null);
	const [wsMcGlobal, setWsMcGlobal] = useState<any>(null);
	const [rotationAngle, setRotationAngle] = useState(0);

	const [cmdSvcConnect, setCmdSvcConnect] = useState(false);

	// CUT COORDINATES
	const [coorditanteToCut, setCoorditanteToCut] = useState({
		m_x1: 0,
		m_y1: 0,
		m_x2: 0,
		m_y2: 0,
		m_x3: 0,
		m_y3: 0,
		m_x4: 0,
		m_y4: 0,
	});

	// PLUGIN CZUR
	function DrawMLine() {
		if (canvasRef && canvasRef.current) {
			const contextFrame = canvasRef.current.getContext('2d');

			if (contextFrame) {
				contextFrame.shadowBlur = 5;
				contextFrame.shadowColor = 'rgba(0,0,0,0.5)';
				contextFrame.strokeStyle = '#00FF00';
				contextFrame.lineWidth = 2;
				contextFrame.beginPath();
				contextFrame.moveTo(coorditanteToCut.m_x1, coorditanteToCut.m_y1);
				contextFrame.lineTo(coorditanteToCut.m_x2, coorditanteToCut.m_y2);
				contextFrame.lineTo(coorditanteToCut.m_x3, coorditanteToCut.m_y3);
				contextFrame.lineTo(coorditanteToCut.m_x4, coorditanteToCut.m_y4);
				contextFrame.lineTo(coorditanteToCut.m_x1, coorditanteToCut.m_y1);
				contextFrame.stroke();
			}
		}
	}

	// Initialize CZURPlugin environment feedback, format: {"id": 2, "error": error code}
	function CzurIdInitializeResponse(jsonObj: any) {
		const { error } = jsonObj;

		switch (error) {
			case 0:
				setOutput(outputs => [
					...outputs,
					`Initialize CZURPlugin successfully`,
				]);
				break;

			case 1:
				setOutput(outputs => [...outputs, `Exception error`]);
				break;

			case 3:
				setOutput(outputs => [...outputs, `Insufficient system memory`]);
				break;

			case 6:
				setOutput(outputs => [...outputs, `Invalid authorization code`]);
				break;

			case 9:
				setOutput(outputs => [
					...outputs,
					`CZURPlugin environment initialized`,
				]);
				break;

			case 42:
				setOutput(outputs => [
					...outputs,
					`Failed to create image processing thread`,
				]);
				break;

			case 43:
				setOutput(outputs => [...outputs, `Character set conversion failed`]);
				break;
			default:
		}
	}

	// OPEN CAMERA
	function CZURIdOpenDeviceResponse(jsonObj: any) {
		const { error } = jsonObj;

		switch (error) {
			case 0:
				setVisibleImage(true);
				setOutput(outputs => [...outputs, `Open camera successfully`]);
				break;

			case 1:
				setOutput(outputs => [...outputs, `Exception error`]);
				break;

			case 8:
				setOutput(outputs => [
					...outputs,
					`CZURPlugin environment not initialized`,
				]);

				break;

			case 10:
				setOutput(outputs => [
					...outputs,
					`The device does not support sub-camera`,
				]);
				break;

			case 11:
				setOutput(outputs => [...outputs, `Invalid camera number`]);
				break;

			case 12:
				setOutput(outputs => [...outputs, `Camera is on`]);
				break;

			case 13:
				setOutput(outputs => [
					...outputs,
					`The device is not connected, please connect the device to the computer`,
				]);
				break;

			default:
		}
	}

	// ROTATE
	// Set the rotation angle feedback, format: {"id": 18, "error": error code}
	function CzurIdSetAngleResponse(jsonObj: any) {
		const { error } = jsonObj;

		switch (error) {
			case 0:
				setOutput(outputs => [
					...outputs,
					`Set the rotation angle successfully`,
				]);
				break;

			case 8:
				setOutput(outputs => [
					...outputs,
					`CZURPlugin environment not initialized`,
				]);
				break;

			case 14:
				setOutput(outputs => [...outputs, `Camera not turned on`]);
				break;

			case 22:
				setOutput(outputs => [...outputs, `Invalid rotation angle`]);
				break;

			default:
		}
	}

	// TAKE PHOTO
	function CzurIdImage(jsonObj: any) {
		const { error } = jsonObj;

		switch (error) {
			case 0:
				// The image processing is successful, determine whether the barcode is recognized
				switch (jsonObj.type) {
					case 8:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: EAN-8, content: ${jsonObj.barcode}`,
						]);
						break;

					case 9:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: UPC-E, content: ${jsonObj.barcode}`,
						]);
						break;

					case 12:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: UPC-A, content: ${jsonObj.barcode}`,
						]);
						break;

					case 13:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: EAN-13, content: ${jsonObj.barcode}`,
						]);
						break;

					case 14:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: ISBN-13, content: ${jsonObj.barcode}`,
						]);
						break;

					case 25:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: Interleaved 2 of 5, content: ${jsonObj.barcode}`,
						]);
						break;

					case 39:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: Code 39, content: ${jsonObj.barcode}`,
						]);
						break;

					case 64:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: QR Code, content: ${jsonObj.barcode}`,
						]);
						break;

					case 128:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: Code 128, content: ${jsonObj.barcode}`,
						]);
						break;

					default:
						if (jsonObj.file1 !== null) {
							setOutput(outputs => [
								...outputs,
								`Save the image successfully, the file name is: ${jsonObj.file1}`,
							]);
						}
				}
				break;

			case 1:
				setOutput(outputs => [...outputs, `Unable to save image`]);
				break;

			case 3:
				setOutput(outputs => [...outputs, `Insufficient system memory`]);
				break;

			case 36:
				setOutput(outputs => [
					...outputs,
					`Failed to save image file, please check if the path is valid`,
				]);
				break;

			case 37:
				setOutput(outputs => [
					...outputs,
					`The source PDF document has a password to save, resulting in failure to save the image`,
				]);
				break;

			case 40:
				setOutput(outputs => [
					...outputs,
					`The image is a blank page, do not save the file`,
				]);
				break;

			default:
		}
	}

	function CzurIdBASE64(jsonObj: any) {
		const { error } = jsonObj;

		switch (error) {
			case 0:
				// The image processing is successful, determine whether the barcode is recognized
				switch (jsonObj.type) {
					case 8:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: EAN-8, content: ${jsonObj.barcode}`,
						]);
						break;

					case 9:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: UPC-E, content: ${jsonObj.barcode}`,
						]);
						break;

					case 12:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: UPC-A, content: ${jsonObj.barcode}`,
						]);
						break;

					case 13:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: EAN-13, content: ${jsonObj.barcode}`,
						]);
						break;

					case 14:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: ISBN-13, content: ${jsonObj.barcode}`,
						]);
						break;

					case 25:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: Interleaved 2 of 5, content: ${jsonObj.barcode}`,
						]);
						break;

					case 39:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: Code 39, content: ${jsonObj.barcode}`,
						]);
						break;

					case 64:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: QR Code, content: ${jsonObj.barcode}`,
						]);
						break;

					case 128:
						setOutput(outputs => [
							...outputs,
							`Recognized barcode, type: Code 128, content: ${jsonObj.barcode}`,
						]);
						break;

					default:
				}
				setOutput(outputs => [
					...outputs,
					`Get BASE64 image data successfully`,
				]);
				// demo simply displays BASE64 data
				if (jsonObj.b641 !== null) {
					setPhotos(oldPhotos => [
						...oldPhotos,
						`data:image/jpg;base64,${jsonObj.b641}`,
					]);
				} else if (jsonObj.b642 !== null) {
					setPhotos(oldPhotos => [
						...oldPhotos,
						`data:image/jpg;base64,${jsonObj.b642}`,
					]);
				}
				break;

			case 1:
				setOutput(outputs => [
					...outputs,
					`Get BASE64 image data successfullyUnable to get JPG image data because of abnormal error`,
				]);
				break;

			case 3:
				setOutput(outputs => [...outputs, `Insufficient system memory`]);
				break;

			case 40:
				setOutput(outputs => [
					...outputs,
					`The image is a blank page and does not save BASE64 encoded data`,
				]);
				break;

			default:
		}
	}

	const CZURIdSetProcessType = useCallback((wsCmd: any) => {
		const cmd = {
			id: 19,
			type: 2,
			left: 0.2,
			top: 0.2,
			right: 0.8,
			bottom: 0.8,
		};
		wsCmd.send(JSON.stringify(cmd));
	}, []);

	// COMMAND SERVER
	const openSocketCommand = useCallback((wsCmd: any) => {
		wsCmd.onopen = () => {
			setCmdSvcConnect(true);
			setOutput(outputs => [
				...outputs,
				`Successfully connected to the command server`,
			]);
			const jsonMsg = {
				id: 1,
				license: `iTKlXuZ/HtJmnPcAsOlww5WxLaQK8NS65OtzYaT6oOGhVCfnMLE1TJzMbogPXmc10OH249e2MRRM2iWcnLtXpJwYUFSn4GvGt/pj3LMDUzaES4bAX8LZq7Mh9I4L5cqjUbfQpUu1xZASBk+/F3LuyWNxul4yfX0JMBTW2DFtx9iIwjhqy0i5dv14/zPEaXag6MpvB+rGYJgQxavUeB8bf8PfcUYg8RgAkcXru9hqTOwMmQzblDNe15PE/i+24xl4xv5Cs9z7BkBThKw6s9/Z2qOGqgJmHrzMbLbasekP8/UnujWqzn3Q1YRzxhzzd7NNmwNtOGQdaU/adTX3c2gmgNt8jhnbgO6typf7/7V31xoeddIOinagn9tcztutAIJ6RNu0LY/zvH5m977BfaUhDV5g+OswO9s4TyUj680UldDl+hSWv11C7Eir2oVI21qb63S3AkykapZht5Az2UyE+SOK3/eRrDy/ihMHp58FGKpKuiDNbMO1DmzkiAKlanRCAA==`,
			};
			wsCmd.send(JSON.stringify(jsonMsg));

			const jsonMsg2 = {
				id: 9,
				index: 0,
			};
			wsCmd.send(JSON.stringify(jsonMsg2));
			setTimeout(() => {
				CZURIdSetProcessType(wsCmd);
			}, 1000);
		};
		wsCmd.onclose = () => {
			setCmdSvcConnect(false);
			setOutput(outputs => [
				...outputs,
				`The connection to the command server was interrupted`,
			]);
		};

		wsCmd.onmessage = (e: any) => {
			const jsonObj = window.JSON.parse(e.data);

			switch (jsonObj.id) {
				// Processing json packet failure feedback
				case 0:
					// eslint-disable-next-line no-case-declarations
					const { error } = jsonObj;

					switch (error) {
						case 3:
							setOutput(outputs => [
								...outputs,
								`Insufficient system memory to process the current command`,
							]);
							break;

						case 5:
							setOutput(outputs => [...outputs, `Invalid json command format`]);
							break;
						default:
					}
					break;

				// Initialize CZURPlugin environment feedback
				case 2:
					CzurIdInitializeResponse(jsonObj);
					break;

				// turn on device feedback
				case 10:
					CZURIdOpenDeviceResponse(jsonObj);
					break;

				// Set the rotation angle feedback
				case 18:
					CzurIdSetAngleResponse(jsonObj);
					break;

				// Automatic cut
				case 56:
					if (canvasRef && canvasRef.current) {
						setCoorditanteToCut({
							m_x1: canvasRef.current.width * jsonObj.x1,
							m_y1: canvasRef.current.height * jsonObj.y1,
							m_x2: canvasRef.current.width * jsonObj.x2,
							m_y2: canvasRef.current.height * jsonObj.y2,
							m_x3: canvasRef.current.width * jsonObj.x3,
							m_y3: canvasRef.current.height * jsonObj.y3,
							m_x4: canvasRef.current.width * jsonObj.x4,
							m_y4: canvasRef.current.height * jsonObj.y4,
						});

						DrawMLine();
					}
					break;
				// image processing result notification
				case 301:
					CzurIdImage(jsonObj);
					break;

				// Photograph (BASE64 encoded data) result notification
				case 318:
					CzurIdBASE64(jsonObj);
					break;

				default:
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const connectCmdSvc = useCallback(() => {
		if (cmdSvcConnect) {
			setOutput(outputs => [...outputs, `Connected to command server`]);
			return;
		}

		const url = `ws://localhost:${cmd_svc_port}/`;
		let wsCmd: any = null;
		if ('WebSocket' in window) {
			wsCmd = new WebSocket(url);
			setWsCmdGlobal(wsCmd);
		} else {
			setOutput(outputs => [
				...outputs,
				`The browser does not support WebSocket`,
			]);
			return;
		}
		openSocketCommand(wsCmd);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// HEADER SERVER
	const openSocketHeader = useCallback(
		(wsMc: any) => {
			wsMc.onopen = () => {
				setCmdSvcConnect(true);
				setOutput(outputs => [
					...outputs,
					`Successfully connected to the main head server`,
				]);
			};
			wsMc.onclose = () => {
				setCmdSvcConnect(false);
				setOutput(outputs => [
					...outputs,
					`The connection to the main head server was lost`,
				]);
			};

			wsMc.onmessage = (e: any) => {
				imageRef?.setAttribute('src', `data:image/jpg;base64,${e.data}`);
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[visibleImage],
	);

	const connectMcSvc = useCallback(() => {
		if (cmdSvcConnect) {
			setOutput(outputs => [...outputs, `Connected to the main header server`]);
			return;
		}

		const url = `ws://localhost:${mc_svc_port}/`;
		let wsMc: any = null;
		if ('WebSocket' in window) {
			wsMc = new WebSocket(url);
			setWsMcGlobal(wsMc);
		} else {
			setOutput(outputs => [
				...outputs,
				`The browser does not support WebSocket`,
			]);
		}

		openSocketHeader(wsMc);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		imageRef.onload = () => {
			if (canvasRef.current) {
				canvasRef.current.setAttribute('width', `${imageRef.naturalWidth}px`);
				canvasRef.current.setAttribute('height', `${imageRef.naturalHeight}px`);
				const contextFrame = canvasRef.current.getContext('2d');

				if (contextFrame) {
					// Set the rotation center point
					const x = imageRef.naturalWidth / 2;
					const y = imageRef.naturalHeight / 2;

					contextFrame.restore();
					contextFrame.clearRect(
						0,
						0,
						canvasRef.current.width,
						canvasRef.current.height,
					);

					contextFrame.translate(x, y);
					contextFrame.rotate((rotationAngle * Math.PI) / 180);
					contextFrame.translate(-x, -y);

					contextFrame.drawImage(
						imageRef,
						0,
						0,
						canvasRef.current.width,
						canvasRef.current.height,
					);
				}
			}
		};
	}, [imageRef, rotationAngle, wsCmdGlobal]);

	useEffect(() => {
		connectCmdSvc();
		connectMcSvc();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {
			if (wsCmdGlobal) wsCmdGlobal.close();
			if (wsMcGlobal) wsMcGlobal.close();
		};
	}, [wsCmdGlobal, wsMcGlobal]);

	const rotate = useCallback(
		(direction: 'left' | 'right') => {
			if (direction === 'left') {
				// Set the rotation angle, format: {"id": 17, "angle": rotation angle}
				setRotationAngle(old => {
					let angle = 0;
					switch (old) {
						case 0:
							angle = 270;
							break;
						case 270:
							angle = 180;
							break;
						case 180:
							angle = 90;
							break;
						default:
							angle = 0;
							break;
					}
					const jsonMsg = {
						id: 17,
						angle,
					};
					wsCmdGlobal.send(JSON.stringify(jsonMsg));
					return angle;
				});
			} else {
				setRotationAngle(old => {
					let angle = 0;
					switch (old) {
						case 0:
							angle = 90;
							break;
						case 90:
							angle = 180;
							break;
						case 180:
							angle = 270;
							break;
						default:
							angle = 0;
							break;
					}
					const jsonMsg = {
						id: 17,
						angle,
					};
					wsCmdGlobal.send(JSON.stringify(jsonMsg));
					return angle;
				});
			}
		},
		[wsCmdGlobal],
	);

	const photograph = useCallback(() => {
		// Photo button click
		const jsonMsg = {
			adjust: 0,
			bcr: 0,
			bpd: 0,
			color: 0,
			dpi: 0,
			id: 68,
			index: 0,
			quality: 75,
			round: 0,
		};
		wsCmdGlobal.send(JSON.stringify(jsonMsg));
	}, [wsCmdGlobal]);

	const removeImage = useCallback((imgUrl: string) => {
		setPhotos(oldPhotos =>
			oldPhotos.filter(photo => {
				return photo !== imgUrl;
			}),
		);
	}, []);

	return (
		<Row gutter={[10, 20]} style={{ marginTop: '10px' }} key="sectionRow">
			<Col span={16}>
				<Row>
					<Col span={24} style={{ marginTop: '15px' }}>
						<h3 style={{ textAlign: 'center' }}>Pré-visualização</h3>
					</Col>
					<Col span={24}>
						<Row justify="center" align="middle">
							<Col
								style={{ width: '100%', maxWidth: '400px', maxHeight: '400px' }}
							>
								{visibleImage ? (
									<canvas
										ref={canvasRef}
										style={{
											maxWidth: '400px',
											maxHeight: '400px',
											width: '100%',
											background: 'black',
											aspectRatio: 'auto 400 / 300',
										}}
									/>
								) : (
									<TextArea
										style={{ width: '100%', marginBottom: '20px' }}
										name="textArea"
										value={output.toString().replaceAll(',', '\n')}
										disabled
									/>
								)}
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Row justify="center" align="middle">
							<Col>
								<Button
									type="button"
									onClick={() => {
										photograph();
									}}
									style={{
										height: '100px',
										width: '100px',
										border: 'none',
										backgroundColor: 'transparent',
										background: 'none',
										boxShadow: 'none',
									}}
								>
									<img src={camera} alt="camera" style={{ width: '100%' }} />
								</Button>
							</Col>
							<Col>
								<Button
									type="button"
									onClick={() => {
										rotate('left');
									}}
									style={{
										height: '100px',
										width: '100px',
										border: 'none',
										backgroundColor: 'transparent',
										background: 'none',
										boxShadow: 'none',
									}}
								>
									<img src={left} alt="left" style={{ width: '100%' }} />
								</Button>
							</Col>
							<Col>
								<Button
									type="button"
									onClick={() => {
										rotate('right');
									}}
									style={{
										height: '100px',
										width: '100px',
										border: 'none',
										backgroundColor: 'transparent',
										background: 'none',
										boxShadow: 'none',
									}}
								>
									<img src={right} alt="right" style={{ width: '100%' }} />
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={8}>
				<Row style={{ height: '100%' }}>
					<Col span={24} style={{ marginTop: '15px' }}>
						<h3 style={{ textAlign: 'center' }}>Imagens Digitalizadas</h3>
					</Col>
					<Col span={24} style={{ height: 'calc(100% - 165px)' }}>
						<Row style={{ paddingTop: '5px' }} gutter={[10, 10]}>
							{photos.length > 0 &&
								photos.map(photo => (
									<Col key={photo} span={7} offset={1}>
										<StyledButton
											type="button"
											onClick={() => {
												removeImage(photo);
											}}
										>
											x
										</StyledButton>
										<img
											src={photo}
											alt="SavedPhoto"
											style={{ width: '100%' }}
										/>
									</Col>
								))}
						</Row>
					</Col>
					<Col span={24}>
						<Row
							justify="center"
							align="middle"
							style={{ alignItems: 'end', height: '100%' }}
						>
							<Col>
								<Button
									type="button"
									onClick={() => {
										if (photos.length > 0) {
											setModalPdf(true);
											return;
										}
										// eslint-disable-next-line no-alert
										alert('Não existe imagem digitalizada!');
									}}
									style={{
										height: '100px',
										width: '100px',
										border: 'none',
										backgroundColor: 'transparent',
										background: 'none',
										boxShadow: 'none',
									}}
								>
									<img src={pdf} alt="pdf" style={{ width: '100%' }} />
								</Button>
							</Col>
							<Col>
								<Button
									type="button"
									onClick={() => {
										setModalScanner(false);
									}}
									style={{
										height: '100px',
										width: '100px',
										border: 'none',
										backgroundColor: 'transparent',
										background: 'none',
										boxShadow: 'none',
									}}
								>
									<img src={close} alt="close" style={{ width: '100%' }} />
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Modal
				title="Digitalização de Documentos"
				visible={modalPdf}
				onOk={() => {
					if (pdfLabel === '') {
						// eslint-disable-next-line no-alert
						alert('Nome inválido!');
						return;
					}
					PDFMaker(photos, pdfLabel);
					setModalPdf(false);
				}}
				onCancel={() => setModalPdf(false)}
			>
				<Row>
					<Col>Informe um nome para o PDF:</Col>
				</Row>
				<Row>
					<Col>
						<Input
							value={pdfLabel}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (e) {
									setPdfLabel(e.target.value);
								}
							}}
						/>
					</Col>
				</Row>
			</Modal>
		</Row>
	);
};

export default ScannerCzur;
