import consultarMunicipioPorUFSaga from './consultarMunicipioPorUF/saga';
import consultaCarroceriaSaga from './consultaCarroceria/saga';
import consultaTipoSaga from './consultaTipo/saga';
import consultaCategoriaSaga from './consultaCategoria/saga';
import consultaCorSaga from './consultaCor/saga';
import consultaCombustivelSaga from './consultaCombustivel/saga';
import consultaEspecieSaga from './consultaEspecie/saga';
import consultarTipoModificacaoAlteracaoSaga from './consultarTipoModificacaoAlteracao/saga';
import consultaMarcaModeloSaga from './consultaMarcaModelo/saga';
import consultarUnidadesTransitoSaga from './consultaUnidadeTransito/saga';
import consultarMunicipioCodigoProdespSaga from './consultarMunicipioCodigoProdesp/saga'

export const consultarMunicipioPorUF = consultarMunicipioPorUFSaga;
export const consultaCarroceria = consultaCarroceriaSaga;
export const consultaTipo = consultaTipoSaga;
export const consultaCategoria = consultaCategoriaSaga;
export const consultaCor = consultaCorSaga;
export const consultaCombustivel = consultaCombustivelSaga;
export const consultaEspecie = consultaEspecieSaga;
export const consultarTipoModificacaoAlteracao = consultarTipoModificacaoAlteracaoSaga;
export const consultaMarcaModelo = consultaMarcaModeloSaga;
export const consultarUnidadesTransito = consultarUnidadesTransitoSaga;
export const consultarMunicipioCodigoProdesp = consultarMunicipioCodigoProdespSaga;
