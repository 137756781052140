export enum Types {
	GERAR_RENACH_TRANSFERENCIA_MUNICIPAL = '@cnh/GERAR_RENACH_TRANSFERENCIA_MUNICIPAL_T',
	GERAR_RENACH_TRANSFERENCIA_MUNICIPAL_SUCCESS = '@cnh/GERAR_RENACH_TRANSFERENCIA_MUNICIPAL_SUCCESS_T',
	GERAR_RENACH_TRANSFERENCIA_MUNICIPAL_FAILURE = '@cnh/GERAR_RENACH_TRANSFERENCIA_MUNICIPAL_FAILURE_T',
}

export interface gerarRenach {
	status: number;
	data: IDadosRenach | null;
}

export interface IDadosRenach {
	codigoInterno: string;
	message: string;
	cpf: string;
	renach: string;
}
export interface IRequestGerarRenach {
	cpf: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	dataNascimento: string;
	sexo: string;
	nacionalidade: string;
	naturalidade: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cep: string;
	tipoDocumento: string;
	numDocumento: string;
	orgaoDocumento: string;
	ufDocumento: string;
	dddCelular: string;
	celular: string;
	cnhDigital: string;
	codigoVerificador: string;
	email: string;
	pretendeAtividadeRemunerada: string;
	uf: string;
}
