export enum Types {
	CONSULTA_SITUACAO_ATUAL_REQUEST = '@aac/CONSULTA_SITUACAO_ATUAL',
	CONSULTA_SITUACAO_ATUAL_SUCCESS = '@aac/CONSULTA_SITUACAO_ATUAL_SUCCESS',
	CONSULTA_SITUACAO_ATUAL_FAILURE = '@aac/CONSULTA_SITUACAO_ATUAL_FAILURE',
	CONSULTA_SITUACAO_ATUAL_CLEAR = '@aac/CONSULTA_SITUACAO_ATUAL_CLEAR',

	ENCERRAR_ATENDIMENTO_REQUEST = '@aac/ENCERRAR_ATENDIMENTO',
	ENCERRAR_ATENDIMENTO_SUCCESS = '@aac/ENCERRAR_ATENDIMENTO_SUCCESS',
	ENCERRAR_ATENDIMENTO_FAILURE = '@aac/ENCERRAR_ATENDIMENTO_FAILURE',
	ENCERRAR_ATENDIMENTO_CLEAR = '@aac/ENCERRAR_ATENDIMENTO_CLEAR',

	ATENDIMENTO_ENCERRADO = '@aac/ATENDIMENTO_ENCERRADO',

	LIMPA_MENSAGEM_RETORNO = '@aac/LIMPA_MENSAGEM_RETORNO',
	LIMPA_STATE = '@aac/LIMPA_STATE',
}

export interface ConsultaUsuarioPendente {
	id: string;
	dc: string;
	dataEmissaoAtestado: number;
	nome: string;
	nomeMae: string;
	nomePai: string;
	docProcurador: string;
	nomeProcurador: string;
	numeroRg: string;
	sexo: string;
	exigenciaProcurado: string;
	exigenciaPreso: string;
	exigenciaEvadido: string;
	exigCopiaApresentado: string;
	exigBaixaDesaparecido: string;
	exigCertidaoObjeto: string;
	exigenciaIP01: string;
	exigenciaIP02: string;
	exigenciaIP03: string;
	exigenciaIP04: string;
	exigenciaIP05: string;
	exigenciaIP06: string;
	exigenciaIP07: string;
	exigenciaIP08: string;
	exigenciaIP09: string;
	exigenciaIP10: string;
	exigDelegacia01: string;
	exigDelegacia02: string;
	exigDelegacia03: string;
	exigDelegacia04: string;
	exigDelegacia05: string;
	exigDelegacia06: string;
	exigDelegacia07: string;
	exigDelegacia08: string;
	exigDelegacia09: string;
	exigDelegacia10: string;
	exigInstauracao01: string;
	exigInstauracao02: string;
	exigInstauracao03: string;
	exigInstauracao04: string;
	exigInstauracao05: string;
	exigInstauracao06: string;
	exigInstauracao07: string;
	exigInstauracao08: string;
	exigInstauracao09: string;
	exigInstauracao10: string;
	exigProcesso01: string;
	exigProcesso02: string;
	exigProcesso03: string;
	exigProcesso04: string;
	exigProcesso05: string;
	exigProcesso06: string;
	exigProcesso07: string;
	exigProcesso08: string;
	exigProcesso09: string;
	exigProcesso10: string;
	exigVara01: string;
	exigVara02: string;
	exigVara03: string;
	exigVara04: string;
	exigVara05: string;
	exigVara06: string;
	exigVara07: string;
	exigVara08: string;
	exigVara09: string;
	exigVara10: string;
	exigBO: string;
	exigBODelegacia: string;
	exigBONumero: string;
	exigCertidaoCriminais: string;
	exigCertidaoCriminais01: string;
	exigCertidaoCriminais02: string;
	exigCertidaoDistribuidor: string;
	exigCertidaoDistribuidor01: string;
	exigObservacao: string;
	exigObservacao01: string;
	exigObservacao02: string;
	exigObservacao03: string;
	exigObservacao04: string;
	exigObservacao05: string;
	exigObservacao06: string;
	exigObservacao07: string;
	exigObservacao08: string;
	exigObservacao09: string;
	exigObservacao10: string;
	positivaInicio: string;
	positivaObservacao01: string;
	positivaObservacao02: string;
	positivaRegime: string;
	positivaTermino: string;
	encerrado: string;
	posto: string;
	resultado: string;
	retorno: string;
	tipoAA: string;
	tipoDoc: string;
	tipoEncaminhamento: string;
	tipoLiberacao: string;
	usuario: string;
	usuarioCentral: string;
	usuarioPosto: string;
	status: string;
}

export interface ConsultaSituacaoAtual {
	dataEmissaoAtestado: string;
	idIdentificacao: string;
	nome: string;
	posto: string;
	rg: string;
	tipoLiberacao: string;
	usuario: string;
	usuarioCentral: string;
	usuarioPosto: string;
}

export interface PainelDeControle {
	usuarioPendente: {
		status: number;
		resultado: ConsultaUsuarioPendente[];
	};
	situacaoAtual: {
		status: number;
		resultado: ConsultaSituacaoAtual[];
	};
}

export interface RequestConsultaSituacaoAtual {
	numeroPosto: string;
}

export interface RequestEncerrarAtendimento {
	idIdentificacao: string;
}
