export enum Types {
	PRE_CADASTRO_CIDADAO_REQUEST = '@sgu-service/PRE_CADASTRO_CIDADAO_REQUEST',
	PRE_CADASTRO_CIDADAO_SUCCESS = '@sgu-service/PRE_CADASTRO_CIDADAO_SUCCESS',
	PRE_CADASTRO_CIDADAO_FAILURE = '@sgu-service/PRE_CADASTRO_CIDADAO_FAILURE',
	PRE_CADASTRO_CIDADAO_CLEAR = '@sgu-service/PRE_CADASTRO_CIDADAO_CLEAR',
}

export interface PreCadastroCidadao {
	status: number;
	data: PreCadastroCidadaoResponse | null;
	errorMessage: any;
	request: PreCadastroCidadaoRequest | null;
}

export interface PreCadastroCidadaoResponse {
	idCidadao: string;
	situacao: number;
	token: string;
	timestamp: number;
	email: string;
	nivel: string;
	cpf: string;
	statusCode: 0;
	mensagem: string;
}

export interface PreCadastroCidadaoRequest {
	evento: {
		id_atendimento: string;
		id_cidadao: string;
		cpf: string;
		identificacao: string;
		ip: string;
		canal: {
			id: number;
			desc: string;
			estacao?: {
				id: number;
				desc: string;
			};
			localidade: {
				id: number;
				desc: string;
			};
		};
		atendente: {
			nome: string;
			id_atendente: string;
			id_posto: string;
			desc_posto: string;
		};
	} | null;
	rg: string | null;
	ufRg: string | null;
	cpf: string;
	cns: string;
	nome: string;
	firstName: string;
	dataNascimento: string;
	nomeMae: string;
	foneResidencialDdd: string;
	foneResidencial: string;
	foneComercialDdd: string;
	foneComercial: string;
	foneCelularDdd: string;
	foneCelular: string;
	email: string;
	autorizaEmail?: boolean;
	autorizaSMS?: boolean;
	origem: string;
	statusUpdateSenha: string;
	senha: string | null;
	businessCategory: string;
	presencial: number;
	sexo: string;
	orgao: number;
	idCidadao: string;
	idAtendimento: string;
	endereco: string;
	cep: string;
	enderecoUf: string | null;
	enderecoBairro: string;
	enderecoCidade: string;
	enderecoNum: string;
	enderecoCompl: string;
}
