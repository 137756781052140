import { all } from 'redux-saga/effects';

import atualizarMunicipioSaga from './atualizarMunicipio/saga';
import cadastrarMunicipioSaga from './cadastrarMunicipio/saga';
import consultarMunicipiosSaga from './consultarMunicipios/saga';
import excluirMunicipioSaga from './excluirMunicipio/saga';
import pesquisaMunicipios from './pesquisaMunicipios/saga';

export default all([
	atualizarMunicipioSaga,
	cadastrarMunicipioSaga,
	consultarMunicipiosSaga,
	excluirMunicipioSaga,
	pesquisaMunicipios,
]);
