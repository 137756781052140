import React, {
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { impressaoCrlveRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/imprimirCrvle/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import Placa from 'components/Common/Form/Fields/Placa';
import ButtonImage from 'components/Common/ButtonImage';
import PDFViewer from 'components/Common/PDFViewer';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import { onlyNumbers } from 'utils/genericFunctions';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

// ROUTES
import { ROUTE_DETRAN_CRV } from 'routes/paths';

// FORM
import {
	ImprimirCrvle,
	initialValues,
	schema,
	treatValues,
	treatRequestSguStatistics,
} from './form';

const ImpressaoCrlve: React.FC = () => {
	const dispatch = useDispatch();

	const { imprimirCrvle } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { salvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento,
	);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const [formData, setFormData] = useState(initialValues);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const sguData: SguData = useMemo(() => {
		return {
			salvarAtendimento,
			login,
		};
	}, [login, salvarAtendimento]);

	const evento = useMemo(() => {
		const ident = '0';
		return getEventoConsultar(sguData, ident);
	}, [sguData]);

	const handleSubmit = useCallback(
		(values: ImprimirCrvle) => {
			setFormData(values);
			const formatValues = treatValues(evento, values);
			dispatch(impressaoCrlveRequest(formatValues));
		},
		[dispatch, evento],
	);

	useEffect(() => {
		if (imprimirCrvle.data) {
			const payload = treatRequestSguStatistics(user, formData);
			dispatch(estatisticaIncluirRequest(payload));
		}
	}, [dispatch, formData, imprimirCrvle.data, user]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Imprimir CRLV-e de Licenciamento de Veículos">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={values => handleSubmit(values)}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={14}>
										<Placa
											title="Placa"
											titleSize="lg"
											name="placa"
											defaultValue={initialValues.placa}
											required
											formik={formik}
										/>
									</Col>
									<Col span={10} />
									<Col span={14}>
										<Field
											required
											titleSize="lg"
											name="renavam"
											title="Renavam"
											as={InputMask}
											mask="99999999999"
											maskChar=""
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue('renavam', v.target.value);
											}}
											error={hasError(formik.errors, 'renavam')}
										/>
									</Col>
									<Col span={10} />
									<Col span={14}>
										<Field
											required
											maxLength="18"
											titleSize="lg"
											name="cpfCnpjProprietario"
											title="CPF/CNPJ Proprietário"
											as={Input}
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'cpfCnpjProprietario',
													onlyNumbers(v.target.value),
												);
											}}
											error={hasError(formik.errors, 'cpfCnpjProprietario')}
										/>
									</Col>
									<Col span={8} />
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={() => {
												formik.handleReset();
												dispatch(clearNotifications());
											}}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="imprimir" />
									</Col>
								</Row>
								{imprimirCrvle.data?.pdf && (
									<PDFViewer
										title="Impressão CRLV Digital"
										source={
											imprimirCrvle.data?.pdf ? imprimirCrvle.data?.pdf : ''
										}
										onClose={formik.handleReset}
									/>
								)}
							</Form>
						);
					}}
				</Formik>
			</Section>

			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</Suspense>
	);
};

export default ImpressaoCrlve;
