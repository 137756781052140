import * as Yup from 'yup';
import { PesquisaFornecedorForm } from 'store/modules/api/procon/fornecedores/consultaFornecedor/types';

export const initialValues: PesquisaFornecedorForm = {
	cpf: '',
	cnpj: '',
	termo: '',
};

export const schema = Yup.object<PesquisaFornecedorForm>().shape(
	{
		// cpf: Yup.string().when(['cnpj', 'termo'], {
		// 	is: (cnpj, termo) => cnpj === '' && termo === '',
		// 	then: Yup.string().required('Campo Obrigatório: CPF/CNPJ ou Termo.'),
		// }),
		// cnpj: Yup.string().when(['cpf', 'termo'], {
		// 	is: (cpf, termo) => cpf === '' && termo === '',
		// 	then: Yup.string().required('Campo Obrigatório: CPF/CNPJ ou Termo.'),
		// }),
		// termo: Yup.string().when(['cpf', 'cnpj'], {
		// 	is: (cpf, cnpj) => cpf === '' && cnpj === '',
		// 	then: Yup.string().required('Campo Obrigatório: CPF/CNPJ ou Termo.'),
		// }),

		validacaoFormulario: Yup.string().when(['cpf', 'cnpj', 'termo'], {
			is: (cpf, cnpj, termo) => cpf === '' && cnpj === '' && termo === '',
			then: Yup.string().required(
				'Campo Obrigatório: CPF/CNPJ ou Nome Fantasia.',
			),
		}),
	},
	[
		['cpf', 'cnpj'],
		['cnpj', 'termo'],
		['cpf', 'termo'],
	],
);
