export enum Types {
	IMPRESSAO_COMUNICACAO_VENDA_REQUEST = '@DETRAN-CRV/IMPRESSAO_COMUNICACAO_VENDA_REQUEST',
	IMPRESSAO_COMUNICACAO_VENDA_SUCCESS = '@DETRAN-CRV/IMPRESSAO_COMUNICACAO_VENDA_SUCCESS',
	IMPRESSAO_COMUNICACAO_VENDA_FAILURE = '@DETRAN-CRV/IMPRESSAO_COMUNICACAO_VENDA_FAILURE',
	IMPRESSAO_COMUNICACAO_VENDA_CLEAR = '@DETRAN-CRV/IMPRESSAO_COMUNICACAO_VENDA_CLEAR',
}

export interface impressaoComunicacaoVenda {
	status: number;
	data: ficha | null;
}

interface ficha {
	ficha: {
		anoFicha: number;
		chassi: string;
		fichaCadastral: string;
		numeroFicha: number;
		placa: string;
	};
}
