/* eslint-disable array-callback-return */
import produce from 'immer';

// TYPES
import { Types, CadastroEnderecos } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: CadastroEnderecos = {
	status: 0,
	data: null,
};

export default function cadastroEnderecosReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): CadastroEnderecos {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CADASTRO_ENDERECOS_REQUEST: {
				break;
			}

			case Types.CADASTRO_ENDERECOS_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = action.payload.status;
				break;
			}

			case Types.CADASTRO_ENDERECOS_FAILURE: {
				break;
			}

			case Types.CADASTRO_ENDERECOS_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				break;
			}

			default:
		}
	});
}
