export enum Types {
	PESQUISA_MUNICIPIOS_REQUEST = '@SGU/PESQUISA_MUNICIPIOS_REQUEST',
	PESQUISA_MUNICIPIOS_SUCCESS = '@SGU/PESQUISA_MUNICIPIOS_SUCCESS',
	PESQUISA_MUNICIPIOS_FAILURE = '@SGU/PESQUISA_MUNICIPIOS_FAILURE',
	PESQUISA_MUNICIPIOS_CLEAR = '@SGU/PESQUISA_MUNICIPIOS_CLEAR',
}

export interface PesquisaMunicipios {
	status: number;
	data: null | Municipio;
}

export interface Municipio {
	codigoNaturalidade: number;
	codigoIbge: number;
	nome: string;
	siglaEstado: string;
}
