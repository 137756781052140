export enum Types {
	CONSULTAR_RESTRICAO_VEICULO = '@DETRAN-CRV/CONSULTAR_RESTRICAO_VEICULO',
	CONSULTAR_RESTRICAO_VEICULO_SUCCESS = '@DETRAN-CRV/CONSULTAR_RESTRICAO_VEICULO_SUCCESS',
	CONSULTAR_RESTRICAO_VEICULO_FAILURE = '@DETRAN-CRV/CONSULTAR_RESTRICAO_VEICULO_FAILURE',
	CONSULTAR_RESTRICAO_VEICULO_CLEAR = '@DETRAN-CRV/CONSULTAR_RESTRICAO_VEICULO_CLEAR',
}

export interface consultarRestricaoVeiculo {
	status: number;
	data: IDadosConsultaRestricaoVeiculo | null;
	dataError: ConsultarRestricaoVeiculoDataError | null;
}

export interface ConsultarRestricaoVeiculoDataError {
	status: number;
	trace: string;
	codigo: string;
	messages: string[];
}

export interface IDadosConsultaRestricaoVeiculo {
	placa: string;
	renavam: number;
	cpfCnpj: number;
	ufOrigem: string;
	municipio: number;
	cpfCnpjProprietario: number;
	restricaoFinanceira: string;
	cnpjAgente: number;
	nomeAgente: string;
	cpfCnpjAgente: number;
	nomeFinanciado: string;
	comunicacaoVendas: string;
	cpfCnpjNovoComprador: number;
	municipioComprador: number;
	municipioProprietario: number;
	cepProprietario: number;
	bloqueioRenajud: string;
	placaMercosul: string;
	anoLicenciamento: number;
	nroLaudoVistoria: string;
	statusLaudo: number;
	obsLaudo: string;
	origemCV: number;
	dataInclusaoComunicacaoVenda: string;
}

export interface IRequestConsultaRestricaoVeiculo {
	placa: string;
	documento: string;
	espelho: string;
	renavam: string;
	segundaViaCrv?: string;
	uf: string;
}
