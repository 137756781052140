import { ReducerAction } from 'store/modules/types';

import { Types, IRequestAlteraPrefeiturasServicosCanais } from './types';

export function alteraPrefeiturasServicosCanaisRequest(
	data: IRequestAlteraPrefeiturasServicosCanais,
): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURA_SERVICOS_CANAIS,
		payload: data,
	};
}
export function alteraPrefeiturasServicosCanaisSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURA_SERVICOS_CANAIS_SUCCESS,
		payload,
	};
}
export function alteraPrefeiturasServicosCanaisFailure(
	payload: [],
): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURA_SERVICOS_CANAIS_FAILURE,
		payload,
	};
}

export function alteraPrefeiturasServicosCanaisClear(): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURA_SERVICOS_CANAIS_CLEAR,
		payload: null,
	};
}
