import * as Yup from 'yup';
import { ScriptPreAtendimentoForm } from 'store/modules/api/iirgd/scriptPreAtendimento/types';

export const scriptPreAtendimentoInitialValues: ScriptPreAtendimentoForm = {
	teveRg: null,
	ondeMorava: null,
	possuiDocumento: null,
	ufRg: '',
	numeroRg: '',
	digitoRg: '',
	dataExpedicaoRg: '',
	teveAlteracao: null,
	rgOuAcervo: null,
	adocao: null,
	tranGenero: null,
	paternidadeReconhecida: null,
	outros: null,
	preAtendimentoValido: false,
};

export const scriptPreAtendimentoSchema =
	Yup.object<ScriptPreAtendimentoForm>().shape({
		teveRg: Yup.boolean()
			.nullable()
			.required(`Pergunta Obrigatória: 1) Já teve RG.`),
		ondeMorava: Yup.string()
			.nullable()
			.when(['teveRg'], {
				is: teveRg => teveRg === true,
				then: Yup.string().required(
					`Campo Obrigatório: 2) Morava aonde quando tirou o RG.`,
				),
			}),
		possuiDocumento: Yup.boolean()
			.nullable()
			.when(['teveRg', 'ondeMorava'], {
				is: (teveRg, ondeMorava) =>
					teveRg === true && ondeMorava === 'Outro Estado',
				then: Yup.boolean().required(`Campo Obrigatório: 3) Está com o RG.`),
			}),
		ufRg: Yup.string()
			.nullable()
			.when(['teveRg', 'possuiDocumento'], {
				is: (teveRg, possuiDocumento) =>
					teveRg === true && possuiDocumento === true,
				then: Yup.string().required(
					`Campo Obrigatório: Informar UF do RG anterior.`,
				),
			}),
		numeroRg: Yup.string()
			.nullable()
			.when(['teveRg', 'possuiDocumento'], {
				is: (teveRg, possuiDocumento) =>
					teveRg === true && possuiDocumento === true,
				then: Yup.string().required(
					`Campo Obrigatório: Informar o Número do RG anterior.`,
				),
			}),
		dataExpedicaoRg: Yup.string()
			.nullable()
			.when(['teveRg', 'possuiDocumento'], {
				is: (teveRg, possuiDocumento) =>
					teveRg === true && possuiDocumento === true,
				then: Yup.string().required(
					`Campo Obrigatório: Informar a data de expedição do RG anterior.`,
				),
			}),
		rgOuAcervo: Yup.string()
			.nullable()
			.when(['teveRg', 'ondeMorava', 'possuiDocumento'], {
				is: (teveRg, ondeMorava, possuiDocumento) =>
					teveRg === true &&
					ondeMorava === 'São Paulo' &&
					possuiDocumento === true,
				then: Yup.string().required(
					`Campo Obrigatório: Informar se RG não cadastrado ou Acervo.`,
				),
			}),
		teveAlteracao: Yup.boolean()
			.nullable()
			.when(['teveRg', 'ondeMorava'], {
				is: (teveRg, ondeMorava) =>
					teveRg === true && ondeMorava === 'Outro Estado',
				then: Yup.boolean().required(
					`Campo Obrigatório: 4) Teve alteração no seu nome, nome dos pais ou na data de nascimento.`,
				),
			}),
		alteracaoConfirmada: Yup.string().when(
			[
				'teveRg',
				'teveAlteracao',
				'adocao',
				'tranGenero',
				'paternidadeReconhecida',
				'outros',
			],
			{
				is: (
					teveRg,
					teveAlteracao,
					adocao,
					tranGenero,
					paternidadeReconhecida,
					outros,
				) =>
					teveRg === true &&
					teveAlteracao === true &&
					(adocao === null || adocao === '' || adocao === false) &&
					(tranGenero === null || tranGenero === '' || tranGenero === false) &&
					(paternidadeReconhecida === null ||
						paternidadeReconhecida === '' ||
						paternidadeReconhecida === false) &&
					(outros === null || outros === '' || outros === false),
				then: Yup.string().required(
					`Campo Obrigatório: Selecionar um das as opções ref. alteração de nome.`,
				),
			},
		),
	});
