import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, AlteraSenhaUsuario } from './types';

export const INITIAL_STATE: AlteraSenhaUsuario = {
	status: 0,
	data: null,
	form: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): AlteraSenhaUsuario {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ALTERA_SENHA_USUARIO_REQUEST: {
				draft.form = {
					usuario: action.payload.cpf,
					novaSenha: action.payload.novaSenha,
				};
				break;
			}

			case Types.ALTERA_SENHA_USUARIO_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = 200;
				break;
			}

			case Types.ALTERA_SENHA_USUARIO_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.ALTERA_SENHA_USUARIO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.form = INITIAL_STATE.form;
				break;
			}

			default:
		}
	});
}
