import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, DownloadDocumentos } from './types';

export const INITIAL_STATE: DownloadDocumentos = {
	status: 0,
	data: null,
	mensagem: '',
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): DownloadDocumentos {
	return produce(state, draft => {
		switch (action.type) {
			case Types.DOWNLOAD_DOCUMENTO_REQUEST: {
				break;
			}

			case Types.DOWNLOAD_DOCUMENTO_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.data;
				break;
			}

			case Types.DOWNLOAD_DOCUMENTO_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				draft.mensagem = action.payload.mensagem;
				break;
			}
			case Types.DOWNLOAD_DOCUMENTO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.mensagem = INITIAL_STATE.mensagem;
				break;
			}

			default:
		}
		return draft;
	});
}
