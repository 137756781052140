import { combineReducers } from 'redux';

import consultaReabilitacao from './consultaReabilitacao/reducer';
import consultaReciclagem from './consultaReciclagem/reducer';
import consultaSegundaViaReabilitacao from './consultaSegundaViaReabilitacao/reducer';
import confirmaPreCadastroReabilitacao from './confirmaPreCadastroReabilitacao/reducer';
import gerarRenach from './gerarRenach/reducer';

export default combineReducers({
	consultaReabilitacao,
	consultaReciclagem,
	consultaSegundaViaReabilitacao,
	confirmaPreCadastroReabilitacao,
	gerarRenach,
});
