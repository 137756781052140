import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConsultarQueimaDareRequest } from './types';
import {
	consultarQueimaDareClear,
	consultarQueimaDareFailure,
	consultarQueimaDareSuccess,
} from './actions';

function* consultarQueimaDare(request: ReducerAction) {
	const { payload }: { payload: IConsultarQueimaDareRequest } = request;

	yield put(consultarQueimaDareClear());

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`relatorios/queima-dare`,
		{
			dtInicialQueima: payload.data_inicial,
			dtFinalQueima: payload.data_final,
		},
	);

	if (response.status === 200) {
		yield put(consultarQueimaDareSuccess(response.status, response.data));
	} else {
		yield put(consultarQueimaDareFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_QUEIMA_DARE_REQUEST, consultarQueimaDare),
]);
