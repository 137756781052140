import { ReducerAction } from 'store/modules/types';
import { Types, IDadosAluno } from './types';

export function mantemDadosAlunoRequest(payload: IDadosAluno): ReducerAction {
	return {
		type: Types.MANTEM_DADOS_ALUNO_REQUEST,
		payload,
	};
}

export function mantemDadosAlunoSuccess(payload: IDadosAluno) {
	return {
		type: Types.MANTEM_DADOS_ALUNO_SUCCESS,
		payload,
	};
}

export function mantemDadosAlunoFailure() {
	return {
		type: Types.MANTEM_DADOS_ALUNO_FAILURE,
		payload: null,
	};
}
