import { combineReducers } from 'redux';

import pesquisarAluno from './PesquisarAluno/reducer';
import consultarFichaAluno from './ConsultarFicha/reducer';
import mantemDadosConsulta from './mantemDadosConsulta/reducer';
import mantemDadosAluno from './mantemDadosAluno/reducer';

export default combineReducers({
	pesquisarAluno,
	consultarFichaAluno,
	mantemDadosConsulta,
	mantemDadosAluno,
});
