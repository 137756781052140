import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_MP } from 'services/_path';
import { Types } from './types';
import {
	enviaSolicitacaoMPSuccess,
	enviaSolicitacaoMPFailure,
} from './actions';

function* enviaSolicitacaoMPRequest({ payload }: any) {
	const response: ApiResponse = yield call(
		postApi,
		PATH_MP,
		`mp/${CHANNEL}/solicitacao`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(enviaSolicitacaoMPSuccess(response));
	} else if (response.error) {
		yield put(enviaSolicitacaoMPFailure(response.message));
	}
}

export default all([
	takeLatest(Types.ENVIA_SOLICITACAO_MP_REQUEST, enviaSolicitacaoMPRequest),
]);
