import { ReducerAction } from 'store/modules/types';
import { Types, IRequestWithHeader } from './types';

export function emitirTransferenciasMunicipiosRequest(
	payload: IRequestWithHeader,
): ReducerAction {
	return {
		type: Types.EMITIR_TRANSFERENCIAS_MUNICIPIOS_REQUEST,
		payload,
	};
}

export function emitirTransferenciasMunicipiosSuccess(
	payload: any,
): ReducerAction {
	return {
		type: Types.EMITIR_TRANSFERENCIAS_MUNICIPIOS_SUCCESS,
		payload,
	};
}

export function emitirTransferenciasMunicipiosFailure(): ReducerAction {
	return {
		type: Types.EMITIR_TRANSFERENCIAS_MUNICIPIOS_FAILURE,
		payload: null,
	};
}

export function emitirTransferenciasMunicipiosClear(): ReducerAction {
	return {
		type: Types.EMITIR_TRANSFERENCIAS_MUNICIPIOS_CLEAR,
		payload: null,
	};
}
