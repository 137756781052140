import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import { Row, Col, Modal } from 'antd';

// ROUTES
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import { OptionProps } from 'components/Common/Form/Select';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { addNotifications } from 'store/modules/app/notifications/actions';
import { consultaTokenCnhDigitalClear } from 'store/modules/api/detranCnh/cnhDigital/tokenDigital/actions';
import {
	validaTokenCnhDigitalClear,
	validaTokenCnhDigitalRequest,
} from 'store/modules/api/detranCnh/cnhDigital/validaToken/actions';

// FORM
import Section from 'components/Common/Section';

// UTILS
import { limparMascara } from 'utils/genericFunctions';

// COMPONENTS
import Alert from 'components/Common/Notifications/Alert';
import DadosHabilitacao from '../PreCadastro/DadosHabilitacao';
import DadosPreCadastro from '../PreCadastro/DadosPreCadastro/index';

import {
	FormCnhEstrangeiro,
	CnhEstrangeiroModalInfo,
	IFormStatusCondutor,
	schemaDefault,
	initialValues,
} from './form';

// STYLE
import 'pages/DetranCnh/styles.css';

interface StatusCondutorCnhEstrangeiroProps {
	dadosHabilitacao?: IFormStatusCondutor;
	dadosPreCadastro: FormCnhEstrangeiro;
	// eslint-disable-next-line react/require-default-props
	listaMunicipios?: OptionProps[] | null;
	handleSubmitWithToken: (values: FormCnhEstrangeiro) => void;
	handleSubmitWithoutToken: (values: FormCnhEstrangeiro) => void;
	schema?: Yup.ObjectSchema<
		Yup.Shape<FormCnhEstrangeiro | undefined, FormCnhEstrangeiro>,
		object
	>;
}

const StatusCondutorCnhEstrangeiro = ({
	dadosHabilitacao,
	dadosPreCadastro,
	handleSubmitWithToken,
	handleSubmitWithoutToken,
	schema,
	listaMunicipios = null,
}: StatusCondutorCnhEstrangeiroProps) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { validaTokenCnhDigital } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cnhDigital,
	);

	const [mensagensInfo, setMensagensInfo] = useState<Array<string>>([]);
	const [modalInfo, setModalInfo] = useState<CnhEstrangeiroModalInfo>({
		type: '',
		message: '',
	});
	const [valuesToSubmit, setValuesToSubmit] =
		useState<FormCnhEstrangeiro>(initialValues);

	const validateToken = (values: FormCnhEstrangeiro) => {
		const data = {
			cpf: values.cpf,
			ddd: limparMascara(values.celular).substring(0, 2),
			celular: limparMascara(values.celular).substring(2, 11),
			email: values.email ? values.email : '',
			token: values.codigoVerificador ? values.codigoVerificador : '',
		};

		dispatch(validaTokenCnhDigitalRequest(data));
	};

	const handleSubmitValidate = useCallback(
		values => {
			if (
				values.flagExameToxicologico === 'N' &&
				values.categoriaPretendida !== '' &&
				values.categoriaPretendida !== 'A' &&
				values.categoriaPretendida !== 'B' &&
				values.categoriaPretendida !== 'AB' &&
				values.categoriaPretendida !== 'X' &&
				values.categoriaPretendida !== 'XB'
			) {
				addNotifications({
					errors: [
						'De acordo com a Resolução Contran nº 843/2021, deve ser providenciado antecipadamente, para apresentação ao médico de trânsito, o exame toxicológico para os processos de Habilitação que envolvem as categorias C, D e E.',
					],
				});
				return;
			}

			if (
				(values.codigoVerificador && values.codigoVerificador !== '') ||
				values.flagSolicitaCNHDigital === 'S'
			) {
				validateToken(values);
				setValuesToSubmit(values);
			} else if (values.flagSolicitaCNHDigital !== 'S') {
				handleSubmitWithoutToken(values);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch],
	);

	const submitWithToken = () => {
		dispatch(validaTokenCnhDigitalClear());
		handleSubmitWithToken(valuesToSubmit);
	};

	const handleCleanNotification = () => {
		setMensagensInfo([]);
	};

	const handleCleanModalInfo = () => {
		if (modalInfo.type === 'success') {
			setModalInfo({
				type: '',
				message: '',
			});
			submitWithToken();
		} else {
			setModalInfo({
				type: '',
				message: '',
			});
			dispatch(validaTokenCnhDigitalClear());
		}
	};

	useEffect(() => {
		dispatch(consultaTokenCnhDigitalClear());
		dispatch(validaTokenCnhDigitalClear());
	}, [dispatch]);

	useEffect(() => {
		if (
			validaTokenCnhDigital.status === 200 &&
			validaTokenCnhDigital.data?.valido
		) {
			setModalInfo({
				type: 'success',
				message: 'O código verificador informado confere com o código gerado.',
			});
			window.scrollTo(0, 0);
		} else if (
			validaTokenCnhDigital.status === 200 &&
			validaTokenCnhDigital.data?.valido === false
		) {
			setModalInfo({
				type: 'alert',
				message: 'Código verificador inválido.',
			});
			window.scrollTo(0, 0);
		}
	}, [dispatch, validaTokenCnhDigital]);

	return (
		<>
			<Section title="Status do Condutor">
				<strong>
					<h3>{dadosHabilitacao?.nome}</h3>
				</strong>
				{mensagensInfo.length > 0 &&
					mensagensInfo.map((message: string) => (
						<Alert
							key={message}
							type="info"
							message={message}
							onClose={handleCleanNotification}
						/>
					))}
				{dadosHabilitacao && dadosHabilitacao.cpf !== '' && (
					<DadosHabilitacao consultaCnhEstrangeiro={dadosHabilitacao} />
				)}
				<DadosPreCadastro
					renderData={dadosPreCadastro}
					handleSubmit={handleSubmitValidate}
					setMensagensInfo={setMensagensInfo}
					clearNotification={handleCleanNotification}
					schema={schema || schemaDefault}
					listaMunicipios={listaMunicipios}
				/>
			</Section>
			<Row justify="space-between" align="middle">
				<Col>
					<ButtonVoltar />
				</Col>
				<Col>
					<ButtonImage
						type="button"
						src="outro-servico-detran"
						onClick={() => history.push(ROUTE_DETRAN_CNH)}
					/>
				</Col>
			</Row>
			<Modal
				centered
				title="Mensagem"
				className="cnh-digital__modal"
				maskClosable={false}
				visible={modalInfo.message !== ''}
				style={{ animationDuration: '0s', color: 'red' }}
				footer={null}
				onCancel={handleCleanModalInfo}
			>
				<div>
					<p
						style={
							modalInfo.type === 'success'
								? { color: '#006ED7', fontWeight: 'bold' }
								: { color: 'red', fontWeight: 'bold' }
						}
					>
						{modalInfo.message}
					</p>
					<ButtonImage
						type="button"
						src="btn-ok"
						onClick={handleCleanModalInfo}
					/>
				</div>
			</Modal>
		</>
	);
};

export default StatusCondutorCnhEstrangeiro;

StatusCondutorCnhEstrangeiro.defaultProps = {
	dadosHabilitacao: null,
	schema: null,
};
