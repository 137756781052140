import escolherPlacaSaga from './EscolherPlaca/saga';
import primeiroEmplacamentoSaga from './PrimeiroEmplacamento/saga';
import licenciamentoVeiculoSaga from './LicenciamentoVeiculo/saga';
import transferenciaPropietarioSaga from './TransferenciaPropietario/saga';
import cancelarAutorizacaoPreviaSaga from './CancelarAutorizacaoPrevia/saga';
import consultarAutorizacaoPreviaSaga from './ConsultarAutorizacaoPrevia/saga';
import consultarDetalhesAutorizacaoPreviaSaga from './consultarDetalhesAutorizacaoPrevia/saga';
import consultarVeiculoV110Saga from './ConsultarVeiculoV110/saga';
import consultaPlacaDisponivelSaga from './consultaPlacaDisponivel/saga';
import consultaUnidadeTransitoSaga from './consultaUnidadeTransito/saga';
import confirmarPlacaSaga from './confirmarPlaca/saga';
import imprimirCrvleSaga from './imprimirCrvle/saga';
import emitirAutorizacaoPreviaSaga from './emitirAutorizacaoPrevia/saga';
import consultarVeiculoV120Saga from './ConsultarVeiculoV120/saga';
import primeiroEmplacamentoEmissaoSaga from './primeiroEmplacamentoEmissao/saga';
import emitirSegundaViaCrvSaga from './emitirSegundaViaCRV/saga';
import emitirBaixaVeiculoSaga from './emitirBaixaVeiculo/saga';
import emitirAlteracaoDadosCadastraisSaga from './emitirAlteracaoDadosCadastrais/saga';
import emitirModificacaoDadosCadastraisSaga from './emitirModificacaoDadosCadastrais/saga';
import emitirTransferenciasProprietariosSaga from './emitirTransferenciasProprietarios/saga';
import emitirTransferenciasMunicipiosEstadosSaga from './emitirTransferenciasMunicipiosEstados/saga';
import emitirTransferenciaMunicipioSaga from './emitirTransferenciaMunicipio/saga';
import emitirTrocaVoluntariaPlacaSaga from './emitirTrocaVoluntariaPlaca/saga';
import emitirEscolhaPlacaPersonalizadaSaga from './emitirEscolhaPlacaPersonalizada/saga';
import emitirSegundaViaPlacaSaga from './emitirSegundaViaPlaca/saga';
import consultarVeiculoSegundaViaPlacaSaga from './consultarVeiculoSegundaViaPlaca/saga';
import cancelarPlacaPrimeiroEmplacamentoSaga from './cancelarPlacaPrimeiroEmplacamento/saga';
import tipoArquivoSaga from './tipoArquivoEmissao/saga';
import consultarAcompanhamentoSaga from './ConsultarAcompanhamento/saga';
import downloadDocumentosSaga from './DownloadDocumentos/saga';
import reenviarDocumentosSaga from './reenviarDocumentos/saga';
// DESBLOQUEIO
import consultaRestricoesSaga from './desbloqueio/consultaRestricoes/saga';
import registraSolicitacoesSaga from './desbloqueio/registraSolicitacoes/saga';

export const escolherPlaca = escolherPlacaSaga;
export const primeiroEmplacamento = primeiroEmplacamentoSaga;
export const licenciamentoVeiculo = licenciamentoVeiculoSaga;
export const transferenciaPropietario = transferenciaPropietarioSaga;
export const cancelarAutorizacaoPrevia = cancelarAutorizacaoPreviaSaga;
export const consultarAutorizacaoPrevia = consultarAutorizacaoPreviaSaga;
export const consultarDetalhesAutorizacaoPrevia =
	consultarDetalhesAutorizacaoPreviaSaga;
export const consultarVeiculoV110 = consultarVeiculoV110Saga;
export const consultaPlacaDisponivel = consultaPlacaDisponivelSaga;
export const consultaUnidadeTransito = consultaUnidadeTransitoSaga;
export const confirmarPlaca = confirmarPlacaSaga;
export const imprimirCrvle = imprimirCrvleSaga;
export const emitirAutorizacaoPrevia = emitirAutorizacaoPreviaSaga;
export const consultarVeiculoV120 = consultarVeiculoV120Saga;
export const primeiroEmplacamentoEmissao = primeiroEmplacamentoEmissaoSaga;
export const emitirSegundaViaCrv = emitirSegundaViaCrvSaga;
export const emitirBaixaVeiculo = emitirBaixaVeiculoSaga;
export const emitirAlteracaoDadosCadastrais =
	emitirAlteracaoDadosCadastraisSaga;
export const emitirModificacaoDadosCadastrais =
	emitirModificacaoDadosCadastraisSaga;
export const emitirTransferenciasProprietarios =
	emitirTransferenciasProprietariosSaga;
export const emitirTransferenciasMunicipiosEstados =
	emitirTransferenciasMunicipiosEstadosSaga;
export const emitirTransferenciaMunicipio = emitirTransferenciaMunicipioSaga;
export const emitirTrocaVoluntariaPlaca = emitirTrocaVoluntariaPlacaSaga;
export const emitirEscolhaPlacaPersonalizada =
	emitirEscolhaPlacaPersonalizadaSaga;
export const emitirSegundaViaPlaca = emitirSegundaViaPlacaSaga;
export const consultarVeiculoSegundaViaPlaca =
	consultarVeiculoSegundaViaPlacaSaga;
export const cancelarPlacaPrimeiroEmplacamento =
	cancelarPlacaPrimeiroEmplacamentoSaga;
export const tipoArquivoEmissao = tipoArquivoSaga;
export const consultarAcompanhamento = consultarAcompanhamentoSaga;
export const downloadDocumentos = downloadDocumentosSaga;
export const reenviarDocumentos = reenviarDocumentosSaga;
export const consultaRestricoes = consultaRestricoesSaga;
export const registraSolicitacoes = registraSolicitacoesSaga;
