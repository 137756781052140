import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultaPermissionarioPontuadoClear,
	consultaPermissionarioPontuadoRequest,
} from 'store/modules/api/detranCnh/eportal/consultaPermissionarioPontuado/actions';
import {
	relatorioAgendamentoMedicoPsicologicoClear,
	relatorioAgendamentoMedicoPsicologicoRequest,
} from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import { eventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/actions';
import {
	consultaProcessoAbertoClear,
	consultaProcessoAbertoRequest,
} from 'store/modules/api/detranCnh/renachIntegrado/consultaProcessoAberto/actions';

// COMPONENTS
import Pesquisa from 'pages/DetranCnh/components/pages/PreCadastro/Pesquisa';

// UTILS
import { limparMascara } from 'utils/genericFunctions';
import { threatValuesStartSessionEventRequest } from 'utils/functions/threatEventsRequest';
import { CONSULTA_PERMISSIONARIO_PONTUADO_ERROR_RESPONSE_SERVICE_MESSAGE } from 'pages/DetranCnh/utils/constants/serviceResponseMessages';
import { treatAgendamentoMedicoPsicologicoRequestInPesquisar } from 'pages/DetranCnh/utils/threatServicesRequest';

// ROUTES
import { ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_STATUS_CONDUTOR } from 'pages/DetranCnh/routes/paths';
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// FORM
import { treatValues } from 'pages/DetranCnh/components/pages/PreCadastro/Pesquisa/form';

const PesquisaPermissionarioPontuado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const tipoDocumentoEnum = [
		{
			value: 'CPF',
			label: 'CPF',
		},
		{
			value: 'RG',
			label: 'RG',
		},
		{
			value: 'REGISTRO',
			label: 'REGISTRO',
		},
	];

	const { consultaPermissionarioPontuado } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.eportal,
	);

	const { relatorio } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.agendamentoMedicoPsicologico,
	);

	const { mainframe } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { consultaProcessoAberto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const handleClear = useCallback(() => {
		dispatch(clearNotifications());
		dispatch(consultaPermissionarioPontuadoClear());
		dispatch(consultaProcessoAbertoClear());
		dispatch(relatorioAgendamentoMedicoPsicologicoClear());
	}, [dispatch]);

	const handleSubmit = useCallback(
		(values: any) => {
			handleClear();

			const valuesRequest = treatValues(values, {
				usuario: mainframe.idUsuario,
				senha: mainframe.senhaMainFrame,
			});

			const valuesStartSessionEvent = threatValuesStartSessionEventRequest(
				atendimento,
				loginUnico,
			);
			dispatch(eventoInicioSessaoRequest(valuesStartSessionEvent));
			dispatch(consultaPermissionarioPontuadoRequest(valuesRequest));
		},
		[
			atendimento,
			handleClear,
			dispatch,
			loginUnico,
			mainframe.idUsuario,
			mainframe.senhaMainFrame,
		],
	);

	useEffect(() => {
		handleClear();
	}, [handleClear]);

	useEffect(() => {
		if (
			consultaProcessoAberto.status === 0 &&
			((consultaPermissionarioPontuado.status === 200 &&
				consultaPermissionarioPontuado.data?.cpf) ||
				(consultaPermissionarioPontuado.status === 400 &&
					consultaPermissionarioPontuado.requestData?.cpf &&
					consultaPermissionarioPontuado.dataError?.mensagem
						?.trim()
						.toLowerCase() ===
						CONSULTA_PERMISSIONARIO_PONTUADO_ERROR_RESPONSE_SERVICE_MESSAGE.CONDUTOR_PROCESSO_ABERTO.toLowerCase()))
		) {
			dispatch(
				consultaProcessoAbertoRequest({
					cpf:
						(consultaPermissionarioPontuado.data?.cpf &&
							limparMascara(consultaPermissionarioPontuado.data.cpf)) ||
						(consultaPermissionarioPontuado.requestData?.cpf &&
							limparMascara(consultaPermissionarioPontuado.requestData.cpf)) ||
						'',
					ident: 0,
				}),
			);
		}
	}, [
		consultaProcessoAberto.status,
		consultaPermissionarioPontuado.status,
		consultaPermissionarioPontuado.data,
		consultaPermissionarioPontuado.requestData,
		consultaPermissionarioPontuado.dataError,
		dispatch,
	]);

	useEffect(() => {
		if (
			(relatorio.data || !relatorio.data) &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			consultaProcessoAberto.data?.message
		) {
			addNotifications({
				errors: [consultaProcessoAberto.data.message],
			});
		}
	}, [consultaProcessoAberto.data, dispatch, relatorio.data]);

	useEffect(() => {
		if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			(consultaProcessoAberto.data.dataExame ||
				consultaProcessoAberto.data.dataExamePsicologo)
		) {
			const requestData = treatAgendamentoMedicoPsicologicoRequestInPesquisar(
				consultaProcessoAberto.data,
			);

			dispatch(relatorioAgendamentoMedicoPsicologicoRequest(requestData));
		} else if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) === 0
		) {
			history.push(ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_STATUS_CONDUTOR);
		}
	}, [
		consultaProcessoAberto.data,
		consultaProcessoAberto.status,
		dispatch,
		history,
	]);

	return (
		<Pesquisa
			handleSubmit={handleSubmit}
			handleClear={handleClear}
			relatorio={relatorio}
			routeBackButton={ROUTE_DETRAN_CNH}
			specificEnum={tipoDocumentoEnum}
		/>
	);
};
export default PesquisaPermissionarioPontuado;
