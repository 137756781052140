import { ReducerAction } from 'store/modules/types';

import { GerarRenachIntegradoRequest, Types } from './types';

export function gerarRenachIntegradoRequest(
	payload: GerarRenachIntegradoRequest,
): ReducerAction {
	return {
		type: Types.GERAR_RENACH_INTEGRADO_REQUEST,
		payload,
	};
}
export function gerarRenachIntegradoSuccess(payload: object): ReducerAction {
	return {
		type: Types.GERAR_RENACH_INTEGRADO_SUCCESS,
		payload,
	};
}
export function gerarRenachIntegradoFailure(payload: object): ReducerAction {
	return {
		type: Types.GERAR_RENACH_INTEGRADO_FAILURE,
		payload,
	};
}

export function gerarRenachIntegradoClear(): any {
	return {
		type: Types.GERAR_RENACH_INTEGRADO_CLEAR,
		payload: null,
	};
}
