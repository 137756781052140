// TYPES
import { ReducerAction } from 'store/modules/types';
import { PesquisaNominal, Types } from './types';

export function pesquisaNominalResquest(
	payload: PesquisaNominal,
): ReducerAction {
	return {
		type: Types.PESQUISA_NOMINAL_REQUEST,
		payload,
	};
}

export function limpaPesquisaNominal(): ReducerAction {
	return {
		type: Types.LIMPA_PESQUISA_NOMINAL,
		payload: null,
	};
}
