import React, { useCallback, useEffect } from 'react';

import { Col, Row } from 'antd';

// ROOUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';
import { ROUTE_IIRGD_DARE_SUCESSO } from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { dependentesDareAdicionar } from 'store/modules/api/dare/dependentesDare/actions';
import {
	gerarDareRequest,
	IRequestGerarDare,
} from 'store/modules/api/gerarDare/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import { limparMascara } from 'utils/genericFunctions';

import { Formulario } from './Formulario';
import { Dependentes } from './Dependentes';

const KitFamilia: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { gerarDare } = useSelector((state: ApplicationState) => state.api);

	const { dependentesDare } = useSelector(
		(state: ApplicationState) => state.api.dare,
	);

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	useEffect(() => {
		if (!cadastroRg.form.principal) {
			history.push(ROUTE_IIRGD);
		}
	}, [cadastroRg.form.principal, history]);

	// Cria as taxas DARE.
	useEffect(() => {
		if (
			!dependentesDare.data &&
			cadastroRg.form.principal &&
			!cadastroRg.form.observacoes.segundaVia
		) {
			const { numeroRg, nome, dataNascimento } = cadastroRg.form.principal;

			const dependentesIniciais = [];

			// Cria a taxa de Correio.
			dependentesIniciais.push({
				nome,
				numeroRg,
				dataNascimento,
				grauParentesco: '',
				segundaVia: false, // Se não é segunda via, é Correio.
			});

			dispatch(dependentesDareAdicionar(dependentesIniciais));
		} else if (
			!dependentesDare.data &&
			cadastroRg.form.principal &&
			cadastroRg.form.observacoes.segundaVia
		) {
			const { numeroRg, nome, dataNascimento } = cadastroRg.form.principal;
			const { declaracaoPobreza, isento2Via062, isento2Via10952 } =
				cadastroRg.form.observacoes.segundaVia;

			const dependentesIniciais = [];

			// Cria a taxa de Correio.
			dependentesIniciais.push({
				nome,
				numeroRg,
				dataNascimento,
				grauParentesco: '',
				segundaVia: false, // Se não é segunda via, é Correio.
			});

			// Verifica se o cidadão possui isenção da segunda via.
			if (!declaracaoPobreza && !isento2Via062 && !isento2Via10952) {
				// Cria a taxa de segunda via.
				dependentesIniciais.unshift({
					nome,
					numeroRg,
					dataNascimento,
					grauParentesco: '',
					segundaVia: true,
				});
			}

			dispatch(dependentesDareAdicionar(dependentesIniciais));
		}
	}, [dependentesDare.data, cadastroRg.form, dispatch]);

	// Redirecionamento para tela de Sucesso.
	useEffect(() => {
		if (gerarDare.data) {
			history.push(ROUTE_IIRGD_DARE_SUCESSO);
		}
	}, [gerarDare.data, history]);

	const handleAvancar = useCallback(() => {
		if (cadastroRg.form.principal && dependentesDare.data) {
			const { enderecoResidencia } = cadastroRg.form.principal;

			const values: IRequestGerarDare = {
				cpf: limparMascara(dependentesDare.cpfPrincipal),
				enderecoContribuinte: enderecoResidencia,
				postoCodigo: Number(login.user.posto),
				postoDescricao: login.user.nomePosto,
				ipUsuario: login.user.ip,
				// emailTo: email,
				taxasReq: [],
				// CONFIRMAR QUAL RG DO KIT INTEIRO DEVE SER ENVIADO
				idCidadao: cadastroRg.form.principal.numeroRg,
				usuario: login.mainframe.idUsuario,
				senha: login.mainframe.senhaMainFrame,
			};

			const taxasReq = dependentesDare.data.map(dependente => {
				return {
					taxasCorreio: !dependente.segundaVia,
					numeroRg: dependente.numeroRg,
					nome: dependente.segundaVia
						? '2ª Via do RGE/CIN'
						: 'Taxa de postagem',
				};
			});

			dispatch(
				gerarDareRequest({
					...values,
					taxasReq,
				}),
			);
		}
	}, [cadastroRg.form.principal, dependentesDare, login, dispatch]);

	return (
		<>
			<Section title="Adicionar Dependente Pagante de TAXA">
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Formulario />
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Dependentes />
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row justify="end">
					<Col>
						<ButtonImage src="avancar" onClick={handleAvancar} />
					</Col>
				</Row>
			</Section>

			<ButtonVoltar />
		</>
	);
};

export default KitFamilia;
