import React, { FC, useCallback, useEffect, useState } from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Col, Row } from 'antd';

import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import Select from 'components/Common/Form/Select';
import Section from 'components/Common/Section';

import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ApplicationState } from 'store';
import data from 'store/modules/enum';
import hasError from 'utils/getFormErrors';

import {
	alteraEnderecoRequest,
	limparAlteraEndereco,
} from 'store/modules/api/bolsaPovo/alteraEndereco/action';
import { IRequestAlteraEndereco } from 'store/modules/api/bolsaPovo/alteraEndereco/types';

import { consultarTipoContatoRequest } from 'store/modules/api/bolsaPovo/consultarTipoContato/action';
import OutlineButton from 'components/Common/Buttons/Outline';
import {
	CadastrarContatoForm,
	CadastrarContatoRequest,
} from 'store/modules/api/bolsaPovo/cadastrarContato/types';
import {
	cadastrarContatoRequest,
	limparCadastrarContato,
} from 'store/modules/api/bolsaPovo/cadastrarContato/action';
import {
	consultarContatoRequest,
	limparConsultarContato,
} from 'store/modules/api/bolsaPovo/consultarContato/action';
import { ContatosConsultados } from 'store/modules/api/bolsaPovo/consultarContato/types';
import { limparExcluirContato } from 'store/modules/api/bolsaPovo/excluirContato/action';
import Alert from 'components/Common/Notifications/Alert';
import { limparAtualizarContato } from 'store/modules/api/bolsaPovo/atualizarContato/action';
import getValidationsErrors from 'utils/getValidationsErrors';
import { addNotifications } from 'store/modules/app/notifications/actions';
import { ROUTE_BOLSA_POVO_CONFIRMACAO } from '../routes/paths';
import {
	initialValues,
	// schema,
	treatValues,
	initialValuesCadastro,
	schemaContatos,
} from './form';
import CadastroContato from './CadastroContato';

const AlteraEndereco: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [
		initialValuesCadastroState,
		setInitialValuesCadastroState,
	] = useState<CadastrarContatoForm>(initialValuesCadastro);

	const [edicao, setEdicao] = useState<number>(0);
	const [showAlert, setShowAlert] = useState<boolean>(false);
	const [showAddressAlert, setShowAddressAlert] = useState<boolean>(false);

	const {
		consultarTipoContato,
		cadastrarContato,
		consultarContato,
		excluirContato,
		atualizarContato,
		alteraEndereco,
	} = useSelector((state: ApplicationState) => state.api.bolsaPovo);

	const { nome } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.user,
	);

	const { cpf } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.user,
	);

	useEffect(() => {
		dispatch(consultarTipoContatoRequest());
		dispatch(limparCadastrarContato());
		dispatch(limparConsultarContato());
		dispatch(limparAlteraEndereco());
		dispatch(limparAtualizarContato());
	}, [dispatch]);

	useEffect(() => {
		if (atualizarContato.status === 200) {
			setShowAlert(true);
			if (showAddressAlert) {
				setShowAddressAlert(false);
				dispatch(limparAlteraEndereco());
			}
			dispatch(consultarContatoRequest({ cpf: String(location.state) }));
		}
	}, [atualizarContato.status, dispatch, location.state, showAddressAlert]);

	useEffect(() => {
		if (alteraEndereco.status === 200) {
			setShowAddressAlert(true);
		}
	}, [alteraEndereco.status]);

	const [contatosEnviados, setContatosEnviados] = useState<
		CadastrarContatoRequest[]
	>([]);

	const handleSubmit = useCallback(
		(values: IRequestAlteraEndereco) => {
			if (showAlert) setShowAlert(false);
			const dadosAtendente = {
				nome,
				cpf,
			};
			const valuesFormated = treatValues(
				values,
				dadosAtendente,
				String(location.state),
			);

			dispatch(alteraEnderecoRequest(valuesFormated));
		},
		[showAlert, nome, cpf, location.state, dispatch],
	);

	const handleSubmitContato = useCallback(
		(contatoValues: CadastrarContatoForm) => {
			if (showAlert) setShowAlert(false);
			setEdicao(0);
			if (
				contatosEnviados.length === 4 &&
				contatosEnviados.filter(item => item.idTipoContato === 3).length ===
					0 &&
				contatoValues.idTipoContato !== 3
			) {
				addNotifications({
					errors: [
						'O máximo permitido são 5 contatos e é obrigatório Informar ao menos um celular.',
					],
				});
			} else if (contatosEnviados.length === 5) {
				addNotifications({
					errors: ['O máximo permitido são 5 contatos.'],
				});
			} else {
				dispatch(
					cadastrarContatoRequest({
						idCpf: String(location.state),
						idTipoContato: contatoValues.idTipoContato,
						valor: contatoValues.valor,
					}),
				);
			}
		},
		[contatosEnviados, dispatch, location.state, showAlert],
	);

	useEffect(() => {
		if (
			cadastrarContato.status === 200 &&
			cadastrarContato &&
			cadastrarContato.form
		) {
			dispatch(consultarContatoRequest({ cpf: String(location.state) }));
		}
	}, [cadastrarContato, dispatch, location.state]);

	useEffect(() => {
		if (
			cadastrarContato.status === 200 &&
			cadastrarContato &&
			cadastrarContato.form &&
			consultarContato.status === 200 &&
			consultarContato.data?.length
		) {
			const checkId = consultarContato.data.filter(
				(item: ContatosConsultados) =>
					item.valor === cadastrarContato?.form.valor,
			);
			if (checkId.length) {
				const newId = checkId[0].id;
				setContatosEnviados(prev => [
					...prev,
					{
						id: newId,
						idCpf: cadastrarContato?.form.idCpf,
						idTipoContato: cadastrarContato?.form.idTipoContato,
						valor: cadastrarContato?.form.valor,
					},
				]);
			}
			setInitialValuesCadastroState(prev => {
				return { idTipoContato: '', valor: prev.valor === '' ? ' ' : '' };
			});
			dispatch(limparCadastrarContato());
			dispatch(limparConsultarContato());
		}
	}, [
		cadastrarContato,
		consultarContato.data,
		consultarContato.status,
		dispatch,
	]);

	const handleUpdate = useCallback(() => {
		const newContatos = [...contatosEnviados];
		newContatos.filter(item => {
			if (item.id === atualizarContato.form?.id) {
				item.valor = atualizarContato.form?.valor;
			}
			return item;
		});

		setContatosEnviados(newContatos);
		dispatch(limparAtualizarContato());
		dispatch(limparConsultarContato());
	}, [atualizarContato, contatosEnviados, dispatch]);

	useEffect(() => {
		if (
			atualizarContato.status === 200 &&
			atualizarContato.form &&
			consultarContato.status === 200 &&
			consultarContato.data?.length
		) {
			handleUpdate();
		}
	}, [atualizarContato, consultarContato, handleUpdate]);

	const handleExcluirContato = useCallback(() => {
		const oldContatos = [...contatosEnviados];
		if (showAlert) setShowAlert(false);

		const indexToDelete = contatosEnviados.findIndex(
			(item: CadastrarContatoRequest) => item.id === excluirContato.form.id,
		);

		if (indexToDelete !== -1) {
			oldContatos.splice(indexToDelete, 1);
		}
		dispatch(limparExcluirContato());
		return setContatosEnviados(oldContatos);
	}, [contatosEnviados, dispatch, excluirContato.form.id, showAlert]);

	useEffect(() => {
		if (excluirContato.status === 200 && excluirContato.form) {
			handleExcluirContato();
		}
	}, [
		cadastrarContato,
		consultarContato.data,
		consultarContato.status,
		dispatch,
		excluirContato.form,
		excluirContato.status,
		handleExcluirContato,
	]);

	const getInput = (
		idTipoContato: number,
		formik: FormikProps<CadastrarContatoForm>,
	) => {
		let input = (
			<Field
				as={Input}
				type="text"
				name="valor"
				disabled
				placeholder="Escolha uma opção do tipo de contato"
				error={hasError(formik.errors, 'valores')}
				required
			/>
		);
		switch (idTipoContato) {
			case 1:
			case 2:
				input = (
					<Field
						as={InputMask}
						type="text"
						mask="(99)9999-9999"
						name="valor"
						maskChar=""
						error={hasError(formik.errors, 'valores')}
						required
						disabled={edicao}
					/>
				);

				break;
			case 3:
				input = (
					<Field
						as={InputMask}
						type="text"
						maskChar=""
						mask="(99)99999-9999"
						name="valor"
						error={hasError(formik.errors, 'valores')}
						required
					/>
				);

				break;
			case 4:
			case 5:
				input = (
					<Field
						as={Input}
						type="text"
						name="valor"
						disabled={
							!formik.values.idTipoContato ||
							formik.values.idTipoContato === null
						}
						error={hasError(formik.errors, 'valores')}
						required
					/>
				);

				break;
			default:
				input = (
					<Field
						as={Input}
						type="text"
						name="valor"
						disabled
						placeholder="Escolha uma opção do tipo de contato"
						error={hasError(formik.errors, 'valores')}
						required
					/>
				);
				break;
		}
		return input;
	};

	// useEffect(() => {
	// 	if (errors.length && edicao) {
	// 		dispatch(clearNotifications());
	// 	}
	// }, [dispatch, edicao, errors.length]);

	const handleConfirmacao = () => {
		const validaCadastro = contatosEnviados.filter(
			item => item.idTipoContato === 3 || item.idTipoContato === '3',
		);
		if (validaCadastro.length) {
			return history.push(ROUTE_BOLSA_POVO_CONFIRMACAO);
		}
		return addNotifications({
			errors: ['É Obrigatório Informar ao menos um celular.'],
		});
	};

	return (
		<>
			<h3
				style={{ textAlign: 'center', fontSize: '26px', padding: '20px 0px' }}
			>
				Informar meu endereço de correspondência - Bolsa do Povo
			</h3>
			<span style={{ color: '#ff0000', textAlign: 'center' }}>
				Esse endereço será utilizado para entrega do cartão do Bolsa do Povo,
				por isso deve ser um endereço de sua completa confiança
			</span>
			<Section>
				{showAddressAlert && (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Alert
								type="info"
								showIcon
								message="Endereço Atualizado com Sucesso."
								closable
								onClose={() => setShowAddressAlert(false)}
							/>
						</Col>
					</Row>
				)}
				<Section title="Dados de endereço de correspondência">
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValues}
						onSubmit={async values => handleSubmit(values)}
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[0, 10]}>
									<Col span={7}>
										<CEP
											title="CEP"
											name="dsCep"
											defaultValue={formik.values.dsCep}
											formik={formik}
											result={(res: IBuscarCepResultado) => {
												formik.setFieldValue('dsCep', res.cep);
												if (showAddressAlert) {
													setShowAddressAlert(false);
													dispatch(limparAlteraEndereco());
												}

												formik.setFieldValue(
													'dsBairro',
													res.bairro.substring(0, 70),
												);

												formik.setFieldValue(
													'dsEndereco',
													res.endereco.substring(0, 70),
												);
												formik.setFieldValue('dsUf', res.uf);
												formik.setFieldValue(
													'dsCodigoIbge',
													res.codigoMunicipio,
												);
												formik.setFieldValue('dsMunicipio', res.municipio);
											}}
										/>
									</Col>
									<Col span={11}>
										<Field
											as={Input}
											type="text"
											title="Endereço"
											name="dsEndereco"
											error={hasError(formik.errors, 'dsEndereco')}
											// required
										/>
									</Col>
									<Col span={5}>
										<Field
											as={Input}
											type="text"
											title="Número"
											name="dsNumero"
											error={hasError(formik.errors, 'dsNumero')}
										/>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={13}>
										<Field
											as={Input}
											type="text"
											title="Complemento"
											name="dsComplemento"
											error={hasError(formik.errors, 'dsComplemento')}
										/>
									</Col>
									<Col span={10}>
										<Field
											as={Input}
											type="text"
											title="Bairro"
											name="dsBairro"
											error={hasError(formik.errors, 'dsBairro')}
										/>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={13}>
										<Field
											as={Input}
											type="text"
											title="Cidade"
											name="dsMunicipio"
											error={hasError(formik.errors, 'dsMunicipio')}
										/>
									</Col>
									<Col span={10}>
										<Field
											as={Select}
											defaultFirstOption={false}
											options={data.uf}
											type="text"
											title="UF"
											name="dsUf"
											onChange={(value: string) => {
												formik.setFieldValue('dsUf', value);
											}}
											error={hasError(formik.errors, 'dsUf')}
										/>
									</Col>
								</Row>
								<Row justify="end" style={{ marginTop: '20px' }}>
									<Col span={4} offset={1}>
										<OutlineButton text="Atualizar Endereço" type="submit" />
									</Col>
									<Col span={1} />
								</Row>
							</Form>
						)}
					</Formik>
				</Section>
				<Section title="Dados de contato">
					{showAlert && (
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Alert
									type="info"
									showIcon
									message="Contato Atualizado com Sucesso."
									closable
									onClose={() => setShowAlert(false)}
								/>
							</Col>
						</Row>
					)}
					<span style={{ color: '#ff0000', textAlign: 'center' }}>
						Obrigatório Informar ao menos um celular.
					</span>
					<Formik
						enableReinitialize
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValuesCadastroState}
						validate={values => getValidationsErrors(values, schemaContatos)}
						onSubmit={values => handleSubmitContato(values)}
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[20, 10]} align="middle">
									<Col span={10}>
										<Field
											as={Select}
											options={consultarTipoContato.data ?? []}
											type="text"
											title="Tipo Contato"
											name="idTipoContato"
											defaultFirstOptionText="- SELECIONE O TIPO DE CONTATO -"
											onChange={(value: string) => {
												if (showAlert) setShowAlert(false);
												formik.setFieldValue('idTipoContato', value);
												formik.setFieldValue('valor', '');
											}}
											error={hasError(formik.errors, 'idTipoContato')}
											required
											disabled={edicao}
										/>
									</Col>
									<Col span={10}>
										{getInput(Number(formik.values.idTipoContato), formik)}
									</Col>
									<Col span={4}>
										<OutlineButton
											style={{ cursor: 'pointer' }}
											type="submit"
											text="Incluir"
											disabled={edicao >= 0}
										/>
									</Col>
								</Row>
								<CadastroContato
									contatosEnviados={contatosEnviados}
									edicao={edicao}
									setEdicao={setEdicao}
								/>
							</Form>
						)}
					</Formik>
				</Section>
				<Row justify="end" style={{ marginTop: '10px' }}>
					<Col span={2}>
						<OutlineButton
							text="Concluir"
							onClick={() => handleConfirmacao()}
						/>
					</Col>
				</Row>
			</Section>
		</>
	);
};

export default AlteraEndereco;
