// TYPES
import { ReducerAction } from 'store/modules/types';
import { AssuntosProblemasRequest, Types } from './types';

export function assuntosProblemasRequest(
	payload: AssuntosProblemasRequest,
): ReducerAction {
	return {
		type: Types.ASSUNTOS_PROBLEMAS__REQUEST,
		payload,
	};
}
export function assuntosProblemasSuccess(payload: object): ReducerAction {
	return {
		type: Types.ASSUNTOS_PROBLEMAS__SUCCESS,
		payload,
	};
}
export function assuntosProblemasFailure(payload: object): ReducerAction {
	return {
		type: Types.ASSUNTOS_PROBLEMAS__FAILURE,
		payload,
	};
}
export function assuntosProblemasClear(): ReducerAction {
	return {
		type: Types.ASSUNTOS_PROBLEMAS__CLEAR,
		payload: null,
	};
}
