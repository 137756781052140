export default {
	title: 'dark',
	fontScale: 0,
	typography: {
		primary: '#ffffff',
		secondary: '#666666',
		disabled: '#808080',
		error: '#ff0000',
	},
	colors: {
		background: '#181818',
		border: '#ffffff',
		disabled: {
			input: 'rgba(239, 239, 239, 0.7)',
		},
	},
	header: {
		backgroundColor: {
			primary: '#202020',
			secondary: '#181818',
		},
	},
	footer: {
		backgroundColor: '#202020',
	},
};
