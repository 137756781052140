export enum Types {
	TRANSFERIR_PONTUACAO = '@jalesTransito/TRANSFERIR_PONTUACAO',
	TRANSFERIR_PONTUACAO_SUCCESS = '@jalesTransito/TRANSFERIR_PONTUACAO_SUCCESS',
	TRANSFERIR_PONTUACAO_FAILURE = '@jalesTransito/TRANSFERIR_PONTUACAO_FAILURE',
}

export interface transferirPontuacao {
	status: number;
	data: IPontuacao | null;
}

interface IPontuacao {
	documento: string;
	numero: string;
}

export interface IRequestPontuacao {
	codigoAit: string;
	condutorNome: string;
	condutorCpf: string;
	condutorCnh: string;
	condutorCnhUf: string;
	condutorCnhCategoria: string;
	fotoCnh: string;
	fotoRg: string;
	fotoCpf: string;
	fotoContratoSocial: string;
	fotoFormulario: string;
	fotoProprietarioCnh: string;
	fotoProprietarioCpf: string;
	fotoProprietarioRg: string;
}
