export enum Types {
	CADASTRAR_SOLICITANTE_REQUEST = '@cdhu/CADASTRAR_SOLICITANTE_REQUEST',
	CADASTRAR_SOLICITANTE_SUCCESS = '@cdhu/CADASTRAR_SOLICITANTE_SUCCESS',
	CADASTRAR_SOLICITANTE_FAILURE = '@cdhu/CADASTRAR_SOLICITANTE_FAILURE',
	CADASTRAR_SOLICITANTE_CLEAR = '@cdhu/CADASTRAR_SOLICITANTE_CLEAR',
}

export interface CadastrarSolicitante {
	status: number;
	data: CadastrarSolicitanteData | null;
	form: CadastrarSolicitanteRequest | null;
}

interface CadastrarSolicitanteData {
	leadId: string;
  	mensagemField: string;
	statusField: number;
}

export interface CadastrarSolicitanteRequestOld {
	cartorio: string;
	cep: string;
	cidadeNascimento: string;
	clienteCdhu: string; // NAO,
	cnjHabOuCondominio: string;
	conjugeCompoeRenda: string; // SIM,
	cor: string; // NAO_INFORMADO,
	corConjuge: string; // NAO_INFORMADO,
	data: string; // yyyy-MM-ddTHH:mm:ssZ,
	dataAtualizObito: string; // yyyy-MM-ddTHH:mm:ssZ,
	dataNascimento: string; // yyyy-MM-ddTHH:mm:ssZ,
	dataNascimentoConjuge: string; // yyyy-MM-ddTHH:mm:ssZ,
	dataObito: string; // yyyy-MM-ddTHH:mm:ssZ,
	descricao: string;
	email: string; // XXXX@XXXX.XXX,
	emailConjuge: string; // XXXX@XXXX.XXX,
	enderecoCompleto: string;
	estadoCivil: string; // CASADO,
	folha: string;
	idade: number;
	livro: string;
	maeConjuge: string;
	nacionalidade: string;
	nascionalidadeConjuge: string;
	nomeConjuge: string;
	nomeMae: string;
	numeroDossie: string;
	numeroIdentidade: string;
	pactoAntenupcial: string; // yyyy-MM-ddTHH:mm:ssZ,
	pis: string;
	primeiroNome: string;
	profissaoConjuge: string; // APOSENTADO,
	profissaoSistemaLegado: string;
	regimeBens: string; // NAO_SE_APLICA,
	rgConjuge: string;
	sexo: string; // NAO_INFORMADO,
	sistemaOrigem: string; // CORPORATIVO,
	telCelular: string; // (XX)XXXXX-XXXX,
	telComercial: string; // (XX)XXXXX-XXXX,
	telContato: string; // (XX)XXXXX-XXXX,
	telResidencial: string; // (XX)XXXXX-XXXX,
	ufNascimento: string;
	ufRg: string; // SP,
	ufRgConjuge: string; // SP,
	dataCasamento: string; // yyyy-MM-ddTHH:mm:ssZ,
	rgRne: string; // RG,
	cpf: string;
	cpfConjuge: string;
	sobrenome: string;
}

export interface CadastrarSolicitanteRequest {
	cpf: string;
	nome?: string;
	primeiroNome?: string;
	sobrenome?: string;
	email: string;
	celular: string;
	telefoneResidencial: string;
	telefoneComercial: string;
	telefoneContato: string;
}
