import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// ROUTES
import { ROUTE_DETRAN_CRV } from 'routes/paths';
import { ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_DESBLOQUEAR } from 'pages/DetranCrv/routes/paths';

// REDUX
import {
	consultaRestricoesClear,
	consultaRestricoesRequest,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/desbloqueio/consultaRestricoes/actions';
import { ConsultaRestricoesRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/desbloqueio/consultaRestricoes/types';

// UTILS
import { threatRequestDataConsultaRestricoesDesbloqueio } from 'pages/DetranCrv/utils/threatServicesRequest';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { PesquisarPorPlacaRenavamCpfCnpj } from '../../../components/pages/ServicosDeVeiculos/PesquisarPorPlacaRenavamCpfCnpj';
import { IPesquisarPorPlacaRenavamCpfCnpj } from '../../../components/pages/ServicosDeVeiculos/PesquisarPorPlacaRenavamCpfCnpj/form';

export const LicenciamentoOcorrenciaObitoConsultar = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { consultaRestricoes } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const handleSubmit = (values: IPesquisarPorPlacaRenavamCpfCnpj) => {
		const newValues: ConsultaRestricoesRequest = {
			...values,
			opcao: 18, // 18 - Opcao para Licenciamento Com Óbito.
		};

		const valuesToConsultaRestricoesRequest =
			threatRequestDataConsultaRestricoesDesbloqueio(newValues);

		dispatch(consultaRestricoesRequest(valuesToConsultaRestricoesRequest));
	};

	useEffect(() => {
		dispatch(consultaRestricoesClear());
	}, [dispatch]);

	useEffect(() => {
		if (consultaRestricoes.status === 200) {
			history.push(ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_DESBLOQUEAR);
		}
	}, [consultaRestricoes, history]);

	return (
		<>
			<PesquisarPorPlacaRenavamCpfCnpj handleSubmit={handleSubmit} />

			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</>
	);
};
