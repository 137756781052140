import * as Yup from 'yup';

export interface IConsultarSegundaViaCrv {
	selecao: 'Chassi' | 'Placa';
	chassi: string;
	placa: string;
	documento: string;
	espelho: string;
	restricaoFinanceira?: string;
	categoria?: number | string;
}

export const initialValues: IConsultarSegundaViaCrv = {
	selecao: 'Chassi',
	chassi: '',
	placa: '',
	documento: '',
	espelho: '',
	categoria: '',
};

export const schema = Yup.object<IConsultarSegundaViaCrv>().shape({
	chassi: Yup.string().when('selecao', {
		is: selecao => selecao === 'Chassi',
		then: Yup.string()
			.required('Campo Obrigatório: Documento / Chassi.')
			.max(22, 'Campo Chassi: Tamanho máximo de 22 caracteres.'),
	}),
	placa: Yup.string().when('selecao', {
		is: selecao => selecao === 'Placa',
		then: Yup.string().required('Campo Obrigatório: Documento / Placa.'),
	}),
	documento: Yup.string().required('Campo Obrigatório: CPF/CNPJ Proprietário.'),
	categoria: Yup.string().required(`Campo Obrigatório: Categoria.`),
	documentoCategoria: Yup.string().when(['documento', 'categoria'], {
		is: (documento, categoria) =>
			documento.length <= 11 && categoria.toString() === '3',
		then: Yup.string().required(
			'Categoria oficial somente permitido para pessoa jurídica (CNPJ).',
		),
	}),
});
