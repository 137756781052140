export enum Types {
	CONSULTAR_QUEIMA_DARE_REQUEST = '@relatorios/CONSULTAR_QUEIMA_DARE_REQUEST',
	CONSULTAR_QUEIMA_DARE_FAILURE = '@relatorios/CONSULTAR_QUEIMA_DARE_FAILURE',
	CONSULTAR_QUEIMA_DARE_SUCCESS = '@relatorios/CONSULTAR_QUEIMA_DARE_SUCCESS',
	CONSULTAR_QUEIMA_DARE_CLEAR = '@relatorios/CONSULTAR_QUEIMA_DARE_CLEAR',
}

export interface IConsultarQueimaDareState {
	status: number;
	data: IConsultarQueimaDare[] | null;
}

export interface IConsultarQueimaDare {
	id: string;
	idQueimaDare: number;
	sistemaOrigem: string;
	tipoDocumento: string;
	nrDocumento: string;
	dsLocalidade: string;
	nmUsuario: string;
	nrDare: string;
	dataDare: string;
	vlDare: number;
	dataQueimaDare: string;
}

export interface IConsultarQueimaDareRequest {
	data_inicial: string;
	data_final: string;
}
