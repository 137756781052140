import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_RELATORIOS, CHANNEL } from 'services/_path';

// ENTITIES
import { IProtocoloFic as IRequestProtocoloFic } from 'pages/iirgd/RgFicDigital/StatusCadastro/Buttons/ImprimirProtocolo/functions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import { protocoloFicSuccess, protocoloFicFailure } from './actions';

function* protocoloFicRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestProtocoloFic } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_RELATORIOS,
		`protocolo/${CHANNEL}/fic`,
		payload,
	);

	if (response.status === 200) {
		yield put(protocoloFicSuccess(response.data));
	} else if (response.error) {
		yield put(protocoloFicFailure());
	}
}

export default all([
	takeLatest(Types.PROTOCOLO_FIC_REQUEST, protocoloFicRequest),
]);
