import { ConsultaConsumidorData } from '../consultaConsumidor/types';

export enum Types {
	CONSUMIDOR_SELECIONADO_REQUEST = '@procon/CONSUMIDOR_SELECIONADO_REQUEST',
	CONSUMIDOR_SELECIONADO_CLEAR = '@procon/CONSUMIDOR_SELECIONADO_CLEAR',
}

export interface ConsumidorSelecionado {
	data: null | ConsultaConsumidorData;
}
