import React, { useEffect, useState } from 'react';

// COMPONENTS
import CPF from 'components/Common/Form/Fields/CPF';
import Input from 'components/Common/Form/Input/Input';

// UTILS
import hasError from 'utils/getFormErrors';

// FORM
import { Field, FormikProps } from 'formik';
import { Col } from 'antd';
import { onlyNumbersAndLetters } from 'utils/genericFunctions';
import { IFormPesquisaRenovacao } from '../form';

interface IProps {
	formik: FormikProps<IFormPesquisaRenovacao>;
}

const Documento: React.FC<IProps> = ({ formik }) => {
	const [tipoDocumento, setTipoDocumento] = useState(
		formik.values.tipoDocumento,
	);

	useEffect(() => {
		if (tipoDocumento !== formik.values.tipoDocumento) {
			setTipoDocumento(formik.values.tipoDocumento);
		}
	}, [formik.values.tipoDocumento, tipoDocumento]);

	return (
		<>
			{tipoDocumento === 'CPF' && (
				<Col offset={1} span={8}>
					<CPF
						subtitle="(apenas números)"
						numero="cpf"
						formik={formik}
						error={hasError(formik.errors, 'cpf')}
						removeContainer
					/>
				</Col>
			)}
			{tipoDocumento === 'RG' && (
				<Col offset={1} span={8}>
					<Field
						as={Input}
						name="numeroRg"
						subtitle="(apenas números)"
						value={onlyNumbersAndLetters(formik.values.numeroRg)}
						error={hasError(formik.errors, 'numeroRg')}
						maxLength="11"
					/>
				</Col>
			)}
			{tipoDocumento === 'REGISTRO' && (
				<Col offset={1} span={8}>
					<Field
						as={Input}
						value={onlyNumbersAndLetters(formik.values.numeroRegistro)}
						name="numeroRegistro"
						subtitle="(apenas números)"
						error={hasError(formik.errors, 'numeroRegistro')}
						maxLength="11"
					/>
				</Col>
			)}
			{tipoDocumento === 'PGU' && (
				<Col offset={1} span={8}>
					<Field
						as={Input}
						value={onlyNumbersAndLetters(formik.values.numeroPgu)}
						name="numeroPgu"
						subtitle="(apenas números)"
						error={hasError(formik.errors, 'numeroPgu')}
						maxLength="11"
					/>
				</Col>
			)}
		</>
	);
};

export default Documento;
