import administrarRoutes from './Administrar/routes/routes';
import usuarioRoutes from './Usuarios/routes/routes';
import relatoriosRoutes from './Relatorios/routes/routes';
// import alterarSenhaRoutes from './AlterarSenha/routes/routes';

export const SGURoute = {
	name: 'Poupatempo - SGU',
	route: '/poupatempo',
};

const sguRoutes = administrarRoutes.concat(
	usuarioRoutes,
	relatoriosRoutes,
	// alterarSenhaRoutes,
);

export default sguRoutes;
