import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarOrgaoRequest } from './types';

export function cadastrarOrgaoRequest(
	data: CadastrarOrgaoRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_ORGAO,
		payload: data,
	};
}
export function cadastrarOrgaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_ORGAO_SUCCESS,
		payload,
	};
}
export function cadastrarOrgaoFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_ORGAO_FAILURE,
		payload: null,
	};
}
export function cadastrarOrgaoClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_ORGAO_CLEAR,
		payload: null,
	};
}
