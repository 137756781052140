import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { userDataRegex } from 'utils/constants/regex';
import * as Yup from 'yup';

export interface IFormStatusCondutor {
	servico?:
		| 'renovacao'
		| 'segundaVia'
		| 'adicaoCategoria'
		| 'mudancaCategoria'
		| 'cnhDefinitiva'
		| 'permissionarioPontuado'
		| 'permissaoInternacional'
		| 'reabilitacao'
		| 'transferenciaMunicipal'
		| 'cnhEstrangeira';
	status: string;
	statusMensagem: string;
	mensagemBloqueio: string;
	numeroRegistro: string;
	numeroPgu: string;
	renach: string;
	local: string;
	categoriaPretendida: string;
	cfcA: string;
	espelhoCnh: string;
	usoForm: string;
	situacaoAtual: string;
	flagAtividadeRemunerada?: string | boolean;
	cfcB: string;
	espelhoPid: string;
	dataPrimeiraHabilitacao: string;
	nome: string;
	dataValidadeCnh: string;
	nomePai: string;
	dataNascimento: string;
	nomeMae: string;
	sexo: string;
	nacionalidade: string;
	tipoDocumento: string;
	numeroRg: string;
	orgaoEmissor: string;
	localEmissao: string;
	cpf: string;
	rne1: string;
	rne2: string;
	rne3: string;
	naturalidade: string;
	identificacaoHabilitacao: string;
	pais: string;
	dataValidade: string;
	enderecoLogradouro: string;
	enderecoNumero: string;
	enderecoComplemento: string;
	enderecoCep: string;
	enderecoBairro: string;
	enderecoMunicipio: string;
	enderecoCodigoMunicipio: string;
	enderecoDescricaoMunicipio: string;
	mensagem: string;
	exameMedicoDataExameMedico: string;
	exameMedicoCategoriaPermitida: string;
	exameMedicoRestricaoMedica: string;
	exameMedicoCrm: string;
	exameMedicoCred1: string;
	exameMedicoCred2: string;
	exameMedicoRegiao: string;
	exameMedicoAptidaoMedica: string;
	examePsicotecnicoDataExamePsicotecnico: string;
	examePsicotecnicoLaudo: string;
	examePsicotecnicoResultado1: string;
	examePsicotecnicoResultado2: string;
	examePsicotecnicoCrp: string;
	examePsicotecnicoCred1: string;
	examePsicotecnicoCred2: string;
	examePsicotecnicoRegiao: string;
	examePsicotecnicoAptidaoPsicotecnica: string;
	dataExameTeorico: string;
	codigoExameTeorico: string;
	codigoEntidade: string;
	descricaoEntidade: string;
	dataExamePratico2Rodas: string;
	codigoExamePratico2Rodas: string;
	dataExamePratico4Rodas: string;
	codigoExamePratico4Rodas: string;
	dataUltimaEmissaoCnh: string;
	autorizaEmail?: boolean;
	autorizaSMS?: boolean;
	nomeSocial?: string;
	filiacao1?: string;
	filiacao2?: string;
	// pcadCpf: string;
	// pcadNome: string;
	// pcadNumeroRg: string;
	// pcadOrgaoEmissor: string;
	// pcadLocalEmissao: string;
	// pcadDataNascimento: string;
	// pcadRne: string;
	// pcadOrgaoEmissorRne: string;
	// pcadLocalEmissaoRne: string;
	// pcadCodigoPais: string;
	// pcadDescricaoPais: string;
	// pcadValidadeHabilitacaoEstrangeira: string;
	// pcadSexo: string;
	// pcadNumeroRegistro: string;
	// pcadNumeroPgu: string;
	// pcadNacionalidade: string;
	// pcadNaturalidade: string;
	// pcadNomePai: string;
	// pcadNomeMae: string;
	// pcadLogradouro: string;
	// pcadCep: string;
	// pcadBairro: string;
	// pcadMunicipio: string;
	// pcadProcesso: string;
	// pcadCiretran: string;
	// pcadCategoriaAtual: string;
	// pcadCategoriaPretendida: string;
	// pcadDataCadastro: string;
	// pcadHoraCadastro: string;
	// pcadUsuarioCadastro: string;
	listaOcorrencias: listaOcorrencias[];
	listaCursos: listaCursos[];
	cepPcon: string;
	cepPcad: string;
	mensagensAlerta: string | string[];
	restricaoMedica?: string;
}

export interface IFormPreCadastro {
	servico:
		| 'renovacao'
		| 'segundaVia'
		| 'adicaoCategoria'
		| 'mudancaCategoria'
		| 'cnhDefinitiva'
		| 'permissionarioPontuado'
		| 'permissaoInternacional'
		| 'reabilitacao'
		| 'transferenciaMunicipal'
		| 'cnhEstrangeira';
	flagAtividadeRemunerada: string | boolean;
	flagDeficienteFisico: string | boolean;
	flagExameToxicologico: string | boolean;
	flagExameDentroDoPosto: string | boolean;
	flagMilitarCfcCorporacao: string | boolean;
	codigoEntidade: string;
	numCarteiraMilitar: string;
	nome: string;
	cpf: string;
	numeroRg: string;
	rne1: string;
	rne2: string;
	rne3: string;
	nomePai: string;
	nomeMae: string;
	sexo: string;
	nacionalidade: string;
	uf: string;
	cep: string;
	enderecoNumero: string;
	enderecoBairro: string;
	telefone: string;
	celular: string;
	orgaoEmissor: string;
	localEmissao: string;
	dataNascimento: string;
	naturalidade: string;
	enderecoLogradouro: string;
	enderecoComplemento: string;
	enderecoCodigoMunicipio: string;
	enderecoDescricaoMunicipio: string;
	email: string;
	confirmaEmail: string;
	categoriaPretendida: string;
	autorizaSMS: string;
	autorizaEmail: string;
	cnhDigital: string;
	codigoVerificador: string;
	codigoPoupatempo: string | number;
	cpfUsuario: string;
	categoriaAtual: string;
	dataPrimeiraHabilitacao: string;
	numeroPgu: string;
	numeroRegistro: string;
	tipoDocumento: string;
	enderecoRecebimentoNumero: string;
	enderecoRecebimentoBairro: string;
	enderecoRecebimentoCep: string;
	enderecoRecebimentoLogradouro: string;
	enderecoRecebimentoComplemento: string;
	enderecoRecebimentoMunicipio: string;
}

interface listaOcorrencias {
	seqOcorrencia: string;
	tipoOcorrencia: string;
	informacaoOcorrencia: string;
}

interface listaCursos {
	codigoCurso: string;
	descricaoCurso: string;
	dataValidade: string;
}
interface radio {
	label: string;
	value: string;
}

export const radioSimNao = [
	{
		label: 'Sim',
		value: 'S',
	},
	{
		label: 'Não',
		value: 'N',
	},
];

export const showComponent = (list: string[], value: string) => {
	return list.includes(value);
};

export const radioTipoRenovacao = [
	{
		label: 'Renovação Via Impressa',
		value: 'N',
	},
	{
		label: 'Renovação Digital (Impressa + Digital)',
		value: 'S',
	},
];

export function radioTipoCnh(usoFormularioEnum: string): radio[] {
	return [
		{
			label: `${usoFormularioEnum} Via Impressa`,
			value: 'N',
		},
		{
			label: `${usoFormularioEnum} Via Digital (Impressa + Digital)`,
			value: 'S',
		},
	];
}

export function chooseEnumToRenderCnhDigital(usoFormularioEnum: string) {
	switch (usoFormularioEnum) {
		case 'RENOVACAO':
			return radioTipoCnh('Renovação');

		case 'CASSACAO':
			return radioTipoCnh('Reabilitação');

		case 'SEGUNDA_VIA':
			return radioTipoCnh('Segunda');

		case 'adicaoCategoria':
			return radioTipoCnh('CNH');

		case 'permissaoInternacional':
			return radioTipoCnh('CNH');

		case 'cnhDefinitiva':
			return radioTipoCnh('CNH');

		case 'MUDANCA_CATEGORIA':
			return radioTipoCnh('CNH');

		case 'PERMISSIONARIO':
			return radioTipoCnh('Reabilitação');

		case 'transferenciaMunicipal':
			return radioTipoCnh('CNH');

		case 'CNH_DEFINITIVA':
			return radioTipoCnh('');

		default:
			return radioTipoCnh('CNH');
	}
}

export const schemaGenerateToken = Yup.object<GerarRenachForm>().shape({
	celular: Yup.string()
		.required('Campo Obrigatório: Celular.')
		.matches(userDataRegex.cellPhoneNumber, {
			message: 'Campo Inválido: Celular.',
			excludeEmptyString: true,
		}),
	email: Yup.string()
		.when(['autorizaEmail'], {
			is: autorizaEmail => autorizaEmail === 'S' || autorizaEmail === true,
			then: Yup.string().required('Campo Obrigatório: Email.'),
		})
		.matches(userDataRegex.emailAddress, {
			message: 'Formato de e-mail inválido.',
			excludeEmptyString: true,
		})
		.test(
			'same-email',
			'A confirmação do e-mail é diferente do e-mail informado.',
			function verify(value) {
				return value !== '' ? this.parent.confirmaEmail === value : true;
			},
		),
});
