import produce from 'immer';

import { Types, EventoInicioSessao } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: EventoInicioSessao = {
	status: 0,
	data: null,
	detailsStartSection: null,
};

export default function eventoInicioSessaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): EventoInicioSessao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EVENTO_INICIO_SESSAO_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.EVENTO_INICIO_SESSAO_SUCCESS: {
				draft.status = action.payload.response.status;
				draft.data = null;
				draft.detailsStartSection = action.payload.request;
				break;
			}
			case Types.EVENTO_INICIO_SESSAO_FAILURE: {
				draft.status = action.payload.response.status;
				draft.data = null;
				draft.detailsStartSection = action.payload.request;
				break;
			}
			case Types.EVENTO_INICIO_SESSAO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.detailsStartSection = INITIAL_STATE.detailsStartSection;
				break;
			}
			default:
		}
	});
}
