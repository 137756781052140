import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ApiResponse, postApi } from 'services/_api';

import { PATH_DETRAN_CRV, CHANNEL } from 'services/_path';

import { ReducerAction } from 'store/modules/types';

import { Types, IPEPMRequest } from './types';

import { consultarPEPMSuccess, consultarPEPMFailure } from './actions';

function* consultarPEPMRequest(request: ReducerAction) {
	const { payload }: { payload: IPEPMRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`resumo-veiculo/${CHANNEL}/pesquisa/pepm`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarPEPMSuccess(response));
	} else if (response.error) {
		yield put(consultarPEPMFailure(response));
	}
}

export default all([takeLatest(Types.PEPM_REQUEST, consultarPEPMRequest)]);
