import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	SEXOS_REQUEST = '@procon/SEXOS_REQUEST',
	SEXOS_SUCCESS = '@procon/SEXOS_SUCCESS',
	SEXOS_FAILURE = '@procon/SEXOS_FAILURE',
	SEXOS_CLEAR = '@procon/SEXOS_CLEAR',
}

export interface Sexos {
	status: null | number;
	data: OptionProps[];
}
