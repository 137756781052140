import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DARE } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IStatusDareMainframeRequest } from './types';

// ACTIONS
import {
	consultarStatusDareMainframeSuccess,
	consultarStatusDareMainframeFailure,
	consultarStatusDareMainframeClear,
} from './actions';

function* consultarStatusDareMainframeRequest(request: ReducerAction) {
	const { payload }: { payload: IStatusDareMainframeRequest } = request;

	yield put(consultarStatusDareMainframeClear());
	const { rg, sequencia, usuario, terminal, digitoVerificador } = payload;
	const response: ApiResponse = yield call(
		postApi,
		PATH_DARE,
		`dare/${CHANNEL}/status-dare-mainframe`,
		{
			rg,
			sequencia,
			usuario,
			terminal,
			digitoVerificador,
		},
	);

	if (response.status === 200) {
		yield put(
			consultarStatusDareMainframeSuccess(response.status, response.data),
		);
	} else {
		yield put(consultarStatusDareMainframeFailure(204, null));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_STATUS_DARE_MAINFRAME_REQUEST,
		consultarStatusDareMainframeRequest,
	),
]);
