import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, ExcluirUsuarioMainframe } from './types';

export const INITIAL_STATE: ExcluirUsuarioMainframe = {
	status: 0,
	data: {
		id: null,
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ExcluirUsuarioMainframe {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EXCLUIR_USUARIO_MAINFRAME_REQUEST: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.EXCLUIR_USUARIO_MAINFRAME_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.EXCLUIR_USUARIO_MAINFRAME_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.EXCLUIR_USUARIO_MAINFRAME_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
