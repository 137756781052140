import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { Types, ConsultarStatusRequest } from './types';

// ACTIONS
import { consultarStatusSuccess, consultarStatusFailure } from './actions';

function* consultarStatus(request: ReducerAction) {
	const { payload }: { payload: ConsultarStatusRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarStatusRequest;

	const response: ApiResponse = yield call(getApi, PATH_SGU, `status`, body);

	if (response.status === 200) {
		yield put(consultarStatusSuccess(response));
	} else {
		yield put(consultarStatusFailure());
	}
}

export default all([takeLatest(Types.CONSULTAR_STATUS, consultarStatus)]);
