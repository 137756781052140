import React, { useCallback } from 'react';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';

// REDUX
import { useDispatch } from 'react-redux';
import { consultarDareRetaguardaClear } from 'store/modules/api/dare/consultarDareRetaguarda/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';

import { Formulario } from './Formulario';
import { Resultado } from './Resultado';

const DareRetaguarda: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const handleButtonVoltar = useCallback(() => {
		dispatch(consultarDareRetaguardaClear());
		history.push(ROUTE_IIRGD);
	}, [dispatch, history]);

	return (
		<>
			<Section title="DARE">
				<Formulario />

				<Resultado />
			</Section>

			<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
		</>
	);
};

export default DareRetaguarda;
