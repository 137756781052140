export enum Types {
	INCLUIR_INTENCAO_VENDA_REQUEST = '@DETRAN-CRV/INCLUIR_INTENCAO_VENDA_REQUEST',
	INCLUIR_INTENCAO_VENDA_SUCCESS = '@DETRAN-CRV/INCLUIR_INTENCAO_VENDA_SUCCESS',
	INCLUIR_INTENCAO_VENDA_FAILURE = '@DETRAN-CRV/INCLUIR_INTENCAO_VENDA_FAILURE',
	INCLUIR_INTENCAO_VENDA_CLEAR = '@DETRAN-CRV/INCLUIR_INTENCAO_VENDA_CLEAR',
}

export interface IncluirIntencaoVenda {
	status: number;
	data: IncluirIntencaoVendaResponse | null;
	dataError: IncluirIntencaoVendaDataError | null;
}

export interface IncluirIntencaoVendaDataError {
	status: number;
	trace: string;
	messages: string[];
}

export interface IncluirIntencaoVendaResponse {
	numeroATPVE: string;
	codigo: number;
}

export interface IncluirIntencaoVendaRequest {
	codigoDespachante: string;
	comprador: {
		bairro: string;
		cep: string;
		codigoMunicipio: string;
		complemento: string;
		documento: {
			numeroIdentificacao: string;
			tipo: string;
		};
		email: string;
		logradouro: string;
		nome: string;
		numero: string;
		uf: string;
		valorVenda: number;
	};
	proprietarioVendedor: {
		documento: {
			numeroIdentificacao: string;
			tipo: string;
		};
		email: string;
	};
	veiculo: {
		chassi: string;
		hodometro: number;
		placa: string;
		renavam: string;
	};
}
