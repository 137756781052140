import React, { useState, useCallback, useEffect } from 'react';

import { Row, Col } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	capturarBiometriaClearLeitura,
	capturarBiometriaRequest,
} from 'store/modules/api/biometria/capturarBiometria/actions';

import { cadastroRgConfrontoDigital } from 'store/modules/api/iirgd/cadastroRg/actions';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';

// IMAGES
import imagemMaoDireita from 'assets/images/coleta-digitais/imagemMaoDireita.png';
import imagemPD from 'assets/images/coleta-digitais/imagemPD.png';
import imagemID from 'assets/images/coleta-digitais/imagemID.png';
import imagemMD from 'assets/images/coleta-digitais/imagemMD.png';
import imagemMaoEsquerda from 'assets/images/coleta-digitais/imagemMaoEsquerda.png';
import imagemPE from 'assets/images/coleta-digitais/imagemPE.png';
import imagemIE from 'assets/images/coleta-digitais/imagemIE.png';
import imagemME from 'assets/images/coleta-digitais/imagemME.png';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import { confrontarDigitaisCnhRequest } from 'store/modules/api/utils/confrontarDigitaisCnh/actions';
import { threatValuesConfrontSessionEventRequest } from 'utils/functions/threatEventsRequest';
import { eventoConfrontoSessaoRequest } from 'store/modules/api/detranCnh/evento/confronto/actions';
import { DadosBiometricos } from 'store/modules/api/utils/consultarDigitais/types';
import { verificarVersaoServicoBiometriaRequest } from 'store/modules/api/biometria/verificarVersaoServicoBiometria/actions';
import Alert from 'components/Common/Notifications/Alert';
import { treatPayloadIncluirEstatisticaBiometricReader } from './form';

interface Props {
	setBiometria?: (v: 'SHOW' | 'SUCCESS' | 'ERROR' | null) => void;
	cpf?: string;
}

interface DedoSorteado {
	posicaoDedo: number;
	indice: string;
	sigla: string;
}

const LeituraDigitais: React.FC<Props> = ({
	setBiometria = () => {},
	cpf = '',
}) => {
	const dispatch = useDispatch();

	const [dedoSorteado, setDedoSorteado] = useState<DedoSorteado>({
		posicaoDedo: 0,
		indice: '',
		sigla: '',
	});
	const [dedosJaSorteados, setDedosJaSorteados] = useState<number[]>([0]);

	const { capturarBiometria, verificarVersaoServicoBiometria } = useSelector(
		(state: ApplicationState) => state.api.biometria,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { confrontarDigitaisCnh } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { consultarDigitais } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const [falhas, setFalhas] = useState<number>(0);

	const finalizarProcessoEncaminhandoParaMesaDeColeta = useCallback(() => {
		dispatch(cadastroRgConfrontoDigital(false));
		const payload = threatValuesConfrontSessionEventRequest(
			atendimento,
			loginUnico,
			dedoSorteado.posicaoDedo.toString(),
			false,
		);

		dispatch(eventoConfrontoSessaoRequest(payload));
		setBiometria('ERROR');
	}, [
		atendimento,
		dedoSorteado.posicaoDedo,
		dispatch,
		loginUnico,
		setBiometria,
	]);

	const sortearDedo = useCallback(() => {
		if (consultarDigitais.data) {
			const posicoesPermitidas = [1, 2, 3, 6, 7, 8];

			let novasPosicoesPermitidas = posicoesPermitidas;
			if (dedoSorteado.posicaoDedo !== 0) {
				novasPosicoesPermitidas = posicoesPermitidas.filter((item: number) => {
					return !dedosJaSorteados.includes(item);
				});
			}

			const filteredDadosBiometricos =
				consultarDigitais.data.dadosBiometricos.filter(dado =>
					novasPosicoesPermitidas.includes(Number(dado.posicaoDedo)),
				);

			if (filteredDadosBiometricos.length > 0) {
				const sorteio = Math.round(
					Math.random() * (filteredDadosBiometricos.length - 1 - 0) + 0,
				);

				const dedoSorteio: DedoSorteado = {
					posicaoDedo: Number(filteredDadosBiometricos[sorteio].posicaoDedo),
					indice: filteredDadosBiometricos[sorteio].indice,
					sigla: filteredDadosBiometricos[sorteio].sigla,
				};

				setDedosJaSorteados(prev => [...prev, dedoSorteio.posicaoDedo]);
				setDedoSorteado({
					posicaoDedo: dedoSorteio.posicaoDedo,
					indice: dedoSorteio.indice,
					sigla: dedoSorteio.sigla,
				});
			} else {
				finalizarProcessoEncaminhandoParaMesaDeColeta();
			}
		}
	}, [
		consultarDigitais,
		dedoSorteado,
		dedosJaSorteados,
		finalizarProcessoEncaminhandoParaMesaDeColeta,
	]);

	useEffect(() => {
		if (
			capturarBiometria.qtdeFalha + confrontarDigitaisCnh.qtdeFalha !== 0 &&
			capturarBiometria.qtdeFalha + confrontarDigitaisCnh.qtdeFalha !==
				falhas &&
			dedoSorteado.posicaoDedo !== 0
		) {
			setFalhas(capturarBiometria.qtdeFalha + confrontarDigitaisCnh.qtdeFalha);
			if (falhas <= 3) {
				sortearDedo();
			}
		}
	}, [
		capturarBiometria,
		confrontarDigitaisCnh.qtdeFalha,
		dedoSorteado.posicaoDedo,
		falhas,
		sortearDedo,
	]);

	// Realiza o primeiro sorteio dos dedos.
	useEffect(() => {
		if (
			dedoSorteado.posicaoDedo === 0 &&
			consultarDigitais.data &&
			consultarDigitais.data.dadosBiometricos.length !== 0
		) {
			sortearDedo();
		}
	}, [dedoSorteado.posicaoDedo, consultarDigitais.data, sortearDedo]);

	// Realiza o redirecionamento para Status do Cadastro.
	useEffect(() => {
		// Caso o confronto de digitais falhe 3 vezes.
		if (confrontarDigitaisCnh.qtdeFalha > 2 || falhas > 2) {
			finalizarProcessoEncaminhandoParaMesaDeColeta();
		}

		// Em caso de sucesso no confronto das digitais.
		if (confrontarDigitaisCnh.resultado && dedoSorteado.posicaoDedo !== 0) {
			dispatch(cadastroRgConfrontoDigital(true));
			const payload = threatValuesConfrontSessionEventRequest(
				atendimento,
				loginUnico,
				dedoSorteado.posicaoDedo.toString(),
			);

			dispatch(eventoConfrontoSessaoRequest(payload));
			setBiometria('SUCCESS');
		}
	}, [
		confrontarDigitaisCnh,
		dispatch,
		finalizarProcessoEncaminhandoParaMesaDeColeta,
		setBiometria,
		atendimento,
		loginUnico,
		dedoSorteado.posicaoDedo,
		falhas,
	]);

	// Aguarda a captura da biometria e realiza o confronto.
	useEffect(() => {
		if (
			cpf &&
			capturarBiometria.status === 200 &&
			capturarBiometria?.data?.length > 0
		) {
			const treatedPayloadDedoSorteado: DadosBiometricos = {
				...dedoSorteado,
				posicaoDedo: dedoSorteado.posicaoDedo.toString(),
			};

			dispatch(
				confrontarDigitaisCnhRequest(
					cpf,
					treatedPayloadDedoSorteado,
					capturarBiometria.data ?? '',
				),
			);
			dispatch(capturarBiometriaClearLeitura());
		} else if (
			cpf &&
			((capturarBiometria.status !== 0 &&
				capturarBiometria.status !== 100 &&
				capturarBiometria.status !== 200) ||
				(capturarBiometria.status === 200 && !capturarBiometria?.data?.length))
		) {
			const treatedPayload = treatPayloadIncluirEstatisticaBiometricReader(
				loginUnico.login.user,
				atendimento.salvarAtendimento,
				cpf,
				capturarBiometria,
			);

			dispatch(estatisticaIncluirRequest(treatedPayload));
			dispatch(capturarBiometriaClearLeitura());
		}
	}, [
		cpf,
		dedoSorteado,
		capturarBiometria,
		dispatch,
		loginUnico.login.user,
		atendimento.salvarAtendimento,
	]);

	const handleCapturaBiometrica = useCallback(() => {
		dispatch(verificarVersaoServicoBiometriaRequest());
	}, [dispatch]);

	useEffect(() => {
		if (verificarVersaoServicoBiometria.data?.versao === 'OLD') {
			dispatch(
				capturarBiometriaRequest({
					orgao: 'CNH',
					serviceVersion: 'OLD',
				}),
			);
		} else if (verificarVersaoServicoBiometria.data?.versao === 'NEW') {
			dispatch(
				capturarBiometriaRequest({
					orgao: 'CNH',
					serviceVersion: 'NEW',
				}),
			);
		}
	}, [dispatch, verificarVersaoServicoBiometria.data]);

	return (
		<>
			{dedoSorteado.posicaoDedo && (
				<>
					<Section title="Leitura de Digitais">
						{(verificarVersaoServicoBiometria.status === 100 ||
							capturarBiometria.status === 100) && (
							<Row>
								<Col span={24}>
									<Alert
										type="info"
										message={
											(verificarVersaoServicoBiometria.status === 100 &&
												'Em preparação, aguarde a liberação para inserir a digital a ser coletada.') ||
											'Leitor biométrico liberado para a coleta, insira a digital a ser coletada.'
										}
									/>
								</Col>
							</Row>
						)}
						<Row gutter={[0, 20]}>
							<Col span={24}>
								<p>
									<strong>
										Clique no botão abaixo e coloque o dedo indicado na leitora
										biométrica para conferência da biometria.
									</strong>
								</p>
							</Col>
						</Row>

						<Row gutter={[0, 30]} justify="center">
							<Col>
								{(dedoSorteado.posicaoDedo === 6 ||
									dedoSorteado.posicaoDedo === 7 ||
									dedoSorteado.posicaoDedo === 8) && (
									<img src={imagemMaoDireita} alt="Mão Direita" />
								)}

								{dedoSorteado.posicaoDedo === 1 && (
									<img src={imagemPD} alt="Mão Direita - Polegar" />
								)}

								{dedoSorteado.posicaoDedo === 2 && (
									<img src={imagemID} alt="Mão Direita - Indicador" />
								)}

								{dedoSorteado.posicaoDedo === 3 && (
									<img src={imagemMD} alt="Mão Direita - Médio" />
								)}
							</Col>

							<Col>
								{(dedoSorteado.posicaoDedo === 1 ||
									dedoSorteado.posicaoDedo === 2 ||
									dedoSorteado.posicaoDedo === 3) && (
									<img src={imagemMaoEsquerda} alt="Mão Esquerda" />
								)}

								{dedoSorteado.posicaoDedo === 6 && (
									<img src={imagemPE} alt="Mão Esquerda - Polegar" />
								)}

								{dedoSorteado.posicaoDedo === 7 && (
									<img src={imagemIE} alt="Mão Esquerda - Indicador" />
								)}

								{dedoSorteado.posicaoDedo === 8 && (
									<img src={imagemME} alt="Mão Esquerda - Médio" />
								)}
							</Col>
						</Row>

						<Row gutter={[0, 10]} justify="center">
							<Col>
								<ButtonImage
									type="button"
									src="captura-biometrica"
									onClick={handleCapturaBiometrica}
									disabled={
										verificarVersaoServicoBiometria.status === 100 ||
										capturarBiometria.status === 100
									}
								/>
							</Col>
						</Row>
					</Section>
				</>
			)}
		</>
	);
};

export default LeituraDigitais;
