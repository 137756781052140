// TYPES
import { ReducerAction } from 'store/modules/types';
import { RecuperarAtendimentoRequest, Types } from './types';

export function recuperarAtendimentoRequest(
	payload: RecuperarAtendimentoRequest,
): ReducerAction {
	return {
		type: Types.RECUPERAR_ATENDIMENTO_REQUEST,
		payload,
	};
}
export function recuperarAtendimentoSuccess(payload: string): ReducerAction {
	return {
		type: Types.RECUPERAR_ATENDIMENTO_SUCCESS,
		payload,
	};
}
export function recuperarAtendimentoFailure(payload: object): ReducerAction {
	return {
		type: Types.RECUPERAR_ATENDIMENTO_FAILURE,
		payload,
	};
}
export function recuperarAtendimentoClear(): ReducerAction {
	return {
		type: Types.RECUPERAR_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
