export enum Types {
	CONSULTA_ATESTADO_NOMINAL_REQUEST = '@aac/CONSULTA_ATESTADO_NOMINAL_REQUEST',
	CONSULTA_ATESTADO_NOMINAL_SUCCESS = '@aac/CONSULTA_ATESTADO_NOMINAL_SUCCESS',
	CONSULTA_ATESTADO_NOMINAL_FAILURE = '@aac/CONSULTA_ATESTADO_NOMINAL_FAILURE',
	LIMPA_CONSULTA_NOMINAL = '@aac/LIMPA_CONSULTA_NOMINAL',
}

export interface consultaAtestadoNominal {
	status: number;
	data: DataConsultaAtestado;
}

export interface DataConsultaAtestado {
	atestadoEmitido: boolean;
	arquivoPDF: string;
	codigoRetorno: string;
	mensagemRetorno: string;
}
