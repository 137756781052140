/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Col, Row } from 'antd';
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

import getValidationsErrors from 'utils/getValidationsErrors';

// COMPONENTS
import SimpleTable from 'components/Common/Table';
import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
import FormBox from 'components/Common/Form/FormBox';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import ModalTelefone from './components/ModalTelefone';

// STYLE
import { ContainerFichaCadastral, ButtonBox } from '../styled';

import { initialValues, ITelefone, schemaTelefone } from './form';
import { customEnums } from './components/ModalTelefone/constants';

interface Props {
	formik: FormikProps<any>;
	interfaceNova?: boolean;
}

const Telefones: React.FC<Props> = ({ formik, interfaceNova = false }) => {
	const dispatch = useDispatch();

	const [telefones, setTelefones] = useState<ITelefone[]>([
		...formik.values.telefones,
	]);
	const [telefoneSelecionado, setTelefoneSelecionado] = useState<any>({});
	const [telefoneIndex, setTelefoneIndex] = useState<null | number>(null);
	const [modalTelefone, setModalTelefone] = useState(false);

	useEffect(() => {
		if (telefones.length >= 0 && !!interfaceNova) {
			const tempList: any = telefones.reduce((acc: any, cur: ITelefone) => {
				return [
					...acc,
					{
						ddd: cur.ddd || '',
						numero: cur.numero || '',
						tipo: cur.tipo || '',
						complemento: cur.complemento || '',
						sms: cur.sms || '',
					},
				];
			}, []);
			formik.setFieldValue('telefones', tempList);
		}
	}, [telefones]);

	const formatTelefoneList = (list: Array<any>) => {
		let tempList: any;
		if (interfaceNova) {
			tempList = list.map((telefone: ITelefone) => {
				return {
					ddd: telefone.dddNumero || '',
					numero: telefone.numero || '',
					tipo: telefone.tipoTelefone || '',
					complemento: telefone.complemento || '',
					sms: telefone.sms || '',
				};
			});
		} else {
			tempList = list;
		}
		return tempList.map((tel: any) => {
			const tipoTel = customEnums.tipo.filter(tipo => tipo.value === tel.tipo);
			return {
				...tel,
				tipoTelefone: tipoTel[0].label || '',
			};
		});
	};

	useEffect(() => {
		if (formik.values.telefones.length !== telefones.length) {
			setTelefones(formatTelefoneList(formik.values.telefones));
		}
	}, [formik.values.telefones]);

	useEffect(() => {
		if (!modalTelefone) {
			setTelefoneIndex(null);
		}
	}, [modalTelefone]);

	const handlenNewChangeTelefone = (
		telefoneRecord: ITelefone,
		index: number,
	) => {
		const telefone = {
			id: index,
			ddd: telefoneRecord.ddd,
			numero: telefoneRecord.numero,
			tipo: telefoneRecord.tipo,
			tipoTelefone: telefoneRecord.tipoTelefone,
			complemento: telefoneRecord.complemento,
			sms: telefoneRecord.sms,
		};
		setTelefoneSelecionado(telefone);
		setTelefoneIndex(index);
		setModalTelefone(true);
	};

	const handleDelete = useCallback(
		async index => {
			const newList = telefones;
			newList.splice(index, 1);
			setTelefones([...newList]);
		},
		[formik],
	);

	const handleSubmit = useCallback(
		(values: any, { resetForm }) => {
			if (telefoneIndex === null) {
				setTelefones(oldTelefones => [...oldTelefones, values]);
			} else {
				setTelefones(oldTelefones => {
					const extractTelefones = [...oldTelefones];
					extractTelefones[telefoneIndex] = values;

					return [...extractTelefones];
				});
			}

			resetForm({ values: { ...initialValues } });
			setModalTelefone(false);
		},
		[telefoneIndex],
	);

	const headers = [
		{
			title: 'Tipo Telefone',
			key: 'Tipo Telefone',
			dataIndex: 'tipoTelefone',
		},
		{
			title: 'DDD',
			key: 'DDD',
			dataIndex: 'ddd',
		},
		{
			title: 'Número',
			key: 'Número',
			dataIndex: 'numero',
		},
		{
			title: 'Complemento',
			key: 'Complemento',
			dataIndex: 'complemento',
		},
		{
			title: 'Deseja Receber SMS',
			key: 'Deseja Receber SMS',
			dataIndex: 'sms',
		},
		{
			title: 'Alterar',
			key: 'Alterar',
			dataIndex: 'type',
			render: (text: any, record: any, index: number) => {
				return (
					<ButtonBox>
						<FaPencilAlt
							size={15}
							onClick={() => {
								handlenNewChangeTelefone(record, index);
							}}
						/>
					</ButtonBox>
				);
			},
		},
		{
			title: 'Excluir',
			key: 'Excluir',
			dataIndex: 'type',
			render: (text: any, record: any, index: any) => {
				return (
					<ButtonBox>
						<FaTrashAlt size={15} onClick={() => handleDelete(index)} />
					</ButtonBox>
				);
			},
		},
	];

	return (
		<ContainerFichaCadastral>
			<FormBox title="Telefones">
				<Button
					className="addButton"
					onClick={() => {
						dispatch(clearNotifications());
						setModalTelefone(true);
					}}
				>
					+ Adicionar
				</Button>
				<Section title="Telefones" size="sm">
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<SimpleTable
								headers={headers}
								body={telefones}
								messageNoResults=" "
								// onEmptyClick={() => {
								// 	dispatch(clearNotifications());
								// 	setModalTelefone(true);
								// }}
							/>
						</Col>
					</Row>
				</Section>
			</FormBox>

			<Formik
				enableReinitialize
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={initialValues}
				validate={values => getValidationsErrors(values, schemaTelefone)}
				onSubmit={handleSubmit}
			>
				{formikTelefone => {
					return (
						<>
							<ModalTelefone
								formik={formikTelefone}
								modalTelefone={modalTelefone}
								setModalTelefone={setModalTelefone}
								telefoneSelecionado={telefoneSelecionado}
								setTelefoneSelecionado={setTelefoneSelecionado}
							/>
						</>
					);
				}}
			</Formik>
		</ContainerFichaCadastral>
	);
};

export default Telefones;
