import produce from 'immer';

// TYPES
import { Types, ConsultarLocalidade } from './types';
import { ReducerAction } from '../../../../../types';

export const INITIAL_STATE: ConsultarLocalidade = {
	status: 0,
	data: null,
	type: null,
};

export default function consultarLocalidadeReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarLocalidade {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_LOCALIDADE_REQUEST: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.type = INITIAL_STATE.type;
				break;
			}

			case Types.CONSULTAR_LOCALIDADE_SUCCESS: {
				draft.status = action.payload.response.status;
				draft.data = action.payload.response.data;
				draft.type = action.payload.type;
				break;
			}

			case Types.CONSULTAR_LOCALIDADE_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTAR_LOCALIDADE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.type = INITIAL_STATE.type;
				break;
			}

			default:
		}
	});
}
