import { all } from 'redux-saga/effects';

import consultaReabilitacao from './consultaReabilitacao/saga';
import consultaReciclagem from './consultaReciclagem/saga';
import consultaSegundaViaReabilitacao from './consultaSegundaViaReabilitacao/saga';
import confirmaPreCadastroReabilitacao from './confirmaPreCadastroReabilitacao/saga';
import gerarRenachSaga from './gerarRenach/saga';

export default all([
	consultaReabilitacao,
	consultaReciclagem,
	consultaSegundaViaReabilitacao,
	confirmaPreCadastroReabilitacao,
	gerarRenachSaga,
]);
