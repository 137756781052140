import React, { useEffect } from 'react';

import { Modal, Row, Col } from 'antd';

interface Props {
	showModal: boolean;
	onCancel: () => void;
	onOk: () => void;
}

const CidadaoSemEmail: React.FC<Props> = ({
	showModal = false,
	onCancel,
	onOk,
}) => {
	// Manipula a visibilidade do Modal.
	useEffect(() => {
		if (showModal === true) {
			Modal.confirm({
				title: 'Importante',
				content: (
					<>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<div style={{ color: 'red', fontWeight: 500 }}>
									Sem e-mail o cidadão não terá acesso a mais serviços no
									Portal.
								</div>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<div>Cidadão realmente não possui ou não sabe o e-mail?</div>
							</Col>
						</Row>
					</>
				),
				onCancel() {
					onCancel();
				},
				onOk() {
					onOk();
				},
				cancelText: 'Não',
				okText: 'Sim',
				width: 475,
				style: {
					animationDuration: '0s',
				},
			});
		}
	}, [showModal, onCancel, onOk]);

	return <></>;
};

export default CidadaoSemEmail;
