import { IRequestConsultaPermissaoInternacionalAutomatizado } from 'store/modules/api/detranCnh/permissaoInternacionalAutomatizado/consultaPermissaoInternacionalAutomatizado/types';
import * as Yup from 'yup';

export interface IFormPesquisaPermissaoInternacionalAutomatizado {
	cpf: string;
}

interface ICredentials {
	usuario: string;
	senha: string;
}

export function treatValuesPid(
	values: string,
	credentials: ICredentials,
): IRequestConsultaPermissaoInternacionalAutomatizado {
	const formattedData: IRequestConsultaPermissaoInternacionalAutomatizado = {
		usuario: credentials.usuario,
		senha: credentials.senha,
		cpf: values.replace(/[.-]+/g, ''),
	};

	return formattedData;
}

export function treatValues(
	values: IFormPesquisaPermissaoInternacionalAutomatizado,
): string {
	return values.cpf.replace(/[.-]+/g, '');
}

export const initialValues: IFormPesquisaPermissaoInternacionalAutomatizado = {
	cpf: '',
};

export const schema =
	Yup.object<IFormPesquisaPermissaoInternacionalAutomatizado>().shape({
		cpf: Yup.string().required('Campo Obrigatório: CPF.'),
	});
