import { ReducerActionRotaSP } from 'store/modules/types';
import { ComprovanteInscricaoRequest, Types } from './types';

export function comprovanteInscricaoRequest(
	token: string,
	payload: ComprovanteInscricaoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.COMPROVANTE_INSCRICAO_REQUEST,
		token,
		payload,
	};
}

export function comprovanteInscricaoSuccess(payload: object) {
	return {
		type: Types.COMPROVANTE_INSCRICAO_SUCCESS,
		payload,
	};
}

export function comprovanteInscricaoFailure(payload: boolean) {
	return {
		type: Types.COMPROVANTE_INSCRICAO_FAILURE,
		payload,
	};
}
