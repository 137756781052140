import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_RFB } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultarProcessoRequest, Types } from './types';

// ACTIONS
import { consultarProcessoSuccess, consultarProcessoFailure } from './actions';

function* consultarProcessoRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultarProcessoRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_RFB,
		`cin/${CHANNEL}/processo`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultarProcessoSuccess(response.data));
	} else {
		yield put(consultarProcessoFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_PROCESSO_REQUEST, consultarProcessoRequest),
]);
