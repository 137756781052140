import React from 'react';

import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
// import { useDispatch } from 'react-redux';

// COMPONENTS
import ServiceList from 'components/Common/ServiceList';
import ButtonVoltar from 'components/Common/ButtonVoltar';

const Poupatempo: React.FC = () => {
	// const dispatch = useDispatch();
	const history = useHistory();

	const services = [
		{
			name: 'Contabilização',
			path: 'contabilizacao',
			recuperaAtendimento: false,
		},
		{
			name: 'Serviços Municipais',
			path: 'servicos-municipais',
			recuperaAtendimento: false,
		},
		{
			name: 'SGU',
			path: 'sgu',
			recuperaAtendimento: false,
		},
	];

	// useEffect(() => {
	// 	dispatch(limpaPreCadastroRequest());
	// }, [dispatch]);

	return (
		<Row>
			<Col span={24}>
				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h1>Escolha o serviço</h1>
					</Col>
				</Row>

				<Row>
					<Col span={24}>{services && <ServiceList data={services} />}</Col>
				</Row>

				<Row>
					<Col span={24}>
						<ButtonVoltar
							navigate={false}
							onClick={() => {
								history.push('/menu-orgaos');
							}}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default Poupatempo;
