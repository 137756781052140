import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarUnidadeRequest } from './types';

export function atualizarUnidadeRequest(
	data: AtualizarUnidadeRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_UNIDADE,
		payload: data,
	};
}
export function atualizarUnidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_UNIDADE_SUCCESS,
		payload,
	};
}
export function atualizarUnidadeFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_UNIDADE_FAILURE,
		payload: null,
	};
}
export function atualizarUnidadeClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_UNIDADE_CLEAR,
		payload: null,
	};
}
