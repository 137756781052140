import axios, { AxiosResponse } from 'axios';

// REDUX
import { store } from 'store';
import { setAuthToken } from 'store/modules/app/authToken/actions';
import { setLoader } from 'store/modules/app/loader/actions';
import { clearNotificationsValidate } from 'store/modules/app/notifications/actions';

// UTILS
import handleFailed, { handleFailedBody } from 'utils/getValidations';
import { handleTempoInatividade } from './utils';

// SERVICES
import { authToken } from './_token';

const { REACT_APP_BASE_URL } = process.env;

const api = axios.create({
	baseURL: REACT_APP_BASE_URL,
});

export interface ApiResponse {
	status: number;
	data?: any;
	municipios?: any;
	error?: boolean;
	message?: any;
	numRg?: string;
}

export interface ResponseError {
	error: boolean;
	message: string;
}

export interface ResponseCheckTimeToken {
	status: number;
	error: boolean;
	message: string;
}

export interface ApiConfig {
	method: string;
	url: string;
	params?: object;
	data?: any;
	headers: ApiConfigHeaders;
}

export interface ApiConfigHeaders {
	Authorization: string | ResponseError;
	header?: object;
}

async function getMinutes(calcTime: Date): Promise<number> {
	let minutesLastToken: number | null = null;
	const calcTimeStore: Date = calcTime;
	const diffMins = Math.abs(
		new Date().getTime() - new Date(calcTimeStore).getTime(),
	);
	minutesLastToken = Math.round(((diffMins % 86400000) % 3600000) / 60000);
	return minutesLastToken;
}

async function resultResponse(config: object): Promise<AxiosResponse<any>> {
	const response = await api(config);

	return response;
}

async function checkTimeToken(
	calcTime: Date | null,
): Promise<ResponseCheckTimeToken> {
	let minutesLastToken: number | null = null;
	if (calcTime) {
		minutesLastToken = await getMinutes(calcTime);
		if (minutesLastToken < 5) {
			console.log('Sistema fora do ar!!!');
			return {
				status: 0,
				error: true,
				message: 'Sistema fora do ar!!!',
			};
		}
	}
	return { status: 0, error: true, message: '' };
}

function runSetLoader(method: string) {
	const methodURL = method.split('/') || [];
	const methodURLLength = methodURL ? methodURL.length - 1 : 0;
	const lastCall = methodURL.length ? methodURL[methodURLLength] : '';

	const storageValue = localStorage.getItem('lastCallApi');
	const storageValueResult: string[] = storageValue
		? JSON.parse(storageValue)
		: [];

	const incrementStorage = [...storageValueResult, lastCall];
	localStorage.setItem('lastCallApi', JSON.stringify(incrementStorage));
	clearNotificationsValidate();
	setLoader(true);
}

function stopSetLoader(method: string) {
	const methodURL = method.split('/') || [];
	const methodURLLength = methodURL ? methodURL.length - 1 : 0;
	const lastCall = methodURL.length ? methodURL[methodURLLength] : '';

	const storageValue = localStorage.getItem('lastCallApi');
	const storageValueResult: string[] = storageValue
		? JSON.parse(storageValue)
		: [];
	const arrayDiference =
		storageValueResult.filter(value => value !== lastCall) || [];
	if (arrayDiference.length) {
		localStorage.setItem('lastCallApi', JSON.stringify(arrayDiference));
	} else {
		localStorage.setItem('lastCallApi', JSON.stringify([]));
	}

	if (storageValueResult.length <= 1 && arrayDiference.length === 0) {
		localStorage.removeItem('lastCallApi');
		setLoader(false);
	}
}

export async function getApi(
	project: string,
	method: string,
	params?: object,
	semNotificacao?: boolean,
	contadorChamadaToken?: number,
): Promise<ApiResponse> {
	runSetLoader(method);
	const state = store.getState();
	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	let token: string | ResponseError = `${state.app.authToken.authorization}`;

	const config: ApiConfig = {
		method: 'get',
		url: `/${project}/${method}`,
		params,
		headers: {
			Authorization: token,
		},
	};
	let contador = contadorChamadaToken ?? 0;

	try {
		const result = await resultResponse(config);
		return result;
	} catch (error) {
		if (error?.response?.status === 401 && contador < 2) {
			try {
				contador++;
				token = await authToken();
				setAuthToken(token);
				config.headers.Authorization = token;
				const result = await getApi(
					project,
					method,
					params,
					semNotificacao,
					contador,
				);

				return result;
			} catch (errorResult) {
				const checkTokenvalidate = checkTimeToken(state.app.authToken.calcTime);

				return checkTokenvalidate;
			}
		}

		const errorResponse = await handleFailed(error, !!semNotificacao);
		const errorData = (await handleFailedBody(error)) as any;

		return {
			status: 0,
			error: true,
			message: errorResponse,
			data: errorData.data,
		};
	} finally {
		setTimeout(() => {
			stopSetLoader(method);
		}, 1000);
	}
}

export async function postApi(
	project: string,
	method: string,
	body: any,
	header?: object,
	semNotificacao?: boolean,
	contadorChamadaToken?: number,
): Promise<ApiResponse> {
	const state = store.getState();

	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	runSetLoader(method);

	let contador = contadorChamadaToken ?? 0;

	let token: string | ResponseError = state.app.authToken.authorization || '';

	const config: ApiConfig = {
		method: 'post',
		url: `/${project}/${method}`,
		data: body,
		headers: {
			Authorization: token,
			...header,
		},
	};

	try {
		const result = await resultResponse(config);
		return result;
	} catch (error) {
		if (error?.response?.status === 401 && contador < 2) {
			try {
				contador++;
				token = await authToken();
				setAuthToken(token);
				config.headers.Authorization = token;
				const result = await postApi(
					project,
					method,
					body,
					header,
					semNotificacao,
					contador,
				);

				return result;
			} catch (errorResult) {
				const checkTokenvalidate = checkTimeToken(state.app.authToken.calcTime);

				return checkTokenvalidate;
			}
		}

		const errorResponse = await handleFailed(error, !!semNotificacao);
		const errorData = await handleFailedBody(error);

		const message =
			errorResponse.length > 1 ? [errorResponse[0]] : errorResponse;

		return {
			status: 0,
			data: errorData,
			error: true,
			message,
			numRg: errorResponse[1] ?? '',
		};
	} finally {
		// setTimeout(() => {
		stopSetLoader(method);

		// }, 1000);
	}
}

export async function putApi(
	project: string,
	method: string,
	body: any,
	header?: object,
	contadorChamadaToken?: number,
): Promise<ApiResponse> {
	runSetLoader(method);
	const state = store.getState();
	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	let contador = contadorChamadaToken ?? 0;

	let token: string | ResponseError = state.app.authToken.authorization || '';

	const config: ApiConfig = {
		method: 'put',
		url: `/${project}/${method}`,
		data: body,
		headers: {
			Authorization: token,
			...header,
		},
	};

	try {
		const result = await resultResponse(config);

		if (result.status === 200 && result.data.mensagem_erro) {
			const errorResponse = await handleFailed(result.data.mensagem_erro);
			return { status: 0, error: true, message: errorResponse };
		}

		return result;
	} catch (error) {
		if (error?.response?.status === 401 && contador < 2) {
			try {
				contador++;
				token = await authToken();
				setAuthToken(token);
				config.headers.Authorization = token;
				const result = await putApi(project, method, body, header);

				return result;
			} catch (errorResult) {
				const checkTokenvalidate = checkTimeToken(state.app.authToken.calcTime);

				return checkTokenvalidate;
			}
		}

		const errorResponse = await handleFailed(error);
		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			stopSetLoader(method);
		}, 1000);
	}
}

export async function patchApi(
	project: string,
	method: string,
	body: any,
	header?: object,
	contadorChamadaToken?: number,
): Promise<ApiResponse> {
	runSetLoader(method);
	const state = store.getState();
	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	let contador = contadorChamadaToken ?? 0;

	let token: string | ResponseError = state.app.authToken.authorization || '';

	const config: ApiConfig = {
		method: 'patch',
		url: `/${project}/${method}`,
		data: body,
		headers: {
			Authorization: token,
			...header,
		},
	};

	try {
		const result = await resultResponse(config);

		if (result.status === 200 && result.data.mensagem_erro) {
			const errorResponse = await handleFailed(result.data.mensagem_erro);
			return { status: 0, error: true, message: errorResponse };
		}

		return result;
	} catch (error) {
		if (error?.response?.status === 401 && contador < 2) {
			try {
				contador++;
				token = await authToken();
				setAuthToken(token);
				config.headers.Authorization = token;
				const result = await putApi(project, method, body, header);

				return result;
			} catch (errorResult) {
				const checkTokenvalidate = checkTimeToken(state.app.authToken.calcTime);

				return checkTokenvalidate;
			}
		}

		const errorResponse = await handleFailed(error);
		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			stopSetLoader(method);
		}, 1000);
	}
}

export async function deleteApi(
	project: string,
	method: string,
	params?: object,
	contadorChamadaToken?: number,
): Promise<ApiResponse> {
	runSetLoader(method);
	const state = store.getState();
	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	let contador = contadorChamadaToken ?? 0;

	let token: string | ResponseError = state.app.authToken.authorization || '';

	const config: ApiConfig = {
		method: 'delete',
		url: `/${project}/${method}`,
		data: params,
		headers: {
			Authorization: token,
		},
	};

	try {
		const result = await resultResponse(config);

		if (result.status === 200 && result.data.mensagem_erro) {
			const errorResponse = await handleFailed(result.data.mensagem_erro);
			return { status: 0, error: true, message: errorResponse };
		}

		return result;
	} catch (error) {
		if (error?.response?.status === 401 && contador < 2) {
			try {
				contador++;
				token = await authToken();
				setAuthToken(token);
				config.headers.Authorization = token;
				const result = await deleteApi(project, method, params);

				return result;
			} catch (errorResult) {
				const checkTokenvalidate = checkTimeToken(state.app.authToken.calcTime);

				return checkTokenvalidate;
			}
		}

		const errorResponse = await handleFailed(error);
		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			stopSetLoader(method);
		}, 1000);
	}
}

export default api;
