import produce from 'immer';
import { Types, INotasFiscaisState } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: INotasFiscaisState = {
	statusTransacaoNotasFiscais: '',
	redirectUrlNotasFiscais: '',
};

export default function vivaLeiteNotasFiscais(
	state = INITIAL_STATE,
	action: ReducerAction,
): INotasFiscaisState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CADASTRA_NOTAS_FISCAIS: {
				break;
			}
			case Types.CADASTRA_NOTAS_FISCAIS_SUCCESS: {
				draft.redirectUrlNotasFiscais = 'imprimirDocumento';
				draft.statusTransacaoNotasFiscais =
					action.payload.data.status_transacao;
				break;
			}
			case Types.CADASTRA_NOTAS_FISCAIS_FAILURE: {
				break;
			}

			case Types.REDIRECT_NOTAS_FISCAIS_VIVA_LEITE_CLEAR: {
				draft.redirectUrlNotasFiscais = '';
				break;
			}

			case Types.NOTIFICATION_NOTAS_FISCAIS_VIVA_LEITE_CLEAR: {
				draft.statusTransacaoNotasFiscais = '';
				break;
			}

			default:
		}
	});
}
