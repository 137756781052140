export enum Types {
	TRANSFERENCIA_PROPRIETARIO_REQUEST = '@DETRAN-CRV/TRANSFERENCIA_PROPRIETARIO_REQUEST',
	TRANSFERENCIA_PROPRIETARIO_SUCCESS = '@DETRAN-CRV/TRANSFERENCIA_PROPRIETARIO_SUCCESS',
	TRANSFERENCIA_PROPRIETARIO_FAILURE = '@DETRAN-CRV/TRANSFERENCIA_PROPRIETARIO_FAILURE',
}

export interface transferenciaPropietario {
	status: number;
	data: ficha | null;
}

interface ficha {
	ficha: {
		anoFicha: number;
		chassi: string;
		codigoSSP: number;
		dataEmissaoDocumento: string;
		dataHoraExlcusao: string;
		dataInclusaoFicha: string;
		dataInclusaohistorico: string;
		municipio: {
			codigo: number;
			nome: string;
		};
		numeroFicha: number;
		opcao: string;
		placa: string;
		planilhaRenavam: string;
		renavam: number;
		retornoBin: string;
		retornoConsistencia: string;
		status: string;
	};
}
