import { ReducerAction } from 'store/modules/types';

import { Types, RequestPesquisaEnderecoRecebimento } from './types';

export function pesquisaEnderecoRecebimentoRequest(
	data: RequestPesquisaEnderecoRecebimento,
): ReducerAction {
	return {
		type: Types.PESQUISA_ENDERECO_RECEBIMENTO_REQUEST,
		payload: data,
	};
}
export function pesquisaEnderecoRecebimentoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.PESQUISA_ENDERECO_RECEBIMENTO_SUCCESS,
		payload,
	};
}
export function pesquisaEnderecoRecebimentoFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.PESQUISA_ENDERECO_RECEBIMENTO_FAILURE,
		payload,
	};
}
export function pesquisaEnderecoRecebimentoClear(
	payload: object,
): ReducerAction {
	return {
		type: Types.PESQUISA_ENDERECO_RECEBIMENTO_CLEAR,
		payload,
	};
}
