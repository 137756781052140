import JustificativaAtendimento from 'components/Pages/JustificativaAtendimento';
import DesbloqueioCartao from '../DesbloqueioCartao';
import RecuperarAtendimentoBolsaPovo from '../RecuperarAtendimento';
import AlteraEndereco from '../AlteraEndereco';
import AlteracaoEndereco from '../AlteracaoEndereco';
import PesquisaEndereco from '../PesquisaEndereco';
import Confirmacao from '../Confirmacao';

// PATHS
import {
	ROUTE_BOLSA_POVO_RECUPERAR_ATENDIMENTO,
	ROUTE_BOLSA_POVO_JUSTIFICATIVA_ATENDIMENTO,
	ROUTE_BOLSA_POVO_DESBLOQUEIO_CARTAO,
	ROUTE_BOLSA_POVO_ALTERA_ENDERECO,
	ROUTE_BOLSA_POVO_ALTERACAO_ENDERECO,
	ROUTE_BOLSA_POVO_CONFIRMACAO,
	ROUTE_BOLSA_POVO_PESQUISA_ALTERACAO_ENDERECO,
} from './paths';

export const bolsaPovoRoute = {
	name: 'Bolsa do Povo',
	route: '/bolsa-povo',
};

export default [
	{
		path: ROUTE_BOLSA_POVO_RECUPERAR_ATENDIMENTO,
		breadcrumb: [
			bolsaPovoRoute,
			{
				name: 'Recuperar atendimento',
			},
		],
		Component: RecuperarAtendimentoBolsaPovo,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_BOLSA_POVO_JUSTIFICATIVA_ATENDIMENTO,
		breadcrumb: [
			bolsaPovoRoute,
			{
				name: 'Justificativa atendimento',
			},
		],
		Component: JustificativaAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_BOLSA_POVO_DESBLOQUEIO_CARTAO,
		breadcrumb: [
			bolsaPovoRoute,
			{
				name: 'Desbloqueio do Cartão',
			},
		],
		Component: DesbloqueioCartao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_BOLSA_POVO_ALTERA_ENDERECO,
		breadcrumb: [
			bolsaPovoRoute,
			{
				name: 'Alteração de Endereço',
			},
		],
		Component: AlteraEndereco,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_BOLSA_POVO_ALTERACAO_ENDERECO,
		breadcrumb: [
			bolsaPovoRoute,
			{
				name: 'Alteração de Endereço ',
			},
		],
		Component: AlteracaoEndereco,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_BOLSA_POVO_PESQUISA_ALTERACAO_ENDERECO,
		breadcrumb: [
			bolsaPovoRoute,
			{
				name: 'Pesquisa - Alteração de Endereço',
			},
		],
		Component: PesquisaEndereco,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_BOLSA_POVO_CONFIRMACAO,
		breadcrumb: [
			bolsaPovoRoute,
			{
				name: 'Confirmação',
			},
		],
		Component: Confirmacao,
		isAuthenticated: true,
		exact: true,
	},
];
