import produce from 'immer';
import { toSelect } from 'utils/genericFunctions';
import { Types, IDocumentosState } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: IDocumentosState = {
	statusTransacaoDocumentos: '',
	selectListaDocumento: [],
	listaStatusDocumentos: [],
	termoAdvertencia: {
		codigo_ciencia: 0,
		titulo: '',
		introducao: '',
		pendencias: [],
		texto_ciencia: '',
	},
	pdfTermoAdvertencia: '',
	selectImpressaoDocumentos: [],
	pdfImpressao: '',
};

export default function vivaLeiteDocumentos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IDocumentosState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_DOCUMENTOS: {
				break;
			}
			case Types.CONSULTA_DOCUMENTOS_SUCCESS: {
				draft.selectListaDocumento = toSelect(
					action.payload.data.lista_documentos,
					'nome',
					'codigo_documento',
				);
				break;
			}
			case Types.CONSULTA_DOCUMENTOS_FAILURE: {
				break;
			}

			case Types.CADASTRA_DOCUMENTO: {
				draft.statusTransacaoDocumentos = '';
				break;
			}
			case Types.CADASTRA_DOCUMENTO_SUCCESS: {
				draft.listaStatusDocumentos = action.payload.data.lista_documentos;
				draft.statusTransacaoDocumentos = action.payload.data.status_transacao;
				break;
			}
			case Types.CADASTRA_DOCUMENTO_FAILURE: {
				break;
			}

			case Types.CONSULTA_STATUS_DOCUMENTOS: {
				break;
			}
			case Types.CONSULTA_STATUS_DOCUMENTOS_SUCCESS: {
				draft.listaStatusDocumentos = action.payload.data.status_documentos;
				break;
			}
			case Types.CONSULTA_STATUS_DOCUMENTOS_FAILURE: {
				break;
			}

			case Types.CONSULTA_TERMO_ADVERTENCIA: {
				break;
			}
			case Types.CONSULTA_TERMO_ADVERTENCIA_SUCCESS: {
				draft.termoAdvertencia = action.payload.data.termo_advertencia;
				break;
			}
			case Types.CONSULTA_TERMO_ADVERTENCIA_FAILURE: {
				break;
			}

			case Types.IMPRIME_TERMO_ADVERTENCIA: {
				break;
			}
			case Types.IMPRIME_TERMO_ADVERTENCIA_SUCCESS: {
				draft.pdfTermoAdvertencia = action.payload.data.pdf;
				break;
			}
			case Types.IMPRIME_TERMO_ADVERTENCIA_FAILURE: {
				break;
			}

			case Types.CADASTRA_TERMO_ADVERTENCIA: {
				break;
			}
			case Types.CADASTRA_TERMO_ADVERTENCIA_SUCCESS: {
				draft.statusTransacaoDocumentos = action.payload.data.status_transacao;
				break;
			}
			case Types.CADASTRA_TERMO_ADVERTENCIA_FAILURE: {
				break;
			}

			case Types.CONSULTA_IMPRESSAO_DOCUMENTOS: {
				break;
			}
			case Types.CONSULTA_IMPRESSAO_DOCUMENTOS_SUCCESS: {
				draft.selectImpressaoDocumentos = toSelect(
					action.payload.data.documentos,
					'nome',
					'codigo_documento',
				);
				break;
			}
			case Types.CONSULTA_IMPRESSAO_DOCUMENTOS_FAILURE: {
				break;
			}

			case Types.CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS: {
				break;
			}
			case Types.CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS_SUCCESS: {
				draft.pdfImpressao = action.payload.data.pdf;
				break;
			}
			case Types.CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS_FAILURE: {
				draft.pdfImpressao = '';
				break;
			}

			case Types.NOTIFICATION_DOCUMENTOS_VIVA_LEITE_CLEAR: {
				draft.statusTransacaoDocumentos = '';
				break;
			}

			case Types.PDF_TERMO_ADVERTENCIA_CLEAR: {
				draft.pdfTermoAdvertencia = '';
				break;
			}

			case Types.PDF_IMPRIMIR_CLEAR: {
				draft.pdfImpressao = '';
				break;
			}

			default:
		}
	});
}
