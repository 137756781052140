import { ReducerAction } from 'store/modules/types';

import { AlterarRenachComRegistroRequest, Types } from './types';

export function alterarRenachComRegistroRequest(
	payload: AlterarRenachComRegistroRequest,
): ReducerAction {
	return {
		type: Types.ALTERAR_RENACH_COM_REGISTRO_REQUEST,
		payload,
	};
}
export function alterarRenachComRegistroSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.ALTERAR_RENACH_COM_REGISTRO_SUCCESS,
		payload,
	};
}
export function alterarRenachComRegistroFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.ALTERAR_RENACH_COM_REGISTRO_FAILURE,
		payload,
	};
}
export function alterarRenachComRegistroClear(): ReducerAction {
	return {
		type: Types.ALTERAR_RENACH_COM_REGISTRO_CLEAR,
		payload: null,
	};
}
