import { DadosBiometricos } from '../consultarDigitais/types';

export enum Types {
	CONFRONTAR_DIGITAIS_CNH_REQUEST = '@utils/CONFRONTAR_DIGITAIS_CNH_REQUEST',
	CONFRONTAR_DIGITAIS_CNH_SUCCESS = '@utils/CONFRONTAR_DIGITAIS_CNH_SUCCESS',
	CONFRONTAR_DIGITAIS_CNH_FAILURE = '@utils/CONFRONTAR_DIGITAIS_CNH_FAILURE',
	CONFRONTAR_DIGITAIS_CNH_CLEAR = '@utils/CONFRONTAR_DIGITAIS_CNH_CLEAR',
}

export interface IConfrontarDigitaisCnhState {
	qtdeFalha: number;
	resultado: boolean;
	dedoSorteio: null | DadosBiometricos[];
}
