import { lazy } from 'react';

// PATHS
import {
	ROUTE_SGU_USUARIO_CADASTRO_CONSULTA,
	ROUTE_SGU_USUARIO_CADASTRO,
	ROUTE_SGU_USUARIO_CADASTRO_MAINFRAME,
	ROUTE_SGU_USUARIO_CADASTRO_EDITAR,
} from './paths';

// COMPONENTS
const ConsultaCadastroConsulta = lazy(
	() => import('../CadastroConsulta/Consulta'),
);

const ConsultaCadastro = lazy(() => import('../CadastroConsulta/Cadastro'));
const CadastroEditar = lazy(
	() => import('../CadastroConsulta/Cadastro/Editar'),
);
const ConsultaCadastroMainframe = lazy(
	() => import('../CadastroConsulta/CadastroMainframe'),
);

export const SGURoute = {
	name: 'Poupatempo - SGU',
	route: '/poupatempo',
};

const usuarioRoutes = [
	{
		path: ROUTE_SGU_USUARIO_CADASTRO_CONSULTA,
		breadcrumb: [
			SGURoute,
			{
				name: 'Usuários - Cadastro - Consulta ',
			},
		],
		Component: ConsultaCadastroConsulta,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_USUARIO_CADASTRO,
		breadcrumb: [
			SGURoute,
			{
				name: 'Usuários - Cadastro ',
			},
		],
		Component: ConsultaCadastro,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_USUARIO_CADASTRO_EDITAR,
		breadcrumb: [
			SGURoute,
			{
				name: 'Usuários - Editar ',
			},
		],
		Component: CadastroEditar,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_USUARIO_CADASTRO_MAINFRAME,
		breadcrumb: [
			SGURoute,
			{
				name: 'Usuários - Cadastro Mainframe',
			},
		],
		Component: ConsultaCadastroMainframe,
		isAuthenticated: true,
		exact: true,
	},
];

export default usuarioRoutes;
