import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { ApplicationState } from 'store';
import {
	ConsultaDocumentosProduzidosRequest,
	DocumentosProduzidos,
} from 'store/modules/api/procon/documentosProduzidos/atendimentos/consultaDocumentosProduzidos/types';
import { consultaDocumentosProduzidosRequest } from 'store/modules/api/procon/documentosProduzidos/atendimentos/consultaDocumentosProduzidos/actions';
import {
	AnexosConsumidor,
	ConsultaAnexosConsumidorRequest,
} from 'store/modules/api/procon/anexosConsumidor/atendimentos/consultaAnexosConsumidor/types';
import { consultaAnexosConsumidorRequest } from 'store/modules/api/procon/anexosConsumidor/atendimentos/consultaAnexosConsumidor/actions';
import { ConsultaCipDataPfPj } from 'store/modules/api/procon/atendimentos/types';
import { consultaProcessoAdministrativoRequest } from 'store/modules/api/procon/processosAdministrativos/processosAdministrativos/actions';

// UTILS
import { formatDate } from 'utils/genericFunctions';
import { threatDataConsultaProcessoAdministrativoRequest } from 'pages/procon/utils/functions/threatDataRequest/consutaProcessoAdministrativo';
import {
	verifyButtonNaoResolvidoIsEnabledByProcess,
	verifyButtonResolvidoIsEnabledByProcess,
} from 'pages/procon/utils/functions/validations/buttons';
import { verifyIsAdministrativeProcess } from 'pages/procon/utils/functions/validations/typeProcess';

// ANT DESIGN
import { Col, Row, Pagination, Collapse } from 'antd';

// COMPONENTS
import { FaDownload, FaPaperclip } from 'react-icons/fa';
import LightButton from 'components/Common/Buttons/Light';
import Section from 'components/Common/Section';
import TypographyCommon from 'components/Common/Typography';

// STYLED
import { styleCols } from '../styled';
import './styled.css';

interface Props {
	formik?: any;
	handleBackSection: React.MouseEventHandler<HTMLButtonElement>;
	handleShowModal: (resolvido: boolean) => void;
	formData: ConsultaCipDataPfPj | null;
	resolvido: boolean | null;
}

const DocumentosProcon: React.FC<Props> = ({
	handleBackSection,
	handleShowModal,
	formData,
	resolvido,
}) => {
	const dispatch = useDispatch();

	const { Panel } = Collapse;

	const [currentPageConsumidor, setCurrentPageConsumidor] = useState<number>(1);

	const [currentPageAtendimento, setCurrentPageAtendimento] =
		useState<number>(1);

	const [indexOfFirstConsumidor, setIndexOfFirstConsumidor] =
		useState<number>(0);

	const [indexOfLastConsumidor, setIndexOfLastConsumidor] = useState<number>(0);

	const [indexOfFirstAtendimento, setIndexOfFirstAtendimento] =
		useState<number>(0);

	const [indexOfLastAtendimento, setIndexOfLastAtendimento] =
		useState<number>(0);

	const { consultaDocumentosProduzidos } = useSelector(
		(state: ApplicationState) =>
			state.api.procon.documentosProduzidos.atendimentos,
	);

	const { consultaAnexosConsumidor } = useSelector(
		(state: ApplicationState) => state.api.procon.anexosConsumidor.atendimentos,
	);

	const { consultaProcessoAdministrativo } = useSelector(
		(state: ApplicationState) => state.api.procon.processosAdministrativos,
	);

	const handleRequestAnexosConsumidor = useCallback(() => {
		const payload: ConsultaAnexosConsumidorRequest = {
			atendimentoId: (formData?.protocolo ? '' : formData?.atendimentoId) || '',
			protocolo: formData?.protocolo || '',
		};

		dispatch(consultaAnexosConsumidorRequest(payload));
	}, [formData, dispatch]);

	const handleRequestAnexosAtendimento = useCallback(() => {
		const payload: ConsultaDocumentosProduzidosRequest = {
			protocolo: formData?.protocolo || '',
			atendimentoId: (formData?.protocolo ? '' : formData?.atendimentoId) || '',
		};

		dispatch(consultaDocumentosProduzidosRequest(payload));
	}, [formData, dispatch]);

	const handleSeeAttached = useCallback((link: any) => {
		window.open(link, '_blank');
	}, []);

	const documentosProduzidos = useCallback(
		(anexo: DocumentosProduzidos) => {
			return (
				<Section
					key={anexo.linkDoDocumento}
					style={{
						flex: 'initial',
						padding: '2px 20px',
						background: 'rgb(237 237 237)',
						border: '1px grey solid',
						borderRadius: '4px',
					}}
				>
					<Row gutter={16} align="top" justify="center">
						<Col span={1}>
							<Row gutter={16} align="top" justify="center">
								<FaPaperclip
									size={18}
									color="#4b5d6f"
									style={{ transform: 'rotate(45deg)' }}
									title="Anexo do atendimento"
								/>
							</Row>
						</Col>
						<Col span={20}>
							<div style={styleCols}>
								<Row gutter={16} align="top" justify="start">
									<p
										style={{
											color: '#2a4158',
											fontSize: '14px',
											fontWeight: 500,
										}}
									>
										{anexo.nomeDoDocumento || 'Anexo'}
									</p>
								</Row>
							</div>
						</Col>
						<Col span={2}>
							<Row gutter={16} align="top" justify="center">
								{formatDate(anexo.dataDeCriacao) || ''}
							</Row>
						</Col>
						<Col span={1}>
							<Row gutter={16} align="top" justify="center">
								<FaDownload
									size={18}
									color="#4b5d6f"
									style={{ cursor: 'pointer' }}
									title="Fazer download"
									onClick={() => handleSeeAttached(anexo.linkDoDocumento)}
								/>
							</Row>
						</Col>
					</Row>
				</Section>
			);
		},
		[handleSeeAttached],
	);

	const anexosConsumidor = useCallback(
		(anexo: AnexosConsumidor) => {
			return (
				<Section
					key={anexo.linkDoDocumento}
					style={{
						flex: 'initial',
						padding: '2px 20px',
						background: '#c5d3df',
						border: '1px grey solid',
						borderRadius: '4px',
					}}
				>
					<Row gutter={16} align="top" justify="center">
						<Col span={1}>
							<Row gutter={16} align="top" justify="center">
								<FaPaperclip
									size={18}
									color="#4b5d6f"
									style={{ transform: 'rotate(45deg)' }}
									title="Anexo do consumidor"
								/>
							</Row>
						</Col>
						<Col span={20}>
							<div style={styleCols}>
								<Row gutter={16} align="top" justify="start">
									<p
										style={{
											color: '#2a4158',
											fontSize: '14px',
											fontWeight: 500,
										}}
									>
										{anexo.descricao || 'Anexo'}
									</p>
								</Row>
							</div>
						</Col>
						<Col span={2}>
							<Row gutter={16} align="top" justify="center">
								{formatDate(anexo.dataDeCriacao) || ''}
							</Row>
						</Col>
						<Col span={1}>
							<Row gutter={16} align="top" justify="center">
								<FaDownload
									size={18}
									color="#4b5d6f"
									style={{ cursor: 'pointer' }}
									title="Fazer download"
									onClick={() => handleSeeAttached(anexo.linkDoDocumento)}
								/>
							</Row>
						</Col>
					</Row>
				</Section>
			);
		},
		[handleSeeAttached],
	);

	const handleAttachments = useCallback(() => {
		handleRequestAnexosConsumidor();
		handleRequestAnexosAtendimento();
	}, [handleRequestAnexosConsumidor, handleRequestAnexosAtendimento]);

	useEffect(() => {
		if (formData) {
			handleAttachments();
		}
	}, [formData, handleAttachments]);

	useEffect(() => {
		if (formData && verifyIsAdministrativeProcess(formData.razaoDoStatus)) {
			const requestData =
				threatDataConsultaProcessoAdministrativoRequest(formData);

			if (requestData) {
				dispatch(consultaProcessoAdministrativoRequest(requestData));
			}
		}
	}, [dispatch, formData]);

	useEffect(() => {
		if (
			consultaAnexosConsumidor.data &&
			consultaAnexosConsumidor.data?.length > 0
		) {
			setIndexOfFirstConsumidor(currentPageConsumidor * 10 - 10);

			setIndexOfLastConsumidor(currentPageConsumidor * 10);
		}
	}, [
		consultaAnexosConsumidor,

		setIndexOfFirstConsumidor,
		setIndexOfLastConsumidor,
		currentPageConsumidor,
	]);

	useEffect(() => {
		if (
			consultaDocumentosProduzidos.data &&
			consultaDocumentosProduzidos.data?.length > 0
		) {
			setIndexOfFirstAtendimento(currentPageAtendimento * 10 - 10);

			setIndexOfLastAtendimento(currentPageAtendimento * 10);
		}
	}, [
		consultaDocumentosProduzidos,

		setIndexOfFirstAtendimento,
		setIndexOfLastAtendimento,
		currentPageAtendimento,
	]);

	return (
		<>
			<Row gutter={[0, 0]} align="top" justify="start">
				<Col className="gutter-row" span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Documentos Procon"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>

			<Collapse
				bordered
				defaultActiveKey={[]}
				className="site-collapse-custom-collapse"
			>
				<Panel
					header={`Documentos Produzidos pelo Atendimento (${
						consultaDocumentosProduzidos.data?.length ?? 0
					})`}
					key="1"
				>
					{consultaDocumentosProduzidos.status !== 200 ? (
						<Row align="top" justify="center">
							<Col span="24">
								<Row align="top" justify="center">
									<LightButton
										type="button"
										buttonColor="default"
										text="Recarregar"
										icon="refresh"
										buttonSize="sm"
										buttonWidth={100}
										onClick={handleRequestAnexosAtendimento}
									/>
								</Row>
							</Col>
						</Row>
					) : (
						<>
							{consultaDocumentosProduzidos.data &&
								consultaDocumentosProduzidos.data.length > 0 &&
								consultaDocumentosProduzidos.data
									.slice(indexOfFirstAtendimento, indexOfLastAtendimento)
									.map(anexo => documentosProduzidos(anexo))}
							<br />
							<Row align="top" justify="end">
								<Pagination
									total={consultaDocumentosProduzidos.data?.length}
									size="small"
									defaultCurrent={1}
									onChange={page => setCurrentPageAtendimento(page)}
									showTotal={total => `Total ${total} anexo(s)`}
								/>
							</Row>
						</>
					)}
				</Panel>
				<Panel
					header={`Anexos do Consumidor (${
						consultaAnexosConsumidor.data?.length ?? 0
					})`}
					key="2"
				>
					{consultaAnexosConsumidor.status !== 200 ? (
						<Row align="top" justify="center">
							<Col span="24">
								<Row align="top" justify="center">
									<LightButton
										type="button"
										buttonColor="default"
										text="Recarregar"
										icon="refresh"
										buttonSize="sm"
										buttonWidth={100}
										onClick={handleRequestAnexosConsumidor}
									/>
								</Row>
							</Col>
						</Row>
					) : (
						<>
							{consultaAnexosConsumidor.data &&
								consultaAnexosConsumidor.data.length > 0 &&
								consultaAnexosConsumidor.data
									.slice(indexOfFirstConsumidor, indexOfLastConsumidor)
									.map(anexo => anexosConsumidor(anexo))}
							<br />
							<Row align="top" justify="end">
								<Pagination
									total={consultaAnexosConsumidor.data?.length}
									size="small"
									defaultCurrent={1}
									onChange={page => setCurrentPageConsumidor(page)}
									showTotal={total => `Total ${total} anexo(s)`}
								/>
							</Row>
						</>
					)}
				</Panel>
			</Collapse>
			<br />
			<Row align="top" justify="start">
				<Col span="14">
					<LightButton
						type="button"
						buttonColor="default"
						text="Voltar"
						icon="back"
						buttonSize="sm"
						buttonWidth={100}
						onClick={handleBackSection}
					/>
				</Col>
				<Col span="5">
					<Row align="top" justify="end">
						{verifyButtonNaoResolvidoIsEnabledByProcess(
							consultaProcessoAdministrativo,
							formData,
						) && (
							<LightButton
								type="button"
								buttonColor="red"
								text="Não Resolvido"
								icon="unlike"
								buttonSize="sm"
								buttonWidth={150}
								onClick={() => handleShowModal(false)}
								disabled={resolvido !== null}
							/>
						)}
					</Row>
				</Col>
				<Col span="5">
					<Row align="top" justify="end">
						{verifyButtonResolvidoIsEnabledByProcess(
							consultaProcessoAdministrativo,
							formData,
						) && (
							<LightButton
								type="button"
								buttonColor="green"
								text="Resolvido"
								icon="like"
								buttonSize="sm"
								buttonWidth={160}
								onClick={() => handleShowModal(true)}
								disabled={resolvido !== null}
							/>
						)}
					</Row>
				</Col>
			</Row>
		</>
	);
};
export default DocumentosProcon;
