// TYPES
import { ReducerAction } from 'store/modules/types';
import { RequestEnviarCentralNumeral, Types } from './types';

export function enviarCentralNumeralRequest(
	payload: RequestEnviarCentralNumeral,
): ReducerAction {
	return {
		type: Types.ENVIAR_CENTRAL_NUMERAL_REQUEST,
		payload,
	};
}

export function enviarCentralNumeralSuccess(payload: any): ReducerAction {
	return {
		type: Types.ENVIAR_CENTRAL_NUMERAL_SUCCESS,
		payload,
	};
}

export function enviarCentralNumeralFailure(): ReducerAction {
	return {
		type: Types.ENVIAR_CENTRAL_NUMERAL_FAILURE,
		payload: null,
	};
}

export function enviarCentralNumeralClear(): ReducerAction {
	return {
		type: Types.ENVIAR_CENTRAL_NUMERAL_CLEAN,
		payload: null,
	};
}
