export enum Types {
	EXCLUIR_USUARIO_MAINFRAME_REQUEST = '@SGU/EXCLUIR_USUARIO_MAINFRAME_REQUEST',
	EXCLUIR_USUARIO_MAINFRAME_SUCCESS = '@SGU/EXCLUIR_USUARIO_MAINFRAME_SUCCESS',
	EXCLUIR_USUARIO_MAINFRAME_FAILURE = '@SGU/EXCLUIR_USUARIO_MAINFRAME_FAILURE',
	EXCLUIR_USUARIO_MAINFRAME_CLEAR = '@SGU/EXCLUIR_USUARIO_MAINFRAME_CLEAR',
}

export interface ExcluirUsuarioMainframe {
	status: number;
	data: {
		id: string | null;
	};
}

export interface UsuarioMainframe {
	localidade: number;
	codigo: number;
}

export interface ExcluirUsuarioMainframeRequest {
	id: number | null;
	idLocalidade: number;
	usuario: string;
}
