import styled, { css } from 'styled-components';

interface Props {
	disabled: boolean;
}

export const Button = styled.button<Props>`
	background: transparent;
	border: unset;
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	margin: 0;
	overflow: hidden;
	padding: 0;
	transition: box-shadow 0.5s;
	img {
		width: 130px;
		height: 130px;
	}

	&:focus {
		box-shadow: 0px 1px 6px 1px rgba(143, 143, 143, 0.75);
	}

	&:hover {
		box-shadow: 0px 1px 6px 1px rgba(143, 143, 143, 0.75);
	}

	${props =>
		props.disabled &&
		css`
			cursor: no-drop;
			opacity: 0.5;

			&:focus {
				box-shadow: unset;
			}

			&:hover {
				box-shadow: unset;
			}
		`};
`;
