import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	PROBLEMAS_REQUEST = '@procon/PROBLEMAS_REQUEST',
	PROBLEMAS_SUCCESS = '@procon/PROBLEMAS_SUCCESS',
	PROBLEMAS_FAILURE = '@procon/PROBLEMAS_FAILURE',
	PROBLEMAS_CLEAR = '@procon/PROBLEMAS_CLEAR',
}

export interface Problemas {
	status: null | number;
	data: OptionProps[];
}

export interface ProblemasRequest {
	grupoDeProblemaId: string;
}
