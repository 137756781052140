export enum Types {
	CADASTRAR_MOTIVO = '@sgu/CADASTRAR_MOTIVO',
	CADASTRAR_MOTIVO_SUCCESS = '@sgu/CADASTRAR_MOTIVO_SUCCESS',
	CADASTRAR_MOTIVO_FAILURE = '@sgu/CADASTRAR_MOTIVO_FAILURE',
	CADASTRAR_MOTIVO_CLEAR = '@sgu/CADASTRAR_MOTIVO_CLEAR',
}

export interface CadastrarMotivo {
	status: number;
	data: null | MotivosData;
}

interface MotivosData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarMotivoRequest {
	id?: number | string;
	codigo?: number | string;
	descricao: string;
}
