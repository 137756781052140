export enum Types {
	ENSINO_REQUEST = '@EDUCACAO/ENSINO_REQUEST',
	ENSINO_SUCCESS = '@EDUCACAO/ENSINO_SUCCESS',
	ENSINO_FAILURE = '@EDUCACAO/ENSINO_FAILURE',
}

export interface Ensino {
	status: number;
	data: null | EnsinoData;
}

interface EnsinoData {
	idProcesso: number;
	mensagem: string;
	TipoEnsino: TipoEnsinoResponse[];
}

interface TipoEnsinoResponse {
	codigo: number;
	descricao: string;
	seriesAnos: SeriesAnos[];
}

interface SeriesAnos {
	codigo: number;
	descricao: string;
}
