export enum Types {
	CONSULTA_SEGUNDA_VIA_REABILITACAO = '@cnh/CONSULTA_SEGUNDA_VIA_REABILITACAO',
	CONSULTA_SEGUNDA_VIA_REABILITACAO_SUCCESS = '@cnh/CONSULTA_SEGUNDA_VIA_REABILITACAO_SUCCESS',
	CONSULTA_SEGUNDA_VIA_REABILITACAO_FAILURE = '@cnh/CONSULTA_SEGUNDA_VIA_REABILITACAO_FAILURE',
	CONSULTA_SEGUNDA_VIA_REABILITACAO_CLEAR = '@cnh/CONSULTA_SEGUNDA_VIA_REABILITACAO_CLEAR',
}

export interface consultaSegundaViaReabilitacao {
	status: number;
	data: IDadosSegundaViaReabilitacao | null;
}

export interface IDadosSegundaViaReabilitacao {
	cpf: string;
	renach: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	dataNascimento: string;
	sexo: string;
	nacionalidade: string;
	naturalidade: string;
	descricaoNaturalidade: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	municipio: string;
	descricaoMunicipio: string;
	cep: string;
	tipoDocumento: string;
	numDocumento: string;
	orgaoDocumento: string;
	ufDocumento: string;
	numRegistro: string;
	numPGU: string;
	categoriaAtual: string;
	dataPrimeiraHabilitacao: string;
	validadeCnh: string;
	dataExameMedico: string;
	categoriaObtidaMedico: string;
	resultadoExameMedico: string;
	restricoesMedicas: string;
	validadeExameMedico: string;
	crm: string;
	ufMedico: string;
	dataExamePsicotecnico: string;
	resultadoExamePsicotecnico: string;
	laudo: string;
	validadeExamePsicotecnico: string;
	crp: string;
	situacaoBloqueio: string;
	situacaoCnhVencida: string;
	situacaoPontuacao: string;
	situacaoTaxaEmissao: string;
	situacaoPendenciasBca: string;
	situacaoProcessoAberto: string;
	procuradoJudicialmente: string;
	ciretranDomicilio: string;
	numRNE: string;
	orgaoRNE: string;
	ufRNE: string;
	paisCNHE: string;
	validadeCnhE: string;
	numCNHE: string;
	exameMedicoPsicologicoInapto: string;
	crmInapto: string;
	cpfMedicoInapto: string;
	crpInapto: string;
	cpfPsicologicoInapto: string;
	email: string;
	dddCelular: string;
	celular: string;
	cnhDigital: string;
	codigoVerificador: string;
	codigoInterno: string;
	message: string;
	nomeSocial: string;
	paisAfetivos1: string;
	paisAfetivos2: string;
}

export interface IRequestConsultaSegundaViaReabilitacao {
	cpfCidadao: string;
}
