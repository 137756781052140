import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaCondutorRequest } from './types';

// ACTIONS
import { consultaCondutorSuccess, consultaCondutorFailure } from './actions';

function* consultaCondutor(request: ReducerAction) {
	const { payload }: { payload: ConsultaCondutorRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`condutor/${CHANNEL}/consulta-condutor`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaCondutorSuccess(response));
	} else {
		yield put(consultaCondutorFailure(response.data));
	}
}

export default all([
	takeLatest(Types.CONSULTA_CONDUTOR_REQUEST, consultaCondutor),
]);
