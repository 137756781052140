// TYPES
import { ReducerAction } from 'store/modules/types';
import { IRequestEmissaoNumeral, DataEmissaoAtestado, Types } from './types';

export function emissaoNumeralRequest(
	payload: IRequestEmissaoNumeral,
): ReducerAction {
	return {
		type: Types.EMISSAO_NUMERAL_REQUEST,
		payload,
	};
}

export function emissaoNumeralSuccess(
	payload: DataEmissaoAtestado,
): ReducerAction {
	return {
		type: Types.EMISSAO_NUMERAL_SUCCESS,
		payload,
	};
}

export function emissaoNumeralFailure(): ReducerAction {
	return {
		type: Types.EMISSAO_NUMERAL_FAILURE,
		payload: null,
	};
}

export function limpaEmissaoNumeral(): ReducerAction {
	return {
		type: Types.LIMPA_EMISSAO_NUMERAL,
		payload: null,
	};
}
