import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import AgendamentoPsicologicoRenachIntegrado from 'pages/DetranCnh/components/pages/AgendamentoPsicologicoRenachIntegrado';
import ButtonImage from 'components/Common/ButtonImage';

// PATH
import { ROUTE_DETRAN_CNH } from 'routes/paths';
import { ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_RENACH } from 'pages/DetranCnh/routes/paths';

// FORM
import { initialValues, dadosCidadao } from './form';

const AgendamentoPsicologicoAdicaoMudancaCategoria: React.FC = () => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);

	const { saveGerarRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const { consultaCategoria } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.consulta,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	useEffect(() => {
		if (saveGerarRenach.data?.gerarRenachBase && user) {
			const {
				cpf,
				logradouroCep,
				categoriaPretendida,
				codCiretranHabilitacao,
			} = saveGerarRenach.data?.gerarRenachBase;

			initialValues.categoria_pretendida =
				consultaCategoria.divisaoEquitativa.filter(
					divisaoEquitativa => divisaoEquitativa.codigo === categoriaPretendida,
				)[0].codigoDivisaoEquitativa;
			initialValues.cpf_cidadao = Number(cpf);
			initialValues.cep_cidadao = Number(logradouroCep);
			initialValues.codigo_ciretran_exame_psicologo = saveGerarRenach.data
				?.gerarRenachBase.flagExamePosto
				? Number(user.posto)
				: Number(codCiretranHabilitacao);
		}
	}, [consultaCategoria.divisaoEquitativa, user, saveGerarRenach.data]);

	useEffect(() => {
		if (saveGerarRenach.data?.gerarRenachBase) {
			const {
				cpf,
				nome,
				nomeSocial,
				nomePai,
				nomeMae,
				numDocumento,
				dataNascimento,
				sexo,
				nacionalidade,
				codigoNaturalidade,
				categoriaPretendida,
				logradouroCep,
				flagDeficienteFisico,
				rneNumero,
			} = saveGerarRenach.data?.gerarRenachBase;

			// DADOS CIDADÃO
			dadosCidadao.nome = nomeSocial || nome;
			dadosCidadao.nomeMae = nomeMae;
			dadosCidadao.nomePai = nomePai;
			dadosCidadao.numeroRg = numDocumento;
			dadosCidadao.cpf = cpf;
			dadosCidadao.sexo = sexo;
			dadosCidadao.nacionalidade = nacionalidade;
			dadosCidadao.cep = logradouroCep;
			dadosCidadao.dataNascimento = dataNascimento;
			dadosCidadao.naturalidade = codigoNaturalidade || '';
			dadosCidadao.categoriaPretendida =
				consultaCategoria.divisaoEquitativa.filter(
					divisaoEquitativa => divisaoEquitativa.codigo === categoriaPretendida,
				)[0].codigoDivisaoEquitativa;
			dadosCidadao.isPortadorNecessidadesEspeciais =
				!!flagDeficienteFisico || flagDeficienteFisico === 'S';
			dadosCidadao.rne = rneNumero || '';
			setIsLoading(false);
		}
	}, [consultaCategoria.divisaoEquitativa, saveGerarRenach.data]);

	return (
		<>
			{!isLoading && (
				<>
					<AgendamentoPsicologicoRenachIntegrado
						dadosCidadao={dadosCidadao}
						dadosAgendamento={initialValues}
						redirecionamento={ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_RENACH}
					/>
					<Row justify="space-between" align="middle">
						<Col>
							{/* MANTER OCULTO ATÉ ALINHAMENTO COM O 2.0 PARA APLICAR O MESMO FLUXO EM AMBOS (VOLTAR PARA A TELA ANTERIOR).
								<ButtonVoltar
									navigate={false}
									onClick={() => history.push(ROUTE_DETRAN_CNH)}
								/>
							 */}
						</Col>
						<Col>
							<ButtonImage
								type="button"
								src="outro-servico-detran"
								onClick={() => history.push(ROUTE_DETRAN_CNH)}
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};
export default AgendamentoPsicologicoAdicaoMudancaCategoria;
