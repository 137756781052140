// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	IConsultarStatusMainframeDareState,
	Types,
	IStatusDareMainframeRequest,
} from './types';

export function consultarStatusDareMainframeRequest(
	payload: IStatusDareMainframeRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_STATUS_DARE_MAINFRAME_REQUEST,
		payload,
	};
}

export function consultarStatusDareMainframeSuccess(
	status: number,
	data: IConsultarStatusMainframeDareState | null,
): ReducerAction {
	return {
		type: Types.CONSULTAR_STATUS_DARE_MAINFRAME_SUCCESS,
		payload: {
			status,
			data,
		},
	};
}

export function consultarStatusDareMainframeFailure(
	status: number,
	data: null,
): ReducerAction {
	return {
		type: Types.CONSULTAR_STATUS_DARE_MAINFRAME_FAILURE,
		payload: {
			status,
			data,
		},
	};
}

export function consultarStatusDareMainframeClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_STATUS_DARE_MAINFRAME_CLEAR,
		payload: null,
	};
}
