import { ReducerAction } from 'store/modules/types';

import { Types, IRequestPreCadastroReabilitacao } from './types';

export function confirmaPreCadastroReabilitacaoRequest(
	data: IRequestPreCadastroReabilitacao,
): ReducerAction {
	return {
		type: Types.CONFIRMA_PRE_CADASTRO_REABILITACAO,
		payload: data,
	};
}
export function confirmaPreCadastroReabilitacaoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONFIRMA_PRE_CADASTRO_REABILITACAO_SUCCESS,
		payload,
	};
}
export function confirmaPreCadastroReabilitacaoFailure(): ReducerAction {
	return {
		type: Types.CONFIRMA_PRE_CADASTRO_REABILITACAO_FAILURE,
		payload: null,
	};
}
