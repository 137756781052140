import { Types } from './types';

export function consultarFichaAlunoRequest(payload: any) {
	return {
		type: Types.CONSULTAR_FICHA_ALUNO_REQUEST,
		payload,
	};
}

export function consultarFichaAlunoSuccess(payload: object) {
	return {
		type: Types.CONSULTAR_FICHA_ALUNO_SUCCESS,
		payload,
	};
}

export function consultarFichaAlunoFailure(payload: boolean) {
	return {
		type: Types.CONSULTAR_FICHA_ALUNO_FAILURE,
		payload,
	};
}

export function consultarFichaAlunoClear() {
	return {
		type: Types.CONSULTAR_FICHA_ALUNO_CLEAR,
		payload: null,
	};
}
