export enum Types {
	ESCOLAS_PROXIMAS_REQUEST = '@EDUCACAO/ESCOLAS_PROXIMAS_REQUEST',
	ESCOLAS_PROXIMAS_SUCCESS = '@EDUCACAO/ESCOLAS_PROXIMAS_SUCCESS',
	ESCOLAS_PROXIMAS_FAILURE = '@EDUCACAO/ESCOLAS_PROXIMAS_FAILURE',
}

export interface EscolasProximas {
	status: number;
	data: null | EscolasProximasData;
}

export interface EscolasProximasRequest {
	latitude: string;
	longitude: string;
}

interface EscolasProximasData {
	processoId: string;
	codigoEscola: number;
}
