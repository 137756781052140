import React from 'react';
import * as Yup from 'yup';

// REDUX
import { useDispatch } from 'react-redux';
import { clearNotifications } from 'store/modules/app/notifications/actions';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import Section from 'components/Common/Section';

// FORM
import { Form, Formik, FormikProps } from 'formik';
import { Col, Row } from 'antd';
import { BoxFormInputsPlacaRenavamCpfCnpj } from '../../../BoxFormInputsPlacaRenavamCpfCnpj';
import {
	initialValuesDefault,
	schemaDefault,
	IPesquisarPorPlacaRenavamCpfCnpj,
} from './form';

interface PesquisarPorPlacaRenavamCpfCnpjProps {
	handleSubmit: (values: IPesquisarPorPlacaRenavamCpfCnpj) => void;
	schema?: Yup.ObjectSchema<Yup.Shape<any | undefined, any>, object>;
	initialValues?: IPesquisarPorPlacaRenavamCpfCnpj | any;
}

export const PesquisarPorPlacaRenavamCpfCnpj = ({
	handleSubmit,
	schema,
	initialValues,
}: PesquisarPorPlacaRenavamCpfCnpjProps) => {
	const dispatch = useDispatch();

	return (
		<Section title="Pesquisar">
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={initialValues || initialValuesDefault}
				validate={values => getValidationsErrors(values, schema)}
				onSubmit={values => {
					handleSubmit(values);
				}}
			>
				{(formik: FormikProps<any>) => {
					return (
						<Form>
							<Row justify="center">
								<Col span={8}>
									<BoxFormInputsPlacaRenavamCpfCnpj formik={formik} />

									<Row gutter={[0, 20]}>
										<Col span={24} />
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => {
													formik.handleReset();
													dispatch(clearNotifications());
												}}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>
					);
				}}
			</Formik>
		</Section>
	);
};

PesquisarPorPlacaRenavamCpfCnpj.defaultProps = {
	schema: schemaDefault,
	initialValues: initialValuesDefault,
};
