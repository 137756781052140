export function isRgPrimeiraVia(lote: string): boolean {
	/**
	 * Tipos de Lotes:
	 * Primeira via => `000001` || `1` (Menor)
	 * Primeira via => `000010` || `10` (Maior)
	 */
	if (['000001', '1', '000010', '10'].includes(lote)) {
		return true;
	}

	return false;
}

export function isRgSegundaVia(lote: string): boolean {
	/**
	 * Tipos de Lotes:
	 * Segunda via  => `000002` (Menor / Maior)
	 * Segunda via  => `2` (Acervo) (?)
	 */

	if (['000002', '2'].includes(lote)) {
		return true;
	}

	return false;
}

export function isRgAcervo(lote: string): boolean {
	/**
	 * Tipos de Lotes:
	 * Segunda via  => `2` (Acervo) (?)
	 */

	if (lote === '2') {
		return true;
	}

	return false;
}

export function isUnderAge(date: string): boolean {
	const today = new Date();
	const birthDate = new Date(date);
	const age = today.getFullYear() - birthDate.getFullYear();
	const hadBirthday =
		today >=
		new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());

	if (age > 18) {
		return false;
	}
	if (age === 18 && hadBirthday) {
		return false;
	}
	return true;
}

const formatDate = (date: string) => {
	if (date.includes('/')) {
		const splitDate = date.split('/');
		return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
	}
	return date;
};

export function isBetweenDate(date: string, numDays: number): boolean {
	const priorDate = new Date();
	priorDate.setDate(priorDate.getDate() - numDays);
	const testDate = new Date(formatDate(date));
	return testDate > priorDate;
}

export const getKeyValue =
	<T extends object, U extends keyof T>(obj: T) =>
	(key: U) =>
		obj[key];

export const setFormValuesByKeys = (formValues: { [key: string]: any }) => {
	if (!formValues) return {};

	const builtFormValues: Object = Object.keys(formValues).reduce(
		(acc: any, cur: string) => {
			return {
				...acc,
				[cur]: getKeyValue(formValues)(cur),
			};
		},
		{},
	);

	return builtFormValues;
};

export const isDateBefore = (dateToTest: string) => {
	if (dateToTest === '') return false;
	const today = new Date();
	const date = new Date(formatDate(dateToTest));
	if (date <= today) {
		return true;
	}
	return false;
};

export const treatObs = (formValues: any) => {
	if (formValues.observacao && formValues.observacao !== '') {
		return formValues.observacao.toUpperCase();
	}
	if (formValues.observacoes && formValues.observacoes !== '') {
		return formValues.observacoes.toUpperCase();
	}
	return '';
};
