import { ReducerAction } from 'store/modules/types';
import {
	Types,
	IRequestWithHeader,
	IResponseEmitirTrocaVoluntariaPlaca,
} from './types';

export function emitirTrocaVoluntariaPlacaRequest(
	payload: IRequestWithHeader,
): ReducerAction {
	return {
		type: Types.EMITIR_TROCA_VOLUNTARIA_PLACA_REQUEST,
		payload,
	};
}
export function emitirTrocaVoluntariaPlacaSuccess(
	payload: IResponseEmitirTrocaVoluntariaPlaca,
): ReducerAction {
	return {
		type: Types.EMITIR_TROCA_VOLUNTARIA_PLACA_SUCCESS,
		payload,
	};
}
export function emitirTrocaVoluntariaPlacaFailure(): ReducerAction {
	return {
		type: Types.EMITIR_TROCA_VOLUNTARIA_PLACA_FAILURE,
		payload: null,
	};
}
export function emitirTrocaVoluntariaPlacaClear(): ReducerAction {
	return {
		type: Types.EMITIR_TROCA_VOLUNTARIA_PLACA_CLEAR,
		payload: null,
	};
}
