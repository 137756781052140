import produce from 'immer';

// TYPES
import { Types, ThemeState } from './types';
import { ReducerAction } from '../../types';

export const INITIAL_STATE: ThemeState = {
	fontSize: 0,
	colorTheme: 'light',
};

export default function app(
	state = INITIAL_STATE,
	action: ReducerAction,
): ThemeState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.INCREASE_FONT_SIZE: {
				draft.fontSize += 10;
				break;
			}
			case Types.DECREASE_FONT_SIZE: {
				draft.fontSize -= 10;
				break;
			}

			case Types.SET_LIGHT_THEME: {
				draft.colorTheme = 'light';
				break;
			}
			case Types.SET_DARK_THEME: {
				draft.colorTheme = 'dark';
				break;
			}

			default:
		}
	});
}
