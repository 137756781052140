export enum Types {
	EXCLUIR_PARAMETRO_SISTEMA_REDIS = '@SGU/EXCLUIR_PARAMETRO_SISTEMA_REDIS',
	EXCLUIR_PARAMETRO_SISTEMA_REDIS_SUCCESS = '@SGU/EXCLUIR_PARAMETRO_SISTEMA_REDIS_SUCCESS',
	EXCLUIR_PARAMETRO_SISTEMA_REDIS_FAILURE = '@SGU/EXCLUIR_PARAMETRO_SISTEMA_REDIS_FAILURE',
	EXCLUIR_PARAMETRO_SISTEMA_REDIS_CLEAR = '@SGU/EXCLUIR_PARAMETRO_SISTEMA_REDIS_CLEAR',
}

export interface ExcluirParametroSistemaRedis {
	status: number;
}

export interface ExcluirParametroSistemaRedisRequest {
	chave: string;
}
