import { limparMascara } from './genericFunctions';

export function hasNumber(value: string): boolean {
	const matches = value.match(/\d+/g);

	if (matches != null) {
		return true;
	}

	return false;
}

export function minCharacters(amount: number, value: string) {
	let resultNumCharacters = false;
	if (value !== '' && value.length < amount) {
		resultNumCharacters = false;
	} else {
		resultNumCharacters = true;
	}
	return resultNumCharacters;
}

export function onlyLetters(value: string): boolean {
	return /^[A-Za-záàâãäéèêëíïóôõöúüçñÁÀÂÃÄÉÈÊËÍÏÓÔÕÖÚÜÇÑ\s]*$/.test(value);
}

export function validateName(value: string): boolean {
	return /[^-~a-zÀ-ÖØ-öø-ÿ,\s]*$/u.test(value);
}

export function validateEmail(value: string): boolean {
	return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
		value,
	);
}

export function namesIirgd(value: string): boolean {
	return /[^-~a-zÀ-ÖØ-öø-ÿ,\s]*$/.test(value);
}

export function onlyNumeric(value: string): boolean {
	return /^[0-9]+$/.test(value);
}

export function isCpfValid(value: string) {
	const cpf = limparMascara(value);

	if (cpf.length !== 11) return false;

	const n = cpf.length;
	let isSameCharacter = true;

	for (let i = 1; i < n; i++) {
		if (cpf[i] !== cpf[0]) {
			isSameCharacter = false;
			break;
		}
	}

	if (isSameCharacter) return false;

	let soma = 0;
	let resto;

	for (let i = 1; i <= 9; i++) {
		soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
	}

	resto = (soma * 10) % 11;

	if (resto === 10 || resto === 11) resto = 0;

	if (resto !== parseInt(cpf.substring(9, 10), 10)) return false;

	soma = 0;

	for (let j = 1; j <= 10; j++) {
		soma += parseInt(cpf.substring(j - 1, j), 10) * (12 - j);
	}

	resto = (soma * 10) % 11;

	if (resto === 10 || resto === 11) resto = 0;

	if (resto !== parseInt(cpf.substring(10, 11), 10)) return false;

	return true;
}
