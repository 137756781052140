import React from 'react';

// ANTD
import { Col, Row } from 'antd';

// COMPONENTS COMMON
import TypographyCommon from 'components/Common/Typography';

// COMPONENTS
import { v4 } from 'uuid';

// PATHS
import Section from 'components/Common/Section';

// REDUX
import { ConsultaExtratoResumoCondutorData } from 'store/modules/api/detranCnh/eportal/consultaExtratoResumoCondutor/types';

// UTILS
import { isFilledValue } from 'utils/stringFunctions';
import { formatDate } from 'utils/genericFunctions';
import { formatCurrency } from 'utils/numberFunctions';

interface Props {
	consultaExtratoResumoCondutor:
		| ConsultaExtratoResumoCondutorData['ptax']
		| null
		| undefined;
	mensagemPtax:
		| ConsultaExtratoResumoCondutorData['mensagemPtax']
		| null
		| undefined;
}

const Taxas: React.FC<Props> = ({
	consultaExtratoResumoCondutor = null,
	mensagemPtax = null,
}) => {
	return (
		<>
			<Section title="Taxas (PTAX)">
				{consultaExtratoResumoCondutor &&
					consultaExtratoResumoCondutor.map(item => (
						<span key={v4()}>
							<Row gutter={[0, 0]} align="top" justify="start">
								<Col span={2}>
									<TypographyCommon
										text="Valor:"
										type="label"
										fontWeight="bold"
										uppercase
									/>
								</Col>
								<Col span={3}>
									<TypographyCommon
										text={isFilledValue(formatCurrency(item?.valor))}
										type="label"
									/>
								</Col>
								<Col span={2}>
									<TypographyCommon
										text="Tipo:"
										type="label"
										fontWeight="bold"
										uppercase
									/>
								</Col>
								<Col span={3}>
									<TypographyCommon
										text={isFilledValue(item?.tipo)}
										type="label"
									/>
								</Col>
								<Col span={3}>
									<TypographyCommon
										text="Situação:"
										type="label"
										fontWeight="bold"
										uppercase
									/>
								</Col>
								<Col span={6}>
									<TypographyCommon
										text={isFilledValue(item?.situacao)}
										type="label"
									/>
								</Col>
								<Col span={2}>
									<TypographyCommon
										text="Data:"
										type="label"
										fontWeight="bold"
										uppercase
									/>
								</Col>
								<Col span={3}>
									<TypographyCommon
										text={isFilledValue(formatDate(item?.dataUtilizada))}
										type="label"
									/>
								</Col>
							</Row>
						</span>
					))}

				{mensagemPtax && (
					<Row gutter={[0, 0]} align="top">
						<Col span={24}>
							<TypographyCommon
								text={isFilledValue(mensagemPtax)}
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Col>
					</Row>
				)}
			</Section>
		</>
	);
};
export default Taxas;
