import React, { useCallback, useState, useEffect } from 'react';
import { Field, FormikProps, Formik } from 'formik';

import SimpleTable from 'components/Common/Table';
import Select, { OptionProps } from 'components/Common/Form/Select';
import Input from 'components/Common/Form/Input/Input';

import { IBeneficiario } from 'store/modules/api/seds/vivaLeite/beneficiarios/types';
import { v4 } from 'uuid';
import { ICodPrograma } from '../form';

export interface IDataMotivo {
	codigo_motivo: number;
	outros_motivos?: string;
}

export interface IPropos {
	formik: FormikProps<ICodPrograma>;
	selectCodigo: (v: number[]) => void;
	beneficiariosData: IBeneficiario[];
	selectMotivosCorte: OptionProps[];
}

export interface IOutrosMotivos {
	outros_motivos: string[];
}

interface IBeneficiariosData {
	id: number;
	cod_pan_beneficio: number;
	idoso?: string;
	responsavel_crianca?: string;
}

const ExcluirBeneficiariosTable = ({
	formik,
	selectCodigo,
	beneficiariosData,
	selectMotivosCorte,
}: IPropos) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<string | string[]>([]);

	const [dataOutrosMotivos, setDataOutrosMotivos] = useState({
		outros_motivos: [''],
	});
	const [beneficiariosDataUpdated, setBeneficiariosDataUpdated] = useState<
		IBeneficiariosData[]
	>([]);

	const rowSelection = {
		selectedRowKeys,
		onChange: (key: string, second: IBeneficiario[]) => {
			const updatedCode: number[] = [];
			second.forEach(item => {
				updatedCode.push(item.cod_pan_beneficio);
			});
			selectCodigo(updatedCode);
			formik.setFieldValue('selectedItems', updatedCode);
			return setSelectedRowKeys(key);
		},
	};

	const handleChangeInput = useCallback(
		(value: IOutrosMotivos, index: number) => {
			formik.setFieldValue(
				`exclusoes[${index}].outros_motivos`,
				value.outros_motivos[index],
			);

			const updatedOutrosMotivos = { ...dataOutrosMotivos };
			updatedOutrosMotivos.outros_motivos[index] = value.outros_motivos[index];

			setDataOutrosMotivos(updatedOutrosMotivos);
		},
		[dataOutrosMotivos, formik],
	);

	const onChangeSelect = useCallback(
		(value: string, index: number, record: IBeneficiario) => {
			formik.setFieldValue(`exclusoes[${index}].codigo_motivo`, value);
			formik.setFieldValue(
				`exclusoes[${index}].cod_pan_beneficio`,
				record.cod_pan_beneficio,
			);
			formik.setFieldValue(
				`exclusoes[${index}].nome`,
				record.responsavel_crianca ? record.responsavel_crianca : record.idoso,
			);
		},
		[formik],
	);

	useEffect(() => {
		setBeneficiariosDataUpdated(
			beneficiariosData
				.filter(item => !!item?.cod_pan_beneficio)
				.map((item, index) => ({ id: index, ...item })),
		);
	}, [beneficiariosData]);

	const headers = [
		{
			title: 'Código',
			dataIndex: 'cod_pan_beneficio',
		},
		{
			title: 'Nome',
			dataIndex: '',
			render: (text: any) => (
				<span>
					{text.responsavel_crianca ? text.responsavel_crianca : text.idoso}
				</span>
			),
		},
		{
			title: 'Motivo',
			dataIndex: '',
			render: (text: string, record: IBeneficiariosData) => (
				<Field
					key={v4()}
					as={Select}
					options={selectMotivosCorte}
					name={`exclusoes[${record.id}].codigo_motivo`}
					onChange={(v: string) => {
						onChangeSelect(v, record.id, record);
					}}
				/>
			),
		},
		{
			key: v4(),
			title: '',
			dataIndex: '',
			editable: true,
			render: (text: string, record: IBeneficiariosData) => (
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={dataOutrosMotivos}
					onSubmit={(v: IOutrosMotivos) => {
						handleChangeInput(v, record.id);
					}}
				>
					{formikInside => (
						<Field
							type="text"
							as={Input}
							name={`outros_motivos[${record.id}]`}
							onBlur={() => {
								formikInside.submitForm();
							}}
							disabled={
								formik.values.exclusoes
									? formik.values.exclusoes[record.id]?.codigo_motivo !== 9
									: true
							}
						/>
					)}
				</Formik>
			),
		},
	];

	return (
		<SimpleTable
			headers={headers}
			body={beneficiariosDataUpdated}
			striped
			rowSelection={rowSelection}
		/>
	);
};

export default ExcluirBeneficiariosTable;
