import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultarMunicipioPorUfRequest } from 'store/modules/api/detranCrv/enum/consultarMunicipioPorUF/actions';
import { emitirTrocaVoluntariaPlacaRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirTrocaVoluntariaPlaca/actions';
import { consultarVeiculoV110Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import { IRequestEmitirTrocaVoluntariaPlaca } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirTrocaVoluntariaPlaca/types';

// COMPONENTS
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import Placa from 'components/Common/Form/Fields/Placa';
import Button from 'components/Common/Button';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import UploadArquivo from 'pages/DetranCrv/components/pages/UploadArquivo';
import TaxaServico from 'pages/DetranCrv/components/pages/TaxaServico';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

import DadosSolicitante from './DadosSolicitante';

// FORM
import { initialValues, schema, treatValues } from './form';

const EmitirTrocaVoluntariaPlaca: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { consultarMunicipioPorUF } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.Enum,
	);

	const { consultarVeiculoV110, emitirTrocaVoluntariaPlaca } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { consultarTaxaServico } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	const { salvarAtendimento } = atendimento;
	const { login } = loginUnico;

	const sguData: SguData = useMemo(() => {
		return {
			salvarAtendimento,
			login,
		};
	}, [login, salvarAtendimento]);

	const [hasLoaded, setHasLoaded] = useState<boolean>(false);
	const evento = useMemo(() => {
		return getEventoConsultar(sguData);
	}, [sguData]);

	useEffect(() => {
		if (!consultarVeiculoV110.data) {
			history.push('/detran-crv/troca-voluntaria-placa/consultar');
		}
	}, [consultarVeiculoV110.data, history]);

	useEffect(() => {
		if (emitirTrocaVoluntariaPlaca.data) {
			history.push('/detran-crv/troca-voluntaria-placa/resultado');
		}
	}, [emitirTrocaVoluntariaPlaca.data, history]);

	useEffect(() => {
		if (consultarVeiculoV110.data && consultarMunicipioPorUF.data.length > 0) {
			const { placa, renavam, chassi, municipio } =
				consultarVeiculoV110.data.caracteristicaVeiculo;

			// Dados do Veículo
			initialValues.placa = placa;
			initialValues.renavam = Number(renavam);
			initialValues.chassi = chassi;
			initialValues.municipio = municipio;

			setHasLoaded(true);
		}
	}, [consultarVeiculoV110.data, consultarMunicipioPorUF.data, history]);

	// Enum: Municípios - SP.
	useEffect(() => {
		if (consultarMunicipioPorUF.data.length === 0) {
			dispatch(consultarMunicipioPorUfRequest('SP'));
		}
	}, [consultarMunicipioPorUF, dispatch]);

	const handleValidate = useCallback(
		(formValues: IRequestEmitirTrocaVoluntariaPlaca) => {
			return getValidationsErrors(formValues, schema);
		},
		[],
	);

	const handleSubmit = useCallback(
		(formValues: IRequestEmitirTrocaVoluntariaPlaca) => {
			const headers = {
				codUnidade: evento.canal.localidade.id.toString(),
				cpfUsuario: evento.cpf,
			};
			let body;
			if (consultarTaxaServico.data) {
				body = treatValues(evento, formValues, consultarTaxaServico.data);
			} else {
				body = treatValues(evento, formValues);
			}
			dispatch(emitirTrocaVoluntariaPlacaRequest({ body, headers }));
		},
		[dispatch, evento, consultarTaxaServico.data],
	);

	const handleButtonCancelar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());

		history.push('/detran-crv/troca-voluntaria-placa/consultar');
	}, [dispatch, history]);

	const handleButtonVoltar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());

		history.push('/detran-crv/troca-voluntaria-placa/consultar');
	}, [dispatch, history]);

	return (
		<>
			{hasLoaded && (
				<>
					<Row>
						<Col span={24}>
							<Section
								size="lg"
								title="Troca Voluntária de Placa"
								titleSize="sm"
							>
								<Formik
									validateOnChange={false}
									validateOnBlur={false}
									initialValues={initialValues}
									validate={handleValidate}
									onSubmit={handleSubmit}
								>
									{formik => (
										<Form autoComplete="off">
											<Row gutter={[0, 10]}>
												<Col span={24}>
													<Section
														size="lg"
														title="Dados do Veículo"
														titleSize="sm"
													>
														<Row gutter={[0, 10]}>
															<Col span={11}>
																<Placa
																	title="Placa"
																	titleSize="lg"
																	name="placa"
																	defaultValue={initialValues.placa}
																	size={80}
																	required
																	disabled
																	formik={formik}
																/>
															</Col>

															<Col span={11}>
																<Field
																	as={InputMask}
																	title="Renavam"
																	titleSize="lg"
																	name="renavam"
																	mask="99999999999"
																	maskChar=""
																	alwaysShowMask={false}
																	size={92}
																	required
																	disabled
																	error={!!formik.errors.renavam}
																/>
															</Col>
														</Row>

														<Row>
															<Col span={11}>
																<Field
																	as={Input}
																	title="Chassi"
																	titleSize="lg"
																	name="chassi"
																	maxLength={22}
																	size={80}
																	required
																	disabled
																	error={!!formik.errors.chassi}
																/>
															</Col>

															<Col span={11}>
																<Field
																	as={Select}
																	title="Município"
																	titleSize="lg"
																	name="municipio.codigo"
																	options={consultarMunicipioPorUF.data}
																	onChange={(v: number) => {
																		formik.setFieldValue('municipio.codigo', v);

																		const selectedItem =
																			consultarMunicipioPorUF.data.filter(
																				item => v === item.value,
																			);

																		formik.setFieldValue(
																			'municipio.nome',
																			selectedItem[0].label,
																		);
																	}}
																	size={92}
																	required
																	disabled
																	error={hasError(formik.errors, 'municipio')}
																/>
															</Col>
														</Row>
													</Section>
												</Col>
											</Row>

											<Row gutter={[0, 10]}>
												<Col span={24} />
											</Row>

											<DadosSolicitante formik={formik} />

											{consultarTaxaServico.data && (
												<Section size="sm">
													<TaxaServico
														dataConsultarTaxaServico={consultarTaxaServico.data}
													/>
												</Section>
											)}

											<UploadArquivo
												formik={formik}
												evento={evento}
												tipoArquivo="2"
												sizeMB={10000000}
											/>

											<Row gutter={[0, 20]}>
												<Col span={24} />
											</Row>

											<Row justify="center">
												<Col>
													<Button onClick={handleButtonCancelar}>
														Cancelar
													</Button>
												</Col>

												<Col offset={1}>
													<Button type="submit">Enviar</Button>
												</Col>
											</Row>
										</Form>
									)}
								</Formik>
							</Section>

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default EmitirTrocaVoluntariaPlaca;
