import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestPreCadastroReabilitacao } from './types';

// ACTIONS
import {
	confirmaPreCadastroReabilitacaoSuccess,
	confirmaPreCadastroReabilitacaoFailure,
} from './actions';

function* confirmaPreCadastroReabilitacao(request: ReducerAction) {
	const { payload }: { payload: IRequestPreCadastroReabilitacao } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`reabilitacao-cassacao/${CHANNEL}/gerar-pre-cadastro`,
		payload,
	);

	if (response.status === 200) {
		yield put(confirmaPreCadastroReabilitacaoSuccess(response));
	} else if (response.error) {
		yield put(confirmaPreCadastroReabilitacaoFailure());
	}
}

export default all([
	takeLatest(
		Types.CONFIRMA_PRE_CADASTRO_REABILITACAO,
		confirmaPreCadastroReabilitacao,
	),
]);
