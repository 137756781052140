import { ReducerAction } from 'store/modules/types';
import { MarcaModelo, Types } from './types';

export interface IRequestConsultaMarcaModelo {
	desc: string;
}

export function consultaMarcaModeloRequest(
	payload: IRequestConsultaMarcaModelo,
): ReducerAction {
	return {
		type: Types.CONSULTA_MARCA_MODELO_REQUEST,
		payload,
	};
}
export function consultaMarcaModeloSuccess(
	payload: MarcaModelo[],
): ReducerAction {
	return {
		type: Types.CONSULTA_MARCA_MODELO_SUCCESS,
		payload,
	};
}
export function consultaMarcaModeloFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_MARCA_MODELO_FAILURE,
		payload: null,
	};
}
export function consultaMarcaModeloClear(): ReducerAction {
	return {
		type: Types.CONSULTA_MARCA_MODELO_CLEAR,
		payload: null,
	};
}
