import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import data from 'store/modules/api/cdhu/enum';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import {
	tipoVinculoSolicitante,
	consultarSolicitanteRequest,
} from 'store/modules/api/cdhu/consultarSolicitante/actions';
import { consultarMutuarioRequest } from 'store/modules/api/cdhu/consultarMutuario/actions';

// PATHS
import {
	ROUTE_CDHU_MUTUARIO_DADOS,
	ROUTE_CDHU_SOLICITANTE_DADOS,
} from 'pages/cdhu/routes/paths';

// COMPONENTS
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';
import CPF from 'components/Common/Form/Fields/CPF';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { IFormConsultarSolicitante, initialValues, schema } from './form';

const PesquisarSolicitante: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [hasSubmit, setHasSubmit] = useState<boolean>(false);

	const {
		consultarMutuario,
		consultarPrestacoesAtrasadas,
		consultarSolicitante,
	} = useSelector((state: ApplicationState) => state.api.cdhu);

	useEffect(() => {
		if (hasSubmit) {
			if (
				consultarMutuario.status === 200 &&
				consultarPrestacoesAtrasadas.status === 200
			) {
				history.push(ROUTE_CDHU_MUTUARIO_DADOS);
				return;
			}

			if (consultarSolicitante.status === 200) {
				history.push(ROUTE_CDHU_SOLICITANTE_DADOS);
			}
		}
	}, [
		hasSubmit,
		consultarMutuario,
		consultarPrestacoesAtrasadas,
		consultarSolicitante,
		history,
	]);

	const handleValidate = useCallback((values: IFormConsultarSolicitante) => {
		return getValidationsErrors(values, schema);
	}, []);

	const handleSubmit = useCallback(
		(values: IFormConsultarSolicitante) => {
			setHasSubmit(true);

			dispatch(tipoVinculoSolicitante(values.tipoVinculo));

			// Mutuário/Beneficiário.
			if (Number(values.tipoVinculo) === 808620001) {
				if (values.numContrato) {
					dispatch(
						consultarMutuarioRequest(token, {
							numeroContrato: Number(values.numContrato),
						}),
					);

					return;
				}

				dispatch(consultarMutuarioRequest(token, { cpf: values.cpf }));

				return;
			}

			dispatch(consultarSolicitanteRequest(token, values));
		},
		[dispatch, token],
	);

	return (
		<>
			<Section size="lg" title="Pesquisar solicitante" titleSize="sm">
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={handleValidate}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row gutter={[0, 10]}>
										<Col span={14}>
											<Field
												as={Select}
												title="Tipo de vínculo com a CDHU"
												titleSize="xl"
												name="dbas"
												options={data.tipoVinculo}
												onChange={(v: number) =>
													formik.setFieldValue('tipoVinculo', v)
												}
												required
												error={!!formik.errors.tipoVinculo}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={21}>
											<CPF
												title="CPF"
												titleSize="xl"
												subtitle="(Apenas números)"
												numero="cpf"
												formik={formik}
												required
												error={
													!!formik.values.cpf &&
													hasError(formik.errors, 'cpfNumContrato')
												}
											/>
										</Col>
									</Row>

									{formik.values.tipoVinculo === 808620001 && (
										<Row gutter={[0, 10]}>
											<Col span={14}>
												<Field
													as={InputMask}
													title="Número do contrato"
													titleSize="xl"
													name="numContrato"
													mask="9999999"
													error={
														!!formik.values.numContrato &&
														hasError(formik.errors, 'cpfNumContrato')
													}
												/>
											</Col>
										</Row>
									)}

									<Row gutter={[0, 20]}>
										<Col span={24} />
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => {
													formik.handleReset();
													dispatch(clearNotifications());
												}}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>

			<ButtonVoltar />
		</>
	);
};

export default PesquisarSolicitante;
