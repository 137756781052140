import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { IPrimeiroEmplacamento } from 'pages/DetranCrv/SolicitarDocumentos/PrimeiroEmplacamento/form';
import { Types } from './types';
import {
	primeiroEmplacamentoSuccess,
	primeiroEmplacamentoFailure,
} from './actions';

function* primeiroEmplacamentoRequest(request: {
	type: string;
	payload: IPrimeiroEmplacamento;
}) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/primeiro-emplacamento/consulta-placa-disponivel`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(primeiroEmplacamentoSuccess(response));
	} else if (response.error) {
		yield put(primeiroEmplacamentoFailure(response.message));
	}
}

export default all([
	takeLatest(Types.PRIMEIRO_EMPLACAMENTO_REQUEST, primeiroEmplacamentoRequest),
]);
