import { ReducerAction } from 'store/modules/types';

import { Types, RelatorioAgendamentoMedicoPsicologicoRequest } from './types';

export function relatorioAgendamentoMedicoPsicologicoRequest(
	data: RelatorioAgendamentoMedicoPsicologicoRequest,
): ReducerAction {
	return {
		type: Types.RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_REQUEST,
		payload: data,
	};
}
export function relatorioAgendamentoMedicoPsicologicoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_SUCCESS,
		payload,
	};
}
export function relatorioAgendamentoMedicoPsicologicoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_FAILURE,
		payload,
	};
}
export function relatorioAgendamentoMedicoPsicologicoClear(): ReducerAction {
	return {
		type: Types.RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_CLEAR,
		payload: null,
	};
}
