import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultaPermissaoInternacional } from './types';

export function consultaPermissaoInternacionalRequest(
	data: IRequestConsultaPermissaoInternacional,
): ReducerAction {
	return {
		type: Types.CONSULTA_PERMISSAO_INTERNACIONAL,
		payload: data,
	};
}
export function consultaPermissaoInternacionalSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_PERMISSAO_INTERNACIONAL_SUCCESS,
		payload,
	};
}
export function consultaPermissaoInternacionalFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.CONSULTA_PERMISSAO_INTERNACIONAL_FAILURE,
		payload,
	};
}
