import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarFuncionalidadeRequest } from './types';

// ACTIONS
import {
	cadastrarFuncionalidadeSuccess,
	cadastrarFuncionalidadeFailure,
} from './actions';

function* cadastrarFuncionalidade(request: ReducerAction) {
	const { payload }: { payload: CadastrarFuncionalidadeRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU,
		`/funcionalidades`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarFuncionalidadeSuccess(response));
	} else {
		yield put(cadastrarFuncionalidadeFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_FUNCIONALIDADE, cadastrarFuncionalidade),
]);
