export enum Types {
	ENVIA_SOLICITACAO_MP_REQUEST = '@MP/ENVIA_SOLICITACAO_MP_REQUEST',
	ENVIA_SOLICITACAO_MP_SUCCESS = '@MP/ENVIA_SOLICITACAO_MP_SUCCESS',
	ENVIA_SOLICITACAO_MP_FAILURE = '@MP/ENVIA_SOLICITACAO_MP_FAILURE',
	LIMPAR_SOLICITACAO_MP = '@MP/LIMPAR_SOLICITACAO_MP',
}

export interface enviaSolicitacaoMP {
	status: number;
	data: enviaSolicitacaoMPData;
}

export interface enviaSolicitacaoMPData {
	protocolo?: string;
	codigo?: number;
	mensagem?: string;
}

export interface IEvento {
	cpf: string;
	id_atendimento?: string;
	id_cidadao?: string;
	idAtendimento?: string;
	idCidadao?: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface IRequestEnviarSolicitacaoMP {
	evento: IEvento;
	filho: {
		nome: string;
		dtNascimento: number;
		cpf: string;
		email: string;
		sexo: string;
		endereco: {
			logradouro: string;
			numero: string;
			bairro: string;
			complemEndereco: string;
			cep: string;
			cidade: string;
			uf: string;
		};
		rg: {
			numero: string;
			digito: string;
			uf: string;
		};
		telefones: [
			{
				ddd: string;
				numero: string;
				tipo: number;
			},
		];
		genitor: {
			nome: string;
			sexo: string;
			dtNascimento: number;
			rg: {
				numero: string;
				digito: string;
				uf: string;
			};
			endereco: {
				logradouro: string;
				numero: string;
				bairro: string;
				complemEndereco: string;
				cep: string;
				cidade: string;
				uf: string;
			};
			telefones: [
				{
					ddd: string;
					numero: string;
					tipo: number;
				},
			];
			cidadeNasc: string;
			ufNasc: string;
			nomeMae: string;
			cpf: string;
			email: string;
			outrasInformacoes: string;
		};
	};
	responsavel: {
		nome: string;
		cpf: string;
		email: string;
		dtNascimento: number;
		sexo: string;
		rg: {
			numero: string;
			digito: string;
			uf: string;
		};
		endereco: {
			logradouro: string;
			numero: string;
			bairro: string;
			complemEndereco: string;
			cep: string;
			cidade: string;
			uf: string;
		};
		telefones: [
			{
				ddd: string;
				numero: string;
				tipo: number;
			},
		];
	};
	destinatarioSolicitacao: string;
	aceiteTermoDeclaracaoPobreza: true;
	aceiteTermo: true;
	aceiteAcrescNomePai: true;
	idAtendente: string;
	dataCriacao: number;
	dataModificacao: number;
	status: string;
	origem: string;
	protocolo: string;
	idCidadao: string;
	idAtendimento: string;
	statusCode: string;
	mensagem: string;
	emailContato: string;
	idPosto: number;
	nomePosto: string;
	idSolicitacao: string;
	informacoesAdicionais: string;
	anexos: [
		{
			nome: string;
			tipo: string;
			conteudo: string;
		},
	];
}
