export enum Types {
	ATUALIZAR_DARE_REQUEST = '@dare/ATUALIZAR_DARE_REQUEST',
	ATUALIZAR_DARE_SUCCESS = '@dare/ATUALIZAR_DARE_SUCCESS',
	ATUALIZAR_DARE_FAILURE = '@dare/ATUALIZAR_DARE_FAILURE',
	ATUALIZAR_DARE_CLEAR = '@dare/ATUALIZAR_DARE_CLEAR',
}

export interface IAtualizarDareState {
	status: number;
	data: AtualizarDareResponse | null;
	numeroDare: string | null;
}

export interface AtualizarDareResponse {
	data: string;
	mensagem: string;
}
export interface IDependenteDare {
	nome: string;
	grauParentesco: string;
	dataNascimento: string;
	numeroRg: string;
	segundaVia: boolean;
}
