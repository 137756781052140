import { IRequestIncluirNotasFiscais } from 'store/modules/api/seds/vivaLeite/notasFiscais/types';
import * as Yup from 'yup';

export interface IFormNotasFiscais {
	mes: number | string;
	ano: number | string;
	danfes: Array<string | number>;
}

export const InitialValues: IFormNotasFiscais = {
	mes: '',
	ano: '',
	danfes: ['', '', '', '', '', '', '', '', ''],
};

export const InitialIncluirNotasFiscais: IRequestIncluirNotasFiscais = {
	servico: 'PRESTACAO CONTAS',
	origem: 'PORTAL',
	num_pan: 0,
	ano: 0,
	mes: 0,
	danfes: [],
};

export const schema = Yup.object<IFormNotasFiscais>().shape({
	mes: Yup.number()
		.required('Campo Obrigatório: Mês de Referência.')
		.nullable()
		.transform((value: string, originalValue: string) =>
			originalValue.toString().trim() === '' ? null : value,
		),
	ano: Yup.number()
		.required('Campo Obrigatório: Ano Referência.')
		.nullable()
		.transform((value: string, originalValue: string) =>
			originalValue.toString().trim() === '' ? null : value,
		),
});
