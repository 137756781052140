import { ReducerAction } from 'store/modules/types';

import { ConsultarLocalidadeRequest, Types } from './types';

export function consultarLocalidadeRequest(
	payload: ConsultarLocalidadeRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_LOCALIDADE_REQUEST,
		payload,
	};
}
export function consultarLocalidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function consultarLocalidadeFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function consultarLocalidadeClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_LOCALIDADE_CLEAR,
		payload: null,
	};
}
