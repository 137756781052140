import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultaSegundaViaReabilitacao } from './types';

export function consultaSegundaViaReabilitacaoRequest(
	data: IRequestConsultaSegundaViaReabilitacao,
): ReducerAction {
	return {
		type: Types.CONSULTA_SEGUNDA_VIA_REABILITACAO,
		payload: data,
	};
}
export function consultaSegundaViaReabilitacaoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_SEGUNDA_VIA_REABILITACAO_SUCCESS,
		payload,
	};
}
export function consultaSegundaViaReabilitacaoFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.CONSULTA_SEGUNDA_VIA_REABILITACAO_FAILURE,
		payload,
	};
}

export function consultaSegundaViaReabilitacaoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_SEGUNDA_VIA_REABILITACAO_CLEAR,
		payload: null,
	};
}
