import { IAtualizarDareState } from './atualizarDare/types';
import { IConsultarDareState } from './consultarDare/types';
import { IConsultarDareRetaguardaState } from './consultarDareRetaguarda/types';
import { IDependentesDareState } from './dependentesDare/types';
import { ImprimirDareState } from './imprimirDare/types';
import { IConsultarStatusDareState } from './statusDare/types';
import { IConsultarStatusMainframeDareState } from './statusDareMainframe/types';

export enum Types {
	DARE_CLEAR = '@dare/DARE_CLEAR',
}

export interface DareState {
	atualizarDare: IAtualizarDareState;
	consultarDare: IConsultarDareState;
	consultarDareRetaguarda: IConsultarDareRetaguardaState;
	dependentesDare: IDependentesDareState;
	imprimirDare: ImprimirDareState;
	statusDare: IConsultarStatusDareState;
	statusDareMainframe: IConsultarStatusMainframeDareState;
}
