import { CancelarAutorizacaoPrevia } from 'pages/DetranCrv/SolicitarDocumentos/CancelarAutorizacaoPrevia/form';
import { Types } from './types';

export function cancelarAutorizacaoPreviaRequest(
	payload: CancelarAutorizacaoPrevia,
) {
	return {
		type: Types.CANCELAR_AUTORIZACAO_PREVIA_REQUEST,
		payload,
	};
}
export function cancelarAutorizacaoPreviaSuccess(payload: any) {
	return {
		type: Types.CANCELAR_AUTORIZACAO_PREVIA_SUCCESS,
		payload,
	};
}
export function cancelarAutorizacaoPreviaFailure(payload: []): any {
	return {
		type: Types.CANCELAR_AUTORIZACAO_PREVIA_FAILURE,
		payload,
	};
}
export function cancelarAutorizacaoPreviaClear(): any {
	return {
		type: Types.CANCELAR_AUTORIZACAO_PREVIA_CLEAR,
	};
}
