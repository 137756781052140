import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConsultarDareRetaguardaState } from './types';

export const INITIAL_STATE: IConsultarDareRetaguardaState = {
	status: 0,
	data: null,
	taxasRes: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IConsultarDareRetaguardaState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_DARE_RETAGUARDA_REQUEST: {
				break;
			}
			case Types.CONSULTAR_DARE_RETAGUARDA_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.taxasRes = action.payload.taxasRes;
				break;
			}
			case Types.CONSULTAR_DARE_RETAGUARDA_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.taxasRes = INITIAL_STATE.taxasRes;
				break;
			}
			case Types.CONSULTAR_DARE_RETAGUARDA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.taxasRes = INITIAL_STATE.taxasRes;
				break;
			}

			default:
		}
		return draft;
	});
}
