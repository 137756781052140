import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import { sexosSuccess, sexosFailure } from './actions';

function* sexosRequest() {
	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`dominios-crm/${CHANNEL}/sexos`,
		{},
	);

	if (response.status === 200) {
		yield put(sexosSuccess(response));
	} else {
		yield put(sexosFailure(response));
	}
}

export default all([takeLatest(Types.SEXOS_REQUEST, sexosRequest)]);
