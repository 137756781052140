import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApiECpf } from 'services/externalApis';
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';
import { getECpfTokenSuccess, getECpfTokenFailure } from './actions';

function* getECpfTokenRequest(request: ReducerAction) {
	const { payload }: { payload: string } = request;

	const response: ApiResponse = yield call(
		getApiECpf,
		'https://identific.certificadodigital.com.br/api/v2/auth/generate_token',
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(getECpfTokenSuccess(response));
	} else if (response.error) {
		yield put(getECpfTokenFailure(response.message));
	}
}

export default all([
	takeLatest(Types.GET_E_CPF_TOKEN_REQUEST, getECpfTokenRequest),
]);
