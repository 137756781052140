export enum Types {
	ENVIA_INTERACOES_PROCESSO_REQUEST = '@procon/ENVIA_INTERACOES_PROCESSO_REQUEST',
	ENVIA_INTERACOES_PROCESSO_SUCCESS = '@procon/ENVIA_INTERACOES_PROCESSO_SUCCESS',
	ENVIA_INTERACOES_PROCESSO_FAILURE = '@procon/ENVIA_INTERACOES_PROCESSO_FAILURE',
	ENVIA_INTERACOES_PROCESSO_CLEAR = '@procon/ENVIA_INTERACOES_PROCESSO_CLEAR',
}

export interface EnviaInteracoesProcesso {
	status: null | number;
	data: null | EnviaInteracoesProcessoData;
}

interface EnviaInteracoesProcessoData {}

export interface EnviaInteracoesProcessoRequest {
	processoAdministrativoId: string;
	protocolo: string;
	textoDaInteracao: string;
	anexos: Anexos[];
}

interface Anexos {
	nome: string;
	base64: string;
}
