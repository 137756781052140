import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarMunicipioRequest } from './types';

export function cadastrarMunicipioRequest(
	data: CadastrarMunicipioRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_MUNICIPIO_REQUEST,
		payload: data,
	};
}
export function cadastrarMunicipioSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_MUNICIPIO_SUCCESS,
		payload,
	};
}
export function cadastrarMunicipioFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_MUNICIPIO_FAILURE,
		payload: null,
	};
}
export function cadastrarMunicipioClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_MUNICIPIO_CLEAR,
		payload: null,
	};
}
