import { ReducerAction } from 'store/modules/types';

import { Types, LogonRequest } from './types';

export function logonRequest(data: LogonRequest): ReducerAction {
	return {
		type: Types.LOGON_REQUEST,
		payload: data,
	};
}
export function logonSuccess(payload: object): ReducerAction {
	return {
		type: Types.LOGON_SUCCESS,
		payload,
	};
}
export function logonFailure(payload: object): ReducerAction {
	return {
		type: Types.LOGON_FAILURE,
		payload,
	};
}

export function logonClear(payload: object): ReducerAction {
	return {
		type: Types.LOGON_CLEAR,
		payload,
	};
}
