import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_VIVA_LEITE } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	Types,
	IRequestUsuario,
	IRequestBuscaDadosCadastrais,
	IRequestAtualizaDadosCadastrais,
	IRequestAlertas,
} from './types';

// ACTIONS
import {
	consultaUsuarioFailure,
	consultaUsuarioSuccess,
	consultaAtualizacaoCadastralFailure,
	consultaAtualizacaoCadastralSuccess,
	atualizaAtualizacaoCadastralFailure,
	atualizaAtualizacaoCadastralSuccess,
	consultaAlertasFailure,
	consultaAlertasSuccess,
	consultaServicosEntidadeFailure,
	consultaServicosEntidadeSuccess,
} from './actions';

const evento = {
	id_atendimento: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
	id_cidadao: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
	cpf: '94177708534',
	identificacao: '94177708534',
	ip: '192.168.1.1',
	canal: {
		id: 1,
		desc: 'Presencial',
		localidade: {
			id: 900,
			desc: 'SE',
		},
	},
};

function* consultaUsuario(request: ReducerAction) {
	const { payload }: { payload: IRequestUsuario } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/identificacao-usuario`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaUsuarioSuccess({ response, cpf: payload.cpf }));
	} else if (response.error) {
		yield put(consultaUsuarioFailure());
	}
}

function* consultaAtualizacaoCadastral(request: ReducerAction) {
	const { payload }: { payload: IRequestBuscaDadosCadastrais } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/atualizacao-cadastral`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaAtualizacaoCadastralSuccess(response));
	} else if (response.error) {
		yield put(consultaAtualizacaoCadastralFailure());
	}
}

function* atualizaAtualizacaoCadastral(request: ReducerAction) {
	const { payload }: { payload: IRequestAtualizaDadosCadastrais } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/atualizacao-cadastral`,
		{
			...payload,
			evento,
		},
	);

	if (response.status === 200) {
		yield put(
			atualizaAtualizacaoCadastralSuccess({
				response,
				dados_atualizar: payload.dados_atualizar,
				redirectUrl: payload.redirectUrlUsuario,
			}),
		);
	} else if (response.error) {
		yield put(atualizaAtualizacaoCadastralFailure());
	}
}

function* consultaAlertas(request: ReducerAction) {
	const { payload }: { payload: IRequestAlertas } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/alertas`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaAlertasSuccess(response));
	} else if (response.error) {
		yield put(consultaAlertasFailure());
	}
}

function* consultaServicosEntidade(request: ReducerAction) {
	const { payload }: { payload: IRequestAlertas } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/servicos-entidade`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaServicosEntidadeSuccess(response));
	} else if (response.error) {
		yield put(consultaServicosEntidadeFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTA_USUARIO, consultaUsuario),
	takeLatest(
		Types.CONSULTA_ATUALIZACAO_CADASTRAL,
		consultaAtualizacaoCadastral,
	),
	takeLatest(
		Types.ATUALIZA_ATUALIZACAO_CADASTRAL,
		atualizaAtualizacaoCadastral,
	),
	takeLatest(Types.CONSULTA_ALERTAS, consultaAlertas),
	takeLatest(Types.CONSULTA_SERVICOS_ENTIDADES, consultaServicosEntidade),
]);
