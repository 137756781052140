import { ConsultaCipDataPfPj } from 'store/modules/api/procon/atendimentos/types';
import { ConsultaAtendimentosDeConsultaData } from 'store/modules/api/procon/atendimentosDeConsulta/consultaAtendimentosDeConsulta/types';
import { ConsultaProcessoAdministrativo } from 'store/modules/api/procon/processosAdministrativos/processosAdministrativos/types';
import {
	verifyIsAdministrativeProcess,
	verifyRazaoStatusIsAwaitingConsumerResponse,
	verifyRazaoStatusIsAwaitingProviderResponse,
	verifySituationIsTnfAwaitingConsumerResponse,
	verifySituationIsTnfAwaitingProviderResponse,
} from './typeProcess';

export const verifyButtonResolvidoIsEnabledByProcess = (
	serviceResponse: ConsultaProcessoAdministrativo,
	formData: ConsultaCipDataPfPj | ConsultaAtendimentosDeConsultaData | null,
): boolean => {
	const disabled = false;
	const enabled = true;

	if (formData?.razaoDoStatus) {
		if (verifyRazaoStatusIsAwaitingConsumerResponse(formData.razaoDoStatus)) {
			return enabled;
		}

		if (verifyRazaoStatusIsAwaitingProviderResponse(formData.razaoDoStatus)) {
			return enabled;
		}

		if (
			serviceResponse.status === 200 &&
			serviceResponse.data &&
			serviceResponse.data.situacao
		) {
			if (
				verifyIsAdministrativeProcess(formData.razaoDoStatus) &&
				verifySituationIsTnfAwaitingConsumerResponse(
					serviceResponse.data.situacao,
				)
			) {
				return enabled;
			}

			if (
				verifyIsAdministrativeProcess(formData.razaoDoStatus) &&
				verifySituationIsTnfAwaitingProviderResponse(
					serviceResponse.data.situacao,
				)
			) {
				return enabled;
			}

			return disabled;
		}

		return disabled;
	}

	return disabled;
};

export const verifyButtonNaoResolvidoIsEnabledByProcess = (
	serviceResponse: ConsultaProcessoAdministrativo,
	formData: ConsultaCipDataPfPj | ConsultaAtendimentosDeConsultaData | null,
): boolean => {
	const disabled = false;
	const enabled = true;

	if (formData?.razaoDoStatus) {
		if (verifyRazaoStatusIsAwaitingConsumerResponse(formData.razaoDoStatus)) {
			return enabled;
		}

		if (
			serviceResponse.status === 200 &&
			serviceResponse.data &&
			serviceResponse.data.situacao
		) {
			if (
				verifyIsAdministrativeProcess(formData.razaoDoStatus) &&
				verifySituationIsTnfAwaitingConsumerResponse(
					serviceResponse.data.situacao,
				)
			) {
				return enabled;
			}

			return disabled;
		}

		return disabled;
	}

	return disabled;
};
