// ROUTES
import { ROUTE_IIRGD } from 'routes/paths';

const RG_FIC_DIGITAL = `${ROUTE_IIRGD}/rg-fic-digital`;

export const ROUTE_IIRGD_DARE_PESQUISA = `${RG_FIC_DIGITAL}/dare/pesquisa`;
export const ROUTE_IIRGD_DARE_KIT_FAMILIA = `${RG_FIC_DIGITAL}/dare/kit-familia`;
export const ROUTE_IIRGD_DARE_SUCESSO = `${RG_FIC_DIGITAL}/dare/sucesso`;

export const ROUTE_IIRGD_PESQUISAR_RG = `${ROUTE_IIRGD}/rg-fic-digital/pesquisar`;
export const ROUTE_IIRGD_PESQUISAR_RG_MAE = `${RG_FIC_DIGITAL}/pesquisar/mae`;
export const ROUTE_IIRGD_RESULTADO_PESQUISA = `${RG_FIC_DIGITAL}/resultado-pesquisa`;
export const ROUTE_IIRGD_RESULTADO_RESTRICOES = `${RG_FIC_DIGITAL}/resultados-restricoes`;

export const ROUTE_IIRGD_CADASTRO = `${RG_FIC_DIGITAL}/cadastro`;
export const ROUTE_IIRGD_EDICAO = `${RG_FIC_DIGITAL}/edicao`;
export const ROUTE_IIRGD_INCLUSAO_OBSERVACOES = `${RG_FIC_DIGITAL}/inclusao-observacoes`;
export const ROUTE_IIRGD_CONFERENCIA_DADOS = `${RG_FIC_DIGITAL}/conferencia-dados`;
export const ROUTE_IIRGD_LEITURA_DIGITAIS = `${RG_FIC_DIGITAL}/leitura-digitais`;
export const ROUTE_IIRGD_STATUS = `${RG_FIC_DIGITAL}/status`;
