import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestHorariosPsicologoPorData } from './types';

// ACTIONS
import {
	horariosPsicologoPorDataSuccess,
	horariosPsicologoPorDataFailure,
} from './actions';

function* horariosPsicologoPorData(request: ReducerAction) {
	const { payload }: { payload: IRequestHorariosPsicologoPorData } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`divisao-equitativa/${CHANNEL}/horarios-psicologo-por-data`,
		payload,
	);

	if (response.status === 200) {
		yield put(horariosPsicologoPorDataSuccess(response));
	} else if (response.error) {
		yield put(horariosPsicologoPorDataFailure(response.error));
	}
}

export default all([
	takeLatest(
		Types.HORARIOS_PSICOLOGO_POR_DATA_REQUEST,
		horariosPsicologoPorData,
	),
]);
