import { takeLatest, call, put, all } from 'redux-saga/effects';

// STORE
import { ReducerAction } from 'store/modules/types';
import { ILogin } from 'store/modules/api/sgu/loginUnico/types';

import { IFormCadastroRg } from 'pages/iirgd/RgFicDigital/CadastroRg/form';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	cadastroRgPrincipal,
	cadastroRgConfigEdicao,
	cadastrarRgSuccess,
	cadastrarRgFailure,
	atualizarRgSuccess,
	cadastroRgSegundaVia,
	atualizarLote,
} from './actions';

// UTILS
import { formatarAtualizarCITA, formatarCadastrarRG } from './functions';
import { IConsultaPesquisaCidadaoSelecionado } from '../consultaPesquisaCidadao/types';

function* cadastrarRgRequest(request: ReducerAction) {
	const { payload } = request;
	const {
		formValues,
		login,
	}: {
		formValues: IFormCadastroRg;
		login: ILogin;
	} = payload;

	const requestValues = formatarCadastrarRG(formValues, login);

	delete requestValues.inscricaoCpf;
	delete requestValues.cpfAtendente;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		'cadastrarRG',
		requestValues,
	);

	if (response.status === 200 && response.data.numRG) {
		formValues.numeroRg = response.data.numRG;
		formValues.sedex = response.data.sedex;
		const body = { ...formValues };

		yield put(cadastroRgPrincipal(null, body, null));
		yield put(atualizarLote(response?.data?.lote));
		yield put(cadastroRgConfigEdicao());
		yield put(cadastrarRgSuccess(true));
	} else if (response?.numRg) {
		formValues.numeroRg = response.numRg;
		yield put(cadastroRgPrincipal(null, formValues, null));
	} else {
		yield put(cadastrarRgFailure(response.data.numRg));
	}
}

function* atualizarRgRequest(request: ReducerAction) {
	const { payload } = request;
	const {
		original,
		formValues,
		login,
		isPrimeiraVia,
		consultaPesquisaCidadaoSelecionado,
	}: {
		original: IFormCadastroRg;
		formValues: IFormCadastroRg;
		login: ILogin;
		isPrimeiraVia: boolean;
		consultaPesquisaCidadaoSelecionado: IConsultaPesquisaCidadaoSelecionado | null;
	} = payload;

	const requestValues = formatarAtualizarCITA(formValues, login);

	delete requestValues.inscricaoCpf;
	delete requestValues.cpfAtendente;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		'atualizarDadosCITA',
		requestValues,
	);

	if (response.status === 200) {
		if (
			isPrimeiraVia &&
			response.data.lote !== '' &&
			response.data.lote.indexOf('2') !== -1
		) {
			yield put(cadastroRgSegundaVia());
		}
		yield put(atualizarLote(response.data.lote));

		yield put(
			cadastroRgPrincipal(
				original,
				{
					...formValues,
					sedex: response?.data?.sedex,
					ufResidencia: requestValues.ufResidencia ?? '',
				},
				consultaPesquisaCidadaoSelecionado,
			),
		);
		yield put(atualizarRgSuccess(true));
	}
}

export default all([
	takeLatest(Types.CADASTRAR_RG_REQUEST, cadastrarRgRequest),
	takeLatest(Types.ATUALIZAR_RG_REQUEST, atualizarRgRequest),
]);
