import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';
import { Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';

// REDUX
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ConsultaTransferenciaEnderecoRequest } from 'store/modules/api/detranCnh/alteracaoEndereco/consultaTransferenciaEndereco/types';
import {
	consultaTransferenciaEnderecoRequest,
	consultaTransferenciaEnderecoClear,
} from 'store/modules/api/detranCnh/alteracaoEndereco/consultaTransferenciaEndereco/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';

import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

import EnumCNH from 'store/modules/api/detranCnh/enum';

import Select from 'components/Common/Form/Select';
import CPF from 'components/Common/Form/Fields/CPF';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';

// PATHS
import { ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_STATUS_CONDUTOR } from 'pages/DetranCnh/routes/paths';
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// FORM
import { numeros } from 'utils/stringFunctions';
import { treatValues, initialValues, schema } from './form';

const PesquisaAlterarEndereco: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [tipoDocumento, setTipoDocumento] = useState('REGISTRO');

	const { consultaTransferenciaEndereco } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.alteracaoEndereco,
	);

	const handleClear = useCallback(() => {
		dispatch(consultaTransferenciaEnderecoClear());
	}, [dispatch]);

	const handleSubmit = useCallback(
		(values: ConsultaTransferenciaEnderecoRequest) => {
			const newValues = treatValues(values);
			dispatch(consultaTransferenciaEnderecoRequest(newValues));
		},
		[dispatch],
	);

	useEffect(() => {
		handleClear();
	}, [handleClear]);

	useEffect(() => {
		if (consultaTransferenciaEndereco.status === 200) {
			history.push(ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_STATUS_CONDUTOR);
		}
	}, [consultaTransferenciaEndereco.status, history]);

	return (
		<>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							<Row gutter={[0, 10]}>
								<Col span={11}>
									<CPF
										title="CPF"
										subtitle="(apenas números)"
										numero="cpf"
										titleSize={145}
										formik={formik}
										error={hasError(formik.errors, 'cpf')}
										removeContainer
									/>
								</Col>
							</Row>
							<Row gutter={[0, 10]}>
								<Col offset={1} span={3}>
									<Field
										as={Select}
										subtitle=" "
										name="tipoDocumento"
										options={EnumCNH.tipoDocumentoEnumPGURegistro}
										onChange={(v: string) => {
											setTipoDocumento(v);
											formik.setFieldValue(`numRegistro`, '');
											formik.setFieldValue(`numPgu`, '');
										}}
										defaultFirstOption={false}
										defaultValue="REGISTRO"
									/>
								</Col>
								{tipoDocumento === 'REGISTRO' && (
									<Col span={6}>
										<Field
											as={Input}
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												formik.setFieldValue(
													'numRegistro',
													numeros(e.target.value) || '',
												)
											}
											name="numRegistro"
											subtitle="(apenas números)"
											error={hasError(formik.errors, 'numRegistro')}
											maxLength="11"
										/>
									</Col>
								)}
								{tipoDocumento === 'PGU' && (
									<Col span={6}>
										<Field
											as={Input}
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												formik.setFieldValue(
													'numPgu',
													numeros(e.target.value) || '',
												)
											}
											name="numPgu"
											subtitle="(apenas números)"
											error={hasError(formik.errors, 'numPgu')}
											maxLength="11"
										/>
									</Col>
								)}
							</Row>
							{/* <Row gutter={[0, 10]}>
								<Col offset={1} span={9}>
									<Field
										as={InputMask}
										name="numPgu"
										title="PGU"
										mask="999999999"
										subtitle="(Apenas números)"
										titleSize="sm"
										error={hasError(formik.errors, 'numPgu')}
									/>
								</Col>
							</Row> */}
							<Row gutter={[0, 20]}>
								<Col span={24} />
							</Row>

							<Row justify="center">
								<Col>
									<ButtonImage
										src="limpar"
										onClick={() => {
											dispatch(clearNotifications());
											formik.handleReset();
											handleClear && handleClear();
										}}
									/>
								</Col>

								<Col offset={1}>
									<ButtonImage type="submit" src="pesquisar" />
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</Section>
			<ButtonVoltar route={ROUTE_DETRAN_CNH} />
		</>
	);
};
export default PesquisaAlterarEndereco;
