import * as Yup from 'yup';

// UTILS
import {
	sameThreeCharactersInSequenceNotAllowedRegex,
	userDataRegex,
} from 'utils/constants/regex';
import {
	formatDate,
	limparMascara,
	onlyLetters,
	removeAcentoCaracteresEspeciais,
	unformatDate,
	verifyStringIsAnInvalidPhrase,
} from 'utils/genericFunctions';

// FORM
import { IFormStatusCondutor } from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor/form';

// TYPES
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { ConsultaPermissionarioPontuadoData } from 'store/modules/api/detranCnh/eportal/consultaPermissionarioPontuado/types';
import { GerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/sockets/saveGerarRenach/types';
import {
	socialNameAndFiliationsPhrasesNotAllowed,
	usoFormularioEnumTypes,
} from 'utils/constants/types';
import { EventoContabilizacao } from 'store/modules/types';

export const radioSimNao = [
	{
		label: 'Sim',
		value: 'S',
	},
	{
		label: 'Não',
		value: 'N',
	},
];

export function treatInitialValues(
	values: ConsultaPermissionarioPontuadoData | null,
	dataPrimeiraHabilitacaoConsultarSegundaVia: string | null,
	user: IUser | null,
): {
	initalValuesHabilitacao: IFormStatusCondutor;
	initialValuesPreCadastro: GerarRenachForm;
} {
	const formattedDataHabilitacao: IFormStatusCondutor = {
		mensagensAlerta: values?.mensagensAlerta || '',
		cpf: values?.cpf || '',
		nome: values?.nome || '',
		nomePai: values?.nomePai || '',
		nomeMae: values?.nomeMae || '',
		numeroRg: values?.numeroRg || '',
		dataNascimento:
			(values?.dataNascimento && formatDate(values.dataNascimento)) || '',
		sexo: values?.sexo || '',
		nacionalidade: values?.nacionalidade || '',
		usoForm: values?.usoForm || '',
		numeroRegistro: values?.numeroRegistro || '',
		numeroPgu: values?.numeroPgu || '',
		renach: values?.renach || '',
		espelhoCnh: values?.espelhoCnh || '',
		exameMedicoDataExameMedico:
			(values?.dadosExameMedico?.dataExameMedico &&
				formatDate(values.dadosExameMedico.dataExameMedico)) ||
			'',
		exameMedicoCrm: values?.dadosExameMedico?.crm || '',
		dataExameTeorico:
			(values?.dataExameTeorico && formatDate(values.dataExameTeorico)) || '',
		examePsicotecnicoCrp: values?.dadosExamePsicotecnico?.crp || '',
		examePsicotecnicoLaudo: values?.dadosExamePsicotecnico?.laudo1 || '',
		examePsicotecnicoDataExamePsicotecnico:
			(values?.dadosExamePsicotecnico?.dataExamePsicotecnico &&
				formatDate(values.dadosExamePsicotecnico.dataExamePsicotecnico)) ||
			'',
		categoriaPretendida:
			values?.categoriaPretendida?.trim() || values?.situacaoAtual || '',
		local: values?.local || '',
		cfcA: values?.cfcA || '',
		exameMedicoCategoriaPermitida:
			values?.dadosExameMedico?.categoriaPermitida || '',
		exameMedicoCred1: values?.dadosExameMedico?.cred2
			? values.dadosExameMedico.cred2
			: values?.dadosExameMedico.cred1 || '',
		examePsicotecnicoResultado2:
			values?.dadosExamePsicotecnico?.resultado2 || '',
		dataPrimeiraHabilitacao:
			(dataPrimeiraHabilitacaoConsultarSegundaVia &&
				formatDate(dataPrimeiraHabilitacaoConsultarSegundaVia)) ||
			(values?.dataPrimeiraHabilitacao &&
				formatDate(values.dataPrimeiraHabilitacao)) ||
			'',
		dataValidadeCnh:
			(values?.dataValidadeCnh && formatDate(values.dataValidadeCnh)) || '',
		situacaoAtual: values?.situacaoAtual || '',
		cfcB: values?.cfcB || '',
		exameMedicoRegiao: values?.dadosExameMedico?.regiao || '',
		dataExamePratico2Rodas:
			(values?.dataExamePratico2Rodas &&
				formatDate(values.dataExamePratico2Rodas)) ||
			'',
		dataExamePratico4Rodas:
			(values?.dataExamePratico4Rodas &&
				formatDate(values.dataExamePratico4Rodas)) ||
			'',
		exameMedicoCred2: values?.dadosExamePsicotecnico?.cred2
			? values.dadosExamePsicotecnico.cred2
			: '',
		examePsicotecnicoRegiao: values?.dadosExamePsicotecnico?.regiao || '',
		dataUltimaEmissaoCnh:
			(values?.dataUltimaEmissaoCnh &&
				formatDate(values.dataUltimaEmissaoCnh)) ||
			'',
		naturalidade: '',
		enderecoCep: values?.endereco?.cep?.trim() || values?.cepPcon?.trim() || '',
		enderecoLogradouro: values?.endereco?.logradouro?.trim() || '',
		enderecoNumero: values?.endereco?.numero || '',
		enderecoComplemento: values?.endereco?.complemento?.trim() || '',
		enderecoBairro: values?.endereco?.bairro?.trim() || '',
		enderecoDescricaoMunicipio:
			values?.endereco?.descricaoMunicipio?.trim() || '',
		localEmissao: values?.localEmissao || '',
		orgaoEmissor: values?.orgaoEmissor || '',
		rne1: values?.rne1 || '',
		rne2: values?.rne2 || '',
		rne3: values?.rne3 || '',
		status: '',
		statusMensagem: '',
		mensagemBloqueio: '',
		pais: '',
		mensagem: '',
		exameMedicoRestricaoMedica: '',
		exameMedicoAptidaoMedica: '',
		examePsicotecnicoResultado1: '',
		examePsicotecnicoCred1: '',
		examePsicotecnicoCred2: '',
		examePsicotecnicoAptidaoPsicotecnica: '',
		codigoExameTeorico: '',
		codigoEntidade: '',
		descricaoEntidade: '',
		listaOcorrencias: [],
		listaCursos: [],
		cepPcon: '',
		cepPcad: '',
		espelhoPid: '',
		tipoDocumento: '',
		dataValidade: '',
		identificacaoHabilitacao: '',
		enderecoCodigoMunicipio: '',
		enderecoMunicipio: '',
		codigoExamePratico2Rodas: '',
		codigoExamePratico4Rodas: '',
		restricaoMedica: values?.dadosExameMedico?.restricaoMedica || '',
	};

	const formattedDataPreCadastro: GerarRenachForm = {
		usoFormularioEnum: usoFormularioEnumTypes.PERMISSIONARIO_PONTUADO,
		cpf: values?.cpf || '',
		nome:
			(values?.nome &&
				removeAcentoCaracteresEspeciais(values.nome.toUpperCase())?.trim()) ||
			'',
		nomeMae:
			(values?.nomeMae &&
				removeAcentoCaracteresEspeciais(
					values.nomeMae.toUpperCase(),
				)?.trim()) ||
			'',
		nomePai:
			(values?.nomePai &&
				removeAcentoCaracteresEspeciais(
					values?.nomePai?.toUpperCase(),
				)?.trim()) ||
			'',
		sexo: values?.sexo || '',
		dataNascimento:
			(values?.dataNascimento && formatDate(values.dataNascimento)) || '',
		tipoDocumentoEnum: values?.tipoDocumento || '',
		numDocumento: values?.numeroRg?.trim() || '',
		orgaoExpDocumento: values?.orgaoEmissor?.trim() || '',
		orgaoUfExpDocumento: values?.localEmissao?.trim() || '',
		nacionalidade: values?.nacionalidade?.trim() || '',
		naturalidade: '',
		codigoNaturalidade: '',
		logradouro:
			(values?.endereco?.logradouro &&
				removeAcentoCaracteresEspeciais(values.endereco.logradouro.trim())) ||
			'',
		logradouroNumero: values?.endereco?.numero?.trim() || '',
		logradouroComplemento:
			(values?.endereco?.complemento &&
				removeAcentoCaracteresEspeciais(
					values.endereco.complemento.trim() || '',
				)) ||
			'',
		logradouroBairro:
			(values?.endereco?.bairro &&
				removeAcentoCaracteresEspeciais(values.endereco?.bairro?.trim())) ||
			'',
		logradouroCodMunicipio: values?.endereco?.codigoMunicipio || '',
		logradouroDescMunicipio: values?.endereco?.descricaoMunicipio || '',
		logradouroCep: values?.endereco?.cep || '',
		codCiretranCondutor: '',
		statusReusoEnum: 'SEM_REUSO',
		flagAtividadeRemunerada: 'N',
		flagDeficienteFisico: 'N',
		flagMilitar: 'N',
		flagExamePosto: 'N',
		flagExameToxicologico: '',
		categoriaAtual: values?.situacaoAtual?.trim() || '',
		categoriaPretendida:
			values?.categoriaPretendida?.trim() ||
			values?.situacaoAtual?.trim() ||
			'',
		registro: values?.numeroRegistro?.trim() || '',
		numeroPgu: values?.numeroPgu || '',
		codCiretranHabilitacao: '',
		cpfUsuario: user?.cpf || '',
		codLocalidade: user?.posto || '',
		uf: '',
		rneNumero: values?.rne1 || '',
		rneOrgao: values?.rne2 || '',
		rneUf:
			values?.nacionalidade.trim() === '1'
				? values?.rne3 || ''
				: values?.rne3 || 'EX',
		origemNomeSocial: '0',
		dataPrimeiraHabilitacao:
			(dataPrimeiraHabilitacaoConsultarSegundaVia &&
				formatDate(dataPrimeiraHabilitacaoConsultarSegundaVia)) ||
			(values?.dataPrimeiraHabilitacao &&
				formatDate(values.dataPrimeiraHabilitacao)) ||
			'',
		email: '',
		celularDDD: '',
		celular: '',
		flagSolicitaCNHDigital: '',
		codigoVerificador: '',
		rneCodPaisCnhEstrangeira: '',
		rneDataValidadeCnhEstrangeira: '',
		rneDataPrimeiraHabilitacaoCnhEstrangeira: '',
		rneIdentificacaoCnhEstrangeira: '',
		codEntidadeMilitar: '',
		numeroIdentMilitar: '',
		flagProcurador: '',
		autorizaEmail: 'N',
		autorizaSMS: 'N',
	};

	return {
		initalValuesHabilitacao: formattedDataHabilitacao,
		initialValuesPreCadastro: formattedDataPreCadastro,
	};
}

// TREAT TO SEND RENACH
export function treatValues(
	evento: EventoContabilizacao,
	values: GerarRenachForm,
	user: IUser,
): GerarRenachIntegradoRequest {
	const formattedData = {
		candidato: {
			apresentaDocumentos: '',
			autorizaEmail: values.autorizaEmail || '',
			autorizaSMS: values.autorizaSMS || '',
			bairro: removeAcentoCaracteresEspeciais(values.logradouroBairro),
			categoriaAtual: values.categoriaPretendida?.trim() || '',
			categoriaPretendida: values.categoriaPretendida?.trim() || '',
			codigoUnidadeAtendimento: user.posto || '',
			celular: limparMascara(values.celular).substring(2, 11),
			cep: values.logradouroCep || '',
			cnhDigital: values.flagSolicitaCNHDigital === 'S' ? 'S' : 'N',
			codigoCiretranPostoExameMedico:
				values.flagExamePosto === 'S' ? user.posto : '',
			codigoEntidade: values.codEntidadeMilitar || '',
			codigoVerificador: values.codigoVerificador || '',
			coletaBiometrica: '',
			complemento: removeAcentoCaracteresEspeciais(
				values.logradouroComplemento,
			),
			cpf: values.cpf || '',
			dataNascimento:
				(values.dataNascimento && unformatDate(values.dataNascimento)) || '',
			dataPrimeiraHabilitacao:
				(values.dataPrimeiraHabilitacao &&
					unformatDate(values.dataPrimeiraHabilitacao)) ||
				'',
			dataValidadeCnhE: values.rneDataValidadeCnhEstrangeira || '',
			dddCelular: limparMascara(values.celular).substring(0, 2),
			dddTelefone: limparMascara(values.telefone).substring(0, 2),
			email: values.email || '',
			logradouro: removeAcentoCaracteresEspeciais(values.logradouro),
			nacionalidade: values.nacionalidade,
			naturalidade: values.codigoNaturalidade
				? values.codigoNaturalidade?.toString()
				: '',
			necessidadeVeiculoAdaptado:
				values.flagDeficienteFisico === 'S' ? 'S' : 'N',
			nome: removeAcentoCaracteresEspeciais(values.nome.toUpperCase()),
			nomeMae: removeAcentoCaracteresEspeciais(values.nomeMae.toUpperCase()),
			nomePai: removeAcentoCaracteresEspeciais(values.nomePai.toUpperCase()),
			nomeSocial:
				values.flagNomeSocial === 'S'
					? removeAcentoCaracteresEspeciais(
							values.nomeSocial ? values.nomeSocial.toUpperCase() : '',
					  )
					: '',
			nomeSocialValidadoBiometria: '',
			origemNomeSocial:
				(values.flagNomeSocial === 'S' && values.origemNomeSocial) || '',
			paisAfetivos1:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo1 ? values.paisAfetivo1.toUpperCase() : '',
					  )
					: '',
			paisAfetivos1ValidadoBiometria: '',
			paisAfetivos2:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo2 ? values.paisAfetivo2.toUpperCase() : '',
					  )
					: '',
			paisAfetivos2ValidadoBiometria: '',
			numCNHE: values.rneIdentificacaoCnhEstrangeira || '',
			numCarteiraMilitar: values.numeroIdentMilitar || '',
			numDocumento: values.numDocumento || '',
			numPGU: values.numeroPgu || '',
			numRNE: values.rneNumero || '',
			numRegistro: values.registro ? values.registro?.trim() : '',
			numero:
				values.logradouroNumero !== 'S/N'
					? String(values.logradouroNumero)
					: '',
			orgaoDocumento: values.orgaoExpDocumento
				? onlyLetters(values.orgaoExpDocumento.toLocaleUpperCase())
				: '',
			orgaoRNE: values.rneOrgao || '',
			paisCNHE: values.rneCodPaisCnhEstrangeira || '',
			pretendeAtividadeRemunerada:
				values.flagAtividadeRemunerada === 'S' ? 'S' : 'N',
			sexo: values.sexo,
			telefone: limparMascara(values.telefone).substring(2, 10),
			testeAlfabetizacao: '',
			tipoDocumento: '1',
			tipoProcesso: '9',
			uf: values.uf || '',
			ufDocumento: values.orgaoUfExpDocumento || '',
			ufRNE: values.rneUf || '',
		},
		dadosExameMedico: {
			codigoCiretranExameMedico: '',
			dataExame: '',
			horarioExame: '',
			idMedico: '',
			idMicroRegiaoMedico: '',
		},
		dadosExamePsicologo: {
			codigoCiretranExamePsicologo: '',
			dataExamePsicologico: '',
			horarioExamePsicologico: '',
			idMicroRegiaoPsicologo: '',
			idPsicologo: '',
		},
		gerarRenachBase: {
			evento: {
				id_atendimento: evento.id_atendimento,
				id_cidadao: evento.id_cidadao,
				ip: evento.ip,
				identificacao: evento.cpf && parseInt(limparMascara(values.cpf), 10),
				cpf: evento.cpf || '',
				canal: {
					id: 1,
					desc: 'Presencial',
					localidade: {
						desc: evento.canal?.localidade.desc || '',
						id: evento.canal?.localidade.id || 0,
					},
				},
			},
			usoFormularioEnum: usoFormularioEnumTypes.PERMISSIONARIO_PONTUADO,
			cpf: values.cpf,
			nome: removeAcentoCaracteresEspeciais(values.nome.toUpperCase()),
			nomeMae: removeAcentoCaracteresEspeciais(values.nomeMae.toUpperCase()),
			nomePai: removeAcentoCaracteresEspeciais(values.nomePai.toUpperCase()),
			sexo: values.sexo,
			dataNascimento: unformatDate(values.dataNascimento),
			tipoDocumentoEnum: 'CARTEIRA_IDENTIDADE',
			numDocumento: values.numDocumento || '',
			orgaoExpDocumento: values.orgaoExpDocumento
				? onlyLetters(values.orgaoExpDocumento.toLocaleUpperCase())
				: '',
			orgaoUfExpDocumento: values.orgaoUfExpDocumento || '',

			nacionalidade: values.nacionalidade,
			codigoNaturalidade: values.codigoNaturalidade.toString(),
			email: values.email || '',
			celularDDD: limparMascara(values.celular).substring(0, 2),
			celular: limparMascara(values.celular).substring(2, 11),
			logradouro: removeAcentoCaracteresEspeciais(values.logradouro),
			logradouroNumero:
				values.logradouroNumero !== 'S/N'
					? String(values.logradouroNumero)
					: null,
			logradouroComplemento:
				(values.logradouroComplemento != null &&
					removeAcentoCaracteresEspeciais(values.logradouroComplemento)) ||
				'',
			logradouroBairro:
				(values.logradouroBairro != null &&
					removeAcentoCaracteresEspeciais(values.logradouroBairro)) ||
				'',
			logradouroCodMunicipio: values.logradouroCodMunicipio || '',
			logradouroCep: values.logradouroCep || '',
			codCiretranCondutor:
				values.codCiretranCondutor != null &&
				values.codCiretranCondutor.toString() === '018'
					? '18'
					: (values.codCiretranCondutor != null &&
							values.codCiretranCondutor.toString()) ||
					  '',
			statusReusoEnum: 'SEM_REUSO',
			flagAtividadeRemunerada: values.flagAtividadeRemunerada === 'S',
			flagDeficienteFisico: values.flagDeficienteFisico === 'S',
			flagSolicitaCNHDigital: values.flagSolicitaCNHDigital === 'S',
			flagExamePosto: values.flagExamePosto === 'S',
			flagExameToxicologico: values.flagExameToxicologico === 'S',
			flagMilitar: values.flagMilitar === 'S',
			codigoVerificador: values.codigoVerificador,
			categoriaAtual: values?.categoriaPretendida?.trim() || '',
			categoriaPretendida: values?.categoriaPretendida?.trim() || '',
			registro: values.registro ? values.registro.trim() : '',
			numeroPgu: values.numeroPgu || '',
			rneNumero: values.rneNumero,
			rneOrgao: values.rneOrgao ? onlyLetters(values.rneOrgao) : '',
			rneUf: values.rneUf !== '' && values.rneUf != null ? values.rneUf : '',
			rneCodPaisCnhEstrangeira:
				values.rneCodPaisCnhEstrangeira !== '' &&
				values.rneCodPaisCnhEstrangeira != null
					? values.rneCodPaisCnhEstrangeira
					: null,
			rneDataValidadeCnhEstrangeira:
				values.rneDataValidadeCnhEstrangeira !== '' &&
				values.rneDataValidadeCnhEstrangeira != null
					? values.rneDataValidadeCnhEstrangeira
					: null,
			rneDataPrimeiraHabilitacaoCnhEstrangeira:
				values.rneDataPrimeiraHabilitacaoCnhEstrangeira !== '' &&
				values.rneDataPrimeiraHabilitacaoCnhEstrangeira != null
					? values.rneDataPrimeiraHabilitacaoCnhEstrangeira
					: null,
			rneIdentificacaoCnhEstrangeira:
				values.rneIdentificacaoCnhEstrangeira !== '' &&
				values.rneIdentificacaoCnhEstrangeira != null
					? values.rneIdentificacaoCnhEstrangeira
					: null,
			codEntidadeMilitar: values.codEntidadeMilitar || '',
			numeroIdentMilitar: values.numeroIdentMilitar || '',
			cpfUsuario: user.cpf,
			codLocalidade: user.posto,
			codCiretranHabilitacao:
				values.codCiretranCondutor != null &&
				values.codCiretranCondutor.toString() === '018'
					? '18'
					: (values.codCiretranCondutor != null &&
							values.codCiretranCondutor.toString()) ||
					  '',
			flagProcurador: false,
			nomeSocial:
				values.flagNomeSocial === 'S'
					? removeAcentoCaracteresEspeciais(
							values.nomeSocial ? values.nomeSocial.toUpperCase() : '',
					  )
					: '',
			paisAfetivo1:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo1 ? values.paisAfetivo1.toUpperCase() : '',
					  )
					: '',
			paisAfetivo2:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo2 ? values.paisAfetivo2.toUpperCase() : '',
					  )
					: '',
		},
	};

	return formattedData;
}

export const schema = Yup.object<GerarRenachForm>().shape(
	{
		flagAtividadeRemunerada: Yup.string().required(
			'Campo Obrigatório: Pretende exercer atividade remunerada.',
		),
		flagNomeSocial: Yup.string().required(
			'Campo Obrigatório: Deseja utilizar o nome social na CNH?.',
		),
		flagSolicitaCNHDigital: Yup.string().required(
			'Campo Obrigatório: Reabilitação Via Impressa ou Reabilitação Digital (Impressa + Digital).',
		),
		paisAfetivo1: Yup.string()
			.when(['flagFiliacaoAfetiva'], {
				is: flagFiliacaoAfetiva => flagFiliacaoAfetiva === 'S',
				then: Yup.string().required('Campo Obrigatório: Filiação Afetiva 1.'),
			})
			.test(
				'same-name-filiacoes',
				'O nome da filiação afetiva 1 não pode ser igual ao nome da filiação afetiva 2.',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? this.parent.paisAfetivo2 !== value
						: true;
				},
			)
			.test(
				'same-name-filiacao1',
				'A filiação afetiva 1 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-1',
				"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		paisAfetivo2: Yup.string()
			.test(
				'same-name-filiacao2',
				'A filiação afetiva 2 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-2',
				"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),

		numDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: RG.'),
		}),

		flagFiliacaoAfetiva: Yup.string().required(
			'Campo Obrigatório: Deseja incluir filiação afetiva na CNH?.',
		),
		codigoNaturalidade: Yup.string().required(
			'Campo Obrigatório: Naturalidade.',
		),
		orgaoExpDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
		}),

		celular: Yup.string()
			.when(['telefone'], {
				is: telefone => telefone === '',
				then: Yup.string().required('Campo Obrigatório: Celular.'),
			})
			.when(['autorizaSMS'], {
				is: autorizaSMS => autorizaSMS === 'S' || autorizaSMS === true,
				then: Yup.string().required('Campo Obrigatório: Celular.'),
			})
			.matches(userDataRegex.cellPhoneNumber, {
				message: 'Campo Inválido: Celular.',
				excludeEmptyString: true,
			}),
		telefone: Yup.string()
			.when(['celular'], {
				is: celular => celular === '',
				then: Yup.string().required('Campo Obrigatório: Telefone.'),
			})
			.matches(userDataRegex.phoneNumber, {
				message: 'Campo Inválido: Telefone.',
				excludeEmptyString: true,
			}),
		email: Yup.string()
			.when(['autorizaEmail'], {
				is: autorizaEmail => autorizaEmail === 'S' || autorizaEmail === true,
				then: Yup.string().required('Campo Obrigatório: Email.'),
			})
			.matches(userDataRegex.emailAddressUpdated, {
				message: 'Formato de e-mail inválido.',
				excludeEmptyString: true,
			})
			.test(
				'same-email',
				'A confirmação do e-mail é diferente do e-mail informado.',
				function verify(value) {
					return value !== '' ? this.parent.confirmaEmail === value : true;
				},
			),

		dataNascimento: Yup.string().required(
			'Campo Obrigatório: Data de Nascimento.',
		),
		logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
		nomeMae: Yup.string().required('Campo Obrigatório: Nome da Mãe.'),
		nome: Yup.string().required('Campo Obrigatório: Nome.'),
		cpf: Yup.string().required('Campo Obrigatório: CPF.'),
		sexo: Yup.string().required('Campo Obrigatório: Sexo.'),
		nacionalidade: Yup.string().required('Campo Obrigatório: Nacionalidade.'),
		uf: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '1',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),

		enderecoRecebimentoCep: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - CEP.',
		),
		enderecoRecebimentoNumero: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Número.',
		),
		enderecoRecebimentoBairro: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Bairro.',
		),
		enderecoRecebimentoLogradouro: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Logradouro.',
		),
		enderecoRecebimentoMunicipio: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Município.',
		),
		nomeSocial: Yup.string()
			.when(['flagNomeSocial'], {
				is: flagNomeSocial => flagNomeSocial === 'S',
				then: Yup.string().required('Campo Obrigatório: Nome Social.'),
			})
			.test(
				'same-name',
				'O nome social não pode ser igual ao nome civil.',
				function verify(value) {
					return value !== '' && this.parent.flagNomeSocial === 'S'
						? this.parent.nome !== value
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-social-name',
				"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		orgaoUfExpDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),
		rneNumero: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: RNE.'),
		}),
		rneOrgao: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
		}),
	},
	[['celular', 'telefone']],
);
