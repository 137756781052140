import { ConsultaCipDataPfPj } from 'store/modules/api/procon/atendimentos/types';
import { ConsultaAtendimentosDeConsultaData } from 'store/modules/api/procon/atendimentosDeConsulta/consultaAtendimentosDeConsulta/types';
import { ConsultaProcessoAdministrativoRequest } from 'store/modules/api/procon/processosAdministrativos/processosAdministrativos/types';

const getFirstValidInfoPayloadRequestConsultaProcessoAdiministrativo = (
	data: ConsultaProcessoAdministrativoRequest,
) => {
	let payload: ConsultaProcessoAdministrativoRequest = {
		processoAdministrativoId: '',
		protocolo: '',
		numeroFa: '',
	};

	if (data.processoAdministrativoId) {
		payload = {
			...payload,
			processoAdministrativoId: data.processoAdministrativoId,
		};
		return payload;
	}

	if (data.protocolo) {
		payload = { ...payload, protocolo: data.protocolo };
		return payload;
	}

	if (data.numeroFa) {
		payload = { ...payload, numeroFa: data.numeroFa };
		return payload;
	}

	return null;
};

export const threatDataConsultaProcessoAdministrativoRequest = (
	requestData: ConsultaCipDataPfPj | ConsultaAtendimentosDeConsultaData,
): ConsultaProcessoAdministrativoRequest | null => {
	const valuesToThreat = {
		processoAdministrativoId: '',
		protocolo: requestData.protocolo,
		numeroFa: requestData.numeroFa || '',
	};

	const threatedValues =
		getFirstValidInfoPayloadRequestConsultaProcessoAdiministrativo(
			valuesToThreat,
		);

	return threatedValues;
};
