import produce from 'immer';

// UTILS
import { calcularIdade, verificarMaioridade } from 'utils/genericFunctions';
import { verificaTipoReuso } from './functions';

// TYPES
import { ReducerAction } from '../../../types';
import { Types, ICadastroRgState } from './types';

export const INITIAL_STATE: ICadastroRgState = {
	config: {
		novo: false,
		acervo: false,
		edicao: false,
		reedicao: false,
	},
	portaria606: false,
	tipo: {
		primeiraVia: false,
		segundaVia: false,
		reusoParcial: false,
		reusoTotal: false,
	},
	perguntas: {
		todosDedos: true,
		coletarAssinatura: false,
		justificativaAssinatura: '',
		justificativaFoto: '',
		coletarFoto: false,
		teveAlteracao: false,
	},
	info: {
		dataNascimento: '',
		idade: 0,
		maior: false,
		lote: null,
	},
	original: null,
	form: {
		principal: null,
		observacoes: {
			primeiraVia: {
				menor: null,
				maior: null,
			},
			segundaVia: null,
		},
	},
	dare: null,
	cadastrarRgSuccess: false,
	atualizarRgSuccess: false,
	finalizado: false,
	consultaPesquisaCidadaoSelecionado: null,
	numRg: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ICadastroRgState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CADASTRO_RG_CFG_NOVO: {
				draft.config.novo = true;
				draft.config.acervo = false;
				draft.config.edicao = false;

				break;
			}
			case Types.CADASTRO_RG_CFG_ACERVO: {
				draft.config.novo = false;
				draft.config.acervo = true;
				draft.config.edicao = false;

				break;
			}
			case Types.CADASTRO_RG_CFG_EDICAO: {
				draft.config.novo = false;
				draft.config.acervo = false;
				draft.config.edicao = true;

				break;
			}

			case Types.CADASTRO_RG_CFG_REEDICAO: {
				draft.config.reedicao = true;

				break;
			}

			case Types.CADASTRO_RG_PRIMEIRA_VIA: {
				draft.tipo.primeiraVia = true;
				draft.tipo.segundaVia = false;

				break;
			}
			case Types.CADASTRO_RG_SEGUNDA_VIA: {
				draft.tipo.primeiraVia = false;
				draft.tipo.segundaVia = true;

				break;
			}

			case Types.CADASTRO_RG_DATA_NASCIMENTO: {
				draft.info.dataNascimento = action.payload;
				draft.info.idade = calcularIdade(action.payload);
				draft.info.maior = verificarMaioridade(action.payload);

				break;
			}

			case Types.CADASTRO_RG_CONFIRMACAO_ATENDIMENTO: {
				if (
					action.payload.todosDedos &&
					!action.payload.coletarAssinatura &&
					!action.payload.coletarFoto
				) {
					// Não houve alteração - Reuso Total.
					draft.perguntas.teveAlteracao = false;
					draft.tipo.reusoParcial = false;
					draft.tipo.reusoTotal = true;
				} else {
					// Houve alteração - Reuso Parcial.
					draft.perguntas.teveAlteracao = true;
					draft.tipo.reusoParcial = true;
					draft.tipo.reusoTotal = false;
				}

				draft.perguntas.coletarAssinatura = action.payload.coletarAssinatura;
				draft.perguntas.coletarFoto = action.payload.coletarFoto;
				draft.perguntas.todosDedos = action.payload.todosDedos;
				draft.perguntas.justificativaAssinatura =
					action.payload.justificativaAssinatura;
				draft.perguntas.justificativaFoto = action.payload.justificativaFoto;

				break;
			}

			case Types.CADASTRO_RG_ORIGINAL: {
				draft.original = action.payload;

				break;
			}

			case Types.CADASTRAR_RG_REQUEST: {
				break;
			}
			case Types.CADASTRAR_RG_SUCCESS: {
				draft.cadastrarRgSuccess = action.payload;
				break;
			}

			case Types.CADASTRAR_RG_FAILURE: {
				draft.cadastrarRgFailure = true;
				draft.numRg = action.payload;
				break;
			}

			case Types.ATUALIZAR_RG_REQUEST: {
				break;
			}
			case Types.ATUALIZAR_RG_SUCCESS: {
				draft.atualizarRgSuccess = action.payload;
				break;
			}

			case Types.CADASTRO_RG_PRINCIPAL: {
				draft.form.principal = action.payload.principal;

				if (
					draft.tipo.segundaVia &&
					action.payload.original &&
					action.payload.consultaPesquisaCidadaoSelecionado?.afis === '*' &&
					!draft.tipo.reusoParcial
				) {
					const tipoReuso = verificaTipoReuso(
						action.payload.original,
						action.payload.principal,
					);

					draft.tipo.reusoParcial = tipoReuso.reusoParcial;
					draft.tipo.reusoTotal = tipoReuso.reusoTotal;
				}

				break;
			}

			case Types.CADASTRO_RG_OBS_PRIMEIRA_VIA_MENOR: {
				draft.form.observacoes.primeiraVia.menor = action.payload;
				draft.form.observacoes.primeiraVia.maior = null;
				draft.form.observacoes.segundaVia = null;

				break;
			}

			case Types.CADASTRO_RG_OBS_PRIMEIRA_VIA_MAIOR: {
				draft.form.observacoes.primeiraVia.menor = null;
				draft.form.observacoes.primeiraVia.maior = action.payload;
				draft.form.observacoes.segundaVia = null;

				break;
			}

			case Types.CADASTRO_RG_OBS_SEGUNDA_VIA: {
				draft.form.observacoes.primeiraVia.menor = null;
				draft.form.observacoes.primeiraVia.maior = null;
				draft.form.observacoes.segundaVia = action.payload;

				break;
			}

			case Types.CADASTRO_RG_VINCULAR_DARE: {
				draft.dare = action.payload;

				break;
			}

			case Types.ATUALIZAR_LOTE_SUCCESS: {
				draft.info.lote = action.payload;

				break;
			}

			case Types.CADASTRO_RG_CONFRONTO_DIGITAL: {
				// Falha no confronto da biometria.
				if (action.payload === false) {
					draft.tipo.reusoParcial = true;
					draft.tipo.reusoTotal = false;
				}

				// Sucesso no confronto da biometria.
				if (action.payload === true) {
					draft.tipo.reusoParcial = false;
					draft.tipo.reusoTotal = true;
				}

				break;
			}

			case Types.CADASTRO_RG_FINALIZADO: {
				draft.finalizado = action.payload;

				break;
			}

			case Types.CADASTRO_RG_PORTARIA_606: {
				draft.portaria606 = true;

				break;
			}

			case Types.CADASTRO_RG_PORTARIA_606_CLEAR: {
				draft.portaria606 = INITIAL_STATE.portaria606;

				break;
			}

			case Types.CADASTRO_RG_CLEAR: {
				draft.config = {
					novo: false,
					acervo: false,
					edicao: false,
					reedicao: false,
				};

				draft.tipo = {
					primeiraVia: false,
					segundaVia: false,
					reusoParcial: false,
					reusoTotal: false,
				};

				draft.perguntas = INITIAL_STATE.perguntas;

				draft.info = {
					dataNascimento: '',
					idade: 0,
					maior: false,
					lote: null,
				};

				draft.original = null;

				draft.form = {
					principal: null,
					observacoes: {
						primeiraVia: {
							menor: null,
							maior: null,
						},
						segundaVia: null,
					},
				};

				draft.dare = null;

				draft.cadastrarRgSuccess = false;
				draft.atualizarRgSuccess = false;

				draft.finalizado = false;

				draft.consultaPesquisaCidadaoSelecionado = null;

				break;
			}

			default:
		}

		return draft;
	});
}
