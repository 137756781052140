export enum Types {
	ALTERA_SENHA_REQUEST = '@iirgd/ALTERA_SENHA_REQUEST',
	ALTERA_SENHA_SUCCESS = '@iirgd/ALTERA_SENHA_SUCCESS',
	ALTERA_SENHA_FAILURE = '@iirgd/ALTERA_SENHA_FAILURE',
	ALTERA_SENHA_CLEAR = '@iirgd/ALTERA_SENHA_CLEAR',
}

export interface AlteraSenha {
	status: number;
	mensagem: string;
	form: AlteraSenhaRequest | null;
}

export interface AlteraSenhaRequest {
	usuario: string;
	senhaAtual: string;
	senhaNova: string;
	senhaNovaConfirma: string;
}
