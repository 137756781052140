import styled from 'styled-components';

export const SlashDivider = styled.div`
	align-items: center;
	display: flex;
	height: 100%;

	div {
		margin: auto 5px;
	}
`;
