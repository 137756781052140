export enum Types {
	ATUALIZAR_MENSAGEM_ERRO_REQUEST = '@SGU/ATUALIZAR_MENSAGEM_ERRO_REQUEST',
	ATUALIZAR_MENSAGEM_ERRO_SUCCESS = '@SGU/ATUALIZAR_MENSAGEM_ERRO_SUCCESS',
	ATUALIZAR_MENSAGEM_ERRO_FAILURE = '@SGU/ATUALIZAR_MENSAGEM_ERRO_FAILURE',
	ATUALIZAR_MENSAGEM_ERRO_CLEAR = '@SGU/ATUALIZAR_MENSAGEM_ERRO_CLEAR',
}

export interface AtualizarMensagemErro {
	status: number;
	data: null | MensagemErroData;
}

interface MensagemErroData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarMensagemErroRequest {
	id?: number | string;
	descricao: string;
}
