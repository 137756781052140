import React, { Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

const BloqueioRenajud: React.FC = () => {
	const { bloqueioRenajud } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.consultarRestricoes.consultarBloqueioDetranRenajud
				.data,
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Row>
				<Col span={24}>
					<Section size="sm" title="DADOS DE CONSULTA A BLOQUEIO RENAJUD">
						<Row gutter={[0, 10]}>
							<Col span={4}>Placa</Col>
							<Col span={4}>
								<strong>{bloqueioRenajud?.placa}</strong>
							</Col>
							<Col span={4}>Município Placa</Col>
							<Col span={4}>
								<strong>{bloqueioRenajud?.municipioPlaca.nome}</strong>
							</Col>
							<Col span={4}>Chassi</Col>
							<Col span={4}>
								<strong>{bloqueioRenajud?.chassi}</strong>
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="QUANTIDADE DE BLOQUEIOS">
						<Row gutter={[0, 10]}>
							<Col span={8}>Quantidade de Ocorrências Encontradas</Col>
							<Col span={4}>
								<strong>
									{bloqueioRenajud?.quantidadeOcorrenciasEncontradas}
								</strong>
							</Col>
							<Col span={8}>Quantidade de Ocorrências Exibidas</Col>
							<Col span={4}>
								<strong>
									{bloqueioRenajud?.quantidadeOcorrenciasExibidas}
								</strong>
							</Col>
						</Row>
					</Section>

					<Section size="sm" title="INFORMAÇÕES DE BLOQUEIOS RENAJUD">
						<Row gutter={[0, 10]}>
							<Col span={6}>Data da Inclusão</Col>
							<Col span={6}>
								<strong>
									{bloqueioRenajud?.registroRenajud[0]
										.registroRenajudRestricao[0].dataHoraInclusao &&
										new Date(
											bloqueioRenajud?.registroRenajud[0].registroRenajudRestricao[0].dataHoraInclusao,
										).toLocaleDateString('pt-BR')}
								</strong>
							</Col>
							<Col span={6}>Hora da Inclusão</Col>
							<Col span={6}>
								<strong>
									{bloqueioRenajud?.registroRenajud[0]
										.registroRenajudRestricao[0].dataHoraInclusao &&
										`${new Date(
											Number(
												bloqueioRenajud?.registroRenajud[0]
													.registroRenajudRestricao[0].dataHoraInclusao,
											),
										).toLocaleTimeString('pt-BR', {
											hour: '2-digit',
											minute: '2-digit',
										})}`}
								</strong>
							</Col>
							<Col span={6}>Tipo Restrição Judicial</Col>
							<Col span={6}>
								<strong>
									{
										bloqueioRenajud?.registroRenajud[0]
											.registroRenajudRestricao[0].tipoRestricao
									}
								</strong>
							</Col>
							<Col span={6}>Código do Tribunal</Col>
							<Col span={6}>
								<strong>
									{bloqueioRenajud?.registroRenajud[0].codigoTribunal}
								</strong>
							</Col>
							<Col span={6}>Código do Órgão Judicial</Col>
							<Col span={6}>
								<strong>
									{bloqueioRenajud?.registroRenajud[0].codigoOrgaoJudicial}
								</strong>
							</Col>
							<Col span={6}>Númerodo Processo</Col>
							<Col span={6}>
								<strong>
									{bloqueioRenajud?.registroRenajud[0].numeroProcesso}
								</strong>
							</Col>
							<Col span={6}>Nome do Órgão Judicial</Col>
							<Col span={6}>
								<strong>
									{bloqueioRenajud?.registroRenajud[0].nomeOrgaoJudicial}
								</strong>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
		</Suspense>
	);
};

export default BloqueioRenajud;
