import { combineReducers } from 'redux';

import consultarFichaCadastral from './ConsultarFichaCadastral/reducer';
import excluirFichaCadastral from './ExcluirFichaCadastral/reducer';
import impressaoRenavam from './ImpressaoRenavam/reducer';
import impressaoLicenciamentoVeiculos from './ImpressaoLicenciamentoVeiculos/reducer';
import impressaoAlteracaoEndereco from './ImpressaoAlteracaoEndereco/reducer';
import impressaoSegundaViaLicenciamento from './ImpressaoSegundaViaLicenciamento/reducer';
import impressaoComunicacaoVenda from './ImpressaoComunicacaoVenda/reducer';
import impressaoOficioSegundaViaPlaca from './ImpressaoOficioSegundaViaPlaca/reducer';
import impressaoTrocaVoluntariaPlaca from './ImpressaoTrocaVoluntariaPlaca/reducer';
import andamentoProcesso from './AndamentoProcesso/reducer';

export default combineReducers({
	consultarFichaCadastral,
	excluirFichaCadastral,
	impressaoRenavam,
	impressaoLicenciamentoVeiculos,
	impressaoAlteracaoEndereco,
	impressaoSegundaViaLicenciamento,
	impressaoComunicacaoVenda,
	impressaoOficioSegundaViaPlaca,
	impressaoTrocaVoluntariaPlaca,
	andamentoProcesso,
});
