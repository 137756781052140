import React, { CSSProperties, useCallback, useEffect } from 'react';
import { Col, Row } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';

import Button from 'components/Common/Button';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import SimpleTable from 'components/Common/Table';
import InputMask from 'components/Common/Form/Input/InputMask';
import TooltipInfo from 'components/Common/Tooltips/Info';
import { Title } from 'components/Common/Form/Input/styled';
import Input from 'components/Common/Form/Input/Input';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from 'store';
import {
	limparListaPortal,
	listarSolicitacaoPortalRequest,
} from 'store/modules/api/mp/listarSolicitacaoPortal/actions';
import { preCadastroCidadaoClear } from 'store/modules/api/sguService/preCadastroCidadao/actions';
import {
	limpaPreCadastroRequest,
	preCadastroRequest,
} from 'store/modules/api/mp/preCadastro/actions';
import { LimparSolicitacaoMP } from 'store/modules/api/mp/enviaSolicitacaoMP/actions';
import { limparAtualizaSolicitacaoPortal } from 'store/modules/api/mp/atualizaSolicitacaoPortal/actions';
import { impressaoTermoPaternidadeClear } from 'store/modules/api/mp/impressaoTermoPaternidade/actions';
import { impressaoSenhaClear } from 'store/modules/api/mp/impressaoGeraSenha/actions';
import { eventoTerminoClear } from 'store/modules/api/mp/eventoTermino/actions';
import { eventoInicioClear } from 'store/modules/api/mp/eventoInicio/actions';
import { enviarSolicitacoesPortalClear } from 'store/modules/api/mp/enviarSolicitacoesPortal/actions';

import {
	DadosGolden,
	DataConsultaAtestado,
} from 'store/modules/api/mp/listarSolicitacaoPortal/types';

import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { formatTimestampToDate } from 'utils/genericFunctions';

import { ROUTE_MINISTERIO_PUBLICO } from 'routes/paths';
import { initialValues, schema } from './form';
import { Content, TooltipBox, Div } from './styled';

const styleBtn = {
	height: 'auto',
	display: 'block',
	padding: '5px',
	width: '195px',
	marginRight: '5px',
};

const BuscarSolicitacao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { listarSolicitacaoPortal } = useSelector(
		(state: ApplicationState) => state.api.ministerioPublico,
	);

	useEffect(() => {
		dispatch(enviarSolicitacoesPortalClear());
		dispatch(LimparSolicitacaoMP());
		dispatch(limparAtualizaSolicitacaoPortal());
		dispatch(limpaPreCadastroRequest());
		dispatch(impressaoTermoPaternidadeClear());
		dispatch(impressaoSenhaClear());
		dispatch(eventoInicioClear());
		dispatch(eventoTerminoClear());
		dispatch(limparListaPortal());
		dispatch(preCadastroCidadaoClear());
	}, [dispatch]);

	const handleSelecionar = useCallback(
		(values: any) => {
			dispatch(preCadastroRequest(values));
			dispatch(limparListaPortal());
			history.push(
				'/ministerio-publico/reconhecimento-de-paternidade/cadastro',
			);
		},
		[history, dispatch],
	);

	const handleRegister = useCallback(
		(solicitante: number, dados: DadosGolden) => {
			const dataRegister = {
				idSolicitacao: 0,
				destinatarioSolicitacao: solicitante,
				RGempty: false,
				tipoSolicitante: solicitante,
				anexos: [],
				protocolo: '',
				filho: {
					nome: '',
					dtNascimento: '',
					sexo: '',
					cpf: '',
					rg: {
						numero: '',
						digito: '',
						uf: '',
					},
					endereco: {
						logradouro: '',
						numero: '',
						complemEndereco: '',
						bairro: '',
						cep: '',
						cidade: '',
						uf: '',
					},
					email: '',
					telefones: {
						fixo: '',
						celular: '',
						comercial: '',
					},
					genitor: {
						outrasInformacoes: '',
						nome: '',
						sexo: '',
						cpf: '',
						dtNascimento: '',
						telefones: {
							fixo: '',
							celular: '',
							comercial: '',
						},
						rg: {
							numero: '',
							digito: '',
							uf: '',
						},
						endereco: {
							logradouro: '',
							complemEndereco: '',
							numero: '',
							bairro: '',
							cep: '',
							cidade: '',
							uf: '',
						},
						email: '',
					},
				},
				responsavel: {
					nome: '',
					dtNascimento: '',
					sexo: '',
					cpf: '',
					rg: {
						numero: '',
						digito: '',
						uf: '',
					},
					telefones: {
						fixo: '',
						celular: '',
						comercial: '',
					},
					endereco: {
						logradouro: '',
						complemEndereco: '',
						numero: '',
						bairro: '',
						cep: '',
						cidade: '',
						uf: '',
					},
					email: '',
				},
				aceiteTermo: false,
				aceiteAcrescNomePai: false,
				aceiteTermoDeclaracaoPobreza: false,
				aceiteAcrescimoNome: false,
				informacoesAdicionais: '',
			};

			if (solicitante === 1) {
				dataRegister.filho.nome = dados.nome;
				dataRegister.filho.dtNascimento = formatTimestampToDate(
					dados.dataNascimento,
				);
				dataRegister.filho.sexo = dados.sexo;
				dataRegister.filho.email = dados.email;
				dataRegister.filho.rg.numero = dados.rg;
				dataRegister.filho.rg.digito = dados.digitoRg;
				dataRegister.filho.rg.uf = dados.ufRg;
				dataRegister.filho.cpf = dados.cpf;

				dataRegister.filho.endereco.cep = dados.endereco.cep;
				dataRegister.filho.endereco.logradouro = dados.endereco.logradouro;
				dataRegister.filho.endereco.numero = dados.endereco.numero;
				dataRegister.filho.endereco.bairro = dados.endereco.bairro;
				// dataRegister.filho.email = dados.email;
				dados.telefones.forEach(tel => {
					if (tel.numero.length > 8) {
						dataRegister.filho.telefones.celular = tel.ddd + tel.numero;
					} else {
						dataRegister.filho.telefones.fixo = tel.ddd + tel.numero;
					}
				});
			} else if (solicitante === 2) {
				dataRegister.responsavel.nome = dados.nome;
				dataRegister.responsavel.dtNascimento = formatTimestampToDate(
					dados.dataNascimento,
				);
				dataRegister.responsavel.sexo = dados.sexo;
				dataRegister.responsavel.rg.numero = dados.rg;
				dataRegister.responsavel.rg.digito = dados.digitoRg;
				dataRegister.responsavel.rg.uf = dados.ufRg;
				dataRegister.responsavel.cpf = dados.cpf;

				dataRegister.responsavel.endereco.cep = dados.endereco.cep;
				dataRegister.responsavel.endereco.logradouro =
					dados.endereco.logradouro;
				dataRegister.responsavel.endereco.numero = dados.endereco.numero;
				dataRegister.responsavel.endereco.bairro = dados.endereco.bairro;
				dataRegister.responsavel.email = dados.email;
				dados.telefones.forEach(tel => {
					if (tel.numero.length > 8) {
						dataRegister.responsavel.telefones.celular = tel.ddd + tel.numero;
					} else {
						dataRegister.responsavel.telefones.fixo = tel.ddd + tel.numero;
					}
				});
			} else if (solicitante === 3) {
				dataRegister.filho.genitor.nome = dados.nome;
				dataRegister.filho.genitor.dtNascimento = formatTimestampToDate(
					dados.dataNascimento,
				);
				dataRegister.filho.genitor.sexo = dados.sexo;
				dataRegister.filho.genitor.rg.numero = dados.rg;
				dataRegister.filho.genitor.rg.digito = dados.digitoRg;
				dataRegister.filho.genitor.rg.uf = dados.ufRg;
				dataRegister.filho.genitor.cpf = dados.cpf;

				dataRegister.filho.genitor.endereco.cep = dados.endereco.cep;
				dataRegister.filho.genitor.endereco.logradouro =
					dados.endereco.logradouro;
				dataRegister.filho.genitor.endereco.numero = dados.endereco.numero;
				dataRegister.filho.genitor.endereco.bairro = dados.endereco.bairro;
				dataRegister.filho.genitor.email = dados.email;
				dados.telefones.forEach(tel => {
					if (tel.numero.length > 8) {
						dataRegister.filho.genitor.telefones.celular = tel.ddd + tel.numero;
					} else {
						dataRegister.filho.genitor.telefones.fixo = tel.ddd + tel.numero;
					}
				});
			}
			dispatch(preCadastroRequest(dataRegister));
			dispatch(limparListaPortal());
			history.push(
				'/ministerio-publico/reconhecimento-de-paternidade/cadastro',
			);
		},
		[dispatch, history],
	);

	const TooltipContent = (
		<>
			<p>Responsável legal, filho (a) acima de 18 anos, próprio pai.</p>
		</>
	);

	const getName = useCallback(
		(solicitante: string, dados: DataConsultaAtestado): string => {
			let name: string = '';
			if (solicitante === '1') {
				name = dados.filho.nome;
			} else if (solicitante === '3') {
				name = dados.filho.genitor.nome;
			} else if (solicitante === '2') {
				name = dados.responsavel.nome;
			}

			return name.toUpperCase();
		},
		[],
	);

	const getCpf = useCallback(
		(solicitante: string, dados: DataConsultaAtestado): string => {
			let cpf: string = '';
			if (solicitante === '1') {
				cpf = dados.filho.cpf;
			} else if (solicitante === '3') {
				cpf = dados.filho.genitor.cpf;
			} else if (solicitante === '2') {
				cpf = dados.responsavel.cpf;
			}

			return cpf.toUpperCase();
		},
		[],
	);

	const headers = [
		{
			title: 'Data Solicitação',
			dataIndex: 'dataCriacao',
			align: 'center',
			render: (dataCriacao: string) => {
				if (dataCriacao) {
					return (
						<b>
							{' '}
							{dataCriacao
								? new Date(dataCriacao).toLocaleDateString('pt-BR')
								: ''}
						</b>
					);
				}
				return '';
			},
		},
		{
			title: 'Filho (a)',
			dataIndex: 'filho',
			align: 'center',
			render: (filho: any) => {
				return <b>{filho?.nome ? filho?.nome.toUpperCase() : ''}</b>;
			},
		},
		{
			title: 'Quem Preencheu',
			dataIndex: 'responsavel',
			align: 'center',
			render: (responsavel: any, record: DataConsultaAtestado) => {
				return <b>{getName(record.destinatarioSolicitacao, record)}</b>;
			},
		},
		{
			title: 'CPF',
			dataIndex: 'responsavel',
			align: 'center',
			render: (responsavel: any, record: DataConsultaAtestado) => {
				return <b>{getCpf(record.destinatarioSolicitacao, record)}</b>;
			},
		},
		{
			title: '',
			dataIndex: 'filho',
			align: 'center',
			render: (filho: any, values: any) => {
				if (filho?.nome) {
					return (
						<ButtonImage
							style={{ display: 'block !important' }}
							type="submit"
							src="selecionar"
							onClick={() => {
								handleSelecionar(values);
							}}
						/>
					);
				}
				return '';
			},
		},
	];

	return (
		<>
			<Section title="Reconhecimento de Paternidade">
				<Row justify="end">
					<Col span={4}>
						<ButtonImage
							type="button"
							src="novo-cadastro"
							onClick={() =>
								history.push(
									'/ministerio-publico/reconhecimento-de-paternidade/cadastro',
								)
							}
						/>
					</Col>
				</Row>
				<Section title="Buscar Solicitação" size="sm">
					<Row justify="center" gutter={[0, 16]}>
						<Col>
							<Formik
								validateOnChange={false}
								validateOnBlur={false}
								initialValues={initialValues}
								validate={values => getValidationsErrors(values, schema)}
								onSubmit={async values => {
									values.cpf = values.cpf.replace(/[.-]+/g, '');
									dispatch(listarSolicitacaoPortalRequest(values));
								}}
							>
								{(formik: FormikProps<any>) => {
									const { errors } = formik;
									return (
										<Content>
											<Form>
												<TooltipBox>
													<Title size={30} required={false}>
														<label>CPF:</label>
													</Title>
													<TooltipInfo content={TooltipContent} />
												</TooltipBox>
												<Field
													name="cpf"
													size={200}
													as={InputMask}
													mask="999.999.999-99"
													error={hasError(errors, 'cpf')}
												/>
												<ButtonImage type="submit" src="pesquisar" />
											</Form>
										</Content>
									);
								}}
							</Formik>
						</Col>
						<Col />
					</Row>
				</Section>
				<Div>
					{listarSolicitacaoPortal.data.length === 1 &&
					listarSolicitacaoPortal.data[0].idSolicitacao === null &&
					listarSolicitacaoPortal.data[0].dadosGolden ? (
						<>
							<Section bgColor="cinza-claro">
								<Row align="middle" justify="center">
									<Col>
										<h2>Dados do Cidadão</h2>
									</Col>
								</Row>
								<Row justify="end">
									<Col span={12}>
										<Input
											name="nome"
											readOnly
											value={listarSolicitacaoPortal.data[0].dadosGolden.nome}
											title="Nome"
										/>
										<Input
											name="nomeMae"
											readOnly
											value={
												listarSolicitacaoPortal.data[0].dadosGolden.nomeMae
											}
											title="Nome Mãe"
										/>
										<Input
											name="nomePai"
											readOnly
											value={
												listarSolicitacaoPortal.data[0].dadosGolden.nomePai
											}
											title="Nome Pai"
										/>
									</Col>
									<Col span={12}>
										<Input
											name="dataNascimento"
											readOnly
											value={formatTimestampToDate(
												listarSolicitacaoPortal.data[0].dadosGolden
													.dataNascimento,
											)}
											title="Data Nascimento"
										/>
										<Input
											name="cpf"
											readOnly
											value={listarSolicitacaoPortal.data[0].dadosGolden.cpf}
											title="CPF"
										/>
										<Input
											name="rg"
											readOnly
											value={`${listarSolicitacaoPortal.data[0].dadosGolden.rg} - ${listarSolicitacaoPortal.data[0].dadosGolden.digitoRg}`}
											title="RG"
										/>
									</Col>
								</Row>
							</Section>
							<Row justify="center">
								<Col span={24} style={{ textAlign: 'center' }}>
									<h3 style={{ color: '#d90000' }}>
										Os dados do cidadão acima representa
									</h3>
								</Col>
								<Button
									style={styleBtn as CSSProperties}
									type="button"
									onClick={() =>
										handleRegister(
											1,
											listarSolicitacaoPortal.data[0]
												.dadosGolden as DadosGolden,
										)
									}
								>
									Filho ou filha
								</Button>
								<Button
									style={styleBtn as CSSProperties}
									type="button"
									onClick={() =>
										handleRegister(
											3,
											listarSolicitacaoPortal.data[0]
												.dadosGolden as DadosGolden,
										)
									}
								>
									Genitor
								</Button>
								<Button
									style={styleBtn as CSSProperties}
									type="button"
									onClick={() =>
										handleRegister(
											2,
											listarSolicitacaoPortal.data[0]
												.dadosGolden as DadosGolden,
										)
									}
								>
									Responsável legal
								</Button>
							</Row>
						</>
					) : (
						<>
							<span>Resultados Encontrados:</span>
							<SimpleTable
								size="sm"
								headers={headers}
								body={listarSolicitacaoPortal.data}
								pageSize={5}
								messageNoResults="Nenhum registro encontrado"
								striped
							/>
						</>
					)}
				</Div>
			</Section>

			<ButtonVoltar route={ROUTE_MINISTERIO_PUBLICO} />
		</>
	);
};

export default BuscarSolicitacao;
