import { AxiosRequestConfig } from 'axios';
import { servicesThatUsePathBffToGenerateLog } from './servicesList';

const {
	REACT_APP_BASE_URL_ROTASP_DEV,
	REACT_APP_BASE_URL_ROTASP_HOMOLOG,
	REACT_APP_BASE_URL_ROTASP_PROD,
	REACT_APP_BASE_URL,
	REACT_APP_ENVIRONMENT,
} = process.env;

const appBaseURLIntegrador =
	(REACT_APP_ENVIRONMENT === 'DEV' && REACT_APP_BASE_URL_ROTASP_DEV) ||
	(REACT_APP_ENVIRONMENT === 'HOMOLOG' && REACT_APP_BASE_URL_ROTASP_HOMOLOG) ||
	(REACT_APP_ENVIRONMENT === 'PROD' && REACT_APP_BASE_URL_ROTASP_PROD) ||
	'';

const appBaseURLBff = REACT_APP_BASE_URL;

const verifyServiceIntegradorUseBffToGenerateLog = (
	configs: AxiosRequestConfig,
) => {
	const service = configs.url || '';

	const useBff = servicesThatUsePathBffToGenerateLog.some(
		item => item.serviceIntegrador === service,
	);

	return useBff;
};

const treatPathBaseBffUrl = (configs: AxiosRequestConfig) => {
	const service = configs.url || '';
	const { pathBff, serviceBff } = servicesThatUsePathBffToGenerateLog.filter(
		item => item.serviceIntegrador === service,
	)[0] || [{ pathBff: '', serviceBff: '' }];

	const pathBase = `${appBaseURLBff}/${pathBff}`;

	return { pathBase, serviceBff };
};

const treatPathBaseIntegradorUrl = (service: string) => {
	const splitPath = appBaseURLIntegrador.split('//');
	const initialPath = splitPath[0];
	const environmentPath = splitPath[1];
	const pathBase = `${initialPath}//${service}.${environmentPath}/`;
	return pathBase;
};

export {
	verifyServiceIntegradorUseBffToGenerateLog,
	treatPathBaseBffUrl,
	treatPathBaseIntegradorUrl,
};
