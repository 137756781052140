import * as Yup from 'yup';

import {
	unformatDate,
	limparMascara,
	formatDate,
	formatDateToString,
	removeAcentoCaracteresEspeciais,
	verifyStringIsAnInvalidPhrase,
	onlyLetters,
} from 'utils/genericFunctions';
import { ConsultaCnhEstrangeiroData } from 'store/modules/api/detranCnh/eportal/consultaCnhEstrangeiro/types';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { socialNameAndFiliationsPhrasesNotAllowed } from 'utils/constants/types';
import { EventoContabilizacao } from 'store/modules/types';
import {
	sameThreeCharactersInSequenceNotAllowedRegex,
	userDataRegex,
} from 'utils/constants/regex';
import { GerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/types';

export interface CnhEstrangeiroModalInfo {
	type: string;
	message: string;
}

export interface FormCnhEstrangeiro {
	autorizaEnvioEmail: string;
	autorizaEnvioSms: string;
	bairro: string;
	categoriaAtual: string;
	categoriaPretendida: string;
	celular?: string;
	cep: string;
	codEntidade: string;
	complemento: string;
	cpf: string;
	confirmaEmail: string;
	codigoUnidadeTransito: number;
	dataNascimento: string;
	dataCadastro: string;
	dataValidadeCnhEstrangeira: string;
	dataPrimeiraHabilitacao: string;
	email?: string;
	enderecoRecebimentoBairro?: string;
	enderecoRecebimentoCep?: string;
	enderecoRecebimentoLogradouro?: string;
	enderecoRecebimentoMunicipio?: string;
	enderecoRecebimentoCodigoMunicipio?: string;
	enderecoRecebimentoNumero?: string;
	enderecoRecebimentoComplemento?: string;
	flagNomeSocial?: string;
	flagFiliacaoAfetiva?: string;
	flagExameNoPosto: string;
	flagAtividadeRemunerada: string | boolean;
	flagSolicitaCNHDigital: string | boolean;
	flagDeficienteFisico: string;
	flagExameToxicologico?: string;
	// LOGRADOURO
	logradouro: string;
	logradouroCodMunicipio: string;
	municipio: string;
	militar: string;
	nacionalidade: string;
	naturalidade: string;
	nome: string;
	nomeSocial?: string;
	nomeMae: string;
	nomePai: string;
	numero_carteira_militar: string;
	numRg?: string;
	numero: string;
	numeroPgu: string;
	numeroCnhEstrangeira: string;
	orgaoExpDocumento?: string;
	orgaoUfExpDocumento?: string;
	processo: string;
	paisAfetivos1?: string;
	paisAfetivos2?: string;
	paisCnhEstrangeiro: string;
	rneNumero?: string;
	rneUf?: string;
	rneOrgao?: string;
	status: string;
	sexo: string;
	telefone?: string;
	tipo_documento: string;
	uf?: string;
	codigoVerificador?: string;
	origemNomeSocial: string;
}

export const radioTipoCnhEstrangeiro = [
	{
		label: 'CNH Via Impressa',
		value: 'N',
	},
	{
		label: 'CNH Via Digital (Impressa + Digital)',
		value: 'S',
	},
];

export const initialValuesDadosHabilitacao: ConsultaCnhEstrangeiroData = {
	cpf: '',
	dataCadastro: '',
	horaCadastro: '',
	usuarioCadastro: '',
	categoria: '',
	ciretran: '',
	identidade: '',
	identidadeUf: '',
	nomeMae: '',
	nomePai: '',
	identificacao: '',
	nacionalidade: '',
	dataNascimento: '',
	codNaturalidade: '',
	naturalidade: '',
	nome: '',
	numeroRg: '',
	orgaoEmissor: '',
	orgaoEmissorUf: '',
	pais1: '',
	pais2: '',
	numeroPgu: '',
	categoriaPretendida: '',
	dataPrimeiraHabilitacao: '',
	processo: '',
	numeroRegistro: '',
	rne: '',
	rneOrgaoEmissor: '',
	sexo: '',
	dataValidade: '',
	endereco: {
		codigo: '',
		logradouro: '',
		numero: '',
		complemento: '',
		cep: '',
		bairro: '',
		descricaoMunicipio: '',
		codigoMunicipio: '',
	},
};

// TREAT TO MOUNT FORM
export function treatInitialValues(
	values: ConsultaCnhEstrangeiroData | null,
): FormCnhEstrangeiro {
	const formattedData = {
		// --- DADOS CNH ESTRANGEIRO --- //
		cpf: values?.cpf?.trim() || '',
		bairro: values?.endereco?.bairro.trim() || '',
		categoriaPretendida: values?.categoriaPretendida?.trim() || '',
		cep: values?.endereco?.cep?.trim() || '',
		numero: values?.endereco?.numero?.trim() || '',
		complemento: values?.endereco?.complemento?.trim() || '',
		logradouro: values?.endereco?.logradouro?.trim() || '',
		municipio: values?.endereco?.descricaoMunicipio?.trim() || '',
		logradouroCodMunicipio: values?.endereco?.codigoMunicipio?.trim() || '',
		codEntidade: '',
		dataNascimento: formatDate(values?.dataNascimento || ''),
		email: '',
		nacionalidade: values?.nacionalidade?.trim() || '3',
		naturalidade: values?.codNaturalidade?.trim() || '',
		categoriaAtual: values?.categoria?.trim() || '',
		ciretran: values?.ciretran?.trim() || '',
		nome: values?.nome?.trim() || '',
		nomeMae: values?.nomeMae?.trim() || '',
		nomePai: values?.nomePai?.trim() || '',
		numero_carteira_militar: '',
		numRg: values?.identidade?.trim() || '',
		orgaoExpDocumento: values?.orgaoEmissor?.trim() || '',
		sexo:
			(values?.sexo &&
				(((values.sexo.trim() === 'FEMININO' || values.sexo.trim() === 'F') &&
					'F') ||
					((values.sexo.trim() === 'MASCULINO' || values.sexo.trim() === 'M') &&
						'M'))) ||
			'M',
		tipo_documento: 'CARTEIRA_IDENTIDADE',
		orgaoUfExpDocumento: values?.identidadeUf?.trim() || '',
		rneUf: values?.orgaoEmissorUf?.trim() || 'EX',
		rneOrgao: values?.rneOrgaoEmissor?.trim() || '',
		rneNumero: values?.rne?.trim().substring(0, 10) || '',
		status: '',
		processo: values?.processo?.trim() || '',
		celular: '',
		telefone: '',
		confirmaEmail: '',
		militar: 'N',
		flagExameNoPosto: 'N',
		flagAtividadeRemunerada: '',
		flagSolicitaCNHDigital: '',
		flagDeficienteFisico: '',
		flagExameToxicologico: 'N',
		paisAfetivos1: '',
		paisAfetivos2: '',
		paisCnhEstrangeiro: values?.pais1?.trim() || '',
		dataValidadeCnhEstrangeira: formatDate(values?.dataValidade?.trim() || ''),
		numeroCnhEstrangeira: values?.identificacao?.trim() || '',
		dataPrimeiraHabilitacao: formatDate(
			values?.dataPrimeiraHabilitacao?.trim() || '',
		),
		numeroPgu: values?.numeroPgu?.trim() || '',
		uf: '',
		codigoVerificador: '',
		autorizaEnvioEmail: 'N',
		autorizaEnvioSms: 'N',
		codigoUnidadeTransito: values?.ciretran ? Number(values?.ciretran) : 0,
		dataCadastro: formatDateToString(new Date()),
		origemNomeSocial: '0',
	};

	return formattedData;
}

// TREAT TO SEND FORM
export function treatValues(
	evento: EventoContabilizacao,
	values: FormCnhEstrangeiro,
	user: IUser,
): GerarRenachIntegradoRequest {
	const formattedData = {
		candidato: {
			apresentaDocumentos: '',
			autorizaEmail: values.autorizaEnvioEmail || '',
			autorizaSMS: values.autorizaEnvioSms || '',
			bairro:
				(values.bairro && removeAcentoCaracteresEspeciais(values.bairro)) || '',
			categoriaAtual: values.categoriaAtual?.trim() || '',
			categoriaPretendida: values.categoriaPretendida?.trim() || '',
			codigoUnidadeAtendimento: user.posto,
			celular: limparMascara(values.celular).substring(2, 11),
			cep: values.cep || '',
			cnhDigital: values.flagSolicitaCNHDigital === 'S' ? 'S' : 'N',
			codigoCiretranPostoExameMedico:
				values.flagExameNoPosto === 'S' ? user.posto : '',
			codigoEntidade: values.codEntidade || '',
			codigoVerificador: values.codigoVerificador || '',
			coletaBiometrica: '',
			complemento:
				(values.complemento &&
					removeAcentoCaracteresEspeciais(values.complemento)) ||
				'',
			cpf: values.cpf,
			dataNascimento:
				values.dataNascimento && unformatDate(values.dataNascimento),
			dataPrimeiraHabilitacao: values.dataPrimeiraHabilitacao
				? unformatDate(values.dataPrimeiraHabilitacao)
				: '',
			dataValidadeCnhE: values.dataValidadeCnhEstrangeira
				? unformatDate(values.dataValidadeCnhEstrangeira)
				: '',
			dddCelular: limparMascara(values.celular).substring(0, 2),
			dddTelefone: limparMascara(values.telefone).substring(0, 2),
			email: values.email || '',
			logradouro: values.logradouro
				? removeAcentoCaracteresEspeciais(values.logradouro)
				: '',
			nacionalidade: values.nacionalidade,
			naturalidade: values.naturalidade ? values.naturalidade?.toString() : '',
			necessidadeVeiculoAdaptado: 'N',
			nome: removeAcentoCaracteresEspeciais(values.nome.toUpperCase()),
			nomeMae: removeAcentoCaracteresEspeciais(values.nomeMae.toUpperCase()),
			nomePai: removeAcentoCaracteresEspeciais(values.nomePai.toUpperCase()),
			nomeSocial:
				values.flagNomeSocial === 'S'
					? removeAcentoCaracteresEspeciais(
							values.nomeSocial ? values.nomeSocial.toUpperCase() : '',
					  )
					: '',
			nomeSocialValidadoBiometria: '',
			origemNomeSocial:
				(values.flagNomeSocial === 'S' && values.origemNomeSocial) || '',
			paisAfetivos1:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivos1 ? values.paisAfetivos1.toUpperCase() : '',
					  )
					: '',
			paisAfetivos1ValidadoBiometria: '',
			paisAfetivos2:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivos2 ? values.paisAfetivos2.toUpperCase() : '',
					  )
					: '',
			paisAfetivos2ValidadoBiometria: '',
			numCNHE: values.numeroCnhEstrangeira || '',
			numCarteiraMilitar: '',
			numDocumento: (values.numRg && limparMascara(values.numRg.trim())) || '',
			numPGU: values.numeroPgu || '',
			numRNE: values.rneNumero || '',
			numRegistro: '',
			numero: values.numero !== 'S/N' ? String(values.numero) : '',
			orgaoDocumento: values.orgaoExpDocumento
				? onlyLetters(values.orgaoExpDocumento.toLocaleUpperCase())
				: '',
			orgaoRNE: values.rneOrgao || '',
			paisCNHE: values.paisCnhEstrangeiro || '',
			pretendeAtividadeRemunerada:
				values.flagAtividadeRemunerada === 'S' ? 'S' : 'N',
			sexo: values.sexo,
			telefone: limparMascara(values.telefone).substring(2, 10),
			testeAlfabetizacao: '',
			tipoDocumento: '1',
			tipoProcesso: '5',
			uf: values.uf || '',
			ufDocumento: values.orgaoUfExpDocumento || '',
			ufRNE: values.rneUf || '',
		},
		dadosExameMedico: {
			codigoCiretranExameMedico: '',
			dataExame: '',
			horarioExame: '',
			idMedico: '',
			idMicroRegiaoMedico: '',
		},
		dadosExamePsicologo: {
			codigoCiretranExamePsicologo: '',
			dataExamePsicologico: '',
			horarioExamePsicologico: '',
			idMicroRegiaoPsicologo: '',
			idPsicologo: '',
		},
		gerarRenachBase: {
			evento: {
				id_atendimento: evento.id_atendimento,
				id_cidadao: evento.id_cidadao,
				ip: evento.ip,
				identificacao: evento.cpf && parseInt(limparMascara(values.cpf), 10),
				cpf: evento.cpf || '',
				canal: {
					id: 1,
					desc: 'Presencial',
					localidade: {
						desc: evento.canal?.localidade.desc || '',
						id: evento.canal?.localidade.id || 0,
					},
				},
			},
			usoFormularioEnum: 'CNH_ESTRANGEIRA',
			cpf: values.cpf,
			nome: removeAcentoCaracteresEspeciais(values.nome.toUpperCase()),
			nomeSocial:
				values.flagNomeSocial === 'S'
					? removeAcentoCaracteresEspeciais(
							values.nomeSocial?.toUpperCase() || '',
					  )
					: '',
			nomeMae: removeAcentoCaracteresEspeciais(values.nomeMae.toUpperCase()),
			nomePai: removeAcentoCaracteresEspeciais(values.nomePai.toUpperCase()),
			sexo: values.sexo,
			dataNascimento: unformatDate(values.dataNascimento),
			tipoDocumentoEnum: 'CARTEIRA_IDENTIDADE',
			numDocumento: (values.numRg && limparMascara(values.numRg.trim())) || '',
			orgaoExpDocumento: values.orgaoExpDocumento?.toUpperCase()
				? onlyLetters(values.orgaoExpDocumento)
				: '',
			orgaoUfExpDocumento: values.orgaoUfExpDocumento?.toUpperCase() || '',
			nacionalidade: values.nacionalidade,
			codigoNaturalidade: values.naturalidade,
			email: values.email?.toUpperCase() || '',
			celularDDD: limparMascara(values.celular).substring(0, 2),
			celular: limparMascara(values.celular).substring(2, 11),
			dddTelefone: limparMascara(values.telefone).substring(0, 2),
			telefone: limparMascara(values.telefone).substring(2, 10),
			logradouro: values.logradouro?.toUpperCase(),
			logradouroNumero: values.numero,
			logradouroComplemento: values.complemento?.toUpperCase() || '',
			logradouroBairro: values.bairro.toUpperCase() || '',
			logradouroCodMunicipio: values.logradouroCodMunicipio.toString(),
			logradouroCep: values.cep,
			codCiretranCondutor: values.codigoUnidadeTransito.toString(),
			statusReusoEnum: 'SEM_REUSO',
			flagAtividadeRemunerada: values.flagAtividadeRemunerada === 'S',
			flagDeficienteFisico: values.flagDeficienteFisico === 'S',
			flagSolicitaCNHDigital: values.flagSolicitaCNHDigital === 'S',
			codigoVerificador: limparMascara(
				values.codigoVerificador?.toUpperCase() || '',
			),
			categoriaAtual: values.categoriaAtual?.trim() || '',
			categoriaPretendida: values.categoriaPretendida?.trim() || '',
			registro: null,
			numeroPgu: values.numeroPgu?.toUpperCase(),
			rneNumero: values.rneNumero?.toUpperCase() || '',
			rneOrgao: values.rneOrgao
				? onlyLetters(values.rneOrgao.toUpperCase())
				: '',
			rneUf: values.rneUf?.toUpperCase() || '',
			rneCodPaisCnhEstrangeira: values.paisCnhEstrangeiro,
			rneDataValidadeCnhEstrangeira: unformatDate(
				values.dataValidadeCnhEstrangeira,
			),
			rneDataPrimeiraHabilitacaoCnhEstrangeira: unformatDate(
				values.dataPrimeiraHabilitacao,
			),
			rneIdentificacaoCnhEstrangeira: values.numeroCnhEstrangeira.toUpperCase(),
			flagMilitar: false,
			codEntidadeMilitar: values.codEntidade,
			numeroIdentMilitar: values.numero_carteira_militar,
			flagExamePosto: values.flagExameNoPosto === 'S',
			flagExameToxicologico: values.flagExameToxicologico === 'S',
			flagProcurador: false,
			codCiretranHabilitacao: values.codigoUnidadeTransito.toString(),
			cpfUsuario: user.cpf,
			codLocalidade: user.posto,
			paisAfetivo1:
				values.flagFiliacaoAfetiva === 'S'
					? values.paisAfetivos1?.toUpperCase() || ''
					: '',
			paisAfetivo2:
				values.flagFiliacaoAfetiva === 'S'
					? values.paisAfetivos2?.toUpperCase() || ''
					: '',
		},
	};
	return formattedData;
}

export const schema = Yup.object<FormCnhEstrangeiro>().shape(
	{
		categoriaAtual: Yup.string().required(
			'Campo Obrigatório: Categoria Atual.',
		),
		categoriaPretendida: Yup.string().required(
			'Campo Obrigatório: Categoria Pretendida.',
		),
		flagAtividadeRemunerada: Yup.string().required(
			'Campo Obrigatório: Pretende exercer atividade remunerada.',
		),
		// flagDeficienteFisico: Yup.string().required(
		// 	'Campo Obrigatório: Tem necessidade de veículo adaptado.',
		// ),
		flagExameToxicologico: Yup.string().when(['categoriaPretendida'], {
			is: categoriaPretendida =>
				categoriaPretendida !== '' &&
				categoriaPretendida !== 'A' &&
				categoriaPretendida !== 'B' &&
				categoriaPretendida !== 'AB' &&
				categoriaPretendida !== 'X' &&
				categoriaPretendida !== 'XB',
			then: Yup.string().required(
				'Campo Obrigatório: Realizou exames Toxicológicos?.',
			),
		}),
		nome: Yup.string().required('Campo Obrigatório: Nome.'),
		nomeSocial: Yup.string()
			.when(['flagNomeSocial'], {
				is: flagNomeSocial => flagNomeSocial === 'S',
				then: Yup.string().required('Campo Obrigatório: Nome Social.'),
			})
			.test(
				'same-name',
				'O nome social não pode ser igual ao nome civil.',
				function verify(value) {
					return value !== '' && this.parent.flagNomeSocial === 'S'
						? this.parent.nome !== value
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-social-name',
				"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		flagNomeSocial: Yup.string().required(
			'Campo Obrigatório: Deseja incluir nome social na CNH?.',
		),
		nacionalidade: Yup.string().required('Campo Obrigatório: Nacionalidade.'),
		uf: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '1',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),
		naturalidade: Yup.string().required('Campo Obrigatório: Naturalidade.'),
		numRg: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: RG.'),
		}),
		orgaoExpDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
		}),
		orgaoUfExpDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),
		rneNumero: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: RNE.'),
		}),
		rneOrgao: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
		}),
		paisCnhEstrangeiro: Yup.string().required(
			'Campo Obrigatório: País CNH Estrangeira.',
		),
		dataValidadeCnhEstrangeira: Yup.string()
			.required('Campo Obrigatório: Validade CNH Estrangeira.')
			.matches(userDataRegex.dateWithoutTime, {
				message: 'Validade CNH Estrangeira: Não é uma data válida.',
				excludeEmptyString: true,
			}),
		numeroCnhEstrangeira: Yup.string().required(
			'Campo Obrigatório: Nº CNH Estrangeira.',
		),
		dataPrimeiraHabilitacao: Yup.string()
			.required('Campo Obrigatório: Data Primeira Habilitação.')
			.matches(userDataRegex.dateWithoutTime, {
				message: 'Data Primeira Habilitação: Não é uma data válida.',
				excludeEmptyString: true,
			}),
		nomeMae: Yup.string().required('Campo Obrigatório: Nome da Mãe.'),
		paisAfetivos1: Yup.string()
			.when(['flagFiliacaoAfetiva'], {
				is: flagFiliacaoAfetiva => flagFiliacaoAfetiva === 'S',
				then: Yup.string().required('Campo Obrigatório: Filiação Afetiva 1.'),
			})
			.test(
				'same-name-filiacoes',
				'O nome da filiação afetiva 1 não pode ser igual ao nome da filiação afetiva 2.',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? this.parent.paisAfetivos2 !== value
						: true;
				},
			)
			.test(
				'same-name-filiacao1',
				'A filiação afetiva 1 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-1',
				"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		paisAfetivos2: Yup.string()
			.test(
				'same-name-filiacao2',
				'A filiação afetiva 2 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-2',
				"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		flagFiliacaoAfetiva: Yup.string().required(
			'Campo Obrigatório: Deseja incluir filiação afetiva na CNH?.',
		),
		sexo: Yup.string().required('Campo Obrigatório: Sexo.'),
		dataNascimento: Yup.string()
			.required('Campo Obrigatório: Data de Nascimento.')
			.matches(userDataRegex.dateWithoutTime, {
				message: 'Data de Nascimento: Não é uma data válida.',
				excludeEmptyString: true,
			}),
		cep: Yup.string().required('Campo Obrigatório: CEP.'),
		logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
		numero: Yup.string().required('Campo Obrigatório: Número.'),
		bairro: Yup.string().required('Campo Obrigatório: Bairro.'),
		municipio: Yup.string().required('Campo Obrigatório: Município.'),
		telefone: Yup.string()
			.when(['celular'], {
				is: celular => celular === '',
				then: Yup.string().required('Campo Obrigatório: Telefone.'),
			})
			.matches(userDataRegex.phoneNumber, {
				message: 'Campo Inválido: Telefone.',
				excludeEmptyString: true,
			}),
		email: Yup.string()
			.when(['autorizaEnvioEmail'], {
				is: autorizaEnvioEmail =>
					autorizaEnvioEmail === 'S' || autorizaEnvioEmail === true,
				then: Yup.string().required('Campo Obrigatório: Email.'),
			})
			.matches(userDataRegex.emailAddressUpdated, {
				message: 'Formato de e-mail inválido.',
				excludeEmptyString: true,
			})
			.test(
				'same-email',
				'A confirmação do e-mail é diferente do e-mail informado.',
				function verify(value) {
					return value !== '' ? this.parent.confirmaEmail === value : true;
				},
			),
		celular: Yup.string()
			.when(['telefone'], {
				is: telefone => telefone === '',
				then: Yup.string().required('Campo Obrigatório: Celular.'),
			})
			.when(['autorizaSMS'], {
				is: autorizaSMS => autorizaSMS === 'S' || autorizaSMS === true,
				then: Yup.string().required('Campo Obrigatório: Celular.'),
			})
			.matches(userDataRegex.cellPhoneNumber, {
				message: 'Campo Inválido: Celular.',
				excludeEmptyString: true,
			}),
		flagSolicitaCNHDigital: Yup.string().required(
			'Campo Obrigatório: CNH Via Impressa ou CNH Via Digital (Impressa + Digital).',
		),
		enderecoRecebimentoCep: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - CEP.',
		),
		enderecoRecebimentoNumero: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Número.',
		),
		enderecoRecebimentoBairro: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Bairro.',
		),
		enderecoRecebimentoLogradouro: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Logradouro.',
		),
		enderecoRecebimentoMunicipio: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Município.',
		),
	},
	[['telefone', 'celular']],
);

export const schemaGenerateToken = Yup.object<FormCnhEstrangeiro>().shape({
	celular: Yup.string()
		.required('Campo Obrigatório: Celular.')
		.matches(userDataRegex.cellPhoneNumber, {
			message: 'Campo Inválido: Celular.',
			excludeEmptyString: true,
		}),
	email: Yup.string()
		.when(['autorizaEnvioEmail'], {
			is: autorizaEnvioEmail => autorizaEnvioEmail === 'S',
			then: Yup.string().required('Campo Obrigatório: Email.'),
		})
		.matches(userDataRegex.emailAddress, {
			message: 'Formato de e-mail inválido.',
			excludeEmptyString: true,
		})
		.test(
			'same-email',
			'A confirmação do e-mail é diferente do e-mail informado.',
			function verify(value) {
				return value !== '' ? this.parent.confirmaEmail === value : true;
			},
		),
});
