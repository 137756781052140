export enum Types {
	ENVIAR_SMS_REQUEST = '@iirgd/ENVIAR_SMS_REQUEST',
	ENVIAR_SMS_SUCCESS = '@iirgd/ENVIAR_SMS_SUCCESS',
	ENVIAR_SMS_FAILURE = '@iirgd/ENVIAR_SMS_FAILURE',
	ENVIAR_SMS_CLEAR = '@iirgd/ENVIAR_SMS_CLEAR',
}

export interface IEnviarSmsState {
	status: number;
}
