import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, ComboMunicipiosSP } from './types';

export const INITIAL_STATE: ComboMunicipiosSP = {
	status: 0,
	data: {
		processoId: '0',
		municipios: [],
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ComboMunicipiosSP {
	return produce(state, draft => {
		switch (action.type) {
			case Types.COMBO_MUNICIPIOS_SP_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.COMBO_MUNICIPIOS_SP_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.COMBO_MUNICIPIOS_SP_FAILURE: {
				draft.status = action.payload.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
		return draft;
	});
}
