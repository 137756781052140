import { ReducerAction } from 'store/modules/types';

import { Types, IRequestGerarPreCadastro } from './types';

export function gerarPreCadastroRequest(
	data: IRequestGerarPreCadastro,
): ReducerAction {
	return {
		type: Types.GERAR_PRE_CADASTRO,
		payload: data,
	};
}
export function gerarPreCadastroSuccess(payload: object): ReducerAction {
	return {
		type: Types.GERAR_PRE_CADASTRO_SUCCESS,
		payload,
	};
}
export function gerarPreCadastroFailure(payload: boolean): ReducerAction {
	return {
		type: Types.GERAR_PRE_CADASTRO_FAILURE,
		payload,
	};
}
