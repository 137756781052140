import * as Yup from 'yup';

import {
	completaZeroEsquerda,
	formatDate,
	limparMascara,
	onlyLetters,
	removeAcentoCaracteresEspeciais,
	unformatDate,
	verifyIsUndefinedReturnEmpty,
	verifyStringIsAnInvalidPhrase,
} from 'utils/genericFunctions';

import { IFormStatusCondutor } from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor/form';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { GerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/types';
import { ConsultaPconData } from 'store/modules/api/detranCnh/eportal/consultaPcon/types';
import { socialNameAndFiliationsPhrasesNotAllowed } from 'utils/constants/types';
import {
	sameThreeCharactersInSequenceNotAllowedRegex,
	userDataRegex,
} from 'utils/constants/regex';
import { EventoContabilizacao } from 'store/modules/types';

export const initalValuesHabilitacao: IFormStatusCondutor = {
	status: '',
	statusMensagem: '',
	mensagemBloqueio: '',
	numeroRegistro: '',
	numeroPgu: '',
	renach: '',
	local: '',
	categoriaPretendida: '',
	cfcA: '',
	espelhoCnh: '',
	usoForm: '',
	situacaoAtual: '',
	cfcB: '',
	espelhoPid: '',
	dataPrimeiraHabilitacao: '',
	nome: '',
	dataValidadeCnh: '',
	nomePai: '',
	dataNascimento: '',
	nomeMae: '',
	sexo: '',
	nacionalidade: '',
	tipoDocumento: '',
	numeroRg: '',
	orgaoEmissor: '',
	localEmissao: '',
	cpf: '',
	rne1: '',
	rne2: '',
	rne3: '',
	naturalidade: '',
	identificacaoHabilitacao: '',
	pais: '',
	dataValidade: '',
	enderecoLogradouro: '',
	enderecoNumero: '',
	enderecoComplemento: '',
	enderecoCep: '',
	enderecoBairro: '',
	enderecoMunicipio: '',
	enderecoCodigoMunicipio: '',
	enderecoDescricaoMunicipio: '',
	mensagem: '',
	exameMedicoDataExameMedico: '',
	exameMedicoCategoriaPermitida: '',
	exameMedicoRestricaoMedica: '',
	exameMedicoCrm: '',
	exameMedicoCred1: '',
	exameMedicoCred2: '',
	exameMedicoRegiao: '',
	exameMedicoAptidaoMedica: '',
	examePsicotecnicoDataExamePsicotecnico: '',
	examePsicotecnicoLaudo: '',
	examePsicotecnicoResultado1: '',
	examePsicotecnicoResultado2: '',
	examePsicotecnicoCrp: '',
	examePsicotecnicoCred1: '',
	examePsicotecnicoCred2: '',
	examePsicotecnicoRegiao: '',
	examePsicotecnicoAptidaoPsicotecnica: '',
	dataExameTeorico: '',
	codigoExameTeorico: '',
	codigoEntidade: '',
	descricaoEntidade: '',
	dataExamePratico2Rodas: '',
	codigoExamePratico2Rodas: '',
	dataExamePratico4Rodas: '',
	codigoExamePratico4Rodas: '',
	dataUltimaEmissaoCnh: '',
	listaOcorrencias: [],
	listaCursos: [],
	cepPcon: '',
	cepPcad: '',
	mensagensAlerta: '',
	restricaoMedica: '',
	nomeSocial: '',
};

export const initialValuesPreCadastro: GerarRenachForm = {
	usoFormularioEnum: 'CASSACAO',
	cpf: '',
	nome: '',
	nomeMae: '',
	nomePai: '',
	sexo: '',
	dataNascimento: '',
	tipoDocumentoEnum: '',
	numDocumento: '',
	orgaoExpDocumento: '',
	orgaoUfExpDocumento: '',
	nacionalidade: '',
	naturalidade: '',
	codigoNaturalidade: '',
	email: '',
	celularDDD: '',
	celular: '',
	logradouro: '',
	logradouroNumero: '',
	logradouroComplemento: '',
	logradouroBairro: '',
	logradouroCodMunicipio: '',
	logradouroCep: '',
	codCiretranCondutor: '',
	statusReusoEnum: '',
	flagAtividadeRemunerada: '',
	flagDeficienteFisico: '',
	flagSolicitaCNHDigital: '',
	codigoVerificador: '',
	categoriaAtual: '',
	categoriaPretendida: '',
	registro: '',
	numeroPgu: '',
	rneNumero: '',
	rneOrgao: '',
	rneUf: '',
	rneCodPaisCnhEstrangeira: '',
	rneDataValidadeCnhEstrangeira: '',
	rneDataPrimeiraHabilitacaoCnhEstrangeira: '',
	rneIdentificacaoCnhEstrangeira: '',
	flagMilitar: '',
	codEntidadeMilitar: '',
	numeroIdentMilitar: '',
	flagExamePosto: '',
	flagExameToxicologico: '',
	flagProcurador: '',
	codCiretranHabilitacao: '',
	cpfUsuario: '',
	codLocalidade: '',
	autorizaEmail: 'N',
	autorizaSMS: 'N',
	origemNomeSocial: '0',
	nomeSocial: '',
	paisAfetivo1: '',
	paisAfetivo2: '',
};

export const radioSimNao = [
	{
		label: 'Sim',
		value: 'S',
	},
	{
		label: 'Não',
		value: 'N',
	},
];

export const radioTipoRenovacao = [
	{
		label: 'Renovação Via Impressa',
		value: 'S',
	},
	{
		label: 'Renovação Digital (Impressa + Digital)',
		value: 'N',
	},
];

// TREAT TO MOUNT FORM
export function treatInitialValues(
	values: ConsultaPconData | null,
	dataPrimeiraHabilitacao: string,
	user: IUser,
): {
	initalValuesHabilitacao: IFormStatusCondutor;
	initialValuesPreCadastro: GerarRenachForm;
} {
	if (values !== null) {
		// DADOS DA CONSULTA INICIAL
		const {
			cpf,
			nome,
			nomePai,
			nomeMae,
			numeroRg,
			dataNascimento,
			sexo,
			nacionalidade,
			usoForm,
			numeroRegistro,
			numeroPgu,
			renach,
			espelhoCnh,
			dadosExameMedico,
			dataExameTeorico,
			dadosExamePsicotecnico,
			local,
			cfcA,
			cfcB,
			dataValidadeCnh,
			situacaoAtual,
			dataExamePratico4Rodas,
			dataExamePratico2Rodas,
			endereco,
			localEmissao,
			orgaoEmissor,
			rne1,
			rne2,
			rne3,
		} = values;

		const formattedDataHabilitacao = {
			...initalValuesHabilitacao,
			// --- DADOS HABILITAÇÃO --- //
			// INFORMAÇÕES PESSOAIS
			cpf,
			nome,
			nomePai,
			nomeMae,
			numeroRg,
			dataNascimento:
				verifyIsUndefinedReturnEmpty(dataNascimento) === ''
					? verifyIsUndefinedReturnEmpty(dataNascimento)
					: formatDate(dataNascimento),
			sexo,
			nacionalidade,

			// INFORMAÇÕES HABILITAÇÃO
			usoForm,
			numeroRegistro,
			numeroPgu,
			renach,
			espelhoCnh,
			exameMedicoDataExameMedico:
				dadosExameMedico.dataExameMedico &&
				formatDate(dadosExameMedico.dataExameMedico),
			exameMedicoCrm: dadosExameMedico.crm || '',
			dataExameTeorico:
				verifyIsUndefinedReturnEmpty(dataExameTeorico) === ''
					? verifyIsUndefinedReturnEmpty(dataExameTeorico)
					: formatDate(dataExameTeorico),
			examePsicotecnicoCrp: dadosExamePsicotecnico?.crp || '',
			examePsicotecnicoLaudo: dadosExamePsicotecnico?.laudo1 || '',
			examePsicotecnicoDataExamePsicotecnico:
				formatDate(dadosExamePsicotecnico?.dataExamePsicotecnico) || '',
			categoriaPretendida: situacaoAtual || '',
			local,
			cfcA,
			exameMedicoCategoriaPermitida: dadosExameMedico?.categoriaPermitida || '',
			exameMedicoCred1:
				(dadosExameMedico?.cred1 &&
					dadosExameMedico.cred1 === ' ' &&
					(dadosExameMedico?.cred2
						? dadosExameMedico.cred2
						: dadosExameMedico.cred1)) ||
				'',
			examePsicotecnicoResultado2: dadosExamePsicotecnico?.resultado2 || '',
			dataPrimeiraHabilitacao:
				verifyIsUndefinedReturnEmpty(dataPrimeiraHabilitacao) === ''
					? verifyIsUndefinedReturnEmpty(dataPrimeiraHabilitacao)
					: formatDate(dataPrimeiraHabilitacao),
			dataValidadeCnh: dataValidadeCnh && formatDate(dataValidadeCnh),
			situacaoAtual,
			cfcB,
			exameMedicoRegiao: dadosExameMedico?.regiao || '',
			dataExamePratico2Rodas:
				verifyIsUndefinedReturnEmpty(dataExamePratico2Rodas) === ''
					? verifyIsUndefinedReturnEmpty(dataExamePratico2Rodas)
					: formatDate(dataExamePratico2Rodas),
			dataExamePratico4Rodas:
				verifyIsUndefinedReturnEmpty(dataExamePratico4Rodas) === ''
					? verifyIsUndefinedReturnEmpty(dataExamePratico4Rodas)
					: formatDate(dataExamePratico4Rodas),
			exameMedicoCred2:
				dadosExameMedico?.cred1 !== '' ? dadosExameMedico?.cred2 : '' || '',
			examePsicotecnicoRegiao: dadosExamePsicotecnico?.regiao || '',
			dataUltimaEmissaoCnh: '',

			// ENDEREÇO CADASTRADO
			enderecoCep: endereco.cep || '',
			enderecoLogradouro: endereco.logradouro || '',
			enderecoNumero: endereco.numero || '',
			enderecoComplemento: endereco.complemento || '',
			enderecoBairro: endereco.bairro || '',
			enderecoDescricaoMunicipio: endereco.descricaoMunicipio || '',

			// FORM
			localEmissao,
			orgaoEmissor,

			rne1,
			rne2,
			rne3,
			restricaoMedica: dadosExameMedico?.restricaoMedica || '',
			naturalidade: '',
		};

		const formattedDataPreCadastro = {
			...initialValuesPreCadastro,
			usoFormularioEnum: 'CASSACAO',
			cpf: values.cpf,
			nome:
				verifyIsUndefinedReturnEmpty(values.nome) === ''
					? verifyIsUndefinedReturnEmpty(values.nome)
					: removeAcentoCaracteresEspeciais(values.nome.toUpperCase()).trim(),
			nomeMae:
				verifyIsUndefinedReturnEmpty(values.nomeMae) === ''
					? verifyIsUndefinedReturnEmpty(values.nomeMae)
					: removeAcentoCaracteresEspeciais(
							values.nomeMae.toUpperCase(),
					  ).trim(),
			nomePai:
				verifyIsUndefinedReturnEmpty(values.nomePai) === ''
					? verifyIsUndefinedReturnEmpty(values.nomePai)
					: removeAcentoCaracteresEspeciais(
							values.nomePai.toUpperCase(),
					  ).trim(),
			sexo: values.sexo,
			dataNascimento:
				verifyIsUndefinedReturnEmpty(dataNascimento) === ''
					? verifyIsUndefinedReturnEmpty(dataNascimento)
					: formatDate(dataNascimento),
			dataPrimeiraHabilitacao,
			tipoDocumentoEnum: values.tipoDocumento || '',
			numDocumento:
				verifyIsUndefinedReturnEmpty(values.numeroRg) === ''
					? verifyIsUndefinedReturnEmpty(values.numeroRg)
					: values?.numeroRg?.trim() || '',
			orgaoExpDocumento:
				verifyIsUndefinedReturnEmpty(orgaoEmissor) === ''
					? verifyIsUndefinedReturnEmpty(orgaoEmissor)
					: orgaoEmissor?.trim() || '',
			orgaoUfExpDocumento:
				verifyIsUndefinedReturnEmpty(values.localEmissao) === ''
					? verifyIsUndefinedReturnEmpty(values.localEmissao)
					: values?.localEmissao?.trim() || '',
			nacionalidade: values.nacionalidade,
			naturalidade: '',
			codigoNaturalidade: '',
			logradouro: removeAcentoCaracteresEspeciais(endereco.logradouro) || '',
			logradouroNumero: removeAcentoCaracteresEspeciais(endereco.numero) || '',
			logradouroComplemento:
				removeAcentoCaracteresEspeciais(endereco.complemento) || '',
			logradouroBairro: removeAcentoCaracteresEspeciais(endereco.bairro) || '',
			logradouroCodMunicipio: values.endereco?.codigoMunicipio || '',
			logradouroDescMunicipio: values.endereco?.descricaoMunicipio || '',
			logradouroCep: completaZeroEsquerda(endereco.cep, 8) || '',
			codCiretranCondutor: local || '',
			statusReusoEnum: 'SEM_REUSO',
			flagAtividadeRemunerada: 'N',
			flagDeficienteFisico: 'N',
			flagMilitar: 'N',
			flagExamePosto: 'N',
			flagExameToxicologico: '',
			categoriaAtual: situacaoAtual,
			categoriaPretendida: situacaoAtual,
			registro: numeroRegistro,
			numeroPgu,
			codCiretranHabilitacao: local || '',
			cpfUsuario: user.cpf,
			codLocalidade: user.posto,
			uf: '',
			origemNomeSocial: '0',
		};

		return {
			initalValuesHabilitacao: formattedDataHabilitacao,
			initialValuesPreCadastro: formattedDataPreCadastro,
		};
	}
	return {
		initalValuesHabilitacao,
		initialValuesPreCadastro,
	};
}

// TREAT TO SEND RENACH
export function treatValues(
	evento: EventoContabilizacao,
	values: GerarRenachForm,
	user: IUser,
	dadosPreCadastro: GerarRenachForm,
): GerarRenachIntegradoRequest {
	const formattedData = {
		candidato: {
			apresentaDocumentos: '',
			autorizaEmail: values.autorizaEmail || '',
			autorizaSMS: values.autorizaSMS || '',
			bairro:
				(values.logradouroBairro &&
					removeAcentoCaracteresEspeciais(values.logradouroBairro)) ||
				'',
			categoriaAtual: values.categoriaPretendida?.trim(),
			categoriaPretendida: values.categoriaPretendida?.trim(),
			codigoUnidadeAtendimento: user.posto,
			celular: limparMascara(values.celular).substring(2, 11),
			cep: values.logradouroCep || '',
			cnhDigital: values.flagSolicitaCNHDigital === 'S' ? 'S' : 'N',
			codigoCiretranPostoExameMedico:
				values.flagExamePosto === 'S' ? user.posto : '',
			codigoEntidade: values.codEntidadeMilitar || '',
			codigoVerificador: values.codigoVerificador || '',
			coletaBiometrica: '',
			complemento:
				(values.logradouroComplemento &&
					removeAcentoCaracteresEspeciais(values.logradouroComplemento)) ||
				'',
			cpf: values.cpf,
			dataNascimento:
				values.dataNascimento && unformatDate(values.dataNascimento),
			dataPrimeiraHabilitacao: values.dataPrimeiraHabilitacao,
			dataValidadeCnhE: values.rneDataValidadeCnhEstrangeira || '',
			dddCelular: limparMascara(values.celular).substring(0, 2),
			dddTelefone: limparMascara(values.telefone).substring(0, 2),
			email: values.email || '',
			logradouro: removeAcentoCaracteresEspeciais(values.logradouro),
			nacionalidade: values.nacionalidade,
			naturalidade: values.codigoNaturalidade
				? values.codigoNaturalidade?.toString()
				: '',
			necessidadeVeiculoAdaptado:
				values.flagDeficienteFisico === 'S' ? 'S' : 'N',
			nome: removeAcentoCaracteresEspeciais(values.nome.toUpperCase()),
			nomeMae: removeAcentoCaracteresEspeciais(values.nomeMae.toUpperCase()),
			nomePai: removeAcentoCaracteresEspeciais(values.nomePai.toUpperCase()),
			nomeSocial:
				values.flagNomeSocial === 'S'
					? removeAcentoCaracteresEspeciais(
							values.nomeSocial ? values.nomeSocial.toUpperCase() : '',
					  )
					: '',
			nomeSocialValidadoBiometria: '',
			origemNomeSocial:
				(values.flagNomeSocial === 'S' && values.origemNomeSocial) || '',
			paisAfetivos1:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo1 ? values.paisAfetivo1.toUpperCase() : '',
					  )
					: '',
			paisAfetivos1ValidadoBiometria: '',
			paisAfetivos2:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo2 ? values.paisAfetivo2.toUpperCase() : '',
					  )
					: '',
			paisAfetivos2ValidadoBiometria: '',
			numCNHE: values.rneIdentificacaoCnhEstrangeira || '',
			numCarteiraMilitar: values.numeroIdentMilitar || '',
			numDocumento:
				(values.numDocumento && limparMascara(values.numDocumento.trim())) ||
				'',
			numPGU: values.numeroPgu || '',
			numRNE: values.rneNumero || '',
			numRegistro: values.registro ? values.registro?.trim() : '',
			numero:
				values.logradouroNumero !== 'S/N'
					? String(values.logradouroNumero)
					: '',
			orgaoDocumento: values.orgaoExpDocumento
				? onlyLetters(values.orgaoExpDocumento.toLocaleUpperCase())
				: '',
			orgaoRNE: values.rneOrgao || '',
			paisCNHE: values.rneCodPaisCnhEstrangeira || '',
			pretendeAtividadeRemunerada:
				values.flagAtividadeRemunerada === 'S' ? 'S' : 'N',
			sexo: values.sexo,
			telefone: limparMascara(values.telefone).substring(2, 10),
			testeAlfabetizacao: '',
			tipoDocumento: '1',
			tipoProcesso: '7',
			uf: values.uf || '',
			ufDocumento: values.orgaoUfExpDocumento || '',
			ufRNE: values.rneUf || '',
		},
		dadosExameMedico: {
			codigoCiretranExameMedico: '',
			dataExame: '',
			horarioExame: '',
			idMedico: '',
			idMicroRegiaoMedico: '',
		},
		dadosExamePsicologo: {
			codigoCiretranExamePsicologo: '',
			dataExamePsicologico: '',
			horarioExamePsicologico: '',
			idMicroRegiaoPsicologo: '',
			idPsicologo: '',
		},
		gerarRenachBase: {
			evento: {
				id_atendimento: evento.id_atendimento,
				id_cidadao: evento.id_cidadao,
				ip: evento.ip,
				identificacao: evento.cpf && parseInt(limparMascara(values.cpf), 10),
				cpf: evento.cpf || '',
				canal: {
					id: 1,
					desc: 'Presencial',
					localidade: {
						desc: evento.canal?.localidade.desc || '',
						id: evento.canal?.localidade.id || 0,
					},
				},
			},
			usoFormularioEnum: 'CASSACAO',
			cpf: values.cpf,
			nome: removeAcentoCaracteresEspeciais(values.nome.toUpperCase()),
			nomeMae: removeAcentoCaracteresEspeciais(values.nomeMae.toUpperCase()),
			nomePai: removeAcentoCaracteresEspeciais(values.nomePai.toUpperCase()),
			sexo: values.sexo,
			dataNascimento: unformatDate(values.dataNascimento),
			tipoDocumentoEnum: 'CARTEIRA_IDENTIDADE',
			numDocumento:
				(values.numDocumento && limparMascara(values.numDocumento.trim())) ||
				'',
			orgaoExpDocumento: values.orgaoExpDocumento
				? onlyLetters(values.orgaoExpDocumento.toLocaleUpperCase())
				: '',
			orgaoUfExpDocumento: values.orgaoUfExpDocumento || '',
			nacionalidade: values.nacionalidade,
			naturalidade: values.codigoNaturalidade
				? values.codigoNaturalidade?.toString()
				: '',
			codigoNaturalidade: values.codigoNaturalidade.toString(),
			email: values.email || '',
			celularDDD: limparMascara(values.celular).substring(0, 2),
			celular: limparMascara(values.celular).substring(2, 11),
			logradouro: values.logradouro
				? removeAcentoCaracteresEspeciais(
						values.logradouro.trim(),
				  ).toUpperCase()
				: '',
			logradouroNumero:
				values.logradouroNumero !== 'S/N'
					? String(values.logradouroNumero)
					: null,
			logradouroComplemento: values.logradouroComplemento
				? removeAcentoCaracteresEspeciais(
						values.logradouroComplemento.trim(),
				  ).toUpperCase()
				: '',
			logradouroBairro: values.logradouroBairro
				? removeAcentoCaracteresEspeciais(
						values.logradouroBairro.trim(),
				  ).toUpperCase()
				: '',
			logradouroCodMunicipio: values.logradouroCodMunicipio || '',
			logradouroCep: values.logradouroCep || '',
			codCiretranCondutor:
				(dadosPreCadastro.codCiretranCondutor &&
					dadosPreCadastro.codCiretranCondutor.toString()) ||
				'',
			statusReusoEnum: 'SEM_REUSO',
			flagAtividadeRemunerada: values.flagAtividadeRemunerada === 'S',
			flagDeficienteFisico: values.flagDeficienteFisico === 'S',
			flagSolicitaCNHDigital: values.flagSolicitaCNHDigital === 'S',
			flagExamePosto: values.flagExamePosto === 'S',
			flagExameToxicologico: values.flagExameToxicologico === 'S',
			flagMilitar: values.flagMilitar === 'S',
			codigoVerificador: values.codigoVerificador,
			categoriaAtual: values.categoriaPretendida?.trim() || '',
			categoriaPretendida: values.categoriaPretendida?.trim() || '',
			registro: values.registro ? values.registro.trim() : '',
			numeroPgu: values.numeroPgu || '',
			rneNumero: values.rneNumero || '',
			rneOrgao: values.rneOrgao ? onlyLetters(values.rneOrgao) : '',
			rneUf: values.rneUf !== '' && values.rneUf != null ? values.rneUf : '',
			rneCodPaisCnhEstrangeira:
				values.rneCodPaisCnhEstrangeira !== '' &&
				values.rneCodPaisCnhEstrangeira != null
					? values.rneCodPaisCnhEstrangeira
					: '',
			rneDataValidadeCnhEstrangeira:
				values.rneDataValidadeCnhEstrangeira !== '' &&
				values.rneDataValidadeCnhEstrangeira != null
					? values.rneDataValidadeCnhEstrangeira
					: null,
			rneDataPrimeiraHabilitacaoCnhEstrangeira:
				values.rneDataPrimeiraHabilitacaoCnhEstrangeira !== '' &&
				values.rneDataPrimeiraHabilitacaoCnhEstrangeira != null
					? values.rneDataPrimeiraHabilitacaoCnhEstrangeira
					: null,
			rneIdentificacaoCnhEstrangeira:
				values.rneIdentificacaoCnhEstrangeira !== '' &&
				values.rneIdentificacaoCnhEstrangeira != null
					? values.rneIdentificacaoCnhEstrangeira
					: null,
			codEntidadeMilitar: values.codEntidadeMilitar || '',
			numeroIdentMilitar: values.numeroIdentMilitar || '',
			cpfUsuario: user.cpf,
			codLocalidade: user.posto,
			codCiretranHabilitacao:
				(dadosPreCadastro.codCiretranHabilitacao &&
					dadosPreCadastro.codCiretranHabilitacao.toString()) ||
				'',
			flagProcurador: false,
			nomeSocial:
				values.flagNomeSocial === 'S'
					? removeAcentoCaracteresEspeciais(
							values.nomeSocial ? values.nomeSocial.toUpperCase() : '',
					  )
					: '',
			paisAfetivo1:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo1 ? values.paisAfetivo1.toUpperCase() : '',
					  )
					: '',
			paisAfetivo2:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo2 ? values.paisAfetivo2.toUpperCase() : '',
					  )
					: '',
		},
	};

	return formattedData;
}

export const schema = Yup.object<GerarRenachForm>().shape(
	{
		flagAtividadeRemunerada: Yup.string().required(
			'Campo Obrigatório: Pretende exercer atividade remunerada.',
		),
		flagExamePosto: Yup.string().required(
			'Campo Obrigatório: Realizar exame dentro do Posto.',
		),
		flagNomeSocial: Yup.string().required(
			'Campo Obrigatório: Deseja incluir nome social na CNH?.',
		),
		flagFiliacaoAfetiva: Yup.string().required(
			'Campo Obrigatório: Deseja incluir filiação afetiva na CNH?.',
		),
		flagExameToxicologico: Yup.string().when(['categoriaPretendida'], {
			is: categoriaPretendida =>
				categoriaPretendida !== '' &&
				categoriaPretendida !== 'A' &&
				categoriaPretendida !== 'B' &&
				categoriaPretendida !== 'AB' &&
				categoriaPretendida !== 'X' &&
				categoriaPretendida !== 'XB',
			then: Yup.string().required(
				'Campo Obrigatório: Realizou exames Toxicológicos?.',
			),
		}),
		paisAfetivo1: Yup.string()
			.when(['flagFiliacaoAfetiva'], {
				is: flagFiliacaoAfetiva => flagFiliacaoAfetiva === 'S',
				then: Yup.string().required('Campo Obrigatório: Filiação Afetiva 1.'),
			})
			.test(
				'same-name-filiacoes',
				'O nome da filiação afetiva 1 não pode ser igual ao nome da filiação afetiva 2.',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? this.parent.paisAfetivo2 !== value
						: true;
				},
			)
			.test(
				'same-name-filiacao1',
				'A filiação afetiva 1 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-1',
				"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		paisAfetivo2: Yup.string()
			.test(
				'same-name-filiacao2',
				'A filiação afetiva 2 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-2',
				"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		flagSolicitaCNHDigital: Yup.string().required(
			'Campo Obrigatório: Renovação Via Impressa ou Renovação Digital (Impressa + Digital).',
		),
		nome: Yup.string().required('Campo Obrigatório: Nome.'),
		cpf: Yup.string().required('Campo Obrigatório: CPF.'),
		nomeMae: Yup.string().required('Campo Obrigatório: Nome da Mãe.'),
		sexo: Yup.string().required('Campo Obrigatório: Sexo.'),
		nacionalidade: Yup.string().required('Campo Obrigatório: Nacionalidade.'),
		uf: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '1',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),
		logradouroCep: Yup.string().required('Campo Obrigatório: CEP.'),
		logradouroNumero: Yup.string().required('Campo Obrigatório: Número.'),
		logradouroBairro: Yup.string().required('Campo Obrigatório: Bairro.'),
		dataNascimento: Yup.string().required(
			'Campo Obrigatório: Data de Nascimento.',
		),
		codigoNaturalidade: Yup.string().required(
			'Campo Obrigatório: Naturalidade.',
		),
		logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
		logradouroCodMunicipio: Yup.string().required(
			'Campo Obrigatório: Município.',
		),
		enderecoRecebimentoCep: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - CEP.',
		),
		enderecoRecebimentoNumero: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Número.',
		),
		enderecoRecebimentoBairro: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Bairro.',
		),
		enderecoRecebimentoLogradouro: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Logradouro.',
		),
		enderecoRecebimentoMunicipio: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Município.',
		),
		email: Yup.string()
			.when(['autorizaEmail'], {
				is: autorizaEmail => autorizaEmail === 'S' || autorizaEmail === true,
				then: Yup.string().required('Campo Obrigatório: Email.'),
			})
			.matches(userDataRegex.emailAddressUpdated, {
				message: 'Formato de e-mail inválido.',
				excludeEmptyString: true,
			})
			.test(
				'same-email',
				'A confirmação do e-mail é diferente do e-mail informado.',
				function verify(value) {
					return value !== '' ? this.parent.confirmaEmail === value : true;
				},
			),
		nomeSocial: Yup.string()
			.when(['flagNomeSocial'], {
				is: flagNomeSocial => flagNomeSocial === 'S',
				then: Yup.string().required('Campo Obrigatório: Nome Social.'),
			})
			.test(
				'same-name',
				'O nome social não pode ser igual ao nome civil.',
				function verify(value) {
					return value !== '' && this.parent.flagNomeSocial === 'S'
						? this.parent.nome !== value
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-social-name',
				"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		celular: Yup.string()
			.when(['telefone'], {
				is: telefone => telefone === '',
				then: Yup.string().required('Campo Obrigatório: Celular.'),
			})
			.when(['autorizaSMS'], {
				is: autorizaSMS => autorizaSMS === 'S' || autorizaSMS === true,
				then: Yup.string().required('Campo Obrigatório: Celular.'),
			})
			.matches(userDataRegex.cellPhoneNumber, {
				message: 'Campo Inválido: Celular.',
				excludeEmptyString: true,
			}),
		telefone: Yup.string()
			.when(['celular'], {
				is: celular => celular === '',
				then: Yup.string().required('Campo Obrigatório: Telefone.'),
			})
			.matches(userDataRegex.phoneNumber, {
				message: 'Campo Inválido: Telefone.',
				excludeEmptyString: true,
			}),
		numDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: RG.'),
		}),
		orgaoExpDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
		}),
		orgaoUfExpDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),
		rneNumero: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: RNE.'),
		}),
		rneOrgao: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
		}),
	},
	[['telefone', 'celular']],
);
