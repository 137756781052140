import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, EnderecoIpState } from './types';

export const INITIAL_STATE: EnderecoIpState = {
	status: 0,
	data: '0:0:0:0:0:0:0:1',
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): EnderecoIpState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ENDERECO_IP_REQUEST: {
				break;
			}
			case Types.ENDERECO_IP_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.ip;
				break;
			}
			case Types.ENDERECO_IP_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.ENDERECO_IP_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
