import produce from 'immer';

// TYPES
import { Types, AuthTokenState } from './types';
import { ReducerAction } from '../../types';

export const INITIAL_STATE: AuthTokenState = {
	expireIn: null,
	authorization: null,
	calcTime: null,
};

export default function app(
	state = INITIAL_STATE,
	action: ReducerAction,
): AuthTokenState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.SET_AUTH_TOKEN: {
				const calcTimeDate = new Date();
				const calcTimeMinutes = calcTimeDate;
				const expireIn = new Date(calcTimeDate);
				expireIn.setHours(expireIn.getHours() + 1);
				draft.expireIn = expireIn;
				draft.authorization = action.payload;
				draft.calcTime = calcTimeMinutes;
				break;
			}

			case Types.SET_TIME_AUTH_TOKEN: {
				const calcTimeDate = new Date();
				const calcTimeMinutes = calcTimeDate;

				draft.expireIn = INITIAL_STATE.expireIn;
				draft.authorization = INITIAL_STATE.authorization;
				draft.calcTime = calcTimeMinutes;
				break;
			}

			case Types.CLEAR_AUTH_TOKEN: {
				draft.expireIn = INITIAL_STATE.expireIn;
				draft.authorization = INITIAL_STATE.authorization;
				draft.calcTime = INITIAL_STATE.calcTime;
				break;
			}
			default:
		}
	});
}
