import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { CancelarAutorizacaoPrevia } from 'pages/DetranCrv/SolicitarDocumentos/CancelarAutorizacaoPrevia/form';
import { Types } from './types';
import {
	cancelarAutorizacaoPreviaSuccess,
	cancelarAutorizacaoPreviaFailure,
} from './actions';

function* cancelarAutorizacaoPreviaRequest(request: {
	type: string;
	payload: CancelarAutorizacaoPrevia;
}) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/exclusao/autorizacoes-previas`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(cancelarAutorizacaoPreviaSuccess(response));
	} else if (response.error) {
		yield put(cancelarAutorizacaoPreviaFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.CANCELAR_AUTORIZACAO_PREVIA_REQUEST,
		cancelarAutorizacaoPreviaRequest,
	),
]);
