import React, { useEffect, useState, useCallback } from 'react';

import * as Yup from 'yup';

// COMPONENTS
import Alert from 'components/Common/Notifications/Alert';
import Section from 'components/Common/Section';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { ApplicationState } from 'store';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/genericFunctions';
import { Col, Row, Space } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { AlertInvalidDrugTest } from 'pages/DetranCnh/components/common/AlertInvalidDrugTest';
import DadosHabilitacao from '../DadosHabilitacao';
import DadosPreCadastro from './DadosPreCadastro';

// FORM
import { IFormStatusCondutor } from './form';
import ExibicaoPreCadastro from './ExibicaoPreCadastro';
import ConfirmacaoAtendimento from '../../ConfirmacaoAtendimento';
import { IConfirmacaoAtendimento } from '../../ConfirmacaoAtendimento/form';

interface IPropos {
	dadosHabilitacao: IFormStatusCondutor;
	dadosPreCadastro: GerarRenachForm;
	handleSubmit: (values: GerarRenachForm) => void;
	handleAfterModal?: (dataModalAtendimento: IConfirmacaoAtendimento) => void;
	handleSubmitGerarPreCadastro?: () => void;
	modalAtendimento?: boolean;
	setModalAtendimento?: (v: IConfirmacaoAtendimento) => void;
	preCadastro?: boolean;
	setPreCadastro?: (v: any | null) => void;
	schema: Yup.ObjectSchema<
		Yup.Shape<GerarRenachForm | undefined, GerarRenachForm>,
		object
	>;
}

const StatusCondutor: React.FC<IPropos> = ({
	dadosHabilitacao,
	dadosPreCadastro,
	modalAtendimento = false,
	setModalAtendimento = () => {},
	preCadastro = false,
	setPreCadastro = () => {},
	handleSubmitGerarPreCadastro = () => {},
	handleAfterModal,
	handleSubmit,
	schema,
}) => {
	const [exibePainelAtualizacao, setExibePainelAtualizacao] = useState(true);
	const [mensagensInfo, setMensagensInfo] = useState<Array<string>>([]);

	const { consultaToxicologico } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cidadao,
	);

	const { gerarRenachIntegrado } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	useEffect(() => {
		if (dadosHabilitacao.mensagemBloqueio === 'Condutor com processo aberto') {
			setExibePainelAtualizacao(false);
		}
	}, [dadosHabilitacao]);

	const handleCleanNotification = useCallback(() => {}, []);

	return (
		<Row>
			<Col span={24}>
				{gerarRenachIntegrado.status &&
				gerarRenachIntegrado.status !== 0 &&
				gerarRenachIntegrado.status !== 100 &&
				gerarRenachIntegrado.status !== 200 &&
				gerarRenachIntegrado.status !== 201 &&
				gerarRenachIntegrado.dataError?.mensagem &&
				((notifications.errors && !notifications.errors.length) ||
					(notifications.errors.length > 0 &&
						Array.isArray(notifications.errors) &&
						notifications.errors[0] !==
							gerarRenachIntegrado.dataError?.mensagem) ||
					(notifications.errors.length > 0 &&
						!Array.isArray(notifications.errors) &&
						notifications.errors !==
							gerarRenachIntegrado.dataError?.mensagem)) ? (
					// eslint-disable-next-line react/jsx-indent
					<Alert
						type="error"
						message={gerarRenachIntegrado.dataError?.mensagem}
						onClose={handleCleanNotification}
					/>
				) : null}
				<Section title="Status do Condutor">
					<h3 style={{ textTransform: 'uppercase' }}>
						{dadosHabilitacao.nome}
					</h3>
					<Space direction="vertical" size={4} style={{ width: '100%' }}>
						{dadosHabilitacao.mensagemBloqueio &&
							dadosHabilitacao.mensagemBloqueio !== '' && (
								<Alert
									type="error"
									message={dadosHabilitacao.mensagemBloqueio}
									onClose={handleCleanNotification}
								/>
							)}
						{Array.isArray(dadosHabilitacao.mensagensAlerta) &&
							dadosHabilitacao.mensagensAlerta.length > 0 &&
							dadosHabilitacao.mensagensAlerta.map((message: string) => (
								<Alert
									key={message}
									type="warning"
									message={message}
									onClose={handleCleanNotification}
								/>
							))}
						{mensagensInfo.length > 0 &&
							mensagensInfo.map((message: string) => (
								<Alert
									key={message}
									type="info"
									message={message}
									onClose={() => setMensagensInfo([])}
								/>
							))}
						{dadosHabilitacao.listaCursos.length > 0 && (
							/* eslint-disable */
							<Alert
								type="info"
								message={
									<>
										<p>CURSOS REALIZADOS:</p>
										<Space direction="vertical" size={2}>
											{dadosHabilitacao.listaCursos.map(curso => {
												if (curso.dataValidade) {
													return (
														<Space size={5} key={curso.codigoCurso}>
															- {curso.descricaoCurso} - Validade:
															{formatDate(curso.dataValidade)}{' '}
															<CheckCircleTwoTone twoToneColor="#52c41a" />
														</Space>
													);
												}
												return (
													<Space size={5} key={curso.codigoCurso}>
														- {curso.codigoCurso}{' '}
														<CheckCircleTwoTone twoToneColor="#52c41a" />
													</Space>
												);
											})}
											<Text>
												INFORME O CANDIDATO: Se algum curso que ele realizou não
												estiver no sistema, aguardar inclusão, ou a 2a via será
												emitida sem a informação do curso.
											</Text>
										</Space>
									</>
								}
							/>
						)}
					</Space>
					{consultaToxicologico.data &&
						consultaToxicologico.data.resultado &&
						consultaToxicologico.data.resultado.trim() !== '' && (
						<AlertInvalidDrugTest consultaToxicologicoResponse={consultaToxicologico} />
						)}
					<DadosHabilitacao consultaRenovacaoCnh={dadosHabilitacao} />
					{exibePainelAtualizacao &&
						!preCadastro &&
						dadosPreCadastro.usoFormularioEnum !== 'permissaoInternacional' && (
							<DadosPreCadastro
								renderData={dadosPreCadastro}
								handleSubmit={handleSubmit}
								setMensagensInfo={setMensagensInfo}
								schema={schema}
							/>
						)}
					{exibePainelAtualizacao &&
						preCadastro &&
						dadosPreCadastro.usoFormularioEnum !== 'SEGUNDA_VIA' &&
						dadosPreCadastro.usoFormularioEnum !== 'CNH_DEFINITIVA' && (
							<ExibicaoPreCadastro
								renderData={dadosPreCadastro}
								handleSubmit={handleSubmitGerarPreCadastro}
								setPreCadastro={setPreCadastro}
							/>
						)}
					{dadosPreCadastro.usoFormularioEnum !== 'CASSACAO' &&
						dadosPreCadastro.usoFormularioEnum !== 'PERMISSIONARIO' && (
							<ConfirmacaoAtendimento
								showModal={modalAtendimento}
								response={res => {
									setModalAtendimento &&
										setModalAtendimento({
											...res,
											isOpenModal: false,
										});
									handleAfterModal &&
										handleAfterModal({
											...res,
											isOpenModal: false,
										});
								}}
							/>
						)}
				</Section>
			</Col>
		</Row>
	);
};

export default StatusCondutor;
