import styled from 'styled-components';

export const Container = styled.div`
	.ant-alert {
		.ant-alert-message {
			display: none;
			color: #000;
			font-size: calc(1rem + ${props => props.theme.fontScale}%);
			font-weight: 700;
		}

		.ant-alert-description {
			line-height: 24px;

			p {
				color: #000;
				font-size: calc(0.85rem + ${props => props.theme.fontScale}%);
				font-weight: 500;

				& + p {
					margin-top: 2px;
				}
			}
		}
	}
`;
