import * as Yup from 'yup';

// UTILS
import { validacaoData } from 'utils/genericFunctions';

import { IFormInclusaoObsPrimeiraViaMaior } from '../../form';

export const initialValues: IFormInclusaoObsPrimeiraViaMaior = {
	rgForaSp: false,
	rgForaSpUf: '',
	rgForaSpNum: '',
	rgForaSpEmissao: '',
	moraCom: '',
	cr: false,
	observacao: '',
	grauParentescoSp: '',
	ocupacaoPrincipalSp: '',
	resideSpAnos: '',
	resideSpMeses: '',
	temParentesSp: false,
	isencaoPrimeiraViaCin: true,
};

export const schema = Yup.object<IFormInclusaoObsPrimeiraViaMaior>().shape({
	rgUf: Yup.string()
		.when('rgForaSpUf', {
			is: rgForaSpUf => rgForaSpUf === 'SP',
			then: Yup.string().required('Campo Estado do RG: Não pode ser SP.'),
		})
		.when(['rgForaSpUf', 'rgForaSpNum', 'rgForaSpEmissao'], {
			is: (rgForaSpUf, rgForaSpNum, rgForaSpEmissao) =>
				rgForaSpUf === '' && (rgForaSpNum !== '' || rgForaSpEmissao !== ''),
			then: Yup.string().required('Campo Obrigatório: Estado do RG.'),
		}),
	rgNum: Yup.string().when(['rgForaSpUf', 'rgForaSpNum', 'rgForaSpEmissao'], {
		is: (rgForaSpUf, rgForaSpNum, rgForaSpEmissao) =>
			rgForaSpNum === '' && (rgForaSpUf !== '' || rgForaSpEmissao !== ''),
		then: Yup.string().required('Campo Obrigatório: Número do RG.'),
	}),
	rgExp: Yup.string().when(['rgForaSpUf', 'rgForaSpNum', 'rgForaSpEmissao'], {
		is: (rgForaSpUf, rgForaSpNum, rgForaSpEmissao) =>
			rgForaSpEmissao === '' && (rgForaSpUf !== '' || rgForaSpNum !== ''),
		then: Yup.string().required('Campo Obrigatório: Número do RG.'),
	}),
	moraCom: Yup.string().required('Campo Obrigatório: Mora com quem?'),
	temParentesSp: Yup.string().required(
		'Campo Obrigatório: Tem parentes em São Paulo?',
	),
	grauParentescoSp: Yup.string().when('temParentesSp', {
		is: temParentesSp => temParentesSp === 'true',
		then: Yup.string()
			.required('Campo Obrigatório: Grau de parentesco.')
			.max(15, 'Campo Grau de parentesco: Tamanho máximo de 15 caracteres.'),
	}),
	ocupacaoPrincipalSp: Yup.string()
		.max(12, 'Campo Ocupação em São Paulo: Tamanho máximo de 12 caracteres.')
		.required('Campo Obrigatório: Qual ocupação possui em São Paulo?'),
	// observacoes: Yup.string()
	// 	.max(338, 'Campo Observações: Tamanho máximo de 338 caracteres.')
	// 	.nullable(),
	validaDataRG: Yup.string().when(['rgForaSpEmissao'], {
		is: rgForaSpEmissao =>
			rgForaSpEmissao !== '' && validacaoData(rgForaSpEmissao) === false,
		then: Yup.string().required(
			`Data de expedição do RG não é uma data válida.`,
		),
	}),
});
