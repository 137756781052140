export enum Types {
	DADOS_EMISSAO_CIN_REQUEST = '@iirgd/DADOS_EMISSAO_CIN_REQUEST',
	DADOS_EMISSAO_CIN_SUCCESS = '@iirgd/DADOS_EMISSAO_CIN_SUCCESS',
	DADOS_EMISSAO_CIN_FAILURE = '@iirgd/DADOS_EMISSAO_CIN_FAILURE',
	DADOS_EMISSAO_CIN_CLEAR = '@iirgd/DADOS_EMISSAO_CIN_CLEAR',
}

export interface DadosEmissaoCINState {
	data: DadosEmissaoCIN | null;
	status: number;
}

export interface DadosEmissaoCINRequest {
	cpfUsuario: string;
	listaDeCpf: string;
}

interface ListaTelefone {
	tipoTelefone: number;
	ddi: number;
	telefone: number;
}

interface ListaCin {
	cpfUsuario: string;
	cpfCidadao: string;
	nome: string;
	nomeSocial: string;
	dataNascimento: string;
	protocoloOIC: string;
	sexo: string;
	codigoMunicipioNaturalidade: number;
	codigoPaisNaturalidadeExterior: string;
	codigoPaisNacionalidade: string;
	listaFiliacao: string;
	indicadorMaeRegistro: false;
	logradouro: string;
	numeroEndereco: string;
	complementoEndereco: string;
	bairroEndereco: string;
	cepResidencia: string;
	codigoPaisResidencia: string;
	codMunNaturalidade: string;
	codPaisNaturalidade: string;
	naturalidadeExterior: string;
	codigoMunicipioResidencia: string;
	localResidenciaExterior: string;
	listaEmail: string[];
	listaTelefone: ListaTelefone[];
	codigoMunicipioEmissao: number;
	foto: string;
	observacao: string;
	indicadorDeficienciaFisica: boolean;
	indicadorDeficienciaAuditiva: boolean;
	indicadorDeficienciaIntelectual: boolean;
	indicadorDeficienciaVisual: boolean;
	indicadorAutismo: boolean;
	doador: boolean;
	tituloEleitor: string;
	codigoTipoSanguineo: number;
	codigoEstadoCivil: number;
	certidaoNascimentoCasamento: string;
	averbacaoDivorcio: string;
	cnh: string;
	categoriaCnh: string;
	pisPasep: string;
	nis: string;
	nit: string;
	carteiraTrabalho: string;
	cartaoNacionalSaude: string;
}

interface RetornoConsulta {
	msgRetorno: string;
	msgInformativa: string;
	cpfContribuinte: string;
	codSitCad: string;
	descSitCad: string;
	dataSitCad: string;
	nomeContribuinte: string;
	nomeSocial: string;
	nomeMae: string;
	dataNascimento: string;
	dataInscricao: string;
	anoObito: string;
	codUFEndereco: string;
	codMunicEnderecoIBGE: string;
	nomeMunicEndereco: string;
	cep: string;
	tipoLogradouro: string;
	logradouro: string;
	nroLogradouro: string;
	compLogradouro: string;
	bairroLogradouro: string;
	indEstrangeiro: string;
	codPaisNacionalidade: string;
	nomePaisNacionalidade: string;
	indResidExt: string;
	codPaisResidExt: string;
	nomePaisResidExt: string;
	ddd: string;
	nroTel: string;
	nroCel: string;
	dataUltimaAtual: string;
	horaUltimaAtual: string;
	listaCin: ListaCin[];
}

export interface DadosEmissaoCIN {
	codRetorno: string;
	msgRetorno: string;
	qtdeRegistrosRetornados: string;
	retornoConsultada: RetornoConsulta[];
}
