import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Modal } from 'antd';

// ROUTES
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// REDUX
// import { consultaPermissaoInternacionalRequest } from 'store/modules/api/detranCnh/permissaoInternacional/consultaPermissaoInternacional/actions';
import { consultaEPortalRequest } from 'store/modules/api/detranCnh/permissaoInternacional/consultaEPortal/actions';
import { pesquisaEnderecoRecebimentoRequest } from 'store/modules/api/detranCnh/condutor/pesquisaEnderecosRecebimento/actions';
import { consultaCEPRequest } from 'store/modules/api/detranCnh/consulta/cep/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import Radio from 'components/Common/Form/Radio';
import Pesquisa from 'pages/DetranCnh/components/pages/PreCadastro/Pesquisa';
import Loader from 'components/Common/Loader';

// FORM
import { treatValues } from 'pages/DetranCnh/components/pages/PreCadastro/Pesquisa/form';
import { threatValuesStartSessionEventRequest } from 'utils/functions/threatEventsRequest';
import { eventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/actions';

// ROUTES
import { ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_STATUS_CONDUTOR } from 'pages/DetranCnh/routes/paths';

let valuesRequest: any = {
	senha: '',
	usuario: '',
};

const PesquisaPermissaoInternacional: React.FC = () => {
	// const [modalVisible, setModalVisible] = useState<boolean>(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const [modalProcurador, setModalProcurador] = useState('N');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const { consultaEPortal } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.permissaoInternacional,
	);

	const { pesquisaEnderecosRecebimento } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { mainframe } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const handleSearchUser = useCallback(() => {
		dispatch(consultaEPortalRequest(valuesRequest));
		dispatch(
			pesquisaEnderecoRecebimentoRequest({
				cpf: valuesRequest.cpf.replace(/[.-]+/g, ''),
			}),
		);
		setIsModalOpen(false);
		setLoading(true);
	}, [dispatch]);

	const handleSubmit = useCallback(
		(values: any) => {
			valuesRequest = treatValues(values, {
				usuario: mainframe.idUsuario,
				senha: mainframe.senhaMainFrame,
			});

			const valuesStartSessionEvent = threatValuesStartSessionEventRequest(
				atendimento,
				loginUnico,
			);

			dispatch(eventoInicioSessaoRequest(valuesStartSessionEvent));
			setIsModalOpen(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[mainframe.idUsuario, mainframe.senhaMainFrame],
	);

	useEffect(() => {
		if (consultaEPortal.status === 200 && consultaEPortal.data) {
			setLoading(false);
			if (Object.values(consultaEPortal.data).length > 0) {
				dispatch(
					consultaCEPRequest({ cep: consultaEPortal.data.endereco.cep }),
				);
			}
			if (pesquisaEnderecosRecebimento.status === 200) {
				history.push(ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_STATUS_CONDUTOR);
			}
		} else {
			setLoading(false);
		}
	}, [
		consultaEPortal.status,
		consultaEPortal.data,
		dispatch,
		history,
		pesquisaEnderecosRecebimento.status,
	]);

	return (
		<>
			{isLoading && <Loader />}
			<Pesquisa
				handleSubmit={handleSubmit}
				routeBackButton={ROUTE_DETRAN_CNH}
			/>
			<Modal
				title="Você é procurador de algum cidadão?"
				visible={isModalOpen}
				onOk={handleSearchUser}
				okText="Prosseguir"
				cancelButtonProps={{ hidden: true }}
				onCancel={() => setIsModalOpen(false)}
			>
				<Radio
					name="procurador"
					value={modalProcurador}
					options={[
						{ value: 'S', label: 'Sim' },
						{ value: 'N', label: 'Não' },
					]}
					onChange={v => setModalProcurador(v.target.value)}
				/>
			</Modal>
		</>
	);
};
export default PesquisaPermissaoInternacional;
