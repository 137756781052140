import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import Section from 'components/Common/Section';
import ColorBox from 'components/Common/ColorBox';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import PDFViewer from 'components/Common/PDFViewer';

// UTILS
import { patternFormat } from 'utils/genericFunctions';
import { consultaReimpressaoFicClear } from 'store/modules/api/iirgd/consultaReimpressaoFic/actions';

const ReimpressaoProtocolo: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { consultaReimpressaoFic } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const { protocoloFic, protocoloFicDigital } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const [PDFTitle, setPDFTitle] = useState<string>(
		'REIMPRESSÃO DO PROTOCOLO FIC',
	);
	const [protocoloBase64, setProtocoloBase64] = useState<string>('');

	useEffect(() => {
		if (consultaReimpressaoFic.status !== 200) {
			dispatch(consultaReimpressaoFicClear());

			history.push(ROUTE_IIRGD);
		}
	}, [consultaReimpressaoFic.status, protocoloFicDigital, dispatch, history]);

	useEffect(() => {
		if (protocoloFic.status === 200 && protocoloFic.data) {
			// setPDFTitle('REIMPRESSÃO DO PROTOCOLO FIC');
			setProtocoloBase64(protocoloFic.data.arquivoPdf);
		}
	}, [protocoloFic]);

	const handleImprimir = useCallback(() => {
		if (
			consultaReimpressaoFic.status === 200 &&
			consultaReimpressaoFic.data.arquivoPdf
		) {
			setPDFTitle('REIMPRESSÃO DO PROTOCOLO FIC DIGITAL');
			setProtocoloBase64(consultaReimpressaoFic.data.arquivoPdf);
		}
	}, [consultaReimpressaoFic.data.arquivoPdf, consultaReimpressaoFic.status]);

	const handleVoltar = useCallback(() => {
		dispatch(consultaReimpressaoFicClear());

		history.push(ROUTE_IIRGD);
	}, [dispatch, history]);

	const handleOnClose = useCallback(() => {
		setPDFTitle('');
		setProtocoloBase64('');
	}, []);

	return (
		<>
			{consultaReimpressaoFic.data && (
				<>
					<Section title="Informações para Impressão">
						<Row>
							<Col span={24}>
								<ColorBox
									bgColor="verde"
									successTitle="Informações localizadas com sucesso."
									title
								>
									<Row gutter={[0, 10]}>
										<Col>
											<p>
												<strong>RGE CIN:</strong>
												<span style={{ marginLeft: '5px' }}>
													{patternFormat(
														consultaReimpressaoFic.numeroRg,
														'##.###.###-##',
													)}
												</span>
											</p>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col>
											<p>
												A continuidade deste processo só poderá ser realizada
												neste Posto de Atendimento.
											</p>
										</Col>
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="imprimir-protocolo"
												onClick={handleImprimir}
											/>
										</Col>
									</Row>
								</ColorBox>
							</Col>
						</Row>
					</Section>

					<Row justify="space-between">
						<Col>
							<ButtonVoltar navigate={false} onClick={handleVoltar} />
						</Col>

						<Col style={{ marginTop: '13px' }}>
							<ButtonImage
								type="button"
								src="outro-servico-iirgd"
								onClick={handleVoltar}
							/>
						</Col>
					</Row>

					{PDFTitle && protocoloBase64 && (
						<PDFViewer
							title={PDFTitle}
							source={protocoloBase64}
							onClose={handleOnClose}
						/>
					)}
				</>
			)}
		</>
	);
};

export default ReimpressaoProtocolo;
