import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ExcluirPerfilRequest } from './types';

// ACTIONS
import { excluirPerfilSuccess, excluirPerfilFailure } from './actions';

function* excluirPerfil(request: ReducerAction) {
	const { payload }: { payload: ExcluirPerfilRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_SGU,
		`perfis/${payload.codigo}`,
	);

	if (response.status === 200) {
		yield put(excluirPerfilSuccess(response));
	} else {
		yield put(excluirPerfilFailure());
	}
}

export default all([takeLatest(Types.EXCLUIR_PERFIL, excluirPerfil)]);
