export enum Types {
	BUSCA_PAGINADA_FORNECEDORES_REQUEST = '@procon/BUSCA_PAGINADA_FORNECEDORES_REQUEST',
	BUSCA_PAGINADA_FORNECEDORES_SUCCESS = '@procon/BUSCA_PAGINADA_FORNECEDORES_SUCCESS',
	BUSCA_PAGINADA_FORNECEDORES_FAILURE = '@procon/BUSCA_PAGINADA_FORNECEDORES_FAILURE',
	BUSCA_PAGINADA_FORNECEDORES_CLEAR = '@procon/BUSCA_PAGINADA_FORNECEDORES_CLEAR',
}

export interface BuscaPaginada {
	status: null | number;
	data: null | BuscaPaginadaData;
}

interface BuscaPaginadaData {
	mensagem: string;
	data: {
		total: number;
		paginaAtual: number;
		qtdItensPorPagina: number;
		itens: Fornecedor[];
	};
}

export interface Fornecedor {
	codigo: number;
	nome: string;
	nomeFantasia: string;
	cnpj: string;
	cpf: string;
	codigoCnae: string;
	status: true;
	statusFormatado: string;
	codigoCnaeFormatado: string;
	numDocumento: string;
	numDocumentoFormatado: string;
	idPai: number;
	enderecoFormatado: string;
	nomeFormatado: string;
	id: number;
	acoes: string;
	podeEditar: true;
	caracteristicas: {
		destaque: true;
		colunaCentralizar: number;
		podeSelecionar: true;
	};
	cpfCnpj: {
		cpf: string;
		cnpj: string;
	};
}

export interface BuscaPaginadaRequest {
	nome?: string;
	numDocumento?: string;
	inicio: number;
	qtdItensPorPagina: number;
}
