/* eslint-disable no-unused-expressions */
import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, EncerrarAtendimento } from './types';

export const INITIAL_STATE: EncerrarAtendimento = {
	status: 0,
	mensagem: '',
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): EncerrarAtendimento {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ENCERRAR_ATENDIMENTO_REQUEST: {
				break;
			}

			case Types.ENCERRAR_ATENDIMENTO_SUCCESS: {
				draft.status = 200;
				draft.mensagem = action.payload.mensagem;
				break;
			}

			case Types.ENCERRAR_ATENDIMENTO_FAILURE: {
				draft.status = 400;
				// draft.mensagemRetorno = action.payload;
				break;
			}
			case Types.ENCERRAR_ATENDIMENTO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.mensagem = INITIAL_STATE.mensagem;
				break;
			}

			default:
		}

		return draft;
	});
}
