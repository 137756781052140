import React, { useCallback } from 'react';

// ANTD
import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_OUTRAS_BASES_CONSULTAR } from 'pages/DetranCrv/routes/paths';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { consultarPEBEClear } from 'store/modules/api/detranCrv/ConsultarResumoExtrato/pebe/actions';
import { consultarPTRESClear } from 'store/modules/api/detranCrv/ConsultarResumoExtrato/ptres/actions';

// COMPONENTS COMMON
import Collapse from 'components/Common/Collapse';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// COMPONENTS
import ExtratoPEBE from './PEBE';
import ExtratoPTRES from './PTRES';

const ConsultarResumoExtratoOutrasBasesResultado: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const { pebe, ptres } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarResumoExtrato,
	);

	const contents = [
		{
			key: 'ptres',
			title: 'Consulta Simples (PTRE)',
			content: <ExtratoPTRES data={ptres} />,
		},
		{
			key: 'pebe',
			title: 'Base Outros Estados (PEBE)',
			content: <ExtratoPEBE data={pebe} />,
		},
	];

	const handleBack = useCallback(() => {
		dispatch(clearNotifications());

		dispatch(consultarPEBEClear());

		dispatch(consultarPTRESClear());

		history.push(ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_OUTRAS_BASES_CONSULTAR);
	}, [history, dispatch]);

	return (
		<>
			<Row>
				<Col span={24}>
					<Section size="lg" title="Resultado" titleSize="sm">
						<Collapse contents={contents} />
					</Section>

					<ButtonVoltar navigate={false} onClick={handleBack} />
				</Col>
			</Row>
		</>
	);
};

export default ConsultarResumoExtratoOutrasBasesResultado;
