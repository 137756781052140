import { EventoContabilizacao } from 'store/modules/types';
import { GerarRenachForm } from '../../sockets/gerarRenach/types';

export enum Types {
	GERAR_RENACH_INTEGRADO_REQUEST = '@cnh/GERAR_RENACH_INTEGRADO_REQUEST',
	GERAR_RENACH_INTEGRADO_SUCCESS = '@cnh/GERAR_RENACH_INTEGRADO_SUCCESS',
	GERAR_RENACH_INTEGRADO_FAILURE = '@cnh/GERAR_RENACH_INTEGRADO_FAILURE',
	GERAR_RENACH_INTEGRADO_CLEAR = '@cnh/GERAR_RENACH_INTEGRADO_CLEAR',
}

export interface GerarRenachIntegrado {
	data: null | GerarRenachIntegradoData;
	dataError?: null | GerarRenachIntegradoDataError;
	requestData: null | GerarRenachIntegradoRequest;
	status: number;
}

export interface GerarRenachIntegradoData {
	categoriaPretendida: string;
	codigoInterno: string;
	cpfCidadao: string;
	crm: string;
	crp: string;
	dataExame: string;
	dataExamePsicologo: string;
	enderecoMedico: string;
	enderecoPsicologo: string;
	horarioExame: string;
	horarioExamePsicologo: string;
	message: string;
	nomeCidadao: string;
	nomeMedico: string;
	nomePsicologo: string;
	nomeSocialCidadao: string;
	paisAfetivos1: string;
	paisAfetivos2: string;
	renach: string;
	retornoBCA: string;
	telefones: string;
	telefonesPsicologo: string;
	tipoProcesso: {
		codigo: string;
		descricao: string;
	};
}

interface GerarRenachIntegradoDataError {
	transacao: string;
	codigo: number;
	mensagem: string;
}

interface GerarRenachIntegradoBase {
	evento?: EventoContabilizacao;
	usoFormularioEnum: string;
	cpf: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	sexo: string;
	dataNascimento: string;
	tipoDocumentoEnum: string;
	numDocumento?: string;
	orgaoExpDocumento?: string;
	orgaoUfExpDocumento?: string;
	nacionalidade: string;
	codigoNaturalidade: string | number;
	email?: string;
	celularDDD: string;
	celular?: string;
	logradouro: string;
	logradouroNumero?: string | null;
	logradouroComplemento?: string;
	logradouroBairro?: string;
	logradouroCodMunicipio?: string;
	logradouroCep?: string;
	codCiretranCondutor?: string;
	statusReusoEnum?: string;
	flagAtividadeRemunerada?: boolean | string;
	flagDeficienteFisico?: boolean | string;
	flagSolicitaCNHDigital?: boolean | string;
	codigoVerificador: string | null;
	categoriaAtual: string;
	categoriaPretendida: string;
	registro?: string | null;
	numeroPgu?: string | null;
	rneNumero?: string | null;
	rneOrgao?: string | null;
	rneUf?: string | null;
	rneCodPaisCnhEstrangeira?: string | null;
	rneDataValidadeCnhEstrangeira?: string | null;
	rneDataPrimeiraHabilitacaoCnhEstrangeira?: string | null;
	rneIdentificacaoCnhEstrangeira?: string | null;
	flagMilitar?: boolean | string;
	codEntidadeMilitar?: string;
	numeroIdentMilitar?: string;
	flagExamePosto?: boolean | string;
	flagExameToxicologico?: boolean | string;
	flagProcurador?: boolean | string;
	codCiretranHabilitacao?: string;
	cpfUsuario?: string;
	codLocalidade?: string;
	nomeSocial?: string;
	paisAfetivo1?: string;
	paisAfetivo2?: string;
	validacaoNomeSocial?: string;
	flagBiometriaNomeSocial?: boolean;
	flagBiometriaFiliacaoAfetiva1?: boolean;
	flagBiometriaFiliacaoAfetiva2?: boolean;
	tipoLocalidadeUsuario?: string;
	dataPrimeiraHabilitacao: string;
	telefone?: string;

	ciretranCandidato?: number;
	codigoInterno?: string;
	cpfCidadao?: string;
	crm?: number;
	crp?: number;
	dataExame?: string;
	dataExamePsicologo?: string;
	dataGeracaoRenach?: string;
	dataRealizacaoExame?: string;
	dataRealizacaoExamePsicologo?: string;
	enderecoMedico?: string;
	enderecoPsicologo?: string;
	horarioExame?: string;
	horarioExamePsicologo?: string;
	horarioRealizacaoExame?: string;
	horarioRealizacaoExamePsicologo?: string;
	ident?: string;
	message?: string;
	nomeCidadao?: string;
	nomeMedico?: string;
	nomePsicologo?: string;
	nomeSocialCidadao?: string;
	paisAfetivos1?: string;
	paisAfetivos2?: string;
	pretendeAtividadeRemunerada?: string;
	renach?: string;
	restricoesExameMedico?: string;
	resultadoExameMedico?: string;
	resultadoExamePsicologico?: string;
	statusExameMedico?: string;
	statusExamePsicologico?: string;
	telefones?: string;
	telefonesPsicologo?: string;
	naturalidade?: string;
	tipoProcesso?: {
		codigo: string;
		descricao: string;
	};
}

export interface GerarRenachIntegradoForm extends GerarRenachIntegradoBase {
	flagNomeSocial?: string;
	flagFiliacaoAfetiva?: string;
	uf?: string;
	confirmaEmail?: string;
	autorizaSMS?: string;
	autorizaEmail?: string;
	telefone?: string;
	logradouroDescMunicipio?: string;
	enderecoRecebimentoNumero?: string;
	enderecoRecebimentoBairro?: string;
	enderecoRecebimentoCep?: string;
	enderecoRecebimentoLogradouro?: string;
	enderecoRecebimentoComplemento?: string;
	enderecoRecebimentoMunicipio?: string;
	enderecoRecebimentoCodigoMunicipio?: string;
}

export interface DadosExameMedico {
	codigoCiretranExameMedico: string;
	dataExame: string;
	horarioExame: string;
	idMedico: string;
	idMicroRegiaoMedico: string;
}

export interface DadosExamePsicologo {
	codigoCiretranExamePsicologo: string;
	dataExamePsicologico: string;
	horarioExamePsicologico: string;
	idMicroRegiaoPsicologo: string;
	idPsicologo: string;
}

export interface GerarRenachIntegradoRequest {
	candidato: {
		apresentaDocumentos: string;
		autorizaEmail: string;
		autorizaSMS: string;
		bairro: string;
		categoriaAtual: string;
		categoriaPretendida: string;
		codigoUnidadeAtendimento: string;
		celular: string;
		cep: string;
		cnhDigital: string;
		codigoCiretranPostoExameMedico: string;
		codigoEntidade: string;
		codigoVerificador: string;
		coletaBiometrica: string;
		complemento: string;
		cpf: string;
		cpfUsuario?: string;
		dataNascimento: string;
		dataPrimeiraHabilitacao?: string;
		dataValidadeCnhE: string;
		dddCelular: string;
		dddTelefone: string;
		email: string;
		logradouro: string;
		nacionalidade: string;
		naturalidade: string;
		necessidadeVeiculoAdaptado?: string;
		nome: string;
		nomeMae: string;
		nomePai: string;
		nomeSocial: string;
		nomeSocialValidadoBiometria: string;
		origemNomeSocial: string;
		paisAfetivos1: string;
		paisAfetivos1ValidadoBiometria: string;
		paisAfetivos2: string;
		paisAfetivos2ValidadoBiometria: string;
		numCNHE: string;
		numCarteiraMilitar: string;
		numDocumento: string;
		numPGU: string;
		numRNE: string;
		numRegistro: string;
		numero: string;
		orgaoDocumento: string;
		orgaoRNE: string;
		paisCNHE: string;
		pretendeAtividadeRemunerada: string;
		sexo: string;
		telefone: string;
		testeAlfabetizacao: string;
		tipoDocumento: string;
		tipoProcesso: string;
		uf: string;
		ufDocumento: string;
		ufRNE: string;
	};
	dadosExameMedico: DadosExameMedico;
	dadosExamePsicologo: DadosExamePsicologo;
	gerarRenachBase?: GerarRenachForm;
	evento?: EventoContabilizacao;
}
