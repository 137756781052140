import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, PesquisaNominal } from './types';

export const INITIAL_STATE: PesquisaNominal = {
	tipoDocApresentado: '',
	ufRg: '',
	numeroDocumento: '',
	dataExpedicao: '',
	nome: '',
	nomePai: '',
	nomeMae: '',
	sexo: '',
	dataNascimento: '',
	requerente: false,
	nomeProcurador: '',
	docProcurador: '',
	posto: '',
	ipEstacao: '',
	usuario: '',
	dc: '',
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): PesquisaNominal {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PESQUISA_NOMINAL_REQUEST: {
				// eslint-disable-next-line no-param-reassign
				draft = action.payload;
				break;
			}

			case Types.LIMPA_PESQUISA_NOMINAL: {
				// eslint-disable-next-line no-param-reassign
				draft = INITIAL_STATE;
				break;
			}

			default:
		}

		return draft;
	});
}
