// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function cdhuClear(): ReducerAction {
	return {
		type: Types.CDHU_CLEAR,
		payload: null,
	};
}
