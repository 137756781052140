import { ReducerAction } from 'store/modules/types';

import { AtualizarNaturalidadeRequest, Types } from './types';

export function atualizarNaturalidadeRequest(
	payload: AtualizarNaturalidadeRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_NATURALIDADE,
		payload,
	};
}
export function atualizarNaturalidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_NATURALIDADE_SUCCESS,
		payload,
	};
}
export function atualizarNaturalidadeFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_NATURALIDADE_FAILURE,
		payload: null,
	};
}
export function atualizarNaturalidadeClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_NATURALIDADE_CLEAR,
		payload: null,
	};
}
