import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarUsuarioRequest } from './types';

// ACTIONS
import { cadastrarUsuarioSuccess, cadastrarUsuarioFailure } from './actions';

function* cadastrarUsuario(request: ReducerAction) {
	const { payload }: { payload: CadastrarUsuarioRequest } = request;

	const newPayload = { ...payload };

	delete newPayload.id;

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU,
		`usuarios`,
		newPayload,
		{
			id_usuario: payload.id,
		},
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarUsuarioSuccess(response));
	} else {
		yield put(cadastrarUsuarioFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_USUARIO_REQUEST, cadastrarUsuario),
]);
