export enum Types {
	TROCAR_SENHA_USUARIO = '@SGU/TROCAR_SENHA_USUARIO',
	TROCAR_SENHA_USUARIO_SUCCESS = '@SGU/TROCAR_SENHA_USUARIO_SUCCESS',
	TROCAR_SENHA_USUARIO_FAILURE = '@SGU/TROCAR_SENHA_USUARIO_FAILURE',
	TROCAR_SENHA_USUARIO_CLEAR = '@SGU/TROCAR_SENHA_USUARIO_CLEAR',
}

export interface TrocarSenhaUsuario {
	status: number;
	data: null | ITrocarSenhaUsuario;
}

export interface ITrocarSenhaUsuario {
	status: string;
	statusMensagem: string;
}

export interface TrocarSenhaUsuarioRequest {
	id?: number | string;
}
