import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { Types, IRequestWithHeaderLocalidadeEdit } from './types';

// ACTIONS
import {
	atualizarLocalidadeSuccess,
	atualizarLocalidadeFailure,
} from './actions';

function* atualizarLocalidade(request: {
	type: string;
	payload: IRequestWithHeaderLocalidadeEdit;
}) {
	const { payload }: { payload: IRequestWithHeaderLocalidadeEdit } = request;

	const { body, headers } = { ...payload };

	const idLocalidade = body.id;

	delete body.id;
	delete body.idUsuario;

	// ID USUÁRIO MOCADO
	const response: ApiResponse = yield call(
		putApi,
		PATH_SGU,
		`localidades/${idLocalidade}`,
		body,
		headers,
	);

	if (response.status === 200) {
		yield put(atualizarLocalidadeSuccess(response));
	} else {
		yield put(atualizarLocalidadeFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_LOCALIDADE_REQUEST, atualizarLocalidade),
]);
