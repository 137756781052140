import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Col, Row } from 'antd';

import Section from 'components/Common/Section';
import Alert from 'components/Common/Notifications/Alert';

import { ApplicationState } from 'store';
import {
	consultaTermoAdvertencia,
	imprimeTermoAdvertencia,
	clearPdfTermoAdvertencia,
	cadastraTermoAdvertencia,
} from 'store/modules/api/seds/vivaLeite/documentos/actions';

import { v4 } from 'uuid';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import PDFViewer from 'components/Common/PDFViewer';
import {
	InitialCadastraTermoAdvertencia,
	InitialImpressao,
	InitialTermoAdvertencia,
} from './form';
import Padrao from '../components/pages/Padrao';

const TermoAdvertencia = () => {
	const { termoAdvertencia, pdfTermoAdvertencia } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.documentos,
	);
	const { selectedUser, selectedCPF } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.usuario,
	);

	const [prosseguir, setProsseguir] = useState(false);
	const [imprimirRequired, setImprimirRequired] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();

	const handlePrint = useCallback(() => {
		const requestData = InitialImpressao;
		requestData.num_pan = selectedUser.numeroPan;
		requestData.cpf = selectedCPF.toString();

		dispatch(imprimeTermoAdvertencia(requestData));
	}, [dispatch, selectedCPF, selectedUser.numeroPan]);

	const handleClear = useCallback(() => {
		dispatch(clearPdfTermoAdvertencia());
	}, [dispatch]);

	const handleNext = useCallback(() => {
		if (!prosseguir) {
			setImprimirRequired(true);
			return;
		}
		const requestData = InitialCadastraTermoAdvertencia;
		requestData.num_pan = selectedUser.numeroPan;
		requestData.cod_termo_advertencia = termoAdvertencia.codigo_ciencia;

		dispatch(cadastraTermoAdvertencia(requestData));
		history.push('/seds/vivaleite/prestacaoDeContas/termoDeAdvertencia');
	}, [
		dispatch,
		history,
		prosseguir,
		selectedUser.numeroPan,
		termoAdvertencia.codigo_ciencia,
	]);

	useEffect(() => {
		const requestData = InitialTermoAdvertencia;
		requestData.num_pan = selectedUser.numeroPan;
		requestData.cpf = selectedCPF.toString();

		dispatch(consultaTermoAdvertencia(requestData));
	}, [dispatch, selectedCPF, selectedUser.numeroPan]);

	useEffect(() => {
		if (pdfTermoAdvertencia !== '') {
			setProsseguir(true);
		}
	}, [dispatch, pdfTermoAdvertencia]);

	return (
		<>
			{imprimirRequired && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Alert
							type="error"
							message="Imprima o termo de advertência!"
							onClose={() => setImprimirRequired(false)}
						/>
					</Col>
				</Row>
			)}
			<Padrao>
				<>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Section
								size="sm"
								titleBold={false}
								title={termoAdvertencia.titulo}
							>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<p>{termoAdvertencia.introducao}</p>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										{termoAdvertencia.pendencias.map((termo: string) => (
											<p key={v4()}>{termo}</p>
										))}
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<ButtonImage
											type="button"
											src="imprimir"
											onClick={handlePrint}
										/>
									</Col>
								</Row>
							</Section>
						</Col>
					</Row>
					<Row gutter={[0, 10]} justify="space-between" align="bottom">
						<Col>
							<ButtonVoltar />
						</Col>
						<Col>
							<ButtonImage
								src="prosseguir"
								type="button"
								onClick={handleNext}
							/>
						</Col>
					</Row>
					{pdfTermoAdvertencia !== '' && (
						<PDFViewer
							title="pdfVivaLeite"
							source={pdfTermoAdvertencia}
							onClose={handleClear}
						/>
					)}
				</>
			</Padrao>
		</>
	);
};

export default TermoAdvertencia;
