import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, pesquisaCitaAAC, DataPesquisaCITA } from './types';

export const INITIAL_STATE: pesquisaCitaAAC = {
	status: 0,
	rgPesquisado: null,
	data: {} as DataPesquisaCITA,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): pesquisaCitaAAC {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PESQUISA_CITA_AAC_REQUEST: {
				draft.rgPesquisado = action.payload.numeroRg;
				break;
			}

			case Types.PESQUISA_CITA_AAC_SUCCESS: {
				draft.status = 200;
				const newData: DataPesquisaCITA = action.payload[0];
				draft.data = newData;
				break;
			}

			case Types.PESQUISA_CITA_AAC_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.LIMPA_PESQUISA_CITA_AAC: {
				draft.rgPesquisado = null;
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}

		return draft;
	});
}
