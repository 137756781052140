import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { EnviarSolicitacoesPortal, Types } from './types';

export const INITIAL_STATE: EnviarSolicitacoesPortal = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): EnviarSolicitacoesPortal {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ENVIAR_SOLICITACOES_PORTAL_REQUEST: {
				draft.status = 100;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.ENVIAR_SOLICITACOES_PORTAL_SUCCESS: {
				draft.data = action.payload.response;
				draft.status = 201;
				break;
			}

			case Types.ENVIAR_SOLICITACOES_PORTAL_FAILURE: {
				draft.data = action.payload.response;
				draft.status = 400;
				break;
			}
			case Types.ENVIAR_SOLICITACOES_PORTAL_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
