export enum Types {
	CADASTRAR_CONTRATO_REQUEST = '@BOLSA-POVO/CADASTRAR_CONTRATO_REQUEST',
	CADASTRAR_CONTRATO_SUCCESS = '@BOLSA-POVO/CADASTRAR_CONTRATO_SUCCESS',
	CADASTRAR_CONTRATO_FAILURE = '@BOLSA-POVO/CADASTRAR_CONTRATO_FAILURE',
	LIMPAR_CADASTRAR_CONTRATO = '@BOLSA-POVO/LIMPAR_CADASTRAR_CONTRATO',
}

export interface CadastrarContato {
	status: number;
	form: CadastrarContatoRequest;
}

export interface CadastrarContatoRequest {
	idCpf: string;
	idTipoContato: number | null | string;
	valor: string;
	id?: number;
}

export interface CadastrarContatoForm {
	idTipoContato: number | null | string;
	valor: string;
	valores?: string;
}
