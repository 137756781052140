import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	MICRO_REGIOES = '@cnh/MICRO_REGIOES',
	MICRO_REGIOES_SUCCESS = '@cnh/MICRO_REGIOES_SUCCESS',
	MICRO_REGIOES_FAILURE = '@cnh/MICRO_REGIOES_FAILURE',
	MICRO_REGIOES_CLEAR = '@cnh/MICRO_REGIOES_CLEAR',
}

export interface microRegioes {
	status: number;
	data: OptionProps[] | [];
}

export interface IRequestMicroRegioes {
	cpf_cidadao: number | string;
	tipo_exame: string;
	is_portador_necessidades_especiais: boolean;
	id_regiao_medico: number;
	id_regiao_psicologo: number;
	usuario: string;
}
