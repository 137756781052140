import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { IEscolherPlaca } from 'pages/DetranCrv/SolicitarDocumentos/EscolherPlaca/form';
import { Types } from './types';
import { escolherPlacaSuccess, escolherPlacaFailure } from './actions';

function* escolherPlacaRequest(request: {
	type: string;
	payload: IEscolherPlaca;
}) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/escolha-placa/atribuidas`,
		{
			chassi: payload.chassi,
			cpfCnpjProprietario: payload.cpfCnpjProprietario,
		},
	);
	if (response.status === 200 || response.status === 201) {
		yield put(escolherPlacaSuccess(response));
	} else if (response.error) {
		yield put(escolherPlacaFailure(response.message));
	}
}

export default all([
	takeLatest(Types.ESCOLHER_PLACA_REQUEST, escolherPlacaRequest),
]);
