// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function classificacoesRequest(): ReducerAction {
	return {
		type: Types.CLASSIFICACOES_REQUEST,
		payload: null,
	};
}
export function classificacoesSuccess(payload: object): ReducerAction {
	return {
		type: Types.CLASSIFICACOES_SUCCESS,
		payload,
	};
}
export function classificacoesFailure(payload: object): ReducerAction {
	return {
		type: Types.CLASSIFICACOES_FAILURE,
		payload,
	};
}
export function classificacoesClear(): ReducerAction {
	return {
		type: Types.CLASSIFICACOES_CLEAR,
		payload: null,
	};
}
