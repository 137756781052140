// TYPES
import { ReducerAction } from 'store/modules/types';
import { ApiResponse } from 'services/_api';
import { Types, ConsultarProcesso, ConsultarProcessoRequest } from './types';

export function consultarProcessoRequest(
	payload: ConsultarProcessoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_PROCESSO_REQUEST,
		payload,
	};
}
export function consultarProcessoSuccess(
	payload: ConsultarProcesso,
): ReducerAction {
	return {
		type: Types.CONSULTAR_PROCESSO_SUCCESS,
		payload,
	};
}
export function consultarProcessoFailure(payload: ApiResponse): ReducerAction {
	return {
		type: Types.CONSULTAR_PROCESSO_FAILURE,
		payload,
	};
}
export function consultarProcessoClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_PROCESSO_CLEAR,
		payload: null,
	};
}
