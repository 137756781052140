import React, { useCallback, useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { Field, Form, Formik } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';
import {
	ROUTE_IIRGD_DARE_KIT_FAMILIA,
	ROUTE_IIRGD_DARE_SUCESSO,
} from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultarDareCpfRequest,
	consultarDareGuiaRequest,
	consultarDareClear,
} from 'store/modules/api/dare/consultarDare/actions';
import { dependentesDareCpfPrincipal } from 'store/modules/api/dare/dependentesDare/actions';
import {
	IRequestGerarDare,
	gerarDareRequest,
	gerarDareClear,
} from 'store/modules/api/gerarDare/actions';

// COMPONENTS
import InputMask from 'components/Common/Form/Input/InputMask';
// import RG from 'components/Common/Form/Fields/RG';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { limparMascara } from 'utils/genericFunctions';

// FORM
import { IParametrosLocalidade } from 'store/modules/api/sgu/loginUnico/types';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { IFormDARE, initialValues, schema } from './form';

// COMPONENTS
import Atendimento from './Atendimento';

const Formulario: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { login, user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { gerarDare } = useSelector((state: ApplicationState) => state.api);

	const { consultarDare } = useSelector(
		(state: ApplicationState) => state.api.dare,
	);

	const { alterarObservacoes, cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const [hasLoaded, setHasLoaded] = useState<boolean>(false);
	const [hasSubmit, setHasSubmit] = useState<boolean>(false);

	const [cpf, setCpf] = useState<string>('');

	// Seta o Número da DARE gerada.
	useEffect(() => {
		if (!hasSubmit) {
			if (!cadastroRg.form.principal) {
				history.push(ROUTE_IIRGD);
				return;
			}

			if (cadastroRg.form.principal.entrega === 'K') {
				initialValues.dare = gerarDare.dareKit?.numDare
					? String(gerarDare.dareKit.numDare)
					: '';
			} else {
				initialValues.dare = '';
			}
			dispatch(gerarDareClear());
			setHasLoaded(true);
		}
	}, [
		hasSubmit,
		cadastroRg.form.principal,
		gerarDare.data,
		dispatch,
		history,
		gerarDare.dareKit,
	]);

	// Redirecionamento para tela de Sucesso.
	useEffect(() => {
		if (hasSubmit && gerarDare.data) {
			history.push(ROUTE_IIRGD_DARE_SUCESSO);
		}
	}, [hasSubmit, gerarDare.data, history]);

	useEffect(() => {
		dispatch(consultarDareClear());
	}, [dispatch]);

	const handleGerarDare = useCallback(() => {
		if (cadastroRg.form.principal) {
			const {
				numeroRg,
				enderecoResidencia,
				entrega,
			} = cadastroRg.form.principal;

			if (typeof entrega === 'number') {
				dispatch(dependentesDareCpfPrincipal(cpf));
				history.push(ROUTE_IIRGD_DARE_KIT_FAMILIA);
				return;
			}

			const values: IRequestGerarDare = {
				cpf: limparMascara(cpf),
				enderecoContribuinte: enderecoResidencia,
				postoCodigo: Number(login.user.posto),
				postoDescricao: login.user.nomePosto,
				ipUsuario: login.user.ip,
				// emailTo: email,
				taxasReq: [],
				idCidadao: cadastroRg.form.principal.numeroRg,
				usuario: login.mainframe.idUsuario,
				senha: login.mainframe.senhaMainFrame,
			};

			// Gera taxa de postagem se a entrega não for "No posto".
			if (entrega !== 'P') {
				values.taxasReq.push({
					taxasCorreio: true,
					numeroRg,
					nome: 'Servico taxa de postagem',
				});
			}

			// Gera taxa de emissão caso o cidadão não seja isento.
			if (cadastroRg.tipo.segundaVia && alterarObservacoes.temp) {
				const {
					declaracaoPobreza,
					isento2Via10952,
					isento2Via062,
				} = alterarObservacoes.temp;

				if (!declaracaoPobreza && !isento2Via10952 && !isento2Via062) {
					values.taxasReq.push({
						taxasCorreio: false,
						numeroRg,
						nome: 'taxa segunda via balcao unico',
					});
				}
			}

			dispatch(gerarDareRequest(values));
		}
	}, [
		history,
		dispatch,
		cpf,
		cadastroRg.form.principal,
		cadastroRg.tipo.segundaVia,
		alterarObservacoes.temp,
		login,
	]);

	const handleValidate = useCallback((formValues: IFormDARE) => {
		return getValidationsErrors(
			{
				cpf: limparMascara(formValues.cpf),
				dare: limparMascara(formValues.dare),
			},
			schema,
		);
	}, []);

	const handleSubmit = useCallback(
		(formValues: IFormDARE) => {
			setCpf(formValues.cpf);
			setHasSubmit(true);

			let codigoOrgao: number = 100;

			const orgao:
				| IParametrosLocalidade
				| undefined = user.parametrosSistema.find(
				(item: IParametrosLocalidade) => {
					return item.sigla === 'DARE_ORGAO';
				},
			);

			if (orgao && orgao.valor) {
				codigoOrgao = Number(orgao.valor);
			}

			if (formValues.cpf) {
				dispatch(
					consultarDareCpfRequest({
						cpf: limparMascara(formValues.cpf),
						orgao: codigoOrgao,
					}),
				);
				return;
			}

			if (formValues.dare) {
				dispatch(
					consultarDareGuiaRequest({
						dare: formValues.dare,
						orgao: codigoOrgao,
					}),
				);
				return;
			}

			dispatch(
				consultarDareGuiaRequest({
					dare: formValues.dare,
					orgao: codigoOrgao,
				}),
			);
		},
		[dispatch, user.parametrosSistema],
	);

	const handleInputChange = useCallback(
		(value, field, formik) => {
			formik.setFieldValue(field, value);
			if (
				consultarDare.status === 200 &&
				consultarDare.tipo === 'dare' &&
				field === 'dare' &&
				limparMascara(value).length < 15
			) {
				dispatch(consultarDareClear());
			} else if (
				consultarDare.status === 200 &&
				consultarDare.tipo === 'cpf' &&
				field === 'cpf' &&
				limparMascara(value).length < 11
			) {
				dispatch(consultarDareClear());
			}
			if (
				field === 'cpf' &&
				limparMascara(value).length === 0 &&
				notifications.errors.length
			) {
				dispatch(clearNotifications());
			}
		},
		[consultarDare.status, consultarDare.tipo, dispatch, notifications],
	);

	return (
		<>
			{hasLoaded && (
				<Row>
					<Col span={24}>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<h2>Informe um dos campos abaixo:</h2>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={7}>
								<Formik
									validateOnChange={false}
									validateOnBlur={false}
									initialValues={initialValues}
									validate={handleValidate}
									onSubmit={handleSubmit}
								>
									{formik => (
										<Form autoComplete="off">
											<Row gutter={[0, 10]}>
												<Col span={24}>
													<Field
														as={InputMask}
														title="CPF da DARE"
														name="cpf"
														mask="999.999.999-99"
														onChange={(e: { target: { value: string } }) =>
															handleInputChange(e.target.value, 'cpf', formik)
														}
														error={hasError(formik.errors, 'cpfDareNumeroRg')}
													/>
												</Col>
											</Row>

											<Row gutter={[0, 10]}>
												<Col span={24}>
													<Field
														as={InputMask}
														title="Nº DARE"
														name="dare"
														mask="999999999999999"
														onChange={(e: { target: { value: string } }) =>
															handleInputChange(e.target.value, 'dare', formik)
														}
														error={hasError(formik.errors, 'cpfDareNumeroRg')}
													/>
												</Col>
											</Row>

											<Row gutter={[0, 10]} justify="end">
												<Col>
													<ButtonImage type="submit" src="pesquisar" />
												</Col>
											</Row>
										</Form>
									)}
								</Formik>
							</Col>

							<Col span={8} offset={2}>
								<Atendimento />
							</Col>

							<Col span={6}>
								{hasSubmit &&
									consultarDare.status !== 0 &&
									consultarDare.tipo !== 'dare' && (
										<Row
											justify="end"
											align="middle"
											style={{ height: '100%' }}
										>
											<Col>
												<ButtonImage
													src="gerar-dare"
													onClick={handleGerarDare}
												/>
											</Col>
										</Row>
									)}
							</Col>
						</Row>
					</Col>
				</Row>
			)}
		</>
	);
};

export default Formulario;
