import { combineReducers } from 'redux';

import { ReducerAction } from '../../types';

import { DetranCrvState, Types } from './types';

// CONSULTAR VEÍCULOS
import consultarVeiculos from './ConsultarVeiculos/combineReducers';

//  CONSULTAR RESTRIÇÕES
import consultarRestricoes from './ConsultarRestricoes/combineReducers';

//  CONSULTAR Resumo Extrato
import consultarResumoExtrato from './ConsultarResumoExtrato/combineReducers';

// MULTAS RENAINF
import multasRenainf from './MultasRenainf/combineReducers';

// ENUM
import Enum from './enum/combineReducers';

// SOLICITAR DOCUMENTOS
import solicitarDocumentos from './SolicitarDocumentos/combineReducers';

// PROCESSOS
import processos from './Processos/combineReducers';

// INTENÇÃO DE VENDAS
import intencaoVenda from './IntencaoDeVenda/combineReducers';

const allReducers = combineReducers({
	consultarRestricoes,
	consultarResumoExtrato,
	consultarVeiculos,
	multasRenainf,
	Enum,
	processos,
	solicitarDocumentos,
	intencaoVenda,
});

const combineReducer = (state: DetranCrvState, action: ReducerAction) => {
	if (action.type === Types.DETRAN_CRV_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
