import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_AAC } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	enviarCentralNumeralSuccess,
	enviarCentralNumeralFailure,
} from './actions';

function* enviarCentralNumeralRequest(request: ReducerAction) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_AAC,
		`aac/${CHANNEL}/enviar-central-numeral`,
		payload,
	);

	if (response.status === 200) {
		yield put(enviarCentralNumeralSuccess(response));
	} else if (response.error) {
		yield put(enviarCentralNumeralFailure());
	}
}

export default all([
	takeLatest(Types.ENVIAR_CENTRAL_NUMERAL_REQUEST, enviarCentralNumeralRequest),
]);
