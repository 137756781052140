import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import {
	Types,
	IConsultaErroData,
	IConsultaErroParsedData,
	ConsultaErroState,
} from './types';

export const INITIAL_STATE: ConsultaErroState = {
	consultaErroData: [],
	totalRegistrosErro: 0,
};

export default function contabilizacao(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaErroState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_ERRO: {
				break;
			}
			case Types.CONSULTA_ERRO_SUCCESS: {
				const parsedContabilizacoes: IConsultaErroParsedData[] = [];
				action.payload.evento_contabilizacoes?.forEach(
					(item: IConsultaErroData) => {
						parsedContabilizacoes.push({
							...item,
							json: JSON.parse(item.json),
						});
					},
				);
				draft.totalRegistrosErro = action.payload.total_registros;
				draft.consultaErroData = parsedContabilizacoes;
				break;
			}
			case Types.CONSULTA_ERRO_FAILURE: {
				draft.consultaErroData = [];
				break;
			}

			default:
		}
	});
}
