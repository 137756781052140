import { all } from 'redux-saga/effects';

import atualizarMensagemSaga from './atualizarMensagem/saga';
import cadastrarMensagemSaga from './cadastrarMensagem/saga';
import consultarMensagensSaga from './consultarMensagens/saga';
import excluirMensagemSaga from './excluirMensagem/saga';

export default all([
	atualizarMensagemSaga,
	cadastrarMensagemSaga,
	consultarMensagensSaga,
	excluirMensagemSaga,
]);
