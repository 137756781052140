import { ReducerAction } from 'store/modules/types';

import {
	Types,
	IRequestDocumentos,
	IRequestCadastraDocumento,
	IRequestStatusDocumentos,
	IRequestTermoAdvertencia,
	IRequestImpressao,
	IRequestCadastraTermoAdvertencia,
	IRequestImpressaoDocumentos,
} from './types';

export function consultaDocumentos(data: IRequestDocumentos): ReducerAction {
	return {
		type: Types.CONSULTA_DOCUMENTOS,
		payload: data,
	};
}
export function consultaDocumentosSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_DOCUMENTOS_SUCCESS,
		payload,
	};
}
export function consultaDocumentosFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_DOCUMENTOS_FAILURE,
		payload: null,
	};
}

export function cadastraDocumento(
	data: IRequestCadastraDocumento,
): ReducerAction {
	return {
		type: Types.CADASTRA_DOCUMENTO,
		payload: data,
	};
}
export function cadastraDocumentoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRA_DOCUMENTO_SUCCESS,
		payload,
	};
}
export function cadastraDocumentoFailure(): ReducerAction {
	return {
		type: Types.CADASTRA_DOCUMENTO_FAILURE,
		payload: null,
	};
}

export function consultaStatusDocumentos(
	data: IRequestStatusDocumentos,
): ReducerAction {
	return {
		type: Types.CONSULTA_STATUS_DOCUMENTOS,
		payload: data,
	};
}
export function consultaStatusDocumentosSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_STATUS_DOCUMENTOS_SUCCESS,
		payload,
	};
}
export function consultaStatusDocumentosFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_STATUS_DOCUMENTOS_FAILURE,
		payload: null,
	};
}

export function consultaTermoAdvertencia(
	data: IRequestTermoAdvertencia,
): ReducerAction {
	return {
		type: Types.CONSULTA_TERMO_ADVERTENCIA,
		payload: data,
	};
}
export function consultaTermoAdvertenciaSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_TERMO_ADVERTENCIA_SUCCESS,
		payload,
	};
}
export function consultaTermoAdvertenciaFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_TERMO_ADVERTENCIA_FAILURE,
		payload: null,
	};
}

export function imprimeTermoAdvertencia(
	data: IRequestImpressao,
): ReducerAction {
	return {
		type: Types.IMPRIME_TERMO_ADVERTENCIA,
		payload: data,
	};
}
export function imprimeTermoAdvertenciaSuccess(payload: object): ReducerAction {
	return {
		type: Types.IMPRIME_TERMO_ADVERTENCIA_SUCCESS,
		payload,
	};
}
export function imprimeTermoAdvertenciaFailure(): ReducerAction {
	return {
		type: Types.IMPRIME_TERMO_ADVERTENCIA_FAILURE,
		payload: null,
	};
}

export function cadastraTermoAdvertencia(
	data: IRequestCadastraTermoAdvertencia,
): ReducerAction {
	return {
		type: Types.CADASTRA_TERMO_ADVERTENCIA,
		payload: data,
	};
}
export function cadastraTermoAdvertenciaSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRA_TERMO_ADVERTENCIA_SUCCESS,
		payload,
	};
}
export function cadastraTermoAdvertenciaFailure(): ReducerAction {
	return {
		type: Types.CADASTRA_TERMO_ADVERTENCIA_FAILURE,
		payload: null,
	};
}

export function consultaImpressaoDocumentos(
	data: IRequestImpressaoDocumentos,
): ReducerAction {
	return {
		type: Types.CONSULTA_IMPRESSAO_DOCUMENTOS,
		payload: data,
	};
}
export function consultaImpressaoDocumentosSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_IMPRESSAO_DOCUMENTOS_SUCCESS,
		payload,
	};
}
export function consultaImpressaoDocumentosFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_IMPRESSAO_DOCUMENTOS_FAILURE,
		payload: null,
	};
}

export function consultaEfetivaImpressaoDocumentos(
	data: IRequestImpressaoDocumentos,
): ReducerAction {
	return {
		type: Types.CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS,
		payload: data,
	};
}
export function consultaEfetivaImpressaoDocumentosSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS_SUCCESS,
		payload,
	};
}
export function consultaEfetivaImpressaoDocumentosFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS_FAILURE,
		payload: null,
	};
}

export function clearNotificationsDocumentosVivaLeite(): ReducerAction {
	return {
		type: Types.NOTIFICATION_DOCUMENTOS_VIVA_LEITE_CLEAR,
		payload: null,
	};
}

export function clearPdfTermoAdvertencia(): ReducerAction {
	return {
		type: Types.PDF_TERMO_ADVERTENCIA_CLEAR,
		payload: null,
	};
}

export function clearPdfImprimir(): ReducerAction {
	return {
		type: Types.PDF_IMPRIMIR_CLEAR,
		payload: null,
	};
}
