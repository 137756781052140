export enum Types {
	DOMINIOS_REQUEST = '@EDUCACAO/DOMINIOS_REQUEST',
	DOMINIOS_SUCCESS = '@EDUCACAO/DOMINIOS_SUCCESS',
	DOMINIOS_FAILURE = '@EDUCACAO/DOMINIOS_FAILURE',
}

export interface Dominios {
	status: number;
	data: null | DominiosData;
}

export interface DominiosData {
	idProcesso: number;
	fasesInscricao: EnumDominios[];
	redesEnsino: EnumDominios[];
	opcoesNoturno: EnumDominios[];
	corRaca: EnumDominios[];
	nacionalidades: EnumDominios[];
	localizacoes: EnumDominios[];
	justificativas: EnumDominios[];
	sexos: EnumDominios[];
	fasesDefinicao: EnumDominios[];
	fasesMovimentacao: EnumDominios[];
	turnos: EnumDominios[];
	motivosTransferencia: EnumDominios[];
	tiposTelefone: EnumDominios[];
	fontes: EnumDominios[];
	areasLogradouro: EnumDominios[];
	tipoOrigem: EnumDominios[];
	responsabilidade: EnumDominios[];
	estadoCivil: EnumDominios[];
	deficiencias: EnumDeficiencia[];
}
export interface EnumDominios {
	codigo: number;
	descricao: string;
}
interface EnumDeficiencia {
	codigo: string;
	descricao: string;
	mobilidadeReduzida: string;
	tipoMobilidadeReduzida: string;
	cuidador: string;
	tipoCuidador: string;
	profSaude: string;
	tipoProfSaude: string;
}
