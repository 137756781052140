export enum Types {
	CONFIRMA_PRE_CADASTRO_REABILITACAO = '@cnh/CONFIRMA_PRE_CADASTRO_REABILITACAO',
	CONFIRMA_PRE_CADASTRO_REABILITACAO_SUCCESS = '@cnh/CONFIRMA_PRE_CADASTRO_REABILITACAO_SUCCESS',
	CONFIRMA_PRE_CADASTRO_REABILITACAO_FAILURE = '@cnh/CONFIRMA_PRE_CADASTRO_REABILITACAO_FAILURE',
}

export interface confirmaPreCadastroReabilitacao {
	status: number;
	data: IDadosReabilitacao | null;
}

export interface IDadosReabilitacao {
	status: string;
	statusMensagem: string;
	mensagemBloqueio: string;
	numeroRegistro: string;
	numeroPgu: string;
	renach: string;
	local: string;
	categoriaPretendida: string;
	cfcA: string;
	espelhoCnh: string;
	usoForm: string;
	situacaoAtual: string;
	cfcB: string;
	espelhoPid: string;
	dataPrimeiraHabilitacao: string;
	nome: string;
	dataValidadeCnh: string;
	nomePai: string;
	dataNascimento: string;
	nomeMae: string;
	sexo: string;
	nacionalidade: string;
	tipoDocumento: string;
	numeroRg: string;
	orgaoEmissor: string;
	localEmissao: string;
	cpf: string;
	rne1: string;
	rne2: string;
	rne3: string;
	naturalidade: string;
	identificacaoHabilitacao: string;
	pais: string;
	dataValidade: string;
	endereco: {
		logradouro: string;
		numero: string;
		complemento: string;
		cep: string;
		bairro: string;
		municipio: string;
		codigoMunicipio: string;
		descricaoMunicipio: string;
	};
	mensagem: string;
	dadosExameMedico: {
		dataExameMedico: string;
		categoriaPermitida: string;
		restricaoMedica: string;
		crm: string;
		cred1: string;
		cred2: string;
		regiao: string;
		aptidaoMedica: string;
	};
	dadosExamePsicotecnico: {
		dataExamePsicotecnico: string;
		laudo: string;
		resultado1: string;
		resultado2: string;
		crp: string;
		cred1: string;
		cred2: string;
		regiao: string;
		aptidaoPsicotecnica: string;
	};
	dataExameTeorico: string;
	codigoExameTeorico: string;
	codigoEntidade: string;
	descricaoEntidade: string;
	dataExamePratico2Rodas: string;
	codigoExamePratico2Rodas: string;
	dataExamePratico4Rodas: string;
	codigoExamePratico4Rodas: string;
	dadosPcad: {
		cpf: string;
		nome: string;
		numeroRg: string;
		orgaoEmissor: string;
		localEmissao: string;
		dataNascimento: string;
		rne: string;
		orgaoEmissorRne: string;
		localEmissaoRne: string;
		codigoPais: string;
		descricaoPais: string;
		validadeHabilitacaoEstrangeira: string;
		sexo: string;
		numeroRegistro: string;
		numeroPgu: string;
		nacionalidade: string;
		naturalidade: string;
		nomePai: string;
		nomeMae: string;
		enderecoPcad: {
			logradouro: string;
			cep: string;
			bairro: string;
			municipio: string;
		};
		processo: string;
		ciretran: string;
		categoriaAtual: string;
		categoriaPretendida: string;
		dataCadastro: string;
		horaCadastro: string;
		usuarioCadastro: string;
	};
	listaOcorrencias: {
		ocorrencia: listaCursos[];
	};
	listaCursos: {
		curso: listaOcorrencias[];
	};
	cepPcon: string;
	cepPcad: string;
	mensagensAlerta: {
		mensagem: string;
	};
	flagAtividadeRemunerada: string;
	flagDeficienteFisico: string;
}

interface listaOcorrencias {
	seqOcorrencia: string;
	tipoOcorrencia: string;
	informacaoOcorrencia: string;
}

interface listaCursos {
	codigoCurso: string;
	descricaoCurso: string;
	dataValidade: string;
}

export interface IRequestPreCadastroReabilitacao {
	autorizaEmail: string;
	autorizaSMS: string;
	bairro: string;
	categoriaAtual: string;
	categoriaPretendida: string;
	telefone: string;
	celular: string;
	cep: string;
	codigoEntidade: string;
	complemento: string;
	cpf: string;
	dataNascimento: string;
	dataPrimeiraHabilitacao: string;
	dddCelular: string;
	dddTelefone: string;
	email: string;
	logradouro: string;
	nacionalidade: string;
	naturalidade: string;
	necessidadeVeiculoAdaptado: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	numCarteiraMilitar: string;
	numDocumento: string;
	numPGU: string;
	numRNE: string;
	numRegistro: string;
	numero: string;
	orgaoDocumento: string;
	orgaoRNE: string;
	pretendeAtividadeRemunerada: string;
	sexo: string;
	tipoDocumento: string;
	ufDocumento: string;
	ufRNE: string;
}
