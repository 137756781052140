import styled from 'styled-components';
import Col from 'antd/es/grid/col';
import ButtonComponent from 'components/Common/Button';

export const Button = styled(ButtonComponent)`
	width: 100%;
	max-width: 190px;
`;

export const ColCenter = styled(Col)`
	display: flex;
	justify-content: center;
`;
