export enum Types {
	CONSULTA_EXTRATO_RESUMO_CONDUTOR_REQUEST = '@cnh/CONSULTA_EXTRATO_RESUMO_CONDUTOR_REQUEST',
	CONSULTA_EXTRATO_RESUMO_CONDUTOR_SUCCESS = '@cnh/CONSULTA_EXTRATO_RESUMO_CONDUTOR_SUCCESS',
	CONSULTA_EXTRATO_RESUMO_CONDUTOR_FAILURE = '@cnh/CONSULTA_EXTRATO_RESUMO_CONDUTOR_FAILURE',
	CONSULTA_EXTRATO_RESUMO_CONDUTOR_CLEAR = '@cnh/CONSULTA_EXTRATO_RESUMO_CONDUTOR_CLEAR',
}

export interface ConsultaExtratoResumoCondutor {
	status: number;
	data: ConsultaExtratoResumoCondutorData | null;
	cpf: string | null;
}

export interface ConsultaExtratoResumoCondutorData {
	mensagem: string;
	pcon: {
		nome: string;
		nomeSocial: string;
		registro: string;
		cpf: string;
		nacionalidade: string;
		naturalidade: string;
		dataNascimento: string;
		numeroRg: string;
		orgaoEmissor: string;
		uf: string;
		categoriaChn: string;
		dataHabilitacao: string;
		dataValidade: string;
		dataEmissao: string;
		restricaoMedica: string;
		linhaMensagem: string;
		flagAtividadeRemunerada: boolean;
		local: string;
		codigoMunicipio: string;
		municipioDescricao: string;
	};
	mensagemPcon: string;
	gfor: {
		usuario: string;
		dataAbertura: string;
		horaAbertura: string;
		gerado: string;
	};
	tipoProcessoGfor: string;
	mensagemGfor: string;
	pcrs: [
		{
			curso: string;
			dataInclusao: string;
		},
	];
	pcob: {
		motivoRequerimento: string;
		codTransferenciaUltimaAtualizacao: string;
		dataUltimaEmissaoHistorico: string;
	};
	ptax: [
		{
			situacao: string;
			valor: string;
			dataUtilizada: string;
			tipo: string;
		},
	];
	mensagemPtax: string;
	port: [
		{
			infracao: string;
			placa: string;
			status: string;
			dataInfracao: string;
			pontos: string;
			enq: string; // enquadramento
			infracaoMun: string; // codInfracaoMunicipio
			portaria: string;
		},
	];
	mensagemPort: string;
	ptox: {
		dataColeta: string;
		dataLimiteUtilizacao: string;
		resultado: string;
		dataValidade: string;
	};
	mensagemPtox: string;
}

export interface ConsultaExtratoResumoCondutorRequest {
	pgu: string;
	cpf: string;
	numeroRg: string;
	numeroRegistro: string;
	nome: string;
	dataNascimento: string;
	usuario: string;
	senha: string;
	evento: IConsultaResumoEventoRequest;
}

export interface ConsultaExtratoResumoCondutorForm {
	cpf: string;
}

export interface IConsultaResumoEventoRequest {
	id_atendimento: string;
	id_cidadao: string;
	// id_orgão: number;
	id_servico: number | string;
	desc_servico: string;
	tipo: string;
	cpf: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		estacao: {
			id: number;
			desc: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
}
