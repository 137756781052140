export enum Types {
	VALIDA_TOKEN_CNH_DIGITAL_REQUEST = '@cnh/VALIDA_TOKEN_CNH_DIGITAL_REQUEST',
	VALIDA_TOKEN_CNH_DIGITAL_SUCCESS = '@cnh/VALIDA_TOKEN_CNH_DIGITAL_SUCCESS',
	VALIDA_TOKEN_CNH_DIGITAL_FAILURE = '@cnh/VALIDA_TOKEN_CNH_DIGITAL_FAILURE',
	VALIDA_TOKEN_CNH_DIGITAL_CLEAR = '@cnh/VALIDA_TOKEN_CNH_DIGITAL_CLEAR',
}

export interface validaTokenCnhDigital {
	status: number;
	data: ValidaTokenCnhDigitalData | null;
}

export interface ValidaTokenCnhDigitalData {
	valido: boolean;
}

export interface ValidaTokenCnhDigitalRequest {
	cpf: string;
	ddd: string;
	celular: string;
	email: string;
	token: string;
}
