export enum Types {
	CILI_SIMPLIFICADA_REQUEST = '@iirgd/CILI_SIMPLIFICADA_REQUEST',
	CILI_SIMPLIFICADA_SUCCESS = '@iirgd/CILI_SIMPLIFICADA_SUCCESS',
	CILI_SIMPLIFICADA_FAILURE = '@iirgd/CILI_SIMPLIFICADA_FAILURE',
	CILI_SIMPLIFICADA_CLEAR = '@iirgd/CILI_SIMPLIFICADA_CLEAR',
}

export interface CiliSimplificadaState {
	status: number;
	data: CiliSimplificada | null;
}

export interface CiliSimplificada {
	messageclassname: string;
	usuario: string;
	codigoRetorno: string;
	mensagemErro: string;
	registroNumero: string;
	registroDigito: string;
	registroSequencia: string;
	retornoUsuario: string;
	messageType: string;
}

export interface CiliSimplificadaRequest {
	usuario: string;
	tipo: string;
	postoEntrega: string;
	postoAtendimento: string;
	registroNumero: string;
	registroDigito: string;
}
