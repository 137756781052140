export enum Types {
	CONSULTAR_INAPTO_REQUEST = '@cnh/CONSULTAR_INAPTO_REQUEST',
	CONSULTAR_INAPTO_SUCCESS = '@cnh/CONSULTAR_INAPTO_SUCCESS',
	CONSULTAR_INAPTO_FAILURE = '@cnh/CONSULTAR_INAPTO_FAILURE',
	CONSULTAR_INAPTO_CLEAR = '@cnh/CONSULTAR_INAPTO_CLEAR',
}

export interface ConsultarInapto {
	status: number;
	data: null | ConsultarInaptoData;
}

interface ConsultarInaptoData {
	cpfCandidato: string;
	cpfPsicologo: string;
	crpPsicologo: string;
	cpfMedico: string;
	crmMedico: string;
}

export interface ConsultarInaptoRequest {
	cpf: string;
	tipoExame: string;
	flagDeficienteFisico?: boolean;
}
