import { IConsultarDare } from 'store/modules/api/dare/consultarDare/types';

export enum Types {
	GERAR_DARE_REQUEST = '@iirgd/GERAR_DARE_REQUEST',
	GERAR_DARE_SUCCESS = '@iirgd/GERAR_DARE_SUCCESS',
	GERAR_DARE_FAILURE = '@iirgd/GERAR_DARE_FAILURE',
	GERAR_DARE_CLEAR = '@iirgd/GERAR_DARE_CLEAR',
	DARE_KIT_REQUEST = '@iirgd/DARE_KIT_REQUEST',
	DARE_KIT_CLEAR = '@iirgd/DARE_KIT_CLEAR',
}

export interface IGerarDareState {
	status: number;
	data: IConsultarDare | null;
	dareKit: {
		numDare: number | null;
		correios: string;
		quantidade: number;
		finalizados: number;
	};
}

export interface DareKitData {
	correios?: string;
	quantidade?: number;
	finalizados?: number;
}
