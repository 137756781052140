import React from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';

// COMPONENTS
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';

// UTILS
import hasError from 'utils/getFormErrors';
import { onlyLetters } from 'utils/genericFunctions';

interface Props {
	formik: FormikProps<any>;
}

const DadosSolicitante: React.FC<Props> = ({ formik }) => {
	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section size="lg" title="Solicitante" titleSize="sm">
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Field
									as={Input}
									title="Nome"
									titleSize="xl"
									name="nomeCidadao"
									maxLength={60}
									size={87}
									required
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'nomeCidadao',
											onlyLetters(e.target.value.toLocaleUpperCase()),
										);
									}}
									error={formik.errors.nomeCidadao}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col
								span={6}
								style={{
									marginLeft: '40px',
								}}
							>
								<Field
									as={InputMask}
									title="DDD/Telefone"
									titleSize="lg"
									name="dddTelefoneCidadao"
									mask="99"
									error={hasError(formik.errors, 'dddTelefoneCidadao')}
									required
								/>
							</Col>
							<Col
								style={{
									alignSelf: 'center',
									textAlign: 'center',
									marginLeft: '4px',
									marginRight: '4px',
								}}
							>
								<span>-</span>
							</Col>
							<Col span={6}>
								<Field
									as={InputMask}
									name="telefoneCidadao"
									mask="99999-9999"
									maskChar={null}
									size={60}
									error={formik.errors.telefoneCidadao}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Field
									as={Input}
									title="E-mail"
									titleSize="xl"
									name="emailCidadao"
									maxLength={60}
									size={87}
									required={formik.values.servicoDigital}
									error={hasError(formik.errors, 'emailCidadao')}
								/>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default DadosSolicitante;
