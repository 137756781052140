import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { Row, Col } from 'antd';

import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultarMunicipioPorUfRequest } from 'store/modules/api/detranCrv/enum/consultarMunicipioPorUF/actions';
import { consultaTipoRequest } from 'store/modules/api/detranCrv/enum/consultaTipo/actions';
import { consultaEspecieRequest } from 'store/modules/api/detranCrv/enum/consultaEspecie/actions';
import { consultaCarroceriaRequest } from 'store/modules/api/detranCrv/enum/consultaCarroceria/actions';
import { consultaCorRequest } from 'store/modules/api/detranCrv/enum/consultaCor/actions';
import { consultaCombustivelRequest } from 'store/modules/api/detranCrv/enum/consultaCombustivel/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCrv/enum/consultaCategoria/actions';
import { emitirTransferenciasMunicipiosRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirTransferenciaMunicipio/actions';
import { consultarVeiculoV110Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import { tipoArquivoClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/tipoArquivoEmissao/actions';

// COMPONENTS COMMON
import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { fillCpfCnpj } from 'utils/genericFunctions';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

// ROUTES
import {
	ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_CONSULTAR,
	ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_RESULTADO,
} from 'pages/DetranCrv/routes/paths';

// COMPONENTS
import TaxaServico from 'pages/DetranCrv/components/pages/TaxaServico';
import { Formik, Form } from 'formik';
import UploadArquivo from 'pages/DetranCrv/components/pages/UploadArquivo';
import DadosVeiculo from './DadosVeiculo';
import DadosProprietario from './DadosProprietario';
import Espelho from './Espelho';
import Gravames from './Gravames';

// FORM
import {
	IEmiteTransferenciaVeiculo,
	initialValues,
	schema,
	treatValues,
} from './form';

const EmitirTransferenciaMunicipio: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		consultarMunicipioPorUF,
		consultaCategoria,
		consultaCarroceria,
		consultaCombustivel,
		consultaCor,
		consultaEspecie,
		consultaTipo,
	} = useSelector((state: ApplicationState) => state.api.detranCrv.Enum);

	const { consultarVeiculoV110, emitirTransferenciaMunicipio } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);
	const { consultarTaxaServico } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);
	const { salvarAtendimento } = atendimento;
	const { login } = loginUnico;
	const sguData: SguData = useMemo(() => {
		return {
			salvarAtendimento,
			login,
		};
	}, [login, salvarAtendimento]);

	const evento = useMemo(() => {
		const proprietario =
			consultarVeiculoV110.data?.caracteristicaVeiculo.proprietario;
		const ident =
			(proprietario &&
				fillCpfCnpj(
					proprietario.identificacao,
					proprietario.cpfCnpjProprietario,
				)) ||
			'0';
		return getEventoConsultar(sguData, ident);
	}, [consultarVeiculoV110.data, sguData]);

	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (!consultarVeiculoV110.data) {
			history.push(ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_CONSULTAR);
		}
	}, [consultarVeiculoV110.data, history]);

	useEffect(() => {
		if (emitirTransferenciaMunicipio.data) {
			history.push(ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_RESULTADO);
		}
	}, [emitirTransferenciaMunicipio.data, history]);

	useEffect(() => {
		if (
			consultarVeiculoV110.data &&
			consultarMunicipioPorUF.data.length > 0 &&
			consultaTipo.data.length > 0 &&
			consultaEspecie.data.length > 0 &&
			consultaCarroceria.data.length > 0 &&
			consultaCor.data.length > 0 &&
			consultaCombustivel.data.length > 0 &&
			consultaCategoria.data.length > 0
		) {
			const {
				ufEspelhoAnterior,
				placa,
				renavam,
				chassi,
				marca,
				municipio,
				municipioPlacaAnterior,
				anoModelo,
				anoFabricacao,
				tipo,
				carroceria,
				cor,
				categoria,
				combustivel,
				especie,
				capacidadePassageiro,
				capacidadeCarga,
				potencia,
				cilindrada,
				cmt,
				pbt,
				eixos,
				ufPlacaAnterior,
				placaAnterior,
				procedencia,
				proprietario,
				gravame,
			} = consultarVeiculoV110.data.caracteristicaVeiculo;

			// Espelho Anterior
			initialValues.ufEspelhoAnterior = ufEspelhoAnterior;

			// Dados do Veículo
			initialValues.placa = placa;
			initialValues.chassi = chassi;
			initialValues.renavam = renavam.toString();
			initialValues.longIdMarca = marca.longId;
			initialValues.descricaoMarca = marca.descricao;

			initialValues.codigoMunicipio = '';
			initialValues.descricaoMunicipio = municipio.nome;

			initialValues.anoFabricacao = anoFabricacao;
			initialValues.anoModelo = anoModelo;

			initialValues.longIdTipo = tipo.longId;
			initialValues.descricaoTipo = tipo.descricao;

			initialValues.longIdCarroceria = carroceria.longId;
			initialValues.descricaoCarroceria = carroceria.descricao;

			initialValues.longIdCor = cor.longId;
			initialValues.descricaoCor = cor.descricao;

			initialValues.longIdCategoria = categoria.longId;
			initialValues.descricaoCategoria = categoria.descricao;

			initialValues.longIdCombustivel = combustivel.longId;
			initialValues.descricaoCombustivel = combustivel.descricao;

			initialValues.longIdEspecie = especie.longId;
			initialValues.descricaoEspecie = especie.descricao;

			initialValues.capacidadePassageiros = capacidadePassageiro;
			initialValues.capacidadeCarga = capacidadeCarga
				? Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(capacidadeCarga)
				: Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(0);
			initialValues.potencia = potencia;
			initialValues.cilindrada = cilindrada;
			initialValues.cmt = cmt
				? Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(cmt)
				: Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(0);

			initialValues.pbt = pbt
				? Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 2,
				  }).format(pbt)
				: Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 2,
				  }).format(0);

			initialValues.eixos = eixos;

			initialValues.procedencia = procedencia;

			// Dados do Proprietário
			if (consultarVeiculoV110.data?.caracteristicaVeiculo?.comunicacaoVenda) {
				initialValues.nomeProprietario =
					proprietario.nomeProprietario?.trim().substring(0, 60) || '';
				initialValues.cepProprietario =
					proprietario.cepProprietario?.trim() || '';
				initialValues.bairroProprietario =
					proprietario.bairroProprietario?.trim().substring(0, 70) || '';
				initialValues.identificacao = proprietario.identificacao || 'FISICA';
				initialValues.cpfCnpjProprietario =
					fillCpfCnpj(
						proprietario.identificacao,
						proprietario.cpfCnpjProprietario,
					) || '';
				initialValues.enderecoProprietario =
					proprietario.enderecoProprietario?.trim().substring(0, 70) || '';
				initialValues.numeroProprietario =
					proprietario.numeroLogradouroProprietario?.trim().substring(0, 5) ||
					'';
				initialValues.complementoProprietario =
					proprietario.complementoLogradouroProprietario
						?.trim()
						.substring(0, 6) || '';

				if (proprietario.municipioProprietario) {
					const selectedMunicipio = consultarMunicipioPorUF.data.filter(
						item => item.value === Number(proprietario.municipioProprietario),
					);

					initialValues.codigoMunicipioProprietario =
						selectedMunicipio[0]?.value;
					initialValues.descricaoMunicipioProprietario =
						selectedMunicipio[0]?.label;
				}

				initialValues.codigoMunicipioPlacaAnterior =
					municipioPlacaAnterior.codigo;
				initialValues.descricaoMunicipioPlacaAnterior =
					municipioPlacaAnterior.nome;

				initialValues.nomeProprietarioAnterior =
					proprietario.nomeProprietarioAnterior;
				initialValues.placaAnterior = placaAnterior;
				initialValues.ufPlacaAnterior = ufPlacaAnterior;
			}

			// Gravame
			initialValues.arrendatarioFinanceira = gravame.arrendatarioFinanceira;
			initialValues.codigoFinanceira = gravame.codigoFinanceira;
			initialValues.numeroContrato = gravame.numeroContrato;
			initialValues.codigoFinanceira = gravame.codigoFinanceira;
			initialValues.restricao = gravame.restricao;

			// Taxa
			initialValues.taxaAutenticacaoDigital = '';
			initialValues.codBanco = consultarTaxaServico.data?.codBanco || '';
			initialValues.dataContabil =
				consultarTaxaServico.data?.dataContabil || '';
			initialValues.nsuProdesp = consultarTaxaServico.data?.nsuProdesp || '';

			setHasLoaded(true);
		}
	}, [
		consultarVeiculoV110.data,
		consultarMunicipioPorUF.data,
		consultaTipo.data,
		consultaEspecie.data,
		consultaCarroceria.data,
		consultaCor.data,
		consultaCombustivel.data,
		consultaCategoria.data,
		consultarTaxaServico.data,
		history,
	]);

	// Enum: Municípios - SP.
	useEffect(() => {
		if (consultarMunicipioPorUF.data.length === 0) {
			dispatch(consultarMunicipioPorUfRequest('SP'));
		}
	}, [consultarMunicipioPorUF.data, dispatch]);

	// Enum: Tipo de Veículo.
	useEffect(() => {
		if (consultaTipo.data.length === 0) {
			dispatch(consultaTipoRequest());
		}
	}, [consultaTipo, dispatch]);

	// Enum: Espécie.
	useEffect(() => {
		if (consultaEspecie.data.length === 0) {
			dispatch(consultaEspecieRequest());
		}
	}, [consultaEspecie, dispatch]);

	// Enum: Carroceria.
	useEffect(() => {
		if (consultaCarroceria.data.length === 0) {
			dispatch(consultaCarroceriaRequest());
		}
	}, [consultaCarroceria, dispatch]);

	// Enum: Cor.
	useEffect(() => {
		if (consultaCor.data.length === 0) {
			dispatch(consultaCorRequest());
		}
	}, [consultaCor, dispatch]);

	// Enum: Combustível.
	useEffect(() => {
		if (consultaCombustivel.data.length === 0) {
			dispatch(consultaCombustivelRequest());
		}
	}, [consultaCombustivel, dispatch]);

	// Enum: Categoria.
	useEffect(() => {
		if (consultaCategoria.data.length === 0) {
			dispatch(consultaCategoriaRequest());
		}
	}, [consultaCategoria, dispatch]);

	const handleValidate = useCallback(
		(formValues: IEmiteTransferenciaVeiculo) => {
			return getValidationsErrors(formValues, schema);
		},
		[],
	);

	const handleSubmit = useCallback(
		(formValues: IEmiteTransferenciaVeiculo) => {
			if (consultarVeiculoV110.data) {
				const headers = {
					codUnidade: evento.canal.localidade.id.toString(),
					cpfUsuario: evento.cpf,
				};
				const body = treatValues(formValues, evento);

				dispatch(emitirTransferenciasMunicipiosRequest({ body, headers }));
			}
		},
		[consultarVeiculoV110.data, evento, dispatch],
	);

	const handleButtonCancelar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());
		dispatch(tipoArquivoClear());

		history.push(ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_CONSULTAR);
	}, [dispatch, history]);

	const handleButtonVoltar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());
		dispatch(tipoArquivoClear());

		history.push(ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_CONSULTAR);
	}, [dispatch, history]);

	return (
		<>
			{hasLoaded && (
				<>
					<Row>
						<Col span={24}>
							<Section size="lg" title="Transferência Município">
								<Formik
									validateOnChange={false}
									validateOnBlur={false}
									initialValues={initialValues}
									validate={handleValidate}
									onSubmit={handleSubmit}
								>
									{formik => (
										<Form autoComplete="off">
											<Espelho formik={formik} />

											<DadosVeiculo formik={formik} />

											<Gravames formik={formik} />

											<DadosProprietario formik={formik} isOpenDocument />

											{consultarTaxaServico.data && (
												<Section size="sm">
													<TaxaServico
														dataConsultarTaxaServico={consultarTaxaServico.data}
													/>
												</Section>
											)}

											<UploadArquivo
												formik={formik}
												evento={evento}
												tipoArquivo="2"
												sizeMB={10000000}
											/>

											<Row gutter={[0, 20]}>
												<Col span={24} />
											</Row>

											<Row justify="center">
												<Col>
													<Button onClick={handleButtonCancelar}>
														Cancelar
													</Button>
												</Col>

												<Col offset={1}>
													<Button type="submit">Enviar</Button>
												</Col>
											</Row>
										</Form>
									)}
								</Formik>
							</Section>

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default EmitirTransferenciaMunicipio;
