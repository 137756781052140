import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_BOLSA_POVO } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { CadastrarContatoRequest, Types } from './types';

// ACTIONS
import { cadastrarContatoSuccess, cadastrarContatoFailure } from './action';

function* cadastrarContatoRequest(request: ReducerAction) {
	const { payload }: { payload: CadastrarContatoRequest } = request;
	const response: ApiResponse = yield call(
		postApi,
		PATH_BOLSA_POVO,
		`bolsa-povo/${CHANNEL}/cadastrar-contato`,
		payload,
	);

	if (response.status === 200) {
		yield put(cadastrarContatoSuccess(response));
	} else if (response.error) {
		yield put(cadastrarContatoFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_CONTRATO_REQUEST, cadastrarContatoRequest),
]);
