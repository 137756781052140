import { ReducerAction } from 'store/modules/types';

import { ConsultarLocalidadesRequest, Types } from './types';

export function consultarLocalidadesRequest(
	payload: ConsultarLocalidadesRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_LOCALIDADES_REQUEST,
		payload,
	};
}
export function consultarLocalidadesSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_LOCALIDADES_SUCCESS,
		payload,
	};
}
export function consultarLocalidadesFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_LOCALIDADES_FAILURE,
		payload: null,
	};
}
export function consultarLocalidadesClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_LOCALIDADES_CLEAR,
		payload: null,
	};
}
