import produce from 'immer';

// TYPES
import { Types, ConsultarTipos } from './types';
import { ReducerAction } from '../../../../../../types';

export const INITIAL_STATE: ConsultarTipos = {
	status: 0,
	data: null,
};

export default function consultarTiposReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarTipos {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_TIPOS: {
				draft.status = 100;
				break;
			}

			case Types.CONSULTAR_TIPOS_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_TIPOS_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.CONSULTAR_TIPOS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
