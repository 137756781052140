import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

// ANTD
import { Col, Row } from 'antd';

// COMPONENTS COMMON
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// REDUX
import { ConsultaExtratoResumoCondutor } from 'store/modules/api/detranCnh/eportal/consultaExtratoResumoCondutor/types';

// PATHS
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// COMPONENTS
import DadosCondutor from './DadosCondutor';
import CNH from './CNH';
import Restricoes from './Restricoes';
import Cursos from './Cursos';
import ExameToxicologico from './ExameToxicologico';
import Pontuacao from './Pontuacao';
import Taxas from './Taxas';

type ILocation = {
	consultaExtratoResumoCondutor: ConsultaExtratoResumoCondutor | null;
};

const ConsultaResumoExtratoCondutor: React.FC = () => {
	const history = useHistory();

	const location = useLocation<ILocation>();

	const { consultaExtratoResumoCondutor } = location.state;

	return (
		<>
			<Row>
				<Col span={24}>
					<DadosCondutor
						consultaExtratoResumoCondutor={
							consultaExtratoResumoCondutor?.data?.pcon
						}
					/>
					<CNH
						consultaExtratoResumoCondutorPCON={
							consultaExtratoResumoCondutor?.data?.pcon
						}
						consultaExtratoResumoCondutorGFOR={
							consultaExtratoResumoCondutor?.data?.gfor
						}
						tipoProcessoGfor={
							consultaExtratoResumoCondutor?.data?.tipoProcessoGfor
						}
					/>
					<Restricoes
						consultaExtratoResumoCondutor={
							consultaExtratoResumoCondutor?.data?.pcon.restricaoMedica
						}
					/>
					<Cursos
						consultaExtratoResumoCondutor={
							consultaExtratoResumoCondutor?.data?.pcrs
						}
					/>
					<ExameToxicologico
						consultaExtratoResumoCondutor={
							consultaExtratoResumoCondutor?.data?.ptox
						}
						mensagemPtox={consultaExtratoResumoCondutor?.data?.mensagemPtox}
					/>
					<Pontuacao
						consultaExtratoResumoCondutor={
							consultaExtratoResumoCondutor?.data?.port
						}
						mensagemPort={consultaExtratoResumoCondutor?.data?.mensagemPort}
					/>
					<Taxas
						consultaExtratoResumoCondutor={
							consultaExtratoResumoCondutor?.data?.ptax
						}
						mensagemPtax={consultaExtratoResumoCondutor?.data?.mensagemPtax}
					/>
				</Col>
			</Row>

			<br />

			<Row justify="space-between" align="middle">
				<Col>
					<ButtonVoltar route="/detran-cnh" />
				</Col>
				<Col>
					<ButtonImage
						type="button"
						src="outro-servico-detran"
						onClick={() => history.push(ROUTE_DETRAN_CNH)}
					/>
				</Col>
			</Row>
		</>
	);
};
export default ConsultaResumoExtratoCondutor;
