import produce from 'immer';

// UTILS
import { formatDate } from 'utils/genericFunctions';

// TYPES
import { Types, consultaSegundaViaCnh } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaSegundaViaCnh = {
	status: 0,
	data: null,
};

export default function consultaSegundaViaCnhReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaSegundaViaCnh {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_SEGUNDA_VIA_CNH: {
				break;
			}

			case Types.CONSULTA_SEGUNDA_VIA_CNH_SUCCESS: {
				const segundaVia = { ...action.payload.data.dadosSegundaVia[0] };

				if (segundaVia.dataPrimeiraHabilitacao !== '') {
					segundaVia.dataPrimeiraHabilitacao = formatDate(
						segundaVia.dataPrimeiraHabilitacao,
					);
				}
				if (segundaVia.dataValidadeCnh !== '') {
					segundaVia.dataValidadeCnh = formatDate(segundaVia.dataValidadeCnh);
				}
				if (segundaVia.dataNascimento !== '') {
					segundaVia.dataNascimento = formatDate(segundaVia.dataNascimento);
				}
				if (segundaVia.dadosExameMedico.dataExameMedico !== '') {
					segundaVia.dadosExameMedico.dataExameMedico = formatDate(
						segundaVia.dadosExameMedico.dataExameMedico,
					);
				}
				if (segundaVia.dadosExamePsicotecnico.dataExamePsicotecnico !== '') {
					segundaVia.dadosExamePsicotecnico.dataExamePsicotecnico = formatDate(
						segundaVia.dadosExamePsicotecnico.dataExamePsicotecnico,
					);
				}
				if (segundaVia.dataExameTeorico !== '') {
					segundaVia.dataExameTeorico = formatDate(segundaVia.dataExameTeorico);
				}
				if (segundaVia.dataExamePratico2Rodas !== '') {
					segundaVia.dataExamePratico2Rodas = formatDate(
						segundaVia.dataExamePratico2Rodas,
					);
				}
				if (segundaVia.dataExamePratico4Rodas !== '') {
					segundaVia.dataExamePratico4Rodas = formatDate(
						segundaVia.dataExamePratico4Rodas,
					);
				}
				if (segundaVia.dadosPcad.dataNascimento !== '') {
					segundaVia.dadosPcad.dataNascimento = formatDate(
						segundaVia.dadosPcad.dataNascimento,
					);
				}

				if (
					!!segundaVia.dadosPcad.naturalidade &&
					segundaVia.dadosPcad.naturalidade !== ''
				) {
					const newNaturalidade = segundaVia.dadosPcad.naturalidade.split(
						' - ',
					)[0];

					segundaVia.dadosPcad.naturalidade = newNaturalidade;
				}

				if (
					!!segundaVia.dadosPcad.enderecoPcad.municipio &&
					segundaVia.dadosPcad.enderecoPcad.municipio !== ''
				) {
					const newNaturalidade = segundaVia.dadosPcad.enderecoPcad.municipio.split(
						' - ',
					)[1];

					segundaVia.dadosPcad.enderecoPcad.municipio = newNaturalidade;
				}

				draft.data = segundaVia;
				break;
			}

			case Types.CONSULTA_SEGUNDA_VIA_CNH_FAILURE: {
				break;
			}

			default:
		}
	});
}
