import * as Yup from 'yup';

// TYPES
import { IRequestEmitirSegundaViaPlaca } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirSegundaViaPlaca/types';
import { IEvento } from 'store/modules/api/detranCrv/types';

export interface IEmitirSegundaViaPlaca {
	evento: IEvento;
	// Dados do Veículo
	placa: string;
	codigoMunicipio: number | string;
	descricaoMunicipio: string;
	protocoloRemarcacao: number | string;
	renavam: string | number;
	longIdMarca: number | string;
	descricaoMarca: string;
	anoFabricacao: number | string;
	anoModelo: number | string;
	chassi: string;
	chassiRemarcado: boolean;
	longIdTipo: number | string;
	descricaoTipo: string;
	longIdCarroceria: number | string;
	descricaoCarroceria: string;
	longIdCategoria: number | string;
	descricaoCategoria: string;
	longIdCor: number | string;
	descricaoCor: string;
	longIdCombustivel: number | string;
	descricaoCombustivel: string;
	longIdEspecie: number | string;
	descricaoEspecie: string;
	capacidadePassageiro: number | string;
	capacidadeCarga: number | string;
	potencia: number | string;
	cilindrada: number | string;
	cmt: number | string;
	pbt: number | string;
	eixos: number | string;
	procedencia: string;

	// Dados do Proprietário
	nomeProprietario: string;
	cepProprietario: string;
	bairroProprietario: string;
	enderecoProprietario: string;
	numeroProprietario: string;
	complementoProprietario: string;
	telefone: string;
	codigoMunicipioProprietario: number | string;
	descricaoMunicipioProprietario: string;
	identificacao: string;
	cpfProprietario: number | string;
	cnpjProprietario: number | string;
	rgProprietario: string;
	rgDigito: string;
	orgaoExpeditorProprietario: string;
	ufProprietario: string;

	// Autorização de Estampagem
	motivoAutorizacaoEmplacamento: string;
	tipoPlaca: string;
	tipoVeiculoOficial: string;
	ufEstampador: string;
	codigoMunicipioEstampador: number | string;
	descricaoMunicipioEstampador: string;

	// Dados para Segunda Via da Placa
	autenticacaoDigital: string;
	ufDestinoLacracao: string;
	codigoMunicipioLacracao: number | string;
	descricaoMunicipioLacracao: string;
	unidadeTransitoAutoriza: number | string;
	motivoSegundaViaPlaca: string;
}

export const initialValues: IEmitirSegundaViaPlaca = {
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
		identificacao: '',
		ip: '',
		canal: {
			id: 0,
			desc: '',
			localidade: {
				id: 0,
				desc: '',
			},
		},
	},
	// Dados do Veículo
	placa: '',
	codigoMunicipio: '',
	descricaoMunicipio: '',
	protocoloRemarcacao: '',
	renavam: '',
	longIdMarca: '',
	descricaoMarca: '',
	anoFabricacao: '',
	anoModelo: '',
	chassi: '',
	chassiRemarcado: false,
	longIdTipo: '',
	descricaoTipo: '',
	longIdCarroceria: '',
	descricaoCarroceria: '',
	longIdCategoria: '',
	descricaoCategoria: '',
	longIdCor: '',
	descricaoCor: '',
	longIdCombustivel: '',
	descricaoCombustivel: '',
	longIdEspecie: '',
	descricaoEspecie: '',
	capacidadePassageiro: '',
	capacidadeCarga: '',
	potencia: '',
	cilindrada: '',
	cmt: '',
	pbt: '',
	eixos: '',
	procedencia: '',

	// Dados do Proprietário
	nomeProprietario: '',
	cepProprietario: '',
	bairroProprietario: '',
	enderecoProprietario: '',
	numeroProprietario: '',
	complementoProprietario: '',
	telefone: '',
	codigoMunicipioProprietario: '',
	descricaoMunicipioProprietario: '',
	identificacao: '',
	cpfProprietario: '',
	cnpjProprietario: '',
	rgProprietario: '',
	rgDigito: '',
	orgaoExpeditorProprietario: '',
	ufProprietario: '',

	// Autorização de Estampagem
	motivoAutorizacaoEmplacamento: '',
	tipoPlaca: '',
	tipoVeiculoOficial: '',
	ufEstampador: 'SP',
	codigoMunicipioEstampador: '',
	descricaoMunicipioEstampador: '',

	// Dados para Segunda Via da Placa
	autenticacaoDigital: '',
	ufDestinoLacracao: 'SP',
	codigoMunicipioLacracao: '',
	descricaoMunicipioLacracao: '',
	unidadeTransitoAutoriza: '',
	motivoSegundaViaPlaca: '',
};

export const schema = Yup.object<IEmitirSegundaViaPlaca>().shape({
	placa: Yup.string().required('Campo Obrigatório: Placa.'),
	municipio: Yup.string().when(['codigoMunicipio', 'descricaoMunicipio'], {
		is: (codigoMunicipio, descricaoMunicipio) =>
			[codigoMunicipio, descricaoMunicipio].includes(''),
		then: Yup.string().required('Campo Obrigatório: Município.'),
	}),
	renavam: Yup.string().required('Campo Obrigatório: Renavam.'),
	chassi: Yup.string().required('Campo Obrigatório: Chassi.'),
	chassiRemarcado: Yup.string().required(
		'Campo Obrigatório: Chassi Remarcado.',
	),
	marca: Yup.string().when(['descricaoMarca', 'longIdMarca'], {
		is: (descricaoMarca, longIdMarca) =>
			[descricaoMarca, longIdMarca].includes(''),
		then: Yup.string().required('Campo Obrigatório: Marca.'),
	}),
	anoModelo: Yup.string().required('Campo Obrigatório: Ano Modelo.'),
	tipo: Yup.string().when(['descricaoTipo', 'longIdTipo'], {
		is: (descricaoTipo, longIdTipo) => [descricaoTipo, longIdTipo].includes(''),
		then: Yup.string().required('Campo Obrigatório: Tipo.'),
	}),
	carroceria: Yup.string().when(['descricaoCarroceria', 'longIdCarroceria'], {
		is: (descricaoCarroceria, longIdCarroceria) =>
			[descricaoCarroceria, longIdCarroceria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Carroceria.'),
	}),
	cor: Yup.string().when(['descricaoCor', 'longIdCor'], {
		is: (descricaoCor, longIdCor) => [descricaoCor, longIdCor].includes(''),
		then: Yup.string().required('Campo Obrigatório: Cor.'),
	}),
	categoria: Yup.string().when(['descricaoCategoria', 'longIdCategoria'], {
		is: (descricaoCategoria, longIdCategoria) =>
			[descricaoCategoria, longIdCategoria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Categoria.'),
	}),
	combustivel: Yup.string().when(
		['descricaoCombustivel', 'longIdCombustivel'],
		{
			is: (descricaoCombustivel, longIdCombustivel) =>
				[descricaoCombustivel, longIdCombustivel].includes(''),
			then: Yup.string().required('Campo Obrigatório: Combustível.'),
		},
	),
	especie: Yup.string().when(['descricaoEspecie', 'longIdEspecie'], {
		is: (descricaoEspecie, longIdEspecie) =>
			[descricaoEspecie, longIdEspecie].includes(''),
		then: Yup.string().required('Campo Obrigatório: Espécie.'),
	}),
	capacidadePassageiro: Yup.string().required(
		'Campo Obrigatório: Capacidade Passageiros.',
	),
	capacidadeCarga: Yup.string().required(
		'Campo Obrigatório: Capacidade Carga.',
	),
	potencia: Yup.string().required('Campo Obrigatório: Potência.'),
	cilindrada: Yup.string().required('Campo Obrigatório: Cilindrada.'),
	cmt: Yup.string().required('Campo Obrigatório: CMT.'),
	pbt: Yup.string().required('Campo Obrigatório: PBT.'),
	eixos: Yup.string().required('Campo Obrigatório: Eixos.'),
	procedencia: Yup.string().required(
		'Campo Obrigatório: Procedência do veículo.',
	),
	nomeProprietario: Yup.string().required('Campo Obrigatório: Nome.'),
	cepProprietario: Yup.string().required('Campo Obrigatório: CEP do imóvel.'),
	bairroProprietario: Yup.string().required('Campo Obrigatório: Bairro.'),
	enderecoProprietario: Yup.string().required('Campo Obrigatório: Endereço.'),
	numeroProprietario: Yup.string().required('Campo Obrigatório: Número.'),
	identificacao: Yup.string().required(
		'Campo Obrigatório: Identificação Contribuinte.',
	),
	cpf: Yup.string().when(['identificacao', 'cpfProprietario'], {
		is: (identificacao, cpfProprietario) =>
			identificacao === 'FISICA' && !cpfProprietario,
		then: Yup.string().required('Campo Obrigatório: CPF do Proprietário.'),
	}),
	cnpj: Yup.string().when(['identificacao', 'cnpjProprietario'], {
		is: (identificacao, cnpjProprietario) =>
			identificacao === 'JURIDICA' && !cnpjProprietario,
		then: Yup.string().required('Campo Obrigatório: CNPJ do Proprietário.'),
	}),
	// pessoaFisicaRg: Yup.string().when(
	// 	['identificacao', 'rgProprietario', 'rgProprietario', 'ufProprietario'],
	// 	{
	// 		is: (
	// 			identificacao,
	// 			rgProprietario,
	// 			orgaoExpeditorProprietario,
	// 			ufProprietario,
	// 		) =>
	// 			identificacao === 'FISICA' &&
	// 			[rgProprietario, orgaoExpeditorProprietario, ufProprietario].includes(
	// 				'',
	// 			),
	// 		then: Yup.string().required(
	// 			'Campos Obrigatórios: RG do Proprietário atual, Órgão Expedidor e UF.',
	// 		),
	// 	},
	// ),
	motivoAutorizacaoEmplacamento: Yup.string().required(
		'Campo Obrigatório: Motivo Autorização Emplacamento.',
	),
	tipoPlaca: Yup.string().required('Campo Obrigatório: Tipo de Placa.'),
	codigoMunicipioEstampador: Yup.string().required(
		'Campo Obrigatório: Município Estampador.',
	),
	tipoVeiculoOficial: Yup.string().when(['motivoAutorizacaoEmplacamento'], {
		is: motivoAutorizacaoEmplacamento =>
			motivoAutorizacaoEmplacamento === 'VEICULO_OFICIAL',
		then: Yup.string().required('Campo Obrigatório: Tipo de Veículo Oficial.'),
	}),
	ufDestinoLacracao: Yup.string().required(
		'Campo Obrigatório: UF Destino da lacração.',
	),
	codigoMunicipioLacracao: Yup.string().required(
		'Campo Obrigatório: Município Destino da lacração.',
	),
	unidadeTransitoAutoriza: Yup.string().required(
		'Campo Obrigatório: Unidade de Trânsito que autoriza.',
	),
	motivoSegundaViaPlaca: Yup.string().required(
		'Campo Obrigatório: Motivo da Solicitação da 2ª via de placa.',
	),
});

export function treatValues(
	evento: IEvento,
	formValues: IEmitirSegundaViaPlaca,
): IRequestEmitirSegundaViaPlaca {
	const {
		chassi,
		placa,
		motivoAutorizacaoEmplacamento,
		motivoSegundaViaPlaca,
		codigoMunicipioLacracao,
		descricaoMunicipioLacracao,
		codigoMunicipioEstampador,
		descricaoMunicipioEstampador,
		tipoPlaca,
		tipoVeiculoOficial,
		ufDestinoLacracao,
		ufEstampador,
		unidadeTransitoAutoriza,
	} = formValues;

	return {
		evento: {
			id_atendimento: evento.id_atendimento,
			id_cidadao: evento.id_cidadao,
			ip: evento.ip,
			identificacao: evento.identificacao,
			cpf: evento.cpf || '',
			canal: {
				id: 1,
				desc: 'Presencial',
				localidade: {
					desc: evento.canal?.localidade.desc || '',
					id: evento.canal?.localidade.id || 0,
				},
			},
		},
		chassi: chassi?.trim() || '',
		placa,
		motivoAutorizacaoEmplacamento,
		motivoSegundaViaPlaca,
		municipioDestinoLacracao: {
			codigo: Number(codigoMunicipioLacracao),
			descricao: descricaoMunicipioLacracao,
		},
		municipioEstampador: {
			codigo: Number(codigoMunicipioEstampador),
			descricao: descricaoMunicipioEstampador,
		},
		tipoPlaca,
		tipoVeiculoOficial: tipoVeiculoOficial || null,
		ufDestinoLacracao,
		ufEstampador,
		unidadeTransitoAutoriza: {
			codigo: Number(unidadeTransitoAutoriza),
		},
	};
}
