import axios from 'axios';

// REDUX
import { store } from 'store';
import { setAuthToken } from 'store/modules/app/authToken/actions';

// UTILS
import handleFailed from 'utils/getValidations';

// SERVICES
import { PATH_SGU_SERVICE_AUTH } from './_path';

export function getAuthorizationToken(): string {
	const state = store.getState();
	const { expireIn, authorization } = state.app.authToken;

	if (expireIn && authorization) {
		const dateNow = new Date();
		const dateExpireIn = new Date(expireIn);

		if (dateExpireIn > dateNow) return authorization;
	}

	return '';
}

export async function authToken() {
	const { REACT_APP_BASE_URL } = process.env;

	const api = axios.create({
		baseURL: REACT_APP_BASE_URL,
	});

	try {
		const response = await api.get(`/${PATH_SGU_SERVICE_AUTH}/token`);
		setAuthToken(`Bearer ${response.data.access_token}`);

		return `${
			response.data.access_token &&
			response.data.access_token.includes('Bearer')
				? response.data.access_token
				: `Bearer ${response.data.access_token}`
		} `;
	} catch (error) {
		const errorResponse = await handleFailed(error);

		return { error: true, message: errorResponse };
	}
}
