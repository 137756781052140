import { ReducerAction } from 'store/modules/types';

import { Types, IRequestHorariosPorData } from './types';

export function horariosPorDataRequest(
	data: IRequestHorariosPorData,
): ReducerAction {
	return {
		type: Types.HORARIOS_POR_DATA,
		payload: data,
	};
}
export function horariosPorDataSuccess(payload: object): ReducerAction {
	return {
		type: Types.HORARIOS_POR_DATA_SUCCESS,
		payload,
	};
}
export function horariosPorDataFailure(payload: boolean): ReducerAction {
	return {
		type: Types.HORARIOS_POR_DATA_FAILURE,
		payload,
	};
}
export function horariosPorDataClear(): ReducerAction {
	return {
		type: Types.HORARIOS_POR_DATA_CLEAR,
		payload: null,
	};
}
