import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';

// REDUX
import EnumCNH from 'store/modules/api/detranCnh/enum';

// COMPONENTS
import Alert from 'components/Common/Notifications/Alert';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';
import Input from 'components/Common/Form/Input/Input';
import ValidDataInput from 'components/Common/Form/Input/ValidData';

// REDUX
import { relatorioAgendamentoMedicoPsicologico } from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/types';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { relatorioAgendamentoMedicoPsicologicoClear } from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';

// UTILS
import { onlyLetters } from 'utils/genericFunctions';

/// FORM
import getValidationsErrors from 'utils/getValidationsErrors';
import ButtonImage from 'components/Common/ButtonImage';
import { useDispatch } from 'react-redux';
// import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import hasError from 'utils/getFormErrors';
// import ModalProcurador from '../../ModalProcurador';
import PDFViewer from 'components/Common/PDFViewer';
import { consultarDigitaisClear } from 'store/modules/api/utils/consultarDigitais/actions';
import { initialValues, schema } from './form';
import Documento from './Documento';

interface IProps {
	handleSubmit: (values: any) => void;
	handleClear?: () => void;
	messageAlert?: string;
	relatorio?: relatorioAgendamentoMedicoPsicologico;
	routeBackButton?: string;
	specificEnum?: typeof EnumCNH.tipoDocumentoEnum;
}

const Pesquisa: React.FC<IProps> = ({
	handleSubmit,
	handleClear,
	messageAlert = '',
	relatorio = null,
	routeBackButton,
	specificEnum,
}) => {
	const [modalPrint, setModalPrint] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(consultarDigitaisClear());
	}, [dispatch]);

	const handleCleanNotification = useCallback(() => {}, []);

	return (
		<>
			<Section title="Pesquisar">
				{messageAlert && (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Alert
								type="warning"
								message={messageAlert}
								onClose={handleCleanNotification}
							/>
						</Col>
					</Row>
				)}
				<Row gutter={[0, 10]}>
					<Col span={8}>
						<p>
							<strong>Pesquise por um dos critérios abaixo: </strong>
						</p>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={values => getValidationsErrors(values, schema)}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row gutter={[0, 10]}>
										<Col offset={2} span={8}>
											<Field
												as={Select}
												title="Tipo de Documento"
												titleSize="md"
												titleAlign="end"
												name="tipoDocumento"
												options={specificEnum || EnumCNH.tipoDocumentoEnum}
												onChange={(v: number) =>
													formik.setFieldValue('tipoDocumento', v)
												}
												defaultFirstOption={false}
												defaultValue="CPF"
											/>
										</Col>
										<Documento formik={formik} />
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={6} style={{ maxWidth: '220px' }}>
											<p>
												<strong>Não tem o número dos documentos? </strong>
											</p>
										</Col>
										<Col
											style={{ cursor: 'pointer' }}
											span={6}
											onClick={() =>
												formik.setFieldValue(
													'buscaPorNome',
													!formik.values.buscaPorNome,
												)
											}
										>
											<p>
												<strong style={{ color: '#333333' }}>
													Realize a busca por nome
												</strong>
											</p>
										</Col>
									</Row>

									{formik.values.buscaPorNome && (
										<>
											<Row gutter={[0, 10]}>
												<Col span={14} offset={2}>
													<Field
														as={Input}
														title="Nome Completo"
														name="nome"
														titleSize="md"
														titleAlign="end"
														maxLength="70"
														value={onlyLetters(formik.values.nome)}
														required
														error={hasError(formik.errors, 'nome')}
													/>
												</Col>
											</Row>

											<Row gutter={[0, 10]} align="middle">
												<Col offset={2} span={6}>
													<Field
														as={ValidDataInput}
														title="Data de Nascimento"
														name="dataNascimento"
														type="text"
														titleSize="md"
														titleAlign="end"
														mask="99/99/9999"
														formik={formik}
														onChange={(v: string) =>
															formik.setFieldValue('dataNascimento', v)
														}
														required
														error={hasError(formik.errors, 'dataNascimento')}
													/>
												</Col>
												<Col offset={1} span={3}>
													<p>
														<strong>(apenas números)</strong>
													</p>
												</Col>
											</Row>
										</>
									)}

									<Row gutter={[0, 20]}>
										<Col span={24} />
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => {
													setModalPrint(false);
													dispatch(
														relatorioAgendamentoMedicoPsicologicoClear(),
													);
													dispatch(clearNotifications());
													formik.handleReset();
													handleClear && handleClear();
												}}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>

										{relatorio && relatorio.data && relatorio.data !== null ? (
											<Col offset={1}>
												<ButtonImage
													src="btn-imprimir-agendamento-medico-psicologico"
													onClick={() => {
														setModalPrint(true);
													}}
												/>
											</Col>
										) : (
											''
										)}
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
				{/* <ModalProcurador
					modalVisible={modalVisible}
					handleSubmit={handleSubmitModal}
				/> */}
			</Section>

			{modalPrint && (
				<PDFViewer
					title="agendamentoMedicoViewer"
					source={relatorio?.data?.arquivoPDF || ''}
					onClose={() => setModalPrint(false)}
					renderMode="popup"
				/>
			)}

			<ButtonVoltar route={routeBackButton} />
		</>
	);
};

export default Pesquisa;
