/* eslint-disable radix */
import { RecuperarAtendimento } from 'store/modules/api/sgu/atendimento/recuperar/types';
import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';
import {
	ILogin,
	IParametrosLocalidade,
} from 'store/modules/api/sgu/loginUnico/types';
import { formatDateToMileseconds } from 'utils/dateFunctions';
import {
	limparMascara,
	validacaoData,
	verificarMaioridade,
} from 'utils/genericFunctions';
import * as Yup from 'yup';

export interface SguData {
	recuperarAtendimento: RecuperarAtendimento;
	salvarAtendimento: SalvarAtendimento;
	login: ILogin;
	user: {
		cpf: string;
		nome: string;
		orgaos: any;
		login?: boolean;
		parametrosSistema: IParametrosLocalidade[];
		perfil: any;
	};
}
export interface ICadastroSolicitante {
	idSolicitacao?: number;
	destinatarioSolicitacao?: string;
	// rg
	RGempty?: boolean;
	// Tipo solicitante
	tipoSolicitante: number;

	// Termos
	aceiteTermo: boolean;
	aceiteTermoDeclaracaoPobreza: boolean;
	aceiteAcrescNomePai: boolean;
	aceiteAcrescimoNome: boolean;
	informacoesAdicionais: string;
	protocolo?: string;
	status?: string;
	statusCode?: number | null;

	mensagem?: string;
	dataCriacao: number;
	dataModificacao: number;
	idCidadao: string;
	idAtendimento: string;
	emailContato: string;

	anexos: Anexos[];
	anexosTipo?: string;

	// Filho ou Filha
	filho: {
		nome: string;
		dtNascimento: string;
		cpf: string;
		email?: string;
		sexo: string;
		endereco?: {
			logradouro: string;
			numero: string;
			complemEndereco: string;
			bairro: string;
			cep: string;
			cidade: string;
			uf: string;
		};
		rg?: {
			numero: string;
			digito: string;
			uf: string;
		};
		telefones?: {
			fixo: string;
			celular: string;
			comercial: string;
		};
		genitor: {
			nome: string;
			sexo: string;
			cpf?: string;
			email?: string;
			dtNascimento?: string;
			nomeMae?: string;
			rg?: {
				numero: string;
				digito: string;
				uf: string;
			};
			telefones?: {
				fixo: string;
				celular: string;
				comercial: string;
			};
			endereco?: {
				logradouro: string;
				complemEndereco: string;
				numero: string;
				bairro: string;
				cep: string;
				cidade: string;
				uf: string;
			};
			outrasInformacoes: string;
		};
	};
	responsavel?: {
		nome?: string;
		dtNascimento?: string;
		email?: string;
		sexo?: string;
		cpf?: string;
		rg?: {
			numero: string;
			digito: string;
			uf: string;
		};
		telefones?: {
			fixo: string;
			celular: string;
			comercial: string;
		};
		endereco?: {
			logradouro: string;
			complemEndereco: string;
			numero: string;
			bairro: string;
			cep: string;
			cidade: string;
			uf: string;
		};
	};
}

interface Anexos {
	nome: string;
	tipo: string;
	conteudo: string;
}

export const initialValues: ICadastroSolicitante = {
	mensagem: '',
	dataCriacao: 0,
	dataModificacao: 0,
	idCidadao: '',
	idAtendimento: '',
	emailContato: '',
	idSolicitacao: 0,
	RGempty: false,
	tipoSolicitante: 0,
	anexos: [],
	anexosTipo: '',
	filho: {
		nome: '',
		dtNascimento: '',
		cpf: '',
		sexo: '',
		email: '',
		endereco: {
			logradouro: '',
			numero: '',
			complemEndereco: '',
			bairro: '',
			cep: '',
			cidade: '',
			uf: '',
		},
		rg: {
			numero: '',
			digito: '',
			uf: '',
		},
		telefones: {
			fixo: '',
			celular: '',
			comercial: '',
		},
		genitor: {
			nome: '',
			sexo: '',
			dtNascimento: '',
			outrasInformacoes: '',
			nomeMae: '',
			rg: {
				numero: '',
				digito: '',
				uf: '',
			},
			telefones: {
				fixo: '',
				celular: '',
				comercial: '',
			},
			endereco: {
				logradouro: '',
				complemEndereco: '',
				numero: '',
				bairro: '',
				cep: '',
				cidade: '',
				uf: '',
			},
		},
	},
	responsavel: {
		nome: '',
		dtNascimento: '',
		sexo: '',
		cpf: '',
		rg: {
			numero: '',
			digito: '',
			uf: '',
		},
		telefones: {
			fixo: '',
			celular: '',
			comercial: '',
		},
		endereco: {
			logradouro: '',
			complemEndereco: '',
			numero: '',
			bairro: '',
			cep: '',
			cidade: '',
			uf: '',
		},
	},
	aceiteTermo: false,
	aceiteAcrescNomePai: false,
	aceiteTermoDeclaracaoPobreza: false,
	aceiteAcrescimoNome: false,
	informacoesAdicionais: '',
};

export const schema = [
	Yup.object<ICadastroSolicitante>().shape({
		filho: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Interessado : Nome Completo.`,
			),
			email: Yup.string().required(`Campo Obrigatório: Interessado : E-mail.`),
			dtNascimento: Yup.string()
				.required(`Campo Obrigatório: Interessado : Data de Nascimento.`)
				// eslint-disable-next-line func-names
				.test(
					'test-name',
					() => `Essa solicitação é apenas para Filhos maiores de Idade.`,
					(value: any) =>
						value === '' || !validacaoData(value)
							? true
							: verificarMaioridade(value),
				)
				.test(
					'test-name',
					value =>
						`Data de Nascimento: '${value.value}' não é uma data válida.`,
					(value: any) => (value === '' ? true : validacaoData(value)),
				),
			sexo: Yup.string().required(`Campo Obrigatório: Interessado : Sexo.`),
			cpf: Yup.string().when(['dtNascimento'], {
				is: dtNascimento =>
					verificarMaioridade(dtNascimento) === true || dtNascimento === '',
				then: Yup.string().required(`Campo Obrigatório: Interessado : CPF.`),
			}),
			endereco: Yup.object().shape({
				logradouro: Yup.string().required(
					`Campo Obrigatório: Interessado : Logradouro.`,
				),
				numero: Yup.string().required(
					`Campo Obrigatório: Interessado : Número.`,
				),
				bairro: Yup.string().required(
					`Campo Obrigatório: Interessado : Bairro.`,
				),
				cep: Yup.string().required(`Campo Obrigatório: Interessado : CEP.`),
				cidade: Yup.string().required(
					`Campo Obrigatório: Interessado : Cidade.`,
				),
				uf: Yup.string().required(
					`Campo Obrigatório: Interessado : UF Endereço.`,
				),
			}),
			telefone: Yup.string().when('telefones', {
				is: telefones =>
					telefones.fixo === '' &&
					telefones.celular === '' &&
					telefones.comercial === '',
				then: Yup.string().required(
					`Necessário informar pelo menos 1 telefone para contato do solicitante.`,
				),
			}),
			genitor: Yup.object().shape({
				nome: Yup.string().required(
					`Campo Obrigatório: Genitor : Nome Completo.`,
				),
				sexo: Yup.string().required(`Campo Obrigatório: Genitor : Sexo.`),
			}),
		}),
		termo1: Yup.string().when('aceiteTermo', {
			is: aceiteTermo => aceiteTermo === false,
			then: Yup.string().required(`Campo Obrigatório: Interessado : Termo 1.`),
		}),
		anexos: Yup.array().min(
			1,
			`Necessário incluir pelo menos 1 anexo na solicitação.`,
		),
	}),
	Yup.object<ICadastroSolicitante>().shape({
		filho: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Interessado : Nome Completo.`,
			),
			dtNascimento: Yup.string()
				.required(`Campo Obrigatório: Interessado : Data de Nascimento.`)
				.test(
					'test-name',
					value =>
						`Data de Nascimento: '${value.value}' não é uma data válida.`,
					(value: any) => (value === '' ? true : validacaoData(value)),
				),
			sexo: Yup.string().required(`Campo Obrigatório: Interessado : Sexo.`),
			cpf: Yup.string().when(['dtNascimento'], {
				is: dtNascimento =>
					verificarMaioridade(dtNascimento) === true || dtNascimento === '',
				then: Yup.string().required(`Campo Obrigatório: Interessado : CPF.`),
			}),
			genitor: Yup.object().shape({
				nome: Yup.string().required(
					`Campo Obrigatório: Genitor : Nome Completo.`,
				),
				sexo: Yup.string().required(`Campo Obrigatório: Genitor : Sexo.`),
			}),
		}),
		anexos: Yup.array().min(
			1,
			`Necessário incluir pelo menos 1 anexo na solicitação.`,
		),
		responsavel: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Responsável : Nome Completo.`,
			),
			email: Yup.string().required(`Campo Obrigatório: Responsável : E-mail.`),
			dtNascimento: Yup.string()
				.required(`Campo Obrigatório: Responsável : Data de Nascimento.`)
				.test(
					'test-name',
					value =>
						`Data de Nascimento: '${value.value}' não é uma data válida.`,
					(value: any) => (value === '' ? true : validacaoData(value)),
				),
			sexo: Yup.string().required(`Campo Obrigatório: Responsável : Sexo.`),
			cpf: Yup.string().when(['dtNascimento'], {
				is: dtNascimento =>
					verificarMaioridade(dtNascimento) === true || dtNascimento === '',
				then: Yup.string().required(`Campo Obrigatório: Responsável : CPF.`),
			}),
			endereco: Yup.object().shape({
				logradouro: Yup.string().required(
					`Campo Obrigatório: Genitor : Logradouro.`,
				),
				numero: Yup.string().required(
					`Campo Obrigatório: Responsável : Número.`,
				),
				bairro: Yup.string().required(
					`Campo Obrigatório: Responsável : Bairro.`,
				),
				cep: Yup.string().required(`Campo Obrigatório: Responsável : CEP.`),
				cidade: Yup.string().required(
					`Campo Obrigatório: Responsável : Cidade.`,
				),
				uf: Yup.string().required(`Campo Obrigatório: Responsável : UF.`),
			}),
			telefone: Yup.string().when('telefones', {
				is: telefones =>
					telefones.fixo === '' &&
					telefones.celular === '' &&
					telefones.comercial === '',
				then: Yup.string().required(
					`Necessário informar pelo menos 1 telefone para contato do solicitante.`,
				),
			}),
		}),
		termo1: Yup.string().when('aceiteTermo', {
			is: aceiteTermo => aceiteTermo === false,
			then: Yup.string().required(`Campo Obrigatório: Responsável : Termo 1.`),
		}),
	}),
	Yup.object<ICadastroSolicitante>().shape({
		filho: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Interessado : Nome Completo.`,
			),
			dtNascimento: Yup.string()
				.required(`Campo Obrigatório: Interessado : Data de Nascimento.`)
				// eslint-disable-next-line func-names
				.test(
					'test-name',
					value =>
						`Data de Nascimento: '${value.value}' não é uma data válida.`,
					(value: any) => (value === '' ? true : validacaoData(value)),
				),
			sexo: Yup.string().required(`Campo Obrigatório: Interessado : Sexo.`),
			cpf: Yup.string().when(['dtNascimento'], {
				is: dtNascimento =>
					verificarMaioridade(dtNascimento) === true || dtNascimento === '',
				then: Yup.string().required(`Campo Obrigatório: Interessado : CPF.`),
			}),
			genitor: Yup.object().shape({
				nome: Yup.string().required(
					`Campo Obrigatório: Genitor : Nome Completo.`,
				),
				cpf: Yup.string().required(`Campo Obrigatório: Genitor : CPF.`),
				dtNascimento: Yup.string().required(
					`Campo Obrigatório: Genitor : Data de Nascimento.`,
				),
				sexo: Yup.string().required(`Campo Obrigatório: Genitor : Sexo.`),
				email: Yup.string().required(`Campo Obrigatório: Genitor : E-mail.`),
				endereco: Yup.object().shape({
					logradouro: Yup.string().required(
						`Campo Obrigatório: Genitor : Logradouro.`,
					),
					numero: Yup.string().required(`Campo Obrigatório: Genitor : Número.`),
					bairro: Yup.string().required(`Campo Obrigatório: Genitor : Bairro.`),
					cep: Yup.string().required(`Campo Obrigatório: Genitor : CEP.`),
					cidade: Yup.string().required(`Campo Obrigatório: Genitor : Cidade.`),
					uf: Yup.string().required(`Campo Obrigatório: Genitor : UF.`),
				}),
				telefone: Yup.string().when('telefones', {
					is: telefones =>
						telefones.fixo === '' &&
						telefones.celular === '' &&
						telefones.comercial === '',
					then: Yup.string().required(
						`Necessário informar pelo menos 1 telefone para contato do solicitante.`,
					),
				}),
			}),
		}),
		termo1: Yup.string().when('aceiteTermo', {
			is: aceiteTermo => aceiteTermo === false,
			then: Yup.string().required(
				`Termo de Aceite deve ser informado(a) para a solicitação.`,
			),
		}),
		responsavel: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Responsável : Nome Completo.`,
			),
			sexo: Yup.string().required(`Campo Obrigatório: Responsável : Sexo.`),
		}),
		anexos: Yup.array().min(
			1,
			`Necessário incluir pelo menos 1 anexo na solicitação.`,
		),
	}),
];

function hasValue(obj: any, key: string) {
	if (!obj) return false;
	return (
		Object.prototype.hasOwnProperty.call(obj, key) &&
		obj[key] !== '00000000-0000-0000-0000-000000000000'
	);
}

export const getIdData = (data: SguData) => {
	const { salvarAtendimento, recuperarAtendimento } = data;
	const idData: any = {
		idCidadao: '',
		idAtendimento: '',
	};
	if (hasValue(recuperarAtendimento.data, 'idCidadao')) {
		idData.idCidadao = recuperarAtendimento.data?.idCidadao;
	}
	if (
		idData.idCidadao === '' &&
		hasValue(recuperarAtendimento.data, 'id_cidadao')
	) {
		idData.idCidadao = recuperarAtendimento.data?.id_cidadao;
	}
	if (hasValue(recuperarAtendimento.data, 'idAtendimento')) {
		idData.idAtendimento = recuperarAtendimento.data?.idAtendimento;
	}
	if (
		idData.idAtendimento === '' &&
		hasValue(recuperarAtendimento.data, 'id_atendimento')
	) {
		idData.idAtendimento = recuperarAtendimento.data?.id_atendimento;
	}
	if (
		idData.idCidadao === '' &&
		hasValue(salvarAtendimento.data, 'idCidadao')
	) {
		idData.idCidadao = salvarAtendimento.data?.idCidadao;
	}
	if (
		idData.idAtendimento === '' &&
		hasValue(salvarAtendimento.data, 'idAtendimento')
	) {
		idData.idAtendimento = salvarAtendimento.data?.idAtendimento;
	}
	return idData;
};

export function formataObjetoPortal(
	values: ICadastroSolicitante,
	solicitante: number,
	sguData: SguData,
	solicitacaoId?: string,
	send?: 'mp',
) {
	const { idCidadao, idAtendimento } = getIdData(sguData);
	const { cpf: cpfAtendente, ip, nomePosto, posto } = sguData.login.user;
	const evento = {
		id_atendimento: idAtendimento || '',
		id_cidadao:
			idCidadao !== '' ? idCidadao : '00000000-0000-0000-0000-000000000000',
		cpf: cpfAtendente,
		identificacao: limparMascara(values.filho.cpf),
		ip,
		canal: {
			id: 1,
			desc: 'Presencial',
			localidade: {
				id: parseInt(posto, 10),
				desc: nomePosto,
			},
		},
	};
	const defaultData = {
		// FORMATACAO PADRÃO
		evento,
		filho: {
			nome: values.filho.nome,
			dtNascimento: formatDateToMileseconds(values.filho.dtNascimento),
			sexo: values.filho.sexo,
			cpf: limparMascara(values.filho.cpf),
			genitor: {
				nome: values.filho.genitor.nome,
				sexo: values.filho.genitor.sexo,
				outrasInformacoes: values.filho.genitor.outrasInformacoes,
			},
		},
		aceiteTermoDeclaracaoPobreza: values.aceiteTermoDeclaracaoPobreza,
		aceiteTermo: values.aceiteTermo,
		aceiteAcrescNomePai: values.aceiteAcrescNomePai,
		idAtendente: cpfAtendente,
		origem: 'P',
		status: values.status ? values.status : '1',
	};

	let body;

	if (solicitante === 1) {
		body = {
			...defaultData,
			destinatarioSolicitacao: 1,
			filho: {
				...defaultData.filho,
				email: values.filho.email,
				endereco: {
					logradouro: values.filho.endereco?.logradouro,
					numero: values.filho.endereco?.numero,
					complemEndereco: values.filho.endereco?.complemEndereco,
					bairro: values.filho.endereco?.bairro,
					cep: values.filho.endereco?.cep,
					cidade: values.filho.endereco?.cidade,
					uf: values.filho.endereco?.uf,
				},
				rg: {
					numero: values.filho.rg?.numero ? values.filho.rg?.numero : '',
					digito:
						values.filho.rg?.numero && values.filho.rg?.digito
							? values.filho.rg?.digito
							: '',
					uf:
						values.filho.rg?.numero && values.filho.rg?.uf
							? values.filho.rg?.uf
							: '',
				},
				telefones: [
					{
						ddd: limparMascara(values.filho.telefones?.fixo).substr(0, 2),
						numero: limparMascara(values.filho.telefones?.fixo).substr(2, 8),
						tipo: 1,
					},
					{
						ddd: limparMascara(values.filho.telefones?.celular).substr(0, 2),
						numero: limparMascara(values.filho.telefones?.celular).substr(2, 9),
						tipo: 2,
					},
					{
						ddd: limparMascara(values.filho.telefones?.comercial).substr(0, 2),
						numero: limparMascara(values.filho.telefones?.comercial).substr(
							2,
							8,
						),
						tipo: 3,
					},
				],
			},
		};

		// Necessário remover os telefones vazios para envio ao serviço enviarSolicitacaoMP
		const newPhoneListFilho = [];
		for (let i = 0; i < body.filho.telefones.length; i++) {
			if (body.filho.telefones[i].ddd !== '') {
				newPhoneListFilho.push(body.filho.telefones[i]);
			}
		}
		body.filho.telefones = newPhoneListFilho;
	} else if (solicitante === 2) {
		defaultData.evento.identificacao = limparMascara(values.responsavel?.cpf);
		body = {
			...defaultData,
			destinatarioSolicitacao: 2,
			filho: {
				...defaultData.filho,
				email: values.filho.email,
				endereco: {
					logradouro: values.filho.endereco?.logradouro,
					numero: values.filho.endereco?.numero,
					complemEndereco: values.filho.endereco?.complemEndereco,
					bairro: values.filho.endereco?.bairro,
					cep: values.filho.endereco?.cep,
					cidade: values.filho.endereco?.cidade,
					uf: values.filho.endereco?.uf,
				},
				rg: {
					numero: values.filho.rg?.numero ? values.filho.rg?.numero : '',
					digito:
						values.filho.rg?.numero && values.filho.rg?.digito
							? values.filho.rg?.digito
							: '',
					uf:
						values.filho.rg?.numero && values.filho.rg?.uf
							? values.filho.rg?.uf
							: '',
				},
				telefones: [
					{
						ddd: limparMascara(values.filho.telefones?.fixo).substr(0, 2),
						numero: limparMascara(values.filho.telefones?.fixo).substr(2, 8),
						tipo: 1,
					},
					{
						ddd: limparMascara(values.filho.telefones?.celular).substr(0, 2),
						numero: limparMascara(values.filho.telefones?.celular).substr(2, 9),
						tipo: 2,
					},
					{
						ddd: limparMascara(values.filho.telefones?.comercial).substr(0, 2),
						numero: limparMascara(values.filho.telefones?.comercial).substr(
							2,
							8,
						),
						tipo: 3,
					},
				],
				genitor: {
					...defaultData.filho.genitor,
				},
			},
			responsavel: {
				nome: values.responsavel?.nome,
				email: values.responsavel?.email,
				dtNascimento: values.responsavel?.dtNascimento
					? formatDateToMileseconds(values.responsavel.dtNascimento)
					: '',
				sexo: values.responsavel?.sexo,
				cpf: limparMascara(values.responsavel?.cpf),
				rg: {
					numero: values.responsavel?.rg?.numero
						? values.responsavel.rg?.numero
						: '',
					digito:
						values.responsavel?.rg?.numero && values.responsavel.rg?.digito
							? values.responsavel.rg?.digito
							: '',
					uf:
						values.responsavel?.rg?.numero && values.responsavel.rg?.uf
							? values.responsavel.rg?.uf
							: '',
				},
				telefones: [
					{
						ddd: limparMascara(values.responsavel?.telefones?.fixo).substr(
							0,
							2,
						),
						numero: limparMascara(values.responsavel?.telefones?.fixo).substr(
							2,
							8,
						),
						tipo: 1,
					},
					{
						ddd: limparMascara(values.responsavel?.telefones?.celular).substr(
							0,
							2,
						),
						numero: limparMascara(
							values.responsavel?.telefones?.celular,
						).substr(2, 9),
						tipo: 2,
					},
					{
						ddd: limparMascara(values.responsavel?.telefones?.comercial).substr(
							0,
							2,
						),
						numero: limparMascara(
							values.responsavel?.telefones?.comercial,
						).substr(2, 8),
						tipo: 3,
					},
				],
				endereco: {
					logradouro: values.responsavel?.endereco?.logradouro,
					complemEndereco: values.responsavel?.endereco?.complemEndereco,
					numero: values.responsavel?.endereco?.numero,
					bairro: values.responsavel?.endereco?.bairro,
					cep: values.responsavel?.endereco?.cep,
					cidade: values.responsavel?.endereco?.cidade,
					uf: values.responsavel?.endereco?.uf,
				},
			},
		};

		// Necessário remover os telefones vazios para envio ao serviço enviarSolicitacaoMP
		const newPhoneListFilho = [];
		for (let i = 0; i < body.filho.telefones.length; i++) {
			if (body.filho.telefones[i].ddd !== '') {
				newPhoneListFilho.push(body.filho.telefones[i]);
			}
		}
		body.filho.telefones = newPhoneListFilho;

		// Necessário remover os telefones vazios para envio ao serviço enviarSolicitacaoMP
		const newPhoneListResponsavel = [];
		for (let i = 0; i < body.responsavel.telefones.length; i++) {
			if (body.responsavel.telefones[i].ddd !== '') {
				newPhoneListResponsavel.push(body.responsavel.telefones[i]);
			}
		}
		body.responsavel.telefones = newPhoneListResponsavel;
	} else if (solicitante === 3) {
		defaultData.evento.identificacao = limparMascara(values.filho.genitor.cpf);
		body = {
			...defaultData,
			destinatarioSolicitacao: 3,
			filho: {
				...defaultData.filho,
				email: values.filho.email,
				endereco: {
					logradouro: values.filho.endereco?.logradouro,
					numero: values.filho.endereco?.numero,
					complemEndereco: values.filho.endereco?.complemEndereco,
					bairro: values.filho.endereco?.bairro,
					cep: values.filho.endereco?.cep,
					cidade: values.filho.endereco?.cidade,
					uf: values.filho.endereco?.uf,
				},
				rg: {
					numero: values.filho.rg?.numero ? values.filho.rg?.numero : '',
					digito:
						values.filho.rg?.numero && values.filho.rg?.digito
							? values.filho.rg?.digito
							: '',
					uf:
						values.filho.rg?.numero && values.filho.rg?.uf
							? values.filho.rg?.uf
							: '',
				},
				telefones: [
					{
						ddd: limparMascara(values.filho.telefones?.fixo).substr(0, 2),
						numero: limparMascara(values.filho.telefones?.fixo).substr(2, 8),
						tipo: 1,
					},
					{
						ddd: limparMascara(values.filho.telefones?.celular).substr(0, 2),
						numero: limparMascara(values.filho.telefones?.celular).substr(2, 9),
						tipo: 2,
					},
					{
						ddd: limparMascara(values.filho.telefones?.comercial).substr(0, 2),
						numero: limparMascara(values.filho.telefones?.comercial).substr(
							2,
							8,
						),
						tipo: 3,
					},
				],
				genitor: {
					...defaultData.filho.genitor,
					dtNascimento: values.filho.genitor.dtNascimento
						? formatDateToMileseconds(values.filho.genitor.dtNascimento)
						: '',

					email: values.filho.genitor.email,
					cpf: limparMascara(values.filho.genitor.cpf),
					nomeMae: values.filho.genitor.nomeMae,
					endereco: {
						logradouro: values.filho.genitor.endereco?.logradouro,
						numero: values.filho.genitor.endereco?.numero,
						complemEndereco: values.filho.genitor.endereco?.complemEndereco,
						bairro: values.filho.genitor.endereco?.bairro,
						cep: values.filho.genitor.endereco?.cep,
						cidade: values.filho.genitor.endereco?.cidade,
						uf: values.filho.genitor.endereco?.uf,
					},
					rg: {
						numero: values.filho.genitor.rg?.numero
							? values.filho.genitor.rg?.numero
							: '',
						digito:
							values.filho.genitor.rg?.numero && values.filho.genitor.rg?.digito
								? values.filho.genitor.rg?.digito
								: '',
						uf:
							values.filho.genitor.rg?.numero && values.filho.genitor.rg?.uf
								? values.filho.genitor.rg?.uf
								: '',
					},
					telefones: [
						{
							ddd: limparMascara(values.filho.genitor.telefones?.fixo).substr(
								0,
								2,
							),
							numero: limparMascara(
								values.filho.genitor.telefones?.fixo,
							).substr(2, 8),
							tipo: 1,
						},
						{
							ddd: limparMascara(
								values.filho.genitor.telefones?.celular,
							).substr(0, 2),
							numero: limparMascara(
								values.filho.genitor.telefones?.celular,
							).substr(2, 9),
							tipo: 2,
						},
						{
							ddd: limparMascara(
								values.filho.genitor.telefones?.comercial,
							).substr(0, 2),
							numero: limparMascara(
								values.filho.genitor.telefones?.comercial,
							).substr(2, 8),
							tipo: 3,
						},
					],
				},
			},
			responsavel: {
				nome: values.responsavel?.nome,
				dtNascimento: values.responsavel?.dtNascimento
					? formatDateToMileseconds(values.responsavel?.dtNascimento)
					: '',
				sexo: values.responsavel?.sexo,
				cpf: limparMascara(values.responsavel?.cpf),
				rg: {
					numero: values.responsavel?.rg?.numero
						? values.responsavel.rg?.numero
						: '',
					digito:
						values.responsavel?.rg?.numero && values.responsavel.rg?.digito
							? values.responsavel.rg?.digito
							: '',
					uf:
						values.responsavel?.rg?.numero && values.responsavel.rg?.uf
							? values.responsavel.rg?.uf
							: '',
				},
				telefones: [
					{
						ddd: limparMascara(values.responsavel?.telefones?.fixo).substr(
							0,
							2,
						),
						numero: limparMascara(values.responsavel?.telefones?.fixo).substr(
							2,
							8,
						),
						tipo: 1,
					},
					{
						ddd: limparMascara(values.responsavel?.telefones?.celular).substr(
							0,
							2,
						),
						numero: limparMascara(
							values.responsavel?.telefones?.celular,
						).substr(2, 9),
						tipo: 2,
					},
					{
						ddd: limparMascara(values.responsavel?.telefones?.comercial).substr(
							0,
							2,
						),
						numero: limparMascara(
							values.responsavel?.telefones?.comercial,
						).substr(2, 8),
						tipo: 3,
					},
				],

				endereco: {
					logradouro: values.responsavel?.endereco?.logradouro,
					complemEndereco: values.responsavel?.endereco?.complemEndereco,
					numero: values.responsavel?.endereco?.numero,
					bairro: values.responsavel?.endereco?.bairro,
					cep: values.responsavel?.endereco?.cep,
					cidade: values.responsavel?.endereco?.cidade,
					uf: values.responsavel?.endereco?.uf,
				},
			},
		};

		// Necessário remover os telefones vazios para envio ao serviço enviarSolicitacaoMP
		const newPhoneListFilho = [];
		for (let i = 0; i < body.filho.telefones.length; i++) {
			if (body.filho.telefones[i].ddd !== '') {
				newPhoneListFilho.push(body.filho.telefones[i]);
			}
		}
		body.filho.telefones = newPhoneListFilho;

		// Necessário remover os telefones vazios para envio ao serviço enviarSolicitacaoMP
		const newPhoneListGenitor = [];
		for (let i = 0; i < body.filho.genitor.telefones.length; i++) {
			if (body.filho.genitor.telefones[i].ddd !== '') {
				newPhoneListGenitor.push(body.filho.genitor.telefones[i]);
			}
		}
		body.filho.genitor.telefones = newPhoneListGenitor;

		// Necessário remover os telefones vazios para envio ao serviço enviarSolicitacaoMP
		const newPhoneListResponsavel = [];
		for (let i = 0; i < body.responsavel.telefones.length; i++) {
			if (body.responsavel.telefones[i].ddd !== '') {
				newPhoneListResponsavel.push(body.responsavel.telefones[i]);
			}
		}
		body.responsavel.telefones = newPhoneListResponsavel;
	}

	if (send) {
		body = {
			...body,
			destinatarioSolicitacao: solicitante ? solicitante.toString() : '',
			idPosto: posto,
			mensagem: values.mensagem || '',
			// TODO
			nomePosto,
			informacoesAdicionais: values.informacoesAdicionais,
			protocolo: '',
			statusCode: values.statusCode ? values.statusCode : null,
			anexos: values.anexos,
			dataCriacao: values.dataCriacao,
			dataModificacao: new Date().getTime(),
			status: values.status,
			idCidadao: values.idCidadao
				? values.idCidadao
				: idCidadao || '00000000-0000-0000-0000-000000000000',
			idAtendimento: idAtendimento || '',
			emailContato: '',
			idSolicitacao:
				solicitacaoId && solicitacaoId !== ''
					? Number(solicitacaoId)
					: (values.status && Number(values?.idSolicitacao)) || 0,
		};
	}

	return body;
}
