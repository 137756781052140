import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

// ANTD
import { Row, Col, Divider } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_AGENDAMENTO_MEDICO } from 'pages/DetranCnh/routes/paths';

// SERVICES
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';
import { ApiResponse, getApi } from 'services/_api';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import EnumCNH from 'store/modules/api/detranCnh/enum';
import { consultaNacionalidadesRequest } from 'store/modules/api/detranCnh/consulta/nacionalidades/actions';
import { consultaPaisesRequest } from 'store/modules/api/detranCnh/consulta/paises/actions';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import {
	cadastroEnderecosClear,
	cadastroEnderecosRequest,
} from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/actions';
import {
	dadosCidadaoClear,
	dadosCidadaoRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/actions';
import { DadosCidadaoRequest } from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/types';
import { GerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/types';
import {
	consultarInaptoClear,
	consultarInaptoRequest,
} from 'store/modules/api/detranCnh/sockets/consultarInapto/actions';
import { consultaEntidadeMilitarRequest } from 'store/modules/api/detranCnh/consulta/entidadeMilitar/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCnh/consulta/categoria/actions';
import { domainServiceMunicipiosRequest } from 'store/modules/api/sguService/domainService/municipios/actions';
import {
	domainServiceNaturalidadesClear,
	domainServiceNaturalidadesRequest,
} from 'store/modules/api/sguService/domainService/naturalidade/actions';
import {
	consultaNomeSocialFiliacaoClear,
	consultaNomeSocialFiliacaoRequest,
} from 'store/modules/api/detranCnh/condutor/consultaNomeSocialFiliacao/actions';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import {
	consultaPesquisaNomeSocialFiliacaoClear,
	consultaPesquisaNomeSocialFiliacaoRequest,
} from 'store/modules/api/detranCnh/IIRGDDetran/consultaPesquisaNomeSocialFiliacao/actions';
import { saveGerarRenachRequest } from 'store/modules/api/detranCnh/sockets/saveGerarRenach/actions';
import { consultaCidadesUFRequest } from 'store/modules/api/utils/consultaCidadesUF/actions';
import {
	consultaSegundaViaReabilitacaoClear,
	consultaSegundaViaReabilitacaoRequest,
} from 'store/modules/api/detranCnh/reabilitacao/consultaSegundaViaReabilitacao/actions';
import { estatisticaIncluirClear } from 'store/modules/api/sguService/estatisticasIncluir/actions';

// COMPONENTS COMMON
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import FormBox from 'components/Common/Form/FormBox';
import Input from 'components/Common/Form/Input/Input';
import Radio from 'components/Common/Form/Radio';
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';
import InputMask from 'components/Common/Form/Input/InputMask';
import Checkbox from 'components/Common/Form/Checkbox';
import ValidData from 'components/Common/Form/Input/ValidData';

// COMPONENTS
import EnderecoRecebimento from 'pages/DetranCnh/components/pages/EnderecoRecebimento';
import CEPLogradouro from 'pages/DetranCnh/components/common/Fields/CEPLogradouro';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { getOriginOfSocialName } from 'pages/DetranCnh/utils/functions/socialName';
import {
	limparMascara,
	onlyLetters,
	onlyNumbers,
	onlyNumbersAndLetters,
	onlyNumbersLettersSpace,
	removeAcentoCaracteresEspeciais,
} from 'utils/genericFunctions';
import { FormikFildObserver } from 'utils/formikObserver';
import { treatValuesCadastroEnderecoRequest } from 'pages/DetranCnh/utils/threatServicesRequest';
import { CNH_PROCESS_VALIDATION } from 'pages/DetranCnh/utils/constants/messages';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

/// FORM
import { Formik, Form, Field, FormikProps } from 'formik';
import { gerarRenachIntegradoClear } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/actions';
import { dadosPreCadastroFormSaveRequest } from 'store/modules/api/detranCnh/utils/dadosPreCadastroFormSave/actions';
import Alert from 'components/Common/Notifications/Alert';
import { IBuscarCepResultadoEndLogradouro } from 'pages/DetranCnh/components/common/Fields/CEPLogradouro/form';
import {
	schema,
	initialValues,
	treatValues,
	treatInitialValues,
	FormPrimeiraHabilitacao,
} from './form';

const StatusCondutorPrimeiraHabilitacao: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const { consultaPrimeiraHabilitacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.primeiraHabilitacao,
	);

	const { cadastroEnderecos, consultaNomeSocialFiliacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { consultaPesquisaNomeSocialFiliacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.IIRGDDetran,
	);

	const { dadosCidadao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.divisaoEquitativa,
	);

	const { consultarInapto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const { user, mainframe } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const loginDataMainframe = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.mainframe,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	const { consultaNaturalidades } = useSelector(
		(state: ApplicationState) => state.api.sguService.domainService,
	);

	const { consultaSegundaViaReabilitacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.reabilitacao,
	);

	const { consultaCidadesUF } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const {
		consultaPaises,
		consultaNacionalidades,
		consultaEntidadeMilitar,
		consultaCategoria,
	} = useSelector((state: ApplicationState) => state.api.detranCnh.consulta);

	const { dadosPreCadastroFormSave } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.utils,
	);

	const { gerarRenachIntegrado } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	// CONTABILIZAÇÃO
	const { sgu } = useSelector((state: ApplicationState) => state.api);

	const [formValues, setFormValues] = useState(initialValues);

	const [requestData, setRequestData] = useState<null | {
		renach: GerarRenachIntegradoRequest;
		cidadao: DadosCidadaoRequest;
	}>(null);

	const handleSubmit = useCallback(
		values => {
			if (!values.codigoUnidadeTransito) {
				addNotifications({
					errors: CNH_PROCESS_VALIDATION.CIRETRAN_INVALIDA,
				});
				return;
			}

			const sguData: SguData = {
				salvarAtendimento: sgu.atendimento.salvarAtendimento,
				login: sgu.loginUnico.login,
			};
			const evento = getEventoConsultar(sguData, values.cpf);

			const endereco = treatValuesCadastroEnderecoRequest(values);

			const cidadao: DadosCidadaoRequest = {
				cidadao: {
					autoriza_envio_email: values.autoriza_envio_email === 'S',
					autoriza_envio_sms: values.autoriza_envio_sms === 'S',
					cpf: values.cpf,
					celular: limparMascara(values.celular).substring(2, 11),
					ddd_celular: limparMascara(values.celular).substring(0, 2),
					ddd_telefone: limparMascara(values.telefone).substring(0, 2),
					telefone: limparMascara(values.telefone).substring(2, 11),
					email: values.email,
					necessidade_veiculo_adaptado: false,
					nome: values.nomeSocial || values.nome,
					pretende_atividade_remunerada:
						(values.pretende_atividade_remunerada &&
							(typeof values.pretende_atividade_remunerada === 'string'
								? values.pretende_atividade_remunerada === 'S'
								: values.pretende_atividade_remunerada)) ||
						false,
				},
			};

			const renach = treatValues(evento, values, user);

			setRequestData({ renach, cidadao });

			dispatch(cadastroEnderecosRequest(endereco));
			dispatch(saveGerarRenachRequest(renach));
			// SALVA OS DADOS PREENCHIDOS NO FORM.
			dispatch(
				dadosPreCadastroFormSaveRequest({
					dataPreCadastroPrimeiraHabilitacao: values,
				}),
			);
			dispatch(gerarRenachIntegradoClear());
		},
		[dispatch, user, sgu],
	);

	// BUSCA CÓDIGO NATURALIDADE
	useEffect(() => {
		if (
			consultaPrimeiraHabilitacao.data &&
			consultaPrimeiraHabilitacao.data.cpf &&
			consultaSegundaViaReabilitacao.status === 0
		) {
			dispatch(
				consultaSegundaViaReabilitacaoRequest({
					cpfCidadao: consultaPrimeiraHabilitacao.data.cpf,
				}),
			);
		}
	}, [
		dispatch,
		consultaPrimeiraHabilitacao.data,
		consultaSegundaViaReabilitacao,
	]);

	useEffect(() => {
		if (cadastroEnderecos.status === 200 && requestData !== null) {
			dispatch(dadosCidadaoRequest(requestData.cidadao));
		}
	}, [cadastroEnderecos.status, dispatch, requestData]);

	useEffect(() => {
		if (dadosCidadao.status === 200 && requestData !== null) {
			const inapto = {
				cpf: requestData.cidadao.cidadao.cpf.toString(),
				tipoExame: 'AMBOS',
				flagDeficienteFisico:
					requestData.cidadao.cidadao.necessidade_veiculo_adaptado,
			};

			dispatch(consultarInaptoRequest(inapto));
		}
	}, [dadosCidadao.status, dispatch, requestData]);

	useEffect(() => {
		if (consultarInapto.status !== 0 && consultarInapto.status !== 100) {
			dispatch(estatisticaIncluirClear());
			history.push(ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_AGENDAMENTO_MEDICO);
		}
	}, [consultarInapto.status, dispatch, history, requestData]);

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultadoEndLogradouro, formik: FormikProps<any>) => {
			formik.setFieldValue(`codigoUnidadeTransito`, res.codigoUnidadeTransito);
			formik.setFieldValue(`cep`, res.cep);
			formik.setFieldValue(
				`logradouro`,
				removeAcentoCaracteresEspeciais(res.endereco.substring(0, 30)),
			);
			formik.setFieldValue(
				`bairro`,
				removeAcentoCaracteresEspeciais(res.bairro.substring(0, 14)),
			);
			formik.setFieldValue(`logradouroCodMunicipio`, res.codigoMunicipio);
			formik.setFieldValue(
				`municipio`,
				removeAcentoCaracteresEspeciais(res.municipio),
			);
		},
		[],
	);

	useEffect(() => {
		if (!dadosPreCadastroFormSave.dataPreCadastroPrimeiraHabilitacao?.cpf) {
			dispatch(
				consultaCategoriaRequest({ funcionalidade: 'primeira_habilitacao' }),
			);
			dispatch(consultaPaisesRequest());
			dispatch(consultaNacionalidadesRequest());
			dispatch(consultaEntidadeMilitarRequest());

			if (
				consultaPrimeiraHabilitacao.data &&
				consultaPrimeiraHabilitacao.data.dadosPcad.codNaturalidade
			) {
				dispatch(
					domainServiceMunicipiosRequest({
						codigo:
							consultaPrimeiraHabilitacao.data.dadosPcad.codNaturalidade.trim(),
					}),
				);
			}
		}
	}, [consultaPrimeiraHabilitacao.data, dispatch, dadosPreCadastroFormSave]);

	const consultaCodCiretran = useCallback(async () => {
		const resDetranEndereco: ApiResponse = await getApi(
			PATH_DETRAN_CNH,
			`consulta/${CHANNEL}?cep=${consultaPrimeiraHabilitacao.data?.dadosPcad.endereco.cep}`,
		);

		if (resDetranEndereco.status === 200) {
			const endereco = resDetranEndereco.data;

			setFormValues(oldFormValues => ({
				...oldFormValues,
				codigoUnidadeTransito: endereco.codigoUnidadeTransito,
			}));
		}
	}, [consultaPrimeiraHabilitacao.data]);

	useEffect(() => {
		if (
			consultaPrimeiraHabilitacao.data &&
			consultaPrimeiraHabilitacao.data.endereco?.cep &&
			consultaPrimeiraHabilitacao.data.cpf &&
			consultaPrimeiraHabilitacao.data.cpf !== '' &&
			!dadosPreCadastroFormSave.dataPreCadastroPrimeiraHabilitacao?.cpf
		) {
			consultaCodCiretran();
		}
	}, [
		consultaCodCiretran,
		consultaPrimeiraHabilitacao.data,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			consultaPrimeiraHabilitacao.cpf &&
			!dadosPreCadastroFormSave.dataPreCadastroPrimeiraHabilitacao?.cpf
		) {
			if (consultaPrimeiraHabilitacao.data !== null) {
				const uf = consultaNaturalidades.data
					? consultaNaturalidades.data[0].municipioIBGE.uf
					: '';

				setFormValues(treatInitialValues(consultaPrimeiraHabilitacao.data, uf));

				if (consultaPrimeiraHabilitacao.data.dadosPcad.endereco.cep) {
					consultaCodCiretran();
				}
			} else {
				setFormValues({
					...initialValues,
					cpf: consultaPrimeiraHabilitacao.cpf,
				});
			}
		}
	}, [
		consultaPrimeiraHabilitacao,
		consultaNaturalidades.data,
		consultaCodCiretran,
		dadosPreCadastroFormSave,
	]);

	const habilitaNomeSocial = (
		formik: FormikProps<FormPrimeiraHabilitacao>,
		valueFlag: React.ChangeEvent<HTMLInputElement>,
	) => {
		formik.setFieldValue('flagNomeSocial', valueFlag.target.value);

		if (valueFlag.target.value === 'N' && formValues.nomeSocial === '') {
			formik.setFieldValue('nomeSocial', '');
		}
	};

	const habilitaFiliacaoAfetiva = (
		formik: FormikProps<FormPrimeiraHabilitacao>,
		valueFlag: React.ChangeEvent<HTMLInputElement>,
	) => {
		formik.setFieldValue('flagFiliacaoAfetiva', valueFlag.target.value);

		if (
			valueFlag.target.value === 'N' &&
			(formValues.paisAfetivo1 === '' || formValues.paisAfetivo2 === '')
		) {
			formik.setFieldValue('paisAfetivo1', '');

			formik.setFieldValue('paisAfetivo2', '');
		}
	};

	const handleNaturalidade = useCallback(
		(uf: string) => {
			if (uf && uf !== '') {
				dispatch(consultaCidadesUFRequest(uf));
			}
		},
		[dispatch],
	);

	const clearInitialStatesWithoutNewCallServices = useCallback(() => {
		dispatch(cadastroEnderecosClear());
		dispatch(estatisticaIncluirClear());
		dispatch(consultarInaptoClear());
		dispatch(dadosCidadaoClear());
	}, [dispatch]);

	useEffect(() => {
		if (
			consultaPrimeiraHabilitacao.cpf &&
			loginDataMainframe &&
			!dadosPreCadastroFormSave.dataPreCadastroPrimeiraHabilitacao?.cpf
		) {
			dispatch(
				consultaNomeSocialFiliacaoRequest({
					cpf: consultaPrimeiraHabilitacao.cpf,
					usuario: loginDataMainframe.idUsuario,
					senha: loginDataMainframe.senhaMainFrame,
				}),
			);
		}
	}, [
		dispatch,
		consultaPrimeiraHabilitacao,
		loginDataMainframe,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			consultaNomeSocialFiliacao.status === 200 &&
			consultaNomeSocialFiliacao.data &&
			!dadosPreCadastroFormSave.dataPreCadastroPrimeiraHabilitacao?.cpf
		) {
			const { nomeSocial, filiacao1, filiacao2 } =
				consultaNomeSocialFiliacao.data;

			setFormValues(oldFormValues => ({
				...oldFormValues,
				nomeSocial: nomeSocial?.trim() || '',
				paisAfetivo1: filiacao1?.trim() || '',
				paisAfetivo2: filiacao2?.trim() || '',
				origemNomeSocial: getOriginOfSocialName(
					consultaNomeSocialFiliacao,
					null,
				),
			}));
		}

		if (
			consultaPrimeiraHabilitacao.cpf &&
			consultaPesquisaNomeSocialFiliacao.status === 0 &&
			((consultaNomeSocialFiliacao.status !== 0 &&
				consultaNomeSocialFiliacao.status !== 200) ||
				(consultaNomeSocialFiliacao.status === 200 &&
					!consultaNomeSocialFiliacao.data?.nomeSocial?.trim()))
		) {
			dispatch(
				consultaPesquisaNomeSocialFiliacaoRequest({
					usuario: mainframe.idUsuario,
					terminal: user.ip,
					cpf: consultaPrimeiraHabilitacao.cpf,
				}),
			);
		}
	}, [
		consultaNomeSocialFiliacao,
		consultaPesquisaNomeSocialFiliacao.status,
		consultaPrimeiraHabilitacao.cpf,
		dispatch,
		mainframe.idUsuario,
		user.ip,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			consultaPesquisaNomeSocialFiliacao.status === 200 &&
			consultaPesquisaNomeSocialFiliacao.data &&
			!dadosPreCadastroFormSave.dataPreCadastroPrimeiraHabilitacao?.cpf
		) {
			setFormValues(oldData => ({
				...oldData,
				nomeSocial:
					consultaPesquisaNomeSocialFiliacao.data?.nomeSocial.trim() || '',
				origemNomeSocial: getOriginOfSocialName(
					null,
					consultaPesquisaNomeSocialFiliacao,
				),
			}));
		}
	}, [consultaPesquisaNomeSocialFiliacao, dadosPreCadastroFormSave]);

	// CONSULTA DOMAIN SERVICES PARA VALIDAR UF DA NATURALIDADE PELO CÓDIGO IBGE
	useEffect(() => {
		if (
			consultaPrimeiraHabilitacao.data &&
			consultaPrimeiraHabilitacao.data.naturalidade &&
			!dadosPreCadastroFormSave.dataPreCadastroPrimeiraHabilitacao?.cpf
		) {
			dispatch(
				domainServiceNaturalidadesRequest({
					idIbge: String(consultaPrimeiraHabilitacao.data.naturalidade).trim(),
				}),
			);
		}
	}, [consultaPrimeiraHabilitacao.data, dispatch, dadosPreCadastroFormSave]);

	useEffect(() => {
		if (
			consultaNaturalidades.status === 200 &&
			consultaNaturalidades.data &&
			consultaNaturalidades.data[0].municipioIBGE.uf
		) {
			dispatch(
				consultaCidadesUFRequest(
					consultaNaturalidades.data[0].municipioIBGE.uf,
				),
			);
		}
	}, [consultaNaturalidades.status, consultaNaturalidades.data, dispatch]);

	// RECARREGA OS DADOS DO FORM COM OS DADOS SALVOS NA STORE.
	useEffect(() => {
		if (dadosPreCadastroFormSave.dataPreCadastroPrimeiraHabilitacao?.cpf) {
			setFormValues(
				dadosPreCadastroFormSave.dataPreCadastroPrimeiraHabilitacao,
			);
		}
	}, [dispatch, dadosPreCadastroFormSave]);

	useEffect(() => {
		clearInitialStatesWithoutNewCallServices();

		if (!dadosPreCadastroFormSave.dataPreCadastroPrimeiraHabilitacao?.cpf) {
			dispatch(consultaNomeSocialFiliacaoClear());
			dispatch(consultaPesquisaNomeSocialFiliacaoClear());
			dispatch(consultaSegundaViaReabilitacaoClear());
			dispatch(domainServiceNaturalidadesClear());
		}
	}, [
		clearInitialStatesWithoutNewCallServices,
		dadosPreCadastroFormSave,
		dispatch,
	]);

	const handleFormChange = useCallback(() => {
		if (errors.length > 0) {
			dispatch(clearNotifications());
		}
	}, [dispatch, errors.length]);

	return (
		<Row>
			<Col span={24}>
				{gerarRenachIntegrado.status &&
				gerarRenachIntegrado.status !== 0 &&
				gerarRenachIntegrado.status !== 100 &&
				gerarRenachIntegrado.status !== 200 &&
				gerarRenachIntegrado.status !== 201 &&
				gerarRenachIntegrado.dataError?.mensagem &&
				((notifications.errors && !notifications.errors.length) ||
					(notifications.errors.length > 0 &&
						Array.isArray(notifications.errors) &&
						notifications.errors[0] !==
							gerarRenachIntegrado.dataError?.mensagem) ||
					(notifications.errors.length > 0 &&
						!Array.isArray(notifications.errors) &&
						notifications.errors !==
							gerarRenachIntegrado.dataError?.mensagem)) ? (
					// eslint-disable-next-line react/jsx-indent
					<Alert
						type="error"
						message={gerarRenachIntegrado.dataError?.mensagem}
						onClose={() => {}}
					/>
				) : null}

				<Section title="Status do Condutor">
					<Row>
						<Col span={12}>
							<h2>Cadastro</h2>

							<Divider style={{ marginTop: 0, backgroundColor: '#000' }} />
						</Col>
					</Row>

					<Row gutter={[0, 20]}>
						<Col span={24}>
							<p>
								Confira / atualize os dados e em seguida clique em
								&quot;Salvar&quot;.
							</p>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Formik
								enableReinitialize
								validateOnChange={false}
								validateOnBlur={false}
								initialValues={formValues}
								validate={values =>
									getValidationsErrors(values, schema, [
										'email',
										'confirmaEmail',
									])
								}
								onSubmit={handleSubmit}
							>
								{formik => (
									<>
										<Form autoComplete="off">
											<FormBox title="Dados do Processo">
												<Row gutter={[0, 10]}>
													<Col span={7}>
														<Field
															as={Input}
															title="Tipo de Processo"
															value="1ª Habilitação"
															style={{
																fontWeight: 500,
															}}
															titleSize={97}
															readOnly
														/>
													</Col>

													<Col span={8}>
														<Field
															as={Select}
															title="Categoria Pretendida"
															name="categoriaPretendida"
															options={consultaCategoria.data || []}
															onChange={(v: string) => {
																if (v === 'A') {
																	formik.setFieldValue(
																		'pretende_atividade_remunerada',
																		'N',
																	);
																}
																formik.setFieldValue('categoriaPretendida', v);
															}}
															error={!!formik.errors.categoriaPretendida}
														/>
													</Col>

													<Col offset={1} span={8}>
														<Field
															as={InputMask}
															title="Data do Pré-Cadastro"
															name="dataCadastro"
															mask="99/99/9999"
															style={{
																fontWeight: 500,
															}}
															readOnly
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={24}>
														<Field
															as={Radio}
															title="Pretende exercer atividade remunerada"
															name="pretende_atividade_remunerada"
															options={EnumCNH.radioSimNaoBoolean}
															titleQuestion
															titleSize="auto"
															disabled={
																formik.values.categoriaPretendida === 'A'
															}
															error={
																!!formik.errors.pretende_atividade_remunerada
															}
														/>
													</Col>
												</Row>

												{/* <Row gutter={[0, 10]}>
													<Col span={24}>
														<Field
															as={Radio}
															title="Tem necessidade de veículo adaptado"
															name="necessidade_veiculo_adaptado"
															options={EnumCNH.radioSimNao}
															titleQuestion
															titleSize="auto"
															error={
																!!formik.errors.necessidade_veiculo_adaptado
															}
														/>
													</Col>
												</Row> */}

												<Row gutter={[0, 10]}>
													<Col span={24}>
														<Field
															as={Radio}
															title="Exame dentro do posto"
															name="exame_dentro_do_posto"
															options={EnumCNH.radioSimNao}
															defaultValue="N"
															titleQuestion
															titleSize="auto"
															disabled
															error={!!formik.errors.exame_dentro_do_posto}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={24}>
														<Field
															as={Radio}
															title="É militar e vai utilizar o CFC da corporação"
															name="militar"
															options={EnumCNH.radioSimNao}
															onChange={(v: any) => {
																formik.setFieldValue('militar', v.target.value);

																if (v.target.value === 'N') {
																	formik.setFieldValue('codEntidade', '');
																}
															}}
															titleQuestion
															titleSize="auto"
															error={!!formik.errors.militar}
														/>
													</Col>
												</Row>

												{formik.values.militar === 'S' && (
													<Row gutter={[0, 10]}>
														<Col span={12}>
															<Field
																as={Select}
																title="Nome da entidade Militar"
																name="codEntidade"
																options={consultaEntidadeMilitar.data || []}
																onChange={(v: number) =>
																	formik.setFieldValue('codEntidade', v)
																}
																titleSize="lg"
																error={!!formik.errors.codEntidade}
															/>
														</Col>

														<Col span={12}>
															<Field
																as={Input}
																title="Nº Carteira Militar"
																name="numero_carteira_militar"
																maxLength="11"
																onChange={(
																	event: ChangeEvent<HTMLInputElement>,
																) => {
																	formik.setFieldValue(
																		'numero_carteira_militar',
																		onlyNumbers(
																			event.target.value,
																		).toLocaleUpperCase(),
																	);
																}}
																error={!!formik.errors.numero_carteira_militar}
															/>
														</Col>
													</Row>
												)}
											</FormBox>

											<FormBox title="Dados Pessoais">
												<Row gutter={[0, 10]}>
													<Col span={12}>
														<Field
															as={Input}
															value={onlyLetters(formik.values.nome)}
															title="Nome"
															name="nome"
															maxLength="70"
															error={!!formik.errors.nome}
															onChange={(v: ChangeEvent<HTMLInputElement>) =>
																formik.setFieldValue(
																	'nome',
																	v.target.value.toUpperCase(),
																)
															}
														/>

														<Field
															as={Input}
															value={onlyLetters(formik.values.nomeSocial)}
															title="Nome Social"
															name="nomeSocial"
															error={!!formik.errors.nomeSocial}
															maxLength="70"
															disabled={formik.values.flagNomeSocial !== 'S'}
															onChange={(v: ChangeEvent<HTMLInputElement>) =>
																formik.setFieldValue(
																	'nomeSocial',
																	v.target.value.toUpperCase(),
																)
															}
														/>
													</Col>

													<Col span={8} offset={1}>
														<Field
															as={Radio}
															title="Deseja utilizar o nome social na CNH"
															name="flagNomeSocial"
															options={EnumCNH.radioSimNao}
															titleQuestion
															directionRow
															titleSize="auto"
															error={!!formik.errors.flagNomeSocial}
															onChange={(
																valueFlag: React.ChangeEvent<HTMLInputElement>,
															) => habilitaNomeSocial(formik, valueFlag)}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={12}>
														<Field
															as={Input}
															value={onlyLetters(formik.values.nomePai)}
															title="Nome do Pai"
															name="nomePai"
															maxLength="70"
															error={!!formik.errors.nomePai}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={12}>
														<Field
															as={Input}
															value={onlyLetters(formik.values.nomeMae)}
															title="Nome da Mãe"
															name="nomeMae"
															maxLength="70"
															error={!!formik.errors.nomeMae}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={12}>
														<Field
															as={Input}
															value={onlyLetters(formik.values.paisAfetivo1)}
															title="Filiação Afetiva 1"
															name="paisAfetivo1"
															error={!!formik.errors.paisAfetivo1}
															maxLength="70"
															disabled={
																formik.values.flagFiliacaoAfetiva !== 'S'
															}
														/>

														<Field
															as={Input}
															value={onlyLetters(formik.values.paisAfetivo2)}
															title="Filiação Afetiva 2"
															name="paisAfetivo2"
															error={!!formik.errors.paisAfetivo2}
															maxLength="70"
															disabled={
																formik.values.flagFiliacaoAfetiva !== 'S'
															}
														/>
													</Col>

													<Col span={8} offset={1}>
														<Field
															as={Radio}
															title="Deseja incluir filiação afetiva na CNH"
															name="flagFiliacaoAfetiva"
															options={EnumCNH.radioSimNao}
															titleQuestion
															directionRow
															titleSize="auto"
															error={!!formik.errors.flagFiliacaoAfetiva}
															onChange={(
																valueFlag: React.ChangeEvent<HTMLInputElement>,
															) => habilitaFiliacaoAfetiva(formik, valueFlag)}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={12}>
														<Field
															as={Select}
															title="Sexo"
															name="sexo"
															options={EnumCNH.sexoEnum}
															onChange={(v: number) =>
																formik.setFieldValue('sexo', v)
															}
															defaultFirstOption={false}
															error={!!formik.errors.sexo}
														/>
													</Col>

													<Col offset={1} span={11}>
														<Field
															as={ValidData}
															title="Data de Nascimento"
															name="dataNascimento"
															mask="99/99/9999"
															subtitle="(DD/MM/AAAA)"
															error={!!formik.errors.dataNascimento}
															formik={formik}
															onChange={(v: string) =>
																formik.setFieldValue('dataNascimento', v)
															}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={12}>
														<Field
															as={Select}
															title="Nacionalidade"
															name="nacionalidade"
															options={consultaNacionalidades.data || []}
															onChange={(v: number | string) => {
																formik.setFieldValue('nacionalidade', v);
																if (v === '3' && formik.values.uf_RNE === '') {
																	formik.setFieldValue('uf_RNE', 'EX');
																}
															}}
															error={!!formik.errors.nacionalidade}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													{formik.values.nacionalidade !== '1' ? (
														<Col span={12}>
															<Field
																as={Select}
																title="Naturalidade"
																name="naturalidade"
																options={consultaPaises.data || []}
																onChange={(v: number) =>
																	formik.setFieldValue('naturalidade', v)
																}
																defaultFirstOption={false}
																error={!!formik.errors.naturalidade}
															/>
														</Col>
													) : (
														<>
															<Col span={12}>
																<Field
																	as={Select}
																	title="UF"
																	name="uf"
																	options={EnumCNH.ufEnum}
																	onChange={(v: string) => {
																		handleNaturalidade(v);
																		formik.setFieldValue('uf', v);
																		formik.setFieldValue('naturalidade', '');
																	}}
																/>
															</Col>
															<Col span={11}>
																<Field
																	as={Select}
																	title="Naturalidade"
																	name="naturalidade"
																	options={consultaCidadesUF.data || []}
																	onChange={(v: number) =>
																		formik.setFieldValue('naturalidade', v)
																	}
																	error={!!formik.errors.naturalidade}
																/>
															</Col>
														</>
													)}
												</Row>
											</FormBox>

											<FormBox title="Documentos">
												<Row gutter={[0, 10]}>
													<Col span={14}>
														<Field
															as={Input}
															title="CPF"
															name="cpf"
															titleSize="sm"
															disabled
															error={!!formik.errors.cpf}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={14}>
														<Field
															as={Input}
															title="RG"
															name="numero_documento"
															onChange={(v: ChangeEvent<HTMLInputElement>) =>
																formik.setFieldValue(
																	'numero_documento',
																	limparMascara(v.target.value.toUpperCase()),
																)
															}
															titleSize="sm"
															subtitle="(Não inclua o DÍGITO nem digite pontos ou hífen)"
															maxLength="20"
															error={!!formik.errors.numero_documento}
														/>
													</Col>

													<Col span={5}>
														<Field
															as={Input}
															title="Órgão Emissor"
															name="orgao_documento"
															maxLength="5"
															error={!!formik.errors.orgao_documento}
														/>
													</Col>

													<Col offset={1} span={4}>
														<Field
															as={Select}
															title="UF"
															name="uf_documento"
															options={EnumCNH.ufEnum}
															onChange={(v: number) =>
																formik.setFieldValue('uf_documento', v)
															}
															titleSize="20"
															error={!!formik.errors.uf_documento}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={14}>
														<Field
															as={Input}
															title="RNE"
															name="num_RNE"
															titleSize="sm"
															maxLength="10"
															onChange={(
																event: ChangeEvent<HTMLInputElement>,
															) => {
																formik.setFieldValue(
																	'num_RNE',
																	onlyNumbersAndLetters(
																		event.target.value,
																	).toLocaleUpperCase(),
																);
															}}
															disabled={formik.values.nacionalidade !== '3'}
															error={!!formik.errors.num_RNE}
															subtitle="(NÃO digite pontos nem hífen)"
														/>
													</Col>

													<Col span={5}>
														<Field
															as={Input}
															title="Órgão Emissor"
															name="orgao_RNE"
															maxLength="5"
															disabled={formik.values.nacionalidade !== '3'}
															error={!!formik.errors.orgao_RNE}
														/>
													</Col>

													<Col offset={1} span={4}>
														<Field
															as={Input}
															title="UF"
															name="uf_RNE"
															maxLength="2"
															value={
																formik.values.nacionalidade !== '3'
																	? ''
																	: formik.values.uf_RNE
															}
															titleSize="20"
															disabled
														/>
													</Col>
												</Row>
											</FormBox>

											<FormBox title="Endereço">
												<Row gutter={[0, 10]}>
													<Col span={8}>
														<CEPLogradouro
															name="cep"
															defaultValue={formValues.cep}
															formik={formik}
															titleSize="sm"
															resultEndLogradouro={(
																res: IBuscarCepResultadoEndLogradouro,
															) => handleCepSelecionado(res, formik)}
														/>
													</Col>

													<Col offset={1} span={15}>
														<Field
															as={Input}
															title="Logradouro"
															name="logradouro"
															titleSize="sm"
															maxLength="30"
															value={onlyNumbersLettersSpace(
																formik.values.logradouro,
															)}
															error={!!formik.errors.logradouro}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={5}>
														<Field
															as={Input}
															title="Número"
															name="numero"
															titleSize="sm"
															maxLength="6"
															disabled={formik.values.numero === '000000'}
															value={onlyNumbers(formik.values.numero)}
															error={!!formik.errors.numero}
														/>
													</Col>

													<Col offset={1} span={2}>
														<Field
															subtitle="S/N"
															name="numero"
															as={Checkbox}
															checked={formik.values.numero === '000000'}
															onChange={(e: ChangeEvent<HTMLInputElement>) => {
																const { checked } = e.target;

																formik.setFieldValue(
																	'numero',
																	checked ? '000000' : '',
																);
															}}
														/>
													</Col>
													<Col offset={1} span={15}>
														<Field
															as={Input}
															title="Complemento"
															name="complemento"
															titleSize="sm"
															maxLength="20"
															value={onlyNumbersLettersSpace(
																formik.values.complemento,
															)}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={8}>
														<Field
															as={Input}
															title="Bairro"
															name="bairro"
															titleSize="sm"
															maxLength="14"
															error={!!formik.errors.bairro}
															value={onlyNumbersLettersSpace(
																formik.values.bairro,
															)}
														/>
													</Col>

													<Col offset={1} span={15}>
														<Field
															as={Input}
															title="Município"
															name="municipio"
															titleSize="sm"
															disabled
															error={!!formik.errors.municipio}
														/>
													</Col>
												</Row>
											</FormBox>

											<FormBox title="Contato">
												<Row gutter={[0, 10]}>
													<Col span={11}>
														<Field
															as={InputMask}
															title="Telefone"
															name="telefone"
															mask="(99)9999-9999"
															titleSize="sm"
															error={!!formik.errors.telefone}
														/>
													</Col>

													<Col offset={1} span={12}>
														<Field
															as={Input}
															title="E-mail"
															name="email"
															maxLength="60"
															onChange={(v: ChangeEvent<HTMLInputElement>) =>
																formik.setFieldValue(
																	'email',
																	v.target.value.toUpperCase(),
																)
															}
															error={!!formik.errors.email}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={11}>
														<Field
															as={InputMask}
															title="Celular"
															name="celular"
															mask="(99)99999-9999"
															titleSize="sm"
															error={!!formik.errors.celular}
														/>
													</Col>

													<Col offset={1} span={12}>
														<Field
															as={Input}
															title="Confirmar e-mail"
															name="confirmaEmail"
															maxLength="60"
															onChange={(v: ChangeEvent<HTMLInputElement>) =>
																formik.setFieldValue(
																	'confirmaEmail',
																	v.target.value.toUpperCase(),
																)
															}
															error={!!formik.errors.email}
														/>
													</Col>
												</Row>

												<Row gutter={[0, 10]} style={{ marginLeft: '90px' }}>
													<Col>
														<Field
															subtitle="Autorizo o envio de SMS pelo DETRAN"
															name="autoriza_sms"
															as={Checkbox}
															checked={formik.values.autoriza_sms === 'S'}
															onChange={(e: ChangeEvent<HTMLInputElement>) => {
																const { checked } = e.target;

																formik.setFieldValue(
																	'autoriza_sms',
																	checked ? 'S' : 'N',
																);
															}}
														/>
													</Col>

													<Col>
														<Field
															subtitle="Autorizo o envio de E-mail pelo DETRAN"
															name="autoriza_email"
															as={Checkbox}
															checked={formik.values.autoriza_email === 'S'}
															onChange={(e: ChangeEvent<HTMLInputElement>) => {
																const { checked } = e.target;

																formik.setFieldValue(
																	'autoriza_email',
																	checked ? 'S' : 'N',
																);
															}}
														/>
													</Col>
												</Row>
											</FormBox>

											<Row>
												<Col>
													<EnderecoRecebimento
														formik={formik}
														cpf={formik.values.cpf}
													/>
												</Col>
											</Row>

											<Row justify="center">
												<Col offset={1}>
													<ButtonImage type="submit" src="salvar" />
												</Col>
											</Row>
										</Form>

										<FormikFildObserver
											dirty={formik.dirty}
											values={formik.values}
											onChange={handleFormChange}
										/>
									</>
								)}
							</Formik>
						</Col>
					</Row>
				</Section>

				<Row justify="space-between" align="middle">
					<Col>
						<ButtonVoltar
							route="/detran-cnh/primeira-habilitacao/pesquisa"
							onClick={() => {
								dispatch(detranCnhClear());
							}}
						/>
					</Col>

					<Col>
						<ButtonImage
							type="button"
							src="outro-servico-detran"
							onClick={() => {
								dispatch(detranCnhClear());
								history.push('/detran-cnh');
							}}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default StatusCondutorPrimeiraHabilitacao;
