import produce from 'immer';
// TYPES
import { Types, confirmaPreCadastroRenovacao } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: confirmaPreCadastroRenovacao = {
	status: 0,
};

export default function confirmaPreCadastroRenovacaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): confirmaPreCadastroRenovacao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONFIRMA_PRE_CADASTRO_RENOVACAO: {
				break;
			}

			case Types.CONFIRMA_PRE_CADASTRO_RENOVACAO_SUCCESS: {
				draft.status = action.payload.status;
				break;
			}

			case Types.CONFIRMA_PRE_CADASTRO_RENOVACAO_FAILURE: {
				break;
			}

			default:
		}
	});
}
