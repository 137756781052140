import * as Yup from 'yup';

export interface ITelefone {
	id?: string;
	ddd: string;
	dddNumero?: string;
	numero: string;
	tipo?: string | number;
	tipoTelefone: string | number;
	complemento: string;
	sms: string;
}

export const initialValues: ITelefone = {
	id: '',
	ddd: '',
	numero: '',
	tipo: '',
	tipoTelefone: '',
	complemento: '',
	sms: '',
};

export const schemaTelefone = Yup.object<ITelefone>().shape({
	tipoTelefone: Yup.string().required(`Campo Obrigatório: Tipo Telefone`),
	ddd: Yup.string().required(`Campo Obrigatório: DDD`),
	numero: Yup.string().required(`Campo Obrigatório: Número`),
});
