import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { Types, AtualizarMunicipioRequest } from './types';

// ACTIONS
import {
	atualizarMunicipioSuccess,
	atualizarMunicipioFailure,
} from './actions';

function* atualizarMunicipioRequest(request: ReducerAction) {
	const { payload }: { payload: AtualizarMunicipioRequest } = request;

	let body = { ...payload };

	delete body.codigo;

	body = cleanEmpty(body) as AtualizarMunicipioRequest;

	const response: ApiResponse = yield call(
		putApi,
		PATH_SGU,
		`municipios/${payload.codigo}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarMunicipioSuccess(response));
	} else {
		yield put(atualizarMunicipioFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_MUNICIPIO_REQUEST, atualizarMunicipioRequest),
]);
