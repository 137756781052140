import { all } from 'redux-saga/effects';

import dominiosSaga from './dominios/saga';
import ensinoSaga from './ensino/saga';
import eixoSaga from './eixo/saga';
import escolasProximasSaga from './escolasProximas/saga';
import comboUfSaga from './uf/saga';
import dadosBasicosSaga from './dadosBasicos/saga';
import tipoEnsinoSaga from './tiposEnsino/saga';

export default all([
	dominiosSaga,
	ensinoSaga,
	eixoSaga,
	escolasProximasSaga,
	comboUfSaga,
	dadosBasicosSaga,
	tipoEnsinoSaga,
]);
