export enum Types {
	CANCELAR_INTENCAO_VENDA_REQUEST = '@DETRAN-CRV/CANCELAR_INTENCAO_VENDA_REQUEST',
	CANCELAR_INTENCAO_VENDA_SUCCESS = '@DETRAN-CRV/CANCELAR_INTENCAO_VENDA_SUCCESS',
	CANCELAR_INTENCAO_VENDA_FAILURE = '@DETRAN-CRV/CANCELAR_INTENCAO_VENDA_FAILURE',
	CANCELAR_INTENCAO_VENDA_CLEAR = '@DETRAN-CRV/CANCELAR_INTENCAO_VENDA_CLEAR',
}

export interface CancelarIntencaoVenda {
	status: number;
	data: CancelarIntencaoVendaResponseData | null;
}

export interface CancelarIntencaoVendaRequest {
	chassi: string;
	numeroATPVE: string;
	renavam: string;
	placa: string;
	codigoDespachante: string;
}

export interface CancelarIntencaoVendaResponseData {
	codigo: number;
	mensagem: string;
}
