export enum Types {
	TIPO_ARQUIVO_REQUEST = '@DETRAN-CRV/TIPO_ARQUIVO_REQUEST',
	TIPO_ARQUIVO_SUCCESS = '@DETRAN-CRV/TIPO_ARQUIVO_SUCCESS',
	TIPO_ARQUIVO_FAILURE = '@DETRAN-CRV/TIPO_ARQUIVO_FAILURE',
	TIPO_ARQUIVO_CLEAR = '@DETRAN-CRV/TIPO_ARQUIVO_CLEAR',
}

export interface tipoArquivo {
	status: number;
	data: tipoArquivoData | null;
}

interface tipoArquivoData {
	tiposArquivosEmissao: ArrayTiposArquivos[];
}

interface ArrayTiposArquivos {
	nomeTipoArquivo: string;
	codigoTipoArquivo: string;
	obrigatorio: string;
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		estacao: {
			id: number;
			desc: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface IRequestTipoArquivo {
	evento: IEvento;
	servico: string;
}
