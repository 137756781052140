import { ReducerAction } from 'store/modules/types';

import { GerarRenachIntegradoRequest, Types } from './types';

export function saveGerarRenachRequest(
	payload: GerarRenachIntegradoRequest,
): ReducerAction {
	return {
		type: Types.SAVE_GERAR_RENACH_REQUEST,
		payload,
	};
}
export function saveGerarRenachSuccess(payload: object): ReducerAction {
	return {
		type: Types.SAVE_GERAR_RENACH_SUCCESS,
		payload,
	};
}
export function saveGerarRenachFailure(): ReducerAction {
	return {
		type: Types.SAVE_GERAR_RENACH_FAILURE,
		payload: null,
	};
}
export function saveGerarRenachClear(): ReducerAction {
	return {
		type: Types.SAVE_GERAR_RENACH_CLEAR,
		payload: null,
	};
}
