import { all } from 'redux-saga/effects';

import atualizarUnidadeSaga from './atualizarUnidade/saga';
import cadastrarUnidadeSaga from './cadastrarUnidade/saga';
import consultarUnidadesSaga from './consultarUnidades/saga';
import excluirUnidadeSaga from './excluirUnidade/saga';

export default all([
	atualizarUnidadeSaga,
	cadastrarUnidadeSaga,
	consultarUnidadesSaga,
	excluirUnidadeSaga,
]);
