import { Types } from './types';

export function impressaoCrlveRequest(payload: any) {
	return {
		type: Types.IMPRIMIR_CRLVE_REQUEST,
		payload,
	};
}
export function impressaoCrlveSuccess(payload: any) {
	return {
		type: Types.IMPRIMIR_CRLVE_SUCCESS,
		payload,
	};
}
export function impressaoCrlveFailure(payload: []): any {
	return {
		type: Types.IMPRIMIR_CRLVE_FAILURE,
		payload,
	};
}
export function impressaoCrlveClear(): any {
	return {
		type: Types.IMPRIMIR_CRLVE_CLEAR,
	};
}
