/* eslint-disable no-new */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Modal, Row, Input } from 'antd';
import { QWebChannel } from 'qwebchannel';

import Button from 'components/Common/Button';
import TextArea from 'components/Common/Form/Input/TextArea';
import CheckBox from 'components/Common/Form/Checkbox';

import left from 'assets/images/button/btn_rotate_left.png';
import right from 'assets/images/button/btn_rotate_right.png';
import camera from 'assets/images/button/btn_take_photo.png';
import pdf from 'assets/images/button/btn_download_pdf.png';
import close from 'assets/images/button/btn_close.png';

import PDFMaker from '../PDFMaker';
import { StyledButton } from './styled';

interface Props {
	setModalScanner: (a: boolean) => void;
}

const ScannerEloam: React.FC<Props> = ({ setModalScanner }) => {
	const imageRef = useRef<HTMLImageElement>(null);

	const baseUrl = 'ws://127.0.0.1:12345';
	const socket = new WebSocket(baseUrl);

	const [photos, setPhotos] = useState<string[]>([]);
	const [modalPdf, setModalPdf] = useState(false);
	const [pdfLabel, setPdfLabel] = useState('');
	const [output, setOutput] = useState<string[]>([]);
	const [visibleImage, setVisibleImage] = useState(false);
	const [cutImage, setCutImage] = useState(false);

	const openSocket = useCallback(() => {
		// socket = new WebSocket(baseUrl);
		socket.onclose = () => {
			setOutput(outputs => [...outputs, 'web channel closed']);
			console.error('web channel closed');
		};
		socket.onerror = error => {
			setOutput(outputs => [...outputs, `web channel error: ${error}`]);
			console.error(`web channel error: ${error}`);
		};
		socket.onopen = () => {
			new QWebChannel(socket, (channel: any) => {
				// make dialog object accessible globally
				(window as any).dialog = channel.objects.dialog;
				const { dialog } = window as any;
				// Web Page Close function
				window.onbeforeunload = () => {
					dialog.get_actionType('closeSignal');
				};
				window.onunload = () => {
					dialog.get_actionType('closeSignal');
				};

				dialog.get_actionType('setScanSize_ori');

				// Server return message
				dialog.sendPrintInfo.connect((message: any) => {
					setOutput(outputs => [...outputs, `${message}`]);
					// Device information priModel
					if (message.indexOf('priDevName:') >= 0) {
						// eslint-disable-next-line no-param-reassign
						message = message.substr(11);
					}
					// Device Output Picture format
					if (message.indexOf('priModel:') >= 0) {
						// eslint-disable-next-line no-param-reassign
						message = message.substr(9);
					}

					// Device resolution
					if (message.indexOf('priResolution:') >= 0) {
						// eslint-disable-next-line no-param-reassign
						message = message.substr(14);
					}

					// Return path keyword after saving picturesavePhoto_success:
					else if (message.indexOf('savePhoto_success:') >= 0) {
						// imgPath = message.substr(18);
					}
				});

				// Receive picture stream used to show, multiple, smaller base64, sub-header data
				dialog.send_priImgData.connect((message: any) => {
					setVisibleImage(true);
					imageRef?.current?.setAttribute(
						'src',
						`data:image/jpg;base64,${message}`,
					);
				});

				// Receive Photo base64
				dialog.send_priPhotoData.connect((message: any) => {
					setPhotos(oldPhotos => [
						...oldPhotos,
						`data:image/jpg;base64,${message}`,
					]);
				});

				setOutput(outputs => [...outputs, `ready to send/receive messages!`]);
				// Web page loading complete signal
				dialog.html_loaded('one');

				dialog.devChanged('primaryDev_:2048 * 1536');
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		openSocket();
	}, [openSocket]);

	useEffect(() => {
		return () => {
			const { dialog } = window as any;
			dialog?.get_actionType && dialog.get_actionType('closeSignal');
			socket.close();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCutImage = useCallback((e: boolean) => {
		const { dialog } = window as any;
		dialog?.devChanged('primaryDev_:2048 * 1536');
		dialog?.get_actionType && dialog.get_actionType('setdeskew');
		setCutImage(e);
	}, []);

	const rotate = useCallback((direction: 'left' | 'right') => {
		const { dialog } = window as any;

		if (direction === 'left') {
			dialog?.get_actionType && dialog.get_actionType('rotateLeft');
		} else {
			dialog?.get_actionType && dialog.get_actionType('rotateRight');
		}
	}, []);

	const photograph = useCallback(() => {
		const { dialog } = window as any;
		// Photo button click
		dialog?.get_actionType && dialog.photoBtnClicked('primaryDev_');
	}, []);

	const removeImage = useCallback((imgUrl: string) => {
		setPhotos(oldPhotos =>
			oldPhotos.filter(photo => {
				return photo !== imgUrl;
			}),
		);
	}, []);

	return (
		<Row gutter={[10, 20]} style={{ marginTop: '10px' }} key="sectionRow">
			<Col span={16}>
				<Row>
					<Col span={24} style={{ marginTop: '15px' }}>
						<h3 style={{ textAlign: 'center' }}>Pré-visualização</h3>
					</Col>
					<Col span={24}>
						<Row justify="center" align="middle">
							<Col
								style={{ width: '100%', maxWidth: '400px', maxHeight: '400px' }}
							>
								{visibleImage ? (
									<img
										ref={imageRef}
										src=""
										alt="Scanner"
										style={{
											maxWidth: '400px',
											maxHeight: '400px',
											width: '100%',
											background: 'black',
											aspectRatio: 'auto 400 / 300',
										}}
									/>
								) : (
									<TextArea
										style={{ width: '100%', marginBottom: '20px' }}
										name="textArea"
										value={output.toString().replaceAll(',', '\n')}
										disabled
									/>
								)}
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Row justify="center" align="middle">
							<Col>
								<CheckBox
									name="cut"
									checked={cutImage}
									onChange={e => {
										handleCutImage(e.target.checked);
									}}
									subtitle="Recorte automático"
								/>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Row justify="center" align="middle">
							<Col>
								<Button
									type="button"
									onClick={() => {
										photograph();
									}}
									style={{
										height: '100px',
										width: '100px',
										border: 'none',
										backgroundColor: 'transparent',
										background: 'none',
										boxShadow: 'none',
									}}
								>
									<img src={camera} alt="camera" style={{ width: '100%' }} />
								</Button>
							</Col>
							<Col>
								<Button
									type="button"
									onClick={() => {
										rotate('left');
									}}
									style={{
										height: '100px',
										width: '100px',
										border: 'none',
										backgroundColor: 'transparent',
										background: 'none',
										boxShadow: 'none',
									}}
								>
									<img src={left} alt="left" style={{ width: '100%' }} />
								</Button>
							</Col>
							<Col>
								<Button
									type="button"
									onClick={() => {
										rotate('right');
									}}
									style={{
										height: '100px',
										width: '100px',
										border: 'none',
										backgroundColor: 'transparent',
										background: 'none',
										boxShadow: 'none',
									}}
								>
									<img src={right} alt="right" style={{ width: '100%' }} />
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={8}>
				<Row style={{ height: '100%' }}>
					<Col span={24} style={{ marginTop: '15px' }}>
						<h3 style={{ textAlign: 'center' }}>Imagens Digitalizadas</h3>
					</Col>
					<Col span={24} style={{ height: 'calc(100% - 165px)' }}>
						<Row style={{ paddingTop: '5px' }} gutter={[10, 10]}>
							{photos.length > 0 &&
								photos.map(photo => (
									<Col key={photo} span={7} offset={1}>
										<StyledButton
											type="button"
											onClick={() => {
												removeImage(photo);
											}}
										>
											x
										</StyledButton>
										<img
											src={photo}
											alt="SavedPhoto"
											style={{ width: '100%' }}
										/>
									</Col>
								))}
						</Row>
					</Col>
					<Col span={24}>
						<Row
							justify="center"
							align="middle"
							style={{ alignItems: 'end', height: '100%' }}
						>
							<Col>
								<Button
									type="button"
									onClick={() => {
										if (photos.length > 0) {
											setModalPdf(true);
											return;
										}
										// eslint-disable-next-line no-alert
										alert('Não existe imagem digitalizada!');
									}}
									style={{
										height: '100px',
										width: '100px',
										border: 'none',
										backgroundColor: 'transparent',
										background: 'none',
										boxShadow: 'none',
									}}
								>
									<img src={pdf} alt="pdf" style={{ width: '100%' }} />
								</Button>
							</Col>
							<Col>
								<Button
									type="button"
									onClick={() => {
										setModalScanner(false);
									}}
									style={{
										height: '100px',
										width: '100px',
										border: 'none',
										backgroundColor: 'transparent',
										background: 'none',
										boxShadow: 'none',
									}}
								>
									<img src={close} alt="close" style={{ width: '100%' }} />
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Modal
				title="Digitalização de Documentos"
				visible={modalPdf}
				onOk={() => {
					if (pdfLabel === '') {
						// eslint-disable-next-line no-alert
						alert('Nome inválido!');
						return;
					}
					PDFMaker(photos, pdfLabel);
					setModalPdf(false);
				}}
				onCancel={() => setModalPdf(false)}
			>
				<Row>
					<Col>Informe um nome para o PDF:</Col>
				</Row>
				<Row>
					<Col>
						<Input
							value={pdfLabel}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (e) {
									setPdfLabel(e.target.value);
								}
							}}
						/>
					</Col>
				</Row>
			</Modal>
		</Row>
	);
};

export default ScannerEloam;
