import { ReducerAction } from 'store/modules/types';
import { Types, IRequestTipoArquivo } from './types';

export function tipoArquivoRequest(
	payload: IRequestTipoArquivo,
): ReducerAction {
	return {
		type: Types.TIPO_ARQUIVO_REQUEST,
		payload,
	};
}
export function tipoArquivoSuccess(payload: any): ReducerAction {
	return {
		type: Types.TIPO_ARQUIVO_SUCCESS,
		payload,
	};
}
export function tipoArquivoFailure(payload: []): ReducerAction {
	return {
		type: Types.TIPO_ARQUIVO_FAILURE,
		payload,
	};
}

export function tipoArquivoClear(): any {
	return {
		type: Types.TIPO_ARQUIVO_CLEAR,
	};
}
