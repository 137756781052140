export enum Types {
	AGENDAR_EXAME_MEDICO = '@cnh/AGENDAR_EXAME_MEDICO',
	AGENDAR_EXAME_MEDICO_SUCCESS = '@cnh/AGENDAR_EXAME_MEDICO_SUCCESS',
	AGENDAR_EXAME_MEDICO_FAILURE = '@cnh/AGENDAR_EXAME_MEDICO_FAILURE',
}

export interface agendarExameMedico {
	status: number;
	data: IDadosAgendarExameMedico | null;
}

interface IDadosAgendarExameMedico {}

export interface IRequestAgendarExameMedico {
	cpf_cidadao: number | string;
	id_medico: number;
	codigo_ciretran: number;
	data_exame: string;
	horario_exame: string;
	tipo_processo: string;
	categoria_pretendida: string;
	cep_cidadao: number | string;
	renach: string;
	id_micro_regiao_medico: number;
	usuario: string;
}
