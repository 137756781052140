import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, CancelarIntencaoVenda } from './types';

export const INITIAL_STATE: CancelarIntencaoVenda = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): CancelarIntencaoVenda {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CANCELAR_INTENCAO_VENDA_REQUEST: {
				draft.status = 100;
				break;
			}

			case Types.CANCELAR_INTENCAO_VENDA_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.data;
				break;
			}

			case Types.CANCELAR_INTENCAO_VENDA_FAILURE: {
				draft.status = 400;
				draft.data = action.payload.data;
				break;
			}

			case Types.CANCELAR_INTENCAO_VENDA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
