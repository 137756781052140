import React from 'react';

import { Container, Title, Content } from './styled';

interface FormBoxProps {
	title: string;
	size?: 'sm' | 'md';
	overflow?: 'x' | 'y';
	height?: number;
	fullHeight?: boolean;
}

const FormBox: React.FC<FormBoxProps> = ({
	title,
	size = 'md',
	overflow,
	height,
	children,
	fullHeight,
}) => {
	return (
		<Container fullHeight={fullHeight}>
			<Title>
				<h5>{title}</h5>
			</Title>

			<Content
				size={size}
				overflow={overflow}
				height={height?.toString().concat('px')}
			>
				{children}
			</Content>
		</Container>
	);
};

export default FormBox;
