import React, { Suspense, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import PDFViewer from 'components/Common/PDFViewer';

import { consultarVeiculoV110Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import { emitirAlteracaoDadosCadastraisClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirAlteracaoDadosCadastrais/actions';

const PrimeiroEmplacamentoImpressao: React.FC = () => {
	const dispatch = useDispatch();
	const { primeiroEmplacamentoEmissao } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const [showPrint, setShowPrint] = useState<boolean>(false);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Resultado do pedido de Licenciamento do Veículo">
				<Row gutter={[0, 10]}>
					<Col span={2} />
					<Col span={4}>Número da Ficha:</Col>
					<Col span={18}>
						<strong>{primeiroEmplacamentoEmissao.data?.numeroficha}</strong>
					</Col>
					<Col span={2} />
					<Col span={4}>Ano da Ficha:</Col>
					<Col span={18}>
						<strong>{primeiroEmplacamentoEmissao.data?.anoFicha}</strong>
					</Col>
				</Row>
				<Row justify="end" align="top" gutter={[20, 10]}>
					<ButtonImage src="imprimir" onClick={() => setShowPrint(true)} />
				</Row>
			</Section>
			<ButtonVoltar
				route="/detran-crv/alteracao-dados-cadastrais/consultar"
				onClick={() => {
					dispatch(emitirAlteracaoDadosCadastraisClear());
					dispatch(consultarVeiculoV110Clear());
				}}
			/>
			{showPrint && primeiroEmplacamentoEmissao.data?.planilhaRenavam && (
				<PDFViewer
					title="Ficha Alteração de Dados Cadastrais"
					source={
						primeiroEmplacamentoEmissao.data?.planilhaRenavam
							? primeiroEmplacamentoEmissao.data?.planilhaRenavam
							: ''
					}
					onClose={() => setShowPrint(false)}
				/>
			)}
		</Suspense>
	);
};

export default PrimeiroEmplacamentoImpressao;
