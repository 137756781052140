import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse } from 'services/_api';
import { postApi } from 'services/_sgu';

// TYPES
import { ReducerAction } from 'store/modules/types';

import { IncluirRequest, Types } from './types';

// ACTIONS
import {
	estatisticaIncluirSuccess,
	estatisticaIncluirFailure,
} from './actions';

function* estatisticaIncluirRequest(request: ReducerAction) {
	const { payload }: { payload: IncluirRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		`estatistica/incluir`,
		payload,
		true,
		true,
		false,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(estatisticaIncluirSuccess(response));
	} else {
		yield put(estatisticaIncluirFailure(response.message));
	}
}

export default all([
	takeLatest(Types.ESTATISTICA_INCLUIR_REQUEST, estatisticaIncluirRequest),
]);
