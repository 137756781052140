import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 10px 0;

	a {
		text-decoration: underline;
	}

	p {
		height: calc(0.85rem + ${props => props.theme.fontScale}%);
	}

	.ant-breadcrumb {
		font-size: calc(0.85rem + ${props => props.theme.fontScale}%);

		a {
			color: ${props => props.theme.typography.primary};
		}

		span:last-child {
			color: ${props => props.theme.typography.primary};

			a {
				color: ${props => props.theme.typography.primary};
			}
		}
	}
`;

export const Span = styled.span``;
