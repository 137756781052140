import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaNaturalidade } from './types';

export function consultaNaturalidadeRequest(
	data: IRequestConsultaNaturalidade,
): ReducerAction {
	return {
		type: Types.CONSULTA_NATURALIDADE_REQUEST,
		payload: data,
	};
}

export function consultaNaturalidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_NATURALIDADE_SUCCESS,
		payload,
	};
}

export function consultaNaturalidadeNoContent(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_NATURALIDADE_NO_CONTENT,
		payload,
	};
}

export function consultaNaturalidadeFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_NATURALIDADE_FAILURE,
		payload: null,
	};
}
