import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ExcluirParametroSistemaRequest } from './types';

// ACTIONS
import {
	excluirParametroSistemaSuccess,
	excluirParametroSistemaFailure,
} from './actions';

function* excluirParametroSistema(request: ReducerAction) {
	const { payload }: { payload: ExcluirParametroSistemaRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_SGU,
		`parametros-sistema/${payload.id}`,
	);

	if (response.status === 200) {
		yield put(excluirParametroSistemaSuccess(response));
	} else if (response.error) {
		yield put(excluirParametroSistemaFailure());
	}
}

export default all([
	takeLatest(Types.EXCLUIR_PARAMETRO_SISTEMA, excluirParametroSistema),
]);
