import { ReducerAction } from 'store/modules/types';
import { ConsultarUsuarioRequest, Types, ConsultarUsuario } from './types';

export function consultarUsuarioRequest(
	payload: ConsultarUsuarioRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_USUARIO_REQUEST,
		payload,
	};
}
export function consultarUsuarioSuccess(
	payload: ConsultarUsuario,
): ReducerAction {
	return {
		type: Types.CONSULTAR_USUARIO_SUCCESS,
		payload,
	};
}
export function consultarUsuarioFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTAR_USUARIO_FAILURE,
		payload,
	};
}
export function consultarUsuarioClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_USUARIO_CLEAR,
		payload: null,
	};
}
