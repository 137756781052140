import React, { ButtonHTMLAttributes } from 'react';

// STYLED
import { Button } from './styled';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	size?: 'sm' | 'md' | 'lg';
	disabled?: boolean;
	onClick: () => void;
}

export const ButtonIcon: React.FC<Props> = ({
	size = 'md',
	disabled = false,
	onClick,
	children,
}) => {
	return (
		<Button
			type="button"
			size={size}
			disabled={disabled}
			onClick={() => onClick()}
		>
			{children}
		</Button>
	);
};
