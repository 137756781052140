// TYPES
import { ReducerAction } from 'store/modules/types';
import { AtualizacaoConsumidorRequest, Types } from './types';

export function atualizacaoConsumidorRequest(
	payload: AtualizacaoConsumidorRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZACAO_CONSUMIDOR_REQUEST,
		payload,
	};
}
export function atualizacaoConsumidorSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZACAO_CONSUMIDOR_SUCCESS,
		payload,
	};
}
export function atualizacaoConsumidorFailure(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZACAO_CONSUMIDOR_FAILURE,
		payload,
	};
}
export function atualizacaoConsumidorClear(): ReducerAction {
	return {
		type: Types.ATUALIZACAO_CONSUMIDOR_CLEAR,
		payload: null,
	};
}
