export enum Types {
	CONSULTA_CADASTRO_CNH = '@cnh/CONSULTA_CADASTRO_CNH',
	CONSULTA_CADASTRO_CNH_SUCCESS = '@cnh/CONSULTA_CADASTRO_CNH_SUCCESS',
	CONSULTA_CADASTRO_CNH_FAILURE = '@cnh/CONSULTA_CADASTRO_CNH_FAILURE',
}

export interface consultaCadastroCnh {
	status: number;
	data: IDadosConsultaCadastroCnh | null;
}

export interface IDadosConsultaCadastroCnh {
	cpf: string;
	nome: string;
	nomeMae: string;
	dataNascimento: string;
	sexo: string;
	categoriaPretendida: string;
	renach: string;
	dataExameMedico: string;
	categoriaObtidaMedico: string;
	resultadoExameMedico: string;
	restricoesMedicas: string;
	crm: string;
	ufMedico: string;
	resultadoExamePsicotecnico: string;
	laudo: string;
	crp: string;
	situacaoExameTeorico: string;
	situacaoExamePratico: string;
	cadastroBinco: string;
	numRegistro: string;
	numPGU: string;
	usoFormulario: string;
	nomePai: string;
	nacionalidade: string;
	naturalidade: string;
	tipoDocumento: string;
	numDocumento: string;
	orgaoDocumento: string;
	ufDocumento: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	municipio: string;
	cep: string;
	categoriaAtual: string;
	sensoCromatico: string;
	campoVisualOD: string;
	campoVisualOE: string;
	acuidadeVisualOD: string;
	acuidadeVisualOE: string;
	acuidadeVisualAmbosOlhos: string;
	acuidadeAuditiva: string;
	visaoNoturna: string;
	ofuscamento: string;
	dinamometriaMaoDireita: string;
	dinamometriaMaoEsquerda: string;
	pressaoArterialMaxima: string;
	pressaoArterialMinima: string;
	disturbioSono: string;
	nivelMental: string;
	personalidade: string;
	coordenacaoBimanual: string;
	credenciadoMedico: string;
	credenciadoPsicologo: string;
	ciretranDomicilio: string;
	ufPsicologo: string;
	inaptoProcessoAnterior: string;
	crmCrpAnterior: string;
	codigoPoupatempo: string;
	permiteReavaliacaoMedico: string;
	permiteReavaliacaoPsicologo: string;
	crmAux: string;
	crpAux: string;
	tipoProcesso: string;
	pretendeAtividadeRemunerada: string;
	necessidadeVeiculoAdaptado: string;
	juntaMedica: string;
	juntaPsicologica: string;
	codigoEntidade: string;
	numCarteiraMilitar: string;
	ciretranPoupaTempoExame: string;
	cnpj: string;
	celular: string;
	cnhDigital: string;
	codigoInterno: string;
	message: string;
}

export interface IRequestConsultaCadastroCnh {
	bilhetagem: string | undefined;
	cpf: string | undefined;
	crm: string | undefined;
	numeroRegistroPgu: string | undefined;
	renach: string | undefined;
	tipoPesquisa: string | undefined;
	tipoRegistroPgu: string | undefined;
}
