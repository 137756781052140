import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

// ANTDCPF
import { Row, Col } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import {
	relatorioAgendamentoMedicoPsicologicoClear,
	relatorioAgendamentoMedicoPsicologicoRequest,
} from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import {
	consultaCnhEstrangeiroClear,
	consultaCnhEstrangeiroRequest,
} from 'store/modules/api/detranCnh/eportal/consultaCnhEstrangeiro/actions';
import { ConsultaCnhEstrangeiroRequest } from 'store/modules/api/detranCnh/eportal/consultaCnhEstrangeiro/types';
import { eventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/actions';
import {
	consultaProcessoAbertoRequest,
	consultaProcessoAbertoClear,
} from 'store/modules/api/detranCnh/renachIntegrado/consultaProcessoAberto/actions';

// COMPONENTS COMMON
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import CPF from 'components/Common/Form/Fields/CPF';
import ButtonImage from 'components/Common/ButtonImage';
import PDFViewer from 'components/Common/PDFViewer';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { threatValuesStartSessionEventRequest } from 'utils/functions/threatEventsRequest';
import { CONSULTA_CNH_ESTRANGEIRO_RESPONSE_SERVICE_MESSAGE } from 'pages/DetranCnh/utils/constants/messages';
import { treatAgendamentoMedicoPsicologicoRequestInPesquisar } from 'pages/DetranCnh/utils/threatServicesRequest';

// ROUTES
import { ROUTE_DETRAN_CNH } from 'routes/paths';
import { ROUTE_DETRAN_CNH_ESTRANGEIRO_STATUS_CONDUTOR } from 'pages/DetranCnh/routes/paths';

// FORM
import { Formik, Form, FormikProps } from 'formik';
import { initialValues, schema } from './form';

const PesquisaCnhEstrangeiro: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const [modalPrint, setModalPrint] = useState(false);

	const { consultaCnhEstrangeiro } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.eportal,
	);

	const { consultaProcessoAberto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { relatorio } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.agendamentoMedicoPsicologico,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { mainframe } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const handleClear = useCallback(
		(formik?: FormikProps<ConsultaCnhEstrangeiroRequest>) => {
			dispatch(clearNotifications());
			dispatch(consultaProcessoAbertoClear());
			dispatch(relatorioAgendamentoMedicoPsicologicoClear());
			dispatch(consultaCnhEstrangeiroClear());
			setModalPrint(false);
			if (formik) {
				formik.handleReset();
			}
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: ConsultaCnhEstrangeiroRequest) => {
			const cpfFormatado = values.cpf.replace(/[.-]/g, '');

			handleClear();

			const valuesConsultaCnhEstrangeiro = {
				cpf: cpfFormatado,
				usuario: mainframe.idUsuario,
				senha: mainframe.senhaMainFrame,
			};

			dispatch(
				consultaCnhEstrangeiroRequest({ ...valuesConsultaCnhEstrangeiro }),
			);

			const valuesStartSessionEvent = threatValuesStartSessionEventRequest(
				atendimento,
				loginUnico,
			);

			dispatch(eventoInicioSessaoRequest(valuesStartSessionEvent));
		},
		[
			atendimento,
			dispatch,
			handleClear,
			loginUnico,
			mainframe.idUsuario,
			mainframe.senhaMainFrame,
		],
	);

	useEffect(() => {
		handleClear();
	}, [handleClear]);

	useEffect(() => {
		if (
			(relatorio.data || !relatorio.data) &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			consultaProcessoAberto.data?.message
		) {
			addNotifications({
				errors: [consultaProcessoAberto.data.message],
			});
		}
	}, [dispatch, consultaProcessoAberto.data, relatorio.data]);

	useEffect(() => {
		if (
			(consultaCnhEstrangeiro.status === 200 &&
				consultaCnhEstrangeiro.data?.cpf &&
				consultaProcessoAberto.status === 0) ||
			(consultaCnhEstrangeiro.status === 400 &&
				consultaCnhEstrangeiro.dataError?.mensagem &&
				consultaCnhEstrangeiro.dataError.mensagem.trim().toLowerCase() ===
					CONSULTA_CNH_ESTRANGEIRO_RESPONSE_SERVICE_MESSAGE.CONDUTOR_PROCESSO_ABERTO.toLowerCase() &&
				consultaCnhEstrangeiro.cpf &&
				consultaProcessoAberto.status === 0)
		) {
			dispatch(
				consultaProcessoAbertoRequest({
					cpf:
						consultaCnhEstrangeiro.data?.cpf ||
						consultaCnhEstrangeiro.cpf ||
						'',
					ident: 0,
				}),
			);
		} else if (consultaCnhEstrangeiro.status === 204) {
			history.push(ROUTE_DETRAN_CNH_ESTRANGEIRO_STATUS_CONDUTOR);
		}
	}, [
		consultaCnhEstrangeiro.status,
		consultaCnhEstrangeiro.cpf,
		dispatch,
		consultaCnhEstrangeiro.data,
		history,
		consultaProcessoAberto.status,
		consultaCnhEstrangeiro.dataError,
	]);

	useEffect(() => {
		if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			(consultaProcessoAberto.data.dataExame ||
				consultaProcessoAberto.data.dataExamePsicologo)
		) {
			const requestData = treatAgendamentoMedicoPsicologicoRequestInPesquisar(
				consultaProcessoAberto.data,
			);

			dispatch(relatorioAgendamentoMedicoPsicologicoRequest(requestData));
		} else if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) === 0
		) {
			history.push(ROUTE_DETRAN_CNH_ESTRANGEIRO_STATUS_CONDUTOR);
		}
	}, [
		consultaProcessoAberto.data,
		consultaProcessoAberto.status,
		dispatch,
		history,
	]);

	return (
		<>
			<Section title="Pesquisar">
				<Row>
					<Col offset={1} span={24}>
						<strong>
							Digite o CPF do cidadão para verificar se existe o pré-cadastro:
						</strong>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={values => getValidationsErrors(values, schema)}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row gutter={[0, 10]}>
										<Col span={12}>
											<CPF
												title="CPF"
												subtitle="(Digite apenas números)"
												numero="cpf"
												formik={formik}
												error={!!formik.errors.cpf}
												disabled={consultaCnhEstrangeiro.status === 100}
											/>
										</Col>
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => handleClear(formik)}
												disabled={consultaCnhEstrangeiro.status === 100}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage
												type="submit"
												src="pesquisar"
												disabled={consultaCnhEstrangeiro.status === 100}
											/>
										</Col>

										{relatorio.data !== null && (
											<Col offset={1}>
												<ButtonImage
													src="btn-imprimir-agendamento-medico-psicologico"
													onClick={() => {
														setModalPrint(true);
													}}
												/>
											</Col>
										)}
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>

			{modalPrint && (
				<PDFViewer
					title="agendamentoMedicoViewer"
					source={relatorio.data?.arquivoPDF || ''}
					onClose={() => setModalPrint(false)}
					renderMode="popup"
				/>
			)}

			<ButtonVoltar
				route={ROUTE_DETRAN_CNH}
				onClick={() => {
					dispatch(detranCnhClear());
				}}
			/>
		</>
	);
};

export default PesquisaCnhEstrangeiro;
