import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestGerarRenach } from './types';

// ACTIONS
import { gerarRenachSuccess, gerarRenachFailure } from './actions';

function* cadatraRenach(request: ReducerAction) {
	const { payload }: { payload: IRequestGerarRenach } = request;

	const requestData = { ...payload };

	delete requestData.exameDentroDoPosto;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`renach/${CHANNEL}/condutor/renovacao`,
		requestData,
	);

	if (response.status === 200) {
		yield put(gerarRenachSuccess({ response, payload }));
	} else if (response.error) {
		yield put(gerarRenachFailure(response.error));
	}
}

export default all([takeLatest(Types.GERAR_RENACH_RENOVACAO, cadatraRenach)]);
