// ROUTES
import { ROUTE_CDHU } from 'routes/paths';

export const ROUTE_CDHU_RECUPERAR_ATENDIMENTO = `${ROUTE_CDHU}/recuperar-atendimento`;
export const ROUTE_CDHU_JUSTIFICATIVA_ATENDIMENTO = `${ROUTE_CDHU}/recuperar-atendimento/justificativa`;

export const ROUTE_CDHU_SOLICITANTE_PESQUISAR = `${ROUTE_CDHU}/solicitante/pesquisar`;
export const ROUTE_CDHU_SOLICITANTE_DADOS = `${ROUTE_CDHU}/solicitante/dados`;

export const ROUTE_CDHU_MUTUARIO_PESQUISAR = `${ROUTE_CDHU}/mutuario/pesquisar`;
export const ROUTE_CDHU_MUTUARIO_DADOS = `${ROUTE_CDHU}/mutuario/dados`;

export const ROUTE_CDHU_SERVICOS = `${ROUTE_CDHU}/servicos`;
export const ROUTE_CDHU_SERVICOS_ACORDO = `${ROUTE_CDHU}/servicos/acordo`;
export const ROUTE_CDHU_SERVICOS_ACORDO_TERMOS_IMPRIMIR = `${ROUTE_CDHU}/servicos/acordo/termos/imprimir`;
export const ROUTE_CDHU_SERVICOS_BOLETO_AGRUPADO = `${ROUTE_CDHU}/servicos/boleto-agrupado`;
export const ROUTE_CDHU_SERVICOS_SUCESSO = `${ROUTE_CDHU}/servicos/sucesso`;
