// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	Types,
	RequestEmissaoAtestado,
	EmissaoAtestadoLiberacaoNormal,
} from './types';

export function emissaoAtestadoPainelRequest(
	payload: RequestEmissaoAtestado,
): ReducerAction {
	return {
		type: Types.EMISSAO_ATESTADO_PAINEL_REQUEST,
		payload,
	};
}

export function emissaoAtestadoPainelSuccess(
	payload: EmissaoAtestadoLiberacaoNormal,
): ReducerAction {
	return {
		type: Types.EMISSAO_ATESTADO_PAINEL_SUCCESS,
		payload,
	};
}

export function emissaoAtestadoPainelFailure(): ReducerAction {
	return {
		type: Types.EMISSAO_ATESTADO_PAINEL_FAILURE,
		payload: null,
	};
}

export function emissaoAtestadoPainelClear(): ReducerAction {
	return {
		type: Types.EMISSAO_ATESTADO_PAINEL_CLEAR,
		payload: null,
	};
}
