import { RequestConsultaTokenCnhDigital } from 'store/modules/api/detranCnh/cnhDigital/tokenDigital/types';
import { limparMascara } from 'utils/genericFunctions';

export const treatValuesGenerateToken = (
	valuesToken: RequestConsultaTokenCnhDigital,
): RequestConsultaTokenCnhDigital => {
	const newData = {
		cpf: limparMascara(valuesToken.cpf),
		ddd: limparMascara(valuesToken.ddd),
		celular: limparMascara(valuesToken.celular),
		email: valuesToken.email,
		duracao: valuesToken.duracao,
	};

	return newData;
};
