export enum Types {
	IMPRESSAO_RENAVAM_REQUEST = '@DETRAN-CRV/IMPRESSAO_RENAVAM_REQUEST',
	IMPRESSAO_RENAVAM_SUCCESS = '@DETRAN-CRV/IMPRESSAO_RENAVAM_SUCCESS',
	IMPRESSAO_RENAVAM_FAILURE = '@DETRAN-CRV/IMPRESSAO_RENAVAM_FAILURE',
	IMPRESSAO_RENAVAM_CLEAR = '@DETRAN-CRV/IMPRESSAO_RENAVAM_CLEAR',
}

export interface impressaoRenavam {
	status: number;
	data: ficha | null;
}

interface ficha {
	ficha: {
		anoFicha: number;
		chassi: string;
		codigoSSP: number;
		dataEmissaoDocumento: string;
		dataHoraExlcusao: string;
		dataInclusaoFicha: string;
		dataInclusaohistorico: string;
		municipio: {
			codigo: number;
			nome: string;
		};
		numeroFicha: number;
		opcao: string;
		placa: string;
		planilhaRenavam: string;
		renavam: number;
		retornoBin: string;
		retornoConsistencia: string;
		status: string;
	};
}
