import { Steps } from 'antd';
import React from 'react';
import { NumberSpan } from './styled';

const { Step } = Steps;

interface Props {
	currentStep: number;
}

const CadastroSteps: React.FC<Props> = ({ currentStep = 0 }) => {
	return (
		<Steps
			type="navigation"
			current={currentStep}
			// onChange={handleChange}
			className="site-navigation-steps"
		>
			<Step
				status={currentStep === 0 ? 'process' : 'wait'}
				title="Solicitante"
				icon={<NumberSpan>1</NumberSpan>}
			/>
			<Step
				status={currentStep === 1 ? 'process' : 'wait'}
				title="Preenchimento"
				icon={<NumberSpan>2</NumberSpan>}
			/>
			<Step
				status={currentStep === 2 ? 'process' : 'wait'}
				title="Confirmação"
				icon={<NumberSpan>3</NumberSpan>}
			/>
			<Step
				status={currentStep === 3 ? 'process' : 'wait'}
				title="Finalizar"
				icon={<NumberSpan>4</NumberSpan>}
			/>
		</Steps>
	);
};

export default CadastroSteps;
