// TYPES
import { PesquisaNumeral } from '../pesquisaNumeral/types';

export enum Types {
	EMISSAO_NUMERAL_REQUEST = '@aac/EMISSAO_NUMERAL_REQUEST',
	EMISSAO_NUMERAL_SUCCESS = '@aac/EMISSAO_NUMERAL_SUCCESS',
	EMISSAO_NUMERAL_FAILURE = '@aac/EMISSAO_NUMERAL_FAILURE',
	LIMPA_EMISSAO_NUMERAL = '@aac/LIMPA_EMISSAO_NUMERAL',
}

export interface emissaoNumeral {
	status: number;
	data: DataEmissaoAtestado;
	file: File;
}

export interface DataEmissaoAtestado {
	atestadoEmitido: boolean;
	codigoRetorno: string;
	id: string;
	mensagemRetorno: string;
}

interface File {
	mime: string;
	data: string;
}

export interface IRequestEmissaoNumeral extends PesquisaNumeral {
	posto: string;
	ipEstacao: string;
	usuario: string;
	codigoRetorno: string;
}
