import styled from 'styled-components';

interface Props {
	bgColor: string;
	color: string;
}

export const Label = styled.div<Props>`
	background-color: ${props => props.bgColor};
	border-radius: 16px;
	color: ${props => props.color};
	display: inline-block;
	font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
	font-weight: 700;
	letter-spacing: 0.25px;
	margin-right: 8px;
	padding: 1px 12px;
`;
