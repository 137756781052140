export enum Types {
	EMITIR_AUTORIZACAO_PREVIA_REQUEST = '@DETRAN-CRV/EMITIR_AUTORIZACAO_PREVIA_REQUEST',
	EMITIR_AUTORIZACAO_PREVIA_SUCCESS = '@DETRAN-CRV/EMITIR_AUTORIZACAO_PREVIA_SUCCESS',
	EMITIR_AUTORIZACAO_PREVIA_FAILURE = '@DETRAN-CRV/EMITIR_AUTORIZACAO_PREVIA_FAILURE',
	EMITIR_AUTORIZACAO_PREVIA_CLEAR = '@DETRAN-CRV/EMITIR_AUTORIZACAO_PREVIA_CLEAR',
}

export interface emitirAutorizacaoPrevia {
	status: number;
	data: IResponseEmitirAutorizacaoPrevia | null;
}

export interface IResponseEmitirAutorizacaoPrevia {
	autorizacao: string;
	chassi: string;
	dataRegistroCerpro: number;
	dataValidadeAutorizacao: number;
	numeroAutorizacao: string;
	placa: string;
	situacaoAutorizacao: string;
	tipoAutorizacao: string;
}

export interface IRequestEmitirAutorizacaoPrevia {
	emissaoAutorizacaoPrevia: {
		capacidadeCarga: number;
		capacidadePassageiro: number;
		carroceria: {
			longId: number;
			descricao: string;
		};
		cilindrada: number;
		chassi: string;
		cmt: number;
		combustivel: {
			longId: number;
			descricao: string;
		};
		cor: {
			longId: number;
			descricao: string;
		};
		cpfResponsavel: number;
		dataAutorizacaoDetran: string;
		dataValidadeAutorizacao: string;
		especie: {
			longId: number;
			descricao: string;
		};
		marca: {
			longId: number;
			descricao: string;
		};
		nomeResponsavel: string;
		observacao: string;
		pbt: number;
		placa: string;
		potencia: number;
		renavam: number;
		tipo: {
			longId: number;
			descricao: string;
		};
		tipoAutorizacaoPrevia: string;
		tipoModificacao: {
			codigo: number;
			descricao: string;
			idAlteracao: number;
		};
	};
}
