import React from 'react';

import { Col, Row } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import Section from 'components/Common/Section';

// UTILS
import { formatCurrency } from 'utils/numberFunctions';

const SituacaoContrato: React.FC = () => {
	const { consultarPrestacoesAtrasadas, simularEfetuarAcordo } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	return (
		<>
			{consultarPrestacoesAtrasadas.data && simularEfetuarAcordo.data && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Section
							size="lg"
							title="Situação geral do contrato"
							titleSize="sm"
						>
							<Row>
								<Col span={11}>
									<Row gutter={[0, 5]}>
										<Col span={14}>Valor total das parcelas atrasadas:</Col>

										<Col>
											<b>
												{formatCurrency(
													simularEfetuarAcordo.data.valorPrestacoesAtrasadas,
												)}
											</b>
										</Col>
									</Row>
								</Col>

								<Col span={11} offset={1}>
									<Row gutter={[0, 5]}>
										<Col span={10}>Qtde. parcelas atrasadas:</Col>

										<Col>
											<b>{consultarPrestacoesAtrasadas.data.quantidadeField}</b>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row>
								<Col span={11}>
									<Row>
										<Col span={14}>Período parcelas atrasadas (mês/ano):</Col>

										<Col>
											{simularEfetuarAcordo.data.periodoInicialAcordo &&
												simularEfetuarAcordo.data.periodoFinalAcordo && (
													<b>
														{`${simularEfetuarAcordo.data.periodoInicialAcordo} a ${simularEfetuarAcordo.data.periodoFinalAcordo}`}
													</b>
												)}
										</Col>
									</Row>
								</Col>

								<Col span={11} offset={1}>
									<Row>
										<Col span={10}>Valor da prestação vigente:</Col>

										<Col>
											<b>
												{formatCurrency(
													simularEfetuarAcordo.data.prestacaoLiquidaAtual,
												)}
											</b>
										</Col>

										{simularEfetuarAcordo.data.valorPrestacaoSuspensa && (
											<Col offset={1}>
												<i>Valor com subsídio.</i>
											</Col>
										)}
									</Row>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			)}
		</>
	);
};

export default SituacaoContrato;
