import { ReducerAction } from 'store/modules/types';
import { Types, IRequestValidacaoCidadao } from './types';

export function desbloqueiaCartaoRequest(payload: IRequestValidacaoCidadao) {
	return {
		type: Types.DESBLOQUEIO_CARTAO,
		payload,
	};
}
export function desbloqueiaCartaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.DESBLOQUEIO_CARTAO_SUCCESS,
		payload,
	};
}
export function desbloqueiaCartaoFailure(): ReducerAction {
	return {
		type: Types.DESBLOQUEIO_CARTAO_FAILURE,
		payload: null,
	};
}

export function limpardesbloqueiaCartao(): any {
	return {
		type: Types.LIMPAR_DESBLOQUEIO_CARTAO,
	};
}
