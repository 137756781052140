export enum Types {
	ENDERECO_IP_REQUEST = '@utils/ENDERECO_IP_REQUEST',
	ENDERECO_IP_SUCCESS = '@utils/ENDERECO_IP_SUCCESS',
	ENDERECO_IP_FAILURE = '@utils/ENDERECO_IP_FAILURE',
	ENDERECO_IP_CLEAR = '@utils/ENDERECO_IP_CLEAR',
}

export interface EnderecoIpState {
	status: number;
	data: string;
}
