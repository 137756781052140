// PATHS
import {
	ROUTE_DETRAN_CRV_ANDAMENTO_PROCESSO,
	ROUTE_DETRAN_CRV_ANDAMENTO_PROCESSO_RESULTADO,
	ROUTE_DETRAN_CRV_CONSULTAR_FICHA_CADASTRAL,
	ROUTE_DETRAN_CRV_CONSULTAR_FICHA_CADASTRAL_RESULTADO,
	ROUTE_DETRAN_CRV_EXCLUIR_FICHA_CADASTRAL,
	ROUTE_DETRAN_CRV_EXCLUIR_FICHA_CADASTRAL_RESULTADO,
	ROUTE_DETRAN_CRV_IMPRESSAO_ALTERACAO_ENDERECO,
	ROUTE_DETRAN_CRV_IMPRESSAO_COMUNICACAO_VENDA_CONSULTA,
	ROUTE_DETRAN_CRV_IMPRESSAO_COMUNICACAO_VENDA_RESULTADO,
	ROUTE_DETRAN_CRV_IMPRESSAO_LICENCIAMENTO_VEICULOS_CONSULTA,
	ROUTE_DETRAN_CRV_IMPRESSAO_LICENCIAMENTO_VEICULOS_RESULTADO,
	ROUTE_DETRAN_CRV_IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA,
	ROUTE_DETRAN_CRV_IMPRESSAO_RENAVAM_CONSULTA,
	ROUTE_DETRAN_CRV_IMPRESSAO_RENAVAM_RESULTADO,
	ROUTE_DETRAN_CRV_IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_CONSULTA,
	ROUTE_DETRAN_CRV_IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_RESULTADO,
	ROUTE_DETRAN_CRV_IMPRESSAO_TROCA_VOLUNTARIA_PLACA,
} from '../routes/paths';
// // ANDAMENTO PROCESSO
import AndamentoProcesso from './AndamentoProcesso';
import AndamentoProcessoResultado from './AndamentoProcesso/ResultadoConsulta';
// // CONSULTAR FICHA CADASTRAL
import ConsultarFichaCadastral from './ConsultarFichaCadastral';
import ConsultarFichaCadastralResultado from './ConsultarFichaCadastral/ResultadoConsulta';
// // EXCLUIR FICHA CADASTRAL
import ExcluirFichaCadastral from './ExcluirFichaCadastral';
import ExcluirFichaCadastralResultado from './ExcluirFichaCadastral/ResultadoConsulta';
// // IMPRESSÃO ALTERAÇÃO ENDEREÇO
import ImpressaoAlteracaoEndereco from './ImpressaoAlteracaoEndereco';
// // IMPRESSÃO COMUNICAÇÃO VENDA
import ImpressaoComunicacaoVenda from './ImpressaoComunicacaoVenda/Consulta';
import ImpressaoComunicacaoVendaResultado from './ImpressaoComunicacaoVenda/Resultado';
// // IMPRESSÃO LICENCIAMENTO VEICULOS
import ImpressaoLicenciamentoVeiculos from './ImpressaoLicenciamentoVeiculos';
import ImpressaoLicenciamentoVeiculosResultado from './ImpressaoLicenciamentoVeiculos/Resultado';
// // IMPRESSÃO OFICIOS SEGUNDA VIA PLACA
import ImpressaoOficioSegundaViaPlaca from './ImpressaoOficioSegundaViaPlaca';
// // IMPRESSÃO RENAVAM
import ImpressaoRenavam from './ImpressaoRenavam/Consulta';
import ImpressaoRenavamResultado from './ImpressaoRenavam/Resultado';
// // IMPRESSÃO SEGUNDA VIA LICENCIAMENTO
import ImpressaoSegundaViaLicenciamento from './ImpressaoSegundaViaLicenciamento/Consulta';
import ImpressaoSegundaViaLicenciamentoResultado from './ImpressaoSegundaViaLicenciamento/Resultado';
// // IMPRESSÃO TROCA VALUNTÁRIA PLACA
import ImpressaoTrocaVoluntariaPlaca from './ImpressaoTrocaVoluntariaPlaca';

export const detranCrvRoute = {
	name: 'DETRAN CRV',
	route: '/detran-crv',
};

export default [
	{
		path: ROUTE_DETRAN_CRV_CONSULTAR_FICHA_CADASTRAL,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Ficha Cadastral',
			},
		],
		Component: ConsultarFichaCadastral,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CONSULTAR_FICHA_CADASTRAL_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Ficha Cadastral',
			},
		],
		Component: ConsultarFichaCadastralResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_EXCLUIR_FICHA_CADASTRAL,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Excluir Ficha Cadastral',
			},
		],
		Component: ExcluirFichaCadastral,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_EXCLUIR_FICHA_CADASTRAL_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Excluir Ficha Cadastral',
			},
		],
		Component: ExcluirFichaCadastralResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_IMPRESSAO_RENAVAM_CONSULTA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Impressão RENAVAM',
			},
		],
		Component: ImpressaoRenavam,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_IMPRESSAO_RENAVAM_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Impressão RENAVAM',
			},
		],
		Component: ImpressaoRenavamResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_IMPRESSAO_LICENCIAMENTO_VEICULOS_CONSULTA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Impressão Licenciamento de Veículos',
			},
		],
		Component: ImpressaoLicenciamentoVeiculos,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_IMPRESSAO_LICENCIAMENTO_VEICULOS_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Impressão Licenciamento de Veículos',
			},
		],
		Component: ImpressaoLicenciamentoVeiculosResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_IMPRESSAO_ALTERACAO_ENDERECO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Impressão Alteração de Endereço',
			},
		],
		Component: ImpressaoAlteracaoEndereco,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_CONSULTA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Impressão Segunda Via Licenciamento',
			},
		],
		Component: ImpressaoSegundaViaLicenciamento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Impressão Segunda Via Licenciamento',
			},
		],
		Component: ImpressaoSegundaViaLicenciamentoResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_IMPRESSAO_COMUNICACAO_VENDA_CONSULTA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Impressão Comunicação de Venda',
			},
		],
		Component: ImpressaoComunicacaoVenda,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_IMPRESSAO_COMUNICACAO_VENDA_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Impressão Comunicação de Venda',
			},
		],
		Component: ImpressaoComunicacaoVendaResultado,
		isAuthenticated: true,
		exact: true,
	},

	{
		path: ROUTE_DETRAN_CRV_IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Impressão Oficio Segunda Via de Placa',
			},
		],
		Component: ImpressaoOficioSegundaViaPlaca,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_IMPRESSAO_TROCA_VOLUNTARIA_PLACA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Impressão Troca Voluntaria de Placa',
			},
		],
		Component: ImpressaoTrocaVoluntariaPlaca,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_ANDAMENTO_PROCESSO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Andamento do Processo',
			},
		],
		Component: AndamentoProcesso,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_ANDAMENTO_PROCESSO_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Andamento do Processo',
			},
		],
		Component: AndamentoProcessoResultado,
		isAuthenticated: true,
		exact: true,
	},
];
