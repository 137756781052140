import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export function consultarTiposRequest(): ReducerAction {
	return {
		type: Types.CONSULTAR_TIPOS,
		payload: null,
	};
}
export function consultarTiposSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_TIPOS_SUCCESS,
		payload,
	};
}
export function consultarTiposFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_TIPOS_FAILURE,
		payload: null,
	};
}
export function consultarTiposClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_TIPOS_CLEAR,
		payload: null,
	};
}
