import { Types } from './types';

export function consultarFichaCadastralRequest(placa: string) {
	return {
		type: Types.CONSULTAR_FICHA_CADASTRAL_REQUEST,
		placa,
	};
}
export function consultarFichaCadastralSuccess(payload: any) {
	return {
		type: Types.CONSULTAR_FICHA_CADASTRAL_SUCCESS,
		payload,
	};
}
export function consultarFichaCadastralFailure(payload: []): any {
	return {
		type: Types.CONSULTAR_FICHA_CADASTRAL_FAILURE,
		payload,
	};
}
export function consultarFichaCadastralClear(): any {
	return {
		type: Types.CONSULTAR_FICHA_CADASTRAL_CLEAR,
	};
}
