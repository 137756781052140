import React from 'react';

import { Row, Col } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import TextArea from 'components/Common/Form/Input/TextArea';

// FORM
import { IFormInclusaoObsPrimeiraViaMenor } from 'pages/iirgd/RgFicDigital/InclusaoObservacoes/Formularios/form';

// STYLED
import { limparMascara } from 'utils/genericFunctions';
import { ConsultarProcesso } from 'store/modules/api/iirgd/integrador/consultarProcesso/types';
import { Label, Value } from '../../../../styled';
import { IconContainer, IconCheck, TextCheck } from '../../styled';

interface Props {
	data: IFormInclusaoObsPrimeiraViaMenor;
}

export const PrimeiraViaMenor: React.FC<Props> = ({ data }) => {
	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const { consultarProcesso } = useSelector(
		(state: ApplicationState) => state.api.iirgd.integrador,
	);

	const situacaoCin = (codigoSituacao: ConsultarProcesso[]) => {
		let situacao = false;
		codigoSituacao.forEach(item => {
			if (item.codigoSituacao === 3) {
				situacao = true;
			}
		});

		return situacao;
	};

	return (
		<>
			{data.rgForaSpNum !== '' && (
				<Row gutter={[0, 10]}>
					<Col span={5}>
						<Label>CARTEIRA DE IDENT. NO ESTADO:</Label>
					</Col>
					<Col span={3}>
						<Value>{data.rgForaSpUf}</Value>
					</Col>

					<Col span={5}>
						<Label>Número Cart. de Identidade:</Label>
					</Col>
					<Col span={3}>
						<Value>{data.rgForaSpNum}</Value>
					</Col>

					<Col span={4}>
						<Label>Expedido em:</Label>
					</Col>
					<Col span={4}>
						<Value>{data.rgForaSpEmissao ? data.rgForaSpEmissao : ''}</Value>
					</Col>
				</Row>
			)}

			{cadastroRg.form.principal?.doador && (
				<Row gutter={[0, 10]}>
					<Col span={4}>
						<IconContainer>
							<IconCheck />
						</IconContainer>
					</Col>
					<Col span={20}>
						<TextCheck>Doador de Órgãos e Tecidos</TextCheck>
					</Col>
				</Row>
			)}

			{data.rgForaSp && (
				<Row gutter={[0, 10]}>
					<Col span={4}>
						<IconContainer>
							<IconCheck />
						</IconContainer>
					</Col>
					<Col span={12}>
						<TextCheck>
							Não possui Carteira de Identidade no Estado de São Paulo
						</TextCheck>
					</Col>
				</Row>
			)}

			{data.termoTutela && (
				<Row gutter={[0, 10]}>
					<Col span={4}>
						<IconContainer>
							<IconCheck />
						</IconContainer>
					</Col>
					<Col span={12}>
						<TextCheck>Termo de Guarda/Tutela/Conselho</TextCheck>
					</Col>

					<Col span={3}>
						<Label>Proc.:</Label>
					</Col>
					<Col span={5}>
						<Value>
							{`${limparMascara(data.tutelaProc)}/${limparMascara(
								data.tutelaAno,
							)} (${data.tutelaData})`}
						</Value>
					</Col>
				</Row>
			)}

			{cadastroRg.dare &&
				(cadastroRg.dare.status || cadastroRg.dare.status === 0) &&
				cadastroRg.form.principal &&
				cadastroRg.form.principal.entrega !== 'P' && (
					<>
						<Row gutter={[0, 10]}>
							<Col span={4}>
								<IconContainer>
									<IconCheck />
								</IconContainer>
							</Col>
							<Col span={20}>
								<TextCheck>
									{cadastroRg.dare.status === 1 ||
									cadastroRg.dare.status === 2 ||
									cadastroRg.dare.status === 6
										? 'Taxa de correio paga.'
										: 'Taxa de correio a ser paga.'}
								</TextCheck>
							</Col>
						</Row>
					</>
				)}

			{cadastroRg.form.principal?.dni === '10977' &&
				!situacaoCin(consultarProcesso.data || []) && (
					<Row gutter={[0, 10]}>
						<Col span={4}>
							<IconContainer>
								<IconCheck />
							</IconContainer>
						</Col>
						<Col span={20}>
							<TextCheck>Isenção da Taxa da CIN</TextCheck>
						</Col>
					</Row>
				)}

			<Row gutter={[0, 10]}>
				<Col span={4}>
					<Label>Observações:</Label>
				</Col>
				<Col span={20}>
					<TextArea name="observacao" value={data.observacao} disabled />
				</Col>
			</Row>
		</>
	);
};
