import React from 'react';
import { Row, Descriptions } from 'antd';

import { formatDate } from 'utils/genericFunctions';

import Section from 'components/Common/Section';

import { ConsultarAcompanhamentoResponse } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarAcompanhamento/types';

interface Props {
	data: ConsultarAcompanhamentoResponse | null;
}

const DadosProcesso: React.FC<Props> = ({ data }) => {
	const { Item } = Descriptions;
	return (
		<>
			<Section size="sm" title="Consultar Ficha Cadastral">
				<Row gutter={[0, 10]}>
					<Descriptions column={3}>
						<Item label="Número da Ficha" labelStyle={{ fontWeight: 'bold' }}>
							{data?.numeroFicha}
						</Item>
						<Item label="Ano Ficha" labelStyle={{ fontWeight: 'bold' }}>
							{data?.anoFicha}
						</Item>
						<Item label="Placa" labelStyle={{ fontWeight: 'bold' }}>
							{data?.placa}
						</Item>
					</Descriptions>
				</Row>
				<Row gutter={[0, 10]}>
					<Descriptions column={3}>
						<Item label="Renavam" labelStyle={{ fontWeight: 'bold' }}>
							{data?.renavam}
						</Item>
						<Item label="Data Emissão" labelStyle={{ fontWeight: 'bold' }}>
							{formatDate(data?.dataEmissao)}
						</Item>
						<Item label="Opção Serviço" labelStyle={{ fontWeight: 'bold' }}>
							{data?.opcaoServico}
						</Item>
					</Descriptions>
				</Row>
				<Row gutter={[0, 10]}>
					<Descriptions column={3}>
						<Item label="Origem Serviço" labelStyle={{ fontWeight: 'bold' }}>
							{data?.ufOrigemVeiculo}
						</Item>
						<Item
							label="Unidade de Trânsito"
							labelStyle={{ fontWeight: 'bold' }}
						>
							{data?.unidadeRetirada?.nomeUnidadeTransito}
						</Item>
						<Item label="Data Solicitação" labelStyle={{ fontWeight: 'bold' }}>
							{formatDate(data?.dataSolicitacao)}
						</Item>
					</Descriptions>
				</Row>
				<Row gutter={[0, 10]}>
					<Descriptions column={3}>
						<Item
							label="Data Atualização Retaguarda"
							labelStyle={{ fontWeight: 'bold' }}
						>
							{formatDate(data?.dataAtualizacaoRetaguarda)}
						</Item>
						<Item
							label="Bloqueio Administrativo"
							labelStyle={{ fontWeight: 'bold' }}
						>
							{data?.existeBloqueioAdministrativo}
						</Item>
						<Item
							label="Data Desbloqueio Administrativo"
							labelStyle={{ fontWeight: 'bold' }}
						>
							{formatDate(data?.dataDesbloqueioAdministrativo)}
						</Item>
					</Descriptions>
				</Row>
				<Row gutter={[0, 10]}>
					<Descriptions>
						<Item label="Status Andamento" labelStyle={{ fontWeight: 'bold' }}>
							{data?.descricaoAndamento}
						</Item>
					</Descriptions>
				</Row>
			</Section>
		</>
	);
};

export default DadosProcesso;
