import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { ApplicationState } from 'store';
import { SectionAtendimento } from 'store/modules/api/procon/enum';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { consultaAtendimentoPfClear } from 'store/modules/api/procon/atendimentoCip/consultaAtendimentoPf/actions';
import { consultaAtendimentoPjClear } from 'store/modules/api/procon/atendimentoCip/consultaAtendimentoPj/actions';

// ANT DESIGN
import { Row, Col } from 'antd';

// COMPONENTS
import Alert from 'components/Common/Notifications/Alert';
import Section from 'components/Common/Section';
import TypographyCommon from 'components/Common/Typography';
import { ConsultaCipDataPfPj } from 'store/modules/api/procon/atendimentos/types';
import { enviaInteracoesAtendimentoClear } from 'store/modules/api/procon/interacoes/atendimentos/enviaInteracoes/actions';
import { consultaProcessoAdministrativoClear } from 'store/modules/api/procon/processosAdministrativos/processosAdministrativos/actions';
import { ConsultaAtendimentosDeConsultaData } from 'store/modules/api/procon/atendimentosDeConsulta/consultaAtendimentosDeConsulta/types';
import LightButton from 'components/Common/Buttons/Light';
import { ROUTE_PROCON } from 'routes/paths';
import {
	ICadastroEventoRequest,
	IDadosRequest,
} from 'store/modules/api/contabilizacao/cadastroEvento/types';
import { limparMascara } from 'utils/genericFunctions';
import { cadastroEventoRequest } from 'store/modules/api/contabilizacao/cadastroEvento/actions';
import { getTimestamp } from 'utils/dateFunctions';
import ResolucaoAtendimentoModal from './components/ResolucaoAtendimentoModal';
import ResolucaoConsultaModal from './components/ResolucaoConsultaModal';
import Atendimento from './Atendimento';
import Fornecedor from './Fornecedor';
import RespostasInteracoes from './RespostasInteracoes';
import DocumentosProcon from './DocumentosProcon';

// PATHS
import { ROUTE_PROCON_PESQUISA_ATENDIMENTO } from '../../routes/paths';
import AtendimentoTipoConsulta from './components/AtendimentoTipoConsulta';
import FornecedorTipoConsulta from './components/FornecedorTipoConsulta';
import RespostasInteracoesTipoConsulta from './components/RespostasInteracoesTipoConsulta';
import DocumentosProconTipoConsulta from './components/DocumentosProconTipoConsulta';

type TypeAccounting = {
	manifestacaoFinalizarAtendimento: {
		id_servico: string | number;
		desc_servico: string;
		tipo: string;
	};
};

type TypeDataAtendimento = {
	protocolo: string;
	atendimentoId: string;
};

const VisualizarAtendimento: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const [section, setSection] = useState<SectionAtendimento>(
		SectionAtendimento.ATENDIMENTO,
	);

	const [dataAtendimento, setDataAtendimento] =
		useState<TypeDataAtendimento | null>(null);

	const [formData, setFormData] = useState<ConsultaCipDataPfPj | null>(null);

	const [formDataTipoConsulta, setFormDataTipoConsulta] =
		useState<ConsultaAtendimentosDeConsultaData | null>(null);

	const [showModal, setShowModal] = useState<boolean>(false);

	const [showAlert, setShowAlert] = useState<boolean>(false);

	const [resolvido, setResolvido] = useState<boolean | null>(null);

	const { consultaAtendimentoPf, consultaAtendimentoPj } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentoCip,
	);

	const { consultaAtendimentosDeConsulta } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentosDeConsulta,
	);

	const { consultaAtendimentos } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentos,
	);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.salvarAtendimento.data,
	);

	const statusRespostaResolvida = useSelector(
		(state: ApplicationState) =>
			state.api.procon.atendimentoCip.respostaResolvida.status,
	);

	const statusRespostaNaoResolvida = useSelector(
		(state: ApplicationState) =>
			state.api.procon.atendimentoCip.respostaNaoResolvida.status,
	);

	useEffect(() => {
		dispatch(enviaInteracoesAtendimentoClear());
		dispatch(consultaProcessoAdministrativoClear());
	}, [dispatch]);

	useEffect(() => {
		if (consultaAtendimentoPf.status === 200 && consultaAtendimentoPf.data) {
			setDataAtendimento({
				protocolo: consultaAtendimentoPf.data.protocolo,
				atendimentoId: consultaAtendimentoPf.data.atendimentoId,
			});

			setFormData(consultaAtendimentoPf.data);
		}

		if (consultaAtendimentoPj.status === 200 && consultaAtendimentoPj.data) {
			setDataAtendimento({
				protocolo: consultaAtendimentoPj.data.protocolo,
				atendimentoId: consultaAtendimentoPj.data.tipoDeAtendimento,
			});

			setFormData(consultaAtendimentoPj.data);
		}
	}, [consultaAtendimentoPf, consultaAtendimentoPj, setFormData]);

	useEffect(() => {
		if (
			consultaAtendimentosDeConsulta.status === 200 &&
			consultaAtendimentosDeConsulta.data
		) {
			setDataAtendimento({
				protocolo: consultaAtendimentosDeConsulta.data.protocolo,
				atendimentoId: consultaAtendimentosDeConsulta.data.atendimentoId,
			});

			setFormDataTipoConsulta(consultaAtendimentosDeConsulta.data);
		}
	}, [consultaAtendimentosDeConsulta, setFormData]);

	const handleShowModal = useCallback(_resolvido => {
		setShowModal(true);
		setResolvido(_resolvido);
	}, []);

	const handleTipoContabilizacao = useMemo(() => {
		return {
			manifestacaoFinalizarAtendimento: {
				id_servico: 12,
				desc_servico: 'procon_manifestacao_finalizar_atendimento',
				tipo: 'procon_manifestacao_finalizar_atendimento',
			},
		};
	}, []);

	const handleContabilizar = useCallback(() => {
		const tipoContabilizacao: TypeAccounting['manifestacaoFinalizarAtendimento'] =
			handleTipoContabilizacao.manifestacaoFinalizarAtendimento;

		const dados: IDadosRequest = {
			data_hora: getTimestamp().toString() || '',
			id_servico: tipoContabilizacao.id_servico || '',
			desc_servico: tipoContabilizacao.desc_servico || '',
			identificacao: Number(limparMascara(consultaAtendimentos.consulta)) || 0,
			cpf: limparMascara(login.user.cpf) || '',
			ip: login.user.ip || '',
			id_orgao: 390,
			desc_orgao: 'PROCON',
			id_localidade: login.user.idLocal,
			desc_localidade: login.user.nomePosto || '',
		};

		const contabilizacao: ICadastroEventoRequest = {
			id_atendimento: dadosAtendimento?.idAtendimento || '',
			tipo: tipoContabilizacao.tipo || '',
			id_cidadao: dadosAtendimento?.idCidadao || '',
			data: getTimestamp() || 0,
			dados: JSON.stringify(dados) || '',
			canal: {
				id: login.user.idCanal.toString() || '',
				descricao: login.user.descCanal || '',
			},
		};

		dispatch(cadastroEventoRequest(contabilizacao));
	}, [
		handleTipoContabilizacao,
		login,
		dadosAtendimento,
		dispatch,
		consultaAtendimentos,
	]);

	const handleEndService = useCallback(() => {
		if (
			statusRespostaResolvida === 0 ||
			statusRespostaResolvida === 200 ||
			statusRespostaNaoResolvida === 0 ||
			statusRespostaNaoResolvida === 200
		) {
			handleContabilizar();
		}

		history.push(ROUTE_PROCON);
	}, [
		history,
		statusRespostaResolvida,
		statusRespostaNaoResolvida,
		handleContabilizar,
	]);

	const handleNextSection = useCallback(() => {
		switch (section) {
			case SectionAtendimento.ATENDIMENTO:
				setSection(SectionAtendimento.FORNECEDOR);
				break;
			case SectionAtendimento.FORNECEDOR:
				setSection(SectionAtendimento.RESPOSTAS_INTERACOES);
				break;
			case SectionAtendimento.RESPOSTAS_INTERACOES:
				setSection(SectionAtendimento.DOCUMENTOS_PROCON);
				break;
			default:
				setSection(SectionAtendimento.DOCUMENTOS_PROCON);
				break;
		}

		dispatch(clearNotifications());
	}, [section, setSection, dispatch]);

	const handleBackSection = useCallback(() => {
		switch (section) {
			case SectionAtendimento.ATENDIMENTO:
				history.push(ROUTE_PROCON_PESQUISA_ATENDIMENTO);
				dispatch(consultaAtendimentoPfClear());
				dispatch(consultaAtendimentoPjClear());
				break;
			case SectionAtendimento.FORNECEDOR:
				setSection(SectionAtendimento.ATENDIMENTO);
				break;
			case SectionAtendimento.RESPOSTAS_INTERACOES:
				setSection(SectionAtendimento.FORNECEDOR);
				break;
			case SectionAtendimento.DOCUMENTOS_PROCON:
				setSection(SectionAtendimento.RESPOSTAS_INTERACOES);
				break;
			default:
				setSection(SectionAtendimento.ATENDIMENTO);
				break;
		}

		dispatch(clearNotifications());
	}, [section, setSection, history, dispatch]);

	const viewController = useCallback(() => {
		return (
			<>
				{section === SectionAtendimento.ATENDIMENTO && (
					<span>
						{formDataTipoConsulta ? (
							<AtendimentoTipoConsulta
								handleBackSection={handleBackSection}
								handleNextSection={handleNextSection}
								formData={formDataTipoConsulta}
							/>
						) : (
							<Atendimento
								handleBackSection={handleBackSection}
								handleNextSection={handleNextSection}
								formData={formData}
							/>
						)}
					</span>
				)}
				{section === SectionAtendimento.FORNECEDOR && (
					<span>
						{formDataTipoConsulta ? (
							<FornecedorTipoConsulta
								handleBackSection={handleBackSection}
								handleNextSection={handleNextSection}
								formData={formDataTipoConsulta}
							/>
						) : (
							<Fornecedor
								handleBackSection={handleBackSection}
								handleNextSection={handleNextSection}
								formData={formData}
							/>
						)}
					</span>
				)}
				{section === SectionAtendimento.RESPOSTAS_INTERACOES && (
					<span>
						{formDataTipoConsulta ? (
							<RespostasInteracoesTipoConsulta
								handleBackSection={handleBackSection}
								handleNextSection={handleNextSection}
								formData={formDataTipoConsulta}
							/>
						) : (
							<RespostasInteracoes
								handleBackSection={handleBackSection}
								handleNextSection={handleNextSection}
								formData={formData}
							/>
						)}
					</span>
				)}
				{section === SectionAtendimento.DOCUMENTOS_PROCON && (
					<span>
						{formDataTipoConsulta ? (
							<DocumentosProconTipoConsulta
								handleBackSection={handleBackSection}
								handleShowModal={handleShowModal}
								formData={formDataTipoConsulta}
							/>
						) : (
							<DocumentosProcon
								handleBackSection={handleBackSection}
								handleShowModal={handleShowModal}
								formData={formData}
								resolvido={resolvido}
							/>
						)}
					</span>
				)}
			</>
		);
	}, [
		handleBackSection,
		handleNextSection,
		handleShowModal,
		section,
		formData,
		formDataTipoConsulta,
		resolvido,
	]);

	const handleTitleAlert = useCallback(() => {
		if (formDataTipoConsulta && resolvido) {
			return 'Consulta Resolvida com Sucesso';
		}

		if (formDataTipoConsulta && !resolvido) {
			return 'Consulta Cancelada com Sucesso';
		}

		if (!formDataTipoConsulta && resolvido) {
			return 'Reclamação Resolvida';
		}

		if (!formDataTipoConsulta && !resolvido) {
			return 'Reclamação Não Resolvida';
		}

		return 'Registro Efetuado com Sucesso';
	}, [formDataTipoConsulta, resolvido]);

	return (
		<>
			{resolvido !== null && showAlert && (
				<>
					<Alert
						type="success"
						message={handleTitleAlert()}
						onClose={() => setShowAlert(false)}
						closable
						showIcon
					/>
					<br />
				</>
			)}

			<Row gutter={[0, 0]} align="top" justify="start" className="no-print">
				<Col className="gutter-row" span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Visualizar Atendimento"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>

			<Section
				bgColor="cinza-escuro"
				style={{
					border: '1px grey solid',
					borderRadius: '4px',
					marginTop: '0px',
				}}
			>
				{viewController()}

				{formDataTipoConsulta ? (
					<ResolucaoConsultaModal
						resolvido={resolvido}
						visivel={showModal}
						setShowAlert={setShowAlert}
						setShowModal={setShowModal}
						dataAtendimento={dataAtendimento}
					/>
				) : (
					<ResolucaoAtendimentoModal
						resolvido={resolvido}
						visivel={showModal}
						setShowAlert={setShowAlert}
						setShowModal={setShowModal}
						dataAtendimento={dataAtendimento}
					/>
				)}
			</Section>
			<Col span="30">
				<Row align="bottom" justify="end">
					<LightButton
						type="button"
						buttonColor="green"
						icon="check"
						text="Finalizar Atendimento"
						buttonSize="sm"
						buttonWidth={190}
						onClick={handleEndService}
					/>
				</Row>
			</Col>
		</>
	);
};
export default VisualizarAtendimento;
