import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Col, Row } from 'antd';
import { Formik, Form, Field, FormikProps } from 'formik';

import Section from 'components/Common/Section';

import { ApplicationState } from 'store';
import {
	cadastraBeneficiario,
	clearFamiliarVivaLeite,
	consultaBeneficiarioInclusao,
	consultaCotasEntidade,
	clearNotificationsBeneficiariosVivaLeite,
} from 'store/modules/api/seds/vivaLeite/beneficiarios/actions';

import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';
import Select from 'components/Common/Form/Select';
import Input from 'components/Common/Form/Input/Input';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

import Padrao from '../components/pages/Padrao';

import {
	InitialValues,
	schema,
	selectPrograma,
	IBuscaBeneficiario,
	InitialConsultaCotas,
	InitialEntidadeCotas,
	InitialDadosBeneficiarios,
	InitialBuscaDadosBeneficiarios,
	InitialIncluirBeneficiarios,
} from './form';

export interface IDataMotivo {
	codigo_motivo: number;
	outros_motivos?: string;
}

export interface IOutrosMotivos {
	outros_motivos: string[];
}

const IncluirBeneficiario = () => {
	const {
		cotasEntidadesData,
		dadosFamilia,
		statusTransacaoBeneficiarios,
	} = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.beneficiarios,
	);

	const { selectedUser } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.usuario,
	);

	const dispatch = useDispatch();
	const history = useHistory();
	const routeMatch = useRouteMatch();

	const [buscaEntidades, setBuscaEntidade] = useState(InitialEntidadeCotas);
	const [dadosBeneficiarios, setDadosBeneficiarios] = useState(
		InitialDadosBeneficiarios,
	);
	const [codigoPrograma, setCodigoPrograma] = useState(0);
	const [urlServico, setUrlServico] = useState<string>('');

	const handleSubmit = useCallback(
		(value: IBuscaBeneficiario) => {
			const servico =
				urlServico === 'resultado'
					? 'ATUALIZACAO CADASTRAL'
					: 'PRESTACAO DE CONTAS';

			const requestData = InitialBuscaDadosBeneficiarios;
			requestData.servico = servico;
			requestData.num_pan = selectedUser.numeroPan;
			requestData.cod_programa = Number(value.cod_programa);
			requestData.etapa = 'busca_familia';
			requestData.nis = value.nis;

			dispatch(consultaBeneficiarioInclusao(requestData));
		},
		[dispatch, selectedUser.numeroPan, urlServico],
	);

	const handleIncluir = useCallback(
		(nomeResponsavel?: string) => {
			const servico =
				urlServico === 'resultado'
					? 'ATUALIZACAO CADASTRAL'
					: 'PRESTACAO DE CONTAS';

			const requestData = InitialIncluirBeneficiarios;

			requestData.servico = servico;
			requestData.num_pan = selectedUser.numeroPan;
			requestData.cod_programa = dadosBeneficiarios.cod_programa;
			requestData.cod_familia_cadunico = dadosFamilia?.cod_familia_cadunico;
			requestData.etapa = 'efetiva_inclusao';

			if (codigoPrograma === 1) {
				requestData.cod_pessoa_cadunico_cri =
					dadosFamilia?.cod_pessoa_cadunico_cri;
				requestData.cod_pessoa_cadunico =
					dadosFamilia?.cod_pessoa_cadunico_resp;
			} else {
				requestData.cod_pessoa_cadunico =
					dadosFamilia?.cod_pessoa_cadunico_idoso;
				if (nomeResponsavel) {
					requestData.nome_responsavel_idoso = nomeResponsavel;
				}
			}

			dispatch(cadastraBeneficiario(requestData));

			const dadosBeneficiariosLabel = { ...InitialDadosBeneficiarios };
			setDadosBeneficiarios({ ...dadosBeneficiariosLabel });
		},
		[
			urlServico,
			selectedUser.numeroPan,
			dadosBeneficiarios.cod_programa,
			dadosFamilia,
			codigoPrograma,
			dispatch,
		],
	);

	const handleNext = useCallback(() => {
		history.push('/seds/vivaleite/prestacaoDeContas/enviarNotasFiscais');
	}, [history]);

	const handleChangeProgram = useCallback(
		(value: number, formik: FormikProps<any>) => {
			formik.setFieldValue('cod_programa', value);
			setCodigoPrograma(value);

			const updatedCotasEntidades = InitialEntidadeCotas;

			if (value === 1) {
				updatedCotasEntidades.cota = cotasEntidadesData.programa_crianca.cota;
				updatedCotasEntidades.vagas = cotasEntidadesData.programa_crianca.vagas;
				updatedCotasEntidades.labelNis = 'Nis da Mãe';
				setBuscaEntidade(updatedCotasEntidades);
			} else if (value === 2) {
				updatedCotasEntidades.cota = cotasEntidadesData.programa_idoso.cota;
				updatedCotasEntidades.vagas = cotasEntidadesData.programa_idoso.vagas;
				updatedCotasEntidades.labelNis = 'Nis do Idoso';
			}

			setBuscaEntidade({ ...updatedCotasEntidades });
			dispatch(clearFamiliarVivaLeite());
		},
		[cotasEntidadesData, dispatch],
	);

	const handleCleanStatus = useCallback(() => {
		if (statusTransacaoBeneficiarios !== '') {
			dispatch(clearNotificationsBeneficiariosVivaLeite());
		}
	}, [dispatch, statusTransacaoBeneficiarios]);

	useEffect(() => {
		const requestData = InitialConsultaCotas;
		requestData.num_pan = selectedUser.numeroPan;

		dispatch(consultaCotasEntidade(requestData));
	}, [dispatch, selectedUser.numeroPan]);

	useEffect(() => {
		if (dadosFamilia?.cod_familia_cadunico !== '' && codigoPrograma === 1) {
			const dadosBeneficiariosLabel = { ...InitialDadosBeneficiarios };

			dadosBeneficiariosLabel.labelNome = 'Nome da Mãe/Responsável:';
			dadosBeneficiariosLabel.nome = dadosFamilia?.responsavel;
			dadosBeneficiariosLabel.dataNascimento =
				dadosFamilia?.data_nasc_responsavel;
			dadosBeneficiariosLabel.nis = dadosFamilia?.nis_responsavel;
			dadosBeneficiariosLabel.cod_programa = 1;
			dadosBeneficiariosLabel.renda = dadosFamilia?.renda_media_familia;
			setDadosBeneficiarios({ ...dadosBeneficiariosLabel });
		} else if (
			dadosFamilia?.cod_familia_cadunico !== '' &&
			codigoPrograma === 2
		) {
			const dadosBeneficiariosLabel = { ...InitialDadosBeneficiarios };
			dadosBeneficiariosLabel.labelNome = 'Nome do Idoso:';
			dadosBeneficiariosLabel.nome = dadosFamilia?.idoso;
			dadosBeneficiariosLabel.dataNascimento = dadosFamilia?.data_nasc_idoso;
			dadosBeneficiariosLabel.nis = dadosFamilia?.nis_idoso;
			dadosBeneficiariosLabel.cod_programa = 2;
			dadosBeneficiariosLabel.renda = dadosFamilia?.renda_media_familia;

			setDadosBeneficiarios({ ...dadosBeneficiariosLabel });
		} else if (dadosFamilia?.cod_familia_cadunico === '') {
			const dadosBeneficiariosLabel = { ...InitialDadosBeneficiarios };
			setDadosBeneficiarios({ ...dadosBeneficiariosLabel });
		}
	}, [dispatch, dadosFamilia, codigoPrograma]);

	useEffect(() => {
		const urlPath = routeMatch.path.split('/');

		setUrlServico(urlPath[urlPath.length - 2]);
	}, [routeMatch]);

	useEffect(() => {
		return function cleanup() {
			handleCleanStatus();
		};
	}, [handleCleanStatus]);

	return (
		<Padrao
			statusTransacao={statusTransacaoBeneficiarios}
			onCleanStatus={handleCleanStatus}
		>
			<>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Section size="sm" titleBold={false} title="INCLUIR BENEFICIARIO">
							<Formik
								validateOnChange={false}
								validateOnBlur={false}
								initialValues={InitialValues}
								validate={values => getValidationsErrors(values, schema)}
								onSubmit={handleSubmit}
							>
								{formik => {
									return (
										<Form>
											<Row gutter={[0, 10]}>
												<Col span={24}>
													<Row gutter={[0, 10]}>
														<Col span={10}>
															<Field
																title="Programa"
																name="cod_programa"
																as={Select}
																options={selectPrograma}
																onChange={(v: number) =>
																	handleChangeProgram(v, formik)
																}
																titleSize="auto"
																error={hasError(formik.errors, 'cod_programa')}
															/>
														</Col>
													</Row>
												</Col>
											</Row>
											<Section
												size="sm"
												titleBold={false}
												title="BUSCAR BENEFICIÁRIO"
											>
												<Row gutter={[0, 10]}>
													<Col span={4}>
														<Row gutter={[0, 10]}>
															<Col span={8}>
																<p>
																	<strong>Cota: </strong>
																</p>
															</Col>
															<Col span={16}>
																<p>{buscaEntidades.cota}</p>
															</Col>
														</Row>
													</Col>

													<Col span={4}>
														<Row gutter={[0, 10]}>
															<Col span={8}>
																<p>
																	<strong>Vagas: </strong>
																</p>
															</Col>
															<Col span={16}>
																<p>{buscaEntidades.vagas}</p>
															</Col>
														</Row>
													</Col>

													<Col span={8}>
														<Field
															title={buscaEntidades.labelNis}
															name="nis"
															maxLength="11"
															as={Input}
															error={hasError(formik.errors, 'nis')}
														/>
													</Col>
													<Col span={5} offset={1}>
														<ButtonImage src="pesquisar" type="submit" />
													</Col>
												</Row>
											</Section>

											<Section
												size="sm"
												titleBold={false}
												title="BUSCAR BENEFICIÁRIO"
											>
												<Row gutter={[0, 10]}>
													<Col span={24}>
														<Row gutter={[0, 10]}>
															<Col>
																<p>
																	<strong>
																		{dadosBeneficiarios.labelNome}
																	</strong>
																</p>
															</Col>
															<Col offset={1}>
																<p>{dadosBeneficiarios.nome}</p>
															</Col>
														</Row>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col span={12}>
														<Row gutter={[0, 10]}>
															<Col span={8}>
																<p>
																	<strong>Data de Nascimento:</strong>
																</p>
															</Col>
															<Col span={16}>
																<p>{dadosBeneficiarios.dataNascimento}</p>
															</Col>
														</Row>
													</Col>

													<Col span={5}>
														<Row gutter={[0, 10]}>
															<Col span={8}>
																<strong>
																	<p>NIS:</p>
																</strong>
															</Col>
															<Col span={16}>
																<p>{dadosBeneficiarios.nis}</p>
															</Col>
														</Row>
													</Col>

													<Col span={7}>
														<Row gutter={[0, 10]}>
															<Col span={8}>
																<p>
																	<strong>Renda:</strong>
																</p>
															</Col>
															<Col span={16}>
																<p>{dadosBeneficiarios.renda}</p>
															</Col>
														</Row>
													</Col>
												</Row>

												{dadosBeneficiarios.cod_programa === 1 && (
													<Row gutter={[0, 10]}>
														<Col span={24}>
															<Row gutter={[0, 10]}>
																<Col span={24}>
																	<Row gutter={[0, 10]}>
																		<Col>
																			<p>
																				<strong>Criança Elegível:</strong>
																			</p>
																		</Col>
																		<Col offset={1}>
																			<p>{dadosFamilia?.crianca}</p>
																		</Col>
																	</Row>
																</Col>
															</Row>

															<Row gutter={[0, 10]}>
																<Col span={12}>
																	<Row gutter={[0, 10]}>
																		<Col span={8}>
																			<p>
																				<strong>Data Nascimento:</strong>
																			</p>
																		</Col>
																		<Col span={16}>
																			<p>{dadosFamilia?.data_nasc_crianca}</p>
																		</Col>
																	</Row>
																</Col>

																<Col span={5}>
																	<Row gutter={[0, 10]}>
																		<Col span={8}>
																			<p>
																				<strong>NIS:</strong>
																			</p>
																		</Col>
																		<Col span={16}>
																			<p>{dadosFamilia?.nis_crianca}</p>
																		</Col>
																	</Row>
																</Col>
															</Row>
														</Col>
													</Row>
												)}

												{dadosBeneficiarios.cod_programa === 2 && (
													<Row gutter={[0, 10]}>
														<Col span={24}>
															<Field
																title="Informe o nome do responsável pelo idoso (em caso de dependência)"
																name="nome_responsavel_idoso"
																as={Input}
																titleSize="xl"
															/>
														</Col>
													</Row>
												)}

												<Row gutter={[0, 10]} justify="end">
													<Col>
														<Button
															type="button"
															onClick={() =>
																handleIncluir(
																	formik.values.nome_responsavel_idoso,
																)
															}
														>
															Incluir
														</Button>
													</Col>
												</Row>
											</Section>
										</Form>
									);
								}}
							</Formik>
						</Section>
					</Col>
				</Row>
				<Row gutter={[0, 10]} justify="space-between" align="bottom">
					<Col>
						<ButtonVoltar />
					</Col>
					<Col>
						{urlServico !== 'resultado' && (
							<ButtonImage
								src="prosseguir"
								type="button"
								onClick={handleNext}
							/>
						)}
					</Col>
				</Row>
			</>
		</Padrao>
	);
};

export default IncluirBeneficiario;
