import { ReducerAction } from 'store/modules/types';

import { AlterarCadastroRequest, Types } from './types';

export function alterarCadastroRequest(
	data: AlterarCadastroRequest,
): ReducerAction {
	return {
		type: Types.ALTERAR_CADASTRO_REQUEST,
		payload: data,
	};
}
export function alterarCadastroSuccess(payload: object): ReducerAction {
	return {
		type: Types.ALTERAR_CADASTRO_SUCCESS,
		payload,
	};
}
export function alterarCadastroFailure(payload: object): ReducerAction {
	return {
		type: Types.ALTERAR_CADASTRO_FAILURE,
		payload,
	};
}

export function alterarCadastroClear(): any {
	return {
		type: Types.ALTERAR_CADASTRO_CLEAR,
		payload: null,
	};
}
