export enum Types {
	ATUALIZA_SOLICITACAO_PORTAL_REQUEST = '@MP/ATUALIZA_SOLICITACAO_PORTAL_REQUEST',
	ATUALIZA_SOLICITACAO_PORTAL_SUCCESS = '@MP/ATUALIZA_SOLICITACAO_PORTAL_SUCCESS',
	ATUALIZA_SOLICITACAO_PORTAL_FAILURE = '@MP/ATUALIZA_SOLICITACAO_PORTAL_FAILURE',
	LIMPAR_ATUALIZA_SOLICITACAO_PORTAL = '@MP/LIMPAR_ATUALIZA_SOLICITACAO_PORTAL',
}

export interface atualizaSolicitacaoPortal {
	status: number;
	data: {
		idSolicitacao: string;
		mensagem: string;
		token: string;
	};
}
