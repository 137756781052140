import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_MP_PATERNIDADE_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	enviarSolicitacoesPortalSuccess,
	enviarSolicitacoesPortalFailure,
} from './actions';

function* enviarSolicitacoesPortal(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: any } = request;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_MP_PATERNIDADE_API,
		`portal/solicitacoes`,
		payload,
	);

	if (response.status === 201) {
		yield put(
			enviarSolicitacoesPortalSuccess({
				response: response.data,
			}),
		);
	} else {
		yield put(enviarSolicitacoesPortalFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.ENVIAR_SOLICITACOES_PORTAL_REQUEST,
		enviarSolicitacoesPortal,
	),
]);
