export const MESSAGES_BIOMETRICS = {
	FORA_TEMPORALIDADE: 'Cidadão fora da temporalidade.',
};

export const MESSAGES_BIOMETRICS_PROCESS_CNH = {
	MSG01:
		'Encaminhar o cidadão para a estação biométrica para completar seu cadastro.',
	MSG02: 'Encaminhar para a mesa de coleta.',
	MSG03: 'Cidadão fora da temporalidade.',
	MSG04: 'Identificação biométrica realizada com sucesso.',
	MSG05: 'CNH será emitida utilizando as imagens existentes.',
	MSG06:
		'Atenção: Não é necessário reter as cópias dos documentos apresentados pelo cidadão, conforme autorização do DETRAN.',
	MSG07:
		'A continuidade deste processo só poderá ser realizada neste Posto de Atendimento.',
	MSG08: 'Coloque novamente o dedo indicado no leitor.',
	MSG09: 'Ocorreu um erro com a leitora de digitais.',
	MSG10: 'Digital não reconhecida, tente novamente.',
};

export const CONSULTA_CNH_ESTRANGEIRO_RESPONSE_SERVICE_MESSAGE = {
	CONDUTOR_PROCESSO_ABERTO: 'Condutor com Processo Aberto',
};

export const CNH_PROCESS_VALIDATION = {
	CIRETRAN_INVALIDA:
		'Dados da Ciretran não encontrados no pré cadastro. Preencha um cep válido para salvar a alteração.',
};
