import { all } from 'redux-saga/effects';

import atualizarSiglaSaga from './atualizarSigla/saga';
import cadastrarSiglaSaga from './cadastrarSigla/saga';
import consultarSiglasaga from './consultarSiglas/saga';
import excluirSiglaSaga from './excluirSigla/saga';

export default all([
	atualizarSiglaSaga,
	cadastrarSiglaSaga,
	consultarSiglasaga,
	excluirSiglaSaga,
]);
