import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	ASSUNTOS_REQUEST = '@procon/ASSUNTOS_REQUEST',
	ASSUNTOS_SUCCESS = '@procon/ASSUNTOS_SUCCESS',
	ASSUNTOS_FAILURE = '@procon/ASSUNTOS_FAILURE',
	ASSUNTOS_CLEAR = '@procon/ASSUNTOS_CLEAR',
}

export interface Assuntos {
	status: null | number;
	data: OptionProps[];
}

export interface AssuntosRequest {
	areaId: string;
}
