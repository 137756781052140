// TYPES
import { ReducerAction } from 'store/modules/types';
import { IRequestPesquisaCita, DataPesquisaCITA, Types } from './types';

export function pesquisaCITAAACRequest(
	payload: IRequestPesquisaCita,
): ReducerAction {
	return {
		type: Types.PESQUISA_CITA_AAC_REQUEST,
		payload,
	};
}

export function pesquisaCITAAACSuccess(
	payload: DataPesquisaCITA,
): ReducerAction {
	return {
		type: Types.PESQUISA_CITA_AAC_SUCCESS,
		payload,
	};
}

export function pesquisaCITAAACFailure(): ReducerAction {
	return {
		type: Types.PESQUISA_CITA_AAC_FAILURE,
		payload: null,
	};
}

export function limpaPesquisaCITAAAC(): ReducerAction {
	return {
		type: Types.LIMPA_PESQUISA_CITA_AAC,
		payload: null,
	};
}
