import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Col, Row } from 'antd';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import { ContentButton } from 'pages/iirgd/Aac/Numeral/Consultar/styled';

import getValidationsErrors from 'utils/getValidationsErrors';

// ROUTES
import { useHistory } from 'react-router-dom';
import {
	ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_INSCRICAO_ALUNO,
	ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_PESQUISA_ALUNO,
} from 'pages/EducacaoUpdate/routes/paths';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import {
	cadastrarFichaAlunoRequest,
	latLngSelecionada,
} from 'store/modules/api/educacaoUpdate/fichaAluno/cadastrarFichaAluno/actions';
import { alterarFichaAlunoRequest } from 'store/modules/api/educacaoUpdate/fichaAluno/alterarFichaAluno/actions';
import { consultaAlunoClear } from 'store/modules/api/educacaoUpdate/fichaAluno/consultaAluno/actions';
import { consultaFichaAlunoClean } from 'store/modules/api/educacaoUpdate/fichaAluno/consultaFichaAluno/actions';

// FORM
import { formatDateISOWithOutUTC } from 'utils/genericFunctions';
import DadosPessoais from './DadosPessoais';
import Documentos from './Documentos';
import Deficiencia from './Deficiencia';
import EnderecoResidencial from './EnderecoResidencial';
import Telefones from './Telefones';
import {
	formatValuesCreate,
	formatValuesUpdate,
	IFichaAluno,
	initialValues,
	schema,
	treatInitialValues,
} from './form';

interface ITelefone {
	key: string;
	descricaoTipo: string;
	complemento: string;
	inSMS: string;
	inDDDNumero: string;
	inNumero: string;
	inTipoTelefone: string;
}

interface ItelefoneFormat {
	telefones: ITelefone[];
}

const FichaAluno = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [formikInitialValues, setFormikInitialValues] = useState<IFichaAluno>({
		...initialValues,
	});
	const [isLoading, setIsLoading] = useState(true);

	const {
		consultaFichaAluno,
		consultaAluno,
		cadastrarFichaAluno,
		alterarFichaAluno,
	} = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.fichaAluno,
	);

	useEffect(() => {
		if (consultaFichaAluno.data) {
			const treatedInitialValues = treatInitialValues(consultaFichaAluno.data);

			if (
				treatedInitialValues.certidaoAntiga.numeroCertidao &&
				treatedInitialValues.certidaoAntiga.numeroCertidao !== ''
			) {
				treatedInitialValues.documentos.tipoCertidao = 'ANTIGA';
			} else if (treatedInitialValues.certidaoNova) {
				if (
					treatedInitialValues.certidaoNova.certificadoMatricula01 &&
					treatedInitialValues.certidaoNova.certificadoMatricula01 !== ''
				) {
					treatedInitialValues.documentos.tipoCertidao = 'NOVA';
				}
			} else {
				treatedInitialValues.documentos.tipoCertidao = '';
			}

			let formInitialValues: any = {
				...initialValues,
				aluno: treatedInitialValues.aluno,
				dadosPessoais: treatedInitialValues.dadosPessoais,
				documentos: treatedInitialValues.documentos,
				deficiencia: treatedInitialValues.deficiencia,
				enderecoResidencial: treatedInitialValues.enderecoResidencial,
				telefones: treatedInitialValues.telefones,
				recursoAvaliacao: treatedInitialValues.recursoAvaliacao,
			};

			if (treatedInitialValues.documentos.tipoCertidao !== 'ANTIGA') {
				formInitialValues = {
					...formInitialValues,
					certidaoNova: treatedInitialValues.certidaoNova,
				};
			} else {
				formInitialValues = {
					...formInitialValues,
					certidaoAntiga: treatedInitialValues.certidaoAntiga,
				};
			}
			setFormikInitialValues(formInitialValues);
			setIsLoading(false);
		} else {
			if (consultaAluno.request) {
				const { dataNascimento, nomeAluno, nomeMae, nomePai } =
					consultaAluno.request;

				const dadosPessoais = {
					...initialValues.dadosPessoais,
					nomeAluno: nomeAluno || '',
					nomeMae: nomeMae || '',
					nomePai: nomePai || '',
					dataNascimento:
						(dataNascimento && formatDateISOWithOutUTC(dataNascimento)) || '',
				};
				setFormikInitialValues({ ...initialValues, dadosPessoais });
			}
			setIsLoading(false);
		}
	}, [consultaAluno.request, consultaFichaAluno.data]);

	const handleSubmit = useCallback(
		(values: IFichaAluno) => {
			dispatch(
				latLngSelecionada({
					lat: Number(values.enderecoResidencial.latitude),
					lng: Number(values.enderecoResidencial.longitude),
				}),
			);
			if (values.aluno.numeroRA !== '') {
				dispatch(alterarFichaAlunoRequest(token, formatValuesUpdate(values)));
			} else {
				dispatch(cadastrarFichaAlunoRequest(token, formatValuesCreate(values)));
			}
		},
		[dispatch, token],
	);

	useEffect(() => {
		if (
			cadastrarFichaAluno.status === 200 ||
			alterarFichaAluno.status === 200 ||
			cadastrarFichaAluno.status === 201 ||
			alterarFichaAluno.status === 201
		) {
			history.push(ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_INSCRICAO_ALUNO);
		}
	}, [cadastrarFichaAluno, alterarFichaAluno, history]);

	useEffect(() => {
		if (consultaFichaAluno.data?.sucesso === 'OK') {
			if (consultaFichaAluno.data) {
				// setRenderDataDadosPessoais(consultaFichaAluno.data);
				// setLoaderInitialValues(true);
			}
		}
	}, [consultaFichaAluno.data]);

	return (
		<>
			{!isLoading && (
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={formikInitialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{formik => (
						<>
							<Form autoComplete="off">
								<Section title="Ficha Aluno">
									<Col span={24}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												{!isLoading && <DadosPessoais formik={formik} />}
											</Col>
										</Row>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Documentos formik={formik} />
											</Col>
										</Row>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Deficiencia formik={formik} />
											</Col>
										</Row>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<EnderecoResidencial formik={formik} />
											</Col>
										</Row>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Telefones
													formik={formik}
													initialValues={formikInitialValues}
												/>
											</Col>
										</Row>
									</Col>
									<ContentButton justify="center">
										<ButtonImage type="submit" src="avancar" />
									</ContentButton>
								</Section>
								<ButtonVoltar
									navigate={false}
									onClick={() => {
										dispatch(consultaAlunoClear());
										dispatch(consultaFichaAlunoClean());
										history.push(
											ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_PESQUISA_ALUNO,
										);
									}}
								/>
							</Form>
						</>
					)}
				</Formik>
			)}
		</>
	);
};

export default FichaAluno;
