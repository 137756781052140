import React, { Suspense, useEffect } from 'react';
import { Col, Modal, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	cancelarAutorizacaoPreviaClear,
	cancelarAutorizacaoPreviaRequest,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/CancelarAutorizacaoPrevia/actions';
import { initialValues, schema } from './form';

const CancelarAutorizacaoPrevia: React.FC = () => {
	const dispatch = useDispatch();

	const { cancelarAutorizacaoPrevia } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	useEffect(() => {
		if (cancelarAutorizacaoPrevia.status === 200) {
			Modal.success({
				content: 'Autorização prévia cancelada com sucesso!',
			});
			dispatch(cancelarAutorizacaoPreviaClear());
		}
	}, [cancelarAutorizacaoPrevia, dispatch]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						Modal.confirm({
							title: 'Deseja realmente cancelar a autorização prévia?',
							okText: 'Confirmar',
							cancelText: 'Cancelar',
							onOk() {
								dispatch(
									cancelarAutorizacaoPreviaRequest({
										chassi: values.chassi,
										numeroAutorizacao: values.numeroAutorizacao,
									}),
								);
							},
							onCancel() {},
						});
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={14}>
										<Field
											required
											titleSize="lg"
											name="chassi"
											title="Chassi"
											as={Input}
											maxLength={22}
											error={hasError(formik.errors, 'chassi')}
										/>
									</Col>
									<Col span={10} />
									<Col span={14}>
										<Field
											required
											titleSize="lg"
											name="numeroAutorizacao"
											title="Número da Autorização"
											as={Input}
											maxLength={11}
											error={hasError(formik.errors, 'numeroAutorizacao')}
										/>
									</Col>
									<Col span={8} />
									<Col span={14}>
										<Field
											titleSize="lg"
											readOnly
											title="Data de Cancelamento"
											as={Input}
											value={new Date().toLocaleDateString('pt-BR')}
										/>
									</Col>
									<Col span={8} />
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={formik.handleReset}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
		</Suspense>
	);
};

export default CancelarAutorizacaoPrevia;
