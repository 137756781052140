import { ReducerAction } from 'store/modules/types';

import { AlterarRenachSemRegistroRequest, Types } from './types';

export function alterarRenachSemRegistroRequest(
	payload: AlterarRenachSemRegistroRequest,
): ReducerAction {
	return {
		type: Types.ALTERAR_RENACH_SEM_REGISTRO_REQUEST,
		payload,
	};
}
export function alterarRenachSemRegistroSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.ALTERAR_RENACH_SEM_REGISTRO_SUCCESS,
		payload,
	};
}
export function alterarRenachSemRegistroFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.ALTERAR_RENACH_SEM_REGISTRO_FAILURE,
		payload,
	};
}
export function alterarRenachSemRegistroClear(): ReducerAction {
	return {
		type: Types.ALTERAR_RENACH_SEM_REGISTRO_CLEAR,
		payload: null,
	};
}
