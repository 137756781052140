import * as Yup from 'yup';

import { SinalizaResolucaoConsultaAtendimentoForm } from 'store/modules/api/procon/atendimentos/sinalizaResolucaoConsultaAtendimento/types';
import { CancelaConsultaAtendimentoForm } from 'store/modules/api/procon/atendimentos/cancelaConsultaAtendimento/types';

export const initialValuesResolvido: SinalizaResolucaoConsultaAtendimentoForm =
	{
		atendimentoId: '',
		justificativa: '',
	};

export const initialValuesCancelar: CancelaConsultaAtendimentoForm = {
	atendimentoId: '',
	justificativa: '',
};

export const schemaResolvido =
	Yup.object<SinalizaResolucaoConsultaAtendimentoForm>().shape({
		justificativa: Yup.string().min(
			50,
			'Campo Obrigatório: Mínimo de 50 caracteres para a Justificativa.',
		),
	});

export const schemaCancelar =
	Yup.object<CancelaConsultaAtendimentoForm>().shape({
		justificativa: Yup.string().min(
			50,
			'Campo Obrigatório: Mínimo de 50 caracteres para a Justificativa.',
		),
	});
