export enum Types {
	CONSULTA_DIVISAO_EQUITATIVA = '@cnh/CONSULTA_DIVISAO_EQUITATIVA',
	CONSULTA_DIVISAO_EQUITATIVA_SUCCESS = '@cnh/CONSULTA_DIVISAO_EQUITATIVA_SUCCESS',
	CONSULTA_DIVISAO_EQUITATIVA_FAILURE = '@cnh/CONSULTA_DIVISAO_EQUITATIVA_FAILURE',
}

export interface consultaDivisaoEquitativa {
	status: number;
	data: IDadosConsultaDivisaoEquitativa | null;
}

interface IDadosConsultaDivisaoEquitativa {}

export interface IRequestConsultaDivisaoEquitativa {
	codigo_ciretran: number;
	is_portador_necessidades_especiais: boolean;
	cep_cidadao: number;
	cpf_cidadao: number;
	usuario: string;
	id_micro_regiao: number;
}
