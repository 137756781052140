import React, { useCallback, useEffect, useState } from 'react';

// ANTD
import { Col, Row } from 'antd';

// COMPONENTS COMMON
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_DETRAN_CNH_RENOVACAO_RENACH } from 'pages/DetranCnh/routes/paths';
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import AgendamentoPsicologicoRenachIntegrado from 'pages/DetranCnh/components/pages/AgendamentoPsicologicoRenachIntegrado';

// FORM
import { initialValues, dadosCidadao } from './form';

const AgendamentoPsicologicoRenovacao: React.FC = () => {
	const history = useHistory();

	// const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(true);

	const { saveGerarRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const consultaCategoria = useSelector(
		(state: ApplicationState) => state.api.detranCnh.consulta.consultaCategoria,
	);

	const handleButtonBack = useCallback(() => {
		// dispatch(cadastroEnderecosClear());
		// dispatch(capturarBiometriaClear());
		// dispatch(consultarDigitaisClear());
		// dispatch(confrontarDigitaisCnhClear());
		// dispatch(estatisticaIncluirClear());
		// dispatch(gerarRenachIntegradoClear());
		// dispatch(consultarInaptoClear());
		// dispatch(dadosCidadaoClear());

		history.goBack();
	}, [history]);

	useEffect(() => {
		if (saveGerarRenach.data?.gerarRenachBase) {
			const {
				cpf,
				logradouroCep,
				categoriaPretendida,
				codCiretranHabilitacao,
			} = saveGerarRenach.data.gerarRenachBase;

			initialValues.categoria_pretendida =
				consultaCategoria.divisaoEquitativa.filter(
					divisaoEquitativa => divisaoEquitativa.codigo === categoriaPretendida,
				)[0].codigoDivisaoEquitativa;
			initialValues.cpf_cidadao = Number(cpf);
			initialValues.cep_cidadao = Number(logradouroCep);
			initialValues.codigo_ciretran_exame_psicologo = Number(
				codCiretranHabilitacao,
			);
		}
	}, [consultaCategoria.divisaoEquitativa, saveGerarRenach.data]);

	useEffect(() => {
		if (saveGerarRenach.data?.gerarRenachBase) {
			const {
				cpf,
				nome,
				nomeSocial,
				nomePai,
				nomeMae,
				numDocumento,
				dataNascimento,
				sexo,
				nacionalidade,
				codigoNaturalidade,
				categoriaPretendida,
				logradouroCep,
				flagDeficienteFisico,
			} = saveGerarRenach.data.gerarRenachBase;

			// DADOS CIDADÃO
			dadosCidadao.nome = nomeSocial || nome;
			dadosCidadao.nomeMae = nomeMae;
			dadosCidadao.nomePai = nomePai;
			dadosCidadao.numeroRg = numDocumento;
			dadosCidadao.cpf = cpf;
			dadosCidadao.sexo = sexo;
			dadosCidadao.nacionalidade = nacionalidade;
			dadosCidadao.cep = logradouroCep;
			dadosCidadao.dataNascimento = dataNascimento;
			dadosCidadao.naturalidade = codigoNaturalidade || '';
			dadosCidadao.categoriaPretendida =
				consultaCategoria.divisaoEquitativa.filter(
					divisaoEquitativa => divisaoEquitativa.codigo === categoriaPretendida,
				)[0].codigoDivisaoEquitativa;
			dadosCidadao.isPortadorNecessidadesEspeciais =
				!!flagDeficienteFisico || flagDeficienteFisico === 'S';

			setIsLoading(false);
		}
	}, [consultaCategoria.divisaoEquitativa, saveGerarRenach.data]);

	return (
		<>
			{!isLoading && (
				<>
					<AgendamentoPsicologicoRenachIntegrado
						dadosCidadao={dadosCidadao}
						dadosAgendamento={initialValues}
						redirecionamento={ROUTE_DETRAN_CNH_RENOVACAO_RENACH}
					/>
					<Row justify="space-between" align="middle">
						<Col>
							<ButtonVoltar
								navigate={false}
								onClick={() => {
									handleButtonBack();
								}}
							/>
						</Col>

						<Col>
							<ButtonImage
								type="button"
								src="outro-servico-detran"
								onClick={() => history.push(ROUTE_DETRAN_CNH)}
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};
export default AgendamentoPsicologicoRenovacao;
