import { ReducerAction } from 'store/modules/types';

import { AnamneseRequest, Types } from './types';

export function anamneseRequest(payload: AnamneseRequest): ReducerAction {
	return {
		type: Types.ANAMNESE_REQUEST,
		payload,
	};
}
export function anamneseSuccess(payload: object): ReducerAction {
	return {
		type: Types.ANAMNESE_SUCCESS,
		payload,
	};
}
export function anamneseFailure(payload: boolean): ReducerAction {
	return {
		type: Types.ANAMNESE_FAILURE,
		payload,
	};
}
export function anamneseClear(): ReducerAction {
	return {
		type: Types.ANAMNESE_CLEAR,
		payload: null,
	};
}
