import React, { useCallback, useEffect, useState } from 'react';

import { Alert as AlertAD } from 'antd';
import { AlertProps } from 'antd/lib/alert';

// STYLED
import { Container } from './styled';

interface Props extends AlertProps {
	onClose?: () => void;
	displayOnClose?: boolean;
}

const Alert: React.FC<Props> = ({
	type,
	message,
	onClose,
	displayOnClose = true,
}) => {
	const [title, setTitle] = useState<string>('');

	useEffect(() => {
		switch (type) {
			case 'success':
				setTitle('Sucesso');
				break;

			case 'info':
				setTitle('Informação');
				break;

			case 'warning':
				setTitle('Atenção');
				break;

			case 'error':
				setTitle('Erro');
				break;

			default:
				setTitle('');
		}
	}, [type]);

	const handleClose = useCallback(() => {
		if (onClose) onClose();
	}, [onClose]);

	return (
		<Container>
			<AlertAD
				type={type}
				message={title}
				description={message}
				onClose={handleClose}
				showIcon
				closable={displayOnClose}
			/>
		</Container>
	);
};

export default Alert;
