export enum Types {
	CANCELAR_SOLICITACAO_EMISSAO_PID_REQUEST = '@cnh/CANCELAR_SOLICITACAO_EMISSAO_PID_REQUEST',
	CANCELAR_SOLICITACAO_EMISSAO_PID_SUCCESS = '@cnh/CANCELAR_SOLICITACAO_EMISSAO_PID_SUCCESS',
	CANCELAR_SOLICITACAO_EMISSAO_PID_FAILURE = '@cnh/CANCELAR_SOLICITACAO_EMISSAO_PID_FAILURE',
	CANCELAR_SOLICITACAO_EMISSAO_PID_CLEAR = '@cnh/CANCELAR_SOLICITACAO_EMISSAO_PID_CLEAR',
}

export interface CancelarSolicitacaoEmissaoPID {
	status: number;
	data: CancelarSolicitacaoEmissaoPIDData | null;
}

export interface CancelarSolicitacaoEmissaoPIDData {
	cpf: string;
	registro: string;
	tipoServico: string;
	resposta: string;
	codigoInterno: string;
	message: string;
}

export interface CancelarSolicitacaoEmissaoPIDRequest {
	evento: {
		id_atendimento: string;
		id_cidadao: string;
		cpf: string;
		identificacao: string;
		ip: string;
		canal: {
			id: number;
			desc: string;
			estacao: {
				id: number;
				desc: string;
			};
			localidade: {
				id: number;
				desc: string;
			};
		};
	};
	cpfCondutor: string;
	numRegistro: string;
	tipoServico: string;
}
