import React, { useState, useCallback, useEffect } from 'react';

import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';
import {
	ROUTE_IIRGD_CONFERENCIA_DADOS,
	ROUTE_IIRGD_STATUS,
} from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	capturarBiometriaClearLeitura,
	capturarBiometriaRequest,
} from 'store/modules/api/biometria/capturarBiometria/actions';
import { cadastroRgConfrontoDigital } from 'store/modules/api/iirgd/cadastroRg/actions';
import { confrontarDigitaisRequest } from 'store/modules/api/utils/confrontarDigitais/actions';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';
import { lockUrl } from 'store/modules/api/sgu/loginUnico/actions';
import { verificarVersaoServicoBiometriaRequest } from 'store/modules/api/biometria/verificarVersaoServicoBiometria/actions';

// IMAGES
import imagemMaoDireita from 'assets/images/coleta-digitais/imagemMaoDireita.png';
import imagemPD from 'assets/images/coleta-digitais/imagemPD.png';
import imagemID from 'assets/images/coleta-digitais/imagemID.png';
import imagemMD from 'assets/images/coleta-digitais/imagemMD.png';
import imagemMaoEsquerda from 'assets/images/coleta-digitais/imagemMaoEsquerda.png';
import imagemPE from 'assets/images/coleta-digitais/imagemPE.png';
import imagemIE from 'assets/images/coleta-digitais/imagemIE.png';
import imagemME from 'assets/images/coleta-digitais/imagemME.png';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Alert from 'components/Common/Notifications/Alert';

const LeituraDigitais: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [dedoSorteado, setDedoSorteado] = useState<number>(0);
	const [dedosJaSorteados, setDedosJaSorteados] = useState<number[]>([0]);

	const { capturarBiometria, verificarVersaoServicoBiometria } = useSelector(
		(state: ApplicationState) => state.api.biometria,
	);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { consultarDigitais, confrontarDigitais } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const [falhas, setFalhas] = useState<number>(0);

	useEffect(() => {
		if (!cadastroRg.form.principal) {
			history.push(ROUTE_IIRGD);
			return;
		}

		if (!consultarDigitais.data) {
			history.push(ROUTE_IIRGD_CONFERENCIA_DADOS);
		}
	}, [cadastroRg.form.principal, consultarDigitais.data, history]);

	const finalizarProcessoEncaminhandoParaMesaDeColeta = useCallback(() => {
		dispatch(cadastroRgConfrontoDigital(false));
		const payload: IncluirRequest = {
			descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
			tipoDocumento: 'RG',
			numeroDocumento: cadastroRg.form?.principal?.numeroRg ?? '',
			codigoLocalidade: Number(login.user.posto),
			idOrgao: login.user.idOrgao,
			cpf: login.user.cpf,
			descricaoServico: 'FALHA_BIOMETRIA',
			idMotivo: 0,
			flagConfronto: confrontarDigitais.resultado ? 'S' : 'N',
			numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
			descricaoDedosEnviados: confrontarDigitais.posicaoDedo ?? '',
			observacao: `Tentativa de captura: ${
				capturarBiometria.qtdeFalha ? capturarBiometria.qtdeFalha : 0
			} - Tentativa de confronto: ${
				confrontarDigitais.qtdeFalha ? confrontarDigitais.qtdeFalha : 0
			}`,
		};
		dispatch(estatisticaIncluirRequest(payload));
		dispatch(lockUrl(true));
		history.push(ROUTE_IIRGD_STATUS);
	}, [
		cadastroRg,
		capturarBiometria.qtdeFalha,
		confrontarDigitais.posicaoDedo,
		confrontarDigitais.qtdeFalha,
		confrontarDigitais.resultado,
		dispatch,
		history,
		login.user.cpf,
		login.user.idOrgao,
		login.user.posto,
		atendimento,
	]);

	const sortearDedo = useCallback(() => {
		if (consultarDigitais.data) {
			const posicoesPermitidas = [1, 2, 3, 6, 7, 8];

			let novasPosicoesPermitidas = posicoesPermitidas;
			if (dedoSorteado !== 0) {
				novasPosicoesPermitidas = posicoesPermitidas.filter((item: number) => {
					return !dedosJaSorteados.includes(item);
				});
			}

			const filteredDadosBiometricos =
				consultarDigitais.data.dadosBiometricos.filter(dado =>
					novasPosicoesPermitidas.includes(Number(dado.posicaoDedo)),
				);

			if (filteredDadosBiometricos.length > 0) {
				const sorteio = Math.round(
					Math.random() * (filteredDadosBiometricos.length - 1 - 0) + 0,
				);

				const dedoSorteio = filteredDadosBiometricos[sorteio].posicaoDedo;

				setDedosJaSorteados(prev => [...prev, Number(dedoSorteio)]);
				setDedoSorteado(Number(dedoSorteio));
			} else {
				finalizarProcessoEncaminhandoParaMesaDeColeta();
			}
		}
	}, [
		consultarDigitais.data,
		dedoSorteado,
		dedosJaSorteados,
		finalizarProcessoEncaminhandoParaMesaDeColeta,
	]);

	useEffect(() => {
		if (
			capturarBiometria.qtdeFalha + confrontarDigitais.qtdeFalha !== 0 &&
			capturarBiometria.qtdeFalha + confrontarDigitais.qtdeFalha !== falhas &&
			dedoSorteado !== 0
		) {
			setFalhas(capturarBiometria.qtdeFalha + confrontarDigitais.qtdeFalha);
			sortearDedo();
		}
	}, [
		confrontarDigitais,
		capturarBiometria.qtdeFalha,
		falhas,
		dedoSorteado,
		sortearDedo,
	]);

	// Realiza o primeiro sorteio dos dedos.
	useEffect(() => {
		if (
			dedoSorteado === 0 &&
			consultarDigitais.data &&
			consultarDigitais.data.dadosBiometricos.length !== 0
		) {
			sortearDedo();
		}
	}, [dedoSorteado, consultarDigitais.data, sortearDedo]);

	// Realiza o redirecionamento para Status do Cadastro.
	useEffect(() => {
		// Caso o confronto de digitais falhe 3 vezes.
		if (confrontarDigitais.qtdeFalha > 2 || capturarBiometria.qtdeFalha > 2) {
			finalizarProcessoEncaminhandoParaMesaDeColeta();
		}

		// Em caso de sucesso no confronto das digitais.
		if (confrontarDigitais.resultado) {
			dispatch(cadastroRgConfrontoDigital(true));
			dispatch(lockUrl(true));
			history.push(ROUTE_IIRGD_STATUS);
		}
	}, [
		cadastroRg,
		capturarBiometria.qtdeFalha,
		confrontarDigitais,
		dispatch,
		finalizarProcessoEncaminhandoParaMesaDeColeta,
		history,
		login.user.cpf,
		login.user.idOrgao,
		login.user.posto,
	]);

	// Aguarda a captura da biometria e realiza o confronto.
	useEffect(() => {
		if (
			cadastroRg.form.principal?.numeroRg &&
			capturarBiometria.status === 200 &&
			capturarBiometria?.data?.length > 0
		) {
			const { numeroRg } = cadastroRg.form.principal;

			dispatch(
				confrontarDigitaisRequest(
					numeroRg,
					dedoSorteado.toString(),
					capturarBiometria.data ?? '',
				),
			);
			dispatch(capturarBiometriaClearLeitura());
		}
	}, [cadastroRg.form.principal, dedoSorteado, capturarBiometria, dispatch]);

	const handleCapturaBiometrica = useCallback(() => {
		dispatch(verificarVersaoServicoBiometriaRequest());
	}, [dispatch]);

	useEffect(() => {
		if (verificarVersaoServicoBiometria.data?.versao === 'OLD') {
			dispatch(
				capturarBiometriaRequest({
					orgao: 'IIRGD',
					serviceVersion: 'OLD',
				}),
			);
		} else if (verificarVersaoServicoBiometria.data?.versao === 'NEW') {
			dispatch(
				capturarBiometriaRequest({
					orgao: 'IIRGD',
					serviceVersion: 'NEW',
				}),
			);
		}
	}, [dispatch, verificarVersaoServicoBiometria.data]);

	return (
		<>
			{dedoSorteado && (
				<>
					<Section title="Leitura de Digitais">
						{(verificarVersaoServicoBiometria.status === 100 ||
							capturarBiometria.status === 100) && (
							<Row>
								<Col span={24}>
									<Alert
										type="info"
										message={
											(verificarVersaoServicoBiometria.status === 100 &&
												'Em preparação, aguarde a liberação para inserir a digital a ser coletada.') ||
											'Leitor biométrico liberado para a coleta, insira a digital a ser coletada.'
										}
									/>
								</Col>
							</Row>
						)}
						<Row gutter={[0, 20]}>
							<Col span={24}>
								<p>
									<strong>
										Clique no botão abaixo e coloque o dedo indicado na leitora
										biométrica para conferência da biometria.
									</strong>
								</p>
							</Col>
						</Row>

						<Row gutter={[0, 30]} justify="center">
							<Col>
								{(dedoSorteado === 6 ||
									dedoSorteado === 7 ||
									dedoSorteado === 8) && (
									<img src={imagemMaoDireita} alt="Mão Direita" />
								)}

								{dedoSorteado === 1 && (
									<img src={imagemPD} alt="Mão Direita - Polegar" />
								)}

								{dedoSorteado === 2 && (
									<img src={imagemID} alt="Mão Direita - Indicador" />
								)}

								{dedoSorteado === 3 && (
									<img src={imagemMD} alt="Mão Direita - Médio" />
								)}
							</Col>

							<Col>
								{(dedoSorteado === 1 ||
									dedoSorteado === 2 ||
									dedoSorteado === 3) && (
									<img src={imagemMaoEsquerda} alt="Mão Esquerda" />
								)}

								{dedoSorteado === 6 && (
									<img src={imagemPE} alt="Mão Esquerda - Polegar" />
								)}

								{dedoSorteado === 7 && (
									<img src={imagemIE} alt="Mão Esquerda - Indicador" />
								)}

								{dedoSorteado === 8 && (
									<img src={imagemME} alt="Mão Esquerda - Médio" />
								)}
							</Col>
						</Row>

						<Row gutter={[0, 10]} justify="center">
							<Col>
								<ButtonImage
									type="button"
									src="captura-biometrica"
									onClick={handleCapturaBiometrica}
									disabled={
										verificarVersaoServicoBiometria.status === 100 ||
										capturarBiometria.status === 100
									}
								/>
							</Col>
						</Row>
					</Section>

					<ButtonVoltar />
				</>
			)}
		</>
	);
};

export default LeituraDigitais;
