export enum Types {
	ANAMNESE_REQUEST = '@cnh/ANAMNESE_REQUEST',
	ANAMNESE_SUCCESS = '@cnh/ANAMNESE_SUCCESS',
	ANAMNESE_FAILURE = '@cnh/ANAMNESE_FAILURE',
	ANAMNESE_CLEAR = '@cnh/ANAMNESE_CLEAR',
}

export interface Anamnese {
	status: number;
	data: null | string;
}

export interface AnamneseRequest {
	cpf: string;
	nome: string;
	renach: string;
	codLocalidade: number;
	nomeLocalidade: string;
	tipoProcesso: string;
}
