import { all } from 'redux-saga/effects';

import atualizarParametroLocalidadeSaga from './atualizarParametroLocalidade/saga';
import cadastrarParametroLocalidadeSaga from './cadastrarParametroLocalidade/saga';
import consultarParametrosLocalidadeSaga from './consultarParametrosLocalidade/saga';
import excluirParametroLocalidadeSaga from './excluirParametroLocalidade/saga';

export default all([
	atualizarParametroLocalidadeSaga,
	cadastrarParametroLocalidadeSaga,
	consultarParametrosLocalidadeSaga,
	excluirParametroLocalidadeSaga,
]);
