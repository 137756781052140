import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarTiposResidencias } from './types';

export const INITIAL_STATE: ConsultarTiposResidencias = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarTiposResidencias {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_TIPOS_RESIDENCIAS_REQUEST: {
				break;
			}

			case Types.CONSULTAR_TIPOS_RESIDENCIAS_SUCCESS: {
				draft.status = action.payload.status;

				const arrayTiposResidencias = action.payload.data.valores.map(
					(key: string) => {
						return { value: key, label: key };
					},
					{},
				);
				draft.data = arrayTiposResidencias;
				break;
			}

			case Types.CONSULTAR_TIPOS_RESIDENCIAS_FAILURE: {
				draft.status = action.payload.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.LIMPAR_CONSULTAR_TIPOS_RESIDENCIAS: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
