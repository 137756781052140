// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	Types,
	IRequestEmitirSegundaViaPlaca,
	IResponseEmitirSegundaViaPlaca,
} from './types';

export function emitirSegundaViaPlacaRequest(
	payload: IRequestEmitirSegundaViaPlaca,
): ReducerAction {
	return {
		type: Types.EMITIR_SEGUNDA_VIA_PLACA_REQUEST,
		payload,
	};
}

export function emitirSegundaViaPlacaSuccess(
	payload: IResponseEmitirSegundaViaPlaca,
): ReducerAction {
	return {
		type: Types.EMITIR_SEGUNDA_VIA_PLACA_SUCCESS,
		payload,
	};
}

export function emitirSegundaViaPlacaFailure(): ReducerAction {
	return {
		type: Types.EMITIR_SEGUNDA_VIA_PLACA_FAILURE,
		payload: null,
	};
}

export function emitirSegundaViaPlacaClear(): ReducerAction {
	return {
		type: Types.EMITIR_SEGUNDA_VIA_PLACA_CLEAR,
		payload: null,
	};
}
