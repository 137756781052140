import React, { useCallback, useEffect } from 'react';

import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import Section from 'components/Common/Section';

// UTILS
import { formatDate, patternFormat } from 'utils/genericFunctions';
import { isFilledValue } from 'utils/stringFunctions';
import { ROUTE_IIRGD_CONSULTAR_CPF } from '../routes/paths';

const ResultadoCpf: React.FC<{ value: string }> = ({ value }) => {
	const history = useHistory();

	const { consultarCin } = useSelector(
		(state: ApplicationState) => state.api.iirgd.integrador,
	);

	useEffect(() => {
		if (!consultarCin.data) {
			history.push(ROUTE_IIRGD_CONSULTAR_CPF);
		}
	}, [consultarCin.data, history]);

	const handleSituacaoCadastral = useCallback((codSituacao: string): string => {
		if (!codSituacao) {
			return '';
		}

		const situacoesCadastrais: any = {
			'0': 'REGULAR',
			'2': 'SUSPENSA',
			'3': 'TITULAR FALECIDO',
			'4': 'PENDENTE DE REGULARIZAÇÃO',
			'5': 'CANCELADA POR MULTIPLICIDADE',
			'8': 'NULA',
			'9': 'CANCELADA DE OFÍCIO',
		};

		return situacoesCadastrais[codSituacao];
	}, []);

	return (
		<>
			{consultarCin.data && (
				<Row>
					<Col span={24}>
						<Section title="Resultado da Consulta">
							<Row gutter={[0, 10]}>
								<Col span={24}>
									<Section key={value} style={{ marginTop: '5px' }}>
										<Row>
											<Col span={8}>
												<p>
													<strong>CPF: </strong>
													{isFilledValue(
														patternFormat(value, `###.###.###-##`),
													)}
												</p>
											</Col>

											<Col span={7}>
												<p>
													<strong>Naturalidade: </strong>
													{isFilledValue(consultarCin.data?.codMunNat)}
												</p>
											</Col>
										</Row>

										<Row>
											<Col span={8}>
												<p>
													<strong>Nome: </strong>
													{isFilledValue(consultarCin.data?.nomeContribuinte)}
												</p>
											</Col>

											<Col span={7}>
												<p>
													<strong>Data da Inscrição: </strong>
													{consultarCin.data &&
														consultarCin.data.dtInscricao &&
														isFilledValue(
															formatDate(
																`${consultarCin.data?.dtInscricao.substring(
																	0,
																	4,
																)}-${consultarCin.data?.dtInscricao.substring(
																	4,
																	6,
																)}-${consultarCin.data?.dtInscricao.substring(
																	6,
																	8,
																)}`,
															),
														)}
												</p>
											</Col>

											<Col span={9}>
												<p>
													<strong>Situação Cadastral: </strong>
													{isFilledValue(
														handleSituacaoCadastral(
															consultarCin.data?.codSitCad,
														),
													)}
												</p>
											</Col>
										</Row>

										<Row>
											<Col span={8}>
												<p>
													<strong>Nome da Mãe: </strong>{' '}
													{consultarCin.data?.nomeMae}
												</p>
											</Col>

											<Col span={7}>
												<p>
													<strong>Data da Última Atualização: </strong>
													{consultarCin.data &&
														consultarCin.data.dtUltAtualiz &&
														isFilledValue(
															formatDate(
																formatDate(
																	`${consultarCin.data?.dtUltAtualiz.substring(
																		0,
																		4,
																	)}-${consultarCin.data?.dtUltAtualiz.substring(
																		4,
																		6,
																	)}-${consultarCin.data?.dtUltAtualiz.substring(
																		6,
																		8,
																	)}`,
																),
															),
														)}
												</p>
											</Col>
										</Row>

										<Row>
											<Col span={8}>
												<p>
													<strong>Data de Nascimento: </strong>
													{consultarCin.data &&
														consultarCin.data.dtNasc &&
														isFilledValue(
															formatDate(
																formatDate(
																	`${consultarCin.data?.dtNasc.substring(
																		0,
																		4,
																	)}-${consultarCin.data?.dtNasc.substring(
																		4,
																		6,
																	)}-${consultarCin.data?.dtNasc.substring(
																		6,
																		8,
																	)}`,
																),
															),
														)}
												</p>
											</Col>
											<Col span={7}>
												<p>
													<strong>Sexo: </strong>
													{isFilledValue(consultarCin.data?.codSexo)}
												</p>
											</Col>
										</Row>
									</Section>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			)}
		</>
	);
};

export default ResultadoCpf;
