export enum Types {
	EMITIR_ALTERACAO_DADOS_CADASTRAIS_REQUEST = '@DETRAN-CRV/EMITIR_ALTERACAO_DADOS_CADASTRAIS_REQUEST',
	EMITIR_ALTERACAO_DADOS_CADASTRAIS_SUCCESS = '@DETRAN-CRV/EMITIR_ALTERACAO_DADOS_CADASTRAIS_SUCCESS',
	EMITIR_ALTERACAO_DADOS_CADASTRAIS_FAILURE = '@DETRAN-CRV/EMITIR_ALTERACAO_DADOS_CADASTRAIS_FAILURE',
	EMITIR_ALTERACAO_DADOS_CADASTRAIS_CLEAR = '@DETRAN-CRV/EMITIR_ALTERACAO_DADOS_CADASTRAIS_CLEAR',
}

export interface emitirAlteracaoDadosCadastrais {
	data: IResponseEmitirAlteracaoDadosCadastrais | null;
	status: number;
}

export interface IRequestWithHeader {
	body: IRequestEmitirAlteracaoDadosCadastrais;
	headers?: any;
}

export interface IResponseEmitirAlteracaoDadosCadastrais {
	anoFicha: number;
	chassi: string;
	numeroficha: number;
	placa: string;
	planilhaRenavam: string;
}

export type IProcedencia = 'IMPORTADA' | 'NACIONAL' | '';

export interface IRequestEmitirAlteracaoDadosCadastrais {
	telefoneCidadao: number | string;
	dddTelefoneCidadao: number | string;
	emailCidadao: string;
	anoModelo: number;
	capacidadeCarga: number | string;
	capacidadePassageiro: number;
	servicoDigital: boolean;
	documentos: any;
	carroceria: {
		longId: number;
		descricao: string;
	};
	categoria: {
		descricao: string;
		longId: number;
	};
	chassi: string;
	chassiRemarcado: boolean;
	cilindrada: number;
	cmt: number;
	combustivel: {
		longId: number;
		descricao: string;
	};
	cor: {
		longId: number;
		descricao: string;
	};
	eixo: number;
	especie: {
		longId: number;
		descricao: string;
	};
	evento: IEvento;
	gravame: {
		arrendatarioFinanceira: string;
		dataVigencia: string;
		financeira: number;
		numeroContrato: string;
		restricaoFinanceira: string;
	};
	marca: {
		longId: number;
		descricao: string;
	};
	municipio: {
		codigo: number;
		nome: string;
	};
	numeroEspelho: number;
	pbt: number;
	placa: string;
	placaAnterior: string;
	potencia: number;
	procedencia: IProcedencia;
	proprietario: proprietario;
	taxaAutenticacaoDigital: string;
	tipo: {
		longId: number;
		descricao: string;
	};
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	ip: string;
	cpf: string;
	identificacao: string;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface proprietario {
	bairro: string;
	cepProprietario: number;
	complemento: string;
	cpfCnpjProprietario: number;
	enderecoProprietario: string;
	identificacao: string;
	municipioProprietario: {
		codigo: number;
		nome: string;
	};
	nomeProprietario: string;
	nomeProprietarioAnterior: string;
	numero: number;
	orgaoExpeditorProprietario: string;
	rgProprietario: number;
	telefone: number;
	ufProprietario: string;
}

export interface IProprietarioFisicoEmitirAlteracaoDadosCadastrais {
	bairro: string;
	cepProprietario: number;
	complemento: string;
	enderecoProprietario: string;
	municipioProprietario: {
		codigo: number;
		nome: string;
	};
	nomeProprietario: string;
	numero: number;
	orgaoExpeditorProprietario: string;
	rgProprietario: string;
	telefone: number | string;
	ufProprietario: string;
}

export interface IProprietarioJuridicoEmitirAlteracaoDadosCadastrais {
	bairro: string;
	cepProprietario: number;
	complemento: string;
	enderecoProprietario: string;
	municipioProprietario: {
		codigo: number;
		nome: string;
	};
	nomeProprietario: string;
	numero: number;
	telefone: number | string;
}
