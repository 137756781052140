export function scrollToTopByClassName(
	className: string,
	customValue?: number,
) {
	const element = document.getElementsByClassName(className);

	for (let index = 0; index < element.length; index++) {
		element[index].scrollTo({ top: customValue ?? 0, behavior: 'smooth' });
	}
}
