import React from 'react';

// ANTD
import { Row, Col, Divider } from 'antd';

// COMPONENTS
import { v4 } from 'uuid';

// COMPONENTS COMMON
import TypographyCommon from 'components/Common/Typography';

// UTILS
import { isFilledValue } from 'utils/stringFunctions';
import { formatDate } from 'utils/genericFunctions';
import { formatCurrency } from 'utils/numberFunctions';

// REDUX
import { TXUT } from 'store/modules/api/detranCrv/ConsultarResumoExtrato/txut/types';

interface Props {
	data: TXUT;
}

const ExtratoTXUT: React.FC<Props> = ({ data }) => {
	return (
		<>
			{data.status === 200 &&
				data.data.veiculosTaxas &&
				data.data.veiculosTaxas.map((item, index) => (
					<span key={v4()}>
						<Row gutter={[0, 0]} align="top">
							<Col span={4}>
								<TypographyCommon
									text="Placa:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text={isFilledValue(item?.placa)}
									type="label"
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text="Renavam:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text={isFilledValue(item?.renavam)}
									type="label"
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text="CNPJ/CPF:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text={isFilledValue(item?.cpfCnpj)}
									type="label"
									labelSize={11}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 0]} align="top">
							<Col span={4}>
								<TypographyCommon
									text="Código Banco:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text={isFilledValue(item?.codBanco)}
									type="label"
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text="NSU Prodesp:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text={isFilledValue(item?.nsuProdesp)}
									type="label"
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text="Data Contábil:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text={isFilledValue(formatDate(item?.dataContabil))}
									type="label"
									labelSize={11}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 0]} align="top">
							<Col span={4}>
								<TypographyCommon
									text="Código Serviço:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text={isFilledValue(item?.codServico)}
									type="label"
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text="NSU Banco:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text={isFilledValue(item?.nsuBanco)}
									type="label"
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text="Data Inclusão Taxa:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text={isFilledValue(formatDate(item?.dataInclusaoTaxa))}
									type="label"
									labelSize={11}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 0]} align="top">
							<Col span={4}>
								<TypographyCommon
									text="Valor:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text={isFilledValue(formatCurrency(item?.valor))}
									type="label"
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text="Ano Licenciamento:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text={isFilledValue(item?.anoLcenciamento)}
									type="label"
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text="Status Taxa:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={4}>
								<TypographyCommon
									text={isFilledValue([
										item?.statusTaxa?.codigo,
										item?.statusTaxa?.descricao,
									])}
									type="label"
									labelSize={11}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 0]} align="top">
							<Col span={4}>
								<TypographyCommon
									text="Taxa Sefaz:"
									type="label"
									fontWeight="bold"
									uppercase
									labelSize={11}
								/>
							</Col>
							<Col span={18}>
								<TypographyCommon
									text={isFilledValue(item?.taxaSefaz)}
									type="label"
									labelSize={11}
								/>
							</Col>
						</Row>

						{data.data.veiculosTaxas.length !== index + 1 && <Divider />}
					</span>
				))}

			{data.status === 406 && (
				<Row gutter={[0, 0]} align="top">
					<Col span={24}>
						<TypographyCommon
							text={data.mensagem}
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
				</Row>
			)}

			{data.status === 500 && (
				<Row gutter={[0, 0]} align="top">
					<Col span={24}>
						<TypographyCommon
							text="Pesquisa disponível somente para busca por placa."
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
				</Row>
			)}
		</>
	);
};

export default ExtratoTXUT;
