import React, { useCallback } from 'react';

// REDUX
import { useDispatch } from 'react-redux';
import { setLightTheme, setDarkTheme } from 'store/modules/app/theme/actions';

// STYLED
import { Container, Light, Dark, Text } from './styled';

const Contrast: React.FC = () => {
	const dispatch = useDispatch();

	const handleLightTheme = useCallback(() => {
		dispatch(setLightTheme());
	}, [dispatch]);

	const handleDarkTheme = useCallback(() => {
		dispatch(setDarkTheme());
	}, [dispatch]);

	return (
		<>
			<Container>
				<Light onClick={handleLightTheme} />

				<Dark onClick={handleDarkTheme} />

				<Text>Contraste</Text>
			</Container>
		</>
	);
};

export default Contrast;
