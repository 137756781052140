import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export function listaPaisesRequest(): ReducerAction {
	return {
		type: Types.LISTA_PAISES,
		payload: null,
	};
}
export function listaPaisesSuccess(payload: object): ReducerAction {
	return {
		type: Types.LISTA_PAISES_SUCCESS,
		payload,
	};
}
export function listaPaisesFailure(payload: boolean): ReducerAction {
	return {
		type: Types.LISTA_PAISES_FAILURE,
		payload,
	};
}
