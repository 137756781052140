import React, { Suspense, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

// ANTD
import { Col, Row, Skeleton, Modal } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { excluirFichaCadastralRequest } from 'store/modules/api/detranCrv/Processos/ExcluirFichaCadastral/actions';

// COMPONENTS COMMON
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { Field, Form, Formik, FormikProps } from 'formik';
import { initialValues, schema } from './form';

const ExcluirFichaCadastral: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const { excluirFichaCadastral } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.processos,
	);

	useEffect(() => {
		if (excluirFichaCadastral.status === 200) {
			history.push('/detran-crv/excluir-ficha-cadastral/resultado');
		}
	}, [excluirFichaCadastral, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						Modal.confirm({
							title: 'Deseja realmente excluir a Ficha Cadastral?',
							okText: 'Confirmar',
							cancelText: 'Cancelar',
							onOk() {
								dispatch(
									excluirFichaCadastralRequest({
										numeroFicha: values.numeroFicha,
										anoFicha: values.anoFicha,
									}),
								);
							},
							onCancel() {},
						});
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={14}>
										<Field
											titleSize="lg"
											required
											maxLength={7}
											name="numeroFicha"
											title="Número da Ficha"
											as={Input}
											error={hasError(formik.errors, 'numeroFicha')}
										/>
									</Col>

									<Col span={10} />

									<Col span={14}>
										<Field
											titleSize="lg"
											required
											maxLength={4}
											name="anoFicha"
											title="Ano da Ficha Cadastral"
											as={Input}
											error={hasError(formik.errors, 'anoFicha')}
										/>
									</Col>
									<Col span={8} />
								</Row>

								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={formik.handleReset}
										/>
									</Col>

									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>

			<ButtonVoltar route="/detran-crv" />
		</Suspense>
	);
};

export default ExcluirFichaCadastral;
