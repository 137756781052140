// ROUTES
import { ROUTE_IIRGD } from 'routes/paths';
import ConsultarCpf from '../Formulario';

import { ROUTE_IIRGD_CIN_EMITIR } from './paths';

const iirgdRoute = {
	name: 'IIRGD',
	route: ROUTE_IIRGD,
};

export default [
	{
		path: ROUTE_IIRGD_CIN_EMITIR,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Emitir CIN',
			},
		],
		Component: ConsultarCpf,
		isAuthenticated: true,
		exact: true,
	},
];
