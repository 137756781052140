// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaCpf, ConsultaCpfRequest, Types } from './types';

export function consultaCpfRequest(payload: ConsultaCpfRequest): ReducerAction {
	return {
		type: Types.CONSULTA_CONSULTA_CPF_REQUEST,
		payload,
	};
}

export function consultaCpfSuccess(payload: ConsultaCpf[]): ReducerAction {
	return {
		type: Types.CONSULTA_CONSULTA_CPF_SUCCESS,
		payload,
	};
}

export function consultaCpfFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_CONSULTA_CPF_FAILURE,
		payload: null,
	};
}

export function consultaCpfClear(): ReducerAction {
	return {
		type: Types.CONSULTA_CONSULTA_CPF_CLEAR,
		payload: null,
	};
}
