import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsumidorSelecionado } from './types';

export const INITIAL_STATE: ConsumidorSelecionado = {
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsumidorSelecionado {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSUMIDOR_SELECIONADO_REQUEST: {
				draft.data = action.payload;
				break;
			}
			case Types.CONSUMIDOR_SELECIONADO_CLEAR: {
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
