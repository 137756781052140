import styled from 'styled-components';

// IMAGES
import increase from 'assets/images/font-size/increase.gif';
import decrease from 'assets/images/font-size/decrease.gif';

export const Container = styled.div`
	align-items: center;
	display: flex;
	height: 16px;
	margin-right: 16px;
`;

export const IncreaseFont = styled.button`
	background-image: url(${increase});
	border: unset;
	margin-right: 4px;
	height: 16px;
	width: 16px;
`;

export const DecreaseFont = styled.button`
	background-image: url(${decrease});
	border: unset;
	margin-right: 4px;
	height: 16px;
	width: 16px;
`;

export const Text = styled.div`
	font-size: 0.75rem;
	margin: auto 0;
`;
