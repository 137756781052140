import React, { Suspense } from 'react';

import { Row, Col, Skeleton, List } from 'antd';

import { v4 } from 'uuid';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { IRegistroBloqueioDetran } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarBloqueioDetranRenajud/types';

// COMPONENTS
import Section from 'components/Common/Section';
import Bloqueio from './Bloqueio';

const BloqueioDetran: React.FC = () => {
	const { consultarBloqueioDetranRenajud } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			{consultarBloqueioDetranRenajud.data.bloqueioDetran && (
				<Row>
					<Col span={24}>
						<Section size="lg" title="Dados do Veículo" titleSize="sm">
							<Row gutter={[0, 10]}>
								<Col span={6}>Placa</Col>

								<Col span={6}>
									<strong>
										{consultarBloqueioDetranRenajud.data.bloqueioDetran.placa}
									</strong>
								</Col>
							</Row>

							<Row>
								<Col span={6}>Chassi</Col>

								<Col span={6}>
									<strong>
										{consultarBloqueioDetranRenajud.data.bloqueioDetran.chassi}
									</strong>
								</Col>
							</Row>
						</Section>

						<Section size="lg" title="Dados do bloqueio DETRAN" titleSize="sm">
							<List
								itemLayout="horizontal"
								size="large"
								dataSource={
									consultarBloqueioDetranRenajud.data.bloqueioDetran
										.registroBloqueios
								}
								split={false}
								locale={{
									emptyText:
										'Não foi localizado nenhum resultado na sua Consulta / Pesquisa.',
								}}
								pagination={{
									showSizeChanger: true,
									pageSize: 10,
									pageSizeOptions: ['10', '20'],
								}}
								renderItem={(item: IRegistroBloqueioDetran) => (
									<Bloqueio key={String(v4())} data={item} />
								)}
							/>
						</Section>
					</Col>
				</Row>
			)}
		</Suspense>
	);
};

export default BloqueioDetran;
