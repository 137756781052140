// TYPES
import { ReducerAction } from 'store/modules/types';
import { ApiResponse } from 'services/_api';
import { Types, EmitirCin, EmitirCinRequest } from './types';

export function emitirCinRequest(payload: EmitirCinRequest): ReducerAction {
	return {
		type: Types.EMITIR_CIN_REQUEST,
		payload,
	};
}
export function emitirCinSuccess(payload: EmitirCin): ReducerAction {
	return {
		type: Types.EMITIR_CIN_SUCCESS,
		payload,
	};
}
export function emitirCinFailure(payload: ApiResponse): ReducerAction {
	return {
		type: Types.EMITIR_CIN_FAILURE,
		payload,
	};
}
export function emitirCinClear(): ReducerAction {
	return {
		type: Types.EMITIR_CIN_CLEAR,
		payload: null,
	};
}
