export enum Types {
	CANCELA_CONSULTA_ATENDIMENTO_REQUEST = '@procon/CANCELA_CONSULTA_ATENDIMENTO_REQUEST',
	CANCELA_CONSULTA_ATENDIMENTO_SUCCESS = '@procon/CANCELA_CONSULTA_ATENDIMENTO_SUCCESS',
	CANCELA_CONSULTA_ATENDIMENTO_FAILURE = '@procon/CANCELA_CONSULTA_ATENDIMENTO_FAILURE',
	CANCELA_CONSULTA_ATENDIMENTO_CLEAR = '@procon/CANCELA_CONSULTA_ATENDIMENTO_CLEAR',
}

export interface CancelaConsultaAtendimento {
	status: null | number;
	data: any;
	consulta: null | CancelaConsultaAtendimentoRequest;
}

export interface CancelaConsultaAtendimentoRequest {
	atendimentoId: string;
	justificativa: string;
}

export interface CancelaConsultaAtendimentoForm {
	atendimentoId: string;
	justificativa: string;
}
