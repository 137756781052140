import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_CDHU_MUTUARIOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaPrestacoesAtrasadasRequest } from './types';

// ACTIONS
import {
	consultarPrestacoesAtrasadasSuccess,
	consultarPrestacoesAtrasadasFailure,
} from './actions';

function* consultarPrestacoesAtrasadasRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultaPrestacoesAtrasadasRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_CDHU_MUTUARIOS_API,
		`prestacoes-atrasadas`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultarPrestacoesAtrasadasSuccess(response.data));
	} else {
		yield put(consultarPrestacoesAtrasadasFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_PRESTACOES_ATRASADAS_REQUEST,
		consultarPrestacoesAtrasadasRequest,
	),
]);
