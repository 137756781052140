import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { AssuntosProblemasRequest, Types } from './types';

// ACTIONS
import { assuntosProblemasSuccess, assuntosProblemasFailure } from './actions';

function* assuntosProblemasRequest(request: ReducerAction) {
	const { payload }: { payload: AssuntosProblemasRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`grupos-de-problemas/${CHANNEL}/assuntos/grupos-de-problemas`,
		payload,
	);

	if (response.status === 200) {
		yield put(assuntosProblemasSuccess(response));
	} else {
		yield put(assuntosProblemasFailure(response));
	}
}

export default all([
	takeLatest(Types.ASSUNTOS_PROBLEMAS__REQUEST, assuntosProblemasRequest),
]);
