import { ReducerAction } from 'store/modules/types';

import { Types, RequestConsultaEPortalSegundaVia } from './types';

export function consultaESegundaViaRequest(
	data: RequestConsultaEPortalSegundaVia,
): ReducerAction {
	return {
		type: Types.CONSULTA_E_PORTAL_SEGUNDA_VIA_REQUEST,
		payload: data,
	};
}
export function consultaESegundaViaSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_E_PORTAL_SEGUNDA_VIA_SUCCESS,
		payload,
	};
}
export function consultaESegundaViaFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_E_PORTAL_SEGUNDA_VIA_FAILURE,
		payload,
	};
}
export function consultaESegundaViaClear(): ReducerAction {
	return {
		type: Types.CONSULTA_E_PORTAL_SEGUNDA_VIA_CLEAR,
		payload: null,
	};
}
