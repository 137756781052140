export enum Types {
	GERAR_RENACH_MUDANCA_CATEGORIA = '@cnh/GERAR_RENACH_MUDANCA_CATEGORIA',
	GERAR_RENACH_MUDANCA_CATEGORIA_SUCCESS = '@cnh/GERAR_RENACH_MUDANCA_CATEGORIA_SUCCESS',
	GERAR_RENACH_MUDANCA_CATEGORIA_FAILURE = '@cnh/GERAR_RENACH_MUDANCA_CATEGORIA_FAILURE',
}

export interface gerarRenach {
	status: number;
	data: null;
}
export interface IRequestGerarRenach {
	autorizaEmail: string;
	autorizaSMS: string;
	bairro: string;
	categoriaAtual: string;
	categoriaPretendida: string;
	telefone: string;
	celular: string;
	cep: string;
	codigoEntidade: string;
	complemento: string;
	cpf: string;
	dataNascimento: string;
	dataPrimeiraHabilitacao: string;
	dddCelular: string;
	dddTelefone: string;
	email: string;
	logradouro: string;
	nacionalidade: string;
	naturalidade: string;
	necessidadeVeiculoAdaptado: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	numCarteiraMilitar: string;
	numDocumento: string;
	numPGU: string;
	numRNE: string;
	numRegistro: string;
	numero: string;
	orgaoDocumento: string;
	orgaoRNE: string;
	pretendeAtividadeRemunerada: string;
	sexo: string;
	tipoDocumento: string;
	ufDocumento: string;
	ufRNE: string;
}
