import React, { Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';
import { consultarBaseOutrosEstadosClear } from 'store/modules/api/detranCrv/ConsultarVeiculos/consultarBaseOutrosEstados/actions';

const ConsultarBaseOutrosEstadosResultado: React.FC = () => {
	const dispatch = useDispatch();
	const { dadosBaseEstadualOutrosEstados } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.consultarVeiculos.consultarBaseOutrosEstados.data,
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Row>
				<Col span={24}>
					<Section size="sm" title="DADOS DO VEÍCULO">
						<Row gutter={[0, 10]}>
							<Col span={6}>Placa</Col>
							<Col span={6}>
								<strong>{dadosBaseEstadualOutrosEstados.placa}</strong>
							</Col>
							<Col span={6}>Chassi</Col>
							<Col span={6}>
								<strong>{dadosBaseEstadualOutrosEstados.chassi}</strong>
							</Col>

							<Col span={6}>Município Emplacamento</Col>
							<Col span={6}>
								<strong>
									{dadosBaseEstadualOutrosEstados.municipioEmplacamento.nome}
								</strong>
							</Col>
							<Col span={6}>UF</Col>
							<Col span={6}>
								<strong>{dadosBaseEstadualOutrosEstados.ufEmplacamento}</strong>
							</Col>

							<Col span={6}>Procedência do Veículo</Col>
							<Col span={6}>
								<strong>{dadosBaseEstadualOutrosEstados.procedencia}</strong>
							</Col>
							<Col span={6}>Situação do Veículo</Col>
							<Col span={6}>
								<strong>
									{dadosBaseEstadualOutrosEstados.situacaoVeiculo}
								</strong>
							</Col>

							<Col span={6}>Última Atualização</Col>
							<Col span={6}>
								<strong>
									{dadosBaseEstadualOutrosEstados.ultimaAtualizacao &&
										new Date(
											dadosBaseEstadualOutrosEstados.ultimaAtualizacao,
										).toLocaleDateString('pt-BR')}
								</strong>
							</Col>
							<Col span={6}>Cor</Col>
							<Col span={6}>
								<strong>
									{dadosBaseEstadualOutrosEstados.cor.longId} -{' '}
									{dadosBaseEstadualOutrosEstados.cor.descricao}
								</strong>
							</Col>

							<Col span={6}>Marca</Col>
							<Col span={18}>
								<strong>
									{dadosBaseEstadualOutrosEstados.marca.longId} -{' '}
									{dadosBaseEstadualOutrosEstados.marca.descricao}
								</strong>
							</Col>

							<Col span={6}>Ano Fabr.</Col>
							<Col span={6}>
								<strong>{dadosBaseEstadualOutrosEstados.anoFabricacao}</strong>
							</Col>
							<Col span={6}>Ano Modelo</Col>
							<Col span={6}>
								<strong>{dadosBaseEstadualOutrosEstados.anoModelo}</strong>
							</Col>
							<Col span={6}>Combustível</Col>
							<Col span={6}>
								<strong>
									{dadosBaseEstadualOutrosEstados.combustivel.longId} -{' '}
									{dadosBaseEstadualOutrosEstados.combustivel.descricao}
								</strong>
							</Col>
							<Col span={6}>Tipo</Col>
							<Col span={6}>
								<strong>
									{dadosBaseEstadualOutrosEstados.tipo.longId} -{' '}
									{dadosBaseEstadualOutrosEstados.tipo.descricao}
								</strong>
							</Col>
							<Col span={6}>Categoria</Col>
							<Col span={6}>
								<strong>
									{dadosBaseEstadualOutrosEstados.categoria.longId} -{' '}
									{dadosBaseEstadualOutrosEstados.categoria.descricao}
								</strong>
							</Col>
							<Col span={6}>Capacidade de Carga</Col>
							<Col span={6}>
								<strong>
									{dadosBaseEstadualOutrosEstados.capacidadeCarga}
								</strong>
							</Col>
							<Col span={6}>Número do Motor</Col>
							<Col span={6}>
								<strong>{dadosBaseEstadualOutrosEstados.numeroMotor}</strong>
							</Col>
							<Col span={6}>Capacidade de Passageiros</Col>
							<Col span={6}>
								<strong>
									{dadosBaseEstadualOutrosEstados.capacidadePassageiro}
								</strong>
							</Col>
							<Col span={6}>CMT</Col>
							<Col span={6}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 1,
									}).format(dadosBaseEstadualOutrosEstados.cmt)}
								</strong>
							</Col>
							<Col span={6}>PBT</Col>
							<Col span={6}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 1,
									}).format(dadosBaseEstadualOutrosEstados.pbt)}
								</strong>
							</Col>
							<Col span={6}>Eixos</Col>
							<Col span={6}>
								<strong>{dadosBaseEstadualOutrosEstados.eixo}</strong>
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="RESTRIÇÕES">
						<Row>
							<Col span={17}>
								<strong>
									{dadosBaseEstadualOutrosEstados.restricoes &&
										dadosBaseEstadualOutrosEstados.restricoes.length > 0 &&
										dadosBaseEstadualOutrosEstados.restricoes.map(
											(item: string) => {
												return <p key={Math.random()}>{item}</p>;
											},
										)}

									{!dadosBaseEstadualOutrosEstados.restricoes && (
										<span>Nenhum Registro Encontrado</span>
									)}
								</strong>
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="DADOS DO PROPRIETÁRIO">
						<Row>
							<Col span={6}>Nome do Proprietário</Col>
							<Col span={16}>
								<strong>
									{
										dadosBaseEstadualOutrosEstados.dadosProprietario
											.nomeProprietario
									}
								</strong>
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="DÉBITOS / MULTAS">
						<Row>
							<Col span={6}>Débitos IPVA Licenciamento</Col>
							<Col span={6}>
								<strong>
									{dadosBaseEstadualOutrosEstados.multa.debitoIpvaLicenciamento}
								</strong>
							</Col>
							<Col span={6}>Débitos Multas</Col>
							<Col span={6}>
								<strong>
									{dadosBaseEstadualOutrosEstados.multa.debitosMultas}
								</strong>
							</Col>
							<Col span={6}>Valor Total Débito IPVA</Col>
							<Col span={6}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 2,
									}).format(
										dadosBaseEstadualOutrosEstados.multa.valorDebitoIpva,
									)}
								</strong>
							</Col>
							<Col span={6}>Valor Total Débito Licenciamento</Col>
							<Col span={6}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 2,
									}).format(
										dadosBaseEstadualOutrosEstados.multa
											.valorDebitoLicenciamento,
									)}
								</strong>
							</Col>
							<Col span={6}>Valor Total Débito Multas</Col>
							<Col span={6}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 2,
									}).format(
										dadosBaseEstadualOutrosEstados.multa.valorDebitoMulta,
									)}
								</strong>
							</Col>
							<Col span={6}>Valor Total Débito DPVAT</Col>
							<Col span={6}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 2,
									}).format(dadosBaseEstadualOutrosEstados.multa.valorDpvat)}
								</strong>
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="GRAVAMES">
						<Row>
							<Col span={6}>Tipo de Transação</Col>
							<Col span={16}>
								<strong>
									{dadosBaseEstadualOutrosEstados.gravame.tipoTransacao}
								</strong>
							</Col>
							<Col span={6}>Tipo de Restrição Financeira</Col>
							<Col span={16}>
								<strong>
									{dadosBaseEstadualOutrosEstados.gravame.tipoRestricao}
								</strong>
							</Col>{' '}
							<Col span={6}>Nome do Agente Financeiro</Col>
							<Col span={16}>
								<strong>
									{dadosBaseEstadualOutrosEstados.gravame.agenteFinanceiro}
								</strong>
							</Col>{' '}
							<Col span={6}>CNPJ/CPF Financ</Col>
							<Col span={16}>
								<strong>
									{dadosBaseEstadualOutrosEstados.gravame.cpfCnpjFinanciado}
								</strong>
							</Col>{' '}
							<Col span={6}>Nome do Financiado</Col>
							<Col span={16}>
								<strong>
									{dadosBaseEstadualOutrosEstados.gravame.nomeFinanciado}
								</strong>
							</Col>{' '}
							<Col span={6}>Data Inclusão de Intenção / Troca Financ</Col>
							<Col span={16}>
								<strong>
									{dadosBaseEstadualOutrosEstados.gravame.dataInclusao &&
										new Date(
											dadosBaseEstadualOutrosEstados.gravame.dataInclusao,
										).toLocaleDateString('pt-BR')}
								</strong>
							</Col>
						</Row>
					</Section>
					<Row justify="space-around" align="middle">
						<Col>
							<ButtonVoltar
								onClick={() => dispatch(consultarBaseOutrosEstadosClear())}
							/>
						</Col>
						<Col>
							<Button className="no-print" onClick={() => window.print()}>
								{' '}
								Imprimir
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Suspense>
	);
};

export default ConsultarBaseOutrosEstadosResultado;
