// FORM
import {
	IDesbloquearVeiculoPorSolicitante,
	initialValuesDesbloquearVeiculoPorSolicitante,
} from 'pages/DetranCrv/components/pages/ServicosDeVeiculos/DesbloquearVeiculoPorSolicitante/form';

// TYPES
import { ConsultaRestricoes } from 'store/modules/api/detranCrv/SolicitarDocumentos/desbloqueio/consultaRestricoes/types';
import { RegistraSolicitacoesDataSearch } from 'store/modules/api/detranCrv/SolicitarDocumentos/desbloqueio/registraSolicitacoes/types';

// UTILS
import { limparMascara } from 'utils/genericFunctions';

export const threatInitialValues = (
	consultaRestricoes: ConsultaRestricoes,
): IDesbloquearVeiculoPorSolicitante => {
	return {
		...initialValuesDesbloquearVeiculoPorSolicitante,
		cpfCnpj: consultaRestricoes.dataRequest?.cpfCnpj || '',
		placa: consultaRestricoes.dataRequest?.placa || '',
		renavam: consultaRestricoes.dataRequest?.renavam || '',
	};
};

export const threatValuesRequestRegistraSolicitacao = (
	values: IDesbloquearVeiculoPorSolicitante,
): RegistraSolicitacoesDataSearch => {
	return {
		placa: values?.placa || '',
		renavam: values?.renavam || '',
		cpfCnpj: values?.cpfCnpj || '',
		opcao: 16,
		documentos: [
			{
				conteudo: (values?.documentos && values?.documentos[0]?.conteudo) || '',
				tipoArquivo:
					(values?.documentos && values?.documentos[0]?.tipoArquivo) || '',
			},
		],
		servicoDigital: true,
		cpfSolicitante: '',
		nomeCidadao: values?.nome || '',
		emailCidadao: values?.email || '',
		dddTelefoneCidadao:
			(values?.telefone && limparMascara(values.telefone).substring(0, 2)) ||
			'',
		telefoneCidadao:
			(values?.telefone && limparMascara(values?.telefone).substring(2, 11)) ||
			'',
	};
};
