export default {
	uf: [
		{
			label: 'AC',
			value: 'AC',
		},
		{
			label: 'AL',
			value: 'AL',
		},
		{
			label: 'AM',
			value: 'AM',
		},
		{
			label: 'AP',
			value: 'AP',
		},
		{
			label: 'BA',
			value: 'BA',
		},
		{
			label: 'CE',
			value: 'CE',
		},
		{
			label: 'DF',
			value: 'DF',
		},
		{
			label: 'ES',
			value: 'ES',
		},
		{
			label: 'GO',
			value: 'GO',
		},
		{
			label: 'MA',
			value: 'MA',
		},
		{
			label: 'MG',
			value: 'MG',
		},
		{
			label: 'MS',
			value: 'MS',
		},
		{
			label: 'MT',
			value: 'MT',
		},
		{
			label: 'PA',
			value: 'PA',
		},
		{
			label: 'PB',
			value: 'PB',
		},
		{
			label: 'PE',
			value: 'PE',
		},
		{
			label: 'PI',
			value: 'PI',
		},
		{
			label: 'PR',
			value: 'PR',
		},
		{
			label: 'RJ',
			value: 'RJ',
		},
		{
			label: 'RN',
			value: 'RN',
		},
		{
			label: 'RO',
			value: 'RO',
		},
		{
			label: 'RR',
			value: 'RR',
		},
		{
			label: 'RS',
			value: 'RS',
		},
		{
			label: 'SC',
			value: 'SC',
		},
		{
			label: 'SE',
			value: 'SE',
		},
		{
			label: 'SP',
			value: 'SP',
		},
		{
			label: 'TO',
			value: 'TO',
		},
	],
	tipoAutorizacaoPrevia: [
		{
			label: 'Fabricação',
			value: 'FABRICACAO',
		},
		{
			label: 'Modificação',
			value: 'MODIFICACAO',
		},
		{
			label: 'Precário',
			value: 'PRECARIO',
		},
		{
			label: 'Sinistro',
			value: 'SINISTRO',
		},
		{
			label: 'Transformação',
			value: 'TRANSFORMACAO',
		},
	],
	restricaoFinanceira: [
		{
			label: 'NADA CONSTA',
			value: 'NADA_CONSTA',
		},
		{
			label: 'ALIENAÇÃO FIDUCIÁRIA',
			value: 'ALIENACAO_FIDUCIARIA',
		},
		{
			label: 'ARRENDAMENTO',
			value: 'ARRENDAMENTO',
		},
		{
			label: 'PENHOR MERCANTIL',
			value: 'PENHOR_MERCANTIL',
		},
		{
			label: 'RESERVA DE DOMÍNIO',
			value: 'RESERVA_DOMINIO',
		},
	],
	identificacao: [
		{ value: 'FISICA', label: 'Pessoa Física' },
		{ value: 'JURIDICA', label: 'Pessoa Jurídica' },
	],
	tipoDocumentoLojaComprador: [
		{ value: 1, label: 'Pessoa Física' },
		{ value: 2, label: 'Pessoa Jurídica' },
	],
	procedencia: [
		{ value: 'NACIONAL', label: 'NACIONAL' },
		{ value: 'IMPORTADA', label: 'IMPORTADA' },
	],
	simNaoBoolean: [
		{
			value: true,
			label: 'Sim',
		},
		{
			value: false,
			label: 'Não',
		},
	],
	procedenciaVeiculo: [
		{
			value: 'NACIONAL',
			label: 'Nacional',
		},
		{
			value: 'IMPORTADA',
			label: 'Importada',
		},
	],
	tipoPessoa: [
		{
			value: 'FISICA',
			label: 'Pessoa Física',
		},
		{
			value: 'JURIDICA',
			label: 'Pessoa Jurídica',
		},
	],
	motivoAutorizacaoEmplacamentoOptions: [
		{
			value: 'VEICULO_BIN',
			label: 'VEÍCULO BIN',
		},
		{
			value: 'VEICULO_SEGURANCA',
			label: 'VEÍCULO DE SEGURANÇA',
		},
		{
			value: 'VEICULO_EXPERIENCIA',
			label: 'VEÍCULO DE EXPERIÊNCIA',
		},
		{
			value: 'VEICULO_OFICIAL',
			label: 'VEÍCULO OFICIAL',
		},
		{
			value: 'DECISAO_JUDICIAL',
			label: 'DECISÃO JUDICIAL',
		},
		{
			value: 'PLACA_PROVISORIA',
			label: 'PLACA PROVISÓRIA',
		},
		{
			value: 'TROCA_CATEGORIA',
			label: 'TROCA DE CATEGORIA OU ESPÉCIE COLEÇÃO',
		},
	],
	tipoPlaca: [
		{
			value: 'DIANTEIRA',
			label: 'DIANTEIRA',
		},
		{
			value: 'TRASEIRA',
			label: 'TRASEIRA',
		},
		{
			value: 'DIANTEIRA_TRASEIRA',
			label: 'DIANTEIRA E TRASEIRA',
		},
		{
			value: 'SEGUNDA_PLACA_TRASEIRA',
			label: 'SEGUNDA PLACA TRASEIRA',
		},
	],
	tipoVeiculoOficial: [
		{
			value: 'BR',
			label: 'Veículos oficiais da união: brasão de armas federais',
		},
		{
			value: 'UF',
			label:
				'Veículos oficiais das unidades da federação: bandeirado estado e brasão estadual',
		},
		{
			value: 'MUN',
			label:
				'Veículos oficiais dos municípios: bandeira do estadoe brasão do município',
		},
		{
			value: 'CMD',
			label: 'Chefes de missões diplomáticas',
		},
		{
			value: 'CD',
			label: 'Corpo diplomático',
		},
		{
			value: 'CC',
			label: 'Corpo consular',
		},
		{
			value: 'OI',
			label: 'Organismo internacional',
		},
		{
			value: 'ADM',
			label: 'Funcionários administrativos estrangeiros',
		},
		{
			value: 'CI',
			label:
				'Peritos estrangeiros com visto permanente, pertencentea acordo de cooperação internacional',
		},
		{
			value: 'PP',
			label: 'Placa particular (artigo 116)',
		},
	],
	motivoSegundaViaPlaca: [
		{
			value: 'PERDA',
			label: 'PERDA',
		},
		{
			value: 'DANO',
			label: 'DANO',
		},
		{
			value: 'FURTO_ROUBO',
			label: 'FURTO ROUBO',
		},
		{
			value: 'TERCEIRA_PLACA',
			label: 'TERCEIRA PLACA',
		},
	],
	consultaIntencaoVenda: [
		{
			value: '1',
			label: 'Registrada',
		},
		{
			value: '2',
			label: 'Venda Consumida',
		},
		{
			value: '3',
			label: 'Consumida',
		},
		{
			value: '4',
			label: 'Cancelada',
		},
	],
};
