import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import { ApplicationState } from 'store';
import { ROUTE_PREFEITURA_CONSULTA_INFRACOES } from 'pages/Prefeitura/routes/paths';

import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import PDFViewer from 'components/Common/PDFViewer';

const TransferirPontuacaoResultado = () => {
	const [documenteVisible, setDocumenteVisible] = useState(false);

	const { transferirPontuacao } = useSelector(
		(state: ApplicationState) => state.api.prefeituras.jalesTransito,
	);

	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section title="Resultado do Pedido de Transferência de Pontos">
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Input
									name="numero"
									title="Número"
									value={transferirPontuacao.data?.numero}
									readOnly
								/>
							</Col>
						</Row>
						<Row gutter={[0, 10]} justify="center">
							<Col>
								<ButtonImage
									src="imprimir"
									onClick={() => setDocumenteVisible(true)}
								/>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
			<ButtonVoltar route={`${ROUTE_PREFEITURA_CONSULTA_INFRACOES}`} />
			{documenteVisible && transferirPontuacao.data && (
				<PDFViewer
					title=""
					source={transferirPontuacao.data.documento}
					onClose={() => setDocumenteVisible(false)}
				/>
			)}
		</>
	);
};

export default TransferirPontuacaoResultado;
