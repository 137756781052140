import * as Yup from 'yup';
import { ConsultarAcompanhamentoRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarAcompanhamento/types';

export const initialValues: ConsultarAcompanhamentoRequest = {
	placa: '',
	renavam: '',
	documento: '',
	processo: null,
};

export const schema = Yup.object<ConsultarAcompanhamentoRequest>().shape({
	placa: Yup.string().required('Campo Obrigatório: Placa.'),
	renavam: Yup.string(),
	documento: Yup.string().required('Campo Obrigatório: CPF/CNPJ Proprietário.'),
});
