import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultaCadastroCnh } from './types';

export function consultaCadastroCnhRequest(
	data: IRequestConsultaCadastroCnh,
): ReducerAction {
	return {
		type: Types.CONSULTA_CADASTRO_CNH,
		payload: data,
	};
}
export function consultaCadastroCnhSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_CADASTRO_CNH_SUCCESS,
		payload,
	};
}
export function consultaCadastroCnhFailure(payload: boolean): ReducerAction {
	return {
		type: Types.CONSULTA_CADASTRO_CNH_FAILURE,
		payload,
	};
}
