import { ReducerAction } from 'store/modules/types';

import { Types, IRequestMicroRegioes } from './types';

export function microRegioesRequest(data: IRequestMicroRegioes): ReducerAction {
	return {
		type: Types.MICRO_REGIOES,
		payload: data,
	};
}
export function microRegioesSuccess(payload: object): ReducerAction {
	return {
		type: Types.MICRO_REGIOES_SUCCESS,
		payload,
	};
}
export function microRegioesFailure(payload: boolean): ReducerAction {
	return {
		type: Types.MICRO_REGIOES_FAILURE,
		payload,
	};
}

export function microRegioesClear(): ReducerAction {
	return {
		type: Types.MICRO_REGIOES_CLEAR,
		payload: null,
	};
}
