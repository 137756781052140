// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	Types,
	IRequestConsultarVeiculoSegundaViaPlaca,
	IResponseConsultarVeiculoSegundaViaPlaca,
} from './types';

export function consultarVeiculoSegundaViaPlacaRequest(
	payload: IRequestConsultarVeiculoSegundaViaPlaca,
): ReducerAction {
	return {
		type: Types.CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_REQUEST,
		payload,
	};
}

export function consultarVeiculoSegundaViaPlacaSuccess(
	payload: IResponseConsultarVeiculoSegundaViaPlaca,
): ReducerAction {
	return {
		type: Types.CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_SUCCESS,
		payload,
	};
}

export function consultarVeiculoSegundaViaPlacaFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_FAILURE,
		payload: null,
	};
}

export function consultarVeiculoSegundaViaPlacaClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_CLEAR,
		payload: null,
	};
}
