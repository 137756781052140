export enum Types {
	CONSULTA_INTERACOES_PROCESSO_REQUEST = '@procon/CONSULTA_INTERACOES_PROCESSO_REQUEST',
	CONSULTA_INTERACOES_PROCESSO_SUCCESS = '@procon/CONSULTA_INTERACOES_PROCESSO_SUCCESS',
	CONSULTA_INTERACOES_PROCESSO_FAILURE = '@procon/CONSULTA_INTERACOES_PROCESSO_FAILURE',
	CONSULTA_INTERACOES_PROCESSO_CLEAR = '@procon/CONSULTA_INTERACOES_PROCESSO_CLEAR',
}

export interface ConsultaInteracoesProcesso {
	status: null | number;
	data: null | ConsultaInteracoesProcessoData;
}

interface ConsultaInteracoesProcessoData {
	interacoes: Interacoes[];
}

interface Interacoes {
	interacaoId: string;
	descricao: string;
	dataDeCriacao: string;
	responsavelPelaInteracao: string;
	interacaoRespondida: string;
	protocolo: string;
	nomeDoConsumidor: string;
	linksDeDocumentos: string[];
	tramiteImportante: string;
	tramite: string;
	tipoDeTramite: string;
}

export interface ConsultaInteracoesProcessoRequest {
	processoAdministrativoId: string;
	protocolo: string;
}
