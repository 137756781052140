export enum Types {
	EMITIR_CIN_REQUEST = '@rfb/EMITIR_CIN_REQUEST',
	EMITIR_CIN_SUCCESS = '@rfb/EMITIR_CIN_SUCCESS',
	EMITIR_CIN_FAILURE = '@rfb/EMITIR_CIN_FAILURE',
	EMITIR_CIN_CLEAR = '@rfb/EMITIR_CIN_CLEAR',
}

export interface EmitirCinState {
	status: number;
	data: EmitirCin | null;
	dataError: EmitirCinFailureData | null;
}

export interface EmitirCinFailureData {
	transacao: string;
	codigo: number;
	mensagem: string;
	mensagens: string[];
}

export interface EmitirCinRequest {
	cpfUsuario: string;
	cpfCidadao: string;
	nome: string;
	nomeSocial: string;
	dataNascimento: string;
	protocoloOIC: string;
	sexo: string;
	codigoMunicipioNaturalidade: number | string;
	codigoPaisNaturalidadeExterior: number | string;
	codigoPaisNacionalidade: number | string;
	listaFiliacao: Array<string>;
	indicadorMaeRegistro: boolean;
	logradouro: string;
	numeroEndereco: string;
	complementoEndereco: string;
	bairroEndereco: string;
	codigoMunicipioResidencia: number | string;
	cepResidencia: string;
	codigoPaisResidencia: string;
	localResidenciaExterior: string;
	listaEmail: Array<string>;
	listaTelefone: Telefone[];
	codigoMunicipioEmissao: number | string;
	foto: string;
	observacao: string;
	indicadorDeficienciaFisica: boolean;
	indicadorDeficienciaAuditiva: boolean;
	indicadorDeficienciaIntelectual: boolean;
	indicadorDeficienciaVisual: boolean;
	indicadorAutismo: boolean;
	doador: boolean;
	tituloEleitor: string;
	codigoTipoSanguineo: number | string | null;
	codigoEstadoCivil: number | string;
	certidaoNascimentoCasamento: string;
	averbacaoDivorcio: string;
	cnh: string;
	categoriaCnh: string;
	pisPasep: string;
	nis: string;
	nit: string;
	carteiraTrabalho: string;
	cartaoNacionalSaude: string;
}

export interface EmitirCin {
	cpfCidadao: string;
	protocoloRFB: string;
	dataHoraProtocoloRFB: string;
	protocoloOIC: string;
	indicadorConsistenciaBiografica: string;
	codigoConsistenciaBiometrica: string;
	codigoSituacao: string;
	hashCodeEmissao: string;
	mensagens: Array<{
		codigo: string;
		mensagem: string;
	}>;
}

export interface Telefone {
	tipoTelefone: number;
	ddi: number;
	telefone: number;
}

export const maritalStatus = {
	solteiro: { value: 1, description: 'SOLTEIRO' },
	casado: { value: 2, description: 'CASADO' },
	separado: { value: 3, description: 'SEPARADO' },
	divorciado: { value: 4, description: 'DIVORCIADO' },
	viuvo: { value: 5, description: 'VIÚVO' },
};

export const enumBloodType = [
	{ value: 1, description: 'AP' },
	{ value: 2, description: 'BP' },
	{ value: 3, description: 'OP' },
	{ value: 4, description: 'AN' },
	{ value: 5, description: 'BN' },
	{ value: 6, description: 'ON' },
	{ value: 7, description: 'ABP' },
	{ value: 8, description: 'ABN' },
];
