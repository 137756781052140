import * as Yup from 'yup';
import { validaCpf } from 'utils/genericFunctions';

import { IRequestGetCpfPermitido } from 'store/modules/api/bolsaPovo/getCpfPermitido/types';

export const initialValues: IRequestGetCpfPermitido = {
	cpf: '',
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: 0,
		identificacao: 0,
		canal: {
			id: 0,
			desc: '',
			estacao: {
				id: 0,
				desc: '',
			},
			localidade: {
				id: 0,
				desc: '',
			},
		},
	},
};

export const schema = Yup.object<IRequestGetCpfPermitido>().shape({
	cpf: Yup.string()
		.required('Campo Obrigatório: CPF.')
		.test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF digitado inválido.',
			test: value => validaCpf(value),
		}),
});
