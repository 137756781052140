import * as Yup from 'yup';

export interface IFormDareRetaguarda {
	cpf: string;
	dare: string;
}

export const initialValues: IFormDareRetaguarda = {
	cpf: '',
	dare: '',
};

export const schema = Yup.object<IFormDareRetaguarda>().shape(
	{
		cpfDare: Yup.string()
			.when(['cpf', 'dare'], {
				is: (cpf, dare) => cpf === '' && dare === '',
				then: Yup.string().required(
					'Preencha pelo menos um dos dois campos! CPF ou o Nº DARE.',
				),
			})
			.when(['cpf', 'dare'], {
				is: (cpf, dare) => cpf !== '' && dare !== '',
				then: Yup.string().required(
					`Preencha apenas um dos dois campos! CPF ou o Nº DARE.`,
				),
			})
			.when(['cpf', 'dare'], {
				is: (cpf, dare) => cpf !== '' && cpf.length !== 11 && dare === '',
				then: Yup.string().required(
					'Campo CPF: Tamanho mínimo de 11 caracteres.',
				),
			})
			.when(['cpf', 'dare'], {
				is: (cpf, dare) => cpf === '' && dare !== '' && dare.length !== 15,
				then: Yup.string().required(
					'Campo Nº DARE: Tamanho mínimo de 15 caracteres.',
				),
			}),
	},
	[['cpf', 'dare']],
);
