import { IEvento } from '../../types';

export enum Types {
	REMOVER_INSCRICAO_REQUEST = '@EDUCACAO/REMOVER_INSCRICAO_REQUEST',
	REMOVER_INSCRICAO_SUCCESS = '@EDUCACAO/REMOVER_INSCRICAO_SUCCESS',
	REMOVER_INSCRICAO_FAILURE = '@EDUCACAO/REMOVER_INSCRICAO_FAILURE',
}

export interface RemoverInscricao {
	status: number;
	data: null | RemoverInscricaoData;
}

export interface RemoverInscricaoData {
	evento: IEvento;
	aluno: Aluno;
	anoLetivo: number;
	codigoEscola: number;
	tipoInscricao: number;
}

interface Aluno {
	numeroRA: number;
	digitoRA: number;
	siglaUFRA: string;
}
