import React, {
	Suspense,
	useCallback,
	useEffect,
	useState,
	useMemo,
	ChangeEvent,
} from 'react';
import { Row, Col, Skeleton } from 'antd';
// import { useHistory, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik, FormikProps } from 'formik';
import { ROUTE_DETRAN_CRV } from 'routes/paths';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import { consultarMunicipioPorUfRequest } from 'store/modules/api/detranCrv/enum/consultarMunicipioPorUF/actions';
import { consultaCarroceriaRequest } from 'store/modules/api/detranCrv/enum/consultaCarroceria/actions';
import { consultaTipoRequest } from 'store/modules/api/detranCrv/enum/consultaTipo/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCrv/enum/consultaCategoria/actions';
import { consultaCorRequest } from 'store/modules/api/detranCrv/enum/consultaCor/actions';
import { consultaEspecieRequest } from 'store/modules/api/detranCrv/enum/consultaEspecie/actions';
import { consultaCombustivelRequest } from 'store/modules/api/detranCrv/enum/consultaCombustivel/actions';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import Button from 'components/Common/Button';
import { primeiroEmplacamentoEmissaoRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/primeiroEmplacamentoEmissao/actions';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import Placa from 'components/Common/Form/Fields/Placa';
import { consultarVeiculoV120Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV120/actions';
import { primeiroEmplacamentoClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/PrimeiroEmplacamento/actions';
import { tipoArquivoClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/tipoArquivoEmissao/actions';
import Money from 'components/Common/Form/Fields/Money';
import UploadArquivo from 'pages/DetranCrv/components/pages/UploadArquivo';
import { fillCpfCnpj, onlyNumbers } from 'utils/genericFunctions';
import {
	letrasNumeros,
	letrasAcentosApostrofo,
	numeros,
} from 'utils/stringFunctions';
import TaxaServico from 'pages/DetranCrv/components/pages/TaxaServico';
import { ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_IMPRESSAO } from 'pages/DetranCrv/routes/paths';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';
import data from '../../../../../store/modules/api/detranCrv/enum';
import {
	emitirPrimeiroEmplacamentoInitialValues,
	IEmitirPrimeiroEmplacamento,
	formatValues,
	schema,
} from './form';
import { IPrimeiroEmplacamento } from '../form';

const PrimeiroEmplacamentoConfirmacao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	// const location: any = useLocation();
	// const isDigital: boolean =
	// 	(location.state.documento && location.state.documento) || false;

	const [historyState, setHistoryState] = useState<IPrimeiroEmplacamento>({
		chassi: '',
		cpfCnpjProprietario: '',
		restricaoFinanceira: '',
	});

	const { consultarVeiculoV120, primeiroEmplacamento } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const isPersonalizada: any =
		primeiroEmplacamento.data?.placaPersonalizada &&
		primeiroEmplacamento.data?.placaPersonalizada.length > 0;

	const {
		consultarMunicipioPorUF,
		consultaCarroceria,
		consultaTipo,
		consultaCategoria,
		consultaCor,
		consultaEspecie,
		consultaCombustivel,
	} = useSelector((state: ApplicationState) => state.api.detranCrv.Enum);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { salvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento,
	);

	const { primeiroEmplacamentoEmissao } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { consultarTaxaServico, saveTaxaServico } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	const sguData: SguData = useMemo(() => {
		return {
			salvarAtendimento,
			login,
		};
	}, [login, salvarAtendimento]);

	const evento = getEventoConsultar(sguData);

	const BooelanOptions = [
		{
			value: true,
			label: 'Sim',
		},
		{
			value: false,
			label: 'Não',
		},
	];

	const [listaMunicipios, setListaMunicipios] = useState(
		consultarMunicipioPorUF.data,
	);
	const [listaCarrocerias, setListaCarrocerias] = useState(
		consultaCarroceria.data,
	);
	const [listaTipos, setListaTipos] = useState(consultaTipo.data);
	const [listaCategorias, setListaCategorias] = useState(
		consultaCategoria.data,
	);
	const [listaCores, setListaCores] = useState(consultaCor.data);
	const [listaEspecies, setListaEspecies] = useState(consultaEspecie.data);
	const [listaCombustivel, setListaCombustivel] = useState(
		consultaCombustivel.data,
	);

	useEffect(() => {
		dispatch(consultarMunicipioPorUfRequest('SP'));
		dispatch(consultaCarroceriaRequest());
		dispatch(consultaTipoRequest());
		dispatch(consultaCategoriaRequest());
		dispatch(consultaCorRequest());
		dispatch(consultaEspecieRequest());
		dispatch(consultaCombustivelRequest());
	}, [dispatch]);

	useEffect(() => {
		if (consultarMunicipioPorUF.status === 200) {
			setListaMunicipios(consultarMunicipioPorUF.data);
		}
		if (consultaCarroceria.status === 200) {
			setListaCarrocerias(consultaCarroceria.data);
		}
		if (consultaTipo.status === 200) {
			setListaTipos(consultaTipo.data);
		}
		if (consultaCategoria.status === 200) {
			setListaCategorias(consultaCategoria.data);
		}
		if (consultaCor.status === 200) {
			setListaCores(consultaCor.data);
		}
		if (consultaEspecie.status === 200) {
			setListaEspecies(consultaEspecie.data);
		}
		if (consultaCombustivel.status === 200) {
			setListaCombustivel(consultaCombustivel.data);
		}
	}, [
		consultarMunicipioPorUF,
		consultaCarroceria,
		consultaTipo,
		consultaCategoria,
		consultaCor,
		consultaEspecie,
		consultaCombustivel,
	]);

	useEffect(() => {
		if (history.location.state) {
			setHistoryState(history.location.state as any);
		}
		if (primeiroEmplacamentoEmissao.status === 200) {
			history.push(ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_IMPRESSAO);
		}
	}, [primeiroEmplacamentoEmissao, history]);

	useEffect(() => {
		if (
			consultarVeiculoV120.status === 200 &&
			consultarVeiculoV120.data &&
			primeiroEmplacamento.data
		) {
			// DADOS DO VEÍCULO
			emitirPrimeiroEmplacamentoInitialValues.placa = isPersonalizada
				? primeiroEmplacamento.data?.placaPersonalizada
				: consultarVeiculoV120.placaPesquisada;
			emitirPrimeiroEmplacamentoInitialValues.codigoMunicipio =
				consultarVeiculoV120.data?.caracteristicaVeiculo.municipio.codigo === 0
					? ''
					: consultarVeiculoV120.data?.caracteristicaVeiculo.municipio.codigo;
			emitirPrimeiroEmplacamentoInitialValues.nomeMunicipio =
				consultarVeiculoV120.data?.caracteristicaVeiculo.municipio.nome;
			emitirPrimeiroEmplacamentoInitialValues.renavam =
				consultarVeiculoV120.data?.caracteristicaVeiculo.renavam;
			emitirPrimeiroEmplacamentoInitialValues.chassi =
				consultarVeiculoV120.data?.caracteristicaVeiculo?.chassi?.trim() || '';
			emitirPrimeiroEmplacamentoInitialValues.chassiRemarcado =
				consultarVeiculoV120.data?.caracteristicaVeiculo.chassiRemarcado;
			emitirPrimeiroEmplacamentoInitialValues.anoFabricacao =
				consultarVeiculoV120.data?.caracteristicaVeiculo.anoFabricacao;
			emitirPrimeiroEmplacamentoInitialValues.anoModelo =
				consultarVeiculoV120.data?.caracteristicaVeiculo.anoModelo;
			emitirPrimeiroEmplacamentoInitialValues.longIdMarca =
				consultarVeiculoV120.data?.caracteristicaVeiculo.marca.longId;
			emitirPrimeiroEmplacamentoInitialValues.descricaoMarca =
				consultarVeiculoV120.data?.caracteristicaVeiculo.marca.descricao;
			emitirPrimeiroEmplacamentoInitialValues.longIdTipo =
				consultarVeiculoV120.data?.caracteristicaVeiculo.tipo.longId;
			emitirPrimeiroEmplacamentoInitialValues.descricaoTipo =
				consultarVeiculoV120.data?.caracteristicaVeiculo.tipo.descricao;
			emitirPrimeiroEmplacamentoInitialValues.longIdCarroceria =
				consultarVeiculoV120.data?.caracteristicaVeiculo.carroceria.longId;
			emitirPrimeiroEmplacamentoInitialValues.descricaoCarroceria =
				consultarVeiculoV120.data?.caracteristicaVeiculo.carroceria.descricao;
			emitirPrimeiroEmplacamentoInitialValues.longIdCor =
				consultarVeiculoV120.data?.caracteristicaVeiculo.cor.longId;
			emitirPrimeiroEmplacamentoInitialValues.descricaoCor =
				consultarVeiculoV120.data?.caracteristicaVeiculo.cor.descricao;
			emitirPrimeiroEmplacamentoInitialValues.longIdCombustivel =
				consultarVeiculoV120.data?.caracteristicaVeiculo.combustivel.longId;
			emitirPrimeiroEmplacamentoInitialValues.descricaoCombustivel =
				consultarVeiculoV120.data?.caracteristicaVeiculo.combustivel.descricao;
			emitirPrimeiroEmplacamentoInitialValues.longIdCategoria =
				consultarVeiculoV120.categoria ? consultarVeiculoV120.categoria : '';
			emitirPrimeiroEmplacamentoInitialValues.descricaoCategoria =
				consultarVeiculoV120.data?.caracteristicaVeiculo.categoria.descricao;
			emitirPrimeiroEmplacamentoInitialValues.longIdEspecie =
				consultarVeiculoV120.data?.caracteristicaVeiculo.especie.longId;
			emitirPrimeiroEmplacamentoInitialValues.descricaoEspecie =
				consultarVeiculoV120.data?.caracteristicaVeiculo.especie.descricao;
			emitirPrimeiroEmplacamentoInitialValues.capacidadePassageiro =
				consultarVeiculoV120.data?.caracteristicaVeiculo.capacidadePassageiro;
			emitirPrimeiroEmplacamentoInitialValues.capacidadeCarga =
				consultarVeiculoV120.data?.caracteristicaVeiculo.capacidadeCarga;
			emitirPrimeiroEmplacamentoInitialValues.potencia =
				consultarVeiculoV120.data?.caracteristicaVeiculo.potencia;
			emitirPrimeiroEmplacamentoInitialValues.cilindrada =
				consultarVeiculoV120.data?.caracteristicaVeiculo.cilindrada;
			emitirPrimeiroEmplacamentoInitialValues.cmt =
				consultarVeiculoV120.data?.caracteristicaVeiculo.cmt;
			emitirPrimeiroEmplacamentoInitialValues.pbt =
				consultarVeiculoV120.data?.caracteristicaVeiculo.pbt;
			emitirPrimeiroEmplacamentoInitialValues.eixo =
				consultarVeiculoV120.data?.caracteristicaVeiculo.eixo;
			emitirPrimeiroEmplacamentoInitialValues.procedencia =
				consultarVeiculoV120.data?.caracteristicaVeiculo.procedencia;

			// GRAVAMES
			emitirPrimeiroEmplacamentoInitialValues.restricaoFinanceiraGravame =
				consultarVeiculoV120.data?.caracteristicaVeiculo.gravame.restricao;
			emitirPrimeiroEmplacamentoInitialValues.financeiraGravame =
				consultarVeiculoV120.data?.caracteristicaVeiculo.gravame.codigoFinanceira;
			emitirPrimeiroEmplacamentoInitialValues.arrendatarioFinanceiraGravame =
				consultarVeiculoV120.data?.caracteristicaVeiculo.gravame.arrendatarioFinanceira;
			emitirPrimeiroEmplacamentoInitialValues.numeroContratoGravame =
				consultarVeiculoV120.data?.caracteristicaVeiculo.gravame.numeroContrato;
			emitirPrimeiroEmplacamentoInitialValues.dataVigenciaGravame =
				consultarVeiculoV120.data?.caracteristicaVeiculo.gravame.dataVigencia;

			// PROPRIETÁRIO
			emitirPrimeiroEmplacamentoInitialValues.nomeProprietario =
				consultarVeiculoV120.data?.caracteristicaVeiculo?.dadosProprietario?.nomeProprietario?.trim() ||
				'';
			emitirPrimeiroEmplacamentoInitialValues.cepProprietario =
				consultarVeiculoV120.data?.caracteristicaVeiculo?.dadosProprietario?.cepProprietario?.trim() ||
				'';
			emitirPrimeiroEmplacamentoInitialValues.bairroProprietario =
				consultarVeiculoV120.data?.caracteristicaVeiculo?.dadosProprietario?.bairroProprietario?.trim() ||
				'';
			emitirPrimeiroEmplacamentoInitialValues.enderecoProprietario =
				consultarVeiculoV120.data?.caracteristicaVeiculo?.dadosProprietario?.enderecoProprietario?.trim() ||
				'';
			emitirPrimeiroEmplacamentoInitialValues.numeroProprietario =
				consultarVeiculoV120.data?.caracteristicaVeiculo?.dadosProprietario?.numeroLogradouroProprietario?.trim() ||
				'';
			emitirPrimeiroEmplacamentoInitialValues.complementoProprietario =
				consultarVeiculoV120.data?.caracteristicaVeiculo.dadosProprietario.complementoLogradouroProprietario;
			const selectedMunicipio = consultarMunicipioPorUF.data?.filter(
				item =>
					item.label ===
					consultarVeiculoV120.data?.caracteristicaVeiculo.dadosProprietario
						.municipioProprietario,
			);

			emitirPrimeiroEmplacamentoInitialValues.codigoMunicipioProprietario =
				selectedMunicipio?.length > 0 ? selectedMunicipio[0].value : '';
			emitirPrimeiroEmplacamentoInitialValues.nomeMunicipioProprietario =
				selectedMunicipio?.length > 0 ? selectedMunicipio[0].label : '';
			emitirPrimeiroEmplacamentoInitialValues.identificacaoProprietario =
				historyState.cpfCnpjProprietario &&
				historyState.cpfCnpjProprietario.length === 11
					? 'FISICA'
					: 'JURIDICA';
			emitirPrimeiroEmplacamentoInitialValues.cpfCnpjProprietario =
				(historyState.cpfCnpjProprietario &&
					fillCpfCnpj(
						emitirPrimeiroEmplacamentoInitialValues.identificacaoProprietario,
						Number(historyState.cpfCnpjProprietario),
					)) ||
				fillCpfCnpj(
					emitirPrimeiroEmplacamentoInitialValues.identificacaoProprietario,
					Number(primeiroEmplacamento.data?.cpfCnpjProprietario),
				);

			// TAXA
			emitirPrimeiroEmplacamentoInitialValues.codBancoTaxaPrimeiroEmplacamento =
				consultarTaxaServico.data?.codBanco || '';
			emitirPrimeiroEmplacamentoInitialValues.dataContabilTaxaPrimeiroEmplacamento =
				consultarTaxaServico.data?.dataContabil || '';
			emitirPrimeiroEmplacamentoInitialValues.nsuProdespTaxaPrimeiroEmplacamento =
				consultarTaxaServico.data?.nsuProdesp || '';
			emitirPrimeiroEmplacamentoInitialValues.codBancoTaxaPlacaAtribuida =
				saveTaxaServico.data?.codBanco || '';
			emitirPrimeiroEmplacamentoInitialValues.dataContabilTaxaPlacaAtribuida =
				saveTaxaServico.data?.dataContabil || '';
			emitirPrimeiroEmplacamentoInitialValues.nsuProdespTaxaPlacaAtribuida =
				saveTaxaServico.data?.nsuProdesp || '';
		}
	}, [
		consultarMunicipioPorUF.data,
		consultarVeiculoV120,
		historyState,
		isPersonalizada,
		primeiroEmplacamento,
		consultarTaxaServico.data,
		saveTaxaServico.data,
	]);

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado, formik: FormikProps<any>) => {
			formik.setFieldValue(`cepProprietario`, res.cep);
			// setFieldValue(`proprietario.endereco.ufProprietario`, res.uf);
			formik.setFieldValue(`enderecoProprietario`, res.endereco);
			formik.setFieldValue(`bairroProprietario`, res.bairro);
			// setFieldValue(`proprietario.endereco.cidade`, res.municipio);
		},
		[],
	);

	const handleClear = useCallback(() => {
		dispatch(consultarVeiculoV120Clear());
		dispatch(primeiroEmplacamentoClear());
		dispatch(tipoArquivoClear());
		history.push(ROUTE_DETRAN_CRV);
	}, [dispatch, history]);

	const handleSubmit = useCallback(
		(formValues: IEmitirPrimeiroEmplacamento) => {
			const headers = {
				codUnidade: evento.canal.localidade.id.toString(),
				cpfUsuario: evento.cpf,
			};
			const body = formatValues(formValues, evento);
			dispatch(primeiroEmplacamentoEmissaoRequest({ body, headers }));
		},
		[dispatch, evento],
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Formik
				enableReinitialize
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={{
					...emitirPrimeiroEmplacamentoInitialValues,
				}}
				validate={values => getValidationsErrors(values, schema)}
				onSubmit={handleSubmit}
			>
				{(formik: FormikProps<any>) => {
					return (
						<Form>
							<Row>
								<Col span={24}>
									<Section size="sm" title="Dados do Veículo">
										<Row gutter={[0, 20]}>
											<Col span={11}>
												<Placa
													title="Placa"
													titleSize="xl"
													name="placa"
													defaultValue={
														emitirPrimeiroEmplacamentoInitialValues.placa
													}
													size={92}
													disabled
													formik={formik}
												/>
											</Col>
											<Col span={11} />
										</Row>
										<Row gutter={[0, 20]}>
											<Col span={11}>
												<Field
													required
													titleSize="xl"
													as={Select}
													name="codigoMunicipio"
													title="Município"
													options={listaMunicipios}
													error={hasError(formik.errors, 'codigoMunicipio')}
													onChange={(v: string) => {
														formik.setFieldValue('codigoMunicipio', v);

														const selectedItem = listaMunicipios.filter(
															item => v === item.value,
														);

														formik.setFieldValue(
															'nomeMunicipio',
															selectedItem[0].label,
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="renavam"
													title="Renavam"
													as={Input}
													error={hasError(formik.errors, 'renavam')}
													maxLength={11}
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'renavam',
															letrasNumeros(v.target.value),
														);
													}}
												/>
											</Col>
										</Row>
										<Row gutter={[0, 20]}>
											<Col span={11}>
												<Field
													titleSize="xl"
													disabled
													name="chassi"
													title="Chassi"
													as={Input}
													error={hasError(formik.errors, 'chassi')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													as={Select}
													name="chassiRemarcado"
													title="Chassi remarcado"
													options={BooelanOptions}
													error={hasError(formik.errors, 'chassiRemarcado')}
													onChange={(v: string) => {
														formik.setFieldValue('chassiRemarcado', v);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="anoFabricacao"
													title="Ano de Fabricação"
													as={Input}
													error={hasError(formik.errors, 'anoFabricacao')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="anoModelo"
													title="Ano Modelo"
													as={Input}
													error={hasError(formik.errors, 'anoModelo')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="descricaoMarca"
													title="Marca"
													as={Input}
													error={hasError(formik.errors, 'descricaoMarca')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													as={Select}
													title="Tipo"
													options={listaTipos}
													error={hasError(formik.errors, 'longIdTipo')}
													name="longIdTipo"
													onChange={(v: string) => {
														formik.setFieldValue('longIdTipo', v);

														const selectedItem = listaTipos.filter(
															item => v === item.value,
														);

														formik.setFieldValue(
															'descricaoTipo',
															selectedItem[0].label,
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													as={Select}
													title="Carroceria"
													options={listaCarrocerias}
													error={hasError(formik.errors, 'longIdCarroceria')}
													name="longIdCarroceria"
													required
													onChange={(v: string) => {
														formik.setFieldValue('longIdCarroceria', v);
														const selectedItem = listaCarrocerias.filter(
															item => v === item.value,
														);

														formik.setFieldValue(
															'descricaoCarroceria',
															selectedItem[0].label
																? selectedItem[0].label
																: '',
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													as={Select}
													title="Cor"
													options={listaCores}
													error={hasError(formik.errors, 'longIdCor')}
													name="longIdCor"
													onChange={(v: string) => {
														formik.setFieldValue('longIdCor', v);
														const selectedItem = listaCores.filter(
															item => v === item.value,
														);

														formik.setFieldValue(
															'descricaoCor',
															selectedItem[0].label,
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													as={Select}
													title="Combustível"
													options={listaCombustivel}
													error={hasError(formik.errors, 'longIdCombustivel')}
													name="longIdCombustivel"
													onChange={(v: string) => {
														formik.setFieldValue('longIdCombustivel', v);
														const selectedItem = listaCombustivel.filter(
															item => v === item.value,
														);

														formik.setFieldValue(
															'descricaoCombustivel',
															selectedItem[0].label,
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													as={Select}
													title="Categoria"
													options={listaCategorias}
													error={hasError(formik.errors, 'longIdCategoria')}
													name="longIdCategoria"
													onChange={(v: string) => {
														formik.setFieldValue('longIdCategoria', v);

														const selectedItem = listaCategorias.filter(
															item => v === item.value,
														);

														formik.setFieldValue(
															'descricaoCategoria',
															selectedItem[0].label,
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													as={Select}
													title="Espécie"
													options={listaEspecies}
													error={hasError(formik.errors, 'longIdEspecie')}
													name="longIdEspecie"
													onChange={(v: string) => {
														formik.setFieldValue('longIdEspecie', v);

														const selectedItem = listaEspecies.filter(
															item => v === item.value,
														);

														selectedItem &&
															formik.setFieldValue(
																'descricaoEspecie',
																selectedItem[0].label,
															);
													}}
												/>
											</Col>
											<Col span={11} />
											<Col span={11}>
												<Field
													titleSize="xl"
													name="capacidadePassageiro"
													title="Capacidade Passageiro"
													as={Input}
													required
													error={hasError(
														formik.errors,
														'capacidadePassageiro',
													)}
													maxLength={3}
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'capacidadePassageiro',
															numeros(v.target.value),
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="capacidadeCarga"
													title="Capacidade Carga"
													as={Input}
													error={hasError(formik.errors, 'capacidadeCarga')}
													maxLength={6}
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'capacidadeCarga',
															numeros(v.target.value),
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="potencia"
													title="Potência"
													as={Input}
													error={hasError(formik.errors, 'potencia')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="cilindrada"
													title="Cilindrada"
													as={Input}
													error={hasError(formik.errors, 'cilindrada')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="cmt"
													title="CMT"
													as={Input}
													required
													error={hasError(formik.errors, 'cmt')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="pbt"
													title="PBT"
													as={Input}
													required
													error={hasError(formik.errors, 'pbt')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="eixo"
													title="Eixos"
													as={Input}
													error={hasError(formik.errors, 'eixo')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													as={Select}
													name="procedencia"
													title="Procedência do Veículo"
													options={data.procedencia}
													required
													error={hasError(formik.errors, 'procedencia')}
													onChange={(v: string) => {
														formik.setFieldValue(
															'identificacaoProprietario',
															v,
														);
													}}
													procedencia
												/>
											</Col>
											<Col span={11}>
												<Field
													// size={100}
													titleSize="xl"
													titleAlign="textEnd"
													name="quantidadeTanquesSuplementar"
													title="Quantidade de Tanques Suplementares"
													as={Input}
													error={hasError(
														formik.errors,
														'quantidadeTanquesSuplementar',
													)}
													maxLength={2}
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'quantidadeTanquesSuplementar',
															numeros(v.target.value),
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													titleAlign="textEnd"
													name="capacidadeVolTanquesSuplementar"
													title="Cap.Volumétrica do Tanque Suplementares"
													as={Input}
													error={hasError(
														formik.errors,
														'capacidadeVolTanquesSuplementar',
													)}
												/>
											</Col>
										</Row>
									</Section>

									<Section size="sm" title="GRAVAMES">
										<Row gutter={[0, 20]}>
											<Col span={11}>
												<Field
													titleSize="xl"
													as={Select}
													title="Restrição Financeira"
													options={data.restricaoFinanceira}
													required
													error={hasError(
														formik.errors,
														'restricaoFinanceiraGravame',
													)}
													name="restricaoFinanceiraGravame"
													onChange={(v: string) => {
														formik.setFieldValue(
															'restricaoFinanceiraGravame',
															v,
														);
													}}
												/>
											</Col>
											<Col span={11} />
											<Col span={11}>
												<Field
													titleSize="xl"
													name="financeiraGravame"
													title="Financeira"
													as={Input}
													error={hasError(formik.errors, 'financeiraGravame')}
													maxLength={4}
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'financeiraGravame',
															letrasNumeros(v.target.value),
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="arrendatarioFinanceiraGravame"
													title="Arrendatário/Financeira"
													as={Input}
													error={hasError(
														formik.errors,
														'arrendatarioFinanceiraGravame',
													)}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="numeroContratoGravame"
													title="Número do Contrato"
													as={Input}
													error={hasError(
														formik.errors,
														'numeroContratoGravame',
													)}
													maxLength={22}
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'numeroContratoGravame',
															letrasNumeros(v.target.value),
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													required
													title="Data Vigência Contrato"
													titleAlign="end"
													name="dataVigenciaGravame"
													type="text"
													mask="99/99/9999"
													subtitle="(DD/MM/AAAA)"
													as={ValidDataInput}
													error={hasError(formik.errors, 'dataVigenciaGravame')}
													formik={formik}
													onChange={(v: string) =>
														formik.setFieldValue('dataVigenciaGravame', v)
													}
												/>
											</Col>
										</Row>
									</Section>

									<Section size="sm" title="DADOS DO PROPRIETÁRIO">
										<Row gutter={[0, 20]}>
											<Col span={22}>
												<Field
													titleSize="xl"
													name="nomeProprietario"
													title="Nome do Proprietário"
													as={Input}
													required
													error={hasError(formik.errors, 'nomeProprietario')}
													maxLength={50}
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'nomeProprietario',
															letrasAcentosApostrofo(v.target.value),
														);
													}}
												/>
											</Col>
											<Col span={2} />
											<Col span={11}>
												<CEP
													title="CEP do Imóvel do Proprietário"
													titleSize="xl"
													retornoErro
													required
													name="cepProprietario"
													formik={formik}
													result={res => handleCepSelecionado(res, formik)}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="bairroProprietario"
													title="Bairro"
													required
													as={Input}
													error={hasError(formik.errors, 'bairroProprietario')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="enderecoProprietario"
													title="Endereço do Proprietário"
													required
													as={Input}
													error={hasError(
														formik.errors,
														'enderecoProprietario',
													)}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="numeroProprietario"
													title="Número"
													as={InputMask}
													mask="99999"
													maskChar=""
													alwaysShowMask={false}
													required
													onChange={(e: { target: { value: string } }) => {
														formik.setFieldValue(
															'numeroProprietario',
															onlyNumbers(e.target.value),
														);
													}}
													error={hasError(formik.errors, 'numeroProprietario')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="complementoProprietario"
													title="Complemento"
													as={Input}
													error={hasError(
														formik.errors,
														'complementoProprietario',
													)}
													maxLength={9}
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'complementoProprietario',
															letrasNumeros(v.target.value),
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="telefoneProprietario"
													title="Telefone"
													as={Input}
													maxLength={9}
													// required={formik.values.servicoDigital}
													error={hasError(
														formik.errors,
														'telefoneProprietario',
													)}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													as={Select}
													name="codigoMunicipioProprietario"
													title="Município"
													options={listaMunicipios}
													required
													error={hasError(
														formik.errors,
														'codigoMunicipioProprietario',
													)}
													onChange={(v: string) => {
														formik.setFieldValue(
															'codigoMunicipioProprietario',
															v,
														);
														const selectedItem = listaMunicipios.filter(
															item => v === item.value,
														);

														formik.setFieldValue(
															'nomeMunicipioProprietario',
															selectedItem[0].label,
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													required
													titleSize="xl"
													as={Select}
													name="identificacaoProprietario"
													title="Identificação do Contribuinte"
													options={data.identificacao}
													defaultValue={formik.values.identificacaoProprietario}
													error={hasError(
														formik.errors,
														'identificacaoProprietario',
													)}
													onChange={(v: string) => {
														formik.setFieldValue(
															'identificacaoProprietario',
															v,
														);
														formik.setFieldValue(
															'cpfCnpjProprietario',
															fillCpfCnpj(
																v,
																Number(formik.values.cpfCnpjProprietario),
															),
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													disabled
													titleSize="xl"
													maxLength={
														formik.values.identificacaoProprietario === 'FISICA'
															? 11
															: 14
													}
													name="cpfCnpjProprietario"
													title="CPF/CNPJ Proprietário"
													as={Input}
													error={hasError(formik.errors, 'cpfCnpjProprietario')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="rgProprietario"
													title="RG do Proprietário atual"
													required
													as={Input}
													error={hasError(formik.errors, 'rgProprietario')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="orgaoExpeditorProprietario"
													title="Órgão Expedidor"
													required
													as={Input}
													error={hasError(
														formik.errors,
														'orgaoExpeditorProprietario',
													)}
													maxLength={6}
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'orgaoExpeditorProprietario',
															letrasNumeros(v.target.value),
														);
													}}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													as={Select}
													name="ufProprietario"
													title="UF"
													options={data.uf}
													error={hasError(formik.errors, 'ufProprietario')}
													required
													onChange={(v: string) => {
														formik.setFieldValue('ufProprietario', v);
													}}
												/>
											</Col>

											{formik.values.servicoDigital && (
												<>
													<Col span={11}>
														<Field
															as={Input}
															title="E-mail"
															titleSize="xl"
															name="emailCidadao"
															maxLength={60}
															required={formik.values.servicoDigital}
															error={hasError(formik.errors, 'emailCidadao')}
														/>
													</Col>

													<Col span={11}>
														<Field
															as={InputMask}
															title="Telefone de Contato"
															titleSize="xl"
															name="dddTelefoneCidadao"
															mask="(99) 9999-99999"
															required={formik.values.servicoDigital}
															error={!!formik.errors.dddTelefoneCidadao}
														/>
													</Col>
												</>
											)}

											<Col span={11}>
												<Field
													titleSize="xl"
													name="nomeProprietarioAnterior"
													title="Nome do Proprietário Anterior"
													as={Input}
													required
													error={hasError(
														formik.errors,
														'nomeProprietarioAnterior',
													)}
													maxLength={40}
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'nomeProprietarioAnterior',
															letrasAcentosApostrofo(v.target.value),
														);
													}}
												/>
											</Col>
										</Row>
									</Section>

									{/* {isDigital && (
										<UploadArquivo
											formik={formik}
											evento={evento}
											tipoArquivo="1"
										/>
									)} */}
									<UploadArquivo
										formik={formik}
										evento={evento}
										tipoArquivo="1"
										sizeMB={10000000}
									/>

									<Section size="sm">
										<Row gutter={[0, 20]}>
											<Col span={11}>
												<Field
													titleSize="xl"
													name="numNotaFiscal"
													title="Número da Nota Fiscal"
													as={Input}
													required
													error={hasError(formik.errors, 'numNotaFiscal')}
													maxLength={10}
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'numNotaFiscal',
															numeros(v.target.value),
														);
													}}
												/>
											</Col>
											<Col span={11} />
											<Col span={11}>
												<Money
													name="valorNotaFiscal"
													title="Valor da Nota Fiscal"
													titleSize="xl"
													// defaultValue={initialValues.valorVenda}
													// size={92}
													formik={formik}
													required
													error={hasError(formik.errors, 'valorNotaFiscal')}
												/>
											</Col>
											<Col span={11}>
												<Field
													titleSize="xl"
													required
													title="Data Emissão da Nota Fiscal"
													name="dataEmissaoNotaFiscal"
													type="text"
													mask="99/99/9999"
													subtitle="(DD/MM/AAAA)"
													as={ValidDataInput}
													error={hasError(
														formik.errors,
														'dataEmissaoNotaFiscal',
													)}
													formik={formik}
													onChange={(v: string) =>
														formik.setFieldValue('dataEmissaoNotaFiscal', v)
													}
													titleAlign="end"
												/>
											</Col>
										</Row>
									</Section>

									{consultarTaxaServico.data && (
										<Section size="sm">
											<TaxaServico
												dataConsultarTaxaServico={consultarTaxaServico.data}
											/>
										</Section>
									)}

									<Row
										style={{ marginTop: '10px' }}
										justify="center"
										align="middle"
										gutter={[10, 10]}
									>
										<Col>
											<Button onClick={() => handleClear()}> Cancelar </Button>
										</Col>
										<Col>
											<Button type="submit"> Enviar </Button>
										</Col>
									</Row>
									<Row
										className="teste"
										style={{ marginTop: '20px' }}
										justify="center"
										align="stretch"
									>
										<Col span={11}>
											<ButtonVoltar onClick={() => handleClear()} />
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>
					);
				}}
			</Formik>
		</Suspense>
	);
};

export default PrimeiroEmplacamentoConfirmacao;
