// TYPES
import { CadastroEnderecosRequest } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/types';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { ConsultarDigitaisRequest } from 'store/modules/api/utils/consultarDigitais/types';
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';
import { ConsultaProcessoAbertoData } from 'store/modules/api/detranCnh/renachIntegrado/consultaProcessoAberto/types';
import { RelatorioAgendamentoMedicoPsicologicoRequest } from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/types';

// UTILS
import { CANAL_SISTEMA } from 'utils/constants';
import { unformatDate } from 'utils/genericFunctions';
import { FormCnhEstrangeiro } from '../CnhEstrangeiro/StatusCondutor/form';

export const treatEventoRequestData = (
	cpf: string,
	dadosAtendimento: SalvarAtendimento,
	user: IUser,
) => {
	return {
		id_atendimento:
			dadosAtendimento?.data?.idAtendimento ||
			'00000000-0000-0000-0000-000000000000',
		id_cidadao:
			dadosAtendimento?.data?.idCidadao ||
			'00000000-0000-0000-0000-000000000000',
		cpf: '',
		identificacao: cpf || '',
		ip: user?.ip || '',
		canal: {
			id: CANAL_SISTEMA.PRESENCIAL.ID,
			desc: CANAL_SISTEMA.PRESENCIAL.DESCRICAO,
			estacao: {
				id: 0,
				desc: '',
			},
			localidade: {
				id: user?.idLocal || 0,
				desc: user?.nomePosto || '',
			},
		},
	};
};

export function threatConsultarDigitaisRequest(
	dadosAtendimento: SalvarAtendimento,
	dadosPreCadastro: GerarRenachForm,
	user: IUser,
	tipoFinalidade: 'REUSO_CNH',
): ConsultarDigitaisRequest {
	return {
		evento: {
			id_atendimento: dadosAtendimento?.data?.idAtendimento || '',
			id_cidadao: dadosAtendimento?.data?.idCidadao || '',
			cpf: '',
			identificacao: dadosPreCadastro?.cpf?.toString() || '',
			ip: user?.ip || '',
			canal: {
				id: CANAL_SISTEMA.PRESENCIAL.ID,
				desc: CANAL_SISTEMA.PRESENCIAL.DESCRICAO,
				estacao: {
					id: 0,
					desc: '',
				},
				localidade: {
					id: user?.idLocal || 0,
					desc: user?.nomePosto || '',
				},
			},
		},
		idAtendimento: dadosAtendimento?.data?.idAtendimento || '',
		cpf: dadosPreCadastro?.cpf?.toString() || '',
		rgSp: '',
		dataNascimento: dadosPreCadastro?.dataNascimento
			? unformatDate(dadosPreCadastro.dataNascimento)
			: '',
		finalidade: tipoFinalidade,
		incluirPendentes: true,
	};
}

export const treatValuesCadastroEnderecoRequest = (
	values: GerarRenachForm | FormCnhEstrangeiro,
): CadastroEnderecosRequest => {
	return {
		cpf: values.cpf,
		enderecoRecebimento: {
			logradouro:
				values.enderecoRecebimentoLogradouro?.toString()?.trim() || '',
			numero: values.enderecoRecebimentoNumero?.toString()?.trim() || '',
			complemento:
				values.enderecoRecebimentoComplemento?.toString()?.trim() || '',
			bairro: values.enderecoRecebimentoBairro?.toString()?.trim() || '',
			cep: values.enderecoRecebimentoCep?.toString()?.trim() || '',
			codigoMunicipio:
				values.enderecoRecebimentoCodigoMunicipio?.toString()?.trim() || '',
			nomeMunicipio:
				values.enderecoRecebimentoMunicipio?.toString()?.trim() || '',
		},
	};
};

export const incluirRequestPayloadBaseParams = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	cpfAtendimento: string,
) => {
	const payload: IncluirRequest = {
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento: cpfAtendimento || '', // cpf do atendimento
		numeroProcesso:
			dadosAtendimento?.data?.idAtendimento ||
			'00000000-0000-0000-0000-000000000000', // id atendimento
		codigoLocalidade: (user?.posto && Number(user.posto)) || 0,
		idOrgao: 2, // Órgão DETRAN-CNH
		cpf: user.cpf, // cpf do atendente
		descricaoServico: '',
		idMotivo: 16,
		observacao: '',
	};

	return payload;
};

export const treatAgendamentoMedicoPsicologicoRequestInPesquisar = (
	consultaProcessoAbertoData: ConsultaProcessoAbertoData,
) => {
	const dataRequest: RelatorioAgendamentoMedicoPsicologicoRequest = {
		categoriaPretendida: consultaProcessoAbertoData.categoriaPretendida ?? '',
		cpfCidadao: consultaProcessoAbertoData.cpfCidadao
			? parseInt(consultaProcessoAbertoData.cpfCidadao, 10)
			: 0,
		crm: consultaProcessoAbertoData ? consultaProcessoAbertoData.crm : '',
		crp: consultaProcessoAbertoData.crp ?? '',
		dataExame:
			(consultaProcessoAbertoData.dataExame &&
				new Date(
					`${consultaProcessoAbertoData.dataExame} ${consultaProcessoAbertoData.horarioExame}`,
				).toISOString()) ||
			'',
		dataExamePsicologo:
			(consultaProcessoAbertoData.dataExamePsicologo &&
				new Date(
					`${consultaProcessoAbertoData.dataExamePsicologo} ${consultaProcessoAbertoData.horarioExamePsicologo}`,
				).toISOString()) ||
			'',
		enderecoMedico: consultaProcessoAbertoData.enderecoMedico ?? '',
		enderecoPsicologo: consultaProcessoAbertoData.enderecoPsicologo ?? '',
		horarioExame:
			(consultaProcessoAbertoData.dataExame &&
				new Date(
					`${consultaProcessoAbertoData.dataExame} ${consultaProcessoAbertoData.horarioExame}`,
				).toISOString()) ||
			'',
		horarioExamePsicologo:
			(consultaProcessoAbertoData.dataExamePsicologo &&
				new Date(
					`${consultaProcessoAbertoData.dataExamePsicologo} ${consultaProcessoAbertoData.horarioExamePsicologo}`,
				).toISOString()) ||
			'',
		nomeCidadao:
			(consultaProcessoAbertoData.nomeSocialCidadao ||
				consultaProcessoAbertoData.nomeCidadao) ??
			'',
		nomeMedico: consultaProcessoAbertoData.nomeMedico ?? '',
		nomePsicologo: consultaProcessoAbertoData.nomePsicologo ?? '',
		telefones: consultaProcessoAbertoData.telefones ?? '',
		telefonesPsicologo: consultaProcessoAbertoData.telefonesPsicologo ?? '',
		tipoProcesso: consultaProcessoAbertoData.tipoProcesso?.descricao ?? '',
		flagHabilitaAgendamentoMedico: !!(
			consultaProcessoAbertoData.dataExame &&
			consultaProcessoAbertoData.dataExame !== ''
		),
		flagHabilitaAgendamentoPsicologico: !!(
			consultaProcessoAbertoData.dataExamePsicologo &&
			consultaProcessoAbertoData.dataExamePsicologo !== ''
		),
	};

	return dataRequest;
};
