import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarContato } from './types';

export const INITIAL_STATE: CadastrarContato = {
	status: 0,
	form: { idCpf: '', idTipoContato: null, valor: '' },
};

export default function alteraEnderecoCartao(
	state = INITIAL_STATE,
	action: ReducerAction,
): CadastrarContato {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CADASTRAR_CONTRATO_REQUEST: {
				draft.form = action.payload;
				break;
			}

			case Types.CADASTRAR_CONTRATO_SUCCESS: {
				draft.status = 200;
				break;
			}

			case Types.CADASTRAR_CONTRATO_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.LIMPAR_CADASTRAR_CONTRATO: {
				draft.status = 0;
				draft.form = INITIAL_STATE.form;
				break;
			}

			default:
		}
	});
}
