import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function PDFMaker(images: string[], title: string) {
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	// const reportTitle = [
	// 	{
	// 		text: title,
	// 		fontSize: 15,
	// 		bold: true,
	// 		margin: [15, 20, 0, 45] as [number, number, number, number], // left, top, right, bottom
	// 	},
	// ];

	const content = [
		images.map((image, index) => {
			if (index === 0) {
				return [
					{ text: title, fontSize: 20, bold: true },
					{
						image,
						fit: [500, 500] as [number, number],
					},
				];
			}
			return {
				image,
				fit: [500, 500] as [number, number],
				pageBreak: 'before' as 'before',
			};
		}),
	];

	const docDefinitios = {
		// header: [reportTitle],
		content,
		pageSize: 'A4' as 'A4',
		pageMargins: [50, 30, 50, 30] as [number, number, number, number],
	};

	pdfMake.createPdf(docDefinitios).download(`${title}.pdf`);
}

export default PDFMaker;
