import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types } from './types';
import { confirmarPlacaSuccess, confirmarPlacaFailure } from './actions';

function* confirmarPlacaRequest(request: { type: string; payload: any }) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`primeiro-emplacamento/${CHANNEL}/teste/confirmar-placa`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(confirmarPlacaSuccess(response));
	} else if (response.error) {
		yield put(confirmarPlacaFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONFIRMAR_PLACA_REQUEST, confirmarPlacaRequest),
]);
