export enum Types {
	EXCLUIR_MOTIVO = '@SGU/EXCLUIR_MOTIVO',
	EXCLUIR_MOTIVO_SUCCESS = '@SGU/EXCLUIR_MOTIVO_SUCCESS',
	EXCLUIR_MOTIVO_FAILURE = '@SGU/EXCLUIR_MOTIVO_FAILURE',
	EXCLUIR_MOTIVO_CLEAR = '@SGU/EXCLUIR_MOTIVO_CLEAR',
}

export interface ExcluirMotivo {
	status: number;
	data: null | ExcluirMotivoData;
}

export interface ExcluirMotivoData {
	status: string;
	statusMensagem: string;
}

export interface ExcluirMotivoRequest {
	id?: number | string;
	codigo: number | string;
	descricao: string;
}
