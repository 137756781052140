import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirUnidadeRequest } from './types';

export function excluirUnidadeRequest(
	data: ExcluirUnidadeRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_UNIDADE,
		payload: data,
	};
}
export function excluirUnidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_UNIDADE_SUCCESS,
		payload,
	};
}
export function excluirUnidadeFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_UNIDADE_FAILURE,
		payload: null,
	};
}
export function excluirUnidadeClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_UNIDADE_CLEAR,
		payload: null,
	};
}
