import styled, { css } from 'styled-components';
import { rgba } from 'polished';

interface TitleProps {
	required: boolean;
	align?: 'start' | 'end';
	size?: 'sm' | 'md' | 'lg' | 'xl' | number | 'auto';
	asteriskDistance?: number;
}

interface ContentProps {
	isFocused: boolean;
	isErrored: boolean;
	isDisabled: boolean;
	size: number;
	isOverflow: boolean;
}

interface SubTitleProps {
	subtitleColor: 'red' | string;
}

export const Container = styled.div`
	display: flex;
	align-items: center;
	max-height: 25px !important;

	& + div {
		margin-top: 10px;
	}
`;

export const Title = styled.div<TitleProps>`
	margin-right: 10px;
	width: auto;

	${props =>
		props.size === 'sm' &&
		css`
			min-width: 80px;
		`};

	${props =>
		props.size === 'md' &&
		css`
			min-width: 120px;
		`};

	${props =>
		props.size === 'lg' &&
		css`
			min-width: 160px;
		`};

	${props =>
		props.size === 'xl' &&
		css`
			min-width: 200px;
		`};

	${props =>
		!!Number(props.size) &&
		css`
			min-width: ${`${props.size}px`};
		`};

	label {
		align-items: center;
		display: flex;
		font-size: calc(0.75rem + ${props => props.theme.fontScale}%);
		font-weight: 500;
		justify-content: flex-end;

		${props =>
			props.align === 'start' &&
			css`
				justify-content: flex-start;
			`};

		${props =>
			props.required &&
			css`
				::before {
					content: '*';
					color: ${props.theme.typography.error};
					font-weight: 700;
					left: ${props.asteriskDistance}px;
					position: relative;
				}
			`};
	}
`;

export const Content = styled.div<ContentProps>`
	display: flex;
	width: 100%;
	${props =>
		props.isOverflow &&
		css`
			overflow: hidden;
		`};

	.ant-select {
		cursor: pointer;
		color: ${props => props.theme.typography.primary};
		font-size: unset;
		width: ${props => props.size}%;

		&:hover {
			.ant-select-selector {
				background-color: ${props => props.theme.colors.background};
				border-color: ${props => props.theme.colors.border};
				box-shadow: 0px 0px 8px #85b2cb;

				${props =>
					props.isErrored &&
					css`
						border-color: rgb(204, 51, 51);
					`}

				${props =>
					props.isDisabled &&
					css`
						background-color: ${props.theme.typography.secondary};
						box-shadow: unset;
					`}
			}

			.ant-select-arrow {
				background-color: #85b2cb;

				${props =>
					props.isErrored &&
					css`
						box-shadow: unset;
					`}

				${props =>
					props.isDisabled &&
					css`
						background-color: ${props.theme.typography.secondary};
					`}
			}
		}

		.ant-select-selector {
			padding: 0px 0 0 4px !important;

			${props =>
				css`
					background-color: ${props.theme.colors.background};
				`}

			${props =>
				props.isErrored &&
				css`
					box-shadow: 0px 1px 1px 0px rgb(204, 51, 51) inset;
				`}

			${props =>
				props.isDisabled &&
				css`
					color: ${rgba(props.theme.typography.primary, 0.7)} !important;

					.ant-select-selection-item {
						background-color: #f5f5f5;
						color: rgba(0, 0, 0, 0.5) !important;
					}
				`}

			.ant-select-selection-search {
				left: 4px !important;

				input {
					margin-top: -4px !important;
				}
			}
		}

		.ant-select-arrow {
			align-items: center;
			background-color: #e6e6e6;
			border: 1px solid ${props => props.theme.colors.border};
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
			display: flex;
			height: 100%;
			margin-top: 0;
			overflow: hidden;
			right: 0px;
			top: 0px;
			width: 30px;

			${props =>
				props.isErrored &&
				css`
					background-color: #f8eeee;
					border-color: rgb(204, 51, 51);
					box-shadow: 0px 1px 1px 0px rgb(204, 51, 51) inset;
				`}

			.anticon {
				color: #3c667c;
				margin: 0 auto;

				${props =>
					props.isErrored &&
					css`
						color: rgb(204, 51, 51);
					`}
			}
		}

		.ant-select-single,
		.ant-select-selector {
			max-height: 25px !important;
		}
		.ant-select-single,
		.ant-select-selector,
		.ant-select-selection-item,
		.ant-select-single,
		.ant-select-selection-placeholder {
			line-height: unset;
			margin: auto;
		}
	}

	.ant-select-focused.ant-select-single {
		&:not(.ant-select-customize-input) {
			.ant-select-selector {
				background-color: ${props => props.theme.colors.background};
				border-color: ${props => props.theme.colors.border};
				box-shadow: unset;
			}
		}
	}

	.ant-select-single {
		&:not(.ant-select-customize-input) {
			.ant-select-selector {
				border-color: ${props => props.theme.colors.border};
				padding: 0 0 0 8px;
				transition: unset;

				${props =>
					props.isErrored &&
					css`
						border-color: rgb(204, 51, 51);
					`}
			}
		}
	}

	.ant-select-single.ant-select-show-arrow {
		.ant-select-selection-search {
			left: 8px;
			right: 30px;
		}

		.ant-select-selection-item {
			font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
			padding-right: 30px;
			text-align: left;
			text-transform: uppercase;
		}
	}
`;

export const SubTitle = styled.div<SubTitleProps>`
	align-items: center;
	display: flex;
	max-width: fit-content;
	width: 100%;

	${props =>
		props.subtitleColor === 'black' &&
		css`
			color: unset;
		`}

	${props =>
		props.subtitleColor === 'red' &&
		css`
			color: red;
		`}

	span {
		font-size: calc(0.71rem + ${props => props.theme.fontScale}%);
		letter-spacing: 0.25px;
		margin: auto 0px auto 10px;
	}
`;
