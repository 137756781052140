import React from 'react';

import { CSSProperties } from 'styled-components';

// STYLED
import { Container, Title } from './styled';

interface SectionProps {
	size?: 'sm' | 'lg';
	title?: string;
	titleSize?: 'sm' | 'lg';
	titleBold?: boolean;
	style?: CSSProperties;
	bgColor?: 'branco' | 'cinza-claro' | 'cinza-escuro';
}

const Section: React.FC<SectionProps> = ({
	size = 'lg',
	title,
	titleSize = size === 'lg' ? 'lg' : 'sm',
	titleBold = true,
	bgColor = 'branco',
	style,
	children,
}) => {
	return (
		<Container size={size} bgColor={bgColor} style={style}>
			<Title titleSize={titleSize} titleBold={titleBold}>
				{title}
			</Title>

			{children}
		</Container>
	);
};

export default Section;
