import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';

import { Row, Col, Divider, Modal } from 'antd';
import { FormikFildObserver } from 'utils/formikObserver';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import EnumCNH from 'store/modules/api/detranCnh/enum';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { cadastroEnderecosClear } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/actions';
import { consultaPaisesRequest } from 'store/modules/api/detranCnh/consulta/paises/actions';
import {
	consultaToxicologicoRequest,
	consultaToxicologicoClear,
} from 'store/modules/api/detranCnh/cidadao/consultaToxicologico/actions';
import { consultaTokenCnhDigitalClear } from 'store/modules/api/detranCnh/cnhDigital/tokenDigital/actions';
import {
	consultarDisponibilidadeMedicoPostoClear,
	consultarDisponibilidadeMedicoPostoRequest,
} from 'store/modules/api/detranCnh/postoAtendimento/consultarDisponibilidadeMedicoPosto/actions';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import { enviaSMSPositusClear } from 'store/modules/api/detranCnh/cnhDigital/smsPositus/actions';
import {
	validaTokenCnhDigitalClear,
	validaTokenCnhDigitalRequest,
} from 'store/modules/api/detranCnh/cnhDigital/validaToken/actions';
import { consultaCidadesUFRequest } from 'store/modules/api/utils/consultaCidadesUF/actions';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import { usoFormularioEnumTypes } from 'utils/constants/types';
import {
	limparMascara,
	onlyLetters,
	onlyNumbers,
	onlyNumbersAndLetters,
	onlyNumbersLettersSpace,
} from 'utils/genericFunctions';
import { IBuscarCepResultadoEndLogradouro } from 'pages/DetranCnh/components/common/Fields/CEPLogradouro/form';
import { verifyDrugTestIsValid } from 'pages/DetranCnh/utils/functions/drugTest';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import CEPLogradouro from 'pages/DetranCnh/components/common/Fields/CEPLogradouro';
import Checkbox from 'components/Common/Form/Checkbox';
import Input from 'components/Common/Form/Input/Input';
import Radio from 'components/Common/Form/Radio';
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';
import InputMask from 'components/Common/Form/Input/InputMask';
import GerarTokenSmsBox from 'pages/DetranCnh/components/common/GerarTokenSmsBox';
import EnderecoRecebimento from '../../../EnderecoRecebimento';

import {
	radioSimNao,
	chooseEnumToRenderCnhDigital,
	schemaGenerateToken,
	showComponent,
} from '../form';

// STYLE
import 'pages/DetranCnh/styles.css';

interface Props {
	renderData: GerarRenachForm;
	handleSubmit: (values: GerarRenachForm) => void;
	schema: Yup.ObjectSchema<
		Yup.Shape<GerarRenachForm | undefined, GerarRenachForm>,
		object
	>;
	setMensagensInfo: (
		values: (oldState: Array<string>) => Array<string>,
	) => void | Array<string>;
}

const DadosPreCadastro: React.FC<Props> = ({
	renderData,
	handleSubmit,
	schema,
	setMensagensInfo,
}) => {
	const dispatch = useDispatch();
	const formikRef = useRef<FormikProps<GerarRenachForm>>(null);

	const [modalInfo, setModalInfo] = useState({
		type: '',
		message: '',
	});
	const [fixedNotification, setFixedNotification] = useState(false);
	const [disableExamePosto, setDisableExamePosto] = useState(false);
	const [formValues, setFormValues] = useState(renderData);

	const listaPaises = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.consulta.consultaPaises.data,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { consultarDisponibilidadeMedicoPosto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.postoAtendimento,
	);

	const { consultaCategoria } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.consulta,
	);

	const { validaTokenCnhDigital } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cnhDigital,
	);

	const nomeSocialFiliacao = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.condutor.consultaNomeSocialFiliacao.data,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	const { consultaToxicologico } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cidadao,
	);

	const { consultaCidadesUF } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const setValueToFormikUsingRef = (nameInput: string, value: string) => {
		if (formikRef.current && nameInput && (value || value === '')) {
			formikRef.current.setFieldValue(nameInput, value);
		}
	};

	useEffect(() => {
		dispatch(cadastroEnderecosClear());
		dispatch(consultarDisponibilidadeMedicoPostoClear());
		dispatch(validaTokenCnhDigitalClear());
		dispatch(consultaPaisesRequest());
		dispatch(enviaSMSPositusClear());
		dispatch(consultaTokenCnhDigitalClear());

		if (renderData !== null) {
			if (
				renderData.tipoDocumentoEnum === 'MUDANCA_CATEGORIA' ||
				renderData.tipoDocumentoEnum === 'ADICAO_CATEGORIA' ||
				renderData.tipoDocumentoEnum === 'RENOVACAO' ||
				renderData.usoFormularioEnum === 'MUDANCA_CATEGORIA' ||
				renderData.usoFormularioEnum === 'ADICAO_CATEGORIA' ||
				renderData.usoFormularioEnum === 'RENOVACAO'
			) {
				dispatch(
					consultarDisponibilidadeMedicoPostoRequest({
						codigoPosto: user.posto,
					}),
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			(consultarDisponibilidadeMedicoPosto.status === 200 ||
				consultarDisponibilidadeMedicoPosto.status === 201) &&
			consultarDisponibilidadeMedicoPosto.data
		) {
			const thereIsDoctorAtPost = !!(
				consultarDisponibilidadeMedicoPosto.data &&
				consultarDisponibilidadeMedicoPosto.data.realizaExameMedicoUnidade ===
					'S'
			);

			if (
				consultaToxicologico.data &&
				consultaToxicologico.data.dataColeta &&
				showComponent(['RENOVACAO'], renderData.usoFormularioEnum)
			) {
				const currentDate = new Date();
				const cnhDate = new Date(
					consultaToxicologico.data.dataLimiteExameMedico,
				);
				const diffTime = cnhDate.getTime() - currentDate.getTime();
				const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));
				if (diffDays <= 15 && thereIsDoctorAtPost) {
					setDisableExamePosto(true);
					setValueToFormikUsingRef('flagExamePosto', 'S');
				} else if (diffDays <= 15 && !thereIsDoctorAtPost) {
					setDisableExamePosto(true);
					setValueToFormikUsingRef('flagExamePosto', 'N');
				} else if (diffDays > 15 && thereIsDoctorAtPost) {
					setDisableExamePosto(false);
					setValueToFormikUsingRef('flagExamePosto', '');
				} else if (diffDays > 15 && !thereIsDoctorAtPost) {
					setDisableExamePosto(true);
					setValueToFormikUsingRef('flagExamePosto', 'N');
				}
			} else if (thereIsDoctorAtPost) {
				setDisableExamePosto(false);
				setValueToFormikUsingRef('flagExamePosto', '');
			} else if (!thereIsDoctorAtPost) {
				setDisableExamePosto(true);
				setValueToFormikUsingRef('flagExamePosto', 'N');
			}
		} else if (consultarDisponibilidadeMedicoPosto.status === 400) {
			setDisableExamePosto(true);
			setValueToFormikUsingRef('flagExamePosto', 'N');
		}
	}, [consultarDisponibilidadeMedicoPosto, consultaToxicologico, renderData]);

	const getStatusDrugTest = useCallback(() => {
		if (
			renderData.cpf &&
			consultaToxicologico.status === 0 &&
			!showComponent(
				['SEGUNDA_VIA', 'CNH_DEFINITIVA'],
				renderData.usoFormularioEnum,
			) &&
			!showComponent(
				['A', 'B', 'AB', 'X', 'XB', ''],
				renderData.categoriaPretendida === ''
					? renderData.categoriaAtual
					: renderData.categoriaPretendida || '',
			)
		) {
			dispatch(consultaToxicologicoRequest(renderData.cpf));
		}
	}, [
		consultaToxicologico.status,
		dispatch,
		renderData.categoriaAtual,
		renderData.categoriaPretendida,
		renderData.cpf,
		renderData.usoFormularioEnum,
	]);

	const handleFormChange = useCallback(() => {
		if (errors.length > 0 && !fixedNotification) {
			dispatch(clearNotifications());
		}
	}, [dispatch, errors.length, fixedNotification]);

	const handleCleanModalInfo = () => {
		if (modalInfo.type === 'success') {
			handleSubmit(formValues);
			setModalInfo({
				type: '',
				message: '',
			});
		} else {
			setModalInfo({
				type: '',
				message: '',
			});
		}
	};

	const handleCepSelecionado = useCallback(
		(
			res: IBuscarCepResultadoEndLogradouro,
			formik: FormikProps<GerarRenachForm>,
		) => {
			formik.setFieldValue(`logradouroCep`, res.cep);
			formik.setFieldValue(
				`logradouro`,
				onlyNumbersLettersSpace(res.endereco.substring(0, 30)),
			);
			formik.setFieldValue(
				`logradouroBairro`,
				onlyNumbersLettersSpace(res.bairro.substring(0, 13)),
			);
			formik.setFieldValue(`logradouroCodMunicipio`, res.codigoMunicipio);
			formik.setFieldValue(`logradouroDescMunicipio`, res.municipio);
			formik.setFieldValue('logradouroUf', res.uf);
			formik.setFieldValue(`codCiretranHabilitacao`, res.codigoUnidadeTransito);
			formik.setFieldValue(`codCiretranCondutor`, res.codigoUnidadeTransito);
		},
		[],
	);

	const handleNaturalidade = useCallback(
		(uf: string) => {
			if (uf && uf !== '') {
				dispatch(consultaCidadesUFRequest(uf));
			}
		},
		[dispatch],
	);

	const habilitaNomeSocial = (
		formik: FormikProps<GerarRenachForm>,
		valueFlag: React.ChangeEvent<HTMLInputElement>,
	) => {
		formik.setFieldValue('flagNomeSocial', valueFlag.target.value);

		if (valueFlag.target.value === 'N' && renderData.nomeSocial === '') {
			formik.setFieldValue('nomeSocial', '');
		}
	};

	const habilitaFiliacaoAfetiva = (
		formik: FormikProps<GerarRenachForm>,
		valueFlag: React.ChangeEvent<HTMLInputElement>,
	) => {
		formik.setFieldValue('flagFiliacaoAfetiva', valueFlag.target.value);

		if (valueFlag.target.value === 'N' && renderData.paisAfetivo1 === '') {
			formik.setFieldValue('paisAfetivo1', '');
		}

		if (valueFlag.target.value === 'N' && renderData.paisAfetivo2 === '') {
			formik.setFieldValue('paisAfetivo2', '');
		}
	};

	const handleChangeNationality = useCallback(
		(formik: FormikProps<GerarRenachForm>, value: string) => {
			formik.setFieldValue('nacionalidade', value);

			if (value.toString() === '1') {
				formik.setFieldValue('rneUf', '');
				formik.setFieldValue('codigoNaturalidade', '');
			} else {
				formik.setFieldValue('rneNumero', '');
				formik.setFieldValue('rneOrgao', '');
				formik.setFieldValue('rneUf', 'EX');
				formik.setFieldValue('uf', '');
				formik.setFieldValue('codigoNaturalidade', '');
			}
		},
		[],
	);

	const validateFieldsGenerateToken = async (
		formik: FormikProps<GerarRenachForm>,
	) => {
		if (
			formik.values.autorizaEmail !== 'S' ||
			formik.values.autorizaSMS !== 'S'
		) {
			setMensagensInfo(() => {
				return [
					'Obrigatório o preenchimento da autorização de envio de SMS e E-mail para validação do código.',
				];
			});
			window.scrollTo(0, 0);
			return false;
		}

		if (
			Object.keys(
				await getValidationsErrors(formik.values, schemaGenerateToken),
			).length === 0
		) {
			return true;
		}

		return false;
	};

	const validateToken = (values: GerarRenachForm) => {
		if (values.autorizaEmail !== 'S' || values.autorizaSMS !== 'S') {
			setMensagensInfo(() => {
				return [
					'Obrigatório o preenchimento da autorização de envio de SMS e E-mail para validação do código.',
				];
			});
			window.scrollTo(0, 0);
			return;
		}
		if (values.codigoVerificador === '') {
			setMensagensInfo(() => {
				return ['Campo obrigatório: Código verificador.'];
			});
			window.scrollTo(0, 0);
			return;
		}
		const data = {
			cpf: values.cpf,
			ddd: limparMascara(values.celular).substring(0, 2),
			celular: limparMascara(values.celular).substring(2, 11),
			email: values.email ? values.email : '',
			token: values.codigoVerificador ? values.codigoVerificador : '',
		};
		setFormValues(values);
		dispatch(validaTokenCnhDigitalRequest(data));
	};

	useEffect(() => {
		if (
			validaTokenCnhDigital.status === 200 &&
			validaTokenCnhDigital.data?.valido
		) {
			setModalInfo({
				type: 'success',
				message: 'O código verificador informado confere com o código gerado.',
			});
			window.scrollTo(0, 0);
		} else if (
			validaTokenCnhDigital.status === 200 &&
			validaTokenCnhDigital.data?.valido === false
		) {
			setModalInfo({
				type: 'alert',
				message: 'Código verificador inválido.',
			});
			window.scrollTo(0, 0);
		}
	}, [dispatch, validaTokenCnhDigital]);

	const verifyDrugTest = useCallback(
		(flagToxicologico: string) => {
			const valid = true;
			const invalid = false;

			if (flagToxicologico === 'S') {
				getStatusDrugTest();
			}

			const drugTestStatusData =
				(flagToxicologico &&
					verifyDrugTestIsValid(flagToxicologico, consultaToxicologico)) ||
				null;

			if (flagToxicologico && !drugTestStatusData?.valid) {
				if (
					drugTestStatusData?.message &&
					notifications.errors[0] !== drugTestStatusData.message
				) {
					setFixedNotification(true);
					addNotifications({
						errors: [drugTestStatusData.message],
						moveToTop: true,
					});
				}

				return invalid;
			}

			if (flagToxicologico && drugTestStatusData?.valid) {
				setFixedNotification(false);
				return valid;
			}

			return invalid;
		},
		[consultaToxicologico, getStatusDrugTest, notifications.errors],
	);

	const renderButton = useCallback(
		(formik: FormikProps<GerarRenachForm>) => {
			const flagToxicologico =
				(formik.values.flagExameToxicologico === true && 'S') ||
				(formik.values.flagExameToxicologico === 'S' && 'S') ||
				(formik.values.flagExameToxicologico === false && 'N') ||
				(formik.values.flagExameToxicologico === 'N' && 'N') ||
				'';

			if (
				((!showComponent(
					[
						usoFormularioEnumTypes.MUDANCA_CATEGORIA,
						usoFormularioEnumTypes.PERMISSIONARIO_PONTUADO,
					],
					renderData.usoFormularioEnum,
				) &&
					!showComponent(
						['A', 'B', 'AB', 'X', 'XB', ''],
						renderData.categoriaPretendida === ''
							? formik.values.categoriaPretendida
							: renderData.categoriaPretendida || '',
					)) ||
					(showComponent(
						[
							usoFormularioEnumTypes.MUDANCA_CATEGORIA,
							usoFormularioEnumTypes.PERMISSIONARIO_PONTUADO,
						],
						renderData.usoFormularioEnum,
					) &&
						!showComponent(
							['A', 'B', 'AB', 'X', 'XB', ''],
							formik.values.categoriaPretendida || '',
						))) &&
				flagToxicologico !== '' &&
				!verifyDrugTestIsValid(flagToxicologico, consultaToxicologico).valid
			) {
				return (
					<ButtonImage
						type="button"
						src="prosseguir"
						onClick={() => {
							window.scrollTo(0, 0);
						}}
					/>
				);
			}
			if (renderData.usoFormularioEnum !== 'RENOVACAO') {
				return <ButtonImage type="submit" src="salvar" />;
			}
			return <ButtonImage type="submit" src="prosseguir" />;
		},
		[
			consultaToxicologico,
			renderData.categoriaPretendida,
			renderData.usoFormularioEnum,
		],
	);

	return (
		<>
			<Formik
				innerRef={formikRef}
				enableReinitialize
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={renderData}
				validate={values =>
					getValidationsErrors(values, schema, ['email', 'confirmaEmail'])
				}
				onSubmit={values =>
					values.flagSolicitaCNHDigital === 'S'
						? validateToken(values)
						: handleSubmit(values)
				}
			>
				{formik => (
					<>
						<Form autoComplete="off">
							{renderData.usoFormularioEnum !== 'reabilitacao' && (
								<Section title="Dados do Pré-Cadastro">
									<Row gutter={[0, 10]}>
										<Col span={24}>
											<p>
												<strong>
													Atualize as informações abaixo e em seguida clique em
													&quot;
													{renderData.usoFormularioEnum === 'RENOVACAO'
														? 'Prosseguir'
														: 'Salvar'}
													&quot;:
												</strong>
											</p>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={24} />
									</Row>

									{(renderData.usoFormularioEnum === 'MUDANCA_CATEGORIA' ||
										renderData.usoFormularioEnum === 'PERMISSIONARIO') && (
										<Row gutter={[0, 20]}>
											<Col span={12}>
												<Field
													as={Select}
													title="Categoria Pretendida"
													name="categoriaPretendida"
													options={consultaCategoria.data}
													onChange={(v: string) => {
														formik.setFieldValue('categoriaPretendida', v);

														if (
															!showComponent(['A', 'B', 'AB', 'X', 'XB', ''], v)
														) {
															dispatch(
																consultaToxicologicoRequest(formik.values.cpf),
															);
														} else {
															dispatch(consultaToxicologicoClear());
														}
													}}
													error={hasError(formik.errors, 'categoriaPretendida')}
												/>
											</Col>
										</Row>
									)}

									<Row gutter={[0, 20]} style={{ marginLeft: '130px' }}>
										<Col span={8}>
											<Field
												as={Radio}
												title="Já exerce ou irá exercer atividade remunerada"
												name="flagAtividadeRemunerada"
												options={radioSimNao}
												titleQuestion
												directionRow
												titleSize="auto"
												error={hasError(
													formik.errors,
													'flagAtividadeRemunerada',
												)}
											/>
										</Col>
									</Row>
									{!showComponent(
										['SEGUNDA_VIA', 'CNH_DEFINITIVA'],
										renderData.usoFormularioEnum,
									) && (
										<Row gutter={[0, 20]} style={{ marginLeft: '130px' }}>
											{!showComponent(
												['A', 'B', 'AB', 'X', 'XB', ''],
												formik.values.categoriaPretendida !== ''
													? formik.values.categoriaPretendida
													: renderData.categoriaPretendida || '',
											) ? (
												<>
													<Col span={8}>
														<Field
															as={Radio}
															title="Exame dentro do posto"
															name="flagExamePosto"
															options={radioSimNao}
															titleQuestion
															directionRow
															disabled={
																disableExamePosto ||
																renderData.usoFormularioEnum === 'CASSACAO' ||
																renderData.usoFormularioEnum ===
																	usoFormularioEnumTypes.PERMISSIONARIO_PONTUADO
															}
															titleSize="auto"
															error={hasError(formik.errors, 'flagExamePosto')}
														/>
													</Col>

													<Col offset={3} span={8}>
														<Field
															as={Radio}
															title="Realizou exames Toxicológicos"
															name="flagExameToxicologico"
															options={radioSimNao}
															titleQuestion
															directionRow
															titleSize="auto"
															onChange={(e: ChangeEvent<HTMLInputElement>) => {
																verifyDrugTest(e.target.value);
																formik.setFieldValue(
																	'flagExameToxicologico',
																	e.target.value,
																);
															}}
															error={hasError(
																formik.errors,
																'flagExameToxicologico',
															)}
														/>
													</Col>
												</>
											) : (
												<Col span={8}>
													<Field
														as={Radio}
														title="Exame dentro do posto"
														name="flagExamePosto"
														options={radioSimNao}
														titleQuestion
														directionRow
														disabled={
															disableExamePosto ||
															renderData.usoFormularioEnum === 'CASSACAO' ||
															renderData.usoFormularioEnum ===
																usoFormularioEnumTypes.PERMISSIONARIO_PONTUADO
														}
														titleSize="auto"
														error={hasError(formik.errors, 'flagExamePosto')}
													/>
												</Col>
											)}
										</Row>
									)}

									{renderData.usoFormularioEnum === 'CASSACAO' ||
									renderData.usoFormularioEnum ===
										usoFormularioEnumTypes.PERMISSIONARIO_PONTUADO ||
									renderData.usoFormularioEnum === 'MUDANCA_CATEGORIA' ? (
										<Row gutter={[0, 10]} style={{ marginLeft: '130px' }}>
											<Col span={8}>
												<Field
													as={Radio}
													title="É militar e vai utilizar o CFC da corporação"
													name="flagMilitar"
													options={radioSimNao}
													defaultValue={renderData.flagMilitar}
													titleQuestion
													directionRow
													titleSize="auto"
												/>
											</Col>
										</Row>
									) : (
										''
									)}

									{formik.values.flagMilitar === 'S' && (
										<>
											<Row gutter={[0, 20]}>
												<Col span={12}>
													<Field
														as={Select}
														title="Nome da entidade Militar"
														name="codEntidadeMilitar"
														maxLength="45"
														options={EnumCNH.entidadeMilitarEnum}
														onChange={(v: string) =>
															formik.setFieldValue('codEntidadeMilitar', v)
														}
														defaultFirstOption={false}
														error={hasError(
															formik.errors,
															'codEntidadeMilitar',
														)}
													/>
												</Col>
												<Col offset={1} span={11}>
													<Field
														as={Input}
														title="Nº Carteira Militar"
														name="numeroIdentMilitar"
														maxLength="11"
														onChange={(
															event: ChangeEvent<HTMLInputElement>,
														) => {
															formik.setFieldValue(
																'numeroIdentMilitar',
																onlyNumbers(
																	event.target.value,
																).toLocaleUpperCase(),
															);
														}}
														error={hasError(
															formik.errors,
															'numeroIdentMilitar',
														)}
													/>
												</Col>
											</Row>
										</>
									)}

									<Row gutter={[0, 20]}>
										<Col span={12}>
											<Field
												as={Input}
												title="Nome"
												name="nome"
												maxLength="45"
												value={onlyLetters(formik.values.nome)}
												error={hasError(formik.errors, 'nome')}
											/>
										</Col>
										<Col offset={1} span={11}>
											<b>Deseja utilizar o nome social na CNH?</b>
										</Col>
									</Row>
									<Row gutter={[0, 20]}>
										<Col span={12}>
											<Field
												as={Input}
												title="Nome Social"
												name="nomeSocial"
												maxLength="45"
												value={onlyLetters(formik.values.nomeSocial || '')}
												disabled={formik.values.flagNomeSocial !== 'S'}
												error={!!formik.errors.nomeSocial}
											/>
										</Col>
										<Col offset={1} span={11}>
											<Field
												as={Radio}
												name="flagNomeSocial"
												options={radioSimNao}
												onChange={(
													valueFlag: React.ChangeEvent<HTMLInputElement>,
												) => habilitaNomeSocial(formik, valueFlag)}
												error={!!formik.errors.flagNomeSocial}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={6}>
											<Field
												as={Input}
												title="CPF"
												name="cpf"
												disabled
												error={hasError(formik.errors, 'cpf')}
											/>
										</Col>
										<Col offset={1} span={5}>
											<p>(Digite apenas números)</p>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={6}>
											<Field
												as={Input}
												title="RG"
												name="numDocumento"
												maxLength="20"
												onChange={(event: ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'numDocumento',
														onlyNumbersAndLetters(
															event.target.value,
														).toLocaleUpperCase(),
													);
												}}
												error={hasError(formik.errors, 'numDocumento')}
											/>
										</Col>
										<Col offset={1} span={5}>
											<p>(Não inclua o DÍGITO nem digite pontos ou hífen)</p>
										</Col>
										<Col offset={1} span={5}>
											<Field
												as={Input}
												title="Órgão Emissor"
												name="orgaoExpDocumento"
												maxLength="10"
												value={onlyLetters(
													formik.values.orgaoExpDocumento || '',
												)}
												error={!!formik.errors.orgaoExpDocumento}
											/>
										</Col>
										<Col offset={1} span={5}>
											<Field
												as={Select}
												title="UF"
												name="orgaoUfExpDocumento"
												titleSize="sm"
												options={EnumCNH.ufEnum}
												onChange={(v: number) =>
													formik.setFieldValue('orgaoUfExpDocumento', v)
												}
												error={!!formik.errors.orgaoUfExpDocumento}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={6}>
											<Field
												as={Input}
												title="RNE"
												name="rneNumero"
												maxLength="10"
												onChange={(event: ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'rneNumero',
														onlyNumbersAndLetters(
															event.target.value,
														).toLocaleUpperCase(),
													);
												}}
												disabled={formik.values.nacionalidade === '1'}
												error={hasError(formik.errors, 'rneNumero')}
											/>
										</Col>
										<Col offset={1} span={5}>
											<p>(NÃO digite pontos nem hífen)</p>
										</Col>
										<Col offset={1} span={5}>
											<Field
												as={Input}
												title="Órgão Emissor"
												name="rneOrgao"
												maxLength="10"
												value={onlyLetters(formik.values.rneOrgao || '')}
												disabled={formik.values.nacionalidade === '1'}
												error={hasError(formik.errors, 'rneOrgao')}
											/>
										</Col>
										<Col offset={1} span={5}>
											<Field
												as={Input}
												title="UF"
												titleSize="sm"
												name="rneUf"
												maxLength="2"
												disabled={formik.values.nacionalidade === '1'}
												error={hasError(formik.errors, 'rneUf')}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={13}>
											<Field
												as={Input}
												title="Nome do Pai"
												name="nomePai"
												maxLength="45"
												value={onlyLetters(formik.values.nomePai)}
												error={hasError(formik.errors, 'nomePai')}
											/>
										</Col>
										<Col offset={1} span={10} />
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={13}>
											<Field
												as={Input}
												title="Nome da Mãe"
												name="nomeMae"
												maxLength="45"
												value={onlyLetters(formik.values.nomeMae)}
												error={hasError(formik.errors, 'nomeMae')}
											/>
										</Col>
										<Col offset={1} span={10} />
									</Row>
									<Row gutter={[0, 20]}>
										<Col span={12}>
											<Field
												as={Input}
												title="Filiação Afetiva 1"
												name="paisAfetivo1"
												maxLength="45"
												value={onlyLetters(formik.values.paisAfetivo1 || '')}
												disabled={
													formik.values.flagFiliacaoAfetiva !== 'S' ||
													(nomeSocialFiliacao?.filiacao1 &&
														nomeSocialFiliacao.filiacao1.trim() !== '')
												}
												error={!!formik.errors.paisAfetivo1}
											/>
										</Col>
										<Col offset={1} span={11}>
											<b>Deseja incluir filiação afetiva na CNH?</b>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={12}>
											<Field
												as={Input}
												title="Filiação Afetiva 2"
												name="paisAfetivo2"
												maxLength="45"
												value={onlyLetters(formik.values.paisAfetivo2 || '')}
												disabled={
													formik.values.flagFiliacaoAfetiva !== 'S' ||
													(nomeSocialFiliacao?.filiacao2 &&
														nomeSocialFiliacao.filiacao2.trim() !== '')
												}
												error={!!formik.errors.paisAfetivo2}
											/>
										</Col>
										<Col offset={1} span={11}>
											<Field
												as={Radio}
												name="flagFiliacaoAfetiva"
												options={radioSimNao}
												onChange={(
													valueFlag: React.ChangeEvent<HTMLInputElement>,
												) => habilitaFiliacaoAfetiva(formik, valueFlag)}
												error={!!formik.errors.flagNomeSocial}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={12}>
											<Field
												as={Select}
												title="Sexo"
												name="sexo"
												options={EnumCNH.sexoEnum}
												onChange={(v: string) =>
													formik.setFieldValue('sexo', v)
												}
												defaultFirstOption={false}
												error={hasError(formik.errors, 'sexo')}
											/>
										</Col>
										<Col offset={1} span={11}>
											<Field
												as={InputMask}
												title="Data de Nascimento"
												name="dataNascimento"
												mask="99/99/9999"
												subtitle="(DD/MM/AAAA)"
												error={hasError(formik.errors, 'dataNascimento')}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={12}>
											<Field
												as={Select}
												title="Nacionalidade"
												name="nacionalidade"
												options={EnumCNH.nacionalidadeEnum}
												onChange={(v: string) =>
													handleChangeNationality(formik, v)
												}
												defaultFirstOption={false}
												error={hasError(formik.errors, 'nacionalidade')}
											/>
										</Col>
										<Col offset={1} span={11} />
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={12}>
											{formik.values.nacionalidade === '1' ? (
												<Field
													as={Select}
													title="UF"
													name="uf"
													options={EnumCNH.ufEnum}
													onChange={(v: string) => {
														formik.setFieldValue('uf', v);
														formik.setFieldValue('codigoNaturalidade', '');
														handleNaturalidade(v);
													}}
													error={hasError(formik.errors, 'uf')}
												/>
											) : (
												<Field
													as={Select}
													title="Naturalidade"
													name="codigoNaturalidade"
													options={listaPaises}
													onChange={(v: number) => {
														formik.setFieldValue('codigoNaturalidade', v);
													}}
													error={hasError(formik.errors, 'codigoNaturalidade')}
												/>
											)}
										</Col>
										<Col offset={1} span={11}>
											{formik.values.nacionalidade === '1' && (
												<Field
													as={Select}
													title="Naturalidade"
													name="codigoNaturalidade"
													options={consultaCidadesUF.data || []}
													onChange={(v: number) => {
														formik.setFieldValue('codigoNaturalidade', v);
													}}
													error={hasError(formik.errors, 'codigoNaturalidade')}
												/>
											)}
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={12}>
											<CEPLogradouro
												name="logradouroCep"
												defaultValue={renderData.logradouroCep}
												formik={formik}
												resultEndLogradouro={(
													res: IBuscarCepResultadoEndLogradouro,
												) => handleCepSelecionado(res, formik)}
											/>
										</Col>
										<Col offset={1} span={11}>
											<Field
												as={Input}
												title="Logradouro"
												name="logradouro"
												maxLength="30"
												onChange={(event: ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'logradouro',
														onlyNumbersLettersSpace(event.target.value) || '',
													);
												}}
												error={hasError(formik.errors, 'logradouro')}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={8}>
											<Field
												as={Input}
												title="Número"
												name="logradouroNumero"
												maxLength="6"
												onChange={(event: ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'logradouroNumero',
														onlyNumbers(event.target.value) || '',
													);
												}}
												disabled={
													formik.values.logradouroNumero === '000000' ||
													formik.values.logradouroNumero?.trim() === 'S/N'
												}
												error={!!formik.errors.logradouroNumero}
											/>
										</Col>
										<Col offset={1} span={3}>
											<Field
												subtitle="S/N"
												name="logradouroNumero"
												as={Checkbox}
												checked={
													formik.values.logradouroNumero === '000000' ||
													formik.values.logradouroNumero?.trim() === 'S/N'
												}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													const { checked } = e.target;

													formik.setFieldValue(
														'logradouroNumero',
														checked ? '000000' : '',
													);
												}}
											/>
										</Col>
										<Col offset={1} span={11}>
											<Field
												as={Input}
												title="Complemento"
												name="logradouroComplemento"
												maxLength="20"
												onChange={(event: ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'logradouroComplemento',
														onlyNumbersLettersSpace(event.target.value) || '',
													);
												}}
												error={hasError(formik.errors, 'logradouroComplemento')}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={12}>
											<Field
												as={Input}
												title="Bairro"
												name="logradouroBairro"
												maxLength="14"
												onChange={(event: ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'logradouroBairro',
														onlyNumbersLettersSpace(event.target.value) || '',
													);
												}}
												error={hasError(formik.errors, 'logradouroBairro')}
											/>
										</Col>
										<Col offset={1} span={11}>
											<Field
												as={Input}
												title="Município"
												name="logradouroDescMunicipio"
												disabled
												onChange={(event: ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'logradouroDescMunicipio',
														onlyNumbersLettersSpace(event.target.value) || '',
													);
												}}
												error={hasError(
													formik.errors,
													'logradouroDescMunicipio',
												)}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={12}>
											<Field
												as={InputMask}
												title="Telefone"
												name="telefone"
												mask="(99)9999-9999"
												error={hasError(formik.errors, 'telefone')}
											/>
										</Col>
										<Col offset={1} span={11}>
											<Field
												as={Input}
												title="E-mail"
												name="email"
												maxLength="60"
												onChange={(v: ChangeEvent<HTMLInputElement>) =>
													formik.setFieldValue(
														'email',
														v.target.value.toUpperCase(),
													)
												}
												error={hasError(formik.errors, 'email')}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={12}>
											<Field
												as={InputMask}
												title="Celular"
												name="celular"
												mask="(99)99999-9999"
												error={hasError(formik.errors, 'celular')}
											/>
										</Col>
										<Col offset={1} span={11}>
											<Field
												as={Input}
												title="Confirmar e-mail"
												name="confirmaEmail"
												maxLength="60"
												onChange={(v: ChangeEvent<HTMLInputElement>) =>
													formik.setFieldValue(
														'confirmaEmail',
														v.target.value.toUpperCase(),
													)
												}
												error={hasError(formik.errors, 'confirmaEmail')}
											/>
										</Col>
									</Row>

									{renderData.usoFormularioEnum === 'mudancaCategoria' && (
										<Row gutter={[0, 20]} style={{ marginLeft: '127px' }}>
											<Col span={24}>
												<Field
													as={Select}
													title="Categoria Pretendida"
													name="categoriaPretendida"
													options={EnumCNH.categorias}
													onChange={(v: number) =>
														formik.setFieldValue('categoriaPretendida', v)
													}
													error={hasError(formik.errors, 'categoriaPretendida')}
												/>
											</Col>
										</Row>
									)}
									{renderData.usoFormularioEnum === 'adicaoCategoria' && (
										<Row gutter={[0, 20]} style={{ marginLeft: '130px' }}>
											<Col span={24}>
												<strong style={{ opacity: 0.8 }}>
													Categoria Pretendida:&nbsp;
												</strong>
												<span>{formik.values.categoriaPretendida}</span>
											</Col>
										</Row>
									)}

									<Row gutter={[0, 20]} style={{ marginLeft: '130px' }}>
										<Col>
											<Field
												subtitle="Autorizo o envio de SMS pelo DETRAN"
												name="autorizaSMS"
												as={Checkbox}
												checked={formik.values.autorizaSMS === 'S'}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													const { checked } = e.target;

													formik.setFieldValue(
														'autorizaSMS',
														checked ? 'S' : 'N',
													);
												}}
												error={hasError(formik.errors, 'autorizaSMS')}
											/>
										</Col>
										<Col offset={1}>
											<Field
												subtitle="Autorizo o envio de E-mail pelo DETRAN"
												name="autorizaEmail"
												as={Checkbox}
												checked={formik.values.autorizaEmail === 'S'}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													const { checked } = e.target;
													formik.setFieldValue(
														'autorizaEmail',
														checked ? 'S' : 'N',
													);
												}}
												error={hasError(formik.errors, 'autorizaEmail')}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]} style={{ marginLeft: '130px' }}>
										<Col>
											<Field
												as={Radio}
												name="flagSolicitaCNHDigital"
												options={chooseEnumToRenderCnhDigital(
													renderData.usoFormularioEnum,
												)}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													const { value } = e.target;

													formik.setFieldValue('flagSolicitaCNHDigital', value);
												}}
												titleQuestion
												directionRow
												titleSize="auto"
												error={!!formik.errors.flagSolicitaCNHDigital}
											/>
										</Col>
									</Row>

									{formik.values.flagSolicitaCNHDigital === 'S' && (
										<>
											<Divider
												style={{ marginTop: 0, backgroundColor: '#000' }}
											/>
											<GerarTokenSmsBox
												codigoPais={renderData.nacionalidade}
												cpf={formik.values.cpf}
												ddd={limparMascara(formik.values.celular).substring(
													0,
													2,
												)}
												celular={limparMascara(formik.values.celular).substring(
													2,
													11,
												)}
												email={formik.values.email || ''}
												tokenValue={(code: string) =>
													formik.setFieldValue('codigoVerificador', code)
												}
												externalValidation={() =>
													validateFieldsGenerateToken(formik)
												}
												setMensagensInfo={setMensagensInfo}
											/>
										</>
									)}
								</Section>
							)}
							{showComponent(
								[
									'CASSACAO',
									'reabilitacao',
									'adicaoCategoria',
									'MUDANCA_CATEGORIA',
									'permissaoInternacional',
									'transferenciaMunicipal',
									'SEGUNDA_VIA',
									'CNH_DEFINITIVA',
									'RENOVACAO',
									'PERMISSIONARIO',
								],
								renderData.usoFormularioEnum,
							) && <EnderecoRecebimento cpf={renderData.cpf} formik={formik} />}

							<Row gutter={[0, 20]}>
								<Col span={24} />
							</Row>
							<Row justify="center">
								<Col offset={1}>{renderButton(formik)}</Col>
							</Row>
						</Form>
						<FormikFildObserver
							dirty={formik.dirty}
							values={formik.values}
							onChange={handleFormChange}
						/>
					</>
				)}
			</Formik>

			<Modal
				centered
				title="Mensagem"
				className="cnh-digital__modal"
				maskClosable={false}
				visible={modalInfo.message !== ''}
				style={{ animationDuration: '0s', color: 'red' }}
				footer={null}
				onCancel={handleCleanModalInfo}
			>
				<div>
					<p
						style={
							modalInfo.type === 'success'
								? { color: '#006ED7', fontWeight: 'bold' }
								: { color: 'red', fontWeight: 'bold' }
						}
					>
						{modalInfo.message}
					</p>
					<ButtonImage
						type="button"
						src="btn-ok"
						onClick={handleCleanModalInfo}
					/>
				</div>
			</Modal>
		</>
	);
};

export default DadosPreCadastro;
