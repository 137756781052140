export enum Types {
	GERA_SENHA_REQUEST = '@relatorios/GERA_SENHA_REQUEST',
	GERA_SENHA_SUCCESS = '@relatorios/GERA_SENHA_SUCCESS',
	GERA_SENHA_FAILURE = '@relatorios/GERA_SENHA_FAILURE',
	GERA_SENHA_CLEAR = '@relatorios/GERA_SENHA_CLEAR',
}

export interface GeraSenha {
	status: number;
	data: GeraSenhaResponse | null;
}

export interface GeraSenhaResponse {
	arquivoPDF: string;
}

export interface GeraSenhaRequest {
	nome: string;
	numRg: string;
	digRg?: string;
	cpf: string;
	cpfDig?: string;
	senha: string;
}
