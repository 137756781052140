import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_PEDIDOS_CONSUMIDOR_REQUEST = '@procon/CONSULTA_PEDIDOS_CONSUMIDOR_REQUEST',
	CONSULTA_PEDIDOS_CONSUMIDOR_SUCCESS = '@procon/CONSULTA_PEDIDOS_CONSUMIDOR_SUCCESS',
	CONSULTA_PEDIDOS_CONSUMIDOR_FAILURE = '@procon/CONSULTA_PEDIDOS_CONSUMIDOR_FAILURE',
	CONSULTA_PEDIDOS_CONSUMIDOR_CLEAR = '@procon/CONSULTA_PEDIDOS_CONSUMIDOR_CLEAR',
}

export interface PedidosConsumidor {
	status: number;
	data: OptionProps[];
}
