// import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_PRIMEIRA_HABILITACAO_REQUEST = '@cnh/CONSULTA_PRIMEIRA_HABILITACAO_REQUEST',
	CONSULTA_PRIMEIRA_HABILITACAO_SUCCESS = '@cnh/CONSULTA_PRIMEIRA_HABILITACAO_SUCCESS',
	CONSULTA_PRIMEIRA_HABILITACAO_FAILURE = '@cnh/CONSULTA_PRIMEIRA_HABILITACAO_FAILURE',
	CONSULTA_PRIMEIRA_HABILITACAO_CLEAR = '@cnh/CONSULTA_PRIMEIRA_HABILITACAO_CLEAR',
}

export interface consultaPrimeiraHabilitacao {
	status: number;
	data: null | ConsultaPrimeiraHabilitacaoData;
	dataError: ConsultaPrimeiraHabilitacaoDataError | null;
	cpf: null | string;
}

export interface ConsultaPrimeiraHabilitacaoData {
	statusMensagem: string;
	renach: string;
	numProcesso: string;
	local: string;
	dataPrimeiraHabilitacao: string;
	cfcA1: string;
	cfcA2: string;
	cfcB2Rodas1: string;
	cfcB2Rodas2: string;
	cfcB2Rodas3: string;
	cfcB4Rodas1: string;
	cfcB4Rodas2: string;
	cfcB4Rodas3: string;
	categoriaPretendida: string;
	escolha: string;
	categoriaAtual1: string;
	categoriaAtual2: string;
	codEntidade: string;
	numRegistro: string;
	dataCadastro: string;
	nome: string;
	sexo: string;
	dataNascimento: string;
	nacionalidade: string;
	naturalidade: string | number;
	codNaturalidade: string;
	endereco: {
		logradouro: string;
		numero: string;
		complemento: string;
		cep: string;
		bairro: string;
		municipio: string;
		codigoMunicipio: string;
	};
	numRg: string;
	orgaoEmissor: string;
	uf: string;
	cpf: string;
	nomePai: string;
	nomeMae: string;
	mensagem: string;
	dadosExameMedico: {
		exameMedico: string;
		categoriaPermitida: string;
		restricaoMedica: string;
		aptidoInaptoTemporario: string;
		cred: string;
		crm: string;
		regiao: string;
	};
	dadosExamePsicotecnico: {
		exame1: string;
		exame2: string;
		laudo: string;
		resultado1: string;
		resultado2: string;
		aptidoInaptoTemporario: string;
		cred: string;
		crp: string;
		regiao: string;
	};
	exameTeorico: string;
	examePratico2Rodas: string;
	examePratico4Rodas: string;
	examinadorTeorico1: string;
	examinadorTeorico2: string;
	examinador2Rodas1: string;
	examinador2Rodas2: string;
	examinador4Rodas1: string;
	examinador4Rodas2: string;
	unidade: string;
	atividadeRemunerada: string;
	dadosPcad: {
		cpf: string;
		nome: string;
		numRg: string;
		orgaoEmissor: string;
		localEmissao: string;
		dataNascimento: string;
		rne: string;
		orgaoEmissorRne: string;
		localEmissaoRne: string;
		codigoPais: string;
		descricaoPais: string;
		validadeHabilitacaoEstrangeira: string;
		sexo: string;
		numRegistro: string;
		numPgu: string;
		nacionalidade: string;
		naturalidade: string;
		codNaturalidade: string;
		nomePai: string;
		nomeMae: string;
		endereco: {
			logradouro: string;
			numero: string;
			complemento: string;
			cep: string;
			bairro: string;
			descricaoMunicipio: string;
			codigoMunicipio: string;
		};
		processo: string;
		ciretran: string;
		categoriaAtual: string;
		categoriaPretendida: string;
		dataCadastro: string;
		horaCadastro: string;
		usuarioCadastro: string;
	};
	mensagensAlerta: [string];
	inapto: string;
	flagAtividadeRemunerada: string;
	flagDeficienteFisico: string;
}

export interface ConsultaPrimeiraHabilitacaoDataError {
	transacao: string;
	codigo: number;
	mensagem: string;
}

export interface RequestConsultaPrimeiraHabilitacao {
	cpf: string;
	usuario: string;
	senha: string;
}
