import React, { FC } from 'react';
import { Col, Row } from 'antd';

import { useHistory } from 'react-router-dom';
import { ApplicationState } from 'store';
import { useSelector } from 'react-redux';

import Button from 'components/Common/Button';
import ButtonImage from 'components/Common/ButtonImage';
import Section from 'components/Common/Section';

import { ROUTE_DETRAN_CNH } from 'routes/paths';
import { ContainerAlteracaoEndereco, ContainerButtons } from './styled';

const Confirmacao: FC = () => {
	const history = useHistory();

	const { alteraTransferenciaEndereco, consultaTransferenciaEndereco } =
		useSelector(
			(state: ApplicationState) => state.api.detranCnh.alteracaoEndereco,
		);

	return (
		<>
			<Section title="Dados do Condutor">
				<Row>
					<Col span={8}>
						<p style={{ fontWeight: 'bold', fontSize: '18px' }}>
							{consultaTransferenciaEndereco.data?.cpf}
						</p>
					</Col>
				</Row>
				<ContainerAlteracaoEndereco>
					<Row gutter={[0, 10]}>
						<Col span={24} style={{ padding: '20px 10px' }}>
							<h1 style={{ paddingBottom: '15px' }}>
								{alteraTransferenciaEndereco.data?.message}
							</h1>
							<Button
								type="button"
								onClick={() => {
									history.push(ROUTE_DETRAN_CNH);
								}}
							>
								Finalizar Processo
							</Button>
						</Col>
					</Row>
				</ContainerAlteracaoEndereco>
			</Section>
			<ContainerButtons>
				<Row gutter={[0, 10]}>
					<Col style={{ padding: '5px 10px' }}>
						<ButtonImage
							type="button"
							src="outro-servico-detran"
							onClick={() => history.push(ROUTE_DETRAN_CNH)}
						/>
					</Col>
				</Row>
			</ContainerButtons>
		</>
	);
};

export default Confirmacao;
