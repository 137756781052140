import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirTiposRequest } from './types';

export function excluirTiposRequest(data: ExcluirTiposRequest): ReducerAction {
	return {
		type: Types.EXCLUIR_TIPOS,
		payload: data,
	};
}
export function excluirTiposSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_TIPOS_SUCCESS,
		payload,
	};
}
export function excluirTiposFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_TIPOS_FAILURE,
		payload: null,
	};
}
export function excluirTiposClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_TIPOS_CLEAR,
		payload: null,
	};
}
