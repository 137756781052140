import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse } from 'services/_api';
import { postApi } from 'services/_sgu';

// TYPES
import { ReducerAction } from 'store/modules/types';

import { CHANNEL } from 'services/_path';
import { SalvaSenhaUsuarioRequest, Types } from './types';

// ACTIONS
import { salvaSenhaUsuarioSuccess, salvaSenhaUsuarioFailure } from './actions';

function* salvaSenhaUsuarioRequest(request: ReducerAction) {
	const { payload }: { payload: SalvaSenhaUsuarioRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		`usuario/${CHANNEL}/salva-senha-usuario`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(salvaSenhaUsuarioSuccess(response.data));
	} else {
		yield put(salvaSenhaUsuarioFailure(response.message));
	}
}

export default all([
	takeLatest(Types.SALVA_SENHA_USUARIO_REQUEST, salvaSenhaUsuarioRequest),
]);
