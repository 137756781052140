export enum Types {
	CONTABILIZACAO_IIRGD_REQUEST = '@procon/CONTABILIZACAO_IIRGD_REQUEST',
	CONTABILIZACAO_IIRGD_SUCCESS = '@procon/CONTABILIZACAO_IIRGD_SUCCESS',
	CONTABILIZACAO_IIRGD_FAILURE = '@procon/CONTABILIZACAO_IIRGD_FAILURE',
	CONTABILIZACAO_IIRGD_CLEAR = '@procon/CONTABILIZACAO_IIRGD_CLEAR',
}

export interface ContabilizacaoIirgd {
	status: number;
	data: null;
}

export interface ContabilizacaoIirgdRequest {
	idAtendimento: string;
	idCidadao: string;
	primeiraVia: boolean;
	numeroRg: string;
	isento2Via10952: boolean;
	declaracaoPobreza: boolean;
	isento2Via062: boolean;
	tipoEntrega: number | string;
	cpf: string;
	ip: string;
	menorIdade: boolean;
	reuso: {
		confrontoRealizado: boolean;
		temTodosOsDedos: boolean;
		erroReuso: boolean;
	};
	naturalidadeEstrangeira: boolean;
	criminal: boolean;
	inclusaoCpf: boolean;
	inclusaoNomeSocial: boolean;
	ciliSimplificada: boolean;
	cpfOperador: string;
	codigoPosto: string;
	nomePosto: string;
	dareVinculada: boolean;
	taxaCorreio: boolean;
	taxaEmissao: boolean;
	statusDare: string;
	origemDare: string;
	dataPagamento: string;
}
