import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaSegundaViaReabilitacao } from './types';

// ACTIONS
import {
	consultaSegundaViaReabilitacaoSuccess,
	consultaSegundaViaReabilitacaoFailure,
} from './actions';

function* consultaSegundaViaReabilitacao(request: ReducerAction) {
	const {
		payload,
	}: { payload: IRequestConsultaSegundaViaReabilitacao } = request;

	const requestData = { ...payload };

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`renach/${CHANNEL}/consultar-2-via`,
		requestData,
	);

	if (response.status === 200) {
		yield put(consultaSegundaViaReabilitacaoSuccess(response));
	} else if (response.error) {
		yield put(consultaSegundaViaReabilitacaoFailure(response.error));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_SEGUNDA_VIA_REABILITACAO,
		consultaSegundaViaReabilitacao,
	),
]);
