import { ReducerAction } from 'store/modules/types';

import { IRequestInfracoes, Types } from './types';

export function consultaInfracoesRequest(
	data: IRequestInfracoes,
): ReducerAction {
	return {
		type: Types.CONSULTA_INFRACOES,
		payload: data,
	};
}
export function consultaInfracoesSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_INFRACOES_SUCCESS,
		payload,
	};
}
export function consultaInfracoesFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_INFRACOES_FAILURE,
		payload,
	};
}
export function consultaInfracoesClear(): ReducerAction {
	return {
		type: Types.CONSULTA_INFRACOES_CLEAR,
		payload: null,
	};
}
