import { ReducerAction } from 'store/modules/types';

import { ConsultarCondutorRequest, Types } from './types';

export function consultarCondutorRequest(
	payload: ConsultarCondutorRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_CONDUTOR_REQUEST,
		payload,
	};
}
export function consultarCondutorSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_CONDUTOR_SUCCESS,
		payload,
	};
}
export function consultarCondutorFailure(payload: boolean): ReducerAction {
	return {
		type: Types.CONSULTAR_CONDUTOR_FAILURE,
		payload,
	};
}
export function consultarCondutorClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_CONDUTOR_CLEAR,
		payload: null,
	};
}
