import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { IAndamentoProcesso } from 'pages/DetranCrv/Processos/AndamentoProcesso/form';
import { Types } from './types';
import { andamentoProcessoSuccess, andamentoProcessoFailure } from './actions';

function* andamentoProcessoRequest(request: ReducerAction) {
	const { payload }: { payload: IAndamentoProcesso } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CRV,
		`processos/${CHANNEL}/andamento-processos?numeroFicha=${payload.numeroFicha}&anoFicha=${payload.anoFicha}`,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(andamentoProcessoSuccess(response));
	} else if (response.error) {
		yield put(andamentoProcessoFailure(response.message));
	}
}

export default all([
	takeLatest(Types.ANDAMENTO_PROCESSO_REQUEST, andamentoProcessoRequest),
]);
