import styled from 'styled-components';

export const Container = styled.div`
	.showbox {
		align-items: center;
		background-color: rgba(0, 0, 0, 0.7);
		bottom: 0;
		cursor: wait;
		display: flex;
		justify-content: center;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 9999;

		.loader {
			width: 50px;
		}

		.circular {
			animation: rotate 2s linear infinite;
			height: 100%;
			transform-origin: center center;
			width: 100%;

			.path {
				stroke-dasharray: 1, 200;
				stroke-dashoffset: 0;
				animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
				stroke-linecap: round;

				@keyframes rotate {
					100% {
						transform: rotate(360deg);
					}
				}

				@keyframes dash {
					0% {
						stroke-dasharray: 1, 200;
						stroke-dashoffset: 0;
					}
					50% {
						stroke-dasharray: 89, 200;
						stroke-dashoffset: -35px;
					}
					100% {
						stroke-dasharray: 89, 200;
						stroke-dashoffset: -124px;
					}
				}

				@keyframes color {
					100%,
					0% {
						/* stroke: #d62d20; */
						stroke: #fff;
					}
					40% {
						/* stroke: #0057e7; */
						stroke: #fff;
					}
					66% {
						/* stroke: #008744; */
						stroke: #fff;
					}
					80%,
					90% {
						/* stroke: #ffa700; */
						stroke: #fff;
					}
				}
			}
		}
	}
`;
