import React, { useEffect, useState } from 'react';

import { Row, Col } from 'antd';

// UTILS
import ButtonImage from 'components/Common/ButtonImage';
import { useHistory } from 'react-router-dom';
import ColorBox from 'components/Common/ColorBox';
import { geraSenhaRequest } from 'store/modules/api/relatorios/geraSenha/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import PDFViewer from 'components/Common/PDFViewer';
import { limparMascara } from 'utils/genericFunctions';
import { eventoTerminoSessaoRequest } from 'store/modules/api/detranCnh/evento/terminoSessao/actions';
import { threatValuesEndSessionEventRequest } from 'utils/functions/threatEventsRequest';
import { BoxRenach } from './styles';

interface IProps {
	renderData: {
		mensagemPromocaoSenha: string;
		tipoProcessoSessao: string;
		dataInicioSessao: string;
	};
}

const RenachGerado: React.FC<IProps> = ({ renderData }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [pdfFile, setPdfFile] = useState('');

	const { imprimirRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.imprimir,
	);

	const { consultaEPortal } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.permissaoInternacional,
	);

	const { preCadastroCidadao } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const { geraSenha } = useSelector(
		(state: ApplicationState) => state.api.relatorios,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	useEffect(() => {
		const valuesEndSessionEvent = threatValuesEndSessionEventRequest(
			atendimento,
			loginUnico,
			renderData.tipoProcessoSessao || '',
			renderData.dataInicioSessao || '',
		);

		dispatch(eventoTerminoSessaoRequest(valuesEndSessionEvent));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (imprimirRenach.status === 200 && imprimirRenach.data) {
			window.open(
				imprimirRenach.data.url,
				'_blank',
				'location=yes,height=auto,width=auto,scrollbars=yes,status=false',
			);
		}
	}, [imprimirRenach]);

	useEffect(() => {
		if (geraSenha.status === 200 && geraSenha.data?.arquivoPDF) {
			setPdfFile(geraSenha.data.arquivoPDF);
		}
	}, [geraSenha]);

	return (
		<>
			<Row gutter={[0, 10]} align="middle" justify="center">
				<Col span={15}>
					<BoxRenach>
						<h2>Informações salvas com sucesso</h2>

						<Row align="middle" justify="center">
							<Col>
								<ButtonImage
									type="button"
									src="finalizar-processo"
									onClick={() => history.push('/detran-cnh')}
								/>
							</Col>
						</Row>
					</BoxRenach>
				</Col>

				<Col span={8} offset={1}>
					<ColorBox
						minHeight={200}
						bgColor="azul"
						title
						header
						successTitle={renderData.mensagemPromocaoSenha}
					>
						<Row
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<Col span={24}>
								<ButtonImage
									type="button"
									src="imprimir"
									onClick={() => {
										dispatch(
											geraSenhaRequest({
												nome: consultaEPortal.data?.nome || '',
												numRg:
													limparMascara(
														consultaEPortal.data?.numeroRg,
													).substring(0, 8) || '0',
												cpf: preCadastroCidadao.data?.cpf || '',
												senha: preCadastroCidadao.data?.token || '',
											}),
										);
									}}
								/>
							</Col>
						</Row>
					</ColorBox>
				</Col>
			</Row>

			{pdfFile && (
				<PDFViewer
					title="Impressão permissão internacional do direito de dirigir"
					source={pdfFile}
					renderMode="popup"
					onClose={() => setPdfFile('')}
				/>
			)}

			{/* <Row justify="space-between" align="middle">
				<Col>
					<ButtonVoltar />
				</Col>
				<Col>
					<ButtonImage
						type="button"
						src="outro-servico-detran"
						onClick={() => history.push('/detran-cnh')}
					/>
				</Col>
			</Row> */}
		</>
	);
};

export default RenachGerado;
