import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaProcessoAbertoRequest, Types } from './types';

// ACTIONS
import {
	consultaProcessoAbertoSuccess,
	consultaProcessoAbertoFailure,
} from './actions';

function* consultaProcessoAbertoRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultaProcessoAbertoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`renach-integrado/${CHANNEL}/consulta/processo-aberto`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaProcessoAbertoSuccess(response));
	} else {
		yield put(consultaProcessoAbertoFailure(response.data));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_PROCESSO_ABERTO_REQUEST,
		consultaProcessoAbertoRequest,
	),
]);
