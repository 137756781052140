export enum Types {
	ENVIA_RESPOSTAS_PROCESSO_REQUEST = '@procon/ENVIA_RESPOSTAS_PROCESSO_REQUEST',
	ENVIA_RESPOSTAS_PROCESSO_SUCCESS = '@procon/ENVIA_RESPOSTAS_PROCESSO_SUCCESS',
	ENVIA_RESPOSTAS_PROCESSO_FAILURE = '@procon/ENVIA_RESPOSTAS_PROCESSO_FAILURE',
	ENVIA_RESPOSTAS_PROCESSO_CLEAR = '@procon/ENVIA_RESPOSTAS_PROCESSO_CLEAR',
}

export interface EnviaRespostasProcesso {
	status: null | number;
	data: null | EnviaRespostasProcessoData;
}

interface EnviaRespostasProcessoData {}

export interface EnviaRespostasProcessoRequest {
	interacaoId: string;
	textoDaInteracao: string;
	anexos: Anexos[];
}

interface Anexos {
	nome: string;
	base64: string;
}
