import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
// import { cdhuClear } from 'store/modules/api/cdhu/actions';

// COMPONENTS
import RecuperaAtendimento from 'components/Pages/RecuperaAtendimento';

const RecuperarAtendimento: React.FC = () => {
	const history = useHistory();
	// const dispatch = useDispatch();

	const { redirecionamento } = useSelector(
		(state: ApplicationState) => state.atendimento,
	);

	const handleClick = useCallback(() => {
		// dispatch(cdhuClear());

		if (redirecionamento) {
			history.push(redirecionamento);
		}
	}, [redirecionamento, history]);

	return <RecuperaAtendimento onClick={handleClick} />;
};

export default RecuperarAtendimento;
