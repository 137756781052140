export enum Types {
	EXCLUIR_NATURALIDADE = '@SGU/EXCLUIR_NATURALIDADE',
	EXCLUIR_NATURALIDADE_SUCCESS = '@SGU/EXCLUIR_NATURALIDADE_SUCCESS',
	EXCLUIR_NATURALIDADE_FAILURE = '@SGU/EXCLUIR_NATURALIDADE_FAILURE',
	EXCLUIR_NATURALIDADE_CLEAR = '@SGU/EXCLUIR_NATURALIDADE_CLEAR',
}

export interface ExcluirNaturalidade {
	status: number;
	data: NaturalidadeData | null;
}

interface NaturalidadeData {
	status: string;
	statusMensagem: string;
}

export interface ExcluirNaturalidadeRequest {
	codigo?: string | number;
	digito: string | number;
	descricao: string;
}
