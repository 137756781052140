import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';

// PATHS
import { ROUTE_MENU_ORGAOS } from 'routes/paths';
import { ROUTE_IIRGD_HABILITAR_RG_NATURALIZADO } from 'pages/iirgd/routes/paths';
import { ROUTE_IIRGD_PESQUISA_ANTECEDENTE } from 'pages/iirgd/Aac/routes/paths';
import { ROUTE_IIRGD_PESQUISAR_RG } from 'pages/iirgd/RgFicDigital/routes/paths';
// import { ROUTE_IIRGD_CIN_EMITIR } from 'pages/iirgd/EmitirCIN/routes/paths';
import { ROUTE_IIRGD_CONSULTAR_CPF } from 'pages/iirgd/ConsultarCpf/routes/paths';
import { ROUTE_IIRGD_PESQUISAR_DBCIN } from 'pages/iirgd/ConsultarDBCIN/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { aacClear } from 'store/modules/api/aac/actions';
import { biometriaClear } from 'store/modules/api/biometria/actions';
import { cdhuClear } from 'store/modules/api/cdhu/actions';
import { dareClear } from 'store/modules/api/dare/actions';
import { iirgdClear } from 'store/modules/api/iirgd/actions';
import { rfbClear } from 'store/modules/api/rfb/actions';
import { enderecosClear } from 'store/modules/api/utils/enderecos/actions';

// UTILS
import {
	HABILITA_CIN,
	HABILITAR_DBCIN,
} from 'store/modules/api/sgu/loginUnico/constants';

// COMPONENTS
import ServiceList from 'components/Common/ServiceList';
import ButtonVoltar from 'components/Common/ButtonVoltar';

import { ApplicationState } from 'store';
import { utilsClear } from 'store/modules/api/utils/actions';
import { consultarCpfClear } from 'store/modules/api/rfb/consultarCpf/actions';
import { logonClear } from 'store/modules/api/iirgd/logon/actions';
import { IParametrosLocalidade } from 'store/modules/api/sgu/loginUnico/types';
import ReimpressaoProtocoloFic from './Reimpressao/ProtocoloFic';

interface ServiceProps {
	name: string;
	path: string;
	descricaoServico?: string;
	recuperaAtendimento?: boolean;
	dadosAgendamentoIIRGD?: true;
}
interface ServiceCombo {
	title: string;
	services: ServiceProps[];
}

const MenuIirgd: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { loginUnico } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const verifyLocationIsAllowedToCIN = useCallback(() => {
		let isAllowed = false;
		loginUnico.login.user.parametrosLocalidade.forEach(
			(item: IParametrosLocalidade) => {
				if (
					item.sigla.toLowerCase() === HABILITA_CIN.toLowerCase() &&
					item.valor.toLowerCase() === 'true'
				) {
					isAllowed = true;
				}
			},
		);
		return isAllowed;
	}, [loginUnico.login.user.parametrosLocalidade]);

	const verifyLocationIsAllowedToDBCIN = useCallback(() => {
		let isAllowed = false;
		loginUnico.user.parametrosSistema.forEach((item: IParametrosLocalidade) => {
			if (
				item.sigla.toLowerCase() === HABILITAR_DBCIN.toLowerCase() &&
				item.valor.toLowerCase() === 'true'
			) {
				isAllowed = true;
			}
		});
		return isAllowed;
	}, [loginUnico.user.parametrosSistema]);

	const [itensService, setItensService] = useState<ServiceProps[]>([
		{
			name: 'RGE CIN - Carteira de Identidade - FIC Digital',
			path: ROUTE_IIRGD_PESQUISAR_RG,
			recuperaAtendimento: false,
			dadosAgendamentoIIRGD: true,
		},
		{
			name: 'AAC - Atestado de Antecedentes Criminais',
			path: ROUTE_IIRGD_PESQUISA_ANTECEDENTE,
		},
	]);
	useEffect(() => {
		dispatch(enderecosClear());
		dispatch(aacClear());
		dispatch(dareClear());
		dispatch(biometriaClear());
		dispatch(consultarCpfClear());
		dispatch(cdhuClear());
		dispatch(iirgdClear());
		dispatch(utilsClear());
		dispatch(logonClear({}));
		dispatch(rfbClear());
	}, [dispatch]);

	useEffect(() => {
		if (user.perfil.id === 6) {
			setItensService(prev => {
				return [
					...prev,
					{
						name: 'Habilitar RGE CIN​ de Naturalizado',
						path: ROUTE_IIRGD_HABILITAR_RG_NATURALIZADO,
					},
				];
			});
		}
	}, [user.perfil.id]);

	useEffect(() => {
		if (verifyLocationIsAllowedToCIN()) {
			setItensService(prev => {
				return [
					{
						name: 'Consultar CPF B Cadastros',
						path: ROUTE_IIRGD_CONSULTAR_CPF,
						recuperaAtendimento: false,
					},
					...prev,
					// {
					// 	name: 'Emitir CIN',
					// 	path: ROUTE_IIRGD_CIN_EMITIR,
					// 	recuperaAtendimento: false,
					// 	dadosAgendamentoIIRGD: true,
					// },
				];
			});
		}
	}, [verifyLocationIsAllowedToCIN]);

	useEffect(() => {
		if (verifyLocationIsAllowedToDBCIN()) {
			setItensService(prev => {
				return [
					...prev,
					{
						name: 'Consultar DBCIN',
						path: ROUTE_IIRGD_PESQUISAR_DBCIN,
						recuperaAtendimento: false,
					},
				];
			});
		}
	}, [verifyLocationIsAllowedToDBCIN]);

	return (
		<Row>
			<Col span={15}>
				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h1>Escolha o Serviço</h1>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						{itensService && <ServiceList data={itensService} />}
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<ButtonVoltar
							navigate={false}
							onClick={() => {
								history.push(ROUTE_MENU_ORGAOS);
							}}
						/>
					</Col>
				</Row>
			</Col>

			<Col span={8} offset={1}>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<ReimpressaoProtocoloFic />
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default MenuIirgd;
