import { Types } from './types';

export function tiposEnsinoRequest(token: string) {
	return {
		type: Types.TIPOS_ENSINO_REQUEST,
		token,
	};
}

export function tiposEnsinoSuccess(payload: object) {
	return {
		type: Types.TIPOS_ENSINO_SUCCESS,
		payload,
	};
}

export function tiposEnsinoFailure(payload: boolean) {
	return {
		type: Types.TIPOS_ENSINO_FAILURE,
		payload,
	};
}
