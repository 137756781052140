import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_CDHU_MUTUARIOS_API } from 'services/rotasp/path';

// UTILS
import { treatObjNullValues, limparMascara } from 'utils/genericFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { IFormConsultarSolicitante } from 'pages/cdhu/Solicitante/Pesquisar/form';
import { Types, ConsultarSolicitanteData } from './types';

// ACTIONS
import {
	consultarSolicitanteSuccess,
	consultarSolicitanteFailure,
} from './actions';

function* consultarSolicitanteRequest(request: ReducerAction) {
	const { token, payload }: { token?: string; payload: IFormConsultarSolicitante } = request;

	const cpfFormatted = limparMascara(payload.cpf);

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_CDHU_MUTUARIOS_API,
		`solicitante/${cpfFormatted}`,
	);

	if (response.status === 200) {
		const data = treatObjNullValues(response.data);

		yield put(consultarSolicitanteSuccess(data as ConsultarSolicitanteData));
	} else {
		yield put(consultarSolicitanteFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_SOLICITANTE_REQUEST, consultarSolicitanteRequest),
]);
