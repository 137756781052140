import { ReducerAction } from 'store/modules/types';

import { Types, CancelarAgendamentoPsicologoRequest } from './types';

export function cancelarAgendamentoPsicologoRequest(
	data: CancelarAgendamentoPsicologoRequest,
): ReducerAction {
	return {
		type: Types.CANCELAR_AGENDAMENTO_PSICOLOGO_REQUEST,
		payload: data,
	};
}
export function cancelarAgendamentoPsicologoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CANCELAR_AGENDAMENTO_PSICOLOGO_SUCCESS,
		payload,
	};
}
export function cancelarAgendamentoPsicologoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CANCELAR_AGENDAMENTO_PSICOLOGO_FAILURE,
		payload,
	};
}
export function cancelarAgendamentoPsicologoClear(): ReducerAction {
	return {
		type: Types.CANCELAR_AGENDAMENTO_PSICOLOGO_CLEAR,
		payload: null,
	};
}
