import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConsultarEstatisticaRequest } from './types';
import {
	consultarEstatisticaClear,
	consultarEstatisticaFailure,
	consultarEstatisticaSuccess,
} from './actions';

function* consultarEstatistica(request: ReducerAction) {
	const { payload }: { payload: IConsultarEstatisticaRequest } = request;

	yield put(consultarEstatisticaClear());

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`relatorios/estatistica`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultarEstatisticaSuccess(response.status, response.data));
	} else {
		yield put(consultarEstatisticaFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_ESTATISTICA_REQUEST, consultarEstatistica),
]);
