export enum Types {
	CONSULTA_TOKEN_CNH_DIGITAL_REQUEST = '@cnh/CONSULTA_TOKEN_CNH_DIGITAL_REQUEST',
	CONSULTA_TOKEN_CNH_DIGITAL_SUCCESS = '@cnh/CONSULTA_TOKEN_CNH_DIGITAL_SUCCESS',
	CONSULTA_TOKEN_CNH_DIGITAL_FAILURE = '@cnh/CONSULTA_TOKEN_CNH_DIGITAL_FAILURE',
	CONSULTA_TOKEN_CNH_DIGITAL_CLEAR = '@cnh/CONSULTA_TOKEN_CNH_DIGITAL_CLEAR',
}

export interface consultaTokenCnhDigital {
	status: number;
	data: ConsultaTokenCnhDigitalData | null;
}

export interface ConsultaTokenCnhDigitalData {
	token: string;
}

export interface RequestConsultaTokenCnhDigital {
	cpf: string;
	ddd: string;
	celular: string;
	email: string;
	duracao: string;
}
