import * as Yup from 'yup';

// REDUX
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { ICaracteristicaVeiculo } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/types';
import { caracteristicas } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV120/types';

export interface ITransferenciaMunicipioEstado {
	chassi: string;
	placa: string;
	tipoConsulta: 'TRANSFERENCIA';
	selecao?: 'chassi' | 'placa';
	documento: string;
	espelho: string;
	categoria: string;
	dataContabil?: string;
	codBanco?: string;
	nsuProdesp?: string;
	restricaoFinanceira?: string;
}

export const initialValues: ITransferenciaMunicipioEstado = {
	chassi: '',
	placa: '',
	documento: '',
	espelho: '',
	tipoConsulta: 'TRANSFERENCIA',
	selecao: 'chassi',
	categoria: '',
	dataContabil: '',
	codBanco: '',
	nsuProdesp: '',
	restricaoFinanceira: 'NADA_CONSTA',
};

export const schema = Yup.object<ITransferenciaMunicipioEstado>().shape({
	placaRenavamChassi: Yup.string().when(['placa', 'chassi'], {
		is: (placa, chassi) => placa === '' && chassi === '',
		then: Yup.string().required(`Campo Obrigatório: Chassi ou Placa.`),
	}),
	documento: Yup.string()
		.required('Campo Obrigatório: CPF/CNPJ Proprietário.')
		.min(11, 'CPF ou CNPJ inválido'),
	categoria: Yup.string().required(`Campo Obrigatório: Categoria.`),
	documentoCategoria: Yup.string().when(['documento', 'categoria'], {
		is: (documento, categoria) =>
			documento.length <= 11 && categoria.toString() === '3',
		then: Yup.string().required(
			'Categoria oficial somente permitido para pessoa jurídica (CNPJ).',
		),
	}),
	espelho: Yup.string().required('Campo Obrigatório: Numero do Espelho.'),
});

export const treatRequestSguStatistics = (
	user: IUser,
	formData: ITransferenciaMunicipioEstado,
	dataConsultarVeiculoV110: ICaracteristicaVeiculo | null,
	dataConsultarVeiculoV120: caracteristicas | null,
): IncluirRequest => {
	const payload: IncluirRequest = {
		observacao: `Chassi: ${
			formData?.chassi.trim() ||
			dataConsultarVeiculoV110?.caracteristicaVeiculo?.chassi?.trim() ||
			dataConsultarVeiculoV120?.caracteristicaVeiculo?.chassi?.trim() ||
			'N/A'
		}, Placa: ${
			dataConsultarVeiculoV110?.caracteristicaVeiculo?.placa?.trim() ||
			dataConsultarVeiculoV120?.caracteristicaVeiculo?.placa?.trim() ||
			'N/A'
		}, Renavam Gerada ${'N/A'}`,
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento: formData?.documento?.trim() || '',
		codigoLocalidade: (user?.posto && Number(user?.posto)) || 0,
		idOrgao: 5,
		cpf: user?.cpf,
		descricaoServico: 'Transferência Município Estado',
		idMotivo: 16,
	};

	return payload;
};
