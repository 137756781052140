export enum Types {
	DEPENDENTES_DARE_CPF_PRINCIPAL = '@dare/DEPENDENTES_DARE_CPF_PRINCIPAL',
	DEPENDENTES_DARE_ADICIONAR = '@dare/DEPENDENTES_DARE_ADICIONAR',
	DEPENDENTES_DARE_EDITANDO = '@dare/DEPENDENTES_DARE_EDITANDO',
	DEPENDENTES_DARE_EDITANDO_CLEAR = '@dare/DEPENDENTES_DARE_EDITANDO_CLEAR',
	DEPENDENTES_DARE_EXCLUIR = '@dare/DEPENDENTES_DARE_EXCLUIR',
	DEPENDENTES_DARE_CLEAR = '@dare/DEPENDENTES_DARE_CLEAR',
}

export interface IDependentesDareState {
	cpfPrincipal: string;
	data: IDependenteDare[] | null;
	editando: {
		index: number;
		data: IDependenteDare | null;
	};
}

export interface IDependenteDare {
	numeroRg: string;
	loteRg?: string;
	nome: string;
	grauParentesco: string;
	dataNascimento: string;
	segundaVia: boolean;
}
