import { Types } from './types';

export function consultarMunicipioPorUfRequest(payload: string) {
	return {
		type: Types.CONSULTAR_MUNICIPIO_POR_UF_REQUEST,
		payload,
	};
}

export function consultarMunicipioPorUfSuccess(payload: object) {
	return {
		type: Types.CONSULTAR_MUNICIPIO_POR_UF_SUCCESS,
		payload,
	};
}

export function consultarMunicipioPorUfFailure(payload: []): any {
	return {
		type: Types.CONSULTAR_MUNICIPIO_POR_UF_FAILURE,
		payload,
	};
}
