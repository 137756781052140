import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import AgendamentoPsicologicoRenachIntegrado from 'pages/DetranCnh/components/pages/AgendamentoPsicologicoRenachIntegrado';
import ButtonImage from 'components/Common/ButtonImage';

// PATHS
import { ROUTE_DETRAN_CNH } from 'routes/paths';
import { ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_RENACH } from 'pages/DetranCnh/routes/paths';

// FORM
import { initialValues, dadosCidadao } from './form';

const AgendamentoPsicologicoReabilitacao: React.FC = () => {
	const history = useHistory();
	// const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);

	const { saveGerarRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const consultaCategoria = useSelector(
		(state: ApplicationState) => state.api.detranCnh.consulta.consultaCategoria,
	);

	// MANTER OCULTO ATÉ ALINHAMENTO COM O 2.0 PARA APLICAR O MESMO FLUXO EM AMBOS (VOLTAR PARA A TELA ANTERIOR).
	// const handleButtonBack = useCallback(() => {
	// 	dispatch(cadastroEnderecosClear());
	// 	dispatch(dadosCidadaoClear());
	// 	history.goBack();
	// }, [dispatch, history]);

	useEffect(() => {
		if (saveGerarRenach.data?.gerarRenachBase) {
			const {
				cpf,
				logradouroCep,
				categoriaPretendida,
				codCiretranHabilitacao,
			} = saveGerarRenach.data?.gerarRenachBase;

			initialValues.categoria_pretendida =
				consultaCategoria.divisaoEquitativa.filter(
					divisaoEquitativa => divisaoEquitativa.codigo === categoriaPretendida,
				)[0].codigoDivisaoEquitativa;
			initialValues.cpf_cidadao = Number(cpf);
			initialValues.cep_cidadao = Number(logradouroCep);
			initialValues.codigo_ciretran_exame_psicologo = Number(
				codCiretranHabilitacao,
			);
		}
	}, [consultaCategoria.divisaoEquitativa, saveGerarRenach.data]);

	useEffect(() => {
		if (saveGerarRenach.data?.gerarRenachBase) {
			const {
				cpf,
				nome,
				nomeSocial,
				nomePai,
				nomeMae,
				numDocumento,
				dataNascimento,
				sexo,
				nacionalidade,
				codigoNaturalidade,
				categoriaPretendida,
				logradouroCep,
				flagDeficienteFisico,
				rneNumero,
			} = saveGerarRenach.data?.gerarRenachBase;

			// DADOS CIDADÃO
			dadosCidadao.nome = nomeSocial || nome;
			dadosCidadao.nomeMae = nomeMae;
			dadosCidadao.nomePai = nomePai;
			dadosCidadao.numeroRg = numDocumento;
			dadosCidadao.cpf = cpf;
			dadosCidadao.sexo = sexo;
			dadosCidadao.nacionalidade = nacionalidade;
			dadosCidadao.cep = logradouroCep;
			dadosCidadao.dataNascimento = dataNascimento;
			dadosCidadao.naturalidade = codigoNaturalidade || '';
			dadosCidadao.categoriaPretendida =
				consultaCategoria.divisaoEquitativa.filter(
					divisaoEquitativa => divisaoEquitativa.codigo === categoriaPretendida,
				)[0].codigoDivisaoEquitativa;
			dadosCidadao.isPortadorNecessidadesEspeciais =
				!!flagDeficienteFisico || flagDeficienteFisico === 'S';
			dadosCidadao.rne = rneNumero || '';

			setIsLoading(false);
		}
	}, [consultaCategoria.divisaoEquitativa, saveGerarRenach.data]);

	return (
		<>
			{!isLoading && (
				<Row>
					<Col span={24}>
						<Row>
							<Col span={24}>
								<AgendamentoPsicologicoRenachIntegrado
									dadosCidadao={dadosCidadao}
									dadosAgendamento={initialValues}
									redirecionamento={
										ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_RENACH
									}
								/>
							</Col>
						</Row>
						<Row justify="space-between" align="middle">
							<Col>
								{/* MANTER OCULTO ATÉ ALINHAMENTO COM O 2.0 PARA APLICAR O MESMO FLUXO EM AMBOS (VOLTAR PARA A TELA ANTERIOR).
									<ButtonVoltar
										navigate={false}
										onClick={() => handleButtonBack()}
									/> 
								*/}
							</Col>
							<Col>
								<ButtonImage
									type="button"
									src="outro-servico-detran"
									onClick={() => history.push(ROUTE_DETRAN_CNH)}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
		</>
	);
};
export default AgendamentoPsicologicoReabilitacao;
