export enum Types {
	CONSULTA_PARAMETROS = '@parametros/CONSULTA_PARAMETROS',
	CONSULTA_PARAMETROS_SUCCESS = '@parametros/CONSULTA_PARAMETROS_SUCCESS',
	CONSULTA_PARAMETROS_FAILURE = '@parametros/CONSULTA_PARAMETROS_FAILURE',

	CADASTRA_PARAMETROS = '@parametros/CADASTRA_PARAMETROS',
	CADASTRA_PARAMETROS_SUCCESS = '@parametros/CADASTRA_PARAMETROS_SUCCESS',
	CADASTRA_PARAMETROS_FAILURE = '@parametros/CADASTRA_PARAMETROS_FAILURE',

	ALTERA_PARAMETROS = '@parametros/ALTERA_PARAMETROS',
	ALTERA_PARAMETROS_SUCCESS = '@parametros/ALTERA_PARAMETROS_SUCCESS',
	ALTERA_PARAMETROS_FAILURE = '@parametros/ALTERA_PARAMETROS_FAILURE',

	REMOVE_PARAMETROS = '@parametros/REMOVE_PARAMETROS',
	REMOVE_PARAMETROS_SUCCESS = '@parametros/REMOVE_PARAMETROS_SUCCESS',
	REMOVE_PARAMETROS_FAILURE = '@parametros/REMOVE_PARAMETROS_FAILURE',

	REDIRECT_PARAMETROS_CLEAR = '@parametros/REDIRECT_PARAMETROS_CLEAR',
}

export interface ParametrosState {
	parametrosData: IParametroData[];
	redirect: boolean;
	situation: string;
}

export interface IParametroData {
	codigo: number;
	descricao: string;
	nome: string;
	rotulo: string;
	tamanho: string;
	tipo: string;
}
