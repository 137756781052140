import { ConsultaPesquisaNomeSocialFiliacao } from 'store/modules/api/detranCnh/IIRGDDetran/consultaPesquisaNomeSocialFiliacao/types';
import { consultaNomeSocialFiliacao } from 'store/modules/api/detranCnh/condutor/consultaNomeSocialFiliacao/types';

export const getOriginOfSocialName = (
	consultaNomeSocialFiliacaoServiceValues: consultaNomeSocialFiliacao | null,
	consultaPesquisaNomeSocialFiliacaoServiceValues: ConsultaPesquisaNomeSocialFiliacao | null,
): '0' | '1' | '2' => {
	// ORIGEM NOME SOCIAL '0' - NENHUM
	// ORIGEM NOME SOCIAL '1' - BASE IIRGD
	// ORIGEM NOME SOCIAL '2' - BASE DE CONDUTORES

	if (
		consultaPesquisaNomeSocialFiliacaoServiceValues?.data?.nomeSocial &&
		consultaPesquisaNomeSocialFiliacaoServiceValues.data.nomeSocial.trim() !==
			''
	) {
		return '1';
	}

	if (
		consultaNomeSocialFiliacaoServiceValues?.data?.nomeSocial &&
		consultaNomeSocialFiliacaoServiceValues.data.nomeSocial.trim() !== ''
	) {
		return '2';
	}

	return '0';
};
