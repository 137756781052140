import React, { useState, useEffect } from 'react';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { setLightTheme, setDarkTheme } from 'store/modules/app/theme/actions';
import { setLoader } from 'store/modules/app/loader/actions';

// ROTAS
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import Routes from 'routes';

// COMPONENTS
import Loader from 'components/Common/Loader';
import Header from 'components/Header';
import Footer from 'components/Footer';
import BreadCrumb from 'components/Common/BreadCrumb';
import Error from 'components/Common/Notifications/FormError/index';
import Menu from 'pages/Poupatempo/Sgu/components/Menu';

// UTILS
import usePersistedState from 'utils/usePersistedState';

// THEMES
import light from 'styles/themes/light';
import dark from 'styles/themes/dark';

// STYLED
import CreateGlobalStyle from './styles/reset';
import { Container } from './styles/pages';

// CACHE

const App: React.FC = () => {
	const dispatch = useDispatch();

	const [font, setFont] = useState(0);
	const [color, setColor] = useState<DefaultTheme>(light);
	const [object, setObject] = useState({ ...light, fontScale: font });
	const [theme, setTheme] = usePersistedState<DefaultTheme>('theme', object);
	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const { visible } = useSelector(
		(state: ApplicationState) => state.app.loader,
	);

	const { fontSize, colorTheme } = useSelector(
		(state: ApplicationState) => state.app.theme,
	);

	const clearInitialLoading = () => {
		localStorage.removeItem('lastCallApi');
		setLoader(false);
	};

	// Gerencia o tamanho da font da aplicação.
	useEffect(() => {
		setFont(fontSize);
	}, [fontSize]);

	// Gerencia a cor do tema da aplicação.
	useEffect(() => {
		setColor(colorTheme === 'light' ? light : dark);
	}, [colorTheme]);

	useEffect(() => {
		clearInitialLoading();
		let storageValue: any = localStorage.getItem('theme');
		storageValue = JSON.parse(storageValue);

		const storageFont = storageValue.font ? storageValue.font : '0';
		const storageColor = storageValue.title === 'dark' ? dark : light;

		setFont(storageFont);
		setColor(storageColor);

		dispatch(storageValue.title === 'light' ? setLightTheme() : setDarkTheme());
	}, [dispatch]);

	useEffect(() => {
		const newValue = { ...color, fontScale: font };
		setObject(newValue);
	}, [color, font]);

	useEffect(() => {
		setTheme(object);
	}, [setTheme, object]);

	return (
		<>
			<BrowserRouter basename="/">
				<ThemeProvider theme={theme}>
					<Header />

					<Container>
						{!!user?.cpf && <Menu />}

						{!!user?.cpf && <BreadCrumb />}

						{visible && <Loader />}

						{notifications.errors &&
							notifications.errors.length > 0 &&
							!notifications.showInside && (
								<Error
									errors={notifications.errors}
									moveToTopWhenDisplayed={notifications.moveToTop}
								/>
							)}

						<Routes />
					</Container>

					<Footer />

					<CreateGlobalStyle />
				</ThemeProvider>
			</BrowserRouter>
		</>
	);
};

export default App;
