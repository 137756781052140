export enum Types {
	INCREASE_FONT_SIZE = '@app/INCREASE_FONT_SIZE',
	DECREASE_FONT_SIZE = '@app/DECREASE_FONT_SIZE',

	SET_LIGHT_THEME = '@app/SET_LIGHT_THEME',
	SET_DARK_THEME = '@app/SET_DARK_THEME',
}

export interface ThemeState {
	fontSize: number;
	colorTheme: 'light' | 'dark';
}
