import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

import { Types, alteraPrefeiturasServicosCanais } from './types';

export const INITIAL_STATE: alteraPrefeiturasServicosCanais = {
	status: 0,
	data: null,
};

export default function alteraPrefeiturasServicosCanaisReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): alteraPrefeiturasServicosCanais {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ALTERA_PREFEITURA_SERVICOS_CANAIS: {
				break;
			}
			case Types.ALTERA_PREFEITURA_SERVICOS_CANAIS_SUCCESS: {
				draft.data = action.payload.situation;
				break;
			}
			case Types.ALTERA_PREFEITURA_SERVICOS_CANAIS_FAILURE: {
				break;
			}

			case Types.ALTERA_PREFEITURA_SERVICOS_CANAIS_CLEAR: {
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
