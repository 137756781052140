// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	RequestConsultaSituacaoAtual,
	ConsultaSituacaoAtual,
	Types,
} from './types';

export function consultaSituacaoAtualRequest(
	payload: RequestConsultaSituacaoAtual,
): ReducerAction {
	return {
		type: Types.CONSULTA_SITUACAO_ATUAL_REQUEST,
		payload,
	};
}

export function consultaSituacaoAtualSuccess(
	payload: ConsultaSituacaoAtual,
): ReducerAction {
	return {
		type: Types.CONSULTA_SITUACAO_ATUAL_SUCCESS,
		payload,
	};
}

export function consultaSituacaoAtualFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_SITUACAO_ATUAL_FAILURE,
		payload: null,
	};
}

export function consultaSituacaoAtualClear(): ReducerAction {
	return {
		type: Types.CONSULTA_SITUACAO_ATUAL_CLEAR,
		payload: null,
	};
}

export function atendimentoEncerrado(payload: string): ReducerAction {
	return {
		type: Types.ATENDIMENTO_ENCERRADO,
		payload,
	};
}

export function limpaMensagemRetorno(): ReducerAction {
	return {
		type: Types.LIMPA_MENSAGEM_RETORNO,
		payload: null,
	};
}

export function limpaPainelDeControle(): ReducerAction {
	return {
		type: Types.LIMPA_STATE,
		payload: null,
	};
}
