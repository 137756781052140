import * as Yup from 'yup';

export interface CancelarAutorizacaoPrevia {
	chassi: string;
	numeroAutorizacao: string;
}

export const initialValues: CancelarAutorizacaoPrevia = {
	chassi: '',
	numeroAutorizacao: '',
};

export const schema = Yup.object<CancelarAutorizacaoPrevia>().shape({
	chassi: Yup.string().required(`Campo Obrigatório: Chassi.`),
	numeroAutorizacao: Yup.string().required(
		`Campo Obrigatório: Número da Autorização.`,
	),
});
