import { all } from 'redux-saga/effects';

import documentoInfracaoSaga from './documentoInfracao/saga';
import infracoesSaga from './infracoes/saga';
import protocolarRecursoSaga from './protocolarRecurso/saga';
import transferirPontuacaoSaga from './transferirPontuacao/saga';

export default all([
	documentoInfracaoSaga,
	infracoesSaga,
	protocolarRecursoSaga,
	transferirPontuacaoSaga,
]);
