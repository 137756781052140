export enum Types {
	PROTOCOLO_FIC_REQUEST = '@iirgd/PROTOCOLO_FIC_REQUEST',
	PROTOCOLO_FIC_SUCCESS = '@iirgd/PROTOCOLO_FIC_SUCCESS',
	PROTOCOLO_FIC_FAILURE = '@iirgd/PROTOCOLO_FIC_FAILURE',
	PROTOCOLO_FIC_CLEAR = '@iirgd/PROTOCOLO_FIC_CLEAR',
}

export interface IProtocoloFicState {
	status: number;
	data: IProtocoloFic | null;
}

export interface IProtocoloFic {
	arquivoPdf: string;
	listErros: string[];
}
