import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { PATH_EDUCACAO_SEDUC_RESPONSAVEIS_API } from 'services/rotasp/path';

import { ApiResponse, postApi } from 'services/rotasp/api';

import { ReducerActionRotaSP } from 'store/modules/types';
import { CadastrarResponsavelData, Types } from './types';

import {
	cadastrarResponsavelSuccess,
	cadastrarResponsavelFailure,
} from './actions';

function* cadastrarResponsavelRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: CadastrarResponsavelData } = request;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_EDUCACAO_SEDUC_RESPONSAVEIS_API,
		`responsaveis`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarResponsavelSuccess(response));
	} else {
		yield put(cadastrarResponsavelFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CADASTRAR_RESPONSAVEL_REQUEST, cadastrarResponsavelRequest),
]);
