import { combineReducers } from 'redux';

import atualizarTipos from './atualizarTipos/reducer';
import consultarTipos from './consultarTipos/reducer';
import cadastrarTipos from './cadastrarTipos/reducer';
import excluirTipos from './excluirTipos/reducer';

export default combineReducers({
	atualizarTipos,
	consultarTipos,
	cadastrarTipos,
	excluirTipos,
});
