import { takeLatest, put, all, call } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_AAC } from 'services/_path';

// STORE
import { ReducerAction } from 'store/modules/types';

// ACTIONS
import {
	consultaUsuarioPendenteSuccess,
	consultaUsuarioPendenteFailure,
	atualizarAtendenteSuccess,
	atualizarAtendenteFailure,
} from './actions';

// TYPES
import {
	Types,
	RequestConsultaUsuarioPendente,
	RequestAtualizarAtendente,
	ConsultaUsuarioPendente,
} from './types';

function* consultaUsuarioPendenteRequest(request: ReducerAction) {
	const { payload }: { payload: RequestConsultaUsuarioPendente } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_AAC,
		`aac/${CHANNEL}/consulta-usuario-pendente`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaUsuarioPendenteSuccess(response.data));
	} else if (response.error) {
		yield put(consultaUsuarioPendenteFailure());
	}
}

function* atualizarAtendenteRequest(request: ReducerAction) {
	const { payload } = request;

	const {
		atualizacao,
		resultado,
	}: {
		atualizacao: RequestAtualizarAtendente;
		resultado: ConsultaUsuarioPendente[];
	} = payload;

	const response: ApiResponse = yield call(
		postApi,
		PATH_AAC,
		`aac/${CHANNEL}/atualiza-atendente`,
		atualizacao,
	);

	if (response.status === 200) {
		yield put(atualizarAtendenteSuccess(response.data, resultado));
	} else if (response.error) {
		yield put(atualizarAtendenteFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTA_USUARIO_PENDENTE, consultaUsuarioPendenteRequest),
	takeLatest(Types.ATUALIZAR_ATENDENTE, atualizarAtendenteRequest),
]);
