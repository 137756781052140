import React from 'react';

// ANTD
import { Row, Col } from 'antd';

// COMPONENTS
import TypographyCommon from 'components/Common/Typography';

// UTILS
import { isFilledValue } from 'utils/stringFunctions';
import { formatDate } from 'utils/genericFunctions';

// REDUX
import { PEPM } from 'store/modules/api/detranCrv/ConsultarResumoExtrato/pepm/types';

interface Props {
	data: PEPM;
}

const ExtratoPEPM: React.FC<Props> = ({ data }) => {
	return (
		<>
			{data.status === 200 && (
				<>
					<Row gutter={[0, 0]} align="top">
						<Col span={4}>
							<TypographyCommon
								text="Placa:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.placa)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text="Renavam:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text={isFilledValue(data.data?.renavam)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text="Chassi:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text={isFilledValue(data.data?.chassi)}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={4}>
							<TypographyCommon
								text="Nome Proprietário:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.nomeProprietario)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text="Tipo Documento:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text={isFilledValue([
									data.data?.tipoDocumentoProprietario?.codigo,
									data.data?.tipoDocumentoProprietario?.descricao,
								])}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text="CNPJ/CPF:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text={isFilledValue(data.data?.cpfCnpj)}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={4}>
							<TypographyCommon
								text="Ano Licenciamento:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.anoLicenciamento)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text="Data Emissão CRV:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text={isFilledValue(formatDate(data.data?.dataEmissaoCRV))}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text="Data Licenciamento:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text={isFilledValue(formatDate(data.data?.dataLicenciamento))}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={4}>
							<TypographyCommon
								text="Município Registro:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue([
									data.data?.municipioRegistro?.codigo,
									data.data?.municipioRegistro?.descricao,
								])}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text="Combustível:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text={isFilledValue([
									data.data?.combustivel?.codigo,
									data.data?.combustivel?.descricao,
								])}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text="Categoria:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text={isFilledValue([
									data.data?.categoria?.codigo,
									data.data?.categoria?.descricao,
								])}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={4}>
							<TypographyCommon
								text="Marca Modelo:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue([
									data.data?.marcaModelo?.codigo,
									data.data?.marcaModelo?.descricao,
								])}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text="Tipo Proprietário:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={10}>
							<TypographyCommon
								text={isFilledValue([
									data.data?.tipoProprietario?.codigo,
									data.data?.tipoProprietario?.descricao,
								])}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>
				</>
			)}

			{data.status === 406 && (
				<Row gutter={[0, 0]} align="top">
					<Col span={24}>
						<TypographyCommon
							text={data.mensagem}
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
				</Row>
			)}
		</>
	);
};

export default ExtratoPEPM;
