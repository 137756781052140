export enum Types {
	ATUALIZAR_PERFIL = '@cnh/ATUALIZAR_PERFIL',
	ATUALIZAR_PERFIL_SUCCESS = '@cnh/ATUALIZAR_PERFIL_SUCCESS',
	ATUALIZAR_PERFIL_FAILURE = '@cnh/ATUALIZAR_PERFIL_FAILURE',
	ATUALIZAR_PERFIL_CLEAR = '@cnh/ATUALIZAR_PERFIL_CLEAR',
}

export interface AtualizarPerfis {
	status: number;
	data: null | PerfilData;
}

export interface PerfilData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarPerfilRequest {
	codigo?: number | string;
	codigoStatus: string | number;
	descricao: string;
	acessaSgu: boolean;
}
