import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// ENTITIES
import { RequestPesquisarRg } from 'pages/iirgd/RgFicDigital/PesquisaRg/Formulario/form';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultaPesquisaCidadaoSuccess,
	consultaPesquisaCidadaoFailure,
} from './actions';
import { consultaPesquisaCirgCidadaoSuccess } from '../consultaPesquisaCirg/actions';

function* consultaPesquisaCidadaoRequest(request: ReducerAction) {
	const { payload } = request;

	const { data }: { data: RequestPesquisarRg } = payload;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		'consultaPesquisaCidadao',
		data,
	);

	if (response.status === 200) {
		if (data.pesquisaCIRG) {
			yield put(consultaPesquisaCirgCidadaoSuccess(response.data.pesquisaCIRG));
		}

		yield put(consultaPesquisaCidadaoSuccess(response.data.pesquisaCivil[0]));
	} else if (response.error) {
		yield put(consultaPesquisaCidadaoFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_PESQUISA_CIDADAO_REQUEST,
		consultaPesquisaCidadaoRequest,
	),
]);
