export const sameThreeCharactersInSequenceNotAllowedRegex =
	/^.*(\w)(\w)?((?!\1)|(?!\2)).$/;

export const userDataRegex = {
	cellPhoneNumberNoCode:
		/^([1-9]{1}(?![0]{8}|[1]{8}|[2]{8}|[3]{8}|[4]{8}|[5]{8}|[6]{8}|[7]{8}|[8]{8}|[9]{8})[0-9]{3}[0-9]{4,5})$/,
	cellPhoneNumber:
		/^(\([0-9]{2}\))([1-9]{1}(?![0]{8}|[1]{8}|[2]{8}|[3]{8}|[4]{8}|[5]{8}|[6]{8}|[7]{8}|[8]{8}|[9]{8})[0-9]{3}[0-9]{4,5})$/,
	phoneNumber:
		/^(\([0-9]{2}\))([2-5]{1}(?![0]{7}|[1]{7}|[2]{7}|[3]{7}|[4]{7}|[5]{7}|[6]{7}|[7]{7}|[8]{7}|[9]{7})[0-9]{3}[0-9]{4})$/,
	emailAddress:
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
	emailAddressUpdated:
		/^(([^<>()/[\]\\.,;:\s@"]+(\.[^<>()/[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	dateWithoutTime:
		/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
	cellPhoneOrPhoneNumberWithAreaCode:
		/^(\([0-9]{2}\))([1-9]{1}(?!([0]{7,8})|[1]{7,8}|[2]{7,8}|[3]{7,8}|[4]{7,8}|[5]{7,8}|[6]{7,8}|[7]{7,8}|[8]{7,8}|[9]{7,8})[0-9]{3}[0-9]{4,5})$/,
};
