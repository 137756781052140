import { CancelarPlacaPrimeiroEmplacamentoRequest, Types } from './types';

export function cancelarPlacaPrimeiroEmplacamentoRequest(
	payload: CancelarPlacaPrimeiroEmplacamentoRequest,
) {
	return {
		type: Types.CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_REQUEST,
		payload,
	};
}
export function cancelarPlacaPrimeiroEmplacamentoSuccess(payload: any) {
	return {
		type: Types.CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_SUCCESS,
		payload,
	};
}
export function cancelarPlacaPrimeiroEmplacamentoFailure(payload: []): any {
	return {
		type: Types.CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_FAILURE,
		payload,
	};
}
export function cancelarPlacaPrimeiroEmplacamentoClear(): any {
	return {
		type: Types.CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_CLEAR,
	};
}
