import { all } from 'redux-saga/effects';

import atualizarMenusSaga from './atualizarMenus/saga';
import cadastrarMenusSaga from './cadastrarMenus/saga';
import consultarMenusSaga from './consultarMenus/saga';
import excluirMenusSaga from './excluirMenus/saga';
import consultarMenuSaga from './consultarMenu/saga';

export default all([
	atualizarMenusSaga,
	cadastrarMenusSaga,
	consultarMenusSaga,
	excluirMenusSaga,
	consultarMenuSaga,
]);
