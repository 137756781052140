import { combineReducers } from 'redux';

import desbloqueiaCartao from './desbloqueioCartao/reducer';
import alteraEndereco from './alteraEndereco/reducer';
import consultarTipoContato from './consultarTipoContato/reducer';
import cadastrarContato from './cadastrarContato/reducer';
import consultarContato from './consultarContato/reducer';
import excluirContato from './excluirContato/reducer';
import atualizarContato from './atualizarContato/reducer';
import consultarEndereco from './consultarEndereco/reducer';
import editaCadastraEndereco from './editaCadastraEndereco/reducer';
import consultarTiposLogradouros from './consultarTiposLogradouros/reducer';
import consultarTiposResidencias from './consultarTiposResidencias/reducer';
import getCpfPermitido from './getCpfPermitido/reducer';

export default combineReducers({
	desbloqueiaCartao,
	alteraEndereco,
	consultarTipoContato,
	cadastrarContato,
	consultarContato,
	excluirContato,
	atualizarContato,
	consultarEndereco,
	editaCadastraEndereco,
	consultarTiposLogradouros,
	consultarTiposResidencias,
	getCpfPermitido,
});
