export enum Types {
	REMOVER_RESPONSAVEL_REQUEST = '@EDUCACAO/REMOVER_RESPONSAVEL_REQUEST',
	REMOVER_RESPONSAVEL_SUCCESS = '@EDUCACAO/REMOVER_RESPONSAVEL_SUCCESS',
	REMOVER_RESPONSAVEL_FAILURE = '@EDUCACAO/REMOVER_RESPONSAVEL_FAILURE',
	REMOVER_RESPONSAVEL_CLEAR = '@EDUCACAO/CADASTRAR_RESPONSAVEL_CLEAR',
}

export interface RemoverResponsavel {
	status: number;
	data: RemoverResponsavelData | null;
}

export interface RemoverResponsavelRequest {
	responsavel: {
		cpf: string;
	};
	aluno: {
		numeroRA: string;
		digitoRA: string;
		siglaUFRA: string;
	};
	documentosAluno: {
		numeroCpf: string;
		numeroRg: string;
		siglaUfRg: string;
	};
}

export interface RemoverResponsavelData {
	processoId: string;
	sucesso: string;
}
