export enum Types {
	CADASTRO_AUTORIZACAO_ESPECIAL_REQUEST = '@iirgd/CADASTRO_AUTORIZACAO_ESPECIAL_REQUEST',
	CADASTRO_AUTORIZACAO_ESPECIAL_SUCCESS = '@iirgd/CADASTRO_AUTORIZACAO_ESPECIAL_SUCCESS',
	CADASTRO_AUTORIZACAO_ESPECIAL_FAILURE = '@iirgd/CADASTRO_AUTORIZACAO_ESPECIAL_FAILURE',
	CADASTRO_AUTORIZACAO_ESPECIAL_CLEAR = '@iirgd/CADASTRO_AUTORIZACAO_ESPECIAL_CLEAR',
}

export interface CadastroAutorizacaoEspecial {
	status: number;
	data: null | CadastroAutorizacaoEspecialData;
	request: null | CadastroAutorizacaoEspecialRequest;
}

export interface CadastroAutorizacaoEspecialData {
	nome: string;
	status: string;
	statusAutorizacao: string;
	statusMSG: string;
	interMSG: string;
}

export interface CadastroAutorizacaoEspecialRequest {
	confirma: string;
	atendente: string;
	motivo: string;
	usuario: string;
	senha: string;
}
