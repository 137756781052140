import React, { Suspense, useEffect, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import PDFViewer from 'components/Common/PDFViewer';
import {
	impressaoTrocaVoluntariaPlacaClear,
	impressaoTrocaVoluntariaPlacaRequest,
} from 'store/modules/api/detranCrv/Processos/ImpressaoTrocaVoluntariaPlaca/actions';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';
import { initialValues, schema, treatValues } from './form';

const ImpressaoTrocaVoluntariaPlaca: React.FC = () => {
	const dispatch = useDispatch();

	const { impressaoTrocaVoluntariaPlaca } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.processos,
	);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { salvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento,
	);

	const [results, setResults] = useState<boolean>(false);

	useEffect(() => {
		if (impressaoTrocaVoluntariaPlaca.status === 200) {
			setResults(true);
		}
	}, [impressaoTrocaVoluntariaPlaca]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						const sguData: SguData = {
							salvarAtendimento,
							login,
						};
						const evento = getEventoConsultar(sguData);

						dispatch(
							impressaoTrocaVoluntariaPlacaRequest(treatValues(evento, values)),
						);
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={14}>
										<Field
											titleSize="lg"
											required={!results}
											maxLength={7}
											readOnly={results}
											name="numeroFicha"
											title="Número da Ficha"
											as={Input}
											error={hasError(formik.errors, 'numeroFicha')}
										/>
									</Col>
									<Col span={10} />
									<Col span={14}>
										<Field
											titleSize="lg"
											required={!results}
											readOnly={results}
											maxLength={results ? '' : 4}
											name="anoFicha"
											title="Ano da Ficha Cadastral"
											as={Input}
											error={hasError(formik.errors, 'anoFicha')}
										/>
									</Col>
									<Col span={8} />
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									{!results && (
										<Col>
											<ButtonImage
												type="button"
												src="limpar"
												onClick={formik.handleReset}
											/>
										</Col>
									)}
									<Col>
										<ButtonImage
											type="submit"
											src={results ? 'imprimir' : 'pesquisar'}
										/>
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			{impressaoTrocaVoluntariaPlaca.data?.fichaCadastral && (
				<PDFViewer
					title="Impressão Troca Voluntária da Placa"
					source={impressaoTrocaVoluntariaPlaca.data?.fichaCadastral}
				/>
			)}
			<ButtonVoltar
				onClick={() => dispatch(impressaoTrocaVoluntariaPlacaClear())}
			/>
		</Suspense>
	);
};

export default ImpressaoTrocaVoluntariaPlaca;
