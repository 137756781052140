export enum Types {
	CONSULTA_CONSUMIDOR_REQUEST = '@procon/CONSULTA_CONSUMIDOR_REQUEST',
	CONSULTA_CONSUMIDOR_SUCCESS = '@procon/CONSULTA_CONSUMIDOR_SUCCESS',
	CONSULTA_CONSUMIDOR_FAILURE = '@procon/CONSULTA_CONSUMIDOR_FAILURE',
	CONSULTA_CONSUMIDOR_CLEAR = '@procon/CONSULTA_CONSUMIDOR_CLEAR',
}

export interface ConsultaConsumidor {
	status: null | number;
	data: null | ConsultaConsumidorData;
}

export interface ConsultaConsumidorData {
	consumidorId: string;
	cpf: string;
	email: string;
	nomeCompleto: string;
	telefoneCelular: string;
	telefoneFixo: string;
	rg: string;
	orgaoEmissor: string;
	ufEmissor: string;
	dataDeNascimento: string;
	idade: number;
	sexo: string;
	pessoaComDeficiencia: boolean;
	endereco: {
		bairro: string;
		cep: string;
		estado: string;
		logradouro: string;
		municipio: string;
		municipioId: string;
		numero: string;
	};
}

interface Evento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string | number;
	ip: string;
	canal: {
		id: number;
		desc: string;
		estacao: {
			id: string;
			desc: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
	atendente: {
		nome: string;
		id_atendente: string;
		id_posto: string;
		desc_posto: string;
	};
}

export interface ConsultaConsumidorRequest {
	servico: string;
	cpf: string;
	evento: Evento;
}
