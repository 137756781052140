import { ReducerAction } from 'store/modules/types';
import { ConsultaRestricoesRequest, Types } from './types';

export function consultaRestricoesRequest(payload: ConsultaRestricoesRequest) {
	return {
		type: Types.CONSULTA_RESTRICOES_REQUEST,
		payload,
	};
}
export function consultaRestricoesSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_RESTRICOES_SUCCESS,
		payload,
	};
}
export function consultaRestricoesFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_RESTRICOES_FAILURE,
		payload,
	};
}
export function consultaRestricoesClear(): ReducerAction {
	return {
		type: Types.CONSULTA_RESTRICOES_CLEAR,
		payload: null,
	};
}
