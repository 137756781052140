import * as Yup from 'yup';

import { RespostaResolvidaForm } from 'store/modules/api/procon/atendimentoCip/respostaResolvida/types';
import { RespostaNaoResolvidaForm } from 'store/modules/api/procon/atendimentoCip/respostaNaoResolvida/types';

export const initialValuesResolvido: RespostaResolvidaForm = {
	tipoDaResolucao: undefined,
	justificativa: '',
};

export const initialValuesNaoResolvido: RespostaNaoResolvidaForm = {
	justificativa: '',
	anexos: [],
};

export const schemaResolvido = Yup.object<RespostaResolvidaForm>().shape({
	tipoDaResolucao: Yup.number().required('Campo Obrigatório: Resolução.'),
	justificativa: Yup.string().min(
		50,
		'Campo Obrigatório: Mínimo de 50 caracteres para a Justificativa.',
	),
});

export const schemaNaoResolvido = Yup.object<RespostaResolvidaForm>().shape({
	justificativa: Yup.string().min(
		50,
		'Campo Obrigatório: Mínimo de 50 caracteres para a Justificativa.',
	),
});
