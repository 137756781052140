import * as Yup from 'yup';

export interface IBuscarMarcas {
	marca: string;
}

export const initialValues: IBuscarMarcas = {
	marca: '',
};

const schema = Yup.object<IBuscarMarcas>().shape({
	marca: Yup.string()
		.required('Campo Obrigatório: Marca.')
		.min(3, 'Campo Marca: Tamanho mínimo de 3 caracteres.'),
});

export default async function getValidationsErrors(values: IBuscarMarcas) {
	try {
		await schema.validate(values, {
			abortEarly: false,
		});

		return true;
	} catch (err) {
		let errors: any = {};

		err.inner.forEach((error: any) => {
			errors = { ...errors, [error.path]: error.message };
		});

		return errors;
	}
}
