import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AtualizarTiposRequest } from './types';

// ACTIONS
import { atualizarTiposSuccess, atualizarTiposFailure } from './actions';

function* atualizarTipos(request: ReducerAction) {
	const { payload }: { payload: AtualizarTiposRequest } = request;

	const body = { ...payload };

	delete body.id;

	const response: ApiResponse = yield call(
		putApi,
		PATH_SGU,
		`tipos-localidade/${payload.id}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarTiposSuccess(response));
	} else {
		yield put(atualizarTiposFailure());
	}
}

export default all([takeLatest(Types.ATUALIZAR_TIPOS, atualizarTipos)]);
