import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ApiResponse, getApi } from 'services/rotasp/api';
import { ReducerActionRotaSP } from 'store/modules/types';
import { PATH_EDUCACAO_SEDUC_DOMINIOS_API } from 'services/rotasp/path';
import { Types } from './types';
import { comboPaisesSuccess, comboPaisesFailure } from './actions';

function* ComboPaisesRequest(request: ReducerActionRotaSP) {
	const { token }: { token?: string } = request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_EDUCACAO_SEDUC_DOMINIOS_API,
		'paises',
	);
	if (response.status === 200) {
		yield put(comboPaisesSuccess(response));
	} else {
		yield put(comboPaisesFailure(response));
	}
}

export default all([
	takeLatest(Types.COMBO_PAISES_REQUEST, ComboPaisesRequest),
]);
