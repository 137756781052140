import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaRestricoes } from './types';

export const INITIAL_STATE: ConsultaRestricoes = {
	status: 0,
	data: null,
	dataRequest: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaRestricoes {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_RESTRICOES_REQUEST: {
				draft.status = 100;
				break;
			}

			case Types.CONSULTA_RESTRICOES_SUCCESS: {
				draft.status = action.payload.response.status;
				draft.data = action.payload.response.data;
				draft.dataRequest = action.payload.payload;
				break;
			}

			case Types.CONSULTA_RESTRICOES_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTA_RESTRICOES_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataRequest = INITIAL_STATE.dataRequest;
				break;
			}

			default:
		}
		return draft;
	});
}
