import { ReducerAction } from 'store/modules/types';

import { Types, EventoInicioSessaoRequest } from './types';

export function eventoInicioSessaoRequest(
	payload: EventoInicioSessaoRequest,
): ReducerAction {
	return {
		type: Types.EVENTO_INICIO_SESSAO_REQUEST,
		payload,
	};
}
export function eventoInicioSessaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.EVENTO_INICIO_SESSAO_SUCCESS,
		payload,
	};
}
export function eventoInicioSessaoFailure(payload: object): ReducerAction {
	return {
		type: Types.EVENTO_INICIO_SESSAO_FAILURE,
		payload,
	};
}
export function eventoInicioSessaoClear(): ReducerAction {
	return {
		type: Types.EVENTO_INICIO_SESSAO_CLEAR,
		payload: null,
	};
}
