import React from 'react';

// STYLED
import {
	TypographyH4Styled,
	TypographyH5Styled,
	TypographyLabelStyled,
} from './styled';

interface Props {
	text: string;
	borderBottom?: boolean;
	underline?: boolean;
	type?: 'h5' | 'h4' | 'label';
	fontWeight?:
		| 100
		| 200
		| 300
		| 400
		| 500
		| 600
		| 700
		| 800
		| 900
		| 'bold'
		| 'bolder'
		| 'normal';
	required?: boolean;
	subtitle?: string;
	color?: string;
	uppercase?: boolean;
	capitalize?: boolean;
	labelSize?: number;
	textCenter?: boolean;
}

const TypographyCommon: React.FC<Props> = ({
	text,
	borderBottom = false,
	underline = false,
	type = 'label',
	fontWeight = 'normal',
	required = false,
	subtitle,
	color = '#2e2e2e',
	uppercase = false,
	capitalize = false,
	labelSize = 11.5,
	textCenter = false,
}) => {
	switch (type) {
		case 'h5':
			return (
				<TypographyH5Styled
					borderBottom={borderBottom}
					required={required}
					fontWeight={fontWeight}
					color={color}
					uppercase={uppercase}
					capitalize={capitalize}
				>
					{text}
				</TypographyH5Styled>
			);
		case 'h4':
			return (
				<TypographyH4Styled
					borderBottom={borderBottom}
					required={required}
					fontWeight={fontWeight}
					color={color}
					uppercase={uppercase}
					capitalize={capitalize}
				>
					{text}
				</TypographyH4Styled>
			);
		case 'label':
			return (
				<TypographyLabelStyled
					fontWeight={fontWeight}
					required={required}
					subtitle={subtitle}
					color={color}
					uppercase={uppercase}
					capitalize={capitalize}
					underline={underline}
					labelSize={labelSize}
					textCenter={textCenter}
				>
					{text}
				</TypographyLabelStyled>
			);
		default:
			return (
				<TypographyLabelStyled
					fontWeight={fontWeight}
					required={required}
					subtitle={subtitle}
					color={color}
					uppercase={uppercase}
					capitalize={capitalize}
					underline={underline}
					labelSize={labelSize}
					textCenter={textCenter}
				>
					{text}
				</TypographyLabelStyled>
			);
	}
};

export default TypographyCommon;
