import produce from 'immer';

// UTILS
import { toSelect } from 'utils/genericFunctions';

// TYPES
import { Types, ConsultaCategoria } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultaCategoria = {
	status: 0,
	data: [],
	divisaoEquitativa: [],
};

export default function modulo(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaCategoria {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_CATEGORIA_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.CONSULTA_CATEGORIA_SUCCESS: {
				draft.status = action.payload.status;
				draft.divisaoEquitativa = action.payload.data;
				const lista = toSelect(action.payload.data, 'descricao', 'codigo');

				draft.data = [...lista];
				break;
			}
			case Types.CONSULTA_CATEGORIA_FAILURE: {
				draft.status = action.payload.status;
				draft.data = INITIAL_STATE.data;
				draft.divisaoEquitativa = INITIAL_STATE.divisaoEquitativa;
				break;
			}

			default:
		}
	});
}
