// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function consultaPedidosConsumidorRequest(): ReducerAction {
	return {
		type: Types.CONSULTA_PEDIDOS_CONSUMIDOR_REQUEST,
		payload: null,
	};
}
export function consultaPedidosConsumidorSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_PEDIDOS_CONSUMIDOR_SUCCESS,
		payload,
	};
}
export function consultaPedidosConsumidorFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_PEDIDOS_CONSUMIDOR_FAILURE,
		payload,
	};
}
export function consultaPedidosConsumidorClear(): ReducerAction {
	return {
		type: Types.CONSULTA_PEDIDOS_CONSUMIDOR_CLEAR,
		payload: null,
	};
}
