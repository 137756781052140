import React from 'react';

import { Row, Col } from 'antd';

// COMPONENTES
import Input from 'components/Common/Form/Input/Input';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import Section from 'components/Common/Section';

// PATHS
import { ROUTE_IIRGD } from 'routes/paths';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { onlyNumbersAndLetters } from 'utils/genericFunctions';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// FORM
import { Field, Form, Formik } from 'formik';
import { initialValues, schema, DadosAgendamentoValues } from './form';
import { Title } from './styled';

interface DadosAgendamentoProps {
	handleSubmit: (values: DadosAgendamentoValues) => void;
}

const DadosAgendamento: React.FC<DadosAgendamentoProps> = ({
	handleSubmit,
}) => {
	const { notifications } = useSelector((state: ApplicationState) => state.app);

	return (
		<Row>
			<Col span={24}>
				<Section title="Dados do Agendamento">
					<Formik
						validateOnChange={notifications.errors?.length > 0 || false}
						validateOnBlur={false}
						initialValues={initialValues}
						validate={values => getValidationsErrors(values, schema)}
						onSubmit={values => handleSubmit(values)}
					>
						{formik => (
							<Form autoComplete="off">
								<Section bgColor="cinza-claro">
									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Title>Preencher um dos campos abaixo:</Title>
										</Col>
									</Row>

									<Row>
										<Col span={10}>
											<Field
												as={Input}
												title="Número do Protocolo"
												name="numeroProtocolo"
												maxLength="12"
												titleAlign="start"
												titleSize="150"
												onChange={(v: React.FormEvent<HTMLInputElement>) =>
													formik.setFieldValue(
														'numeroProtocolo',
														onlyNumbersAndLetters(v.currentTarget.value),
													)
												}
												required={formik.values.senhaAtendimento === ''}
												error={!!formik.errors.inputsValidation}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Field
												as={Input}
												title="Senha do Atendimento"
												name="senhaAtendimento"
												maxLength="5"
												titleAlign="start"
												titleSize="150"
												onChange={(v: React.FormEvent<HTMLInputElement>) =>
													formik.setFieldValue(
														'senhaAtendimento',
														onlyNumbersAndLetters(v.currentTarget.value),
													)
												}
												required={formik.values.numeroProtocolo === ''}
												error={!!formik.errors.inputsValidation}
											/>
										</Col>
									</Row>
								</Section>
								<Row gutter={[0, 20]}>
									<Col span={24} />
								</Row>
								<Row gutter={[0, 10]} justify="center">
									<Col>
										<ButtonImage src="avancar" type="submit" />
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</Section>

				<ButtonVoltar route={ROUTE_IIRGD} />
			</Col>
		</Row>
	);
};

export default DadosAgendamento;
