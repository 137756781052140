import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirOrgaoRequest } from './types';

export function excluirOrgaoRequest(data: ExcluirOrgaoRequest): ReducerAction {
	return {
		type: Types.EXCLUIR_ORGAO,
		payload: data,
	};
}
export function excluirOrgaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_ORGAO_SUCCESS,
		payload,
	};
}
export function excluirOrgaoFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_ORGAO_FAILURE,
		payload: null,
	};
}
export function excluirOrgaoClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_ORGAO_CLEAR,
		payload: null,
	};
}
