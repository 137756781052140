import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_SGU_SERVICE } from 'services/_path';

// import { getApi } from 'services/apiAtendimento';

import { ReducerAction } from 'store/modules/types';
import { Types, IRequestRecuperarATendimento } from './types';

import {
	consultaAtendimentoSuccess,
	consultaAtendimentoFailure,
	// consultaAtendimentoUserDataSuccess,
} from './actions';

function* consultaAtendimento(request: ReducerAction) {
	const { payload }: { payload: IRequestRecuperarATendimento } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU_SERVICE,
		`usuario/${CHANNEL}/recuperar-atendimento?cpf=${payload}`,
	);

	if (response.status === 200) {
		yield put(consultaAtendimentoSuccess(response));
	} else if (response.error) {
		yield put(consultaAtendimentoFailure(response.error));
	}
}

export default all([
	takeLatest(Types.CONSULTA_ATENDIMENTO, consultaAtendimento),
	// takeLatest(Types.CONSULTA_ATENDIMENTO_USER_DATA, consultaAtendimentoUserData),
]);
