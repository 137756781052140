import { all } from 'redux-saga/effects';

import pesquisarAluno from './PesquisarAluno/saga';
import consultarFichaAluno from './ConsultarFicha/saga';
import mantemDadosConsulta from './mantemDadosConsulta/saga';
import mantemDadosAluno from './mantemDadosAluno/saga';

export default all([
	pesquisarAluno,
	consultarFichaAluno,
	mantemDadosConsulta,
	mantemDadosAluno,
]);
