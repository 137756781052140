export enum Types {
	CONFIRMAR_PLACA_REQUEST = '@DETRAN-CRV/CONFIRMAR_PLACA_REQUEST',
	CONFIRMAR_PLACA_SUCCESS = '@DETRAN-CRV/CONFIRMAR_PLACA_SUCCESS',
	CONFIRMAR_PLACA_FAILURE = '@DETRAN-CRV/CONFIRMAR_PLACA_FAILURE',
	CONFIRMAR_PLACA_CLEAR = '@DETRAN-CRV/CONFIRMAR_PLACA_CLEAR',
}

export interface confirmarPlaca {
	status: number;
	data: placa | null;
	placaEscolhida?: string;
}

interface placa {
	chassi: string;
	cpfCnpjProprietario: string;
	placaEscolhida: string;
}
