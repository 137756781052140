import * as Yup from 'yup';
import {
	CadastrarInscricaoRequest,
	Inscricao as InscricaoRequest,
} from 'store/modules/api/educacaoUpdate/inscricao/cadastrarInscricao/types';

export interface InscricaoAlunoForm {
	idProcesso: number | string;
	mensagem: string;
	aluno: Aluno;
	inscricao: Inscricao;
	nivelEnsino: NivelEnsino;
}

export interface IInscriçãoCodigoEducacao {
	codigoUnidade: number | string;
	codigoEscola: number | string;
}

interface Aluno {
	numeroRA: string | undefined;
	digitoRA: string | undefined;
	siglaUFRA: string | undefined;
}

interface Inscricao {
	anoLetivo: string | number;
	codigoEscola: number | string;
	tipoEscola: string;
	codigoUnidade: number | string;
	fase: number | string;
	fasesInscricao: string | number;
	redeEnsino: string | number;
	ensinoTecProfissionalizante: boolean;
	ensinoTecProfissionalizante1: number | string;
	ensinoTecProfissionalizante2: number | string;
	ensinoTecProfissionalizante3: number | string;
	opcaoNoturno: string | number;
	tipoEnsino: TipoEnsino[];
	codigoSerieAno: string | number;
	eixo: Eixos[];
	interesseIntegral?: string;
	espanhol: string | number;
	atendimentoNoturno: string | number;
	codigoObservacaoOpcaoNoturno: string;
}

interface TipoEnsino {
	codigo: number;
	descricao: string;
	seriesAnos: SeriesAnos[];
}

interface SeriesAnos {
	codigo: number;
	descricao: string;
}

interface Eixos {
	codigo: number;
	nome: string;
}

interface NivelEnsino {
	codigoTipoEnsino: number | string;
	codigoSerieAno: number | string;
}

export const initialValues: InscricaoAlunoForm = {
	idProcesso: '',
	mensagem: '',
	aluno: {
		numeroRA: '',
		digitoRA: '',
		siglaUFRA: '',
	},
	inscricao: {
		anoLetivo: new Date().getFullYear(),
		codigoEscola: '',
		tipoEscola: '',
		codigoUnidade: '',
		fase: '',
		fasesInscricao: '',
		redeEnsino: '',
		ensinoTecProfissionalizante: false,
		ensinoTecProfissionalizante1: '',
		ensinoTecProfissionalizante2: '',
		ensinoTecProfissionalizante3: '',
		opcaoNoturno: '',
		tipoEnsino: [],
		codigoSerieAno: '',
		eixo: [],
		interesseIntegral: '',
		espanhol: '',
		atendimentoNoturno: '',
		codigoObservacaoOpcaoNoturno: '',
	},
	nivelEnsino: {
		codigoTipoEnsino: '',
		codigoSerieAno: '',
	},
};

export const schema = Yup.object<InscricaoAlunoForm>().shape({
	inscricao: Yup.object().shape({
		opcaoNoturno: Yup.string().when(['atendimentoNoturno'], {
			is: atendimentoNoturno => atendimentoNoturno,
			then: Yup.string().required(
				`Campo Obrigatório: Observação para a escolha do período noturno`,
			),
		}),
	}),
});

export function formatValuesUpdate(
	data: InscricaoAlunoForm,
	alunoEnvio: Aluno,
	inscriçãoCodigoEducacao: IInscriçãoCodigoEducacao,
): CadastrarInscricaoRequest {
	const aluno = {
		numeroRA: alunoEnvio.numeroRA?.trim() || '',
		digitoRA: alunoEnvio.digitoRA?.trim() || '',
		siglaUFRA: alunoEnvio.siglaUFRA?.trim() || '',
	};

	const inscricao: InscricaoRequest = {
		anoLetivo: data.inscricao?.anoLetivo?.toString()?.trim() || '',
		codigoEscola:
			inscriçãoCodigoEducacao.codigoEscola?.toString()?.trim() || '',
		codigoUnidade:
			inscriçãoCodigoEducacao.codigoUnidade?.toString()?.trim() || '',
		fase: data.inscricao.fasesInscricao?.toString()?.trim() || '',
		interesseIntegral: !!data.inscricao.interesseIntegral,
		interesseEspanhol: !!data.inscricao.espanhol,
		atendimentoNoturno: !!data.inscricao.atendimentoNoturno,
		codigoObservacaoOpcaoNoturno: data.inscricao.codigoObservacaoOpcaoNoturno,
		codigoEscolaNovotecOpcao1: '',
		codigoUnidadeNovotecOpcao1: '',
		interesseCentroIdiomas: false,
		aceiteEscolaPEIDistante: false,
		codigoEixoCELOpcao1: '',
		codigoEscolaCELOpcao1: '',
		codigoUnidadeCELOpcao1: '',
		interesseNovoTec: false,
		codigoEixoNovotecOpcao1: '',
		codigoEixoNovotecOpcao2: '',
		codigoEixoNovotecOpcao3: '',
	};

	const nivelEnsino = {
		codigoTipoEnsino: data.nivelEnsino?.codigoTipoEnsino?.toString() || '',
		codigoSerieAno: data.nivelEnsino?.codigoSerieAno?.toString() || '',
	};

	if (data.inscricao?.ensinoTecProfissionalizante) {
		inscricao.interesseNovoTec = true;
		inscricao.codigoEixoNovotecOpcao1 =
			data.inscricao.ensinoTecProfissionalizante1.toString();
		inscricao.codigoEixoNovotecOpcao2 =
			data.inscricao.ensinoTecProfissionalizante2.toString();
		inscricao.codigoEixoNovotecOpcao3 =
			data.inscricao.ensinoTecProfissionalizante3.toString();
	}

	const requestValues = {
		aluno,
		inscricao,
		nivelEnsino,
	};

	return requestValues;
}
