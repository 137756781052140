export enum Types {
	DADOS_BASICOS_REQUEST = '@EDUCACAO/DADOS_BASICOS_REQUEST',
	DADOS_BASICOS_SUCCESS = '@EDUCACAO/DADOS_BASICOS_SUCCESS',
	DADOS_BASICOS_FAILURE = '@EDUCACAO/DADOS_BASICOS_FAILURE',
}

export interface DadosBasicos {
	status: number;
	data: null | DadosBasicosData;
}

export interface DadosBasicosData {
	processoId: string;
	coresRacas: EnumDadosBasicos[];
	nacionalidades: EnumDadosBasicos[];
	localizacoesDiferenciadas: EnumDadosBasicos[];
	deficiencias: EnumDadosBasicos[];
	justificativas: EnumDadosBasicos[];
	sexos: EnumDadosBasicos[];
	fasesInscricao: EnumDadosBasicos[];
	fasesDefinicao: EnumDadosBasicos[];
	fasesMovimentacao: EnumDadosBasicos[];
	redesEnsino: EnumDadosBasicos[];
	turnos: EnumDadosBasicos[];
	motivosTransferencia: EnumDadosBasicos[];
	opcoesNoturno: EnumDadosBasicos[];
	tiposTelefone: EnumDadosBasicos[];
	provasAmpliadas: ProvasAmpliadas[];
	areasLogradouro: EnumDadosBasicos[];
	responsabilidades: EnumDadosBasicos[];
	estadosCivis: EnumDadosBasicos[];
	origens: EnumDadosBasicos[];
}
export interface EnumDadosBasicos {
	codigo: string;
	descricao: string;
}

interface ProvasAmpliadas {
	tamanhoFonte: string;
}

// interface EnumDeficiencia {
// 	codigo: string;
// 	descricao: string;
// 	mobilidadeReduzida: string;
// 	tipoMobilidadeReduzida: string;
// 	cuidador: string;
// 	tipoCuidador: string;
// 	profSaude: string;
// 	tipoProfSaude: string;
// }
