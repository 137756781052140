import {
	IConsultarDare,
	ITaxaConsultarDare,
} from 'store/modules/api/dare/consultarDare/types';

export enum Types {
	CONSULTAR_DARE_RETAGUARDA_REQUEST = '@dare/CONSULTAR_DARE_RETAGUARDA_REQUEST',
	CONSULTAR_DARE_RETAGUARDA_SUCCESS = '@dare/CONSULTAR_DARE_RETAGUARDA_SUCCESS',
	CONSULTAR_DARE_RETAGUARDA_FAILURE = '@dare/CONSULTAR_DARE_RETAGUARDA_FAILURE',
	CONSULTAR_DARE_RETAGUARDA_CLEAR = '@dare/CONSULTAR_DARE_RETAGUARDA_CLEAR',
}

export interface IConsultarDareRetaguardaState {
	status: number;
	data: IConsultarDare[] | null;
	taxasRes: ITaxaConsultarDare[] | null;
}
