import { ReducerActionRotaSP } from 'store/modules/types';
import { ConsultaInscricaoRequest, Types } from './types';

export function consultarInscricaoRequest(
	token: string,
	payload: ConsultaInscricaoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_INSCRICAO_REQUEST,
		payload,
		token,
	};
}

export function selecionarInscricao(payload: any) {
	return {
		type: Types.SELECIONAR_INSCRICAO,
		payload,
	};
}

export function consultarInscricaoNoContent(payload: object) {
	return {
		type: Types.CONSULTAR_INSCRICAO_NO_CONTENT,
		payload,
	};
}

export function consultarInscricaoSuccess(payload: object) {
	return {
		type: Types.CONSULTAR_INSCRICAO_SUCCESS,
		payload,
	};
}

export function consultarInscricaoFailure(payload: boolean) {
	return {
		type: Types.CONSULTAR_INSCRICAO_FAILURE,
		payload,
	};
}

export function consultarInscricaoClear() {
	return {
		type: Types.CONSULTAR_INSCRICAO_CLEAR,
	};
}
