import { takeLatest, call, put, all } from 'redux-saga/effects';

// STORE
import { ReducerAction } from 'store/modules/types';

// ENTITIES
import { IConsultarDare } from 'store/modules/api/dare/consultarDare/types';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// TYPES
import { IFormCadastroRg } from 'pages/iirgd/RgFicDigital/CadastroRg/form';
import { RequestAlterarObservacoes, Types } from './types';

// ACTIONS
import {
	alterarObservacoesSuccess,
	alterarObservacoesFailure,
} from './actions';
import { cadastroRgPrincipal } from '../cadastroRg/actions';

function* alterarObservacoesRequest(request: ReducerAction) {
	const { payload } = request;

	const {
		formObservacoes,
		dare,
		cadastroRg,
	}: {
		formObservacoes: RequestAlterarObservacoes;
		dare: IConsultarDare | null;
		cadastroRg: IFormCadastroRg;
	} = payload;

	let values = formObservacoes;

	if (dare) {
		const hasTaxaEmissao = dare.taxasRes.filter(
			taxa => taxa.servicoTipo === 1300057 || taxa.servicoTipo === 1200057,
		);

		if (hasTaxaEmissao.length > 0) {
			const { status } = dare;

			if ([1, 2, 6].includes(status)) {
				values = { ...formObservacoes, pagto2Via: 'P' };
			} else {
				values = { ...formObservacoes, pagto2Via: 'A' };
			}
		}
	} else {
		values = { ...formObservacoes, pagto2Via: '' };
	}

	if (
		formObservacoes.declaracaoPobreza ||
		formObservacoes.isento2Via10952 ||
		formObservacoes.isento2Via062
	) {
		values = { ...formObservacoes, pagto2Via: '' };
	}

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		'alterarObservacoes',
		values,
	);

	if (response.status === 200) {
		yield put(
			alterarObservacoesSuccess(
				response.status,
				!!response?.data?.cpf,
				response?.data?.mensagemCPF,
			),
		);
		if (response?.data?.cpf && cadastroRg) {
			yield put(
				cadastroRgPrincipal(
					null,
					{
						...cadastroRg,
						cpf: response.data.cpf,
					},
					null,
				),
			);
		}
	} else if (response.error) {
		yield put(alterarObservacoesFailure());
	}
}

export default all([
	takeLatest(Types.ALTERAR_OBSERVACOES_REQUEST, alterarObservacoesRequest),
]);
