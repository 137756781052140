import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { ConsultarUnidadesRequest, Types } from './types';
import { consultarUnidadesSuccess, consultarUnidadesFailure } from './actions';

// UTILS

function* consultarUnidadesRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultarUnidadesRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarUnidadesRequest;

	const response: ApiResponse = yield call(getApi, PATH_SGU, `unidades`, body);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarUnidadesSuccess(response));
	} else {
		yield put(consultarUnidadesFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_UNIDADES_REQUEST, consultarUnidadesRequest),
]);
