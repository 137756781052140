import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarResponsavel } from './types';

export const INITIAL_STATE: CadastrarResponsavel = {
	status: 0,
	data: null,
	latlng: null,
	form: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): CadastrarResponsavel {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CADASTRAR_RESPONSAVEL_REQUEST: {
				draft.form = action.payload;
				break;
			}
			case Types.LAT_LNG_SELECIONADA: {
				draft.latlng = action.payload;
				break;
			}
			case Types.CADASTRAR_RESPONSAVEL_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.data;
				break;
			}
			case Types.CADASTRAR_RESPONSAVEL_FAILURE: {
				draft.status = 400;
				break;
			}
			case Types.CADASTRAR_RESPONSAVEL_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.latlng = INITIAL_STATE.latlng;
				break;
			}
			case Types.PRE_CADASTRO_RESPONSAVEL_REQUEST: {
				draft.status = 999;
				draft.data = { responsavel: action.payload };
				break;
			}
			case Types.PRE_CADASTRO_RESPONSAVEL_CLEAR: {
				draft.status = 0;
				draft.data = null;
				break;
			}
			default:
		}
		return draft;
	});
}
