import produce from 'immer';

import { Types, consultaEPortal } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaEPortal = {
	status: 0,
	data: null,
};

export default function consultaEPortalReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaEPortal {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_E_PORTAL_REQUEST: {
				break;
			}
			case Types.CONSULTA_E_PORTAL_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTA_E_PORTAL_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTA_E_PORTAL_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
	});
}
