import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { toSelect } from 'utils/genericFunctions';
import { Types, consultaCategoria } from './types';

export const INITIAL_STATE: consultaCategoria = {
	status: 0,
	data: [],
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaCategoria {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_CATEGORIA_REQUEST: {
				break;
			}

			case Types.CONSULTA_CATEGORIA_SUCCESS: {
				draft.status = action.payload.status;
				const select = toSelect(
					action.payload.data.categorias,
					'descricao',
					'longId',
				);
				draft.data = select;
				break;
			}

			case Types.CONSULTA_CATEGORIA_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			default:
		}
		return draft;
	});
}
