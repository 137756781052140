import React, { Suspense } from 'react';

// ANTD
import { Row, Col, Skeleton } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { excluirFichaCadastralClear } from 'store/modules/api/detranCrv/Processos/ExcluirFichaCadastral/actions';

// COMPONENTS COMMON
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';

const ExcluirFichaCadastralResultado: React.FC = () => {
	const dispatch = useDispatch();

	const data = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.processos.excluirFichaCadastral.data?.ficha,
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Row>
				<Col span={24}>
					<Section size="sm" title="CONFIRMAÇÃO DA EXCLUSÃO DA FICHA CADASTRAL">
						<Row gutter={[0, 10]}>
							<Col span={12}>N° da Ficha</Col>

							<Col span={12}>
								<strong>{data?.numeroFicha}</strong>
							</Col>

							<Col span={12}>Ano Ficha</Col>

							<Col span={12}>
								<strong>{data?.anoFicha}</strong>
							</Col>

							<Col span={12}>Data da Exclusão da Ficha</Col>

							<Col span={12}>
								<strong>
									{data?.dataHoraExlcusao &&
										new Date(data?.dataHoraExlcusao).toLocaleDateString(
											'pt-BR',
										)}
								</strong>
							</Col>
						</Row>
					</Section>

					<Row justify="space-around" align="middle">
						<Col>
							<ButtonVoltar
								onClick={() => dispatch(excluirFichaCadastralClear())}
							/>
						</Col>

						<Col>
							<Button className="no-print" onClick={() => window.print()}>
								{' '}
								Imprimir
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Suspense>
	);
};

export default ExcluirFichaCadastralResultado;
