import { ReducerAction } from 'store/modules/types';

import {
	Types,
	IRequestConsultaBeneficiario,
	IRequestSelectMotivosCorte,
	IRequestExcluir,
	IRequestCotas,
	IRequestIncluirBeneficiarioBusca,
	IRequestIncluirBeneficiario,
} from './types';

export function consultaBeneficiario(
	data: IRequestConsultaBeneficiario,
): ReducerAction {
	return {
		type: Types.CONSULTA_BENEFICIARIOS,
		payload: data,
	};
}
export function consultaBeneficiarioSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_BENEFICIARIOS_SUCCESS,
		payload,
	};
}
export function consultaBeneficiarioFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_BENEFICIARIOS_FAILURE,
		payload: null,
	};
}

export function consultaMotivosCorte(
	data: IRequestSelectMotivosCorte,
): ReducerAction {
	return {
		type: Types.CONSULTA_MOTIVOS_CORTE,
		payload: data,
	};
}
export function consultaMotivosCorteSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_MOTIVOS_CORTE_SUCCESS,
		payload,
	};
}
export function consultaMotivosCorteFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_MOTIVOS_CORTE_FAILURE,
		payload: null,
	};
}

export function removeBeneficiarios(data: IRequestExcluir): ReducerAction {
	return {
		type: Types.REMOVE_BENEFICIARIOS,
		payload: data,
	};
}
export function removeBeneficiariosSuccess(payload: object): ReducerAction {
	return {
		type: Types.REMOVE_BENEFICIARIOS_SUCCESS,
		payload,
	};
}
export function removeBeneficiariosFailure(): ReducerAction {
	return {
		type: Types.REMOVE_BENEFICIARIOS_FAILURE,
		payload: null,
	};
}

export function consultaCotasEntidade(data: IRequestCotas): ReducerAction {
	return {
		type: Types.CONSULTA_COTAS_ENTIDADE,
		payload: data,
	};
}
export function consultaCotasEntidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_COTAS_ENTIDADE_SUCCESS,
		payload,
	};
}
export function consultaCotasEntidadeFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_COTAS_ENTIDADE_FAILURE,
		payload: null,
	};
}

export function consultaBeneficiarioInclusao(
	data: IRequestIncluirBeneficiarioBusca,
): ReducerAction {
	return {
		type: Types.CONSULTA_BENEFICIARIOS_INCLUSAO,
		payload: data,
	};
}
export function consultaBeneficiarioInclusaoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_BENEFICIARIOS_INCLUSAO_SUCCESS,
		payload,
	};
}
export function consultaBeneficiarioInclusaoFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_BENEFICIARIOS_INCLUSAO_FAILURE,
		payload: null,
	};
}

export function cadastraBeneficiario(
	data: IRequestIncluirBeneficiario,
): ReducerAction {
	return {
		type: Types.CADASTRA_BENEFICIARIO,
		payload: data,
	};
}
export function cadastraBeneficiarioSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRA_BENEFICIARIO_SUCCESS,
		payload,
	};
}
export function cadastraBeneficiarioFailure(): ReducerAction {
	return {
		type: Types.CADASTRA_BENEFICIARIO_FAILURE,
		payload: null,
	};
}

export function clearBeneficiarioVivaLeite(): ReducerAction {
	return {
		type: Types.BENEFICIARIO_CLEAR,
		payload: null,
	};
}

export function clearFamiliarVivaLeite(): ReducerAction {
	return {
		type: Types.FAMILIAR_CLEAR,
		payload: null,
	};
}

export function clearNotificationsBeneficiariosVivaLeite(): ReducerAction {
	return {
		type: Types.NOTIFICATION_BENEFICIARIOS_VIVA_LEITE_CLEAR,
		payload: null,
	};
}
