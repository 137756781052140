import JustificativaAtendimento from 'components/Pages/JustificativaAtendimento';
import PesquisarSolicitante from '../Solicitante/Pesquisar';
import RecuperarAtendimento from '../RecuperarAtendimento';
import DadosSolicitante from '../Solicitante/Dados';
import PesquisarMutuario from '../Mutuario/Pesquisar';
import DadosMutuario from '../Mutuario/Dados';
import Servicos from '../Servicos';
import Acordo from '../Servicos/Acordo';
import Termos from '../Servicos/Acordo/Termos';
import BoletoAgrupado from '../Servicos/BoletoAgrupado';
import Sucesso from '../Servicos/Sucesso';

import {
	ROUTE_CDHU_MUTUARIO_DADOS,
	ROUTE_CDHU_MUTUARIO_PESQUISAR,
	ROUTE_CDHU_RECUPERAR_ATENDIMENTO,
	ROUTE_CDHU_SERVICOS,
	ROUTE_CDHU_SERVICOS_ACORDO,
	ROUTE_CDHU_SERVICOS_ACORDO_TERMOS_IMPRIMIR,
	ROUTE_CDHU_SERVICOS_BOLETO_AGRUPADO,
	ROUTE_CDHU_SERVICOS_SUCESSO,
	ROUTE_CDHU_SOLICITANTE_DADOS,
	ROUTE_CDHU_SOLICITANTE_PESQUISAR,
	ROUTE_CDHU_JUSTIFICATIVA_ATENDIMENTO,
} from './paths';

export const cdhuRoute = {
	name: 'CDHU',
	route: '/cdhu',
};

const cdhuRoutes = [
	{
		path: ROUTE_CDHU_RECUPERAR_ATENDIMENTO,
		breadcrumb: [
			cdhuRoute,
			{
				name: 'Recuperar atendimento',
			},
		],
		Component: RecuperarAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_CDHU_JUSTIFICATIVA_ATENDIMENTO,
		breadcrumb: [
			cdhuRoute,
			{
				name: 'Justificativa atendimento',
			},
		],
		Component: JustificativaAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_CDHU_SOLICITANTE_PESQUISAR,
		breadcrumb: [
			cdhuRoute,
			{
				name: 'Pesquisar solicitante',
			},
		],
		Component: PesquisarSolicitante,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_CDHU_SOLICITANTE_DADOS,
		breadcrumb: [
			cdhuRoute,
			{
				name: 'Dados do solicitante',
			},
		],
		Component: DadosSolicitante,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_CDHU_MUTUARIO_PESQUISAR,
		breadcrumb: [
			cdhuRoute,
			{
				name: 'Pesquisar mutuário',
			},
		],
		Component: PesquisarMutuario,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_CDHU_MUTUARIO_DADOS,
		breadcrumb: [
			cdhuRoute,
			{
				name: 'Dados do mutuário',
			},
		],
		Component: DadosMutuario,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_CDHU_SERVICOS,
		breadcrumb: [
			cdhuRoute,
			{
				name: 'Serviços',
			},
		],
		Component: Servicos,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_CDHU_SERVICOS_ACORDO,
		breadcrumb: [
			cdhuRoute,
			{
				name: 'Serviços',
			},
			{
				name: 'Acordo',
			},
		],
		Component: Acordo,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_CDHU_SERVICOS_ACORDO_TERMOS_IMPRIMIR,
		breadcrumb: [
			cdhuRoute,
			{
				name: 'Serviços',
			},
			{
				name: 'Imprimir termos',
			},
		],
		Component: Termos,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_CDHU_SERVICOS_BOLETO_AGRUPADO,
		breadcrumb: [
			cdhuRoute,
			{
				name: 'Serviços',
			},
			{
				name: 'Boleto agrupado',
			},
		],
		Component: BoletoAgrupado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_CDHU_SERVICOS_SUCESSO,
		breadcrumb: [
			cdhuRoute,
			{
				name: 'Serviços',
			},
		],
		Component: Sucesso,
		isAuthenticated: true,
		exact: true,
	},
];

export default cdhuRoutes;
