import { ReducerAction } from 'store/modules/types';

import {
	DadosPreCadastroFormSave,
	DadosPreCadastroFormSaveRequest,
	Types,
} from './types';

export function dadosPreCadastroFormSaveRequest(
	payload: DadosPreCadastroFormSaveRequest,
): ReducerAction {
	return {
		type: Types.DADOS_PRE_CADASTRO_FORM_SAVE_REQUEST,
		payload,
	};
}
export function dadosPreCadastroFormSaveSuccess(
	payload: DadosPreCadastroFormSave,
): ReducerAction {
	return {
		type: Types.DADOS_PRE_CADASTRO_FORM_SAVE_SUCCESS,
		payload,
	};
}
export function dadosPreCadastroFormSaveClear(): ReducerAction {
	return {
		type: Types.DADOS_PRE_CADASTRO_FORM_SAVE_CLEAR,
		payload: null,
	};
}
