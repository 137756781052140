export enum Types {
	CONSULTAR_VEICULO_BASE_ESTADUAL_REQUEST = '@DETRAN-CRV/CONSULTAR_VEICULO_BASE_ESTADUAL_REQUEST',
	CONSULTAR_VEICULO_BASE_ESTADUAL_SUCCESS = '@DETRAN-CRV/CONSULTAR_VEICULO_BASE_ESTADUAL_SUCCESS',
	CONSULTAR_VEICULO_BASE_ESTADUAL_FAILURE = '@DETRAN-CRV/CONSULTAR_VEICULO_BASE_ESTADUAL_FAILURE',
	CONSULTAR_VEICULO_BASE_ESTADUAL_CLEAR = '@DETRAN-CRV/CONSULTAR_VEICULO_BASE_ESTADUAL_CLEAR',
}

export interface consultarVeiculoBaseEstadual {
	status: number;
	data: IDadosVeiculoBaseEstadual;
}
export interface IDadosVeiculoBaseEstadual {
	dadosVeiculo: {
		anoFabricacao: number;
		anoModelo: number;
		capacidadeCarga: number;
		capacidadePassageiro: number;
		chassi: string;
		cilindrada: number;
		cmt: number;
		dataAlteracaoMotor: number;
		eixo: number;
		numeroMotor: string;
		pbt: number;
		placa: string;
		potencia: number;
		procedencia: string;
		renavam: number;
		ultimaAtualizacaoDespachante: number;
		carroceria: {
			longId: number;
			descricao: string;
		};
		categoria: {
			descricao: string;
			longId: number;
		};
		combustivel: {
			longId: number;
			descricao: string;
		};
		cor: {
			longId: number;
			descricao: string;
		};
		especie: {
			longId: number;
			descricao: string;
		};
		marca: {
			longId: number;
			descricao: string;
		};
		municipio: {
			codigo: number;
			nome: string;
		};
		tipo: {
			longId: number;
			descricao: string;
		};
		comunicacaoVenda: {
			comunicacaoVenda: string;
			cpfCnpjComprador: number;
			dataInclusaoComunicacao: string;
			dataNotaFiscal: string;
			dataVenda: string;
			identificacao: string;
			protocoloDetran: string;
		};
		crvCrvl: {
			dataLicenciamento: number;
			exercicioLicenciamento: number;
			dataEmissaoCrv: string;
		};
		gravame: {
			arrendatarioFinanceira: string;
			codigoFinanceira: number;
			cpfCnpjFinanciado: number;
			nomeAgente: string;
			numeroContrato: string;
			restricaoFinanceira: string;
			dataInclusao: string;
			dataVigencia: string;
		};
		intencaoGravame: {
			agenteFinanceiro: string;
			cpfCnpjFinanciado: number;
			dataInclusao: string;
			dataVigencia: string;
			exercicioLicenciamento: number;
			nomeFinanciado: string;
			numeroContrato: string;
			restricao: string;
			tipoTransacao: string;
		};
		multas: {
			cetesb: number;
			debitoIpva: number;
			der: number;
			dersa: number;
			detran: number;
			municipais: number;
			policiaRodoviariaFederal: number;
			renainf: number;
		};
		proprietario: {
			cpfCnpjProprietario: number;
			identificacao: string;
			nomeProprietario: string;
			tipoDocumento: string;
		};
		restricoes: {
			bloqueioGuincho: string;
			bloqueioRenajud: string;
			furto: string;
			inspecaoAmbiental: string;
			restricaoAdministrativa: string;
			restricaoJudicial: string;
			restricaoTributaria: string;
		};
	};
}

export interface IRequestConsultaVeiculoBaseEstadual {
	chassi: string;
	placa?: string;
	renavam?: string;
	municipio: {
		codigo: number | string;
		nome: string;
	};
}
