import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// ANTD
import { Col, Row } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { consultaEventosEspeciaisRequest } from 'store/modules/api/procon/eventosEspeciais/eventosEspeciais/actions';
import { CadastraAtendimentoPfRequest } from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPf/types';
import { CadastraAtendimentoPjRequest } from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPj/types';
import {
	ID_PRAZO_PADRAO,
	LABEL_PRAZO_PADRAO,
} from 'store/modules/api/procon/eventosEspeciais/eventosEspeciais/types';
import { Consts } from 'store/modules/api/procon/enum';

// UTILS
import hasError from 'utils/getFormErrors';

// COMPONENTS
import Select, { OptionProps } from 'components/Common/Form/Select';
import TypographyCommon from 'components/Common/Typography';
import CheckBox from 'components/Common/Form/Checkbox';

// FORM
import { Field, FormikProps } from 'formik';

interface EventosEspeciais {
	eventoEspecialId: string;
}

interface Props {
	formik: FormikProps<
		CadastraAtendimentoPfRequest | CadastraAtendimentoPjRequest
	>;
	title?: boolean;
	disabled?: boolean;
}

const EventosEspeciais: React.FC<Props> = ({
	formik,
	title = '',
	disabled = false,
}) => {
	const dispatch = useDispatch();

	const [prazoPadrao, setPrazoPadrao] = useState<boolean>(false);
	const [options, setOptions] = useState<OptionProps[] | null>(null);

	const { data } = useSelector(
		(state: ApplicationState) =>
			state.api.procon.eventosEspeciais.eventosEspeciais,
	);

	useEffect(() => {
		dispatch(consultaEventosEspeciaisRequest());
	}, [dispatch]);

	const handleUpdate = useCallback(() => {
		formik.setFieldValue('eventoEspecialId', data[0].value);
	}, [data, formik]);

	useEffect(() => {
		if (
			data.length === 1 &&
			(data[0].value === ID_PRAZO_PADRAO ||
				data[0].label === LABEL_PRAZO_PADRAO)
		) {
			setPrazoPadrao(true);
		} else if (data.length) {
			setOptions(data);
			setPrazoPadrao(false);
		}
	}, [data]);

	useEffect(() => {
		if (prazoPadrao && formik.values.eventoEspecialId !== ID_PRAZO_PADRAO) {
			handleUpdate();
		}
	}, [prazoPadrao, handleUpdate, formik.values.eventoEspecialId]);

	return (
		<>
			<Row gutter={[0, 20]} align="middle" justify="start">
				<Col span={24}>
					<Field
						as={CheckBox}
						name="isEventoSazonal"
						subtitle={'Trata-se de um Evento Especial/Sazonal'.toUpperCase()}
						titleSize="xl"
						titleAlign="start"
						checked={formik.values.isEventoSazonal}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							if (formik.values.isEventoSazonal) {
								formik.setFieldValue(
									'eventoEspecialId',
									Consts.EVENTO_ESPECIAL_DEFAULT,
								);
							} else {
								formik.setFieldValue('eventoEspecialId', '');
							}

							formik.setFieldValue('isEventoSazonal', e.target.checked);
						}}
						error={hasError(formik.errors, 'isEventoSazonal')}
						disabled={prazoPadrao}
					/>
				</Col>
			</Row>

			<Row gutter={[0, 0]} align="middle" justify="start">
				<TypographyCommon
					text="Evento Especial/Sazonal:"
					fontWeight="bold"
					required
					uppercase
				/>
			</Row>
			<Row gutter={[0, 20]} align="middle" justify="start">
				<Col span={24}>
					<Field
						as={Select}
						disabled={prazoPadrao || !disabled}
						title={title ? 'Evento Especial/Sazional' : ''}
						name="eventoEspecialId"
						options={options ?? []}
						titleSize="xl"
						value={prazoPadrao ? data[0].label : formik.values.eventoEspecialId}
						onChange={(v: string) => {
							formik.setFieldValue('eventoEspecialId', v);
						}}
						error={hasError(formik.errors, 'eventoEspecialId')}
						required
					/>
				</Col>
			</Row>
		</>
	);
};

export default EventosEspeciais;
