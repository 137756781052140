import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, VerificarVersaoServicoBiometria } from './types';

export const INITIAL_STATE: VerificarVersaoServicoBiometria = {
	status: 0,
	data: { versao: '' },
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): VerificarVersaoServicoBiometria {
	return produce(state, draft => {
		switch (action.type) {
			case Types.VERIFICAR_VERSAO_SERVICO_BIOMETRIA_REQUEST: {
				draft.status = 100;
				draft.data = { versao: '' };
				break;
			}
			case Types.VERIFICAR_VERSAO_SERVICO_BIOMETRIA_OLD: {
				draft.status = 200;
				draft.data = { versao: 'OLD' };
				break;
			}
			case Types.VERIFICAR_VERSAO_SERVICO_BIOMETRIA_NEW: {
				draft.status = 200;
				draft.data = { versao: 'NEW' };
				break;
			}
			case Types.VERIFICAR_VERSAO_SERVICO_BIOMETRIA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
