import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	height: 4vh;
	min-height: 35px;
	width: 100%;

	${props => css`
		background-color: ${props.theme.header.backgroundColor.primary};
	`}

	/* Small devices (landscape phones, 576px and up) */
	@media (max-width: 575.98px) {
		display: block;
		height: auto;
	}

	/* Medium devices (tablets, 768px and up) */
	@media (max-width: 768.98px) {
	}

	/* Large devices (desktops, 992px and up) */
	@media (max-width: 991.98px) {
	}

	/* Extra large devices (large desktops, 1200px and up) */
	@media (max-width: 1199.98px) {
	}
`;

export const Content = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 1006px;
	padding: 0 15px;
	width: 100%;

	/* Small devices (landscape phones, 576px and up) */
	@media (max-width: 575.98px) {
		display: block;
		max-width: 100%;
		padding: 15px;
		text-align: center;

		div {
			padding: 5px 0;
		}
	}

	/* Medium devices (tablets, 768px and up) */
	@media (max-width: 767.98px) {
		max-width: 100%;
	}

	/* Large devices (desktops, 992px and up) */
	@media (max-width: 991.98px) {
	}

	/* Extra large devices (large desktops, 1200px and up) */
	@media (max-width: 1199.98px) {
	}
`;

export const Message = styled.div`
	font-size: calc(0.85rem + ${props => props.theme.fontScale}%);
	font-weight: 500;
	letter-spacing: 0.025em;
	margin: auto 0;
`;
