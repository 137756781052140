import { IIpressaoRenavam } from 'pages/DetranCrv/Processos/ImpressaoRenavam/Consulta/form';
import { Types } from './types';

export function impressaoRenavamRequest(payload: IIpressaoRenavam) {
	return {
		type: Types.IMPRESSAO_RENAVAM_REQUEST,
		payload,
	};
}
export function impressaoRenavamSuccess(payload: any) {
	return {
		type: Types.IMPRESSAO_RENAVAM_SUCCESS,
		payload,
	};
}
export function impressaoRenavamFailure(payload: []): any {
	return {
		type: Types.IMPRESSAO_RENAVAM_FAILURE,
		payload,
	};
}
export function impressaoRenavamClear(): any {
	return {
		type: Types.IMPRESSAO_RENAVAM_CLEAR,
	};
}
