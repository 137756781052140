import { ReducerAction } from 'store/modules/types';
import { IConsultaErroRequest, Types } from './types';

export function consultaErro(data: IConsultaErroRequest): ReducerAction {
	return {
		type: Types.CONSULTA_ERRO,
		payload: data,
	};
}
export function consultaErroSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_ERRO_SUCCESS,
		payload,
	};
}
export function consultaErroFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_ERRO_FAILURE,
		payload,
	};
}
