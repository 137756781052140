import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaPermissionarioPontuadoRequest } from './types';

// ACTIONS
import {
	consultaPermissionarioPontuadoSuccess,
	consultaPermissionarioPontuadoFailure,
} from './actions';

function* consultaPermissonarioPontuado(request: ReducerAction) {
	const { payload }: { payload: ConsultaPermissionarioPontuadoRequest } =
		request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`eportal/${CHANNEL}/pesquisa/reabilitacao-permissionario`,
		payload,
	);

	if (response.status === 200) {
		yield put(
			consultaPermissionarioPontuadoSuccess({ response, request: payload }),
		);
	} else {
		yield put(
			consultaPermissionarioPontuadoFailure({
				response: response.data,
				request: payload,
			}),
		);
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_PERMISSIONARIO_PONTUADO_REQUEST,
		consultaPermissonarioPontuado,
	),
]);
