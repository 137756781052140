import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_SGU_SERVICE } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { RecuperarAtendimentoRequest, Types } from './types';

// ACTIONS
import {
	recuperarAtendimentoSuccess,
	recuperarAtendimentoFailure,
} from './actions';

function* recuperarAtendimentoRequest(request: ReducerAction) {
	const { payload }: { payload: RecuperarAtendimentoRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU_SERVICE,
		`atendimento/${CHANNEL}/recuperar`,
		payload,
	);

	if (response.status === 200) {
		yield put(recuperarAtendimentoSuccess(response.data));
	} else {
		yield put(recuperarAtendimentoFailure(response));
	}
}

export default all([
	takeLatest(Types.RECUPERAR_ATENDIMENTO_REQUEST, recuperarAtendimentoRequest),
]);
