import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_ATPVE_API } from 'services/rotasp/path';
import { ReducerActionRotaSP } from 'store/modules/types';
import { CancelarIntencaoVendaRequest, Types } from './types';
import {
	cancelarIntencaoVendaSuccess,
	cancelarIntencaoVendaFailure,
} from './actions';

function* cancelarIntencaoVendaRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: CancelarIntencaoVendaRequest } = request;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_ATPVE_API,
		`intencoes-vendas`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(cancelarIntencaoVendaSuccess(response));
	} else if (response.error) {
		yield put(cancelarIntencaoVendaFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.CANCELAR_INTENCAO_VENDA_REQUEST,
		cancelarIntencaoVendaRequest,
	),
]);
