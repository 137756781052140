import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Alert, Col, Row } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { CadastroConsultaForm } from 'store/modules/api/procon/atendimentosDeConsulta/cadastro/types';

// COMPONENTS
import LightButton from 'components/Common/Buttons/Light';
import Section from 'components/Common/Section';
import { OptionProps } from 'components/Common/Form/Select';
import TypographyCommon from 'components/Common/Typography';

// PATHS
import { ROUTE_PROCON } from 'routes/paths';

const Sucesso: React.FC = () => {
	const history = useHistory();

	const location = useLocation<CadastroConsultaForm>();

	const { dadosAtendimento } = useSelector(
		(state: ApplicationState) =>
			state.api.procon.atendimentosDeConsulta.cadastroConsulta,
	);

	const { selecionado } = useSelector(
		(state: ApplicationState) => state.api.procon.fornecedores,
	);

	const { areas, grupoDeProblemas } = useSelector(
		(state: ApplicationState) => state.api.procon,
	);

	const handleBack = () => {
		history.push(ROUTE_PROCON);
	};

	const handleNewQuery = () => {
		history.push(ROUTE_PROCON);
	};

	const handlePrint = () => {
		window.print();
	};

	const getClassificacao = () => {
		const text: string[] = [];
		if (!dadosAtendimento) {
			return <TypographyCommon type="label" text="Não informado" uppercase />;
		}

		if (dadosAtendimento.areaId && areas.consultaAreas.data) {
			areas.consultaAreas.data.filter((item: OptionProps) => {
				if (item.value === dadosAtendimento.areaId) {
					text.push(item.label);
				}

				return <TypographyCommon type="label" text="Não informado" uppercase />;
			});
		}

		if (dadosAtendimento.assuntoId && areas.assuntos.data) {
			areas.assuntos.data.filter((item: OptionProps) => {
				if (item.value === dadosAtendimento.assuntoId) {
					text.push(item.label);
				}

				return <TypographyCommon type="label" text="Não informado" uppercase />;
			});
		}

		if (
			dadosAtendimento.grupoDeProblemaId &&
			grupoDeProblemas.assuntosProblemas.data
		) {
			grupoDeProblemas.assuntosProblemas.data.filter((item: OptionProps) => {
				if (item.value === dadosAtendimento.grupoDeProblemaId) {
					text.push(item.label);
				}

				return <TypographyCommon type="label" text="Não informado" uppercase />;
			});
		}

		if (dadosAtendimento.problemaId && grupoDeProblemas.problemas.data) {
			grupoDeProblemas.problemas.data.filter((item: OptionProps) => {
				if (item.value === dadosAtendimento.problemaId) {
					text.push(item.label);
				}

				return <TypographyCommon type="label" text="Não informado" uppercase />;
			});
		}

		if (text.length) {
			return (
				<>
					{text.map((item: string, index: number) => {
						const texto = item.concat(index === text.length - 1 ? '' : ' • ');
						return (
							<TypographyCommon
								key={item}
								type="label"
								text={texto}
								uppercase
							/>
						);
					})}
				</>
			);
		}

		return <TypographyCommon type="label" text="Não informado" uppercase />;
	};

	return (
		<>
			<Alert
				className="no-print"
				type="info"
				showIcon
				message=" "
				description="Consulta cadastrada com sucesso! O Protocolo do atendimento será enviado para o e-mail cadastrado."
				closable
			/>

			<br />

			<Row gutter={[0, 0]} align="top" justify="start">
				<Col className="gutter-row" span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Detalhes da Consulta"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>

			<Section
				bgColor="cinza-escuro"
				style={{
					border: '1px grey solid',
					borderRadius: '4px',
					marginTop: '0px',
				}}
			>
				<Row gutter={[6, 0]} align="top" justify="start">
					<Col span={9}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Fornecedor:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={23}>
								<TypographyCommon
									text={
										selecionado.data?.razaoSocial ||
										location.state.nomeDoFornecedor ||
										'Não informado'
									}
									type="label"
									uppercase
								/>
							</Col>
						</Row>
					</Col>
					<Col span={9}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Nome Fantasia do Fornecedor:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={23}>
								<TypographyCommon
									text={
										selecionado.data?.nomeFantasia ||
										location.state.nomeDoFornecedor ||
										'Não informado'
									}
									type="label"
									uppercase
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={[6, 0]} align="top" justify="start">
					<Col span={24}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Nome do Serviço ou Produto:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={24}>
								<TypographyCommon
									text={
										dadosAtendimento?.nomeDoServicoOuProduto ?? 'Não informado'
									}
									type="label"
									uppercase
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={[6, 0]} align="top" justify="start">
					<Col span={24}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Classificação:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={24}>{getClassificacao()}</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={[6, 0]} align="top" justify="start">
					<Col span={24}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Descrição da Consulta:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={24}>
								<TypographyCommon
									text={
										dadosAtendimento?.descricaoDaConsulta ?? 'Não informado'
									}
									type="label"
									uppercase
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<br />

				<Row align="top" justify="start">
					<Col span="15" className="no-print">
						<LightButton
							type="button"
							buttonColor="default"
							text="Voltar"
							icon="back"
							buttonSize="sm"
							buttonWidth={100}
							onClick={handleBack}
						/>
					</Col>
					<Col span="4" className="no-print">
						<Row align="top" justify="end">
							<LightButton
								type="button"
								buttonColor="default"
								text="Imprimir"
								icon="print"
								buttonSize="sm"
								buttonWidth={140}
								leftOrRight="left"
								onClick={() => handlePrint()}
							/>
						</Row>
					</Col>
					<Col span="5" className="no-print">
						<Row align="top" justify="end">
							<LightButton
								onClick={handleNewQuery}
								type="button"
								buttonColor="green"
								icon="add"
								buttonSize="sm"
								buttonWidth={150}
								text="Finalizar Atendimento"
							/>
						</Row>
					</Col>
				</Row>
			</Section>
		</>
	);
};
export default Sucesso;
