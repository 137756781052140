import * as Yup from 'yup';

export interface ConsultaPlacaDisponivelRequest {
	categoria: string;
	chassi: string;
	tipoRestricao: string;
	placa: string;
}

export const initialValues: ConsultaPlacaDisponivelRequest = {
	categoria: '',
	chassi: '',
	tipoRestricao: '',
	placa: '',
};

export const schema = (isPersonalizada: boolean) =>
	Yup.object<ConsultaPlacaDisponivelRequest>().shape({
		categoria: !isPersonalizada
			? Yup.string().required(`Campo Obrigatório: Categoria.`)
			: Yup.string(),
		placa: !isPersonalizada
			? Yup.string().required(`Campo Obrigatório: Placa.`)
			: Yup.string(),
	});
