import React, { useCallback, useEffect, useState } from 'react';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_AGENDAMENTO_MEDICO } from 'pages/DetranCnh/routes/paths';

// ANTD
import { Row, Col } from 'antd';

// COMPONENTS COMMON
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';

// COMPONENTS
import StatusCondutor from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { DadosCidadaoRequest } from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/types';
import {
	cadastroEnderecosClear,
	cadastroEnderecosRequest,
} from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/actions';
import { CadastroEnderecosRequest } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/types';
import {
	dadosCidadaoClear,
	dadosCidadaoRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/actions';
import {
	domainServiceNaturalidadesClear,
	domainServiceNaturalidadesRequest,
} from 'store/modules/api/sguService/domainService/naturalidade/actions';
import {
	consultaPesquisaNomeSocialFiliacaoClear,
	consultaPesquisaNomeSocialFiliacaoRequest,
} from 'store/modules/api/detranCnh/IIRGDDetran/consultaPesquisaNomeSocialFiliacao/actions';
import {
	consultaNomeSocialFiliacaoClear,
	consultaNomeSocialFiliacaoRequest,
} from 'store/modules/api/detranCnh/condutor/consultaNomeSocialFiliacao/actions';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { GerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/types';
import {
	consultaSegundaViaReabilitacaoClear,
	consultaSegundaViaReabilitacaoRequest,
} from 'store/modules/api/detranCnh/reabilitacao/consultaSegundaViaReabilitacao/actions';
import { consultaCidadesUFRequest } from 'store/modules/api/utils/consultaCidadesUF/actions';
import { saveGerarRenachRequest } from 'store/modules/api/detranCnh/sockets/saveGerarRenach/actions';
import { dadosPreCadastroFormSaveRequest } from 'store/modules/api/detranCnh/utils/dadosPreCadastroFormSave/actions';
import { consultaCEPRequest } from 'store/modules/api/detranCnh/consulta/cep/actions';
import { gerarRenachIntegradoClear } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/actions';
import { estatisticaIncluirClear } from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { consultarInaptoClear } from 'store/modules/api/detranCnh/sockets/consultarInapto/actions';
import { addNotifications } from 'store/modules/app/notifications/actions';

// UTILS
import { limparMascara } from 'utils/genericFunctions';
import { getOriginOfSocialName } from 'pages/DetranCnh/utils/functions/socialName';
import { CNH_PROCESS_VALIDATION } from 'pages/DetranCnh/utils/constants/messages';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

// FORM
import { treatValuesCadastroEnderecoRequest } from 'pages/DetranCnh/utils/threatServicesRequest';
import {
	initalValuesHabilitacao,
	initialValuesPreCadastro,
	treatValues,
	treatInitialValues,
	schema,
} from './form';

const StatusCondutorReabilitacao: React.FC = () => {
	const history = useHistory();

	const dispatch = useDispatch();

	const { consultaSegundaViaReabilitacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.reabilitacao,
	);

	const { consultaPcon } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.eportal,
	);

	const { user, mainframe } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { consultaNaturalidades } = useSelector(
		(state: ApplicationState) => state.api.sguService.domainService,
	);

	const { cadastroEnderecos, consultaNomeSocialFiliacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { dadosCidadao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.divisaoEquitativa,
	);

	const { consultaPesquisaNomeSocialFiliacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.IIRGDDetran,
	);

	const { dadosPreCadastroFormSave } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.utils,
	);

	const { consultaCEP } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.consulta,
	);

	// CONTABILIZAÇÃO
	const { sgu } = useSelector((state: ApplicationState) => state.api);

	const [dadosPreCadastro, setDadosPreCadastro] = useState(
		initialValuesPreCadastro,
	);

	const [dadosHabilitacao, setDadosHabilitacao] = useState(
		initalValuesHabilitacao,
	);

	const [requestData, setRequestData] = useState<null | {
		endereco: CadastroEnderecosRequest;
		cidadao: DadosCidadaoRequest;
	}>(null);

	const [preCadastro, setPreCadastro] =
		useState<null | GerarRenachIntegradoRequest>(null);

	const handleSubmit = useCallback(
		(values: GerarRenachForm) => {
			if (!values.codCiretranCondutor || !values.codCiretranHabilitacao) {
				addNotifications({
					errors: CNH_PROCESS_VALIDATION.CIRETRAN_INVALIDA,
				});
				return;
			}

			const sguData: SguData = {
				salvarAtendimento: sgu.atendimento.salvarAtendimento,
				login: sgu.loginUnico.login,
			};
			const evento = getEventoConsultar(sguData, values.cpf);

			const renach = treatValues(evento, values, user, dadosPreCadastro);
			const endereco = treatValuesCadastroEnderecoRequest(values);

			const cidadao: DadosCidadaoRequest = {
				cidadao: {
					autoriza_envio_email: values.autorizaSMS === 'S',
					autoriza_envio_sms: values.autorizaEmail === 'S',
					cpf: Number(values.cpf),
					celular: limparMascara(values.celular).substring(2, 11),
					ddd_celular: limparMascara(values.celular).substring(0, 2),
					ddd_telefone: limparMascara(values.telefone).substring(0, 2),
					telefone: limparMascara(values.telefone).substring(2, 11),
					email: values.email || '',
					necessidade_veiculo_adaptado: false,
					nome: values.nomeSocial?.toUpperCase() || values.nome.toUpperCase(),
					pretende_atividade_remunerada: values.flagAtividadeRemunerada === 'S',
				},
			};

			setDadosPreCadastro(values);
			setPreCadastro(renach);
			setRequestData({ endereco, cidadao });

			dispatch(cadastroEnderecosRequest(endereco));
			dispatch(saveGerarRenachRequest(renach));
			// SALVA OS DADOS PREENCHIDOS NO FORM.
			dispatch(
				dadosPreCadastroFormSaveRequest({
					dataPreCadastro: values,
					dataPreCadastroHabilitacao: dadosHabilitacao,
				}),
			);
			dispatch(gerarRenachIntegradoClear());
		},
		[
			sgu.atendimento,
			sgu.loginUnico,
			user,
			dadosPreCadastro,
			dispatch,
			dadosHabilitacao,
		],
	);

	const clearInitialStatesWithoutNewCallServices = useCallback(() => {
		dispatch(cadastroEnderecosClear());
		dispatch(estatisticaIncluirClear());
		dispatch(consultarInaptoClear());
		dispatch(dadosCidadaoClear());
	}, [dispatch]);

	useEffect(() => {
		if (
			consultaPcon.data &&
			consultaPcon.data.cpf &&
			consultaSegundaViaReabilitacao.status === 0
		) {
			dispatch(
				consultaSegundaViaReabilitacaoRequest({
					cpfCidadao: consultaPcon.data.cpf,
				}),
			);
		}
	}, [
		consultaPcon.data,
		consultaPcon.status,
		consultaSegundaViaReabilitacao.status,
		dispatch,
	]);

	useEffect(() => {
		if (
			consultaPcon.data &&
			consultaSegundaViaReabilitacao.status === 200 &&
			consultaSegundaViaReabilitacao.data &&
			user &&
			(!dadosPreCadastroFormSave.dataPreCadastro?.cpf ||
				!dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf)
		) {
			const newFormValues = treatInitialValues(
				consultaPcon.data,
				consultaSegundaViaReabilitacao.data.dataPrimeiraHabilitacao || '',
				user,
			);

			setDadosPreCadastro(newFormValues.initialValuesPreCadastro);
			setDadosHabilitacao(newFormValues.initalValuesHabilitacao);
		}
	}, [
		consultaPcon.data,
		consultaSegundaViaReabilitacao.status,
		consultaSegundaViaReabilitacao.data,
		user,
		dadosPreCadastroFormSave,
	]);

	// GET UF
	useEffect(() => {
		if (
			consultaSegundaViaReabilitacao.status === 200 &&
			consultaSegundaViaReabilitacao.data &&
			consultaSegundaViaReabilitacao.data.naturalidade &&
			consultaSegundaViaReabilitacao.data.nacionalidade?.toString() === '1' &&
			dadosPreCadastro.cpf &&
			dadosPreCadastro.cpf !== '' &&
			(!dadosPreCadastroFormSave.dataPreCadastro?.cpf ||
				!dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf)
		) {
			dispatch(
				domainServiceNaturalidadesRequest({
					idIbge: consultaSegundaViaReabilitacao.data.naturalidade.trim(),
				}),
			);
		}
	}, [
		consultaSegundaViaReabilitacao.data,
		consultaSegundaViaReabilitacao.status,
		dadosPreCadastro.cpf,
		dispatch,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			consultaNaturalidades.status === 200 &&
			consultaNaturalidades.data &&
			consultaNaturalidades.data[0].municipioIBGE?.uf &&
			(!dadosPreCadastroFormSave.dataPreCadastro?.cpf ||
				!dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf)
		) {
			setDadosHabilitacao(oldFormValues => ({
				...oldFormValues,
				naturalidade:
					(consultaNaturalidades.data &&
						consultaNaturalidades.data[0]?.municipioIBGE?.descricao) ||
					'',
				codigoNaturalidade:
					(consultaNaturalidades.data &&
						Number(consultaNaturalidades.data[0]?.municipioIBGE?.id)) ||
					'',
				uf:
					(consultaNaturalidades.data &&
						consultaNaturalidades.data[0]?.municipioIBGE?.uf) ||
					'',
			}));
			setDadosPreCadastro(oldFormValues => ({
				...oldFormValues,
				naturalidade:
					(consultaNaturalidades.data &&
						consultaNaturalidades.data[0]?.municipioIBGE?.descricao) ||
					'',
				codigoNaturalidade:
					(consultaNaturalidades.data &&
						Number(consultaNaturalidades.data[0]?.municipioIBGE?.id)) ||
					'',
				uf:
					(consultaNaturalidades.data &&
						consultaNaturalidades.data[0]?.municipioIBGE?.uf) ||
					'',
			}));

			dispatch(
				consultaCidadesUFRequest(
					consultaNaturalidades.data[0].municipioIBGE.uf,
				),
			);
		}
	}, [
		consultaNaturalidades.data,
		consultaNaturalidades.status,
		dadosPreCadastroFormSave,
		dispatch,
	]);

	useEffect(() => {
		if (
			consultaPcon.data &&
			consultaPcon.data.cpf &&
			mainframe &&
			dadosPreCadastro.cpf &&
			dadosPreCadastro.cpf !== '' &&
			consultaNomeSocialFiliacao.status === 0
		) {
			dispatch(
				consultaNomeSocialFiliacaoRequest({
					cpf: consultaPcon.data.cpf,
					usuario: mainframe.idUsuario,
					senha: mainframe.senhaMainFrame,
				}),
			);
		}
	}, [
		dispatch,
		consultaPcon,
		mainframe,
		dadosPreCadastro.cpf,
		consultaNomeSocialFiliacao.status,
	]);

	useEffect(() => {
		if (
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf &&
			consultaNomeSocialFiliacao.status === 200 &&
			consultaNomeSocialFiliacao.data
		) {
			const { nomeSocial, filiacao1, filiacao2 } =
				consultaNomeSocialFiliacao.data;

			setDadosPreCadastro(oldFormValues => ({
				...oldFormValues,
				nomeSocial: nomeSocial?.trim() || '',
				paisAfetivo1: filiacao1?.trim() || '',
				paisAfetivo2: filiacao2?.trim() || '',
				origemNomeSocial: getOriginOfSocialName(
					consultaNomeSocialFiliacao,
					null,
				),
			}));
		}
	}, [consultaNomeSocialFiliacao, dadosPreCadastroFormSave]);

	useEffect(() => {
		if (
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf &&
			consultaPcon.data?.cpf &&
			consultaPesquisaNomeSocialFiliacao.status === 0 &&
			((consultaNomeSocialFiliacao.status !== 0 &&
				consultaNomeSocialFiliacao.status !== 200) ||
				(consultaNomeSocialFiliacao.status === 200 &&
					!consultaNomeSocialFiliacao.data?.nomeSocial?.trim()))
		) {
			dispatch(
				consultaPesquisaNomeSocialFiliacaoRequest({
					usuario: mainframe.idUsuario,
					terminal: user.ip,
					cpf: consultaPcon.data.cpf,
				}),
			);
		}
	}, [
		consultaNomeSocialFiliacao,
		consultaPcon.data,
		consultaPesquisaNomeSocialFiliacao.status,
		dispatch,
		mainframe.idUsuario,
		user.ip,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf &&
			consultaPesquisaNomeSocialFiliacao.status === 200 &&
			consultaPesquisaNomeSocialFiliacao.data
		) {
			setDadosPreCadastro(oldData => ({
				...oldData,
				nomeSocial:
					consultaPesquisaNomeSocialFiliacao.data?.nomeSocial.trim() || '',
				origemNomeSocial: getOriginOfSocialName(
					null,
					consultaPesquisaNomeSocialFiliacao,
				),
			}));
		}
	}, [consultaPesquisaNomeSocialFiliacao, dadosPreCadastroFormSave]);

	// GET CODIGO CIRETRAN
	useEffect(() => {
		if (
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf &&
			consultaPcon.data &&
			consultaPcon.data.endereco?.cep &&
			consultaPesquisaNomeSocialFiliacao.status !== 0 &&
			consultaPesquisaNomeSocialFiliacao.status !== 100 &&
			consultaCEP.status === 0
		) {
			dispatch(
				consultaCEPRequest({
					cep: consultaPcon.data.endereco.cep,
				}),
			);
		}
	}, [
		consultaCEP.status,
		consultaPcon.data,
		consultaPesquisaNomeSocialFiliacao.status,
		dadosPreCadastro.cpf,
		dispatch,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			consultaCEP.status === 200 &&
			consultaCEP.data &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			setDadosPreCadastro(oldFormValues => ({
				...oldFormValues,
				codCiretranHabilitacao:
					consultaCEP.data?.codigoUnidadeTransito?.toString() || '',
				codCiretranCondutor:
					consultaCEP.data?.codigoUnidadeTransito?.toString() || '',
			}));
		}
	}, [consultaCEP.data, consultaCEP.status, dadosPreCadastroFormSave]);

	useEffect(() => {
		if (cadastroEnderecos.status === 200 && requestData !== null) {
			dispatch(dadosCidadaoRequest(requestData.cidadao));
		}
	}, [dispatch, cadastroEnderecos.status, requestData]);

	useEffect(() => {
		if (dadosCidadao.status === 200 && requestData !== null) {
			dispatch(estatisticaIncluirClear());
			history.push(ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_AGENDAMENTO_MEDICO);
		}
	}, [
		dispatch,
		dadosCidadao.status,
		history,
		cadastroEnderecos.status,
		requestData,
	]);

	// RECARREGA OS DADOS DO FORM COM OS DADOS SALVOS NA STORE.
	useEffect(() => {
		if (dadosPreCadastroFormSave.dataPreCadastro?.cpf) {
			setDadosPreCadastro(dadosPreCadastroFormSave.dataPreCadastro);
		}
	}, [dispatch, dadosPreCadastroFormSave]);

	// RECARREGA OS DADOS DO FORM COM OS DADOS SALVOS NA STORE.
	useEffect(() => {
		if (dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf) {
			setDadosHabilitacao(dadosPreCadastroFormSave.dataPreCadastroHabilitacao);
		}
	}, [dispatch, dadosPreCadastroFormSave]);

	useEffect(() => {
		clearInitialStatesWithoutNewCallServices();
		if (!dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf) {
			dispatch(consultaNomeSocialFiliacaoClear());
			dispatch(consultaPesquisaNomeSocialFiliacaoClear());
			dispatch(consultaSegundaViaReabilitacaoClear());
			dispatch(domainServiceNaturalidadesClear());
		}
	}, [
		dispatch,
		dadosPreCadastroFormSave,
		clearInitialStatesWithoutNewCallServices,
	]);

	return (
		<>
			<StatusCondutor
				schema={schema}
				dadosHabilitacao={dadosHabilitacao}
				dadosPreCadastro={dadosPreCadastro}
				preCadastro={preCadastro !== null}
				setPreCadastro={setPreCadastro}
				handleSubmit={handleSubmit}
			/>

			<Row justify="space-between" align="middle">
				<Col>
					<ButtonVoltar
						navigate={false}
						onClick={() => history.push('/detran-cnh')}
					/>
				</Col>

				<Col>
					<ButtonImage
						type="button"
						src="outro-servico-detran"
						onClick={() => history.push('/detran-cnh')}
					/>
				</Col>
			</Row>
		</>
	);
};

export default StatusCondutorReabilitacao;
