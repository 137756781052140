import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// UTILS
import { treatObjNullValues } from 'utils/genericFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	IRequestPesquisaObservacao,
	consultaPesquisaObservacaoSuccess,
	consultaPesquisaObservacaoFailure,
	consultaPesquisaObservacaoClear,
} from './actions';

function* consultaPesquisaObservacaoRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestPesquisaObservacao } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		'consultaPesquisaObservacao',
		payload,
	);

	if (response.status === 200) {
		// Tratativa para tratar valores NULL.
		response.data = treatObjNullValues(response.data);

		yield put(consultaPesquisaObservacaoClear());
		yield put(consultaPesquisaObservacaoSuccess(response.data));
	} else if (response.error) {
		yield put(consultaPesquisaObservacaoFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_PESQUISA_OBSERVACAO_REQUEST,
		consultaPesquisaObservacaoRequest,
	),
]);
