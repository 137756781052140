import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	consultaEventosEspeciaisSuccess,
	consultaEventosEspeciaisFailure,
} from './actions';

function* consultaEventosEspeciaisRequest() {
	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`eventos-especiais/${CHANNEL}/eventos-especiais`,
		{},
	);

	if (response.status === 200) {
		yield put(consultaEventosEspeciaisSuccess(response));
	} else {
		yield put(consultaEventosEspeciaisFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_EVENTOS_ESPECIAIS_REQUEST,
		consultaEventosEspeciaisRequest,
	),
]);
