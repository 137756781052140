import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import data from 'store/modules/api/detranCrv/enum';
import {
	consultarMunicipioCodigoProdesClear,
	consultarMunicipioCodigoProdespRequest,
} from 'store/modules/api/detranCrv/enum/consultarMunicipioCodigoProdesp/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';

// UTILS
import {
	onlyLetters,
	onlyNumbers,
	onlyNumbersLettersSpace,
} from 'utils/genericFunctions';
import hasError from 'utils/getFormErrors';

// FORM
import { IEmitirSegundaViaCrv } from '../form';

interface Props {
	initialValues: IEmitirSegundaViaCrv;
	formik: FormikProps<IEmitirSegundaViaCrv>;
}

const DadosProprietario: React.FC<Props> = ({ initialValues, formik }) => {
	const dispatch = useDispatch();

	const { consultarMunicipioPorUF, consultarMunicipioCodigoProdesp } =
		useSelector((state: ApplicationState) => state.api.detranCrv.Enum);

	const { consultarVeiculoV110 } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const [tipoPessoa, setTipoPessoa] = useState<string>('');
	const [cpfCnpj, setCpfCnpj] = useState<string>('');

	useEffect(() => {
		if (initialValues) {
			const { identificacao, cpfProprietario, cnpjProprietario } =
				initialValues;

			if (identificacao === 'FISICA' || identificacao === 'JURIDICA') {
				setTipoPessoa(identificacao);

				if (
					identificacao === 'FISICA' &&
					String(cpfProprietario).length === 11
				) {
					setCpfCnpj(String(cpfProprietario));
				}

				if (
					identificacao === 'JURIDICA' &&
					String(cnpjProprietario).length === 14
				) {
					setCpfCnpj(String(cnpjProprietario));
				}
			}
		}
	}, [initialValues]);

	useEffect(() => {
		if (
			consultarMunicipioCodigoProdesp.data &&
			consultarMunicipioCodigoProdesp.status === 200
		) {
			formik.setFieldValue(
				'codigoMunicipioProprietario',
				Number(consultarMunicipioCodigoProdesp.data.codigoPRODESP),
			);
			dispatch(consultarMunicipioCodigoProdesClear());
		}
	}, [
		consultarMunicipioCodigoProdesp.data,
		consultarMunicipioCodigoProdesp.status,
		dispatch,
		formik,
	]);

	const clearFormikAddressFields = useCallback(() => {
		formik.setFieldValue('complementoProprietario', '');
		formik.setFieldValue('numeroProprietario', '');
	}, [formik]);

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado) => {
			clearFormikAddressFields();

			if (res.codigoMunicipio) {
				const consultaMunicipioData = {
					evento: null,
					codigoMunicipioCorreio: res.codigoMunicipio.toString(),
				};
				dispatch(consultarMunicipioCodigoProdespRequest(consultaMunicipioData));
			}
			formik.setFieldValue('cepProprietario', res.cep);
			formik.setFieldValue('bairroProprietario', res.bairro.substring(0, 70));
			formik.setFieldValue(
				'enderecoProprietario',
				res.endereco.substring(0, 70),
			);
			formik.setFieldValue('descricaoMunicipioProprietario', res.municipio);
		},
		[clearFormikAddressFields, dispatch, formik],
	);

	return (
		<>
			{consultarVeiculoV110.data &&
				consultarVeiculoV110.data.caracteristicaVeiculo && (
					<>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Section size="lg" title="Dados do Proprietário" titleSize="sm">
									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Field
												as={Input}
												title="Nome"
												titleSize="lg"
												name="nomeProprietario"
												maxLength={60}
												size={87}
												required
												disabled
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'nomeProprietario',
														onlyLetters(v.target.value),
													);
												}}
												error={!!formik.errors.nomeProprietario}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={11}>
											<CEP
												title="CEP do Imóvel"
												titleSize="lg"
												name="cepProprietario"
												defaultValue={formik.values.cepProprietario}
												required
												size={80}
												formik={formik}
												result={(res: IBuscarCepResultado) => {
													handleCepSelecionado(res);
												}}
											/>
										</Col>

										<Col span={11}>
											<Field
												as={Input}
												title="Bairro"
												titleSize="lg"
												name="bairroProprietario"
												maxLength={70}
												size={92}
												required
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'bairroProprietario',
														onlyLetters(v.target.value),
													);
												}}
												error={!!formik.errors.bairroProprietario}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={11}>
											<Field
												as={Input}
												title="Endereço"
												titleSize="lg"
												name="enderecoProprietario"
												maxLength={70}
												size={80}
												required
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'enderecoProprietario',
														onlyNumbersLettersSpace(v.target.value),
													);
												}}
												error={!!formik.errors.enderecoProprietario}
											/>
										</Col>

										<Col span={11}>
											<Field
												as={InputMask}
												title="Número"
												titleSize="lg"
												name="numeroProprietario"
												mask="99999"
												maskChar=""
												alwaysShowMask={false}
												size={92}
												required
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'numeroProprietario',
														onlyNumbers(v.target.value),
													);
												}}
												error={!!formik.errors.numeroProprietario}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={11}>
											<Field
												as={Input}
												title="Complemento"
												titleSize="lg"
												name="complementoProprietario"
												maxLength={6}
												size={80}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'complementoProprietario',
														onlyNumbersLettersSpace(v.target.value),
													);
												}}
												error={!!formik.errors.complementoProprietario}
											/>
										</Col>

										<Col span={11}>
											<Field
												as={InputMask}
												title="Telefone"
												titleSize="lg"
												name="telefone"
												mask="(99) 99999-9999"
												size={92}
												required
												error={!!formik.errors.telefone}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'telefone',
														onlyNumbers(v.target.value),
													);
												}}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={11}>
											<Field
												as={Select}
												title="Município"
												titleSize="lg"
												name="codigoMunicipioProprietario"
												options={consultarMunicipioPorUF.data}
												onChange={(v: number) => {
													formik.setFieldValue(
														'codigoMunicipioProprietario',
														v,
													);

													const selectedItem =
														consultarMunicipioPorUF.data.filter(
															item => v === item.value,
														);

													formik.setFieldValue(
														'descricaoMunicipioProprietario',
														selectedItem[0].label,
													);
												}}
												size={80}
												error={hasError(formik.errors, 'municipioProprietario')}
											/>
										</Col>
									</Row>

									<Row
										gutter={
											tipoPessoa === 'FISICA' ||
											formik.values.identificacao === 'FISICA'
												? [0, 10]
												: undefined
										}
									>
										<Col span={11}>
											<Field
												as={Select}
												title="Identificação Contribuinte"
												titleSize="lg"
												name="identificacao"
												options={data.tipoPessoa}
												onChange={(v: string) => {
													formik.setFieldValue('identificacao', v);

													if (v === 'FISICA') {
														formik.setFieldValue('cnpjProprietario', '');
														return;
													}

													if (v === 'JURIDICA') {
														formik.setFieldValue('cpfProprietario', '');
														return;
													}

													formik.setFieldValue('cpfProprietario', '');
													formik.setFieldValue('cnpjProprietario', '');
												}}
												size={80}
												required
												disabled={tipoPessoa !== ''}
												error={hasError(formik.errors, 'identificacao')}
											/>
										</Col>

										<Col span={11}>
											{formik.values.identificacao === '' && (
												<Input
													title="CPF/CNPJ Proprietário"
													titleSize="lg"
													name=""
													size={92}
													required
													disabled
													error={
														hasError(formik.errors, 'cpf') ||
														hasError(formik.errors, 'cnpj')
													}
												/>
											)}

											{formik.values.identificacao === 'FISICA' && (
												<Field
													as={InputMask}
													title="CPF/CNPJ Proprietário"
													titleSize="lg"
													name="cpfProprietario"
													mask="999.999.999-99"
													size={92}
													required
													disabled={cpfCnpj !== ''}
													error={hasError(formik.errors, 'cpf')}
												/>
											)}

											{formik.values.identificacao === 'JURIDICA' && (
												<Field
													as={InputMask}
													title="CPF/CNPJ Proprietário"
													titleSize="lg"
													name="cnpjProprietario"
													mask="99.999.999/9999-99"
													size={92}
													required
													disabled={cpfCnpj !== ''}
													error={hasError(formik.errors, 'cnpj')}
												/>
											)}
										</Col>
									</Row>

									{(tipoPessoa === 'FISICA' ||
										formik.values.identificacao === 'FISICA') && (
										<Row>
											<Col span={11}>
												<Row>
													<Col span={18}>
														<Field
															as={InputMask}
															title="RG"
															titleSize="lg"
															name="rgProprietario"
															mask="99.999.999"
															required
															error={hasError(formik.errors, 'pessoaFisicaRg')}
														/>
													</Col>
													<Col
														span={1}
														style={{
															alignSelf: 'center',
															textAlign: 'center',
															marginLeft: '2px',
															marginRight: '2px',
														}}
													>
														<span>-</span>
													</Col>
													<Col span={2}>
														<Field
															as={InputMask}
															mask="9"
															name="rgDigito"
															required
															error={hasError(formik.errors, 'pessoaFisicaRg')}
														/>
													</Col>
												</Row>
											</Col>
											<Col span={12}>
												<Row>
													<Col span={12} offset={1}>
														<Field
															as={Input}
															title="Órgão Expedidor"
															titleSize={100}
															name="orgaoExpeditorProprietario"
															maxLength={5}
															required
															onChange={(
																v: React.ChangeEvent<HTMLInputElement>,
															) => {
																formik.setFieldValue(
																	'orgaoExpeditorProprietario',
																	onlyLetters(v.target.value),
																);
															}}
															error={hasError(formik.errors, 'pessoaFisicaRg')}
														/>
													</Col>
													<Col span={6} offset={2}>
														<Field
															as={Select}
															title="UF"
															titleSize={20}
															name="ufProprietario"
															defaultFirstOption={false}
															options={data.uf}
															onChange={(value: string) =>
																formik.setFieldValue('ufProprietario', value)
															}
															required
															error={hasError(formik.errors, 'pessoaFisicaRg')}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									)}
									<Row>
										<Col span={11}>
											<Field
												as={Input}
												title="E-mail"
												titleSize="lg"
												name="emailCidadao"
												size={92}
												error={hasError(formik.errors, 'emailCidadao')}
												required
											/>
										</Col>
										<Col span={11}>
											<Field
												as={InputMask}
												title="Telefone de Contato"
												titleSize="lg"
												name="telefoneCidadao"
												mask="(99) 99999-9999"
												size={92}
												required
												error={!!formik.errors.telefoneCidadao}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'telefoneCidadao',
														onlyNumbers(v.target.value),
													);
												}}
											/>
										</Col>
									</Row>
								</Section>
							</Col>
						</Row>
					</>
				)}
		</>
	);
};

export default DadosProprietario;
