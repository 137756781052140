import styled from 'styled-components';

export const Container = styled.button`
	background-color: transparent;
	border: none;
	display: flex;
	margin-top: 16px;
	width: fit-content;

	&:hover {
		outline: unset;
	}
`;

export const Icon = styled.div`
	display: flex;
	margin: auto 8px auto 0;
`;

export const Text = styled.span`
	color: ${props => props.theme.typography.primary};
	font-size: calc(0.9rem + ${props => props.theme.fontScale}%);
	font-weight: 700;
`;
