import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Formik, Form, Field, FormikProps } from 'formik';

// REDUX
import {
	consultaReabilitacaoClear,
	consultaReabilitacaoRequest,
} from 'store/modules/api/detranCnh/reabilitacao/consultaReabilitacao/actions';
import {
	consultaPconClear,
	consultaPconRequest,
} from 'store/modules/api/detranCnh/eportal/consultaPcon/actions';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import { ConsultaPconRequest } from 'store/modules/api/detranCnh/eportal/consultaPcon/types';
import {
	relatorioAgendamentoMedicoPsicologicoRequest,
	relatorioAgendamentoMedicoPsicologicoClear,
} from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';
import {
	consultaProcessoAbertoClear,
	consultaProcessoAbertoRequest,
} from 'store/modules/api/detranCnh/renachIntegrado/consultaProcessoAberto/actions';
import { eventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/actions';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import CPF from 'components/Common/Form/Fields/CPF';
import ButtonImage from 'components/Common/ButtonImage';
import InputMask from 'components/Common/Form/Input/InputMask';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { limparMascara } from 'utils/genericFunctions';
import { threatValuesStartSessionEventRequest } from 'utils/functions/threatEventsRequest';
import { REABILITACAO_ERROR_RESPONSE_SERVICE_MESSAGE } from 'pages/DetranCnh/utils/constants/serviceResponseMessages';
import { treatAgendamentoMedicoPsicologicoRequestInPesquisar } from 'pages/DetranCnh/utils/threatServicesRequest';

// ROUTES
import { ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_STATUS_CONDUTOR } from 'pages/DetranCnh/routes/paths';
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// FORM
import {
	IFormPesquisaRenovacao,
	initialValues,
} from 'pages/DetranCnh/components/pages/PreCadastro/Pesquisa/form';
import PDFViewer from 'components/Common/PDFViewer';
import {
	IFormPesquisaReabilitacaoCassacaoCrime,
	treatValues,
	schema,
} from './form';

const PesquisaReabilitacaoCassacaoCrime: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [modalPrint, setModalPrint] = useState(false);

	const { consultaReabilitacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.reabilitacao,
	);

	const { consultaPcon } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.eportal,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { relatorio } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.agendamentoMedicoPsicologico,
	);

	const { consultaProcessoAberto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const clearInitialRequests = useCallback(
		(formik?: FormikProps<IFormPesquisaRenovacao>) => {
			setModalPrint(false);
			dispatch(clearNotifications());
			dispatch(relatorioAgendamentoMedicoPsicologicoClear());
			dispatch(consultaReabilitacaoClear());
			dispatch(consultaPconClear());
			dispatch(consultaProcessoAbertoClear());

			if (formik) {
				formik.handleReset();
			}
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: IFormPesquisaReabilitacaoCassacaoCrime) => {
			clearInitialRequests();

			const valuesRequest = treatValues(values, {
				usuario: loginUnico.login.mainframe.idUsuario,
				senha: loginUnico.login.mainframe.senhaMainFrame,
				ip: loginUnico.login.user.ip,
			});

			const valuesStartSessionEvent = threatValuesStartSessionEventRequest(
				atendimento,
				loginUnico,
			);
			dispatch(eventoInicioSessaoRequest(valuesStartSessionEvent));
			dispatch(consultaReabilitacaoRequest(valuesRequest));
		},
		[atendimento, clearInitialRequests, dispatch, loginUnico],
	);

	useEffect(() => {
		clearInitialRequests();
	}, [clearInitialRequests]);

	useEffect(() => {
		if (
			consultaPcon.status === 0 &&
			consultaReabilitacao.status === 200 &&
			consultaReabilitacao.data?.cpf
		) {
			const consultaPconRequestData: ConsultaPconRequest = {
				senha: loginUnico.login.mainframe.senhaMainFrame,
				usuario: loginUnico.login.mainframe.idUsuario,
				cpf:
					(consultaReabilitacao.data?.cpf &&
						limparMascara(consultaReabilitacao.data.cpf)) ||
					(consultaReabilitacao.requestData?.cpf &&
						limparMascara(consultaReabilitacao.requestData.cpf)) ||
					'',
			};

			dispatch(consultaPconRequest(consultaPconRequestData));
		}
	}, [
		consultaPcon.status,
		consultaReabilitacao.status,
		consultaReabilitacao.data,
		dispatch,
		loginUnico,
		consultaReabilitacao.requestData,
	]);

	useEffect(() => {
		if (
			consultaProcessoAberto.status === 0 &&
			((consultaReabilitacao.status === 200 &&
				consultaReabilitacao.data?.cpf &&
				consultaPcon.status === 200 &&
				consultaPcon.data) ||
				(consultaReabilitacao.status === 400 &&
					consultaReabilitacao.requestData?.cpf &&
					consultaReabilitacao.dataError?.mensagem?.trim().toLowerCase() ===
						REABILITACAO_ERROR_RESPONSE_SERVICE_MESSAGE.CONDUTOR_PROCESSO_ABERTO.toLowerCase() &&
					consultaPcon.status === 0))
		) {
			dispatch(
				consultaProcessoAbertoRequest({
					cpf:
						(consultaReabilitacao.data?.cpf &&
							limparMascara(consultaReabilitacao.data.cpf)) ||
						(consultaReabilitacao.requestData?.cpf &&
							limparMascara(consultaReabilitacao.requestData.cpf)) ||
						'',
					ident: 0,
				}),
			);
		}
	}, [
		consultaPcon.data,
		consultaPcon.status,
		consultaProcessoAberto.status,
		consultaReabilitacao.status,
		consultaReabilitacao.data,
		consultaReabilitacao.requestData,
		consultaReabilitacao.dataError,
		dispatch,
	]);

	useEffect(() => {
		if (
			(relatorio.data || !relatorio.data) &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			consultaProcessoAberto.data?.message
		) {
			addNotifications({
				errors: [consultaProcessoAberto.data.message],
			});
		}
	}, [consultaProcessoAberto.data, dispatch, relatorio.data]);

	useEffect(() => {
		if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			(consultaProcessoAberto.data.dataExame ||
				consultaProcessoAberto.data.dataExamePsicologo)
		) {
			const requestData = treatAgendamentoMedicoPsicologicoRequestInPesquisar(
				consultaProcessoAberto.data,
			);

			dispatch(relatorioAgendamentoMedicoPsicologicoRequest(requestData));
		} else if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) === 0
		) {
			history.push(ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_STATUS_CONDUTOR);
		}
	}, [
		consultaProcessoAberto.data,
		consultaProcessoAberto.status,
		dispatch,
		history,
	]);

	return (
		<>
			<Section title="Pesquisar">
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={values => getValidationsErrors(values, schema)}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row gutter={[0, 10]}>
										<Col span={11}>
											<CPF
												title="CPF"
												subtitle="(Digite apenas números)"
												numero="cpf"
												formik={formik}
												error={!!formik.errors.cpf}
												required
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={10}>
											<Field
												as={InputMask}
												name="numeroPgu"
												title="PGU"
												mask="999999999"
												subtitle="(Apenas números)"
												titleSize="sm"
												error={!!formik.errors.numeroPgu}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={24} />
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => {
													clearInitialRequests(formik);
												}}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage
												type="submit"
												src="pesquisar"
												disabled={
													consultaReabilitacao.status === 100 ||
													consultaPcon.status === 100
												}
											/>
										</Col>

										{relatorio && relatorio.data && relatorio.data !== null ? (
											<Col offset={1}>
												<ButtonImage
													src="btn-imprimir-agendamento-medico-psicologico"
													onClick={() => {
														setModalPrint(true);
													}}
												/>
											</Col>
										) : (
											''
										)}
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>

			{modalPrint && (
				<PDFViewer
					title="agendamentoMedicoViewer"
					source={relatorio?.data?.arquivoPDF || ''}
					onClose={() => setModalPrint(false)}
					renderMode="popup"
				/>
			)}

			<ButtonVoltar route={ROUTE_DETRAN_CNH} />
		</>
	);
};
export default PesquisaReabilitacaoCassacaoCrime;
