import React, { ButtonHTMLAttributes, useEffect, useRef } from 'react';

import iirgd from 'assets/images/orgaos/1.jpg';
import detran_cnh from 'assets/images/orgaos/2.jpg';
import mp from 'assets/images/orgaos/3.jpg';
import cdhu from 'assets/images/orgaos/4.png';
import detran_crv from 'assets/images/orgaos/5.jpg';
import educacao from 'assets/images/orgaos/6.jpg';
import seds from 'assets/images/orgaos/7.jpg';
import procon from 'assets/images/orgaos/8.jpg';
import prefeituras from 'assets/images/orgaos/10.jpg';
import poupatempo from 'assets/images/orgaos/12.jpg';
import bolsa_povo from 'assets/images/orgaos/13.jpg';

// STYLED
import { Skeleton } from 'antd';
import { Button } from './styled';

export type SourceButtonImage =
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 9
	| 10
	| 12
	| 13;

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	type?: 'submit' | 'reset' | 'button' | undefined;
	src: SourceButtonImage;
	disabled?: boolean;
	setFocus?: () => boolean;
}

const ButtonImageOrgao: React.FC<Props> = ({
	type = 'button',
	src,
	disabled = false,
	setFocus,
	...rest
}) => {
	let orgao;

	switch (src) {
		case 1: {
			orgao = iirgd;
			break;
		}
		case 2: {
			orgao = detran_cnh;
			break;
		}
		case 3: {
			orgao = mp;
			break;
		}
		case 4: {
			orgao = cdhu;
			break;
		}
		case 5: {
			orgao = detran_crv;
			break;
		}
		case 6: {
			orgao = educacao;
			break;
		}
		case 7: {
			orgao = seds;
			break;
		}
		case 8: {
			orgao = procon;
			break;
		}

		case 10: {
			orgao = prefeituras;
			break;
		}
		case 12: {
			orgao = poupatempo;
			break;
		}
		case 13: {
			orgao = bolsa_povo;
			break;
		}
		default:
	}

	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (setFocus) {
			const focus = setFocus();

			if (focus === true) {
				buttonRef.current?.focus();
			}
		}
	}, [setFocus]);

	return (
		<Button
			type={type}
			disabled={disabled}
			onClick={rest.onClick}
			ref={buttonRef}
			className="img imageInput"
		>
			{orgao && <img src={orgao} alt={orgao} />}
			{orgao === undefined && (
				<Skeleton.Image style={{ width: 130, height: 130 }} />
			)}
		</Button>
	);
};

export default ButtonImageOrgao;
