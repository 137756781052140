import produce from 'immer';

// UTILS
import { toSelect } from 'utils/genericFunctions';

// TYPES
import { Types, ConsultaEntidadeMilitar } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultaEntidadeMilitar = {
	status: 0,
	data: null,
};

export default function consultaEntidadeMilitarReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaEntidadeMilitar {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_ENTIDADE_MILITAR_REQUEST: {
				break;
			}
			case Types.CONSULTA_ENTIDADE_MILITAR_SUCCESS: {
				draft.status = action.payload.status;
				const lista = toSelect(action.payload.data, 'descricao', 'codigo');

				draft.data = [...lista];
				break;
			}
			case Types.CONSULTA_ENTIDADE_MILITAR_FAILURE: {
				draft.status = action.payload.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
