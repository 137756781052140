export enum Types {
	CONSULTA_HISTORICO = '@contabilizacao/CONSULTA_HISTORICO',
	CONSULTA_HISTORICO_SUCCESS = '@contabilizacao/CONSULTA_HISTORICO_SUCCESS',
	CONSULTA_HISTORICO_FAILURE = '@contabilizacao/CONSULTA_HISTORICO_FAILURE',
}

export interface ConsultaHistoricoState {
	consultaHistoricoData: IConsultaHistorico[];
	totalRegistrosHistorico: number;
}

export interface IConsultaHistoricoRequest {
	data_evento_de?: string;
	data_evento_ate?: string;
	data_criacao_de?: string;
	data_criacao_ate?: string;
	data_modificacao_de?: string;
	data_modificacao_ate?: string;
	id_atendimento?: string;
	id_cidadao?: string;
	canal?: string;
	pagina_inicio: number;
	limite: number;
}

export interface IConsultaHistorico {
	codigo: number;
	codigo_http_status: number;
	dados_json: string;
	data_criacao: string;
	mensagem: string;
	data_evento: string;
	id_atendimento: string;
	tipo: string;
	id_cidadao: string;
	canal: ICanal;
	key: string;
}

export interface ICanal {
	id: string;
	descricao: string;
}

interface ICanalJson {
	id: string;
	desc: string;
}

export interface IJson {
	canal: ICanalJson;
	id_atendimento: string;
	id_cidadao: string;
	dados: {
		data_hora: string;
	};
}
