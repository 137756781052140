import React from 'react';

import { ComboFinalizacao } from '../Buttons/ComboFinalizacao';

export const SegundaVia: React.FC = () => {
	return (
		<>
			<ComboFinalizacao />
		</>
	);
};
