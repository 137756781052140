import React, { useCallback } from 'react';

import { Row, Col } from 'antd';
import { FormikProps } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ConsultaPesquisaCirg } from 'store/modules/api/iirgd/consultaPesquisaCirg/types';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';

// ASSETS
import icon from 'assets/images/icons/icon_lupa.gif';

// COMPONENTS
import RG from 'components/Common/Form/Fields/RG';

import { Container, Button } from 'components/Common/SearchButtons/styled';

// UTILS
import { isRgSegundaVia } from 'pages/iirgd/RgFicDigital/utils/rgFicDigitalFunctions';
import {
	calcularIdade,
	formatDate,
	limparMascara,
} from 'utils/genericFunctions';

// SERVICES
import { postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// FORM
import {
	ConsultaPesquisaCivilData,
	RetornoCivil,
} from 'store/modules/api/iirgd/consultaPesquisaCivil/types';
import { KitFamiliaDARE } from '../form';

interface Props {
	initialValue: string;
	reset: boolean;
	formik: FormikProps<KitFamiliaDARE>;
}

const RgDependente: React.FC<Props> = ({ initialValue, reset, formik }) => {
	const dispatch = useDispatch();

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const consultaPesquisaCirg = useCallback(
		async (searchNumeroRg: string) => {
			if (
				limparMascara(searchNumeroRg).length === 0 &&
				notifications.errors.length
			) {
				formik.handleReset();
				dispatch(clearNotifications());
			} else if (searchNumeroRg.length >= 10) {
				clearNotifications();
				let numeroRg = searchNumeroRg;
				if (limparMascara(numeroRg).length === 10) {
					numeroRg = `${searchNumeroRg.slice(0, 8)}-${searchNumeroRg.slice(
						8,
						10,
					)}`;
				}

				formik.setErrors({});
				formik.setFieldValue('loteRg', '');
				formik.setFieldValue('nome', '');
				formik.setFieldValue('dataNascimento', '');

				const response = await postApi(PATH_IIRGD, 'consultaPesquisaCidadao', {
					numeroRg,
					pesquisaCIRG: true,
					pesquisaCivil: true,
					usuario: login.mainframe.idUsuario,
					senha: login.mainframe.senhaMainFrame,
				});

				clearNotifications();

				if (response.status === 200 && response.data) {
					const data = response.data as {
						pesquisaCIRG: ConsultaPesquisaCirg[];
						pesquisaCivil: RetornoCivil[];
					};
					const rgCirg = data.pesquisaCIRG.filter(
						(itemCirg: ConsultaPesquisaCirg) => itemCirg.numeroRg === numeroRg,
					);
					const rgCivil: RetornoCivil[] = data.pesquisaCivil.filter(
						(itemCivil: RetornoCivil) =>
							itemCivil.pessoa.filter(
								(itemPessoa: ConsultaPesquisaCivilData) =>
									itemPessoa.numeroRg === numeroRg,
							),
					);
					const rgLote = rgCivil[0].pessoa.filter(
						(itemRgLote: ConsultaPesquisaCivilData) =>
							itemRgLote.numeroRg === numeroRg,
					);

					if (rgCirg.length && rgLote.length) {
						const { nome, dataNascimento, sexo } = rgCirg[0];
						const { lote } = rgLote[0];
						formik.setFieldValue('loteRg', lote);
						formik.setFieldValue('sexo', sexo);
						if (isRgSegundaVia(lote)) {
							if (
								(sexo.toLocaleUpperCase() === 'M' &&
									calcularIdade(formatDate(dataNascimento)) >= 65) ||
								(sexo.toLocaleUpperCase() === 'F' &&
									calcularIdade(formatDate(dataNascimento)) >= 60)
							) {
								addNotifications({
									errors: [
										'Isento da taxa de 2ª via conforme lei estadual 10.952 de 07/11/2001. (Idoso, Idosa ou Desempregado)',
									],
								});
							}
							formik.setFieldValue('nome', nome);
							formik.setFieldValue(
								'dataNascimento',
								formatDate(dataNascimento),
							);
						} else {
							addNotifications({
								errors: ['RG de Primeira via é Isento de Taxa.'],
							});

							formik.setFieldError('numeroRg', '');
						}
					}
				} else {
					addNotifications({
						errors: [
							'Ocorreu uma falha na comunicação com o serviço. Preencha manualmente as informações ou tente novamente.',
						],
					});

					formik.setFieldValue('loteRg', '2');
				}
			}
		},
		[
			notifications.errors.length,
			dispatch,
			formik,
			login.mainframe.idUsuario,
			login.mainframe.senhaMainFrame,
		],
	);

	return (
		<Row gutter={[0, 10]}>
			<Col span={9}>
				<RG
					title="RGE/CIN"
					titleSize={200}
					numero="numeroRg"
					defaultNumero={initialValue}
					required
					formik={formik}
					result={rg => {
						formik.setFieldValue('numeroRg', rg.numeroRg);
						consultaPesquisaCirg(rg.numeroRg);
					}}
					reset={reset}
					error={!!formik.errors.numeroRg || !!formik.errors.loteRg}
				/>
			</Col>

			<Col>
				<Container>
					<Button
						type="button"
						icon={icon}
						disabled={
							!formik.values.numeroRg || formik.values.numeroRg.length !== 10
						}
						onClick={() => consultaPesquisaCirg(formik.values.numeroRg)}
					/>
				</Container>
			</Col>

			<Col>
				<small
					style={{
						display: 'flex',
						alignItems: 'center',
						height: '100%',
					}}
				>
					(Permitido apenas RGE/CIN de Segunda Via)
				</small>
			</Col>
		</Row>
	);
};

export default RgDependente;
