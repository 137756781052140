import React from 'react';

const render = (text: any, record: any, index: any, isDate: any = false) => {
	return {
		props: {
			style: { color: record.usuarioCentral ? '#a0a0a0' : 'red' },
		},
		children: (
			<span style={{ color: record.usuarioCentral ? '#a0a0a0' : 'red' }}>
				{isDate ? text.replace(/T.*/, '').split('-').reverse().join('/') : text}
			</span>
		),
	};
};

export const columns = [
	{
		title: 'POSTO DE ORIGEM',
		dataIndex: 'postoOrigem',
		children: [
			{
				title: 'RG',
				dataIndex: 'rg',
				showSorterTooltip: false,
				render,
				sorter: {
					compare: (a: any, b: any) => a.rg - b.rg,
					multiple: 1,
				},
			},
			{
				title: 'NOME DE REGISTRO CIVIL',
				dataIndex: 'nome',
				showSorterTooltip: false,
				render,
				sorter: {
					compare: (a: any, b: any) => a.nome.localeCompare(b.nome),
					multiple: 1,
				},
			},
			{
				title: 'POSTO',
				dataIndex: 'posto',
				showSorterTooltip: false,
				render,
				sorter: {
					compare: (a: any, b: any) => a.posto.localeCompare(b.posto),
					multiple: 1,
				},
			},
			{
				title: 'USUÁRIO',
				dataIndex: 'usuario',
				showSorterTooltip: false,
				render,
				sorter: {
					compare: (a: any, b: any) => a.usuario.localeCompare(b.usuario),
					multiple: 1,
				},
			},
		],
	},
	{
		title: 'CENTRAL',
		dataIndex: 'central',
		children: [
			{
				title: 'USUÁRIO',
				dataIndex: 'usuarioCentral',
				showSorterTooltip: false,
				render,
				sorter: {
					compare: (a: any, b: any) =>
						a?.usuario.localeCompare(b?.usuarioCentral),
					multiple: 1,
				},
			},
		],
	},
	{
		title: 'POSTO',
		dataIndex: 'postoHeader',
		children: [
			{
				title: 'USUÁRIO',
				dataIndex: 'usuarioPosto',
				showSorterTooltip: false,
				render,
				sorter: {
					compare: (a: any, b: any) =>
						a?.usuarioPosto.localeCompare(b?.usuarioPosto),
					multiple: 1,
				},
			},
			{
				title: 'ENCERRADO',
				dataIndex: 'dataEncerrado',
				showSorterTooltip: false,
				render: (text: any, record: any, index: any) =>
					render(text, record, index, !!record.dataEncerrado),
				sorter: {
					compare: (a: any, b: any) =>
						a?.dataEncerrado.localeCompare(b?.dataEncerrado),
					multiple: 1,
				},
			},
		],
	},
];
