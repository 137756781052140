import styled, { css } from 'styled-components';

interface SubTitleProps {
	disabled: boolean;
	strong: boolean;
}
interface IContentProps {
	marginLeft: number;
}

export const Container = styled.div`
	display: flex;
	align-items: center;
	min-height: 25px;
	margin: auto 0;

	.ant-checkbox-wrapper {
		text-align: justify;
	}
`;

export const Content = styled.div<IContentProps>`
	align-items: center;
	display: flex;
	min-height: 25px;
	line-height: 25px;

	.ant-checkbox-checked {
		.ant-checkbox-inner {
			&::after {
				border-color: ${props => props.theme.typography.primary};
			}
		}
	}

	.ant-checkbox-inner {
		background-color: ${props => props.theme.colors.background};
		border-color: ${props => props.theme.colors.border};
	}

	.ant-checkbox-disabled {
		.ant-checkbox-inner {
			background-color: ${props => props.theme.colors.disabled.input};
		}
	}

	${props =>
		props.marginLeft !== 0 &&
		css`
			margin-left: ${props.marginLeft}px;
		`};
`;

export const SubTitle = styled.label<SubTitleProps>`
	color: ${props => props.theme.typography.primary};
	cursor: pointer;
	font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
	user-select: none;

	${props =>
		props.strong === true &&
		css`
			font-weight: 500;
		`};

	${props =>
		props.disabled === true &&
		css`
			cursor: no-drop;
		`};
`;
