import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';

import * as Yup from 'yup';
import { IEvento } from 'store/modules/api/detranCrv/types';

export interface ImprimirCrvle {
	evento: IEvento;
	placa: string;
	renavam: string;
	cpfCnpjProprietario: string;
}

export const initialValues: ImprimirCrvle = {
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
		identificacao: '',
		ip: '',
		canal: {
			id: 0,
			desc: '',
			localidade: {
				id: 0,
				desc: '',
			},
		},
	},
	placa: '',
	renavam: '',
	cpfCnpjProprietario: '',
};

export function treatValues(
	evento: IEvento,
	values: ImprimirCrvle,
): ImprimirCrvle {
	const formattedData = {
		evento,
		placa: values.placa?.trim().toUpperCase() || '',
		renavam: values.renavam?.trim() || '',
		cpfCnpjProprietario: values.cpfCnpjProprietario?.trim() || '',
	};

	return formattedData;
}

export const schema = Yup.object<ImprimirCrvle>().shape({
	placa: Yup.string().required(`Campo Obrigatório: Placa.`),
	renavam: Yup.string().required(`Campo Obrigatório: Renavam.`),
	cpfCnpjProprietario: Yup.string().required(
		`Campo Obrigatório: CPF ou CGC do Proprietário.`,
	),
});

export const treatRequestSguStatistics = (
	user: IUser,
	formData: ImprimirCrvle,
): IncluirRequest => {
	const payload: IncluirRequest = {
		observacao: `Chassi: N/A, Placa: ${
			formData?.placa || 'N/A'
		}, Renavam Gerada ${formData?.renavam || 'N/A'}`,
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento: `${formData.cpfCnpjProprietario || 'N/A'}`,
		codigoLocalidade: (user?.posto && Number(user.posto)) || 0,
		idOrgao: 5,
		cpf: user.cpf,
		descricaoServico: 'Imprimir CRLV-e de Licenciamento de Veículos',
		idMotivo: 16,
	};

	return payload;
};
