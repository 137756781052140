import React from 'react';

import Section from 'components/Common/Section';
import SimpleTable from 'components/Common/Table';

import { HistoricoAnalise } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarAcompanhamento/types';

interface Props {
	lista: HistoricoAnalise[] | [];
}

const ListaAnalises: React.FC<Props> = ({ lista }) => {
	const headers = [
		{
			title: 'Data',
			key: 'data',
			dataIndex: 'data',
		},
		{
			title: 'Local',
			key: 'local',
			dataIndex: 'local',
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
		},
		{
			title: 'Motivo Reprovação',
			key: 'motivo',
			dataIndex: 'motivoReprovacao',
		},
	];

	return (
		<>
			<Section size="sm" title="Historico de Análises">
				<SimpleTable headers={headers} body={lista} />
			</Section>
		</>
	);
};

export default ListaAnalises;
