import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	TIPOS_RESOLUCAO_REQUEST = '@procon/TIPOS_RESOLUCAO_REQUEST',
	TIPOS_RESOLUCAO_SUCCESS = '@procon/TIPOS_RESOLUCAO_SUCCESS',
	TIPOS_RESOLUCAO_FAILURE = '@procon/TIPOS_RESOLUCAO_FAILURE',
	TIPOS_RESOLUCAO_CLEAR = '@procon/TIPOS_RESOLUCAO_CLEAR',
}

export interface TiposResolucao {
	status: null | number;
	data: OptionProps[];
}
