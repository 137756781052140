export enum Types {
	GERAR_RENACH_SEGUNDA_VIA_REQUEST = '@cnh/GERAR_RENACH_SEGUNDA_VIA_REQUEST',
	GERAR_RENACH_SEGUNDA_VIA_SUCCESS = '@cnh/GERAR_RENACH_SEGUNDA_VIA_SUCCESS',
	GERAR_RENACH_SEGUNDA_VIA_FAILURE = '@cnh/GERAR_RENACH_SEGUNDA_VIA_FAILURE',
}

export interface gerarRenachSegundaVia {
	status: number;
	data: null | GerarRenachSegundaViaData;
	requestData: null | GerarRenachSegundaViaRequest;
}

interface GerarRenachSegundaViaData {
	numRenachGerado: string;
	flagAgendarMedico: boolean;
}

interface Eventos {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		// estacao: {
		// 	id: number;
		// 	desc: string;
		// };
		localidade: {
			id: number;
			desc: string;
		};
	};
	// atendente: {
	// 	nome: string;
	// 	id_atendente: string;
	// 	id_posto: string;
	// 	desc_posto: string;
	// };
}

interface GerarRenachSegundaViaBase {
	usoFormularioEnum: string;
	cpf: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	nomeSocial: string;
	sexo: string;
	dataNascimento: string;
	tipoDocumentoEnum: string;
	numDocumento: string;
	orgaoExpDocumento: string;
	orgaoUfExpDocumento: string;
	nacionalidade: number | string;
	codigoNaturalidade: string | number;
	email: string;
	celularDDD: string;
	celular: string;
	logradouroUf: string | null;
	logradouro: string;
	logradouroNumero: string | null;
	logradouroComplemento: string;
	logradouroBairro: string;
	logradouroCodMunicipio: string;
	logradouroCep: string;
	codCiretranCondutor: string;
	statusReusoEnum: string;
	flagAtividadeRemuneradaAtual: boolean;
	flagAtividadeRemunerada: boolean | string;
	flagSolicitaCNHDigital: boolean | string;
	codigoVerificador: string | null;
	categoriaPretendida: string;
	flagProcurador: boolean | string;
	codCiretranHabilitacao: string | null;
	cpfUsuario: string;
	codLocalidade: number | string;
	paisAfetivos1: string;
	paisAfetivos2: string;
}

export interface GerarRenachSegundaViaForm extends GerarRenachSegundaViaBase {
	flagNomeSocial?: string;
	flagFiliacaoAfetiva?: string;
	uf?: string;
	confirmaEmail?: string;
	autorizaSMS?: string;
	autorizaEmail?: string;
	telefone?: string;
	logradouroDescMunicipio?: string;
	enderecoRecebimentoNumero?: string;
	enderecoRecebimentoBairro?: string;
	enderecoRecebimentoCep?: string;
	enderecoRecebimentoLogradouro?: string;
	enderecoRecebimentoComplemento?: string;
	enderecoRecebimentoMunicipio?: string;
	enderecoRecebimentoCodigoMunicipio?: string;
}
export interface GerarRenachSegundaViaRequest
	extends GerarRenachSegundaViaBase {
	evento: Eventos;
}
