import JustificativaAtendimento from 'components/Pages/JustificativaAtendimento';
import FichaAluno from '../Inscricao/FichaAluno';

import RecuperarAtendimento from '../RecuperarAtendimento';
import PesquisaFichaAluno from '../Inscricao/Pesquisa';
import InscricaoAluno from '../Inscricao/InscricaoAluno';
import PesquisarAluno from '../Cancelamento/PesquisarAluno';
import VizualizaFichaAluno from '../Cancelamento/VizualizaFichaAluno';
import PesquisaResponsaveis from '../CadastroResponsaveis/Pesquisa';
import FichaResponsavel from '../CadastroResponsaveis/FichaResponsavel';

// PATHS
import {
	ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_FICHA_ALUNO,
	ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_INSCRICAO_ALUNO,
	ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_PESQUISA_ALUNO,
	ROUTE_SECRETARIA_EDUCACAO_RECUPERAR_ATENDIMENTO,
	ROUTE_SECRETARIA_EDUCACAO_CANCELAMENTO_PESQUISAR_ALUNO,
	ROUTE_SECRETARIA_EDUCACAO_CANCELAMENTO_FICHA_ALUNO,
	ROUTE_SECRETARIA_EDUCACAO_JUSTIFICATIVA_ATENDIMENTO,
	ROUTE_SECRETARIA_EDUCACAO_CADASTRO_RESPONSAVEL_FICHA,
	ROUTE_SECRETARIA_EDUCACAO_CADASTRO_RESPONSAVEIS_PESQUISA,
} from './paths';

export const secretariaEducacaoRoute = {
	name: 'Secretaria da Educação',
	route: '/secretaria-educacao',
};

const secretariaEducacaoRoutes = [
	{
		path: ROUTE_SECRETARIA_EDUCACAO_RECUPERAR_ATENDIMENTO,
		breadcrumb: [
			secretariaEducacaoRoute,
			{
				name: 'Recuperar atendimento',
			},
		],
		Component: RecuperarAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SECRETARIA_EDUCACAO_JUSTIFICATIVA_ATENDIMENTO,
		breadcrumb: [
			secretariaEducacaoRoute,
			{
				name: 'Justificativa atendimento',
			},
		],
		Component: JustificativaAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_PESQUISA_ALUNO,
		breadcrumb: [
			secretariaEducacaoRoute,
			{
				name: 'Pesquisar aluno',
			},
		],
		Component: PesquisaFichaAluno,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_FICHA_ALUNO,
		breadcrumb: [
			secretariaEducacaoRoute,
			{
				name: 'Ficha Aluno',
			},
		],
		Component: FichaAluno,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_INSCRICAO_ALUNO,
		breadcrumb: [
			secretariaEducacaoRoute,
			{
				name: 'Inscrição Aluno',
			},
		],
		Component: InscricaoAluno,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SECRETARIA_EDUCACAO_CANCELAMENTO_PESQUISAR_ALUNO,
		breadcrumb: [
			secretariaEducacaoRoute,
			{
				name: 'Pesquisar Aluno',
			},
		],
		Component: PesquisarAluno,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SECRETARIA_EDUCACAO_CANCELAMENTO_FICHA_ALUNO,
		breadcrumb: [
			secretariaEducacaoRoute,
			{
				name: 'Visualiza Ficha',
			},
		],
		Component: VizualizaFichaAluno,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SECRETARIA_EDUCACAO_CADASTRO_RESPONSAVEIS_PESQUISA,
		breadcrumb: [
			secretariaEducacaoRoute,
			{
				name: 'Pesquisar Responsáveis',
			},
		],
		Component: PesquisaResponsaveis,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SECRETARIA_EDUCACAO_CADASTRO_RESPONSAVEL_FICHA,
		breadcrumb: [
			secretariaEducacaoRoute,
			{
				name: 'Ficha Responsável',
			},
		],
		Component: FichaResponsavel,
		isAuthenticated: true,
		exact: true,
	},
];

export default secretariaEducacaoRoutes;
