import React, { useCallback } from 'react';

import { Modal } from 'antd';

// REDUX
import { useSelector } from 'react-redux';

import { ApplicationState } from 'store';
import { IConsultaPesquisaCriminal } from 'store/modules/api/iirgd/consultaPesquisaCriminal/types';

// SERVICE
import { postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// STYLED
import { Button } from './styled';

interface Props {
	data: IConsultaPesquisaCriminal;
	onSuccess: () => void;
}

export const CentralLiberacao: React.FC<Props> = ({ data, onSuccess }) => {
	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { consultaPesquisaCidadao } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const handleSubmit = useCallback(async () => {
		if (consultaPesquisaCidadao.selecionado) {
			const response = await postApi(PATH_IIRGD, 'centralDeLiberacao', {
				envioPosto: login.user.posto,
				envioUsuario: login.mainframe.idUsuario,
				pesquisaDtNascimento:
					consultaPesquisaCidadao.selecionado.dataNascimento,
				pesquisaMae: consultaPesquisaCidadao.selecionado.nomeMae,
				pesquisaNome: consultaPesquisaCidadao.selecionado.nome,
				pesquisaPai: consultaPesquisaCidadao.selecionado.nomePai,
				pesquisaRg: consultaPesquisaCidadao.selecionado.numeroRg,
				restricaoDtNascimento: data.dataNascimento,
				restricaoMae: data.nomeMae,
				restricaoNaturalidade: data.descricaoNaturalidade,
				restricaoNome: data.nome,
				restricaoObservacao: data.status,
				restricaoPai: data.nomePai,
				restricaoRg: data.numeroRg,
				restricaoStatusEmissao: data.statusEmissao,
			});

			if (response.status === 200) {
				onSuccess();
			}
		}
	}, [login, consultaPesquisaCidadao.selecionado, data, onSuccess]);

	const handleClick = useCallback(() => {
		const envioCentralLiberacao = login.user.parametrosLocalidade.find(
			parametro => parametro.sigla === 'HABILITA_ENVIO_CENTRAL_LIBERACAO',
		);

		if (envioCentralLiberacao && envioCentralLiberacao.valor === 'TRUE') {
			Modal.confirm({
				title: 'Enviar para Central de Liberação?',
				content: (
					<>
						<div>{data.nome}</div>
						<div>RGE/CIN: {data.numeroRg}</div>
					</>
				),
				onOk() {
					handleSubmit();
				},
				cancelText: 'Cancelar',
				okText: 'Enviar',
				style: { animationDuration: '0s' },
			});
		}
	}, [login.user.parametrosLocalidade, data, handleSubmit]);

	return (
		<Button
			type="button"
			statusCriminal={data.statusCriminal}
			onClick={handleClick}
		/>
	);
};
