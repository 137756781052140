import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_EDUCACAO } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { RemoverInscricaoData, Types } from './types';
import { removerInscricaoSuccess, removerInscricaoFailure } from './actions';

function* removerInscricaoRequest(request: ReducerAction) {
	const { payload }: { payload: RemoverInscricaoData } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_EDUCACAO,
		`aluno/${CHANNEL}/inscricao/remove`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(removerInscricaoSuccess(response));
	} else if (response.error) {
		yield put(removerInscricaoFailure(response.message));
	}
}

export default all([
	takeLatest(Types.REMOVER_INSCRICAO_REQUEST, removerInscricaoRequest),
]);
