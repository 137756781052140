export enum Types {
	COMBO_MUNICIPIOS_SP_REQUEST = '@EDUCACAO/COMBO_MUNICIPIOS_SP_REQUEST',
	COMBO_MUNICIPIOS_SP_SUCCESS = '@EDUCACAO/COMBO_MUNICIPIOS_SP_SUCCESS',
	COMBO_MUNICIPIOS_SP_FAILURE = '@EDUCACAO/COMBO_MUNICIPIOS_SP_FAILURE',
}

export interface ComboMunicipiosSP {
	status: number;
	data: ComboMunicipioData;
}

interface ComboMunicipioData {
	processoId: string;
	municipios: Municipios[];
}

interface Municipios {
	codigo: string;
	descricao: string;
}

export interface ComboMunicipiosSPRequestData {
	uf: string;
	codDiretoria?: string;
}
