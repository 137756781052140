export enum Types {
	CONSULTAR_ESTATISTICA_REQUEST = '@relatorios/CONSULTAR_ESTATISTICA_REQUEST',
	CONSULTAR_ESTATISTICA_FAILURE = '@relatorios/CONSULTAR_ESTATISTICA_FAILURE',
	CONSULTAR_ESTATISTICA_SUCCESS = '@relatorios/CONSULTAR_ESTATISTICA_SUCCESS',
	CONSULTAR_ESTATISTICA_CLEAR = '@relatorios/CONSULTAR_ESTATISTICA_CLEAR',
}

export interface IConsultarEstatisticaState {
	status: number;
	data: IConsultarEstatistica[] | null;
}

export interface IConsultarEstatistica {
	id: number;
	sistemaOrigem: string;
	numProcesso: string;
	tipoDocumento: string;
	documento: string;
	localidade: string;
	usuario: string;
	servico: string;
	confronto: string;
	dadosEnviados: string;
	motivo: string;
	via: number;
	obs: string;
	dataCadastro: string;
	totalizador: string;
}

export interface IConsultarEstatisticaRequest {
	registroInicial?: string;
	limite?: string;
	dataInicial: string;
	dataFinal: string;
	idOrgao?: number | string;
	idLocalidade?: string | null;
	documento?: string | null;
}
