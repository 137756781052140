import produce from 'immer';
import { toSelect } from 'utils/genericFunctions';
import {
	IBeneficiario,
	IObjectExcluir,
	Types,
	IBeneficiariosState,
} from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: IBeneficiariosState = {
	statusTransacaoBeneficiarios: '',
	beneficiariosData: [],
	selectMotivosCorte: [],
	cotasEntidadesData: {
		programa_crianca: {
			cota: 0,
			cadastradas: 0,
			vagas: 0,
		},
		programa_idoso: {
			cota: 0,
			cadastradas: 0,
			vagas: 0,
		},
	},
	dadosFamilia: {
		cod_pessoa_cadunico_resp: '',
		responsavel: '',
		nis_responsavel: '',
		data_nasc_responsavel: '',
		cod_pessoa_cadunico_cri: '',
		crianca: '',
		data_nasc_crianca: '',
		nis_crianca: '',
		cod_familia_cadunico: '',
		renda_media_familia: '',
		cod_pessoa_cadunico_idoso: '',
		data_nasc_idoso: '',
		idoso: '',
		nis_idoso: '',
	},
};

export default function vivaLeiteBeneficiarios(
	state = INITIAL_STATE,
	action: ReducerAction,
): IBeneficiariosState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_BENEFICIARIOS: {
				break;
			}
			case Types.CONSULTA_BENEFICIARIOS_SUCCESS: {
				const beneficiarios = action.payload.data.beneficiarios
					? action.payload.data.beneficiarios
					: [];
				draft.beneficiariosData = beneficiarios;
				break;
			}
			case Types.CONSULTA_BENEFICIARIOS_FAILURE: {
				break;
			}

			case Types.CONSULTA_MOTIVOS_CORTE: {
				break;
			}
			case Types.CONSULTA_MOTIVOS_CORTE_SUCCESS: {
				draft.selectMotivosCorte = toSelect(
					action.payload.data.motivos,
					'descricao',
					'codigo_motivo',
				);
				break;
			}
			case Types.CONSULTA_MOTIVOS_CORTE_FAILURE: {
				break;
			}

			case Types.REMOVE_BENEFICIARIOS: {
				break;
			}
			case Types.REMOVE_BENEFICIARIOS_SUCCESS: {
				const updatedParametros: IBeneficiario[] = [...draft.beneficiariosData];

				action.payload.request.exclusoes.forEach((item: IObjectExcluir) => {
					const repeatIndex = draft.beneficiariosData.findIndex(newItem => {
						return item?.cod_pan_beneficio === newItem?.cod_pan_beneficio;
					});

					if (repeatIndex > -1) {
						delete updatedParametros[repeatIndex];
					}
				});
				draft.beneficiariosData = updatedParametros;
				draft.statusTransacaoBeneficiarios =
					action.payload.response.data.status_transacao;
				break;
			}
			case Types.REMOVE_BENEFICIARIOS_FAILURE: {
				break;
			}

			case Types.CONSULTA_COTAS_ENTIDADE: {
				break;
			}
			case Types.CONSULTA_COTAS_ENTIDADE_SUCCESS: {
				draft.cotasEntidadesData = action.payload.data.cotas_entidade;
				break;
			}
			case Types.CONSULTA_COTAS_ENTIDADE_FAILURE: {
				break;
			}

			case Types.CONSULTA_BENEFICIARIOS_INCLUSAO: {
				break;
			}
			case Types.CONSULTA_BENEFICIARIOS_INCLUSAO_SUCCESS: {
				if (
					action.payload.data.codigo === 204 ||
					(action.payload.data.alerta && action.payload.data.alerta !== '')
				) {
					draft.dadosFamilia = INITIAL_STATE.dadosFamilia;
				} else {
					draft.dadosFamilia = action.payload.data.dados_familia;
				}
				break;
			}
			case Types.CONSULTA_BENEFICIARIOS_INCLUSAO_FAILURE: {
				break;
			}

			case Types.CADASTRA_BENEFICIARIO: {
				break;
			}
			case Types.CADASTRA_BENEFICIARIO_SUCCESS: {
				draft.statusTransacaoBeneficiarios =
					action.payload.data.status_transacao;
				break;
			}
			case Types.CADASTRA_BENEFICIARIO_FAILURE: {
				break;
			}

			case Types.BENEFICIARIO_CLEAR: {
				draft.beneficiariosData = INITIAL_STATE.beneficiariosData;
				break;
			}

			case Types.FAMILIAR_CLEAR: {
				draft.dadosFamilia = INITIAL_STATE.dadosFamilia;
				break;
			}

			case Types.NOTIFICATION_BENEFICIARIOS_VIVA_LEITE_CLEAR: {
				draft.statusTransacaoBeneficiarios = '';
				break;
			}

			default:
		}
	});
}
