export enum Types {
	CONSULTA_PROCESSO_ABERTO_REQUEST = '@cnh/CONSULTA_PROCESSO_ABERTO_REQUEST',
	CONSULTA_PROCESSO_ABERTO_SUCCESS = '@cnh/CONSULTA_PROCESSO_ABERTO_SUCCESS',
	CONSULTA_PROCESSO_ABERTO_FAILURE = '@cnh/CONSULTA_PROCESSO_ABERTO_FAILURE',
	CONSULTA_PROCESSO_ABERTO_CLEAR = '@cnh/CONSULTA_PROCESSO_ABERTO_CLEAR',
}

export interface ConsultaProcessoAberto {
	status: number;
	data: ConsultaProcessoAbertoData | null;
}

export interface ConsultaProcessoAbertoData {
	categoriaPretendida: string;
	ciretranCandidato: number;
	codigoInterno: string;
	cpfCidadao: string;
	crm: number;
	crp: number;
	dataExame: string;
	dataExamePsicologo: string;
	dataGeracaoRenach: string;
	dataRealizacaoExame: string;
	dataRealizacaoExamePsicologo: string;
	enderecoMedico: string;
	enderecoPsicologo: string;
	horarioExame: string;
	horarioExamePsicologo: string;
	horarioRealizacaoExame: string;
	horarioRealizacaoExamePsicologo: string;
	ident: string;
	message: string;
	nomeCidadao: string;
	nomeMedico: string;
	nomePsicologo: string;
	nomeSocialCidadao: string;
	paisAfetivos1: string;
	paisAfetivos2: string;
	pretendeAtividadeRemunerada: string;
	renach: string;
	restricoesExameMedico: string;
	resultadoExameMedico: string;
	resultadoExamePsicologico: string;
	statusExameMedico: string;
	statusExamePsicologico: string;
	telefones: string;
	telefonesPsicologo: string;
	tipoProcesso: {
		codigo: string;
		descricao: string;
	};
}

export interface ConsultaProcessoAbertoRequest {
	cpf: string;
	ident?: string | number;
}
