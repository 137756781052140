import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AtualizarMensagemRequest } from './types';

// ACTIONS
import { atualizarMensagemSuccess, atualizarMensagemFailure } from './actions';

function* atualizarMensagem(request: ReducerAction) {
	const { payload }: { payload: AtualizarMensagemRequest } = request;

	const body = { ...payload };

	delete body.id;
	delete body.search;

	const response: ApiResponse = yield call(
		putApi,
		PATH_SGU,
		`mensagens/${payload.id}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarMensagemSuccess(response));
	} else {
		yield put(atualizarMensagemFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_MENSAGEM_REQUEST, atualizarMensagem),
]);
