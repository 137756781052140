// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';
import { DadosBiometricos } from '../consultarDigitais/types';

export function confrontarDigitaisCnhRequest(
	cpf: string,
	dedoSorteio: DadosBiometricos,
	digital: string,
): ReducerAction {
	return {
		type: Types.CONFRONTAR_DIGITAIS_CNH_REQUEST,
		payload: {
			cpf,
			dedoSorteio,
			digital,
		},
	};
}
export function confrontarDigitaisCnhSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONFRONTAR_DIGITAIS_CNH_SUCCESS,
		payload,
	};
}
export function confrontarDigitaisCnhFailure(): ReducerAction {
	return {
		type: Types.CONFRONTAR_DIGITAIS_CNH_FAILURE,
		payload: null,
	};
}
export function confrontarDigitaisCnhClear(): ReducerAction {
	return {
		type: Types.CONFRONTAR_DIGITAIS_CNH_CLEAR,
		payload: null,
	};
}
