import React, { useEffect } from 'react';

import { Row, Col, Divider } from 'antd';

// COMPONENTS
import Section from 'components/Common/Section';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { domainServiceNaturalidadesRequest } from 'store/modules/api/sguService/domainService/naturalidade/actions';
import EnumCNH from 'store/modules/api/detranCnh/enum';

// UTILS
import { formatDate } from 'utils/genericFunctions';

// FORM
import { IDadosCidadao } from './form';

interface IProps {
	dadosCidadao: IDadosCidadao;
	limiteExame?: string;
}

const DadosCidadao: React.FC<IProps> = ({
	dadosCidadao,
	limiteExame = undefined,
}) => {
	const dispatch = useDispatch();

	const listaPaises = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.consulta.consultaPaises.data,
	);

	const { consultaNaturalidades } = useSelector(
		(state: ApplicationState) => state.api.sguService.domainService,
	);

	useEffect(() => {
		if (
			dadosCidadao.nacionalidade.toString() === '1' &&
			dadosCidadao.naturalidade
		) {
			dispatch(
				domainServiceNaturalidadesRequest({
					idIbge: String(dadosCidadao.naturalidade),
				}),
			);
		}
	}, [dadosCidadao.nacionalidade, dadosCidadao.naturalidade, dispatch]);

	return (
		<>
			<Section bgColor="cinza-claro">
				<Row align="middle">
					<Col>
						<h1 style={{ opacity: 0.7 }}>Dados do Cidadão:&nbsp;</h1>
					</Col>
					<Col>
						<h3 style={{ opacity: 0.7, fontWeight: 'normal' }}>
							{dadosCidadao.nome}
						</h3>
					</Col>
				</Row>
				<Divider
					style={{ marginTop: 0, backgroundColor: '#000', opacity: 0.7 }}
				/>

				<Row>
					<Col
						style={{
							paddingTop: '0px',
							paddingBottom: '0px',
						}}
					>
						<p>
							<strong style={{ opacity: 0.7 }}>Informações Pessoais</strong>
						</p>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col
						span={8}
						style={{
							paddingTop: '0px',
							paddingBottom: '0px',
						}}
					>
						<Row>
							<Col
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									<strong>CPF: </strong>
								</p>
							</Col>
							<Col
								offset={1}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									{dadosCidadao.cpf.substr(0, 3)}.
									{dadosCidadao.cpf.substr(3, 3)}.
									{dadosCidadao.cpf.substr(6, 3)}-
									{dadosCidadao.cpf.substr(9, 2)}
								</p>
							</Col>
						</Row>

						<Row>
							<Col
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									<strong>Data de nasc: </strong>
								</p>
							</Col>
							<Col
								offset={1}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>{formatDate(dadosCidadao.dataNascimento)}</p>
							</Col>
						</Row>
						{/* <Row>
							<Col
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									<strong>Nome do pai: </strong>
								</p>
							</Col>
							<Col
								offset={1}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>{dadosCidadao.nomePai}</p>
							</Col>
						</Row> */}

						{/* <Row>
							<Col
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									<strong>Nome da mãe: </strong>
								</p>
							</Col>
							<Col
								offset={1}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>{dadosCidadao.nomeMae}</p>
							</Col>
						</Row> */}

						<Row>
							<Col
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									<strong>Naturalidade: </strong>
								</p>
							</Col>
							<Col
								offset={1}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									{dadosCidadao.nacionalidade.toString() === '1'
										? (consultaNaturalidades.data &&
												consultaNaturalidades.data[0].municipioIBGE
													.descricao) ||
										  ''
										: listaPaises.filter(
												itemNaturalidade =>
													itemNaturalidade.value === dadosCidadao.naturalidade,
										  )[0]?.label || ''}
								</p>
							</Col>
						</Row>
					</Col>

					<Col
						span={8}
						style={{
							paddingTop: '0px',
							paddingBottom: '0px',
						}}
					>
						<Row>
							<Col
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									<strong>RG: </strong>
								</p>
							</Col>
							<Col
								offset={1}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>{dadosCidadao.numeroRg?.toUpperCase()}</p>
							</Col>
						</Row>
					</Col>

					<Col
						span={8}
						style={{
							paddingTop: '0px',
							paddingBottom: '0px',
						}}
					>
						<Row>
							<Col
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									<strong>RNE: </strong>
								</p>
							</Col>
							<Col
								offset={1}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>{dadosCidadao.rne}</p>
							</Col>
							<Col
								offset={1}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p />
							</Col>
						</Row>

						<Row>
							<Col
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									<strong>Sexo: </strong>
								</p>
							</Col>
							<Col
								offset={1}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									{EnumCNH.sexoEnum.filter(
										itemSexo => itemSexo.value === dadosCidadao.sexo,
									)[0]?.label || ''}
								</p>
							</Col>
						</Row>

						<Row>
							<Col
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									<strong>Nacionalidade: </strong>
								</p>
							</Col>
							<Col
								offset={1}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
								}}
							>
								<p>
									{EnumCNH.nacionalidadeEnumPrimeiraHabilitacao.filter(
										itemNacionalidade =>
											itemNacionalidade.value === dadosCidadao.nacionalidade,
									)[0]?.label || ''}
								</p>
							</Col>
						</Row>
					</Col>
				</Row>

				{limiteExame && (
					<Row>
						<Col
							style={{
								paddingTop: '0px',
								paddingBottom: '0px',
							}}
						>
							<p>
								<strong>Data limite para a utilização do toxicológico: </strong>
							</p>
						</Col>
						<Col
							style={{
								paddingTop: '0px',
								paddingBottom: '0px',
							}}
						>
							<p>&nbsp;{`${limiteExame}`}</p>
						</Col>
					</Row>
				)}

				<Row gutter={[0, 20]}>
					<Col span={24} />
				</Row>
			</Section>
		</>
	);
};

export default DadosCidadao;
