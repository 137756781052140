import React, { useState, useEffect, useCallback } from 'react';

// REDUX
import { useDispatch } from 'react-redux';

// STYLED
import { Container, Content, Item, CloseButton } from './styled';

// FORM
import { clearNotifications } from '../../../../store/modules/app/notifications/actions';

interface ErrorInfo {
	errors?: any;
	moveToTopWhenDisplayed?: boolean;
	onClose?: () => void;
}

const Error: React.FC<ErrorInfo> = ({
	errors,
	moveToTopWhenDisplayed,
	onClose,
}) => {
	const dispatch = useDispatch();

	const [validation, setValidation] = useState(errors);

	useEffect(() => {
		setValidation(errors);
		if (moveToTopWhenDisplayed) {
			window.scrollTo(0, 0);
		}
	}, [errors, moveToTopWhenDisplayed]);

	const clearErrors = useCallback(() => {
		setValidation([]);

		if (onClose) onClose();

		dispatch(clearNotifications());
	}, [dispatch, onClose]);

	return (
		<Container isErrored={validation.length > 0}>
			<Content>
				{Array.isArray(errors) ? (
					(errors.length === 1 &&
						errors.map((item: any, i: number) => {
							return <Item key={String(i)}>{item}</Item>;
						})) ||
					errors.map((item: any, i: number) => {
						return <Item key={String(i)}>- {item}</Item>;
					})
				) : (
					<Item key="error-0">{errors}</Item>
				)}

				<CloseButton type="button" onClick={clearErrors}>
					<span>&times;</span>
				</CloseButton>
			</Content>
		</Container>
	);
};

export default Error;
