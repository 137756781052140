import React, { useState, useEffect, useCallback } from 'react';

import { Row, Col, List, Alert } from 'antd';

// FORM
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';
import {
	ROUTE_IIRGD_RESULTADO_PESQUISA,
	ROUTE_IIRGD_CADASTRO,
	ROUTE_IIRGD_EDICAO,
} from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import {
	cadastroRgConfigNovo,
	cadastroRgPrimeiraVia,
} from 'store/modules/api/iirgd/cadastroRg/actions';
import {
	consultaPesquisaCriminalClear,
	consultaPesquisaCriminalTemporalidade,
	consultaPesquisaCriminalRestricao,
} from 'store/modules/api/iirgd/consultaPesquisaCriminal/actions';

import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Radio from 'components/Common/Form/Radio';
import Badge from 'components/Common/Badge';

// UTILS
import {
	formatDate,
	limparMascara,
	patternFormat,
} from 'utils/genericFunctions';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

// TYPES
import { SCRIPT_POSSUI_RG_EM_SAO_PAULO } from 'store/modules/api/iirgd/scriptPreAtendimento/types';

// STYLED
import { Title, RedIcon, YellowIcon } from './styled';
import { CentralLiberacao } from './CentralLiberacao';

interface Restricao {
	restricao: string;
}

const RestricoesCriminais: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [centralLiberacaoMsg, setCentralLiberacaoMsg] =
		useState<boolean>(false);

	const [hasClicked, setHasClicked] = useState<boolean>(false);

	const {
		consultaPesquisaCirg,
		consultaPesquisaCriminal,
		cadastroRg,
		scriptPreAtendimento,
	} = useSelector((state: ApplicationState) => state.api.iirgd);

	const { selecionado } = useSelector(
		(state: ApplicationState) => state.api.iirgd.consultaPesquisaCidadao,
	);

	const locale = {
		emptyText: <strong>Nenhuma restrição encontrada</strong>,
	};

	useEffect(() => {
		// if (!hasClicked) {
		// 	dispatch(consultaPesquisaCirgClear());
		// 	return;
		// }

		if (hasClicked && consultaPesquisaCirg.data) {
			history.push(ROUTE_IIRGD_EDICAO);
		}
	}, [hasClicked, consultaPesquisaCirg, dispatch, history]);

	const handleEdit = useCallback(
		(restricaoCriminal: boolean) => {
			if (selecionado) {
				const { numeroRg } = selecionado;
				if (restricaoCriminal && consultaPesquisaCriminal?.data) {
					const cidadaoComRestricao = consultaPesquisaCriminal.data.filter(
						restricao =>
							limparMascara(numeroRg) === limparMascara(restricao.numeroRg),
					);

					if (cidadaoComRestricao.length > 0) {
						const { afis } = cidadaoComRestricao[0];

						if (afis && afis === '*') {
							dispatch(consultaPesquisaCriminalTemporalidade(true));
						} else {
							dispatch(consultaPesquisaCriminalTemporalidade(false));
						}
					}
				}

				dispatch(consultaPesquisaCriminalRestricao(restricaoCriminal));
				setHasClicked(true);
				if (cadastroRg.config.acervo) {
					history.push(ROUTE_IIRGD_CADASTRO);
				}
			}
		},
		[
			selecionado,
			consultaPesquisaCriminal,
			dispatch,
			cadastroRg.config.acervo,
			history,
		],
	);

	const handleButtonVoltar = useCallback(() => {
		dispatch(consultaPesquisaCriminalClear());

		history.push(ROUTE_IIRGD_RESULTADO_PESQUISA);
	}, [dispatch, history]);

	const schema = Yup.object<Restricao>().shape({
		restricao: Yup.string().required(`Campo Obrigatório: Restrição.`),
	});

	const getRg = () => {
		if (
			scriptPreAtendimento.numeroRg &&
			scriptPreAtendimento.rgOuAcervo &&
			scriptPreAtendimento.ondeMorava === SCRIPT_POSSUI_RG_EM_SAO_PAULO
		) {
			return scriptPreAtendimento.numeroRg;
		}
		if (selecionado) {
			if (!selecionado?.numeroRg) {
				return '__.___.___-__';
			}
			if (
				selecionado?.numeroRg &&
				limparMascara(selecionado?.numeroRg).length === 10
			) {
				return patternFormat(selecionado.numeroRg, '##.###.###-##');
			}
			return patternFormat(selecionado.numeroRg, '##.###.###-#');
		}
		return '__.___.___-_';
	};

	return (
		<>
			{selecionado && (
				<>
					<Row>
						<Col span={24}>
							<Section title="Resultado da Pesquisa de Restrições">
								{centralLiberacaoMsg && (
									<Row gutter={[0, 20]}>
										<Col span={24}>
											<Alert
												message=""
												description="Dados enviados para análise com sucesso!"
												type="success"
												closable
												showIcon
												onClose={() => setCentralLiberacaoMsg(false)}
											/>
										</Col>
									</Row>
								)}

								<Section bgColor="cinza-claro">
									<Row gutter={[0, 10]}>
										<Col>
											<h5>Termos pesquisados:</h5>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={3}>
											<p>
												<strong>RGE/CIN:</strong>
											</p>
										</Col>
										<Col span={13}>
											<p>{getRg()}</p>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={3}>
											<p>
												<strong>Nome:</strong>
											</p>
										</Col>
										<Col span={13}>
											<p>{selecionado.nome.toLocaleUpperCase('pt-BR')}</p>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={3}>
											<p>
												<strong>Nome do Pai:</strong>
											</p>
										</Col>
										<Col span={13}>
											<p>{selecionado.nomePai.toLocaleUpperCase('pt-BR')}</p>
										</Col>

										<Col span={4}>
											<p>
												<strong>Data de Nascimento:</strong>
											</p>
										</Col>
										<Col span={3}>
											<p>{formatDate(selecionado.dataNascimento)}</p>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={3}>
											<p>
												<strong>Nome da Mãe:</strong>
											</p>
										</Col>
										<Col span={13}>
											<p>{selecionado.nomeMae.toLocaleUpperCase('pt-BR')}</p>
										</Col>
									</Row>

									{/* <Row gutter={[0, 10]}>
										<Col span={3}>
											<p>
												<strong>Naturalidade:</strong>
											</p>
										</Col>
										<Col span={13}>
											<p>
												{`${selecionado.codigoNaturalidade} | ${selecionado.municipioNaturalidade}`}
											</p>
										</Col>
									</Row> */}

									{consultaPesquisaCriminal.data.length === 0 &&
										selecionado.numeroRg && (
											<Row justify="end">
												<Col>
													<ButtonImage
														src={
															cadastroRg.config.acervo
																? 'btn-rgecin-acervo'
																: 'selecionar'
														}
														onClick={() => {
															handleEdit(false);
														}}
													/>
												</Col>
											</Row>
										)}

									{consultaPesquisaCriminal.data.length === 0 &&
										!selecionado.numeroRg && (
											<Row justify="end">
												<Col>
													<ButtonImage
														src="btn-rge-cin"
														onClick={() => {
															dispatch(cadastroRgConfigNovo());
															dispatch(cadastroRgPrimeiraVia());
															history.push(ROUTE_IIRGD_CADASTRO);
														}}
													/>
												</Col>
											</Row>
										)}

									{consultaPesquisaCriminal.data.length > 0 && (
										<Formik
											validateOnChange={false}
											validateOnBlur={false}
											initialValues={{ restricao: null }}
											validate={values => getValidationsErrors(values, schema)}
											onSubmit={() => {}}
										>
											{formik => (
												<Form>
													<Row>
														<Col span={24}>
															{formik.values.restricao !== null && (
																<>
																	{selecionado && !selecionado.numeroRg ? (
																		<Row justify="end">
																			<Col>
																				<ButtonImage
																					src="btn-rge-cin"
																					onClick={() => {
																						dispatch(cadastroRgConfigNovo());
																						dispatch(cadastroRgPrimeiraVia());
																						dispatch(
																							consultaPesquisaCriminalRestricao(
																								!!formik.values.restricao,
																							),
																						);
																						history.push(ROUTE_IIRGD_CADASTRO);
																					}}
																				/>
																			</Col>
																		</Row>
																	) : (
																		<Row justify="end">
																			<Col>
																				<ButtonImage
																					src="selecionar"
																					onClick={() => {
																						handleEdit(
																							!!formik.values.restricao,
																						);
																					}}
																				/>
																			</Col>
																		</Row>
																	)}
																</>
															)}

															<Row justify="center">
																<Col span={12}>
																	<Section bgColor="cinza-claro">
																		<Row gutter={[0, 10]} justify="center">
																			<Col>
																				<Title>
																					Encontrou <YellowIcon /> ou
																					<RedIcon /> para esse cidadão?
																				</Title>
																			</Col>
																		</Row>

																		<Row justify="center">
																			<Col>
																				<Field
																					as={Radio}
																					titleSize={250}
																					name="restricao"
																					options={data.simNaoBoolean}
																					defaultValue={formik.values.restricao}
																					error={hasError(formik, 'restricao')}
																				/>
																			</Col>
																		</Row>
																	</Section>
																</Col>
															</Row>
														</Col>
													</Row>
												</Form>
											)}
										</Formik>
									)}
								</Section>

								<Row gutter={[0, 20]}>
									<Col span={24} />
								</Row>

								<Row>
									<Col>
										<h5 style={{ fontWeight: 'bold' }}>Lista de Restrições:</h5>
									</Col>
								</Row>

								<List
									itemLayout="horizontal"
									size="large"
									dataSource={consultaPesquisaCriminal.data}
									split={false}
									locale={locale}
									pagination={{
										showSizeChanger: true,
										pageSize: 10,
										pageSizeOptions: ['10', '20'],
									}}
									renderItem={item => (
										<>
											<Section
												key={item.numeroRg}
												style={{ marginTop: '10px' }}
											>
												<Row gutter={[0, 10]}>
													<Col span={15}>
														{item.exrg && (
															<Badge
																bgColor={
																	item.statusCriminal === 'V'
																		? '#e82323'
																		: '#ffe000'
																}
																color={
																	item.statusCriminal === 'V'
																		? '#ffffff'
																		: '#000000'
																}
																text="EXRG"
															/>
														)}

														{item.pesq && (
															<Badge
																bgColor={
																	item.statusCriminal === 'V'
																		? '#e82323'
																		: '#ffe000'
																}
																color={
																	item.statusCriminal === 'V'
																		? '#ffffff'
																		: '#000000'
																}
																text="PESQ"
															/>
														)}

														{item.tlod && (
															<Badge
																bgColor={
																	item.statusCriminal === 'V'
																		? '#e82323'
																		: '#ffe000'
																}
																color={
																	item.statusCriminal === 'V'
																		? '#ffffff'
																		: '#000000'
																}
																text="TLOD"
															/>
														)}
													</Col>

													<Col>
														<p
															style={{ alignItems: 'center', display: 'flex' }}
														>
															<strong style={{ marginRight: '5px' }}>
																Restrição:
															</strong>

															<CentralLiberacao
																data={item}
																onSuccess={() => {
																	setCentralLiberacaoMsg(true);
																}}
															/>
														</p>
													</Col>
												</Row>

												<Row gutter={[0, 10]}>
													<Col>
														<h5>{item.nome}</h5>
													</Col>
												</Row>

												<Row>
													<Col span={15}>
														<Row>
															<Col>
																<strong>RG: </strong>
																{patternFormat(item.numeroRg, '##.###.###-#')}
															</Col>
														</Row>

														<Row>
															<Col>
																<strong>Status Emissão: </strong>
																{item.statusEmissao}
															</Col>
														</Row>

														<Row>
															<Col>
																<strong>Nome do Pai: </strong> {item.nomePai}
															</Col>
														</Row>

														<Row>
															<Col>
																<strong>Nome da Mãe: </strong> {item.nomeMae}
															</Col>
														</Row>
													</Col>

													<Col span={8}>
														<Row>
															<Col>
																<strong>Sexo: </strong>
																{item.sexo === 'M' ? 'MASCULINO' : 'FEMININO'}
															</Col>
														</Row>

														<Row>
															<Col>
																<strong>Obs: </strong> {item.status}
															</Col>
														</Row>

														<Row>
															<Col>
																<strong>Data de Nascimento: </strong>
																{formatDate(item.dataNascimento)}
															</Col>
														</Row>

														<Row>
															<Col>
																<strong>Naturalidade: </strong>
																{item.descricaoNaturalidade}
															</Col>
														</Row>
													</Col>
												</Row>
											</Section>
										</>
									)}
								/>
							</Section>

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default RestricoesCriminais;
