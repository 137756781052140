import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
	.react-pdf-modal {
		.ant-modal {
			max-width: unset !important;
			padding-bottom: 0;
			top: 0;
			width: 100% !important;

			.ant-modal-header {
				height: 55px;

				.ant-modal-title {
					font-weight: 700;
    			text-align: center;
				}
			}

			.ant-modal-body {
				height: calc(100vh - 55px);
				overflow: hidden;
				padding: 0px;
			}
		}
	}

	#viewer {
		#title {
			display: none;
		}
	}
`;
