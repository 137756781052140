import { all } from 'redux-saga/effects';

import preCadastroCidadao from './preCadastroCidadao/saga';
import domainService from './domainService/combineSaga';
import estatisticasIncluir from './estatisticasIncluir/saga';
import alteraSenhaUsuario from './alteraSenhaUsuario/saga';
import salvaSenhaUsuario from './salvaSenhaUsuario/saga';

export default all([
	preCadastroCidadao,
	domainService,
	estatisticasIncluir,
	alteraSenhaUsuario,
	salvaSenhaUsuario,
]);
