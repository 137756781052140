import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_BOLSA_POVO } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	consultarTiposLogradourosSuccess,
	consultarTiposLogradourosFailure,
} from './action';

function* consultarTiposLogradourosRequest() {
	const response: ApiResponse = yield call(
		postApi,
		PATH_BOLSA_POVO,
		`enderecos/${CHANNEL}/tipos-logradouros`,
		{},
	);

	if (response.status === 200) {
		yield put(consultarTiposLogradourosSuccess(response));
	} else if (response.error) {
		yield put(consultarTiposLogradourosFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_TIPOS_LOGRADOUROS_REQUEST,
		consultarTiposLogradourosRequest,
	),
]);
