import produce from 'immer';
// TYPES
import { Types, PesquisarContato } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: PesquisarContato = {
	status: 0,
	data: null,
};

export default function atualizarContato(
	state = INITIAL_STATE,
	action: ReducerAction,
): PesquisarContato {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PESQUISAR_CONTATO: {
				draft.status = 100;
				break;
			}

			case Types.PESQUISAR_CONTATO_SUCCESS: {
				draft.status = action.payload.response.status;
				draft.data = action.payload.response.data;
				break;
			}

			case Types.PESQUISAR_CONTATO_FAILURE: {
				draft.status = action.payload.data.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.PESQUISAR_CONTATO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
