export enum Types {
	IMPRESSAO_LICENCIAMENTO_VEICULOS_REQUEST = '@DETRAN-CRV/IMPRESSAO_LICENCIAMENTO_VEICULOS_REQUEST',
	IMPRESSAO_LICENCIAMENTO_VEICULOS_SUCCESS = '@DETRAN-CRV/IMPRESSAO_LICENCIAMENTO_VEICULOS_SUCCESS',
	IMPRESSAO_LICENCIAMENTO_VEICULOS_FAILURE = '@DETRAN-CRV/IMPRESSAO_LICENCIAMENTO_VEICULOS_FAILURE',
	IMPRESSAO_LICENCIAMENTO_VEICULOS_CLEAR = '@DETRAN-CRV/IMPRESSAO_LICENCIAMENTO_VEICULOS_CLEAR',
}

export interface impressaoLicenciamentoVeiculos {
	status: number;
	data: ficha | null;
}

interface ficha {
	ficha: {
		anoFicha: 0;
		chassi: 'string';
		numeroFicha: 0;
		placa: 'string';
		fichaCadastral: 'string';
	};
}
