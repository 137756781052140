import React, { useEffect, useState } from 'react';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import { Modal } from 'antd';
import ButtonImage from 'components/Common/ButtonImage';
import { disponibilzarDareRequest } from 'store/modules/api/potencial/disponibilzarDare/actions';

import { protocoloFicClear } from 'store/modules/api/iirgd/protocoloFic/actions';

export const PagamentoPìnPad: React.FC = () => {
	const dispatch = useDispatch();

	const { disponibilzarDare } = useSelector(
		(state: ApplicationState) => state.api.potencial,
	);

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	useEffect(() => {
		if (disponibilzarDare.status === 200 || disponibilzarDare.status === 201) {
			setIsDisabled(true);
			dispatch(protocoloFicClear());
		}
	}, [dispatch, disponibilzarDare]);

	const handleSubmit = () => {
		dispatch(
			disponibilzarDareRequest({
				numeroDare: cadastroRg?.dare?.numeroControleGuiaMae ?? 0,
				rgAtendimento: cadastroRg.form.principal?.numeroRg
					? cadastroRg.form.principal?.numeroRg
					: '',
			}),
		);
	};

	const handleClick = () => {
		Modal.info({
			title: 'Confirma o Envio dos dados?',
			onOk() {
				handleSubmit();
			},
		});
	};

	return (
		<ButtonImage
			type="button"
			src="btn-pag-pinpad"
			onClick={handleClick}
			disabled={isDisabled}
		/>
	);
};
