import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ReducerActionRotaSP } from 'store/modules/types';
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_EDUCACAO_SEDUC_DOMINIOS_API } from 'services/rotasp/path';
import { ComboMunicipiosRequestData, Types } from './types';
import { comboMunicipiosSuccess, comboMunicipiosFailure } from './actions';

function* ComboMunicipiosRequest(request: ReducerActionRotaSP) {
	const {
		payload,
		token,
	}: { payload: ComboMunicipiosRequestData; token?: string } = request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_EDUCACAO_SEDUC_DOMINIOS_API,
		'municipios',
		payload,
	);
	if (response.status === 200) {
		yield put(comboMunicipiosSuccess(response));
	} else {
		yield put(comboMunicipiosFailure(response));
	}
}

export default all([
	takeLatest(Types.COMBO_MUNICIPIOS_REQUEST, ComboMunicipiosRequest),
]);
