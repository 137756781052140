export enum Types {
	CONSULTA_ERRO = '@contabilizacao/CONSULTA_ERRO',
	CONSULTA_ERRO_SUCCESS = '@contabilizacao/CONSULTA_ERRO_SUCCESS',
	CONSULTA_ERRO_FAILURE = '@contabilizacao/CONSULTA_ERRO_FAILURE',
}

export interface ConsultaErroState {
	consultaErroData: IConsultaErroParsedData[];
	totalRegistrosErro: number;
}

export interface ICanal {
	id: string;
	descricao: string;
}

interface ICanalJson {
	id: string;
	desc: string;
}
export interface IConsultaErroData {
	codigo: number;
	causa: string;
	json: string;
	mensagem_erro: string;
}

export interface IConsultaErroParsedData {
	codigo: number;
	causa: string;
	json: IJson;
	mensagem_erro: string;
}

export interface IJson {
	canal: ICanalJson;
	id_atendimento: string;
	id_cidadao: string;
	dados: {
		data_hora: string;
	};
}

export interface IConsultaErroRequest {
	codigo?: string | number;
	json?: string;
	causa?: string;
	mensagem_erro?: string;
	pagina_inicio: number;
	limite: number;
}
