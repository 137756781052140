import React, { Suspense, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import PDFViewer from 'components/Common/PDFViewer';
import { impressaoComunicacaoVendaClear } from 'store/modules/api/detranCrv/Processos/ImpressaoComunicacaoVenda/actions';

const ImpressaoComunicacaoVendaResultado: React.FC = () => {
	const dispatch = useDispatch();
	const { impressaoComunicacaoVenda } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.processos,
	);

	const [showPrint, setShowPrint] = useState<boolean>(false);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Impressão RENAVAM">
				<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
					<Col span={14}>
						<Input
							titleSize="lg"
							readOnly
							maxLength={7}
							name="numeroFicha"
							title="Número da Ficha"
							value={impressaoComunicacaoVenda.data?.ficha.numeroFicha}
						/>
					</Col>
					<Col span={10} />
					<Col span={14}>
						<Input
							titleSize="lg"
							readOnly
							name="anoFicha"
							title="Ano da Ficha Cadastral"
							value={impressaoComunicacaoVenda.data?.ficha.anoFicha}
						/>
					</Col>
					<Col span={8} />
				</Row>

				<Row justify="end">
					<Col>
						<ButtonImage src="imprimir" onClick={() => setShowPrint(true)} />
					</Col>
				</Row>
			</Section>
			<ButtonVoltar
				onClick={() => dispatch(impressaoComunicacaoVendaClear())}
				route="/detran-crv/impressao-comunicacao-venda/consulta"
			/>

			{showPrint && (
				<PDFViewer
					title="Impressão Comunicação de Venda"
					source={
						impressaoComunicacaoVenda.data?.ficha.fichaCadastral
							? impressaoComunicacaoVenda.data?.ficha.fichaCadastral
							: ''
					}
					onClose={() => setShowPrint(false)}
				/>
			)}
		</Suspense>
	);
};

export default ImpressaoComunicacaoVendaResultado;
