import React, { useCallback, useEffect, useState } from 'react';

// ROUTES
import { useHistory } from 'react-router-dom';
import {
	ROUTE_DETRAN_CNH_DEFINITIVA_AGENDAMENTO_PSICOLOGICO,
	ROUTE_DETRAN_CNH_DEFINITIVA_PESQUISA,
	ROUTE_DETRAN_CNH_DEFINITIVA_RENACH,
	ROUTE_DETRAN_CNH_SEGUNDA_VIA_PESQUISA,
} from 'pages/DetranCnh/routes/paths';

// ANTD
import { Row, Col } from 'antd';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// COMPONENTS COMMON
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import { AlertaCnhValidadePeriodoPermissionario } from 'pages/DetranCnh/components/common/AlertaCnhValidadePeriodoPermissionario';

// COMPONENTS
import LeituraDigitais from 'pages/DetranCnh/components/pages/LeituraDigitais';
import StatusCondutor from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { saveGerarRenachRequest } from 'store/modules/api/detranCnh/sockets/saveGerarRenach/actions';
import {
	consultaNomeSocialFiliacaoClear,
	consultaNomeSocialFiliacaoRequest,
} from 'store/modules/api/detranCnh/condutor/consultaNomeSocialFiliacao/actions';
import { DadosCidadaoRequest } from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/types';
import {
	cadastroEnderecosClear,
	cadastroEnderecosRequest,
} from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/actions';
import { CadastroEnderecosRequest } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/types';
import {
	dadosCidadaoClear,
	dadosCidadaoRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/actions';
import {
	consultarInaptoClear,
	consultarInaptoRequest,
} from 'store/modules/api/detranCnh/sockets/consultarInapto/actions';
import {
	consultaSegundaViaReabilitacaoClear,
	consultaSegundaViaReabilitacaoRequest,
} from 'store/modules/api/detranCnh/reabilitacao/consultaSegundaViaReabilitacao/actions';
import { consultaCidadesUFRequest } from 'store/modules/api/utils/consultaCidadesUF/actions';
import {
	consultarDigitaisClear,
	consultarDigitaisRequest,
} from 'store/modules/api/utils/consultarDigitais/actions';
import {
	consultaPesquisaNomeSocialFiliacaoClear,
	consultaPesquisaNomeSocialFiliacaoRequest,
} from 'store/modules/api/detranCnh/IIRGDDetran/consultaPesquisaNomeSocialFiliacao/actions';
import { GerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/types';
import { capturarBiometriaClear } from 'store/modules/api/biometria/capturarBiometria/actions';
import { confrontarDigitaisCnhClear } from 'store/modules/api/utils/confrontarDigitaisCnh/actions';
import {
	estatisticaIncluirClear,
	estatisticaIncluirRequest,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';
import {
	domainServiceNaturalidadesRequest,
	domainServiceNaturalidadesClear,
} from 'store/modules/api/sguService/domainService/naturalidade/actions';
import {
	gerarRenachIntegradoClear,
	gerarRenachIntegradoRequest,
} from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/actions';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCnh/consulta/categoria/actions';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import { dadosPreCadastroFormSaveRequest } from 'store/modules/api/detranCnh/utils/dadosPreCadastroFormSave/actions';
import { verificarVersaoServicoBiometriaClear } from 'store/modules/api/biometria/verificarVersaoServicoBiometria/actions';

// UTILS
import {
	dateMoreDays,
	limparMascara,
	verifyIsUndefinedReturnEmpty,
} from 'utils/genericFunctions';
import { hasUpdateInAttendencyModal } from 'pages/DetranCnh/utils/validations';
import {
	threatConsultarDigitaisRequest,
	treatValuesCadastroEnderecoRequest,
} from 'pages/DetranCnh/utils/threatServicesRequest';
import { treatRequestSguStatisticsGerarRenachCnhDefinitiva } from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';
import { getOriginOfSocialName } from 'pages/DetranCnh/utils/functions/socialName';
import { CNH_PROCESS_VALIDATION } from 'pages/DetranCnh/utils/constants/messages';
import { treatDataPreCadastroFormWithOrWithoutModalAttendency } from 'pages/DetranCnh/utils/functions/dataPreCadastroForm';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

// FORM
import {
	IConfirmacaoAtendimento,
	initialValues,
} from 'pages/DetranCnh/components/pages/ConfirmacaoAtendimento/form';
import { IFormStatusCondutor } from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor/form';
import {
	initalValuesHabilitacao,
	initialValuesPreCadastro,
	schema,
	treatDataValues,
	treatValues,
} from './form';

interface CustomizedLocationState {
	flagProcurador: string;
}

interface DiferencaDiasDatasCnh {
	primeiraHabilitacaoParaUltimaEmissao: number | null;
	diferencaDiasHojeParaDataPrimeiraHabiitacaoIncrementada: number | null;
}

const StatusCondutorCnhDefinitiva: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const historyState = history.location.state as CustomizedLocationState;

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { user, mainframe } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { consultarDigitais } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { gerarRenachIntegrado } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { consultaCnhDefinitiva } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cnhDefinitiva,
	);

	const { consultaNomeSocialFiliacao, cadastroEnderecos } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { dadosCidadao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.divisaoEquitativa,
	);

	const { consultaNaturalidades } = useSelector(
		(state: ApplicationState) => state.api.sguService.domainService,
	);

	const { consultaSegundaViaReabilitacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.reabilitacao,
	);

	const { consultaPesquisaNomeSocialFiliacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.IIRGDDetran,
	);

	const { dadosPreCadastroFormSave } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.utils,
	);

	// CONTABILIZAÇÃO
	const { sgu } = useSelector((state: ApplicationState) => state.api);

	const [modalAtendimento, setModalAtendimento] =
		useState<IConfirmacaoAtendimento>(initialValues);

	const [dadosPreCadastro, setDadosPreCadastro] = useState(
		initialValuesPreCadastro,
	);

	const [dadosHabilitacao, setDadosHabilitacao] = useState(
		initalValuesHabilitacao,
	);

	const { consultarInapto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const [requestData, setRequestData] = useState<null | {
		endereco: CadastroEnderecosRequest;
		cidadao: DadosCidadaoRequest;
	}>(null);

	const [preCadastro, setGerarRenachSegundaViaData] =
		useState<null | GerarRenachIntegradoRequest>(null);

	const [diferencaDiasDatasCnh, setDiferencaDiasDatasCnh] =
		useState<DiferencaDiasDatasCnh>({
			primeiraHabilitacaoParaUltimaEmissao: null,
			diferencaDiasHojeParaDataPrimeiraHabiitacaoIncrementada: null,
		});

	const [biometria, setBiometria] = useState<
		'SHOW' | 'SUCCESS' | 'ERROR' | null
	>(null);

	const handleButtonBack = useCallback(() => {
		if (biometria === 'SHOW') {
			dispatch(consultarDigitaisClear());
			dispatch(capturarBiometriaClear());
			dispatch(confrontarDigitaisCnhClear());
			dispatch(clearNotifications());
			dispatch(verificarVersaoServicoBiometriaClear());
			setBiometria(null);
		} else {
			dispatch(detranCnhClear());
			history.push(ROUTE_DETRAN_CNH_DEFINITIVA_PESQUISA);
		}
	}, [biometria, dispatch, history]);

	const saveDataPreCadastroForm = useCallback(
		(
			dataPreCadastro: GerarRenachForm,
			dataHabilitacao: IFormStatusCondutor,
			dataModalAttendency?: IConfirmacaoAtendimento,
		) => {
			// SALVA OS DADOS PREENCHIDOS NO FORM COM OU SEM O MODAL CONFIRMACAO DE ATENDIMENTO.
			dispatch(
				dadosPreCadastroFormSaveRequest({
					dataPreCadastro: treatDataPreCadastroFormWithOrWithoutModalAttendency(
						dataPreCadastro,
						dataModalAttendency,
					),
					dataPreCadastroHabilitacao: dataHabilitacao,
				}),
			);
		},
		[dispatch],
	);

	const handleClickButtonProsseguirSegundaVia = useCallback(() => {
		dispatch(detranCnhClear());
		history.push(ROUTE_DETRAN_CNH_SEGUNDA_VIA_PESQUISA);
	}, [dispatch, history]);

	const handleSubmit = useCallback(
		(values: GerarRenachForm) => {
			if (!values.codCiretranCondutor || !values.codCiretranHabilitacao) {
				addNotifications({
					errors: CNH_PROCESS_VALIDATION.CIRETRAN_INVALIDA,
				});
				return;
			}

			const sguData: SguData = {
				salvarAtendimento: sgu.atendimento.salvarAtendimento,
				login: sgu.loginUnico.login,
			};
			const evento = getEventoConsultar(sguData, values.cpf);

			const renach = treatValues(
				evento,
				values,
				user,
				historyState.flagProcurador === 'S' ||
					dadosPreCadastroFormSave.dataPreCadastro?.flagProcurador === true
					? 'S'
					: 'N',
			);

			const endereco = treatValuesCadastroEnderecoRequest(values);

			const cidadao: DadosCidadaoRequest = {
				cidadao: {
					autoriza_envio_email: values.autorizaSMS === 'S',
					autoriza_envio_sms: values.autorizaEmail === 'S',
					cpf: Number(values.cpf),
					celular: limparMascara(values.celular).substring(2, 11),
					ddd_celular: limparMascara(values.celular).substring(0, 2),
					ddd_telefone: limparMascara(values.telefone).substring(0, 2),
					telefone: limparMascara(values.telefone).substring(2, 11),
					email: values.email || '',
					necessidade_veiculo_adaptado: false,
					nome: values.nome,
					pretende_atividade_remunerada: values.flagAtividadeRemunerada === 'S',
				},
			};

			saveDataPreCadastroForm(values, dadosHabilitacao);
			setDadosPreCadastro(values);
			setGerarRenachSegundaViaData(renach);
			setRequestData({ endereco, cidadao });

			dispatch(saveGerarRenachRequest(renach));
			dispatch(gerarRenachIntegradoClear());

			if (historyState.flagProcurador === 'N') {
				setModalAtendimento({
					...modalAtendimento,
					isOpenModal: true,
				});
			} else {
				dispatch(cadastroEnderecosRequest(endereco));
			}
		},
		[
			sgu.atendimento,
			sgu.loginUnico,
			user,
			historyState,
			modalAtendimento,
			dispatch,
			dadosPreCadastroFormSave.dataPreCadastro,
			dadosHabilitacao,
			saveDataPreCadastroForm,
		],
	);

	// LEITURA DIGITAIS
	useEffect(() => {
		if (
			consultarDigitais?.data &&
			consultarDigitais?.status === 200 &&
			consultarDigitais?.data?.valida &&
			consultarDigitais?.data?.dadosBiometricos &&
			consultarDigitais?.data?.dadosBiometricos.length > 0
		) {
			setBiometria('SHOW');
		} else if (
			consultarDigitais?.status !== 0 &&
			consultarDigitais?.status !== 100 &&
			requestData !== null
		) {
			dispatch(cadastroEnderecosRequest(requestData.endereco));
		}
	}, [consultarDigitais, dispatch, requestData, setBiometria]);

	useEffect(() => {
		if (biometria === 'SUCCESS' && requestData !== null) {
			dispatch(cadastroEnderecosRequest(requestData.endereco));
		} else if (biometria === 'ERROR' && requestData !== null) {
			dispatch(cadastroEnderecosRequest(requestData.endereco));
		}
	}, [biometria, dispatch, requestData]);

	const handleAfterModal = useCallback(
		(dataModalAtendimento: IConfirmacaoAtendimento) => {
			if (requestData) {
				if (dadosPreCadastro) {
					saveDataPreCadastroForm(
						dadosPreCadastro,
						dadosHabilitacao,
						dataModalAtendimento,
					);
				}

				if (
					dadosPreCadastro &&
					!hasUpdateInAttendencyModal(dataModalAtendimento)
				) {
					const requestValues = threatConsultarDigitaisRequest(
						dadosAtendimento,
						dadosPreCadastro,
						user,
						'REUSO_CNH',
					);

					dispatch(consultarDigitaisRequest(requestValues));
				} else if (requestData !== null) {
					dispatch(cadastroEnderecosRequest(requestData.endereco));
				}

				setModalAtendimento(dataModalAtendimento);
			}
		},
		[
			requestData,
			dadosPreCadastro,
			dispatch,
			dadosHabilitacao,
			user,
			dadosAtendimento,
			saveDataPreCadastroForm,
		],
	);

	// GET COMBO DE NATURALIDADE DE ACORDO COM A UF DO IBGE
	useEffect(() => {
		if (
			consultaNaturalidades.status === 200 &&
			consultaNaturalidades.data &&
			consultaNaturalidades.data[0].municipioIBGE.uf
		) {
			dispatch(
				consultaCidadesUFRequest(
					consultaNaturalidades.data[0].municipioIBGE.uf,
				),
			);
		}
	}, [consultaNaturalidades.status, consultaNaturalidades.data, dispatch]);

	// GET CODIGO CIRETRAN
	const consultaCodCiretran = useCallback(async () => {
		const resDetranEndereco: ApiResponse = await getApi(
			PATH_DETRAN_CNH,
			`consulta/${CHANNEL}?cep=${consultaCnhDefinitiva.data?.endereco.cep}`,
		);

		if (resDetranEndereco.status === 200) {
			const endereco = resDetranEndereco.data;
			setDadosPreCadastro(oldFormValues => ({
				...oldFormValues,
				codCiretranHabilitacao:
					endereco?.codigoUnidadeTransito?.toString() || '',
				codCiretranCondutor: endereco?.codigoUnidadeTransito?.toString() || '',
			}));
		}
	}, [consultaCnhDefinitiva.data]);

	const clearInitialStatesWithoutNewCallServices = useCallback(() => {
		dispatch(cadastroEnderecosClear());
		dispatch(capturarBiometriaClear());
		dispatch(consultarDigitaisClear());
		dispatch(confrontarDigitaisCnhClear());
		dispatch(estatisticaIncluirClear());
		dispatch(consultarInaptoClear());
		dispatch(dadosCidadaoClear());
		dispatch(verificarVersaoServicoBiometriaClear());
	}, [dispatch]);

	// BUSCA CÓDIGO NATURALIDADE
	useEffect(() => {
		if (
			consultaCnhDefinitiva.data &&
			consultaCnhDefinitiva.data.cpf &&
			consultaSegundaViaReabilitacao.status === 0 &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			dispatch(
				consultaSegundaViaReabilitacaoRequest({
					cpfCidadao: consultaCnhDefinitiva.data.cpf,
				}),
			);
		}
	}, [
		dispatch,
		consultaCnhDefinitiva.data,
		consultaSegundaViaReabilitacao.status,
		dadosPreCadastroFormSave,
	]);

	// FORMATA DADOS DA CONSULTA PARA PREENCHER O FORMULÁRIO DE STATUS DO CONDUTOR
	useEffect(() => {
		if (
			consultaSegundaViaReabilitacao.data &&
			user &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			const uf =
				(consultaNaturalidades.data &&
					consultaNaturalidades.data[0].municipioIBGE.uf) ||
				'';
			const codigoNaturalidade =
				(consultaNaturalidades.data &&
					consultaNaturalidades.data[0].municipioIBGE.id.toString()) ||
				'';
			const { dataPrimeiraHabilitacao } = consultaSegundaViaReabilitacao.data;

			const newFormValues = treatDataValues(
				consultaCnhDefinitiva.data,
				dataPrimeiraHabilitacao,
				codigoNaturalidade,
				user,
				uf,
				historyState.flagProcurador === 'S' ||
					dadosPreCadastroFormSave.dataPreCadastro?.flagProcurador === true
					? 'S'
					: 'N',
			);

			setDadosPreCadastro(newFormValues.initialValuesPreCadastro);
			setDadosHabilitacao(newFormValues.initalValuesHabilitacao);
		}
	}, [
		consultaCnhDefinitiva.data,
		consultaSegundaViaReabilitacao.data,
		consultaNaturalidades.data,
		dispatch,
		user,
		historyState.flagProcurador,
		dadosPreCadastroFormSave,
	]);

	// CONSULTA DOMAIN SERVICES PARA VALIDAR UF DA NATURALIDADE PELO CÓDIGO IBGE
	useEffect(() => {
		if (
			consultaSegundaViaReabilitacao.data &&
			consultaSegundaViaReabilitacao.data.naturalidade &&
			consultaSegundaViaReabilitacao.data.nacionalidade?.toString() === '1' &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			dispatch(
				domainServiceNaturalidadesRequest({
					idIbge: consultaSegundaViaReabilitacao.data.naturalidade.trim(),
				}),
			);
		}
	}, [consultaSegundaViaReabilitacao.data, dispatch, dadosPreCadastroFormSave]);

	useEffect(() => {
		if (
			consultaCnhDefinitiva.data?.cpf &&
			mainframe &&
			dadosPreCadastro.cpf &&
			dadosPreCadastro.cpf !== '' &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			dispatch(
				consultaNomeSocialFiliacaoRequest({
					cpf: consultaCnhDefinitiva.data.cpf,
					usuario: mainframe.idUsuario,
					senha: mainframe.senhaMainFrame,
				}),
			);
		}
	}, [
		consultaCnhDefinitiva.data,
		dadosPreCadastro.cpf,
		dispatch,
		mainframe,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			consultaNomeSocialFiliacao.status === 200 &&
			consultaNomeSocialFiliacao.data
		) {
			const { nomeSocial, filiacao1, filiacao2 } =
				consultaNomeSocialFiliacao.data;

			setDadosPreCadastro(oldFormValues => ({
				...oldFormValues,
				nomeSocial:
					verifyIsUndefinedReturnEmpty(nomeSocial) === ''
						? verifyIsUndefinedReturnEmpty(nomeSocial)
						: nomeSocial.trim(),
				paisAfetivos1:
					verifyIsUndefinedReturnEmpty(filiacao1) === ''
						? verifyIsUndefinedReturnEmpty(filiacao1)
						: filiacao1.trim(),
				paisAfetivos2:
					verifyIsUndefinedReturnEmpty(filiacao2) === ''
						? verifyIsUndefinedReturnEmpty(filiacao2)
						: filiacao2.trim(),
				origemNomeSocial: getOriginOfSocialName(
					consultaNomeSocialFiliacao,
					null,
				),
			}));
		}

		if (
			consultaCnhDefinitiva.data?.cpf &&
			consultaPesquisaNomeSocialFiliacao.status === 0 &&
			((consultaNomeSocialFiliacao.status !== 0 &&
				consultaNomeSocialFiliacao.status !== 200) ||
				(consultaNomeSocialFiliacao.status === 200 &&
					!consultaNomeSocialFiliacao.data?.nomeSocial?.trim()))
		) {
			dispatch(
				consultaPesquisaNomeSocialFiliacaoRequest({
					usuario: mainframe.idUsuario,
					terminal: user.ip,
					cpf: consultaCnhDefinitiva.data.cpf,
				}),
			);
		}
	}, [
		consultaCnhDefinitiva.data,
		consultaNomeSocialFiliacao,
		consultaPesquisaNomeSocialFiliacao.status,
		dispatch,
		mainframe.idUsuario,
		user.ip,
	]);

	useEffect(() => {
		if (
			consultaPesquisaNomeSocialFiliacao.status === 200 &&
			consultaPesquisaNomeSocialFiliacao.data &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			setDadosPreCadastro(oldData => ({
				...oldData,
				nomeSocial:
					consultaPesquisaNomeSocialFiliacao.data &&
					consultaPesquisaNomeSocialFiliacao.data.nomeSocial
						? consultaPesquisaNomeSocialFiliacao.data.nomeSocial.trim()
						: '',
				origemNomeSocial: getOriginOfSocialName(
					null,
					consultaPesquisaNomeSocialFiliacao,
				),
			}));
		}
	}, [consultaPesquisaNomeSocialFiliacao, dadosPreCadastroFormSave]);

	const calcularDiferencaDeDatas = (dataPrimeiraHabilitacao: string) => {
		const partesData = dataPrimeiraHabilitacao.split('/');
		const dataPrimeiraHabilitacaoDate = new Date(
			`${partesData[1]}/${partesData[0]}/${partesData[2]}`,
		);

		if (!Number.isNaN(dataPrimeiraHabilitacaoDate.getTime())) {
			const hoje = new Date();
			const dataExpiracao = new Date(dataPrimeiraHabilitacaoDate);
			dataExpiracao.setDate(dataExpiracao.getDate() + 364);

			const diferencaMiliSegundos = dataExpiracao.getTime() - hoje.getTime();
			const diferencaDias = Math.ceil(
				diferencaMiliSegundos / (1000 * 60 * 60 * 24),
			);

			return {
				primeiraHabilitacaoParaUltimaEmissao: diferencaDias,
				diferencaDiasHojeParaDataPrimeiraHabilitacaoIncrementada: Math.ceil(
					(dataExpiracao.getTime() - dataPrimeiraHabilitacaoDate.getTime()) /
						(1000 * 60 * 60 * 24),
				),
			};
		}

		return null;
	};

	useEffect(() => {
		if (dadosHabilitacao?.dataPrimeiraHabilitacao) {
			const { dataPrimeiraHabilitacao } = dadosHabilitacao;

			const diffDatas = calcularDiferencaDeDatas(dataPrimeiraHabilitacao);

			if (diffDatas !== null) {
				setDiferencaDiasDatasCnh(prevState => ({
					...prevState,
					...diffDatas,
				}));
			}
		}
	}, [dadosHabilitacao]);

	useEffect(() => {
		if (
			consultaCnhDefinitiva.data &&
			consultaCnhDefinitiva.data.endereco?.cep &&
			dadosPreCadastro.cpf &&
			dadosPreCadastro.cpf !== '' &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			consultaCodCiretran();
		}
	}, [
		consultaCodCiretran,
		consultaCnhDefinitiva.data,
		dadosPreCadastro.cpf,
		dadosPreCadastroFormSave,
	]);

	// ESTATISTICA SGU CAMPOS SELECIONADOS - ANTES DE GERAR RENACH
	useEffect(() => {
		if (
			cadastroEnderecos.status === 200 &&
			preCadastro !== null &&
			requestData !== null
		) {
			dispatch(dadosCidadaoRequest(requestData.cidadao));
			dispatch(consultaCategoriaRequest({ funcionalidade: 'mudanca' }));
			setBiometria(null);
		}
	}, [cadastroEnderecos.status, dispatch, preCadastro, requestData]);

	useEffect(() => {
		if (
			dadosCidadao.status === 200 &&
			consultaCnhDefinitiva.data &&
			preCadastro !== null &&
			preCadastro?.candidato &&
			requestData !== null
		) {
			if (
				preCadastro.candidato.pretendeAtividadeRemunerada === 'S' &&
				!consultaCnhDefinitiva.data?.flagAtividadeRemunerada
			) {
				const inapto = {
					cpf: requestData.cidadao.cidadao.cpf.toString(),
					tipoExame: 'PSICOLOGO',
					flagDeficienteFisico:
						requestData.cidadao.cidadao.necessidade_veiculo_adaptado,
				};

				dispatch(consultarInaptoRequest(inapto));
			} else if (
				consultaCnhDefinitiva.data &&
				preCadastro.candidato.cpf &&
				gerarRenachIntegrado.status === 0 &&
				(consultaCnhDefinitiva.data.flagAtividadeRemunerada ||
					preCadastro.candidato.pretendeAtividadeRemunerada === 'N')
			) {
				const payload = treatRequestSguStatisticsGerarRenachCnhDefinitiva(
					user,
					dadosAtendimento,
					consultaCnhDefinitiva.data,
					preCadastro,
					'SELECTED_FIELDS',
				);
				dispatch(estatisticaIncluirRequest(payload));

				const treatedPayload: GerarRenachIntegradoRequest = {
					candidato: { cpfUsuario: user.cpf, ...preCadastro.candidato },
					dadosExameMedico: preCadastro.dadosExameMedico,
					dadosExamePsicologo: preCadastro.dadosExamePsicologo,
					evento: preCadastro.gerarRenachBase?.evento,
				};

				dispatch(gerarRenachIntegradoRequest(treatedPayload));
			}
		}
	}, [
		consultaCnhDefinitiva.data,
		dadosCidadao.status,
		dispatch,
		preCadastro,
		requestData,
		user,
		dadosAtendimento,
		gerarRenachIntegrado.status,
		user.cpf,
	]);

	useEffect(() => {
		if (consultarInapto.status !== 0 && consultarInapto.status !== 100) {
			dispatch(estatisticaIncluirClear());
			history.push(ROUTE_DETRAN_CNH_DEFINITIVA_AGENDAMENTO_PSICOLOGICO);
		}
	}, [consultarInapto.status, dispatch, history]);

	// REGISTRA ESTATISTICA SGU APÓS GERAR O RENACH - NUMERO RENACH OU MSG ERRO.
	useEffect(() => {
		if (
			preCadastro &&
			consultaCnhDefinitiva.data &&
			gerarRenachIntegrado.status !== 0 &&
			gerarRenachIntegrado.status !== 100 &&
			(gerarRenachIntegrado.data || gerarRenachIntegrado.dataError)
		) {
			const payload = treatRequestSguStatisticsGerarRenachCnhDefinitiva(
				user,
				dadosAtendimento,
				consultaCnhDefinitiva.data,
				preCadastro,
				'GENERATED_RENACH',
				gerarRenachIntegrado,
			);
			dispatch(estatisticaIncluirRequest(payload));
		}
	}, [
		consultaCnhDefinitiva.data,
		dadosAtendimento,
		dispatch,
		gerarRenachIntegrado,
		preCadastro,
		user,
	]);

	// RENACH
	useEffect(() => {
		if (
			(gerarRenachIntegrado.status === 200 ||
				gerarRenachIntegrado.status === 201) &&
			gerarRenachIntegrado.data
		) {
			dispatch(estatisticaIncluirClear());
			history.push(ROUTE_DETRAN_CNH_DEFINITIVA_RENACH);
		} else if (
			gerarRenachIntegrado.status !== 0 &&
			gerarRenachIntegrado.status !== 100 &&
			gerarRenachIntegrado.status !== 200 &&
			gerarRenachIntegrado.status !== 201
		) {
			clearInitialStatesWithoutNewCallServices();
		}
	}, [
		dispatch,
		clearInitialStatesWithoutNewCallServices,
		gerarRenachIntegrado.data,
		gerarRenachIntegrado.status,
		history,
	]);

	// RECARREGA OS DADOS DO FORM COM OS DADOS SALVOS NA STORE.
	useEffect(() => {
		if (dadosPreCadastroFormSave.dataPreCadastro?.cpf) {
			setDadosPreCadastro(dadosPreCadastroFormSave.dataPreCadastro);
		}
	}, [dispatch, dadosPreCadastroFormSave]);

	// RECARREGA OS DADOS DO FORM COM OS DADOS SALVOS NA STORE.
	useEffect(() => {
		if (dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf) {
			setDadosHabilitacao(dadosPreCadastroFormSave.dataPreCadastroHabilitacao);
		}
	}, [dispatch, dadosPreCadastroFormSave]);

	useEffect(() => {
		clearInitialStatesWithoutNewCallServices();

		if (!dadosPreCadastroFormSave.dataPreCadastro?.cpf) {
			dispatch(consultaNomeSocialFiliacaoClear());
			dispatch(consultaPesquisaNomeSocialFiliacaoClear());
			dispatch(consultaSegundaViaReabilitacaoClear());
			dispatch(domainServiceNaturalidadesClear());
			setBiometria(null);
		}
	}, [
		clearInitialStatesWithoutNewCallServices,
		dadosPreCadastroFormSave,
		dispatch,
	]);

	return (
		<>
			{diferencaDiasDatasCnh.primeiraHabilitacaoParaUltimaEmissao !== null && (
				<>
					{
						// 		data 1hab +  364 dias - data ultima emissao > 0
						diferencaDiasDatasCnh.primeiraHabilitacaoParaUltimaEmissao <= 0 &&
							biometria !== 'SHOW' && (
								<StatusCondutor
									schema={schema}
									dadosHabilitacao={dadosHabilitacao}
									dadosPreCadastro={dadosPreCadastro}
									modalAtendimento={modalAtendimento.isOpenModal}
									handleSubmit={handleSubmit}
									handleAfterModal={handleAfterModal}
								/>
							)
					}

					{diferencaDiasDatasCnh.primeiraHabilitacaoParaUltimaEmissao > 0 &&
						!dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf && (
							// 		data 1hab + 364 dias - data ultima emissao <= 0
							<AlertaCnhValidadePeriodoPermissionario
								unexpiredLicense
								diasVencimentoCnh={
									Math.abs(
										diferencaDiasDatasCnh.primeiraHabilitacaoParaUltimaEmissao,
									) || 0
								}
								nomeCondutor={dadosHabilitacao.nome}
								validadeCnh={dateMoreDays(
									dadosHabilitacao.dataPrimeiraHabilitacao,
									364,
								)}
								onClickProsseguirSegundaVia={() =>
									handleClickButtonProsseguirSegundaVia()
								}
							/>
						)}

					{biometria === 'SHOW' && (
						<LeituraDigitais
							setBiometria={setBiometria}
							cpf={dadosPreCadastro?.cpf || ''}
						/>
					)}

					<Row justify="space-between" align="middle">
						<Col>
							<ButtonVoltar
								navigate={false}
								onClick={() => {
									handleButtonBack();
								}}
							/>
						</Col>

						<Col>
							<ButtonImage
								type="button"
								src="outro-servico-detran"
								onClick={() => history.push('/detran-cnh')}
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default StatusCondutorCnhDefinitiva;
