import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_JALES } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { IRequestPontuacao, Types } from './types';

// ACTIONS
import {
	transferirPontuacaoSuccess,
	transferirPontuacaoFailure,
} from './actions';

function* transferirPontuacao(request: ReducerAction) {
	const { payload }: { payload: IRequestPontuacao } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_JALES,
		`transito/${CHANNEL}/transferir-pontuacao`,
		payload,
	);

	if (response.status === 200) {
		yield put(transferirPontuacaoSuccess(response));
	} else if (response.status === 204) {
		yield put(
			transferirPontuacaoFailure({
				mensagem: 'Não há resultados para os parâmetros informados! ',
			}),
		);
	}
}

export default all([
	takeLatest(Types.TRANSFERIR_PONTUACAO, transferirPontuacao),
]);
