export enum Types {
	CONSULTAR_STATUS_DARE_MAINFRAME_REQUEST = '@dare/CONSULTAR_STATUS_DARE_MAINFRAME_REQUEST',
	CONSULTAR_STATUS_DARE_MAINFRAME_SUCCESS = '@dare/CONSULTAR_STATUS_DARE_MAINFRAME_SUCCESS',
	CONSULTAR_STATUS_DARE_MAINFRAME_FAILURE = '@dare/CONSULTAR_STATUS_DARE_MAINFRAME_FAILURE',
	CONSULTAR_STATUS_DARE_MAINFRAME_CLEAR = '@dare/CONSULTAR_STATUS_DARE_MAINFRAME_CLEAR',
}

export interface IConsultarStatusMainframeDareState {
	status: number;
	data: IStatusDareMainframe | null;
}

export interface IStatusDareMainframe {
	rg: string;
	sequencia: string;
	via: string;
	taxaSegundaVia: string;
	dataNascimento: string;
	dataAtendimento: string;
	digitoVerificador: string;
	taxaCorreio: string;
	liberacaoBiometria: string;
	tipoLiberacaoBiometria: string;
	dataLiberacaoBiometria: string;
	usuarioPagamentoSegundaVia: string;
	dataPagamentoSegundaVia: string;
	usuarioPagamentoCorreio: string;
	dataPagamentoCorreio: string;
	dataEmissaoUltimaCI: string;
	mensagem: string;
}

export interface IStatusDareMainframeRequest {
	rg: string;
	sequencia: string;
	usuario: string;
	terminal: string;
	digitoVerificador: string;
}
