import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaPesquisaCivilRequest } from './types';

// ACTIONS
import {
	consultaPesquisaCivilSuccess,
	consultaPesquisaCivilFailure,
} from './actions';
import { consultaPesquisaCidadaoSuccess } from '../consultaPesquisaCidadao/actions';

function* consultaPesquisaCivilRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultaPesquisaCivilRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		'consultaPesquisaCivil',
		payload,
	);

	if (response.status === 200) {
		yield put(consultaPesquisaCivilSuccess(response.data.pessoa));
		yield put(consultaPesquisaCidadaoSuccess(response.data));
	} else if (response.error) {
		yield put(consultaPesquisaCivilFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_PESQUISA_CIVIL_REQUEST,
		consultaPesquisaCivilRequest,
	),
]);
