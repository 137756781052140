// ROUTES
import {
	ROUTE_IIRGD_PESQUISA_ANTECEDENTE,
	ROUTE_AAC_PESQUISA_ANTECEDENTES_NUMERAL,
	ROUTE_AAC_RESULTADO_NUMERAL,
	ROUTE_AAC_PESQUISA_ANTECEDENTES_NOMINAL,
	ROUTE_AAC_RESULTADO_NOMINAL,
	ROUTE_AAC_PESQUISA_ANTECEDENTES_CENTRAL,
	ROUTE_AAC_PESQUISA_ANTECEDENTES_PAINEL,
	ROUTE_AAC_RESULTADO_PAINEL,
	ROUTE_AAC_RESULTADO_SITUACAO_PAINEL,
} from './paths';

import Apresentacao from '../Apresentacao';

import PainelDeControle from '../PainelDeControle';
import PainelDeControleResultado from '../PainelDeControle/Resultado';

import Numeral from '../Numeral/Consultar';
import NumeralResultado from '../Numeral/Resultado';

import Nominal from '../Nominal/Consultar';
import NominalResultado from '../Nominal/Resultado';

import SituacaoAtual from '../PainelDeControle/SituacaoAtual';
import PesquisaAntecedentes from '../PesquisaAntecedentes';

const iirgdRoute = {
	name: 'IIRGD',
	route: '/iirgd',
};

const aacRoute = {
	name: 'Pesquisa de Antecedentes',
};

export default [
	{
		path: ROUTE_IIRGD_PESQUISA_ANTECEDENTE,
		breadcrumb: [iirgdRoute, aacRoute],
		Component: Apresentacao,
		exact: true,
		isAuthenticated: true,
	},
	{
		path: ROUTE_AAC_PESQUISA_ANTECEDENTES_NUMERAL,
		breadcrumb: [iirgdRoute, aacRoute],
		Component: Numeral,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_AAC_RESULTADO_NUMERAL,
		breadcrumb: [iirgdRoute, aacRoute],
		Component: NumeralResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_AAC_PESQUISA_ANTECEDENTES_NOMINAL,
		breadcrumb: [iirgdRoute, aacRoute],
		Component: Nominal,
		isAuthenticated: true,
		exact: true,
	},
	{
		id: 5,
		name: 'AAC - Atestado de Antecedentes Criminais - Nominal - Resultado',
		path: ROUTE_AAC_RESULTADO_NOMINAL,
		breadcrumb: [iirgdRoute, aacRoute],
		Component: NominalResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_AAC_PESQUISA_ANTECEDENTES_CENTRAL,
		breadcrumb: [iirgdRoute, aacRoute],
		Component: PesquisaAntecedentes,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_AAC_PESQUISA_ANTECEDENTES_PAINEL,
		breadcrumb: [iirgdRoute, aacRoute],
		Component: PainelDeControle,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_AAC_RESULTADO_PAINEL,
		breadcrumb: [iirgdRoute, aacRoute],
		Component: PainelDeControleResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_AAC_RESULTADO_SITUACAO_PAINEL,
		breadcrumb: [iirgdRoute, aacRoute],
		Component: SituacaoAtual,
		isAuthenticated: true,
		exact: true,
	},
];
