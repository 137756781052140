import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Col } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import {
	relatorioAgendamentoMedicoPsicologicoRequest,
	relatorioAgendamentoMedicoPsicologicoClear,
} from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';
import {
	consultaPrimeiraHabilitacaoRequest,
	consultaPrimeiraHabilitacaoClear,
} from 'store/modules/api/detranCnh/primeiraHabilitacao/consultaPrimeiraHabilitacao/actions';
import { RequestConsultaPrimeiraHabilitacao } from 'store/modules/api/detranCnh/primeiraHabilitacao/consultaPrimeiraHabilitacao/types';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import {
	consultaProcessoAbertoClear,
	consultaProcessoAbertoRequest,
} from 'store/modules/api/detranCnh/renachIntegrado/consultaProcessoAberto/actions';
import { eventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/actions';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import CPF from 'components/Common/Form/Fields/CPF';
import ButtonImage from 'components/Common/ButtonImage';
import PDFViewer from 'components/Common/PDFViewer';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { CONSULTA_CNH_ESTRANGEIRO_RESPONSE_SERVICE_MESSAGE } from 'pages/DetranCnh/utils/constants/messages';
import { threatValuesStartSessionEventRequest } from 'utils/functions/threatEventsRequest';
import { treatAgendamentoMedicoPsicologicoRequestInPesquisar } from 'pages/DetranCnh/utils/threatServicesRequest';

// ROUTES
import { ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_STATUS_CONDUTOR } from 'pages/DetranCnh/routes/paths';
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// FORM
import { Formik, Form, FormikProps } from 'formik';
import { initialValues, schema } from './form';

const PesquisaPrimeiraHabilitacao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [modalPrint, setModalPrint] = useState(false);
	const [cpf, setCpf] = useState('');

	const { consultaPrimeiraHabilitacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.primeiraHabilitacao,
	);

	// const { consultaAgendamentos } = useSelector(
	// 	(state: ApplicationState) => state.api.detranCnh.divisaoEquitativa,
	// );

	const { relatorio } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.agendamentoMedicoPsicologico,
	);

	const { mainframe } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { consultaProcessoAberto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const handleClear = useCallback(
		(formik?: FormikProps<RequestConsultaPrimeiraHabilitacao>) => {
			dispatch(clearNotifications());
			dispatch(consultaProcessoAbertoClear());
			dispatch(relatorioAgendamentoMedicoPsicologicoClear());
			dispatch(consultaPrimeiraHabilitacaoClear());
			setModalPrint(false);
			if (formik) {
				formik.handleReset();
			}
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: RequestConsultaPrimeiraHabilitacao) => {
			setModalPrint(false);
			handleClear();

			if (consultaPrimeiraHabilitacao.status === 0) {
				const newValues = {
					cpf: values.cpf.replace(/[.-]/g, ''),
					usuario: mainframe.idUsuario,
					senha: mainframe.senhaMainFrame,
				};

				setCpf(values.cpf.replace(/[.-]/g, ''));

				const valuesStartSessionEvent = threatValuesStartSessionEventRequest(
					atendimento,
					loginUnico,
				);
				dispatch(eventoInicioSessaoRequest(valuesStartSessionEvent));
				dispatch(consultaPrimeiraHabilitacaoRequest({ ...newValues }));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch],
	);

	useEffect(() => {
		handleClear();
	}, [handleClear]);

	useEffect(() => {
		if (
			(relatorio.data || !relatorio.data) &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			consultaProcessoAberto.data?.message
		) {
			addNotifications({
				errors: [consultaProcessoAberto.data.message],
			});
		}
	}, [dispatch, consultaProcessoAberto.data, relatorio.data]);

	useEffect(() => {
		if (
			(consultaPrimeiraHabilitacao.status === 200 &&
				consultaPrimeiraHabilitacao.data?.cpf &&
				consultaProcessoAberto.status === 0) ||
			(consultaPrimeiraHabilitacao.status === 400 &&
				consultaPrimeiraHabilitacao.dataError?.mensagem &&
				consultaPrimeiraHabilitacao.dataError.mensagem.trim().toLowerCase() ===
					CONSULTA_CNH_ESTRANGEIRO_RESPONSE_SERVICE_MESSAGE.CONDUTOR_PROCESSO_ABERTO.toLowerCase() &&
				consultaPrimeiraHabilitacao.cpf &&
				consultaProcessoAberto.status === 0)
		) {
			dispatch(
				consultaProcessoAbertoRequest({
					cpf:
						consultaPrimeiraHabilitacao.data?.cpf ||
						consultaPrimeiraHabilitacao.cpf ||
						'',
					ident: 0,
				}),
			);
		} else if (
			consultaPrimeiraHabilitacao.status === 204 ||
			consultaPrimeiraHabilitacao.status === 200
		) {
			history.push(ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_STATUS_CONDUTOR);
		} else if (
			consultaPrimeiraHabilitacao.status !== 0 &&
			consultaPrimeiraHabilitacao.status !== 200 &&
			consultaPrimeiraHabilitacao.status !== 204
		) {
			addNotifications({
				errors: [
					consultaPrimeiraHabilitacao.dataError
						? consultaPrimeiraHabilitacao.dataError.mensagem
						: '',
				],
			});
		}
	}, [
		consultaPrimeiraHabilitacao.status,
		consultaPrimeiraHabilitacao.cpf,
		dispatch,
		consultaPrimeiraHabilitacao.data,
		history,
		consultaProcessoAberto.status,
		consultaPrimeiraHabilitacao.dataError,
	]);

	useEffect(() => {
		if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			(consultaProcessoAberto.data.dataExame ||
				consultaProcessoAberto.data.dataExamePsicologo)
		) {
			const requestData = treatAgendamentoMedicoPsicologicoRequestInPesquisar(
				consultaProcessoAberto.data,
			);

			dispatch(relatorioAgendamentoMedicoPsicologicoRequest(requestData));
		} else if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) === 0
		) {
			history.push(ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_STATUS_CONDUTOR);
		}
	}, [
		consultaProcessoAberto.data,
		consultaProcessoAberto.status,
		cpf,
		dispatch,
		history,
		mainframe,
	]);

	return (
		<>
			<Section title="Pesquisar">
				<Row>
					<Col offset={1} span={24}>
						<strong>
							Digite o CPF do cidadão para verificar se existe o pré-cadastro:
						</strong>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={values => getValidationsErrors(values, schema)}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row gutter={[0, 10]}>
										<Col span={12}>
											<CPF
												title="CPF"
												subtitle="(Digite apenas números)"
												numero="cpf"
												formik={formik}
												error={!!formik.errors.cpf}
												disabled={consultaPrimeiraHabilitacao.status === 100}
											/>
										</Col>
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => {
													setModalPrint(false);
													dispatch(
														relatorioAgendamentoMedicoPsicologicoClear(),
													);
													dispatch(clearNotifications());
													formik.handleReset();
												}}
												disabled={consultaPrimeiraHabilitacao.status === 100}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage
												type="submit"
												src="pesquisar"
												disabled={consultaPrimeiraHabilitacao.status === 100}
											/>
										</Col>

										{relatorio.data !== null && (
											<Col offset={1}>
												<ButtonImage
													src="btn-imprimir-agendamento-medico-psicologico"
													onClick={() => {
														setModalPrint(true);
													}}
												/>
											</Col>
										)}
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>

			{modalPrint && (
				<PDFViewer
					title="agendamentoMedicoViewer"
					source={relatorio.data?.arquivoPDF || ''}
					onClose={() => setModalPrint(false)}
					renderMode="popup"
				/>
			)}

			<ButtonVoltar
				route={ROUTE_DETRAN_CNH}
				onClick={() => {
					dispatch(detranCnhClear());
				}}
			/>
		</>
	);
};

export default PesquisaPrimeiraHabilitacao;
