import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_JALES } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultaDocumentoInfracaoSuccess,
	consultaDocumentoInfracaoFailure,
} from './actions';

function* consultaDocumentoInfracao(request: ReducerAction) {
	const { payload }: { payload: string } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_JALES,
		`transito/${CHANNEL}/documento-infracao`,
		{ codigoAit: payload },
	);

	if (response.status === 200) {
		yield put(consultaDocumentoInfracaoSuccess(response));
	} else if (response.status === 204) {
		yield put(
			consultaDocumentoInfracaoFailure({
				mensagem: 'Não há resultados para os parâmetros informados! ',
			}),
		);
	}
}

export default all([
	takeLatest(Types.CONSULTA_DOCUMENTO_INFRACAO, consultaDocumentoInfracao),
]);
