import React, { FC } from 'react';
import { Col, Row } from 'antd';

import { useHistory } from 'react-router-dom';

import Button from 'components/Common/Button';

import { ROUTE_BOLSA_POVO, ROUTE_MENU_ORGAOS } from 'routes/paths';
import { ContainerBolsaPovo, ContainerButtons } from './styled';

const Confirmacao: FC = () => {
	const history = useHistory();

	return (
		<>
			<ContainerBolsaPovo>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h1>
							Solicitação de desbloqueio de cartão Bolsa do Povo realizado com
							sucesso!
						</h1>
						<span>
							A solicitação de desbloqueio de cartão pode demorar até 2 dias
							úteis.
						</span>
					</Col>
				</Row>
			</ContainerBolsaPovo>
			<ContainerButtons>
				<Row gutter={[0, 10]}>
					<Col style={{ padding: '5px 10px' }}>
						<Button
							type="button"
							onClick={() => {
								history.push(ROUTE_BOLSA_POVO);
							}}
						>
							Voltar
						</Button>
					</Col>
					<Col style={{ padding: '5px 10px' }}>
						<Button
							type="button"
							onClick={() => {
								history.push(ROUTE_MENU_ORGAOS);
							}}
						>
							Finalizar atendimento
						</Button>
					</Col>
				</Row>
			</ContainerButtons>
		</>
	);
};

export default Confirmacao;
