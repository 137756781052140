import { Types, ConsultarVeiculoV120Request } from './types';

export function consultarVeiculoV120Request(
	payload: ConsultarVeiculoV120Request,
) {
	return {
		type: Types.CONSULTAR_VEICULO_V120_REQUEST,
		payload,
	};
}
export function consultarVeiculoV120Success(payload: any) {
	return {
		type: Types.CONSULTAR_VEICULO_V120_SUCCESS,
		payload,
	};
}
export function consultarVeiculoV120Failure(payload: []): any {
	return {
		type: Types.CONSULTAR_VEICULO_V120_FAILURE,
		payload,
	};
}
export function consultarVeiculoV120Clear(): any {
	return {
		type: Types.CONSULTAR_VEICULO_V120_CLEAR,
	};
}
