import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_AAC } from 'services/_path';

// STORE
import { ReducerAction } from 'store/modules/types';

// UTILS
import { convertDate } from 'utils/genericFunctions';

// ACTIONS
import { emissaoNominalSuccess, emissaoNominalFailure } from './actions';

// TYPES
import { Types, IRequestEmissaoNominal } from './types';

function* emissaoNominalRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestEmissaoNominal } = request;

	const body = {
		numeroRg: payload.numeroDocumento,
		nome: payload.nome,
		nomePai: payload.nomePai,
		nomeMae: payload.nomeMae,
		sexo: payload.sexo,
		dataNascimento: convertDate(payload.dataNascimento),
		dataExpedicaoRg: convertDate(payload.dataExpedicao),
		posto: payload.posto,
		ipEstacao: payload.ipEstacao,
		usuario: payload.usuario,
		codigoRetorno: payload.codigoRetorno,
		docProcurador: payload.docProcurador,
		nomeProcurador: payload.nomeProcurador,
		tipoDocApresentado: payload.tipoDocApresentado,
		uf: payload.ufRg,
	};

	const response: ApiResponse = yield call(
		postApi,
		PATH_AAC,
		`aac/${CHANNEL}/emissaoAtestadoNominal`,
		body,
	);

	if (response.status === 200) {
		yield put(emissaoNominalSuccess(response.data));
	} else if (response.error) {
		yield put(emissaoNominalFailure());
	}
}

export default all([
	takeLatest(Types.EMISSAO_NOMINAL_REQUEST, emissaoNominalRequest),
]);
