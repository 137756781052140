export enum Types {
	CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_REQUEST = '@DETRAN-CRV/CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_REQUEST',
	CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_SUCCESS = '@DETRAN-CRV/CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_SUCCESS',
	CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_FAILURE = '@DETRAN-CRV/CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_FAILURE',
	CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_CLEAR = '@DETRAN-CRV/CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_CLEAR',
}

export interface consultarVeiculoSegundaViaPlaca {
	status: number;
	data: IResponseConsultarVeiculoSegundaViaPlaca | null;
}

export interface IResponseConsultarVeiculoSegundaViaPlaca {
	anoModelo: number;
	capacidadeCarga: number;
	capacidadePassageiro: number;
	chassi: string;
	chassiRemarcado: boolean;
	cilindrada: number;
	cmt: number;
	eixo: number;
	pbt: number;
	placa: string;
	potencia: number;
	procedencia: string;
	protocoloRemarcacao: number;
	renavam: number;
	carroceria: {
		longId: number;
		descricao: string;
	};
	categoria: {
		descricao: string;
		longId: number;
	};
	combustivel: {
		longId: number;
		descricao: string;
	};
	cor: {
		longId: number;
		descricao: string;
	};
	especie: {
		longId: number;
		descricao: string;
	};
	marca: {
		longId: number;
		descricao: string;
	};
	municipio: {
		codigo: number;
		nome: string;
	};
	tipo: {
		longId: number;
		descricao: string;
	};
	proprietario: {
		nomeProprietario: string;
		cepProprietario: number;
		bairro: string;
		enderecoProprietario: string;
		numero: number;
		complemento: string;
		telefone: number;
		identificacao: string;
		cpfCnpjProprietario: number;
		rgProprietario: number;
		orgaoExpeditorProprietario: string;
		ufProprietario: string;
		nomeProprietarioAnterior: string;
		municipioProprietario: {
			codigo: number;
			nome: string;
		};
	};
}

export interface IRequestConsultarVeiculoSegundaViaPlaca {
	chassi?: string;
	placa?: string;
}
