import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AtualizarFuncionalidadeRequest } from './types';

// ACTIONS
import {
	atualizarFuncionalidadeSuccess,
	atualizarFuncionalidadeFailure,
} from './actions';

function* atualizarFuncionalidade(request: ReducerAction) {
	const { payload }: { payload: AtualizarFuncionalidadeRequest } = request;

	const body = { ...payload };

	delete body.id;

	const response: ApiResponse = yield call(
		putApi,
		PATH_SGU,
		`funcionalidades/${payload.id}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarFuncionalidadeSuccess(response));
	} else {
		yield put(atualizarFuncionalidadeFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_FUNCIONALIDADE, atualizarFuncionalidade),
]);
