import React from 'react';

// ANTD
import { Row, Col, Divider } from 'antd';

// COMPONENTS COMMON
import TypographyCommon from 'components/Common/Typography';
import Section from 'components/Common/Section';

// COMPONENTS
import { v4 } from 'uuid';

// UTILS
import { isFilledValue } from 'utils/stringFunctions';
import { formatDate } from 'utils/genericFunctions';

// REDUX
import { PCOV } from 'store/modules/api/detranCrv/ConsultarResumoExtrato/pcov/types';

interface Props {
	data: PCOV;
}

const ExtratoPCOV: React.FC<Props> = ({ data }) => {
	return (
		<>
			{data.status === 200 && (
				<>
					<Row gutter={[0, 0]} align="top">
						<Col span={6}>
							<TypographyCommon
								text="Chassi:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.chassi)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text="Renavam:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.renavam)}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={24}>
							<Section size="lg" title="Histórico" titleSize="sm">
								{data.data?.lstCv &&
									data.data.lstCv.map((item, index) => (
										<span key={v4()}>
											<Row gutter={[0, 0]} align="top">
												<Col span={6}>
													<TypographyCommon
														text="Status:"
														type="label"
														fontWeight="bold"
														uppercase
														labelSize={11}
													/>
												</Col>
												<Col span={6}>
													<TypographyCommon
														text={isFilledValue([
															item?.statusCV?.codigo,
															item?.statusCV?.descricao,
														])}
														type="label"
														labelSize={11}
													/>
												</Col>
												<Col span={6}>
													<TypographyCommon
														text="Placa:"
														type="label"
														fontWeight="bold"
														uppercase
														labelSize={11}
													/>
												</Col>
												<Col span={6}>
													<TypographyCommon
														text={isFilledValue(item?.placa)}
														type="label"
														labelSize={11}
													/>
												</Col>
											</Row>

											<Row gutter={[0, 0]} align="top">
												<Col span={6}>
													<TypographyCommon
														text="Comprador:"
														type="label"
														fontWeight="bold"
														uppercase
														labelSize={11}
													/>
												</Col>
												<Col span={6}>
													<TypographyCommon
														text={isFilledValue(item?.nomeComprador)}
														type="label"
														labelSize={11}
													/>
												</Col>
												<Col span={6}>
													<TypographyCommon
														text="Origem:"
														type="label"
														fontWeight="bold"
														uppercase
														labelSize={11}
													/>
												</Col>
												<Col span={6}>
													<TypographyCommon
														text={isFilledValue([
															item?.origemCV?.codigo,
															item?.origemCV?.descricao,
														])}
														type="label"
														labelSize={11}
													/>
												</Col>
											</Row>

											<Row gutter={[0, 0]} align="top">
												<Col span={6}>
													<TypographyCommon
														text="Tipo Documento Comprador:"
														type="label"
														fontWeight="bold"
														uppercase
														labelSize={11}
													/>
												</Col>
												<Col span={6}>
													<TypographyCommon
														text={isFilledValue([
															item?.tipoDocumentoComprador?.codigo,
															item?.tipoDocumentoComprador?.descricao,
														])}
														type="label"
														labelSize={11}
													/>
												</Col>
												<Col span={6}>
													<TypographyCommon
														text="CNPJ/CPF Comprador:"
														type="label"
														fontWeight="bold"
														uppercase
														labelSize={11}
													/>
												</Col>
												<Col span={6}>
													<TypographyCommon
														text={isFilledValue(item?.cpfCnpjComprador)}
														type="label"
														labelSize={11}
													/>
												</Col>
											</Row>

											<Row gutter={[0, 0]} align="top">
												<Col span={6}>
													<TypographyCommon
														text="Data Venda:"
														type="label"
														fontWeight="bold"
														uppercase
														labelSize={11}
													/>
												</Col>
												<Col span={6}>
													<TypographyCommon
														text={isFilledValue(formatDate(item?.dataVenda))}
														type="label"
														labelSize={11}
													/>
												</Col>
												<Col span={6}>
													<TypographyCommon
														text="Usuário:"
														type="label"
														fontWeight="bold"
														uppercase
														labelSize={11}
													/>
												</Col>
												<Col span={6}>
													<TypographyCommon
														text={isFilledValue(item.usuario)}
														type="label"
														labelSize={11}
													/>
												</Col>
											</Row>

											{data.data.lstCv.length !== index + 1 && <Divider />}
										</span>
									))}
							</Section>
						</Col>
					</Row>
				</>
			)}

			{data.status === 406 && (
				<Row gutter={[0, 0]} align="top">
					<Col span={24}>
						<TypographyCommon
							text={data.mensagem}
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
				</Row>
			)}
		</>
	);
};

export default ExtratoPCOV;
