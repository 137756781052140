export enum Types {
	AGENDAR_EXAMES_RENACH_INTEGRADO_REQUEST = '@cnh/AGENDAR_EXAMES_RENACH_INTEGRADO_REQUEST',
	AGENDAR_EXAMES_RENACH_INTEGRADO_SUCCESS = '@cnh/AGENDAR_EXAMES_RENACH_INTEGRADO_SUCCESS',
	AGENDAR_EXAMES_RENACH_INTEGRADO_FAILURE = '@cnh/AGENDAR_EXAMES_RENACH_INTEGRADO_FAILURE',
	AGENDAR_EXAMES_RENACH_INTEGRADO_PRE_SAVE = '@cnh/AGENDAR_EXAMES_RENACH_INTEGRADO_PRE_SAVE',
	AGENDAR_EXAMES_RENACH_INTEGRADO_PRE_SAVE_CLEAR = '@cnh/AGENDAR_EXAMES_RENACH_INTEGRADO_PRE_SAVE_CLEAR',
	AGENDAR_EXAMES_RENACH_INTEGRADO_CLEAR = '@cnh/AGENDAR_EXAMES_RENACH_INTEGRADO_CLEAR',
}

export interface AgendarExamesRenachIntegrado {
	status: number;
	data: IDadosAgendarExamesRenachIntegrado | null;
	preSaveData: PreSaveData | null;
	error?: AgendarExamesRenachIntegradoErrorData | null;
}

export interface IDadosAgendarExamesRenachIntegrado {
	categoria_pretendida: string;
	cpf_cidadao: number;
	crm: number;
	crp: number;
	data_exame: string;
	data_exame_psicologo: string;
	endereco_medico: string;
	endereco_psicologo: string;
	horario_exame: string;
	horario_exame_psicologo: string;
	nome_cidadao: string;
	nome_medico: string;
	nome_psicologo: string;
	telefones: string;
	telefones_psicologo: string;
	tipo_processo: string;
}

export interface PreSaveData {
	cpf_cidadao: number;
	tipo_processo: string;
	cep_cidadao: number;
	codigo_ciretran_exame_medico: number;
	categoria_pretendida: string;
	id_medico: number;
	data_exame: string;
	horario_exame: string;
	codigo_ciretran_exame_psicologo?: number;
	id_psicologo?: number;
	data_exame_psicologico?: string;
	horario_exame_psicologico?: string;
	renach: string;
	id_micro_regiao_medico: number;
	id_micro_regiao_psicologo?: number;
	usuario: string;
}

export interface IRequestAgendarExamesRenachIntegrado {
	cpf_cidadao: number;
	tipo_processo: string;
	cep_cidadao: number;
	codigo_ciretran_exame_medico: number;
	categoria_pretendida: string;
	id_medico: number;
	data_exame: string | null;
	horario_exame: string | null;
	codigo_ciretran_exame_psicologo: number | string;
	id_psicologo: number | string;
	data_exame_psicologico: string;
	horario_exame_psicologico: string;
	renach: string;
	id_micro_regiao_medico: number;
	id_micro_regiao_psicologo?: number | string;
	usuario: string;
}

interface AgendarExamesRenachIntegradoErrorData {
	transacao: string;
	codigo: 0;
	mensagem: string;
	mensagens: [string];
}
