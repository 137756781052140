import {
	IRequestCadastraTermoAdvertencia,
	IRequestImpressao,
	IRequestTermoAdvertencia,
} from 'store/modules/api/seds/vivaLeite/documentos/types';

export const InitialTermoAdvertencia: IRequestTermoAdvertencia = {
	servico: 'PRESTACAO CONTAS',
	origem: 'PORTAL',
	etapa: 'gera_termo_advertencia',
	num_pan: 0,
	cpf: '',
};

export const InitialImpressao: IRequestImpressao = {
	servico: 'ATUALIZACAO CADASTRAL',
	origem: 'PORTAL',
	etapa: 'efetiva_impressao_documento',
	codigo_documento: 4,
	num_pan: 0,
	cpf: '',
};

export const InitialCadastraTermoAdvertencia: IRequestCadastraTermoAdvertencia = {
	servico: 'PRESTACAO CONTAS',
	origem: 'PORTAL',
	num_pan: 0,
	etapa: 'efetiva_ciencia',
	cod_termo_advertencia: 0,
};
