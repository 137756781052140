import * as Yup from 'yup';

interface BuscaSolicitaoPaternidade {
	cpf: string;
}

export const initialValues = {
	cpf: '',
};

export const schema = Yup.object<BuscaSolicitaoPaternidade>().shape({
	cpf: Yup.string().min(11, `Necessário preencher o CPF para pesquisar.`),
});
