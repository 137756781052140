import { all } from 'redux-saga/effects';

import enderecosSaga from './enderecos/saga';
import consultarMunicipioSaga from './consultarMunicipios/saga';
import cadastroEnderecosSaga from './cadastroEnderecos/saga';
import consultaMunicipiosSaga from './consultaMunicipios/saga';
import pesquisaEnderecosRecebimentoSaga from './pesquisaEnderecosRecebimento/saga';
import consultaNomeSocialFiliacaoSaga from './consultaNomeSocialFiliacao/saga';
import incluirExameMedicoSaga from './incluirExameMedicoDentroPosto/saga';
import consultaCondutor from './consultaCondutor/saga';

export default all([
	enderecosSaga,
	consultarMunicipioSaga,
	cadastroEnderecosSaga,
	consultaMunicipiosSaga,
	pesquisaEnderecosRecebimentoSaga,
	consultaNomeSocialFiliacaoSaga,
	incluirExameMedicoSaga,
	consultaCondutor,
]);
