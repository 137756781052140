import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, consultarBaseOutrosEstados } from './types';

export const INITIAL_STATE: consultarBaseOutrosEstados = {
	status: 0,
	data: {
		dadosBaseEstadualOutrosEstados: {
			anoFabricacao: 0,
			anoModelo: 0,
			capacidadeCarga: 0,
			capacidadePassageiro: 0,
			chassi: '',
			cmt: 0,
			eixo: 0,
			numeroMotor: '',
			pbt: 0,
			placa: '',
			procedencia: '',
			situacaoVeiculo: '',
			ufEmplacamento: '',
			ultimaAtualizacao: 0,
			categoria: {
				descricao: '',
				longId: 0,
			},
			combustivel: {
				longId: 0,
				descricao: '',
			},
			cor: {
				longId: 0,
				descricao: '',
			},
			especie: {
				longId: 0,
				descricao: '',
			},
			marca: {
				longId: 0,
				descricao: '',
			},
			municipioEmplacamento: {
				codigo: 0,
				nome: '',
			},
			tipo: {
				longId: 0,
				descricao: '',
			},
			dadosProprietario: {
				nomeProprietario: '',
			},
			multa: {
				debitoIpvaLicenciamento: '',
				debitosMultas: '',
				valorDebitoIpva: 0,
				valorDebitoLicenciamento: 0,
				valorDebitoMulta: 0,
				valorDpvat: 0,
			},
			restricoes: [],
			gravame: {
				tipoTransacao: '',
				restricao: '',
				tipoRestricao: '',
				agenteFinanceiro: '',
				nomeFinanciado: '',
				cpfCnpjFinanciado: 0,
				codigoFinanceira: 0,
				arrendatarioFinanceira: '',
				numeroContrato: '',
				dataInclusao: '',
				dataVigencia: '',
			},
		},
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultarBaseOutrosEstados {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_BASE_OUTROS_ESTADOS_REQUEST: {
				break;
			}

			case Types.CONSULTAR_BASE_OUTROS_ESTADOS_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_BASE_OUTROS_ESTADOS_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_BASE_OUTROS_ESTADOS_CLEAR: {
				draft.status = 0;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
