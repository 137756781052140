import { all } from 'redux-saga/effects';

import atualizarMotivoSaga from './atualizarMotivo/saga';
import cadastrarMotivoSaga from './cadastrarMotivo/saga';
import consultarMotivosSaga from './consultarMotivos/saga';
import excluirMotivoSaga from './excluirMotivo/saga';

export default all([
	atualizarMotivoSaga,
	cadastrarMotivoSaga,
	consultarMotivosSaga,
	excluirMotivoSaga,
]);
