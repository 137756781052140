import React, { useEffect, useState } from 'react';

import { Row, Col } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import Section from 'components/Common/Section';

// UTILS
import { formatDateToString, patternFormat } from 'utils/genericFunctions';
import { formatCurrency } from 'utils/numberFunctions';

const SituacaoContrato: React.FC = () => {
	const [qtdParcPrd, setQtdParcPrd] = useState<number>(0);
	const [qtdParcAtraso, setQtdParcAtraso] = useState<number>(0);

	const [valorParcPrd, setValorParcPrd] = useState<number>(0);
	const [valorParcAtraso, setValorParcAtraso] = useState<number>(0);

	const { consultarMutuario, consultarPrestacoesAtrasadas } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	useEffect(() => {
		if (consultarPrestacoesAtrasadas.data) {
			const {
				pagValorTotal,
				pagValorTotalPRD,
				prestacoesPRD,
				prestacoesAtField,
			} = consultarPrestacoesAtrasadas.data;

			setQtdParcPrd(prestacoesPRD !== undefined ? prestacoesPRD.length : 0);
			setQtdParcAtraso(prestacoesAtField !== undefined ? prestacoesAtField.length : 0);

			setValorParcPrd(Number(pagValorTotalPRD));
			setValorParcAtraso(Number(pagValorTotal));
		}
	}, [consultarPrestacoesAtrasadas]);

	return (
		<>
			{consultarMutuario.data && consultarPrestacoesAtrasadas.data && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Section size="lg" title="Situação do contrato" titleSize="sm">
							<Row gutter={[0, 5]}>
								<Col span={6}>Conjunto Habitacional:</Col>

								<Col>
									<b>{consultarMutuario.data.cnjHab.toUpperCase()}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Nº Dossiê:</Col>

								<Col>
									<b>{consultarMutuario.data.nroDossie}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Endereço:</Col>

								<Col>
									<b>{consultarMutuario.data.endCompleto.toUpperCase()}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Cidade:</Col>

								<Col>
									<b>{consultarMutuario.data.cidade.toUpperCase()}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>CEP:</Col>

								<Col>
									<b>
										{patternFormat(consultarMutuario.data.cep, '##.###-###')}
									</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Data Lavratura:</Col>

								<Col>
									<b>
										{(consultarMutuario.data.dtLavratura &&
											formatDateToString(
												new Date(consultarMutuario.data.dtLavratura),
											)) ||
											''}
									</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Data Último Evento:</Col>

								<Col>
									<b>
										{(consultarMutuario.data.dtUltimoEvento &&
											formatDateToString(
												new Date(consultarMutuario.data.dtUltimoEvento),
											)) ||
											''}
									</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Evento:</Col>

								<Col>
									<b>{consultarMutuario.data.evento}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Grifo:</Col>

								<Col>
									<b>{consultarMutuario.data.grifo}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Data Grifo:</Col>

								<Col>
									<b>
										{(consultarMutuario.data.dtGrifo &&
											formatDateToString(
												new Date(consultarMutuario.data.dtGrifo),
											)) ||
											''}
									</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Quantidade de Parcelas PRD:</Col>

								<Col>
									<b>{qtdParcPrd}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Quantidade de Prestações em atraso:</Col>

								<Col>
									<b>{qtdParcAtraso}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Valor Total das Parcelas PRD:</Col>

								<Col>
									<b>{formatCurrency(valorParcPrd)}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Valor Total das Prestações em atraso:</Col>

								<Col>
									<b>{formatCurrency(valorParcAtraso)}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={6}>Acordo Vigente:</Col>

								<Col>
									<b>{consultarMutuario.data.acordo}</b>
								</Col>
							</Row>

							<Row>
								<Col span={6}>Tipo de Financiamento:</Col>

								<Col>
									<b>{consultarMutuario.data.tipoFinanciamento}</b>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			)}
		</>
	);
};

export default SituacaoContrato;
