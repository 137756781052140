import { IAlterarObservacoesState } from './alterarObservacoes/types';
import { ICadastroRgState } from './cadastroRg/types';
import { IConsultaPesquisaCidadaoState } from './consultaPesquisaCidadao/types';
import { ConsultaPesquisaCirgState } from './consultaPesquisaCirg/types';
import { IConsultaPesquisaCriminalState } from './consultaPesquisaCriminal/types';
import { IConsultaPesquisaObservacaoState } from './consultaPesquisaObservacao/types';
import { ConsultaReimpressaoFicState } from './consultaReimpressaoFic/types';
import { IEnviarSmsState } from './enviarSms/types';
import { IProtocoloFicState } from './protocoloFic/types';
import { IProtocoloFicDigitalState } from './protocoloFicDigital/types';
import { IProtocoloReusoState } from './protocoloReuso/types';
import { ConsultaCpfState } from './pesquisaCicp/types';
import { DadosEmissaoCINState } from './consultaCIN/types';
import { CiliSimplificadaState } from './ciliSimplificada/types';

import AutorizacaoEspecial from './autorizacaoEspecial/types';
import { ContabilizacaoIirgd } from './contabilizacaoIirgd/types';
import { ContabilizacaoIirgd as ContabilizacaoEmissaoCarteira } from './contabilizacaoEmissaoCarteira/types';

import { ConsultaPesquisaCivil } from './consultaPesquisaCivil/types';
import { ScriptPreAtendimento } from './scriptPreAtendimento/types';
import { Logon } from './logon/types';
import { AlteraSenha } from './alteraSenha/types';
import { ConsultaDBCIN } from './consultaDBCIN/types';
import { LibracaoCiliState } from './liberacaoCili/types';

import { IirgdIntegradorState } from './integrador/types';

export enum Types {
	IIRGD_CLEAR = '@iirgd/IIRGD_CLEAR',
}

export interface IirgdState {
	alterarObservacoes: IAlterarObservacoesState;
	cadastroRg: ICadastroRgState;
	consultaPesquisaCidadao: IConsultaPesquisaCidadaoState;
	consultaPesquisaCirg: ConsultaPesquisaCirgState;
	consultaPesquisaCriminal: IConsultaPesquisaCriminalState;
	consultaPesquisaObservacao: IConsultaPesquisaObservacaoState;
	consultaReimpressaoFic: ConsultaReimpressaoFicState;
	enviarSms: IEnviarSmsState;
	protocoloFic: IProtocoloFicState;
	protocoloFicDigital: IProtocoloFicDigitalState;
	protocoloReuso: IProtocoloReusoState;
	autorizacaoEspecial: AutorizacaoEspecial;
	contabilizacaoIirgd: ContabilizacaoIirgd;
	contabilizacaoEmissaoCarteira: ContabilizacaoEmissaoCarteira;
	consultaPesquisaCivil: ConsultaPesquisaCivil;
	scriptPreAtendimento: ScriptPreAtendimento;
	logon: Logon;
	alteraSenha: AlteraSenha;
	pesquisaCicp: ConsultaCpfState;
	consultaCIN: DadosEmissaoCINState;
	ciliSimplificada: CiliSimplificadaState;
	consultaDBCIN: ConsultaDBCIN;
	integrador: IirgdIntegradorState;
	liberacaoCili: LibracaoCiliState;
}
