import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AtualizarParametroSistemaRedisRequest } from './types';

// ACTIONS
import {
	atualizarParametroSistemaRedisSuccess,
	atualizarParametroSistemaRedisFailure,
} from './actions';

function* atualizarParametroSistemaRedis(request: ReducerAction) {
	const { payload }: { payload: AtualizarParametroSistemaRedisRequest } =
		request;

	const body = { valor: payload.valor };

	const response: ApiResponse = yield call(
		putApi,
		PATH_SGU,
		`parametros-sistema-redis/${payload.chave}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarParametroSistemaRedisSuccess(response));
	} else {
		yield put(atualizarParametroSistemaRedisFailure());
	}
}

export default all([
	takeLatest(
		Types.ATUALIZAR_PARAMETRO_SISTEMA_REDIS,
		atualizarParametroSistemaRedis,
	),
]);
