import React, { Suspense, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import ButtonImage from 'components/Common/ButtonImage';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import PDFViewer from 'components/Common/PDFViewer';
import { impressaoLicenciamentoVeiculosClear } from 'store/modules/api/detranCrv/Processos/ImpressaoLicenciamentoVeiculos/actions';
import ButtonVoltar from 'components/Common/ButtonVoltar';

const ImpressaoLicenciamentoVeiculosResultado: React.FC = () => {
	const dispatch = useDispatch();

	const data = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.processos.impressaoLicenciamentoVeiculos.data,
	);

	const [showPrint, setShowPrint] = useState<boolean>(false);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Impressão Licenciamento de Veículos">
				<Row gutter={[0, 10]}>
					<Col span={2} />
					<Col span={4}>Número da Ficha:</Col>
					<Col span={18}>
						<strong>{data?.ficha.numeroFicha}</strong>
					</Col>
					<Col span={2} />
					<Col span={4}>Ano da Ficha:</Col>
					<Col span={18}>
						<strong>{data?.ficha.anoFicha}</strong>
					</Col>
				</Row>
				<Row justify="end" align="top" gutter={[20, 10]}>
					<ButtonImage src="imprimir" onClick={() => setShowPrint(true)} />
				</Row>
			</Section>
			<ButtonVoltar
				onClick={() => {
					dispatch(impressaoLicenciamentoVeiculosClear());
				}}
			/>
			{showPrint && (
				<PDFViewer
					title="Impressão Licenciamento de Veículo"
					source={data?.ficha.fichaCadastral ? data?.ficha.fichaCadastral : ''}
					onClose={() => setShowPrint(false)}
				/>
			)}
		</Suspense>
	);
};

export default ImpressaoLicenciamentoVeiculosResultado;
