import { combineReducers } from 'redux';

import dominios from './dominios/reducer';
import ensino from './ensino/reducer';
import eixo from './eixo/reducer';
import escolasProximas from './escolasProximas/reducer';
import comboUf from './uf/reducer';
import dadosBasicos from './dadosBasicos/reducer';
import tiposEnsino from './tiposEnsino/reducer';

export default combineReducers({
	dominios,
	ensino,
	eixo,
	escolasProximas,
	comboUf,
	dadosBasicos,
	tiposEnsino,
});
