import produce from 'immer';

import { ReducerAction } from 'store/modules/types';

import { Types, PEBE } from './types';

export const INITIAL_STATE: PEBE = {
	status: 0,
	mensagem: '',
	data: {
		tipoCliente: 0,
		qtdeOcorrencias: '',
		placa: '',
		renavam: '',
		uf: '',
		situacaoVeiculo: '',
		lotacao: 0,
		anoFabricacao: 0,
		anoModelo: 0,
		numMotor: '',
		procedencia: '',
		chassi: '',
		chassiRemarcado: {
			codigo: '',
			descricao: '',
		},
		marca: {
			codigo: '',
			descricao: '',
		},
		cor: {
			codigo: '',
			descricao: '',
		},
		combustivel: {
			codigo: '',
			descricao: '',
		},
		municipio: {
			codigo: '',
			descricao: '',
		},
		restricoes: [
			{
				restricao: '',
			},
		],
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): PEBE {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PEBE_REQUEST: {
				break;
			}

			case Types.PEBE_SUCCESS: {
				draft.status = action.payload.status;

				draft.data = action.payload.data;

				break;
			}

			case Types.PEBE_FAILURE: {
				const { status, data, message } = action.payload;

				draft.status = status || data.status;

				draft.data = data;

				draft.mensagem = `${data.data.codigo} - ${message}`;

				break;
			}

			case Types.PEBE_CLEAR: {
				draft.status = 0;

				draft.data = INITIAL_STATE.data;

				break;
			}

			default:
		}

		return draft;
	});
}
