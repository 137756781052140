import produce from 'immer';

import { Types, DadosPreCadastroFormSave } from './types';
import { ReducerAction } from '../../../../types';

// UTILS

export const INITIAL_STATE: DadosPreCadastroFormSave = {
	dataPreCadastro: null,
	dataPreCadastroHabilitacao: null,
	dataPreCadastroPrimeiraHabilitacao: null,
	dataPreCadastroCnhEstrangeiro: null,
	dataPreCadastroHabilitacaoCnhEstrangeiro: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): DadosPreCadastroFormSave {
	return produce(state, draft => {
		switch (action.type) {
			case Types.DADOS_PRE_CADASTRO_FORM_SAVE_REQUEST: {
				break;
			}
			case Types.DADOS_PRE_CADASTRO_FORM_SAVE_SUCCESS: {
				if (action.payload.dataPreCadastroPrimeiraHabilitacao?.cpf) {
					draft.dataPreCadastroPrimeiraHabilitacao =
						action.payload.dataPreCadastroPrimeiraHabilitacao;
					draft.dataPreCadastroCnhEstrangeiro =
						INITIAL_STATE.dataPreCadastroCnhEstrangeiro;
					draft.dataPreCadastroHabilitacaoCnhEstrangeiro =
						INITIAL_STATE.dataPreCadastroHabilitacaoCnhEstrangeiro;
					draft.dataPreCadastro = INITIAL_STATE.dataPreCadastro;
					draft.dataPreCadastroHabilitacao =
						INITIAL_STATE.dataPreCadastroHabilitacao;
				} else if (action.payload.dataPreCadastroCnhEstrangeiro?.cpf) {
					draft.dataPreCadastroCnhEstrangeiro =
						action.payload.dataPreCadastroCnhEstrangeiro;
					draft.dataPreCadastroHabilitacaoCnhEstrangeiro =
						action.payload.dataPreCadastroHabilitacaoCnhEstrangeiro;
					draft.dataPreCadastro = INITIAL_STATE.dataPreCadastro;
					draft.dataPreCadastroHabilitacao =
						INITIAL_STATE.dataPreCadastroHabilitacao;
					draft.dataPreCadastroPrimeiraHabilitacao =
						INITIAL_STATE.dataPreCadastroPrimeiraHabilitacao;
				} else {
					draft.dataPreCadastroPrimeiraHabilitacao =
						INITIAL_STATE.dataPreCadastroPrimeiraHabilitacao;
					draft.dataPreCadastroCnhEstrangeiro =
						INITIAL_STATE.dataPreCadastroCnhEstrangeiro;
					draft.dataPreCadastroHabilitacaoCnhEstrangeiro =
						INITIAL_STATE.dataPreCadastroHabilitacaoCnhEstrangeiro;
					draft.dataPreCadastro = action.payload.dataPreCadastro;
					draft.dataPreCadastroHabilitacao =
						action.payload.dataPreCadastroHabilitacao;
				}
				break;
			}
			case Types.DADOS_PRE_CADASTRO_FORM_SAVE_CLEAR: {
				draft.dataPreCadastro = INITIAL_STATE.dataPreCadastro;
				draft.dataPreCadastroHabilitacao =
					INITIAL_STATE.dataPreCadastroHabilitacao;
				draft.dataPreCadastroPrimeiraHabilitacao =
					INITIAL_STATE.dataPreCadastroPrimeiraHabilitacao;
				draft.dataPreCadastroCnhEstrangeiro =
					INITIAL_STATE.dataPreCadastroCnhEstrangeiro;
				draft.dataPreCadastroHabilitacaoCnhEstrangeiro =
					INITIAL_STATE.dataPreCadastroHabilitacaoCnhEstrangeiro;
				break;
			}
			default:
		}
	});
}
