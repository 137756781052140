import { ReducerAction } from 'store/modules/types';
import { ConsultarOrgaosRequest, Types } from './types';

export function consultarOrgaoRequest(
	payload: ConsultarOrgaosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_ORGAOS_REQUEST,
		payload,
	};
}
export function consultarOrgaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_ORGAOS_SUCCESS,
		payload,
	};
}
export function consultarOrgaoFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_ORGAOS_FAILURE,
		payload: null,
	};
}
export function consultarOrgaoClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_ORGAOS_CLEAR,
		payload: null,
	};
}
