import { ReducerActionRotaSP } from 'store/modules/types';

import { EnviarSolicitacoesPortalRequest, Types } from './types';

export function enviarSolicitacoesPortalRequest(
	token: string,
	data: EnviarSolicitacoesPortalRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ENVIAR_SOLICITACOES_PORTAL_REQUEST,
		token,
		payload: data,
	};
}
export function enviarSolicitacoesPortalSuccess(
	payload: any,
): ReducerActionRotaSP {
	return {
		type: Types.ENVIAR_SOLICITACOES_PORTAL_SUCCESS,
		payload,
	};
}
export function enviarSolicitacoesPortalFailure(
	payload: any,
): ReducerActionRotaSP {
	return {
		type: Types.ENVIAR_SOLICITACOES_PORTAL_FAILURE,
		payload,
	};
}

export function enviarSolicitacoesPortalClear(): { type: string } {
	return {
		type: Types.ENVIAR_SOLICITACOES_PORTAL_CLEAR,
	};
}
