import React, {
	Suspense,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';

import { useHistory } from 'react-router-dom';

// ANTD
import { Col, Row, Skeleton } from 'antd';

// COMPONENTS COMMON
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Placa from 'components/Common/Form/Fields/Placa';
import Money from 'components/Common/Form/Fields/Money';
import Select from 'components/Common/Form/Select';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';

// COMPONENTS
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import data from 'store/modules/api/detranCrv/enum';
import { IncluirIntencaoVendaRequest } from 'store/modules/api/detranCrv/IntencaoDeVenda/Incluir/types';
import { incluirIntencaoVendaRequest } from 'store/modules/api/detranCrv/IntencaoDeVenda/Incluir/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import {
	consultarMunicipioCodigoProdesClear,
	consultarMunicipioCodigoProdespRequest,
} from 'store/modules/api/detranCrv/enum/consultarMunicipioCodigoProdesp/actions';

// PATHS
import { ROUTE_DETRAN_CRV } from 'routes/paths';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import {
	onlyLetters,
	onlyNumbers,
	onlyNumbersLettersSpace,
	removeAcentoCaracteresEspeciais,
} from 'utils/genericFunctions';

// FORM
import { Field, Form, Formik, FormikProps } from 'formik';
import {
	formataObjetoIncluir,
	initialValues,
	schema,
	treatValuesCodigoProdesp,
} from './form';

const IncluirIntencaoVenda: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const [numeroProp, setNumeroProp] = useState<string>('');
	const [numeroComp, setNumeroComp] = useState<string>('');
	const [codigoMunicipioDetran, setCodigoMunicipioDetran] = useState<
		number | string
	>('');

	const handleCpfCnpj = (
		value: string,
		seter: React.Dispatch<React.SetStateAction<string>>,
		name: string,
		formik: FormikProps<any>,
	) => {
		let digits = value.replace(/\D/g, '');
		if (digits.length <= 11) {
			digits = digits.replace(/(\d{3})(\d)/, '$1.$2');
			digits = digits.replace(/(\d{3})(\d)/, '$1.$2');
			digits = digits.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
			seter(digits);
			formik.setFieldValue(name, digits.replace(/\D/g, ''));
		} else if (digits.length <= 14) {
			digits = digits.replace(/^(\d{2})(\d)/, '$1.$2');
			digits = digits.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
			digits = digits.replace(/\.(\d{3})(\d)/, '.$1/$2');
			digits = digits.replace(/(\d{4})(\d)/, '$1-$2');
			seter(digits);
			formik.setFieldValue(name, digits.replace(/\D/g, ''));
		}
	};

	const { atendimento, loginUnico } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { incluirIntencaoVenda } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.intencaoVenda,
	);

	const { consultarMunicipioCodigoProdesp } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.Enum,
	);

	const { token } = useContext<IAuthContext>(AuthContext);

	const handleClear = useCallback(
		(formik: FormikProps<IncluirIntencaoVendaRequest>) => {
			dispatch(clearNotifications());
			formik.handleReset();
		},
		[dispatch],
	);

	useEffect(() => {
		if (
			codigoMunicipioDetran.toString() !== '' &&
			consultarMunicipioCodigoProdesp.status === 0
		) {
			const treatValuesEvento = treatValuesCodigoProdesp(
				atendimento,
				loginUnico,
				codigoMunicipioDetran,
			);

			dispatch(consultarMunicipioCodigoProdespRequest(treatValuesEvento));
		}
	}, [
		codigoMunicipioDetran,
		atendimento,
		loginUnico,
		dispatch,
		consultarMunicipioCodigoProdesp,
	]);

	useEffect(() => {
		if (
			incluirIntencaoVenda.status === 200 ||
			incluirIntencaoVenda.status === 201
		) {
			history.push('/detran-crv/incluir-intencao-venda/resultado');
		}
	}, [incluirIntencaoVenda, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Incluir ATPV-e">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						const body: IncluirIntencaoVendaRequest = formataObjetoIncluir(
							values,
							consultarMunicipioCodigoProdesp.data?.codigoPRODESP || 0,
						);

						dispatch(incluirIntencaoVendaRequest(token, body));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											required
											name="veiculoRenavam"
											title="Renavam"
											as={InputMask}
											mask="99999999999"
											maskChar=""
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue('veiculoRenavam', v.target.value);
											}}
											error={hasError(formik.errors, 'veiculoRenavam')}
										/>
									</Col>
									<Col span={12}>
										<Placa
											title="Placa"
											titleSize="lg"
											name="veiculoPlaca"
											size={80}
											required
											formik={formik}
										/>
									</Col>
								</Row>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											required
											name="veiculoChassi"
											title="Chassi"
											as={InputMask}
											mask="**********************"
											maskChar=""
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue('veiculoChassi', v.target.value);
											}}
											error={hasError(formik.errors, 'veiculoChassi')}
										/>
									</Col>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											required
											name="veiculoHodometro"
											title="Hodômetro"
											as={InputMask}
											mask="9999999"
											maskChar=""
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'veiculoHodometro',
													v.target.value,
												);
											}}
											error={hasError(formik.errors, 'veiculoHodometro')}
										/>
									</Col>
								</Row>{' '}
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											required
											maxLength={50}
											name="proprietarioVendedorEmail"
											title="Email Proprietário"
											as={Input}
											error={hasError(
												formik.errors,
												'proprietarioVendedorEmail',
											)}
										/>
									</Col>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											value={numeroProp}
											required
											name="proprietarioVendedorDocumentoNumeroIdentificacao"
											title="CPF/CNPJ Proprietário"
											as={Input}
											onChange={(e: { target: { value: string } }) => {
												handleCpfCnpj(
													e.target.value,
													setNumeroProp,
													'proprietarioVendedorDocumentoNumeroIdentificacao',
													formik,
												);
											}}
											error={hasError(
												formik.errors,
												'proprietarioVendedorDocumentoNumeroIdentificacao',
											)}
										/>
									</Col>
								</Row>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											value={numeroComp}
											required
											name="compradorDocumentoNumeroIdentificacao"
											title="CPF/CNPJ Comprador"
											as={Input}
											onChange={(e: { target: { value: string } }) => {
												handleCpfCnpj(
													e.target.value,
													setNumeroComp,
													'compradorDocumentoNumeroIdentificacao',
													formik,
												);
											}}
											error={hasError(
												formik.errors,
												'compradorDocumentoNumeroIdentificacao',
											)}
										/>
									</Col>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											required
											maxLength={100}
											name="compradorNome"
											title="Nome Comprador"
											as={Input}
											onChange={(e: { target: { value: string } }) => {
												formik.setFieldValue(
													'compradorNome',
													onlyLetters(e.target.value.toLocaleUpperCase()),
												);
											}}
											error={hasError(formik.errors, 'compradorNome')}
										/>
									</Col>
								</Row>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											required
											maxLength={50}
											name="compradorEmail"
											title="Email Comprador"
											as={Input}
											error={hasError(formik.errors, 'compradorEmail')}
										/>
									</Col>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											required
											maxLength={15}
											name="compradorValorVenda"
											title="Valor Venda"
											as={Money}
											formik={formik}
											error={hasError(formik.errors, 'compradorValorVenda')}
										/>
									</Col>
								</Row>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<CEP
											title="CEP Comprador"
											titleSize="lg"
											name="compradorCep"
											defaultValue={String(formik.values.cepProprietario)}
											required
											size={80}
											formik={formik}
											result={(res: IBuscarCepResultado) => {
												const { codigoMunicipioProdesp } = res;
												setCodigoMunicipioDetran(codigoMunicipioProdesp || '');
												dispatch(consultarMunicipioCodigoProdesClear());
												formik.setFieldValue('compradorCep', res.cep);
												formik.setFieldValue(
													'compradorBairro',
													removeAcentoCaracteresEspeciais(
														res.bairro.substring(0, 70),
													),
												);
												formik.setFieldValue(
													'compradorCodigoBairro',
													res.codigoBairro,
												);
												formik.setFieldValue(
													'compradorLogradouro',
													removeAcentoCaracteresEspeciais(
														res.endereco.substring(0, 70),
													),
												);
												if (res.codigoMunicipio) {
													formik.setFieldValue(
														'compradorDescricaoMunicipio',
														removeAcentoCaracteresEspeciais(
															res.municipio.toString().substring(0, 70),
															// res.codigoMunicipio.toString().substring(0, 70),
														),
													);
													formik.setFieldValue(
														'compradorCodigoMunicipio',
														res.codigoMunicipioProdesp,
													);
													formik.setFieldValue(
														'compradorUf',
														res.uf.substring(0, 2),
													);
												}
											}}
										/>
									</Col>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											required
											maxLength={50}
											name="compradorDescricaoMunicipio"
											title="Município Comprador"
											as={Input}
											onChange={(e: { target: { value: string } }) => {
												formik.setFieldValue(
													'compradorDescricaoMunicipio',
													onlyLetters(e.target.value.toLocaleUpperCase()),
												);
											}}
											error={hasError(
												formik.errors,
												'compradorDescricaoMunicipio',
											)}
										/>
									</Col>
								</Row>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											required
											maxLength={50}
											name="compradorBairro"
											title="Bairro Comprador"
											as={Input}
											onChange={(e: { target: { value: string } }) => {
												formik.setFieldValue(
													'compradorBairro',
													onlyLetters(e.target.value.toLocaleUpperCase()),
												);
											}}
											error={hasError(formik.errors, 'compradorBairro')}
										/>
									</Col>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											required
											maxLength={50}
											name="compradorLogradouro"
											title="Logradouro Comprador"
											as={Input}
											onChange={(e: { target: { value: string } }) => {
												formik.setFieldValue(
													'compradorLogradouro',
													onlyLetters(e.target.value.toLocaleUpperCase()),
												);
											}}
											error={hasError(formik.errors, 'compradorLogradouro')}
										/>
									</Col>
								</Row>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											required
											maxLength={22}
											name="compradorNumero"
											title="Número"
											as={Input}
											onChange={(e: { target: { value: string } }) => {
												formik.setFieldValue(
													'compradorNumero',
													onlyNumbers(e.target.value.toLocaleUpperCase()),
												);
											}}
											error={hasError(formik.errors, 'compradorNumero')}
										/>
									</Col>
									<Col span={12}>
										<Field
											as={Select}
											size={80}
											titleSize="lg"
											title="UF"
											required
											name="compradorUf"
											options={data.uf}
											onChange={(value: string) =>
												formik.setFieldValue('compradorUf', value)
											}
											error={hasError(formik.errors, 'compradorUf')}
										/>
									</Col>
								</Row>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											size={80}
											titleSize="lg"
											maxLength={15}
											name="compradorComplemento"
											title="Complemento"
											as={Input}
											onChange={(e: { target: { value: string } }) => {
												formik.setFieldValue(
													'compradorComplemento',
													onlyNumbersLettersSpace(
														e.target.value.toLocaleUpperCase(),
													),
												);
											}}
											error={hasError(formik.errors, 'compradorComplemento')}
										/>
									</Col>
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="btn-cancelar-check"
											onClick={() => handleClear(formik)}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="btn-incluir-check" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</Suspense>
	);
};

export default IncluirIntencaoVenda;
