import React, { ChangeEvent, useState, useEffect } from 'react';

import { Col, Modal, Row } from 'antd';
import { Field, Form, Formik } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import Radio from 'components/Common/Form/Radio';
import Error from 'components/Common/Notifications/FormError/index';
import CheckBox from 'components/Common/Form/Checkbox';
import Select from 'components/Common/Form/Select';
import ValidDataInput from 'components/Common/Form/Input/ValidData';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// STYLED

// FORM
import { ScriptPreAtendimentoForm } from 'store/modules/api/iirgd/scriptPreAtendimento/types';
import LightButton from 'components/Common/Buttons/Light';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { scriptPreAtendimentoRequest } from 'store/modules/api/iirgd/scriptPreAtendimento/actions';
import { ROUTE_IIRGD_PESQUISAR_RG } from 'pages/iirgd/RgFicDigital/routes/paths';
import { consultaPesquisaCidadaoClear } from 'store/modules/api/iirgd/consultaPesquisaCidadao/actions';
import {
	consultaPesquisaCirgClear,
	consultaPesquisaCirgCidadaoClear,
} from 'store/modules/api/iirgd/consultaPesquisaCirg/actions';
import { consultarCpfClear } from 'store/modules/api/rfb/consultarCpf/actions';
import { useHistory } from 'react-router-dom';
import RG from 'components/Common/Form/Fields/RG';
import { maskRgX } from 'utils/stringFunctions';
import { onlyAlfaNumerico } from 'utils/genericFunctions';
import Input from 'components/Common/Form/Input/Input';
import {
	scriptPreAtendimentoInitialValues,
	scriptPreAtendimentoSchema,
} from './form';

interface Props {
	isVisible: boolean;
	onClose: () => void;
}

const ScriptPreAtendimentoModal: React.FC<Props> = ({ isVisible, onClose }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { scriptPreAtendimento } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const [
		formScriptPreAtendimentoInitialValues,
		setFormScriptPreAtendimentoInitialValues,
	] = useState<ScriptPreAtendimentoForm>(scriptPreAtendimentoInitialValues);

	const handleClose = () => {
		onClose();
		dispatch(clearNotifications());
	};

	const handleSubmit = (values: ScriptPreAtendimentoForm) => {
		if (values.ondeMorava === 'São Paulo' && !values.rgOuAcervo) {
			Modal.confirm({
				okText: 'Pesquisar',
				cancelText: 'Cancelar',
				title: 'Atenção!',
				okButtonProps: { color: '#FFFFFF !important' },
				centered: true,
				content: (
					<>
						<div>
							Estado selecionado não se trata de uma 1ª via de RG! Informar o Nº
							do RG ou os dados corretos para que seja realizado uma nova
							pesquisa.
						</div>
					</>
				),
				onOk() {
					dispatch(clearNotifications());
					dispatch(consultaPesquisaCidadaoClear());
					dispatch(consultaPesquisaCirgClear());
					dispatch(consultarCpfClear());
					dispatch(consultaPesquisaCirgCidadaoClear());
					handleClose();
					history.push(ROUTE_IIRGD_PESQUISAR_RG);
				},
			});
		} else {
			if (values.digitoRg) {
				values.numeroRg =
					maskRgX(values.numeroRg.concat(values.digitoRg)) || '';
			}
			delete values.digitoRg;
			dispatch(
				scriptPreAtendimentoRequest({ ...values, preAtendimentoValido: true }),
			);
			handleClose();
		}
	};

	useEffect(() => {
		if (scriptPreAtendimento.teveRg !== null) {
			setFormScriptPreAtendimentoInitialValues(scriptPreAtendimento);
		}
	}, [scriptPreAtendimento]);

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={false}
			initialValues={formScriptPreAtendimentoInitialValues}
			validate={values =>
				getValidationsErrors(values, scriptPreAtendimentoSchema)
			}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{formik => (
				<Modal
					onCancel={() => handleClose()}
					title="Perguntas a serem analisadas pelo Atendente"
					visible={isVisible}
					width={900}
					footer={null}
					style={{ animationDuration: '0s' }}
					destroyOnClose
					closable
				>
					{notifications.errors &&
						notifications.errors.length > 0 &&
						notifications.showInside && (
							<>
								<Error errors={notifications.errors} />
								<br />
							</>
						)}
					<Form autoComplete="off">
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Field
									as={Radio}
									directionRow
									customWidth="fit-content"
									titleSize={0}
									titleQuestion
									title="1) Já teve RGE/CIN"
									name="teveRg"
									noTextTransform
									required
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue('teveRg', e.target.value);
										if (notifications.errors) {
											dispatch(clearNotifications());
											formik.setErrors({});
										}
										if (!e.target.value) {
											formik.setFieldValue('ondeMorava', null);
											formik.setFieldValue('possuiDocumento', null);
											formik.setFieldValue('ufRg', null);
											formik.setFieldValue('numeroRg', null);
											formik.setFieldValue('dataExpedicaoRg', null);
											formik.setFieldValue('teveAlteracao', null);
											formik.setFieldValue('adocao', null);
											formik.setFieldValue('tranGenero', null);
											formik.setFieldValue('paternidadeReconhecida', null);
											formik.setFieldValue('outros', null);
										}
									}}
									options={data.simNaoBoolean}
									error={formik.errors.teveRg}
								/>
							</Col>
							<Col span={24}>
								<Field
									as={Radio}
									directionRow
									customWidth="fit-content"
									titleQuestion
									title="2) Morava aonde quando tirou o RGE/CIN"
									name="ondeMorava"
									noTextTransform
									error={formik.errors.ondeMorava}
									disabled={!formik.values.teveRg}
									required={formik.values.teveRg}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue('ondeMorava', e.target.value);
										if (notifications.errors) {
											dispatch(clearNotifications());
											formik.setErrors({});
										}
									}}
									options={[
										{
											value: 'São Paulo',
											label: 'São Paulo',
										},
										{
											value: 'Outro Estado',
											label: 'Outro Estado',
										},
									]}
								/>
							</Col>
							<Col span={24}>
								<Field
									as={Radio}
									titleQuestion
									directionRow
									customWidth="fit-content"
									titleSize="sm"
									title="3) Está com o RGE/CIN"
									name="possuiDocumento"
									noTextTransform
									options={data.simNaoBoolean}
									error={formik.errors.possuiDocumento}
									disabled={!formik.values.teveRg || !formik.values.ondeMorava}
									required={formik.values.ondeMorava}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue('possuiDocumento', e.target.value);
										if (notifications.errors) {
											dispatch(clearNotifications());
											formik.setErrors({});
										}
										if (!e.target.value) {
											formik.setFieldValue('ufRg', null);
											formik.setFieldValue('numeroRg', null);
											formik.setFieldValue('dataExpedicaoRg', '');
										}
									}}
								/>
							</Col>
							<Col span={8}>
								<Field
									as={Select}
									name="ufRg"
									titleAlign="start"
									titleSize={130}
									onChange={(v: string) => {
										formik.setFieldValue('ufRg', v);
										if (notifications.errors) {
											dispatch(clearNotifications());
											formik.setErrors({});
										}
									}}
									title="RGE/CIN no Estado de"
									options={data.uf}
									error={!!formik.errors.ufRg}
									disabled={
										!formik.values.teveRg || !formik.values.possuiDocumento
									}
									required={formik.values.possuiDocumento}
								/>
							</Col>
							<Col span={16} />
							<Col span={8}>
								{formik.values.ufRg === 'SP' ? (
									<RG
										title="Número RGE/CIN"
										titleAlign="start"
										titleSize={130}
										numero="numeroRg"
										digito="digitoRg"
										defaultNumero={formik.values.numeroRg}
										defaultDigito={formik.values.digitoRg}
										formik={formik}
										valueNumero={formik.values.numeroRg}
										valueDigito={formik.values.digitoRg}
										required={formik.values.possuiDocumento === true}
										error={!!formik.errors.numeroRg}
										disabled={
											!formik.values.teveRg || !formik.values.possuiDocumento
										}
										result={rg => {
											formik.setFieldValue('numeroRg', rg.numeroRg);
											formik.setFieldValue('digitoRg', rg.digitoRg);

											if (rg.numeroRg === '' && rg.digitoRg === '') {
												dispatch(clearNotifications());
											}
										}}
										autoFocus
									/>
								) : (
									<Field
										as={Input}
										title="Número RGE/CIN"
										titleAlign="start"
										titleSize={130}
										name="numeroRg"
										onChange={(v: ChangeEvent<HTMLInputElement>) => {
											formik.setFieldValue(
												'numeroRg',
												onlyAlfaNumerico(v.currentTarget.value) || '',
											);
										}}
										mask="99.999.999-9"
										maxLength={15}
										required={formik.values.possuiDocumento}
										error={!!formik.errors.numeroRg}
										disabled={
											!formik.values.teveRg || !formik.values.possuiDocumento
										}
									/>
								)}
							</Col>
							<Col span={16} />
							<Col span={8}>
								<Field
									as={ValidDataInput}
									titleAlign="start"
									titleSize={130}
									title="Expedido em"
									name="dataExpedicaoRg"
									type="text"
									mask="99/99/9999"
									maskChar=""
									required={formik.values.possuiDocumento}
									formik={formik}
									error={!!formik.errors.dataExpedicaoRg}
									disabled={
										!formik.values.teveRg || !formik.values.possuiDocumento
									}
								/>
							</Col>
							<Col span={16} />
							<Col span={24}>
								<Field
									as={Radio}
									directionRow
									customWidth="fit-content"
									titleSize={0}
									titleQuestion
									title="4) RGE/CIN não cadastrado"
									name="rgOuAcervo"
									noTextTransform
									disabled={
										!formik.values.teveRg ||
										formik.values.ondeMorava === 'Outro Estado' ||
										!formik.values.possuiDocumento
									}
									required={
										formik.values.teveRg &&
										formik.values.ondeMorava === 'São Paulo' &&
										formik.values.possuiDocumento
									}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue('rgOuAcervo', e.target.value);
										if (notifications.errors) {
											dispatch(clearNotifications());
											formik.setErrors({});
										}
									}}
									options={data.simNaoBoolean}
									error={formik.errors.rgOuAcervo}
								/>
							</Col>
							<Col span={24}>
								<Field
									as={Radio}
									titleQuestion
									directionRow
									customWidth="fit-content"
									required={formik.values.ondeMorava === 'Outro Estado'}
									title="5) Teve alteração no seu nome, nome dos pais ou na data de nascimento"
									name="teveAlteracao"
									noTextTransform
									options={data.simNaoBoolean}
									disabled={
										!formik.values.teveRg ||
										formik.values.ondeMorava !== 'Outro Estado'
									}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue('teveAlteracao', e.target.value);
										if (notifications.errors) {
											dispatch(clearNotifications());
											formik.setErrors({});
										}
										if (!e.target.value) {
											formik.setFieldValue('adocao', null);
											formik.setFieldValue('tranGenero', null);
											formik.setFieldValue('paternidadeReconhecida', null);
											formik.setFieldValue('outros', null);
										}
									}}
									error={!!formik.errors.teveAlteracao}
								/>
							</Col>
							<Col span={24}>
								<Field
									as={CheckBox}
									name="adocao"
									error={!!formik.errors.adocao}
									subtitle="Indício de adoção"
									checked={formik.values.adocao}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue('adocao', e.target.checked);
										if (notifications.errors) {
											dispatch(clearNotifications());
											formik.setErrors({});
										}
									}}
									disabled={!formik.values.teveAlteracao}
								/>
								<Field
									as={CheckBox}
									name="tranGenero"
									error={!!formik.errors.tranGenero}
									subtitle="Trans-Gênero"
									checked={formik.values.tranGenero}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue('tranGenero', e.target.checked);
										if (notifications.errors) {
											dispatch(clearNotifications());
											formik.setErrors({});
										}
									}}
									disabled={!formik.values.teveAlteracao}
								/>
								<Field
									as={CheckBox}
									name="paternidadeReconhecida"
									error={!!formik.errors.paternidadeReconhecida}
									subtitle="Reconhecimento de Paternidade / Maternidade"
									checked={formik.values.paternidadeReconhecida}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'paternidadeReconhecida',
											e.target.checked,
										);
										if (notifications.errors) {
											dispatch(clearNotifications());
											formik.setErrors({});
										}
									}}
									disabled={!formik.values.teveAlteracao}
								/>
								<Field
									as={CheckBox}
									name="outros"
									error={!!formik.errors.outros}
									subtitle="Outros"
									checked={formik.values.outros}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue('outros', e.target.checked);
										if (notifications.errors) {
											dispatch(clearNotifications());
											formik.setErrors({});
										}
									}}
									disabled={!formik.values.teveAlteracao}
								/>
							</Col>
						</Row>
						<Row justify="end" gutter={[20, 0]}>
							<Col>
								<LightButton type="submit" text="Salvar" />
							</Col>
						</Row>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

export default ScriptPreAtendimentoModal;
