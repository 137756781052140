import produce from 'immer';

// TYPES
import { toSelect } from 'utils/genericFunctions';
import { Types, ConsultaMunicipios } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultaMunicipios = {
	status: 0,
	data: null,
};

export default function consultaMunicipiosReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaMunicipios {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_MUNICIPIOS_REQUEST: {
				break;
			}

			case Types.CONSULTA_MUNICIPIOS_SUCCESS: {
				draft.status = action.payload.status;
				const lista = toSelect(
					action.payload.data.municipios,
					'nome',
					'codigo',
				);

				draft.data = [...lista];
				break;
			}

			case Types.CONSULTA_MUNICIPIOS_FAILURE: {
				break;
			}

			case Types.CONSULTA_MUNICIPIOS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
