import React from 'react';

// ANTD
import { Collapse } from 'antd';

/**
 * Exemplo de como passar as propriedades
 * const expandIconPosition: ExpandIconPosition = 'left';
 * const defaultActiveKeys: string[] = ['key'];
 * const contents = [
		{
			key: 'key',
			title: 'title',
			content: <></>,
		}
	];
 */

type ExpandIconPosition = 'left' | 'right' | undefined;

type CollapseContent = {
	key: string;
	title: string;
	content: React.ReactNode;
};

interface Props {
	expandIconPosition?: ExpandIconPosition;
	defaultActiveKeys?: string[];
	contents: {
		key: string;
		title: string;
		content: React.ReactNode;
	}[];
	extraOptions?: React.ReactNode;
	accordion?: boolean;
	onChange?: (key: string | string[]) => void;
}

const CollapseCommon: React.FC<Props> = ({
	expandIconPosition = 'left',
	defaultActiveKeys,
	contents,
	extraOptions,
	accordion = false,
	onChange,
}) => {
	const { Panel } = Collapse;

	return (
		<>
			<Collapse
				defaultActiveKey={defaultActiveKeys}
				onChange={onChange}
				expandIconPosition={expandIconPosition}
				accordion={accordion}
			>
				{contents &&
					contents.map((item: CollapseContent) => (
						<Panel
							key={item.key}
							header={
								item.content ? (
									item.title
								) : (
									<span>
										{item.title} <em> (Não há registro)</em>
									</span>
								)
							}
							extra={item.content && extraOptions}
							showArrow={item.content !== undefined}
						>
							{item.content}
						</Panel>
					))}
			</Collapse>
		</>
	);
};

export default CollapseCommon;
