export enum Types {
	CADASTRAR_UNIDADE = '@SGU/CADASTRAR_UNIDADE',
	CADASTRAR_UNIDADE_SUCCESS = '@SGU/CADASTRAR_UNIDADE_SUCCESS',
	CADASTRAR_UNIDADE_FAILURE = '@SGU/CADASTRAR_UNIDADE_FAILURE',
	CADASTRAR_UNIDADE_CLEAR = '@SGU/CADASTRAR_UNIDADE_CLEAR',
}

export interface CadastrarUnidade {
	status: number;
	data: null | UnidadeData;
}

interface UnidadeData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarUnidadeRequest {
	id?: number | string;
	codigo: number | string;
	nome: string;
	sigla: string;
	tipo?: string;
}
