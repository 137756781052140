import produce from 'immer';

// TYPES
import { Types, LoaderState } from './types';
import { ReducerAction } from '../../types';

export const INITIAL_STATE: LoaderState = {
	visible: false,
};

export default function app(
	state = INITIAL_STATE,
	action: ReducerAction,
): LoaderState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.SET_LOADER: {
				draft.visible = action.payload;
				break;
			}

			default:
		}
	});
}
