import { ReducerAction } from 'store/modules/types';
import { Types, IRequestEditarCadastrarEndereco } from './types';

export function editaCadastraEnderecoRequest(
	payload: IRequestEditarCadastrarEndereco,
) {
	return {
		type: Types.EDITA_CADASTRA_ENDERECO,
		payload,
	};
}
export function editaCadastraEnderecoSuccess(payload: object): ReducerAction {
	return {
		type: Types.EDITA_CADASTRA_ENDERECO_SUCCESS,
		payload,
	};
}
export function editaCadastraEnderecoFailure(): ReducerAction {
	return {
		type: Types.EDITA_CADASTRA_ENDERECO_FAILURE,
		payload: null,
	};
}

export function limparEditaCadastraEndereco(): any {
	return {
		type: Types.LIMPAR_EDITA_CADASTRA_ENDERECO,
	};
}
