import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';

// TYPES
import { ReducerAction } from 'store/modules/types';

import { CHANNEL, PATH_SGU_SERVICE } from 'services/_path';
import { DomainServiceMunicipiosRequest, Types } from './types';

// ACTIONS
import {
	domainServiceMunicipiosSuccess,
	domainServiceMunicipiosFailure,
} from './actions';

function* domainServiceMunicipiosRequest(request: ReducerAction) {
	const { payload }: { payload: DomainServiceMunicipiosRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU_SERVICE,
		`domain-service/${CHANNEL}/municipio`,
		payload,
	);

	if (response.status === 200) {
		yield put(domainServiceMunicipiosSuccess(response));
	} else {
		yield put(domainServiceMunicipiosFailure(response));
	}
}

export default all([
	takeLatest(
		Types.DOMAIN_SERVICE_MUNICIPIOS_REQUEST,
		domainServiceMunicipiosRequest,
	),
]);
