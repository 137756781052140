import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// ANT DESIGN
import { Col, Row, Modal, Tabs } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { participacoesAudienciaRequest } from 'store/modules/api/procon/dominiosCrm/participacoesAudiencia/actions';
import { formaAquisicaoRequest } from 'store/modules/api/procon/dominiosCrm/formaAquisicao/actions';
import { tiposContratacaoRequest } from 'store/modules/api/procon/dominiosCrm/tiposContratacao/actions';
import { tiposDocumentosCompraRequest } from 'store/modules/api/procon/dominiosCrm/tiposDocumentosCompra/actions';
import { formasDevolucaoRequest } from 'store/modules/api/procon/dominiosCrm/formasDevolucao/actions';
import { consultaFormasPagamentoRequest } from 'store/modules/api/procon/dominiosCrm/formasPagamento/actions';
import { consultaPedidosConsumidorRequest } from 'store/modules/api/procon/dominiosCrm/pedidosConsumidor/actions';
import { CadastraAtendimentoPfRequest } from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPf/types';
import { CadastraAtendimentoPjRequest } from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPj/types';
import { consultaConsumidorClear } from 'store/modules/api/procon/consumidores/consultaConsumidor/actions';
import { pesquisaFornecedorClear } from 'store/modules/api/procon/fornecedores/pesquisaFornecedor/actions';
import {
	cadastraAtendimentoPfClear,
	cadastraAtendimentoPfRequest,
} from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPf/actions';
import {
	cadastraAtendimentoPjClear,
	cadastraAtendimentoPjRequest,
} from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPj/actions';
import { EventoCipPFPJ } from 'store/modules/api/procon/atendimentoCip/combineTypes';
import {
	clearNotifications,
	showInside,
} from 'store/modules/app/notifications/actions';
import { cadastroConsumidorClear } from 'store/modules/api/procon/consumidores/cadastro/actions';
import { atualizacaoConsumidorClear } from 'store/modules/api/procon/consumidores/atualizacao/actions';
import { tiposChavePixRequest } from 'store/modules/api/procon/dominiosCrm/tiposChavePix/actions';
import { consumidorSelecionadoClear } from 'store/modules/api/procon/consumidores/selecionado/actions';
import { fornecedorSelecionadoClear } from 'store/modules/api/procon/fornecedores/selecionado/actions';

// ENUMS
import EnumProcon from 'store/modules/api/procon/enum';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { limparMascara, unformatDate } from 'utils/genericFunctions';
import { scrollToTopByClassName } from 'utils/scrollFunctions';

// COMPONENTS COMMON
import LightButton from 'components/Common/Buttons/Light';
import Section from 'components/Common/Section';
import TextArea from 'components/Common/Form/Input/TextArea';
import Input from 'components/Common/Form/Input/Input';
import CheckBox from 'components/Common/Form/Checkbox';
import Select from 'components/Common/Form/Select';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import TypographyCommon from 'components/Common/Typography';
import InputMask from 'components/Common/Form/Input/InputMask';
import Money from 'components/Common/Form/Fields/Money';

// PATHS
import { ROUTE_PROCON } from 'routes/paths';

// FORM
import { Field, Form, Formik, FormikProps } from 'formik';
import { letrasAcentos } from 'utils/stringFunctions';
import {
	initialValues,
	schema,
	convertEmptyToNull,
	creditCardLabel,
	initialValuesPj,
	schemaPj,
} from './form';

// COMPONENTS
import DadosSolicitacoes from '../components/DadosSolicitacoes';
import EventosEspeciais from '../components/EventosEspeciais';
import PesquisarConsumidor from '../components/PesquisarConsumidor';
import PesquisarFornecedor from '../components/PesquisarFornecedor';
import ProcuradorOuResponsavel from '../components/ProcuradorOuResponsavel';
import Anexos from '../components/Anexos';

// PATHS
import { ROUTE_PROCON_CADASTRAR_RECLAMACAO_CONFIRMACAO } from '../routes/paths';

// STYLED
import './styled.css';
import { stylePaddingCols } from './styled';

const CadastrarReclamacao: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const { TabPane } = Tabs;

	const [formAcquisitionRequired, setFormAcquisitionRequired] =
		useState<boolean>(true);

	const [activeTab, setActiveTab] = useState<string>('tab-reclamacao-pf');

	const [initialValuesPFPJ, setInitialValuesPFPJ] = useState<
		CadastraAtendimentoPfRequest | CadastraAtendimentoPjRequest
	>(initialValues);

	const [showModal, setShowModal] = useState<
		'none' | 'fornecedor' | 'consumidor' | 'procurador'
	>('none');

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const loginUnico = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	useEffect(() => {
		if (showModal === 'none') {
			showInside(false);
			dispatch(cadastroConsumidorClear());
			dispatch(atualizacaoConsumidorClear());
			dispatch(consultaConsumidorClear());
		}
	}, [dispatch, showModal]);

	useEffect(() => {
		dispatch(participacoesAudienciaRequest());
		dispatch(formaAquisicaoRequest());
		dispatch(tiposContratacaoRequest());
		dispatch(tiposDocumentosCompraRequest());
		dispatch(formasDevolucaoRequest());
		dispatch(consultaFormasPagamentoRequest());
		dispatch(consultaPedidosConsumidorRequest());
	}, [dispatch]);

	useEffect(() => {
		if (showModal !== 'none') {
			showInside(true);
		} else {
			showInside(false);
		}
	}, [notifications.errors, showModal]);

	const {
		formaAquisicao,
		participacoesAudiencia,
		tiposContratacao,
		tiposDocumentosCompra,
		formasDevolucao,
		formasPagamento,
		pedidosConsumidor,
	} = useSelector((state: ApplicationState) => state.api.procon.dominiosCrm);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.salvarAtendimento.data,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { cadastraAtendimentoPf, cadastraAtendimentoPj } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentoCip,
	);

	const { tiposChavePix } = useSelector(
		(state: ApplicationState) => state.api.procon.dominiosCrm,
	);

	const { selecionado } = useSelector(
		(state: ApplicationState) => state.api.procon.consumidores,
	);

	useEffect(() => {
		if (cadastraAtendimentoPf.status === 200) {
			history.push(ROUTE_PROCON_CADASTRAR_RECLAMACAO_CONFIRMACAO);
		}
	}, [history, cadastraAtendimentoPf]);

	useEffect(() => {
		if (cadastraAtendimentoPj.status === 200) {
			history.push(ROUTE_PROCON_CADASTRAR_RECLAMACAO_CONFIRMACAO);
		}
	}, [history, cadastraAtendimentoPj]);

	// const treatValuesPfPj = useCallback(
	// 	(body: CadastraAtendimentoPfRequest | CadastraAtendimentoPjRequest) => {
	// 		if (
	// 			body?.novoFornecedor?.email !== '' &&
	// 			body?.novoFornecedor?.email !== null
	// 		) {
	// 			if (body?.novoFornecedor?.bairro === '') {
	// 				body.novoFornecedor.bairro = null;
	// 			}
	// 			if (body?.novoFornecedor?.cep === '') {
	// 				body.novoFornecedor.cep = null;
	// 			}
	// 			if (body?.novoFornecedor?.logradouro === '') {
	// 				body.novoFornecedor.logradouro = null;
	// 			}
	// 			if (body?.novoFornecedor?.municipioId === '') {
	// 				body.novoFornecedor.municipioId = null;
	// 			}
	// 			if (body?.novoFornecedor?.municipioDescricao === '') {
	// 				body.novoFornecedor.municipioDescricao = null;
	// 			}
	// 			if (body?.novoFornecedor?.numero === '') {
	// 				body.novoFornecedor.numero = null;
	// 			}
	// 			if (body?.novoFornecedor?.telefone === '') {
	// 				body.novoFornecedor.telefone = null;
	// 			}
	// 			if (body?.novoFornecedor?.celular === '') {
	// 				body.novoFornecedor.celular = null;
	// 			}
	// 			if (body?.novoFornecedor?.cpf === '') {
	// 				body.novoFornecedor.cpf = null;
	// 			}
	// 			if (body?.novoFornecedor?.cnpj === '') {
	// 				body.novoFornecedor.cnpj = null;
	// 			}
	// 		}
	// 		return body;
	// 	},
	// 	[],
	// );

	const handleSubmit = useCallback(
		(values: CadastraAtendimentoPfRequest | CadastraAtendimentoPjRequest) => {
			showInside(false);

			if (user) {
				const dadosEvento: EventoCipPFPJ = {
					id_atendimento: dadosAtendimento?.idAtendimento || '',
					id_cidadao: dadosAtendimento?.idCidadao || '',
					cpf: user.cpf,
					identificacao: Number(limparMascara(selecionado.data?.cpf)),
					ip: user.ip,
					canal: {
						id: loginUnico.login.user.idCanal,
						desc: loginUnico.login.user.descCanal,
						estacao: {
							id: '',
							desc: '',
						},
						localidade: {
							id: Number(user.posto),
							desc: user.nomePosto,
						},
					},
				};

				const body:
					| CadastraAtendimentoPfRequest
					| CadastraAtendimentoPjRequest = {
					evento: dadosEvento,
					...values,
				};

				if (values.fornecedorId) {
					delete body.novoFornecedor;
				}

				delete body.consumidorNome;
				delete body.fornecedorNome;
				delete body.temProcurador;
				delete body.isEventoSazonal;

				delete body.dataDaOcorrenciaOuServico;

				delete body.tipoConsumidor;

				body.idDoBalcaoUnico = user.posto;
				body.dataDaContratacao = unformatDate(values.dataDaContratacao);
				body.dataDaOcorrenciaOuDoServico = unformatDate(
					values.dataDaOcorrenciaOuDoServico,
				);
				body.dataDeCancelamentoDesistenciaOuNegativa = unformatDate(
					values.dataDeCancelamentoDesistenciaOuNegativa,
				);
				body.valorDaCompra = Number(values.valorDaCompra);
				if (body.compraNumeroDoCartao) {
					body.compraNumeroDoCartao = creditCardLabel(
						body.compraNumeroDoCartao,
					);
				}
				if (body.devolucaoNumeroDoCartao) {
					body.devolucaoNumeroDoCartao = creditCardLabel(
						body.devolucaoNumeroDoCartao,
					);
				}

				if (activeTab === 'tab-reclamacao-pf') {
					delete body.cnpjDoConsumidor;

					const newBodyCipPF: CadastraAtendimentoPfRequest | null =
						convertEmptyToNull(body);
					if (newBodyCipPF?.novoFornecedor?.municipioId) {
						newBodyCipPF.novoFornecedor = convertEmptyToNull(
							newBodyCipPF.novoFornecedor,
						);
					}

					if (
						newBodyCipPF?.novoFornecedor?.email !== '' &&
						newBodyCipPF?.novoFornecedor?.email !== null
					) {
						if (newBodyCipPF?.novoFornecedor?.bairro === '') {
							newBodyCipPF.novoFornecedor.bairro = null;
						}
						if (newBodyCipPF?.novoFornecedor?.cep === '') {
							newBodyCipPF.novoFornecedor.cep = null;
						}
						if (newBodyCipPF?.novoFornecedor?.logradouro === '') {
							newBodyCipPF.novoFornecedor.logradouro = null;
						}
						if (newBodyCipPF?.novoFornecedor?.municipioId === '') {
							newBodyCipPF.novoFornecedor.municipioId = null;
						}
						if (newBodyCipPF?.novoFornecedor?.municipioDescricao === '') {
							newBodyCipPF.novoFornecedor.municipioDescricao = null;
						}
						if (newBodyCipPF?.novoFornecedor?.numero === '') {
							newBodyCipPF.novoFornecedor.numero = null;
						}
						if (newBodyCipPF?.novoFornecedor?.telefone === '') {
							newBodyCipPF.novoFornecedor.telefone = null;
						}
						if (newBodyCipPF?.novoFornecedor?.celular === '') {
							newBodyCipPF.novoFornecedor.celular = null;
						}
						if (newBodyCipPF?.novoFornecedor?.cpf === '') {
							newBodyCipPF.novoFornecedor.cpf = null;
						}
						if (newBodyCipPF?.novoFornecedor?.cnpj === '') {
							newBodyCipPF.novoFornecedor.cnpj = null;
						}
					}

					if (
						newBodyCipPF?.novoFornecedor?.email !== '' &&
						newBodyCipPF?.novoFornecedor?.email !== null
					) {
						if (newBodyCipPF?.novoFornecedor?.bairro === '') {
							newBodyCipPF.novoFornecedor.bairro = null;
						}
						if (newBodyCipPF?.novoFornecedor?.cep === '') {
							newBodyCipPF.novoFornecedor.cep = null;
						}
						if (newBodyCipPF?.novoFornecedor?.logradouro === '') {
							newBodyCipPF.novoFornecedor.logradouro = null;
						}
						if (newBodyCipPF?.novoFornecedor?.municipioId === '') {
							newBodyCipPF.novoFornecedor.municipioId = null;
						}
						if (newBodyCipPF?.novoFornecedor?.municipioDescricao === '') {
							newBodyCipPF.novoFornecedor.municipioDescricao = null;
						}
						if (newBodyCipPF?.novoFornecedor?.numero === '') {
							newBodyCipPF.novoFornecedor.numero = null;
						}
						if (newBodyCipPF?.novoFornecedor?.telefone === '') {
							newBodyCipPF.novoFornecedor.telefone = null;
						}
						if (newBodyCipPF?.novoFornecedor?.celular === '') {
							newBodyCipPF.novoFornecedor.celular = null;
						}
						if (newBodyCipPF?.novoFornecedor?.cpf === '') {
							newBodyCipPF.novoFornecedor.cpf = null;
						}
						if (newBodyCipPF?.novoFornecedor?.cnpj === '') {
							newBodyCipPF.novoFornecedor.cnpj = null;
						}
					}

					if (
						newBodyCipPF &&
						values.temProcurador &&
						newBodyCipPF.anexos.length > 0
					) {
						Modal.confirm({
							okText: 'Enviar, documento já anexado',
							cancelText: 'Voltar',
							okButtonProps: { id: 'confirm-procurador-documento-anexado' },
							content: (
								<>
									<div>
										Para o envio do formulário com Procurador, é necessário
										anexar um documento informando o mesmo.
									</div>
								</>
							),
							onOk() {
								dispatch(cadastraAtendimentoPfRequest(newBodyCipPF));
							},
						});
					} else if (newBodyCipPF && newBodyCipPF?.anexos?.length > 0) {
						dispatch(cadastraAtendimentoPfRequest(newBodyCipPF));
					} else if (newBodyCipPF) {
						Modal.confirm({
							okText: 'Sim, enviar sem anexo',
							cancelText: 'Não',
							okButtonProps: {
								id: 'confirm-procurador-documento-nao-anexado-pf',
							},
							content: (
								<>
									<div>Gostaria de enviar o formulário sem o Anexo?</div>
								</>
							),
							onOk() {
								dispatch(cadastraAtendimentoPfRequest(newBodyCipPF));
							},
						});
					}
				}

				if (activeTab === 'tab-reclamacao-pj') {
					body.cnpjDoConsumidor = limparMascara(body.cnpjDoConsumidor);

					const newBodyCipPJ: CadastraAtendimentoPjRequest =
						convertEmptyToNull(body);

					if (newBodyCipPJ?.novoFornecedor?.municipioId) {
						newBodyCipPJ.novoFornecedor = convertEmptyToNull(
							newBodyCipPJ.novoFornecedor,
						);
					}
					if (
						newBodyCipPJ?.novoFornecedor?.email !== '' &&
						newBodyCipPJ?.novoFornecedor?.email !== null
					) {
						if (newBodyCipPJ?.novoFornecedor?.bairro === '') {
							newBodyCipPJ.novoFornecedor.bairro = null;
						}
						if (newBodyCipPJ?.novoFornecedor?.cep === '') {
							newBodyCipPJ.novoFornecedor.cep = null;
						}
						if (newBodyCipPJ?.novoFornecedor?.logradouro === '') {
							newBodyCipPJ.novoFornecedor.logradouro = null;
						}
						if (newBodyCipPJ?.novoFornecedor?.municipioId === '') {
							newBodyCipPJ.novoFornecedor.municipioId = null;
						}
						if (newBodyCipPJ?.novoFornecedor?.municipioDescricao === '') {
							newBodyCipPJ.novoFornecedor.municipioDescricao = null;
						}
						if (newBodyCipPJ?.novoFornecedor?.numero === '') {
							newBodyCipPJ.novoFornecedor.numero = null;
						}
						if (newBodyCipPJ?.novoFornecedor?.telefone === '') {
							newBodyCipPJ.novoFornecedor.telefone = null;
						}
						if (newBodyCipPJ?.novoFornecedor?.celular === '') {
							newBodyCipPJ.novoFornecedor.celular = null;
						}
						if (newBodyCipPJ?.novoFornecedor?.cpf === '') {
							newBodyCipPJ.novoFornecedor.cpf = null;
						}
						if (newBodyCipPJ?.novoFornecedor?.cnpj === '') {
							newBodyCipPJ.novoFornecedor.cnpj = null;
						}
					}

					if (newBodyCipPJ && newBodyCipPJ.anexos.length > 0) {
						dispatch(cadastraAtendimentoPjRequest(newBodyCipPJ));
					} else {
						Modal.confirm({
							okText: 'Sim, enviar sem anexo',
							cancelText: 'Não',
							okButtonProps: {
								id: 'confirm-procurador-documento-nao-anexado-pj',
							},
							content: (
								<>
									<div>Gostaria de enviar o formulário sem o Anexo?</div>
								</>
							),
							onOk() {
								dispatch(cadastraAtendimentoPjRequest(newBodyCipPJ));
							},
						});
					}
				}
			}
		},
		[user, dadosAtendimento, selecionado, loginUnico, activeTab, dispatch],
	);

	const handleCalcColCompraBancoEmissor = useCallback(
		(type: string | number) => {
			if (type === '472890000' || type === 472890000) {
				return 24;
			}

			if (type === '472890001' || type === 472890001) {
				return 10;
			}

			if (
				type === '472890002' ||
				type === 472890002 ||
				type === '472890009' ||
				type === 472890009
			) {
				return 16;
			}

			return 24;
		},
		[],
	);

	// const handleCalcMarginFormaDevolucao = useCallback(
	// 	(type: string | number) => {
	// 		if (type === '1' || type === 1 || type === '4' || type === 4) {
	// 			return '-20px';
	// 		}

	// 		if (type === '2' || type === 2) {
	// 			return '-40px';
	// 		}

	// 		if (type === '3' || type === 3) {
	// 			return '0px';
	// 		}

	// 		return '0px';
	// 	},
	// 	[],
	// );

	const handleViewFormaPagmento = useCallback((type: string | number) => {
		if (
			type === '472890003' ||
			type === '472890005' ||
			type === '472890007' ||
			type === '472890008'
		) {
			return false;
		}

		if (
			type === 472890003 ||
			type === 472890005 ||
			type === 472890007 ||
			type === 472890008
		) {
			return false;
		}

		return true;
	}, []);

	const handleSubtitleMaskPix = useCallback((type: number | string) => {
		switch (type) {
			case 472890000:
				return ' (XXX.XXX.XXX-XX)';
			case 472890001:
				return ' (XX.XXX.XXX/XXXX-XX)';
			case 472890003:
				return ' ((XX) X XXXX-XXXX)';
			default:
				return '';
		}
	}, []);

	const handleMaskPix = useCallback((type: number | string) => {
		switch (type) {
			case 472890000:
				return '999.999.999-99';
			case 472890001:
				return '99.999.999/0001-99';
			case 472890003:
				return '(99) 9 9999-9999';
			default:
				return '';
		}
	}, []);

	const handleDefineFormaDeAquisicao = useCallback(
		(
			formik: FormikProps<
				CadastraAtendimentoPfRequest | CadastraAtendimentoPjRequest
			>,
			value: number,
		) => {
			formik.setFieldValue('formaDeAquisicao', value);

			if (value === 4) {
				formik.setFieldValue('tipoDeContratacao', '');
				formik.setFieldValue('dataDaContratacao', '');
				formik.setFieldValue('nomeDoProdutoOuServico', '');
				formik.setFieldValue('detalhesDoProdutoOuServico', '');
				formik.setFieldValue('tipoDoDocumento', '');
				formik.setFieldValue('numeroDoDocumento', '');
				formik.setFieldValue('formaDePagamento', '');
				formik.setFieldValue('valorDaCompra', '');
				setFormAcquisitionRequired(false);
			} else {
				setFormAcquisitionRequired(true);
			}
		},
		[setFormAcquisitionRequired],
	);

	const handleClearFieldsCompraOrDevolucao = useCallback(
		(
			type: string,
			formik: FormikProps<
				CadastraAtendimentoPfRequest | CadastraAtendimentoPjRequest
			>,
		) => {
			if (type === 'compra') {
				formik.setFieldValue('compraAgencia', '');
				formik.setFieldValue('compraBancoEmissor', '');
				formik.setFieldValue('compraBandeiraDoCartao', '');
				formik.setFieldValue('compraChavePix', '');
				formik.setFieldValue('compraConta', '');
				formik.setFieldValue('compraIntermediadoraDePagamento', '');
				formik.setFieldValue('compraNumeroDoCartao', '');
				formik.setFieldValue('compraTipoDeChavePix', '');
			}

			if (type === 'devolucao') {
				formik.setFieldValue('devolucaoAgencia', '');
				formik.setFieldValue('devolucaoBancoEmissorDoCartao', '');
				formik.setFieldValue('devolucaoBandeiraDoCartao', '');
				formik.setFieldValue('devolucaoChavePix', '');
				formik.setFieldValue('devolucaoConta', '');
				formik.setFieldValue('devolucaoNomeDoBanco', '');
				formik.setFieldValue('devolucaoNumeroDeParcelas', 0);
				formik.setFieldValue('devolucaoNumeroDoCartao', '');
				formik.setFieldValue('devolucaoTipoDeChavePix', '');
				formik.setFieldValue('devolucaoValorDaParcela', 0);
			}
		},
		[],
	);

	const handleClearConsumidor = useCallback(
		(
			formik: FormikProps<
				CadastraAtendimentoPfRequest | CadastraAtendimentoPjRequest
			>,
		) => {
			dispatch(consumidorSelecionadoClear());
			dispatch(consultaConsumidorClear());
			formik.setFieldValue('consumidorNome', '');
		},
		[dispatch],
	);

	const handleClearFornecedor = useCallback(
		(
			formik: FormikProps<
				CadastraAtendimentoPfRequest | CadastraAtendimentoPjRequest
			>,
		) => {
			dispatch(fornecedorSelecionadoClear());
			dispatch(pesquisaFornecedorClear());
			formik.setFieldValue('fornecedorNome', '');
		},
		[dispatch],
	);

	const handleBack = () => {
		history.push(ROUTE_PROCON);
	};

	const handleValidate = useCallback(
		(values: CadastraAtendimentoPfRequest | CadastraAtendimentoPjRequest) => {
			if (activeTab === 'tab-reclamacao-pf') {
				return getValidationsErrors(values, schema);
			}

			return getValidationsErrors(values, schemaPj);
		},
		[activeTab],
	);

	const getTitle = useCallback(() => {
		switch (showModal) {
			case 'procurador':
				return (
					<TypographyCommon
						text="Procurador"
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				);
			case 'consumidor':
				return (
					<TypographyCommon
						text={
							activeTab === 'tab-reclamacao-pf'
								? 'Consumidor'
								: 'Representante Legal'
						}
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				);
			case 'fornecedor':
				return (
					<TypographyCommon
						text="Fornecedor"
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				);
			default:
				return (
					<TypographyCommon
						text="[Sem Título]"
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				);
		}
	}, [activeTab, showModal]);

	const handleChangeTab = useCallback(
		(
			key: string,
			formik: FormikProps<
				CadastraAtendimentoPfRequest | CadastraAtendimentoPjRequest
			>,
		) => {
			if (formik.dirty) {
				return Modal.confirm({
					okText: 'Sim',
					cancelText: 'Não',
					okButtonProps: { id: 'tab-confirm-pj-pf' },
					content: (
						<>
							<div>
								Ao alterar entre as telas de atendimento pessoa fisica e
								atendimento pessoa jurídica você perderá os dados preenchidos.
								Tem certeza que deseja confirmar a ação?
							</div>
						</>
					),
					onOk() {
						dispatch(cadastraAtendimentoPfClear());
						dispatch(cadastraAtendimentoPjClear());
						formik.resetForm();
						setActiveTab(key);
						dispatch(clearNotifications());
					},
				});
			}

			return setActiveTab(key);
		},
		[dispatch],
	);

	useEffect(() => {
		dispatch(tiposChavePixRequest());
	}, [dispatch]);

	useEffect(() => {
		if (notifications.errors.length) {
			scrollToTopByClassName('ant-modal-wrap', 0);
		}
	}, [notifications]);

	useEffect(() => {
		if (activeTab === 'tab-reclamacao-pf') setInitialValuesPFPJ(initialValues);
		else setInitialValuesPFPJ(initialValuesPj);
	}, [activeTab, setInitialValuesPFPJ]);

	return (
		<>
			<Row gutter={[0, 0]} align="middle" justify="start">
				<Col className="gutter-row" span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Cadastrar Reclamação"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>

			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={initialValuesPFPJ}
				validate={values => handleValidate(values)}
				onSubmit={handleSubmit}
			>
				{formik => (
					<Form autoComplete="off">
						<div className="card-container">
							<Tabs
								type="card"
								activeKey={activeTab}
								onChange={key => handleChangeTab(key, formik)}
							>
								<TabPane
									id="tab-reclamacao-pf"
									tab="Consumidor Pessoa Física"
									key="tab-reclamacao-pf"
								/>
								<TabPane
									id="tab-reclamacao-pj"
									tab="Consumidor Pessoa Jurídica"
									key="tab-reclamacao-pj"
								/>
							</Tabs>
						</div>

						<Section
							bgColor="cinza-escuro"
							style={{
								border: '1px grey solid',
								borderRadius: '4px',
								marginTop: '-17px',
							}}
						>
							<Row gutter={[0, 0]} align="middle" justify="start">
								<TypographyCommon
									text={
										activeTab === 'tab-reclamacao-pf'
											? 'Consumidor'
											: 'Representante Legal da Empresa'
									}
									fontWeight="bold"
									required
									uppercase
								/>
							</Row>
							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={19} style={stylePaddingCols}>
									<Field
										as={Input}
										name="consumidorNome"
										placeholder={
											activeTab === 'tab-reclamacao-pf'
												? 'Clique na lupa para selecionar um Consumidor'
												: 'Clique na lupa para selecionar o Representante Legal da Empresa'
										}
										error={hasError(formik.errors, 'consumidorId')}
										disabled
										required
									/>
								</Col>
								<Col span={5}>
									<Row align="middle" justify="space-between">
										<LightButton
											id="btn_pesquisar_consumidor"
											type="button"
											buttonColor="default"
											text="Pesquisar"
											onClick={() => {
												setShowModal('consumidor');
											}}
											icon="search"
											buttonSize="sm"
											title="Pesquisar Consumidor"
										/>
										<LightButton
											id="btn_limpar_consumidor"
											type="button"
											buttonColor="default"
											text="Limpar"
											onClick={() => handleClearConsumidor(formik)}
											icon="erase"
											buttonSize="sm"
											title="Limpar"
										/>
									</Row>
								</Col>
							</Row>

							{activeTab === 'tab-reclamacao-pj' && (
								<>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="CNPJ do Consumidor:"
											subtitle=" (XX.XXX.XXX/XXXX-XX)"
											fontWeight="bold"
											required
											uppercase
										/>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={InputMask}
												name="cnpjDoConsumidor"
												value={formik.values.cnpjDoConsumidor}
												mask="99.999.999/9999-99"
												maskChar=""
												error={hasError(formik.errors, 'cnpjDoConsumidor')}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'cnpjDoConsumidor',
														e.currentTarget.value,
													);
												}}
											/>
										</Col>
									</Row>
								</>
							)}

							<Row gutter={[0, 0]} align="middle" justify="start">
								<TypographyCommon
									text="Fornecedor"
									fontWeight="bold"
									required
									uppercase
								/>
							</Row>
							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={19} style={stylePaddingCols}>
									<Field
										as={Input}
										placeholder="Clique na lupa para selecionar um Fornecedor"
										name="fornecedorNome"
										error={hasError(formik.errors, 'validacaoFornecedor')}
										disabled
										required
									/>
								</Col>
								<Col span={5}>
									<Row align="middle" justify="space-between">
										<LightButton
											id="btn_pesquisar_fornecedor"
											type="button"
											buttonColor="default"
											text="Pesquisar"
											onClick={() => setShowModal('fornecedor')}
											icon="search"
											buttonSize="sm"
											title="Pesquisar Fornecedor"
										/>
										<LightButton
											id="btn_limpar_fornecedor"
											type="button"
											buttonColor="default"
											text="Limpar"
											onClick={() => handleClearFornecedor(formik)}
											icon="erase"
											buttonSize="sm"
											title="Limpar"
										/>
									</Row>
								</Col>
							</Row>

							{activeTab === 'tab-reclamacao-pf' && (
								<>
									{formik.values.temProcurador &&
									formik.values.cpfDoProcuradorOuResponsavel &&
									formik.values.rgDoResponsavelOuProcurador &&
									formik.values.nomeDoResponsavelOuProcurador ? (
										<>
											<TypographyCommon
												text="Procurador"
												fontWeight="bold"
												borderBottom
												type="h5"
												uppercase
											/>
											<Row gutter={[0, 0]} align="middle" justify="start">
												<Col span={22}>
													<Row gutter={[0, 0]} align="middle" justify="start">
														<Col span={14}>
															<TypographyCommon
																text="Nome:"
																fontWeight="bold"
																required
																uppercase
															/>
														</Col>
														<Col span={5}>
															<TypographyCommon
																text="CPF:"
																fontWeight="bold"
																required
																uppercase
															/>
														</Col>
														<Col span={5}>
															<TypographyCommon
																text="RG:"
																fontWeight="bold"
																required
																uppercase
															/>
														</Col>
														<Col span={14}>
															<Row
																gutter={[0, 12]}
																align="middle"
																justify="start"
															>
																<TypographyCommon
																	text={
																		formik.values
																			.nomeDoResponsavelOuProcurador ||
																		'Não informado'
																	}
																	uppercase
																/>
															</Row>
														</Col>
														<Col span={5}>
															<Row
																gutter={[0, 12]}
																align="middle"
																justify="start"
															>
																<TypographyCommon
																	text={
																		formik.values
																			.cpfDoProcuradorOuResponsavel ||
																		'Não informado'
																	}
																	uppercase
																/>
															</Row>
														</Col>
														<Col span={5}>
															<Row
																gutter={[0, 12]}
																align="middle"
																justify="start"
															>
																<TypographyCommon
																	text={
																		formik.values.rgDoResponsavelOuProcurador ||
																		'Não informado'
																	}
																	uppercase
																/>
															</Row>
														</Col>
													</Row>
												</Col>

												<Col span={2}>
													<Row gutter={[0, 0]} align="middle" justify="end">
														<LightButton
															type="button"
															buttonColor="default"
															text="Limpar"
															onClick={() => {
																formik.setFieldValue('temProcurador', false);
																formik.setFieldValue(
																	'nomeDoResponsavelOuProcurador',
																	'',
																);
																formik.setFieldValue(
																	'cpfDoProcuradorOuResponsavel',
																	'',
																);
																formik.setFieldValue(
																	'rgDoResponsavelOuProcurador',
																	'',
																);
															}}
															icon="erase"
															buttonSize="sm"
															title="Limpar"
														/>
													</Row>
												</Col>
											</Row>
										</>
									) : (
										<Row gutter={[0, 20]} align="middle" justify="start">
											<Col span={24}>
												<Field
													as={CheckBox}
													name="temProcurador"
													subtitle={'Desejo incluir Procurador'.toUpperCase()}
													maxLength="500"
													titleSize="xl"
													titleAlign="start"
													checked={formik.values.temProcurador}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														formik.setFieldValue(
															'temProcurador',
															!!e.target.checked,
														);
														setShowModal('procurador');
													}}
													error={hasError(formik.errors, 'temProcurador')}
												/>
											</Col>
										</Row>
									)}
								</>
							)}

							<br />

							<DadosSolicitacoes formik={formik} />

							<br />

							<Row gutter={[0, 10]} align="middle" justify="start">
								<Col
									className="gutter-row"
									span={24}
									style={{ paddingBottom: '10px' }}
								>
									<TypographyCommon
										text="Dados Adicionais"
										borderBottom
										type="h5"
										fontWeight="bold"
										uppercase
									/>
								</Col>
							</Row>

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={24}>
									<Field
										as={CheckBox}
										name="procureiOFornecedorParaResolverAQuestao"
										subtitle={'Procurei o fornecedor para solucionar a questão'.toUpperCase()}
										maxLength="500"
										titleSize="xl"
										titleAlign="start"
										checked={
											formik.values.procureiOFornecedorParaResolverAQuestao
										}
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											formik.setFieldValue(
												'procureiOFornecedorParaResolverAQuestao',
												e.target.checked,
											);

											if (!e.target.checked) {
												formik.setFieldValue(
													'descricaoDoPrimeiroContatoComFornecedor',
													'',
												);
											}
										}}
										error={hasError(
											formik.errors,
											'procureiOFornecedorParaResolverAQuestao',
										)}
									/>
								</Col>
								<Col span={24}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Informe como foi seu contato com a empresa e o número do protocolo:"
											subtitle=" (Quantidade Mínima 50 Caracteres e Máxima 500)"
											fontWeight="bold"
											uppercase
											required={
												formik.values.procureiOFornecedorParaResolverAQuestao
											}
										/>
									</Row>
									<Field
										as={TextArea}
										name="descricaoDoPrimeiroContatoComFornecedor"
										maxLength="500"
										error={hasError(
											formik.errors,
											'descricaoDoPrimeiroContatoComFornecedor',
										)}
										required
										disabled={
											!formik.values.procureiOFornecedorParaResolverAQuestao
										}
									/>
								</Col>
							</Row>

							<Row
								gutter={[0, 0]}
								align="middle"
								justify="start"
								style={{ marginTop: '20px' }}
							>
								<Col
									className="gutter-row"
									span={24}
									style={{ paddingBottom: '10px' }}
								>
									<TypographyCommon
										text=""
										borderBottom
										type="h5"
										fontWeight="bold"
										uppercase
									/>
								</Col>
							</Row>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<TypographyCommon
									text="Participação em Audiência:"
									fontWeight="bold"
									required
									uppercase
								/>
							</Row>
							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={24}>
									<Field
										as={Select}
										name="participacaoEmAudiencia"
										options={participacoesAudiencia.data ?? []}
										defaultValue=""
										titleSize="xl"
										onChange={(v: string) => {
											formik.setFieldValue('participacaoEmAudiencia', v);
										}}
										error={hasError(formik.errors, 'participacaoEmAudiencia')}
										required
									/>
								</Col>
							</Row>

							<EventosEspeciais
								formik={formik}
								disabled={formik.values.isEventoSazonal}
							/>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col
									className="gutter-row"
									span={24}
									style={{ paddingBottom: '10px' }}
								>
									<TypographyCommon
										text="Dados da Compra / Contratação"
										borderBottom
										type="h5"
										fontWeight="bold"
										uppercase
									/>
								</Col>
							</Row>

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={14}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="Forma de Aquisição:"
												fontWeight="bold"
												required
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={23}>
											<Field
												as={Select}
												name="formaDeAquisicao"
												options={formaAquisicao.data ?? []}
												onChange={(v: number) => {
													handleDefineFormaDeAquisicao(formik, v);
												}}
												error={hasError(formik.errors, 'formaDeAquisicao')}
												required
											/>
										</Col>
									</Row>
								</Col>
								<Col span={10}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="Tipo de Contratação:"
												fontWeight="bold"
												required={formAcquisitionRequired}
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={Select}
												name="tipoDeContratacao"
												options={tiposContratacao.data ?? []}
												onChange={(v: string) => {
													formik.setFieldValue('tipoDeContratacao', v);
												}}
												error={hasError(
													formik.errors,
													'validacaoTipoDeContratacao',
												)}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={10} style={stylePaddingCols}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="Tipo de Documento:"
												fontWeight="bold"
												required={formAcquisitionRequired}
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={Select}
												name="tipoDoDocumento"
												options={tiposDocumentosCompra.data ?? []}
												onChange={(v: string) => {
													formik.setFieldValue('tipoDoDocumento', v);
												}}
												error={hasError(
													formik.errors,
													'validacaoTipoDoDocumento',
												)}
											/>
										</Col>
									</Row>
								</Col>
								<Col span={14}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="Número do Documento:"
												fontWeight="bold"
												required={formAcquisitionRequired}
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={Input}
												maxLength="25"
												name="numeroDoDocumento"
												error={hasError(
													formik.errors,
													'validacaoNumeroDoDocumento',
												)}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={8} style={stylePaddingCols}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="Data da Contratação:"
												fontWeight="bold"
												required={formAcquisitionRequired}
												subtitle=" (dd/mm/aaaa)"
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												id="dataDaContratacao"
												name="dataDaContratacao"
												type="text"
												mask="99/99/9999"
												as={ValidDataInput}
												error={hasError(
													formik.errors,
													'validacaoDataDaContratacao',
												)}
												formik={formik}
												customChanges={(
													v: React.ChangeEvent<HTMLInputElement>,
												) => {
													formik.setFieldValue(
														'dataDaContratacao',
														v.target.value,
													);
												}}
											/>
										</Col>
									</Row>
								</Col>

								<Col span={16}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="Nome do Produto ou Serviço:"
												fontWeight="bold"
												required={formAcquisitionRequired}
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={Input}
												maxLength="100"
												name="nomeDoProdutoOuServico"
												error={hasError(
													formik.errors,
													'validacaoNomeDoProdutoOuServico',
												)}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'nomeDoProdutoOuServico',
														letrasAcentos(v.target.value),
													);
												}}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row gutter={[0, 20]} align="middle" justify="start">
								<TypographyCommon
									text="Descreva detalhes do produto ou serviço:"
									fontWeight="bold"
									subtitle=" (Quantidade Mínima 50 Caracteres e Máxima 1.000)"
									required={formAcquisitionRequired}
									uppercase
								/>
							</Row>
							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={24}>
									<Field
										as={TextArea}
										name="detalhesDoProdutoOuServico"
										maxLength="1000"
										error={hasError(
											formik.errors,
											'validacaoDetalhesDoProdutoOuServico',
										)}
									/>
								</Col>
							</Row>

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={12} style={stylePaddingCols}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="Data de Cancelamento, Desistência ou Negativa:"
												fontWeight="bold"
												required={formAcquisitionRequired}
												subtitle=" (dd/mm/aaaa)"
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												id="dataDeCancelamentoDesistenciaOuNegativa"
												name="dataDeCancelamentoDesistenciaOuNegativa"
												type="text"
												mask="99/99/9999"
												as={ValidDataInput}
												error={hasError(
													formik.errors,
													'dataDeCancelamentoDesistenciaOuNegativa',
												)}
												formik={formik}
												required={formAcquisitionRequired}
												customChanges={(
													v: React.ChangeEvent<HTMLInputElement>,
												) => {
													formik.setFieldValue(
														'dataDeCancelamentoDesistenciaOuNegativa',
														v.target.value,
													);
												}}
											/>
										</Col>
									</Row>
								</Col>
								<Col span={12}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="Data da Ocorrência:"
												fontWeight="bold"
												required={formAcquisitionRequired}
												subtitle=" (dd/mm/aaaa)"
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												id="dataDaOcorrenciaOuDoServico"
												name="dataDaOcorrenciaOuDoServico"
												type="text"
												mask="99/99/9999"
												as={ValidDataInput}
												error={hasError(
													formik.errors,
													'dataDaOcorrenciaOuDoServico',
												)}
												formik={formik}
												required={formAcquisitionRequired}
												customChanges={(
													v: React.ChangeEvent<HTMLInputElement>,
												) => {
													formik.setFieldValue(
														'dataDaOcorrenciaOuDoServico',
														v.target.value,
													);
												}}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col
									className="gutter-row"
									span={24}
									style={{ paddingBottom: '10px' }}
								>
									<TypographyCommon
										text="Dados do Pagamento"
										borderBottom
										type="h5"
										fontWeight="bold"
										uppercase
									/>
								</Col>
							</Row>

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={6}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={23}>
											<TypographyCommon
												text="Valor da contratação:"
												fontWeight="bold"
												subtitle=" (R$ 0,00)"
												required={formAcquisitionRequired}
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={23}>
											<Money
												name="valorDaCompra"
												formik={formik}
												defaultValue=""
												error={hasError(
													formik.errors,
													'validacaoValorDaCompra',
												)}
											/>
										</Col>
									</Row>
								</Col>
								<Col span={18}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="Forma de Pagamento:"
												fontWeight="bold"
												required={formAcquisitionRequired}
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={Select}
												name="formaDePagamento"
												options={formasPagamento.data ?? []}
												onChange={(v: string) => {
													handleClearFieldsCompraOrDevolucao('compra', formik);
													formik.setFieldValue('formaDePagamento', v);
												}}
												error={hasError(
													formik.errors,
													'validacaoFormaDePagamento',
												)}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							{(formik.values.formaDePagamento === 472890000 ||
								formik.values.formaDePagamento === 472890001 ||
								formik.values.formaDePagamento === 472890002 ||
								formik.values.formaDePagamento === 472890009) && (
								<>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col
											span={handleCalcColCompraBancoEmissor(
												formik.values.formaDePagamento,
											)}
											style={
												formik.values.formaDePagamento === 472890000
													? {}
													: stylePaddingCols
											}
										>
											<Row gutter={[0, 0]} align="middle" justify="start">
												<Col span={24}>
													<TypographyCommon
														text="Banco Emissor:"
														fontWeight="bold"
														required
														uppercase
														padding-bottom="0px"
													/>
												</Col>
											</Row>
											<Row gutter={[0, 20]} align="middle" justify="start">
												<Col span={24}>
													<Field
														as={Input}
														maxLength="80"
														name="compraBancoEmissor"
														error={hasError(
															formik.errors,
															'validacaoCompraCreditoEmContaBancoEmissor',
														)}
														required
														padding-bottom="0px"
														onChange={(
															v: React.ChangeEvent<HTMLInputElement>,
														) => {
															formik.setFieldValue(
																'compraBancoEmissor',
																letrasAcentos(v.target.value),
															);
														}}
													/>
												</Col>
											</Row>
										</Col>

										{formik.values.formaDePagamento === 472890001 && (
											<>
												<Col span={7} style={stylePaddingCols}>
													<Row gutter={[0, 0]} align="middle" justify="start">
														<Col span={24}>
															<TypographyCommon
																text="Bandeira do Cartão:"
																fontWeight="bold"
																required
																uppercase
															/>
														</Col>
													</Row>
													<Row gutter={[0, 20]} align="middle" justify="start">
														<Col span={24}>
															<Field
																as={Select}
																name="compraBandeiraDoCartao"
																options={EnumProcon.selectBandeirasDoCartao}
																defaultValue=""
																onChange={(v: string) => {
																	formik.setFieldValue(
																		'compraBandeiraDoCartao',
																		v,
																	);
																}}
																error={hasError(
																	formik.errors,
																	'validacaoCompraCreditoEmContaBandeiraDoCartao',
																)}
																required
															/>
														</Col>
													</Row>
												</Col>
												<Col span={7}>
													<Row gutter={[0, 0]} align="middle" justify="start">
														<Col span={24}>
															<TypographyCommon
																text="Número do Cartão:"
																subtitle=" (9999 99XX XXXX 9999)"
																fontWeight="bold"
																required
																uppercase
															/>
														</Col>
													</Row>
													<Row gutter={[0, 20]} align="middle" justify="start">
														<Col span={24}>
															<Field
																as={InputMask}
																name="compraNumeroDoCartao"
																value={formik.values.compraNumeroDoCartao}
																mask="9999 99XX XXXX 9999"
																maskChar=""
																error={hasError(
																	formik.errors,
																	'validacaoCompraCreditoEmContaNumeroDoCartao',
																)}
																onChange={(
																	e: React.ChangeEvent<HTMLInputElement>,
																) => {
																	formik.setFieldValue(
																		'compraNumeroDoCartao',
																		e.currentTarget.value,
																	);
																}}
															/>
														</Col>
													</Row>
												</Col>
											</>
										)}

										{(formik.values.formaDePagamento === 472890002 ||
											formik.values.formaDePagamento === 472890009) && (
											<>
												<Col span={4} style={stylePaddingCols}>
													<Row gutter={[0, 0]} align="middle" justify="start">
														<Col span={24}>
															<TypographyCommon
																text="Agência:"
																subtitle=" (XXXX)"
																fontWeight="bold"
																required
																uppercase
															/>
														</Col>
													</Row>
													<Row gutter={[0, 20]} align="middle" justify="start">
														<Col span={24}>
															<Field
																as={InputMask}
																name="compraAgencia"
																value={formik.values.compraAgencia}
																mask="9999"
																maskChar=""
																error={hasError(
																	formik.errors,
																	'validacaoCompraCreditoEmContaAgencia',
																)}
																onChange={(
																	e: React.ChangeEvent<HTMLInputElement>,
																) => {
																	formik.setFieldValue(
																		'compraAgencia',
																		e.currentTarget.value,
																	);
																}}
															/>
														</Col>
													</Row>
												</Col>
												<Col span={4}>
													<Row gutter={[0, 0]} align="middle" justify="start">
														<Col span={24}>
															<TypographyCommon
																text="Conta:"
																subtitle=" (XXXXX-X)"
																fontWeight="bold"
																required
																uppercase
															/>
														</Col>
													</Row>
													<Row gutter={[0, 20]} align="middle" justify="start">
														<Col span={24}>
															<Field
																as={InputMask}
																name="compraConta"
																value={formik.values.compraConta}
																mask="99999-9"
																maskChar=""
																error={hasError(
																	formik.errors,
																	'validacaoCompraCreditoEmContaConta',
																)}
																onChange={(
																	e: React.ChangeEvent<HTMLInputElement>,
																) => {
																	formik.setFieldValue(
																		'compraConta',
																		e.currentTarget.value,
																	);
																}}
															/>
														</Col>
													</Row>
												</Col>
											</>
										)}
									</Row>
								</>
							)}

							{formik.values.formaDePagamento === 472890004 && (
								<>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Row gutter={[0, 0]} align="middle" justify="start">
												<Col span={24}>
													<TypographyCommon
														text="Intermediadora de Pagamento:"
														fontWeight="bold"
														required
														uppercase
													/>
												</Col>
											</Row>
											<Row gutter={[0, 20]} align="middle" justify="start">
												<Col span={24}>
													<Field
														as={Input}
														maxLength="80"
														name="compraIntermediadoraDePagamento"
														error={hasError(
															formik.errors,
															'validacaoCompraCreditoEmContaIntermediadoraDePagamento',
														)}
														required
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								</>
							)}

							{formik.values.formaDePagamento === 472890006 && (
								<>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={12} style={stylePaddingCols}>
											<Row gutter={[0, 0]} align="middle" justify="start">
												<Col span={24}>
													<TypographyCommon
														text="Tipo de Chave PIX:"
														fontWeight="bold"
														required
														uppercase
													/>
												</Col>
											</Row>
											<Row gutter={[0, 20]} align="middle" justify="start">
												<Col span={24}>
													<Field
														as={Select}
														name="compraTipoDeChavePix"
														options={tiposChavePix.data ?? []}
														defaultValue=""
														onChange={(v: string) => {
															formik.setFieldValue('compraTipoDeChavePix', v);
															formik.setFieldValue('compraChavePix', '');
														}}
														error={hasError(
															formik.errors,
															'validacaoCompraCreditoEmContaTipoDeChavePix',
														)}
														required
													/>
												</Col>
											</Row>
										</Col>
										<Col span={12}>
											<Row gutter={[0, 0]} align="middle" justify="start">
												<Col span={24}>
													<TypographyCommon
														text="Chave PIX:"
														subtitle={handleSubtitleMaskPix(
															formik.values.compraTipoDeChavePix,
														)}
														fontWeight="bold"
														required
														uppercase
													/>
												</Col>
											</Row>
											<Row gutter={[0, 20]} align="middle" justify="start">
												<Col span={24}>
													<Field
														as={InputMask}
														name="compraChavePix"
														value={formik.values.compraChavePix}
														mask={handleMaskPix(
															formik.values.compraTipoDeChavePix,
														)}
														maskChar=""
														error={hasError(
															formik.errors,
															'validacaoCompraCreditoEmContaChavePix',
														)}
														onChange={(
															e: React.ChangeEvent<HTMLInputElement>,
														) => {
															formik.setFieldValue(
																'compraChavePix',
																e.currentTarget.value,
															);
														}}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								</>
							)}

							{handleViewFormaPagmento(formik.values.formaDePagamento) &&
								formik.values.formaDePagamento && (
									<>
										<br />
										<Row gutter={[0, 0]} align="middle" justify="start">
											<Col className="gutter-row" span={24}>
												<TypographyCommon
													text=""
													borderBottom
													type="h5"
													fontWeight="bold"
													uppercase
												/>
											</Col>
										</Row>
										<br />
									</>
								)}

							<Row gutter={[0, 0]} align="middle" justify="start">
								<TypographyCommon
									text="Descreva detalhes da reclamação:"
									fontWeight="bold"
									subtitle=" (Quantidade Mínima 50 Caracteres e Máxima 1.000)"
									required
									uppercase
								/>
							</Row>
							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={24}>
									<Field
										as={TextArea}
										name="detalhesDaReclamacao"
										maxLength="1000"
										error={hasError(formik.errors, 'detalhesDaReclamacao')}
										required
									/>
								</Col>
							</Row>

							<Row gutter={[0, 0]} align="middle" justify="start">
								<TypographyCommon
									text="Pedido:"
									fontWeight="bold"
									required
									uppercase
								/>
							</Row>
							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={24}>
									<Field
										as={Select}
										name="pedido"
										options={pedidosConsumidor.data ?? []}
										onChange={(v: string) => {
											formik.setFieldValue('formaDeDevolucao', '');
											formik.setFieldValue('descricaoDoPedidoDoConsumidor', '');
											formik.setFieldValue('pedido', v);
										}}
										error={hasError(formik.errors, 'pedido')}
										required
									/>
								</Col>
							</Row>

							<Row gutter={[0, 0]} align="middle" justify="start">
								<TypographyCommon
									text="Descrição do pedido do consumidor:"
									fontWeight="bold"
									subtitle=" (Quantidade Mínima 50 Caracteres e Máxima 2.000)"
									required={
										formik.values.pedido === '39' || formik.values.pedido === 39
									}
									uppercase
								/>
							</Row>
							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={24}>
									<Field
										as={TextArea}
										name="descricaoDoPedidoDoConsumidor"
										maxLength="2000"
										error={hasError(
											formik.errors,
											'descricaoDoPedidoDoConsumidor',
										)}
										disabled={
											formik.values.pedido !== '39' &&
											formik.values.pedido !== 39
										}
									/>
								</Col>
							</Row>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col
									className="gutter-row"
									span={24}
									style={{ paddingBottom: '10px' }}
								>
									<TypographyCommon
										text="Dados do Pagamento da Devolução"
										borderBottom
										type="h5"
										fontWeight="bold"
										uppercase
									/>
								</Col>
							</Row>

							<Row gutter={[0, 0]} align="middle" justify="start">
								<TypographyCommon
									text="Forma de Devolução:"
									fontWeight="bold"
									required={
										formik.values.pedido === 5 || formik.values.pedido === 34
									}
									uppercase
								/>
							</Row>
							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={24}>
									<Field
										as={Select}
										name="formaDeDevolucao"
										options={formasDevolucao.data ?? []}
										onChange={(v: string) => {
											handleClearFieldsCompraOrDevolucao('devolucao', formik);
											formik.setFieldValue('formaDeDevolucao', v);
										}}
										error={hasError(formik.errors, 'validacaoFormaDeDevolucao')}
										required
										disabled={
											formik.values.pedido !== 5 && formik.values.pedido !== 34
										}
									/>
								</Col>
							</Row>

							{(!formik.values.formaDeDevolucao ||
								formik.values.formaDeDevolucao === 3) && <br />}

							{formik.values.formaDeDevolucao &&
								formik.values.formaDeDevolucao !== 3 && (
									<>
										{formik.values.formaDeDevolucao === 1 && (
											<>
												<Row gutter={[0, 20]} align="middle" justify="start">
													<Col span={16} style={stylePaddingCols}>
														<Row gutter={[0, 0]} align="middle" justify="start">
															<Col span={24}>
																<TypographyCommon
																	text="Banco Emissor:"
																	fontWeight="bold"
																	required
																	uppercase
																/>
															</Col>
														</Row>
														<Row
															gutter={[0, 20]}
															align="middle"
															justify="start"
														>
															<Col span={24}>
																<Field
																	as={Input}
																	maxLength="80"
																	name="devolucaoNomeDoBanco"
																	error={hasError(
																		formik.errors,
																		'validacaoDevolucaoCreditoEmContaNomeDoBanco',
																	)}
																	required
																/>
															</Col>
														</Row>
													</Col>
													<Col span={4} style={stylePaddingCols}>
														<Row gutter={[0, 0]} align="middle" justify="start">
															<Col span={24}>
																<TypographyCommon
																	text="Agência:"
																	subtitle=" (XXXX)"
																	fontWeight="bold"
																	required
																	uppercase
																/>
															</Col>
														</Row>
														<Row
															gutter={[0, 20]}
															align="middle"
															justify="start"
														>
															<Col span={24}>
																<Field
																	as={InputMask}
																	name="devolucaoAgencia"
																	value={formik.values.devolucaoAgencia}
																	mask="9999"
																	maskChar=""
																	error={hasError(
																		formik.errors,
																		'validacaoDevolucaoCreditoEmContaAgencia',
																	)}
																	onChange={(
																		e: React.ChangeEvent<HTMLInputElement>,
																	) => {
																		formik.setFieldValue(
																			'devolucaoAgencia',
																			e.currentTarget.value,
																		);
																	}}
																/>
															</Col>
														</Row>
													</Col>
													<Col span={4}>
														<Row gutter={[0, 0]} align="middle" justify="start">
															<Col span={24}>
																<TypographyCommon
																	text="Conta:"
																	subtitle=" (XXXXX-X)"
																	fontWeight="bold"
																	required
																	uppercase
																/>
															</Col>
														</Row>
														<Row
															gutter={[0, 20]}
															align="middle"
															justify="start"
														>
															<Col span={24}>
																<Field
																	as={InputMask}
																	name="devolucaoConta"
																	value={formik.values.devolucaoConta}
																	mask="99999-9"
																	maskChar=""
																	error={hasError(
																		formik.errors,
																		'validacaoDevolucaoCreditoEmContaConta',
																	)}
																	onChange={(
																		e: React.ChangeEvent<HTMLInputElement>,
																	) => {
																		formik.setFieldValue(
																			'devolucaoConta',
																			e.currentTarget.value,
																		);
																	}}
																/>
															</Col>
														</Row>
													</Col>
												</Row>
											</>
										)}

										{formik.values.formaDeDevolucao === 2 && (
											<>
												<Row gutter={[0, 10]} align="middle" justify="start">
													<Col span={16} style={stylePaddingCols}>
														<Row gutter={[0, 0]} align="middle" justify="start">
															<Col span={24}>
																<TypographyCommon
																	text="Banco Emissor:"
																	fontWeight="bold"
																	required
																	uppercase
																/>
															</Col>
														</Row>
														<Row
															gutter={[0, 20]}
															align="middle"
															justify="start"
														>
															<Col span={24}>
																<Field
																	as={Input}
																	maxLength="80"
																	name="devolucaoBancoEmissorDoCartao"
																	error={hasError(
																		formik.errors,
																		'validacaoDevolucaoCreditoEmContaBancoEmissorDoCartao',
																	)}
																	required
																/>
															</Col>
														</Row>
													</Col>
													<Col span={8}>
														<Row gutter={[0, 0]} align="middle" justify="start">
															<Col span={24}>
																<TypographyCommon
																	text="Bandeira do Cartão:"
																	fontWeight="bold"
																	required
																	uppercase
																/>
															</Col>
														</Row>
														<Row
															gutter={[0, 20]}
															align="middle"
															justify="start"
														>
															<Col span={24}>
																<Field
																	as={Select}
																	name="devolucaoBandeiraDoCartao"
																	options={EnumProcon.selectBandeirasDoCartao}
																	defaultValue=""
																	onChange={(v: string) => {
																		formik.setFieldValue(
																			'devolucaoBandeiraDoCartao',
																			v,
																		);
																	}}
																	error={hasError(
																		formik.errors,
																		'validacaoDevolucaoCreditoEmContaBandeiraDoCartao',
																	)}
																	required
																/>
															</Col>
														</Row>
													</Col>
												</Row>

												<Row
													gutter={[0, 0]}
													align="middle"
													justify="start"
													style={{ marginTop: '-20px' }}
												>
													<Col span={12} style={stylePaddingCols}>
														<Row gutter={[0, 0]} align="middle" justify="start">
															<Col span={24}>
																<TypographyCommon
																	text="Número do Cartão:"
																	subtitle=" (9999 99XX XXXX 9999)"
																	fontWeight="bold"
																	required
																	uppercase
																/>
															</Col>
														</Row>
														<Row
															gutter={[0, 20]}
															align="middle"
															justify="start"
														>
															<Col span={24}>
																<Field
																	as={InputMask}
																	name="devolucaoNumeroDoCartao"
																	value={formik.values.devolucaoNumeroDoCartao}
																	mask="9999 99XX XXXX 9999"
																	maskChar=""
																	error={hasError(
																		formik.errors,
																		'validacaoDevolucaoCreditoEmContaNumeroDoCartao',
																	)}
																	onChange={(
																		e: React.ChangeEvent<HTMLInputElement>,
																	) => {
																		formik.setFieldValue(
																			'devolucaoNumeroDoCartao',
																			e.currentTarget.value,
																		);
																	}}
																/>
															</Col>
														</Row>
													</Col>
													<Col span={6} style={stylePaddingCols}>
														<Row gutter={[0, 0]} align="middle" justify="start">
															<Col span={24}>
																<TypographyCommon
																	text="Número de Parcelas:"
																	fontWeight="bold"
																	required
																	uppercase
																/>
															</Col>
														</Row>
														<Row
															gutter={[0, 20]}
															align="middle"
															justify="start"
														>
															<Col span={24}>
																<Field
																	as={Input}
																	maxLength="3"
																	name="devolucaoNumeroDeParcelas"
																	error={hasError(
																		formik.errors,
																		'validacaoDevolucaoCreditoEmContaNumeroDeParcelas',
																	)}
																/>
															</Col>
														</Row>
													</Col>
													<Col span={6}>
														<Row gutter={[0, 0]} align="middle" justify="start">
															<Col span={24}>
																<TypographyCommon
																	text="Valor das Parcelas:"
																	subtitle=" (R$ 0,00)"
																	fontWeight="bold"
																	required
																	uppercase
																/>
															</Col>
														</Row>
														<Row
															gutter={[0, 20]}
															align="middle"
															justify="start"
														>
															<Col span={24}>
																<Money
																	name="devolucaoValorDaParcela"
																	formik={formik}
																	error={hasError(
																		formik.errors,
																		'devolucaoValorDaParcela',
																	)}
																/>
															</Col>
														</Row>
													</Col>
												</Row>
											</>
										)}

										{formik.values.formaDeDevolucao === 4 && (
											<>
												<Row gutter={[0, 20]} align="middle" justify="start">
													<Col span={12} style={stylePaddingCols}>
														<Row gutter={[0, 0]} align="middle" justify="start">
															<Col span={24}>
																<TypographyCommon
																	text="Tipo de Chave PIX:"
																	fontWeight="bold"
																	required
																	uppercase
																/>
															</Col>
														</Row>
														<Row
															gutter={[0, 20]}
															align="middle"
															justify="start"
														>
															<Col span={24}>
																<Field
																	as={Select}
																	name="devolucaoTipoDeChavePix"
																	options={tiposChavePix.data ?? []}
																	defaultValue=""
																	onChange={(v: string) => {
																		formik.setFieldValue(
																			'devolucaoTipoDeChavePix',
																			v,
																		);
																		formik.setFieldValue(
																			'devolucaoChavePix',
																			'',
																		);
																	}}
																	error={hasError(
																		formik.errors,
																		'validacaoDevolucaoCreditoEmContaTipoDeChavePix',
																	)}
																	required
																/>
															</Col>
														</Row>
													</Col>
													<Col span={12}>
														<Row gutter={[0, 0]} align="middle" justify="start">
															<Col span={24}>
																<TypographyCommon
																	text="Chave PIX:"
																	subtitle={handleSubtitleMaskPix(
																		formik.values.devolucaoTipoDeChavePix,
																	)}
																	fontWeight="bold"
																	required
																	uppercase
																/>
															</Col>
														</Row>
														<Row
															gutter={[0, 20]}
															align="middle"
															justify="start"
														>
															<Col span={24}>
																<Field
																	as={InputMask}
																	name="devolucaoChavePix"
																	value={formik.values.devolucaoChavePix}
																	mask={handleMaskPix(
																		formik.values.devolucaoTipoDeChavePix,
																	)}
																	maskChar=""
																	error={hasError(
																		formik.errors,
																		'validacaoDevolucaoCreditoEmContaChavePix',
																	)}
																	onChange={(
																		e: React.ChangeEvent<HTMLInputElement>,
																	) => {
																		formik.setFieldValue(
																			'devolucaoChavePix',
																			e.currentTarget.value,
																		);
																	}}
																/>
															</Col>
														</Row>
													</Col>
												</Row>
											</>
										)}
									</>
								)}

							<br />

							{/* <div
								style={{
									marginTop: handleCalcMarginFormaDevolucao(
										formik.values.formaDeDevolucao,
									),
								}}
							> */}
							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col
									className="gutter-row"
									span={24}
									style={{ paddingBottom: '10px' }}
								>
									<TypographyCommon
										text="Anexos"
										borderBottom
										type="h5"
										fontWeight="bold"
										uppercase
									/>
								</Col>
							</Row>

							<Anexos formik={formik} />

							<Row gutter={[0, 0]} align="top" justify="start">
								<Col className="gutter-row" span="12">
									<LightButton
										type="button"
										buttonColor="default"
										text="Voltar"
										icon="back"
										buttonSize="sm"
										buttonWidth={100}
										onClick={handleBack}
									/>
								</Col>
								<Col className="gutter-row" span="12">
									<Row align="top" justify="end">
										<LightButton
											id="btn_salvar_reclamacao"
											type="submit"
											buttonColor="default"
											text="Salvar"
											icon="save"
											buttonSize="sm"
											buttonWidth={140}
										/>
									</Row>
								</Col>
							</Row>

							<Modal
								title={getTitle()}
								visible={showModal !== 'none'}
								onCancel={() => {
									dispatch(consultaConsumidorClear());
									dispatch(clearNotifications());
									setShowModal('none');
									formik.setFieldValue('temProcurador', false);
									formik.setFieldValue('nomeDoResponsavelOuProcurador', '');
									formik.setFieldValue('cpfDoProcuradorOuResponsavel', '');
									formik.setFieldValue('rgDoResponsavelOuProcurador', '');
								}}
								width={900}
								footer={null}
								style={{ animationDuration: '0s' }}
								destroyOnClose
							>
								{showModal === 'consumidor' && (
									<PesquisarConsumidor
										formikParent={formik}
										setShowModal={() => setShowModal('none')}
										activeTab={activeTab}
										typeRegistration="reclamacao"
									/>
								)}

								{showModal === 'fornecedor' && (
									<PesquisarFornecedor
										formikParent={formik}
										setShowModal={() => setShowModal('none')}
										typeRegistration="reclamacao"
									/>
								)}
								{showModal === 'procurador' && (
									<ProcuradorOuResponsavel
										formikParent={formik}
										setShowModal={() => {
											setShowModal('none');
										}}
									/>
								)}
							</Modal>
						</Section>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CadastrarReclamacao;
