import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import data from 'store/modules/api/detranCrv/enum';
import {
	consultarMunicipioCodigoProdesClear,
	consultarMunicipioCodigoProdespRequest,
} from 'store/modules/api/detranCrv/enum/consultarMunicipioCodigoProdesp/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';

// UTILS
import hasError from 'utils/getFormErrors';

// FORM
import { IEmitirSegundaViaPlaca } from '../form';

interface Props {
	initialValues: IEmitirSegundaViaPlaca;
	formik: FormikProps<IEmitirSegundaViaPlaca>;
}

const DadosProprietario: React.FC<Props> = ({ initialValues, formik }) => {
	const dispatch = useDispatch();

	const { consultarMunicipioPorUF, consultarMunicipioCodigoProdesp } =
		useSelector((state: ApplicationState) => state.api.detranCrv.Enum);

	const { consultarVeiculoSegundaViaPlaca } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const [tipoPessoa, setTipoPessoa] = useState<string>('');
	const [cpfCnpj, setCpfCnpj] = useState<string>('');

	useEffect(() => {
		if (initialValues) {
			const { identificacao, cpfProprietario, cnpjProprietario } =
				initialValues;

			if (identificacao === 'FISICA' || identificacao === 'JURIDICA') {
				setTipoPessoa(identificacao);

				if (
					identificacao === 'FISICA' &&
					String(cpfProprietario).length === 11
				) {
					setCpfCnpj(String(cpfProprietario));
				}

				if (
					identificacao === 'JURIDICA' &&
					String(cnpjProprietario).length === 14
				) {
					setCpfCnpj(String(cnpjProprietario));
				}
			}
		}
	}, [initialValues]);

	useEffect(() => {
		if (
			consultarMunicipioCodigoProdesp.data &&
			consultarMunicipioCodigoProdesp.status === 200
		) {
			formik.setFieldValue(
				'codigoMunicipioProprietario',
				Number(consultarMunicipioCodigoProdesp.data.codigoPRODESP),
			);
			dispatch(consultarMunicipioCodigoProdesClear());
		}
	}, [
		consultarMunicipioCodigoProdesp.data,
		consultarMunicipioCodigoProdesp.status,
		dispatch,
		formik,
	]);

	const clearFormikAddressFields = useCallback(() => {
		formik.setFieldValue('complementoProprietario', '');
		formik.setFieldValue('numeroProprietario', '');
	}, [formik]);

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado) => {
			clearFormikAddressFields();

			if (res.codigoMunicipio) {
				const consultaMunicipioData = {
					evento: null,
					codigoMunicipioCorreio: res.codigoMunicipio.toString(),
				};
				dispatch(consultarMunicipioCodigoProdespRequest(consultaMunicipioData));
			}
			formik.setFieldValue('cepProprietario', res.cep);
			formik.setFieldValue('bairroProprietario', res.bairro.substring(0, 70));
			formik.setFieldValue(
				'enderecoProprietario',
				res.endereco.substring(0, 70),
			);
		},
		[clearFormikAddressFields, dispatch, formik],
	);

	return (
		<>
			{consultarVeiculoSegundaViaPlaca.data && (
				<>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Section size="lg" title="Dados do Proprietário" titleSize="sm">
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Field
											as={Input}
											title="Nome"
											titleSize="lg"
											name="nomeProprietario"
											maxLength={60}
											size={87}
											disabled
											error={!!formik.errors.nomeProprietario}
										/>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={11}>
										<CEP
											title="CEP do Imóvel"
											titleSize="lg"
											name="cepProprietario"
											defaultValue={formik.values.cepProprietario}
											disabled
											size={80}
											formik={formik}
											result={(res: IBuscarCepResultado) => {
												handleCepSelecionado(res);
											}}
										/>
									</Col>

									<Col span={11}>
										<Field
											as={Input}
											disabled
											title="Bairro"
											titleSize="lg"
											name="bairroProprietario"
											maxLength={70}
											size={92}
											error={!!formik.errors.bairroProprietario}
										/>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={11}>
										<Field
											as={Input}
											disabled
											title="Endereço"
											titleSize="lg"
											name="enderecoProprietario"
											maxLength={70}
											size={80}
											error={!!formik.errors.enderecoProprietario}
										/>
									</Col>

									<Col span={11}>
										<Field
											as={InputMask}
											title="Número"
											titleSize="lg"
											disabled
											name="numeroProprietario"
											mask="99999"
											maskChar=""
											alwaysShowMask={false}
											size={92}
											error={!!formik.errors.numeroProprietario}
										/>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={11}>
										<Field
											as={Input}
											title="Complemento"
											titleSize="lg"
											disabled
											name="complementoProprietario"
											maxLength={6}
											size={80}
											error={!!formik.errors.complementoProprietario}
										/>
									</Col>

									<Col span={11}>
										{/* <Field
											as={InputMask}
											title="Telefone"
											titleSize="lg"
											name="telefone"
											mask="(99) 9999-9999"
											size={92}
											error={!!formik.errors.telefone}
										/> */}
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={11}>
										<Field
											as={Select}
											title="Município"
											titleSize="lg"
											disabled
											name="codigoMunicipioProprietario"
											options={consultarMunicipioPorUF.data}
											onChange={(v: number) => {
												formik.setFieldValue('codigoMunicipioProprietario', v);

												const selectedItem =
													consultarMunicipioPorUF.data.filter(
														item => v === item.value,
													);

												formik.setFieldValue(
													'descricaoMunicipioProprietario',
													selectedItem[0].label,
												);
											}}
											size={80}
											error={hasError(formik.errors, 'municipioProprietario')}
										/>
									</Col>
								</Row>

								<Row
									gutter={
										tipoPessoa === 'FISICA' ||
										formik.values.identificacao === 'FISICA'
											? [0, 10]
											: undefined
									}
								>
									<Col span={11}>
										<Field
											as={Select}
											title="Identificação Contribuinte"
											titleSize="lg"
											name="identificacao"
											options={data.tipoPessoa}
											onChange={(v: string) => {
												formik.setFieldValue('identificacao', v);

												if (v === 'FISICA') {
													formik.setFieldValue('cnpjProprietario', '');
													return;
												}

												if (v === 'JURIDICA') {
													formik.setFieldValue('cpfProprietario', '');
													return;
												}

												formik.setFieldValue('cpfProprietario', '');
												formik.setFieldValue('cnpjProprietario', '');
											}}
											size={80}
											disabled={tipoPessoa !== ''}
											error={hasError(formik.errors, 'identificacao')}
										/>
									</Col>

									<Col span={11}>
										{formik.values.identificacao === '' && (
											<Input
												title="CPF/CNPJ do Proprietário"
												titleSize="lg"
												name=""
												disabled
												size={92}
												error={
													hasError(formik.errors, 'cpf') ||
													hasError(formik.errors, 'cnpj')
												}
											/>
										)}

										{formik.values.identificacao === 'FISICA' && (
											<Field
												as={InputMask}
												title="CPF/CNPJ do Proprietário"
												titleSize="lg"
												name="cpfProprietario"
												mask="999.999.999-99"
												size={92}
												disabled={cpfCnpj !== ''}
												error={hasError(formik.errors, 'cpf')}
											/>
										)}

										{formik.values.identificacao === 'JURIDICA' && (
											<Field
												as={InputMask}
												title="CPF/CNPJ do Proprietário"
												titleSize="lg"
												name="cnpjProprietario"
												mask="99.999.999/9999-99"
												size={92}
												disabled={cpfCnpj !== ''}
												error={hasError(formik.errors, 'cnpj')}
											/>
										)}
									</Col>
								</Row>

								{(tipoPessoa === 'FISICA' ||
									formik.values.identificacao === 'FISICA') && (
									<>
										<Row gutter={[0, 10]}>
											<Col span={11}>
												<Row>
													<Col span={18}>
														<Field
															as={InputMask}
															title="RG"
															titleSize="lg"
															name="rgProprietario"
															mask="99.999.999"
															error={hasError(formik.errors, 'rgProprietario')}
														/>
													</Col>
													<Col
														span={1}
														style={{
															alignSelf: 'center',
															textAlign: 'center',
															marginLeft: '2px',
															marginRight: '2px',
														}}
													>
														<span>-</span>
													</Col>
													<Col span={2}>
														<Field
															as={InputMask}
															mask="9"
															name="rgDigito"
															error={hasError(formik.errors, 'rgDigito')}
														/>
													</Col>
												</Row>
											</Col>
											<Col span={11}>
												<Field
													as={Input}
													title="Órgão Expedidor"
													titleSize="lg"
													name="orgaoExpeditorProprietario"
													maxLength={5}
													size={40}
													error={!!formik.errors.orgaoExpeditorProprietario}
												/>
											</Col>
										</Row>

										<Row gutter={[0, 10]}>
											<Col
												span={11}
												offset={1}
												style={{
													paddingLeft: '2px',
												}}
											>
												<Field
													as={Select}
													title="UF"
													name="ufProprietario"
													defaultFirstOption={false}
													options={data.uf}
													onChange={(value: string) =>
														formik.setFieldValue('ufProprietario', value)
													}
													size={35}
												/>
											</Col>
										</Row>
									</>
								)}
							</Section>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default DadosProprietario;
