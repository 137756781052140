export enum Types {
	IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_REQUEST = '@DETRAN-CRV/IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_REQUEST',
	IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_SUCCESS = '@DETRAN-CRV/IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_SUCCESS',
	IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_FAILURE = '@DETRAN-CRV/IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_FAILURE',
	IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_CLEAR = '@DETRAN-CRV/IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_CLEAR',
}

export interface impressaoSegundaViaLicenciamento {
	status: number;
	data: ficha | null;
}

interface ficha {
	anoFicha: number;
	chassi: string;
	numeroficha: number;
	placa: string;
	fichaCadastral: string;
}
