export enum Types {
	SIMULAR_EFETUAR_ACORDO_REQUEST = '@cdhu/SIMULAR_EFETUAR_ACORDO_REQUEST',
	SIMULAR_EFETUAR_ACORDO_SUCCESS = '@cdhu/SIMULAR_EFETUAR_ACORDO_SUCCESS',
	SIMULAR_EFETUAR_ACORDO_FAILURE = '@cdhu/SIMULAR_EFETUAR_ACORDO_FAILURE',
	SIMULAR_EFETUAR_ACORDO_CLEAR = '@cdhu/SIMULAR_EFETUAR_ACORDO_CLEAR',
}

export interface SimularEftuarAcordo {
	status: number;
	data: SimularEftuarAcordoData | null;
	request: SimularEftuarAcordoRequest | null;
}

export interface SimularEftuarAcordoData {
	aceite: string;
	acordoId: string;
	carteira: string;
	cedente: string;
	codigoBarras: string;
	codigoDigitavel: string;
	contaCedente: string;
	dadosContrato: string;
	dadosContrato2: string;
	dadosContrato3: string;
	dadosContrato4: string;
	dadosContrato5: string;
	dadosContrato6: string;
	dadosContrato7: string;
	dadosContrato8: string;
	dadosPrestacao: string;
	dadosPrestacao2: string;
	dadosPrestacao3: string;
	dadosPrestacao4: string;
	dadosPrestacao5: string;
	dadosPrestacao6: string;
	dadosPrestacao7: string;
	dadosPrestacao8: string;
	dataAcordo: string;
	dataDocumento: string; // yyyy-MM-dd,
	dataFimParcela: string; // MM/AAAA,
	dataInicioPrestacaoAtrasada: string; // MMAAAA,
	dataInicioParcela: string; // MM/AAAA,
	dataLimiteBoleto: string; // 2017-07-21T17:32:28Z,
	dataPrestacaoFinal: string;
	dataPrestacaoInicial: string;
	dataProcessamentoDocumento: string; // yyyy-MM-dd,
	dataRecebimento: string; // yyyy-MM-dd,
	dataUltimaPrestacaoBoleto: string; // yyyy-MM-dd,
	dataVencimento: string; // yyyy-MM-dd,
	dataVencimentoBoleto: string; // yyyy-MM-dd,
	dataVencimentoPrestacao: string;
	dtVencimento: string; // 2024-03-27T12:01:16.005Z,
	efetivacaoProdesp: string;
	especieDocumento: string;
	especieMoeda: string;
	instrucoes: string;
	instrucoes2: string;
	instrucoes3: string;
	instrucoes4: string;
	instrucoes5: string;
	instrucoes6: string;
	instrucoes7: string;
	instrucoes8: string;
	localPagamento: string;
	mensagemAoSacado: string;
	mensagemAoSacado2: string;
	mensagemAoSacado3: string;
	mensagemAoSacado4: string;
	mensagemAoSacado5: string;
	nossoNumero: string;
	novaPrestacaoLiquida: string;
	novoPrazo: string;
	numeroDocumento: string;
	numeroPrestacao: string;
	periodoFinalAcordo: string; // MM/yyyy,
	periodoInicialAcordo: string; // MM/yyyy,
	prazoAcordo: string;
	prestacaoLiquidaAtual: string;
	processaEfeticacao: string;
	processaSimulacao: string;
	qtdParcelasAcordo: string;
	qtdPrestacoesAtraso: string;
	qtdPrestacoesParcelaInicial: string;
	quantidadeMoeda: string;
	remetente: string;
	sacado: string;
	sacado2: string;
	sacado3: string;
	sacado4: string;
	sacado5: string;
	sacado6: string;
	sacado7: string;
	tipoBoleto: string;
	tipoBoletoGerado: string;
	tipoReajuste: string;
	urlBoletoGerado: string;
	usoBanco: string;
	valor: string;
	valorCorrecaoMonetaria: string;
	valorCustas: string;
	valorDocumento: string;
	valorHonorario: string;
	valorJuros: string;
	valorNotificacao: string;
	valorParcelaAcordo: string;
	valorParcelaInicial: string;
	valorParcial: string;
	valorPrestacaoSuspensa: string;
	valorPrestacaoSuspensaAtualizada: string;
	valorPrestacoesAtrasadas: string;
	valorRecebido: string;
	valorRestanteDebito: string;
	valorTotalAcordo: string;
	valorTotalTaxas: string;
	boletoId: string;
	valorDebitoAcordoAnt: string;
	valorDebitoPrest: string;
	statusField: number;
	mensagemField: string;
}

export interface SimularEftuarAcordoRequest {
	servicoEnum: string; // 'SIMULAR_ACORDO'
	acordoId?: string;
	ocorrenciaId: string;
	amortizacaoInicial?: number;
	contratoCDHU: number;
	dataVencimentoBoleto?: string; // 'yyyy-MM-dd',
	novoPrazo?: number;
	tipoSimulacao?: number;
}
