import * as Yup from 'yup';
import { validaCpf } from 'utils/genericFunctions';

export interface IFormConsultarSolicitante {
	tipoVinculo: number | string;
	cpf: string;
	numContrato: number | string;
}

export const initialValues: IFormConsultarSolicitante = {
	tipoVinculo: '',
	cpf: '',
	numContrato: '',
};

export const schema = Yup.object<IFormConsultarSolicitante>().shape(
	{
		cpf: Yup.string().test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF digitado inválido.',
			test: value => validaCpf(value),
		}),
		tipoVinculo: Yup.string().required(
			'Campo Obrigatório: Tipo de vínculo com a CDHU.',
		),
		cpfNumContrato: Yup.string()
			.when(['tipoVinculo', 'cpf'], {
				is: (tipoVinculo, cpf) => tipoVinculo !== '808620001' && cpf === '',
				then: Yup.string().required('Campo Obrigatório: CPF.'),
			})
			.when(['tipoVinculo', 'cpf', 'numContrato'], {
				is: (tipoVinculo, cpf, numContrato) =>
					tipoVinculo === '808620001' && cpf === '' && numContrato === '',
				then: Yup.string().required(
					'Preencha pelo menos um dos dois campos! CPF ou o Número do contrato.',
				),
			})
			.when(['tipoVinculo', 'cpf', 'numContrato'], {
				is: (tipoVinculo, cpf, numContrato) =>
					tipoVinculo === '808620001' &&
					cpf !== '' &&
					cpf.length !== 11 &&
					numContrato === '',
				then: Yup.string().required(
					'Campo CPF: Tamanho mínimo de 11 caracteres.',
				),
			})
			.when(['tipoVinculo', 'numContrato'], {
				is: (tipoVinculo, numContrato) =>
					tipoVinculo === '808620001' &&
					numContrato !== '' &&
					String(numContrato).length !== 7,
				then: Yup.string().required(
					'Campo Número do contrato: Tamanho mínimo de 7 caracteres.',
				),
			}),
	},
	[['cpf', 'numContrato']],
);
