import React, { useEffect, useState, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { Col, Row } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_SECRETARIA_EDUCACAO } from 'routes/paths';
import { ROUTE_SECRETARIA_EDUCACAO_CANCELAMENTO_PESQUISAR_ALUNO } from 'pages/EducacaoUpdate/routes/paths';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { consultarInscricaoClear } from 'store/modules/api/educacaoUpdate/inscricao/consultarInscricao/actions';
import { removerInscricaoRequest } from 'store/modules/api/educacaoUpdate/inscricao/removerInscricao/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import Alert from 'components/Common/Notifications/Alert';
import Button from 'components/Common/Button';
import { ContentButton } from 'pages/iirgd/Aac/Numeral/Consultar/styled';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import DadosPessoais from './DadosPessoais';
import Documentos from './Documentos';
import DadosInscricao from './DadosInscricao';

// FORM
import { IVisualizarFicha, initialValues } from './form';

const VizualizaFichaAluno = () => {
	const [InitialValues, setInitialValues] = useState(initialValues);
	const [inscricaoRemovida, setInscricaoRemovida] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();

	const { consultaFichaAluno } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.fichaAluno,
	);

	const { consultarInscricao, removerInscricao } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.inscricao,
	);

	const { cpf, ip, nomePosto, posto } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.user,
	);

	const { data: dataRecuperarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.recuperarAtendimento,
	);

	const { data: dataSalvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const evento = useMemo(() => {
		const cpfAluno = consultaFichaAluno.data?.documentos?.cpf;
		return {
			id_atendimento: dataSalvarAtendimento?.idAtendimento || '',
			id_cidadao:
				dataRecuperarAtendimento?.idCidadao ||
				'00000000-0000-0000-0000-000000000000',
			cpf,
			identificacao: cpfAluno || '0',
			ip,
			canal: {
				id: 1,
				desc: 'Presencial',
				localidade: {
					id: parseInt(posto, 10),
					desc: nomePosto,
				},
			},
		};
	}, [
		consultaFichaAluno.data,
		cpf,
		dataRecuperarAtendimento,
		dataSalvarAtendimento,
		ip,
		nomePosto,
		posto,
	]);

	useEffect(() => {
		if (consultaFichaAluno.data) {
			const { dadosPessoais, justificativaDocumentos } =
				consultaFichaAluno.data;

			if (dadosPessoais) {
				// Aluno
				initialValues.aluno.numeroRA = dadosPessoais?.numRA?.trim() || '';
				initialValues.aluno.digitoRA = dadosPessoais?.digitoRA?.trim() || '';
				initialValues.aluno.siglaUFRA = dadosPessoais?.siglaUFRA?.trim() || '';
			}

			const { documentos } = consultaFichaAluno.data;
			// Documentos
			initialValues.documentos.codigoINEP = documentos?.codigoINEP || '';
			initialValues.documentos.cpf = documentos?.cpf || '';
			initialValues.documentos.numeroDocumentoCivil =
				documentos?.numDocumentoCivil || '';
			initialValues.documentos.digitoDocumentoCivil =
				documentos?.digitoDocumentoCivil || '';
			initialValues.documentos.ufDocumentoCivil =
				documentos?.ufDocumentoCivil || '';
			initialValues.documentos.dataEmissaoDocumentoCivil =
				documentos?.dataEmissaoDocumentoCivil || '';
			initialValues.documentos.dataEmissaoCertidao =
				documentos?.dataEmissaoCertidao || '';
			initialValues.documentos.numeroNIS = documentos?.numeroNis || '';
			initialValues.documentos.numeroRG = documentos?.numDocumentoCivil || '';
			initialValues.documentos.digitoRG =
				documentos?.digitoDocumentoCivil || '';
			initialValues.documentos.ufRG = documentos?.ufDocumentoCivil || '';
			initialValues.documentos.justificativaDocumentos =
				justificativaDocumentos || '';
			initialValues.documentos.tipoCertidao = '';

			// Dados Pessoais
			initialValues.dadosPessoais.numeroRA = dadosPessoais?.numRA?.trim() || '';
			initialValues.dadosPessoais.digitoRA =
				dadosPessoais?.digitoRA?.trim() || '';
			initialValues.dadosPessoais.siglaUFRA =
				dadosPessoais?.siglaUFRA?.trim() || '';
			initialValues.dadosPessoais.nomeAluno = dadosPessoais?.nomeAluno || '';
			initialValues.dadosPessoais.dataNascimento =
				dadosPessoais?.dataNascimento || '';
			initialValues.dadosPessoais.corRaca = dadosPessoais?.corRaca || '';
			initialValues.dadosPessoais.descricaoCorRaca =
				dadosPessoais?.corRaca || '';
			initialValues.dadosPessoais.codigoSexo = dadosPessoais?.codSexo || '';
			initialValues.dadosPessoais.sexo = dadosPessoais?.sexo || '';
			initialValues.dadosPessoais.nomeMae = dadosPessoais?.nomeMae || '';
			initialValues.dadosPessoais.nomePai = dadosPessoais?.nomePai || '';
			initialValues.dadosPessoais.nomeSocial = dadosPessoais?.nomeSocial || '';
			initialValues.dadosPessoais.nomeAfetivo =
				dadosPessoais?.nomeAfetivo || '';
			initialValues.dadosPessoais.email = dadosPessoais?.email || '';
			initialValues.dadosPessoais.nacionalidade =
				dadosPessoais?.nacionalidade || '';
			initialValues.dadosPessoais.descricaoNacionalidade =
				dadosPessoais?.descricaoNacionalidade || '';
			initialValues.dadosPessoais.codigoPaisOrigem =
				dadosPessoais?.codPaisOrigem || '';
			initialValues.dadosPessoais.nomePaisOrigem =
				dadosPessoais?.nomePaisOrigem || '';
			initialValues.dadosPessoais.dataEntradaPais =
				dadosPessoais?.dataEntradaPais || '';
			initialValues.dadosPessoais.bolsaFamilia = dadosPessoais?.bolsaFamilia
				? 'S'
				: '';
			initialValues.dadosPessoais.quilombola = dadosPessoais?.quilombola
				? 'S'
				: '';
			initialValues.dadosPessoais.nomeMunicipioNascimento =
				dadosPessoais?.nomeMunicipioNascimento || '';
			initialValues.dadosPessoais.ufMunicipioNascimento =
				dadosPessoais?.ufMunicipioNascimento || '';
			initialValues.dadosPessoais.alunoFalecido = dadosPessoais?.alunoFalecido
				? 'S'
				: '';
			initialValues.dadosPessoais.dataFaleciomento =
				dadosPessoais?.dataFalecimento || '';
			initialValues.dadosPessoais.codigoBolsaFamilia = false;
			initialValues.dadosPessoais.possuiInternet = dadosPessoais?.possuiInternet
				? 'S'
				: '';
			initialValues.dadosPessoais.possuiNotebookSmartphoneTablet =
				dadosPessoais?.possuiNotebookSmartphoneTablet ? 'S' : '';

			if (
				consultaFichaAluno.data.certidaoAntiga?.numCertidao &&
				consultaFichaAluno.data.certidaoAntiga.numCertidao !== ''
			) {
				const { certidaoAntiga } = consultaFichaAluno.data;
				initialValues.documentos.tipoCertidao = 'ANTIGA';
				initialValues.certidaoAntiga.numeroCertidao =
					certidaoAntiga?.numCertidao || '';
				initialValues.certidaoAntiga.numeroLivroRegistro =
					certidaoAntiga?.livro || '';
				initialValues.certidaoAntiga.numeroFolhaRegistro =
					certidaoAntiga?.folha || '';
				initialValues.certidaoAntiga.nomeMunicipioComarca =
					certidaoAntiga?.municipioComarca || '';
				initialValues.certidaoAntiga.ufComarca =
					certidaoAntiga?.ufComarca || '';
				initialValues.certidaoAntiga.distritoNascimento =
					certidaoAntiga?.distritoCertidao || '';
				initialValues.certidaoAntiga.dataEmissaoCertidao =
					documentos?.dataEmissaoCertidao || '';
			} else if (
				consultaFichaAluno.data.certidaoNova?.certMatr01 &&
				consultaFichaAluno.data.certidaoNova.certMatr01 !== ''
			) {
				const { certidaoNova } = consultaFichaAluno.data;
				initialValues.documentos.tipoCertidao = 'NOVA';
				initialValues.certidaoNova.certificadoMatricula01 =
					certidaoNova?.certMatr01 || '';
				initialValues.certidaoNova.certificadoMatricula02 =
					certidaoNova?.certMatr02 || '';
				initialValues.certidaoNova.certificadoMatricula03 =
					certidaoNova?.certMatr03 || '';
				initialValues.certidaoNova.certificadoMatricula04 =
					certidaoNova?.certMatr04 || '';
				initialValues.certidaoNova.certificadoMatricula05 =
					certidaoNova?.certMatr05 || '';
				initialValues.certidaoNova.certificadoMatricula06 =
					certidaoNova?.certMatr06 || '';
				initialValues.certidaoNova.certificadoMatricula07 =
					certidaoNova?.certMatr07 || '';
				initialValues.certidaoNova.certificadoMatricula08 =
					certidaoNova?.certMatr08 || '';
				initialValues.certidaoNova.certificadoMatricula09 =
					certidaoNova?.certMatr09 || '';
			} else {
				initialValues.documentos.tipoCertidao = '';
			}

			if (consultarInscricao.data) {
				const { inscricaoSelecionada } = consultarInscricao.data;
				// DADOS DA INSCRIÇÃO
				initialValues.inscricoes.codigoEscola =
					inscricaoSelecionada?.codigoEscola || '';
				initialValues.inscricoes.tipo = inscricaoSelecionada?.tipo || '';
				initialValues.inscricoes.descricaoTipo =
					inscricaoSelecionada?.descricaoTipo || '';
				initialValues.inscricoes.situacao =
					inscricaoSelecionada?.situacao || '';
			}

			if (consultarInscricao.request) {
				const { anoLetivo, numeroRA, digitoRA, siglaUFRA } =
					consultarInscricao.request;
				// DADOS DA INSCRIÇÃO
				initialValues.dadosRequisicaoInscricao.anoLetivo = anoLetivo || 0;
				initialValues.dadosRequisicaoInscricao.numeroRA = numeroRA || 0;
				initialValues.dadosRequisicaoInscricao.digitoRA = digitoRA || 0;
				initialValues.dadosRequisicaoInscricao.siglaUF = siglaUFRA || '';
			}

			setInitialValues(initialValues);
		}
	}, [
		consultaFichaAluno.data,
		consultarInscricao.data,
		consultarInscricao.request,
	]);

	const handleSubmit = (values: IVisualizarFicha) => {
		const dataRequest = {
			evento,
			aluno: {
				numeroRA: values.dadosRequisicaoInscricao.numeroRA,
				digitoRA: values.dadosRequisicaoInscricao.digitoRA,
				siglaUFRA: values.dadosRequisicaoInscricao.siglaUF,
			},
			anoLetivo: values.dadosRequisicaoInscricao.anoLetivo,
			codigoEscola: Number(values.inscricoes.codigoEscola),
			tipoInscricao: Number(values.inscricoes.tipo),
		};
		dispatch(removerInscricaoRequest(dataRequest));
	};

	useEffect(() => {
		if (removerInscricao.status === 200 || removerInscricao.status === 201) {
			window.scrollTo(0, 0);
			setInscricaoRemovida(true);
		}
	}, [removerInscricao]);

	return (
		<>
			<Formik
				enableReinitialize
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={InitialValues}
				onSubmit={values => handleSubmit(values)}
			>
				{formik => (
					<>
						<Form autoComplete="off">
							{inscricaoRemovida && (
								<Row gutter={[0, 20]}>
									<Col span={24}>
										<Alert
											type="info"
											message={<p>Inscrição removida com sucesso!</p>}
											onClose={() => setInscricaoRemovida(false)}
										/>
									</Col>
								</Row>
							)}
							<Section title="Ficha Aluno">
								<Col span={24}>
									<Row gutter={[0, 20]}>
										<Col span={24}>
											<DadosPessoais formik={formik} />
										</Col>
									</Row>
									<Row gutter={[0, 20]}>
										<Col span={24}>
											<Documentos formik={formik} />
										</Col>
									</Row>
									<Row gutter={[0, 20]}>
										<Col span={24}>
											<DadosInscricao formik={formik} />
										</Col>
									</Row>
								</Col>
								<ContentButton justify="center">
									{!inscricaoRemovida ? (
										<Button
											onClick={() => {
												formik.handleSubmit();
											}}
										>
											Cancelar Inscrição
										</Button>
									) : (
										<Button
											onClick={() => {
												history.push(ROUTE_SECRETARIA_EDUCACAO);
												dispatch(consultarInscricaoClear());
											}}
										>
											Encerrar
										</Button>
									)}
								</ContentButton>
							</Section>
							<ButtonVoltar
								navigate={false}
								onClick={() => {
									dispatch(consultarInscricaoClear());
									history.push(
										ROUTE_SECRETARIA_EDUCACAO_CANCELAMENTO_PESQUISAR_ALUNO,
									);
								}}
							/>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default VizualizaFichaAluno;
