import { NotificationsRequest, Types } from './types';
import { store } from '../../../index';

export function addNotifications(data: NotificationsRequest) {
	store.dispatch({
		type: Types.ADD_NOTIFICATIONS,
		payload: data,
	});
}

export function showInside(data: boolean) {
	store.dispatch({
		type: Types.SHOW_INSIDE,
		payload: data,
	});
}

export function clearNotificationsValidate() {
	store.dispatch({
		type: Types.CLEAR_NOTIFICATIONS,
		payload: null,
	});
}

export function clearNotifications() {
	return {
		type: Types.CLEAR_NOTIFICATIONS,
		payload: null,
	};
}

export function addNotificationsDireto(data: any) {
	return {
		type: Types.ADD_NOTIFICATIONS,
		payload: data,
	};
}
