import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarEndereco } from './types';

export const INITIAL_STATE: ConsultarEndereco = {
	status: 0,
	data: null,
};

export default function consultarEnderecoCartao(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarEndereco {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_ENDERECO: {
				break;
			}

			case Types.CONSULTAR_ENDERECO_SUCCESS: {
				// const dataTabelas = action.payload.data.tabelas[0];
				draft.status = 200;
				draft.data = action.payload;
				break;
			}

			case Types.CONSULTAR_ENDERECO_FAILURE: {
				draft.status = 0;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.LIMPAR_CONSULTAR_ENDERECO: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
