import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { Types, IDownloadDocumentosRequest } from './types';
import {
	downloadDocumentosSuccess,
	downloadDocumentosFailure,
} from './actions';

function* downloadDocumentosRequest(request: ReducerAction) {
	const { payload }: { payload: IDownloadDocumentosRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`fichas/${CHANNEL}/download-documentos`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(downloadDocumentosSuccess(response));
	} else if (response.error) {
		yield put(downloadDocumentosFailure(response));
	}
}

export default all([
	takeLatest(Types.DOWNLOAD_DOCUMENTO_REQUEST, downloadDocumentosRequest),
]);
