import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_RELATORIOS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { AnamneseRequest, Types } from './types';

// ACTIONS
import { anamneseSuccess, anamneseFailure } from './actions';

function* anamnese(request: ReducerAction) {
	const { payload }: { payload: AnamneseRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_RELATORIOS,
		`detran-cnh/${CHANNEL}/anamnese`,
		payload,
	);
	if (response.status === 200) {
		yield put(anamneseSuccess(response));
	} else if (response.error) {
		yield put(anamneseFailure(response.error));
	}
}

export default all([takeLatest(Types.ANAMNESE_REQUEST, anamnese)]);
