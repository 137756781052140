// import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_PARAMETROS_SISTEMA_REDIS = '@SGU/CONSULTAR_PARAMETROS_SISTEMA_REDIS',
	CONSULTAR_PARAMETROS_SISTEMA_REDIS_SUCCESS = '@SGU/CONSULTAR_PARAMETROS_SISTEMA_REDIS_SUCCESS',
	CONSULTAR_PARAMETROS_SISTEMA_REDIS_FAILURE = '@SGU/CONSULTAR_PARAMETROS_SISTEMA_REDIS_FAILURE',
	CONSULTAR_PARAMETROS_SISTEMA_REDIS_CLEAR = '@SGU/CONSULTAR_PARAMETROS_SISTEMA_REDIS_CLEAR',
}

export interface ConsultarParametrosSistemaRedis {
	status: number;
	data: ParametrosSistemaData[] | null;
}

export interface ParametrosSistemaData {
	chave: string;
	valor: string;
}
