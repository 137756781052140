export enum Types {
	CONSULTA_CONSULTA_CPF_REQUEST = '@iirgd/CONSULTA_CICP_CONSULTA_CPF_REQUEST',
	CONSULTA_CONSULTA_CPF_SUCCESS = '@iirgd/CONSULTA_CICP_CONSULTA_CPF_SUCCESS',
	CONSULTA_CONSULTA_CPF_FAILURE = '@iirgd/CONSULTA_CICP_CONSULTA_CPF_FAILURE',
	CONSULTA_CONSULTA_CPF_CLEAR = '@iirgd/CONSULTA_CICP_CONSULTA_CPF_CLEAR',
}

export interface ConsultaCpfState {
	data: ConsultaCpf[] | null;
	status: number;
}

export interface ConsultaCpf {
	atendimentoCIN?: string;
	numeroRg: number;
	nome: string;
	nomePai: string;
	nomeMae: string;
	sexo: string;
	dataNascimento: string;
	maior: boolean;
	codigoNaturalidade: string;
	municipioNaturalidade: string;
	pidt: string;
	dbas: string;
	lote: string;
	cpf: string;
	pis: string;
	pasep: string;
	comarca: string;
	cartorio: string;
	livro: string;
	folha: string;
	numero: string;
	dataDocumento: string;
	pMinLei1: string;
	pMinLei2: string;
	pMinLei3: string;
	entrega: string;
	entrega2: string;
	estadoCivil: string;
	cutis: string;
	cabelos: string;
	olhos: string;
	alturaM: string;
	alturaCm: string;
	grauInstrucao: string;
	profissao: string;
	enderecoResidencia: string;
	numeroResidencia: string;
	complementoResidencia: string;
	bairroResidencia: string;
	codigoMunicipioResidencia: string;
	descMunicipioResidencia: string;
	cepResidencia: string;
	enderecoTrabalho: string;
	numeroTrabalho: string;
	complementoTrabalho: string;
	bairroTrabalho: string;
	codigoMunicipioTrabalho: string;
	descMunicipioTrabalho: string;
	cepTrabalho: string;
	status: string;
	statusMessage: string;
	solicitacao: string;
	foneResidencia: string;
	foneComercial: string;
	celular: string;
	operadora: string;
	email: string;
	identificadoAssina: string;
	motivoFaltaAssinatura: string;
	dataValidadeCi: string;
	observacaoPagamento: string;
	carimboMaior65: string;
	tipoSanguineo: string;
	ctpsNum: string;
	ctpsSerie: string;
	ctpsSerieUf: string;
	cnh: string;
	certidaoMilitar: string;
	tituloEleitor: string;
	dni: string;
	nascSaude: string;
	identProf1: string;
	orgaoEmissor1: string;
	identProf2: string;
	orgaoEmissor2: string;
	identProf3: string;
	orgaoEmissor3: string;
	dataValidade: string;
	cid1: string;
	cid2: string;
	cid3: string;
	cid4: string;
	nomeSocial: string;
	sedex: string;
}

export interface ConsultaCpfRequest {
	cpf: string;
	usuario: string;
	senha: string;
}
