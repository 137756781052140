import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { ConsultarMunicipiosIbgeRequest, Types } from './types';

// ACTIONS
import {
	consultarMunicipiosIbgeSuccess,
	consultarMunicipiosIbgeFailure,
} from './actions';

function* consultarMunicipiosIbgeRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultarMunicipiosIbgeRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarMunicipiosIbgeRequest;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`municipios-ibge`,
		body,
	);

	if (response.status === 200) {
		yield put(consultarMunicipiosIbgeSuccess(response));
	} else {
		yield put(consultarMunicipiosIbgeFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_MUNICIPIOS_IBGE_REQUEST,
		consultarMunicipiosIbgeRequest,
	),
]);
