import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export function pesquisaMunicipiosRequest(data: string): ReducerAction {
	return {
		type: Types.PESQUISA_MUNICIPIOS_REQUEST,
		payload: data,
	};
}
export function pesquisaMunicipiosSuccess(payload: object): ReducerAction {
	return {
		type: Types.PESQUISA_MUNICIPIOS_SUCCESS,
		payload,
	};
}
export function pesquisaMunicipiosFailure(): ReducerAction {
	return {
		type: Types.PESQUISA_MUNICIPIOS_FAILURE,
		payload: null,
	};
}
export function pesquisaMunicipiosClear(): ReducerAction {
	return {
		type: Types.PESQUISA_MUNICIPIOS_CLEAR,
		payload: null,
	};
}
