export enum Types {
	CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_REQUEST = '@DETRAN-CRV/CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_REQUEST',
	CONSULTAR_BLOQUEIO_DETRAN_SUCCESS = '@DETRAN-CRV/CONSULTAR_BLOQUEIO_DETRAN_SUCCESS',
	CONSULTAR_BLOQUEIO_RENAJUD_SUCCESS = '@DETRAN-CRV/CONSULTAR_BLOQUEIO_RENAJUD_SUCCESS',
	CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_FAILURE = '@DETRAN-CRV/CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_FAILURE',
	CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_CLEAR = '@DETRAN-CRV/CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_CLEAR',
}

export interface consultarBloqueioDetranRenajud {
	status: number;
	data: {
		bloqueioDetran: bloqueioDetran | null;
		bloqueioRenajud: bloqueioRenajud | null;
	};
}

export interface IRegistroBloqueioDetran {
	anoBoat: number;
	anoOficio: number;
	anoProcesso: number;
	anoProtocolo: number;
	dataInclusao: string;
	motivoBloqueio: string;
	numeroBoat: string;
	numeroOficio: string;
	numeroProcesso: string;
	numeroProtocolo: number;
	tipoBloqueio: string;
	municipioBloqueio: {
		codigo: number;
		nome: string;
	};
}

export interface bloqueioDetran {
	chassi: string;
	placa: string;
	registroBloqueios: IRegistroBloqueioDetran[];
}

export interface bloqueioRenajud {
	chassi: string;
	placa: string;
	quantidadeOcorrenciasExibidas: number;
	quantidadeOcorrenciasEncontradas: number;
	municipioPlaca: {
		codigo: number;
		nome: string;
	};
	registroRenajud: [
		{
			codigoOrgaoJudicial: number;
			codigoTribunal: string;
			nomeOrgaoJudicial: string;
			numeroProcesso: string;
			registroRenajudRestricao: [
				{
					dataHoraInclusao: string;
					tipoRestricao: string;
				},
			];
		},
	];
}

export interface IRequestConsultarBloqueioDetranRenajud {
	chassi: string;
	tipoBloqueio?: string;
}
