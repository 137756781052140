import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { PATH_EDUCACAO_SEDUC_DOMINIOS_API } from 'services/rotasp/path';
import { ApiResponse, getApi } from 'services/rotasp/api';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types } from './types';

import { tiposEnsinoSuccess, tiposEnsinoFailure } from './actions';

function* ensinoRequest(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: {} } = request;
	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_EDUCACAO_SEDUC_DOMINIOS_API,
		`tipos-ensino`,
		payload,
	);
	if (response.status === 200) {
		yield put(tiposEnsinoSuccess(response));
	} else if (response.error) {
		yield put(tiposEnsinoFailure(response.message));
	}
}

export default all([takeLatest(Types.TIPOS_ENSINO_REQUEST, ensinoRequest)]);
