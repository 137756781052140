import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarMensagemErroRequest } from './types';

export function atualizarMensagemErroRequest(
	data: AtualizarMensagemErroRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_MENSAGEM_ERRO_REQUEST,
		payload: data,
	};
}
export function atualizarMensagemErroSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_MENSAGEM_ERRO_SUCCESS,
		payload,
	};
}
export function atualizarMensagemErroFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_MENSAGEM_ERRO_FAILURE,
		payload: null,
	};
}
export function atualizarMensagemErroClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_MENSAGEM_ERRO_CLEAR,
		payload: null,
	};
}
