import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarDigitaisState } from './types';

export const INITIAL_STATE: ConsultarDigitaisState = {
	status: 0,
	data: null,
	mensagens: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarDigitaisState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_DIGITAIS_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.CONSULTAR_DIGITAIS_SUCCESS: {
				draft.status = 200;
				draft.data = {
					dadosBiometricos: action.payload?.dadosBiometricos
						? action.payload.dadosBiometricos
						: [],
					valida: action.payload.valida,
					motivo: action.payload.motivo,
					origem: action.payload.origem,
				};
				break;
			}
			case Types.CONSULTAR_DIGITAIS_FAILURE: {
				draft.status = action.payload.status;
				draft.data = INITIAL_STATE.data;
				draft.mensagens = action.payload.data.mensagens;
				break;
			}
			case Types.CONSULTAR_DIGITAIS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.mensagens = INITIAL_STATE.mensagens;
				break;
			}

			default:
		}
		return draft;
	});
}
