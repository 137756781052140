import * as Yup from 'yup';
import {
	IAtualizacaoCadastral,
	IRequestAtualizaDadosCadastrais,
	IRequestBuscaDadosCadastrais,
} from 'store/modules/api/seds/vivaLeite/usuario/types';

export function treatValues(
	values: IAtualizacaoCadastral,
	userData: IRequestBuscaDadosCadastrais,
): IRequestAtualizaDadosCadastrais {
	const formattedData: IRequestAtualizaDadosCadastrais = {
		...userData,
		dados_atualizar: values.dados_atualizar,
	};

	return formattedData;
}

export const schema = Yup.object<IRequestAtualizaDadosCadastrais>().shape({
	dados_atualizar: Yup.object().shape({
		telefone_1_responsavel: Yup.string()
			.required(
				'Telefone I do responsável não informado ou maior do que 14 caracteres.',
			)
			.max(
				14,
				'Telefone I do responsável não informado ou maior do que 14 caracteres.',
			),
		horario_entrega_usina: Yup.string().test({
			name: 'horario_entrega_usina',
			message: 'Horário de entrega da Usina: Horário inválido!',
			test: (horario: any) => {
				if (horario === '') {
					return true;
				}
				const validaHorario = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(
					horario,
				);
				return validaHorario;
			},
		}),
		horario_entrega_beneficiarios: Yup.string().test({
			name: 'horario_entrega_beneficiarios',
			message: 'Horário de entrega aos Beneficiários: Horário inválido!',
			test: (horario: any) => {
				if (horario === '') {
					return true;
				}
				const validaHorario = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(
					horario,
				);
				return validaHorario;
			},
		}),
		enderecos_entrega: Yup.array().of(
			Yup.object().shape({
				cep: Yup.string().required('Campo Obrigatório: CEP.'),
				numero: Yup.string()
					.required(
						'Existe(m) número(s) não informado(s) ou maior do que 10 caracteres.',
					)
					.max(
						10,
						'Existe(m) número(s) não informado(s) ou maior do que 10 caracteres.',
					),
				endereco: Yup.string()
					.required(
						'Existe(m) endereço(s) não informado(s) ou maior do que 100 caracteres',
					)
					.max(
						100,
						'Existe(m) endereço(s) não informado(s) ou maior do que 100 caracteres.',
					),
				bairro: Yup.string()
					.required(
						'Existe(m) bairro(s) não informado(s) ou maior do que 60 caracteres.',
					)
					.max(
						60,
						'Existe(m) bairro(s) não informado(s) ou maior do que 60 caracteres.',
					),
				telefone: Yup.string().required(
					'Existe(m) telefone(s) inválido(s) ou não informado(s).',
				),
			}),
		),
	}),
});
