import { all } from 'redux-saga/effects';

import consultaPermissaoInternacionalSaga from './consultaPermissaoInternacional/saga';
import consultaEPortalSaga from './consultaEPortal/saga';
import gerarRenachSaga from './gerarRenach/saga';

export default all([
	consultaPermissaoInternacionalSaga,
	consultaEPortalSaga,
	gerarRenachSaga,
]);
