import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastroEnderecosRequest } from './types';

// ACTIONS
import { cadastroEnderecosSuccess, cadastroEnderecosFailure } from './actions';

function* cadastroEnderecos(request: ReducerAction) {
	const { payload }: { payload: CadastroEnderecosRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`condutor/${CHANNEL}/cadastro-enderecos`,
		payload,
	);

	if (response.status === 200) {
		yield put(cadastroEnderecosSuccess(response));
	} else if (response.error) {
		yield put(cadastroEnderecosFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRO_ENDERECOS_REQUEST, cadastroEnderecos),
]);
