import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Col, Row } from 'antd';
import { Formik, Form, Field, FormikProps } from 'formik';

import Section from 'components/Common/Section';
import Alert from 'components/Common/Notifications/Alert';

import { ApplicationState } from 'store';

import {
	consultaImpressaoDocumentos,
	consultaEfetivaImpressaoDocumentos,
	clearPdfImprimir,
} from 'store/modules/api/seds/vivaLeite/documentos/actions';
import { clearNotificationsNotasFiscaisVivaLeite } from 'store/modules/api/seds/vivaLeite/notasFiscais/actions';

import PDFViewer from 'components/Common/PDFViewer';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';
import Select from 'components/Common/Form/Select';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

import Padrao from '../components/pages/Padrao';

import {
	InitialValues,
	schema,
	InitialImpressaoDocumentos,
	IBuscaDocumentosImpressao,
} from './form';

export interface IDataMotivo {
	codigo_motivo: number;
	outros_motivos?: string;
}

export interface IOutrosMotivos {
	outros_motivos: string[];
}

const ImprimirDocumento = () => {
	const routeMatch = useRouteMatch();

	const { selectImpressaoDocumentos, pdfImpressao } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.documentos,
	);
	const { selectedUser, selectedCPF } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.usuario,
	);
	const { statusTransacaoNotasFiscais } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.notasFiscais,
	);

	const [prosseguir, setProsseguir] = useState(false);
	const [imprimirRequired, setImprimirRequired] = useState(false);
	const [urlServico, setUrlServico] = useState<string>('');

	const dispatch = useDispatch();
	const history = useHistory();

	const handleOnChange = useCallback(
		(value: React.ReactText, formik: FormikProps<any>) => {
			formik.setFieldValue('codigo_documento', value);
		},
		[],
	);

	useEffect(() => {
		const servico =
			urlServico === 'resultado'
				? 'ATUALIZACAO CADASTRAL'
				: 'PRESTACAO DE CONTAS';

		const requestData = InitialImpressaoDocumentos;
		requestData.servico = servico;
		requestData.num_pan = selectedUser.numeroPan;
		requestData.cpf = selectedCPF.toString();
		requestData.etapa = 'busca_lista_documentos_impressao';

		dispatch(consultaImpressaoDocumentos(requestData));
	}, [dispatch, selectedCPF, selectedUser.numeroPan, urlServico]);

	const handlePrint = useCallback(
		(value: IBuscaDocumentosImpressao) => {
			const requestData = InitialImpressaoDocumentos;
			requestData.num_pan = selectedUser.numeroPan;
			requestData.cpf = selectedCPF.toString();
			requestData.codigo_documento = Number(value.codigo_documento);
			requestData.etapa = 'efetiva_impressao_documento';

			dispatch(consultaEfetivaImpressaoDocumentos(requestData));

			if (Number(value.codigo_documento) === 1) {
				setProsseguir(true);
			}
		},
		[dispatch, selectedCPF, selectedUser.numeroPan],
	);

	const handleClear = useCallback(() => {
		dispatch(clearPdfImprimir());
	}, [dispatch]);

	const handleNext = useCallback(() => {
		if (!prosseguir) {
			setImprimirRequired(true);
			return;
		}
		history.push('/seds/vivaleite/prestacaoDeContas/envioDocumentos');
	}, [history, prosseguir]);

	const handleCleanStatus = useCallback(() => {
		if (statusTransacaoNotasFiscais !== '') {
			dispatch(clearNotificationsNotasFiscaisVivaLeite());
		}
	}, [dispatch, statusTransacaoNotasFiscais]);

	useEffect(() => {
		const urlPath = routeMatch.path.split('/');

		setUrlServico(urlPath[urlPath.length - 2]);
	}, [routeMatch]);

	useEffect(() => {
		return function cleanup() {
			handleCleanStatus();
		};
	}, [handleCleanStatus]);

	return (
		<>
			{imprimirRequired && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Alert
							type="error"
							message="É obrigatório imprimir o Termo de Responsabilidade."
							onClose={() => setImprimirRequired(false)}
						/>
					</Col>
				</Row>
			)}
			<Padrao
				statusTransacao={statusTransacaoNotasFiscais}
				onCleanStatus={handleCleanStatus}
			>
				<>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Formik
								validateOnChange={false}
								validateOnBlur={false}
								initialValues={InitialValues}
								validate={values => getValidationsErrors(values, schema)}
								onSubmit={handlePrint}
							>
								{formik => {
									return (
										<Form>
											<Section
												size="sm"
												titleBold={false}
												title="IMPRESSÃO DE DOCUMENTOS"
											>
												<Row gutter={[0, 10]}>
													<Col span={15}>
														<Field
															title="Selecionar Documento"
															name="codigo_documento"
															titleSize="lg"
															as={Select}
															options={selectImpressaoDocumentos}
															onChange={(v: number) =>
																handleOnChange(v, formik)
															}
															error={hasError(
																formik.errors,
																'codigo_documento',
															)}
														/>
													</Col>
													<Col span={4} offset={1}>
														<Button type="submit">Imprimir</Button>
													</Col>
												</Row>
											</Section>

											<Row
												gutter={[0, 10]}
												justify="space-between"
												align="bottom"
											>
												<Col>
													<ButtonVoltar />
												</Col>
												<Col>
													{urlServico !== 'resultado' && (
														<ButtonImage
															src="prosseguir"
															onClick={handleNext}
														/>
													)}
												</Col>
											</Row>
										</Form>
									);
								}}
							</Formik>
						</Col>
					</Row>
					{pdfImpressao !== '' && (
						<PDFViewer
							title="pdfVivaLeite"
							source={pdfImpressao}
							onClose={handleClear}
						/>
					)}
				</>
			</Padrao>
		</>
	);
};

export default ImprimirDocumento;
