import * as Yup from 'yup';

export interface IImpressaoSegundaViaLicenciamento {
	numeroFicha: string;
	anoFicha: string;
}

export const initialValues: IImpressaoSegundaViaLicenciamento = {
	numeroFicha: '',
	anoFicha: '',
};

export const schema = Yup.object<IImpressaoSegundaViaLicenciamento>().shape({
	numeroFicha: Yup.string().required(`Campo Obrigatório: Número da Ficha.`),
	anoFicha: Yup.string().required(`Campo Obrigatório: Ano da Ficha Cadastral.`),
});
