// TYPES
import { ReducerAction } from 'store/modules/types';
import { IConsultarStatusDareState, Types } from './types';

export interface IRequestStatusDare {
	rg: string;
}

export function consultarStatusDareRequest(
	payload: IRequestStatusDare,
): ReducerAction {
	return {
		type: Types.CONSULTAR_STATUS_DARE_REQUEST,
		payload,
	};
}

export function consultarStatusDareSuccess(
	status: number,
	data: IConsultarStatusDareState[] | null,
): ReducerAction {
	return {
		type: Types.CONSULTAR_STATUS_DARE_SUCCESS,
		payload: {
			status,
			data,
		},
	};
}

export function consultarStatusDareFailure(
	status: number,
	data: null,
): ReducerAction {
	return {
		type: Types.CONSULTAR_STATUS_DARE_FAILURE,
		payload: {
			status,
			data,
		},
	};
}

export function consultarStatusDareClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_STATUS_DARE_CLEAR,
		payload: null,
	};
}
