import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IEnviarSmsState } from './types';

export const INITIAL_STATE: IEnviarSmsState = {
	status: 0,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IEnviarSmsState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ENVIAR_SMS_REQUEST: {
				break;
			}
			case Types.ENVIAR_SMS_SUCCESS: {
				draft.status = action.payload;
				break;
			}
			case Types.ENVIAR_SMS_FAILURE: {
				draft.status = INITIAL_STATE.status;
				break;
			}

			case Types.ENVIAR_SMS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				break;
			}

			default:
		}
		return draft;
	});
}
