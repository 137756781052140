import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// ANTD DESIGN
import { Alert, Col, Row } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import {
	consultaConsumidorClear,
	consultaConsumidorRequest,
} from 'store/modules/api/procon/consumidores/consultaConsumidor/actions';
import { ConsultaConsumidorRequest } from 'store/modules/api/procon/consumidores/consultaConsumidor/types';
import {
	ICadastroEventoRequest,
	IDadosRequest,
} from 'store/modules/api/contabilizacao/cadastroEvento/types';
import {
	clearNotifications,
	showInside,
} from 'store/modules/app/notifications/actions';
import { cadastroConsumidorClear } from 'store/modules/api/procon/consumidores/cadastro/actions';
import { atualizacaoConsumidorClear } from 'store/modules/api/procon/consumidores/atualizacao/actions';
import { cadastroEventoRequest } from 'store/modules/api/contabilizacao/cadastroEvento/actions';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import { limparMascara } from 'utils/genericFunctions';
import { getTimestamp } from 'utils/dateFunctions';
import {} from 'utils/stringFunctions';

// COMPONENTS
import { Field, Form, Formik, FormikProps } from 'formik';
import CPF from 'components/Common/Form/Fields/CPF';
import Error from 'components/Common/Notifications/FormError/index';
import LightButton from 'components/Common/Buttons/Light';
import Section from 'components/Common/Section';
import TypographyCommon from 'components/Common/Typography';
import CadastroConsultaConsumidor from '../CadastroConsultaConsumidor';

// FORM
import { initialValues, schema } from './form';

// STYLED
import { AlertContainer, stylePaddingCols } from './styled';

type TypeAccounting = {
	reclamacao: {
		id_servico: string | number;
		desc_servico: string;
		tipo: string;
	};
	consulta: {
		id_servico: string | number;
		desc_servico: string;
		tipo: string;
	};
};

interface Props {
	formikParent: FormikProps<any>;
	setShowModal: (v: 'none' | 'fornecedor' | 'consumidor') => void;
	activeTab?: string;
	typeRegistration: 'consulta' | 'reclamacao';
}

const PesquisarConsumidor: React.FC<Props> = ({
	formikParent,
	setShowModal,
	activeTab = '',
	typeRegistration,
}) => {
	const dispatch = useDispatch();

	const [type, setType] = useState<'add' | 'update' | 'check' | null>(null);

	const loginUnico = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const [formData] = useState(initialValues);

	const [showCadastroConsumidor, setShowCadastroConsumidor] =
		useState<boolean>(false);
	const atualizaConsumidorStatus = useSelector(
		(state: ApplicationState) =>
			state.api.procon.consumidores.atualizacao.status,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.salvarAtendimento.data,
	);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const consumidorStatus = useSelector(
		(state: ApplicationState) =>
			state.api.procon.consumidores.consultaConsumidor.status,
	);

	const cadastroConsumidorStatus = useSelector(
		(state: ApplicationState) => state.api.procon.consumidores.cadastro.status,
	);

	const [cadastrandoConsumidor, setCadastrandoConsumidor] =
		useState<boolean>(false);

	const handleTipoContabilizacao = useMemo(() => {
		return {
			reclamacao: {
				id_servico: 7,
				desc_servico: 'procon_reclamacao_solicitacao',
				tipo: 'procon_reclamacao_solicitacao',
			},
			consulta: {
				id_servico: 5,
				desc_servico: 'procon_consulta_solicitacao',
				tipo: 'procon_consulta_solicitacao',
			},
		};
	}, []);

	const handleContabilizar = useCallback(() => {
		const tipoContabilizacao: TypeAccounting['reclamacao' | 'consulta'] =
			handleTipoContabilizacao[typeRegistration];

		const dados: IDadosRequest = {
			data_hora: getTimestamp().toString() || '',
			id_servico: tipoContabilizacao.id_servico || '',
			desc_servico: tipoContabilizacao.desc_servico || '',
			identificacao: Number(limparMascara(formData.cpf)) || 0,
			cpf: limparMascara(loginUnico.login.user.cpf) || '',
			ip: loginUnico.login.user.ip || '',
			id_orgao: 390,
			desc_orgao: 'PROCON',
			id_localidade: login.user.idLocal,
			desc_localidade: loginUnico.login.user.nomePosto || '',
		};

		const contabilizacao: ICadastroEventoRequest = {
			id_atendimento: dadosAtendimento?.idAtendimento || '',
			tipo: tipoContabilizacao.tipo || '',
			id_cidadao: dadosAtendimento?.idCidadao || '',
			data: getTimestamp() || 0,
			dados: JSON.stringify(dados) || '',
			canal: {
				id: loginUnico.login.user.idCanal.toString() || '',
				descricao: loginUnico.login.user.descCanal || '',
			},
		};

		dispatch(cadastroEventoRequest(contabilizacao));
	}, [
		handleTipoContabilizacao,
		typeRegistration,
		formData.cpf,
		loginUnico,
		login,
		dadosAtendimento,
		dispatch,
	]);

	const handleCadastrar = useCallback(
		(formik: FormikProps<ConsultaConsumidorRequest>) => {
			if (
				consumidorStatus === 0 ||
				(consumidorStatus !== 200 && consumidorStatus !== 201)
			) {
				handleContabilizar();
			}

			setCadastrandoConsumidor(true);

			if (notifications.errors.length) {
				dispatch(clearNotifications());
			}

			dispatch(consultaConsumidorClear());

			dispatch(atualizacaoConsumidorClear());
		},
		[
			dispatch,
			notifications.errors.length,
			handleContabilizar,
			consumidorStatus,
		],
	);

	const handleSubmit = useCallback(
		(values: ConsultaConsumidorRequest) => {
			const tipoContabilizacao: TypeAccounting['reclamacao' | 'consulta'] =
				handleTipoContabilizacao[typeRegistration];

			values.cpf = limparMascara(values.cpf);

			values.servico = tipoContabilizacao.tipo;

			const evento = {
				id_atendimento: dadosAtendimento?.idAtendimento || '',
				id_cidadao: dadosAtendimento?.idCidadao || '',
				cpf: limparMascara(loginUnico.login.user.cpf),
				identificacao: Number(limparMascara(values.cpf)),
				ip: loginUnico.login.user.ip,
				canal: {
					id: loginUnico.login.user.idCanal,
					desc: loginUnico.login.user.descCanal,
					estacao: {
						id: loginUnico.login.user.posto,
						desc: loginUnico.login.user.nomePosto,
					},
					localidade: {
						id: Number(loginUnico.login.user.posto),
						desc: loginUnico.login.user.nomePosto,
					},
				},
				atendente: {
					nome: loginUnico.user.nome,
					id_atendente: '',
					id_posto: loginUnico.login.user.posto,
					desc_posto: loginUnico.login.user.nomePosto,
				},
			};

			values.evento = evento;

			dispatch(consultaConsumidorRequest(values));
		},
		[
			handleTipoContabilizacao,
			typeRegistration,
			dadosAtendimento,
			loginUnico,
			dispatch,
		],
	);

	const handleClearConsumidor = useCallback(
		(formik: FormikProps<ConsultaConsumidorRequest>) => {
			formik.setFieldValue('cpf', '');
			dispatch(consultaConsumidorClear());
			setType(null);
			clearNotifications();
		},
		[setType, dispatch],
	);

	useEffect(() => {
		dispatch(clearNotifications());
		showInside(true);
	}, [dispatch]);

	useEffect(() => {
		if (consumidorStatus === 201 || consumidorStatus === 200) {
			setType('check');
			dispatch(cadastroConsumidorClear());
		}
	}, [consumidorStatus, typeRegistration, dispatch]);

	useEffect(() => {
		if (cadastroConsumidorStatus === 200) {
			setType('check');
			setShowCadastroConsumidor(true);
		}
		if (atualizaConsumidorStatus === 200) {
			setType('check');
		}
	}, [atualizaConsumidorStatus, cadastroConsumidorStatus, consumidorStatus]);

	useEffect(
		() => () => {
			dispatch(cadastroConsumidorClear());
			dispatch(consultaConsumidorClear());
			dispatch(atualizacaoConsumidorClear());
			showInside(true);
			setShowCadastroConsumidor(false);
		},
		[dispatch],
	);

	return (
		<>
			{(showCadastroConsumidor || atualizaConsumidorStatus === 200) && (
				<>
					<AlertContainer>
						{showCadastroConsumidor && (
							<Alert
								closable
								className="no-print"
								type="info"
								showIcon
								message=" "
								description="Consumidor cadastrado com sucesso!"
							/>
						)}
					</AlertContainer>
					<br />
				</>
			)}

			{notifications.errors &&
				notifications.errors.length > 0 &&
				notifications.showInside && (
					<>
						<Error errors={notifications.errors} />
						<br />
					</>
				)}

			{atualizaConsumidorStatus === 200 && (
				<>
					<AlertContainer>
						<Alert
							className="no-print"
							type="info"
							showIcon
							message=" "
							description="Cliente Atualizado com sucesso!"
							closable
						/>
					</AlertContainer>
					<br />
				</>
			)}

			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={formData}
				validate={values => getValidationsErrors(values, schema)}
				onSubmit={handleSubmit}
			>
				{formik => (
					<Form autoComplete="off">
						<Row
							gutter={[0, 0]}
							align="middle"
							justify="end"
							style={{ paddingBottom: '6px' }}
						>
							<LightButton
								type="button"
								buttonColor="default"
								text="Novo Cadastro"
								icon="add"
								buttonSize="sm"
								onClick={() => {
									setType('add');
									handleCadastrar(formik);
								}}
								disabled={consumidorStatus === 200 || consumidorStatus === 201}
							/>
						</Row>

						<Section
							bgColor="cinza-escuro"
							style={{
								border: '1px grey solid',
								borderRadius: '4px',
								marginTop: '0px',
							}}
						>
							<TypographyCommon
								text={
									activeTab === 'tab-reclamacao-pj' &&
									typeRegistration !== 'consulta'
										? 'Pesquisa Representante Legal'
										: 'Pesquisa Consumidor'
								}
								borderBottom
								type="h5"
								fontWeight="bold"
								uppercase
							/>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<TypographyCommon
									text={'CPF do '.concat(
										activeTab === 'tab-reclamacao-pj' &&
											typeRegistration !== 'consulta'
											? 'Representante Legal'
											: 'Consumidor',
									)}
									subtitle=" (XXX.XXX.XXX-XX)"
									fontWeight="bold"
									uppercase
									required={type !== 'add'}
								/>
							</Row>
							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={18} style={stylePaddingCols}>
									<Field
										as={CPF}
										numero="cpf"
										placeholder="Digite um CPF"
										formik={formik}
										error={hasError(formik.errors, 'cpf')}
										required={cadastrandoConsumidor}
										disabled={type === 'add'}
									/>
								</Col>
								<Col span={6}>
									<Row align="middle" justify="space-between">
										<LightButton
											id="btn_modal_pesquisar_consumidor"
											type="submit"
											buttonColor="default"
											text="Pesquisar"
											icon="search"
											buttonSize="sm"
											buttonWidth={110}
											title="Pesquisar Consumidor"
											disabled={type === 'add'}
										/>
										<LightButton
											id="btn_modal_limpar_consumidor"
											type="button"
											buttonColor="default"
											text="Limpar"
											onClick={() => handleClearConsumidor(formik)}
											icon="erase"
											buttonSize="sm"
											buttonWidth={80}
											title="Limpar"
										/>
									</Row>
								</Col>
							</Row>

							{type && (
								<>
									<br />
									<CadastroConsultaConsumidor
										formikParent={formikParent}
										parentCpf={formik.values.cpf}
										setType={setType}
										type={type}
										setShowModal={setShowModal}
									/>
								</>
							)}
						</Section>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default PesquisarConsumidor;
