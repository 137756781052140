import React, { useCallback } from 'react';

// ROUTES
import { useHistory } from 'react-router-dom';
import {
	ROUTE_IIRGD_CADASTRO,
	ROUTE_IIRGD_EDICAO,
} from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultaPesquisaObservacaoClear } from 'store/modules/api/iirgd/consultaPesquisaObservacao/actions';
import { protocoloFicClear } from 'store/modules/api/iirgd/protocoloFic/actions';
import { protocoloFicDigitalClear } from 'store/modules/api/iirgd/protocoloFicDigital/actions';
import { protocoloReusoClear } from 'store/modules/api/iirgd/protocoloReuso/actions';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import { cadastroRgConfigRedicao } from 'store/modules/api/iirgd/cadastroRg/actions';
import { consultarDigitaisClear } from 'store/modules/api/utils/consultarDigitais/actions';
import { confrontarDigitaisClear } from 'store/modules/api/utils/confrontarDigitais/actions';

export const ReeditarCadastro: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const handleClick = useCallback(() => {
		dispatch(cadastroRgConfigRedicao());
		dispatch(consultaPesquisaObservacaoClear());
		dispatch(protocoloFicClear());
		dispatch(protocoloFicDigitalClear());
		dispatch(protocoloReusoClear());
		// dispatch(capturarBiometriaClear());
		dispatch(consultarDigitaisClear());
		dispatch(confrontarDigitaisClear());

		if (cadastroRg.config.novo) {
			history.push(ROUTE_IIRGD_CADASTRO);
			return;
		}

		history.push(ROUTE_IIRGD_EDICAO);
	}, [dispatch, cadastroRg.config, history]);

	return (
		<ButtonImage type="button" src="reeditar-cadastro" onClick={handleClick} />
	);
};
