// TYPES
import { ReducerAction } from 'store/modules/types';
import { AssuntosRequest, Types } from './types';

export function assuntosRequest(payload: AssuntosRequest): ReducerAction {
	return {
		type: Types.ASSUNTOS_REQUEST,
		payload,
	};
}
export function assuntosSuccess(payload: object): ReducerAction {
	return {
		type: Types.ASSUNTOS_SUCCESS,
		payload,
	};
}
export function assuntosFailure(payload: object): ReducerAction {
	return {
		type: Types.ASSUNTOS_FAILURE,
		payload,
	};
}
export function assuntosClear(): ReducerAction {
	return {
		type: Types.ASSUNTOS_CLEAR,
		payload: null,
	};
}
