import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, PesquisarContatoRequest } from './types';

// ACTIONS
import { pesquisarContatoSuccess, pesquisarContatoFailure } from './actions';

function* atualizarContato(request: ReducerAction) {
	const { payload }: { payload: PesquisarContatoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`portal-detran/${CHANNEL}/contato/pesquisar`,
		payload,
		undefined,
		true,
	);

	if (response.status === 200) {
		yield put(pesquisarContatoSuccess({ response, payload }));
	} else {
		yield put(pesquisarContatoFailure(response));
	}
}

export default all([takeLatest(Types.PESQUISAR_CONTATO, atualizarContato)]);
