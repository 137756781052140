import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, consultarVeiculoBaseEstadual } from './types';

export const INITIAL_STATE: consultarVeiculoBaseEstadual = {
	status: 0,
	data: {
		dadosVeiculo: {
			anoFabricacao: 0,
			anoModelo: 0,
			capacidadeCarga: 0,
			capacidadePassageiro: 0,
			chassi: '',
			cilindrada: 0,
			cmt: 0.0,
			dataAlteracaoMotor: 0,
			eixo: 0,
			numeroMotor: '',
			pbt: 0.0,
			placa: '',
			potencia: 0,
			procedencia: '',
			renavam: 0,
			ultimaAtualizacaoDespachante: 0,
			carroceria: {
				longId: 0,
				descricao: '',
			},
			categoria: {
				descricao: '',
				longId: 0,
			},
			combustivel: {
				longId: 0,
				descricao: '',
			},
			cor: {
				longId: 0,
				descricao: '',
			},
			especie: {
				longId: 0,
				descricao: '',
			},
			marca: {
				longId: 0,
				descricao: '',
			},
			municipio: {
				codigo: 0,
				nome: '',
			},
			tipo: {
				longId: 0,
				descricao: '',
			},
			comunicacaoVenda: {
				comunicacaoVenda: '',
				cpfCnpjComprador: 0,
				dataInclusaoComunicacao: '',
				dataNotaFiscal: '',
				dataVenda: '',
				identificacao: '',
				protocoloDetran: '',
			},
			crvCrvl: {
				dataLicenciamento: 0,
				exercicioLicenciamento: 0,
				dataEmissaoCrv: '',
			},
			gravame: {
				arrendatarioFinanceira: '',
				codigoFinanceira: 0,
				cpfCnpjFinanciado: 0,
				nomeAgente: '',
				numeroContrato: '',
				restricaoFinanceira: '',
				dataInclusao: '',
				dataVigencia: '',
			},
			intencaoGravame: {
				agenteFinanceiro: '',
				cpfCnpjFinanciado: 0,
				dataInclusao: '',
				dataVigencia: '',
				exercicioLicenciamento: 0,
				nomeFinanciado: '',
				numeroContrato: '',
				restricao: '',
				tipoTransacao: '',
			},
			multas: {
				cetesb: 0.0,
				debitoIpva: 0.0,
				der: 0.0,
				dersa: 0.0,
				detran: 0.0,
				municipais: 0.0,
				policiaRodoviariaFederal: 0.0,
				renainf: 0.0,
			},
			proprietario: {
				cpfCnpjProprietario: 0,
				identificacao: '',
				nomeProprietario: '',
				tipoDocumento: '',
			},
			restricoes: {
				bloqueioGuincho: '',
				bloqueioRenajud: '',
				furto: '',
				inspecaoAmbiental: '',
				restricaoAdministrativa: '',
				restricaoJudicial: '',
				restricaoTributaria: '',
			},
		},
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultarVeiculoBaseEstadual {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_VEICULO_BASE_ESTADUAL_REQUEST: {
				break;
			}

			case Types.CONSULTAR_VEICULO_BASE_ESTADUAL_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_VEICULO_BASE_ESTADUAL_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_VEICULO_BASE_ESTADUAL_CLEAR: {
				draft.status = 0;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
