import { ReducerAction } from 'store/modules/types';

import { Types, ValidaTokenCnhDigitalRequest } from './types';

export function validaTokenCnhDigitalRequest(
	data: ValidaTokenCnhDigitalRequest,
): ReducerAction {
	return {
		type: Types.VALIDA_TOKEN_CNH_DIGITAL_REQUEST,
		payload: data,
	};
}
export function validaTokenCnhDigitalSuccess(payload: object): ReducerAction {
	return {
		type: Types.VALIDA_TOKEN_CNH_DIGITAL_SUCCESS,
		payload,
	};
}
export function validaTokenCnhDigitalFailure(payload: object): ReducerAction {
	return {
		type: Types.VALIDA_TOKEN_CNH_DIGITAL_FAILURE,
		payload,
	};
}
export function validaTokenCnhDigitalClear(): ReducerAction {
	return {
		type: Types.VALIDA_TOKEN_CNH_DIGITAL_CLEAR,
		payload: null,
	};
}
