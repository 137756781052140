import { ConsultarUnidadesTransitoRequest, Types } from './types';

export function consultarUnidadeTransitoRequest(
	payload: ConsultarUnidadesTransitoRequest,
) {
	return {
		type: Types.CONSULTAR_UNIDADE_TRANSITO_REQUEST,
		payload,
	};
}
export function consultarUnidadeTransitoSuccess(payload: object) {
	return {
		type: Types.CONSULTAR_UNIDADE_TRANSITO_SUCCESS,
		payload,
	};
}
export function consultarUnidadeTransitoFailure(payload: []): any {
	return {
		type: Types.CONSULTAR_UNIDADE_TRANSITO_FAILURE,
		payload,
	};
}
export function consultarUnidadeTransitoClear(): any {
	return {
		type: Types.CONSULTAR_UNIDADE_TRANSITO_CLEAR,
	};
}
