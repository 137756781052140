import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, consultarVeiculoV120 } from './types';

export const INITIAL_STATE: consultarVeiculoV120 = {
	status: 0,
	data: null,
	placaPesquisada: '',
	categoria: '',
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultarVeiculoV120 {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_VEICULO_V120_REQUEST: {
				draft.placaPesquisada = action.payload.placa;
				draft.categoria = action.payload.categoria;
				break;
			}

			case Types.CONSULTAR_VEICULO_V120_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_VEICULO_V120_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTAR_VEICULO_V120_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
