// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function enviarCentralNominalRequest(payload: any): ReducerAction {
	return {
		type: Types.ENVIAR_CENTRAL_NOMINAL_REQUEST,
		payload,
	};
}

export function enviarCentralNominalSuccess(payload: any): ReducerAction {
	return {
		type: Types.ENVIAR_CENTRAL_NOMINAL_SUCCESS,
		payload,
	};
}

export function enviarCentralNominalFailure(): ReducerAction {
	return {
		type: Types.ENVIAR_CENTRAL_NOMINAL_FAILURE,
		payload: null,
	};
}

export function enviarCentralNominalClear(): ReducerAction {
	return {
		type: Types.ENVIAR_CENTRAL_NOMINAL_CLEAN,
		payload: null,
	};
}
