export enum Types {
	DISPONIBILIZAR_DARE_REQUEST = '@POTENCIAL/DISPONIBILIZAR_DARE_REQUEST',
	DISPONIBILIZAR_DARE_SUCCESS = '@POTENCIAL/DISPONIBILIZAR_DARE_SUCCESS',
	DISPONIBILIZAR_DARE_FAILURE = '@POTENCIAL/DISPONIBILIZAR_DARE_FAILURE',
	DISPONIBILIZAR_DARE_CLEAR = '@POTENCIAL/DISPONIBILIZAR_DARE_CLEAR',
}

export interface DisponibilzarDare {
	status: number;
	data: darePinPad | null;
}

interface darePinPad {
	codigo: string;
}

export interface DisponibilzarDareRequest {
	numeroDare: number;
	rgAtendimento: string;
}
