import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Menu as MenuAntd } from 'antd';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// ICONS
import { FaUser, FaCog, FaTable } from 'react-icons/fa';

// ROUTES
import { ROUTE_SGU_USUARIO_CADASTRO_CONSULTA } from 'pages/Poupatempo/Sgu/Usuarios/routes/paths';
import {
	ROUTE_SGU_ADMIN_FUNCIONALIDADE,
	ROUTE_SGU_ADMIN_LOCALIDADE,
	ROUTE_SGU_ADMIN_MENSAGEM,
	ROUTE_SGU_ADMIN_MENSAGEM_ERRO,
	ROUTE_SGU_ADMIN_MENUS,
	ROUTE_SGU_ADMIN_MOTIVO,
	ROUTE_SGU_ADMIN_MUNICIPIO,
	ROUTE_SGU_ADMIN_MUNICIPIO_IBGE,
	ROUTE_SGU_ADMIN_NATURALIDADE,
	ROUTE_SGU_ADMIN_ORGAO,
	ROUTE_SGU_ADMIN_PARAMETROS_LOCALIDADE,
	ROUTE_SGU_ADMIN_PARAMETROS_SISTEMA,
	ROUTE_SGU_ADMIN_PARAMETROS_SISTEMA_REDIS,
	ROUTE_SGU_ADMIN_PERFIL,
	ROUTE_SGU_ADMIN_SIGLA,
	ROUTE_SGU_ADMIN_STATUS,
	ROUTE_SGU_ADMIN_SUFIXO,
} from 'pages/Poupatempo/Sgu/Administrar/routes/paths';
import {
	ROUTE_SGU_RELATORIO_ESTATISTICA,
	ROUTE_SGU_RELATORIO_QUEIMA_DARE,
	ROUTE_SGU_RELATORIO_SITUACAO_DARE,
	ROUTE_SGU_RELATORIO_USUARIO_POR_LOCALIDADE,
	ROUTE_SGU_RELATORIO_RELATORIO_MENSAL_DARE,
} from 'pages/Poupatempo/Sgu/Relatorios/routes/paths';
// import { ROUTE_SGU_ADMIN_ALTERAR_SENHA } from 'pages/Poupatempo/Sgu/AlterarSenha/routes/paths';

// MENU ANTD
const { SubMenu, Item } = MenuAntd;

const Menu: React.FC = () => {
	const [current, setCurrent] = useState<string>('');
	const [visible, setVisible] = useState(false);
	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const location = useLocation();

	const handleClick = useCallback((values: any) => {
		setCurrent(values.key);
	}, []);

	useEffect(() => {
		if (location.pathname.includes('/poupatempo/sgu')) {
			setVisible(true);
		} else {
			setVisible(false);
		}
	}, [location]);

	return (
		<>
			{visible && (
				<MenuAntd
					onClick={handleClick}
					selectedKeys={[current]}
					mode="horizontal"
				>
					<SubMenu
						key="User"
						icon={<FaUser style={{ marginRight: '10px' }} />}
						title="Usuários"
					>
						<Item key="user:1">
							<Link to={ROUTE_SGU_USUARIO_CADASTRO_CONSULTA}>
								Cadastro - Consulta
							</Link>
						</Item>
					</SubMenu>
					{user?.permisaoUsuario?.isTemAcessoADM && (
						<SubMenu
							key="Admin"
							icon={<FaCog style={{ marginRight: '10px' }} />}
							title="Administrar"
						>
							<Item key="admin:1">
								<Link to={ROUTE_SGU_ADMIN_FUNCIONALIDADE}>Funcionalidades</Link>
							</Item>
							<SubMenu key="localidades" title="Localidades">
								<Item key="localidades:1">
									<Link to={ROUTE_SGU_ADMIN_LOCALIDADE}>Localidade</Link>
								</Item>
							</SubMenu>
							<SubMenu key="mensagens" title="Mensagens">
								<Item key="mensagens:1">
									<Link to={ROUTE_SGU_ADMIN_MENSAGEM_ERRO}>
										Mensagem de Erro
									</Link>
								</Item>
								<Item key="mensagens:2">
									<Link to={ROUTE_SGU_ADMIN_MENSAGEM}>Mensagem De/Para</Link>
								</Item>
							</SubMenu>
							<SubMenu key="municipios" title="Municípios">
								<Item key="municipios:1">
									<Link to={ROUTE_SGU_ADMIN_MUNICIPIO}>Município</Link>
								</Item>
								<Item key="municipios:2">
									<Link to={ROUTE_SGU_ADMIN_MUNICIPIO_IBGE}>
										Município IBGE
									</Link>
								</Item>
							</SubMenu>
							<SubMenu key="parametro" title="Paramêtro">
								<Item key="parametro:1">
									<Link to={ROUTE_SGU_ADMIN_PARAMETROS_LOCALIDADE}>
										Parâmetros da Localidade
									</Link>
								</Item>
								<Item key="parametro:2">
									<Link to={ROUTE_SGU_ADMIN_PARAMETROS_SISTEMA}>
										Parâmetros do Sistema
									</Link>
								</Item>
								<Item key="parametro:3">
									<Link to={ROUTE_SGU_ADMIN_PARAMETROS_SISTEMA_REDIS}>
										Parâmetros do Sistema (Redis)
									</Link>
								</Item>
							</SubMenu>
							<Item key="admin:2">
								<Link to={ROUTE_SGU_ADMIN_MENUS}>Menus</Link>
							</Item>
							<Item key="admin:3">
								<Link to={ROUTE_SGU_ADMIN_MOTIVO}>Motivo</Link>
							</Item>
							<Item key="admin:4">
								<Link to={ROUTE_SGU_ADMIN_NATURALIDADE}>Naturalidade</Link>
							</Item>
							<Item key="admin:5">
								<Link to={ROUTE_SGU_ADMIN_ORGAO}>Órgão</Link>
							</Item>
							<Item key="admin:6">
								<Link to={ROUTE_SGU_ADMIN_PERFIL}>Perfil</Link>
							</Item>
							<Item key="admin:7">
								<Link to={ROUTE_SGU_ADMIN_SIGLA}>Sigla</Link>
							</Item>
							<Item key="admin:8">
								<Link to={ROUTE_SGU_ADMIN_STATUS}>Status</Link>
							</Item>
							<Item key="admin:9">
								<Link to={ROUTE_SGU_ADMIN_SUFIXO}>Sufixo</Link>
							</Item>
						</SubMenu>
					)}
					{user?.permisaoUsuario?.isTemAcessoGerenciarRelatorio && (
						<SubMenu
							key="Report"
							icon={<FaTable style={{ marginRight: '10px' }} />}
							title="Relatórios"
						>
							<Item key="report:1">
								<Link to={ROUTE_SGU_RELATORIO_USUARIO_POR_LOCALIDADE}>
									Relação de Usuários
								</Link>
							</Item>
							<Item key="report:2">
								<Link to={ROUTE_SGU_RELATORIO_ESTATISTICA}>Estatística</Link>
							</Item>
							<Item key="report:3">
								<Link to={ROUTE_SGU_RELATORIO_QUEIMA_DARE}>Queima de DARE</Link>
							</Item>
							<Item key="report:4">
								<Link to={ROUTE_SGU_RELATORIO_SITUACAO_DARE}>
									Consulta Status DARE
								</Link>
							</Item>
							<Item key="report:5">
								<Link to={ROUTE_SGU_RELATORIO_RELATORIO_MENSAL_DARE}>
									Relatório Mensal DARE
								</Link>
							</Item>
						</SubMenu>
					)}
					{/* <Item
						key="ChangePassword"
						icon={<FaKey />}
						style={{ fontSize: 'calc(0.8rem + 0%)' }}
					>
						<Link to={ROUTE_SGU_ADMIN_ALTERAR_SENHA}>Alterar Senha</Link>
					</Item> */}
				</MenuAntd>
			)}
		</>
	);
};

export default Menu;
