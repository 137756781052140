import React from 'react';

import { Row, Col } from 'antd';

// REDUX
import { ConsultaUsuarioPendente } from 'store/modules/api/aac/novoPainelDeControle/consultaUsuarioPendente/types';

// COMPONENTS
import Radio from 'components/Common/Form/Radio';
import CheckBox from 'components/Common/Form/Checkbox';

import DadosRg from '../../components/DadosRg';

import IPN from './components/IPN';
import ProcessoVara from './components/ProcessoVara';
import BO from './components/BO';
import CertidaoCriminais from './components/CertidaoCriminais';
import CertidaoDistribuidor from './components/CertidaoDistribuidor';
import Observacoes from './components/Observacoes';

interface Props {
	data: ConsultaUsuarioPendente;
}

const Exigencia: React.FC<Props> = ({ data }) => {
	return (
		<>
			<DadosRg data={data} />

			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Radio
						name="Exigência"
						defaultValue="sim"
						options={[{ value: 'sim', label: 'Exigência' }]}
						onChange={() => {}}
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={24}>
					<CheckBox
						name="procurado"
						checked={!!data?.exigenciaProcurado?.trim()}
						onChange={() => {}}
						disabled
						subtitle="PROCURADO"
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={24}>
					<CheckBox
						name="preso"
						checked={!!data?.exigenciaPreso?.trim()}
						onChange={() => {}}
						disabled
						subtitle="PRESO"
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={24}>
					<CheckBox
						name="evadido"
						onChange={() => {}}
						checked={!!data?.exigenciaEvadido?.trim()}
						disabled
						subtitle="EVADIDO"
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={24}>
					<CheckBox
						name="copiaApresentado"
						checked={!!data?.exigCopiaApresentado?.trim()}
						onChange={() => {}}
						disabled
						subtitle="Copia do documento apresentado (RG/cnh/Outros)"
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={24}>
					<CheckBox
						name="baixaDesaparecido"
						checked={!!data?.exigBaixaDesaparecido?.trim()}
						onChange={() => {}}
						disabled
						subtitle="Baixa da queixa de desaparecimento"
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={24}>
					<CheckBox
						name="certidaoObjeto"
						checked={!!data?.exigCertidaoObjeto?.trim()}
						onChange={() => {}}
						disabled
						subtitle="Certidão de Objeto e Pé"
					/>
				</Col>
			</Row>

			<IPN data={data} />

			<ProcessoVara data={data} />

			<BO data={data} />

			<CertidaoCriminais data={data} />

			<CertidaoDistribuidor data={data} />

			<Observacoes data={data} />
		</>
	);
};

export default Exigencia;
