import { IImpressaoTrocaVoluntariaPlaca } from 'pages/DetranCrv/Processos/ImpressaoTrocaVoluntariaPlaca/form';
import { Types } from './types';

export function impressaoTrocaVoluntariaPlacaRequest(
	payload: IImpressaoTrocaVoluntariaPlaca,
) {
	return {
		type: Types.IMPRESSAO_TROCA_VOLUNTARIA_PLACA_REQUEST,
		payload,
	};
}
export function impressaoTrocaVoluntariaPlacaSuccess(payload: any) {
	return {
		type: Types.IMPRESSAO_TROCA_VOLUNTARIA_PLACA_SUCCESS,
		payload,
	};
}
export function impressaoTrocaVoluntariaPlacaFailure(payload: []): any {
	return {
		type: Types.IMPRESSAO_TROCA_VOLUNTARIA_PLACA_FAILURE,
		payload,
	};
}

export function impressaoTrocaVoluntariaPlacaClear(): any {
	return {
		type: Types.IMPRESSAO_TROCA_VOLUNTARIA_PLACA_CLEAR,
	};
}
