import React, { ChangeEvent, useCallback, useState } from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

// REDUX
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import FormBox from 'components/Common/Form/FormBox';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask/index';
import Select from 'components/Common/Form/Select';
import CheckBox from 'components/Common/Form/Checkbox';

// FORM
import { removeAcentoCedilha } from 'utils/genericFunctions';
import { IFormCadastroRg } from '../../../form';

// MODAL
import CidadaoSemEmail from './CidadaoSemEmail';

interface Props {
	formik: FormikProps<IFormCadastroRg>;
}

const DadosAdicionaisContato: React.FC<Props> = ({ formik }) => {
	const { setFieldValue } = formik;

	const [modalCidadaoSemEmail, setModalCidadaoSemEmail] =
		useState<boolean>(false);

	const handleChangeCheckboxEmail = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { checked } = e.target;

			if (checked) {
				setModalCidadaoSemEmail(true);
				return;
			}

			formik.setFieldValue('naoPossuiEmail', false);
		},
		[formik],
	);

	return (
		<>
			<FormBox title="Dados adicionais de contato">
				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={Select}
							title="Envio de Aviso"
							name="solicitacao"
							defaultValue={formik.values.solicitacao}
							defaultFirstOption={false}
							options={data.envioAviso}
							onChange={(v: string) => setFieldValue('solicitacao', v)}
							error={!!formik.errors.solicitacao}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={InputMask}
							title="Telefone Residencial"
							name="foneResidencia"
							mask="(99)9999-9999"
							error={!!formik.errors.foneResidencia}
						/>
					</Col>

					<Col span={12}>
						<Field
							as={InputMask}
							title="Telefone Comercial"
							name="foneComercial"
							mask="(99)9999-9999"
							size={90}
							error={!!formik.errors.foneComercial}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={InputMask}
							title="Telefone Celular"
							required={
								formik.values.solicitacao === '2' ||
								formik.values.solicitacao === 'SMS'
							}
							name="celular"
							mask="(99)99999-9999"
							error={!!formik.errors.celular}
						/>
					</Col>

					<Col span={12}>
						<Field
							as={Select}
							title="Operadora"
							name="operadora"
							options={data.operadoraCelular}
							onChange={(v: string) => setFieldValue('operadora', v)}
							size={90}
							error={!!formik.errors.operadora}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={17}>
						<Field
							as={Input}
							title="E-mail"
							name="email"
							maxLength={60}
							size={96.5}
							value={removeAcentoCedilha(formik.values.email)}
							required={!formik.values.naoPossuiEmail}
							disabled={formik.values.naoPossuiEmail}
							error={!!formik.errors.email}
						/>
					</Col>

					<Col>
						<Field
							as={CheckBox}
							name="naoPossuiEmail"
							checked={formik.values.naoPossuiEmail}
							subtitle="Cidadão não possui e-mail"
							onChange={handleChangeCheckboxEmail}
							error={formik.errors.naoPossuiEmail}
						/>
					</Col>
				</Row>
			</FormBox>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>

			<CidadaoSemEmail
				showModal={modalCidadaoSemEmail}
				onCancel={() => {
					setModalCidadaoSemEmail(false);
					formik.setFieldValue('naoPossuiEmail', false);
				}}
				onOk={() => {
					setModalCidadaoSemEmail(false);
					formik.setFieldValue('email', '');
					formik.setFieldValue('naoPossuiEmail', true);
				}}
			/>
		</>
	);
};

export default DadosAdicionaisContato;
