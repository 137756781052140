import React from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import data from 'store/modules/api/detranCrv/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import CpfCnpj from 'components/Common/Form/Fields/CpfCnpj';
import Placa from 'components/Common/Form/Fields/Placa';

// UTILS
import hasError from 'utils/getFormErrors';
import {
	onlyNumbers,
	onlyLetters,
	onlyNumbersLettersSpace,
} from 'utils/genericFunctions';

// FORM
import { IEmitirAlteracaoCadastral, initialValues } from '../form';

interface Props {
	formik: FormikProps<IEmitirAlteracaoCadastral>;
}

const DadosProprietario: React.FC<Props> = ({ formik }) => {
	const { consultarMunicipioPorUF } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.Enum,
	);

	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section size="lg" title="Dados do Proprietário" titleSize="sm">
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Field
									as={Input}
									title="Nome do Proprietário"
									titleSize="xl"
									name="nomeProprietario"
									maxLength={60}
									size={87}
									required
									disabled
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'nomeProprietario',
											onlyNumbersLettersSpace(v.target.value),
										);
									}}
									error={!!formik.errors.nomeProprietario}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<CEP
									title="CEP do Imóvel do Proprietário"
									titleSize="xl"
									name="cepProprietario"
									defaultValue={String(formik.values.cepProprietario)}
									required
									size={80}
									formik={formik}
									result={(res: IBuscarCepResultado) => {
										formik.setFieldValue('cepProprietario', res.cep);
										formik.setFieldValue(
											'bairroProprietario',
											res.bairro.substring(0, 70),
										);
										formik.setFieldValue(
											'enderecoProprietario',
											res.endereco.substring(0, 70),
										);
									}}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Input}
									title="Bairro"
									titleSize="xl"
									name="bairroProprietario"
									maxLength={70}
									size={92}
									required
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'bairroProprietario',
											onlyLetters(v.target.value),
										);
									}}
									error={!!formik.errors.bairroProprietario}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Endereço do Proprietário"
									titleSize="xl"
									name="enderecoProprietario"
									maxLength={70}
									size={80}
									required
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'enderecoProprietario',
											onlyLetters(v.target.value),
										);
									}}
									error={!!formik.errors.enderecoProprietario}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={InputMask}
									title="Número"
									titleSize="xl"
									name="numeroProprietario"
									mask="99999"
									maskChar=""
									alwaysShowMask={false}
									size={92}
									required
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'numeroProprietario',
											onlyNumbers(v.target.value),
										);
									}}
									error={!!formik.errors.numeroProprietario}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Complemento"
									titleSize="xl"
									name="complementoProprietario"
									maxLength={6}
									size={80}
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'complementoProprietario',
											onlyNumbersLettersSpace(v.target.value),
										);
									}}
									error={!!formik.errors.complementoProprietario}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={InputMask}
									title="Telefone"
									titleSize="xl"
									name="telefone"
									mask="999999999"
									size={92}
									error={!!formik.errors.telefone}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Select}
									title="Município do Proprietário"
									titleSize="xl"
									name="codigoMunicipioProprietario"
									options={consultarMunicipioPorUF.data}
									onChange={(v: number) => {
										formik.setFieldValue('codigoMunicipioProprietario', v);

										const selectedItem = consultarMunicipioPorUF.data.filter(
											item => v === item.value,
										);

										formik.setFieldValue(
											'descricaoMunicipioProprietario',
											selectedItem[0].label,
										);
									}}
									size={80}
									error={hasError(formik.errors, 'municipioProprietario')}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Select}
									title="Identificação do Contribuinte"
									titleSize="xl"
									name="identificacao"
									options={data.identificacao}
									onChange={(v: number) => {
										formik.setFieldValue('identificacao', v);
									}}
									size={92}
									error={hasError(formik.errors, 'identificacao')}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<CpfCnpj
									title="CPF/CNPJ Proprietário"
									titleSize="xl"
									name="cpfCnpjProprietario"
									defaultValue={initialValues.cpfCnpjProprietario}
									maskType={
										formik.values.identificacao === 'FISICA' ? 'cpf' : 'cnpj'
									}
									size={80}
									required
									formik={formik}
								/>
							</Col>

							<Col span={11}>
								<Row>
									<Col span={20}>
										<Field
											as={InputMask}
											title="RG"
											titleSize="xl"
											name="rgProprietario"
											mask="99.999.999"
											error={hasError(formik.errors, 'rgProprietario')}
											required={formik.values.identificacao === 'FISICA'}
										/>
									</Col>
									<span
										style={{
											alignSelf: 'center',
											marginLeft: '2px',
											marginRight: '2px',
										}}
									>
										-
									</span>
									<Col span={3}>
										<Field
											as={InputMask}
											mask="9"
											size={85}
											name="rgDigito"
											error={hasError(formik.errors, 'rgDigito')}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									required={formik.values.identificacao === 'FISICA'}
									title="Órgão Expedidor"
									titleSize="xl"
									name="orgaoExpeditorProprietario"
									maxLength={5}
									size={80}
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'orgaoExpeditorProprietario',
											onlyLetters(v.target.value),
										);
									}}
									error={!!formik.errors.orgaoExpeditorProprietario}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Select}
									titleSize="xl"
									title="UF"
									required={formik.values.identificacao === 'FISICA'}
									name="ufProprietario"
									options={data.uf}
									onChange={(value: string) =>
										formik.setFieldValue('ufProprietario', value)
									}
									error={hasError(formik.errors, 'ufProprietario')}
									size={92}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Nome do Proprietário Anterior"
									titleSize="xl"
									name="nomeProprietarioAnterior"
									maxLength={70}
									size={80}
									required
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'nomeProprietarioAnterior',
											onlyLetters(v.target.value),
										);
									}}
									error={!!formik.errors.nomeProprietarioAnterior}
								/>
							</Col>

							<Col span={11}>
								<Placa
									title="Placa Anterior"
									titleSize="xl"
									name="placaAnterior"
									defaultValue={initialValues.placaAnterior}
									size={92}
									required
									formik={formik}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									disabled
									as={Input}
									title="Município Placa Anterior"
									titleSize="xl"
									name="nomeMunicipioPlacaAnterior"
									maxLength={70}
									size={80}
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'nomeMunicipioPlacaAnterior',
											onlyLetters(v.target.value),
										);
									}}
									error={!!formik.errors.nomeMunicipioPlacaAnterior}
								/>
							</Col>

							<Col span={11}>
								<Field
									disabled
									as={Input}
									title="UF da Placa Anterior"
									titleSize="xl"
									name="ufPlacaAnterior"
									maxLength={70}
									size={92}
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'ufPlacaAnterior',
											onlyLetters(v.target.value),
										);
									}}
									error={!!formik.errors.ufPlacaAnterior}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									required
									as={Input}
									title="E-mail"
									titleSize="xl"
									name="emailCidadao"
									maxLength={70}
									size={80}
									error={!!formik.errors.emailCidadao}
								/>
							</Col>

							<Col span={11}>
								<Field
									required
									as={InputMask}
									title="Telefone de contato"
									titleSize="xl"
									name="telefoneCidadao"
									mask="(99) 99999-9999"
									size={92}
									error={!!formik.errors.telefoneCidadao}
								/>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default DadosProprietario;
