import { all } from 'redux-saga/effects';

import queimaDare from './queimaDare/saga';
import estatistica from './estatisticas/saga';
import relacaoUsuario from './relacaoUsuario/saga';
import relatorioMensalDare from './relatorioMensalDare/saga';

export default all([
	queimaDare,
	estatistica,
	relacaoUsuario,
	relatorioMensalDare,
]);
