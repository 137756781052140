import { combineReducers } from 'redux';

import consultaPermissaoInternacional from './consultaPermissaoInternacional/reducer';
import consultaEPortal from './consultaEPortal/reducer';
import gerarRenach from './gerarRenach/reducer';

export default combineReducers({
	consultaPermissaoInternacional,
	consultaEPortal,
	gerarRenach,
});
