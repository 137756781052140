import {
	logOffAndReloadRequestApi,
	extenderTempoParaInatividade,
} from 'store/modules/api/sgu/loginUnico/actions';

export async function handleTempoInatividade(tempoLogado: number) {
	if (new Date().getTime() - tempoLogado > 21600000) {
		logOffAndReloadRequestApi();
	} else {
		extenderTempoParaInatividade();
	}
}
