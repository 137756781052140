import styled from 'styled-components';

export const ContainerBolsaPovo = styled.div`
	> .ant-row {
		padding-top: 25px;
		padding-bottom: 25px;
		justify-content: center;
		text-align: center;
		> .ant-col {
			background: forestgreen;
			flex-wrap: wrap;
			/* padding: 20px 0; */
		}
		h1 {
			color: white;
		}
		span {
			color: white;
			font-size: 16px;
		}
	}
`;

export const ContainerButtons = styled.div`
	> .ant-row {
		margin-top: 25px;
		margin-bottom: 25px;
		justify-content: center;
	}
`;
