import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	TIPOS_DOCUMENTOS_COMPRA_REQUEST = '@procon/TIPOS_DOCUMENTOS_COMPRA_REQUEST',
	TIPOS_DOCUMENTOS_COMPRA_SUCCESS = '@procon/TIPOS_DOCUMENTOS_COMPRA_SUCCESS',
	TIPOS_DOCUMENTOS_COMPRA_FAILURE = '@procon/TIPOS_DOCUMENTOS_COMPRA_FAILURE',
	TIPOS_DOCUMENTOS_COMPRA_CLEAR = '@procon/TIPOS_DOCUMENTOS_COMPRA_CLEAR',
}

export interface TiposDocumentosCompra {
	status: null | number;
	data: OptionProps[];
}
