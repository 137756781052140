import { all } from 'redux-saga/effects';

import consultarBloqueioDetranRenajudSaga from './consultarBloqueioDetranRenajud/saga';
import consultarRestricaoVeiculoSaga from './consultarRestricaoVeiculo/saga';
import consultarTaxaServicoSaga from './consultarTaxaServico/saga';
import saveTaxaServicoSaga from './saveTaxaServico/saga';

export const consultarBloqueioDetranRenajud =
	consultarBloqueioDetranRenajudSaga;
export const consultarRestricaoVeiculo = consultarRestricaoVeiculoSaga;
export const consultarTaxaServico = consultarTaxaServicoSaga;
export const saveTaxaServico = saveTaxaServicoSaga;

export default all([
	consultarBloqueioDetranRenajud,
	consultarRestricaoVeiculo,
	consultarTaxaServico,
	saveTaxaServico,
]);
