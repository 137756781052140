// import { IRequestCadastraExameMedico } from 'store/modules/detranCnh/divisaoEquitativa/types';
import * as Yup from 'yup';

import { formatDateISOWithOutUTC, unformatDate } from 'utils/genericFunctions';
import {
	GerarRenachIntegradoRequest,
	DadosExamePsicologo,
} from 'store/modules/api/detranCnh/sockets/saveGerarRenach/types';

export interface ICadastraExamePsicologico {
	cpf_cidadao: number;
	tipo_processo: string;
	cep_cidadao: number;
	codigo_ciretran_exame_medico: number | string;
	categoria_pretendida: string;
	id_medico: number;
	codigo_ciretran_exame_psicologo: number;
	id_psicologo: number;
	data_exame_psicologico: string;
	horario_exame_psicologico: string;
	renach: string;
	id_micro_regiao_psicologo: number | string;
	usuario: string;
	regiao?: number | string;
	data_exame_medico?: string;
	horario_exame_medico?: string;
}

export function treatValuesGerarRenach(
	values: GerarRenachIntegradoRequest,
	agendarExames: DadosExamePsicologo,
	cpfUsuario: string,
): GerarRenachIntegradoRequest {
	const horaFormat =
		agendarExames.horarioExamePsicologico &&
		formatDateISOWithOutUTC(agendarExames.horarioExamePsicologico, true).split(
			' ',
		)[1];

	const formattedData = {
		candidato: { cpfUsuario, ...values.candidato },
		dadosExameMedico: values.dadosExameMedico,
		dadosExamePsicologo: {
			codigoCiretranExamePsicologo: agendarExames.codigoCiretranExamePsicologo,
			dataExamePsicologico:
				agendarExames.dataExamePsicologico &&
				unformatDate(agendarExames.dataExamePsicologico),
			horarioExamePsicologico: horaFormat,
			idMicroRegiaoPsicologo: agendarExames.idMicroRegiaoPsicologo,
			idPsicologo: agendarExames.idPsicologo,
		},
		evento: values.gerarRenachBase?.evento,
	};
	return formattedData;
}

export const schema = Yup.object<ICadastraExamePsicologico>().shape({
	regiao: Yup.string().when('codigo_ciretran', {
		is: codigo_ciretran => codigo_ciretran === '18',
		then: Yup.string().required('Campo Obrigatório: Região.'),
	}),
	id_micro_regiao_psicologo: Yup.string().when('codigo_ciretran', {
		is: codigo_ciretran => codigo_ciretran === '18',
		then: Yup.string().required('Campo Obrigatório: Micro Região.'),
	}),
	data_exame_psicologico: Yup.string().required('Campo Obrigatório: Data.'),
	horario_exame_psicologico: Yup.string().required('Campo Obrigatório: Hora.'),
});
