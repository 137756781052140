import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_RELATORIOS } from 'services/_path';
import { Types } from './types';
import {
	impressaoTermoPaternidadeSuccess,
	impressaoTermoPaternidadeFailure,
} from './actions';

function* impressaoTermoPaternidadeRequest({ payload }: any) {
	const response: ApiResponse = yield call(
		postApi,
		PATH_RELATORIOS,
		`mp/${CHANNEL}/termoPaternidade`,
		payload,
	);

	if (response.status === 200) {
		yield put(impressaoTermoPaternidadeSuccess(response));
	} else if (response.error) {
		yield put(impressaoTermoPaternidadeFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.IMPRESSAO_TERMO_PATERNIDADE_REQUEST,
		impressaoTermoPaternidadeRequest,
	),
]);
