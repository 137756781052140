import styled, { css } from 'styled-components';

interface ContainerProps {
	size: 'sm' | 'md' | 'lg';
	striped: boolean;
	bold: boolean;
	hover: boolean;
}

export const Container = styled.div<ContainerProps>`
	width: 100%;

	.icon-class {
		width: 0%;
	}

	th + .icon-class {
		text-align: center !important;
	}

	.ant-table-wrapper {
		width: 100%;
	}

	.ant-table {
		color: ${props => props.theme.typography.primary};

		table {
			border-spacing: 2px;
		}

		.ant-table-header {
			table {
				table-layout: fixed;
			}
		}

		.ant-table-body {
			table {
				table-layout: auto !important;
			}
		}
	}

	.ant-table-thead {
		tr {
			th {
				border: 1px solid #d0c2c9;
				color: ${props => props.theme.typography.primary};
				font-weight: 500;

				${props =>
					props.theme.title === 'light'
						? css`
								background-color: #e1dfe0;
						  `
						: css`
								background-color: #000000;
						  `}
			}
		}

		.ant-table-column-sorters {
			padding: unset !important;
		}
	}

	.ant-table-tbody {
		.ant-table-row {
			&.green {
				.ant-table-cell {
					background-color: #a3d1a3 !important;
					border: 1px solid #7cbf7c !important;
				}
			}

			.ant-table-cell {
				background-color: ${props => props.theme.colors.background};
				border: 1px solid ${props => props.theme.colors.border};
			}
		}

		${props =>
			props.striped &&
			css`
				.ant-table-row:nth-child(even) {
					.ant-table-cell {
						background-color: #f2f2f2;
					}
				}
			`};
	}

	${props =>
		props.size === 'sm' &&
		css`
			.ant-table {
				.ant-table-cell {
					font-size: 12px;
					padding: 3px 3px !important;
				}
			}
		`};

	${props =>
		props.size === 'md' &&
		css`
			.ant-table {
				.ant-table-cell {
					font-size: 14px;
					padding: 6px 6px !important;
				}
			}
		`};

	${props =>
		props.size === 'lg' &&
		css`
			.ant-table {
				.ant-table-cell {
					font-size: 16px;
					padding: 9px 9px !important;

					.ant-table-column-sorters-with-tooltip {
						.ant-table-column-sorters {
							span {
								font-size: 16px;
							}
						}
					}
				}
			}
		`};

	${props =>
		props.bold &&
		css`
			.ant-table {
				font-weight: 500;
			}
		`};

	${props =>
		props.hover &&
		css`
			tr {
				&:hover {
					cursor: pointer;
				}
			}
		`};

	.ant-table-expanded-row > .ant-table-cell {
		padding: 0 !important;
	}

	.ant-table-content {
		table {
			tbody {
				.ant-table-row-expand-icon-cell {
					padding: 6px 14px !important;
				}
			}
		}
	}

	.ant-table {
		.ant-table-tbody {
			.ant-table-wrapper {
				&:only-child {
					.ant-table {
						margin: 0;
					}
				}
			}
		}
	}
`;

export const UlTableIcons = styled.div`
	display: flex;
	justify-content: space-around;
	margin: 0;
	list-style: none;
	width: 100%;

	svg {
		cursor: pointer;
		margin: 0 10px;
	}
`;
