import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_PREFEITURAS } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import { consultaCanaisSuccess, consultaCanaisFailure } from './actions';

function* consultaCanais() {
	const response: ApiResponse = yield call(getApi, PATH_PREFEITURAS, 'canais');

	if (response.status === 200) {
		yield put(consultaCanaisSuccess(response));
	} else if (response.status === 204) {
		yield put(consultaCanaisFailure([]));
	} else if (response.error) {
		yield put(consultaCanaisFailure(response.message));
	}
}

export default all([takeLatest(Types.CONSULTA_CANAIS, consultaCanais)]);
