import RenachGerado from 'pages/DetranCnh/components/pages/RenachGerado';
import React from 'react';

const RenachPermissaoInternacionalAutomatizado: React.FC = () => {
	const renach = {
		nome: '',
		renach: '',
	};

	return <RenachGerado renderData={renach} />;
};
export default RenachPermissaoInternacionalAutomatizado;
