import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`
  .ant-select-dropdown {
		padding: 0!important;
		${props => css`
			background-color: ${props.theme.colors.background};
		`}

    .ant-select-item {
      font-size: calc(0.7rem + ${props => props.theme.fontScale}%);

			&.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
				${props =>
					props.theme.title === 'light'
						? css`
								background-color: #e6e6e6;
						  `
						: css`
								background-color: #333333;
						  `}
			}
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: ${props => props.theme.typography.primary};
      background-color: #e6e6e6;

			${props =>
				props.theme.title === 'light'
					? css`
							background-color: #e6e6e6;
					  `
					: css`
							background-color: #333333;
					  `}
    }

		.ant-select-item-option-content {
			color: ${props => props.theme.typography.primary};
			text-transform: uppercase;
		}
  }

	.ant-select-disabled {
		border-color: rgba(143, 143, 143, 0.6) !important;

		.ant-select-selector {
			border-color: rgba(143, 143, 143, 0.4) !important;
    	background-color: rgba(248, 248, 248, 0.6) !important;
		}

		.ant-select-selection-item {
			${props =>
				props.theme.title === 'light'
					? css`
							border-color: rgba(143, 143, 143, 0.4) !important;
							background-color: rgba(248, 248, 248, 0.6) !important;
					  `
					: css`
							border-color: rgba(143, 143, 143, 0.4) !important;
							background-color: rgb(154 150 150 / 60%) !important;
					  `}
		}

		.ant-select-arrow {
			background-color: rgba(248, 248, 248, 0.6) !important;
			border-color: rgba(143, 143, 143, 0.6) !important;
		}

	}
`;
