import * as Yup from 'yup';

// REDUX
import { ConsultaTransferenciaEnderecoRequest } from 'store/modules/api/detranCnh/alteracaoEndereco/consultaTransferenciaEndereco/types';

// UTILS
import { validaCpf, limparMascara } from 'utils/genericFunctions';

export const initialValues: ConsultaTransferenciaEnderecoRequest = {
	cpf: '',
	numRegistro: '',
	numPgu: '',
};

export function treatValues(
	values: ConsultaTransferenciaEnderecoRequest,
): ConsultaTransferenciaEnderecoRequest {
	const formattedData: ConsultaTransferenciaEnderecoRequest = {
		cpf: limparMascara(values.cpf),
		numRegistro: values.numRegistro,
		numPgu: values.numPgu,
		// numRegistro: values.numRegistro,
		// numPgu: values.numPgu,
	};

	return formattedData;
}

export const schema = Yup.object<ConsultaTransferenciaEnderecoRequest>().shape({
	cpf: Yup.string().test({
		name: 'cpfValidation',
		exclusive: true,
		message: 'CPF digitado inválido.',
		test: value => validaCpf(value),
	}),
});
