export enum Types {
	CONSULTAR_NUMERO_MOTOR_BIN_REQUEST = '@DETRAN-CRV/CONSULTAR_NUMERO_MOTOR_BIN_REQUEST',
	CONSULTAR_NUMERO_MOTOR_BIN_SUCCESS = '@DETRAN-CRV/CONSULTAR_NUMERO_MOTOR_BIN_SUCCESS',
	CONSULTAR_NUMERO_MOTOR_BIN_FAILURE = '@DETRAN-CRV/CONSULTAR_NUMERO_MOTOR_BIN_FAILURE',
	CONSULTAR_NUMERO_MOTOR_BIN_CLEAR = '@DETRAN-CRV/CONSULTAR_NUMERO_MOTOR_BIN_CLEAR',
}

export interface consultarNumeroMotorBin {
	status: number;
	data: dataConsultarNumeroMotorBin | null;
}

interface dataConsultarNumeroMotorBin {
	dadosMotor: {
		dataGeracao: string;
		documentoPesquisado: string;
		numeroMotor: string;
		situacaoMotor: string;
	};
}
export interface IRequestConsultarNumeroMotorBin {
	numeroMotor: string;
	numeroDocumento: string;
}
