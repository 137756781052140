export const CANAL_SISTEMA = {
	PRESENCIAL: {
		ID: 1,
		DESCRICAO: 'PRESENCIAL',
	},
};

export const STATUS_BIOMETRICS = {
	FORA_TEMPORALIDADE: 'FORA_TEMPORALIDADE',
	BIOMETRICS_OK: 'BIOMETRICS_OK',
	BIOMETRICS_NOK: 'BIOMETRICS_NOK',
};
