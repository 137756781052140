// ADMINISTRAR

// PAGES
import { ConsultaMunicipio } from './consultaMunicipio/types';

export enum Types {
	ADMIN_CLEAR = '@sgu/SGU_CLEAR',
}

export interface AdminState {
	consultaMunicipio: ConsultaMunicipio;
}
