// TYPES
import { ReducerAction } from 'store/modules/types';
import { ScriptPreAtendimento, Types } from './types';

export function scriptPreAtendimentoRequest(
	payload: ScriptPreAtendimento,
): ReducerAction {
	return {
		type: Types.SCRIPT_PRE_ATENDIMENTO_REQUEST,
		payload,
	};
}
export function scriptPreAtendimentoClear(): ReducerAction {
	return {
		type: Types.SCRIPT_PRE_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
