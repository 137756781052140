import { ConsultaToxicologico } from 'store/modules/api/detranCnh/cidadao/consultaToxicologico/types';

export const verifyDrugTestInternalCodeIsValid = (
	consultaToxicologicoResponse: ConsultaToxicologico,
) => {
	const codigoInterno =
		consultaToxicologicoResponse.data?.codigoInterno?.trim() || null;

	const valid = true;
	const invalid = false;

	if (codigoInterno !== null && Number(codigoInterno) === 0) {
		return valid;
	}

	return invalid;
};

export const verifyDrugTestIsValid = (
	flagToxicologico: string,
	consultaToxicologicoResponse: ConsultaToxicologico,
) => {
	if (flagToxicologico === 'N') {
		return {
			valid: false,
			message:
				'De acordo com a Resolução Contran nº 843/2021, deve ser providenciado antecipadamente, para apresentação ao médico de trânsito, o exame toxicológico para os processos de Habilitação que envolvem as categorias C, D e E.',
		};
	}
	if (
		(flagToxicologico === 'S' &&
			consultaToxicologicoResponse.status === 200 &&
			consultaToxicologicoResponse.data &&
			!verifyDrugTestInternalCodeIsValid(consultaToxicologicoResponse)) ||
		(consultaToxicologicoResponse.status === 400 && flagToxicologico === 'S')
	) {
		if (
			consultaToxicologicoResponse.status === 400 &&
			consultaToxicologicoResponse.dataError?.message
		) {
			return {
				valid: false,
				message: `Exame toxicológico: ${consultaToxicologicoResponse.dataError.message}`,
			};
		}

		return {
			valid: false,
			message:
				'Exame toxicológico fora do prazo ou pendente. Por favor procure os laboratórios credenciados para novo exame e tente novamente!',
		};
	}
	if (
		flagToxicologico === 'S' &&
		consultaToxicologicoResponse.status === 200 &&
		consultaToxicologicoResponse.data &&
		verifyDrugTestInternalCodeIsValid(consultaToxicologicoResponse)
	) {
		return {
			valid: true,
			message: '',
		};
	}

	return {
		valid: false,
		message: '',
	};
};
