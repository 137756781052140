import { all } from 'redux-saga/effects';

import atualizarMunicipioIbgeSaga from './atualizarMunicipioIbge/saga';
import cadastrarMunicipioIbgeSaga from './cadastrarMunicipioIbge/saga';
import consultarMunicipiosIbgeSaga from './consultarMunicipiosIbge/saga';
import excluirMunicipioIbgeSaga from './excluirMunicipioIbge/saga';

export default all([
	atualizarMunicipioIbgeSaga,
	consultarMunicipiosIbgeSaga,
	cadastrarMunicipioIbgeSaga,
	excluirMunicipioIbgeSaga,
]);
