export enum Types {
	EIXO_REQUEST = '@EDUCACAO/EIXO_REQUEST',
	EIXO_SUCCESS = '@EDUCACAO/EIXO_SUCCESS',
	EIXO_FAILURE = '@EDUCACAO/EIXO_FAILURE',
}

export interface Eixo {
	status: number;
	data: null | EixoData;
}

interface EixoData {
	processoId: number;
	eixos: EixoEnum[];
}

interface EixoEnum {
	codigo: string;
	descricao: string;
}
