import { Types } from './types';

export function consultaCombustivelRequest() {
	return {
		type: Types.CONSULTA_COMBUSTIVEL_REQUEST,
	};
}
export function consultaCombustivelSuccess(payload: any) {
	return {
		type: Types.CONSULTA_COMBUSTIVEL_SUCCESS,
		payload,
	};
}
export function consultaCombustivelFailure(payload: []): any {
	return {
		type: Types.CONSULTA_COMBUSTIVEL_FAILURE,
		payload,
	};
}
