import { all } from 'redux-saga/effects';

import motivosSaga from './motivos/combineSaga';
import municipiosSaga from './municipios/combineSaga';
import naturalidadesSaga from './naturalidades/combineSaga';
import orgaosSaga from './orgaos/combineSaga';
import perfisSaga from './perfis/combineSaga';
import statusSaga from './status/combineSaga';
import siglasSaga from './siglas/combineSaga';
import localidadeSaga from './localidades/combineSaga';
import localidadesAssociadasSaga from './localidadesAssociadas/combineSaga';
import sufixosSaga from './sufixos/combineSaga';
import menusSaga from './menus/combineSaga';
import unidadesSaga from './unidades/combineSaga';
import funcionalidadesSaga from './funcionalidades/combineSaga';
import mensagensSaga from './mensagens/combineSaga';
import parametrosSaga from './parametros/combineSaga';

export default all([
	motivosSaga,
	municipiosSaga,
	naturalidadesSaga,
	orgaosSaga,
	perfisSaga,
	statusSaga,
	siglasSaga,
	localidadeSaga,
	localidadesAssociadasSaga,
	sufixosSaga,
	menusSaga,
	unidadesSaga,
	funcionalidadesSaga,
	mensagensSaga,
	parametrosSaga,
]);
