import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_COMBUSTIVEL_REQUEST = '@DETRAN-CRV/CONSULTA_COMBUSTIVEL_REQUEST',
	CONSULTA_COMBUSTIVEL_SUCCESS = '@DETRAN-CRV/CONSULTA_COMBUSTIVEL_SUCCESS',
	CONSULTA_COMBUSTIVEL_FAILURE = '@DETRAN-CRV/CONSULTA_COMBUSTIVEL_FAILURE',
}

export interface consultaCombustivel {
	status: number;
	data: OptionProps[];
}
