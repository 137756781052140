import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export interface IRequestConsultaParametros {
	parametro?: string;
}

export interface IRequestCadastraParametro {
	codigo?: number;
	descricao: string;
	nome: string;
	rotulo: string;
	tamanho: number | string;
	tipo: string;
}

export function consultaParametros(
	data: IRequestConsultaParametros,
): ReducerAction {
	return {
		type: Types.CONSULTA_PARAMETROS,
		payload: data,
	};
}
export function consultaParametrosSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PARAMETROS_SUCCESS,
		payload,
	};
}
export function consultaParametrosFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PARAMETROS_FAILURE,
		payload,
	};
}

export function cadastraParametros(
	data: IRequestCadastraParametro,
): ReducerAction {
	return {
		type: Types.CADASTRA_PARAMETROS,
		payload: data,
	};
}
export function cadastraParametrosSuccess(payload: []): ReducerAction {
	return {
		type: Types.CADASTRA_PARAMETROS_SUCCESS,
		payload,
	};
}
export function cadastraParametrosFailure(payload: []): ReducerAction {
	return {
		type: Types.CADASTRA_PARAMETROS_FAILURE,
		payload,
	};
}

export function alteraParametros(
	data: IRequestCadastraParametro,
): ReducerAction {
	return {
		type: Types.ALTERA_PARAMETROS,
		payload: data,
	};
}
export function alteraParametrosSuccess(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_PARAMETROS_SUCCESS,
		payload,
	};
}
export function alteraParametrosFailure(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_PARAMETROS_FAILURE,
		payload,
	};
}

export function removeParametros(codigo: number): ReducerAction {
	return {
		type: Types.REMOVE_PARAMETROS,
		payload: codigo,
	};
}
export function removeParametrosSuccess(codigo: number): ReducerAction {
	return {
		type: Types.REMOVE_PARAMETROS_SUCCESS,
		payload: codigo,
	};
}
export function removeParametrosFailure(payload: []): ReducerAction {
	return {
		type: Types.REMOVE_PARAMETROS_FAILURE,
		payload,
	};
}

export function clearRedirect(): ReducerAction {
	return {
		type: Types.REDIRECT_PARAMETROS_CLEAR,
		payload: null,
	};
}
