import { IConsultarQueimaDareState } from './queimaDare/types';
import { IConsultarEstatisticaState } from './estatisticas/types';
import { IConsultarRelacaoUsuarioState } from './relacaoUsuario/types';
import { IConsultarRelatorioMensalDareState } from './relatorioMensalDare/types';

export enum Types {
	RELATORIOS_CLEAR = '@relatorios/RELATORIOS_CLEAR',
}

export interface RelatoriosState {
	queimaDare: IConsultarQueimaDareState;
	estatistica: IConsultarEstatisticaState;
	relacaoUsuario: IConsultarRelacaoUsuarioState;
	relatorioMensalDare: IConsultarRelatorioMensalDareState;
}
