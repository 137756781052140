export enum Types {
	CONSULTA_ATENDIMENTO = '@atendimento/CONSULTA_ATENDIMENTO',
	CONSULTA_ATENDIMENTO_SUCCESS = '@atendimento/CONSULTA_ATENDIMENTO_SUCCESS',
	CONSULTA_ATENDIMENTO_FAILURE = '@atendimento/CONSULTA_ATENDIMENTO_FAILURE',

	CONSULTA_ATENDIMENTO_USER_DATA = '@atendimento/CONSULTA_ATENDIMENTO_USER_DATA',
	CONSULTA_ATENDIMENTO_USER_DATA_SUCCESS = '@atendimento/CONSULTA_ATENDIMENTO_USER_DATA_SUCCESS',
	CONSULTA_ATENDIMENTO_USER_DATA_FAILURE = '@atendimento/CONSULTA_ATENDIMENTO_USER_DATA_FAILURE',

	REDIRECIONAMENTO_ATENDIMENTO = '@atendimento/REDIRECIONAMENTO_ATENDIMENTO',
	REDIRECIONAMENTO_ATENDIMENTO_CLEAR = '@atendimento/REDIRECIONAMENTO_ATENDIMENTO_CLEAR',
}

export interface AtendimentoState {
	atendimentoData: IAtendimento;
	atendimentoUserData: IAtendimentoUserData;
	redirecionamento: string | null;
	descricaoServico: string | null;
}

interface IAtendimento {
	cpf: string;
	estadoPonto: string;
	estadoUsuario: string;
	idPonto: number;
	id_atendimento: string;
	id_cidadao: string;
	msg: string;
	nomePonto: string;
}

interface IAtendimentoUserData {
	Id: string;
	Nome: string;
	Sexo: string;
	NomeMae: string;
	NomePai: string;
	CPF: string;
	RG: string;
	DigitoRG: string;
	UFRG: string;
	Email: string;
	Nascimento: string;
	TipoRegistro: string;
	Enderecos: IEnderecos[];
	Telefones: ITelefones[];
}

interface IEnderecos {
	CEP: string;
	Logradouro: string;
	Complemento: null;
	Bairro: string;
	Municipio: string;
	UF: string | null;
	Tipo: string;
}
interface ITelefones {
	DDD: string;
	Numero: string;
	Ramal: string | null;
	Tipo: string;
	TelefoneComDDD: string | null;
}

export interface IRequestRecuperarATendimento {
	// canal: string;
	cpf: string;
}

export interface RedirecionarAtendimentoRequest {
	route: string;
	descricaoServico?: string;
}
