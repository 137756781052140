import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultarVeiculoV110Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import { emitirTransferenciasMunicipiosClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirTransferenciaMunicipio/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import PDFViewer from 'components/Common/PDFViewer';
import { ROUTE_DETRAN_CRV } from 'routes/paths';

const ResultadoTransferenciaMunicipio: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [protocoloBase64, setProtocoloBase64] = useState<string>('');
	const [onPrint, setOnPrint] = useState<boolean>(false);

	const { emitirTransferenciaMunicipio } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	useEffect(() => {
		if (emitirTransferenciaMunicipio.data) {
			setProtocoloBase64(emitirTransferenciaMunicipio.data.planilhaRenavam);
		}
	}, [emitirTransferenciaMunicipio.data]);

	const handleClickImprimir = useCallback(() => {
		setOnPrint(true);
	}, []);

	const handleButtonVoltar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());
		dispatch(emitirTransferenciasMunicipiosClear());

		history.push(ROUTE_DETRAN_CRV);
	}, [dispatch, history]);

	return (
		<>
			{emitirTransferenciaMunicipio.data && (
				<>
					<Row>
						<Col span={24}>
							<Section
								size="lg"
								title="Resultado da Transferência"
								titleSize="sm"
							>
								<Row>
									<Col span={22} offset={2}>
										<Row gutter={[0, 5]}>
											<Col span={4}>Número da Ficha:</Col>

											<Col>
												<b>{emitirTransferenciaMunicipio.data.numeroficha}</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={4}>Ano da Ficha:</Col>

											<Col>
												<b>{emitirTransferenciaMunicipio.data.anoFicha}</b>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row gutter={[0, 20]} className="no-print">
									<Col span={24} />
								</Row>

								<Row justify="end" className="no-print">
									<Col>
										<ButtonImage src="imprimir" onClick={handleClickImprimir} />
									</Col>
								</Row>
							</Section>

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Col>
					</Row>

					{onPrint && protocoloBase64 && (
						<PDFViewer
							title="IMPRIMIR FICHA RENAVAM"
							source={protocoloBase64}
							onClose={() => setOnPrint(false)}
						/>
					)}
				</>
			)}
		</>
	);
};

export default ResultadoTransferenciaMunicipio;
