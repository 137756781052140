import { ReducerAction } from 'store/modules/types';
import {
	Types,
	IResponseEmitirModificacaoDadosCadastrais,
	IRequestWithHeader,
} from './types';

export function emitirModificacaoDadosCadastraisRequest(
	payload: IRequestWithHeader,
): ReducerAction {
	return {
		type: Types.EMITIR_MODIFICACAO_DADOS_CADASTRAIS_REQUEST,
		payload,
	};
}
export function emitirModificacaoDadosCadastraisSuccess(
	payload: IResponseEmitirModificacaoDadosCadastrais,
): ReducerAction {
	return {
		type: Types.EMITIR_MODIFICACAO_DADOS_CADASTRAIS_SUCCESS,
		payload,
	};
}
export function emitirModificacaoDadosCadastraisFailure(): ReducerAction {
	return {
		type: Types.EMITIR_MODIFICACAO_DADOS_CADASTRAIS_FAILURE,
		payload: null,
	};
}
export function emitirModificacaoDadosCadastraisClear(): ReducerAction {
	return {
		type: Types.EMITIR_MODIFICACAO_DADOS_CADASTRAIS_CLEAR,
		payload: null,
	};
}
