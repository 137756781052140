import produce from 'immer';

import { ReducerAction } from 'store/modules/types';

import { Types, TXUT } from './types';

export const INITIAL_STATE: TXUT = {
	status: 0,
	mensagem: '',
	data: {
		codigoRetorno: 0,
		tipoCliente: 0,
		tipoPesquisa: {
			codigo: '',
			descricao: '',
		},
		qtdeTaxas: 0,
		veiculosTaxas: [
			{
				placa: '',
				renavam: '',
				cpfCnpj: '',
				codBanco: 0,
				nsuProdesp: 0,
				dataContabil: '',
				codServico: 0,
				dataInclusaoTaxa: '',
				nsuBanco: 0,
				valor: 0,
				anoLcenciamento: 0,
				taxaSefaz: '',
				statusTaxa: {
					codigo: '',
					descricao: '',
				},
			},
		],
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): TXUT {
	return produce(state, draft => {
		switch (action.type) {
			case Types.TXUT_REQUEST: {
				break;
			}

			case Types.TXUT_SUCCESS: {
				draft.status = action.payload.status;

				draft.data = action.payload.data;

				break;
			}

			case Types.TXUT_FAILURE: {
				const { status, data, message } = action.payload;

				draft.status = status || data.status;

				draft.data = data;

				draft.mensagem = `${data.data.codigo} - ${message}`;

				break;
			}

			case Types.TXUT_CLEAR: {
				draft.status = 0;

				draft.data = INITIAL_STATE.data;

				break;
			}

			default:
		}

		return draft;
	});
}
