import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarParametroSistemaRequest } from './types';

export function cadastrarParametroSistemaRequest(
	data: CadastrarParametroSistemaRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_PARAMETRO_SISTEMA,
		payload: data,
	};
}
export function cadastrarParametroSistemaSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRAR_PARAMETRO_SISTEMA_SUCCESS,
		payload,
	};
}
export function cadastrarParametroSistemaFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_PARAMETRO_SISTEMA_FAILURE,
		payload: null,
	};
}
export function cadastrarParametroSistemaClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_PARAMETRO_SISTEMA_CLEAR,
		payload: null,
	};
}
