// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultarFotosRgState, Types } from './types';

export function consultarFotosRgRequest(payload: string): ReducerAction {
	return {
		type: Types.CONSULTAR_FOTOS_RG_REQUEST,
		payload,
	};
}
export function consultarFotosRgSuccess(
	payload: ConsultarFotosRgState,
): ReducerAction {
	return {
		type: Types.CONSULTAR_FOTOS_RG_SUCCESS,
		payload,
	};
}
export function consultarFotosRgFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_FOTOS_RG_FAILURE,
		payload,
	};
}
export function consultarFotosRgClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_FOTOS_RG_CLEAR,
		payload: null,
	};
}
