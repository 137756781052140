import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_BOLSA_POVO } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestEditarCadastrarEndereco } from './types';

// ACTIONS
import {
	editaCadastraEnderecoSuccess,
	editaCadastraEnderecoFailure,
} from './action';

function* editaCadastraEndereco(request: ReducerAction) {
	const { payload }: { payload: IRequestEditarCadastrarEndereco } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_BOLSA_POVO,
		`cidadao/${CHANNEL}/enderecos`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(editaCadastraEnderecoSuccess(response));
	} else if (response.error) {
		yield put(editaCadastraEnderecoFailure());
	}
}

export default all([
	takeLatest(Types.EDITA_CADASTRA_ENDERECO, editaCadastraEndereco),
]);
