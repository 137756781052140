import { takeLatest, call, put, all } from 'redux-saga/effects';

// GENERICS
import { cleanEmpty } from 'utils/genericFunctions';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarMensagensRequest } from './types';

// ACTIONS
import {
	consultarMensagensSuccess,
	consultarMensagensFailure,
} from './actions';

function* consultarMensagens(request: ReducerAction) {
	const { payload }: { payload: ConsultarMensagensRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarMensagensRequest;

	const response: ApiResponse = yield call(getApi, PATH_SGU, `mensagens`, body);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarMensagensSuccess(response));
	} else {
		yield put(consultarMensagensFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_MENSAGENS_REQUEST, consultarMensagens),
]);
