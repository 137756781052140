import { combineReducers } from 'redux';

import buscaPaginada from './buscaPaginada/reducer';
import consultaFornecedor from './consultaFornecedor/reducer';
import pesquisaFornecedor from './pesquisaFornecedor/reducer';
import cadastraFornecedor from './cadastraFornecedor/reducer';
import selecionado from './selecionado/reducer';

export default combineReducers({
	buscaPaginada,
	consultaFornecedor,
	pesquisaFornecedor,
	cadastraFornecedor,
	selecionado,
});
