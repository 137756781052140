import { combineReducers } from 'redux';

import cadastrarInscricao from './cadastrarInscricao/reducer';
import comprovanteInscricao from './comprovanteInscricao/reducer';
import consultarInscricao from './consultarInscricao/reducer';
import removerInscricao from './removerInscricao/reducer';

export default combineReducers({
	cadastrarInscricao,
	comprovanteInscricao,
	consultarInscricao,
	removerInscricao,
});
