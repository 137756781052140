import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaPesquisaNomeSocialFiliacaoRequest } from './types';

export function consultaPesquisaNomeSocialFiliacaoRequest(
	data: ConsultaPesquisaNomeSocialFiliacaoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_REQUEST,
		payload: data,
	};
}
export function consultaPesquisaNomeSocialFiliacaoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_SUCCESS,
		payload,
	};
}
export function consultaPesquisaNomeSocialFiliacaoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_FAILURE,
		payload,
	};
}
export function consultaPesquisaNomeSocialFiliacaoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_CLEAR,
		payload: null,
	};
}
