import { ReducerActionRotaSP } from 'store/modules/types';
import { AlterarFichaAlunoRequestData, Types } from './types';

export function alterarFichaAlunoRequest(
	token: string,
	payload: AlterarFichaAlunoRequestData,
): ReducerActionRotaSP {
	return {
		type: Types.ALTERAR_FICHA_ALUNO_REQUEST,
		token,
		payload,
	};
}

export function alterarFichaAlunoSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ALTERAR_FICHA_ALUNO_SUCCESS,
		payload,
	};
}

export function alterarFichaAlunoFailure(
	payload: boolean,
): ReducerActionRotaSP {
	return {
		type: Types.ALTERAR_FICHA_ALUNO_FAILURE,
		payload,
	};
}

export function alterarFichaAlunoClear() {
	return {
		type: Types.ALTERAR_FICHA_ALUNO_CLEAR,
		payload: null,
	};
}
