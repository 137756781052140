import { takeLatest, put, all, call } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { CHANNEL, PATH_AAC } from 'services/_path';

// STORE
import { ReducerAction } from 'store/modules/types';

// ACTIONS
import {
	encerrarAtendimentoSuccess,
	encerrarAtendimentoFailure,
} from './actions';

// TYPES
import { Types, RequestEncerrarAtendimento } from './types';

function* encerrarAtendimentoRequest(request: ReducerAction) {
	const { payload }: { payload: RequestEncerrarAtendimento } = request;

	const response: ApiResponse = yield call(
		putApi,
		PATH_AAC,
		`aac/${CHANNEL}/encerra-atendimento`,
		payload,
	);

	if (response.status === 200) {
		yield put(encerrarAtendimentoSuccess(response.data));
	} else if (response.error) {
		yield put(encerrarAtendimentoFailure());
	}
}

export default all([
	takeLatest(Types.ENCERRAR_ATENDIMENTO_REQUEST, encerrarAtendimentoRequest),
]);
