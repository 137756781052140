import styled from 'styled-components';

interface ContainerProps {
	mtSize?: number;
	mbSize?: number;
}

export const Container = styled.hr<ContainerProps>`
	margin-top: ${props => (props.mtSize ? props.mtSize : 0)}px;
	margin-bottom: ${props => (props.mbSize ? props.mbSize : 0)}px;
	border: 0.5px solid #ccc;
`;
