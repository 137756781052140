// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function consultaFormasPagamentoRequest(): ReducerAction {
	return {
		type: Types.CONSULTA_FORMAS_PAGAMENTO_REQUEST,
		payload: null,
	};
}
export function consultaFormasPagamentoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_FORMAS_PAGAMENTO_SUCCESS,
		payload,
	};
}
export function consultaFormasPagamentoFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_FORMAS_PAGAMENTO_FAILURE,
		payload,
	};
}
export function consultaFormasPagamentoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_FORMAS_PAGAMENTO_CLEAR,
		payload: null,
	};
}
