import { combineReducers } from 'redux';

import atualizarPerfil from './atualizarPerfil/reducer';
import cadastrarPerfil from './cadastrarPerfil/reducer';
import consultarPerfis from './consultarPerfis/reducer';
import excluirPerfil from './excluirPerfil/reducer';

export default combineReducers({
	atualizarPerfil,
	cadastrarPerfil,
	consultarPerfis,
	excluirPerfil,
});
