// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function estadosRequest(): ReducerAction {
	return {
		type: Types.ESTADOS_REQUEST,
		payload: null,
	};
}
export function estadosSuccess(payload: object): ReducerAction {
	return {
		type: Types.ESTADOS_SUCCESS,
		payload,
	};
}
export function estadosFailure(payload: object): ReducerAction {
	return {
		type: Types.ESTADOS_FAILURE,
		payload,
	};
}
export function estadosClear(): ReducerAction {
	return {
		type: Types.ESTADOS_CLEAR,
		payload: null,
	};
}
