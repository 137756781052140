import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_BOLSA_POVO } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarContatoRequest } from './types';

// ACTIONS
import { consultarContatoSuccess, consultarContatoFailure } from './action';

function* consultarContatoRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultarContatoRequest } = request;
	const response: ApiResponse = yield call(
		postApi,
		PATH_BOLSA_POVO,
		`bolsa-povo/${CHANNEL}/consultar-contato`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultarContatoSuccess(response));
	} else if (response.error) {
		yield put(consultarContatoFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_CONTATO_REQUEST, consultarContatoRequest),
]);
