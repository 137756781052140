import styled from 'styled-components';

export const ContainerFichaCadastral = styled.div`
	.ant-col {
		div {
		label {
			max-width: 260px;
			text-align: right;
		}
		.ant-checkbox-wrapper {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			top: 0;
			left: 6px;
			width: 205px;
		}
	}
	.ant-checkbox-wrapper {
	}
`;

export const ContainerSelect = styled.div`
	.ant-col {
		div {
			min-width: 180px;
		}
	}
`;

export const ButtonDownloadComprovante = styled.button`
	background: none;
	color: #1890ff;
	transition: color 0.3s;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`;
