// ROUTES
import { ROUTE_SEDS } from 'routes/paths';

// HOME
export const ROUTE_SEDS_RECUPERAR_ATENDIMENTO = `${ROUTE_SEDS}/recuperar-atendimento`;
export const ROUTE_SEDS_JUSTIFICATIVA_ATENDIMENTO = `${ROUTE_SEDS}/recuperar-atendimento/justificativa`;

// VIVA LEITE
export const ROUTE_SEDS_IDENTIFICAR_USUARIO = `${ROUTE_SEDS}/vivaleite/identificarUsuario`;

export const ROUTE_SEDS_ATUALIZACAO_CADASTRAL = `${ROUTE_SEDS}/vivaleite/atualizacaoCadastral`;
export const ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO = `${ROUTE_SEDS}/vivaleite/atualizacaoCadastral/resultado`;
export const ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO_ENVIO_DOCUMENTOS = `${ROUTE_SEDS}/vivaleite/atualizacaoCadastral/resultado/envioDocumentos`;
export const ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO_INCLUIR_BENEFICIARIO = `${ROUTE_SEDS}/vivaleite/atualizacaoCadastral/resultado/incluirBeneficiario`;
export const ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO_EXCLUIR_BENEFICIARIO = `${ROUTE_SEDS}/vivaleite/atualizacaoCadastral/resultado/excluirBeneficiario`;
export const ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO_IMPRIMIR_DOCUMENTO = `${ROUTE_SEDS}/vivaleite/atualizacaoCadastral/resultado/imprimirDocumento`;

export const ROUTE_SEDS_PRESTACAO_DE_CONTAS = `${ROUTE_SEDS}/vivaleite/prestacaoDeContas`;
export const ROUTE_SEDS_PRESTACAO_DE_CONTAS_TERMO_DE_ADVERTENCIA = `${ROUTE_SEDS}/vivaleite/prestacaoDeContas/termoDeAdvertencia`;
export const ROUTE_SEDS_PRESTACAO_DE_CONTAS_EXCLUIR_BENEFICIARIO = `${ROUTE_SEDS}/vivaleite/prestacaoDeContas/excluirBeneficiario`;
export const ROUTE_SEDS_PRESTACAO_DE_CONTAS_INCLUIR_BENEFICIARIO = `${ROUTE_SEDS}/vivaleite/prestacaoDeContas/incluirBeneficiario`;
export const ROUTE_SEDS_PRESTACAO_DE_CONTAS_ENVIAR_NOTAS_FISCAIS = `${ROUTE_SEDS}/vivaleite/prestacaoDeContas/enviarNotasFiscais`;
export const ROUTE_SEDS_PRESTACAO_DE_CONTAS_IMPRIMIR_DOCUMENTO = `${ROUTE_SEDS}/vivaleite/prestacaoDeContas/imprimirDocumento`;
export const ROUTE_SEDS_PRESTACAO_DE_CONTAS_ENVIO_DOCUMENTOS = `${ROUTE_SEDS}/vivaleite/prestacaoDeContas/envioDocumentos`;
