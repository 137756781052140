// PATHS
import JustificativaAtendimento from 'components/Pages/JustificativaAtendimento';
import {
	ROUTE_DETRAN_CNH_MENU_SCANNER,
	ROUTE_DETRAN_CNH_PESQUISA_EXTRATO_RESUMO_CONDUTOR,
	ROUTE_DETRAN_CNH_CONSULTA_EXTRATO_RESUMO_CONDUTOR,
	ROUTE_DETRAN_CNH_DEFINITIVA_PESQUISA,
	ROUTE_DETRAN_CNH_DEFINITIVA_AGENDAMENTO_PSICOLOGICO,
	ROUTE_DETRAN_CNH_DEFINITIVA_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_DEFINITIVA_RENACH,
	ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_PESQUISA,
	ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_RENACH,
	ROUTE_DETRAN_CNH_RECUPERAR_ATENDIMENTO,
	ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_PESQUISA,
	ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_CONFIRMACAO,
	ROUTE_DETRAN_CNH_RENOVACAO_AGENDAMENTO_MEDICO,
	ROUTE_DETRAN_CNH_RENOVACAO_AGENDAMENTO_PSICOLOGICO,
	ROUTE_DETRAN_CNH_RENOVACAO_PESQUISA,
	ROUTE_DETRAN_CNH_RENOVACAO_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_RENOVACAO_RENACH,
	ROUTE_DETRAN_CNH_SEGUNDA_VIA_AGENDAMENTO_PSICOLOGICO,
	ROUTE_DETRAN_CNH_SEGUNDA_VIA_PESQUISA,
	ROUTE_DETRAN_CNH_SEGUNDA_VIA_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_SEGUNDA_VIA_RENACH,
	ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_AGENDAMENTO_MEDICO,
	ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_AGENDAMENTO_PSICOLOGICO,
	ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_PESQUISA,
	ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_RENACH,
	ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_AGENDAMENTO_MEDICO,
	ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_AGENDAMENTO_PSICOLOGICO,
	ROUTE_DETRAN_CNH_ESTRANGEIRO_PESQUISA,
	ROUTE_DETRAN_CNH_ESTRANGEIRO_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_ESTRANGEIRO_AGENDAMENTO_MEDICO,
	ROUTE_DETRAN_CNH_ESTRANGEIRO_AGENDAMENTO_PSICOLOGICO,
	ROUTE_DETRAN_CNH_ESTRANGEIRO_RENACH,
	ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_PESQUISA,
	ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_RENACH,
	ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_PESQUISA,
	ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_STATUS_CONDUTOR,
	// ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_RENACH,
	ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_PESQUISA,
	ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_RENACH,
	ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_AGENDAMENTO_MEDICO,
	ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_AGENDAMENTO_PSICOLOGICO,
	ROUTE_DETRAN_CNH_CONSULTA_PONTUACAO_CNH_PESQUISA,
	ROUTE_DETRAN_CNH_CONSULTA_PONTUACAO_CNH_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_JUSTIFICATIVA_ATENDIMENTO,
	ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_AGENDAMENTO_MEDICO,
	ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_AGENDAMENTO_PSICOLOGICO,
	ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_PESQUISA,
	ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_RENACH,
	ROUTE_DETRAN_CNH_DIGITAL_PESQUISA,
	ROUTE_DETRAN_CNH_DIGITAL_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_DIGITAL_CADASTRO,
	ROUTE_DETRAN_CNH_ALTERAR_RENACH,
	ROUTE_DETRAN_CNH_ALTERAR_RENACH_RENACH,
	ROUTE_DETRAN_CNH_CONSULTAR_CANCElAR_PROCESSO_ABERTO,
	ROUTE_DETRAN_CNH_CANCELAR_SOLICITACAO_EMISSAO_PID_PESQUISA,
	ROUTE_DETRAN_CNH_CONSULTA_NOME_SOCIAL,
} from './paths';

// SCANNER
import MenuScanner from '../../../components/Pages/Scanner/Menu';

// Recuperar Atendimento
import RecuperarAtendimento from '../RecuperarAtendimento';

// Alteração de endereco CNH
import PesquisaAlterarEndereco from '../AlterarEndereco/Pesquisa';
import StatusCondutorAlterarEndereco from '../AlterarEndereco/StatusCondutor';
import ConfirmacaoAlterarEndereco from '../AlterarEndereco/Confirmacao';

// Renovação CNH
import ConsultaPesquisaResumoCondutor from '../ConsultaResumoExtratoCondutor/Pesquisa';
import ConsultaResumoExtratoCondutor from '../ConsultaResumoExtratoCondutor/Extrato';
import PesquisaRenovacao from '../Renovacao/Pesquisa';
import StatusCondutorRenovacao from '../Renovacao/StatusCondutor';
import AgendamentoMedicoRenovacao from '../Renovacao/AgendamentoMedico';
import AgendamentoPsicologicoRenovacao from '../Renovacao/AgendamentoPsicologico';

// Segunda Via de CNH ou de Permissão
import PesquisaSegundaVia from '../SegundaVia/Pesquisa';
import StatusCondutorSegundaVia from '../SegundaVia/StatusCondutor';
// import AgendamentoMedicoSegundaVia from '../SegundaVia/AgendamentoMedico';
import AgendamentoPsicologicoSegundaVia from '../SegundaVia/AgendamentoPsicologico';

// CNH Definitiva
import PesquisaCnhDefinitiva from '../CnhDefinitiva/Pesquisa';
import StatusCondutorCnhDefinitiva from '../CnhDefinitiva/StatusCondutor';
// import AgendamentoMedicoCnhDefinitiva from '../CnhDefinitiva/AgendamentoMedico';
import AgendamentoPsicologicoCnhDefinitiva from '../CnhDefinitiva/AgendamentoPsicologico';

// Primeira Habilitação
import PesquisaPrimeiraHabilitacao from '../PrimeiraHabilitacao/Pesquisa';
import StatusCondutorPrimeiraHabilitacao from '../PrimeiraHabilitacao/StatusCondutor';
import AgendamentoMedicoPrimeiraHabilitacao from '../PrimeiraHabilitacao/AgendamentoMedico';
import AgendamentoPsicologicoPrimeiraHabilitacao from '../PrimeiraHabilitacao/AgendamentoPsicologico';

// Permissionário Pontuado
import PesquisaPermissionarioPontuado from '../PermissionarioPontuado/Pesquisa';
import StatusCondutorPermissionarioPontuado from '../PermissionarioPontuado/StatusCondutor';
import AgendamentoMedicoPermissionarioPontuado from '../PermissionarioPontuado/AgendamentoMedico';
import AgendamentoPsicologicoPermissionarioPontuado from '../PermissionarioPontuado/AgendamentoPsicologico';

// CNH Estrangeiro
import PesquisaCnhEstrangeiro from '../CnhEstrangeiro/Pesquisa';
import AgendamentoMedicoCnhEstrangeiro from '../CnhEstrangeiro/AgendamentoMedico';
import AgendamentoPsicologicoCnhEstrangeiro from '../CnhEstrangeiro/AgendamentoPsicologico';
import StatusCondutorCnhEstrangeiro from '../CnhEstrangeiro/StatusCondutor';

// Adição/Mudança de Categoria
import PesquisaAdicaoMudancaCategoria from '../AdicaoMudancaCategoria/Pesquisa';
import StatusCondutorAdicaoMudancaCategoria from '../AdicaoMudancaCategoria/StatusCondutor';
import AgendamentoMedicoAdicaoMudancaCategoria from '../AdicaoMudancaCategoria/AgendamentoMedico';
import AgendamentoPsicologicoAdicaoMudancaCategoria from '../AdicaoMudancaCategoria/AgendamentoPsicologico';

// Permissao Internacional para dirigir - PID
import PesquisaPermissaoInternacionalAutomatizado from '../PermissaoInternacionalAutomatizado/Pesquisa';
import StatusCondutorPermissaoInternacionalAutomatizado from '../PermissaoInternacionalAutomatizado/StatusCondutor';
import RenachPermissaoInternacionalAutomatizado from '../PermissaoInternacionalAutomatizado/Renach';

// Permissao Internacional para dirigir - PID
import PesquisaPermissaoInternacional from '../PermissaoInternacional/Pesquisa';
import StatusCondutorPermissaoInternacional from '../PermissaoInternacional/StatusCondutor';
// import RenachPermissaoInternacional from '../PermissaoInternacional/Renach';

// Consulta Pontuação
import PesquisaConsultarPontuacao from '../ConsultarPontuacao/Pesquisa';
import StatusCondutorConsultaPontuacao from '../ConsultarPontuacao/StatusCondutor';

// Transferência Municipal
// import PesquisaTransferenciaMunicipal from '../TransferenciaMunicipal/Pesquisa';
// import StatusCondutorTransferenciaMunicipal from '../TransferenciaMunicipal/StatusCondutor';
// import RenachTransferenciaMunicipal from '../TransferenciaMunicipal/Renach';

// Reabilitação - Cassação/Crime
import PesquisaReabilitacaoCassacaoCrime from '../ReabilitacaoCassacaoCrime/Pesquisa';
import StatusCondutorReabilitacao from '../ReabilitacaoCassacaoCrime/StatusCondutor';

// Cnh Digital
import PesquisaCnhDigital from '../CnhDigital/Pesquisa';
import StatusCondutorCnhDigital from '../CnhDigital/StatusCondutor';
import CadastroCnhDigital from '../CnhDigital/Cadastro';
import AgendamentoMedicoReabilitacao from '../ReabilitacaoCassacaoCrime/AgendamentoMedico';

import AgendamentoPsicologicoReabilitacao from '../ReabilitacaoCassacaoCrime/AgendamentoPsicologico';

// Alterar Renach
import { AlterarRenachGerado } from '../AlterarRenachGerado/StatusCondutor';
import RenachAlterarRenach from '../AlterarRenachGerado/Renach';

// Processo Aberto
import ProcessoAberto from '../ConsultarCancelarProcessoAberto/pesquisa';

// Common component
import RenachIntegradoGerado from '../components/pages/RenachIntegradoGerado';

// Cancelar Solicitação Emissão PID
import CancelarSolicitacaoEmissaoPIDPesquisa from '../CancelarSolicitacaoEmissaoPID/Pesquisa';

// Consulta Nome Social
import ConsultaNomeSocial from '../ConsultaNomeSocial/Pesquisa';

export const detranCnhRoute = {
	name: 'DETRAN',
	route: '/detran-cnh',
};

const detranCnhRoutes = [
	{
		path: ROUTE_DETRAN_CNH_MENU_SCANNER,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Menu Scanner',
			},
		],
		Component: MenuScanner,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_RECUPERAR_ATENDIMENTO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Recuperar atendimento',
			},
		],
		Component: RecuperarAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_JUSTIFICATIVA_ATENDIMENTO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Justificativa atendimento',
			},
		],
		Component: JustificativaAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PESQUISA_EXTRATO_RESUMO_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Consulta Resumo - Extrato',
			},
		],
		Component: ConsultaPesquisaResumoCondutor,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_CONSULTA_EXTRATO_RESUMO_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Consulta Resumo - Extrato',
			},
		],
		Component: ConsultaResumoExtratoCondutor,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Solicitar Alteração de Endereço',
			},
		],
		Component: PesquisaAlterarEndereco,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Solicitar Alteração de Endereço',
			},
		],
		Component: StatusCondutorAlterarEndereco,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_CONFIRMACAO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Solicitar Alteração de Endereço',
			},
		],
		Component: ConfirmacaoAlterarEndereco,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_RENOVACAO_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Renovação de CNH',
			},
		],
		Component: PesquisaRenovacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_RENOVACAO_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Renovação de CNH',
			},
		],
		Component: StatusCondutorRenovacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_RENOVACAO_AGENDAMENTO_MEDICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Renovação de CNH',
			},
		],
		Component: AgendamentoMedicoRenovacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_RENOVACAO_AGENDAMENTO_PSICOLOGICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Renovação de CNH',
			},
		],
		Component: AgendamentoPsicologicoRenovacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_RENOVACAO_RENACH,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Renovação de CNH',
			},
		],
		Component: RenachIntegradoGerado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_SEGUNDA_VIA_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Segunda Via de CNH ou de Permissão',
			},
		],
		Component: PesquisaSegundaVia,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_SEGUNDA_VIA_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Segunda Via de CNH ou de Permissão',
			},
		],
		Component: StatusCondutorSegundaVia,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_SEGUNDA_VIA_RENACH,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Segunda Via de CNH ou de Permissão',
			},
		],
		Component: RenachIntegradoGerado,
		isAuthenticated: true,
		exact: true,
	},
	// {
	// 	path: ROUTE_DETRAN_CNH_SEGUNDA_VIA_AGENDAMENTO_MEDICO,
	// 	breadcrumb: [
	// 		detranCnhRoute,
	// 		{
	// 			name: 'Segunda Via de CNH ou de Permissão',
	// 		},
	// 	],
	// 	Component: AgendamentoMedicoSegundaVia,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	{
		path: ROUTE_DETRAN_CNH_SEGUNDA_VIA_AGENDAMENTO_PSICOLOGICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Segunda Via de CNH ou de Permissão',
			},
		],
		Component: AgendamentoPsicologicoSegundaVia,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_DEFINITIVA_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'CNH Definitiva',
			},
		],
		Component: PesquisaCnhDefinitiva,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_DEFINITIVA_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'CNH Definitiva',
			},
		],
		Component: StatusCondutorCnhDefinitiva,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_DEFINITIVA_RENACH,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'CNH Definitiva',
			},
		],
		Component: RenachIntegradoGerado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_DEFINITIVA_AGENDAMENTO_PSICOLOGICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'CNH Definitiva',
			},
		],
		Component: AgendamentoPsicologicoCnhDefinitiva,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Primeira Habilitação',
			},
		],
		Component: PesquisaPrimeiraHabilitacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Primeira Habilitação',
			},
		],
		Component: StatusCondutorPrimeiraHabilitacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_RENACH,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Primeira Habilitação',
			},
		],
		Component: RenachIntegradoGerado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_AGENDAMENTO_MEDICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Primeira Habilitação',
			},
		],
		Component: AgendamentoMedicoPrimeiraHabilitacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_AGENDAMENTO_PSICOLOGICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Primeira Habilitação',
			},
		],
		Component: AgendamentoPsicologicoPrimeiraHabilitacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Nova Habilitação - Permissionário Pontuado',
			},
		],
		Component: PesquisaPermissionarioPontuado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Nova Habilitação - Permissionário Pontuado',
			},
		],
		Component: StatusCondutorPermissionarioPontuado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_RENACH,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Nova Habilitação - Permissionário Pontuado',
			},
		],
		Component: RenachIntegradoGerado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_AGENDAMENTO_MEDICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Nova Habilitação - Permissionário Pontuado',
			},
		],
		Component: AgendamentoMedicoPermissionarioPontuado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_AGENDAMENTO_PSICOLOGICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Nova Habilitação - Permissionário Pontuado',
			},
		],
		Component: AgendamentoPsicologicoPermissionarioPontuado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ESTRANGEIRO_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Registro de Habilitação Estrangeira',
			},
		],
		Component: PesquisaCnhEstrangeiro,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ESTRANGEIRO_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Registro de Habilitação Estrangeira',
			},
		],
		Component: StatusCondutorCnhEstrangeiro,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ESTRANGEIRO_AGENDAMENTO_MEDICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Registro de Habilitação Estrangeira',
			},
		],
		Component: AgendamentoMedicoCnhEstrangeiro,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ESTRANGEIRO_AGENDAMENTO_PSICOLOGICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Registro de Habilitação Estrangeira',
			},
		],
		Component: AgendamentoPsicologicoCnhEstrangeiro,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ESTRANGEIRO_RENACH,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Registro de Habilitação Estrangeira',
			},
		],
		Component: RenachIntegradoGerado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Permissão Internacional do Direito de Dirigir - PID',
			},
		],
		Component: PesquisaPermissaoInternacionalAutomatizado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Permissão Internacional do Direito de Dirigir - PID',
			},
		],
		Component: StatusCondutorPermissaoInternacionalAutomatizado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_RENACH,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Permissão Internacional do Direito de Dirigir - PID',
			},
		],
		Component: RenachPermissaoInternacionalAutomatizado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Permissão Internacional do Direito de Dirigir - PID',
			},
		],
		Component: PesquisaPermissaoInternacional,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Permissão Internacional do Direito de Dirigir - PID',
			},
		],
		Component: StatusCondutorPermissaoInternacional,
		isAuthenticated: true,
		exact: true,
	},
	// {
	// 	path: ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_RENACH,
	// 	breadcrumb: [
	// 		detranCnhRoute,
	// 		{
	// 			name: 'Permissão Internacional do Direito de Dirigir - PID',
	// 		},
	// 	],
	// 	Component: RenachPermissaoInternacional,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	{
		path: ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Reabilitação Cassação/Crime',
			},
		],
		Component: PesquisaReabilitacaoCassacaoCrime,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Reabilitação - Cassação / Crime',
			},
		],
		Component: StatusCondutorReabilitacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_RENACH,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Reabilitação - Cassação / Crime',
			},
		],
		Component: RenachIntegradoGerado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_AGENDAMENTO_MEDICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Reabilitação - Cassação / Crime',
			},
		],
		Component: AgendamentoMedicoReabilitacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_AGENDAMENTO_PSICOLOGICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Reabilitação - Cassação / Crime',
			},
		],
		Component: AgendamentoPsicologicoReabilitacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_CONSULTA_PONTUACAO_CNH_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Consulta Pontuação',
			},
		],
		Component: PesquisaConsultarPontuacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_CONSULTA_PONTUACAO_CNH_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Consulta Pontuação',
			},
		],
		Component: StatusCondutorConsultaPontuacao,
		isAuthenticated: true,
		exact: true,
	},
	// {
	// 	path: ROUTE_DETRAN_CNH_TRANSFERENCIA_MUNICIPAL_PESQUISA,
	// 	breadcrumb: [
	// 		detranCnhRoute,
	// 		{
	// 			name: 'Transferência Municipal',
	// 		},
	// 	],
	// 	Component: PesquisaTransferenciaMunicipal,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	// {
	// 	path: ROUTE_DETRAN_CNH_TRANSFERENCIA_MUNICIPAL_STATUS_CONDUTOR,
	// 	breadcrumb: [
	// 		detranCnhRoute,
	// 		{
	// 			name: 'Transferência Municipal',
	// 		},
	// 	],
	// 	Component: StatusCondutorTransferenciaMunicipal,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	// {
	// 	path: ROUTE_DETRAN_CNH_TRANSFERENCIA_MUNICIPAL_RENACH,
	// 	breadcrumb: [
	// 		detranCnhRoute,
	// 		{
	// 			name: 'Transferência Municipal',
	// 		},
	// 	],
	// 	Component: RenachTransferenciaMunicipal,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	{
		path: ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Adição/Mudança de Categoria',
			},
		],
		Component: PesquisaAdicaoMudancaCategoria,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Adição/Mudança de Categoria',
			},
		],
		Component: StatusCondutorAdicaoMudancaCategoria,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_AGENDAMENTO_MEDICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Adição/Mudança de Categoria',
			},
		],
		Component: AgendamentoMedicoAdicaoMudancaCategoria,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_AGENDAMENTO_PSICOLOGICO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Adição/Mudança de Categoria',
			},
		],
		Component: AgendamentoPsicologicoAdicaoMudancaCategoria,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_RENACH,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Adição/Mudança de Categoria',
			},
		],
		Component: RenachIntegradoGerado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_DIGITAL_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'CNH Digital',
			},
		],
		Component: PesquisaCnhDigital,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_DIGITAL_STATUS_CONDUTOR,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'CNH Digital',
			},
		],
		Component: StatusCondutorCnhDigital,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_DIGITAL_CADASTRO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'CNH Digital',
			},
		],
		Component: CadastroCnhDigital,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ALTERAR_RENACH,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Alterar RENACH',
			},
		],
		Component: AlterarRenachGerado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_ALTERAR_RENACH_RENACH,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Alterar RENACH',
			},
		],
		Component: RenachAlterarRenach,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_CANCELAR_SOLICITACAO_EMISSAO_PID_PESQUISA,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Cancelar Solicitação Emissão PID',
			},
		],
		Component: CancelarSolicitacaoEmissaoPIDPesquisa,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_CONSULTA_NOME_SOCIAL,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Consulta Nome Social',
			},
		],
		Component: ConsultaNomeSocial,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH_CONSULTAR_CANCElAR_PROCESSO_ABERTO,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Consultar / Cancelar Proceso Aberto',
			},
		],
		Component: ProcessoAberto,
		isAuthenticated: true,
		exact: true,
	},
];

export default detranCnhRoutes;
