import produce from 'immer';

// TYPES
import { Types, ConsultaPontos } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultaPontos = {
	status: 0,
	data: null,
};

export default function modulo(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaPontos {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PONTOS_REQUEST: {
				break;
			}
			case Types.CONSULTA_PONTOS_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTA_PONTOS_FAILURE: {
				draft.status = 400;
				draft.data = action.payload;
				break;
			}
			case Types.CONSULTA_PONTOS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
