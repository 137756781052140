import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, deleteApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { ExcluirNaturalidadeRequest, Types } from './types';
import {
	excluirNaturalidadeSuccess,
	excluirNaturalidadeFailure,
} from './actions';

function* excluirNaturalidadeRequest(request: ReducerAction) {
	const { payload }: { payload: ExcluirNaturalidadeRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_SGU,
		`naturalidades/${payload.codigo}`,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(excluirNaturalidadeSuccess(response));
	} else {
		yield put(excluirNaturalidadeFailure());
	}
}

export default all([
	takeLatest(Types.EXCLUIR_NATURALIDADE, excluirNaturalidadeRequest),
]);
