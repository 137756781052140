import React from 'react';

import { useHistory } from 'react-router-dom';

// COMPONENTS
import DateTimeClock from 'components/Common/DateTimeClock';

// STYLED
import { Container, Content, Message } from './styled';

const Navbar = () => {
	const history = useHistory();

	return (
		<>
			<Container className="no-print">
				<Content>
					{history.location.pathname === '/' && (
						<>
							<Message>Bem-vindo ao sistema integrado Poupatempo!</Message>

							<DateTimeClock />
						</>
					)}
				</Content>
			</Container>
		</>
	);
};

export default Navbar;
