import { store } from '../../../index';

// TYLES
import { Types } from './types';

export function setAuthToken(payload: any) {
	store.dispatch({
		type: Types.SET_AUTH_TOKEN,
		payload,
	});
}

export function setTimeAuthToken() {
	store.dispatch({
		type: Types.SET_TIME_AUTH_TOKEN,
	});
}

export function clearAuthToken() {
	store.dispatch({
		type: Types.CLEAR_AUTH_TOKEN,
	});
}
