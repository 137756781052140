import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { toSelect } from 'utils/genericFunctions';
import { Types, ConsultarTipoContato } from './types';

export const INITIAL_STATE: ConsultarTipoContato = {
	status: 0,
	data: [],
};

export default function alteraEnderecoCartao(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarTipoContato {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_TIPO_CONTRATO_REQUEST: {
				break;
			}

			case Types.CONSULTAR_TIPO_CONTRATO_SUCCESS: {
				draft.status = 200;
				const select = toSelect(action.payload.data.tipos, 'descricao', 'id');
				draft.data = select;
				break;
			}

			case Types.CONSULTAR_TIPO_CONTRATO_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.LIMPAR_CONSULTAR_TIPO_CONTRATO: {
				draft.status = 0;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
