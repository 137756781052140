export enum Types {
	EVENTO_TERMINO_SESSAO_REQUEST = '@cnh/EVENTO_TERMINO_REQUEST',
	EVENTO_TERMINO_SESSAO_SUCCESS = '@cnh/EVENTO_TERMINO_SUCCESS',
	EVENTO_TERMINO_SESSAO_FAILURE = '@cnh/EVENTO_TERMINO_FAILURE',
	EVENTO_TERMINO_SESSAO_CLEAR = '@cnh/EVENTO_TERMINO_CLEAR',
}

export interface EventoTerminoSessao {
	status: number;
	data: null;
}

export interface EventoTerminoSessaoRequest {
	id_atendimento: string;
	id_cidadao: string;
	canal: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
	atendente: {
		nome: string;
		id_atendente: string;
		id_posto: string;
		desc_posto: string;
	};
	servico: {
		tipoServico: string;
		data_inicio: string;
		data_termino: string;
	};
}
