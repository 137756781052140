import produce from 'immer';
import { Types, ServicosState } from './types';
import { ReducerAction } from '../../../types';

export const INITIAL_STATE: ServicosState = {
	servicosData: [],
	parametrosServicosData: [],
	nextStep: false,
	codigoRedirect: '',
	redirect: false,
	situation: '',
};

export default function servicos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ServicosState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_SERVICOS: {
				break;
			}
			case Types.CONSULTA_SERVICOS_SUCCESS: {
				draft.servicosData = action.payload.data;
				break;
			}
			case Types.CONSULTA_SERVICOS_FAILURE: {
				draft.servicosData = [];
				break;
			}
			case Types.CADASTRA_SERVICOS: {
				break;
			}
			case Types.CADASTRA_SERVICOS_SUCCESS: {
				draft.nextStep = true;
				draft.redirect = true;
				draft.situation = 'Cadastrado';
				draft.codigoRedirect = action.payload.data.codigo;
				break;
			}
			case Types.CADASTRA_SERVICOS_FAILURE: {
				break;
			}
			case Types.ALTERA_SERVICOS: {
				break;
			}
			case Types.ALTERA_SERVICOS_SUCCESS: {
				draft.nextStep = true;
				draft.redirect = true;
				draft.situation = 'Alterado';
				break;
			}
			case Types.ALTERA_SERVICOS_FAILURE: {
				break;
			}
			case Types.REMOVE_SERVICOS: {
				break;
			}
			case Types.REMOVE_SERVICOS_SUCCESS: {
				const updatedParametros = draft.servicosData.filter(
					item => item.codigo !== action.payload,
				);
				draft.servicosData = updatedParametros;
				draft.redirect = true;
				draft.situation = 'Removido';
				break;
			}
			case Types.REMOVE_SERVICOS_FAILURE: {
				break;
			}

			case Types.CONSULTA_PARAMETROS_SERVICOS: {
				break;
			}
			case Types.CONSULTA_PARAMETROS_SERVICOS_SUCCESS: {
				draft.parametrosServicosData = action.payload.data;
				break;
			}
			case Types.CONSULTA_PARAMETROS_SERVICOS_FAILURE: {
				draft.parametrosServicosData = [];
				break;
			}
			case Types.ALTERA_PARAMETROS_SERVICOS: {
				break;
			}
			case Types.ALTERA_PARAMETROS_SERVICOS_SUCCESS: {
				draft.parametrosServicosData = action.payload.body;
				draft.redirect = true;
				draft.situation = action.payload.situation;
				break;
			}
			case Types.ALTERA_PARAMETROS_SERVICOS_FAILURE: {
				break;
			}

			case Types.REDIRECT_SERVICOS_CLEAR: {
				draft.redirect = false;
				draft.codigoRedirect = '';
				break;
			}

			case Types.NEXT_STEP_SERVICOS_CLEAR: {
				draft.nextStep = false;
				break;
			}
			default:
		}
	});
}
