export enum Types {
	EMISSAO_ATESTADO_ANTECEDENTES_REQUEST = '@aac/EMISSAO_ATESTADO_ANTECEDENTES_REQUEST',
	EMISSAO_ATESTADO_ANTECEDENTES_SUCCESS = '@aac/EMISSAO_ATESTADO_ANTECEDENTES_SUCCESS',
	EMISSAO_ATESTADO_ANTECEDENTES_FAILURE = '@aac/EMISSAO_ATESTADO_ANTECEDENTES_FAILURE',
	LIMPA_ATESTADO_ANTECEDENTES = '@aac/LIMPA_ATESTADO_ANTECEDENTES',
}

export interface EmissaoAtestadoAntecedentes {
	status: number;
	data: DataAtestadoAntecedentes;
	pesquisa: RequestEmissaoAtestadoAntecedentes;
}

export interface DataAtestadoAntecedentes {
	pdfBase64?: string;
	nomeSocial?: string;
	codigo?: string;
	mensagem?: string;
}

export interface RequestEmissaoAtestadoAntecedentes {
	dataExpedicaoRg: string;
	dataNascimento: string | null;
	dataNascimentoTs?: string;
	numeroRg: number | string;
	digitoRg: string;
	nomeSocial?: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	sexo: string;
	tipoDocApresentado: string;
	nomeProcurador: string;
	docProcurador: string;
	numeroDocumento: string;
	ipEstacao: string;
	posto: string;
	usuario: string;
	tipoEmissao: 'NUMERAL' | 'NOMINAL' | string;
	origem: string;

	cpf?: string;
}
