import { Types } from './types';

export function changeUrlRequest() {
	return {
		type: Types.CHANGE_URL_REQUEST,
	};
}
export function changeUrlClear() {
	return {
		type: Types.CHANGE_URL_CLEAR,
	};
}
