import React, { useCallback } from 'react';

import { Col, Row } from 'antd';
import { Field, Form, Formik } from 'formik';

// REDUX
import { useDispatch } from 'react-redux';
import { consultarDareRetaguardaRequest } from 'store/modules/api/dare/consultarDareRetaguarda/actions';

// COMPONENTS
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { limparMascara } from 'utils/genericFunctions';

// FORM
import { IFormDareRetaguarda, initialValues, schema } from './form';

export const Formulario: React.FC = () => {
	const dispatch = useDispatch();

	const handleValidate = useCallback(
		async (formValues: IFormDareRetaguarda) => {
			const values = {
				cpf: limparMascara(formValues.cpf),
				dare: limparMascara(formValues.dare),
			};

			return getValidationsErrors(values, schema);
		},
		[],
	);

	const handleSubmit = useCallback(
		(formValues: IFormDareRetaguarda) => {
			if (formValues.cpf) {
				dispatch(
					consultarDareRetaguardaRequest({
						cpf: limparMascara(formValues.cpf),
					}),
				);
				return;
			}

			dispatch(consultarDareRetaguardaRequest({ dare: formValues.dare }));
		},
		[dispatch],
	);

	return (
		<Row>
			<Col span={24}>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h2>Informe um dos campos abaixo:</h2>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={handleValidate}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row gutter={[0, 10]}>
										<Col span={14}>
											<Field
												as={InputMask}
												title="CPF"
												name="cpf"
												mask="999.999.999-99"
												required={
													!limparMascara(formik.values.dare) &&
													limparMascara(formik.values.cpf)
												}
												error={
													hasError(formik.errors, 'cpfDare') &&
													((limparMascara(formik.values.cpf) === '' &&
														limparMascara(formik.values.dare) === '') ||
														(limparMascara(formik.values.cpf) !== '' &&
															limparMascara(formik.values.dare) !== '') ||
														(limparMascara(formik.values.cpf) !== '' &&
															limparMascara(formik.values.cpf).length !== 11 &&
															limparMascara(formik.values.dare) === '')) &&
													!(
														limparMascara(formik.values.cpf) === '' &&
														limparMascara(formik.values.dare) !== '' &&
														limparMascara(formik.values.dare).length !== 15
													)
												}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={14}>
											<Field
												as={InputMask}
												title="Nº DARE"
												name="dare"
												mask="999999999999999"
												required={
													!limparMascara(formik.values.cpf) &&
													limparMascara(formik.values.dare)
												}
												error={
													hasError(formik.errors, 'cpfDare') &&
													((limparMascara(formik.values.cpf) === '' &&
														limparMascara(formik.values.dare) === '') ||
														(limparMascara(formik.values.cpf) !== '' &&
															limparMascara(formik.values.dare) !== '') ||
														(limparMascara(formik.values.cpf) === '' &&
															limparMascara(formik.values.dare) !== '' &&
															limparMascara(formik.values.dare).length !==
																15)) &&
													!(
														limparMascara(formik.values.cpf) !== '' &&
														limparMascara(formik.values.cpf).length !== 11 &&
														limparMascara(formik.values.dare) === ''
													)
												}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]} justify="center">
										<Col span={10}>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
