import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_RFB } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import { consultaCpfSuccess, consultaCpfFailure } from './actions';

function* consultaCpfRequest(request: ReducerAction) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_RFB,
		`receita-federal/${CHANNEL}/emissao/dados-emissao-cin`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaCpfSuccess(response.data));
	} else if (response.error) {
		yield put(consultaCpfFailure());
	}
}

export default all([
	takeLatest(Types.DADOS_EMISSAO_CIN_REQUEST, consultaCpfRequest),
]);
