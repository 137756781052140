import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	REGIAO_MICRO_REGIAO_POR_CEP = '@cnh/REGIAO_MICRO_REGIAO_POR_CEP',
	REGIAO_MICRO_REGIAO_POR_CEP_SUCCESS = '@cnh/REGIAO_MICRO_REGIAO_POR_CEP_SUCCESS',
	REGIAO_MICRO_REGIAO_POR_CEP_FAILURE = '@cnh/REGIAO_MICRO_REGIAO_POR_CEP_FAILURE',
	REGIAO_MICRO_REGIAO_POR_CEP_CLEAR = '@cnh/REGIAO_MICRO_REGIAO_POR_CEP_CLEAR',
}

export interface RegiaoMicroRegiaoPorCep {
	status: number;
	data: RegiaoMicroRegiaoPorCepData;
}

interface RegiaoMicroRegiaoPorCepData {
	micro_regiao: OptionProps[];
	regiao: OptionProps[];
}

export interface RegiaoMicroRegiaoPorCepRequest {
	cpf_cidadao: number | string;
	tipo_exame: string;
	is_portador_necessidades_especiais: boolean;
	cep_cidadao: number | string;
	usuario: string;
}
