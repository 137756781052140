// PATHS
import {
	ROUTE_DETRAN_CRV_CANCELAR_INTENCAO_VENDA_CONSULTA,
	ROUTE_DETRAN_CRV_CANCELAR_INTENCAO_VENDA_RESULTADO,
	ROUTE_DETRAN_CRV_CONSULTAR_INTENCAO_VENDA_CONSULTA,
	ROUTE_DETRAN_CRV_CONSULTAR_INTENCAO_VENDA_RESULTADO,
	ROUTE_DETRAN_CRV_IMPRIMIR_INTENCAO_VENDA_CONSULTA,
	ROUTE_DETRAN_CRV_IMPRIMIR_INTENCAO_VENDA_RESULTADO,
	ROUTE_DETRAN_CRV_INCLUIR_INTENCAO_VENDA_CONSULTA,
	ROUTE_DETRAN_CRV_INCLUIR_INTENCAO_VENDA_RESULTADO,
} from '../routes/paths';
import CancelarIntencaoVenda from './Cancelar/Consulta';
import CancelarIntencaoVendaResultado from './Cancelar/Resultado';
import ConsultarIntencaoVenda from './Consultar/Consulta';
import ConsultarIntencaoVendaResultado from './Consultar/Resultado';
import ImprimirIntencaoVenda from './Imprimir/Consulta';
import ImprimirIntencaoVendaResultado from './Imprimir/Resultado';
import IncluirIntencaoVenda from './Incluir/Emissao';
import IncluirIntencaoVendaResultado from './Incluir/Resultado';

export const detranCrvRoute = {
	name: 'DETRAN CRV',
	route: '/detran-crv',
};

export default [
	{
		path: ROUTE_DETRAN_CRV_CONSULTAR_INTENCAO_VENDA_CONSULTA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Intenção de Venda',
			},
		],
		Component: ConsultarIntencaoVenda,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CONSULTAR_INTENCAO_VENDA_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Intenção de Venda',
			},
		],
		Component: ConsultarIntencaoVendaResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_INCLUIR_INTENCAO_VENDA_CONSULTA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Incluir Intenção de Venda',
			},
		],
		Component: IncluirIntencaoVenda,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_INCLUIR_INTENCAO_VENDA_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Incluir Intenção de Venda',
			},
		],
		Component: IncluirIntencaoVendaResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CANCELAR_INTENCAO_VENDA_CONSULTA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Cancelar Intenção de Venda',
			},
		],
		Component: CancelarIntencaoVenda,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CANCELAR_INTENCAO_VENDA_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Cancelar Intenção de Venda',
			},
		],
		Component: CancelarIntencaoVendaResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_IMPRIMIR_INTENCAO_VENDA_CONSULTA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Imprimir ATPV-e',
			},
		],
		Component: ImprimirIntencaoVenda,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_IMPRIMIR_INTENCAO_VENDA_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Imprimir ATPV-e',
			},
		],
		Component: ImprimirIntencaoVendaResultado,
		isAuthenticated: true,
		exact: true,
	},
];
