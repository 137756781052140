import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, ExcluirMunicipioIbge } from './types';

export const INITIAL_STATE: ExcluirMunicipioIbge = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ExcluirMunicipioIbge {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EXCLUIR_MUNICIPIO_IBGE_REQUEST: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.EXCLUIR_MUNICIPIO_IBGE_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.EXCLUIR_MUNICIPIO_IBGE_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.EXCLUIR_MUNICIPIO_IBGE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
