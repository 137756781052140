import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaCondutorRequest } from './types';

export function consultaCondutorRequest(
	data: ConsultaCondutorRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_CONDUTOR_REQUEST,
		payload: data,
	};
}
export function consultaCondutorSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_CONDUTOR_SUCCESS,
		payload,
	};
}
export function consultaCondutorFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_CONDUTOR_FAILURE,
		payload,
	};
}
export function consultaCondutorClear(): ReducerAction {
	return {
		type: Types.CONSULTA_CONDUTOR_CLEAR,
		payload: null,
	};
}
