import { GetCredentials } from './getCredentials/types';
import { GetECpfValidation } from './getECpfValidation/types';
import { GetToken } from './getToken/types';
import { CertificateValidate } from './certificateValidate/types';
import { ChangeUrl } from './changeUrl/types';

export enum Types {
	E_CPF_CLEAR = '@E-CPF/E_CPF_CLEAR',
}

export interface ECpfState {
	getToken: GetToken;
	getECpfValidation: GetECpfValidation;
	getCredentials: GetCredentials;
	certificateValidate: CertificateValidate;
	changeUrl: ChangeUrl;
}
