export const getMensagemAlerta = (codigo: string) => {
	let mensangem = '';

	switch (codigo) {
		case 'CO6':
			mensangem =
				'O cidadão poderá solicitar o Atestado mediante apresentação de outros documentos como: CNH, Passaporte, RNE, CRNM, Protocolo RNE, Funcional, Carteira de Trabalho e Outros.';
			break;
		case 'C13':
			mensangem =
				'O cidadão deverá fazer o BO de Encontro antes de solicitar o Atestado de Antecedentes.';
			break;

		default:
			break;
	}

	return mensangem;
};

export const getCodigosMostrarEmTela = (codigo: string) => {
	if (!codigo) {
		return false;
	}
	if (codigo.includes('CO6')) {
		return true;
	}
	if (codigo.includes('C13')) {
		return true;
	}
	return false;
};
