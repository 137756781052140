import * as Yup from 'yup';

interface IBuscarMunicipioPais {
	pesquisa: string;
}

export const initialValues: IBuscarMunicipioPais = {
	pesquisa: '',
};

export interface ICfg {
	titleModal: string;
	formLabel: string;
	tableHeader: string;
}

export const cfgMunicipio: ICfg = {
	titleModal: 'Buscar Município',
	formLabel: 'município',
	tableHeader: 'Nome do Município',
};

export const cfgNaturalidade: ICfg = {
	titleModal: 'Buscar País',
	formLabel: 'país',
	tableHeader: 'Nome do País',
};

const schema = Yup.object<IBuscarMunicipioPais>().shape({
	pesquisa: Yup.string().required('Campo Obrigatório: Município.'),
});

export default async function getValidationsErrors(
	values: IBuscarMunicipioPais,
) {
	try {
		await schema.validate(values, {
			abortEarly: false,
		});

		return true;
	} catch (err) {
		let errors: any = {};

		err.inner.forEach((error: any) => {
			errors = { ...errors, [error.path]: error.message };
		});

		return errors;
	}
}
