import { ReducerAction } from 'store/modules/types';
import {
	Types,
	IRequestAlteraEndereco,
	IRequestAlteraEnderecoNovo,
} from './types';

export function alteraEnderecoRequest(
	payload: IRequestAlteraEndereco | IRequestAlteraEnderecoNovo,
) {
	return {
		type: Types.ALTERA_ENDERECO,
		payload,
	};
}
export function alteraEnderecoSuccess(payload: object): ReducerAction {
	return {
		type: Types.ALTERA_ENDERECO_SUCCESS,
		payload,
	};
}
export function alteraEnderecoFailure(): ReducerAction {
	return {
		type: Types.ALTERA_ENDERECO_FAILURE,
		payload: null,
	};
}

export function limparAlteraEndereco(): any {
	return {
		type: Types.LIMPAR_ALTERA_ENDERECO,
	};
}
