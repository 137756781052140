import React, { useCallback } from 'react';
import MP_GENITOR from 'assets/images/mp/novo-cadastro/MP_GENITOR.png';
import MP_INTERESSADO from 'assets/images/mp/novo-cadastro/MP_INTERESSADO.png';
import MP_RESPONSAVEL from 'assets/images/mp/novo-cadastro/MP_RESPONSAVEL.png';
import { Col, Row } from 'antd';
import { FormikProps } from 'formik';
import { Option } from './styled';
import { ICadastroSolicitante } from '../Forms/form';

interface Props {
	solicitante: (option: number) => void;
	formik: FormikProps<ICadastroSolicitante>;
}

const Selections: React.FC<Props> = ({ solicitante, formik }) => {
	const handleClick = useCallback(
		(id: number) => {
			formik.setFieldValue('tipoSolicitante', id);
		},
		[formik],
	);

	return (
		<Col>
			<h2 style={{ margin: '20px 0', borderBottom: '1px solid black' }}>
				Quem preencheu a Solicitação ?{' '}
			</h2>

			<Row justify="center">
				<Col span={8}>
					<Option
						onClick={() => {
							solicitante(1);
							handleClick(1);
						}}
					>
						<img src={MP_INTERESSADO} alt="" />
						<h2>Filho ou Filha</h2>
						<span>(Maior de 18 anos)</span>
					</Option>
				</Col>
				<Col span={8}>
					<Option
						onClick={() => {
							solicitante(3);
							handleClick(3);
						}}
					>
						<img src={MP_GENITOR} alt="" />
						<h2>Genitor</h2>
						<span>(Pai ou Mãe que irá fazer o reconhecimento)</span>
					</Option>
				</Col>
				<Col span={8}>
					<Option
						onClick={() => {
							solicitante(2);
							handleClick(2);
						}}
					>
						<img src={MP_RESPONSAVEL} alt="" />
						<h2>Responsável Legal</h2>
						<span>
							(Tutor, Pai, Mãe ou Responsável que possui a guarda do(a)
							Filho(a))
						</span>
					</Option>
				</Col>
			</Row>
		</Col>
	);
};

export default Selections;
