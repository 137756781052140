import React, { useCallback } from 'react';

import { FaArrowLeft } from 'react-icons/fa';

import { useHistory } from 'react-router-dom';

// STYLED
import { Container, Icon, Text } from './styled';

interface Props {
	navigate?: boolean;
	route?: string;
	onClick?: () => void;
}

const ButtonVoltar: React.FC<Props> = ({
	navigate = true,
	route = '',
	onClick,
}) => {
	const history = useHistory();

	const handleClick = useCallback(() => {
		if (onClick) onClick();

		if (!navigate) return;

		if (route !== '') {
			history.push(route);
		} else {
			history.goBack();
		}
	}, [onClick, navigate, route, history]);

	return (
		<Container type="button" className="no-print" onClick={handleClick}>
			<Icon>
				<FaArrowLeft />
			</Icon>

			<Text>Voltar</Text>
		</Container>
	);
};

export default ButtonVoltar;
