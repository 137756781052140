import { ReducerAction } from 'store/modules/types';

import { Types, ResetarSenhaUsuarioMainframeRequest } from './types';

export function resetarSenhaUsuarioMainframeRequest(
	data: ResetarSenhaUsuarioMainframeRequest,
): ReducerAction {
	return {
		type: Types.RESETAR_SENHA_USUARIO_MAINFRAME_REQUEST,
		payload: data,
	};
}
export function resetarSenhaUsuarioMainframeSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.RESETAR_SENHA_USUARIO_MAINFRAME_SUCCESS,
		payload,
	};
}
export function resetarSenhaUsuarioMainframeFailure(): ReducerAction {
	return {
		type: Types.RESETAR_SENHA_USUARIO_MAINFRAME_FAILURE,
		payload: null,
	};
}
export function resetarSenhaUsuarioMainframeClear(): ReducerAction {
	return {
		type: Types.RESETAR_SENHA_USUARIO_MAINFRAME_CLEAR,
		payload: null,
	};
}
