// TYPES
import { ReducerAction } from 'store/modules/types';
import { DadosEmissaoCIN, DadosEmissaoCINRequest, Types } from './types';

export function consultaCpfRequest(
	payload: DadosEmissaoCINRequest,
): ReducerAction {
	return {
		type: Types.DADOS_EMISSAO_CIN_REQUEST,
		payload,
	};
}

export function consultaCpfSuccess(payload: DadosEmissaoCIN): ReducerAction {
	return {
		type: Types.DADOS_EMISSAO_CIN_SUCCESS,
		payload,
	};
}

export function consultaCpfFailure(): ReducerAction {
	return {
		type: Types.DADOS_EMISSAO_CIN_FAILURE,
		payload: null,
	};
}

export function consultaCpfClear(): ReducerAction {
	return {
		type: Types.DADOS_EMISSAO_CIN_CLEAR,
		payload: null,
	};
}
