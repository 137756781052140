export enum Types {
	CONSULTA_CNH_DEFINITIVA_REQUEST = '@cnh/CONSULTA_CNH_DEFINITIVA_REQUEST',
	CONSULTA_CNH_DEFINITIVA_SUCCESS = '@cnh/CONSULTA_CNH_DEFINITIVA_SUCCESS',
	CONSULTA_CNH_DEFINITIVA_FAILURE = '@cnh/CONSULTA_CNH_DEFINITIVA_FAILURE',
	CONSULTA_CNH_DEFINITIVA_CLEAR = '@cnh/CONSULTA_CNH_DEFINITIVA_CLEAR',
}

export interface consultaCnhDefinitiva {
	status: number;
	data: IResponseConsultaCnhDefinitiva | null;
	transacao?: string;
	codigo?: number;
	mensagens?: string[];
	dataError: ConsultaCnhDefinitivaDataError | null;
	requestData: IRequestConsultaCnhDefinitiva | null;
}

export interface IResponseConsultaCnhDefinitiva {
	cpf: string;
	numeroRg: string;
	numeroRegistro: string;
	numeroPgu: string;
	nome: string;
	renach: string;
	local: string;
	categoriaPretendida: string;
	cfcA: string;
	cfcB: string;
	espelhoCnh: string;
	usoForm: string;
	situacaoAtual: string;
	espelhoPid: string;
	nomePai: string;
	dataPrimeiraHabilitacao: string;
	dataValidadeCnh: string;
	dataNascimento: string;
	nomeMae: string;
	sexo: string;
	nacionalidade: string;
	tipoDocumento: string;
	orgaoEmissor: string;
	localEmissao: string;
	rne1: string;
	rne2: string;
	rne3: string;
	naturalidade: string;
	identificacaoHabilitacao: string;
	pais: string;
	dataValidade: string;
	dataUltimaEmissaoCnh: string;
	dataExameTeorico: string;
	codigoExameTeorico: string;
	codigoEntidade: string;
	descricaoEntidade: string;
	dataExamePratico2Rodas: string;
	codigoExamePratico2Rodas: string;
	dataExamePratico4Rodas: string;
	codigoExamePratico4Rodas: string;
	cepPcon: string;
	cepPcad: string;
	flagAtividadeRemunerada: boolean;
	flagDeficienteFisico: boolean;
	endereco: Endereco;
	dadosPcad: DadosPcad;
	dadosPcan: DadosPcan;
	dadosExameMedico: DadosExameMedico;
	dadosExamePsicotecnico: DadosExamePsicotecnico;
	mensagensAlerta: string[];
}

export interface ConsultaCnhDefinitivaDataError {
	transacao: string;
	codigo: number;
	mensagem: string;
}

export interface DadosExamePsicotecnico {
	dataExamePsicotecnico: string;
	cpf: string;
	exame1: string;
	exame2: string;
	laudo1: string;
	laudo2: string;
	resultado1: string;
	resultado2: string;
	examePsicotecnico1: string;
	examePsicotecnico2: string;
	aptidoInaptoTemporario: string;
	aptidaoPsicotecnica: string;
	cred1: string;
	cred2: string;
	crp: string;
	regiao: string;
	aptoAte: string;
}

export interface DadosExameMedico {
	dataExameMedico: string;
	exameMedico: string;
	categoriaPermitida: string;
	restricaoMedica: string;
	aptidoInaptoTemporario: string;
	resultado: string;
	cred1: string;
	cred2: string;
	crm: string;
	cpf: string;
	regiao: string;
	aptidaoMedica: string;
	aptoAte: string;
	exame: string;
}

export interface Endereco {
	codigo: string;
	logradouro: string;
	numero: string;
	complemento: string;
	cep: string;
	bairro: string;
	descricaoMunicipio: string;
	codigoMunicipio: string;
}

export interface DadosPcad {
	cpf: string;
	nome: string;
	numRg: string;
	orgaoEmissor: string;
	localEmissao: string;
	dataNascimento: string;
	rne: string;
	orgaoEmissorRne: string;
	localEmissaoRne: string;
	codigoPais: string;
	descricaoPais: string;
	validadeHabilitacaoEstrangeira: string;
	sexo: string;
	numRegistro: string;
	numPgu: string;
	nacionalidade: string;
	naturalidade: string;
	codNaturalidade: string;
	nomePai: string;
	nomeMae: string;
	endereco: Endereco;
	processo: string;
	ciretran: string;
	categoriaAtual: string;
	categoriaPretendida: string;
	dataCadastro: string;
	horaCadastro: string;
	usuarioCadastro: string;
}

export interface DadosPcan {
	numeroRenach: string;
	numeroProcesso: string;
	local: string;
	dataPrimeiraHabilitacao: string;
	cfcA1: string;
	cfcA2: string;
	cfcB2Rodas1: string;
	cfcB2Rodas2: string;
	cfcB2Rodas3: string;
	cfcB4Rodas1: string;
	cfcB4Rodas2: string;
	cfcB4Rodas3: string;
	categoriaPretendida: string;
	escolha: string;
	categoriaAtual1: string;
	categoriaAtual2: string;
	codigoEntidade: string;
	numeroRegistro: string;
	dataCadastro: string;
	nome: string;
	sexo: string;
	dataNascimento: string;
	nacionalidade: string;
	codigoNaturalidade: string;
	descricaoNaturalidade: string;
	numeroRg: string;
	orgaoEmissor: string;
	uf: string;
	cpf: string;
	nomePai: string;
	nomeMae: string;
	exameTeorico: string;
	examePratico2Rodas: string;
	examePratico4Rodas: string;
	examinadorTeorico1: string;
	examinadorTeorico2: string;
	examinadorPratico2Rodas1: string;
	examinadorPratico2Rodas2: string;
	examinadorPratico4Rodas1: string;
	examinadorPratico4Rodas2: string;
	unidade: string;
	dataUltimaEmissaoCnh: string;
	flagAtividadeRemunerada: boolean;
	endereco: Endereco;
	dadosExameMedico: DadosExameMedico;
	dadosExamePsicotecnico: DadosExamePsicotecnico;
}

export interface IRequestConsultaCnhDefinitiva {
	cpf?: string;
	usuario?: string;
	senha?: string;
	numeroRg?: string;
	numeroRegistro?: string;
	numeroPgu?: string;
	nome?: string;
	dataNascimento?: string;
}
