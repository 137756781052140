import { lazy } from 'react';

import sguRoutes from '../Sgu/routes';

// PATHS
import {
	ROUTE_POUPATEMPO_CONTABILIZACAO,
	ROUTE_POUPATEMPO_JUSTIFICATIVA_ATENDIMENTO,
	ROUTE_POUPATEMPO_RECUPERAR_ATENDIMENTO,
	ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS,
	ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PARAMETROS,
	ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PARAMETROS_ADD,
	ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PARAMETROS_CODIGO,
	ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PREFEITURAS,
	ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PREFEITURAS_ADD,
	ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PREFEITURAS_CODIGO,
	ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_SERVICOS,
	ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_SERVICOS_ADD,
	ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_SERVICOS_CODIGO,
	ROUTE_POUPATEMPO_SGU,
} from './paths';

// ATENDIMENTO
const RecuperaAtendimentoPoupatempo = lazy(
	() => import('../RecuperarAtendimento'),
);
const JustificativaAtendimento = lazy(
	() => import('components/Pages/JustificativaAtendimento'),
);

// SGU PRINCIPAL
const SGUPrincipal = lazy(() => import('../Sgu/Principal'));

// CONTABILIZAÇÃO
const Contabilizacao = lazy(() => import('../Contabilizacao/Nav'));

// SERVIÇOS MUNICIPAIS
const MenuOptions = lazy(() => import('../ServicosMunicipais/MenuOptions'));
const Parametros = lazy(() => import('../ServicosMunicipais/Parametros'));
const CreateEdit = lazy(
	() => import('../ServicosMunicipais/Parametros/CreateEdit'),
);
const Prefeituras = lazy(() => import('../ServicosMunicipais/Prefeituras'));
const NavPrefeituras = lazy(
	() => import('../ServicosMunicipais/Prefeituras/CreateEdit/nav'),
);
const Servicos = lazy(() => import('../ServicosMunicipais/Servicos'));
const NavService = lazy(
	() => import('../ServicosMunicipais/Servicos/CreateEdit/nav'),
);

export const poupatempoRoute = {
	name: 'Poupatempo',
	route: '/poupatempo',
};

export const SGURoute = {
	name: 'SGU',
	route: '/sgu',
};

const routes = [
	{
		path: ROUTE_POUPATEMPO_RECUPERAR_ATENDIMENTO,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Recupera Atendimento',
			},
		],
		Component: RecuperaAtendimentoPoupatempo,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_POUPATEMPO_JUSTIFICATIVA_ATENDIMENTO,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Justificativa atendimento',
			},
		],
		Component: JustificativaAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_POUPATEMPO_CONTABILIZACAO,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Contabilização',
			},
		],
		Component: Contabilizacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Serviços Municipais',
			},
		],
		Component: MenuOptions,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PARAMETROS,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Parâmetros',
			},
		],
		Component: Parametros,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PARAMETROS_ADD,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Adicionar Parâmetro',
			},
		],
		Component: CreateEdit,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: `${ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PARAMETROS_CODIGO}:codigo`,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Editar Parâmetro',
			},
		],
		Component: CreateEdit,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_SERVICOS,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Serviços',
			},
		],
		Component: Servicos,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_SERVICOS_ADD,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Adicionar Serviço',
			},
		],
		Component: NavService,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: `${ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_SERVICOS_CODIGO}:codigo`,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Editar Serviço',
			},
		],
		Component: NavService,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PREFEITURAS,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Prefeituras',
			},
		],
		Component: Prefeituras,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PREFEITURAS_ADD,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Adicionar Prefeituras',
			},
		],
		Component: NavPrefeituras,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: `${ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PREFEITURAS_CODIGO}:codigo`,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Editar Prefeituras',
			},
		],
		Component: NavPrefeituras,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_POUPATEMPO_SGU,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Principal',
			},
		],
		Component: SGUPrincipal,
		isAuthenticated: true,
		exact: true,
	},
];

const poupatempoRoutes = routes.concat(sguRoutes);

export default poupatempoRoutes;
