export enum Types {
	GERAR_RENACH_RENOVACAO = '@cnh/GERAR_RENACH_RENOVACAO',
	GERAR_RENACH_RENOVACAO_SUCCESS = '@cnh/GERAR_RENACH_RENOVACAO_SUCCESS',
	GERAR_RENACH_RENOVACAO_FAILURE = '@cnh/GERAR_RENACH_RENOVACAO_FAILURE',
}

export interface gerarRenach {
	status: number;
	data: null | IDadosRenach;
	dataRequest: null | IRequestGerarRenach;
}

export interface IDadosRenach {
	codigoInterno: string;
	message: string;
	cpf: string;
	renach: string;
}
export interface IRequestGerarRenach {
	autorizaEmail: string;
	autorizaSMS: string;
	bairro: string;
	categoriaAtual: string;
	categoriaPretendida: string;
	telefone: string;
	celular: string;
	cep: string;
	cnhDigital: string;
	codigoEntidade: string;
	codigoVerificador: string;
	complemento: string;
	cpf: string;
	dataNascimento: string;
	dataPrimeiraHabilitacao: string;
	dddCelular: string;
	dddTelefone: string;
	email: string;
	logradouro: string;
	nacionalidade: string;
	naturalidade: string;
	necessidadeVeiculoAdaptado: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	numCarteiraMilitar: string;
	numDocumento: string;
	numPGU: string;
	// numRNE: string;
	numRegistro: string;
	numero: string;
	orgaoDocumento: string;
	orgaoRNE: string;
	pretendeAtividadeRemunerada: string;
	sexo: string;
	tipoDocumento: string;
	ufDocumento: string;
	ufRNE: string;
	exameDentroDoPosto?: string;
}
