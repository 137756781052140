import React, { useCallback, useEffect } from 'react';

import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import {
	ROUTE_IIRGD_PESQUISAR_RG,
	ROUTE_IIRGD_RESULTADO_PESQUISA,
} from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultaPesquisaCirgClear,
	consultaPesquisaMaeCirgClear,
} from 'store/modules/api/iirgd/consultaPesquisaCirg/actions';
import { consultaPesquisaCidadaoRequest } from 'store/modules/api/iirgd/consultaPesquisaCidadao/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// FORM
import { ConsultaPesquisaCirg } from 'store/modules/api/iirgd/consultaPesquisaCirg/types';
import { treatValues } from '../Formulario/form';

const PesquisaRgMae: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { consultaPesquisaCirg, consultaPesquisaCidadao } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	// Verifica se as informações são válidas.
	useEffect(() => {
		if (!consultaPesquisaCirg.mae.data || !consultaPesquisaCidadao.form) {
			dispatch(consultaPesquisaCirgClear());
			history.push(ROUTE_IIRGD_PESQUISAR_RG);
		}
	}, [consultaPesquisaCirg, consultaPesquisaCidadao, dispatch, history]);

	// Realiza o redirecionamento após o sucesso na consulta.
	useEffect(() => {
		if (consultaPesquisaCidadao.status === 200) {
			history.push(ROUTE_IIRGD_RESULTADO_PESQUISA);
		}
	}, [consultaPesquisaCidadao, history]);

	const handleRetornar = useCallback(() => {
		dispatch(consultaPesquisaMaeCirgClear());
		history.push(ROUTE_IIRGD_PESQUISAR_RG);
	}, [dispatch, history]);

	const handleAvancar = useCallback(() => {
		if (consultaPesquisaCidadao.form) {
			const values = treatValues(consultaPesquisaCidadao.form, login);

			dispatch(
				consultaPesquisaCidadaoRequest(values, consultaPesquisaCidadao.form),
			);
		}
	}, [consultaPesquisaCidadao, login, dispatch]);

	const getNomeMae = () => {
		let nomeMae = 'NOME DA MÃE NÃO ENCONTRADO';
		if (consultaPesquisaCirg.mae.data?.length) {
			const maeFilter = consultaPesquisaCirg.mae.data.filter(
				(item: ConsultaPesquisaCirg) =>
					item.numeroRg === consultaPesquisaCidadao.form?.rgMae,
			);
			if (maeFilter.length) {
				nomeMae = maeFilter[0].nome.toUpperCase().trim();
			}
		}
		return nomeMae.toLocaleUpperCase().trim();
	};

	return (
		<>
			aqui
			{consultaPesquisaCirg.mae.data && consultaPesquisaCidadao.form && (
				<Row>
					<Col span={24}>
						<Section title="Pesquisar">
							<Row gutter={[0, 10]}>
								<Col span={24} />
							</Row>

							<Row gutter={[0, 10]}>
								<Col
									span={6}
									style={{
										textAlign: 'right',
									}}
								>
									<strong>Nome:</strong>
								</Col>

								<Col offset={1}>
									{consultaPesquisaCidadao.form?.nome.toUpperCase()}
								</Col>
							</Row>

							<Row gutter={[0, 10]}>
								<Col
									span={6}
									style={{
										textAlign: 'right',
									}}
								>
									<strong>Nome do Pai:</strong>
								</Col>

								<Col offset={1}>
									{consultaPesquisaCidadao.form.nomePai.toUpperCase()}
								</Col>
							</Row>

							<Row gutter={[0, 10]}>
								<Col
									span={6}
									style={{
										textAlign: 'right',
									}}
								>
									<strong>Nome da Mãe informado:</strong>
								</Col>

								<Col
									style={{
										color: 'red',
									}}
									offset={1}
								>
									{consultaPesquisaCidadao.form.nomeMae.toUpperCase()}
								</Col>
							</Row>

							<Row gutter={[0, 10]}>
								<Col
									span={6}
									style={{
										textAlign: 'right',
									}}
								>
									<strong>Nome da Mãe pesquisado pelo RG:</strong>
								</Col>

								<Col
									style={{
										color: 'red',
									}}
									offset={1}
								>
									{getNomeMae()}
								</Col>
							</Row>

							<Row gutter={[0, 10]}>
								<Col
									span={6}
									style={{
										textAlign: 'right',
									}}
								>
									<strong>Data de Nascimento:</strong>
								</Col>

								<Col offset={1}>
									{consultaPesquisaCidadao.form.dataNascimento}
								</Col>
							</Row>

							<Row gutter={[0, 10]}>
								<Col
									span={6}
									style={{
										textAlign: 'right',
									}}
								>
									<strong>Naturalidade:</strong>
								</Col>

								<Col offset={1}>
									{`${consultaPesquisaCidadao.form.codigoNaturalidade} | ${consultaPesquisaCidadao.form.municipioNaturalidade}`}
								</Col>
							</Row>

							<Row gutter={[0, 20]}>
								<Col span={24} />
							</Row>

							<Row justify="center">
								<Col>
									<ButtonImage
										src="retornar"
										onClick={() => handleRetornar()}
									/>
								</Col>

								<Col offset={1}>
									<ButtonImage src="avancar" onClick={() => handleAvancar()} />
								</Col>
							</Row>
						</Section>

						<ButtonVoltar navigate={false} onClick={() => handleRetornar()} />
					</Col>
				</Row>
			)}
		</>
	);
};

export default PesquisaRgMae;
