import React, { useCallback, useEffect, useState } from 'react';

import { FormikProps } from 'formik';
import { Row, Col, Upload, Button, Modal } from 'antd';
import MenuScanner from 'components/Pages/Scanner/Menu';

// COMPONENTS
import Section from 'components/Common/Section';
import SimpleTable from 'components/Common/Table';
import Error from 'components/Common/Notifications/FormError/index';

type SupportedFiles =
	| 'image/jpg'
	| 'image/gif'
	| 'image/jpeg'
	| 'application/pdf';

interface Props {
	formik: FormikProps<any>;
	supportedFileExtensions: SupportedFiles[];
}

const UploadArquivoGeral: React.FC<Props> = ({
	formik,
	supportedFileExtensions,
}) => {
	const { Dragger } = Upload;

	const [tableData, setTableData] = useState<any>(formik.values.documentos);
	const [errors, setErros] = useState<any>([]);
	const [modalScanner, setModalScanner] = useState(false);

	useEffect(() => {
		setTableData(formik.values.documentos);
	}, [formik.values.documentos]);

	const fileToBase64 = (file: any) => {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = event => {
				if (event?.target?.result !== null && event?.target?.result) {
					resolve(event.target.result);
				}
			};
		});
	};

	const handleChange = useCallback(
		async (info: any) => {
			if (info.file.size > 10000000) {
				setErros(['Tamanho máximo permitido para o documento 10 (em MB).']);
			} else {
				const b64File = await fileToBase64(info.file).then(result => {
					return result;
				});
				const b64 = b64File as string;
				const formatedContudo = b64.toString().split(',')[1];

				formik.setFieldValue('documentos', [
					{
						nomeDocumento: info.file.name,
						tipoArquivo: '44',
						conteudo: formatedContudo,
					},
				]);
			}
		},
		[formik],
	);

	const props = {
		name: 'file',
		multiple: false,
		openFileDialogOnClick: false,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		beforeUpload: (file: any) => {
			return false;
		},
		onChange: (info: any) => handleChange(info),
	};

	const handleRemove = useCallback(
		async index => {
			const allFiles = [...tableData];
			allFiles.splice(index, 1);
			formik.setFieldValue('documentos', allFiles);
		},
		[formik, tableData],
	);

	const headers = [
		{
			key: '1',
			title: 'id',
			dataIndex: 'id',
			render: (text: any, record: any, index: any) => index + 1,
		},
		{
			key: '2',
			title: 'Nome',
			dataIndex: 'nomeDocumento',
			render: (text: any) => text,
		},
		{
			key: '3',
			title: '',
			dataIndex: 'type',
			render: (text: any, record: any, index: any) => (
				<div style={{ textAlign: 'center' }}>
					<Button onClick={() => handleRemove(index)}>Excluir</Button>
				</div>
			),
		},
	];

	return (
		<Section
			size="lg"
			title="Documentos"
			style={formik.errors.documentos ? { borderColor: 'red' } : undefined}
		>
			<Row gutter={[0, 10]}>
				<Col span={24} style={{ fontWeight: 'bold' }}>
					{`Anexar arquivo com até 10 megabytes. Formatos suportados: ${
						(supportedFileExtensions.length > 0 &&
							supportedFileExtensions.map((item, idx) => {
								if (idx + 1 < supportedFileExtensions.length) {
									return ` ${
										supportedFileExtensions[idx].toUpperCase().split('/')[1]
									}`;
								}
								return ` ${
									supportedFileExtensions[idx].toUpperCase().split('/')[1]
								}`;
							})) ||
						''
					}`}
				</Col>

				<Col span={24}>
					{errors.length > 0 && (
						<Error errors={errors} onClose={() => setErros([])} />
					)}

					<Dragger
						{...props}
						accept={`${supportedFileExtensions.map(
							(item, idx) => supportedFileExtensions[idx],
						)}`}
						showUploadList={false}
					>
						<Button className="addButton" onClick={() => setModalScanner(true)}>
							Digitalizar
						</Button>
						<Upload
							{...props}
							openFileDialogOnClick
							accept="image/jpg, image/gif, image/jpeg, application/pdf"
							showUploadList={false}
						>
							<Button className="addButton" onClick={() => {}}>
								Documento
							</Button>
						</Upload>
					</Dragger>
				</Col>

				<Col span={24}>
					<SimpleTable
						headers={headers}
						body={tableData}
						messageNoResults="Nenhum registro encontrado"
					/>
				</Col>
			</Row>

			{modalScanner && (
				<Modal
					title="Digitalização de Documentos"
					visible={modalScanner}
					footer={[]}
					width="90%"
					style={{ top: '2px' }}
					onCancel={() => setModalScanner(false)}
				>
					<MenuScanner voltar={false} />
				</Modal>
			)}
		</Section>
	);
};

export default UploadArquivoGeral;
