import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// ANT DESIGN
import { Col, Row } from 'antd';

// REDUX
import {
	clearNotifications,
	showInside,
} from 'store/modules/app/notifications/actions';
import { CadastraAtendimentoPfRequest } from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPf/types';
import { ApplicationState } from 'store';

// COMPONENTS
import LightButton from 'components/Common/Buttons/Light';
import Section from 'components/Common/Section';
import { Field, Form, Formik, FormikProps } from 'formik';
import CPF from 'components/Common/Form/Fields/CPF';
import Error from 'components/Common/Notifications/FormError/index';
import TypographyCommon from 'components/Common/Typography';
import Input from 'components/Common/Form/Input/Input';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

// FORM
import InputMask from 'components/Common/Form/Input/InputMask';
import {
	CadastroProcuradorOuResponsavelForm,
	cadastroProcuradorOuResponsaveSchema,
	initialValues,
} from './form';

// STYLED
import { stylePaddingCols } from './styled';

interface Props {
	formikParent: FormikProps<CadastraAtendimentoPfRequest>;
	setShowModal: () => void;
}

const ProcuradorOuResponsavel: React.FC<Props> = ({
	formikParent,
	setShowModal,
}) => {
	const dispatch = useDispatch();

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	useEffect(() => {
		dispatch(clearNotifications());
		showInside(true);
	}, [dispatch]);

	const handleSubmit = useCallback(
		(values: CadastroProcuradorOuResponsavelForm) => {
			formikParent.setFieldValue(
				'nomeDoResponsavelOuProcurador',
				values.nomeDoResponsavelOuProcurador,
			);
			formikParent.setFieldValue(
				'cpfDoProcuradorOuResponsavel',
				values.cpfDoProcuradorOuResponsavel,
			);
			formikParent.setFieldValue(
				'rgDoResponsavelOuProcurador',
				values.rgDoResponsavelOuProcurador,
			);
			setShowModal();
		},
		[formikParent, setShowModal],
	);

	const handleCancel = () => {
		formikParent.setFieldValue('temProcurador', false);
		formikParent.setFieldValue('nomeDoResponsavelOuProcurador', '');
		formikParent.setFieldValue('cpfDoProcuradorOuResponsavel', '');
		formikParent.setFieldValue('rgDoResponsavelOuProcurador', '');
		setShowModal();
	};

	useEffect(
		() => () => {
			showInside(false);
		},
		[dispatch],
	);

	useEffect(
		() => () => {
			if (notifications.errors && notifications.errors.length > 0)
				dispatch(clearNotifications());
		},
		[dispatch, notifications.errors],
	);

	return (
		<>
			{notifications.errors &&
				notifications.errors.length > 0 &&
				notifications.showInside && <Error errors={notifications.errors} />}

			<Section
				bgColor="cinza-escuro"
				style={{
					border: '1px grey solid',
					borderRadius: '4px',
					marginTop: '0px',
				}}
			>
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values =>
						getValidationsErrors(values, cadastroProcuradorOuResponsaveSchema)
					}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							<Row
								gutter={[0, 0]}
								align="middle"
								justify="start"
								style={{ height: '50px' }}
							>
								<Col span={24}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="Nome:"
												fontWeight="bold"
												required
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 16]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={Input}
												name="nomeDoResponsavelOuProcurador"
												error={hasError(
													formik.errors,
													'nomeDoResponsavelOuProcurador',
												)}
												required
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<br />

							<Row
								gutter={[0, 0]}
								align="middle"
								justify="start"
								style={{ height: '50px' }}
							>
								<Col span={12} style={stylePaddingCols}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="CPF:"
												fontWeight="bold"
												required
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={CPF}
												numero="cpfDoProcuradorOuResponsavel"
												formik={formik}
												required
												error={hasError(
													formik.errors,
													'cpfDoProcuradorOuResponsavel',
												)}
												removeContainer
											/>
										</Col>
									</Row>
								</Col>

								<Col span={12}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="RG:"
												fontWeight="bold"
												required
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={InputMask}
												mask="********************"
												maskChar=""
												name="rgDoResponsavelOuProcurador"
												error={hasError(
													formik.errors,
													'rgDoResponsavelOuProcurador',
												)}
												required
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<br />
							<br />

							<Row gutter={[0, 0]} align="middle" justify="center">
								<Col span={12}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<LightButton
											type="button"
											buttonColor="default"
											text="Cancelar"
											buttonSize="sm"
											buttonWidth={140}
											onClick={() => {
												handleCancel();
											}}
										/>
									</Row>
								</Col>
								<Col span={12}>
									<Row gutter={[0, 0]} align="middle" justify="end">
										<LightButton
											icon="add"
											type="submit"
											buttonColor="default"
											buttonSize="sm"
											buttonWidth={150}
											text="Cadastrar"
										/>
									</Row>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</Section>
		</>
	);
};
export default ProcuradorOuResponsavel;
