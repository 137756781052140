// ROUTES
import { ROUTE_IIRGD } from 'routes/paths';

export const ROUTE_IIRGD_DADOS_AGENDAMENTO = `${ROUTE_IIRGD}/dados-agendamento`;

export const ROUTE_IIRGD_RECUPERAR_ATENDIMENTO = `${ROUTE_IIRGD}/recuperar-atendimento`;
export const ROUTE_IIRGD_REIMPRESSAO_PROTOCOLO = `${ROUTE_IIRGD}/reimpressao-protocolo`;
export const ROUTE_IIRGD_JUSTIFICATIVA_ATENDIMENTO = `${ROUTE_IIRGD}/recuperar-atendimento/justificativa`;

export const ROUTE_IIRGD_DARE_RETAGUARDA = `${ROUTE_IIRGD}/dare/retaguarda`;

export const ROUTE_IIRGD_HABILITAR_RG_NATURALIZADO = `${ROUTE_IIRGD}/supervisor/habilita-rg`;
