import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, consultarRestricaoVeiculo } from './types';

export const INITIAL_STATE: consultarRestricaoVeiculo = {
	status: 0,
	data: null,
	dataError: null,
};

export default function restricaoVeiculo(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultarRestricaoVeiculo {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_RESTRICAO_VEICULO: {
				draft.status = 100;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}

			case Types.CONSULTAR_RESTRICAO_VEICULO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}

			case Types.CONSULTAR_RESTRICAO_VEICULO_FAILURE: {
				draft.status = action.payload.data.status;
				draft.dataError = action.payload.data;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.CONSULTAR_RESTRICAO_VEICULO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}

			default:
		}
		return draft;
	});
}
