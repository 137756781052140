import * as Yup from 'yup';

export interface IFormRecurso {
	codigoAit?: string;
	cnhUf: string;
	cnhCategoria: string;
	requerenteTipo: string;
	requerenteNome: string;
	requerenteCpf: string;
	requerenteRua: string;
	requerenteNumero: string;
	requerenteBairro: string;
	requerenteComplemento: string;
	requerenteUf: string;
	requerenteCep: string;
	fotoRg: string;
	fotoCnh: string;
	fotoCrlv: string;
	fotoRequerimento: string;
	fotoContratoSocial: string;
	fotoFormulario: string;
	tipoRecurso: string;
}

export const initialValues: IFormRecurso = {
	cnhUf: '',
	cnhCategoria: '',
	requerenteTipo: '',
	requerenteNome: '',
	requerenteCpf: '',
	requerenteRua: '',
	requerenteNumero: '',
	requerenteBairro: '',
	requerenteComplemento: '',
	requerenteUf: '',
	requerenteCep: '',
	fotoRg: '',
	fotoCnh: '',
	fotoCrlv: '',
	fotoRequerimento: '',
	fotoContratoSocial: '',
	fotoFormulario: '',
	tipoRecurso: '',
};

export const schema = Yup.object<IFormRecurso>().shape({
	cnhCategoria: Yup.string().required('Campo Obrigatório: Categoria.'),
	cnhUf: Yup.string().required('Campo Obrigatório: UF.'),
	requerenteTipo: Yup.string().required('Campo Obrigatório: Tipo.'),
	requerenteNome: Yup.string().required('Campo Obrigatório: Nome.'),
	requerenteCpf: Yup.string()
		.required('Campo Obrigatório: CPF.')
		.test({
			name: 'endpoint_required',
			message: 'Campo CPF: Tamanho mínimo de 11 caracteres.',
			test: (cpf: any) => cpf === '' || cpf.length === 11,
		}),
	requerenteRua: Yup.string().required('Campo Obrigatório: Endereço.'),
	requerenteNumero: Yup.string().required('Campo Obrigatório: Número.'),
	requerenteBairro: Yup.string().required('Campo Obrigatório: Bairro.'),
	requerenteUf: Yup.string().required('Campo Obrigatório: Estado.'),
	requerenteCep: Yup.string().required('Campo Obrigatório: CEP.'),
	fotoRg: Yup.string().required('Campo Obrigatório: RG.'),
	fotoCnh: Yup.string().required('Campo Obrigatório: CNH.'),
	fotoCrlv: Yup.string().required('Campo Obrigatório: CLRV.'),
	fotoRequerimento: Yup.string().required('Campo Obrigatório: Requerimento.'),
	fotoFormulario: Yup.string().required('Campo Obrigatório: Formulário.'),
});
