export enum Types {
	CONSULTAR_AUTORIZACAO_PREVIA_REQUEST = '@DETRAN-CRV/CONSULTAR_AUTORIZACAO_PREVIA_REQUEST',
	CONSULTAR_AUTORIZACAO_PREVIA_SUCCESS = '@DETRAN-CRV/CONSULTAR_AUTORIZACAO_PREVIA_SUCCESS',
	CONSULTAR_AUTORIZACAO_PREVIA_FAILURE = '@DETRAN-CRV/CONSULTAR_AUTORIZACAO_PREVIA_FAILURE',
	CONSULTAR_AUTORIZACAO_PREVIA_CLEAR = '@DETRAN-CRV/CONSULTAR_AUTORIZACAO_PREVIA_CLEAR',
}

export interface consultarAutorizacaoPrevia {
	status: number;
	data: ocorrencias | null;
}

interface ocorrencias {
	ocorrencias: [
		{
			codigoAutorizacao: number;
			dataHoraRegistroSerpro: string;
			numeroAutorizacao: string;
			sistemaConsumidor: string;
			situacaoAutorizacao: string;
			tipoAutorizacao: string;
		},
	];
}
