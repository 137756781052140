export enum Types {
	CONSULTAR_RELATORIO_MENSAL_DARE_REQUEST = '@relatorios/CONSULTAR_RELATORIO_MENSAL_DARE_REQUEST',
	CONSULTAR_RELATORIO_MENSAL_DARE_FAILURE = '@relatorios/CONSULTAR_RELATORIO_MENSAL_DARE_FAILURE',
	CONSULTAR_RELATORIO_MENSAL_DARE_SUCCESS = '@relatorios/CONSULTAR_RELATORIO_MENSAL_DARE_SUCCESS',
	CONSULTAR_RELATORIO_MENSAL_DARE_CLEAR = '@relatorios/CONSULTAR_RELATORIO_MENSAL_DARE_CLEAR',
}

export interface IConsultarRelatorioMensalDareState {
	status: number;
	data: IConsultarRelatorioMensalDare[] | null;
}

export interface IConsultarRelatorioMensalDare {
	id: string;
	localidade: string;
	descricaoServico: string;
	dataEvento: string;
	quantidade: number;
}

export interface IConsultarRelatorioMensalDareRequest {
	dataInicial: string;
	dataFinal: string;
}
