import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { consultarMutuarioClear } from 'store/modules/api/cdhu/consultarMutuario/actions';

// PATHS
import {
	ROUTE_CDHU_SERVICOS,
	ROUTE_CDHU_SOLICITANTE_PESQUISAR,
} from 'pages/cdhu/routes/paths';

// COMPONENTS
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { getInitialValues, IFormDadosSolicitante, schema } from '../../form';

const DadosMutuario: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [isFormDisabled, setIsFormDisabled] = useState<boolean>(true);
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);
	const [hasSubmit, setHasSubmit] = useState<boolean>(false);

	const [initialValues, setInitialValues] = useState<IFormDadosSolicitante>({
		cpf: '',
		nome: '',
		email: '',
		celular: '',
		telefoneResidencial: '',
		telefoneComercial: '',
		telefoneContato: '',
	});

	const { consultarMutuario, consultarSolicitante } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	useEffect(() => {
		if (consultarSolicitante.tipoVinculo && consultarMutuario.data) {
			const cpfMutuario = consultarMutuario.data.cpf || '';
			const cpfSolicitante = consultarSolicitante.form?.cpf || '';
			const isEditable =
				cpfMutuario.replace(/\D/g, '') === cpfSolicitante.replace(/\D/g, '');
			const values: Object = {
				cpf: cpfMutuario,
				...(isEditable
					? { ...consultarSolicitante.data }
					: { ...consultarMutuario.data }),
			};

			// Mutuário/Solicitante.
			// Caso o solicitante seja o próprio mutuário é permitida a edição dos dados.
			if (cpfMutuario.length !== 0 && isEditable) {
				setIsFormDisabled(false);
			} else {
				setIsFormDisabled(true);
			}

			setInitialValues(getInitialValues(values, isEditable));
			setHasLoaded(true);
		}
	}, [consultarMutuario.data, consultarSolicitante]);

	useEffect(() => {
		if (hasSubmit) {
			history.push({
				pathname: ROUTE_CDHU_SERVICOS,
				state: {
					...initialValues,
				},
			});
		}
	}, [hasSubmit, initialValues, history]);

	const handleValidate = useCallback((values: IFormDadosSolicitante) => {
		return getValidationsErrors(values, schema);
	}, []);

	const handleSubmit = useCallback((values: IFormDadosSolicitante) => {
		setInitialValues({
			...values,
		});
		setHasSubmit(true);
	}, []);

	return (
		<>
			<Section size="lg" title="Dados do mutuário" titleSize="sm">
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={handleValidate}
							onSubmit={handleSubmit}
							enableReinitialize
						>
							{formik => (
								<Form autoComplete="off">
									<>
										{hasLoaded && (
											<Row align="middle">
												<Col>
													<Row gutter={[0, 10]}>
														<Col span={12}>
															<Field
																as={InputMask}
																title="CPF"
																name="cpf"
																mask="999.999.999-99"
																disabled
																required
															/>
														</Col>
													</Row>

													<Row gutter={[0, 10]}>
														<Col span={24}>
															<Field
																as={Input}
																title="Nome"
																name="nome"
																maxLength={44}
																error={!!formik.errors.nome}
																disabled={isFormDisabled}
															/>
														</Col>
													</Row>

													<Row gutter={[0, 10]}>
														<Col span={24}>
															<Field
																as={Input}
																title="E-mail"
																name="email"
																maxLength={50}
																disabled={
																	initialValues.email !== '' && isFormDisabled
																}
																required
																error={!!formik.errors.email}
															/>
														</Col>
													</Row>

													<Row gutter={[0, 10]}>
														<Col span={12}>
															<Field
																as={InputMask}
																title="Celular"
																name="celular"
																mask={
																	initialValues.celular &&
																	initialValues.celular.length === 11
																		? '(99)99999-9999'
																		: '(99)9999-9999'
																}
																disabled={
																	initialValues.celular !== '' && isFormDisabled
																}
																error={!!formik.errors.celular}
															/>
														</Col>

														<Col span={12}>
															<Field
																as={InputMask}
																title="Telefone"
																name="telefoneResidencial"
																mask="(99)9999-9999"
																disabled={
																	initialValues.telefoneResidencial !== '' &&
																	isFormDisabled
																}
																error={!!formik.errors.telefoneResidencial}
															/>
														</Col>
													</Row>

													<Row>
														<Col span={12}>
															<Field
																as={InputMask}
																title="Tel. Comercial"
																name="telefoneComercial"
																mask="(99)9999-9999"
																disabled={
																	initialValues.telefoneComercial !== '' &&
																	isFormDisabled
																}
																error={!!formik.errors.telefoneComercial}
															/>
														</Col>

														<Col span={12}>
															<Field
																as={InputMask}
																title="Tel. Contato"
																name="telefoneContato"
																mask="(99)9999-9999"
																disabled={
																	initialValues.telefoneContato !== '' &&
																	isFormDisabled
																}
																error={!!formik.errors.telefoneContato}
															/>
														</Col>
													</Row>
												</Col>

												<Col offset={4}>
													<ButtonImage type="submit" src="prosseguir" />
												</Col>
											</Row>
										)}
									</>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>

			<ButtonVoltar
				navigate={false}
				onClick={() => {
					dispatch(clearNotifications());
					dispatch(consultarMutuarioClear());
					history.push(ROUTE_CDHU_SOLICITANTE_PESQUISAR);
				}}
			/>
		</>
	);
};

export default DadosMutuario;
