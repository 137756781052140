export enum Types {
	ALTERAR_RENACH_COM_REGISTRO_REQUEST = '@cnh/ALTERAR_RENACH_COM_REGISTROREQUEST',
	ALTERAR_RENACH_COM_REGISTRO_SUCCESS = '@cnh/ALTERAR_RENACH_COM_REGISTRO_SUCCESS',
	ALTERAR_RENACH_COM_REGISTRO_FAILURE = '@cnh/ALTERAR_RENACH_COM_REGISTRO_FAILURE',
	ALTERAR_RENACH_COM_REGISTRO_CLEAR = '@cnh/ALTERAR_RENACH_COM_REGISTRO_CLEAR',
}

export interface AlterarRenachComRegistro {
	status: number;
	data: null | AlterarRenachComRegistroData;
	requestData: null | AlterarRenachComRegistroRequest;
}

interface AlterarRenachComRegistroData {
	cpf: string;
}

export interface AlterarRenachComRegistroRequest {
	cpf: string;
	sp: string | null;
	renach: string;
	numeroRegistro: string;
	numeroPGU: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	nomeSocial: string;
	dataNascimento: string;
	sexo: string;
	tipoDocumento: string;
	numeroDocumento: string;
	orgaoExpedidordocumento: string;
	ufDocumento: string;
	nacionalidade: string;
	codigoNaturalidade: string;
	logradouro: string;
	numeroLogradouro: string;
	complementoLogradouro: string;
	bairro: string;
	codigoMunicipio: string;
	cep: string;
	usoFormularioEnum: string;
	codigoCiretran: string;
	numeroRNE: string;
	orgaoExpedidorRNE: string | null;
	ufRNE: string | null;
	codigoPaisCNHEstrangeiro: string | null;
	dataValidadeCNHEstrangeiro: string | null;
	dataPrimeiroHabilitacao: string | null;
	identificacaoCNHEstrangeiro: string | null;
	atividadeRemunerada: boolean;
	veiculoAdaptado: boolean;
	email: string | null;
	ddd: string | null;
	celular: string | null;
	cnhDigital: boolean;
	codigoVerificador: string | null;
	cpfUsuario: string;
	paisAfetivos1: string;
	paisAfetivos2: string;
}
