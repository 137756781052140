import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100%;
`;

export const DividerBarra = styled.div`
	margin: 7px 3px;

	::before {
		content: '/';
	}
`;

export const Divider = styled.div`
	margin: 7px 3px;

	::before {
		content: '-';
	}
`;
