import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, SinalizaResolucaoConsultaAtendimento } from './types';

export const INITIAL_STATE: SinalizaResolucaoConsultaAtendimento = {
	status: null,
	data: null,
	consulta: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): SinalizaResolucaoConsultaAtendimento {
	return produce(state, draft => {
		switch (action.type) {
			case Types.SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_REQUEST: {
				draft.status = 100;
				draft.consulta = action.payload;
				break;
			}
			case Types.SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.data;
				break;
			}
			case Types.SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_FAILURE: {
				draft.status = 400;
				draft.data = action.payload;
				break;
			}
			case Types.SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.consulta = INITIAL_STATE.consulta;
				break;
			}

			default:
		}
		return draft;
	});
}
