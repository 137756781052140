import { EventoConfrontoSessaoRequest } from 'store/modules/api/detranCnh/evento/confronto/types';
import { EventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/types';
import { EventoTerminoSessaoRequest } from 'store/modules/api/detranCnh/evento/terminoSessao/types';
import { AtendimentoState } from 'store/modules/api/sgu/atendimento/types';
import { ILoginUnicoState } from 'store/modules/api/sgu/loginUnico/types';
import { enumTypeServiceDetranCnhEventSession } from 'utils/constants/enum';
import { formatDateISOWithTimeZone } from 'utils/genericFunctions';

export function threatValuesStartSessionEventRequest(
	atendimento: AtendimentoState,
	loginUnico: ILoginUnicoState,
): EventoInicioSessaoRequest {
	const newValues = {
		dataPayload: {
			id_atendimento: atendimento?.salvarAtendimento?.data?.idAtendimento || '',
			id_cidadao: atendimento?.salvarAtendimento?.data?.idCidadao || '',
			canal: {
				id: 1,
				desc: 'Presencial',
				localidade: {
					id: loginUnico.login.user.posto
						? Number(loginUnico.login.user.posto)
						: 0,
					desc: loginUnico?.login?.user?.nomePosto || '',
				},
			},
			atendente: {
				nome: loginUnico?.user?.nome || '',
				id_atendente: '',
				id_posto: loginUnico?.login?.user?.posto || '',
				desc_posto: loginUnico?.login?.user?.nomePosto || '',
			},
		},
		detailsStartSection: {
			dataInicio: formatDateISOWithTimeZone(new Date()) || '',
		},
	};

	return newValues;
}

export function threatValuesEndSessionEventRequest(
	atendimento: AtendimentoState,
	loginUnico: ILoginUnicoState,
	tipoProcessoSessao: string,
	dataInicioSessao: string,
): EventoTerminoSessaoRequest {
	const newValues = {
		id_atendimento: atendimento?.salvarAtendimento?.data?.idAtendimento || '',
		id_cidadao: atendimento?.salvarAtendimento?.data?.idCidadao || '',
		canal: {
			id: 1,
			desc: 'Presencial',
			localidade: {
				id: loginUnico?.login?.user?.posto
					? Number(loginUnico.login.user.posto)
					: 0,
				desc: loginUnico?.login?.user?.nomePosto || '',
			},
		},
		atendente: {
			nome: loginUnico?.user?.nome || '',
			id_atendente: '',
			id_posto: loginUnico?.login?.user?.posto || '',
			desc_posto: loginUnico?.login?.user?.nomePosto || '',
		},
		servico: {
			tipoServico: tipoProcessoSessao
				? enumTypeServiceDetranCnhEventSession.filter(
						typeEvent => typeEvent.type === tipoProcessoSessao,
				  )[0]?.value || ''
				: '',
			data_inicio: dataInicioSessao,
			data_termino: formatDateISOWithTimeZone(new Date()) || '',
		},
	};
	return newValues;
}

export function threatValuesConfrontSessionEventRequest(
	atendimento: AtendimentoState,
	loginUnico: ILoginUnicoState,
	idColetaBiometria: string,
	confronto: boolean | undefined = true,
): EventoConfrontoSessaoRequest {
	const newValues = {
		id_atendimento: atendimento?.salvarAtendimento?.data?.idAtendimento || '',
		id_cidadao: atendimento?.salvarAtendimento?.data?.idCidadao || '',
		canal: {
			id: 1,
			desc: 'Presencial',
			localidade: {
				id: loginUnico?.login?.user?.posto
					? Number(loginUnico.login.user.posto)
					: 0,
				desc: loginUnico?.login?.user?.nomePosto || '',
			},
		},
		atendente: {
			nome: loginUnico?.user?.nome || '',
			id_atendente: '',
			id_posto: loginUnico?.login?.user?.posto || '',
			desc_posto: loginUnico?.login?.user?.nomePosto || '',
		},
		reuso: {
			confronto_realizado: confronto,
			dedos: idColetaBiometria || '',
		},
	};

	return newValues;
}
