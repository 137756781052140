import React, { useState, useCallback, useEffect } from 'react';

import { Modal, Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import {
	pesquisaNaturalidadeRequest,
	pesquisaNaturalidadeClear,
} from 'store/modules/sgu/actions';

// ASSETS
import icon from 'assets/images/icons/icon_book.gif';

// COMPONENTS
import Input from 'components/Common/Form/Input/Input';
import ButtonImage from 'components/Common/ButtonImage';
import Error from 'components/Common/Notifications/FormError';
import SimpleTable from 'components/Common/Table';

// UTILS
import { completaZeroEsquerda } from 'utils/genericFunctions';

// FORM
import { ApplicationState } from 'store';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import {
	paisClear,
	paisRequest,
} from 'store/modules/api/utils/endereco/pais/actions';
import getValidationsErrors, {
	initialValues,
	ICfg,
	cfgMunicipio,
	cfgNaturalidade,
} from './form';

// STYLED
import CreateGlobalStyle from '../reset';
import { Container, ButtonDiv, Button } from '../styled';

interface MunicipioIBGE {
	id: number;
	numero: number;
	descricao: string;
	uf: string;
}

interface IPesquisaNaturalidadeResultado {
	id: number;
	digito: number;
	descricao: string;
	municipioIBGE: MunicipioIBGE | null;
}

interface Props {
	type: 'Município' | 'Naturalidade';
	disabled?: boolean;
	onClick?: () => void;
	result: (data: any) => void;
	formik: any;
}

const BuscarMunicipioNaturalidade: React.FC<Props> = ({
	type,
	disabled = false,
	onClick,
	result,
	formik,
}) => {
	const dispatch = useDispatch();

	const [showModal, setShowModal] = useState<boolean>(false);
	const [notificationErrors, setNotificationErrors] = useState<string[]>([]);

	const [cfgExibicao, setCfgExibicao] = useState<ICfg>({} as ICfg);

	const resultadosPesquisa = useSelector(
		(state: ApplicationState) => state.sgu.pesquisaNaturalidade,
	);

	const { pais } = useSelector((state: ApplicationState) => state.api.utils);

	useEffect(() => {
		if (type === 'Município') {
			setCfgExibicao(cfgMunicipio);
			return;
		}

		setCfgExibicao(cfgNaturalidade);
	}, [type]);

	useEffect(() => {
		if (onClick && showModal) onClick();
	}, [onClick, showModal]);

	const handleShowModal = useCallback(() => {
		if (disabled) {
			return;
		}

		setShowModal(true);
	}, [disabled, setShowModal]);

	const handleHideModal = useCallback(() => {
		setShowModal(false);
		dispatch(pesquisaNaturalidadeClear());
	}, [setShowModal, dispatch]);

	const handleValidate = useCallback(
		async values => {
			setNotificationErrors([]);

			const errors = await getValidationsErrors(values);

			if (typeof errors === 'boolean') {
				return errors;
			}

			const formikErrors: string[] = Object.keys(errors).map(value => {
				return errors[value];
			});

			setNotificationErrors(formikErrors);

			return errors;
		},
		[setNotificationErrors],
	);

	const handleSubmit = useCallback(
		values => {
			setNotificationErrors([]);

			dispatch(pesquisaNaturalidadeRequest({ descricao: values.pesquisa }));
		},
		[setNotificationErrors, dispatch],
	);

	const handleSelectedRow = useCallback(
		(res: IPesquisaNaturalidadeResultado) => {
			if (
				(!res.municipioIBGE || res.municipioIBGE.id === 0) &&
				type === 'Município' &&
				res.id !== 99000 &&
				formik.dni === '10977'
			) {
				handleHideModal();
				addNotifications({
					errors: [
						'Naturalidade do cidadão Inválida - Município não registrado no IBGE. Consulte o Supervisor!',
					],
				});
				return;
			}
			if (res.descricao && type === 'Naturalidade') {
				dispatch(paisRequest(res.descricao.toLocaleUpperCase()));
			}
			result({
				...res,
				codigo: completaZeroEsquerda(res.id, 5),
			});
			handleHideModal();
			dispatch(clearNotifications());
		},
		[result, handleHideModal, dispatch, type, formik],
	);

	useEffect(() => {
		if (
			pais.status !== 0 &&
			pais.data &&
			(pais.data.length < 1 || pais.data[0].sigla === '' || !pais.data[0].sigla)
		) {
			addNotifications({
				errors: [
					'A emissão da CIN não poderá ser realizada neste momento no estado de São Paulo. Nacionalidade (País) do cidadão não possui código ICAO correspondente. Para prosseguir, selecione a opção RG Estadual no campo DOCUMENTO A EMITIR.',
				],
			});
		}
	}, [pais]);

	useEffect(() => {
		dispatch(paisClear());
	}, [dispatch]);

	return (
		<Container>
			<Button
				type="button"
				icon={icon}
				disabled={disabled}
				onClick={handleShowModal}
			/>

			<Modal
				title={cfgExibicao.titleModal}
				visible={showModal}
				onCancel={handleHideModal}
				footer={null}
				style={{ animationDuration: '0s' }}
				destroyOnClose
			>
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={handleValidate}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							{notificationErrors.length > 0 && (
								<Error errors={notificationErrors} />
							)}

							<Row gutter={[0, 10]}>
								<Col span={24}>
									<p>
										<strong>
											{`1 - Digite o nome do ${cfgExibicao.formLabel}:`}
										</strong>
									</p>
								</Col>
							</Row>

							<Row gutter={[0, 10]}>
								<Col span={17}>
									<Field
										as={Input}
										name="pesquisa"
										autoFocus
										error={!!formik.errors.pesquisa}
									/>
								</Col>

								<Col span={7}>
									<ButtonDiv>
										<ButtonImage type="submit" src="pesquisar" />
									</ButtonDiv>
								</Col>
							</Row>

							<Row gutter={[0, 10]}>
								<Col span={24} />
							</Row>
						</Form>
					)}
				</Formik>

				{resultadosPesquisa.naturalidades &&
				resultadosPesquisa.naturalidades.length > 0 ? (
					<>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<p>
									<strong>{`2 - Escolha o ${cfgExibicao.formLabel}:`}</strong>
								</p>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={24}>
								<SimpleTable
									headers={[
										{
											title: cfgExibicao.tableHeader,
											dataIndex: 'descricao',
										},
									]}
									body={resultadosPesquisa.naturalidades}
									onClick={handleSelectedRow}
								/>
							</Col>
						</Row>
					</>
				) : (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<SimpleTable
								messageNoResults={
									resultadosPesquisa.status === 200 &&
									!resultadosPesquisa.naturalidades.length
										? 'Nenhum resultado encontrato para a pesquisa.'
										: 'Faça uma pesquisa.'
								}
								headers={[
									{
										title: cfgExibicao.tableHeader,
										dataIndex: 'descricao',
									},
								]}
								body={[]}
								onClick={handleSelectedRow}
							/>
						</Col>
					</Row>
				)}
			</Modal>

			<CreateGlobalStyle />
		</Container>
	);
};

export default BuscarMunicipioNaturalidade;
