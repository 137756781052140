import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { Types, AtualizarMunicipioIbgeRequest } from './types';

// ACTIONS
import {
	atualizarMunicipioIbgeSuccess,
	atualizarMunicipioIbgeFailure,
} from './actions';

function* atualizarMunicipioIbgeRequest(request: ReducerAction) {
	const { payload }: { payload: AtualizarMunicipioIbgeRequest } = request;

	let body = { ...payload };

	delete body.codigoNaturalidade;

	body = cleanEmpty(body) as AtualizarMunicipioIbgeRequest;

	const response: ApiResponse = yield call(
		putApi,
		PATH_SGU,
		`municipios-ibge/${payload.codigoNaturalidade}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarMunicipioIbgeSuccess(response));
	} else {
		yield put(atualizarMunicipioIbgeFailure());
	}
}

export default all([
	takeLatest(
		Types.ATUALIZAR_MUNICIPIO_IBGE_REQUEST,
		atualizarMunicipioIbgeRequest,
	),
]);
