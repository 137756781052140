import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_UTILS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RequestEnviaSMS } from './types';

// ACTIONS
import { enviaSMSSuccess, enviaSMSFailure } from './actions';

function* enviaSMS(request: ReducerAction) {
	const { payload }: { payload: RequestEnviaSMS } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_UTILS,
		`sms-salesforce/${CHANNEL}/enviar`,
		payload,
	);

	if (
		response.status === 200 ||
		response.status === 201 ||
		response.status === 204
	) {
		yield put(enviaSMSSuccess({ response, request: payload }));
	} else if (response.error) {
		yield put(enviaSMSFailure(response.error));
	}
}

export default all([takeLatest(Types.ENVIAR_SMS_REQUEST, enviaSMS)]);
