export enum Types {
	LIBERACAO_CILI_REQUEST = '@iirgd/LIBERACAO_CILI_REQUEST',
	LIBERACAO_CILI_SUCCESS = '@iirgd/LIBERACAO_CILI_SUCCESS',
	LIBERACAO_CILI_FAILURE = '@iirgd/LIBERACAO_CILI_FAILURE',
	LIBERACAO_CILI_CLEAR = '@iirgd/LIBERACAO_CILI_CLEAR',
}

export interface LibracaoCiliState {
	status: number;
	data: LibracaoCili | null;
}

export interface LibracaoCili {
	messageclassname: string;
	usuario: string;
	codigoRetorno: string;
	mensagemErro: string;
	registroNumero: string;
	registroDigito: string;
	registroSequencia: string;
	retornoUsuario: string;
	messageType: string;
}

export interface LibracaoCiliRequest {
	usuario: string;
	tipo: string;
	postoEntrega: string;
	postoAtendimento: string;
	registroNumero: string;
	registroDigito: string;
}
