import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirMotivoRequest } from './types';

export function excluirMotivoRequest(
	data: ExcluirMotivoRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_MOTIVO,
		payload: data,
	};
}
export function excluirMotivoSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_MOTIVO_SUCCESS,
		payload,
	};
}
export function excluirMotivoFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_MOTIVO_FAILURE,
		payload: null,
	};
}
export function excluirMotivoClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_MOTIVO_CLEAR,
		payload: null,
	};
}
