import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { IRequestWithHeader, Types } from './types';

// ACTIONS
import {
	emitirTransferenciasMunicipiosSuccess,
	emitirTransferenciasMunicipiosFailure,
} from './actions';

function* transferenciaMunicipioRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestWithHeader } = request;
	const { body, headers } = payload;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/emissao/emitir-transferencia-municipio`,
		body,
		headers,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(emitirTransferenciasMunicipiosSuccess(response.data));
	} else if (response.error) {
		yield put(emitirTransferenciasMunicipiosFailure());
	}
}

export default all([
	takeLatest(
		Types.EMITIR_TRANSFERENCIAS_MUNICIPIOS_REQUEST,
		transferenciaMunicipioRequest,
	),
]);
