import { ReducerAction } from 'store/modules/types';

import { ConsultarNaturalidadesRequest, Types } from './types';

export function consultarNaturalidadesRequest(
	payload: ConsultarNaturalidadesRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_NATURALIDADES_REQUEST,
		payload,
	};
}
export function consultarNaturalidadesSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_NATURALIDADES_SUCCESS,
		payload,
	};
}
export function consultarNaturalidadesFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_NATURALIDADES_FAILURE,
		payload: null,
	};
}
export function consultarNaturalidadesClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_NATURALIDADES_CLEAR,
		payload: null,
	};
}
