import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_DETRAN_CRV, CHANNEL } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultarBloqueioDetranRenajud } from './types';

// ACTIONS
import {
	consultarBloqueioDetranSuccess,
	consultarBloqueioRenajudSuccess,
	consultarBloqueioDetranRenajudFailure,
} from './actions';

function* consultarBloqueioDetranRenajudRequest(request: ReducerAction) {
	const {
		payload,
	}: { payload: IRequestConsultarBloqueioDetranRenajud } = request;

	const tipo = payload.tipoBloqueio;

	let body;

	if (tipo === '1') {
		body = {
			chassi: payload.chassi,
		};
	} else {
		body = {
			chassi: payload.chassi,
		};
	}

	/**
	 * Tipos de Bloqueio:
	 * 1 = Bloqueios Ativos DETRAN
	 * 2 = Bloqueios Judiciais RENAJUD
	 */
	const requestUrl =
		tipo === '2'
			? `consultas/${CHANNEL}/consultar-bloqueio-judicial-renajud`
			: `consultas/${CHANNEL}/consultar-bloqueio-ativo`;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		requestUrl,
		body,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(
			tipo === '2'
				? consultarBloqueioRenajudSuccess(response.data)
				: consultarBloqueioDetranSuccess(response.data),
		);
	} else if (response.error) {
		yield put(consultarBloqueioDetranRenajudFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_REQUEST,
		consultarBloqueioDetranRenajudRequest,
	),
]);
