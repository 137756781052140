/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';

import { Row, Col } from 'antd';

// STORES
import data from 'store/modules/api/mp/enum';

// UTILS
import hasError from 'utils/getFormErrors';
import {
	onlyLetters,
	onlyLettersAndSpace,
	onlyNumbers,
	onlyNumbersAndLetters,
} from 'utils/genericFunctions';

// COMMONS
import RG from 'components/Common/Form/Fields/RG';
import Select from 'components/Common/Form/Select';
import InputMask from 'components/Common/Form/Input/InputMask';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import Input from 'components/Common/Form/Input/Input';

// FORM
import { FormikProps, Field } from 'formik';
import { ICadastroSolicitante } from '../form';

interface Props {
	formik: FormikProps<ICadastroSolicitante>;
	step: number;
	solicitante: string;
	formDisabled: boolean;
}

const DadosSolicitante: React.FC<Props> = ({
	formik,
	step,
	solicitante,
	formDisabled,
}) => {
	const { setFieldValue } = formik;
	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado) => {
			setFieldValue(`${solicitante}.endereco.cep`, res.cep);
			setFieldValue(`${solicitante}.endereco.uf`, res.uf);
			setFieldValue(`${solicitante}.endereco.logradouro`, res.endereco);
			setFieldValue(`${solicitante}.endereco.bairro`, res.bairro);
			setFieldValue(`${solicitante}.endereco.cidade`, res.municipio);
		},
		[setFieldValue, solicitante],
	);

	return (
		<>
			<Row gutter={[10, 10]}>
				<Col span={8}>
					<RG
						title="RG"
						// disabled
						numero={`${solicitante}.rg.numero`}
						digito={`${solicitante}.rg.digito`}
						defaultNumero={
							solicitante === 'filho'
								? formik.values.filho?.rg?.numero
								: solicitante === 'responsavel'
								? formik.values.responsavel?.rg?.numero
								: formik.values.filho.genitor?.rg?.numero
						}
						defaultDigito={
							solicitante === 'filho'
								? formik.values.filho?.rg?.digito
								: solicitante === 'responsavel'
								? formik.values.responsavel?.rg?.digito
								: formik.values.filho.genitor?.rg?.digito
						}
						formik={formik}
						reset={!!formik.values.RGempty}
						result={rg => {
							formik.setFieldValue(`${solicitante}.rg.numero`, rg.numeroRg);
							formik.setFieldValue(`${solicitante}.rg.digito`, rg.digitoRg);
						}}
						readOnly={step === 2}
					/>
				</Col>
				<Col span={16}>
					{step === 1 && (
						<Field
							// disabled
							as={Select}
							size={35}
							title="UF RG"
							name={`${solicitante}.rg.uf`}
							options={data.uf}
							onChange={(v: string) =>
								formik.setFieldValue(`${solicitante}.rg.uf`, v)
							}
							error={hasError(formik.errors, `${solicitante}.rg.uf`)}
						/>
					)}
					{step === 2 && (
						<Field
							readOnly
							as={Input}
							size={35}
							title="UF RG"
							name={`${solicitante}.rg.uf`}
							error={hasError(formik.errors, `${solicitante}.rg.uf`)}
						/>
					)}
				</Col>
			</Row>
			{formik.values.tipoSolicitante === 3 && solicitante === 'filho.genitor' && (
				<Row>
					<Col span={18}>
						<Field
							as={Input}
							title="Nome da Mãe"
							name="filho.genitor.nomeMae"
							maxLength={60}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								formik.setFieldValue(
									'filho.genitor.nomeMae',
									onlyLettersAndSpace(e.target.value),
								);
							}}
							error={hasError(formik.errors, 'filho.genitor.nomeMae')}
							readOnly={step === 2}
							disabled={formDisabled}
						/>
					</Col>
				</Row>
			)}
			<Row gutter={[10, 10]}>
				<Col span={8}>
					<CEP
						disabled={formDisabled}
						retornoErro
						readOnly={step === 2}
						// isCalledInService="IIRGD"
						required={
							formik.values.tipoSolicitante === 1 ||
							(formik.values.tipoSolicitante === 3 &&
								solicitante !== 'responsavel') ||
							(formik.values.tipoSolicitante === 2 &&
								solicitante === 'responsavel')
						}
						name={`${solicitante}.endereco.cep`}
						defaultValue={
							solicitante === 'filho'
								? formik.values.filho?.endereco?.cep
								: solicitante === 'responsavel'
								? formik.values.responsavel?.endereco?.cep
								: formik.values.filho.genitor?.endereco?.cep
						}
						formik={formik}
						instanceRequest={`${solicitante}.endereco.cep`}
						result={handleCepSelecionado}
					/>
				</Col>
				<Col span={16}>
					<Field
						maxLength={22}
						title="Logradouro"
						name={`${solicitante}.endereco.logradouro`}
						type="text"
						size={70}
						disabled={formDisabled}
						as={Input}
						onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
							formik.setFieldValue(
								`${solicitante}.endereco.logradouro`,
								onlyLetters(v.target.value),
							);
						}}
						error={hasError(
							formik.errors,
							`${solicitante}.endereco.logradouro`,
						)}
						required={
							formik.values.tipoSolicitante === 1 ||
							(formik.values.tipoSolicitante === 3 &&
								solicitante !== 'responsavel') ||
							(formik.values.tipoSolicitante === 2 &&
								solicitante === 'responsavel')
						}
						readOnly={step === 2}
					/>
				</Col>
			</Row>
			<Row gutter={[10, 10]}>
				<Col span={8}>
					<Field
						maxLength={10}
						required={
							formik.values.tipoSolicitante === 1 ||
							(formik.values.tipoSolicitante === 3 &&
								solicitante !== 'responsavel') ||
							(formik.values.tipoSolicitante === 2 &&
								solicitante === 'responsavel')
						}
						title="Número"
						name={`${solicitante}.endereco.numero`}
						type="text"
						as={Input}
						onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
							formik.setFieldValue(
								`${solicitante}.endereco.numero`,
								onlyNumbers(v.target.value),
							);
						}}
						error={hasError(formik.errors, `${solicitante}.endereco.numero`)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col>
				<Col span={8}>
					<Field
						maxLength={6}
						title="Complemento"
						name={`${solicitante}.endereco.complemEndereco`}
						type="text"
						as={Input}
						onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
							formik.setFieldValue(
								`${solicitante}.endereco.complemEndereco`,
								onlyNumbersAndLetters(v.target.value),
							);
						}}
						error={hasError(
							formik.errors,
							`${solicitante}.endereco.complemEndereco`,
						)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col>
			</Row>

			<Row gutter={[10, 10]}>
				<Col span={8}>
					<Field
						maxLength={14}
						required={
							formik.values.tipoSolicitante === 1 ||
							(formik.values.tipoSolicitante === 3 &&
								solicitante !== 'responsavel') ||
							(formik.values.tipoSolicitante === 2 &&
								solicitante === 'responsavel')
						}
						title="Bairro"
						name={`${solicitante}.endereco.bairro`}
						type="text"
						as={Input}
						onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
							formik.setFieldValue(
								`${solicitante}.endereco.bairro`,
								onlyLetters(v.target.value),
							);
						}}
						error={hasError(formik.errors, `${solicitante}.endereco.bairro`)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col>
				<Col span={8}>
					<Field
						maxLength={50}
						required={
							formik.values.tipoSolicitante === 1 ||
							(formik.values.tipoSolicitante === 3 &&
								solicitante !== 'responsavel') ||
							(formik.values.tipoSolicitante === 2 &&
								solicitante === 'responsavel')
						}
						title="Município"
						name={`${solicitante}.endereco.cidade`}
						type="text"
						as={Input}
						onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
							formik.setFieldValue(
								`${solicitante}.endereco.cidade`,
								onlyLetters(v.target.value),
							);
						}}
						error={hasError(formik.errors, `${solicitante}.endereco.cidade`)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col>
				<Col span={8}>
					{step === 1 && (
						<Field
							required={
								formik.values.tipoSolicitante === 1 ||
								(formik.values.tipoSolicitante === 3 &&
									solicitante !== 'responsavel') ||
								(formik.values.tipoSolicitante === 2 &&
									solicitante === 'responsavel')
							}
							as={Select}
							title="UF"
							name={`${solicitante}.endereco.uf`}
							options={data.uf}
							onChange={(v: string) =>
								formik.setFieldValue(`${solicitante}.endereco.uf`, v)
							}
							error={hasError(formik.errors, `${solicitante}.endereco.uf`)}
							disabled={formDisabled}
						/>
					)}

					{step === 2 && (
						<Field
							readOnly
							as={Input}
							title="UF"
							name={`${solicitante}.endereco.uf`}
							error={hasError(formik.errors, `${solicitante}.rg.uf`)}
							disabled={formDisabled}
						/>
					)}
				</Col>
			</Row>
			<Row gutter={[10, 10]}>
				<Col span={16}>
					<Field
						maxLength={150}
						required={
							formik.values.tipoSolicitante === 1 ||
							(formik.values.tipoSolicitante === 3 &&
								solicitante !== 'responsavel') ||
							(formik.values.tipoSolicitante === 2 &&
								solicitante === 'responsavel')
						}
						title="E-mail"
						name={`${solicitante}.email`}
						type="text"
						as={Input}
						error={hasError(formik.errors, `${solicitante}.email`)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col>
			</Row>

			<Row gutter={[10, 10]}>
				<Col span={8}>
					<Field
						title="Telefone"
						name={`${solicitante}.telefones.fixo`}
						type="text"
						mask="(99) 9999-9999"
						as={InputMask}
						error={hasError(formik.errors, `${solicitante}.telefones.fixo`)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col>
				<Col span={8}>
					<Field
						title="Celular"
						name={`${solicitante}.telefones.celular`}
						type="text"
						mask="(99) 99999-9999"
						as={InputMask}
						error={hasError(formik.errors, `${solicitante}.telefones.celular`)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col>
				<Col span={8}>
					<Field
						title="Tel Comercial"
						name={`${solicitante}.telefones.comercial`}
						type="text"
						mask="(99) 9999-9999"
						as={InputMask}
						error={hasError(
							formik.errors,
							`${solicitante}.telefones.comercial`,
						)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col>
			</Row>
			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default DadosSolicitante;
