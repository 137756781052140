// import { IRequestGerarPreCadastro } from 'store/modules/api/detranCnh/primeiraHabilitacao/gerarPreCadastro/types';
import * as Yup from 'yup';

// UTILS
import {
	unformatDate,
	limparMascara,
	formatDate,
	formatDateToString,
	removeAcentoCaracteresEspeciais,
	verifyStringIsAnInvalidPhrase,
	onlyLetters,
} from 'utils/genericFunctions';
import { trimValues } from 'utils/stringFunctions';
import {
	sameThreeCharactersInSequenceNotAllowedRegex,
	userDataRegex,
} from 'utils/constants/regex';
import { socialNameAndFiliationsPhrasesNotAllowed } from 'utils/constants/types';

// STORES
import { ConsultaPrimeiraHabilitacaoData } from 'store/modules/api/detranCnh/primeiraHabilitacao/consultaPrimeiraHabilitacao/types';
import { GerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/types';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { EventoContabilizacao } from 'store/modules/types';

export interface FormPrimeiraHabilitacao {
	cpf: string;
	autoriza_email: string;
	autoriza_sms: string;
	bairro: string;
	categoriaPretendida: string;
	celular: string;
	cep: string;
	codEntidade: string;
	complemento: string;
	dataNascimento: string;
	email: string;
	// LOGRADOURO
	logradouro: string;
	logradouroCodMunicipio: string;

	nacionalidade: string;
	naturalidade: string | number;
	necessidade_veiculo_adaptado: string;
	nome: string;
	nomeSocial: string;
	nomeMae: string;
	nomePai: string;
	numero_carteira_militar: string;
	numero_documento: string;
	numero: string;
	orgao_documento: string;
	pretende_atividade_remunerada: string | boolean;
	sexo: string;
	telefone: string;
	tipo_documento: string;
	uf_documento: string;
	num_RNE: string;
	orgao_RNE: string;
	uf_RNE: string;
	status: string;
	processo: string;
	dataCadastro: string;
	confirmaEmail: string;
	municipio: string;
	militar: string;
	exame_dentro_do_posto: string;
	codigoUnidadeTransito: number;
	enderecoRecebimentoBairro?: string;
	enderecoRecebimentoCep?: string;
	enderecoRecebimentoLogradouro?: string;
	enderecoRecebimentoMunicipio?: string;
	enderecoRecebimentoCodigoMunicipio?: string;
	enderecoRecebimentoNumero?: string;
	enderecoRecebimentoComplemento?: string;
	flagNomeSocial?: string;
	flagFiliacaoAfetiva?: string;
	autorizaSMS?: string;
	autorizaEmail?: string;
	flagDeficienteFisico?: string;
	flagAtividadeRemunerada?: string;
	paisAfetivo1: string;
	paisAfetivo2: string;
	origemNomeSocial: string;
}

export const initialValues: FormPrimeiraHabilitacao = {
	cpf: '',
	autoriza_email: 'N',
	autoriza_sms: 'N',
	bairro: '',
	categoriaPretendida: '',
	celular: '',
	cep: '',
	codEntidade: '',
	complemento: '',
	dataNascimento: '',
	email: '',
	logradouro: '',
	logradouroCodMunicipio: '',
	nacionalidade: '1',
	naturalidade: '',
	necessidade_veiculo_adaptado: '',
	nome: '',
	nomeMae: '',
	nomePai: '',
	nomeSocial: '',
	numero_carteira_militar: '',
	numero_documento: '',
	numero: '',
	orgao_documento: '',
	pretende_atividade_remunerada: '',
	sexo: 'M',
	telefone: '',
	tipo_documento: 'CARTEIRA_IDENTIDADE',
	uf_documento: '',
	num_RNE: '',
	orgao_RNE: '',
	uf_RNE: '',
	status: '',
	processo: '',
	dataCadastro: formatDateToString(new Date()),
	confirmaEmail: '',
	municipio: '',
	militar: 'N',
	exame_dentro_do_posto: 'N',
	codigoUnidadeTransito: 0,
	paisAfetivo1: '',
	paisAfetivo2: '',
	origemNomeSocial: '0',
};

// TREAT TO MOUNT FORM
export function treatInitialValues(
	values: ConsultaPrimeiraHabilitacaoData | null,
	uf: string,
): FormPrimeiraHabilitacao {
	// ENDEREÇO
	if (values?.dadosPcad) {
		const {
			logradouro,
			numero,
			bairro,
			cep,
			complemento,
			descricaoMunicipio,
			codigoMunicipio,
		} = values.dadosPcad.endereco;

		// DADOS PRÉ-CADASTRO
		const {
			cpf,
			categoriaPretendida,
			dataNascimento,
			nome,
			nomeMae,
			nomePai,
			numRg,
			orgaoEmissor,
			localEmissao,
			processo,
			codNaturalidade,
			naturalidade,
			nacionalidade,
			rne,
			orgaoEmissorRne,
			localEmissaoRne,
			sexo,
		} = values.dadosPcad;

		const { codEntidade } = values;

		const formattedData = {
			...initialValues,
			// --- DADOS PRIMEIRA HABILITAÇÃO --- //
			cpf,
			bairro: trimValues(bairro) || '',
			categoriaPretendida: trimValues(categoriaPretendida) || '',
			cep,
			codEntidade,
			complemento,
			dataNascimento: formatDate(dataNascimento) || '',
			email: '',
			logradouro,
			nacionalidade,
			naturalidade,
			codNaturalidade: Number(trimValues(codNaturalidade)),
			uf,
			necessidade_veiculo_adaptado: '',
			nome: trimValues(nome) || '',
			nomeMae: trimValues(nomeMae) || '',
			nomePai: trimValues(nomePai) || '',
			numero_carteira_militar: '',
			numero_documento: trimValues(numRg) || '',
			numero,
			orgao_documento: trimValues(orgaoEmissor) || '',
			pretende_atividade_remunerada:
				trimValues(categoriaPretendida) === 'A'
					? false
					: values.atividadeRemunerada || '',
			sexo,
			tipo_documento: 'CARTEIRA_IDENTIDADE',
			uf_documento: localEmissao,
			num_RNE: trimValues(rne) || '',
			orgao_RNE: trimValues(orgaoEmissorRne) || '',
			uf_RNE: trimValues(localEmissaoRne) || '',
			status: '',
			processo,
			celular: '',
			telefone: '',
			confirmaEmail: '',
			municipio: trimValues(descricaoMunicipio) || '',
			logradouroCodMunicipio: trimValues(codigoMunicipio) || '',
			militar: 'N',
		};
		return formattedData;
	}
	return initialValues;
}

// TREAT TO SEND FORM
export function treatValues(
	evento: EventoContabilizacao,
	values: FormPrimeiraHabilitacao,
	user: IUser,
): GerarRenachIntegradoRequest {
	const formattedData = {
		candidato: {
			apresentaDocumentos: '',
			autorizaEmail: values.autoriza_email || '',
			autorizaSMS: values.autoriza_sms || '',
			bairro:
				(values.bairro &&
					removeAcentoCaracteresEspeciais(values.bairro.toUpperCase())) ||
				'',
			categoriaAtual: '',
			categoriaPretendida:
				values.categoriaPretendida && values.categoriaPretendida?.trim(),
			codigoUnidadeAtendimento: user.posto,
			celular: limparMascara(values.celular).substring(2, 11),
			cep: values.cep || '',
			cnhDigital: 'N',
			codigoCiretranPostoExameMedico:
				values.exame_dentro_do_posto === 'S' ? user.posto : '',
			codigoEntidade: values.codEntidade || '',
			codigoVerificador: '',
			coletaBiometrica: '',
			complemento:
				(values.complemento &&
					removeAcentoCaracteresEspeciais(values.complemento)) ||
				'',
			cpf: values.cpf,
			dataNascimento:
				values.dataNascimento && unformatDate(values.dataNascimento),
			dataPrimeiraHabilitacao: '',
			dataValidadeCnhE: '' || '',
			dddCelular: limparMascara(values.celular).substring(0, 2),
			dddTelefone: limparMascara(values.telefone).substring(0, 2),
			email: values.email || '',
			logradouro:
				values.logradouro &&
				removeAcentoCaracteresEspeciais(values.logradouro).toUpperCase(),
			nacionalidade: values.nacionalidade,
			naturalidade: values.naturalidade ? values.naturalidade?.toString() : '',
			necessidadeVeiculoAdaptado:
				values.necessidade_veiculo_adaptado === 'S' ? 'S' : 'N',
			nome:
				removeAcentoCaracteresEspeciais(values.nome.toUpperCase())?.trim() ||
				'',
			nomeMae:
				removeAcentoCaracteresEspeciais(values.nomeMae.toUpperCase())?.trim() ||
				'',
			nomePai:
				removeAcentoCaracteresEspeciais(values.nomePai.toUpperCase())?.trim() ||
				'',
			nomeSocial:
				values.flagNomeSocial === 'S'
					? removeAcentoCaracteresEspeciais(
							values.nomeSocial ? values.nomeSocial.toUpperCase() : '',
					  )?.trim() || ''
					: '',
			nomeSocialValidadoBiometria: '',
			paisAfetivos1:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo1 ? values.paisAfetivo1.toUpperCase() : '',
					  )?.trim() || ''
					: '',
			paisAfetivos1ValidadoBiometria: '',
			paisAfetivos2:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo2 ? values.paisAfetivo2.toUpperCase() : '',
					  )?.trim() || ''
					: '',
			paisAfetivos2ValidadoBiometria: '',
			numCNHE: '',
			numCarteiraMilitar: values.numero_carteira_militar || '',
			numDocumento:
				(values.numero_documento &&
					limparMascara(values.numero_documento.trim())) ||
				'',
			numPGU: '',
			numRNE: values.num_RNE || '',
			numRegistro: '',
			numero: values.numero !== 'S/N' ? String(values.numero) : '',
			orgaoDocumento: values.orgao_documento
				? onlyLetters(values.orgao_documento.toLocaleUpperCase())
				: '',
			orgaoRNE: values.orgao_RNE || '',
			paisCNHE: '',
			pretendeAtividadeRemunerada:
				values.categoriaPretendida !== 'A' &&
				values.pretende_atividade_remunerada
					? 'S'
					: 'N' || 'N',
			sexo: values.sexo,
			telefone: limparMascara(values.telefone).substring(2, 10),
			testeAlfabetizacao: '',
			tipoDocumento: '1',
			tipoProcesso: '1',
			uf: '',
			ufDocumento: values.uf_documento || '',
			ufRNE: values.uf_RNE || '',
			origemNomeSocial:
				(values.flagNomeSocial === 'S' && values.origemNomeSocial) || '',
		},
		dadosExameMedico: {
			codigoCiretranExameMedico: '',
			dataExame: '',
			horarioExame: '',
			idMedico: '',
			idMicroRegiaoMedico: '',
		},
		dadosExamePsicologo: {
			codigoCiretranExamePsicologo: '',
			dataExamePsicologico: '',
			horarioExamePsicologico: '',
			idMicroRegiaoPsicologo: '',
			idPsicologo: '',
		},
		gerarRenachBase: {
			evento: {
				id_atendimento: evento.id_atendimento,
				id_cidadao: evento.id_cidadao,
				ip: evento.ip,
				identificacao: evento.cpf && parseInt(limparMascara(values.cpf), 10),
				cpf: evento.cpf || '',
				canal: {
					id: 1,
					desc: 'Presencial',
					localidade: {
						desc: evento.canal?.localidade.desc || '',
						id: evento.canal?.localidade.id || 0,
					},
				},
			},
			usoFormularioEnum: 'PRIMEIRA_HABILITACAO',
			cpf: values.cpf,
			nome:
				removeAcentoCaracteresEspeciais(values.nome.toUpperCase())?.trim() ||
				'',
			nomeSocial:
				values.flagNomeSocial === 'S'
					? removeAcentoCaracteresEspeciais(
							values.nomeSocial.toUpperCase(),
					  )?.trim() || ''
					: '',
			nomeMae:
				removeAcentoCaracteresEspeciais(values.nomeMae.toUpperCase())?.trim() ||
				'',
			nomePai:
				removeAcentoCaracteresEspeciais(values.nomePai.toUpperCase())?.trim() ||
				'',
			sexo: values.sexo,
			dataNascimento: unformatDate(values.dataNascimento),
			tipoDocumentoEnum: values.tipo_documento,
			numDocumento:
				(values.numero_documento &&
					limparMascara(values.numero_documento.trim())) ||
				'',
			orgaoExpDocumento: values.orgao_documento
				? onlyLetters(values.orgao_documento.toLocaleUpperCase())
				: '',
			orgaoUfExpDocumento: values.uf_documento,
			nacionalidade: values.nacionalidade,
			codigoNaturalidade: values.naturalidade,
			naturalidade: values.naturalidade ? values.naturalidade?.toString() : '',
			email: values.email,
			celularDDD: limparMascara(values.celular).substring(0, 2),
			celular: limparMascara(values.celular).substring(2, 11),
			logradouro: values.logradouro,
			logradouroNumero: String(Number(values.numero)),
			logradouroComplemento: values.complemento,
			logradouroBairro: values.bairro,
			logradouroCodMunicipio: values.logradouroCodMunicipio,
			logradouroCep: values.cep,
			codCiretranCondutor: values.codigoUnidadeTransito.toString(),
			statusReusoEnum: 'SEM_REUSO',
			flagAtividadeRemunerada:
				values.categoriaPretendida !== 'A' &&
				values.pretende_atividade_remunerada
					? 'S'
					: 'N' || 'N',
			flagDeficienteFisico: values.necessidade_veiculo_adaptado === 'S',
			flagSolicitaCNHDigital: true,
			codigoVerificador: null,
			categoriaAtual: '',
			categoriaPretendida: trimValues(values.categoriaPretendida) || '',
			registro: null,
			numeroPgu: null,
			rneNumero: trimValues(values.num_RNE) || '',
			rneOrgao: values.orgao_RNE ? onlyLetters(values.orgao_RNE.trim()) : '',
			rneUf: trimValues(values.uf_RNE) || '',
			rneCodPaisCnhEstrangeira: null,
			rneDataValidadeCnhEstrangeira: null,
			rneDataPrimeiraHabilitacaoCnhEstrangeira: null,
			rneIdentificacaoCnhEstrangeira: null,
			flagMilitar: false,
			codEntidadeMilitar: values.codEntidade,
			numeroIdentMilitar: values.numero_carteira_militar,
			flagExamePosto: values.exame_dentro_do_posto === 'S',
			flagExameToxicologico: false,
			flagProcurador: false,
			codCiretranHabilitacao: values.codigoUnidadeTransito.toString(),
			cpfUsuario: user.cpf,
			codLocalidade: user.posto,
			paisAfetivo1:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo1.toUpperCase(),
					  )?.trim() || ''
					: '',
			paisAfetivo2:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo2.toUpperCase(),
					  )?.trim() || ''
					: '',
		},
	};

	return formattedData;
}

export const schema = Yup.object<FormPrimeiraHabilitacao>().shape(
	{
		categoriaPretendida: Yup.string().required(
			'Campo Obrigatório: Categoria Pretendida.',
		),
		pretende_atividade_remunerada: Yup.string().required(
			'Campo Obrigatório: Pretende exercer atividade remunerada.',
		),
		necessidade_veiculo_adaptado: Yup.string(),
		militar: Yup.string().required(
			'Campo Obrigatório: É militar e vai utilizar o CFC da corporação.',
		),
		nome: Yup.string().required('Campo Obrigatório: Nome.'),
		flagNomeSocial: Yup.string().required(
			'Campo Obrigatório: Deseja incluir nome social na CNH?.',
		),
		nomeSocial: Yup.string()
			.when(['flagNomeSocial'], {
				is: flagNomeSocial => flagNomeSocial === 'S',
				then: Yup.string().required('Campo Obrigatório: Nome Social.'),
			})
			.test(
				'same-name',
				'O nome social não pode ser igual ao nome civil.',
				function verify(value) {
					return value !== '' && this.parent.flagNomeSocial === 'S'
						? this.parent.nome !== value
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-social-name',
				"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		nomeMae: Yup.string().required('Campo Obrigatório: Nome da Mãe.'),
		flagFiliacaoAfetiva: Yup.string().required(
			'Campo Obrigatório: Deseja incluir filiação afetiva na CNH?.',
		),
		paisAfetivo1: Yup.string()
			.when(['flagFiliacaoAfetiva'], {
				is: flagFiliacaoAfetiva => flagFiliacaoAfetiva === 'S',
				then: Yup.string().required('Campo Obrigatório: Filiação Afetiva 1.'),
			})
			.test(
				'same-name-filiacoes',
				'O nome da filiação afetiva 1 não pode ser igual ao nome da filiação afetiva 2.',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? this.parent.paisAfetivo2 !== value
						: true;
				},
			)
			.test(
				'same-name-filiacao1',
				'A filiação afetiva 1 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-1',
				"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		paisAfetivo2: Yup.string()
			.test(
				'same-name-filiacao2',
				'A filiação afetiva 2 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-2',
				"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		sexo: Yup.string().required('Campo Obrigatório: Sexo.'),
		dataNascimento: Yup.string().required(
			'Campo Obrigatório: Data de Nascimento.',
		),
		nacionalidade: Yup.string().required('Campo Obrigatório: Nacionalidade.'),
		naturalidade: Yup.string().required('Campo Obrigatório: Naturalidade.'),
		numero_documento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade !== '3',
			then: Yup.string().required('Campo Obrigatório: RG.'),
		}),
		orgao_documento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade !== '3',
			then: Yup.string().required('Campo Obrigatório: Orgão Emissor.'),
		}),
		num_RNE: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: RNE.'),
		}),
		orgao_RNE: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor RNE.'),
		}),
		uf_documento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade !== '3',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),
		cep: Yup.string().required('Campo Obrigatório: CEP.'),
		logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
		numero: Yup.string().required('Campo Obrigatório: Número.'),
		bairro: Yup.string().required('Campo Obrigatório: Bairro.'),
		municipio: Yup.string().required('Campo Obrigatório: Município.'),
		telefone: Yup.string()
			.when(['celular'], {
				is: celular => celular === '',
				then: Yup.string().required('Campo Obrigatório: Telefone.'),
			})
			.matches(userDataRegex.phoneNumber, {
				message: 'Campo Inválido: Telefone.',
				excludeEmptyString: true,
			}),
		celular: Yup.string()
			.when(['telefone'], {
				is: telefone => telefone === '',
				then: Yup.string().required('Campo Obrigatório: Celular.'),
			})
			.when(['autorizaSMS'], {
				is: autorizaSMS => autorizaSMS === 'S' || autorizaSMS === true,
				then: Yup.string().required('Campo Obrigatório: Celular.'),
			})
			.matches(userDataRegex.cellPhoneNumber, {
				message: 'Campo Inválido: Celular.',
				excludeEmptyString: true,
			}),
		email: Yup.string()
			.when(['autoriza_email'], {
				is: autoriza_email => autoriza_email === 'S' || autoriza_email === true,
				then: Yup.string().required('Campo Obrigatório: Email.'),
			})
			.matches(userDataRegex.emailAddressUpdated, {
				message: 'Formato de e-mail inválido.',
				excludeEmptyString: true,
			})
			.test(
				'same-email',
				'A confirmação do e-mail é diferente do e-mail informado.',
				function verify(value) {
					return value !== '' ? this.parent.confirmaEmail === value : true;
				},
			),
		enderecoRecebimentoCep: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - CEP.',
		),
		enderecoRecebimentoLogradouro: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Logradouro.',
		),
		enderecoRecebimentoNumero: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Número.',
		),
		enderecoRecebimentoBairro: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Bairro.',
		),
		enderecoRecebimentoMunicipio: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Município.',
		),
	},
	[['telefone', 'celular']],
);
