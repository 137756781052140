import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_ESPECIE_REQUEST = '@DETRAN-CRV/CONSULTA_ESPECIE_REQUEST',
	CONSULTA_ESPECIE_SUCCESS = '@DETRAN-CRV/CONSULTA_ESPECIE_SUCCESS',
	CONSULTA_ESPECIE_FAILURE = '@DETRAN-CRV/CONSULTA_ESPECIE_FAILURE',
}

export interface consultaEspecie {
	status: number;
	data: OptionProps[];
}
