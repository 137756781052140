import { combineReducers } from 'redux';

import atualizarMensagem from './atualizarMensagem/reducer';
import cadastrarMensagem from './cadastrarMensagem/reducer';
import consultarMensagens from './consultarMensagens/reducer';
import excluirMensagem from './excluirMensagem/reducer';

export default combineReducers({
	atualizarMensagem,
	cadastrarMensagem,
	consultarMensagens,
	excluirMensagem,
});
