export enum Types {
	CONSULTA_AGENDAMENTOS_REQUEST = '@cnh/CONSULTA_AGENDAMENTOS_REQUEST',
	CONSULTA_AGENDAMENTOS_SUCCESS = '@cnh/CONSULTA_AGENDAMENTOS_SUCCESS',
	CONSULTA_AGENDAMENTOS_FAILURE = '@cnh/CONSULTA_AGENDAMENTOS_FAILURE',
	CONSULTA_AGENDAMENTOS_CLEAR = '@cnh/CONSULTA_AGENDAMENTOS_CLEAR',
}

export interface ConsultaAgendamentos {
	status: number;
	data: ConsultaAgendamentosData | null;
}

interface ConsultaAgendamentosData {
	medico: {
		categoriaPretendida: string;
		medico: string;
		crm: number;
		endereco: string;
		telefone: string;
		data: string;
		hora: string;
	};
	psicologo: {
		categoriaPretendida: string;
		psicologo: string;
		crp: number;
		endereco: string;
		telefone: string;
		data: string;
		hora: string;
	};
	cidadao: {
		nome: string;
		cpf: number;
		servico: string;
	};
}

export interface ConsultaAgendamentosRequest {
	cpf: string;
}
