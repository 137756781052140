import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { CHANNEL, PATH_DARE } from 'services/_path';

// ACTIONS
import { cadastroRgVincularDare } from 'store/modules/api/iirgd/cadastroRg/actions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	IRequestAtualizarDare,
	atualizarDareSuccess,
	atualizarDareFailure,
} from './actions';

function* atualizarDareRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestAtualizarDare } = request;

	const { dare } = payload;
	delete payload.dare;

	const response: ApiResponse = yield call(
		putApi,
		PATH_DARE,
		`dare/${CHANNEL}/cili`,
		payload,
	);

	if (response.status === 201 || response.status === 200) {
		yield put(atualizarDareSuccess(response.data));
		yield put(cadastroRgVincularDare({ ...response.data, ...dare }));
	} else if (response.error) {
		yield put(atualizarDareFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_DARE_REQUEST, atualizarDareRequest),
]);
