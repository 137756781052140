export enum Types {
	SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_REQUEST = '@procon/SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_REQUEST',
	SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_SUCCESS = '@procon/SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_SUCCESS',
	SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_FAILURE = '@procon/SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_FAILURE',
	SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_CLEAR = '@procon/SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_CLEAR',
}

export interface SinalizaResolucaoConsultaAtendimento {
	status: null | number;
	data: any;
	consulta: null | SinalizaResolucaoConsultaAtendimentoRequest;
}

export interface SinalizaResolucaoConsultaAtendimentoRequest {
	atendimentoId: string;
	justificativa: string;
}

export interface SinalizaResolucaoConsultaAtendimentoForm {
	atendimentoId: string;
	justificativa: string;
}
