import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaUsuariosMainframe } from './types';

export const INITIAL_STATE: ConsultaUsuariosMainframe = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaUsuariosMainframe {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_USUARIOS_MAINFRAME_REQUEST: {
				break;
			}
			case Types.CONSULTA_USUARIOS_MAINFRAME_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTA_USUARIOS_MAINFRAME_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CONSULTA_USUARIOS_MAINFRAME_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
