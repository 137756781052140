import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';

import { Col, Row } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import {
	consultaDocumentoInfracaoClear,
	consultaDocumentoInfracaoRequest,
} from 'store/modules/api/prefeituras/jalesTransito/documentoInfracao/actions';
import { consultaInfracoesClear } from 'store/modules/api/prefeituras/jalesTransito/infracoes/actions';

// FORM
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import SimpleTable from 'components/Common/Table';

import { ButtonTable } from './styled';

const ListaInfracoes = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [tableData, setTableData] = useState<any>([]);

	const { infracoes, documentoInfracao } = useSelector(
		(state: ApplicationState) => state.api.prefeituras.jalesTransito,
	);

	const handleOnClick = useCallback(
		(values: string) => {
			dispatch(consultaDocumentoInfracaoRequest(values));
		},
		[dispatch],
	);

	const handleOnReturn = useCallback(() => {
		dispatch(consultaDocumentoInfracaoClear());
		dispatch(consultaInfracoesClear());
	}, [dispatch]);

	const headers = [
		{
			title: 'Código AIT',
			dataIndex: 'codigoAit',
			align: 'center',
			render: (text: string) => {
				return (
					<ButtonTable type="button" onClick={() => handleOnClick(text)}>
						<span>{text}</span>
					</ButtonTable>
				);
			},
		},
	];

	useEffect(() => {
		if (documentoInfracao.status === 200) {
			history.push(`/prefeitura/jales/consulta/infracoes`);
		}
	}, [history, documentoInfracao.status]);

	useEffect(() => {
		if (infracoes.data) {
			const data = infracoes.data.map((row: any) => {
				return { key: v4(), ...row };
			});

			setTableData(data);
		}
	}, [infracoes.data]);

	return (
		<>
			<Section title="Infrações">
				<Row gutter={[0, 16]}>
					<Col span={24}>
						<SimpleTable
							headers={headers}
							body={tableData}
							striped
							pagination={false}
						/>
					</Col>
				</Row>
			</Section>
			<ButtonVoltar onClick={handleOnReturn} />
		</>
	);
};

export default ListaInfracoes;
