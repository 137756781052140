import { IConsultarCpfState } from './consultarCpf/types';
import { EmitirCinState } from './emitirCin/types';
import { CadastrarCinState } from './cadastrarCin/types';
import { ConsultarConsistenciaBiometricaState } from './consistenciaBiometrica/types';
import { ConsultarCpfCinState } from './consultarCin/types';
import { ConsultarProcessoState } from './consultarProcesso/types';

export enum Types {
	RFB_CLEAR = '@rfb/RFB_CLEAR',
}

export interface RfbState {
	consultarCpf: IConsultarCpfState;
	consultarProcesso: ConsultarProcessoState;
	emitirCin: EmitirCinState;
	cadastrarCin: CadastrarCinState;
	consistenciaBiometrica: ConsultarConsistenciaBiometricaState;
	consultarCin: ConsultarCpfCinState;
}
