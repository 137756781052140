import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// REDUX
import { ApplicationState } from 'store';
import {
	consultaCondutorRequest,
	consultaCondutorClear,
} from 'store/modules/api/detranCnh/condutor/consultaCondutor/actions';

// COMPONENTS
import RenachAlterado from 'pages/DetranCnh/components/pages/RenachAlterado';

interface CustomizedLocationState {
	cpf: string;
}

const RenachAlterarRenach: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const historyState = history.location.state as CustomizedLocationState;

	useEffect(() => {
		if (historyState?.cpf) {
			dispatch(consultaCondutorRequest({ cpf: historyState.cpf }));
		}
	}, [dispatch, historyState]);

	useEffect(() => {
		dispatch(consultaCondutorClear());
	}, [dispatch]);

	const { data } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor.consultaCondutor,
	);

	const renach = {
		nome:
			(data?.nomeSocial && data.nomeSocial.trim()) ||
			(data?.nome && data.nome.trim()) ||
			'',
		renach: (data?.renach && data.renach.trim()) || '',
		cpf: (data?.cpf && data.cpf.trim()) || '',
		usoFormularioEnum: (data?.usoFormulario && data.usoFormulario.trim()) || '',
		flagAtividadeRemunerada: data?.pretendeAtividadeRemunerada === 'S',
	};

	return <RenachAlterado renderData={renach} />;
};

export default RenachAlterarRenach;
