import React from 'react';
import { Row, Col } from 'antd';
import icon_senha from 'assets/images/icons/icon_senha.png';

import { Container, Header, Icon, Title } from './styled';

interface ColorBoxProps {
	title?: boolean;
	header?: boolean;
	successTitle?: string;
	bgColor?: 'branco' | 'cinza' | 'verde' | 'azul' | 'vermelho';
	border?: boolean;
	minHeight?: 'unset' | number;
	type?: 'loading' | 'success' | 'error';
}

const ColorBox: React.FC<ColorBoxProps> = ({
	title = false,
	header = false,
	successTitle = 'Informações salvas com sucesso!',
	bgColor = 'branco',
	children,
	minHeight = 'unset',
	type = 'success',
}) => {
	return (
		<Row>
			<Col span={24}>
				<Container bgColor={bgColor} minHeight={minHeight}>
					{header && (
						<Row justify="center">
							<img
								src={icon_senha}
								alt="icone de cadeado"
								style={{ marginBottom: '10px' }}
							/>
						</Row>
					)}

					{title && (
						<Header>
							<Icon type={type} />
							<Title type={type}>{successTitle}</Title>
						</Header>
					)}

					{children}
				</Container>
			</Col>
		</Row>
	);
};

export default ColorBox;
