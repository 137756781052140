import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IAlterarObservacoesState } from './types';

export const INITIAL_STATE: IAlterarObservacoesState = {
	status: 0,
	temp: null,
	cpfCadastrado: false,
	mensagemCPF: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IAlterarObservacoesState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ALTERAR_OBSERVACOES_VALUES_TEMP: {
				draft.temp = action.payload;
				break;
			}

			case Types.ALTERAR_OBSERVACOES_REQUEST: {
				break;
			}
			case Types.ALTERAR_OBSERVACOES_SUCCESS: {
				draft.status = action.payload.status;
				if (action.payload.cpfCadastrado) {
					draft.cpfCadastrado = action.payload.cpfCadastrado;
				}
				if (action.payload?.mensagemCPF) {
					draft.mensagemCPF = action.payload.mensagemCPF;
				}

				break;
			}
			case Types.ALTERAR_OBSERVACOES_FAILURE: {
				draft.status = INITIAL_STATE.status;
				if (action.payload?.mensagemCPF) {
					draft.mensagemCPF = action.payload.mensagemCPF;
				}
				break;
			}

			case Types.ALTERAR_OBSERVACOES_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.temp = INITIAL_STATE.temp;
				break;
			}

			default:
		}
		return draft;
	});
}
