/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Field, FormikProps } from 'formik';
import { Col, Row } from 'antd';

import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import Select from 'components/Common/Form/Select';
import RG from 'components/Common/Form/Fields/RG';

import dataEnum from 'store/modules/api/educacaoUpdate/enum';

import FormBox from 'components/Common/Form/FormBox';
import { IVisualizarFicha } from '../form';

import { ContainerFichaCadastral } from '../styled';
import { Container, Divider } from './styled';

interface Props {
	formik: FormikProps<IVisualizarFicha>;
}

const Documentos: React.FC<Props> = ({ formik }) => {
	return (
		<ContainerFichaCadastral>
			<FormBox title="Documentos">
				<Row gutter={[0, 20]}>
					<Col span={12}>
						<Field
							title="CPF"
							name="documentos.cpf"
							mask="999.999.999-99"
							as={InputMask}
							disabled
						/>
					</Col>

					<Col span={12}>
						<Field
							as={Input}
							title="NIS"
							name="documentos.numeroNIS"
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={12}>
						<RG
							title="Documento Civil RG"
							numero="documentos.numeroDocumentoCivil"
							defaultNumero={
								formik.initialValues.documentos.numeroDocumentoCivil
							}
							formik={formik}
							result={rg => {
								formik.setFieldValue(
									'documentos.numeroDocumentoCivil',
									rg.numeroRg,
								);
							}}
							disabled
						/>
					</Col>

					<Col span={12}>
						<Field
							as={Select}
							title="UF RG"
							name="documentos.ufDocumentoCivil"
							options={dataEnum.comboUF}
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={12}>
						<Field
							as={ValidDataInput}
							title="Data Emissão RG/RNE"
							name="documentos.dataEmissaoDocumentoCivil"
							mask="99/99/9999"
							type="text"
							formik={formik}
							disabled
						/>
					</Col>
					<Col span={12}>
						<Field
							title="Data Entrada no Brasil"
							name="dadosPessoais.dataEntradaPais"
							type="text"
							mask="99/99/9999"
							as={ValidDataInput}
							formik={formik}
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={Select}
							title="Tipo de Certidão"
							name="documentos.tipoCertidao"
							value={formik.values.documentos.tipoCertidao}
							options={dataEnum.tipoCertidao}
							disabled
						/>
					</Col>
					{formik.values.documentos.tipoCertidao === 'ANTIGA' && (
						<Col span={12}>
							<Field
								title="Data de emissão"
								name="documentos.dataEmissaoCertidao"
								type="text"
								mask="99/99/9999"
								as={ValidDataInput}
								formik={formik}
								titleAlign="end"
								disabled
							/>
						</Col>
					)}
				</Row>
				{formik.values.documentos.tipoCertidao === '' ? (
					''
				) : formik.values.documentos.tipoCertidao === 'NOVA' ? (
					<>
						<Row gutter={[0, 20]}>
							<Container>
								<Col span={5}>
									<Field
										as={Input}
										title="Matricula"
										name="certidaoNova.certificadoMatricula01"
										disabled
									/>
								</Col>
								<Col span={1}>
									<Field
										as={Input}
										name="certidaoNova.certificadoMatricula02"
										disabled
									/>
								</Col>
								<Col span={1}>
									<Field
										as={Input}
										name="certidaoNova.certificadoMatricula03"
										disabled
									/>
								</Col>
								<Col span={2}>
									<Field
										as={Input}
										name="certidaoNova.certificadoMatricula04"
										disabled
									/>
								</Col>
								<Col span={1}>
									<Field
										as={Input}
										name="certidaoNova.certificadoMatricula05"
										disabled
									/>
								</Col>
								<Col span={2}>
									<Field
										as={Input}
										name="certidaoNova.certificadoMatricula06"
										disabled
									/>
								</Col>
								<Col span={1}>
									<Field
										as={Input}
										name="certidaoNova.certificadoMatricula07"
										disabled
									/>
								</Col>
								<Col span={2}>
									<Field
										as={Input}
										name="certidaoNova.certificadoMatricula08"
										disabled
									/>
								</Col>
								<Divider />
								<Col span={2}>
									<Field
										as={Input}
										name="certidaoNova.certificadoMatricula09"
										disabled
									/>
								</Col>
							</Container>
						</Row>
					</>
				) : (
					<div>
						<Row gutter={[0, 20]}>
							<Col span={12}>
								<Field
									as={Input}
									title="Número"
									name="certidaoAntiga.numeroCertidao"
									disabled
								/>
							</Col>
							<Col span={12}>
								<Field
									as={Input}
									title="Folha"
									name="certidaoAntiga.numeroFolhaRegistro"
									disabled
								/>
							</Col>
						</Row>
						<Row gutter={[0, 20]}>
							<Col span={12}>
								<Field
									as={Input}
									title="Livro"
									name="certidaoAntiga.numeroLivroRegistro"
									disabled
								/>
							</Col>
							<Col span={12}>
								<Field
									as={Input}
									title="Distrito Certidão Nascimento"
									name="certidaoAntiga.distritoNascimento"
									disabled
								/>
							</Col>
						</Row>
						<Row gutter={[0, 20]}>
							<Col span={12}>
								<Field
									as={Input}
									title="Município da Comarca"
									name="certidaoAntiga.nomeMunicipioComarca"
									disabled
								/>
							</Col>
							<Col span={12}>
								<Field
									as={Select}
									title="UF da Comarca"
									name="certidaoAntiga.ufComarca"
									options={dataEnum.comboUF}
									disabled
								/>
							</Col>
						</Row>
					</div>
				)}
			</FormBox>
		</ContainerFichaCadastral>
	);
};

export default Documentos;
