// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	Types,
	RequestEmissaoAtestadoLiberacaoPositiva,
	EmissaoAtestadoLiberacaoPositiva,
} from './types';

export function emissaoAtestadoLiberacaoPositivaRequest(
	payload: RequestEmissaoAtestadoLiberacaoPositiva,
): ReducerAction {
	return {
		type: Types.EMISSAO_ATESTADO_LIBERACAO_POSITIVA_REQUEST,
		payload,
	};
}

export function emissaoAtestadoLiberacaoPositivaSuccess(
	payload: EmissaoAtestadoLiberacaoPositiva,
): ReducerAction {
	return {
		type: Types.EMISSAO_ATESTADO_LIBERACAO_POSITIVA_SUCCESS,
		payload,
	};
}

export function emissaoAtestadoLiberacaoPositivaFailure(): ReducerAction {
	return {
		type: Types.EMISSAO_ATESTADO_LIBERACAO_POSITIVA_FAILURE,
		payload: null,
	};
}

export function emissaoAtestadoLiberacaoPositivaClear(): ReducerAction {
	return {
		type: Types.EMISSAO_ATESTADO_LIBERACAO_POSITIVA_CLEAR,
		payload: null,
	};
}
