import produce from 'immer';
// TYPES
import { Types, gerarRenach } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: gerarRenach = {
	status: 0,
	data: null,
};

export default function gerarRenachReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): gerarRenach {
	return produce(state, draft => {
		switch (action.type) {
			case Types.GERAR_RENACH_TRANSFERENCIA_MUNICIPAL: {
				break;
			}

			case Types.GERAR_RENACH_TRANSFERENCIA_MUNICIPAL_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.GERAR_RENACH_TRANSFERENCIA_MUNICIPAL_FAILURE: {
				break;
			}

			default:
		}
	});
}
