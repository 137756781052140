import styled from 'styled-components';

import icon_correct from 'assets/images/icons/icon_correct.png';

export const IconContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 15px;
`;

export const IconCheck = styled.div`
	background-image: url(${icon_correct});
	height: 24px;
	width: 24px;
`;

export const TextCheck = styled.div`
	color: #000;
	font-size: calc(0.83rem + ${props => props.theme.fontScale}%);
	font-weight: 700;
	height: 24px;
	line-height: 24px;
`;
