import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, CadastrarInscricaoRequest } from './types';

export function cadastrarInscricaoRequest(
	token: string,
	payload: CadastrarInscricaoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_INSCRICAO_REQUEST,
		token,
		payload,
	};
}

export function cadastrarInscricaoSuccess(payload: object) {
	return {
		type: Types.CADASTRAR_INSCRICAO_SUCCESS,
		payload,
	};
}

export function cadastrarInscricaoFailure(payload: boolean) {
	return {
		type: Types.CADASTRAR_INSCRICAO_FAILURE,
		payload,
	};
}

export function cadastrarInscricaoClear() {
	return {
		type: Types.CADASTRAR_INSCRICAO_CLEAR,
		payload: null,
	};
}
