import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
	useContext,
} from 'react';
import { Field, FormikProps } from 'formik';
import { Col, Row, Modal, Alert } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { getGeocode, getLatLng } from 'use-places-autocomplete';

import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import Button from 'components/Common/Button';
import FormBox from 'components/Common/Form/FormBox';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import CheckBox from 'components/Common/Form/Checkbox';

import hasError from 'utils/getFormErrors';
import { limparMascara, onlyNumbersLettersSpace } from 'utils/genericFunctions';

import { ApplicationState } from 'store';
import { comboMunicipiosSPRequest } from 'store/modules/api/educacaoUpdate/fichaAluno/comboMunicipiosSP/actions';
import { consultaFichaAlunoRequest } from 'store/modules/api/educacaoUpdate/fichaAluno/consultaFichaAluno/actions';
import { EnumDadosBasicos } from 'store/modules/api/educacaoUpdate/combos/dadosBasicos/types';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

import SearchAutocomplete from '../SearchAutocomplete';
import Endereco from '../Endereco';

import {
	ContainerFichaCadastral,
	Container,
	DividerBarra,
	Divider,
	Latitude,
	Longitude,
	Cidade,
	Uf,
} from '../styled';
import { getApiKey } from '../Funcoes/apiKey';

interface Props {
	formik: FormikProps<any>;
	dadosAluno?: any;
	isResponsavel?: boolean;
}

const containerStyle = {
	height: '600px',
};

interface latLng {
	lat: number | string;
	lng: number | string;
}

const libraries = ['places'] as Libraries;

const EnderecoResidencial: React.FC<Props> = ({
	formik,
	dadosAluno,
	isResponsavel,
}) => {
	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const consultaFichaAluno = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.fichaAluno.consultaFichaAluno,
	);

	const { parametrosSistema } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.user,
	);

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: getApiKey(parametrosSistema),
		libraries,
	});
	const [markers, setMarkers] = useState<latLng>({
		lat: '',
		lng: '',
	});
	const [logradouro, setLogradouro] = useState<string>('');
	const [logradouroLatLng, setlogradouroLatLng] = useState<latLng>({
		lat: -23.55052,
		lng: -46.633308,
	});
	const [zoomMap, setZoomMap] = useState<number>(14);
	const [inputLatLng, setInputLatLng] = useState<latLng>({
		lat: '',
		lng: '',
	});
	const [centerMap, setCenterMap] = useState<latLng>(logradouroLatLng);
	const [enderecoAluno, setEnderecoAluno] = useState(false);

	const [modalMap, setModalMap] = useState<boolean>(false);
	const [dataMunicipios, setDataMunicipios] = useState<
		Array<{ value: string; label: string }>
	>([]);
	const [showAlert, setShowAlert] = useState<boolean>(false);

	const { setFieldValue } = formik;

	const { municipios } = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.fichaAluno.comboMunicipiosSP.data,
	);

	const { latlng } = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.fichaAluno.cadastrarFichaAluno,
	);

	const { dadosBasicos } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.combos,
	);

	useEffect(() => {
		if (latlng) {
			setMarkers({
				lat: latlng.lat,
				lng: latlng.lng,
			});
			setlogradouroLatLng(latlng);
			setCenterMap(latlng);
		}
	}, [latlng]);

	useEffect(() => {
		const lista: Array<{ value: string; label: string }> = [];
		municipios?.map(item => {
			return lista.push({
				value: item.codigo,
				label: item.descricao,
			});
		});
		setDataMunicipios(lista);
	}, [municipios]);

	useEffect(() => {
		if (loadError) {
			setShowAlert(true);
		}
	}, [loadError]);

	const handleChangeMunicipio = (v: any) => {
		const [result] = municipios.filter((nome: any) => {
			if (nome.codigo === v) {
				return nome.descricao;
			}
			return null;
		});
		formik.setFieldValue('enderecoResidencial.nomeCidade', result?.descricao);
		formik.setFieldValue(
			'enderecoResidencial.codigoMunicipioDNE',
			result?.codigo,
		);
	};

	useEffect(() => {
		dispatch(comboMunicipiosSPRequest(token));
	}, [dispatch, token]);

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado) => {
			setFieldValue('enderecoResidencial.cep', res.cep);
			setFieldValue(
				'enderecoResidencial.logradouro',
				res.endereco.substring(0, 30),
			);
			setFieldValue('enderecoResidencial.bairro', res.bairro.substring(0, 20));
			setFieldValue('enderecoResidencial.numero', '');
			setFieldValue('enderecoResidencial.nomeCidade', res.municipio);
			setInputLatLng({
				lat: '',
				lng: '',
			});
			setEnderecoAluno(false);
		},
		[setFieldValue],
	);

	const setValueLogradouro = async (v: string) => {
		if (isLoaded) {
			try {
				const results = await getGeocode({
					address: v,
				});
				const latLng = await getLatLng(results[0]);
				const formattedAdress = results[0].formatted_address.replace(
					', Brasil',
					'',
				);
				setLogradouro(formattedAdress);
				setlogradouroLatLng(latLng);
				setZoomMap(18);
				setCenterMap(latLng);
				setMarkers(latLng);
			} catch (err) {
				console.log('ERROR', v);
			}
		}
	};

	useEffect(() => {
		setValueLogradouro(
			`${formik.values.enderecoResidencial.logradouro}, ${formik.values.enderecoResidencial.numero} - ${formik.values.enderecoResidencial.bairro} - ${formik.values.enderecoResidencial.nomeCidade} - ${formik.values.enderecoResidencial.cep}`,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		formik.values.enderecoResidencial.logradouro,
		formik.values.enderecoResidencial.numero,
		formik.values.enderecoResidencial.bairro,
		formik.values.enderecoResidencial.nomeCidade,
		formik.values.enderecoResidencial.cep,
		isLoaded,
	]);

	const mapRef = useRef();
	const onMapLoad = useCallback((map: any) => {
		mapRef.current = map;
	}, []);

	useEffect(() => {
		setFieldValue('enderecoResidencial.latitude', inputLatLng.lat);
		setFieldValue('enderecoResidencial.longitude', inputLatLng.lng);
	}, [inputLatLng, setFieldValue]);

	useEffect(() => {
		if (formik.values.enderecoResidencial.latitude !== '') {
			setInputLatLng({
				lat: formik.values.enderecoResidencial.latitude,
				lng: formik.values.enderecoResidencial.longitude,
			});
		}
	}, [formik.values.enderecoResidencial]);

	const handleOpenMap = () => {
		setModalMap(true);
	};

	const handleGetEnderecoAluno = (e: any) => {
		setEnderecoAluno(e.target.checked);
		if (e.target.checked && consultaFichaAluno.status !== 200) {
			dispatch(
				consultaFichaAlunoRequest(token, {
					numeroRA: limparMascara(dadosAluno?.numeroRA) || '',
					digitoRA: dadosAluno?.digitoRA || '',
					siglaUFRA: dadosAluno?.siglaUFRA || '',
				}),
			);
		}
	};

	const setValuesEndereco = (data: any, isSet: boolean) => {
		const datakeys = Object.keys(data);
		if (isSet) {
			const keys = Object.keys(data).map(
				key => `${`${key}`.charAt(0)}${`${key}`.slice(1)}`,
			);
			const filteredKeys = keys.filter(
				key =>
					key !== 'codigoMunicipioDNE' &&
					key !== 'codigoArea' &&
					key !== 'codigoLocalizacao',
			);
			return filteredKeys.reduce((o, key, index) => {
				if (key === 'areaLogradouro') {
					return {
						...o,
						areaLogradouro: '',
					};
				}
				if (key === 'localizacaoDiferenciada') {
					return {
						...o,
						codLocalizacaoDiferenciada: '',
					};
				}
				return {
					...o,
					[key]: data[datakeys[index]],
				};
			}, {});
		}
		return datakeys.reduce((o, key) => ({ ...o, [key]: '' }), {});
	};

	useEffect(() => {
		if (enderecoAluno && consultaFichaAluno.status === 200) {
			const { data } = consultaFichaAluno;
			const enderecoResidencial = setValuesEndereco(
				data?.enderecoResidencial,
				true,
			);
			formik.setFieldValue('enderecoResidencial', enderecoResidencial);
		} else {
			const enderecoResidencial = setValuesEndereco(
				formik.values.enderecoResidencial,
				false,
			);
			formik.setFieldValue('enderecoResidencial', enderecoResidencial);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [consultaFichaAluno, enderecoAluno]);

	const onMarkerClick = async (evt: any) => {
		if (!loadError) {
			const results = await getGeocode({ location: evt.latLng });
			setLogradouro(results[0].formatted_address);
			setlogradouroLatLng(evt.latLng);
			setZoomMap(18);
			setCenterMap(evt.latLng);
			setMarkers({
				lat: evt.latLng.lat(),
				lng: evt.latLng.lng(),
			});
		}
	};

	const getEditedDadosBasicosOptionList = useCallback(
		(
			objectPropNameToEdit:
				| 'sexos'
				| 'estadosCivis'
				| 'nacionalidades'
				| 'responsabilidades'
				| 'localizacoesDiferenciadas'
				| 'areasLogradouro',
		): { label: string; value: string }[] => {
			if (
				dadosBasicos.data &&
				objectPropNameToEdit &&
				dadosBasicos.data[objectPropNameToEdit]
			) {
				// Remove number e enum diferente do padrao.
				const {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					processoId,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					deficiencias,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					provasAmpliadas,
					...dadosBasicosEditedData
				} = dadosBasicos.data;

				return (
					dadosBasicosEditedData[
						objectPropNameToEdit as keyof typeof dadosBasicosEditedData
					].map((opt: EnumDadosBasicos) => {
						return { label: opt.descricao, value: opt.codigo.toString() };
					}) || []
				);
			}

			return [];
		},
		[dadosBasicos.data],
	);

	return (
		<>
			<ContainerFichaCadastral>
				<FormBox title="Endereço Residencial">
					<Row gutter={[0, 10]}>
						<Col span={12}>
							<CEP
								name="enderecoResidencial.cep"
								defaultValue={formik.values.enderecoResidencial.cep}
								formik={formik}
								result={handleCepSelecionado}
								required
							/>
						</Col>
						{isResponsavel && (
							<Col span={12}>
								<Field
									as={CheckBox}
									subtitle="Copiar Endereço do Aluno:"
									name="copiarEndereco"
									order="left"
									checked={enderecoAluno}
									onChange={(e: any) => handleGetEnderecoAluno(e)}
								/>
							</Col>
						)}
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Field
								as={Select}
								title="Localização/Zona de Residência"
								name="enderecoResidencial.areaLogradouro"
								options={getEditedDadosBasicosOptionList('areasLogradouro')}
								onChange={(v: string) =>
									formik.setFieldValue('enderecoResidencial.areaLogradouro', v)
								}
								error={hasError(
									formik.errors,
									'enderecoResidencial.areaLogradouro',
								)}
								required
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Field
								as={Select}
								title="Localização Diferenciada"
								name="enderecoResidencial.codLocalizacaoDiferenciada"
								options={getEditedDadosBasicosOptionList(
									'localizacoesDiferenciadas',
								)}
								onChange={(v: string) =>
									formik.setFieldValue(
										'enderecoResidencial.localizacaoDiferenciada',
										v,
									)
								}
								error={hasError(
									formik.errors,
									'enderecoResidencial.codLocalizacaoDiferenciada',
								)}
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Endereco
								title="Endereço - N°"
								enderecoRua="enderecoResidencial.logradouro"
								numero="enderecoResidencial.numero"
								formik={formik}
								result={endereco => {
									formik.setFieldValue(
										'enderecoResidencial.logradouro',
										endereco.enderecoRua,
									);
									formik.setFieldValue(
										'enderecoResidencial.numero',
										endereco.numero,
									);
								}}
								error={hasError(
									formik.errors,
									'enderecoResidencial.logradouro' ||
										'enderecoResidencial.numero',
								)}
								required
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Field
								as={Input}
								title="Complemento"
								name="enderecoResidencial.complemento"
								maxLength={60}
								onChange={(e: React.FormEvent<HTMLInputElement>) => {
									formik.setFieldValue(
										'enderecoResidencial.complemento',
										onlyNumbersLettersSpace(e.currentTarget.value),
									);
								}}
								error={hasError(
									formik.errors,
									'enderecoResidencial.complemento',
								)}
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Field
								as={Input}
								title="Bairro"
								name="enderecoResidencial.bairro"
								maxLength={60}
								onChange={(e: React.FormEvent<HTMLInputElement>) => {
									formik.setFieldValue(
										'enderecoResidencial.bairro',
										onlyNumbersLettersSpace(e.currentTarget.value),
									);
								}}
								error={hasError(formik.errors, 'enderecoResidencial.bairro')}
								required
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Container>
								<Cidade>
									<Field
										as={Select}
										title="Cidade - UF"
										name="enderecoResidencial.nomeCidade"
										options={dataMunicipios}
										onChange={(v: string) => {
											handleChangeMunicipio(v);
										}}
										error={hasError(
											formik.errors,
											'enderecoResidencial.nomeCidade',
										)}
										required
									/>
								</Cidade>
								<Divider />
								<Uf>
									<Field
										as={Input}
										name="enderecoResidencial.ufCidade"
										value="SP"
										disabled
									/>
								</Uf>
							</Container>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={12}>
							<Container>
								<Latitude>
									<Field
										as={Input}
										title="Latitude/Longitude"
										name="enderecoResidencial.latitude"
										value={inputLatLng.lat}
										error={hasError(
											formik.errors,
											'enderecoResidencial.latitude',
										)}
										required
									/>
								</Latitude>
								<DividerBarra />
								<Longitude>
									<Field
										as={Input}
										name="enderecoResidencial.longitude"
										value={inputLatLng.lng}
										error={hasError(
											formik.errors,
											'enderecoResidencial.longitude',
										)}
									/>
								</Longitude>
							</Container>
						</Col>
						<Col span={4} offset={1}>
							<Button className="addButton" onClick={() => handleOpenMap()}>
								Geolocalizar
							</Button>
						</Col>
					</Row>
				</FormBox>
				<Modal
					title=""
					visible={modalMap}
					onCancel={() => {
						setModalMap(false);
					}}
					width={880}
					footer={null}
				>
					<>
						<Row gutter={[0, 10]} justify="center">
							<Col span={3}>
								<h3>Google Maps</h3>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								{isLoaded && (
									<>
										<SearchAutocomplete
											logradouro={logradouro}
											setLogradouro={setLogradouro}
										/>
										<GoogleMap
											mapContainerStyle={containerStyle}
											center={centerMap}
											zoom={zoomMap}
											options={{
												streetViewControl: true,
												fullscreenControl: true,
												mapTypeControl: false,
											}}
											onLoad={onMapLoad}
											onClick={(evt: any) => onMarkerClick(evt)}
										>
											<Marker position={logradouroLatLng} />
										</GoogleMap>
									</>
								)}
								{showAlert && (
									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Alert
												type="error"
												showIcon
												message="Google Maps não responde!"
												closable
												onClose={() => setShowAlert(false)}
											/>
										</Col>
									</Row>
								)}
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={4}>
								<Button
									className="addButton"
									onClick={() => {
										setModalMap(false);
										if (markers) {
											setInputLatLng(markers);
											formik.setFieldValue(
												'enderecoResidencial.latitude',
												markers.lat,
											);
											formik.setFieldValue(
												'enderecoResidencial.longitude',
												markers.lng,
											);
										}
									}}
								>
									Confirmar
								</Button>
							</Col>
						</Row>
					</>
				</Modal>
			</ContainerFichaCadastral>
		</>
	);
};

export default EnderecoResidencial;
