import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { GerarRenachRequest, Types } from './types';

// ACTIONS
import { gerarRenachSuccess, gerarRenachFailure } from './actions';

function* gerarRenach(request: ReducerAction) {
	const { payload }: { payload: GerarRenachRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`sockets/${CHANNEL}/gerar-renach-pcad`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(gerarRenachSuccess({ response, request: payload }));
	} else if (response.error) {
		yield put(gerarRenachFailure(response.error));
	}
}

export default all([takeLatest(Types.GERAR_RENACH_REQUEST, gerarRenach)]);
