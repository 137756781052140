export enum Types {
	CONSULTA_USUARIOS_MAINFRAME_REQUEST = '@iirgd/CONSULTA_USUARIOS_MAINFRAME',
	CONSULTA_USUARIOS_MAINFRAME_SUCCESS = '@iirgd/CONSULTA_USUARIOS_MAINFRAME_SUCCESS',
	CONSULTA_USUARIOS_MAINFRAME_FAILURE = '@iirgd/CONSULTA_USUARIOS_MAINFRAME_FAILURE',
	CONSULTA_USUARIOS_MAINFRAME_CLEAR = '@iirgd/CONSULTA_USUARIOS_MAINFRAME_CLEAR',
}

export interface ConsultaUsuariosMainframe {
	status: number;
	data: null | ConsultaUsuariosMainframeData[];
}

export interface ConsultaUsuariosMainframeData {
	dtCadastro: string;
	idUsuarioMainframe: string;
	senha: string;
}

export interface ConsultaUsuariosMainframeRequest {
	cpf: string;
	codigoLocalidade: number;
}
