export enum Types {
	CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_REQUEST = '@cnh/CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_REQUEST',
	CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_SUCCESS = '@cnh/CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_SUCCESS',
	CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_FAILURE = '@cnh/CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_FAILURE',
	CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_CLEAR = '@cnh/CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_CLEAR',
}

export interface ConsultaPesquisaNomeSocialFiliacao {
	status: number;
	data: null | ConsultaPesquisaNomeSocialFiliacaoData;
}

export interface ConsultaPesquisaNomeSocialFiliacaoData {
	rg: string;
	digito: string;
	orgaoExpedidor: string;
	cpf: string;
	nome: string;
	nomePai: string;
	nomeMae: string;
	dataNascimento: string;
	sexo: string;
	naturalidade: string;
	dataUltimaExpedicao: string;
	bloqueio: string;
	dataUltimaAfis: string;
	codigoNaturalidade: string;
	nomeSocial: string;
}

export interface ConsultaPesquisaNomeSocialFiliacaoRequest {
	usuario: string;
	terminal: string;
	cpf: string;
}
