import { all } from 'redux-saga/effects';

import consultaErro from './consultaErro/saga';
import consultaHistorico from './consultaHistorico/saga';
import consultaNaoProcessada from './consultaNaoProcessada/saga';
import cadastroEvento from './cadastroEvento/saga';

export default all([
	consultaErro,
	consultaHistorico,
	consultaNaoProcessada,
	cadastroEvento,
]);
