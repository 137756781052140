import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaCadastroCnh } from './types';

// ACTIONS
import {
	consultaCadastroCnhSuccess,
	consultaCadastroCnhFailure,
} from './actions';

function* consultaCadastroCnh(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaCadastroCnh } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}/consultaCadastroCnh`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaCadastroCnhSuccess(response));
	} else if (response.error) {
		yield put(consultaCadastroCnhFailure(response.error));
	}
}

export default all([
	takeLatest(Types.CONSULTA_CADASTRO_CNH, consultaCadastroCnh),
]);
