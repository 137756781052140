import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarParametroSistemaRequest } from './types';

// ACTIONS
import {
	cadastrarParametroSistemaSuccess,
	cadastrarParametroSistemaFailure,
} from './actions';

function* cadastrarParametroSistema(request: ReducerAction) {
	const { payload }: { payload: CadastrarParametroSistemaRequest } = request;

	const body = { ...payload };

	delete body.id;

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU,
		`parametros-sistema`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarParametroSistemaSuccess(response));
	} else {
		yield put(cadastrarParametroSistemaFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_PARAMETRO_SISTEMA, cadastrarParametroSistema),
]);
