export enum Types {
	CONSULTA_RESTRICOES_REQUEST = '@DETRAN-CRV/CONSULTA_RESTRICOES_REQUEST',
	CONSULTA_RESTRICOES_SUCCESS = '@DETRAN-CRV/CONSULTA_RESTRICOES_SUCCESS',
	CONSULTA_RESTRICOES_FAILURE = '@DETRAN-CRV/CONSULTA_RESTRICOES_FAILURE',
	CONSULTA_RESTRICOES_CLEAR = '@DETRAN-CRV/CONSULTA_RESTRICOES_CLEAR',
}

export interface ConsultaRestricoes {
	status: number;
	data: ConsultaRestricoesData | null;
	dataRequest: ConsultaRestricoesRequest | null;
}

export interface ConsultaRestricoesRequest {
	placa: string;
	renavam: string;
	cpfCnpj: string;
	opcao: 16 | 17 | 18;
}

interface ConsultaRestricoesData {
	codigo: number;
	mensagem: string;
}
