import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { IRequestConsultarVeiculoSegundaViaPlaca, Types } from './types';

// ACTIONS
import {
	consultarVeiculoSegundaViaPlacaSuccess,
	consultarVeiculoSegundaViaPlacaFailure,
} from './actions';

function* consultarVeiculoSegundaViaPlacaRequest(request: ReducerAction) {
	const {
		payload,
	}: { payload: IRequestConsultarVeiculoSegundaViaPlaca } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/consulta/veiculos-segundas-vias-placas`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarVeiculoSegundaViaPlacaSuccess(response.data));
	} else if (response.error) {
		yield put(consultarVeiculoSegundaViaPlacaFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_REQUEST,
		consultarVeiculoSegundaViaPlacaRequest,
	),
]);
