import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaAtendimentos, ConsultaAtendimentosData } from './types';

export const INITIAL_STATE: ConsultaAtendimentos = {
	status: null,
	data: null,
	consulta: '',
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaAtendimentos {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_ATENDIMENTOS_REQUEST: {
				draft.consulta = action.payload.cpf;
				draft.status = 0;
				break;
			}
			case Types.CONSULTA_ATENDIMENTOS_SUCCESS: {
				draft.status = 200;
				let newData: ConsultaAtendimentosData[] = [...action.payload.data];
				newData = newData.sort(
					(a: any, b: any) =>
						new Date(b.dataDeSolicitacao).getTime() -
						new Date(a.dataDeSolicitacao).getTime(),
				);
				draft.data = newData;
				break;
			}
			case Types.CONSULTA_ATENDIMENTOS_FAILURE: {
				draft.status = 400;
				break;
			}
			case Types.CONSULTA_ATENDIMENTOS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.consulta = INITIAL_STATE.consulta;
				break;
			}

			default:
		}
		return draft;
	});
}
