import React, { useCallback, useEffect } from 'react';

import { Col, Row } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';
import { ROUTE_IIRGD_INCLUSAO_OBSERVACOES } from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultarDareClear } from 'store/modules/api/dare/consultarDare/actions';
import { disponibilzarDareClear } from 'store/modules/api/potencial/disponibilzarDare/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';

import Formulario from './Formulario';
import Resultado from './Resultado';

const PesquisaDARE: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	useEffect(() => {
		if (!cadastroRg.form.principal) {
			history.push(ROUTE_IIRGD);
		}
		dispatch(disponibilzarDareClear());
	}, [cadastroRg.form.principal, dispatch, history]);

	const handleButtonVoltar = useCallback(() => {
		if (
			cadastroRg.form.observacoes.primeiraVia.menor ||
			cadastroRg.form.observacoes.segundaVia
		) {
			history.push(ROUTE_IIRGD_INCLUSAO_OBSERVACOES);
			return;
		}

		dispatch(consultarDareClear());
		dispatch(disponibilzarDareClear());

		history.push(ROUTE_IIRGD);
	}, [cadastroRg.form.observacoes, history, dispatch]);

	return (
		<>
			<Section title="DARE">
				<Formulario />

				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Resultado />
			</Section>

			<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
		</>
	);
};

export default PesquisaDARE;
