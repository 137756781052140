import { IImpressaoAlteracaoEndereco } from 'pages/DetranCrv/Processos/ImpressaoAlteracaoEndereco/form';
import { Types } from './types';

export function ImpressaoAlteracaoEnderecoRequest(
	payload: IImpressaoAlteracaoEndereco,
) {
	return {
		type: Types.IMPRESSAO_ALTERACAO_ENDERECO_REQUEST,
		payload,
	};
}
export function ImpressaoAlteracaoEnderecoSuccess(payload: any) {
	return {
		type: Types.IMPRESSAO_ALTERACAO_ENDERECO_SUCCESS,
		payload,
	};
}
export function ImpressaoAlteracaoEnderecoFailure(payload: []): any {
	return {
		type: Types.IMPRESSAO_ALTERACAO_ENDERECO_FAILURE,
		payload,
	};
}
