import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CLASSIFICACOES_REQUEST = '@procon/CLASSIFICACOES_REQUEST',
	CLASSIFICACOES_SUCCESS = '@procon/CLASSIFICACOES_SUCCESS',
	CLASSIFICACOES_FAILURE = '@procon/CLASSIFICACOES_FAILURE',
	CLASSIFICACOES_CLEAR = '@procon/CLASSIFICACOES_CLEAR',
}

export interface Classificacoes {
	status: null | number;
	data: OptionProps[];
}
