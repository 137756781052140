export enum Types {
	PTREOE_REQUEST = '@DETRAN-CRV/PTREOE_REQUEST',
	PTREOE_SUCCESS = '@DETRAN-CRV/PTREOE_SUCCESS',
	PTREOE_FAILURE = '@DETRAN-CRV/PTREOE_FAILURE',
	PTREOE_CLEAR = '@DETRAN-CRV/PTREOE_CLEAR',
}

export interface PTREOE {
	status: number;
	mensagem: string;
	data: IPTREOE;
}

export interface IPTREOE {
	tipoCliente: number;
	qtdeOcorrencias: string;
	placa: string;
	renavam: string;
	uf: string;
	situacaoVeiculo: string;
	lotacao: number;
	anoFabricacao: number;
	anoModelo: number;
	numMotor: string;
	procedencia: string;
	chassi: string;
	chassiRemarcado: {
		codigo: string;
		descricao: string;
	};
	marca: {
		codigo: string;
		descricao: string;
	};
	cor: {
		codigo: string;
		descricao: string;
	};
	combustivel: {
		codigo: string;
		descricao: string;
	};
	municipio: {
		codigo: string;
		descricao: string;
	};
	restricoes: [
		{
			restricao: string;
		},
	];
}

export interface IPTREOERequest {
	tipoPesquisa: number;
	chassi: string;
	placa: string;
	renavam: string;
	ufVeiculo: string;
	marcaVeiculo: string | null;
	anoFabricacao: string | null;
}
