// ROUTES
import { ROUTE_SGU, ROUTE_POUPATEMPO } from 'routes/paths';

// ADMINISTRAR
export const ROUTE_SGU_ADMIN_FUNCIONALIDADE = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/funcionalidade`;
export const ROUTE_SGU_ADMIN_LOCALIDADE = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/localidade`;
export const ROUTE_SGU_ADMIN_LOCALIDADE_ANTIGA = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/localidade-antiga`;
export const ROUTE_SGU_ADMIN_MENSAGEM_ERRO = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/mensagens-erro`;
export const ROUTE_SGU_ADMIN_MENSAGEM = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/mensagem-de-para`;
export const ROUTE_SGU_ADMIN_MUNICIPIO = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/municipio`;
export const ROUTE_SGU_ADMIN_MUNICIPIO_IBGE = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/municipio-ibge`;
export const ROUTE_SGU_ADMIN_PARAMETROS_LOCALIDADE = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/parametros-localidade`;
export const ROUTE_SGU_ADMIN_PARAMETROS_SISTEMA = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/parametros-sistema`;
export const ROUTE_SGU_ADMIN_PARAMETROS_SISTEMA_REDIS = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/parametros-sistema-redis`;
export const ROUTE_SGU_ADMIN_MENUS = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/menus`;
export const ROUTE_SGU_ADMIN_MOTIVO = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/motivo`;
export const ROUTE_SGU_ADMIN_NATURALIDADE = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/naturalidade`;
export const ROUTE_SGU_ADMIN_ORGAO = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/orgao`;
export const ROUTE_SGU_ADMIN_PERFIL = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/perfil`;
export const ROUTE_SGU_ADMIN_SIGLA = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/sigla`;
export const ROUTE_SGU_ADMIN_STATUS = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/status`;
export const ROUTE_SGU_ADMIN_SUFIXO = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/sufixo`;
export const ROUTE_SGU_ADMIN_UNIDADE = `${ROUTE_POUPATEMPO}${ROUTE_SGU}/admin/unidade`;
