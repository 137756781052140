// TYPES
import {
	GerarRenachIntegrado,
	GerarRenachIntegradoRequest,
} from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/types';
import { ConsultaEPortalSegundaViaData } from 'store/modules/api/detranCnh/segundaVia/consultaEPortalSegundaVia/types';
import { IConfrontarDigitaisCnhState } from 'store/modules/api/utils/confrontarDigitaisCnh/types';
import { ConsultarDigitaisState } from 'store/modules/api/utils/consultarDigitais/types';
import { IResponseConsultaCnhDefinitiva } from 'store/modules/api/detranCnh/cnhDefinitiva/consultaCnhDefinitiva/types';
import { FormCnhEstrangeiro } from 'pages/DetranCnh/components/pages/PreCadastroEstrangeiro/StatusCondutor/form';
import { IConfirmacaoAtendimento } from 'pages/DetranCnh/components/pages/ConfirmacaoAtendimento/form';
import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';
import { usoFormularioEnumTypes } from 'utils/constants/types';
import { AlterarCadastro } from 'store/modules/api/detranCnh/renachIntegrado/alterarCadastro/types';
import { ConsultaCondutorResponse } from 'store/modules/api/detranCnh/condutor/consultaCondutor/types';
import { PreCadastroCidadao } from 'store/modules/api/sguService/preCadastroCidadao/types';
import { CapturarBiometriaState } from 'store/modules/api/biometria/capturarBiometria/types';

// UTILS
import { STATUS_BIOMETRICS } from 'utils/constants';
import { showComponent } from 'utils/genericFunctions';
import { getBiometricsStatus } from '../biometrics';

// RETORNA AS MENSAGENS PARA O CAMPO OBSERVACAO NA REQ E EM CASO DE ERRO
export const getObservationPromotePassword = (
	dadosAtendimento: SalvarAtendimento,
	dataGerarRenach: GerarRenachIntegradoRequest,
	preCadastroCidadaoResponse: PreCadastroCidadao,
): string => {
	if (preCadastroCidadaoResponse.status === 100) {
		return `Id_atendimento: ${
			dadosAtendimento.data?.idAtendimento ||
			'00000000-0000-0000-0000-000000000000'
		}; Id_cidadao: ${
			dadosAtendimento.data?.idCidadao || '00000000-0000-0000-0000-000000000000'
		}; ${
			(dataGerarRenach.candidato.email &&
				`E-mail: ${dataGerarRenach.candidato.email};`) ||
			''
		} ${
			(dataGerarRenach.candidato.dddCelular &&
				`DDD Celular: ${dataGerarRenach.candidato.dddCelular};`) ||
			''
		} ${
			(dataGerarRenach.candidato.celular &&
				`Celular: ${dataGerarRenach.candidato.celular};`) ||
			''
		} ${
			(dataGerarRenach.candidato.dddTelefone &&
				`DDD Telefone: ${dataGerarRenach.candidato.dddTelefone};`) ||
			''
		} ${
			(dataGerarRenach.candidato.telefone &&
				`Telefone: ${dataGerarRenach.candidato.telefone};`) ||
			''
		}`;
	}
	if (preCadastroCidadaoResponse.status === 400) {
		return preCadastroCidadaoResponse.errorMessage[0] || '';
	}
	return '';
};

// RETORNA OS CAMPOS SELECIONADOS PARA O CAMPO OBSERVACAO
export const getObservationSelectedFieldsGerarRenachIntegrado = (
	dataGerarRenach: GerarRenachIntegradoRequest,
): string => {
	return `Pretende exercer atividade remunerada? ${
		(dataGerarRenach.candidato.pretendeAtividadeRemunerada &&
			typeof dataGerarRenach.candidato.pretendeAtividadeRemunerada !==
				'string') ||
		dataGerarRenach.candidato.pretendeAtividadeRemunerada === 'S'
			? 'Sim'
			: 'Não'
	}; Exame dentro do posto? ${
		(dataGerarRenach?.gerarRenachBase?.flagExamePosto &&
			typeof dataGerarRenach?.gerarRenachBase?.flagExamePosto !== 'string') ||
		dataGerarRenach?.gerarRenachBase?.flagExamePosto === 'S'
			? 'Sim'
			: 'Não'
	}; ${
		(showComponent(
			[
				usoFormularioEnumTypes.PRIMEIRA_HABILITACAO,
				usoFormularioEnumTypes.ADICAO_CATEGORIA,
				usoFormularioEnumTypes.ADICAO_MUDANCA,
				usoFormularioEnumTypes.MUDANCA_CATEGORIA,
				usoFormularioEnumTypes.CASSACAO,
				usoFormularioEnumTypes.CASSACAO_CRIME,
				usoFormularioEnumTypes.PERMISSIONARIO,
				usoFormularioEnumTypes.PERMISSIONARIO_PONTUADO,
			],
			dataGerarRenach?.gerarRenachBase?.usoFormularioEnum || '',
		) &&
			((dataGerarRenach?.gerarRenachBase?.flagMilitar &&
				typeof dataGerarRenach?.gerarRenachBase?.flagMilitar !== 'string') ||
			dataGerarRenach?.gerarRenachBase?.flagMilitar === 'S'
				? 'É militar e vai utilizar o CFC da corporação? Sim;'
				: 'É militar e vai utilizar o CFC da corporação? Não;')) ||
		''
	} Ciretran relativo ao endereço: ${
		dataGerarRenach?.gerarRenachBase?.codCiretranCondutor || ''
	}; ${
		(showComponent(
			[
				usoFormularioEnumTypes.ADICAO_CATEGORIA,
				usoFormularioEnumTypes.ADICAO_MUDANCA,
				usoFormularioEnumTypes.MUDANCA_CATEGORIA,
				usoFormularioEnumTypes.CNH_ESTRANGEIRA,
				usoFormularioEnumTypes.CASSACAO,
				usoFormularioEnumTypes.CASSACAO_CRIME,
				usoFormularioEnumTypes.RENOVACAO,
			],
			dataGerarRenach?.gerarRenachBase?.usoFormularioEnum || '',
		) &&
			(dataGerarRenach.candidato.categoriaPretendida.indexOf('C') !== -1 ||
			dataGerarRenach.candidato.categoriaPretendida.indexOf('D') !== -1 ||
			dataGerarRenach.candidato.categoriaPretendida.indexOf('E') !== -1
				? 'Realizou exames Toxicológicos? Sim;'
				: 'Realizou exames Toxicológicos? Não;')) ||
		''
	} Categoria Atual: ${
		dataGerarRenach.candidato.categoriaAtual || ''
	}; Categoria Pretendida: ${
		dataGerarRenach.candidato.categoriaPretendida || ''
	}; Solicitou CNH Digital? ${
		(dataGerarRenach.candidato.cnhDigital &&
			typeof dataGerarRenach.candidato.cnhDigital !== 'string') ||
		dataGerarRenach.candidato.cnhDigital === 'S'
			? 'Sim'
			: 'Não'
	}; Deseja utilizar o nome social na CNH? ${
		(dataGerarRenach.candidato.nomeSocial &&
			typeof dataGerarRenach.candidato.nomeSocial !== 'string') ||
		(dataGerarRenach.candidato.nomeSocial &&
			dataGerarRenach.candidato.nomeSocial !== '')
			? 'Sim'
			: 'Não'
	}; Deseja incluir filiação afetiva na CNH? ${
		(dataGerarRenach.candidato.paisAfetivos1 &&
			typeof dataGerarRenach.candidato.paisAfetivos1 !== 'string') ||
		(dataGerarRenach.candidato.paisAfetivos1 &&
			dataGerarRenach.candidato.paisAfetivos1 !== '') ||
		(dataGerarRenach.candidato.paisAfetivos2 &&
			typeof dataGerarRenach.candidato.paisAfetivos2 !== 'string') ||
		(dataGerarRenach.candidato.paisAfetivos2 &&
			dataGerarRenach.candidato.paisAfetivos2 !== '')
			? 'Sim'
			: 'Não'
	}`;
};

// RETORNA OS CAMPOS SELECIONADOS PARA O CAMPO OBSERVACAO
export const getObservationSelectedFieldsGerarRenachSegundaVia = (
	responsePesquisaSegundaVia:
		| ConsultaEPortalSegundaViaData
		| IResponseConsultaCnhDefinitiva,
	dataGerarRenachSegundaVia: GerarRenachIntegradoRequest,
): string => {
	return `Pretende exercer atividade remunerada? ${
		(dataGerarRenachSegundaVia.candidato.pretendeAtividadeRemunerada &&
			typeof dataGerarRenachSegundaVia.candidato.pretendeAtividadeRemunerada !==
				'string') ||
		dataGerarRenachSegundaVia.candidato.pretendeAtividadeRemunerada === 'S'
			? 'Sim'
			: 'Não'
	}; Já exercia atividade remunerada? ${
		responsePesquisaSegundaVia.flagAtividadeRemunerada ? 'Sim' : 'Não'
	}; Ciretran relativo ao endereço: ${
		dataGerarRenachSegundaVia.gerarRenachBase?.codCiretranCondutor || ''
	}; Categoria Atual: ${
		dataGerarRenachSegundaVia.candidato.categoriaAtual || ''
	}; Categoria Pretendida: ${
		dataGerarRenachSegundaVia.candidato.categoriaPretendida || ''
	}; Solicitou CNH Digital? ${
		(dataGerarRenachSegundaVia.candidato.cnhDigital &&
			typeof dataGerarRenachSegundaVia.candidato.cnhDigital !== 'string') ||
		dataGerarRenachSegundaVia.candidato.cnhDigital === 'S'
			? 'Sim'
			: 'Não'
	}; Deseja utilizar o nome social na CNH? ${
		(dataGerarRenachSegundaVia.candidato.nomeSocial &&
			typeof dataGerarRenachSegundaVia.candidato.nomeSocial !== 'string') ||
		(dataGerarRenachSegundaVia.candidato.nomeSocial &&
			dataGerarRenachSegundaVia.candidato.nomeSocial !== '')
			? 'Sim'
			: 'Não'
	}; Deseja incluir filiação afetiva na CNH? ${
		(dataGerarRenachSegundaVia.candidato.paisAfetivos1 &&
			typeof dataGerarRenachSegundaVia.candidato.paisAfetivos1 !== 'string') ||
		(dataGerarRenachSegundaVia.candidato.paisAfetivos1 &&
			dataGerarRenachSegundaVia.candidato.paisAfetivos1 !== '') ||
		(dataGerarRenachSegundaVia.candidato.paisAfetivos2 &&
			typeof dataGerarRenachSegundaVia.candidato.paisAfetivos2 !== 'string') ||
		(dataGerarRenachSegundaVia.candidato.paisAfetivos2 &&
			dataGerarRenachSegundaVia.candidato.paisAfetivos2 !== '')
			? 'Sim'
			: 'Não'
	}`;
};

// RETORNA OS CAMPOS SELECIONADOS PARA O CAMPO OBSERVACAO
export const getObservationSelectedFieldsAlterarRenach = (
	dataAlterarRenach: FormCnhEstrangeiro,
	dataConsultarDadosCidadao: ConsultaCondutorResponse,
): string => {
	return `Pretende exercer atividade remunerada? ${
		(dataAlterarRenach?.flagAtividadeRemunerada &&
			typeof dataAlterarRenach?.flagAtividadeRemunerada !== 'string') ||
		dataAlterarRenach?.flagAtividadeRemunerada === 'S'
			? 'Sim'
			: 'Não'
	}; Já exercia atividade remunerada? ${
		(dataConsultarDadosCidadao?.pretendeAtividadeRemunerada &&
			typeof dataConsultarDadosCidadao?.pretendeAtividadeRemunerada !==
				'string') ||
		dataConsultarDadosCidadao?.pretendeAtividadeRemunerada === 'S'
			? 'Sim'
			: 'Não'
	}; Exame dentro do posto? ${
		(dataAlterarRenach?.flagExameNoPosto &&
			typeof dataAlterarRenach?.flagExameNoPosto !== 'string') ||
		dataAlterarRenach?.flagExameNoPosto === 'S'
			? 'Sim'
			: 'Não'
	}; Ciretran relativo ao endereço: ${
		dataAlterarRenach?.codigoUnidadeTransito || ''
	}; Categoria Atual: ${
		dataAlterarRenach?.categoriaAtual || ''
	}; Categoria Pretendida: ${
		dataAlterarRenach?.categoriaPretendida || ''
	}; Solicitou CNH Digital? ${
		(dataAlterarRenach?.flagSolicitaCNHDigital &&
			typeof dataAlterarRenach?.flagSolicitaCNHDigital !== 'string') ||
		dataAlterarRenach?.flagSolicitaCNHDigital === 'S'
			? 'Sim'
			: 'Não'
	};`;
};

export const getResponseDetailsRenachIntegrado = (
	generatedRenachResponse: GerarRenachIntegrado,
): string => {
	if (
		generatedRenachResponse?.status === 200 ||
		generatedRenachResponse?.status === 201
	) {
		return `RENACH GERADO: ${generatedRenachResponse.data?.renach || ''}`;
	}
	return `Erro ao gerar RENACH: ${
		generatedRenachResponse.dataError?.mensagem || ''
	}`;
};

export const getResponseDetailsEditedRenach = (
	dataConsultarDadosCidadao: ConsultaCondutorResponse,
	editedRenachResponse: AlterarCadastro,
): string => {
	if (
		editedRenachResponse?.status === 200 ||
		editedRenachResponse?.status === 201
	) {
		return `RENACH GERADO ALTERADO: ${dataConsultarDadosCidadao.renach || ''}`;
	}
	return `Erro ao alterar RENACH: ${
		editedRenachResponse.dataError?.mensagem || ''
	}`;
};

export const getIdMotivoBiometricRead = (
	confrontoDigitaisCnh: IConfrontarDigitaisCnhState,
	consultarDigitais: ConsultarDigitaisState,
	biometricReadService: CapturarBiometriaState,
	dataGerarRenach: GerarRenachIntegradoRequest,
	dataModalAtendimento?: IConfirmacaoAtendimento,
	dataModalProcurador?: boolean | string,
): string => {
	if (consultarDigitais.status === 200 && confrontoDigitaisCnh.resultado) {
		// SE HOUVE CONFRONTO
		return '0';
	}
	if (
		getBiometricsStatus(
			consultarDigitais,
			dataGerarRenach.gerarRenachBase?.usoFormularioEnum || '',
		) === STATUS_BIOMETRICS.FORA_TEMPORALIDADE
	) {
		// SE ESTA FORA DA TEMPORALIDADE
		return '1';
	}
	if (
		consultarDigitais.status === 200 &&
		confrontoDigitaisCnh.dedoSorteio &&
		confrontoDigitaisCnh.dedoSorteio[0].posicaoDedo &&
		!confrontoDigitaisCnh.resultado &&
		biometricReadService.qtdeFalha !== 3
	) {
		// SE FOI RECUSADO PELO CONFRONTO
		return '5';
	}
	if (
		consultarDigitais.status === 200 &&
		!confrontoDigitaisCnh.resultado &&
		biometricReadService.qtdeFalha === 3
	) {
		// SE FOI EMITIDO SEM REUSO, ERRO DE LEITURA BIOMETRICA POR 3 VEZES SEM REALIZAR CONFRONTO
		return '10';
	}
	if (consultarDigitais.status !== 0) {
		// SE FOI EMITIDO SEM REUSO
		return '10';
	}
	if (dataModalProcurador === true || dataModalProcurador === 'S') {
		// SE FOI EMITIDO PELO PROCURADOR
		return '22';
	}
	if (dataModalAtendimento && !dataModalAtendimento.todosDedos) {
		// SE NA JANELA DE PERGUNTAS ASSINALOU QUE NÃO POSSUI TODOS OS DEDOS
		return '4';
	}
	if (dataModalAtendimento && dataModalAtendimento.coletarAssinatura) {
		// SE NA JANELA DE PERGUNTAS ASSINALOU QUE HOUVE A ALTERAÇÃO DE ASSINATURA
		return '6';
	}
	if (dataModalAtendimento && dataModalAtendimento.coletarFoto) {
		// SE NA JANELA DE PERGUNTAS ASSINALOU QUE HOUVE A ALTERAÇÃO DE CARACTERÍSTICA FÍSICA
		return '9';
	}
	return '';
};
