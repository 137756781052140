import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { PATH_PREFEITURAS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestAlteraPrefeiturasServicosCanais } from './types';

// ACTIONS
import {
	alteraPrefeiturasServicosCanaisSuccess,
	alteraPrefeiturasServicosCanaisFailure,
} from './actions';
import { consultaPrefeiturasServicosCanaisRequest } from '../consultaPrefeiturasServicosCanais/actions';

function* alteraPrefeiturasServicosCanais(request: ReducerAction) {
	const {
		payload,
	}: { payload: IRequestAlteraPrefeiturasServicosCanais } = request;

	const response: ApiResponse = yield call(
		putApi,
		PATH_PREFEITURAS,
		`prefeituras-servicos/${payload.idPrefeituraServico}/canais`,
		payload.body,
	);

	if (response.status === 200) {
		yield put(alteraPrefeiturasServicosCanaisSuccess(payload));
		yield put(consultaPrefeiturasServicosCanaisRequest(payload));
	} else if (response.error) {
		yield put(alteraPrefeiturasServicosCanaisFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.ALTERA_PREFEITURA_SERVICOS_CANAIS,
		alteraPrefeiturasServicosCanais,
	),
]);
