export enum Types {
	CONSULTA_ENDERECOS = '@cnh/CONSULTA_ENDERECOS',
	CONSULTA_ENDERECOS_SUCCESS = '@cnh/CONSULTA_ENDERECOS_SUCCESS',
	CONSULTA_ENDERECOS_FAILURE = '@cnh/CONSULTA_ENDERECOS_FAILURE',
}

export interface consultaEndereco {
	status: number;
	data: IDadosConsultaEndereco | null;
}

export interface IDadosConsultaEndereco {
	cpf: string;
	codigo: string;
	mensagem: string;
	enderecoCadastro: IEnderecoCadastrado;
	enderecosRecebimento: IEnderecosRecebimento[] | null;
}

interface IEnderecoCadastrado {
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cep: string;
	codigoMunicipio: string;
	nomeMunicipio: string;
	tipo: string;
}

interface IEnderecosRecebimento {
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cep: string;
	codigoMunicipio: string;
	nomeMunicipio: string;
	tipo: string;
}

export interface IRequestEnderecos {
	cpf: string;
}
