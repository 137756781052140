export enum Types {
	CONSULTA_PLACA_DISPONIVEL_REQUEST = '@DETRAN-CRV/CONSULTA_PLACA_DISPONIVEL_REQUEST',
	CONSULTA_PLACA_DISPONIVEL_SUCCESS = '@DETRAN-CRV/CONSULTA_PLACA_DISPONIVEL_SUCCESS',
	CONSULTA_PLACA_DISPONIVEL_FAILURE = '@DETRAN-CRV/CONSULTA_PLACA_DISPONIVEL_FAILURE',
	CONSULTA_PLACA_DISPONIVEL_CLEAR = '@DETRAN-CRV/CONSULTA_PLACA_DISPONIVEL_CLEAR',
}

export interface consultaPlacaDisponivel {
	status: number;
	data: placas | null;
}

interface placas {
	chassi: string;
	cpfCnpjProprietario: number;
	placa: string;
	placas: string[];
	possuiPlaca: boolean;
	quantidadeAlteracoesRestante: number;
}

export interface ConsultaPlacaDisponivelRequest {
	dadosVeiculo?: {
		chassiVeiculo: string;
		cpfCnpjVeiculo: number | null;
	};
	dadosPlaca: {
		complementoPlaca: string;
		letrasPlaca: string;
	};
}
