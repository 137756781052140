// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultarMutuarioData, Types } from './types';

export interface IRequestConsultarMutuario {
	cpf?: string;
	numeroContrato?: number;
}

export function consultarMutuarioRequest(
	token: string,
	payload: IRequestConsultarMutuario,
): any {
	return {
		type: Types.CONSULTAR_MUTUARIO_REQUEST,
		token,
		payload,
	};
}
export function consultarMutuarioSuccess(
	payload: ConsultarMutuarioData,
): ReducerAction {
	return {
		type: Types.CONSULTAR_MUTUARIO_SUCCESS,
		payload,
	};
}
export function consultarMutuarioFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_MUTUARIO_FAILURE,
		payload: null,
	};
}
export function consultarMutuarioClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_MUTUARIO_CLEAR,
		payload: null,
	};
}
