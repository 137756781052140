import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form, Field, FormikProps } from 'formik';
import { Col, Row } from 'antd';

import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { limparMascara } from 'utils/genericFunctions';

import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Radio from 'components/Common/Form/Radio';
import Upload from 'components/Common/Upload';
import InputMask from 'components/Common/Form/Input/InputMask';

import data from 'store/modules/api/prefeituras/enum';
import { protocolarRecursoRequest } from 'store/modules/api/prefeituras/jalesTransito/protocolarRecurso/actions';

import { ApplicationState } from 'store';

import { IFormRecurso, initialValues, schema } from './form';

import { Content } from './styled';

const Recurso = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [tipoRecurso, setTipoRecurso] = useState('');

	const { protocolarRecurso, documentoInfracao } = useSelector(
		(state: ApplicationState) => state.api.prefeituras.jalesTransito,
	);

	const handleSubmit = useCallback(
		(values: IFormRecurso) => {
			if (documentoInfracao.data) {
				values.requerenteCpf = limparMascara(values.requerenteCpf);
				dispatch(
					protocolarRecursoRequest({
						...values,
						tipoRecurso,
						codigoAit: documentoInfracao.data.codigoAit,
					}),
				);
			}
		},
		[dispatch, documentoInfracao.data, tipoRecurso],
	);

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado, formik: FormikProps<IFormRecurso>) => {
			formik.setFieldValue(`requerenteCep`, res.cep);
			formik.setFieldValue(`requerenteRua`, res.endereco.substring(0, 40));
			formik.setFieldValue(`requerenteBairro`, res.bairro.substring(0, 13));
			formik.setFieldValue(`requerenteUf`, res.uf);
		},
		[],
	);

	useEffect(() => {
		if (documentoInfracao.data) {
			// Mapeia o link para verificar qual tipo de recurso foi designado
			const getTipoRecurso = documentoInfracao.data.urlProtocolar.split('/');
			// Capta o tipo de recurso (ultimo item da URL) e manda para o estado
			setTipoRecurso(getTipoRecurso[getTipoRecurso.length - 1]);
		}
	}, [documentoInfracao.data]);

	useEffect(() => {
		if (protocolarRecurso.status === 200) {
			history.push(`/prefeitura/jales/consulta/infracoes/resultado/recurso`);
			// dispatch(clearRedirectJalesTransito());
		}
	}, [dispatch, history, protocolarRecurso]);

	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValues}
						validate={values => getValidationsErrors(values, schema)}
						onSubmit={handleSubmit}
					>
						{formik => {
							return (
								<Form>
									<Content>
										<Section title="CNH">
											<Row gutter={[0, 10]}>
												<Col span={12}>
													<Field
														title="UF"
														name="cnhUf"
														as={Select}
														options={data.estados}
														defaultValue="SP"
														onChange={(v: string) => {
															formik.setFieldValue('cnhUf', v);
														}}
														required
														error={hasError(formik.errors, 'cnhUf')}
													/>
												</Col>
												<Col span={12}>
													<Field
														title="Categoria"
														name="cnhCategoria"
														as={Input}
														required
														error={hasError(formik.errors, 'cnhCategoria')}
													/>
												</Col>
											</Row>
										</Section>

										<Section title="Requerente">
											<Row gutter={[0, 10]}>
												<Col span={10}>
													<Field
														title="Tipo"
														name="requerenteTipo"
														as={Radio}
														required
														error={hasError(formik.errors, 'requerenteTipo')}
														options={[
															{
																label: 'Condutor',
																value: 'C',
															},
															{
																label: 'Proprietário',
																value: 'P',
															},
															{
																label: 'Representante',
																value: 'R',
															},
														]}
													/>
												</Col>
											</Row>
											<Row gutter={[0, 10]}>
												<Col span={12}>
													<Field
														title="Nome"
														name="requerenteNome"
														as={Input}
														required
														error={hasError(formik.errors, 'requerenteNome')}
													/>
												</Col>
												<Col span={12}>
													<Field
														title="CPF"
														name="requerenteCpf"
														as={InputMask}
														mask="999.999.999-99"
														required
														error={hasError(formik.errors, 'requerenteCpf')}
													/>
												</Col>
											</Row>
											<Row gutter={[0, 10]}>
												<Col span={12}>
													<CEP
														name="requerenteCep"
														defaultValue={initialValues.requerenteCep}
														required
														formik={formik}
														result={(res: IBuscarCepResultado) =>
															handleCepSelecionado(res, formik)
														}
													/>
												</Col>
											</Row>
											<Row gutter={[0, 10]}>
												<Col span={12}>
													<Field
														title="Endereço"
														name="requerenteRua"
														as={Input}
														required
														error={hasError(formik.errors, 'requerenteRua')}
													/>
												</Col>
												<Col span={12}>
													<Field
														title="Número"
														name="requerenteNumero"
														as={Input}
														required
														error={hasError(formik.errors, 'requerenteNumero')}
													/>
												</Col>
											</Row>
											<Row gutter={[0, 10]}>
												<Col span={12}>
													<Field
														title="Bairro"
														name="requerenteBairro"
														as={Input}
														required
														error={hasError(formik.errors, 'requerenteBairro')}
													/>
												</Col>
												<Col span={12}>
													<Field
														title="Complemento"
														name="requerenteComplemento"
														as={Input}
													/>
												</Col>
											</Row>
											<Row gutter={[0, 10]}>
												<Col span={12}>
													<Field
														title="Estado"
														name="requerenteUf"
														as={Select}
														options={data.estados}
														onChange={(v: string) => {
															formik.setFieldValue('requerenteUf', v);
														}}
														required
														error={hasError(formik.errors, 'requerenteUf')}
													/>
												</Col>
											</Row>
										</Section>

										<Section title="Fotos">
											<Row gutter={[0, 10]}>
												<Col span={24} md={12}>
													<Upload
														title="CNH"
														name="fotoCnh"
														formik={formik}
														required
														error={hasError(formik.errors, 'fotoCnh')}
													/>
													<Upload
														title="Contrato Social"
														name="fotoContratoSocial"
														formik={formik}
													/>
													<Upload
														title="Formulário"
														name="fotoFormulario"
														formik={formik}
														required
														error={hasError(formik.errors, 'fotoFormulario')}
													/>
													<Upload
														title="RG"
														name="fotoRg"
														formik={formik}
														required
														error={hasError(formik.errors, 'fotoRg')}
													/>
												</Col>
												<Col span={24} md={12}>
													<Upload
														title="CRLV"
														name="fotoCrlv"
														formik={formik}
														required
														error={hasError(formik.errors, 'fotoCrlv')}
													/>
													<Upload
														title="Requerimento"
														name="fotoRequerimento"
														formik={formik}
														required
														error={hasError(formik.errors, 'fotoRequerimento')}
													/>
												</Col>
											</Row>
										</Section>
										<ButtonImage type="submit" src="salvar" />
									</Content>
								</Form>
							);
						}}
					</Formik>
				</Col>
			</Row>
			<ButtonVoltar />
		</>
	);
};

export default Recurso;
