// import { IRequestCadastraExameMedico } from 'store/modules/detranCnh/divisaoEquitativa/types';
import {
	DadosExameMedico,
	GerarRenachIntegradoRequest,
} from 'store/modules/api/detranCnh/sockets/saveGerarRenach/types';

import { formatDateISOWithOutUTC, unformatDate } from 'utils/genericFunctions';
import * as Yup from 'yup';

export interface ICadastraExameMedico {
	cpf_cidadao: number;
	tipo_processo: string;
	cep_cidadao: number;
	categoria_pretendida: string;
	id_medico: number;
	data_exame: string;
	horario_exame: string;
	codigo_ciretran_exame_medico?: number;
	codigo_ciretran?: number;
	renach: string;
	id_micro_regiao_medico: number | string;
	usuario: string;
	regiao?: number | string;
}

export function treatValuesGerarRenach(
	values: GerarRenachIntegradoRequest,
	agendarExames: DadosExameMedico,
	cpfUsuario: string,
): GerarRenachIntegradoRequest {
	const horaFormat =
		agendarExames.horarioExame &&
		formatDateISOWithOutUTC(agendarExames.horarioExame, true).split(' ')[1];

	const formattedData = {
		candidato: { cpfUsuario, ...values.candidato },
		dadosExameMedico: {
			codigoCiretranExameMedico: agendarExames.codigoCiretranExameMedico,
			dataExame:
				agendarExames.dataExame && unformatDate(agendarExames.dataExame),
			horarioExame: horaFormat,
			idMedico: agendarExames.idMedico,
			idMicroRegiaoMedico: agendarExames.idMicroRegiaoMedico,
		},
		dadosExamePsicologo: values.dadosExamePsicologo,
		evento: values.gerarRenachBase?.evento,
	};
	return formattedData;
}

export function treatValuesGerarRenachWithPsicologico(
	values: GerarRenachIntegradoRequest,
	agendarExames: DadosExameMedico,
): GerarRenachIntegradoRequest {
	const horaFormat =
		agendarExames.horarioExame &&
		formatDateISOWithOutUTC(agendarExames.horarioExame, true).split(' ')[1];

	const formattedData = {
		candidato: values.candidato,
		dadosExameMedico: {
			codigoCiretranExameMedico: agendarExames.codigoCiretranExameMedico,
			dataExame:
				agendarExames.dataExame && unformatDate(agendarExames.dataExame),
			horarioExame: horaFormat,
			idMedico: agendarExames.idMedico,
			idMicroRegiaoMedico: agendarExames.idMicroRegiaoMedico,
		},
		dadosExamePsicologo: values.dadosExamePsicologo,
		gerarRenachBase: values.gerarRenachBase,
	};
	return formattedData;
}

export const schema = Yup.object<ICadastraExameMedico>().shape({
	regiao: Yup.string().when('codigo_ciretran', {
		is: codigo_ciretran => codigo_ciretran === '18',
		then: Yup.string().required('Campo Obrigatório: Região.'),
	}),
	id_micro_regiao_medico: Yup.string().when('codigo_ciretran', {
		is: codigo_ciretran => codigo_ciretran === '18',
		then: Yup.string().required('Campo Obrigatório: Micro Região.'),
	}),
	data_exame: Yup.string().required('Campo Obrigatório: Data.'),
	horario_exame: Yup.string().required('Campo Obrigatório: Hora.'),
});
