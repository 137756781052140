import React, { useState, useCallback } from 'react';

import { v4 } from 'uuid';

import ReactInputMask, { Props as InputProps } from 'react-input-mask';

// STYLED
import { Container, Title, Content, Box, SubTitle, Hint } from '../styled';

interface Props extends InputProps {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	titleQuestion?: boolean;
	titleAlign?: 'start' | 'end';
	subtitle?: string;
	hint?: string;
	name: string;
	autoFocus?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	size?: number;
	error?: boolean;
}

const InputMask: React.FC<Props> = ({
	title,
	titleSize = 'md',
	titleQuestion = false,
	titleAlign = 'end',
	subtitle,
	hint,
	name,
	autoFocus = false,
	required = false,
	disabled = false,
	readOnly = false,
	size = 100,
	error,
	...rest
}) => {
	const [isFocused, setIsFocused] = useState(false);

	const handleInputFocus = useCallback(() => {
		setIsFocused(true);
	}, []);

	const handleInputBlur = useCallback(() => {
		setIsFocused(false);
	}, []);

	return (
		<Container>
			{title && (
				<Title align={titleAlign} size={titleSize} required={required}>
					<label htmlFor={name}>{`${title}${titleQuestion ? '?' : ':'}`}</label>
				</Title>
			)}

			<Content>
				<Box
					size={size}
					isErrored={!!error}
					isFocused={isFocused}
					isDisabled={disabled}
					isReadOnly={readOnly}
				>
					<ReactInputMask
						name={name}
						id={`${name}-${v4()}`}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						autoFocus={autoFocus}
						disabled={disabled}
						readOnly={readOnly}
						onKeyDown={e => {
							rest.onKeyDown
								? rest.onKeyDown(e)
								: e.key === 'Enter' && e.preventDefault();
						}}
						{...rest}
					/>

					{subtitle && (
						<SubTitle>
							<span>{subtitle}</span>
						</SubTitle>
					)}
				</Box>

				{hint && <Hint>{hint}</Hint>}
			</Content>
		</Container>
	);
};

export default InputMask;
