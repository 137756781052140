import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function consultarTiposResidenciasRequest() {
	return {
		type: Types.CONSULTAR_TIPOS_RESIDENCIAS_REQUEST,
	};
}
export function consultarTiposResidenciasSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_TIPOS_RESIDENCIAS_SUCCESS,
		payload,
	};
}
export function consultarTiposResidenciasFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_TIPOS_RESIDENCIAS_FAILURE,
		payload: null,
	};
}

export function limparconsultarTiposResidencias(): any {
	return {
		type: Types.LIMPAR_CONSULTAR_TIPOS_RESIDENCIAS,
	};
}
