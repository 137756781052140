import { ReducerAction } from 'store/modules/types';
import {
	bloqueioDetran,
	IRequestConsultarBloqueioDetranRenajud,
	Types,
} from './types';

export function consultarBloqueioDetranRenajudRequest(
	payload: IRequestConsultarBloqueioDetranRenajud,
): ReducerAction {
	return {
		type: Types.CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_REQUEST,
		payload,
	};
}

export function consultarBloqueioDetranSuccess(
	payload: bloqueioDetran,
): ReducerAction {
	return {
		type: Types.CONSULTAR_BLOQUEIO_DETRAN_SUCCESS,
		payload,
	};
}

export function consultarBloqueioRenajudSuccess(
	payload: bloqueioDetran,
): ReducerAction {
	return {
		type: Types.CONSULTAR_BLOQUEIO_RENAJUD_SUCCESS,
		payload,
	};
}

export function consultarBloqueioDetranRenajudFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_FAILURE,
		payload: null,
	};
}

export function consultarBloqueioDetranRenajudClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_CLEAR,
		payload: null,
	};
}
