import React, {
	ChangeEvent,
	Suspense,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';
import Radio from 'components/Common/Form/Radio';
import { consultarCadastroBinRenavamRequest } from 'store/modules/api/detranCrv/ConsultarVeiculos/consultarCadastroBinRenavam/actions';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { initialValues, schema, treatValues } from './form';

const ConsultarCadastroBinRenavam: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const Options = [
		{
			value: 'chassi',
			label: 'Chassi',
		},
		{
			value: 'placa',
			label: 'Placa',
		},
		{
			value: 'renavam',
			label: 'Renavam',
		},
	];

	const [lenght, setLenght] = useState<number>(0);

	const handleChange = useCallback((value: string) => {
		switch (value) {
			case 'chassi':
				setLenght(22);
				break;
			case 'placa':
				setLenght(7);
				break;
			case 'renavam':
				setLenght(11);
				break;
			default:
				break;
		}
	}, []);

	const { consultarCadastroBinRenavam } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarVeiculos,
	);

	useEffect(() => {
		if (consultarCadastroBinRenavam.status === 200) {
			history.push('/detran-crv/consulta-cadastro-bin-renavam/resultado');
		}
	}, [consultarCadastroBinRenavam, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						const formatValuesReq = treatValues(values);
						dispatch(consultarCadastroBinRenavamRequest(formatValuesReq));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[0, 20]} justify="center">
									<Col span={8}>
										<Field
											name="selecao"
											as={Radio}
											options={Options}
											error={hasError(formik.errors, 'selecao')}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												const { value } = event.target;
												formik.handleReset();
												formik.setFieldValue('selecao', value);
												handleChange(value);
											}}
										/>
									</Col>
								</Row>
								{formik.values.selecao !== '' && (
									<Row gutter={[0, 20]} justify="center">
										<Col span={8}>
											<Field
												title="Documento"
												required
												maxLength={lenght}
												name={formik.values.selecao}
												as={Input}
												error={hasError(formik.errors, 'selecao')}
											/>
										</Col>
									</Row>
								)}
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={formik.handleReset}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route="/detran-crv" />
		</Suspense>
	);
};

export default ConsultarCadastroBinRenavam;
