import produce from 'immer';
// import { formatDate, toSelect } from 'utils/genericFunctions';

import { Types, gerarRenachPrimeiraHabilitacao } from './types';
import { ReducerAction } from '../../../../types';

// UTILS

export const INITIAL_STATE: gerarRenachPrimeiraHabilitacao = {
	status: 0,
	data: null,
};

export default function gerarRenachPrimeiraHabilitacaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): gerarRenachPrimeiraHabilitacao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.GERAR_RENACH_PRIMEIRA_HABILITACAO: {
				draft.status = 0;
				break;
			}
			case Types.GERAR_RENACH_PRIMEIRA_HABILITACAO_SUCCESS: {
				break;
			}
			case Types.GERAR_RENACH_PRIMEIRA_HABILITACAO_FAILURE: {
				break;
			}

			default:
		}
	});
}
