// TYPES
import { ReducerAction } from 'store/modules/types';
import { CapturarBiometriaRequest, Types } from './types';

export function capturarBiometriaRequest(
	payload: CapturarBiometriaRequest,
): ReducerAction {
	return {
		type: Types.CAPTURAR_BIOMETRIA_REQUEST,
		payload,
	};
}
export function capturarBiometriaSuccess(payload: string): ReducerAction {
	return {
		type: Types.CAPTURAR_BIOMETRIA_SUCCESS,
		payload,
	};
}
export function capturarBiometriaFailure(payload: object): ReducerAction {
	return {
		type: Types.CAPTURAR_BIOMETRIA_FAILURE,
		payload,
	};
}
export function capturarBiometriaClear(): ReducerAction {
	return {
		type: Types.CAPTURAR_BIOMETRIA_CLEAR,
		payload: null,
	};
}

export function capturarBiometriaClearLeitura(): ReducerAction {
	return {
		type: Types.CAPTURAR_BIOMETRIA_CLEAR_LEITURA,
		payload: null,
	};
}
