import { ApiResponse } from 'services/_api';
import { EventoTerminoRequest, Types } from './types';

export function eventoTerminoRequest(payload: EventoTerminoRequest) {
	return {
		type: Types.EVENTO_TERMINO_REQUEST,
		payload,
	};
}
export function eventoTerminoSuccess(payload: ApiResponse) {
	return {
		type: Types.EVENTO_TERMINO_SUCCESS,
		payload,
	};
}
export function eventoTerminoFailure(payload: ApiResponse) {
	return {
		type: Types.EVENTO_TERMINO_FAILURE,
		payload,
	};
}
export function eventoTerminoClear(): any {
	return {
		type: Types.EVENTO_TERMINO_CLEAR,
	};
}
