import { ReducerAction } from 'store/modules/types';

import { Types, DadosCidadaoRequest } from './types';

export function dadosCidadaoRequest(data: DadosCidadaoRequest): ReducerAction {
	return {
		type: Types.DADOS_CIDADAO_REQUEST,
		payload: data,
	};
}
export function dadosCidadaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.DADOS_CIDADAO_SUCCESS,
		payload,
	};
}
export function dadosCidadaoFailure(payload: object): ReducerAction {
	return {
		type: Types.DADOS_CIDADAO_FAILURE,
		payload,
	};
}
export function dadosCidadaoClear(): ReducerAction {
	return {
		type: Types.DADOS_CIDADAO_CLEAR,
		payload: null,
	};
}
