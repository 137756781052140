import styled, { css } from 'styled-components';

interface ContainerProps {
	size: 'sm' | 'lg';
	bgColor: 'branco' | 'cinza-claro' | 'cinza-escuro';
}

interface TitleProps {
	titleSize: 'sm' | 'lg';
	titleBold: boolean;
}

export const Container = styled.section<ContainerProps>`
	border: 1px solid ${props => props.theme.colors.border};
	margin-top: 20px;
	position: relative;
	width: 100%;

	${props =>
		props.bgColor === 'branco' &&
		css`
			background-color: ${props.theme.colors.background};
		`};

	${props =>
		props.theme.title === 'light' &&
		props.bgColor === 'cinza-claro' &&
		css`
			background-color: #f0f0f0;
		`};

	${props =>
		props.theme.title === 'light' &&
		props.bgColor === 'cinza-escuro' &&
		css`
			background-color: rgb(231 231 231);
		`};

	${props =>
		props.theme.title === 'dark' &&
		props.bgColor === 'cinza-claro' &&
		css`
			background-color: #67686b;
		`};

	${props =>
		props.theme.title === 'dark' &&
		props.bgColor === 'cinza-escuro' &&
		css`
			background-color: rgb(231 231 231);
		`};

	${props =>
		props.size === 'sm' &&
		css`
			padding: 20px 10px 15px 10px;
		`};

	${props =>
		props.size === 'lg' &&
		css`
			padding: 20px 20px 15px 20px;
		`};
`;

export const Title = styled.h1<TitleProps>`
	background-color: ${props => props.theme.colors.background};
	padding: 0px 5px;
	position: absolute;
	left: 10px;

	${props =>
		props.titleSize === 'sm' &&
		css`
			font-size: calc(0.85rem + ${props.theme.fontScale}%);
			font-weight: ${props.titleBold ? 500 : 400};
			top: -11px;
		`};

	${props =>
		props.titleSize === 'lg' &&
		css`
			font-size: calc(1.1rem + ${props.theme.fontScale}%);
			font-weight: ${props.titleBold ? 500 : 400};
			top: -15px;
		`};
`;
