import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { ConsultarIntencaoVendaRequest, Types } from './types';
import {
	consultarIntencaoVendaSuccess,
	consultarIntencaoVendaFailure,
} from './actions';

function* consultarIntencaoVendaRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultarIntencaoVendaRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`aptv/${CHANNEL}/consultas`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarIntencaoVendaSuccess(response));
	} else if (response.error) {
		yield put(consultarIntencaoVendaFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_INTENCAO_VENDA_REQUEST,
		consultarIntencaoVendaRequest,
	),
]);
