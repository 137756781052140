import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarTiposRequest } from './types';

export function atualizarTiposRequest(
	data: AtualizarTiposRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_TIPOS,
		payload: data,
	};
}
export function atualizarTiposSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_TIPOS_SUCCESS,
		payload,
	};
}
export function atualizarTiposFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_TIPOS_FAILURE,
		payload: null,
	};
}
export function atualizarTiposClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_TIPOS_CLEAR,
		payload: null,
	};
}
