import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultaRenainfPlaca } from './types';

export function multasRenainfPlacaRequest(
	data: IRequestConsultaRenainfPlaca,
): ReducerAction {
	return {
		type: Types.MULTAS_RENAINF_PLACA_REQUEST,
		payload: data,
	};
}
export function multasRenainfPlacaSuccess(payload: object) {
	return {
		type: Types.MULTAS_RENAINF_PLACA_SUCCESS,
		payload,
	};
}
export function multasRenainfPlacaFailure(): ReducerAction {
	return {
		type: Types.MULTAS_RENAINF_PLACA_FAILURE,
		payload: null,
	};
}
export function multasRenainfPlacaClear(): any {
	return {
		type: Types.MULTAS_RENAINF_PLACA_CLEAR,
	};
}
