import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { ConsultarParametrosSistemaRequest, Types } from './types';

// ACTIONS
import {
	consultarParametrosSistemaSuccess,
	consultarParametrosSistemaFailure,
} from './actions';

function* consultarParametrosSistema(request: ReducerAction) {
	const { payload }: { payload: ConsultarParametrosSistemaRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarParametrosSistemaRequest;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`parametros-sistema`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarParametrosSistemaSuccess(response));
	} else {
		yield put(consultarParametrosSistemaFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_PARAMETROS_SISTEMA, consultarParametrosSistema),
]);
