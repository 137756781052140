// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function participacoesAudienciaRequest() {
	return {
		type: Types.PARTICIPACOES_AUDIENCIA_REQUEST,
	};
}
export function participacoesAudienciaSuccess(payload: object): ReducerAction {
	return {
		type: Types.PARTICIPACOES_AUDIENCIA_SUCCESS,
		payload,
	};
}
export function participacoesAudienciaFailure(payload: object): ReducerAction {
	return {
		type: Types.PARTICIPACOES_AUDIENCIA_FAILURE,
		payload,
	};
}
export function participacoesAudienciaClear(): ReducerAction {
	return {
		type: Types.PARTICIPACOES_AUDIENCIA_CLEAR,
		payload: null,
	};
}
