import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaMunicipiosRequest } from './types';

export function consultaMunicipiosRequest(
	data: ConsultaMunicipiosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_MUNICIPIOS_REQUEST,
		payload: data,
	};
}
export function consultaMunicipiosSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_MUNICIPIOS_SUCCESS,
		payload,
	};
}
export function consultaMunicipiosFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_MUNICIPIOS_FAILURE,
		payload: null,
	};
}
export function consultaMunicipiosClear(): ReducerAction {
	return {
		type: Types.CONSULTA_MUNICIPIOS_CLEAR,
		payload: null,
	};
}
