import * as Yup from 'yup';

// TYPES
import { IRequestEmitirTrocaVoluntariaPlaca } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirTrocaVoluntariaPlaca/types';
import { IEvento } from 'store/modules/api/detranCrv/types';

import {
	concatenatesTaxaServicoPix,
	limparMascara,
} from 'utils/genericFunctions';

export interface IMunicipio {
	codigo: number;
	nome: string;
}

interface ITaxaServico {
	codBanco: string;
	dataContabil: string;
	nsuProdesp: string;
}

export const initialValues: IRequestEmitirTrocaVoluntariaPlaca = {
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
		identificacao: '',
		ip: '',
		canal: {
			id: 0,
			desc: '',
			localidade: {
				id: 0,
				desc: '',
			},
		},
	},
	placa: '',
	renavam: 0,
	chassi: '',
	municipio: {
		codigo: 0,
		nome: '',
	},
	taxaAutenticacaoDigital: '',
	nomeCidadao: '',
	dddTelefoneCidadao: '',
	emailCidadao: '',
	telefoneCidadao: '',
	servicoDigital: true,
	documentos: [],
	codBanco: '',
	dataContabil: '',
	nsuProdesp: '',
};

export const schema = Yup.object<IRequestEmitirTrocaVoluntariaPlaca>().shape({
	placa: Yup.string().required('Campo Obrigatório: Placa.'),
	renavam: Yup.string().required('Campo Obrigatório: Renavam.'),
	municipio: Yup.object<IMunicipio>().shape({
		nome: Yup.string().required('Campo Obrigatório: Município.'),
	}),
	chassi: Yup.string().required('Campo Obrigatório: Chassi.'),
	nomeCidadao: Yup.string().required('Campo Obrigatório: Nome.'),
	emailCidadao: Yup.string().required('Campo Obrigatório: E-mail.'),
	telefoneCidadao: Yup.string().required('Campo Obrigatório: Telefone.'),
	dddTelefoneCidadao: Yup.string().required('Campo Obrigatório: DDD.'),
	documentos: Yup.string().required(`Campo Obrigatório: Anexo de documento.`),
});

export function treatValues(
	evento: IEvento,
	formValues: IRequestEmitirTrocaVoluntariaPlaca,
	taxaServico?: ITaxaServico,
): IRequestEmitirTrocaVoluntariaPlaca {
	const {
		placa,
		renavam,
		chassi,
		municipio,
		dddTelefoneCidadao,
		emailCidadao,
		telefoneCidadao,
		servicoDigital,
		documentos,
		nomeCidadao,
	} = formValues;

	const formattedEvento = {
		id_atendimento: evento.id_atendimento,
		id_cidadao: evento.id_cidadao,
		ip: evento.ip,
		identificacao: evento.identificacao,
		cpf: evento.cpf || '',
		canal: {
			id: 1,
			desc: 'Presencial',
			localidade: {
				desc: evento.canal?.localidade.desc || '',
				id: evento.canal?.localidade.id || 0,
			},
		},
	};

	return {
		evento: formattedEvento,
		chassi,
		placa,
		renavam,
		municipio,
		taxaAutenticacaoDigital:
			concatenatesTaxaServicoPix(
				taxaServico?.codBanco || '',
				taxaServico?.dataContabil || '',
				taxaServico?.nsuProdesp || '',
			) || '',
		nomeCidadao,
		dddTelefoneCidadao: Number(dddTelefoneCidadao),
		emailCidadao,
		telefoneCidadao: Number(limparMascara(telefoneCidadao.toString())),
		servicoDigital,
		documentos,
	};
}
