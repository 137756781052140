import { IImpressaoSegundaViaLicenciamento } from 'pages/DetranCrv/Processos/ImpressaoSegundaViaLicenciamento/Consulta/form';
import { Types } from './types';

export function impressaoSegundaViaLicenciamentoRequest(
	payload: IImpressaoSegundaViaLicenciamento,
) {
	return {
		type: Types.IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_REQUEST,
		payload,
	};
}
export function impressaoSegundaViaLicenciamentoSuccess(payload: any) {
	return {
		type: Types.IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_SUCCESS,
		payload,
	};
}
export function impressaoSegundaViaLicenciamentoFailure(payload: []): any {
	return {
		type: Types.IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_FAILURE,
		payload,
	};
}

export function impressaoSegundaViaLicenciamentoClear(): any {
	return {
		type: Types.IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_CLEAR,
	};
}
