import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaExtratoResumoCondutorRequest } from './types';

export function consultaExtratoResumoCondutorRequest(
	data: ConsultaExtratoResumoCondutorRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_EXTRATO_RESUMO_CONDUTOR_REQUEST,
		payload: data,
	};
}

export function consultaExtratoResumoCondutorSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_EXTRATO_RESUMO_CONDUTOR_SUCCESS,
		payload,
	};
}

export function consultaExtratoResumoCondutorFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_EXTRATO_RESUMO_CONDUTOR_FAILURE,
		payload,
	};
}

export function consultaExtratoResumoCondutorClear(): ReducerAction {
	return {
		type: Types.CONSULTA_EXTRATO_RESUMO_CONDUTOR_CLEAR,
		payload: null,
	};
}
