import { all } from 'redux-saga/effects';

import adminSaga from './admin/combineSaga';
import administrarSaga from './administrar/combineSaga';
import loginUnico from './loginUnico/saga';
import usuariosSaga from './usuarios/combineSaga';
import atendimentoSaga from './atendimento/combineSaga';
import estatistica from './estatistica/combineSaga';
import relatorios from './relatorios/combineSaga';

export default all([
	adminSaga,
	administrarSaga,
	loginUnico,
	usuariosSaga,
	atendimentoSaga,
	estatistica,
	relatorios,
]);
