import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { Types, AtualizarUnidadeRequest } from './types';

// ACTIONS
import { atualizarUnidadeSuccess, atualizarUnidadeFailure } from './actions';

function* atualizarUnidade(request: ReducerAction) {
	const { payload }: { payload: AtualizarUnidadeRequest } = request;

	let body = { ...payload };

	delete body.id;

	body = cleanEmpty(body) as AtualizarUnidadeRequest;

	body.tipo = 'P';

	const response: ApiResponse = yield call(
		putApi,
		PATH_SGU,
		`unidades/${payload.id}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarUnidadeSuccess(response));
	} else {
		yield put(atualizarUnidadeFailure());
	}
}

export default all([takeLatest(Types.ATUALIZAR_UNIDADE, atualizarUnidade)]);
