export enum Types {
	ENCERRAR_ATENDIMENTO_REQUEST = '@aac/ENCERRAR_ATENDIMENTO_REQUEST',
	ENCERRAR_ATENDIMENTO_SUCCESS = '@aac/ENCERRAR_ATENDIMENTO_SUCCESS',
	ENCERRAR_ATENDIMENTO_FAILURE = '@aac/ENCERRAR_ATENDIMENTO_FAILURE',
	ENCERRAR_ATENDIMENTO_CLEAR = '@aac/ENCERRAR_ATENDIMENTO_CLEAR',
}

export interface EncerrarAtendimento {
	status: number;
	mensagem: string;
}
export interface RequestEncerrarAtendimento {
	idIdentificacao: string;
}
