import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConsultarTemporalidadeState } from './types';

export const INITIAL_STATE: IConsultarTemporalidadeState = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IConsultarTemporalidadeState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_TEMPORALIDADE_REQUEST: {
				break;
			}
			case Types.CONSULTAR_TEMPORALIDADE_SUCCESS: {
				draft.status = 200;
				draft.data = {
					dadosBiometricos: action.payload.DadosBiometricos,
					valida: action.payload.valida,
				};
				break;
			}
			case Types.CONSULTAR_TEMPORALIDADE_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CONSULTAR_TEMPORALIDADE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
