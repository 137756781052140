import * as Yup from 'yup';

// TYPES
import {
	IProcedencia,
	IRequestEmitirBaixaVeiculo,
	IProprietarioFisicoEmitirBaixaVeiculo,
	IProprietarioJuridicoEmitirBaixaVeiculo,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirBaixaVeiculo/types';
import { IEvento } from 'store/modules/api/detranCrv/types';

// UTILS
import { limparMascara } from 'utils/genericFunctions';
import { ICaracteristicaVeiculo } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/types';
import { formatRg } from '../../formFunctions';

export interface IEmitirBaixaVeiculo {
	evento: IEvento;
	anoModelo: number | string;
	bairroProprietario: string;
	capacidadeCarga: number | string;
	capacidadePassageiro: number | string;
	cepProprietario: string;
	chassi: string;
	chassiRemarcado: boolean;
	cilindrada: number | string;
	cmt: number | string;
	codigoMunicipio: number | string;
	codigoMunicipioProprietario: number | string;
	complementoProprietario: string;
	descricaoCarroceria: string;
	descricaoCategoria: string;
	descricaoCombustivel: string;
	descricaoCor: string;
	descricaoEspecie: string;
	descricaoMarca: string;
	descricaoMunicipio: string;
	descricaoMunicipioProprietario: string;
	descricaoTipo: string;
	eixos: number | string;
	enderecoProprietario: string;
	longIdCarroceria: number | string;
	longIdCategoria: number | string;
	longIdCombustivel: number | string;
	longIdCor: number | string;
	longIdEspecie: number | string;
	longIdMarca: number | string;
	longIdTipo: number | string;
	nomeProprietario: string;
	numeroEspelho: string;
	numeroProprietario: string;
	orgaoExpeditorProprietario: string;
	pbt: number | string;
	placa: string;
	potencia: number | string;
	procedencia: IProcedencia;
	protocoloRemarcacao: string;
	rgProprietario: string;
	rgDigito: string;
	telefone: string;
	ufProprietario: string;
}

export const initialValues: IEmitirBaixaVeiculo = {
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
		identificacao: '',
		ip: '',
		canal: {
			id: 0,
			desc: '',
			localidade: {
				id: 0,
				desc: '',
			},
		},
	},
	anoModelo: '',
	bairroProprietario: '',
	capacidadeCarga: '',
	capacidadePassageiro: '',
	cepProprietario: '',
	chassi: '',
	chassiRemarcado: false,
	cilindrada: '',
	cmt: '',
	codigoMunicipio: '',
	codigoMunicipioProprietario: '',
	complementoProprietario: '',
	descricaoCarroceria: '',
	descricaoCategoria: '',
	descricaoCombustivel: '',
	descricaoCor: '',
	descricaoEspecie: '',
	descricaoMarca: '',
	descricaoMunicipio: '',
	descricaoMunicipioProprietario: '',
	descricaoTipo: '',
	eixos: '',
	enderecoProprietario: '',
	longIdCarroceria: '',
	longIdCategoria: '',
	longIdCombustivel: '',
	longIdCor: '',
	longIdEspecie: '',
	longIdMarca: '',
	longIdTipo: '',
	nomeProprietario: '',
	numeroEspelho: '',
	numeroProprietario: '',
	orgaoExpeditorProprietario: '',
	pbt: '',
	placa: '',
	potencia: '',
	procedencia: '',
	protocoloRemarcacao: '',
	rgProprietario: '',
	rgDigito: '',
	telefone: '',
	ufProprietario: '',
};

export const schema = Yup.object<IEmitirBaixaVeiculo>().shape({
	placa: Yup.string().required('Campo Obrigatório: Placa.'),
	municipio: Yup.string().when(['codigoMunicipio', 'descricaoMunicipio'], {
		is: (codigoMunicipio, descricaoMunicipio) =>
			[codigoMunicipio, descricaoMunicipio].includes(''),
		then: Yup.string().required('Campo Obrigatório: Município.'),
	}),
	numeroEspelho: Yup.string().required('Campo Obrigatório: Número de Espelho.'),
	chassi: Yup.string().required('Campo Obrigatório: Chassi.'),
	chassiRemarcado: Yup.string().required(
		'Campo Obrigatório: Chassi Remarcado.',
	),
	marca: Yup.string().when(['descricaoMarca', 'longIdMarca'], {
		is: (descricaoMarca, longIdMarca) =>
			[descricaoMarca, longIdMarca].includes(''),
		then: Yup.string().required('Campo Obrigatório: Marca.'),
	}),
	anoModelo: Yup.string().required('Campo Obrigatório: Ano Modelo.'),
	tipo: Yup.string().when(['descricaoTipo', 'longIdTipo'], {
		is: (descricaoTipo, longIdTipo) => [descricaoTipo, longIdTipo].includes(''),
		then: Yup.string().required('Campo Obrigatório: Tipo.'),
	}),
	carroceria: Yup.string().when(['descricaoCarroceria', 'longIdCarroceria'], {
		is: (descricaoCarroceria, longIdCarroceria) =>
			[descricaoCarroceria, longIdCarroceria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Carroceria.'),
	}),
	cor: Yup.string().when(['descricaoCor', 'longIdCor'], {
		is: (descricaoCor, longIdCor) => [descricaoCor, longIdCor].includes(''),
		then: Yup.string().required('Campo Obrigatório: Cor.'),
	}),
	categoria: Yup.string().when(['descricaoCategoria', 'longIdCategoria'], {
		is: (descricaoCategoria, longIdCategoria) =>
			[descricaoCategoria, longIdCategoria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Categoria.'),
	}),
	combustivel: Yup.string().when(
		['descricaoCombustivel', 'longIdCombustivel'],
		{
			is: (descricaoCombustivel, longIdCombustivel) =>
				[descricaoCombustivel, longIdCombustivel].includes(''),
			then: Yup.string().required('Campo Obrigatório: Combustível.'),
		},
	),
	especie: Yup.string().when(['descricaoEspecie', 'longIdEspecie'], {
		is: (descricaoEspecie, longIdEspecie) =>
			[descricaoEspecie, longIdEspecie].includes(''),
		then: Yup.string().required('Campo Obrigatório: Espécie.'),
	}),
	capacidadePassageiro: Yup.string().required(
		'Campo Obrigatório: Capacidade Passageiros.',
	),
	capacidadeCarga: Yup.string().required(
		'Campo Obrigatório: Capacidade Carga.',
	),
	potencia: Yup.string().required('Campo Obrigatório: Potência.'),
	cilindrada: Yup.string().required('Campo Obrigatório: Cilindrada.'),
	cmt: Yup.string().required('Campo Obrigatório: CMT.'),
	pbt: Yup.string().required('Campo Obrigatório: PBT.'),
	eixos: Yup.string().required('Campo Obrigatório: Eixos.'),
	procedencia: Yup.string().required(
		'Campo Obrigatório: Procedência do veículo.',
	),
	nomeProprietario: Yup.string().required('Campo Obrigatório: Nome.'),
	cepProprietario: Yup.string().required('Campo Obrigatório: CEP do imóvel.'),
	bairroProprietario: Yup.string().required('Campo Obrigatório: Bairro.'),
	enderecoProprietario: Yup.string().required('Campo Obrigatório: Endereço.'),
	numeroProprietario: Yup.string().required('Campo Obrigatório: Número.'),
});

export function treatValues(
	evento: IEvento,
	formValues: IEmitirBaixaVeiculo,
	veiculo: ICaracteristicaVeiculo,
	// codigoMunicipioProdesp: number,
): IRequestEmitirBaixaVeiculo {
	const {
		anoModelo,
		bairroProprietario,
		capacidadeCarga,
		capacidadePassageiro,
		cepProprietario,
		chassi,
		chassiRemarcado,
		cilindrada,
		cmt,
		codigoMunicipioProprietario,
		complementoProprietario,
		descricaoCarroceria,
		descricaoCategoria,
		descricaoCombustivel,
		descricaoCor,
		descricaoEspecie,
		descricaoMarca,
		descricaoMunicipioProprietario,
		descricaoTipo,
		eixos,
		enderecoProprietario,
		longIdCarroceria,
		longIdCategoria,
		longIdCombustivel,
		longIdCor,
		longIdEspecie,
		longIdMarca,
		longIdTipo,
		nomeProprietario,
		numeroEspelho,
		numeroProprietario,
		orgaoExpeditorProprietario,
		pbt,
		placa,
		potencia,
		procedencia,
		protocoloRemarcacao,
		rgProprietario,
		rgDigito,
		telefone,
		ufProprietario,
	} = formValues;

	const formatedRg = formatRg(rgProprietario, rgDigito);

	const formattedEvento = {
		id_atendimento: evento.id_atendimento,
		id_cidadao: evento.id_cidadao,
		ip: evento.ip,
		identificacao: evento.identificacao,
		cpf: evento.cpf || '',
		canal: {
			id: 1,
			desc: 'Presencial',
			localidade: {
				desc: evento.canal?.localidade.desc || '',
				id: evento.canal?.localidade.id || 0,
			},
		},
	};

	const requestValues = {
		evento: formattedEvento,
		anoModelo: Number(anoModelo),
		capacidadeCarga: capacidadeCarga ? Number(capacidadeCarga) : '0.00',
		capacidadePassageiro: Number(capacidadePassageiro),
		carroceria: {
			descricao: descricaoCarroceria,
			longId: Number(longIdCarroceria),
		},
		categoria: {
			descricao: descricaoCategoria,
			longId: Number(longIdCategoria),
		},
		chassi,
		chassiRemarcado,
		cilindrada: Number(cilindrada),
		cmt: Number(cmt),
		combustivel: {
			descricao: descricaoCombustivel,
			longId: Number(longIdCombustivel),
		},
		cor: {
			descricao: descricaoCor,
			longId: Number(longIdCor),
		},
		eixo: Number(eixos),
		especie: {
			descricao: descricaoEspecie,
			longId: Number(longIdEspecie),
		},
		marca: {
			descricao: descricaoMarca,
			longId: Number(longIdMarca),
		},
		municipio: {
			codigo: Number(codigoMunicipioProprietario),
			nome: descricaoMunicipioProprietario,
		},
		numeroEspelho: numeroEspelho ? Number(numeroEspelho) : '',
		pbt: Number(pbt),
		placa,
		potencia: Number(potencia),
		procedencia,
		proprietario: {} as
			| IProprietarioFisicoEmitirBaixaVeiculo
			| IProprietarioJuridicoEmitirBaixaVeiculo,
		protocoloRemarcacao: protocoloRemarcacao ? Number(protocoloRemarcacao) : '',
		tipo: {
			descricao: descricaoTipo,
			longId: Number(longIdTipo),
		},
	};

	const { identificacao } = veiculo.caracteristicaVeiculo.proprietario;

	if (identificacao === 'FISICA') {
		requestValues.proprietario = {
			bairro: bairroProprietario,
			cepProprietario: Number(limparMascara(cepProprietario)),
			complemento: complementoProprietario,
			enderecoProprietario,
			municipioProprietario: {
				// codigo: Number(codigoMunicipioProdesp),
				codigo: Number(codigoMunicipioProprietario),
				nome: descricaoMunicipioProprietario,
			},
			nomeProprietario,
			numero: Number(numeroProprietario),
			orgaoExpeditorProprietario,
			rgProprietario: formatedRg,
			telefone: telefone ? Number(limparMascara(telefone)) : '',
			ufProprietario,
		};
	} else {
		requestValues.proprietario = {
			bairro: bairroProprietario,
			cepProprietario: Number(limparMascara(cepProprietario)),
			complemento: complementoProprietario,
			enderecoProprietario,
			municipioProprietario: {
				// codigo: Number(codigoMunicipioProdesp),
				codigo: Number(codigoMunicipioProprietario),
				nome: descricaoMunicipioProprietario,
			},
			nomeProprietario,
			numero: Number(numeroProprietario),
			telefone: telefone ? Number(limparMascara(telefone)) : '',
		};
	}

	return requestValues;
}
