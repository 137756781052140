import React, { ChangeEvent, useCallback, useEffect } from 'react';

import { Row, Col, Divider } from 'antd';

// COMPONENTS
import CEPEnderecoRecebimento from 'pages/DetranCnh/components/common/Fields/CEPEnderecoRecebimento';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';

import { Field, FormikProps } from 'formik';
import Checkbox from 'components/Common/Form/Checkbox';
import { onlyNumbers, onlyNumbersLettersSpace } from 'utils/genericFunctions';

import { IBuscarCepResultadoEndRecebimento } from 'pages/DetranCnh/components/common/Fields/CEPEnderecoRecebimento/form';
import { ICadastroEndereco } from './form';

interface IProps {
	formik: FormikProps<ICadastroEndereco>;
	tipoProcedimento: boolean;
	renderData?: any;
}

const CadastroEndereco: React.FC<IProps> = ({
	formik,
	tipoProcedimento = false,
	renderData,
}) => {
	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultadoEndRecebimento) => {
			formik.setFieldValue(`enderecoRecebimentoCep`, res.cep);
			formik.setFieldValue(
				`enderecoRecebimentoLogradouro`,
				onlyNumbersLettersSpace(res.endereco.substring(0, 30)),
			);
			formik.setFieldValue(
				`enderecoRecebimentoBairro`,
				onlyNumbersLettersSpace(res.bairro.substring(0, 14)),
			);
			formik.setFieldValue(
				`enderecoRecebimentoMunicipio`,
				onlyNumbersLettersSpace(res.municipio),
			);
			formik.setFieldValue(
				`enderecoRecebimentoCodigoMunicipio`,
				res.numeroIBGE,
			);
		},
		[formik],
	);

	useEffect(() => {
		if (renderData) {
			formik.setFieldValue(
				`enderecoRecebimentoCep`,
				onlyNumbersLettersSpace(renderData[0].cep.toString()),
			);
			formik.setFieldValue(
				`enderecoRecebimentoLogradouro`,
				onlyNumbersLettersSpace(renderData[0].logradouro),
			);
			formik.setFieldValue(
				`enderecoRecebimentoBairro`,
				onlyNumbersLettersSpace(renderData[0].bairro),
			);
			formik.setFieldValue(
				`enderecoRecebimentoMunicipio`,
				onlyNumbersLettersSpace(renderData[0].nomeMunicipio),
			);
			formik.setFieldValue(
				`enderecoRecebimentoCodigoMunicipio`,
				onlyNumbersLettersSpace(renderData[0].codigoMunicipio),
			);
			formik.setFieldValue(
				`enderecoRecebimentoNumero`,
				onlyNumbersLettersSpace(renderData[0].numero),
			);
			formik.setFieldValue(
				`enderecoRecebimentoComplemento`,
				onlyNumbersLettersSpace(renderData[0].complemento),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [renderData]);

	return (
		<>
			{tipoProcedimento && (
				<>
					<Row>
						<Col span={12}>
							<h1 style={{ opacity: 0.7 }}>Endereço de Recebimento</h1>
						</Col>
					</Row>
					<Divider
						style={{ marginTop: 0, backgroundColor: '#000', opacity: 0.6 }}
					/>
				</>
			)}

			<Section
				title="CADASTRO DE NOVO ENDEREÇO PARA RECEBIMENTO DA CNH"
				titleSize="sm"
			>
				<Row justify="center">
					<Col span={24}>
						<Row gutter={[0, 10]}>
							<Col span={10}>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<CEPEnderecoRecebimento
											name="enderecoRecebimentoCep"
											formik={formik}
											defaultValue={formik.values.enderecoRecebimentoCep}
											titleSize="sm"
											instanceRequest="endereco_recebimento_cadastro_endereco_cep"
											resultEndRecebimento={(
												res: IBuscarCepResultadoEndRecebimento,
											) => handleCepSelecionado(res)}
										/>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={18}>
										<Field
											as={Input}
											title="Número"
											name="enderecoRecebimentoNumero"
											formik={formik}
											titleSize="sm"
											maxLength="6"
											fixedHint={6}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'enderecoRecebimentoNumero',
													onlyNumbers(event.target.value) || '',
												);
											}}
											disabled={
												formik.values.enderecoRecebimentoNumero === '000000'
											}
											error={!!formik.errors.enderecoRecebimentoNumero}
										/>
									</Col>
									<Col offset={1} span={4}>
										<Field
											subtitle="S/N"
											name="enderecoRecebimentoNumero"
											as={Checkbox}
											checked={
												formik.values.enderecoRecebimentoNumero === '000000'
											}
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												const { checked } = e.target;

												formik.setFieldValue(
													'enderecoRecebimentoNumero',
													checked ? '000000' : '',
												);
											}}
											// error={hasError(formik.errors, 'autorizaEmail')}
										/>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Field
											as={Input}
											title="Bairro"
											name="enderecoRecebimentoBairro"
											formik={formik}
											titleSize="sm"
											maxLength="14"
											fixedHint={14}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'enderecoRecebimentoBairro',
													onlyNumbersLettersSpace(event.target.value) || '',
												);
											}}
											error={!!formik.errors.enderecoRecebimentoBairro}
										/>
									</Col>
								</Row>
							</Col>

							<Col span={13} offset={1}>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Field
											as={Input}
											title="Logradouro"
											name="enderecoRecebimentoLogradouro"
											formik={formik}
											titleSize="sm"
											maxLength="30"
											fixedHint={30}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'enderecoRecebimentoLogradouro',
													onlyNumbersLettersSpace(event.target.value) || '',
												);
											}}
											error={!!formik.errors.enderecoRecebimentoLogradouro}
										/>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Field
											as={Input}
											title="Complemento"
											name="enderecoRecebimentoComplemento"
											formik={formik}
											entidade-militar
											titleSize="sm"
											maxLength="20"
											fixedHint={20}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'enderecoRecebimentoComplemento',
													onlyNumbersLettersSpace(event.target.value) || '',
												);
											}}
											error={!!formik.errors.enderecoRecebimentoComplemento}
										/>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Field
											as={Input}
											title="Município"
											name="enderecoRecebimentoMunicipio"
											formik={formik}
											titleSize="sm"
											disabled
											error={!!formik.errors.enderecoRecebimentoMunicipio}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Row gutter={[0, 5]}>
						<Col span={24}>
							<p style={{ justifyContent: 'center', fontWeight: 'bold' }}>
								O endereço informado valerá somente para recebimento do
								documento solicitado e não altera o endereço do cadastro no meu
								prontuário.
							</p>
						</Col>
					</Row>
				</Row>
			</Section>
		</>
	);
};

export default CadastroEndereco;
