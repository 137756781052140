import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_UTILS } from 'services/_path';

// ACTIONS
import { addNotifications } from 'store/modules/app/notifications/actions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	confrontarDigitaisSuccess,
	confrontarDigitaisFailure,
} from './actions';

function* confrontarDigitaisRequest(request: ReducerAction) {
	const { payload } = request;

	const {
		numeroRg,
		posicaoDedo,
		digital,
	}: { numeroRg: string; posicaoDedo: string; digital: string } = payload;

	const response: ApiResponse = yield call(
		postApi,
		PATH_UTILS,
		`/biometria/${CHANNEL}/confronto`,
		{
			rgSp: numeroRg,
			imagem: {
				indice: Number(posicaoDedo),
				tipo: 'DIGITAL',
				conteudo: digital,
			},
			finalidade: 'REUSO_RG',
			incluirPendentes: true,
		},
	);

	if (response.status === 200) {
		if (
			response.data.resultado === true ||
			response.data.resultado === 'true'
		) {
			yield put(confrontarDigitaisSuccess());
		} else {
			yield put(confrontarDigitaisFailure());
			addNotifications({
				errors: [
					'A conferência da biometria falhou. Realize o procedimento novamente.',
				],
			});
		}
	} else {
		yield put(confrontarDigitaisFailure());
		addNotifications({
			errors: [
				'A conferência da biometria falhou. Realize o procedimento novamente.',
			],
		});
	}
}

export default all([
	takeLatest(Types.CONFRONTAR_DIGITAIS_REQUEST, confrontarDigitaisRequest),
]);
