import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, Enderecos } from './types';

export const INITIAL_STATE: Enderecos = {
	status: 0,
	data: null,
	dataFailure: null,
	instanceRequest: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): Enderecos {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ENDERECOS_REQUEST: {
				draft.status = 100;
				draft.data = INITIAL_STATE.data;
				draft.instanceRequest = action.payload.instanceRequest;
				break;
			}

			case Types.ENDERECOS_SUCCESS: {
				draft.data = action.payload.response;
				draft.status = 200;
				draft.instanceRequest = action.payload.instanceRequest;
				break;
			}

			case Types.ENDERECOS_FAILURE: {
				draft.status = 400;
				draft.dataFailure = action.payload.response;
				draft.instanceRequest = action.payload.instanceRequest;
				break;
			}
			case Types.ENDERECOS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.instanceRequest = INITIAL_STATE.instanceRequest;
				break;
			}

			default:
		}
	});
}
