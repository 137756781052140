import React, { useCallback, useState } from 'react';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import {
	consultaDBCINIirgdClear,
	consultaDBCINIirgdRequest,
} from 'store/modules/api/iirgd/consultaDBCIN/actions';

// ANTD
import { Col, Row } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';

//	COMPONENTS COMMON
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';
import RG from 'components/Common/Form/Fields/RG';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { Field, Form, Formik, FormikProps } from 'formik';
import {
	IFormConsultarDBCIN,
	initialValues,
	schema,
	treatValues,
} from './form';

// COMPONENTS
import ResultadoDBCIN from '../ResultadoDBCIN';

const PesquisarDBCIN: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const [clearResult, setClearResult] = useState<boolean>(false);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	const { consultaDBCIN } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const handleValidate = useCallback((formValues: IFormConsultarDBCIN) => {
		return getValidationsErrors(formValues, schema);
	}, []);

	const handleChange = (rg: { digitoRg: string; numeroRg: string }) => {
		if (errors.length && rg.numeroRg.length < 8 && !rg.digitoRg.length) {
			dispatch(clearNotifications());
		}
	};

	const handleResetForm = useCallback(
		async (setFieldValue, handleReset) => {
			await setFieldValue('RGempty', true);

			handleReset();

			dispatch(clearNotifications());

			setClearResult(true);
		},
		[dispatch],
	);

	const handleBack = useCallback(() => {
		dispatch(clearNotifications());

		dispatch(consultaDBCINIirgdClear());

		history.push(ROUTE_IIRGD);
	}, [dispatch, history]);

	const handleSubmit = useCallback(
		(formValues: IFormConsultarDBCIN) => {
			setClearResult(false);

			const values = treatValues(formValues, login);

			dispatch(consultaDBCINIirgdRequest(values));
		},
		[dispatch, login],
	);

	return (
		<Row>
			<Col span={24}>
				<Section title="Consultar DBCIN">
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValues}
						validate={handleValidate}
						onSubmit={handleSubmit}
					>
						{(formik: FormikProps<any>) => {
							const { handleReset, setFieldValue } = formik;

							return (
								<Form autoComplete="off">
									<Row gutter={[0, 10]}>
										<Col span={19}>
											<RG
												title="RG"
												subtitle="(Apenas números e sem dígito. Ex: 99999999)"
												numero="numeroRg"
												digito="digitoRg"
												formik={formik}
												reset={!!formik.values.RGempty}
												result={rg => {
													setFieldValue('numeroRg', rg.numeroRg);
													setFieldValue('digitoRg', rg.digitoRg);
													handleChange(rg);
												}}
												error={hasError(formik.errors, 'validacaoRG')}
												autoFocus
												required
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Field
												as={InputMask}
												title="CPF"
												subtitle="(Apenas números. Ex: 999.999.999-99)"
												titleSize="md"
												size={24}
												name="cpf"
												mask="999.999.999-99"
												onChange={(e: { target: { value: string } }) => {
													formik.setFieldValue('cpf', e.target.value);
													dispatch(clearNotifications());
												}}
												required
												error={hasError(formik.errors, 'cpf')}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={24} />
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() =>
													handleResetForm(setFieldValue, handleReset)
												}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>
									</Row>
								</Form>
							);
						}}
					</Formik>

					{!clearResult && consultaDBCIN.data && <ResultadoDBCIN />}
				</Section>

				<ButtonVoltar navigate={false} onClick={() => handleBack()} />
			</Col>
		</Row>
	);
};

export default PesquisarDBCIN;
