import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarMensagemRequest } from './types';

export function cadastrarMensagemRequest(
	data: CadastrarMensagemRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_MENSAGEM_REQUEST,
		payload: data,
	};
}
export function cadastrarMensagemSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_MENSAGEM_SUCCESS,
		payload,
	};
}
export function cadastrarMensagemFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_MENSAGEM_FAILURE,
		payload: null,
	};
}
export function cadastrarMensagemClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_MENSAGEM_CLEAR,
		payload: null,
	};
}
