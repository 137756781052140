import React from 'react';

import { Row, Col } from 'antd';
import Input from 'components/Common/Form/Input/Input';
import { Container } from './styles';

interface IProps {
	exigProcesso01: string;
	exigProcesso02: string;
	exigProcesso03: string;
	exigProcesso04: string;
	exigProcesso05: string;
	exigProcesso06: string;
	exigProcesso07: string;
	exigProcesso08: string;
	exigProcesso09: string;
	exigProcesso10: string;
	exigVara01: string;
	exigVara02: string;
	exigVara03: string;
	exigVara04: string;
	exigVara05: string;
	exigVara06: string;
	exigVara07: string;
	exigVara08: string;
	exigVara09: string;
	exigVara10: string;
}
interface IData {
	data: IProps;
}

const ProcessoVara: React.FC<IData> = ({ data }) => {
	return (
		<Container>
			<Row gutter={15}>
				<Col span={6}>
					<h5>Processo</h5>
				</Col>
				<Col span={18}>
					<h5>Vara Criminal</h5>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigProcesso01" value={data?.exigProcesso01} disabled />
				</Col>
				<Col span={18}>
					<Input name={data?.exigVara01} value={data?.exigVara01} disabled />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigProcesso02" value={data?.exigProcesso02} disabled />
				</Col>
				<Col span={18}>
					<Input name="exigVara02" value={data?.exigVara02} disabled />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigProcesso03" value={data?.exigProcesso03} disabled />
				</Col>
				<Col span={18}>
					<Input name="exigVara03" value={data?.exigVara03} disabled />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigProcesso04" value={data?.exigProcesso04} disabled />
				</Col>
				<Col span={18}>
					<Input name="exigVara04" value={data?.exigVara04} disabled />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigProcesso05" value={data?.exigProcesso05} disabled />
				</Col>
				<Col span={18}>
					<Input name="exigVara05" value={data?.exigVara05} disabled />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigProcesso06" value={data?.exigProcesso06} disabled />
				</Col>
				<Col span={18}>
					<Input name="exigVara06" value={data?.exigVara06} disabled />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigProcesso07" value={data?.exigProcesso07} disabled />
				</Col>
				<Col span={18}>
					<Input name="exigVara07" value={data?.exigVara07} disabled />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigProcesso08" value={data?.exigProcesso08} disabled />
				</Col>
				<Col span={18}>
					<Input name="exigVara08" value={data?.exigVara08} disabled />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigProcesso09" value={data?.exigProcesso09} disabled />
				</Col>
				<Col span={18}>
					<Input name="exigVara09" value={data?.exigVara09} disabled />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigProcesso10" value={data?.exigProcesso10} disabled />
				</Col>
				<Col span={18}>
					<Input name="exigVara10" value={data?.exigVara10} disabled />
				</Col>
			</Row>
		</Container>
	);
};

export default ProcessoVara;
