import { combineReducers } from 'redux';

import consultaEndereco from './enderecos/reducer';
import consultarMunicipio from './consultarMunicipios/reducer';
import cadastroEnderecos from './cadastroEnderecos/reducer';
import consultaMunicipios from './consultaMunicipios/reducer';
import pesquisaEnderecosRecebimento from './pesquisaEnderecosRecebimento/reducer';
import consultaNomeSocialFiliacao from './consultaNomeSocialFiliacao/reducer';
import incluirExameMedico from './incluirExameMedicoDentroPosto/reducer';
import consultaCondutor from './consultaCondutor/reducer';

export default combineReducers({
	consultaEndereco,
	consultarMunicipio,
	cadastroEnderecos,
	consultaMunicipios,
	pesquisaEnderecosRecebimento,
	consultaNomeSocialFiliacao,
	incluirExameMedico,
	consultaCondutor,
});
