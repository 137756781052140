import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultaDivisaoEquitativa } from './types';

export function consultaDivisaoEquitativaRequest(
	data: IRequestConsultaDivisaoEquitativa,
): ReducerAction {
	return {
		type: Types.CONSULTA_DIVISAO_EQUITATIVA,
		payload: data,
	};
}
export function consultaDivisaoEquitativaSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_DIVISAO_EQUITATIVA_SUCCESS,
		payload,
	};
}
export function consultaDivisaoEquitativaFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.CONSULTA_DIVISAO_EQUITATIVA_FAILURE,
		payload,
	};
}
