import produce from 'immer';
import { toSelect } from 'utils/genericFunctions';
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaNacionalidades } from './types';

export const INITIAL_STATE: ConsultaNacionalidades = {
	status: 0,
	data: null,
};

export default function consultaNacionalidades(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaNacionalidades {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_NACIONALIDADES_REQUEST: {
				break;
			}
			case Types.CONSULTA_NACIONALIDADES_SUCCESS: {
				draft.status = action.payload.status;
				const lista = toSelect(action.payload.data, 'descricao', 'codigo');

				draft.data = [...lista];
				break;
			}
			case Types.CONSULTA_NACIONALIDADES_FAILURE: {
				draft.status = action.payload.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
		return draft;
	});
}
