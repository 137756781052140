import styled, { css } from 'styled-components';

interface Props {
	size: 'sm' | 'md' | 'lg';
	disabled: boolean;
}

export const Button = styled.button<Props>`
	background-color: transparent;
	border: unset;
	cursor: pointer;
	color: ${props => props.theme.typography.primary};
	display: flex;
	margin: 0 5px;

	${props =>
		props.size === 'sm' &&
		css`
			font-size: 0.8em;
		`};

	${props =>
		props.size === 'md' &&
		css`
			font-size: 1em;
		`};

	${props =>
		props.size === 'lg' &&
		css`
			font-size: 1.2em;
		`};

	${props =>
		props.disabled &&
		css`
			cursor: not-allowed;
			opacity: 0.25;
		`};
`;
