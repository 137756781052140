import { combineReducers } from 'redux';

import atualizarMunicipioIbge from './atualizarMunicipioIbge/reducer';
import consultarMunicipiosIbge from './consultarMunicipiosIbge/reducer';
import cadastrarMunicipioIbge from './cadastrarMunicipioIbge/reducer';
import excluirMunicipioIbge from './excluirMunicipioIbge/reducer';

export default combineReducers({
	atualizarMunicipioIbge,
	consultarMunicipiosIbge,
	cadastrarMunicipioIbge,
	excluirMunicipioIbge,
});
