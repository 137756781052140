import styled from 'styled-components';

interface ContainerProps {
	isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
	color: #c33;
	background-color: #f8eeee;
	border: 1px solid #c33;
	border-radius: 4px;
	margin: 5px auto 10px auto;
	width: 100%;

	svg {
		color: #c53030;
		margin: 15px auto;
		width: 100%;
	}
`;

export const Content = styled.div`
	align-items: baseline;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 10px 5px;
	position: relative;
`;

export const Item = styled.p`
	color: #000;
	display: flex;
	font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
	font-weight: 500;
	justify-content: space-between;
	margin-bottom: 0px;
	padding: 2px 30px 2px 10px;
	max-width: 100%;
`;

export const CloseButton = styled.button`
	align-items: center;
	background-color: transparent;
	border: unset;
	cursor: pointer;
	display: flex;
	height: 25px;
	justify-content: center;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 25px;

	&:hover {
		box-shadow: unset;
	}

	span {
		color: #000000;
		font-size: calc(1.3rem + ${props => props.theme.fontScale}%);
		font-weight: 700;
	}
`;
