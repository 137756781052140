import { useEffect } from 'react';

interface Props<T> {
	dirty: boolean;
	values: T;
	onChange: (values: T) => void;
}

export function FormikObserver<T>(props: Props<T>) {
	useEffect(() => {
		if (props.dirty) props.onChange(props.values);
	}, [props]);

	return null;
}

FormikObserver.defaultProps = {
	onChange: () => null,
};

export function FormikFildObserver<T>(props: Props<T>) {
	const { values } = props;
	useEffect(() => {
		if (values) props.onChange(values);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values]);

	return null;
}

FormikFildObserver.defaultProps = {
	onChange: () => null,
};
