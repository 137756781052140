// TYPES
import { ReducerAction } from 'store/modules/types';
import { DareKitData, Types } from './types';

interface IRequestTaxasDare {
	taxasCorreio: boolean;
	numeroRg: string;
	nome: string;
}

export interface IRequestGerarDare {
	cpf: string;
	enderecoContribuinte: string;
	postoCodigo: number;
	postoDescricao: string;
	ipUsuario: string;
	emailTo?: string;
	taxasReq: IRequestTaxasDare[];
	idCidadao: string;
	usuario: string;
	senha: string;
}

export function gerarDareRequest(payload: IRequestGerarDare): ReducerAction {
	return {
		type: Types.GERAR_DARE_REQUEST,
		payload,
	};
}
export function gerarDareSuccess(payload: object): ReducerAction {
	return {
		type: Types.GERAR_DARE_SUCCESS,
		payload,
	};
}
export function gerarDareFailure(): ReducerAction {
	return {
		type: Types.GERAR_DARE_FAILURE,
		payload: null,
	};
}
export function gerarDareClear(): ReducerAction {
	return {
		type: Types.GERAR_DARE_CLEAR,
		payload: null,
	};
}

export function dareKitRequest(payload: DareKitData): ReducerAction {
	return {
		type: Types.DARE_KIT_REQUEST,
		payload,
	};
}
export function dareKitClear(): ReducerAction {
	return {
		type: Types.DARE_KIT_CLEAR,
		payload: null,
	};
}
