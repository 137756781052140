import React, { useCallback, useEffect, useState } from 'react';

// ANTD
import { Col, Row } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import {
	cancelarSolicitacaoEmissaoPIDClear,
	cancelarSolicitacaoEmissaoPIDRequest,
} from 'store/modules/api/detranCnh/solicitacaoEmissaoPID/cancelarSolicitacao/actions';

// COMPONENTS COMMON
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import Alert from 'components/Common/Notifications/Alert';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import { onlyAlfaNumerico } from 'utils/genericFunctions';

// PATHS
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// FORM
import { Formik, Form, Field, FormikProps } from 'formik';
import {
	CancelarSolicitacaoEmissaoPIDForm,
	initalValues,
	schema,
	treatCancelarSolicitacaoEmissaoPIDRequest,
} from './form';

const CancelarSolicitacaoEmissaoPIDPesquisa: React.FC = () => {
	const dispatch = useDispatch();

	const [showAlert, setShowAlert] = useState<boolean>(false);

	const { cancelarSolicitacaoEmissaoPID } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.solicitacaoEmissaoPID,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const handleSubmit = useCallback(
		(values: CancelarSolicitacaoEmissaoPIDForm) => {
			setShowAlert(false);

			const treatedPayload = treatCancelarSolicitacaoEmissaoPIDRequest(
				values,
				dadosAtendimento,
				user,
			);
			dispatch(cancelarSolicitacaoEmissaoPIDRequest(treatedPayload));
		},
		[dadosAtendimento, dispatch, user],
	);

	const handleClear = useCallback(
		(formik: FormikProps<CancelarSolicitacaoEmissaoPIDForm>) => {
			formik.resetForm();
			dispatch(cancelarSolicitacaoEmissaoPIDClear());
			dispatch(clearNotifications());
			setShowAlert(false);
		},
		[dispatch],
	);

	useEffect(() => {
		if (cancelarSolicitacaoEmissaoPID.status === 200) {
			setShowAlert(true);
		}
	}, [cancelarSolicitacaoEmissaoPID.status]);

	return (
		<>
			{showAlert && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Alert
							type="success"
							showIcon
							message="Cancelado com sucesso!"
							closable
							onClose={() => setShowAlert(false)}
						/>
					</Col>
				</Row>
			)}

			<Section title="Pesquisar">
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initalValues}
							validate={values => getValidationsErrors(values, schema)}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row align="middle" justify="start">
										<Col span={24}>
											<Row>
												<Col span={12}>
													<Field
														as={InputMask}
														title="CPF"
														titleSize="70"
														name="cpf"
														mask="999.999.999-99"
														size={40}
														subtitle="(Digite apenas números)"
														placeholder=""
														error={hasError(formik.errors, 'cpf')}
														disabled={
															cancelarSolicitacaoEmissaoPID.status === 100
														}
													/>
												</Col>
											</Row>
											<Row>
												<Col span={12}>
													<Field
														as={Input}
														title="N° Registro"
														name="numeroRegistro"
														titleSize="70"
														size={40}
														onChange={(e: React.FormEvent<HTMLInputElement>) =>
															formik.setFieldValue(
																'numeroRegistro',
																onlyAlfaNumerico(e.currentTarget.value),
															)
														}
														error={hasError(formik.errors, 'numeroRegistro')}
														disabled={
															cancelarSolicitacaoEmissaoPID.status === 100
														}
													/>
												</Col>
											</Row>
										</Col>
									</Row>

									<Row gutter={[10, 10]} justify="center" align="middle">
										<Col>
											<ButtonImage
												type="reset"
												src="limpar"
												onClick={() => handleClear(formik)}
												disabled={cancelarSolicitacaoEmissaoPID.status === 100}
											/>
										</Col>
										<Col>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>

			<Row justify="space-between" align="middle">
				<Col>
					<ButtonVoltar route={ROUTE_DETRAN_CNH} />
				</Col>
			</Row>
		</>
	);
};
export default CancelarSolicitacaoEmissaoPIDPesquisa;
