import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { Types, ConsultarSiglasRequest } from './types';

// ACTIONS
import { consultarSiglasSuccess, consultarSiglasFailure } from './actions';

function* consultarSiglas(request: ReducerAction) {
	const { payload }: { payload: ConsultarSiglasRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarSiglasRequest;

	const response: ApiResponse = yield call(getApi, PATH_SGU, `siglas`, body);

	if (response.status === 200) {
		yield put(consultarSiglasSuccess(response));
	} else {
		yield put(consultarSiglasFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_SIGLAS_REQUEST, consultarSiglas),
]);
