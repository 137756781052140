import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaDBCIN } from './types';

export const INITIAL_STATE: ConsultaDBCIN = {
	status: 0,
	data: null,
	request: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaDBCIN {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_DBCIN_IIRGD_REQUEST: {
				draft.request = action.payload.request;
				break;
			}

			case Types.CONSULTA_DBCIN_IIRGD_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.data;
				draft.request = action.payload.request;
				break;
			}

			case Types.CONSULTA_DBCIN_IIRGD_FAILURE: {
				draft.status = 400;
				draft.data = action.payload;
				draft.request = action.payload.request;
				break;
			}

			case Types.CONSULTA_DBCIN_IIRGD_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.request = INITIAL_STATE.request;
				break;
			}

			default:
		}

		return draft;
	});
}
