import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ReducerAction } from 'store/modules/types';
import { PATH_EDUCACAO_SEDUC_INSCRICOES_API } from 'services/rotasp/path';
import { ApiResponse, getApi } from 'services/rotasp/api';
import { ComprovanteInscricaoRequest, Types } from './types';
import {
	comprovanteInscricaoSuccess,
	comprovanteInscricaoFailure,
} from './actions';

function* comprovanteInscricaoRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: ComprovanteInscricaoRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_EDUCACAO_SEDUC_INSCRICOES_API,
		`comprovantes`,
		payload,
	);
	if (response.status === 200) {
		yield put(comprovanteInscricaoSuccess(response));
	} else if (response.error) {
		yield put(comprovanteInscricaoFailure(response.message));
	}
}

export default all([
	takeLatest(Types.COMPROVANTE_INSCRICAO_REQUEST, comprovanteInscricaoRequest),
]);
