import produce from 'immer';

// TYPES
import { Types, ConsultaCondutor } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultaCondutor = {
	status: 0,
	data: null,
};

export default function consultaCondutorReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaCondutor {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_CONDUTOR_REQUEST: {
				draft.status = 100;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.CONSULTA_CONDUTOR_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTA_CONDUTOR_FAILURE: {
				draft.status = action.payload.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.CONSULTA_CONDUTOR_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
