export enum Types {
	SALVA_SENHA_USUARIO_REQUEST = '@sgu-service/SALVA_SENHA_USUARIO_REQUEST',
	SALVA_SENHA_USUARIO_SUCCESS = '@sgu-service/SALVA_SENHA_USUARIO_SUCCESS',
	SALVA_SENHA_USUARIO_FAILURE = '@sgu-service/SALVA_SENHA_USUARIO_FAILURE',
	SALVA_SENHA_USUARIO_CLEAR = '@sgu-service/SALVA_SENHA_USUARIO_CLEAR',
}

export interface SalvaSenhaUsuario {
	status: number;
	data: null | SalvaSenhaUsuarioResponse;
	form: null | SalvaSenhaUsuarioRequest;
}
export interface SalvaSenhaUsuarioRequest {
	idUsuarioMainframe: string;
	idLocalidade: string;
	cpf: string;
	senha: string;
	senhaMainframe: string;
}

export interface SalvaSenhaUsuarioResponse {
	idUsuarioMainframe: string;
	idLocalidade: string;
	cpf: string;
	senha: string;
	senhaMainframe: string;
}
