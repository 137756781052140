import React from 'react';

// REDUX
import { TitleSectionAtendimento } from 'store/modules/api/procon/enum';

// ANT DESIGN
import { Col, Row } from 'antd';

// COMPONENTS
import LightButton from 'components/Common/Buttons/Light';
import TypographyCommon from 'components/Common/Typography';

// STYLED
import { ConsultaAtendimentosDeConsultaData } from 'store/modules/api/procon/atendimentosDeConsulta/consultaAtendimentosDeConsulta/types';
import { styleCols } from '../../styled';

interface Props {
	handleBackSection: React.MouseEventHandler<HTMLButtonElement>;
	handleNextSection: React.MouseEventHandler<HTMLButtonElement>;
	formData: ConsultaAtendimentosDeConsultaData | null;
}

const FornecedorTipoConsulta: React.FC<Props> = ({
	handleBackSection,
	handleNextSection,
	formData,
}) => {
	return (
		<>
			<Row gutter={[0, 0]} align="top" justify="start">
				<Col className="gutter-row" span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Dados do Fornecedor"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Fornecedor:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.nomeFornecedor || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Nome Fantasia:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.nomeFantasiaFornecedor || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Tipo de Fornecedor:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.tipoDoFornecedor || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="CPF/CNPJ do Fornecedor:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.fornecedor &&
										(formData?.fornecedor.cnpj ?? formData?.fornecedor.cpf)) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="Telefone:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.fornecedor && formData?.fornecedor.telefone) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="Site:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.fornecedor && formData?.fornecedor.website) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="E-mail:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.fornecedor && formData?.fornecedor.email) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Logradouro:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.fornecedor &&
										formData?.fornecedor.endereco.logradouro) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="CEP:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.fornecedor && formData?.fornecedor.endereco.cep) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="Bairro:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.fornecedor &&
										formData?.fornecedor.endereco.bairro) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={12}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Complemento:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.fornecedor &&
										formData?.fornecedor.endereco.complemento) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="Cidade:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.fornecedor &&
										formData?.fornecedor.endereco.cidade) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="Estado:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.fornecedor &&
										formData?.fornecedor.endereco.estado) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={12}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Procurei o Fornecedor para Solucionar a Questão?"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									formData?.procureiOFornecedorParaSolucionarAQuestao ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Forma de Devolução:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.formaDeDevolucao || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={12}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="Pedido:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.pedidoDoConsumidor || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={12}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Informe como foi seu Contato"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									formData?.descricaoDoPrimeiroContatoComFornecedor ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={24}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Detalhes da Reclamação:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.detalhesDaReclamacao || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<br />
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span="12">
					<LightButton
						type="button"
						buttonColor="default"
						text="Voltar"
						icon="back"
						buttonSize="sm"
						buttonWidth={100}
						onClick={handleBackSection}
					/>
				</Col>
				<Col className="gutter-row" span="12">
					<Row align="top" justify="end">
						<LightButton
							type="button"
							buttonColor="default"
							text={TitleSectionAtendimento.RESPOSTAS_INTERACOES}
							icon="next"
							buttonSize="sm"
							buttonWidth={190}
							leftOrRight="right"
							onClick={handleNextSection}
						/>
					</Row>
				</Col>
			</Row>
		</>
	);
};
export default FornecedorTipoConsulta;
