import styled from 'styled-components';

export const BoxRenach = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	justify-content: center;
	width: 100%;
	background-color: #f8eeee;
	border: solid 1px #c33;
	border-radius: 5px;
	margin-top: 30px;

	> h2 {
		color: #c33;
		margin-bottom: 20px;
	}
`;
