import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../types';
import { Types, DareState } from './types';

import atualizarDare from './atualizarDare/reducer';
import consultarDare from './consultarDare/reducer';
import consultarDareRetaguarda from './consultarDareRetaguarda/reducer';
import dependentesDare from './dependentesDare/reducer';
import imprimirDare from './imprimirDare/reducer';
import statusDare from './statusDare/reducer';
import statusDareMainframe from './statusDareMainframe/reducer';

const allReducers = combineReducers({
	atualizarDare,
	consultarDare,
	consultarDareRetaguarda,
	dependentesDare,
	imprimirDare,
	statusDare,
	statusDareMainframe,
});

const combineReducer = (state: DareState, action: ReducerAction) => {
	if (action.type === Types.DARE_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
