import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import data from 'store/modules/enum';
import dataCrv from 'store/modules/api/detranCrv/enum';
import {
	consultarMunicipioCodigoProdesClear,
	consultarMunicipioCodigoProdespRequest,
} from 'store/modules/api/detranCrv/enum/consultarMunicipioCodigoProdesp/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import Placa from 'components/Common/Form/Fields/Placa';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import CpfCnpj from 'components/Common/Form/Fields/CpfCnpj';

// UTILS
import hasError from 'utils/getFormErrors';
import {
	onlyLetters,
	onlyNumbers,
	onlyNumbersLettersSpace,
} from 'utils/genericFunctions';

// FORM
import { IEmiteTransferenciaVeiculo, initialValues } from '../form';

interface Props {
	formik: FormikProps<IEmiteTransferenciaVeiculo>;
	isOpenDocument: boolean;
}

const DadosProprietario: React.FC<Props> = ({
	formik,
	isOpenDocument = false,
}) => {
	const dispatch = useDispatch();

	const { consultarMunicipioPorUF, consultarMunicipioCodigoProdesp } =
		useSelector((state: ApplicationState) => state.api.detranCrv.Enum);

	const { consultarVeiculoV110 } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const [tipoPessoa, setTipoPessoa] = useState<string>('');

	useEffect(() => {
		formik.setFieldValue('servicoDigital', isOpenDocument);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			consultarMunicipioCodigoProdesp.data &&
			consultarMunicipioCodigoProdesp.status === 200
		) {
			formik.setFieldValue(
				'codigoMunicipioProprietario',
				Number(consultarMunicipioCodigoProdesp.data.codigoPRODESP),
			);
			dispatch(consultarMunicipioCodigoProdesClear());
		}
	}, [
		consultarMunicipioCodigoProdesp.data,
		consultarMunicipioCodigoProdesp.status,
		dispatch,
		formik,
	]);

	const clearFormikAddressFields = useCallback(() => {
		formik.setFieldValue('complementoProprietario', '');
		formik.setFieldValue('numeroProprietario', '');
	}, [formik]);

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado) => {
			clearFormikAddressFields();

			if (res.codigoMunicipio) {
				const consultaMunicipioData = {
					evento: null,
					codigoMunicipioCorreio: res.codigoMunicipio.toString(),
				};
				dispatch(consultarMunicipioCodigoProdespRequest(consultaMunicipioData));
			}
			formik.setFieldValue('cepProprietario', res.cep);
			formik.setFieldValue('bairroProprietario', res.bairro.substring(0, 70));
			formik.setFieldValue(
				'enderecoProprietario',
				res.endereco.substring(0, 70),
			);
			formik.setFieldValue('descricaoMunicipioProprietario', res.municipio);
		},
		[clearFormikAddressFields, dispatch, formik],
	);

	useEffect(() => {
		if (consultarVeiculoV110.data) {
			if (
				consultarVeiculoV110.data?.caracteristicaVeiculo?.indicadorRenave ===
					1 ||
				consultarVeiculoV110.data?.caracteristicaVeiculo?.indicadorRenave === 2
			) {
				const { tipoDocumentoLojaComprador } =
					consultarVeiculoV110.data.caracteristicaVeiculo;

				setTipoPessoa(tipoDocumentoLojaComprador === 1 ? 'FISICA' : 'JURIDICA');
			} else {
				const { proprietario } =
					consultarVeiculoV110.data.caracteristicaVeiculo;

				setTipoPessoa(proprietario.identificacao);
			}
		}
	}, [consultarVeiculoV110.data]);

	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section size="lg" title="Dados do Proprietário" titleSize="sm">
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Field
									as={Input}
									title="Nome do Proprietário"
									titleSize="xl"
									name="nomeProprietario"
									maxLength={60}
									size={87}
									required
									onChange={(event: ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'nomeProprietario',
											onlyNumbersLettersSpace(
												event.target.value,
											).toLocaleUpperCase(),
										);
									}}
									error={!!formik.errors.nomeProprietario}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<CEP
									title="CEP do Imóvel do Proprietário"
									titleSize="xl"
									name="cepProprietario"
									defaultValue={formik.values.cepProprietario}
									required
									size={80}
									formik={formik}
									result={(res: IBuscarCepResultado) => {
										handleCepSelecionado(res);
									}}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Input}
									title="Bairro"
									titleSize="xl"
									name="bairroProprietario"
									maxLength={70}
									size={92}
									required
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'bairroProprietario',
											onlyLetters(e.target.value),
										);
									}}
									error={!!formik.errors.bairroProprietario}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Endereço do Proprietário"
									titleSize="xl"
									name="enderecoProprietario"
									maxLength={70}
									size={80}
									required
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'enderecoProprietario',
											onlyNumbersLettersSpace(e.target.value),
										);
									}}
									error={!!formik.errors.enderecoProprietario}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={InputMask}
									title="Número"
									titleSize="xl"
									name="numeroProprietario"
									mask="99999"
									maskChar=""
									alwaysShowMask={false}
									size={92}
									required
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'numeroProprietario',
											onlyNumbers(e.target.value),
										);
									}}
									error={!!formik.errors.numeroProprietario}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Complemento"
									titleSize="xl"
									name="complementoProprietario"
									maxLength={6}
									size={80}
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'complementoProprietario',
											onlyNumbersLettersSpace(e.target.value),
										);
									}}
									error={!!formik.errors.complementoProprietario}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Input}
									title="Telefone"
									titleSize="xl"
									name="telefone"
									maxLength={9}
									size={92}
									// required={formik.values.servicoDigital}
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'telefone',
											onlyNumbers(e.target.value),
										);
									}}
									error={!!formik.errors.telefone}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Select}
									title="Município do Proprietário"
									titleSize="xl"
									name="codigoMunicipioProprietario"
									options={consultarMunicipioPorUF.data}
									onChange={(v: number) => {
										formik.setFieldValue('codigoMunicipioProprietario', v);

										const selectedItem = consultarMunicipioPorUF.data.filter(
											item => v === item.value,
										);

										formik.setFieldValue(
											'descricaoMunicipioProprietario',
											selectedItem[0].label,
										);
									}}
									size={80}
									required
									error={hasError(formik.errors, 'codigoMunicipioProprietario')}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Select}
									title="Identificação do Contribuinte"
									titleSize="xl"
									name="identificacao"
									defaultFirstOption={false}
									options={dataCrv.identificacao}
									onChange={(v: string) => {
										formik.setFieldValue('identificacao', v);
										setTipoPessoa(v);
									}}
									size={92}
									error={hasError(formik.errors, 'identificacao')}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<CpfCnpj
									title="CPF/CNPJ Proprietário"
									titleSize="xl"
									name="cpfCnpjProprietario"
									defaultValue={initialValues.cpfCnpjProprietario}
									maskType={
										formik.values.identificacao === 'FISICA' ? 'cpf' : 'cnpj'
									}
									size={80}
									required
									formik={formik}
								/>
							</Col>

							{tipoPessoa === 'FISICA' && (
								<Col
									span={11}
									style={{
										marginLeft: '40px',
									}}
								>
									<Row>
										<Col span={18}>
											<Field
												as={InputMask}
												title="RG"
												titleSize="lg"
												name="rgProprietario"
												mask="99.999.999"
												required
												error={hasError(formik.errors, 'rgProprietario')}
											/>
										</Col>
										<Col
											span={1}
											style={{
												alignSelf: 'center',
												textAlign: 'center',
											}}
										>
											<span>-</span>
										</Col>
										<Col span={2}>
											<Field
												as={InputMask}
												mask="9"
												name="rgDigito"
												error={hasError(formik.errors, 'rgDigito')}
												size={85}
											/>
										</Col>
									</Row>
								</Col>
							)}
						</Row>

						{tipoPessoa === 'FISICA' && (
							<>
								<Row gutter={[0, 10]}>
									<Col span={11}>
										<Field
											as={Input}
											title="Órgão Expedidor"
											titleSize="xl"
											name="orgaoExpeditorProprietario"
											maxLength={5}
											size={80}
											required
											onChange={(e: { target: { value: string } }) => {
												formik.setFieldValue(
													'orgaoExpeditorProprietario',
													onlyLetters(e.target.value),
												);
											}}
											error={hasError(
												formik.errors,
												'orgaoExpeditorProprietario',
											)}
										/>
									</Col>

									<Col span={11}>
										<Field
											as={Select}
											title="UF"
											titleSize="xl"
											name="ufProprietario"
											defaultFirstOption={false}
											options={data.uf}
											onChange={(value: string) =>
												formik.setFieldValue('ufProprietario', value)
											}
											size={92}
											required
											error={hasError(formik.errors, 'ufProprietario')}
										/>
									</Col>
								</Row>
							</>
						)}

						{isOpenDocument && (
							<Row gutter={[0, 10]}>
								<Col span={11}>
									<Field
										as={Input}
										title="E-mail"
										titleSize="xl"
										name="emailCidadao"
										maxLength={60}
										size={80}
										required={formik.values.servicoDigital}
										error={hasError(formik.errors, 'emailCidadao')}
									/>
								</Col>

								<Col span={11}>
									<Field
										as={InputMask}
										title="Telefone de Contato"
										titleSize="xl"
										name="dddTelefoneCidadao"
										mask="(99) 9999-99999"
										size={92}
										required={formik.values.servicoDigital}
										error={!!formik.errors.dddTelefoneCidadao}
									/>
								</Col>
							</Row>
						)}

						<Row gutter={tipoPessoa === 'FISICA' ? [0, 10] : undefined}>
							<Col span={11}>
								<Field
									as={Input}
									title="Nome do Proprietário Anterior"
									titleSize="xl"
									name="nomeProprietarioAnterior"
									maxLength={60}
									size={80}
									required
									onChange={(event: ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'nomeProprietarioAnterior',
											onlyNumbersLettersSpace(
												event.target.value,
											).toLocaleUpperCase(),
										);
									}}
									error={hasError(formik.errors, 'nomeProprietarioAnterior')}
								/>
							</Col>

							<Col span={11}>
								<Placa
									title="Placa Anterior"
									titleSize="xl"
									name="placaAnterior"
									defaultValue={initialValues.placaAnterior}
									size={92}
									required
									formik={formik}
								/>
							</Col>
						</Row>

						<Row gutter={tipoPessoa === 'FISICA' ? [0, 10] : undefined}>
							<Col span={11}>
								<Field
									as={Select}
									title="Município Placa Anterior"
									titleSize="xl"
									name="codigoMunicipioPlacaAnterior"
									options={consultarMunicipioPorUF.data}
									onChange={(v: number) => {
										formik.setFieldValue('codigoMunicipioPlacaAnterior', v);

										const selectedItem = consultarMunicipioPorUF.data.filter(
											item => v === item.value,
										);

										formik.setFieldValue(
											'descricaoMunicipioPlacaAnterior',
											selectedItem[0].label,
										);
									}}
									size={80}
									required
									disabled
									error={hasError(
										formik.errors,
										'codigoMunicipioPlacaAnterior',
									)}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Select}
									title="UF da Placa Anterior"
									titleSize="xl"
									name="ufPlacaAnterior"
									defaultFirstOption={false}
									options={data.uf}
									onChange={(value: string) =>
										formik.setFieldValue('ufPlacaAnterior', value)
									}
									size={92}
									disabled
									required
								/>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default DadosProprietario;
