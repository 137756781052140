import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarMensagemErroRequest } from './types';

export function cadastrarMensagemErroRequest(
	data: CadastrarMensagemErroRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_MENSAGEM_ERRO_REQUEST,
		payload: data,
	};
}
export function cadastrarMensagemErroSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_MENSAGEM_ERRO_SUCCESS,
		payload,
	};
}
export function cadastrarMensagemErroFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_MENSAGEM_ERRO_FAILURE,
		payload: null,
	};
}
export function cadastrarMensagemErroClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_MENSAGEM_ERRO_CLEAR,
		payload: null,
	};
}
