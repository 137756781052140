import * as Yup from 'yup';

import { IEvento } from 'store/modules/api/detranCrv/Processos/ImpressaoTrocaVoluntariaPlaca/types';

export interface IImpressaoTrocaVoluntariaPlaca {
	anoFicha: string;
	numeroFicha: string;
}

export const initialValues: IImpressaoTrocaVoluntariaPlaca = {
	anoFicha: '',
	numeroFicha: '',
};

export const schema = Yup.object<IImpressaoTrocaVoluntariaPlaca>().shape({
	numeroFicha: Yup.string().required(`Campo Obrigatório: Número da Ficha.`),
	anoFicha: Yup.string().required(`Campo Obrigatório: Ano da Ficha Cadastral.`),
});

export function treatValues(
	evento: IEvento,
	formValues: IImpressaoTrocaVoluntariaPlaca,
): IImpressaoTrocaVoluntariaPlaca {
	const { anoFicha, numeroFicha } = formValues;

	const formattedValues = {
		anoFicha,
		evento,
		numeroFicha,
	};

	return formattedValues;
}
