import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, consultaAtestadoNominal } from './types';

export const INITIAL_STATE: consultaAtestadoNominal = {
	status: 0,
	data: {
		atestadoEmitido: false,
		codigoRetorno: '',
		mensagemRetorno: '',
		arquivoPDF: '',
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaAtestadoNominal {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_ATESTADO_NOMINAL_REQUEST: {
				break;
			}

			case Types.CONSULTA_ATESTADO_NOMINAL_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTA_ATESTADO_NOMINAL_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.LIMPA_CONSULTA_NOMINAL: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
