export enum Types {
	ENVIA_INTERACOES_ATENDIMENTO_REQUEST = '@procon/ENVIA_INTERACOES_ATENDIMENTO_REQUEST',
	ENVIA_INTERACOES_ATENDIMENTO_SUCCESS = '@procon/ENVIA_INTERACOES_ATENDIMENTO_SUCCESS',
	ENVIA_INTERACOES_ATENDIMENTO_FAILURE = '@procon/ENVIA_INTERACOES_ATENDIMENTO_FAILURE',
	ENVIA_INTERACOES_ATENDIMENTO_CLEAR = '@procon/ENVIA_INTERACOES_ATENDIMENTO_CLEAR',
}

export interface EnviaInteracoesAtendimento {
	status: null | number;
	data: null | EnviaInteracoesAtendimentoData;
	form: null | EnviaInteracoesAtendimentoRequest;
}

interface EnviaInteracoesAtendimentoData {}

export interface EnviaInteracoesAtendimentoRequest {
	atendimentoId?: string | null;
	protocolo?: string | null;
	textoDaInteracao: string;
	anexos?: Anexos[];
}

export interface EnviaInteracoesAtendimentoForm {
	respostaConsumidor: string;
	anexos: Anexos[];
	anexosTipo?: string;
}
export interface Anexos {
	nome: string;
	base64: string;
}
