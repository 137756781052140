import { ICadastraExameMedico } from 'pages/DetranCnh/components/pages/AgendamentoMedico/form';
import { IDadosCidadao } from 'pages/DetranCnh/components/pages/DadosCidadao/form';
import { DIVISAO_EQUITATIVA_PARAMS } from 'pages/DetranCnh/utils/constants/services';

export const initialValues: ICadastraExameMedico = {
	cpf_cidadao: 0,
	tipo_processo: 'CASSACAO_REABILTIACAO',
	cep_cidadao: 0,
	categoria_pretendida: '',
	id_medico: 0,
	data_exame: '',
	horario_exame: '',
	codigo_ciretran_exame_medico: 0,
	renach: '',
	id_micro_regiao_medico: '',
	usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
	regiao: '',
};

export const dadosCidadao: IDadosCidadao = {
	nome: '',
	cpf: '',
	nomePai: '',
	nomeMae: '',
	naturalidade: '',
	numeroRg: '',
	rne: '',
	dataNascimento: '',
	sexo: '',
	nacionalidade: '',
	renach: '',
	codigoCiretran: 0,
	categoriaPretendida: '',
	tipoProcesso: '',
	cep: '',
	isPortadorNecessidadesEspeciais: false,
};
