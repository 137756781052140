export enum Types {
	EMITIR_TRANSFERENCIAS_PROPRIETARIOS_REQUEST = '@DETRAN-CRV/EMITIR_TRANSFERENCIAS_PROPRIETARIOS_REQUEST',
	EMITIR_TRANSFERENCIAS_PROPRIETARIOS_SUCCESS = '@DETRAN-CRV/EMITIR_TRANSFERENCIAS_PROPRIETARIOS_SUCCESS',
	EMITIR_TRANSFERENCIAS_PROPRIETARIOS_FAILURE = '@DETRAN-CRV/EMITIR_TRANSFERENCIAS_PROPRIETARIOS_FAILURE',
	EMITIR_TRANSFERENCIAS_PROPRIETARIOS_CLEAR = '@DETRAN-CRV/EMITIR_TRANSFERENCIAS_PROPRIETARIOS_CLEAR',
}

export interface emitirTransferenciasProprietarios {
	status: number;
	data: IResponseTransferenciasProprietarios | null;
}
export interface IResponseTransferenciasProprietarios {
	chassi: string;
	placa: string;
	anoFicha: number;
	numeroficha: number;
	planilhaRenavam: string;
}

export interface IRequestWithHeader {
	body: IRequestTransferenciasProprietarios;
	headers?: any;
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	ip: string;
	cpf: string;
	identificacao: string;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface IRequestTransferenciasProprietarios {
	evento: IEvento;
	dadosTransferencia: {
		anoFabricacao: number;
		anoModelo: number;
		capacidadeCarga: number;
		capacidadePassageiros: number;
		carroceria: {
			longId: number;
			descricao: string;
		};
		categoria: {
			descricao: string;
			longId: number;
		};
		chassi: string;
		chassiRemarcado: boolean;
		cilindrada: number;
		cmt: number;
		combustivel: {
			longId: number;
			descricao: string;
		};
		cor: {
			longId: number;
			descricao: string;
		};
		dataVenda: string | Date;
		eixos: number;
		especie: {
			longId: number;
			descricao: string;
		};
		gravame: {
			arrendatarioFinanceira: string;
			dataVigencia: string;
			financeira: number;
			numeroContrato: string;
			restricaoFinanceira: string;
		};
		marca: {
			longId: number;
			descricao: string;
		};
		municipio: {
			codigo: number;
			nome: string;
		};
		municipioPlacaAnterior: {
			codigo: number;
			nome: string;
		};
		numeroEspelhoAnterior: number;
		pbt: number;
		placa: string;
		placaAnterior: string;
		potencia: number;
		procedencia: string;
		proprietario: {
			bairro: string;
			cepProprietario: number;
			complemento: string;
			cpfCnpjProprietario: number;
			enderecoProprietario: string;
			identificacao: string;
			municipioProprietario: {
				codigo: number;
				nome: string;
			};
			nomeProprietario: string;
			nomeProprietarioAnterior: string;
			numero: number;
			telefone: number;
			orgaoExpedidorProprietario?: string;
			rgProprietario?: number | string;
			ufProprietario?: string;
		};
		protocoloRemacarcao: number;
		renavam: number;
		tipo: {
			longId: number;
			descricao: string;
		};
		ufEspelhoAnterior: string;
		ufPlacaAnterior: string;
		valorVenda: number;
		taxaAutenticacaoDigital?: string;
		documentos: any;
		emailCidadao: string;
		dddTelefoneCidadao: number;
		telefoneCidadao: number;
		servicoDigital: boolean;
		// comunicacaoVenda: {
		// 	dataVenda: any;
		// };
	};
}
