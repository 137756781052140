import React, { useEffect } from 'react';

import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { educacaoClear } from 'store/modules/api/educacaoUpdate/actions';
import { enderecosClear } from 'store/modules/api/utils/enderecos/actions';

// import { pesquisarAlunoClear } from 'store/modules/educacaoBackup/PesquisarAluno/actions';
// import { consultarFichaAlunoClear } from 'store/modules/educacaoBackup/ConsultarFicha/actions';
// import { alterarFichaAlunoClear } from 'store/modules/educacaoBackup/AlterarFichaAluno/actions';
// import { cadastrarFichaAlunoClear } from 'store/modules/educacaoBackup/CadastrarFichaAluno/actions';

// COMPONENTS
import ServiceList from 'components/Common/ServiceList';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// const { REACT_APP_ENVIRONMENT } = process.env;

const Educacao = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const services = [
		{
			name: 'Inscrição para alunos fora da rede pública',
			path: 'inscricao/pesquisar-aluno',
		},
		{
			name: 'Cancelar Inscrição de alunos fora da rede pública',
			path: 'cancelamento/pesquisar-aluno',
		},
		{
			name: 'Cadastro de Responsáveis',
			path: 'cadastro-responsaveis/pesquisar-responsaveis',
		},
	];

	// const enviromentServices =
	// 	REACT_APP_ENVIRONMENT !== 'PROD'
	// 		? [
	// 				...services,
	// 				{
	// 					name: 'Cadastro de Responsáveis',
	// 					path: 'cadastro-responsaveis/pesquisar-responsaveis',
	// 				},
	// 		  ]
	// 		: services;

	useEffect(() => {
		dispatch(educacaoClear());
	}, [dispatch]);

	useEffect(() => {
		dispatch(enderecosClear());
		// dispatch(consultarFichaAlunoClear());
		// dispatch(pesquisarAlunoClear());
		// dispatch(cadastrarFichaAlunoClear());
		// dispatch(alterarFichaAlunoClear());
	}, [dispatch]);

	return (
		<Row>
			<Col span={24}>
				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h1>Escolha o serviço:</h1>
					</Col>
				</Row>

				<Row>
					<Col span={24}>{services && <ServiceList data={services} />}</Col>
				</Row>

				<Row>
					<Col span={24}>
						<ButtonVoltar
							navigate={false}
							onClick={() => {
								history.push('/menu-orgaos');
							}}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default Educacao;
