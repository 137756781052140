import { ApiResponse } from 'services/_api';
import { ReducerAction } from 'store/modules/types';

import { IncluirRequest, Types } from './types';

export function estatisticaIncluirRequest(data: IncluirRequest): ReducerAction {
	return {
		type: Types.ESTATISTICA_INCLUIR_REQUEST,
		payload: data,
	};
}
export function estatisticaIncluirSuccess(payload: ApiResponse): ReducerAction {
	return {
		type: Types.ESTATISTICA_INCLUIR_SUCCESS,
		payload,
	};
}
export function estatisticaIncluirFailure(payload: string): ReducerAction {
	return {
		type: Types.ESTATISTICA_INCLUIR_FAILURE,
		payload,
	};
}
export function estatisticaIncluirClear(): { type: string } {
	return {
		type: Types.ESTATISTICA_INCLUIR_CLEAR,
	};
}
