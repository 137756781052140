import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Formik, Form, Field } from 'formik';
import { Col, Row, Modal } from 'antd';

import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { limparMascara } from 'utils/genericFunctions';

import ButtonImage from 'components/Common/ButtonImage';
import InputMask from 'components/Common/Form/Input/InputMask';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import SimpleTable from 'components/Common/Table';

import { FaSearch } from 'react-icons/fa';

import { ApplicationState } from 'store';

import {
	consultaUsuario,
	selecionaUsuario,
} from 'store/modules/api/seds/vivaLeite/usuario/actions';
import { IUsuario } from 'store/modules/api/seds/vivaLeite/usuario/types';

import { IFormVivaLeite, schema } from './form';

const ConsultaUsuario = () => {
	const dispatch = useDispatch();

	const { usuarioData, selectedCPF } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.usuario,
	);

	const [showModal, setShowModal] = useState(false);
	const [formUser, setFormUser] = useState({ cpf: selectedCPF.toString() });

	const handleHideModal = useCallback(() => {
		setShowModal(false);
	}, [setShowModal]);

	const handleOpenModal = useCallback(
		(userData: IUsuario) => {
			setShowModal(true);
			dispatch(selecionaUsuario(userData));
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: IFormVivaLeite) => {
			values.cpf = limparMascara(values.cpf);
			dispatch(consultaUsuario(values));
		},
		[dispatch],
	);

	const headers = [
		{
			title: 'Número PAN',
			dataIndex: 'numeroPan',
		},
		{
			title: 'Razão Social',
			dataIndex: 'razaoSocial',
		},
		{
			title: 'Presidente',
			dataIndex: 'presidente',
		},
		{
			title: 'Responsável',
			dataIndex: 'responsavel',
		},
		{
			title: 'Status Entidade',
			dataIndex: 'status',
		},
		{
			title: 'Situação do Responsável',
			dataIndex: 'situacaoResponsaveis',
		},
		{
			title: '',
			dataIndex: 'numeroPan',
			render: (text: number, record: IUsuario) => {
				return (
					<>
						<FaSearch size={20} onClick={() => handleOpenModal(record)} />
					</>
				);
			},
		},
	];

	useEffect(() => {
		setFormUser({ cpf: selectedCPF.toString() });
	}, [selectedCPF]);

	return (
		<>
			<Section size="sm" title="IDENTIFICAÇÃO DO USUÁRIO">
				<Section size="sm" titleBold={false} title="BUSCAR USUÁRIO">
					<Row gutter={[0, 10]} justify="center" align="middle">
						<Col span={24}>
							<Formik
								enableReinitialize
								validateOnChange={false}
								validateOnBlur={false}
								initialValues={formUser}
								validate={values => getValidationsErrors(values, schema)}
								onSubmit={handleSubmit}
							>
								{formik => {
									return (
										<Form>
											<Row gutter={[0, 10]} justify="center" align="middle">
												<Col span={8}>
													<Field
														title="CPF"
														name="cpf"
														as={InputMask}
														titleSize="auto"
														mask="999.999.999-99"
														error={hasError(formik.errors, 'cpf')}
													/>
												</Col>
												<Col offset={1}>
													<ButtonImage type="submit" src="pesquisar" />
												</Col>
											</Row>
										</Form>
									);
								}}
							</Formik>
						</Col>
					</Row>
				</Section>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<SimpleTable
							headers={headers}
							body={usuarioData}
							striped
							pagination={false}
							messageNoResults="Nenhum registro encontrado"
						/>
					</Col>
				</Row>
			</Section>
			<ButtonVoltar />
			<Modal
				visible={showModal}
				onCancel={handleHideModal}
				width={350}
				title="Serviços"
				footer={null}
			>
				<Row>
					<Col>
						<Link to="/seds/vivaleite/atualizacaoCadastral">
							<b>Atualização Cadastral</b>
						</Link>
					</Col>
				</Row>
				<Row>
					<Col>
						<Link to="/seds/vivaleite/prestacaoDeContas">
							<b>Prestação de Contas</b>
						</Link>
					</Col>
				</Row>
			</Modal>
		</>
	);
};

export default ConsultaUsuario;
