import { ReducerAction } from 'store/modules/types';

import { IConsultaHistoricoRequest, Types } from './types';

export function consultaHistorico(
	data: IConsultaHistoricoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_HISTORICO,
		payload: data,
	};
}
export function consultaHistoricoSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_HISTORICO_SUCCESS,
		payload,
	};
}
export function consultaHistoricoFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_HISTORICO_FAILURE,
		payload,
	};
}
