import React, { useCallback, useEffect, useState } from 'react';

import { Modal, Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultaMarcaModeloRequest,
	consultaMarcaModeloClear,
} from 'store/modules/api/detranCrv/enum/consultaMarcaModelo/actions';

// ASSETS
import icon from 'assets/images/icons/icon_lupa.gif';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import ButtonImage from 'components/Common/ButtonImage';
import Error from 'components/Common/Notifications/FormError';
import SimpleTable from 'components/Common/Table';

// FORM
import getValidationsErrors, { IBuscarMarcas, initialValues } from './form';

// STYLED
import { Container, ButtonDiv, Button } from '../styled';
import CreateGlobalStyle from '../reset';

export interface IBuscarMarcasResultado {
	longId: number;
	descricao: string;
}

interface Props {
	disabled?: boolean;
	result: (data: IBuscarMarcasResultado) => void;
}

const BuscarMarcas: React.FC<Props> = ({ disabled = false, result }) => {
	const dispatch = useDispatch();

	const [showModal, setShowModal] = useState<boolean>(false);
	const [notificationErrors, setNotificationErrors] = useState<string[]>([]);

	const { consultaMarcaModelo } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.Enum,
	);

	const handleShowModal = useCallback(() => setShowModal(true), [setShowModal]);

	const handleHideModal = useCallback(() => {
		setShowModal(false);
		dispatch(consultaMarcaModeloClear());
	}, [setShowModal, dispatch]);

	useEffect(() => {
		if (consultaMarcaModelo.status === 400) {
			handleHideModal();
		}
	}, [consultaMarcaModelo.status, handleHideModal]);

	const handleValidate = useCallback(
		async (values: IBuscarMarcas) => {
			setNotificationErrors([]);

			const errors = await getValidationsErrors(values);

			if (typeof errors === 'boolean') {
				return errors;
			}

			const formikErrors: string[] = Object.keys(errors).map(value => {
				return errors[value];
			});

			setNotificationErrors(formikErrors);

			return errors;
		},
		[setNotificationErrors],
	);

	const handleSubmit = useCallback(
		(values: IBuscarMarcas) => {
			setNotificationErrors([]);

			dispatch(
				consultaMarcaModeloRequest({
					desc: values.marca,
				}),
			);
		},
		[setNotificationErrors, dispatch],
	);

	const handleSelected = useCallback(
		(res: IBuscarMarcasResultado) => {
			result(res);
			handleHideModal();
		},
		[result, handleHideModal],
	);

	return (
		<Container>
			<Button
				type="button"
				icon={icon}
				disabled={disabled}
				onClick={handleShowModal}
			/>

			<Modal
				title="Buscar por Marca de Veículos"
				visible={showModal}
				onCancel={handleHideModal}
				width={670}
				footer={null}
				style={{ animationDuration: '0s' }}
				destroyOnClose
			>
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={handleValidate}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							{notificationErrors.length > 0 && (
								<Error errors={notificationErrors} />
							)}

							<Section title="Pesquisa" size="sm">
								<Row>
									<Col span={12}>
										<Field
											as={Input}
											title="Marca"
											titleSize="sm"
											name="marca"
											error={!!formik.errors.marca}
										/>
									</Col>

									<Col span={6}>
										<ButtonDiv>
											<ButtonImage type="submit" src="pesquisar" />
										</ButtonDiv>
									</Col>
								</Row>
							</Section>

							<Row gutter={[0, 10]}>
								<Col span={24} />
							</Row>

							{consultaMarcaModelo.data && consultaMarcaModelo.data.length > 0 && (
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<SimpleTable
											headers={[
												{
													title: 'Id',
													dataIndex: 'longId',
													align: 'center',
												},
												{
													title: 'Marca / Modelo',
													dataIndex: 'descricao',
												},
											]}
											body={consultaMarcaModelo.data}
											onClick={handleSelected}
										/>
									</Col>
								</Row>
							)}
						</Form>
					)}
				</Formik>
			</Modal>

			<CreateGlobalStyle />
		</Container>
	);
};

export default BuscarMarcas;
