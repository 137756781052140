import produce from 'immer';

// UTILS
import { toSelect } from 'utils/genericFunctions';

// TYPES
import { Types, regioes } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: regioes = {
	status: 0,
	data: [],
};

export default function regioesReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): regioes {
	return produce(state, draft => {
		switch (action.type) {
			case Types.REGIOES: {
				break;
			}

			case Types.REGIOES_SUCCESS: {
				if (action.payload.data.regioes_medico) {
					const regioesData = toSelect(
						action.payload.data.regioes_medico,
						'nome',
						'long_id',
					);
					draft.data = regioesData;
				} else if (action.payload.data.regioes_psicologo) {
					const regioesData = toSelect(
						action.payload.data.regioes_psicologo,
						'nome',
						'long_id',
					);
					draft.data = regioesData;
				}

				break;
			}

			case Types.REGIOES_FAILURE: {
				break;
			}

			default:
		}
	});
}
