import React, { useEffect } from 'react';

import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { limpardesbloqueiaCartao } from 'store/modules/api/bolsaPovo/desbloqueioCartao/action';
import { limparAlteraEndereco } from 'store/modules/api/bolsaPovo/alteraEndereco/action';
import { limparConsultarEndereco } from 'store/modules/api/bolsaPovo/consultarEndereco/action';
import { limparConsultarContato } from 'store/modules/api/bolsaPovo/consultarContato/action';

// COMPONENTS
import ServiceList from 'components/Common/ServiceList';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { enderecosClear } from 'store/modules/api/utils/enderecos/actions';
import {
	ROUTE_BOLSA_POVO_DESBLOQUEIO_CARTAO,
	ROUTE_BOLSA_POVO_PESQUISA_ALTERACAO_ENDERECO,
} from '../routes/paths';

const BolsaPovo = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const services = [
		{
			name: 'Desbloqueio do Cartão',
			path: ROUTE_BOLSA_POVO_DESBLOQUEIO_CARTAO,
			recuperaAtendimento: true,
		},
		{
			name: 'Atualização de Endereço',
			path: ROUTE_BOLSA_POVO_PESQUISA_ALTERACAO_ENDERECO,
			recuperaAtendimento: true,
		},
	];

	useEffect(() => {
		dispatch(enderecosClear());
		dispatch(limpardesbloqueiaCartao());
		dispatch(limparAlteraEndereco());
		dispatch(limparConsultarEndereco());
		dispatch(limparConsultarContato());
	}, [dispatch]);

	return (
		<Row>
			<Col span={24}>
				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row>
					<Col span={24}>{services && <ServiceList data={services} />}</Col>
				</Row>

				<Row>
					<Col span={24}>
						<ButtonVoltar
							navigate={false}
							onClick={() => {
								history.push('/menu-orgaos');
							}}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default BolsaPovo;
