export enum Types {
	PAIS = '@utils/PAIS',
	PAIS_SUCCESS = '@utils/PAIS_SUCCESS',
	PAIS_FAILURE = '@utils/PAIS_FAILURE',
	PAIS_CLEAR = '@utils/PAIS_CLEAR',
}

export interface Pais {
	status: number;
	data: null | IPais[];
}

interface IPais {
	codPais: number;
	sigla: string;
	pais: string;
	paisEmIngles: string;
}
