import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarStatusRequest } from './types';

// ACTIONS
import { cadastrarStatusSuccess, cadastrarStatusFailure } from './actions';

function* cadastrarStatus(request: ReducerAction) {
	const { payload }: { payload: CadastrarStatusRequest } = request;

	const body = { ...payload };

	delete body.codigo;

	const response: ApiResponse = yield call(postApi, PATH_SGU, `status`, body);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarStatusSuccess(response));
	} else {
		yield put(cadastrarStatusFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_STATUS, cadastrarStatus)]);
