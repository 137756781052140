import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export interface IConsultaPrefeiturasRequest {
	prefeitura?: string;
	paginaInicio?: number;
	limite?: number;
}

export interface ICadastraPrefeiturasRequest {
	codigo?: string | number;
	codigo_orgao: string | number;
	descricao: string;
	descricao_orgao: string;
	nome: string;
}

export interface IAlteraPrefeiturasRequest {
	codigo?: string | number;
	body: {
		codigo_orgao: string | number;
		descricao: string;
		descricao_orgao: string;
		nome: string;
	};
}

export interface ICadastraPrefeiturasServicosRequest {
	codigoPrefeitura?: string;
	codigoServico: number;
	body: any[];
	valuesToUpdate?: any[];
}

export interface IConsultaPrefeiturasServicosRequest {
	idPrefeitura: string;
}

export interface IAlteraPrefeiturasServicosRequest {
	codigo?: number | null;
	body: {
		codigo?: number | null;
		contabiliza: boolean;
		endpoint: string;
		primeiro_servico: boolean;
		proximo_servico: number | null;
	};
	delete?: number | null;
}

export interface IConsultaPrefeiturasServicosParametrosRequest {
	codigoPrefeiturasServicos: number;
}

export interface ICadastraPrefeiturasServicosParametrosRequest {
	body: any;
	idPrefeituraServico: number;
	idParametro: any;
}

export interface IAlteraPrefeiturasServicosParametrosRequest {
	grupo_obrigatorio: any;
	idPrefeituraServico: number;
	idParametro: number;
}

export interface IRemovePrefeiturasServicosParametrosRequest {
	idPrefeituraServico: number;
	idParametro: any;
}

export function consultaPrefeituras(
	data: IConsultaPrefeiturasRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS,
		payload: data,
	};
}
export function consultaPrefeiturasSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_SUCCESS,
		payload,
	};
}
export function consultaPrefeiturasFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_FAILURE,
		payload,
	};
}

export function cadastraPrefeituras(
	data: ICadastraPrefeiturasRequest,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS,
		payload: data,
	};
}
export function cadastraPrefeiturasSuccess(
	payload: ICadastraPrefeiturasRequest,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SUCCESS,
		payload,
	};
}
export function cadastraPrefeiturasFailure(payload: []): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_FAILURE,
		payload,
	};
}

export function alteraPrefeituras(
	data: IAlteraPrefeiturasRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS,
		payload: data,
	};
}
export function alteraPrefeiturasSuccess(
	payload: IAlteraPrefeiturasRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_SUCCESS,
		payload,
	};
}
export function alteraPrefeiturasFailure(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_FAILURE,
		payload,
	};
}

export function removePrefeituras(data: number): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS,
		payload: data,
	};
}
export function removePrefeiturasSuccess(payload: number): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SUCCESS,
		payload,
	};
}
export function removePrefeiturasFailure(payload: []): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_FAILURE,
		payload,
	};
}

export function consultaPrefeiturasServicos(
	data: IConsultaPrefeiturasServicosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_SERVICOS,
		payload: data,
	};
}
export function consultaPrefeiturasServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_SERVICOS_SUCCESS,
		payload,
	};
}
export function consultaPrefeiturasServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_SERVICOS_FAILURE,
		payload,
	};
}

export function cadastraPrefeiturasServicos(
	data: ICadastraPrefeiturasServicosRequest,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS,
		payload: data,
	};
}
export function cadastraPrefeiturasServicosSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_SUCCESS,
		payload,
	};
}
export function cadastraPrefeiturasServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_FAILURE,
		payload,
	};
}

export function alteraPrefeiturasServicos(
	data: IAlteraPrefeiturasServicosRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_SERVICOS,
		payload: data,
	};
}
export function alteraPrefeiturasServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_SERVICOS_SUCCESS,
		payload,
	};
}
export function alteraPrefeiturasServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_SERVICOS_FAILURE,
		payload,
	};
}

export function removePrefeiturasServicos(data: any): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS,
		payload: data,
	};
}
export function removePrefeiturasServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS_SUCCESS,
		payload,
	};
}
export function removePrefeiturasServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS_FAILURE,
		payload,
	};
}

export function consultaPrefeiturasServicosParametros(
	data: IConsultaPrefeiturasServicosParametrosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS,
		payload: data,
	};
}
export function consultaPrefeiturasServicosParametrosSuccess(
	payload: [],
): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS,
		payload,
	};
}
export function consultaPrefeiturasServicosParametrosFailure(
	payload: [],
): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE,
		payload,
	};
}

export function cadastraPrefeiturasServicosParametros(
	data: ICadastraPrefeiturasServicosParametrosRequest,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS,
		payload: data,
	};
}
export function cadastraPrefeiturasServicosParametrosSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS,
		payload,
	};
}
export function cadastraPrefeiturasServicosParametrosFailure(
	payload: [],
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE,
		payload,
	};
}

export function alteraPrefeiturasServicosParametros(
	data: IAlteraPrefeiturasServicosParametrosRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_SERVICOS_PARAMETROS,
		payload: data,
	};
}
export function alteraPrefeiturasServicosParametrosSuccess(
	payload: IAlteraPrefeiturasServicosParametrosRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS,
		payload,
	};
}
export function alteraPrefeiturasServicosParametrosFailure(
	payload: [],
): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE,
		payload,
	};
}

export function removePrefeiturasServicosParametros(
	data: IRemovePrefeiturasServicosParametrosRequest,
): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS_PARAMETROS,
		payload: data,
	};
}
export function removePrefeiturasServicosParametrosSuccess(
	payload: [],
): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS,
		payload,
	};
}
export function removePrefeiturasServicosParametrosFailure(
	payload: [],
): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE,
		payload,
	};
}

export function clearRedirect(): ReducerAction {
	return {
		type: Types.REDIRECT_PREFEITURAS_CLEAR,
		payload: null,
	};
}

export function nextStepClear(): ReducerAction {
	return {
		type: Types.NEXT_STEP_PREFEITURAS_CLEAR,
		payload: null,
	};
}
