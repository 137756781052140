import React, { useEffect, useState, useCallback } from 'react';

import { Row, Col, Alert } from 'antd';

// ROUTES
import { useHistory, useParams } from 'react-router-dom';
import {
	ROUTE_IIRGD_PESQUISA_ANTECEDENTE,
	ROUTE_AAC_RESULTADO_NOMINAL,
	ROUTE_AAC_RESULTADO_NUMERAL,
} from 'pages/iirgd/Aac/routes/paths';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import {
	enviarCentralNumeralRequest,
	enviarCentralNumeralClear,
} from 'store/modules/api/aac/enviarCentralNumeral/actions';
import {
	enviarCentralNominalClear,
	enviarCentralNominalRequest,
} from 'store/modules/api/aac/enviarCentralNominal/actions';
import { limpaAtestadoAntecedentes } from 'store/modules/api/aac/emissaoAtestadoAntecedentes/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import { formatDate, unformatDate } from 'utils/genericFunctions';
import { RequestEmissaoAtestadoAntecedentes } from 'store/modules/api/aac/emissaoAtestadoAntecedentes/types';
import { RequestEnviarCentralNumeral } from 'store/modules/api/aac/enviarCentralNumeral/types';
import { EnviarCentralNominalRequest } from 'store/modules/api/aac/enviarCentralNominal/types';
import Divider from 'components/Common/Divider';
import { getCodigosMostrarEmTela, getMensagemAlerta } from './form';
import NavMenu from '../components/NavMenu';

interface DataFormEmissao {
	nome: string;
	numeroRg?: string;
	numeroDocumento?: string;
	sexo: string;
	nomePai: string;
	nomeMae: string;
	dataExpedicao: string;
	dataNascimento: string;
	mensagemRetorno?: string;
	codigoRetorno?: string;
}

interface Alerta {
	tipo: 'info' | 'error';
	mensagem: string;
	show: boolean;
}

const PesquisaAntecedentes: React.FC = () => {
	const { pesquisa }: { pesquisa: string } = useParams();

	const dispatch = useDispatch();
	const history = useHistory();

	const {
		emissaoAtestadoAntecedentes,
		enviarCentralNumeral,
		enviarCentralNominal,
	} = useSelector((state: ApplicationState) => state.api.aac);

	const [alerta, setAlerta] = useState<Alerta | null>(null);
	const [data, setData] = useState<RequestEmissaoAtestadoAntecedentes | null>(
		null,
	);

	// RECUPERA DATA
	useEffect(() => {
		if (emissaoAtestadoAntecedentes.pesquisa) {
			setData(emissaoAtestadoAntecedentes.pesquisa);
		}
	}, [emissaoAtestadoAntecedentes]);

	// RECUPERA ALERTA
	useEffect(() => {
		if (
			emissaoAtestadoAntecedentes?.data?.codigo &&
			getCodigosMostrarEmTela(emissaoAtestadoAntecedentes?.data?.codigo)
		) {
			setAlerta({
				tipo: 'info',
				mensagem: getMensagemAlerta(emissaoAtestadoAntecedentes?.data?.codigo),
				show: true,
			});
		}
		if (
			enviarCentralNumeral.status === 200 ||
			enviarCentralNominal.status === 200
		) {
			setAlerta({
				tipo: 'info',
				mensagem:
					'Encaminhado para Central de Liberação, acompanhe a análise no Painel de Controle.',
				show: true,
			});
		}
	}, [
		emissaoAtestadoAntecedentes,
		enviarCentralNominal.status,
		enviarCentralNumeral.status,
	]);

	// ENVIO A CENTRAL
	const handleEnvioCentral = (tipoEncaminhamento: string) => {
		if (pesquisa === 'numeral') {
			const payload: RequestEnviarCentralNumeral = {
				codigoRetorno: emissaoAtestadoAntecedentes.data?.codigo ?? '',
				dataExpedicaoRg: emissaoAtestadoAntecedentes.pesquisa.dataExpedicaoRg,
				dataNascimento: emissaoAtestadoAntecedentes.pesquisa.dataNascimentoTs
					? unformatDate(
							new Date(
								emissaoAtestadoAntecedentes.pesquisa.dataNascimentoTs,
							).toLocaleDateString('pt-BR'),
					  )
					: null,
				dc: emissaoAtestadoAntecedentes.pesquisa.digitoRg,
				docProcurador: emissaoAtestadoAntecedentes.pesquisa.docProcurador,
				ipEstacao: emissaoAtestadoAntecedentes.pesquisa.ipEstacao,
				nome: emissaoAtestadoAntecedentes.pesquisa.nome,
				nomeMae: emissaoAtestadoAntecedentes.pesquisa.nomeMae,
				nomePai: emissaoAtestadoAntecedentes.pesquisa.nomePai,
				nomeProcurador: emissaoAtestadoAntecedentes.pesquisa.nomeProcurador,
				nomeSocial: emissaoAtestadoAntecedentes.pesquisa?.nomeSocial ?? '',
				numeroRg: Number(emissaoAtestadoAntecedentes.pesquisa.numeroRg),
				origem: emissaoAtestadoAntecedentes.pesquisa.origem,
				posto: emissaoAtestadoAntecedentes.pesquisa.posto,
				sexo: emissaoAtestadoAntecedentes.pesquisa.sexo,
				status: '',
				tipoDocApresentado:
					emissaoAtestadoAntecedentes.pesquisa.tipoDocApresentado,
				tipoEncaminhamento,
				usuario: emissaoAtestadoAntecedentes.pesquisa.usuario,
			};

			dispatch(enviarCentralNumeralRequest(payload));
		}

		if (pesquisa === 'nominal') {
			const payload: EnviarCentralNominalRequest = {
				codigoRetorno: emissaoAtestadoAntecedentes.data?.codigo ?? '',
				nomeMae: emissaoAtestadoAntecedentes.pesquisa.nomeMae,
				nomePai: emissaoAtestadoAntecedentes.pesquisa.nomePai,
				nomeProcurador: emissaoAtestadoAntecedentes.pesquisa.nomeProcurador,
				nomeSocial: emissaoAtestadoAntecedentes.pesquisa?.nomeSocial ?? '',
				origem: emissaoAtestadoAntecedentes.pesquisa.origem,
				posto: emissaoAtestadoAntecedentes.pesquisa.posto,
				sexo: emissaoAtestadoAntecedentes.pesquisa.sexo,
				dataExpedicaoRg: emissaoAtestadoAntecedentes.pesquisa.dataExpedicaoRg,
				dataNascimento: emissaoAtestadoAntecedentes.pesquisa.dataNascimentoTs
					? unformatDate(
							new Date(
								emissaoAtestadoAntecedentes.pesquisa.dataNascimentoTs,
							).toLocaleDateString('pt-BR'),
					  )
					: null,
				// dc: emissaoAtestadoAntecedentes.pesquisa.digitoRg,
				docProcurador: emissaoAtestadoAntecedentes.pesquisa.docProcurador,
				ipEstacao: emissaoAtestadoAntecedentes.pesquisa.ipEstacao,
				nome: emissaoAtestadoAntecedentes.pesquisa.nome,
				status: '',
				tipoDocApresentado:
					emissaoAtestadoAntecedentes.pesquisa.tipoDocApresentado,
				tipoEncaminhamento,
				usuario: emissaoAtestadoAntecedentes.pesquisa.usuario,
			};
			payload.numeroRg = emissaoAtestadoAntecedentes.pesquisa.numeroDocumento
				.toString()
				.replaceAll(' ', '');
			dispatch(enviarCentralNominalRequest(payload));
		}
	};

	const handleClickEncerrar = useCallback(() => {
		dispatch(limpaAtestadoAntecedentes());
		dispatch(enviarCentralNominalClear());
		dispatch(enviarCentralNumeralClear());

		history.push(ROUTE_IIRGD_PESQUISA_ANTECEDENTE);
	}, [dispatch, history]);

	const handleButtonVoltar = useCallback(() => {
		if (pesquisa === 'numeral') {
			dispatch(limpaAtestadoAntecedentes());
			dispatch(enviarCentralNumeralClear());

			history.push(ROUTE_AAC_RESULTADO_NUMERAL);
			return;
		}

		dispatch(enviarCentralNominalClear());
		history.push(ROUTE_AAC_RESULTADO_NOMINAL);
	}, [pesquisa, dispatch, history]);

	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	useEffect(() => {
		setIsDisabled(
			emissaoAtestadoAntecedentes.data.codigo === 'CO6' ||
				emissaoAtestadoAntecedentes.data.codigo === 'C13' ||
				enviarCentralNumeral.status === 200 ||
				enviarCentralNominal.status === 200,
		);
	}, [
		emissaoAtestadoAntecedentes.data.codigo,
		enviarCentralNominal.status,
		enviarCentralNumeral.status,
	]);

	return (
		<>
			<Row>
				<Col span={24}>
					<NavMenu />

					<Divider mtSize={15} mbSize={15} />
				</Col>
			</Row>
			{alerta?.show && (
				<Alert
					style={{ marginBottom: '50px' }}
					message=""
					description={alerta.mensagem}
					type={alerta.tipo}
					closable
					showIcon
				/>
			)}

			<Section title="Resultados de Antecedentes">
				<Row gutter={[0, 20]}>
					<Col>
						<h3>Termos pesquisados</h3>
					</Col>
				</Row>

				<Row gutter={[0, 5]}>
					<Col span={24}>
						<Row gutter={[0, 0]}>
							<Col span={4}>
								<strong>Nome:</strong>
							</Col>

							<Col span={20}>{data?.nome}</Col>
						</Row>
					</Col>
					<Col span={12}>
						<Row gutter={[0, 5]}>
							<Col span={8}>
								<strong>RG:</strong>
							</Col>

							<Col span={12}>{data?.numeroRg || data?.numeroDocumento} </Col>
						</Row>

						<Row gutter={[0, 5]}>
							<Col span={8}>
								<strong>Sexo:</strong>
							</Col>

							<Col span={12}>
								{data?.sexo === 'M' ? 'MASCULINO' : 'FEMININO'}
							</Col>
						</Row>

						<Row gutter={[0, 5]}>
							<Col span={8}>
								<strong>Nome do Pai:</strong>
							</Col>

							<Col span={12}>{data?.nomePai}</Col>
						</Row>

						<Row>
							<Col span={8}>
								<strong>Nome da Mãe:</strong>
							</Col>

							<Col span={12}>{data?.nomeMae}</Col>
						</Row>
					</Col>

					<Col span={12}>
						<Row gutter={[0, 5]}>
							<Col span={8}>
								<strong>Data de Expedição:</strong>
							</Col>

							<Col span={12}>{formatDate(data?.dataExpedicaoRg) ?? ''}</Col>
						</Row>

						<Row>
							<Col span={8}>
								<strong>Data de Nascimento:</strong>
							</Col>

							<Col span={12}>
								{data && data?.dataNascimentoTs
									? formatDate(
											new Date(data.dataNascimentoTs).toLocaleDateString(
												'pt-BR',
											),
									  )
									: ''}
							</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={[0, 50]}>
					<Col span={18} offset={4}>
						<Row gutter={[0, 10]}>
							<Col>
								<h4>
									Não foi possível emitir o Atestado de Antecedentes para o
									requerimento acima.
								</h4>
							</Col>
						</Row>

						{emissaoAtestadoAntecedentes?.data?.codigo &&
							getCodigosMostrarEmTela(
								emissaoAtestadoAntecedentes?.data?.codigo,
							) &&
							emissaoAtestadoAntecedentes?.data?.mensagem !== '' && (
								<Row>
									<Col>
										<strong>Motivo: </strong>
										{emissaoAtestadoAntecedentes?.data?.mensagem}
									</Col>
								</Row>
							)}
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Row justify="space-between">
							<Col>
								<Row gutter={[0, 10]}>
									<Col>
										<Button
											disabled={isDisabled}
											style={{ width: '385px', height: '50px' }}
											onClick={() => handleEnvioCentral('E')}
										>
											Encaminhar para a Central de Liberação de AAC com E-mail
										</Button>
									</Col>
								</Row>

								<Row justify="center">
									<Col>
										<strong style={{ color: 'red' }}>(Certidão)</strong>
									</Col>
								</Row>
							</Col>

							<Col>
								<Button
									disabled={isDisabled}
									style={{ width: '360px', height: '50px' }}
									onClick={() => handleEnvioCentral('S')}
								>
									Encaminhar para a Central de Liberação de AAC e solicitar ao
									cidadão que aguarde o retorno da análise
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Section>

			<Row justify="space-between">
				<Col>
					<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
				</Col>

				<Col style={{ marginTop: '13px' }}>
					<Button onClick={handleClickEncerrar}>Encerrar</Button>
				</Col>
			</Row>
		</>
	);
};

export default PesquisaAntecedentes;
