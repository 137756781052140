import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

// ANT DESIGN
import { Col, Row, Modal } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { CadastroConsultaForm } from 'store/modules/api/procon/atendimentosDeConsulta/cadastro/types';
import { cadastroConsultaRequest } from 'store/modules/api/procon/atendimentosDeConsulta/cadastro/actions';
import { consultaConsumidorClear } from 'store/modules/api/procon/consumidores/consultaConsumidor/actions';
import { consultaFornecedorClear } from 'store/modules/api/procon/fornecedores/consultaFornecedor/actions';
import { pesquisaFornecedorClear } from 'store/modules/api/procon/fornecedores/pesquisaFornecedor/actions';
import {
	clearNotifications,
	showInside,
} from 'store/modules/app/notifications/actions';

// COMPONENTS
import LightButton from 'components/Common/Buttons/Light';
import Section from 'components/Common/Section';
import TextArea from 'components/Common/Form/Input/TextArea';
import Input from 'components/Common/Form/Input/Input';
import PesquisarConsumidor from 'pages/procon/components/PesquisarConsumidor';
import PesquisarFornecedor from 'pages/procon/components/PesquisarFornecedor';
import DadosSolicitacoes from 'pages/procon/components/DadosSolicitacoes';
import TypographyCommon from 'components/Common/Typography';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { scrollToTopByClassName } from 'utils/scrollFunctions';
import hasError from 'utils/getFormErrors';

// PATHS
import { ROUTE_PROCON_CADASTRAR_CONSULTA_SUCESSO } from 'pages/procon/routes/paths';
import { ROUTE_PROCON } from 'routes/paths';

// FORM
import { Field, Form, Formik, FormikProps } from 'formik';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import { limparMascara, unformatDate } from 'utils/genericFunctions';
import { initialValues, convertEmptyToNull, schema } from './form';

// STYLED
import { stylePaddingCols } from './styled';

const CadatroConsulta: React.FC = () => {
	const history = useHistory();

	const dispatch = useDispatch();

	const [formData, setFormData] = useState(initialValues);

	const [showModal, setShowModal] = useState<
		'none' | 'fornecedor' | 'consumidor'
	>('none');

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const loginUnico = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.salvarAtendimento.data,
	);

	const idDoBalcaoUnico = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.user.posto,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const dadosConsultaStatus = useSelector(
		(state: ApplicationState) =>
			state.api.procon.atendimentosDeConsulta.cadastroConsulta.status,
	);

	const { selecionado } = useSelector(
		(state: ApplicationState) => state.api.procon.consumidores,
	);

	const handleSubmit = useCallback(
		(values: CadastroConsultaForm) => {
			setFormData(values);

			if (user) {
				const evento = {
					id_atendimento: dadosAtendimento?.idAtendimento || '',
					id_cidadao: dadosAtendimento?.idCidadao || '',
					cpf: user.cpf,
					identificacao: Number(limparMascara(selecionado.data?.cpf)),
					ip: user.ip,
					canal: {
						id: loginUnico.login.user.idCanal,
						desc: loginUnico.login.user.descCanal,
						localidade: {
							id: Number(user.posto),
							desc: user.nomePosto,
						},
						estacao: {
							id: '',
							desc: '',
						},
					},
				};

				if (values.nomeDoFornecedor) {
					delete values.fornecedorId;
				}

				const updatedValues = { ...values, idDoBalcaoUnico };

				delete updatedValues.consumidorNome;
				delete updatedValues.tipoConsumidor;

				if (values.dataDeCompraAquisicao) {
					updatedValues.dataDeCompraAquisicao = new Date(
						unformatDate(values.dataDeCompraAquisicao),
					).toISOString();
				}

				dispatch(
					cadastroConsultaRequest({
						evento,
						...convertEmptyToNull(updatedValues),
					}),
				);
			}
		},
		[
			user,
			dadosAtendimento,
			selecionado,
			loginUnico,
			idDoBalcaoUnico,
			dispatch,
		],
	);

	const handleClearConsumidor = useCallback(
		(formik: FormikProps<CadastroConsultaForm>) => {
			dispatch(consultaConsumidorClear());
			formik.setFieldValue('consumidorNome', '');
		},
		[dispatch],
	);

	const handleClearFornecedor = useCallback(
		(formik: FormikProps<CadastroConsultaForm>) => {
			dispatch(pesquisaFornecedorClear());
			formik.setFieldValue('fornecedorNome', '');
		},
		[dispatch],
	);

	const handleBack = () => {
		history.push(ROUTE_PROCON);
	};

	useEffect(() => {
		if (notifications.errors.length) {
			scrollToTopByClassName('ant-modal-wrap', 0);
		}
	}, [notifications]);

	useEffect(() => {
		if (showModal !== 'none') {
			showInside(true);
		} else {
			showInside(false);
		}
	}, [notifications.errors, showModal]);

	useEffect(() => {
		dispatch(consultaConsumidorClear());
		dispatch(consultaFornecedorClear());
		dispatch(pesquisaFornecedorClear());
	}, [dispatch]);

	useEffect(() => {
		if (dadosConsultaStatus === 200) {
			history.push(ROUTE_PROCON_CADASTRAR_CONSULTA_SUCESSO, formData);
		}
	}, [dadosConsultaStatus, history, formData]);

	const getTitle = useCallback(() => {
		switch (showModal) {
			case 'consumidor':
				return (
					<TypographyCommon
						text="Consumidor"
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				);
			case 'fornecedor':
				return (
					<TypographyCommon
						text="Fornecedor"
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				);
			default:
				return (
					<TypographyCommon
						text="[Sem Título]"
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				);
		}
	}, [showModal]);

	return (
		<>
			<Row gutter={[0, 0]} align="middle" justify="start">
				<Col className="gutter-row" span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Cadastrar Consulta"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>

			<Section
				bgColor="cinza-escuro"
				style={{
					border: '1px grey solid',
					borderRadius: '4px',
					marginTop: '0px',
				}}
			>
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={formData}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							<Row gutter={[0, 0]} align="middle" justify="start">
								<TypographyCommon
									text="Consumidor"
									fontWeight="bold"
									required
									uppercase
								/>
							</Row>
							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={19} style={stylePaddingCols}>
									<Field
										as={Input}
										name="consumidorNome"
										placeholder="Clique na lupa para selecionar um Consumidor"
										error={hasError(formik.errors, 'consumidorId')}
										disabled
										required
									/>
								</Col>
								<Col span={5}>
									<Row align="middle" justify="space-between">
										<LightButton
											type="button"
											buttonColor="default"
											text="Pesquisar"
											onClick={() => setShowModal('consumidor')}
											icon="search"
											buttonSize="sm"
											title="Pesquisar Consumidor"
										/>
										<LightButton
											type="button"
											buttonColor="default"
											text="Limpar"
											onClick={() => handleClearConsumidor(formik)}
											icon="erase"
											buttonSize="sm"
											title="Limpar"
										/>
									</Row>
								</Col>
							</Row>

							<Row gutter={[0, 0]} align="middle" justify="start">
								<TypographyCommon
									text="Fornecedor"
									fontWeight="bold"
									uppercase
								/>
							</Row>
							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={19} style={stylePaddingCols}>
									<Field
										as={Input}
										name="nomeDoFornecedor"
										placeholder="Clique na lupa para selecionar um Fornecedor"
										error={hasError(formik.errors, 'validacaoFornecedor')}
										disabled
										required
									/>
								</Col>
								<Col span={5}>
									<Row align="middle" justify="space-between">
										<LightButton
											type="button"
											buttonColor="default"
											text="Pesquisar"
											onClick={() => setShowModal('fornecedor')}
											icon="search"
											buttonSize="sm"
											title="Pesquisar Fornecedor"
										/>
										<LightButton
											type="button"
											buttonColor="default"
											text="Limpar"
											onClick={() => handleClearFornecedor(formik)}
											icon="erase"
											buttonSize="sm"
											title="Limpar"
										/>
									</Row>
								</Col>
							</Row>

							<DadosSolicitacoes formik={formik} />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col
									className="gutter-row"
									span={24}
									style={{ paddingBottom: '10px', paddingTop: '15px' }}
								>
									<TypographyCommon
										text="Dados da Consulta"
										borderBottom
										type="h5"
										fontWeight="bold"
										uppercase
									/>
								</Col>
							</Row>

							<Row
								gutter={[0, 20]}
								align="middle"
								justify="start"
								style={{ marginBottom: '0px' }}
							>
								<Col span={8} style={stylePaddingCols}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="Data de Compra de Aquisição:"
												fontWeight="bold"
												subtitle=" (dd/mm/aaaa)"
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												id="dataDeCompraAquisicao"
												name="dataDeCompraAquisicao"
												type="text"
												mask="99/99/9999"
												maskChar=""
												as={ValidDataInput}
												error={hasError(formik.errors, 'dataDeCompraAquisicao')}
												formik={formik}
												required
												customChanges={(
													v: React.ChangeEvent<HTMLInputElement>,
												) => {
													formik.setFieldValue(
														'dataDeCompraAquisicao',
														v.target.value,
													);
												}}
											/>
										</Col>
									</Row>
								</Col>

								<Col span={16}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<Col span={24}>
											<TypographyCommon
												text="Nome do Produto ou Serviço:"
												fontWeight="bold"
												uppercase
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={Input}
												maxLength="100"
												name="nomeDoServicoOuProduto"
												error={hasError(
													formik.errors,
													'nomeDoServicoOuProduto',
												)}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={24}>
									<TypographyCommon
										text="Descreva a Dúvida do Consumidor:"
										subtitle=" (Quantidade Mínima 50 Caracteres e Máxima 500)"
										fontWeight="bold"
										required
										uppercase
									/>
								</Col>
							</Row>
							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={24}>
									<Field
										as={TextArea}
										name="descricaoDaConsulta"
										maxLength="500"
										error={hasError(formik.errors, 'descricaoDaConsulta')}
										required
									/>
								</Col>
							</Row>

							<br />

							<Row gutter={[0, 0]} align="top" justify="start">
								<Col className="gutter-row" span="12">
									<LightButton
										type="button"
										buttonColor="default"
										text="Voltar"
										icon="back"
										buttonSize="sm"
										buttonWidth={100}
										onClick={handleBack}
									/>
								</Col>
								<Col className="gutter-row" span="12">
									<Row align="top" justify="end">
										<LightButton
											type="submit"
											buttonColor="default"
											text="Salvar"
											icon="save"
											buttonSize="sm"
											buttonWidth={140}
										/>
									</Row>
								</Col>
							</Row>

							<Modal
								title={getTitle()}
								visible={showModal !== 'none'}
								onCancel={() => {
									dispatch(consultaConsumidorClear());
									dispatch(clearNotifications());
									setShowModal('none');
								}}
								width={900}
								footer={null}
								style={{ animationDuration: '0s' }}
								destroyOnClose
							>
								{showModal === 'consumidor' && (
									<PesquisarConsumidor
										formikParent={formik}
										setShowModal={setShowModal}
										typeRegistration="consulta"
									/>
								)}

								{showModal === 'fornecedor' && (
									<PesquisarFornecedor
										formikParent={formik}
										setShowModal={setShowModal}
										typeRegistration="consulta"
									/>
								)}
							</Modal>
						</Form>
					)}
				</Formik>
			</Section>
		</>
	);
};
export default CadatroConsulta;
