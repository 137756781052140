import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ExcluirParametroSistemaRedisRequest } from './types';

// ACTIONS
import {
	excluirParametroSistemaRedisSuccess,
	excluirParametroSistemaRedisFailure,
} from './actions';

function* excluirParametroSistemaRedis(request: ReducerAction) {
	const { payload }: { payload: ExcluirParametroSistemaRedisRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_SGU,
		`parametros-sistema-redis/${payload.chave}`,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(excluirParametroSistemaRedisSuccess(response));
	} else if (response.error) {
		yield put(excluirParametroSistemaRedisFailure());
	}
}

export default all([
	takeLatest(
		Types.EXCLUIR_PARAMETRO_SISTEMA_REDIS,
		excluirParametroSistemaRedis,
	),
]);
