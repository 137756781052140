import { OptionProps } from 'components/Common/Form/Select';

interface Eventos {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export enum Types {
	REGIOES = '@cnh/REGIOES',
	REGIOES_SUCCESS = '@cnh/REGIOES_SUCCESS',
	REGIOES_FAILURE = '@cnh/REGIOES_FAILURE',
}

export interface regioes {
	status: number;
	data: OptionProps[] | [];
}

export interface IRequestRegioes {
	evento?: Eventos;
	cpf_cidadao: number | string;
	tipo_exame: string;
	is_portador_necessidades_especiais: boolean;
	usuario: string;
}
