import produce from 'immer';

import { Types, Anamnese } from './types';
import { ReducerAction } from '../../../../types';

// UTILS

export const INITIAL_STATE: Anamnese = {
	status: 0,
	data: null,
};

export default function anamneseReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): Anamnese {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ANAMNESE_REQUEST: {
				break;
			}
			case Types.ANAMNESE_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data.arquivoPDF;
				break;
			}
			case Types.ANAMNESE_FAILURE: {
				break;
			}
			case Types.ANAMNESE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
