import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestGerarRenach } from './types';

// ACTIONS
import {
	gerarRenachTransferenciaMunicipalSuccess,
	gerarRenachTransferenciaMunicipalFailure,
} from './actions';

function* cadatraRenach(request: ReducerAction) {
	const { payload }: { payload: IRequestGerarRenach } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`renach/${CHANNEL}/gerar-2-via`,
		payload,
	);

	if (response.status === 200) {
		yield put(gerarRenachTransferenciaMunicipalSuccess(response));
	} else if (response.error) {
		yield put(gerarRenachTransferenciaMunicipalFailure(response.error));
	}
}

export default all([
	takeLatest(Types.GERAR_RENACH_TRANSFERENCIA_MUNICIPAL, cadatraRenach),
]);
