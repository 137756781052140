export enum Types {
	CONSULTA_PESQUISA_CIVIL_REQUEST = '@iirgd/CONSULTA_PESQUISA_CIVIL_REQUEST',
	CONSULTA_PESQUISA_CIVIL_SUCCESS = '@iirgd/CONSULTA_PESQUISA_CIVIL_SUCCESS',
	CONSULTA_PESQUISA_CIVIL_FAILURE = '@iirgd/CONSULTA_PESQUISA_CIVIL_FAILURE',
	CONSULTA_PESQUISA_CIVIL_CLEAR = '@iirgd/CONSULTA_PESQUISA_CIVIL_CLEAR',
}

export interface ConsultaPesquisaCivil {
	status: number;
	data: ConsultaPesquisaCivilData[];
}

export interface RetornoCivil {
	pessoa: ConsultaPesquisaCivilData[];
}

export interface ConsultaPesquisaCivilData {
	numeroRg: string;
	dataInclusao: string;
	statusEmissao: string;
	nome: string;
	nomePai: string;
	nomeMae: string;
	sexo: string;
	dataNascimento: string;
	numeroNaturalidade: string;
	descricaoNaturalidade: string;
	mensagem: string;
	numeroPosto: string;
	documento: string;
	lote: string;
	digitoLote: string;
	cpf: string;
	numeroPis: string;
	numeroPasep: string;
	comarca: string;
	cartorio: string;
	livroCartorio: string;
	folhaCartorio: string;
	numeroCartorio: string;
	dataDocumento: string;
	cirg: boolean;
	cinn: boolean;
	cino: boolean;
	tlod: boolean;
	exrg: boolean;
	pesq: boolean;
	status: string;
	afis: string;
	statusCriminal: string;
	tipoSanguineo: string;
	pMinLei1: string;
	pMinLei3: string;
	pMinLei2: string;
}

export interface ConsultaPesquisaCivilRequest {
	numeroRg: string;
	nomeCompleto?: string;
	dataNascimento?: string;
	naturalidade?: number;
	nomePai?: string;
	nomeMae?: string;
	usuario: string;
	senha: string;
	ip?: string;
	pesquisaCIRG: boolean;
	pesquisaCivil: boolean;
}
