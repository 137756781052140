import { validaCpf } from 'utils/genericFunctions';
import * as Yup from 'yup';

export interface ICnhEstranhgeiroRequest {
	cpf: string;
	usuario: string;
	senha: string;
}

export interface IFormPesquisaCnhEstrangeiro {
	cpf: string;
	usuario: string;
	senha: string;
}

export const initialValues: IFormPesquisaCnhEstrangeiro = {
	cpf: '',
	usuario: '',
	senha: '',
};

export const schema = Yup.object<IFormPesquisaCnhEstrangeiro>().shape({
	cpf: Yup.string()
		.required('Campo Obrigatório: CPF.')
		.test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF digitado inválido.',
			test: value => validaCpf(value),
		}),
});
