import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// REDUX
import { ApplicationState } from 'store';
import {
	registraSolicitacoesClear,
	registraSolicitacoesRequest,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/desbloqueio/registraSolicitacoes/actions';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import { threatRequestDataRegistraSolicitacoesDesbloqueio } from 'pages/DetranCrv/utils/threatServicesRequest';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

// FORM
import { IDesbloquearVeiculoPorSolicitante } from 'pages/DetranCrv/components/pages/ServicosDeVeiculos/DesbloquearVeiculoPorSolicitante/form';

// PATHS
import { ROUTE_DETRAN_CRV } from 'routes/paths';
import {
	ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_CONFIRMACAO,
	ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_CONSULTAR,
} from 'pages/DetranCrv/routes/paths';

import { DesbloquearVeiculoPorSolicitante } from '../../../components/pages/ServicosDeVeiculos/DesbloquearVeiculoPorSolicitante';

// FORM
import {
	threatInitialValues,
	threatValuesRequestRegistraSolicitacao,
	schemaLicenciamentoObito,
} from './form';

export const LicenciamentoOcorrenciaObitoDesbloquear = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { atendimento, loginUnico } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { consultaRestricoes, registraSolicitacoes } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { salvarAtendimento } = atendimento;
	const { login } = loginUnico;

	const sguData: SguData = useMemo(() => {
		return {
			salvarAtendimento,
			login,
		};
	}, [login, salvarAtendimento]);

	const evento = getEventoConsultar(sguData);

	const initialValuesUpdated: IDesbloquearVeiculoPorSolicitante =
		threatInitialValues(consultaRestricoes);

	const handleSubmit = useCallback(
		(values: IDesbloquearVeiculoPorSolicitante) => {
			const newValues = threatValuesRequestRegistraSolicitacao(values);

			const valuesToRequest = threatRequestDataRegistraSolicitacoesDesbloqueio(
				evento,
				newValues,
			);

			dispatch(registraSolicitacoesRequest(valuesToRequest));
		},
		[dispatch, evento],
	);

	useEffect(() => {
		dispatch(registraSolicitacoesClear());
	}, [dispatch]);

	useEffect(() => {
		if (consultaRestricoes.status !== 200) {
			history.push(ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_CONSULTAR);
		}
	}, [consultaRestricoes.status, history]);

	useEffect(() => {
		if (registraSolicitacoes.status === 200) {
			history.push(ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_CONFIRMACAO);
		}
	}, [history, registraSolicitacoes.status]);

	return (
		<>
			<DesbloquearVeiculoPorSolicitante
				handleSubmit={handleSubmit}
				initialValues={initialValuesUpdated}
				schema={schemaLicenciamentoObito}
				showInputCpf
			/>

			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</>
	);
};
