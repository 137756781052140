import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultaVeiculoBaseEstadual } from './types';

export function consultarVeiculoBaseEstadualRequest(
	data: IRequestConsultaVeiculoBaseEstadual,
): ReducerAction {
	return {
		type: Types.CONSULTAR_VEICULO_BASE_ESTADUAL_REQUEST,
		payload: data,
	};
}
export function consultarVeiculoBaseEstadualSuccess(payload: object) {
	return {
		type: Types.CONSULTAR_VEICULO_BASE_ESTADUAL_SUCCESS,
		payload,
	};
}
export function consultarVeiculoBaseEstadualFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_VEICULO_BASE_ESTADUAL_FAILURE,
		payload,
	};
}

export function consultarVeiculoBaseEstadualClear(): any {
	return {
		type: Types.CONSULTAR_VEICULO_BASE_ESTADUAL_CLEAR,
	};
}
