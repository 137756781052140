import { GetECpfValidationRequest, Types } from './types';

export function getECpfValidationRequest(payload: GetECpfValidationRequest) {
	return {
		type: Types.GET_E_CPF_VALIDATION_REQUEST,
		payload,
	};
}
export function getECpfValidationSuccess(payload: object) {
	return {
		type: Types.GET_E_CPF_VALIDATION_SUCCESS,
		payload,
	};
}
export function getECpfValidationFailure(payload: []): any {
	return {
		type: Types.GET_E_CPF_VALIDATION_FAILURE,
		payload,
	};
}
export function getECpfValidationClear() {
	return {
		type: Types.GET_E_CPF_VALIDATION_CLEAR,
	};
}
