import produce from 'immer';
// TYPES
import { Types, confirmaPreCadastroReabilitacao } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: confirmaPreCadastroReabilitacao = {
	status: 0,
	data: null,
};

export default function detranCnhReabilitacao(
	state = INITIAL_STATE,
	action: ReducerAction,
): confirmaPreCadastroReabilitacao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONFIRMA_PRE_CADASTRO_REABILITACAO: {
				break;
			}

			case Types.CONFIRMA_PRE_CADASTRO_REABILITACAO_SUCCESS: {
				draft.status = action.payload.status;
				break;
			}

			case Types.CONFIRMA_PRE_CADASTRO_REABILITACAO_FAILURE: {
				break;
			}

			default:
		}
	});
}
