import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, EventoTermino } from './types';

export const INITIAL_STATE: EventoTermino = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): EventoTermino {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EVENTO_TERMINO_REQUEST: {
				draft.status = 100;
				break;
			}

			case Types.EVENTO_TERMINO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = null;
				break;
			}

			case Types.EVENTO_TERMINO_FAILURE: {
				draft.status = action.payload.status;
				draft.data = null;
				break;
			}

			case Types.EVENTO_TERMINO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
