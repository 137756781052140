import { ReducerAction } from 'store/modules/types';

import { Types, RequestConsultaTokenCnhDigital } from './types';

export function consultaTokenCnhDigitalRequest(
	data: RequestConsultaTokenCnhDigital,
): ReducerAction {
	return {
		type: Types.CONSULTA_TOKEN_CNH_DIGITAL_REQUEST,
		payload: data,
	};
}
export function consultaTokenCnhDigitalSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_TOKEN_CNH_DIGITAL_SUCCESS,
		payload,
	};
}
export function consultaTokenCnhDigitalFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.CONSULTA_TOKEN_CNH_DIGITAL_FAILURE,
		payload,
	};
}
export function consultaTokenCnhDigitalClear(): ReducerAction {
	return {
		type: Types.CONSULTA_TOKEN_CNH_DIGITAL_CLEAR,
		payload: null,
	};
}
