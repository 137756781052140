export enum Types {
	CONSULTA_PROCESSO_ADMINISTRATIVO_REQUEST = '@procon/CONSULTA_PROCESSO_ADMINISTRATIVO_REQUEST',
	CONSULTA_PROCESSO_ADMINISTRATIVO_SUCCESS = '@procon/CONSULTA_PROCESSO_ADMINISTRATIVO_SUCCESS',
	CONSULTA_PROCESSO_ADMINISTRATIVO_FAILURE = '@procon/CONSULTA_PROCESSO_ADMINISTRATIVO_FAILURE',
	CONSULTA_PROCESSO_ADMINISTRATIVO_CLEAR = '@procon/CONSULTA_PROCESSO_ADMINISTRATIVO_CLEAR',
}

export interface ConsultaProcessoAdministrativo {
	status: null | number;
	data: null | ConsultaProcessoAdministrativoData;
}

export interface ConsultaProcessoAdministrativoRequest {
	protocolo: string;
	numeroFa: string;
	processoAdministrativoId: string;
}

interface ConsultaProcessoAdministrativoData {
	processoAdministrativoId: string;
	area: string;
	assunto: string;
	dataDaSolicitacao: string;
	dataDaContratacao: string;
	dataDaOcorrenciaOuServico: string;
	dataDeCancelamentoDesistenciaOuNegativa: string;
	descricaoDoProblema: string;
	detalhesDoServicoOuProduto: string;
	formaDeAquisicao: string;
	formaDePagamento: string;
	grupoDeProblema: string;
	nomeDoServicoOuProduto: string;
	numeroDoDocumentoDeCompra: string;
	pedidoDoConsumidor: string;
	problema: string;
	procureiOFornecedorParaSolucionarAQuestao: string;
	protocolo: string;
	prazoDeAtendimento: string;
	participacaoEmAudiencia: string;
	situacao: string;
	tipoDeContratacao: string;
	tipoDoDocumentoDeCompra: string;
	valorDaCompra: number;
	consumidor: {
		cpf: string;
		email: string;
		nome: string;
		telefoneFixo: string;
		telefoneCelular: string;
		endereco: {
			bairro: string;
			cep: string;
			complemento: string;
			estado: string;
			logradouro: string;
			municipio: string;
			numero: string;
			municipioId: string;
		};
	};
	fornecedor: {
		cnpj: string;
		cpf: string;
		email: string;
		endereco: {
			bairro: string;
			cep: string;
			complemento: string;
			estado: string;
			logradouro: string;
			municipio: string;
			numero: string;
			municipioId: string;
		};
		razaoSocial: string;
		nomeFantasia: string;
		website: string;
	};
}
