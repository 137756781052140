import { ReducerAction } from 'store/modules/types';

import { ConsultarSiglasRequest, Types } from './types';

export function consultarSiglasRequest(
	payload: ConsultarSiglasRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_SIGLAS_REQUEST,
		payload,
	};
}
export function consultarSiglasSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_SIGLAS_SUCCESS,
		payload,
	};
}
export function consultarSiglasFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_SIGLAS_FAILURE,
		payload: null,
	};
}
export function cadastrarSiglaClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_SIGLAS_CLEAR,
		payload: null,
	};
}
