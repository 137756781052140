import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ReducerAction } from 'store/modules/types';
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_EDUCACAO_SEDUC_INSCRICOES_API } from 'services/rotasp/path';
import { ConsultaInscricaoRequest, Types } from './types';
import {
	consultarInscricaoSuccess,
	consultarInscricaoFailure,
	consultarInscricaoNoContent,
} from './actions';

function* consultarInscricaoRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultaInscricaoRequest } = request;

	const statusAllowed = [200, 204];

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_EDUCACAO_SEDUC_INSCRICOES_API,
		`inscricoes`,
		payload,
		undefined,
		undefined,
		undefined,
		undefined,
		statusAllowed,
	);

	if (response.status === 200) {
		yield put(consultarInscricaoSuccess({ response, request: payload }));
	} else if (response.status === 204) {
		const data = {
			mensage: 'Aluno não encontrado',
			status: 204,
		};
		yield put(consultarInscricaoNoContent(data));
	} else if (response.error) {
		yield put(consultarInscricaoFailure(response.error));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_INSCRICAO_REQUEST, consultarInscricaoRequest),
]);
