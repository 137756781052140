export enum Types {
	PEBE_REQUEST = '@DETRAN-CRV/PEBE_REQUEST',
	PEBE_SUCCESS = '@DETRAN-CRV/PEBE_SUCCESS',
	PEBE_FAILURE = '@DETRAN-CRV/PEBE_FAILURE',
	PEBE_CLEAR = '@DETRAN-CRV/PEBE_CLEAR',
}

export interface PEBE {
	status: number;
	mensagem: string;
	data: IPEBE;
}

export interface IPEBE {
	tipoCliente: number;
	qtdeOcorrencias: string;
	placa: string;
	renavam: string;
	uf: string;
	situacaoVeiculo: string;
	lotacao: number;
	cpfCnpj?: string;
	anoFabricacao: number;
	anoModelo: number;
	numMotor: string;
	procedencia: string;
	chassi: string;
	chassiRemarcado: {
		codigo: string;
		descricao: string;
	};
	marca: {
		codigo: string;
		descricao: string;
	};
	cor: {
		codigo: string;
		descricao: string;
	};
	combustivel: {
		codigo: string;
		descricao: string;
	};
	municipio: {
		codigo: string;
		descricao: string;
	};
	restricoes: [
		{
			restricao: string;
		},
	];
}

export interface IPEBERequest {
	tipoPesquisa: number;
	chassi: string;
	placa: string;
	ufVeiculo: string;
}
