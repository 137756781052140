import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ApiResponse, postApi } from 'services/_api';

import { PATH_DETRAN_CRV, CHANNEL } from 'services/_path';

import { ReducerAction } from 'store/modules/types';

import { Types, IPGATRequest } from './types';

import { consultarPGATSuccess, consultarPGATFailure } from './actions';

function* consultarPGATRequest(request: ReducerAction) {
	const { payload }: { payload: IPGATRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`resumo-veiculo/${CHANNEL}/pesquisa/gravame`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarPGATSuccess(response));
	} else if (response.error) {
		yield put(consultarPGATFailure(response));
	}
}

export default all([takeLatest(Types.PGAT_REQUEST, consultarPGATRequest)]);
