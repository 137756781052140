import * as Yup from 'yup';

export interface IPesquisarPorPlacaRenavamCpfCnpj {
	cpfCnpj: string;
	placa: string;
	renavam: string;
}

export const initialValuesDefault: IPesquisarPorPlacaRenavamCpfCnpj = {
	cpfCnpj: '',
	placa: '',
	renavam: '',
};

export const schemaDefault =
	Yup.object<IPesquisarPorPlacaRenavamCpfCnpj>().shape({
		cpfCnpj: Yup.string().required(
			'O Campo CPF/CNPJ do proprietário é de preenchimento obrigatório.',
		),
		placa: Yup.string().required(
			'O Campo Placa é de preenchimento obrigatório.',
		),
		renavam: Yup.string().required(
			'O Campo Renavam é de preenchimento obrigatório.',
		),
	});
