export enum Types {
	SOLICITA_CNH_DIGITAL_REQUEST = '@cnh/SOLICITA_CNH_DIGITAL_REQUEST',
	SOLICITA_CNH_DIGITAL_SUCCESS = '@cnh/SOLICITA_CNH_DIGITAL_SUCCESS',
	SOLICITA_CNH_DIGITAL_FAILURE = '@cnh/SOLICITA_CNH_DIGITAL_FAILURE',
	SOLICITA_CNH_DIGITAL_CLEAR = '@cnh/SOLICITAR_CNH_DIGITAL_CLEAR',
}

export interface SolicitaCnhDigital {
	data: SolicitaCnhDigitalData | null;
	status: number;
}

export interface SolicitaCnhDigitalData {
	cpf: string;
}

export interface SolicitaCnhDigitalRequest {
	cpf: string;
	registro: string;
	ddd: string;
	telefone: string;
	email: string;
	codigoVerificador: string;
	broncos: string;
	cpfUsuario: string;
	posto: string;
}
