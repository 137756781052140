import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { gerarBoletoAgrupadoRequest } from 'store/modules/api/cdhu/gerarBoletoAgrupado/actions';
import { calculoBoletoAgrupadoClear } from 'store/modules/api/cdhu/gerarOcorrencia/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import CheckBox from 'components/Common/Form/Checkbox';
import Radio from 'components/Common/Form/Radio';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { formatCurrency, formatStringNumber } from 'utils/numberFunctions';
import { FormikObserver } from 'utils/formikObserver';
import hasError from 'utils/getFormErrors';

// FORM
import { IFormOpcoes, initialValues, schema, treatValues } from './form';

// STYLED
import { Divider } from './styled';

// COMPONENTS
import TablePrestacoes from './TablePrestacoes';

const Opcoes: React.FC = () => {
	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [hasSubmit, setHasSubmit] = useState<boolean>(false);

	const [valorPrestacoesAtrasadas, setValorPrestacoesAtrasadas] =
		useState<number>(0);
	const [qtdePrestacoesAtrasadas, setQtdePrestacoesAtrasadas] =
		useState<number>(0);

	const [qtdePrestacoesExibir, setQtdePrestacoesExibir] = useState<number>(0);

	const { consultarMutuario, consultarPrestacoesAtrasadas, gerarOcorrencia } =
		useSelector((state: ApplicationState) => state.api.cdhu);

	useEffect(() => {
		if (hasSubmit) {
			// console.log('Submitted!');
		}
	}, [hasSubmit]);

	useEffect(() => {
		if (
			consultarPrestacoesAtrasadas.data &&
			gerarOcorrencia.calculoBoletoAgrupado
		) {
			let valoresPrestacoesPrd;

			const { prestacoesPRD, prestacoesAtField } =
				consultarPrestacoesAtrasadas.data;

			const { prds, parcelasEmAtraso } = gerarOcorrencia.calculoBoletoAgrupado;


			if (prestacoesPRD && prestacoesPRD.length) {
				valoresPrestacoesPrd = prestacoesPRD.map(prestacao =>
					formatStringNumber(prestacao.valorAtualizadoField),
				);
			}

			const valoresPrestacoes = prestacoesAtField.map(prestacao =>
				formatStringNumber(prestacao.valorAtualizadoField),
			);

			let valorAtrasadas = 0;
			let qtdeAtrasadas = 0;

			if (prds && valoresPrestacoesPrd && valoresPrestacoesPrd.length > 0) {
				valorAtrasadas += valoresPrestacoesPrd.reduce((a, b) => {
					return a + b;
				});

				qtdeAtrasadas += prestacoesPRD.length;
			}

			if (parcelasEmAtraso && valoresPrestacoes.length > 0) {
				valorAtrasadas += valoresPrestacoes.reduce((a, b) => {
					return a + b;
				});

				qtdeAtrasadas += prestacoesAtField.length;
			}

			setValorPrestacoesAtrasadas(valorAtrasadas);
			setQtdePrestacoesAtrasadas(qtdeAtrasadas);
		}
	}, [
		consultarPrestacoesAtrasadas.data,
		gerarOcorrencia.calculoBoletoAgrupado,
	]);

	const handleFormChange = useCallback((values: IFormOpcoes) => {
		if (values.qtdPrestacoes && values.qtdPrestacoes !== 6) {
			setQtdePrestacoesExibir(Number(values.qtdPrestacoes));
			return;
		}

		if (values.qtdPrestacoes === 6 && values.prestacoes) {
			setQtdePrestacoesExibir(Number(values.prestacoes));
			return;
		}

		setQtdePrestacoesExibir(0);
	}, []);

	const handleValidate = useCallback((values: IFormOpcoes) => {
		return getValidationsErrors(values, schema);
	}, []);

	const handleSubmit = useCallback(
		(formValues: IFormOpcoes) => {
			if (
				consultarMutuario.data &&
				consultarPrestacoesAtrasadas.data &&
				gerarOcorrencia.data &&
				gerarOcorrencia.calculoBoletoAgrupado
			) {
				const values = treatValues(
					formValues,
					consultarMutuario.data,
					consultarPrestacoesAtrasadas.data,
					gerarOcorrencia.data,
					gerarOcorrencia.calculoBoletoAgrupado,
				);

				dispatch(gerarBoletoAgrupadoRequest(token, values));
				setHasSubmit(true);
				dispatch(calculoBoletoAgrupadoClear());

				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}
		},
		[
			consultarMutuario.data,
			consultarPrestacoesAtrasadas.data,
			gerarOcorrencia.data,
			gerarOcorrencia.calculoBoletoAgrupado,
			dispatch,
			token,
		],
	);

	return (
		<>
			{consultarPrestacoesAtrasadas.data &&
				gerarOcorrencia.calculoBoletoAgrupado && (
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValues}
						validate={handleValidate}
						onSubmit={handleSubmit}
					>
						{formik => (
							<>
								<Form autoComplete="off">
									<Row>
										<Col span={24}>
											<Section size="sm" title="Selecione uma opção">
												<Row gutter={[0, 20]}>
													<Col span={24}>
														<Row>
															<Col span={24}>
																<Field
																	as={CheckBox}
																	name="opcao1"
																	checked={formik.values.opcao1}
																	subtitle="Opção 1: Pagamento total das parcelas em atraso."
																	onChange={(
																		e: ChangeEvent<HTMLInputElement>,
																	) => {
																		formik.setFieldValue(
																			'opcao1',
																			e.target.checked,
																		);

																		formik.setFieldValue('opcao2', false);
																	}}
																	error={hasError(formik.errors, 'opcao')}
																/>
															</Col>
														</Row>

														<Row gutter={[0, 10]}>
															<Col span={24} />
														</Row>

														<Row justify="space-around">
															<Col span={8}>
																<span>Valor das prestações atrasadas:</span>
																<strong style={{ marginLeft: '8px' }}>
																	{formatCurrency(valorPrestacoesAtrasadas)}
																</strong>
															</Col>

															<Col span={8}>
																<span>Quantidade de prestações atrasadas:</span>
																<strong style={{ marginLeft: '8px' }}>
																	{qtdePrestacoesAtrasadas}
																</strong>
															</Col>
														</Row>
													</Col>
												</Row>

												<Row gutter={[0, 20]}>
													<Col span={24}>
														<Divider />
													</Col>
												</Row>

												<Row>
													<Col span={24}>
														<Row>
															<Col span={12}>
																<Row>
																	<Col span={24}>
																		<Field
																			as={CheckBox}
																			name="opcao2"
																			checked={formik.values.opcao2}
																			subtitle="Opção 2: Pagamento parcial das parcelas em atraso."
																			onChange={(
																				e: ChangeEvent<HTMLInputElement>,
																			) => {
																				const { checked } = e.target;

																				formik.setFieldValue('opcao2', checked);
																				formik.setFieldValue('opcao1', false);

																				if (checked === false) {
																					formik.setFieldValue(
																						'qtdPrestacoes',
																						0,
																					);
																					formik.setFieldValue(
																						'prestacoes',
																						'',
																					);
																				}
																			}}
																			error={hasError(formik.errors, 'opcao')}
																		/>
																	</Col>
																</Row>

																<Row>
																	<Col span={24}>
																		<Row align="bottom">
																			<Col offset={1}>
																				{formik.values.opcao2 ? (
																					<Field
																						as={Radio}
																						display="vertical"
																						name="qtdPrestacoes"
																						options={[
																							{
																								value: 1,
																								label: '1 prestação',
																							},
																							{
																								value: 2,
																								label: '2 prestações',
																							},
																							{
																								value: 3,
																								label: '3 prestações',
																							},
																							{
																								value: 4,
																								label: '4 prestações',
																							},
																							{
																								value: 5,
																								label: '5 prestações',
																							},
																							{
																								value: 6,
																								label:
																									'Informe a quantidade de prestações',
																							},
																						]}
																						defaultValue={
																							initialValues.qtdPrestacoes
																						}
																						disabled={!formik.values.opcao2}
																						error={
																							!!formik.errors.qtdPrestacoes
																						}
																					/>
																				) : (
																					<Field
																						as={Radio}
																						display="vertical"
																						name="tempQtdPrestacoes"
																						options={[
																							{
																								value: 10,
																								label: '1 prestação',
																							},
																							{
																								value: 20,
																								label: '2 prestações',
																							},
																							{
																								value: 30,
																								label: '3 prestações',
																							},
																							{
																								value: 40,
																								label: '4 prestações',
																							},
																							{
																								value: 50,
																								label: '5 prestações',
																							},
																							{
																								value: -10,
																								label:
																									'Informe a quantidade de prestações',
																							},
																						]}
																						defaultValue={
																							initialValues.tempQtdPrestacoes
																						}
																						disabled
																						error={
																							!!formik.errors.tempQtdPrestacoes
																						}
																					/>
																				)}
																			</Col>

																			<Col span={3}>
																				<Field
																					as={InputMask}
																					name="prestacoes"
																					mask="999"
																					maskChar=""
																					disabled={
																						!formik.values.opcao2 ||
																						formik.values.qtdPrestacoes !== 6
																					}
																					error={!!formik.errors.prestacoes}
																					style={{ marginBottom: '4px' }}
																				/>
																			</Col>
																		</Row>
																	</Col>
																</Row>
															</Col>

															<Col span={12}>
																<TablePrestacoes
																	enabled={formik.values.opcao2}
																	maxRows={qtdePrestacoesExibir}
																/>
															</Col>
														</Row>
													</Col>
												</Row>
											</Section>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={24} />
									</Row>

									<Row justify="end">
										<Col>
											<ButtonImage type="submit" src="prosseguir" />
										</Col>
									</Row>
								</Form>

								<FormikObserver
									dirty={formik.dirty}
									values={formik.values}
									onChange={handleFormChange}
								/>
							</>
						)}
					</Formik>
				)}
		</>
	);
};

export default Opcoes;
