import * as Yup from 'yup';
import { SalvarAtendimentoRequest } from 'store/modules/api/sgu/atendimento/salvar/types';

import {
	DataArrayProps,
	initialValues as initialSaveValues,
} from '../RecuperaAtendimento/form';

export interface JustificativaAtendimentoProps {
	motivoJustificativa: string;
	justificativa: string;
}

export const initialValues: JustificativaAtendimentoProps = {
	motivoJustificativa: '',
	justificativa: '',
};

export const schema = Yup.object<JustificativaAtendimentoProps>().shape({
	motivoJustificativa: Yup.string().required(`Campo Obrigatório: Motivo.`),
	justificativa: Yup.string().when(['motivoJustificativa'], {
		is: motivoJustificativa => motivoJustificativa === 'OUTROS',
		then: Yup.string().required('Campo Obrigatório: Justificativa.'),
	}),
});

export function treatValues(
	values: DataArrayProps | null,
	form: JustificativaAtendimentoProps,
	userCpf: string,
): SalvarAtendimentoRequest {
	const formattedData = {
		...initialSaveValues,
		cpfUsuario: userCpf,
		motivoJustificativa: form.motivoJustificativa,
		justificativa: form.justificativa,
		codLocalidade: values?.codLocalidade || initialSaveValues.codLocalidade,
		situacao: 'RECUSADO' as 'RECUSADO',
		descricaoServico:
			values?.descricaoServico || initialSaveValues.descricaoServico,
		atendimento: {
			idCidadao:
				values?.atendimento?.idCidadao ||
				initialSaveValues.atendimento.idCidadao,
			idAtendimento:
				values?.atendimento?.idAtendimento ||
				initialSaveValues.atendimento.idAtendimento,
			cpf: values?.atendimento?.cpf || initialSaveValues.atendimento.cpf,
			msg: values?.atendimento?.msg || initialSaveValues.atendimento.msg,
			rg: values?.atendimento?.rg || initialSaveValues.atendimento.rg,
		},
		orgao: values?.orgao || initialSaveValues.orgao,
	};

	return formattedData;
}
