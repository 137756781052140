import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	padding-left: 87px;
	margin-bottom: 10px;
`;

export const ContentButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 20px;

	button {
		margin: 0 15px;
	}
`;
