import styled, { css } from 'styled-components';

import icon_check from 'assets/images/icons/icon_check.gif';
import icon_alerta from 'assets/images/icons/icon_alerta.gif';

interface ContainerProps {
	bgColor: 'branco' | 'cinza' | 'verde' | 'azul' | 'vermelho';
	minHeight: 'unset' | number;
}
interface IconProps {
	type: 'loading' | 'success' | 'error';
}

interface TitleProps {
	type: 'loading' | 'success' | 'error';
}

export const Container = styled.section<ContainerProps>`
	background-color: #e1f2e1;
	border: 2px solid #9bb79b;
	border-radius: 10px;
	padding: 20px;
	display: flex;
	flex-direction: column;

	${props =>
		props.minHeight === 'unset' &&
		css`
			min-height: unset;
		`};

	${props =>
		props.minHeight !== 'unset' &&
		css`
			min-height: ${`${props.minHeight}px`};
		`};

	${props =>
		props.bgColor === 'branco' &&
		css`
			background-color: unset;
		`};

	${props =>
		props.bgColor === 'branco' &&
		css`
			background-color: unset;
		`};

	${props =>
		props.bgColor === 'verde' &&
		css`
			background-color: #e2f2e2;
			border: 2px solid #89ab89;
		`};

	${props =>
		props.bgColor === 'azul' &&
		css`
			background-color: #d8eaff;
			border: none;
		`};

	${props =>
		props.bgColor === 'cinza' &&
		css`
			background-color: #f0f0f0;
		`};

	${props =>
		props.bgColor === 'vermelho' &&
		css`
			background-color: #f8eeee;
			border: 2px solid #c33;
		`};

	${props =>
		props.theme.title === 'dark' &&
		css`
			background-color: #000000;
			border-color: #ffff;
		`}
`;

export const Header = styled.div`
	display: flex;
	margin-bottom: 20px;
`;

export const Icon = styled.div<IconProps>`
	background-image: url(${props =>
		(props.type === 'success' && icon_check) ||
		(props.type === 'error' && icon_alerta) ||
		(props.type === 'loading' && '') ||
		''});
	background-repeat: no-repeat;
	height: ${props => (props.type === 'success' ? '20px' : '40px')};
	margin: auto 5px auto 0px;
	width: ${props => (props.type === 'success' ? '20px' : '40px')};
`;

export const Title = styled.div<TitleProps>`
	color: ${props => props.type === 'success' && '#677d67'};
	font-size: calc(1rem + ${props => props.theme.fontScale}%);
	text-align: center;
`;
