// TYPES
import { ReducerAction } from 'store/modules/types';
import { ContabilizacaoIirgdRequest, Types } from './types';

export function contabilizacaoIirgdRequest(
	payload: ContabilizacaoIirgdRequest,
): ReducerAction {
	return {
		type: Types.CONTABILIZACAO_IIRGD_REQUEST,
		payload,
	};
}
export function contabilizacaoIirgdSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONTABILIZACAO_IIRGD_SUCCESS,
		payload,
	};
}
export function contabilizacaoIirgdFailure(payload: object): ReducerAction {
	return {
		type: Types.CONTABILIZACAO_IIRGD_FAILURE,
		payload,
	};
}
export function contabilizacaoIirgdClear(): ReducerAction {
	return {
		type: Types.CONTABILIZACAO_IIRGD_CLEAR,
		payload: null,
	};
}
