import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultarNumeroMotorBin } from './types';

export function ConsultarNumeroMotorBinRequest(
	data: IRequestConsultarNumeroMotorBin,
): ReducerAction {
	return {
		type: Types.CONSULTAR_NUMERO_MOTOR_BIN_REQUEST,
		payload: data,
	};
}
export function ConsultarNumeroMotorBinSuccess(payload: object) {
	return {
		type: Types.CONSULTAR_NUMERO_MOTOR_BIN_SUCCESS,
		payload,
	};
}
export function ConsultarNumeroMotorBinFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_NUMERO_MOTOR_BIN_FAILURE,
		payload: null,
	};
}

export function ConsultarNumeroMotorBinClear(): any {
	return {
		type: Types.CONSULTAR_NUMERO_MOTOR_BIN_CLEAR,
	};
}
