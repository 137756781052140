export enum Types {
	CONSULTA_E_PORTAL_AUTOMATIZADO_REQUEST = '@cnh/CONSULTA_E_PORTAL_AUTOMATIZADO_REQUEST',
	CONSULTA_E_PORTAL_AUTOMATIZADO_SUCCESS = '@cnh/CONSULTA_E_PORTAL_AUTOMATIZADO_SUCCESS',
	CONSULTA_E_PORTAL_AUTOMATIZADO_FAILURE = '@cnh/CONSULTA_E_PORTAL_AUTOMATIZADO_FAILURE',
	CONSULTA_E_PORTAL_AUTOMATIZADO_CLEAR = '@cnh/CONSULTA_E_PORTAL_AUTOMATIZADO_CLEAR',
}

export interface consultaEPortalAutomatizado {
	status: number;
	data: null | consultaEPortalAutomatizadoData;
	// | consultaEPortalAutomatizadoDataError;
}

export interface consultaEPortalAutomatizadoData {
	cpf: string;
	registro: string;
	nome: string;
	ciretran: string;
	categoria: string;
	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	codigoMunicipioIBGE: string;
	municipio: string;
	nomeMunicipio: string;
	codigoInterno: string;
	message: string;
	dataValidadeCnh: string;
	mensagem?: string;
}

// export interface consultaEPortalAutomatizadoDataError {
// 	transacao: string;
// 	codigo: number;
// 	mensagem: string;
// 	mensagens: [string];
// }
