export enum Types {
	SET_AUTH_TOKEN = '@app/SET_AUTH_TOKEN',
	SET_TIME_AUTH_TOKEN = '@app/SET_TIME_AUTH_TOKEN',
	CLEAR_AUTH_TOKEN = '@app/CLEAR_AUTH_TOKEN',
}

export interface AuthTokenState {
	expireIn: Date | null;
	authorization: string | null;
	calcTime: Date | null;
}
