import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultaAnexosConsumidorSuccess,
	consultaAnexosConsumidorFailure,
} from './actions';

function* consultaAnexosConsumidorRequest(request: ReducerAction) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`anexos-consumidor/${CHANNEL}/atendimentos/anexos-do-consumidor`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaAnexosConsumidorSuccess(response));
	} else {
		yield put(consultaAnexosConsumidorFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_ANEXOS_CONSUMIDOR_REQUEST,
		consultaAnexosConsumidorRequest,
	),
]);
