export enum Types {
	IMPRESSAO_GERA_SENHA_REQUEST = '@MP/IMPRESSAO_GERA_SENHA_REQUEST',
	IMPRESSAO_GERA_SENHA_SUCCESS = '@MP/IMPRESSAO_GERA_SENHA_SUCCESS',
	IMPRESSAO_GERA_SENHA_FAILURE = '@MP/IMPRESSAO_GERA_SENHA_FAILURE',
	IMPRESSAO_SENHA_CLEAR = '@MP/IMPRESSAO_SENHA_CLEAR',
}
export interface impressaoGeraSenha {
	status: number;
	data: { arquivoPDF: string };
}
