import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RegiaoMicroRegiaoPorCepRequest } from './types';

// ACTIONS
import {
	regiaoMicroRegiaoPorCepSuccess,
	regiaoMicroRegiaoPorCepFailure,
} from './actions';

function* regiaoMicroRegiaoPorCep(request: ReducerAction) {
	const { payload }: { payload: RegiaoMicroRegiaoPorCepRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`divisao-equitativa/${CHANNEL}/regiao-micro-regiao-por-cep`,
		payload,
	);

	if (response.status === 200) {
		yield put(regiaoMicroRegiaoPorCepSuccess(response));
	} else {
		yield put(regiaoMicroRegiaoPorCepFailure(response));
	}
}

export default all([
	takeLatest(Types.REGIAO_MICRO_REGIAO_POR_CEP, regiaoMicroRegiaoPorCep),
]);
