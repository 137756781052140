import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, SalvaSenhaUsuario } from './types';

export const INITIAL_STATE: SalvaSenhaUsuario = {
	status: 0,
	data: null,
	form: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): SalvaSenhaUsuario {
	return produce(state, draft => {
		switch (action.type) {
			case Types.SALVA_SENHA_USUARIO_REQUEST: {
				draft.form = action.payload;
				break;
			}

			case Types.SALVA_SENHA_USUARIO_SUCCESS: {
				draft.data = action.payload;
				draft.status = 200;
				break;
			}

			case Types.SALVA_SENHA_USUARIO_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.SALVA_SENHA_USUARIO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.form = INITIAL_STATE.form;
				break;
			}

			default:
		}
	});
}
