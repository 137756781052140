import * as Yup from 'yup';
import { CadastroConsultaForm } from 'store/modules/api/procon/atendimentosDeConsulta/cadastro/types';

export const initialValues: CadastroConsultaForm = {
	areaId: '',
	assuntoId: '',
	consumidorId: '',
	consumidorNome: '',
	descricaoDaConsulta: '',
	fornecedorId: '',
	grupoDeProblemaId: '',
	problemaId: '',
	nomeDoServicoOuProduto: '',
	dataDeCompraAquisicao: '',
	nomeDoFornecedor: '',
	idDoBalcaoUnico: '',
};

export function convertEmptyToNull(values: object): any {
	if (!values) {
		return null;
	}
	const newData: any = {};

	Object.entries(values).forEach(([key, value]) => {
		newData[key] = value === '' || value === 0 ? null : value;
	});

	return newData;
}

export const schema = Yup.object<CadastroConsultaForm>().shape({
	consumidorId: Yup.string().required('Campo Obrigatório: Consumidor.'),
	// validacaoFornecedor: Yup.string().when(['fornecedorId', 'nomeDoFornecedor'], {
	// 	is: (fornecedorId, nomeDoFornecedor) =>
	// 		fornecedorId === '' && nomeDoFornecedor === '',
	// 	then: Yup.string().required('Campo Obrigatório: Fornecedor.'),
	// }),
	areaId: Yup.string().required(
		'Campo Obrigatório: Classificação da Solicitação: "Área."',
	),
	assuntoId: Yup.string().when(['areaId'], {
		is: areaId => areaId !== '',
		then: Yup.string().required(
			'Campo Obrigatório: Classificação da Solicitação: "Assunto."',
		),
	}),
	grupoDeProblemaId: Yup.string().when(['areaId', 'assuntoId'], {
		is: (areaId, assuntoId) => areaId !== '' && assuntoId !== '',
		then: Yup.string().required(
			'Campo Obrigatório: Classificação da Solicitação: "Grupo de Problema."',
		),
	}),
	problemaId: Yup.string().when(['areaId', 'assuntoId', 'grupoDeProblemaId'], {
		is: (areaId, assuntoId, grupoDeProblemaId) =>
			areaId !== '' && assuntoId !== '' && grupoDeProblemaId !== '',
		then: Yup.string().required(
			'Campo Obrigatório: Classificação da Solicitação: "Problema."',
		),
	}),
	descricaoDaConsulta: Yup.string().min(
		50,
		'Quantidade Mínima Descreva a dúvida do consumidor: 50 Caracteres',
	),
});
