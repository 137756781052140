import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// ANT DESIGN
import { Col, Row, Modal } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { CadastroConsumidorForm } from 'store/modules/api/procon/consumidores/cadastro/types';
import { cadastroConsumidorRequest } from 'store/modules/api/procon/consumidores/cadastro/actions';
import { sexosRequest } from 'store/modules/api/procon/dominiosCrm/sexos/actions';
import { estadosRequest } from 'store/modules/api/procon/dominiosCrm/estados/actions';
import {
	atualizacaoConsumidorClear,
	atualizacaoConsumidorRequest,
} from 'store/modules/api/procon/consumidores/atualizacao/actions';
import {
	showInside,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import {
	municipiosClear,
	municipiosRequest,
} from 'store/modules/api/procon/dominiosCrm/municipios/actions';

// COMPONENTS
import LightButton from 'components/Common/Buttons/Light';
import CPF from 'components/Common/Form/Fields/CPF';
import InputMask from 'components/Common/Form/Input/InputMask';
import TypographyCommon from 'components/Common/Typography';
import Input from 'components/Common/Form/Input/Input';
import Radio from 'components/Common/Form/Radio';
import Select from 'components/Common/Form/Select';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import {
	patternFormat,
	unformatDate,
	limparMascara,
} from 'utils/genericFunctions';

// FORM
import { Field, Form, Formik, FormikProps } from 'formik';
import { consultaConsumidorRequest } from 'store/modules/api/procon/consumidores/consultaConsumidor/actions';
import { ConsultaConsumidorRequest } from 'store/modules/api/procon/consumidores/consultaConsumidor/types';
import { consumidorSelecionadoRequest } from 'store/modules/api/procon/consumidores/selecionado/actions';
import {
	letrasAcentos,
	letrasAcentosNumeros,
	letrasNumeros,
	tratarEmailProcon,
} from 'utils/stringFunctions';
import {
	convertToLowerCase,
	initialValues,
	inputInitialValues,
	schema,
	schemaCheck,
} from './form';

// COMPONENTS
import ConsultaMunicipio from '../../CadastrarReclamacao/components/consultaMunicipio';

interface Props {
	formikParent: FormikProps<any>;
	type: 'add' | 'update' | 'check';
	setShowModal: (v: 'none' | 'fornecedor' | 'consumidor') => void;
	setType: (v: 'add' | 'update' | 'check' | null) => void;
	parentCpf: string;
}
const CadastroConsultaConsumidor: React.FC<Props> = ({
	formikParent,
	type,
	setShowModal,
	setType,
	parentCpf,
}) => {
	const dispatch = useDispatch();

	const [formData, setFormData] = useState({
		...initialValues,
		cpf: parentCpf,
	});

	useEffect(() => {
		dispatch(municipiosClear());
		showInside(true);
	}, [dispatch]);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const consumidor = useSelector(
		(state: ApplicationState) =>
			state.api.procon.consumidores.consultaConsumidor.data,
	);

	const { status: statusConsumidor } = useSelector(
		(state: ApplicationState) =>
			state.api.procon.consumidores.consultaConsumidor,
	);

	const cadastroNovoConsumidorStatus = useSelector(
		(state: ApplicationState) => state.api.procon.consumidores.cadastro.status,
	);

	const atualizacaoConsumidorStatus = useSelector(
		(state: ApplicationState) =>
			state.api.procon.consumidores.atualizacao.status,
	);

	const sexos = useSelector(
		(state: ApplicationState) => state.api.procon.dominiosCrm.sexos.data,
	);

	const estados = useSelector(
		(state: ApplicationState) => state.api.procon.dominiosCrm.estados.data,
	);

	const resultadosPesquisa = useSelector(
		(state: ApplicationState) => state.api.procon.dominiosCrm.municipios,
	);

	const handleOnChangeCep = useCallback(
		(res: IBuscarCepResultado, formik: FormikProps<CadastroConsumidorForm>) => {
			if (res.uf !== 'SP') {
				formik.setFieldValue('cep', consumidor?.endereco.cep ?? '');
				return Modal.info({
					title: 'ENDEREÇO NÃO PERMITIDO',
					content: (
						<div>
							<p>É permitido apenas CEP na localidade do Estado de São Paulo</p>
						</div>
					),
					onOk() {},
				});
			}
			dispatch(municipiosClear());
			if (res.municipio) {
				dispatch(municipiosRequest({ filtro: res.municipio }));
			}

			return setFormData({
				...formik.values,
				// municipioDescricao: res.municipio,
				cep: res.cep,
				logradouro: res.endereco.substring(0, 100),
				bairro: res.bairro.substring(0, 60),
				estado:
					Number(
						estados.find(
							item => res.estado.toUpperCase() === item.label.toUpperCase(),
						)?.value,
					) || '',
			});
		},
		[consumidor, dispatch, estados],
	);

	useEffect(() => {
		if (resultadosPesquisa.status === 200) {
			if (
				resultadosPesquisa.data.length > 0 &&
				resultadosPesquisa.data[0].value &&
				formData.municipioId !== resultadosPesquisa.data[0].value
			) {
				setFormData(prev => {
					return {
						...prev,
						municipioId: resultadosPesquisa.data[0].value.toString(),
					};
				});
				// } else if (resultadosPesquisa.data.length === 0) {
				// 	setFormData(prev => {
				// 		return {
				// 			...prev,
				// 			municipioId: '',
				// 			municipioDescricao: '',
				// 		};
				// 	});
			}
		}
	}, [
		formData.municipioId,
		resultadosPesquisa.data,
		resultadosPesquisa.status,
	]);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.salvarAtendimento.data,
	);

	const loginUnico = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	useEffect(() => {
		if (statusConsumidor === 200) {
			setType('check');
		}
	}, [setType, statusConsumidor]);

	useEffect(() => {
		if (notifications.showInside === false) {
			showInside(true);
		}
	}, [notifications.showInside]);

	const [consultaIdConsumidor, setConsultaIdConsumidor] = useState(false);

	useEffect(() => {
		if (
			(cadastroNovoConsumidorStatus === 200 ||
				atualizacaoConsumidorStatus === 200 ||
				cadastroNovoConsumidorStatus === 201) &&
			!consultaIdConsumidor
		) {
			setConsultaIdConsumidor(true);
			const body: ConsultaConsumidorRequest = {
				servico: 'consulta',
				cpf: limparMascara(formData.cpf),
				evento: {
					id_atendimento: dadosAtendimento?.idAtendimento || '',
					id_cidadao: dadosAtendimento?.idCidadao || '',
					cpf: limparMascara(loginUnico.login.user.cpf),
					identificacao: Number(limparMascara(formData.cpf)),
					ip: loginUnico.login.user.ip,
					canal: {
						id: loginUnico.login.user.idCanal,
						desc: loginUnico.login.user.descCanal,
						estacao: {
							id: loginUnico.login.user.posto,
							desc: loginUnico.login.user.nomePosto,
						},
						localidade: {
							id: Number(loginUnico.login.user.posto),
							desc: loginUnico.login.user.nomePosto,
						},
					},
					atendente: {
						nome: loginUnico.user.nome,
						id_atendente: '',
						id_posto: loginUnico.login.user.posto,
						desc_posto: loginUnico.login.user.nomePosto,
					},
				},
			};

			dispatch(consultaConsumidorRequest(body));
		}
	}, [
		atualizacaoConsumidorStatus,
		cadastroNovoConsumidorStatus,
		consultaIdConsumidor,
		dadosAtendimento,
		dispatch,
		formData.cpf,
		loginUnico,
	]);

	const handleSubmit = useCallback(
		(values: CadastroConsumidorForm) => {
			const formattedValues = { ...values };

			formattedValues.dataDeNascimento = unformatDate(values.dataDeNascimento);

			if (values.telefoneCelular && formattedValues.telefoneCelular) {
				formattedValues.telefoneCelular = patternFormat(
					values.telefoneCelular,
					'(##) #####-#####',
				);
			}

			setFormData(convertToLowerCase(values));

			delete formattedValues.confirmaEmail;
			delete formattedValues.confirmaTelefoneCelular;
			delete formattedValues.confirmaTelefoneFixo;
			delete formattedValues.municipioDescricao;
			delete formattedValues.tipoDeAcao;

			formattedValues.cpf = limparMascara(formattedValues.cpf);

			if (type === 'add') {
				delete formattedValues.pessoaComDeficiencia;
				dispatch(cadastroConsumidorRequest(formattedValues));
			} else if (type === 'check') {
				if (consumidor) {
					formikParent.setFieldValue(
						'tipoConsumidor',
						consumidor.cpf ? 'PF' : 'PJ',
					);
					formikParent.setFieldValue('consumidorId', consumidor.consumidorId);
					formikParent.setFieldValue('consumidorNome', consumidor.nomeCompleto);
					setShowModal('none');
					dispatch(consumidorSelecionadoRequest(consumidor));
				}
			} else if (type === 'update') {
				if (consumidor) {
					delete formattedValues.confirmaTelefoneCelular;
					delete formattedValues.confirmaTelefoneFixo;

					const ufEmissor = estados.find(
						item => String(values.ufEmissor) === String(item.value),
					);

					formattedValues.ufEmissor = 25;
					if (ufEmissor?.value) {
						formattedValues.ufEmissor = ufEmissor.value;
					}

					const estado = estados.find(
						item => String(values.estado) === String(item.value),
					);

					formattedValues.estado = 25;
					if (estado?.value) {
						formattedValues.estado = estado.value;
					}

					dispatch(
						atualizacaoConsumidorRequest({
							consumidorId: consumidor.consumidorId,
							consumidorAtualizacao: formattedValues,
						}),
					);
				}
			}
		},
		[type, dispatch, consumidor, formikParent, setShowModal, estados],
	);

	const handleValidation = useCallback(
		(values: CadastroConsumidorForm) => {
			showInside(true);
			if (type === 'add' || type === 'update') {
				return getValidationsErrors(values, schema);
			}
			return getValidationsErrors(values, schemaCheck);
		},
		[type],
	);

	useEffect(() => {
		if (!sexos.length) dispatch(sexosRequest());
	}, [dispatch, sexos]);

	useEffect(() => {
		if (!estados.length) dispatch(estadosRequest());
	}, [dispatch, estados]);

	useEffect(() => {
		if (
			(type === 'check' || type === 'update') &&
			consumidor &&
			statusConsumidor === 200
		) {
			const body: CadastroConsumidorForm = inputInitialValues({
				...consumidor,
			});
			body.ufEmissor =
				estados.find(item => body.ufEmissor === item.value)?.label || '';
			body.estado =
				estados.find(item => body.estado === item.value)?.label || '';
			setFormData(convertToLowerCase(body));
		} else if (type === 'add') {
			setFormData(
				convertToLowerCase({
					...initialValues,
					cpf: parentCpf,
				}),
			);
		}
	}, [consumidor, type, estados, statusConsumidor, parentCpf]);

	useEffect(() => {
		if (atualizacaoConsumidorStatus === 200 && type === 'update') {
			setType('check');
			// dispatch(atualizacaoConsumidorClear());
		}
	}, [atualizacaoConsumidorStatus, setType, type]);

	return (
		<>
			<Row gutter={[0, 0]} align="middle" justify="start">
				<Col span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Consumidor"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>

			<Formik
				enableReinitialize
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={formData}
				validate={handleValidation}
				onSubmit={handleSubmit}
			>
				{formik => (
					<Form autoComplete="off">
						<Row gutter={[0, 0]} align="middle" justify="start">
							<Col span={12} style={{ height: '50px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="Nome:"
										fontWeight="bold"
										required={type === 'add'}
										uppercase
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={23}>
										<Field
											as={Input}
											name="nomeCompleto"
											error={hasError(formik.errors, 'nomeCompleto')}
											required
											disabled={type === 'check'}
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'nomeCompleto',
													letrasAcentos(v.target.value),
												);
											}}
										/>
									</Col>
								</Row>
							</Col>
							<Col span={12} style={{ height: '50px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="CPF:"
										fontWeight="bold"
										required={type === 'add'}
										uppercase
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={24}>
										<Field
											as={CPF}
											numero="cpf"
											formik={formik}
											error={hasError(formik.errors, 'cpf')}
											required
											removeContainer
											disabled={parentCpf !== '' || type === 'check'}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<br />

						<Row gutter={[0, 0]} align="middle" justify="start">
							<Col span={12} style={{ height: '50px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon text="RG:" fontWeight="bold" uppercase />
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={23}>
										<Field
											as={Input}
											name="rg"
											maxLength="12"
											error={hasError(formik.errors, 'rg')}
											disabled={type === 'check'}
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'rg',
													v.target.value.replace(/[^0-9\s]/g, ''),
												);
											}}
										/>
									</Col>
								</Row>
							</Col>
							<Col span={6} style={{ height: '50px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="Órgão Emissor:"
										fontWeight="bold"
										uppercase
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={23}>
										<Field
											as={Input}
											name="orgaoEmissorDoRg"
											maxLength="10"
											error={hasError(formik.errors, 'orgaoEmissorDoRg')}
											disabled={type === 'check'}
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'orgaoEmissorDoRg',
													v.target.value.replace(/[^a-zA-Z\s]/g, ''),
												);
											}}
										/>
									</Col>
								</Row>
							</Col>
							<Col span={6} style={{ height: '50px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon text="UF:" fontWeight="bold" uppercase />
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={24}>
										<Field
											as={Select}
											name="ufEmissor"
											options={estados}
											onChange={(v: number) => {
												formik.setFieldValue('ufEmissor', v);
											}}
											error={hasError(formik.errors, 'ufEmissor')}
											disabled={type === 'check'}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<br />

						<Row gutter={[0, 0]} align="middle" justify="start">
							<Col span={12} style={{ height: '50px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="Data de Nascimento:"
										fontWeight="bold"
										required={type === 'add'}
										subtitle=" (dd/mm/aaaa)"
										uppercase
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={23}>
										<Field
											as={InputMask}
											name="dataDeNascimento"
											mask="99/99/9999"
											error={hasError(formik.errors, 'dataDeNascimento')}
											required
											disabled={type === 'check'}
										/>
									</Col>
								</Row>
							</Col>
							<Col span={12} style={{ height: '50px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="Sexo:"
										fontWeight="bold"
										required={type === 'add'}
										uppercase
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={24}>
										<Field
											as={Radio}
											name="sexo"
											options={sexos}
											value={Number(formik.values.sexo)}
											error={hasError(formik.errors, 'sexo')}
											required
											disabled={type === 'check'}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<br />

						<Row gutter={[0, 0]} align="middle" justify="start">
							<Col span={12} style={{ height: '70px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="E-mail:"
										fontWeight="bold"
										required={type === 'add'}
										uppercase
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={23}>
										<Field
											as={Input}
											name="email"
											error={hasError(formik.errors, 'email')}
											required
											maxLength="260"
											disabled={type === 'check'}
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'email',
													tratarEmailProcon(v.target.value),
												);
											}}
										/>
									</Col>
								</Row>
							</Col>
							{type !== 'check' && (
								<Col span={12} style={{ height: '70px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Confirme o E-mail:"
											fontWeight="bold"
											required={type === 'add'}
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={Input}
												name="confirmaEmail"
												error={hasError(formik.errors, 'confirmaEmail')}
												required
												maxLength="260"
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'confirmaEmail',
														tratarEmailProcon(v.target.value),
													);
												}}
											/>
										</Col>
									</Row>
								</Col>
							)}
							<Col span={12} style={{ height: '70px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="Celular:"
										subtitle=" ((99) X 9999-9999)"
										fontWeight="bold"
										required={type === 'add'}
										uppercase
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={23}>
										<Field
											as={InputMask}
											name="telefoneCelular"
											mask="(99) 9 9999-9999"
											error={hasError(formik.errors, 'telefoneCelular')}
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue('telefoneCelular', v.target.value);
											}}
											required
											disabled={type === 'check'}
										/>
									</Col>
								</Row>
							</Col>
							{type !== 'check' && (
								<Col span={12} style={{ height: '70px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Confirme o Celular:"
											subtitle=" ((99) X 9999-9999)"
											fontWeight="bold"
											required={type === 'add'}
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={InputMask}
												name="confirmaTelefoneCelular"
												mask="(99) 9 9999-9999"
												error={hasError(
													formik.errors,
													'confirmaTelefoneCelular',
												)}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'confirmaTelefoneCelular',
														v.target.value,
													);
												}}
												required
											/>
										</Col>
									</Row>
								</Col>
							)}

							<Col span={12} style={{ height: '70px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="Telefone Fixo:"
										subtitle=" ((99) 9999-9999)"
										fontWeight="bold"
										uppercase
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={23}>
										<Field
											as={InputMask}
											name="telefoneFixo"
											mask="(99) 9999-9999"
											error={hasError(formik.errors, 'telefoneFixo')}
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue('telefoneFixo', v.target.value);
											}}
											disabled={type === 'check'}
										/>
									</Col>
								</Row>
							</Col>
							{type !== 'check' && (
								<Col span={12} style={{ height: '70px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Telefone Fixo:"
											subtitle=" ((99) 9999-9999)"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={InputMask}
												name="confirmaTelefoneFixo"
												mask="(99) 9999-9999"
												error={hasError(formik.errors, 'confirmaTelefoneFixo')}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'confirmaTelefoneFixo',
														v.target.value,
													);
												}}
											/>
										</Col>
									</Row>
								</Col>
							)}
						</Row>
						{/* </Row> */}

						<Row gutter={[0, 0]} align="middle" justify="start">
							<Col span={6} style={{ height: '50px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="CEP:"
										fontWeight="bold"
										uppercase
										required
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={23}>
										<CEP
											title=""
											name="cep"
											formik={formik}
											defaultValue={formik.values.cep}
											result={(res: IBuscarCepResultado) =>
												handleOnChangeCep(res, formik)
											}
											maskChar=""
											disabled={type === 'check'}
											required
										/>
									</Col>
								</Row>
							</Col>
							<Col span={18} style={{ height: '50px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="Logradouro:"
										fontWeight="bold"
										uppercase
										required
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={24}>
										<Field
											as={Input}
											name="logradouro"
											maxLength="250"
											error={hasError(formik.errors, 'logradouro')}
											disabled={type === 'check'}
											required
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'logradouro',
													letrasAcentos(v.target.value),
												);
											}}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<br />

						<Row gutter={[0, 0]} align="middle" justify="start">
							<Col span={6} style={{ height: '45px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="Número:"
										fontWeight="bold"
										uppercase
										required
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={23}>
										<Field
											as={Input}
											name="numero"
											maxLength="6"
											error={hasError(formik.errors, 'numero')}
											disabled={type === 'check'}
											required
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'numero',
													letrasNumeros(v.target.value),
												);
											}}
										/>
									</Col>
								</Row>
							</Col>
							<Col span={18} style={{ height: '45px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="Complemento:"
										fontWeight="bold"
										uppercase
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={24}>
										<Field
											as={Input}
											name="complemento"
											maxLength="110"
											error={hasError(formik.errors, 'complemento')}
											disabled={type === 'check'}
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'complemento',
													letrasAcentosNumeros(v.target.value),
												);
											}}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<br />

						<Row gutter={[0, 0]} align="middle" justify="start">
							<Col span={6} style={{ height: '50px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="Bairro:"
										fontWeight="bold"
										uppercase
										required
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={23}>
										<Field
											as={Input}
											name="bairro"
											maxLength="55"
											error={hasError(formik.errors, 'bairro')}
											disabled={type === 'check'}
											required
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'bairro',
													letrasAcentos(v.target.value),
												);
											}}
										/>
									</Col>
								</Row>
							</Col>
							<Col span={9} style={{ height: '50px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="Estado:"
										fontWeight="bold"
										uppercase
										required
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={23}>
										<Field
											as={Select}
											name="estado"
											options={estados}
											error={hasError(formik.errors, 'estado')}
											disabled
											required
										/>
									</Col>
								</Row>
							</Col>
							<Col span={type !== 'check' ? 8 : 9} style={{ height: '50px' }}>
								<Row gutter={[0, 0]} align="middle" justify="start">
									<TypographyCommon
										text="Município:"
										fontWeight="bold"
										uppercase
										required
									/>
								</Row>
								<Row gutter={[6, 0]} align="middle" justify="start">
									<Col span={type !== 'check' ? 23 : 24}>
										<Field
											as={Input}
											name="municipioDescricao"
											error={hasError(formik.errors, 'municipioId')}
											required
											disabled
										/>
									</Col>
								</Row>
							</Col>
							{type !== 'check' && (
								<Col
									span={1}
									style={{
										paddingTop: '34px',
									}}
								>
									<Row gutter={[6, 0]} align="middle" justify="center">
										<Col span={24}>
											<ConsultaMunicipio formikParent={formik} />
										</Col>
									</Row>
								</Col>
							)}
						</Row>

						<br />

						<Row gutter={[0, 0]} align="middle" justify="center">
							<Col span={24}>
								<Row gutter={[0, 0]} align="middle" justify="center">
									{type === 'check' && (
										<>
											<Col offset={1}>
												<LightButton
													id="btn_modal_consulta_check_alterar_consumidor"
													type="button"
													text="Alterar"
													buttonColor="default"
													buttonSize="sm"
													icon="edit"
													buttonWidth={140}
													onClick={() => {
														setType('update');
														formik.setFieldValue('tipoDeAcao', 'update');
														dispatch(atualizacaoConsumidorClear());
													}}
												/>
											</Col>
											<Col offset={1}>
												<LightButton
													id="btn_modal_consulta_check_selecionar_consumidor"
													type="submit"
													text="Selecionar"
													buttonColor="default"
													buttonSize="sm"
													icon="check"
													buttonWidth={140}
												/>
											</Col>
										</>
									)}
									{type === 'add' && (
										<>
											<Col>
												<LightButton
													id="btn_modal_consulta_add_cancelar_consumidor"
													type="button"
													text="Cancelar"
													buttonColor="default"
													buttonSize="sm"
													buttonWidth={140}
													onClick={() => {
														dispatch(clearNotifications());
														setType(null);
														formik.setFieldValue('tipoDeAcao', 'add');
													}}
												/>
											</Col>
											<Col offset={1}>
												<LightButton
													id="btn_modal_consulta_add_salvar_consumidor"
													type="submit"
													text="Salvar"
													buttonColor="default"
													buttonSize="sm"
													buttonWidth={140}
													icon="add"
												/>
											</Col>
										</>
									)}
									{type === 'update' && (
										<>
											<Col>
												<LightButton
													id="btn_modal_consulta_update_cancelar_consumidor"
													type="button"
													text="Cancelar"
													buttonColor="default"
													buttonSize="sm"
													buttonWidth={140}
													onClick={() => {
														dispatch(clearNotifications());
														setType('check');
													}}
												/>
											</Col>
											<Col offset={1}>
												<LightButton
													id="btn_modal_consulta_update_salvar_consumidor"
													type="submit"
													text="Salvar"
													buttonColor="default"
													buttonSize="sm"
													buttonWidth={140}
													icon="add"
												/>
											</Col>
										</>
									)}
								</Row>
							</Col>
						</Row>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default CadastroConsultaConsumidor;
