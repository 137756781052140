// TYPES
import { ReducerAction } from 'store/modules/types';
import { EnviaRespostasAtendimentoRequest, Types } from './types';

export function enviaRespostasAtendimentoRequest(
	payload: EnviaRespostasAtendimentoRequest,
): ReducerAction {
	return {
		type: Types.ENVIA_RESPOSTAS_ATENDIMENTO_REQUEST,
		payload,
	};
}
export function enviaRespostasAtendimentoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.ENVIA_RESPOSTAS_ATENDIMENTO_SUCCESS,
		payload,
	};
}
export function enviaRespostasAtendimentoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.ENVIA_RESPOSTAS_ATENDIMENTO_FAILURE,
		payload,
	};
}
export function enviaRespostasAtendimentoClear(): ReducerAction {
	return {
		type: Types.ENVIA_RESPOSTAS_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
