export enum Types {
	CONSULTA_PREFEITURA_SERVICOS_CANAIS = '@canais/CONSULTA_PREFEITURA_SERVICOS_CANAIS',
	CONSULTA_PREFEITURA_SERVICOS_CANAIS_SUCCESS = '@canais/CONSULTA_PREFEITURA_SERVICOS_CANAIS_SUCCESS',
	CONSULTA_PREFEITURA_SERVICOS_CANAIS_FAILURE = '@canais/CONSULTA_PREFEITURA_SERVICOS_CANAIS_FAILURE',
}

export interface consultaPrefeiturasServicosCanais {
	status: number;
	data: IPrefeituraServicosCanaisData[] | null;
}

interface IPrefeituraServicosCanaisData {
	id: number;
	descricao: string;
}

export interface IRequestPrefeituraServicosCanaisData {
	idPrefeituraServico: number;
	body?: any;
	situation?: string;
}
