export enum Types {
	CONSULTAR_CADASTRO_CIDADAO_REQUEST = '@cnh/CONSULTAR_CADASTRO_CIDADAO_REQUEST',
	CONSULTAR_CADASTRO_CIDADAO_SUCCESS = '@cnh/CONSULTAR_CADASTRO_CIDADAO_SUCCESS',
	CONSULTAR_CADASTRO_CIDADAO_FAILURE = '@cnh/CONSULTAR_CADASTRO_CIDADAO_FAILURE',
	CONSULTAR_CADASTRO_CIDADAO_CLEAR = '@cnh/CONSULTAR_CADASTRO_CIDADAO_CLEAR',
}

export interface ConsultarCadastroCidadao {
	data: ConsultarCadastroCidadaoData | null;
	status: number;
}

export interface ConsultarCadastroCidadaoData {
	cpf: string;
	nome: string;
	nomeMae: string;
	nomeSocial: string;
	dataNascimento: string;
	sexo: string;
	categoria: string;
	renach: string;
	dataExameMedico: string;
	categoriaMedico: string;
	resultadoExameMedico: string;
	restricaoMedica: string;
	validadeExameMedico: string;
	crm: string;
	regiaoUf: string;
	dataExamePsicotecnico: string;
	resultadoExamePsicotecnico: string;
	laudo: string;
	validadeExamePsicotecnico: string;
	crp: string;
	situacaoExameTeorico: string;
	dataExameTeorico: string;
	situacaoExamePratico: string;
	dataExamePratico: string;
	situacaoCadBinco: string;
	numeroRegistro: string;
	numeroPgu: string;
	usoFormularioEnum: string;
	origem: string;
	emissao: string;
	nomePai: string;
	nacionalidade: string;
	naturalidade: string;
	tipoDocumento: string;
	numeroDocumento: string;
	orgaoExpedidor: string;
	ufExpedicao: string;
	ufExpedicaoRNE: string;
	logradouro: string;
	numeroLogradouro: string;
	complemento: string;
	bairro: string;
	codigoMunicipio: string;
	cep: string;
	dataPrimeiraHabilitacao: string;
	categoriaAtual: string;
	sensoCromatico: string;
	campoVisualOlhoDireito: string;
	campoVisualOlhoEsquerdo: string;
	visaoOlhoDireito: string;
	visaoOlhoDireitoC: string;
	visaoOlhoEsquerdo: string;
	visaoOlhoEsquerdoC: string;
	visaoAmbosOlhos: string;
	acuidadeAuditiva: string;
	visaoNoturna: string;
	ofuscamento: string;
	dinamometriaMaoDireita: string;
	dinamometriaMaoEsquerda: string;
	pressaoArterialMaximo: string;
	pressaoArterialMinima: string;
	disturbioDoSono: string;
	nivelMental: string;
	personalidade: string;
	atencao: string;
	portariaCredenciamentoMedico: string;
	portariaCredenciamentoPsicologo: string;
	ciretranCandidato: string;
	regiaoPsicologo: string;
	inapto: string;
	documentoAtribuidoParaInapto: string;
	numeroCiretran: string;
	trocarDeMedico: string;
	trocarDePsicologo: string;
	dataGeracaoRenach: string;
	crmMedico: string;
	crpPsicologo: string;
	numeroRNE: string;
	orgaoExpedidorRNE: string;
	codigoPaisDaCNHEstrangeira: string;
	validadeCNHEstrangeira: string;
	identificacaoCNHEstrangeira: string;
	tipoServico: string;
	observacaoRestricaoX: string;
	exerceAtividadeRemunerada: string;
	necessitaVeiculaAdaptado: string;
	existeJuntaMedica: string;
	existeJuntaPsicologica: string;
	codigoEntidadeMilitar: string;
	numeroIdentificacaoMilitar: string;
	laudoToxicologico: string;
	cnpj: string;
	dataExameToxicologico: string;
	dataInicioCassacao: string;
	datafimCassacao: string;
	codigoPoupatempoOuCiretran: number;
	emailCandidato: string;
	celularDDD: number;
	celularNumero: number;
	cnhDigital: boolean;
	codigoVerificador: string;
	restricoesMedicas: string;
	condutorComBloqueioD: boolean;
	dataFimBloqueioD: string;
	precisaCursoReciclagem: boolean;
	paisAfetivo1: string;
	paisAfetivo2: string;
	brancos: string;
}

export interface ConsultarCadastroCidadaoRequest {
	cpf: string;
	crm?: number | null;
	renach?: string | null;
	cpfUsuario?: string | null;
	codigoCiretranUsuario?: number | null;
	numeroCFC?: number | null;
	bilhetado?: number | null;
	tipoTransacao?: number | null;
	numeroTipo?: number | null;
	funcionalidade?: number | null;
}
