import { Types } from './types';

export function listarSolicitacaoPortalRequest(payload: any) {
	return {
		type: Types.LISTAR_SOLICITACAO_PORTAL_REQUEST,
		payload,
	};
}
export function listarSolicitacaoPortalSuccess(payload: object) {
	return {
		type: Types.LISTAR_SOLICITACAO_PORTAL_SUCCESS,
		payload,
	};
}
export function listarSolicitacaoPortalFailure(payload: []): any {
	return {
		type: Types.LISTAR_SOLICITACAO_PORTAL_FAILURE,
		payload,
	};
}

export function limparListaPortal(): any {
	return {
		type: Types.LIMPAR_LISTA_PORTAL,
	};
}
