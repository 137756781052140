import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types, IRequestWithHeader } from './types';
import {
	primeiroEmplacamentoEmissaoSuccess,
	primeiroEmplacamentoEmissaoFailure,
} from './actions';

function* primeiroEmplacamentoEmissaoRequest(request: {
	type: string;
	payload: IRequestWithHeader;
}) {
	const { payload }: { payload: IRequestWithHeader } = request;
	const { body, headers } = payload;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/emissao/primeiro-emplacamento-emitir`,
		body,
		headers,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(primeiroEmplacamentoEmissaoSuccess(response));
	} else if (response.error) {
		yield put(primeiroEmplacamentoEmissaoFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.PRIMEIRO_EMPLACAMENTO_EMISSAO_REQUEST,
		primeiroEmplacamentoEmissaoRequest,
	),
]);
