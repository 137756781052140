import React, { useCallback, useEffect, useState } from 'react';

import { FormikProps } from 'formik';
import { Row, Col, Upload, Modal, Divider, Spin } from 'antd';

// COMPONENTS
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';

// FORM
import SimpleTable from 'components/Common/Table';
import Button from 'components/Common/Button';
import Error from 'components/Common/Notifications/FormError/index';
import { CadastraAtendimentoPfRequest } from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPf/types';
import { DraggerProps } from 'antd/lib/upload/Dragger';
import LightButton from 'components/Common/Buttons/Light';

interface Props {
	formik: FormikProps<CadastraAtendimentoPfRequest | any>;
	disabled?: boolean;
}

interface TableData {
	base64: string;
	nome: string;
}

const Anexos: React.FC<Props> = ({ formik, disabled = false }) => {
	const { Dragger } = Upload;

	const [tableData, setTableData] = useState<TableData[]>(formik.values.anexos);
	const [modalFile, setModalFile] = useState<boolean>(false);
	const [errors, setErros] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		setTableData(formik.values.anexos);
	}, [formik.values.anexos, tableData]);

	const fileToBase64 = (file: any) => {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = event => {
				if (event?.target?.result !== null && event?.target?.result) {
					resolve(event.target.result);
				}
			};
		});
	};

	const handleChange = useCallback(
		async (info: any) => {
			setLoading(true);
			if (info.file.size > 9000000) {
				setLoading(false);
				setErros(['Tamanho máximo permitido para o documento 9 (em MB).']);
			} else if (
				info.file.type !== 'application/pdf' &&
				info.file.type !== 'image/png' &&
				info.file.type !== 'image/jpeg' &&
				info.file.type !== 'text/csv' &&
				info.file.type !==
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			) {
				setLoading(false);
				setErros([
					'Tipo de arquivo inválido. Favor incluir arquivos com extensão: .pdf, .png, .jpeg, .csv ou .xlsx.',
				]);
			} else if (formik?.values?.anexos?.length >= 6) {
				setLoading(false);
				setErros(['Máximo de 6 arquivos permitidos.']);
			} else {
				if (errors.length) {
					setErros([]);
				}
				const b64File = await fileToBase64(info.file).then(result => {
					return result;
				});
				const b64 = b64File as string;
				const b64String = b64
					.toString()
					.replace('data:application/pdf;base64,', '')
					.replace('data:image/jpeg;base64,', '')
					.replace('data:image/png;base64,', '')
					.replace('data:text/csv;base64,', '')
					.replace(
						'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
						'',
					);

				formik.setFieldValue('anexos', [
					...formik.values.anexos,
					{
						nome: info.file.name,
						base64: b64String,
					},
				]);

				setTimeout(() => {
					setLoading(false);
					setModalFile(false);
				}, 1500);
			}
		},
		[errors.length, formik],
	);

	const props: DraggerProps = {
		name: 'file',
		accept:
			'image/png, image/jpeg, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf',
		multiple: true,
		onChange: (info: any) => {
			setLoading(true);
			handleChange(info);
		},
	};

	const handleRemove = useCallback(
		async index => {
			const allFiles = [...tableData];
			allFiles.splice(index, 1);
			formik.setFieldValue('anexos', allFiles);
		},
		[formik, tableData],
	);

	const headers = [
		{
			key: '1',
			title: 'Nº',
			dataIndex: 'contador',
			align: 'center',
			render: (text: any, record: any, index: any) => index + 1,
		},
		{
			key: '2',
			title: 'Nome',
			dataIndex: 'nome',
			align: 'center',
		},
		{
			key: '3',
			align: 'center',
			title: 'Ações',
			dataIndex: 'type',
			render: (text: any, record: any, index: any) => (
				<Button
					id="btn_remover_anexo"
					style={{ margin: 'auto' }}
					onClick={() => handleRemove(index)}
				>
					Excluir
				</Button>
			),
		},
	];

	const handleBefore = () => {
		setLoading(true);
		return false;
	};

	const handleModal = () => {
		if (tableData.length >= 6) {
			return Modal.info({
				title: 'Máximo de arquivos permitidos atingidos',
				content: (
					<div>
						<p>
							O máximo de arquivos permitidos são 6 (seis). Para qualquer
							alteração basta excluir um arquivo e realizar nova inclusão.
						</p>
					</div>
				),
				onOk() {},
			});
		}
		return setModalFile(true);
	};

	return (
		<>
			<Row gutter={[0, 20]} justify="center">
				<Col>
					<LightButton
						buttonColor="default"
						text="Incluir anexo"
						icon="add"
						buttonSize="sm"
						buttonWidth={140}
						id="btn_incluir_anexo"
						disabled={disabled}
						className="addButton"
						onClick={() => handleModal()}
					/>
				</Col>
				<Col span={24}>
					<SimpleTable
						pageSize={10}
						headers={headers}
						body={tableData}
						messageNoResults="Nenhum registro encontrado"
					/>
				</Col>
			</Row>

			<Modal
				title="Selecione o Documento"
				visible={modalFile}
				onCancel={() => {
					setModalFile(false);
					setErros([]);
				}}
			>
				{errors.length > 0 && (
					<>
						<Error errors={errors} onClose={() => setErros([])} />
						<Divider />
					</>
				)}
				<Spin
					spinning={loading}
					indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
				>
					<Dragger
						{...props}
						showUploadList={false}
						beforeUpload={(file: any) => handleBefore()}
					>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">
							Clique ou arraste o arquivo para incluir o Anexo.
						</p>
					</Dragger>
				</Spin>
			</Modal>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default Anexos;
