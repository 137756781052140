import { ReducerAction } from 'store/modules/types';
import { TipoModificacaoAlteracao, Types } from './types';

export function consultarTipoModificacaoAlteracaoRequest(
	payload: string,
): ReducerAction {
	return {
		type: Types.CONSULTA_TIPO_MODIFICACAO_ALTERACAO_REQUEST,
		payload,
	};
}
export function consultarTipoModificacaoAlteracaoSuccess(
	payload: TipoModificacaoAlteracao[],
): ReducerAction {
	return {
		type: Types.CONSULTA_TIPO_MODIFICACAO_ALTERACAO_SUCCESS,
		payload,
	};
}
export function consultarTipoModificacaoAlteracaoFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_TIPO_MODIFICACAO_ALTERACAO_FAILURE,
		payload: null,
	};
}
export function consultarTipoModificacaoAlteracaoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_TIPO_MODIFICACAO_ALTERACAO_CLEAR,
		payload: null,
	};
}
