import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, PreCadastroRequest } from './types';

export const INITIAL_STATE: PreCadastroRequest = {
	status: 0,
	data: {
		mensagem: '',
		dataCriacao: 0,
		dataModificacao: 0,
		idCidadao: '',
		idAtendimento: '',
		emailContato: '',
		idSolicitacao: 0,
		destinatarioSolicitacao: '',
		RGempty: false,
		tipoSolicitante: 0,
		anexos: [],
		protocolo: '',
		filho: {
			nome: '',
			dtNascimento: '',
			sexo: '',
			cpf: '',
			rg: {
				numero: '',
				digito: '',
				uf: '',
			},
			endereco: {
				logradouro: '',
				numero: '',
				complemEndereco: '',
				bairro: '',
				cep: '',
				cidade: '',
				uf: '',
			},
			telefones: {
				fixo: '',
				celular: '',
				comercial: '',
			},
			genitor: {
				outrasInformacoes: '',
				nome: '',
				sexo: '',
				dtNascimento: '',
				telefones: {
					fixo: '',
					celular: '',
					comercial: '',
				},
			},
		},
		responsavel: {
			nome: '',
			dtNascimento: '',
			sexo: '',
			cpf: '',
			rg: {
				numero: '',
				digito: '',
				uf: '',
			},
			telefones: {
				fixo: '',
				celular: '',
				comercial: '',
			},
			endereco: {
				logradouro: '',
				complemEndereco: ',',
				numero: '',
				bairro: '',
				cep: '',
				cidade: '',
				uf: '',
			},
		},
		aceiteTermo: false,
		aceiteAcrescNomePai: false,
		aceiteTermoDeclaracaoPobreza: false,
		aceiteAcrescimoNome: false,
		informacoesAdicionais: '',
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): PreCadastroRequest {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PRE_CADASTRO_REQUEST: {
				draft.status = 200;

				if (
					action.payload.destinatarioSolicitacao !== '' &&
					action.payload.anexos === undefined
				) {
					const telFilho = {
						fixo: '',
						celular: '',
						comercial: '',
					};

					if (
						action.payload.filho &&
						action.payload.filho.telefones &&
						action.payload.filho.telefones.length > 0
					) {
						action.payload.filho.telefones.map((item: any) => {
							if (item.tipo === 1) {
								telFilho.fixo = item.ddd + item.numero;
							} else if (item.tipo === 2) {
								telFilho.celular = item.ddd + item.numero;
							} else if (item.tipo === 3) {
								telFilho.comercial = item.ddd + item.numero;
							}
							return telFilho;
						});
					}

					const telGenitor = {
						fixo: '',
						celular: '',
						comercial: '',
					};

					if (
						action.payload.filho &&
						action.payload.filho.genitor &&
						action.payload.filho.genitor.telefones &&
						action.payload.filho.genitor.telefones.length > 0
					) {
						action.payload.filho.genitor.telefones.map((item: any) => {
							if (item.tipo === 1) {
								telGenitor.fixo = item.ddd + item.numero;
							} else if (item.tipo === 2) {
								telGenitor.celular = item.ddd + item.numero;
							} else if (item.tipo === 3) {
								telGenitor.comercial = item.ddd + item.numero;
							}
							return telGenitor;
						});
					}

					const telResponsavel = {
						fixo: '',
						celular: '',
						comercial: '',
					};

					if (
						action.payload.responsavel &&
						action.payload.responsavel.telefones &&
						action.payload.responsavel.telefones.length > 0
					) {
						action.payload.responsavel.telefones.map((item: any) => {
							if (item.tipo === 1) {
								telResponsavel.fixo = item.ddd + item.numero;
							} else if (item.tipo === 2) {
								telResponsavel.celular = item.ddd + item.numero;
							} else if (item.tipo === 3) {
								telResponsavel.comercial = item.ddd + item.numero;
							}
							return telResponsavel;
						});
					}

					action.payload = {
						...INITIAL_STATE.data,
						...action.payload,
						informacoesAdicionais: action.payload.informacoesAdicionais,
						tipoSolicitante: Number(action.payload.destinatarioSolicitacao),
						anexos:
							action.payload.anexos !== undefined ? action.payload.anexos : [],
						anexosTipo: '',
						filho: {
							...action.payload.filho,
							dtNascimento:
								action.payload.filho && action.payload.filho.dtNascimento
									? new Date(
											action.payload.filho.dtNascimento,
									  ).toLocaleDateString('pt-BR')
									: '',
							telefones: {
								fixo: telFilho.fixo,
								celular: telFilho.celular,
								comercial: telFilho.comercial,
							},
							genitor: action.payload.filho
								? {
										...action.payload.filho.genitor,
										dtNascimento: action.payload.filho.genitor.dtNascimento
											? new Date(
													action.payload.filho.genitor.dtNascimento,
											  ).toLocaleDateString('pt-BR')
											: '',
										telefones: {
											fixo: telGenitor.fixo,
											celular: telGenitor.celular,
											comercial: telGenitor.comercial,
										},
								  }
								: {
										telefones: {
											fixo: telGenitor.fixo,
											celular: telGenitor.celular,
											comercial: telGenitor.comercial,
										},
								  },
						},
						responsavel: {
							...action.payload.responsavel,
							dtNascimento:
								action.payload.responsavel &&
								action.payload.responsavel.dtNascimento
									? new Date(
											action.payload.responsavel.dtNascimento,
									  ).toLocaleDateString('pt-BR')
									: '',
							telefones: {
								fixo: telResponsavel.fixo,
								celular: telResponsavel.celular,
								comercial: telResponsavel.comercial,
							},
						},
					};
				}
				draft.data = action.payload;
				break;
			}
			case Types.LIMPA_PRE_CADASTRO_REQUEST: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
