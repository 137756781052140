import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

// ANTD
import { Row, Col } from 'antd';

// COMPONENTS
import ServiceList from 'components/Common/ServiceList';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { detranCrvClear } from 'store/modules/api/detranCrv/actions';
import { IParametrosLocalidade } from 'store/modules/api/sgu/loginUnico/types';
import { enderecosClear } from 'store/modules/api/utils/enderecos/actions';

const MenuDetranCrv: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const loginUnico = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	useEffect(() => {
		dispatch(enderecosClear());
		dispatch(detranCrvClear());
	}, [dispatch]);

	const services = [
		{
			title: '',
			services: [
				{
					name: 'Digitalizar Documentos',
					path: 'menu-scanner',
					recuperaAtendimento: false,
				},
				{
					name: 'Consultar Resumo - Extrato - Base Estadual',
					path: 'consulta-resumo-extrato/base-estadual/consultar',
					recuperaAtendimento: true,
				},
			],
		},
		// {
		// 	title: 'Consultar Veículos',
		// 	services: [
		// 		{
		// 			name: 'Consultar Veículo na Base Estadual',
		// 			path: 'consulta-veiculo-base-estadual',
		// 		},
		// 		{
		// 			name: 'Consultar Cadastro BIN Renavam',
		// 			path: 'consulta-cadastro-bin-renavam',
		// 		},
		// 		{
		// 			name: 'Consultar Base de Outros Estados',
		// 			path: 'consultar-base-outros-estados',
		// 		},
		// 		{
		// 			name: 'Consultar Número de Motor na BIN',
		// 			path: 'consultar-numero-motor-bin',
		// 		},
		// 	],
		// },
		// {
		// 	title: 'Consultar Restrições',
		// 	services: [
		// 		{
		// 			name: 'Consultar Bloqueio DETRAN / RENAJUD',
		// 			path: 'consultar-bloqueio-detran-renajud',
		// 		},
		// 	],
		// },
		// {
		// 	title: 'Multas RENAINF',
		// 	services: [
		// 		{
		// 			name: 'Por Placa',
		// 			path: 'multas-renainf-placa',
		// 		},
		// 		{
		// 			name: 'Por Órgão Autuador',
		// 			path: 'multas-renainf-orgao-autuador',
		// 		},
		// 	],
		// },
		{
			title: 'Solicitar Documentos',
			services: [
				{
					name: 'Escolher Placa',
					path: 'escolher-placa',
				},
				{
					name: 'Primeiro Emplacamento',
					path: 'primeiro-emplacamento',
				},
				// {
				// 	name: 'Licenciamento de Veículo',
				// 	path: 'licenciamento-veiculo',
				// },
				{
					name: 'Transferência de Proprietário',
					path: 'transferencia-proprietario/consultar',
				},
				{
					name: 'Transferência Município / Estado',
					path: 'transferencia-municipio-estado/consultar',
				},
				{
					name: 'Transferência Município',
					path: 'transferencia-municipio/consultar',
				},
				{
					name: 'Alteração de Dados Cadastrais',
					path: 'alteracao-dados-cadastrais/consultar',
				},
				{
					name: 'Segunda Via de CRV',
					path: 'segunda-via-crv/consultar',
				},
				{
					name: 'Baixar Veículo',
					path: 'baixar-veiculo/consultar',
				},
				// {
				// 	name: 'Cancelar Autorização Prévia',
				// 	path: 'cancelar-autorizacao-previa',
				// },
				// {
				// 	name: 'Consultar Autorização Prévia',
				// 	path: 'consultar-autorizacao-previa',
				// },
				// {
				// 	name: 'Registrar Autorização Prévia',
				// 	path: 'registrar-autorizacao-previa',
				// },
				// {
				// 	name: 'Imprimir CRLV-e',
				// 	path: 'imprimir-crlve',
				// },
				{
					name: 'Troca Voluntária de Placa',
					path: 'troca-voluntaria-placa/consultar',
				},
				{
					name: 'Reenvio de Documentos',
					path: 'reenviar-documento/consultar',
				},
				{
					name: 'Desbloqueio de veículo - bloqueio diversos - categoria aluguel',
					path: 'desbloqueio-veiculo/categoria-aluguel/consultar',
				},
				{
					name: 'Desbloqueio de veículo - bloqueio judicial',
					path: 'desbloqueio-veiculo/bloqueio-judicial/consultar',
				},
				{
					name: 'Licenciamento de veículo com ocorrência de óbito',
					path: 'licenciamento-veiculo/ocorrencia-obito/consultar',
				},
			],
		},
		{
			title: 'Processos',
			services: [
				// {
				// 	name: 'Consultar Ficha Cadastral',
				// 	path: 'consultar-ficha-cadastral',
				// },
				// {
				// 	name: 'Excluir Ficha Cadastral',
				// 	path: 'excluir-ficha-cadastral',
				// },
				// {
				// 	name: 'Andamento do Processo',
				// 	path: 'andamento-processo',
				// },
				// {
				// 	name: 'Impressão RENAVAM',
				// 	path: 'impressao-renavam/consulta',
				// },
				// {
				// 	name: 'Impressão Licenciamento de Veículos',
				// 	path: 'impressao-licenciamento-veiculos/consulta',
				// },
				// {
				// 	name: 'Impressão Alteração de Endereço',
				// 	path: 'impressao-alteracao-endereco',
				// },
				// {
				// 	name: 'Impressão Segunda Via Licenciamento de Veículos',
				// 	path: 'impressao-segunda-via-licenciamento/consulta',
				// },
				// {
				// 	name: 'Impressão Comunicação de Venda',
				// 	path: 'impressao-comunicacao-venda/consulta',
				// },
				// {
				// 	name: 'Impressão Oficio Segunda Via de Placa',
				// 	path: 'impressao-oficio-segunda-via-placa',
				// },
				// {
				// 	name: 'Impressão Troca Voluntaria de Placa',
				// 	path: 'impressao-troca-voluntaria-placa',
				// },
				{
					name: 'Imprimir CRLV-e',
					path: 'imprimir-crlve',
				},
			],
		},
		{
			title: 'ATPVe - Intenção de Venda',
			services: [
				{
					name: 'Consultar',
					path: 'consultar-intencao-venda/consulta',
				},
				{
					name: 'Incluir',
					path: 'incluir-intencao-venda/consulta',
				},
				{
					name: 'Cancelar',
					path: 'cancelar-intencao-venda/consulta',
				},
				{
					name: 'Imprimir',
					path: 'imprimir-intencao-venda/consulta',
				},
			],
		},
	];

	services.filter(item => {
		if (item.title === '') {
			const parametroResumoExtratoOutrasBases =
				loginUnico.user.parametrosSistema.find(
					// eslint-disable-next-line no-shadow
					(item: IParametrosLocalidade) =>
						item.sigla.includes('CRV_PESQUISA_RESUMO_OUTRAS_BASES'),
				);

			if (parametroResumoExtratoOutrasBases?.valor === 'TRUE') {
				item.services.push({
					name: 'Consultar Resumo - Extrato - Outras Bases',
					path: 'consulta-resumo-extrato/outras-bases/consultar',
					recuperaAtendimento: true,
				});
			}
		}

		if (item.title.toLowerCase() === 'solicitar documentos') {
			if (
				loginUnico.user.parametrosSistema.find(
					parametro => parametro.sigla === 'CRV_HABILITA_SEGUNDA_VIA_PLACA',
				)?.valor === 'TRUE'
			) {
				item.services.push({
					name: 'Segunda Via de Placa',
					path: 'segunda-via-placa/consultar',
					recuperaAtendimento: true,
				});
			}

			return item.services;
		}

		if (item.title === 'Processos') {
			const parametroConsultarFichaCadastral =
				loginUnico.user.parametrosSistema.find(
					// eslint-disable-next-line no-shadow
					(item: IParametrosLocalidade) =>
						item.sigla.includes('CRV_HABILITA_CONSULTA_FICHA_CADASTRAL'),
				);

			if (parametroConsultarFichaCadastral?.valor === 'TRUE') {
				item.services.push({
					name: 'Consultar Ficha Cadastral',
					path: 'consultar-ficha-cadastral',
					recuperaAtendimento: true,
				});
			}

			const parametroExcluirFichaCadastral =
				loginUnico.user.parametrosSistema.find(
					// eslint-disable-next-line no-shadow
					(item: IParametrosLocalidade) =>
						item.sigla.includes('CRV_HABILITA_EXCLUIR_FICHA_CADASTRAL'),
				);

			if (parametroExcluirFichaCadastral?.valor === 'TRUE') {
				item.services.push({
					name: 'Excluir Ficha Cadastral',
					path: 'excluir-ficha-cadastral',
					recuperaAtendimento: true,
				});
			}
		}

		return item.services;
	});

	// // HABILITA SOMENTE EM DEV OU HOMOLOG
	// if (
	// 	REACT_APP_ENVIRONMENT === 'DEV' ||
	// 	REACT_APP_ENVIRONMENT === 'HOMOLOG' ||
	// 	window.location.href === 'http://poupatempo4g.des.sp.gov.br/detran-crv' ||
	// 	window.location.href === 'https://poupatempo4g.des.sp.gov.br/detran-crv' ||
	// 	window.location.href ===
	// 		'http://homologa.poupatempo4g.sp.gov.br/detran-crv' ||
	// 	window.location.href ===
	// 		'https://homologa.poupatempo4g.sp.gov.br/detran-crv'
	// ) {
	// services.filter(item => {
	// 	if (item.title === '') {
	// INCLUIR PATH AQUI
	// 	}
	// 	return null;
	// });
	// }

	return (
		<Row>
			<Col span={24}>
				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h1>Escolha o serviço:</h1>
					</Col>
				</Row>

				<Row>
					<Col span={24}>{services && <ServiceList data={services} />}</Col>
				</Row>

				<Row>
					<Col span={24}>
						<ButtonVoltar
							navigate={false}
							onClick={() => {
								history.push('/menu-orgaos');
							}}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default MenuDetranCrv;
