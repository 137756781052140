// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestWithHeader, IResponseSegundaViaCrv } from './types';

export function emitirSegundaViaCrvRequest(
	payload: IRequestWithHeader,
): ReducerAction {
	return {
		type: Types.EMITIR_SEGUNDA_VIA_CRV_REQUEST,
		payload,
	};
}

export function emitirSegundaViaCrvSuccess(
	payload: IResponseSegundaViaCrv,
): ReducerAction {
	return {
		type: Types.EMITIR_SEGUNDA_VIA_CRV_SUCCESS,
		payload,
	};
}

export function emitirSegundaViaCrvFailure(): ReducerAction {
	return {
		type: Types.EMITIR_SEGUNDA_VIA_CRV_FAILURE,
		payload: null,
	};
}

export function emitirSegundaViaCrvClear(): ReducerAction {
	return {
		type: Types.EMITIR_SEGUNDA_VIA_CRV_CLEAR,
		payload: null,
	};
}
