// ROUTES
import { ROUTE_DETRAN_CNH } from 'routes/paths';

export const ROUTE_DETRAN_CNH_RECUPERAR_ATENDIMENTO = `${ROUTE_DETRAN_CNH}/recuperar-atendimento`;
export const ROUTE_DETRAN_CNH_JUSTIFICATIVA_ATENDIMENTO = `${ROUTE_DETRAN_CNH}/recuperar-atendimento/justificativa`;

// SCANNER
export const ROUTE_DETRAN_CNH_MENU_SCANNER = `${ROUTE_DETRAN_CNH}/menu-scanner`;

// CNH CONSULTA EXTRATO RESUMO CONDUTOR
export const ROUTE_DETRAN_CNH_PESQUISA_EXTRATO_RESUMO_CONDUTOR = `${ROUTE_DETRAN_CNH}/resumo-condutor/pesquisa`;
export const ROUTE_DETRAN_CNH_CONSULTA_EXTRATO_RESUMO_CONDUTOR = `${ROUTE_DETRAN_CNH}/resumo-condutor/extrato`;

// ALTERAR ENDEREÇO
export const ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_PESQUISA = `${ROUTE_DETRAN_CNH}/solicitar-alteracao-endereco/pesquisa`;
export const ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/solicitar-alteracao-endereco/status-condutor`;
export const ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_CONFIRMACAO = `${ROUTE_DETRAN_CNH}/solicitar-alteracao-endereco/confirmacao`;

// RENOVAÇÃO
export const ROUTE_DETRAN_CNH_RENOVACAO_PESQUISA = `${ROUTE_DETRAN_CNH}/renovacao/pesquisa`;
export const ROUTE_DETRAN_CNH_RENOVACAO_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/renovacao/status-condutor`;
export const ROUTE_DETRAN_CNH_RENOVACAO_RENACH = `${ROUTE_DETRAN_CNH}/renovacao/renach`;
export const ROUTE_DETRAN_CNH_RENOVACAO_AGENDAMENTO_PSICOLOGICO = `${ROUTE_DETRAN_CNH}/renovacao/agendamento-psicologico`;
export const ROUTE_DETRAN_CNH_RENOVACAO_AGENDAMENTO_MEDICO = `${ROUTE_DETRAN_CNH}/renovacao/agendamento-medico`;

// SEGUNDA VIA
export const ROUTE_DETRAN_CNH_SEGUNDA_VIA_PESQUISA = `${ROUTE_DETRAN_CNH}/segunda-via/pesquisa`;
export const ROUTE_DETRAN_CNH_SEGUNDA_VIA_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/segunda-via/status-condutor`;
export const ROUTE_DETRAN_CNH_SEGUNDA_VIA_RENACH = `${ROUTE_DETRAN_CNH}/segunda-via/renach`;
export const ROUTE_DETRAN_CNH_SEGUNDA_VIA_AGENDAMENTO_MEDICO = `${ROUTE_DETRAN_CNH}/segunda-via/agendamento-medico`;
export const ROUTE_DETRAN_CNH_SEGUNDA_VIA_AGENDAMENTO_PSICOLOGICO = `${ROUTE_DETRAN_CNH}/segunda-via/agendamento-psicologico`;

// CNH DEFINITIVA
export const ROUTE_DETRAN_CNH_DEFINITIVA_PESQUISA = `${ROUTE_DETRAN_CNH}/cnh-definitiva/pesquisa`;
export const ROUTE_DETRAN_CNH_DEFINITIVA_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/cnh-definitiva/status-condutor`;
export const ROUTE_DETRAN_CNH_DEFINITIVA_RENACH = `${ROUTE_DETRAN_CNH}/cnh-definitiva/renach`;
export const ROUTE_DETRAN_CNH_DEFINITIVA_AGENDAMENTO_PSICOLOGICO = `${ROUTE_DETRAN_CNH}/cnh-definitiva/agendamento-psicologico`;

// PRIMEIRA HABILITAÇÃO
export const ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_PESQUISA = `${ROUTE_DETRAN_CNH}/primeira-habilitacao/pesquisa`;
export const ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/primeira-habilitacao/status-condutor`;
export const ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_RENACH = `${ROUTE_DETRAN_CNH}/primeira-habilitacao/renach`;
export const ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_AGENDAMENTO_MEDICO = `${ROUTE_DETRAN_CNH}/primeira-habilitacao/agendamento-medico`;
export const ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_AGENDAMENTO_PSICOLOGICO = `${ROUTE_DETRAN_CNH}/primeira-habilitacao/agendamento-psicologico`;

// PERMISSIONÁRIO PONTUADO
export const ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_PESQUISA = `${ROUTE_DETRAN_CNH}/permissionario-pontuado/pesquisa`;
export const ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/permissionario-pontuado/status-condutor`;
export const ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_RENACH = `${ROUTE_DETRAN_CNH}/permissionario-pontuado/renach`;
export const ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_AGENDAMENTO_MEDICO = `${ROUTE_DETRAN_CNH}/permissionario-pontuado/agendamento-medico`;
export const ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_AGENDAMENTO_PSICOLOGICO = `${ROUTE_DETRAN_CNH}/permissionario-pontuado/agendamento-psicologico`;

// CNH ESTRANGEIRO
export const ROUTE_DETRAN_CNH_ESTRANGEIRO_PESQUISA = `${ROUTE_DETRAN_CNH}/cnh-estrangeiro/pesquisa`;
export const ROUTE_DETRAN_CNH_ESTRANGEIRO_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/cnh-estrangeiro/status-condutor`;
export const ROUTE_DETRAN_CNH_ESTRANGEIRO_RENACH = `${ROUTE_DETRAN_CNH}/cnh-estrangeiro/renach`;
export const ROUTE_DETRAN_CNH_ESTRANGEIRO_AGENDAMENTO_MEDICO = `${ROUTE_DETRAN_CNH}/cnh-estrangeiro/agendamento-medico`;
export const ROUTE_DETRAN_CNH_ESTRANGEIRO_AGENDAMENTO_PSICOLOGICO = `${ROUTE_DETRAN_CNH}/cnh-estrangeiro/agendamento-psicologico`;

// ADIÇÃO MUDANÇA CATEGORIA
export const ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_PESQUISA = `${ROUTE_DETRAN_CNH}/adicao-mudanca-categoria/pesquisa`;
export const ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/adicao-mudanca-categoria/status-condutor`;
export const ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_RENACH = `${ROUTE_DETRAN_CNH}/adicao-mudanca-categoria/renach-gerado`;
export const ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_AGENDAMENTO_MEDICO = `${ROUTE_DETRAN_CNH}/adicao-mudanca-categoria/agendamento-medico`;
export const ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_AGENDAMENTO_PSICOLOGICO = `${ROUTE_DETRAN_CNH}/adicao-mudanca-categoria/agendamento-psicologico`;

// PERMISSAO INTERNACIONAL AUTOMATIZADO
export const ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_PESQUISA = `${ROUTE_DETRAN_CNH}/permissao-internacional-automatizado/pesquisa`;
export const ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/permissao-internacional-automatizado/status-condutor`;
export const ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_RENACH = `${ROUTE_DETRAN_CNH}/permissao-internacional-automatizado/renach-gerado`;

// PERMISSAO INTERNACIONAL
export const ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_PESQUISA = `${ROUTE_DETRAN_CNH}/permissao-internacional/pesquisa`;
export const ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/permissao-internacional/status-condutor`;
export const ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_RENACH = `${ROUTE_DETRAN_CNH}/permissao-internacional/renach-gerado`;

// CNH CASSADA
export const ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_PESQUISA = `${ROUTE_DETRAN_CNH}/cnh-cassada-reabilitacao/pesquisa`;
export const ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/cnh-cassada-reabilitacao/status-condutor`;
export const ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_RENACH = `${ROUTE_DETRAN_CNH}/cnh-cassada-reabilitacao/renach-gerado`;
export const ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_AGENDAMENTO_MEDICO = `${ROUTE_DETRAN_CNH}/cnh-cassada-reabilitacao/agendamento-medico`;
export const ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_AGENDAMENTO_PSICOLOGICO = `${ROUTE_DETRAN_CNH}/cnh-cassada-reabilitacao/agendamento-psicologico`;

// CONSULTA PONTUAÇÃO CNH
export const ROUTE_DETRAN_CNH_CONSULTA_PONTUACAO_CNH_PESQUISA = `${ROUTE_DETRAN_CNH}/consulta-pontuacao-cnh/pesquisa`;
export const ROUTE_DETRAN_CNH_CONSULTA_PONTUACAO_CNH_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/consulta-pontuacao-cnh/status-condutor`;

// TRANSFERÊNCIA MUNICIPAL
export const ROUTE_DETRAN_CNH_TRANSFERENCIA_MUNICIPAL_PESQUISA = `${ROUTE_DETRAN_CNH}/transferencia-municipal/pesquisa`;
export const ROUTE_DETRAN_CNH_TRANSFERENCIA_MUNICIPAL_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/transferencia-municipal/status-condutor`;
export const ROUTE_DETRAN_CNH_TRANSFERENCIA_MUNICIPAL_RENACH = `${ROUTE_DETRAN_CNH}/transferencia-municipal/renach`;

// CNH DIGITAL
export const ROUTE_DETRAN_CNH_DIGITAL_PESQUISA = `${ROUTE_DETRAN_CNH}/ecnhDigital/pesquisa`;
export const ROUTE_DETRAN_CNH_DIGITAL_STATUS_CONDUTOR = `${ROUTE_DETRAN_CNH}/ecnhDigital/status-condutor`;
export const ROUTE_DETRAN_CNH_DIGITAL_CADASTRO = `${ROUTE_DETRAN_CNH}/ecnhDigital/cadastro`;

// ALTERAR RENACH
export const ROUTE_DETRAN_CNH_ALTERAR_RENACH = `${ROUTE_DETRAN_CNH}/renach/alteracao`;
export const ROUTE_DETRAN_CNH_ALTERAR_RENACH_AGENDAMENTO_PSICOLOGICO = `${ROUTE_DETRAN_CNH}/renach/agendamento-psicologico`;
export const ROUTE_DETRAN_CNH_ALTERAR_RENACH_RENACH = `${ROUTE_DETRAN_CNH}/renach/renach-gerado`;

// PROCESSO ABERTO
export const ROUTE_DETRAN_CNH_CONSULTAR_CANCElAR_PROCESSO_ABERTO = `${ROUTE_DETRAN_CNH}/consultar-cancelar-processo-aberto/pesquisa`;
// CANCELAR SOLICITAÇÃO EMISSÃO PID
export const ROUTE_DETRAN_CNH_CANCELAR_SOLICITACAO_EMISSAO_PID_PESQUISA = `${ROUTE_DETRAN_CNH}/cancelar-solicitacao-emissao-pid/pesquisa`;

// CONSULTA NOME SOCIAL
export const ROUTE_DETRAN_CNH_CONSULTA_NOME_SOCIAL = `${ROUTE_DETRAN_CNH}/consulta-nome-social/pesquisa`;
