import * as Yup from 'yup';

export interface ConsultaRenainfPlaca {
	placa: string;
}

export const initialValues: ConsultaRenainfPlaca = {
	placa: '',
};

export const schema = Yup.object<ConsultaRenainfPlaca>().shape({
	placa: Yup.string().required(`Campo Obrigatório: Placa.`),
});
