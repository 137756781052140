import React, { useState, useCallback, useEffect } from 'react';

import { Modal, Row, Col, Tooltip } from 'antd';
import { Formik, Form, Field, FormikProps } from 'formik';

// REDUX
import { ApplicationState } from 'store';
import { useSelector, useDispatch } from 'react-redux';

// ASSETS
import icon from 'assets/images/icons/icon_book.gif';

// COMPONENTS
import Input from 'components/Common/Form/Input/Input';
import ButtonImage from 'components/Common/ButtonImage';
import Error from 'components/Common/Notifications/FormError';
import SimpleTable from 'components/Common/Table';

// STYLED
import CreateGlobalStyle from 'components/Common/SearchButtons/reset';
import {
	Container,
	ButtonDiv,
	Button,
} from 'components/Common/SearchButtons/styled';

// FORM
import {
	municipiosClear,
	municipiosRequest,
} from 'store/modules/api/procon/dominiosCrm/municipios/actions';

import { OptionProps } from 'components/Common/Form/Select';
import getValidationsErrors, { initialValues } from './form';

interface MunicipioIBGE {
	numero: number;
	descricao: string;
	uf: string;
}

interface IPesquisaNaturalidadeResultado {
	codigo: number;
	digito: number;
	descricao: string;
	municipioIBGE: MunicipioIBGE | null;
}

interface Props {
	disabled?: boolean;
	onClick?: () => void;
	formikParent: FormikProps<any>;
	forcedOpenModal?: boolean;
}

const ConsultaMunicipio: React.FC<Props> = ({
	disabled = false,
	onClick,
	formikParent,
	forcedOpenModal = false,
}) => {
	const dispatch = useDispatch();

	const [showModal, setShowModal] = useState<boolean>(false);
	const [notificationErrors, setNotificationErrors] = useState<string[]>([]);

	const resultadosPesquisa = useSelector(
		(state: ApplicationState) => state.api.procon.dominiosCrm.municipios,
	);

	useEffect(() => {
		if (forcedOpenModal) {
			setShowModal(true);
		}
	}, [forcedOpenModal]);

	useEffect(() => {
		if (onClick && showModal) onClick();
	}, [onClick, showModal]);

	const handleShowModal = useCallback(() => {
		if (disabled) {
			return;
		}

		setShowModal(true);
	}, [disabled, setShowModal]);

	const handleHideModal = useCallback(() => {
		setShowModal(false);
		dispatch(municipiosClear());
	}, [setShowModal, dispatch]);

	const handleValidate = useCallback(
		async values => {
			setNotificationErrors([]);

			const errors = await getValidationsErrors(values);

			if (typeof errors === 'boolean') {
				return errors;
			}

			const formikErrors: string[] = Object.keys(errors).map(value => {
				return errors[value];
			});

			setNotificationErrors(formikErrors);

			return errors;
		},
		[setNotificationErrors],
	);

	const handleSubmit = useCallback(
		values => {
			setNotificationErrors([]);

			dispatch(municipiosRequest({ filtro: values.pesquisa }));
		},
		[setNotificationErrors, dispatch],
	);

	const handleSelectedRow = useCallback(
		(res: OptionProps) => {
			formikParent.setFieldValue('municipioId', res.value);
			formikParent.setFieldValue('municipioDescricao', res.label);

			handleHideModal();
		},
		[formikParent, handleHideModal],
	);

	return (
		<Tooltip title="Buscar Municípios">
			<Container>
				<Button
					type="button"
					icon={icon}
					disabled={disabled}
					onClick={handleShowModal}
				/>

				<Modal
					title="Buscar Municípios"
					visible={showModal}
					onCancel={handleHideModal}
					footer={null}
					style={{ animationDuration: '0s' }}
					destroyOnClose
				>
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValues}
						validate={handleValidate}
						onSubmit={handleSubmit}
					>
						{formik => (
							<Form autoComplete="off">
								{notificationErrors.length > 0 && (
									<Error errors={notificationErrors} />
								)}

								<Row gutter={[0, 10]}>
									<Col span={24}>
										<p>
											<strong>1 - Digite o nome do município:</strong>
										</p>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={17}>
										<Field
											as={Input}
											name="pesquisa"
											autoFocus
											error={!!formik.errors.pesquisa}
										/>
									</Col>

									<Col span={7}>
										<ButtonDiv>
											<ButtonImage type="submit" src="pesquisar" />
										</ButtonDiv>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={24} />
								</Row>
							</Form>
						)}
					</Formik>

					{resultadosPesquisa.data.length > 0 ? (
						<>
							<Row gutter={[0, 10]}>
								<Col span={24}>
									<p>
										<strong>2 - Escolha o Município:</strong>
									</p>
								</Col>
							</Row>

							<Row gutter={[0, 10]}>
								<Col span={24}>
									<SimpleTable
										headers={[
											{
												title: 'Nome do Município',
												dataIndex: 'label',
											},
										]}
										body={resultadosPesquisa.data}
										onClick={handleSelectedRow}
									/>
								</Col>
							</Row>
						</>
					) : (
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<SimpleTable
									messageNoResults={
										resultadosPesquisa.status === 200 &&
										!resultadosPesquisa.data.length
											? 'Nenhum resultado encontrato para a pesquisa.'
											: 'Faça uma pesquisa.'
									}
									headers={[
										{
											title: 'Nome do Município',
											dataIndex: 'label',
										},
									]}
									body={[]}
									onClick={handleSelectedRow}
								/>
							</Col>
						</Row>
					)}
				</Modal>

				<CreateGlobalStyle />
			</Container>
		</Tooltip>
	);
};

export default ConsultaMunicipio;
