import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, primeiroEmplacamento } from './types';

export const INITIAL_STATE: primeiroEmplacamento = {
	status: 0,
	data: null,
	restricaoFinanceira: '',
	escolhaPlaca: {
		chassi: '',
		cpfCnpjProprietario: '',
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): primeiroEmplacamento {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PRIMEIRO_EMPLACAMENTO_REQUEST: {
				draft.restricaoFinanceira = action.payload.restricaoFinanceira;
				break;
			}

			case Types.PRIMEIRO_EMPLACAMENTO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.PRIMEIRO_EMPLACAMENTO_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.PRIMEIRO_REDIRECT_ESCOLHA_REQUEST: {
				draft.status = INITIAL_STATE.status;
				draft.escolhaPlaca = {
					chassi: action.payload.chassi,
					cpfCnpjProprietario: action.payload.cpfCnpjProprietario,
				};
				break;
			}

			case Types.PRIMEIRO_EMPLACAMENTO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.escolhaPlaca = INITIAL_STATE.escolhaPlaca;

				break;
			}

			default:
		}
		return draft;
	});
}
