import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, deleteApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { CancelarPlacaPrimeiroEmplacamentoRequest, Types } from './types';
import {
	cancelarPlacaPrimeiroEmplacamentoSuccess,
	cancelarPlacaPrimeiroEmplacamentoFailure,
} from './actions';

function* cancelarPlacaPrimeiroEmplacamentoRequest(request: {
	type: string;
	payload: CancelarPlacaPrimeiroEmplacamentoRequest;
}) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/primeiro-emplacamento/placas-atribuidas?chassi=${payload.chassi}&cpfCnpjProprietario=${payload.cpfCnpjProprietario}}`,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(cancelarPlacaPrimeiroEmplacamentoSuccess(response));
	} else if (response.error) {
		yield put(cancelarPlacaPrimeiroEmplacamentoFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_REQUEST,
		cancelarPlacaPrimeiroEmplacamentoRequest,
	),
]);
