import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, relatorioAgendamentoMedicoPsicologico } from './types';

export const INITIAL_STATE: relatorioAgendamentoMedicoPsicologico = {
	status: 0,
	data: null,
};

export default function relatorioAgendamentoMedicoPsicologicoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): relatorioAgendamentoMedicoPsicologico {
	return produce(state, draft => {
		switch (action.type) {
			case Types.RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_REQUEST: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				break;
			}
			case Types.RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = action.payload.status;
				break;
			}
			case Types.RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_FAILURE: {
				draft.data = INITIAL_STATE.data;
				draft.status = action.payload.status;
				break;
			}
			case Types.RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				break;
			}
			default:
		}
	});
}
