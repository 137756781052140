// TYPES
import { ReducerAction } from 'store/modules/types';
import { IConsultaPesquisaCidadaoSelecionado } from '../consultaPesquisaCidadao/types';
import { ConsultaPesquisaCirg, Types } from './types';

export function consultaPesquisaCirgRequest(
	selecionado: IConsultaPesquisaCidadaoSelecionado,
	usuario: string,
	senha: string,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIRG_REQUEST,
		payload: {
			selecionado,
			usuario,
			senha,
		},
	};
}
export function consultaPesquisaCirgCidadaoSuccess(
	payload: ConsultaPesquisaCirg,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIRG_CIDADAO_SUCCESS,
		payload,
	};
}

export function consultaPesquisaCirgCidadaoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIRG_CIDADAO_CLEAR,
		payload: null,
	};
}

export function consultaPesquisaCirgSuccess(
	payload: ConsultaPesquisaCirg,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIRG_SUCCESS,
		payload,
	};
}
export function consultaPesquisaCirgFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIRG_FAILURE,
		payload: null,
	};
}

export function consultaPesquisaCirgMaeRequest(
	numeroRg: string,
	usuario: string,
	senha: string,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIRG_MAE_REQUEST,
		payload: {
			numeroRg,
			usuario,
			senha,
		},
	};
}
export function consultaPesquisaCirgMaeSuccess(
	payload: ConsultaPesquisaCirg,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIRG_MAE_SUCCESS,
		payload,
	};
}
export function consultaPesquisaCirgMaeFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIRG_MAE_FAILURE,
		payload: null,
	};
}

export function consultaPesquisaCirgClear(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIRG_CLEAR,
		payload: null,
	};
}

export function consultaPesquisaMaeCirgClear(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_MAE_CIRG_CLEAR,
		payload: null,
	};
}
