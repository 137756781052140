import { all } from 'redux-saga/effects';

import consultarNaturalidades from './consultarNaturalidades/saga';
import atualizarNaturalidade from './atualizarNaturalidade/saga';
import cadastrarNaturalidade from './cadastrarNaturalidade/saga';
import excluirNaturalidade from './excluirNaturalidade/saga';

export default all([
	consultarNaturalidades,
	atualizarNaturalidade,
	cadastrarNaturalidade,
	excluirNaturalidade,
]);
