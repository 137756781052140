import React, { useState, useEffect } from 'react';

import { Table, Empty } from 'antd';
import { ExpandableConfig } from 'antd/es/table/interface';

import { v4 } from 'uuid';

// STYLED
import { TableProps } from 'antd/lib/table';
import { Container } from './styled';

export interface IHeader {
	key?: string;
	title: any;
	dataIndex: string;
	visible?: boolean;
	children?: IChildren[];
	align?: 'left' | 'right' | 'center' | string;
	render?: (text: any, record: any, index: any) => any;
	defaultSortOrder?: string;
	width?: string;
}

interface IChildren {
	key?: string;
	title?: any;
	dataIndex?: string;
}

export type ScrollType = {
	x?: number;
	y?: number;
};

interface Props {
	headers: IHeader[];
	body: any;
	expandable?: ExpandableConfig<any> | undefined;
	pageSize?: number;
	size?: 'sm' | 'md' | 'lg';
	striped?: boolean;
	bold?: boolean;
	hover?: boolean;
	pagination?: boolean;
	total?: number;
	current?: number;
	messageNoResults?: string;
	status?: number | null;
	onChange?: (pagination: any, filters: any, sorter: any, extra: any) => void;
	rowClassName?: (record: any, index: number) => string;
	onClick?: (record: any, rowIndex: number | undefined) => void;
	rowSelection?: any;
	footerProps?: TableProps<unknown>['footer'] | undefined;
	showSizeChanger?: boolean;
	noInitialText?: boolean;
	scroll?: ScrollType;
}

const SimpleTable: React.FC<Props> = ({
	headers,
	body,
	expandable = undefined,
	pageSize = 5,
	size = 'md',
	striped = false,
	bold = false,
	hover = true,
	pagination = true,
	total = 0,
	current,
	messageNoResults = '',
	status = 0,
	onChange,
	rowClassName,
	onClick,
	rowSelection,
	footerProps = null,
	showSizeChanger = false,
	noInitialText = false,
	scroll,
}) => {
	const [dataHeader, setDataHeader] = useState<any[]>([]);
	const [dataSource, setDataSource] = useState<any[]>([]);
	const [text, setText] = useState<string>('Faça sua pesquisa');

	const footer = undefined;

	useEffect(() => {}, [footerProps]);

	useEffect(() => {
		const header = headers.map(row => {
			return { key: v4(), ...row };
		});

		setDataHeader(header);
	}, [headers]);

	useEffect(() => {
		if (body) {
			const data = body.map((row: any) => {
				return { key: v4(), ...row };
			});

			setDataSource(data);
		}
	}, [body]);

	useEffect(() => {
		if (status === 0) {
			setText('Faça sua pesquisa');
		}

		if (body?.length === 0 && status !== 0) {
			setText('Nenhum resultado encontrado');
			return;
		}
		if (noInitialText) setText('');
	}, [body, noInitialText, status]);

	return (
		<Container size={size} striped={striped} bold={bold} hover={hover}>
			<Table
				columns={dataHeader}
				dataSource={dataSource}
				expandable={expandable}
				size="small"
				locale={{
					emptyText: (
						<Empty
							description={messageNoResults === '' ? text : messageNoResults}
						/>
					),
				}}
				pagination={
					pagination
						? {
								defaultPageSize: pageSize,
								defaultCurrent: 1,
								total,
								current,
								showSizeChanger,
								pageSizeOptions: ['5', '10', '15', '20', '50', '100'],
						  }
						: false
				}
				rowClassName={(record, index) => {
					if (rowClassName) return rowClassName(record, index);

					return '';
				}}
				onRow={(record, rowIndex) => {
					return {
						onClick: () => {
							if (onClick) onClick(record, rowIndex);
						}, // click row
						// onDoubleClick: event => { }, // double click row
						// onContextMenu: event => { }, // right button click row
						// onMouseEnter: event => { }, // mouse enter row
						// onMouseLeave: event => { }, // mouse leave row
					};
				}}
				onChange={onChange}
				rowSelection={
					rowSelection && {
						type: 'checkbox',
						...rowSelection,
					}
				}
				footer={footerProps !== null ? footerProps : footer}
				scroll={scroll}
			/>
		</Container>
	);
};

export default SimpleTable;
