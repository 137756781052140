export enum Types {
	RESETAR_SENHA_USUARIO_MAINFRAME_REQUEST = '@SGU/RESETAR_SENHA_USUARIO_MAINFRAME',
	RESETAR_SENHA_USUARIO_MAINFRAME_SUCCESS = '@SGU/RESETAR_SENHA_USUARIO_MAINFRAME_SUCCESS',
	RESETAR_SENHA_USUARIO_MAINFRAME_FAILURE = '@SGU/RESETAR_SENHA_USUARIO_MAINFRAME_FAILURE',
	RESETAR_SENHA_USUARIO_MAINFRAME_CLEAR = '@SGU/RESETAR_SENHA_USUARIO_MAINFRAME_CLEAR',
}

export interface ResetarSenhaUsuarioMainframe {
	status: number;
	data: null | IResetarSenhaUsuarioMainframe;
}

export interface IResetarSenhaUsuarioMainframe {
	status: string;
	statusMensagem: string;
}

export interface ResetarSenhaUsuarioMainframeRequest {
	id: number | null;
	idLocalidade: number;
	usuario: string;
}
