import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import { Form, Formik, Field } from 'formik';
import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import {
	ROUTE_IIRGD_DARE_PESQUISA,
	ROUTE_IIRGD_CADASTRO,
	ROUTE_IIRGD_EDICAO,
	ROUTE_IIRGD_CONFERENCIA_DADOS,
} from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import { alterarObservacoesValuesTemp } from 'store/modules/api/iirgd/alterarObservacoes/actions';
import {
	cadastroRgConfigRedicao,
	cadastroRgObsSegundaVia,
} from 'store/modules/api/iirgd/cadastroRg/actions';
import { consultaPesquisaObservacaoClear } from 'store/modules/api/iirgd/consultaPesquisaObservacao/actions';
import { dareClear } from 'store/modules/api/dare/actions';
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import FormBox from 'components/Common/Form/FormBox';
import CheckBox from 'components/Common/Form/Checkbox';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import Select from 'components/Common/Form/Select';
import TextArea from 'components/Common/Form/Input/TextArea';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ValidDataInput from 'components/Common/Form/Input/ValidData';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import {
	calcularIdade,
	formatDate,
	onlyAlfaNumerico,
} from 'utils/genericFunctions';
import { RequestAlterarObservacoes } from 'store/modules/api/iirgd/alterarObservacoes/types';
import { IConsultaPesquisaObservacao } from 'store/modules/api/iirgd/consultaPesquisaObservacao/types';
import { ConsultarProcesso } from 'store/modules/api/iirgd/integrador/consultarProcesso/types';
import {
	isBetweenDate,
	setFormValuesByKeys,
} from '../../../utils/rgFicDigitalFunctions';

// FORM
import { initialValues, schema } from './form';

import { getObsValueRequest, threatDataCadastroRgSegundaVia } from '../form';

// COMPONENTS
import { Header } from '../../Header';
import DocumentoResponsavel from '../Componentes/DocumentoResponsavel';

// STYLED
import { SlashDivider } from './styled';

interface CheckBoxes {
	declaracaoPobreza: boolean;
	solicitarBloqueio: boolean;
	termoRecolhimentoRg: boolean;
	isento2Via10952: boolean;
	isento2Via062: boolean;
	termoTutela: boolean;
	isencaoPrimeiraViaCin: boolean;
}

export const FormSegundaVia: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [formData, setFormData] = useState<IConsultaPesquisaObservacao | null>(
		null,
	);

	const [checkboxesDisabled, setCheckboxesDisabled] = useState<CheckBoxes>({
		declaracaoPobreza: false,
		solicitarBloqueio: false,
		termoRecolhimentoRg: false,
		isento2Via10952: false,
		isento2Via062: false,
		termoTutela: false,
		isencaoPrimeiraViaCin: false,
	});

	const [ctpsEnabled, setCtpsEnabled] = useState<boolean>(false);
	const [procEnabled, setProcEnabled] = useState<boolean>(false);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const {
		alterarObservacoes,
		cadastroRg,
		consultaPesquisaCirg,
		consultaPesquisaCriminal,
		consultaPesquisaObservacao,
	} = useSelector((state: ApplicationState) => state.api.iirgd);

	const { consultarProcesso } = useSelector(
		(state: ApplicationState) => state.api.iirgd.integrador,
	);

	const [menorDezesseis, setMenorDesesseis] = useState<boolean>(false);

	const situacaoCin = useCallback((codigoSituacao: ConsultarProcesso[]) => {
		let situacao = false;

		codigoSituacao.forEach(item => {
			if (item.codigoSituacao === 3) {
				situacao = true;
			}
		});

		return situacao;
	}, []);

	const verifyIsOldPersonWithExceptions = useCallback(
		(userGenre: string, userBornDate: string) => {
			if (
				userBornDate &&
				userBornDate !== '' &&
				userGenre &&
				userGenre !== ''
			) {
				const isManOverOrEqual65 =
					(userGenre === 'M' && calcularIdade(userBornDate) >= 65) || false;

				const isWomanOverOrEqual60 =
					(userGenre === 'F' && calcularIdade(userBornDate) >= 60) || false;

				if (isManOverOrEqual65 || isWomanOverOrEqual60) {
					setCtpsEnabled(false);
					setCheckboxesDisabled(prev => ({
						...prev,
						isento2Via10952: !checkboxesDisabled.isencaoPrimeiraViaCin,
					}));
					setFormData(
						prev =>
							({
								...prev,
								isento2Via10952: !checkboxesDisabled.isencaoPrimeiraViaCin,
							} as IConsultaPesquisaObservacao),
					);
				} else {
					setCtpsEnabled(true);
				}
			}
		},
		[checkboxesDisabled.isencaoPrimeiraViaCin],
	);

	const enableRule10952 = useCallback(
		(userGenre: string, userBornDate: string, checked?: boolean) => {
			verifyIsOldPersonWithExceptions(userGenre, userBornDate);

			if (checked === true || checked === undefined) {
				setCheckboxesDisabled(prev => ({
					...prev,
					declaracaoPobreza:
						cadastroRg.form.principal?.dni === '10977' &&
						checkboxesDisabled.isencaoPrimeiraViaCin,
					isento2Via062:
						cadastroRg.form.principal?.dni === '10977' &&
						checkboxesDisabled.isencaoPrimeiraViaCin,
				}));
			} else if (checked === false) {
				setCheckboxesDisabled(prev => ({
					...prev,
					declaracaoPobreza: false,
					isento2Via062: false,
					isento2Via10952: false,
				}));
			}
		},
		[
			cadastroRg.form.principal,
			checkboxesDisabled.isencaoPrimeiraViaCin,
			verifyIsOldPersonWithExceptions,
		],
	);

	const handleRedirecionamento = useCallback(
		(formValues: IConsultaPesquisaObservacao) => {
			if (cadastroRg.form.principal) {
				const { entrega } = cadastroRg.form.principal;

				dispatch(dareClear());
				if (
					(entrega === 'P' &&
						(formValues.declaracaoPobreza ||
							formValues.isento2Via10952 ||
							formValues.isento2Via062)) ||
					(entrega === 'K' &&
						(formValues.declaracaoPobreza ||
							formValues.isento2Via10952 ||
							formValues.isento2Via062)) ||
					cadastroRg.config.acervo ||
					checkboxesDisabled.isencaoPrimeiraViaCin
				) {
					history.push(ROUTE_IIRGD_CONFERENCIA_DADOS);
					return;
				}

				// Redirecionamento para DARE.
				// Irá gerar taxa de correios e/ou taxa de emissão da segunda via.
				history.push(ROUTE_IIRGD_DARE_PESQUISA);
			}
		},
		[
			cadastroRg.form.principal,
			cadastroRg.config.acervo,
			dispatch,
			checkboxesDisabled.isencaoPrimeiraViaCin,
			history,
		],
	);

	const handleSubmit = useCallback(
		(formValues: IConsultaPesquisaObservacao) => {
			dispatch(clearNotifications());

			const finalValues: RequestAlterarObservacoes = getObsValueRequest(
				formValues,
				cadastroRg,
				login,
				consultaPesquisaCirg.data && consultaPesquisaCirg.data?.lote
					? consultaPesquisaCirg.data.lote
					: '',
				checkboxesDisabled.isencaoPrimeiraViaCin,
				cadastroRg.form.principal?.dni === '10977' &&
					situacaoCin(consultarProcesso.data || []),
			);
			if (
				formValues.isento2Via062 &&
				!isBetweenDate(formValues.rgRecolhidoEmissao, 90)
			) {
				addNotifications({
					errors: ['Data de emissão do RG não pode ser maior que 90 dias.'],
				});
				return;
			}

			if (formValues.isento2Via10952) {
				finalValues.ctpsObs = formValues.ctpsObs;
				finalValues.srObs = formValues.srObs;
				finalValues.estObs = formValues.estObs;
			}

			if (cadastroRg.form.principal?.argumento === 'acervo') {
				finalValues.lote = '000002';
			}

			if (finalValues.lote === '') {
				finalValues.lote = cadastroRg.tipo.primeiraVia ? '000001' : '000002';
			}

			const dataCadastroRgSegundaVia = threatDataCadastroRgSegundaVia({
				...formValues,
				isencaoPrimeiraViaCin: checkboxesDisabled.isencaoPrimeiraViaCin,
			});

			dispatch(alterarObservacoesValuesTemp(finalValues));
			dispatch(cadastroRgObsSegundaVia(dataCadastroRgSegundaVia));

			handleRedirecionamento({
				...formValues,
				isencaoPrimeiraViaCin: checkboxesDisabled.isencaoPrimeiraViaCin,
			});
		},
		[
			dispatch,
			cadastroRg,
			login,
			consultaPesquisaCirg.data,
			checkboxesDisabled.isencaoPrimeiraViaCin,
			situacaoCin,
			consultarProcesso.data,
			handleRedirecionamento,
		],
	);

	const handleButtonVoltar = useCallback(() => {
		dispatch(clearNotifications());
		dispatch(consultaPesquisaObservacaoClear());
		dispatch(cadastroRgConfigRedicao());

		if (cadastroRg.config.novo) {
			history.push(ROUTE_IIRGD_CADASTRO);
			return;
		}

		history.push(ROUTE_IIRGD_EDICAO);
	}, [dispatch, cadastroRg.config, history]);

	useEffect(() => {
		let hasData = false;
		const formPrincipal = cadastroRg.form.principal;
		const formSegundaVia = cadastroRg.form.observacoes.segundaVia;

		if (cadastroRg.info.idade < 16) {
			setMenorDesesseis(true);
		} else {
			setMenorDesesseis(false);
		}

		if (formPrincipal) {
			if (
				formPrincipal.dni === '10977' &&
				!situacaoCin(consultarProcesso.data || [])
			) {
				setCheckboxesDisabled(checkboxes => {
					return {
						...checkboxes,
						isencaoPrimeiraViaCin: true,
					};
				});
			} else if (
				formPrincipal.dni === '10977' &&
				situacaoCin(consultarProcesso.data || [])
			) {
				setCheckboxesDisabled(checkboxes => {
					return {
						...checkboxes,
						isencaoPrimeiraViaCin: false,
					};
				});
			} else {
				setCheckboxesDisabled(checkboxes => {
					return {
						...checkboxes,
						isencaoPrimeiraViaCin: false,
					};
				});
			}
		}

		if (formPrincipal && alterarObservacoes.temp !== null) {
			const { sexo, dataNascimento } = formPrincipal;
			hasData = true;

			setFormData(
				setFormValuesByKeys({
					dataNascimento,
					sexo,
					...alterarObservacoes.temp,
					rgRecolhidoEmissao: formatDate(
						alterarObservacoes.temp.rgRecolhidoEmissao,
					),
					tutelaData: alterarObservacoes.temp.tutelaData
						? formatDate(alterarObservacoes.temp.tutelaData)
						: '',

					cr: consultaPesquisaCriminal.restricaoCriminal,
				}) as IConsultaPesquisaObservacao,
			);

			if (
				formPrincipal &&
				formPrincipal.dni === '10977' &&
				!situacaoCin(consultarProcesso.data || [])
			) {
				setFormData(
					prev =>
						({
							...prev,
							declaracaoPobreza: false,
							isento2Via10952: false,
							isento2Via062: false,
							ctpsObs: '',
							srObs: '',
							estObs: '',
						} as IConsultaPesquisaObservacao),
				);
			}

			if (alterarObservacoes.temp.declaracaoPobreza) {
				setCheckboxesDisabled(checkboxes => {
					return {
						...checkboxes,
						isento2Via10952: checkboxesDisabled.isencaoPrimeiraViaCin,
						isento2Via062: checkboxesDisabled.isencaoPrimeiraViaCin,
					};
				});
			} else if (alterarObservacoes.temp.isento2Via10952) {
				const checked = !!alterarObservacoes.temp.isento2Via10952;

				enableRule10952(
					formPrincipal.sexo,
					cadastroRg.info.dataNascimento,
					checked,
				);
			} else if (alterarObservacoes.temp.isento2Via062) {
				setCheckboxesDisabled(checkboxes => {
					return {
						...checkboxes,
						declaracaoPobreza:
							checkboxesDisabled.isencaoPrimeiraViaCin &&
							cadastroRg.form.principal?.dni === '10977',
						isento2Via10952:
							checkboxesDisabled.isencaoPrimeiraViaCin &&
							cadastroRg.form.principal?.dni === '10977',
					};
				});
			}

			if (alterarObservacoes.temp.termoTutela) {
				setProcEnabled(true);
			}
		}
		// Primeira via ou Segunda via do RG (Edição).
		// Caso o cidadão tenha alguma observação cadastrada.
		else if (
			formPrincipal &&
			consultaPesquisaObservacao.result &&
			consultaPesquisaObservacao.result.observacaoCadastrada
		) {
			hasData = true;
			const { sexo, dataNascimento } = formPrincipal;
			setFormData(
				setFormValuesByKeys({
					dataNascimento,
					sexo,
					...consultaPesquisaObservacao.result,
					rgRecolhidoEmissao: formatDate(
						consultaPesquisaObservacao.result.rgRecolhidoEmissao,
					),
					tutelaData: consultaPesquisaObservacao.result.tutelaData
						? formatDate(consultaPesquisaObservacao.result.tutelaData)
						: '',
					dataExpedicaoDocumentoResponsavel: consultaPesquisaObservacao.result
						.dataExpedicaoDocumentoResponsavel
						? formatDate(
								consultaPesquisaObservacao.result
									.dataExpedicaoDocumentoResponsavel,
						  )
						: '',
					cr: consultaPesquisaCriminal.restricaoCriminal,
				}) as IConsultaPesquisaObservacao,
			);

			if (
				formPrincipal &&
				formPrincipal.dni === '10977' &&
				!situacaoCin(consultarProcesso.data || [])
			) {
				setFormData(
					prev =>
						({
							...prev,
							declaracaoPobreza: false,
							isento2Via10952: false,
							isento2Via062: false,
							ctpsObs: '',
							srObs: '',
							estObs: '',
						} as IConsultaPesquisaObservacao),
				);
			}

			if (consultaPesquisaObservacao.result.declaracaoPobreza) {
				setCheckboxesDisabled(checkboxes => {
					return {
						...checkboxes,
						isento2Via10952:
							cadastroRg.form.principal?.dni === '10977' &&
							checkboxesDisabled.isencaoPrimeiraViaCin,
						isento2Via062:
							cadastroRg.form.principal?.dni === '10977' &&
							checkboxesDisabled.isencaoPrimeiraViaCin,
					};
				});
			} else if (consultaPesquisaObservacao.result.isento2Via10952) {
				const checked = !!consultaPesquisaObservacao.result.isento2Via10952;

				enableRule10952(
					formPrincipal.sexo,
					formPrincipal.dataNascimento,
					checked,
				);
			} else if (consultaPesquisaObservacao.result.isento2Via062) {
				setCheckboxesDisabled(checkboxes => {
					return {
						...checkboxes,
						declaracaoPobreza:
							cadastroRg.form.principal?.dni === '10977' &&
							checkboxesDisabled.isencaoPrimeiraViaCin,
						isento2Via10952:
							cadastroRg.form.principal?.dni === '10977' &&
							checkboxesDisabled.isencaoPrimeiraViaCin,
					};
				});
			}

			if (consultaPesquisaObservacao.result.termoTutela) {
				setProcEnabled(true);
			}
		}
		// Quando já existe um formulário em andamento.
		else if (formPrincipal && formSegundaVia) {
			hasData = true;
			const { sexo, dataNascimento } = formPrincipal;
			setFormData(
				setFormValuesByKeys({
					dataNascimento,
					sexo,
					...formSegundaVia,
					cr: consultaPesquisaCriminal.restricaoCriminal,
				}) as IConsultaPesquisaObservacao,
			);

			if (
				formPrincipal &&
				formPrincipal.dni === '10977' &&
				!situacaoCin(consultarProcesso.data || [])
			) {
				setFormData(
					prev =>
						({
							...prev,
							declaracaoPobreza: false,
							isento2Via10952: false,
							isento2Via062: false,
							ctpsObs: '',
							srObs: '',
							estObs: '',
						} as IConsultaPesquisaObservacao),
				);
			}

			if (formSegundaVia.declaracaoPobreza) {
				setCheckboxesDisabled(checkboxes => {
					return {
						...checkboxes,
						declaracaoPobreza: false,
						isento2Via10952:
							cadastroRg.form.principal?.dni === '10977' &&
							checkboxesDisabled.isencaoPrimeiraViaCin,
						isento2Via062:
							cadastroRg.form.principal?.dni === '10977' &&
							checkboxesDisabled.isencaoPrimeiraViaCin,
					};
				});
			}

			if (formSegundaVia.isento2Via10952) {
				const checked = formSegundaVia.isento2Via10952;
				enableRule10952(
					formPrincipal.sexo,
					formPrincipal.dataNascimento,
					checked,
				);
			}

			if (formSegundaVia.isento2Via062) {
				setCheckboxesDisabled(checkboxes => {
					return {
						...checkboxes,
						isento2Via062: false,
						declaracaoPobreza:
							!checkboxesDisabled.isencaoPrimeiraViaCin &&
							cadastroRg.form.principal?.dni === '10977',
						isento2Via10952:
							!checkboxesDisabled.isencaoPrimeiraViaCin &&
							cadastroRg.form.principal?.dni === '10977',
					};
				});
			}

			if (formSegundaVia.termoTutela) {
				setProcEnabled(true);
			}

			return;
		}

		if (!hasData && formPrincipal) {
			const { sexo, dataNascimento } = formPrincipal;

			setFormData({
				...initialValues,
				sexo,
				dataNascimento,
				cr: consultaPesquisaCriminal.restricaoCriminal,
			} as IConsultaPesquisaObservacao);

			enableRule10952(sexo, dataNascimento);

			if (
				formPrincipal &&
				formPrincipal.dni === '10977' &&
				!situacaoCin(consultarProcesso.data || [])
			) {
				setFormData(
					prev =>
						({
							...prev,
							declaracaoPobreza: false,
							isento2Via10952: false,
							isento2Via062: false,
							ctpsObs: '',
							srObs: '',
							estObs: '',
						} as IConsultaPesquisaObservacao),
				);
			}
		}
	}, [
		alterarObservacoes.temp,
		cadastroRg,
		checkboxesDisabled.isencaoPrimeiraViaCin,
		consultaPesquisaCriminal,
		consultaPesquisaObservacao,
		consultarProcesso.data,
		enableRule10952,
		setCheckboxesDisabled,
		situacaoCin,
	]);

	return (
		<>
			{formData && (
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={formData}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							<Section title="Inclusão de Observações">
								<Header />
								<FormBox title="Declarações do Cidadão">
									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Field
												as={CheckBox}
												name="declaracaoPobreza"
												subtitle="Declaro ser pobre, conforme a lei 7.115 de 29/08/1983"
												checked={formik.values.declaracaoPobreza}
												disabled={
													checkboxesDisabled.declaracaoPobreza ||
													(cadastroRg.form.principal?.dni === '10977' &&
														checkboxesDisabled.isencaoPrimeiraViaCin)
												}
												onChange={(change: ChangeEvent<HTMLInputElement>) => {
													const { checked } = change.target;

													formik.setFieldValue('declaracaoPobreza', checked);

													if (checked) {
														formik.setFieldValue('isento2Via10952', false);
														formik.setFieldValue('ctpsObs', '');
														formik.setFieldValue('srObs', '');
														formik.setFieldValue('estObs', '');
														formik.setFieldValue('isento2Via062', false);

														setCheckboxesDisabled({
															...checkboxesDisabled,
															isento2Via10952:
																cadastroRg.form.principal?.dni === '10977' &&
																checkboxesDisabled.isencaoPrimeiraViaCin,
															isento2Via062:
																cadastroRg.form.principal?.dni === '10977' &&
																checkboxesDisabled.isencaoPrimeiraViaCin,
														});

														return;
													}

													setCheckboxesDisabled({
														...checkboxesDisabled,
														isento2Via10952: false,
														isento2Via062: false,
													});
												}}
												error={!!formik.errors.declaracaoPobreza}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Field
												as={CheckBox}
												name="solicitarBloqueio"
												subtitle="Perdeu RGE CIN e solicita seu bloqueio"
												checked={formik.values.solicitarBloqueio}
												disabled={
													checkboxesDisabled.solicitarBloqueio ||
													formik.values.termoRecolhimentoRg
												}
												error={!!formik.errors.solicitarBloqueio}
												onChange={(change: ChangeEvent<HTMLInputElement>) => {
													const { checked } = change.target;

													formik.setFieldValue('solicitarBloqueio', checked);

													if (checked) {
														formik.setFieldValue('rgRecolhidoEmissao', '');
														formik.setFieldValue('rgRecolhidoIdentific', '');
														formik.setFieldValue('rgRecolhidoPor', '');
													}
												}}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Field
												as={CheckBox}
												name="termoRecolhimentoRg"
												subtitle="Está ciente que o RGE CIN abaixo será recolhido e inutilizado"
												checked={formik.values.termoRecolhimentoRg}
												disabled={
													checkboxesDisabled.termoRecolhimentoRg ||
													formik.values.solicitarBloqueio
												}
												error={!!formik.errors.termoRecolhimentoRg}
												onChange={(change: ChangeEvent<HTMLInputElement>) => {
													const { checked } = change.target;

													formik.setFieldValue('termoRecolhimentoRg', checked);

													if (!checked) {
														formik.setFieldValue('rgRecolhidoEmissao', '');
														formik.setFieldValue('rgRecolhidoIdentific', '');
														formik.setFieldValue('rgRecolhidoPor', '');
													}
												}}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24} />
									</Row>

									<Section title="Informações do RGE/CIN" size="sm">
										<Row gutter={[0, 10]}>
											<Col span={9}>
												<Field
													as={ValidDataInput}
													title="Expedido em"
													titleSize="lg"
													name="rgRecolhidoEmissao"
													type="text"
													mask="99/99/9999"
													disabled={
														formik.values.solicitarBloqueio ||
														!formik.values.termoRecolhimentoRg
													}
													required={formik.values.termoRecolhimentoRg}
													error={hasError(formik.errors, 'rgRecolhidoEmissao')}
													formik={formik}
													onChange={(v: string) =>
														formik.setFieldValue('rgRecolhidoEmissao', v)
													}
												/>
											</Col>
										</Row>

										<Row gutter={[0, 10]}>
											<Col span={24} />
										</Row>

										<Row>
											<Col span={9}>
												<Field
													as={InputMask}
													title="Setor Identificador"
													titleSize="lg"
													name="rgRecolhidoIdentific"
													mask="999999"
													maskChar=""
													disabled={
														formik.values.solicitarBloqueio ||
														!formik.values.termoRecolhimentoRg
													}
													required={formik.values.termoRecolhimentoRg}
													error={hasError(
														formik.errors,
														'rgRecolhidoIdentific',
													)}
												/>
											</Col>

											<Col span={13}>
												<Field
													as={Input}
													title="Recolhido por"
													name="rgRecolhidoPor"
													value={onlyAlfaNumerico(formik.values.rgRecolhidoPor)}
													maxLength={30}
													required={formik.values.termoRecolhimentoRg}
													disabled={
														formik.values.solicitarBloqueio ||
														!formik.values.termoRecolhimentoRg
													}
													error={hasError(formik.errors, 'rgRecolhidoPor')}
												/>
											</Col>
										</Row>
									</Section>

									<Row gutter={[0, 10]}>
										<Col span={24} />
									</Row>

									{cadastroRg.info.maior && (
										<>
											<Row>
												<Col span={24}>
													<div style={{ marginLeft: '90px' }}>
														<Field
															as={CheckBox}
															name="cr"
															subtitle="CR"
															checked={formik.values.cr}
															disabled
															error={!!formik.errors.cr}
														/>
													</div>
												</Col>
											</Row>

											<Row gutter={[0, 10]}>
												<Col span={24} />
											</Row>
										</>
									)}

									<Row gutter={[0, 0]}>
										<Col span={24}>
											<Field
												as={TextArea}
												title="Observações"
												titleSize="sm"
												name="observacao"
												defaultValue={formData.observacao}
												value={
													formik.values.observacao
														? formik.values.observacao
														: ''
												}
												maxLength={338}
												size={100}
												error={!!formik.errors.observacao}
											/>
										</Col>
									</Row>
								</FormBox>

								<Row gutter={[0, 10]}>
									<Col span={24} />
								</Row>

								{menorDezesseis && <DocumentoResponsavel formik={formik} />}

								<Row gutter={[0, 10]}>
									<Col span={24} />
								</Row>

								<FormBox title="Complementação do IIRGD">
									<Row gutter={[0, 10]}>
										<Col span={12}>
											<Field
												as={CheckBox}
												name="isento2Via10952"
												subtitle="Isento da taxa de 2ª via conforme lei estadual 10.952 de 07/11/2001"
												checked={formik.values.isento2Via10952}
												disabled={
													checkboxesDisabled.isento2Via10952 ||
													checkboxesDisabled.isencaoPrimeiraViaCin
												}
												onChange={(change: ChangeEvent<HTMLInputElement>) => {
													const { checked } = change.target;
													const sexo = cadastroRg.form?.principal?.sexo || '';
													const dataNascimento =
														cadastroRg.form?.principal?.dataNascimento || '';

													formik.setFieldValue('isento2Via10952', checked);

													enableRule10952(sexo, dataNascimento, checked);

													if (checked) {
														formik.setFieldValue('declaracaoPobreza', false);
														formik.setFieldValue('isento2Via062', false);
													} else {
														formik.setFieldValue('ctpsObs', '');
														formik.setFieldValue('srObs', '');
														formik.setFieldValue('estObs', '');
													}
												}}
												error={!!formik.errors.isento2Via10952}
											/>

											<small style={{ color: 'red', marginLeft: '25px' }}>
												(Idoso, Idosa ou Desempregado)
											</small>
										</Col>

										<Col span={4}>
											<Field
												as={InputMask}
												title="CTPS"
												titleSize={50}
												name="ctpsObs"
												mask="9999999999"
												maskChar=""
												required={ctpsEnabled && formik.values.isento2Via10952}
												disabled={
													!ctpsEnabled || !formik.values.isento2Via10952
												}
												error={
													hasError(formik.errors, 'ctps') ||
													hasError(formik.errors, 'ctpsFeminino') ||
													hasError(formik.errors, 'ctpsMasculino')
												}
											/>
										</Col>

										<Col span={3}>
											<Field
												as={InputMask}
												title="Série"
												titleSize={47}
												name="srObs"
												mask="9999"
												maskChar=""
												required={ctpsEnabled && formik.values.isento2Via10952}
												disabled={
													!ctpsEnabled || !formik.values.isento2Via10952
												}
												error={
													hasError(formik.errors, 'ctps') ||
													hasError(formik.errors, 'ctpsFeminino') ||
													hasError(formik.errors, 'ctpsMasculino')
												}
											/>
										</Col>

										<Col span={5}>
											<Field
												as={Select}
												title="Estado"
												titleSize={65}
												name="estObs"
												defaultFirstOption={false}
												options={data.uf}
												onChange={(uf: string) =>
													formik.setFieldValue('estObs', uf)
												}
												required={ctpsEnabled && formik.values.isento2Via10952}
												disabled={
													!ctpsEnabled || !formik.values.isento2Via10952
												}
												size={60}
												error={
													hasError(formik.errors, 'ctps') ||
													hasError(formik.errors, 'ctpsFeminino') ||
													hasError(formik.errors, 'ctpsMasculino')
												}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={12}>
											<Field
												as={CheckBox}
												name="isento2Via062"
												subtitle="Isento da taxa de 2ª via conforme Portaria 062/2001"
												checked={formik.values.isento2Via062}
												disabled={
													checkboxesDisabled.isento2Via062 ||
													(cadastroRg.form.principal?.dni === '10977' &&
														checkboxesDisabled.isencaoPrimeiraViaCin)
												}
												onChange={(change: ChangeEvent<HTMLInputElement>) => {
													const { checked } = change.target;

													formik.setFieldValue('isento2Via062', checked);

													if (checked) {
														formik.setFieldValue('declaracaoPobreza', false);
														formik.setFieldValue('solicitarBloqueio', false);
														formik.setFieldValue('termoRecolhimentoRg', true);
														formik.setFieldValue('isento2Via10952', false);
														formik.setFieldValue('ctpsObs', '');
														formik.setFieldValue('srObs', '');
														formik.setFieldValue('estObs', '');

														setCheckboxesDisabled({
															...checkboxesDisabled,
															declaracaoPobreza:
																cadastroRg.form.principal?.dni === '10977' &&
																checkboxesDisabled.isencaoPrimeiraViaCin,
															solicitarBloqueio: true,
															termoRecolhimentoRg: true,
															isento2Via10952:
																cadastroRg.form.principal?.dni === '10977' &&
																checkboxesDisabled.isencaoPrimeiraViaCin,
														});

														setCtpsEnabled(false);

														return;
													}

													setCheckboxesDisabled({
														...checkboxesDisabled,
														declaracaoPobreza: false,
														solicitarBloqueio: false,
														termoRecolhimentoRg: false,
														isento2Via10952: false,
													});
												}}
												error={!!formik.errors.isento2Via062}
											/>

											<small style={{ color: 'red', marginLeft: '25px' }}>
												(Erro de digitação do documento dentro do prazo de 90
												dias)
											</small>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={12}>
											<Field
												as={CheckBox}
												name="termoTutela"
												subtitle="Termo de Guarda/Tutela/Conselho"
												checked={formik.values.termoTutela}
												onChange={(change: ChangeEvent<HTMLInputElement>) => {
													const { checked } = change.target;

													formik.setFieldValue('termoTutela', checked);

													setProcEnabled(checked);

													if (!checked) {
														formik.setFieldValue('tutelaProc', '');
														formik.setFieldValue('tutelaAno', '');
														formik.setFieldValue('tutelaData', '');
													}
												}}
												error={!!formik.errors.termoTutela}
											/>
										</Col>

										<Col span={4}>
											<Field
												as={InputMask}
												title="Proc."
												titleSize={50}
												name="tutelaProc"
												maskChar=""
												mask="9999999999"
												required={procEnabled}
												disabled={!procEnabled}
												error={hasError(formik.errors, 'proc')}
											/>
										</Col>

										<Col>
											<SlashDivider>
												<div>/</div>
											</SlashDivider>
										</Col>

										<Col span={3}>
											<Field
												as={InputMask}
												name="tutelaAno"
												mask="9999"
												maskChar=""
												required={procEnabled}
												disabled={!procEnabled}
												error={hasError(formik.errors, 'proc')}
											/>
										</Col>

										<Col span={4}>
											<Field
												as={ValidDataInput}
												title="de"
												titleSize={30}
												titleAlign="end"
												name="tutelaData"
												type="text"
												mask="99/99/9999"
												maskChar=""
												required={procEnabled}
												disabled={!procEnabled}
												formik={formik}
												size={79}
												error={
													hasError(formik.errors, 'proc') ||
													hasError(formik.errors, 'validaDataProcesso')
												}
											/>
										</Col>
									</Row>

									{cadastroRg.form.principal &&
										cadastroRg.form.principal.dni === '10977' && (
											<Row gutter={[0, 10]}>
												<Col span={11}>
													<Field
														as={CheckBox}
														name="isencaoPrimeiraViaCin"
														subtitle="Isenção da Taxa da CIN"
														disabled
														checked={checkboxesDisabled.isencaoPrimeiraViaCin}
														onChange={(
															change: ChangeEvent<HTMLInputElement>,
														) => {
															const { checked } = change.target;

															formik.setFieldValue(
																'isencaoPrimeiraViaCin',
																checked,
															);
														}}
														error={!!formik.errors.termoTutela}
													/>
												</Col>
											</Row>
										)}
									{/* <Row gutter={[0, 10]}>
										<Col span={11}>
											<Field
												as={CheckBox}
												name="temCin"
												subtitle="Tem CIN?"
												disabled
												checked={checkboxesDisabled.temCin}
												onChange={(change: ChangeEvent<HTMLInputElement>) => {
													const { checked } = change.target;

													formik.setFieldValue('temCin', checked);
												}}
												error={!!formik.errors.temCin}
											/>
										</Col>
									</Row> */}
								</FormBox>

								<Row gutter={[0, 30]}>
									<Col span={24} />
								</Row>

								<Row gutter={[0, 10]} justify="center">
									<Col>
										<ButtonImage type="submit" src="avancar" />
									</Col>
								</Row>
							</Section>

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};
