import { Types } from './types';

export function dominiosRequest() {
	return {
		type: Types.DOMINIOS_REQUEST,
	};
}

export function dominiosSuccess(payload: object) {
	return {
		type: Types.DOMINIOS_SUCCESS,
		payload,
	};
}

export function dominiosFailure(payload: boolean) {
	return {
		type: Types.DOMINIOS_FAILURE,
		payload,
	};
}
