// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaConsumidorRequest, Types } from './types';

export function consultaConsumidorRequest(
	payload: ConsultaConsumidorRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_CONSUMIDOR_REQUEST,
		payload,
	};
}
export function consultaConsumidorSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_CONSUMIDOR_SUCCESS,
		payload,
	};
}
export function consultaConsumidorFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_CONSUMIDOR_FAILURE,
		payload,
	};
}
export function consultaConsumidorClear(): ReducerAction {
	return {
		type: Types.CONSULTA_CONSUMIDOR_CLEAR,
		payload: null,
	};
}
