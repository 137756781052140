import {
	addNotifications,
	clearNotificationsValidate,
	// showInside,
} from '../store/modules/app/notifications/actions';

export default function handleFailed(
	errors: any,
	semNotificacao?: boolean,
	defaultErrorMessage?: string,
	serviceIntegrador?: boolean,
) {
	let notifications: any = [];

	clearNotificationsValidate();

	Object.keys(errors).forEach((item: any) => {
		if (errors[item]?.data?.mensagens) {
			notifications = errors[item]?.data?.mensagens;
		} else if (errors[item]?.data?.mensagem) {
			notifications.push(errors[item]?.data?.mensagem);
		} else if (errors[item]?.data?.message) {
			notifications.push(errors[item]?.data?.message);
		} else if (errors[item]?.data?.messages) {
			if (Array.isArray(errors[item]?.data?.messages)) {
				notifications = errors[item]?.data?.messages;
			} else {
				notifications = [errors[item]?.data?.messages];
			}
		} else if (errors.mensagem_erro) {
			notifications.push(errors.mensagem_erro);
		} else if (errors[item]?.data?.mensagemErro) {
			if (Array.isArray(errors[item]?.data?.mensagemErro)) {
				notifications = errors[item]?.data?.mensagemErro;
			} else {
				notifications = [errors[item]?.data?.mensagemErro];
			}
		} else if (errors[item]?.data?.WebErrorMessage) {
			if (Array.isArray(errors[item]?.data?.WebErrorMessage)) {
				notifications = errors[item]?.data?.WebErrorMessage;
			} else {
				notifications = [errors[item]?.data?.WebErrorMessage];
			}
		} else if (errors[item]?.data?.ErrorMessage) {
			if (Array.isArray(errors[item]?.data?.ErrorMessage)) {
				notifications = errors[item]?.data?.ErrorMessage;
			} else {
				notifications = [errors[item]?.data?.ErrorMessage];
			}
		} else if (item === 'code' && errors[item] === 'ECONNABORTED') {
			notifications =
				errors.config?.timeoutErrorMessage &&
				errors.config.timeoutErrorMessage !== ''
					? errors.config.timeoutErrorMessage
					: 'Tempo máximo para o retorno desta requisição foi excedido. Tente novamente.';
		} else if (serviceIntegrador && errors[item]?.data) {
			notifications = [errors[item]?.data];
		}

		if (errors[item]?.data?.numRg) {
			notifications.push(errors[item]?.data?.numRg);
		}
	});

	if (notifications.length === 0) {
		notifications = [
			defaultErrorMessage && defaultErrorMessage !== ''
				? defaultErrorMessage
				: 'Serviço indisponível ou erro de conexão. Entre em contato com o suporte.',
		];
	}

	if (notifications.length === 1 && !semNotificacao) {
		addNotifications({ errors: notifications[0] });
	} else if (notifications.length > 1 && !semNotificacao) {
		addNotifications({ errors: notifications });
	} else if (!semNotificacao) {
		addNotifications({ errors: notifications });
	}

	return notifications;
}

export async function handleFailedBody(errors: any) {
	let errorBody = 0;

	Object.keys(errors).forEach((item: any) => {
		if (errors[item]?.status && errors[item]?.data) {
			errorBody = errors[item];
		}
	});
	return errorBody;
}

export function handleValidations(errors: any) {
	// addNotifications(errors);
	addNotifications({ errors });
}
