import React from 'react';

import { Col, Row } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import Section from 'components/Common/Section';

const DadosMutuario: React.FC = () => {
	const { consultarMutuario } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	return (
		<>
			{consultarMutuario.data && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Section>
							<Row>
								<Col span={16}>
									<Row gutter={[0, 5]}>
										<Col span={6}>Nome:</Col>

										<Col span={18}>
											<b>{consultarMutuario.data.nome.toUpperCase()}</b>
										</Col>
									</Row>
								</Col>

								<Col span={7} offset={1}>
									<Row gutter={[0, 5]}>
										<Col span={5}>CPF:</Col>

										<Col>
											<b>{consultarMutuario.data.cpf}</b>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={4}>Empreendimento:</Col>

								<Col span={20}>
									<b>{consultarMutuario.data.cnjHab.toUpperCase()}</b>
								</Col>
							</Row>

							<Row>
								<Col span={16}>
									<Row>
										<Col span={6}>Endereço:</Col>

										<Col span={18}>
											<b>{consultarMutuario.data.endCompleto.toUpperCase()}</b>
										</Col>
									</Row>
								</Col>

								<Col span={7} offset={1}>
									<Row>
										<Col span={5}>Conta:</Col>

										<Col>
											<b>{consultarMutuario.data.numeroContrato}</b>
										</Col>
									</Row>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			)}
		</>
	);
};

export default DadosMutuario;
