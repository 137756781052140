import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	IRequestConsultaMarcaModelo,
	consultaMarcaModeloSuccess,
	consultaMarcaModeloFailure,
} from './actions';

function* consultaMarcaModeloRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaMarcaModelo } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consulta-marca-modelo`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultaMarcaModeloSuccess(response.data));
	} else if (response.error) {
		yield put(consultaMarcaModeloFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTA_MARCA_MODELO_REQUEST, consultaMarcaModeloRequest),
]);
