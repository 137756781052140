import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarNaturalidade } from './types';

export const INITIAL_STATE: CadastrarNaturalidade = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): CadastrarNaturalidade {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CADASTRAR_NATURALIDADE: {
				draft.status = INITIAL_STATE.status;
				break;
			}

			case Types.CADASTRAR_NATURALIDADE_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = action.payload.status;
				break;
			}

			case Types.CADASTRAR_NATURALIDADE_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CADASTRAR_NATURALIDADE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
