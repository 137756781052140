import { combineReducers } from 'redux';

import agendarExameMedico from './agendarExameMedico/reducer';
import agendarExames from './agendarExames/reducer';
import agendarExamesRenachIntegrado from './agendarExamesRenachIntegrado/reducer';
import agendarExamesRenovacao from './agendarExamesRenovacao/reducer';
import consulta from './consultaDivisaoEquitativa/reducer';
import dadosCidadao from './dadosCidadao/reducer';
import datasExame from './datasExame/reducer';
import datasExamePsicologico from './datasExamePsicologico/reducer';
import horariosPorData from './horariosPorData/reducer';
import horariosPsicologoPorData from './horariosPsicologoPorData/reducer';
import microRegioes from './microRegioes/reducer';
import regiaoMicroRegiaoPorCep from './regiaoMicroRegiaoPorCep/reducer';
import regioes from './regioes/reducer';
import consultaAgendamentos from './consultaAgendamentos/reducer';
import cancelarAgendamentoPsicologo from './cancelarAgendamentoPsicologo/reducer';

export default combineReducers({
	agendarExameMedico,
	agendarExames,
	agendarExamesRenachIntegrado,
	agendarExamesRenovacao,
	consulta,
	dadosCidadao,
	datasExame,
	datasExamePsicologico,
	horariosPorData,
	horariosPsicologoPorData,
	microRegioes,
	regiaoMicroRegiaoPorCep,
	regioes,
	consultaAgendamentos,
	cancelarAgendamentoPsicologo,
});
