import produce from 'immer';

// TYPES
import { Types, consultaDivisaoEquitativa } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaDivisaoEquitativa = {
	status: 0,
	data: null,
};

export default function consultaDivisaoEquitativaReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaDivisaoEquitativa {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_DIVISAO_EQUITATIVA: {
				break;
			}

			case Types.CONSULTA_DIVISAO_EQUITATIVA_SUCCESS: {
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTA_DIVISAO_EQUITATIVA_FAILURE: {
				break;
			}

			default:
		}
	});
}
