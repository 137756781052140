import React, { useCallback, useEffect, useState } from 'react';

import { Col, Row, Modal } from 'antd';
import { Field, Form, Formik } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultaUsuariosMainframeRequest,
	consultaUsuariosMainframeClear,
} from 'store/modules/api/iirgd/autorizacaoEspecial/consulta/actions';
import {
	cadastroAutorizacaoEspecialRequest,
	cadastroAutorizacaoEspecialClear,
} from 'store/modules/api/iirgd/autorizacaoEspecial/cadastro/actions';
import {
	ConsultaUsuariosMainframeData,
	ConsultaUsuariosMainframeRequest,
} from 'store/modules/api/iirgd/autorizacaoEspecial/consulta/types';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { formatDate, limparMascara } from 'utils/genericFunctions';

// COMPONENTS
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import SimpleTable from 'components/Common/Table';

// FORM
import ColorBox from 'components/Common/ColorBox';
import { initialValues, schema } from './form';

const HabilirRgNaturalizado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [cpf, setCpf] = useState('');

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { consulta, cadastro } = useSelector(
		(state: ApplicationState) => state.api.iirgd.autorizacaoEspecial,
	);

	useEffect(() => {
		dispatch(consultaUsuariosMainframeClear());
		dispatch(cadastroAutorizacaoEspecialClear());
	}, [dispatch]);

	const handleSubmit = useCallback(
		(formValues: ConsultaUsuariosMainframeRequest) => {
			formValues.codigoLocalidade = login.user.idLocalidade;
			formValues.cpf = limparMascara(formValues.cpf);
			setCpf(limparMascara(formValues.cpf));
			dispatch(consultaUsuariosMainframeRequest(formValues));
		},
		[dispatch, login.user.idLocalidade],
	);

	const handleActivate = useCallback(
		(data: ConsultaUsuariosMainframeData) => {
			Modal.confirm({
				title: `Habilitar RGE CIN de Naturalidade Estrangeira para o Operador: ${data.idUsuarioMainframe} CPF: ${cpf}`,
				okText: 'Confirmar',
				cancelText: 'Cancelar',
				onOk() {
					dispatch(
						cadastroAutorizacaoEspecialRequest({
							confirma: 'S',
							atendente: data.idUsuarioMainframe,
							motivo: '1',
							usuario: login.mainframe.idUsuario,
							senha: login.mainframe.senhaMainFrame,
						}),
					);
				},
				onCancel() {},
			});
		},
		[cpf, dispatch, login.mainframe.idUsuario, login.mainframe.senhaMainFrame],
	);

	const columns = [
		{
			dataIndex: 'idUsuarioMainframe',
			align: 'center',
			title: 'Código do Operador',
		},
		{
			dataIndex: 'dtCadastro',
			title: 'Data Cadastro',
			align: 'center',
			render: (text: string, record: ConsultaUsuariosMainframeData) => {
				return formatDate(record.dtCadastro);
			},
		},
		{
			dataIndex: '',
			title: 'Vincular com RGE/CIN',
			align: 'center',
			render: (data: ConsultaUsuariosMainframeData) => (
				<Row justify="center" align="middle">
					<Col>
						<ButtonImage
							type="submit"
							src="btn-habilitar"
							onClick={() => handleActivate(data)}
						/>
					</Col>
				</Row>
			),
		},
	];

	return (
		<Row>
			<Col span={24}>
				<Section title="Habilitar RGE CIN​ de Naturalizado">
					{cadastro.data && (
						<ColorBox
							bgColor="verde"
							successTitle={`Operador ${cadastro.request?.atendente} habilitado com sucesso!`}
							title
						>
							<Row gutter={[0, 10]}>
								<Col>
									<p>
										<strong>
											OBS:
											<span style={{ marginLeft: '5px' }}>
												{cadastro.data.statusMSG}
											</span>
										</strong>
									</p>
								</Col>
							</Row>
						</ColorBox>
					)}

					<Row gutter={[0, 20]}>
						<Col span={24} />
					</Row>

					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValues}
						validate={values => getValidationsErrors(values, schema)}
						onSubmit={handleSubmit}
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[0, 10]}>
									<Col span={12}>
										<Field
											as={InputMask}
											title="CPF do Operador"
											titleSize="lg"
											name="cpf"
											mask="999.999.999-99"
											error={!!formik.errors.cpf}
											required
										/>
									</Col>

									<Col offset={1} span={6}>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						)}
					</Formik>

					{consulta.data && (
						<SimpleTable
							headers={columns}
							body={consulta.data}
							size="sm"
							striped
							bold
							hover={false}
							pagination={false}
						/>
					)}
				</Section>

				<ButtonVoltar
					navigate={false}
					onClick={() => {
						history.push(ROUTE_IIRGD);
					}}
				/>
			</Col>
		</Row>
	);
};

export default HabilirRgNaturalizado;
