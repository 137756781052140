import produce from 'immer';

// TYPES
import { Types, ConsultarCadastroCidadao } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultarCadastroCidadao = {
	status: 0,
	data: null,
};

export default function modulo(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarCadastroCidadao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_CADASTRO_CIDADAO_REQUEST: {
				break;
			}
			case Types.CONSULTAR_CADASTRO_CIDADAO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTAR_CADASTRO_CIDADAO_FAILURE: {
				draft.status = action.payload.data.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTAR_CADASTRO_CIDADAO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
