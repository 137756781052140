import React from 'react';

import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ColorBox from 'components/Common/ColorBox';

import { RiAlertFill } from 'react-icons/ri';
import { AlertArea } from './styled';

interface AlertaUsuarioProps {
	diasVencimentoCnh: Number;
	nomeCondutor: string;
	validadeCnh: string;
	unexpiredLicense?: boolean;
	onClickProsseguirSegundaVia: () => void;
}

export function AlertaCnhValidadePeriodoPermissionario({
	diasVencimentoCnh,
	nomeCondutor,
	validadeCnh,
	unexpiredLicense,
	onClickProsseguirSegundaVia,
}: AlertaUsuarioProps) {
	return (
		<Section title="Status Condutor">
			<h3 style={{ textTransform: 'uppercase' }}>{nomeCondutor}</h3>
			<AlertArea>
				<ColorBox bgColor="vermelho" type="error">
					<span className="alert-area__title-box-area">
						<RiAlertFill color="#c33" size={25} />
						<h3>Atenção</h3>
					</span>

					{unexpiredLicense && (
						<span className="alert-area__message-box-area">
							Cnh Selecionada não se enquadra no período de Permissionário.
						</span>
					)}
					{unexpiredLicense ? (
						<span className="alert-area__message-box-area">
							Data atual vencimento =
							<span className="message-box-area__strong-red">
								{' '}
								{validadeCnh}
							</span>
							. Faltam{' '}
							<span className="message-box-area__strong-red">
								{' '}
								{diasVencimentoCnh}{' '}
							</span>{' '}
							dias para o vencimento da CNH.
						</span>
					) : (
						<>
							<span className="alert-area__message-box-area">
								Validade da Permissão para Dirigir =
								<span className="message-box-area__strong-red">
									{' '}
									{validadeCnh}
								</span>
								. Faltam{' '}
								<span className="message-box-area__strong-red">
									{' '}
									{diasVencimentoCnh}{' '}
								</span>{' '}
								dias para mudar para CNH Definitiva.
							</span>
							<span className="alert-area__message-box-area">
								A CNH Definitiva só pode ser emitida após 365 dias da data da 1ª
								Habilitação.
							</span>
						</>
					)}
					<span className="alert-area__message-box-area">
						É possível tirar uma 2a via com a mesma validade da CNH atual. Ao
						prosseguir, o processo será redirecionado para a Emissão de Segunda
						Via.
					</span>
					<div className="buttons-area">
						{unexpiredLicense ? (
							<ButtonImage
								type="button"
								src="btn-prosseguir-segunda-via"
								onClick={onClickProsseguirSegundaVia}
							/>
						) : (
							<ButtonImage
								type="button"
								src="prosseguir"
								onClick={onClickProsseguirSegundaVia}
							/>
						)}
					</div>
				</ColorBox>
			</AlertArea>
		</Section>
	);
}

AlertaCnhValidadePeriodoPermissionario.defaultProps = {
	unexpiredLicense: false,
};
