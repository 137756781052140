import { Types, IDownloadDocumentosRequest } from './types';

export function downloadDocumentosRequest(payload: IDownloadDocumentosRequest) {
	return {
		type: Types.DOWNLOAD_DOCUMENTO_REQUEST,
		payload,
	};
}
export function downloadDocumentosSuccess(payload: any) {
	return {
		type: Types.DOWNLOAD_DOCUMENTO_SUCCESS,
		payload,
	};
}
export function downloadDocumentosFailure(payload: any) {
	return {
		type: Types.DOWNLOAD_DOCUMENTO_FAILURE,
		payload,
	};
}
export function downloadDocumentosClear(): any {
	return {
		type: Types.DOWNLOAD_DOCUMENTO_CLEAR,
	};
}
