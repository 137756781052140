import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirMensagemRequest } from './types';

export function excluirMensagemRequest(
	data: ExcluirMensagemRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_MENSAGEM_REQUEST,
		payload: data,
	};
}
export function excluirMensagemSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_MENSAGEM_SUCCESS,
		payload,
	};
}
export function excluirMensagemFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_MENSAGEM_FAILURE,
		payload: null,
	};
}
export function excluirMensagemClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_MENSAGEM_CLEAR,
		payload: null,
	};
}
