import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, consultarVeiculoSegundaViaPlaca } from './types';

export const INITIAL_STATE: consultarVeiculoSegundaViaPlaca = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultarVeiculoSegundaViaPlaca {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_REQUEST: {
				break;
			}

			case Types.CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}

			case Types.CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.CONSULTAR_VEICULO_SEGUNDA_VIA_PLACA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
