import { IImpressaoOficioSegundaViaPlaca } from 'pages/DetranCrv/Processos/ImpressaoOficioSegundaViaPlaca/form';
import { Types } from './types';

export function impressaoOficioSegundaViaPlacaRequest(
	payload: IImpressaoOficioSegundaViaPlaca,
) {
	return {
		type: Types.IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA_REQUEST,
		payload,
	};
}
export function impressaoOficioSegundaViaPlacaSuccess(payload: any) {
	return {
		type: Types.IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA_SUCCESS,
		payload,
	};
}
export function impressaoOficioSegundaViaPlacaFailure(payload: []): any {
	return {
		type: Types.IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA_FAILURE,
		payload,
	};
}
