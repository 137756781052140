import React from 'react';

import { Row, Col, Divider } from 'antd';

// REDUX
import EnumCNH from 'store/modules/api/detranCnh/enum';

// COMPONENTS
import Section from 'components/Common/Section';

// FORM
import { IFormStatusCondutor } from '../StatusCondutor/form';

interface IProps {
	consultaRenovacaoCnh: IFormStatusCondutor;
}

const DadosHabilitacao: React.FC<IProps> = ({ consultaRenovacaoCnh }) => {
	return (
		<>
			<Section bgColor="cinza-claro">
				<Row>
					<Col span={24}>
						<h1>Dados da Habilitação</h1>
					</Col>
				</Row>
				<Divider style={{ marginTop: 0, backgroundColor: '#000' }} />
				<Row>
					<Col span={24}>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<strong style={{ opacity: 0.8 }}>
											Informações Pessoais
										</strong>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={8}>
										<Row>
											<Col>
												<p>
													<strong>CPF: </strong>
													<span>{consultaRenovacaoCnh.cpf}</span>
												</p>
											</Col>
										</Row>

										<Row>
											<Col>
												<p>
													<strong>Nome do pai: </strong>
													<span>{consultaRenovacaoCnh.nomePai}</span>
												</p>
											</Col>
										</Row>

										<Row>
											<Col>
												<p>
													<strong>Nome da mãe: </strong>
													<span>{consultaRenovacaoCnh.nomeMae}</span>
												</p>
											</Col>
										</Row>

										<Row>
											<Col>
												<p>
													<strong>Naturalidade: </strong>
													<span>{consultaRenovacaoCnh.naturalidade}</span>
												</p>
											</Col>
										</Row>

										{consultaRenovacaoCnh.servico !== 'segundaVia' &&
											consultaRenovacaoCnh.servico !==
												'permissaoInternacional' &&
											consultaRenovacaoCnh.servico !== 'reabilitacao' &&
											consultaRenovacaoCnh.servico !== 'cnhDefinitiva' &&
											consultaRenovacaoCnh.servico !==
												'permissionarioPontuado' && (
												<Row>
													<Col>
														<p>
															<strong>Cód.Militar: </strong>
															<span />
														</p>
													</Col>
												</Row>
											)}
									</Col>

									<Col span={8}>
										<Row>
											<Col>
												<p>
													<strong>RG: </strong>
													<span>{consultaRenovacaoCnh.numeroRg}</span>
												</p>
											</Col>
										</Row>
									</Col>

									<Col span={8}>
										<Row>
											<Col>
												<p>
													<strong>RNE: </strong>
													<span>{consultaRenovacaoCnh.rne1}</span>
												</p>
											</Col>
										</Row>

										<Row>
											<Col>
												<p>
													<strong>Data de nasc: </strong>
													<span>{consultaRenovacaoCnh.dataNascimento}</span>
												</p>
											</Col>
										</Row>

										<Row>
											<Col>
												<p>
													<strong>Sexo: </strong>
													<span>
														{
															EnumCNH.sexoEnum.filter(
																sexo =>
																	sexo.value === consultaRenovacaoCnh.sexo,
															)[0]?.label
														}
													</span>
												</p>
											</Col>
										</Row>

										<Row>
											<Col>
												<p>
													<strong>Nacionalidade: </strong>
													<span>
														{
															EnumCNH.nacionalidadeEnum.filter(
																nacionalidade =>
																	nacionalidade.value ===
																	consultaRenovacaoCnh.nacionalidade,
															)[0]?.label
														}
													</span>
												</p>
											</Col>
										</Row>

										{consultaRenovacaoCnh.servico !== 'segundaVia' &&
											consultaRenovacaoCnh.servico !==
												'permissaoInternacional' &&
											consultaRenovacaoCnh.servico !== 'reabilitacao' &&
											consultaRenovacaoCnh.servico !== 'cnhDefinitiva' &&
											consultaRenovacaoCnh.servico !==
												'permissionarioPontuado' && (
												<Row>
													<Col>
														<p>
															<strong>Identificação Militar: </strong>
															<span />
														</p>
													</Col>
												</Row>
											)}
									</Col>
								</Row>
							</Col>
						</Row>

						<Row gutter={[0, 20]}>
							<Col span={24} />
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<strong style={{ opacity: 0.8 }}>
											Informações Habilitação
										</strong>
									</Col>
								</Row>

								<Row gutter={[0, 0]}>
									<Col span={24}>
										<p>
											<strong>Uso do formulário: </strong>
											<span>{consultaRenovacaoCnh.usoForm}</span>
										</p>
									</Col>
								</Row>

								<Row gutter={[0, 0]}>
									<Col span={8}>
										<p>
											<strong>Registro nº: </strong>
											<span>{consultaRenovacaoCnh.numeroRegistro}</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>Categoria Pretendida: </strong>
											<span>{consultaRenovacaoCnh.categoriaPretendida}</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>1ª habilitação: </strong>
											<span>
												{consultaRenovacaoCnh.dataPrimeiraHabilitacao}
											</span>
										</p>
									</Col>
								</Row>

								<Row gutter={[0, 0]}>
									<Col span={16}>
										<p>
											<strong>PGU nº: </strong>
											<span>{consultaRenovacaoCnh.numeroPgu}</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>Validade da CNH: </strong>
											<span>{consultaRenovacaoCnh.dataValidadeCnh}</span>
										</p>
									</Col>
								</Row>

								<Row gutter={[0, 0]}>
									<Col span={8}>
										<p>
											<strong>Renach nº: </strong>
											<span>{consultaRenovacaoCnh.renach}</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>Local nº: </strong>
											<span>{consultaRenovacaoCnh.local}</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>Categoria Atual: </strong>
											<span>{consultaRenovacaoCnh.situacaoAtual}</span>
										</p>
									</Col>
								</Row>

								<Row gutter={[0, 0]}>
									<Col span={8}>
										<p>
											<strong>Espelho da CNH nº: </strong>
											<span>{consultaRenovacaoCnh.espelhoCnh}</span>
										</p>
									</Col>
									<Col span={8}>
										<p>
											<strong>CFC-A: </strong>
											<span>{consultaRenovacaoCnh.cfcA}</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>CFC-B: </strong>
											<span>{consultaRenovacaoCnh.cfcB}</span>
										</p>
									</Col>
								</Row>

								<Row gutter={[0, 0]}>
									<Col span={24}>
										<p>
											<strong>Última Emissão da CNH: </strong>
											<span>
												{consultaRenovacaoCnh.dataUltimaEmissaoCnh}
												{consultaRenovacaoCnh.flagAtividadeRemunerada &&
													' - EXERCE ATIV.REM. - CMF'}
											</span>
										</p>
									</Col>
								</Row>

								<Row gutter={[0, 0]}>
									<Col span={8}>
										<p>
											<strong>Exame Médico: </strong>
											<span>
												{consultaRenovacaoCnh.exameMedicoDataExameMedico}
											</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong> Apto para categoria: </strong>
											<span>
												{consultaRenovacaoCnh.exameMedicoCategoriaPermitida}
											</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>Restrições médicas: </strong>
											<span>{consultaRenovacaoCnh?.restricaoMedica || ''}</span>
										</p>
									</Col>
								</Row>

								<Row gutter={[0, 0]}>
									<Col span={8}>
										<p>
											<strong>CRM: </strong>
											<span>{consultaRenovacaoCnh.exameMedicoCrm}</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>CRED: </strong>
											<span>{consultaRenovacaoCnh.exameMedicoCred1}</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>Região: </strong>
											<span>{consultaRenovacaoCnh.exameMedicoRegiao}</span>
										</p>
									</Col>
								</Row>

								<Row gutter={[0, 0]}>
									<Col span={8}>
										<p>
											<strong>Exame Teórico: </strong>
											<span>{consultaRenovacaoCnh.dataExameTeorico}</span>
										</p>
									</Col>

									<Col span={8} />

									<Col span={8}>
										<p>
											<strong>Exame Prático: </strong>
											{consultaRenovacaoCnh.dataExamePratico4Rodas && (
												<span>
													{consultaRenovacaoCnh.dataExamePratico4Rodas} (4
													RODAS)
												</span>
											)}
										</p>
										{consultaRenovacaoCnh.dataExamePratico2Rodas && (
											<p>
												{consultaRenovacaoCnh.dataExamePratico2Rodas} (2 RODAS)
											</p>
										)}
									</Col>
								</Row>

								<Row gutter={[0, 0]}>
									<Col span={8}>
										<p>
											<strong>CRP: </strong>
											<span>{consultaRenovacaoCnh.examePsicotecnicoCrp}</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>CRED 1: </strong>
											<span />
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>CRED 2: </strong>
											<span>{consultaRenovacaoCnh.exameMedicoCred2}</span>
										</p>
									</Col>
								</Row>

								<Row gutter={[0, 0]}>
									<Col span={16}>
										<p>
											<strong>Laudo: </strong>
											<span>{consultaRenovacaoCnh.examePsicotecnicoLaudo}</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>Cód. Região: </strong>
											<span>{consultaRenovacaoCnh.exameMedicoRegiao}</span>
										</p>
									</Col>
								</Row>

								<Row gutter={[0, 0]}>
									<Col span={8}>
										<p>
											<strong>Exame Psicotécnico: </strong>
											<span>
												{
													consultaRenovacaoCnh.examePsicotecnicoDataExamePsicotecnico
												}
											</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>Info Resultado: </strong>
											<span>
												{consultaRenovacaoCnh.examePsicotecnicoResultado2}
											</span>
										</p>
									</Col>

									<Col span={8}>
										<p>
											<strong>Aptidão: </strong>
											<span />
										</p>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={[0, 20]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<strong style={{ opacity: 0.8 }}>Endereço Cadastrado</strong>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={8}>
								<Row>
									<Col>
										<p>
											<strong>CEP: </strong>
											<span>{consultaRenovacaoCnh.enderecoCep}</span>
										</p>
									</Col>
								</Row>

								<Row>
									<Col>
										<p>
											<strong>Número: </strong>
											<span>{consultaRenovacaoCnh.enderecoNumero}</span>
										</p>
									</Col>
								</Row>

								<Row>
									<Col>
										<p>
											<strong>Bairro: </strong>
											<span>{consultaRenovacaoCnh.enderecoBairro}</span>
										</p>
									</Col>
								</Row>
							</Col>

							<Col span={8}>
								<Row>
									<Col>
										<p>
											<strong>Logradouro: </strong>
											<span>{consultaRenovacaoCnh.enderecoLogradouro}</span>
										</p>
									</Col>
								</Row>

								<Row>
									<Col>
										<p>
											<strong>Complemento: </strong>
											<span>{consultaRenovacaoCnh.enderecoComplemento}</span>
										</p>
									</Col>
								</Row>

								<Row>
									<Col>
										<p>
											<strong>Município: </strong>
											<span>
												{consultaRenovacaoCnh.enderecoDescricaoMunicipio}
											</span>
										</p>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Section>
		</>
	);
};

export default DadosHabilitacao;
