import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirFuncionalidadeRequest } from './types';

export function excluirFuncionalidadeRequest(
	data: ExcluirFuncionalidadeRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADE,
		payload: data,
	};
}
export function excluirFuncionalidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADE_SUCCESS,
		payload,
	};
}
export function excluirFuncionalidadeFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADE_FAILURE,
		payload: null,
	};
}
export function excluirFuncionalidadeClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADE_CLEAR,
		payload: null,
	};
}
