import { combineReducers } from 'redux';

import buscaPaginada from './buscaPaginada/reducer';
import consultaConsumidor from './consultaConsumidor/reducer';
import cadastro from './cadastro/reducer';
import atualizacao from './atualizacao/reducer';
import selecionado from './selecionado/reducer';

export default combineReducers({
	buscaPaginada,
	consultaConsumidor,
	cadastro,
	atualizacao,
	selecionado,
});
