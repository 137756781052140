export enum Types {
	CONSULTA_DOCUMENTOS_PRODUZIDOS_REQUEST = '@procon/CONSULTA_DOCUMENTOS_PRODUZIDOS_REQUEST',
	CONSULTA_DOCUMENTOS_PRODUZIDOS_SUCCESS = '@procon/CONSULTA_DOCUMENTOS_PRODUZIDOS_SUCCESS',
	CONSULTA_DOCUMENTOS_PRODUZIDOS_FAILURE = '@procon/CONSULTA_DOCUMENTOS_PRODUZIDOS_FAILURE',
	CONSULTA_DOCUMENTOS_PRODUZIDOS_CLEAR = '@procon/CONSULTA_DOCUMENTOS_PRODUZIDOS_CLEAR',
}

export interface ConsultaDocumentosProduzidos {
	status: null | number;
	data: null | DocumentosProduzidos[];
}

export interface DocumentosProduzidos {
	documentoProduzidoId: string;
	dataDeCriacao: string;
	linkDoDocumento: string;
	tipoDeDocumento: string;
	nomeDoDocumento: string;
}

export interface ConsultaDocumentosProduzidosRequest {
	protocolo: string;
	atendimentoId: string;
}
