import { all } from 'redux-saga/effects';

import cadastrarInscricaoSaga from './cadastrarInscricao/saga';
import comprovanteInscricaoSaga from './comprovanteInscricao/saga';
import consultarInscricaoSaga from './consultarInscricao/saga';
import removerInscricaoSaga from './removerInscricao/saga';

export default all([
	cadastrarInscricaoSaga,
	comprovanteInscricaoSaga,
	consultarInscricaoSaga,
	removerInscricaoSaga,
]);
