import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirSiglaRequest } from './types';

export function excluirSiglaRequest(data: ExcluirSiglaRequest): ReducerAction {
	return {
		type: Types.EXCLUIR_SIGLA_REQUEST,
		payload: data,
	};
}
export function excluirSiglaSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_SIGLA_SUCCESS,
		payload,
	};
}
export function excluirSiglaFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_SIGLA_FAILURE,
		payload: null,
	};
}
export function excluirSiglaClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_SIGLA_CLEAR,
		payload: null,
	};
}
