import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL_DETRAN, PATH_CONTABILIZA } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, EventoTerminoSessaoRequest } from './types';

// ACTIONS
import {
	eventoTerminoSessaoSuccess,
	eventoTerminoSessaoFailure,
} from './actions';

function* eventoTerminoSessao(request: ReducerAction) {
	const { payload }: { payload: EventoTerminoSessaoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_CONTABILIZA,
		`evento/${CHANNEL_DETRAN}/termino`,
		payload,
		undefined,
		true,
	);

	if (response.status === 201) {
		yield put(eventoTerminoSessaoSuccess(response));
	} else {
		yield put(eventoTerminoSessaoFailure(response));
	}
}

export default all([
	takeLatest(Types.EVENTO_TERMINO_SESSAO_REQUEST, eventoTerminoSessao),
]);
