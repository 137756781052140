import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { PATH_CONTABILIZA } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { Types, EventoTerminoRequest } from './types';
import { eventoTerminoSuccess, eventoTerminoFailure } from './actions';

function* eventoInicioRequest(request: ReducerAction) {
	const { payload }: { payload: EventoTerminoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_CONTABILIZA,
		`evento/mp/termino`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(eventoTerminoSuccess(response));
	} else {
		yield put(eventoTerminoFailure(response));
	}
}

export default all([
	takeLatest(Types.EVENTO_TERMINO_REQUEST, eventoInicioRequest),
]);
