export enum Types {
	ENVIAR_SMS_POSITUS_REQUEST = '@cnh/ENVIAR_SMS_POSITUS_REQUEST',
	ENVIAR_SMS_POSITUS_SUCCESS = '@cnh/ENVIAR_SMS_POSITUS_SUCCESS',
	ENVIAR_SMS_POSITUS_FAILURE = '@cnh/ENVIAR_SMS_POSITUS_FAILURE',
	ENVIAR_SMS_POSITUS_CLEAR = '@cnh/ENVIAR_SMS_POSITUS_CLEAR',
}

export interface enviaSMSPositus {
	status: number;
	data: EnviaSMSPositusData | null;
}

export interface EnviaSMSPositusData {
	tokenId: string;
}

export interface RequestEnviaSMSPositus {
	codigoPais: string;
	ddd: string;
	numero: string;
	mensagem: string;
}
