import produce from 'immer';

import { ReducerAction } from 'store/modules/types';

import { Types, CadastroAutorizacaoEspecial } from './types';

export const INITIAL_STATE: CadastroAutorizacaoEspecial = {
	status: 0,
	data: null,
	request: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): CadastroAutorizacaoEspecial {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CADASTRO_AUTORIZACAO_ESPECIAL_REQUEST: {
				break;
			}
			case Types.CADASTRO_AUTORIZACAO_ESPECIAL_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.response.data;
				draft.request = action.payload.request;
				break;
			}
			case Types.CADASTRO_AUTORIZACAO_ESPECIAL_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CADASTRO_AUTORIZACAO_ESPECIAL_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
