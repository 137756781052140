import { all } from 'redux-saga/effects';

// RENOVAÇÃO
import renovacaoCnh from './renovacao/combineSaga';

// SEGUNDA VIA
import segundaVia from './segundaVia/combineSaga';

// CNH DEFINITIVA
import cnhDefinitiva from './cnhDefinitiva/combineSaga';

// PRIMEIRA HABILITAÇÃO
import primeiraHabilitacao from './primeiraHabilitacao/combineSaga';

// PERMISSIONÁRIO PONTUADO
import permissionarioPontuado from './permissionarioPontuado/combineSaga';

// ADICAO CATEGORIA
import adicaoCategoria from './adicaoCategoria/combineSaga';

// MUDANÇA CATEGORIA
import mudancaCategoria from './mudancaCategoria/combineSaga';

// PERMISSAO INTERNACIONAL - PID
import permissaoInternacional from './permissaoInternacional/combineSaga';

// PERMISSAO INTERNACIONAL AUTOMATIZADO - PID
import permissaoInternacionalAutomatizado from './permissaoInternacionalAutomatizado/combineSaga';

// REABILITAÇÃO
import reabilitacao from './reabilitacao/combineSaga';

// CONDUTOR - ENDEREÇOS
import condutor from './condutor/combineSaga';

// DIVISÃO EQUITATIVA
import divisaoEquitativa from './divisaoEquitativa/combineSaga';

// CNH DIGITAL
import cnhDigital from './cnhDigital/combineSaga';

// TRANSFERÊNCIA MUNICIPAL
import transferenciaMunicipal from './transferenciaMunicipal/combineSaga';

// ENUM
import enumServices from './enumServices/combineSaga';

// BIOMETRIA
import biometria from './biometria/combineSaga';

// DADOS DOMÍNIO
import dadosDominio from './dadosDominio/combineSaga';

// AGENDAMENTO MÉDICO E PSICOLÓGICO
import agendamentoMedicoPsicologico from './agendamentoMedicoPsicologico/combineSaga';

// IMPRIMIR
import imprimir from './imprimir/combineSaga';

// CONSULTA
import consulta from './consulta/combineSaga';

// SOCKETS
import sockets from './sockets/combineSaga';

// PORTAL DETRAN
import portalDetran from './portalDetran/combineSaga';

// EPORTAL
import eportal from './eportal/combineSaga';

// POSTO ATENDIMENTO
import postoAtendimento from './postoAtendimento/combineSaga';

// RELATORIO
import relatorio from './relatorio/combineSaga';

// IIRGDDetran
import IIRGDDetran from './IIRGDDetran/combineSaga';

// CIDADAO
import cidadao from './cidadao/combineSaga';

// EVENTO
import evento from './evento/combineSaga';

// RENACH INTEGRADO
import renachIntegrado from './renachIntegrado/combineSaga';

// UTILS
import utils from './utils/combineSaga';

// ALTERAÇÃO ENDEREÇO
import alteracaoEndereco from './alteracaoEndereco/combineSaga';

// CONSULTAR SOLICITAÇÃO EMISSÃO PID
import solicitacaoEmissaoPID from './solicitacaoEmissaoPID/combineSaga';

// NOME SOCIAL
import nomeSocial from './nomeSocial/combineSaga';

export default all([
	renovacaoCnh,
	cnhDefinitiva,
	segundaVia,
	primeiraHabilitacao,
	permissionarioPontuado,
	adicaoCategoria,
	mudancaCategoria,
	permissaoInternacionalAutomatizado,
	permissaoInternacional,
	reabilitacao,
	divisaoEquitativa,
	condutor,
	cnhDigital,
	transferenciaMunicipal,
	enumServices,
	biometria,
	dadosDominio,
	agendamentoMedicoPsicologico,
	imprimir,
	consulta,
	sockets,
	portalDetran,
	eportal,
	postoAtendimento,
	relatorio,
	IIRGDDetran,
	cidadao,
	evento,
	renachIntegrado,
	utils,
	alteracaoEndereco,
	solicitacaoEmissaoPID,
	nomeSocial,
]);
