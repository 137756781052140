import styled from 'styled-components';

export const ContainerFichaCadastral = styled.div`
	.ant-col {
		div {
		label {
			max-width: 140px;
			text-align: right;
			line-height: 1;
		}
		.ant-checkbox-wrapper {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			top: 0;
			left: 6px;
			width: 140px;
		}
	}
	.ant-checkbox-wrapper {
	}
`;

export const ButtonBox = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
`;

export const Container = styled.div`
	display: flex;
	width: 100%;
`;

export const Latitude = styled.div`
	width: 300px;
`;

export const Longitude = styled.div`
	width: 170px;
`;

export const DividerBarra = styled.div`
	margin: auto 3px;

	::before {
		content: '/';
	}
`;

export const Cidade = styled.div`
	width: 100%;
`;

export const Uf = styled.div`
	width: 40px;
`;

export const Divider = styled.div`
	margin: auto 3px;

	::before {
		content: '-';
	}
`;

export const EnderecoRua = styled.div`
	width: 100%;
`;

export const Numero = styled.div`
	width: 80px;
`;
