export enum Types {
	COMBO_UF_REQUEST = '@EDUCACAO/COMBO_UF_REQUEST',
	COMBO_UF_SUCCESS = '@EDUCACAO/COMBO_UF_SUCCESS',
	COMBO_UF_FAILURE = '@EDUCACAO/COMBO_UF_FAILURE',
}

export interface ComboUf {
	status: number;
	data: ComboUfResponse | null;
}

interface ComboUfResponse {
	processoId: string;
	ufs: EnumUF[];
}

export interface EnumUF {
	codigo: string;
	descricao: string;
}
