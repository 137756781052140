// TYPES
import { ReducerAction } from 'store/modules/types';
import { BuscaPaginadaRequest, Types } from './types';

export function buscaPaginadaRequest(
	payload: BuscaPaginadaRequest,
): ReducerAction {
	return {
		type: Types.BUSCA_PAGINADA_FORNECEDORES_REQUEST,
		payload,
	};
}
export function buscaPaginadaSuccess(payload: object): ReducerAction {
	return {
		type: Types.BUSCA_PAGINADA_FORNECEDORES_SUCCESS,
		payload,
	};
}
export function buscaPaginadaFailure(payload: object): ReducerAction {
	return {
		type: Types.BUSCA_PAGINADA_FORNECEDORES_FAILURE,
		payload,
	};
}
export function buscaPaginadaClear(): ReducerAction {
	return {
		type: Types.BUSCA_PAGINADA_FORNECEDORES_CLEAR,
		payload: null,
	};
}
