import produce from 'immer';

// UTILS
import { toSelect } from 'utils/genericFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, FormaAquisicao } from './types';

export const INITIAL_STATE: FormaAquisicao = {
	status: 0,
	data: [],
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): FormaAquisicao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.FORMA_AQUISICAO_REQUEST: {
				break;
			}
			case Types.FORMA_AQUISICAO_SUCCESS: {
				draft.status = action.payload.status;

				if (action.payload.data.dominio) {
					const select = toSelect(
						action.payload.data.dominio,
						'descricao',
						'codigo',
					);
					draft.data = select;
				} else {
					draft.data = [];
				}
				break;
			}
			case Types.FORMA_AQUISICAO_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.FORMA_AQUISICAO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
