import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	HORARIOS_PSICOLOGO_POR_DATA_REQUEST = '@cnh/HORARIOS_PSICOLOGO_POR_DATA_REQUEST',
	HORARIOS_PSICOLOGO_POR_DATA_SUCCESS = '@cnh/HORARIOS_PSICOLOGO_POR_DATA_SUCCESS',
	HORARIOS_PSICOLOGO_POR_DATA_FAILURE = '@cnh/HORARIOS_PSICOLOGO_POR_DATA_FAILURE',
	HORARIOS_PSICOLOGO_POR_DATA_CLEAR = '@cnh/HORARIOS_PSICOLOGO_POR_DATA_CLEAR',
}

export interface horariosPsicologoPorData {
	status: number;
	data: IDadosCnhDefinitiva | null;
}

interface IDadosCnhDefinitiva {
	enum: OptionProps[] | [];
	idPsicologo: number;
}

export interface IRequestHorariosPsicologoPorData {
	data_exame_psicologico: string;
	id_psicologo: number;
	existe_agendamento_exame_medico: boolean;
}
