const IP = window.location.host;
let SERVER = 'http://poupatempo4g.des.sp.gov.br';
let ambiente = 'DEV';

switch (IP) {
	/* DEV - LOCAL */
	case 'localhost:3000':
		SERVER = 'http://balcaoapi.des.sp.gov.br';
		ambiente = 'LOCAL';
		break;

	// MINHA MAQUINA VIRTUAL - GUILHERME
	// case '10.32.37.36:3000':
	// 	SERVER = 'http://homologa.poupatempo4g.sp.gov.br';
	// 	ambiente = 'LOCAL';
	// 	break;

	/* DEV - AMBIENTE */
	case 'http://10.200.240.21:5000':
		SERVER = 'http://poupatempo4g.des.sp.gov.br';
		ambiente = 'DEV';
		break;

	/* HOMOLOGAÇÃO */
	case 'HOMOLOGACAO':
		SERVER = 'http://homologa.poupatempo4g.sp.gov.br/';
		ambiente = 'HOMOLOG';
		break;

	/* PRODUÇÃO */
	case 'PRODUCAO':
		SERVER = 'http://www.poupatempo4g.sp.gov.br/';
		ambiente = 'PROD';
		break;

	default:
		break;
}

export const PATH = SERVER;
export const ENVIRONMENT = ambiente;

export const ServiceURLs = [
	'ppt4g-rest-sgu-service',
	'',
	'',
	'',
	'',
	'ppt4g-rest-detran-crv',
];
