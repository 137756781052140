export enum Types {
	CONFRONTAR_DIGITAIS_REQUEST = '@utils/CONFRONTAR_DIGITAIS_REQUEST',
	CONFRONTAR_DIGITAIS_SUCCESS = '@utils/CONFRONTAR_DIGITAIS_SUCCESS',
	CONFRONTAR_DIGITAIS_FAILURE = '@utils/CONFRONTAR_DIGITAIS_FAILURE',
	CONFRONTAR_DIGITAIS_CLEAR = '@utils/CONFRONTAR_DIGITAIS_CLEAR',
}

export interface IConfrontarDigitaisState {
	qtdeFalha: number;
	resultado: boolean;
	posicaoDedo: null | string;
}
