export interface IBuscarCepResultadoEndLogradouro {
	cep: string;
	bairro: string;
	tipoLogradouro: string;
	endereco: string;
	uf: string;
	municipio: string;
	numeroIBGE: number;
	codigoMunicipio: number;
	codigoUnidadeTransito: number;
}

export interface ConsultaCEP {
	data?: IBuscarCepResultadoEndLogradouro;
	status: number;
}

export const initialResultValuesEndLogradouro: IBuscarCepResultadoEndLogradouro =
	{
		cep: '',
		bairro: '',
		tipoLogradouro: '',
		endereco: '',
		uf: '',
		municipio: '',
		numeroIBGE: 0,
		codigoMunicipio: 0,
		codigoUnidadeTransito: 0,
	};
