// TYPES
import { ReducerAction } from 'store/modules/types';
import { MunicipiosRequest, Types } from './types';

export function municipiosRequest(payload: MunicipiosRequest): ReducerAction {
	return {
		type: Types.MUNICIPIOS_REQUEST,
		payload,
	};
}
export function municipiosSuccess(payload: object): ReducerAction {
	return {
		type: Types.MUNICIPIOS_SUCCESS,
		payload,
	};
}
export function municipiosFailure(payload: object): ReducerAction {
	return {
		type: Types.MUNICIPIOS_FAILURE,
		payload,
	};
}
export function municipiosClear(): ReducerAction {
	return {
		type: Types.MUNICIPIOS_CLEAR,
		payload: null,
	};
}
