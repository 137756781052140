import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_BOLSA_POVO } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, Tabelas } from './types';

// ACTIONS
import { getCpfPermitidoSuccess, getCpfPermitidoFailure } from './action';

function* getCpfPermitido(request: ReducerAction) {
	const { payload }: { payload: Tabelas } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_BOLSA_POVO,
		`bolsa-povo/${CHANNEL}/cpf-permitido`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(getCpfPermitidoSuccess(response.data.tabelas[0]));
	} else if (response.error) {
		yield put(getCpfPermitidoFailure());
	}
}

export default all([
	takeLatest(Types.GET_CPF_PERMITIDO_REQUEST, getCpfPermitido),
]);
