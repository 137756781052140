export enum Types {
	CADASTRAR_MENSAGEM_ERRO_REQUEST = '@SGU/CADASTRAR_MENSAGEM_ERRO_REQUEST',
	CADASTRAR_MENSAGEM_ERRO_SUCCESS = '@SGU/CADASTRAR_MENSAGEM_ERRO_SUCCESS',
	CADASTRAR_MENSAGEM_ERRO_FAILURE = '@SGU/CADASTRAR_MENSAGEM_ERRO_FAILURE',
	CADASTRAR_MENSAGEM_ERRO_CLEAR = '@SGU/CADASTRAR_MENSAGEM_ERRO_CLEAR',
}

export interface CadastrarMensagemErro {
	status: number;
	data: null | MensagemErroData;
}

interface MensagemErroData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarMensagemErroRequest {
	id?: number | string;
	descricao: string;
}
