import { RequestAlterarObservacoes } from 'store/modules/api/iirgd/alterarObservacoes/types';
// eslint-disable-next-line import/no-cycle
import { ICadastroRgState } from 'store/modules/api/iirgd/cadastroRg/types';
import { IConsultaPesquisaObservacao } from 'store/modules/api/iirgd/consultaPesquisaObservacao/types';
import { ILogin } from 'store/modules/api/sgu/loginUnico/types';
import {
	calcularIdade,
	limparMascara,
	unformatDate,
} from 'utils/genericFunctions';
import { removeSpecialCharsBreakLineAndNormalizeString } from 'utils/stringFunctions';

interface commonData {
	observacao?: string;
	rgForaSp: boolean | string;
	rgForaSpEmissao: string;
	rgForaSpNum: string;
	rgForaSpUf: string;
	isencaoPrimeiraViaCin?: boolean;
}

export interface IFormInclusaoObsPrimeiraViaMaior extends commonData {
	cr: boolean;
	grauParentescoSp?: string;
	moraCom?: string;
	ocupacaoPrincipalSp?: string;
	resideSpAnos?: string;
	resideSpMeses?: string | number | null;
	temParentesSp?: boolean | string;
}

export interface IFormInclusaoObsPrimeiraViaMenor extends commonData {
	dataExpedicaoDocumentoResponsavel?: string;
	numeroDocumentoResponsavel?: string;
	tipoDocumentoResponsavel?: string;
	ufDocumentoResponsavel?: string;
	serie?: string;
	termoTutela: boolean | string;
	tutelaProc: string;
	tutelaAno: string;
	tutelaData: string;
}

export interface IFormInclusaoObsSegundaVia
	extends IFormInclusaoObsPrimeiraViaMenor {
	dataNascimento?: string;
	sexo?: string;
	cr: boolean;
	cptsNum: string;
	ctpsSerie: string;
	ctpsSerieUf: string;
	ctpsObs: string;
	srObs: string;
	estObs: string;
	declaracaoPobreza: boolean | string;
	isento2Via062: boolean;
	isento2Via10952: boolean;
	rgRecolhidoEmissao: string;
	rgRecolhidoIdentific: string;
	rgRecolhidoPor: string;
	solicitarBloqueio: boolean | string;
	termoRecolhimentoRg: boolean | string;
	lote: string;
	grauParentescoSp: string;
	moraCom: string;
	resideSpAnos: string;
	resideSpMeses: string;
	usuario: string;
	ocupacaoPrincipalSp: string;
	dataExpedicaoDocumentoResponsavel?: string;
	numeroDocumentoResponsavel?: string;
	tipoDocumentoResponsavel?: string;
	ufDocumentoResponsavel?: string;
}

export interface IRequestAlterarObservacoes extends IFormInclusaoObsSegundaVia {
	carteiraFuncional: string;
	classeProfissional: string;
	cnh: string;
	ctpsObs: string;
	srObs: string;
	estObs: string;
	ctpsSerieUf: string;
	grauParentescoSp: string;
	moraCom: string;
	numeroRg: string;
	pagto2Via: string;
	ocupacaoPrincipalSp: string;
	reservista: string;
	resideSpAnos: string;
	resideSpMeses: string;
	tituloEleitor: string;
	usuario: string;
	senha: string;
	serie?: string;
}

export function threatDataCadastroRgObsPrimeiraViaMaior(
	formValues: IConsultaPesquisaObservacao,
): IFormInclusaoObsPrimeiraViaMaior {
	return {
		...formValues,
		observacao: formValues.observacao
			? removeSpecialCharsBreakLineAndNormalizeString(formValues.observacao)
			: '',
		grauParentescoSp: formValues.grauParentescoSp || '',
	};
}

export function threatDataCadastroRgObsPrimeiraViaMenor(
	formValues: IConsultaPesquisaObservacao,
): IFormInclusaoObsPrimeiraViaMenor {
	return {
		...formValues,
		observacao: formValues.observacao
			? removeSpecialCharsBreakLineAndNormalizeString(formValues.observacao)
			: '',
	};
}

export function threatDataCadastroRgSegundaVia(
	formValues: IConsultaPesquisaObservacao,
): IConsultaPesquisaObservacao {
	return {
		...formValues,
		observacao: formValues.observacao
			? removeSpecialCharsBreakLineAndNormalizeString(formValues.observacao)
			: '',
	};
}

export function getObsValueRequest(
	formValues: IConsultaPesquisaObservacao,
	cadastroRG: ICadastroRgState,
	login: ILogin,
	lote: string,
	isencaoPrimeiraViaCin: boolean,
	temCin?: boolean,
): RequestAlterarObservacoes {
	const numeroDocumentoResponsavel = () => {
		if (formValues.tipoDocumentoResponsavel === 'CTPS') {
			return `${formValues.numeroDocumentoResponsavel}${formValues.serie}`;
		}

		if (
			formValues.tipoDocumentoResponsavel === 'RG' &&
			formValues.ufDocumentoResponsavel === 'SP'
		) {
			const newRg = limparMascara(formValues.numeroDocumentoResponsavel);
			if (newRg.length === 10) {
				return `${newRg.slice(0, -2)}-${newRg[newRg.length - 2]}`;
			}
			return `${newRg.slice(0, -1)}-${newRg[newRg.length - 1]}`;
		}
		return limparMascara(formValues.numeroDocumentoResponsavel);
	};
	const body: RequestAlterarObservacoes = {
		numeroRg: cadastroRG.form.principal?.numeroRg ?? '',
		lote,
		rgForaSp: formValues.rgForaSp ? formValues.rgForaSp : '',
		declaracaoPobreza: !!formValues.declaracaoPobreza,
		solicitarBloqueio: !!formValues.solicitarBloqueio,
		termoRecolhimentoRg: !!formValues.termoRecolhimentoRg,
		rgRecolhidoEmissao: formValues.rgRecolhidoEmissao
			? unformatDate(formValues.rgRecolhidoEmissao)
			: '',
		rgRecolhidoIdentific: formValues.rgRecolhidoIdentific
			? formValues.rgRecolhidoIdentific
			: '',
		rgRecolhidoPor: formValues.rgRecolhidoPor ? formValues.rgRecolhidoPor : '',
		pagto2Via: formValues.pagto2Via ? '' : '',
		isento2Via062: !!formValues.isento2Via062,
		isento2Via10952: !!formValues.isento2Via10952,
		ctpsObs: formValues.ctpsObs ? formValues.ctpsObs : '',
		srObs: formValues.srObs ? formValues.srObs : '',
		estObs: formValues.estObs ? formValues.estObs : '',
		termoTutela: !!formValues.termoTutela,
		tutelaProc: formValues.tutelaProc ? formValues.tutelaProc : '',
		tutelaAno: formValues.tutelaAno ? formValues.tutelaAno : '',
		tutelaData: formValues.tutelaData
			? unformatDate(formValues.tutelaData)
			: '',
		observacao: formValues.observacao
			? removeSpecialCharsBreakLineAndNormalizeString(formValues.observacao)
			: '',
		resideSpAnos: formValues.resideSpAnos ?? '',
		resideSpMeses: formValues.resideSpMeses ?? '',
		moraCom: formValues.moraCom ?? '',
		temParentesSp: formValues.temParentesSp ?? '',
		grauParentescoSp: formValues.grauParentescoSp ?? '',
		ocupacaoPrincipalSp: formValues.ocupacaoPrincipalSp ?? '',
		rgForaSpUf: formValues.rgForaSpUf ?? '',
		rgForaSpNum: formValues.rgForaSpNum ?? '',
		rgForaSpEmissao: formValues.rgForaSpEmissao
			? unformatDate(formValues.rgForaSpEmissao)
			: '',
		cptsNum: formValues.ctpsNum ?? '',
		ctpsSerie: formValues.ctpsSerie ?? '',
		ctpsSerieUf: formValues.ctpsSerieUf ?? '',
		cnh: formValues.cnh ?? '',
		tituloEleitor: formValues.tituloEleitor ?? '',
		reservista: formValues.reservista ?? '',
		classeProfissional: formValues.classeProfissional ?? '',
		carteiraFuncional: formValues.carteiraFuncional ?? '',
		usuario: login.mainframe.idUsuario,
		senha: login.mainframe.senhaMainFrame,
		cr: !!formValues.cr,
		tipoDocumentoResponsavel: formValues.tipoDocumentoResponsavel,
		numeroDocumentoResponsavel: numeroDocumentoResponsavel(),
		ufDocumentoResponsavel: formValues.ufDocumentoResponsavel ?? '',
		dataExpedicaoDocumentoResponsavel:
			formValues.dataExpedicaoDocumentoResponsavel
				? unformatDate(formValues.dataExpedicaoDocumentoResponsavel)
				: '',
		inscricaoCpf: cadastroRG.form.principal?.inscricaoCpf === 'true',
		cpfAtendente: login.user.cpf,
		entrega: cadastroRG.form.principal?.entrega,
		isencaoPrimeiraViaCin,
		temCin,
	};

	if (
		cadastroRG.form.principal?.dataNascimento &&
		calcularIdade(cadastroRG.form.principal?.dataNascimento) > 16
	) {
		if (formValues.dataExpedicaoDocumentoResponsavel)
			body.dataExpedicaoDocumentoResponsavel = '';
		if (formValues.ufDocumentoResponsavel) body.ufDocumentoResponsavel = '';
		if (formValues.tipoDocumentoResponsavel) body.tipoDocumentoResponsavel = '';
		if (formValues.numeroDocumentoResponsavel)
			body.numeroDocumentoResponsavel = '';
	}

	return body;
}
