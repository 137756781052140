import { Types } from './types';

export function dadosBasicosRequest(token: string) {
	return {
		type: Types.DADOS_BASICOS_REQUEST,
		token,
	};
}

export function dadosBasicosSuccess(payload: object) {
	return {
		type: Types.DADOS_BASICOS_SUCCESS,
		payload,
	};
}

export function dadosBasicosFailure(payload: boolean) {
	return {
		type: Types.DADOS_BASICOS_FAILURE,
		payload,
	};
}
