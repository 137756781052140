export enum Types {
	DADOS_CIDADAO_REQUEST = '@cnh/DADOS_CIDADAO_REQUEST',
	DADOS_CIDADAO_SUCCESS = '@cnh/DADOS_CIDADAO_SUCCESS',
	DADOS_CIDADAO_FAILURE = '@cnh/DADOS_CIDADAO_FAILURE',
	DADOS_CIDADAO_CLEAR = '@cnh/DADOS_CIDADAO_CLEAR',
}

export interface DadosCidadao {
	status: number;
	data: DadosCidadaoData | null;
	requestData: DadosCidadaoRequest | null;
}

interface DadosCidadaoData {
	codigo: number;
}

interface Evento {
	tipo: string;
	id_servico: number;
	desc_servico: string;
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	id_orgao: number;
	desc_orgao: string;
	canal: {
		id: number;
		desc: string;
		estacao: {
			id: number;
			desc: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
	atendente: {
		nome: string;
		id_atendente: string;
		id_posto: string;
		desc_posto: string;
	};
}
export interface DadosCidadaoRequest {
	evento?: Evento;
	cidadao: {
		autoriza_envio_email: boolean;
		autoriza_envio_sms: boolean;
		celular: string;
		cpf: number;
		ddd_celular: string;
		ddd_telefone: string;
		email: string;
		necessidade_veiculo_adaptado?: boolean;
		nome: string;
		pretende_atividade_remunerada: boolean;
		telefone: string;
	};
}
