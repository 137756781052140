import { ApiResponse } from 'services/_api';
import { ReducerAction } from 'store/modules/types';

import { PreCadastroCidadaoRequest, Types } from './types';

export function preCadastroCidadaoRequest(
	data: PreCadastroCidadaoRequest,
): ReducerAction {
	return {
		type: Types.PRE_CADASTRO_CIDADAO_REQUEST,
		payload: data,
	};
}
export function preCadastroCidadaoSuccess(payload: ApiResponse): ReducerAction {
	return {
		type: Types.PRE_CADASTRO_CIDADAO_SUCCESS,
		payload,
	};
}
export function preCadastroCidadaoFailure(payload: string): ReducerAction {
	return {
		type: Types.PRE_CADASTRO_CIDADAO_FAILURE,
		payload,
	};
}
export function preCadastroCidadaoClear(): { type: string } {
	return {
		type: Types.PRE_CADASTRO_CIDADAO_CLEAR,
	};
}
