import { ConsultarIntencaoVendaRequest } from 'store/modules/api/detranCrv/IntencaoDeVenda/Consultar/types';
import { IEvento } from 'store/modules/api/detranCrv/types';
import * as Yup from 'yup';

interface ILocalidade {
	id: number;
	desc: string;
}

export const initialValues: ConsultarIntencaoVendaRequest = {
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
		identificacao: '',
		ip: '',
		canal: {
			id: 0,
			desc: '',
			localidade: {
				id: 0,
				desc: '',
			},
		},
	},
	tipoConsultaAtpve: '',
	placa: '',
	renavam: '',
	numeroATPVE: '',
	dataInicioConsulta: '',
	horaInicioConsulta: '',
	dataFimConsulta: '',
	horaFimConsulta: '',
	codigoEstadoIntencaoVenda: '',
};

const formatDate = (dateString: string) => {
	const dateArray = dateString.split('/');
	const formatedDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
	return formatedDate;
};

const formatTime = (timeString: string) => {
	const timeArray = timeString.split(':');
	const formatedTime = `${timeArray[0]}:${timeArray[1]}:${timeArray[2]}`;
	return formatedTime;
};

export function treatValues(
	evento: IEvento,
	values: ConsultarIntencaoVendaRequest,
): ConsultarIntencaoVendaRequest {
	const formatedEvento = {
		id_atendimento: evento.id_atendimento,
		id_cidadao: evento.id_cidadao,
		ip: evento.ip,
		identificacao: evento.identificacao,
		cpf: evento.cpf || '',
		canal: {
			id: 1,
			desc: 'Presencial',
			localidade: {
				desc: evento.canal?.localidade.desc || '',
				id: evento.canal?.localidade.id || 0,
			},
		},
	};

	if (
		values.codigoEstadoIntencaoVenda !== '0' &&
		values.numeroATPVE === '' &&
		values.horaFimConsulta === '' &&
		values.dataFimConsulta === '' &&
		values.dataInicioConsulta === '' &&
		values.horaInicioConsulta === ''
	) {
		values.tipoConsultaAtpve = 'INTENCAO_VENDA_T1';
	} else if (
		values.numeroATPVE === '' &&
		values.dataInicioConsulta !== '' &&
		values.horaInicioConsulta !== '' &&
		values.dataFimConsulta !== '' &&
		values.horaFimConsulta !== '' &&
		values.codigoEstadoIntencaoVenda !== '0'
	) {
		values.tipoConsultaAtpve = 'INTENCAO_VENDA_T1_COM_DATA';
	} else if (
		values.numeroATPVE !== '' &&
		values.codigoEstadoIntencaoVenda === '0' &&
		values.dataInicioConsulta === '' &&
		values.horaInicioConsulta === '' &&
		values.dataFimConsulta === '' &&
		values.horaFimConsulta === ''
	) {
		values.tipoConsultaAtpve = 'INTENCAO_VENDA_T2';
	} else if (
		values.numeroATPVE !== '' &&
		values.codigoEstadoIntencaoVenda !== '0' &&
		values.dataInicioConsulta === '' &&
		values.horaInicioConsulta === '' &&
		values.dataFimConsulta === '' &&
		values.horaFimConsulta === ''
	) {
		values.tipoConsultaAtpve = 'INTENCAO_VENDA_T1_COM_ATPVE';
	} else if (
		values.renavam !== '' &&
		values.placa !== '' &&
		values.codigoEstadoIntencaoVenda === '0' &&
		values.dataInicioConsulta === '' &&
		values.horaInicioConsulta === '' &&
		values.dataFimConsulta === '' &&
		values.horaFimConsulta === '' &&
		values.numeroATPVE === ''
	) {
		values.tipoConsultaAtpve = 'CRV_ELETRONICO';
	} else if (
		values.placa !== '' &&
		values.renavam !== '' &&
		values.codigoEstadoIntencaoVenda !== '0' &&
		values.dataInicioConsulta !== '' &&
		values.horaInicioConsulta !== '' &&
		values.dataFimConsulta !== '' &&
		values.horaFimConsulta !== '' &&
		values.numeroATPVE !== ''
	) {
		values.tipoConsultaAtpve = 'INTENCAO_VENDA_T1_COM_DATA_ATPVE';
	} else {
		values.tipoConsultaAtpve = 'CRV_ELETRONICO';
	}

	const formattedValues = {
		evento: formatedEvento,
		tipoConsultaAtpve: values.tipoConsultaAtpve,
		placa: values.placa,
		renavam: values.renavam,
		numeroATPVE: values.numeroATPVE,
		dataInicioConsulta:
			values.dataInicioConsulta && formatDate(values.dataInicioConsulta),
		horaInicioConsulta:
			values.horaInicioConsulta && formatTime(values.horaInicioConsulta),
		dataFimConsulta:
			values.dataFimConsulta && formatDate(values.dataFimConsulta),
		horaFimConsulta:
			values.horaFimConsulta && formatTime(values.horaFimConsulta),
		codigoEstadoIntencaoVenda:
			values.codigoEstadoIntencaoVenda === ''
				? '0'
				: values.codigoEstadoIntencaoVenda,
	};

	return formattedValues;
}

export const schema = Yup.object<ConsultarIntencaoVendaRequest>().shape(
	{
		placa: Yup.string().required(`Campo Obrigatório: Placa.`),
		renavam: Yup.string().required(`Campo Obrigatório: Renavam.`),
		dataInicioConsulta: Yup.string()
			.when(['dataFimConsulta', 'horaInicioConsulta', 'horaFimConsulta'], {
				is: (dataFimConsulta, horaInicioConsulta, horaFimConsulta) =>
					dataFimConsulta || horaInicioConsulta || horaFimConsulta,
				then: Yup.string().required(`Campo Obrigatório: Data Início.`),
			})
			.nullable(),
		dataFimConsulta: Yup.string()
			.when(['dataInicioConsulta', 'horaInicioConsulta', 'horaFimConsulta'], {
				is: (dataInicioConsulta, horaInicioConsulta, horaFimConsulta) =>
					dataInicioConsulta || horaInicioConsulta || horaFimConsulta,
				then: Yup.string().required(`Campo Obrigatório: Data Fim.`),
			})
			.nullable(),
		horaInicioConsulta: Yup.string().when(
			['dataFimConsulta', 'dataInicioConsulta', 'horaFimConsulta'],
			{
				is: (dataFimConsulta, dataInicioConsulta, horaFimConsulta) =>
					dataFimConsulta || dataInicioConsulta || horaFimConsulta,
				then: Yup.string().required(`Campo Obrigatório: Hora Início.`),
			},
		),
		horaFimConsulta: Yup.string().when(
			['dataFimConsulta', 'horaInicioConsulta', 'dataInicioConsulta'],
			{
				is: (dataFimConsulta, horaInicioConsulta, dataInicioConsulta) =>
					dataFimConsulta || horaInicioConsulta || dataInicioConsulta,
				then: Yup.string().required(`Campo Obrigatório: Hora Fim.`),
			},
		),
	},
	[
		['dataInicioConsulta', 'dataFimConsulta'],
		['dataInicioConsulta', 'horaInicioConsulta'],
		['dataInicioConsulta', 'horaFimConsulta'],
		['dataFimConsulta', 'horaInicioConsulta'],
		['dataFimConsulta', 'horaFimConsulta'],
		['horaInicioConsulta', 'horaFimConsulta'],
	],
);
