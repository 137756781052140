export enum Types {
	CONSULTA_REABILITACAO = '@cnh/CONSULTA_REABILITACAO_T',
	CONSULTA_REABILITACAO_SUCCESS = '@cnh/CONSULTA_REABILITACAO_SUCCESS_T',
	CONSULTA_REABILITACAO_FAILURE = '@cnh/CONSULTA_REABILITACAO_FAILURE_T',
	CONSULTA_REABILITACAO_CLEAR = '@cnh/CONSULTA_REABILITACAO_CLEAR_T',
}

export interface consultaReabilitacao {
	status: number;
	data: ReabilitacaoCnhData | null;
	dataError: ReabilitacaoCnhDataError | null;
	requestData: ConsultaReabilitacaoRequest | null;
}

export interface ReabilitacaoCnhData {
	cassado: string;
	cpf: string;
	crimetran: string;
	data: string;
	dataCassadoFinal: string;
	dataCassadoInicial: string;
	dataCrimeFinal: string;
	dataCrimeInicial: string;
	dataSuspensoFinal: string;
	dataSuspensoInicial: string;
	hora: string;
	origem: string;
	numeroPgu: string;
	retorno: string;
	sequencia: string;
	suspenso: string;
}

export interface ReabilitacaoCnhDataError {
	transacao: string;
	codigo: number;
	mensagem: string;
}

export interface ConsultaReabilitacaoRequest {
	cpf: string;
	data: string;
	hora: string;
	numeroPgu?: string;
	sequencia: string;
	usuario: string;
	senha: string;
	enderecoIp: string;
}
