import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { ConsultarParametrosLocalidadeRequest, Types } from './types';

// ACTIONS
import {
	consultarParametrosLocalidadeSuccess,
	consultarParametrosLocalidadeFailure,
} from './actions';

function* consultarParametrosLocalidade(request: ReducerAction) {
	const { payload }: { payload: ConsultarParametrosLocalidadeRequest } =
		request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarParametrosLocalidadeRequest;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`parametros-localidades`,
		body,
	);

	if (response.status === 200) {
		yield put(consultarParametrosLocalidadeSuccess(response));
	} else {
		yield put(consultarParametrosLocalidadeFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_PARAMETROS_LOCALIDADE,
		consultarParametrosLocalidade,
	),
]);
