import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';

// REDUX
import { ApplicationState } from 'store';

// ANT DESIGN
import { Alert, Col, Row } from 'antd';

// COMPONENTS
import Section from 'components/Common/Section';
import LightButton from 'components/Common/Buttons/Light';
import { OptionProps } from 'components/Common/Form/Select';
import TypographyCommon from 'components/Common/Typography';

// UTILS
import { formatDate } from 'utils/genericFunctions';

// PATHS
import { ROUTE_PROCON } from 'routes/paths';
import { CadastraAtendimentoPjRequest } from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPj/types';
import { CadastraAtendimentoPfRequest } from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPf/types';

const ConfirmacaoReclamacao: React.FC = () => {
	const history = useHistory();

	const { cadastraAtendimentoPf, cadastraAtendimentoPj } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentoCip,
	);

	const {
		tiposContratacao,
		formaAquisicao,
		tiposDocumentosCompra,
		participacoesAudiencia,
		pedidosConsumidor,
		formasDevolucao,
	} = useSelector((state: ApplicationState) => state.api.procon.dominiosCrm);

	const [formData, setFormData] = useState<
		CadastraAtendimentoPfRequest | CadastraAtendimentoPjRequest | null
	>(null);

	useEffect(() => {
		if (cadastraAtendimentoPf.status === 200) {
			setFormData(cadastraAtendimentoPf.dadosAtendimento);
		}

		if (cadastraAtendimentoPj.status === 200) {
			setFormData(cadastraAtendimentoPj.dadosAtendimento);
		}
	}, [
		cadastraAtendimentoPf.status,
		cadastraAtendimentoPj.dadosAtendimento,
		cadastraAtendimentoPj.status,
		formData,
		cadastraAtendimentoPf.dadosAtendimento,
	]);

	const { selecionado } = useSelector(
		(state: ApplicationState) => state.api.procon.fornecedores,
	);

	const { areas, grupoDeProblemas } = useSelector(
		(state: ApplicationState) => state.api.procon,
	);

	const handleBack = () => {
		history.push(ROUTE_PROCON);
	};

	const handlePrint = () => {
		window.print();
	};

	const handleNewReclamation = () => {
		history.push(ROUTE_PROCON);
	};

	const getClassificacao = () => {
		const text: string[] = [];
		if (formData?.areaId && areas.consultaAreas.data) {
			areas.consultaAreas.data.filter((item: OptionProps) => {
				if (item.value === formData?.areaId) {
					text.push(item.label);
				}

				return <TypographyCommon type="label" text="Não informado" uppercase />;
			});
		}

		if (formData?.assuntoId && areas.assuntos.data) {
			areas.assuntos.data.filter((item: OptionProps) => {
				if (item.value === formData?.assuntoId) {
					text.push(item.label);
				}

				return <TypographyCommon type="label" text="Não informado" uppercase />;
			});
		}

		if (
			formData?.grupoDeProblemaId &&
			grupoDeProblemas.assuntosProblemas.data
		) {
			grupoDeProblemas.assuntosProblemas.data.filter((item: OptionProps) => {
				if (item.value === formData?.grupoDeProblemaId) {
					text.push(item.label);
				}

				return <TypographyCommon type="label" text="Não informado" uppercase />;
			});
		}

		if (formData?.problemaId && grupoDeProblemas.problemas.data) {
			grupoDeProblemas.problemas.data.filter((item: OptionProps) => {
				if (item.value === formData?.problemaId) {
					text.push(item.label);
				}

				return <TypographyCommon type="label" text="Não informado" uppercase />;
			});
		}

		if (text.length) {
			return (
				<>
					{text.map((item: string, index: number) => {
						const texto = item.concat(index === text.length - 1 ? '' : ' • ');
						return (
							<TypographyCommon
								key={item}
								type="label"
								text={texto}
								uppercase
							/>
						);
					})}
				</>
			);
		}

		return <TypographyCommon type="label" text="Não informado" uppercase />;
	};

	const getTipoDeContratacao = (id?: number | string): string => {
		if (!id) {
			return '';
		}
		const forma = tiposContratacao.data.filter(
			(item: OptionProps) => item.value === formData?.tipoDeContratacao,
		);
		return forma[0].label.toLocaleUpperCase() ?? '';
	};

	const getFormaDeAquisicao = (id?: number | string): string => {
		if (!id) {
			return '';
		}
		const forma = formaAquisicao.data.filter(
			(item: OptionProps) => item.value === formData?.formaDeAquisicao,
		);
		return forma[0].label.toLocaleUpperCase() ?? '';
	};

	const getTipoDoDocumento = (id?: number | string): string => {
		if (!id) {
			return '';
		}
		const forma = tiposDocumentosCompra.data.filter(
			(item: OptionProps) => item.value === formData?.tipoDoDocumento,
		);
		return forma[0].label.toLocaleUpperCase() ?? '';
	};

	// const getNumeroDoDocumento = (id?: number | string): string => {
	// 	if (!id) {
	// 		return '';
	// 	}
	// 		const forma = numeroDocumento?.data.filter(
	// 			(item: OptionProps) => item.value === formData?.numeroDoDocumento,
	// 		);
	// 		return forma[0].label.toLocaleUpperCase() ?? '';
	// 	return '';
	// };

	const getParticipacaoEmAudiencia = (id?: number | string): string => {
		if (!id) {
			return '';
		}
		const forma = participacoesAudiencia.data.filter(
			(item: OptionProps) => item.value === formData?.participacaoEmAudiencia,
		);
		return forma[0].label.toLocaleUpperCase() ?? '';
	};

	const getPedido = (id?: number | string): string => {
		if (!id) {
			return '';
		}
		const forma = pedidosConsumidor.data.filter(
			(item: OptionProps) => item.value === formData?.pedido,
		);
		return forma[0].label.toLocaleUpperCase() ?? '';
	};

	const getFormaDeDevolucao = (id?: number | string): string => {
		if (!id) {
			return '';
		}
		const forma = formasDevolucao.data.filter(
			(item: OptionProps) => item.value === formData?.formaDeDevolucao,
		);
		return forma[0].label.toLocaleUpperCase() ?? '';
	};

	// const getDevolucaoNomeDoBanco = (id?: number | string): string => {
	// 	if (!id) {
	// 		return '';
	// 	}
	// 	const forma = devolucaoNomeDoBanco.data.filter(
	// 		(item: OptionProps) => item.value === formData?.devolucaoNomeDoBanco,
	// 	);
	// 	return forma[0].label.toLocaleUpperCase() ?? '';
	// };

	return (
		<>
			<Alert
				className="no-print"
				type="info"
				showIcon
				message=" "
				description="Reclamação cadastrada com sucesso!"
				closable
			/>

			<br />

			<Row gutter={[0, 0]} align="top" justify="start">
				<Col className="gutter-row" span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Detalhes da Reclamação"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>

			<Section
				bgColor="cinza-escuro"
				style={{
					border: '1px grey solid',
					borderRadius: '4px',
					marginTop: '0px',
				}}
			>
				<Row gutter={[0, 0]} align="top" justify="start">
					<Col
						className="gutter-row"
						span={24}
						style={{ paddingBottom: '10px' }}
					>
						<TypographyCommon
							text={`Consumidor Pessoa ${
								formData?.cnpjDoConsumidor?.length ? 'Jurídica' : 'Física'
							}`}
							borderBottom
							type="h5"
							fontWeight="bold"
							uppercase
						/>
					</Col>
				</Row>
				<Row gutter={[6, 0]} align="top" justify="start">
					<Col span={24}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Nome do Serviço ou Produto:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={24}>
								<TypographyCommon
									text={formData?.nomeDoProdutoOuServico ?? 'Não informado'}
									type="label"
									capitalize
								/>
							</Col>
						</Row>
					</Col>

					<Col span={24}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Tipo de Contratação:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={24}>
								<TypographyCommon
									text={getTipoDeContratacao(formData?.tipoDeContratacao)}
									type="label"
									capitalize
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<Col span={24}>
					<Row gutter={[0, 0]} align="top" justify="start">
						<TypographyCommon
							text="Forma de Aquisição:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Row>
					<Row gutter={[6, 0]} align="top" justify="start">
						<Col span={24}>
							<TypographyCommon
								text={getFormaDeAquisicao(formData?.formaDeAquisicao)}
								type="label"
								capitalize
							/>
						</Col>
					</Row>
				</Col>

				<Col span={24}>
					<Row gutter={[0, 0]} align="top" justify="start">
						<TypographyCommon
							text="Tipo de Documento:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Row>
					<Row gutter={[6, 0]} align="top" justify="start">
						<Col span={24}>
							<TypographyCommon
								text={getTipoDoDocumento(formData?.tipoDoDocumento)}
								type="label"
								capitalize
							/>
						</Col>
					</Row>
				</Col>

				{/* <Col span={24}>
					<Row gutter={[0, 0]} align="top" justify="start">
						<TypographyCommon
							text="Número do Documento:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Row>
					<Row gutter={[6, 0]} align="top" justify="start">
						<Col span={24}>
							<TypographyCommon
								text={getNumeroDoDocumento(formData?.numeroDoDocumento)}
								type="label"
								capitalize
							/>
						</Col>
					</Row>
				</Col> */}

				<Row gutter={[6, 0]} align="top" justify="start">
					<Col span={24}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Classificação:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={24}>{getClassificacao()}</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={[6, 0]} align="top" justify="start">
					<Col span={24}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Participação em Audiência:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={24}>
								<TypographyCommon
									text={getParticipacaoEmAudiencia(
										formData?.participacaoEmAudiencia,
									)}
									type="label"
									capitalize
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={[6, 0]} align="top" justify="start">
					<Col span={24}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Descrição do Serviço ou Produto:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={24}>
								<TypographyCommon
									text={formData?.detalhesDoProdutoOuServico ?? 'Não informado'}
									type="label"
									capitalize
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Col span={24}>
					<Row gutter={[0, 0]} align="top" justify="start">
						<TypographyCommon
							text="Pedido:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Row>
					<Row gutter={[6, 0]} align="top" justify="start">
						<Col span={24}>
							<TypographyCommon
								text={getPedido(formData?.pedido)}
								type="label"
								capitalize
							/>
						</Col>
					</Row>
				</Col>

				{(formData?.pedido === 5 || formData?.pedido === 34) && (
					<Col span={24}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Forma de Devolução:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={24}>
								<TypographyCommon
									text={getFormaDeDevolucao(formData?.formaDeDevolucao)}
									type="label"
									capitalize
								/>
							</Col>
						</Row>
					</Col>
				)}
				{/*
				{formData?.formaDeDevolucao === 1 && (
					<Col span={24}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Banco emissor:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={24}>
								<TypographyCommon
									text={getDevolucaoNomeDoBanco(formData?.devolucaoNomeDoBanco)}
									type="label"
									capitalize
								/>
							</Col>
						</Row>
					</Col>
				)} */}

				<Row gutter={[6, 0]} align="top" justify="start">
					<Col span={24}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Descrição da Reclamação:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={24}>
								<TypographyCommon
									text={formData?.detalhesDaReclamacao ?? 'Não informado'}
									type="label"
									capitalize
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row gutter={[6, 0]} align="top" justify="start">
					<Col span={6}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Data da Compra ou Aquisição:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={23}>
								<TypographyCommon
									text={
										formData?.dataDaContratacao
											? formatDate(formData?.dataDaContratacao)
											: 'Não informado'
									}
									type="label"
									capitalize
								/>
							</Col>
						</Row>
					</Col>
					<Col span={9}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Fornecedor:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={23}>
								<TypographyCommon
									text={selecionado.data?.razaoSocial ?? 'Não informado'}
									type="label"
									capitalize
								/>
							</Col>
						</Row>
					</Col>
					<Col span={9}>
						<Row gutter={[0, 0]} align="top" justify="start">
							<TypographyCommon
								text="Nome Fantasia do Fornecedor:"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={[6, 0]} align="top" justify="start">
							<Col span={23}>
								<TypographyCommon
									text={selecionado.data?.nomeFantasia ?? 'Não informado'}
									type="label"
									capitalize
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row align="top" justify="start" className="no-print">
					<Col span="15">
						<LightButton
							type="button"
							buttonColor="default"
							text="Voltar"
							icon="back"
							buttonSize="sm"
							buttonWidth={100}
							onClick={handleBack}
						/>
					</Col>
					<Col span="4">
						<Row align="top" justify="end" className="no-print">
							<LightButton
								type="button"
								buttonColor="default"
								text="Imprimir"
								icon="print"
								buttonSize="sm"
								buttonWidth={140}
								leftOrRight="left"
								onClick={() => handlePrint()}
							/>
						</Row>
					</Col>
					<Col span="5">
						<Row align="top" justify="end" className="no-print">
							<LightButton
								onClick={handleNewReclamation}
								type="button"
								buttonColor="green"
								icon="add"
								buttonSize="sm"
								buttonWidth={150}
								text="Finalizar Atendimento"
							/>
						</Row>
					</Col>
				</Row>
			</Section>
		</>
	);
};
export default ConfirmacaoReclamacao;
