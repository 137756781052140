import { ReducerAction } from 'store/modules/types';

import { Types, IPEBERequest } from './types';

export function consultarPEBERequest(data: IPEBERequest): ReducerAction {
	return {
		type: Types.PEBE_REQUEST,
		payload: data,
	};
}

export function consultarPEBESuccess(payload: object) {
	return {
		type: Types.PEBE_SUCCESS,
		payload,
	};
}

export function consultarPEBEFailure(payload: object): ReducerAction {
	return {
		type: Types.PEBE_FAILURE,
		payload,
	};
}

export function consultarPEBEClear(): any {
	return {
		type: Types.PEBE_CLEAR,
	};
}
