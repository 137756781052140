export enum Types {
	CERTIFICATE_VALIDATE_REQUEST = '@E-CPF/CERTIFICATE_VALIDATE_REQUEST',
	CERTIFICATE_VALIDATE_SUCCESS = '@E-CPF/CERTIFICATE_VALIDATE_SUCCESS',
	CERTIFICATE_VALIDATE_FAILURE = '@E-CPF/CERTIFICATE_VALIDATE_FAILURE',
	CERTIFICATE_VALIDATE_CLEAR = '@E-CPF/CERTIFICATE_VALIDATE_CLEAR',
}
export interface CertificateValidate {
	status: number;
	data: Data | null;
}
interface Data {
	isValido: boolean;
	cpf: string;
	dtNascimento: string;
	isPessoaFisica: number;
}
export interface CertificateValidateRequest {
	cpf: string;
}
