export enum Types {
	TIPOS_ENSINO_REQUEST = '@EDUCACAO/TIPOS_ENSINO_REQUEST',
	TIPOS_ENSINO_SUCCESS = '@EDUCACAO/TIPOS_ENSINO_SUCCESS',
	TIPOS_ENSINO_FAILURE = '@EDUCACAO/TIPOS_ENSINO_FAILURE',
}

export interface TiposEnsino {
	status: number;
	data: null | TiposEnsinoData;
}

interface TiposEnsinoData {
	processoId: string;
	tipoEnsino: TipoEnsinoResponse[];
}

export interface TipoEnsinoResponse {
	codigo: string;
	descricao: string;
	serieAno: SeriesAnos[];
}

export interface SeriesAnos {
	codigo: string;
	descricao: string;
}
