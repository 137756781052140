import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types } from './types';
import {
	multasRenainfOrgaoSuccess,
	multasRenainfOrgaoFailure,
} from './actions';

function* multasRenainfOrgaoRequest({ payload }: any) {
	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consulta-renainf-orgao`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(multasRenainfOrgaoSuccess(response));
	} else if (response.error) {
		yield put(multasRenainfOrgaoFailure());
	}
}

export default all([
	takeLatest(Types.MULTAS_RENAINF_ORGAO_REQUEST, multasRenainfOrgaoRequest),
]);
