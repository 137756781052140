import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, transferirPontuacao } from './types';

export const INITIAL_STATE: transferirPontuacao = {
	status: 0,
	data: null,
};

export default function transferirPontuacaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): transferirPontuacao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.TRANSFERIR_PONTUACAO: {
				break;
			}
			case Types.TRANSFERIR_PONTUACAO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.TRANSFERIR_PONTUACAO_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
