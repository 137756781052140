import { ICadastroSolicitante } from 'pages/mp/ReconhecimentoPaternidade/NovoCadastro/Forms/form';

export enum Types {
	PRE_CADASTRO_REQUEST = '@MP/PRE_CADASTRO_REQUEST',
	LIMPA_PRE_CADASTRO_REQUEST = '@MP/LIMPA_PRE_CADASTRO_REQUEST',
}

export interface PreCadastroRequest {
	status: number;
	data: ICadastroSolicitante;
}
