export enum Types {
	IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA_REQUEST = '@DETRAN-CRV/IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA_REQUEST',
	IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA_SUCCESS = '@DETRAN-CRV/IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA_SUCCESS',
	IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA_FAILURE = '@DETRAN-CRV/IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA_FAILURE',
}

export interface impressaoOficioSegundaViaPlaca {
	status: number;
	data: ficha | null;
}

interface ficha {
	anoFicha: number;
	chassi: string;
	numeroficha: number;
	oficio: string;
	placa: string;
}
