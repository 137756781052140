import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, consultaPrefeiturasServicosCanais } from './types';

export const INITIAL_STATE: consultaPrefeiturasServicosCanais = {
	status: 0,
	data: null,
};

export default function consultaPrefeiturasServicosCanaisReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaPrefeiturasServicosCanais {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PREFEITURA_SERVICOS_CANAIS: {
				break;
			}
			case Types.CONSULTA_PREFEITURA_SERVICOS_CANAIS_SUCCESS: {
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTA_PREFEITURA_SERVICOS_CANAIS_FAILURE: {
				draft.data = null;
				break;
			}
			default:
		}
	});
}
