import { userDataRegex } from 'utils/constants/regex';
import * as Yup from 'yup';
import { FileUploaded } from '../../UploadArquivoGeral/form';

export interface IDesbloquearVeiculoPorSolicitante {
	cpfCnpj: string;
	cpf?: string;
	placa: string;
	renavam: string;
	nome: string;
	telefone: string;
	email: string;
	documentos?: FileUploaded[];
}

export const initialValuesDesbloquearVeiculoPorSolicitante: IDesbloquearVeiculoPorSolicitante =
	{
		cpfCnpj: '',
		cpf: '',
		placa: '',
		renavam: '',
		nome: '',
		telefone: '',
		email: '',
		documentos: [],
	};

export const schemaDefault =
	Yup.object<IDesbloquearVeiculoPorSolicitante>().shape({
		cpfCnpj: Yup.string().required(
			'O Campo CPF/CNPJ do proprietário é de preenchimento obrigatório.',
		),
		placa: Yup.string().required(
			'O Campo Placa é de preenchimento obrigatório.',
		),
		renavam: Yup.string().required(
			'O campo Renavam é de preenchimento obrigatório.',
		),
		nome: Yup.string().required('O Campo Nome é de preenchimento obrigatório.'),
		telefone: Yup.string()
			.required('O Campo Telefone é de preenchimento obrigatório.')
			.matches(userDataRegex.cellPhoneOrPhoneNumberWithAreaCode, {
				message: 'Campo Inválido: Telefone.',
				excludeEmptyString: true,
			}),
		email: Yup.string()
			.required('O Campo E-mail é de preenchimento obrigatório.')
			.matches(userDataRegex.emailAddress, {
				message: 'Formato de e-mail inválido.',
				excludeEmptyString: true,
			}),
		documentos: Yup.array().min(
			1,
			'O Campo Documentos é de preenchimento obrigatório.',
		),
	});
