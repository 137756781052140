export enum Types {
	EXCLUIR_STATUS = '@SGU/EXCLUIR_STATUS',
	EXCLUIR_STATUS_SUCCESS = '@SGU/EXCLUIR_STATUS_SUCCESS',
	EXCLUIR_STATUS_FAILURE = '@SGU/EXCLUIR_STATUS_FAILURE',
	EXCLUIR_STATUS_CLEAR = '@SGU/EXCLUIR_STATUS_CLEAR',
}

export interface ExcluirStatus {
	status: number;
	data: null | ExcluirStatusData;
}

export interface ExcluirStatusData {
	status: string;
	statusMensagem: string;
}

export interface ExcluirStatusRequest {
	codigo?: number | string;
	descricao: string;
}
