export enum Types {
	CONSULTA_MUNICIPIO_REQUEST = '@utils/CONSULTA_MUNICIPIO_REQUEST',
	CONSULTA_MUNICIPIO_SUCCESS = '@utils/CONSULTA_MUNICIPIO_SUCCESS',
	CONSULTA_MUNICIPIO_FAILURE = '@utils/CONSULTA_MUNICIPIO_FAILURE',
	CONSULTA_MUNICIPIO_CLEAR = '@utils/CONSULTA_MUNICIPIO_CLEAR',
}

export interface ConsultaMunicipioRequest {
	canal?: string;
	paginaInicial?: number;
	limite?: number;
	codigo?: string | number;
	nome?: string;
	uf?: string;
}

export interface ConsultaMunicipio {
	status: number;
	municipios: null | Municipio[];
	totalRegistros?: number;
	totalRegistrosConsulta?: number;
}

export interface Municipio {
	codigo: number;
	nome: string;
	uf: string;
}
