import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse } from 'services/_api';
import { putApi } from 'services/_sgu';

// TYPES
import { ReducerAction } from 'store/modules/types';

import { CHANNEL } from 'services/_path';
import { AlteraSenhaUsuarioRequest, Types } from './types';

// ACTIONS
import {
	alteraSenhaUsuarioSuccess,
	alteraSenhaUsuarioFailure,
} from './actions';

function* alteraSenhaUsuarioRequest(request: ReducerAction) {
	const { payload }: { payload: AlteraSenhaUsuarioRequest } = request;

	const response: ApiResponse = yield call(
		putApi,
		`usuario/${CHANNEL}/altera-senha-usuario`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(alteraSenhaUsuarioSuccess(response));
	} else {
		yield put(alteraSenhaUsuarioFailure(response.message));
	}
}

export default all([
	takeLatest(Types.ALTERA_SENHA_USUARIO_REQUEST, alteraSenhaUsuarioRequest),
]);
