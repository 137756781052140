import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	FORMAS_DEVOLUCAO_REQUEST = '@procon/FORMAS_DEVOLUCAO_REQUEST',
	FORMAS_DEVOLUCAO_SUCCESS = '@procon/FORMAS_DEVOLUCAO_SUCCESS',
	FORMAS_DEVOLUCAO_FAILURE = '@procon/FORMAS_DEVOLUCAO_FAILURE',
	FORMAS_DEVOLUCAO_CLEAR = '@procon/FORMAS_DEVOLUCAO_CLEAR',
}

export interface FormasDevolucao {
	status: null | number;
	data: OptionProps[];
}
