import { all } from 'redux-saga/effects';

import consultaRenovacaoCnhSaga from './consultaSegundaViaCnh/saga';
import gerarRenachSegundaViaSaga from './gerarRenachSegundaVia/saga';
import consultaEPortalSegundaViaSaga from './consultaEPortalSegundaVia/saga';

export default all([
	consultaRenovacaoCnhSaga,
	gerarRenachSegundaViaSaga,
	consultaEPortalSegundaViaSaga,
]);
