import { all } from 'redux-saga/effects';

import {
	consultarMunicipioPorUF,
	consultaCarroceria,
	consultaTipo,
	consultaCategoria,
	consultaCor,
	consultaCombustivel,
	consultaEspecie,
	consultarTipoModificacaoAlteracao,
	consultaMarcaModelo,
	consultarUnidadesTransito,
	consultarMunicipioCodigoProdesp,
} from './enum/combineSaga';

import {
	consultarVeiculoBaseEstadual,
	consultarCadastroBinRenavam,
	consultarBaseOutrosEstados,
	consultarNumeroMotorBin,
} from './ConsultarVeiculos/combineSaga';

import {
	consultarBloqueioDetranRenajud,
	consultarRestricaoVeiculo,
	consultarTaxaServico,
	saveTaxaServico,
} from './ConsultarRestricoes/combineSaga';

import {
	multasRenainfOrgao,
	multasRenainfPlaca,
} from './MultasRenainf/combineSaga';

import {
	consultarFichaCadastral,
	excluirFichaCadastral,
	impressaoRenavam,
	impressaoLicenciamentoVeiculos,
	impressaoAlteracaoEndereco,
	impressaoSegundaViaLicenciamento,
	impressaoComunicacaoVenda,
	impressaoOficioSegundaViaPlaca,
	impressaoTrocaVoluntariaPlaca,
	andamentoProcesso,
} from './Processos/combineSaga';

import {
	escolherPlaca,
	primeiroEmplacamento,
	licenciamentoVeiculo,
	transferenciaPropietario,
	cancelarAutorizacaoPrevia,
	consultarAutorizacaoPrevia,
	consultarDetalhesAutorizacaoPrevia,
	consultarVeiculoV110,
	consultaPlacaDisponivel,
	consultaUnidadeTransito,
	confirmarPlaca,
	imprimirCrvle,
	emitirAutorizacaoPrevia,
	consultarVeiculoV120,
	primeiroEmplacamentoEmissao,
	emitirSegundaViaCrv,
	emitirBaixaVeiculo,
	emitirAlteracaoDadosCadastrais,
	emitirModificacaoDadosCadastrais,
	emitirTransferenciasMunicipiosEstados,
	emitirTransferenciasProprietarios,
	emitirTransferenciaMunicipio,
	emitirTrocaVoluntariaPlaca,
	emitirEscolhaPlacaPersonalizada,
	emitirSegundaViaPlaca,
	consultarVeiculoSegundaViaPlaca,
	cancelarPlacaPrimeiroEmplacamento,
	tipoArquivoEmissao,
	consultarAcompanhamento,
	downloadDocumentos,
	reenviarDocumentos,
	consultaRestricoes,
	registraSolicitacoes,
} from './SolicitarDocumentos/combineSaga';

import {
	imprimirIntencaoVenda,
	cancelarIntencaoVenda,
	incluirIntencaoVenda,
	consultarIntencaoVenda,
} from './IntencaoDeVenda/combineSaga';

import {
	pcov,
	pepm,
	pgat,
	txut,
	ptres,
	ptreoe,
	pebe,
} from './ConsultarResumoExtrato/combineSaga';

export default all([
	// ENUM'S
	consultarMunicipioPorUF,
	consultaCarroceria,
	consultaTipo,
	consultaCategoria,
	consultaCor,
	consultaCombustivel,
	consultaEspecie,
	consultarTipoModificacaoAlteracao,
	consultaMarcaModelo,
	consultarUnidadesTransito,
	consultarMunicipioCodigoProdesp,

	// CONSULTA VEÍCULOS
	consultarVeiculoBaseEstadual,
	consultarCadastroBinRenavam,
	consultarBaseOutrosEstados,
	consultarNumeroMotorBin,

	// CONSULTAR RESTRIÇÕES
	consultarBloqueioDetranRenajud,
	consultarRestricaoVeiculo,
	consultarTaxaServico,
	saveTaxaServico,

	// MULTAS RENAINF
	multasRenainfOrgao,
	multasRenainfPlaca,

	// SOLICITAR DOCUMENTOS
	escolherPlaca,
	primeiroEmplacamento,
	licenciamentoVeiculo,
	transferenciaPropietario,
	cancelarAutorizacaoPrevia,
	consultarAutorizacaoPrevia,
	consultarDetalhesAutorizacaoPrevia,
	consultarVeiculoV110,
	consultaPlacaDisponivel,
	consultaUnidadeTransito,
	confirmarPlaca,
	imprimirCrvle,
	emitirAutorizacaoPrevia,
	consultarVeiculoV120,
	primeiroEmplacamentoEmissao,
	emitirSegundaViaCrv,
	emitirBaixaVeiculo,
	emitirAlteracaoDadosCadastrais,
	emitirModificacaoDadosCadastrais,
	emitirTransferenciasMunicipiosEstados,
	emitirTransferenciasProprietarios,
	emitirTransferenciaMunicipio,
	emitirTrocaVoluntariaPlaca,
	emitirEscolhaPlacaPersonalizada,
	emitirSegundaViaPlaca,
	consultarVeiculoSegundaViaPlaca,
	cancelarPlacaPrimeiroEmplacamento,
	tipoArquivoEmissao,
	consultarAcompanhamento,
	downloadDocumentos,
	reenviarDocumentos,
	consultaRestricoes,
	registraSolicitacoes,

	// PROCESSOS
	consultarFichaCadastral,
	excluirFichaCadastral,
	impressaoRenavam,
	impressaoLicenciamentoVeiculos,
	impressaoAlteracaoEndereco,
	impressaoSegundaViaLicenciamento,
	impressaoComunicacaoVenda,
	impressaoOficioSegundaViaPlaca,
	impressaoTrocaVoluntariaPlaca,
	andamentoProcesso,

	// INTENÇÃO DE VENDA
	imprimirIntencaoVenda,
	cancelarIntencaoVenda,
	incluirIntencaoVenda,
	consultarIntencaoVenda,

	// CONSULTAR RESUMO EXTRATO
	pcov,
	pepm,
	pgat,
	txut,
	ptres,
	ptreoe,
	pebe,
]);
