import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestDatasExame } from './types';

// ACTIONS
import {
	datasExameSuccess,
	datasExameFailure,
	datasExameEnumSuccess,
} from './actions';

function* datasExame(request: ReducerAction) {
	const { payload }: { payload: IRequestDatasExame } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`divisao-equitativa/${CHANNEL}/datas-exame`,
		payload,
	);

	if (response.status === 200) {
		yield put(datasExameSuccess(response));
	} else if (response.error) {
		yield put(datasExameFailure(response.error));
	}
}

function* datasExameEnum(request: ReducerAction) {
	const { payload }: { payload: IRequestDatasExame } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`divisao-equitativa/${CHANNEL}/datas-exame`,
		payload,
	);

	if (response.status === 200) {
		yield put(datasExameEnumSuccess(response));
	} else if (response.error) {
		yield put(datasExameFailure(response.error));
	}
}

export default all([
	takeLatest(Types.DATAS_EXAME, datasExame),
	takeLatest(Types.DATAS_EXAME_ENUM, datasExameEnum),
]);
