import * as Yup from 'yup';

export interface IConsultaBaseEstadual {
	chassi: string;
	placa: string;
	renavam: string;
	municipio: {
		codigo: number | string;
		nome: string;
	};
}

export const initialValues: IConsultaBaseEstadual = {
	chassi: '',
	placa: '',
	renavam: '',
	municipio: {
		codigo: '',
		nome: '',
	},
};

export function treatValues(
	values: IConsultaBaseEstadual,
): IConsultaBaseEstadual {
	const formattedData = {
		chassi: values.chassi?.trim() || '',
		placa: values.placa?.trim() || '',
		renavam: values.renavam?.trim() || '',
		municipio: {
			codigo: values.municipio.codigo,
			nome: values.municipio.nome,
		},
	};

	return formattedData;
}

export const schema = Yup.object<IConsultaBaseEstadual>().shape({
	placaRenavamChassi: Yup.string()
		.when(['placa', 'renavam', 'chassi'], {
			is: (placa, renavam, chassi) =>
				placa === '' && renavam === '' && chassi === '',
			then: Yup.string().required(`Placa e Renavam ou Chassi É OBRIGATÓRIO`),
		})
		.when(['placa', 'renavam', 'chassi'], {
			is: (placa, renavam, chassi) =>
				placa !== '' && renavam === '' && chassi === '',
			then: Yup.string().required(`Renavam ou Chassi É OBRIGATÓRIO`),
		})
		.when(['placa', 'renavam', 'chassi'], {
			is: (placa, renavam, chassi) =>
				placa === '' && renavam !== '' && chassi === '',
			then: Yup.string().required(`Placa É OBRIGATÓRIO`),
		}),
});
