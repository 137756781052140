// import { IEvento } from '../types';

export enum Types {
	DOWNLOAD_DOCUMENTO_REQUEST = '@DETRAN-CRV/DOWNLOAD_DOCUMENTO_REQUEST',
	DOWNLOAD_DOCUMENTO_SUCCESS = '@DETRAN-CRV/DOWNLOAD_DOCUMENTO_SUCCESS',
	DOWNLOAD_DOCUMENTO_FAILURE = '@DETRAN-CRV/DOWNLOAD_DOCUMENTO_FAILURE',
	DOWNLOAD_DOCUMENTO_CLEAR = '@DETRAN-CRV/DOWNLOAD_DOCUMENTO_CLEAR',
}

export interface DownloadDocumentos {
	status: number;
	data: IDownloadDocumentosResponse | null;
	mensagem?: string;
}

export interface IDownloadDocumentosResponse {
	documentos: Documento[];
}

export interface Documento {
	arquivo: string;
	nome: string;
}

export interface IDownloadDocumentosRequest {
	numeroFicha: string;
	anoFicha: string;
}
