import { combineReducers } from 'redux';

import consultarBloqueioDetranRenajud from './consultarBloqueioDetranRenajud/reducer';
import consultarRestricaoVeiculo from './consultarRestricaoVeiculo/reducer';
import consultarTaxaServico from './consultarTaxaServico/reducer';
import saveTaxaServico from './saveTaxaServico/reducer';

export default combineReducers({
	consultarBloqueioDetranRenajud,
	consultarRestricaoVeiculo,
	consultarTaxaServico,
	saveTaxaServico,
});
