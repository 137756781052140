import { Types } from './types';

export function impressaoGeraSenhaRequest(payload: any) {
	return {
		type: Types.IMPRESSAO_GERA_SENHA_REQUEST,
		payload,
	};
}
export function impressaoGeraSenhaSuccess(payload: any) {
	return {
		type: Types.IMPRESSAO_GERA_SENHA_SUCCESS,
		payload,
	};
}
export function impressaoGeraSenhaFailure(payload: []): any {
	return {
		type: Types.IMPRESSAO_GERA_SENHA_FAILURE,
		payload,
	};
}
export function impressaoSenhaClear(): any {
	return {
		type: Types.IMPRESSAO_SENHA_CLEAR,
	};
}
