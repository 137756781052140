import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_IIRGD } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ContabilizacaoIirgdRequest, Types } from './types';

// ACTIONS
import {
	contabilizacaoIirgdSuccess,
	contabilizacaoIirgdFailure,
} from './actions';

function* contabilizacaoIirgdRequest(request: ReducerAction) {
	const { payload }: { payload: ContabilizacaoIirgdRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		`emissao-carteira-identidade/${CHANNEL}/contabilizacao`,
		payload,
		{},
		true,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(contabilizacaoIirgdSuccess(response));
	} else {
		yield put(contabilizacaoIirgdFailure(response));
	}
}

export default all([
	takeLatest(Types.CONTABILIZACAO_IIRGD_REQUEST, contabilizacaoIirgdRequest),
]);
