import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaDivisaoEquitativa } from './types';

// ACTIONS
import {
	consultaDivisaoEquitativaSuccess,
	consultaDivisaoEquitativaFailure,
} from './actions';

function* consultaDivisaoEquitativa(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaDivisaoEquitativa } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}/consultaDivisaoEquitativa`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaDivisaoEquitativaSuccess(response));
	} else if (response.error) {
		yield put(consultaDivisaoEquitativaFailure(response.error));
	}
}

export default all([
	takeLatest(Types.CONSULTA_DIVISAO_EQUITATIVA, consultaDivisaoEquitativa),
]);
