export enum Types {
	CONSULTA_RECICLAGEM = '@cnh/CONSULTA_RECICLAGEM_T',
	CONSULTA_RECICLAGEM_SUCCESS = '@cnh/CONSULTA_RECICLAGEM_SUCCESS_T',
	CONSULTA_RECICLAGEM_FAILURE = '@cnh/CONSULTA_RECICLAGEM_FAILURE_T',
}

export interface consultaReciclagem {
	status: number;
	data: IDadosReciclagem | null;
}

export interface IDadosReciclagem {
	cpf_condutor: string;
	realiza_processo: string;
	precisa_curso: string;
	codigo: string;
	mensagem: string;
}

export interface IRequestConsultaRecilagem {
	cpf_condutor: string;
	numero_pgu?: string;
}
