import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ReducerAction } from 'store/modules/types';
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_EDUCACAO_SEDUC_FICHAS_API } from 'services/rotasp/path';
import { AlterarFichaAlunoRequestData, Types } from './types';
import { alterarFichaAlunoSuccess, alterarFichaAlunoFailure } from './actions';

function* alterarFichaAlunoRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: AlterarFichaAlunoRequestData } = request;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_EDUCACAO_SEDUC_FICHAS_API,
		`fichas`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(alterarFichaAlunoSuccess(response));
	} else if (response.status === 204) {
		yield put(alterarFichaAlunoSuccess(response));
	} else if (response.error) {
		yield put(alterarFichaAlunoFailure(response.message));
	}
}

export default all([
	takeLatest(Types.ALTERAR_FICHA_ALUNO_REQUEST, alterarFichaAlunoRequest),
]);
