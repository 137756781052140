import { ReducerAction } from 'store/modules/types';

import {
	Types,
	ExcluirFuncionalidadesRelacionadasLocalidadeRequest,
} from './types';

export function excluirFuncionalidadesRelacionadasLocalidadeRequest(
	data: ExcluirFuncionalidadesRelacionadasLocalidadeRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE,
		payload: data,
	};
}
export function excluirFuncionalidadesRelacionadasLocalidadeSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function excluirFuncionalidadesRelacionadasLocalidadeFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function excluirFuncionalidadesRelacionadasLocalidadeClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE_CLEAR,
		payload: null,
	};
}
