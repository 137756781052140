import * as Yup from 'yup';

// REDUX
import data from 'store/modules/api/cdhu/enum';

// TYPES
import { ConsultarMutuarioData } from 'store/modules/api/cdhu/consultarMutuario/types';
import { ConsultarSolicitanteData } from 'store/modules/api/cdhu/consultarSolicitante/types';
import {
	GerarOcorrenciaRequest,
} from 'store/modules/api/cdhu/gerarOcorrencia/types';

import { IFormDadosSolicitante } from '../form';

export interface IFormServicosMutuario {
	servico: string;
	subservico: string;
	motivoBoletoAgrupado: string;
	motivo: string;
}

export const initialValues: IFormServicosMutuario = {
	servico: '',
	subservico: '',
	motivoBoletoAgrupado: '',
	motivo: '',
};

export const schema = Yup.object<IFormServicosMutuario>().shape({
	servico: Yup.string().required('Campo Obrigatório: Serviço.'),
	boletoAgrupado: Yup.string()
		.when(['servico', 'motivoBoletoAgrupado'], {
			is: (servico, motivoBoletoAgrupado) =>
				servico === 'boletoAgrupado' && !motivoBoletoAgrupado,
			then: Yup.string().required('Campo Obrigatório: Motivo.'),
		})
		.nullable(),
	subservico: Yup.string()
		.when(['servico', 'motivoBoletoAgrupado'], {
			is: (servico, motivoBoletoAgrupado) =>
				servico === 'boletoAgrupado' && !motivoBoletoAgrupado,
			then: Yup.string().required('Campo Obrigatório: Motivo.'),
		})
		.nullable(),
	motivo: Yup.string()
		.when(['servico', 'motivoBoletoAgrupado'], {
			is: (servico, motivoBoletoAgrupado) =>
				servico === 'boletoAgrupado' && !motivoBoletoAgrupado,
			then: Yup.string().required('Campo Obrigatório: Motivo.'),
		})
		.nullable(),
});

function handleServicoEnum(servico: string): string {
	switch (servico) {
		case 'boletoAgrupado':
			return 'BOLETO_AGRUPADO';

		case 'acordo':
			return 'ACORDO';

		case 'transfTitularidade':
			return 'INFO_TRANSFERENCIA_TITULARIDADE';

		case 'seguroHabitacional':
			return 'INFO_SEGURO_HABITACIONAL';

		case 'inscricoesProgHabit':
			return 'INFO_INSCRICOES_PROGRAMAS_HABITACIONAIS';

		case 'situacaoFinanceira':
			return 'INFO_SITUACAO_FINANCEIRA';

		case 'saldoDevedor':
			return 'INFO_SALDO_DEVEDOR';

		default:
			return '';
	}
}

function handleServicoDescricao(servico: string): string {
	const descricao = data.servicos.filter(item => item.value === servico);

	return descricao[0].label;
}

function handleDadosSolicitante(
	dadosSolicitante: ConsultarSolicitanteData | null,
) {
	if (dadosSolicitante) {
		const {
			telefoneCelular,
			telefoneResidencial,
			telefoneComercial,
			telefoneDeContato,
		} = dadosSolicitante;

		const solicitanteId = dadosSolicitante.id;
		const emailSolicitante = dadosSolicitante.email;
		const telCelularSolicitante = telefoneCelular;
		const telResidencialSolicitante = telefoneResidencial;
		const telComercialSolicitante = telefoneComercial;
		const telContatoSolicitante = telefoneDeContato;

		return {
			solicitanteId,
			emailSolicitante,
			telCelularSolicitante,
			telResidencialSolicitante,
			telComercialSolicitante,
			telContatoSolicitante,
		};
	}

	return {
		solicitanteId: null,
		emailSolicitante: null,
		telCelularSolicitante: null,
		telResidencialSolicitante: null,
		telComercialSolicitante: null,
		telContatoSolicitante: null,
	};
}

export function treatValues(
	formValues: IFormServicosMutuario,
	dataFormValuesSolicitante: IFormDadosSolicitante,
	dataConsultarMutuario: ConsultarMutuarioData,
	dataConsultarSolicitante: ConsultarSolicitanteData | null,
	tipoVinculo: number | string,
): GerarOcorrenciaRequest {
	const servicoEnum = handleServicoEnum(formValues.servico);
	const descricao = handleServicoDescricao(formValues.servico);

	const {
		componenteContrato,
		contratoCDHU,
		celular,
		telefone1,
		telefone2,
		numeroContrato,
	} = dataConsultarMutuario;

	const dataSolicitante = handleDadosSolicitante(dataConsultarSolicitante);

	return {
		servicoEnum,
		tipoVinculoCDHU: 'CIDADAO_SEM_VINCULO',
		descricao,
		tipoOcorrencia: 'INFORMACAO',
		origem: 'PRESENCIAL_POUPATEMPO',
		formaRetorno: 'PRESENCIAL_POUPATEMPO',
		// Dados Mutuário
		componenteContratoId: componenteContrato,
		contratoCDHUId: contratoCDHU,
		numeroContrato,
		mutuarioId: dataConsultarMutuario.id,
		// mutuarioSolicitanteId,
		emailMutuario:
			dataFormValuesSolicitante.email || dataConsultarMutuario.email || '',
		telCelularMutuario: dataFormValuesSolicitante.celular || celular || '',
		telResidencialMutuario:
			dataFormValuesSolicitante.telefoneResidencial || telefone1 || '',
		telComercialMutuario:
			dataFormValuesSolicitante.telefoneComercial || telefone2 || '',

		// Dados Solicitante
		alterarDadosSolicitante: false,
		solicitanteId: dataSolicitante.solicitanteId || '',
		emailSolicitante: dataSolicitante.emailSolicitante || '',
		telCelularSolicitante: dataSolicitante.telCelularSolicitante || '',
		telResidencialSolicitante: dataSolicitante.telResidencialSolicitante || '',
		telComercialSolicitante: dataSolicitante.telComercialSolicitante || '',
		telContatoSolicitante: dataSolicitante.telContatoSolicitante || '',

		// Motivo
		motivoSolicitar2ViaBoleto: 'PROBLEMAS_COM_CORREIO',
		motivoSolicitarBoletoAgrupado: 'BOLETO_VENCIDO',
		motivoSolicitarCarne: 'NAO_ENTREGA_PELOS_CORREIOS',
	};
}
