import React, { useCallback } from 'react';

// ANTD
import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_BASE_ESTADUAL_CONSULTAR } from 'pages/DetranCrv/routes/paths';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { consultarPEPMClear } from 'store/modules/api/detranCrv/ConsultarResumoExtrato/pepm/actions';
import { consultarPGATClear } from 'store/modules/api/detranCrv/ConsultarResumoExtrato/pgat/actions';
import { consultarPCOVClear } from 'store/modules/api/detranCrv/ConsultarResumoExtrato/pcov/actions';
import { consultarTXUTClear } from 'store/modules/api/detranCrv/ConsultarResumoExtrato/txut/actions';

// COMPONENTS COMMON
import Collapse from 'components/Common/Collapse';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// COMPONENTS
import ExtratoPEPM from './PEPM';
import ExtratoPCOV from './PCOV';
import ExtratoTXUT from './TXUT';
import ExtratoPGAT from './PGAT';

const ConsultarResumoExtratoBaseEstadualResultado: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const { pepm, pcov, txut, pgat } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarResumoExtrato,
	);

	const contents = [
		{
			key: 'pepm',
			title: 'Cadastro Estadual (PEPM)',
			content: <ExtratoPEPM data={pepm} />,
		},
		{
			key: 'txut',
			title: 'Taxas (TXUT)',
			content: <ExtratoTXUT data={txut} />,
		},
		{
			key: 'pcov',
			title: 'Comunicação de Venda (PCOV)',
			content: <ExtratoPCOV data={pcov} />,
		},
		{
			key: 'pgat',
			title: 'Gravame (PGAT)',
			content: <ExtratoPGAT data={pgat} />,
		},
		// {
		// 	key: 'ptreoe',
		// 	title: 'Consulta Ocorrência Escolhida (PTRE)',
		// 	content: <ExtratoPTREOE data={ptreoe} />,
		// },
		// {
		// 	key: 'prin',
		// 	title: 'PRIN',
		// 	content: <ExtratoPEPM />,
		// },
		// {
		// 	key: 'pfur',
		// 	title: 'PFUR',
		// 	content: <ExtratoPEPM />,
		// },
		// {
		// 	key: 'pblo',
		// 	title: 'PBLO',
		// 	content: <ExtratoPEPM />,
		// },
		// {
		// 	key: 'tlct',
		// 	title: 'TLCT',
		// 	content: <ExtratoPEPM />,
		// },
		// {
		// 	key: 'pvis',
		// 	title: 'PVIS',
		// 	content: <ExtratoPEPM />,
		// },
		// {
		// 	key: 'papp',
		// 	title: 'PAPP',
		// 	content: undefined,
		// },
	];

	const handleBack = useCallback(() => {
		dispatch(clearNotifications());

		dispatch(consultarPEPMClear());

		dispatch(consultarPGATClear());

		dispatch(consultarPCOVClear());

		dispatch(consultarTXUTClear());

		history.push(ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_BASE_ESTADUAL_CONSULTAR);
	}, [history, dispatch]);

	return (
		<>
			<Row>
				<Col span={24}>
					<Section size="lg" title="Resultado" titleSize="sm">
						<Collapse contents={contents} />
					</Section>

					<ButtonVoltar navigate={false} onClick={handleBack} />
				</Col>
			</Row>
		</>
	);
};

export default ConsultarResumoExtratoBaseEstadualResultado;
