import { combineReducers } from 'redux';

import atualizarMunicipio from './atualizarMunicipio/reducer';
import cadastrarMunicipio from './cadastrarMunicipio/reducer';
import consultarMunicipios from './consultarMunicipios/reducer';
import excluirMunicipio from './excluirMunicipio/reducer';
import pesquisaMunicipios from './pesquisaMunicipios/reducer';

export default combineReducers({
	atualizarMunicipio,
	cadastrarMunicipio,
	consultarMunicipios,
	excluirMunicipio,
	pesquisaMunicipios,
});
