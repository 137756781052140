import styled from 'styled-components';

export const Content = styled.div`
	form {
		display: flex;

		input {
			margin: 0 20px;
		}
	}
`;

export const TooltipBox = styled.div`
	display: flex;
	align-items: center;
`;

export const Div = styled.div`
	padding-top: 30px;
	button {
		display: contents;
	}
`;
