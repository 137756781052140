import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConfrontarDigitaisState } from './types';

export const INITIAL_STATE: IConfrontarDigitaisState = {
	qtdeFalha: 0,
	resultado: false,
	posicaoDedo: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IConfrontarDigitaisState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONFRONTAR_DIGITAIS_REQUEST: {
				draft.posicaoDedo = action.payload.posicaoDedo;
				break;
			}
			case Types.CONFRONTAR_DIGITAIS_SUCCESS: {
				draft.qtdeFalha = 0;
				draft.resultado = true;
				break;
			}
			case Types.CONFRONTAR_DIGITAIS_FAILURE: {
				draft.qtdeFalha += 1;
				draft.resultado = false;
				break;
			}
			case Types.CONFRONTAR_DIGITAIS_CLEAR: {
				draft.qtdeFalha = INITIAL_STATE.qtdeFalha;
				draft.resultado = INITIAL_STATE.resultado;
				draft.posicaoDedo = INITIAL_STATE.posicaoDedo;
				break;
			}

			default:
		}
		return draft;
	});
}
