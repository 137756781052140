import { combineReducers } from 'redux';

import documentoInfracao from './documentoInfracao/reducer';
import infracoes from './infracoes/reducer';
import protocolarRecurso from './protocolarRecurso/reducer';
import transferirPontuacao from './transferirPontuacao/reducer';

export default combineReducers({
	documentoInfracao,
	infracoes,
	protocolarRecurso,
	transferirPontuacao,
});
