import React, { useCallback, useState } from 'react';

import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultarVeiculoV110Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import { emitirTransferenciasMunicipiosEstadosClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirTransferenciasMunicipiosEstados/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import PDFViewer from 'components/Common/PDFViewer';
import { ROUTE_DETRAN_CRV } from 'routes/paths';

const ResultadoTransferenciaMunicipioEstado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [showPrint, setShowPrint] = useState<boolean>(false);

	const { emitirTransferenciasMunicipiosEstados } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const handleButtonVoltar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());
		dispatch(emitirTransferenciasMunicipiosEstadosClear());

		history.push(ROUTE_DETRAN_CRV);
	}, [dispatch, history]);

	return (
		<>
			{emitirTransferenciasMunicipiosEstados.data && (
				<>
					<Row>
						<Col span={24}>
							<Section
								size="lg"
								title="Resultado da Transferência"
								titleSize="sm"
							>
								<Row>
									<Col span={22} offset={2}>
										<Row gutter={[0, 5]}>
											<Col span={4}>Número da Ficha:</Col>

											<Col>
												<b>
													{
														emitirTransferenciasMunicipiosEstados.data
															.numeroficha
													}
												</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={4}>Ano da Ficha:</Col>

											<Col>
												<b>
													{emitirTransferenciasMunicipiosEstados.data.anoFicha}
												</b>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row gutter={[0, 20]} className="no-print">
									<Col span={24} />
								</Row>

								<Row justify="end" className="no-print">
									<Col>
										<ButtonImage
											src="imprimir"
											onClick={() => setShowPrint(true)}
										/>
									</Col>
								</Row>
							</Section>

							{showPrint && (
								<PDFViewer
									title="Impressão Transferência Município / Estado"
									source={
										emitirTransferenciasMunicipiosEstados.data.planilhaRenavam
									}
									onClose={() => setShowPrint(false)}
								/>
							)}

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default ResultadoTransferenciaMunicipioEstado;
