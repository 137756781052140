// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaAtendimentosRequest, Types } from './types';

export function consultaAtendimentosRequest(
	payload: ConsultaAtendimentosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTOS_REQUEST,
		payload,
	};
}
export function consultaAtendimentosSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTOS_SUCCESS,
		payload,
	};
}
export function consultaAtendimentosFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTOS_FAILURE,
		payload,
	};
}
export function consultaAtendimentosClear(): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTOS_CLEAR,
		payload: null,
	};
}
