import produce from 'immer';
import { Types, ServicosPrefeiturasState } from './types';
import { ReducerAction } from '../../../types';

export const INITIAL_STATE: ServicosPrefeiturasState = {
	servicosPrefeiturasData: {},
};

export default function servicosPrefeituras(
	state = INITIAL_STATE,
	action: ReducerAction,
): ServicosPrefeiturasState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES: {
				break;
			}
			case Types.CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_SUCCESS: {
				draft.servicosPrefeiturasData = action.payload.data;
				break;
			}
			case Types.CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_FAILURE: {
				draft.servicosPrefeiturasData = action.payload;
				break;
			}

			case Types.REMOVE_PREFEITURAS_SERVICOS: {
				draft.servicosPrefeiturasData = {};
				break;
			}
			default:
		}
	});
}
