import { Types } from './types';

export function getECpfTokenRequest(payload: string) {
	return {
		type: Types.GET_E_CPF_TOKEN_REQUEST,
		payload,
	};
}
export function getECpfTokenSuccess(payload: object) {
	return {
		type: Types.GET_E_CPF_TOKEN_SUCCESS,
		payload,
	};
}
export function getECpfTokenFailure(payload: []): any {
	return {
		type: Types.GET_E_CPF_TOKEN_FAILURE,
		payload,
	};
}
export function getECpfTokenClear() {
	return {
		type: Types.GET_E_CPF_TOKEN_CLEAR,
	};
}
