// TYPES
import { ReducerAction } from 'store/modules/types';
import { CadastroConsumidorRequest, Types } from './types';

export function cadastroConsumidorRequest(
	payload: CadastroConsumidorRequest,
): ReducerAction {
	return {
		type: Types.CADASTRO_CONSUMIDOR_REQUEST,
		payload,
	};
}
export function cadastroConsumidorSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRO_CONSUMIDOR_SUCCESS,
		payload,
	};
}
export function cadastroConsumidorFailure(payload: object): ReducerAction {
	return {
		type: Types.CADASTRO_CONSUMIDOR_FAILURE,
		payload,
	};
}
export function cadastroConsumidorClear(): ReducerAction {
	return {
		type: Types.CADASTRO_CONSUMIDOR_CLEAR,
		payload: null,
	};
}
