import React, { Suspense, useEffect } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import Select from 'components/Common/Form/Select';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';

import { consultarBaseOutrosEstadosRequest } from 'store/modules/api/detranCrv/ConsultarVeiculos/consultarBaseOutrosEstados/actions';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { initialValues, schema, treatValues } from './form';
import dataEnum from '../../../../store/modules/api/detranCrv/enum';

const ConsultarBaseOutrosEstados: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { consultarBaseOutrosEstados } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarVeiculos,
	);

	useEffect(() => {
		if (consultarBaseOutrosEstados.status === 200) {
			history.push('/detran-crv/consultar-base-outros-estados/resultado');
		}
	}, [history, consultarBaseOutrosEstados]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						const formatValuesReq = treatValues(values);
						dispatch(consultarBaseOutrosEstadosRequest(formatValuesReq));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[20, 20]} style={{ marginTop: '20px' }}>
									<Col span={12}>
										<Field
											size={60}
											maxLength={22}
											name="chassi"
											title="Chassi"
											as={Input}
											error={hasError(formik.errors, 'chassi')}
										/>
									</Col>
									<Col span={12}>
										<Field
											as={Select}
											size={60}
											defaultFirstOption
											name="uf"
											title="UF"
											subtitleColor="red"
											options={dataEnum.uf}
											error={!!formik.errors.uf}
											// error={hasError(formik.errors, 'chassi')}
											onChange={(v: string) => formik.setFieldValue('uf', v)}
										/>
									</Col>
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={formik.handleReset}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route="/detran-crv" />
		</Suspense>
	);
};

export default ConsultarBaseOutrosEstados;
