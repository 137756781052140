import React from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

import data from 'store/modules/api/mp/enum';

import FormBox from 'components/Common/Form/FormBox';
import Input from 'components/Common/Form/Input/Input';

import Radio from 'components/Common/Form/Radio';
import hasError from 'utils/getFormErrors';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import InputMask from 'components/Common/Form/Input/InputMask';
import { onlyLetters } from 'utils/genericFunctions';
import DadosSolicitante from '../DadosSolicitante';
import { ICadastroSolicitante } from '../form';

interface Props {
	formik: FormikProps<ICadastroSolicitante>;
	step: number;
	formDisabled: boolean;
}

const DadosGenitor: React.FC<Props> = ({ formik, step, formDisabled }) => {
	return (
		<>
			<FormBox
				title={`Dados do Genitor ${
					formik.values.tipoSolicitante === 3 ? ' - Solicitante' : ''
				}`}
			>
				<Row gutter={[0, 10]}>
					<Col span={18}>
						<Field
							required
							maxLength={60}
							value={onlyLetters(formik.values.filho.genitor.nome)}
							as={Input}
							title="Nome Completo"
							name="filho.genitor.nome"
							error={hasError(formik.errors, 'filho.genitor.nome')}
							readOnly={step === 2}
							disabled={formDisabled}
						/>
					</Col>
				</Row>

				{/* <Row gutter={[0, 10]}>
					<Col span={8}>
						{step === 1 && (
							<Field
								required
								as={Radio}
								options={data.sexo}
								title="Sexo *"
								name="filho.genitor.sexo"
								error={hasError(formik.errors, 'filho.genitor.sexo')}
							/>
						)}
						{step === 2 && (
							<Field
								required
								as={Input}
								title="Sexo *"
								name="filho.genitor.sexo"
								value={
									formik.values.filho.genitor.sexo === 'M'
										? 'MASCULINO'
										: 'FEMININO'
								}
								readOnly={step === 2}
							/>
						)}
					</Col>
				</Row> */}

				<Row gutter={[0, 10]}>
					{formik.values.tipoSolicitante === 3 && (
						<Col span={10}>
							<Field
								required
								title="Data de Nascimento"
								name="filho.genitor.dtNascimento"
								type="text"
								mask="99/99/9999"
								subtitle={step === 2 ? '' : '(DD/MM/AAAA)'}
								size={60}
								as={ValidDataInput}
								error={hasError(formik.errors, 'filho.genitor.dtNascimento')}
								formik={formik}
								onChange={(v: string) =>
									formik.setFieldValue('filho.genitor.dtNascimento', v)
								}
								readOnly={step === 2}
								disabled={formDisabled}
							/>
						</Col>
					)}
					<Col span={6}>
						{step === 1 && (
							<Field
								required
								as={Radio}
								titleSize={formik.values.tipoSolicitante === 3 ? 'sm' : 'md'}
								options={data.sexo}
								title="Sexo"
								defaultValue={formik.values.filho.genitor.sexo}
								name="filho.genitor.sexo"
								error={hasError(formik.errors, 'filho.genitor.sexo')}
								disabled={formDisabled}
							/>
						)}
						{step === 2 && (
							<Field
								required
								as={Input}
								title="Sexo"
								titleSize="sm"
								name="filho.genitor.sexo"
								value={
									formik.values.filho.genitor.sexo === 'M'
										? 'MASCULINO'
										: 'FEMININO'
								}
								readOnly={step === 2}
								disabled={formDisabled}
							/>
						)}
					</Col>
					{formik.values.tipoSolicitante === 3 && (
						<Col span={8}>
							<Field
								title="CPF"
								name="filho.genitor.cpf"
								as={InputMask}
								mask="999.999.999-99"
								error={hasError(formik.errors, 'filho.genitor.cpf')}
								readOnly={step === 2}
								required
								disabled={formDisabled}
							/>
						</Col>
					)}
				</Row>

				{formik.values.tipoSolicitante === 3 && (
					<DadosSolicitante
						formDisabled={formDisabled}
						formik={formik}
						step={step}
						solicitante="filho.genitor"
					/>
				)}
			</FormBox>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default DadosGenitor;
