import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col, Skeleton, Pagination } from 'antd';

// ROUTES
import { useHistory, Link } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';
import {
	ROUTE_AAC_PAINEL_CONTROLE,
	ROUTE_AAC_PAINEL_RESULTADO,
	ROUTE_AAC_PESQUISA_ANTECEDENTES_PAINEL,
	ROUTE_AAC_RESULTADO_SITUACAO_PAINEL,
} from 'pages/iirgd/Aac/routes/paths';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultaSituacaoAtualRequest,
	limpaMensagemRetorno,
} from 'store/modules/api/aac/painelDeControle/actions';
import {
	atualizarAtendenteRequest,
	consultaUsuarioPendenteRequest,
} from 'store/modules/api/aac/novoPainelDeControle/consultaUsuarioPendente/actions';
import {
	ConsultaUsuarioPendente,
	UsuarioPendente,
} from 'store/modules/api/aac/novoPainelDeControle/consultaUsuarioPendente/types';

// COMPONENTS
import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
import EmptyData from 'components/Common/Empty';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Divider from 'components/Common/Divider';
import { encerrarAtendimentoClear } from 'store/modules/api/aac/novoPainelDeControle/encerrarAtendimento/actions';
import { emissaoAtestadoLiberacaoPositivaClear } from 'store/modules/api/aac/novoPainelDeControle/emissaoAtestadoLiberacaoPositiva/actions';
import { emissaoAtestadoPainelClear } from 'store/modules/api/aac/novoPainelDeControle/emissaoAtestadoNormal/actions';
import { emissaoExigenciasClear } from 'store/modules/api/aac/novoPainelDeControle/emissaoExigencias/actions';

import DadosRg from './components/DadosRg';

// STYLED
import {
	Container,
	ButtonLink,
	ContainerComponent,
	PaginationContainer,
} from './styled';
import NavMenu from '../components/NavMenu';

const PainelDeControle: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [localPageSize] = useState<number>(10);

	const [data, setData] = useState<UsuarioPendente>({
		status: 200,
		resultado: [],
	});

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { usuarioPendente, painelDeControle, encerrarAtendimento } =
		useSelector((state: ApplicationState) => state.api.aac);

	useEffect(() => {
		if (encerrarAtendimento.status === 200) {
			dispatch(encerrarAtendimentoClear());
			dispatch(
				consultaUsuarioPendenteRequest({
					identifUsuario: login.mainframe.idUsuario,
					numeroPosto: login.user.posto,
				}),
			);
		}
	}, [
		dispatch,
		encerrarAtendimento.status,
		login.mainframe.idUsuario,
		login.user.posto,
	]);

	const handlePagination = useCallback(
		(page: number, pageSize?: number | undefined) => {
			let results: ConsultaUsuarioPendente[] = [];
			if (usuarioPendente.resultado) {
				const paginaInicial = 0 + (pageSize || 1) * (page - 1);
				const paginaFinal = 0 + (pageSize || 1) * page;
				const resultados = [...usuarioPendente.resultado];
				results = resultados.slice(paginaInicial, paginaFinal);
			}
			const newData: UsuarioPendente = {
				status: usuarioPendente.status,
				resultado: results,
			};
			setData(newData);
		},
		[usuarioPendente],
	);

	useEffect(() => {
		handlePagination(1, localPageSize);
		dispatch(emissaoAtestadoLiberacaoPositivaClear());
		dispatch(emissaoAtestadoPainelClear());
		dispatch(emissaoExigenciasClear());
	}, [dispatch, handlePagination, localPageSize]);

	useEffect(() => {
		if (painelDeControle.situacaoAtual.status !== 0) {
			history.push(ROUTE_AAC_RESULTADO_SITUACAO_PAINEL);
		}
	}, [history, painelDeControle]);

	const handleClickSituacaoAtual = useCallback(() => {
		dispatch(consultaSituacaoAtualRequest({ numeroPosto: login.user.posto }));
	}, [dispatch, login]);

	const handleClickResultado = (pesquisado: ConsultaUsuarioPendente) => {
		dispatch(limpaMensagemRetorno());

		const filtered = usuarioPendente.resultado?.findIndex(
			(item: ConsultaUsuarioPendente) => item.id === pesquisado.id,
		);

		history.push(
			`${ROUTE_AAC_PAINEL_CONTROLE}/${filtered}/${ROUTE_AAC_PAINEL_RESULTADO}`,
		);
	};

	const handleClickAtualizar = useCallback(() => {
		dispatch(
			atualizarAtendenteRequest(
				{
					identifUsuario: login.mainframe.idUsuario,
					numeroPosto: login.user.posto,
				},
				data?.resultado ? data.resultado : [],
			),
		);
	}, [data, dispatch, login.mainframe.idUsuario, login.user.posto]);

	return (
		<>
			<Row>
				<Col span={24}>
					<NavMenu />

					<Divider mtSize={15} mbSize={15} />
				</Col>
			</Row>
			<Section title="Painel de Controle">
				{data.status === 0 ? (
					<>
						<Section>
							<Skeleton active />
						</Section>

						<Section>
							<Skeleton active />
						</Section>
					</>
				) : (
					<>
						<Container>
							<strong>
								<Link
									to={ROUTE_AAC_PESQUISA_ANTECEDENTES_PAINEL}
									onClick={handleClickSituacaoAtual}
								>
									[Exibe situação atual e pendente de até 5 dias anteriores]
								</Link>
							</strong>
						</Container>
						{data.resultado &&
							data.resultado.length > 0 &&
							data.resultado.map((item: ConsultaUsuarioPendente) => (
								<Row key={item.id} gutter={[0, 0]}>
									<Col span={24}>
										<Section>
											<ContainerComponent>
												<DadosRg data={item} />

												<ButtonLink>
													<Button
														type="button"
														onClick={() => handleClickResultado(item)}
													>
														Resultado
													</Button>
												</ButtonLink>
											</ContainerComponent>
										</Section>
									</Col>
								</Row>
							))}

						<PaginationContainer>
							{data.resultado &&
								data.resultado.length &&
								usuarioPendente.resultado && (
									<Pagination
										onChange={handlePagination}
										showSizeChanger
										defaultCurrent={1}
										total={usuarioPendente.resultado.length}
									/>
								)}
						</PaginationContainer>

						<Container>
							<Col span={24} offset={1} />
						</Container>
						{data.status === 400 && (
							<EmptyData
								description="Serviço indisponível, tente novamente em alguns intantes."
								image="PRESENTED_IMAGE_DEFAULT"
							/>
						)}
						{data.resultado &&
							data.resultado.length === 0 &&
							data.status === 200 && (
								<EmptyData
									description="Nenhum retorno até o momento."
									image="PRESENTED_IMAGE_SIMPLE"
								/>
							)}
						<Button type="button" onClick={handleClickAtualizar}>
							Atualizar
						</Button>
					</>
				)}
			</Section>

			<ButtonVoltar route={ROUTE_IIRGD} />
		</>
	);
};

export default PainelDeControle;
