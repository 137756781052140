import { GerarRenachRequest } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { AtendimentoState } from 'store/modules/api/sgu/atendimento/types';
import { ILogin } from 'store/modules/api/sgu/loginUnico/types';
import { PreCadastroCidadaoRequest } from 'store/modules/api/sguService/preCadastroCidadao/types';
import { limparMascara } from 'utils/genericFunctions';

export function formatarPreCadastro(
	atendimento: AtendimentoState,
	login: ILogin,
	userName: string,
	cadastro: GerarRenachRequest,
): PreCadastroCidadaoRequest {
	const cpf = login.user.cpf ? limparMascara(login.user.cpf) : '';

	return {
		evento: {
			id_atendimento: atendimento.salvarAtendimento.data?.idAtendimento ?? '',
			id_cidadao: atendimento.salvarAtendimento.data?.idCidadao ?? '',
			cpf: cadastro.cpf,
			identificacao: cpf,
			ip: login.user.ip,
			canal: {
				id: 1,
				desc: 'Presencial',
				estacao: {
					id: 0,
					desc: '',
				},
				localidade: {
					id: Number(login.user.posto),
					desc: login.user.nomePosto,
				},
			},
			atendente: {
				nome: userName,
				id_atendente: '',
				id_posto: login.user.posto,
				desc_posto: login.user.nomePosto,
			},
		},
		rg: '',
		ufRg: null,
		cpf: cadastro.cpf,
		cns: '',
		nome:
			cadastro.nomeSocial && cadastro.nomeSocial !== ''
				? cadastro.nomeSocial
				: cadastro.nome,
		firstName:
			cadastro.nomeSocial && cadastro.nomeSocial !== ''
				? cadastro.nomeSocial.split(' ')[0]
				: cadastro.nome.split(' ')[0],
		dataNascimento: cadastro.dataNascimento
			? new Date(cadastro.dataNascimento).getTime().toString()
			: '',
		nomeMae: cadastro.nomeMae ?? '',
		foneResidencialDdd: '',
		foneResidencial: '',
		foneComercialDdd: '',
		foneComercial: '',
		foneCelularDdd: cadastro.celularDDD,
		foneCelular: cadastro.celular || '',
		email: cadastro.email ?? '',
		autorizaEmail: false,
		autorizaSMS: false,
		// "P" FIXO
		origem: 'P',
		statusUpdateSenha: '',
		senha: null,
		businessCategory: '',
		// 10 COM COLETA/ 100 SEM  COLETA
		presencial:
			cadastro.usoFormularioEnum === 'PRIMEIRA_HABILITACAO' ? 100 : 10,
		sexo: cadastro.sexo ?? '',
		// 200 DETRAN
		orgao: 200,
		idCidadao: atendimento.salvarAtendimento.data?.idCidadao ?? '',
		idAtendimento: atendimento.salvarAtendimento.data?.idAtendimento ?? '',
		endereco: cadastro.logradouro ?? '',
		cep: cadastro.logradouroCep ?? '',
		enderecoUf: 'SP',
		enderecoBairro: cadastro.logradouroBairro ?? '',
		enderecoCidade: cadastro.logradouroCodMunicipio ?? '',
		enderecoNum: cadastro.logradouroNumero ?? '',
		enderecoCompl: cadastro.logradouroComplemento ?? '',
	};
}
