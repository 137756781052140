import produce from 'immer';

import { Types, EventoTerminoSessao } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: EventoTerminoSessao = {
	status: 0,
	data: null,
};

export default function eventoTerminoSessaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): EventoTerminoSessao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EVENTO_TERMINO_SESSAO_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.EVENTO_TERMINO_SESSAO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = null;
				break;
			}
			case Types.EVENTO_TERMINO_SESSAO_FAILURE: {
				draft.status = action.payload.data.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.EVENTO_TERMINO_SESSAO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
	});
}
