// import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_REQUEST = '@cnh/RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_REQUEST',
	RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_SUCCESS = '@cnh/RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_SUCCESS',
	RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_FAILURE = '@cnh/RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_FAILURE',
	RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_CLEAR = '@cnh/RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_CLEAR',
}

export interface relatorioAgendamentoMedicoPsicologico {
	status: number;
	data: null | RelatorioAgendamentoMedicoPsicologicoData;
}

interface RelatorioAgendamentoMedicoPsicologicoData {
	arquivoPDF: string;
}

export interface RelatorioAgendamentoMedicoPsicologicoRequest {
	categoriaPretendida: string;
	cpfCidadao: number | string;
	crm: number | string;
	crp: number | string;
	dataExame?: string | Date;
	dataExamePsicologo: string | Date;
	enderecoMedico?: string;
	enderecoPsicologo: string;
	horarioExame?: string | Date;
	horarioExamePsicologo: string | Date;
	nomeCidadao: string;
	nomeMedico?: string;
	nomePsicologo: string;
	telefones?: string;
	telefonesPsicologo: string;
	tipoProcesso: string;
	flagHabilitaAgendamentoMedico?: boolean;
	flagHabilitaAgendamentoPsicologico?: boolean;
}
