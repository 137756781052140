import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RecuperarAtendimento } from './types';

export const INITIAL_STATE: RecuperarAtendimento = {
	status: 0,
	data: null,
	messageError: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): RecuperarAtendimento {
	return produce(state, draft => {
		switch (action.type) {
			case Types.RECUPERAR_ATENDIMENTO_REQUEST: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.messageError = INITIAL_STATE.messageError;
				break;
			}
			case Types.RECUPERAR_ATENDIMENTO_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}
			case Types.RECUPERAR_ATENDIMENTO_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.messageError = action.payload.message;
				break;
			}
			case Types.RECUPERAR_ATENDIMENTO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.messageError = INITIAL_STATE.messageError;
				break;
			}

			default:
		}
		return draft;
	});
}
