import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarParametroLocalidadeRequest } from './types';

// ACTIONS
import {
	cadastrarParametroLocalidadeSuccess,
	cadastrarParametroLocalidadeFailure,
} from './actions';

function* cadastrarParametroLocalidade(request: ReducerAction) {
	const { payload }: { payload: CadastrarParametroLocalidadeRequest } = request;

	const body = {
		...payload,
	};

	delete body.id;

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU,
		`parametros-localidades`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarParametroLocalidadeSuccess(response));
	} else {
		yield put(cadastrarParametroLocalidadeFailure());
	}
}

export default all([
	takeLatest(
		Types.CADASTRAR_PARAMETRO_LOCALIDADE,
		cadastrarParametroLocalidade,
	),
]);
