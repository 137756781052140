import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export function consultaPaisesRequest(): ReducerAction {
	return {
		type: Types.CONSULTA_PAISES_REQUEST,
		payload: null,
	};
}
export function consultaPaisesSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_PAISES_SUCCESS,
		payload,
	};
}
export function consultaPaisesFailure(payload: boolean): ReducerAction {
	return {
		type: Types.CONSULTA_PAISES_FAILURE,
		payload,
	};
}
