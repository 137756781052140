import produce from 'immer';

// UTILS
import { formatDate } from 'utils/genericFunctions';

// TYPES
import { Types, consultaAdicaoCategoria } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaAdicaoCategoria = {
	status: 0,
	data: null,
};

export default function adicaoCategoria(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaAdicaoCategoria {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_ADICAO_CATEGORIA: {
				break;
			}

			case Types.CONSULTA_ADICAO_CATEGORIA_SUCCESS: {
				const adicao = { ...action.payload.data.dadosAdicao[0] };

				if (adicao.dataNascimento !== '') {
					adicao.dataNascimento = formatDate(adicao.dataNascimento);
				}

				draft.data = adicao;
				break;
			}

			case Types.CONSULTA_ADICAO_CATEGORIA_FAILURE: {
				break;
			}

			default:
		}
	});
}
