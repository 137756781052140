import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ApiResponse, postApi } from 'services/_api';
import { PATH_EDUCACAO } from 'services/_path';
import { Types } from './types';
import {
	consultarFichaAlunoSuccess,
	consultarFichaAlunoFailure,
} from './actions';

function* consultarFichaAlunoRequest({ payload }: any) {
	const response: ApiResponse = yield call(
		postApi,
		PATH_EDUCACAO,
		`aluno/ficha/consultar`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultarFichaAlunoSuccess(response));
	} else if (response.error) {
		yield put(consultarFichaAlunoFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_FICHA_ALUNO_REQUEST, consultarFichaAlunoRequest),
]);
