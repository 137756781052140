import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_UTILS } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import { enderecoIpSuccess, enderecoIpFailure } from './actions';

function* enderecoIpRequest() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_UTILS,
		`endereco-ip/${CHANNEL}/ip`,
		{},
		true,
	);

	if (response.status === 200) {
		yield put(enderecoIpSuccess(response.data));
	} else {
		yield put(enderecoIpFailure());
	}
}

export default all([takeLatest(Types.ENDERECO_IP_REQUEST, enderecoIpRequest)]);
