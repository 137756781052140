import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { toSelect } from 'utils/genericFunctions';
import { Types, ConsultarOrgao } from './types';

export const INITIAL_STATE: ConsultarOrgao = {
	status: 0,
	data: null,
	enum: [],
	totalRegistrosConsulta: 0,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarOrgao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_ORGAOS_REQUEST: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			case Types.CONSULTAR_ORGAOS_SUCCESS: {
				draft.enum = toSelect(action.payload.data, 'descricao', 'codigo');
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.totalRegistrosConsulta = action.payload.headers['x-total-count'];
				break;
			}

			case Types.CONSULTAR_ORGAOS_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTAR_ORGAOS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			default:
		}
		return draft;
	});
}
