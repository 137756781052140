import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CancelarSolicitacaoEmissaoPIDRequest } from './types';

// ACTIONS
import {
	cancelarSolicitacaoEmissaoPIDSuccess,
	cancelarSolicitacaoEmissaoPIDFailure,
} from './actions';

function* cancelarSolicitacaoEmissaoPID(request: ReducerAction) {
	const { payload }: { payload: CancelarSolicitacaoEmissaoPIDRequest } =
		request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`pid/${CHANNEL}/servico/cancelar-solicitacao`,
		payload,
	);

	if (response.status === 200) {
		yield put(
			cancelarSolicitacaoEmissaoPIDSuccess({ response, request: payload }),
		);
	} else {
		yield put(cancelarSolicitacaoEmissaoPIDFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CANCELAR_SOLICITACAO_EMISSAO_PID_REQUEST,
		cancelarSolicitacaoEmissaoPID,
	),
]);
