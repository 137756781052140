import { ReducerAction } from 'store/modules/types';

import { IRequestIncluirNotasFiscais, Types } from './types';

export function cadastraNotasFiscais(
	data: IRequestIncluirNotasFiscais,
): ReducerAction {
	return {
		type: Types.CADASTRA_NOTAS_FISCAIS,
		payload: data,
	};
}
export function cadastraNotasFiscaisSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRA_NOTAS_FISCAIS_SUCCESS,
		payload,
	};
}
export function cadastraNotasFiscaisFailure(): ReducerAction {
	return {
		type: Types.CADASTRA_NOTAS_FISCAIS_FAILURE,
		payload: null,
	};
}

export function clearRedirectNotasFiscaisVivaLeite(): ReducerAction {
	return {
		type: Types.REDIRECT_NOTAS_FISCAIS_VIVA_LEITE_CLEAR,
		payload: null,
	};
}

export function clearNotificationsNotasFiscaisVivaLeite(): ReducerAction {
	return {
		type: Types.NOTIFICATION_NOTAS_FISCAIS_VIVA_LEITE_CLEAR,
		payload: null,
	};
}
