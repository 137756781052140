// ENTITIES
import { IFormCadastroRg } from 'pages/iirgd/RgFicDigital/CadastroRg/form';
import { IConfirmacaoAtendimento } from 'pages/iirgd/RgFicDigital/CadastroRg/Modal/ConfirmacaoAtendimento/form';
import {
	IFormInclusaoObsPrimeiraViaMenor,
	IFormInclusaoObsPrimeiraViaMaior,
} from 'pages/iirgd/RgFicDigital/InclusaoObservacoes/Formularios/form';
import { IConsultarDare } from 'store/modules/api/dare/consultarDare/types';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ILogin } from 'store/modules/api/sgu/loginUnico/types';
import { Types } from './types';
import { IConsultaPesquisaObservacao } from '../consultaPesquisaObservacao/types';
import { IConsultaPesquisaCidadaoSelecionado } from '../consultaPesquisaCidadao/types';

export function cadastroRgConfigNovo(): ReducerAction {
	return {
		type: Types.CADASTRO_RG_CFG_NOVO,
		payload: null,
	};
}

export function cadastroRgConfigAcervo(): ReducerAction {
	return {
		type: Types.CADASTRO_RG_CFG_ACERVO,
		payload: null,
	};
}

export function cadastroRgConfigEdicao(): ReducerAction {
	return {
		type: Types.CADASTRO_RG_CFG_EDICAO,
		payload: null,
	};
}

export function cadastroRgConfigRedicao(): ReducerAction {
	return {
		type: Types.CADASTRO_RG_CFG_REEDICAO,
		payload: null,
	};
}

export function cadastroRgPrimeiraVia(): ReducerAction {
	return {
		type: Types.CADASTRO_RG_PRIMEIRA_VIA,
		payload: null,
	};
}

export function cadastroRgSegundaVia(): ReducerAction {
	return {
		type: Types.CADASTRO_RG_SEGUNDA_VIA,
		payload: null,
	};
}

export function cadastroRgDataNascimento(
	dataNascimento: string,
): ReducerAction {
	return {
		type: Types.CADASTRO_RG_DATA_NASCIMENTO,
		payload: dataNascimento,
	};
}

export function cadastroRgConfirmacaoAtendimento(
	payload: IConfirmacaoAtendimento,
): ReducerAction {
	return {
		type: Types.CADASTRO_RG_CONFIRMACAO_ATENDIMENTO,
		payload,
	};
}

export function cadastroRgOriginal(payload: IFormCadastroRg): ReducerAction {
	return {
		type: Types.CADASTRO_RG_ORIGINAL,
		payload,
	};
}

export function cadastrarRgRequest(
	formValues: IFormCadastroRg,
	login: ILogin,
): ReducerAction {
	return {
		type: Types.CADASTRAR_RG_REQUEST,
		payload: {
			formValues,
			login,
		},
	};
}

export function cadastrarRgSuccess(data: boolean): ReducerAction {
	return {
		type: Types.CADASTRAR_RG_SUCCESS,
		payload: data,
	};
}

export function cadastrarRgFailure(numRg: number | null): ReducerAction {
	return {
		type: Types.CADASTRAR_RG_FAILURE,
		payload: numRg,
	};
}

export function atualizarRgRequest(
	original: IFormCadastroRg | null,
	formValues: IFormCadastroRg,
	login: ILogin,
	isPrimeiraVia: boolean,
	consultaPesquisaCidadaoSelecionado: IConsultaPesquisaCidadaoSelecionado | null,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_RG_REQUEST,
		payload: {
			original,
			formValues,
			login,
			isPrimeiraVia,
			consultaPesquisaCidadaoSelecionado,
		},
	};
}

export function atualizarRgSuccess(data: boolean): ReducerAction {
	return {
		type: Types.ATUALIZAR_RG_SUCCESS,
		payload: data,
	};
}

export function atualizarLote(data: string): ReducerAction {
	return {
		type: Types.ATUALIZAR_LOTE_SUCCESS,
		payload: data,
	};
}

export function cadastroRgPrincipal(
	original: IFormCadastroRg | null,
	principal: IFormCadastroRg,
	consultaPesquisaCidadaoSelecionado: IConsultaPesquisaCidadaoSelecionado | null,
): ReducerAction {
	return {
		type: Types.CADASTRO_RG_PRINCIPAL,
		payload: {
			original,
			principal,
			consultaPesquisaCidadaoSelecionado,
		},
	};
}

export function cadastroRgObsPrimeiraViaMenor(
	data: IFormInclusaoObsPrimeiraViaMenor,
): ReducerAction {
	return {
		type: Types.CADASTRO_RG_OBS_PRIMEIRA_VIA_MENOR,
		payload: data,
	};
}

export function cadastroRgObsPrimeiraViaMaior(
	data: IFormInclusaoObsPrimeiraViaMaior,
): ReducerAction {
	return {
		type: Types.CADASTRO_RG_OBS_PRIMEIRA_VIA_MAIOR,
		payload: data,
	};
}

export function cadastroRgObsSegundaVia(
	data: IConsultaPesquisaObservacao,
): ReducerAction {
	return {
		type: Types.CADASTRO_RG_OBS_SEGUNDA_VIA,
		payload: data,
	};
}

export function cadastroRgVincularDare(payload: IConsultarDare): ReducerAction {
	return {
		type: Types.CADASTRO_RG_VINCULAR_DARE,
		payload,
	};
}

export function cadastroRgConfrontoDigital(data: boolean): ReducerAction {
	return {
		type: Types.CADASTRO_RG_CONFRONTO_DIGITAL,
		payload: data,
	};
}

export function cadastroRgFinalizado(data: boolean): ReducerAction {
	return {
		type: Types.CADASTRO_RG_FINALIZADO,
		payload: data,
	};
}

export function cadastroRgPortaria606(): ReducerAction {
	return {
		type: Types.CADASTRO_RG_PORTARIA_606,
		payload: null,
	};
}

export function cadastroRgPortaria606Clear(): ReducerAction {
	return {
		type: Types.CADASTRO_RG_PORTARIA_606_CLEAR,
		payload: null,
	};
}

export function cadastroRgClear(): ReducerAction {
	return {
		type: Types.CADASTRO_RG_CLEAR,
		payload: null,
	};
}
