import * as Yup from 'yup';

export interface IConsultarAutorizacaoPrevia {
	chassi: string;
	placa: string;
	dataInicio: string;
	dataFim: string;
}

export const initialValues: IConsultarAutorizacaoPrevia = {
	chassi: '',
	placa: '',
	dataInicio: '',
	dataFim: '',
};

export const schema = Yup.object<IConsultarAutorizacaoPrevia>().shape({
	chassi: Yup.string().required(`Campo Obrigatório: Chassi.`),
});
