import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD_REIMPRESSAO_PROTOCOLO } from 'pages/iirgd/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from 'store';
import { consultaReimpressaoFicRequest } from 'store/modules/api/iirgd/consultaReimpressaoFic/actions';

// COMPONENTS
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';
import CardReimpressao from 'components/Common/CardReimpressao';
import { limparMascara } from 'utils/genericFunctions';

const ReimpressaoProtocoloFic: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { consultaReimpressaoFic } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const [hasSubmit, setHasSubmit] = useState<boolean>(false);
	const [numeroRg, setNumeroRg] = useState<string>('');

	useEffect(() => {
		if (hasSubmit && consultaReimpressaoFic.status === 200) {
			history.push(ROUTE_IIRGD_REIMPRESSAO_PROTOCOLO);
		}
	}, [hasSubmit, consultaReimpressaoFic.status, history]);

	const handleChange = useCallback((change: ChangeEvent<HTMLInputElement>) => {
		const { value } = change.target;

		setNumeroRg(value);
	}, []);

	const handleClick = useCallback(() => {
		const numRg = numeroRg.split('-');
		if (
			(numeroRg && numeroRg.length === 10 && numRg[0] && numRg[1]) ||
			(numeroRg && numeroRg.length === 11 && numRg[0] && numRg[1])
		) {
			const { idUsuario, senhaMainFrame } = login.mainframe;
			// const { ip } = login.user;

			dispatch(
				consultaReimpressaoFicRequest({
					posto: {
						codigo: login.user.posto ? Number(login.user.posto) : 0,
						digito: login.user.digito_posto
							? Number(login.user.digito_posto)
							: 0,
						nome: login.user.nomePosto,
						usuario: idUsuario,
					},
					numeroRg: numRg[0],
					digitoRg: limparMascara(numRg[1]),
					senha: senhaMainFrame,
					// ip,
				}),
			);

			setHasSubmit(true);
		}
	}, [numeroRg, dispatch, login]);

	return (
		<CardReimpressao
			title="Reimprimir Protocolo (FIC)"
			description="Digite o RGE CIN com dígito para reimpressão:"
		>
			<Row justify="space-between">
				<Col>
					<InputMask
						name="numeroRg"
						mask="99999999-**"
						value={numeroRg}
						onChange={handleChange}
					/>
				</Col>
				<Col>
					<ButtonImage src="reimprimir" onClick={handleClick} />
				</Col>
			</Row>
		</CardReimpressao>
	);
};

export default ReimpressaoProtocoloFic;
