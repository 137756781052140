import React, { useContext, useEffect, useState } from 'react';
import { Field, FormikProps } from 'formik';
import { Col, Row } from 'antd';

// COMPONENTS
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import CheckBox from 'components/Common/Form/Checkbox';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import FormBox from 'components/Common/Form/FormBox';
import InputMask from 'components/Common/Form/Input/InputMask';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { comboPaisesRequest } from 'store/modules/api/educacaoUpdate/fichaAluno/comboPaises/actions';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// ENUM
import dataEnum from 'store/modules/api/educacaoUpdate/enum';

// FORM
import { IDadosPessoais, IVisualizarFicha } from '../form';
import { ContainerFichaCadastral } from '../styled';

interface Props {
	formik: FormikProps<IVisualizarFicha>;
	renderData?: IDadosPessoais;
}

const DadosPessoais: React.FC<Props> = ({ formik }) => {
	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [dataPaises, setDataPaises] = useState<
		Array<{ value: string; label: string }>
	>([]);
	const [dataMunicipios, setDataMunicipios] = useState<
		Array<{ value: string; label: string }>
	>([]);

	const { paises } = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.fichaAluno.comboPaises.data,
	);

	const { municipios } = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.fichaAluno.comboMunicipios.data,
	);

	useEffect(() => {
		const listaMunicipios: Array<{ value: string; label: string }> = [];
		municipios.map(item => {
			return listaMunicipios.push({
				value: item.descricao,
				label: item.descricao,
			});
		});
		setDataMunicipios(listaMunicipios);
	}, [municipios]);

	useEffect(() => {
		const listaPaises: Array<{ value: string; label: string }> = [];
		paises.map((item: { codigo: string; descricao: string }) => {
			return listaPaises.push({
				value: item.codigo,
				label: item.descricao,
			});
		});
		setDataPaises(listaPaises);
	}, [paises]);

	useEffect(() => {
		if (paises.length < 1) {
			dispatch(comboPaisesRequest(token));
		}
	}, [dispatch, paises, token]);

	return (
		<ContainerFichaCadastral>
			<FormBox title="Dados Pessoais">
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Nome"
							name="dadosPessoais.nomeAluno"
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Nome Social (DECR.55588/10)"
							name="dadosPessoais.nomeSocial"
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Nome Afetivo (Lei Nº 16.785/18)"
							name="dadosPessoais.nomeAfetivo"
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={8}>
						<Field
							as={Select}
							title="Sexo"
							name="dadosPessoais.sexo"
							options={dataEnum.codigoSexo}
							disabled
						/>
					</Col>

					<Col span={7}>
						<Field
							as={Select}
							title="Raça/Cor"
							name="dadosPessoais.descricaoCorRaca"
							options={dataEnum.corRaca}
							disabled
						/>
					</Col>

					<Col offset={1} span={8}>
						<Field
							title="Data de Nascimento"
							name="dadosPessoais.dataNascimento"
							type="text"
							mask="99/99/9999"
							size={60}
							as={ValidDataInput}
							formik={formik}
							titleAlign="end"
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Email"
							name="dadosPessoais.email"
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Nome Mãe"
							name="dadosPessoais.nomeMae"
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Nome Pai"
							name="dadosPessoais.nomePai"
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<Field
							as={CheckBox}
							subtitle="Participa do Programa Bolsa Família:"
							name="dadosPessoais.codigoBolsaFamilia"
							order="left"
							checked={formik.values.dadosPessoais.codigoBolsaFamilia}
							disabled
						/>
					</Col>
				</Row>
				{formik.values.dadosPessoais.numeroRA !== '' && (
					<Row gutter={[0, 10]}>
						<Col span={8}>
							<Field
								as={Input}
								title="RA"
								name=""
								style={{ fontWeight: 'bold' }}
								value={`${formik.values.dadosPessoais.numeroRA}-${formik.values.dadosPessoais.digitoRA}/${formik.values.dadosPessoais.siglaUFRA}`}
								readOnly
							/>
						</Col>
					</Row>
				)}
				<Row gutter={[0, 20]}>
					<Col span={8}>
						<Field
							as={InputMask}
							title="Código Inep"
							name="dadosPessoais.codigoINEP"
							mask="999999999999"
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<Field
							as={Select}
							title="Nacionalidade"
							name="dadosPessoais.descricaoNacionalidade"
							options={dataEnum.nacionalidade}
							disabled
						/>
					</Col>
				</Row>

				<Row gutter={[0, 20]}>
					<Col span={8}>
						<Field
							as={Select}
							title="UF de Nascimento"
							name="dadosPessoais.ufMunicipioNascimento"
							options={dataEnum.comboUF}
							disabled
						/>
					</Col>
					<Col span={16}>
						<Field
							as={Select}
							title="Município de Nascimento"
							name="dadosPessoais.nomeMunicipioNascimento"
							options={dataMunicipios}
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<Field
							as={Select}
							title="País de Origem"
							name="dadosPessoais.nomePaisOrigem"
							options={dataPaises}
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<Field
							as={CheckBox}
							subtitle="Quilombola:"
							name="dadosPessoais.quilombola"
							checked={formik.values.dadosPessoais.quilombola}
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<Field
							as={CheckBox}
							subtitle="Possui Internet:"
							name="dadosPessoais.possuiInternet"
							checked={formik.values.dadosPessoais.possuiInternet}
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<Field
							as={CheckBox}
							subtitle="Possui Notebook, Smartphone, Tablet:"
							name="dadosPessoais.possuiNotebookSmartphoneTablet"
							checked={
								formik.values.dadosPessoais.possuiNotebookSmartphoneTablet
							}
							disabled
						/>
					</Col>
				</Row>
			</FormBox>
		</ContainerFichaCadastral>
	);
};

export default DadosPessoais;
