// ROUTES
import { ROUTE_IIRGD } from 'routes/paths';

const PESQUISA_ANTECEDENTES = `${ROUTE_IIRGD}/pesquisa-antecedentes`;

const NUMERAL = `${PESQUISA_ANTECEDENTES}/numeral`;
const NOMINAL = `${PESQUISA_ANTECEDENTES}/nominal`;
const CENTRAL = `${PESQUISA_ANTECEDENTES}/central`;
const PAINEL = `${PESQUISA_ANTECEDENTES}/painel-de-controle`;

export const ROUTE_IIRGD_PESQUISA_ANTECEDENTE = `${ROUTE_IIRGD}/pesquisa-antecedentes`;

export const ROUTE_AAC_PESQUISA_CENTRAL_NUMERAL = `${CENTRAL}/numeral/pesquisa-antecedentes`;
export const ROUTE_AAC_PESQUISA_ANTECEDENTES_NUMERAL = `${NUMERAL}/pesquisa-antecedentes`;
export const ROUTE_AAC_RESULTADO_NUMERAL = `${NUMERAL}/resultado/pesquisa-antecedentes`;

export const ROUTE_AAC_PESQUISA_CENTRAL_NOMINAL = `${CENTRAL}/nominal/pesquisa-antecedentes`;
export const ROUTE_AAC_PESQUISA_ANTECEDENTES_NOMINAL = `${NOMINAL}/pesquisa-antecedentes`;
export const ROUTE_AAC_RESULTADO_NOMINAL = `${NOMINAL}/resultado/pesquisa-antecedentes`;

export const ROUTE_AAC_PESQUISA_ANTECEDENTES_CENTRAL = `${CENTRAL}/:pesquisa/pesquisa-antecedentes`;

export const ROUTE_AAC_PAINEL_CONTROLE = PAINEL;
export const ROUTE_AAC_PAINEL_RESULTADO = 'resultado/pesquisa-antecedentes';

export const ROUTE_AAC_PESQUISA_ANTECEDENTES_PAINEL = `${PAINEL}/pesquisa-antecedentes`;
export const ROUTE_AAC_RESULTADO_PAINEL = `${PAINEL}/:id/resultado/pesquisa-antecedentes`;
export const ROUTE_AAC_RESULTADO_SITUACAO_PAINEL = `${PAINEL}/situacao-atual/pesquisa-antecedentes`;
