import { ReducerAction } from 'store/modules/types';

import { Types, RequestConsultaEPortal } from './types';

export function consultaEPortalRequest(
	data: RequestConsultaEPortal,
): ReducerAction {
	return {
		type: Types.CONSULTA_E_PORTAL_REQUEST,
		payload: data,
	};
}
export function consultaEPortalSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_E_PORTAL_SUCCESS,
		payload,
	};
}
export function consultaEPortalFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_E_PORTAL_FAILURE,
		payload,
	};
}
export function consultaEPortalClear(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_E_PORTAL_CLEAR,
		payload,
	};
}
