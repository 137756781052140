import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarMutuario } from './types';

export const INITIAL_STATE: ConsultarMutuario = {
	status: 0,
	data: null,
	form: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarMutuario {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_MUTUARIO_REQUEST: {
				if (action.payload.cpf) {
					draft.form = {
						cpf: action.payload.cpf,
						numContrato: '',
					};
				} else {
					draft.form = {
						cpf: '',
						numContrato: action.payload.numeroContrato,
					};
				}
				draft.status = 100;
				draft.data = INITIAL_STATE.data;

				break;
			}
			case Types.CONSULTAR_MUTUARIO_SUCCESS: {
				draft.status = action.payload?.status ?? 200;
				draft.data = action.payload;
				break;
			}
			case Types.CONSULTAR_MUTUARIO_FAILURE: {
				draft.status = action.payload?.data?.status ?? 400;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CONSULTAR_MUTUARIO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.form = INITIAL_STATE.form;
				break;
			}

			default:
		}

		return draft;
	});
}
