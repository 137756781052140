// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaInteracoesAtendimentoRequest, Types } from './types';

export function consultaInteracoesAtendimentoRequest(
	payload: ConsultaInteracoesAtendimentoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_INTERACOES_ATENDIMENTO_REQUEST,
		payload,
	};
}
export function consultaInteracoesAtendimentoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_INTERACOES_ATENDIMENTO_SUCCESS,
		payload,
	};
}
export function consultaInteracoesAtendimentoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_INTERACOES_ATENDIMENTO_FAILURE,
		payload,
	};
}
export function consultaInteracoesAtendimentoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_INTERACOES_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
