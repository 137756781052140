import { takeLatest, call, put, all, takeEvery } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi, postApi } from 'services/_sgu';
import { CHANNEL } from 'services/_path';

// TYPES
import { ReducerAction } from '../types';
import { Types } from './types';

// ACTIONS
import {
	pesquisaNaturalidadeSuccess,
	pesquisaNaturalidadeFailure,
	listaCepSuccess,
	listaCepFailure,
	consultaMainframeSuccess,
	consultaMainframeFailure,
} from './actions';

interface IRequestPesquisaNaturalidade {
	canal: string;
	registroInicial?: number;
	limite?: number;
	id?: number;
	descricao?: string;
	idIbge?: number;
	ufIbge: string;
}

interface IRequestListaCep {
	uf: string;
	cidade: string;
	logradouro: string;
	numero?: string | number;
}

interface IRequestConsultaMainframe {
	cpf: string;
	codigoLocalidade: string;
}

function* pesquisaNaturalidadeRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestPesquisaNaturalidade } = request;

	const response: ApiResponse = yield call(
		getApi,
		`domain-service/${CHANNEL}/naturalidade`,
		payload,
	);

	if (
		response.status === 200 ||
		response.status === 201 ||
		response.status === 204
	) {
		yield put(pesquisaNaturalidadeSuccess(response.data));
	} else if (response.error) {
		yield put(pesquisaNaturalidadeFailure(response.message));
	}
}

function* listaCep(request: ReducerAction) {
	const { payload }: { payload: IRequestListaCep } = request;

	const response: ApiResponse = yield call(
		getApi,
		'admin/endereco/lista-cep',
		payload,
	);

	if (response.status === 202) {
		yield put(listaCepSuccess(response.data));
	} else if (response.error) {
		yield put(listaCepFailure(response.message));
	}
}

function* consultaMainframe(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaMainframe } = request;
	const { cpf, codigoLocalidade } = payload;

	const response: ApiResponse = yield call(
		postApi,
		`usuario/${CHANNEL}/busca/usuario-mainframe`,
		{
			cpf,
			idLocalidade: codigoLocalidade,
		},
	);

	if (response.status === 200) {
		yield put(consultaMainframeSuccess({ response, request: payload }));
	} else if (response.error) {
		yield put(consultaMainframeFailure(response.message));
	}
}

export default all([
	takeLatest(Types.PESQUISA_NATURALIDADE_REQUEST, pesquisaNaturalidadeRequest),
	takeLatest(Types.LISTA_CEP, listaCep),
	takeEvery(Types.CONSULTA_MAINFRAME, consultaMainframe),
]);
