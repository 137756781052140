import { ReducerAction, ReducerActionRotaSP } from 'store/modules/types';

import { Types, ConsultaAlunoRequest } from './types';

export function consultaAlunoRequest(
	token: string,
	data: ConsultaAlunoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_ALUNO_REQUEST,
		token,
		payload: data,
	};
}

export function consultaAlunoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_ALUNO_SUCCESS,
		payload,
	};
}

export function consultaAlunoFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_ALUNO_FAILURE,
		payload: null,
	};
}

export function consultaAlunoClear() {
	return {
		type: Types.CONSULTA_ALUNO_CLEAR,
		payload: null,
	};
}
