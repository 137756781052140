import produce from 'immer';

// import { formatDate } from 'utils/genericFunctions';
import { Types, validaTokenCnhDigital } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: validaTokenCnhDigital = {
	status: 0,
	data: null,
};

export default function validaTokenCnhDigitalReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): validaTokenCnhDigital {
	return produce(state, draft => {
		switch (action.type) {
			case Types.VALIDA_TOKEN_CNH_DIGITAL_REQUEST: {
				break;
			}
			case Types.VALIDA_TOKEN_CNH_DIGITAL_SUCCESS: {
				draft.data = action.payload.response.data;
				draft.status = action.payload.response.status;
				break;
			}
			case Types.VALIDA_TOKEN_CNH_DIGITAL_FAILURE: {
				draft.data = action.payload.data;
				break;
			}
			case Types.VALIDA_TOKEN_CNH_DIGITAL_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				break;
			}
			default:
		}
	});
}
