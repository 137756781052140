import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirMensagemErroRequest } from './types';

export function excluirMensagemErroRequest(
	data: ExcluirMensagemErroRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_MENSAGEM_ERRO_REQUEST,
		payload: data,
	};
}
export function excluirMensagemErroSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_MENSAGEM_ERRO_SUCCESS,
		payload,
	};
}
export function excluirMensagemErroFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_MENSAGEM_ERRO_FAILURE,
		payload: null,
	};
}
export function excluirMensagemErroClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_MENSAGEM_ERRO_CLEAR,
		payload: null,
	};
}
