import { ApiResponse } from 'services/_api';
import { ReducerAction } from 'store/modules/types';

import { GeraSenhaRequest, Types } from './types';

export function geraSenhaRequest(data: GeraSenhaRequest): ReducerAction {
	return {
		type: Types.GERA_SENHA_REQUEST,
		payload: data,
	};
}
export function geraSenhaSuccess(payload: ApiResponse): ReducerAction {
	return {
		type: Types.GERA_SENHA_SUCCESS,
		payload,
	};
}
export function geraSenhaFailure(payload: string): ReducerAction {
	return {
		type: Types.GERA_SENHA_FAILURE,
		payload,
	};
}
export function geraSenhaClear(): { type: string } {
	return {
		type: Types.GERA_SENHA_CLEAR,
	};
}
