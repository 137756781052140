import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../types';
import { Types, BiometriaState } from './types';

import capturarBiometria from './capturarBiometria/reducer';
import verificarVersaoServicoBiometria from './verificarVersaoServicoBiometria/reducer';

const allReducers = combineReducers({
	capturarBiometria,
	verificarVersaoServicoBiometria,
});

const combineReducer = (state: BiometriaState, action: ReducerAction) => {
	if (action.type === Types.BIOMETRIA_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
