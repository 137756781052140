import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_ENTIDADE_MILITAR_REQUEST = '@cnh/CONSULTA_ENTIDADE_MILITAR_REQUEST',
	CONSULTA_ENTIDADE_MILITAR_SUCCESS = '@cnh/CONSULTA_ENTIDADE_MILITAR_SUCCESS',
	CONSULTA_ENTIDADE_MILITAR_FAILURE = '@cnh/CONSULTA_ENTIDADE_MILITAR_FAILURE',
}

export interface ConsultaEntidadeMilitar {
	status: number;
	data: null | OptionProps[];
}
