import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 20px 0 20px 0;
`;

export const ButtonLink = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 50px;

	a {
		color: #000000;
	}
`;
export const ContainerComponent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const PaginationContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`;
