import { ConsultaPconRequest, Types } from './types';

export function consultaPconRequest(payload: ConsultaPconRequest) {
	return {
		type: Types.CONSULTA_PCON_REQUEST,
		payload,
	};
}
export function consultaPconSuccess(payload: object) {
	return {
		type: Types.CONSULTA_PCON_SUCCESS,
		payload,
	};
}
export function consultaPconFailure(payload: object): any {
	return {
		type: Types.CONSULTA_PCON_FAILURE,
		payload,
	};
}

export function consultaPconClear(): any {
	return {
		type: Types.CONSULTA_PCON_CLEAR,
	};
}
