import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarMunicipioIbgeRequest } from './types';

export function atualizarMunicipioIbgeRequest(
	data: AtualizarMunicipioIbgeRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_MUNICIPIO_IBGE_REQUEST,
		payload: data,
	};
}
export function atualizarMunicipioIbgeSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_MUNICIPIO_IBGE_SUCCESS,
		payload,
	};
}
export function atualizarMunicipioIbgeFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_MUNICIPIO_IBGE_FAILURE,
		payload: null,
	};
}
export function atualizarMunicipioIbgeClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_MUNICIPIO_IBGE_CLEAR,
		payload: null,
	};
}
