import React, { useCallback, useEffect, useState, useContext } from 'react';

import { Row, Col } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { cadastrarSolicitanteRequest } from 'store/modules/api/cdhu/cadastrarSolicitante/actions';
import { consultarSolicitanteClear } from 'store/modules/api/cdhu/consultarSolicitante/actions';

// PATHS
import {
	ROUTE_CDHU_MUTUARIO_PESQUISAR,
	ROUTE_CDHU_SOLICITANTE_PESQUISAR,
} from 'pages/cdhu/routes/paths';

// COMPONENTS
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { getInitialValues, IFormDadosSolicitante, schema } from '../../form';

const DadosSolicitante: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	const [initialValues, setInitialValues] = useState<IFormDadosSolicitante>({
		cpf: '',
		nome: '',
		email: '',
		celular: '',
		telefoneResidencial: '',
		telefoneComercial: '',
		telefoneContato: '',
	});

	const { consultarSolicitante, cadastrarSolicitante } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	useEffect(() => {
		if (consultarSolicitante.data && consultarSolicitante.form) {
			const values: Object = {
				...consultarSolicitante.form,
				...consultarSolicitante.data,
			};

			setInitialValues(getInitialValues(values));
			setHasLoaded(true);
		}
	}, [consultarSolicitante]);

	useEffect(() => {
		if (cadastrarSolicitante.data && cadastrarSolicitante.status === 200) {
			history.push(ROUTE_CDHU_MUTUARIO_PESQUISAR);
		}
	}, [cadastrarSolicitante, history]);

	const handleValidate = useCallback((values: IFormDadosSolicitante) => {
		return getValidationsErrors(values, schema);
	}, []);

	const handleSubmit = useCallback(
		(values: IFormDadosSolicitante) => {
			dispatch(cadastrarSolicitanteRequest(token, values));
		},
		[dispatch, token],
	);

	return (
		<>
			<Section size="lg" title="Dados do solicitante" titleSize="sm">
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={handleValidate}
							onSubmit={handleSubmit}
							enableReinitialize
						>
							{formik => (
								<Form autoComplete="off">
									<>
										{hasLoaded && (
											<Row align="middle">
												<Col>
													<Row gutter={[0, 10]}>
														<Col span={12}>
															<Field
																as={InputMask}
																title="CPF"
																name="cpf"
																mask="999.999.999-99"
																disabled
																required
															/>
														</Col>
													</Row>

													<Row gutter={[0, 10]}>
														<Col span={24}>
															<Field
																as={Input}
																title="Nome"
																name="nome"
																maxLength={44}
																error={!!formik.errors.nome}
															/>
														</Col>
													</Row>

													<Row gutter={[0, 10]}>
														<Col span={24}>
															<Field
																as={Input}
																title="E-mail"
																name="email"
																maxLength={50}
																required
																error={!!formik.errors.email}
															/>
														</Col>
													</Row>

													<Row gutter={[0, 10]}>
														<Col span={12}>
															<Field
																as={InputMask}
																title="Celular"
																name="celular"
																mask="(99)99999-9999"
																error={!!formik.errors.celular}
															/>
														</Col>

														<Col span={12}>
															<Field
																as={InputMask}
																title="Telefone"
																name="telefoneResidencial"
																mask="(99)9999-9999"
																error={!!formik.errors.telefoneResidencial}
															/>
														</Col>
													</Row>

													<Row>
														<Col span={12}>
															<Field
																as={InputMask}
																title="Tel. Comercial"
																name="telefoneComercial"
																mask="(99)9999-9999"
																error={!!formik.errors.telefoneComercial}
															/>
														</Col>

														<Col span={12}>
															<Field
																as={InputMask}
																title="Tel. Contato"
																name="telefoneContato"
																mask="(99)9999-9999"
																error={!!formik.errors.telefoneContato}
															/>
														</Col>
													</Row>
												</Col>

												<Col offset={4}>
													<ButtonImage type="submit" src="prosseguir" />
												</Col>
											</Row>
										)}
									</>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>

			<ButtonVoltar
				navigate={false}
				onClick={() => {
					dispatch(clearNotifications());
					dispatch(consultarSolicitanteClear());
					history.push(ROUTE_CDHU_SOLICITANTE_PESQUISAR);
				}}
			/>
		</>
	);
};

export default DadosSolicitante;
