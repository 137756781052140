export enum Types {
	CADASTRAR_MUNICIPIO_REQUEST = '@SGU/CADASTRAR_MUNICIPIO_REQUEST',
	CADASTRAR_MUNICIPIO_SUCCESS = '@SGU/CADASTRAR_MUNICIPIO_SUCCESS',
	CADASTRAR_MUNICIPIO_FAILURE = '@SGU/CADASTRAR_MUNICIPIO_FAILURE',
	CADASTRAR_MUNICIPIO_CLEAR = '@SGU/CADASTRAR_MUNICIPIO_CLEAR',
}

export interface CadastrarMunicipio {
	status: number;
	data: null | MunicipioData;
}

interface MunicipioData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarMunicipioRequest {
	codigo?: string | number;
	descricao: string;
	idUf: string | number;
}
