import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultarTemporalidade } from './types';

// ACTIONS
import {
	consultarTemporalidadeClear,
	consultarTemporalidadeSuccess,
	consultarTemporalidadeFailure,
} from './actions';

function* consultarTemporalidadeRequest(request: ReducerAction) {
	const { payload } = request;

	const { cpf }: IRequestConsultarTemporalidade = payload;

	yield put(consultarTemporalidadeClear());

	const params = {
		cpfCondutor: cpf,
	};

	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}/consultarTemporalidade`,
		params,
	);

	if (response.status === 200) {
		yield put(consultarTemporalidadeSuccess(response.data));
	} else {
		yield put(consultarTemporalidadeFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_TEMPORALIDADE_REQUEST,
		consultarTemporalidadeRequest,
	),
]);
