import produce from 'immer';

// UTILS
import { formatDate } from 'utils/genericFunctions';

// TYPES
import { Types, consultaReabilitacao } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaReabilitacao = {
	status: 0,
	data: null,
};

export default function consultaReabilitacaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaReabilitacao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_REABILITACAO: {
				break;
			}

			case Types.CONSULTA_REABILITACAO_SUCCESS: {
				const reabilitacao = { ...action.payload.data.dadosReabilitacao[0] };

				if (reabilitacao.dataPrimeiraHabilitacao !== '') {
					reabilitacao.dataPrimeiraHabilitacao = formatDate(
						reabilitacao.dataPrimeiraHabilitacao,
					);
				}
				if (reabilitacao.dataValidadeCnh !== '') {
					reabilitacao.dataValidadeCnh = formatDate(
						reabilitacao.dataValidadeCnh,
					);
				}
				if (reabilitacao.dataNascimento !== '') {
					reabilitacao.dataNascimento = formatDate(reabilitacao.dataNascimento);
				}
				if (reabilitacao.dadosExameMedico.dataExameMedico !== '') {
					reabilitacao.dadosExameMedico.dataExameMedico = formatDate(
						reabilitacao.dadosExameMedico.dataExameMedico,
					);
				}
				if (reabilitacao.dadosExamePsicotecnico.dataExamePsicotecnico !== '') {
					reabilitacao.dadosExamePsicotecnico.dataExamePsicotecnico = formatDate(
						reabilitacao.dadosExamePsicotecnico.dataExamePsicotecnico,
					);
				}
				if (reabilitacao.dataExameTeorico !== '') {
					reabilitacao.dataExameTeorico = formatDate(
						reabilitacao.dataExameTeorico,
					);
				}
				if (reabilitacao.dataExamePratico2Rodas !== '') {
					reabilitacao.dataExamePratico2Rodas = formatDate(
						reabilitacao.dataExamePratico2Rodas,
					);
				}
				if (reabilitacao.dataExamePratico4Rodas !== '') {
					reabilitacao.dataExamePratico4Rodas = formatDate(
						reabilitacao.dataExamePratico4Rodas,
					);
				}
				if (reabilitacao.dadosPcad.dataNascimento !== '') {
					reabilitacao.dadosPcad.dataNascimento = formatDate(
						reabilitacao.dadosPcad.dataNascimento,
					);
				}

				if (
					!!reabilitacao.dadosPcad.naturalidade &&
					reabilitacao.dadosPcad.naturalidade !== ''
				) {
					const newNaturalidade = reabilitacao.dadosPcad.naturalidade.split(
						' - ',
					)[0];

					reabilitacao.dadosPcad.naturalidade = newNaturalidade;
				}

				if (
					!!reabilitacao.dadosPcad.enderecoPcad.municipio &&
					reabilitacao.dadosPcad.enderecoPcad.municipio !== ''
				) {
					const newNaturalidade = reabilitacao.dadosPcad.enderecoPcad.municipio.split(
						' - ',
					)[1];

					reabilitacao.dadosPcad.enderecoPcad.municipio = newNaturalidade;
				}

				draft.data = reabilitacao;
				break;
			}

			case Types.CONSULTA_REABILITACAO_FAILURE: {
				break;
			}

			default:
		}
	});
}
