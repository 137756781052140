import { Types } from './types';

export function confirmarPlacaRequest(payload: any) {
	return {
		type: Types.CONFIRMAR_PLACA_REQUEST,
		payload,
	};
}
export function confirmarPlacaSuccess(payload: any) {
	return {
		type: Types.CONFIRMAR_PLACA_SUCCESS,
		payload,
	};
}
export function confirmarPlacaFailure(payload: []): any {
	return {
		type: Types.CONFIRMAR_PLACA_FAILURE,
		payload,
	};
}
export function confirmarPlacaClear(): any {
	return {
		type: Types.CONFIRMAR_PLACA_CLEAR,
	};
}
