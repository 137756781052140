import React, { useCallback, useEffect, useState } from 'react';

import { FormikProps } from 'formik';
import { Row, Col, Upload, Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import MenuScanner from 'components/Pages/Scanner/Menu';

import { ApplicationState } from 'store';

// COMPONENTS
import Section from 'components/Common/Section';
import SimpleTable from 'components/Common/Table';
import Error from 'components/Common/Notifications/FormError/index';
import Loader from 'components/Common/Loader/index';

import {
	tipoArquivoRequest,
	tipoArquivoClear,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/tipoArquivoEmissao/actions';

interface Props {
	formik: FormikProps<any>;
	tipoArquivo?: string;
	evento?: any;
	showList?: boolean;
	sizeMB?: number;
}

interface ListaDocs {
	codigoTipoArquivo: string;
	nomeTipoArquivo: string;
	obrigatorio: string;
}

const UploadArquivo: React.FC<Props> = ({
	formik,
	tipoArquivo = '',
	evento,
	showList = true,
	sizeMB = 15000000,
}) => {
	const { Dragger } = Upload;

	const dispatch = useDispatch();
	const { tipoArquivoEmissao } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const [tableData, setTableData] = useState<any>(formik.values.documentos);
	const [errors, setErros] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [listaDocumentos, setListaDocumentos] = useState<
		Array<ListaDocs> | any
	>([]);
	const [listaPrimeiroEmplacamento, setListaPrimeiroEmplacamento] = useState<
		Array<ListaDocs> | any
	>([]);
	const [modalScanner, setModalScanner] = useState(false);

	useEffect(() => {
		setTableData(formik.values.documentos);
	}, [formik.values.documentos]);

	useEffect(() => {
		if (tipoArquivo !== '' && showList && tipoArquivoEmissao.status === 0) {
			setIsLoading(true);
			dispatch(tipoArquivoRequest({ evento, servico: tipoArquivo }));
		}
	}, [dispatch, evento, showList, tipoArquivo, tipoArquivoEmissao.status]);

	const getDocsList = useCallback(() => {
		if (tipoArquivoEmissao.status === 200) {
			return tipoArquivoEmissao.data?.tiposArquivosEmissao;
		}
		return [];
	}, [tipoArquivoEmissao]);

	useEffect(() => {
		if (tipoArquivoEmissao.status !== 0) {
			setIsLoading(false);
		}
		if (tipoArquivoEmissao.status === 200) {
			if (tipoArquivo === '1' && listaDocumentos.length > 0 && showList) {
				setListaPrimeiroEmplacamento(getDocsList());
				return;
			}
			setListaDocumentos(getDocsList());
		}
	}, [getDocsList, listaDocumentos, showList, tipoArquivo, tipoArquivoEmissao]);

	useEffect(() => {
		if (
			tipoArquivo === '1' &&
			listaDocumentos.length > 0 &&
			showList &&
			tipoArquivoEmissao.status !== 200
		) {
			dispatch(tipoArquivoClear());
			dispatch(tipoArquivoRequest({ evento, servico: '5' }));
		}
	}, [
		dispatch,
		evento,
		listaDocumentos,
		showList,
		tipoArquivo,
		tipoArquivoEmissao.status,
	]);

	const fileToBase64 = (file: any) => {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = event => {
				if (event?.target?.result !== null && event?.target?.result) {
					resolve(event.target.result);
				}
			};
		});
	};

	const handleChange = useCallback(
		async (info: any) => {
			if (info.file.size > sizeMB) {
				setErros([
					`Tamanho máximo permitido para o documento ${sizeMB
						.toString()
						.substring(0, 2)} (em MB).`,
				]);
			} else {
				const b64File = await fileToBase64(info.file).then(result => {
					return result;
				});
				const b64 = b64File as string;
				const formatedContudo = b64.toString().split(',')[1];

				formik.setFieldValue('documentos', [
					{
						nomeDocumento: info.file.name,
						tipoArquivo: '44',
						conteudo: formatedContudo,
					},
				]);
			}
		},
		[formik, sizeMB],
	);

	const props = {
		name: 'file',
		multiple: false,
		openFileDialogOnClick: false,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		beforeUpload: (file: any) => {
			return false;
		},
		onChange: (info: any) => handleChange(info),
	};

	const handleRemove = useCallback(
		async index => {
			const allFiles = [...tableData];
			allFiles.splice(index, 1);
			formik.setFieldValue('documentos', allFiles);
		},
		[formik, tableData],
	);

	const headers = [
		{
			key: '1',
			title: 'id',
			dataIndex: 'id',
			render: (text: any, record: any, index: any) => index + 1,
		},
		{
			key: '2',
			title: 'Nome',
			dataIndex: 'nomeDocumento',
			render: (text: any) => text,
		},
		{
			key: '3',
			title: '',
			dataIndex: 'type',
			render: (text: any, record: any, index: any) => (
				<div style={{ textAlign: 'center' }}>
					<Button onClick={() => handleRemove(index)}>Excluir</Button>
				</div>
			),
		},
	];

	const DocsList: React.FC<any> = () => {
		if (listaDocumentos.length <= 0) {
			return (
				<Col span={22}>
					Não foi possível listar os documentos necessários no momento, tente
					novamente mais tarde.
				</Col>
			);
		}

		const docList =
			listaPrimeiroEmplacamento.length > 0
				? [...listaDocumentos, ...listaPrimeiroEmplacamento]
				: listaDocumentos;

		const docsNecessarios = docList.map((doc: ListaDocs, index: number) => {
			const isRequired = doc.obrigatorio === 'Sim';
			return (
				<Col
					key={`doc-${index + 1}`}
					span={22}
					style={isRequired ? { fontWeight: 'bold' } : {}}
				>
					{isRequired ? `* ${doc.nomeTipoArquivo}` : doc.nomeTipoArquivo}
				</Col>
			);
		});
		return docsNecessarios;
	};

	return (
		<>
			<Section size="sm" title="Documentos">
				<Row gutter={[0, 10]}>
					<Col span={24} style={{ fontWeight: 'bold' }}>
						Anexar arquivo com até {`${sizeMB.toString().substring(0, 2)} `}
						megabytes. Formatos suportados: PDF, GIF, JPG, JPEG
					</Col>

					<Col span={24}>
						{errors.length > 0 && (
							<Error errors={errors} onClose={() => setErros([])} />
						)}

						<Dragger
							{...props}
							accept="image/jpg, image/gif, image/jpeg, application/pdf"
							showUploadList={false}
						>
							<Button
								className="addButton"
								onClick={() => setModalScanner(true)}
							>
								Digitalizar
							</Button>
							<Upload
								{...props}
								openFileDialogOnClick
								accept="image/jpg, image/gif, image/jpeg, application/pdf"
								showUploadList={false}
							>
								<Button className="addButton" onClick={() => {}}>
									Documento
								</Button>
							</Upload>
						</Dragger>
					</Col>

					<Col span={24}>
						<SimpleTable
							headers={headers}
							body={tableData}
							messageNoResults="Nenhum registro encontrado"
						/>
					</Col>

					{showList && (
						<Col span={24}>
							<Section title="Lista de Documentos" size="sm">
								<Row gutter={[0, 5]} justify="center">
									{isLoading ? (
										<Loader />
									) : (
										<>
											<Col span={24}>
												Os documentos marcados com (*) são obrigatórios
											</Col>
											<DocsList />
										</>
									)}
								</Row>
							</Section>
						</Col>
					)}
				</Row>

				{modalScanner && (
					<Modal
						title="Digitalização de Documentos"
						visible={modalScanner}
						footer={[]}
						width="90%"
						style={{ top: '2px' }}
						onCancel={() => setModalScanner(false)}
					>
						<MenuScanner voltar={false} />
					</Modal>
				)}
			</Section>
		</>
	);
};

export default UploadArquivo;
