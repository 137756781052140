// TYPES
import { ReducerAction } from 'store/modules/types';
import { DadosAgendamentoState, Types } from './types';

export function dadosAgendamentoRequest(
	payload: DadosAgendamentoState,
): ReducerAction {
	return {
		type: Types.DADOS_AGENDAMENTO_REQUEST,
		payload,
	};
}
export function dadosAgendamentoClear(): ReducerAction {
	return {
		type: Types.DADOS_AGENDAMENTO_CLEAR,
		payload: null,
	};
}
