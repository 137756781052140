// TYLES
import { Types } from './types';
import { ReducerAction } from '../../types';

export function increaseFontSize(): ReducerAction {
	return {
		type: Types.INCREASE_FONT_SIZE,
		payload: null,
	};
}
export function decreaseFontSize(): ReducerAction {
	return {
		type: Types.DECREASE_FONT_SIZE,
		payload: null,
	};
}

export function setLightTheme(): ReducerAction {
	return {
		type: Types.SET_LIGHT_THEME,
		payload: null,
	};
}
export function setDarkTheme(): ReducerAction {
	return {
		type: Types.SET_DARK_THEME,
		payload: null,
	};
}
