import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaPontosRequest } from './types';

// ACTIONS
import { consultaPontosSuccess, consultaPontosFailure } from './actions';

function* consultaPontuacao(request: ReducerAction) {
	const { payload }: { payload: ConsultaPontosRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`sockets/${CHANNEL}/consulta/pontos`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaPontosSuccess(response));
	} else {
		yield put(consultaPontosFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTA_PONTOS_REQUEST, consultaPontuacao),
]);
