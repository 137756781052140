import * as Yup from 'yup';

import {
	limparMascara,
	validaCpf,
	validacaoData,
} from 'utils/genericFunctions';

import { EventoContabilizacao } from 'store/modules/types';
import { IRequestValidacaoCidadao } from 'store/modules/api/bolsaPovo/desbloqueioCartao/types';

interface DadosAtendente {
	nome: string;
	cpf: string;
}

export const initialValues: IRequestValidacaoCidadao = {
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
		identificacao: '',
		ip: '',
		canal: {
			id: 0,
			desc: '',
			localidade: {
				id: 0,
				desc: '',
			},
		},
	},
	idCpf: '',
	dsNome: '',
	dsPrimeiroNomeMae: '',
	dsNaoPossuiNomeMae: false,
	DsNumeroCartaoQuatroUltimosDigitos: '',
	dtNascimento: '',
	dsAtendenteCpf: '',
	dsAtendenteNome: '',
};

export function treatValues(
	evento: EventoContabilizacao,
	values: IRequestValidacaoCidadao,
	dadosAtendente: DadosAtendente,
): IRequestValidacaoCidadao {
	const requestValues = {
		evento: {
			id_atendimento: evento.id_atendimento,
			id_cidadao: evento.id_cidadao,
			ip: evento.ip,
			identificacao:
				values.idCpf !== '' ? parseInt(limparMascara(values.idCpf), 10) : 0,
			// identificacao: evento.identificacao,
			cpf: evento.cpf || '',
			canal: {
				id: 1,
				desc: 'Presencial',
				localidade: {
					desc: evento.canal?.localidade.desc || '',
					id: evento.canal?.localidade.id || 0,
				},
			},
		},
		idCpf: limparMascara(values.idCpf),
		dsNome: '',
		dsPrimeiroNomeMae: values.dsPrimeiroNomeMae,
		dsNaoPossuiNomeMae: values.dsNaoPossuiNomeMae.toString() || 'false',
		DsNumeroCartaoQuatroUltimosDigitos:
			values.DsNumeroCartaoQuatroUltimosDigitos,
		dtNascimento: values.dtNascimento,
		dsAtendenteCpf: dadosAtendente.cpf,
		dsAtendenteNome: dadosAtendente.nome,
	};

	return requestValues;
}

export const schema = Yup.object<IRequestValidacaoCidadao>().shape({
	idCpf: Yup.string()
		.required('Campo Obrigatório: CPF do Cidadão.')
		.test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF digitado inválido.',
			test: value => validaCpf(value),
		}),
	dtNascimento: Yup.string()
		.required('Campo Obrigatório: Data de Nascimento.')
		.test({
			name: 'dtNascimentoValidation',
			exclusive: true,
			message: 'Data de nascimento digitada inválida.',
			test: value => validacaoData(value),
		}),
	// dsNome: Yup.string().required(`Campo Obrigatório: Nome do Beneficiário.`),
	// dsPrimeiroNomeMae: Yup.string().when(['dsNaoPossuiNomeMae'], {
	// 	is: dsNaoPossuiNomeMae => dsNaoPossuiNomeMae === false,
	// 	then: Yup.string().required(`Campo Obrigatório: Primeiro nome da mãe`),
	// }),
	// dtNascimento: Yup.string().required(`Campo Obrigatório: Data de Nascimento.`),
	DsNumeroCartaoQuatroUltimosDigitos: Yup.string().required(
		`Campo Obrigatório: Número do cartão - Quatro últimos digitos.`,
	),
});
