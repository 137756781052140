import * as Yup from 'yup';

import {
	IFormPreCadastro,
	IFormStatusCondutor,
} from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor/form';
import { PesquisaEnderecoRecebimentoData } from 'store/modules/api/detranCnh/condutor/pesquisaEnderecosRecebimento/types';
// import { consultaCEPData } from 'store/modules/api/detranCnh/dadosDominio/cep/types';
import { limparMascara } from 'utils/genericFunctions';
import { CadastroEnderecosRequest } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/types';

import { AtendimentoState } from 'store/modules/api/sgu/atendimento/types';
import { ILoginUnicoState } from 'store/modules/api/sgu/loginUnico/types';
import { consultaEPortalAutomatizadoData } from 'store/modules/api/detranCnh/permissaoInternacionalAutomatizado/consultaEPortalAutomatizado/types';
import { RequestEmissaoPid } from 'store/modules/api/detranCnh/permissaoInternacionalAutomatizado/emissaoPid/types';
import { userDataRegex } from 'utils/constants/regex';

interface FormDadosSubmitPid {
	cpf: string;
	registro: string;
	nome: string;
	ciretran: string;
	categoria: string;
	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	codigoMunicipioIBGE: string;
	municipio: string;
	nomeMunicipio: string;
	codigoInterno: string;
	message: string;
	dataValidadeCnh: string;
	email: string;
	confirmaEmail: string;
	telefone: string;
	celular: string;
	authEmail: boolean;
	authSms: boolean;
	endereco: {
		logradouro: string;
		numero: string;
		complemento: string;
		bairro: string;
		descricaoMunicipio: string;
		cep: string;
	};
	evento: {
		id_atendimento: string;
		id_cidadao: string;
		cpf: string;
		identificacao: string;
		ip: string;
		canal: {
			id: string;
			desc: string;
			estacao: {
				id: string;
				desc: string;
			};
			localidade: {
				id: string;
				desc: string;
			};
		};
	} | null;
}

export const initialValuesDadosCondutor: consultaEPortalAutomatizadoData = {
	cpf: '',
	registro: '',
	nome: '',
	ciretran: '',
	categoria: '',
	cep: '',
	logradouro: '',
	numero: '',
	complemento: '',
	bairro: '',
	codigoMunicipioIBGE: '',
	municipio: '',
	nomeMunicipio: '',
	codigoInterno: '',
	message: '',
	dataValidadeCnh: '',
};

export const initalValuesHabilitacao: IFormStatusCondutor = {
	servico: 'permissaoInternacional',
	status: '',
	statusMensagem: '',
	mensagemBloqueio: '',
	numeroRegistro: '',
	numeroPgu: '',
	renach: '',
	local: '',
	categoriaPretendida: '',
	cfcA: '',
	espelhoCnh: '',
	usoForm: '',
	situacaoAtual: '',
	cfcB: '',
	espelhoPid: '',
	dataPrimeiraHabilitacao: '',
	nome: '',
	dataValidadeCnh: '',
	nomePai: '',
	dataNascimento: '',
	nomeMae: '',
	sexo: '',
	nacionalidade: '',
	tipoDocumento: '',
	numeroRg: '',
	orgaoEmissor: '',
	localEmissao: '',
	cpf: '',
	rne1: '',
	rne2: '',
	rne3: '',
	naturalidade: '',
	identificacaoHabilitacao: '',
	pais: '',
	dataValidade: '',
	enderecoLogradouro: '',
	enderecoNumero: '',
	enderecoComplemento: '',
	enderecoCep: '',
	enderecoBairro: '',
	enderecoMunicipio: '',
	enderecoCodigoMunicipio: '',
	enderecoDescricaoMunicipio: '',
	mensagem: '',
	exameMedicoDataExameMedico: '',
	exameMedicoCategoriaPermitida: '',
	exameMedicoRestricaoMedica: '',
	exameMedicoCrm: '',
	exameMedicoCred1: '',
	exameMedicoCred2: '',
	exameMedicoRegiao: '',
	exameMedicoAptidaoMedica: '',
	examePsicotecnicoDataExamePsicotecnico: '',
	examePsicotecnicoLaudo: '',
	examePsicotecnicoResultado1: '',
	examePsicotecnicoResultado2: '',
	examePsicotecnicoCrp: '',
	examePsicotecnicoCred1: '',
	examePsicotecnicoCred2: '',
	examePsicotecnicoRegiao: '',
	examePsicotecnicoAptidaoPsicotecnica: '',
	dataExameTeorico: '',
	codigoExameTeorico: '',
	codigoEntidade: '',
	descricaoEntidade: '',
	dataExamePratico2Rodas: '',
	codigoExamePratico2Rodas: '',
	dataExamePratico4Rodas: '',
	codigoExamePratico4Rodas: '',
	dataUltimaEmissaoCnh: '',
	listaOcorrencias: [],
	listaCursos: [],
	cepPcon: '',
	cepPcad: '',
	mensagensAlerta: '',
	nomeSocial: '',
	filiacao1: '',
	filiacao2: '',
	autorizaEmail: false,
	autorizaSMS: false,
};

export const initialValuesPreCadastro: IFormPreCadastro = {
	servico: 'permissaoInternacional',
	flagAtividadeRemunerada: '',
	flagDeficienteFisico: '',
	flagExameToxicologico: '',
	flagMilitarCfcCorporacao: 'N',
	flagExameDentroDoPosto: 'S',
	codigoEntidade: '',
	numCarteiraMilitar: '',
	nome: '',
	cpf: '',
	numeroRg: '',
	rne1: '',
	rne2: '',
	rne3: '',
	nomePai: '',
	nomeMae: '',
	sexo: '',
	nacionalidade: '',
	uf: '',
	cep: '',
	enderecoNumero: '',
	enderecoBairro: '',
	telefone: '',
	celular: '',
	orgaoEmissor: '',
	localEmissao: '',
	dataNascimento: '',
	naturalidade: '',
	enderecoLogradouro: '',
	enderecoComplemento: '',
	enderecoCodigoMunicipio: '',
	enderecoDescricaoMunicipio: '',
	email: '',
	confirmaEmail: '',
	autorizaSMS: '',
	autorizaEmail: '',
	categoriaPretendida: '',
	cnhDigital: '',
	codigoVerificador: '',
	codigoPoupatempo: '',
	cpfUsuario: '',
	categoriaAtual: '',
	dataPrimeiraHabilitacao: '',
	numeroPgu: '',
	numeroRegistro: '',
	tipoDocumento: '1',
	enderecoRecebimentoNumero: '',
	enderecoRecebimentoBairro: '',
	enderecoRecebimentoCep: '',
	enderecoRecebimentoLogradouro: '',
	enderecoRecebimentoComplemento: '',
	enderecoRecebimentoMunicipio: '',
};

export const initialValuesDadosHabilitacao: FormDadosSubmitPid = {
	cpf: '',
	registro: '',
	nome: '',
	ciretran: '',
	categoria: '',
	cep: '',
	logradouro: '',
	numero: '',
	complemento: '',
	bairro: '',
	codigoMunicipioIBGE: '',
	municipio: '',
	nomeMunicipio: '',
	codigoInterno: '',
	message: '',
	dataValidadeCnh: '',
	email: '',
	confirmaEmail: '',
	telefone: '',
	celular: '',
	authEmail: false,
	authSms: false,
	evento: null,
	endereco: {
		logradouro: '',
		numero: '',
		complemento: '',
		bairro: '',
		descricaoMunicipio: '',
		cep: '',
	},
};

export const initialValuesEndereco: PesquisaEnderecoRecebimentoData = {
	cpf: '',
	codigo: '',
	mensagem: '',
	enderecoCadastro: {
		logradouro: '',
		numero: '',
		complemento: '',
		bairro: '',
		cep: '',
		codigoMunicipio: '',
		nomeMunicipio: '',
	},
	enderecosRecebimento: [],
};

export const radioSimNao = [
	{
		label: 'Sim',
		value: 'S',
	},
	{
		label: 'Não',
		value: 'N',
	},
];

export const radioTipoRenovacao = [
	{
		label: 'Renovação Via Impressa',
		value: 'S',
	},
	{
		label: 'Renovação Digital (Impressa + Digital)',
		value: 'N',
	},
];

export function treatValues(values: any): CadastroEnderecosRequest {
	const formattedData = {
		cpf: values.cpf,
		enderecoRecebimento: {
			logradouro: values.enderecoRecebimentoLogradouro || '',
			numero: values.enderecoRecebimentoNumero || '',
			complemento: values.enderecoRecebimentoComplemento || '',
			bairro: values.enderecoRecebimentoBairro || '',
			cep: values.enderecoRecebimentoCep || '',
			codigoMunicipio: values.enderecoRecebimentoCodigoMunicipio || '',
			nomeMunicipio: values.enderecoRecebimentoMunicipio || '',
		},
	};

	return formattedData;
}

export function treatValuesEmissaoPid(
	atendimento: AtendimentoState,
	loginUnico: ILoginUnicoState,
	values: FormDadosSubmitPid,
): RequestEmissaoPid {
	const cpfAtendende = loginUnico.login.user.cpf
		? limparMascara(loginUnico.login.user.cpf)
		: '';
	return {
		cpf: values.cpf ? limparMascara(values.cpf.trim()) : '',
		cpfUsuario: cpfAtendende,
		ciretran: values.ciretran,
		evento: {
			id_atendimento: atendimento.salvarAtendimento.data?.idAtendimento ?? '',
			id_cidadao: atendimento.salvarAtendimento.data?.idCidadao ?? '',
			cpf: cpfAtendende,
			identificacao: values.cpf ? limparMascara(values.cpf.trim()) : '',
			ip: loginUnico.login.user.ip,
			canal: {
				id: 1,
				desc: 'Presencial',
				estacao: {
					id: 0,
					desc: '',
				},
				localidade: {
					id: Number(loginUnico.login.user.posto),
					desc: loginUnico.login.user.nomePosto,
				},
			},
		},
	};
}

export const schema = Yup.object<IFormPreCadastro>().shape({
	enderecoRecebimentoLogradouro: Yup.string().required(
		'Campo Obrigatório: Logradouro.',
	),
	// enderecoRecebimentoComplemento: Yup.string().required(
	// 	'Campo Obrigatório: Complemento.',
	// ),
	enderecoRecebimentoNumero: Yup.string().required(
		'Campo Obrigatório: Número.',
	),
	enderecoRecebimentoBairro: Yup.string().required(
		'Campo Obrigatório: Bairro.',
	),
	enderecoRecebimentoCep: Yup.string().required('Campo Obrigatório: CEP.'),
	celular: Yup.string()
		.when(['telefone'], {
			is: telefone => telefone === '',
			then: Yup.string().required('Campo Obrigatório: Celular.'),
		})
		.when(['autorizaSMS'], {
			is: autorizaSMS => autorizaSMS === 'S' || autorizaSMS === true,
			then: Yup.string().required('Campo Obrigatório: Celular.'),
		})
		.matches(userDataRegex.cellPhoneNumber, {
			message: 'Campo Inválido: Celular.',
			excludeEmptyString: true,
		}),
	email: Yup.string()
		.when(['autorizaEmail'], {
			is: autorizaEmail => autorizaEmail === 'S' || autorizaEmail === true,
			then: Yup.string().required('Campo Obrigatório: Email.'),
		})
		.matches(userDataRegex.emailAddress, {
			message: 'Formato de e-mail inválido.',
			excludeEmptyString: true,
		})
		.test(
			'same-email',
			'A confirmação do e-mail é diferente do e-mail informado.',
			function verify(value) {
				return value !== '' ? this.parent.confirmaEmail === value : true;
			},
		),
	authSms: Yup.string().required('Campo Obrigatório: Autoriza SMS.'),
	authEmail: Yup.string().required('Campo Obrigatório: Autoriza e-mail.'),
});
