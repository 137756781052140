import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarMunicipios } from './types';

export const INITIAL_STATE: ConsultarMunicipios = {
	status: 0,
	data: null,
};

export default function consultaMunipioReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarMunicipios {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_MUNICIPIOS_REQUEST: {
				break;
			}
			case Types.CONSULTAR_MUNICIPIOS_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTAR_MUNICIPIOS_FAILURE: {
				draft.status = action.payload.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
		return draft;
	});
}
