import * as Yup from 'yup';

export interface IAndamentoProcesso {
	numeroFicha: string;
	anoFicha: string;
}

export const initialValues: IAndamentoProcesso = {
	numeroFicha: '',
	anoFicha: '',
};

export const schema = Yup.object<IAndamentoProcesso>().shape({
	numeroFicha: Yup.string().required(`Campo Obrigatório: Número da Ficha.`),
	anoFicha: Yup.string().required(`Campo Obrigatório: Ano da Ficha Cadastral.`),
});
