import { Types } from './types';

export function consultaCategoriaRequest() {
	return {
		type: Types.CONSULTA_CATEGORIA_REQUEST,
	};
}
export function consultaCategoriaSuccess(payload: any) {
	return {
		type: Types.CONSULTA_CATEGORIA_SUCCESS,
		payload,
	};
}
export function consultaCategoriaFailure(payload: []): any {
	return {
		type: Types.CONSULTA_CATEGORIA_FAILURE,
		payload,
	};
}
