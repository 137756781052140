// TYPES
import { ReducerAction } from 'store/modules/types';
import { RequestEmissaoExigencia, EmissaoExigencia, Types } from './types';

export function emissaoExigenciasRequest(
	payload: RequestEmissaoExigencia,
): ReducerAction {
	return {
		type: Types.EMISSAO_EXIGENCIAS_REQUEST,
		payload,
	};
}

export function emissaoExigenciasSuccess(
	payload: EmissaoExigencia,
): ReducerAction {
	return {
		type: Types.EMISSAO_EXIGENCIAS_SUCCESS,
		payload,
	};
}

export function emissaoExigenciasFailure(): ReducerAction {
	return {
		type: Types.EMISSAO_EXIGENCIAS_FAILURE,
		payload: null,
	};
}

export function emissaoExigenciasClear(): ReducerAction {
	return {
		type: Types.EMISSAO_EXIGENCIAS_CLEAR,
		payload: null,
	};
}
