import * as Yup from 'yup';

import { unformatDate, validacaoData } from 'utils/genericFunctions';
import { IFormInclusaoObsPrimeiraViaMenor } from '../../form';
import { isDateBefore } from '../../../../utils/rgFicDigitalFunctions';

export const initialValues: IFormInclusaoObsPrimeiraViaMenor = {
	rgForaSp: false,
	rgForaSpUf: '',
	rgForaSpNum: '',
	rgForaSpEmissao: '',
	observacao: '',
	termoTutela: false,
	tutelaProc: '',
	tutelaAno: '',
	tutelaData: '',
	tipoDocumentoResponsavel: '',
	ufDocumentoResponsavel: '',
	numeroDocumentoResponsavel: '',
	dataExpedicaoDocumentoResponsavel: '',
	serie: '',
	isencaoPrimeiraViaCin: true,
};

export const checkCRNMDate = (date: string) => {
	const inputDate = new Date(unformatDate(date));
	const testDate = new Date('2017-01-01');

	if (inputDate < testDate) {
		return false;
	}
	return true;
};

export const checkRNEMDate = (date: string) => {
	const inputDate = new Date(unformatDate(date));
	const testDate = new Date('2017-01-01');

	if (inputDate > testDate) {
		return false;
	}
	return true;
};

export const possuiUf = (tipoDoc: string) => {
	if (
		tipoDoc === 'RG' ||
		tipoDoc === 'CNH' ||
		tipoDoc === 'CTPS' ||
		tipoDoc === 'CFUNCION'
	) {
		return true;
	}
	return false;
};

export const possuiDataExpedicao = (tipoDoc: string) => {
	if (!tipoDoc || tipoDoc === 'OUTROS' || tipoDoc === 'T REFUG') {
		return false;
	}
	return true;
};

export const schema = (isMenorDezesseis: boolean) =>
	Yup.object<IFormInclusaoObsPrimeiraViaMenor>().shape({
		rgUf: Yup.string()
			.when('rgForaSpUf', {
				is: rgForaSpUf => rgForaSpUf === 'SP',
				then: Yup.string().required('Campo Estado do RG: Não pode ser SP.'),
			})
			.when(['rgForaSpUf', 'rgForaSpNum', 'rgForaSpEmissao'], {
				is: (rgForaSpUf, rgForaSpNum, rgForaSpEmissao) =>
					rgForaSpUf === '' && (rgForaSpNum !== '' || rgForaSpEmissao !== ''),
				then: Yup.string().required('Campo Obrigatório: Estado do RG.'),
			}),
		rgNum: Yup.string().when(['rgForaSpUf', 'rgForaSpNum', 'rgForaSpEmissao'], {
			is: (rgForaSpUf, rgForaSpNum, rgForaSpEmissao) =>
				rgForaSpNum === '' && (rgForaSpUf !== '' || rgForaSpEmissao !== ''),
			then: Yup.string().required('Campo Obrigatório: Número do RG.'),
		}),
		rgEmi: Yup.string().when(['rgForaSpUf', 'rgForaSpNum', 'rgForaSpEmissao'], {
			is: (rgForaSpUf, rgForaSpNum, rgForaSpEmissao) =>
				rgForaSpEmissao === '' && (rgForaSpUf !== '' || rgForaSpNum !== ''),
			then: Yup.string().required(
				'Campo Obrigatório: Campo data de expedição de Doc fora de SP obrigatório.',
			),
		}),
		observacoes: Yup.string()
			.max(338, 'Campo Observações: Tamanho máximo de 338 caracteres.')
			.nullable(),
		proc: Yup.string().when('termoTutela', {
			is: termoTutela => termoTutela === true,
			then: Yup.string()
				.when(['tutelaProc', 'tutelaAno', 'tutelaData'], {
					is: (tutelaProc, tutelaAno, tutelaData) =>
						tutelaProc === '' && tutelaAno === '' && tutelaData === '',
					then: Yup.string().required(
						'Campos Obrigatórios: Número, ano e data do processo.',
					),
				})
				.when(['tutelaProc', 'tutelaAno', 'tutelaData'], {
					is: (tutelaProc, tutelaAno, tutelaData) =>
						tutelaProc === '' && (tutelaAno !== '' || tutelaData !== ''),
					then: Yup.string().required('Campo Obrigatório: Número do Processo.'),
				})
				.when(['tutelaProc', 'tutelaAno', 'tutelaData'], {
					is: (tutelaProc, tutelaAno, tutelaData) =>
						tutelaData === '' && (tutelaProc !== '' || tutelaAno !== ''),
					then: Yup.string().required('Campo Obrigatório: Data do Processo.'),
				})
				.when(['tutelaProc', 'tutelaAno', 'tutelaData'], {
					is: (tutelaProc, tutelaAno, tutelaData) =>
						tutelaAno === '' && (tutelaProc !== '' || tutelaData !== ''),
					then: Yup.string().required('Campo Obrigatório: Ano do Processo.'),
				}),
		}),
		validaDataProcesso: Yup.string().when(['tutelaData', 'termoTutela'], {
			is: (tutelaData, termoTutela) =>
				tutelaData !== '' && !validacaoData(tutelaData) && termoTutela,
			then: Yup.string().required(`Data do Termo não é uma data válida.`),
		}),
		validaDataRG: Yup.string().when(['rgForaSpEmissao'], {
			is: rgForaSpEmissao =>
				rgForaSpEmissao !== '' && validacaoData(rgForaSpEmissao) === false,
			then: Yup.string().required(
				`Data de expedição do RG não é uma data válida.`,
			),
		}),
		docResponsavel: Yup.string().when('tipoDocumentoResponsavel', {
			is: tipoDocumentoResponsavel =>
				isMenorDezesseis && tipoDocumentoResponsavel === '',
			then: Yup.string().required('Campo Obrigatório: Tipo Doc.'),
		}),
		ufRespon: Yup.string().when(
			['ufDocumentoResponsavel', 'tipoDocumentoResponsavel'],
			{
				is: (ufDocumentoResponsavel, tipoDocumentoResponsavel) =>
					isMenorDezesseis &&
					possuiUf(tipoDocumentoResponsavel) &&
					ufDocumentoResponsavel === '',
				then: Yup.string().required('Campo Obrigatório: UF'),
			},
		),
		numDocRespon: Yup.string().when(['numeroDocumentoResponsavel'], {
			is: numeroDocumentoResponsavel =>
				isMenorDezesseis && numeroDocumentoResponsavel === '',
			then: Yup.string().required('Campo Obrigatório: Número do Documento'),
		}),
		serie: Yup.string().when(['tipoDocumentoResponsavel'], {
			is: tipoDocumentoResponsavel => tipoDocumentoResponsavel === 'CTPS',
			then: Yup.string().required('Campo Obrigatório: Série'),
		}),
		dataExpRespon: Yup.string()
			.when(['dataExpedicaoDocumentoResponsavel', 'tipoDocumentoResponsavel'], {
				is: (dataExpedicaoDocumentoResponsavel, tipoDocumentoResponsavel) =>
					isMenorDezesseis &&
					dataExpedicaoDocumentoResponsavel === '' &&
					!validacaoData(dataExpedicaoDocumentoResponsavel) &&
					possuiDataExpedicao(tipoDocumentoResponsavel),
				then: Yup.string().required('Campo Obrigatório: Data de Expedição.'),
			})
			.when(['dataExpedicaoDocumentoResponsavel', 'tipoDocumentoResponsavel'], {
				is: (dataExpedicaoDocumentoResponsavel, tipoDocumentoResponsavel) =>
					isMenorDezesseis &&
					!isDateBefore(dataExpedicaoDocumentoResponsavel) &&
					possuiDataExpedicao(tipoDocumentoResponsavel),
				then: Yup.string().required(
					'Campo Inconsistente: Data de Expedição não pode ser anterior a data atual.',
				),
			})
			.when(['dataExpedicaoDocumentoResponsavel', 'tipoDocumentoResponsavel'], {
				is: (dataExpedicaoDocumentoResponsavel, tipoDocumentoResponsavel) =>
					isMenorDezesseis &&
					tipoDocumentoResponsavel === 'CRNM' &&
					!checkCRNMDate(dataExpedicaoDocumentoResponsavel),
				then: Yup.string().required(
					'Para CRNM a Data de Expedição, não pode ser Anterior a 2017.',
				),
			})
			.when(['dataExpedicaoDocumentoResponsavel', 'tipoDocumentoResponsavel'], {
				is: (dataExpedicaoDocumentoResponsavel, tipoDocumentoResponsavel) =>
					isMenorDezesseis &&
					tipoDocumentoResponsavel === 'RNE' &&
					!checkRNEMDate(dataExpedicaoDocumentoResponsavel),
				then: Yup.string().required(
					'Para RNE a Data de Expedição, não pode ser Posterior a 2017.',
				),
			}),
	});
