import React, { useEffect, useRef } from 'react';

// MODEL
import { IButtonProps } from '../model';

// COMPONENTS
import Icon from '../components/Icon';

// STYLED
import { Button } from './styled';
import { Container, Text } from '../styled';

const LightButton: React.FC<IButtonProps> = ({
	id,
	type = 'button',
	buttonColor = 'default',
	buttonSize = 'md',
	buttonWidth,
	leftOrRight = 'left',
	icon,
	text,
	disabled = false,
	setFocus,
	...rest
}) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (setFocus) {
			const focus = setFocus();

			if (focus === true) {
				buttonRef.current?.focus();
			}
		}
	}, [setFocus]);

	return (
		<Container
			buttonSize={buttonSize}
			isDisabled={disabled}
			buttonWidth={buttonWidth?.toString().concat('px')}
		>
			<Button
				id={id}
				type={type}
				disabled={disabled}
				ref={buttonRef}
				buttonColor={buttonColor}
				{...rest}
				text={text}
				leftOrRight={leftOrRight}
			>
				{icon ? <Icon buttonIcon={icon} /> : ''}
				<Text buttonSize={buttonSize}>{text}</Text>
			</Button>
		</Container>
	);
};

export default LightButton;
