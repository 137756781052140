import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConsultarRelacaoUsuarioRequest } from './types';
import {
	consultarRelacaoUsuarioClear,
	consultarRelacaoUsuarioFailure,
	consultaRelacaoUsuarioSuccess,
} from './actions';

function* consultarQueimaDare(request: ReducerAction) {
	const { payload }: { payload: IConsultarRelacaoUsuarioRequest } = request;

	yield put(consultarRelacaoUsuarioClear());

	const payloadQR: IConsultarRelacaoUsuarioRequest = {
		idOrgao: payload.idOrgao,
		idLocalidade: payload.idLocalidade,
	};

	if (payload?.codigoMainFrame) {
		payloadQR.codigoMainFrame = payload.codigoMainFrame;
	}

	if (payload?.registroInicial) {
		payloadQR.registroInicial = payload.registroInicial;
	}

	if (payload?.limite) {
		payloadQR.limite = payload.limite;
	}

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`relatorios/localidade-usuario`,
		payloadQR,
	);

	if (response.status === 200) {
		yield put(consultaRelacaoUsuarioSuccess(response.status, response.data));
	} else {
		yield put(consultarRelacaoUsuarioFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_RELACAO_USUARIO_REQUEST, consultarQueimaDare),
]);
