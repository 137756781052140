import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { RadioChangeEvent } from 'antd/lib/radio';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import EnumCNH from 'store/modules/api/detranCnh/enum';
import { ApplicationState } from 'store';
import { consultaPontosRequest } from 'store/modules/api/detranCnh/sockets/consultaPontos/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import {
	// consultaPconClear,
	consultaPconRequest,
} from 'store/modules/api/detranCnh/eportal/consultaPcon/actions';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';
import ButtonImage from 'components/Common/ButtonImage';
import Radio from 'components/Common/Form/Radio';

// FORM
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
// import { ConsultarPontuacaoRequest } from 'store/modules/api/detranCnh/sockets/consultarPontuacao/types';
import { ROUTE_DETRAN_CNH_CONSULTA_PONTUACAO_CNH_STATUS_CONDUTOR } from 'pages/DetranCnh/routes/paths';
import { eventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/actions';
import { threatValuesStartSessionEventRequest } from 'utils/functions/threatEventsRequest';
import {
	initialValues,
	schema,
	radioOptionsConsulta,
	treatValues,
	treatValuesPcon,
	IFormPesquisaConsultarPontuacao,
} from './form';

// UTILS
import Documento from './Documento';

// const InitialValuesForm: ConsultarPontuacaoRequest = {
// 	registroCondutor: '',
// 	pguCondutor: '',
// 	cpfCondutor: '',
// 	dataNascimento: '',
// 	tipoCertidaoPontos: 'HISTORICO',
// };

const InitialValuesForm: IFormPesquisaConsultarPontuacao = {
	tipoDocumento: 'CPF',
	cpf: '',
	numeroRg: '',
	numeroRegistro: '',
	numeroPgu: '',
	flagDozeMeses: false,
};

const PesquisaConsultarPontuacao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	// const [
	// 	initialValuesConsultaPontuacaoState,
	// 	setInitialValuesConsultaPontuacaoState,
	// ] = useState<ConsultarPontuacaoRequest>(InitialValuesForm);

	const [initialValuesSubmit, setInitialValuesSubmit] =
		useState<IFormPesquisaConsultarPontuacao>(InitialValuesForm);

	const loginDataMainframe = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.mainframe,
	);

	const dataPcon = useSelector(
		(state: ApplicationState) => state.api.detranCnh.eportal.consultaPcon,
	);

	const statusCondutor = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets.consultaPontos,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const handleSubmit = useCallback(
		(values: any) => {
			const valuesRequest = treatValuesPcon(values, loginDataMainframe);
			const valuesStartSessionEvent = threatValuesStartSessionEventRequest(
				atendimento,
				loginUnico,
			);
			// console.log('values: ', values);
			// console.log('valuesRequest: ', valuesRequest);
			// console.log('valuesStartSessionEvent: ', valuesStartSessionEvent);
			// chamar PCON de acordo com o tipo de tipoDocumento: cpf, pgu, registro
			dispatch(consultaPconRequest(valuesRequest));

			dispatch(eventoInicioSessaoRequest(valuesStartSessionEvent));
			// setInitialValuesSubmit(values);
			setInitialValuesSubmit(values);

			// if (valuesRequest.tipoCnh !== 'PGU') {
			// 	dispatch(
			// 		consultaPconRequest({
			// 			cpf: valuesRequest.cpf,
			// 			numeroRegistro: valuesRequest.numRegistro,
			// 			usuario: loginDataMainframe.idUsuario,
			// 			senha: loginDataMainframe.senhaMainFrame,
			// 		}),
			// 	);
			// } else if (valuesRequest.tipoCnh === 'PGU') {
			// 	dispatch(
			// 		consultarPontuacaoRequest({
			// 			tipoCnh: 'PGU',
			// 			numRegistro: valuesRequest.numRegistro,
			// 			cpf: '',
			// 			dataNascimento: '1951-01-01',
			// 			flagDozeMeses: initialValuesConsultaPontuacaoState.flagDozeMeses,
			// 		}),
			// 	);
			// }
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, loginDataMainframe, loginDataMainframe],
	);

	// useEffect(() => {
	// 	return () => {
	// 		dispatch(consultaPconClear());
	// 	};
	// }, [dispatch]);

	useEffect(() => {
		if ((dataPcon.status === 200 || dataPcon.status === 204) && dataPcon.data) {
			if (
				initialValuesSubmit.cpf !== '' ||
				initialValuesSubmit.numeroRegistro !== '' ||
				initialValuesSubmit.numeroPgu !== ''
			) {
				const valuesRequest = treatValues(dataPcon.data, initialValuesSubmit);
				dispatch(consultaPontosRequest(valuesRequest));
			}
		}
	}, [dataPcon.data, dataPcon.status, dispatch, initialValuesSubmit]);

	useEffect(() => {
		if (
			(statusCondutor.status === 200 || statusCondutor.status === 204) &&
			statusCondutor.data
		) {
			history.push({
				pathname: `${ROUTE_DETRAN_CNH_CONSULTA_PONTUACAO_CNH_STATUS_CONDUTOR}`,
				state: { flagDozeMeses: initialValuesSubmit.flagDozeMeses },
			});
		}
	}, [
		initialValuesSubmit,
		history,
		statusCondutor.data,
		statusCondutor.status,
	]);

	return (
		<>
			<Section title="Pesquisar">
				<Row gutter={[0, 20]}>
					<Col span={8}>
						<p>
							<strong>Pesquise por um dos critérios abaixo: </strong>
						</p>
					</Col>
				</Row>

				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							<Row align="middle" justify="center" gutter={[20, 20]}>
								<Col span={24}>
									<Row align="middle" wrap>
										<Col offset={1} span={8}>
											<Field
												as={Select}
												title="Tipo de Documento"
												titleSize="md"
												name="tipoDocumento"
												options={EnumCNH.tipoDocumentoEnum.filter(
													op => op.label !== 'RG',
												)}
												onChange={(v: number) =>
													formik.setFieldValue('tipoDocumento', v)
												}
												defaultFirstOption={false}
												defaultValue="CPF"
											/>
										</Col>
										<Col offset={1} span={11}>
											<Documento formik={formik} />
										</Col>
									</Row>
									<Row align="middle">
										<Col span={24} offset={1}>
											<Field
												as={Radio}
												name="flagDozeMeses"
												options={radioOptionsConsulta}
												display="vertical"
												onChange={(e: RadioChangeEvent) => {
													const { value } = e.target;

													formik.setFieldValue('flagDozeMeses', value);
												}}
												error={hasError(formik.errors, 'flagDozeMeses')}
											/>
										</Col>
									</Row>
									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => {
													formik.handleReset();
													dispatch(clearNotifications());
												}}
											/>
										</Col>
										<Col offset={1}>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</Section>
			<ButtonVoltar />
		</>
	);
};
export default PesquisaConsultarPontuacao;
