import React, { useCallback, useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { Field, Form, Formik, FormikState } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	dependentesDareAdicionar,
	dependentesDareEditandoClear,
} from 'store/modules/api/dare/dependentesDare/actions';
import { IDependenteDare } from 'store/modules/api/dare/dependentesDare/types';
import data from 'store/modules/api/dare/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import Select from 'components/Common/Form/Select';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { limparMascara, patternFormat } from 'utils/genericFunctions';

// COMPONENTS
// import { addNotifications } from 'store/modules/app/notifications/actions';
import RgDependente from './RgDependente';

// FORM
import { KitFamiliaDARE, initialValues, schema } from './form';

export const Formulario: React.FC = () => {
	const dispatch = useDispatch();

	const [formData, setFormData] = useState<KitFamiliaDARE>(initialValues);
	const [resetRg, setResetRg] = useState<boolean>(false);

	const { dependentesDare } = useSelector(
		(state: ApplicationState) => state.api.dare,
	);

	const handleValidate = useCallback(async values => {
		return getValidationsErrors(values, schema);
	}, []);

	const handleSubmit = useCallback(
		(
			formValues: IDependenteDare,
			resetForm?: (nextState?: Partial<FormikState<KitFamiliaDARE>>) => void,
		) => {
			setResetRg(false);

			// const rgDuplicado = dependentesDare.data?.filter(
			// 	(item: IDependenteDare) =>
			// 		limparMascara(item.numeroRg) === limparMascara(formValues.numeroRg),
			// );

			if (
				dependentesDare.data
				// && !rgDuplicado?.length
			) {
				if (dependentesDare.editando.data) {
					dispatch(dependentesDareEditandoClear());
				}

				dispatch(
					dependentesDareAdicionar(
						[
							{
								...formValues,
								nome: formValues.nome.toUpperCase(),
								numeroRg:
									limparMascara(formValues.numeroRg).length === 10
										? patternFormat(formValues.numeroRg, '##.###.###-##')
										: patternFormat(formValues.numeroRg, '##.###.###-#'),
							},
						].concat(dependentesDare.data),
					),
				);

				setResetRg(true);
				if (resetForm) resetForm();
			}
			// else {
			// 	addNotifications({
			// 		errors: ['RG já informado para este kit.'],
			// 	});
			// }
		},
		[dependentesDare, dispatch],
	);

	// Manipula os valores do formulário (editar/resetar).
	useEffect(() => {
		if (dependentesDare.editando.data) {
			const {
				numeroRg,
				loteRg,
				nome,
				dataNascimento,
				grauParentesco,
				segundaVia,
			} = dependentesDare.editando.data;

			setFormData({
				numeroRg,
				loteRg,
				nome,
				dataNascimento,
				grauParentesco,
				segundaVia,
			});

			return;
		}

		setFormData({
			numeroRg: '',
			loteRg: '',
			nome: '',
			dataNascimento: '',
			grauParentesco: '',
			segundaVia: true,
		});
	}, [dependentesDare.editando.data]);

	return (
		<>
			<Section
				size="lg"
				title={dependentesDare.editando.data ? 'Editar dependente' : ''}
				titleSize="sm"
			>
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={formData}
							validate={handleValidate}
							onSubmit={(values, { resetForm }) => {
								handleSubmit(values, resetForm);
							}}
							enableReinitialize
						>
							{formik => (
								<Form autoComplete="off">
									<RgDependente
										initialValue={formData.numeroRg}
										reset={resetRg}
										formik={formik}
									/>

									<Row gutter={[0, 10]}>
										<Col span={22}>
											<Field
												as={Input}
												title="Nome"
												titleSize={200}
												name="nome"
												maxLength={60}
												size={94}
												required
												error={!!formik.errors.nome}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={12}>
											<Field
												as={InputMask}
												title="Data de Nascimento"
												titleSize={200}
												name="dataNascimento"
												mask="99/99/9999"
												subtitle="(DD/MM/AAAA)"
												size={50}
												required
												error={!!formik.errors.dataNascimento}
											/>
										</Col>

										<Col span={9}>
											<Field
												as={Select}
												title="Grau de Parentesco"
												titleSize={125}
												name="grauParentesco"
												options={data.grauParentesco}
												onChange={(v: string) =>
													formik.setFieldValue('grauParentesco', v)
												}
												required
												error={!!formik.errors.grauParentesco}
											/>
										</Col>
									</Row>

									<Row justify="center">
										<Col span={18}>
											<Row justify="end">
												<Col>
													{!dependentesDare.editando.data ? (
														<ButtonImage type="submit" src="adicionar" />
													) : (
														<ButtonImage type="submit" src="salvar" />
													)}
												</Col>
											</Row>
										</Col>
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>
		</>
	);
};
