export enum Types {
	CONSULTA_PESQUISA_OBSERVACAO_REQUEST = '@iirgd/CONSULTA_PESQUISA_OBSERVACAO_REQUEST',
	CONSULTA_PESQUISA_OBSERVACAO_SUCCESS = '@iirgd/CONSULTA_PESQUISA_OBSERVACAO_SUCCESS',
	CONSULTA_PESQUISA_OBSERVACAO_FAILURE = '@iirgd/CONSULTA_PESQUISA_OBSERVACAO_FAILURE',
	CONSULTA_PESQUISA_OBSERVACAO_CLEAR = '@iirgd/CONSULTA_PESQUISA_OBSERVACAO_CLEAR',
}

export interface IConsultaPesquisaObservacaoState {
	result: IConsultaPesquisaObservacao | null;
}

export interface IConsultaPesquisaObservacao {
	rgForaSpExpedicao: string;
	observacaoCadastrada: boolean;
	numeroRg: string;
	declaracaoPobreza: boolean;
	rgForaSp: boolean;
	solicitarBloqueio: boolean;
	termoRecolhimentoRg: boolean;
	rgRecolhidoEmissao: string;
	rgRecolhidoIdentific: string;
	rgRecolhidoPor: string;
	pagto2Via: boolean;
	isento2Via10952: boolean;
	isento2Via062: boolean;
	isento2Via062Status: string;
	ctpsObs: string;
	srObs: string;
	estObs: string;
	termoTutela: boolean;
	tutelaProc: string;
	tutelaAno: string;
	tutelaData: string;
	observacoes: string;
	observacao?: string;
	resideSpAnos: string;
	resideSpMeses: string;
	moraCom: string;
	temParentesSp: boolean;
	grauParentescoSp: string;
	ocupacaoPrincipalSp: string;
	rgForaSpUf: string;
	rgForaSpNum: string;
	rgForaSpEmissao: string;
	ctpsNum: string;
	cptsNum: string;
	ctpsSerie: string;
	ctpsSerieUf: string;
	cnh: string;
	tituloEleitor: string;
	reservista: string;
	classeProfissional: string;
	carteiraFuncional: string;
	cr: boolean;
	tipoDocumentoResponsavel: string;
	ufDocumentoResponsavel: string;
	numeroDocumentoResponsavel: string;
	serie?: string;
	dataExpedicaoDocumentoResponsavel: string;
	lote?: string;
	isencaoPrimeiraViaCin?: boolean;
}
