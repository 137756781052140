import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	TIPOS_CHAVE_PIX_REQUEST = '@procon/TIPOS_CHAVE_PIX_REQUEST',
	TIPOS_CHAVE_PIX_SUCCESS = '@procon/TIPOS_CHAVE_PIX_SUCCESS',
	TIPOS_CHAVE_PIX_FAILURE = '@procon/TIPOS_CHAVE_PIX_FAILURE',
	TIPOS_CHAVE_PIX_CLEAR = '@procon/TIPOS_CHAVE_PIX_CLEAR',
}

export interface TiposChavePix {
	status: number;
	data: OptionProps[];
}
