// ROUTES
import { ROUTE_MINISTERIO_PUBLICO } from 'routes/paths';

// HOME
export const ROUTE_MINISTERIO_PUBLICO_RECUPERAR_ATENDIMENTO = `${ROUTE_MINISTERIO_PUBLICO}/recuperar-atendimento`;
export const ROUTE_MINISTERIO_PUBLICO_JUSTIFICATIVA_ATENDIMENTO = `${ROUTE_MINISTERIO_PUBLICO}/recuperar-atendimento/justificativa`;

// MP
export const ROUTE_MINISTERIO_PUBLICO_RECONHECIMENTO_DE_PATERNIDADE = `${ROUTE_MINISTERIO_PUBLICO}/reconhecimento-de-paternidade`;
export const ROUTE_MINISTERIO_PUBLICO_RECONHECIMENTO_DE_PATERNIDADE_CADASTRO = `${ROUTE_MINISTERIO_PUBLICO}/reconhecimento-de-paternidade/cadastro`;
