import { ICadastraExamePsicologico } from 'pages/DetranCnh/components/pages/AgendamentoPsicologico/form';
import { IDadosCidadao } from 'pages/DetranCnh/components/pages/DadosCidadao/form';
import { DIVISAO_EQUITATIVA_PARAMS } from 'pages/DetranCnh/utils/constants/services';

export const initialValues: ICadastraExamePsicologico = {
	cpf_cidadao: 0,
	tipo_processo: 'ADICAO_MUDANCA',
	cep_cidadao: 0,
	codigo_ciretran_exame_medico: '',
	categoria_pretendida: '',
	id_medico: 0,
	codigo_ciretran_exame_psicologo: 0,
	id_psicologo: 0,
	data_exame_psicologico: '',
	horario_exame_psicologico: '',
	renach: '',
	id_micro_regiao_psicologo: '',
	usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
};

export const dadosCidadao: IDadosCidadao = {
	nome: '',
	cpf: '',
	nomePai: '',
	nomeMae: '',
	naturalidade: '',
	numeroRg: '',
	rne: '',
	dataNascimento: '',
	sexo: '',
	nacionalidade: '',
	renach: '',
	codigoCiretran: 0,
	categoriaPretendida: '',
	tipoProcesso: '',
	cep: '',
	isPortadorNecessidadesEspeciais: false,
};
