import { Types } from './types';

export function comboUfRequest(token: string) {
	return {
		type: Types.COMBO_UF_REQUEST,
		token,
	};
}

export function comboUfSuccess(payload: object) {
	return {
		type: Types.COMBO_UF_SUCCESS,
		payload,
	};
}

export function comboUfFailure(payload: object) {
	return {
		type: Types.COMBO_UF_FAILURE,
		payload,
	};
}
