import { all } from 'redux-saga/effects';

import consultarCpf from './consultarCpf/saga';
import emitirCin from './emitirCin/saga';
import cadastrarCin from './cadastrarCin/saga';
import consistenciaBiometrica from './consistenciaBiometrica/saga';
import consultarCin from './consultarCin/saga';
import consultarProcesso from './consultarProcesso/saga';

export default all([
	consultarCpf,
	emitirCin,
	cadastrarCin,
	consistenciaBiometrica,
	consultarCin,
	consultarProcesso,
]);
