import produce from 'immer';

// TYPES
import { Types, consultaReciclagem } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaReciclagem = {
	status: 0,
	data: null,
};

export default function consultaReciclagemReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaReciclagem {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_RECICLAGEM: {
				break;
			}

			case Types.CONSULTA_RECICLAGEM_SUCCESS: {
				const reciclagem = {
					...action.payload.data,
				};

				draft.data = reciclagem;
				break;
			}

			case Types.CONSULTA_RECICLAGEM_FAILURE: {
				break;
			}

			default:
		}
	});
}
