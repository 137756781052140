import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, UsuarioPendente } from './types';

export const INITIAL_STATE: UsuarioPendente = {
	status: 0,
	resultado: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): UsuarioPendente {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_USUARIO_PENDENTE: {
				break;
			}

			case Types.CONSULTA_USUARIO_PENDENTE_SUCCESS: {
				draft.status = 200;
				draft.resultado = action.payload;
				break;
			}

			case Types.CONSULTA_USUARIO_PENDENTE_FAILURE: {
				draft.status = 400;
				draft.resultado = INITIAL_STATE.resultado;
				break;
			}

			case Types.LIMPA_USUARIO_PENDENTE: {
				draft.status = INITIAL_STATE.status;
				draft.resultado = INITIAL_STATE.resultado;
				break;
			}

			case Types.ATUALIZAR_ATENDENTE: {
				break;
			}

			case Types.ATUALIZAR_ATENDENTE_SUCCESS: {
				draft.status = 200;
				draft.resultado = [
					...action.payload.resultado,
					...action.payload.response,
				];
				break;
			}

			case Types.ATUALIZAR_ATENDENTE_FAILURE: {
				draft.status = 400;
				draft.resultado = INITIAL_STATE.resultado;
				break;
			}

			case Types.LIMPA_ATUALIZAR_ATENDENTE: {
				draft.status = INITIAL_STATE.status;
				draft.resultado = INITIAL_STATE.resultado;
				break;
			}

			default:
		}

		return draft;
	});
}
