import * as Yup from 'yup';

export interface IConsultarCadastroNumeroMotorBin {
	numeroMotor: string;
	numeroDocumento: string;
	selecao: 'chassi' | 'placa' | 'renavam' | string;
	input?: string | number;
}

export const initialValues: IConsultarCadastroNumeroMotorBin = {
	numeroMotor: '',
	numeroDocumento: '',
	selecao: 'placa',
	input: '',
};

export function treatValues(
	values: IConsultarCadastroNumeroMotorBin,
): IConsultarCadastroNumeroMotorBin {
	const formattedData = {
		numeroMotor: values.numeroMotor,
		numeroDocumento: values.numeroDocumento,
		selecao: values.selecao,
	};

	return formattedData;
}

export const schema = Yup.object<IConsultarCadastroNumeroMotorBin>().shape({
	placaRenavamChassi: Yup.string().when(['placa', 'renavam', 'chassi'], {
		is: (placa, renavam, chassi) =>
			placa === '' && (renavam === null || renavam === '') && chassi === '',
		then: Yup.string().required(
			`Escolha uma das opções: PLACA / CHASSI / RENAVAM`,
		),
	}),
	numeroMotor: Yup.string().required(
		`Campo Obrigatório: Nº do Motor obrigatório.`,
	),
});
