export enum Types {
	CONSULTA_TIPO_MODIFICACAO_ALTERACAO_REQUEST = '@DETRAN-CRV/CONSULTA_TIPO_MODIFICACAO_ALTERACAO_REQUEST',
	CONSULTA_TIPO_MODIFICACAO_ALTERACAO_SUCCESS = '@DETRAN-CRV/CONSULTA_TIPO_MODIFICACAO_ALTERACAO_SUCCESS',
	CONSULTA_TIPO_MODIFICACAO_ALTERACAO_FAILURE = '@DETRAN-CRV/CONSULTA_TIPO_MODIFICACAO_ALTERACAO_FAILURE',
	CONSULTA_TIPO_MODIFICACAO_ALTERACAO_CLEAR = '@DETRAN-CRV/CONSULTA_TIPO_MODIFICACAO_ALTERACAO_CLEAR',
}

export interface consultarTipoModificacaoAlteracao {
	status: number;
	data: TipoModificacaoAlteracao[] | null;
}

export interface TipoModificacaoAlteracao {
	codigo: string;
	descricao: string;
	idAlteracao: string;
}
