import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import data from 'store/modules/enum';
import {
	consultarMunicipioCodigoProdesClear,
	consultarMunicipioCodigoProdespRequest,
} from 'store/modules/api/detranCrv/enum/consultarMunicipioCodigoProdesp/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';

// UTILS
import hasError from 'utils/getFormErrors';
import { onlyNumbersLettersSpace } from 'utils/genericFunctions';

// FORM
import { IEmitirBaixaVeiculo } from '../form';

interface Props {
	formik: FormikProps<IEmitirBaixaVeiculo>;
}

const DadosProprietario: React.FC<Props> = ({ formik }) => {
	const dispatch = useDispatch();

	const { consultarMunicipioPorUF, consultarMunicipioCodigoProdesp } =
		useSelector((state: ApplicationState) => state.api.detranCrv.Enum);

	const { consultarVeiculoV110 } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const [tipoPessoa, setTipoPessoa] = useState<string>('');

	useEffect(() => {
		if (consultarVeiculoV110.data) {
			const { proprietario } = consultarVeiculoV110.data.caracteristicaVeiculo;

			setTipoPessoa(proprietario.identificacao);
		}
	}, [consultarVeiculoV110.data]);

	useEffect(() => {
		if (
			consultarMunicipioCodigoProdesp.data &&
			consultarMunicipioCodigoProdesp.status === 200
		) {
			formik.setFieldValue(
				'codigoMunicipioProprietario',
				Number(consultarMunicipioCodigoProdesp.data.codigoPRODESP),
			);
			dispatch(consultarMunicipioCodigoProdesClear());
		}
	}, [
		consultarMunicipioCodigoProdesp.data,
		consultarMunicipioCodigoProdesp.status,
		dispatch,
		formik,
	]);

	const clearFormikAddressFields = useCallback(() => {
		formik.setFieldValue('complementoProprietario', '');
		formik.setFieldValue('numeroProprietario', '');
	}, [formik]);

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado) => {
			clearFormikAddressFields();

			if (res.codigoMunicipio) {
				const consultaMunicipioData = {
					evento: null,
					codigoMunicipioCorreio: res.codigoMunicipio.toString(),
				};
				dispatch(consultarMunicipioCodigoProdespRequest(consultaMunicipioData));
			}
			formik.setFieldValue('cepProprietario', res.cep);
			formik.setFieldValue('bairroProprietario', res.bairro.substring(0, 70));
			formik.setFieldValue(
				'enderecoProprietario',
				res.endereco.substring(0, 70),
			);
			formik.setFieldValue('descricaoMunicipioProprietario', res.municipio);
		},
		[clearFormikAddressFields, dispatch, formik],
	);

	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section size="lg" title="Dados do Proprietário" titleSize="sm">
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Field
									as={Input}
									title="Nome"
									titleSize="lg"
									name="nomeProprietario"
									maxLength={60}
									size={87}
									required
									disabled
									error={!!formik.errors.nomeProprietario}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<CEP
									title="CEP do Imóvel"
									titleSize="lg"
									name="cepProprietario"
									defaultValue={formik.values.cepProprietario}
									required
									size={80}
									formik={formik}
									result={(res: IBuscarCepResultado) => {
										handleCepSelecionado(res);
									}}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Input}
									title="Bairro"
									titleSize="lg"
									name="bairroProprietario"
									maxLength={70}
									size={92}
									required
									error={!!formik.errors.bairroProprietario}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Endereço"
									titleSize="lg"
									name="enderecoProprietario"
									maxLength={70}
									size={80}
									required
									error={!!formik.errors.enderecoProprietario}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={InputMask}
									title="Número"
									titleSize="lg"
									name="numeroProprietario"
									mask="99999"
									maskChar=""
									alwaysShowMask={false}
									size={92}
									required
									error={!!formik.errors.numeroProprietario}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Complemento"
									titleSize="lg"
									name="complementoProprietario"
									maxLength={6}
									size={80}
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'complementoProprietario',
											onlyNumbersLettersSpace(
												e.target.value.toLocaleUpperCase(),
											),
										);
									}}
									error={!!formik.errors.complementoProprietario}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={InputMask}
									title="Telefone"
									titleSize="lg"
									name="telefone"
									mask="(99) 9999-9999"
									size={92}
									error={!!formik.errors.telefone}
								/>
							</Col>
						</Row>

						<Row gutter={tipoPessoa === 'FISICA' ? [0, 10] : undefined}>
							<Col span={11}>
								<Field
									as={Select}
									title="Município"
									titleSize="lg"
									name="codigoMunicipioProprietario"
									options={consultarMunicipioPorUF.data}
									onChange={(v: number) => {
										formik.setFieldValue('codigoMunicipioProprietario', v);

										const selectedItem = consultarMunicipioPorUF.data.filter(
											item => v === item.value,
										);

										formik.setFieldValue(
											'descricaoMunicipioProprietario',
											selectedItem[0].label,
										);
									}}
									size={80}
									error={hasError(formik.errors, 'municipioProprietario')}
								/>
							</Col>
						</Row>

						{tipoPessoa === 'FISICA' && (
							<>
								<Row gutter={[0, 10]}>
									<Col span={11}>
										<Row>
											<Col span={18}>
												<Field
													as={InputMask}
													title="RG"
													titleSize="lg"
													name="rgProprietario"
													mask="99.999.999"
													error={hasError(formik.errors, 'rgProprietario')}
												/>
											</Col>
											<Col
												span={1}
												style={{
													alignSelf: 'center',
													textAlign: 'center',
													marginLeft: '2px',
													marginRight: '2px',
												}}
											>
												<span>-</span>
											</Col>
											<Col span={2}>
												<Field
													as={InputMask}
													mask="9"
													name="rgDigito"
													error={hasError(formik.errors, 'rgDigito')}
												/>
											</Col>
										</Row>
									</Col>
									<Col span={11}>
										<Field
											as={Input}
											title="Órgão Expedidor"
											titleSize="lg"
											name="orgaoExpeditorProprietario"
											maxLength={5}
											size={40}
											onChange={(e: { target: { value: string } }) => {
												formik.setFieldValue(
													'orgaoExpeditorProprietario',
													onlyNumbersLettersSpace(
														e.target.value.toLocaleUpperCase(),
													),
												);
											}}
											error={!!formik.errors.orgaoExpeditorProprietario}
										/>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col
										span={11}
										offset={1}
										style={{
											paddingLeft: '2px',
										}}
									>
										<Field
											as={Select}
											title="UF"
											name="ufProprietario"
											defaultFirstOption={false}
											options={data.uf}
											onChange={(value: string) =>
												formik.setFieldValue('ufProprietario', value)
											}
											size={35}
										/>
									</Col>
								</Row>
							</>
						)}
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default DadosProprietario;
