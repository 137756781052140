import React, { useEffect, useContext, useState, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

// ANTD
import { Col, Row } from 'antd';

// ICONS
import { FaLock } from 'react-icons/fa';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	limpaLogin,
	logInRequest,
	logOffRequest,
} from 'store/modules/api/sgu/loginUnico/actions';
import { limpaMainframe } from 'store/modules/sgu/actions';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import {
	alteraSenhaUsuarioClear,
	alteraSenhaUsuarioRequest,
} from 'store/modules/api/sguService/alteraSenhaUsuario/actions';
import { AlteraSenhaUsuarioRequest } from 'store/modules/api/sguService/alteraSenhaUsuario/types';
import { ILoginRequest } from 'store/modules/api/sgu/loginUnico/types';
import { setLoader } from 'store/modules/app/loader/actions';

// UTILS
import handleFailed from 'utils/getValidations';
import getValidationsErrors from 'utils/getValidationsErrors';

// COMPONENTS
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// FORM
import { Field, Form, Formik } from 'formik';

// COMPONENTS COMMON
import Alert from 'components/Common/Notifications/Alert';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import ButtonImage from 'components/Common/ButtonImage';
import Error from 'components/Common/Notifications/FormError/index';
import {
	Notification,
	Container,
	Header,
	ButtonGOV,
	ContainerGeral,
	LogoBU,
	// LogoGov,
	Text,
} from './styled';

// FORM
import { FormLogin, initialValues, schema } from './form';

const Login: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const testMode =
		process.env.REACT_APP_ENVIRONMENT !== undefined &&
		process.env.REACT_APP_ENVIRONMENT === 'TEST';

	const { tokenData, login, logOut, loginInProgress } =
		useContext<IAuthContext>(AuthContext);

	const [formInitialValues, setFormInitialValues] =
		useState<FormLogin>(initialValues);

	const { alteraSenhaUsuario } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const { user, reload, resetSenha } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const { consultaMainframe } = useSelector(
		(state: ApplicationState) => state.sgu,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	useEffect(() => {
		if (testMode) {
			if (!resetSenha?.status) {
				setFormInitialValues(initialValues);
			}

			if (resetSenha?.status) {
				setFormInitialValues((prev: FormLogin) => {
					return {
						...prev,
						alteraSenha: true,
					};
				});
			}
		}
	}, [testMode, resetSenha]);

	useEffect(() => {
		if (!testMode) {
			if (loginInProgress) {
				setLoader(true);
			} else {
				setLoader(false);
			}
		}
	}, [testMode, loginInProgress]);

	useEffect(() => {
		if (testMode) {
			if (alteraSenhaUsuario.status === 200) {
				dispatch(limpaLogin());
			}
		}
	}, [testMode, dispatch, alteraSenhaUsuario.status]);

	useEffect(() => {
		if (!testMode) {
			setTimeout(() => {
				if (errors[0] === 'Usuário não cadastrado ou inativo no sistema') {
					alert('Usuário não cadastrado ou inativo no sistema');
					dispatch(clearNotifications());
					if (tokenData) {
						logOut();
					}
				}
			}, 2000);
		}
	}, [testMode, logOut, errors, dispatch, tokenData]);

	useEffect(() => {
		if (!testMode) {
			try {
				if (tokenData?.preferred_username) {
					dispatch(
						logInRequest({
							cpf: tokenData?.preferred_username,
							senha: '',
						}),
					);
				}
			} catch (error) {
				handleFailed(error);
			}
		}
	}, [testMode, tokenData, dispatch]);

	useEffect(() => {
		dispatch(limpaMainframe());

		setLoader(false);

		dispatch(limpaLogin());

		if (testMode) {
			dispatch(alteraSenhaUsuarioClear());
		}
	}, [testMode, dispatch]);

	useEffect(() => {
		if (consultaMainframe.status !== 0) dispatch(limpaMainframe());
	}, [consultaMainframe, dispatch]);

	useEffect(() => {
		if (reload) {
			if (testMode) {
				localStorage.clear();

				dispatch(logOffRequest());

				window.location.reload();
			} else {
				localStorage.clear();

				if (tokenData) {
					logOut();
				}

				dispatch(logOffRequest());

				history.push('/');
			}
		}
	}, [testMode, dispatch, reload, logOut, history, tokenData]);

	useEffect(() => {
		/* VALIDANDO SE O USUÁRIO TEM ACESSO À ALGUM ORGÃO - INICIO */
		if (!user?.login) {
			addNotifications({
				errors: ['O usuário não possui acesso a nenhum orgão do poupatempo.'],
			});

			dispatch(logOffRequest());
		}
		/* VALIDANDO SE O USUÁRIO TEM ACESSO À ALGUM ORGÃO - FIM */
	}, [dispatch, user]);

	const handleValidate = useCallback((formValues: FormLogin) => {
		return getValidationsErrors(formValues, schema);
	}, []);

	const handleModeTestSubmit = useCallback(
		(formValues: FormLogin) => {
			if (alteraSenhaUsuario?.status === 200) {
				dispatch(alteraSenhaUsuarioClear());
			}
			setFormInitialValues(formValues);
			if (resetSenha?.status && formValues.senhaNova) {
				const payload: AlteraSenhaUsuarioRequest = {
					cpf: (formValues.cpf = formValues.cpf.replace(/[.-]+/g, '')),
					senhaAtual: formValues.senha,
					senhaNova: formValues.senhaNova,
				};
				dispatch(alteraSenhaUsuarioRequest(payload));
			} else {
				const payload: ILoginRequest = {
					cpf: (formValues.cpf = formValues.cpf.replace(/[.-]+/g, '')),
					senha: formValues.senha,
				};
				dispatch(logInRequest(payload));
			}
		},
		[alteraSenhaUsuario, dispatch, resetSenha],
	);

	const handleSubmit = async () => {
		login();
	};

	return testMode ? (
		<Notification>
			{resetSenha.status && (
				<Alert message={resetSenha.message} type="info" onClose={() => {}} />
			)}
			{alteraSenhaUsuario.status === 200 && (
				<Alert
					message="Senha alterada com sucesso. Faça login com a nova senha"
					type="info"
					onClose={() => {}}
				/>
			)}
			<Container>
				<Header>
					<FaLock />
					<h1>LOGIN</h1>
				</Header>

				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={formInitialValues}
					validate={handleValidate}
					onSubmit={handleModeTestSubmit}
					enableReinitialize
				>
					{formik => (
						<Form autoComplete="off">
							<Row gutter={[0, 10]}>
								<Col span={24}>
									<Field
										as={InputMask}
										title="Usuário"
										name="cpf"
										maskChar=""
										size={80}
										mask="999.999.999-99"
										error={formik.errors.validacaoLogin || formik.errors.cpf}
									/>
								</Col>
							</Row>
							{!resetSenha?.status && (
								<Row gutter={[0, 10]}>
									<Col>
										<Field
											id="senha-login"
											title="Senha"
											name="senha"
											maxLength={10}
											size={80}
											as={Input}
											type="password"
											error={
												formik.errors.validacaoLogin || formik.errors.senha
											}
										/>
									</Col>
								</Row>
							)}
							{resetSenha?.status && (
								<>
									<Row gutter={[0, 10]}>
										<Col>
											<Field
												id="senhaNova"
												title="Nova Senha"
												name="senhaNova"
												maxLength={10}
												size={80}
												as={Input}
												type="password"
												defaultValue=""
												autoComplete="off"
												error={formik.errors.validaNovaSenha}
											/>
										</Col>
									</Row>
									<Row gutter={[0, 10]}>
										<Col>
											<Field
												title="Confirmação"
												name="confirmacaoSenha"
												maxLength={10}
												size={80}
												as={Input}
												autoComplete="off"
												type="password"
												error={formik.errors.validaNovaSenha}
											/>
										</Col>
									</Row>
								</>
							)}
							<Row gutter={[0, 10]}>
								<Col />
							</Row>
							<Row gutter={[20, 10]} justify="center">
								{resetSenha?.status && (
									<Col>
										<ButtonImage
											type="button"
											src="retornar"
											onClick={() => dispatch(limpaLogin())}
										/>
									</Col>
								)}
								<Col>
									<ButtonImage type="submit" src="entrar" />
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</Container>
		</Notification>
	) : (
		<ContainerGeral>
			<Row>
				<Col span={12} offset={6}>
					{notifications.errors &&
						notifications.errors.length > 0 &&
						!notifications.showInside && (
							<Error
								errors={notifications.errors}
								moveToTopWhenDisplayed={notifications.moveToTop}
							/>
						)}
				</Col>
			</Row>
			<Notification>
				<Container>
					<Header>
						<LogoBU />
					</Header>
					<Text>
						Sistema Integrado do <br /> Poupatempo
					</Text>
					<ButtonGOV onClick={handleSubmit}>
						<span>Entrar com</span> gov.br
					</ButtonGOV>
					{/* <LogoGov /> */}
				</Container>
			</Notification>
		</ContainerGeral>
	);
};

export default Login;
