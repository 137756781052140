import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_SGU_SERVICE } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { SalvarAtendimentoRequest, Types } from './types';

// ACTIONS
import { salvarAtendimentoSuccess, salvarAtendimentoFailure } from './actions';

function* salvarAtendimentoRequest(request: ReducerAction) {
	const { payload }: { payload: SalvarAtendimentoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU_SERVICE,
		`atendimento/${CHANNEL}/salvar`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(salvarAtendimentoSuccess(response.data));
	} else {
		yield put(salvarAtendimentoFailure());
	}
}

export default all([
	takeLatest(Types.SALVAR_ATENDIMENTO_REQUEST, salvarAtendimentoRequest),
]);
