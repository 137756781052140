import React from 'react';

import { Popover } from 'antd';
import { RenderFunction } from 'antd/lib/tooltip';

// ICONS
import info from 'assets/images/icons/info.png';

// STYLED
import { Image } from './styled';
import CreateGlobalStyle from '../reset';

interface Props {
	title?: string;
	content?: React.ReactNode | RenderFunction;
	customComponent?: React.ReactNode;
}

const TooltipInfo: React.FC<Props> = ({ title, content, customComponent }) => {
	return (
		<>
			<Popover title={title} content={content}>
				{customComponent || <Image src={info} alt="Informação" />}
			</Popover>

			<CreateGlobalStyle />
		</>
	);
};

export default TooltipInfo;
