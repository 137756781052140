import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirUsuarioRequest } from './types';

export function excluirUsuarioRequest(
	data: ExcluirUsuarioRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_USUARIO,
		payload: data,
	};
}
export function excluirUsuarioSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_USUARIO_SUCCESS,
		payload,
	};
}
export function excluirUsuarioFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_USUARIO_FAILURE,
		payload: null,
	};
}
export function excluirUsuarioClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_USUARIO_CLEAR,
		payload: null,
	};
}
