import { combineReducers } from 'redux';

import eventoInicioSessao from './inicioSessao/reducer';
import eventoTerminoSessao from './terminoSessao/reducer';
import eventoConfrontoSessao from './confronto/reducer';

export default combineReducers({
	eventoInicioSessao,
	eventoTerminoSessao,
	eventoConfrontoSessao,
});
