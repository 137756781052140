import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ApiResponse, postApi } from 'services/_api';

import { PATH_DETRAN_CRV, CHANNEL } from 'services/_path';

import { ReducerAction } from 'store/modules/types';

import { Types, IPEBERequest } from './types';

import { consultarPEBESuccess, consultarPEBEFailure } from './actions';

function* consultarPEBERequest(request: ReducerAction) {
	const { payload }: { payload: IPEBERequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`resumo-veiculo/${CHANNEL}/pesquisa/pebe`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarPEBESuccess(response));
	} else if (response.error) {
		yield put(consultarPEBEFailure(response));
	}
}

export default all([takeLatest(Types.PEBE_REQUEST, consultarPEBERequest)]);
