import React from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';

// FORM
import Radio from 'components/Common/Form/Radio';
import data from 'store/modules/api/detranCrv/enum';
import { RadioChangeEvent } from 'antd/lib/radio';
import { onlyNumbers, onlyNumbersLettersSpace } from 'utils/genericFunctions';
import { IEmitirAlteracaoCadastral } from '../form';

// UTILS

interface Props {
	formik: FormikProps<IEmitirAlteracaoCadastral>;
}

const Modificacao: React.FC<Props> = ({ formik }) => {
	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section
						size="lg"
						title="Modificação Dados Cadastrais"
						titleSize="sm"
					>
						<Row gutter={[0, 10]} justify="space-around">
							<Col span={6}>
								<Field
									titleSize="xl"
									title="Plataforma"
									as={Radio}
									name="plataformaModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									// titleSize="sm"
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('plataformaModificacao', value);
									}}
								/>
							</Col>
							<Col span={6} />
							<Col span={10}>
								<Field
									titleSize="xl"
									title="Parachoque"
									as={Radio}
									name="parachoqueModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('parachoqueModificacao', value);
									}}
								/>
							</Col>
							<Col span={2} />
						</Row>
						<Row gutter={[0, 10]} justify="space-around">
							<Col span={6}>
								<Field
									titleSize="xl"
									title="Cabine Dupla"
									as={Radio}
									name="cabineDuplaModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									// titleSize="sm"
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('cabineDuplaModificacao', value);
									}}
								/>
							</Col>
							<Col span={6} />
							<Col span={10}>
								<Field
									titleSize="xl"
									title="Alongamento Chassi"
									as={Radio}
									name="alongamentoChassiModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('alongamentoChassiModificacao', value);
									}}
								/>
							</Col>
							<Col span={2} />
						</Row>
						<Row gutter={[0, 10]} justify="space-around">
							<Col span={6}>
								<Field
									titleSize="xl"
									title="Cabine Estendida"
									as={Radio}
									name="cabineEstendidaModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									// titleSize="sm"
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('cabineDuplaModificacao', value);
									}}
								/>
							</Col>
							<Col span={6} />
							<Col span={10}>
								<Field
									titleSize="xl"
									title="Suspensão Pneumática"
									as={Radio}
									name="suspensaoPneumaticaModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue(
											'suspensaoPneumaticaModificacao',
											value,
										);
									}}
								/>
							</Col>
							<Col span={2} />
						</Row>
						<Row gutter={[0, 10]} justify="space-around">
							<Col span={6}>
								<Field
									titleSize="xl"
									title="Blindagem"
									as={Radio}
									name="blindagemModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									// titleSize="sm"
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('blindagemModificacao', value);
									}}
								/>
							</Col>
							<Col span={6} />
							<Col span={10}>
								<Field
									titleSize="xl"
									title="Encurtamento de Chassi"
									as={Radio}
									name="encurtamentoChassiModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue(
											'encurtamentoChassiModificacao',
											value,
										);
									}}
								/>
							</Col>
							<Col span={2} />
						</Row>
						<Row gutter={[0, 10]} justify="space-around">
							<Col span={6}>
								<Field
									titleSize="xl"
									title="Iluminação Frontal"
									as={Radio}
									name="iluminacaoFrontalSinalizacaoModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									// titleSize="sm"
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue(
											'iluminacaoFrontalSinalizacaoModificacao',
											value,
										);
									}}
								/>
							</Col>
							<Col span={6} />
							<Col span={10}>
								<Field
									titleSize="xl"
									title="Rebaixamento"
									as={Radio}
									name="rebaixamentoModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('rebaixamentoModificacao', value);
									}}
								/>
							</Col>
							<Col span={2} />
						</Row>
						<Row gutter={[0, 10]} justify="space-around">
							<Col span={6}>
								<Field
									titleSize="xl"
									title="Reencarroçamento"
									as={Radio}
									name="reencarrocamentoModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									// titleSize="sm"
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('reencarrocamentoModificacao', value);
									}}
								/>
							</Col>
							<Col span={6} />
							<Col span={10}>
								<Field
									titleSize="xl"
									title="Acessibilidade Condutor"
									as={Radio}
									name="acessibilidadeCondutorModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue(
											'acessibilidadeCondutorModificacao',
											value,
										);
									}}
								/>
							</Col>
							<Col span={2} />
						</Row>
						<Row gutter={[0, 10]} justify="space-around">
							<Col span={6}>
								<Field
									titleSize="xl"
									title="Freios"
									as={Radio}
									name="freiosModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									// titleSize="sm"
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('freiosModificacao', value);
									}}
								/>
							</Col>
							<Col span={6} />
							<Col span={10}>
								<Field
									titleSize="xl"
									title="Roda-Pneu"
									as={Radio}
									name="rodaPneuModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('rodaPneuModificacao', value);
									}}
								/>
							</Col>
							<Col span={2} />
						</Row>
						<Row gutter={[0, 10]} justify="space-around">
							<Col span={6}>
								<Field
									titleSize="xl"
									title="Visual"
									as={Radio}
									name="visualModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									// titleSize="sm"
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('visualModificacao', value);
									}}
								/>
							</Col>
							<Col span={6} />
							<Col span={10}>
								<Field
									titleSize="xl"
									title="Insulfilme"
									as={Radio}
									name="insulfilmeModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('insulfilmeModificacao', value);
									}}
								/>
							</Col>
							<Col span={2} />
						</Row>
						<Row gutter={[0, 10]} justify="space-around">
							<Col span={6}>
								<Field
									titleSize="xl"
									title="Potência Cilindrada"
									as={Radio}
									name="potenciaCilindradaModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									// titleSize="sm"
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue(
											'potenciaCilindradaModificacao',
											value,
										);
									}}
								/>
							</Col>
							<Col span={6} />
							<Col span={10}>
								<Field
									titleSize="xl"
									title="Cabine Suplementar"
									as={Radio}
									name="capacidadeVolumeTanqueSuplementarModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue(
											'insulfilmcapacidadeVolumeTanqueSuplementarModificacaoeModificacao',
											value,
										);
									}}
								/>
							</Col>
							<Col span={2} />
						</Row>
						<Row gutter={[0, 10]} justify="space-around">
							<Col span={6}>
								<Field
									titleSize="xl"
									title="Eixo"
									as={Radio}
									name="eixoModificacao"
									options={[
										{ label: 'Auto-Direcional', value: 'AUTO_DIRECIONAL' },
										{
											label: 'Pneumático',
											value: 'PNEUMATICO',
										},
										{
											label: 'Direcional',
											value: 'DIRECIONAL',
										},
									]}
									defaultValue={false}
									// titleSize="sm"
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('eixoModificacao', value);
									}}
								/>
							</Col>
							<Col span={18} />
						</Row>
						<Row gutter={[0, 10]} justify="space-around">
							<Col span={6}>
								<Field
									titleSize="xl"
									title="Teto Solar"
									as={Radio}
									name="tetoSolarModificacao"
									options={data.simNaoBoolean}
									defaultValue={false}
									// titleSize="sm"
									onChange={(e: RadioChangeEvent) => {
										const { value } = e.target;
										formik.setFieldValue('tetoSolarModificacao', value);
									}}
								/>
							</Col>
							<Col span={18} />
							<Col span={6} />
							<Col span={18}>
								<span style={{ color: 'red' }}>
									(Somente para os veículos: 06-Automóvel , 13-Camioneta,
									14-Caminhão, 17-Caminhão-Trator, 23-Caminhonete,
									25-Utilitário)
								</span>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Certificado Seg. Veicular - CSV"
									titleSize="xl"
									name="certificadoSegVeicularModificacao"
									size={80}
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'certificadoSegVeicularModificacao',
											onlyNumbersLettersSpace(
												e.target.value.toLocaleUpperCase(),
											),
										);
									}}
									required={formik.values.tanqueSuplementarModificacao}
									error={!!formik.errors.certificadoSegVeicularModificacao}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Input}
									title="CSV Ano"
									titleSize="xl"
									name="csvAnoModificacao"
									size={80}
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'csvAnoModificacao',
											onlyNumbers(e.target.value.toLocaleUpperCase()),
										);
									}}
									required={formik.values.tanqueSuplementarModificacao}
									error={!!formik.errors.csvAnoModificacao}
								/>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Distância Eixo"
									titleSize="xl"
									name="distanciaEixoModificacao"
									size={80}
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'distanciaEixoModificacao',
											onlyNumbers(e.target.value.toLocaleUpperCase()),
										);
									}}
									error={!!formik.errors.distanciaEixoModificacao}
								/>
							</Col>

							<Col span={11} />
						</Row>

						<Row gutter={[0, 10]} justify="space-around">
							<Section>
								<Row gutter={[0, 10]} justify="space-around">
									<Col span={6}>
										<Field
											titleSize="xl"
											title="Tanque Suplementar"
											as={Radio}
											name="tanqueSuplementarModificacao"
											options={data.simNaoBoolean}
											defaultValue={false}
											// titleSize="sm"
											onChange={(e: RadioChangeEvent) => {
												const { value } = e.target;
												formik.setFieldValue(
													'tanqueSuplementarModificacao',
													value,
												);
											}}
										/>
									</Col>
									<Col span={18} />
								</Row>
								{formik.values.tanqueSuplementarModificacao && (
									<Row gutter={[0, 10]}>
										<Col span={9}>
											<Field
												as={Input}
												title="Quantidade Tanque Supl."
												titleSize="lg"
												name="quantidadeTanqueSuplementarModificacao"
												size={80}
												required
												onChange={(e: { target: { value: string } }) => {
													formik.setFieldValue(
														'quantidadeTanqueSuplementarModificacao',
														onlyNumbers(e.target.value.toLocaleUpperCase()),
													);
												}}
												error={
													!!formik.errors.quantidadeTanqueSuplementarModificacao
												}
											/>{' '}
										</Col>
										<Col span={9}>
											{' '}
											<Field
												as={Input}
												title="Capacidade Vol Tanque"
												titleSize="lg"
												name="capacidadeVolumeTanqueSuplementarModificacao"
												size={80}
												required
												onChange={(e: { target: { value: string } }) => {
													formik.setFieldValue(
														'capacidadeVolumeTanqueSuplementarModificacao',
														onlyNumbers(e.target.value.toLocaleUpperCase()),
													);
												}}
												error={
													!!formik.errors
														.capacidadeVolumeTanqueSuplementarModificacao
												}
											/>
										</Col>
									</Row>
								)}
							</Section>
						</Row>
						<Row gutter={[0, 10]} justify="space-around">
							<Section>
								<Row gutter={[0, 10]} justify="space-around">
									<Col span={6}>
										<Field
											titleSize="xl"
											title="Suspensão"
											as={Radio}
											name="suspensaoModificacao"
											options={data.simNaoBoolean}
											defaultValue={false}
											// titleSize="sm"
											onChange={(e: RadioChangeEvent) => {
												const { value } = e.target;
												formik.setFieldValue('suspensaoModificacao', value);
											}}
										/>
									</Col>
									<Col span={18} />
								</Row>
								{formik.values.suspensaoModificacao && (
									<Row gutter={[0, 10]}>
										<Col span={9}>
											<Field
												as={Input}
												title="Valor da Suspensão"
												titleSize="lg"
												name="valorSuspensaoModificacao"
												size={80}
												required
												onChange={(e: { target: { value: string } }) => {
													formik.setFieldValue(
														'valorSuspensaoModificacao',
														onlyNumbers(e.target.value.toLocaleUpperCase()),
													);
												}}
												error={!!formik.errors.valorSuspensaoModificacao}
											/>
										</Col>
									</Row>
								)}
							</Section>
						</Row>
						<Row gutter={[0, 10]} justify="space-around">
							<Section>
								<Row gutter={[0, 10]} justify="space-around">
									<Col span={6}>
										<Field
											titleSize="xl"
											title="Acessibilidade de Passageiro"
											as={Radio}
											name="acessibilidadePassageiroModificacao"
											options={data.simNaoBoolean}
											defaultValue={false}
											// titleSize="sm"
											onChange={(e: RadioChangeEvent) => {
												const { value } = e.target;
												formik.setFieldValue(
													'acessibilidadePassageiroModificacao',
													value,
												);
											}}
										/>
									</Col>
									<Col span={18} />
								</Row>
								{formik.values.acessibilidadePassageiroModificacao && (
									<Row gutter={[0, 10]}>
										<Col span={9}>
											<Field
												as={Input}
												title="Tipo de Acessibilidade de Passageiro"
												titleSize="lg"
												name="tipoAcessibilidadePassageiroModificacao"
												size={80}
												required
												onChange={(e: { target: { value: string } }) => {
													formik.setFieldValue(
														'tipoAcessibilidadePassageiroModificacao',
														onlyNumbersLettersSpace(
															e.target.value.toLocaleUpperCase(),
														),
													);
												}}
												error={
													!!formik.errors
														.tipoAcessibilidadePassageiroModificacao
												}
											/>
										</Col>
									</Row>
								)}
							</Section>
						</Row>
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default Modificacao;
