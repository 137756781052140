import { DIVISAO_EQUITATIVA_PARAMS } from 'pages/DetranCnh/utils/constants/services';
import * as Yup from 'yup';

export interface ICadastraExameMedico {
	cpf_cidadao: number;
	tipo_processo: string;
	cep_cidadao: number;
	categoria_pretendida: string;
	id_medico: number;
	data_exame: string;
	horario_exame: string;
	codigo_ciretran_exame_medico?: number;
	codigo_ciretran?: number | string;
	renach: string;
	id_micro_regiao_medico: number | string;
	usuario: string;
	regiao?: string;
}

export const dadosAgendamento: ICadastraExameMedico = {
	cpf_cidadao: 0,
	tipo_processo: '',
	cep_cidadao: 0,
	categoria_pretendida: '',
	id_medico: 0,
	data_exame: '',
	horario_exame: '',
	codigo_ciretran_exame_medico: 0,
	codigo_ciretran: '',
	renach: '',
	id_micro_regiao_medico: '',
	usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
	regiao: '',
};

export const schema = Yup.object<ICadastraExameMedico>().shape({
	regiao: Yup.string().when('codigo_ciretran', {
		is: codigo_ciretran => codigo_ciretran === '18',
		then: Yup.string().required('Campo Obrigatório: Região.'),
	}),
	id_micro_regiao_medico: Yup.string().when('codigo_ciretran', {
		is: codigo_ciretran => codigo_ciretran === '18',
		then: Yup.string().required('Campo Obrigatório: Micro Região.'),
	}),
	data_exame: Yup.string().required('Campo Obrigatório: Data.'),
	horario_exame: Yup.string().required('Campo Obrigatório: Hora.'),
});
