import { combineReducers } from 'redux';

import atualizarStatus from './atualizarStatus/reducer';
import consultarStatus from './consultarStatus/reducer';
import cadastrarStatus from './cadastrarStatus/reducer';
import excluirStatus from './excluirStatus/reducer';

export default combineReducers({
	atualizarStatus,
	consultarStatus,
	cadastrarStatus,
	excluirStatus,
});
