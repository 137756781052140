import { ReducerAction } from 'store/modules/types';

import { ExcluirNaturalidadeRequest, Types } from './types';

export function excluirNaturalidadeRequest(
	payload: ExcluirNaturalidadeRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_NATURALIDADE,
		payload,
	};
}
export function excluirNaturalidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_NATURALIDADE_SUCCESS,
		payload,
	};
}
export function excluirNaturalidadeFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_NATURALIDADE_FAILURE,
		payload: null,
	};
}
export function excluirNaturalidadeClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_NATURALIDADE_CLEAR,
		payload: null,
	};
}
