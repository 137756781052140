import React from 'react';

// ANTD
import { Col, Row } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

//	COMPONENTS
import { v4 as uuidV4 } from 'uuid';

//	COMPONENTS COMMON
import Section from 'components/Common/Section';

// UTILS
import { isFilledValue } from 'utils/stringFunctions';
import { patternFormat } from 'utils/genericFunctions';
import { formatDateISOToFullUTC } from 'utils/dateFunctions';

const ResultadoDBCIN: React.FC = () => {
	const { consultaDBCIN } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	return (
		<Row>
			<Col span={24}>
				<Section title="Resultado da Consulta">
					<Section key={uuidV4()} style={{ marginTop: '5px' }}>
						<Row>
							<Col span={24}>
								<p>
									<strong>RG: </strong>
									{isFilledValue(
										patternFormat(
											consultaDBCIN.data?.registroNumero?.concat(
												consultaDBCIN.data?.registroDigito,
											),
											`##.###.###-#`,
										),
									)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>CPF: </strong>
									{isFilledValue(
										patternFormat(
											consultaDBCIN.data?.usuarioInclusaoCpf,
											`###.###.###-##`,
										),
									)}
								</p>
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={24}>
								<p>
									<strong>Dados RFB: </strong>
								</p>
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={24}>
								<p>
									<strong>Código Hash: </strong>
									{isFilledValue(consultaDBCIN.data?.codigoHashRfb)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Protocolo: </strong>
									{isFilledValue(consultaDBCIN.data?.protocoloRfb)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Data Protocolo: </strong>
									{consultaDBCIN.data && consultaDBCIN.data?.dataProtocoloRfb
										? formatDateISOToFullUTC(
												new Date(consultaDBCIN.data?.dataProtocoloRfb),
										  )
										: isFilledValue(consultaDBCIN.data?.dataProtocoloRfb)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Indicador Consistência Biográfica: </strong>
									{isFilledValue(
										consultaDBCIN.data?.indicadorConsistenciaBiografica,
									)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Indicador Consistência Biométrica: </strong>
									{isFilledValue(
										consultaDBCIN.data?.indicadorConsistenciaBiometrica,
									)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Situação SERPRO: </strong>
									{isFilledValue(consultaDBCIN.data?.situacaoSerpro)}
								</p>
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={24}>
								<p>
									<strong>Dados RFB: </strong>
								</p>
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={24}>
								<p>
									<strong>Posto Emissor: </strong>
									{isFilledValue(consultaDBCIN.data?.postoEmissor)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Tipográfico: </strong>
									{isFilledValue(consultaDBCIN.data?.tipoGrafico)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Protocolo da Imagem: </strong>
									{isFilledValue(consultaDBCIN.data?.prtValidImagem)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Imagem CIN: </strong>
									{isFilledValue(consultaDBCIN.data?.cinImagem)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>QRCode CIN: </strong>
									{isFilledValue(consultaDBCIN.data?.cinQrCode)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Código MRZ Passaporte: </strong>
									{isFilledValue(consultaDBCIN.data?.codMrzPassaporte)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Data Emissão: </strong>
									{consultaDBCIN.data && consultaDBCIN.data?.dataEmissao
										? formatDateISOToFullUTC(
												new Date(consultaDBCIN.data?.dataEmissao),
										  )
										: isFilledValue(consultaDBCIN.data?.dataEmissao)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Situação: </strong>
									{`${isFilledValue(
										consultaDBCIN.data?.situacao,
									)} - ${isFilledValue(consultaDBCIN.data?.situacaoDescricao)}`}
									{/* {isFilledValue(
										`${consultaDBCIN.data?.situacao} - ${consultaDBCIN.data?.situacaoDescricao}`,
									)} */}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Observações: </strong>
									{isFilledValue(consultaDBCIN.data?.observacao)}
								</p>
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={24}>
								<p>
									<strong>Atualizações: </strong>
								</p>
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={24}>
								<p>
									<strong>Inclusão: </strong>
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Usuário: </strong>
									{isFilledValue(consultaDBCIN.data?.usuarioInclusao)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>CPF: </strong>
									{isFilledValue(
										patternFormat(
											consultaDBCIN.data?.usuarioInclusaoCpf,
											`###.###.###-##`,
										),
									)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Data: </strong>
									{consultaDBCIN.data && consultaDBCIN.data?.dataInclusao
										? formatDateISOToFullUTC(
												new Date(consultaDBCIN.data?.dataInclusao),
										  )
										: isFilledValue(consultaDBCIN.data?.dataInclusao)}
								</p>
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={24}>
								<p>
									<strong>Alteração: </strong>
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Usuário: </strong>
									{isFilledValue(consultaDBCIN.data?.usuarioAlteracao)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>CPF: </strong>
									{isFilledValue(
										patternFormat(
											consultaDBCIN.data?.usuarioAlteracaoCpf,
											`###.###.###-##`,
										),
									)}
								</p>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<p>
									<strong>Data: </strong>
									{consultaDBCIN.data && consultaDBCIN.data?.dataAlteracao
										? formatDateISOToFullUTC(
												new Date(consultaDBCIN.data?.dataAlteracao),
										  )
										: isFilledValue(consultaDBCIN.data?.dataAlteracao)}
								</p>
							</Col>
						</Row>
					</Section>
				</Section>
			</Col>
		</Row>
	);
};

export default ResultadoDBCIN;
