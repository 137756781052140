import * as Yup from 'yup';

import { validaCpf } from 'utils/genericFunctions';
import { ConsultaExtratoResumoCondutorForm } from 'store/modules/api/detranCnh/eportal/consultaExtratoResumoCondutor/types';

export const initalValues: ConsultaExtratoResumoCondutorForm = {
	cpf: '',
};

export const schema = Yup.object<ConsultaExtratoResumoCondutorForm>().shape({
	cpf: Yup.string()
		.required('Campo Obrigatório: CPF.')
		.test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF digitado inválido.',
			test: value => validaCpf(value),
		}),
});
