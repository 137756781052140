import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_PERFIS_REQUEST = '@cnh/CONSULTAR_PERFIS_REQUEST',
	CONSULTAR_PERFIS_SUCCESS = '@cnh/CONSULTAR_PERFIS_SUCCESS',
	CONSULTAR_PERFIS_FAILURE = '@cnh/CONSULTAR_PERFIS_FAILURE',
	CONSULTAR_PERFIS_CLEAR = '@cnh/CONSULTAR_PERFIS_CLEAR',
}

export interface ConsultarPerfis {
	status: number;
	data: Perfil[] | null;
	enum: OptionProps[];
	totalRegistrosConsulta: number;
}

export interface Perfil {
	codigo?: string | number;
	status?: IStatus;
	descricao: string;
	codigoStatus: string | number;
	acessaSgu: boolean;
}

export interface IStatus {
	codigo: string | number;
	descricao: string;
}

export interface ConsultarPerfisRequest {
	registroInicial?: number;
	limite?: number;
	current?: number;
	codigo?: string | number;
	codigoStatus?: string | number;
	acessaSgu?: boolean;
	descricao?: string;
}
