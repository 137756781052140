export enum Types {
	CONSULTA_ATENDIMENTOS_DE_CONSULTA_REQUEST = '@procon/CONSULTA_ATENDIMENTOS_DE_CONSULTA_REQUEST',
	CONSULTA_ATENDIMENTOS_DE_CONSULTA_SUCCESS = '@procon/CONSULTA_ATENDIMENTOS_DE_CONSULTA_SUCCESS',
	CONSULTA_ATENDIMENTOS_DE_CONSULTA_FAILURE = '@procon/CONSULTA_ATENDIMENTOS_DE_CONSULTA_FAILURE',
	CONSULTA_ATENDIMENTOS_DE_CONSULTA_CLEAR = '@procon/CONSULTA_ATENDIMENTOS_DE_CONSULTA_CLEAR',
}

export interface ConsultaAtendimentosDeConsulta {
	status: number;
	data: null | ConsultaAtendimentosDeConsultaData;
}

export interface ConsultaAtendimentosDeConsultaData {
	idDoBalcaoUnico: string;
	atendimentoId: string;
	area: string;
	assunto: string;
	dataDaContratacao: string;
	dataDaSolicitacao: string;
	descricao: string;
	grupoDeProblema: string;
	nomeFornecedor: string;
	nomeFantasiaFornecedor: string;
	prazo: string;
	problema: string;
	protocolo: string;
	produtoOuServicoContratado: string;
	tipoDeAtendimento: string;
	razaoDoStatus: string;
	tipoDoConsumidor?: string;
	fornecedor: {
		cnpj: string;
		cpf: string;
		email: string;
		endereco: {
			bairro: string;
			cep: string;
			complemento: string;
			estado: string;
			logradouro: string;
			municipio: string;
			municipioId: string;
			numero: string;
			cidade: string;
		};
		telefone: string;
		razaoSocial: string;
		nomeFantasia: string;
		website: string;
	};
	tipoDoFornecedor: string;
	procureiOFornecedorParaSolucionarAQuestao: string;
	formaDeDevolucao: string;
	pedidoDoConsumidor: string;
	descricaoDoPrimeiroContatoComFornecedor: string;
	detalhesDaReclamacao: string;
	interacao: string;
	nomeDoResponsavelOuProcurador: string;
	numeroFa?: string;
	podeResolver: boolean;
	podeCancelar: boolean;
}

export interface ConsultaAtendimentosDeConsultaRequest {
	atendimentoId: string;
	protocolo: string;
	numeroFa: string;
}
