import { ReducerActionRotaSP } from 'store/modules/types';
import { Types } from './types';

export function comboMunicipiosSPRequest(token: string): ReducerActionRotaSP {
	return {
		type: Types.COMBO_MUNICIPIOS_SP_REQUEST,
		token,
		payload: null,
	};
}
export function comboMunicipiosSPSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.COMBO_MUNICIPIOS_SP_SUCCESS,
		payload,
	};
}

export function comboMunicipiosSPFailure(payload: object): ReducerActionRotaSP {
	return {
		type: Types.COMBO_MUNICIPIOS_SP_FAILURE,
		payload,
	};
}
