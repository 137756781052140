import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { SinalizaResolucaoConsultaAtendimentoRequest, Types } from './types';

// ACTIONS
import {
	sinalizaResolucaoConsultaAtendimentoSuccess,
	sinalizaResolucaoConsultaAtendimentoFailure,
} from './actions';

function* sinalizaResolucaoConsultaAtendimentoRequest(request: ReducerAction) {
	const { payload }: { payload: SinalizaResolucaoConsultaAtendimentoRequest } =
		request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`atendimentos/${CHANNEL}/resolucoes`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(sinalizaResolucaoConsultaAtendimentoSuccess(response));
	} else {
		yield put(sinalizaResolucaoConsultaAtendimentoFailure(response));
	}
}

export default all([
	takeLatest(
		Types.SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_REQUEST,
		sinalizaResolucaoConsultaAtendimentoRequest,
	),
]);
