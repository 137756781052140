import { all } from 'redux-saga/effects';

import atualizarTiposSaga from './atualizarTipos/saga';
import consultarTiposSaga from './consultarTipos/saga';
import cadastrarTipos from './cadastrarTipos/saga';
import excluirTiposSaga from './excluirTipos/saga';

export default all([
	atualizarTiposSaga,
	consultarTiposSaga,
	cadastrarTipos,
	excluirTiposSaga,
]);
