import * as Yup from 'yup';

// UTILS
import {
	concatenatesTaxaServicoPix,
	limparMascara,
	separarDDD,
} from 'utils/genericFunctions';

// TYPES
import {
	IRequestSegundaViaCrv,
	IProprietarioFisicoEmitirSegundaViaCrv,
	IProprietarioJuridicoEmitirSegundaViaCrv,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirSegundaViaCRV/types';
import { IEvento } from 'store/modules/api/detranCrv/types';

import { userDataRegex } from 'utils/constants/regex';
import { formatRg } from '../../formFunctions';

interface ITaxaServico {
	codBanco: string;
	dataContabil: string;
	nsuProdesp: string;
}
export interface IEmitirSegundaViaCrv {
	evento: IEvento;
	placa: string;
	protocoloRemarcacao: number | string;
	codigoMunicipio: number | string;
	descricaoMunicipio: string;
	renavam: string | number;
	chassi: string;
	chassiRemarcado: boolean;
	longIdMarca: number | string;
	descricaoMarca: string;
	anoModelo: number | string;
	longIdTipo: number | string;
	descricaoTipo: string;
	longIdCarroceria: number | string;
	descricaoCarroceria: string;
	longIdCor: number | string;
	descricaoCor: string;
	longIdCategoria: number | string;
	descricaoCategoria: string;
	longIdCombustivel: number | string;
	descricaoCombustivel: string;
	longIdEspecie: number | string;
	descricaoEspecie: string;
	capacidadePassageiro: number | string;
	capacidadeCarga: number | string;
	potencia: number | string;
	cilindrada: number | string;
	cmt: number | string;
	pbt: number | string;
	eixos: number | string;
	procedencia: string;
	nomeProprietario: string;
	cepProprietario: string;
	bairroProprietario: string;
	enderecoProprietario: string;
	numeroProprietario: string;
	complementoProprietario: string;
	telefone: string;
	telefoneCidadao: string;
	codigoMunicipioProprietario: number | string;
	descricaoMunicipioProprietario: string;
	identificacao: string;
	cpfProprietario: number | string;
	cnpjProprietario: number | string;
	rgProprietario: string;
	rgDigito: string;
	orgaoExpeditorProprietario: string;
	ufProprietario: string;
	emailCidadao: string;
	taxaAutenticacaoDigital: string;
	codBanco: string;
	dataContabil: string;
	nsuProdesp: string;
	documentos: any;
}

export const initialValues: IEmitirSegundaViaCrv = {
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
		identificacao: '',
		ip: '',
		canal: {
			id: 0,
			desc: '',
			localidade: {
				id: 0,
				desc: '',
			},
		},
	},
	placa: '',
	protocoloRemarcacao: '',
	codigoMunicipio: '',
	descricaoMunicipio: '',
	renavam: '',
	chassi: '',
	chassiRemarcado: false,
	longIdMarca: '',
	descricaoMarca: '',
	anoModelo: '',
	longIdTipo: '',
	descricaoTipo: '',
	longIdCarroceria: '',
	descricaoCarroceria: '',
	longIdCor: '',
	descricaoCor: '',
	longIdCategoria: '',
	descricaoCategoria: '',
	longIdCombustivel: '',
	descricaoCombustivel: '',
	longIdEspecie: '',
	descricaoEspecie: '',
	capacidadePassageiro: '',
	capacidadeCarga: '',
	potencia: '',
	cilindrada: '',
	cmt: '',
	pbt: '',
	eixos: '',
	procedencia: '',
	nomeProprietario: '',
	cepProprietario: '',
	bairroProprietario: '',
	enderecoProprietario: '',
	numeroProprietario: '',
	complementoProprietario: '',
	telefone: '',
	telefoneCidadao: '',
	codigoMunicipioProprietario: '',
	descricaoMunicipioProprietario: '',
	identificacao: '',
	cpfProprietario: '',
	cnpjProprietario: '',
	rgProprietario: '',
	rgDigito: '',
	orgaoExpeditorProprietario: '',
	ufProprietario: '',
	emailCidadao: '',
	taxaAutenticacaoDigital: '',
	codBanco: '',
	dataContabil: '',
	nsuProdesp: '',
	documentos: [],
};

export const schema = Yup.object<IEmitirSegundaViaCrv>().shape({
	placa: Yup.string().required('Campo Obrigatório: Placa.'),
	municipio: Yup.string().when(['codigoMunicipio', 'descricaoMunicipio'], {
		is: (codigoMunicipio, descricaoMunicipio) =>
			[codigoMunicipio, descricaoMunicipio].includes(''),
		then: Yup.string().required('Campo Obrigatório: Município.'),
	}),
	renavam: Yup.string().required('Campo Obrigatório: Renavam.'),
	chassi: Yup.string().required('Campo Obrigatório: Chassi.'),
	chassiRemarcado: Yup.string().required(
		'Campo Obrigatório: Chassi Remarcado.',
	),
	marca: Yup.string().when(['descricaoMarca', 'longIdMarca'], {
		is: (descricaoMarca, longIdMarca) =>
			[descricaoMarca, longIdMarca].includes(''),
		then: Yup.string().required('Campo Obrigatório: Marca.'),
	}),
	anoModelo: Yup.string().required('Campo Obrigatório: Ano Modelo.'),
	tipo: Yup.string().when(['descricaoTipo', 'longIdTipo'], {
		is: (descricaoTipo, longIdTipo) => [descricaoTipo, longIdTipo].includes(''),
		then: Yup.string().required('Campo Obrigatório: Tipo.'),
	}),
	carroceria: Yup.string().when(['descricaoCarroceria', 'longIdCarroceria'], {
		is: (descricaoCarroceria, longIdCarroceria) =>
			[descricaoCarroceria, longIdCarroceria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Carroceria.'),
	}),
	cor: Yup.string().when(['descricaoCor', 'longIdCor'], {
		is: (descricaoCor, longIdCor) => [descricaoCor, longIdCor].includes(''),
		then: Yup.string().required('Campo Obrigatório: Cor.'),
	}),
	categoria: Yup.string().when(['descricaoCategoria', 'longIdCategoria'], {
		is: (descricaoCategoria, longIdCategoria) =>
			[descricaoCategoria, longIdCategoria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Categoria.'),
	}),
	combustivel: Yup.string().when(
		['descricaoCombustivel', 'longIdCombustivel'],
		{
			is: (descricaoCombustivel, longIdCombustivel) =>
				[descricaoCombustivel, longIdCombustivel].includes(''),
			then: Yup.string().required('Campo Obrigatório: Combustível.'),
		},
	),
	especie: Yup.string().when(['descricaoEspecie', 'longIdEspecie'], {
		is: (descricaoEspecie, longIdEspecie) =>
			[descricaoEspecie, longIdEspecie].includes(''),
		then: Yup.string().required('Campo Obrigatório: Espécie.'),
	}),
	capacidadePassageiro: Yup.string().required(
		'Campo Obrigatório: Capacidade Passageiros.',
	),
	capacidadeCarga: Yup.string().required(
		'Campo Obrigatório: Capacidade Carga.',
	),
	potencia: Yup.string().required('Campo Obrigatório: Potência.'),
	cilindrada: Yup.string().required('Campo Obrigatório: Cilindrada.'),
	cmt: Yup.string().required('Campo Obrigatório: CMT.'),
	pbt: Yup.string().required('Campo Obrigatório: PBT.'),
	eixos: Yup.string().required('Campo Obrigatório: Eixos.'),
	procedencia: Yup.string().required(
		'Campo Obrigatório: Procedência do veículo.',
	),
	nomeProprietario: Yup.string().required('Campo Obrigatório: Nome.'),
	cepProprietario: Yup.string().required('Campo Obrigatório: CEP do imóvel.'),
	bairroProprietario: Yup.string().required('Campo Obrigatório: Bairro.'),
	enderecoProprietario: Yup.string().required('Campo Obrigatório: Endereço.'),
	numeroProprietario: Yup.string().required('Campo Obrigatório: Número.'),
	identificacao: Yup.string().required(
		'Campo Obrigatório: Identificação Contribuinte.',
	),
	cpf: Yup.string().when(['identificacao', 'cpfProprietario'], {
		is: (identificacao, cpfProprietario) =>
			identificacao === 'FISICA' && !cpfProprietario,
		then: Yup.string().required('Campo Obrigatório: CPF do Proprietário.'),
	}),
	cnpj: Yup.string().when(['identificacao', 'cnpjProprietario'], {
		is: (identificacao, cnpjProprietario) =>
			identificacao === 'JURIDICA' && !cnpjProprietario,
		then: Yup.string().required('Campo Obrigatório: CNPJ do Proprietário.'),
	}),
	emailCidadao: Yup.string()
		.matches(userDataRegex.emailAddress, {
			message: 'Campo Inválido: E-mail',
			excludeEmptyString: true,
		})
		.required('Campo Obrigatório: E-mail.'),
	pessoaFisicaRg: Yup.string().when(
		['identificacao', 'rgProprietario', 'rgProprietario', 'ufProprietario'],
		{
			is: (
				identificacao,
				rgProprietario,
				orgaoExpeditorProprietario,
				ufProprietario,
			) =>
				identificacao === 'FISICA' &&
				[rgProprietario, orgaoExpeditorProprietario, ufProprietario].includes(
					'',
				),
			then: Yup.string().required(
				'Campos Obrigatórios: RG do Proprietário atual, Órgão Expedidor e UF.',
			),
		},
	),
	telefone: Yup.string()
		.required('Campo Obrigatório: Telefone.')
		.min(10, 'O Telefone deve conter DDD e número.'),
	telefoneCidadao: Yup.string()
		.required('Campo Obrigatório: Telefone de Contato.')
		.min(10, 'O Telefone de Contato deve conter DDD e número.'),
});

export function treatValues(
	formValues: IEmitirSegundaViaCrv,
	evento: IEvento,
	taxaServico?: ITaxaServico,
): IRequestSegundaViaCrv {
	const {
		anoModelo,
		capacidadeCarga,
		capacidadePassageiro,
		chassi,
		chassiRemarcado,
		cilindrada,
		cmt,
		eixos,
		pbt,
		placa,
		potencia,
		procedencia,
		protocoloRemarcacao,
		longIdCarroceria,
		descricaoCarroceria,
		longIdCategoria,
		descricaoCategoria,
		longIdCombustivel,
		descricaoCombustivel,
		longIdCor,
		descricaoCor,
		longIdEspecie,
		descricaoEspecie,
		longIdMarca,
		descricaoMarca,
		codigoMunicipioProprietario,
		descricaoMunicipioProprietario,
		longIdTipo,
		descricaoTipo,
		nomeProprietario,
		cepProprietario,
		enderecoProprietario,
		numeroProprietario,
		complementoProprietario,
		bairroProprietario,
		telefone,
		identificacao,
		cpfProprietario,
		cnpjProprietario,
		rgProprietario,
		orgaoExpeditorProprietario,
		ufProprietario,
		rgDigito,
		renavam,
		documentos,
		emailCidadao,
		telefoneCidadao,
	} = formValues;

	const formatedRg = formatRg(rgProprietario, rgDigito);
	const { ddd, numTelefone } = separarDDD(telefoneCidadao);

	const requestValues = {
		evento: {
			id_atendimento: evento.id_atendimento,
			id_cidadao: evento.id_cidadao,
			ip: evento.ip,
			identificacao: evento.identificacao,
			cpf: evento.cpf || '',
			canal: {
				id: 1,
				desc: 'Presencial',
				localidade: {
					desc: evento.canal?.localidade.desc || '',
					id: evento.canal?.localidade.id || 0,
				},
			},
		},
		taxaAutenticacaoDigital:
			concatenatesTaxaServicoPix(
				taxaServico?.codBanco || '',
				taxaServico?.dataContabil || '',
				taxaServico?.nsuProdesp || '',
			) || '',
		nomeCidadao: nomeProprietario,
		dddTelefoneCidadao: Number(ddd),
		telefoneCidadao: Number(numTelefone),
		emailCidadao,
		servicoDigital: true,
		documentos,
		anoModelo: Number(anoModelo),
		capacidadeCarga:
			Number(capacidadeCarga) === 0 ? 0.0 : Number(capacidadeCarga),
		capacidadePassageiro: Number(capacidadePassageiro),
		chassi,
		chassiRemarcado,
		cilindrada: Number(cilindrada),
		cmt: Number(cmt),
		eixo: Number(eixos),
		pbt: Number(pbt),
		placa,
		potencia: Number(potencia),
		procedencia,
		protocoloRemarcacao: protocoloRemarcacao ? Number(protocoloRemarcacao) : 0,
		renavam: Number(renavam),
		carroceria: {
			longId: Number(longIdCarroceria),
			descricao: descricaoCarroceria,
		},
		categoria: {
			longId: Number(longIdCategoria),
			descricao: descricaoCategoria,
		},
		combustivel: {
			longId: Number(longIdCombustivel),
			descricao: descricaoCombustivel,
		},
		cor: {
			longId: Number(longIdCor),
			descricao: descricaoCor,
		},
		especie: {
			longId: Number(longIdEspecie),
			descricao: descricaoEspecie,
		},
		marca: {
			longId: Number(longIdMarca),
			descricao: descricaoMarca,
		},
		municipio: {
			codigo: Number(codigoMunicipioProprietario),
			nome: descricaoMunicipioProprietario,
		},
		tipo: {
			longId: Number(longIdTipo),
			descricao: descricaoTipo,
		},
		proprietario: {} as
			| IProprietarioFisicoEmitirSegundaViaCrv
			| IProprietarioJuridicoEmitirSegundaViaCrv,
	};

	if (identificacao === 'FISICA') {
		requestValues.proprietario = {
			nomeProprietario,
			cepProprietario: Number(limparMascara(cepProprietario)),
			enderecoProprietario,
			numero: Number(numeroProprietario),
			complemento: complementoProprietario,
			bairro: bairroProprietario,
			telefone: telefone ? Number(limparMascara(telefone)) : 0,
			municipioProprietario: {
				codigo: Number(codigoMunicipioProprietario),
				nome: descricaoMunicipioProprietario,
			},
			identificacao,
			cpfCnpjProprietario: Number(limparMascara(String(cpfProprietario))),
			rgProprietario: Number(formatedRg),
			orgaoExpeditorProprietario,
			ufProprietario,
			nomeProprietarioAnterior: '',
		};
	} else {
		requestValues.proprietario = {
			nomeProprietario,
			cepProprietario: Number(limparMascara(cepProprietario)),
			enderecoProprietario,
			numero: Number(numeroProprietario),
			complemento: complementoProprietario,
			bairro: bairroProprietario,
			telefone: telefone ? Number(limparMascara(telefone)) : 0,
			municipioProprietario: {
				codigo: Number(codigoMunicipioProprietario),
				nome: descricaoMunicipioProprietario,
			},
			identificacao,
			cpfCnpjProprietario: Number(limparMascara(String(cnpjProprietario))),
			nomeProprietarioAnterior: '',
		};
	}

	return requestValues;
}
