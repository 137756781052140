import { ReducerAction } from 'store/modules/types';

import { RedirecionarAtendimentoRequest, Types } from './types';

export function consultaAtendimento(data: string): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO,
		payload: data,
	};
}
export function consultaAtendimentoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_SUCCESS,
		payload,
	};
}
export function consultaAtendimentoFailure(payload: boolean): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_FAILURE,
		payload,
	};
}

export function consultaAtendimentoUserData(data: string): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_USER_DATA,
		payload: data,
	};
}
export function consultaAtendimentoUserDataSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_USER_DATA_SUCCESS,
		payload,
	};
}
export function consultaAtendimentoUserDataFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_USER_DATA_FAILURE,
		payload,
	};
}

export function redirecionamentoAtendimento(
	payload: RedirecionarAtendimentoRequest,
): ReducerAction {
	return {
		type: Types.REDIRECIONAMENTO_ATENDIMENTO,
		payload,
	};
}
export function redirecionamentoAtendimentoClear(): ReducerAction {
	return {
		type: Types.REDIRECIONAMENTO_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
