/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';

import { Field, FormikProps } from 'formik';
import { Row, Col } from 'antd';

// FORM
import hasError from 'utils/getFormErrors';

// COMPONENTS
import InputMask from 'components/Common/Form/Input/InputMask';

// STYLED
import { Container, Numero, EnderecoRua, Divider } from '../styled';

interface Result {
	enderecoRua: string;
	numero: string;
}

interface Props {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | number;
	enderecoRua: string;
	numero: string;
	valueEndereco?: string;
	valueNumero?: string;
	autoFocus?: boolean;
	required?: boolean;
	disabled?: boolean;
	reset?: boolean;
	formik: FormikProps<any>;
	error?: boolean;
	onFocus?: () => void;
	result: (res: Result) => void;
}

const Endereco: React.FC<Props> = ({
	title,
	titleSize,
	enderecoRua,
	numero = '',
	autoFocus = false,
	required = false,
	formik,
	error = false,
	onFocus,
}) => {
	const { errors } = formik;

	const handleOnFocus = useCallback(() => {
		if (onFocus) onFocus();
	}, [onFocus]);

	return (
		<>
			<Row>
				<Col span={24}>
					<Container>
						<EnderecoRua>
							<Field
								as={InputMask}
								title={title}
								titleSize={titleSize}
								name={enderecoRua}
								autoFocus={autoFocus}
								onFocus={handleOnFocus}
								required={required}
								mask="************************************************************"
								maskChar={null}
								formatChars={{ '*': '[A-Za-z0-9 ]' }}
								error={
									hasError(errors, enderecoRua) ||
									hasError(errors, numero) ||
									error
								}
							/>
						</EnderecoRua>
						<Divider />
						<Numero>
							<Field
								as={InputMask}
								name={numero}
								mask="99999999"
								maskChar={null}
								onFocus={handleOnFocus}
								error={
									hasError(errors, numero) || hasError(errors, numero) || error
								}
							/>
						</Numero>
					</Container>
				</Col>
			</Row>
		</>
	);
};

export default Endereco;
