// UTILS
import { formatDate } from 'utils/genericFunctions';

import { IFormCadastroRg } from 'pages/iirgd/RgFicDigital/CadastroRg/form';
import { ConsultaPesquisaCirg } from './types';

export function formatarCadastroRgOriginal(
	pesquisaCirg: ConsultaPesquisaCirg,
): IFormCadastroRg {
	return {
		// Principal
		argumento: pesquisaCirg.argumento,
		numeroRg: pesquisaCirg.numeroRg,
		pidt: '',
		nome: pesquisaCirg.nome,
		nomeSocial: pesquisaCirg.nomeSocial,
		nomePai: pesquisaCirg.nomePai,
		nomeMae: pesquisaCirg.nomeMae,
		sexo: pesquisaCirg.sexo,
		dataNascimento: formatDate(pesquisaCirg.dataNascimento),
		codigoNaturalidade: pesquisaCirg.codigoNaturalidade,
		municipioNaturalidade: pesquisaCirg.municipioNaturalidade,
		multifiliacao:
			!!pesquisaCirg.filiacaoTres ||
			!!pesquisaCirg.filiacaoQuatro ||
			!!pesquisaCirg.filiacaoCinco ||
			!!pesquisaCirg.filiacaoSeis,
		filiacaoTres: pesquisaCirg.filiacaoTres || '',
		filiacaoQuatro: pesquisaCirg.filiacaoQuatro || '',
		filiacaoCinco: pesquisaCirg.filiacaoCinco || '',
		filiacaoSeis: pesquisaCirg.filiacaoSeis || '',

		// Documento apresentado
		dbas: pesquisaCirg.dbas,
		comarca: pesquisaCirg.comarca,
		cartorio: pesquisaCirg.cartorio,
		livro: pesquisaCirg.livro,
		folha: pesquisaCirg.folha,
		numero: pesquisaCirg.numero,
		dataDocumento: formatDate(pesquisaCirg.dataDocumento),
		naturalidadeEstrangeira: false,
		pMinLei1: pesquisaCirg.pMinLei1,
		pMinLei2: pesquisaCirg.pMinLei2,

		// Dados adicionais - Cidadão
		estadoCivil: pesquisaCirg.estadoCivil,
		cutis: pesquisaCirg.cutis,
		cabelos: pesquisaCirg.cabelos,
		olhos: pesquisaCirg.olhos,
		altura: `${pesquisaCirg.alturaM},${pesquisaCirg.alturaCm}`,
		grauInstrucao: pesquisaCirg.grauInstrucao,
		profissao: pesquisaCirg.profissao,
		cepResidencia: pesquisaCirg.cepResidencia,
		enderecoResidencia: pesquisaCirg.enderecoResidencia,
		numeroResidencia: pesquisaCirg.numeroResidencia,
		complementoResidencia: pesquisaCirg.complementoResidencia,
		bairroResidencia: pesquisaCirg.bairroResidencia,
		codigoMunicipioResidencia: pesquisaCirg.codigoMunicipioResidencia,
		descMunicipioResidencia: pesquisaCirg.descMunicipioResidencia,
		ufResidencia: pesquisaCirg.ufResidencia,

		// Dados adicionais - Trabalho
		cepTrabalho: pesquisaCirg.cepTrabalho,
		enderecoTrabalho: pesquisaCirg.enderecoTrabalho,
		numeroTrabalho: pesquisaCirg.numeroTrabalho,
		complementoTrabalho: pesquisaCirg.complementoTrabalho,
		bairroTrabalho: pesquisaCirg.bairroTrabalho,
		codigoMunicipioTrabalho: pesquisaCirg.codigoMunicipioTrabalho,
		descMunicipioTrabalho: pesquisaCirg.descMunicipioTrabalho,

		// Dados adicionais - Contato
		solicitacao: '1',
		foneResidencia: pesquisaCirg.foneResidencia,
		foneComercial: pesquisaCirg.foneComercial,
		celular: pesquisaCirg.celular,
		operadora: pesquisaCirg.operadora,
		email: pesquisaCirg.email,
		naoPossuiEmail: !!pesquisaCirg.email,

		// Informações adicionais
		identificadoAssina: pesquisaCirg.identificadoAssina,
		motivoFaltaAssinatura: pesquisaCirg.motivoFaltaAssinatura,
		carimboMaior65: pesquisaCirg.carimboMaior65 === '2',
		tipoSanguineo: pesquisaCirg.tipoSanguineo,
		cid1: pesquisaCirg.cid1,
		cid2: pesquisaCirg.cid2,
		cid3: pesquisaCirg.cid3,
		cid4: pesquisaCirg.cid4,

		// Dados adicionais - RG
		inscricaoCpf: '',
		cpf: pesquisaCirg.cpf,
		pis: pesquisaCirg.pis,
		pasep: pesquisaCirg.pasep,
		dni: pesquisaCirg.dni,
		tituloEleitor: pesquisaCirg.tituloEleitor,
		ctpsNum: pesquisaCirg.ctpsNum,
		ctpsSerie: pesquisaCirg.ctpsSerie,
		ctpsSerieUf: pesquisaCirg.ctpsSerieUf,
		certidaoMilitar: pesquisaCirg.certidaoMilitar,
		cnh: pesquisaCirg.cnh,
		doador: pesquisaCirg.doador,
		categoriaCnh: pesquisaCirg.categoriaCnh,
		nascSaude: pesquisaCirg.nascSaude,
		identProf1: pesquisaCirg.identProf1,
		orgaoEmissor1: pesquisaCirg.orgaoEmissor1,
		identProf2: pesquisaCirg.identProf2,
		orgaoEmissor2: pesquisaCirg.orgaoEmissor2,
		identProf3: pesquisaCirg.identProf3,
		orgaoEmissor3: pesquisaCirg.orgaoEmissor3,
		sedex: pesquisaCirg.sedex ? pesquisaCirg.sedex : '',

		// Entrega RG
		entrega: '',
		qtdKitFamilia: '',
		data: '',
		hora: '',
	};
}
