/* eslint-disable array-callback-return */
import produce from 'immer';

// TYPES
import { Types, consultaEndereco } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaEndereco = {
	status: 0,
	data: null,
};

export default function consultaEnderecoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaEndereco {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_ENDERECOS: {
				break;
			}

			case Types.CONSULTA_ENDERECOS_SUCCESS: {
				// const dadosFormatados = { ...action.payload.data };
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTA_ENDERECOS_FAILURE: {
				break;
			}

			default:
		}
	});
}
