import { EventoContabilizacao } from 'store/modules/types';

export enum Types {
	SAVE_GERAR_RENACH_REQUEST = '@cnh/SAVE_GERAR_RENACH_REQUEST',
	SAVE_GERAR_RENACH_SUCCESS = '@cnh/SAVE_GERAR_RENACH_SUCCESS',
	SAVE_GERAR_RENACH_FAILURE = '@cnh/SAVE_GERAR_RENACH_FAILURE',
	SAVE_GERAR_RENACH_CLEAR = '@cnh/SAVE_GERAR_RENACH_CLEAR',
}

export interface SaveGerarRenach {
	data: null | GerarRenachIntegradoRequest;
	status: number;
}

interface SaveGerarRenachBase {
	evento?: EventoContabilizacao;
	usoFormularioEnum: string;
	cpf: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	sexo: string;
	dataNascimento: string;
	tipoDocumentoEnum: string;
	numDocumento?: string;
	orgaoExpDocumento?: string;
	orgaoUfExpDocumento?: string;
	nacionalidade: string;
	codigoNaturalidade: string | number;
	email?: string;
	celularDDD: string;
	celular?: string;
	logradouro: string;
	logradouroNumero: string | null;
	logradouroComplemento: string;
	logradouroBairro: string;
	logradouroCodMunicipio: string;
	logradouroCep: string;
	codCiretranCondutor: string;
	statusReusoEnum: string;
	flagAtividadeRemunerada: boolean | string;
	flagDeficienteFisico: boolean | string;
	flagSolicitaCNHDigital: boolean | string;
	codigoVerificador: string | null;
	categoriaAtual: string;
	categoriaPretendida: string;
	registro: string | null;
	numeroPgu: string | null;
	rneNumero?: string | null;
	rneOrgao?: string | null;
	rneUf: string | null;
	rneCodPaisCnhEstrangeira: string | null;
	rneDataValidadeCnhEstrangeira: string | null;
	rneDataPrimeiraHabilitacaoCnhEstrangeira: string | null;
	rneIdentificacaoCnhEstrangeira: string | null;
	flagMilitar: boolean | string;
	codEntidadeMilitar: string;
	numeroIdentMilitar: string;
	flagExamePosto: boolean | string;
	flagExameToxicologico?: boolean | string;
	flagProcurador: boolean | string;
	codCiretranHabilitacao: string;
	cpfUsuario: string;
	codLocalidade: string;
	nomeSocial?: string;
	paisAfetivo1?: string;
	paisAfetivo2?: string;
	dataPrimeiraHabilitacao?: string;
	naturalidade?: string;
	tipoProcesso?: {
		codigo: string;
		descricao: string;
	};
}

export interface SaveGerarRenachForm extends SaveGerarRenachBase {
	flagNomeSocial?: string;
	flagFiliacaoAfetiva?: string;
	uf?: string;
	confirmaEmail?: string;
	autorizaSMS?: string;
	autorizaEmail?: string;
	telefone?: string;
	logradouroDescMunicipio?: string;
	enderecoRecebimentoNumero?: string;
	enderecoRecebimentoBairro?: string;
	enderecoRecebimentoCep?: string;
	enderecoRecebimentoLogradouro?: string;
	enderecoRecebimentoComplemento?: string;
	enderecoRecebimentoMunicipio?: string;
	enderecoRecebimentoCodigoMunicipio?: string;
}

export interface DadosExameMedico {
	codigoCiretranExameMedico: string;
	dataExame: string;
	horarioExame: string;
	idMedico: string;
	idMicroRegiaoMedico: string;
}

export interface DadosExamePsicologo {
	codigoCiretranExamePsicologo: string;
	dataExamePsicologico: string;
	horarioExamePsicologico: string;
	idMicroRegiaoPsicologo: string;
	idPsicologo: string;
}

export interface GerarRenachIntegradoRequest {
	candidato: {
		apresentaDocumentos: string;
		autorizaEmail: string;
		autorizaSMS: string;
		bairro: string;
		categoriaAtual: string;
		categoriaPretendida: string;
		codigoUnidadeAtendimento: string;
		celular: string;
		cep: string;
		cnhDigital: string;
		codigoCiretranPostoExameMedico: string;
		codigoEntidade: string;
		codigoVerificador: string;
		coletaBiometrica: string;
		complemento: string;
		cpf: string;
		dataNascimento: string;
		dataPrimeiraHabilitacao?: string;
		dataValidadeCnhE: string;
		dddCelular: string;
		dddTelefone: string;
		email: string;
		logradouro: string;
		nacionalidade: string;
		naturalidade: string;
		necessidadeVeiculoAdaptado?: string;
		nome: string;
		nomeMae: string;
		nomePai: string;
		nomeSocial: string;
		nomeSocialValidadoBiometria: string;
		origemNomeSocial: string;
		paisAfetivos1: string;
		paisAfetivos1ValidadoBiometria: string;
		paisAfetivos2: string;
		paisAfetivos2ValidadoBiometria: string;
		numCNHE: string;
		numCarteiraMilitar: string;
		numDocumento: string;
		numPGU: string;
		numRNE: string;
		numRegistro: string;
		numero: string;
		orgaoDocumento: string;
		orgaoRNE: string;
		paisCNHE: string;
		pretendeAtividadeRemunerada: string;
		sexo: string;
		telefone: string;
		testeAlfabetizacao: string;
		tipoDocumento: string;
		tipoProcesso: string;
		uf: string;
		ufDocumento: string;
		ufRNE: string;
	};
	dadosExameMedico: DadosExameMedico;
	dadosExamePsicologo: DadosExamePsicologo;
	gerarRenachBase?: SaveGerarRenachForm;
	// evento?: EventoContabilizacao;
}
