import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_PAISES_REQUEST = '@cnh/CONSULTA_PAISES_REQUEST',
	CONSULTA_PAISES_SUCCESS = '@cnh/CONSULTA_PAISES_SUCCESS',
	CONSULTA_PAISES_FAILURE = '@cnh/CONSULTA_PAISES_FAILURE',
}

export interface ConsultaPaises {
	status: number;
	data: OptionProps[];
}
