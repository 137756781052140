import styled, { css } from 'styled-components';

// IMAGES
import icon_circulo_vermelho from 'assets/images/icons/icon_circulo_vermelho.gif';
import icon_circulo_amarelo from 'assets/images/icons/icon_circulo_amarelo.gif';

interface Props {
	statusCriminal: string;
}

export const Button = styled.button<Props>`
	border: unset;
	height: 24px;
	width: 24px;

	${props => css`
		${props.statusCriminal === 'V'
			? css`
					background-image: url(${icon_circulo_vermelho});
			  `
			: css`
					background-image: url(${icon_circulo_amarelo});
			  `};
	`};
`;
