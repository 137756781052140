export enum Types {
	ENVIA_RESPOSTAS_ATENDIMENTO_REQUEST = '@procon/ENVIA_RESPOSTAS_ATENDIMENTO_REQUEST',
	ENVIA_RESPOSTAS_ATENDIMENTO_SUCCESS = '@procon/ENVIA_RESPOSTAS_ATENDIMENTO_SUCCESS',
	ENVIA_RESPOSTAS_ATENDIMENTO_FAILURE = '@procon/ENVIA_RESPOSTAS_ATENDIMENTO_FAILURE',
	ENVIA_RESPOSTAS_ATENDIMENTO_CLEAR = '@procon/ENVIA_RESPOSTAS_ATENDIMENTO_CLEAR',
}

export interface EnviaRespostasAtendimento {
	status: null | number;
	data: null | EnviaRespostasAtendimentoData;
	form: null | EnviaRespostasAtendimentoRequest;
}

interface EnviaRespostasAtendimentoData {}

export interface EnviaRespostasAtendimentoRequest {
	interacaoId: string;
	textoDaInteracao: string;
	anexos?: Anexos[];
}

export interface Anexos {
	nome: string;
	base64: string;
}

export interface EnviaRespostasAtendimentoForm {
	respostaConsumidor: string;
	anexos: Anexos[];
	anexosTipo?: string;
}
