import React, { useCallback, useEffect, useState } from 'react';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_DETRAN_CNH_ESTRANGEIRO_AGENDAMENTO_MEDICO } from 'pages/DetranCnh/routes/paths';

// ANTD
import { Row, Col, Modal } from 'antd';

// COMPONENTS COMMON
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import Alert from 'components/Common/Notifications/Alert';
import Section from 'components/Common/Section';

// COMPONENTS
import { AlertInvalidDrugTest } from 'pages/DetranCnh/components/common/AlertInvalidDrugTest';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultaPaisesRequest } from 'store/modules/api/detranCnh/consulta/paises/actions';
import { consultaNacionalidadesRequest } from 'store/modules/api/detranCnh/consulta/nacionalidades/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCnh/consulta/categoria/actions';
import {
	dadosCidadaoClear,
	dadosCidadaoRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/actions';
import {
	cadastroEnderecosClear,
	cadastroEnderecosRequest,
} from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/actions';
import {
	consultarInaptoClear,
	consultarInaptoRequest,
} from 'store/modules/api/detranCnh/sockets/consultarInapto/actions';
import { consultaTokenCnhDigitalClear } from 'store/modules/api/detranCnh/cnhDigital/tokenDigital/actions';
import { addNotifications } from 'store/modules/app/notifications/actions';
import {
	consultaPesquisaNomeSocialFiliacaoClear,
	consultaPesquisaNomeSocialFiliacaoRequest,
} from 'store/modules/api/detranCnh/IIRGDDetran/consultaPesquisaNomeSocialFiliacao/actions';
import {
	validaTokenCnhDigitalClear,
	validaTokenCnhDigitalRequest,
} from 'store/modules/api/detranCnh/cnhDigital/validaToken/actions';
import {
	consultaNomeSocialFiliacaoClear,
	consultaNomeSocialFiliacaoRequest,
} from 'store/modules/api/detranCnh/condutor/consultaNomeSocialFiliacao/actions';
import { DadosCidadaoRequest } from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/types';
import { consultaToxicologicoClear } from 'store/modules/api/detranCnh/cidadao/consultaToxicologico/actions';
import { estatisticaIncluirClear } from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { saveGerarRenachRequest } from 'store/modules/api/detranCnh/sockets/saveGerarRenach/actions';
import { dadosPreCadastroFormSaveRequest } from 'store/modules/api/detranCnh/utils/dadosPreCadastroFormSave/actions';
import { gerarRenachIntegradoClear } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/actions';

// UTILS
import { limparMascara } from 'utils/genericFunctions';
import { getOriginOfSocialName } from 'pages/DetranCnh/utils/functions/socialName';
import { treatValuesCadastroEnderecoRequest } from 'pages/DetranCnh/utils/threatServicesRequest';
import { CNH_PROCESS_VALIDATION } from 'pages/DetranCnh/utils/constants/messages';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

// FORM
import {
	initialValuesDadosHabilitacao,
	treatInitialValues,
	treatValues,
	schema,
	FormCnhEstrangeiro,
	CnhEstrangeiroModalInfo,
} from './form';
import DadosPreCadastro from '../PreCadastro/DadosPreCadastro/index';
import DadosHabilitacao from '../PreCadastro/DadosHabilitacao';

// STYLES
import 'pages/DetranCnh/styles.css';

let cadastroEnderecoRequestData = {
	cpf: '',
	enderecoRecebimento: {
		logradouro: '',
		numero: '',
		complemento: '',
		bairro: '',
		cep: '',
		codigoMunicipio: '',
		nomeMunicipio: '',
	},
};

const StatusCondutorCnhEstrangeiro: React.FC = () => {
	const history = useHistory();

	const dispatch = useDispatch();

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const loginDataMainframe = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.mainframe,
	);

	const { consultaCnhEstrangeiro } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.eportal,
	);

	const { consultaNomeSocialFiliacao, cadastroEnderecos } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { consultaPesquisaNomeSocialFiliacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.IIRGDDetran,
	);

	const { dadosCidadao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.divisaoEquitativa,
	);

	const { consultarInapto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const { validaTokenCnhDigital } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cnhDigital,
	);

	const { consultaToxicologico } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cidadao,
	);

	const { dadosPreCadastroFormSave } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.utils,
	);

	const { gerarRenachIntegrado } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	// CONTABILIZAÇÃO
	const { sgu } = useSelector((state: ApplicationState) => state.api);

	const [dadosHabilitacao, setDadosHabilitacao] = useState(
		initialValuesDadosHabilitacao,
	);

	const [dadosPreCadastro, setDadosPreCadastro] = useState(
		treatInitialValues(null),
	);

	const [mensagensInfo, setMensagensInfo] = useState<Array<string>>([]);

	const [requestData, setRequestData] = useState<null | {
		cidadao: DadosCidadaoRequest;
	}>(null);

	const [modalInfo, setModalInfo] = useState<CnhEstrangeiroModalInfo>({
		type: '',
		message: '',
	});

	const validateToken = useCallback(
		(values: FormCnhEstrangeiro) => {
			const data = {
				cpf: values.cpf,
				ddd: limparMascara(values.celular).substring(0, 2),
				celular: limparMascara(values.celular).substring(2, 11),
				email: values.email ? values.email : '',
				token: values.codigoVerificador ? values.codigoVerificador : '',
			};

			dispatch(validaTokenCnhDigitalRequest(data));
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: FormCnhEstrangeiro) => {
			if (!values.codigoUnidadeTransito) {
				addNotifications({
					errors: CNH_PROCESS_VALIDATION.CIRETRAN_INVALIDA,
				});
				return;
			}

			if (
				values.flagExameToxicologico === 'N' &&
				values.categoriaPretendida !== '' &&
				values.categoriaPretendida !== 'A' &&
				values.categoriaPretendida !== 'B' &&
				values.categoriaPretendida !== 'AB' &&
				values.categoriaPretendida !== 'X' &&
				values.categoriaPretendida !== 'XB'
			) {
				addNotifications({
					errors: [
						'De acordo com a Resolução Contran nº 843/2021, deve ser providenciado antecipadamente, para apresentação ao médico de trânsito, o exame toxicológico para os processos de Habilitação que envolvem as categorias C, D e E.',
					],
				});

				return;
			}

			const sguData: SguData = {
				salvarAtendimento: sgu.atendimento.salvarAtendimento,
				login: sgu.loginUnico.login,
			};
			const evento = getEventoConsultar(sguData, values.cpf);

			const endereco = treatValuesCadastroEnderecoRequest(values);

			const cidadao: DadosCidadaoRequest = {
				cidadao: {
					autoriza_envio_email: values.autorizaEnvioEmail === 'S',
					autoriza_envio_sms: values.autorizaEnvioSms === 'S',
					cpf: Number(values.cpf),
					celular: limparMascara(values.celular).substring(2, 11),
					ddd_celular: limparMascara(values.celular).substring(0, 2),
					ddd_telefone: limparMascara(values.telefone).substring(0, 2),
					telefone: limparMascara(values.telefone).substring(2, 11),
					email: values.email || '',
					necessidade_veiculo_adaptado: false,
					nome: values.nomeSocial || values.nome,
					pretende_atividade_remunerada: values.flagAtividadeRemunerada === 'S',
				},
			};

			const renach = treatValues(evento, values, user);

			setRequestData({ cidadao });
			dispatch(saveGerarRenachRequest(renach));
			// SALVA OS DADOS PREENCHIDOS NO FORM.
			dispatch(
				dadosPreCadastroFormSaveRequest({
					dataPreCadastroCnhEstrangeiro: values,
					dataPreCadastroHabilitacaoCnhEstrangeiro: dadosHabilitacao,
				}),
			);
			dispatch(gerarRenachIntegradoClear());

			if (values.codigoVerificador && values.codigoVerificador !== '') {
				validateToken(values);

				cadastroEnderecoRequestData = endereco;
			} else {
				dispatch(cadastroEnderecosRequest(endereco));
			}
		},
		[user, dispatch, validateToken, sgu, dadosHabilitacao],
	);

	const handleCleanNotification = () => {
		setMensagensInfo([]);
	};

	const cadastroEndereco = () => {
		dispatch(cadastroEnderecosRequest(cadastroEnderecoRequestData));
	};

	const handleCleanModalInfo = () => {
		if (modalInfo.type === 'success') {
			cadastroEndereco();

			setModalInfo({
				type: '',
				message: '',
			});
		} else {
			setModalInfo({
				type: '',
				message: '',
			});
		}
	};

	const clearInitialStatesWithoutNewCallServices = useCallback(() => {
		dispatch(cadastroEnderecosClear());
		dispatch(estatisticaIncluirClear());
		dispatch(consultarInaptoClear());
		dispatch(dadosCidadaoClear());
	}, [dispatch]);

	useEffect(() => {
		if (cadastroEnderecos.status === 200 && requestData?.cidadao) {
			dispatch(dadosCidadaoRequest(requestData.cidadao));
		}
	}, [cadastroEnderecos.status, dispatch, requestData]);

	useEffect(() => {
		if (dadosCidadao.status === 200 && requestData !== null) {
			const inapto = {
				cpf: requestData.cidadao.cidadao.cpf.toString(),
				tipoExame: 'AMBOS',
				flagDeficienteFisico:
					requestData.cidadao.cidadao.necessidade_veiculo_adaptado,
			};

			dispatch(consultarInaptoRequest(inapto));
		}
	}, [dadosCidadao.status, dispatch, requestData]);

	useEffect(() => {
		if (consultarInapto.status !== 0 && consultarInapto.status !== 100) {
			dispatch(estatisticaIncluirClear());
			history.push(ROUTE_DETRAN_CNH_ESTRANGEIRO_AGENDAMENTO_MEDICO);
		}
	}, [consultarInapto.status, dispatch, history, requestData]);

	// RECARREGA OS DADOS DO FORM COM OS DADOS SALVOS NA STORE.
	useEffect(() => {
		if (dadosPreCadastroFormSave.dataPreCadastroCnhEstrangeiro?.cpf) {
			setDadosPreCadastro(
				dadosPreCadastroFormSave.dataPreCadastroCnhEstrangeiro,
			);
		}
	}, [dispatch, dadosPreCadastroFormSave]);

	// RECARREGA OS DADOS DO FORM COM OS DADOS SALVOS NA STORE.
	useEffect(() => {
		if (
			dadosPreCadastroFormSave.dataPreCadastroHabilitacaoCnhEstrangeiro?.cpf
		) {
			setDadosHabilitacao(
				dadosPreCadastroFormSave.dataPreCadastroHabilitacaoCnhEstrangeiro,
			);
		}
	}, [dispatch, dadosPreCadastroFormSave]);

	useEffect(() => {
		clearInitialStatesWithoutNewCallServices();

		if (!dadosPreCadastroFormSave.dataPreCadastroCnhEstrangeiro?.cpf) {
			dispatch(consultaCategoriaRequest({ funcionalidade: 'cnh_estrangeiro' }));
			dispatch(consultaNacionalidadesRequest());
			dispatch(consultaPaisesRequest());
			dispatch(consultaNomeSocialFiliacaoClear());
			dispatch(consultaPesquisaNomeSocialFiliacaoClear());
			dispatch(consultaTokenCnhDigitalClear());
			dispatch(validaTokenCnhDigitalClear());
			dispatch(consultaToxicologicoClear());
		}
	}, [
		dispatch,
		dadosPreCadastroFormSave,
		clearInitialStatesWithoutNewCallServices,
	]);

	useEffect(() => {
		if (
			consultaCnhEstrangeiro.cpf &&
			loginDataMainframe &&
			!dadosPreCadastroFormSave.dataPreCadastroCnhEstrangeiro?.cpf
		) {
			if (consultaCnhEstrangeiro.data) {
				const treatedValues = treatInitialValues(consultaCnhEstrangeiro.data);
				setDadosPreCadastro(treatedValues);
				setDadosHabilitacao(consultaCnhEstrangeiro.data);
			} else {
				setDadosPreCadastro(oldData => ({
					...oldData,
					cpf: consultaCnhEstrangeiro.cpf || '',
				}));
			}

			dispatch(
				consultaNomeSocialFiliacaoRequest({
					cpf: consultaCnhEstrangeiro.cpf,
					usuario: loginDataMainframe.idUsuario,
					senha: loginDataMainframe.senhaMainFrame,
				}),
			);
		}
	}, [
		dispatch,
		consultaCnhEstrangeiro,
		loginDataMainframe,
		user.posto,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			consultaNomeSocialFiliacao.status === 200 &&
			consultaNomeSocialFiliacao.data &&
			!dadosPreCadastroFormSave.dataPreCadastroCnhEstrangeiro?.cpf
		) {
			setDadosPreCadastro(oldData => ({
				...oldData,
				nomeSocial: consultaNomeSocialFiliacao.data?.nomeSocial?.trim() || '',
				paisAfetivos1: consultaNomeSocialFiliacao.data?.filiacao1?.trim() || '',
				paisAfetivos2: consultaNomeSocialFiliacao.data?.filiacao2?.trim() || '',
				origemNomeSocial: getOriginOfSocialName(
					consultaNomeSocialFiliacao,
					null,
				),
			}));
		}

		if (
			consultaCnhEstrangeiro.cpf &&
			consultaPesquisaNomeSocialFiliacao.status === 0 &&
			((consultaNomeSocialFiliacao.status !== 0 &&
				consultaNomeSocialFiliacao.status !== 200) ||
				(consultaNomeSocialFiliacao.status === 200 &&
					!consultaNomeSocialFiliacao.data?.nomeSocial?.trim()))
		) {
			dispatch(
				consultaPesquisaNomeSocialFiliacaoRequest({
					usuario: loginDataMainframe.idUsuario,
					terminal: user.ip,
					cpf: consultaCnhEstrangeiro.cpf,
				}),
			);
		}
	}, [
		consultaCnhEstrangeiro.cpf,
		consultaNomeSocialFiliacao,
		consultaPesquisaNomeSocialFiliacao.status,
		dispatch,
		loginDataMainframe.idUsuario,
		user.ip,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			consultaPesquisaNomeSocialFiliacao.status === 200 &&
			consultaPesquisaNomeSocialFiliacao.data &&
			!dadosPreCadastroFormSave.dataPreCadastroCnhEstrangeiro?.cpf
		) {
			setDadosPreCadastro(oldData => ({
				...oldData,
				nomeSocial:
					consultaPesquisaNomeSocialFiliacao.data?.nomeSocial.trim() || '',
				origemNomeSocial: getOriginOfSocialName(
					null,
					consultaPesquisaNomeSocialFiliacao,
				),
			}));
		}
	}, [consultaPesquisaNomeSocialFiliacao, dadosPreCadastroFormSave]);

	useEffect(() => {
		if (
			validaTokenCnhDigital.status === 200 &&
			validaTokenCnhDigital.data?.valido
		) {
			setModalInfo({
				type: 'success',
				message: 'O código verificador informado confere com o código gerado.',
			});

			window.scrollTo(0, 0);
		} else if (
			validaTokenCnhDigital.status === 200 &&
			validaTokenCnhDigital.data?.valido === false
		) {
			setModalInfo({
				type: 'alert',
				message: 'Código verificador inválido.',
			});

			window.scrollTo(0, 0);
		}
	}, [dispatch, validaTokenCnhDigital]);

	return (
		<Row>
			<Col span={24}>
				{gerarRenachIntegrado.status &&
				gerarRenachIntegrado.status !== 0 &&
				gerarRenachIntegrado.status !== 100 &&
				gerarRenachIntegrado.status !== 200 &&
				gerarRenachIntegrado.status !== 201 &&
				gerarRenachIntegrado.dataError?.mensagem &&
				((notifications.errors && !notifications.errors.length) ||
					(notifications.errors.length > 0 &&
						Array.isArray(notifications.errors) &&
						notifications.errors[0] !==
							gerarRenachIntegrado.dataError?.mensagem) ||
					(notifications.errors.length > 0 &&
						!Array.isArray(notifications.errors) &&
						notifications.errors !==
							gerarRenachIntegrado.dataError?.mensagem)) ? (
					// eslint-disable-next-line react/jsx-indent
					<Alert
						type="error"
						message={gerarRenachIntegrado.dataError?.mensagem}
						onClose={() => {}}
					/>
				) : null}

				<Section title="Status do Condutor">
					<strong>
						<h3>{dadosHabilitacao.nome}</h3>
					</strong>

					{mensagensInfo.length > 0 &&
						mensagensInfo.map((message: string) => (
							<Alert
								key={message}
								type="info"
								message={message}
								onClose={handleCleanNotification}
							/>
						))}

					{consultaToxicologico.data && consultaToxicologico.data.resultado && (
						<AlertInvalidDrugTest
							consultaToxicologicoResponse={consultaToxicologico}
						/>
					)}

					{dadosHabilitacao.cpf !== '' && (
						<DadosHabilitacao consultaCnhEstrangeiro={dadosHabilitacao} />
					)}

					<DadosPreCadastro
						renderData={dadosPreCadastro}
						handleSubmit={handleSubmit}
						setMensagensInfo={setMensagensInfo}
						clearNotification={handleCleanNotification}
						schema={schema}
					/>
				</Section>

				<Row justify="space-between" align="middle">
					<Col>
						<ButtonVoltar />
					</Col>

					<Col>
						<ButtonImage
							type="button"
							src="outro-servico-detran"
							onClick={() => history.push('/detran-cnh')}
						/>
					</Col>
				</Row>

				<Modal
					centered
					title="Mensagem"
					className="cnh-digital__modal"
					maskClosable={false}
					visible={modalInfo.message !== ''}
					style={{ animationDuration: '0s', color: 'red' }}
					footer={null}
					onCancel={handleCleanModalInfo}
				>
					<div>
						<p
							style={
								modalInfo.type === 'success'
									? { color: '#006ED7', fontWeight: 'bold' }
									: { color: 'red', fontWeight: 'bold' }
							}
						>
							{modalInfo.message}
						</p>

						<ButtonImage
							type="button"
							src="btn-ok"
							onClick={handleCleanModalInfo}
						/>
					</div>
				</Modal>
			</Col>
		</Row>
	);
};

export default StatusCondutorCnhEstrangeiro;
