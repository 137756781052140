import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	align-items: center;
	h2 {
		margin: 0 0 0 20px;
	}
`;

export const Icon = styled.div`
	border: 2px solid #a8a8a8;
	border-radius: 3px;
	width: 60px;
	height: 60px;
	background: #c4c4c4 -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0)));
	font-size: 30px;
	text-align: center;

	&::after {
		content: '!';
		display: block;
		font-weight: 500;
		color: #4f4f4f;
		text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.7);
		font-size: 30px;
		margin-top: 5px;
	}
`;

export const ContentText = styled.div`
	p {
		font-size: 12px;
		color: gray;
		margin-left: 90px;
		margin-top: 30px;
		margin-bottom: 30px;
	}

	span {
		display: block;
		font-size: 12px;
		color: gray;
		margin-left: 90px;
		margin-top: 5px;
		margin-bottom: 10px;
	}
`;
