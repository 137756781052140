import produce from 'immer';

// TYPES
import { Types, SolicitaCnhDigital } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: SolicitaCnhDigital = {
	status: 0,
	data: null,
};

export default function modulo(
	state = INITIAL_STATE,
	action: ReducerAction,
): SolicitaCnhDigital {
	return produce(state, draft => {
		switch (action.type) {
			case Types.SOLICITA_CNH_DIGITAL_REQUEST: {
				break;
			}
			case Types.SOLICITA_CNH_DIGITAL_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.SOLICITA_CNH_DIGITAL_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.SOLICITA_CNH_DIGITAL_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
