import produce from 'immer';

// TYPES
import { Types, AlteraTransferenciaEndereco } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: AlteraTransferenciaEndereco = {
	status: 0,
	data: null,
};

export default function alteraTransferenciaEnderecoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): AlteraTransferenciaEndereco {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ALTERA_TRANSFERENCIA_ENDERECO: {
				break;
			}

			case Types.ALTERA_TRANSFERENCIA_ENDERECO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.ALTERA_TRANSFERENCIA_ENDERECO_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			default:
		}
	});
}
