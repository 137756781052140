// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, ConsultaPrestacoesAtrasadasRequest } from './types';

// export interface IRequestConsultarPrestacoesAtrasadas {
// 	dataLimite: string;
// 	numeroContrato: number;
// }

export function consultarPrestacoesAtrasadasRequest(
	token: string,
	payload: ConsultaPrestacoesAtrasadasRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PRESTACOES_ATRASADAS_REQUEST,
		token,
		payload,
	};
}
export function consultarPrestacoesAtrasadasSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PRESTACOES_ATRASADAS_SUCCESS,
		payload,
	};
}
export function consultarPrestacoesAtrasadasFailure(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PRESTACOES_ATRASADAS_FAILURE,
		payload,
	};
}
export function consultarPrestacoesAtrasadasClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PRESTACOES_ATRASADAS_CLEAR,
		payload: null,
	};
}
