import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	consultarMunicipioPorUfSuccess,
	consultarMunicipioPorUfFailure,
} from './actions';

function* consultarMunicipioPorUfRequest({ payload }: any) {
	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consulta-municipio/${payload}`,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarMunicipioPorUfSuccess({ ...response, estado: payload }));
	} else if (response.error) {
		yield put(consultarMunicipioPorUfFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_MUNICIPIO_POR_UF_REQUEST,
		consultarMunicipioPorUfRequest,
	),
]);
