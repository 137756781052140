export enum Types {
	MANTEM_DADOS_CONSULTA_REQUEST = '@EDUCACAO/MANTEM_DADOS_CONSULTA_REQUEST',
	MANTEM_DADOS_CONSULTA_SUCCESS = '@EDUCACAO/MANTEM_DADOS_CONSULTA_SUCCESS',
	MANTEM_DADOS_CONSULTA_FAILURE = '@EDUCACAO/MANTEM_DADOS_CONSULTA_FAILURE',
}

export interface IDadosConsulta {
	data: IMantemDados;
}

export interface IMantemDados {
	filtrosNomes: {
		nomeAluno: string;
		nomeMae: string;
		nomePai: string;
	};
	dataNascimento: string;
}

interface IFiltrosNomes {
	nomeAluno: string;
	nomeMae: string;
	nomePai: string;
}
