export enum Types {
	CONSULTAR_CPF_REQUEST = '@rfb/CONSULTAR_CPF_REQUEST',
	CONSULTAR_CPF_SUCCESS = '@rfb/CONSULTAR_CPF_SUCCESS',
	CONSULTAR_CPF_FAILURE = '@rfb/CONSULTAR_CPF_FAILURE',
	CONSULTAR_CPF_CLEAR = '@rfb/CONSULTAR_CPF_CLEAR',
}

export interface IConsultarCpfState {
	status: number;
	data: IConsultarCpf | null;
}

export interface IConsultarCpf {
	qtdeRegistrosRetornados: string;
	resultado: Resultado[] | null;
	codRetorno: string;
	msgRetorno: string;
}

export interface ConsultarCpfRequest {
	evento: {
		id_atendimento: string;
		id_cidadao: string;
		cpf: string;
		identificacao: string | number;
		ip: string;
		canal: {
			id: number;
			desc: string;
			localidade: {
				id: number;
				desc: string;
			};
		};
	};
	cpf: string;
	cpfUsuario: string;
	dataNascimento: string;
	dataNascimentoInicial: string;
	dataNascimentoFinal: string;
	nome: string;
	nomeMae: string;
}
export interface Resultado {
	niCpf: string;
	situacaoCadastral: string;
	nomePessoaFisica: string;
	nomeMae: string;
	dtNascAmd: string;
	anoObito: string;
	codMunDomicIbge: string;
	codMunDomic: string;
	nmMunDomic: string;
	ufMunDomic: string;
	codMunNaturalidadeIbge: string;
	codMunNaturalidade: string;
	nmMunNaturalidade: string;
	ufMunNaturalidade: string;
}
