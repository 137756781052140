import styled, { css } from 'styled-components';

interface Props {
	buttonColor: 'default' | 'green' | 'blue' | 'red';
}

export const Button = styled.button<Props>`
	${props => css`
		${props.buttonColor === 'default' &&
		css`
			background-color: #fcfcfc;
			border-color: #dfe2e6;
			color: #4b5d6f;

			&:hover {
				background-color: #f4f6f7;
				border-color: #afb6bd;
				box-shadow: 0px 0px 4px 0px #bec5cd;
			}
		`};

		${props.buttonColor === 'green' &&
		css`
			background-color: ${props.theme.colors.background};
			border-color: ${props.theme.colors.border};
			color: #2ea44f;

			&:hover {
				background-color: #d9f7e2;
				border-color: #5dda5d;
				box-shadow: 0px 0px 4px 0px #87f3a5;
			}
		`};

		${props.buttonColor === 'blue' &&
		css`
			background-color: ${props.theme.colors.background};
			border-color: ${props.theme.colors.border};
			color: #035fc7;

			&:hover {
				background-color: #e4eef7;
				border-color: #4390da;
				box-shadow: 0px 0px 4px 0px #9bcdff;
			}
		`};

		${props.buttonColor === 'red' &&
		css`
			background-color: ${props.theme.colors.background};
			border-color: ${props.theme.colors.border};
			color: #cb2431;

			&:hover {
				background-color: #f7dbdd;
				border-color: #ef979e;
				box-shadow: 0px 0px 4px 0px #ff929b;
			}
		`};
	`};
`;
