import React, { useEffect, useState } from 'react';

import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage, { SourceButtonImage } from 'components/Common/ButtonImage';
import PDFViewer from 'components/Common/PDFViewer';

// REDUX
import { ApplicationState } from 'store';
import {
	imprimirRenachClear,
	imprimirRenachRequest,
} from 'store/modules/api/detranCnh/imprimir/renach/actions';
import { useDispatch, useSelector } from 'react-redux';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import {
	relatorioAgendamentoMedicoPsicologicoClear,
	relatorioAgendamentoMedicoPsicologicoRequest,
} from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';
import {
	consultaProcessoAbertoRequest,
	consultaProcessoAbertoClear,
} from 'store/modules/api/detranCnh/renachIntegrado/consultaProcessoAberto/actions';

// UTILS
import { ROUTE_DETRAN_CNH } from 'routes/paths';
import { BoxRenach } from './styles';
import { RenachAlteradoForm } from './form';

interface TypeBtnImprimirRenach {
	typeBtn: SourceButtonImage;
	typeFlagExameMedico: boolean;
	typeFlagExamePsico: boolean;
	isVisible: boolean;
}

const RenachAlterado = ({ renderData }: RenachAlteradoForm) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [pdfFile, setPdfFile] = useState('');

	const { imprimirRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.imprimir,
	);

	const alterarCadastroResponse = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.renachIntegrado.alterarCadastro.data,
	);

	const { relatorio } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.agendamentoMedicoPsicologico,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { consultaProcessoAberto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const [typeButtonImprimirExame, setTypeButtonImprimirExame] =
		useState<TypeBtnImprimirRenach>({
			typeBtn: 'btn-imprimir-agendamento-medico-psicologico',
			typeFlagExameMedico: false,
			typeFlagExamePsico: false,
			isVisible: false,
		});

	useEffect(() => {
		dispatch(imprimirRenachClear());
		dispatch(relatorioAgendamentoMedicoPsicologicoClear());
		dispatch(consultaProcessoAbertoClear());
		setPdfFile('');
	}, [dispatch]);

	useEffect(() => {
		if (alterarCadastroResponse && alterarCadastroResponse.cpfCidadao) {
			dispatch(
				consultaProcessoAbertoRequest({
					cpf: `00000000000${alterarCadastroResponse.cpfCidadao.trim()}`.slice(
						-11,
					),
					ident: 0,
				}),
			);
		}
	}, [dispatch, alterarCadastroResponse]);

	useEffect(() => {
		setTimeout(() => {
			if (
				imprimirRenach.status !== 0 &&
				imprimirRenach.status === 200 &&
				imprimirRenach.data
			) {
				window.open(
					imprimirRenach.data.url,
					'_blank',
					'location=yes,height=auto,width=auto,scrollbars=yes,status=false',
				);
				dispatch(imprimirRenachClear());
			}
		}, 400);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imprimirRenach]);

	useEffect(() => {
		setTimeout(() => {
			if (relatorio.status === 200 && relatorio.data?.arquivoPDF) {
				setPdfFile(relatorio.data.arquivoPDF);
				dispatch(relatorioAgendamentoMedicoPsicologicoClear());
			}
		}, 400);
	}, [dispatch, relatorio]);

	useEffect(() => {
		if (consultaProcessoAberto.data) {
			if (
				(consultaProcessoAberto.data.nomeMedico === undefined ||
					consultaProcessoAberto.data.nomeMedico === '') &&
				(consultaProcessoAberto.data.nomePsicologo === undefined ||
					consultaProcessoAberto.data.nomePsicologo === '')
			) {
				setTypeButtonImprimirExame({
					typeBtn: 'btn-imprimir-agendamento-medico-psicologico',
					typeFlagExameMedico: false,
					typeFlagExamePsico: false,
					isVisible: false,
				});
			} else if (
				consultaProcessoAberto.data.nomeMedico !== undefined &&
				consultaProcessoAberto.data.nomeMedico !== '' &&
				consultaProcessoAberto.data.nomePsicologo !== undefined &&
				consultaProcessoAberto.data.nomePsicologo !== ''
			) {
				setTypeButtonImprimirExame({
					typeBtn: 'btn-imprimir-agendamento-medico-psicologico',
					typeFlagExameMedico: true,
					typeFlagExamePsico: true,
					isVisible: true,
				});
			} else if (
				(consultaProcessoAberto.data.nomeMedico === undefined ||
					consultaProcessoAberto.data.nomeMedico === '') &&
				consultaProcessoAberto.data.nomePsicologo !== undefined &&
				consultaProcessoAberto.data.nomePsicologo !== ''
			) {
				setTypeButtonImprimirExame({
					typeBtn: 'btn-imprimir-agendamento-psicologico',
					typeFlagExameMedico: false,
					typeFlagExamePsico: true,
					isVisible: true,
				});
			} else if (
				consultaProcessoAberto.data.nomeMedico !== undefined &&
				consultaProcessoAberto.data.nomeMedico !== '' &&
				(consultaProcessoAberto.data.nomePsicologo === undefined ||
					consultaProcessoAberto.data.nomePsicologo === '')
			) {
				setTypeButtonImprimirExame({
					typeBtn: 'btn-imprimir-agendamento-medico-psicologico',
					typeFlagExameMedico: true,
					typeFlagExamePsico: false,
					isVisible: true,
				});
			}
		}
	}, [consultaProcessoAberto.data]);

	return (
		<>
			<Section title="Status do Condutor">
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h3 style={{ textTransform: 'uppercase' }}>{renderData.nome}</h3>
					</Col>
				</Row>

				<Row gutter={[0, 10]} align="middle" justify="center">
					<Col span={24}>
						<BoxRenach>
							<h2>Informações salvas com sucesso</h2>
							<p>
								O número do RENACH é: <strong>{renderData.renach}</strong>
							</p>

							<Row align="middle" justify="center">
								<Col>
									<ButtonImage
										type="button"
										src="btn-imprimir-renach"
										onClick={() =>
											dispatch(
												imprimirRenachRequest({
													cpfCidadao: renderData.cpf,
													cpfUsuarioLogado: user.cpf,
												}),
											)
										}
									/>
								</Col>
							</Row>

							{consultaProcessoAberto.data &&
								alterarCadastroResponse &&
								typeButtonImprimirExame.isVisible && (
									<Row align="middle" justify="center">
										<Col>
											<ButtonImage
												type="button"
												src={typeButtonImprimirExame.typeBtn}
												onClick={() => {
													if (consultaProcessoAberto.data) {
														dispatch(
															relatorioAgendamentoMedicoPsicologicoRequest({
																categoriaPretendida:
																	consultaProcessoAberto.data
																		.categoriaPretendida,
																cpfCidadao:
																	`00000000000${alterarCadastroResponse.cpfCidadao}`.slice(
																		-11,
																	),
																crm: consultaProcessoAberto.data.crm,
																crp: consultaProcessoAberto.data.crp,
																dataExame:
																	(consultaProcessoAberto.data.dataExame &&
																		new Date(
																			`${consultaProcessoAberto.data.dataExame} ${consultaProcessoAberto.data.horarioExame}`,
																		).toISOString()) ||
																	'',
																dataExamePsicologo:
																	(consultaProcessoAberto.data
																		.dataExamePsicologo &&
																		new Date(
																			`${consultaProcessoAberto.data.dataExamePsicologo} ${consultaProcessoAberto.data.horarioExamePsicologo}`,
																		).toISOString()) ||
																	'',
																enderecoMedico:
																	consultaProcessoAberto.data.enderecoMedico,
																enderecoPsicologo:
																	consultaProcessoAberto.data.enderecoPsicologo,
																horarioExame:
																	(consultaProcessoAberto.data.dataExame &&
																		new Date(
																			`${consultaProcessoAberto.data.dataExame} ${consultaProcessoAberto.data.horarioExame}`,
																		).toISOString()) ||
																	'',
																horarioExamePsicologo:
																	(consultaProcessoAberto.data
																		.dataExamePsicologo &&
																		new Date(
																			`${consultaProcessoAberto.data.dataExamePsicologo} ${consultaProcessoAberto.data.horarioExamePsicologo}`,
																		).toISOString()) ||
																	'',
																nomeCidadao:
																	consultaProcessoAberto.data
																		.nomeSocialCidadao ||
																	consultaProcessoAberto.data.nomeCidadao,
																nomeMedico:
																	consultaProcessoAberto.data.nomeMedico,
																nomePsicologo:
																	consultaProcessoAberto.data.nomePsicologo,
																telefones:
																	consultaProcessoAberto.data.telefones,
																telefonesPsicologo:
																	consultaProcessoAberto.data
																		.telefonesPsicologo,
																tipoProcesso:
																	consultaProcessoAberto.data.tipoProcesso.descricao.toUpperCase(),
																flagHabilitaAgendamentoMedico:
																	typeButtonImprimirExame.typeFlagExameMedico,
																flagHabilitaAgendamentoPsicologico:
																	typeButtonImprimirExame.typeFlagExamePsico,
															}),
														);
													}
												}}
											/>
										</Col>
									</Row>
								)}
						</BoxRenach>
					</Col>
				</Row>

				{pdfFile && pdfFile !== '' && (
					<PDFViewer
						title="Impressão Segunda Via CNH"
						source={pdfFile}
						renderMode="popup"
						onClose={() => setPdfFile('')}
					/>
				)}
			</Section>

			<Row justify="end" align="middle">
				<Col>
					<ButtonImage
						type="button"
						src="outro-servico-detran"
						onClick={() => {
							dispatch(detranCnhClear());
							history.push(ROUTE_DETRAN_CNH);
						}}
					/>
				</Col>
			</Row>
		</>
	);
};

export default RenachAlterado;
