import * as Yup from 'yup';

// STORE
import { ILogin } from 'store/modules/api/sgu/loginUnico/types';
import { ConsultaDBCINRequest } from 'store/modules/api/iirgd/consultaDBCIN/types';

// UTILS
import { isCpfValid } from 'utils/yupValidations';
import { limparMascara } from 'utils/genericFunctions';

export interface IFormConsultarDBCIN {
	numeroRg: string;
	digitoRg: string;
	cpf: string;
	RGempty: boolean;
}

export const initialValues: IFormConsultarDBCIN = {
	numeroRg: '',
	digitoRg: '',
	cpf: '',
	RGempty: false,
};

export const schema = Yup.object<IFormConsultarDBCIN>().shape({
	validacaoRG: Yup.string().when(['numeroRg'], {
		is: numeroRg => numeroRg.length !== 8,
		then: Yup.string().required(`Campo Obrigatório: RG.`),
	}),
	cpf: Yup.string()
		.required('Campo Obrigatório: CPF.')
		.test({
			name: 'isValidCPF',
			exclusive: true,
			message: 'CPF informado é inválido.',
			test: value => (value !== '' ? isCpfValid(value) : true),
		}),
});

export function treatValues(
	values: IFormConsultarDBCIN,
	login: ILogin,
): ConsultaDBCINRequest {
	const { cpf, numeroRg, digitoRg } = values;

	const numeroCpf = (cpf.length > 0 && cpf.split('-')[0]) ?? null;

	const digitoCpf = (cpf.length > 0 && cpf.split('-')[1]) ?? null;

	const registroNumero = numeroRg.length > 0 ? limparMascara(numeroRg) : '';

	const registroDigito = digitoRg.length > 0 ? limparMascara(digitoRg) : '';

	return {
		cpf: numeroCpf ? limparMascara(numeroCpf) : '',
		digitoCpf: digitoCpf ? limparMascara(digitoCpf) : '',
		registroNumero,
		registroDigito,
		registroSequencia: '1',
		usuario: login.mainframe.idUsuario,
		senha: login.mainframe.senhaMainFrame,
	};
}
