import { combineReducers } from 'redux';

import aac from './aac/combineReducers';
import biometria from './biometria/combineReducers';
import cdhu from './cdhu/combineReducers';
import dare from './dare/combineReducers';
import iirgd from './iirgd/combineReducers';
import rfb from './rfb/combineReducers';
import sgu from './sgu/combineReducers';
import eCpf from './eCpf/combineReducers';
import prefeituras from './prefeituras/combineReducers';
import seds from './seds/combineReducers';
import educacao from './educacao/combineReducers';
import detranCrv from './detranCrv/combineReducers';
import contabilizacao from './contabilizacao/combineReducers';
import detranCnh from './detranCnh/combineReducers';
import ministerioPublico from './mp/combineReducers';
import servicosMunicipais from './servicosMunicipais/combineReducers';
import educacaoUpdate from './educacaoUpdate/combineReducers';
import potencial from './potencial/combineReducers';
import utils from './utils/combineReducers';
import procon from './procon/combineReducers';
import sguService from './sguService/combineReducers';
import gerarDare from './gerarDare/reducer';
import relatorios from './relatorios/combineReducers';
import bolsaPovo from './bolsaPovo/combineReducers';

export default combineReducers({
	aac,
	biometria,
	cdhu,
	dare,
	iirgd,
	rfb,
	sgu,
	eCpf,
	prefeituras,
	seds,
	educacao,
	detranCrv,
	contabilizacao,
	detranCnh,
	ministerioPublico,
	servicosMunicipais,
	educacaoUpdate,
	potencial,
	utils,
	procon,
	gerarDare,
	sguService,
	relatorios,
	bolsaPovo,
});
