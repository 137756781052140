import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	Types,
	ExcluirFuncionalidadesRelacionadasLocalidadeRequest,
} from './types';

// ACTIONS
import {
	excluirFuncionalidadesRelacionadasLocalidadeSuccess,
	excluirFuncionalidadesRelacionadasLocalidadeFailure,
} from './actions';

function* excluirFuncionalidadesRelacionadasLocalidade(request: ReducerAction) {
	const {
		payload,
	}: { payload: ExcluirFuncionalidadesRelacionadasLocalidadeRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_SGU,
		`localidades-funcionalidade/${payload.id}`,
	);

	if (response.status === 200) {
		response.data = {
			id: payload.id,
		};
		yield put(excluirFuncionalidadesRelacionadasLocalidadeSuccess(response));
	} else {
		yield put(excluirFuncionalidadesRelacionadasLocalidadeFailure());
	}
}

export default all([
	takeLatest(
		Types.EXCLUIR_FUNCIONALIDADES_RELACIONADAS_LOCALIDADE,
		excluirFuncionalidadesRelacionadasLocalidade,
	),
]);
