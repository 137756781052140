import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestGerarRenachPrimeiraHabilitacao } from './types';

// ACTIONS
import {
	gerarRenachPrimeiraHabilitacaoSuccess,
	gerarRenachPrimeiraHabilitacaoFailure,
} from './actions';

function* cadatraRenach(request: ReducerAction) {
	const {
		payload,
	}: { payload: IRequestGerarRenachPrimeiraHabilitacao } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}/gerarRenachPrimeiraHabilitacao`,
		payload,
	);

	if (response.status === 200) {
		yield put(gerarRenachPrimeiraHabilitacaoSuccess(response));
	} else if (response.error) {
		yield put(gerarRenachPrimeiraHabilitacaoFailure(response.error));
	}
}

export default all([
	takeLatest(Types.GERAR_RENACH_PRIMEIRA_HABILITACAO, cadatraRenach),
]);
