import { all } from 'redux-saga/effects';

import buscaPaginadaSaga from './buscaPaginada/saga';
import consultaFornecedor from './consultaFornecedor/saga';
import pesquisaFornecedor from './pesquisaFornecedor/saga';
import cadastraFornecedor from './cadastraFornecedor/saga';

export default all([
	buscaPaginadaSaga,
	consultaFornecedor,
	pesquisaFornecedor,
	cadastraFornecedor,
]);
