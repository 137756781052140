import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import { consultaPaisesSuccess, consultaPaisesFailure } from './actions';

function* consultaPaises() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}/paises`,
		{},
	);

	if (response.status === 200) {
		yield put(consultaPaisesSuccess(response));
	} else if (response.error) {
		yield put(consultaPaisesFailure(response.error));
	}
}

export default all([takeLatest(Types.CONSULTA_PAISES_REQUEST, consultaPaises)]);
