import { combineReducers } from 'redux';

import consultaProcessoAberto from './consultaProcessoAberto/reducer';
import gerarRenachIntegrado from './gerarRenachIntegrado/reducer';
import alterarCadastro from './alterarCadastro/reducer';

export default combineReducers({
	consultaProcessoAberto,
	gerarRenachIntegrado,
	alterarCadastro,
});
