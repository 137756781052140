import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarSufixoRequest } from './types';

// ACTIONS
import { cadastrarSufixoSuccess, cadastrarSufixoFailure } from './action';

function* cadastrarSufixo(request: ReducerAction) {
	const { payload }: { payload: CadastrarSufixoRequest } = request;

	const body = { ...payload };

	delete body.codigo;

	const response: ApiResponse = yield call(postApi, PATH_SGU, `/sufixos`, body);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarSufixoSuccess(response));
	} else {
		yield put(cadastrarSufixoFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_SUFIXO, cadastrarSufixo)]);
