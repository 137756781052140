import styled from 'styled-components';

export const SlashDivider = styled.div`
	align-items: center;
	display: flex;

	div {
		margin: 0 5px;
	}
`;
