import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultaRenainfOrgao } from './types';

export function multasRenainfOrgaoRequest(
	data: IRequestConsultaRenainfOrgao,
): ReducerAction {
	return {
		type: Types.MULTAS_RENAINF_ORGAO_REQUEST,
		payload: data,
	};
}
export function multasRenainfOrgaoSuccess(payload: object) {
	return {
		type: Types.MULTAS_RENAINF_ORGAO_SUCCESS,
		payload,
	};
}
export function multasRenainfOrgaoFailure(): ReducerAction {
	return {
		type: Types.MULTAS_RENAINF_ORGAO_FAILURE,
		payload: null,
	};
}
export function multasRenainfOrgaoClear(): any {
	return {
		type: Types.MULTAS_RENAINF_ORGAO_CLEAR,
	};
}
