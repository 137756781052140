import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarPerfilRequest } from './types';

export function cadastrarPerfilRequest(
	data: CadastrarPerfilRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_PERFIL,
		payload: data,
	};
}
export function cadastrarPerfilSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_PERFIL_SUCCESS,
		payload,
	};
}
export function cadastrarPerfilFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_PERFIL_FAILURE,
		payload: null,
	};
}
export function cadastrarPerfilClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_PERFIL_CLEAR,
		payload: null,
	};
}
