import React from 'react';

// REDUX
import { ConsultaAtendimentosDeConsultaData } from 'store/modules/api/procon/atendimentosDeConsulta/consultaAtendimentosDeConsulta/types';

// ANT DESIGN
import { Col, Row } from 'antd';

// COMPONENTS
import LightButton from 'components/Common/Buttons/Light';
import TypographyCommon from 'components/Common/Typography';

// UTILS
import { formatDate } from 'utils/genericFunctions';

// STYLED
import { TitleSectionAtendimento } from 'store/modules/api/procon/enum';
import { styleCols } from '../../styled';

interface Props {
	handleBackSection: React.MouseEventHandler<HTMLButtonElement>;
	handleNextSection: React.MouseEventHandler<HTMLButtonElement>;
	formData: ConsultaAtendimentosDeConsultaData | null;
}

const AtendimentoTipoConsulta: React.FC<Props> = ({
	handleNextSection,
	handleBackSection,
	formData,
}) => {
	return (
		<>
			<Row gutter={[0, 0]} align="top" justify="start">
				<Col className="gutter-row" span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Dados do Atendimento"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="Protocolo:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.protocolo || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="Status:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.razaoDoStatus || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Data da Solicitação:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									formatDate(formData?.dataDaSolicitacao) || 'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="Prazo:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formatDate(formData?.prazo) || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Tipo de Atendimento:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.tipoDeAtendimento || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Tipo de Consumidor:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.tipoDoConsumidor || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="TIPO DE ÁREA:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.area || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="TIPO DE ASSUNTO:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.assunto || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={12}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="TIPO DE PROBLEMA:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.grupoDeProblema || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={12}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Classificação Atual:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.problema || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={24}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Detalhes do Serviço ou Produto:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.descricao || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<br />
			<Row gutter={16} align="top" className="no-print">
				<Col className="gutter-row" span={8}>
					<Row justify="start">
						<LightButton
							type="button"
							buttonColor="default"
							text="Voltar"
							icon="back"
							buttonSize="sm"
							buttonWidth={100}
							onClick={handleBackSection}
						/>
					</Row>
				</Col>
				<Col className="gutter-row" span={8}>
					<Row justify="center">
						<LightButton
							type="button"
							buttonColor="default"
							text="Imprimir"
							icon="print"
							buttonSize="sm"
							buttonWidth={100}
							onClick={() => window.print()}
						/>
					</Row>
				</Col>
				<Col className="gutter-row" span={8}>
					<Row align="top" justify="end">
						<LightButton
							type="button"
							buttonColor="default"
							text={TitleSectionAtendimento.FORNECEDOR}
							icon="next"
							buttonSize="sm"
							buttonWidth={190}
							leftOrRight="right"
							onClick={handleNextSection}
						/>
					</Row>
				</Col>
			</Row>
		</>
	);
};
export default AtendimentoTipoConsulta;
