export enum Types {
	ENVIAR_SMS_REQUEST = '@cnh/ENVIAR_SMS_REQUEST',
	ENVIAR_SMS_SUCCESS = '@cnh/ENVIAR_SMS_SUCCESS',
	ENVIAR_SMS_FAILURE = '@cnh/ENVIAR_SMS_FAILURE',
	ENVIAR_SMS_CLEAR = '@cnh/ENVIAR_SMS_CLEAR',
}

export interface enviaSMS {
	status: number;
	data: EnviaSMSData | null;
}

export interface EnviaSMSData {
	tokenId: string;
}

export interface RequestEnviaSMS {
	ddd: string;
	numero: string;
	mensagem: string;
}
