import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';
import { ILogin } from 'store/modules/api/sgu/loginUnico/types';
import { limparMascara } from 'utils/genericFunctions';

export interface SguData {
	salvarAtendimento: SalvarAtendimento;
	login: ILogin;
}

function hasValue(obj: any, key: string) {
	if (!obj) return false;
	return (
		Object.prototype.hasOwnProperty.call(obj, key) &&
		obj[key] !== '00000000-0000-0000-0000-000000000000'
	);
}

export const getIdData = (data: SguData) => {
	const { salvarAtendimento } = data;
	const idData: any = {
		idCidadao: '',
		idAtendimento: '',
	};
	if (
		idData.idCidadao === '' &&
		hasValue(salvarAtendimento.data, 'idCidadao')
	) {
		idData.idCidadao = salvarAtendimento.data?.idCidadao;
	}
	if (
		idData.idAtendimento === '' &&
		hasValue(salvarAtendimento.data, 'idAtendimento')
	) {
		idData.idAtendimento = salvarAtendimento.data?.idAtendimento;
	}
	return idData;
};

export interface IEventBuilderData {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export const getEventoConsultar = (
	sguData: SguData,
	cpfIdentificacao?: string,
): IEventBuilderData => {
	const { idCidadao, idAtendimento } = getIdData(sguData);
	const { cpf: cpfAtendente, ip, nomePosto, posto } = sguData.login.user;
	const identificacao =
		(cpfIdentificacao && limparMascara(cpfIdentificacao)) || '0';
	const evento = {
		id_atendimento: idAtendimento || '',
		id_cidadao:
			idCidadao !== '' ? idCidadao : '00000000-0000-0000-0000-000000000000',
		cpf: cpfAtendente,
		identificacao,
		ip,
		canal: {
			id: 1,
			desc: 'Presencial',
			localidade: {
				id: parseInt(posto, 10),
				desc: nomePosto,
			},
		},
	};
	return evento;
};
