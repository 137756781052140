import React, { useEffect, useState } from 'react';

// UTILS
import { toTitleCase } from 'utils/genericFunctions';

// STYLED
import { Clock } from './styled';

const DateTimeClock: React.FC = () => {
	const options = {
		weekday: 'long',
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
	};

	const [date, setDate] = useState(new Date());

	useEffect(() => {
		const interval = setInterval(() => {
			setDate(new Date());
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return <Clock>{toTitleCase(date.toLocaleString('pt-BR', options))}</Clock>;
};

export default DateTimeClock;
