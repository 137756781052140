export enum Types {
	IMPRESSAO_TERMO_PATERNIDADE_REQUEST = '@MP/IMPRESSAO_TERMO_PATERNIDADE_REQUEST',
	IMPRESSAO_TERMO_PATERNIDADE_SUCCESS = '@MP/IMPRESSAO_TERMO_PATERNIDADE_SUCCESS',
	IMPRESSAO_TERMO_PATERNIDADE_FAILURE = '@MP/IMPRESSAO_TERMO_PATERNIDADE_FAILURE',
	IMPRESSAO_TERMO_PATERNIDADE_CLEAR = '@MP/IMPRESSAO_TERMO_PATERNIDADE_CLEAR',
}
export interface impressaoTermoPaternidade {
	status: number;
	data: { arquivoPDF: string };
}
