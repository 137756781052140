export enum Types {
	CADASTRAR_TIPOS = '@SGU/CADASTRAR_TIPOS',
	CADASTRAR_TIPOS_SUCCESS = '@SGU/CADASTRAR_TIPOS_SUCCESS',
	CADASTRAR_TIPOS_FAILURE = '@SGU/CADASTRAR_TIPOS_FAILURE',
	CADASTRAR_TIPOS_CLEAR = '@SGU/CADASTRAR_TIPOS_CLEAR',
}

export interface CadastrarTipos {
	status: number;
	data: null | CadastrarTiposData;
}

export interface CadastrarTiposData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarTiposRequest {
	codigo?: number | string;
	descricao: string;
}
