import { Types, IRequestConsultaCnhDefinitiva } from './types';

export function consultaCnhDefinitivaRequest(
	data: IRequestConsultaCnhDefinitiva,
) {
	return {
		type: Types.CONSULTA_CNH_DEFINITIVA_REQUEST,
		payload: data,
	};
}
export function consultaCnhDefinitivaSuccess(payload: object) {
	return {
		type: Types.CONSULTA_CNH_DEFINITIVA_SUCCESS,
		payload,
	};
}
export function consultaCnhDefinitivaFailure(payload: object) {
	return {
		type: Types.CONSULTA_CNH_DEFINITIVA_FAILURE,
		payload,
	};
}
export function consultaCnhDefinitivaClear() {
	return {
		type: Types.CONSULTA_CNH_DEFINITIVA_CLEAR,
		payload: null,
	};
}
