import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { ApplicationState } from 'store';

import {
	ConsultaInteracoesAtendimentoRequest,
	Interacoes,
} from 'store/modules/api/procon/interacoes/atendimentos/consultaInteracoes/types';
import { consultaInteracoesAtendimentoRequest } from 'store/modules/api/procon/interacoes/atendimentos/consultaInteracoes/actions';

// ENUM
import { TitleSectionAtendimento } from 'store/modules/api/procon/enum';

// ANT DESIGN
import { Col, Row } from 'antd';

// UTILS
import hasError from 'utils/getFormErrors';
import { random } from 'utils/numberFunctions';
import { formatDateISOWithOutUTC } from 'utils/genericFunctions';
import getValidationsErrors from 'utils/getValidationsErrors';

// COMPONENTS
import { Form, Field, Formik } from 'formik';
import LightButton from 'components/Common/Buttons/Light';
import TextArea from 'components/Common/Form/Input/TextArea';
import FormBox from 'components/Common/Form/FormBox';
import TypographyCommon from 'components/Common/Typography';
import PDFViewer from 'components/Common/PDFViewer';

// FORM
import {
	enviaInteracoesAtendimentoClear,
	enviaInteracoesAtendimentoRequest,
} from 'store/modules/api/procon/interacoes/atendimentos/enviaInteracoes/actions';
import {
	EnviaInteracoesAtendimentoForm,
	EnviaInteracoesAtendimentoRequest,
} from 'store/modules/api/procon/interacoes/atendimentos/enviaInteracoes/types';
import Alert from 'components/Common/Notifications/Alert';
import { ConsultaAtendimentosDeConsultaData } from 'store/modules/api/procon/atendimentosDeConsulta/consultaAtendimentosDeConsulta/types';
import { initialValues, schema } from './form';

// STYLED
import { Mensagem } from './styled';

interface Props {
	handleBackSection: React.MouseEventHandler<HTMLButtonElement>;
	handleNextSection: React.MouseEventHandler<HTMLButtonElement>;
	formData: ConsultaAtendimentosDeConsultaData | null;
}

interface Attachment {
	showModal: boolean;
	base64: string;
}

const RespostasInteracoesTipoConsulta: React.FC<Props> = ({
	handleBackSection,
	handleNextSection,
	formData,
}) => {
	const dispatch = useDispatch();

	const { consultaInteracoes, enviaInteracoes } = useSelector(
		(state: ApplicationState) => state.api.procon.interacoes.atendimentos,
	);

	const { consultaAtendimentosDeConsulta } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentosDeConsulta,
	);

	const [formInitialValues, setFormInitialValues] =
		useState<EnviaInteracoesAtendimentoForm>(initialValues);

	const [interacoes, setInteracoes] = useState<Interacoes[]>([]);
	const [attachment, setAttachment] = useState<Attachment>({
		showModal: false,
		base64: '',
	});

	const getInfoAtendimento = useCallback(() => {
		let payload: {
			atendimentoId: string | null;
			protocolo: string | null;
		} = { atendimentoId: null, protocolo: null };

		if (consultaAtendimentosDeConsulta.status === 200) {
			if (consultaAtendimentosDeConsulta.data?.atendimentoId) {
				payload = {
					atendimentoId: consultaAtendimentosDeConsulta.data?.atendimentoId,
					protocolo: null,
				};
				return payload;
			}
			if (consultaAtendimentosDeConsulta.data?.protocolo) {
				payload = {
					atendimentoId: null,
					protocolo: consultaAtendimentosDeConsulta.data?.protocolo,
				};
				return payload;
			}
		}
		return payload;
	}, [consultaAtendimentosDeConsulta]);

	const handleSubmit = useCallback(
		(values: EnviaInteracoesAtendimentoForm) => {
			dispatch(enviaInteracoesAtendimentoClear());
			const infoAtendimento: {
				atendimentoId: string | null;
				protocolo: string | null;
			} = getInfoAtendimento();

			setFormInitialValues({
				respostaConsumidor: values.respostaConsumidor,
				anexos: values.anexos,
			});

			const payload: EnviaInteracoesAtendimentoRequest = {
				...infoAtendimento,
				textoDaInteracao: values.respostaConsumidor,
				anexos: values.anexos,
			};

			if (payload.anexos?.length === 0) {
				delete payload.anexos;
			}

			dispatch(enviaInteracoesAtendimentoRequest(payload));
		},
		[dispatch, getInfoAtendimento],
	);

	const handleRequestInteracoes = useCallback(() => {
		const payload: ConsultaInteracoesAtendimentoRequest = {
			atendimentoId: (formData?.protocolo ? '' : formData?.atendimentoId) || '',
			protocolo: formData?.protocolo || '',
		};

		dispatch(consultaInteracoesAtendimentoRequest(payload));
	}, [formData, dispatch]);

	const handleSeeAttached = useCallback((base64: string) => {
		setAttachment({ showModal: true, base64 });
	}, []);

	const handleDownloadAttached = useCallback((link: string) => {
		window.open(link, '_blank');
	}, []);

	const handleDefineBackgroundColorMessage = useCallback((type: string) => {
		switch (type) {
			case 'Consumidor':
				return 'rgb(197, 211, 223)';
			case 'Procon':
				return '#cccccc';
			case 'Fornecedor':
				return '#d3d081';
			default:
				return '#cccccc';
		}
	}, []);

	const handleDefineLeftOrRightMessage = useCallback((type: string) => {
		switch (type) {
			case 'Consumidor':
				return 'right';
			case 'Procon':
				return 'left';
			case 'Fornecedor':
				return 'left';
			default:
				return 'left';
		}
	}, []);

	const handleDefineTitleNameMessage = useCallback(
		(type: string, titleName: string) => {
			if (type === 'Consumidor') {
				return `${type} (${titleName})`;
			}

			return `${type}`;
		},
		[],
	);

	useEffect(() => {
		if (formData) {
			handleRequestInteracoes();
		}
	}, [formData, handleRequestInteracoes]);

	useEffect(() => {
		if (consultaInteracoes.data?.interacoes) {
			const novasInteracoes = [...consultaInteracoes.data?.interacoes];
			novasInteracoes.sort((a: Interacoes, b: Interacoes) => {
				return (
					new Date(a.dataDeCriacao).getTime() -
					new Date(b.dataDeCriacao).getTime()
				);
			});
			setInteracoes(novasInteracoes);
		}
	}, [consultaInteracoes, setInteracoes]);

	useEffect(() => {
		if (enviaInteracoes.status === 200) {
			setFormInitialValues(initialValues);
			const novosAnexos: string[] = [];

			let nome: string = '';
			if (consultaAtendimentosDeConsulta.data?.nomeDoResponsavelOuProcurador) {
				nome =
					consultaAtendimentosDeConsulta.data?.nomeDoResponsavelOuProcurador;
			}

			const novaInteracao: Interacoes = {
				interacaoId: '',
				descricao: enviaInteracoes.form?.textoDaInteracao || '',
				dataDeCriacao: new Date().toLocaleString(),
				responsavelPelaInteracao: 'Consumidor',
				interacaoRespondida: '',
				protocolo: '',
				nomeDoConsumidor: nome,
				linksDeDocumentos: novosAnexos,
				tramiteImportante: '',
				tramite: '',
				tipoDeTramite: '',
			};

			setInteracoes(prev => [...prev, novaInteracao]);
		}
	}, [enviaInteracoes, formData, dispatch, consultaAtendimentosDeConsulta]);

	return (
		<>
			{attachment.showModal && (
				<PDFViewer
					title="Anexos do Consumidor"
					source={attachment.base64}
					onClose={() => {
						setAttachment({ showModal: false, base64: '' });
					}}
				/>
			)}
			{enviaInteracoes.status === 200 && (
				<Alert
					type="success"
					message="Resposta do consumidor enviada com sucesso"
					onClose={() => dispatch(enviaInteracoesAtendimentoClear())}
				/>
			)}
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={formInitialValues}
				validate={values => getValidationsErrors(values, schema)}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{formik => (
					<Form autoComplete="off">
						<Row gutter={[0, 0]} align="top" justify="start">
							<Col
								className="gutter-row"
								span={24}
								style={{ paddingBottom: '10px' }}
							>
								<TypographyCommon
									text="Respostas e Interações"
									borderBottom
									type="h5"
									fontWeight="bold"
									uppercase
								/>
							</Col>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<Col className="gutter-row" span={24}>
								<Row
									gutter={16}
									align="top"
									justify="start"
									style={{ marginLeft: '0' }}
								>
									<TypographyCommon
										text="Resposta do Consumidor:"
										subtitle=" (Quantidade Mínima 50 Caracteres e Máxima 500)"
										fontWeight="bold"
										uppercase
									/>
								</Row>
								<Row gutter={16} align="top" justify="start">
									<Col span={24} className="gutter-row">
										<Field
											as={TextArea}
											name="respostaConsumidor"
											formik={formik}
											maxLength="500"
											error={hasError(formik.errors, 'respostaConsumidor')}
											disabled={formData?.interacao === 'Bloqueado'}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row gutter={16} align="top" justify="center">
							<LightButton
								type="submit"
								buttonColor="default"
								text="Salvar"
								icon="save"
								buttonSize="sm"
								buttonWidth={140}
								disabled={formData?.interacao === 'Bloqueado'}
							/>
						</Row>
						<br />
						<FormBox
							title="Histórico"
							overflow="y"
							height={
								consultaInteracoes.status === 200 && interacoes.length > 0
									? 500
									: 80
							}
						>
							{consultaInteracoes.status !== 200 || interacoes.length === 0 ? (
								<Row align="top" justify="center">
									<Col span="24">
										<Row align="top" justify="center">
											<LightButton
												type="button"
												buttonColor="default"
												text={
													consultaInteracoes.status === 400
														? 'Recarregar'
														: 'Não há interações, recarregar'
												}
												icon="refresh"
												buttonSize="sm"
												buttonWidth={
													consultaInteracoes.status === 400 ? 100 : 200
												}
												onClick={handleRequestInteracoes}
											/>
										</Row>
									</Col>
								</Row>
							) : (
								<>
									{interacoes.length > 0 &&
										interacoes.map((_interacao, index) => (
											<span
												key={
													_interacao.interacaoId
														? _interacao.interacaoId
														: index
												}
											>
												<>
													<Row gutter={[0, 10]} key={_interacao.interacaoId}>
														<Col span={24} style={{ overflowWrap: 'anywhere' }}>
															<Mensagem
																colorBackground={handleDefineBackgroundColorMessage(
																	_interacao.responsavelPelaInteracao,
																)}
																leftOrRight={handleDefineLeftOrRightMessage(
																	_interacao.responsavelPelaInteracao,
																)}
															>
																<Row
																	gutter={16}
																	align="top"
																	justify="start"
																	style={{ padding: '0 10px' }}
																>
																	<Col className="gutter-row" span={12}>
																		<Row
																			gutter={16}
																			align="top"
																			justify="start"
																		>
																			<div className="mensagemTitulo">
																				{handleDefineTitleNameMessage(
																					_interacao.responsavelPelaInteracao,
																					_interacao.nomeDoConsumidor,
																				)}
																			</div>
																		</Row>
																	</Col>
																	<Col className="gutter-row" span={12}>
																		<Row gutter={16} align="top" justify="end">
																			<div className="mensagemPeriodo">
																				{formatDateISOWithOutUTC(
																					_interacao.dataDeCriacao,
																					true,
																				)}
																			</div>
																		</Row>
																	</Col>
																</Row>
																<Row gutter={16} align="top" justify="start">
																	<Col className="gutter-row" span={24}>
																		<p className="mensagemDescricao">
																			{_interacao.descricao}
																		</p>
																	</Col>
																</Row>
																{_interacao.linksDeDocumentos &&
																	_interacao.linksDeDocumentos.length > 0 && (
																		<Row
																			gutter={16}
																			align="top"
																			justify="start"
																		>
																			{_interacao.linksDeDocumentos.map(
																				linkAnexo => (
																					<Col
																						className="gutter-row"
																						span={4}
																						key={random(100)}
																					>
																						<LightButton
																							type="button"
																							buttonColor="default"
																							text="Anexo"
																							icon="clip"
																							buttonSize="sm"
																							onClick={() => {
																								if (_interacao.interacaoId) {
																									handleDownloadAttached(
																										linkAnexo,
																									);
																								} else {
																									handleSeeAttached(linkAnexo);
																								}
																							}}
																						/>
																					</Col>
																				),
																			)}
																		</Row>
																	)}
															</Mensagem>
														</Col>
													</Row>
													<br />
												</>
											</span>
										))}
								</>
							)}
						</FormBox>
					</Form>
				)}
			</Formik>
			<br />
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span="8">
					<LightButton
						type="button"
						buttonColor="default"
						text="Voltar"
						icon="back"
						buttonSize="sm"
						buttonWidth={100}
						onClick={handleBackSection}
					/>
				</Col>
				<Col className="gutter-row" span="16">
					<Row align="top" justify="end">
						<LightButton
							type="button"
							buttonColor="default"
							text={TitleSectionAtendimento.DOCUMENTOS_PROCON}
							icon="next"
							buttonSize="sm"
							buttonWidth={190}
							leftOrRight="right"
							onClick={handleNextSection}
						/>
					</Row>
				</Col>
			</Row>
		</>
	);
};
export default RespostasInteracoesTipoConsulta;
