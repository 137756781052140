import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	DATAS_EXAME_PSICOLOGICO_REQUEST = '@cnh/DATAS_EXAME_PSICOLOGICO_REQUEST',
	DATAS_EXAME_PSICOLOGICO_SUCCESS = '@cnh/DATAS_EXAME_PSICOLOGICO_SUCCESS',
	DATAS_EXAME_PSICOLOGICO_ENUM_REQUEST = '@cnh/DATAS_EXAME_PSICOLOGICO_ENUM_REQUEST',
	DATAS_EXAME_PSICOLOGICO_ENUM_SUCCESS = '@cnh/DATAS_EXAME_PSICOLOGICO_ENUM_SUCCESS',
	DATAS_EXAME_PSICOLOGICO_FAILURE = '@cnh/DATAS_EXAME_PSICOLOGICO_FAILURE',
	DATAS_EXAME_PSICOLOGICO_CLEAR = '@cnh/DATAS_EXAME_PSICOLOGICO_CLEAR',
}

export interface datasExamePsicologico {
	status: number;
	data: IDadosDatasExamePsicologico | null;
	enumUnidades: {
		id_medico: number | null;
		unidades_disponiveis: OptionProps[];
	};
}

interface IDadosDatasExamePsicologico {
	enum: OptionProps[] | [];
	idPsicologo: number;
}

export interface IRequestDatasExamePsicologico {
	codigo_ciretran: number;
	cep_cidadao: number | string;
	// data_exame_medico: string;
	// horario_exame_medico: string;
	cpf_cidadao: number | string;
	usuario: string;
	id_micro_regiao?: number;
}
