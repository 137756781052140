import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// STORE
import { ReducerAction } from 'store/modules/types';

// UTILS
import { treatObjNullValues } from 'utils/genericFunctions';

// ACTIONS
import { pesquisaCITAAACSuccess, pesquisaCITAAACFailure } from './actions';

// TYPES
import { IRequestPesquisaCita, DataPesquisaCITA, Types } from './types';

function* pesquisaCITAAACRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestPesquisaCita } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		'consultaPesquisaCIRG',
		payload,
	);

	if (response.status === 200) {
		yield put(
			pesquisaCITAAACSuccess(
				treatObjNullValues(response.data) as DataPesquisaCITA,
			),
		);
	} else if (response.error) {
		yield put(pesquisaCITAAACFailure());
	}
}

export default all([
	takeLatest(Types.PESQUISA_CITA_AAC_REQUEST, pesquisaCITAAACRequest),
]);
