import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { postApi } from 'services/_api';
import { PATH_SERVICOS_PREFEITURAS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	cadastraPrefeiturasServicosValidacoesFailure,
	cadastraPrefeiturasServicosValidacoesSuccess,
	IPrefeiturasServicosValidacoes,
} from './actions';

function* cadastraPrefeiturasServicosValidacoes(request: ReducerAction) {
	const { payload }: { payload: IPrefeiturasServicosValidacoes } = request;

	const headerComplement = {
		pdfFileSize: 'A4',
	};

	const response = yield call(
		postApi,
		PATH_SERVICOS_PREFEITURAS,
		`presencial/prefeituras/${payload.codigoPefeitura}/servicos-prefeituras`,
		payload.body,
		headerComplement,
	);

	if (response.status === 200) {
		yield put(cadastraPrefeiturasServicosValidacoesSuccess(response));
	} else if (response.status === 204) {
		yield put(cadastraPrefeiturasServicosValidacoesFailure({}));
	}
}

export default all([
	takeLatest(
		Types.CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES,
		cadastraPrefeiturasServicosValidacoes,
	),
]);
