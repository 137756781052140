import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';

// TYPES
import { ReducerAction } from 'store/modules/types';

import { CHANNEL, PATH_SGU_SERVICE } from 'services/_path';
import { PreCadastroCidadaoRequest, Types } from './types';

// ACTIONS
import {
	preCadastroCidadaoSuccess,
	preCadastroCidadaoFailure,
} from './actions';

function* preCadastroCidadaoRequest(request: ReducerAction) {
	const { payload }: { payload: PreCadastroCidadaoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU_SERVICE,
		`portal-poupatempo/${CHANNEL}/pre-cadastro-cidadao`,
		payload,
		undefined,
		true,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(preCadastroCidadaoSuccess(response));
	} else if (response.error || response.message !== '') {
		yield put(preCadastroCidadaoFailure(response.message));
	} else {
		yield put(
			preCadastroCidadaoFailure('Serviço de geração de senha retornou erro.'),
		);
	}
}

export default all([
	takeLatest(Types.PRE_CADASTRO_CIDADAO_REQUEST, preCadastroCidadaoRequest),
]);
