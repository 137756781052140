import { Types } from './types';

export function pesquisarAlunoRequest(payload: any) {
	return {
		type: Types.PESQUISAR_ALUNO_REQUEST,
		payload,
	};
}

export function pesquisarAlunoSuccess(payload: object) {
	return {
		type: Types.PESQUISAR_ALUNO_SUCCESS,
		payload,
	};
}

export function pesquisarAlunoNoContent(payload: object) {
	return {
		type: Types.PESQUISAR_ALUNO_NO_CONTENT,
		payload,
	};
}

export function pesquisarAlunoFailure(payload: boolean) {
	return {
		type: Types.PESQUISAR_ALUNO_FAILURE,
		payload,
	};
}

export function pesquisarAlunoClear() {
	return {
		type: Types.PESQUISAR_ALUNO_CLEAR,
		payload: null,
	};
}
