import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Formik, Form } from 'formik';

// REDUX
import { consultaEPortalAutomatizadoRequest } from 'store/modules/api/detranCnh/permissaoInternacionalAutomatizado/consultaEPortalAutomatizado/actions';
import { consultaEnderecosRequest } from 'store/modules/api/detranCnh/condutor/enderecos/actions';
import { consultaCEPRequest } from 'store/modules/api/detranCnh/consulta/cep/actions';
import { consultaPermissaoInternacionalAutomatizadoRequest } from 'store/modules/api/detranCnh/permissaoInternacionalAutomatizado/consultaPermissaoInternacionalAutomatizado/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { eventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import CPF from 'components/Common/Form/Fields/CPF';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import RedirecionamentoRenovacaoCNHVencida from 'pages/DetranCnh/components/pages/RedirecionamentoRenovacaoCNHVencida';

// UTILS
import { validateCnh } from 'utils/genericFunctions';
import getValidationsErrors from 'utils/getValidationsErrors';
import { threatValuesStartSessionEventRequest } from 'utils/functions/threatEventsRequest';

// ROUTES
import { ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_STATUS_CONDUTOR } from 'pages/DetranCnh/routes/paths';

// FORM
import {
	treatValues,
	treatValuesPid,
	initialValues,
	schema,
	IFormPesquisaPermissaoInternacionalAutomatizado,
} from './form';

const PesquisaPermissaoInternacionalAutomatizado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [isValidateCnh, setIsValidateCnh] = useState(3);
	// const [modalProcurador, setModalProcurador] = useState('N');
	// const [openModalConfirm, setOpenModalConfirm] = useState(false);

	const {
		consultaEPortalAutomatizado,
		consultaPermissaoInternacionalAutomatizado,
	} = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.permissaoInternacionalAutomatizado,
	);

	const { consultaEndereco } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { idUsuario, senhaMainFrame } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.mainframe,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const handleSubmit = useCallback(
		(values: IFormPesquisaPermissaoInternacionalAutomatizado) => {
			const valuesRequest = treatValues(values);
			dispatch(
				consultaEnderecosRequest({
					cpf: valuesRequest,
				}),
			);
			const requestPIDData = treatValuesPid(valuesRequest, {
				usuario: idUsuario,
				senha: senhaMainFrame,
			});

			const valuesStartSessionEvent = threatValuesStartSessionEventRequest(
				atendimento,
				loginUnico,
			);
			dispatch(eventoInicioSessaoRequest(valuesStartSessionEvent));

			dispatch(
				consultaPermissaoInternacionalAutomatizadoRequest(requestPIDData),
			);
		},
		[atendimento, dispatch, idUsuario, loginUnico, senhaMainFrame],
	);

	useEffect(() => {
		if (
			consultaPermissaoInternacionalAutomatizado.status === 200 &&
			consultaPermissaoInternacionalAutomatizado.data
		) {
			if (consultaPermissaoInternacionalAutomatizado.data.dataValidadeCnh) {
				const validaCnh = validateCnh(
					consultaPermissaoInternacionalAutomatizado.data.dataValidadeCnh,
				);
				if (validaCnh === 3) {
					dispatch(
						consultaEPortalAutomatizadoRequest(
							consultaPermissaoInternacionalAutomatizado.data.cpf,
						),
					);
				}
				setIsValidateCnh(validaCnh);
			}
		}
	}, [
		consultaPermissaoInternacionalAutomatizado,
		dispatch,
		history,
		consultaEndereco,
		idUsuario,
		senhaMainFrame,
	]);

	useEffect(() => {
		if (
			consultaEPortalAutomatizado.status === 200 &&
			consultaEPortalAutomatizado.data &&
			isValidateCnh === 3
		) {
			if (Object.values(consultaEPortalAutomatizado.data).length > 0) {
				dispatch(
					consultaCEPRequest({ cep: consultaEPortalAutomatizado.data.cep }),
				);

				if (consultaEndereco.status === 200) {
					history.push(
						ROUTE_DETRAN_CNH_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_STATUS_CONDUTOR,
					);
				}
			}
		}
	}, [
		consultaEPortalAutomatizado,
		isValidateCnh,
		dispatch,
		history,
		idUsuario,
		senhaMainFrame,
		consultaEndereco.status,
	]);

	return (
		<>
			{isValidateCnh !== 3 ? (
				<RedirecionamentoRenovacaoCNHVencida
					rengeVencimento={2}
					nomeCidadao={
						consultaPermissaoInternacionalAutomatizado?.data?.nome || ''
					}
				/>
			) : (
				<Section title="Pesquisar">
					<Row>
						<Col span={24}>
							<Formik
								validateOnChange={false}
								validateOnBlur={false}
								initialValues={initialValues}
								validate={values => getValidationsErrors(values, schema)}
								onSubmit={handleSubmit}
							>
								{formik => (
									<Form autoComplete="off">
										<Row gutter={[0, 10]}>
											<Col span={11}>
												<CPF
													title="CPF"
													subtitle="(Digite apenas números)"
													numero="cpf"
													formik={formik}
													required
													error={!!formik.errors.cpf}
												/>
											</Col>
										</Row>

										<Row justify="center">
											<Col>
												<ButtonImage
													src="limpar"
													onClick={() => {
														dispatch(clearNotifications());
														formik.handleReset();
													}}
												/>
											</Col>

											<Col offset={1}>
												<ButtonImage type="submit" src="pesquisar" />
											</Col>
										</Row>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</Section>
			)}
			{/* <Modal
				visible={openModalConfirm}
				onCancel={() => setOpenModalConfirm(false)}
				width={500}
				footer={null}
				style={{
					animationDuration: '0s',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				className="modal-confirm-procurador"
				destroyOnClose
			>
				<div className="modal-confirm-procurador__content">
					<strong>Você é procurador de algum cidadão?</strong>
					<Radio
						name="procurador"
						value={modalProcurador}
						options={[
							{ value: 'S', label: 'Sim' },
							{ value: 'N', label: 'Não' },
						]}
						onChange={v => {
							setModalProcurador(v.target.value);
						}}
					/>
				</div>
				<Button
					type="button"
					className="modal-confirm-procurador__button-prosseguir"
					onClick={() => {
						handleSearchUser();
					}}
				>
					Prosseguir
				</Button>
			</Modal> */}

			<ButtonVoltar route="/detran-cnh" />
		</>
	);
};
export default PesquisaPermissaoInternacionalAutomatizado;
