import styled from 'styled-components';
import Button from 'components/Common/Button';

export const StyledButton = styled(Button)`
	position: absolute;
	vertical-align: top;
	top: -10px;
	right: -10px;
	border-radius: 10em;
	padding-bottom: 12px;
	text-decoration: none;
	font: 700 21px/20px sans-serif;
	background: #555;
	border: 3px solid #fff;
	color: #fff;
	box-shadow: 0 2px 6px rgb(0 0 0 / 50%), inset 0 2px 4px rgb(0 0 0 / 30%);
	text-shadow: 0 1px 2px rgb(0 0 0 / 50%);
	-webkit-transition: background 0.5s;
	transition: background 0.5s;

	min-width: 10px;
	width: 30px;
	height: 30px;
`;
