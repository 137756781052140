export enum Types {
	EMISSAO_PID = '@cnh/EMISSAO_PID',
	EMISSAO_PID_SUCCESS = '@cnh/EMISSAO_PID_SUCCESS',
	EMISSAO_PID_FAILURE = '@cnh/EMISSAO_PID_FAILURE',
}

export interface emissaoPid {
	status: number;
	data: null;
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		estacao: {
			id: number;
			desc: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface RequestEmissaoPid {
	cpf: string;
	cpfUsuario: string;
	ciretran: string;
	evento: IEvento;
}
