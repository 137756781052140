import { all } from 'redux-saga/effects';

import sexos from './sexos/saga';
import estados from './estados/saga';
import municipios from './municipios/saga';
import participacoesAudiencia from './participacoesAudiencia/saga';
import formaAquisicao from './formaAquisicao/saga';
import tiposContratacao from './tiposContratacao/saga';
import tiposDocumentosCompra from './tiposDocumentosCompra/saga';
import formasDevolucao from './formasDevolucao/saga';
import formasPagamento from './formasPagamento/saga';
import pedidosConsumidor from './pedidosConsumidor/saga';
import tiposResolucao from './tiposResolucao/saga';
import tiposChavePix from './tiposChavePix/saga';

export default all([
	sexos,
	estados,
	municipios,
	participacoesAudiencia,
	formaAquisicao,
	tiposContratacao,
	tiposDocumentosCompra,
	formasDevolucao,
	formasPagamento,
	pedidosConsumidor,
	tiposResolucao,
	tiposChavePix,
]);
