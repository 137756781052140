export interface IBuscarCepResultadoEndRecebimento {
	cep: string;
	bairro: string;
	tipoLogradouro: string;
	endereco: string;
	complemento: string;
	tipoLogradouroAbrev: string;
	enderecoAbrev: string;
	tipoLogradouroAbrevDNE: string;
	localidade: string;
	estado: string;
	uf: string;
	numeroIBGE: number;
	logradouro: string;
	cdTipoCEP: number;
	tipoCEP: string;
	municipio: string;
	tipoLocalidade: string;
	codigoMunicipio: number;
	codigoLocalRel: number;
	latitude: string;
	longitude: string;
	codigoDne: number;
	tipoLogradouroDne: number;
	codigoBairro: number;
}

export interface EnderecoCEP {
	data?: IBuscarCepResultadoEndRecebimento;
	status: number;
}

export const initialResultValuesEndRecebimento: IBuscarCepResultadoEndRecebimento =
	{
		cep: '',
		bairro: '',
		tipoLogradouro: '',
		endereco: '',
		complemento: '',
		tipoLogradouroAbrev: '',
		enderecoAbrev: '',
		tipoLogradouroAbrevDNE: '',
		localidade: '',
		estado: '',
		uf: '',
		numeroIBGE: 0,
		logradouro: '',
		cdTipoCEP: 0,
		tipoCEP: '',
		municipio: '',
		tipoLocalidade: '',
		codigoMunicipio: 0,
		codigoLocalRel: 0,
		latitude: '',
		longitude: '',
		codigoDne: 0,
		tipoLogradouroDne: 0,
		codigoBairro: 0,
	};
