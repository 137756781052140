export enum Types {
	EXCLUIR_MENSAGEM_REQUEST = '@SGU/EXCLUIR_MENSAGEM_REQUEST',
	EXCLUIR_MENSAGEM_SUCCESS = '@SGU/EXCLUIR_MENSAGEM_SUCCESS',
	EXCLUIR_MENSAGEM_FAILURE = '@SGU/EXCLUIR_MENSAGEM_FAILURE',
	EXCLUIR_MENSAGEM_CLEAR = '@SGU/EXCLUIR_MENSAGEM_CLEAR',
}

export interface ExcluirMensagem {
	status: number;
	data: null | IExcluirMensagem;
}

export interface IExcluirMensagem {
	status: string;
	statusMensagem: string;
}

export interface ExcluirMensagemRequest {
	search?: string;
	id?: number | string;
}
