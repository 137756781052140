/* eslint-disable array-callback-return */
import produce from 'immer';

// TYPES
import { Types, IncluirExameMedico } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: IncluirExameMedico = {
	status: 0,
	data: null,
};

export default function incluirExameMedicoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): IncluirExameMedico {
	return produce(state, draft => {
		switch (action.type) {
			case Types.INCLUIR_EXAME_MEDICO_REQUEST: {
				draft.status = 100;
				break;
			}

			case Types.INCLUIR_EXAME_MEDICO_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = action.payload.status;
				break;
			}

			case Types.INCLUIR_EXAME_MEDICO_FAILURE: {
				break;
			}

			case Types.INCLUIR_EXAME_MEDICO_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				break;
			}

			default:
		}
	});
}
