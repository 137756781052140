import { ReducerAction } from 'store/modules/types';

import { IConsultaNaoProcessadaRequest, Types } from './types';

export function consultaNaoProcessada(
	data: IConsultaNaoProcessadaRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_NAO_PROCESSADA,
		payload: data,
	};
}
export function consultaNaoProcessadaSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_NAO_PROCESSADA_SUCCESS,
		payload,
	};
}
export function consultaNaoProcessadaFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_NAO_PROCESSADA_FAILURE,
		payload,
	};
}
