import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { IRequestWithHeader, Types } from './types';

// ACTIONS
import {
	emitirAlteracaoDadosCadastraisSuccess,
	emitirAlteracaoDadosCadastraisFailure,
} from './actions';

function* emitirAlteracaoDadosCadastraisRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestWithHeader } = request;
	const { body, headers } = payload;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/emissao/alteracoes-dados-cadastrais`,
		body,
		headers,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(emitirAlteracaoDadosCadastraisSuccess(response.data));
	} else if (response.error) {
		yield put(emitirAlteracaoDadosCadastraisFailure());
	}
}

export default all([
	takeLatest(
		Types.EMITIR_ALTERACAO_DADOS_CADASTRAIS_REQUEST,
		emitirAlteracaoDadosCadastraisRequest,
	),
]);
