import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaInteracoesProcesso } from './types';

export const INITIAL_STATE: ConsultaInteracoesProcesso = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaInteracoesProcesso {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_INTERACOES_PROCESSO_REQUEST: {
				break;
			}
			case Types.CONSULTA_INTERACOES_PROCESSO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTA_INTERACOES_PROCESSO_FAILURE: {
				draft.status = 0;
				draft.data = action.payload;
				break;
			}
			case Types.CONSULTA_INTERACOES_PROCESSO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
