import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
// import { ApiResponse, postApi } from 'services/_api';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestPesquisarUsuarioPortal } from './types';

// ACTIONS
import {
	pesquisarUsuarioPortalSuccess,
	pesquisarUsuarioPortalFailure,
} from './actions';

function* pesquisarUsuarioPortal(request: ReducerAction) {
	const { payload }: { payload: IRequestPesquisarUsuarioPortal } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`cnh-digital/${CHANNEL}/pesquisar-usuario-portal`,
		payload,
	);

	if (response.status === 200) {
		yield put(pesquisarUsuarioPortalSuccess(response));
	} else if (response.error) {
		yield put(pesquisarUsuarioPortalFailure());
	}
}

export default all([
	takeLatest(Types.PESQUISAR_USUARIO_PORTAL, pesquisarUsuarioPortal),
]);
