import { ConsultaRestricoesRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/desbloqueio/consultaRestricoes/types';
import {
	RegistraSolicitacoesDataSearch,
	RegistraSolicitacoesRequest,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/desbloqueio/registraSolicitacoes/types';
import { IEventBuilderData } from 'utils/eventBuilder';
import {
	formatDateISOWithTimeZone,
	removeAcentoCaracteresEspeciais,
} from 'utils/genericFunctions';

export const threatRequestDataConsultaRestricoesDesbloqueio = (
	valuesSearch: ConsultaRestricoesRequest,
): ConsultaRestricoesRequest => {
	return {
		placa: valuesSearch.placa,
		renavam: valuesSearch.renavam,
		cpfCnpj: valuesSearch.cpfCnpj,
		opcao: valuesSearch.opcao,
	};
};

export const threatRequestDataRegistraSolicitacoesDesbloqueio = (
	evento: IEventBuilderData,
	valuesSearch: RegistraSolicitacoesDataSearch,
): RegistraSolicitacoesRequest => {
	return {
		request: {
			evento: {
				id_atendimento: evento?.id_atendimento || '',
				id_cidadao: evento?.id_cidadao || '',
				cpf: evento?.cpf || '',
				identificacao: valuesSearch?.cpfCnpj || '',
				ip: evento?.ip || '',
				canal: {
					id: evento?.canal?.id || 0,
					desc: evento?.canal?.desc || '',
					localidade: {
						id: evento?.canal?.id || 0,
						desc: evento?.canal?.localidade?.desc || '',
					},
				},
			},
			codUnidadeTransito: evento?.canal?.localidade?.id?.toString() || '',
			cpfUsuarioBU: evento?.cpf || '',
			placa: valuesSearch?.placa || '',
			renavam: valuesSearch?.renavam || '',
			cpfCnpj: valuesSearch?.cpfCnpj || '',
			opcao: valuesSearch?.opcao || '',
			documentos: [
				{
					conteudo:
						(valuesSearch.documentos &&
							valuesSearch?.documentos[0]?.conteudo) ||
						'',
					tipoArquivo:
						(valuesSearch.documentos &&
							valuesSearch.documentos[0]?.tipoArquivo) ||
						'',
				},
			],
			servicoDigital: valuesSearch?.servicoDigital || false,
			cpfSolicitante: valuesSearch?.cpfSolicitante || '',
			ipAcesso: evento?.ip || '',
			nomeCidadao:
				(valuesSearch?.nomeCidadao &&
					removeAcentoCaracteresEspeciais(valuesSearch.nomeCidadao)) ||
				'',
			emailCidadao: valuesSearch?.emailCidadao || '',
			dddTelefoneCidadao: valuesSearch?.dddTelefoneCidadao || '',
			telefoneCidadao: valuesSearch?.telefoneCidadao || '',
		},
		requestDate: formatDateISOWithTimeZone(new Date()) || '',
	};
};
