export enum Types {
	CONSULTAR_USUARIOS_REQUEST = '@SGU/CONSULTAR_USUARIOS_REQUEST',
	CONSULTAR_USUARIOS_SUCCESS = '@SGU/CONSULTAR_USUARIOS_SUCCESS',
	CONSULTAR_USUARIOS_FAILURE = '@SGU/CONSULTAR_USUARIOS_FAILURE',
	CONSULTAR_USUARIOS_CLEAR = '@SGU/CONSULTAR_USUARIOS_CLEAR',
}

export interface ConsultarUsuarios {
	status: number;
	data: Usuarios[] | null;
}

interface Usuarios {
	id: number;
	nome: string;
	cpf: string;
	status: {
		codigo: number;
		descricao: string;
	};
	perfil: {
		codigo: number;
		descricao: string;
	};
}

export interface ConsultarUsuariosRequest {
	registroInicial?: number;
	limite?: number;
	current?: number;
	tipoDocumento?: 'nome' | 'cpf';
	cpf?: string;
	nome?: string;
}
