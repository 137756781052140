import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../types';
import { Types, RfbState } from './types';

import consultarCpf from './consultarCpf/reducer';
import emitirCin from './emitirCin/reducer';
import cadastrarCin from './cadastrarCin/reducer';
import consistenciaBiometrica from './consistenciaBiometrica/reducer';
import consultarCin from './consultarCin/reducer';
import consultarProcesso from './consultarProcesso/reducer';

const allReducers = combineReducers({
	consultarCpf,
	emitirCin,
	cadastrarCin,
	consistenciaBiometrica,
	consultarCin,
	consultarProcesso,
});

const combineReducer = (state: RfbState, action: ReducerAction) => {
	if (action.type === Types.RFB_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
