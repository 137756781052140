import pepmSaga from './pepm/saga';
import pgatSaga from './pgat/saga';
import pcovSaga from './pcov/saga';
import txutSaga from './txut/saga';
import ptresSaga from './ptres/saga';
import ptreoeSaga from './ptreoe/saga';
import pebeSaga from './pebe/saga';

export const pepm = pepmSaga;
export const pgat = pgatSaga;
export const pcov = pcovSaga;
export const txut = txutSaga;
export const ptres = ptresSaga;
export const ptreoe = ptreoeSaga;
export const pebe = pebeSaga;
