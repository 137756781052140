export enum Types {
	ALTERA_TRANSFERENCIA_ENDERECO = '@cnh/ALTERA_TRANSFERENCIA_ENDERECO_T',
	ALTERA_TRANSFERENCIA_ENDERECO_SUCCESS = '@cnh/ALTERA_TRANSFERENCIA_ENDERECO_SUCCESS_T',
	ALTERA_TRANSFERENCIA_ENDERECO_FAILURE = '@cnh/ALTERA_TRANSFERENCIA_ENDERECO_FAILURE_T',
}

export interface AlteraTransferenciaEndereco {
	status: number;
	data: TransferenciaEnderecoState | null;
}

export interface TransferenciaEnderecoState {
	codigoInterno: string;
	cpf: string;
	message: string;
	messageAlert: string;
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		estacao: {
			id: number;
			desc: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface AlteraTransferenciaEnderecoRequest {
	apresentaDocumentos: string;
	bairro: string;
	cep: string;
	codigoMunicipioIBGE: string;
	complemento: string;
	cpf: string;
	cpfUsuario: string;
	logradouro: string;
	numero: string;
	numPgu: string;
	codigoUnidadeAtendimento: string;
	semNumero?: string;
	evento?: IEvento;
}
