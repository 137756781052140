import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultaTipoRequest } from 'store/modules/api/detranCrv/enum/consultaTipo/actions';
import { consultaEspecieRequest } from 'store/modules/api/detranCrv/enum/consultaEspecie/actions';
import { consultaCarroceriaRequest } from 'store/modules/api/detranCrv/enum/consultaCarroceria/actions';
import { consultaCorRequest } from 'store/modules/api/detranCrv/enum/consultaCor/actions';
import { consultaCombustivelRequest } from 'store/modules/api/detranCrv/enum/consultaCombustivel/actions';
import { consultarVeiculoV110Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import { emitirAutorizacaoPreviaRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirAutorizacaoPrevia/actions';
import data from 'store/modules/api/detranCrv/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import Select from 'components/Common/Form/Select';
import TextArea from 'components/Common/Form/Input/TextArea';
import Button from 'components/Common/Button';
import Placa from 'components/Common/Form/Fields/Placa';
import BuscarMarcas from 'components/Common/SearchButtons/Marcas';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';

// COMPONENTS
import ButtonPesquisaTipoAutorizacao from './ButtonPesquisaTipoAutorizacao';

// FORM
import { IAutorizacaoPrevia, initialValues, schema, treatValues } from './form';

const AutorizacaoPrevia: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const {
		consultaCarroceria,
		consultaCombustivel,
		consultaCor,
		consultaEspecie,
		consultaTipo,
	} = useSelector((state: ApplicationState) => state.api.detranCrv.Enum);

	const { consultarVeiculoV110 } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { emitirAutorizacaoPrevia } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (!consultarVeiculoV110.data) {
			history.push('/detran-crv/registrar-autorizacao-previa');
		}
	}, [consultarVeiculoV110.data, history]);

	useEffect(() => {
		if (
			consultarVeiculoV110.data &&
			consultaTipo.data &&
			consultaEspecie.data &&
			consultaCarroceria.data &&
			consultaCor.data &&
			consultaCombustivel.data
		) {
			const {
				chassi,
				placa,
				renavam,
				marca,
				tipo,
				especie,
				carroceria,
				capacidadePassageiro,
				cor,
				combustivel,
				potencia,
				cilindrada,
				pbt,
				cmt,
				capacidadeCarga,
			} = consultarVeiculoV110.data.caracteristicaVeiculo;

			const date = new Date();
			const dia = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
			const mes =
				date.getMonth() + 1 < 10
					? `0${date.getMonth() + 1}`
					: date.getMonth() + 1;
			const ano = date.getFullYear();

			initialValues.chassi = chassi;
			initialValues.placa = placa;
			initialValues.renavam = renavam;
			initialValues.dataAutorizacaoDetran = `${dia}/${mes}/${ano}`;
			initialValues.cpfResponsavel = user.cpf;
			initialValues.nomeResponsavel = user.nome;
			initialValues.descricaoMarca = marca.descricao;
			initialValues.longIdMarca = marca.longId;
			initialValues.descricaoTipo = tipo.descricao;
			initialValues.longIdTipo = tipo.longId;
			initialValues.descricaoEspecie = especie.descricao;
			initialValues.longIdEspecie = especie.longId;
			initialValues.descricaoCarroceria = carroceria.descricao;
			initialValues.longIdCarroceria = carroceria.longId;
			initialValues.potencia = potencia;
			initialValues.cilindrada = cilindrada;
			initialValues.capacidadePassageiro = capacidadePassageiro;
			initialValues.descricaoCor = cor.descricao;
			initialValues.longIdCor = cor.longId;
			initialValues.descricaoCombustivel = combustivel.descricao;
			initialValues.longIdCombustivel = combustivel.longId;

			initialValues.pbt = pbt
				? Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(pbt)
				: Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(0);

			initialValues.cmt = cmt
				? Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(cmt)
				: Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(0);

			initialValues.capacidadeCarga = capacidadeCarga
				? Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(capacidadeCarga)
				: Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(0);

			setHasLoaded(true);
		}
	}, [
		consultarVeiculoV110.data,
		consultaTipo.data,
		consultaEspecie.data,
		consultaCarroceria.data,
		consultaCor.data,
		consultaCombustivel.data,
		user,
		history,
	]);

	// Enum: Tipo de Veículo.
	useEffect(() => {
		if (consultaTipo.data.length === 0) {
			dispatch(consultaTipoRequest());
		}
	}, [consultaTipo, dispatch]);

	// Enum: Espécie.
	useEffect(() => {
		if (consultaEspecie.data.length === 0) {
			dispatch(consultaEspecieRequest());
		}
	}, [consultaEspecie, dispatch]);

	// Enum: Carroceria.
	useEffect(() => {
		if (consultaCarroceria.data.length === 0) {
			dispatch(consultaCarroceriaRequest());
		}
	}, [consultaCarroceria, dispatch]);

	// Enum: Cor.
	useEffect(() => {
		if (consultaCor.data.length === 0) {
			dispatch(consultaCorRequest());
		}
	}, [consultaCor, dispatch]);

	// Enum: Combustível.
	useEffect(() => {
		if (consultaCombustivel.data.length === 0) {
			dispatch(consultaCombustivelRequest());
		}
	}, [consultaCombustivel, dispatch]);

	useEffect(() => {
		if (emitirAutorizacaoPrevia.data) {
			history.push('/detran-crv/resultado-autorizacao-previa');
		}
	}, [emitirAutorizacaoPrevia.data, history]);

	const handleValidate = useCallback((formValues: IAutorizacaoPrevia) => {
		return getValidationsErrors(formValues, schema);
	}, []);

	const handleSubmit = useCallback(
		(formValues: IAutorizacaoPrevia) => {
			const values = treatValues(formValues);

			dispatch(emitirAutorizacaoPreviaRequest(values));
		},
		[dispatch],
	);

	const handleButtonCancelar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());
		history.push('/detran-crv/autorizacao-previa');
	}, [dispatch, history]);

	const handleButtonVoltar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());
		history.push('/detran-crv/autorizacao-previa');
	}, [dispatch, history]);

	return (
		<>
			{hasLoaded && (
				<>
					<Row>
						<Col span={24}>
							<Section size="lg" title="Autorização Prévia" titleSize="sm">
								<Formik
									validateOnChange={false}
									validateOnBlur={false}
									initialValues={initialValues}
									validate={handleValidate}
									onSubmit={handleSubmit}
								>
									{formik => (
										<Form autoComplete="off">
											<Row gutter={[0, 10]}>
												<Col span={24}>
													<Section size="lg" title="Autorização" titleSize="sm">
														<Row gutter={[0, 10]}>
															<Col span={11}>
																<Field
																	as={Input}
																	title="Chassi"
																	titleSize="lg"
																	name="chassi"
																	maxLength={22}
																	size={80}
																	required
																	error={!!formik.errors.chassi}
																/>
															</Col>

															<Col span={11}>
																<Placa
																	title="Placa"
																	titleSize="xl"
																	name="placa"
																	defaultValue={initialValues.placa}
																	size={92}
																	required
																	formik={formik}
																/>
															</Col>
														</Row>

														<Row gutter={[0, 10]}>
															<Col span={11}>
																<Field
																	as={Input}
																	title="Renavam"
																	titleSize="lg"
																	name="renavam"
																	maxLength={12}
																	size={80}
																	required
																	error={!!formik.errors.renavam}
																/>
															</Col>

															<Col span={11}>
																<Field
																	as={Select}
																	title="Tipo de Autorização"
																	titleSize="lg"
																	name="tipoAutorizacaoPrevia"
																	options={data.tipoAutorizacaoPrevia}
																	onChange={(v: string) =>
																		formik.setFieldValue(
																			'tipoAutorizacaoPrevia',
																			v,
																		)
																	}
																	size={92}
																	required
																	error={!!formik.errors.tipoAutorizacaoPrevia}
																/>
															</Col>
														</Row>

														<Row gutter={[0, 10]}>
															<Col span={24} />
														</Row>

														<Row justify="center" gutter={[0, 10]}>
															<Col span={17} style={{ marginLeft: '79px' }}>
																<Row gutter={[0, 10]}>
																	<Col span={24}>
																		<Field
																			as={TextArea}
																			name="descricaoTipoModificacao"
																			disabled
																			error={hasError(
																				formik.errors,
																				'tipoModificacao',
																			)}
																		/>
																	</Col>
																</Row>

																<Row>
																	<Col>
																		<ButtonPesquisaTipoAutorizacao
																			formik={formik}
																			disabled={
																				!formik.values.tipoAutorizacaoPrevia
																			}
																			result={res => {
																				formik.setFieldValue(
																					'codigoTipoModificacao',
																					res.codigo,
																				);
																				formik.setFieldValue(
																					'descricaoTipoModificacao',
																					res.descricao,
																				);
																				formik.setFieldValue(
																					'idAlteracaoTipoModificacao',
																					res.idAlteracao,
																				);
																			}}
																		/>
																	</Col>
																</Row>
															</Col>
														</Row>

														<Row gutter={[0, 10]}>
															<Col span={24} />
														</Row>

														<Row gutter={[0, 10]}>
															<Col span={11}>
																<Field
																	as={InputMask}
																	title="Validade da Autorização"
																	titleSize="lg"
																	name="dataValidadeAutorizacao"
																	mask="99/99/9999"
																	size={80}
																	required
																	error={
																		!!formik.errors.dataValidadeAutorizacao
																	}
																/>
															</Col>

															<Col span={11} offset={1}>
																<Field
																	as={InputMask}
																	title="Data da Autorização Detran"
																	titleSize="lg"
																	name="dataAutorizacaoDetran"
																	mask="99/99/9999"
																	size={77}
																	required
																	error={!!formik.errors.dataAutorizacaoDetran}
																/>
															</Col>
														</Row>

														<Row gutter={[0, 10]}>
															<Col span={11}>
																<Field
																	as={InputMask}
																	title="CPF do Responsável"
																	titleSize="lg"
																	name="cpfResponsavel"
																	mask="999.999.999-99"
																	size={80}
																	disabled
																	required
																	error={!!formik.errors.cpfResponsavel}
																/>
															</Col>

															<Col span={11} offset={1}>
																<Field
																	as={Input}
																	title="Nome do Responsável"
																	titleSize="lg"
																	name="nomeResponsavel"
																	size={77}
																	disabled
																	required
																	error={!!formik.errors.nomeResponsavel}
																/>
															</Col>
														</Row>

														<Row gutter={[0, 10]}>
															<Col span={24} />
														</Row>

														<Row>
															<Col span={24}>
																<Field
																	as={TextArea}
																	title="Observações"
																	titleSize="lg"
																	name="observacao"
																	size={87}
																	error={!!formik.errors.observacao}
																/>
															</Col>
														</Row>
													</Section>
												</Col>
											</Row>

											<Row gutter={[0, 10]}>
												<Col span={24}>
													<Section size="lg" title="Modificação" titleSize="sm">
														<Row gutter={[0, 10]}>
															<Col span={10}>
																<Field
																	as={Input}
																	title="Marca"
																	titleSize="lg"
																	name="descricaoMarca"
																	size={95}
																	required
																	disabled
																	error={!!formik.errors.descricaoMarca}
																/>
															</Col>

															<Col>
																<BuscarMarcas
																	result={res => {
																		formik.setFieldValue(
																			'longIdMarca',
																			res.longId,
																		);
																		formik.setFieldValue(
																			'descricaoMarca',
																			res.descricao,
																		);
																	}}
																/>
															</Col>

															<Col span={11}>
																<Field
																	as={Select}
																	title="Tipo"
																	titleSize="lg"
																	name="longIdTipo"
																	options={consultaTipo.data}
																	onChange={(v: number) => {
																		formik.setFieldValue('longIdTipo', v);

																		const selectedItem = consultaTipo.data.filter(
																			item => v === item.value,
																		);

																		formik.setFieldValue(
																			'descricaoTipo',
																			selectedItem[0].label,
																		);
																	}}
																	size={92}
																	required
																	error={!!formik.errors.longIdTipo}
																/>
															</Col>
														</Row>

														<Row gutter={[0, 10]}>
															<Col span={11}>
																<Field
																	as={Select}
																	title="Espécie"
																	titleSize="lg"
																	name="longIdEspecie"
																	options={consultaEspecie.data}
																	onChange={(v: number) => {
																		formik.setFieldValue('longIdEspecie', v);

																		const selectedItem = consultaEspecie.data.filter(
																			item => v === item.value,
																		);

																		formik.setFieldValue(
																			'descricaoEspecie',
																			selectedItem[0].label,
																		);
																	}}
																	size={80}
																	required
																	error={!!formik.errors.longIdEspecie}
																/>
															</Col>

															<Col span={11}>
																<Field
																	as={Select}
																	title="Carroceria"
																	titleSize="lg"
																	name="longIdCarroceria"
																	options={consultaCarroceria.data}
																	onChange={(v: number) => {
																		formik.setFieldValue('longIdCarroceria', v);

																		const selectedItem = consultaCarroceria.data.filter(
																			item => v === item.value,
																		);

																		formik.setFieldValue(
																			'descricaoCarroceria',
																			selectedItem[0].label,
																		);
																	}}
																	size={92}
																	required
																	error={!!formik.errors.longIdCarroceria}
																/>
															</Col>
														</Row>

														<Row gutter={[0, 10]}>
															<Col span={11}>
																<Field
																	as={Input}
																	title="Potência"
																	titleSize="lg"
																	name="potencia"
																	size={80}
																	required
																	error={!!formik.errors.potencia}
																/>
															</Col>

															<Col span={11}>
																<Field
																	as={Input}
																	title="Cilindrada"
																	titleSize="lg"
																	name="cilindrada"
																	size={92}
																	required
																	error={!!formik.errors.cilindrada}
																/>
															</Col>
														</Row>

														<Row gutter={[0, 10]}>
															<Col span={11}>
																<Field
																	as={InputMask}
																	title="Capacidade passageiros"
																	titleSize="lg"
																	name="capacidadePassageiro"
																	mask="9"
																	size={80}
																	error={!!formik.errors.capacidadePassageiro}
																/>
															</Col>

															<Col span={11}>
																<Field
																	as={Select}
																	title="Cor"
																	titleSize="lg"
																	name="longIdCor"
																	options={consultaCor.data}
																	onChange={(v: number) => {
																		formik.setFieldValue('longIdCor', v);

																		const selectedItem = consultaCor.data.filter(
																			item => v === item.value,
																		);

																		formik.setFieldValue(
																			'descricaoCor',
																			selectedItem[0].label,
																		);
																	}}
																	size={92}
																	required
																	error={!!formik.errors.longIdCor}
																/>
															</Col>
														</Row>

														<Row gutter={[0, 10]}>
															<Col span={11}>
																<Field
																	as={Select}
																	title="Combustível"
																	titleSize="lg"
																	name="longIdCombustivel"
																	options={consultaCombustivel.data}
																	onChange={(v: number) => {
																		formik.setFieldValue(
																			'longIdCombustivel',
																			v,
																		);

																		const selectedItem = consultaCombustivel.data.filter(
																			item => v === item.value,
																		);

																		formik.setFieldValue(
																			'descricaoCombustivel',
																			selectedItem[0].label,
																		);
																	}}
																	size={80}
																	error={!!formik.errors.longIdCombustivel}
																/>
															</Col>

															<Col span={11}>
																<Field
																	as={Input}
																	title="CMT"
																	titleSize="lg"
																	name="cmt"
																	size={92}
																	required
																	error={!!formik.errors.cmt}
																/>
															</Col>
														</Row>

														<Row>
															<Col span={11}>
																<Field
																	as={InputMask}
																	title="PBT"
																	titleSize="lg"
																	name="pbt"
																	mask="9.9"
																	size={80}
																	required
																	error={!!formik.errors.pbt}
																/>
															</Col>

															<Col span={11}>
																<Field
																	as={InputMask}
																	title="Capacidade carga"
																	titleSize="lg"
																	name="capacidadeCarga"
																	mask="9.9"
																	size={92}
																	required
																	error={!!formik.errors.capacidadeCarga}
																/>
															</Col>
														</Row>
													</Section>
												</Col>
											</Row>

											<Row gutter={[0, 20]}>
												<Col span={24} />
											</Row>

											<Row justify="center">
												<Col>
													<Button onClick={handleButtonCancelar}>
														Cancelar
													</Button>
												</Col>

												<Col offset={1}>
													<Button type="submit">Enviar</Button>
												</Col>
											</Row>
										</Form>
									)}
								</Formik>
							</Section>

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default AutorizacaoPrevia;
