import produce from 'immer';

import { Types, SaveGerarRenach } from './types';
import { ReducerAction } from '../../../../types';

// UTILS

export const INITIAL_STATE: SaveGerarRenach = {
	data: null,
	status: 0,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): SaveGerarRenach {
	return produce(state, draft => {
		switch (action.type) {
			case Types.SAVE_GERAR_RENACH_REQUEST: {
				break;
			}
			case Types.SAVE_GERAR_RENACH_SUCCESS: {
				draft.data = action.payload;
				draft.status = 200;
				break;
			}
			case Types.SAVE_GERAR_RENACH_FAILURE: {
				draft.status = 400;
				break;
			}
			case Types.SAVE_GERAR_RENACH_CLEAR: {
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
