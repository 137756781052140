import React from 'react';

import { Row, Col, Divider } from 'antd';

// COMPONENTS
import SimpleTable from 'components/Common/Table';
import Section from 'components/Common/Section';
import { BoxTable } from './styled';

// FORM
import { IDataEndereco } from '../form';

// interface IEnderecoRecebimento {
// 	bairro: string;
// 	cep: string;
// 	codigoMunicipio: string;
// 	complemento: string;
// 	descricaoMunicipio: string;
// 	logradouro: string;
// 	numero: string;
// }

interface IProps {
	dataEnderecoRecebimento: IDataEndereco[] | null;
	tipoProcedimento?: false;
	onSelect: (record: any, selected: any) => void;
}

const ListaEnderecos: React.FC<IProps> = ({
	dataEnderecoRecebimento = [],
	tipoProcedimento = false,
	onSelect,
}) => {
	const headers = [
		{
			title: 'Tipo Endereço',
			dataIndex: 'tipo',
		},
		{
			title: 'CEP',
			dataIndex: 'cep',
		},
		{
			title: 'Logradouro',
			dataIndex: 'logradouro',
		},
		{
			title: 'Número',
			dataIndex: 'numero',
		},
		{
			title: 'Complemento',
			dataIndex: 'complemento',
		},
		{
			title: 'Bairro',
			dataIndex: 'bairro',
		},
		// {
		// 	title: 'Município',
		// 	dataIndex: 'nomeMunicipio',
		// },
		// {
		// 	title: '',
		// 	dataIndex: 'type',
		// 	render: () => {
		// 		return <FaRegCheckCircle size={15} />;
		// 	},
		// },
	];

	return (
		<>
			{tipoProcedimento && (
				<>
					<Row>
						<Col span={12}>
							<h1 style={{ opacity: 0.7 }}>Endereço de Recebimento</h1>
						</Col>
					</Row>
					<Divider
						style={{ marginTop: 0, backgroundColor: '#000', opacity: 0.6 }}
					/>
				</>
			)}

			<Section title="LISTA ENDEREÇOS DE RECEBIMENTO" titleSize="sm">
				<Row>
					<Col span={24}>
						<BoxTable>
							<SimpleTable
								headers={headers}
								body={dataEnderecoRecebimento}
								pagination={false}
								rowSelection={{
									onChange: onSelect,
									type: 'radio',
								}}
							/>
						</BoxTable>
					</Col>
				</Row>
			</Section>
		</>
	);
};

export default ListaEnderecos;
