import React, { Suspense } from 'react';
import { Col, Row, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from 'store';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Input from 'components/Common/Form/Input/Input';
import { ItemComunicacoesVenda } from 'store/modules/api/detranCrv/IntencaoDeVenda/Consultar/types';
import InputMask from 'components/Common/Form/Input/InputMask';
import Select from 'components/Common/Form/Select';
import data from 'store/modules/api/detranCrv/enum';
import { consultarIntencaoVendaClear } from 'store/modules/api/detranCrv/IntencaoDeVenda/Consultar/actions';

interface DocCpfCnpj {
	tipo: string;
	numeroIdentificacao: string;
}

const ConsultarIntencaoVendaResultado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { consultarIntencaoVenda } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.intencaoVenda,
	);

	const { data: dataVenda } = consultarIntencaoVenda;

	const isArray = !!(dataVenda && dataVenda.comunicacoesVenda);

	const getDataHora = (dataHora: any) => {
		return new Date(dataHora).toLocaleDateString('pt-BR', {
			hour: '2-digit',
			minute: '2-digit',
		});
	};

	const formatCpfCnpj = (valueDoc: DocCpfCnpj) => {
		let cpfCnpj = valueDoc.numeroIdentificacao;
		if (valueDoc && valueDoc.tipo === '1') {
			cpfCnpj = cpfCnpj.substring(cpfCnpj.length - 11, cpfCnpj.length);
		}
		return cpfCnpj;
	};

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			{isArray ? (
				dataVenda!.comunicacoesVenda.map((item: ItemComunicacoesVenda) => {
					return (
						<Section
							key={`${item.intencaoVenda.dataHora}${item.intencaoVenda.codigoEstado}${item.comprador.documento}`}
							title="COMUNICAÇÃO DE VENDA"
							style={{ marginBottom: '5px' }}
						>
							<Section title="DADOS DO COMPRADOR" titleSize="sm">
								<Row gutter={[0, 0]} style={{ marginTop: '5px' }}>
									<Col span={12}>
										<InputMask
											titleSize="lg"
											readOnly
											name="numeroIdentificacao"
											mask={
												item.comprador.documento.tipo === '1'
													? '999.999.999-99'
													: '99.999.999/9999-99'
											}
											value={formatCpfCnpj(item.comprador.documento)}
											title="CPF/CNPJ"
										/>
									</Col>
									<Col span={12}>
										<Input
											titleSize="lg"
											readOnly
											value={item.comprador.uf}
											name="uf"
											title="UF"
										/>
									</Col>
								</Row>
							</Section>

							<Section title="DADOS DA INTENÇÃO DE VENDA" titleSize="sm">
								<Row gutter={[0, 0]} style={{ marginTop: '5px' }}>
									<Col span={12}>
										<Input
											titleSize="lg"
											readOnly
											name="UF"
											value={item.intencaoVenda.uf}
											title="UF"
										/>
									</Col>
									<Col span={12}>
										<Select
											titleSize="lg"
											size={80}
											defaultFirstOption
											disabled
											value={item.intencaoVenda.codigoEstado}
											name="codigoEstadoIntencaoVenda"
											title="Estado Intenção Venda"
											options={data.consultaIntencaoVenda}
											error={false}
											onChange={() => {}}
										/>
									</Col>
								</Row>
								<Row gutter={[0, 0]} style={{ marginTop: '5px' }}>
									<Col span={12}>
										<Input
											titleSize="lg"
											readOnly
											name="dataHora"
											value={new Date(
												item.intencaoVenda.dataHora,
											).toLocaleDateString('pt-BR', {
												hour: '2-digit',
												minute: '2-digit',
											})}
											title="Data/Hora"
										/>
									</Col>
									<Col span={12}>
										<Input
											titleSize="lg"
											readOnly
											name="dataHoraAtualizacao"
											value={new Date(
												item.intencaoVenda.dataHoraAtualizacao,
											).toLocaleDateString('pt-BR', {
												hour: '2-digit',
												minute: '2-digit',
											})}
											title="Data/Hora Atualização"
										/>
									</Col>
								</Row>
							</Section>
						</Section>
					);
				})
			) : (
				<>
					<Section title="DADOS DO VEÍCULO" titleSize="sm">
						<Row gutter={[0, 0]} style={{ marginTop: '5px' }}>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									value={dataVenda?.numeroATPVE}
									name="numeroATPVE"
									title="Número ATPV-e"
								/>
							</Col>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									value={dataVenda?.veiculo?.placa}
									name="placa"
									title="Placa"
								/>
							</Col>
						</Row>
						<Row gutter={[0, 0]} style={{ marginTop: '5px' }}>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									value={dataVenda?.veiculo?.renavam}
									name="renavam"
									title="Renavam"
								/>
							</Col>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									value={dataVenda?.veiculo?.chassi}
									name="chassi"
									title="Chassi"
								/>
							</Col>
						</Row>
						<Row gutter={[0, 0]} style={{ marginTop: '5px' }}>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									value={dataVenda?.veiculo?.hodometro}
									name="hodometro"
									title="Hodômetro"
								/>
							</Col>
						</Row>
					</Section>

					<Section title="DADOS DO COMPRADOR" titleSize="sm">
						<Row gutter={[0, 0]} style={{ marginTop: '5px' }}>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									value={dataVenda?.comprador?.documento.numeroIdentificacao}
									name="numeroIdentificacao"
									title="CPF/CNPJ"
								/>
							</Col>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									value={dataVenda?.comprador?.nome}
									name="nome"
									title="Nome"
								/>
							</Col>
						</Row>
						<Row gutter={[0, 0]} style={{ marginTop: '5px' }}>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									value={dataVenda?.comprador?.email}
									name="email"
									title="Email"
								/>
							</Col>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									value={dataVenda?.comprador?.codigoMunicipio}
									name="municipio"
									title="Município"
								/>
							</Col>
						</Row>
						<Row gutter={[0, 0]} style={{ marginTop: '5px' }}>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									value={dataVenda?.comprador?.uf}
									name="uf"
									title="UF"
								/>
							</Col>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									value={dataVenda?.comprador?.valorVenda}
									name="valorVenda"
									title="Valor Venda"
								/>
							</Col>
						</Row>
					</Section>

					<Section title="DADOS DA INTENÇÃO DE VENDA" titleSize="sm">
						<Row gutter={[0, 0]} style={{ marginTop: '5px' }}>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									value={dataVenda?.intencaoVenda?.uf}
									name="uf"
									title="UF"
								/>
							</Col>
							<Col span={12}>
								<Select
									titleSize="lg"
									size={80}
									defaultFirstOption
									disabled
									value={dataVenda?.intencaoVenda?.codigoEstado}
									name="codigoEstadoIntencaoVenda"
									title="Estado Intenção Venda"
									options={data.consultaIntencaoVenda}
									error={false}
									onChange={() => {}}
								/>
							</Col>
						</Row>
						<Row gutter={[0, 0]} style={{ marginTop: '5px' }}>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									name="dataHora"
									value={getDataHora(dataVenda?.intencaoVenda?.dataHora)}
									title="Data/Hora"
								/>
							</Col>
							<Col span={12}>
								<Input
									titleSize="lg"
									readOnly
									name="dataHoraAtualizacao"
									value={getDataHora(
										dataVenda?.intencaoVenda?.dataHoraAtualizacao,
									)}
									title="Data/Hora Atualização"
								/>
							</Col>
						</Row>
					</Section>
				</>
			)}
			<Row justify="space-between" align="bottom" gutter={[20, 10]}>
				<Col>
					<ButtonVoltar
						onClick={() => dispatch(consultarIntencaoVendaClear())}
						route="/detran-crv/consultar-intencao-venda/consulta"
					/>
				</Col>
				<Col>
					<ButtonImage
						type="button"
						src="encerrar"
						onClick={() => {
							dispatch(consultarIntencaoVendaClear());
							history.push('/detran-crv');
						}}
					/>
				</Col>
			</Row>
		</Suspense>
	);
};

export default ConsultarIntencaoVendaResultado;
