import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IDependentesDareState, IDependenteDare } from './types';

export const INITIAL_STATE: IDependentesDareState = {
	cpfPrincipal: '',
	data: null,
	editando: {
		index: -1,
		data: null,
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IDependentesDareState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.DEPENDENTES_DARE_CPF_PRINCIPAL: {
				draft.cpfPrincipal = action.payload;
				break;
			}
			case Types.DEPENDENTES_DARE_ADICIONAR: {
				draft.data = action.payload;
				break;
			}
			case Types.DEPENDENTES_DARE_EDITANDO: {
				if (draft.data) {
					const {
						index,
						dependente,
					}: { index: number; dependente: IDependenteDare } = action.payload;

					draft.data.splice(index, 1);

					draft.editando.index = index;
					draft.editando.data = dependente;
				}
				break;
			}
			case Types.DEPENDENTES_DARE_EDITANDO_CLEAR: {
				draft.editando = INITIAL_STATE.editando;
				break;
			}
			case Types.DEPENDENTES_DARE_EXCLUIR: {
				if (draft.data) {
					draft.data.splice(action.payload, 1);
				}
				break;
			}
			case Types.DEPENDENTES_DARE_CLEAR: {
				draft.cpfPrincipal = INITIAL_STATE.cpfPrincipal;
				draft.data = INITIAL_STATE.data;
				draft.editando = INITIAL_STATE.editando;
				break;
			}

			default:
		}
		return draft;
	});
}
