import { Types } from './types';

export function consultaCorRequest() {
	return {
		type: Types.CONSULTA_COR_REQUEST,
	};
}
export function consultaCorSuccess(payload: any) {
	return {
		type: Types.CONSULTA_COR_SUCCESS,
		payload,
	};
}
export function consultaCorFailure(payload: []): any {
	return {
		type: Types.CONSULTA_COR_FAILURE,
		payload,
	};
}
