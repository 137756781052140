import { isCpfValid } from 'utils/yupValidations';
import * as Yup from 'yup';

export interface IFormDARE {
	cpf: string;
	dare: string;
	numeroRg: string;
}

export const initialValues: IFormDARE = {
	cpf: '',
	dare: '',
	numeroRg: '',
};

export const schema = Yup.object<IFormDARE>().shape({
	cpfDareNumeroRg: Yup.string()
		.when(['cpf', 'dare'], {
			is: (cpf, dare) => cpf === '' && dare === '',
			then: Yup.string().required('Campo obrigatório: CPF ou Nª da DARE'),
		})
		.when(['cpf', 'dare'], {
			is: (cpf, dare) => cpf !== '' && dare !== '',
			then: Yup.string().required(
				`Preencha apenas um dos campos abaixo! CPF ou Nº DARE`,
			),
		})
		.when(['cpf', 'dare'], {
			is: (cpf, dare) => cpf !== '' && cpf.length !== 11 && dare === '',
			then: Yup.string().required(
				'Campo CPF: Tamanho mínimo de 11 caracteres.',
			),
		})
		.when(['cpf', 'dare'], {
			is: (cpf, dare) => cpf === '' && dare !== '' && dare.length !== 15,
			then: Yup.string().required(
				'Campo Nº DARE: Tamanho mínimo de 15 caracteres.',
			),
		}),
	cpf: Yup.string().test({
		name: 'isCpfValid',
		exclusive: true,
		message: 'Campo CPF da DARE: CPF inválido.',
		test: value => {
			if (!value) return true;

			return isCpfValid(value);
		},
	}),
});
