import { IConfrontarDigitaisCnhState } from 'store/modules/api/utils/confrontarDigitaisCnh/types';
import {
	ConsultarDigitaisState,
	ConsultarDigitaisUserErrorMessages,
	ConsultarDigitaisUserReasonMessages,
} from 'store/modules/api/utils/consultarDigitais/types';

import { STATUS_BIOMETRICS } from 'utils/constants';
import { MESSAGES_BIOMETRICS_PROCESS_CNH } from 'pages/DetranCnh/utils/constants/messages';
import { usoFormularioEnumTypes } from 'utils/constants/types';
import { showComponent } from 'utils/genericFunctions';
import { removeAccents } from 'utils/stringFunctions';
import { CapturarBiometriaState } from 'store/modules/api/biometria/capturarBiometria/types';

export interface BiometricsProcessMessages {
	messageAlert: string;
	processMessages: string[];
}

export const getBiometricsStatus = (
	consultarDigitais: ConsultarDigitaisState,
	typeProccess: string,
): string => {
	const errorMessageUserBiometry: ConsultarDigitaisUserErrorMessages =
		'pessoa-desconhecida';

	const userMessageReasonBiometry: ConsultarDigitaisUserReasonMessages =
		'fora-da-temporalidade';

	// Digitais encontradas e tipo do processo igual a segunda via (segunda-via ignora temporalidade e valida).
	if (
		showComponent([usoFormularioEnumTypes.SEGUNDA_VIA], typeProccess) &&
		consultarDigitais?.data &&
		consultarDigitais?.status === 200 &&
		consultarDigitais?.data?.dadosBiometricos &&
		consultarDigitais?.data?.dadosBiometricos.length > 0
	) {
		return STATUS_BIOMETRICS.BIOMETRICS_OK;
	}

	// Digitais não encontradas e tipo do processo igual a segunda via (segunda-via - pessoa desconhecida).
	if (
		showComponent([usoFormularioEnumTypes.SEGUNDA_VIA], typeProccess) &&
		consultarDigitais?.status === 404 &&
		!consultarDigitais?.data?.dadosBiometricos
	) {
		return STATUS_BIOMETRICS.BIOMETRICS_NOK;
	}

	// Digitais encontradas, dentro da temporalidade, valida true e tipo do processo diferente de segunda via.
	if (
		!showComponent([usoFormularioEnumTypes.SEGUNDA_VIA], typeProccess) &&
		consultarDigitais?.data &&
		consultarDigitais?.status === 200 &&
		consultarDigitais?.data?.valida &&
		consultarDigitais?.data?.dadosBiometricos &&
		consultarDigitais?.data?.dadosBiometricos.length > 0 &&
		!(
			consultarDigitais?.mensagens?.length &&
			consultarDigitais.mensagens.filter(
				msg =>
					msg &&
					removeAccents(msg.toString().trim()).toUpperCase() ===
						removeAccents(errorMessageUserBiometry).toUpperCase(),
			)
		) &&
		!(
			consultarDigitais.data.motivo &&
			removeAccents(
				consultarDigitais.data.motivo.toString().trim(),
			).toUpperCase() === removeAccents(userMessageReasonBiometry).toUpperCase()
		)
	) {
		return STATUS_BIOMETRICS.BIOMETRICS_OK;
	}

	// Digitais encontradas, valida false, fora de temporalidade e tipo do processo diferente de segunda via.
	if (
		!showComponent([usoFormularioEnumTypes.SEGUNDA_VIA], typeProccess) &&
		consultarDigitais?.status === 200 &&
		consultarDigitais?.data &&
		!consultarDigitais.data.valida &&
		consultarDigitais.data.motivo &&
		removeAccents(
			consultarDigitais.data.motivo.toString().trim(),
		).toUpperCase() === removeAccents(userMessageReasonBiometry).toUpperCase()
	) {
		return STATUS_BIOMETRICS.FORA_TEMPORALIDADE;
	}

	// Digitais não encontradas, pessoa desconhecida e tipo do processo diferente de segunda via.
	if (
		!showComponent([usoFormularioEnumTypes.SEGUNDA_VIA], typeProccess) &&
		consultarDigitais?.status === 404 &&
		consultarDigitais?.mensagens?.length &&
		consultarDigitais.mensagens.filter(
			msg =>
				msg &&
				removeAccents(msg.toString().trim()).toUpperCase() ===
					removeAccents(errorMessageUserBiometry).toUpperCase(),
		)
	) {
		return STATUS_BIOMETRICS.BIOMETRICS_NOK;
	}

	return STATUS_BIOMETRICS.BIOMETRICS_NOK;
};

export const getBiometricsProcessMessages = (
	typeProccess: string,
	consultarDigitais: ConsultarDigitaisState,
	confrontoDigitais: IConfrontarDigitaisCnhState,
	biometricReadService: CapturarBiometriaState,
): BiometricsProcessMessages => {
	const { MSG01, MSG02, MSG03, MSG04, MSG05, MSG06, MSG07 } =
		MESSAGES_BIOMETRICS_PROCESS_CNH;

	// Não foi possível realizar a leitura das digitais no leitor biométrico (3 tentativas).
	if (biometricReadService.qtdeFalha === 3) {
		return {
			processMessages: [MSG01, MSG07],
			messageAlert: MSG02,
		};
	}

	// Digitais encontradas, dentro da temporalidade e tipo do processo diferente de segunda via
	if (
		!showComponent([usoFormularioEnumTypes.SEGUNDA_VIA], typeProccess) &&
		confrontoDigitais.resultado &&
		getBiometricsStatus(consultarDigitais, typeProccess) ===
			STATUS_BIOMETRICS.BIOMETRICS_OK
	) {
		return {
			processMessages: [MSG04, MSG05, MSG06],
			messageAlert: MSG04,
		};
	}

	// Digitais encontradas, desconsidera temporalidade e tipo do processo igual segunda via.
	if (
		showComponent([usoFormularioEnumTypes.SEGUNDA_VIA], typeProccess) &&
		confrontoDigitais.resultado &&
		getBiometricsStatus(consultarDigitais, typeProccess) ===
			STATUS_BIOMETRICS.BIOMETRICS_OK
	) {
		return { processMessages: [MSG04, MSG05, MSG06], messageAlert: MSG04 };
	}

	// Digitais não encontradas - pessoa-desconhecida (segunda via).
	if (
		showComponent([usoFormularioEnumTypes.SEGUNDA_VIA], typeProccess) &&
		!confrontoDigitais.resultado &&
		getBiometricsStatus(consultarDigitais, typeProccess) ===
			STATUS_BIOMETRICS.BIOMETRICS_NOK
	) {
		return { processMessages: [MSG01, MSG07], messageAlert: MSG02 };
	}

	// Digitais encontradas mas com insucesso no confronto biométrico após 3 tentativas (segunda via ignora o valida).
	if (
		((!showComponent([usoFormularioEnumTypes.SEGUNDA_VIA], typeProccess) &&
			consultarDigitais.data?.valida) ||
			showComponent([usoFormularioEnumTypes.SEGUNDA_VIA], typeProccess)) &&
		!confrontoDigitais.resultado &&
		confrontoDigitais.qtdeFalha === 3
	) {
		return { processMessages: [MSG01, MSG07], messageAlert: MSG02 };
	}

	// Digitais encontradas, fora da temporalidade e tipo do processo diferente de segunda via.
	if (
		!showComponent([usoFormularioEnumTypes.SEGUNDA_VIA], typeProccess) &&
		getBiometricsStatus(consultarDigitais, typeProccess) ===
			STATUS_BIOMETRICS.FORA_TEMPORALIDADE
	) {
		return { processMessages: [MSG01, MSG07], messageAlert: MSG03 };
	}

	// Digitais não encontradas e tipo do processo diferente de segunda via.
	if (
		!showComponent([usoFormularioEnumTypes.SEGUNDA_VIA], typeProccess) &&
		!confrontoDigitais.resultado &&
		getBiometricsStatus(consultarDigitais, typeProccess) ===
			STATUS_BIOMETRICS.BIOMETRICS_NOK
	) {
		return { messageAlert: MSG02, processMessages: [MSG01, MSG07] };
	}

	return {
		messageAlert: '',
		processMessages: [],
	};
};

export const getBiometricReaderDescription = (
	capturarBiometria: CapturarBiometriaState,
): string => {
	const timeoutError =
		capturarBiometria.dataError?.message?.includes('TIMEOUT') || false;

	if (capturarBiometria.status === 200 && capturarBiometria.data?.length > 0) {
		return 'Leitura da digital realizada com sucesso.';
	}

	if (capturarBiometria.status !== 200 && !timeoutError) {
		return 'Ocorreu um erro com a leitora de digitais.';
	}

	if (capturarBiometria.status !== 200 && timeoutError) {
		return 'Não foi possível realizar a leitura da digital, ocorreu erro por timeout.';
	}

	return '';
};
