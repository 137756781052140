import React, { Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { consultarVeiculoBaseEstadualClear } from 'store/modules/api/detranCrv/ConsultarVeiculos/consultarVeiculoBaseEstadual/actions';
import { completaZeroEsquerda } from 'utils/genericFunctions';

const ConsultaVeiculoBaseEstadualResultado: React.FC = () => {
	const dispatch = useDispatch();

	const { dadosVeiculo } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.consultarVeiculos.consultarVeiculoBaseEstadual.data,
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Row>
				<Col span={24}>
					<Section size="sm" title="DADOS DO VEÍCULO">
						<Row>
							<Col span={3}>Placa</Col>
							<Col span={7}>
								<strong>{dadosVeiculo.placa}</strong>
							</Col>
							<Col span={3}>Município</Col>
							<Col span={7}>
								<strong>{`${dadosVeiculo.municipio.codigo} - ${dadosVeiculo.municipio.nome}`}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={3}>Renavam</Col>
							<Col span={7}>
								<strong>{dadosVeiculo.renavam}</strong>
							</Col>
							<Col span={3}>Chassi</Col>
							<Col span={9}>
								<strong>{dadosVeiculo.chassi}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={3}>Nº Motor</Col>
							<Col span={7}>
								<strong>{dadosVeiculo.numeroMotor}</strong>
							</Col>
							<Col span={3}>Data de Alteração Motor</Col>
							<Col span={7}>
								<strong>
									{dadosVeiculo?.dataAlteracaoMotor &&
										new Date(
											dadosVeiculo?.dataAlteracaoMotor,
										).toLocaleDateString('pt-BR')}
								</strong>
							</Col>
						</Row>
						<Row>
							<Col span={3}>Tipo</Col>
							<Col span={7}>
								<strong>
									{dadosVeiculo.tipo.longId} - {dadosVeiculo.tipo.descricao}
								</strong>
							</Col>
							<Col span={3}>Procedência</Col>
							<Col span={3}>
								<strong>{dadosVeiculo.procedencia}</strong>
							</Col>
							<Col span={3}>Combustível</Col>
							<Col span={4}>
								<strong>
									{dadosVeiculo.combustivel.longId} -
									{dadosVeiculo.combustivel.descricao}
								</strong>
							</Col>
						</Row>
						<Row>
							<Col span={3}>Cor</Col>
							<Col span={7}>
								<strong>
									{dadosVeiculo.cor.longId} - {dadosVeiculo.cor.descricao}
								</strong>
							</Col>
							<Col span={3}>Marca</Col>
							<Col span={7}>
								<strong>
									{dadosVeiculo.marca.longId} - {dadosVeiculo.marca.descricao}
								</strong>
							</Col>
						</Row>
						<Row>
							<Col span={3}>Categoria</Col>
							<Col span={7}>
								<strong>
									{dadosVeiculo.categoria.longId} -{' '}
									{dadosVeiculo.categoria.descricao}
								</strong>
							</Col>
							<Col span={3}>Ano Fabr.</Col>
							<Col span={3}>
								<strong>{dadosVeiculo.anoFabricacao}</strong>
							</Col>
							<Col span={3}>Ano Modelo</Col>
							<Col span={4}>
								<strong>{dadosVeiculo.anoModelo}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={3}>Espécie</Col>
							<Col span={7}>
								<strong>
									{dadosVeiculo.especie.longId} -{' '}
									{dadosVeiculo.especie.descricao}
								</strong>
							</Col>
							<Col span={3}>Cap Pass.</Col>
							<Col span={3}>
								<strong>{dadosVeiculo.capacidadePassageiro}</strong>
							</Col>
							<Col span={3}>Cap. Carga</Col>
							<Col span={4}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 1,
									}).format(dadosVeiculo.capacidadeCarga)}
								</strong>
							</Col>
						</Row>
						<Row>
							<Col span={3}>Carroceria</Col>
							<Col span={7}>
								<strong>
									{dadosVeiculo.carroceria.longId} -{' '}
									{dadosVeiculo.carroceria.descricao}
								</strong>
							</Col>
							<Col span={3}>Cilindrada</Col>
							<Col span={3}>
								<strong>{dadosVeiculo.cilindrada} CC</strong>
							</Col>
							<Col span={3}>Potência</Col>
							<Col span={4}>
								<strong>{dadosVeiculo.potencia} CV </strong>
							</Col>
						</Row>
						<Row>
							<Col span={3}>Eixos</Col>
							<Col span={7}>
								<strong>{dadosVeiculo.eixo}</strong>
							</Col>
							<Col span={3}>CMT</Col>
							<Col span={3}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 1,
									}).format(dadosVeiculo.cmt)}
								</strong>
							</Col>
							<Col span={3}>PBT</Col>
							<Col span={4}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 1,
									}).format(dadosVeiculo.pbt)}
								</strong>
							</Col>
						</Row>
						<Row>
							<Col span={3}>Última Atualização Despachante</Col>
							<Col span={7}>
								<strong>{dadosVeiculo.ultimaAtualizacaoDespachante}</strong>
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="DADOS DO PROPRIETÁRIO">
						<Row>
							<Col span={5}>Nome do Proprietário</Col>
							<Col span={17}>
								<strong>{dadosVeiculo.proprietario.nomeProprietario}</strong>
							</Col>
							<Col span={5}>Tipo de Documento Proprietário</Col>
							<Col span={17}>
								<strong>{dadosVeiculo.proprietario.tipoDocumento}</strong>
							</Col>
							<Col span={5}>Nº Documento Proprietário</Col>
							<Col span={17}>
								{dadosVeiculo.proprietario.tipoDocumento === 'CPF' ? (
									<InputMask
										name="baseEstadualCpfCnpjProprietario"
										value={
											dadosVeiculo.proprietario.cpfCnpjProprietario
												? completaZeroEsquerda(
														dadosVeiculo.proprietario.cpfCnpjProprietario,
														11,
												  )
												: ''
										}
										mask="999.999.999-99"
										readOnly
										style={{ paddingBottom: '6px', fontWeight: 700 }}
									/>
								) : (
									<InputMask
										name="baseEstadualCpfCnpjProprietario"
										value={
											dadosVeiculo.proprietario.cpfCnpjProprietario
												? completaZeroEsquerda(
														dadosVeiculo.proprietario.cpfCnpjProprietario,
														14,
												  )
												: ''
										}
										mask="99.999.999/9999-99"
										readOnly
										style={{ paddingBottom: '6px', fontWeight: 700 }}
									/>
								)}
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="GRAVAMES">
						<Row>
							<Col span={6}>Restrição Financeira</Col>
							<Col span={6}>
								<strong>{dadosVeiculo.gravame.restricaoFinanceira}</strong>
							</Col>
							<Col span={6}>Nº Contrato</Col>
							<Col span={6}>
								<strong>{dadosVeiculo.gravame.numeroContrato}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={6}>Nome Agente</Col>
							<Col span={6}>
								<strong>{dadosVeiculo.gravame.nomeAgente}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={6}>Arrendatário / Financiado</Col>
							<Col span={6}>
								<strong>{dadosVeiculo.gravame.nomeAgente}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={6}>CNPJ/CPF Financ</Col>
							<Col span={6}>
								<strong>{dadosVeiculo.gravame.cpfCnpjFinanciado}</strong>
							</Col>
							<Col span={6}>Código da Financeira</Col>
							<Col span={6}>
								<strong>{dadosVeiculo.gravame.codigoFinanceira}</strong>
							</Col>
						</Row>
						<Section size="sm" title="DATA">
							<Row>
								<Col span={6}>Inclusão Financiamento</Col>
								<Col span={6}>
									<strong>
										{dadosVeiculo.gravame.dataInclusao &&
											new Date(
												dadosVeiculo.gravame.dataInclusao,
											).toLocaleDateString('pt-BR')}
									</strong>
								</Col>
								<Col span={6}> Vigência Contrato</Col>
								<Col span={6}>
									<strong>
										{dadosVeiculo.gravame.dataVigencia &&
											new Date(
												dadosVeiculo.gravame.dataVigencia,
											).toLocaleDateString('pt-BR')}
									</strong>
								</Col>
							</Row>
						</Section>
					</Section>
					<Section size="sm" title="INTENÇÃO DO GRAVAME">
						<Row>
							<Col span={6}>Restr. Financeira</Col>
							<Col span={6}>
								<strong>{dadosVeiculo.intencaoGravame.restricao}</strong>
							</Col>
							{/* <Col span={6}>Tipo Transação</Col>
							<Col span={6}>
								<strong>{dadosVeiculo.intencaoGravame.tipoTransacao}</strong>
							</Col> */}
						</Row>
						<Row>
							<Col span={6}>Agente Financeiro</Col>
							<Col span={6}>
								<strong>{dadosVeiculo.intencaoGravame.agenteFinanceiro}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={6}>Nome do Financiado</Col>
							<Col span={6}>
								<strong>{dadosVeiculo.intencaoGravame.nomeFinanciado}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={6}>CNPJ/CPF Financ</Col>
							<Col span={6}>
								<strong>
									<InputMask
										name="intencaoGravame"
										value={
											dadosVeiculo.intencaoGravame.cpfCnpjFinanciado
												? completaZeroEsquerda(
														dadosVeiculo.intencaoGravame.cpfCnpjFinanciado,
														14,
												  )
												: ''
										}
										mask="99.999.999/9999-99"
										readOnly
										style={{ paddingBottom: '6px', fontWeight: 700 }}
									/>
								</strong>
							</Col>
							{/* <Col span={6}> Data Inclusão</Col>
							<Col span={6}>
								<strong>
									{dadosVeiculo.intencaoGravame.dataInclusao &&
										new Date(
											dadosVeiculo.intencaoGravame.dataInclusao,
										).toLocaleDateString('pt-BR')}
								</strong>
							</Col> */}
						</Row>
						{/* <Row>
							<Col span={6}>Nº Contrato</Col>
							<Col span={6}>
								<strong>{dadosVeiculo.intencaoGravame.numeroContrato}</strong>
							</Col>
							<Col span={6}> Data Vigência</Col>
							<Col span={6}>
								<strong>
									{dadosVeiculo.intencaoGravame.dataVigencia &&
										new Date(
											dadosVeiculo.intencaoGravame.dataVigencia,
										).toLocaleDateString('pt-BR')}
								</strong>
							</Col>
						</Row> */}
					</Section>
					<Section size="sm" title="DÉBITOS / MULTAS">
						<Row>
							<Col span={3}>DERSA</Col>
							<Col span={5}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 2,
									}).format(dadosVeiculo.multas.dersa)}
								</strong>
							</Col>
							<Col span={3}>DER</Col>
							<Col span={5}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 2,
									}).format(dadosVeiculo.multas.der)}
								</strong>
							</Col>
							<Col span={3}>DETRAN</Col>
							<Col span={5}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 2,
									}).format(dadosVeiculo.multas.detran)}
								</strong>
							</Col>
						</Row>
						<Row>
							<Col span={3}>CETESB</Col>
							<Col span={5}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 2,
									}).format(dadosVeiculo.multas.cetesb)}
								</strong>
							</Col>
							<Col span={3}>Renainf</Col>
							<Col span={5}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 2,
									}).format(dadosVeiculo.multas.renainf)}
								</strong>
							</Col>
							<Col span={3}>Municipais</Col>
							<Col span={5}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 2,
									}).format(dadosVeiculo.multas.municipais)}
								</strong>
							</Col>
						</Row>
						<Row>
							<Col span={3}>Polícia Rodoviária Federal</Col>
							<Col span={5}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 2,
									}).format(dadosVeiculo.multas.policiaRodoviariaFederal)}
								</strong>
							</Col>
							<Col span={3}>IPVA</Col>
							<Col span={5}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 2,
									}).format(dadosVeiculo.multas.debitoIpva)}
								</strong>
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="RESTRIÇÕES">
						<Row>
							<Col span={6}>Restrições Furto</Col>
							<Col span={12}>
								<strong>{dadosVeiculo.restricoes.furto}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={6}>Bloqueio de Guincho</Col>
							<Col span={12}>
								<strong>{dadosVeiculo.restricoes.bloqueioGuincho}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={6}>Restrições Administrativas</Col>
							<Col span={12}>
								<strong>
									{dadosVeiculo.restricoes.restricaoAdministrativa}
								</strong>
							</Col>
						</Row>
						<Row>
							<Col span={6}>Restrições Judicial</Col>
							<Col span={12}>
								<strong>{dadosVeiculo.restricoes.restricaoJudicial}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={6}>Restrições Tributária</Col>
							<Col span={12}>
								<strong>{dadosVeiculo.restricoes.restricaoTributaria}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={6}>Bloqueios RENAJUD</Col>
							<Col span={12}>
								<strong>{dadosVeiculo.restricoes.bloqueioRenajud}</strong>
							</Col>
						</Row>
						<Row>
							<Col span={6}>Inspeção Ambiental</Col>
							<Col span={12}>
								<strong>{dadosVeiculo.restricoes.inspecaoAmbiental}</strong>
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="CRV / CRVL / ATUALIZAÇÃO">
						<Row>
							<Col span={6}>Exerc. Licenciamento</Col>
							<Col span={6}>
								<strong>{dadosVeiculo.crvCrvl.exercicioLicenciamento}</strong>
							</Col>
							<Col span={6}>Licenciamento</Col>
							<Col span={6}>
								<strong>
									{dadosVeiculo.crvCrvl.dataLicenciamento &&
										new Date(
											dadosVeiculo.crvCrvl.dataLicenciamento,
										).toLocaleDateString('pt-BR')}
								</strong>
							</Col>
						</Row>
						<Row>
							<Col span={6}>Última Atualização feita pelo Despachante</Col>
							<Col span={6}>
								<strong>
									{' '}
									{dadosVeiculo.crvCrvl.dataEmissaoCrv &&
										new Date(
											dadosVeiculo.crvCrvl.dataEmissaoCrv,
										).toLocaleDateString('pt-BR')}
								</strong>
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="COMUNICAÇÃO DE VENDAS">
						<Row>
							<Col span={6}>Comunicação de Vendas</Col>
							<Col span={8}>
								<strong>
									{dadosVeiculo.comunicacaoVenda.comunicacaoVenda}
								</strong>
							</Col>
							<Col span={6}>Inclusão</Col>
							<Col span={4}>
								<strong>
									{dadosVeiculo.comunicacaoVenda.dataInclusaoComunicacao &&
										new Date(
											dadosVeiculo.comunicacaoVenda.dataInclusaoComunicacao,
										).toLocaleDateString('pt-BR')}
								</strong>
							</Col>
						</Row>{' '}
						<Row>
							<Col span={6}>Tipo Docto Comprador</Col>
							<Col span={8}>
								<strong>{dadosVeiculo.comunicacaoVenda.identificacao}</strong>
							</Col>
							<Col span={6}>CNPJ / CPF do Comprador</Col>
							<Col span={4}>
								<strong>
									{dadosVeiculo.comunicacaoVenda.cpfCnpjComprador}
								</strong>
							</Col>
						</Row>
						<Section size="sm" title="DATAS">
							<Row>
								<Col span={3}>Venda</Col>
								<Col span={5}>
									<strong>
										{dadosVeiculo.comunicacaoVenda.dataVenda &&
											new Date(
												dadosVeiculo.comunicacaoVenda.dataVenda,
											).toLocaleDateString('pt-BR')}
									</strong>
								</Col>
								<Col span={3}>Nota Fiscal</Col>
								<Col span={4}>
									<strong>
										{dadosVeiculo.comunicacaoVenda.dataNotaFiscal &&
											new Date(
												dadosVeiculo.comunicacaoVenda.dataNotaFiscal,
											).toLocaleDateString('pt-BR')}
									</strong>
								</Col>
								<Col span={4}>Protocolo Detran</Col>
								<Col span={5}>
									<strong>
										{dadosVeiculo.comunicacaoVenda.protocoloDetran &&
											new Date(
												dadosVeiculo.comunicacaoVenda.protocoloDetran,
											).toLocaleDateString('pt-BR')}
									</strong>
								</Col>
							</Row>
						</Section>
					</Section>
					<Row
						className="teste"
						style={{ marginTop: '20px' }}
						justify="center"
						align="bottom"
					>
						<Col span={12}>
							<ButtonVoltar
								onClick={() => dispatch(consultarVeiculoBaseEstadualClear())}
							/>
						</Col>
						<Col span={12}>
							<ButtonImage src="imprimir" onClick={() => window.print()} />
						</Col>
					</Row>
				</Col>
			</Row>
		</Suspense>
	);
};

export default ConsultaVeiculoBaseEstadualResultado;
