export enum Types {
	ATUALIZAR_MUNICIPIO_IBGE_REQUEST = '@SGU/ATUALIZAR_MUNICIPIO_IBGE_REQUEST',
	ATUALIZAR_MUNICIPIO_IBGE_SUCCESS = '@SGU/ATUALIZAR_MUNICIPIO_IBGE_SUCCESS',
	ATUALIZAR_MUNICIPIO_IBGE_FAILURE = '@SGU/ATUALIZAR_MUNICIPIO_IBGE_FAILURE',
	ATUALIZAR_MUNICIPIO_IBGE_CLEAR = '@SGU/ATUALIZAR_MUNICIPIO_IBGE_CLEAR',
}

export interface AtualizarMunicipioIbge {
	status: number;
	data: null | MunicipioIbgeData;
}

interface MunicipioIbgeData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarMunicipioIbgeRequest {
	codigoNaturalidade?: string | number;
	codigoIbge: string | number;
	nome: string;
	siglaEstado: string;
}
