import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../../types';
import { Types, UsuarioState } from './types';

import consultaUsuarios from './consultarUsuarios/reducer';
import consultarUsuario from './consultarUsuario/reducer';
import cadastrarUsuario from './cadastrarUsuario/reducer';
import cadastrarUsuarioMainframe from './cadastrarUsuarioMainframe/reducer';
import atualizarUsuario from './atualizarUsuario/reducer';
import excluirLocalidadesRelacionadas from './excluirLocalidadesRelacionadas/reducer';
import excluirUsuario from './excluirUsuario/reducer';
import trocarSenhaUsuario from './trocarSenhaUsuario/reducer';
import excluirUsuarioMainframe from './excluirUsuarioMainframe/reducer';
import resetarSenhaUsuarioMainframe from './resetarSenhaUsuarioMainframe/reducer';

const allReducers = combineReducers({
	consultaUsuarios,
	consultarUsuario,
	cadastrarUsuario,
	cadastrarUsuarioMainframe,
	atualizarUsuario,
	excluirLocalidadesRelacionadas,
	excluirUsuario,
	trocarSenhaUsuario,
	excluirUsuarioMainframe,
	resetarSenhaUsuarioMainframe,
});

const combineReducer = (state: UsuarioState, action: ReducerAction) => {
	if (action.type === Types.SGU_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
