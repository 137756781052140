import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_UNIDADE_TRANSITO_REQUEST = '@DETRAN-CRV/CONSULTAR_UNIDADE_TRANSITO_REQUEST',
	CONSULTAR_UNIDADE_TRANSITO_SUCCESS = '@DETRAN-CRV/CONSULTAR_UNIDADE_TRANSITO_SUCCESS',
	CONSULTAR_UNIDADE_TRANSITO_FAILURE = '@DETRAN-CRV/CONSULTAR_UNIDADE_TRANSITO_FAILURE',
	CONSULTAR_UNIDADE_TRANSITO_CLEAR = '@DETRAN-CRV/CONSULTAR_UNIDADE_TRANSITO_CLEAR',
}

export interface consultarUnidadesTransito {
	status: number;
	dataUnidades: OptionProps[];
}

export interface ConsultarUnidadesTransitoRequest {
	codigo_municipio: number;
	codigo_unidade_transito: number;
}
