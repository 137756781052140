import React from 'react';

import { Row, Col } from 'antd';
import Input from 'components/Common/Form/Input/Input';
import CheckBox from 'components/Common/Form/Checkbox';
import { Container } from './styles';

interface IBO {
	exigBO: string;
	exigBODelegacia: string;
	exigBONumero: string;
}

interface IData {
	data: IBO;
}

const BO: React.FC<IData> = ({ data }) => {
	return (
		<Container>
			<Row gutter={[15, 5]}>
				<Col span={24}>
					<CheckBox
						name="bo"
						checked={!!data?.exigBO?.trim()}
						onChange={() => {}}
						disabled
						subtitle="BO"
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input
						name="exigBODelegacia"
						value={data?.exigBODelegacia}
						disabled
					/>
				</Col>
				<Col span={18}>
					<Input name="exigBONumero" value={data?.exigBONumero} disabled />
				</Col>
			</Row>
		</Container>
	);
};

export default BO;
