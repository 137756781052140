import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarFuncionalidadeRequest } from './types';

export function atualizarFuncionalidadeRequest(
	data: AtualizarFuncionalidadeRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_FUNCIONALIDADE,
		payload: data,
	};
}
export function atualizarFuncionalidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_FUNCIONALIDADE_SUCCESS,
		payload,
	};
}
export function atualizarFuncionalidadeFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_FUNCIONALIDADE_FAILURE,
		payload: null,
	};
}
export function atualizarFuncionalidadeClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_FUNCIONALIDADE_CLEAR,
		payload: null,
	};
}
