import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage, { SourceButtonImage } from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
// import ColorBox from 'components/Common/ColorBox';
import PDFViewer from 'components/Common/PDFViewer';

// REDUX
import { ApplicationState } from 'store';
import {
	imprimirRenachClear,
	imprimirRenachRequest,
} from 'store/modules/api/detranCnh/imprimir/renach/actions';
import { anamneseRequest } from 'store/modules/api/detranCnh/relatorio/anamnese/actions';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import {
	relatorioAgendamentoMedicoPsicologicoClear,
	relatorioAgendamentoMedicoPsicologicoRequest,
} from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';

import { eventoTerminoSessaoRequest } from 'store/modules/api/detranCnh/evento/terminoSessao/actions';

// UTILS
import { threatValuesEndSessionEventRequest } from 'utils/functions/threatEventsRequest';
import {
	BiometricsProcessMessages,
	getBiometricsProcessMessages,
} from 'pages/DetranCnh/utils/functions/biometrics';
import Alert from 'components/Common/Notifications/Alert';
import {
	estatisticaIncluirClear,
	estatisticaIncluirRequest,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { treatRequestSguStatisticsBiometricRead } from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';
import { hasUpdateInAttendencyModal } from 'pages/DetranCnh/utils/validations';
import { BoxRenach, MessageRed } from './styles';

interface TypeBtnImprimirRenach {
	typeBtn: SourceButtonImage;
	typeFlagExameMedico: boolean;
	typeFlagExamePsico: boolean;
	isVisible: boolean;
}

const RenachIntegradoGerado: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [pdfFile, setPdfFile] = useState('');
	const [pdfFile3, setPdfFile3] = useState('');
	const [typeButtonImprimirExame, setTypeButtonImprimirExame] =
		useState<TypeBtnImprimirRenach>({
			typeBtn: 'btn-imprimir-agendamento-medico-psicologico',
			typeFlagExameMedico: false,
			typeFlagExamePsico: false,
			isVisible: false,
		});
	const [messagesBiometrics, setMessagesBiometrics] =
		useState<BiometricsProcessMessages>({
			messageAlert: '',
			processMessages: [],
		});

	const { requestData, data: responseRenachIntegrado } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.renachIntegrado.gerarRenachIntegrado,
	);

	const userLogin = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { imprimirRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.imprimir,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { relatorio } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.agendamentoMedicoPsicologico,
	);

	const { anamnese } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.relatorio,
	);

	const { estatisticasIncluir } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const { eventoTerminoSessao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.evento,
	);

	const { consultarDigitais, confrontarDigitaisCnh } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { dadosPreCadastroFormSave } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.utils,
	);

	const { capturarBiometria } = useSelector(
		(state: ApplicationState) => state.api.biometria,
	);

	const { saveGerarRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const { detailsStartSection } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.evento.eventoInicioSessao,
	);

	const { login } = loginUnico;

	useEffect(() => {
		if (requestData) {
			if (
				requestData.dadosExameMedico.idMedico === '' &&
				requestData.dadosExamePsicologo.idPsicologo === ''
			) {
				setTypeButtonImprimirExame({
					typeBtn: 'btn-imprimir-agendamento-medico-psicologico',
					typeFlagExameMedico: false,
					typeFlagExamePsico: false,
					isVisible: false,
				});
			} else if (
				requestData.dadosExameMedico.idMedico !== '' &&
				requestData.dadosExamePsicologo.idPsicologo !== ''
			) {
				setTypeButtonImprimirExame({
					typeBtn: 'btn-imprimir-agendamento-medico-psicologico',
					typeFlagExameMedico: true,
					typeFlagExamePsico: true,
					isVisible: true,
				});
			} else if (
				requestData.dadosExameMedico.idMedico === '' &&
				requestData.dadosExamePsicologo.idPsicologo !== ''
			) {
				setTypeButtonImprimirExame({
					typeBtn: 'btn-imprimir-agendamento-psicologico',
					typeFlagExameMedico: false,
					typeFlagExamePsico: true,
					isVisible: true,
				});
			} else if (
				requestData.dadosExameMedico.idMedico !== '' &&
				requestData.dadosExamePsicologo.idPsicologo === ''
			) {
				setTypeButtonImprimirExame({
					typeBtn: 'btn-imprimir-agendamento-medico-psicologico',
					typeFlagExameMedico: true,
					typeFlagExamePsico: false,
					isVisible: true,
				});
			}
		}
	}, [requestData]);

	useEffect(() => {
		if (imprimirRenach.status === 200 && imprimirRenach.data) {
			window.open(
				imprimirRenach.data.url,
				'_blank',
				'location=yes,height=auto,width=auto,scrollbars=yes,status=false',
			);
		}
	}, [imprimirRenach]);

	useEffect(() => {
		if (relatorio.status === 200 && relatorio.data?.arquivoPDF) {
			setPdfFile(relatorio.data.arquivoPDF);
		}
	}, [relatorio]);

	useEffect(() => {
		if (anamnese.status === 200 && anamnese.data) {
			setPdfFile3(anamnese.data);
		}
	}, [anamnese]);

	useEffect(() => {
		const valuesEndSessionEvent = threatValuesEndSessionEventRequest(
			atendimento,
			loginUnico,
			saveGerarRenach.data?.gerarRenachBase?.usoFormularioEnum || '',
			detailsStartSection?.dataInicio || '',
		);

		if (eventoTerminoSessao.status === 0) {
			dispatch(eventoTerminoSessaoRequest(valuesEndSessionEvent));
		}
	}, [
		atendimento,
		detailsStartSection,
		dispatch,
		eventoTerminoSessao.status,
		loginUnico,
		saveGerarRenach.data,
	]);

	useEffect(() => {
		if (
			(requestData &&
				consultarDigitais.status !== 0 &&
				consultarDigitais.status !== 100) ||
			(requestData &&
				dadosPreCadastroFormSave.dataPreCadastro?.dadosModalAtendimento &&
				hasUpdateInAttendencyModal(
					dadosPreCadastroFormSave.dataPreCadastro.dadosModalAtendimento,
				))
		) {
			const messages = getBiometricsProcessMessages(
				requestData.gerarRenachBase?.usoFormularioEnum || '',
				consultarDigitais,
				confrontarDigitaisCnh,
				capturarBiometria,
			);

			if (messages.processMessages.length || messages.messageAlert !== '') {
				setMessagesBiometrics(messages);
			}
		}
	}, [
		confrontarDigitaisCnh,
		consultarDigitais,
		dadosPreCadastroFormSave,
		requestData,
		capturarBiometria,
	]);

	useEffect(() => {
		dispatch(relatorioAgendamentoMedicoPsicologicoClear());
		dispatch(estatisticaIncluirClear());
		dispatch(imprimirRenachClear());

		return () => {
			dispatch(relatorioAgendamentoMedicoPsicologicoClear());
			dispatch(imprimirRenachClear());
		};
	}, [dispatch]);

	// REGISTRA ESTATISTICA SGU APÓS GERAR O RENACH - CONFRONTO.
	useEffect(() => {
		if (estatisticasIncluir.status === 0 && requestData) {
			if (
				dadosPreCadastroFormSave.dataPreCadastro?.dadosModalAtendimento ||
				dadosPreCadastroFormSave.dataPreCadastro?.flagProcurador === true ||
				dadosPreCadastroFormSave.dataPreCadastro?.flagProcurador === 'S'
			) {
				const payloadBiometricRead = treatRequestSguStatisticsBiometricRead(
					userLogin.user,
					dadosAtendimento,
					requestData,
					confrontarDigitaisCnh,
					consultarDigitais,
					capturarBiometria,
					dadosPreCadastroFormSave.dataPreCadastro?.dadosModalAtendimento,
					dadosPreCadastroFormSave.dataPreCadastro?.flagProcurador,
				);
				dispatch(estatisticaIncluirRequest(payloadBiometricRead));
			}
		}
	}, [
		capturarBiometria,
		confrontarDigitaisCnh,
		consultarDigitais,
		dadosAtendimento,
		dadosPreCadastroFormSave,
		dispatch,
		estatisticasIncluir.status,
		requestData,
		userLogin.user,
	]);

	return (
		<>
			<Section title="Status do Condutor">
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h3 style={{ textTransform: 'uppercase' }}>
							{responseRenachIntegrado?.nomeSocialCidadao ||
								responseRenachIntegrado?.nomeCidadao ||
								''}
						</h3>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						{messagesBiometrics.messageAlert !== '' && (
							<Alert
								key={messagesBiometrics.messageAlert}
								type="info"
								message={messagesBiometrics.messageAlert}
								onClose={() =>
									setMessagesBiometrics(state => ({
										...state,
										messageAlert: '',
									}))
								}
							/>
						)}
					</Col>
				</Row>

				<Row gutter={[0, 10]} align="middle" justify="center">
					<Col span={15}>
						<BoxRenach>
							<h2>Informações salvas com sucesso</h2>
							<Row>
								<Col span={24}>
									<p>
										O número do RENACH é:{' '}
										<strong>{responseRenachIntegrado?.renach || ''}</strong>
									</p>
								</Col>
								{messagesBiometrics.processMessages.length > 0 ? (
									messagesBiometrics.processMessages.map(item => {
										if (item.includes('Encaminhar o cidadão')) {
											return (
												<Col key={item} span={24}>
													<MessageRed>{item}</MessageRed>
												</Col>
											);
										}
										return (
											<Col key={item} span={24}>
												<p>{item}</p>
											</Col>
										);
									})
								) : (
									<>
										<Col span={24}>
											<MessageRed>
												Encaminhar o cidadão para a estação biométrica para
												completar seu cadastro.
											</MessageRed>
										</Col>
										<Col span={24}>
											<p>
												A continuidade deste processo só poderá ser realizada
												neste Posto de Atendimento.
											</p>
										</Col>
									</>
								)}
							</Row>

							<Row align="middle" justify="center">
								<Col>
									<ButtonImage
										type="button"
										src="btn-imprimir-renach"
										onClick={() =>
											dispatch(
												imprimirRenachRequest({
													cpfCidadao: requestData?.candidato.cpf || '',
													cpfUsuarioLogado: login.user.cpf || '',
												}),
											)
										}
									/>
								</Col>
							</Row>

							{requestData && requestData.candidato.tipoProcesso === '2' && (
								<Row align="middle" justify="center">
									<Col>
										<ButtonImage
											type="button"
											src="btn-imprimir-anamnese"
											onClick={() =>
												dispatch(
													anamneseRequest({
														cpf: requestData?.candidato.cpf || '',
														nome:
															responseRenachIntegrado?.nomeSocialCidadao ||
															responseRenachIntegrado?.nomeCidadao ||
															'',
														renach: responseRenachIntegrado?.renach || '',
														codLocalidade: requestData
															? Number(login.user.posto)
															: 0,
														nomeLocalidade: userLogin.user.nomePosto,
														tipoProcesso: 'RENOVAÇÃO',
													}),
												)
											}
										/>
									</Col>
								</Row>
							)}
							{requestData && typeButtonImprimirExame.isVisible && (
								<Row align="middle" justify="center">
									<Col>
										<ButtonImage
											type="button"
											src={typeButtonImprimirExame.typeBtn}
											onClick={() => {
												if (responseRenachIntegrado) {
													dispatch(
														relatorioAgendamentoMedicoPsicologicoRequest({
															categoriaPretendida:
																requestData.candidato.categoriaPretendida,
															cpfCidadao: parseInt(
																responseRenachIntegrado.cpfCidadao,
																10,
															),
															crm: responseRenachIntegrado.crm,
															crp: responseRenachIntegrado.crp,
															dataExame:
																(responseRenachIntegrado.dataExame &&
																	new Date(
																		`${responseRenachIntegrado.dataExame} ${responseRenachIntegrado.horarioExame}`,
																	).toISOString()) ||
																'',
															dataExamePsicologo:
																(responseRenachIntegrado.dataExamePsicologo &&
																	new Date(
																		`${responseRenachIntegrado.dataExamePsicologo} ${responseRenachIntegrado.horarioExamePsicologo}`,
																	).toISOString()) ||
																'',
															enderecoMedico:
																responseRenachIntegrado.enderecoMedico,
															enderecoPsicologo:
																responseRenachIntegrado.enderecoPsicologo,
															horarioExame:
																(responseRenachIntegrado.dataExame &&
																	new Date(
																		`${responseRenachIntegrado.dataExame} ${responseRenachIntegrado.horarioExame}`,
																	).toISOString()) ||
																'',
															horarioExamePsicologo:
																(responseRenachIntegrado.dataExamePsicologo &&
																	new Date(
																		`${responseRenachIntegrado.dataExamePsicologo} ${responseRenachIntegrado.horarioExamePsicologo}`,
																	).toISOString()) ||
																'',
															nomeCidadao:
																responseRenachIntegrado.nomeSocialCidadao ||
																responseRenachIntegrado.nomeCidadao,
															nomeMedico: responseRenachIntegrado.nomeMedico,
															nomePsicologo:
																responseRenachIntegrado.nomePsicologo,
															telefones: responseRenachIntegrado.telefones,
															telefonesPsicologo:
																responseRenachIntegrado.telefonesPsicologo,
															tipoProcesso:
																responseRenachIntegrado.tipoProcesso?.descricao?.toLocaleUpperCase() ||
																'',
															flagHabilitaAgendamentoMedico:
																typeButtonImprimirExame.typeFlagExameMedico,
															flagHabilitaAgendamentoPsicologico:
																typeButtonImprimirExame.typeFlagExamePsico,
														}),
													);
												}
											}}
										/>
									</Col>
								</Row>
							)}

							<Row align="middle" justify="center">
								<Col>
									<ButtonImage
										type="button"
										src="finalizar-processo"
										onClick={() => {
											dispatch(detranCnhClear());
											history.push('/detran-cnh');
										}}
									/>
								</Col>
							</Row>
						</BoxRenach>
					</Col>
				</Row>

				{pdfFile && (
					<PDFViewer
						title="Impressão Segunda Via CNH"
						source={pdfFile}
						renderMode="popup"
						onClose={() => setPdfFile('')}
					/>
				)}

				{pdfFile3 && (
					<PDFViewer
						title="Impressão Anamnese"
						source={pdfFile3}
						renderMode="popup"
						onClose={() => setPdfFile3('')}
					/>
				)}
			</Section>

			<Row justify="space-between" align="middle">
				<Col>
					<ButtonVoltar
						navigate={false}
						onClick={() => {
							dispatch(detranCnhClear());
							history.push('/detran-cnh');
						}}
					/>
				</Col>
				<Col>
					<ButtonImage
						type="button"
						src="outro-servico-detran"
						onClick={() => {
							dispatch(detranCnhClear());
							history.push('/detran-cnh');
						}}
					/>
				</Col>
			</Row>
		</>
	);
};

export default RenachIntegradoGerado;
