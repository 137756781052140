import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';

// TYPES
import { ReducerAction } from 'store/modules/types';

import { CHANNEL, PATH_SGU_SERVICE } from 'services/_path';
import { DomainServiceNaturalidadesRequest, Types } from './types';

// ACTIONS
import {
	domainServiceNaturalidadesSuccess,
	domainServiceNaturalidadesFailure,
} from './actions';

function* domainServiceNaturalidadesRequest(request: ReducerAction) {
	const { payload }: { payload: DomainServiceNaturalidadesRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU_SERVICE,
		`domain-service/${CHANNEL}/naturalidade`,
		payload,
	);

	if (response.status === 200) {
		yield put(domainServiceNaturalidadesSuccess(response));
	} else {
		yield put(domainServiceNaturalidadesFailure(response));
	}
}

export default all([
	takeLatest(
		Types.DOMAIN_SERVICE_NATURALIDADES_REQUEST,
		domainServiceNaturalidadesRequest,
	),
]);
