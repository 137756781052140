import React from 'react';

import { Row, Col, Divider } from 'antd';

// COMPONENTS
import Section from 'components/Common/Section';

// UTILS
import { formatDate } from 'utils/genericFunctions';

// FORM
import { IDadosCondutor } from './form';

interface IProps {
	dadosCondutor: IDadosCondutor;
}

const DadosCondutor: React.FC<IProps> = ({ dadosCondutor }) => {
	return (
		<>
			<Section bgColor="cinza-claro">
				<Row align="middle">
					<Col>
						<h1 style={{ opacity: 0.7 }}>Dados do Cidadão:&nbsp;</h1>
					</Col>
					<Col>
						<h3 style={{ opacity: 0.7, fontWeight: 'normal' }}>
							{dadosCondutor.nome}
						</h3>
					</Col>
				</Row>
				<Divider
					style={{ marginTop: 0, backgroundColor: '#000', opacity: 0.7 }}
				/>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<p>
							<strong>Nome do condutor: </strong>
							{dadosCondutor.nome}
						</p>
					</Col>

					<Col span={12}>
						<p>
							<strong>CPF: </strong>
							{dadosCondutor.cpf}
						</p>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<p>
							<strong>Nº do registro da CNH: </strong>
							{dadosCondutor.registro}
						</p>
					</Col>

					<Col span={12}>
						<p>
							<strong>Data Validade da CNH: </strong>
							{formatDate(dadosCondutor.dataValidadeCnh)}
						</p>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<p>
							<strong>Categoria CNH: </strong>
							{dadosCondutor.categoria}
						</p>
					</Col>
				</Row>
			</Section>
		</>
	);
};

export default DadosCondutor;
