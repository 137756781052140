import * as Yup from 'yup';

import { IRequestConsultaRenainfOrgao } from 'store/modules/api/detranCrv/MultasRenainf/multasRenainfOrgao/types';
import { SalvarAtendimentoData } from 'store/modules/api/sgu/atendimento/salvar/types';

export const initialValues: IRequestConsultaRenainfOrgao = {
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
	},
	codigoOrgaoAutuador: '',
	numeroInfracao: '',
	codigoInfracao: '',
};

export function treatValues(
	evento: SalvarAtendimentoData | null,
	values: IRequestConsultaRenainfOrgao,
): IRequestConsultaRenainfOrgao {
	const formattedData = {
		evento: {
			id_atendimento: evento?.idAtendimento ? evento.idAtendimento : '',
			id_cidadao: evento?.idCidadao ? evento.idCidadao : '',
			cpf: evento?.cpf ? evento.cpf : '',
		},
		codigoOrgaoAutuador: values.codigoOrgaoAutuador,
		numeroInfracao: values.numeroInfracao,
		codigoInfracao: values.codigoInfracao,
	};

	return formattedData;
}

export const schema = Yup.object<IRequestConsultaRenainfOrgao>().shape({
	codigoOrgaoAutuador: Yup.string().required(
		`Campo Obrigatório: Código do Órgão Autuador.`,
	),
	numeroInfracao: Yup.string().required(
		`Campo Obrigatório: Número do Auto de Infração.`,
	),
	codigoInfracao: Yup.string().required(
		`Campo Obrigatório: Código da Infração.`,
	),
});
