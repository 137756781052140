import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	consultaFormasPagamentoSuccess,
	consultaFormasPagamentoFailure,
} from './actions';

function* consultaFormasPagamentoRequest() {
	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`dominios-crm/${CHANNEL}/formas-pagamento`,
		{},
	);

	if (response.status === 200) {
		yield put(consultaFormasPagamentoSuccess(response));
	} else {
		yield put(consultaFormasPagamentoFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_FORMAS_PAGAMENTO_REQUEST,
		consultaFormasPagamentoRequest,
	),
]);
