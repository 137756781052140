// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import {
	GerarBoletoAgrupadoData,
	GerarBoletoAgrupadoRequest,
	Types,
} from './types';

export function gerarBoletoAgrupadoRequest(
	token: string,
	payload: GerarBoletoAgrupadoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.GERAR_BOLETO_AGRUPADO_REQUEST,
		token,
		payload,
	};
}
export function gerarBoletoAgrupadoSuccess(
	payload: GerarBoletoAgrupadoData,
): ReducerActionRotaSP {
	return {
		type: Types.GERAR_BOLETO_AGRUPADO_SUCCESS,
		payload,
	};
}
export function gerarBoletoAgrupadoFailure(): ReducerActionRotaSP {
	return {
		type: Types.GERAR_BOLETO_AGRUPADO_FAILURE,
		payload: null,
	};
}
export function gerarBoletoAgrupadoClear(): ReducerActionRotaSP {
	return {
		type: Types.GERAR_BOLETO_AGRUPADO_CLEAR,
		payload: null,
	};
}
