import { ReducerAction } from 'store/modules/types';
import { Types, IDadosConsulta } from './types';

export function mantemDadosConsultaRequest(
	payload: IDadosConsulta,
): ReducerAction {
	return {
		type: Types.MANTEM_DADOS_CONSULTA_REQUEST,
		payload,
	};
}

export function mantemDadosConsultaSuccess(payload: IDadosConsulta) {
	return {
		type: Types.MANTEM_DADOS_CONSULTA_SUCCESS,
		payload,
	};
}

export function mantemDadosConsultaFailure() {
	return {
		type: Types.MANTEM_DADOS_CONSULTA_FAILURE,
		payload: null,
	};
}
