import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ApiResponse, postApi } from 'services/_api';
import { PATH_EDUCACAO } from 'services/_path';
import { Types } from './types';
import {
	pesquisarAlunoSuccess,
	pesquisarAlunoFailure,
	pesquisarAlunoNoContent,
} from './actions';

function* pesquisarAlunoRequest({ payload }: any) {
	const response: ApiResponse = yield call(
		postApi,
		PATH_EDUCACAO,
		`aluno/consultar`,
		payload,
	);
	if (response.status === 200) {
		yield put(pesquisarAlunoSuccess(response));
	} else if (response.status === 204) {
		const data = {
			mensage: 'ALUNO NÃO ENCONTRADO',
			status: 204,
		};
		yield put(pesquisarAlunoNoContent(data));
	} else if (response.error) {
		yield put(pesquisarAlunoFailure(response.message));
	}
}

export default all([
	takeLatest(Types.PESQUISAR_ALUNO_REQUEST, pesquisarAlunoRequest),
]);
