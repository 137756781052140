import produce from 'immer';

import { ReducerAction } from 'store/modules/types';
import { treatObjNullValues } from 'utils/genericFunctions';

import {
	Types,
	IConsultaPesquisaCidadaoState,
	ConsultaPesquisaCidadao,
} from './types';

export const INITIAL_STATE: IConsultaPesquisaCidadaoState = {
	status: 0,
	data: [],
	form: null,
	selecionado: null,
	ocorrencias: {
		cirg: 0,
		cino: 0,
		cinn: 0,
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IConsultaPesquisaCidadaoState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PESQUISA_CIDADAO_REQUEST: {
				draft.form = action.payload.form;
				draft.status = 100;
				break;
			}
			case Types.CONSULTA_PESQUISA_CIDADAO_FORM_PESQUISA_MAE_REQUEST: {
				draft.form = action.payload.form;
				break;
			}
			case Types.CONSULTA_PESQUISA_CIDADAO_SUCCESS: {
				const newValues: ConsultaPesquisaCidadao[] = [];
				if (action.payload.pessoa) {
					action.payload.pessoa.map((item: ConsultaPesquisaCidadao) => {
						const newItem: ConsultaPesquisaCidadao = treatObjNullValues(
							item,
						) as ConsultaPesquisaCidadao;
						return newValues.push(newItem);
					});
				}

				draft.data = newValues;
				draft.ocorrencias = {
					cirg: action.payload.contadorCirg,
					cino: action.payload.contadorCino,
					cinn: action.payload.contadorCinn,
				};
				draft.status = 200;
				break;
			}
			case Types.CONSULTA_PESQUISA_CIDADAO_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				draft.ocorrencias = INITIAL_STATE.ocorrencias;
				break;
			}

			case Types.CONSULTA_PESQUISA_CIDADAO_SELECIONADO: {
				draft.selecionado = action.payload;
				break;
			}
			case Types.CONSULTA_PESQUISA_CIDADAO_SELECIONADO_CLEAR: {
				draft.selecionado = INITIAL_STATE.selecionado;
				break;
			}

			case Types.CONSULTA_PESQUISA_CIDADAO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.form = INITIAL_STATE.form;
				draft.selecionado = INITIAL_STATE.selecionado;
				draft.ocorrencias = INITIAL_STATE.ocorrencias;
				break;
			}

			default:
		}
		return draft;
	});
}
