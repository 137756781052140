import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ExcluirMensagemErroRequest } from './types';

// ACTIONS
import {
	excluirMensagemErroSuccess,
	excluirMensagemErroFailure,
} from './actions';

function* excluirMensagemErro(request: ReducerAction) {
	const { payload }: { payload: ExcluirMensagemErroRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_SGU,
		`mensagens-erro/${payload.id}`,
	);

	if (response.status === 200) {
		yield put(excluirMensagemErroSuccess(response));
	} else {
		yield put(excluirMensagemErroFailure());
	}
}

export default all([
	takeLatest(Types.EXCLUIR_MENSAGEM_ERRO_REQUEST, excluirMensagemErro),
]);
