import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../types';
import { Types, EducacaoUpdateState } from './types';

// FICHA ALUNO
import fichaAluno from './fichaAluno/combineReducers';

// INSCRICAO
import inscricao from './inscricao/combineReducers';

// COMBOS
import combos from './combos/combineReducers';

import responsavel from './responsavel/combineReducers';

const allReducers = combineReducers({
	fichaAluno,
	responsavel,
	inscricao,
	combos,
});

const combineReducer = (state: EducacaoUpdateState, action: ReducerAction) => {
	if (action.type === Types.EDUCACAO_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
