// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function consultaEventosEspeciaisRequest(): ReducerAction {
	return {
		type: Types.CONSULTA_EVENTOS_ESPECIAIS_REQUEST,
		payload: null,
	};
}
export function consultaEventosEspeciaisSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_EVENTOS_ESPECIAIS_SUCCESS,
		payload,
	};
}
export function consultaEventosEspeciaisFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_EVENTOS_ESPECIAIS_FAILURE,
		payload,
	};
}
export function consultaEventosEspeciaisClear(): ReducerAction {
	return {
		type: Types.CONSULTA_EVENTOS_ESPECIAIS_CLEAR,
		payload: null,
	};
}
