import { ReducerAction } from 'store/modules/types';
import { Types, ExcluirContatoRequest } from './types';

export function excluirContatoRequest(
	payload: ExcluirContatoRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_CONTRATO_REQUEST,
		payload,
	};
}
export function excluirContatoSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_CONTRATO_SUCCESS,
		payload,
	};
}
export function excluirContatoFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_CONTRATO_FAILURE,
		payload: null,
	};
}

export function limparExcluirContato(): any {
	return {
		type: Types.LIMPAR_EXCLUIR_CONTRATO,
	};
}
