import { combineReducers } from 'redux';

import cadastrarResponsavel from './cadastroResponsavel/reducer';
import listarResponsaveis from './consultaResponsaveis/reducer';
import removerResponsavel from './removerResponsavel/reducer';

export default combineReducers({
	cadastrarResponsavel,
	listarResponsaveis,
	removerResponsavel,
});
