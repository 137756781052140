import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConsultaPesquisaCriminalState } from './types';

export const INITIAL_STATE: IConsultaPesquisaCriminalState = {
	status: 0,
	data: [],
	restricaoCriminal: false,
	temporalidade: false,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IConsultaPesquisaCriminalState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PESQUISA_CRIMINAL_RESTRICAO: {
				draft.restricaoCriminal = action.payload;
				break;
			}

			case Types.CONSULTA_PESQUISA_CRIMINAL_TEMPORALIDADE: {
				draft.temporalidade = action.payload;
				break;
			}

			case Types.CONSULTA_PESQUISA_CRIMINAL_REQUEST: {
				break;
			}
			case Types.CONSULTA_PESQUISA_CRIMINAL_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}
			case Types.CONSULTA_PESQUISA_CRIMINAL_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.restricaoCriminal = INITIAL_STATE.restricaoCriminal;
				draft.temporalidade = INITIAL_STATE.temporalidade;
				break;
			}

			case Types.CONSULTA_PESQUISA_CRIMINAL_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.restricaoCriminal = INITIAL_STATE.restricaoCriminal;
				draft.temporalidade = INITIAL_STATE.temporalidade;
				break;
			}

			default:
		}

		return draft;
	});
}
