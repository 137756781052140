import { ReducerAction } from 'store/modules/types';
import { Types, SaveTaxaServicoRequest } from './types';

export function saveTaxaServicoRequest(
	data: SaveTaxaServicoRequest,
): ReducerAction {
	return {
		type: Types.SAVE_TAXA_SERVICO,
		payload: data,
	};
}

export function saveTaxaServicoSuccess(payload: object): ReducerAction {
	return {
		type: Types.SAVE_TAXA_SERVICO_SUCCESS,
		payload,
	};
}

export function saveTaxaServicoFailure(payload: object): ReducerAction {
	return {
		type: Types.SAVE_TAXA_SERVICO_FAILURE,
		payload,
	};
}

export function saveTaxaServicoClear(): ReducerAction {
	return {
		type: Types.SAVE_TAXA_SERVICO_CLEAR,
		payload: null,
	};
}
