import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, patchApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ResetarSenhaUsuarioMainframeRequest } from './types';

// ACTIONS
import {
	resetarSenhaUsuarioMainframeSuccess,
	resetarSenhaUsuarioMainframeFailure,
} from './actions';

function* resetarSenhaUsuarioMainframe(request: ReducerAction) {
	const { payload }: { payload: ResetarSenhaUsuarioMainframeRequest } = request;
	const { id, idLocalidade, usuario } = payload;

	const response: ApiResponse = yield call(
		patchApi,
		PATH_SGU,
		`/usuarios/${id}/localidades/${idLocalidade}/usuario-mainframe/${usuario}`,
		{},
	);

	if (response.status === 200) {
		yield put(resetarSenhaUsuarioMainframeSuccess(response));
	} else {
		yield put(resetarSenhaUsuarioMainframeFailure());
	}
}

export default all([
	takeLatest(
		Types.RESETAR_SENHA_USUARIO_MAINFRAME_REQUEST,
		resetarSenhaUsuarioMainframe,
	),
]);
