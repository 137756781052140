/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';

import { Row, Col } from 'antd';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import icon_alerta from 'assets/images/icons/icon_alerta.gif';

import { clearNotifications } from 'store/modules/app/notifications/actions';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import { relatorioAgendamentoMedicoPsicologicoClear } from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';
import { ROUTE_DETRAN_CNH_RENOVACAO_PESQUISA } from '../../../routes/paths';

import { BoxRenach } from './styles';

// import ColorBox from 'components/Common/ColorBox';
// import { DadosContatoRequest } from './form';

interface IProps {
	rengeVencimento: number;
	nomeCidadao: string;
}

const RedirecionamentoRenovacaoCNHVencida: React.FC<IProps> = ({
	rengeVencimento,
	nomeCidadao = '',
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [mensageText, setMensageText] = useState('');

	useEffect(() => {
		if (rengeVencimento === 1) {
			setMensageText(
				'IMPOSSIBILIDADE DE EMISSÃO DE 2VIA DE CNH. CONTATAR O DENATRAN– Portaria Contran 208/2021',
			);
		} else if (rengeVencimento === 2) {
			setMensageText('A CNH pesquisada está vencida.');
		}
	}, [rengeVencimento]);

	return (
		<Section title="Status do Condutor">
			<h3 style={{ textTransform: 'uppercase' }}>{nomeCidadao}</h3>
			<BoxRenach>
				<Row gutter={[0, 20]}>
					<Col span={1}>
						<img src={icon_alerta} alt="icone de alerta" />
					</Col>
					<Col span={3}>
						<h2 style={{ color: '#C33' }}>Atenção</h2>
					</Col>
				</Row>
				{mensageText && (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<h3 style={{ color: '#000', fontWeight: 'normal' }}>
								{mensageText}
							</h3>
						</Col>
					</Row>
				)}

				<Row align="middle" justify="center">
					<Col>
						<ButtonImage
							type="button"
							src="btn-prosseguir-renovacao"
							onClick={() => {
								dispatch(clearNotifications());
								dispatch(detranCnhClear());
								dispatch(relatorioAgendamentoMedicoPsicologicoClear());
								history.push(ROUTE_DETRAN_CNH_RENOVACAO_PESQUISA);
							}}
						/>
					</Col>
				</Row>
			</BoxRenach>
		</Section>
	);
};

export default RedirecionamentoRenovacaoCNHVencida;
