import styled, { css } from 'styled-components';

interface IProps {
	colorBackground: string;
	leftOrRight: 'left' | 'right';
}

export const Mensagem = styled.div<IProps>`
	padding: 10px;
	width: 60%;
	border-radius: 6px;

	${props => css`
		float: ${props.leftOrRight};
		background: ${props.colorBackground};

		${props.leftOrRight === 'right' &&
		css`
			&:before {
				right: -8px;
				top: 36px;
			}
		`};

		${props.leftOrRight === 'left' &&
		css`
			&:before {
				left: -8px;
				top: 36px;
			}
		`};

		&:before {
			content: '';
			position: absolute;
			width: 16px;
			height: 16px;
			background: ${props.colorBackground};
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
		}

		.mensagemTitulo {
			color: rgb(47, 47, 47);
			font-size: calc(0.8rem + 0%);
			text-transform: uppercase;
			font-weight: bold;
		}

		.mensagemPeriodo {
			color: rgb(47, 47, 47);
			font-size: calc(0.8rem + 0%);
			font-weight: normal;
		}

		.mensagemDescricao {
			overflow-wrap: 'anywhere' !important;
			color: '#161616';
			font-weight: normal;
		}
	`};
`;
