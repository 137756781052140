// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { SimularEftuarAcordoRequest, Types } from './types';

export function simularEfetuarAcordoRequest(
	token: string,
	payload: SimularEftuarAcordoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.SIMULAR_EFETUAR_ACORDO_REQUEST,
		token,
		payload,
	};
}
export function simularEfetuarAcordoSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.SIMULAR_EFETUAR_ACORDO_SUCCESS,
		payload,
	};
}
export function simularEfetuarAcordoFailure(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.SIMULAR_EFETUAR_ACORDO_FAILURE,
		payload,
	};
}
export function simularEfetuarAcordoClear(): ReducerActionRotaSP {
	return {
		type: Types.SIMULAR_EFETUAR_ACORDO_CLEAR,
		payload: null,
	};
}
