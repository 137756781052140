import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_sgu';
import { authToken } from 'services/_token';

// TYPES
import { addNotifications } from 'store/modules/app/notifications/actions';
// import { getToken } from 'services/token';
import { extenderTempoParaInatividade } from 'store/modules/api/sgu/loginUnico/actions';
import { ReducerAction } from '../../../types';
import { ILoginRequest, Types } from './types';

// ACTIONS
import { logInSuccess, logInFailure, logInChangePassword } from './actions';

function* logInRequest(request: ReducerAction) {
	const { payload }: { payload: ILoginRequest } = request;
	yield call(authToken);

	yield call(extenderTempoParaInatividade);

	const response: ApiResponse = yield call(
		getApi,
		`usuario/login-unico/${payload.cpf}`,
	);

	if (response.status === 200) {
		yield put(logInSuccess(response.data));
	} else if (
		response.error &&
		response.message[0] === 'Senha do usuário necessita ser alterada.'
	) {
		yield put(
			logInChangePassword({
				status: response.error,
				message: response.message[0],
			}),
		);
	} else if (response.error) {
		addNotifications({
			errors: response.message,
		});
		yield put(logInFailure(response.message));
	}
}

export default all([takeLatest(Types.LOG_IN_REQUEST, logInRequest)]);
