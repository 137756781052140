// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function tiposContratacaoRequest() {
	return {
		type: Types.TIPOS_CONTRATACAO_REQUEST,
	};
}
export function tiposContratacaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.TIPOS_CONTRATACAO_SUCCESS,
		payload,
	};
}
export function tiposContratacaoFailure(payload: object): ReducerAction {
	return {
		type: Types.TIPOS_CONTRATACAO_FAILURE,
		payload,
	};
}
export function tiposContratacaoClear(): ReducerAction {
	return {
		type: Types.TIPOS_CONTRATACAO_CLEAR,
		payload: null,
	};
}
