import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarProcessoState } from './types';

export const INITIAL_STATE: ConsultarProcessoState = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarProcessoState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_PROCESSO_REQUEST: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CONSULTAR_PROCESSO_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}
			case Types.CONSULTAR_PROCESSO_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CONSULTAR_PROCESSO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
