import { ReducerAction } from 'store/modules/types';

import { Types, IRequestHorariosPsicologoPorData } from './types';

export function horariosPsicologoPorDataRequest(
	data: IRequestHorariosPsicologoPorData,
): ReducerAction {
	return {
		type: Types.HORARIOS_PSICOLOGO_POR_DATA_REQUEST,
		payload: data,
	};
}
export function horariosPsicologoPorDataSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.HORARIOS_PSICOLOGO_POR_DATA_SUCCESS,
		payload,
	};
}
export function horariosPsicologoPorDataFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.HORARIOS_PSICOLOGO_POR_DATA_FAILURE,
		payload,
	};
}
export function horariosPsicologoPorDataClear(): ReducerAction {
	return {
		type: Types.HORARIOS_PSICOLOGO_POR_DATA_CLEAR,
		payload: null,
	};
}
