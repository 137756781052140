import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, RegistraSolicitacoes } from './types';

export const INITIAL_STATE: RegistraSolicitacoes = {
	status: 0,
	responseData: null,
	requestData: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): RegistraSolicitacoes {
	return produce(state, draft => {
		switch (action.type) {
			case Types.REGISTRA_SOLICITACOES_REQUEST: {
				draft.status = 100;
				draft.requestData = action.payload.payload;
				break;
			}

			case Types.REGISTRA_SOLICITACOES_SUCCESS: {
				draft.requestData = action.payload.payload;
				draft.status = 200;
				draft.responseData = action.payload.response.data;
				break;
			}

			case Types.REGISTRA_SOLICITACOES_FAILURE: {
				draft.requestData = action.payload.payload;
				draft.status = action.payload.response.status;
				draft.responseData = action.payload.response.data;
				break;
			}

			case Types.REGISTRA_SOLICITACOES_CLEAR: {
				draft.requestData = INITIAL_STATE.requestData;
				draft.status = INITIAL_STATE.status;
				draft.responseData = INITIAL_STATE.responseData;
				break;
			}

			default:
		}
		return draft;
	});
}
