import { Types } from './types';

export function atualizaSolicitacaoPortalRequest(
	payload: any,
	idSolicitacao: number,
) {
	return {
		type: Types.ATUALIZA_SOLICITACAO_PORTAL_REQUEST,
		payload,
		idSolicitacao,
	};
}
export function atualizaSolicitacaoPortalSuccess(payload: []) {
	return {
		type: Types.ATUALIZA_SOLICITACAO_PORTAL_SUCCESS,
		payload,
	};
}
export function atualizaSolicitacaoPortalFailure(payload: []): any {
	return {
		type: Types.ATUALIZA_SOLICITACAO_PORTAL_FAILURE,
		payload,
	};
}

export function limparAtualizaSolicitacaoPortal(): any {
	return {
		type: Types.LIMPAR_ATUALIZA_SOLICITACAO_PORTAL,
	};
}
