import styled from 'styled-components';

interface BoxNomeProps {
	sizeInputMunicipio?: number;
}

export const Container = styled.div`
	display: flex;
	position: relative;
	width: 100%;
`;

export const BoxCodigo = styled.div`
	position: relative;
	width: 251px;
`;

export const BoxNome = styled.div<BoxNomeProps>`
	position: absolute;
	right: 22px;
	width: ${props =>
		props.sizeInputMunicipio !== 100
			? `${props.sizeInputMunicipio}px`
			: `${125}px`};
`;

export const BoxIcon = styled.div`
	position: absolute;
	right: -10px;
`;
