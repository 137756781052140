export enum Types {
	CONSULTA_ANEXOS_CONSUMIDOR_REQUEST = '@procon/CONSULTA_ANEXOS_CONSUMIDOR_REQUEST',
	CONSULTA_ANEXOS_CONSUMIDOR_SUCCESS = '@procon/CONSULTA_ANEXOS_CONSUMIDOR_SUCCESS',
	CONSULTA_ANEXOS_CONSUMIDOR_FAILURE = '@procon/CONSULTA_ANEXOS_CONSUMIDOR_FAILURE',
	CONSULTA_ANEXOS_CONSUMIDOR_CLEAR = '@procon/CONSULTA_ANEXOS_CONSUMIDOR_CLEAR',
}

export interface ConsultaAnexosConsumidor {
	status: null | number;
	data: null | AnexosConsumidor[];
}

export interface AnexosConsumidor {
	interacaoId: string;
	descricao: string;
	dataDeCriacao: string;
	responsavelPelaInteracao: string;
	interacaoRespondida: string;
	protocolo: string;
	nomeDoConsumidor: string;
	linkDoDocumento: string;
	tramiteImportante: string;
	tramite: string;
	tipoDeTramite: string;
}

export interface ConsultaAnexosConsumidorRequest {
	atendimentoId: string;
	protocolo: string;
}
