import { combineReducers } from 'redux';

import consultaTransferenciaEndereco from './consultaTransferenciaEndereco/reducer';
import alteraTransferenciaEndereco from './alteraTransferenciaEndereco/reducer';
import consultaFazendaSefazCep from './consultaFazendaSefazCep/reducer';

export default combineReducers({
	consultaTransferenciaEndereco,
	alteraTransferenciaEndereco,
	consultaFazendaSefazCep,
});
