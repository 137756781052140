export enum Types {
	DOMAIN_SERVICE_NATURALIDADES_REQUEST = '@sgu-service/DOMAIN_SERVICE_NATURALIDADES_REQUEST',
	DOMAIN_SERVICE_NATURALIDADES_SUCCESS = '@sgu-service/DOMAIN_SERVICE_NATURALIDADES_SUCCESS',
	DOMAIN_SERVICE_NATURALIDADES_FAILURE = '@sgu-service/DOMAIN_SERVICE_NATURALIDADES_FAILURE',
	DOMAIN_SERVICE_NATURALIDADES_CLEAR = '@sgu-service/DOMAIN_SERVICE_NATURALIDADES_CLEAR',
}

export interface DomainServiceNaturalidades {
	status: number;
	data: DomainServiceNaturalidadesResponse[] | null;
}

export interface DomainServiceNaturalidadesResponse {
	id: number;
	digito: number;
	descricao: string;
	municipioIBGE: {
		id: number;
		descricao: string;
		uf: string;
	};
}

export interface DomainServiceNaturalidadesRequest {
	idIbge: string;
}
