import React, { useCallback, useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// ROUTER
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch } from 'react-redux';
import { logOffRequest } from 'store/modules/api/sgu/loginUnico/actions';
import { changeUrlClear } from 'store/modules/api/eCpf/changeUrl/actions';

// ICONS
import { FaSignOutAlt } from 'react-icons/fa';

// STYLED
import { Button, Icon, Text } from './styled';

const LogoutButton: React.FC = () => {
	const { logOut, tokenData } = useContext<IAuthContext>(AuthContext);

	const history = useHistory();
	const dispatch = useDispatch();
	const handleLogout = useCallback(async () => {
		dispatch(changeUrlClear());
		dispatch(logOffRequest());
		if (tokenData) {
			logOut();
		}
		history.push('/');
	}, [dispatch, history, logOut, tokenData]);

	return (
		<Button type="button" onClick={handleLogout}>
			<Icon>
				<FaSignOutAlt />
			</Icon>

			<Text>Sair</Text>
		</Button>
	);
};

export default LogoutButton;
