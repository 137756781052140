import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RegistraSolicitacoesRequest } from './types';

import {
	registraSolicitacoesFailure,
	registraSolicitacoesSuccess,
} from './actions';

function* registraSolicitacoesRequest(request: ReducerAction) {
	const { payload }: { payload: RegistraSolicitacoesRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`desbloqueio/${CHANNEL}/registra-solicitacoes`,
		payload.request,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(registraSolicitacoesSuccess({ response, payload }));
	} else {
		yield put(registraSolicitacoesFailure({ response, payload }));
	}
}

export default all([
	takeLatest(Types.REGISTRA_SOLICITACOES_REQUEST, registraSolicitacoesRequest),
]);
