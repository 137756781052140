import { all } from 'redux-saga/effects';

import atualizarDare from './atualizarDare/saga';
import consultarDare from './consultarDare/saga';
import consultarDareRetaguarda from './consultarDareRetaguarda/saga';
import imprimirDare from './imprimirDare/saga';
import statusDare from './statusDare/saga';
import statusDareMainframe from './statusDareMainframe/saga';

export default all([
	atualizarDare,
	consultarDare,
	consultarDareRetaguarda,
	imprimirDare,
	statusDare,
	statusDareMainframe,
]);
