import {
	IConsultarDare,
	ITaxaConsultarDare,
} from 'store/modules/api/dare/consultarDare/types';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export interface IRequestConsultarDare {
	cpf?: string;
	dare?: string;
}

export function consultarDareRetaguardaRequest(
	payload: IRequestConsultarDare,
): ReducerAction {
	return {
		type: Types.CONSULTAR_DARE_RETAGUARDA_REQUEST,
		payload,
	};
}
export function consultarDareRetaguardaSuccess(
	status: number,
	data: IConsultarDare[],
	taxasRes: ITaxaConsultarDare[],
): ReducerAction {
	return {
		type: Types.CONSULTAR_DARE_RETAGUARDA_SUCCESS,
		payload: {
			status,
			data,
			taxasRes,
		},
	};
}
export function consultarDareRetaguardaFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_DARE_RETAGUARDA_FAILURE,
		payload: null,
	};
}
export function consultarDareRetaguardaClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_DARE_RETAGUARDA_CLEAR,
		payload: null,
	};
}
