import styled from 'styled-components';

export const ContainerFichaCadastral = styled.div`
	.ant-col {
		div {
		label {
			max-width: 140px;
			text-align: right;
		}
		.ant-checkbox-wrapper {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			top: 0;
			left: 6px;
			width: 140px;
		}
	}
	.ant-checkbox-wrapper {
	}
`;

export const ButtonBox = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
`;
