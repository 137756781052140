import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_RELATORIOS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RelatorioAgendamentoMedicoPsicologicoRequest } from './types';

// ACTIONS
import {
	relatorioAgendamentoMedicoPsicologicoSuccess,
	relatorioAgendamentoMedicoPsicologicoFailure,
} from './actions';

function* relatorioAgendamentoMedicoPsicologico(request: ReducerAction) {
	const {
		payload,
	}: { payload: RelatorioAgendamentoMedicoPsicologicoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_RELATORIOS,
		`detran-cnh/${CHANNEL}/agendamento-medico-psicologico`,
		payload,
	);

	if (response.status === 200) {
		yield put(relatorioAgendamentoMedicoPsicologicoSuccess(response));
	} else {
		yield put(relatorioAgendamentoMedicoPsicologicoFailure(response));
	}
}

export default all([
	takeLatest(
		Types.RELATORIO_AGENDAMENTO_MEDICO_PSICOLOGICO_REQUEST,
		relatorioAgendamentoMedicoPsicologico,
	),
]);
