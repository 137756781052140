import styled, { css } from 'styled-components';

import BackgroundImage from 'assets/images/bg_header.jpg';
import InvertedBackgroundImage from 'assets/images/bg_headerInvertido.jpg';

export const Background = styled.div`
	// background-image: url(${BackgroundImage});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	height: 9vh;
	min-height: 84px;

	${props => css`
		background-image: linear-gradient(
			${props.theme.header.backgroundColor.secondary},
			${props.theme.header.backgroundColor.primary}
		);
	`}

	${props =>
		props.theme.title !== 'light' &&
		css`
			// background-image: url(${InvertedBackgroundImage});
		`};

	/* // Medium devices (tablets, 768px and up) */
	@media (max-width: 768.98px) {
	}

	/* Small devices (landscape phones, 576px and up) */
	@media (max-width: 575.98px) {
		height: auto;
	}

	/* // Large devices (desktops, 992px and up) */
	@media (max-width: 991.98px) {
	}

	/* // Extra large devices (large desktops, 1200px and up) */
	@media (max-width: 1199.98px) {
	}
`;

export const Container = styled.div`
	margin: 0 auto;
	max-width: 1006px;
	padding: 0 15px;
	width: 100%;

	/* Small devices (landscape phones, 576px and up) */
	@media (max-width: 575.98px) {
		max-width: 100%;
	}

	/* Medium devices (tablets, 768px and up) */
	@media (max-width: 767.98px) {
		max-width: 100%;
	}

	/* Large devices (desktops, 992px and up) */
	@media (max-width: 991.98px) {
	}

	/* Extra large devices (large desktops, 1200px and up) */
	@media (max-width: 1199.98px) {
	}
`;

export const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	height: 100%;
	padding: 8px 0px;

	/* // Medium devices (tablets, 768px and up) */
	@media (max-width: 575.98px) {
		margin: 15px 0;
		justify-content: center;
	}
`;

export const HeaderInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
`;

export const OptionsMenu = styled.div`
	display: flex;
	margin: 8px 0 24px 0;
`;

export const LoginContainer = styled.div`
	display: flex;

	@media (max-width: 575.98px) {
		align-self: center;
	}
`;

export const LoginInfo = styled.div`
	font-weight: 700;
	margin-right: 8px;

	&::after {
		content: '|';
		margin-left: 8px;
	}
`;
