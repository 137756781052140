import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AlteraSenhaDetran } from './types';

export const INITIAL_STATE: AlteraSenhaDetran = {
	status: 0,
	mensagem: '',
	form: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): AlteraSenhaDetran {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ALTERA_SENHA_DETRAN_REQUEST: {
				draft.status = 100;
				draft.form = action.payload;
				draft.mensagem = INITIAL_STATE.mensagem;
				break;
			}

			case Types.ALTERA_SENHA_DETRAN_SUCCESS: {
				draft.status = 200;
				break;
			}

			case Types.ALTERA_SENHA_DETRAN_FAILURE: {
				draft.status = 400;

				break;
			}
			case Types.ALTERA_SENHA_DETRAN_CLEAR: {
				draft.status = 0;
				draft.mensagem = INITIAL_STATE.mensagem;
				draft.form = INITIAL_STATE.form;
				break;
			}

			default:
		}
	});
}
