import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse } from 'services/_api';
import { getApi } from 'services/apiBiometria';
import { PATH_BIOMETRIA } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	verificarVersaoServicoBiometriaOld,
	verificarVersaoServicoBiometriaNew,
} from './actions';

function* verificarVersaoServicoBiometria() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_BIOMETRIA,
		'Biometria',
		undefined,
		true,
		true,
	);

	if (
		response.data &&
		response.data.status !== 0 &&
		response.data.status !== 404
	) {
		// INDICA QUE ESTA EM USO A VERSAO ANTIGA DO SERVICO: api/Biometria
		yield put(verificarVersaoServicoBiometriaOld());
	} else {
		// INDICA QUE ESTA EM USO A VERSAO NOVA DO SERVICO: api/biometria/capturar
		yield put(verificarVersaoServicoBiometriaNew());
	}
}

export default all([
	takeLatest(
		Types.VERIFICAR_VERSAO_SERVICO_BIOMETRIA_REQUEST,
		verificarVersaoServicoBiometria,
	),
]);
