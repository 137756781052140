export enum Types {
	CONSULTAR_FICHA_ALUNO_REQUEST = '@EDUCACAO/CONSULTAR_FICHA_ALUNO_REQUEST',
	CONSULTAR_FICHA_ALUNO_SUCCESS = '@EDUCACAO/CONSULTAR_FICHA_ALUNO_SUCCESS',
	CONSULTAR_FICHA_ALUNO_FAILURE = '@EDUCACAO/CONSULTAR_FICHA_ALUNO_FAILURE',
	CONSULTAR_FICHA_ALUNO_CLEAR = '@CONSULTAR_FICHA_ALUNO_CLEAR',
}

export interface IConsultarFichaAlunoState {
	status: number;
	data: FichaAlunoResponse | null;
}

export interface FichaAlunoResponse {
	aluno: Aluno;
	idProcesso: number;
	mensagem: string;
	dataAlteracao: string;
	dadosPessoais: IDadosPessoais;
	documentos: IDocumentos;
	justificativaDocumentos: string;
	certidaoAntiga: ICertidaoAntiga;
	certidaoNova: ICertidaoNova;
	enderecoResidencial: IEnderecoResidencial;
	telefones: ITelefone[];
	ddd?: string;
	numero?: string;
	tipo?: string;
	descricaoTipo?: string;
	complemento?: string;
	sms?: string;
	deficiencia: IDeficiencia;
	recursoAvaliacao: IRecursoAvaliacao;
	necessidadesEspeciais: INecessidadesEspeciais[];
	irmaos?: IIrmao[];
}

interface Aluno {
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
}

interface IDadosPessoais {
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
	nomeAluno: string;
	dataNascimento: string;
	corRaca: number;
	descricaoCorRaca: string;
	codigoSexo: number;
	sexo: string;
	nomeMae: string;
	nomePai: string;
	nomeSocial: string;
	nomeAfetivo: string;
	email: string;
	nacionalidade: number;
	descricaoNacionalidade: string;
	codigoPaisOrigem: number;
	nomePaisOrigem: string;
	dataEntradaPais: string;
	bolsaFamilia: string;
	quilombola: string;
	nomeMunicipioNascimento: string;
	ufMunicipioNascimento: string;
	alunoFalecido: string;
	dataFaleciomento: string;
	codigoMunicipioNascimentoDNE: number;
	codigoBolsaFamilia: number;
	possuiInternet: string;
	possuiNotebookSmartphoneTablet: string;
}

interface IDocumentos {
	codigoINEP: number;
	cpf: string;
	numeroDocumentoCivil: string;
	digitoDocumentoCivil: string;
	dataEmissaoRG: string;
	ufDocumentoCivil: string;
	dataEmissaoDocumentoCivil: string;
	dataEmissaoCertidao: string;
	tipoCertidao: string;
	numeroNIS: string;
	numeroRG: string;
	digitoRG: string;
	ufRG: string;
	justificativaDocumentos: string;
}

interface ICertidaoAntiga {
	numeroCertidao: string;
	numeroLivroRegistro: string;
	numeroFolhaRegistro: string;
	nomeMunicipioComarca: string;
	ufComarca: string;
	distritoNascimento: string;
	folha: string;
	livro: string;
	municipioComarca: string;
	distritoCertidao: string;
	dataEmissaoCertidao: string;
}

interface ICertidaoNova {
	certificadoMatrtricula01: string;
	certificadoMatrtricula02: string;
	certificadoMatrtricula03: string;
	certificadoMatrtricula04: string;
	certificadoMatrtricula05: string;
	certificadoMatrtricula06: string;
	certificadoMatrtricula07: string;
	certificadoMatrtricula08: string;
	certificadoMatrtricula09: string;
}

interface IEnderecoResidencial {
	logradouro: string;
	numero: string;
	areaLogradouro: string;
	complemento: string;
	bairro: string;
	nomeCidade: string;
	ufCidade: string;
	latitude: string;
	longitude: string;
	cep: string;
	codigoMunicipuoDNE: number;
	codigoArea: number;
	codigoLocalizacao: number;
	localizacaoDiferenciada: string;
}

interface ITelefone {
	key: string;
	descricaoTipo: string;
	complemento: string;
	inSMS: string;
	inDDDNumero: string;
	inNumero: string;
	inTipoTelefone: string;
}

interface IDeficiencia {
	codigo: string;
	descricao: string;
	mobilidadeReduzida: string;
	tipoMobilidadeReduzida: string;
	cuidador: string;
	tipoCuidador: string;
	profSaude: string;
	tipoProfSaude: string;
	possuiNecessidades: boolean;
	codigoNecessidade: string;
}

interface IRecursoAvaliacao {
	guiaInterprete: string | boolean;
	interpreteLibras: string | boolean;
	leituraLabial: string | boolean;
	nenhum: string | boolean;
	provaAmpliada: string | boolean;
	tamanhoFonte: number;
	provaBraile: string | boolean;
	auxilioTranscricao: string | boolean;
	auxilioLeitor: string | boolean;
	provaVideoLibras: string | boolean;
	cdAudioDeficienteVisual: string | boolean;
	provaLinguaPortuguesa: string | boolean;
}

interface INecessidadesEspeciais {
	codigo: number;
	nome: string;
	tipo: string;
}

interface IIrmao {
	nomeAluno: string;
	dataNascimento: string;
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
	gemeo: string;
}
