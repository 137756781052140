import produce from 'immer';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, ConsultarCpfCinState } from './types';

export const INITIAL_STATE: ConsultarCpfCinState = {
	status: 0,
	data: null,
	dataError: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerActionRotaSP,
): ConsultarCpfCinState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_CIN_CPF_REQUEST: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}
			case Types.CONSULTAR_CIN_CPF_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}
			case Types.CONSULTAR_CIN_CPF_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				draft.dataError = action.payload.data;
				break;
			}
			case Types.CONSULTAR_CIN_CPF_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}

			default:
		}
		return draft;
	});
}
