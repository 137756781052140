import React from 'react';

import { Row, Col } from 'antd';

// REDUX
import { ConsultaUsuarioPendente } from 'store/modules/api/aac/novoPainelDeControle/consultaUsuarioPendente/types';

// COMPONENTS
import Section from 'components/Common/Section';
import Radio from 'components/Common/Form/Radio';

import DadosRg from '../../components/DadosRg';

interface Props {
	data: ConsultaUsuarioPendente;
}

const LiberacaoNormal: React.FC<Props> = ({ data }) => {
	return (
		<>
			<Section>
				<DadosRg data={data} />
			</Section>

			<Row gutter={[0, 20]}>
				<Col span={24} />
			</Row>

			<Row>
				<Col span={24}>
					<Radio
						name="Liberação Normal"
						defaultValue="sim"
						options={[{ value: 'sim', label: 'Liberação Normal' }]}
						onChange={() => {}}
					/>
				</Col>
			</Row>

			<Row gutter={[0, 20]}>
				<Col span={24} />
			</Row>

			<Row justify="center">
				<Col>
					<p>
						Analise efetuada no IIRGD por: <span>{data.usuarioCentral}</span>
					</p>
				</Col>
			</Row>
		</>
	);
};

export default LiberacaoNormal;
