import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { CadastrarNaturalidadeRequest, Types } from './types';
import {
	cadastrarNaturalidadeSuccess,
	cadastrarNaturalidadeFailure,
} from './actions';

function* cadastrarNaturalidadeRequest(request: ReducerAction) {
	const { payload }: { payload: CadastrarNaturalidadeRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU,
		`naturalidades`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarNaturalidadeSuccess(response));
	} else {
		yield put(cadastrarNaturalidadeFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_NATURALIDADE, cadastrarNaturalidadeRequest),
]);
