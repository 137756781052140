import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export function consultaEntidadeMilitarRequest(): ReducerAction {
	return {
		type: Types.CONSULTA_ENTIDADE_MILITAR_REQUEST,
		payload: null,
	};
}
export function consultaEntidadeMilitarSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_ENTIDADE_MILITAR_SUCCESS,
		payload,
	};
}
export function consultaEntidadeMilitarFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_ENTIDADE_MILITAR_FAILURE,
		payload,
	};
}
