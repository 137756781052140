/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';
import { useLocation } from 'react-router-dom';

// REDUX
import data from 'store/modules/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import Select from 'components/Common/Form/Select';

// UTILS
import hasError from 'utils/getFormErrors';
import { completaZeroEsquerda } from 'utils/genericFunctions';

// FORM
import { IEmiteTransferenciaVeiculo } from '../form';

interface LocationState {
	state: {
		espelho: string;
		dcumento: boolean;
	};
}
interface Props {
	formik: FormikProps<IEmiteTransferenciaVeiculo>;
}

const Espelho: React.FC<Props> = ({ formik }) => {
	const location = useLocation();
	const locationState: LocationState | any = location.state;

	useEffect(() => {
		if (locationState.espelho !== '') {
			const formattedEspelho = completaZeroEsquerda(locationState.espelho, 12);
			formik.setFieldValue('numeroEspelhoAnterior', formattedEspelho || '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section>
						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={InputMask}
									title="Espelho Anterior"
									titleSize="lg"
									name="numeroEspelhoAnterior"
									mask="999999999999"
									size={80}
									required
									error={hasError(formik.errors, 'numeroEspelhoAnterior')}
								/>
							</Col>
							<Col span={11}>
								<Field
									as={Select}
									title="UF Espelho Anterior"
									titleSize="lg"
									name="ufEspelhoAnterior"
									options={data.uf}
									error={hasError(formik.errors, 'ufEspelhoAnterior')}
									onChange={(v: string) => {
										formik.setFieldValue('ufEspelhoAnterior', v);
									}}
									size={92}
									disabled
								/>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default Espelho;
