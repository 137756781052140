import * as Yup from 'yup';

// STORE
import { ILogin } from 'store/modules/api/sgu/loginUnico/types';

// UTILS
import { isCpfValid } from 'utils/yupValidations';
import { limparMascara } from 'utils/genericFunctions';
import {
	ConsultaCpf,
	ConsultaCpfRequest,
} from 'store/modules/api/iirgd/pesquisaCicp/types';
import {
	CadastrarCinRequest,
	enumBloodType,
	maritalStatus,
} from 'store/modules/api/rfb/cadastrarCin/types';
import { ConsultaPesquisaCirgState } from 'store/modules/api/iirgd/consultaPesquisaCirg/types';
import { DadosEmissaoCINState } from 'store/modules/api/iirgd/consultaCIN/types';

export interface IFormConsultarCpf {
	cpf: string;
}

export const initialValues: IFormConsultarCpf = {
	cpf: '',
};

export const schema = Yup.object<IFormConsultarCpf>().shape({
	cpf: Yup.string()
		.required('Campo Obrigatório: CPF.')
		.test({
			name: 'isValidCPF',
			exclusive: true,
			message: 'CPF informado é inválido.',
			test: value => (value !== '' ? isCpfValid(value) : true),
		}),
});

export function treatValues(
	values: IFormConsultarCpf,
	login: ILogin,
): ConsultaCpfRequest {
	const { cpf } = values;

	return {
		cpf: limparMascara(cpf).slice(0, -2),
		usuario: login.mainframe.idUsuario,
		senha: login.mainframe.senhaMainFrame,
	};
}

export interface IBGECodes {
	codigoMunicipioResidencia: number | null;
	codigoNaturalidade: number | null;
	codigoMunicipioEmissao: number | null;
}

export const emitirCinTreatPayloadRequest = (
	consultaCIN: DadosEmissaoCINState,
	consultaCpf: ConsultaCpf,
	userCPF: string,
	IBGECountiesCodes: IBGECodes,
	consultaPesquisaCirg: ConsultaPesquisaCirgState,
	foto: string,
): CadastrarCinRequest => {
	const getMaritalStatus = (): number => {
		const maritalStatusEdited = [
			{
				...maritalStatus.solteiro,
				valueInNormalFlux: 'S',
			},
			{ ...maritalStatus.casado, valueInNormalFlux: 'C,T' },
			{ ...maritalStatus.separado, valueInNormalFlux: 'J,D' },
			{ ...maritalStatus.divorciado, valueInNormalFlux: 'I' },
			{ ...maritalStatus.viuvo, valueInNormalFlux: 'V' },
		];

		return (
			maritalStatusEdited.filter(item =>
				item.valueInNormalFlux
					.toLowerCase()
					.includes(consultaCpf.estadoCivil?.toLowerCase() || ''),
			)[0]?.value || 0
		);
	};

	const getBloodType = (): number | null => {
		return (
			enumBloodType.filter(
				item =>
					item.description.toLowerCase() ===
						consultaCpf.tipoSanguineo?.toLowerCase() || '',
			)[0]?.value || null
		);
	};

	const getParentsList = () => {
		const parentsList = [];

		if (consultaCpf.nomePai) {
			parentsList.push(consultaCpf.nomePai);
		}
		if (consultaCpf.nomeMae) {
			parentsList.push(consultaCpf.nomeMae);
		}

		return parentsList;
	};

	return {
		cpfUsuario: userCPF || '',
		cpfCidadao: limparMascara(consultaCpf.cpf) || '',
		nome: consultaCpf.nome || '',
		nomeSocial: consultaCpf.nomeSocial || '',
		dataNascimento: consultaCpf.dataNascimento || '',
		protocoloOIC: consultaCpf.numeroRg.toString() || '',
		sexo: consultaCpf.sexo || '',
		codigoMunicipioNaturalidade: IBGECountiesCodes.codigoNaturalidade || 0,
		codigoPaisNaturalidadeExterior: '',
		codigoPaisNacionalidade: 'BRA',
		listaFiliacao: getParentsList(),
		indicadorMaeRegistro: !!consultaCpf.nomeMae,
		logradouro: consultaCpf.enderecoResidencia || '',
		numeroEndereco: consultaCpf.numeroResidencia || '',
		complementoEndereco: consultaCpf.complementoResidencia || '',
		bairroEndereco: consultaCpf.bairroResidencia || '',
		codigoMunicipioResidencia: IBGECountiesCodes.codigoMunicipioResidencia || 0,
		cepResidencia: limparMascara(consultaCpf.cepResidencia) || '',
		codigoPaisResidencia: 'BRA',
		localResidenciaExterior: '', // -> SERA ATUALIZADO QUANDO CORRIGIDO NO BACK
		listaEmail:
			consultaCIN.data?.retornoConsultada[0]?.listaCin[0].listaEmail || [],
		listaTelefone:
			consultaCIN.data?.retornoConsultada[0]?.listaCin[0].listaTelefone || [],
		codigoMunicipioEmissao: IBGECountiesCodes.codigoMunicipioEmissao || 0,
		observacao: 'Sem observação', // -> SERA ATUALIZADO QUANDO CORRIGIDO NO BACK
		indicadorDeficienciaFisica:
			consultaPesquisaCirg.data?.indicadorDeficienciaFisica ?? false,
		indicadorDeficienciaAuditiva:
			consultaPesquisaCirg.data?.indicadorDeficienciaAuditiva ?? false,
		indicadorDeficienciaIntelectual:
			consultaPesquisaCirg.data?.indicadorDeficienciaIntelectual ?? false,
		indicadorDeficienciaVisual:
			consultaPesquisaCirg.data?.indicadorDeficienciaVisual ?? false,
		indicadorAutismo: consultaPesquisaCirg.data?.indicadorAutismo ?? false,
		doador: false,
		tituloEleitor: consultaCpf.tituloEleitor || '',
		codigoTipoSanguineo: getBloodType(),
		codigoEstadoCivil: getMaritalStatus(),
		certidaoNascimentoCasamento:
			consultaCIN.data?.retornoConsultada[0]?.listaCin[0]
				.certidaoNascimentoCasamento || '',
		averbacaoDivorcio: '',
		cnh: consultaCpf.cnh || '',
		categoriaCnh: '', // -> SERA ATUALIZADO QUANDO CORRIGIDO NO BACK
		pisPasep: consultaCpf.pasep || '',
		nis: '', // -> SERA ATUALIZADO QUANDO CORRIGIDO NO BACK
		nit: '', // -> SERA ATUALIZADO QUANDO CORRIGIDO NO BACK
		carteiraTrabalho: consultaCpf.ctpsNum || '',
		cartaoNacionalSaude: consultaCpf.nascSaude || '',
		foto,
	};
};
