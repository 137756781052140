import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarMotivoRequest } from './types';

export function cadastrarMotivoRequest(
	data: CadastrarMotivoRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_MOTIVO,
		payload: data,
	};
}
export function cadastrarMotivoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_MOTIVO_SUCCESS,
		payload,
	};
}
export function cadastrarMotivoFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_MOTIVO_FAILURE,
		payload: null,
	};
}
export function cadastrarMotivoClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_MOTIVO_CLEAR,
		payload: null,
	};
}
