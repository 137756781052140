import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';

// REDUX
import { ApplicationState } from 'store';

// FORM
import {
	IFormInclusaoObsPrimeiraViaMenor,
	IFormInclusaoObsPrimeiraViaMaior,
	IFormInclusaoObsSegundaVia,
} from '../../../InclusaoObservacoes/Formularios/form';

// COMPONENTS
import { PrimeiraViaMenor } from './PrimeiraVia/Menor';
import { PrimeiraViaMaior } from './PrimeiraVia/Maior';
import { SegundaVia } from './SegundaVia';

// STYLED
import { Title } from '../../styled';

export const Observacoes: React.FC = () => {
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	const [obsPrimeiraViaMenor, setObsPrimeiraViaMenor] =
		useState<IFormInclusaoObsPrimeiraViaMenor | null>(null);

	const [obsPrimeiraViaMaior, setObsPrimeiraViaMaior] =
		useState<IFormInclusaoObsPrimeiraViaMaior | null>(null);

	const [obsSegundaVia, setObsSegundaVia] =
		useState<IFormInclusaoObsSegundaVia | null>(null);

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	useEffect(() => {
		if (cadastroRg.form.observacoes.primeiraVia.menor) {
			setObsPrimeiraViaMenor(cadastroRg.form.observacoes.primeiraVia.menor);
		} else if (cadastroRg.form.observacoes.primeiraVia.maior) {
			setObsPrimeiraViaMaior(cadastroRg.form.observacoes.primeiraVia.maior);
		} else if (cadastroRg.form.observacoes.segundaVia) {
			setObsSegundaVia(cadastroRg.form.observacoes.segundaVia);
		}

		setHasLoaded(true);
	}, [cadastroRg.form.observacoes]);

	return (
		<>
			{hasLoaded && (
				<>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Title>Observações</Title>
						</Col>
					</Row>

					{obsPrimeiraViaMenor && (
						<PrimeiraViaMenor data={obsPrimeiraViaMenor} />
					)}

					{obsPrimeiraViaMaior && (
						<PrimeiraViaMaior data={obsPrimeiraViaMaior} />
					)}

					{obsSegundaVia && <SegundaVia data={obsSegundaVia} />}

					<Row gutter={[0, 10]}>
						<Col span={24} />
					</Row>
				</>
			)}
		</>
	);
};
