import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultaToxicologicoSuccess,
	consultaToxicologicoFailure,
} from './actions';

function* consultaToxicologico(request: ReducerAction) {
	const { payload }: { payload: string } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CNH,
		`cidadao/${CHANNEL}/consulta/exame/toxicologico/${payload}`,
		{},
		true,
	);

	if (response.status === 200) {
		yield put(consultaToxicologicoSuccess(response));
	} else {
		yield put(consultaToxicologicoFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTA_TOXICOLOGICO_REQUEST, consultaToxicologico),
]);
