import styled, { css } from 'styled-components';
import { rgba } from 'polished';

export const Container = styled.div`
	background-color: #f5f5f5;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-top: 16px;
	padding: 12px;
	width: 100%;

	${props =>
		props.theme.title !== 'light' &&
		css`
			background-color: ${rgba(0, 0, 0, 0.3)};
			border: 1px solid #676767;
		`};
`;

export const Title = styled.h4`
	font-size: calc(1rem + ${props => props.theme.fontScale}%);
	font-weight: 500;
	margin-bottom: 4px;
`;

export const Description = styled.div`
	font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
`;

export const FormRow = styled.div`
	margin-top: 8px;
`;
