import React, { useCallback, useEffect } from 'react';

import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// PATHS
import { ROUTE_CDHU_SERVICOS_SUCESSO } from 'pages/cdhu/routes/paths';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import { formatCurrency } from 'utils/numberFunctions';

// COMPONENTS
import { formatDate } from 'utils/genericFunctions';
import AcordoNormal from './AcordoNormal';
import AcordoHeader from './Header';
import AcordoFooter from './Footer';
// import AcordoCovid from './AcordoCovid';

const Termos: React.FC = () => {
	const history = useHistory();

	// const [exibirAcordoNormal, setExibirAcordoNormal] = useState<boolean>(false);
	// const [exibirAcordoCovid, setExibirAcordoCovid] = useState<boolean>(false);

	const { consultarMutuario, consultarPrestacoesAtrasadas, simularEfetuarAcordo } =
		useSelector((state: ApplicationState) => state.api.cdhu);

	useEffect(() => {
		window.print();
	}, []);

	// const validaInicioParcela = useCallback((data: string): boolean => {
	// 	const dataSplitted = data.split('/');
	// 	const date = new Date(
	// 		Number(dataSplitted[2]),
	// 		Number(dataSplitted[1]) - 1,
	// 		Number(dataSplitted[0]),
	// 	);

	// 	const dataValidacao = new Date(2020, 0, 1);

	// 	return date < dataValidacao;
	// }, []);

	// useEffect(() => {
	// 	if (simularEfetuarAcordo.data) {
	// 		const { dataInicioParcela } = simularEfetuarAcordo.data;

	// 		if (!dataInicioParcela) {
	// 			// setExibirAcordoCovid(true);
	// 			setExibirAcordoNormal(true);
	// 		}

	// 		// if (dataInicioParcela && validaInicioParcela(dataInicioParcela)) {
	// 		// 	setExibirAcordoNormal(true);
	// 		// 	setExibirAcordoCovid(false);
	// 		// }
	// 	}
	// }, [simularEfetuarAcordo.data, validaInicioParcela]);

	const handleClickImprimir = useCallback(() => {
		window.print();
	}, []);

	const handleButtonVoltar = useCallback(() => {
		history.push(ROUTE_CDHU_SERVICOS_SUCESSO);
	}, [history]);

	return (
		<>
			{consultarMutuario.data &&
				consultarPrestacoesAtrasadas.data &&
				simularEfetuarAcordo.data && (
					<>
						<Row gutter={[0, 20]}>
							<Col span={24} />
						</Row>
						<Row gutter={[0, 20]}>
							<Col span={24}>
								<AcordoHeader />
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<Row gutter={[0, 10]}>
									<Col span={15}>
										<Row>
											<Col>
												<strong>1. Conjunto:</strong>
											</Col>

											<Col style={{ marginLeft: '8px' }}>
												{consultarMutuario.data.cnjHab.toUpperCase()}
											</Col>
										</Row>
									</Col>

									<Col span={8} offset={1}>
										<Row justify="end">
											<Col>
												<strong>2. Conta do mutuário:</strong>
											</Col>

											<Col style={{ marginLeft: '8px' }}>
												{consultarMutuario.data.numeroContrato}
											</Col>
										</Row>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col>
										<strong>Endereço:</strong>
									</Col>

									<Col style={{ marginLeft: '8px' }}>
										{consultarMutuario.data.endCompleto.toUpperCase()}
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={15}>
										<Row>
											<Col>
												<strong>3. Nome:</strong>
											</Col>

											<Col style={{ marginLeft: '8px' }}>
												{consultarMutuario.data.nome.toUpperCase()}
											</Col>
										</Row>
									</Col>

									<Col span={8} offset={1}>
										<Row justify="end">
											<Col>
												<strong>CPF:</strong>
											</Col>

											<Col style={{ marginLeft: '8px' }}>
												{consultarMutuario.data.cpf}
											</Col>
										</Row>
									</Col>
								</Row>

								<Row justify="space-between" gutter={[0, 10]}>
									<Col span={7}>
										<Row>
											<Col>
												<strong>4. Débito do acordo:</strong>
											</Col>

											<Col style={{ marginLeft: '8px' }}>
												{formatCurrency(
													simularEfetuarAcordo.data.valorRestanteDebito,
												)}
											</Col>
										</Row>
									</Col>

									<Col span={7} offset={1}>
										<Row justify="center">
											<Col>
												<strong>4.1. Período:</strong>
											</Col>

											<Col style={{ marginLeft: '8px' }}>
												{simularEfetuarAcordo.data.periodoInicialAcordo &&
													simularEfetuarAcordo.data.periodoFinalAcordo && (
														<>
															{`${simularEfetuarAcordo.data.periodoInicialAcordo} a ${simularEfetuarAcordo.data.periodoFinalAcordo}`}
														</>
													)}
											</Col>
										</Row>
									</Col>

									<Col span={7} offset={1}>
										<Row justify="end">
											<Col>
												<strong>4.2. Qtde. prestações:</strong>
											</Col>

											<Col style={{ marginLeft: '8px' }}>
												{consultarPrestacoesAtrasadas.data.quantidadeField}
											</Col>
										</Row>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col>
										<strong>5. Valor da parcela do Acordo (R$):</strong>
									</Col>

									<Col style={{ marginLeft: '8px' }}>
										{formatCurrency(simularEfetuarAcordo.data.valorParcelaAcordo)}
									</Col>
								</Row>

								<Row justify="space-between" gutter={[0, 10]}>
									<Col span={8}>
										<Row>
											<Col>
												<strong>5.1. Vencto. 1ª parcela:</strong>
											</Col>

											<Col style={{ marginLeft: '8px' }}>
												{formatDate(simularEfetuarAcordo.data.dataPrestacaoInicial)}
											</Col>
										</Row>
									</Col>

									<Col span={9}>
										<Row justify="center">
											<Col>
												<strong>5.2. Vencto. última parcela:</strong>
											</Col>

											<Col style={{ marginLeft: '8px' }}>
												{formatDate(simularEfetuarAcordo.data.dataPrestacaoFinal)}
											</Col>
										</Row>
									</Col>

									<Col span={7}>
										<Row justify="end">
											<Col>
												<strong>5.3. Número de parcelas:</strong>
											</Col>

											<Col style={{ marginLeft: '8px' }}>
												{simularEfetuarAcordo.data.qtdParcelasAcordo}
											</Col>
										</Row>
									</Col>
								</Row>

								<AcordoNormal />

								<AcordoFooter />

								{/* {exibirAcordoNormal && <AcordoNormal />} */}

								{/* {exibirAcordoCovid && <AcordoCovid />} */}
							</Col>
						</Row>

						<Row gutter={[0, 10]} className="no-print">
							<Col span={24} />
						</Row>

						<Row justify="space-between" className="no-print">
							<Col>
								<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
							</Col>

							<Col>
								<ButtonImage src="imprimir" onClick={handleClickImprimir} />
							</Col>
						</Row>
					</>
				)}
		</>
	);
};

export default Termos;
