import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	TIPOS_CONTRATACAO_REQUEST = '@procon/TIPOS_CONTRATACAO_REQUEST',
	TIPOS_CONTRATACAO_SUCCESS = '@procon/TIPOS_CONTRATACAO_SUCCESS',
	TIPOS_CONTRATACAO_FAILURE = '@procon/TIPOS_CONTRATACAO_FAILURE',
	TIPOS_CONTRATACAO_CLEAR = '@procon/TIPOS_CONTRATACAO_CLEAR',
}

export interface TiposContratacao {
	status: null | number;
	data: OptionProps[];
}
