import React from 'react';

// COMPONENTS
import Input from 'components/Common/Form/Input/Input';

// FORM
import { Field, FormikProps } from 'formik';
import { Col, Row } from 'antd';

// UTILS
import { onlyNumbers, onlyNumbersAndLetters } from 'utils/genericFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';

interface BoxFormInputsPlacaRenavamCpfCnpjProps {
	formik: FormikProps<any>;
	onlyRead?: boolean;
}

export const BoxFormInputsPlacaRenavamCpfCnpj = ({
	formik,
	onlyRead,
}: BoxFormInputsPlacaRenavamCpfCnpjProps) => {
	const dispatch = useDispatch();

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);
	return (
		<Row justify="center" gutter={[0, 12]}>
			<Col span={24}>
				<Field
					as={Input}
					title="CPF/CNPJ do proprietário"
					name="cpfCnpj"
					titleAlign="start"
					titleSize="100"
					size={96}
					maxLength={14}
					required
					error={!!formik.errors.cpfCnpj}
					onChange={(e: React.FormEvent<HTMLInputElement>) => {
						if (errors.length > 0) {
							dispatch(clearNotifications());
						}
						formik.setFieldValue(
							'cpfCnpj',
							onlyNumbers(e.currentTarget.value) || '',
						);
					}}
					disabled={onlyRead}
				/>
			</Col>
			<Col span={24}>
				<Field
					as={Input}
					title="Placa"
					name="placa"
					titleAlign="start"
					titleSize="100"
					size={96}
					maxLength={7}
					required
					error={!!formik.errors.placa}
					onChange={(e: React.FormEvent<HTMLInputElement>) => {
						if (errors.length > 0) {
							dispatch(clearNotifications());
						}
						formik.setFieldValue(
							'placa',
							onlyNumbersAndLetters(e.currentTarget.value).toUpperCase() || '',
						);
					}}
					disabled={onlyRead}
				/>
			</Col>
			<Col span={24}>
				<Field
					as={Input}
					title="Renavam"
					name="renavam"
					titleAlign="start"
					titleSize="100"
					size={96}
					maxLength={11}
					required
					error={!!formik.errors.renavam}
					onChange={(e: React.FormEvent<HTMLInputElement>) => {
						if (errors.length > 0) {
							dispatch(clearNotifications());
						}
						formik.setFieldValue(
							'renavam',
							onlyNumbers(e.currentTarget.value).toUpperCase() || '',
						);
					}}
					disabled={onlyRead}
				/>
			</Col>
		</Row>
	);
};

BoxFormInputsPlacaRenavamCpfCnpj.defaultProps = {
	onlyRead: false,
};
