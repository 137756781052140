import { getBiometricReaderDescription } from 'pages/DetranCnh/utils/functions/biometrics';
import { incluirRequestPayloadBaseParams } from 'pages/DetranCnh/utils/threatServicesRequest';
import { CapturarBiometriaState } from 'store/modules/api/biometria/capturarBiometria/types';
import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';

export const treatPayloadIncluirEstatisticaBiometricReader = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	cpfAtendimento: string,
	capturarBiometria: CapturarBiometriaState,
): IncluirRequest => {
	const payloadBase = incluirRequestPayloadBaseParams(
		user,
		dadosAtendimento,
		cpfAtendimento,
	);

	const descriptionBiometricReader =
		getBiometricReaderDescription(capturarBiometria);

	const treatedPayload = {
		...payloadBase,
		descricaoServico: 'CAPTURA_BIOMETRICA',
		idMotivo: 10,
		observacao: descriptionBiometricReader,
	};

	return treatedPayload;
};
