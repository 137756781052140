import produce from 'immer';

// UTILS
import { formatDate } from 'utils/genericFunctions';

// TYPES
import { Types, consultaPermissaoInternacional } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaPermissaoInternacional = {
	status: 0,
	data: null,
};

export default function consultaPermissaoInternacionalReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaPermissaoInternacional {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PERMISSAO_INTERNACIONAL: {
				break;
			}

			case Types.CONSULTA_PERMISSAO_INTERNACIONAL_SUCCESS: {
				// const permissaoInternacional = {
				// 	...action.payload.data.dadosPermissaoInternacional[0],
				// };
				const permissaoInternacional = {
					...action.payload[0],
				};

				if (permissaoInternacional.dataPrimeiraHabilitacao !== '') {
					permissaoInternacional.dataPrimeiraHabilitacao = formatDate(
						permissaoInternacional.dataPrimeiraHabilitacao,
					);
				}
				if (permissaoInternacional.dataValidadeCnh !== '') {
					permissaoInternacional.dataValidadeCnh = formatDate(
						permissaoInternacional.dataValidadeCnh,
					);
				}
				if (permissaoInternacional.dataNascimento !== '') {
					permissaoInternacional.dataNascimento = formatDate(
						permissaoInternacional.dataNascimento,
					);
				}
				if (
					permissaoInternacional.dadosExameMedico.dataExameMedico !==
						undefined &&
					permissaoInternacional.dadosExameMedico.dataExameMedico !== ''
				) {
					permissaoInternacional.dadosExameMedico.dataExameMedico = formatDate(
						permissaoInternacional.dadosExameMedico.dataExameMedico,
					);
				}
				if (
					permissaoInternacional.dadosExamePsicotecnico
						.dataExamePsicotecnico !== ''
				) {
					permissaoInternacional.dadosExamePsicotecnico.dataExamePsicotecnico = formatDate(
						permissaoInternacional.dadosExamePsicotecnico.dataExamePsicotecnico,
					);
				}
				if (permissaoInternacional.dataExameTeorico !== '') {
					permissaoInternacional.dataExameTeorico = formatDate(
						permissaoInternacional.dataExameTeorico,
					);
				}
				if (permissaoInternacional.dataExamePratico2Rodas !== '') {
					permissaoInternacional.dataExamePratico2Rodas = formatDate(
						permissaoInternacional.dataExamePratico2Rodas,
					);
				}
				if (permissaoInternacional.dataExamePratico4Rodas !== '') {
					permissaoInternacional.dataExamePratico4Rodas = formatDate(
						permissaoInternacional.dataExamePratico4Rodas,
					);
				}
				if (permissaoInternacional.dadosPcad.dataNascimento !== '') {
					permissaoInternacional.dadosPcad.dataNascimento = formatDate(
						permissaoInternacional.dadosPcad.dataNascimento,
					);
				}

				if (
					!!permissaoInternacional.dadosPcad.naturalidade &&
					permissaoInternacional.dadosPcad.naturalidade !== ''
				) {
					const newNaturalidade = permissaoInternacional.dadosPcad.naturalidade.split(
						' - ',
					)[0];

					permissaoInternacional.dadosPcad.naturalidade = newNaturalidade;
				}

				if (
					!!permissaoInternacional.dadosPcad.enderecoPcad.municipio &&
					permissaoInternacional.dadosPcad.enderecoPcad.municipio !== ''
				) {
					const newNaturalidade = permissaoInternacional.dadosPcad.enderecoPcad.municipio.split(
						' - ',
					)[1];

					permissaoInternacional.dadosPcad.enderecoPcad.municipio = newNaturalidade;
				}

				draft.data = permissaoInternacional;
				break;
			}

			case Types.CONSULTA_PERMISSAO_INTERNACIONAL_FAILURE: {
				break;
			}

			default:
		}
	});
}
