import React, { useCallback, useEffect, useState } from 'react';

import { Modal, Row, Col } from 'antd';
import { FormikProps } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultarTipoModificacaoAlteracaoRequest,
	consultarTipoModificacaoAlteracaoClear,
} from 'store/modules/api/detranCrv/enum/consultarTipoModificacaoAlteracao/actions';
import { TipoModificacaoAlteracao } from 'store/modules/api/detranCrv/enum/consultarTipoModificacaoAlteracao/types';

// COMPONENTS
import Button from 'components/Common/Button';
import SimpleTable from 'components/Common/Table';

// FORM
import { IAutorizacaoPrevia } from '../form';

export interface IBuscarModificaoAlteracaoResultado {
	codigo: number;
	descricao: string;
	idAlteracao: number;
}

interface Props {
	formik: FormikProps<IAutorizacaoPrevia>;
	disabled: boolean;
	result: (data: IBuscarModificaoAlteracaoResultado) => void;
}

const ButtonPesquisaTipoAutorizacao: React.FC<Props> = ({
	formik,
	disabled,
	result,
}) => {
	const dispatch = useDispatch();

	const { consultarTipoModificacaoAlteracao } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.Enum,
	);

	const [showModal, setShowModal] = useState<boolean>(false);

	const handleShowModal = useCallback(() => setShowModal(true), [setShowModal]);

	const handleHideModal = useCallback(() => {
		setShowModal(false);
		dispatch(consultarTipoModificacaoAlteracaoClear());
	}, [setShowModal, dispatch]);

	useEffect(() => {
		if (
			consultarTipoModificacaoAlteracao.data &&
			consultarTipoModificacaoAlteracao.data.length > 0
		) {
			handleShowModal();
		}
	}, [consultarTipoModificacaoAlteracao.data, handleShowModal]);

	const handlePesquisarTipoAutorizacao = useCallback(
		(tipoAutorizacaoPrevia: string) => {
			if (tipoAutorizacaoPrevia) {
				dispatch(
					consultarTipoModificacaoAlteracaoRequest(tipoAutorizacaoPrevia),
				);
			}
		},
		[dispatch],
	);

	const handleSelected = useCallback(
		(res: IBuscarModificaoAlteracaoResultado) => {
			result(res);
			handleHideModal();
		},
		[result, handleHideModal],
	);

	return (
		<>
			<Button
				type="button"
				disabled={disabled}
				onClick={() =>
					handlePesquisarTipoAutorizacao(formik.values.tipoAutorizacaoPrevia)
				}
			>
				Pesquisar Tipo Autorização
			</Button>

			<Modal
				title="Busca de Modificação de Alteração"
				visible={showModal}
				onCancel={handleHideModal}
				width={670}
				footer={null}
				style={{ animationDuration: '0s' }}
				destroyOnClose
			>
				{consultarTipoModificacaoAlteracao.data &&
					consultarTipoModificacaoAlteracao.data.length > 0 && (
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<SimpleTable
									headers={[
										{
											title: 'Código',
											dataIndex: 'codigo',
											align: 'center',
											render: (
												text: TipoModificacaoAlteracao,
												record: TipoModificacaoAlteracao,
											) => {
												return `${record.codigo} - ${record.idAlteracao}`;
											},
										},
										{
											title: 'Descrição',
											dataIndex: 'descricao',
										},
									]}
									body={consultarTipoModificacaoAlteracao.data}
									onClick={handleSelected}
								/>
							</Col>
						</Row>
					)}
			</Modal>
		</>
	);
};

export default ButtonPesquisaTipoAutorizacao;
