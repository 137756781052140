import { combineReducers } from 'redux';

import escolherPlaca from './EscolherPlaca/reducer';
import primeiroEmplacamento from './PrimeiroEmplacamento/reducer';
import licenciamentoVeiculo from './LicenciamentoVeiculo/reducer';
import transferenciaPropietario from './TransferenciaPropietario/reducer';
import cancelarAutorizacaoPrevia from './CancelarAutorizacaoPrevia/reducer';
import consultarAutorizacaoPrevia from './ConsultarAutorizacaoPrevia/reducer';
import consultarDetalhesAutorizacaoPrevia from './consultarDetalhesAutorizacaoPrevia/reducer';
import consultarVeiculoV110 from './ConsultarVeiculoV110/reducer';
import consultaPlacaDisponivel from './consultaPlacaDisponivel/reducer';
import consultaUnidadeTransito from './consultaUnidadeTransito/reducer';
import confirmarPlaca from './confirmarPlaca/reducer';
import imprimirCrvle from './imprimirCrvle/reducer';
import emitirAutorizacaoPrevia from './emitirAutorizacaoPrevia/reducer';
import consultarVeiculoV120 from './ConsultarVeiculoV120/reducer';
import primeiroEmplacamentoEmissao from './primeiroEmplacamentoEmissao/reducer';
import emitirSegundaViaCrv from './emitirSegundaViaCRV/reducer';
import emitirBaixaVeiculo from './emitirBaixaVeiculo/reducer';
import emitirAlteracaoDadosCadastrais from './emitirAlteracaoDadosCadastrais/reducer';
import emitirModificacaoDadosCadastrais from './emitirModificacaoDadosCadastrais/reducer';
import emitirTransferenciasMunicipiosEstados from './emitirTransferenciasMunicipiosEstados/reducer';
import emitirTransferenciasProprietarios from './emitirTransferenciasProprietarios/reducer';
import emitirTransferenciaMunicipio from './emitirTransferenciaMunicipio/reducer';
import emitirTrocaVoluntariaPlaca from './emitirTrocaVoluntariaPlaca/reducer';
import emitirEscolhaPlacaPersonalizada from './emitirEscolhaPlacaPersonalizada/reducer';
import emitirSegundaViaPlaca from './emitirSegundaViaPlaca/reducer';
import consultarVeiculoSegundaViaPlaca from './consultarVeiculoSegundaViaPlaca/reducer';
import cancelarPlacaPrimeiroEmplacamento from './cancelarPlacaPrimeiroEmplacamento/reducer';
import tipoArquivoEmissao from './tipoArquivoEmissao/reducer';
import consultarAcompanhamento from './ConsultarAcompanhamento/reducer';
import downloadDocumentos from './DownloadDocumentos/reducer';
import reenviarDocumentos from './reenviarDocumentos/reducer';
import consultaRestricoes from './desbloqueio/consultaRestricoes/reducer';
import registraSolicitacoes from './desbloqueio/registraSolicitacoes/reducer';

export default combineReducers({
	escolherPlaca,
	primeiroEmplacamento,
	licenciamentoVeiculo,
	transferenciaPropietario,
	cancelarAutorizacaoPrevia,
	consultarAutorizacaoPrevia,
	consultarDetalhesAutorizacaoPrevia,
	consultarVeiculoV110,
	consultaPlacaDisponivel,
	consultaUnidadeTransito,
	confirmarPlaca,
	imprimirCrvle,
	emitirAutorizacaoPrevia,
	consultarVeiculoV120,
	primeiroEmplacamentoEmissao,
	emitirSegundaViaCrv,
	emitirBaixaVeiculo,
	emitirAlteracaoDadosCadastrais,
	emitirModificacaoDadosCadastrais,
	emitirTransferenciasMunicipiosEstados,
	emitirTransferenciasProprietarios,
	emitirTransferenciaMunicipio,
	emitirTrocaVoluntariaPlaca,
	emitirEscolhaPlacaPersonalizada,
	emitirSegundaViaPlaca,
	consultarVeiculoSegundaViaPlaca,
	cancelarPlacaPrimeiroEmplacamento,
	tipoArquivoEmissao,
	consultarAcompanhamento,
	downloadDocumentos,
	reenviarDocumentos,
	consultaRestricoes,
	registraSolicitacoes,
});
