import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { Types, CadastrarMunicipioIbgeRequest } from './types';

// ACTIONS
import {
	cadastrarMunicipioIbgeSuccess,
	cadastrarMunicipioIbgeFailure,
} from './actions';

function* cadastrarMunicipioIbgeRequest(request: ReducerAction) {
	const { payload }: { payload: CadastrarMunicipioIbgeRequest } = request;

	let body = { ...payload };

	body = cleanEmpty(body) as CadastrarMunicipioIbgeRequest;

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU,
		`municipios-ibge`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarMunicipioIbgeSuccess(response));
	} else {
		yield put(cadastrarMunicipioIbgeFailure());
	}
}

export default all([
	takeLatest(
		Types.CADASTRAR_MUNICIPIO_IBGE_REQUEST,
		cadastrarMunicipioIbgeRequest,
	),
]);
