import * as Yup from 'yup';
import { CancelarIntencaoVendaRequest } from 'store/modules/api/detranCrv/IntencaoDeVenda/Cancelar/types';

interface ILocalidade {
	id: number;
	desc: string;
}

export const initialValues: CancelarIntencaoVendaRequest = {
	placa: '',
	renavam: '',
	chassi: '',
	numeroATPVE: '',
	codigoDespachante: '',
};

export function treatValues(
	values: CancelarIntencaoVendaRequest,
): CancelarIntencaoVendaRequest {
	const formattedData = {
		placa: values.placa?.trim() || '' || '',
		renavam: values.renavam?.trim() || '',
		chassi: values.chassi?.trim() || '',
		numeroATPVE: values.numeroATPVE?.trim() || '',
		codigoDespachante: '88888',
	};

	return formattedData;
}

export const schema = Yup.object<CancelarIntencaoVendaRequest>().shape({
	placa: Yup.string().required(`Campo Obrigatório: Placa.`),
	renavam: Yup.string().required(`Campo Obrigatório: Renavam.`),
	chassi: Yup.string().required(`Campo Obrigatório: Chassi.`),
	numeroATPVE: Yup.string().required(
		`Campo Obrigatório: Número Autorização Venda.`,
	),
});
