import { ReducerAction } from 'store/modules/types';

import { Types, AlteraSenhaDetranRequest } from './types';

export function alteraSenhaDetranRequest(
	data: AlteraSenhaDetranRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_SENHA_DETRAN_REQUEST,
		payload: data,
	};
}
export function alteraSenhaDetranSuccess(payload: object): ReducerAction {
	return {
		type: Types.ALTERA_SENHA_DETRAN_SUCCESS,
		payload,
	};
}
export function alteraSenhaDetranFailure(payload: object): ReducerAction {
	return {
		type: Types.ALTERA_SENHA_DETRAN_FAILURE,
		payload,
	};
}

export function alteraSenhaDetranClear(payload: object): ReducerAction {
	return {
		type: Types.ALTERA_SENHA_DETRAN_CLEAR,
		payload,
	};
}
