import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AlteraSenha } from './types';

export const INITIAL_STATE: AlteraSenha = {
	status: 0,
	mensagem: '',
	form: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): AlteraSenha {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ALTERA_SENHA_REQUEST: {
				draft.status = 100;
				draft.form = action.payload;
				draft.mensagem = INITIAL_STATE.mensagem;
				break;
			}

			case Types.ALTERA_SENHA_SUCCESS: {
				draft.status = 200;
				break;
			}

			case Types.ALTERA_SENHA_FAILURE: {
				draft.status = 400;

				break;
			}
			case Types.ALTERA_SENHA_CLEAR: {
				draft.status = 0;
				draft.form = INITIAL_STATE.form;
				draft.mensagem = INITIAL_STATE.mensagem;
				break;
			}

			default:
		}
	});
}
