import React from 'react';

import { Container } from './styles';

interface dividerProps {
	mtSize?: number;
	mbSize?: number;
}

const Divider: React.FC<dividerProps> = ({ mtSize, mbSize }) => {
	return <Container mtSize={mtSize} mbSize={mbSize} />;
};

export default Divider;
