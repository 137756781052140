import produce from 'immer';

// TYPES
import { Types, ExcluirLocalidade } from './types';
import { ReducerAction } from '../../../../../types';

export const INITIAL_STATE: ExcluirLocalidade = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ExcluirLocalidade {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EXCLUIR_LOCALIDADE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.EXCLUIR_LOCALIDADE_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.EXCLUIR_LOCALIDADE_FAILURE: {
				draft.status = 400;

				break;
			}

			case Types.EXCLUIR_LOCALIDADE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;

				break;
			}

			default:
		}
	});
}
