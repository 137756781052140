import { ApiResponse } from 'services/_api';
import { ReducerAction } from 'store/modules/types';

import { AlteraSenhaUsuarioRequest, Types } from './types';

export function alteraSenhaUsuarioRequest(
	data: AlteraSenhaUsuarioRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_SENHA_USUARIO_REQUEST,
		payload: data,
	};
}
export function alteraSenhaUsuarioSuccess(payload: ApiResponse): ReducerAction {
	return {
		type: Types.ALTERA_SENHA_USUARIO_SUCCESS,
		payload,
	};
}
export function alteraSenhaUsuarioFailure(payload: string): ReducerAction {
	return {
		type: Types.ALTERA_SENHA_USUARIO_FAILURE,
		payload,
	};
}
export function alteraSenhaUsuarioClear(): { type: string } {
	return {
		type: Types.ALTERA_SENHA_USUARIO_CLEAR,
	};
}
