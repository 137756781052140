export enum Types {
	CONSULTAR_MOTIVOS = '@SGU/CONSULTAR_MOTIVOS',
	CONSULTAR_MOTIVOS_SUCCESS = '@SGU/CONSULTAR_MOTIVOS_SUCCESS',
	CONSULTAR_MOTIVOS_FAILURE = '@SGU/CONSULTAR_MOTIVOS_FAILURE',
	CONSULTAR_MOTIVOS_CLEAR = '@SGU/CONSULTAR_MOTIVOS_CLEAR',
}

export interface ConsultarMotivos {
	status: number;
	data: Motivo[];
	totalRegistrosConsulta: number;
}

export interface Motivo {
	id: number;
	codigo: number;
	descricao: string;
}

export interface ConsultarMotivosRequest {
	registroInicial?: number;
	limite?: number;
	current?: number;
	id?: number | string;
	codigo?: number | string;
	descricao?: string;
}
