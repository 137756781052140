import { ReducerAction } from 'store/modules/types';

import { Types, IPTRESRequest } from './types';

export function consultarPTRESRequest(data: IPTRESRequest): ReducerAction {
	return {
		type: Types.PTRES_REQUEST,
		payload: data,
	};
}

export function consultarPTRESSuccess(payload: object) {
	return {
		type: Types.PTRES_SUCCESS,
		payload,
	};
}

export function consultarPTRESFailure(payload: object): ReducerAction {
	return {
		type: Types.PTRES_FAILURE,
		payload,
	};
}

export function consultarPTRESClear(): any {
	return {
		type: Types.PTRES_CLEAR,
	};
}
