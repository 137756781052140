import produce from 'immer';

// TYPES
import { Types, CancelarAgendamentoPsicologo } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: CancelarAgendamentoPsicologo = {
	status: 0,
	data: null,
};

export default function cancelarAgendamentoPsicologoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): CancelarAgendamentoPsicologo {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CANCELAR_AGENDAMENTO_PSICOLOGO_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.CANCELAR_AGENDAMENTO_PSICOLOGO_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = action.payload.status;
				break;
			}
			case Types.CANCELAR_AGENDAMENTO_PSICOLOGO_FAILURE: {
				draft.status = action.payload.data;
				draft.status = action.payload.status;
				break;
			}
			case Types.CANCELAR_AGENDAMENTO_PSICOLOGO_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				break;
			}
			default:
		}
	});
}
