import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_VIVA_LEITE } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	Types,
	IRequestDocumentos,
	IRequestCadastraDocumento,
	IRequestStatusDocumentos,
	IRequestTermoAdvertencia,
	IRequestImpressao,
	IRequestCadastraTermoAdvertencia,
	IRequestImpressaoDocumentos,
} from './types';

// ACTIONS
import {
	consultaDocumentosFailure,
	consultaDocumentosSuccess,
	cadastraDocumentoFailure,
	cadastraDocumentoSuccess,
	consultaStatusDocumentosFailure,
	consultaStatusDocumentosSuccess,
	consultaTermoAdvertenciaFailure,
	consultaTermoAdvertenciaSuccess,
	imprimeTermoAdvertenciaFailure,
	imprimeTermoAdvertenciaSuccess,
	cadastraTermoAdvertenciaFailure,
	cadastraTermoAdvertenciaSuccess,
	consultaImpressaoDocumentosFailure,
	consultaImpressaoDocumentosSuccess,
	consultaEfetivaImpressaoDocumentosFailure,
	consultaEfetivaImpressaoDocumentosSuccess,
} from './actions';

const evento = {
	id_atendimento: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
	id_cidadao: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
	cpf: '94177708534',
	identificacao: '94177708534',
	ip: '192.168.1.1',
	canal: {
		id: 1,
		desc: 'Presencial',
		localidade: {
			id: 900,
			desc: 'SE',
		},
	},
};

function* consultaDocumentos(request: ReducerAction) {
	const { payload }: { payload: IRequestDocumentos } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/envia-documento`,
		{
			...payload,
			evento,
		},
	);

	if (response.status === 200) {
		yield put(consultaDocumentosSuccess(response));
	} else if (response.error) {
		yield put(consultaDocumentosFailure());
	}
}

function* cadastraDocumento(request: ReducerAction) {
	const { payload }: { payload: IRequestCadastraDocumento } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/envia-documento`,
		{
			...payload,
			evento,
		},
	);

	if (response.status === 200) {
		yield put(cadastraDocumentoSuccess(response));
	} else if (response.error) {
		yield put(cadastraDocumentoFailure());
	}
}

function* consultaStatusDocumentos(request: ReducerAction) {
	const { payload }: { payload: IRequestStatusDocumentos } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/status-documentos`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaStatusDocumentosSuccess(response));
	} else if (response.error) {
		yield put(consultaStatusDocumentosFailure());
	}
}

function* consultaTermoAdvertencia(request: ReducerAction) {
	const { payload }: { payload: IRequestTermoAdvertencia } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/termo-advertencia`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaTermoAdvertenciaSuccess(response));
	} else if (response.error) {
		yield put(consultaTermoAdvertenciaFailure());
	}
}

function* imprimeTermoAdvertencia(request: ReducerAction) {
	const { payload }: { payload: IRequestImpressao } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/impressao-documentos`,
		{
			...payload,
			evento,
		},
	);

	if (response.status === 200) {
		yield put(imprimeTermoAdvertenciaSuccess(response));
	} else if (response.error) {
		yield put(imprimeTermoAdvertenciaFailure());
	}
}

function* cadastraTermoAdvertencia(request: ReducerAction) {
	const { payload }: { payload: IRequestCadastraTermoAdvertencia } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/termo-advertencia`,
		payload,
	);

	if (response.status === 200) {
		yield put(cadastraTermoAdvertenciaSuccess(response));
	} else if (response.error) {
		yield put(cadastraTermoAdvertenciaFailure());
	}
}

function* consultaImpressaoDocumentos(request: ReducerAction) {
	const { payload }: { payload: IRequestImpressaoDocumentos } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/impressao-documentos`,
		{
			...payload,
			evento,
		},
	);

	if (response.status === 200) {
		yield put(consultaImpressaoDocumentosSuccess(response));
	} else if (response.error) {
		yield put(consultaImpressaoDocumentosFailure());
	}
}

function* consultaEfetivaImpressaoDocumentos(request: ReducerAction) {
	const { payload }: { payload: IRequestImpressaoDocumentos } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/impressao-documentos`,
		{
			...payload,
			evento,
		},
	);

	if (response.status === 200) {
		yield put(consultaEfetivaImpressaoDocumentosSuccess(response));
	} else if (response.error) {
		yield put(consultaEfetivaImpressaoDocumentosFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTA_DOCUMENTOS, consultaDocumentos),
	takeLatest(Types.CADASTRA_DOCUMENTO, cadastraDocumento),
	takeLatest(Types.CONSULTA_STATUS_DOCUMENTOS, consultaStatusDocumentos),
	takeLatest(Types.CONSULTA_TERMO_ADVERTENCIA, consultaTermoAdvertencia),
	takeLatest(Types.IMPRIME_TERMO_ADVERTENCIA, imprimeTermoAdvertencia),
	takeLatest(Types.CADASTRA_TERMO_ADVERTENCIA, cadastraTermoAdvertencia),
	takeLatest(Types.CONSULTA_IMPRESSAO_DOCUMENTOS, consultaImpressaoDocumentos),
	takeLatest(
		Types.CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS,
		consultaEfetivaImpressaoDocumentos,
	),
]);
