import * as Yup from 'yup';
import { EnviaInteracoesAtendimentoForm } from 'store/modules/api/procon/interacoes/atendimentos/enviaInteracoes/types';

export const initialValues: EnviaInteracoesAtendimentoForm = {
	respostaConsumidor: '',
	anexos: [],
};

export const schema = Yup.object().shape({
	respostaConsumidor: Yup.string().min(
		50,
		'Campo Obrigatório: Mínimo de 50 caracteres para a resposta do consumidor.',
	),
});
