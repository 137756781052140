import { CadastrarUsuario } from './cadastrarUsuario/types';
import { ConsultarUsuarios } from './consultarUsuarios/types';
import { ConsultarUsuario } from './consultarUsuario/types';
import { CadastrarUsuarioMainframe } from './cadastrarUsuarioMainframe/types';
import { AtualizarUsuario } from './atualizarUsuario/types';
import { ExcluirLocalidadesRelacionadas } from './excluirLocalidadesRelacionadas/types';
import { ExcluirUsuario } from './excluirUsuario/types';
import { TrocarSenhaUsuario } from './trocarSenhaUsuario/types';
import { ExcluirUsuarioMainframe } from './excluirUsuarioMainframe/types';
import { ResetarSenhaUsuarioMainframe } from './resetarSenhaUsuarioMainframe/types';

export enum Types {
	SGU_CLEAR = '@sgu/SGU_CLEAR',
}

export interface UsuarioState {
	consultaUsuarios: ConsultarUsuarios;
	consultarUsuario: ConsultarUsuario;
	cadastrarUsuario: CadastrarUsuario;
	cadastrarUsuarioMainframe: CadastrarUsuarioMainframe;
	atualizarUsuario: AtualizarUsuario;
	excluirLocalidadesRelacionadas: ExcluirLocalidadesRelacionadas;
	excluirUsuario: ExcluirUsuario;
	trocarSenhaUsuario: TrocarSenhaUsuario;
	excluirUsuarioMainframe: ExcluirUsuarioMainframe;
	resetarSenhaUsuarioMainframe: ResetarSenhaUsuarioMainframe;
}
