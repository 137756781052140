import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export function consultaCanaisRequest(): ReducerAction {
	return {
		type: Types.CONSULTA_CANAIS,
		payload: null,
	};
}
export function consultaCanaisSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_CANAIS_SUCCESS,
		payload,
	};
}
export function consultaCanaisFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_CANAIS_FAILURE,
		payload,
	};
}
