import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const PersonalizedLink = styled(Link)`
	color: ${props => props.theme.typography.primary};

	&:hover {
		color: ${props => props.theme.typography.primary};
	}
`;
