import { ReducerAction } from 'store/modules/types';
import { RegistraSolicitacoesRequest, Types } from './types';

export function registraSolicitacoesRequest(
	payload: RegistraSolicitacoesRequest,
) {
	return {
		type: Types.REGISTRA_SOLICITACOES_REQUEST,
		payload,
	};
}
export function registraSolicitacoesSuccess(payload: object): ReducerAction {
	return {
		type: Types.REGISTRA_SOLICITACOES_SUCCESS,
		payload,
	};
}
export function registraSolicitacoesFailure(payload: object): ReducerAction {
	return {
		type: Types.REGISTRA_SOLICITACOES_FAILURE,
		payload,
	};
}
export function registraSolicitacoesClear(): ReducerAction {
	return {
		type: Types.REGISTRA_SOLICITACOES_CLEAR,
		payload: null,
	};
}
