export enum Types {
	MULTAS_RENAINF_ORGAO_REQUEST = '@DETRAN-CRV/MULTAS_RENAINF_ORGAO_REQUEST',
	MULTAS_RENAINF_ORGAO_SUCCESS = '@DETRAN-CRV/MULTAS_RENAINF_ORGAO_SUCCESS',
	MULTAS_RENAINF_ORGAO_FAILURE = '@DETRAN-CRV/MULTAS_RENAINF_ORGAO_FAILURE',
	MULTAS_RENAINF_ORGAO_CLEAR = '@DETRAN-CRV/MULTAS_RENAINF_ORGAO_CLEAR',
}

export interface multasRenainfOrgao {
	status: number;
	data: dadosRenainfOrgao | null;
}

interface dadosRenainfOrgao {
	dadosRenainfOrgao: {
		aceiteUfJurisdicaoSuspensaoCancelamento: string;
		cnhCondutor: number;
		cnhInfrator: number;
		codigoInfracao: string;
		dataAceiteUfHabilitacao: string;
		dataCadastroInfracao: string;
		dataEmissao: string;
		dataEmissaoPenalidade: string;
		dataHoraInfracao: string;
		dataNotificacaoInfracao: string;
		dataPagamentoInfracao: string;
		dataRegistroPagamento: string;
		dataRegistroSuspensaoCancelamento: string;
		indicadorExigibilidade: string;
		limitePermitido: number;
		localInfracao: string;
		marca: {
			longId: number;
			descricao: string;
		};
		medicaoConsiderada: number;
		medicaoReal: number;
		municipioEmplacamento: {
			codigo: number;
			nome: string;
		};
		municipioInfracao: {
			codigo: number;
			nome: string;
		};
		numeroAutoInfracao: string;
		orgaoAutuador: string;
		origemSuspensaoCancelamento: number;
		tipoInfracao: string;
		tipoSuspensaoCancelamento: number;
		ufJurisdicaoVeiculo: string;
		ufOrgaoAutuador: string;
		ufPagamento: string;
		unidadeMedida: string;
		valorInfracao: number;
		valorPago: number;
		ufEmplacamento: string;
		placa: string;
	};
}
export interface IRequestConsultaRenainfOrgao {
	evento: IEvento;
	codigoOrgaoAutuador: string;
	numeroInfracao: string;
	codigoInfracao: string;
}
export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
}
