import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, EmissaoAtestadoLiberacaoPositiva } from './types';

export const INITIAL_STATE: EmissaoAtestadoLiberacaoPositiva = {
	status: 0,
	data: { pdfBase64: '', nomeSocial: '' },
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): EmissaoAtestadoLiberacaoPositiva {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EMISSAO_ATESTADO_LIBERACAO_POSITIVA_REQUEST: {
				break;
			}

			case Types.EMISSAO_ATESTADO_LIBERACAO_POSITIVA_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}

			case Types.EMISSAO_ATESTADO_LIBERACAO_POSITIVA_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.EMISSAO_ATESTADO_LIBERACAO_POSITIVA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}

		return draft;
	});
}
