export enum Types {
	CONSULTA_RENOVACAO_CNH_REQUEST = '@cnh/CONSULTA_RENOVACAO_CNH_REQUEST',
	CONSULTA_RENOVACAO_CNH_SUCCESS = '@cnh/CONSULTA_RENOVACAO_CNH_SUCCESS',
	CONSULTA_RENOVACAO_CNH_FAILURE = '@cnh/CONSULTA_RENOVACAO_CNH_FAILURE',
	CONSULTA_RENOVACAO_CNH_CLEAR = '@cnh/CONSULTA_RENOVACAO_CNH_CLEAR',
}

export interface ConsultaRenovacaoCnh {
	status: number;
	data: RenovacaoCnhData | null;
	dataError: RenovacaoCnhDataError | null;
	requestData: ConsultaRenovacaoRequest | null;
}

export interface RenovacaoCnhData {
	cpf: string;
	numeroRg: string;
	numeroRegistro: string;
	numeroPgu: string;
	nome: string;
	renach: string;
	local: string;
	categoriaPretendida: string;
	cfcA: string;
	cfcB: string;
	espelhoCnh: string;
	usoForm: string;
	situacaoAtual: string;
	espelhoPid: string;
	nomePai: string;
	dataPrimeiraHabilitacao: string;
	dataValidadeCnh: string;
	dataNascimento: string;
	nomeMae: string;
	sexo: string;
	nacionalidade: string;
	tipoDocumento: string;
	orgaoEmissor: string;
	localEmissao: string;
	rne1: string;
	rne2: string;
	rne3: string;
	naturalidade: string;
	identificacaoHabilitacao: string;
	pais: string;
	dataValidade: string;
	dataUltimaEmissaoCnh: string;
	dataExameTeorico: string;
	codigoExameTeorico: string;
	codigoEntidade: string;
	descricaoEntidade: string;
	dataExamePratico2Rodas: string;
	codigoExamePratico2Rodas: string;
	dataExamePratico4Rodas: string;
	codigoExamePratico4Rodas: string;
	cepPcon: string;
	cepPcad: string;
	flagAtividadeRemunerada: boolean;
	flagDeficienteFisico: boolean;
	flagExameToxicologico?: boolean | string;
	endereco: {
		codigo: string;
		logradouro: string;
		numero: string;
		complemento: string;
		cep: string;
		bairro: string;
		descricaoMunicipio: string;
		codigoMunicipio: string;
	};
	dadosPcad: {
		cpf: string;
		nome: string;
		numRg: string;
		orgaoEmissor: string;
		localEmissao: string;
		dataNascimento: string;
		rne: string;
		orgaoEmissorRne: string;
		localEmissaoRne: string;
		codigoPais: string;
		descricaoPais: string;
		validadeHabilitacaoEstrangeira: string;
		sexo: string;
		numRegistro: string;
		numPgu: string;
		nacionalidade: string;
		naturalidade: string;
		codNaturalidade: string;
		nomePai: string;
		nomeMae: string;
		endereco: {
			codigo: string;
			logradouro: string;
			numero: string;
			complemento: string;
			cep: string;
			bairro: string;
			descricaoMunicipio: string;
			codigoMunicipio: string;
		};
		processo: string;
		ciretran: string;
		categoriaAtual: string;
		categoriaPretendida: string;
		dataCadastro: string;
		horaCadastro: string;
		usuarioCadastro: string;
	};
	dadosExameMedico: {
		dataExameMedico: string;
		exameMedico: string;
		categoriaPermitida: string;
		restricaoMedica: string;
		aptidoInaptoTemporario: string;
		resultado: string;
		cred1: string;
		cred2: string;
		crm: string;
		cpf: string;
		regiao: string;
		aptidaoMedica: string;
		aptoAte: string;
		exame: string;
	};
	dadosExamePsicotecnico: {
		dataExamePsicotecnico: string;
		cpf: string;
		exame1: string;
		exame2: string;
		laudo1: string;
		laudo2: string;
		resultado1: string;
		resultado2: string;
		examePsicotecnico1: string;
		examePsicotecnico2: string;
		aptidoInaptoTemporario: string;
		aptidaoPsicotecnica: string;
		cred1: string;
		cred2: string;
		crp: string;
		regiao: string;
		aptoAte: string;
	};
	cursos: ListaCursos[];
	ocorrencias: ListaOcorrencias[];
	mensagensAlerta: string[];
}

export interface RenovacaoCnhDataError {
	transacao: string;
	codigo: number;
	mensagem: string;
}

interface ListaOcorrencias {
	sequencia: string;
	tipo: string;
	informacao: string;
}

interface ListaCursos {
	codigo: string;
	descricao: string;
	dataValidadade: string;
}

export interface ConsultaRenovacaoRequest {
	cpf?: string;
	numeroRg?: string;
	numeroRegistro?: string;
	numeroPgu?: string;
	nome?: string;
	dataNascimento?: string;
	usuario: string;
	senha: string;
	flagAtividadeRemunerada?: boolean;
}
