import { validaCpf } from 'utils/genericFunctions';
import * as Yup from 'yup';

export interface CadastroProcuradorOuResponsavelForm {
	nomeDoResponsavelOuProcurador: string;
	cpfDoProcuradorOuResponsavel: string;
	rgDoResponsavelOuProcurador: string;
}

export const initialValues: CadastroProcuradorOuResponsavelForm = {
	nomeDoResponsavelOuProcurador: '',
	cpfDoProcuradorOuResponsavel: '',
	rgDoResponsavelOuProcurador: '',
};

export const cadastroProcuradorOuResponsaveSchema =
	Yup.object<CadastroProcuradorOuResponsavelForm>().shape({
		cpfDoProcuradorOuResponsavel: Yup.string()
			.required('Campo Obrigatório: CPF.')
			.test({
				name: 'cpfValidation',
				exclusive: true,
				message: 'CPF digitado inválido.',
				test: value => validaCpf(value),
			}),
		nomeDoResponsavelOuProcurador: Yup.string().required(
			'Campo Obrigatório: Nome.',
		),
		rgDoResponsavelOuProcurador: Yup.string().required(
			'Campo Obrigatório: RG.',
		),
	});
