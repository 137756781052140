import { all } from 'redux-saga/effects';
import consultaRenovacaoCnhSaga from './consultaRenovacaoCnh/saga';
import consultaPconSaga from './consultaPcon/saga';
import consultaCnhEstrangeiroSaga from './consultaCnhEstrangeiro/saga';
import consultaMudancaCategoriaSaga from './consultaMudancaCategoria/saga';
import consultaPermissionarioPontuadoSaga from './consultaPermissionarioPontuado/saga';
import consultaExtratoResumoCondutorSaga from './consultaExtratoResumoCondutor/saga';
import loginSoapSaga from './loginSoap/saga';
import consultaGforSaga from './consultaGfor/saga';
import logonRestSaga from './logonRest/saga';
import alteraSenhaDetran from './alteraSenhaDetran/saga';

export default all([
	consultaRenovacaoCnhSaga,
	consultaPconSaga,
	consultaCnhEstrangeiroSaga,
	consultaMudancaCategoriaSaga,
	consultaPermissionarioPontuadoSaga,
	consultaExtratoResumoCondutorSaga,
	loginSoapSaga,
	consultaGforSaga,
	logonRestSaga,
	alteraSenhaDetran,
]);
