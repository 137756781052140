import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_DETRAN_CRV, CHANNEL } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarTaxaServicoRequest } from './types';

// ACTIONS
import {
	consultarTaxaServicoSuccess,
	consultarTaxaServicoFailure,
} from './actions';

function* consultarTaxaServico(request: ReducerAction) {
	const { payload }: { payload: ConsultarTaxaServicoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`taxa/${CHANNEL}/pesquisa`,
		payload,
	);
	if (response.status === 200) {
		yield put(consultarTaxaServicoSuccess(response));
	} else {
		yield put(consultarTaxaServicoFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_TAXA_SERVICO, consultarTaxaServico),
]);
