import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import { estadosSuccess, estadosFailure } from './actions';

function* estadosRequest() {
	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`dominios-crm/${CHANNEL}/estados`,
		{},
	);

	if (response.status === 200) {
		yield put(estadosSuccess(response));
	} else {
		yield put(estadosFailure(response));
	}
}

export default all([takeLatest(Types.ESTADOS_REQUEST, estadosRequest)]);
