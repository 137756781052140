import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarContatoRequest } from './types';

export function atualizarContatoRequest(
	data: AtualizarContatoRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_CONTATO,
		payload: data,
	};
}
export function atualizarContatoSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_CONTATO_SUCCESS,
		payload,
	};
}
export function atualizarContatoFailure(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_CONTATO_FAILURE,
		payload,
	};
}
