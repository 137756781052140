import { all } from 'redux-saga/effects';

import gerarRenachSaga from './gerarRenach/saga';
import saveGerarRenachSaga from './saveGerarRenach/saga';
import gerarRenachSemPcadSaga from './gerarRenachSemPcad/saga';
import consultarInaptoSaga from './consultarInapto/saga';
import consultaPontosSaga from './consultaPontos/saga';
import solicitaCnhDigitalSaga from './solicitaCnhDigital/saga';
import consultarCondutorSaga from './consultarCondutor/saga';
import consultarCadastroCidadaoSaga from './consultarCadastroCidadao/saga';
import alterarRenachComRegistroSaga from './alterarRenachComRegistro/saga';
import alterarRenachSemRegistroSaga from './alterarRenachSemRegistro/saga';

export default all([
	gerarRenachSaga,
	saveGerarRenachSaga,
	gerarRenachSemPcadSaga,
	consultarInaptoSaga,
	consultaPontosSaga,
	solicitaCnhDigitalSaga,
	consultarCondutorSaga,
	consultarCadastroCidadaoSaga,
	alterarRenachComRegistroSaga,
	alterarRenachSemRegistroSaga,
]);
