import { ReducerAction } from 'store/modules/types';

import { Types, ConsultarDisponibilidadeMedicoPostoRequest } from './types';

export function consultarDisponibilidadeMedicoPostoRequest(
	data: ConsultarDisponibilidadeMedicoPostoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_REQUEST,
		payload: data,
	};
}
export function consultarDisponibilidadeMedicoPostoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_SUCCESS,
		payload,
	};
}
export function consultarDisponibilidadeMedicoPostoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_FAILURE,
		payload,
	};
}
export function consultarDisponibilidadeMedicoPostoClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_CLEAR,
		payload: null,
	};
}
