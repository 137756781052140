import { combineReducers } from 'redux';

import atualizarLocalidade from './atualizarLocalidade/reducer';
import cadastrarLocalidade from './cadastrarLocalidade/reducer';
import consultarLocalidades from './consultarLocalidades/reducer';
import consultarLocalidade from './consultarLocalidade/reducer';
import excluirLocalidade from './excluirLocalidade/reducer';
import tiposLocalidades from './tipos/combineReducers';

export default combineReducers({
	atualizarLocalidade,
	cadastrarLocalidade,
	consultarLocalidade,
	consultarLocalidades,
	excluirLocalidade,
	tiposLocalidades,
});
