// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	RequestConsultaUsuarioPendente,
	RequestAtualizarAtendente,
	ConsultaUsuarioPendente,
	Types,
} from './types';

export function consultaUsuarioPendenteRequest(
	payload: RequestConsultaUsuarioPendente,
): ReducerAction {
	return {
		type: Types.CONSULTA_USUARIO_PENDENTE,
		payload,
	};
}

export function consultaUsuarioPendenteSuccess(
	payload: ConsultaUsuarioPendente,
): ReducerAction {
	return {
		type: Types.CONSULTA_USUARIO_PENDENTE_SUCCESS,
		payload,
	};
}

export function consultaUsuarioPendenteFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_USUARIO_PENDENTE_FAILURE,
		payload: null,
	};
}

export function limpaUsuarioPendente(): ReducerAction {
	return {
		type: Types.LIMPA_USUARIO_PENDENTE,
		payload: null,
	};
}

export function atualizarAtendenteRequest(
	atualizacao: RequestAtualizarAtendente,
	resultado: ConsultaUsuarioPendente[],
): ReducerAction {
	return {
		type: Types.ATUALIZAR_ATENDENTE,
		payload: {
			atualizacao,
			resultado,
		},
	};
}

export function atualizarAtendenteSuccess(
	response: ConsultaUsuarioPendente,
	resultado: ConsultaUsuarioPendente[],
): ReducerAction {
	return {
		type: Types.ATUALIZAR_ATENDENTE_SUCCESS,
		payload: {
			response,
			resultado,
		},
	};
}

export function atualizarAtendenteFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_ATENDENTE_FAILURE,
		payload: null,
	};
}

export function limpaAtualizarAtendente(): ReducerAction {
	return {
		type: Types.LIMPA_ATUALIZAR_ATENDENTE,
		payload: null,
	};
}
