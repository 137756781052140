export enum Types {
	CONSULTA_PESQUISA_CIRG_REQUEST = '@iirgd/CONSULTA_PESQUISA_CIRG_REQUEST',
	CONSULTA_PESQUISA_CIRG_SUCCESS = '@iirgd/CONSULTA_PESQUISA_CIRG_SUCCESS',
	CONSULTA_PESQUISA_CIRG_FAILURE = '@iirgd/CONSULTA_PESQUISA_CIRG_FAILURE',
	CONSULTA_PESQUISA_CIRG_CIDADAO_SUCCESS = '@iirgd/CONSULTA_PESQUISA_CIRG_CIDADAO_SUCCESS',
	CONSULTA_PESQUISA_CIRG_CIDADAO_CLEAR = '@iirgd/CONSULTA_PESQUISA_CIRG_CIDADAO_CLEAR',

	CONSULTA_PESQUISA_CIRG_MAE_REQUEST = '@iirgd/CONSULTA_PESQUISA_CIRG_MAE_REQUEST',
	CONSULTA_PESQUISA_CIRG_MAE_SUCCESS = '@iirgd/CONSULTA_PESQUISA_CIRG_MAE_SUCCESS',
	CONSULTA_PESQUISA_CIRG_MAE_FAILURE = '@iirgd/CONSULTA_PESQUISA_CIRG_MAE_FAILURE',

	CONSULTA_PESQUISA_CIRG_CLEAR = '@iirgd/CONSULTA_PESQUISA_CIRG_CLEAR',
	CONSULTA_PESQUISA_MAE_CIRG_CLEAR = '@iirgd/CONSULTA_PESQUISA_MAE_CIRG_CLEAR',
}

export interface ConsultaPesquisaCirgState {
	data: ConsultaPesquisaCirg | null;
	status: number;
	cidadao: {
		status: number;
		data: ConsultaPesquisaCirg[] | null;
	};
	mae: {
		status: number;
		data: ConsultaPesquisaCirg[] | null;
	};
}

export interface ConsultaPesquisaCirg {
	argumento: string;
	numeroRg: string;
	nome: string;
	nomeSocial: string;
	nomePai: string;
	nomeMae: string;
	sexo: string;
	dataNascimento: string;
	maior: boolean;
	codigoNaturalidade: string;
	municipioNaturalidade: string;
	filiacaoTres: string;
	filiacaoQuatro: string;
	filiacaoCinco: string;
	filiacaoSeis: string;
	pidt: string;
	dbas: string;
	lote: string;
	comarca: string;
	cartorio: string;
	livro: string;
	folha: string;
	numero: string;
	dataDocumento: string;
	pMinLei1: string;
	pMinLei2: string;
	estadoCivil: string;
	cutis: string;
	cabelos: string;
	olhos: string;
	alturaM: string;
	alturaCm: string;
	grauInstrucao: string;
	profissao: string;
	enderecoResidencia: string;
	complementoResidencia: string;
	numeroResidencia: string;
	bairroResidencia: string;
	codigoMunicipioResidencia: string;
	descMunicipioResidencia: string;
	ufResidencia: string;
	cepResidencia: string;
	enderecoTrabalho: string;
	complementoTrabalho: string;
	bairroTrabalho: string;
	numeroTrabalho: string;
	codigoMunicipioTrabalho: string;
	descMunicipioTrabalho: string;
	cepTrabalho: string;
	solicitacao: string;
	foneResidencia: string;
	foneComercial: string;
	celular: string;
	operadora: string;
	email: string;
	identificadoAssina: string;
	tipoSanguineo: string;
	cid1: string;
	cid2: string;
	cid3: string;
	cid4: string;
	pis: string;
	ctpsNum: string;
	ctpsSerie: string;
	ctpsSerieUf: string;
	certidaoMilitar: string;
	nascSaude: string;
	pasep: string;
	dni: string;
	tituloEleitor: string;
	cnh: string;
	categoriaCnh: string;
	doador: boolean;
	identProf1: string;
	orgaoEmissor1: string;
	identProf2: string;
	orgaoEmissor2: string;
	identProf3: string;
	orgaoEmissor3: string;
	cpf: string;
	carimboMaior65: string;
	motivoFaltaAssinatura: string;
	sedex: string | null;
	indicadorDeficienciaFisica?: boolean;
	indicadorDeficienciaAuditiva?: boolean;
	indicadorDeficienciaIntelectual?: boolean;
	indicadorDeficienciaVisual?: boolean;
	indicadorAutismo?: boolean;
}
