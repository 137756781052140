import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarTiposRequest } from './types';

export function cadastrarTiposRequest(
	data: CadastrarTiposRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_TIPOS,
		payload: data,
	};
}
export function cadastrarTiposSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_TIPOS_SUCCESS,
		payload,
	};
}
export function cadastrarTiposFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_TIPOS_FAILURE,
		payload: null,
	};
}
export function cadastrarTiposClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_TIPOS_CLEAR,
		payload: null,
	};
}
