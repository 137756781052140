import { ReducerAction } from 'store/modules/types';

import { Types, IRequestAgendarExamesRenovacao } from './types';

export function agendarExamesRenovacaoRequest(
	data: IRequestAgendarExamesRenovacao,
): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_RENOVACAO,
		payload: data,
	};
}
export function agendarExamesRenovacaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_RENOVACAO_SUCCESS,
		payload,
	};
}
export function agendarExamesRenovacaoFailure(payload: boolean): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_RENOVACAO_FAILURE,
		payload,
	};
}
