import React, { useCallback, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import {
	dadosAgendamentoClear,
	dadosAgendamentoRequest,
} from 'store/modules/api/sgu/atendimento/dadosAgendamento/actions';

// COMPONENTS
import DadosAgendamento from 'components/Pages/DadosAgendamento';
import {
	DadosAgendamentoValues,
	treatValues,
} from 'components/Pages/DadosAgendamento/form';

// PATHS
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { ApplicationState } from 'store';
import { ROUTE_IIRGD_RECUPERAR_ATENDIMENTO } from '../routes/paths';
import { ROUTE_IIRGD_CIN_EMITIR } from '../EmitirCIN/routes/paths';

const DadosDoAgendamento: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { redirecionamento } = useSelector(
		(state: ApplicationState) => state.atendimento,
	);

	const handleSubmit = useCallback(
		(values: DadosAgendamentoValues) => {
			const treatedPayload = treatValues(values);
			dispatch(dadosAgendamentoRequest(treatedPayload));

			if (redirecionamento === ROUTE_IIRGD_CIN_EMITIR) {
				history.push(redirecionamento);
				return;
			}

			history.push(ROUTE_IIRGD_RECUPERAR_ATENDIMENTO);
		},
		[dispatch, history, redirecionamento],
	);

	useEffect(() => {
		dispatch(dadosAgendamentoClear());
		dispatch(clearNotifications());
		console.log('REDIRECIONAMENTO', redirecionamento, ROUTE_IIRGD_CIN_EMITIR);
	}, [dispatch, redirecionamento]);

	return <DadosAgendamento handleSubmit={handleSubmit} />;
};

export default DadosDoAgendamento;
