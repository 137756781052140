export enum Types {
	IMPRIMIR_DARE_REQUEST = '@dare/IMPRIMIR_DARE_REQUEST',
	IMPRIMIR_DARE_SUCCESS = '@dare/IMPRIMIR_DARE_SUCCESS',
	IMPRIMIR_DARE_FAILURE = '@dare/IMPRIMIR_DARE_FAILURE',
	IMPRIMIR_DARE_CLEAR = '@dare/IMPRIMIR_DARE_CLEAR',
}

export interface ImprimirDareState {
	status: number;
	data: ImprimirDareResponse;
}

export interface RequestImprimirDare {
	numeroDare: string;
}

interface ImprimirDareResponse {
	arquivoPDF: string;
	mensagem?: string;
	codigo?: number;
}
