import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirSufixoRequest } from './types';

export function excluirSufixoRequest(
	data: ExcluirSufixoRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_SUFIXO,
		payload: data,
	};
}
export function excluirSufixoSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_SUFIXO_SUCCESS,
		payload,
	};
}
export function excluirSufixoFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_SUFIXO_FAILURE,
		payload: null,
	};
}
export function excluirSufixoClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_SUFIXO_CLEAR,
		payload: null,
	};
}
