import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AlteraSenhaRequest } from './types';

// ACTIONS
import { alteraSenhaSuccess, alteraSenhaFailure } from './actions';

function* alteraSenhaRequest(request: ReducerAction) {
	const { payload }: { payload: AlteraSenhaRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		`alteraSenha`,
		payload,
	);

	if (response.status === 200 || response.status === 204) {
		yield put(alteraSenhaSuccess(response.data));
	} else {
		yield put(alteraSenhaFailure(response));
	}
}

export default all([
	takeLatest(Types.ALTERA_SENHA_REQUEST, alteraSenhaRequest),
]);
