import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirMunicipioIbgeRequest } from './types';

export function excluirMunicipioIbgeRequest(
	data: ExcluirMunicipioIbgeRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_MUNICIPIO_IBGE_REQUEST,
		payload: data,
	};
}
export function excluirMunicipioIbgeSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_MUNICIPIO_IBGE_SUCCESS,
		payload,
	};
}
export function excluirMunicipioIbgeFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_MUNICIPIO_IBGE_FAILURE,
		payload: null,
	};
}
export function excluirMunicipioIbgeClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_MUNICIPIO_IBGE_CLEAR,
		payload: null,
	};
}
