export enum Types {
	ATUALIZAR_MENSAGEM_REQUEST = '@SGU/ATUALIZAR_MENSAGEM_REQUEST',
	ATUALIZAR_MENSAGEM_SUCCESS = '@SGU/ATUALIZAR_MENSAGEM_SUCCESS',
	ATUALIZAR_MENSAGEM_FAILURE = '@SGU/ATUALIZAR_MENSAGEM_FAILURE',
	ATUALIZAR_MENSAGEM_CLEAR = '@SGU/ATUALIZAR_MENSAGEM_CLEAR',
}

export interface AtualizarMensagem {
	status: number;
	data: null | MensagemData;
}

interface MensagemData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarMensagemRequest {
	search?: string;
	id?: number | string;
	de: string;
	para: string;
	regex: string;
}
