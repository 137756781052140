export enum Types {
	RECUPERAR_ATENDIMENTO_REQUEST = '@atendimento/RECUPERAR_ATENDIMENTO_REQUEST',
	RECUPERAR_ATENDIMENTO_SUCCESS = '@atendimento/RECUPERAR_ATENDIMENTO_SUCCESS',
	RECUPERAR_ATENDIMENTO_FAILURE = '@atendimento/RECUPERAR_ATENDIMENTO_FAILURE',
	RECUPERAR_ATENDIMENTO_CLEAR = '@atendimento/RECUPERAR_ATENDIMENTO_CLEAR',
}

export interface RecuperarAtendimento {
	status: number;
	data: RecuperarAtendimentoData | null;
	messageError: string[] | null;
}

export interface RecuperarAtendimentoData {
	cpf: string;
	msg: string | null;
	estadoUsuario: string;
	idPonto: number;
	nomePonto: string;
	estadoPonto: string;
	id: string;
	sexo: string;
	rg: string;
	ufRg: string;
	email: string;
	tipoRegistro: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	dataNascimento: string;
	digitoRg: string;
	idAtendimento: string;
	idCidadao: string;
	enderecos: Endereco[];
	telefones: Telefone[];
	id_atendimento: string;
	id_cidadao: string;
}

interface Endereco {
	logradouro: string;
	tipo: string;
	bairro: string;
	complemento: string;
	cep: string;
	municipio: string;
	uf: string;
}

interface Telefone {
	ddd: string;
	numero: string;
	ramal: string;
	tipo: string;
	telefoneComDDD: string;
}

export interface RecuperarAtendimentoRequest {
	cpf: string;
}
