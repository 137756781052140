// import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_TIPOS_RESIDENCIAS_REQUEST = '@BOLSA-POVO/CONSULTAR_TIPOS_RESIDENCIAS_REQUEST',
	CONSULTAR_TIPOS_RESIDENCIAS_SUCCESS = '@BOLSA-POVO/CONSULTAR_TIPOS_RESIDENCIAS_SUCCESS',
	CONSULTAR_TIPOS_RESIDENCIAS_FAILURE = '@BOLSA-POVO/CONSULTAR_TIPOS_RESIDENCIAS_FAILURE',
	LIMPAR_CONSULTAR_TIPOS_RESIDENCIAS = '@BOLSA-POVO/LIMPAR_CONSULTAR_TIPOS_RESIDENCIAS',
}

export interface ConsultarTiposResidencias {
	status: number;
	data: TiposResidencias | null;
}

export interface TiposResidencias {
	valores: string[];
}
