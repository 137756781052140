import { all } from 'redux-saga/effects';

import cadastrarResponsavelSaga from './cadastroResponsavel/saga';
import listarResponsaveis from './consultaResponsaveis/saga';
import removerResponsavel from './removerResponsavel/saga';

export default all([
	cadastrarResponsavelSaga,
	listarResponsaveis,
	removerResponsavel,
]);
