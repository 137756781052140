export enum Types {
	EXCLUIR_UNIDADE = '@SGU/EXCLUIR_UNIDADE',
	EXCLUIR_UNIDADE_SUCCESS = '@SGU/EXCLUIR_UNIDADE_SUCCESS',
	EXCLUIR_UNIDADE_FAILURE = '@SGU/EXCLUIR_UNIDADE_FAILURE',
	EXCLUIR_UNIDADE_CLEAR = '@SGU/EXCLUIR_UNIDADE_CLEAR',
}

export interface ExcluirUnidade {
	status: number;
	data: null | ExcluirUnidadeData;
}

export interface ExcluirUnidadeData {
	status: string;
	statusMensagem: string;
}

export interface ExcluirUnidadeRequest {
	id?: number | string;
	codigo: number | string;
	nome: string;
	sigla: string;
}
