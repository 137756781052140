import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarParametroLocalidadeRequest } from './types';

export function atualizarParametroLocalidadeRequest(
	data: AtualizarParametroLocalidadeRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_PARAMETRO_LOCALIDADE,
		payload: data,
	};
}
export function atualizarParametroLocalidadeSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_PARAMETRO_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function atualizarParametroLocalidadeFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_PARAMETRO_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function atualizarParametroLocalidadeClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_PARAMETRO_LOCALIDADE_CLEAR,
		payload: null,
	};
}
