import produce from 'immer';

// TYPES
import { Types, CadastrarMenus } from './types';
import { ReducerAction } from '../../../../../types';

export const INITIAL_STATE: CadastrarMenus = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): CadastrarMenus {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CADASTRAR_MENUS: {
				draft.status = 100;
				break;
			}

			case Types.CADASTRAR_MENUS_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.data;
				break;
			}

			case Types.CADASTRAR_MENUS_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.CADASTRAR_MENUS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
