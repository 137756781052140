import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse } from 'services/_api';
import { getApi } from 'services/apiBiometria';
import { PATH_BIOMETRIA } from 'services/_path';

// TYPES
import { addNotifications } from 'store/modules/app/notifications/actions';
import { ReducerAction } from 'store/modules/types';
import { CapturarBiometriaRequest, Types } from './types';

// ACTIONS
import { capturarBiometriaSuccess, capturarBiometriaFailure } from './actions';

function* capturarBiometriaRequest(request: ReducerAction) {
	const { payload }: { payload: CapturarBiometriaRequest } = request;

	const pathOldServiceVersion = 'Biometria';
	const pathNewServiceVersion = 'biometria/captura';
	const pathVersion =
		payload.serviceVersion === 'OLD'
			? pathOldServiceVersion
			: pathNewServiceVersion;

	const response: ApiResponse = yield call(
		getApi,
		PATH_BIOMETRIA,
		pathVersion,
		undefined,
		undefined,
		true,
	);

	if (response.status === 200 && response.data?.wsq?.length > 0) {
		yield put(capturarBiometriaSuccess(response.data.wsq));
	} else {
		if (payload?.orgao === 'CNH') {
			addNotifications({
				errors: ['Ocorreu um erro com a leitora de digitais.'],
			});
		} else {
			addNotifications({
				errors: [
					response?.message
						? response.message
						: 'A leitura da biometria falhou. Realize o procedimento novamente.',
				],
			});
		}

		yield put(capturarBiometriaFailure(response));
	}
}

export default all([
	takeLatest(Types.CAPTURAR_BIOMETRIA_REQUEST, capturarBiometriaRequest),
]);
