import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarUsuarioRequest } from './types';

export function cadastrarUsuarioRequest(
	data: CadastrarUsuarioRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_USUARIO_REQUEST,
		payload: data,
	};
}
export function cadastrarUsuarioSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_USUARIO_SUCCESS,
		payload,
	};
}
export function cadastrarUsuarioFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_USUARIO_FAILURE,
		payload: null,
	};
}
export function cadastrarUsuarioClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_USUARIO_CLEAR,
		payload: null,
	};
}
