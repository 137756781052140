import { combineReducers } from 'redux';

import consultarVeiculoBaseEstadual from './consultarVeiculoBaseEstadual/reducer';
import consultarCadastroBinRenavam from './consultarCadastroBinRenavam/reducer';
import consultarBaseOutrosEstados from './consultarBaseOutrosEstados/reducer';
import consultarNumeroMotorBin from './ConsultarNumeroMotorBin/reducer';

export default combineReducers({
	consultarVeiculoBaseEstadual,
	consultarCadastroBinRenavam,
	consultarBaseOutrosEstados,
	consultarNumeroMotorBin,
});
