export enum Types {
	CADASTRAR_PARAMETRO_SISTEMA = '@SGU/CADASTRAR_PARAMETRO_SISTEMA',
	CADASTRAR_PARAMETRO_SISTEMA_SUCCESS = '@SGU/CADASTRAR_PARAMETRO_SISTEMA_SUCCESS',
	CADASTRAR_PARAMETRO_SISTEMA_FAILURE = '@SGU/CADASTRAR_PARAMETRO_SISTEMA_FAILURE',
	CADASTRAR_PARAMETRO_SISTEMA_CLEAR = '@SGU/CADASTRAR_PARAMETRO_SISTEMA_CLEAR',
}

export interface CadastrarParametroSistema {
	status: number;
	data: null | ParametroSistemaData;
}

interface ParametroSistemaData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarParametroSistemaRequest {
	id?: number | string;
	sigla: string;
	descricao: string;
	valor: string;
}
