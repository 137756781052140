export enum Types {
	EVENTO_INICIO_REQUEST = '@MP/EVENTO_INICIO_REQUEST',
	EVENTO_INICIO_SUCCESS = '@MP/EVENTO_INICIO_SUCCESS',
	EVENTO_INICIO_FAILURE = '@MP/EVENTO_INICIO_FAILURE',
	EVENTO_INICIO_CLEAR = '@MP/EVENTO_INICIO_CLEAR',
}

export interface EventoInicio {
	status: number;
	data: EventoInicioErrorData | null;
	detailsStartSection: EventoInicioSessaoDetalhes | null;
}

export interface EventoInicioSessaoDetalhes {
	dataInicio: string;
}

export interface EventoInicioRequest {
	dataPayload: {
		id_atendimento: string;
		id_cidadao: string;
		canal: {
			id: number;
			desc: string;
			localidade: {
				id: number;
				desc: string;
			};
		};
		atendente: {
			nome: string;
			id_atendente: string;
			id_posto: string;
			desc_posto: string;
		};
	};
	detailsStartSection: EventoInicioSessaoDetalhes;
}

export interface EventoInicioErrorData {
	transacao: string;
	codigo: number;
	mensagem: string;
	mensagens: string[];
}
