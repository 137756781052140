import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DARE } from 'services/_path';

// ACTIONS
import { cadastroRgVincularDare } from 'store/modules/api/iirgd/cadastroRg/actions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	IRequestGerarDare,
	gerarDareSuccess,
	gerarDareFailure,
} from './actions';

function* gerarDareRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestGerarDare } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DARE,
		`dare/${CHANNEL}/gerarDareRG`,
		payload,
	);

	if (response.status === 201) {
		const { data } = response;

		yield put(gerarDareSuccess(data));
		yield put(cadastroRgVincularDare(data));
	} else if (response.error) {
		yield put(gerarDareFailure());
	}
}

export default all([takeLatest(Types.GERAR_DARE_REQUEST, gerarDareRequest)]);
