import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, PesquisaFornecedor } from './types';

export const INITIAL_STATE: PesquisaFornecedor = {
	status: 0,
	data: [],
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): PesquisaFornecedor {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PESQUISA_FORNECEDOR_REQUEST: {
				break;
			}
			case Types.PESQUISA_FORNECEDOR_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.PESQUISA_FORNECEDOR_FAILURE: {
				draft.status = 400;
				draft.data = action.payload;
				break;
			}
			case Types.PESQUISA_FORNECEDOR_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
