import React, { useCallback } from 'react';

import { Row, Col, Skeleton } from 'antd';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

import { ApplicationState } from 'store';
import {
	consultaSituacaoAtualRequest,
	limpaPainelDeControle,
} from 'store/modules/api/aac/painelDeControle/actions';

// COMPONENTS
import SimpleTable from 'components/Common/Table';
import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import EmptyData from 'components/Common/Empty';
import Divider from 'components/Common/Divider';
import NavMenu from '../../components/NavMenu';

// FORM
import { columns } from './form';

// STYLED
import { Container } from './styles';
import { ROUTE_AAC_PESQUISA_ANTECEDENTES_PAINEL } from '../../routes/paths';

const SituacaoAtual: React.FC = () => {
	const dispatch = useDispatch();

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { resultado, status } = useSelector((state: ApplicationState) => {
		return {
			resultado: state.api.aac.painelDeControle.situacaoAtual.resultado,
			status: state.api.aac.painelDeControle.situacaoAtual.status,
		};
	});

	const handleDispatch = useCallback(() => {
		dispatch(consultaSituacaoAtualRequest({ numeroPosto: user.posto }));
	}, [dispatch, user]);

	const handleReturn = () => {
		dispatch(limpaPainelDeControle());
	};

	return (
		<>
			<Row>
				<Col span={24}>
					<NavMenu />

					<Divider mtSize={15} mbSize={15} />
				</Col>
			</Row>
			<Section title="Painel de Controle">
				{resultado.length > 0 && status !== 0 && (
					<>
						<Row justify="end" gutter={[0, 10]}>
							<Col>
								<Button type="button" onClick={() => handleDispatch()}>
									Atualizar
								</Button>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Container>
									<SimpleTable
										pageSize={20}
										headers={columns}
										body={resultado}
									/>
								</Container>
							</Col>
						</Row>
					</>
				)}
				{status === 0 && (
					<>
						<Row justify="end" gutter={[0, 20]}>
							<Col>
								<Skeleton.Button active size="default" shape="square" />
							</Col>
						</Row>
						<Section>
							<Skeleton active paragraph={{ rows: 8 }} />
						</Section>
					</>
				)}
				{status === 400 && (
					<EmptyData
						description="Serviço indisponível, tente novamente em alguns intantes."
						image="PRESENTED_IMAGE_DEFAULT"
					/>
				)}
			</Section>
			<ButtonVoltar
				onClick={handleReturn}
				route={ROUTE_AAC_PESQUISA_ANTECEDENTES_PAINEL}
			/>
		</>
	);
};

export default SituacaoAtual;
