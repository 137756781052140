import { ReducerAction } from 'store/modules/types';

import { Types, IPCOVRequest } from './types';

export function consultarPCOVRequest(data: IPCOVRequest): ReducerAction {
	return {
		type: Types.PCOV_REQUEST,
		payload: data,
	};
}

export function consultarPCOVSuccess(payload: object) {
	return {
		type: Types.PCOV_SUCCESS,
		payload,
	};
}

export function consultarPCOVFailure(payload: object): ReducerAction {
	return {
		type: Types.PCOV_FAILURE,
		payload,
	};
}

export function consultarPCOVClear(): any {
	return {
		type: Types.PCOV_CLEAR,
	};
}
