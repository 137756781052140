import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConsultarDareState } from './types';

export const INITIAL_STATE: IConsultarDareState = {
	status: 0,
	data: null,
	tipo: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IConsultarDareState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_DARE_GUIA_REQUEST: {
				draft.tipo = 'dare';
				break;
			}

			case Types.CONSULTAR_DARE_CPF_REQUEST: {
				draft.tipo = 'cpf';
				break;
			}

			case Types.CONSULTAR_DARE_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_DARE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
