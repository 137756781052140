import { ReducerAction } from 'store/modules/types';

import { Types, RequestEmissaoPid } from './types';

export function gerarRenachRequest(data: RequestEmissaoPid): ReducerAction {
	return {
		type: Types.EMISSAO_PID,
		payload: data,
	};
}
export function gerarRenachSuccess(payload: object): ReducerAction {
	return {
		type: Types.EMISSAO_PID_SUCCESS,
		payload,
	};
}
export function gerarRenachFailure(): ReducerAction {
	return {
		type: Types.EMISSAO_PID_FAILURE,
		payload: null,
	};
}
