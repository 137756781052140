import React from 'react';

import { Row, Col } from 'antd';

// REDUX
import { ConsultaUsuarioPendente } from 'store/modules/api/aac/novoPainelDeControle/consultaUsuarioPendente/types';

// COMPONENTS
import Section from 'components/Common/Section';
import Radio from 'components/Common/Form/Radio';

import DadosRg from '../../components/DadosRg';

interface Props {
	data: ConsultaUsuarioPendente;
}

const LiberacaoPositiva: React.FC<Props> = ({ data }) => {
	return (
		<>
			<Section>
				<DadosRg data={data} />
			</Section>

			<Row gutter={[0, 20]}>
				<Col span={24} />
			</Row>

			<Row>
				<Col span={24}>
					<Radio
						name="liberacaoPositiva"
						defaultValue="sim"
						options={[{ value: 'sim', label: 'Liberação Positiva' }]}
						onChange={() => {}}
					/>
				</Col>
			</Row>

			<Row gutter={[0, 5]}>
				<Col span={24}>
					<Section size="sm">
						<Row gutter={[0, 0]}>
							<Col span={3}>
								<strong>Regime:</strong>
							</Col>

							<Col>{data.positivaRegime}</Col>
						</Row>

						<Row gutter={[0, 0]}>
							<Col span={3}>
								<strong>Execução nº:</strong>
							</Col>

							<Col>{data.positivaExecucao}</Col>
						</Row>

						<Row gutter={[0, 0]}>
							<Col span={3}>
								<strong>Data início:</strong>
							</Col>

							<Col>{data.positivaInicio}</Col>
						</Row>

						<Row gutter={[0, 0]}>
							<Col span={3}>
								<strong>Data término:</strong>
							</Col>

							<Col>{data.positivaTermino}</Col>
						</Row>

						<Row>
							<Col span={3}>
								<strong>Observações:</strong>
							</Col>

							<Col>
								<Row>{data.positivaObservacao01}</Row>

								<Row>{data.positivaObservacao02}</Row>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>

			<Row gutter={[0, 20]}>
				<Col span={24} />
			</Row>

			<Row justify="center">
				<Col>
					Análise efetuada no IIRGD por: <strong>{data.usuarioCentral}</strong>
				</Col>
			</Row>
		</>
	);
};

export default LiberacaoPositiva;
