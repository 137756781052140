import { combineReducers } from 'redux';

import pesquisarUsuarioPortal from './pesquisarUsuarioPortal/reducer';
import consultaCadastroCnh from './consultaCadastroCnh/reducer';
import consultaTokenCnhDigital from './tokenDigital/reducer';
import enviaSMS from './smsSalesForce/reducer';
import enviaSMSPositus from './smsPositus/reducer';
import validaTokenCnhDigital from './validaToken/reducer';

export default combineReducers({
	pesquisarUsuarioPortal,
	consultaCadastroCnh,
	consultaTokenCnhDigital,
	enviaSMS,
	validaTokenCnhDigital,
	enviaSMSPositus,
});
