import { ReducerActionRotaSP } from 'store/modules/types';
import { ImpressaoIntencaoVendaRequest, Types } from './types';

export function impressaoIntencaoVendaRequest(
	token: string,
	payload: ImpressaoIntencaoVendaRequest,
): ReducerActionRotaSP {
	return {
		type: Types.IMPRESSAO_INTENCAO_VENDA_REQUEST,
		token,
		payload,
	};
}
export function impressaoIntencaoVendaSuccess(payload: any) {
	return {
		type: Types.IMPRESSAO_INTENCAO_VENDA_SUCCESS,
		payload,
	};
}
export function impressaoIntencaoVendaFailure(
	payload: any,
): ReducerActionRotaSP {
	return {
		type: Types.IMPRESSAO_INTENCAO_VENDA_FAILURE,
		payload,
	};
}
export function impressaoIntencaoVendaClear(): any {
	return {
		type: Types.IMPRESSAO_INTENCAO_VENDA_CLEAR,
	};
}
