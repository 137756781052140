import React, { useCallback } from 'react';

import { Modal } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from 'store';
import { enviarSmsRequest } from 'store/modules/api/iirgd/enviarSms/actions';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';

export const EnvioSMS: React.FC = () => {
	const dispatch = useDispatch();

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const handleClick = useCallback(async () => {
		if (cadastroRg.form.principal && cadastroRg.form.principal.celular) {
			dispatch(
				enviarSmsRequest({
					numeroRg: cadastroRg.form.principal.numeroRg,
					usuario: login.mainframe.idUsuario,
					senha: login.mainframe.senhaMainFrame,
				}),
			);
			return;
		}

		Modal.confirm({
			title: 'Atenção',
			content: <div>O cidadão não possui celular cadastrado.</div>,
			style: {
				animationDuration: '0s',
			},
			cancelButtonProps: { hidden: true },
		});
	}, [cadastroRg.form.principal, dispatch, login.mainframe]);

	return <ButtonImage type="button" src="envio-sms" onClick={handleClick} />;
};
