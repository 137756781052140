/* eslint-disable react/no-unescaped-entities */
import React, { ChangeEvent } from 'react';

import { Row, Col, Divider } from 'antd';
import { Field, FormikProps } from 'formik';

import hasError from 'utils/getFormErrors';

import Checkbox from 'components/Common/Form/Checkbox';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
// import { DadosContatoRequest } from './form';

interface IProps {
	formik: FormikProps<any>;
}

const DadosContato: React.FC<IProps> = ({ formik }) => {
	return (
		<>
			<Row>
				<Col span={12}>
					<h1 style={{ opacity: 0.7, marginTop: 25 }}>Dados de Contato</h1>
				</Col>
			</Row>
			<Divider
				style={{
					marginTop: 0,
					marginBottom: 10,
					backgroundColor: '#000',
					opacity: 0.6,
				}}
			/>
			<Row gutter={[0, 20]}>
				<Col span={24}>
					<p>Atualize as informações abaixo e em seguida clique em "Salvar":</p>
				</Col>
			</Row>
			<Row gutter={[0, 10]}>
				<Col span={8}>
					<Field
						as={InputMask}
						title="Telefone"
						name="telefone"
						mask="(99)9999-9999"
						error={hasError(formik.errors, 'telefone')}
					/>
				</Col>
				<Col offset={1} span={8}>
					<Field
						as={Input}
						title="E-mail"
						name="email"
						maxLength="50"
						onChange={(v: ChangeEvent<HTMLInputElement>) =>
							formik.setFieldValue('email', v.target.value.toUpperCase())
						}
						error={hasError(formik.errors, 'email')}
					/>
				</Col>
			</Row>
			<Row gutter={[0, 10]}>
				<Col span={8}>
					<Field
						as={InputMask}
						title="Celular"
						name="celular"
						mask="(99)99999-9999"
						error={hasError(formik.errors, 'celular')}
					/>
				</Col>
				<Col offset={1} span={8}>
					<Field
						as={Input}
						title="Confirmar e-mail"
						name="confirmaEmail"
						maxLength="50"
						onChange={(v: ChangeEvent<HTMLInputElement>) =>
							formik.setFieldValue(
								'confirmaEmail',
								v.target.value.toUpperCase(),
							)
						}
						error={hasError(formik.errors, 'confirmaEmail')}
					/>
				</Col>
			</Row>
			<Row gutter={[0, 10]}>
				<Col span={12}>
					<Row gutter={[0, 0]}>
						<Col span={14}>
							<Field
								subtitle="Autorizo o envio de SMS pelo DETRAN"
								name="authSms"
								as={Checkbox}
								checked={formik.values.authSms}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									const { checked } = e.target;

									formik.setFieldValue('authSms', !!checked);
								}}
							/>
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Field
						subtitle="Autorizo o envio de E-mail pelo DETRAN"
						name="authEmail"
						as={Checkbox}
						checked={formik.values.authEmail}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							const { checked } = e.target;

							formik.setFieldValue('authEmail', !!checked);
						}}
					/>
				</Col>
			</Row>
		</>
	);
};

export default DadosContato;
