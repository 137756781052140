import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { CadastraFornecedorRequest, Types } from './types';

// ACTIONS
import {
	cadastraFornecedorSuccess,
	cadastraFornecedorFailure,
} from './actions';

function* cadastraFornecedorRequest(request: ReducerAction) {
	const { payload }: { payload: CadastraFornecedorRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`fornecedores/${CHANNEL}/cadastro`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastraFornecedorSuccess(response));
	} else {
		yield put(cadastraFornecedorFailure(response));
	}
}

export default all([
	takeLatest(Types.CADASTRA_FORNECEDOR_REQUEST, cadastraFornecedorRequest),
]);
