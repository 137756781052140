export enum Types {
	CAPTURAR_BIOMETRIA_REQUEST = '@biometria/CAPTURAR_BIOMETRIA_REQUEST',
	CAPTURAR_BIOMETRIA_SUCCESS = '@biometria/CAPTURAR_BIOMETRIA_SUCCESS',
	CAPTURAR_BIOMETRIA_FAILURE = '@biometria/CAPTURAR_BIOMETRIA_FAILURE',
	CAPTURAR_BIOMETRIA_CLEAR = '@biometria/CAPTURAR_BIOMETRIA_CLEAR',
	CAPTURAR_BIOMETRIA_CLEAR_LEITURA = '@biometria/CAPTURAR_BIOMETRIA_CLEAR_LEITURA',
}

export interface CapturarBiometriaState {
	status: number;
	data: string;
	qtdeFalha: number;
	dataError: CapturarBiometriaDataError | null;
}

interface CapturarBiometriaDataError {
	status: number;
	message: string;
	error: boolean;
}

export interface CapturarBiometriaRequest {
	orgao: 'IIRGD' | 'CNH';
	serviceVersion: 'OLD' | 'NEW';
}
