export enum Types {
	ALTERA_ENDERECO = '@MP/ALTERA_ENDERECO',
	ALTERA_ENDERECO_SUCCESS = '@MP/ALTERA_ENDERECO_SUCCESS',
	ALTERA_ENDERECO_FAILURE = '@MP/ALTERA_ENDERECO_FAILURE',
	LIMPAR_ALTERA_ENDERECO = '@MP/LIMPAR_ALTERA_ENDERECO',
}

export interface AlteraEndereco {
	status: number;
	data: IRequestAlteraEndereco | IRequestAlteraEnderecoNovo | null;
}

export interface IRequestAlteraEndereco {
	idCpf: string;
	dsCep: string;
	dsCodigoIbge: string;
	dsEndereco: string;
	dsBairro: string;
	dsMunicipio: string;
	dsNumero: string;
	dsComplemento: string;
	dsUf: string;
	idEnderecoTipo: string;
	dsTelefoneContato: string;
	dsCelular: string;
	dsEmail: string;
	dsAtendenteCpf: string;
	dsAtendenteNome: string;
}

export interface IRequestAlteraEnderecoNovo {
	cpf: string;
	idTipoEndereco: string;
	cep: string;
	tipoLogradouro: string;
	logradouro: string;
	numero: string;
	referencia: string;
	semNumero: string;
	complemento: string;
	bairro: string;
	municipio: string;
	uf: string;
	dataAtualizacao: string;
	email: string;
	foneCelular: string;
	foneFixo: string;
	latitude?: string;
	longitude?: string;
	tipo?: string;
	idIbge: string;
	ip1?: string;
	ip2?: string;
	agentBroseSo?: string;
	pagina?: string;
	so?: string;
	bro?: string;
	usuarioAtualizacao?: string;
}
