import styled, { css } from 'styled-components';

interface ButtonProps {
	icon: string;
	disabled?: boolean;
}

export const Container = styled.div`
	align-items: center;
	display: flex;
	height: 25px;
	margin: 0 10px;
	width: 100%;
`;

export const ButtonDiv = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;

	input {
		margin: 0;
	}
`;

export const Button = styled.button<ButtonProps>`
	background-color: transparent;
	background-image: url(${props => props.icon});
	background-repeat: no-repeat;
	background-size: 16px 16px;
	border: unset;
	cursor: pointer;
	height: 16px;
	padding: 0;
	width: 16px;

	${props =>
		props.disabled &&
		css`
			opacity: 0.5;

			&:hover {
				cursor: no-drop;
			}
		`};

	&:hover {
		box-shadow: unset;
	}
`;
