export enum Types {
	PESQUISAR_ALUNO_REQUEST = '@EDUCACAO/PESQUISAR_ALUNO_REQUEST',
	PESQUISAR_ALUNO_SUCCESS = '@EDUCACAO/PESQUISAR_ALUNO_SUCCESS',
	PESQUISAR_ALUNO_NO_CONTENT = '@PESQUISAR_ALUNO_NO_CONTENT',
	PESQUISAR_ALUNO_FAILURE = '@EDUCACAO/PESQUISAR_ALUNO_FAILURE',
	PESQUISAR_ALUNO_CLEAR = '@PESQUISAR_ALUNO_CLEAR',
}

export interface PesquisarAluno {
	status: number;
	data: PesquisarAlunoResponse;
}

interface PesquisarAlunoResponse {
	idProcesso: number;
	alunos: AlunosPesquisa[];
}

interface AlunosPesquisa {
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
	nome: string;
	nomeMae: string;
	dataNascimento: string;
	nomePai: string;
}
