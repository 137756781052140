export enum Types {
	EXCLUIR_PERFIL = '@SGU/EXCLUIR_PERFIL',
	EXCLUIR_PERFIL_SUCCESS = '@SGU/EXCLUIR_PERFIL_SUCCESS',
	EXCLUIR_PERFIL_FAILURE = '@SGU/EXCLUIR_PERFIL_FAILURE',
	EXCLUIR_PERFIL_CLEAR = '@SGU/EXCLUIR_PERFIL_CLEAR',
}

export interface ExcluirPerfil {
	status: number;
	data: null | IExcluirPerfil;
}

export interface IExcluirPerfil {
	status: string;
	statusMensagem: string;
}

export interface ExcluirPerfilRequest {
	codigo?: number | string;
	descricao: string;
	codigoStatus: string | number;
	acessaSgu: boolean;
}
