import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	FORMA_AQUISICAO_REQUEST = '@procon/FORMA_AQUISICAO_REQUEST',
	FORMA_AQUISICAO_SUCCESS = '@procon/FORMA_AQUISICAO_SUCCESS',
	FORMA_AQUISICAO_FAILURE = '@procon/FORMA_AQUISICAO_FAILURE',
	FORMA_AQUISICAO_CLEAR = '@procon/FORMA_AQUISICAO_CLEAR',
}

export interface FormaAquisicao {
	status: null | number;
	data: OptionProps[];
}
