import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { AlterarCadastroRequest, Types } from './types';

// ACTIONS
import { alterarCadastroSuccess, alterarCadastroFailure } from './actions';

function* alterarCadastroRequest(request: ReducerAction) {
	const { payload }: { payload: AlterarCadastroRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`renach-integrado/${CHANNEL}/alterar-cadastro`,
		payload,
	);

	if (response.status === 200) {
		yield put(alterarCadastroSuccess({ ...response, request: payload }));
	} else {
		yield put(alterarCadastroFailure(response.data));
	}
}

export default all([
	takeLatest(Types.ALTERAR_CADASTRO_REQUEST, alterarCadastroRequest),
]);
