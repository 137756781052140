import React, {
	ChangeEvent,
	Suspense,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';

// REDUX
import {
	escolherPlacaRequest,
	escolherPlacaClear,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/EscolherPlaca/actions';
import {
	consultarTaxaServicoClear,
	consultarTaxaServicoRequest,
} from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/actions';
import { ConsultarTaxaServicoRequest } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/types';
import { consultaCategoriaRequest } from 'store/modules/api/detranCrv/enum/consultaCategoria/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import {
	estatisticaIncluirClear,
	estatisticaIncluirRequest,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Select from 'components/Common/Form/Select';
import Error from 'components/Common/Notifications/FormError/index';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { limparMascara } from 'utils/genericFunctions';

// ROUTES
import { ROUTE_DETRAN_CRV } from 'routes/paths';

// FORM
import { numeros, letrasNumeros } from 'utils/stringFunctions';
import {
	IEscolherPlaca,
	initialValues,
	schema,
	treatRequestSguStatistics,
} from './form';

const EscolherPlaca: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { escolherPlaca } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	const { consultarTaxaServico } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	const { consultaCategoria } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.Enum,
	);

	const [notificationErrors, setNotificationErrors] = useState<string[]>([]);
	const [categoriaOficial, setCategoriaOficial] = useState<string | number>('');
	const [formData, setFormData] = useState(initialValues);

	useEffect(() => {
		dispatch(consultaCategoriaRequest());
		dispatch(consultarTaxaServicoClear());
		dispatch(escolherPlacaClear());
	}, [dispatch]);

	useEffect(() => {
		const payload = treatRequestSguStatistics(
			user,
			formData,
			escolherPlaca.data,
		);

		if (
			escolherPlaca.status === 200 &&
			(consultarTaxaServico.status === 200 || categoriaOficial === 3)
		) {
			dispatch(estatisticaIncluirRequest(payload));
			history.push('/detran-crv/escolher-placa/resultado');
		}
	}, [
		consultarTaxaServico.status,
		categoriaOficial,
		escolherPlaca.status,
		escolherPlaca.data,
		history,
		formData,
		user,
		dispatch,
	]);

	const handleClear = useCallback(
		(formik?: FormikProps<IEscolherPlaca>) => {
			if (formik) {
				formik.handleReset();
			}
			dispatch(escolherPlacaClear());

			dispatch(clearNotifications());
			dispatch(consultarTaxaServicoClear());
			setNotificationErrors([]);

			dispatch(estatisticaIncluirClear());
		},
		[dispatch],
	);

	useEffect(() => {
		if (
			escolherPlaca.status === 200 &&
			consultarTaxaServico.status === 0 &&
			categoriaOficial !== 3
		) {
			const dataConsultarTaxaServico: ConsultarTaxaServicoRequest = {
				codigoTaxaServico: '9',
				identificadorTaxa:
					escolherPlaca.data?.resultadoEscolhaPlacaAtribuida.cpfCnpjProprietario.toString() ||
					'',
				despachante: '',
			};

			dispatch(consultarTaxaServicoRequest(dataConsultarTaxaServico));
		}
	}, [
		dispatch,
		categoriaOficial,
		escolherPlaca.data,
		escolherPlaca.status,
		consultarTaxaServico.status,
	]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			{notificationErrors.length > 0 && (
				<Error
					errors={notificationErrors}
					onClose={() => setNotificationErrors([])}
				/>
			)}
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={formData}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						dispatch(clearNotifications());
						dispatch(escolherPlacaClear());
						dispatch(consultarTaxaServicoClear());

						setFormData(values);

						dispatch(
							escolherPlacaRequest({
								chassi: values.chassi?.trim() || '',
								cpfCnpjProprietario: values.cpfCnpjProprietario
									? limparMascara(values.cpfCnpjProprietario.trim())
									: '',
							}),
						);
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row style={{ marginTop: '10px' }}>
									<Col span={14}>
										<Field
											required
											titleSize="lg"
											name="chassi"
											title="Chassi"
											as={Input}
											error={hasError(formik.errors, 'chassi')}
											maxLength={22}
											onChange={(v: ChangeEvent<HTMLInputElement>) => {
												if (errors.length > 0) {
													dispatch(clearNotifications());
												}
												formik.setFieldValue(
													'chassi',
													letrasNumeros(v.target.value),
												);
											}}
										/>
									</Col>
									<Col span={10}>
										<Field
											as={Select}
											title="Categoria"
											options={consultaCategoria.data}
											error={
												!!formik.values.categoria &&
												hasError(formik.errors, 'documentoCategoria')
											}
											name="categoria"
											onChange={(v: number) => {
												formik.setFieldValue('categoria', v);
												setCategoriaOficial(v);
												dispatch(consultarTaxaServicoClear());
												dispatch(escolherPlacaClear());
												if (errors.length > 0) {
													dispatch(clearNotifications());
												}
												// handleCategoria(v);
											}}
										/>
									</Col>
									<Col span={14}>
										<Field
											required
											titleSize="lg"
											name="cpfCnpjProprietario"
											title="CPF/CNPJ Proprietário"
											as={Input}
											error={
												!!formik.values.cpfCnpjProprietario &&
												hasError(formik.errors, 'documentoCategoria')
											}
											maxLength={14}
											onChange={(v: ChangeEvent<HTMLInputElement>) => {
												if (errors.length > 0) {
													dispatch(clearNotifications());
												}
												formik.setFieldValue(
													'cpfCnpjProprietario',
													numeros(v.target.value),
												);
											}}
										/>
									</Col>

									<Col span={8} />
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={() => handleClear(formik)}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</Suspense>
	);
};

export default EscolherPlaca;
