export enum Types {
	CONSULTAR_CONTATO_REQUEST = '@BOLSA-POVO/CONSULTAR_CONTATO_REQUEST',
	CONSULTAR_CONTATO_SUCCESS = '@BOLSA-POVO/CONSULTAR_CONTATO_SUCCESS',
	CONSULTAR_CONTATO_FAILURE = '@BOLSA-POVO/CONSULTAR_CONTATO_FAILURE',
	LIMPAR_CONSULTAR_CONTATO = '@BOLSA-POVO/LIMPAR_CONSULTAR_CONTATO',
}

export interface ConsultarContato {
	status: number;
	data: ContatosConsultados[] | null;
}

export interface ContatosConsultados {
	id: number;
	cpf: string;
	idTipoContato: number;
	valor: string;
}

export interface ConsultarContatoRequest {
	cpf: string;
}
