import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, patchApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, TrocarSenhaUsuarioRequest } from './types';

// ACTIONS
import {
	trocarSenhaUsuarioSuccess,
	trocarSenhaUsuarioFailure,
} from './actions';

function* trocarSenhaUsuario(request: ReducerAction) {
	const { payload }: { payload: TrocarSenhaUsuarioRequest } = request;

	const response: ApiResponse = yield call(
		patchApi,
		PATH_SGU,
		`usuarios/${payload.id}/resetar`,
		{},
	);

	if (response.status === 200) {
		yield put(trocarSenhaUsuarioSuccess(response));
	} else {
		yield put(trocarSenhaUsuarioFailure());
	}
}

export default all([
	takeLatest(Types.TROCAR_SENHA_USUARIO, trocarSenhaUsuario),
]);
