export enum Types {
	EMITIR_TROCA_VOLUNTARIA_PLACA_REQUEST = '@DETRAN-CRV/EMITIR_TROCA_VOLUNTARIA_PLACA_REQUEST',
	EMITIR_TROCA_VOLUNTARIA_PLACA_SUCCESS = '@DETRAN-CRV/EMITIR_TROCA_VOLUNTARIA_PLACA_SUCCESS',
	EMITIR_TROCA_VOLUNTARIA_PLACA_FAILURE = '@DETRAN-CRV/EMITIR_TROCA_VOLUNTARIA_PLACA_FAILURE',
	EMITIR_TROCA_VOLUNTARIA_PLACA_CLEAR = '@DETRAN-CRV/EMITIR_TROCA_VOLUNTARIA_PLACA_CLEAR',
}

export interface emitirTrocaVoluntariaPlaca {
	status: number;
	data: IResponseEmitirTrocaVoluntariaPlaca | null;
}

export interface IRequestWithHeader {
	body: IRequestEmitirTrocaVoluntariaPlaca;
	headers?: any;
}

export interface IResponseEmitirTrocaVoluntariaPlaca {
	anoFicha: number;
	chassi: string;
	fichaCadastral: string;
	numeroficha: number;
	placa: string;
	taxaAutenticacaoDigital: string;
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	ip: string;
	cpf: string;
	identificacao: string;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}
export interface IRequestEmitirTrocaVoluntariaPlaca {
	evento: IEvento;
	chassi: string;
	placa: string;
	renavam: number;
	municipio: {
		codigo: number;
		nome: string;
	};
	taxaAutenticacaoDigital: string;
	nomeCidadao: string;
	dddTelefoneCidadao: number | string;
	emailCidadao: string;
	telefoneCidadao: number | string;
	servicoDigital: boolean;
	documentos: any;
	codBanco?: string;
	dataContabil?: string;
	nsuProdesp?: string;
}
