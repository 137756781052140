// TYPES
import { ReducerAction } from 'store/modules/types';
import { IConsultaPesquisaCriminalState, Types } from './types';

export interface IRequestPesquisaCriminal {
	numeroRg: string;
	nomeCompleto: string;
	nomePai: string;
	nomeMae: string;
	dataNascimento: string;
	usuario: string;
	senha: string;
}

export function consultaPesquisaCriminalTemporalidade(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CRIMINAL_TEMPORALIDADE,
		payload,
	};
}

export function consultaPesquisaCriminalRestricao(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CRIMINAL_RESTRICAO,
		payload,
	};
}

export function consultaPesquisaCriminalRequest(
	payload: IRequestPesquisaCriminal,
) {
	return {
		type: Types.CONSULTA_PESQUISA_CRIMINAL_REQUEST,
		payload,
	};
}
export function consultaPesquisaCriminalSuccess(
	payload: IConsultaPesquisaCriminalState[],
) {
	return {
		type: Types.CONSULTA_PESQUISA_CRIMINAL_SUCCESS,
		payload,
	};
}
export function consultaPesquisaCriminalFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CRIMINAL_FAILURE,
		payload: null,
	};
}

export function consultaPesquisaCriminalClear(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CRIMINAL_CLEAR,
		payload: null,
	};
}
