import { ReducerAction } from 'store/modules/types';

import { Types, IRequestPesquisarUsuarioPortal } from './types';

export function pesquisarUsuarioPortalRequest(
	data: IRequestPesquisarUsuarioPortal,
): ReducerAction {
	return {
		type: Types.PESQUISAR_USUARIO_PORTAL,
		payload: data,
	};
}
export function pesquisarUsuarioPortalSuccess(payload: object): ReducerAction {
	return {
		type: Types.PESQUISAR_USUARIO_PORTAL_SUCCESS,
		payload,
	};
}
export function pesquisarUsuarioPortalFailure(): ReducerAction {
	return {
		type: Types.PESQUISAR_USUARIO_PORTAL_FAILURE,
		payload: null,
	};
}
