import React, { Suspense, useCallback, useEffect } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import ButtonImage from 'components/Common/ButtonImage';
import InputMask from 'components/Common/Form/Input/InputMask';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { useHistory } from 'react-router-dom';
import Placa from 'components/Common/Form/Fields/Placa';
import Select from 'components/Common/Form/Select';
import { consultarIntencaoVendaRequest } from 'store/modules/api/detranCrv/IntencaoDeVenda/Consultar/actions';
import data from 'store/modules/api/detranCrv/enum';
import ValidDataInput from 'components/Common/Form/Input/ValidData';

import TimePicker from 'components/Common/Form/Input/TimePicker';
import { ConsultarIntencaoVendaRequest } from 'store/modules/api/detranCrv/IntencaoDeVenda/Consultar/types';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { ROUTE_DETRAN_CRV } from 'routes/paths';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';
import { initialValues, schema, treatValues } from './form';

const ConsultarIntencaoVenda: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const { consultarIntencaoVenda } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.intencaoVenda,
	);

	const { salvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento,
	);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const handleClear = useCallback(
		(formik: FormikProps<ConsultarIntencaoVendaRequest>) => {
			dispatch(clearNotifications());
			formik.handleReset();
		},
		[dispatch],
	);

	useEffect(() => {
		if (consultarIntencaoVenda.status === 200) {
			history.push('/detran-crv/consultar-intencao-venda/resultado');
		}
	}, [consultarIntencaoVenda, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Consultar ATPV-e">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						const sguData: SguData = {
							salvarAtendimento,
							login,
						};
						const evento = getEventoConsultar(sguData);

						const formatValues = treatValues(evento, values);
						dispatch(consultarIntencaoVendaRequest(formatValues));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											titleSize="lg"
											required
											name="renavam"
											title="Renavam"
											mask="99999999999"
											maskChar=""
											as={InputMask}
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue('renavam', v.target.value);
											}}
											error={hasError(formik.errors, 'renavam')}
										/>
									</Col>
									<Col span={12}>
										<Placa
											title="Placa"
											titleSize="lg"
											name="placa"
											defaultValue={initialValues.placa}
											size={80}
											required
											formik={formik}
										/>
									</Col>
								</Row>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											as={Select}
											titleSize="lg"
											size={80}
											name="codigoEstadoIntencaoVenda"
											title="Estado Intenção Venda"
											options={data.consultaIntencaoVenda}
											error={hasError(
												formik.errors,
												'codigoEstadoIntencaoVenda',
											)}
											onChange={(v: string) => {
												formik.setFieldValue('codigoEstadoIntencaoVenda', v);
											}}
										/>
									</Col>
									<Col span={12}>
										<Field
											titleSize="lg"
											required
											name="numeroATPVE"
											title="Número ATPV-e"
											mask="999999999999999"
											maskChar=""
											as={InputMask}
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue('numeroATPVE', v.target.value);
											}}
											error={hasError(formik.errors, 'numeroATPVE')}
										/>
									</Col>
								</Row>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											required={
												formik.values.dataInicioConsulta ||
												formik.values.horaInicioConsulta ||
												formik.values.horaFimConsulta ||
												formik.values.dataFimConsulta
											}
											name="dataInicioConsulta"
											title="Data de Início"
											type="text"
											mask="99/99/9999"
											subtitle="(DD/MM/AAAA)"
											size={50}
											as={ValidDataInput}
											error={hasError(formik.errors, 'dataInicioConsulta')}
											formik={formik}
											titleSize="lg"
											onChange={(v: string) =>
												formik.setFieldValue('dataInicioConsulta', v)
											}
											higherCurrent
										/>
									</Col>
									<Col span={12}>
										<Field
											as={TimePicker}
											required={
												formik.values.dataInicioConsulta ||
												formik.values.horaInicioConsulta ||
												formik.values.horaFimConsulta ||
												formik.values.dataFimConsulta
											}
											titleSize="lg"
											name="horaInicioConsulta"
											title="Hora Início"
											subtitle="(HH:MM)"
											onChange={(time: string) => {
												formik.setFieldValue('horaInicioConsulta', time);
											}}
											error={hasError(formik.errors, 'horaInicioConsulta')}
										/>
									</Col>
								</Row>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											required={
												formik.values.dataInicioConsulta ||
												formik.values.horaInicioConsulta ||
												formik.values.horaFimConsulta ||
												formik.values.dataFimConsulta
											}
											titleSize="lg"
											name="dataFimConsulta"
											title="Data Fim"
											type="text"
											mask="99/99/9999"
											subtitle="(DD/MM/AAAA)"
											size={50}
											as={ValidDataInput}
											error={hasError(formik.errors, 'dataFimConsulta')}
											formik={formik}
											onChange={(v: string) =>
												formik.setFieldValue('dataFimConsulta', v)
											}
											higherCurrent
										/>
									</Col>
									<Col span={12}>
										<Field
											as={TimePicker}
											required={
												formik.values.dataInicioConsulta ||
												formik.values.horaInicioConsulta ||
												formik.values.horaFimConsulta ||
												formik.values.dataFimConsulta
											}
											titleSize="lg"
											name="horaFimConsulta"
											title="Hora Fim"
											subtitle="(HH:MM)"
											onChange={(time: string) => {
												formik.setFieldValue('horaFimConsulta', time);
											}}
											error={hasError(formik.errors, 'horaFimConsulta')}
										/>
									</Col>
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={() => handleClear(formik)}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</Suspense>
	);
};

export default ConsultarIntencaoVenda;
