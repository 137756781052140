import produce from 'immer';

// UTILS
import { toSelect } from 'utils/genericFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaAreas } from './types';

export const INITIAL_STATE: ConsultaAreas = {
	status: 0,
	data: [],
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaAreas {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_AREAS_REQUEST: {
				break;
			}
			case Types.CONSULTA_AREAS_SUCCESS: {
				draft.status = action.payload.status;

				if (action.payload.data) {
					const select = toSelect(action.payload.data, 'nome', 'id');
					draft.data = select;
				} else {
					draft.data = [];
				}

				break;
			}
			case Types.CONSULTA_AREAS_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CONSULTA_AREAS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
