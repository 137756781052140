import { ReducerActionRotaSP } from 'store/modules/types';

import { EnderecosRequest, Types } from './types';

export function enderecosRequest(
	token: string,
	data: EnderecosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ENDERECOS_REQUEST,
		token,
		payload: data,
	};
}
export function enderecosSuccess(payload: any): ReducerActionRotaSP {
	return {
		type: Types.ENDERECOS_SUCCESS,
		payload,
	};
}
export function enderecosFailure(payload: any): ReducerActionRotaSP {
	return {
		type: Types.ENDERECOS_FAILURE,
		payload,
	};
}

export function enderecosClear(): { type: string } {
	return {
		type: Types.ENDERECOS_CLEAR,
	};
}
