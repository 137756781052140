import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirStatusRequest } from './types';

export function excluirStatusRequest(
	data: ExcluirStatusRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_STATUS,
		payload: data,
	};
}
export function excluirStatusSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_STATUS_SUCCESS,
		payload,
	};
}
export function excluirStatusFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_STATUS_FAILURE,
		payload: null,
	};
}
export function excluirStatusClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_STATUS_CLEAR,
		payload: null,
	};
}
