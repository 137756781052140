import { ReducerAction } from 'store/modules/types';

import { ConsultaProcessoAbertoRequest, Types } from './types';

export function consultaProcessoAbertoRequest(
	data: ConsultaProcessoAbertoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_PROCESSO_ABERTO_REQUEST,
		payload: data,
	};
}
export function consultaProcessoAbertoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_PROCESSO_ABERTO_SUCCESS,
		payload,
	};
}
export function consultaProcessoAbertoFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_PROCESSO_ABERTO_FAILURE,
		payload,
	};
}

export function consultaProcessoAbertoClear(): any {
	return {
		type: Types.CONSULTA_PROCESSO_ABERTO_CLEAR,
		payload: null,
	};
}
