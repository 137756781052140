// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	IConsultarQueimaDare,
	IConsultarQueimaDareRequest,
	Types,
} from './types';

export function consultarQueimaDareRequest(
	payload: IConsultarQueimaDareRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_QUEIMA_DARE_REQUEST,
		payload,
	};
}

export function consultarQueimaDareSuccess(
	status: number,
	data: IConsultarQueimaDare[] | null,
): ReducerAction {
	return {
		type: Types.CONSULTAR_QUEIMA_DARE_SUCCESS,
		payload: {
			status,
			data,
		},
	};
}

export function consultarQueimaDareClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_QUEIMA_DARE_CLEAR,
		payload: null,
	};
}

export function consultarQueimaDareFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_QUEIMA_DARE_FAILURE,
		payload: null,
	};
}
