import {
	ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_DEFINITIVA_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_ESTRANGEIRO_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_RENOVACAO_STATUS_CONDUTOR,
	ROUTE_DETRAN_CNH_SEGUNDA_VIA_STATUS_CONDUTOR,
} from 'pages/DetranCnh/routes/paths';

export const usoFormularioEnumTypes = {
	PRIMEIRA_HABILITACAO: 'PRIMEIRA_HABILITACAO',
	RENOVACAO: 'RENOVACAO',
	MUDANCA_CATEGORIA: 'MUDANCA_CATEGORIA',
	ADICAO_CATEGORIA: 'ADICAO_CATEGORIA',
	ADICAO_MUDANCA: 'ADICAO_MUDANCA',
	PERMISSIONARIO_PONTUADO: 'PERMISSIONARIO',
	CNH_ESTRANGEIRA: 'CNH_ESTRANGEIRA',
	CASSACAO: 'CASSACAO',
	CASSACAO_CRIME: 'CASSACAO_CRIME',
	PERMISSIONARIO: 'PERMISSIONARIO',
	ALTERAR_RENACH: 'ALTERAR_RENACH',
	CONSULTA_PONTOS: 'CONSULTA_PONTOS',
	SEGUNDA_VIA: 'SEGUNDA_VIA',
	CNH_DEFINITIVA: 'CNH_DEFINITIVA',
	CNH_DIGITAL: 'CNH_DIGITAL',
	PERMISSAO_INTERNACIONAL: 'PERMISSAO_INTERNACIONAL',
};

export const enumFormUse = {
	PRIMEIRA_HABILITACAO: '1',
	RENOVACAO: '2',
	SEGUNDA_VIA: '3',
	ADICAO_MUDANCA: '4',
	CNH_ESTRANGEIRA: '5',
	PERMISSIONARIO_PONTUADO: '9',
	CASSACAO: '7',
	CNH_DEFINITIVA: '8',
};

export const enumServicesCnhWithAccents = {
	PRIMEIRA_HABILITACAO: 'Primeira Habilitação',
	RENOVACAO: 'Renovação de CNH',
	SEGUNDA_VIA: 'Segunda Via de CNH ou de Permissão',
	ADICAO_MUDANCA: 'Adição/Mudança de Categoria',
	CNH_ESTRANGEIRA: 'Registro de Habilitação Estrangeira',
	PERMISSIONARIO_PONTUADO: 'Nova Habilitação - Permissionário Pontuado',
	CASSACAO: 'Reabilitação - Cassação / Crime',
	CNH_DEFINITIVA: 'CNH Definitiva',
	PID: 'Permissão Internacional para Dirigir - PID',
	ALTERAR_ENDERECO: 'Solicitar Alteração de Endereço',
};

export const enumFormUseUrlStatusCondutor = [
	{
		idTypeProcess: enumFormUse.PRIMEIRA_HABILITACAO,
		descriptionUpperCaseWithoutSpaceAndAccents:
			usoFormularioEnumTypes.PRIMEIRA_HABILITACAO,
		descriptionWithSpaceAndAccents:
			enumServicesCnhWithAccents.PRIMEIRA_HABILITACAO,
		url: ROUTE_DETRAN_CNH_PRIMEIRA_HABILITACAO_STATUS_CONDUTOR,
	},
	{
		idTypeProcess: enumFormUse.RENOVACAO,
		descriptionUpperCaseWithoutSpaceAndAccents:
			usoFormularioEnumTypes.RENOVACAO,
		descriptionWithSpaceAndAccents: enumServicesCnhWithAccents.RENOVACAO,
		url: ROUTE_DETRAN_CNH_RENOVACAO_STATUS_CONDUTOR,
	},
	{
		idTypeProcess: enumFormUse.SEGUNDA_VIA,
		descriptionUpperCaseWithoutSpaceAndAccents:
			usoFormularioEnumTypes.SEGUNDA_VIA,
		descriptionWithSpaceAndAccents: enumServicesCnhWithAccents.SEGUNDA_VIA,
		url: ROUTE_DETRAN_CNH_SEGUNDA_VIA_STATUS_CONDUTOR,
	},
	{
		idTypeProcess: enumFormUse.ADICAO_MUDANCA,
		descriptionUpperCaseWithoutSpaceAndAccents:
			usoFormularioEnumTypes.ADICAO_MUDANCA,
		descriptionWithSpaceAndAccents: enumServicesCnhWithAccents.ADICAO_MUDANCA,
		url: ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_STATUS_CONDUTOR,
	},
	{
		idTypeProcess: enumFormUse.CNH_ESTRANGEIRA,
		descriptionUpperCaseWithoutSpaceAndAccents:
			usoFormularioEnumTypes.CNH_ESTRANGEIRA,
		descriptionWithSpaceAndAccents: enumServicesCnhWithAccents.CNH_ESTRANGEIRA,
		url: ROUTE_DETRAN_CNH_ESTRANGEIRO_STATUS_CONDUTOR,
	},
	{
		idTypeProcess: enumFormUse.PERMISSIONARIO_PONTUADO,
		descriptionUpperCaseWithoutSpaceAndAccents:
			usoFormularioEnumTypes.PERMISSIONARIO_PONTUADO,
		descriptionWithSpaceAndAccents:
			enumServicesCnhWithAccents.PERMISSIONARIO_PONTUADO,
		url: ROUTE_DETRAN_CNH_PERMISSIONARIO_PONTUADO_STATUS_CONDUTOR,
	},
	{
		idTypeProcess: enumFormUse.CASSACAO,
		descriptionUpperCaseWithoutSpaceAndAccents: usoFormularioEnumTypes.CASSACAO,
		descriptionWithSpaceAndAccents: enumServicesCnhWithAccents.CASSACAO,
		url: ROUTE_DETRAN_CNH_CASSADA_REABILITACAO_STATUS_CONDUTOR,
	},
	{
		idTypeProcess: enumFormUse.CNH_DEFINITIVA,
		descriptionUpperCaseWithoutSpaceAndAccents:
			usoFormularioEnumTypes.CNH_DEFINITIVA,
		descriptionWithSpaceAndAccents: enumServicesCnhWithAccents.CNH_DEFINITIVA,
		url: ROUTE_DETRAN_CNH_DEFINITIVA_STATUS_CONDUTOR,
	},
];

export const renachTypes = {
	PRIMEIRA_HABILITACAO: 'PRIMEIRA HABILITACAO',
	RENOVACAO: 'RENOVACAO',
	MUDANCA_CATEGORIA: 'MUDANCA CATEGORIA',
	ADICAO_CATEGORIA: 'ADICAO CATEGORIA',
	CASSACAO_CRIME: 'CASSACAO',
	PERMISSIONARIO_PONTUADO: 'NOVA HABILITACAO',
	CNH_ESTRANGEIRA: 'REG. CNH ESTRANGEIRA',
	CASSACAO: 'CASSACAO',
	CNH_DEFINITIVA: 'CNH DEFINITIVA',
};

export const socialNameAndFiliationsPhrasesNotAllowed = [
	'nada consta',
	'nao consta',
	'n/a',
	'nao se aplica',
	'nao declarado',
	'nao informado',
	'nao existe',
];
