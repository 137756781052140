import { all } from 'redux-saga/effects';

// BENEFICIÁRIOS
import beneficiarios from './beneficiarios/saga';

// DOCUMENTOS
import documentos from './documentos/saga';

// NOTAS FISCAIS
import notasFiscais from './notasFiscais/saga';

// USUARIO
import usuario from './usuario/saga';

export default all([beneficiarios, documentos, notasFiscais, usuario]);
