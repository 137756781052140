export enum Types {
	CONSULTA_NOME_SOCIAL_FILIACAO_REQUEST = '@cnh/CONSULTA_NOME_SOCIAL_FILIACAO_REQUEST',
	CONSULTA_NOME_SOCIAL_FILIACAO_SUCCESS = '@cnh/CONSULTA_NOME_SOCIAL_FILIACAO_SUCCESS',
	CONSULTA_NOME_SOCIAL_FILIACAO_FAILURE = '@cnh/CONSULTA_NOME_SOCIAL_FILIACAO_FAILURE',
	CONSULTA_NOME_SOCIAL_FILIACAO_CLEAR = '@cnh/CONSULTA_NOME_SOCIAL_FILIACAO_CLEAR',
}

export interface consultaNomeSocialFiliacao {
	status: number;
	data: null | ConsultaNomeSocialFiliacaoData;
}

export interface ConsultaNomeSocialFiliacaoData {
	cpf: string;
	argumento: string;
	registro: string;
	pgu: string;
	renach: string;
	nome: string;
	nomeSocial: string;
	pai: string;
	mae: string;
	filiacao1: string;
	filiacao2: string;
	s1: string;
	s2: string;
}

export interface RequestConsultaNomeSocialFiliacao {
	nome?: string;
	nomeSocial?: string;
	cpf: string;
	registro?: string;
	renach?: string;
	usuario: string;
	senha: string;
}
