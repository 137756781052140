import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';

// COMPONENTS
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { IParametrosLocalidade } from 'store/modules/api/sgu/loginUnico/types';
import { RG_DIGITAL_ENVIO_SMS } from 'store/modules/api/sgu/loginUnico/constants';
import { EnvioSMS } from '../EnvioSMS';
import { FinalizarProcesso } from '../FinalizarProcesso';
import { ReeditarCadastro } from '../ReeditarCadastro';
import { ImprimirProtocolo } from '../ImprimirProtocolo';
import { PagamentoPìnPad } from '../PagamentoPìnPad';
import { ImprimirDare } from '../ImprimirDare';

export const ComboFinalizacao: React.FC = () => {
	const { gerarDare } = useSelector((state: ApplicationState) => state.api);
	const { consultarDare } = useSelector(
		(state: ApplicationState) => state.api.dare,
	);

	const { loginUnico } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const [showSMS, setShowSMS] = useState<boolean>(false);
	useEffect(() => {
		if (
			loginUnico.login.user.parametrosLocalidade.length &&
			loginUnico.login.user.parametrosLocalidade.filter(
				(item: IParametrosLocalidade) => item.sigla === RG_DIGITAL_ENVIO_SMS,
			).length
		) {
			const filterValue: IParametrosLocalidade[] =
				loginUnico.login.user.parametrosLocalidade.filter(
					(item: IParametrosLocalidade) => item.sigla === RG_DIGITAL_ENVIO_SMS,
				);

			if (
				filterValue &&
				filterValue[0] &&
				filterValue[0].sigla === RG_DIGITAL_ENVIO_SMS &&
				filterValue[0].valor === 'TRUE'
			) {
				setShowSMS(true);
			} else {
				setShowSMS(false);
			}
		}
	}, [loginUnico.login.user.parametrosLocalidade]);

	return (
		<>
			<Row gutter={[0, 20]}>
				<Col span={24} />
			</Row>

			<Row justify="center">
				<Col offset={1}>
					<ImprimirProtocolo />
				</Col>
				<Col offset={1}>
					<ReeditarCadastro />
				</Col>
				<Col offset={1}>
					{(gerarDare.data || consultarDare.data) && <PagamentoPìnPad />}
				</Col>
			</Row>

			<Row justify="center">
				<Col offset={1}>{showSMS && <EnvioSMS />}</Col>

				<Col offset={1}>
					<FinalizarProcesso />
				</Col>
			</Row>

			<Row justify="center">
				<Col offset={1}>
					{(gerarDare.data || consultarDare.data) && <ImprimirDare />}
				</Col>
			</Row>
		</>
	);
};
