export const blobFileToLinkClick = (
	blobData: Blob,
	fileName: string,
	extensionFile: string,
) => {
	const url = window.URL.createObjectURL(new Blob([blobData]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', `${fileName}.${extensionFile}`);
	document.body.appendChild(link);
	link.click();
};
