import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_MUNICIPIO_POR_UF_REQUEST = '@DETRAN-CRV/CONSULTAR_MUNICIPIO_POR_UF_REQUEST',
	CONSULTAR_MUNICIPIO_POR_UF_SUCCESS = '@DETRAN-CRV/CONSULTAR_MUNICIPIO_POR_UF_SUCCESS',
	CONSULTAR_MUNICIPIO_POR_UF_FAILURE = '@DETRAN-CRV/CONSULTAR_MUNICIPIO_POR_UF_FAILURE',
}

export interface consultarMunicipioPorUF {
	status: number;
	data: OptionProps[];
	dataOutrosEstados: OptionProps[];
}
