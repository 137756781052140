import * as Yup from 'yup';
import { validaCpf } from 'utils/genericFunctions';

export interface IFormConsultarMutuario {
	cpf: string;
	numContrato: number | string;
}

export const initialValues: IFormConsultarMutuario = {
	cpf: '',
	numContrato: '',
};

export const schema = Yup.object<IFormConsultarMutuario>().shape(
	{
		cpf: Yup.string().test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF digitado inválido.',
			test: value => validaCpf(value),
		}),
		cpfNumContrato: Yup.string()
			.when(['cpf', 'numContrato'], {
				is: (cpf, numContrato) => cpf === '' && numContrato === '',
				then: Yup.string().required(
					'Preencha pelo menos um dos dois campos! CPF ou o Número do contrato.',
				),
			})
			.when(['cpf', 'numContrato'], {
				is: (cpf, numContrato) =>
					cpf !== '' && cpf.length !== 11 && numContrato === '',
				then: Yup.string().required(
					'Campo CPF: Tamanho mínimo de 11 caracteres.',
				),
			})
			.when(['cpf', 'numContrato'], {
				is: (cpf, numContrato) =>
					cpf === '' && numContrato !== '' && numContrato.length !== 7,
				then: Yup.string().required(
					'Campo Número do contrato: Tamanho mínimo de 7 caracteres.',
				),
			}),
	},
	[['cpf', 'numContrato']],
);
