import JustificativaAtendimento from 'components/Pages/JustificativaAtendimento';
import RecuperaAtendimentoMP from '../ReconhecimentoPaternidade';
import BuscarSolicitacao from '../ReconhecimentoPaternidade/BuscarSolicitacao';
import NovoCadastro from '../ReconhecimentoPaternidade/NovoCadastro';

// PATHS
import {
	ROUTE_MINISTERIO_PUBLICO_RECUPERAR_ATENDIMENTO,
	ROUTE_MINISTERIO_PUBLICO_RECONHECIMENTO_DE_PATERNIDADE,
	ROUTE_MINISTERIO_PUBLICO_RECONHECIMENTO_DE_PATERNIDADE_CADASTRO,
	ROUTE_MINISTERIO_PUBLICO_JUSTIFICATIVA_ATENDIMENTO,
} from './paths';

export const mpRoute = {
	name: 'Ministério Público',
	route: '/ministerio-publico',
};

export default [
	{
		path: ROUTE_MINISTERIO_PUBLICO_RECUPERAR_ATENDIMENTO,
		breadcrumb: [
			mpRoute,
			{
				name: 'Recupera Atendimento',
			},
		],
		Component: RecuperaAtendimentoMP,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_MINISTERIO_PUBLICO_JUSTIFICATIVA_ATENDIMENTO,
		breadcrumb: [
			mpRoute,
			{
				name: 'Justificativa atendimento',
			},
		],
		Component: JustificativaAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_MINISTERIO_PUBLICO_RECONHECIMENTO_DE_PATERNIDADE,
		breadcrumb: [
			mpRoute,
			{
				name: 'Reconhecimento de Paternidade',
			},
		],
		Component: BuscarSolicitacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_MINISTERIO_PUBLICO_RECONHECIMENTO_DE_PATERNIDADE_CADASTRO,
		breadcrumb: [
			mpRoute,
			{
				name: 'Reconhecimento de Paternidade',
			},
		],
		Component: NovoCadastro,
		isAuthenticated: true,
		exact: true,
	},
];
