import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultarInaptoRequest, Types } from './types';

// ACTIONS
import { consultarInaptoSuccess, consultarInaptoFailure } from './actions';

function* consultarInapto(request: ReducerAction) {
	const { payload }: { payload: ConsultarInaptoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`sockets/${CHANNEL}/consulta/inapto`,
		payload,
	);

	if (response.status === 200 || response.status === 204) {
		yield put(consultarInaptoSuccess(response));
	} else if (response.error) {
		yield put(consultarInaptoFailure(response.error));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_INAPTO_REQUEST, consultarInapto),
]);
