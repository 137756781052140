export enum Types {
	CADASTRAR_USUARIO_REQUEST = '@SGU/CADASTRAR_USUARIO_REQUEST',
	CADASTRAR_USUARIO_SUCCESS = '@SGU/CADASTRAR_USUARIO_SUCCESS',
	CADASTRAR_USUARIO_FAILURE = '@SGU/CADASTRAR_USUARIO_FAILURE',
	CADASTRAR_USUARIO_CLEAR = '@SGU/CADASTRAR_USUARIO_CLEAR',
}

export interface CadastrarUsuario {
	status: number;
	data: null | CadastrarUsuarioResponse;
}

export interface CadastrarUsuarioResponse {
	id: number;
	nome: string;
	email: string;
	endereco: string;
	cpf: string;
	rg: string;
	digitoRg: string;
	uf: string;
	isPossuiUsuarioMainframe: number;
	ddCelular: number | null;
	nrCelular: string;
	ddComercial: number | null;
	nrComercial: string;
	ddResidencial: number | null;
	nrResidencial: string;
	localidadesAssociadas: {
		id: number;
		codigo: number;
		nome: string;
		orgao: {
			id: number;
			descricao: string;
		};
	}[];
	perfil: {
		acessaSgu: boolean;
		codigo: number;
		codigoStatus: number;
		descricao: string;
	};
	status: {
		codigo: number;
		descricao: string;
	};
}

interface UsuarioData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarUsuarioRequest {
	nome: string;
	email: string;
	endereco: string;
	cpf: string;
	rg: string;
	digitoRg: string;
	uf: string;
	ddCelular: number | null;
	nrCelular: string;
	ddComercial: number | null;
	nrComercial: string;
	ddResidencial: number | null;
	nrResidencial: string;
	perfil: number;
	status: number;
	localidades: string[] | null;
	id?: number | string;
}
