import produce from 'immer';

// TYPES
import { Types, ConsultaExtratoResumoCondutor } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultaExtratoResumoCondutor = {
	status: 0,
	data: null,
	cpf: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaExtratoResumoCondutor {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_EXTRATO_RESUMO_CONDUTOR_REQUEST: {
				draft.status = 100;
				break;
			}

			case Types.CONSULTA_EXTRATO_RESUMO_CONDUTOR_SUCCESS: {
				if (action.payload.response.status === 204) {
					draft.data = INITIAL_STATE.data;
					draft.status = action.payload.response.status;
					draft.cpf = action.payload.request.cpf;
					return;
				}

				draft.data = action.payload.response.data;
				draft.status = action.payload.response.status;
				draft.cpf = action.payload.request.cpf;
				break;
			}

			case Types.CONSULTA_EXTRATO_RESUMO_CONDUTOR_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTA_EXTRATO_RESUMO_CONDUTOR_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.cpf = INITIAL_STATE.cpf;
				break;
			}

			default:
		}
	});
}
