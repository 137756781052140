import { ReducerAction } from 'store/modules/types';

import { ConsultarInaptoRequest, Types } from './types';

export function consultarInaptoRequest(
	payload: ConsultarInaptoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_INAPTO_REQUEST,
		payload,
	};
}
export function consultarInaptoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_INAPTO_SUCCESS,
		payload,
	};
}
export function consultarInaptoFailure(payload: boolean): ReducerAction {
	return {
		type: Types.CONSULTAR_INAPTO_FAILURE,
		payload,
	};
}
export function consultarInaptoClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_INAPTO_CLEAR,
		payload: null,
	};
}
