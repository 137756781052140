export enum Types {
	LICENCIAMENTO_VEICULO_REQUEST = '@DETRAN-CRV/LICENCIAMENTO_VEICULO_REQUEST',
	LICENCIAMENTO_VEICULO_SUCCESS = '@DETRAN-CRV/LICENCIAMENTO_VEICULO_SUCCESS',
	LICENCIAMENTO_VEICULO_FAILURE = '@DETRAN-CRV/LICENCIAMENTO_VEICULO_FAILURE',
	LICENCIAMENTO_VEICULO_CLEAR = '@DETRAN-CRV/LICENCIAMENTO_VEICULO_CLEAR',
}

export interface licenciamentoVeiculo {
	status: number;
	data: ficha | null;
}

interface ficha {
	anoFicha: number;
	chassi: string;
	codigoSSP: number;
	dataEmissaoDocumento: string;
	dataHoraExlcusao: string;
	dataInclusaoFicha: string;
	dataInclusaohistorico: string;
	fichaCadastral: string;
	numeroficha: number;
	municipio: {
		codigo: number;
		nome: string;
	};
	numeroFicha: number;
	opcao: string;
	placa: string;
	planilhaRenavam: string;
	renavam: number;
	retornoBin: string;
	retornoConsistencia: string;
	status: string;
}
