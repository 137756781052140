export enum Types {
	CONSULTA_CNH_ESTRANGEIRO_REQUEST = '@cnh/CONSULTA_CNH_ESTRANGEIRO_REQUEST',
	CONSULTA_CNH_ESTRANGEIRO_SUCCESS = '@cnh/CONSULTA_CNH_ESTRANGEIRO_SUCCESS',
	CONSULTA_CNH_ESTRANGEIRO_FAILURE = '@cnh/CONSULTA_CNH_ESTRANGEIRO_FAILURE',
	CONSULTA_CNH_ESTRANGEIRO_CLEAR = '@cnh/CONSULTA_CNH_ESTRANGEIRO_CLEAR',
}

export interface ConsultaCnhEstrangeiro {
	status: number;
	data: ConsultaCnhEstrangeiroData | null;
	dataError: ConsultaCnhEstrangeiroDataError | null;
	cpf: string | null;
}

export interface ConsultaCnhEstrangeiroData {
	cpf: string;
	dataCadastro: string;
	horaCadastro: string;
	usuarioCadastro: string;
	categoria: string;
	ciretran: string;
	identidade: string;
	identidadeUf: string;
	nomeMae: string;
	nomePai: string;
	identificacao: string;
	nacionalidade: string;
	dataNascimento: string;
	codNaturalidade: string;
	naturalidade: string;
	nome: string;
	numeroRg: string;
	orgaoEmissor: string;
	orgaoEmissorUf: string;
	pais1: string;
	pais2: string;
	numeroPgu: string;
	categoriaPretendida: string;
	dataPrimeiraHabilitacao: string;
	processo: string;
	numeroRegistro: string;
	rne: string;
	rneOrgaoEmissor: string;
	sexo: string;
	dataValidade: string;
	endereco: {
		codigo: string;
		logradouro: string;
		numero: string;
		complemento: string;
		cep: string;
		bairro: string;
		descricaoMunicipio: string;
		codigoMunicipio: string;
	};
}

export interface ConsultaCnhEstrangeiroDataError {
	transacao: string;
	codigo: number;
	mensagem: string;
}

export interface ConsultaCnhEstrangeiroRequest {
	cpf: string;
	usuario: string;
	senha: string;
}
