export enum Types {
	TXUT_REQUEST = '@DETRAN-CRV/TXUT_REQUEST',
	TXUT_SUCCESS = '@DETRAN-CRV/TXUT_SUCCESS',
	TXUT_FAILURE = '@DETRAN-CRV/TXUT_FAILURE',
	TXUT_CLEAR = '@DETRAN-CRV/TXUT_CLEAR',
}

export interface TXUT {
	status: number;
	mensagem: string;
	data: ITXUT;
}

export interface ITXUT {
	codigoRetorno: number;
	tipoCliente: number;
	tipoPesquisa: {
		codigo: string;
		descricao: string;
	};
	qtdeTaxas: number;
	veiculosTaxas: [
		{
			placa: string;
			renavam: string;
			cpfCnpj: string;
			codBanco: number;
			nsuProdesp: number;
			dataContabil: string;
			codServico: number;
			dataInclusaoTaxa: string;
			nsuBanco: number;
			valor: number;
			anoLcenciamento: number;
			taxaSefaz: string;
			statusTaxa: {
				codigo: string;
				descricao: string;
			};
		},
	];
}

export interface ITXUTRequest {
	tipoPesquisa: number;
	placa: string;
	renavam: string;
	cpfCnpj: string;
	codBanco: string | null;
	nsuProdesp: string | null;
	dataContabil: string | null;
}
