import React, { useCallback } from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

// COMPONENTS
import FormBox from 'components/Common/Form/FormBox';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask/index';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import BuscarEndereco, {
	IBuscarEnderecoResultado,
} from 'components/Common/SearchButtons/Endereco';
import Municipio from 'components/Common/Form/Fields/Municipio';

// UTILS
import { patternFormat, completaZeroEsquerda } from 'utils/genericFunctions';

// FORM
import { IFormCadastroRg } from '../../../form';

interface Props {
	formik: FormikProps<IFormCadastroRg>;
}

const DadosAdicionaisTrabalho: React.FC<Props> = ({ formik }) => {
	const { setFieldValue } = formik;

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado) => {
			setFieldValue('cepTrabalho', res.cep);
			setFieldValue('enderecoTrabalho', res.endereco.substring(0, 22));
			setFieldValue('bairroTrabalho', res.bairro.substring(0, 14));
			setFieldValue(
				'codigoMunicipioTrabalho',
				res.codigoMunicipio
					? patternFormat(
							completaZeroEsquerda(res.codigoNaturalidade, 6),
							'#####-#',
					  )
					: '',
			);
			setFieldValue('descMunicipioTrabalho', res.municipio);
		},
		[setFieldValue],
	);

	const handleEnderecoSelecionado = useCallback(
		(res: IBuscarEnderecoResultado) => {
			if (res.cep) setFieldValue('cepTrabalho', res.cep);
			if (res.endereco || res.tipoLogradouro)
				setFieldValue(
					'enderecoTrabalho',
					`${res.tipoLogradouro ? res.tipoLogradouro : ''} ${
						res.endereco ? res.endereco.substring(0, 22) : ''
					}`,
				);
			setFieldValue(
				'bairroTrabalho',
				res.bairro ? res.bairro.substring(0, 14) : '',
			);
			if (res.codigoMunicipio)
				setFieldValue(
					'codigoMunicipioTrabalho',
					res.codigoMunicipio
						? patternFormat(
								completaZeroEsquerda(res.codigoMunicipio, 6),
								'#####-#',
						  )
						: '',
				);
			setFieldValue(
				'descMunicipioTrabalho',
				res.municipio ? res.municipio : '',
			);
			setFieldValue('ufResidencia', res.uf ? res.uf : '');

			// APAGAR NÚMERO E COMPLEMENTO APÓS PREENCHER COM NOVO ENDEREÇO
			setFieldValue('numeroTrabalho', '');
			setFieldValue('complementoTrabalho', '');
		},
		[setFieldValue],
	);

	return (
		<>
			<FormBox title="Dados adicionais do trabalho">
				<Row gutter={[0, 10]}>
					<Col span={11}>
						<CEP
							name="cepTrabalho"
							titleSize="sm"
							defaultValue={formik.values.cepTrabalho}
							required={formik.values.entrega === 'T'}
							formik={formik}
							result={handleCepSelecionado}
							isCalledInService="IIRGD"
							instanceRequest="iirgd-dados-trabalho"
						/>
					</Col>

					<Col span={1}>
						<BuscarEndereco result={handleEnderecoSelecionado} />
					</Col>

					<Col span={12}>
						<Field
							as={Input}
							title="Logradouro"
							name="enderecoTrabalho"
							maxLength={22}
							required={formik.values.entrega === 'T'}
							error={!!formik.errors.enderecoTrabalho}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={InputMask}
							titleSize="sm"
							title="Número"
							name="numeroTrabalho"
							mask="99999"
							maskChar=""
							required={formik.values.entrega === 'T'}
							error={!!formik.errors.numeroTrabalho}
						/>
					</Col>

					<Col span={12}>
						<Field
							as={Input}
							title="Complemento"
							name="complementoTrabalho"
							maxLength={8}
							error={!!formik.errors.complementoTrabalho}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={Input}
							titleSize="sm"
							title="Bairro"
							name="bairroTrabalho"
							maxLength={14}
							error={!!formik.errors.bairroTrabalho}
						/>
					</Col>

					<Col span={12}>
						<Municipio
							codigoMunicipio="codigoMunicipioTrabalho"
							municipio="descMunicipioTrabalho"
							sizeInputMunicipio={160}
							sizeInputCodigoMunicipio={100}
							required={formik.values.entrega === 'T'}
							formik={formik}
							codigoValue={formik.values.codigoMunicipioTrabalho}
							disabled
						/>
					</Col>
				</Row>
			</FormBox>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default DadosAdicionaisTrabalho;
