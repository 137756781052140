import consultarFichaCadastralSaga from './ConsultarFichaCadastral/saga';
import excluirFichaCadastralSaga from './ExcluirFichaCadastral/saga';
import impressaoRenavamSaga from './ImpressaoRenavam/saga';
import impressaoLicenciamentoVeiculosSaga from './ImpressaoLicenciamentoVeiculos/saga';
import impressaoAlteracaoEnderecoSaga from './ImpressaoAlteracaoEndereco/saga';
import impressaoSegundaViaLicenciamentoSaga from './ImpressaoSegundaViaLicenciamento/saga';
import impressaoComunicacaoVendaSaga from './ImpressaoComunicacaoVenda/saga';
import impressaoOficioSegundaViaPlacaSaga from './ImpressaoOficioSegundaViaPlaca/saga';
import impressaoTrocaVoluntariaPlacaSaga from './ImpressaoTrocaVoluntariaPlaca/saga';
import andamentoProcessoSaga from './AndamentoProcesso/saga';

export const consultarFichaCadastral = consultarFichaCadastralSaga;
export const excluirFichaCadastral = excluirFichaCadastralSaga;
export const impressaoRenavam = impressaoRenavamSaga;
export const impressaoLicenciamentoVeiculos = impressaoLicenciamentoVeiculosSaga;
export const impressaoAlteracaoEndereco = impressaoAlteracaoEnderecoSaga;
export const impressaoSegundaViaLicenciamento = impressaoSegundaViaLicenciamentoSaga;
export const impressaoComunicacaoVenda = impressaoComunicacaoVendaSaga;
export const impressaoOficioSegundaViaPlaca = impressaoOficioSegundaViaPlacaSaga;
export const impressaoTrocaVoluntariaPlaca = impressaoTrocaVoluntariaPlacaSaga;
export const andamentoProcesso = andamentoProcessoSaga;
