import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { IImpressaoTrocaVoluntariaPlaca } from 'pages/DetranCrv/Processos/ImpressaoTrocaVoluntariaPlaca/form';
import { Types } from './types';
import {
	impressaoTrocaVoluntariaPlacaSuccess,
	impressaoTrocaVoluntariaPlacaFailure,
} from './actions';

function* impressaoTrocaVoluntariaPlacaRequest(request: ReducerAction) {
	const { payload }: { payload: IImpressaoTrocaVoluntariaPlaca } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`processos/${CHANNEL}/impressao/trocas-voluntarias-placas`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(impressaoTrocaVoluntariaPlacaSuccess(response));
	} else if (response.error) {
		yield put(impressaoTrocaVoluntariaPlacaFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.IMPRESSAO_TROCA_VOLUNTARIA_PLACA_REQUEST,
		impressaoTrocaVoluntariaPlacaRequest,
	),
]);
