// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function formaAquisicaoRequest() {
	return {
		type: Types.FORMA_AQUISICAO_REQUEST,
	};
}
export function formaAquisicaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.FORMA_AQUISICAO_SUCCESS,
		payload,
	};
}
export function formaAquisicaoFailure(payload: object): ReducerAction {
	return {
		type: Types.FORMA_AQUISICAO_FAILURE,
		payload,
	};
}
export function formaAquisicaoClear(): ReducerAction {
	return {
		type: Types.FORMA_AQUISICAO_CLEAR,
		payload: null,
	};
}
