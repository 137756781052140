import React from 'react';

import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';

// REDUX
import { ApplicationState } from 'store';

// COMPONENTS
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import { limparMascara } from 'utils/genericFunctions';

export const Header: React.FC = () => {
	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	return (
		<>
			{cadastroRg.form.principal && (
				<Row gutter={[0, 10]}>
					<Col span={5}>
						<InputMask
							title="RGE/CIN"
							titleSize={23}
							name="rg"
							defaultValue={limparMascara(cadastroRg.form.principal.numeroRg)}
							mask="99.999.999-**"
							maskChar=""
							readOnly
						/>
					</Col>

					<Col span={19}>
						<Input
							title="Nome"
							titleSize={55}
							name="nome"
							defaultValue={cadastroRg.form.principal.nome}
							readOnly
						/>
					</Col>
				</Row>
			)}
		</>
	);
};
