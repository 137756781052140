import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_UTILS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultarDigitaisClear,
	consultarDigitaisSuccess,
	consultarDigitaisFailure,
} from './actions';

function* consultarDigitaisRequest(request: ReducerAction) {
	const { payload } = request;

	yield put(consultarDigitaisClear());

	const response: ApiResponse = yield call(
		postApi,
		PATH_UTILS,
		`biometria/${CHANNEL}/consulta/digitais`,
		payload,
		undefined,
		true,
	);

	if (response.status === 200) {
		yield put(consultarDigitaisSuccess(response.data));
	} else {
		yield put(consultarDigitaisFailure(response.data));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_DIGITAIS_REQUEST, consultarDigitaisRequest),
]);
