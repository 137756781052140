export enum Types {
	ATUALIZAR_NATURALIDADE = '@SGU/ATUALIZAR_NATURALIDADE',
	ATUALIZAR_NATURALIDADE_SUCCESS = '@SGU/ATUALIZAR_NATURALIDADE_SUCCESS',
	ATUALIZAR_NATURALIDADE_FAILURE = '@SGU/ATUALIZAR_NATURALIDADE_FAILURE',
	ATUALIZAR_NATURALIDADE_CLEAR = '@SGU/ATUALIZAR_NATURALIDADE_CLEAR',
}

export interface AtualizarNaturalidade {
	status: number;
	data: NaturalidadeData | null;
}

interface NaturalidadeData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarNaturalidadeRequest {
	codigo?: string | number;
	digito: string | number;
	descricao: string;
}
