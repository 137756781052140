import { all } from 'redux-saga/effects';

import getToken from './getToken/saga';
import getECpfValidation from './getECpfValidation/saga';
import getCredentials from './getCredentials/saga';
import certificateValidate from './certificateValidate/saga';

export default all([
	getToken,
	getECpfValidation,
	getCredentials,
	certificateValidate,
]);
