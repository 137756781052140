import { userDataRegex } from 'utils/constants/regex';
import * as Yup from 'yup';

// FORM
import {
	IDesbloquearVeiculoPorSolicitante,
	initialValuesDesbloquearVeiculoPorSolicitante,
} from 'pages/DetranCrv/components/pages/ServicosDeVeiculos/DesbloquearVeiculoPorSolicitante/form';

// TYPES
import { ConsultaRestricoes } from 'store/modules/api/detranCrv/SolicitarDocumentos/desbloqueio/consultaRestricoes/types';
import { RegistraSolicitacoesDataSearch } from 'store/modules/api/detranCrv/SolicitarDocumentos/desbloqueio/registraSolicitacoes/types';

// UTILS
import { limparMascara, validaCpf } from 'utils/genericFunctions';

export const threatInitialValues = (
	consultaRestricoes: ConsultaRestricoes,
): IDesbloquearVeiculoPorSolicitante => {
	return {
		...initialValuesDesbloquearVeiculoPorSolicitante,
		cpfCnpj: consultaRestricoes.dataRequest?.cpfCnpj || '',
		placa: consultaRestricoes.dataRequest?.placa || '',
		renavam: consultaRestricoes.dataRequest?.renavam || '',
	};
};

export const threatValuesRequestRegistraSolicitacao = (
	values: IDesbloquearVeiculoPorSolicitante,
): RegistraSolicitacoesDataSearch => {
	return {
		placa: values?.placa || '',
		renavam: values?.renavam || '',
		cpfCnpj: values?.cpfCnpj || '',
		opcao: 18,
		documentos: [
			{
				conteudo: (values?.documentos && values?.documentos[0]?.conteudo) || '',
				tipoArquivo:
					(values?.documentos && values?.documentos[0]?.tipoArquivo) || '',
			},
		],
		servicoDigital: true,
		cpfSolicitante: values?.cpf || '',
		nomeCidadao: values?.nome || '',
		emailCidadao: values?.email || '',
		dddTelefoneCidadao:
			(values?.telefone && limparMascara(values.telefone).substring(0, 2)) ||
			'',
		telefoneCidadao:
			(values?.telefone && limparMascara(values.telefone).substring(2, 11)) ||
			'',
	};
};

export const schemaLicenciamentoObito =
	Yup.object<IDesbloquearVeiculoPorSolicitante>().shape({
		cpfCnpj: Yup.string().required(
			'O Campo CPF/CNPJ do proprietário é de preenchimento obrigatório.',
		),
		placa: Yup.string().required(
			'O Campo Placa é de preenchimento obrigatório.',
		),
		renavam: Yup.string().required(
			'O campo Renavam é de preenchimento obrigatório.',
		),
		nome: Yup.string().required('O Campo Nome é de preenchimento obrigatório.'),
		cpf: Yup.string()
			.required('O Campo CPF do solicitante é de preenchimento obrigatório.')
			.test({
				name: 'cpfValidation',
				exclusive: true,
				message: 'CPF digitado inválido.',
				test: value => validaCpf(value),
			}),
		telefone: Yup.string()
			.required('O Campo Telefone é de preenchimento obrigatório.')
			.matches(userDataRegex.cellPhoneOrPhoneNumberWithAreaCode, {
				message: 'Campo Inválido: Telefone.',
				excludeEmptyString: true,
			}),
		email: Yup.string()
			.required('O Campo E-mail é de preenchimento obrigatório.')
			.matches(userDataRegex.emailAddress, {
				message: 'Formato de e-mail inválido.',
				excludeEmptyString: true,
			}),
		documentos: Yup.array().min(
			1,
			'O Campo Documentos é de preenchimento obrigatório.',
		),
	});
