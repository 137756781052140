import React, { Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

import { consultarBloqueioDetranRenajudClear } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarBloqueioDetranRenajud/actions';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';

import BloqueioDetran from './BloqueioDetran';
import BloqueioRenajud from './BloqueioRenajud';

const ConsultaVeiculoBaseEstadualResultado: React.FC = () => {
	const dispatch = useDispatch();
	const { consultarBloqueioDetranRenajud } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	const handleClear = () => {
		dispatch(consultarBloqueioDetranRenajudClear());
	};

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Row>
				<Col span={24}>
					{consultarBloqueioDetranRenajud.data.bloqueioDetran && (
						<BloqueioDetran />
					)}

					{consultarBloqueioDetranRenajud.data.bloqueioRenajud && (
						<BloqueioRenajud />
					)}
				</Col>
			</Row>

			<Row justify="space-between" align="middle">
				<Col>
					<ButtonVoltar onClick={() => handleClear()} />
				</Col>

				<Col style={{ marginTop: '13px' }}>
					<Button className="no-print" onClick={() => window.print()}>
						Imprimir
					</Button>
				</Col>
			</Row>
		</Suspense>
	);
};

export default ConsultaVeiculoBaseEstadualResultado;
