import React from 'react';

// ANTD
import { Col, Row } from 'antd';

// COMPONENTS
import TypographyCommon from 'components/Common/Typography';

// PATHS
import Section from 'components/Common/Section';

// REDUX
import { ConsultaExtratoResumoCondutorData } from 'store/modules/api/detranCnh/eportal/consultaExtratoResumoCondutor/types';

// UTILS
import { isFilledValue, maskCPF } from 'utils/stringFunctions';
import { formatDate } from 'utils/genericFunctions';

interface Props {
	consultaExtratoResumoCondutor:
		| ConsultaExtratoResumoCondutorData['pcon']
		| null
		| undefined;
}

const DadosCondutor: React.FC<Props> = ({
	consultaExtratoResumoCondutor = null,
}) => {
	return (
		<>
			<Section title="Dados do Condutor (PCON)">
				<Row gutter={[0, 0]} align="top">
					<Col span={5}>
						<TypographyCommon
							text="Nome:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={19}>
						<TypographyCommon
							text={isFilledValue(consultaExtratoResumoCondutor?.nome)}
							type="label"
						/>
					</Col>
				</Row>
				<Row gutter={[0, 0]} align="top">
					<Col span={5}>
						<TypographyCommon
							text="Registro:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text={isFilledValue(consultaExtratoResumoCondutor?.registro)}
							type="label"
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text="CPF:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={9}>
						<TypographyCommon
							text={isFilledValue(maskCPF(consultaExtratoResumoCondutor?.cpf))}
							type="label"
						/>
					</Col>
				</Row>
				<Row gutter={[0, 0]} align="top">
					<Col span={5}>
						<TypographyCommon
							text="Nacionalidade:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text={isFilledValue(consultaExtratoResumoCondutor?.nacionalidade)}
							type="label"
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text="Naturalidade:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={9}>
						<TypographyCommon
							text={isFilledValue(consultaExtratoResumoCondutor?.naturalidade)}
							type="label"
						/>
					</Col>
				</Row>
				<Row gutter={[0, 0]} align="top">
					<Col span={5}>
						<TypographyCommon
							text="Data de Nascimento:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={19}>
						<TypographyCommon
							text={isFilledValue(
								formatDate(consultaExtratoResumoCondutor?.dataNascimento),
							)}
							type="label"
						/>
					</Col>
				</Row>
				<Row gutter={[0, 0]} align="top">
					<Col span={5}>
						<TypographyCommon
							text="Identidade N°:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text={isFilledValue(consultaExtratoResumoCondutor?.numeroRg)}
							type="label"
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text="Expedida por:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={4}>
						<TypographyCommon
							text={isFilledValue(consultaExtratoResumoCondutor?.orgaoEmissor)}
							type="label"
						/>
					</Col>
					<Col span={2}>
						<TypographyCommon
							text="U.F.:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={3}>
						<TypographyCommon
							text={isFilledValue(consultaExtratoResumoCondutor?.uf)}
							type="label"
						/>
					</Col>
				</Row>
				<Row gutter={[0, 0]} align="top">
					<Col span={24}>
						<TypographyCommon
							text={isFilledValue(consultaExtratoResumoCondutor?.linhaMensagem)}
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
				</Row>
			</Section>
		</>
	);
};
export default DadosCondutor;
