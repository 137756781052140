import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarInscricao } from './types';

export const INITIAL_STATE: ConsultarInscricao = {
	status: 0,
	data: null,
	request: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarInscricao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_INSCRICAO_REQUEST: {
				break;
			}
			case Types.SELECIONAR_INSCRICAO: {
				draft.data = { ...draft.data, inscricaoSelecionada: action.payload };
				break;
			}
			case Types.CONSULTAR_INSCRICAO_SUCCESS: {
				draft.status = action.payload.response.status;
				draft.data = action.payload.response.data;
				draft.request = action.payload.request;
				break;
			}
			case Types.CONSULTAR_INSCRICAO_NO_CONTENT: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.request = INITIAL_STATE.request;
				break;
			}
			case Types.CONSULTAR_INSCRICAO_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.request = INITIAL_STATE.request;
				break;
			}
			case Types.CONSULTAR_INSCRICAO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.request = INITIAL_STATE.request;
				break;
			}
			default:
		}
		return draft;
	});
}
