import React, { useMemo } from 'react';

// ANTD
import { Row, Col } from 'antd';

// COMPONENTS
import TypographyCommon from 'components/Common/Typography';

// UTILS
import { isFilledValue } from 'utils/stringFunctions';

// REDUX
import { PEBE } from 'store/modules/api/detranCrv/ConsultarResumoExtrato/pebe/types';

interface Props {
	data: PEBE;
}

const ExtratoPEBE: React.FC<Props> = ({ data }) => {
	const handleListRestrictions = useMemo(() => {
		let restricoes: string = '';

		if (data.data?.restricoes && data.data?.restricoes.length > 0) {
			data.data?.restricoes.forEach((item, index) => {
				if (
					data.data?.restricoes.length === 1 ||
					data.data?.restricoes.length === index + 1
				) {
					restricoes += item.restricao || '';
				} else {
					restricoes += `${item.restricao} | ` || '';
				}
			});
		}

		return restricoes || 'Não informado';
	}, [data.data]);

	return (
		<>
			{data.status === 200 && (
				<>
					<Row gutter={[0, 0]} align="top">
						<Col span={4}>
							<TypographyCommon
								text="Placa:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text={isFilledValue(data.data?.placa)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text="Renavam:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.renavam)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text="Chassi:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text={isFilledValue(data.data?.chassi)}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={4}>
							<TypographyCommon
								text="Situação do Veículo:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text={isFilledValue([
									data.data?.chassiRemarcado?.codigo,
									data.data?.chassiRemarcado?.descricao,
								])}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text="Lotação:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.lotacao)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text="Ano Fabricação:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text={isFilledValue(data.data?.anoFabricacao)}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={4}>
							<TypographyCommon
								text="Ano Modelo:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text={isFilledValue(data.data?.anoModelo)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text="Número Motor:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.numMotor)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text="Procedência:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text={isFilledValue(data.data?.procedencia)}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={4}>
							<TypographyCommon
								text="Situação do Veículo:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text={isFilledValue(data.data?.situacaoVeiculo)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text="Marca:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue([
									data.data?.marca?.codigo,
									data.data?.marca?.descricao,
								])}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text="Cor:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text={isFilledValue([
									data.data?.cor?.codigo,
									data.data?.cor?.descricao,
								])}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={4}>
							<TypographyCommon
								text="Combustível:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text={isFilledValue([
									data.data?.combustivel?.codigo,
									data.data?.combustivel?.descricao,
								])}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text="Município:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue([
									data.data?.municipio?.codigo,
									data.data?.municipio?.descricao,
								])}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text="UF:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={3}>
							<TypographyCommon
								text={isFilledValue(data.data?.uf)}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={4}>
							<TypographyCommon
								text="Restrições:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={4}>
							<TypographyCommon
								text={handleListRestrictions}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>
				</>
			)}

			{data.status === 406 && (
				<Row gutter={[0, 0]} align="top">
					<Col span={24}>
						<TypographyCommon
							text={data.mensagem}
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
				</Row>
			)}
		</>
	);
};

export default ExtratoPEBE;
