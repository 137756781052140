import React, { useState, useCallback, useEffect } from 'react';

import { Row, Col, Alert, Modal } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';
import { ROUTE_IIRGD_CONFERENCIA_DADOS } from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import { enviarSmsClear } from 'store/modules/api/iirgd/enviarSms/actions';
import { cadastroRgClear } from 'store/modules/api/iirgd/cadastroRg/actions';
import { consultaPesquisaCirgClear } from 'store/modules/api/iirgd/consultaPesquisaCirg/actions';
import { consultaPesquisaCidadaoClear } from 'store/modules/api/iirgd/consultaPesquisaCidadao/actions';
import { consultaPesquisaCriminalClear } from 'store/modules/api/iirgd/consultaPesquisaCriminal/actions';
import { protocoloFicClear } from 'store/modules/api/iirgd/protocoloFic/actions';
import { protocoloFicDigitalClear } from 'store/modules/api/iirgd/protocoloFicDigital/actions';
import { protocoloReusoClear } from 'store/modules/api/iirgd/protocoloReuso/actions';
import { dareClear } from 'store/modules/api/dare/actions';
// import { capturarBiometriaClear } from 'store/modules/api/biometria/capturarBiometria/actions';
import { contabilizacaoIirgdRequest } from 'store/modules/api/iirgd/contabilizacaoEmissaoCarteira/actions';
import { ContabilizacaoIirgdRequest } from 'store/modules/api/iirgd/contabilizacaoEmissaoCarteira/types';
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { emitirCinClear } from 'store/modules/api/rfb/emitirCin/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ColorBox from 'components/Common/ColorBox';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import { limparMascara, patternFormat } from 'utils/genericFunctions';

// COMPONENTS
import { disponibilzarDareClear } from 'store/modules/api/potencial/disponibilzarDare/actions';
import { ConsultarProcesso } from 'store/modules/api/rfb/consultarProcesso/types';
import { lockUrl } from 'store/modules/api/sgu/loginUnico/actions';
import { LibracaoCiliRequest } from 'store/modules/api/iirgd/liberacaoCili/types';
import {
	libracaoCiliRequest,
	libracaoCiliClear,
} from 'store/modules/api/iirgd/liberacaoCili/actions';
import { PrimeiraVia } from './PrimeiraVia';
import { SegundaVia } from './SegundaVia';
import { formatarContabilizacao, getMensagemReuso } from './functions';
import { getTipoCili } from './Buttons/ImprimirProtocolo/functions';

const StatusCadastro: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	const [modalColetaBiometrica, setModalColetaBiometrica] =
		useState<boolean>(false);

	const [exibirMsgColetaBiometrica, setExibirMsgColetaBiometrica] =
		useState<boolean>(false);

	const [successTitle, setSuccessTitle] = useState<boolean>(false);

	const [emitirCinEnviada, setEmitirCinEnviada] = useState<boolean>(false);

	const {
		enviarSms,
		cadastroRg,
		consultaPesquisaCriminal,
		consultaPesquisaCidadao,
		alterarObservacoes,
	} = useSelector((state: ApplicationState) => state.api.iirgd);

	const { disponibilzarDare } = useSelector(
		(state: ApplicationState) => state.api.potencial,
	);

	const { emitirCin } = useSelector((state: ApplicationState) => state.api.rfb);

	const { consultarProcesso } = useSelector(
		(state: ApplicationState) => state.api.rfb,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { confrontarDigitais, consultarDigitais } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { capturarBiometria } = useSelector(
		(state: ApplicationState) => state.api.biometria,
	);

	const { login, lockedUrl } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	// ESTATISTICA CPF
	useEffect(() => {
		if (
			alterarObservacoes.status === 200 &&
			cadastroRg?.form?.principal?.inscricaoCpf === 'true'
		) {
			const payload: IncluirRequest = {
				descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
				tipoDocumento: 'RG',
				numeroDocumento: cadastroRg.form.principal?.numeroRg ?? '',
				codigoLocalidade: Number(login.user.posto),
				idOrgao: login.user.idOrgao,
				cpf: login.user.cpf,
				descricaoServico: 'INSCRICAO_CPF',
				idMotivo: 16,
				numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
				observacao: alterarObservacoes.cpfCadastrado
					? `CPF Cadastrado com sucesso. ${alterarObservacoes.mensagemCPF}`
					: `CPF Não cadastrado. ${alterarObservacoes.mensagemCPF}`,
			};
			dispatch(estatisticaIncluirRequest(payload));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		alterarObservacoes,
		// cadastroRg,
		dispatch,
		login.user.cpf,
		login.user.idOrgao,
		login.user.posto,
		atendimento,
	]);

	// ESTATISTICA REUSO
	useEffect(() => {
		if (cadastroRg.tipo.reusoTotal || cadastroRg.tipo.reusoParcial) {
			const mensagemReuso = getMensagemReuso(cadastroRg);
			let tipo: string = '';
			if (cadastroRg.tipo.reusoTotal) {
				tipo = 'T';
			}
			if (cadastroRg.tipo.reusoParcial) {
				tipo = 'S';
			}
			const payload: IncluirRequest = {
				descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
				tipoDocumento: 'RG',
				numeroDocumento: cadastroRg.form.principal?.numeroRg ?? '',
				codigoLocalidade: Number(login.user.posto),
				idOrgao: login.user.idOrgao,
				cpf: login.user.cpf,
				descricaoServico: 'REUSO_CARTEIRA_DE_IDENTIDADE',
				observacao: mensagemReuso,
				idMotivo: cadastroRg.tipo.reusoParcial ? 13 : 0,
				flagConfronto: confrontarDigitais.resultado ? 'S' : 'N',
				descricaoDedosEnviados: confrontarDigitais?.posicaoDedo ?? '',
				numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
				totalizador: tipo,
			};
			dispatch(estatisticaIncluirRequest(payload));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		alterarObservacoes.cpfCadastrado,
		confrontarDigitais,
		dispatch,
		login,
		atendimento,
	]);

	// ESTATISTICA PAGAMENTO PIN PAD (DISPONIBILIZAR DARE)
	useEffect(() => {
		if (disponibilzarDare.status === 201) {
			const payload: IncluirRequest = {
				descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
				tipoDocumento: 'RG',
				numeroDocumento: cadastroRg.form.principal?.numeroRg ?? '',
				codigoLocalidade: Number(login.user.posto),
				idOrgao: login.user.idOrgao,
				cpf: login.user.cpf,
				descricaoServico: 'ENVIAR_DADOS_PINPAD',
				idMotivo: 16,
				numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
				observacao: `Código de retorno: ${disponibilzarDare.data?.codigo}`,
			};
			dispatch(estatisticaIncluirRequest(payload));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, disponibilzarDare, login, atendimento]);

	// ESTATISTICA GERA SENHA
	const [estatisticaGeraSenhaEnviada, setEstatisticaGeraSenhaEnviada] =
		useState<boolean>(false);
	const handleEstatisticaGeraSenha = useCallback(
		(mensagem: string) => {
			setEstatisticaGeraSenhaEnviada(true);
			const payload: IncluirRequest = {
				descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
				tipoDocumento: 'RG',
				numeroDocumento: cadastroRg.form.principal?.numeroRg ?? '',
				codigoLocalidade: Number(login.user.posto),
				idOrgao: login.user.idOrgao,
				cpf: login.user.cpf,
				descricaoServico: 'PROMOVER_SENHA',
				idMotivo: 16,
				numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
				observacao:
					mensagem || `Código de retorno: ${disponibilzarDare.data?.codigo}`,
			};
			dispatch(estatisticaIncluirRequest(payload));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, disponibilzarDare, login, atendimento],
	);

	useEffect(() => {
		if (!emitirCinEnviada && emitirCin.status !== 0) {
			const payload: IncluirRequest = {
				descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
				tipoDocumento: 'RG',
				numeroDocumento: cadastroRg.form.principal?.numeroRg ?? '',
				codigoLocalidade: Number(login.user.posto),
				idOrgao: login.user.idOrgao,
				cpf: login.user.cpf,
				descricaoServico: 'PROCESSO_CIN',
				idMotivo: 16,
				numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
				observacao: '',
				// emitirCin.status === 200
				// 	? `RG será emitido de conforme o decreto 10.977 - ${emitirCin.data?.hashCodeEmissao}`
				// 	: ` RG NÃO será emitido conforme decreto 10.977 - CPF do usuário inexistente. `,
			};
			dispatch(estatisticaIncluirRequest(payload));
			setEmitirCinEnviada(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, login, atendimento, emitirCin, emitirCinEnviada]);

	const { geraSenha } = useSelector(
		(state: ApplicationState) => state.api.relatorios,
	);

	useEffect(() => {
		if (!estatisticaGeraSenhaEnviada && geraSenha.status !== 0) {
			if (
				!cadastroRg.form.principal?.cpf ||
				!cadastroRg.form.principal?.email
			) {
				setEstatisticaGeraSenhaEnviada(true);
				handleEstatisticaGeraSenha(
					'CPF ou E-Mail, não informados no momento do cadastro.',
				);
			} else if (
				!cadastroRg.form.principal?.foneResidencia &&
				!cadastroRg.form.principal?.foneComercial &&
				!cadastroRg.form.principal?.celular
			) {
				setEstatisticaGeraSenhaEnviada(true);
				handleEstatisticaGeraSenha('Informar pelo menos um telefone.');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		geraSenha,
		handleEstatisticaGeraSenha,
		// preCadastroCidadao,
		estatisticaGeraSenhaEnviada,
	]);

	const handleEstatisticaTipoCili = useCallback(() => {
		const funcionalidadeHabilitadaSgu = login.user.parametrosLocalidade.filter(
			(item: { sigla: string }) => {
				return item.sigla === 'HABILITA_CILI_SIMPLIFICADA';
			},
		);

		const payload: IncluirRequest = {
			descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
			tipoDocumento: 'RG',
			numeroDocumento: cadastroRg.form.principal?.numeroRg ?? '',
			codigoLocalidade: Number(login.user.posto),
			idOrgao: login.user.idOrgao,
			cpf: login.user.cpf,
			descricaoServico: 'CILI_SIMPLIFICADA',
			idMotivo: 16,
			numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
			observacao: `Funcionalidade habilitada no SGU? ${
				funcionalidadeHabilitadaSgu ? 'SIM' : 'NÃO'
			} - Dentro da temporalidade? ${
				consultaPesquisaCidadao.selecionado?.afis === '*' ? 'SIM' : 'NÃO'
			} - Alterações nas perguntas? ${
				cadastroRg.perguntas.teveAlteracao ? 'SIM' : 'NÃO'
			} - Tipo cili simplificada? ${getTipoCili(
				consultaPesquisaCidadao,
				cadastroRg,
				confrontarDigitais,
				capturarBiometria,
				consultarDigitais,
				consultaPesquisaCriminal.restricaoCriminal,
			)}`,
		};
		dispatch(estatisticaIncluirRequest(payload));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		capturarBiometria,
		confrontarDigitais,
		consultaPesquisaCidadao,
		dispatch,
		login,
		atendimento,
		consultarDigitais,
	]);

	useEffect(() => {
		const tipo = getTipoCili(
			consultaPesquisaCidadao,
			cadastroRg,
			confrontarDigitais,
			capturarBiometria,
			consultarDigitais,
			consultaPesquisaCriminal.restricaoCriminal,
		);
		if (tipo && tipo !== '') {
			const payload: LibracaoCiliRequest = {
				usuario: login.mainframe.idUsuario,
				tipo,
				postoEntrega: login.user.posto || '',
				postoAtendimento: login.user.posto || '',
				registroNumero: cadastroRg.form.principal?.numeroRg.split('-')[0] ?? '',
				registroDigito: cadastroRg.form.principal?.numeroRg.split('-')[1] ?? '',
			};
			dispatch(libracaoCiliRequest(payload));
		} else {
			dispatch(libracaoCiliClear());
		}
	}, [
		cadastroRg,
		capturarBiometria,
		confrontarDigitais,
		consultaPesquisaCidadao,
		consultaPesquisaCriminal.restricaoCriminal,
		consultarDigitais,
		dispatch,
		login,
	]);

	// ESTATISTICA TIPO CILI
	useEffect(() => {
		handleEstatisticaTipoCili();
	}, [handleEstatisticaTipoCili]);

	const situacaoCin = (codigoSituacao: ConsultarProcesso[]) => {
		let situacao = false;
		codigoSituacao.forEach(item => {
			if (item.codigoSituacao === 3) {
				situacao = true;
			}
		});

		return situacao;
	};

	const handleContabilizacacao = useCallback(() => {
		const payload: ContabilizacaoIirgdRequest = formatarContabilizacao(
			atendimento.salvarAtendimento,
			cadastroRg,
			loginUnico.login,
			consultaPesquisaCriminal.restricaoCriminal,
			getTipoCili(
				consultaPesquisaCidadao,
				cadastroRg,
				confrontarDigitais,
				capturarBiometria,
				consultarDigitais,
				consultaPesquisaCriminal.restricaoCriminal,
			),
			confrontarDigitais.resultado,
			cadastroRg?.form?.principal?.dni === '10977' &&
				!situacaoCin(consultarProcesso.data || []),
		);
		dispatch(contabilizacaoIirgdRequest(payload));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		atendimento.salvarAtendimento,
		loginUnico.login,
		consultaPesquisaCriminal.restricaoCriminal,
		consultaPesquisaCidadao,
		consultarProcesso,
		confrontarDigitais,
		capturarBiometria,
		consultarDigitais,
		dispatch,
	]);

	useEffect(() => {
		handleContabilizacacao();
	}, [handleContabilizacacao]);

	useEffect(() => {
		if (lockedUrl) {
			dispatch(lockUrl(false));
		} else {
			history.goBack();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		// dispatch(capturarBiometriaClear());
		// dispatch(consultarDigitaisClear());
		dispatch(emitirCinClear());
	}, [dispatch]);

	const [isDisponibilizarDareSuccess, setIsDisponibilizarDareSuccess] =
		useState<boolean>(false);

	useEffect(() => {
		if (cadastroRg.finalizado) {
			setIsDisponibilizarDareSuccess(false);
		} else {
			setIsDisponibilizarDareSuccess(
				disponibilzarDare.status === 200 || disponibilzarDare.status === 201,
			);
		}
	}, [cadastroRg.finalizado, disponibilzarDare]);

	useEffect(() => {
		if (!cadastroRg.form.principal) {
			history.push(ROUTE_IIRGD);
		}
	}, [cadastroRg.form.principal, history]);

	useEffect(() => {
		dispatch(protocoloFicClear());
		dispatch(protocoloFicDigitalClear());
		dispatch(protocoloReusoClear());
		dispatch(disponibilzarDareClear());
	}, [dispatch]);

	const resetState = useCallback(() => {
		dispatch(cadastroRgClear());
		dispatch(consultaPesquisaCirgClear());
		dispatch(consultaPesquisaCidadaoClear());
		dispatch(consultaPesquisaCriminalClear());
		dispatch(dareClear());
		dispatch(enviarSmsClear());
		dispatch(protocoloFicClear());
		dispatch(protocoloFicDigitalClear());
		dispatch(protocoloReusoClear());
	}, [dispatch]);

	useEffect(() => {
		if (modalColetaBiometrica) {
			Modal.confirm({
				content: <h5>Encaminhar para a mesa de coleta.</h5>,
				style: {
					animationDuration: '0s',
					marginTop: '25vh',
				},
				cancelButtonProps: { hidden: true },
				width: 455,
				onOk: () => {
					if (!cadastroRg.finalizado) {
						setSuccessTitle(true);
					}
					setModalColetaBiometrica(false);
				},
			});
		}
	}, [cadastroRg.finalizado, modalColetaBiometrica]);

	const [exibirMsgConfirmacaoReuso, setExibirMsgConfirmacaoReuso] =
		useState<boolean>(false);

	useEffect(() => {
		if (
			(consultaPesquisaCidadao.selecionado?.afis === '*' ||
				cadastroRg.tipo.primeiraVia ||
				cadastroRg.config.acervo ||
				cadastroRg.tipo.segundaVia) &&
			!confrontarDigitais.resultado
		) {
			setModalColetaBiometrica(coleta => {
				if (coleta) {
					return false;
				}
				return true;
			});
			setExibirMsgConfirmacaoReuso(false);
			setExibirMsgColetaBiometrica(true);
			setHasLoaded(true);
			return;
		}
		if (confrontarDigitais.resultado === true) {
			setExibirMsgColetaBiometrica(false);
			setExibirMsgConfirmacaoReuso(true);
			Modal.confirm({
				content: (
					<h5>
						Reuso foi realizado com sucesso, cidadão dispensado da coleta.
					</h5>
				),
				style: {
					animationDuration: '0s',
					marginTop: '25vh',
				},
				cancelButtonProps: { hidden: true },
				width: 455,
				onOk: () => {},
			});
		}

		setSuccessTitle(true);
		setHasLoaded(true);
	}, [
		confrontarDigitais.resultado,
		cadastroRg.config.acervo,
		cadastroRg.tipo.primeiraVia,
		cadastroRg.tipo.reusoParcial,
		cadastroRg.tipo.reusoTotal,
		cadastroRg.tipo.segundaVia,
		consultaPesquisaCidadao,
		consultaPesquisaCriminal.restricaoCriminal,
	]);

	const handleButtonVoltar = useCallback(() => {
		resetState();
		history.push(ROUTE_IIRGD_CONFERENCIA_DADOS);
	}, [resetState, history]);

	const handleOutroServicoIIRGD = useCallback(() => {
		resetState();
		history.push(ROUTE_IIRGD);
	}, [resetState, history]);

	return (
		<>
			{hasLoaded && (
				<>
					{isDisponibilizarDareSuccess && (
						<Alert
							type="info"
							message=""
							description="Dados enviados com sucesso!"
							showIcon
						/>
					)}
					<Section title="Status do Cadastro">
						{enviarSms.status === 200 && (
							<Row gutter={[0, 20]}>
								<Col span={24}>
									<Alert
										type="info"
										showIcon
										message="SMS enviado com sucesso!"
										closable
										onClose={() => {
											dispatch(enviarSmsClear());
										}}
									/>
								</Col>
							</Row>
						)}

						{cadastroRg.finalizado && (
							<Row gutter={[0, 20]}>
								<Col span={24}>
									<Alert
										message="Processo Finalizado com Sucesso!"
										type="info"
										description=" "
										showIcon
									/>
								</Col>
							</Row>
						)}

						<Row gutter={[20, 10]} align="middle" justify="center">
							<Col span={16}>
								<ColorBox minHeight={200} bgColor="verde" title={successTitle}>
									{exibirMsgColetaBiometrica && (
										<Row gutter={[0, 10]}>
											<Col>
												<p style={{ color: 'red', fontWeight: 'bolder' }}>
													Encaminhar para a mesa de coleta.
												</p>
											</Col>
										</Row>
									)}

									{exibirMsgConfirmacaoReuso && (
										<Row gutter={[0, 10]}>
											<Col>
												<p style={{ color: 'red', fontWeight: 'bolder' }}>
													Reuso foi realizado com sucesso, cidadão dispensado da
													coleta.
												</p>
											</Col>
										</Row>
									)}

									<Row gutter={[0, 10]}>
										<Col>
											<p>
												<strong>RGE/CIN:</strong>
												<span style={{ marginLeft: '5px' }}>
													{limparMascara(cadastroRg.form.principal?.numeroRg)
														.length === 10
														? patternFormat(
																cadastroRg.form.principal?.numeroRg,
																'##.###.###-##',
														  )
														: patternFormat(
																cadastroRg.form.principal?.numeroRg,
																'##.###.###-#',
														  )}
												</span>
											</p>
											{/* {emitirCin.status === 200 && emitirCin.data && (
												<Row>
													<Col>
														<p style={{ fontWeight: 'bolder' }}>
															RG será emitido de conforme o decreto 10.977
														</p>
													</Col>
												</Row>
											)} */}
											{/* {emitirCin.status !== 0 &&
												emitirCin.status !== 100 &&
												emitirCin.status !== 200 &&
												emitirCin.dataError && (
													<Row>
														<Col>
															<p style={{ color: 'red', fontWeight: 'bolder' }}>
																RG NÃO será emitido conforme decreto 10.977 -
																Ocorreu um problema com o serviço de emissão do
																CIN
															</p>
														</Col>
													</Row>
												)} */}
											{cadastroRg?.form?.principal?.inscricaoCpf === 'true' &&
												!cadastroRg?.form?.principal?.cpf && (
													<Row>
														<Col>
															<p style={{ color: 'red' }}>
																Não foi possível realizar a inscrição do CPF na
																base da RFB.
															</p>
														</Col>
													</Row>
												)}

											{cadastroRg?.form?.principal?.inscricaoCpf === 'true' &&
												!cadastroRg.config.reedicao &&
												cadastroRg?.form?.principal?.cpf && (
													<Row>
														<Col>
															<p>
																<strong>CPF:</strong>
																<span style={{ marginLeft: '5px' }}>
																	{patternFormat(
																		cadastroRg?.form?.principal?.cpf,
																		'###.###.###-##',
																	)}
																</span>
															</p>
														</Col>
													</Row>
												)}
											<p>
												A continuidade deste processo só poderá ser realizada
												neste Posto de Atendimento.
											</p>
										</Col>
									</Row>

									{cadastroRg.tipo.primeiraVia && !cadastroRg.finalizado && (
										<PrimeiraVia />
									)}

									{!cadastroRg.finalizado && cadastroRg.tipo.segundaVia && (
										<SegundaVia />
									)}
								</ColorBox>
							</Col>
						</Row>
					</Section>

					<Row justify="space-between">
						<Col>
							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Col>

						<Col style={{ marginTop: '13px' }}>
							<ButtonImage
								src="outro-servico-iirgd"
								onClick={handleOutroServicoIIRGD}
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default StatusCadastro;
