import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IGerarDareState } from './types';

export const INITIAL_STATE: IGerarDareState = {
	status: 0,
	data: null,
	dareKit: {
		numDare: null,
		correios: '',
		quantidade: 1,
		finalizados: 0,
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IGerarDareState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.GERAR_DARE_REQUEST: {
				break;
			}
			case Types.GERAR_DARE_SUCCESS: {
				draft.data = action.payload;
				draft.status = 200;
				draft.dareKit.numDare = action.payload.numeroControleGuiaMae;

				break;
			}
			case Types.GERAR_DARE_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.GERAR_DARE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.DARE_KIT_REQUEST: {
				if (action.payload.quantidade) {
					draft.dareKit.quantidade = action.payload.quantidade;
				}
				if (action.payload.correios) {
					draft.dareKit.correios = action.payload.correios;
				}
				if (action.payload.finalizados) {
					draft.dareKit.finalizados += action.payload.finalizados;
				}
				break;
			}
			case Types.DARE_KIT_CLEAR: {
				draft.dareKit = INITIAL_STATE.dareKit;
				break;
			}

			default:
		}
		return draft;
	});
}
