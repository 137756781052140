import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirParametroSistemaRedisRequest } from './types';

export function excluirParametroSistemaRedisRequest(
	data: ExcluirParametroSistemaRedisRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_PARAMETRO_SISTEMA_REDIS,
		payload: data,
	};
}
export function excluirParametroSistemaRedisSuccess(
	data: object,
): ReducerAction {
	return {
		type: Types.EXCLUIR_PARAMETRO_SISTEMA_REDIS_SUCCESS,
		payload: data,
	};
}
export function excluirParametroSistemaRedisFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_PARAMETRO_SISTEMA_REDIS_FAILURE,
		payload: null,
	};
}
export function excluirParametroSistemaRedisClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_PARAMETRO_SISTEMA_REDIS_CLEAR,
		payload: null,
	};
}
