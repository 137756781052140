import React from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

import data from 'store/modules/api/mp/enum';

import FormBox from 'components/Common/Form/FormBox';
import Input from 'components/Common/Form/Input/Input';

import Radio from 'components/Common/Form/Radio';
import hasError from 'utils/getFormErrors';
import InputMask from 'components/Common/Form/Input/InputMask';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import { onlyLetters } from 'utils/genericFunctions';
import { ICadastroSolicitante } from '../form';
import DadosSolicitante from '../DadosSolicitante';

interface Props {
	formik: FormikProps<ICadastroSolicitante>;
	step: number;
	formDisabled: boolean;
}

const DadosResponsavel: React.FC<Props> = ({ formik, step, formDisabled }) => {
	return (
		<>
			<FormBox
				title={`Dados da mãe, pai ou Responsável legal ${
					formik.values.tipoSolicitante === 2 ? ' - Solicitante' : ''
				}`}
			>
				<Row gutter={[0, 10]}>
					<Col span={18}>
						<Field
							as={Input}
							title="Nome Completo"
							name="responsavel.nome"
							required={
								formik.values.tipoSolicitante === 2 ||
								formik.values.tipoSolicitante === 3
							}
							error={hasError(formik.errors, 'responsavel.nome')}
							readOnly={step === 2}
							maxLength={60}
							value={onlyLetters(formik.values.responsavel?.nome!)}
							disabled={formDisabled}
						/>
					</Col>
				</Row>

				{/* <Row gutter={[0, 10]}>
					<Col span={8}>
						{step === 1 && (
							<Field
								as={Radio}
								required={formik.values.tipoSolicitante === 2}
								options={data.sexo}
								title="Sexo"
								name="responsavel.sexo"
								defaultValue={formik.values.responsavel?.sexo}
								error={hasError(formik.errors, 'responsavel.sexo')}
							/>
						)}
						{step === 2 && (
							<Field
								as={Input}
								title="Sexo *"
								name="responsavel.sexo"
								value={
									formik.values.responsavel?.sexo === 'M'
										? 'MASCULINO'
										: 'FEMININO'
								}
								required={
									formik.values.tipoSolicitante === 2 ||
									formik.values.tipoSolicitante === 3
								}
								readOnly={step === 2}
							/>
						)}
					</Col>
				</Row> */}

				<Row gutter={[0, 10]}>
					<Col span={10}>
						<Field
							required={formik.values.tipoSolicitante === 2}
							title="Data de Nascimento"
							name="responsavel.dtNascimento"
							type="text"
							mask="99/99/9999"
							subtitle={step === 2 ? '' : '(DD/MM/AAAA)'}
							size={60}
							as={ValidDataInput}
							error={hasError(formik.errors, 'responsavel.dtNascimento')}
							formik={formik}
							onChange={(v: string) =>
								formik.setFieldValue('responsavel.dtNascimento', v)
							}
							readOnly={step === 2}
							disabled={formDisabled}
						/>
					</Col>
					<Col span={6}>
						{step === 1 && (
							<Field
								required={
									formik.values.tipoSolicitante === 2 ||
									formik.values.tipoSolicitante === 3
								}
								as={Radio}
								titleSize="sm"
								options={data.sexo}
								title="Sexo"
								defaultValue={formik.values.responsavel?.sexo}
								name="responsavel.sexo"
								error={hasError(formik.errors, 'responsavel.sexo')}
								disabled={formDisabled}
							/>
						)}
						{step === 2 && (
							<Field
								required={formik.values.tipoSolicitante === 2}
								as={Input}
								title="Sexo"
								titleSize="sm"
								name="responsavel.sexo"
								value={
									// eslint-disable-next-line no-nested-ternary
									formik.values.responsavel?.sexo === 'M'
										? 'MASCULINO'
										: formik.values.responsavel?.sexo === 'F'
										? 'FEMININO'
										: ''
								}
								readOnly={step === 2}
								disabled={formDisabled}
							/>
						)}
					</Col>
					<Col span={8}>
						<Field
							required={formik.values.tipoSolicitante === 2}
							title="CPF"
							name="responsavel.cpf"
							as={InputMask}
							mask="999.999.999-99"
							error={hasError(formik.errors, 'responsavel.cpf')}
							readOnly={step === 2}
							disabled={formDisabled}
						/>
					</Col>
				</Row>
				{(formik.values.tipoSolicitante === 2 ||
					formik.values.tipoSolicitante === 3) && (
					<DadosSolicitante
						formik={formik}
						step={step}
						solicitante="responsavel"
						formDisabled={formDisabled}
					/>
				)}
			</FormBox>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default DadosResponsavel;
