import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';

// TYPES
import { ReducerAction } from 'store/modules/types';

import { CHANNEL, PATH_RELATORIOS } from 'services/_path';
import { GeraSenhaRequest, Types } from './types';

// ACTIONS
import { geraSenhaSuccess, geraSenhaFailure } from './actions';

function* geraSenhaRequest(request: ReducerAction) {
	const { payload }: { payload: GeraSenhaRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_RELATORIOS,
		`protocolo/${CHANNEL}/gera-senha`,
		payload,
		undefined,
		true,
	);

	if (response.status === 200) {
		yield put(geraSenhaSuccess(response));
	} else {
		yield put(geraSenhaFailure(response.message[0] ?? ''));
	}
}

export default all([takeLatest(Types.GERA_SENHA_REQUEST, geraSenhaRequest)]);
