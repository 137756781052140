import { IConfirmacaoAtendimento } from 'pages/DetranCnh/components/pages/ConfirmacaoAtendimento/form';
import { EventoContabilizacao } from 'store/modules/types';

export enum Types {
	GERAR_RENACH_REQUEST = '@cnh/GERAR_RENACH_REQUEST',
	GERAR_RENACH_SUCCESS = '@cnh/GERAR_RENACH_SUCCESS',
	GERAR_RENACH_FAILURE = '@cnh/GERAR_RENACH_FAILURE',
}

export interface GerarRenach {
	status: number;
	data: null | GerarRenachData;
	requestData: null | GerarRenachRequest;
}

interface GerarRenachData {
	numRenachGerado: string;
	flagAgendarMedico: boolean;
	flagAgendarPsicologo: boolean;
}

interface Eventos {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

interface GerarRenachBase {
	evento?: EventoContabilizacao;
	usoFormularioEnum: string;
	cpf: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	sexo: string;
	dataNascimento: string;
	tipoDocumentoEnum: string;
	numDocumento?: string;
	orgaoExpDocumento?: string;
	orgaoUfExpDocumento?: string;
	nacionalidade: string;
	codigoNaturalidade: string | number;
	email?: string;
	celularDDD: string;
	celular?: string;
	logradouro: string;
	logradouroNumero: string | null;
	logradouroComplemento: string;
	logradouroBairro: string;
	logradouroCodMunicipio: string;
	logradouroCep: string;
	codCiretranCondutor: string;
	statusReusoEnum: string;
	flagAtividadeRemunerada: boolean | string;
	flagDeficienteFisico: boolean | string;
	flagSolicitaCNHDigital: boolean | string;
	codigoVerificador: string | null;
	categoriaAtual: string;
	categoriaPretendida: string;
	registro: string | null;
	numeroPgu: string | null;
	rneNumero?: string | null;
	rneOrgao?: string | null;
	rneUf: string | null;
	rneCodPaisCnhEstrangeira: string | null;
	rneDataValidadeCnhEstrangeira: string | null;
	rneDataPrimeiraHabilitacaoCnhEstrangeira: string | null;
	rneIdentificacaoCnhEstrangeira: string | null;
	flagMilitar: boolean | string;
	codEntidadeMilitar: string;
	numeroIdentMilitar: string;
	flagExamePosto: boolean | string;
	flagExameToxicologico?: boolean | string;
	flagProcurador: boolean | string;
	codCiretranHabilitacao: string;
	cpfUsuario: string;
	codLocalidade: string;
	nomeSocial?: string;
	paisAfetivo1?: string;
	paisAfetivo2?: string;
	dataPrimeiraHabilitacao?: string;
	naturalidade?: string;
	tipoProcesso?: {
		codigo: string;
		descricao: string;
	};
}

export interface GerarRenachForm extends GerarRenachBase {
	flagNomeSocial?: string;
	flagFiliacaoAfetiva?: string;
	uf?: string;
	confirmaEmail?: string;
	autorizaSMS?: string;
	autorizaEmail?: string;
	telefone?: string;
	logradouroDescMunicipio?: string;
	enderecoRecebimentoNumero?: string;
	enderecoRecebimentoBairro?: string;
	enderecoRecebimentoCep?: string;
	enderecoRecebimentoLogradouro?: string;
	enderecoRecebimentoComplemento?: string;
	enderecoRecebimentoMunicipio?: string;
	enderecoRecebimentoCodigoMunicipio?: string;
	logradouroUf?: string;
	origemNomeSocial?: string;
	dadosModalAtendimento?: IConfirmacaoAtendimento;
}
export interface GerarRenachRequest extends GerarRenachBase {
	evento: Eventos;
}
