import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	IRequestEnvioSms,
	enviarSmsSuccess,
	enviarSmsFailure,
} from './actions';

function* enviarSmsRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestEnvioSms } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		'enviarSMS',
		payload,
	);

	if (response.status === 200) {
		yield put(enviarSmsSuccess(response.status));
	} else if (response.error) {
		yield put(enviarSmsFailure());
	}
}

export default all([takeLatest(Types.ENVIAR_SMS_REQUEST, enviarSmsRequest)]);
