import produce from 'immer';

// TYPES
import { Types, gerarRenach } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: gerarRenach = {
	status: 0,
	data: null,
	dataRequest: null,
};

export default function gerarRenachReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): gerarRenach {
	return produce(state, draft => {
		switch (action.type) {
			case Types.GERAR_RENACH_RENOVACAO: {
				break;
			}
			case Types.GERAR_RENACH_RENOVACAO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.response.data;
				draft.dataRequest = action.payload.payload;
				break;
			}
			case Types.GERAR_RENACH_RENOVACAO_FAILURE: {
				break;
			}

			default:
		}
	});
}
