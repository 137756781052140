import * as Yup from 'yup';

export interface IConsultaBloqueioDetranRenajud {
	chassi: string;
	tipoBloqueio: string;
	selecao?: string;
}

export const initialValues: IConsultaBloqueioDetranRenajud = {
	chassi: '',
	tipoBloqueio: '',
	selecao: '1',
};

export function treatValues(
	values: IConsultaBloqueioDetranRenajud,
): IConsultaBloqueioDetranRenajud {
	const formattedData = {
		chassi: values.chassi,
		tipoBloqueio: values.selecao || '1',
	};

	return formattedData;
}

export const schema = Yup.object<IConsultaBloqueioDetranRenajud>().shape({
	chassi: Yup.string().required(`Campo Obrigatório: Chassi`),
});
