import { combineReducers } from 'redux';

import alterarFichaAluno from './alterarFichaAluno/reducer';
import cadastrarFichaAluno from './cadastrarFichaAluno/reducer';
import consultaAluno from './consultaAluno/reducer';
import consultaFichaAluno from './consultaFichaAluno/reducer';
import comboPaises from './comboPaises/reducer';
import comboMunicipios from './comboMunicipios/reducer';
import comboMunicipiosSP from './comboMunicipiosSP/reducer';

export default combineReducers({
	alterarFichaAluno,
	cadastrarFichaAluno,
	consultaAluno,
	consultaFichaAluno,
	comboPaises,
	comboMunicipios,
	comboMunicipiosSP,
});
