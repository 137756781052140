import * as Yup from 'yup';
import { validaCpfCnpj } from 'utils/genericFunctions';

export interface ITransferenciaProprietario {
	chassi: string;
	placa: string;
	tipoConsulta: 'TRANSFERENCIA';
	selecao?: 'chassi' | 'placa';
	documento: string;
	espelho: string;
	categoria?: number | string;
	dataContabil?: string;
	codBanco?: string;
	nsuProdesp?: string;
	restricaoFinanceira?: string;
	digital: boolean;
}

export const initialValues: ITransferenciaProprietario = {
	chassi: '',
	placa: '',
	documento: '',
	espelho: '',
	tipoConsulta: 'TRANSFERENCIA',
	selecao: 'chassi',
	restricaoFinanceira: 'NADA_CONSTA',
	categoria: '',
	dataContabil: '',
	codBanco: '',
	nsuProdesp: '',
	digital: true,
};

export const schema = Yup.object<ITransferenciaProprietario>().shape({
	placaRenavamChassi: Yup.string().when(['placa', 'chassi'], {
		is: (placa, chassi) => placa === '' && chassi === '',
		then: Yup.string().required(`Campo Obrigatório: Chassi ou Placa.`),
	}),
	documento: Yup.string()
		.required('Campo Obrigatório: CPF/CNPJ Proprietário.')
		.test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF/CNPJ inválido.',
			test: value => value === '' || validaCpfCnpj(value),
		}),
	categoria: Yup.string().required(`Campo Obrigatório: Categoria.`),
	documentoCategoria: Yup.string().when(['documento', 'categoria'], {
		is: (documento, categoria) =>
			documento.length <= 11 && categoria.toString() === '3',
		then: Yup.string().required(
			'Categoria oficial somente permitido para pessoa jurídica (CNPJ).',
		),
	}),
	espelho: Yup.string().required('Campo Obrigatório: Numero do Espelho.'),
	// restricaoFinanceira: Yup.string().required('Campo Obrigatório: ')
});
