import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarTiposLogradouros } from './types';

export const INITIAL_STATE: ConsultarTiposLogradouros = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarTiposLogradouros {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_TIPOS_LOGRADOUROS_REQUEST: {
				break;
			}

			case Types.CONSULTAR_TIPOS_LOGRADOUROS_SUCCESS: {
				draft.status = action.payload.status;

				const arrayTiposLogradouros = action.payload.data.valores.map(
					(key: string) => {
						return { value: key, label: key };
					},
					{},
				);
				draft.data = arrayTiposLogradouros;
				break;
			}

			case Types.CONSULTAR_TIPOS_LOGRADOUROS_FAILURE: {
				draft.status = action.payload.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.LIMPAR_CONSULTAR_TIPOS_LOGRADOUROS: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
