import { ReducerAction } from 'store/modules/types';
import { ConsultarContatoRequest, Types } from './types';

export function consultarContatoRequest(
	payload: ConsultarContatoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_CONTATO_REQUEST,
		payload,
	};
}
export function consultarContatoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_CONTATO_SUCCESS,
		payload,
	};
}
export function consultarContatoFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_CONTATO_FAILURE,
		payload: null,
	};
}

export function limparConsultarContato(): any {
	return {
		type: Types.LIMPAR_CONSULTAR_CONTATO,
	};
}
