import {
	IRequestDocumentos,
	IRequestStatusDocumentos,
} from 'store/modules/api/seds/vivaLeite/documentos/types';
import * as Yup from 'yup';

export interface IEnvioConsultaDocumentos {
	codigo_documento: number | string;
	outro_documento: string;
	documento: string;
	type?: string;
}

export const InitialBuscaDocumentos: IRequestDocumentos = {
	servico: 'ATUALIZACAO CADASTRAL',
	origem: 'PORTAL',
	etapa: 'busca_lista_documentos',
};

export const InitialBuscaStatusDocumentos: IRequestStatusDocumentos = {
	servico: 'PRESTACAO CONTAS',
	origem: 'BALCAO',
	num_pan: 0,
};

export const InitialValues: IEnvioConsultaDocumentos = {
	codigo_documento: '',
	outro_documento: '',
	documento: '',
	type: '',
};

export const schema = Yup.object<IEnvioConsultaDocumentos>().shape({
	codigo_documento: Yup.number()
		.required('Campo Obrigatório: Incluir Documento.')
		.nullable()
		.transform((value: string, originalValue: string) =>
			originalValue.toString().trim() === '' ? null : value,
		),
	documento: Yup.string().required('Documento não informado.'),
	type: Yup.string().test({
		name: 'Format',
		exclusive: true,
		message: 'Tipo de arquivo inválido!',
		test: value => value === '' || value === 'pdf',
	}),
	outro_documento: Yup.string().when('codigo_documento', {
		is: codigo_documento => codigo_documento === 7,
		then: Yup.string().test({
			name: 'codigo',
			exclusive: true,
			message: 'Campo Obrigatório: Descrição Documento.',
			test: value => value !== '',
		}),
	}),
});
