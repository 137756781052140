export enum Types {
	CONSULTAR_SUFIXO = '@sgu/CONSULTAR_SUFIXO',
	CONSULTAR_SUFIXO_SUCCESS = '@sgu/CONSULTAR_SUFIXO_SUCCESS',
	CONSULTAR_SUFIXO_FAILURE = '@sgu/CONSULTAR_SUFIXO_FAILURE',
	CONSULTAR_SUFIXO_CLEAR = '@sgu/CONSULTAR_SUFIXO_CLEAR',
}

export interface ConsultarSufixos {
	status: number;
	data: null | SufixosData[];
	totalRegistrosConsulta: number;
}

export interface SufixosData {
	codigo?: string | number;
	descricao: string;
}

export interface ConsultarSufixosRequest {
	registro_inicial?: number;
	limite?: number;
	current?: number;
	descricao?: string;
}
