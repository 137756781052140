import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_CIN } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import { consultarCpfCinSuccess, consultarCpfCinFailure } from './actions';

function* consultarCpfCinRequest(request: ReducerActionRotaSP) {
	const { payload, token }: { payload: string; token?: string } = request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_CIN,
		`cpf/${payload}`,
	);

	if (response.status === 200) {
		yield put(consultarCpfCinSuccess(response.data));
	} else {
		yield put(consultarCpfCinFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_CIN_CPF_REQUEST, consultarCpfCinRequest),
]);
