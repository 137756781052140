// TYPES
import { ReducerAction } from 'store/modules/types';
import { SinalizaResolucaoConsultaAtendimentoRequest, Types } from './types';

export function sinalizaResolucaoConsultaAtendimentoRequest(
	payload: SinalizaResolucaoConsultaAtendimentoRequest,
): ReducerAction {
	return {
		type: Types.SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_REQUEST,
		payload,
	};
}
export function sinalizaResolucaoConsultaAtendimentoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_SUCCESS,
		payload,
	};
}
export function sinalizaResolucaoConsultaAtendimentoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_FAILURE,
		payload,
	};
}
export function sinalizaResolucaoConsultaAtendimentoClear(): ReducerAction {
	return {
		type: Types.SINALIZA_RESOLUCAO_CONSULTA_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
