export enum Types {
	PEPM_REQUEST = '@DETRAN-CRV/PEPM_REQUEST',
	PEPM_SUCCESS = '@DETRAN-CRV/PEPM_SUCCESS',
	PEPM_FAILURE = '@DETRAN-CRV/PEPM_FAILURE',
	PEPM_CLEAR = '@DETRAN-CRV/PEPM_CLEAR',
}

export interface PEPM {
	status: number;
	mensagem: string;
	data: IPEPM;
}

export interface IPEPM {
	tipoCliente: number;
	codigoRetorno: number;
	placa: string;
	renavam: string;
	chassi: string;
	cpfCnpj: string;
	nomeProprietario: string;
	anoLicenciamento: number;
	dataEmissaoCRV: string;
	dataLicenciamento: string;
	municipioRegistro: {
		codigo: string;
		descricao: string;
	};
	combustivel: {
		codigo: string;
		descricao: string;
	};
	categoria: {
		codigo: string;
		descricao: string;
	};
	marcaModelo: {
		codigo: string;
		descricao: string;
	};
	tipoPesquisa: {
		codigo: string;
		descricao: string;
	};
	tipoDocumentoProprietario: {
		codigo: string;
		descricao: string;
	};
	tipoProprietario: {
		codigo: string;
		descricao: string;
	};
}

export interface IPEPMRequest {
	tipoPesquisa: number;
	chassi: string;
	placa: string;
	renavam: string;
}
