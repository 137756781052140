import MenuScanner from 'components/Pages/Scanner/Menu';

// PATHS
import JustificativaAtendimento from 'components/Pages/JustificativaAtendimento';
import {
	ROUTE_DETRAN_CRV_CONSULTAR_BLOQUEIO_DETRAN_RENAJUD,
	ROUTE_DETRAN_CRV_CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_RESULTADO,
	ROUTE_DETRAN_CRV_JUSTIFICATIVA_ATENDIMENTO,
	ROUTE_DETRAN_CRV_MENU_SCANNER,
	ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_BASE_ESTADUAL_CONSULTAR,
	ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_BASE_ESTADUAL_RESULTADO,
	ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_OUTRAS_BASES_CONSULTAR,
	ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_OUTRAS_BASES_RESULTADO,
	ROUTE_DETRAN_CRV_RECUPERAR_ATENDIMENTO,
} from './paths';

// CONSULTAR VEICULOS
import consultarVeiculosRoute from '../ConsultarVeiculos/routes';

// PROCESSOS
import processosRoute from '../Processos/routes';

// MULTAS RENAINF
import multasRenainfRoute from '../MultasRenainf/routes';

// SOLICITAR DOCUMENTOS
import solicitarDocumentosRoute from '../SolicitarDocumentos/routes';

// INTENÇÃO DE VENDAS
import intencaoVendaRoute from '../IntencaoDeVenda/routes';

// CONSULTAR RESTRIÇÕES
import ConsultarBloqueioDetranRenajud from '../ConsultarRestricoes/ConsultarBloqueioDetranRenajud';
import ConsultarBloqueioDetranRenajudResultado from '../ConsultarRestricoes/ConsultarBloqueioDetranRenajud/ResultadoConsulta';

// CONSULTAR RESUMO - EXTRATO - BASE ESTADUAL
import ConsultarResumoExtratoBaseEstadual from '../ConsultarResumoExtrato/BaseEstadual/Consultar';
import ConsultarResumoExtratoBaseEstadualResultado from '../ConsultarResumoExtrato/BaseEstadual/Resultado';

// CONSULTAR RESUMO - EXTRATO - OUTRAS BASES
import ConsultarResumoExtratoOutrasBases from '../ConsultarResumoExtrato/OutrasBases/Consultar';
import ConsultarResumoExtratoOutrasBasesResultado from '../ConsultarResumoExtrato/OutrasBases/Resultado';

// RECUPERA ATENDIMENTO
import RecuperaAtendimentoDetranCrv from '../RecuperarAtendimento';

export const detranCrvRoute = {
	name: 'DETRAN CRV',
	route: '/detran-crv',
};

const routes = [
	{
		path: ROUTE_DETRAN_CRV_MENU_SCANNER,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Menu Scanner',
			},
		],
		Component: MenuScanner,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_BASE_ESTADUAL_CONSULTAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consulta Resumo - Extrato - Base Estadual',
			},
		],
		Component: ConsultarResumoExtratoBaseEstadual,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_BASE_ESTADUAL_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consulta Resumo - Extrato - Base Estadual / Resultado',
			},
		],
		Component: ConsultarResumoExtratoBaseEstadualResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_OUTRAS_BASES_CONSULTAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consulta Resumo - Extrato - Outras Bases',
			},
		],
		Component: ConsultarResumoExtratoOutrasBases,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_OUTRAS_BASES_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consulta Resumo Extrato - Outras Bases / Resultado',
			},
		],
		Component: ConsultarResumoExtratoOutrasBasesResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_RECUPERAR_ATENDIMENTO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Recupera Atendimento',
			},
		],
		Component: RecuperaAtendimentoDetranCrv,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_JUSTIFICATIVA_ATENDIMENTO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Justificativa atendimento',
			},
		],
		Component: JustificativaAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CONSULTAR_BLOQUEIO_DETRAN_RENAJUD,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Bloqueio DETRAN / RENAJUD',
			},
		],
		Component: ConsultarBloqueioDetranRenajud,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Bloqueio DETRAN / RENAJUD',
			},
		],
		Component: ConsultarBloqueioDetranRenajudResultado,
		isAuthenticated: true,
		exact: true,
	},
];

const detranCrvRoutes = routes.concat(
	consultarVeiculosRoute,
	multasRenainfRoute,
	processosRoute,
	solicitarDocumentosRoute,
	intencaoVendaRoute,
);

export default detranCrvRoutes;
