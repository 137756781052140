import React, { useState, useEffect } from 'react';

import { Row, Col, Divider } from 'antd';
import { FormikProps } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultaEnderecosRequest } from 'store/modules/api/detranCnh/condutor/enderecos/actions';

import ListaEnderecos from './ListaEnderecos';
import CadastroEndereco from './CadastroEndereco';

// FORM
import { IDataEndereco, treatValues } from './form';

interface IProps {
	cpf: string;
	formik: FormikProps<any>;
}

const EnderecoEntrega: React.FC<IProps> = ({ cpf = '', formik }) => {
	const dispatch = useDispatch();

	const [dataEndereco, setDataEndereco] = useState<IDataEndereco>({
		cep: '',
		numero: '',
		bairro: '',
		logradouro: '',
		complemento: '',
		nomeMunicipio: '',
		codigoMunicipio: 'Domicílio',
		tipo: '',
	});

	const [dataEnderecoRecebimento, setDataEnderecoRecebimento] = useState<
		IDataEndereco[] | undefined
	>([]);

	useEffect(() => {
		if (cpf) {
			dispatch(consultaEnderecosRequest({ cpf }));
		}
	}, [cpf, dispatch]);

	const { consultaEndereco } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	useEffect(() => {
		if (consultaEndereco.data?.mensagem === 'CONSULTA EFETUADA COM SUCESSO') {
			const listaDeTodosEnderecos: IDataEndereco[] = [];

			if (consultaEndereco.data?.enderecosRecebimento) {
				consultaEndereco.data?.enderecosRecebimento.map(enderecoRecebimento => {
					const formattedEnderecoRecebimento = {
						...enderecoRecebimento,
						tipo: 'Recebimento',
					};

					listaDeTodosEnderecos.push(formattedEnderecoRecebimento);
				});
			}

			if (
				consultaEndereco.data?.enderecoCadastro &&
				consultaEndereco.data?.enderecoCadastro.logradouro !== ''
			) {
				const formattedEnderecoCadastrado = {
					...consultaEndereco.data?.enderecoCadastro,
					tipo: 'Domicílio',
				};

				listaDeTodosEnderecos.push(formattedEnderecoCadastrado);
			}

			const updatedData = listaDeTodosEnderecos.map(item => treatValues(item));

			setDataEnderecoRecebimento(updatedData);
		}
	}, [consultaEndereco.data]);

	const onSelect = (record: any, selected: any) => {
		setDataEndereco(selected);
	};

	// useEffect(() => {
	// 	// console.log();
	// 	// console.log('Data dataEndereco useEffect: ', dataEndereco.cep);

	// }, [dataEndereco, dispatch]);

	return (
		<>
			<Row>
				<Col span={12}>
					<h1 style={{ opacity: 0.7, marginTop: 25 }}>
						Endereço de Recebimento
					</h1>
				</Col>
			</Row>
			<Divider
				style={{
					marginTop: 0,
					marginBottom: 10,
					backgroundColor: '#000',
					opacity: 0.6,
				}}
			/>
			<Row gutter={[0, 20]}>
				<Col span={24}>
					{dataEnderecoRecebimento && dataEnderecoRecebimento.length > 0 && (
						<ListaEnderecos
							onSelect={onSelect}
							dataEnderecoRecebimento={dataEnderecoRecebimento}
						/>
					)}
					{dataEndereco.cep !== '' ? (
						<CadastroEndereco
							formik={formik}
							renderData={dataEndereco}
							tipoProcedimento={false}
						/>
					) : (
						<CadastroEndereco formik={formik} tipoProcedimento={false} />
					)}
				</Col>
			</Row>
		</>
	);
};

export default EnderecoEntrega;
