import { combineReducers } from 'redux';

import sexos from './sexos/reducer';
import estados from './estados/reducer';
import municipios from './municipios/reducer';
import participacoesAudiencia from './participacoesAudiencia/reducer';
import formaAquisicao from './formaAquisicao/reducer';
import tiposContratacao from './tiposContratacao/reducer';
import tiposDocumentosCompra from './tiposDocumentosCompra/reducer';
import formasDevolucao from './formasDevolucao/reducer';
import formasPagamento from './formasPagamento/reducer';
import pedidosConsumidor from './pedidosConsumidor/reducer';
import tiposResolucao from './tiposResolucao/reducer';
import tiposChavePix from './tiposChavePix/reducer';

export default combineReducers({
	sexos,
	estados,
	municipios,
	participacoesAudiencia,
	formaAquisicao,
	tiposContratacao,
	tiposDocumentosCompra,
	formasDevolucao,
	formasPagamento,
	pedidosConsumidor,
	tiposResolucao,
	tiposChavePix,
});
