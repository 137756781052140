import { ICaracteristicaVeiculo } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/types';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';

import * as Yup from 'yup';

export interface ITrocaVoluntariaPlaca {
	tipoPesquisa: 'Chassi' | 'Placa';
	chassi: string;
	placa: string;
	categoria?: string | number;
}

export const initialValues: ITrocaVoluntariaPlaca = {
	tipoPesquisa: 'Chassi',
	chassi: '',
	placa: '',
	categoria: '',
};

export const schema = Yup.object<ITrocaVoluntariaPlaca>().shape({
	chassi: Yup.string().when('tipoPesquisa', {
		is: tipoPesquisa => tipoPesquisa === 'Chassi',
		then: Yup.string()
			.required('Campo Obrigatório: Documento / Chassi.')
			.max(22, 'Campo Chassi: Tamanho máximo de 22 caracteres.'),
	}),
	categoria: Yup.string().required(`Campo Obrigatório: Categoria.`),
	placa: Yup.string().when('tipoPesquisa', {
		is: tipoPesquisa => tipoPesquisa === 'Placa',
		then: Yup.string().required('Campo Obrigatório: Documento / Placa.'),
	}),
});

export const treatRequestSguStatistics = (
	user: IUser,
	formData: ITrocaVoluntariaPlaca,
	data: ICaracteristicaVeiculo,
): IncluirRequest => {
	const payload: IncluirRequest = {
		observacao: `Chassi: ${
			formData?.chassi || data.caracteristicaVeiculo?.chassi || 'N/A'
		}, Placa: ${
			formData?.placa || data.caracteristicaVeiculo?.placa || 'N/A'
		}, Renavam Gerada ${data.caracteristicaVeiculo?.renavam || 'N/A'}`,
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento: '',
		codigoLocalidade: (user?.posto && Number(user.posto)) || 0,
		idOrgao: 5,
		cpf: user.cpf,
		descricaoServico: 'Troca Voluntária de Placa',
		idMotivo: 16,
	};

	return payload;
};
