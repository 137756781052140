import React from 'react';

import { Col, Row } from 'antd';

// REDUX
import {
	IConsultarDare,
	ITaxaConsultarDare,
} from 'store/modules/api/dare/consultarDare/types';

// COMPONENTS
import SimpleTable from 'components/Common/Table';

// UTILS
import {
	isEqualDates,
	formatDate,
	patternFormat,
} from 'utils/genericFunctions';
import { formatCurrency } from 'utils/numberFunctions';

// COMPONENTS
// import Selecionar from './Selecionar';

// STYLED
import { Container } from './styled';

interface Props {
	record: IConsultarDare;
	index: number;
}

const DetalheFilhote: React.FC<Props> = ({ record }) => {
	const handleRowClassName = (taxa: ITaxaConsultarDare) => {
		const date = new Date();

		if (
			taxa.dataVinculoRg &&
			isEqualDates(
				`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
					2,
					'0',
				)}-${String(date.getDate()).padStart(2, '0')}`,
				taxa.dataVinculoRg.split(' ')[0],
			)
		)
			return 'green';

		return '';
	};

	const columns = [
		{
			dataIndex: 'numeroControleGuiaFilhote',
			align: 'center',
			title: 'Filhote',
		},
		{
			dataIndex: 'identificador',
			title: 'Número do RGE/CIN',
			align: 'center',
			render: (text: string, taxasRes: ITaxaConsultarDare) => {
				return patternFormat(taxasRes.identificador, '##.###.###-#');
			},
		},
		{
			dataIndex: 'dataVinculoRg',
			title: 'Data Vinculação',
			align: 'center',
			render: (text: string, taxasRes: ITaxaConsultarDare) => {
				return formatDate(taxasRes.dataVinculoRg);
			},
		},
		{
			dataIndex: 'valorReceita',
			title: 'Valor Taxa',
			align: 'center',
			render: (text: string, taxasRes: ITaxaConsultarDare) => {
				return formatCurrency(taxasRes.valorReceita);
			},
		},
		{
			dataIndex: 'statusDescricao',
			title: 'Status',
			align: 'center',
			// render: (text: string, taxasRes: ITaxaConsultarDare) => {
			// 	return handleSituacao(taxasRes.status);
			// },
		},
	];

	return (
		<>
			<Container>
				<Row justify="center">
					<Col span={22}>
						<SimpleTable
							headers={columns}
							body={record.taxasRes}
							size="sm"
							striped
							bold
							hover={false}
							pagination={false}
							rowClassName={handleRowClassName}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default DetalheFilhote;
