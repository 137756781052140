import { all } from 'redux-saga/effects';

import atualizarPerfilSaga from './atualizarPerfil/saga';
import cadastrarPerfilSaga from './cadastrarPerfil/saga';
import consultarPerfiSaga from './consultarPerfis/saga';
import excluirPerfilSaga from './excluirPerfil/saga';

export default all([
	atualizarPerfilSaga,
	cadastrarPerfilSaga,
	consultarPerfiSaga,
	excluirPerfilSaga,
]);
