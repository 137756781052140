import { Types } from './types';

export function consultaTipoRequest() {
	return {
		type: Types.CONSULTA_TIPO_REQUEST,
	};
}
export function consultaTipoSuccess(payload: any) {
	return {
		type: Types.CONSULTA_TIPO_SUCCESS,
		payload,
	};
}
export function consultaTipoFailure(payload: []): any {
	return {
		type: Types.CONSULTA_TIPO_FAILURE,
		payload,
	};
}
