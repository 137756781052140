import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, IDadosConsulta } from './types';

export const INITIAL_STATE: IDadosConsulta = {
	data: {
		dataNascimento: '',
		filtrosNomes: {
			nomeAluno: '',
			nomeMae: '',
			nomePai: '',
		},
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IDadosConsulta {
	return produce(state, draft => {
		switch (action.type) {
			case Types.MANTEM_DADOS_CONSULTA_REQUEST: {
				break;
			}
			case Types.MANTEM_DADOS_CONSULTA_SUCCESS: {
				draft.data = action.payload;
				break;
			}
			case Types.MANTEM_DADOS_CONSULTA_FAILURE: {
				draft.data = action.payload;
				break;
			}
			default:
		}
		return draft;
	});
}
