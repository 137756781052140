export enum Types {
	CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO = '@cnh/CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO',
	CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_SUCCESS = '@cnh/CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_SUCCESS',
	CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_FAILURE = '@cnh/CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_FAILURE',
}

export interface consultaPermissaoInternacionalAutomatizado {
	status: number;
	data: IDadosPermissaoInternacionalAutomatizado | null;
}

export interface IDadosPermissaoInternacionalAutomatizado {
	mensagemEmissaoPid: string;
	numeroRegistro: string;
	numeroPgu: string;
	renach: string;
	local: string;
	categoriaPretendida: string;
	cfcA: string;
	cfcB: string;
	usoForm: string;
	situacaoAtual: string;
	espelhoCnh: string;
	espelhoPid: string;
	dataPrimeiraHabilitacao: string;
	dataUltEmissaoCnh: string;
	nome: string;
	dataValidadeCnh: string;
	nomePai: string;
	dataNascimento: string;
	nomeMae: string;
	sexo: string;
	nacionalidade: string;
	tipoDocumento: string;
	numeroRg: string;
	orgaoEmissor: string;
	localEmissao: string;
	cpf: string;
	rne1: string;
	rne2: string;
	rne3: string;
	naturalidade: string;
	identificacaoHabilitacao: string;
	pais: string;
	dataValidade: string;
	mensagem: string;
	dataExameTeorico: string;
	codigoExameTeorico: string;
	codigoEntidade: string;
	descricaoEntidade: string;
	dataExamePratico2Rodas: string;
	codigoExamePratico2Rodas: string;
	dataExamePratico4Rodas: string;
	codigoExamePratico4Rodas: string;
	flagAtividadeRemunerada: string;
	flagDeficienteFisico: string;
	endereco: {
		codigo: string;
		logradouro: string;
		numero: string;
		complemento: string;
		cep: string;
		bairro: string;
		descricaoMunicipio: string;
		codigoMunicipio: string;
	};
	dadosExameMedico: {
		dataExameMedico: string;
		exameMedico: string;
		categoriaPermitida: string;
		restricaoMedica: string;
		aptidoInaptoTemporario: string;
		resultado: string;
		cred1: string;
		cred2: string;
		crm: string;
		cpf: string;
		regiao: string;
		aptidaoMedica: string;
		aptoAte: string;
		exame: string;
	};
	dadosExamePsicotecnico: {
		dataExamePsicotecnico: string;
		cpf: string;
		exame1: string;
		exame2: string;
		laudo1: string;
		laudo2: string;
		resultado1: string;
		resultado2: string;
		examePsicotecnico1: string;
		examePsicotecnico2: string;
		aptidoInaptoTemporario: string;
		aptidaoPsicotecnica: string;
		cred1: string;
		cred2: string;
		crp: string;
		regiao: string;
		aptoAte: string;
	};
	ocorrencias: [
		{
			sequencia: string;
			tipo: string;
			informacao: string;
		},
	];
}

interface listaOcorrencias {
	seqOcorrencia: string;
	tipoOcorrencia: string;
	informacaoOcorrencia: string;
}

interface listaCursos {
	codigoCurso: string;
	descricaoCurso: string;
	dataValidade: string;
}

export interface IRequestConsultaPermissaoInternacionalAutomatizado {
	cpf?: string;
	numeroRg?: string;
	numeroRegistro?: string;
	numeroPgu?: string;
	atividadeRemunerada?: string;
	nome?: string;
	dataNascimento?: string;
	usuario: string;
	senha: string;
}
