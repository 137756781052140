import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultaSegundaVia } from './types';

export function consultaSegundaViaCnhRequest(
	data: IRequestConsultaSegundaVia,
): ReducerAction {
	return {
		type: Types.CONSULTA_SEGUNDA_VIA_CNH,
		payload: data,
	};
}
export function consultaSegundaViaCnhSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_SEGUNDA_VIA_CNH_SUCCESS,
		payload,
	};
}
export function consultaSegundaViaCnhFailure(payload: boolean): ReducerAction {
	return {
		type: Types.CONSULTA_SEGUNDA_VIA_CNH_FAILURE,
		payload,
	};
}
