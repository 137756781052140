// TYPES
import { ReducerAction } from 'store/modules/types';
import { ApiResponse } from 'services/_api';
import { Types, CadastrarCin, CadastrarCinRequest } from './types';

export function cadastrarCinRequest(
	payload: CadastrarCinRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_CIN_REQUEST,
		payload,
	};
}
export function cadastrarCinSuccess(payload: CadastrarCin): ReducerAction {
	return {
		type: Types.CADASTRAR_CIN_SUCCESS,
		payload,
	};
}
export function cadastrarCinFailure(payload: ApiResponse): ReducerAction {
	return {
		type: Types.CADASTRAR_CIN_FAILURE,
		payload,
	};
}
export function cadastrarCinClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_CIN_CLEAR,
		payload: null,
	};
}
