import produce from 'immer';

// TYPES
import { Types, consultaCnhDefinitiva } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaCnhDefinitiva = {
	status: 0,
	data: null,
	dataError: null,
	requestData: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaCnhDefinitiva {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_CNH_DEFINITIVA_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.CONSULTA_CNH_DEFINITIVA_SUCCESS: {
				draft.status = action.payload.response.status;
				draft.requestData = action.payload.request;
				draft.data = action.payload.response.data;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}
			case Types.CONSULTA_CNH_DEFINITIVA_FAILURE: {
				draft.status = action.payload.response.status;
				draft.requestData = action.payload.request;
				draft.data = INITIAL_STATE.data;
				draft.dataError = action.payload.response.data;
				break;
			}
			case Types.CONSULTA_CNH_DEFINITIVA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;
				draft.requestData = INITIAL_STATE.requestData;
				break;
			}
			default:
		}
		return draft;
	});
}
