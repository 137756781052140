import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { treatObjNullValues } from 'utils/genericFunctions';
import {
	Types,
	ConsultaPesquisaCirgState,
	ConsultaPesquisaCirg,
} from './types';

export const INITIAL_STATE: ConsultaPesquisaCirgState = {
	data: null,
	status: 0,
	cidadao: {
		status: 0,
		data: null,
	},
	mae: {
		status: 0,
		data: null,
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaPesquisaCirgState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PESQUISA_CIRG_REQUEST: {
				break;
			}
			case Types.CONSULTA_PESQUISA_CIRG_SUCCESS: {
				draft.data = action.payload;
				draft.status = 200;
				break;
			}
			case Types.CONSULTA_PESQUISA_CIRG_CIDADAO_SUCCESS: {
				let newValues: ConsultaPesquisaCirg[] = [];
				if (action.payload) {
					newValues = action.payload.map((item: ConsultaPesquisaCirg) =>
						treatObjNullValues(item),
					);
				}
				draft.cidadao.data = newValues;
				draft.cidadao.status = 200;
				break;
			}

			case Types.CONSULTA_PESQUISA_CIRG_CIDADAO_CLEAR: {
				draft.cidadao.status = INITIAL_STATE.cidadao.status;
				draft.cidadao.data = INITIAL_STATE.cidadao.data;
				break;
			}

			case Types.CONSULTA_PESQUISA_CIRG_FAILURE: {
				draft.data = INITIAL_STATE.data;
				draft.status = 400;
				break;
			}

			case Types.CONSULTA_PESQUISA_CIRG_MAE_REQUEST: {
				break;
			}
			case Types.CONSULTA_PESQUISA_CIRG_MAE_SUCCESS: {
				draft.mae.status = 200;
				draft.mae.data = action.payload;
				break;
			}
			case Types.CONSULTA_PESQUISA_CIRG_MAE_FAILURE: {
				draft.mae.status = 204;
				draft.mae.data = INITIAL_STATE.mae.data;
				break;
			}

			case Types.CONSULTA_PESQUISA_CIRG_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				draft.mae = INITIAL_STATE.mae;
				break;
			}

			case Types.CONSULTA_PESQUISA_MAE_CIRG_CLEAR: {
				draft.mae = INITIAL_STATE.mae;
				break;
			}

			default:
		}
		return draft;
	});
}
