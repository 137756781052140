import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_JALES } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { IRequestRecurso, Types } from './types';

// ACTIONS
import { protocolarRecursoSuccess, protocolarRecursoFailure } from './actions';

function* protocolarRecurso(request: ReducerAction) {
	const { payload }: { payload: IRequestRecurso } = request;

	const obj: any = { ...payload };

	const response: ApiResponse = yield call(
		postApi,
		PATH_JALES,
		`transito/${CHANNEL}/protocolar-recursos`,
		obj,
	);

	if (response.status === 200) {
		yield put(protocolarRecursoSuccess(response));
	} else if (response.status === 204) {
		yield put(
			protocolarRecursoFailure({
				mensagem: 'Não há resultados para os parâmetros informados! ',
			}),
		);
	}
}

export default all([takeLatest(Types.PROTOCOLAR_RECURSO, protocolarRecurso)]);
