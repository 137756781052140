import { IParametroData } from '../parametros/types';

export enum Types {
	CONSULTA_SERVICOS = '@servicos/CONSULTA_SERVICOS',
	CONSULTA_SERVICOS_SUCCESS = '@servicos/CONSULTA_SERVICOS_SUCCESS',
	CONSULTA_SERVICOS_FAILURE = '@servicos/CONSULTA_SERVICOS_FAILURE',

	CADASTRA_SERVICOS = '@servicos/CADASTRA_SERVICOS',
	CADASTRA_SERVICOS_SUCCESS = '@servicos/CADASTRA_SERVICOS_SUCCESS',
	CADASTRA_SERVICOS_FAILURE = '@servicos/CADASTRA_SERVICOS_FAILURE',

	ALTERA_SERVICOS = '@servicos/ALTERA_SERVICOS',
	ALTERA_SERVICOS_SUCCESS = '@servicos/ALTERA_SERVICOS_SUCCESS',
	ALTERA_SERVICOS_FAILURE = '@servicos/ALTERA_SERVICOS_FAILURE',

	REMOVE_SERVICOS = '@servicos/REMOVE_SERVICOS',
	REMOVE_SERVICOS_SUCCESS = '@servicos/REMOVE_SERVICOS_SUCCESS',
	REMOVE_SERVICOS_FAILURE = '@servicos/REMOVE_SERVICOS_FAILURE',

	CONSULTA_PARAMETROS_SERVICOS = '@servicos/CONSULTA_PARAMETROS_SERVICOS',
	CONSULTA_PARAMETROS_SERVICOS_SUCCESS = '@servicos/CONSULTA_PARAMETROS_SERVICOS_SUCCESS',
	CONSULTA_PARAMETROS_SERVICOS_FAILURE = '@servicos/CONSULTA_PARAMETROS_SERVICOS_FAILURE',

	ALTERA_PARAMETROS_SERVICOS = '@servicos/ALTERA_PARAMETROS_SERVICOS',
	ALTERA_PARAMETROS_SERVICOS_SUCCESS = '@servicos/ALTERA_PARAMETROS_SERVICOS_SUCCESS',
	ALTERA_PARAMETROS_SERVICOS_FAILURE = '@servicos/ALTERA_PARAMETROS_SERVICOS_FAILURE',

	REDIRECT_SERVICOS_CLEAR = '@servicos/REDIRECT_SERVICOS_CLEAR',
	NEXT_STEP_SERVICOS_CLEAR = '@servicos/NEXT_STEP_SERVICOS_CLEAR',
}

export interface ServicosState {
	servicosData: IServicoData[];
	parametrosServicosData: IParametroData[];
	nextStep: boolean;
	codigoRedirect: string;
	redirect: boolean;
	situation: string;
}

export interface IServicoData {
	codigo: number;
	codigo_contabilizacao: string;
	descricao: string;
	descricao_contabilizacao: string;
	tipo_contabilizacao: string;
}
