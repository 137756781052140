import styled from 'styled-components';

export const BoxTable = styled.div`
	.ant-table-row {
		&.ant-table-row-selected {
			font-weight: 400;
			.ant-table-cell {
				background-color: #f0fdff;
			}
		}
	}
`;
