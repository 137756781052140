import React, { Suspense, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import ButtonImage from 'components/Common/ButtonImage';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import PDFViewer from 'components/Common/PDFViewer';
import { impressaoIntencaoVendaClear } from 'store/modules/api/detranCrv/IntencaoDeVenda/Imprimir/actions';
import Input from 'components/Common/Form/Input/Input';

const ImprimirIntencaoVendaResultado: React.FC = () => {
	const dispatch = useDispatch();
	const { imprimirIntencaoVenda } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.intencaoVenda,
	);

	const [showPrint, setShowPrint] = useState<boolean>(false);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Impressão Intenção de Venda">
				<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
					<Col span={14}>
						<Input
							titleSize="lg"
							readOnly
							name="placa"
							title="Placa"
							value={imprimirIntencaoVenda.placa}
						/>
					</Col>
					<Col span={10} />
					<Col span={14}>
						<Input
							titleSize="lg"
							readOnly
							name="renavam"
							title="Renavam"
							value={imprimirIntencaoVenda.renavam}
						/>
					</Col>
					<Col span={8} />
				</Row>

				<Row justify="end">
					<Col>
						<ButtonImage src="imprimir" onClick={() => setShowPrint(true)} />
					</Col>
				</Row>
			</Section>
			<ButtonVoltar
				onClick={() => dispatch(impressaoIntencaoVendaClear())}
				route="/detran-crv/imprimir-intencao-venda/consulta"
			/>

			{showPrint && (
				<PDFViewer
					title="Impressão Intenção de Venda"
					source={
						imprimirIntencaoVenda.data?.pdfAtpvBase64
							? imprimirIntencaoVenda.data.pdfAtpvBase64
							: ''
					}
					onClose={() => setShowPrint(false)}
				/>
			)}
		</Suspense>
	);
};

export default ImprimirIntencaoVendaResultado;
