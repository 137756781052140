import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaUsuariosMainframeRequest } from './types';

export function consultaUsuariosMainframeRequest(
	payload: ConsultaUsuariosMainframeRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_USUARIOS_MAINFRAME_REQUEST,
		payload,
	};
}
export function consultaUsuariosMainframeSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_USUARIOS_MAINFRAME_SUCCESS,
		payload,
	};
}
export function consultaUsuariosMainframeFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_USUARIOS_MAINFRAME_FAILURE,
		payload,
	};
}

export function consultaUsuariosMainframeClear(): ReducerAction {
	return {
		type: Types.CONSULTA_USUARIOS_MAINFRAME_CLEAR,
		payload: null,
	};
}
