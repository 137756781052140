import * as Yup from 'yup';

// TYPES
import { IRequestEmitirAutorizacaoPrevia } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirAutorizacaoPrevia/types';
import { limparMascara, unformatDate } from 'utils/genericFunctions';

export interface IAutorizacaoPrevia {
	chassi: string;
	placa: string;
	renavam: number | string;
	tipoAutorizacaoPrevia: string;
	codigoTipoModificacao: string;
	descricaoTipoModificacao: string;
	idAlteracaoTipoModificacao: string;
	dataValidadeAutorizacao: string;
	dataAutorizacaoDetran: string;
	cpfResponsavel: number | string;
	nomeResponsavel: string;
	observacao: string;
	descricaoMarca: string;
	longIdMarca: number;
	descricaoTipo: string;
	longIdTipo: number | string;
	descricaoEspecie: string;
	longIdEspecie: number | string;
	descricaoCarroceria: string;
	longIdCarroceria: number | string;
	potencia: number | string;
	cilindrada: number | string;
	capacidadePassageiro: number | string;
	descricaoCor: string;
	longIdCor: number | string;
	descricaoCombustivel: string;
	longIdCombustivel: number | string;
	cmt: number | string;
	pbt: number | string;
	capacidadeCarga: number | string;
}

export const initialValues: IAutorizacaoPrevia = {
	chassi: '',
	placa: '',
	renavam: '',
	tipoAutorizacaoPrevia: '',
	codigoTipoModificacao: '',
	descricaoTipoModificacao: '',
	idAlteracaoTipoModificacao: '',
	dataValidadeAutorizacao: '',
	dataAutorizacaoDetran: '',
	cpfResponsavel: '',
	nomeResponsavel: '',
	observacao: '',
	descricaoMarca: '',
	longIdMarca: 0,
	descricaoTipo: '',
	longIdTipo: '',
	descricaoEspecie: '',
	longIdEspecie: '',
	descricaoCarroceria: '',
	longIdCarroceria: '',
	potencia: '',
	cilindrada: '',
	capacidadePassageiro: '',
	descricaoCor: '',
	longIdCor: '',
	descricaoCombustivel: '',
	longIdCombustivel: '',
	cmt: '',
	pbt: '',
	capacidadeCarga: '',
};

export const schema = Yup.object<IAutorizacaoPrevia>().shape({
	chassi: Yup.string().required('Campo Obrigatório: Chassi.'),
	placa: Yup.string().required('Campo Obrigatório: Placa.'),
	renavam: Yup.string().required('Campo Obrigatório: Renavam.'),
	tipoAutorizacaoPrevia: Yup.string().required(
		'Campo Obrigatório: Tipo de Autorização.',
	),
	tipoModificacao: Yup.string().when(
		['codigoTipoModificacao', 'descricaoModificacao'],
		{
			is: (codigoTipoModificacao, descricaoModificacao) =>
				[codigoTipoModificacao, descricaoModificacao].includes(''),
			then: Yup.string().required('Campo Obrigatório: Tipo de Modificação.'),
		},
	),
	dataValidadeAutorizacao: Yup.string().required(
		'Campo Obrigatório: Validade da Autorização.',
	),
	dataAutorizacaoDetran: Yup.string().required(
		'Campo Obrigatório: Data da Autorização Detran.',
	),
	marca: Yup.string().when(['descricaoMarca', 'longIdMarca'], {
		is: (descricaoMarca, longIdMarca) =>
			[descricaoMarca, longIdMarca].includes(''),
		then: Yup.string().required('Campo Obrigatório: Marca.'),
	}),
	tipo: Yup.string().when(['descricaoTipo', 'longIdTipo'], {
		is: (descricaoTipo, longIdTipo) => [descricaoTipo, longIdTipo].includes(''),
		then: Yup.string().required('Campo Obrigatório: Tipo.'),
	}),
	especie: Yup.string().when(['descricaoEspecie', 'longIdEspecie'], {
		is: (descricaoEspecie, longIdEspecie) =>
			[descricaoEspecie, longIdEspecie].includes(''),
		then: Yup.string().required('Campo Obrigatório: Espécie.'),
	}),
	carroceria: Yup.string().when(['descricaoCarroceria', 'longIdCarroceria'], {
		is: (descricaoCarroceria, longIdCarroceria) =>
			[descricaoCarroceria, longIdCarroceria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Carroceria.'),
	}),
	potencia: Yup.string().required('Campo Obrigatório: Potência.'),
	cilindrada: Yup.string().required('Campo Obrigatório: Cilindrada.'),
	capacidadePassageiro: Yup.string().required(
		'Campo Obrigatório: Capacidade passageiros.',
	),
	cor: Yup.string().when(['descricaoCor', 'longIdCor'], {
		is: (descricaoCor, longIdCor) => [descricaoCor, longIdCor].includes(''),
		then: Yup.string().required('Campo Obrigatório: Cor.'),
	}),
	cmt: Yup.string().required('Campo Obrigatório: CMT.'),
	pbt: Yup.string().required('Campo Obrigatório: PBT.'),
	capacidadeCarga: Yup.string().required(
		'Campo Obrigatório: Capacidade carga.',
	),
});

export function treatValues(
	formValues: IAutorizacaoPrevia,
): IRequestEmitirAutorizacaoPrevia {
	const {
		capacidadeCarga,
		capacidadePassageiro,
		longIdCarroceria,
		descricaoCarroceria,
		cilindrada,
		chassi,
		cmt,
		longIdCombustivel,
		descricaoCombustivel,
		longIdCor,
		descricaoCor,
		cpfResponsavel,
		dataAutorizacaoDetran,
		dataValidadeAutorizacao,
		longIdEspecie,
		descricaoEspecie,
		longIdMarca,
		descricaoMarca,
		nomeResponsavel,
		observacao,
		pbt,
		placa,
		potencia,
		renavam,
		longIdTipo,
		descricaoTipo,
		tipoAutorizacaoPrevia,
		codigoTipoModificacao,
		descricaoTipoModificacao,
		idAlteracaoTipoModificacao,
	} = formValues;

	return {
		emissaoAutorizacaoPrevia: {
			capacidadeCarga: Number(capacidadeCarga),
			capacidadePassageiro: Number(capacidadePassageiro),
			carroceria: {
				longId: Number(longIdCarroceria),
				descricao: descricaoCarroceria,
			},
			cilindrada: Number(cilindrada),
			chassi,
			cmt: Number(cmt),
			combustivel: {
				longId: Number(longIdCombustivel),
				descricao: descricaoCombustivel,
			},
			cor: {
				longId: Number(longIdCor),
				descricao: descricaoCor,
			},
			cpfResponsavel: Number(limparMascara(String(cpfResponsavel))),
			dataAutorizacaoDetran: unformatDate(dataAutorizacaoDetran),
			dataValidadeAutorizacao: unformatDate(dataValidadeAutorizacao),
			especie: {
				longId: Number(longIdEspecie),
				descricao: descricaoEspecie,
			},
			marca: {
				longId: Number(longIdMarca),
				descricao: descricaoMarca,
			},
			nomeResponsavel,
			observacao,
			pbt: Number(pbt),
			placa,
			potencia: Number(potencia),
			renavam: Number(renavam),
			tipo: {
				longId: Number(longIdTipo),
				descricao: descricaoTipo,
			},
			tipoAutorizacaoPrevia,
			tipoModificacao: {
				codigo: Number(codigoTipoModificacao),
				descricao: descricaoTipoModificacao,
				idAlteracao: Number(idAlteracaoTipoModificacao),
			},
		},
	};
}
