// TYPES
import { ReducerAction } from 'store/modules/types';
import { CancelaConsultaAtendimentoRequest, Types } from './types';

export function cancelaConsultaAtendimentoRequest(
	payload: CancelaConsultaAtendimentoRequest,
): ReducerAction {
	return {
		type: Types.CANCELA_CONSULTA_ATENDIMENTO_REQUEST,
		payload,
	};
}
export function cancelaConsultaAtendimentoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CANCELA_CONSULTA_ATENDIMENTO_SUCCESS,
		payload,
	};
}
export function cancelaConsultaAtendimentoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CANCELA_CONSULTA_ATENDIMENTO_FAILURE,
		payload,
	};
}
export function cancelaConsultaAtendimentoClear(): ReducerAction {
	return {
		type: Types.CANCELA_CONSULTA_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
