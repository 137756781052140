import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	margin-top: 30px;
	h5 {
		text-align: center;
		font-weight: 900;
	}
	label {
		font-weight: 900;
	}
`;
