import styled, { css } from 'styled-components';

interface ButtonProps {
	disabled: boolean;
}

export const Container = styled.button`
	min-width: 100px;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-weight: 700;
	border-radius: 5px;
	height: 25px;
	border: 1px solid #8f8f8f;

	background: rgba(255, 255, 255, 1);
	background: -moz-linear-gradient(
		top,
		rgba(255, 255, 255, 1) 0%,
		rgba(246, 246, 246, 1) 47%,
		rgba(214, 214, 214, 1) 100%
	);
	background: -webkit-gradient(
		left top,
		left bottom,
		color-stop(0%, rgba(255, 255, 255, 1)),
		color-stop(47%, rgba(246, 246, 246, 1)),
		color-stop(100%, rgba(214, 214, 214, 1))
	);
	background: -webkit-linear-gradient(
		top,
		rgba(255, 255, 255, 1) 0%,
		rgba(246, 246, 246, 1) 47%,
		rgba(214, 214, 214, 1) 100%
	);
	background: -o-linear-gradient(
		top,
		rgba(255, 255, 255, 1) 0%,
		rgba(246, 246, 246, 1) 47%,
		rgba(214, 214, 214, 1) 100%
	);
	background: -ms-linear-gradient(
		top,
		rgba(255, 255, 255, 1) 0%,
		rgba(246, 246, 246, 1) 47%,
		rgba(214, 214, 214, 1) 100%
	);
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 1) 0%,
		rgba(246, 246, 246, 1) 47%,
		rgba(214, 214, 214, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d6d6d6', GradientType=0 );

	transition: box-shadow 0.5s;
	&:focus {
		box-shadow: 0px 1px 6px 1px rgba(143, 143, 143, 0.75);
	}

	&:hover {
		box-shadow: 0px 1px 6px 1px rgba(143, 143, 143, 0.75);
	}

	${props =>
		props.disabled &&
		css`
			cursor: not-allowed;
			opacity: 0.5;

			&:focus {
				box-shadow: unset;
			}

			&:hover {
				box-shadow: unset;
			}
		`};
`;
