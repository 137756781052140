export enum Types {
	CONSULTAR_INTENCAO_VENDA_REQUEST = '@DETRAN-CRV/CONSULTAR_INTENCAO_VENDA_REQUEST',
	CONSULTAR_INTENCAO_VENDA_SUCCESS = '@DETRAN-CRV/CONSULTAR_INTENCAO_VENDA_SUCCESS',
	CONSULTAR_INTENCAO_VENDA_FAILURE = '@DETRAN-CRV/CONSULTAR_INTENCAO_VENDA_FAILURE',
	CONSULTAR_INTENCAO_VENDA_CLEAR = '@DETRAN-CRV/CONSULTAR_INTENCAO_VENDA_CLEAR',
}

export interface ConsultarIntencaoVenda {
	status: number;
	data: ComunicacoesVenda | null;
	dados: ConsultarIntencaoVendaRequest | null;
}

export interface ComunicacoesVenda {
	comunicacoesVenda: ItemComunicacoesVenda[];
	numeroATPVE?: string;
	tipoAssinatura?: string;
	statusAssinatura?: string;
	comprador?: {
		codigoMunicipio: string;
		email: string;
		nome: string;
		uf: string;
		valorVenda: number;
		documento: {
			numeroIdentificacao: string;
			tipo: string;
		};
	};
	intencaoVenda?: {
		codigoEstado: string;
		dataHora: string;
		dataHoraAtualizacao: string;
		uf: string;
	};
	veiculo?: {
		renavam: string;
		placa: string;
		chassi: string;
		hodometro: string;
	};
}
export interface ItemComunicacoesVenda {
	intencaoVenda: {
		uf: string;
		codigoEstado: string;
		dataHora: string;
		dataHoraAtualizacao: string;
	};
	comprador: {
		documento: {
			tipo: string;
			numeroIdentificacao: string;
		};
		uf: string;
	};
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	ip: string;
	cpf: string;
	identificacao: string;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}
export interface ConsultarIntencaoVendaRequest {
	evento: IEvento;
	tipoConsultaAtpve: string;
	renavam: string;
	placa: string;
	numeroATPVE?: string;
	dataInicioConsulta?: string;
	horaInicioConsulta?: string;
	dataFimConsulta?: string;
	horaFimConsulta?: string;
	codigoEstadoIntencaoVenda?: string;
}
