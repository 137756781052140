import React, { useCallback } from 'react';

import { Field, FormikProps } from 'formik';
import { Row, Col } from 'antd';

// FORM
import hasError from 'utils/getFormErrors';

// COMPONENTS
import InputMask from '../../Input/InputMask';

// STYLED
import { Container, Numero, Divider, Digito } from './styled';
import { SubTitle } from '../../Input/styled';

interface Props {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | number;
	titleAlign?: 'start' | 'end';
	subtitle?: string;
	numero: string;
	digito?: string;
	autoFocus?: boolean;
	required?: boolean;
	disabled?: boolean;
	formik: FormikProps<any>;
	error?: boolean;
	marginLeft?: boolean;
	removeContainer?: boolean;
	placeholder?: string;
	onKeyDown?: (e: KeyboardEvent) => void;
}

const CPF: React.FC<Props> = ({
	title,
	titleSize,
	subtitle,
	numero,
	titleAlign = 'end',
	digito = '',
	autoFocus = false,
	required = false,
	disabled = false,
	formik,
	error = false,
	marginLeft = false,
	removeContainer = false,
	placeholder = '',
	onKeyDown = () => null,
}) => {
	const { errors } = formik;

	function calcSpan() {
		if (digito) {
			return 13;
		}
		if (!subtitle) {
			return 24;
		}
		return 16;
	}

	const handleInput = useCallback(() => {
		return (
			<>
				{digito ? (
					<>
						<Numero marginLeft={marginLeft} hasDigito>
							<Field
								as={InputMask}
								title={title}
								titleSize={titleSize}
								titleAlign={titleAlign}
								name={numero}
								mask="999.999.999"
								autoFocus={autoFocus}
								required={required}
								disabled={disabled}
								error={
									hasError(errors, numero) || hasError(errors, digito) || error
								}
								onKeyDown={onKeyDown}
							/>
						</Numero>
						<Divider />
						<Digito>
							<Field
								as={InputMask}
								name={digito}
								mask="99"
								required={required}
								disabled={disabled}
								error={
									hasError(errors, numero) || hasError(errors, digito) || error
								}
								onKeyDown={onKeyDown}
							/>
						</Digito>
					</>
				) : (
					<Numero marginLeft={marginLeft} hasDigito={false}>
						<Field
							as={InputMask}
							title={title}
							titleSize={titleSize}
							titleAlign={titleAlign}
							name={numero}
							mask="999.999.999-99"
							placeholder={placeholder}
							autoFocus={autoFocus}
							required={required}
							disabled={disabled}
							error={hasError(errors, numero) || error}
							onKeyDown={onKeyDown}
						/>
					</Numero>
				)}
			</>
		);
	}, [
		autoFocus,
		digito,
		disabled,
		error,
		errors,
		marginLeft,
		numero,
		placeholder,
		required,
		onKeyDown,
		title,
		titleAlign,
		titleSize,
	]);

	return (
		<Row>
			{removeContainer ? (
				<Col style={{ padding: 0 }} span={calcSpan()}>
					{handleInput()}
				</Col>
			) : (
				<Col span={calcSpan()}>
					<Container>{handleInput()}</Container>
				</Col>
			)}
			{subtitle && (
				<Col span={8} style={{ padding: removeContainer ? 0 : 'inherit' }}>
					<div style={{ display: 'flex', height: '100%' }}>
						<SubTitle>
							<span>{subtitle}</span>
						</SubTitle>
					</div>
				</Col>
			)}
		</Row>
	);
};

export default CPF;
