import React, { useCallback } from 'react';

import { FiEdit, FiTrash2 } from 'react-icons/fi';

import { Col, Row } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	dependentesDareEditando,
	dependentesDareExcluir,
} from 'store/modules/api/dare/dependentesDare/actions';
import { IDependenteDare } from 'store/modules/api/dare/consultarDare/types';

// COMPONENTS
import SimpleTable from 'components/Common/Table';
import { ButtonIcon } from 'components/Common/ButtonIcon';

// UTILS
import { limparMascara, patternFormat } from 'utils/genericFunctions';

export const Dependentes: React.FC = () => {
	const dispatch = useDispatch();

	const { dependentesDare } = useSelector(
		(state: ApplicationState) => state.api.dare,
	);

	const handleEdit = useCallback(
		(text, record, index) => {
			dispatch(dependentesDareEditando(index, record));
		},
		[dispatch],
	);

	const handleDelete = useCallback(
		(text, record, index) => {
			dispatch(dependentesDareExcluir(index));
		},
		[dispatch],
	);

	const columns = [
		{
			dataIndex: 'nome',
			title: 'Nome',
			render: (text: string) => {
				return text.toLocaleUpperCase();
			},
		},
		{
			dataIndex: 'grauParentesco',
			title: 'Grau de Parentesco',
			align: 'center',
		},
		{
			dataIndex: 'dataNascimento',
			title: 'Data de Nascimento',
			align: 'center',
		},
		{
			dataIndex: 'numeroRg',
			title: 'Nº do RGE/CIN',
			align: 'center',
			render: (text: string, record: IDependenteDare) => {
				return limparMascara(record.numeroRg).length === 10
					? patternFormat(record.numeroRg, '##.###.###-##')
					: patternFormat(record.numeroRg, '##.###.###-#');
			},
		},
		{
			dataIndex: 'servico',
			title: 'Serviço',
			align: 'center',
			render: (text: boolean, record: IDependenteDare) => {
				return record.segundaVia ? '2ª Via do RGE/CIN' : 'Taxa de postagem';
			},
		},
		{
			dataIndex: '',
			title: '',
			align: 'center',
			render: (text: string, record: IDependenteDare, index: number) => (
				<Row align="middle" justify="center">
					<Col>
						<ButtonIcon
							disabled={!record.grauParentesco}
							onClick={() => {
								handleEdit(text, record, index);
							}}
						>
							<FiEdit />
						</ButtonIcon>
					</Col>

					<Col>
						<ButtonIcon
							disabled={!record.grauParentesco}
							onClick={() => {
								handleDelete(text, record, index);
							}}
						>
							<FiTrash2 />
						</ButtonIcon>
					</Col>
				</Row>
			),
		},
	];

	return (
		<>
			{dependentesDare.data && (
				<Row>
					<Col span={24}>
						<SimpleTable
							headers={columns}
							body={dependentesDare.data}
							size="sm"
							striped
							hover={false}
							pagination={false}
						/>
					</Col>
				</Row>
			)}
		</>
	);
};
