// BENEFICIÁRIOS
import { IBeneficiariosState } from './beneficiarios/types';

// DOCUMENTOS
import { IDocumentosState } from './documentos/types';

// NOTAS FISCAIS
import { INotasFiscaisState } from './notasFiscais/types';

// USUARIO
import { IUsuarioState } from './usuario/types';

export enum Types {
	SEDS_CLEAR = '@vivaLeite/SEDS_CLEAR',
}

export interface VivaLeiteState {
	beneficiarios: IBeneficiariosState;
	documentos: IDocumentosState;
	notasFiscais: INotasFiscaisState;
	usuario: IUsuarioState;
}
