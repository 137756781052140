// TYPES
import { ReducerAction } from 'store/modules/types';
import { IConsultaPesquisaObservacao, Types } from './types';

export interface IRequestPesquisaObservacao {
	numeroRg: string;
	usuario: string;
	senha: string;
}

export function consultaPesquisaObservacaoRequest(
	data: IRequestPesquisaObservacao,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_OBSERVACAO_REQUEST,
		payload: data,
	};
}
export function consultaPesquisaObservacaoSuccess(
	payload: IConsultaPesquisaObservacao,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_OBSERVACAO_SUCCESS,
		payload,
	};
}
export function consultaPesquisaObservacaoFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_OBSERVACAO_FAILURE,
		payload: null,
	};
}

export function consultaPesquisaObservacaoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_OBSERVACAO_CLEAR,
		payload: null,
	};
}
