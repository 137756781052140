import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarContato } from './types';

export const INITIAL_STATE: ConsultarContato = {
	status: 0,
	data: null,
};

export default function consultarContato(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarContato {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_CONTATO_REQUEST: {
				break;
			}

			case Types.CONSULTAR_CONTATO_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.data.contatos;
				break;
			}

			case Types.CONSULTAR_CONTATO_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.LIMPAR_CONSULTAR_CONTATO: {
				draft.status = 0;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
