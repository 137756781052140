import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	DATAS_EXAME = '@cnh/DATAS_EXAME',
	DATAS_EXAME_SUCCESS = '@cnh/DATAS_EXAME_SUCCESS',
	DATAS_EXAME_ENUM = '@cnh/DATAS_ENUM_EXAME',
	DATAS_EXAME_ENUM_SUCCESS = '@cnh/DATAS_ENUM_EXAME_SUCCESS',
	DATAS_EXAME_FAILURE = '@cnh/DATAS_EXAME_FAILURE',
	DATAS_EXAME_CLEAR = '@cnh/DATAS_EXAME_CLEAR',
}

export interface datasExame {
	status: number;
	data: IDatasExame | null;
	enumUnidades: {
		id_medico: number | null;
		unidades_disponiveis: OptionProps[];
	};
}

interface IDatasExame {
	enum: OptionProps[] | [];
	idMedico: number;
}

export interface IRequestDatasExame {
	codigo_ciretran: number;
	is_portador_necessidades_especiais: boolean;
	cep_cidadao: number | string;
	cpf_cidadao: number | string;
	usuario: string;
	id_micro_regiao?: number;
}
