import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row, Modal } from 'antd';

import { Link, useHistory } from 'react-router-dom';
import { v4 } from 'uuid';

import ButtonImage from 'components/Common/ButtonImage';
import Section from 'components/Common/Section';

import { ApplicationState } from 'store';

import { IRequestAlertas } from 'store/modules/api/seds/vivaLeite/usuario/types';

import {
	consultaServicosEntidade,
	clearUsuarioVivaLeite,
	clearNotificationsUsuarioVivaLeite,
} from 'store/modules/api/seds/vivaLeite/usuario/actions';

import Padrao from '../components/pages/Padrao';

const ResultadoAtualizacaoCadastral = () => {
	const { selectedUser, servicos, statusTransacaoUsuario } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.usuario,
	);

	const dispatch = useDispatch();
	const history = useHistory();

	const handleEncerrar = useCallback(() => {
		Modal.confirm({
			title: 'Deseja realizar um novo atendimento para o mesmo CPF?',
			okText: 'Sim',
			cancelText: 'Não',
			onOk() {
				history.push('/seds/vivaleite/identificarUsuario');
			},
			onCancel() {
				dispatch(clearUsuarioVivaLeite());
				history.push('/');
			},
		});
	}, [dispatch, history]);

	const handleLinkService = useCallback((codigoServico: number) => {
		let link = '';
		switch (codigoServico) {
			case 1: {
				link = '/seds/vivaleite/atualizacaoCadastral';
				break;
			}
			case 2: {
				link =
					'/seds/vivaleite/atualizacaoCadastral/resultado/excluirBeneficiario';
				break;
			}
			case 3: {
				link = '/seds/vivaleite/atualizacaoCadastral/resultado/envioDocumentos';
				break;
			}
			case 5: {
				link =
					'/seds/vivaleite/atualizacaoCadastral/resultado/imprimirDocumento';
				break;
			}

			default:
		}

		return link;
	}, []);

	const handleCleanStatus = useCallback(() => {
		if (statusTransacaoUsuario !== '') {
			dispatch(clearNotificationsUsuarioVivaLeite());
		}
	}, [dispatch, statusTransacaoUsuario]);

	useEffect(() => {
		const requestData: IRequestAlertas = {
			servico: 'ATUALIZACAO CADASTRAL',
			origem: 'portal',
			num_pan: selectedUser.numeroPan,
		};

		dispatch(consultaServicosEntidade(requestData));
	}, [dispatch, selectedUser.numeroPan]);

	useEffect(() => {
		return function cleanup() {
			handleCleanStatus();
		};
	}, [handleCleanStatus]);

	return (
		<Padrao
			statusTransacao={statusTransacaoUsuario}
			onCleanStatus={handleCleanStatus}
		>
			<>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Section size="sm" titleBold={false} title="ATUALIZAÇÃO CADASTRAL">
							{servicos.map(
								item =>
									item.permissao === 'S' && (
										<Row key={v4()} gutter={[0, 10]}>
											<Col span={24}>
												<Link to={handleLinkService(item.cod_servico)}>
													<b>{item.descricao}</b>
												</Link>
											</Col>
										</Row>
									),
							)}
						</Section>
					</Col>
				</Row>

				<Row gutter={[0, 10]} justify="end" align="bottom">
					<Col>
						<ButtonImage
							src="encerrar"
							type="button"
							onClick={handleEncerrar}
						/>
					</Col>
				</Row>
			</>
		</Padrao>
	);
};

export default ResultadoAtualizacaoCadastral;
