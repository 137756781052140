import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, putApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { AtualizarNaturalidadeRequest, Types } from './types';
import {
	atualizarNaturalidadeSuccess,
	atualizarNaturalidadeFailure,
} from './actions';

function* atualizarNaturalidadeRequest(request: ReducerAction) {
	const { payload }: { payload: AtualizarNaturalidadeRequest } = request;

	let body = { ...payload };

	delete body.codigo;

	body = cleanEmpty(body) as AtualizarNaturalidadeRequest;

	const response: ApiResponse = yield call(
		putApi,
		PATH_SGU,
		`naturalidades/${payload.codigo}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarNaturalidadeSuccess(response));
	} else {
		yield put(atualizarNaturalidadeFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_NATURALIDADE, atualizarNaturalidadeRequest),
]);
