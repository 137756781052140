import 'roboto-fontface';

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
    color: ${props => props.theme.typography.primary};
    background: ${props => props.theme.colors.background};

    font-family: 'Roboto', sans-serif;
    font-size: 1rem;

		h1, h2, h3, h4, h5, h6 {
			color: ${props => props.theme.typography.primary};
			font-weight: 500;
			margin-bottom: 0;
		}
		span, .ant-picker-content th, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
				color: ${props => props.theme.typography.primary};
		}

    h1 {
			font-size: calc(1.2rem + ${props => props.theme.fontScale}%);
		}
		h2 {
			font-size: calc(1.1rem + ${props => props.theme.fontScale}%);
		}
		h3 {
			font-size: calc(1rem + ${props => props.theme.fontScale}%);
		}
		h4 {
			font-size: calc(0.95rem + ${props => props.theme.fontScale}%);
		}
		h5 {
			font-size: calc(0.9rem + ${props => props.theme.fontScale}%);
		}
		h5 {
			font-size: calc(0.85rem + ${props => props.theme.fontScale}%);
		}

    div, p, small, b, strong {
			color: ${props => props.theme.typography.primary};
			font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
		}

		button {
			cursor: pointer;
		}

		p {
			margin-bottom: 0;
		}

		b {
			font-weight: 500;
		}

		strong {
			font-weight: 700;
		}

		@media print {
			.print {
				display: block;
			}

			.no-print {
				display: none;
			}
		}

		// Correções/Ajustes do Ant Design.
		.ant-list-empty-text {
			color: ${props => props.theme.typography.primary};
		}

		.ant-pagination-item {
			background-color: ${props => props.theme.colors.background};

			a {
				color: ${props => props.theme.typography.primary};
			}
		}
		.ant-pagination-item-link {
			background-color: ${props => props.theme.colors.background} !important;
			color: ${props => props.theme.typography.primary} !important;
		}
		.ant-pagination-options {
			.ant-pagination-options-size-changer.ant-select {
				margin-right: 0;

				.ant-select-selector {
					background-color: ${props => props.theme.colors.background};
				}
			}

			.ant-select-dropdown {
				background-color: ${props => props.theme.colors.background};
			}

			.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
				background-color: ${props => props.theme.colors.background};
				color: ${props => props.theme.typography.primary};
			}

			.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
				background-color: ${props => props.theme.colors.background};
				color: ${props => props.theme.typography.primary};
			}
		}

		.ant-modal-content {
			background-color: ${props => props.theme.colors.background};
		}
		.ant-modal-header {
			background-color: ${props => props.theme.colors.background};
		}
		.ant-modal-title {
			color: ${props => props.theme.typography.primary};
		}
  }

	input:-webkit-autofill,
	input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active  {
			-webkit-box-shadow: 0 0 0 1000px white inset !important;
	}

	.ant-col {
		padding-top: 5px;
    padding-bottom: 5px;
	}

	.ant-picker-panel-container {
		background: ${props => props.theme.colors.background} !important;
	}

	.ant-btn[disabled] > * {
		color: ${props => props.theme.typography.disabled} !important;
	}

	.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
		color:${props => props.theme.colors.background} !important;
	}
`;
