import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_UNIDADES_REQUEST = '@SGU/CONSULTAR_UNIDADES_REQUEST',
	CONSULTAR_UNIDADES_SUCCESS = '@SGU/CONSULTAR_UNIDADES_SUCCESS',
	CONSULTAR_UNIDADES_FAILURE = '@SGU/CONSULTAR_UNIDADES_FAILURE',
	CONSULTAR_UNIDADES_CLEAR = '@SGU/CONSULTAR_UNIDADES_CLEAR',
}

export interface ConsultarUnidades {
	status: number;
	data: Unidade[] | null;
	enum: OptionProps[];
	totalRegistrosConsulta: number;
}

export interface Unidade {
	id: number;
	codigo: number;
	nome: string;
	sigla: string;
}

export interface ConsultarUnidadesRequest {
	registro_inicial?: number;
	limite?: number;
	current?: number;
	codigo?: number | string;
	nome?: string;
	sigla?: string;
}
