import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Col, Modal } from 'antd';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// FORM
import Section from 'components/Common/Section';
import Alert from 'components/Common/Notifications/Alert';
import { consultaTokenCnhDigitalClear } from 'store/modules/api/detranCnh/cnhDigital/tokenDigital/actions';
import { enviaSMSClear } from 'store/modules/api/detranCnh/cnhDigital/smsSalesForce/actions';

import {
	solicitaCnhDigitalClear,
	solicitaCnhDigitalRequest,
} from 'store/modules/api/detranCnh/sockets/solicitaCnhDigital/actions';
import {
	ROUTE_DETRAN_CNH_DIGITAL_CADASTRO,
	ROUTE_DETRAN_CNH_DIGITAL_PESQUISA,
} from 'pages/DetranCnh/routes/paths';
import {
	validaTokenCnhDigitalClear,
	validaTokenCnhDigitalRequest,
} from 'store/modules/api/detranCnh/cnhDigital/validaToken/actions';
import {
	treatInitialValues,
	treatValues,
	schema,
	FormCnhDigital,
	CnhDigitalModalInfo,
	treatValuesGenerateKey,
} from './form';
import DadosHabilitacao from '../PreCadastro/DadosHabilitacao';
import DadosContato from '../PreCadastro/DadosContato';

// STYLE
import 'pages/DetranCnh/styles.css';

const StatusCondutorCnhDigital: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { consultaEPortalSegundaVia } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.segundaVia,
	);

	const { pesquisarContato } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.portalDetran,
	);

	const { validaTokenCnhDigital } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cnhDigital,
	);

	const { solicitaCnhDigital } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const [isLoading, setIsLoading] = useState(true);
	const [mensagensInfo, setMensagensInfo] = useState<Array<string>>([]);
	const [modalInfo, setModalInfo] = useState<CnhDigitalModalInfo>({
		type: '',
		message: '',
	});
	const [dadosHabilitacao, setDadosHabilitacao] = useState(
		treatInitialValues(null, null, null).initialValuesDadosHabilitacao,
	);
	const [dadosContato, setDadosContato] = useState(
		treatInitialValues(null, null, null).initialValuesDadosContato,
	);
	const [requestData, setRequestData] = useState(treatValues(null, null, null));

	const validateToken = (values: FormCnhDigital) => {
		const data = {
			cpf: values.cpf,
			ddd: values.dddCelular,
			celular: values.celular,
			email: values.email,
			token: values.codigoVerificador,
		};

		dispatch(validaTokenCnhDigitalRequest(data));
	};

	const validationFieldsAndData = (values: FormCnhDigital): boolean => {
		setMensagensInfo([]);

		if (values.autorizaEnvioEmail !== 'S' || values.autorizaEnvioSms !== 'S') {
			setMensagensInfo((oldState: Array<string>) => {
				return [
					...oldState,
					'Obrigatório o preenchimento da autorização de envio de SMS e E-mail para validação do código.',
				];
			});
			window.scrollTo(0, 0);
			return false;
		}

		if (!values.codigoVerificador || values.codigoVerificador === '') {
			setMensagensInfo((oldState: Array<string>) => {
				return [...oldState, 'Campo obrigatório: Código verificador.'];
			});
			window.scrollTo(0, 0);
			return false;
		}

		validateToken(values);

		return true;
	};

	const handleSubmit = (values: FormCnhDigital) => {
		if (!validationFieldsAndData(values)) return null;

		const newValues = treatValues(
			values,
			dadosHabilitacao.numeroRegistro,
			user,
		);

		setRequestData(newValues);
		return null;
	};

	const handleCleanNotification = () => {
		setMensagensInfo([]);
	};

	const handleCleanModalInfo = () => {
		setModalInfo({
			type: '',
			message: '',
		});
	};

	useEffect(() => {
		setIsLoading(false);
		dispatch(enviaSMSClear());
		dispatch(consultaTokenCnhDigitalClear());
		dispatch(solicitaCnhDigitalClear());
		dispatch(validaTokenCnhDigitalClear());
	}, [dispatch]);

	useEffect(() => {
		if (
			consultaEPortalSegundaVia.status === 200 &&
			consultaEPortalSegundaVia.data
		) {
			const treatedValues = treatInitialValues(
				consultaEPortalSegundaVia.data,
				null,
				null,
			);
			setDadosHabilitacao(treatedValues.initialValuesDadosHabilitacao);
		}
		setIsLoading(false);
	}, [consultaEPortalSegundaVia]);

	useEffect(() => {
		if (pesquisarContato.status === 200 && pesquisarContato.data) {
			const treatedValues = treatInitialValues(
				null,
				pesquisarContato.data,
				null,
			);
			setDadosContato(treatedValues.initialValuesDadosContato);
		} else if (consultaEPortalSegundaVia.data?.cpf) {
			const treatedValues = treatInitialValues(
				null,
				null,
				consultaEPortalSegundaVia.data.cpf,
			);
			setDadosContato(treatedValues.initialValuesDadosContato);
		}
		setIsLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pesquisarContato]);

	useEffect(() => {
		if (
			validaTokenCnhDigital.status === 200 &&
			validaTokenCnhDigital.data?.valido
		) {
			setModalInfo({
				type: 'success',
				message: 'O código verificador informado confere com o código gerado.',
			});
			window.scrollTo(0, 0);
		} else if (
			validaTokenCnhDigital.status === 200 &&
			validaTokenCnhDigital.data?.valido === false
		) {
			setModalInfo({
				type: 'alert',
				message: 'Código verificador inválido.',
			});
			window.scrollTo(0, 0);
		}
	}, [dispatch, validaTokenCnhDigital]);

	useEffect(() => {
		if (consultaEPortalSegundaVia.status !== 200) {
			history.push(ROUTE_DETRAN_CNH_DIGITAL_PESQUISA);
		}
	}, [consultaEPortalSegundaVia.status, history]);

	useEffect(() => {
		if (
			validaTokenCnhDigital.status === 200 &&
			validaTokenCnhDigital.data?.valido === true &&
			requestData.cpf &&
			modalInfo.message === ''
		) {
			dispatch(solicitaCnhDigitalRequest(requestData));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestData, modalInfo, dispatch]);

	useEffect(() => {
		if (
			solicitaCnhDigital.status === 200 ||
			solicitaCnhDigital.status === 201
		) {
			const newValues = treatValuesGenerateKey(
				consultaEPortalSegundaVia.data,
				dadosContato,
			);

			history.push({
				pathname: ROUTE_DETRAN_CNH_DIGITAL_CADASTRO,
				state: newValues,
			});
		}
	}, [
		consultaEPortalSegundaVia.data,
		dadosContato,
		history,
		solicitaCnhDigital.status,
	]);

	return (
		<>
			{!isLoading && (
				<>
					<Section title="Status do Condutor">
						<strong>
							<h3>{dadosHabilitacao.nome}</h3>
						</strong>
						{mensagensInfo.length > 0 &&
							mensagensInfo.map((message: string) => (
								<Alert
									key={message}
									type="info"
									message={message}
									onClose={handleCleanNotification}
								/>
							))}
						<DadosHabilitacao consultaCnhDigital={dadosHabilitacao} />
						<DadosContato
							handleSubmit={handleSubmit}
							clearNotification={handleCleanNotification}
							setMensagensInfo={setMensagensInfo}
							renderData={dadosContato}
							schema={schema}
						/>
					</Section>
					<Row align="middle">
						<Col>
							<ButtonVoltar />
						</Col>

						<Col style={{ marginLeft: 'auto' }}>
							<ButtonImage
								type="button"
								src="outro-servico-detran"
								onClick={() => history.push('/detran-cnh')}
							/>
						</Col>
					</Row>
				</>
			)}

			<Modal
				centered
				title="Mensagem"
				className="cnh-digital__modal"
				maskClosable={false}
				visible={modalInfo.message !== ''}
				style={{ animationDuration: '0s', color: 'red' }}
				footer={null}
				onCancel={handleCleanModalInfo}
			>
				<div>
					<p
						style={
							modalInfo.type === 'success'
								? { color: '#006ED7', fontWeight: 'bold' }
								: { color: 'red', fontWeight: 'bold' }
						}
					>
						{modalInfo.message}
					</p>
					<ButtonImage
						type="button"
						src="btn-ok"
						onClick={handleCleanModalInfo}
					/>
				</div>
			</Modal>
		</>
	);
};

export default StatusCondutorCnhDigital;
