// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IProtocoloReuso } from './types';

export interface IRequestReuso {
	rg: string;
	protocolo: number;
	nomePosto: string;
	entregaNoPosto: boolean;
}

export function protocoloReusoRequest(payload: IRequestReuso): ReducerAction {
	return {
		type: Types.PROTOCOLO_REUSO_REQUEST,
		payload,
	};
}
export function protocoloReusoSuccess(payload: IProtocoloReuso): ReducerAction {
	return {
		type: Types.PROTOCOLO_REUSO_SUCCESS,
		payload,
	};
}
export function protocoloReusoFailure(): ReducerAction {
	return {
		type: Types.PROTOCOLO_REUSO_FAILURE,
		payload: null,
	};
}
export function protocoloReusoClear(): ReducerAction {
	return {
		type: Types.PROTOCOLO_REUSO_CLEAR,
		payload: null,
	};
}
