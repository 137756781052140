import { ReducerAction } from 'store/modules/types';

import {
	Types,
	IRequestAgendarExamesRenachIntegrado,
	PreSaveData,
} from './types';

export function agendarExamesRenachIntegradoRequest(
	data: IRequestAgendarExamesRenachIntegrado,
): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_RENACH_INTEGRADO_REQUEST,
		payload: data,
	};
}
export function agendarExamesRenachIntegradoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_RENACH_INTEGRADO_SUCCESS,
		payload,
	};
}
export function agendarExamesRenachIntegradoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_RENACH_INTEGRADO_FAILURE,
		payload,
	};
}
export function agendarExamesRenachIntegradoPreSave(
	data: PreSaveData,
): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_RENACH_INTEGRADO_PRE_SAVE,
		payload: data,
	};
}
export function agendarExamesRenachIntegradoPreSaveClear(): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_RENACH_INTEGRADO_PRE_SAVE_CLEAR,
		payload: null,
	};
}
export function agendarExamesRenachIntegradoClear(): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_RENACH_INTEGRADO_CLEAR,
		payload: null,
	};
}
