import { ReducerAction } from 'store/modules/types';

import { Types, ConsultarMensagensRequest } from './types';

export function consultarMensagensRequest(
	data: ConsultarMensagensRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_MENSAGENS_REQUEST,
		payload: data,
	};
}
export function consultarMensagensSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_MENSAGENS_SUCCESS,
		payload,
	};
}
export function consultarMensagensFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_MENSAGENS_FAILURE,
		payload: null,
	};
}
export function consultarMensagensClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_MENSAGENS_CLEAR,
		payload: null,
	};
}
