import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarStatusRequest } from './types';

export function cadastrarStatusRequest(
	data: CadastrarStatusRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_STATUS,
		payload: data,
	};
}
export function cadastrarStatusSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_STATUS_SUCCESS,
		payload,
	};
}
export function cadastrarStatusFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_STATUS_FAILURE,
		payload: null,
	};
}
export function cadastrarStatusClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_STATUS_CLEAR,
		payload: null,
	};
}
