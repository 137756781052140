import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { Types, CadastrarUnidadeRequest } from './types';

// ACTIONS
import { cadastrarUnidadeSuccess, cadastrarUnidadeFailure } from './actions';

function* cadastrarUnidade(request: ReducerAction) {
	const { payload }: { payload: CadastrarUnidadeRequest } = request;

	let body = { ...payload };

	delete body.id;

	body = cleanEmpty(body) as CadastrarUnidadeRequest;

	body.tipo = 'P';

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU,
		`/unidades`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarUnidadeSuccess(response));
	} else {
		yield put(cadastrarUnidadeFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_UNIDADE, cadastrarUnidade)]);
