import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	ESTADOS_REQUEST = '@procon/ESTADOS_REQUEST',
	ESTADOS_SUCCESS = '@procon/ESTADOS_SUCCESS',
	ESTADOS_FAILURE = '@procon/ESTADOS_FAILURE',
	ESTADOS_CLEAR = '@procon/ESTADOS_CLEAR',
}

export interface Estados {
	status: null | number;
	data: OptionProps[];
}
