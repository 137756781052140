// TYPES
import { ReducerAction } from 'store/modules/types';
import { CadastraAtendimentoPfRequest, Types } from './types';

export function cadastraAtendimentoPfRequest(
	payload: CadastraAtendimentoPfRequest,
): ReducerAction {
	return {
		type: Types.CADASTRA_ATENDIMENTO_PF_REQUEST,
		payload,
	};
}
export function cadastraAtendimentoPfSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRA_ATENDIMENTO_PF_SUCCESS,
		payload,
	};
}
export function cadastraAtendimentoPfFailure(payload: object): ReducerAction {
	return {
		type: Types.CADASTRA_ATENDIMENTO_PF_FAILURE,
		payload,
	};
}
export function cadastraAtendimentoPfClear(): ReducerAction {
	return {
		type: Types.CADASTRA_ATENDIMENTO_PF_CLEAR,
		payload: null,
	};
}
