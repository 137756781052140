import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestDatasExamePsicologico } from './types';

// ACTIONS
import {
	datasExamePsicologicoSuccess,
	datasExamePsicologicoFailure,
	datasExamePsicologicoEnumSuccess,
} from './actions';

function* datasExamePsicologico(request: ReducerAction) {
	const { payload }: { payload: IRequestDatasExamePsicologico } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`divisao-equitativa/${CHANNEL}/datas-exame-psicologico`,
		payload,
	);

	if (response.status === 200) {
		yield put(datasExamePsicologicoSuccess(response));
	} else if (response.error) {
		yield put(datasExamePsicologicoFailure(response.error));
	}
}

function* datasExamePsicologicoEnum(request: ReducerAction) {
	const { payload }: { payload: IRequestDatasExamePsicologico } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`divisao-equitativa/${CHANNEL}/datas-exame-psicologico`,
		payload,
	);

	if (response.status === 200) {
		yield put(datasExamePsicologicoEnumSuccess(response));
	} else if (response.error) {
		yield put(datasExamePsicologicoFailure(response.error));
	}
}

export default all([
	takeLatest(Types.DATAS_EXAME_PSICOLOGICO_REQUEST, datasExamePsicologico),
	takeLatest(
		Types.DATAS_EXAME_PSICOLOGICO_ENUM_REQUEST,
		datasExamePsicologicoEnum,
	),
]);
