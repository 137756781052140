import React, { useCallback, useEffect } from 'react';

import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { cdhuClear } from 'store/modules/api/cdhu/actions';

// PATHS
import { ROUTE_CDHU_SERVICOS_SUCESSO } from 'pages/cdhu/routes/paths';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';

import DadosMutuario from '../components/DadosMutuario';
import SituacaoContrato from '../components/SituacaoContrato';

import Calculo from './Calculo';
import Opcoes from './Opcoes';

const BoletoAgrupado: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { gerarBoletoAgrupado } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	useEffect(() => {
		if (gerarBoletoAgrupado.status === 200) {
			history.push(ROUTE_CDHU_SERVICOS_SUCESSO);
		}
	}, [gerarBoletoAgrupado, history]);

	const handleButtonVoltar = useCallback(() => {
		dispatch(cdhuClear());
		history.push('/menu-orgaos');
	}, [dispatch, history]);

	return (
		<>
			<DadosMutuario />

			<SituacaoContrato />

			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section size="lg" title="Boleto agrupado" titleSize="sm">
						<Calculo />

						<Opcoes />
					</Section>

					<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
				</Col>
			</Row>
		</>
	);
};

export default BoletoAgrupado;
