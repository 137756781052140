import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_ATPVE_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, ImpressaoIntencaoVendaRequest } from './types';

import {
	impressaoIntencaoVendaSuccess,
	impressaoIntencaoVendaFailure,
} from './actions';

function* impressaoIntencaoVendaRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ImpressaoIntencaoVendaRequest } = request;

	const instance = payload.instanceRequest;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_ATPVE_API,
		`impressoes`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(impressaoIntencaoVendaSuccess(response));
	} else if (response.error) {
		yield put(
			impressaoIntencaoVendaFailure({
				response: response.message,
				instanceRequest: instance,
			}),
		);
	}
}

export default all([
	takeLatest(
		Types.IMPRESSAO_INTENCAO_VENDA_REQUEST,
		impressaoIntencaoVendaRequest,
	),
]);
