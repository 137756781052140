export enum Types {
	CONSULTA_UNIDADE_TRANSITO_REQUEST = '@DETRAN-CRV/CONSULTA_UNIDADE_TRANSITO_REQUEST',
	CONSULTA_UNIDADE_TRANSITO_SUCCESS = '@DETRAN-CRV/CONSULTA_UNIDADE_TRANSITO_SUCCESS',
	CONSULTA_UNIDADE_TRANSITO_FAILURE = '@DETRAN-CRV/CONSULTA_UNIDADE_TRANSITO_FAILURE',
	CONSULTA_UNIDADE_TRANSITO_CLEAR = '@DETRAN-CRV/CONSULTA_UNIDADE_TRANSITO_CLEAR',
}

export interface consultaUnidadeTransito {
	status: number;
	data: unidades_transito | null;
	placaEscolhida?: string;
}

interface unidades_transito {
	unidades_transito: [
		{
			bairro: string;
			cep: number;
			codigo: number;
			codigo_municipio: number;
			complemento: string;
			logradouro: string;
			nome: string;
			numero_logradouro: number;
		},
	];
}
