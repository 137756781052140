import { ReducerAction } from 'store/modules/types';

import { DomainServiceMunicipiosRequest, Types } from './types';

export function domainServiceMunicipiosRequest(
	data: DomainServiceMunicipiosRequest,
): ReducerAction {
	return {
		type: Types.DOMAIN_SERVICE_MUNICIPIOS_REQUEST,
		payload: data,
	};
}
export function domainServiceMunicipiosSuccess(payload: object): ReducerAction {
	return {
		type: Types.DOMAIN_SERVICE_MUNICIPIOS_SUCCESS,
		payload,
	};
}
export function domainServiceMunicipiosFailure(payload: object): ReducerAction {
	return {
		type: Types.DOMAIN_SERVICE_MUNICIPIOS_FAILURE,
		payload,
	};
}
export function domainServiceMunicipiosClear(): { type: string } {
	return {
		type: Types.DOMAIN_SERVICE_MUNICIPIOS_CLEAR,
	};
}
