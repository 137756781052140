// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaAtendimentoPfRequest, Types } from './types';

export function consultaAtendimentoPfRequest(
	payload: ConsultaAtendimentoPfRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_PF_REQUEST,
		payload,
	};
}
export function consultaAtendimentoPfSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_PF_SUCCESS,
		payload,
	};
}
export function consultaAtendimentoPfFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_PF_FAILURE,
		payload,
	};
}
export function consultaAtendimentoPfClear(): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_PF_CLEAR,
		payload: null,
	};
}
