export enum Types {
	PESQUISA_NATURALIDADE_REQUEST = '@sgu/PESQUISA_NATURALIDADE_REQUEST',
	PESQUISA_NATURALIDADE_SUCCESS = '@sgu/PESQUISA_NATURALIDADE_SUCCESS',
	PESQUISA_NATURALIDADE_FAILURE = '@sgu/PESQUISA_NATURALIDADE_FAILURE',
	PESQUISA_NATURALIDADE_CLEAR = '@sgu/PESQUISA_NATURALIDADE_CLEAR',

	LISTA_CEP = '@sgu/LISTA_CEP',
	LISTA_CEP_SUCCESS = '@sgu/LISTA_CEP_SUCCESS',
	LISTA_CEP_FAILURE = '@sgu/LISTA_CEP_FAILURE',

	CONSULTA_MAINFRAME = '@sgu/CONSULTA_MAINFRAME',
	CONSULTA_MAINFRAME_SUCCESS = '@sgu/CONSULTA_MAINFRAME_SUCCESS',
	CONSULTA_MAINFRAME_FAILURE = '@sgu/CONSULTA_MAINFRAME_FAILURE',

	LIMPA_MAINFRAME = '@sgu/LIMPA_MAINFRAME',
}

interface MunicipioIBGE {
	numero: number;
	descricao: string;
	uf: string;
}

interface Naturalidade {
	id: string;
	codigo: number;
	digito: number;
	descricao: string;
	municipioIBGE: MunicipioIBGE | null;
}

interface IPesquisaNaturalidade {
	status: number;
	naturalidades: Naturalidade[];
}

interface IListaCep {
	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	codigoMunicipio: number;
	municipio: string;
}

export interface SGUState {
	pesquisaNaturalidade: IPesquisaNaturalidade;
	listaCep: IListaCep[];
	consultaMainframe: {
		status: number;
		options: IMainframeOptions[];
		data: IMainframe[];
		dataRequest: ConsultaMainframeRequest | null;
	};
}

interface IMainframe {
	dtCadastro: string;
	senha: string;
	idUsuarioMainframe: string;
}

export interface IMainframeOptions {
	orgao: number;
	data: OptionsMainframe[];
}

export interface OptionsMainframe {
	senha?: string;
	value: string;
	label: string;
	codigoLocalidade?: number;
}
export interface ConsultaMainframeRequest {
	cpf: string;
	codigoLocalidade: number;
	orgao?: number;
}
