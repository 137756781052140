import { combineReducers } from 'redux';

import { ReducerAction } from 'store/modules/types';
import { UtilsState, Types } from './types';

import listaCep from './endereco/lista-cep/reducer';
import pais from './endereco/pais/reducer';
import consultarDigitais from './consultarDigitais/reducer';
import confrontarDigitais from './confrontarDigitais/reducer';
import confrontarDigitaisCnh from './confrontarDigitaisCnh/reducer';
import enderecoIp from './enderecoIp/reducer';
import consultaCidadesUF from './consultaCidadesUF/reducer';
import consultarFotosRg from './consultarFotosRg/reducer';
import enderecos from './enderecos/reducer';
import baixarArquivos from './arquivos/baixarArquivos/reducer';

const allReducers = combineReducers({
	listaCep,
	pais,
	consultarDigitais,
	confrontarDigitais,
	confrontarDigitaisCnh,
	enderecoIp,
	consultaCidadesUF,
	consultarFotosRg,
	enderecos,
	baixarArquivos,
});

const combineReducer = (state: UtilsState, action: ReducerAction) => {
	if (action.type === Types.UTILS_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
