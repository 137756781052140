import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApiArchives } from 'services/rotasp/api';
import { PATH_ARQUIVOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { BaixarArquivosRequest, Types } from './types';

// ACTIONS
import { baixarArquivosSuccess, baixarArquivosFailure } from './actions';

function* baixarArquivos(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: BaixarArquivosRequest } =
		request;

	const pathArchiveDownload = payload.url.split('.br/')[1] || '';

	const defaultMessageError =
		'Não foi possível realizar o download do arquivo.';

	const response: ApiResponse = yield call(
		getApiArchives,
		token || '',
		PATH_ARQUIVOS_API,
		`${pathArchiveDownload}`,
		undefined,
		defaultMessageError,
		undefined,
		undefined,
		{
			'Content-Type': 'application/octet-stream',
			'Content-Disposition': 'attachment',
		},
	);

	if (response.status === 200) {
		yield put(
			baixarArquivosSuccess({
				response: response.data,
			}),
		);
	} else {
		yield put(
			baixarArquivosFailure({
				response: response.message,
			}),
		);
	}
}

export default all([takeLatest(Types.BAIXAR_ARQUIVOS_REQUEST, baixarArquivos)]);
