import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApiECpf } from 'services/externalApis';
import { GetECpfValidationRequest, Types } from './types';
import { getECpfValidationSuccess, getECpfValidationFailure } from './actions';

function* getECpfValidationRequest(request: {
	type: string;
	payload: GetECpfValidationRequest;
}) {
	const data = {
		token: request.payload.token,
		credential: request.payload.credential,
		apiToken: request.payload.apiToken,
	};

	const response: ApiResponse = yield call(
		getApiECpf,
		`https://identific.certificadodigital.com.br/api/v2/auth/user_data?token=${data.token}&credential=${data.credential}`,
		data.apiToken,
	);

	if (
		response.status === 200 ||
		response.status === 201 ||
		response.status === 302
	) {
		yield put(getECpfValidationSuccess(response));
	} else if (response.error) {
		yield put(getECpfValidationFailure(response.message));
	}
}

export default all([
	takeLatest(Types.GET_E_CPF_VALIDATION_REQUEST, getECpfValidationRequest),
]);
