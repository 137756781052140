import React from 'react';
import * as Yup from 'yup';

// REDUX
import { useDispatch } from 'react-redux';
import { clearNotifications } from 'store/modules/app/notifications/actions';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { Form, Formik, FormikProps } from 'formik';
import { Col, Row } from 'antd';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import Section from 'components/Common/Section';
import { BoxFormInputsNomeCpfDDDTelefoneEmail } from 'pages/DetranCrv/components/BoxFormInputsNomeCpfDDDTelefoneEmail';
import {
	initialValuesDesbloquearVeiculoPorSolicitante,
	schemaDefault,
	IDesbloquearVeiculoPorSolicitante,
} from './form';
import { BoxFormInputsPlacaRenavamCpfCnpj } from '../../../BoxFormInputsPlacaRenavamCpfCnpj';
import UploadArquivoGeral from '../../UploadArquivoGeral';

interface DesbloquearVeiculoPorSolicitanteProps {
	handleSubmit: (values: IDesbloquearVeiculoPorSolicitante) => void;
	schema?: Yup.ObjectSchema<Yup.Shape<any | undefined, any>, object>;
	initialValues?: IDesbloquearVeiculoPorSolicitante;
	showInputCpf?: boolean;
}

export const DesbloquearVeiculoPorSolicitante = ({
	handleSubmit,
	schema,
	initialValues,
	showInputCpf,
}: DesbloquearVeiculoPorSolicitanteProps) => {
	const dispatch = useDispatch();

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={false}
			initialValues={
				initialValues || initialValuesDesbloquearVeiculoPorSolicitante
			}
			validate={values => getValidationsErrors(values, schema)}
			onSubmit={values => {
				handleSubmit(values);
			}}
		>
			{(formik: FormikProps<any>) => {
				return (
					<Form>
						<Row>
							<Col span={24}>
								<Section title="Pesquisar">
									<Row>
										<Col span={10}>
											<BoxFormInputsPlacaRenavamCpfCnpj
												formik={formik}
												onlyRead
											/>
										</Col>
									</Row>
									<Row>
										<Col span={24}>
											<Section title="Solicitante">
												<BoxFormInputsNomeCpfDDDTelefoneEmail
													formik={formik}
													showInputCpf={showInputCpf}
												/>
											</Section>
										</Col>
									</Row>
								</Section>

								<UploadArquivoGeral
									formik={formik}
									supportedFileExtensions={['application/pdf']}
								/>

								<Row gutter={[0, 20]}>
									<Col span={24} />
								</Row>

								<Row justify="center">
									<Col>
										<ButtonImage
											src="limpar"
											onClick={() => {
												formik.handleReset();
												dispatch(clearNotifications());
											}}
										/>
									</Col>

									<Col offset={1}>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Col>
						</Row>
					</Form>
				);
			}}
		</Formik>
	);
};

DesbloquearVeiculoPorSolicitante.defaultProps = {
	schema: schemaDefault,
	initialValues: initialValuesDesbloquearVeiculoPorSolicitante,
	showInputCpf: false,
};
