import produce from 'immer';

import { ReducerAction } from 'store/modules/types';

import { Types, PEPM } from './types';

export const INITIAL_STATE: PEPM = {
	status: 0,
	mensagem: '',
	data: {
		tipoCliente: 0,
		codigoRetorno: 0,
		placa: '',
		renavam: '',
		chassi: '',
		cpfCnpj: '',
		nomeProprietario: '',
		anoLicenciamento: 0,
		dataEmissaoCRV: '',
		dataLicenciamento: '',
		municipioRegistro: {
			codigo: '',
			descricao: '',
		},
		combustivel: {
			codigo: '',
			descricao: '',
		},
		categoria: {
			codigo: '',
			descricao: '',
		},
		marcaModelo: {
			codigo: '',
			descricao: '',
		},
		tipoPesquisa: {
			codigo: '',
			descricao: '',
		},
		tipoDocumentoProprietario: {
			codigo: '',
			descricao: '',
		},
		tipoProprietario: {
			codigo: '',
			descricao: '',
		},
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): PEPM {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PEPM_REQUEST: {
				break;
			}

			case Types.PEPM_SUCCESS: {
				draft.status = action.payload.status;

				draft.data = action.payload.data;

				break;
			}

			case Types.PEPM_FAILURE: {
				const { status, data, message } = action.payload;

				draft.status = status || data.status;

				draft.data = data;

				draft.mensagem = `${data.data.codigo} - ${message}`;

				break;
			}

			case Types.PEPM_CLEAR: {
				draft.status = 0;

				draft.data = INITIAL_STATE.data;

				break;
			}

			default:
		}

		return draft;
	});
}
