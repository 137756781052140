export enum Types {
	CADASTRO_CONSULTA_REQUEST = '@procon/CADASTRO_CONSULTA_REQUEST',
	CADASTRO_CONSULTA_SUCCESS = '@procon/CADASTRO_CONSULTA_SUCCESS',
	CADASTRO_CONSULTA_FAILURE = '@procon/CADASTRO_CONSULTA_FAILURE',
	CADASTRO_CONSULTA_CLEAR = '@procon/CADASTRO_CONSULTA_CLEAR',
}

export interface CadastroConsulta {
	status: null | number;
	data: null | CadastroConsultaProps;
	dadosAtendimento: null | CadastroConsultaRequest;
}

interface Evento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string | number;
	ip: string;
	canal: {
		id: number;
		desc: string;
		estacao: {
			desc: string;
			id: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface CadastroConsultaProps {
	areaId: string;
	assuntoId: string;
	consumidorId: string;
	descricaoDaConsulta: string;
	fornecedorId?: string;
	grupoDeProblemaId: string;
	problemaId: string;
	nomeDoServicoOuProduto?: string;
	dataDeCompraAquisicao?: string;
	nomeDoFornecedor: string;
	idDoBalcaoUnico: string;
}

export interface CadastroConsultaRequest extends CadastroConsultaProps {
	evento: Evento;
}
export interface CadastroConsultaForm extends CadastroConsultaProps {
	consumidorNome?: string;
	fornecedorNome?: string;
	tipoConsumidor?: string;
	anexos?: AnexosConsultaRequest[];
}

export interface AnexosConsultaRequest {
	nome: string;
	base64: string;
}
