import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_RFB } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarConsistenciaBiometricaRequest } from './types';

// ACTIONS
import {
	consultarConsistenciaBiometricaSuccess,
	consultarConsistenciaBiometricaFailure,
} from './actions';

function* consultarConsistenciaBiometricaRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultarConsistenciaBiometricaRequest } =
		request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_RFB,
		`/receita-federal/${CHANNEL}/emissao/consistencia-biometrica`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultarConsistenciaBiometricaSuccess(response.data));
	} else {
		yield put(consultarConsistenciaBiometricaFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_CONSISTENCIA_BIOMETRICA_REQUEST,
		consultarConsistenciaBiometricaRequest,
	),
]);
