import React, { Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import Button from 'components/Common/Button';
import { escolherPlacaClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/EscolherPlaca/actions';
import Input from 'components/Common/Form/Input/Input';
import { useHistory } from 'react-router-dom';
import { emitirEscolhaPlacaPersonalizadaClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirEscolhaPlacaPersonalizada/actions';
import { primeiroRedirectEscolhaRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/PrimeiroEmplacamento/actions';
import { consultarTaxaServicoClear } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/actions';

const EscolherPlacaConfirmacao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { escolherPlaca, emitirEscolhaPlacaPersonalizada } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const handlePrimeiroEmplacamentoRedirect = () => {
		if (
			escolherPlaca.data?.resultadoEscolhaPlacaAtribuida.chassi &&
			escolherPlaca.data?.resultadoEscolhaPlacaAtribuida.cpfCnpjProprietario
		) {
			dispatch(
				primeiroRedirectEscolhaRequest({
					chassi: escolherPlaca.data?.resultadoEscolhaPlacaAtribuida.chassi,
					cpfCnpjProprietario:
						escolherPlaca.data?.resultadoEscolhaPlacaAtribuida
							.cpfCnpjProprietario,
				}),
			);
			history.push('/detran-crv/primeiro-emplacamento');
		}
	};

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section size="sm" title="CONFIRMACAO">
				<Row gutter={[0, 20]} justify="center">
					<Col span={24}>
						<Input
							titleSize="xl"
							disabled
							name="chassi"
							title="Chassi"
							value={escolherPlaca.data?.resultadoEscolhaPlacaAtribuida.chassi}
							size={40}
						/>
					</Col>
					<Col span={24}>
						<Input
							disabled
							titleSize="xl"
							name="cpfCnpjProprietario"
							title="CPF/CNPJ Proprietário"
							value={
								escolherPlaca.data?.resultadoEscolhaPlacaAtribuida
									.cpfCnpjProprietario
							}
							size={40}
						/>
					</Col>
					<Col span={24}>
						<Input
							titleSize="xl"
							size={40}
							disabled
							name="quantidadeAlteracoesRestante"
							title="Quantidade de Alterações Restantes"
							value={
								escolherPlaca.data?.resultadoEscolhaPlacaAtribuida
									.quantidadeAlteracoesRestante
							}
						/>
					</Col>
					<Col span={8} />
					<Col span={8} style={{ textAlign: 'center' }}>
						Placa Selecionada
						<Section>
							<div>
								<h3>SP - MUNICIPIO</h3>
								<h3>{emitirEscolhaPlacaPersonalizada.data?.placa} </h3>
							</div>
						</Section>
					</Col>
					<Col span={8} />
				</Row>
				<Row justify="center" align="middle" gutter={[10, 10]}>
					<Col>
						<Button
							onClick={() => {
								dispatch(escolherPlacaClear());
								history.push('/detran-crv');
							}}
						>
							Cancelar
						</Button>
					</Col>
					<Col>
						<Button
							onClick={() => {
								dispatch(consultarTaxaServicoClear());
								handlePrimeiroEmplacamentoRedirect();
							}}
						>
							Primeiro Emplacamento
						</Button>
					</Col>
					<Col>
						<Button
							type="submit"
							onClick={() => {
								dispatch(emitirEscolhaPlacaPersonalizadaClear());
								history.push('/detran-crv/escolher-placa/resultado');
							}}
						>
							Escolher placa
						</Button>
					</Col>
				</Row>
			</Section>

			{/* <Row align="middle">
				<Col>
					<ButtonVoltar onClick={() => dispatch(escolherPlacaClear())} />
				</Col>
			</Row> */}
		</Suspense>
	);
};

export default EscolherPlacaConfirmacao;
