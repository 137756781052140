export enum Types {
	ATUALIZAR_CONTATO = '@cnh/ATUALIZAR_CONTATO',
	ATUALIZAR_CONTATO_SUCCESS = '@cnh/ATUALIZAR_CONTATO_SUCCESS',
	ATUALIZAR_CONTATO_FAILURE = '@cnh/ATUALIZAR_CONTATO_FAILURE',
}

export interface AtualizarContato {
	status: number;
	data: null | AtualizarContatoData;
}

export interface AtualizarContatoData {
	cpf: string;
	codigoAreaCelular: string;
	telefoneCelular: string;
	email: string;
	emailCNHDigital: string;
	nomeCompleto: string;
}

export interface AtualizarContatoRequest {
	evento: {
		id_atendimento: string;
		id_cidadao: string;
		cpf: string;
		identificacao: string;
		ip: string;
		canal: {
			id: number;
			desc: string;
			localidade: {
				id: number;
				desc: string;
			};
		};
	};
	cpf: string;
	codigoAreaCelular: string;
	telefoneCelular: string;
	email: string;
	emailCNHDigital: string;
	nomeCompleto: string;
}
