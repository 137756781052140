import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RequestConsultaNomeSocial } from './types';

// ACTIONS
import {
	consultaNomeSocialSuccess,
	consultaNomeSocialFailure,
} from './actions';

function* consultaNomeSocial(request: ReducerAction) {
	const { payload }: { payload: RequestConsultaNomeSocial } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`eportal/${CHANNEL}/consulta/pnsf`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaNomeSocialSuccess(response));
	} else if (response.error) {
		yield put(consultaNomeSocialFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTA_NOME_SOCIAL_REQUEST, consultaNomeSocial),
]);
