export enum Types {
	PCOV_REQUEST = '@DETRAN-CRV/PCOV_REQUEST',
	PCOV_SUCCESS = '@DETRAN-CRV/PCOV_SUCCESS',
	PCOV_FAILURE = '@DETRAN-CRV/PCOV_FAILURE',
	PCOV_CLEAR = '@DETRAN-CRV/PCOV_CLEAR',
}

export interface PCOV {
	status: number;
	mensagem: string;
	data: IPCOV;
}

export interface IPCOV {
	codigoRetorno: string;
	tipoCliente: number;
	chassi: string;
	renavam: string;
	qtdeCv: number;
	lstCv: [
		{
			statusCV: {
				codigo: string;
				descricao: string;
			};
			placa: string;
			nomeComprador: string;
			tipoDocumentoComprador: {
				codigo: string;
				descricao: string;
			};
			cpfCnpjComprador: string;
			dataVenda: string;
			origemCV: {
				codigo: string;
				descricao: string;
			};
			usuario: string;
		},
	];
}

export interface IPCOVRequest {
	tipoPesquisa: number;
	chassi: string;
	placa: string;
	renavam: string;
}
