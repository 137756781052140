import { Types } from './types';

export function consultarDetalhesAutorizacaoPreviaRequest(payload: any) {
	return {
		type: Types.CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_REQUEST,
		payload,
	};
}
export function consultarDetalhesAutorizacaoPreviaSuccess(payload: any) {
	return {
		type: Types.CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_SUCCESS,
		payload,
	};
}
export function consultarDetalhesAutorizacaoPreviaFailure(payload: []): any {
	return {
		type: Types.CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_FAILURE,
		payload,
	};
}
export function consultarDetalhesAutorizacaoPreviaClear(): any {
	return {
		type: Types.CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_CLEAR,
	};
}
