export enum Types {
	CONSULTA_INTERACOES_ATENDIMENTO_REQUEST = '@procon/CONSULTA_INTERACOES_ATENDIMENTO_REQUEST',
	CONSULTA_INTERACOES_ATENDIMENTO_SUCCESS = '@procon/CONSULTA_INTERACOES_ATENDIMENTO_SUCCESS',
	CONSULTA_INTERACOES_ATENDIMENTO_FAILURE = '@procon/CONSULTA_INTERACOES_ATENDIMENTO_FAILURE',
	CONSULTA_INTERACOES_ATENDIMENTO_CLEAR = '@procon/CONSULTA_INTERACOES_ATENDIMENTO_CLEAR',
}

export interface ConsultaInteracoesAtendimento {
	status: null | number;
	data: null | ConsultaInteracoesAtendimentoData;
}

export interface ConsultaInteracoesAtendimentoData {
	interacoes: Interacoes[];
}

export interface Interacoes {
	interacaoId: string;
	descricao: string;
	dataDeCriacao: string;
	responsavelPelaInteracao: string;
	interacaoRespondida: string;
	protocolo: string;
	nomeDoConsumidor: string;
	linksDeDocumentos: string[];
	tramiteImportante: string;
	tramite: string;
	tipoDeTramite: string;
}

export interface ConsultaInteracoesAtendimentoRequest {
	atendimentoId: string;
	protocolo: string;
}
