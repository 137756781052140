import React, { useCallback } from 'react';

import { Field, FormikProps } from 'formik';

// COMPONENTS
import Input from 'components/Common/Form/Input/Input';
import BuscarMunicipioNaturalidade from 'components/Common/SearchButtons/MunicipioNaturalidade';

// FORM
import hasError from 'utils/getFormErrors';

// STYLED
import { Container, BoxCodigo, BoxNome, BoxIcon } from './styled';

import CodigoMunicipio from './Codigo';

interface Props {
	codigoMunicipio: string;
	municipio: string;
	codigoValue: string | number;
	required?: boolean;
	sizeInputMunicipio?: number;
	sizeInputCodigoMunicipio?: number;
	formik: FormikProps<any>;
	disabled?: boolean;
}

const Municipio: React.FC<Props> = ({
	codigoMunicipio,
	municipio,
	codigoValue,
	required = false,
	sizeInputMunicipio = 100,
	sizeInputCodigoMunicipio = 47,
	formik,
	disabled,
}) => {
	const { setFieldValue, errors } = formik;

	const handleCodigoChanged = useCallback(
		res => {
			setFieldValue(codigoMunicipio, res.codigoMunicipio);
			setFieldValue(municipio, res.municipio);
		},
		[setFieldValue, codigoMunicipio, municipio],
	);

	const handleMunicipioSelecionado = useCallback(
		res => {
			setFieldValue(codigoMunicipio, `${res.codigo}-${res.digito}`);
			setFieldValue(municipio, res.descricao);
		},
		[setFieldValue, codigoMunicipio, municipio],
	);

	return (
		<Container>
			<BoxCodigo>
				<CodigoMunicipio
					type={
						formik.values.dbas === 'NL' ||
						formik.values.dbas === 'NZ' ||
						formik.values.dbas === 'TI'
							? 'Nacionalidade'
							: 'Naturalidade'
					}
					title="Município"
					size={sizeInputCodigoMunicipio}
					name={codigoMunicipio}
					codigoValue={codigoValue}
					required={required}
					formik={formik}
					result={handleCodigoChanged}
					disabled={disabled}
				/>
			</BoxCodigo>

			<BoxNome sizeInputMunicipio={sizeInputMunicipio}>
				<Field
					as={Input}
					size={sizeInputMunicipio}
					name={municipio}
					disabled
					error={hasError(errors, municipio)}
				/>
			</BoxNome>

			<BoxIcon>
				<BuscarMunicipioNaturalidade
					formik={formik}
					type={
						formik.values.dbas === 'NL' ||
						formik.values.dbas === 'NZ' ||
						formik.values.dbas === 'TI' ||
						formik.values.dbas === 'TN' ||
						(formik.values.dbas === 'CN' && formik.values.livro[0] === 'K') ||
						(formik.values.dbas === 'CN' && formik.values.livro[0] === 'Y') ||
						(formik.values.dbas === 'CC' && formik.values.livro[0] === 'T') ||
						(formik.values.dbas === 'CC' && formik.values.livro[0] === 'W')
							? 'Naturalidade'
							: 'Município'
					}
					result={handleMunicipioSelecionado}
				/>
			</BoxIcon>
		</Container>
	);
};

export default Municipio;
