import React, { useEffect } from 'react';

import { Modal, Row, Col } from 'antd';

interface Props {
	showModal: boolean;
	onCancel: () => void;
	onOk: () => void;
	selected: string;
}

const DNI: React.FC<Props> = ({
	showModal = false,
	onCancel,
	onOk,
	selected,
}) => {
	// Manipula a visibilidade do Modal.
	useEffect(() => {
		if (showModal === true) {
			Modal.confirm({
				title: 'Atenção',
				content: (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<div style={{ color: 'red', fontWeight: 500 }}>
								Confirma Tipo de Documento{' '}
								{selected === '10977' ? 'CIN' : 'RGE'}? Não será permitida a
								alteração do Tipo de Documento!
							</div>
						</Col>
					</Row>
				),
				onCancel() {
					onCancel();
				},
				onOk() {
					onOk();
				},
				cancelText: 'Não',
				okText: 'Sim',
				width: 475,
				style: {
					animationDuration: '0s',
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal, selected]);

	return <></>;
};

export default DNI;
