import { takeLatest, call, put, all } from 'redux-saga/effects';

/// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DARE } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { IConsultarDare } from 'store/modules/api/dare/consultarDare/types';
import { Types } from './types';

// ACTIONS
import {
	IRequestConsultarDare,
	consultarDareRetaguardaSuccess,
	consultarDareRetaguardaFailure,
	consultarDareRetaguardaClear,
} from './actions';

interface IRequestConsultaDareRetaguarda {
	cpf?: string;
	numeroDare?: string;
	pagina: number;
	orgao: number;
	receita: number;
}

const numOrgao = 100;
const numReceita = 1624;

function* consultarDareRetaguardaRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultarDare } = request;

	yield put(consultarDareRetaguardaClear());

	const params: IRequestConsultaDareRetaguarda = {
		pagina: 1,
		orgao: numOrgao,
		receita: numReceita,
	};

	if (payload.cpf) {
		params.cpf = payload.cpf;
	} else {
		params.numeroDare = payload.dare;
	}

	const response: ApiResponse = yield call(
		getApi,
		PATH_DARE,
		`dare/${CHANNEL}/consultarDare`,
		params,
	);

	if (response.status === 200) {
		const taxasRes = response.data.map((row: IConsultarDare) => row.taxasRes);

		yield put(
			consultarDareRetaguardaSuccess(
				response.status,
				response.data,
				taxasRes[0],
			),
		);
	} else {
		yield put(consultarDareRetaguardaFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_DARE_RETAGUARDA_REQUEST,
		consultarDareRetaguardaRequest,
	),
]);
