import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, DomainServiceNaturalidades } from './types';

export const INITIAL_STATE: DomainServiceNaturalidades = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): DomainServiceNaturalidades {
	return produce(state, draft => {
		switch (action.type) {
			case Types.DOMAIN_SERVICE_NATURALIDADES_REQUEST: {
				break;
			}

			case Types.DOMAIN_SERVICE_NATURALIDADES_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = action.payload.status;
				break;
			}

			case Types.DOMAIN_SERVICE_NATURALIDADES_FAILURE: {
				draft.status = action.payload.status;
				break;
			}

			case Types.DOMAIN_SERVICE_NATURALIDADES_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
