import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { ConsultarOrgaosRequest, Types } from './types';
import { consultarOrgaoSuccess, consultarOrgaoFailure } from './actions';

// UTILS

function* consultarOrgaoRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultarOrgaosRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarOrgaosRequest;

	const response: ApiResponse = yield call(getApi, PATH_SGU, `orgaos`, body);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarOrgaoSuccess(response));
	} else {
		yield put(consultarOrgaoFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_ORGAOS_REQUEST, consultarOrgaoRequest),
]);
