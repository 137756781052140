import React, { ReactChild, ReactChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import Section from 'components/Common/Section';
import Alert from 'components/Common/Notifications/Alert';

import { ApplicationState } from 'store';

import {
	IRequestAlertas,
	IRequestBuscaDadosCadastrais,
} from 'store/modules/api/seds/vivaLeite/usuario/types';

import { consultaAlertas } from 'store/modules/api/seds/vivaLeite/usuario/actions';

interface IProps {
	children: ReactChild | ReactChildren;
	statusTransacao?: string;
	onCleanStatus?: () => void;
}

export const InitialBuscaDadosCadastrais: IRequestBuscaDadosCadastrais = {
	servico: 'ATUALIZACAO CADASTRAL',
	origem: 'PORTAL',
	num_pan: 0,
	etapa: 'busca_dados_cadastrais',
};

const Padrao: React.FC<IProps> = ({
	statusTransacao = '',
	children,
	onCleanStatus = () => {},
}) => {
	const { selectedUser, alertas, selectedCPF } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.usuario,
	);

	const dispatch = useDispatch();

	useEffect(() => {
		const requestData: IRequestAlertas = InitialBuscaDadosCadastrais;
		requestData.num_pan = selectedUser.numeroPan;

		dispatch(consultaAlertas(requestData));
	}, [dispatch, selectedUser.numeroPan]);

	return (
		<>
			{statusTransacao !== '' && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Alert
							type="info"
							message={statusTransacao}
							onClose={onCleanStatus}
						/>
					</Col>
				</Row>
			)}
			<Row gutter={[0, 10]} justify="center">
				<Col span={14}>
					<Section size="sm" titleBold={false} title="ENTIDADE">
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Row gutter={[0, 10]}>
									<Col span={8}>
										<p>
											<strong>CPF: </strong>
										</p>
									</Col>
									<Col span={16}>
										<p>{selectedCPF}</p>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={8}>
										<p>
											<strong>Número Pan: </strong>
										</p>
									</Col>
									<Col span={16}>
										<p>{selectedUser.numeroPan}</p>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={8}>
										<p>
											<strong>Razão Social: </strong>
										</p>
									</Col>
									<Col span={16}>
										<p>{selectedUser.razaoSocial}</p>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={8}>
										<p>
											<strong>Presidente: </strong>
										</p>
									</Col>
									<Col span={16}>
										<p>{selectedUser.presidente}</p>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={8}>
										<p>
											<strong>Responsável: </strong>
										</p>
									</Col>
									<Col span={16}>
										<p>{selectedUser.responsavel}</p>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={8}>
										<p>
											<strong>Status Entidade: </strong>
										</p>
									</Col>
									<Col span={16}>
										<p>{selectedUser.status}</p>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={8}>
										<p>
											<strong>Situação do Responsável: </strong>
										</p>
									</Col>
									<Col span={16}>
										<p>{selectedUser.situacaoResponsaveis}</p>
									</Col>
								</Row>
							</Col>
						</Row>
					</Section>
				</Col>
				<Col span={9} offset={1}>
					<Section
						size="sm"
						titleBold={false}
						style={{ height: 'calc(100% - 20px)' }}
						title="ALERTAS"
					>
						{alertas.map((item: string) => (
							<Row gutter={[0, 10]} key={`${item}`}>
								<Col span={24}>
									<b>{item}</b>
								</Col>
							</Row>
						))}
					</Section>
				</Col>
			</Row>
			{children}
		</>
	);
};

export default Padrao;
