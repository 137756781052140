import { Types } from './types';

export function transferenciaPropietarioRequest(payload: any) {
	return {
		type: Types.TRANSFERENCIA_PROPRIETARIO_REQUEST,
		payload,
	};
}
export function transferenciaPropietarioSuccess(payload: any) {
	return {
		type: Types.TRANSFERENCIA_PROPRIETARIO_SUCCESS,
		payload,
	};
}
export function transferenciaPropietarioFailure(payload: []): any {
	return {
		type: Types.TRANSFERENCIA_PROPRIETARIO_FAILURE,
		payload,
	};
}
