import { validaCpf } from 'utils/genericFunctions';
import * as Yup from 'yup';

export interface IFormConsultarCancelarProcessoAberto {
	cpf: string;
}

export const initialValues: IFormConsultarCancelarProcessoAberto = {
	cpf: '',
};

export const schema = Yup.object<IFormConsultarCancelarProcessoAberto>().shape({
	cpf: Yup.string()
		.required('Campo Obrigatório: CPF.')
		.test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF digitado inválido.',
			test: value => validaCpf(value),
		}),
});
