import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, primeiroEmplacamentoEmissao } from './types';

export const INITIAL_STATE: primeiroEmplacamentoEmissao = {
	status: 0,
	data: null,
	tipoRestricao: '',
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): primeiroEmplacamentoEmissao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PRIMEIRO_EMPLACAMENTO_EMISSAO_REQUEST: {
				draft.tipoRestricao = action.payload.body.tipoRestricao;
				break;
			}

			case Types.PRIMEIRO_EMPLACAMENTO_EMISSAO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.PRIMEIRO_EMPLACAMENTO_EMISSAO_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			default:
		}
		return draft;
	});
}
