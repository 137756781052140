import * as Yup from 'yup';

import {
	formatDateToString,
	limparMascara,
	onlyZeros,
} from 'utils/genericFunctions';
import { IRequestAlteraEndereco } from 'store/modules/api/bolsaPovo/alteraEndereco/types';
import { CadastrarContatoForm } from 'store/modules/api/bolsaPovo/cadastrarContato/types';
import { validateEmail } from 'utils/yupValidations';
import { Tabelas } from 'store/modules/api/bolsaPovo/consultarEndereco/types';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { IRequestEditarCadastrarEndereco } from 'store/modules/api/bolsaPovo/editaCadastraEndereco/types';
import { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
// import { ConsultaEnderecoNovo } from 'store/modules/api/bolsaPovo/consultarEndereco/types';

interface DadosAtendente {
	nome: string;
	cpf: string;
}

export const initialValuesDataCep: IBuscarCepResultado = {
	cep: '',
	endereco: '',
	bairro: '',
	numeroIBGE: '',
	codigoMunicipio: '',
	codigoNaturalidade: '',
	municipio: '',
	uf: '',
	codigoBairro: '',
	codigoUnidadeTransito: '',
	estado: '',
	tipoLogradouro: '',
	enderecoAbrev: '',
};

export const initialValues: IRequestAlteraEndereco = {
	idCpf: '',
	dsCep: '',
	dsCodigoIbge: '',
	dsEndereco: '',
	dsBairro: '',
	dsMunicipio: '',
	dsNumero: '',
	dsComplemento: '',
	dsUf: '',
	idEnderecoTipo: '',
	dsTelefoneContato: '',
	dsCelular: '',
	dsEmail: '',
	dsAtendenteCpf: '',
	dsAtendenteNome: '',
};

export const initialValuesCadastro: CadastrarContatoForm = {
	idTipoContato: '',
	valor: '',
};

export function treatValues(
	values: IRequestAlteraEndereco,
	dadosAtendente: DadosAtendente,
	idCpf: string,
): IRequestAlteraEndereco {
	const requestValues = {
		idCpf: limparMascara(idCpf),
		dsCep: values.dsCep,
		dsCodigoIbge: '',
		dsEndereco: values.dsEndereco,
		dsBairro: values.dsBairro,
		dsMunicipio: values.dsMunicipio,
		dsNumero: values.dsNumero,
		dsComplemento: values.dsComplemento,
		dsUf: values.dsUf,
		idEnderecoTipo: values.idEnderecoTipo,
		dsTelefoneContato: limparMascara(values.dsTelefoneContato),
		dsCelular: limparMascara(values.dsCelular),
		dsEmail: values.dsEmail,
		dsAtendenteCpf: dadosAtendente.cpf,
		dsAtendenteNome: dadosAtendente.nome,
	};

	return requestValues;
}

export const initialValuesNovo: Tabelas = {
	cpf: '',
	idTipoEndereco: '',
	cep: '',
	tipoLogradouro: '',
	logradouro: '',
	numero: '',
	referencia: '',
	semNumero: 'N',
	complemento: '',
	bairro: '',
	municipio: '',
	uf: '',
	dataAtualizacao: '',
	email: '',
	foneCelular: '',
	foneFixo: '',
	latitude: '',
	longitude: '',
	tipo: '',
	idIbge: '',
	ip1: '',
	ip2: '',
	agentBroseSo: '',
	pagina: '',
	so: '',
	bro: '',
	usuarioAtualizacao: '',
};

export function treatValuesNovo(
	values: Tabelas,
	cpf: string,
	user: IUser,
	idAtendimento: string,
	idCidadao: string,
): IRequestEditarCadastrarEndereco {
	const dataAtual = new Date();
	const horaAtual = dataAtual.getHours();
	const minutoAtual = dataAtual.getMinutes();
	const requestValues = {
		cpf,
		idTipoEndereco: '1',
		cep: values.cep,
		tipoLogradouro: values.tipoLogradouro.toUpperCase(),
		logradouro: values.logradouro,
		numero: values.semNumero === 'S' ? '' : values.numero,
		referencia: values.referencia,
		semNumero: values.semNumero,
		complemento: values.complemento,
		bairro: values.bairro,
		municipio: values.municipio,
		uf: values.uf,
		dataAtualizacao: `${formatDateToString(
			dataAtual,
		)} ${horaAtual}:${`0${minutoAtual}`.substr(-2)}:00`,
		email: values.email || '',
		foneCelular: values.foneCelular,
		foneFixo: values.foneFixo || '',
		latitude: '',
		longitude: '',
		tipo: values.idTipoEndereco,
		idIbge: '',
		ip1:
			user.ip.length > 0 && user.ip.split(',').length >= 1
				? user.ip.split(',')[0]
				: '',
		ip2:
			user.ip.split(',') && user.ip.split(',').length >= 2
				? user.ip.split(',')[1]
				: '',
		agentBroseSo: '',
		pagina: 'balcaounico',
		so: '',
		bro: '',
		usuarioAtualizacao: null,
		evento: {
			id_atendimento: idAtendimento,
			id_cidadao: idCidadao,
			cpf: user.cpf,
			identificacao: cpf,
			ip: user.ip,
			canal: {
				id: 1,
				desc: 'Presencial',
				localidade: {
					id: Number(user.posto),
					desc: user.nomePosto,
				},
			},
		},
	};
	return requestValues;
}

export const schemaEndereco = Yup.object<Tabelas>().shape({
	cep: Yup.string().required('Campo Obrigatório: CEP'),
	tipoLogradouro: Yup.string().required('Campo Obrigatório:  Tipo Logradouro'),
	logradouro: Yup.string().required('Campo Obrigatório:  Endereço'),
	bairro: Yup.string().required('Campo Obrigatório:  Bairro'),
	municipio: Yup.string().required('Campo Obrigatório:  Cidade'),
	uf: Yup.string().required('Campo Obrigatório:  UF'),
	idTipoEndereco: Yup.string().required('Campo Obrigatório:  Tipo residência'),
	NumeroSemNumero: Yup.string()
		.when(['semNumero', 'numero'], {
			is: (semNumero, numero) =>
				semNumero.toString() === 'N' && numero.toString() === '',
			then: Yup.string().required('Campo Obrigatório: Número ou Sem Número'),
		})
		.when(['numero'], {
			is: numero => numero.toString() !== '' && onlyZeros(numero.toString()),
			then: Yup.string().required('Campo Inválido: Número.'),
		}),
	complemento: Yup.string().when(['semNumero'], {
		is: semNumero => semNumero.toString() === 'S',
		then: Yup.string().required('Campo Obrigatório: Complemento'),
	}),
});

export const schemaContatos = Yup.object<CadastrarContatoForm>().shape({
	idTipoContato: Yup.string().required(
		'Campo Obrigatório: Por favor selecione o tipo de contato.',
	),
	valores: Yup.string()
		.when(['idTipoContato', 'valor'], {
			is: (idTipoContato, valor) =>
				(idTipoContato === '1' ||
					idTipoContato === 1 ||
					idTipoContato === 2 ||
					idTipoContato === '2') &&
				limparMascara(valor).length < 10,
			then: Yup.string().required(
				'Campo Inválido: O formato do telefone está incorreto.',
			),
		})
		.when(['idTipoContato', 'valor'], {
			is: (idTipoContato, valor) =>
				(idTipoContato === '3' || idTipoContato === 3) &&
				limparMascara(valor).length < 11,
			then: Yup.string().required(
				'Campo Inválido: O formato do telefone está incorreto.',
			),
		})
		.when(['idTipoContato', 'valor'], {
			is: (idTipoContato, valor) =>
				(idTipoContato === '4' ||
					idTipoContato === 4 ||
					idTipoContato === '5' ||
					idTipoContato === 5) &&
				!validateEmail(valor),
			then: Yup.string().required(
				'Campo Inválido: O formato do e-mail está incorreto.',
			),
		}),
});
