import * as Yup from 'yup';

import { formatDate, onlyLetters } from 'utils/genericFunctions';

import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { ConsultaEPortalSegundaViaData } from 'store/modules/api/detranCnh/segundaVia/consultaEPortalSegundaVia/types';
import { PesquisarContatoData } from 'store/modules/api/detranCnh/portalDetran/pesquisarContato/types';
import { SolicitaCnhDigitalRequest } from 'store/modules/api/detranCnh/sockets/solicitaCnhDigital/types';
import { GerarRenachRequest } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { userDataRegex } from 'utils/constants/regex';

export interface GerarSenhaRequest extends GerarRenachRequest {}

export interface CnhDigitalModalInfo {
	type: string;
	message: string;
}

export interface DadosHabilitacaoCnhDigital {
	cpf: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	naturalidade: string;
	numeroRg: string;
	numeroRne: string;
	dataNascimento: string;
	sexo: string;
	nacionalidade: string;
	usoForm: string;
	numeroRegistro: string;
	numeroPgu: string;
	numeroRenach: string;
	numeroEspelhoCnh: string;
	dataUltimaEmissaoCnh: string;
	dataExameMedico: string;
	crm: string;
	dataExameTeorico: string;
	crp: string;
	laudo: string;
	dataExamePsicotecnico: string;
	categoriaPretendida: string;
	numeroLocalRenach: string;
	cfcA: string;
	aptoCategoria: string;
	numeroCred: string;
	numeroCred1: string;
	infoResultado: string;
	dataPrimeiraHabilitacao: string;
	dataValidadeCnh: string;
	categoriaAtual: string;
	cfcB: string;
	restricoesMedicas: string;
	regiao: string;
	dataExamePratico4Rodas: string;
	dataExamePratico2Rodas: string;
	numeroCred2: string;
	codRegiao: string;
	aptidaoPsicotecnica: string;
	enderecoCep: string;
	enderecoNumero: string;
	enderecoBairro: string;
	enderecoLogradouro: string;
	enderecoComplemento: string;
	enderecoDescMunicipio: string;
	enderecoCodMunicipio: string;
}

export interface FormCnhDigital {
	codigoPais: string;
	cpf: string;
	autorizaEnvioEmail: string;
	autorizaEnvioSms: string;
	confirmaEmail?: string;
	codigoVerificador: string;
	celular: string;
	dddCelular: string;
	email: string;
	nacionalidade?: string;
}

// TREAT TO MOUNT FORM
export function treatInitialValues(
	valuesEportalSegundaViaData: ConsultaEPortalSegundaViaData | null,
	valuesPesquisarContato: PesquisarContatoData | null,
	cpf: string | null,
): {
	initialValuesDadosHabilitacao: DadosHabilitacaoCnhDigital;
	initialValuesDadosContato: FormCnhDigital;
} {
	const formattedData = {
		initialValuesDadosHabilitacao: {
			cpf: valuesEportalSegundaViaData?.cpf || '',
			nome: valuesEportalSegundaViaData?.nome || '',
			nomeMae: valuesEportalSegundaViaData?.nomeMae || '',
			nomePai: valuesEportalSegundaViaData?.nomePai || '',
			naturalidade: valuesEportalSegundaViaData?.naturalidade || '',
			numeroRg: valuesEportalSegundaViaData?.numeroRg || '',
			numeroRne: valuesEportalSegundaViaData?.rne1 || '',
			dataNascimento:
				(valuesEportalSegundaViaData?.dataNascimento &&
					formatDate(valuesEportalSegundaViaData.dataNascimento)) ||
				'',
			sexo: valuesEportalSegundaViaData?.sexo || '',
			nacionalidade: valuesEportalSegundaViaData?.nacionalidade || '',
			usoForm: valuesEportalSegundaViaData?.usoForm || '',
			numeroRegistro: valuesEportalSegundaViaData?.numeroRegistro || '',
			numeroPgu: valuesEportalSegundaViaData?.numeroPgu || '',
			numeroRenach: valuesEportalSegundaViaData?.renach || '',
			numeroEspelhoCnh: valuesEportalSegundaViaData?.espelhoCnh || '',
			dataUltimaEmissaoCnh:
				(valuesEportalSegundaViaData?.dataUltimaEmissaoCnh &&
					formatDate(valuesEportalSegundaViaData.dataUltimaEmissaoCnh)) ||
				'',
			dataExameMedico:
				(valuesEportalSegundaViaData?.dadosExameMedico?.dataExameMedico &&
					formatDate(
						valuesEportalSegundaViaData.dadosExameMedico.dataExameMedico,
					)) ||
				'',
			crm: valuesEportalSegundaViaData?.dadosExameMedico?.crm || '',
			dataExameTeorico:
				(valuesEportalSegundaViaData?.dataExameTeorico &&
					formatDate(valuesEportalSegundaViaData.dataExameTeorico)) ||
				'',
			crp: valuesEportalSegundaViaData?.dadosExamePsicotecnico?.crp || '',
			laudo:
				valuesEportalSegundaViaData?.dadosExamePsicotecnico?.laudo1 ||
				valuesEportalSegundaViaData?.dadosExamePsicotecnico?.laudo2 ||
				valuesEportalSegundaViaData?.renach ||
				'',
			dataExamePsicotecnico:
				(valuesEportalSegundaViaData?.dadosExamePsicotecnico
					?.dataExamePsicotecnico &&
					formatDate(
						valuesEportalSegundaViaData.dadosExamePsicotecnico
							.dataExamePsicotecnico,
					)) ||
				'',
			categoriaPretendida:
				valuesEportalSegundaViaData?.dadosPcad?.categoriaPretendida ||
				valuesEportalSegundaViaData?.categoriaPretendida ||
				'',
			numeroLocalRenach: valuesEportalSegundaViaData?.local || '',
			cfcA: valuesEportalSegundaViaData?.cfcA || '',
			aptoCategoria:
				valuesEportalSegundaViaData?.dadosExameMedico?.categoriaPermitida || '',
			numeroCred:
				(valuesEportalSegundaViaData?.dadosExameMedico?.cred1 &&
				valuesEportalSegundaViaData.dadosExameMedico.cred1.trim() !== ''
					? valuesEportalSegundaViaData.dadosExameMedico.cred1
					: valuesEportalSegundaViaData?.dadosExameMedico?.cred2.trim() &&
					  valuesEportalSegundaViaData.dadosExameMedico.cred2 !== '' &&
					  valuesEportalSegundaViaData.dadosExameMedico.cred2) || '',
			numeroCred1:
				valuesEportalSegundaViaData?.dadosExamePsicotecnico?.cred1 || '',
			infoResultado:
				valuesEportalSegundaViaData?.dadosExamePsicotecnico?.resultado1 &&
				valuesEportalSegundaViaData.dadosExamePsicotecnico.resultado1.trim() !==
					''
					? valuesEportalSegundaViaData.dadosExamePsicotecnico.resultado1
					: (valuesEportalSegundaViaData?.dadosExamePsicotecnico?.resultado2 &&
							valuesEportalSegundaViaData.dadosExamePsicotecnico.resultado2.trim() !==
								'' &&
							valuesEportalSegundaViaData.dadosPcan.dadosExamePsicotecnico
								.resultado2) ||
					  '',
			dataPrimeiraHabilitacao:
				(valuesEportalSegundaViaData?.dataPrimeiraHabilitacao &&
					formatDate(valuesEportalSegundaViaData.dataPrimeiraHabilitacao)) ||
				'',
			dataValidadeCnh:
				(valuesEportalSegundaViaData?.dataValidadeCnh &&
					formatDate(valuesEportalSegundaViaData.dataValidadeCnh)) ||
				'',
			categoriaAtual: valuesEportalSegundaViaData?.situacaoAtual || '',
			cfcB: valuesEportalSegundaViaData?.cfcB || '',
			restricoesMedicas:
				valuesEportalSegundaViaData?.dadosExameMedico?.restricaoMedica || '',
			regiao: valuesEportalSegundaViaData?.dadosExameMedico?.regiao || '',
			dataExamePratico4Rodas:
				(valuesEportalSegundaViaData?.dataExamePratico4Rodas &&
					formatDate(valuesEportalSegundaViaData.dataExamePratico4Rodas)) ||
				'',
			dataExamePratico2Rodas:
				(valuesEportalSegundaViaData?.dataExamePratico2Rodas &&
					formatDate(valuesEportalSegundaViaData.dataExamePratico2Rodas)) ||
				'',
			numeroCred2:
				valuesEportalSegundaViaData?.dadosExamePsicotecnico?.cred2 || '',
			codRegiao:
				valuesEportalSegundaViaData?.dadosExamePsicotecnico?.regiao || '',
			aptidaoPsicotecnica:
				valuesEportalSegundaViaData?.dadosExamePsicotecnico
					?.aptidaoPsicotecnica || '',
			enderecoCep: valuesEportalSegundaViaData?.endereco?.cep || '',
			enderecoNumero: valuesEportalSegundaViaData?.endereco?.numero || '',
			enderecoBairro: valuesEportalSegundaViaData?.endereco?.bairro || '',
			enderecoLogradouro:
				valuesEportalSegundaViaData?.endereco?.logradouro || '',
			enderecoComplemento:
				valuesEportalSegundaViaData?.endereco?.complemento || '',
			enderecoDescMunicipio:
				valuesEportalSegundaViaData?.endereco?.descricaoMunicipio || '',
			enderecoCodMunicipio:
				valuesEportalSegundaViaData?.endereco?.codigoMunicipio || '',
		},
		initialValuesDadosContato: {
			codigoPais: valuesPesquisarContato?.codigoPais?.trim() || '55',
			cpf: valuesPesquisarContato?.cpf?.trim() || cpf || '',
			autorizaEnvioEmail: 'N',
			autorizaEnvioSms: 'N',
			dddCelular: valuesPesquisarContato?.codigoAreaCelular?.trim() || '',
			celular: valuesPesquisarContato?.telefoneCelular?.trim() || '',
			email: valuesPesquisarContato?.email?.trim() || '',
			confirmaEmail: valuesPesquisarContato?.email?.trim() || '',
			codigoVerificador: '',
		},
	};

	return formattedData;
}

// TREAT TO SEND FORM
export function treatValues(
	values: FormCnhDigital | null,
	numeroRegistro: string | null,
	user: IUser | null,
): SolicitaCnhDigitalRequest {
	const formattedData = {
		cpf: values?.cpf || '',
		registro: numeroRegistro || '',
		ddd: values?.dddCelular || '',
		telefone: values?.celular || '',
		email: values?.email || '',
		codigoVerificador: values?.codigoVerificador || '',
		broncos: '',
		cpfUsuario: user?.cpf || '',
		posto: user?.posto || '',
	};
	return formattedData;
}

export function treatValuesGenerateKey(
	valuesEportalSegundaViaData: ConsultaEPortalSegundaViaData | null,
	dadosContato: FormCnhDigital | null,
): GerarSenhaRequest {
	const formattedData = {
		evento: {
			id_atendimento: '',
			id_cidadao: '',
			cpf: '',
			identificacao: '',
			ip: '',
			canal: {
				id: 0,
				desc: '',
				localidade: {
					id: 0,
					desc: '',
				},
			},
		},
		usoFormularioEnum: 'CNH_DIGITAL',
		cpf: valuesEportalSegundaViaData?.cpf || '',
		nome: valuesEportalSegundaViaData?.nome || '',
		nomeMae: valuesEportalSegundaViaData?.nomeMae || '',
		nomePai: valuesEportalSegundaViaData?.nomePai || '',
		sexo: valuesEportalSegundaViaData?.sexo || '',
		dataNascimento: valuesEportalSegundaViaData?.dataNascimento || '',
		tipoDocumentoEnum: 'CARTEIRA_IDENTIDADE',
		numDocumento: valuesEportalSegundaViaData?.numeroRg || '',
		orgaoExpDocumento: valuesEportalSegundaViaData?.orgaoEmissor
			? onlyLetters(valuesEportalSegundaViaData.orgaoEmissor)
			: '',
		orgaoUfExpDocumento: valuesEportalSegundaViaData?.localEmissao || '',
		nacionalidade: valuesEportalSegundaViaData?.nacionalidade || '',
		codigoNaturalidade: '',
		email: dadosContato?.email || '',
		celularDDD: dadosContato?.dddCelular || '',
		celular: dadosContato?.celular || '',
		logradouro: valuesEportalSegundaViaData?.endereco.logradouro || '',
		logradouroNumero: valuesEportalSegundaViaData?.endereco.numero || '',
		logradouroComplemento:
			valuesEportalSegundaViaData?.endereco.complemento || '',
		logradouroBairro: valuesEportalSegundaViaData?.endereco.bairro || '',
		logradouroCodMunicipio:
			valuesEportalSegundaViaData?.endereco.codigoMunicipio || '',
		logradouroCep: valuesEportalSegundaViaData?.endereco.cep || '',
		codCiretranCondutor: '',
		statusReusoEnum: 'SEM_REUSO',
		flagAtividadeRemunerada:
			valuesEportalSegundaViaData?.flagAtividadeRemunerada || '',
		flagDeficienteFisico:
			valuesEportalSegundaViaData?.flagDeficienteFisico || '',
		flagSolicitaCNHDigital: true,
		codigoVerificador: dadosContato?.codigoVerificador || '',
		categoriaAtual: valuesEportalSegundaViaData?.situacaoAtual || '',
		categoriaPretendida: valuesEportalSegundaViaData?.categoriaPretendida || '',
		registro: valuesEportalSegundaViaData?.numeroRegistro || '',
		numeroPgu: valuesEportalSegundaViaData?.numeroPgu || '',
		rneNumero: null,
		rneOrgao: null,
		rneUf: null,
		rneCodPaisCnhEstrangeira: null,
		rneDataValidadeCnhEstrangeira: null,
		rneDataPrimeiraHabilitacaoCnhEstrangeira: null,
		rneIdentificacaoCnhEstrangeira: null,
		flagMilitar: false,
		codEntidadeMilitar: '',
		numeroIdentMilitar: '',
		flagExamePosto: false,
		flagExameToxicologico: '',
		flagProcurador: '',
		codCiretranHabilitacao: '',
		cpfUsuario: '',
		codLocalidade: '',
		nomeSocial: '',
		paisAfetivo1: '',
		paisAfetivo2: '',
	};
	return formattedData;
}

export const schema = Yup.object<FormCnhDigital>().shape(
	{
		dddCelular: Yup.string().required('Campo Obrigatório: DDD.'),
		celular: Yup.string()
			.required('Campo Obrigatório: Celular.')
			.matches(userDataRegex.cellPhoneNumberNoCode, {
				message: 'Campo Inválido: Celular.',
				excludeEmptyString: true,
			}),
		email: Yup.string()
			.required('Campo Obrigatório: Email.')
			.matches(userDataRegex.emailAddress, {
				message: 'Formato de e-mail inválido.',
				excludeEmptyString: true,
			})
			.test(
				'same-email',
				'A confirmação do e-mail é diferente do e-mail informado.',
				function verify(value) {
					return value !== '' ? this.parent.confirmaEmail === value : true;
				},
			),
		confirmaEmail: Yup.string().required(
			'Campo Obrigatório: Confirmar e-mail.',
		),
	},
	[['telefone', 'celular']],
);
