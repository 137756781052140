// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaConsumidorData } from '../consultaConsumidor/types';
import { Types } from './types';

export function consumidorSelecionadoRequest(
	payload: ConsultaConsumidorData,
): ReducerAction {
	return {
		type: Types.CONSUMIDOR_SELECIONADO_REQUEST,
		payload,
	};
}
export function consumidorSelecionadoClear(): ReducerAction {
	return {
		type: Types.CONSUMIDOR_SELECIONADO_CLEAR,
		payload: null,
	};
}
