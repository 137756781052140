import React from 'react';

import { Row, Col } from 'antd';
import Input from 'components/Common/Form/Input/Input';
import CheckBox from 'components/Common/Form/Checkbox';
import { Container } from './styles';

interface ICertidaoDistribuidor {
	exigCertidaoDistribuidor: string;
	exigCertidaoDistribuidor01: string;
}

interface IData {
	data: ICertidaoDistribuidor;
}

const CertidaoDistribuidor: React.FC<IData> = ({ data }) => {
	return (
		<Container>
			<Row gutter={[15, 5]}>
				<Col span={24}>
					<CheckBox
						name="certidaoDistribuidor"
						checked={!!data?.exigCertidaoDistribuidor?.trim()}
						onChange={() => {}}
						disabled
						subtitle="Certidão do Distribuidor Criminal para fins judiciais da Comarca de:"
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Input
						name="exigCertidaoDistribuidor01"
						value={data?.exigCertidaoDistribuidor01}
						disabled
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default CertidaoDistribuidor;
