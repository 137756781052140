import React from 'react';
import { Field, FormikProps } from 'formik';
import { Col, Row } from 'antd';

import Input from 'components/Common/Form/Input/Input';
import FormBox from 'components/Common/Form/FormBox';
import { IVisualizarFicha } from '../form';

import { ContainerFichaCadastral } from '../styled';

interface Props {
	formik: FormikProps<IVisualizarFicha>;
}

const DadosInscricao: React.FC<Props> = () => {
	return (
		<ContainerFichaCadastral>
			<FormBox title="Dados da Inscrição">
				<Row gutter={[0, 20]}>
					<Col span={12}>
						<Field
							title="Tipo Inscrição"
							name="inscricoes.descricaoTipo"
							as={Input}
							disabled
						/>
					</Col>

					<Col span={12}>
						<Field
							title="Situação"
							name="inscricoes.situacao"
							as={Input}
							disabled
						/>
					</Col>
				</Row>
			</FormBox>
		</ContainerFichaCadastral>
	);
};

export default DadosInscricao;
