import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_FUNCIONALIDADES_REQUEST = '@SGU/CONSULTAR_FUNCIONALIDADES_REQUEST',
	CONSULTAR_FUNCIONALIDADES_SUCCESS = '@SGU/CONSULTAR_FUNCIONALIDADES_SUCCESS',
	CONSULTAR_FUNCIONALIDADES_FAILURE = '@SGU/CONSULTAR_FUNCIONALIDADES_FAILURE',
	CONSULTAR_FUNCIONALIDADES_CLEAR = '@SGU/CONSULTAR_FUNCIONALIDADES_CLEAR',
}

export interface ConsultarFuncionalidade {
	status: number;
	data: Funcionalidade[] | null;
	enum: OptionProps[];
	totalRegistrosConsulta: number;
}

export interface Funcionalidade {
	id: string | number;
	descricao: string;
}

export interface ConsultarFuncionalidadesRequest {
	registroInicial?: number;
	limite?: number;
	current?: number;
	id?: number | string;
	descricao?: string;
}
