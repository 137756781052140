import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ValidaTokenCnhDigitalRequest } from './types';

// ACTIONS
import {
	validaTokenCnhDigitalSuccess,
	validaTokenCnhDigitalFailure,
} from './actions';

function* validaTokenCnhDigital(request: ReducerAction) {
	const { payload }: { payload: ValidaTokenCnhDigitalRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`redis/${CHANNEL}/valida/token`,
		payload,
	);

	if (
		response.status === 200 ||
		response.status === 201 ||
		response.status === 204
	) {
		yield put(validaTokenCnhDigitalSuccess({ response, request: payload }));
	} else if (response) {
		yield put(validaTokenCnhDigitalFailure(response));
	}
}

export default all([
	takeLatest(Types.VALIDA_TOKEN_CNH_DIGITAL_REQUEST, validaTokenCnhDigital),
]);
