// TYPES
import { ReducerAction } from 'store/modules/types';
import { EnderecoIpState, Types } from './types';

export function enderecoIpRequest(): ReducerAction {
	return {
		type: Types.ENDERECO_IP_REQUEST,
		payload: null,
	};
}
export function enderecoIpSuccess(payload: EnderecoIpState): ReducerAction {
	return {
		type: Types.ENDERECO_IP_SUCCESS,
		payload,
	};
}
export function enderecoIpFailure(): ReducerAction {
	return {
		type: Types.ENDERECO_IP_FAILURE,
		payload: null,
	};
}
export function enderecoIpClear(): ReducerAction {
	return {
		type: Types.ENDERECO_IP_CLEAR,
		payload: null,
	};
}
