import React, { Suspense } from 'react';
import { Col, Row, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Input from 'components/Common/Form/Input/Input';
import { incluirIntencaoVendaClear } from 'store/modules/api/detranCrv/IntencaoDeVenda/Incluir/actions';

const IncluirIntencaoVendaResultado: React.FC = () => {
	const dispatch = useDispatch();
	const { incluirIntencaoVenda } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.intencaoVenda,
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Resultado Registra ATPVE">
				{(incluirIntencaoVenda.status === 200 || incluirIntencaoVenda.status === 201) ? (
					<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
						<Col span={14}>
							<Input
								titleSize="lg"
								readOnly
								name="codigo"
								title="Número ATPVE"
								value={incluirIntencaoVenda.data?.numeroATPVE}
							/>
						</Col>
						<Col span={10} />
					</Row>
				) : (
					<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
						<Col span={14}>
							<p style={{ textAlign: 'start' }}>
								HOUVE UM PROBLEMA COM O REGISTRO DE VENDA
							</p>
						</Col>
					</Row>
				)}
			</Section>
			<ButtonVoltar
				onClick={() => dispatch(incluirIntencaoVendaClear())}
				route="/detran-crv/incluir-intencao-venda/consulta"
			/>
		</Suspense>
	);
};

export default IncluirIntencaoVendaResultado;
