import React, {
	ChangeEvent,
	Suspense,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';
import Radio from 'components/Common/Form/Radio';
import { ConsultarNumeroMotorBinRequest } from 'store/modules/api/detranCrv/ConsultarVeiculos/ConsultarNumeroMotorBin/actions';
import { initialValues, schema, treatValues } from './form';

const ConsultarNumeroMotorBin: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const Options = [
		{
			value: 'placa',
			label: 'Placa',
		},
		{
			value: 'chassi',
			label: 'Chassi',
		},
		{
			value: 'renavam',
			label: 'Renavam',
		},
	];

	const [lenght, setLenght] = useState<number>(7);

	const handleChange = useCallback((value: string) => {
		switch (value) {
			case 'chassi':
				setLenght(22);
				break;
			case 'placa':
				setLenght(7);
				break;
			case 'renavam':
				setLenght(11);
				break;
			default:
				break;
		}
	}, []);

	const { consultarNumeroMotorBin } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarVeiculos,
	);

	useEffect(() => {
		if (consultarNumeroMotorBin.status === 200) {
			history.push('/detran-crv/consultar-numero-motor-bin/resultado');
		}
	}, [consultarNumeroMotorBin, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						const formatValuesReq = treatValues(values);
						dispatch(ConsultarNumeroMotorBinRequest(formatValuesReq));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[0, 20]} justify="center">
									<Col span={24}>
										<p>
											<strong> N° Motor</strong>
										</p>
										<Field
											size={20}
											maxLength={22}
											name="numeroMotor"
											as={Input}
											error={hasError(formik.errors, 'numeroMotor')}
										/>
									</Col>
									<Col span={24}>
										<p>
											<strong> Escolha uma das opções</strong>
										</p>
										<Field
											name="selecao"
											as={Radio}
											options={Options}
											defaultValue="placa"
											error={hasError(formik.errors, 'selecao')}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												const { value } = event.target;
												formik.handleReset();
												formik.setFieldValue('selecao', value);
												handleChange(value);
											}}
										/>
									</Col>
									<Col span={24}>
										<Field
											size={20}
											maxLength={lenght}
											name="numeroDocumento"
											as={Input}
											error={hasError(formik.errors, 'numeroDocumento')}
										/>
									</Col>
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={formik.handleReset}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
		</Suspense>
	);
};

export default ConsultarNumeroMotorBin;
