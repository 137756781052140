export enum Types {
	EXCLUIR_LOCALIDADES_RELACIONADAS = '@SGU/EXCLUIR_LOCALIDADES_RELACIONADAS',
	EXCLUIR_LOCALIDADES_RELACIONADAS_SUCCESS = '@SGU/EXCLUIR_LOCALIDADES_RELACIONADAS_SUCCESS',
	EXCLUIR_LOCALIDADES_RELACIONADAS_FAILURE = '@SGU/EXCLUIR_LOCALIDADES_RELACIONADAS_FAILURE',
	EXCLUIR_LOCALIDADES_RELACIONADAS_CLEAR = '@SGU/EXCLUIR_LOCALIDADES_RELACIONADAS_CLEAR',
}

export interface ExcluirLocalidadesRelacionadas {
	status: number;
	data: null | ExcluirLocalidadesRelacionadasRequest;
}

export interface IExcluirLocalidadesRelacionadas {
	status: string;
	statusMensagem: string;
}

export interface ExcluirLocalidadesRelacionadasRequest {
	id?: number | string;
	descricao?: string;
}
