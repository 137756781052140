import { IEscolherPlaca } from 'pages/DetranCrv/SolicitarDocumentos/EscolherPlaca/form';
import { Types } from './types';

export function escolherPlacaRequest(payload: IEscolherPlaca) {
	return {
		type: Types.ESCOLHER_PLACA_REQUEST,
		payload,
	};
}
export function escolherPlacaSuccess(payload: any) {
	return {
		type: Types.ESCOLHER_PLACA_SUCCESS,
		payload,
	};
}
export function escolherPlacaFailure(payload: []): any {
	return {
		type: Types.ESCOLHER_PLACA_FAILURE,
		payload,
	};
}
export function escolherPlacaClear(): any {
	return {
		type: Types.ESCOLHER_PLACA_CLEAR,
	};
}
export function confirmarPlacaEscolhida(payload: {
	placaEscolhida: string;
}): any {
	return {
		type: Types.CONFIRMAR_PLACA_ESCOLHIDA,
		payload,
	};
}
