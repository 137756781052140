import { Types, ConsultarAcompanhamentoRequest } from './types';

export function consultarAcompanhamentoRequest(
	payload: ConsultarAcompanhamentoRequest,
) {
	return {
		type: Types.CONSULTAR_ACOMPANHAMENTO_REQUEST,
		payload,
	};
}
export function consultarAcompanhamentoSuccess(payload: any) {
	return {
		type: Types.CONSULTAR_ACOMPANHAMENTO_SUCCESS,
		payload,
	};
}
export function consultarAcompanhamentoFailure(payload: any) {
	return {
		type: Types.CONSULTAR_ACOMPANHAMENTO_FAILURE,
		payload,
	};
}
export function consultarAcompanhamentoClear(): any {
	return {
		type: Types.CONSULTAR_ACOMPANHAMENTO_CLEAR,
	};
}
