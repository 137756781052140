import * as Yup from 'yup';

import { IFormStatusCondutor } from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor/form';
import {
	formatDate,
	limparMascara,
	onlyLetters,
	removeAcentoCaracteresEspeciais,
	unformatDate,
	verifyStringIsAnInvalidPhrase,
} from 'utils/genericFunctions';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { GerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/types';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { ConsultaEPortalSegundaViaData } from 'store/modules/api/detranCnh/segundaVia/consultaEPortalSegundaVia/types';
import { PreCadastroCidadaoRequest } from 'store/modules/api/sguService/preCadastroCidadao/types';
import {
	socialNameAndFiliationsPhrasesNotAllowed,
	usoFormularioEnumTypes,
} from 'utils/constants/types';
import {
	sameThreeCharactersInSequenceNotAllowedRegex,
	userDataRegex,
} from 'utils/constants/regex';
import { trimValues } from 'utils/stringFunctions';
import { EventoContabilizacao } from 'store/modules/types';

export const initalValuesHabilitacao: IFormStatusCondutor = {
	status: '',
	statusMensagem: '',
	mensagemBloqueio: '',
	numeroRegistro: '',
	numeroPgu: '',
	renach: '',
	local: '',
	categoriaPretendida: '',
	cfcA: '',
	espelhoCnh: '',
	usoForm: '',
	situacaoAtual: '',
	cfcB: '',
	espelhoPid: '',
	dataPrimeiraHabilitacao: '',
	nome: '',
	dataValidadeCnh: '',
	nomePai: '',
	dataNascimento: '',
	nomeMae: '',
	sexo: '',
	nacionalidade: '',
	tipoDocumento: '',
	numeroRg: '',
	orgaoEmissor: '',
	localEmissao: '',
	cpf: '',
	rne1: '',
	rne2: '',
	rne3: '',
	naturalidade: '',
	identificacaoHabilitacao: '',
	pais: '',
	dataValidade: '',
	enderecoLogradouro: '',
	enderecoNumero: '',
	enderecoComplemento: '',
	enderecoCep: '',
	enderecoBairro: '',
	enderecoMunicipio: '',
	enderecoCodigoMunicipio: '',
	enderecoDescricaoMunicipio: '',
	mensagem: '',
	exameMedicoDataExameMedico: '',
	exameMedicoCategoriaPermitida: '',
	exameMedicoRestricaoMedica: '',
	exameMedicoCrm: '',
	exameMedicoCred1: '',
	exameMedicoCred2: '',
	exameMedicoRegiao: '',
	exameMedicoAptidaoMedica: '',
	examePsicotecnicoDataExamePsicotecnico: '',
	examePsicotecnicoLaudo: '',
	examePsicotecnicoResultado1: '',
	examePsicotecnicoResultado2: '',
	examePsicotecnicoCrp: '',
	examePsicotecnicoCred1: '',
	examePsicotecnicoCred2: '',
	examePsicotecnicoRegiao: '',
	examePsicotecnicoAptidaoPsicotecnica: '',
	dataExameTeorico: '',
	codigoExameTeorico: '',
	codigoEntidade: '',
	descricaoEntidade: '',
	dataExamePratico2Rodas: '',
	codigoExamePratico2Rodas: '',
	dataExamePratico4Rodas: '',
	codigoExamePratico4Rodas: '',
	dataUltimaEmissaoCnh: '',
	listaOcorrencias: [],
	listaCursos: [],
	cepPcon: '',
	cepPcad: '',
	mensagensAlerta: [],
};

export const initialValuesPreCadastro: GerarRenachForm = {
	usoFormularioEnum: usoFormularioEnumTypes.SEGUNDA_VIA,
	cpf: '',
	nome: '',
	nomeMae: '',
	nomePai: '',
	nomeSocial: '',
	sexo: '',
	dataNascimento: '',
	tipoDocumentoEnum: 'CARTEIRA_IDENTIDADE',
	numDocumento: '',
	orgaoExpDocumento: '',
	orgaoUfExpDocumento: '',
	nacionalidade: '',
	codigoNaturalidade: '',
	email: '',
	celularDDD: '',
	celular: '',
	logradouroUf: '',
	logradouro: '',
	logradouroNumero: '',
	logradouroComplemento: '',
	logradouroBairro: '',
	logradouroCodMunicipio: '',
	logradouroCep: '',
	codCiretranCondutor: '',
	statusReusoEnum: '',
	flagAtividadeRemunerada: false,
	flagSolicitaCNHDigital: '',
	codigoVerificador: '',
	categoriaPretendida: '',
	flagProcurador: false,
	codCiretranHabilitacao: '',
	cpfUsuario: '',
	codLocalidade: '',
	flagDeficienteFisico: '',
	categoriaAtual: '',
	registro: null,
	numeroPgu: null,
	rneNumero: null,
	rneOrgao: null,
	rneUf: null,
	rneCodPaisCnhEstrangeira: null,
	rneDataValidadeCnhEstrangeira: null,
	rneDataPrimeiraHabilitacaoCnhEstrangeira: null,
	rneIdentificacaoCnhEstrangeira: null,
	flagMilitar: '',
	codEntidadeMilitar: '',
	numeroIdentMilitar: '',
	flagExamePosto: 'N',
	flagExameToxicologico: '',
	paisAfetivo1: '',
	paisAfetivo2: '',
	autorizaEmail: 'N',
	autorizaSMS: 'N',
	uf: '',
	confirmaEmail: '',
	logradouroDescMunicipio: '',
	enderecoRecebimentoCep: '',
	enderecoRecebimentoLogradouro: '',
	enderecoRecebimentoBairro: '',
	enderecoRecebimentoMunicipio: '',
	enderecoRecebimentoCodigoMunicipio: '',
	origemNomeSocial: '0',
};

const getCategoriaPretendida = (
	dadosPcad: ConsultaEPortalSegundaViaData['dadosPcad'],
	categoriaPretendida: string,
	situacaoAtual: string,
) =>
	dadosPcad &&
	dadosPcad.categoriaPretendida &&
	dadosPcad.categoriaPretendida.trim() !== ''
		? dadosPcad.categoriaPretendida.trim()
		: trimValues(categoriaPretendida) || situacaoAtual;

// TREAT TO MOUNT FORM
export function treatInitialValues(
	values: ConsultaEPortalSegundaViaData | null,
	dataPrimeiraHabilitacao: string,
	codigoNaturalidadeIBGE: string | null,
	user: IUser,
	uf: string,
	flagProcurador: string,
): {
	initalValuesHabilitacao: IFormStatusCondutor;
	initialValuesPreCadastro: GerarRenachForm;
} {
	// DADOS DA CONSULTA INICIAL
	if (values !== null) {
		const {
			mensagensAlerta,
			cpf,
			nome,
			nomePai,
			nomeMae,
			naturalidade,
			numeroRg,
			dataNascimento,
			sexo,
			nacionalidade,
			usoForm,
			numeroRegistro,
			numeroPgu,
			renach,
			espelhoCnh,
			dadosExameMedico,
			dataExameTeorico,
			dadosExamePsicotecnico,
			categoriaPretendida,
			local,
			cfcA,
			cfcB,
			dataValidadeCnh,
			situacaoAtual,
			dataExamePratico4Rodas,
			dataExamePratico2Rodas,
			endereco,
			// cepPcon,
			flagAtividadeRemunerada,
			flagDeficienteFisico,
			localEmissao,
			orgaoEmissor,
			rne1,
			rne2,
			rne3,
			dataUltimaEmissaoCnh,
			dadosPcad,
		} = values;

		const formattedDataHabilitacao = {
			...initalValuesHabilitacao,
			// --- DADOS HABILITAÇÃO --- //
			mensagensAlerta,

			// INFORMAÇÕES PESSOAIS
			cpf,
			nome,
			nomePai,
			nomeMae,
			naturalidade,
			numeroRg,
			dataNascimento: dataNascimento && formatDate(dataNascimento),
			sexo,
			nacionalidade,

			// INFORMAÇÕES HABILITAÇÃO
			usoForm,
			numeroRegistro,
			numeroPgu,
			renach,
			espelhoCnh,
			exameMedicoDataExameMedico:
				dadosExameMedico.dataExameMedico &&
				formatDate(dadosExameMedico.dataExameMedico),
			exameMedicoCrm: dadosExameMedico.crm,
			dataExameTeorico: dataExameTeorico && formatDate(dataExameTeorico),
			examePsicotecnicoCrp: dadosExamePsicotecnico.crp,
			examePsicotecnicoLaudo:
				dadosExamePsicotecnico.dataExamePsicotecnico &&
				formatDate(dadosExamePsicotecnico.dataExamePsicotecnico),
			examePsicotecnicoDataExamePsicotecnico: formatDate(
				dadosExamePsicotecnico.dataExamePsicotecnico,
			),
			categoriaPretendida: getCategoriaPretendida(
				dadosPcad,
				categoriaPretendida,
				situacaoAtual,
			),
			local,
			cfcA,
			exameMedicoCategoriaPermitida: dadosExameMedico.categoriaPermitida,
			exameMedicoCred1: dadosExameMedico.cred2 || '',
			examePsicotecnicoResultado2: dadosExamePsicotecnico.resultado2,
			dataPrimeiraHabilitacao:
				dataPrimeiraHabilitacao && formatDate(dataPrimeiraHabilitacao),
			dataValidadeCnh: dataValidadeCnh && formatDate(dataValidadeCnh),
			situacaoAtual,
			cfcB,
			exameMedicoRegiao: dadosExameMedico.regiao,
			dataExamePratico2Rodas:
				dataExamePratico2Rodas && formatDate(dataExamePratico2Rodas),
			dataExamePratico4Rodas:
				dataExamePratico4Rodas && formatDate(dataExamePratico4Rodas),
			exameMedicoCred2: dadosExamePsicotecnico.cred2 || '',
			examePsicotecnicoRegiao: dadosExamePsicotecnico.regiao,
			dataUltimaEmissaoCnh:
				dataUltimaEmissaoCnh && formatDate(dataUltimaEmissaoCnh),

			// ENDEREÇO CADASTRADO
			enderecoCep: endereco.cep || '',
			enderecoLogradouro: endereco.logradouro || '',
			enderecoNumero: endereco.numero || '',
			enderecoComplemento: endereco.complemento || '',
			enderecoBairro: endereco.bairro || '',
			enderecoDescricaoMunicipio: endereco.descricaoMunicipio || '',

			// FORM
			localEmissao,
			orgaoEmissor,

			rne1:
				dadosPcad && dadosPcad.rne && dadosPcad.rne.trim() !== ''
					? dadosPcad.rne
					: rne1,
			rne2:
				dadosPcad &&
				dadosPcad.orgaoEmissorRne &&
				dadosPcad.orgaoEmissorRne.trim() !== ''
					? dadosPcad.orgaoEmissorRne
					: rne2,
			rne3:
				dadosPcad &&
				dadosPcad.localEmissaoRne &&
				dadosPcad.localEmissaoRne.trim() !== ''
					? dadosPcad.localEmissaoRne
					: rne3,
		};

		const formattedDataPreCadastro = {
			...initialValuesPreCadastro,
			usoFormularioEnum: usoFormularioEnumTypes.SEGUNDA_VIA,
			cpf: values.cpf,
			nome: removeAcentoCaracteresEspeciais(
				values.nome?.toUpperCase() || '',
			).trim(),
			nomeMae: removeAcentoCaracteresEspeciais(
				values.nomeMae?.toUpperCase() || '',
			).trim(),
			nomePai: removeAcentoCaracteresEspeciais(
				values.nomePai?.toUpperCase() || '',
			).trim(),
			sexo: values.sexo,
			dataNascimento: dataNascimento && formatDate(dataNascimento),
			dataPrimeiraHabilitacao,
			tipoDocumentoEnum: values.tipoDocumento,
			numDocumento: values.numeroRg?.trim(),
			orgaoExpDocumento:
				dadosPcad &&
				dadosPcad.orgaoEmissor &&
				dadosPcad.orgaoEmissor?.trim() !== ''
					? dadosPcad.orgaoEmissor.toLocaleUpperCase()?.trim()
					: orgaoEmissor?.trim(),
			orgaoUfExpDocumento:
				dadosPcad &&
				dadosPcad.localEmissao &&
				dadosPcad.localEmissao?.trim() !== ''
					? dadosPcad?.localEmissao?.trim()
					: values.localEmissao?.trim(),
			nacionalidade: values.nacionalidade,
			codigoNaturalidade: codigoNaturalidadeIBGE
				? Number(codigoNaturalidadeIBGE.trim())
				: (dadosPcad?.codNaturalidade &&
						Number(dadosPcad.codNaturalidade.trim())) ||
				  '',
			logradouro:
				values.endereco.logradouro &&
				removeAcentoCaracteresEspeciais(values.endereco.logradouro.trim()),
			logradouroNumero: values.endereco.numero,
			logradouroComplemento:
				values.endereco.complemento &&
				removeAcentoCaracteresEspeciais(values.endereco.complemento.trim()),
			logradouroBairro:
				values.endereco.bairro &&
				removeAcentoCaracteresEspeciais(values.endereco.bairro?.trim()),
			logradouroCodMunicipio: values.endereco.codigoMunicipio,
			logradouroDescMunicipio: values.endereco.descricaoMunicipio,
			logradouroCep: values.endereco.cep,
			codCiretranCondutor: dadosPcad?.ciretran || local || '',
			statusReusoEnum: 'SEM_REUSO',
			flagAtividadeRemunerada: flagAtividadeRemunerada ? 'S' : 'N',
			flagDeficienteFisico: flagDeficienteFisico ? 'S' : 'N',
			categoriaAtual:
				dadosPcad &&
				dadosPcad.categoriaAtual &&
				dadosPcad.categoriaAtual?.trim() !== ''
					? dadosPcad.categoriaAtual?.trim()
					: situacaoAtual,
			categoriaPretendida:
				dadosPcad &&
				dadosPcad.categoriaPretendida &&
				dadosPcad.categoriaPretendida?.trim() !== ''
					? dadosPcad.categoriaPretendida?.trim()
					: situacaoAtual,
			flagProcurador: flagProcurador === 'S',
			registro:
				dadosPcad &&
				dadosPcad.numRegistro &&
				dadosPcad.numRegistro?.trim() !== ''
					? dadosPcad.numRegistro?.trim()
					: numeroRegistro,
			numeroPgu:
				dadosPcad && dadosPcad.numPgu && dadosPcad.numPgu?.trim() !== ''
					? dadosPcad.numPgu?.trim()
					: numeroPgu,
			codCiretranHabilitacao: dadosPcad?.ciretran || local || '',
			cpfUsuario: user.cpf,
			codLocalidade: user.posto,
			uf,
			flagMilitar: 'N',
			rneNumero:
				dadosPcad && dadosPcad.rne && dadosPcad.rne?.trim() !== ''
					? dadosPcad.rne
					: rne1,
			rneOrgao:
				dadosPcad &&
				dadosPcad.orgaoEmissorRne &&
				dadosPcad.orgaoEmissorRne?.trim() !== ''
					? dadosPcad.orgaoEmissorRne
					: rne2,
			rneUf:
				dadosPcad &&
				dadosPcad.localEmissaoRne &&
				dadosPcad.localEmissaoRne?.trim() !== ''
					? dadosPcad.localEmissaoRne
					: rne3,
			origemNomeSocial: '0',
		};

		return {
			initalValuesHabilitacao: formattedDataHabilitacao,
			initialValuesPreCadastro: formattedDataPreCadastro,
		};
	}

	return {
		initalValuesHabilitacao,
		initialValuesPreCadastro,
	};
}

// TREAT TO SEND PRE CADASTRO
export function treatValuesPreCadastro(
	values: GerarRenachForm,
	user: IUser,
	idAtendimento: string,
	idCidadao: string,
	consultaCEP: string | null,
): PreCadastroCidadaoRequest {
	return {
		evento: {
			id_atendimento: idAtendimento,
			id_cidadao: idCidadao,
			cpf: user.cpf,
			identificacao: values.cpf ? limparMascara(values.cpf.trim()) : '',
			ip: user.ip,
			canal: {
				id: 1,
				desc: 'Presencial',
				estacao: {
					id: 0,
					desc: '',
				},
				localidade: {
					id: Number(user.posto),
					desc: user.nomePosto,
				},
			},
			atendente: {
				nome: '',
				id_atendente: '',
				id_posto: user.posto,
				desc_posto: user.nomePosto,
			},
		},
		// rg: values.numDocumento !== undefined ? values.numDocumento : '',
		rg: null,
		ufRg: null,
		cpf: values.cpf ? limparMascara(values.cpf.trim()) : '',
		cns: '',
		nome: values.nome.trim() ?? '',
		firstName: values.nome.trim() ?? '',
		dataNascimento: values.dataNascimento
			? new Date(unformatDate(values.dataNascimento)).getTime().toString()
			: '',
		nomeMae: values.nomeMae.trim() ?? '',
		foneResidencialDdd: limparMascara(values.telefone).substring(0, 2),
		foneResidencial: limparMascara(values.telefone).substring(2, 11),
		foneComercialDdd: '',
		foneComercial: '',
		foneCelularDdd: limparMascara(values.celular).substring(0, 2),
		foneCelular: limparMascara(values.celular).substring(2, 11),

		email: values.email || '',
		autorizaEmail: values.autorizaEmail === 'S',
		autorizaSMS: values.autorizaSMS === 'S',

		// "P" FIXO
		origem: 'P',
		statusUpdateSenha: '',
		senha: '',
		businessCategory: '',
		// 10 com biometria - 100 sem biometria
		presencial: 10,
		sexo: values.sexo ?? '',
		// 300 FIXO
		orgao: 200,
		idCidadao,
		idAtendimento,
		endereco: values.logradouro ? values.logradouro.trim() : '',
		cep: values?.logradouroCep?.trim() || '',
		enderecoUf: consultaCEP,
		enderecoBairro: values.logradouroBairro
			? values.logradouroBairro.trim()
			: '',
		enderecoCidade: values.logradouroDescMunicipio
			? values.logradouroDescMunicipio.trim()
			: '',
		enderecoNum: values.logradouroNumero ? values.logradouroNumero.trim() : '',
		enderecoCompl: values.logradouroComplemento
			? values.logradouroComplemento.trim()
			: '',
	};
}

// TREAT TO SEND RENACH
export function treatValues(
	evento: EventoContabilizacao,
	values: GerarRenachForm,
	user: IUser,
	dadosPreCadastro: GerarRenachForm,
	flagProcurador: string,
): GerarRenachIntegradoRequest {
	return {
		candidato: {
			apresentaDocumentos: '',
			autorizaEmail: values.autorizaEmail || '',
			autorizaSMS: values.autorizaSMS || '',
			bairro:
				(values.logradouroBairro &&
					removeAcentoCaracteresEspeciais(values.logradouroBairro)) ||
				'',
			categoriaAtual: values.categoriaPretendida?.trim(),
			categoriaPretendida: values.categoriaPretendida?.trim(),
			codigoUnidadeAtendimento: user.posto,
			celular: limparMascara(values.celular).substring(2, 11),
			cep: values.logradouroCep || '',
			cnhDigital: values.flagSolicitaCNHDigital === 'S' ? 'S' : 'N',
			codigoCiretranPostoExameMedico:
				values.flagExamePosto === 'S' ? user.posto : '',
			codigoEntidade: values.codEntidadeMilitar || '',
			codigoVerificador: values.codigoVerificador || '',
			coletaBiometrica: '',
			complemento:
				(values.logradouroComplemento &&
					removeAcentoCaracteresEspeciais(values.logradouroComplemento)) ||
				'',
			cpf: values.cpf,
			dataNascimento:
				values.dataNascimento && unformatDate(values.dataNascimento),
			dataPrimeiraHabilitacao: values.dataPrimeiraHabilitacao,
			dataValidadeCnhE: values.rneDataValidadeCnhEstrangeira || '',
			dddCelular: limparMascara(values.celular).substring(0, 2),
			dddTelefone: limparMascara(values.telefone).substring(0, 2),
			email: values.email || '',
			logradouro: removeAcentoCaracteresEspeciais(values.logradouro),
			nacionalidade: values.nacionalidade,
			naturalidade: values.codigoNaturalidade
				? values.codigoNaturalidade?.toString()
				: '',
			necessidadeVeiculoAdaptado:
				values.flagDeficienteFisico === 'S' ? 'S' : 'N',
			nome: removeAcentoCaracteresEspeciais(values.nome.toUpperCase()),
			nomeMae: removeAcentoCaracteresEspeciais(values.nomeMae.toUpperCase()),
			nomePai: removeAcentoCaracteresEspeciais(values.nomePai.toUpperCase()),
			nomeSocial:
				values.flagNomeSocial === 'S'
					? removeAcentoCaracteresEspeciais(
							values.nomeSocial ? values.nomeSocial.toUpperCase() : '',
					  )
					: '',
			nomeSocialValidadoBiometria: '',
			origemNomeSocial:
				(values.flagNomeSocial === 'S' && values.origemNomeSocial) || '',
			paisAfetivos1:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo1 ? values.paisAfetivo1.toUpperCase() : '',
					  )
					: '',
			paisAfetivos1ValidadoBiometria: '',
			paisAfetivos2:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo2 ? values.paisAfetivo2.toUpperCase() : '',
					  )
					: '',
			paisAfetivos2ValidadoBiometria: '',
			numCNHE: values.rneIdentificacaoCnhEstrangeira || '',
			numCarteiraMilitar: values.numeroIdentMilitar || '',
			numDocumento: values.numDocumento || '',
			numPGU: values.numeroPgu || '',
			numRNE: values.rneNumero || '',
			numRegistro: values.registro ? values.registro?.trim() : '',
			numero:
				values.logradouroNumero !== 'S/N'
					? String(values.logradouroNumero)
					: '',
			orgaoDocumento: values.orgaoExpDocumento
				? onlyLetters(values.orgaoExpDocumento.toLocaleUpperCase())
				: '',
			orgaoRNE: values.rneOrgao || '',
			paisCNHE: values.rneCodPaisCnhEstrangeira || '',
			pretendeAtividadeRemunerada:
				values.flagAtividadeRemunerada === 'S' ? 'S' : 'N',
			sexo: values.sexo,
			telefone: limparMascara(values.telefone).substring(2, 10),
			testeAlfabetizacao: '',
			tipoDocumento: '1',
			tipoProcesso: '3',
			uf: values.uf || '',
			ufDocumento: values.orgaoUfExpDocumento || '',
			ufRNE: values.rneUf || '',
		},
		dadosExameMedico: {
			codigoCiretranExameMedico: '',
			dataExame: '',
			horarioExame: '',
			idMedico: '',
			idMicroRegiaoMedico: '',
		},
		dadosExamePsicologo: {
			codigoCiretranExamePsicologo: '',
			dataExamePsicologico: '',
			horarioExamePsicologico: '',
			idMicroRegiaoPsicologo: '',
			idPsicologo: '',
		},
		gerarRenachBase: {
			evento: {
				id_atendimento: evento.id_atendimento,
				id_cidadao: evento.id_cidadao,
				ip: evento.ip,
				identificacao: evento.cpf && parseInt(limparMascara(values.cpf), 10),
				cpf: evento.cpf || '',
				canal: {
					id: 1,
					desc: 'Presencial',
					localidade: {
						desc: evento.canal?.localidade.desc || '',
						id: evento.canal?.localidade.id || 0,
					},
				},
			},
			usoFormularioEnum: usoFormularioEnumTypes.SEGUNDA_VIA,
			cpf: values.cpf ? limparMascara(values.cpf.trim()) : '',
			nome: removeAcentoCaracteresEspeciais(values.nome.toUpperCase()),
			nomeMae: removeAcentoCaracteresEspeciais(values.nomeMae.toUpperCase()),
			nomePai: removeAcentoCaracteresEspeciais(values.nomePai.toUpperCase()),
			nomeSocial:
				values.flagNomeSocial === 'S' && values.nomeSocial
					? removeAcentoCaracteresEspeciais(values.nomeSocial.toUpperCase())
					: '',
			sexo: values.sexo.trim() ?? '',
			dataNascimento: unformatDate(values.dataNascimento),
			tipoDocumentoEnum: values.tipoDocumentoEnum,
			numDocumento: values.numDocumento ? values.numDocumento.trim() : '',
			orgaoExpDocumento: values.orgaoExpDocumento
				? onlyLetters(values.orgaoExpDocumento.trim())
				: '',
			orgaoUfExpDocumento: values.orgaoUfExpDocumento || '',
			nacionalidade: values.nacionalidade,
			codigoNaturalidade: values.codigoNaturalidade,
			email: values.email || '',
			celularDDD: values.celular
				? limparMascara(values.celular).substring(0, 2)
				: '',
			celular: values.celular
				? limparMascara(values.celular).substring(2, 11)
				: '',
			logradouro: removeAcentoCaracteresEspeciais(values.logradouro) || '',
			logradouroNumero:
				values.logradouroNumero !== 'S/N'
					? String(values.logradouroNumero)
					: null,
			logradouroComplemento:
				(values.logradouroComplemento &&
					removeAcentoCaracteresEspeciais(values.logradouroComplemento)) ||
				'',
			logradouroBairro:
				(values.logradouroBairro &&
					removeAcentoCaracteresEspeciais(values.logradouroBairro)) ||
				'',
			logradouroCodMunicipio: values.logradouroCodMunicipio || '',
			logradouroCep: values.logradouroCep || '',
			codCiretranCondutor:
				(dadosPreCadastro.codCiretranCondutor &&
					dadosPreCadastro.codCiretranCondutor.toString()) ||
				'',
			statusReusoEnum: 'SEM_REUSO',
			flagDeficienteFisico: values.flagDeficienteFisico === 'S',
			// flagAtividadeRemuneradaAtual: values.flagAtividadeRemunerada === 'S',
			flagAtividadeRemunerada:
				values.flagAtividadeRemunerada === 'S' ? 'S' : 'N',
			flagSolicitaCNHDigital: values.flagSolicitaCNHDigital === 'S',
			categoriaAtual: values.categoriaPretendida.trim(),
			codigoVerificador: values.codigoVerificador,
			categoriaPretendida: values?.categoriaPretendida?.trim() || '',
			registro: values.registro ? values.registro.trim() : '',
			numeroPgu: values.numeroPgu || '',
			rneNumero: values.rneNumero || '',
			rneOrgao: values.rneOrgao ? onlyLetters(values.rneOrgao) : '',
			rneUf: values.rneUf !== undefined ? values.rneUf : null,
			rneCodPaisCnhEstrangeira:
				values.rneCodPaisCnhEstrangeira !== undefined
					? values.rneCodPaisCnhEstrangeira
					: '',
			rneDataValidadeCnhEstrangeira:
				values.rneDataValidadeCnhEstrangeira !== undefined
					? values.rneDataValidadeCnhEstrangeira
					: null,
			rneDataPrimeiraHabilitacaoCnhEstrangeira:
				values.rneDataPrimeiraHabilitacaoCnhEstrangeira !== undefined
					? values.rneDataPrimeiraHabilitacaoCnhEstrangeira
					: null,
			rneIdentificacaoCnhEstrangeira:
				values.rneIdentificacaoCnhEstrangeira !== undefined
					? values.rneIdentificacaoCnhEstrangeira
					: null,
			codEntidadeMilitar: '',
			numeroIdentMilitar: '',
			flagExamePosto: values.flagExamePosto === 'S',
			flagExameToxicologico: values.flagExameToxicologico === 'S',
			flagMilitar: values.flagMilitar === 'S',
			flagProcurador: flagProcurador === 'S',
			codCiretranHabilitacao:
				(dadosPreCadastro.codCiretranHabilitacao &&
					dadosPreCadastro.codCiretranHabilitacao.toString()) ||
				'',
			cpfUsuario: user.cpf,
			codLocalidade: user.posto,
			paisAfetivo1:
				values.flagFiliacaoAfetiva === 'S' && values.paisAfetivo1
					? removeAcentoCaracteresEspeciais(values.paisAfetivo1.toUpperCase())
					: '',
			paisAfetivo2:
				values.flagFiliacaoAfetiva === 'S' && values.paisAfetivo2
					? removeAcentoCaracteresEspeciais(values.paisAfetivo2.toUpperCase())
					: '',
		},
	};
}

export const schema = Yup.object<GerarRenachForm>().shape({
	flagAtividadeRemunerada: Yup.string().required(
		'Campo Obrigatório: Pretende exercer atividade remunerada.',
	),
	nome: Yup.string().required('Campo Obrigatório: Nome.'),
	cpf: Yup.string().required('Campo Obrigatório: CPF.'),
	numDocumento: Yup.string().when(['nacionalidade'], {
		is: nacionalidade => nacionalidade.toString() !== '3',
		then: Yup.string().required('Campo Obrigatório: RG.'),
	}),
	nomeMae: Yup.string().required('Campo Obrigatório: Nome da Mãe.'),
	sexo: Yup.string().required('Campo Obrigatório: Sexo.'),
	nacionalidade: Yup.string().required('Campo Obrigatório: Nacionalidade.'),
	uf: Yup.string().when(['nacionalidade'], {
		is: nacionalidade => nacionalidade.toString() === '1',
		then: Yup.string().required('Campo Obrigatório: UF.'),
	}),
	logradouroNumero: Yup.string().required('Campo Obrigatório: Número.'),
	logradouroBairro: Yup.string().required('Campo Obrigatório: Bairro.'),
	telefone: Yup.string().matches(userDataRegex.phoneNumber, {
		message: 'Campo Inválido: Telefone.',
		excludeEmptyString: true,
	}),
	celular: Yup.string()
		.when(['telefone'], {
			is: telefone => telefone === '',
			then: Yup.string().required('Campo Obrigatório: Celular.'),
		})
		.when(['autorizaSMS'], {
			is: autorizaSMS => autorizaSMS === 'S' || autorizaSMS === true,
			then: Yup.string().required('Campo Obrigatório: Celular.'),
		})
		.matches(userDataRegex.cellPhoneNumber, {
			message: 'Campo Inválido: Celular.',
			excludeEmptyString: true,
		}),
	orgaoExpDocumento: Yup.string().when(['nacionalidade'], {
		is: nacionalidade => nacionalidade.toString() !== '3',
		then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
	}),
	orgaoUfExpDocumento: Yup.string().when(['nacionalidade'], {
		is: nacionalidade => nacionalidade.toString() !== '3',
		then: Yup.string().required('Campo Obrigatório: UF.'),
	}),
	rneNumero: Yup.string().when(['nacionalidade'], {
		is: nacionalidade => nacionalidade.toString() === '3',
		then: Yup.string().required('Campo Obrigatório: RNE.'),
	}),
	rneOrgao: Yup.string().when(['nacionalidade'], {
		is: nacionalidade => nacionalidade.toString() === '3',
		then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
	}),
	dataNascimento: Yup.string().required(
		'Campo Obrigatório: Data de Nascimento.',
	),
	codigoNaturalidade: Yup.string().required('Campo Obrigatório: Naturalidade.'),
	logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
	enderecoRecebimentoCep: Yup.string().required(
		'Campo Obrigatório: Endereço de Recebimento - CEP.',
	),
	enderecoRecebimentoNumero: Yup.string().required(
		'Campo Obrigatório: Endereço de Recebimento - Número.',
	),
	enderecoRecebimentoBairro: Yup.string().required(
		'Campo Obrigatório: Endereço de Recebimento - Bairro.',
	),
	enderecoRecebimentoLogradouro: Yup.string().required(
		'Campo Obrigatório: Endereço de Recebimento - Logradouro.',
	),
	enderecoRecebimentoMunicipio: Yup.string().required(
		'Campo Obrigatório: Endereço de Recebimento - Município.',
	),
	email: Yup.string()
		.when(['autorizaEmail'], {
			is: autorizaEmail => autorizaEmail === 'S' || autorizaEmail === true,
			then: Yup.string().required('Campo Obrigatório: Email.'),
		})
		.matches(userDataRegex.emailAddressUpdated, {
			message: 'Formato de e-mail inválido.',
			excludeEmptyString: true,
		})
		.test(
			'same-email',
			'A confirmação do e-mail é diferente do e-mail informado.',
			function verify(value) {
				return value !== '' ? this.parent.confirmaEmail === value : true;
			},
		),
	flagSolicitaCNHDigital: Yup.string().required(
		'Campo Obrigatório:  Segunda Via Impressa ou Segunda Via Digital (Impressa + Digital).',
	),
	flagNomeSocial: Yup.string().required(
		'Campo Obrigatório: Deseja incluir nome social na CNH?.',
	),
	flagFiliacaoAfetiva: Yup.string().required(
		'Campo Obrigatório: Deseja incluir filiação afetiva na CNH?.',
	),
	nomeSocial: Yup.string()
		.when(['flagNomeSocial'], {
			is: flagNomeSocial => flagNomeSocial === 'S',
			then: Yup.string().required('Campo Obrigatório: Nome Social.'),
		})
		.test(
			'same-name',
			'O nome social não pode ser igual ao nome civil.',
			function verify(value) {
				return value !== '' && this.parent.flagNomeSocial === 'S'
					? this.parent.nome !== value
					: true;
			},
		)
		.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
			message:
				"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
			excludeEmptyString: true,
		})
		.test(
			'verify-social-name',
			"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
			function verify(value) {
				return !(
					value &&
					verifyStringIsAnInvalidPhrase(
						value,
						socialNameAndFiliationsPhrasesNotAllowed,
					)
				);
			},
		),
	paisAfetivo1: Yup.string()
		.when(['flagFiliacaoAfetiva'], {
			is: flagFiliacaoAfetiva => flagFiliacaoAfetiva === 'S',
			then: Yup.string().required('Campo Obrigatório: Filiação Afetiva 1.'),
		})
		.test(
			'same-name-filiacoes',
			'O nome da filiação afetiva 1 não pode ser igual ao nome da filiação afetiva 2.',
			function verify(value) {
				return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
					? this.parent.paisAfetivo2 !== value
					: true;
			},
		)
		.test(
			'same-name-filiacao1',
			'A filiação afetiva 1 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
			function verify(value) {
				return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
					? !(this.parent.nomePai === value || this.parent.nomeMae === value)
					: true;
			},
		)
		.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
			message:
				"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
			excludeEmptyString: true,
		})
		.test(
			'verify-filiation-name-1',
			"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
			function verify(value) {
				return !(
					value &&
					verifyStringIsAnInvalidPhrase(
						value,
						socialNameAndFiliationsPhrasesNotAllowed,
					)
				);
			},
		),
	paisAfetivo2: Yup.string()
		.test(
			'same-name-filiacao2',
			'A filiação afetiva 2 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
			function verify(value) {
				return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
					? !(this.parent.nomePai === value || this.parent.nomeMae === value)
					: true;
			},
		)
		.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
			message:
				"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
			excludeEmptyString: true,
		})
		.test(
			'verify-filiation-name-2',
			"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
			function verify(value) {
				return !(
					value &&
					verifyStringIsAnInvalidPhrase(
						value,
						socialNameAndFiliationsPhrasesNotAllowed,
					)
				);
			},
		),
});
