import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaSegundaVia } from './types';

// ACTIONS
import {
	consultaSegundaViaCnhSuccess,
	consultaSegundaViaCnhFailure,
} from './actions';

function* consultaSegundaViaCnh(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaSegundaVia } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}/consultaSegundaViaCnh`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaSegundaViaCnhSuccess(response));
	} else if (response.error) {
		yield put(consultaSegundaViaCnhFailure(response.error));
	}
}

export default all([
	takeLatest(Types.CONSULTA_SEGUNDA_VIA_CNH, consultaSegundaViaCnh),
]);
