import { combineReducers } from 'redux';

import atualizarParametroLocalidade from './atualizarParametroLocalidade/reducer';
import cadastrarParametroLocalidade from './cadastrarParametroLocalidade/reducer';
import consultarParametrosLocalidade from './consultarParametrosLocalidade/reducer';
import excluirParametroLocalidade from './excluirParametroLocalidade/reducer';

export default combineReducers({
	atualizarParametroLocalidade,
	cadastrarParametroLocalidade,
	consultarParametrosLocalidade,
	excluirParametroLocalidade,
});
