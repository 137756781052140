import React, { InputHTMLAttributes, useState, useCallback } from 'react';

import { v4 } from 'uuid';

import { Input as InputAntDesign } from 'antd';

import TypographyCommon from 'components/Common/Typography';
import { Container, Title, Content, Box, SubTitle, Hint } from '../styled';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	titleQuestion?: boolean;
	titleAlign?: 'start' | 'end';
	subtitle?: string;
	subtitleLabel?: string;
	name: string;
	defaultValue?: string;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	size?: number;
	error?: boolean;
	handleOnChange?: () => void;
	fixedHint?: number;
	autoComplete?: string;
	setBeforeLeftPosition?: number;
}

const Input: React.FC<InputProps> = ({
	title,
	titleSize = 'md',
	titleQuestion = false,
	titleAlign = 'end',
	subtitle,
	subtitleLabel,
	name,
	defaultValue = '',
	required = false,
	disabled = false,
	readOnly = false,
	size = 100,
	error,
	handleOnChange,
	itemRef,
	fixedHint,
	autoComplete = '',
	setBeforeLeftPosition = -4,
	...rest
}) => {
	const [isFocused, setIsFocused] = useState(false);

	const handleInputFocus = useCallback(() => {
		setIsFocused(true);
	}, []);

	const handleInputBlur = useCallback(() => {
		setIsFocused(false);
	}, []);

	const handleHintVisibility = useCallback((): boolean => {
		if (
			rest.maxLength &&
			rest.value &&
			String(rest.value).length === rest.maxLength &&
			disabled === false
		) {
			return true;
		}

		return false;
	}, [rest, disabled]);

	return (
		<Container>
			{title && (
				<Title
					align={titleAlign}
					size={titleSize}
					required={required}
					setBeforeLeftPosition={setBeforeLeftPosition}
				>
					{/* <label htmlFor={name}>{`${title}${titleQuestion ? '?' : ':'}`}</label> */}
					<TypographyCommon
						text={`${title}${titleQuestion ? '?' : ':'}`}
						subtitle={subtitleLabel}
					/>
				</Title>
			)}

			<Content>
				<Box
					size={size}
					isErrored={!!error}
					isFocused={isFocused}
					isDisabled={disabled}
					isReadOnly={readOnly}
				>
					<InputAntDesign
						name={name}
						id={`${name}-${v4()}`}
						defaultValue={defaultValue}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						disabled={disabled}
						readOnly={readOnly}
						itemRef={itemRef}
						onChange={handleOnChange}
						autoComplete={autoComplete}
						onKeyDown={e => {
							rest.onKeyDown
								? rest.onKeyDown(e)
								: e.key === 'Enter' && e.preventDefault();
						}}
						{...rest}
					/>

					{subtitle && (
						<SubTitle>
							<span>{subtitle}</span>
						</SubTitle>
					)}
				</Box>

				{handleHintVisibility() && (
					<Hint>{`(Tamanho máximo: ${rest.maxLength} caracteres)`}</Hint>
				)}

				{fixedHint && <Hint>{`Tamanho máximo: ${fixedHint} caracteres`}</Hint>}
			</Content>
		</Container>
	);
};

export default Input;
