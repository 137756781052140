import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { ConsultaHistoricoState, Types } from './types';

export const INITIAL_STATE: ConsultaHistoricoState = {
	consultaHistoricoData: [],
	totalRegistrosHistorico: 0,
};

export default function contabilizacao(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaHistoricoState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_HISTORICO: {
				break;
			}
			case Types.CONSULTA_HISTORICO_SUCCESS: {
				draft.totalRegistrosHistorico = action.payload.total_registros;
				draft.consultaHistoricoData =
					action.payload.evento_contabilizacao_historicos;
				break;
			}
			case Types.CONSULTA_HISTORICO_FAILURE: {
				draft.consultaHistoricoData = [];
				break;
			}

			default:
		}
	});
}
