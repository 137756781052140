import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, PesquisarAluno } from './types';

export const INITIAL_STATE: PesquisarAluno = {
	status: 0,
	data: {
		idProcesso: 0,
		alunos: [],
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): PesquisarAluno {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PESQUISAR_ALUNO_REQUEST: {
				break;
			}
			case Types.PESQUISAR_ALUNO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.PESQUISAR_ALUNO_NO_CONTENT: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.PESQUISAR_ALUNO_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.PESQUISAR_ALUNO_CLEAR: {
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
		return draft;
	});
}
