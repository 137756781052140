import React, { Suspense, useEffect, useState } from 'react';

import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultarMunicipioPorUfRequest } from 'store/modules/api/detranCrv/enum/consultarMunicipioPorUF/actions';
import { licenciamentoVeiculoRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/LicenciamentoVeiculo/actions';
import { consultarVeiculoV110Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';

// COMPONENTS
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';
import Select, { OptionProps } from 'components/Common/Form/Select';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';
import Placa from 'components/Common/Form/Fields/Placa';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

// FORM
import { schema, EmiteLicenciamento } from './form';

const LicenciamentoVeiculoResultado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const data = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.solicitarDocumentos.consultarVeiculoV110.data
				?.caracteristicaVeiculo,
	);

	const { consultarMunicipioPorUF } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.Enum,
	);

	const [listaMunicipios, setListaMunicipios] = useState(
		consultarMunicipioPorUF.data,
	);

	useEffect(() => {
		dispatch(consultarMunicipioPorUfRequest('SP'));
	}, [dispatch]);

	useEffect(() => {
		if (consultarMunicipioPorUF.status === 200) {
			setListaMunicipios(consultarMunicipioPorUF.data);
		}
	}, [consultarMunicipioPorUF]);

	const { consultarVeiculoV110, licenciamentoVeiculo } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);
	const [initialValues, setInitialValues] = useState<EmiteLicenciamento>({
		chassi: '',
		placa: '',
		municipio: {
			codigo: null,
			nome: '',
		},
		renavam: null,
		autenticacao: '',
	});

	useEffect(() => {
		if (consultarVeiculoV110.data?.caracteristicaVeiculo) {
			setInitialValues({
				chassi:
					consultarVeiculoV110.data.caracteristicaVeiculo.chassi?.trim() || '',
				placa: consultarVeiculoV110.data.caracteristicaVeiculo.placa,
				municipio: {
					codigo:
						consultarVeiculoV110.data.caracteristicaVeiculo.municipio.codigo,
					nome: consultarVeiculoV110.data.caracteristicaVeiculo.municipio.nome,
				},
				renavam: consultarVeiculoV110.data.caracteristicaVeiculo.renavam,
				autenticacao: '',
			});
		}
	}, [consultarVeiculoV110, history]);

	useEffect(() => {
		if (licenciamentoVeiculo.status === 200) {
			history.push('/detran-crv/licenciamento-veiculo/impressao');
		}
	}, [licenciamentoVeiculo, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Licenciamento">
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						delete values.autenticacao;
						dispatch(licenciamentoVeiculoRequest(values));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Section>
									<Row gutter={[0, 20]}>
										<Col span={12}>
											<Placa
												title="Placa"
												name="placa"
												defaultValue={initialValues.placa}
												formik={formik}
											/>
										</Col>
										<Col span={12}>
											<Field
												name="renavam"
												title="Renavam"
												as={Input}
												error={hasError(formik.errors, 'renavam')}
											/>
										</Col>
										<Col span={12}>
											<Field
												name="chassi"
												title="Chassi"
												as={Input}
												error={hasError(formik.errors, 'chassi')}
											/>
										</Col>
										<Col span={12}>
											<Field
												as={Select}
												defaultValue={data?.municipio.codigo}
												name="municipio.codigo"
												title="Município"
												options={listaMunicipios}
												error={!!formik.errors.municipio}
												onChange={(v: string) => {
													formik.setFieldValue('municipio.codigo', v);
													formik.setFieldValue(
														'municipio.nome',
														listaMunicipios.filter((item: OptionProps) => {
															if (item.value === v) {
																return item.label;
															}
															return '';
														}),
													);
												}}
											/>
										</Col>
										<Col span={24}>
											<Field
												maxLength="80"
												name="autenticacao"
												title="Autenticação Digital"
												as={Input}
												error={hasError(formik.errors, 'autenticacao')}
											/>
										</Col>
									</Row>
									<Row justify="center" align="top" gutter={[20, 10]}>
										<Col>
											<Button type="button" onClick={formik.handleReset}>
												Cancelar
											</Button>
										</Col>
										<Col>
											<Button type="submit">Enviar</Button>
										</Col>
									</Row>
								</Section>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar onClick={() => dispatch(consultarVeiculoV110Clear())} />
		</Suspense>
	);
};

export default LicenciamentoVeiculoResultado;
