export enum Types {
	CADASTRA_NOTAS_FISCAIS = '@vivaLeite/CADASTRA_NOTAS_FISCAIS',
	CADASTRA_NOTAS_FISCAIS_SUCCESS = '@vivaLeite/CADASTRA_NOTAS_FISCAIS_SUCCESS',
	CADASTRA_NOTAS_FISCAIS_FAILURE = '@vivaLeite/CADASTRA_NOTAS_FISCAIS_FAILURE',

	REDIRECT_NOTAS_FISCAIS_VIVA_LEITE_CLEAR = '@vivaLeite/REDIRECT_NOTAS_FISCAIS_VIVA_LEITE_CLEAR',

	NOTIFICATION_NOTAS_FISCAIS_VIVA_LEITE_CLEAR = '@vivaLeite/NOTIFICATION_NOTAS_FISCAIS_VIVA_LEITE_CLEAR',
}

export interface INotasFiscaisState {
	statusTransacaoNotasFiscais: string;
	redirectUrlNotasFiscais: string;
}

export interface IRequestIncluirNotasFiscais {
	servico: string;
	origem: string;
	num_pan: number;
	ano: number;
	mes: number;
	danfes: number[];
}
