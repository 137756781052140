import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../types';
import { Types, ProconState } from './types';

import atendimentoCip from './atendimentoCip/combineReducers';
import interacoes from './interacoes/combineReducers';
import consumidores from './consumidores/combineReducers';
import fornecedores from './fornecedores/combineReducers';
import dominiosCrm from './dominiosCrm/combineReducers';
import dominios from './dominios/combineReducers';
import atendimentos from './atendimentos/combineReducers';
import areas from './areas/combineReducers';
import grupoDeProblemas from './grupoDeProblemas/combineReducers';
import eventosEspeciais from './eventosEspeciais/combineReducers';
import atendimentosDeConsulta from './atendimentosDeConsulta/combineReducers';
import anexosConsumidor from './anexosConsumidor/combineReducers';
import documentosProduzidos from './documentosProduzidos/combineReducers';
import processosAdministrativos from './processosAdministrativos/combineReducers';

const allReducers = combineReducers({
	atendimentoCip,
	interacoes,
	consumidores,
	fornecedores,
	dominiosCrm,
	dominios,
	atendimentos,
	areas,
	grupoDeProblemas,
	eventosEspeciais,
	atendimentosDeConsulta,
	anexosConsumidor,
	documentosProduzidos,
	processosAdministrativos,
});

const combineReducer = (state: ProconState, action: ReducerAction) => {
	if (action.type === Types.PROCON_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
