export enum Types {
	EXCLUIR_PARAMETRO_LOCALIDADE = '@SGU/EXCLUIR_PARAMETRO_LOCALIDADE',
	EXCLUIR_PARAMETRO_LOCALIDADE_SUCCESS = '@SGU/EXCLUIR_PARAMETRO_LOCALIDADE_SUCCESS',
	EXCLUIR_PARAMETRO_LOCALIDADE_FAILURE = '@SGU/EXCLUIR_PARAMETRO_LOCALIDADE_FAILURE',
	EXCLUIR_PARAMETRO_LOCALIDADE_CLEAR = '@SGU/EXCLUIR_PARAMETRO_LOCALIDADE_CLEAR',
}

export interface ExcluirParametroLocalidade {
	status: number;
	data: null | ParametroLocalidadeData;
}

interface ParametroLocalidadeData {
	status: string;
	statusMensagem: string;
}

export interface ExcluirParametroLocalidadeRequest {
	id?: number | string;
	descricao: string;
	sigla: string;
	valor: string;
	idLocalidade: string | number;
}
