import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirUsuarioMainframeRequest } from './types';

export function excluirUsuarioMainframeRequest(
	data: ExcluirUsuarioMainframeRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_USUARIO_MAINFRAME_REQUEST,
		payload: data,
	};
}
export function excluirUsuarioMainframeSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_USUARIO_MAINFRAME_SUCCESS,
		payload,
	};
}
export function excluirUsuarioMainframeFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_USUARIO_MAINFRAME_FAILURE,
		payload: null,
	};
}
export function excluirUsuarioMainframeClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_USUARIO_MAINFRAME_CLEAR,
		payload: null,
	};
}
