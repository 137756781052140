// FICHA ALUNO
import FichaAluno from './fichaAluno/types';

// COMBOS
import Combos from './combos/types';

// INSCRICAO
import Inscricao from './inscricao/types';

import Responsavel from './responsavel/types';

export enum Types {
	EDUCACAO_CLEAR = '@educacao/EDUCACAO_CLEAR',
}
export interface EducacaoUpdateState {
	fichaAluno: FichaAluno;
	responsavel: Responsavel;
	combos: Combos;
	inscricao: Inscricao;
}

export interface IEvento {
	cpf: string;
	id_atendimento?: string;
	id_cidadao?: string;
	idAtendimento?: string;
	idCidadao?: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface IDocumentosAluno {
	nrRG: string;
	ufRG: string;
	cpf: string;
}

export interface IAluno {
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
}
