import * as Yup from 'yup';
import { ConsultaUsuariosMainframeRequest } from 'store/modules/api/iirgd/autorizacaoEspecial/consulta/types';

export const initialValues: ConsultaUsuariosMainframeRequest = {
	cpf: '',
	codigoLocalidade: 0,
};

export const schema = Yup.object<ConsultaUsuariosMainframeRequest>().shape({
	cpf: Yup.string().required('Campo Obrigatório: CPF do Operador.'),
});
