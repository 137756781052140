import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Row, Col } from 'antd';

// COMPONENTS
import PDFViewer from 'components/Common/PDFViewer';
import ButtonImage from 'components/Common/ButtonImage';

// REDUX
import { eventoTerminoSessaoRequest } from 'store/modules/api/detranCnh/evento/terminoSessao/actions';

// UTILS
import { threatValuesEndSessionEventRequest } from 'utils/functions/threatEventsRequest';

// TYPES
import { usoFormularioEnumTypes } from 'utils/constants/types';

import { BoxRenach } from './styles';

const RenachGerado: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [pdfFile, setPdfFile] = useState('');

	const { imprimirRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.imprimir,
	);

	const { eventoTerminoSessao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.evento,
	);

	const { detailsStartSection } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.evento.eventoInicioSessao,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	useEffect(() => {
		if (imprimirRenach.status === 200 && imprimirRenach.data) {
			window.open(
				imprimirRenach.data.url,
				'_blank',
				'location=yes,height=auto,width=auto,scrollbars=yes,status=false',
			);
		}
	}, [imprimirRenach]);

	useEffect(() => {
		if (eventoTerminoSessao.status === 0) {
			const valuesEndSessionEvent = threatValuesEndSessionEventRequest(
				atendimento,
				loginUnico,
				usoFormularioEnumTypes.PERMISSAO_INTERNACIONAL,
				detailsStartSection?.dataInicio || '',
			);

			dispatch(eventoTerminoSessaoRequest(valuesEndSessionEvent));
		}
	}, [
		atendimento,
		dispatch,
		eventoTerminoSessao.status,
		loginUnico,
		detailsStartSection,
	]);

	return (
		<>
			<Row gutter={[0, 10]} align="middle" justify="center">
				<Col span={15}>
					<BoxRenach>
						<h2>Informações salvas com sucesso</h2>

						<Row align="middle" justify="center">
							<Col>
								<ButtonImage
									type="button"
									src="finalizar-processo"
									onClick={() => history.push('/detran-cnh')}
								/>
							</Col>
						</Row>
					</BoxRenach>
				</Col>
			</Row>

			{pdfFile && (
				<PDFViewer
					title="Impressão permissão internacional do direito de dirigir"
					source={pdfFile}
					renderMode="popup"
					onClose={() => setPdfFile('')}
				/>
			)}
		</>
	);
};

export default RenachGerado;
