export enum Types {
	EMISSAO_ATESTADO_PAINEL_REQUEST = '@aac/EMISSAO_ATESTADO_PAINEL_REQUEST',
	EMISSAO_ATESTADO_PAINEL_SUCCESS = '@aac/EMISSAO_ATESTADO_PAINEL_SUCCESS',
	EMISSAO_ATESTADO_PAINEL_FAILURE = '@aac/EMISSAO_ATESTADO_PAINEL_FAILURE',
	EMISSAO_ATESTADO_PAINEL_CLEAR = '@aac/EMISSAO_ATESTADO_PAINEL_CLEAR',
}

export interface EmissaoAtestadoPainelLiberacaoNormal {
	status: number;
	data: EmissaoAtestadoLiberacaoNormal;
}

export interface EmissaoAtestadoLiberacaoNormal {
	pdfBase64: string;
}

export interface RequestEmissaoAtestado {
	dataNascimento: string | null;
	dataNascimentoTs?: string;
	digitoRg: string;
	nome: string;
	nomeSocial: string;
	tipoDocApresentado: string;
	nomeMae: string;
	nomePai: string;
	numeroDocumento: string;
	numeroRg: string | number;
	sexo: string;
	resultado: string;
	codigoValidacao: string;
	codigoRetorno: string;
	tipoLiberacao: string;
	origem: string;
	docProcurador: string;
	nomeProcurador: string;
}
