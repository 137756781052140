import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AtualizarUsuarioRequest } from './types';

// ACTIONS
import { atualizarUsuarioSuccess, atualizarUsuarioFailure } from './actions';

function* atualizarUsuario(request: ReducerAction) {
	const { payload }: { payload: AtualizarUsuarioRequest } = request;

	const body = { ...payload };

	const id_usuario = body.id;

	delete body.id;

	const response: ApiResponse = yield call(
		putApi,
		PATH_SGU,
		`usuarios/${payload.id}`,
		body,
		{
			id_usuario,
		},
	);

	if (response.status === 200) {
		yield put(atualizarUsuarioSuccess(response));
	} else {
		yield put(atualizarUsuarioFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_USUARIO_REQUEST, atualizarUsuario),
]);
