import { combineReducers } from 'redux';

import parametros from './parametros/reducer';
import servicos from './servicos/reducer';
import prefeituras from './prefeituras/reducer';
import servicosPrefeituras from './servicosPrefeituras/reducer';

// CANAIS
import canais from './canais/combineReducers';

// PREFEITURAS SERVIÇOS CANAIS
import prefeiturasServicosCanais from './prefeiturasServicosCanais/combineReducers';

export default combineReducers({
	parametros,
	servicos,
	prefeituras,
	servicosPrefeituras,
	canais,
	prefeiturasServicosCanais,
});
