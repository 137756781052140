import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AtualizarContatoRequest } from './types';

// ACTIONS
import { atualizarContatoSuccess, atualizarContatoFailure } from './actions';

function* atualizarContato(request: ReducerAction) {
	const { payload }: { payload: AtualizarContatoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`portal-detran/${CHANNEL}/contato/atualizar`,
		payload,
	);

	if (response.status === 200) {
		yield put(atualizarContatoSuccess({ response, payload }));
	} else {
		yield put(atualizarContatoFailure(response));
	}
}

export default all([takeLatest(Types.ATUALIZAR_CONTATO, atualizarContato)]);
