import { ReducerAction } from 'store/modules/types';

import { Types, ConsultarUmaFuncionalidadeRequest } from './types';

export function consultarUmaFuncionalidadeRequest(
	data: ConsultarUmaFuncionalidadeRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_UMA_FUNCIONALIDADE,
		payload: data,
	};
}
export function consultarUmaFuncionalidadeSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_UMA_FUNCIONALIDADE_SUCCESS,
		payload,
	};
}
export function consultarUmaFuncionalidadeFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_UMA_FUNCIONALIDADE_FAILURE,
		payload: null,
	};
}
export function consultarUmaFuncionalidadeClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_UMA_FUNCIONALIDADE_CLEAR,
		payload: null,
	};
}
