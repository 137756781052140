import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_NACIONALIDADES_REQUEST = '@cnh/CONSULTA_NACIONALIDADES_REQUEST',
	CONSULTA_NACIONALIDADES_SUCCESS = '@cnh/CONSULTA_NACIONALIDADES_SUCCESS',
	CONSULTA_NACIONALIDADES_NO_CONTENT = '@cnh/CONSULTA_NACIONALIDADES_NO_CONTENT',
	CONSULTA_NACIONALIDADES_FAILURE = '@cnh/CONSULTA_NACIONALIDADES_FAILURE',
}

export interface ConsultaNacionalidades {
	status: number;
	data: null | OptionProps[];
}
