export enum Types {
	CADASTRAR_PERFIL = '@SGU/CADASTRAR_PERFIL',
	CADASTRAR_PERFIL_SUCCESS = '@SGU/CADASTRAR_PERFIL_SUCCESS',
	CADASTRAR_PERFIL_FAILURE = '@SGU/CADASTRAR_PERFIL_FAILURE',
	CADASTRAR_PERFIL_CLEAR = '@SGU/CADASTRAR_PERFIL_CLEAR',
}

export interface CadastrarPerfil {
	status: number;
	data: null | PerfilData;
}

export interface PerfilData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarPerfilRequest {
	codigo?: string | number;
	descricao: string;
	codigoStatus: string | number;
	acessaSgu: boolean;
}
