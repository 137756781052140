import { EventoCipPFPJ } from '../combineTypes';

export enum Types {
	CADASTRA_ATENDIMENTO_PF_REQUEST = '@procon/CADASTRA_ATENDIMENTO_PF_REQUEST',
	CADASTRA_ATENDIMENTO_PF_SUCCESS = '@procon/CADASTRA_ATENDIMENTO_PF_SUCCESS',
	CADASTRA_ATENDIMENTO_PF_FAILURE = '@procon/CADASTRA_ATENDIMENTO_PF_FAILURE',
	CADASTRA_ATENDIMENTO_PF_CLEAR = '@procon/CADASTRA_ATENDIMENTO_PF_CLEAR',
}

export interface CadastraAtendimentoPf {
	status: null | number;
	data: null | CadastraAtendimentoPfData;
	dadosAtendimento: null | CadastraAtendimentoPfRequest;
}

interface CadastraAtendimentoPfData {
	atendimentoId: string;
}

export interface NovoFornecedor {
	tipo?: string | undefined;
	cnpj: string | null;
	cpf: string | null;
	email: string | null;
	razaoSocial: string | null;
	nomeFantasia: string | null;
	website: string | null;
	bairro: string | null;
	cep: string | null;
	complemento: string | null;
	estado: number | null;
	logradouro: string | null;
	municipioId: string | null;
	municipioDescricao: string | null;
	numero: string | null;
	telefone: string | null;
	celular: string | null;
}

export interface AnexosCipRequest {
	nome: string;
	base64: string;
}

export interface CadastraAtendimentoPfRequest {
	fornecedorId: string;
	consumidorId: string;
	eventoEspecialId: string;
	areaId: string;
	assuntoId: string;
	grupoDeProblemaId: string;
	problemaId: string;
	procureiOFornecedorParaResolverAQuestao: boolean;
	participacaoEmAudiencia: number | string;
	formaDeAquisicao: number | string;
	tipoDeContratacao: number | string;
	dataDaContratacao: string;
	nomeDoProdutoOuServico: string;
	tipoDoDocumento: number | string;
	numeroDoDocumento: string;
	dataDaOcorrenciaOuDoServico: string;
	dataDeCancelamentoDesistenciaOuNegativa: string;
	formaDePagamento: number | string;
	valorDaCompra: number | string;
	detalhesDaReclamacao: string;
	pedido: number | string;
	formaDeDevolucao: number | string;
	anexos: AnexosCipRequest[];
	descricaoDoPrimeiroContatoComFornecedor?: string;
	// PF
	temProcurador?: boolean;
	isEventoSazonal?: boolean;
	cpfDoProcuradorOuResponsavel?: string;
	detalhesDoProdutoOuServico?: string;
	nomeDoResponsavelOuProcurador?: string;
	rgDoResponsavelOuProcurador?: string;
	// PJ
	cnpjDoConsumidor?: string;
	dataDaOcorrenciaOuServico?: string;
	// EVENTO
	evento?: EventoCipPFPJ;
	// VALIDAÇÃO
	consumidorNome?: string;
	fornecedorNome?: string;
	tipoConsumidor?: 'PF' | 'PJ' | null;
	novoFornecedor?: NovoFornecedor;
	// NOVOS CAMPOS (COMPRA / DEVOLUÇÃO)
	compraAgencia: string;
	compraBancoEmissor: string;
	compraBandeiraDoCartao: string;
	compraChavePix: string;
	compraConta: string;
	compraIntermediadoraDePagamento: string;
	compraNumeroDoCartao: string;
	compraTipoDeChavePix: number | string;
	devolucaoAgencia: string;
	devolucaoBancoEmissorDoCartao: string;
	devolucaoBandeiraDoCartao: string;
	devolucaoChavePix: string;
	devolucaoConta: string;
	devolucaoNomeDoBanco: string;
	devolucaoNumeroDeParcelas: number;
	devolucaoNumeroDoCartao: string;
	devolucaoTipoDeChavePix: number | string;
	devolucaoValorDaParcela: number;
	idDoBalcaoUnico: string;
	descricaoDoPedidoDoConsumidor: string;
}
