import { ReducerAction } from 'store/modules/types';
import {
	Types,
	IResponseEmitirAlteracaoDadosCadastrais,
	IRequestWithHeader,
} from './types';

export function emitirAlteracaoDadosCadastraisRequest(
	payload: IRequestWithHeader,
): ReducerAction {
	return {
		type: Types.EMITIR_ALTERACAO_DADOS_CADASTRAIS_REQUEST,
		payload,
	};
}
export function emitirAlteracaoDadosCadastraisSuccess(
	payload: IResponseEmitirAlteracaoDadosCadastrais,
): ReducerAction {
	return {
		type: Types.EMITIR_ALTERACAO_DADOS_CADASTRAIS_SUCCESS,
		payload,
	};
}
export function emitirAlteracaoDadosCadastraisFailure(): ReducerAction {
	return {
		type: Types.EMITIR_ALTERACAO_DADOS_CADASTRAIS_FAILURE,
		payload: null,
	};
}
export function emitirAlteracaoDadosCadastraisClear(): ReducerAction {
	return {
		type: Types.EMITIR_ALTERACAO_DADOS_CADASTRAIS_CLEAR,
		payload: null,
	};
}
