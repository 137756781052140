// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function formasDevolucaoRequest() {
	return {
		type: Types.FORMAS_DEVOLUCAO_REQUEST,
	};
}
export function formasDevolucaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.FORMAS_DEVOLUCAO_SUCCESS,
		payload,
	};
}
export function formasDevolucaoFailure(payload: object): ReducerAction {
	return {
		type: Types.FORMAS_DEVOLUCAO_FAILURE,
		payload,
	};
}
export function formasDevolucaoClear(): ReducerAction {
	return {
		type: Types.FORMAS_DEVOLUCAO_CLEAR,
		payload: null,
	};
}
