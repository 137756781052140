import * as Yup from 'yup';

export interface IFormVivaLeite {
	cpf: string;
}

export const InitialValues: IFormVivaLeite = {
	cpf: '',
};

export const schema = Yup.object<IFormVivaLeite>().shape({
	cpf: Yup.string().required('Campo Obrigatório: CPF.'),
});
