export enum Types {
	AGENDAR_EXAMES_REQUEST = '@cnh/AGENDAR_EXAMES_REQUEST',
	AGENDAR_EXAMES_SUCCESS = '@cnh/AGENDAR_EXAMES_SUCCESS',
	AGENDAR_EXAMES_FAILURE = '@cnh/AGENDAR_EXAMES_FAILURE',
	AGENDAR_EXAMES_PRE_SAVE = '@cnh/AGENDAR_EXAMES_PRE_SAVE',
	AGENDAR_EXAMES_PRE_SAVE_CLEAR = '@cnh/AGENDAR_EXAMES_PRE_SAVE_CLEAR',
	AGENDAR_EXAMES_CLEAR = '@cnh/AGENDAR_EXAMES_CLEAR',
}

export interface AgendarExames {
	status: number;
	data: IDadosAgendarExames | null;
	preSaveData: PreSaveData | null;
	error?: AgendarExamesErrorData | null;
}

export interface IDadosAgendarExames {
	categoria_pretendida: string;
	cpf_cidadao: number;
	crm: number;
	crp: number;
	data_exame: string;
	data_exame_psicologo: string;
	endereco_medico: string;
	endereco_psicologo: string;
	horario_exame: string;
	horario_exame_psicologo: string;
	nome_cidadao: string;
	nome_medico: string;
	nome_psicologo: string;
	telefones: string;
	telefones_psicologo: string;
	tipo_processo: string;
}

export interface PreSaveData {
	cpf_cidadao: number;
	tipo_processo: string;
	cep_cidadao: number;
	codigo_ciretran_exame_medico: number;
	categoria_pretendida: string;
	id_medico: number;
	data_exame: string;
	horario_exame: string;
	// codigo_ciretran_exame_psicologo: number;
	// id_psicologo: number;
	// data_exame_psicologico: string;
	// horario_exame_psicologico: string;
	renach: string;
	id_micro_regiao_medico: number;
	// id_micro_regiao_psicologo: number;
	usuario: string;
}

export interface IRequestAgendarExames {
	cpf_cidadao: number;
	tipo_processo: string;
	cep_cidadao: number;
	codigo_ciretran_exame_medico: number;
	categoria_pretendida: string;
	id_medico: number;
	data_exame: string | null;
	horario_exame: string | null;
	codigo_ciretran_exame_psicologo: number | string;
	id_psicologo: number | string;
	data_exame_psicologico: string;
	horario_exame_psicologico: string;
	renach: string;
	id_micro_regiao_medico: number;
	id_micro_regiao_psicologo?: number | string;
	usuario: string;
}

interface AgendarExamesErrorData {
	transacao: string;
	codigo: 0;
	mensagem: string;
	mensagens: [string];
}
