import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';

import { Formik, Form, Field } from 'formik';
import { Modal, Row, Col } from 'antd';

// REDUX
import EnumCNH from 'store/modules/api/detranCnh/enum';

// COMPONENTS
import Radio from 'components/Common/Form/Radio';
import Select from 'components/Common/Form/Select';
import ButtonImage from 'components/Common/ButtonImage';
import Error from 'components/Common/Notifications/FormError';

// FORM
import getValidationsErrors, {
	initialValues,
	IConfirmacaoAtendimento,
} from './form';

// STYLED
import {
	Item,
	Label,
	ImageBox,
	Maos,
	Assinatura,
	Foto,
	ButtonDiv,
} from './styled';

interface Props {
	showModal: boolean;
	response: (data: IConfirmacaoAtendimento) => void;
}

const ConfirmacaoAtendimento: React.FC<Props> = ({
	showModal = false,
	response,
}) => {
	const [modalVisible, setModalVisible] = useState<boolean>(false);

	const [notificationErrors, setNotificationErrors] = useState<string[]>([]);

	const [justificativaAssinatura, setJustificativaAssinatura] =
		useState<boolean>(false);
	const [selectedAssinatura, setSelectedAssinatura] = useState<string>('');

	const [justificativaFoto, setJustificativaFoto] = useState<boolean>(false);
	const [selectedFoto, setSelectedFoto] = useState<string>('');

	// Manipula a visibilidade do Modal.
	useEffect(() => {
		setModalVisible(showModal);
	}, [showModal]);

	const handleValidate = useCallback(
		async values => {
			setNotificationErrors([]);

			const errors = await getValidationsErrors(values);

			if (typeof errors === 'boolean') {
				return errors;
			}

			const formikErrors: string[] = Object.keys(errors).map(value => {
				return errors[value];
			});

			setNotificationErrors(formikErrors);

			return errors;
		},
		[setNotificationErrors],
	);

	return (
		<Modal
			centered
			visible={modalVisible}
			closable={false}
			footer={null}
			style={{ animationDuration: '0s' }}
		>
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={initialValues}
				validate={handleValidate}
				onSubmit={async (formValues, { resetForm }) => {
					setNotificationErrors([]);
					response(formValues);
					resetForm();
				}}
			>
				{formik => (
					<Form autoComplete="off">
						{notificationErrors.length > 0 && (
							<Error
								errors={notificationErrors}
								onClose={() => {
									setNotificationErrors([]);
								}}
							/>
						)}

						<Item>
							<Row gutter={[0, 10]}>
								<Col span={24}>
									<Label>O cidadão tem todos os dedos das mãos?</Label>
								</Col>
							</Row>

							<Row gutter={[0, 10]}>
								<Col span={24}>
									<ImageBox>
										<Maos />
									</ImageBox>
								</Col>
							</Row>

							<Row justify="center" gutter={[0, 10]}>
								<Col>
									<Field
										as={Radio}
										name="todosDedos"
										options={EnumCNH.simNaoBoolean}
										defaultValue={initialValues.todosDedos}
									/>
								</Col>
							</Row>
						</Item>

						<Item>
							<Row gutter={[0, 10]}>
								<Col span={24}>
									<Label>É necessário coletar nova assinatura?</Label>
								</Col>
							</Row>

							<Row gutter={[0, 10]}>
								<Col span={24}>
									<ImageBox>
										<Assinatura />
									</ImageBox>
								</Col>
							</Row>

							<Row gutter={[0, 10]} justify="center">
								<Col>
									<Field
										as={Radio}
										name="coletarAssinatura"
										options={EnumCNH.simNaoBoolean}
										defaultValue={initialValues.coletarAssinatura}
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											const { value } = event.target;

											formik.setFieldValue('coletarAssinatura', value);
											setJustificativaAssinatura(!!value);

											if (!value) {
												setSelectedAssinatura('');
											}
										}}
										disabled={formik.values.coletarFoto}
									/>
								</Col>
							</Row>

							{justificativaAssinatura && (
								<Row gutter={[0, 10]} justify="center">
									<Col span={12}>
										<Field
											as={Select}
											name="justificativaAssinatura"
											options={EnumCNH.justificativaMudancaAssinatura}
											onChange={(v: string) => {
												formik.setFieldValue('justificativaAssinatura', v);
												setSelectedAssinatura(v);
											}}
											required={justificativaAssinatura}
											error={!!formik.errors.justificativaAssinatura}
										/>
									</Col>
								</Row>
							)}
						</Item>

						<Item>
							<Row gutter={[0, 10]}>
								<Col span={24}>
									<Label>É necessário coletar nova foto?</Label>
								</Col>
							</Row>

							<Row gutter={[0, 10]}>
								<Col span={24}>
									<ImageBox>
										<Foto />
									</ImageBox>
								</Col>
							</Row>

							<Row gutter={[0, 10]} justify="center">
								<Col>
									<Field
										as={Radio}
										name="coletarFoto"
										options={EnumCNH.simNaoBoolean}
										defaultValue={initialValues.coletarFoto}
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											const { value } = event.target;

											formik.setFieldValue('coletarFoto', value);
											setJustificativaFoto(!!value);

											if (!value) {
												setSelectedFoto('');
											}
										}}
										disabled={formik.values.coletarAssinatura}
									/>
								</Col>
							</Row>

							{justificativaFoto && (
								<Row gutter={[0, 10]} justify="center">
									<Col span={12}>
										<Field
											as={Select}
											name="justificativaFoto"
											options={EnumCNH.justificativaMudancaFisica}
											onChange={(v: string) => {
												formik.setFieldValue('justificativaFoto', v);
												setSelectedFoto(v);
											}}
											required={justificativaFoto}
											error={!!formik.errors.justificativaFoto}
										/>
									</Col>
								</Row>
							)}
						</Item>

						{(selectedAssinatura || selectedFoto) && (
							<>
								<Row gutter={[0, 10]}>
									<Col span={24} />
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={24}>
										<p>
											<strong>Justificativa:</strong>
										</p>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={24}>
										{selectedAssinatura && <p>{selectedAssinatura}</p>}

										{selectedFoto && <p>{selectedFoto}</p>}
									</Col>
								</Row>
							</>
						)}

						<ButtonDiv>
							<ButtonImage type="submit" src="avancar" />
						</ButtonDiv>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};

export default ConfirmacaoAtendimento;
