import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirPerfilRequest } from './types';

export function excluirPerfilRequest(
	data: ExcluirPerfilRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_PERFIL,
		payload: data,
	};
}
export function excluirPerfilSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_PERFIL_SUCCESS,
		payload,
	};
}
export function excluirPerfilFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_PERFIL_FAILURE,
		payload: null,
	};
}
export function excluirPerfilClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_PERFIL_CLEAR,
		payload: null,
	};
}
