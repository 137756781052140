import React, { useCallback, useEffect } from 'react';
import { Row, Col, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, FormikProps } from 'formik';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import Select from 'components/Common/Form/Select';
import Section from 'components/Common/Section';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { DIVISAO_EQUITATIVA_PARAMS } from 'pages/DetranCnh/utils/constants/services';
import { formatDate } from 'utils/genericFunctions';

// REDUX
import {
	horariosPsicologoPorDataClear,
	horariosPsicologoPorDataRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/horariosPsicologoPorData/actions';
import {
	datasExamePsicologicoClear,
	datasExamePsicologicoRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/datasExamePsicologico/actions';
import {
	microRegioesClear,
	microRegioesRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/microRegioes/actions';
import { regioesRequest } from 'store/modules/api/detranCnh/divisaoEquitativa/regioes/actions';
import {
	regiaoMicroRegiaoPorCepClear,
	regiaoMicroRegiaoPorCepRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/regiaoMicroRegiaoPorCep/actions';
import { addNotifications } from 'store/modules/app/notifications/actions';
import { alterarCadastroRequest } from 'store/modules/api/detranCnh/renachIntegrado/alterarCadastro/actions';

// FORM
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import { FormCnhEstrangeiro } from 'pages/DetranCnh/components/pages/PreCadastroEstrangeiro/StatusCondutor/form';
import DadosCidadao from '../DadosCidadao';
import { ICadastraExamePsicologico, schema, dadosAgendamento } from './form';
import { treatValuesIntegrado } from '../StatusCondutor/form';
import { ICadastraExameMedico } from '../AgendamentoMedico/form';

interface IProps {
	dadosCidadao: FormCnhEstrangeiro;
	redirecionamento?: string;
	redirecionamentoAgendamentoMedico?: string;
	medicoData: null | ICadastraExameMedico;
}

const AgendamentoPsicologico: React.FC<IProps> = ({
	dadosCidadao,
	redirecionamento,
	redirecionamentoAgendamentoMedico,
	medicoData,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		regiaoMicroRegiaoPorCep,
		regioes,
		microRegioes,
		datasExamePsicologico,
		horariosPsicologoPorData,
		agendarExames,
	} = useSelector(
		(state: ApplicationState) => state.api.detranCnh.divisaoEquitativa,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { consultaToxicologico } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cidadao,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.salvarAtendimento.data,
	);

	const handleSubmit = useCallback(
		(values: ICadastraExamePsicologico) => {
			if (datasExamePsicologico.data?.idPsicologo) {
				const treatedPsicologicoData =
					datasExamePsicologico.enumUnidades?.unidades_disponiveis?.length === 0
						? {
								...values,
								codigo_ciretran_exame_psicologo:
									dadosCidadao?.codigoUnidadeTransito?.toString() || '',
								id_psicologo: datasExamePsicologico.data.idPsicologo,
						  }
						: {
								...values,
								id_psicologo: datasExamePsicologico.data.idPsicologo,
						  };

				dispatch(
					alterarCadastroRequest(
						treatValuesIntegrado(
							dadosCidadao,
							user,
							medicoData || undefined,
							treatedPsicologicoData,
						),
					),
				);
			}
		},
		[
			dadosCidadao,
			datasExamePsicologico.data,
			datasExamePsicologico.enumUnidades,
			dispatch,
			medicoData,
			user,
		],
	);

	const clearFieldsFormikAndData = useCallback(
		(
			type:
				| 'regiao'
				| 'microRegiao'
				| 'data'
				| 'codigo_ciretran_exame_psicologo'
				| 'all',
			formik?: FormikProps<ICadastraExamePsicologico>,
		) => {
			switch (type) {
				case 'regiao':
					formik?.setFieldValue(`id_micro_regiao_psicologo`, '');
					formik?.setFieldValue(`data_exame_psicologico`, '');
					formik?.setFieldValue(`horario_exame_psicologico`, '');
					dispatch(microRegioesClear());
					dispatch(datasExamePsicologicoClear());
					dispatch(horariosPsicologoPorDataClear());
					break;

				case 'microRegiao':
					formik?.setFieldValue(`data_exame_psicologico`, '');
					formik?.setFieldValue(`horario_exame_psicologico`, '');
					dispatch(datasExamePsicologicoClear());
					dispatch(horariosPsicologoPorDataClear());
					break;

				case 'codigo_ciretran_exame_psicologo':
					formik?.setFieldValue(`data_exame_psicologico`, '');
					formik?.setFieldValue(`horario_exame_psicologico`, '');
					dispatch(datasExamePsicologicoClear());
					dispatch(horariosPsicologoPorDataClear());
					break;

				case 'data':
					formik?.setFieldValue(`horario_exame_psicologico`, '');
					dispatch(horariosPsicologoPorDataClear());
					break;

				case 'all':
					formik?.setFieldValue(`regiao`, '');
					formik?.setFieldValue(`id_micro_regiao_psicologo`, '');
					formik?.setFieldValue(`data_exame_psicologico`, '');
					formik?.setFieldValue(`horario_exame_psicologico`, '');
					dispatch(regiaoMicroRegiaoPorCepClear());
					dispatch(microRegioesClear());
					dispatch(datasExamePsicologicoClear());
					dispatch(horariosPsicologoPorDataClear());
					break;

				default:
			}
		},
		[dispatch],
	);

	const getRegiaoMicroRegiaoCep = useCallback(
		(cep: string) => {
			dispatch(
				regiaoMicroRegiaoPorCepRequest({
					cpf_cidadao: Number(dadosCidadao.cpf),
					tipo_exame: 'PSICOLOGO',
					is_portador_necessidades_especiais:
						dadosCidadao.flagDeficienteFisico === 'S',
					cep_cidadao: Number(cep),
					usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
				}),
			);
		},
		[dadosCidadao.cpf, dadosCidadao.flagDeficienteFisico, dispatch],
	);

	const handleCepSelecionado = useCallback(
		(
			res: IBuscarCepResultado,
			formik: FormikProps<ICadastraExamePsicologico>,
		) => {
			formik.setFieldValue(`cep`, res.cep);
			formik.setFieldValue(`logradouro`, res.endereco.substring(0, 40));
			formik.setFieldValue(`bairro`, res.bairro.substring(0, 13));
			formik.setFieldValue(`municipio`, res.municipio);
			if (res.cep.length === 8) {
				clearFieldsFormikAndData('all', formik);
				getRegiaoMicroRegiaoCep(res.cep);
			} else {
				clearFieldsFormikAndData('all', formik);
			}
		},
		[clearFieldsFormikAndData, getRegiaoMicroRegiaoCep],
	);
	const loadCleanChanges = useCallback(
		(
			item:
				| 'regiao'
				| 'microRegiao'
				| 'data'
				| 'hora'
				| 'codigo_ciretran_exame_psicologo',
			selectedValue: number,
			formik?: FormikProps<ICadastraExamePsicologico>,
		) => {
			switch (item) {
				case 'regiao':
					clearFieldsFormikAndData(item, formik);
					if (selectedValue) {
						dispatch(
							microRegioesRequest({
								cpf_cidadao: `00000000000${dadosCidadao.cpf}`.slice(-11),
								tipo_exame: 'PSICOLOGO',
								is_portador_necessidades_especiais:
									dadosCidadao.flagDeficienteFisico === 'S',
								id_regiao_medico: selectedValue,
								id_regiao_psicologo: selectedValue,
								usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
							}),
						);
					}

					break;
				case 'microRegiao':
					clearFieldsFormikAndData(item, formik);
					if (selectedValue) {
						dispatch(
							datasExamePsicologicoRequest({
								cep_cidadao: `00000000${dadosCidadao.cep}`.slice(-8),
								cpf_cidadao: `00000000000${dadosCidadao.cpf}`.slice(-11),
								codigo_ciretran: 18,
								id_micro_regiao: selectedValue,
								usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
							}),
						);
					}

					break;
				case 'codigo_ciretran_exame_psicologo':
					clearFieldsFormikAndData(item, formik);
					if (selectedValue) {
						if (selectedValue === 18) {
							dispatch(
								regioesRequest({
									cpf_cidadao: `00000000000${dadosCidadao.cpf}`.slice(-11),
									tipo_exame: 'PSICOLOGO',
									is_portador_necessidades_especiais:
										dadosCidadao.flagDeficienteFisico === 'S',
									usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
								}),
							);
						} else {
							dispatch(
								datasExamePsicologicoRequest({
									cep_cidadao: `00000000${dadosCidadao.cep}`.slice(-8),
									cpf_cidadao: `00000000000${dadosCidadao.cpf}`.slice(-11),
									codigo_ciretran: selectedValue,
									usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
								}),
							);
						}
					}
					break;
				case 'data':
					clearFieldsFormikAndData(item, formik);
					if (selectedValue) {
						dispatch(
							horariosPsicologoPorDataRequest({
								data_exame_psicologico: selectedValue.toString(),
								id_psicologo: Number(datasExamePsicologico.data?.idPsicologo),
								existe_agendamento_exame_medico: false,
							}),
						);
					}

					break;
				case 'hora':
					break;
				default:
					break;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			dadosCidadao,
			dadosAtendimento,
			user,
			datasExamePsicologico.data,
			dispatch,
		],
	);

	useEffect(() => {
		if (agendarExames.status === 200 && redirecionamento) {
			history.push(redirecionamento);
		} else if (
			agendarExames.status === 406 &&
			redirecionamentoAgendamentoMedico
		) {
			addNotifications({
				errors: [
					`${
						agendarExames.error?.mensagem ||
						'ERRO AO AGENDAR EXAME MEDICO/PSICOLOGICO'
					}`,
				],
			});
			history.push(redirecionamentoAgendamentoMedico);
		}
	}, [
		agendarExames,
		dispatch,
		history,
		redirecionamento,
		redirecionamentoAgendamentoMedico,
	]);

	useEffect(() => {
		if (dadosCidadao && Number(dadosCidadao.codigoUnidadeTransito) === 18) {
			dispatch(
				regioesRequest({
					cpf_cidadao: Number(dadosCidadao.cpf),
					tipo_exame: 'PSICOLOGO',
					is_portador_necessidades_especiais:
						dadosCidadao.flagDeficienteFisico === 'S',
					usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
				}),
			);
		} else if (dadosCidadao !== null) {
			dispatch(
				datasExamePsicologicoRequest({
					cep_cidadao: Number(dadosCidadao.cep),
					cpf_cidadao: Number(dadosCidadao.cpf),
					codigo_ciretran: Number(dadosCidadao.codigoUnidadeTransito),
					usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
				}),
			);
		}
	}, [dispatch, dadosAtendimento, user, dadosCidadao]);

	useEffect(() => {
		if (
			regiaoMicroRegiaoPorCep.data.regiao?.length &&
			(regiaoMicroRegiaoPorCep.status === 200 ||
				regiaoMicroRegiaoPorCep.status === 204)
		) {
			loadCleanChanges(
				'regiao',
				Number(regiaoMicroRegiaoPorCep.data.regiao[0].value),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [regiaoMicroRegiaoPorCep]);

	return (
		<Section title="Status do Condutor">
			<DadosCidadao
				dadosCidadao={dadosCidadao}
				limiteExame={
					consultaToxicologico.data &&
					consultaToxicologico.data.dataLimiteExameMedico
						? formatDate(consultaToxicologico.data.dataLimiteExameMedico)
						: undefined
				}
			/>

			<Row gutter={[0, 40]}>
				<Col span={24} />
			</Row>

			<Row>
				<Col span={24}>
					<h1 style={{ opacity: 0.7 }}>Agendamento Psicológico</h1>
				</Col>
			</Row>
			<Divider
				style={{ marginTop: 0, backgroundColor: '#000', opacity: 0.6 }}
			/>
			<Row>
				<Col span={24}>
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={dadosAgendamento}
						validate={values => getValidationsErrors(values, schema)}
						onSubmit={handleSubmit}
					>
						{formik => (
							<Form autoComplete="off">
								{dadosCidadao &&
									Number(dadosCidadao.codigoUnidadeTransito) !== 18 &&
									datasExamePsicologico.enumUnidades.unidades_disponiveis
										.length > 0 && (
										<Row gutter={[0, 10]}>
											<Col span={8}>
												<Field
													as={Select}
													title="Unidades Disponíveis"
													name="codigo_ciretran_exame_psicologo"
													options={
														datasExamePsicologico.enumUnidades
															? datasExamePsicologico.enumUnidades
																	.unidades_disponiveis
															: []
													}
													onChange={(v: number) => {
														formik.setFieldValue(
															'codigo_ciretran_exame_psicologo',
															v,
														);
														loadCleanChanges(
															'codigo_ciretran_exame_psicologo',
															v,
														);
													}}
													titleSize="md"
													size={80}
													error={
														!!formik.errors.codigo_ciretran_exame_psicologo
													}
												/>
											</Col>
										</Row>
									)}
								{((dadosCidadao &&
									Number(dadosCidadao.codigoUnidadeTransito) === 18) ||
									formik.values.codigo_ciretran_exame_psicologo === 18) && (
									<Row gutter={[0, 10]}>
										<Col span={8}>
											<Field
												as={Select}
												title="Região"
												name="regiao"
												options={
													regiaoMicroRegiaoPorCep.data.regiao?.length
														? regiaoMicroRegiaoPorCep.data.regiao
														: regioes.data
												}
												defaultFirstOptionText={
													regiaoMicroRegiaoPorCep.data.regiao?.length
														? regiaoMicroRegiaoPorCep.data.regiao[0].label
														: '- SELECIONE -'
												}
												onChange={(v: number) => {
													formik.setFieldValue('regiao', v);
													loadCleanChanges('regiao', v, formik);
												}}
												titleSize="md"
												size={80}
												error={!!formik.errors.regiao}
											/>
										</Col>
										<Col span={8}>
											<Field
												as={Select}
												title="Macrorregião"
												name="id_micro_regiao_psicologo"
												options={
													microRegioes.data.length ? microRegioes.data : []
												}
												onChange={(v: number) => {
													formik.setFieldValue('id_micro_regiao_psicologo', v);
													loadCleanChanges('microRegiao', v, formik);
												}}
												titleSize="md"
												size={80}
												error={!!formik.errors.id_micro_regiao_psicologo}
											/>
										</Col>
									</Row>
								)}

								<Row gutter={[0, 10]}>
									{((formik.values.codigo_ciretran_exame_psicologo &&
										formik.values.codigo_ciretran_exame_psicologo !== 0 &&
										formik.values.codigo_ciretran_exame_psicologo !== '') ||
										(formik.values.id_micro_regiao_psicologo &&
											formik.values.id_micro_regiao_psicologo !== '') ||
										datasExamePsicologico.data?.enum.length) && (
										<>
											<Col span={8}>
												<Field
													as={Select}
													title="Data"
													name="data_exame_psicologico"
													options={datasExamePsicologico.data?.enum || []}
													onChange={(v: number) => {
														formik.setFieldValue('data_exame_psicologico', v);
														loadCleanChanges('data', v, formik);
													}}
													titleSize="md"
													size={80}
													error={!!formik.errors.data_exame_psicologico}
												/>
											</Col>
											<Col span={8}>
												<Field
													as={Select}
													title="Hora"
													name="horario_exame_psicologico"
													options={horariosPsicologoPorData.data?.enum || []}
													onChange={(v: number) => {
														formik.setFieldValue(
															'horario_exame_psicologico',
															v,
														);
														loadCleanChanges('hora', v);
													}}
													titleSize="md"
													size={80}
													error={!!formik.errors.horario_exame_psicologico}
												/>
											</Col>
										</>
									)}
									{((dadosCidadao &&
										Number(dadosCidadao.codigoUnidadeTransito) === 18) ||
										formik.values.codigo_ciretran_exame_psicologo === 18) && (
										<Col span={8}>
											<CEP
												name="cep_cidadao"
												formik={formik}
												titleSize="sm"
												isCalledInService="DETRAN_CNH"
												size={80}
												result={(res: IBuscarCepResultado) =>
													handleCepSelecionado(res, formik)
												}
											/>
										</Col>
									)}
								</Row>

								<Row gutter={[0, 20]}>
									<Col span={24} />
								</Row>

								<Row justify="center">
									<Col>
										<ButtonImage type="submit" src="salvar" />
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</Col>
			</Row>
		</Section>
	);
};

export default AgendamentoPsicologico;
