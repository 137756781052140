import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_AAC } from 'services/_path';

// STORE
import { ReducerAction } from 'store/modules/types';

// ACTIONS
import { emissaoExigenciasSuccess, emissaoExigenciasFailure } from './actions';

// TYPES
import { Types, RequestEmissaoExigencia } from './types';

function* emissaoExigenciasRequest(request: ReducerAction) {
	const { payload }: { payload: RequestEmissaoExigencia } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_AAC,
		`aac/${CHANNEL}/emissao-atestado-exigencia`,
		payload,
	);

	if (response.status === 200) {
		yield put(emissaoExigenciasSuccess(response.data));
	} else if (response.error) {
		yield put(emissaoExigenciasFailure());
	}
}

export default all([
	takeLatest(Types.EMISSAO_EXIGENCIAS_REQUEST, emissaoExigenciasRequest),
]);
