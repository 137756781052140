import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_FORMAS_PAGAMENTO_REQUEST = '@procon/CONSULTA_FORMAS_PAGAMENTO_REQUEST',
	CONSULTA_FORMAS_PAGAMENTO_SUCCESS = '@procon/CONSULTA_FORMAS_PAGAMENTO_SUCCESS',
	CONSULTA_FORMAS_PAGAMENTO_FAILURE = '@procon/CONSULTA_FORMAS_PAGAMENTO_FAILURE',
	CONSULTA_FORMAS_PAGAMENTO_CLEAR = '@procon/CONSULTA_FORMAS_PAGAMENTO_CLEAR',
}

export interface FormasPagamento {
	status: number;
	data: OptionProps[];
}
