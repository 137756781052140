import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_IIRGD } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaPesquisaNomeSocialFiliacaoRequest } from './types';

// ACTIONS
import {
	consultaPesquisaNomeSocialFiliacaoSuccess,
	consultaPesquisaNomeSocialFiliacaoFailure,
} from './actions';

function* consultaPesquisaNomeSocialFiliacao(request: ReducerAction) {
	const {
		payload,
	}: { payload: ConsultaPesquisaNomeSocialFiliacaoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		`IIRGDDetran/${CHANNEL}/consultaPesquisaNomeSocial`,
		payload,
		undefined,
		true,
	);

	if (response.status === 200) {
		yield put(consultaPesquisaNomeSocialFiliacaoSuccess(response));
	} else {
		yield put(consultaPesquisaNomeSocialFiliacaoFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_REQUEST,
		consultaPesquisaNomeSocialFiliacao,
	),
]);
