import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_VIVA_LEITE } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { addNotifications } from 'store/modules/app/notifications/actions';
import {
	Types,
	IRequestConsultaBeneficiario,
	IRequestSelectMotivosCorte,
	IRequestExcluir,
	IRequestCotas,
	IRequestIncluirBeneficiarioBusca,
	IRequestIncluirBeneficiario,
} from './types';

// ACTIONS
import {
	consultaMotivosCorteFailure,
	consultaMotivosCorteSuccess,
	consultaBeneficiarioFailure,
	consultaBeneficiarioSuccess,
	removeBeneficiariosFailure,
	removeBeneficiariosSuccess,
	consultaCotasEntidadeFailure,
	consultaCotasEntidadeSuccess,
	consultaBeneficiarioInclusaoFailure,
	consultaBeneficiarioInclusaoSuccess,
	cadastraBeneficiarioFailure,
	cadastraBeneficiarioSuccess,
} from './actions';

const evento = {
	id_atendimento: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
	id_cidadao: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
	cpf: '94177708534',
	identificacao: '94177708534',
	ip: '192.168.1.1',
	canal: {
		id: 1,
		desc: 'Presencial',
		localidade: {
			id: 900,
			desc: 'SE',
		},
	},
};

function* consultaBeneficiario(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaBeneficiario } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/exclusao-beneficiarios`,
		{
			...payload,
			evento,
		},
	);

	if (response.status === 200) {
		yield put(consultaBeneficiarioSuccess(response));
	} else if (response.error) {
		yield put(consultaBeneficiarioFailure());
	}
}

function* consultaMotivosCorte(request: ReducerAction) {
	const { payload }: { payload: IRequestSelectMotivosCorte } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/motivos-corte`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaMotivosCorteSuccess(response));
	} else if (response.error) {
		yield put(consultaMotivosCorteFailure());
	}
}

function* removeBeneficiarios(request: ReducerAction) {
	const { payload }: { payload: IRequestExcluir } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/exclusao-beneficiarios`,
		{
			...payload,
			evento,
		},
	);

	if (response.status === 200) {
		yield put(removeBeneficiariosSuccess({ request: payload, response }));
	} else if (response.error) {
		yield put(removeBeneficiariosFailure());
	}
}

function* consultaCotasEntidade(request: ReducerAction) {
	const { payload }: { payload: IRequestCotas } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/cotas-entidade`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaCotasEntidadeSuccess(response));
	} else if (response.error) {
		yield put(consultaCotasEntidadeFailure());
	}
}

function* consultaBeneficiarioInclusao(request: ReducerAction) {
	const { payload }: { payload: IRequestIncluirBeneficiarioBusca } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/inclusao-beneficiarios`,
		payload,
	);

	if (response.status === 200) {
		if (response.data.codigo === 204) {
			addNotifications({
				errors: [response.data.mensagem],
			});
		} else if (response.data.alerta && response.data.alerta !== '') {
			addNotifications({
				errors: [response.data.alerta],
			});
		}
		yield put(consultaBeneficiarioInclusaoSuccess(response));
	} else if (response.error) {
		yield put(consultaBeneficiarioInclusaoFailure());
	}
}

function* cadastraBeneficiario(request: ReducerAction) {
	const { payload }: { payload: IRequestIncluirBeneficiario } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/inclusao-beneficiarios`,
		{
			...payload,
			evento,
		},
	);

	if (response.status === 200) {
		yield put(cadastraBeneficiarioSuccess(response));
	} else if (response.error) {
		yield put(cadastraBeneficiarioFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTA_BENEFICIARIOS, consultaBeneficiario),
	takeLatest(Types.CONSULTA_MOTIVOS_CORTE, consultaMotivosCorte),
	takeLatest(Types.REMOVE_BENEFICIARIOS, removeBeneficiarios),
	takeLatest(Types.CONSULTA_COTAS_ENTIDADE, consultaCotasEntidade),
	takeLatest(
		Types.CONSULTA_BENEFICIARIOS_INCLUSAO,
		consultaBeneficiarioInclusao,
	),
	takeLatest(Types.CADASTRA_BENEFICIARIO, cadastraBeneficiario),
]);
