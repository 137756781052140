import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, DomainServiceMunicipios } from './types';

export const INITIAL_STATE: DomainServiceMunicipios = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): DomainServiceMunicipios {
	return produce(state, draft => {
		switch (action.type) {
			case Types.DOMAIN_SERVICE_MUNICIPIOS_REQUEST: {
				break;
			}

			case Types.DOMAIN_SERVICE_MUNICIPIOS_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = action.payload.status;
				break;
			}

			case Types.DOMAIN_SERVICE_MUNICIPIOS_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.DOMAIN_SERVICE_MUNICIPIOS_CLEAR: {
				draft.status = 0;
				draft.data = null;
				break;
			}

			default:
		}
	});
}
