import JustificativaAtendimento from 'components/Pages/JustificativaAtendimento';
import ListaInfracoes from '../Jales/Transito/ListaInfracoes';
import Atendimento from '../Jales/Atendimento';
import Pesquisar from '../Jales/Transito/Pesquisar';
import ConsultaInfracoes from '../Jales/Transito/ConsultaInfracoes';
import Recurso from '../Jales/Transito/Recurso';
import RecursoResultado from '../Jales/Transito/Recurso/resultado';
import TransferirPontuacao from '../Jales/Transito/TransferirPontuacao';
import TransferirPontuacaoResultado from '../Jales/Transito/TransferirPontuacao/resultado';

// PATHS
import {
	ROUTE_PREFEITURA_CONSULTA_INFRACOES,
	ROUTE_PREFEITURA_CONSULTA_INFRACOES_RECURSO,
	ROUTE_PREFEITURA_CONSULTA_INFRACOES_RESULTADO_RECURSO,
	ROUTE_PREFEITURA_CONSULTA_INFRACOES_RESULTADO_TRANSFERENCIA,
	ROUTE_PREFEITURA_CONSULTA_INFRACOES_TRANSFERENCIA,
	ROUTE_PREFEITURA_JALES_TRANSPORTE,
	ROUTE_PREFEITURA_JUSTIFICATIVA_ATENDIMENTO,
	ROUTE_PREFEITURA_LISTA_INFRACOES,
	ROUTE_PREFEITURA_RECUPERAR_ATENDIMENTO,
} from './paths';

export const prefeituraRoute = {
	name: 'Prefeitura',
	route: '/prefeitura',
};

export default [
	{
		path: ROUTE_PREFEITURA_RECUPERAR_ATENDIMENTO,
		breadcrumb: [
			prefeituraRoute,
			{
				name: 'Atendimento',
			},
		],
		Component: Atendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PREFEITURA_JUSTIFICATIVA_ATENDIMENTO,
		breadcrumb: [
			prefeituraRoute,
			{
				name: 'Justificativa atendimento',
			},
		],
		Component: JustificativaAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PREFEITURA_JALES_TRANSPORTE,
		Component: Pesquisar,
		breadcrumb: [
			prefeituraRoute,
			{
				name: 'Consulta Auto Infração',
			},
		],
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PREFEITURA_LISTA_INFRACOES,
		Component: ListaInfracoes,
		breadcrumb: [
			prefeituraRoute,
			{
				name: 'Infrações',
			},
		],
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PREFEITURA_CONSULTA_INFRACOES,
		Component: ConsultaInfracoes,
		breadcrumb: [
			prefeituraRoute,
			{
				name: 'Consulta Auto Infração',
			},
		],
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PREFEITURA_CONSULTA_INFRACOES_RECURSO,
		Component: Recurso,
		breadcrumb: [
			prefeituraRoute,
			{
				name: 'Recurso',
			},
		],
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PREFEITURA_CONSULTA_INFRACOES_TRANSFERENCIA,
		Component: TransferirPontuacao,
		breadcrumb: [
			prefeituraRoute,
			{
				name: 'Transferência',
			},
		],
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PREFEITURA_CONSULTA_INFRACOES_RESULTADO_RECURSO,
		Component: RecursoResultado,
		breadcrumb: [
			prefeituraRoute,
			{
				name: 'Recurso',
			},
		],
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PREFEITURA_CONSULTA_INFRACOES_RESULTADO_TRANSFERENCIA,
		Component: TransferirPontuacaoResultado,
		breadcrumb: [
			prefeituraRoute,
			{
				name: 'Transferência',
			},
		],
		isAuthenticated: true,
		exact: true,
	},
];
