import produce from 'immer';

// TYPES
import { Types, CadastrarMotivo } from './types';
import { ReducerAction } from '../../../../../types';

export const INITIAL_STATE: CadastrarMotivo = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): CadastrarMotivo {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CADASTRAR_MOTIVO: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.CADASTRAR_MOTIVO_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = action.payload.status;

				break;
			}

			case Types.CADASTRAR_MOTIVO_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CADASTRAR_MOTIVO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
