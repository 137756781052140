import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaCategoriaRequest } from './types';

// ACTIONS
import { consultaCategoriaSuccess, consultaCategoriaFailure } from './actions';

function* consultaCategoria(request: ReducerAction) {
	const { payload }: { payload: ConsultaCategoriaRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}/categoria?funcionalidade=${payload.funcionalidade}`,
		{},
	);

	if (response.status === 200) {
		yield put(consultaCategoriaSuccess(response));
	} else if (response.error) {
		yield put(consultaCategoriaFailure(response.error));
	}
}

export default all([
	takeLatest(Types.CONSULTA_CATEGORIA_REQUEST, consultaCategoria),
]);
