export enum Types {
	ESTATISTICA_INCLUIR_REQUEST = '@sgu-service/ESTATISTICA_INCLUIR_REQUEST',
	ESTATISTICA_INCLUIR_SUCCESS = '@sgu-service/ESTATISTICA_INCLUIR_SUCCESS',
	ESTATISTICA_INCLUIR_FAILURE = '@sgu-service/ESTATISTICA_INCLUIR_FAILURE',
	ESTATISTICA_INCLUIR_CLEAR = '@sgu-service/ESTATISTICA_INCLUIR_CLEAR',
}

export interface EstatisticasIncluir {
	status: number;
	data: null | IncluirError;
}

interface IncluirError {
	codigo: number;
	mensagem: string;
}

export interface IncluirRequest {
	idCidadao?: string;
	descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0' | string;
	tipoDocumento: 'RG' | string;
	numeroDocumento: string;
	codigoLocalidade: number;
	idOrgao: number;
	cpf: string;
	descricaoServico:
		| 'RECUPERA_ATENDIMENTO'
		| 'VINCULACAO_DARE'
		| 'INSCRICAO_CPF'
		| 'ENVIAR_DADOS_PINPAD'
		| 'PROMOVER_SENHA'
		| 'REUSO_CARTEIRA_DE_IDENTIDADE'
		| string;
	flagConfronto?: string;
	descricaoDedosEnviados?: string;
	idMotivo: number;
	observacao: string;
	numeroVia?: number;
	numeroProcesso?: string;
	totalizador?: string;
}
