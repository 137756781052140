export enum Types {
	PROTOCOLAR_RECURSO = '@jalesTransito/PROTOCOLAR_RECURSO',
	PROTOCOLAR_RECURSO_SUCCESS = '@jalesTransito/PROTOCOLAR_RECURSO_SUCCESS',
	PROTOCOLAR_RECURSO_FAILURE = '@jalesTransito/PROTOCOLAR_RECURSO_FAILURE',
}

export interface protocolarRecurso {
	status: number;
	data: IRecurso | null;
}

interface IRecurso {
	documento: string;
	numero: string;
}

export interface IRequestRecurso {
	codigoAit: string;
	cnhUf?: string;
	cnhCategoria: string;
	requerenteTipo: string;
	requerenteNome: string;
	requerenteCpf: string;
	requerenteRua: string;
	requerenteNumero: string;
	requerenteBairro: string;
	requerenteComplemento: string;
	requerenteUf: string;
	requerenteCep: string;
	fotoRg: string;
	fotoCnh: string;
	fotoCrlv: string;
	fotoRequerimento: string;
	fotoContratoSocial: string;
	fotoFormulario: string;
	tipoRecurso: string;
}
