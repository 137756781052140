import React, { Suspense } from 'react';

// ANTD
import { Row, Col, Skeleton } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { consultarFichaCadastralClear } from 'store/modules/api/detranCrv/Processos/ConsultarFichaCadastral/actions';

// COMPONENTS
import Button from 'components/Common/Button';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';

// UTILS
import { isFilledValue } from 'utils/stringFunctions';

const ConsultarFichaCadastralResultado: React.FC = () => {
	const dispatch = useDispatch();
	const data = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.processos.consultarFichaCadastral.data?.ficha,
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Row>
				<Col span={24}>
					<Section size="sm" title="CONSULTAR FICHA CADASTRAL">
						<Row gutter={[0, 10]}>
							<Col span={6}>N° da Ficha</Col>
							<Col span={6}>
								<strong>{isFilledValue(data?.numeroFicha)}</strong>
							</Col>
							<Col span={4}>Ano Ficha</Col>
							<Col span={8}>
								<strong>{isFilledValue(data?.anoFicha)}</strong>
							</Col>
							<Col span={6}>Renavam</Col>
							<Col span={6}>
								<strong>{isFilledValue(data?.renavam)}</strong>
							</Col>
							<Col span={4}> Chassi</Col>
							<Col span={8}>
								<strong>{isFilledValue(data?.chassi)}</strong>
							</Col>
							<Col span={6}>Placa</Col>
							<Col span={6}>
								<strong>{isFilledValue(data?.placa)}</strong>
							</Col>
							<Col span={4}>Município</Col>
							<Col span={8}>
								<strong>{isFilledValue(data?.municipio.nome)}</strong>
							</Col>
							<Col span={6}>Despachante</Col>
							<Col span={6}>
								<strong>{isFilledValue(data?.codigoSSP)}</strong>
							</Col>
							<Col span={4}>Status Registro</Col>
							<Col span={8}>
								<strong>{isFilledValue(data?.status)}</strong>
							</Col>
							<Col span={6}>Retorno Consistência</Col>
							<Col span={6}>
								<strong>{isFilledValue(data?.retornoConsistencia)}</strong>
							</Col>
							<Col span={4}>Opção</Col>
							<Col span={8}>
								<strong>{isFilledValue(data?.opcao)}</strong>
							</Col>
							<Col span={24}>
								<Section size="sm" title="DATAS">
									<Row gutter={[0, 10]}>
										<Col span={12}>Inclusão da Ficha</Col>
										<Col span={12}>
											<strong>
												{data?.dataInclusaoFicha &&
													isFilledValue(
														new Date(
															data?.dataInclusaoFicha,
														).toLocaleDateString('pt-BR'),
													)}
											</strong>
										</Col>
									</Row>
								</Section>
							</Col>
						</Row>
					</Section>
					<Row justify="space-between" align="middle">
						<Col>
							<ButtonVoltar
								onClick={() => dispatch(consultarFichaCadastralClear())}
							/>
						</Col>
						<Col>
							<Button className="no-print" onClick={() => window.print()}>
								Imprimir
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Suspense>
	);
};

export default ConsultarFichaCadastralResultado;
