import axios from 'axios';

// REDUX
import { setLoader } from 'store/modules/app/loader/actions';
import { clearNotificationsValidate } from 'store/modules/app/notifications/actions';

// UTILS
import handleFailed, { handleFailedBody } from 'utils/getValidations';

const api = axios.create({
	baseURL: 'http://localhost:3333',
});

interface Response {
	status: number;
	data?: object;
	error?: boolean;
	message?: any;
}

export async function getApi(
	project: string,
	method: string,
	params?: object,
	verifyLocalServiceVersionTimeout?: boolean,
	disableLoader?: boolean,
): Promise<Response> {
	if (!disableLoader) {
		setLoader(true);
	}

	clearNotificationsValidate();

	try {
		const axiosTimeoutInMiliSeconds = 40000;
		const miliSecondsToSeconds = axiosTimeoutInMiliSeconds / 1000;

		const response = await api.get(`/${project}/${method}`, {
			timeout: verifyLocalServiceVersionTimeout
				? 3500
				: axiosTimeoutInMiliSeconds,
			timeoutErrorMessage: verifyLocalServiceVersionTimeout
				? undefined
				: `TIMEOUT - O Tempo máximo de ${miliSecondsToSeconds.toString()} segundos para a leitura da Biometria foi excedido. Tente Novamente.`,
			...params,
		});
		return response;
	} catch (error) {
		const defaultErrorMessage =
			'A leitura da biometria falhou. Realize o procedimento novamente.';

		const errorResponse = await handleFailed(error, true, defaultErrorMessage);
		const dataError = (await handleFailedBody(error)) as any;
		let dataTimeoutVerifyServiceVersion = { status: 0 };

		// Serve para validar se o serviço para verificar a versão respondeu timeout (indica que existe o serviço ativo).
		if (
			verifyLocalServiceVersionTimeout &&
			error?.toString()?.includes('timeout')
		) {
			dataTimeoutVerifyServiceVersion = { status: 408 };
		}

		return {
			status: 0,
			error: true,
			message: errorResponse,
			data: dataError || dataTimeoutVerifyServiceVersion,
		};
	} finally {
		setLoader(false);
	}
}

export default api;
