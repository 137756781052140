export enum Consts {
	EVENTO_ESPECIAL_DEFAULT = '49724238-1b3b-eb11-a813-000d3ac179a6',
}

export enum SectionAtendimento {
	ATENDIMENTO,
	FORNECEDOR,
	RESPOSTAS_INTERACOES,
	DOCUMENTOS_PROCON,
}

export enum TitleSectionAtendimento {
	ATENDIMENTO = 'Visualizar Atendimento',
	FORNECEDOR = 'Dados do Fornecedor',
	RESPOSTAS_INTERACOES = 'Respostas e Interações',
	DOCUMENTOS_PROCON = 'Documentos Procon',
}

export default {
	radioCadastroFornecedor: [
		{
			label: 'CNPJ',
			value: 'CNPJ',
		},
		{
			label: 'CPF',
			value: 'CPF',
		},
	],
	selectBandeirasDoCartao: [
		{
			label: 'AMÉRICA EXPRESS',
			value: 'AMÉRICA EXPRESS',
		},
		{
			label: 'DINERS CLUB INTERNATIONAL',
			value: 'DINERS CLUB INTERNATIONAL',
		},
		{
			label: 'ELO',
			value: 'ELO',
		},
		{
			label: 'HIPERCARD',
			value: 'HIPERCARD',
		},
		{
			label: 'MASTERCARD',
			value: 'MASTERCARD',
		},
		{
			label: 'MAESTRO',
			value: 'MAESTRO',
		},
		{
			label: 'VISA',
			value: 'VISA',
		},
		{
			label: 'OUTROS',
			value: 'OUTROS',
		},
	],
};
