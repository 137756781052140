import React, { Suspense, useCallback, useEffect, useState } from 'react';

import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { consultarAutorizacaoPreviaRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarAutorizacaoPrevia/actions';
import { consultarDetalhesAutorizacaoPreviaRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/consultarDetalhesAutorizacaoPrevia/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import SimpleTable from 'components/Common/Table';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import Placa from 'components/Common/Form/Fields/Placa';
import Input from 'components/Common/Form/Input/Input';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import { formatDateISO, onlyNumbersLettersSpace } from 'utils/genericFunctions';
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { IConsultarAutorizacaoPrevia, initialValues, schema } from './form';

const ConsultarAutorizacaoPrevia: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { consultarAutorizacaoPrevia, consultarDetalhesAutorizacaoPrevia } =
		useSelector(
			(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
		);

	const [requestBody, setRequestBody] = useState({
		chassi: '',
		placa: '',
		dataInicio: '',
		dataFim: '',
	});

	const columns = [
		{
			dataIndex: 'numeroAutorizacao',
			title: 'Número',
			align: 'center',
		},
		{
			dataIndex: 'situacaoAutorizacao',
			title: 'Situação',
			align: 'center',
		},
		{
			dataIndex: 'codigoAutorizacao',
			title: 'Código',
			align: 'center',
		},
		{
			dataIndex: 'sistemaConsumidor',
			title: 'Sistema Consumidor',
			align: 'center',
		},
		{
			dataIndex: 'dataHoraRegistroSerpro',
			title: 'Data Serpro',
			align: 'center',
			render: (dataHoraRegistroSerpro: number) => {
				const data = dataHoraRegistroSerpro
					? new Date(dataHoraRegistroSerpro).toLocaleDateString('pt-BR')
					: '';
				return data;
			},
		},
		{
			dataIndex: '',
			title: 'Ações',
			align: 'center',
			render: (text: any, record: any, index: number) => (
				<div style={{ margin: 'auto' }}>
					<ButtonImage
						src="selecionar"
						onClick={() =>
							dispatch(
								consultarDetalhesAutorizacaoPreviaRequest({
									chassi: requestBody.chassi,
									numeroAutorizacao:
										consultarAutorizacaoPrevia.data?.ocorrencias[index]
											.numeroAutorizacao,
								}),
							)
						}
					/>
				</div>
			),
		},
	];

	const handleClear = useCallback(
		(formik: FormikProps<IConsultarAutorizacaoPrevia>) => {
			dispatch(clearNotifications());
			formik.handleReset();
		},
		[dispatch],
	);

	useEffect(() => {
		if (consultarDetalhesAutorizacaoPrevia.status === 200) {
			history.push('/detran-crv/consultar-autorizacao-previa/resultado');
		}
	}, [consultarDetalhesAutorizacaoPrevia, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						const body = {
							chassi: values.chassi,
							placa: values.placa,
							dataInicio: values.dataInicio
								? formatDateISO(values.dataInicio)
								: '',
							dataFim: values.dataFim ? formatDateISO(values.dataFim) : '',
						};
						setRequestBody(body);

						dispatch(consultarAutorizacaoPreviaRequest(body));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={10}>
										<Field
											required
											titleSize="lg"
											name="chassi"
											title="Chassi"
											as={Input}
											error={hasError(formik.errors, 'chassi')}
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'chassi',
													onlyNumbersLettersSpace(v.target.value),
												);
											}}
										/>
									</Col>
									<Col span={10}>
										<Placa
											title="Placa"
											titleSize="lg"
											name="placaAnterior"
											defaultValue={initialValues.placa}
											formik={formik}
										/>
									</Col>
									<Col span={4} />
									<Col span={10}>
										<Field
											titleSize="lg"
											title="Data Inicial"
											name="dataInicio"
											type="text"
											mask="99/99/9999"
											as={ValidDataInput}
											error={hasError(formik.errors, 'dataInicio')}
											formik={formik}
											onChange={(v: string) =>
												formik.setFieldValue('dataInicio', v)
											}
										/>
									</Col>
									<Col span={10}>
										<Field
											titleSize="lg"
											title="Data Final"
											name="dataFim"
											type="text"
											mask="99/99/9999"
											as={ValidDataInput}
											error={hasError(formik.errors, 'dataFim')}
											formik={formik}
											onChange={(v: string) =>
												formik.setFieldValue('dataFim', v)
											}
										/>
									</Col>
									<Col span={4} />
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={() => handleClear(formik)}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
				<SimpleTable
					headers={columns}
					body={consultarAutorizacaoPrevia?.data?.ocorrencias}
					size="sm"
					striped
					bold
					hover={false}
					// rowClassName={handleRowClassName}
				/>
			</Section>
		</Suspense>
	);
};

export default ConsultarAutorizacaoPrevia;
