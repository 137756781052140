import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, ImpressaoIntencaoVenda } from './types';

export const INITIAL_STATE: ImpressaoIntencaoVenda = {
	status: 0,
	data: null,
	dataFailure: null,
	instanceRequest: null,
	placa: '',
	renavam: '',
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ImpressaoIntencaoVenda {
	return produce(state, draft => {
		switch (action.type) {
			case Types.IMPRESSAO_INTENCAO_VENDA_REQUEST: {
				draft.status = 100;
				draft.placa = action.payload.placa;
				draft.renavam = action.payload.renavam;
				draft.instanceRequest = action.payload.instanceRequest;
				break;
			}

			case Types.IMPRESSAO_INTENCAO_VENDA_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload.data;
				draft.instanceRequest = action.payload.instanceRequest;
				break;
			}

			case Types.IMPRESSAO_INTENCAO_VENDA_FAILURE: {
				draft.status = 400;
				draft.data = action.payload.response[0].messages;
				draft.instanceRequest = action.payload.instanceRequest;
				break;
			}

			case Types.IMPRESSAO_INTENCAO_VENDA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.placa = INITIAL_STATE.placa;
				draft.renavam = INITIAL_STATE.renavam;
				draft.instanceRequest = INITIAL_STATE.instanceRequest;

				break;
			}

			default:
		}
		return draft;
	});
}
