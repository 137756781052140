import { IFormConsultarMutuario } from 'pages/cdhu/Mutuario/Pesquisar/form';

export enum Types {
	CONSULTAR_MUTUARIO_REQUEST = '@cdhu/CONSULTAR_MUTUARIO_REQUEST',
	CONSULTAR_MUTUARIO_SUCCESS = '@cdhu/CONSULTAR_MUTUARIO_SUCCESS',
	CONSULTAR_MUTUARIO_FAILURE = '@cdhu/CONSULTAR_MUTUARIO_FAILURE',
	CONSULTAR_MUTUARIO_CLEAR = '@cdhu/CONSULTAR_MUTUARIO_CLEAR',
}

export interface ConsultarMutuario {
	status: number;
	data: ConsultarMutuarioData | null;
	form: IFormConsultarMutuario | null;
}

export interface ConsultarMutuarioData {
	dataCriacao: string;
	dataModificacao: string;
	id: string;
	logicalName: string;
	nome: string;
	acordo: string;
	cpf: string;
	cartorio: string;
	celular: string;
	cep: string;
	cidade: string;
	cidadeNasc: string;
	cnjHab: string;
	componenteContrato: string;
	conjugeCompoemRenda: string;
	contratoCDHU: string;
	cor: string;
	corRacaConjuge: string;
	cpfConfirmado: string;
	cpfConjuge: string;
	data: string;
	descricao: string;
	diaVencProrrogado: string;
	dtCasamento: string;
	dtGrifo: string;
	dtLavratura: string;
	dtNascimento: string;
	dtNascimentoConjuge: string;
	dtObito: string;
	dtObitoAtualizada: string;
	dtUltimoEvento: string;
	email: string;
	emailConjuge: string;
	endCompleto: string;
	estadoCivil: string;
	evento: string;
	folha: string;
	grifo: string;
	idade: number;
	livro: string;
	maeConjuge: string;
	mutuarioSolicitanteId: string;
	nacionalidade: string;
	nacionalidadeConjuge: string;
	nisPis: string;
	nomeConjuge: string;
	nomeMae: string;
	nroDossie: string;
	numIdentidade: string;
	numeroContrato: string;
	pactoAntenupcial: string;
	primeiroNome: string;
	profissaoConjuge: string;
	profissaoSistemaLegado: string;
	qtdPrestacoesAtrasadas: string;
	regimeBens: number;
	rg: string;
	rgConjuge: string;
	sexo: string;
	sobrenome: string;
	telefone1: string;
	telefone2: string;
	telefone3: string;
	tipoFinanciamento: string;
	ufNascimento: string;
	ufRg: string;
	ufRgConjuge: string;
	mensagemField: string;
	statusField: string;
	servicosSugeridos: {
		acordo: boolean;
		boletoAgrupado: boolean;
		segundaViaDeBoleto: boolean;
		segundaViaDeCarne: boolean;
	};
}

export interface ConsultarMutuarioRequest {
	numeroContrato: string;
}
