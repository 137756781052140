// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function listaMotivoJustificativaRequest(): ReducerAction {
	return {
		type: Types.LISTA_MOTIVO_JUSTIFICATIVA_REQUEST,
		payload: null,
	};
}
export function listaMotivoJustificativaSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.LISTA_MOTIVO_JUSTIFICATIVA_SUCCESS,
		payload,
	};
}
export function listaMotivoJustificativaFailure(): ReducerAction {
	return {
		type: Types.LISTA_MOTIVO_JUSTIFICATIVA_FAILURE,
		payload: null,
	};
}
export function listaMotivoJustificativaClear(): ReducerAction {
	return {
		type: Types.LISTA_MOTIVO_JUSTIFICATIVA_CLEAR,
		payload: null,
	};
}
