import { IRequestImpressaoDocumentos } from 'store/modules/api/seds/vivaLeite/documentos/types';
import * as Yup from 'yup';

export interface IBuscaDocumentosImpressao {
	codigo_documento: number | string;
}

export const InitialValues: IBuscaDocumentosImpressao = {
	codigo_documento: '',
};

export const InitialImpressaoDocumentos: IRequestImpressaoDocumentos = {
	servico: 'ATUALIZACAO CADASTRAL',
	origem: 'PORTAL',
	num_pan: 0,
	etapa: 'busca_lista_documentos_impressao',
	cpf: '',
};

export const schema = Yup.object<IBuscaDocumentosImpressao>().shape({
	codigo_documento: Yup.number()
		.required('Selecione o documento.')
		.nullable()
		.transform((value: string, originalValue: string) =>
			originalValue.toString().trim() === '' ? null : value,
		),
});
