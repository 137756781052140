const data = new Date();
const ano = data.getFullYear();

export default {
	mes: [
		{
			value: 1,
			label: 'Janeiro',
		},
		{
			value: 2,
			label: 'Fevereiro',
		},
		{
			value: 3,
			label: 'Março',
		},
		{
			value: 4,
			label: 'Abril',
		},
		{
			value: 5,
			label: 'Maio',
		},
		{
			value: 6,
			label: 'Junho',
		},
		{
			value: 7,
			label: 'Julho',
		},
		{
			value: 8,
			label: 'Agosto',
		},
		{
			value: 9,
			label: 'Setembro',
		},
		{
			value: 10,
			label: 'Outubro',
		},
		{
			value: 11,
			label: 'Novembro',
		},
		{
			value: 12,
			label: 'Dezembro',
		},
	],
	anoReferencia: [
		{
			value: ano,
			label: `${ano}`,
		},
		{
			value: ano - 1,
			label: `${ano - 1}`,
		},
	],
};
