import {
	ConsultaPconData,
	ConsultaPconRequest,
} from 'store/modules/api/detranCnh/eportal/consultaPcon/types';
import { ConsultaPontosRequest } from 'store/modules/api/detranCnh/sockets/consultaPontos/types';
import * as Yup from 'yup';

export interface IFormPesquisaConsultarPontuacao {
	tipoDocumento: 'CPF' | 'RG' | 'REGISTRO' | 'PGU';
	cpf: string;
	numeroRg: string;
	numeroRegistro: string;
	numeroPgu: string;
	flagDozeMeses: boolean | '';
}

interface LoginMainFrame {
	idUsuario: string;
	senhaMainFrame: string;
}

export const initialValues: IFormPesquisaConsultarPontuacao = {
	tipoDocumento: 'CPF',
	cpf: '',
	numeroRg: '',
	numeroRegistro: '',
	numeroPgu: '',
	flagDozeMeses: false,
};

export const radioOptionsConsulta = [
	{
		label: 'Consulta Histórico de Pontos',
		value: false,
	},
	{
		label: 'Consulta de Pontos dos Últimos 12 meses',
		value: true,
	},
];

export function treatValuesPcon(
	values: IFormPesquisaConsultarPontuacao,
	loginDataMainframe: LoginMainFrame,
): ConsultaPconRequest {
	let formattedData = {
		cpf: '',
		numeroRegistro: '',
		numeroPgu: '',
		usuario: loginDataMainframe.idUsuario,
		senha: loginDataMainframe.senhaMainFrame,
	};

	switch (values.tipoDocumento) {
		case 'CPF':
			formattedData = {
				...formattedData,
				cpf: values.cpf?.replace(/[.-]+/g, '') || '',
			};
			break;
		case 'REGISTRO':
			formattedData = {
				...formattedData,
				numeroRegistro:
					(Number(values.numeroRegistro) &&
						Number(values.numeroRegistro).toString()) ||
					'',
			};
			break;
		case 'PGU':
			formattedData = {
				...formattedData,
				numeroPgu:
					(Number(values.numeroPgu) && Number(values.numeroPgu).toString()) ||
					'',
			};
			break;
		default:
	}
	return formattedData;
}

export function treatValues(
	values: ConsultaPconData,
	valuesForm: IFormPesquisaConsultarPontuacao,
): ConsultaPontosRequest {
	const formattedData = {
		tipoCnh:
			(valuesForm.tipoDocumento === 'CPF' && 'REG') ||
			(valuesForm.tipoDocumento === 'PGU' && 'PGU') ||
			(valuesForm.tipoDocumento === 'REGISTRO' && 'REG') ||
			'',
		numRegistro:
			(valuesForm.tipoDocumento === 'PGU' &&
				Number(values.numeroPgu).toString()) ||
			Number(values.numeroRegistro).toString() ||
			'',
		cpf: valuesForm.tipoDocumento !== 'PGU' ? values.cpf : '',
		dataNascimento: values.dataNascimento,
		flagDozeMeses: !!valuesForm.flagDozeMeses,
	};

	return formattedData;
}

export const schema = Yup.object<IFormPesquisaConsultarPontuacao>().shape({
	cpf: Yup.string().when(
		['tipoDocumento'],
		(tipoDocumento: string, schema2: any) => {
			return schema2.test({
				test: (cpf: string) => tipoDocumento !== 'CPF' || cpf !== '',
				message: 'Campo Obrigatório: Documento.',
			});
		},
	),
	numeroRg: Yup.string().when(
		['tipoDocumento'],
		(tipoDocumento: string, schema2: any) => {
			return schema2.test({
				test: (numeroRg: string) => tipoDocumento !== 'RG' || numeroRg !== '',
				message: 'Campo Obrigatório: Documento.',
			});
		},
	),
	numeroRegistro: Yup.string().when(
		['tipoDocumento'],
		(tipoDocumento: string, schema2: any) => {
			return schema2.test({
				test: (numeroRegistro: string) =>
					tipoDocumento !== 'REGISTRO' || numeroRegistro !== '',
				message: 'Campo Obrigatório: Documento.',
			});
		},
	),
	numeroPgu: Yup.string().when(
		['tipoDocumento'],
		(tipoDocumento: string, schema2: any) => {
			return schema2.test({
				test: (numeroPgu: string) =>
					tipoDocumento !== 'PGU' || numeroPgu !== '',
				message: 'Campo Obrigatório: Documento.',
			});
		},
	),
	flagDozeMeses: Yup.string().required('Campo Obrigatório: Tipo Consulta'),
});
