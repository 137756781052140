import React, { useState, useCallback, useEffect } from 'react';

import { ConfigProvider, TimePicker as Picker } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/locale/pt_BR';

// STYLED
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { Box } from '../DatePicker/styled';
import { Container, Title, Content, SubTitle, Hint } from '../styled';

const format = 'HH:mm:ss';

interface Props {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | number;
	titleQuestion?: boolean;
	subtitle?: string;
	hint?: string;
	name: string;
	defaultValue?: string;
	value?: string;
	showNow?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	size?: number;
	error?: boolean;
	onChange: (date: string) => void;
}

const TimePicker: React.FC<Props> = ({
	title,
	titleSize = 'md',
	titleQuestion = false,
	subtitle,
	hint,
	name,
	defaultValue = '',
	value = '',
	showNow = true,
	required = false,
	disabled = false,
	readOnly = false,
	size = 100,
	error,
	onChange,
}) => {
	const [selectedValue, setSelectedValue] = useState<string>(value);

	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		setSelectedValue(value);
	}, [value]);

	const handleInputFocus = useCallback(() => {
		setIsFocused(true);
	}, []);

	const handleInputBlur = useCallback(() => {
		setIsFocused(false);
	}, []);

	const handleChange = useCallback(
		(e: moment.Moment | null, timeString: string) => {
			onChange(timeString);
		},
		[onChange],
	);

	const DatePickerAutoaccept = (props: PickerProps<moment.Moment>) => {
		const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
			const selectedTime = moment(e.target.value, format);

			if (selectedTime && selectedTime.isValid() && props.onChange) {
				props.onChange(selectedTime, e.target.value);
			}
		};
		return <Picker {...props} onBlur={onBlur} />;
	};

	return (
		<Container>
			{title && (
				<Title size={titleSize} required={required}>
					<label htmlFor={name}>{`${title}${titleQuestion ? '?' : ':'}`}</label>
				</Title>
			)}

			<Content>
				<Box
					size={size}
					isErrored={!!error}
					isFocused={isFocused}
					isDisabled={disabled}
					isReadOnly={readOnly}
				>
					<ConfigProvider locale={locale}>
						<DatePickerAutoaccept
							name={name}
							id={name}
							defaultValue={
								defaultValue ? moment(defaultValue, format) : undefined
							}
							value={selectedValue ? moment(selectedValue, format) : undefined}
							showNow={showNow}
							format={format}
							placeholder=""
							onFocus={handleInputFocus}
							onBlur={handleInputBlur}
							inputReadOnly={readOnly}
							disabled={disabled}
							onChange={handleChange}
						/>
					</ConfigProvider>

					{subtitle && (
						<SubTitle>
							<span>{subtitle}</span>
						</SubTitle>
					)}
				</Box>

				{hint && <Hint>{hint}</Hint>}
			</Content>
		</Container>
	);
};

export default TimePicker;
