export enum Types {
	AGENDAR_EXAMES_RENOVACAO = '@cnh/AGENDAR_EXAMES_RENOVACAO',
	AGENDAR_EXAMES_RENOVACAO_SUCCESS = '@cnh/AGENDAR_EXAMES_RENOVACAO_SUCCESS',
	AGENDAR_EXAMES_RENOVACAO_FAILURE = '@cnh/AGENDAR_EXAMES_RENOVACAO_FAILURE',
}

export interface agendarExamesRenovacao {
	status: number;
	data: IDadosAgendarExamesRenovacao | null;
}

interface IDadosAgendarExamesRenovacao {}

export interface IRequestAgendarExamesRenovacao {
	cidadao: {
		autoriza_envio_email: boolean;
		autoriza_envio_sms: boolean;
		celular: string;
		cpf: number;
		ddd_celular: string;
		ddd_telefone: string;
		email: string;
		necessidade_veiculo_adaptado: boolean;
		nome: string;
		pretende_atividade_remunerada: boolean;
		telefone: string;
	};
	cep_cidadao: number;
	dados_exame_medico: {
		categoria_pretendida: string;
		codigo_ciretran_exame_medico: number;
		data_exame: string;
		horario_exame: string;
		id_medico: number;
	};
	dados_exame_psicologico: {
		categoria_pretendida: string;
		codigo_ciretran_exame_psicologo: number;
		data_exame_psicologico: string;
		horario_exame_psicologico: string;
		id_psicologo: number;
	};
	id_micro_regiao_medico: number;
	id_micro_regiao_psicologo: number;
	usuario: string;
}
