export enum Types {
	CONSULTAR_MENSAGENS_ERRO_REQUEST = '@SGU/CONSULTAR_MENSAGENS_ERRO_REQUEST',
	CONSULTAR_MENSAGENS_ERRO_SUCCESS = '@SGU/CONSULTAR_MENSAGENS_ERRO_SUCCESS',
	CONSULTAR_MENSAGENS_ERRO_FAILURE = '@SGU/CONSULTAR_MENSAGENS_ERRO_FAILURE',
	CONSULTAR_MENSAGENS_ERRO_CLEAR = '@SGU/CONSULTAR_MENSAGENS_ERRO_CLEAR',
}

export interface ConsultarMensagensErro {
	status: number;
	data: null | MensagemErro;
	totalRegistrosConsulta: number;
}

export interface MensagemErro {
	codigo?: string | number;
	descricao: string;
}

export interface ConsultarMensagensErroRequest {
	registroInicial?: number;
	limite?: number;
	current?: number;
	id?: string | number;
	descricao?: string;
}
