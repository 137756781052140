import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaMudancaCategoriaRequest } from './types';

export function consultaMudancaCategoriaRequest(
	data: ConsultaMudancaCategoriaRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_MUDANCA_CATEGORIA_CNH_REQUEST,
		payload: data,
	};
}
export function consultaMudancaCategoriaSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_MUDANCA_CATEGORIA_CNH_SUCCESS,
		payload,
	};
}
export function consultaMudancaCategoriaFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_MUDANCA_CATEGORIA_CNH_FAILURE,
		payload,
	};
}
export function consultaMudancaCategoriaClear(): ReducerAction {
	return {
		type: Types.CONSULTA_MUDANCA_CATEGORIA_CNH_CLEAR,
		payload: null,
	};
}
