import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Formik, Form, Field, FormikProps } from 'formik';

import { Col, Row } from 'antd';

import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import Alert from 'components/Common/Notifications/Alert';
import ButtonImage from 'components/Common/ButtonImage';
import Checkbox from 'components/Common/Form/Checkbox';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';

import { ApplicationState } from 'store';

import {
	atualizaAtualizacaoCadastral,
	consultaAtualizacaoCadastral,
	clearRedirectUsuarioVivaLeite,
} from 'store/modules/api/seds/vivaLeite/usuario/actions';
import { clearNotificationsDocumentosVivaLeite } from 'store/modules/api/seds/vivaLeite/documentos/actions';

import {
	IAtualizacaoCadastral,
	IEndrerecosEntrega,
} from 'store/modules/api/seds/vivaLeite/usuario/types';
import { treatValues, schema } from './form';

import { InitialBuscaDadosCadastrais } from '../components/pages/Padrao';

const AtualizacaoCadastral = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const routeMatch = useRouteMatch();

	const {
		atualizacaoCadastralData,
		selectedUser,
		redirectUrlUsuario,
	} = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.usuario,
	);

	const { statusTransacaoDocumentos } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.documentos,
	);

	const [urlServico, setUrlServico] = useState<string>('');

	const handleCleanStatus = useCallback(() => {
		dispatch(clearNotificationsDocumentosVivaLeite());
	}, [dispatch]);

	const handleSubmit = useCallback(
		(values: IAtualizacaoCadastral) => {
			const servico =
				urlServico === 'atualizacaoCadastral'
					? 'ATUALIZACAO CADASTRAL'
					: 'PRESTACAO DE CONTAS';
			const requestData = InitialBuscaDadosCadastrais;
			requestData.servico = servico;
			requestData.num_pan = selectedUser.numeroPan;
			requestData.etapa = 'efetiva_atualizacao_cadastral';

			const request = treatValues(values, requestData);

			dispatch(
				atualizaAtualizacaoCadastral({
					...request,
					redirectUrlUsuario: urlServico,
				}),
			);
		},
		[dispatch, selectedUser.numeroPan, urlServico],
	);

	const handleCepSelecionado = useCallback(
		(
			res: IBuscarCepResultado,
			formik: FormikProps<IAtualizacaoCadastral>,
			index: number,
		) => {
			formik.setFieldValue(
				`dados_atualizar.enderecos_entrega[${index}].cep`,
				res.cep,
			);
			formik.setFieldValue(
				`dados_atualizar.enderecos_entrega[${index}].endereco`,
				res.endereco.substring(0, 100),
			);
			formik.setFieldValue(
				`dados_atualizar.enderecos_entrega[${index}].bairro`,
				res.bairro.substring(0, 60),
			);
		},
		[],
	);

	useEffect(() => {
		const requestData = InitialBuscaDadosCadastrais;
		requestData.num_pan = selectedUser.numeroPan;
		requestData.etapa = 'busca_dados_cadastrais';

		dispatch(consultaAtualizacaoCadastral(requestData));
	}, [dispatch, selectedUser.numeroPan]);

	useEffect(() => {
		if (redirectUrlUsuario === 'atualizacaoCadastral') {
			dispatch(clearRedirectUsuarioVivaLeite());
			history.push('atualizacaoCadastral/resultado');
		} else if (redirectUrlUsuario === 'termoDeAdvertencia') {
			dispatch(clearRedirectUsuarioVivaLeite());
			history.push('excluirBeneficiario');
		}
	}, [dispatch, history, redirectUrlUsuario, selectedUser.numeroPan]);

	useEffect(() => {
		const finalPath = routeMatch.path.split('/');

		setUrlServico(finalPath[finalPath.length - 1]);
	}, [routeMatch]);

	useEffect(() => {
		return function cleanup() {
			handleCleanStatus();
		};
	}, [handleCleanStatus, redirectUrlUsuario]);

	return (
		<>
			{statusTransacaoDocumentos !== '' && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Alert
							type="info"
							message={statusTransacaoDocumentos}
							onClose={handleCleanStatus}
						/>
					</Col>
				</Row>
			)}
			<Row gutter={[0, 10]} justify="center" align="middle">
				<Col span={24}>
					<Section size="sm" titleBold={false} title="DADOS DA ENTIDADE">
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Input
									title="PAN"
									name="pan"
									defaultValue={`${selectedUser.numeroPan.toString()} - ${
										selectedUser.razaoSocial
									}`}
									readOnly
								/>
							</Col>
						</Row>
					</Section>
				</Col>
				<Col span={24}>
					<Formik
						enableReinitialize
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={atualizacaoCadastralData}
						validate={values => getValidationsErrors(values, schema)}
						onSubmit={handleSubmit}
					>
						{formik => {
							return (
								<Form autoComplete="off">
									<Section
										size="sm"
										titleBold={false}
										title="DADOS DO RESPONSÁVEL"
									>
										<Row gutter={[0, 10]}>
											<Col span={11}>
												<Field
													title="Nome"
													name="dados_visualizar.nome_responsavel"
													as={Input}
													readOnly
												/>
											</Col>
											<Col offset={1} span={12}>
												<Field
													subtitle="O responsável é o Vice-presidente"
													name="dados_visualizar.responsavel_vice"
													as={Checkbox}
													marginLeft={133}
													checked={
														formik.values.dados_visualizar.responsavel_vice ===
														'V'
													}
													disabled
												/>
											</Col>
										</Row>
										<Row gutter={[0, 10]}>
											<Col span={11}>
												<Field
													title="RG"
													name="dados_visualizar.rg_responsavel"
													as={Input}
													readOnly
												/>
											</Col>
											<Col offset={1} span={12}>
												<Field
													title="Endereço"
													name="dados_visualizar.endereco_responsavel"
													as={Input}
													readOnly
												/>
											</Col>
										</Row>
										<Row gutter={[0, 10]}>
											<Col span={11}>
												<Field
													title="CPF"
													name="dados_visualizar.cpf_responsavel"
													as={Input}
													readOnly
												/>
											</Col>
											<Col offset={1} span={12}>
												<Row>
													<Col span={12}>
														<Field
															title="Número"
															name="dados_atualizar.numero_endereco_responsavel"
															as={Input}
														/>
													</Col>
													<Col span={12}>
														<Field
															title="Complemento"
															name="dados_visualizar.complemento_responsavel"
															as={Input}
															readOnly
														/>
													</Col>
												</Row>
											</Col>
										</Row>
										<Row gutter={[0, 10]}>
											<Col span={11}>
												<Field
													title="Telefone I"
													name="dados_atualizar.telefone_1_responsavel"
													as={Input}
													required
													error={hasError(
														formik.errors,
														'dados_atualizar.telefone_1_responsavel',
													)}
													maxLength="12"
												/>
											</Col>
											<Col offset={1} span={12}>
												<Field
													title="CEP"
													name="dados_visualizar.cep_responsavel"
													as={Input}
													readOnly
												/>
											</Col>
										</Row>
										<Row gutter={[0, 10]}>
											<Col span={11}>
												<Field
													title="Telefone II"
													name="dados_atualizar.telefone_2_responsavel"
													as={Input}
													maxLength="12"
												/>
											</Col>
											<Col offset={1} span={12}>
												<Field
													title="Bairro"
													name="dados_visualizar.bairro_responsavel"
													as={Input}
													readOnly
												/>
											</Col>
										</Row>
										<Row gutter={[0, 10]}>
											<Col span={11}>
												<Field
													title="Celular"
													name="dados_atualizar.celular_responsavel"
													as={Input}
													maxLength="12"
												/>
											</Col>
											<Col offset={1} span={12}>
												<Field
													title="Município"
													name="dados_visualizar.municipio_responsavel"
													as={Input}
													readOnly
												/>
											</Col>
										</Row>
									</Section>
									<Section size="sm" titleBold={false} title="DIAS DE ENTREGA">
										<Row gutter={[0, 10]}>
											<Col span={4}>
												<Field
													subtitle="Segunda-feira"
													name="dados_atualizar.entrega_segunda"
													as={Checkbox}
													checked={
														formik.values.dados_atualizar.entrega_segunda ===
														'S'
													}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														const { checked } = e.target;

														formik.setFieldValue(
															'dados_atualizar.entrega_segunda',
															checked ? 'S' : '',
														);
													}}
												/>
											</Col>
											<Col span={4}>
												<Field
													subtitle="Terça-feira"
													name="dados_atualizar.entrega_terca"
													as={Checkbox}
													checked={
														formik.values.dados_atualizar.entrega_terca === 'S'
													}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														const { checked } = e.target;

														formik.setFieldValue(
															'dados_atualizar.entrega_terca',
															checked ? 'S' : '',
														);
													}}
												/>
											</Col>
											<Col span={4}>
												<Field
													subtitle="Quarta-feira"
													name="dados_atualizar.entrega_quarta"
													as={Checkbox}
													checked={
														formik.values.dados_atualizar.entrega_quarta === 'S'
													}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														const { checked } = e.target;

														formik.setFieldValue(
															'dados_atualizar.entrega_quarta',
															checked ? 'S' : '',
														);
													}}
												/>
											</Col>
											<Col span={4}>
												<Field
													subtitle="Quinta-feira"
													name="dados_atualizar.entrega_quinta"
													as={Checkbox}
													checked={
														formik.values.dados_atualizar.entrega_quinta === 'S'
													}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														const { checked } = e.target;

														formik.setFieldValue(
															'dados_atualizar.entrega_quinta',
															checked ? 'S' : '',
														);
													}}
												/>
											</Col>
											<Col span={4}>
												<Field
													subtitle="Sexta-feira"
													name="dados_atualizar.entrega_sexta"
													as={Checkbox}
													checked={
														formik.values.dados_atualizar.entrega_sexta === 'S'
													}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														const { checked } = e.target;

														formik.setFieldValue(
															'dados_atualizar.entrega_sexta',
															checked ? 'S' : '',
														);
													}}
												/>
											</Col>
											<Col span={4}>
												<Field
													subtitle="Sábado"
													name="dados_atualizar.entrega_sabado"
													as={Checkbox}
													checked={
														formik.values.dados_atualizar.entrega_sabado === 'S'
													}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														const { checked } = e.target;

														formik.setFieldValue(
															'dados_atualizar.entrega_sabado',
															checked ? 'S' : '',
														);
													}}
												/>
											</Col>
										</Row>
										<Row gutter={[0, 10]}>
											<Col span={8}>
												<Field
													title="Horário de entrega da Usina"
													name="dados_atualizar.horario_entrega_usina"
													as={InputMask}
													titleSize="xl"
													mask="99:99"
													error={hasError(
														formik.errors,
														`dados_atualizar.horario_entrega_usina`,
													)}
												/>
											</Col>
											<Col span={8} offset={1}>
												<Field
													title="Horário de entrega aos Beneficiários"
													name="dados_atualizar.horario_entrega_beneficiarios"
													as={InputMask}
													titleSize="xl"
													mask="99:99"
													error={hasError(
														formik.errors,
														`dados_atualizar.horario_entrega_beneficiarios`,
													)}
												/>
											</Col>
										</Row>
									</Section>
									<Section
										size="sm"
										titleBold={false}
										title="ENDEREÇO DE ENTREGA"
									>
										<Row gutter={[0, 10]}>
											<Col span={24}>
												<Row gutter={[0, 10]}>
													<Col span={8}>
														<Field
															title="Município"
															name="dados_visualizar.municipio_endereco_entrega"
															as={Input}
															readOnly
														/>
													</Col>
													<Col span={8}>
														<Field
															title="Distrito"
															name="dados_visualizar.distrito_endereco_entrega"
															as={Input}
															readOnly
														/>
													</Col>
													<Col span={8}>
														<Field
															title="Zona"
															name="dados_visualizar.zona_endereco_entrega"
															as={Input}
															readOnly
														/>
													</Col>
												</Row>
											</Col>
											<Col span={24}>
												<Field
													title="E-mail"
													name="dados_atualizar.email_2_endereco_entrega"
													as={Input}
													maxLength="60"
												/>
											</Col>
										</Row>
										{atualizacaoCadastralData.dados_atualizar.enderecos_entrega.map(
											(item: IEndrerecosEntrega, index: number) => (
												<Section
													key={item.codigo_endereco}
													size="sm"
													titleBold={false}
													title="EDEREÇO DE ENTREGA 1"
												>
													<Row gutter={[0, 10]}>
														<Col span={24}>
															<CEP
																name={`dados_atualizar.enderecos_entrega[${index}].cep`}
																defaultValue={
																	atualizacaoCadastralData.dados_atualizar
																		.enderecos_entrega[index]?.cep
																}
																required
																formik={formik}
																result={(res: IBuscarCepResultado) =>
																	handleCepSelecionado(res, formik, index)
																}
															/>
														</Col>
													</Row>
													<Row gutter={[0, 10]}>
														<Col span={18}>
															<Field
																title="Endereço"
																name={`dados_atualizar.enderecos_entrega[${index}].endereco`}
																as={Input}
																required
																error={hasError(
																	formik.errors,
																	`dados_atualizar.enderecos_entrega[${index}].endereco`,
																)}
																maxLength="100"
															/>
														</Col>
														<Col span={6}>
															<Field
																title="Número"
																name={`dados_atualizar.enderecos_entrega[${index}].numero`}
																as={Input}
																required
																error={hasError(
																	formik.errors,
																	`dados_atualizar.enderecos_entrega[${index}].numero`,
																)}
																maxLength="10"
															/>
														</Col>
													</Row>
													<Row gutter={[0, 10]}>
														<Col span={8}>
															<Field
																title="Complemento"
																name={`dados_atualizar.enderecos_entrega[${index}].complemento`}
																as={Input}
																maxLength="20"
															/>
														</Col>
														<Col span={10}>
															<Field
																title="Bairro"
																name={`dados_atualizar.enderecos_entrega[${index}].bairro`}
																as={Input}
																required
																error={hasError(
																	formik.errors,
																	`dados_atualizar.enderecos_entrega[${index}].bairro`,
																)}
																maxLength="60"
															/>
														</Col>
													</Row>
													<Row gutter={[0, 10]}>
														<Col span={18}>
															<Field
																title="Ponto de Referência"
																name={`dados_atualizar.enderecos_entrega[${index}].ponto_referencial`}
																as={Input}
																maxLength="60"
															/>
														</Col>
														<Col span={6}>
															<Field
																title="Telefone"
																name={`dados_atualizar.enderecos_entrega[${index}].telefone`}
																as={Input}
																required
																error={hasError(
																	formik.errors,
																	`dados_atualizar.enderecos_entrega[${index}].telefone`,
																)}
															/>
														</Col>
													</Row>
												</Section>
											),
										)}
									</Section>
									<Row gutter={[0, 10]} justify="space-between" align="bottom">
										<Col>
											<ButtonVoltar />
										</Col>
										<Col>
											<ButtonImage src="prosseguir" type="submit" />
										</Col>
									</Row>
								</Form>
							);
						}}
					</Formik>
				</Col>
			</Row>
		</>
	);
};

export default AtualizacaoCadastral;
