import { ReducerAction } from 'store/modules/types';

import { ListaCepRequest, Types } from './types';

export function listaCepRequest(data: ListaCepRequest): ReducerAction {
	return {
		type: Types.LISTA_CEP,
		payload: data,
	};
}
export function listaCepSuccess(payload: []): ReducerAction {
	return {
		type: Types.LISTA_CEP_SUCCESS,
		payload,
	};
}
export function listaCepFailure(payload: []): ReducerAction {
	return {
		type: Types.LISTA_CEP_FAILURE,
		payload,
	};
}

export function listaCepClear(): { type: string } {
	return {
		type: Types.LISTA_CEP_CLEAR,
	};
}
