import React from 'react';

import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { formatCurrency } from 'utils/numberFunctions';

const AcordoNormal: React.FC = () => {
	const { data } = useSelector(
		(state: ApplicationState) => state.api.cdhu.simularEfetuarAcordo,
	);
	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>

			<Row gutter={[0, 15]}>
				<Col>
					<strong>6. Condições gerais:</strong>
				</Col>
			</Row>

			<Row gutter={[0, 15]}>
				<Col>
					<p>
						<span>
							<strong>6.1.</strong>
						</span>
						<span style={{ marginLeft: '8px' }}>
							Na condição de promissário comprador de unidade habitacional da
							CDHU, confesso-me devedor do número de prestações que constam no
							campo 4.2, relativas ao período indicado no campo 4.1, e que
							perfazem o valor indicado no campo 4, as quais me obrigo a pagar
							na forma especificada nos campos 5, 5.1, 5.2 e 5.3.
						</span>
					</p>
				</Col>
			</Row>

			<Row gutter={[0, 15]}>
				<Col>
					<p>
						<span>
							<strong>6.2.</strong>
						</span>
						<span style={{ marginLeft: '8px' }}>Declaro, também, que:</span>
					</p>
				</Col>
			</Row>

			<Row gutter={[0, 15]}>
				<Col>
					<p>
						<span>
							<strong>6.2.1.</strong>
						</span>
						<span style={{ marginLeft: '8px' }}>
							É de meu conhecimento que o valor da parcela especificado no item
							5 e o débito de acordo no item 4 serão objeto de atualização
							monetária, com a utilização do índice constante do contrato de
							financiamento.
						</span>
					</p>
				</Col>
			</Row>

			<Row gutter={[0, 15]}>
				<Col>
					<p>
						<span>
							<strong>6.2.2.</strong>
						</span>
						<span style={{ marginLeft: '8px' }}>
							O presente termo não tem caráter de novação de dívida, e seu
							inadimplemento consiste na falta de pagamento de uma das parcelas
							acimas elencadas, configurará, de pleno direito, infração
							contratual ensejadora de rescisão do compormisso de venda e compra
							celebrado com a CDHU.
						</span>
					</p>
				</Col>
			</Row>

			<Row gutter={[0, 15]}>
				<Col>
					{/* {Number(data?.valorDebitoAcordoAnt.replace(',', '.')) > 0 && ( */}
					<p>
						<span>
							<strong>6.2.3</strong>
						</span>
						<span style={{ marginLeft: '8px' }}>
							Incluso no cálculo o Saldo de Acordo anterior no valor de{' '}
							{formatCurrency(data?.valorDebitoAcordoAnt || '')}.
						</span>
					</p>
					{/*  )} */}
				</Col>
			</Row>
		</>
	);
};

export default AcordoNormal;
