import { RequestEmissaoAtestadoAntecedentes } from 'store/modules/api/aac/emissaoAtestadoAntecedentes/types';
import { validacaoData } from 'utils/genericFunctions';
import * as Yup from 'yup';

export interface FormEmissaoNumeral extends RequestEmissaoAtestadoAntecedentes {
	requerente: boolean;
	nomeSocial?: string;
}
export interface PesquisaCITA {
	dataExpedicaoRg: string;
	dataNascimento: string;
	docApresentado: string;
	docProcurador: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	nomeProcurador: string;
	requerente: boolean;
	rg: string;
	sexo: string;
	nomeSocial?: string;
}

export const schema = Yup.object<PesquisaCITA>().shape({
	dataExpedicaoRg: Yup.string()
		.required(`Campo Obrigatório: Data de Expedição.`)
		.test(
			'test-name',
			value => `Data Expedição: '${value.value}' não é uma data válida.`,
			(value: any) => (value === '' ? true : validacaoData(value)),
		),
	nomeProcurador: Yup.string().when(['requerente'], {
		is: requerente => requerente === true,
		then: Yup.string().required(
			`Por favor informe o Nome do(a) Procurador(a).`,
		),
	}),
	docProcurador: Yup.string().when(['requerente'], {
		is: requerente => requerente === true,
		then: Yup.string().required(`Por favor informe o RG do(a) Procurador(a).`),
	}),
});
