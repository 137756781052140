import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { IImpressaoLicenciamentoVeiculos } from 'pages/DetranCrv/Processos/ImpressaoLicenciamentoVeiculos/form';
import { Types } from './types';
import {
	ImpressaoAlteracaoEnderecoSuccess,
	ImpressaoAlteracaoEnderecoFailure,
} from './actions';

function* ImpressaoAlteracaoEnderecoRequest(request: ReducerAction) {
	const { payload }: { payload: IImpressaoLicenciamentoVeiculos } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`processos/${CHANNEL}/impressao/alteracoes-enderecos`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(ImpressaoAlteracaoEnderecoSuccess(response));
	} else if (response.error) {
		yield put(ImpressaoAlteracaoEnderecoFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.IMPRESSAO_ALTERACAO_ENDERECO_REQUEST,
		ImpressaoAlteracaoEnderecoRequest,
	),
]);
