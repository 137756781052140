import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 1006px;
	min-height: 82vh;
	padding: 0 15px 15px 15px;

	/* Small devices (landscape phones, 576px and up) */
	@media (max-width: 575.98px) {
		max-width: 100%;
	}

	/* Medium devices (tablets, 768px and up) */
	@media (max-width: 767.98px) {
		max-width: 100%;
	}

	/* Large devices (desktops, 992px and up) */
	@media (max-width: 991.98px) {
	}

	/* Extra large devices (large desktops, 1200px and up) */
	@media (max-width: 1199.98px) {
	}
`;
