import { IExcluirFichaCadastral } from 'pages/DetranCrv/Processos/ExcluirFichaCadastral/form';
import { Types } from './types';

export function excluirFichaCadastralRequest(payload: IExcluirFichaCadastral) {
	return {
		type: Types.EXCLUIR_FICHA_CADASTRAL_REQUEST,
		payload,
	};
}
export function excluirFichaCadastralSuccess(payload: any) {
	return {
		type: Types.EXCLUIR_FICHA_CADASTRAL_SUCCESS,
		payload,
	};
}
export function excluirFichaCadastralFailure(payload: []): any {
	return {
		type: Types.EXCLUIR_FICHA_CADASTRAL_FAILURE,
		payload,
	};
}
export function excluirFichaCadastralClear(): any {
	return {
		type: Types.EXCLUIR_FICHA_CADASTRAL_CLEAR,
	};
}
