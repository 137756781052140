import { combineReducers } from 'redux';

import atualizarOrgao from './atualizarOrgao/reducer';
import cadastrarOrgao from './cadastrarOrgao/reducer';
import consultarOrgao from './consultarOrgao/reducer';
import excluirOrgao from './excluirOrgao/reducer';

export default combineReducers({
	atualizarOrgao,
	cadastrarOrgao,
	consultarOrgao,
	excluirOrgao,
});
