import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarFuncionalidadeRequest } from './types';

export function cadastrarFuncionalidadeRequest(
	data: CadastrarFuncionalidadeRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_FUNCIONALIDADE,
		payload: data,
	};
}
export function cadastrarFuncionalidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_FUNCIONALIDADE_SUCCESS,
		payload,
	};
}
export function cadastrarFuncionalidadeFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_FUNCIONALIDADE_FAILURE,
		payload: null,
	};
}
export function cadastrarFuncionalidadeClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_FUNCIONALIDADE_CLEAR,
		payload: null,
	};
}
