import React, { useEffect } from 'react';

import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch } from 'react-redux';
import { consultaDocumentoInfracaoClear } from 'store/modules/api/prefeituras/jalesTransito/documentoInfracao/actions';
import { consultaInfracoesClear } from 'store/modules/api/prefeituras/jalesTransito/infracoes/actions';

// COMPONENTS
import ServiceList from 'components/Common/ServiceList';
import ButtonVoltar from 'components/Common/ButtonVoltar';

const Prefeitura: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const services = [
		{
			name: 'Consultar Auto de Infração',
			path: 'jales/transporte',
		},
	];

	useEffect(() => {
		dispatch(consultaDocumentoInfracaoClear());
		dispatch(consultaInfracoesClear());
	}, [dispatch]);

	return (
		<Row>
			<Col span={24}>
				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h1>Escolha o serviço:</h1>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<h1>Jales</h1>
						<h2>Trânsito</h2>
					</Col>
				</Row>

				<Row>
					<Col span={24}>{services && <ServiceList data={services} />}</Col>
				</Row>

				<Row>
					<Col span={24}>
						<ButtonVoltar
							navigate={false}
							onClick={() => {
								history.push('/menu-orgaos');
							}}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default Prefeitura;
