import { ReducerAction } from 'store/modules/types';

import { Types, IPEPMRequest } from './types';

export function consultarPEPMRequest(data: IPEPMRequest): ReducerAction {
	return {
		type: Types.PEPM_REQUEST,
		payload: data,
	};
}

export function consultarPEPMSuccess(payload: object) {
	return {
		type: Types.PEPM_SUCCESS,
		payload,
	};
}

export function consultarPEPMFailure(payload: object): ReducerAction {
	return {
		type: Types.PEPM_FAILURE,
		payload,
	};
}

export function consultarPEPMClear(): any {
	return {
		type: Types.PEPM_CLEAR,
	};
}
