import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, consultarCadastroBinRenavam } from './types';

export const INITIAL_STATE: consultarCadastroBinRenavam = {
	status: 0,
	data: {
		dadosBinRenavam: {
			anoFabricacao: 0,
			anoModelo: 0,
			capacidadePassageiro: 0,
			capacidadeCarga: 0,
			chassi: '',
			cilindrada: 0,
			cmt: 0,
			eixo: 0,
			numeroMotor: '',
			pbt: 0,
			placa: '',
			potencia: 0,
			procedencia: '',
			renavam: 0,
			situacaoVeiculo: '',
			tipoRemarcacao: '',
			uf: '',
			cpfCnpjFaturado: 0,
			cpfCnpjProprietario: 0,
			carroceria: {
				longId: 0,
				descricao: '',
			},
			combustivel: {
				longId: 0,
				descricao: '',
			},
			cor: {
				longId: 0,
				descricao: '',
			},
			especie: {
				longId: 0,
				descricao: '',
			},
			marca: {
				longId: 0,
				descricao: '',
			},
			municipio: {
				codigo: 0,
				nome: '',
			},
			tipo: {
				longId: 0,
				descricao: '',
			},
			restricoes: [],
			gravame: {
				tipoTransacao: '',
				restricao: '',
				tipoRestricao: '',
				agenteFinanceiro: '',
				nomeFinanciado: '',
				cpfCnpjFinanciado: 0,
				codigoFinanceira: 0,
				arrendatarioFinanceira: '',
				numeroContrato: '',
				dataInclusao: '',
				dataVigencia: '',
			},
		},
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultarCadastroBinRenavam {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_CADASTRO_BIN_RENAVAM_REQUEST: {
				break;
			}

			case Types.CONSULTAR_CADASTRO_BIN_RENAVAM_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_CADASTRO_BIN_RENAVAM_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_CADASTRO_BIN_RENAVAM_CLEAR: {
				draft.status = 0;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
