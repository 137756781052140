export default {
	grauParentesco: [
		{
			value: 'Outros',
			label: 'Outros',
		},
		{
			value: 'Avô',
			label: 'Avô',
		},
		{
			value: 'Avó',
			label: 'Avó',
		},
		{
			value: 'Esposo (a)',
			label: 'Esposo (a)',
		},
		{
			value: 'Irmão (a)',
			label: 'Irmão (a)',
		},
		{
			value: 'Filho (a)',
			label: 'Filho (a)',
		},
		{
			value: 'Mãe',
			label: 'Mãe',
		},
		{
			value: 'Pai',
			label: 'Pai',
		},
		{
			value: 'Responsável Legal',
			label: 'Responsável Legal',
		},
	],
};
