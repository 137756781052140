import React, { useCallback, useEffect } from 'react';

import { Row, Col } from 'antd';
import { Form, Formik, FormikProps } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_AAC_RESULTADO_NUMERAL } from 'pages/iirgd/Aac/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { limpaAtestadoAntecedentes } from 'store/modules/api/aac/emissaoAtestadoAntecedentes/actions';
import { pesquisaCITAAACRequest } from 'store/modules/api/aac/pesquisaCITA/actions';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import RG from 'components/Common/Form/Fields/RG';
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import Divider from 'components/Common/Divider';

// utils
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { limparMascara } from 'utils/genericFunctions';
import NavMenu from '../../components/NavMenu';
import { initialValues, schema } from './form';

// STYLED
import { ContentButton } from './styled';

const Numeral: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { pesquisaCitaAAC } = useSelector(
		(state: ApplicationState) => state.api.aac,
	);
	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	useEffect(() => {
		dispatch(limpaAtestadoAntecedentes());
		if (pesquisaCitaAAC.status === 200) {
			history.push(ROUTE_AAC_RESULTADO_NUMERAL);
		}
	}, [pesquisaCitaAAC, history, dispatch]);

	const handleResetForm = useCallback(
		async (setFieldValue, handleReset) => {
			await setFieldValue('RGempty', true);
			handleReset();
			dispatch(clearNotifications());
		},
		[dispatch],
	);

	const handleChange = (rg: { digitoRg: string; numeroRg: string }) => {
		if (errors.length && rg.numeroRg.length < 8 && !rg.digitoRg.length) {
			dispatch(clearNotifications());
		}
		if (
			pesquisaCitaAAC.rgPesquisado &&
			limparMascara(pesquisaCitaAAC.rgPesquisado) !==
				`${rg.numeroRg}${rg.digitoRg.length}`
		) {
			dispatch(clearNotifications());
		}
	};

	return (
		<>
			<Row>
				<Col span={24}>
					<NavMenu />

					<Divider mtSize={15} mbSize={15} />
				</Col>
			</Row>
			<Section title="Pesquisar Numeral">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						values.numeroRg = values.numeroRg.replace(/[_.-]+/g, '');

						const payload = {
							numeroRg: `${values.numeroRg}-${values.digitoRg}`,
							usuario: login.mainframe.idUsuario,
							senha: login.mainframe.senhaMainFrame,
						};

						dispatch(pesquisaCITAAACRequest(payload));
					}}
				>
					{(formik: FormikProps<any>) => {
						const { handleReset, setFieldValue } = formik;
						return (
							<Form>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<RG
											title="RG de SP	"
											subtitle="(Apenas números e sem dígito. Ex: 99999999)"
											numero="numeroRg"
											digito="digitoRg"
											formik={formik}
											reset={!!formik.values.RGempty}
											result={rg => {
												setFieldValue('numeroRg', rg.numeroRg);
												setFieldValue('digitoRg', rg.digitoRg);
												handleChange(rg);
											}}
										/>
										<ContentButton justify="center">
											<ButtonImage
												onClick={() =>
													handleResetForm(setFieldValue, handleReset)
												}
												type="button"
												src="limpar"
											/>
											<ButtonImage type="submit" src="pesquisar" />
										</ContentButton>
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route="/iirgd" />
		</>
	);
};

export default Numeral;
