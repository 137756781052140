export enum Types {
	CONSULTA_E_PORTAL_REQUEST = '@cnh/CONSULTA_E_PORTAL_REQUEST',
	CONSULTA_E_PORTAL_SUCCESS = '@cnh/CONSULTA_E_PORTAL_SUCCESS',
	CONSULTA_E_PORTAL_FAILURE = '@cnh/CONSULTA_E_PORTAL_FAILURE',
	CONSULTA_E_PORTAL_CLEAR = '@cnh/CONSULTA_E_PORTAL_CLEAR',
}

export interface consultaEPortal {
	status: number;
	data: null | ConsultaEPortalData;
}

export interface ConsultaEPortalData {
	mensagemEmissaoPid: string;
	numeroRegistro: string;
	numeroPgu: string;
	renach: string;
	local: string;
	categoriaPretendida: string;
	cfcA: string;
	cfcB: string;
	usoForm: string;
	situacaoAtual: string;
	espelhoCnh: string;
	espelhoPid: string;
	dataPrimeiraHabilitacao: string; // 2022-04-01
	dataUltEmissaoCnh: string;
	nome: string;
	dataValidadeCnh: string;
	nomePai: string;
	dataNascimento: string;
	nomeMae: string;
	sexo: string;
	nacionalidade: string;
	tipoDocumento: string;
	numeroRg: string;
	orgaoEmissor: string;
	localEmissao: string;
	cpf: string;
	rne1: string;
	rne2: string;
	rne3: string;
	naturalidade: string;
	identificacaoHabilitacao: string;
	pais: string;
	dataValidade: string;
	mensagem: string;
	dataExameTeorico: string;
	codigoExameTeorico: string;
	codigoEntidade: string;
	descricaoEntidade: string;
	dataExamePratico2Rodas: string;
	codigoExamePratico2Rodas: string;
	dataExamePratico4Rodas: string;
	codigoExamePratico4Rodas: string;
	flagAtividadeRemunerada: string;
	flagDeficienteFisico: string;
	endereco: {
		codigo: string;
		logradouro: string;
		numero: string;
		complemento: string;
		cep: string;
		bairro: string;
		descricaoMunicipio: string;
		codigoMunicipio: string;
	};
	dadosExameMedico: {
		dataExameMedico: string;
		exameMedico: string;
		categoriaPermitida: string;
		restricaoMedica: string;
		aptidoInaptoTemporario: string;
		resultado: string;
		cred1: string;
		cred2: string;
		crm: string;
		cpf: string;
		regiao: string;
		aptidaoMedica: string;
		aptoAte: string;
		exame: string;
	};
	dadosExamePsicotecnico: {
		dataExamePsicotecnico: string;
		exame1: string;
		exame2: string;
		laudo1: string;
		laudo2: string;
		resultado1: string;
		resultado2: string;
		examePsicotecnico1: string;
		examePsicotecnico2: string;
		aptidoInaptoTemporario: string;
		aptidaoPsicotecnica: string;
		cred1: string;
		cred2: string;
		crp: string;
		regiao: string;
		aptoAte: string;
	};
	ocorrencias: [
		{
			sequencia: string;
			tipo: string;
			informacao: string;
		},
	];
}

export interface RequestConsultaEPortal {
	cpf?: string;
	numeroRg?: string;
	numeroRegistro?: string;
	numeroPgu?: string;
	nome?: string;
	dataNascimento?: string;
	usuario?: string;
	senha?: string;
}
