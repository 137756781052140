import { ReducerAction } from 'store/modules/types';

import { Types, IRequestDatasExamePsicologico } from './types';

export function datasExamePsicologicoRequest(
	data: IRequestDatasExamePsicologico,
): ReducerAction {
	return {
		type: Types.DATAS_EXAME_PSICOLOGICO_REQUEST,
		payload: data,
	};
}
export function datasExamePsicologicoSuccess(payload: object): ReducerAction {
	return {
		type: Types.DATAS_EXAME_PSICOLOGICO_SUCCESS,
		payload,
	};
}
export function datasExamePsicologicoEnumRequest(
	data: IRequestDatasExamePsicologico,
): ReducerAction {
	return {
		type: Types.DATAS_EXAME_PSICOLOGICO_ENUM_REQUEST,
		payload: data,
	};
}
export function datasExamePsicologicoEnumSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.DATAS_EXAME_PSICOLOGICO_ENUM_SUCCESS,
		payload,
	};
}
export function datasExamePsicologicoFailure(payload: boolean): ReducerAction {
	return {
		type: Types.DATAS_EXAME_PSICOLOGICO_FAILURE,
		payload,
	};
}
export function datasExamePsicologicoClear(): ReducerAction {
	return {
		type: Types.DATAS_EXAME_PSICOLOGICO_CLEAR,
		payload: null,
	};
}
