import { ReducerAction } from 'store/modules/types';

import { Types, ITXUTRequest } from './types';

export function consultarTXUTRequest(data: ITXUTRequest): ReducerAction {
	return {
		type: Types.TXUT_REQUEST,
		payload: data,
	};
}

export function consultarTXUTSuccess(payload: object) {
	return {
		type: Types.TXUT_SUCCESS,
		payload,
	};
}

export function consultarTXUTFailure(payload: object): ReducerAction {
	return {
		type: Types.TXUT_FAILURE,
		payload,
	};
}

export function consultarTXUTClear(): any {
	return {
		type: Types.TXUT_CLEAR,
	};
}
