import React from 'react';

// ANTD
import { Col, Row } from 'antd';

// COMPONENTS COMMON
import TypographyCommon from 'components/Common/Typography';

// COMPONENTS
import { v4 } from 'uuid';

// PATHS
import Section from 'components/Common/Section';

// REDUX
import { ConsultaExtratoResumoCondutorData } from 'store/modules/api/detranCnh/eportal/consultaExtratoResumoCondutor/types';

// UTILS
import { isFilledValue } from 'utils/stringFunctions';
import { formatDate } from 'utils/genericFunctions';

interface Props {
	consultaExtratoResumoCondutor:
		| ConsultaExtratoResumoCondutorData['pcrs']
		| null
		| undefined;
}

const Cursos: React.FC<Props> = ({ consultaExtratoResumoCondutor = null }) => {
	return (
		<>
			<Section title="Cursos (PCRS)">
				{consultaExtratoResumoCondutor &&
					consultaExtratoResumoCondutor.map(item => (
						<Row gutter={[0, 0]} align="top" key={v4()}>
							<Col span={5}>
								<TypographyCommon
									text={isFilledValue(item?.curso)}
									type="label"
									fontWeight="bold"
									uppercase
								/>
							</Col>
							<Col span={19}>
								<TypographyCommon
									text={isFilledValue(
										formatDate(item?.dataInclusao),
										'Data não informada',
									)}
									type="label"
								/>
							</Col>
						</Row>
					))}

				{!consultaExtratoResumoCondutor && (
					<Row gutter={[0, 0]} align="top">
						<Col span={24}>
							<TypographyCommon
								text="Não consta curso para este condutor"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Col>
					</Row>
				)}
			</Section>
		</>
	);
};
export default Cursos;
