import React from 'react';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import Logo from '../Common/Logo';

import FontSize from './components/FontSize';
import Contrast from './components/Contrast';
import LogoutButton from './components/LogoutButton';
import Navbar from './components/Navbar';

// STYLED
import {
	Background,
	Container,
	Content,
	OptionsMenu,
	HeaderInfo,
	LoginContainer,
	LoginInfo,
} from './styled';

const Header: React.FC = () => {
	const { login, user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	return (
		<>
			<Background className="no-print">
				<Container>
					<Content>
						<Logo src={login.user.idOrgao} />

						<HeaderInfo>
							<OptionsMenu>
								<FontSize />

								<Contrast />
							</OptionsMenu>

							{!!user.cpf && (
								<LoginContainer>
									{user.cpf && <LoginInfo>Operador: {user.cpf}</LoginInfo>}

									{login.user.idOrgao === 1 && (
										<LoginInfo>IIRGD: {login.mainframe.idUsuario}</LoginInfo>
									)}

									{login.user.idOrgao === 2 && (
										<LoginInfo>DETRAN: {login.mainframe.idUsuario}</LoginInfo>
									)}

									<LogoutButton />
								</LoginContainer>
							)}
						</HeaderInfo>
					</Content>
				</Container>
			</Background>

			<Navbar />
		</>
	);
};

export default Header;
