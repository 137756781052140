import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaNaturalidade } from './types';

// ACTIONS
import {
	consultaNaturalidadeSuccess,
	consultaNaturalidadeNoContent,
	consultaNaturalidadeFailure,
} from './actions';

function* consultaNaturalidade(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaNaturalidade } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CNH,
		`dados-dominio/${CHANNEL}/municipios?uf=${payload.uf}`,
	);

	if (response.status === 200) {
		yield put(consultaNaturalidadeSuccess(response));
	}

	if (response.status === 204) {
		yield put(consultaNaturalidadeNoContent(response));
	} else if (response.error) {
		yield put(consultaNaturalidadeFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTA_NATURALIDADE_REQUEST, consultaNaturalidade),
]);
