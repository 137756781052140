import { ReducerAction } from 'store/modules/types';

import { Types, LoginSoapRequest } from './types';

export function loginSoapRequest(data: LoginSoapRequest): ReducerAction {
	return {
		type: Types.LOGIN_SOAP_REQUEST,
		payload: data,
	};
}
export function loginSoapSuccess(payload: object): ReducerAction {
	return {
		type: Types.LOGIN_SOAP_SUCCESS,
		payload,
	};
}
export function loginSoapFailure(payload: object): ReducerAction {
	return {
		type: Types.LOGIN_SOAP_FAILURE,
		payload,
	};
}

export function loginSoapClear(payload: object): ReducerAction {
	return {
		type: Types.LOGIN_SOAP_CLEAR,
		payload,
	};
}
