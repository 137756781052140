import React, { useCallback, ChangeEvent, useState, useEffect } from 'react';
import { FormikProps, Field } from 'formik';

// SERVICES
import { getApi } from 'services/_api';

// COMPONENTS
import InputMask from 'components/Common/Form/Input/InputMask';

// UTILS
import hasError from 'utils/getFormErrors';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// FORM
import {
	IBuscarCepResultadoEndLogradouro,
	ConsultaCEP,
	initialResultValuesEndLogradouro,
} from './form';

interface Props {
	title?: string;
	name: string;
	defaultValue?: string;
	required?: boolean;
	size?: number;
	formik: FormikProps<any>;
	readOnly?: boolean;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	titleAlign?: 'start' | 'end';
	renderValue?: string | null;
	resultEndLogradouro: (data: IBuscarCepResultadoEndLogradouro) => void;
	maskChar?: string;
	disabled?: boolean;
	noAbrevLogradouro?: boolean;
	errorName?: string;
}

const CEPLogradouro: React.FC<Props> = ({
	title = 'CEP',
	name,
	defaultValue = '',
	required = false,
	size = 100,
	formik,
	resultEndLogradouro,
	readOnly = false,
	titleSize = 'md',
	titleAlign = 'end',
	renderValue = null,
	maskChar = '',
	disabled = false,
	noAbrevLogradouro = false,
	errorName = '',
}) => {
	const { errors } = formik;

	const [selectedCep, setSelectedCep] = useState<string>(defaultValue);
	const border = useState<boolean>(readOnly);

	const getLogradouroDetran = useCallback(
		(detranEndereco: any) => {
			const logradouroDetran = noAbrevLogradouro
				? (detranEndereco?.endereco && `${detranEndereco.endereco}`) || ''
				: `${
						detranEndereco?.tipoLogradouro
							? `${detranEndereco.tipoLogradouro} ${
									detranEndereco?.endereco || ''
							  }`
							: (detranEndereco?.endereco && `${detranEndereco.endereco}`) || ''
				  }`;

			return logradouroDetran;
		},
		[noAbrevLogradouro],
	);

	const handleOnChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;

			setSelectedCep(value);
		},
		[setSelectedCep],
	);

	const handleOnBlur = useCallback(async () => {
		const cep = selectedCep.replace(/[_.-]+/g, '');

		if (cep.length !== 8) {
			resultEndLogradouro({
				...initialResultValuesEndLogradouro,
			});

			return;
		}

		const resDetranEndereco: ConsultaCEP = await getApi(
			PATH_DETRAN_CNH,
			`consulta/${CHANNEL}?cep=${cep}`,
		);

		if (
			resDetranEndereco?.status === 200 ||
			resDetranEndereco?.status === 202
		) {
			const detranEndereco = resDetranEndereco.data;

			resultEndLogradouro({
				cep: detranEndereco?.cep || '',
				endereco: (detranEndereco && getLogradouroDetran(detranEndereco)) || '',
				bairro: detranEndereco?.bairro || '',
				numeroIBGE: detranEndereco?.numeroIBGE || 0,
				codigoMunicipio: detranEndereco?.codigoMunicipio || 0,
				municipio: detranEndereco?.municipio || '',
				uf: detranEndereco?.uf || '',
				codigoUnidadeTransito: detranEndereco?.codigoUnidadeTransito || 0,
				tipoLogradouro: detranEndereco?.tipoLogradouro || '',
			});
		} else if (
			resDetranEndereco?.status !== 200 &&
			resDetranEndereco?.status !== 202
		) {
			resultEndLogradouro({
				...initialResultValuesEndLogradouro,
				cep,
			});
		}
	}, [selectedCep, resultEndLogradouro, getLogradouroDetran]);

	useEffect(() => {
		setSelectedCep(defaultValue);
	}, [defaultValue]);

	return (
		<Field
			as={InputMask}
			title={title}
			titleSize={titleSize}
			titleAlign={titleAlign}
			name={name}
			value={renderValue || selectedCep}
			mask="99999-999"
			required={required}
			onChange={handleOnChange}
			onBlur={handleOnBlur}
			error={hasError(errors, errorName || name)}
			style={{ border: border ? '' : 'none' }}
			readOnly={readOnly}
			size={size}
			maskChar={maskChar}
			disabled={disabled}
		/>
	);
};

export default CEPLogradouro;
