export enum Types {
	CONSULTAR_MENUS = '@sgu/CONSULTAR_MENUS',
	CONSULTAR_MENUS_SUCCESS = '@sgu/CONSULTAR_MENUS_SUCCESS',
	CONSULTAR_MENUS_FAILURE = '@sgu/CONSULTAR_MENUS_FAILURE',
	CONSULTAR_MENUS_CLEAR = '@sgu/CONSULTAR_MENUS_CLEAR',
}

export interface ConsultarMenus {
	status: number;
	data: null | MenusData;
	totalRegistrosConsulta: number;
}

interface MenusData {
	status: string;
	statusMensagem: string;
	motivos: MotivosMenu[];
}

export interface MotivosMenu {
	nome: string;
	codigo: number;
	digito: number;
	tipo: number;
}

export interface RequestConsultarMenus {
	registroInicial?: number;
	limite?: number;
	current?: number;
	nrOrdem?: number | null;
	idMenu?: number | string;
	descricao?: number | string;
	siglaMenu?: number | string;
}
