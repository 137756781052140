import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export function consultaDocumentoInfracaoRequest(data: string): ReducerAction {
	return {
		type: Types.CONSULTA_DOCUMENTO_INFRACAO,
		payload: data,
	};
}
export function consultaDocumentoInfracaoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_DOCUMENTO_INFRACAO_SUCCESS,
		payload,
	};
}
export function consultaDocumentoInfracaoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_DOCUMENTO_INFRACAO_FAILURE,
		payload,
	};
}

export function consultaDocumentoInfracaoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_DOCUMENTO_INFRACAO_CLEAR,
		payload: null,
	};
}
