import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CapturarBiometriaState } from './types';

export const INITIAL_STATE: CapturarBiometriaState = {
	status: 0,
	data: '',
	qtdeFalha: 0,
	dataError: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): CapturarBiometriaState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CAPTURAR_BIOMETRIA_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.CAPTURAR_BIOMETRIA_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				draft.qtdeFalha = 0;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}
			case Types.CAPTURAR_BIOMETRIA_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				draft.dataError = action.payload;
				draft.qtdeFalha += 1;
				break;
			}
			case Types.CAPTURAR_BIOMETRIA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.qtdeFalha = INITIAL_STATE.qtdeFalha;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}

			case Types.CAPTURAR_BIOMETRIA_CLEAR_LEITURA: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}

			default:
		}
		return draft;
	});
}
