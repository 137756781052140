// TYPES
import { ReducerAction } from 'store/modules/types';
import { RespostaResolvidaRequest, Types } from './types';

export function respostaResolvidaRequest(
	payload: RespostaResolvidaRequest,
): ReducerAction {
	return {
		type: Types.RESPOSTA_RESOLVIDA_REQUEST,
		payload,
	};
}
export function respostaResolvidaSuccess(payload: object): ReducerAction {
	return {
		type: Types.RESPOSTA_RESOLVIDA_SUCCESS,
		payload,
	};
}
export function respostaResolvidaFailure(payload: object): ReducerAction {
	return {
		type: Types.RESPOSTA_RESOLVIDA_FAILURE,
		payload,
	};
}
export function respostaResolvidaClear(): ReducerAction {
	return {
		type: Types.RESPOSTA_RESOLVIDA_CLEAR,
		payload: null,
	};
}
