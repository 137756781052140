export enum Types {
	EMITIR_SEGUNDA_VIA_CRV_REQUEST = '@DETRAN-CRV/EMITIR_SEGUNDA_VIA_CRV_REQUEST',
	EMITIR_SEGUNDA_VIA_CRV_SUCCESS = '@DETRAN-CRV/EMITIR_SEGUNDA_VIA_CRV_SUCCESS',
	EMITIR_SEGUNDA_VIA_CRV_FAILURE = '@DETRAN-CRV/EMITIR_SEGUNDA_VIA_CRV_FAILURE',
	EMITIR_SEGUNDA_VIA_CRV_CLEAR = '@DETRAN-CRV/EMITIR_SEGUNDA_VIA_CRV_CLEAR',
}

export interface emitirSegundaViaCrv {
	status: number;
	data: IResponseSegundaViaCrv | null;
}

export interface IRequestWithHeader {
	body: IRequestSegundaViaCrv;
	headers?: any;
}

export interface IResponseSegundaViaCrv {
	chassi: string;
	placa: string;
	anoFicha: number;
	numeroficha: number;
	planilhaRenavam: string;
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	ip: string;
	cpf: string;
	identificacao: string;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}
export interface IRequestSegundaViaCrv {
	evento: IEvento;
	taxaAutenticacaoDigital: string;
	nomeCidadao: string;
	dddTelefoneCidadao: number;
	telefoneCidadao: number;
	emailCidadao: string;
	servicoDigital: boolean;
	documentos: [
		{
			conteudo: string;
			nomeDocumento: string;
			tipoArquivo: string;
		},
	];
	anoModelo: number;
	capacidadeCarga: number;
	capacidadePassageiro: number;
	chassi: string;
	chassiRemarcado: boolean;
	cilindrada: number;
	cmt: number;
	eixo: number;
	pbt: number;
	placa: string;
	potencia: number;
	procedencia: string;
	protocoloRemarcacao: string | number;
	renavam: number;
	carroceria: {
		longId: number;
		descricao: string;
	};
	categoria: {
		descricao: string;
		longId: number;
	};
	combustivel: {
		longId: number;
		descricao: string;
	};
	cor: {
		longId: number;
		descricao: string;
	};
	especie: {
		longId: number;
		descricao: string;
	};
	marca: {
		longId: number;
		descricao: string;
	};
	municipio: {
		codigo: number;
		nome: string;
	};
	tipo: {
		longId: number;
		descricao: string;
	};
	proprietario:
		| IProprietarioFisicoEmitirSegundaViaCrv
		| IProprietarioJuridicoEmitirSegundaViaCrv;
}

export interface IProprietarioFisicoEmitirSegundaViaCrv {
	nomeProprietario: string;
	cepProprietario: number;
	enderecoProprietario: string;
	numero: number;
	complemento: string;
	bairro: string;
	telefone: string | number;
	municipioProprietario: {
		codigo: number;
		nome: string;
	};
	identificacao: string;
	cpfCnpjProprietario: number;
	rgProprietario: number;
	orgaoExpeditorProprietario: string;
	ufProprietario: string;
	nomeProprietarioAnterior: string;
}

export interface IProprietarioJuridicoEmitirSegundaViaCrv {
	nomeProprietario: string;
	cepProprietario: number;
	enderecoProprietario: string;
	numero: number;
	complemento: string;
	bairro: string;
	telefone: string | number;
	municipioProprietario: {
		codigo: number;
		nome: string;
	};
	identificacao: string;
	cpfCnpjProprietario: number;
	nomeProprietarioAnterior: string;
}
