import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, infracoes } from './types';

export const INITIAL_STATE: infracoes = {
	status: 0,
	data: null,
};

export default function infracoesReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): infracoes {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_INFRACOES: {
				break;
			}
			case Types.CONSULTA_INFRACOES_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data.infracoes;
				break;
			}
			case Types.CONSULTA_INFRACOES_FAILURE: {
				draft.status = INITIAL_STATE.status;
				// draft.erroConsulta = 'Não há resultados para os parâmetros informados!';
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.CONSULTA_INFRACOES_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
	});
}
