export enum Types {
	GERAR_RENACH_SEM_PCAD_REQUEST = '@cnh/GERAR_RENACH_SEM_PCAD_REQUEST',
	GERAR_RENACH_SEM_PCAD_SUCCESS = '@cnh/GERAR_RENACH_SEM_PCAD_SUCCESS',
	GERAR_RENACH_SEM_PCAD_FAILURE = '@cnh/GERAR_RENACH_SEM_PCAD_FAILURE',
}

export interface GerarRenachSemPcad {
	status: number;
	data: null | GerarRenachSemPcadData;
	requestData: null | GerarRenachSemPcadRequest;
}

interface GerarRenachSemPcadData {
	cpf: string;
	uf: string;
	renach: string;
}

// interface Eventos {
// 	id_atendimento: string;
// 	id_cidadao: string;
// 	cpf: string;
// 	identificacao: string;
// 	ip: string;
// 	canal: {
// 		id: number;
// 		desc: string;
// 		localidade: {
// 			id: number;
// 			desc: string;
// 		};
// 	};
// }

interface GerarRenachSemPcadBase {
	cpf: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	nomeSocial?: string;
	paisAfetivos1?: string;
	paisAfetivos2?: string;
	dataNascimento: string;
	sexo: string;
	// tipoDocumentoEnum: 'CARTEIRA_IDENTIDADE';
	tipoDocumentoEnum: string;
	rg: string;
	orgaoExpDocumento: string;
	orgaoUfExpDocumento: string;
	nacionalidade: string;
	codNaturalidade: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cep: string;
	categoriaPretendidaDuasRodas: string;
	categoriaPretendidaQuatroRodas: string;
	numeroCnh: string;
	numeroPgu: string;
	cpfUsuario: string;
	codCiretranCandidatoCondutor: string;
	campoNaoUtilizado: string;
	numPoupaTempo: string;
	codUnidadePoupaTempoCiretran: string;
	rneNumero?: string;
	rneOrgao?: string;
	rneUf: string;
	rneCodPaisCnhEstrangeira: string;
	rneDataValidadeCnhEstrangeira: string;
	rneDataPrimeiraHabilitacaoCnhEstrangeira: string;
	rneIdentificacaoCnhEstrangeira: string;
	statusReusoEnum: string;
	// statusReusoEnum: SEM_REUSO;
	codEntidadeMilitar: string;
	numeroIdentMilitar: string;
	email: string;
	celular: string;
	codVerificador: string;
	flagPendenciaColetaBiometrica: boolean;
	flagSolicitaCNHDigital: boolean;
	flagAtividadeRemunerada: boolean;
	flagDeficienteFisico: boolean;
	flagPendenciaTesteAnalfabetismo: boolean;
	flagPrecisaApresentarDocumentos: boolean;
	DDD: string;
}

export interface GerarRenachSemPcadForm extends GerarRenachSemPcadBase {
	flagNomeSocial?: string;
	flagFiliacaoAfetiva?: string;
	uf?: string;
	confirmaEmail?: string;
	autorizaSMS?: string;
	autorizaEmail?: string;
	telefone?: string;
	logradouroDescMunicipio?: string;
	enderecoRecebimentoNumero?: string;
	enderecoRecebimentoBairro?: string;
	enderecoRecebimentoCep?: string;
	enderecoRecebimentoLogradouro?: string;
	enderecoRecebimentoComplemento?: string;
	enderecoRecebimentoMunicipio?: string;
	enderecoRecebimentoCodigoMunicipio?: string;
}
export interface GerarRenachSemPcadRequest extends GerarRenachSemPcadBase {}
