import React, {
	Suspense,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import PDFViewer from 'components/Common/PDFViewer';
import {
	impressaoIntencaoVendaRequest,
	impressaoIntencaoVendaClear,
} from 'store/modules/api/detranCrv/IntencaoDeVenda/Imprimir/actions';
import Placa from 'components/Common/Form/Fields/Placa';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import {
	clearNotifications,
	addNotifications,
} from 'store/modules/app/notifications/actions';
import { ImpressaoIntencaoVendaRequest } from 'store/modules/api/detranCrv/IntencaoDeVenda/Imprimir/types';
import { ROUTE_DETRAN_CRV } from 'routes/paths';
import { initialValues, schema, treatValues } from './form';

const ImprimirIntencaoVenda: React.FC = () => {
	const dispatch = useDispatch();

	const { token } = useContext<IAuthContext>(AuthContext);

	const { imprimirIntencaoVenda } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.intencaoVenda,
	);

	const [showPrint, setShowPrint] = useState<boolean>(false);

	const handleClear = useCallback(
		(formik: FormikProps<ImpressaoIntencaoVendaRequest>) => {
			dispatch(clearNotifications());
			formik.handleReset();
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: ImpressaoIntencaoVendaRequest) => {
			dispatch(clearNotifications());
			const formatValues = treatValues(values);
			dispatch(impressaoIntencaoVendaRequest(token, formatValues));
		},
		[dispatch, token],
	);

	useEffect(() => {
		if (imprimirIntencaoVenda.status === 400 && imprimirIntencaoVenda.data) {
			const notifications = Array(imprimirIntencaoVenda.data);
			const notification = notifications[0] as string;
			addNotifications({ errors: [notification] });
		}
	}, [imprimirIntencaoVenda]);

	useEffect(() => {
		if (
			imprimirIntencaoVenda.status === 200 ||
			imprimirIntencaoVenda.status === 201
		) {
			setShowPrint(true);
		}
	}, [imprimirIntencaoVenda]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Impressão ATPV-e">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={values => handleSubmit(values)}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={14}>
										<Placa
											title="Placa"
											name="placa"
											titleSize="lg"
											required
											formik={formik}
										/>
									</Col>
									<Col span={10} />
									<Col span={14}>
										<Field
											titleSize="lg"
											required
											name="renavam"
											title="Renavam"
											as={InputMask}
											mask="99999999999"
											maskChar=""
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue('renavam', v.target.value);
											}}
											error={hasError(formik.errors, 'renavam')}
										/>
									</Col>
									<Col span={8} />
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											src="limpar"
											onClick={() => handleClear(formik)}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="imprimir" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route={ROUTE_DETRAN_CRV} />

			{showPrint && (
				<PDFViewer
					title="Impressão Intenção de Venda"
					source={
						imprimirIntencaoVenda.data?.pdfAtpvBase64
							? imprimirIntencaoVenda.data.pdfAtpvBase64
							: ''
					}
					onClose={() => {
						setShowPrint(false);
						dispatch(impressaoIntencaoVendaClear());
					}}
				/>
			)}
		</Suspense>
	);
};

export default ImprimirIntencaoVenda;
