import { combineReducers } from 'redux';

import gerarRenach from './gerarRenach/reducer';
import saveGerarRenach from './saveGerarRenach/reducer';
import gerarRenachSemPcad from './gerarRenachSemPcad/reducer';
import consultarInapto from './consultarInapto/reducer';
import consultaPontos from './consultaPontos/reducer';
import solicitaCnhDigital from './solicitaCnhDigital/reducer';
import consultarCondutor from './consultarCondutor/reducer';
import consultarCadastroCidadao from './consultarCadastroCidadao/reducer';
import alterarRenachComRegistro from './alterarRenachComRegistro/reducer';
import alterarRenachSemRegistro from './alterarRenachSemRegistro/reducer';

export default combineReducers({
	gerarRenach,
	saveGerarRenach,
	gerarRenachSemPcad,
	consultarInapto,
	consultaPontos,
	solicitaCnhDigital,
	consultarCondutor,
	consultarCadastroCidadao,
	alterarRenachComRegistro,
	alterarRenachSemRegistro,
});
