// TYPES
import { IFormCadastroRg } from 'pages/iirgd/RgFicDigital/CadastroRg/form';
import { ReducerAction } from 'store/modules/types';
import { IConsultarDare } from '../../dare/consultarDare/types';
import { RequestAlterarObservacoes, Types } from './types';

export function alterarObservacoesValuesTemp(
	payload: RequestAlterarObservacoes,
): ReducerAction {
	return {
		type: Types.ALTERAR_OBSERVACOES_VALUES_TEMP,
		payload,
	};
}

export function alterarObservacoesRequest(
	formObservacoes: RequestAlterarObservacoes,
	dare: IConsultarDare | null,
	cadastroRg: IFormCadastroRg | null,
): ReducerAction {
	return {
		type: Types.ALTERAR_OBSERVACOES_REQUEST,
		payload: {
			formObservacoes,
			dare,
			cadastroRg,
		},
	};
}

export function alterarObservacoesSuccess(
	status: number,
	cadastrodeCpf?: boolean,
	mensagemCPF?: string | null,
): ReducerAction {
	return {
		type: Types.ALTERAR_OBSERVACOES_SUCCESS,
		payload: {
			status,
			cadastrodeCpf,
			mensagemCPF,
		},
	};
}

export function alterarObservacoesFailure(): ReducerAction {
	return {
		type: Types.ALTERAR_OBSERVACOES_FAILURE,
		payload: null,
	};
}

export function alterarObservacoesClear(): ReducerAction {
	return {
		type: Types.ALTERAR_OBSERVACOES_CLEAR,
		payload: null,
	};
}
