import { all } from 'redux-saga/effects';

// FICHA ALUNO
import fichaAluno from './fichaAluno/combineSaga';

// COMBOS
import combos from './combos/combineSaga';

// COMBOS
import inscricao from './inscricao/combineSaga';

import responsavel from './responsavel/combineSaga';

export default all([fichaAluno, combos, inscricao, responsavel]);
