import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultaInfracoesRequest,
	consultaInfracoesClear,
} from 'store/modules/api/prefeituras/jalesTransito/infracoes/actions';
import { consultaDocumentoInfracaoClear } from 'store/modules/api/prefeituras/jalesTransito/documentoInfracao/actions';

// FORM
import { Formik, Form, Field } from 'formik';
import { Col, Row } from 'antd';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
// import Alert from 'components/Common/Notifications/Alert';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { IFormJalesTransito, InitialValues, schema } from './form';

import { Content } from './styled';

const Pesquisar = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { infracoes } = useSelector(
		(state: ApplicationState) => state.api.prefeituras.jalesTransito,
	);

	const handleSubmit = useCallback(
		(values: IFormJalesTransito) => {
			dispatch(consultaInfracoesRequest(values));
		},
		[dispatch],
	);

	// const handleCleanNotification = useCallback(() => {
	// 	dispatch(clearNotificationJalesTransito());
	// }, [dispatch]);

	useEffect(() => {
		if (infracoes.status === 200) {
			history.push(`/prefeitura/jales/lista/infracoes`);
		}
	}, [dispatch, history, infracoes]);

	// useEffect(() => {
	// 	return function cleanup() {
	// 		handleCleanNotification();
	// 	};
	// }, [handleCleanNotification]);

	useEffect(() => {
		dispatch(consultaDocumentoInfracaoClear());
		dispatch(consultaInfracoesClear());
	}, [dispatch]);

	return (
		<>
			{/* {jalesTransito.erroConsulta !== '' && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Alert
							type="error"
							message={jalesTransito.erroConsulta}
							onClose={handleCleanNotification}
						/>
					</Col>
				</Row>
			)} */}
			<Section title="Pesquisar">
				<Row gutter={[0, 16]}>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={InitialValues}
							validate={values => getValidationsErrors(values, schema)}
							onSubmit={handleSubmit}
						>
							{formik => {
								return (
									<Form>
										<Content>
											<Row gutter={[0, 16]}>
												<Col span={8}>
													<Field
														title="Placa"
														name="placa"
														as={Input}
														required
														error={hasError(formik.errors, 'placa')}
													/>
												</Col>
												<Col span={8} offset={2}>
													<Field
														title="Renavam"
														name="renavan"
														as={Input}
														required
														error={hasError(formik.errors, 'renavan')}
													/>
												</Col>
											</Row>
											<ButtonImage type="submit" src="pesquisar" />
										</Content>
									</Form>
								);
							}}
						</Formik>
					</Col>
				</Row>
			</Section>
			<ButtonVoltar />
		</>
	);
};

export default Pesquisar;
