import styled, { css } from 'styled-components';

interface Props {
	borderBottom?: boolean;
	underline?: boolean;
	fontWeight?:
		| 100
		| 200
		| 300
		| 400
		| 500
		| 600
		| 700
		| 800
		| 900
		| 'bold'
		| 'bolder'
		| 'normal';
	required?: boolean;
	subtitle?: string;
	color?: string;
	uppercase?: boolean;
	capitalize?: boolean;
	labelSize?: number;
	textCenter?: boolean;
}

export const TypographyH5Styled = styled.h5<Props>`
	overflow-wrap: anywhere;

	${props => css`
		${props.borderBottom === true &&
		css`
			border-bottom: 0.1px #c6c6c6 solid;
		`};

		${props.required &&
		css`
			&:before {
				content: '* ';
				color: red;
			}
		`};

		${props.fontWeight &&
		css`
			font-weight: ${props.fontWeight};
		`};

		${props.color &&
		css`
			color: ${props.color};
		`};

		${props.uppercase === true &&
		props.uppercase === true &&
		css`
			text-transform: capitalize;
		`};

		${props.uppercase === true &&
		css`
			text-transform: uppercase;
		`};

		${props.capitalize === true &&
		css`
			text-transform: capitalize;
		`};
	`};
`;

export const TypographyH4Styled = styled.h4<Props>`
	overflow-wrap: anywhere;

	${props => css`
		${props.borderBottom === true &&
		css`
			border-bottom: 0.1px #c6c6c6 solid;
		`};

		${props.required &&
		css`
			&:before {
				content: '* ';
				color: red;
			}
		`};

		${props.fontWeight &&
		css`
			font-weight: ${props.fontWeight};
		`};

		${props.color &&
		css`
			color: ${props.color};
		`};

		${props.uppercase === true &&
		props.uppercase === true &&
		css`
			text-transform: capitalize;
		`};

		${props.uppercase === true &&
		css`
			text-transform: uppercase;
		`};

		${props.capitalize === true &&
		css`
			text-transform: capitalize;
		`};
	`};
`;

export const TypographyLabelStyled = styled.label<Props>`
	overflow-wrap: anywhere;

	${props => css`
		${props.borderBottom === true &&
		css`
			border-bottom: 0.1px #c6c6c6 solid;
		`};

		${props.underline === true &&
		css`
			text-decoration: underline;
		`};

		${props.fontWeight &&
		css`
			font-weight: ${props.fontWeight};
		`};

		${props.required &&
		css`
			&:before {
				content: '* ';
				color: red;
			}
		`};

		${props.subtitle &&
		css`
			&:after {
				content: '${props.subtitle}';
				font-weight: normal;
			}
		`};

		${props.color &&
		css`
			color: ${props.color};
		`};

		${props.uppercase === true &&
		props.uppercase === true &&
		css`
			text-transform: capitalize;
		`};

		${props.uppercase === true &&
		css`
			text-transform: uppercase;
		`};

		${props.capitalize === true &&
		css`
			text-transform: capitalize;
		`};

		${props.labelSize &&
		css`
			font-size: ${`${props.labelSize}px`};
		`};

		${props.textCenter &&
		css`
			text-align: center;
		`};
	`};
`;
