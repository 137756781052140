import React, { useCallback } from 'react';

import { FormikProps, Field } from 'formik';

// COMPONENTS
import InputMask from 'components/Common/Form/Input/InputMask';

// UTILS
import hasError from 'utils/getFormErrors';

interface Props {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	name: string;
	defaultValue?: string;
	required?: boolean;
	disabled?: boolean;
	size?: number;
	formik: FormikProps<any>;
	readOnly?: boolean;
	onChange?: Function;
}

const Placa: React.FC<Props> = ({
	title = 'Placa',
	titleSize = 'md',
	name,
	// defaultValue = '',
	required = false,
	disabled = false,
	size = 100,
	formik,
	readOnly = false,
	onChange = undefined,
}) => {
	const { setFieldValue } = formik;

	// const [selectedPlaca, setSelectedPlaca] = useState<string>(defaultValue);

	const handleOnChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (onChange) {
				onChange(event);
				return;
			}
			const { value } = event.target;

			setFieldValue(name, value);
		},
		[setFieldValue, name, onChange],
	);

	return (
		<Field
			as={InputMask}
			title={title}
			titleSize={titleSize}
			name={name}
			value={formik.values.placa}
			mask="aaa9*99"
			maskChar=""
			required={required}
			disabled={disabled}
			onChange={handleOnChange}
			error={hasError(formik.errors, name)}
			readOnly={readOnly}
			size={size}
		/>
	);
};

export default Placa;
