import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { IImpressaoOficioSegundaViaPlaca } from 'pages/DetranCrv/Processos/ImpressaoOficioSegundaViaPlaca/form';
import { Types } from './types';
import {
	impressaoOficioSegundaViaPlacaSuccess,
	impressaoOficioSegundaViaPlacaFailure,
} from './actions';

function* impressaoOficioSegundaViaPlacaRequest(request: ReducerAction) {
	const { payload }: { payload: IImpressaoOficioSegundaViaPlaca } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`processos/${CHANNEL}/impressao/oficios-segundas-vias-placas`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(impressaoOficioSegundaViaPlacaSuccess(response));
	} else if (response.error) {
		yield put(impressaoOficioSegundaViaPlacaFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA_REQUEST,
		impressaoOficioSegundaViaPlacaRequest,
	),
]);
