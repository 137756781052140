import React, { Suspense, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import PDFViewer from 'components/Common/PDFViewer';
import { licenciamentoVeiculoClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/LicenciamentoVeiculo/actions';
import { consultarVeiculoV110Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';

const LicenciamentoVeiculoResultadoImpressao: React.FC = () => {
	const dispatch = useDispatch();
	const data = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.solicitarDocumentos.licenciamentoVeiculo.data,
	);

	const [showPrint, setShowPrint] = useState<boolean>(false);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Resultado do pedido de Licenciamento do Veículo">
				<Row gutter={[0, 10]}>
					<Col span={2} />
					<Col span={4}>Número da Ficha:</Col>
					<Col span={18}>
						<strong>{data?.numeroficha}</strong>
					</Col>
					<Col span={2} />
					<Col span={4}>Ano da Ficha:</Col>
					<Col span={18}>
						<strong>{data?.anoFicha}</strong>
					</Col>
				</Row>
				<Row justify="end" align="top" gutter={[20, 10]}>
					<ButtonImage src="imprimir" onClick={() => setShowPrint(true)} />
				</Row>
			</Section>
			<ButtonVoltar
				route="/detran-crv/licenciamento-veiculo"
				onClick={() => {
					dispatch(licenciamentoVeiculoClear());
					dispatch(consultarVeiculoV110Clear());
				}}
			/>
			{showPrint && (
				<PDFViewer
					title="Impressão Resultado Licenciamento do Veículo"
					source={data?.fichaCadastral ? data?.fichaCadastral : ''}
					onClose={() => setShowPrint(false)}
				/>
			)}
		</Suspense>
	);
};

export default LicenciamentoVeiculoResultadoImpressao;
