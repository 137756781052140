export enum Types {
	CONSULTAR_FOTOS_RG_REQUEST = '@utils/CONSULTAR_FOTOS_RG_REQUEST',
	CONSULTAR_FOTOS_RG_SUCCESS = '@utils/CONSULTAR_FOTOS_RG_SUCCESS',
	CONSULTAR_FOTOS_RG_FAILURE = '@utils/CONSULTAR_FOTOS_RG_FAILURE',
	CONSULTAR_FOTOS_RG_CLEAR = '@utils/CONSULTAR_FOTOS_RG_CLEAR',
}

export interface ConsultarFotosRgState {
	status: number;
	data: ConsultaDigitais | null;
}

interface ConsultaDigitais {
	erros: string[];
	finalidade: string;
	rgSp: string;
	id: string;
	idColeta: string;
	idColetaFotos: string;
	idColetaAssinatura: string;
	idColetaDigitais: string;
	dadosBiometricos: Array<{
		tipo: string;
		imagem: string;
		idColeta: string;
	}>;
	dataCadastro: string;
	dataColeta: string;
	origem: string;
	validacaoPendente: true;
}
