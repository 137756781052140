import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	LISTA_MOTIVO_JUSTIFICATIVA_REQUEST = '@atendimento/LISTA_MOTIVO_JUSTIFICATIVA_REQUEST',
	LISTA_MOTIVO_JUSTIFICATIVA_SUCCESS = '@atendimento/LISTA_MOTIVO_JUSTIFICATIVA_SUCCESS',
	LISTA_MOTIVO_JUSTIFICATIVA_FAILURE = '@atendimento/LISTA_MOTIVO_JUSTIFICATIVA_FAILURE',
	LISTA_MOTIVO_JUSTIFICATIVA_CLEAR = '@atendimento/LISTA_MOTIVO_JUSTIFICATIVA_CLEAR',
}

export interface ListaMotivoJustificativa {
	status: number;
	data: OptionProps[];
}
