import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	MUNICIPIOS_REQUEST = '@procon/MUNICIPIOS_REQUEST',
	MUNICIPIOS_SUCCESS = '@procon/MUNICIPIOS_SUCCESS',
	MUNICIPIOS_FAILURE = '@procon/MUNICIPIOS_FAILURE',
	MUNICIPIOS_CLEAR = '@procon/MUNICIPIOS_CLEAR',
}

export interface Municipios {
	status: null | number;
	data: OptionProps[];
}

export interface MunicipiosRequest {
	filtro: string;
}
