import { ReducerAction } from 'store/modules/types';

import { RequestConsultarMenus, Types } from './types';

export function consultarMenusRequest(
	payload: RequestConsultarMenus,
): ReducerAction {
	return {
		type: Types.CONSULTAR_MENUS,
		payload,
	};
}
export function consultarMenusSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_MENUS_SUCCESS,
		payload,
	};
}
export function consultarMenusFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_MENUS_FAILURE,
		payload: null,
	};
}

export function consultarMenusClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_MENUS_CLEAR,
		payload: null,
	};
}
