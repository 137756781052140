import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, GetCpfPermitido } from './types';

export const INITIAL_STATE: GetCpfPermitido = {
	status: 0,
	data: null,
	request: null,
};

export default function getCpfPermitido(
	state = INITIAL_STATE,
	action: ReducerAction,
): GetCpfPermitido {
	return produce(state, draft => {
		switch (action.type) {
			case Types.GET_CPF_PERMITIDO_REQUEST: {
				draft.request = action.payload.cpf;
				break;
			}

			case Types.GET_CPF_PERMITIDO_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}

			case Types.GET_CPF_PERMITIDO_FAILURE: {
				draft.status = 404;
				draft.data = INITIAL_STATE.data;
				draft.request = INITIAL_STATE.request;
				break;
			}

			case Types.GET_CPF_PERMITIDO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.request = INITIAL_STATE.request;
				break;
			}

			default:
		}
	});
}
