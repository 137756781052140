import React, { useState, useCallback, useEffect } from 'react';

import { Row, Col } from 'antd';
import { Formik, Form, Field, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { ConsultarAcompanhamentoRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarAcompanhamento/types';
import {
	consultarAcompanhamentoRequest,
	consultarAcompanhamentoClear,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarAcompanhamento/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import Placa from 'components/Common/Form/Fields/Placa';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// Utils
import getValidationsErrors from 'utils/getValidationsErrors';
import { onlyNumbers, onlyNumbersLettersSpace } from 'utils/genericFunctions';

// ROUTES
import { ROUTE_DETRAN_CRV } from 'routes/paths';
import { ROUTE_DETRAN_REENVIAR_DOCUMENTOS_RESULTADO } from '../../../routes/paths';

// FORM
import { initialValues, schema } from './form';

const ReenviarDocumentos: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [initialForm, setInitialForm] =
		useState<ConsultarAcompanhamentoRequest>(initialValues);

	const { consultarAcompanhamento } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const handleSubmit = useCallback(
		(formValues: ConsultarAcompanhamentoRequest) => {
			const { placa, documento, renavam, processo } = formValues;
			const numberRenavam = renavam === '' ? '0' : renavam;

			dispatch(
				consultarAcompanhamentoRequest({
					placa,
					renavam: numberRenavam,
					documento,
					processo,
				}),
			);
		},
		[dispatch],
	);

	const handleClear = useCallback(
		(formik: FormikProps<ConsultarAcompanhamentoRequest>) => {
			dispatch(clearNotifications());
			formik.handleReset();
			setInitialForm(initialValues);
			dispatch(consultarAcompanhamentoClear());
		},
		[dispatch],
	);

	useEffect(() => {
		if (consultarAcompanhamento.status === 200) {
			if (consultarAcompanhamento.data) {
				history.push({
					pathname: ROUTE_DETRAN_REENVIAR_DOCUMENTOS_RESULTADO,
				});
			}
		}
	}, [consultarAcompanhamento, dispatch, history, initialForm]);

	return (
		<>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialForm}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							<Row>
								<Col span={11} offset={5}>
									<Row>
										<Col span={24}>
											<Placa
												title="Placa"
												titleSize="lg"
												name="placa"
												size={96}
												required
												formik={formik}
											/>
										</Col>
									</Row>

									<Row>
										<Col span={24}>
											<Field
												titleSize="lg"
												maxLength={14}
												name="renavam"
												title="Renavam"
												size={96}
												as={Input}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'renavam',
														onlyNumbersLettersSpace(v.target.value),
													);
												}}
												error={!!formik.errors.renavam}
											/>
										</Col>
									</Row>

									<Row>
										<Col span={24}>
											<Field
												required
												titleSize="lg"
												maxLength={14}
												name="documento"
												title="CPF/CNPJ Proprietário"
												size={96}
												as={Input}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'documento',
														onlyNumbers(v.target.value),
													);
												}}
												error={!!formik.errors.documento}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row gutter={[0, 20]}>
								<Col span={24} />
							</Row>

							<Row justify="center">
								<Col>
									<ButtonImage
										src="limpar"
										onClick={() => handleClear(formik)}
									/>
								</Col>

								<Col offset={1}>
									<ButtonImage type="submit" src="pesquisar" />
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</Section>

			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</>
	);
};

export default ReenviarDocumentos;
