import React, { useState, useCallback, TextareaHTMLAttributes } from 'react';

import { Input } from 'antd';

import { Container, Title, Content, Box, SubTitle, Hint } from '../styled';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	title?: string;
	titleAlign?: 'start' | 'end';
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | number;
	titleQuestion?: boolean;
	subtitle?: string;
	hint?: string;
	name: string;
	defaultValue?: string;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	size?: number;
	error?: boolean;
}

const { TextArea: TextAreaAntDesign } = Input;

const TextArea: React.FC<TextAreaProps> = ({
	title,
	titleAlign = 'end',
	titleSize = 'md',
	titleQuestion = false,
	subtitle,
	hint,
	name,
	defaultValue = '',
	required = false,
	disabled = false,
	readOnly = false,
	size = 100,
	error,
	...rest
}) => {
	const [isFocused, setIsFocused] = useState(false);

	const handleInputFocus = useCallback(() => {
		setIsFocused(true);
	}, []);

	const handleInputBlur = useCallback(() => {
		setIsFocused(false);
	}, []);

	return (
		<Container>
			{title && (
				<Title align={titleAlign} size={titleSize} required={required}>
					<label htmlFor={name}>{`${title}${titleQuestion ? '?' : ':'}`}</label>
				</Title>
			)}

			<Content>
				<Box
					size={size}
					isErrored={!!error}
					isFocused={isFocused}
					isDisabled={disabled}
					isReadOnly={readOnly}
				>
					<TextAreaAntDesign
						name={name}
						id={name}
						defaultValue={defaultValue}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						autoSize={{ minRows: 4, maxRows: 4 }}
						disabled={disabled}
						readOnly={readOnly}
						{...rest}
					/>

					{subtitle && (
						<SubTitle>
							<span>{subtitle}</span>
						</SubTitle>
					)}
				</Box>

				{hint && <Hint>{hint}</Hint>}
			</Content>
		</Container>
	);
};

export default TextArea;
