import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types } from './types';
import {
	transferenciaPropietarioSuccess,
	transferenciaPropietarioFailure,
} from './actions';

function* transferenciaPropietarioRequest(request: {
	type: string;
	payload: any;
}) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/escolha-placa/atribuidas?`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(transferenciaPropietarioSuccess(response));
	} else if (response.error) {
		yield put(transferenciaPropietarioFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.TRANSFERENCIA_PROPRIETARIO_REQUEST,
		transferenciaPropietarioRequest,
	),
]);
