import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarPerfilRequest } from './types';

export function atualizarPerfilRequest(
	data: AtualizarPerfilRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_PERFIL,
		payload: data,
	};
}
export function atualizarPerfilSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_PERFIL_SUCCESS,
		payload,
	};
}
export function atualizarPerfilFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_PERFIL_FAILURE,
		payload: null,
	};
}
export function atualizarPerfilClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_PERFIL_CLEAR,
		payload: null,
	};
}
