import React, { ChangeEvent, useCallback } from 'react';

import { Row, Col, Divider } from 'antd';
import * as Yup from 'yup';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import Checkbox from 'components/Common/Form/Checkbox';

// UTILS
import { onlyNumbers } from 'utils/genericFunctions';
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { Formik, Form, Field, FormikProps } from 'formik';
import GerarTokenSmsBox from 'pages/DetranCnh/components/common/GerarTokenSmsBox';
import { FormCnhDigital } from '../../StatusCondutor/form';

interface DadosPreCadastroProps {
	handleSubmit: (values: any) => void;
	schema: Yup.ObjectSchema<
		Yup.Shape<FormCnhDigital | undefined, FormCnhDigital>,
		object
	>;
	renderData: FormCnhDigital;
	clearNotification: () => void;
	setMensagensInfo: (
		values: (oldState: Array<string>) => Array<string>,
	) => void | Array<string>;
}

const DadosContato = ({
	handleSubmit,
	setMensagensInfo,
	clearNotification,
	renderData,
	schema,
}: DadosPreCadastroProps) => {
	const verifyFieldsToSendToken = useCallback(
		(formik: FormikProps<FormCnhDigital>) => {
			if (
				formik.values.autorizaEnvioEmail !== 'S' ||
				formik.values.autorizaEnvioSms !== 'S'
			) {
				setMensagensInfo((oldState: Array<string>) => {
					return [
						...oldState,
						'Obrigatório o preenchimento da autorização de envio de SMS e E-mail para validação do código.',
					];
				});
				window.scrollTo(0, 0);
				return false;
			}

			return true;
		},
		[setMensagensInfo],
	);

	const validateFieldsGenerateToken = useCallback(
		async (formik: FormikProps<FormCnhDigital>) => {
			clearNotification();

			const responseValidations = await getValidationsErrors(
				formik.values,
				schema,
			);

			if (
				Object.keys(responseValidations).length === 0 &&
				verifyFieldsToSendToken(formik)
			) {
				return true;
			}
			return false;
		},
		[clearNotification, schema, verifyFieldsToSendToken],
	);

	return (
		<>
			<Row style={{ marginTop: 15 }}>
				<Col span={24}>
					<h2>Dados de Contato</h2>
					<Divider style={{ margin: 0, backgroundColor: '#000' }} />
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<p>
						<strong>
							Atualize as informações abaixo e em seguida clique em
							&quot;Salvar&quot;:
						</strong>
					</p>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Formik
						enableReinitialize
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={renderData}
						validate={values => getValidationsErrors(values, schema)}
						onSubmit={handleSubmit}
					>
						{formik => (
							<Form autoComplete="off">
								<Row>
									<Col span={3}>
										<Field
											as={Input}
											title="DDD"
											name="dddCelular"
											maxLength="2"
											titleSize="40"
											titleAlign="start"
											value={onlyNumbers(formik.values.dddCelular)}
											error={!!formik.errors.dddCelular}
										/>
									</Col>
									<Col offset={1} span={6}>
										<Field
											as={InputMask}
											title="Celular"
											name="celular"
											mask="99999-9999"
											titleAlign="start"
											titleSize="40"
											onChange={(v: ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue(
													'celular',
													v.currentTarget.value.replace(/[-_ ]+/gi, ''),
												);
											}}
											error={!!formik.errors.celular}
										/>
									</Col>
								</Row>
								<Row>
									<Col span={10}>
										<Field
											as={Input}
											title="E-mail"
											name="email"
											maxLength="50"
											titleSize="40"
											titleAlign="start"
											onChange={(v: ChangeEvent<HTMLInputElement>) =>
												formik.setFieldValue(
													'email',
													v.target.value.toUpperCase(),
												)
											}
											error={!!formik.errors.email}
										/>
									</Col>
									<Col offset={3} span={10}>
										<Field
											as={Input}
											title="Confirmar e-mail"
											name="confirmaEmail"
											maxLength="50"
											titleSize="100"
											titleAlign="start"
											onChange={(v: ChangeEvent<HTMLInputElement>) =>
												formik.setFieldValue(
													'confirmaEmail',
													v.target.value.toUpperCase(),
												)
											}
											error={!!formik.errors.email}
										/>
									</Col>
								</Row>

								<Row>
									<Col span={10}>
										<Field
											subtitle="Autorizo o envio de SMS pelo DETRAN"
											name="autorizaEnvioSms"
											as={Checkbox}
											checked={formik.values.autorizaEnvioSms === 'S'}
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												const { checked } = e.target;

												formik.setFieldValue(
													'autorizaEnvioSms',
													checked ? 'S' : 'N',
												);
											}}
										/>
									</Col>
									<Col offset={3} span={10}>
										<Field
											subtitle="Autorizo o envio de E-mail pelo DETRAN"
											name="autorizaEnvioEmail"
											as={Checkbox}
											checked={formik.values.autorizaEnvioEmail === 'S'}
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												const { checked } = e.target;

												formik.setFieldValue(
													'autorizaEnvioEmail',
													checked ? 'S' : 'N',
												);
											}}
										/>
									</Col>
								</Row>
								<Divider
									style={{
										marginTop: 10,
										marginBottom: 10,
										backgroundColor: '#000',
									}}
								/>

								<GerarTokenSmsBox
									codigoPais={renderData.codigoPais}
									cpf={formik.values.cpf}
									ddd={formik.values.dddCelular}
									celular={formik.values.celular}
									email={formik.values.email}
									tokenValue={(code: string) =>
										formik.setFieldValue('codigoVerificador', code)
									}
									externalValidation={() => validateFieldsGenerateToken(formik)}
									setMensagensInfo={setMensagensInfo}
								/>

								<Row justify="center" style={{ marginTop: 50 }}>
									<Col>
										<ButtonImage type="submit" src="salvar" />
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</Col>
			</Row>
		</>
	);
};

export default DadosContato;
