import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_CONTABILIZA } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { IConsultaHistoricoRequest, Types } from './types';

// ACTIONS
import { consultaHistoricoSuccess, consultaHistoricoFailure } from './actions';

function* consultaHistorico(request: ReducerAction) {
	const { payload }: { payload: IConsultaHistoricoRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_CONTABILIZA,
		'contabilizacoes-hist',
		payload,
	);

	if (response.status === 200) {
		yield put(consultaHistoricoSuccess(response.data));
	} else if (response.error) {
		yield put(consultaHistoricoFailure(response.message));
	}
}

export default all([takeLatest(Types.CONSULTA_HISTORICO, consultaHistorico)]);
