import { ReducerAction } from 'store/modules/types';

import { Types, PesquisarContatoRequest } from './types';

export function pesquisarContatoRequest(
	data: PesquisarContatoRequest,
): ReducerAction {
	return {
		type: Types.PESQUISAR_CONTATO,
		payload: data,
	};
}
export function pesquisarContatoSuccess(payload: object): ReducerAction {
	return {
		type: Types.PESQUISAR_CONTATO_SUCCESS,
		payload,
	};
}
export function pesquisarContatoFailure(payload: object): ReducerAction {
	return {
		type: Types.PESQUISAR_CONTATO_FAILURE,
		payload,
	};
}
export function pesquisarContatoClear(): ReducerAction {
	return {
		type: Types.PESQUISAR_CONTATO_CLEAR,
		payload: null,
	};
}
