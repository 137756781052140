export enum Types {
	CONSULTA_MARCA_MODELO_REQUEST = '@DETRAN-CRV/CONSULTA_MARCA_MODELO_REQUEST',
	CONSULTA_MARCA_MODELO_SUCCESS = '@DETRAN-CRV/CONSULTA_MARCA_MODELO_SUCCESS',
	CONSULTA_MARCA_MODELO_FAILURE = '@DETRAN-CRV/CONSULTA_MARCA_MODELO_FAILURE',
	CONSULTA_MARCA_MODELO_CLEAR = '@DETRAN-CRV/CONSULTA_MARCA_MODELO_CLEAR',
}

export interface consultaMarcaModelo {
	status: number;
	data: MarcaModelo[] | null;
}

export interface MarcaModelo {
	longId: number;
	descricao: string;
}
