import { ReducerAction } from 'store/modules/types';

import { Types, RegiaoMicroRegiaoPorCepRequest } from './types';

export function regiaoMicroRegiaoPorCepRequest(
	data: RegiaoMicroRegiaoPorCepRequest,
): ReducerAction {
	return {
		type: Types.REGIAO_MICRO_REGIAO_POR_CEP,
		payload: data,
	};
}
export function regiaoMicroRegiaoPorCepSuccess(payload: object): ReducerAction {
	return {
		type: Types.REGIAO_MICRO_REGIAO_POR_CEP_SUCCESS,
		payload,
	};
}
export function regiaoMicroRegiaoPorCepFailure(payload: object): ReducerAction {
	return {
		type: Types.REGIAO_MICRO_REGIAO_POR_CEP_FAILURE,
		payload,
	};
}
export function regiaoMicroRegiaoPorCepClear(): ReducerAction {
	return {
		type: Types.REGIAO_MICRO_REGIAO_POR_CEP_CLEAR,
		payload: null,
	};
}
