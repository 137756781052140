// ROUTES
import { ROUTE_PROCON } from 'routes/paths';

// HOME
export const ROUTE_PROCON_RECUPERAR_ATENDIMENTO = `${ROUTE_PROCON}/recuperar-atendimento`;
export const ROUTE_PROCON_JUSTIFICATIVA_ATENDIMENTO = `${ROUTE_PROCON}/recuperar-atendimento/justificativa`;

// CADASTRAR CONSULTA
export const ROUTE_PROCON_CADASTRAR_CONSULTA = `${ROUTE_PROCON}/cadastrar-consulta`;
export const ROUTE_PROCON_CADASTRAR_CONSULTA_SUCESSO = `${ROUTE_PROCON}/cadastrar-consulta/sucesso`;

// CADASTRAR RECLAMAÇÃO
export const ROUTE_PROCON_CADASTRAR_RECLAMACAO = `${ROUTE_PROCON}/cadastrar/reclamacao`;
export const ROUTE_PROCON_CADASTRAR_RECLAMACAO_CONFIRMACAO = `${ROUTE_PROCON}/confirmacao/reclamacao`;
export const ROUTE_PROCON_CADASTRAR_RECLAMACAO_CONSULTA_CONSUMIDOR = `${ROUTE_PROCON}/consulta-consumidor`;
export const ROUTE_PROCON_CADASTRAR_RECLAMACAO_CADASTRO_CONSUMIDOR = `${ROUTE_PROCON}/cadastro-consumidor`;
export const ROUTE_PROCON_CADASTRAR_RECLAMACAO_ATUALIZACAO_CONSUMIDOR = `${ROUTE_PROCON}/atualiza-consumidor`;
export const ROUTE_PROCON_CADASTRAR_RECLAMACAO_CADASTRO_CONSULTA = `${ROUTE_PROCON}/cadastro-consulta`;

// PESQUISA ATENDIMENTO - MANIFESTAÇÃO
export const ROUTE_PROCON_PESQUISA_ATENDIMENTO = `${ROUTE_PROCON}/pesquisa-atendimento`;
export const ROUTE_PROCON_PESQUISA_ATENDIMENTO_MANIFESTACAO = `${ROUTE_PROCON}/pesquisa-atendimento/manifestacao`;
