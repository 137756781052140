import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// ACTIONS
import { addNotifications } from 'store/modules/app/notifications/actions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConfrontarDigitais } from './types';

// ACTIONS
import {
	confrontarDigitaisClear,
	confrontarDigitaisSuccess,
	confrontarDigitaisFailure,
} from './actions';

function* confrontarDigitaisRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestConfrontarDigitais } = request;

	yield put(confrontarDigitaisClear());

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}/confrontarDigitais`,
		payload,
	);

	if (response.status === 200) {
		if (response.data.resultado) {
			yield put(confrontarDigitaisSuccess());
		} else {
			yield put(confrontarDigitaisFailure());
			addNotifications({
				errors: [
					'A conferência da biometria falhou. Realize o procedimento novamente.',
				],
			});
		}
	} else {
		yield put(confrontarDigitaisFailure());
		addNotifications({
			errors: [
				'A conferência da biometria falhou. Realize o procedimento novamente.',
			],
		});
	}
}

export default all([
	takeLatest(Types.CONFRONTAR_DIGITAIS_REQUEST, confrontarDigitaisRequest),
]);
