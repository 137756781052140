import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik, Field, FormikProps } from 'formik';
import { Row, Col } from 'antd';

// COMPONENTS
import Alert from 'components/Common/Notifications/Alert';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import Checkbox from 'components/Common/Form/Checkbox';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { AlteraTransferenciaEnderecoRequest } from 'store/modules/api/detranCnh/alteracaoEndereco/alteraTransferenciaEndereco/types';
import { alteraTransferenciaEnderecoRequest } from 'store/modules/api/detranCnh/alteracaoEndereco/alteraTransferenciaEndereco/actions';
import { consultaFazendaCpfCepSefazRequest } from 'store/modules/api/detranCnh/alteracaoEndereco/consultaFazendaSefazCep/actions';
import { ConsultaFazendaSefazCepRequest } from 'store/modules/api/detranCnh/alteracaoEndereco/consultaFazendaSefazCep/types';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { EnderecosData } from 'store/modules/api/utils/enderecos/types';

// ROUTES
import {
	ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_CONFIRMACAO,
	ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_PESQUISA,
} from 'pages/DetranCnh/routes/paths';

// UTILS
import hasError from 'utils/getFormErrors';
import {
	limparMascara,
	onlyNumbers,
	onlyNumbersLettersSpace,
	removeAcentoCaracteresEspeciais,
} from 'utils/genericFunctions';
import getValidationsErrors from 'utils/getValidationsErrors';
import { treatRequestSguChangeAddress } from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';

// FORM
import {
	treatInitialValues,
	treatValues,
	initialValues,
	schema,
	initialValuesAlterarEndereco,
} from './form';

// STYLE
import 'pages/DetranCnh/styles.css';

const StatusCondutorAlterarEndereco: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const {
		consultaTransferenciaEndereco,
		alteraTransferenciaEndereco,
		consultaFazendaSefazCep,
	} = useSelector(
		(state: ApplicationState) => state.api.detranCnh.alteracaoEndereco,
	);

	const { cpf } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.user,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { posto } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.user,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { enderecos } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const [isLoading, setIsLoading] = useState(true);
	const [isValidCEP, setIsValidCEP] = useState(false);
	const [mensagensInfo, setMensagensInfo] = useState<Array<string>>([]);
	const [isActiveCheckboxComprovante, setIsActiveCheckboxComprovante] =
		useState(false);
	const [dadosEndereco, setDadosEndereco] = useState(initialValues);
	const [requestData, setRequestData] =
		useState<AlteraTransferenciaEnderecoRequest | null>(null);

	const handleSubmit = useCallback(
		(values: AlteraTransferenciaEnderecoRequest) => {
			if (isActiveCheckboxComprovante && values.apresentaDocumentos === 'N') {
				setMensagensInfo([
					'A validação do documento de endereço é obrigatória',
				]);
			} else if (
				(!isActiveCheckboxComprovante ||
					(isActiveCheckboxComprovante &&
						values.apresentaDocumentos === 'S')) &&
				consultaTransferenciaEndereco.data &&
				consultaTransferenciaEndereco.requestData &&
				cpf
			) {
				const dataConsulta = consultaTransferenciaEndereco.data;
				const requestDataConsulta = consultaTransferenciaEndereco.requestData;
				const newValues = treatValues(
					values,
					dataConsulta,
					requestDataConsulta,
					cpf,
					posto,
					user,
					dadosAtendimento?.data?.idAtendimento || '',
					dadosAtendimento?.data?.idCidadao || '',
				);
				setRequestData(newValues);
			}
		},
		[
			consultaTransferenciaEndereco.data,
			consultaTransferenciaEndereco.requestData,
			cpf,
			dadosAtendimento,
			isActiveCheckboxComprovante,
			posto,
			user,
		],
	);

	const clearFormikAddressFields = useCallback((formik: FormikProps<any>) => {
		formik.setFieldValue('complemento', '');
		formik.setFieldValue('numero', '');
		formik.setFieldValue(`cep`, '');
		formik.setFieldValue(`logradouro`, '');
		formik.setFieldValue(`bairro`, '');
		formik.setFieldValue(`codigoMunicipioIBGE`, '');
		formik.setFieldValue(`nomeMunicipio`, '');
		formik.setFieldValue(`municipio`, '');
	}, []);

	const registerSGUStatistics = useCallback(
		(
			cepProccess: string,
			message: string,
			cpfProccess: string,
			dataCorporativeCep: EnderecosData[] | null,
		) => {
			const payload = treatRequestSguChangeAddress(
				user,
				dadosAtendimento,
				cpfProccess,
				dataCorporativeCep,
				message,
				cepProccess,
			);

			dispatch(estatisticaIncluirRequest(payload));
		},
		[dadosAtendimento, dispatch, user],
	);

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado, formik: FormikProps<any>) => {
			clearFormikAddressFields(formik);
			setMensagensInfo([]);
			const cpfConsulta = consultaTransferenciaEndereco.data?.cpf || '';
			if (res.cep && res.uf) {
				if (!res.numeroIBGE) {
					const messageInvalidNumberIBGE =
						'Código IBGE retornado pelo CEP é inválido.';
					dispatch(clearNotifications());
					setMensagensInfo([messageInvalidNumberIBGE]);
					setIsValidCEP(false);
					setIsActiveCheckboxComprovante(false);
					registerSGUStatistics(
						res.cep,
						messageInvalidNumberIBGE,
						cpfConsulta,
						enderecos.data,
					);
				} else {
					setIsValidCEP(true);
					if (consultaTransferenciaEndereco.data) {
						const dataRequestSefaz: ConsultaFazendaSefazCepRequest = {
							cpf_cnpj: limparMascara(cpfConsulta),
							cep: res.cep,
						};
						dispatch(consultaFazendaCpfCepSefazRequest(dataRequestSefaz));
					}
					formik.setFieldValue(`cep`, res.cep);
					formik.setFieldValue(
						`logradouro`,
						res.enderecoAbrev.substring(0, 80),
					);
					formik.setFieldValue(
						`bairro`,
						removeAcentoCaracteresEspeciais(res.bairro.substring(0, 80)),
					);
					formik.setFieldValue(`codigoMunicipioIBGE`, res.numeroIBGE);
					formik.setFieldValue(
						`nomeMunicipio`,
						removeAcentoCaracteresEspeciais(res.municipio),
					);
					formik.setFieldValue(
						`municipio`,
						removeAcentoCaracteresEspeciais(res.municipio),
					);
				}
			}
			if (
				res.cep &&
				!res.uf &&
				enderecos.status !== 0 &&
				enderecos.status !== 100 &&
				enderecos.status !== 200
			) {
				const invalidCEPMessage =
					'Dados do CEP não foram retornados pelo serviço de CEP Corporativo';
				registerSGUStatistics(
					res.cep,
					invalidCEPMessage,
					cpfConsulta,
					enderecos.data,
				);
			}
		},
		[
			clearFormikAddressFields,
			consultaTransferenciaEndereco.data,
			dispatch,
			enderecos.data,
			enderecos.status,
			registerSGUStatistics,
		],
	);

	const handleCleanNotification = () => {
		setMensagensInfo([]);
	};

	useEffect(() => {
		if (consultaFazendaSefazCep.status === 200) {
			const responseNotification = consultaFazendaSefazCep.data?.codigo;
			if (responseNotification !== '00' && isValidCEP) {
				setIsActiveCheckboxComprovante(true);
			}
		}
	}, [consultaFazendaSefazCep, isValidCEP, mensagensInfo]);

	useEffect(() => {
		setIsLoading(false);
	}, [dispatch]);

	useEffect(() => {
		if (consultaTransferenciaEndereco.data) {
			const dataInitalValues = treatInitialValues(
				consultaTransferenciaEndereco.data,
			);
			setDadosEndereco(dataInitalValues);
		}
	}, [consultaTransferenciaEndereco.data]);

	useEffect(() => {
		if (consultaTransferenciaEndereco.status !== 200) {
			history.push(ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_PESQUISA);
		}
	}, [consultaTransferenciaEndereco.status, history]);

	useEffect(() => {
		if (requestData) {
			dispatch(alteraTransferenciaEnderecoRequest(requestData));
		}
	}, [dispatch, requestData]);

	useEffect(() => {
		if (
			alteraTransferenciaEndereco.status === 200 ||
			alteraTransferenciaEndereco.status === 201
		) {
			if (
				requestData &&
				alteraTransferenciaEndereco.data &&
				alteraTransferenciaEndereco.data.codigoInterno === '0000'
			) {
				registerSGUStatistics(
					requestData.cep,
					'Endereço alterado com sucesso',
					requestData.cpf,
					enderecos.data,
				);

				history.push(ROUTE_DETRAN_CNH_ALTERAR_ENDERECO_CONFIRMACAO);
			}
		}
	}, [
		alteraTransferenciaEndereco.data,
		alteraTransferenciaEndereco.status,
		dispatch,
		enderecos.data,
		history,
		registerSGUStatistics,
		requestData,
	]);

	return (
		<>
			{!isLoading && (
				<>
					{mensagensInfo.length > 0 &&
						mensagensInfo.map((message: string) => (
							<Alert
								key={message}
								type="error"
								message={message}
								onClose={handleCleanNotification}
							/>
						))}
					<Section title="">
						<Section title="Dados do Condutor">
							<Row>
								<Col span={8}>
									<p>
										<strong>CPF: </strong>
										<span style={{ marginLeft: '60px' }}>
											{consultaTransferenciaEndereco.data?.cpf}
										</span>
									</p>
								</Col>
							</Row>
						</Section>

						<Section title="Endereço">
							<Row>
								<Col span={10}>
									<Input
										title="CEP"
										name="cepEnderecoAntigo"
										titleSize="sm"
										titleAlign="start"
										value={dadosEndereco.cep}
										disabled
									/>
								</Col>
								<Col offset={3} span={11}>
									<Input
										title="Logradouro"
										name="logradouroEnderecoAntigo"
										titleAlign="start"
										value={dadosEndereco.logradouro}
										disabled
									/>
								</Col>
							</Row>

							<Row>
								<Col span={10}>
									<Input
										title="Número"
										name="numeroEnderecoAntigo"
										titleSize="sm"
										titleAlign="start"
										value={onlyNumbers(dadosEndereco.numero || '')}
										disabled
									/>
								</Col>
								<Col offset={1} span={1}>
									<Checkbox
										subtitle="S/N"
										name="semNumeroEnderecoAntigo"
										checked={dadosEndereco.numero === '000000'}
										disabled
										onChange={() => {}}
									/>
								</Col>
								<Col offset={1} span={11}>
									<Input
										title="Complemento"
										name="complementoEnderecoAntigo"
										titleAlign="start"
										value={onlyNumbersLettersSpace(
											dadosEndereco.complemento || '',
										)}
										disabled
									/>
								</Col>
							</Row>

							<Row>
								<Col span={10}>
									<Input
										title="Bairro"
										name="bairroEnderecoAntigo"
										titleSize="sm"
										titleAlign="start"
										value={onlyNumbersLettersSpace(dadosEndereco.bairro)}
										disabled
									/>
								</Col>
								<Col offset={3} span={11}>
									<Input
										title="Município"
										name="municipioEnderecoAntigo"
										value={dadosEndereco.nomeMunicipio}
										titleAlign="start"
										disabled
									/>
								</Col>
							</Row>
						</Section>

						<Col span={24}>
							<Formik
								enableReinitialize
								validateOnChange={false}
								validateOnBlur={false}
								initialValues={initialValuesAlterarEndereco}
								validate={values => getValidationsErrors(values, schema)}
								onSubmit={async values => handleSubmit(values)}
							>
								{formik => (
									<Form autoComplete="off">
										<Section title="Novo Endereço">
											<Row>
												<Col span={10}>
													<CEP
														retornoErro={false}
														name="cep"
														formik={formik}
														titleSize={80}
														titleAlign="start"
														defaultValue=""
														result={(res: IBuscarCepResultado) => {
															handleCepSelecionado(res, formik);
														}}
													/>
												</Col>
												<Col offset={3} span={11}>
													<Field
														as={Input}
														title="Logradouro"
														name="logradouro"
														maxLength="30"
														titleSize="120"
														titleAlign="start"
														value={formik.values.logradouro}
														error={!!formik.errors.logradouro}
													/>
												</Col>
											</Row>

											<Row>
												<Col span={10}>
													<Field
														as={Input}
														title="Número"
														name="numero"
														maxLength="6"
														titleAlign="start"
														titleSize="80"
														value={onlyNumbers(formik.values.numero)}
														disabled={formik.values.semNumero === 'S'}
														error={hasError(formik.errors, 'numeroSemNumero')}
													/>
												</Col>
												<Col offset={1} span={1}>
													<Field
														subtitle="S/N"
														name="semNumero"
														as={Checkbox}
														checked={formik.values.semNumero === 'S'}
														onChange={(e: ChangeEvent<HTMLInputElement>) => {
															const { checked } = e.target;

															formik.setFieldValue(
																'semNumero',
																checked ? 'S' : 'N',
															);
															if (checked) {
																formik.setFieldValue('numero', '');
															} else {
																formik.setFieldValue('semNumero', '');
															}
														}}
														error={hasError(formik.errors, 'numeroSemNumero')}
													/>
												</Col>
												<Col offset={1} span={11}>
													<Field
														as={Input}
														title="Complemento"
														name="complemento"
														maxLength="20"
														titleAlign="start"
														titleSize="120"
														value={onlyNumbersLettersSpace(
															formik.values.complemento || '',
														)}
														error={formik.errors.complemento}
													/>
												</Col>
											</Row>

											<Row>
												<Col span={10}>
													<Field
														as={Input}
														title="Bairro"
														name="bairro"
														maxLength="80"
														titleAlign="start"
														titleSize="80"
														value={onlyNumbersLettersSpace(
															formik.values.bairro,
														)}
														error={!!formik.errors.bairro}
													/>
												</Col>
												<Col offset={3} span={11}>
													<Field
														as={Input}
														title="Município"
														name="municipio"
														titleSize="120"
														titleAlign="start"
														disabled={formik.values.codigoMunicipioIBGE !== ''}
														error={!!formik.errors.codigoMunicipioIBGE}
													/>
												</Col>
											</Row>
										</Section>
										{isActiveCheckboxComprovante ? (
											<Row>
												<Col span={10}>
													<Field
														subtitle="Apresentado documento comprobatório de residência"
														name="apresentaDocumentos"
														as={Checkbox}
														checked={formik.values.apresentaDocumentos === 'S'}
														onChange={(e: ChangeEvent<HTMLInputElement>) => {
															const { checked } = e.target;

															formik.setFieldValue(
																'apresentaDocumentos',
																checked ? 'S' : 'N',
															);
														}}
													/>
												</Col>
											</Row>
										) : (
											<Row />
										)}
										<Row justify="center">
											<Col style={{ marginTop: '20px' }}>
												<ButtonImage type="submit" src="salvar" />
											</Col>
										</Row>
									</Form>
								)}
							</Formik>
						</Col>
					</Section>

					<Row align="middle">
						<Col>
							<ButtonVoltar />
						</Col>

						<Col style={{ marginLeft: 'auto' }}>
							<ButtonImage
								type="button"
								src="outro-servico-detran"
								onClick={() => history.push('/detran-cnh')}
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default StatusCondutorAlterarEndereco;
