import React from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';

// REDUX
import data from 'store/modules/api/detranCrv/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';

// UTILS
import hasError from 'utils/getFormErrors';
import { onlyNumbers, onlyNumbersLettersSpace } from 'utils/genericFunctions';

// FORM
import InputMask from 'components/Common/Form/Input/InputMask';
import { IEmitirAlteracaoCadastral } from '../form';

interface Props {
	formik: FormikProps<IEmitirAlteracaoCadastral>;
}

const Gravames: React.FC<Props> = ({ formik }) => {
	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section size="lg" title="Gravames" titleSize="sm">
						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									required
									as={Select}
									title="Restrição Financeira"
									titleSize="lg"
									name="restricaoFinanceiraGravame"
									options={data.restricaoFinanceira}
									onChange={(v: number) => {
										formik.setFieldValue('restricaoFinanceiraGravame', v);
									}}
									size={80}
									error={hasError(formik.errors, 'restricaoFinanceiraGravame')}
								/>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Código Financeira"
									titleSize="lg"
									name="financeiraGravame"
									maxLength={70}
									size={80}
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'financeiraGravame',
											onlyNumbers(e.target.value.toLocaleUpperCase()),
										);
									}}
									error={!!formik.errors.financeiraGravame}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Input}
									title="Arrendatário / Financeira"
									titleSize="lg"
									name="arrendatarioFinanceiraGravame"
									maxLength={70}
									size={92}
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'arrendatarioFinanceiraGravame',
											onlyNumbersLettersSpace(
												e.target.value.toLocaleUpperCase(),
											),
										);
									}}
									error={!!formik.errors.arrendatarioFinanceiraGravame}
								/>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Número Contrato"
									titleSize="lg"
									name="numeroContratoGravame"
									maxLength={70}
									size={80}
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'numeroContratoGravame',
											onlyNumbers(e.target.value.toLocaleUpperCase()),
										);
									}}
									error={!!formik.errors.numeroContratoGravame}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={InputMask}
									title="Data Vigência Contrato"
									name="dataVigenciaGravame"
									mask="99/99/9999"
									titleSize="lg"
									size={92}
									error={!!formik.errors.dataVigenciaGravame}
								/>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default Gravames;
