import React, { useCallback } from 'react';

import { Col, Row } from 'antd';
import { Field, Form, Formik } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import {
	consultaCpfRequest,
	consultaCpfClear,
} from 'store/modules/api/iirgd/pesquisaCicp/actions';
import InputMask from 'components/Common/Form/Input/InputMask';
import hasError from 'utils/getFormErrors';
import { IFormConsultarCpf, initialValues, schema, treatValues } from './form';
import ResultadoCpf from '../Resultado';

const ConsultarCpf: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const consultarCpf = useSelector(
		(state: ApplicationState) => state.api.iirgd.pesquisaCicp,
	);

	const handleValidate = useCallback((formValues: IFormConsultarCpf) => {
		return getValidationsErrors(formValues, schema);
	}, []);

	const handleSubmit = useCallback(
		(formValues: IFormConsultarCpf) => {
			const values = treatValues(formValues, login);

			dispatch(consultaCpfRequest(values));
		},
		[login, dispatch],
	);

	return (
		<Row>
			<Col span={24}>
				<Section title="Consultar Dados CIN">
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValues}
						validate={handleValidate}
						onSubmit={handleSubmit}
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[0, 10]}>
									<Col span={16}>
										<Field
											as={InputMask}
											title="CPF"
											subtitle="(Apenas números. Ex: 999.999.999-99)"
											titleSize="lg"
											name="cpf"
											mask="999.999.999-99"
											onChange={(e: { target: { value: string } }) => {
												formik.setFieldValue('cpf', e.target.value);
												dispatch(clearNotifications());
											}}
											autoFocus
											required
											error={hasError(formik.errors, 'cpf')}
										/>
									</Col>
								</Row>

								<Row gutter={[0, 20]}>
									<Col span={24} />
								</Row>

								<Row justify="center">
									<Col>
										<ButtonImage
											src="limpar"
											onClick={() => {
												formik.handleReset();
												dispatch(clearNotifications());
												dispatch(consultaCpfClear());
											}}
										/>
									</Col>

									<Col offset={1}>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
					{consultarCpf.data && <ResultadoCpf />}
				</Section>

				<ButtonVoltar
					navigate={false}
					onClick={() => {
						dispatch(consultaCpfClear());
						history.push(ROUTE_IIRGD);
					}}
				/>
			</Col>
		</Row>
	);
};

export default ConsultarCpf;
