// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CiliSimplificada, CiliSimplificadaRequest } from './types';

export function ciliSimplificadaRequest(
	payload: CiliSimplificadaRequest,
): ReducerAction {
	return {
		type: Types.CILI_SIMPLIFICADA_REQUEST,
		payload,
	};
}
export function ciliSimplificadaSuccess(
	payload: CiliSimplificada,
): ReducerAction {
	return {
		type: Types.CILI_SIMPLIFICADA_SUCCESS,
		payload,
	};
}
export function ciliSimplificadaFailure(): ReducerAction {
	return {
		type: Types.CILI_SIMPLIFICADA_FAILURE,
		payload: null,
	};
}
export function protocoloFicDigitalClear(): ReducerAction {
	return {
		type: Types.CILI_SIMPLIFICADA_CLEAR,
		payload: null,
	};
}
