import { ReducerAction } from 'store/modules/types';

import { Types, IRequestDatasExame } from './types';

export function datasExameRequest(data: IRequestDatasExame): ReducerAction {
	return {
		type: Types.DATAS_EXAME,
		payload: data,
	};
}
export function datasExameEnumRequest(data: IRequestDatasExame): ReducerAction {
	return {
		type: Types.DATAS_EXAME_ENUM,
		payload: data,
	};
}
export function datasExameEnumSuccess(data: object): ReducerAction {
	return {
		type: Types.DATAS_EXAME_ENUM_SUCCESS,
		payload: data,
	};
}
export function datasExameSuccess(payload: object): ReducerAction {
	return {
		type: Types.DATAS_EXAME_SUCCESS,
		payload,
	};
}
export function datasExameFailure(payload: boolean): ReducerAction {
	return {
		type: Types.DATAS_EXAME_FAILURE,
		payload,
	};
}
export function datasExameClear(): ReducerAction {
	return {
		type: Types.DATAS_EXAME_CLEAR,
		payload: null,
	};
}
