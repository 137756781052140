import produce from 'immer';

import { Types, gerarRenachSegundaVia } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: gerarRenachSegundaVia = {
	status: 0,
	data: null,
	requestData: null,
};

export default function gerarRenachSegundaViaReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): gerarRenachSegundaVia {
	return produce(state, draft => {
		switch (action.type) {
			case Types.GERAR_RENACH_SEGUNDA_VIA_REQUEST: {
				break;
			}
			case Types.GERAR_RENACH_SEGUNDA_VIA_SUCCESS: {
				draft.status = action.payload.response.status;
				draft.data = action.payload.response.data;
				draft.requestData = action.payload.request;
				break;
			}
			case Types.GERAR_RENACH_SEGUNDA_VIA_FAILURE: {
				break;
			}
			default:
		}
	});
}
