import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { AlterarRenachSemRegistroRequest, Types } from './types';

// ACTIONS
import {
	alterarRenachSemRegistroSuccess,
	alterarRenachSemRegistroFailure,
} from './actions';

function* alterarRenachSemRegistro(request: ReducerAction) {
	const { payload }: { payload: AlterarRenachSemRegistroRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`sockets/${CHANNEL}/altera-renach/sem-registro`,
		payload,
	);

	if (response.status === 200) {
		yield put(alterarRenachSemRegistroSuccess(response));
	} else {
		yield put(alterarRenachSemRegistroFailure(response));
	}
}

export default all([
	takeLatest(
		Types.ALTERAR_RENACH_SEM_REGISTRO_REQUEST,
		alterarRenachSemRegistro,
	),
]);
