export enum Types {
	LISTA_CEP = '@utils/LISTA_CEP',
	LISTA_CEP_SUCCESS = '@utils/LISTA_CEP_SUCCESS',
	LISTA_CEP_FAILURE = '@utils/LISTA_CEP_FAILURE',
	LISTA_CEP_CLEAR = '@utils/LISTA_CEP_CLEAR',
}

export interface ListaCep {
	status: number;
	data: null | IListaCep[];
}

interface IListaCep {
	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	codigoMunicipio: number;
	municipio: string;
}

export interface ListaCepRequest {
	logradouro: string;
	numero?: number;
	cidade: string;
	uf: string;
}
