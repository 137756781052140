// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function educacaoClear(): ReducerAction {
	return {
		type: Types.EDUCACAO_CLEAR,
		payload: null,
	};
}
