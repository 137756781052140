import {
	ROUTE_AAC_PESQUISA_ANTECEDENTES_NOMINAL,
	ROUTE_AAC_PESQUISA_ANTECEDENTES_NUMERAL,
	ROUTE_AAC_PESQUISA_ANTECEDENTES_PAINEL,
} from '../../routes/paths';

export default [
	{
		path: ROUTE_AAC_PESQUISA_ANTECEDENTES_NUMERAL,
		serviceName: 'Numeral',
	},
	{
		path: ROUTE_AAC_PESQUISA_ANTECEDENTES_NOMINAL,
		serviceName: 'Nominal',
	},
	{
		path: ROUTE_AAC_PESQUISA_ANTECEDENTES_PAINEL,
		serviceName: 'Painel de Controle',
	},
];
