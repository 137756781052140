export enum Types {
	EMITIR_TRANSFERENCIAS_MUNICIPIOS_REQUEST = '@DETRAN-CRV/EMITIR_TRANSFERENCIAS_MUNICIPIOS_REQUEST',
	EMITIR_TRANSFERENCIAS_MUNICIPIOS_SUCCESS = '@DETRAN-CRV/EMITIR_TRANSFERENCIAS_MUNICIPIOS_SUCCESS',
	EMITIR_TRANSFERENCIAS_MUNICIPIOS_FAILURE = '@DETRAN-CRV/EMITIR_TRANSFERENCIAS_MUNICIPIOS_FAILURE',
	EMITIR_TRANSFERENCIAS_MUNICIPIOS_CLEAR = '@DETRAN-CRV/EMITIR_TRANSFERENCIAS_MUNICIPIOS_CLEAR',
}

export interface emitirTransferenciaMunicipio {
	status: number;
	data: IResponseTransferenciasaMunicipio | null;
}

export interface IResponseTransferenciasaMunicipio {
	chassi: string;
	placa: string;
	anoFicha: number;
	numeroficha: number;
	planilhaRenavam: string;
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface IRequestWithHeader {
	body: IRequestTransferenciasMunicipios;
	headers?: any;
}

export interface IRequestTransferenciasMunicipios {
	evento: IEvento;
	placa: string;
	chassi: string;
	espelhoAnterior: number;
	ufEspelhoAnterior: string;
	protocoloRemarcacao: number;
	renavam: number;
	chassiRemarcado: boolean;
	anoFabricacao: number;
	anoModelo: number;
	capacidadePassageiro: number;
	capacidadeCarga: number;
	potencia: number;
	cilindrada: number;
	cmt: number;
	pbt: number;
	eixo: number;
	procedencia: string;
	carroceria: {
		longId: number;
		descricao: string;
	};
	categoria: {
		descricao: string;
		longId: number;
	};
	combustivel: {
		longId: number;
		descricao: string;
	};
	cor: {
		longId: number;
		descricao: string;
	};
	especie: {
		longId: number;
		descricao: string;
	};
	marca: {
		longId: number;
		descricao: string;
	};
	tipo: {
		longId: number;
		descricao: string;
	};
	gravame: {
		arrendatarioFinanceira: string;
		dataVigencia: string;
		financeira: number;
		numeroContrato: string;
		restricaoFinanceira: string;
	};
	municipio: {
		codigo: number;
		nome: string;
	};
	proprietario: {
		nomeProprietario: string;
		cepProprietario: number;
		bairro: string;
		enderecoProprietario: string;
		numero: number;
		complemento: string;
		telefone: number;
		identificacao: string;
		cpfCnpjProprietario: number;
		rgProprietario: number;
		orgaoExpeditorProprietario: string;
		ufProprietario: string;
		nomeProprietarioAnterior: string;
		municipioProprietario: {
			codigo: number;
			nome: string;
		};
	};
	placaAnterior: string;
	ufPlacaAnterior: string;
	municipioPlacaAnterior: {
		codigo: number;
		nome: string;
	};
	taxaAutenticacaoDigital?: string;
	documentos: any;
	emailCidadao: string;
	dddTelefoneCidadao: number;
	telefoneCidadao: number;
	servicoDigital: boolean;
	// 	taxa: {
	// 	autenticacaoDigitalDut?: string;
	// 	codBanco?: string;
	// 	dataContabil?: string;
	// 	nsuProdesp?: string;
	// };
}
