import * as Yup from 'yup';

import {
	calcularIdade,
	validacaoData,
	// , validacaoData
} from 'utils/genericFunctions';

import { IConsultaPesquisaObservacao } from 'store/modules/api/iirgd/consultaPesquisaObservacao/types';
import {
	checkCRNMDate,
	possuiDataExpedicao,
	possuiUf,
} from '../PrimeiraVia/Menor/form';

export const initialValues: IConsultaPesquisaObservacao = {
	declaracaoPobreza: false,
	solicitarBloqueio: false,
	termoRecolhimentoRg: false,
	rgRecolhidoEmissao: '',
	rgRecolhidoIdentific: '',
	rgRecolhidoPor: '',
	cr: false,
	isento2Via10952: false,
	cptsNum: '',
	ctpsSerie: '',
	ctpsSerieUf: '',
	ctpsObs: '',
	srObs: '',
	estObs: '',
	observacao: '',
	isento2Via062: false,
	termoTutela: false,
	tutelaProc: '',
	tutelaAno: '',
	tutelaData: '',
	tipoDocumentoResponsavel: '',
	ufDocumentoResponsavel: '',
	numeroDocumentoResponsavel: '',
	dataExpedicaoDocumentoResponsavel: '',
	rgForaSp: false,
	rgForaSpEmissao: '',
	rgForaSpNum: '',
	rgForaSpUf: '',
	lote: '',
	grauParentescoSp: '',
	moraCom: '',
	resideSpAnos: '',
	resideSpMeses: '',
	ocupacaoPrincipalSp: '',
	rgForaSpExpedicao: '',
	observacaoCadastrada: false,
	numeroRg: '',
	pagto2Via: false,
	isento2Via062Status: '',
	observacoes: '',
	temParentesSp: false,
	ctpsNum: '',
	cnh: '',
	tituloEleitor: '',
	reservista: '',
	classeProfissional: '',
	carteiraFuncional: '',
	serie: '',
	isencaoPrimeiraViaCin: true,
};

export const schema = Yup.object<IConsultaPesquisaObservacao>().shape({
	rgRecolhidoEmissao: Yup.string().when('termoRecolhimentoRg', {
		is: termoRecolhimentoRg => termoRecolhimentoRg,
		then: Yup.string().required('Campo Obrigatório: Expedido em.'),
	}),
	rgRecolhidoIdentific: Yup.string().when('termoRecolhimentoRg', {
		is: termoRecolhimentoRg => termoRecolhimentoRg,
		then: Yup.string().required('Campo Obrigatório: Setor Identificador.'),
	}),
	rgRecolhidoPor: Yup.string().when('termoRecolhimentoRg', {
		is: termoRecolhimentoRg => termoRecolhimentoRg,
		then: Yup.string().required('Campo Obrigatório: Recolhido por.'),
	}),
	observacao: Yup.string()
		.max(338, 'Campo Observações: Tamanho máximo de 338 caracteres.')
		.nullable(),
	ctpsFeminino: Yup.string().when(
		['isento2Via10952', 'sexo', 'dataNascimento'],
		{
			is: (isento2Via10952, sexo, dataNascimento) =>
				isento2Via10952 === true &&
				sexo === 'F' &&
				calcularIdade(dataNascimento) > 60,
			then: Yup.string().when(['ctpsObs', 'srObs', 'estObs'], {
				is: (ctpsObs, srObs, estObs) =>
					ctpsObs !== '' || srObs !== '' || estObs !== '',
				then: Yup.string().required(
					'Campo Inválido: CTPS permitido apenas para menores de 60 anos do sexo feminino.',
				),
			}),
		},
	),
	ctpsMasculino: Yup.string().when(
		['isento2Via10952', 'sexo', 'dataNascimento'],
		{
			is: (isento2Via10952, sexo, dataNascimento) =>
				isento2Via10952 === true &&
				sexo === 'M' &&
				calcularIdade(dataNascimento) > 65,
			then: Yup.string().when(['ctpsObs', 'srObs', 'estObs'], {
				is: (ctpsObs, srObs, estObs) =>
					ctpsObs !== '' || srObs !== '' || estObs !== '',
				then: Yup.string().required(
					'Campo Inválido: CTPS permitido apenas para menores de 65 anos do sexo masculino.',
				),
			}),
		},
	),
	// ctps: Yup.string().when(
	// 	['isento2Via10952', 'sexo', 'dataNascimento', 'ctpsObs', 'srObs', 'estObs'],
	// 	{
	// 		is: (isento2Via10952, sexo, dataNascimento, ctpsObs, srObs, estObs) =>
	// 			isento2Via10952 === true &&
	// 			((sexo === 'M' && calcularIdade(dataNascimento) < 65) ||
	// 				(sexo === 'F' && calcularIdade(dataNascimento) < 60)) &&
	// 			(!ctpsObs || !srObs || !estObs),
	// 		then: Yup.string().required(
	// 			'Necessário informar todos os campos referentes a CTPS.',
	// 		),
	// 	},
	// ),
	ctps: Yup.string().when(['isento2Via10952', 'sexo', 'dataNascimento'], {
		is: (isento2Via10952, sexo, dataNascimento) =>
			isento2Via10952 === true &&
			((sexo === 'M' && calcularIdade(dataNascimento) < 65) ||
				(sexo === 'F' && calcularIdade(dataNascimento) < 60)),
		then: Yup.string()
			.when(['ctpsObs', 'srObs', 'estObs'], {
				is: (ctpsObs, srObs, estObs) =>
					ctpsObs === '' && srObs === '' && estObs === '',
				then: Yup.string().required(
					'Necessário informar todos os campos referentes a CTPS.',
				),
			})
			.when(['ctpsObs', 'srObs', 'estObs'], {
				is: (ctpsObs, srObs, estObs) =>
					ctpsObs === '' && (srObs !== '' || estObs !== ''),
				then: Yup.string().required(
					'Necessário informar todos os campos referentes a CTPS.',
				),
			})
			.when(['ctpsObs', 'srObs', 'estObs'], {
				is: (ctpsObs, srObs, estObs) =>
					srObs === '' && (ctpsObs !== '' || estObs !== ''),
				then: Yup.string().required(
					'Necessário informar todos os campos referentes a CTPS.',
				),
			})
			.when(['ctpsObs', 'srObs', 'estObs'], {
				is: (ctpsObs, srObs, estObs) =>
					estObs === '' && (ctpsObs !== '' || srObs !== ''),
				then: Yup.string().required(
					'Necessário informar todos os campos referentes a CTPS.',
				),
			}),
	}),
	proc: Yup.string().when('termoTutela', {
		is: termoTutela => termoTutela === true,
		then: Yup.string()
			.when(['tutelaProc', 'tutelaAno', 'tutelaData'], {
				is: (tutelaProc, tutelaAno, tutelaData) =>
					tutelaProc === '' && tutelaAno === '' && tutelaData === '',
				then: Yup.string().required(
					'Campos Obrigatórios: Número, ano e data do processo.',
				),
			})
			.when(['tutelaProc', 'tutelaAno', 'tutelaData'], {
				is: (tutelaProc, tutelaAno, tutelaData) =>
					tutelaProc === '' && (tutelaAno !== '' || tutelaData !== ''),
				then: Yup.string().required('Campo Obrigatório: Número do Processo.'),
			})
			.when(['tutelaProc', 'tutelaAno', 'tutelaData'], {
				is: (tutelaProc, tutelaAno, tutelaData) =>
					tutelaData === '' && (tutelaProc !== '' || tutelaAno !== ''),
				then: Yup.string().required('Campo Obrigatório: Data do Processo.'),
			})
			.when(['tutelaProc', 'tutelaAno', 'tutelaData'], {
				is: (tutelaProc, tutelaAno, tutelaData) =>
					tutelaAno === '' && (tutelaProc !== '' || tutelaData !== ''),
				then: Yup.string().required('Campo Obrigatório: Ano do Processo.'),
			}),
	}),
	validaDataProcesso: Yup.string().when(['tutelaData', 'termoTutela'], {
		is: (tutelaData, termoTutela) =>
			tutelaData !== '' && !validacaoData(tutelaData) && termoTutela,
		then: Yup.string().required(`Data do Termo não é uma data válida.`),
	}),
	docResponsavel: Yup.string().when(
		['tipoDocumentoResponsavel', 'dataNascimento'],
		{
			is: (tipoDocumentoResponsavel, dataNascimento) =>
				tipoDocumentoResponsavel === '' && calcularIdade(dataNascimento) < 16,
			then: Yup.string().required('Campo Obrigatório: Tipo Doc.'),
		},
	),
	ufRespon: Yup.string().when(
		['ufDocumentoResponsavel', 'tipoDocumentoResponsavel', 'dataNascimento'],
		{
			is: (ufDocumentoResponsavel, tipoDocumentoResponsavel, dataNascimento) =>
				possuiUf(tipoDocumentoResponsavel) &&
				ufDocumentoResponsavel === '' &&
				calcularIdade(dataNascimento) < 16,
			then: Yup.string().required('Campo Obrigatório: UF'),
		},
	),
	numDocRespon: Yup.string().when(
		[
			'numeroDocumentoResponsavel',
			'dataNascimento',
			'tipoDocumentoResponsavel',
		],
		{
			is: (
				numeroDocumentoResponsavel,
				dataNascimento,
				tipoDocumentoResponsavel,
			) =>
				numeroDocumentoResponsavel === '' &&
				calcularIdade(dataNascimento) < 16 &&
				tipoDocumentoResponsavel !== '',
			then: Yup.string().required('Campo Obrigatório: Número do Documento'),
		},
	),
	serieResponsavel: Yup.string().when(
		['dataNascimento', 'tipoDocumentoResponsavel', 'serie'],
		{
			is: (dataNascimento, tipoDocumentoResponsavel, serie) =>
				serie === '' &&
				calcularIdade(dataNascimento) < 16 &&
				tipoDocumentoResponsavel === 'CTPS',
			then: Yup.string().required('Campo Obrigatório: Série.'),
		},
	),
	dataExpRespon: Yup.string()
		.when(
			[
				'dataExpedicaoDocumentoResponsavel',
				'dataNascimento',
				'tipoDocumentoResponsavel',
			],
			{
				is: (
					dataExpedicaoDocumentoResponsavel,
					dataNascimento,
					tipoDocumentoResponsavel,
				) =>
					dataExpedicaoDocumentoResponsavel === '' &&
					!validacaoData(dataExpedicaoDocumentoResponsavel) &&
					calcularIdade(dataNascimento) < 16 &&
					possuiDataExpedicao(tipoDocumentoResponsavel) &&
					tipoDocumentoResponsavel !== '',
				then: Yup.string().required('Campo Obrigatório: Data de Expedição.'),
			},
		)
		.when(['dataExpedicaoDocumentoResponsavel', 'tipoDocumentoResponsavel'], {
			is: (dataExpedicaoDocumentoResponsavel, tipoDocumentoResponsavel) =>
				tipoDocumentoResponsavel === 'CRNM' &&
				!checkCRNMDate(dataExpedicaoDocumentoResponsavel),
			then: Yup.string().required(
				'Campo Inconsistente: Data de Expedição do CRNM não pode ser anterior a 2017.',
			),
		}),
});
