import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaRestricoesRequest } from './types';

import {
	consultaRestricoesFailure,
	consultaRestricoesSuccess,
} from './actions';

function* consultaRestricoesRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultaRestricoesRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`desbloqueio/${CHANNEL}/consulta-restricoes`,
		payload,
	);
	if (response.status === 200) {
		yield put(consultaRestricoesSuccess({ response, payload }));
	} else {
		yield put(consultaRestricoesFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTA_RESTRICOES_REQUEST, consultaRestricoesRequest),
]);
