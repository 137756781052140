import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import { Row, Col, Divider, Modal } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import Select from 'components/Common/Form/Select';
import Section from 'components/Common/Section';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import ColorBox from 'components/Common/ColorBox';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import {
	treatRequestSguStatisticsGerarRenachCnhDefinitiva,
	treatRequestSguStatisticsGerarRenachIntegrado,
	treatRequestSguStatisticsGerarRenachSegundaVia,
} from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';
import { formatDate } from 'utils/genericFunctions';
import { enumFormUseUrlStatusCondutor } from 'utils/constants/types';
import { DIVISAO_EQUITATIVA_PARAMS } from 'pages/DetranCnh/utils/constants/services';

// STORE
import { ApplicationState } from 'store';
import {
	datasExamePsicologicoClear,
	datasExamePsicologicoRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/datasExamePsicologico/actions';
import {
	microRegioesClear,
	microRegioesRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/microRegioes/actions';
import { useHistory } from 'react-router-dom';
import { regioesRequest } from 'store/modules/api/detranCnh/divisaoEquitativa/regioes/actions';
import {
	regiaoMicroRegiaoPorCepClear,
	regiaoMicroRegiaoPorCepRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/regiaoMicroRegiaoPorCep/actions';
import { gerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/actions';
import { DadosExamePsicologo } from 'store/modules/api/detranCnh/sockets/saveGerarRenach/types';
import {
	estatisticaIncluirClear,
	estatisticaIncluirRequest,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';
import {
	horariosPsicologoPorDataClear,
	horariosPsicologoPorDataRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/horariosPsicologoPorData/actions';
import { cadastroEnderecosClear } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/actions';
import { capturarBiometriaClear } from 'store/modules/api/biometria/capturarBiometria/actions';
import { consultarDigitaisClear } from 'store/modules/api/utils/consultarDigitais/actions';
import { confrontarDigitaisCnhClear } from 'store/modules/api/utils/confrontarDigitaisCnh/actions';
import { consultarInaptoClear } from 'store/modules/api/detranCnh/sockets/consultarInapto/actions';
import { dadosCidadaoClear } from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/actions';
import { verificarVersaoServicoBiometriaClear } from 'store/modules/api/biometria/verificarVersaoServicoBiometria/actions';

import { IDadosCidadao } from '../DadosCidadao/form';
import {
	ICadastraExamePsicologico,
	treatValuesGerarRenach,
	schema,
} from './form';
import DadosCidadao from '../DadosCidadao';

interface IProps {
	dadosAgendamento: ICadastraExamePsicologico;
	dadosCidadao: IDadosCidadao;
	redirecionamento?: string;
	redirecionamentoAgendamentoMedico?: string;
}

const AgendamentoPsicologicoRenachIntegrado: React.FC<IProps> = ({
	dadosAgendamento,
	dadosCidadao,
	redirecionamento,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		regiaoMicroRegiaoPorCep,
		regioes,
		microRegioes,
		datasExamePsicologico,
		horariosPsicologoPorData,
	} = useSelector(
		(state: ApplicationState) => state.api.detranCnh.divisaoEquitativa,
	);

	const { gerarRenachIntegrado } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { saveGerarRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { consultaToxicologico } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cidadao,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { estatisticasIncluir } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const { consultaEPortalSegundaVia } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.segundaVia,
	);

	const { consultaCnhDefinitiva } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cnhDefinitiva,
	);

	const [dadosAgendamentoMedico, setDadosAgendamentoMedico] =
		useState<DadosExamePsicologo | null>(null);

	const [dadosAgendamentoForm, setDadosAgendamentoForm] =
		useState(dadosAgendamento);

	const handleSubmit = useCallback(
		(values: ICadastraExamePsicologico) => {
			if (datasExamePsicologico.data?.idPsicologo) {
				if (saveGerarRenach.data) {
					const {
						codigo_ciretran_exame_psicologo,
						data_exame_psicologico,
						horario_exame_psicologico,
						id_micro_regiao_psicologo,
					} = values;

					const sendValues: DadosExamePsicologo = {
						codigoCiretranExamePsicologo: codigo_ciretran_exame_psicologo
							? codigo_ciretran_exame_psicologo.toString()
							: '',
						dataExamePsicologico: data_exame_psicologico,
						horarioExamePsicologico: horario_exame_psicologico,
						idPsicologo: datasExamePsicologico.data.idPsicologo
							? datasExamePsicologico.data.idPsicologo.toString()
							: '',
						idMicroRegiaoPsicologo: id_micro_regiao_psicologo
							? id_micro_regiao_psicologo.toString()
							: '',
					};

					setDadosAgendamentoMedico(sendValues);
				}
			}
		},
		[datasExamePsicologico.data, saveGerarRenach.data],
	);

	// REGISTRA ESTATISTICA SGU ANTES DE GERAR O RENACH - CAMPOS SELECIONADOS
	useEffect(() => {
		if (
			dadosAgendamentoMedico &&
			saveGerarRenach.data &&
			estatisticasIncluir.status === 0 &&
			gerarRenachIntegrado.status === 0
		) {
			if (
				saveGerarRenach.data.candidato.tipoProcesso === '3' &&
				consultaEPortalSegundaVia.status === 200 &&
				consultaEPortalSegundaVia.data
			) {
				const payload = treatRequestSguStatisticsGerarRenachSegundaVia(
					user,
					dadosAtendimento,
					consultaEPortalSegundaVia.data,
					saveGerarRenach.data,
					'SELECTED_FIELDS',
				);
				dispatch(estatisticaIncluirRequest(payload));
			} else if (
				saveGerarRenach.data.candidato.tipoProcesso === '8' &&
				consultaCnhDefinitiva.status === 200 &&
				consultaCnhDefinitiva.data
			) {
				const payload = treatRequestSguStatisticsGerarRenachCnhDefinitiva(
					user,
					dadosAtendimento,
					consultaCnhDefinitiva.data,
					saveGerarRenach.data,
					'SELECTED_FIELDS',
				);
				dispatch(estatisticaIncluirRequest(payload));
			} else {
				const payload = treatRequestSguStatisticsGerarRenachIntegrado(
					user,
					dadosAtendimento,
					saveGerarRenach.data,
					'SELECTED_FIELDS',
				);
				dispatch(estatisticaIncluirRequest(payload));
			}
		}
	}, [
		consultaCnhDefinitiva.data,
		consultaCnhDefinitiva.status,
		consultaEPortalSegundaVia.data,
		consultaEPortalSegundaVia.status,
		dadosAgendamentoMedico,
		dadosAtendimento,
		dispatch,
		estatisticasIncluir.status,
		gerarRenachIntegrado.status,
		saveGerarRenach.data,
		user,
	]);

	// RENACH
	useEffect(() => {
		if (
			estatisticasIncluir.status !== 0 &&
			estatisticasIncluir.status !== 100 &&
			saveGerarRenach.data !== null &&
			dadosAgendamentoMedico
		) {
			const renach = treatValuesGerarRenach(
				saveGerarRenach.data,
				dadosAgendamentoMedico,
				user.cpf,
			);
			dispatch(gerarRenachIntegradoRequest(renach));
			dispatch(estatisticaIncluirClear());
		}
	}, [
		dadosAgendamentoMedico,
		dispatch,
		estatisticasIncluir.status,
		saveGerarRenach.data,
		user.cpf,
	]);

	// REGISTRA ESTATISTICA SGU APÓS GERAR O RENACH - NUMERO RENACH OU MSG ERRO.
	useEffect(() => {
		if (
			dadosAgendamentoMedico &&
			saveGerarRenach.data &&
			estatisticasIncluir.status === 0 &&
			gerarRenachIntegrado.status !== 0 &&
			gerarRenachIntegrado.status !== 100 &&
			(gerarRenachIntegrado.data || gerarRenachIntegrado.dataError)
		) {
			if (
				gerarRenachIntegrado.requestData?.candidato.tipoProcesso === '3' &&
				consultaEPortalSegundaVia.status === 200 &&
				consultaEPortalSegundaVia.data
			) {
				const payload = treatRequestSguStatisticsGerarRenachSegundaVia(
					user,
					dadosAtendimento,
					consultaEPortalSegundaVia.data,
					saveGerarRenach.data,
					'GENERATED_RENACH',
					gerarRenachIntegrado,
				);
				dispatch(estatisticaIncluirRequest(payload));
			} else if (
				gerarRenachIntegrado.requestData?.candidato.tipoProcesso === '8' &&
				consultaCnhDefinitiva.status === 200 &&
				consultaCnhDefinitiva.data
			) {
				const payload = treatRequestSguStatisticsGerarRenachCnhDefinitiva(
					user,
					dadosAtendimento,
					consultaCnhDefinitiva.data,
					saveGerarRenach.data,
					'GENERATED_RENACH',
					gerarRenachIntegrado,
				);
				dispatch(estatisticaIncluirRequest(payload));
			} else {
				const payload = treatRequestSguStatisticsGerarRenachIntegrado(
					user,
					dadosAtendimento,
					saveGerarRenach.data,
					'GENERATED_RENACH',
					gerarRenachIntegrado,
				);
				dispatch(estatisticaIncluirRequest(payload));
			}
		}
	}, [
		consultaCnhDefinitiva.data,
		consultaCnhDefinitiva.status,
		consultaEPortalSegundaVia.data,
		consultaEPortalSegundaVia.status,
		dadosAgendamentoMedico,
		dadosAtendimento,
		dispatch,
		estatisticasIncluir.status,
		gerarRenachIntegrado,
		saveGerarRenach.data,
		user,
	]);

	const handleModalSubmit = useCallback(
		(values: ICadastraExamePsicologico) => {
			if (
				consultaToxicologico.data &&
				consultaToxicologico.data.dataLimiteExameMedico
			) {
				const limitDate = new Date(
					consultaToxicologico.data.dataLimiteExameMedico,
				);
				const exameDate = new Date(values.data_exame_psicologico);
				const diffTime = limitDate.getTime() - exameDate.getTime();
				const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

				if (diffDays < 0) {
					Modal.confirm({
						okText: 'Prosseguir com a data',
						cancelText: 'Nova data',
						title: 'Confirmar Agendamento?',
						content: `Data de agendamento do exame psicológico fora da validade para utilização do exame toxicológico. Verifique outra data para que seja possível realizar o exame psicológico até ${formatDate(
							consultaToxicologico.data.dataLimiteExameMedico,
						)} ou verifique com a clínica a antecipação do exame psicológico.`,
						onOk() {
							handleSubmit(values);
						},
						onCancel() {},
					});
				} else {
					handleSubmit(values);
				}
			} else {
				handleSubmit(values);
			}
		},
		[consultaToxicologico.data, handleSubmit],
	);

	const clearFieldsFormikAndData = useCallback(
		(
			type:
				| 'regiao'
				| 'microRegiao'
				| 'data'
				| 'codigo_ciretran_exame_medico'
				| 'all',
			formik?: FormikProps<ICadastraExamePsicologico>,
		) => {
			switch (type) {
				case 'regiao':
					formik?.setFieldValue(`id_micro_regiao_psicologo`, '');
					formik?.setFieldValue(`data_exame_psicologico`, '');
					formik?.setFieldValue(`horario_exame_psicologico`, '');
					dispatch(microRegioesClear());
					dispatch(datasExamePsicologicoClear());
					dispatch(horariosPsicologoPorDataClear());
					break;

				case 'microRegiao':
					formik?.setFieldValue(`data_exame_psicologico`, '');
					formik?.setFieldValue(`horario_exame_psicologico`, '');
					dispatch(datasExamePsicologicoClear());
					dispatch(horariosPsicologoPorDataClear());
					break;

				case 'codigo_ciretran_exame_medico':
					formik?.setFieldValue(`data_exame_psicologico`, '');
					formik?.setFieldValue(`horario_exame_psicologico`, '');
					dispatch(datasExamePsicologicoClear());
					dispatch(horariosPsicologoPorDataClear());
					break;

				case 'data':
					formik?.setFieldValue(`horario_exame_psicologico`, '');
					dispatch(horariosPsicologoPorDataClear());
					break;

				case 'all':
					formik?.setFieldValue(`regiao`, '');
					formik?.setFieldValue(`id_micro_regiao_psicologo`, '');
					formik?.setFieldValue(`data_exame_psicologico`, '');
					formik?.setFieldValue(`horario_exame_psicologico`, '');
					dispatch(regiaoMicroRegiaoPorCepClear());
					dispatch(microRegioesClear());
					dispatch(datasExamePsicologicoClear());
					dispatch(horariosPsicologoPorDataClear());
					break;

				default:
			}
		},
		[dispatch],
	);

	const getRegiaoMicroRegiaoCep = useCallback(
		(cep: string) => {
			dispatch(
				regiaoMicroRegiaoPorCepRequest({
					cpf_cidadao: Number(dadosCidadao.cpf),
					tipo_exame: 'PSICOLOGO',
					is_portador_necessidades_especiais:
						dadosCidadao.isPortadorNecessidadesEspeciais,
					cep_cidadao: Number(cep),
					usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
				}),
			);
		},
		[dadosCidadao.cpf, dadosCidadao.isPortadorNecessidadesEspeciais, dispatch],
	);

	const handleCepSelecionado = useCallback(
		(
			res: IBuscarCepResultado,
			formik: FormikProps<ICadastraExamePsicologico>,
		) => {
			formik.setFieldValue(`cep`, res.cep);
			formik.setFieldValue(`logradouro`, res.endereco.substring(0, 40));
			formik.setFieldValue(`bairro`, res.bairro.substring(0, 13));
			formik.setFieldValue(`municipio`, res.municipio);
			if (res.cep.length === 8) {
				clearFieldsFormikAndData('all', formik);
				getRegiaoMicroRegiaoCep(res.cep);
			} else {
				clearFieldsFormikAndData('all', formik);
			}
		},
		[clearFieldsFormikAndData, getRegiaoMicroRegiaoCep],
	);
	const loadCleanChanges = useCallback(
		(
			item:
				| 'regiao'
				| 'microRegiao'
				| 'data'
				| 'hora'
				| 'codigo_ciretran_exame_medico',
			selectedValue: number,
			formik?: FormikProps<ICadastraExamePsicologico>,
		) => {
			switch (item) {
				case 'regiao':
					clearFieldsFormikAndData(item, formik);
					if (selectedValue) {
						dispatch(
							microRegioesRequest({
								cpf_cidadao: Number(dadosCidadao.cpf),
								tipo_exame: 'PSICOLOGO',
								is_portador_necessidades_especiais:
									dadosCidadao.isPortadorNecessidadesEspeciais,
								id_regiao_medico: selectedValue,
								id_regiao_psicologo: selectedValue,
								usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
							}),
						);
					}

					break;
				case 'microRegiao':
					clearFieldsFormikAndData(item, formik);
					if (selectedValue) {
						dispatch(
							datasExamePsicologicoRequest({
								cep_cidadao: Number(dadosCidadao.cep),
								cpf_cidadao: Number(dadosCidadao.cpf),
								codigo_ciretran: 18,
								id_micro_regiao: selectedValue,
								usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
							}),
						);
					}

					break;
				case 'codigo_ciretran_exame_medico':
					clearFieldsFormikAndData(item, formik);
					if (selectedValue) {
						if (selectedValue === 18) {
							dispatch(
								regioesRequest({
									evento: {
										id_atendimento: dadosAtendimento?.data?.idAtendimento || '',
										id_cidadao: dadosAtendimento?.data?.idCidadao || '',
										cpf: user.cpf,
										identificacao: dadosCidadao.cpf,
										ip: user.ip,
										canal: {
											id: 1,
											desc: 'Presencial',
											localidade: {
												id: Number(user.posto),
												desc: user.nomePosto,
											},
										},
									},
									cpf_cidadao: Number(dadosCidadao.cpf),
									tipo_exame: 'PSICOLOGO',
									is_portador_necessidades_especiais:
										dadosCidadao.isPortadorNecessidadesEspeciais,
									usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
								}),
							);
						} else {
							dispatch(
								datasExamePsicologicoRequest({
									cep_cidadao: Number(dadosCidadao.cep),
									cpf_cidadao: Number(dadosCidadao.cpf),
									codigo_ciretran: selectedValue,
									usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
								}),
							);
						}
					}
					break;
				case 'data':
					clearFieldsFormikAndData(item, formik);
					if (selectedValue) {
						dispatch(
							horariosPsicologoPorDataRequest({
								data_exame_psicologico: selectedValue.toString(),
								id_psicologo: Number(datasExamePsicologico.data?.idPsicologo),
								existe_agendamento_exame_medico: false,
							}),
						);
					}

					break;
				case 'hora':
					break;
				default:
					break;
			}
		},
		[
			clearFieldsFormikAndData,
			dispatch,
			dadosCidadao.cpf,
			dadosCidadao.isPortadorNecessidadesEspeciais,
			dadosCidadao.cep,
			dadosAtendimento,
			user.cpf,
			user.ip,
			user.posto,
			user.nomePosto,
			datasExamePsicologico.data,
		],
	);

	useEffect(() => {
		if (
			gerarRenachIntegrado.status === 200 &&
			gerarRenachIntegrado.requestData?.dadosExamePsicologo
				.dataExamePsicologico !== '' &&
			redirecionamento
		) {
			dispatch(estatisticaIncluirClear());
			history.push(redirecionamento);
		} else if (
			gerarRenachIntegrado.status !== 0 &&
			gerarRenachIntegrado.status !== 100 &&
			gerarRenachIntegrado.status !== 200 &&
			gerarRenachIntegrado.requestData?.candidato.tipoProcesso
		) {
			const urlRedirect =
				enumFormUseUrlStatusCondutor.filter(
					item =>
						Number(item.idTypeProcess) ===
						Number(gerarRenachIntegrado.requestData?.candidato.tipoProcesso),
				)[0].url || '';

			if (urlRedirect && urlRedirect !== '') {
				dispatch(cadastroEnderecosClear());
				dispatch(capturarBiometriaClear());
				dispatch(consultarDigitaisClear());
				dispatch(confrontarDigitaisCnhClear());
				dispatch(estatisticaIncluirClear());
				dispatch(consultarInaptoClear());
				dispatch(dadosCidadaoClear());
				dispatch(verificarVersaoServicoBiometriaClear());
				history.push(urlRedirect);
			}
		}
	}, [dispatch, gerarRenachIntegrado, history, redirecionamento]);

	useEffect(() => {
		if (
			saveGerarRenach.data?.gerarRenachBase?.codCiretranHabilitacao === '18' ||
			saveGerarRenach.data?.gerarRenachBase?.codCiretranHabilitacao === '018'
		) {
			dispatch(
				regioesRequest({
					evento: {
						id_atendimento: dadosAtendimento?.data?.idAtendimento || '',
						id_cidadao: dadosAtendimento?.data?.idCidadao || '',
						cpf: user.cpf,
						identificacao: dadosCidadao.cpf,
						ip: user.ip,
						canal: {
							id: 1,
							desc: 'Presencial',
							localidade: {
								id: Number(user.posto),
								desc: user.nomePosto,
							},
						},
					},
					cpf_cidadao: Number(dadosCidadao.cpf),
					tipo_exame: 'PSICOLOGO',
					is_portador_necessidades_especiais:
						dadosCidadao.isPortadorNecessidadesEspeciais,
					usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
				}),
			);
		} else if (saveGerarRenach.data?.gerarRenachBase !== null) {
			dispatch(
				datasExamePsicologicoRequest({
					cep_cidadao: Number(dadosCidadao.cep),
					cpf_cidadao: Number(dadosCidadao.cpf),
					codigo_ciretran: Number(
						saveGerarRenach.data?.gerarRenachBase?.codCiretranHabilitacao,
					),
					usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
				}),
			);
		}
	}, [dadosCidadao, dadosAtendimento, user, dispatch, saveGerarRenach.data]);

	useEffect(() => {
		if (
			regiaoMicroRegiaoPorCep.status === 200 ||
			regiaoMicroRegiaoPorCep.status === 204
		) {
			if (
				regiaoMicroRegiaoPorCep.data.regiao?.length &&
				regiaoMicroRegiaoPorCep.data.micro_regiao?.length
			) {
				loadCleanChanges(
					'regiao',
					Number(regiaoMicroRegiaoPorCep.data.regiao[0].value),
				);
				loadCleanChanges(
					'microRegiao',
					Number(regiaoMicroRegiaoPorCep.data.micro_regiao[0].value),
				);

				setTimeout(() => {
					setDadosAgendamentoForm({
						...dadosAgendamento,
						id_micro_regiao_psicologo:
							regiaoMicroRegiaoPorCep.data.micro_regiao[0].value,
						regiao: regiaoMicroRegiaoPorCep.data.regiao[0].value,
					});
				}, 300);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [regiaoMicroRegiaoPorCep]);

	return (
		<Section title="Status do Condutor">
			<DadosCidadao
				dadosCidadao={dadosCidadao}
				limiteExame={
					consultaToxicologico.data &&
					consultaToxicologico.data.dataLimiteExameMedico
						? formatDate(consultaToxicologico.data.dataLimiteExameMedico)
						: undefined
				}
			/>

			<Row gutter={[0, 40]}>
				<Col span={24} />
			</Row>

			<Row>
				<Col span={24}>
					<h1 style={{ opacity: 0.7 }}>Agendamento Psicológico</h1>
				</Col>
			</Row>
			<Divider
				style={{ marginTop: 0, backgroundColor: '#000', opacity: 0.6 }}
			/>
			<Row>
				<Col span={24}>
					{!datasExamePsicologico.enumUnidades ||
						(datasExamePsicologico.enumUnidades &&
							datasExamePsicologico.enumUnidades.unidades_disponiveis.length <=
								0) ||
						datasExamePsicologico.data === null ||
						(datasExamePsicologico.data !== null &&
							datasExamePsicologico.data.enum.length <= 0 && (
								<div style={{ marginTop: '20px' }}>
									<ColorBox bgColor="vermelho">
										<Row>
											<Col>
												<strong>
													NÃO CONSTA GRADE DISPONÌVEL PARA AGENDAMENTO!
												</strong>
											</Col>
										</Row>
									</ColorBox>
								</div>
							))}

					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={dadosAgendamentoForm}
						validate={values => getValidationsErrors(values, schema)}
						onSubmit={handleModalSubmit}
						enableReinitialize
					>
						{formik => (
							<Form autoComplete="off">
								{saveGerarRenach.data?.gerarRenachBase &&
									saveGerarRenach.data?.gerarRenachBase
										.codCiretranHabilitacao !== '18' &&
									saveGerarRenach.data?.gerarRenachBase
										.codCiretranHabilitacao !== '018' &&
									datasExamePsicologico.enumUnidades.unidades_disponiveis
										.length > 0 && (
										<Row gutter={[0, 10]}>
											<Col span={8}>
												<Field
													as={Select}
													title="Unidades Disponíveis"
													name="codigo_ciretran_exame_medico"
													options={
														datasExamePsicologico.enumUnidades
															? datasExamePsicologico.enumUnidades
																	.unidades_disponiveis
															: []
													}
													onChange={(v: number) => {
														formik.setFieldValue(
															'codigo_ciretran_exame_medico',
															v,
														);
														loadCleanChanges('codigo_ciretran_exame_medico', v);
													}}
													titleSize="md"
													size={80}
													error={!!formik.errors.codigo_ciretran_exame_medico}
												/>
											</Col>
										</Row>
									)}
								{((saveGerarRenach.data?.gerarRenachBase &&
									saveGerarRenach.data?.gerarRenachBase
										?.codCiretranHabilitacao === '18') ||
									saveGerarRenach.data?.gerarRenachBase
										?.codCiretranHabilitacao === '018' ||
									formik.values.codigo_ciretran_exame_medico === 18) && (
									<Row gutter={[0, 10]}>
										<Col span={8}>
											<Field
												as={Select}
												title="Região"
												name="regiao"
												options={
													regiaoMicroRegiaoPorCep.data.regiao?.length
														? regiaoMicroRegiaoPorCep.data.regiao
														: regioes.data
												}
												defaultFirstOptionText={
													regiaoMicroRegiaoPorCep.data.regiao?.length
														? regiaoMicroRegiaoPorCep.data.regiao[0].label
														: '- SELECIONE -'
												}
												onChange={(v: number) => {
													formik.setFieldValue('regiao', v);
													loadCleanChanges('regiao', v, formik);
												}}
												titleSize="md"
												size={80}
												error={!!formik.errors.regiao}
											/>
										</Col>
										<Col span={8}>
											<Field
												as={Select}
												title="Macrorregião"
												name="id_micro_regiao_psicologo"
												options={
													microRegioes.data.length ? microRegioes.data : []
												}
												onChange={(v: number) => {
													formik.setFieldValue('id_micro_regiao_psicologo', v);
													loadCleanChanges('microRegiao', v, formik);
												}}
												titleSize="md"
												size={80}
												error={!!formik.errors.id_micro_regiao_psicologo}
											/>
										</Col>
									</Row>
								)}

								<Row gutter={[0, 10]}>
									{((formik.values.codigo_ciretran_exame_medico &&
										formik.values.codigo_ciretran_exame_medico !== 0) ||
										(formik.values.id_micro_regiao_psicologo &&
											formik.values.id_micro_regiao_psicologo !== '') ||
										datasExamePsicologico.data?.enum.length) && (
										<>
											<Col span={8}>
												<Field
													as={Select}
													title="Data"
													name="data_exame_psicologico"
													options={datasExamePsicologico.data?.enum || []}
													onChange={(v: number) => {
														formik.setFieldValue('data_exame_psicologico', v);
														loadCleanChanges('data', v, formik);
													}}
													titleSize="md"
													size={80}
													error={!!formik.errors.data_exame_psicologico}
												/>
											</Col>
											<Col span={8}>
												<Field
													as={Select}
													title="Hora"
													name="horario_exame_psicologico"
													options={horariosPsicologoPorData.data?.enum || []}
													onChange={(v: number) => {
														formik.setFieldValue(
															'horario_exame_psicologico',
															v,
														);
														loadCleanChanges('hora', v);
													}}
													titleSize="md"
													size={80}
													error={!!formik.errors.horario_exame_psicologico}
												/>
											</Col>
										</>
									)}
									{((saveGerarRenach.data?.gerarRenachBase &&
										saveGerarRenach.data?.gerarRenachBase
											?.codCiretranHabilitacao === '18') ||
										saveGerarRenach.data?.gerarRenachBase
											?.codCiretranHabilitacao === '018' ||
										formik.values.codigo_ciretran_exame_medico === 18) && (
										<Col span={8}>
											<CEP
												name="cep_cidadao"
												formik={formik}
												titleSize="sm"
												size={80}
												isCalledInService="DETRAN_CNH"
												result={(res: IBuscarCepResultado) =>
													handleCepSelecionado(res, formik)
												}
											/>
										</Col>
									)}
								</Row>

								<Row gutter={[0, 20]}>
									<Col span={24} />
								</Row>

								<Row justify="center">
									<Col>
										<ButtonImage
											type="submit"
											src="salvar"
											disabled={
												datasExamePsicologico.data === null ||
												(datasExamePsicologico.data !== null &&
													datasExamePsicologico.data.enum.length <= 0)
											}
										/>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</Col>
			</Row>
		</Section>
	);
};

export default AgendamentoPsicologicoRenachIntegrado;
