import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../../types';
import { Types, AdministrarState } from './types';

import motivos from './motivos/combineReducers';
import municipios from './municipios/combineReducers';
import naturalidades from './naturalidades/combineReducers';
import orgaos from './orgaos/combineReducers';
import perfis from './perfis/combineReducers';
import status from './status/combineReducers';
import siglas from './siglas/combineReducers';
import localidades from './localidades/combineReducers';
import localidadesAssociada from './localidadesAssociadas/combineReducers';
import sufixos from './sufixos/combineReducers';
import menus from './menus/combineReducers';
import unidades from './unidades/combineReducers';
import funcionalidades from './funcionalidades/combineReducers';
import mensagens from './mensagens/combineReducers';
import parametros from './parametros/combineReducers';

const allReducers = combineReducers({
	motivos,
	municipios,
	naturalidades,
	orgaos,
	perfis,
	status,
	siglas,
	localidades,
	localidadesAssociada,
	sufixos,
	menus,
	unidades,
	funcionalidades,
	mensagens,
	parametros,
});

const combineReducer = (state: AdministrarState, action: ReducerAction) => {
	if (action.type === Types.SGU_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
