export enum Types {
	CONSULTAR_CONSISTENCIA_BIOMETRICA_REQUEST = '@rfb/CONSULTAR_CONSISTENCIA_BIOMETRICA_REQUEST',
	CONSULTAR_CONSISTENCIA_BIOMETRICA_SUCCESS = '@rfb/CONSULTAR_CONSISTENCIA_BIOMETRICA_SUCCESS',
	CONSULTAR_CONSISTENCIA_BIOMETRICA_FAILURE = '@rfb/CONSULTAR_CONSISTENCIA_BIOMETRICA_FAILURE',
	CONSULTAR_CONSISTENCIA_BIOMETRICA_CLEAR = '@rfb/CONSULTAR_CONSISTENCIA_BIOMETRICA_CLEAR',
}

export interface ConsultarConsistenciaBiometricaState {
	status: number;
	data: ConsultarConsistenciaBiometrica | null;
}

export interface ConsultarConsistenciaBiometricaRequest {
	cpfUsuario: string;
	protocoloRFB: string;
	cpfCidadao: string;
	dataHoraProtocoloRFB: string;
	codigoConsistenciaBiometrica: number;
	observacao: string;
}

export interface ConsultarConsistenciaBiometrica {
	cpf: string;
	protocoloRFB: string;
	dataHoraProtocoloRFB: string;
	protocoloOIC: string;
	indConsBiografica: string;
	indConsBiometrica: string;
	situacao: string;
	hashcodeRFB: string;
	listaMensagem: string[];
	erro: string[];
}
