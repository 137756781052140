import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaFazendaSefazCepRequest } from './types';

export function consultaFazendaCpfCepSefazRequest(
	data: ConsultaFazendaSefazCepRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_FAZENDA_CPF_CEP_SEFAZ,
		payload: data,
	};
}
export function consultaFazendaCpfCepSefazSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_FAZENDA_CPF_CEP_SEFAZ_SUCCESS,
		payload,
	};
}
export function consultaFazendaCpfCepSefazFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.CONSULTA_FAZENDA_CPF_CEP_SEFAZ_FAILURE,
		payload,
	};
}
