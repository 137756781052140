import { ReducerAction } from 'store/modules/types';

import { Types, ImprimirRenachRequest } from './types';

export function imprimirRenachRequest(
	data: ImprimirRenachRequest,
): ReducerAction {
	return {
		type: Types.IMPRIMIR_RENACH_REQUEST,
		payload: data,
	};
}
export function imprimirRenachSuccess(payload: object): ReducerAction {
	return {
		type: Types.IMPRIMIR_RENACH_SUCCESS,
		payload,
	};
}
export function imprimirRenachFailure(payload: boolean): ReducerAction {
	return {
		type: Types.IMPRIMIR_RENACH_FAILURE,
		payload,
	};
}
export function imprimirRenachClear(): ReducerAction {
	return {
		type: Types.IMPRIMIR_RENACH_CLEAR,
		payload: null,
	};
}
