import produce from 'immer';
import { Types, NotificationsState } from './types';
import { ReducerAction } from '../../types';

export const INITIAL_STATE: NotificationsState = {
	errors: [],
	showInside: false,
	moveToTop: true,
	notificationOrigin: undefined,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): NotificationsState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ADD_NOTIFICATIONS: {
				draft.errors = action.payload.errors;
				draft.moveToTop = action.payload.moveToTop !== false;
				draft.notificationOrigin = action.payload.notificationOrigin;
				break;
			}
			case Types.SHOW_INSIDE: {
				draft.showInside = action.payload;
				break;
			}
			case Types.CLEAR_NOTIFICATIONS: {
				draft.errors = INITIAL_STATE.errors;
				draft.moveToTop = INITIAL_STATE.moveToTop;
				draft.notificationOrigin = INITIAL_STATE.notificationOrigin;
				// draft.showInside = INITIAL_STATE.showInside;
				break;
			}
			default:
		}
	});
}
