import produce from 'immer';

// TYPES
import { Types, agendarExameMedico } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: agendarExameMedico = {
	status: 0,
	data: null,
};

export default function agendarExameMedicoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): agendarExameMedico {
	return produce(state, draft => {
		switch (action.type) {
			case Types.AGENDAR_EXAME_MEDICO: {
				break;
			}

			case Types.AGENDAR_EXAME_MEDICO_SUCCESS: {
				draft.data = action.payload.data;
				break;
			}

			case Types.AGENDAR_EXAME_MEDICO_FAILURE: {
				break;
			}

			default:
		}
	});
}
