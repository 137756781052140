import { ReducerAction } from 'store/modules/types';

import { Types, AlteraSenhaRequest } from './types';

export function alteraSenhaRequest(data: AlteraSenhaRequest): ReducerAction {
	return {
		type: Types.ALTERA_SENHA_REQUEST,
		payload: data,
	};
}
export function alteraSenhaSuccess(payload: object): ReducerAction {
	return {
		type: Types.ALTERA_SENHA_SUCCESS,
		payload,
	};
}
export function alteraSenhaFailure(payload: object): ReducerAction {
	return {
		type: Types.ALTERA_SENHA_FAILURE,
		payload,
	};
}

export function alteraSenhaClear(payload: object): ReducerAction {
	return {
		type: Types.ALTERA_SENHA_CLEAR,
		payload,
	};
}
