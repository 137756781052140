import React, { useEffect } from 'react';

// ANTD
import { Row, Col } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import FormBox from 'components/Common/Form/FormBox';
import Select from 'components/Common/Form/Select';
import InputMask from 'components/Common/Form/Input/InputMask';

// FORM
import { FormikProps, Field } from 'formik';
import { IFormCadastroRg } from '../../form';

// COMPONENTS
import CID from './CID';

interface Props {
	formik: FormikProps<IFormCadastroRg>;
}

const InfosAdicionais: React.FC<Props> = ({ formik }) => {
	const { setFieldValue } = formik;

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	useEffect(() => {
		if (
			formik.values.grauInstrucao === 'N' &&
			formik.values.identificadoAssina !== false
		) {
			formik.setFieldValue('identificadoAssina', false);
		}
	}, [formik]);

	return (
		<>
			<FormBox title="Informações adicionais">
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Select}
							title="Identificado(a) assinará Carteira de Identidade e FIC"
							titleSize={300}
							titleQuestion
							size={30}
							name="identificadoAssina"
							defaultValue={formik.values.identificadoAssina}
							defaultFirstOption={false}
							options={data.simNaoBoolean}
							onChange={(v: boolean) => setFieldValue('identificadoAssina', v)}
							error={!!formik.errors.identificadoAssina}
						/>
					</Col>
				</Row>

				{!formik.values.identificadoAssina && (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Field
								as={Select}
								titleSize={300}
								size={30}
								title="Motivo da falta de assinatura"
								name="motivoFaltaAssinatura"
								defaultValue={formik.values.motivoFaltaAssinatura}
								value={formik.values.motivoFaltaAssinatura}
								options={data.motivoFaltaAssinatura}
								onChange={(v: boolean) =>
									setFieldValue('motivoFaltaAssinatura', v)
								}
								error={!!formik.errors.motivoFaltaAssinatura}
							/>
						</Col>
					</Row>
				)}

				{formik.values.motivoFaltaAssinatura === '3' && (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Field
								as={InputMask}
								title="Data de Validade"
								subtitle="(DD/MM/AAAA)"
								name="dataValidade"
								mask="99/99/9999"
								size={40}
								titleSize={300}
								required
								error={!!formik.errors.dataValidade}
							/>
						</Col>
					</Row>
				)}

				{cadastroRg.info.idade >= 65 && (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Field
								as={Select}
								title={`Imprimir expressão "Maior de 65 anos" na Carteira de Identidade`}
								titleSize={300}
								titleQuestion
								name="carimboMaior65"
								size={30}
								options={data.simNaoBoolean}
								onChange={(v: boolean) => setFieldValue('carimboMaior65', v)}
								error={!!formik.errors.carimboMaior65}
							/>
						</Col>
					</Row>
				)}

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Select}
							title="Tipo Sanguíneo"
							titleSize={300}
							subtitle="(Permitido apenas para maiores de 5 anos)"
							name="tipoSanguineo"
							options={data.tipoSanguineo}
							onChange={(v: string) => setFieldValue('tipoSanguineo', v)}
							size={60}
							disabled={cadastroRg.info.idade < 5}
							error={!!formik.errors.tipoSanguineo}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<CID formik={formik} />
					</Col>
				</Row>
			</FormBox>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default InfosAdicionais;
