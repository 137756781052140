import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultaTipoRequest } from 'store/modules/api/detranCrv/enum/consultaTipo/actions';
import { consultaEspecieRequest } from 'store/modules/api/detranCrv/enum/consultaEspecie/actions';
import { consultaCarroceriaRequest } from 'store/modules/api/detranCrv/enum/consultaCarroceria/actions';
import { consultaCorRequest } from 'store/modules/api/detranCrv/enum/consultaCor/actions';
import { consultaCombustivelRequest } from 'store/modules/api/detranCrv/enum/consultaCombustivel/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCrv/enum/consultaCategoria/actions';
import { consultarMunicipioPorUfRequest } from 'store/modules/api/detranCrv/enum/consultarMunicipioPorUF/actions';
import { consultarVeiculoV110Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import { completaZeroEsquerda } from 'utils/genericFunctions';
import getValidationsErrors from 'utils/getValidationsErrors';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

// COMPONENTS
import Radio from 'components/Common/Form/Radio';
import { RadioChangeEvent } from 'antd/lib/radio';
import { emitirAlteracaoDadosCadastraisRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirAlteracaoDadosCadastrais/actions';
import { emitirModificacaoDadosCadastraisRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirModificacaoDadosCadastrais/actions';

import UploadArquivo from 'pages/DetranCrv/components/pages/UploadArquivo';
import TaxaServico from 'pages/DetranCrv/components/pages/TaxaServico';
import DadosVeiculo from './DadosVeiculo';
import DadosProprietario from './DadosProprietario';
import Gravames from './Gravames';
import Modificacao from './Modificacao';

// FORM
import {
	IEmitirAlteracaoCadastral,
	initialValues,
	schema,
	treatValues,
	treatValuesModificar,
} from './form';

const EmitirAlteracao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		consultaCategoria,
		consultaCarroceria,
		consultaCombustivel,
		consultaCor,
		consultaEspecie,
		consultarMunicipioPorUF,
		consultaTipo,
	} = useSelector((state: ApplicationState) => state.api.detranCrv.Enum);

	const {
		consultarVeiculoV110,
		emitirAlteracaoDadosCadastrais,
		emitirModificacaoDadosCadastrais,
	} = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { consultarTaxaServico } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	const { salvarAtendimento } = atendimento;
	const { login } = loginUnico;

	const sguData: SguData = useMemo(() => {
		return {
			salvarAtendimento,
			login,
		};
	}, [login, salvarAtendimento]);

	const evento = useMemo(() => {
		return getEventoConsultar(sguData);
	}, [sguData]);

	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (!consultarVeiculoV110.data) {
			history.push('/detran-crv/alteracao-dados-cadastrais/consultar');
		}
	}, [consultarVeiculoV110.data, history]);

	useEffect(() => {
		if (emitirAlteracaoDadosCadastrais.data) {
			history.push('/detran-crv/alteracao-dados-cadastrais/resultado');
		}
		if (emitirModificacaoDadosCadastrais.data) {
			history.push('/detran-crv/alteracao-dados-cadastrais/resultado');
		}
	}, [
		emitirAlteracaoDadosCadastrais.data,
		history,
		emitirModificacaoDadosCadastrais.data,
	]);

	useEffect(() => {
		if (
			consultarVeiculoV110.data &&
			consultaTipo.data.length > 0 &&
			consultaEspecie.data.length > 0 &&
			consultaCarroceria.data.length > 0 &&
			consultaCor.data.length > 0 &&
			consultaCombustivel.data.length > 0 &&
			consultarMunicipioPorUF.data.length > 0 &&
			consultaCategoria.data.length > 0
		) {
			const {
				placa,
				chassi,
				marca,
				municipio,
				anoModelo,
				anoFabricacao,
				tipo,
				carroceria,
				cor,
				categoria,
				combustivel,
				especie,
				capacidadePassageiro,
				capacidadeCarga,
				potencia,
				cilindrada,
				cmt,
				pbt,
				eixos,
				procedencia,
				proprietario,
				gravame,
				municipioPlacaAnterior,
				ufPlacaAnterior,
				placaAnterior,
			} = consultarVeiculoV110.data.caracteristicaVeiculo;

			// Dados do Veículo
			initialValues.placa = placa;
			initialValues.chassi = chassi;
			initialValues.longIdMarca = marca.longId;
			initialValues.descricaoMarca = marca.descricao;

			initialValues.codigoMunicipio = municipio.codigo;
			initialValues.nomeMunicipio = municipio.nome;

			initialValues.anoModelo = anoModelo;
			initialValues.anoFabricacao = anoFabricacao;

			initialValues.longIdTipo = tipo.longId;
			initialValues.descricaoTipo = tipo.descricao;

			initialValues.longIdCarroceria = carroceria.longId;
			initialValues.descricaoCarroceria = carroceria.descricao;

			initialValues.longIdCor = cor.longId;
			initialValues.descricaoCor = cor.descricao;

			initialValues.longIdCategoria = categoria.longId;
			initialValues.descricaoCategoria = categoria.descricao;

			initialValues.longIdCombustivel = combustivel.longId;
			initialValues.descricaoCombustivel = combustivel.descricao;

			initialValues.longIdEspecie = especie.longId;
			initialValues.descricaoEspecie = especie.descricao;

			initialValues.capacidadePassageiro = capacidadePassageiro;
			initialValues.capacidadeCarga = capacidadeCarga
				? Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(capacidadeCarga)
				: Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(0);
			initialValues.potencia = potencia;
			initialValues.cilindrada = cilindrada;
			initialValues.cmt = cmt
				? Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(cmt)
				: Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(0);

			initialValues.pbt = pbt
				? Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 2,
				  }).format(pbt)
				: Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 2,
				  }).format(0);

			initialValues.eixo = eixos === 0 ? 0 : eixos;

			initialValues.procedencia = procedencia;

			// Gravames
			initialValues.arrendatarioFinanceiraGravame =
				gravame.arrendatarioFinanceira;
			initialValues.dataVigenciaGravame = gravame.dataVigencia;
			initialValues.financeiraGravame = gravame.codigoFinanceira;
			initialValues.numeroContratoGravame = gravame.numeroContrato;
			initialValues.restricaoFinanceiraGravame = gravame.restricao;

			// Dados do Proprietário
			initialValues.nomeProprietario =
				proprietario.nomeProprietario?.trim().substring(0, 60) || '';
			initialValues.cepProprietario =
				proprietario.cepProprietario?.trim() || '';
			initialValues.bairroProprietario =
				proprietario.bairroProprietario?.trim().substring(0, 70) || '';
			initialValues.enderecoProprietario =
				proprietario.enderecoProprietario?.trim().substring(0, 70) || '';
			initialValues.numeroProprietario =
				proprietario.numeroLogradouroProprietario?.trim().substring(0, 5) || '';
			initialValues.complementoProprietario =
				proprietario.complementoLogradouroProprietario
					?.trim()
					.substring(0, 6) || '';

			const selectedMunicipio = consultarMunicipioPorUF.data.filter(
				item => item.value === proprietario.municipioProprietario.codigo,
			);

			initialValues.codigoMunicipioProprietario =
				selectedMunicipio.length > 0 ? selectedMunicipio[0].value : '';
			initialValues.nomeMunicipioProprietario =
				selectedMunicipio.length > 0 ? selectedMunicipio[0].label : '';
			initialValues.identificacao =
				proprietario.identificacao === 'FISICA' ? 'FISICA' : 'JURIDICA';
			initialValues.ufPlacaAnterior = ufPlacaAnterior;
			initialValues.codigoMunicipioPlacaAnterior =
				municipioPlacaAnterior.codigo;
			initialValues.nomeMunicipioPlacaAnterior = municipioPlacaAnterior.nome;

			initialValues.cpfCnpjProprietario = completaZeroEsquerda(
				proprietario.cpfCnpjProprietario,
				proprietario.identificacao === 'FISICA' ? 11 : 14,
			);

			initialValues.nomeProprietarioAnterior =
				proprietario.nomeProprietarioAnterior;
			initialValues.placaAnterior = placaAnterior;

			initialValues.acessibilidadeCondutorModificacao = false;
			initialValues.acessibilidadePassageiroModificacao = false;
			initialValues.alongamentoChassiModificacao = false;
			initialValues.blindagemModificacao = false;
			initialValues.cabineDuplaModificacao = false;
			initialValues.cabineEstendidaModificacao = false;
			initialValues.cabineSuplementarModificacao = false;
			initialValues.capacidadeVolumeTanqueSuplementarModificacao = '';
			initialValues.certificadoSegVeicularModificacao = '';
			initialValues.csvAnoModificacao = '';
			initialValues.distanciaEixoModificacao = '';
			initialValues.eixoModificacao = '';
			initialValues.encurtamentoChassiModificacao = false;
			initialValues.freiosModificacao = false;
			initialValues.iluminacaoFrontalSinalizacaoModificacao = false;
			initialValues.insulfilmeModificacao = false;
			initialValues.parachoqueModificacao = false;
			initialValues.plataformaModificacao = false;
			initialValues.potenciaCilindradaModificacao = false;
			initialValues.quantidadeTanqueSuplementarModificacao = '';
			initialValues.rebaixamentoModificacao = false;
			initialValues.reencarrocamentoModificacao = false;
			initialValues.rodaPneuModificacao = false;
			initialValues.suspensaoModificacao = false;
			initialValues.suspensaoPneumaticaModificacao = false;
			initialValues.tanqueSuplementarModificacao = false;
			initialValues.tetoSolarModificacao = false;
			initialValues.tipoAcessibilidadePassageiroModificacao = '';
			initialValues.valorSuspensaoModificacao = '';
			initialValues.visualModificacao = false;

			// Taxa
			initialValues.codBanco = consultarTaxaServico.data?.codBanco || '';
			initialValues.dataContabil =
				consultarTaxaServico.data?.dataContabil || '';
			initialValues.nsuProdesp = consultarTaxaServico.data?.nsuProdesp || '';

			setHasLoaded(true);
		}
	}, [
		consultarVeiculoV110.data,
		consultaTipo.data,
		consultaEspecie.data,
		consultaCarroceria.data,
		consultaCor.data,
		consultaCombustivel.data,
		consultaCategoria.data,
		consultarMunicipioPorUF.data,
		consultarTaxaServico.data,
		history,
	]);

	// Enum: Municípios - SP.
	useEffect(() => {
		if (consultarMunicipioPorUF.data.length === 0) {
			dispatch(consultarMunicipioPorUfRequest('SP'));
		}
	}, [consultarMunicipioPorUF, dispatch]);

	// Enum: Tipo de Veículo.
	useEffect(() => {
		if (consultaTipo.data.length === 0) {
			dispatch(consultaTipoRequest());
		}
	}, [consultaTipo, dispatch]);

	// Enum: Espécie.
	useEffect(() => {
		if (consultaEspecie.data.length === 0) {
			dispatch(consultaEspecieRequest());
		}
	}, [consultaEspecie, dispatch]);

	// Enum: Carroceria.
	useEffect(() => {
		if (consultaCarroceria.data.length === 0) {
			dispatch(consultaCarroceriaRequest());
		}
	}, [consultaCarroceria, dispatch]);

	// Enum: Cor.
	useEffect(() => {
		if (consultaCor.data.length === 0) {
			dispatch(consultaCorRequest());
		}
	}, [consultaCor, dispatch]);

	// Enum: Combustível.
	useEffect(() => {
		if (consultaCombustivel.data.length === 0) {
			dispatch(consultaCombustivelRequest());
		}
	}, [consultaCombustivel, dispatch]);

	// Enum: Categoria.
	useEffect(() => {
		if (consultaCategoria.data.length === 0) {
			dispatch(consultaCategoriaRequest());
		}
	}, [consultaCategoria, dispatch]);

	const handleValidate = useCallback((formValues: any) => {
		return getValidationsErrors(formValues, schema);
	}, []);

	const handleSubmit = useCallback(
		(formValues: IEmitirAlteracaoCadastral) => {
			if (consultarVeiculoV110.data) {
				const headers = {
					codUnidade: evento.canal.localidade.id.toString(),
					cpfUsuario: evento.cpf,
				};
				if (formValues.tipoPesquisa === 'alteracao') {
					let values;
					if (consultarTaxaServico.data) {
						values = treatValues(evento, formValues, consultarTaxaServico.data);
					} else {
						values = treatValues(evento, formValues);
					}
					dispatch(
						emitirAlteracaoDadosCadastraisRequest({ body: values, headers }),
					);
				} else if (formValues.tipoPesquisa === 'modificacao') {
					let values;
					if (consultarTaxaServico.data) {
						values = treatValuesModificar(
							evento,
							formValues,
							consultarTaxaServico.data,
						);
					} else {
						values = treatValuesModificar(evento, formValues);
					}
					dispatch(
						emitirModificacaoDadosCadastraisRequest({ body: values, headers }),
					);
				}
			}
		},
		[consultarTaxaServico.data, consultarVeiculoV110.data, dispatch, evento],
	);

	const handleButtonCancelar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());

		history.push('/detran-crv/alteracao-dados-cadastrais/consultar');
	}, [dispatch, history]);

	const handleButtonVoltar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());

		history.push('/detran-crv/alteracao-dados-cadastrais/consultar');
	}, [dispatch, history]);

	return (
		<>
			{hasLoaded && (
				<>
					<Row>
						<Col span={24}>
							<Section
								size="lg"
								title="Alterar Dados Cadastrais"
								titleSize="sm"
							>
								<Formik
									validateOnChange={false}
									validateOnBlur={false}
									initialValues={initialValues}
									validate={handleValidate}
									onSubmit={handleSubmit}
								>
									{formik => (
										<Form autoComplete="off">
											<Section>
												<Row gutter={[0, 15]}>
													<Col span={24} />
												</Row>

												<Row justify="center">
													<Col>
														<Field
															as={Radio}
															wrap
															subtitle=" "
															name="tipoPesquisa"
															options={[
																{ label: 'Alteração', value: 'alteracao' },
																{
																	label: 'Modificação',
																	value: 'modificacao',
																},
															]}
															defaultValue={initialValues.tipoPesquisa}
															onChange={(e: RadioChangeEvent) => {
																const { value } = e.target;
																formik.setFieldValue('tipoPesquisa', value);
															}}
														/>
													</Col>
												</Row>
											</Section>

											<DadosVeiculo formik={formik} />

											<Gravames formik={formik} />

											<DadosProprietario formik={formik} />

											{consultarTaxaServico.data && (
												<Section size="sm">
													<TaxaServico
														dataConsultarTaxaServico={consultarTaxaServico.data}
													/>
												</Section>
											)}

											{formik.values.tipoPesquisa === 'modificacao' && (
												<Modificacao formik={formik} />
											)}

											<UploadArquivo
												formik={formik}
												evento={evento}
												tipoArquivo="2"
												sizeMB={10000000}
											/>

											<Row gutter={[0, 20]}>
												<Col span={24} />
											</Row>

											<Row justify="center">
												<Col>
													<Button onClick={handleButtonCancelar}>
														Cancelar
													</Button>
												</Col>

												<Col offset={1}>
													<Button type="submit">Enviar</Button>
												</Col>
											</Row>
										</Form>
									)}
								</Formik>
							</Section>

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default EmitirAlteracao;
