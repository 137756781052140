import React from 'react';
import styled from 'styled-components';

import { Row, Col, Image } from 'antd';
import cdhu from 'assets/images/orgaos/4.png';
import TypographyCommon from 'components/Common/Typography';

const Container = styled.section`
	background-color: #2d5294;
	border: 0.5px solid #2d5294;
	max-width: 2%;
	padding-right: 1px;
	height: 95px;
`;

const AcordoHeader: React.FC = () => {
	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={6}>
					<Image width={200} src={cdhu} />
				</Col>
				<Col span={12} style={{ alignSelf: 'center' }}>
					<Row justify="center">
						<TypographyCommon
							text="TERMO DE CONFISSÃO DE DÍVIDA"
							type="label"
							fontWeight="bold"
							labelSize={24}
							textCenter
							uppercase
						/>
					</Row>
				</Col>
				<Col span={6} style={{ alignSelf: 'center' }}>
					<Row>
						<Col span={1}>
							<Container />
						</Col>
						<Col span={22}>
							<Row>
								<h6>Companhia de Desenvolvimento</h6>
							</Row>
							<Row>
								<h6>Habitacional e Urbano do</h6>
							</Row>
							<Row>
								<h6>Estado de São Paulo</h6>
							</Row>
							<Row>
								<h6>Rua Boa Vista, 170 - Sé</h6>
							</Row>
							<Row>
								<h6>CEP 01014-930 - São Paulo / SP</h6>
							</Row>
							<Row>
								<h6>Telefone (011) 2545-2000</h6>
							</Row>
							<Row>
								<h6>www.cdhu.sp.gov.br</h6>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default AcordoHeader;
