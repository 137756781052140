import styled from 'styled-components';
import backgroundLogin from 'assets/images/background.png';
import logo from 'assets/images/marca_poupatempo_balcao_unico.png';
import logoGov from 'assets/images/logo_spgovbr.svg';
// @ts-ignore
export const Notification = styled.div`
	margin-top: 15px;
`;

export const Header = styled.div`
	display: flex;
	align-items: 'center';
	margin-bottom: 20px;

	h1 {
		color: #c33;
		margin-left: 20px;
		line-height: 29px;
	}

	svg {
		color: #ffffff;
		background-color: #666666;
		padding: 7px;
		border-radius: 15px;
		width: 30px;
		height: 30px;
	}
`;

export const ContainerGeral = styled.div`
	background-image: url(${backgroundLogin});
	background-color: black;
	background-size: cover;
	position: fixed;
	background-position: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

export const LogoBU = styled.div`
	background-image: url(${logo});
	width: 156px;
	height: 101px;
`;

export const LogoGov = styled.div`
	background-image: url(${logoGov});
	width: 145px;
	height: 20px;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-width: 300px;
	margin: 10% auto;
	background-color: white;
	border: 1px solid ${props => props.theme.colors.border};
	border-radius: 10px;
	padding: 35px;

	form {
		align-items: baseline;
	}
`;

export const Text = styled.p`
	color: #000;
	text-align: center;
	font-family: 'Montserrat';
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 15px 0; ;
`;

export const ButtonGOV = styled.button`
	align-items: center;
	background-color: #1351b4;
	border: 0;
	border-radius: 100em;
	color: white;
	cursor: pointer;
	display: inline-flex;
	font-size: 16px;
	font-weight: 600;
	height: 40px;
	justify-content: center;
	overflow: hidden;
	padding: 0 24px;
	position: relative;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	width: 100%;
	margin: 20px 0 0 0;

	span {
		color: white;
		margin: 0 3px 0 0;
		font-weight: 100;
	}

	&:hover {
		background-image: linear-gradient(
			rgba(255, 255, 255, 0.16),
			rgba(255, 255, 255, 0.16)
		);
	}
`;
