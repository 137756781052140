import React from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';

// COMPONENTS
import Section from 'components/Common/Section';

// FORM
import hasError from 'utils/getFormErrors';
import Select from 'components/Common/Form/Select';
import { useDispatch, useSelector } from 'react-redux';
import { consultarMunicipioPorUfRequest } from 'store/modules/api/detranCrv/enum/consultarMunicipioPorUF/actions';
import { ApplicationState } from 'store';
import { IEmitirSegundaViaPlaca } from '../form';
import data from '../../../../../../store/modules/api/detranCrv/enum';

interface Props {
	formik: FormikProps<IEmitirSegundaViaPlaca>;
}

const AutorizacaoEstampagem: React.FC<Props> = ({ formik }) => {
	const dispatch = useDispatch();
	const { dataOutrosEstados } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.Enum.consultarMunicipioPorUF,
	);

	const dataUfSP = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.Enum.consultarMunicipioPorUF.data,
	);

	return (
		<>
			<Row>
				<Col span={24}>
					<Section size="lg" title="Autorização de Estampagem" titleSize="sm">
						<Row gutter={[0, 10]}>
							<Col span={13}>
								<Field
									as={Select}
									title="Motivo de Autorização de Emplacamento"
									titleSize="lg"
									name="motivoAutorizacaoEmplacamento"
									options={data.motivoAutorizacaoEmplacamentoOptions}
									onChange={(v: number) => {
										formik.setFieldValue('motivoAutorizacaoEmplacamento', v);

										const selectedItem =
											data.motivoAutorizacaoEmplacamentoOptions.filter(
												item => v.toString() === item.value,
											);

										formik.setFieldValue(
											'motivoAutorizacaoEmplacamento',
											selectedItem[0].value,
										);
									}}
									size={100}
									required
									error={hasError(
										formik.errors,
										'motivoAutorizacaoEmplacamento',
									)}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Select}
									title="Tipo de Placa"
									titleSize="lg"
									name="tipoPlaca"
									options={data.tipoPlaca}
									onChange={(v: number) => {
										formik.setFieldValue('tipoPlaca', v);

										const selectedItem = data.tipoPlaca.filter(
											item => v.toString() === item.value,
										);

										formik.setFieldValue('tipoPlaca', selectedItem[0].value);
									}}
									size={61}
									required
									error={hasError(formik.errors, 'tipoPlaca')}
								/>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={23}>
								<Field
									as={Select}
									title="Tipo de Veículo Oficial"
									titleSize="lg"
									name="tipoVeiculoOficial"
									options={data.tipoVeiculoOficial}
									onChange={(v: number) => {
										formik.setFieldValue('tipoVeiculoOficial', v);

										const selectedItem = data.tipoVeiculoOficial.filter(
											item => v.toString() === item.value,
										);

										formik.setFieldValue(
											'tipoVeiculoOficial',
											selectedItem[0].value,
										);
									}}
									size={92}
									required={
										formik.values.motivoAutorizacaoEmplacamento ===
										'VEICULO_OFICIAL'
									}
									disabled={
										formik.values.motivoAutorizacaoEmplacamento !==
										'VEICULO_OFICIAL'
									}
									error={hasError(formik.errors, 'tipoVeiculoOficial')}
								/>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Select}
									title="UF Estampador"
									titleSize="lg"
									name="ufEstampador"
									options={data.uf}
									onChange={(v: number) => {
										formik.setFieldValue('ufEstampador', v);
										dispatch(consultarMunicipioPorUfRequest(v.toString()));
										const selectedItem = data.uf.filter(
											item => v.toString() === item.value,
										);

										formik.setFieldValue('ufEstampador', selectedItem[0].value);
									}}
									size={92}
									required
									error={hasError(formik.errors, 'ufEstampador')}
								/>
							</Col>

							<Col span={11}>
								{formik.values.ufEstampador === 'SP' ? (
									<Field
										as={Select}
										title="Município Estampador"
										titleSize="lg"
										name="codigoMunicipioEstampador"
										options={dataUfSP}
										onChange={(v: number) => {
											formik.setFieldValue('codigoMunicipioEstampador', v);

											const selectedItem = dataUfSP.filter(
												item => v === item.value,
											);

											formik.setFieldValue(
												'codigoMunicipioEstampador',
												selectedItem[0].value ? selectedItem[0].value : '',
											);

											formik.setFieldValue(
												'descricaoMunicipioEstampador',
												selectedItem[0].label,
											);
										}}
										size={92}
										required
										error={hasError(formik.errors, 'codigoMunicipioEstampador')}
									/>
								) : (
									<Field
										as={Select}
										title="Município Estampador"
										titleSize="lg"
										name="codigoMunicipioEstampador"
										options={dataOutrosEstados}
										onChange={(v: number) => {
											formik.setFieldValue('codigoMunicipioEstampador', v);

											const selectedItem = dataOutrosEstados.filter(
												item => v.toString() === item.value,
											);

											formik.setFieldValue(
												'codigoMunicipioEstampador',
												selectedItem[0].value ? selectedItem[0].value : '',
											);

											formik.setFieldValue(
												'descricaoMunicipioEstampador',
												selectedItem[0].label,
											);
										}}
										size={92}
										required
										error={hasError(formik.errors, 'codigoMunicipioEstampador')}
									/>
								)}
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default AutorizacaoEstampagem;
