export enum Types {
	CONSULTA_USUARIO_PENDENTE = '@aac/CONSULTA_USUARIO_PENDENTE',
	CONSULTA_USUARIO_PENDENTE_SUCCESS = '@aac/CONSULTA_USUARIO_PENDENTE_SUCCESS',
	CONSULTA_USUARIO_PENDENTE_FAILURE = '@aac/CONSULTA_USUARIO_PENDENTE_FAILURE',
	LIMPA_USUARIO_PENDENTE = '@aac/LIMPA_USUARIO_PENDENTE',

	ATUALIZAR_ATENDENTE = '@aac/ATUALIZAR_ATENDENTE',
	ATUALIZAR_ATENDENTE_SUCCESS = '@aac/ATUALIZAR_ATENDENTE_SUCCESS',
	ATUALIZAR_ATENDENTE_FAILURE = '@aac/ATUALIZAR_ATENDENTE_FAILURE',
	LIMPA_ATUALIZAR_ATENDENTE = '@aac/LIMPA_ATUALIZAR_ATENDENTE',
}

export interface UsuarioPendente {
	status: number;
	resultado: ConsultaUsuarioPendente[] | null;
}

export interface ConsultaUsuarioPendente {
	codigoValidacao: string;
	id: string;
	dc: string;
	dataEmissaoAtestado: number;
	dataNascimento: number;
	dataExpedicaoRg: number;
	nome: string;
	nomeMae: string;
	nomePai: string;
	nomeSocial: string;
	docProcurador: string;
	nomeProcurador: string;
	numeroRg: string;
	sexo: string;
	exigenciaProcurado: string;
	exigenciaPreso: string;
	exigenciaEvadido: string;
	exigCopiaApresentado: string;
	exigBaixaDesaparecido: string;
	exigCertidaoObjeto: string;
	exigenciaIP01: string;
	exigenciaIP02: string;
	exigenciaIP03: string;
	exigenciaIP04: string;
	exigenciaIP05: string;
	exigenciaIP06: string;
	exigenciaIP07: string;
	exigenciaIP08: string;
	exigenciaIP09: string;
	exigenciaIP10: string;
	exigDelegacia01: string;
	exigDelegacia02: string;
	exigDelegacia03: string;
	exigDelegacia04: string;
	exigDelegacia05: string;
	exigDelegacia06: string;
	exigDelegacia07: string;
	exigDelegacia08: string;
	exigDelegacia09: string;
	exigDelegacia10: string;
	exigInstauracao01: string;
	exigInstauracao02: string;
	exigInstauracao03: string;
	exigInstauracao04: string;
	exigInstauracao05: string;
	exigInstauracao06: string;
	exigInstauracao07: string;
	exigInstauracao08: string;
	exigInstauracao09: string;
	exigInstauracao10: string;
	exigProcesso01: string;
	exigProcesso02: string;
	exigProcesso03: string;
	exigProcesso04: string;
	exigProcesso05: string;
	exigProcesso06: string;
	exigProcesso07: string;
	exigProcesso08: string;
	exigProcesso09: string;
	exigProcesso10: string;
	exigVara01: string;
	exigVara02: string;
	exigVara03: string;
	exigVara04: string;
	exigVara05: string;
	exigVara06: string;
	exigVara07: string;
	exigVara08: string;
	exigVara09: string;
	exigVara10: string;
	exigBO: string;
	exigBODelegacia: string;
	exigBONumero: string;
	exigCertidaoCriminais: string;
	exigCertidaoCriminais01: string;
	exigCertidaoCriminais02: string;
	exigCertidaoDistribuidor: string;
	exigCertidaoDistribuidor01: string;
	exigObservacao: string;
	exigObservacao01: string;
	exigObservacao02: string;
	exigObservacao03: string;
	exigObservacao04: string;
	exigObservacao05: string;
	exigObservacao06: string;
	exigObservacao07: string;
	exigObservacao08: string;
	exigObservacao09: string;
	exigObservacao10: string;
	positivaInicio: string;
	positivaObservacao01: string;
	positivaObservacao02: string;
	positivaRegime: string;
	positivaTermino: string;
	positivaExecucao: string;
	encerrado: string;
	posto: string;
	resultado: string;
	retorno: string;
	tipoAA: string;
	tipoDoc: string;
	tipoDocApresentado: string;
	tipoEncaminhamento: string;
	tipoLiberacao: string;
	usuario: string;
	usuarioCentral: string;
	usuarioPosto: string;
	status: string;
	naoPagamentoMultaMes: string;
	naoPagamentoMultaAno: string;
	codigoRetorno: string;
	dataCentralEncerrado: string;
	dataEncerrado: string;
	dataGeracao: string;
	mensagemRetorno: string;
	naoPgtoNumeroCertidao: string;
	naoPgtoDataCertidao: string;
}

export interface RequestConsultaUsuarioPendente {
	identifUsuario: string;
	numeroPosto: string;
}

export interface RequestAtualizarAtendente {
	identifUsuario: string;
	numeroPosto: string;
}
