import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarOrgaoRequest } from './types';

// ACTIONS
import { cadastrarOrgaoSuccess, cadastrarOrgaoFailure } from './actions';

function* cadastrarOrgao(request: ReducerAction) {
	const { payload }: { payload: CadastrarOrgaoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU,
		`/orgaos`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarOrgaoSuccess(response));
	} else {
		yield put(cadastrarOrgaoFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_ORGAO, cadastrarOrgao)]);
