import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultarLocalidadesAssociadasRequest, Types } from './types';

// ACTIONS
import {
	consultarLocalidadesAssociadaSuccess,
	consultarLocalidadesAssociadaFailure,
} from './actions';

function* consultarLocalidadesAssociadas(request: ReducerAction) {
	const { payload }: { payload: ConsultarLocalidadesAssociadasRequest } =
		request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`localidades-associada/${payload.id}`,
	);

	if (response.status === 200) {
		yield put(
			consultarLocalidadesAssociadaSuccess({ response, type: payload.type }),
		);
	} else {
		yield put(consultarLocalidadesAssociadaFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_LOCALIDADES_ASSOCIADA_REQUEST,
		consultarLocalidadesAssociadas,
	),
]);
