import produce from 'immer';

// import { formatDate } from 'utils/genericFunctions';
import { Types, consultaTokenCnhDigital } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaTokenCnhDigital = {
	status: 0,
	data: null,
};

export default function consultaTokenCnhDigitalReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaTokenCnhDigital {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_TOKEN_CNH_DIGITAL_REQUEST: {
				break;
			}
			case Types.CONSULTA_TOKEN_CNH_DIGITAL_SUCCESS: {
				// if (action.payload.response.status === 200) {
				// 	draft.data = INITIAL_STATE.data;
				// 	draft.status = action.payload.response.status;
				// 	return;
				// }
				draft.data = action.payload.response.data;
				draft.status = action.payload.response.status;
				break;
			}
			case Types.CONSULTA_TOKEN_CNH_DIGITAL_FAILURE: {
				break;
			}
			case Types.CONSULTA_TOKEN_CNH_DIGITAL_CLEAR: {
				draft.status = INITIAL_STATE.status;
				break;
			}
			default:
		}
	});
}
