export enum Types {
	GERAR_PRE_CADASTRO = '@cnh/GERAR_PRE_CADASTRO',
	GERAR_PRE_CADASTRO_SUCCESS = '@cnh/GERAR_PRE_CADASTRO_SUCCESS',
	GERAR_PRE_CADASTRO_FAILURE = '@cnh/GERAR_PRE_CADASTRO_FAILURE',
}

export interface gerarPreCadastro {
	status: number;
	data: null | IDadosPrimeiraHabilitacao;
	dataRequest: null | IRequestGerarPreCadastro;
}

export interface IDadosPrimeiraHabilitacao {
	codigo: string;
	mensagem: string;
	cpf: string;
}

export interface IRequestGerarPreCadastro {
	cpf: string;
	autoriza_email: string;
	autoriza_sms: string;
	bairro: string;
	categoria_pretendida: string;
	celular: string;
	cep: string;
	codigo_entidade: string;
	complemento: string;
	data_nascimento: string;
	ddd_celular: string;
	ddd_telefone: string;
	email: string;
	logradouro: string;
	nacionalidade: string;
	naturalidade: string;
	necessidade_veiculo_adaptado: string;
	nome: string;
	nome_mae: string;
	nome_pai: string;
	numero_carteira_militar: string;
	numero_documento: string;
	numero: string;
	orgao_documento: string;
	pretende_atividade_remunerada: string;
	sexo: string;
	telefone: string;
	tipo_documento: string;
	uf_documento: string;
	num_RNE: string;
	orgao_RNE: string;
	uf_RNE: string;
	exame_dentro_do_posto?: string;
}
