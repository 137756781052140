export enum Types {
	ADD_NOTIFICATIONS = '@notifications/ADD_NOTIFICATIONS',
	CLEAR_NOTIFICATIONS = '@notifications/CLEAR_NOTIFICATIONS',
	SHOW_INSIDE = '@notifications/SHOW_INSIDE',
}

export interface NotificationsState {
	errors: string[] | string;
	showInside?: boolean;
	moveToTop?: boolean;
	notificationOrigin?: 'FORM_VALIDATE' | 'SERVICE_API' | 'SERVICE_SGU';
}
export interface NotificationsRequest {
	errors: string[] | string;
	moveToTop?: boolean;
	notificationOrigin?: 'FORM_VALIDATE' | 'SERVICE_API' | 'SERVICE_SGU';
}
