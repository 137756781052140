import React, { Suspense, useEffect, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';
import { multasRenainfOrgaoClear } from 'store/modules/api/detranCrv/MultasRenainf/multasRenainfOrgao/actions';
// import { multasRenainfPlacaClear } from 'store/modules/api/detranCrv/MultasRenainf/multasRenainfPlaca/actions';

// ROUTES
// import { useHistory } from 'react-router-dom';

const MultasRenainfPorOrgaoResultado: React.FC = () => {
	const dispatch = useDispatch();
	// const history = useHistory();

	const [dataResult, setDataResult] = useState<any>({});

	const { multasRenainfOrgao, multasRenainfPlaca } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.multasRenainf,
	);

	useEffect(() => {
		if (
			multasRenainfPlaca.data &&
			multasRenainfPlaca.data?.dadosRenainfPlaca.quantidadeOcorrencia === 1
		) {
			setDataResult(multasRenainfPlaca.data.dadosRenainfPlaca);
		} else if (multasRenainfOrgao.data) {
			setDataResult(multasRenainfOrgao.data?.dadosRenainfOrgao);
		}
	}, [multasRenainfPlaca.data, multasRenainfOrgao.data]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Row>
				<Col span={24}>
					<Section title="Consultar RENAINF por Orgão Autuador">
						<Section size="sm" title="DADOS DA NOTIFICAÇÃO">
							<Row gutter={[0, 10]}>
								<Col span={6}>Orgão Autuador</Col>
								<Col span={18}>
									<strong>{dataResult.orgaoAutuador}</strong>
								</Col>
								<Col span={6}>Cod. da Infração</Col>
								<Col span={18}>
									<strong>{dataResult.codigoInfracao}</strong>
								</Col>
								<Col span={6}>N° do Auto de Infração</Col>
								<Col span={18}>
									<strong>{dataResult.numeroAutoInfracao}</strong>
								</Col>
								<Col span={6}>UF do Orgão Autuador</Col>
								<Col span={6}>
									<strong>{dataResult.ufJurisdicaoVeiculo}</strong>
								</Col>
								<Col span={6}>Local da Infração</Col>
								<Col span={6}>
									<strong>{dataResult.localInfracao}</strong>
								</Col>
								<Col span={6}>Número da Placa</Col>
								<Col span={6}>
									<strong>{dataResult.placa}</strong>
								</Col>
								<Col span={6}>UF de Emplacamento</Col>
								<Col span={6}>
									<strong>{dataResult.ufEmplacamento}</strong>
								</Col>
								<Col span={6}>Data da Infração</Col>
								<Col span={6}>
									<strong>
										{dataResult.dataHoraInfracao &&
											new Date(dataResult.dataHoraInfracao).toLocaleDateString(
												'pt-BR',
											)}
									</strong>
								</Col>
								<Col span={6}>Hora da Infração</Col>
								<Col span={6}>
									<strong>
										{dataResult.dataHoraInfracao &&
											`${new Date(
												Number(dataResult.dataHoraInfracao),
											).toLocaleTimeString([], {
												hour: '2-digit',
												minute: '2-digit',
											})}`}
									</strong>
								</Col>
								<Col span={6}>Cadastramento Infração</Col>
								<Col span={6}>
									<strong>
										{dataResult.dataCadastroInfracao &&
											new Date(
												dataResult.dataCadastroInfracao,
											).toLocaleDateString('pt-BR')}
									</strong>
								</Col>
								<Col span={6}>Código Municipal Infração</Col>
								<Col span={6}>
									<strong>{dataResult.municipioInfracao?.codigo}</strong>
								</Col>
								<Col span={6}>Tipo Auto de Infração</Col>
								<Col span={6}>
									<strong>{dataResult.tipoInfracao}</strong>
								</Col>
								<Col span={6}>Medição Real</Col>
								<Col span={6}>
									<strong>
										{Intl.NumberFormat('en-IN', {
											style: 'decimal',
											minimumFractionDigits: 2,
										}).format(Number(dataResult.medicaoReal))}
									</strong>
								</Col>
								<Col span={6}>Limite Permitido</Col>
								<Col span={6}>
									<strong>
										{Intl.NumberFormat('en-IN', {
											style: 'decimal',
											minimumFractionDigits: 2,
										}).format(Number(dataResult.limitePermitido))}
									</strong>
								</Col>
								<Col span={6}>Medição Considerada</Col>
								<Col span={6}>
									<strong>
										{Intl.NumberFormat('en-IN', {
											style: 'decimal',
											minimumFractionDigits: 2,
										}).format(Number(dataResult.medicaoConsiderada))}
									</strong>
								</Col>
								<Col span={6}>Unidade de Medida</Col>
								<Col span={6}>
									<strong>{dataResult.unidadeMedida}</strong>
								</Col>
								<Col span={6}>Valor da Infração</Col>
								<Col span={6}>
									<strong>
										{Intl.NumberFormat('pt-BR', {
											style: 'decimal',
											minimumFractionDigits: 2,
										}).format(Number(dataResult.valorInfracao))}
									</strong>
								</Col>
								<Col span={6}>UF jurisdição do veículo</Col>
								<Col span={6}>
									<strong>{dataResult.ufJurisdicaoVeiculo}</strong>
								</Col>
								<Col span={6}>Cód. Municipal Emplacamento</Col>
								<Col span={6}>
									<strong>{dataResult.municipioEmplacamento?.codigo}</strong>
								</Col>
								<Col span={6}>Marca/Modelo do Veículo</Col>
								<Col span={6}>
									<strong>
										{dataResult.marca?.longId} -{dataResult.marca?.descricao}{' '}
									</strong>
								</Col>
								<Col span={12} />
								<Col span={6}>Datas de Emissões</Col>
								<Col span={6}>
									<strong>
										{dataResult.dataEmissao &&
											new Date(dataResult.dataEmissao).toLocaleDateString(
												'pt-BR',
											)}
									</strong>
								</Col>
								<Col span={6}>Emissão da Penalidade</Col>
								<Col span={6}>
									<strong>
										{dataResult.dataEmissaoPenalidade &&
											new Date(
												dataResult.dataEmissaoPenalidade,
											).toLocaleDateString('pt-BR')}
									</strong>
								</Col>
								<Col span={6}>Notificação da Infração</Col>
								<Col span={6}>
									<strong>
										{dataResult.dataNotificacaoInfracao &&
											new Date(
												dataResult.dataNotificacaoInfracao,
											).toLocaleDateString('pt-BR')}
									</strong>
								</Col>
							</Row>
						</Section>
						<Section size="sm" title="DADOS DO PAGAMENTO">
							<Row gutter={[0, 10]}>
								<Col span={6}>UF Pagamento</Col>
								<Col span={6}>
									<strong>{dataResult.ufPagamento}</strong>
								</Col>
								<Col span={6}>Data do Pagamento da Infração</Col>
								<Col span={6}>
									<strong>
										{dataResult.dataPagamentoInfracao &&
											new Date(
												dataResult.dataPagamentoInfracao,
											).toLocaleDateString('pt-BR')}
									</strong>
								</Col>
								<Col span={6}>Valor Pago</Col>
								<Col span={6}>
									<strong>
										{Intl.NumberFormat('en-IN', {
											style: 'decimal',
											minimumFractionDigits: 2,
										}).format(Number(dataResult.valorPago))}
									</strong>
								</Col>
								<Col span={6}>Data de Registro do Pagamento</Col>
								<Col span={6}>
									<strong>
										{dataResult.dataRegistroPagamento &&
											new Date(
												dataResult.dataRegistroPagamento,
											).toLocaleDateString('pt-BR')}
									</strong>
								</Col>
							</Row>
						</Section>
						<Section size="sm" title="DADOS DO INFRATOR / CONDUTOR">
							<Row gutter={[0, 10]}>
								<Col span={6}>N° da CNH do Infrator</Col>
								<Col span={6}>
									<strong>{dataResult.cnhInfrator}</strong>
								</Col>
								<Col span={6}>N° da CNH do Condutor</Col>
								<Col span={6}>
									<strong>{dataResult.cnhCondutor}</strong>
								</Col>
							</Row>
						</Section>
						<Section size="sm" title="DADOS DA SUSPENSÃO CANCELAMENTO">
							<Row gutter={[0, 10]}>
								<Col span={6}>Tipo</Col>
								<Col span={6}>
									<strong>{dataResult.tipoSuspensaoCancelamento}</strong>
								</Col>
								<Col span={6}>Data de Registro</Col>
								<Col span={6}>
									<strong>
										{dataResult.dataRegistroSuspensaoCancelamento &&
											new Date(
												dataResult.dataRegistroSuspensaoCancelamento,
											).toLocaleDateString('pt-BR')}
									</strong>
								</Col>
								<Col span={6}>Origem</Col>
								<Col span={6}>
									<strong>{dataResult.origemSuspensaoCancelamento}</strong>
								</Col>
								<Col span={6}>Aceite UF Jurisdição</Col>
								<Col span={6}>
									<strong>
										{dataResult.aceiteUfJurisdicaoSuspensaoCancelamento}
									</strong>
								</Col>
							</Row>
						</Section>
					</Section>
					<Row justify="space-around" align="middle">
						<Col>
							<ButtonVoltar
								onClick={() => {
									dispatch(multasRenainfOrgaoClear());
									// history.push('/detran-crv/multas-renainf-placa/resultado');
									// dispatch(multasRenainfPlacaClear());
								}}
							/>
						</Col>
						<Col>
							<Button className="no-print" onClick={() => window.print()}>
								{' '}
								Imprimir
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Suspense>
	);
};

export default MultasRenainfPorOrgaoResultado;
