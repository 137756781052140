// TYPES
import { ReducerAction } from 'store/modules/types';
import { ApiResponse } from 'services/_api';
import {
	Types,
	ConsultarConsistenciaBiometrica,
	ConsultarConsistenciaBiometricaRequest,
} from './types';

export function consultarConsistenciaBiometricaRequest(
	payload: ConsultarConsistenciaBiometricaRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_CONSISTENCIA_BIOMETRICA_REQUEST,
		payload,
	};
}
export function consultarConsistenciaBiometricaSuccess(
	payload: ConsultarConsistenciaBiometrica,
): ReducerAction {
	return {
		type: Types.CONSULTAR_CONSISTENCIA_BIOMETRICA_SUCCESS,
		payload,
	};
}
export function consultarConsistenciaBiometricaFailure(
	payload: ApiResponse,
): ReducerAction {
	return {
		type: Types.CONSULTAR_CONSISTENCIA_BIOMETRICA_FAILURE,
		payload,
	};
}
export function consultarConsistenciaBiometricaClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_CONSISTENCIA_BIOMETRICA_CLEAR,
		payload: null,
	};
}
