import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_RFB } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import { consultarCpfCinSuccess, consultarCpfCinFailure } from './actions';

function* consultarCpfCinRequest(request: ReducerAction) {
	const { payload }: { payload: string } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_RFB,
		`cin/${CHANNEL}/cpf/${payload}`,
		{},
		true,
	);

	if (response.status === 200) {
		yield put(consultarCpfCinSuccess(response.data));
	} else {
		yield put(consultarCpfCinFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_CIN_CPF_REQUEST, consultarCpfCinRequest),
]);
