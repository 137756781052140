export enum Types {
	CADASTRAR_SUFIXO = '@sgu/CADASTRAR_SUFIXO',
	CADASTRAR_SUFIXO_SUCCESS = '@sgu/CADASTRAR_SUFIXO_SUCCESS',
	CADASTRAR_SUFIXO_FAILURE = '@sgu/CADASTRAR_SUFIXO_FAILURE',
	CADASTRAR_SUFIXO_CLEAR = '@sgu/CADASTRAR_SUFIXO_CLEAR',
}

export interface CadastrarSufixo {
	status: number;
	data: null | CadastrarSufixoData;
}

export interface CadastrarSufixoData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarSufixoRequest {
	codigo?: number | string;
	descricao: string;
}
