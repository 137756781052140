import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { PATH_EDUCACAO_SEDUC_ALUNOS_API } from 'services/rotasp/path';
import { Types, ConsultaAlunoRequest } from './types';

// ACTIONS
import { consultaAlunoSuccess, consultaAlunoFailure } from './actions';

function* consultaAluno(request: ReducerAction) {
	const { token, payload }: { token?: string; payload: ConsultaAlunoRequest } =
		request;

	const statusAllowed = [200, 204];

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_EDUCACAO_SEDUC_ALUNOS_API,
		`alunos`,
		payload,
		undefined,
		undefined,
		undefined,
		undefined,
		statusAllowed,
	);

	if (response.status === 200 || response.status === 204) {
		yield put(consultaAlunoSuccess(response));
	} else {
		yield put(consultaAlunoFailure());
	}
}

export default all([takeLatest(Types.CONSULTA_ALUNO_REQUEST, consultaAluno)]);
