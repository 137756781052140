import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarParametroSistemaRequest } from './types';

export function atualizarParametroSistemaRequest(
	data: AtualizarParametroSistemaRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_PARAMETRO_SISTEMA,
		payload: data,
	};
}
export function atualizarParametroSistemaSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_PARAMETRO_SISTEMA_SUCCESS,
		payload,
	};
}
export function atualizarParametroSistemaFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_PARAMETRO_SISTEMA_FAILURE,
		payload: null,
	};
}
export function atualizarParametroSistemaClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_PARAMETRO_SISTEMA_CLEAR,
		payload: null,
	};
}
