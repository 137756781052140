import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types } from './types';
import {
	consultaCarroceriaSuccess,
	consultaCarroceriaFailure,
} from './actions';

function* consultaCarroceriaRequest() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consulta-carroceria`,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultaCarroceriaSuccess(response));
	} else if (response.error) {
		yield put(consultaCarroceriaFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTA_CARROCERIA_REQUEST, consultaCarroceriaRequest),
]);
