import produce from 'immer';

// // UTILS
// import { formatDate } from 'utils/genericFunctions';

// TYPES
import { Types, ConsultaCnhEstrangeiro } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultaCnhEstrangeiro = {
	status: 0,
	data: null,
	dataError: null,
	cpf: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaCnhEstrangeiro {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_CNH_ESTRANGEIRO_REQUEST: {
				draft.status = 100;
				break;
			}

			case Types.CONSULTA_CNH_ESTRANGEIRO_SUCCESS: {
				if (action.payload.response.status === 204) {
					draft.data = INITIAL_STATE.data;
					draft.dataError = INITIAL_STATE.dataError;
					draft.status = action.payload.response.status;
					draft.cpf = action.payload.request.cpf;
					return;
				}
				draft.dataError = INITIAL_STATE.dataError;
				draft.data = action.payload.response.data;
				draft.status = action.payload.response.status;
				draft.cpf = action.payload.request.cpf;
				break;
			}

			case Types.CONSULTA_CNH_ESTRANGEIRO_FAILURE: {
				draft.data = INITIAL_STATE.data;
				draft.dataError = action.payload.response.data;
				draft.status = action.payload.response.status;
				draft.cpf = action.payload.request.cpf;
				break;
			}

			case Types.CONSULTA_CNH_ESTRANGEIRO_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				draft.cpf = INITIAL_STATE.cpf;
				break;
			}

			default:
		}
	});
}
