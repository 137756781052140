import produce from 'immer';

// TYPES
import { Types, consultarDisponibilidadeMedicoPosto } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultarDisponibilidadeMedicoPosto = {
	status: 0,
	data: null,
};

export default function consultarDisponibilidadeMedicoPostoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultarDisponibilidadeMedicoPosto {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_SUCCESS: {
				draft.status = action.payload.response.status;
				draft.data = action.payload.response.data;
				break;
			}
			case Types.CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_FAILURE: {
				draft.status = 400;
				draft.data = action.payload.response;
				break;
			}
			case Types.CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
