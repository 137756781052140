import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestWithHeaderLocalidadeDelete } from './types';

// ACTIONS
import { excluirLocalidadeSuccess, excluirLocalidadeFailure } from './actions';

function* excluirLocalidade(request: ReducerAction) {
	const { payload }: { payload: IRequestWithHeaderLocalidadeDelete } = request;

	const { body } = { ...payload };

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_SGU,
		`localidades/${body.id}`,
	);

	if (response.status === 200) {
		yield put(excluirLocalidadeSuccess(response));
	} else {
		yield put(excluirLocalidadeFailure());
	}
}

export default all([takeLatest(Types.EXCLUIR_LOCALIDADE, excluirLocalidade)]);
