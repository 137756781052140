import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_CARROCERIA_REQUEST = '@DETRAN-CRV/CONSULTA_CARROCERIA_REQUEST',
	CONSULTA_CARROCERIA_SUCCESS = '@DETRAN-CRV/CONSULTA_CARROCERIA_SUCCESS',
	CONSULTA_CARROCERIA_FAILURE = '@DETRAN-CRV/CONSULTA_CARROCERIA_FAILURE',
}

export interface consultaCarroceria {
	status: number;
	data: OptionProps[];
}
