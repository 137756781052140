import React, { useEffect, useState } from 'react';

import { Steps, Modal, Row, Col } from 'antd';
import {
	DeliveredProcedureOutlined,
	IdcardOutlined,
	LoadingOutlined,
	LockOutlined,
	SafetyCertificateOutlined,
	UnlockOutlined,
} from '@ant-design/icons';
import LightButton from 'components/Common/Buttons/Light';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import { setLoader } from 'store/modules/app/loader/actions';
import { addNotifications } from 'store/modules/app/notifications/actions';
import { changeUrlRequest } from 'store/modules/api/eCpf/changeUrl/actions';
import CertificadoDigitalIframe from './iframe';

interface Steps {
	types: 'wait' | 'finish' | 'process';
}

interface Props {
	certificateVisible: boolean;
	setCertificateVisible: (v: boolean) => void;
	setCertificateValid: (v: boolean) => void;
	startECpf: boolean;
}

const CertificadoDigital: React.FC<Props> = ({
	certificateVisible,
	setCertificateVisible,
	setCertificateValid,
	startECpf,
}) => {
	const dispatch = useDispatch();
	const { Step } = Steps;

	const { getECpfValidation } = useSelector(
		(state: ApplicationState) => state.api.eCpf,
	);

	const [eCpfModalMessage, setECpfModalMessage] = useState<string>(
		'Será feita a consulta do E-CPF agora. Por favor aguarde.',
	);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const [consultaCpf, setConsultaCpf] = useState<any>({});

	const [eCpfSteps, setECpfSteps] = useState<{
		step1: Steps['types'];
		step2: Steps['types'];
		step3: Steps['types'];
		step4: Steps['types'];
	}>({
		step1: 'finish',
		step2: 'wait',
		step3: 'wait',
		step4: 'wait',
	});

	useEffect(() => {
		if (!(window.location.href.indexOf('www.poupatempo4g.sp.gov.br/') !== -1)) {
			setECpfSteps({
				step1: 'wait',
				step2: 'wait',
				step3: 'wait',
				step4: 'wait',
			});
		}
	}, []);

	// VALIDAÇÃO E-CPF
	useEffect(() => {
		if (
			startECpf &&
			eCpfSteps.step2 === 'wait' &&
			eCpfSteps.step1 === 'finish'
		) {
			setECpfSteps({ ...eCpfSteps, step2: 'process' });
		}
	}, [eCpfSteps, startECpf]);

	useEffect(() => {
		window.addEventListener('message', e => {
			setConsultaCpf(e.data);
		});
	}, []);

	useEffect(() => {
		if (eCpfSteps.step2 === 'process') {
			setECpfSteps({ ...eCpfSteps, step2: 'finish', step3: 'process' });
		}
	}, [dispatch, eCpfSteps, login.user.cpf]);

	useEffect(() => {
		if (
			consultaCpf.isValido &&
			eCpfSteps.step3 === 'process' &&
			eCpfSteps.step4 === 'wait'
		) {
			setECpfSteps({ ...eCpfSteps, step3: 'finish', step4: 'finish' });
			setECpfModalMessage(
				'Acesso Conferido e autorizado. Você será redirecionado em breve.',
			);
			changeUrlRequest();
			setCertificateValid(true);
		} else if (
			consultaCpf.codigo &&
			consultaCpf.codigo === 400 &&
			eCpfSteps.step3 === 'process' &&
			eCpfSteps.step4 === 'wait'
		) {
			setECpfSteps({
				step1: 'finish',
				step2: 'wait',
				step3: 'wait',
				step4: 'wait',
			});
			let notifications: Array<string | undefined> = [];

			if (consultaCpf.mensagens) {
				notifications = [...consultaCpf.mensagens];
			}
			addNotifications({
				errors: [consultaCpf.mensagem, ...notifications],
			});
			setCertificateVisible(false);
			setLoader(false);
		}
	}, [consultaCpf, eCpfSteps, setCertificateValid, setCertificateVisible]);

	return (
		<Modal
			title="Validação do E-CPF."
			centered
			visible={certificateVisible}
			closable={false}
			onOk={() => {
				setCertificateVisible(false);
			}}
			onCancel={() => setCertificateVisible(false)}
			width={1000}
		>
			<Row style={{ marginTop: '20px' }} justify="center" gutter={[20, 20]}>
				<h3>{eCpfModalMessage}</h3>
			</Row>
			<Steps style={{ margin: '50px 0' }}>
				<Step
					status={eCpfSteps.step1}
					title="Aviso"
					icon={<IdcardOutlined />}
				/>
				<Step
					status={eCpfSteps.step2}
					title="Validação E-CPF"
					icon={
						eCpfSteps.step2 === 'process' ? (
							<LoadingOutlined />
						) : (
							<SafetyCertificateOutlined />
						)
					}
				/>
				<Step
					status={eCpfSteps.step3}
					title="Consultando Dados"
					icon={
						eCpfSteps.step3 === 'process' ? (
							<LoadingOutlined />
						) : (
							<DeliveredProcedureOutlined />
						)
					}
				/>
				<Step
					status={eCpfSteps.step4}
					title="Liberação"
					icon={
						getECpfValidation.status === 0 ||
						getECpfValidation.status === 400 ? (
							<LockOutlined
								style={{
									color: getECpfValidation.status === 400 ? 'red' : 'unset',
								}}
							/>
						) : (
							<UnlockOutlined style={{ color: 'green' }} />
						)
					}
				/>
			</Steps>
			{!(
				window.location.href.indexOf('www.poupatempo4g.sp.gov.br/') !== -1
			) && (
				<Row gutter={[0, 0]} align="top" justify="start">
					<Col className="gutter-row" span="12">
						<LightButton
							type="button"
							buttonColor="default"
							text="Pular Certificado"
							icon="back"
							buttonSize="sm"
							onClick={() => {
								changeUrlRequest();
								setCertificateValid(true);
								setCertificateVisible(false);
							}}
						/>
					</Col>
					<Col className="gutter-row" span="12">
						<Row align="top" justify="end">
							<LightButton
								type="button"
								buttonColor="default"
								text="Testar Certificado"
								buttonSize="sm"
								onClick={() => setECpfSteps({ ...eCpfSteps, step1: 'finish' })}
							/>
						</Row>
					</Col>
				</Row>
			)}

			{eCpfSteps.step1 === 'finish' && <CertificadoDigitalIframe />}
		</Modal>
	);
};

export default CertificadoDigital;
