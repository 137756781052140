import { combineReducers } from 'redux';

import pepm from './pepm/reducer';
import pgat from './pgat/reducer';
import pcov from './pcov/reducer';
import txut from './txut/reducer';
import ptres from './ptres/reducer';
import ptreoe from './ptreoe/reducer';
import pebe from './pebe/reducer';

export default combineReducers({
	pepm,
	pgat,
	pcov,
	txut,
	ptres,
	ptreoe,
	pebe,
});
