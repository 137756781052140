import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types } from './types';
import { consultaCategoriaSuccess, consultaCategoriaFailure } from './actions';

function* consultaCategoriaRequest() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consulta-categoria`,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultaCategoriaSuccess(response));
	} else if (response.error) {
		yield put(consultaCategoriaFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTA_CATEGORIA_REQUEST, consultaCategoriaRequest),
]);
