// TYPES
import { ReducerAction } from 'store/modules/types';
import { RequestEncerrarAtendimento, Types } from './types';

export function encerrarAtendimentoRequest(
	payload: RequestEncerrarAtendimento,
): ReducerAction {
	return {
		type: Types.ENCERRAR_ATENDIMENTO_REQUEST,
		payload,
	};
}

export function encerrarAtendimentoSuccess(payload: {
	mensagemRetorno: string;
}): ReducerAction {
	return {
		type: Types.ENCERRAR_ATENDIMENTO_SUCCESS,
		payload,
	};
}

export function encerrarAtendimentoFailure(): ReducerAction {
	return {
		type: Types.ENCERRAR_ATENDIMENTO_FAILURE,
		payload: null,
	};
}

export function encerrarAtendimentoClear(): ReducerAction {
	return {
		type: Types.ENCERRAR_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
