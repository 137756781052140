import React from 'react';
import { useLocation } from 'react-router-dom';
import CadastroGeradoCnhDigital from '../CadastroGerado';
import { GerarSenhaRequest } from '../StatusCondutor/form';

const CadastroCnhDigital: React.FC = () => {
	const { state } = useLocation<GerarSenhaRequest>();

	return <CadastroGeradoCnhDigital requestData={state} />;
};
export default CadastroCnhDigital;
