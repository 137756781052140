import produce from 'immer';

// UTILS
import { toSelect } from 'utils/genericFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, Estados } from './types';

export const INITIAL_STATE: Estados = {
	status: 0,
	data: [],
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): Estados {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ESTADOS_REQUEST: {
				break;
			}
			case Types.ESTADOS_SUCCESS: {
				draft.status = action.payload.status;
				const select = toSelect(
					action.payload.data.dominio,
					'descricao',
					'codigo',
				);
				draft.data = select;
				break;
			}
			case Types.ESTADOS_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.ESTADOS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
