import React from 'react';

import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

import {
	ConfirmacaoServicoDesbloqueio,
	FormOwnerData,
} from 'pages/DetranCrv/components/pages/ServicosDeVeiculos/ConfirmacaoServicoDesbloqueio';

import { formatDateISOWithOutUTC } from 'utils/genericFunctions';

const BloqueioDiversosCategoriaAluguelConfirmacao = () => {
	const { registraSolicitacoes } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const formOwnerData: FormOwnerData = {
		typeService: 'DESB_CAT_ALUGUEL',
		plate:
			registraSolicitacoes?.requestData?.request?.placa?.toString().trim() ||
			'',
		cpfcnpj:
			registraSolicitacoes?.requestData?.request?.cpfCnpj?.toString().trim() ||
			'',
		renavam:
			registraSolicitacoes?.requestData?.request?.renavam?.toString().trim() ||
			'',
		requestDate:
			(registraSolicitacoes?.requestData?.requestDate &&
				formatDateISOWithOutUTC(
					registraSolicitacoes.requestData.requestDate.toString().trim(),
					true,
				)) ||
			'',
		formYear: registraSolicitacoes?.responseData?.ano?.toString().trim() || '',
		formNumber:
			registraSolicitacoes?.responseData?.ficha?.toString().trim() || '',
	};

	return <ConfirmacaoServicoDesbloqueio formOwnerData={formOwnerData} />;
};

export default BloqueioDiversosCategoriaAluguelConfirmacao;
