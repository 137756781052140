// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	IConsultarRelacaoUsuario,
	IConsultarRelacaoUsuarioRequest,
	Types,
} from './types';

export function consultarRelacaoUsuarioRequest(
	payload: IConsultarRelacaoUsuarioRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_RELACAO_USUARIO_REQUEST,
		payload,
	};
}

export function consultaRelacaoUsuarioSuccess(
	status: number,
	data: IConsultarRelacaoUsuario[] | null,
): ReducerAction {
	return {
		type: Types.CONSULTAR_RELACAO_USUARIO_SUCCESS,
		payload: {
			status,
			data,
		},
	};
}

export function consultarRelacaoUsuarioClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_RELACAO_USUARIO_CLEAR,
		payload: null,
	};
}

export function consultarRelacaoUsuarioFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_RELACAO_USUARIO_FAILURE,
		payload: null,
	};
}
