export enum Types {
	CONSULTA_CONDUTOR_REQUEST = '@cnh/CONSULTA_CONDUTOR_REQUEST',
	CONSULTA_CONDUTOR_SUCCESS = '@cnh/CONSULTA_CONDUTOR_SUCCESS',
	CONSULTA_CONDUTOR_FAILURE = '@cnh/CONSULTA_CONDUTOR_FAILURE',
	CONSULTA_CONDUTOR_CLEAR = '@cnh/CONSULTA_CONDUTOR_CLEAR',
}

export interface ConsultaCondutor {
	status: number;
	data: null | ConsultaCondutorResponse;
}

export interface ConsultaCondutorResponse {
	acuidadeAuditiva: string;
	acuidadeVisualAmbosOlhos: string;
	acuidadeVisualOD: string;
	acuidadeVisualODComplemento: string;
	acuidadeVisualOE: string;
	acuidadeVisualOEComplemento: string;
	bairro: string;
	cadastroBinco: string;
	campoVisualOD: string;
	campoVisualOE: string;
	categoriaAtual: string;
	categoriaObtidaMedico: string;
	categoriaPretendida: string;
	celular: string;
	cep: string;
	ciretranDomicilio: string;
	ciretranPoupaTempoExame: string;
	cnhBloqueada: string;
	cnhDigital: string;
	cnpj: string;
	codigoEntidade: string;
	codigoInterno: string;
	codigoPoupatempo: string;
	codigoVerificador: string;
	complemento: string;
	coordenacaoBimanual: string;
	cpf: string;
	credenciadoMedico: string;
	credenciadoPsicologo: string;
	crm: string;
	crmAux: string;
	crmCrpAnterior: string;
	crp: string;
	crpAux: string;
	dataCadastramento: string;
	dataEmissaoCnh: string;
	dataExameMedico: string;
	dataExamePratico: string;
	dataExamePsicotecnico: string;
	dataExameTeorico: string;
	dataExameToxicologico: string;
	dataFimCassacao: string;
	dataInicioCassacao: string;
	dataNascimento: string;
	dataPrimeiraHabilitacao: string;
	dataTerminoBloqueio: string;
	dddCelular: string;
	descricaoMunicipio: string;
	descricaoNaturalidade: string;
	dinamometriaMaoDireita: string;
	dinamometriaMaoEsquerda: string;
	disturbioSono: string;
	email: string;
	inaptoProcessoAnterior: string;
	juntaMedica: string;
	juntaPsicologica: string;
	laudo: string;
	laudoToxicologico: string;
	logradouro: string;
	message: string;
	municipio: string;
	nacionalidade: string;
	naturalidade: string;
	necessidadeVeiculoAdaptado: string;
	nivelMental: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	nomeSocial: string;
	nomeSocialValidadoIIRGD: string;
	numCNHE: string;
	numCarteiraMilitar: string;
	numDocumento: string;
	numPGU: string;
	numRNE: string;
	numRegistro: string;
	numero: string;
	observacaoDeRestricao: string;
	ofuscamento: string;
	orgaoDocumento: string;
	orgaoRNE: string;
	origem: string;
	paisAfetivos1: string;
	paisAfetivos1ValidadoBiometria: string;
	paisAfetivos2: string;
	paisAfetivos2ValidadoBiometria: string;
	paisCNHE: string;
	permiteReavaliacaoMedico: string;
	permiteReavaliacaoPsicologo: string;
	personalidade: string;
	pressaoArterialMaxima: string;
	pressaoArterialMinima: string;
	pretendeAtividadeRemunerada: string;
	renach: string;
	restricaoMedica: string;
	restricoesMedicas: string;
	resultadoExameMedico: string;
	resultadoExamePsicotecnico: string;
	reversadoRestricoesMedicas: string;
	sensoCromatico: string;
	sexo: string;
	situacaoCursoReciclagem: string;
	situacaoExamePratico: string;
	situacaoExameTeorico: string;
	tipoDocumento: string;
	tipoProcesso: string;
	ufDocumento: string;
	ufMedico: string;
	ufPsicologo: string;
	ufRNE: string;
	usoFormulario: string;
	validadeCnhE: string;
	validadeExameMedico: string;
	validadeExamePsicotecnico: string;
	visaoNoturna: string;
}

export interface ConsultaCondutorRequest {
	cpf: string;
}
