import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirParametroLocalidadeRequest } from './types';

export function excluirParametroLocalidadeRequest(
	data: ExcluirParametroLocalidadeRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_PARAMETRO_LOCALIDADE,
		payload: data,
	};
}
export function excluirParametroLocalidadeSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.EXCLUIR_PARAMETRO_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function excluirParametroLocalidadeFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_PARAMETRO_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function excluirParametroLocalidadeClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_PARAMETRO_LOCALIDADE_CLEAR,
		payload: null,
	};
}
