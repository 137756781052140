import { ReducerAction } from 'store/modules/types';

import { Types, RequestConsultaNomeSocial } from './types';

export function consultaNomeSociallRequest(
	data: RequestConsultaNomeSocial,
): ReducerAction {
	return {
		type: Types.CONSULTA_NOME_SOCIAL_REQUEST,
		payload: data,
	};
}
export function consultaNomeSocialSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_NOME_SOCIAL_SUCCESS,
		payload,
	};
}
export function consultaNomeSocialFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_NOME_SOCIAL_FAILURE,
		payload,
	};
}
export function consultaNomeSocialClear(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_NOME_SOCIAL_CLEAR,
		payload,
	};
}
