import { ReducerAction } from 'store/modules/types';

import { Types, EventoConfrontoSessaoRequest } from './types';

export function eventoConfrontoSessaoRequest(
	data: EventoConfrontoSessaoRequest,
): ReducerAction {
	return {
		type: Types.EVENTO_CONFRONTO_SESSAO_REQUEST,
		payload: data,
	};
}
export function eventoConfrontoSessaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.EVENTO_CONFRONTO_SESSAO_SUCCESS,
		payload,
	};
}
export function eventoConfrontoSessaoFailure(payload: object): ReducerAction {
	return {
		type: Types.EVENTO_CONFRONTO_SESSAO_FAILURE,
		payload,
	};
}
export function eventoConfrontoSessaoClear(): ReducerAction {
	return {
		type: Types.EVENTO_CONFRONTO_SESSAO_CLEAR,
		payload: null,
	};
}
