import { ReducerAction } from 'store/modules/types';

import { Types, ConsultarStatusRequest } from './types';

export function consultarStatusRequest(
	data: ConsultarStatusRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_STATUS,
		payload: data,
	};
}
export function consultarStatusSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_STATUS_SUCCESS,
		payload,
	};
}
export function consultarStatusFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_STATUS_FAILURE,
		payload: null,
	};
}
export function consultarStatusClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_STATUS_CLEAR,
		payload: null,
	};
}
