export enum Types {
	CONSULTAR_CONDUTOR_REQUEST = '@cnh/CONSULTAR_CONDUTOR_REQUEST',
	CONSULTAR_CONDUTOR_SUCCESS = '@cnh/CONSULTAR_CONDUTOR_SUCCESS',
	CONSULTAR_CONDUTOR_FAILURE = '@cnh/CONSULTAR_CONDUTOR_FAILURE',
	CONSULTAR_CONDUTOR_CLEAR = '@cnh/CONSULTAR_CONDUTOR_CLEAR',
}

export interface ConsultarCondutor {
	status: number;
	data: null | ConsultarCondutorData;
}

export interface ConsultarCondutorData {
	cpf: string;
	uf: string;
	numRenachGerado: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	nomeSocial: string;
	dataNascimento: string;
	sexo: string;
	nacionalidade: string;
	naturalidade: string;
	logradouro: string;
	logradouroNumero: string;
	logradouroComplemento: string;
	logradouroBairro: string;
	codigoMunicipio: string;
	logradouroCep: string;
	tipoDocumento: string;
	numDocumento: string;
	orgaoExpDocumento: string;
	orgaoUfExpDocumento: string;
	numeroRegistro: string;
	pgu: string;
	categoriaAtualDuasRodas: string;
	categoriaAtualQuatroRodas: string;
	dataPrimeiraHabilitacao: string;
	dataValidadeCNH: string;
	dataExameMedico: string;
	categoriaAvaliadaPeloMedicoDuasRodas: string;
	categoriaAvaliadaPeloMedicoQuatroRodas: string;
	resultadoExameMedico: string;
	restricaoMedica: string;
	dataValidadeExameMedico: string;
	crm: string;
	regiaoMedico: string;
	dataExamePsicologico: string;
	resultadoExamePsicologico: string;
	numeroLaudo: string;
	dataValidadeExamePsicologico: string;
	crp: string;
	indicadorBloqueio: string;
	indicadorCnhVencida: string;
	indicadorCondutorPossuiPontuacao: string;
	indicadorCondutorPossuiTaxaEmissaoPaga: string;
	indicadorCondutorPossuiPendenciasBCA: string;
	indicadorCondutorPossuiProcessoEmAberto: string;
	indicadorCondutorProcuradoJudicialmente: string;
	codigoCiretranCondutor: string;
	rneNumero: string;
	rneOrgaoEmissor: string;
	rneUfOrgaoEmissor: string;
	codigoPaisChnEstrangeira: string;
	dataValidadeCnhEstrangeira: string;
	identificacaoCnhEstrangeira: string;
	zeros: string;
	crmMedicoInapto: string;
	cpfMedicoInapto: string;
	crpPsicologoInapto: string;
	cpfPsicologoInapto: string;
	email: string;
	celularDDD: string;
	celular: string;
	codVerificador: string;
	paisAfetivos1: string;
	paisAfetivos2: string;
	brancos: string;
	dataInicioCassacao: string;
	dataFimCassacao: string;
	dataInicioSuspensao: string;
	dataFimSuspensao: string;
	dataInicioCrimeTransito: string;
	dataFimCrimeTransito: string;
	dataTerminoPermissao: string;
	flagSolicitaCNHDigital: true;
	flagCondutorCassado: true;
	flagCondutorSuspensao: true;
	flagCondutorCrimeTransito: true;
	flagEmitiuCnhDefinitiva: true;
	flagPossuiPontuacaoPeriodoPermissao: true;
}

export interface ConsultarCondutorRequest {
	cpf: string;
	pgu?: string | null;
	tipoProcesso?: string | null;
	cpfUsuarioEfetuouTransacao?: string | null;
	codCiretran?: string | null;
}
