import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	PARTICIPACOES_AUDIENCIA_REQUEST = '@procon/PARTICIPACOES_AUDIENCIA_REQUEST',
	PARTICIPACOES_AUDIENCIA_SUCCESS = '@procon/PARTICIPACOES_AUDIENCIA_SUCCESS',
	PARTICIPACOES_AUDIENCIA_FAILURE = '@procon/PARTICIPACOES_AUDIENCIA_FAILURE',
	PARTICIPACOES_AUDIENCIA_CLEAR = '@procon/PARTICIPACOES_AUDIENCIA_CLEAR',
}

export interface ParticipacoesAudiencia {
	status: null | number;
	data: OptionProps[];
}
