import axios, { AxiosError } from 'axios';

import handleFailed from 'utils/getValidations';

interface IRequests {
	onSuccess: (token: string) => void;
	onFailure: (err: AxiosError) => void;
}

interface ApiResponse {
	status: number;
	data?: any;
	error?: boolean;
	message?: any;
}

export async function getApiCDHUFinanceiro(
	project: string,
	token: string,
): Promise<ApiResponse> {
	try {
		const response = await axios.get(`${project}`, {
			headers: {
				authorization: `Bearer ${token}`,
			},
		});

		return response;
	} catch (error) {
		const errorResponse = await handleFailed(error);

		return { status: 0, error: true, message: errorResponse };
	}
}
