import { takeLatest, put, all, call } from 'redux-saga/effects';
import { setLoader } from 'store/modules/app/loader/actions';

// SERVICES
import { ApiResponse as ApiResponseRotasp, postApi } from 'services/rotasp/api';
import { CHANNEL, PATH_CDHU } from 'services/_path';
import { ApiResponse, getApi } from 'services/_api';
import { PATH_CDHU_MUTUARIOS_API } from 'services/rotasp/path';

// UTILS
import { treatObjNullValues } from 'utils/genericFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { getApiCDHUFinanceiro } from 'services/apiCDHU';
import { Types, GerarOcorrenciaData, GerarOcorrenciaRequest } from './types';

// ACTIONS
import {
	gerarOcorrenciaSuccess,
	gerarOcorrenciaFailure,
	gerarOcorrenciaClear,
} from './actions';

function* gerarOcorrenciaRequest(request: ReducerAction) {
	const { token, payload }: { token?: string; payload: GerarOcorrenciaRequest } = request;
	
	yield put(gerarOcorrenciaClear());

	const responseOcorrencia: ApiResponseRotasp = yield call(
		postApi,
		token || '',
		PATH_CDHU_MUTUARIOS_API,
		`ocorrencias`,
		payload,
	);

	const responseToken: ApiResponse = yield call(
		getApi,
		PATH_CDHU,
		`${CHANNEL}/token`,
	);
	
	const baseURL = responseToken.data.baseUrl
	const tokenFinanceiro = responseToken.data.token

	// setTimeout(() => {
	// 	setLoader(true);
	// }, 1050);

	const response: ApiResponse[] = yield all([
		getApiCDHUFinanceiro(
			`${baseURL}/api/DadosFinanceiros/ObterComponentesPrestacao?Contrato=${payload.numeroContrato}`,
			tokenFinanceiro,
		),
		getApiCDHUFinanceiro(
			`${baseURL}/api/DadosFinanceiros/ObterFichaFinanceira?Contrato=${payload.numeroContrato}`,
			tokenFinanceiro,
		),
		getApiCDHUFinanceiro(
			`${baseURL}/api/DadosFinanceiros/ObterSituacaoGeralDasPrestacoes?Contrato=${payload.numeroContrato}`,
			tokenFinanceiro,
		),
		getApiCDHUFinanceiro(
			`${baseURL}/api/DadosFinanceiros/ObterDebitos?Contrato=${payload.numeroContrato}`,
			tokenFinanceiro,
		),
	]);

	const financeiro = {
		componentePrestacao: response[0]?.data?.base64Api,
		fichaFinanceira: response[1]?.data?.base64Api,
		situacaoGeraldaPrestacao: response[2]?.data?.base64Api,
		debitos: response[3]?.data?.base64Api,
	};

	setLoader(false);

	if (responseOcorrencia.status === 201) {
		const data = treatObjNullValues(
			responseOcorrencia.data,
		) as GerarOcorrenciaData;

		yield put(gerarOcorrenciaSuccess({ ...data, ...financeiro }, payload));
	} else {
		yield put(gerarOcorrenciaFailure());
	}
}

export default all([
	takeLatest(Types.GERAR_OCORRENCIA_REQUEST, gerarOcorrenciaRequest),
]);
