import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types } from './types';
import {
	multasRenainfPlacaSuccess,
	multasRenainfPlacaFailure,
} from './actions';

function* multasRenainfPlacaRequest({ payload }: any) {
	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consulta-renainf-placa`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(multasRenainfPlacaSuccess(response));
	} else if (response.error) {
		yield put(multasRenainfPlacaFailure());
	}
}

export default all([
	takeLatest(Types.MULTAS_RENAINF_PLACA_REQUEST, multasRenainfPlacaRequest),
]);
