/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Field, FormikProps } from 'formik';
import { Col, Row, Table } from 'antd';

import CheckBox from 'components/Common/Form/Checkbox';

import hasError from 'utils/getFormErrors';
import FormBox from 'components/Common/Form/FormBox';
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';

import dataEnum from 'store/modules/api/educacaoUpdate/enum';

import { IFichaAluno } from '../form';

import { ContainerDeficiencia, Content } from './styled';

interface Props {
	formik: FormikProps<IFichaAluno>;
}

const Deficiencia: React.FC<Props> = ({ formik }) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

	const selectRow = (record: { key: any; name?: string; codigo: number }) => {
		const selectedRowKeysNow = [...selectedRowKeys];
		if (selectedRowKeysNow.indexOf(record.key) >= 0) {
			selectedRowKeysNow.splice(selectedRowKeysNow.indexOf(record.key), 1);
		} else {
			selectedRowKeysNow.push(record.key);
		}
		setSelectedRowKeys(selectedRowKeysNow);
	};

	const onSelectedRowKeysChange = (
		selectedRowKeysNow: React.SetStateAction<any[]>,
	) => {
		setSelectedRowKeys(selectedRowKeysNow);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectedRowKeysChange,
	};

	useEffect(() => {
		if (
			formik.values.deficiencia.codigoNecessidade !== undefined &&
			formik.values.deficiencia.codigoNecessidade !== ''
		) {
			formik.setFieldValue('deficiencia.possuiNecessidades', true);
		} else {
			formik.setFieldValue('deficiencia.possuiNecessidades', false);
		}
	}, [formik.values.deficiencia.codigoNecessidade]);

	const dataSource = [
		{
			key: '1',
			name: 'MULTIPLA',
			codigo: 1,
		},
		{
			key: '2',
			name: 'CEGUEIRA',
			codigo: 2,
		},
		{
			key: '3',
			name: 'BAIXA VISAO',
			codigo: 3,
		},
		{
			key: '4',
			name: '	SURDEZ SEVERA OU PROFUNDA',
			codigo: 4,
		},
		{
			key: '5',
			name: 'SURDEZ LEVE OU MODERADA',
			codigo: 5,
		},
		{
			key: '6',
			name: 'SURDOCEGUEIRA',
			codigo: 6,
		},
		{
			key: '7',
			name: 'FISICA - PARALISIA CEREBRAL',
			codigo: 7,
		},
		{
			key: '8',
			name: 'FISICA - CADEIRANTE',
			codigo: 8,
		},
		{
			key: '9',
			name: 'FISICA - OUTROS',
			codigo: 9,
		},
		{
			key: '10',
			name: 'SINDROME DE DOWN',
			codigo: 10,
		},
		{
			key: '11',
			name: 'INTELECTUAL',
			codigo: 11,
		},
		{
			key: '12',
			name: 'AUTISTA INFANTIL',
			codigo: 20,
		},
		{
			key: '13',
			name: 'SINDROME DE ASPERGER',
			codigo: 21,
		},
		{
			key: '14',
			name: 'SINDROME DE RETT',
			codigo: 22,
		},
		{
			key: '15',
			name: 'TRANSTORNO DESINTEGRATIVO DA INFANCIA',
			codigo: 23,
		},
		{
			key: '16',
			name: 'ALTAS HABILIDADES/SUPERDOTACAO',
			codigo: 30,
		},
	];

	useEffect(() => {
		const selectedRowKeysNow: string[] = [];
		if (formik.values.necessidadesEspeciais !== undefined) {
			const dataSelected = formik.values.necessidadesEspeciais.map(item => {
				return item.codigo;
			});

			dataSource.map(item => {
				dataSelected.map(codigoNecessidadeEspecial => {
					if (item.codigo === codigoNecessidadeEspecial) {
						selectedRowKeysNow.push(item.key);
					}
				});
			});
			setSelectedRowKeys(selectedRowKeysNow);
		}
	}, [formik.values.necessidadesEspeciais]);

	useEffect(() => {
		const necessidadeEspeciais = selectedRowKeys.map((index: number) => {
			return dataSource[index - 1].codigo;
		});
		formik.setFieldValue(
			'deficiencia.codigoNecessidade',
			necessidadeEspeciais.join(';'),
		);
	}, [selectedRowKeys]);

	const columns = [
		{
			title: 'Necessidades Especiais Disponíveis',
			dataIndex: 'name',
		},
	];

	return (
		<>
			<FormBox title="Deficiência">
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={CheckBox}
							subtitle="Aluno Possui Necessidade Educacional Especial"
							name="deficiencia.possuiNecessidades"
							checked={formik.values.deficiencia.possuiNecessidades}
							error={hasError(formik.errors, 'deficiencia.possuiNecessidades')}
						/>
					</Col>
				</Row>
				{formik.values.deficiencia.possuiNecessidades && (
					<>
						<ContainerDeficiencia>
							<Row gutter={[0, 10]}>
								<Col span={24}>
									<Table
										rowSelection={rowSelection}
										columns={columns}
										dataSource={dataSource}
										pagination={false}
										onRow={record => ({
											onClick: () => {
												selectRow(record);
											},
										})}
									/>
								</Col>
							</Row>
						</ContainerDeficiencia>
						<Content>
							<Section title="Recurso Avaliação">
								<Row gutter={[0, 10]}>
									<Col span={8}>
										<Field
											as={CheckBox}
											subtitle="Auxílio Leitor"
											name="recursoAvaliacao.auxilioLeitor"
											checked={formik.values.recursoAvaliacao?.auxilioLeitor}
											error={hasError(
												formik.errors,
												'recursoAvaliacao.auxilioLeitor',
											)}
										/>
									</Col>

									<Col span={8}>
										<Field
											as={CheckBox}
											subtitle="Auxílio Transcrição"
											name="recursoAvaliacao.auxilioTranscricao"
											checked={
												formik.values.recursoAvaliacao?.auxilioTranscricao
											}
											error={hasError(
												formik.errors,
												'recursoAvaliacao.auxilioTranscricao',
											)}
										/>
									</Col>

									<Col span={8}>
										<Field
											as={CheckBox}
											subtitle="Guia / Intérprete"
											name="recursoAvaliacao.guiaInterprete"
											checked={formik.values.recursoAvaliacao?.guiaInterprete}
											error={hasError(
												formik.errors,
												'recursoAvaliacao.guiaInterprete',
											)}
										/>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={8}>
										<Field
											as={CheckBox}
											subtitle="Intérprete Libras"
											name="recursoAvaliacao.interpreteLibras"
											checked={formik.values.recursoAvaliacao?.interpreteLibras}
											error={hasError(
												formik.errors,
												'recursoAvaliacao.interpreteLibras',
											)}
										/>
									</Col>

									<Col span={8}>
										<Field
											as={CheckBox}
											subtitle="Leitura Labial"
											name="recursoAvaliacao.leituraLabial"
											checked={formik.values.recursoAvaliacao?.leituraLabial}
											error={hasError(
												formik.errors,
												'recursoAvaliacao.leituraLabial',
											)}
										/>
									</Col>

									<Col span={8}>
										<Field
											as={CheckBox}
											subtitle="Prova em Braile"
											name="recursoAvaliacao.provaBraile"
											checked={formik.values.recursoAvaliacao?.provaBraile}
											error={hasError(
												formik.errors,
												'recursoAvaliacao.provaBraile',
											)}
										/>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={8}>
										<Field
											as={CheckBox}
											subtitle="Nenhum"
											name="recursoAvaliacao.nenhum"
											checked={formik.values.recursoAvaliacao?.nenhum}
											error={hasError(formik.errors, 'recursoAvaliacao.nenhum')}
										/>
									</Col>

									<Col span={8}>
										<Field
											as={CheckBox}
											subtitle="Prova Ampliada"
											name="recursoAvaliacao.provaAmpliada"
											checked={formik.values.recursoAvaliacao?.provaAmpliada}
											error={hasError(
												formik.errors,
												'recursoAvaliacao.provaAmpliada',
											)}
										/>
									</Col>
									{formik.values.recursoAvaliacao?.provaAmpliada && (
										<Col span={8}>
											<Field
												as={Select}
												title=""
												name="recursoAvaliacao.tamanhoFonte"
												size={70}
												options={dataEnum.tamanhoFonte}
												onChange={(v: string) =>
													formik.setFieldValue(
														'recursoAvaliacao.tamanhoFonte',
														v,
													)
												}
												error={hasError(
													formik.errors,
													'recursoAvaliacao.tamanhoFonte',
												)}
											/>
										</Col>
									)}
								</Row>

								<Row>
									<Col span={16}>
										<Field
											as={CheckBox}
											subtitle="CD com áudio para deficiente visual"
											name="recursoAvaliacao.cdAudioDeficienteVisual"
											checked={
												formik.values.recursoAvaliacao?.cdAudioDeficienteVisual
											}
											error={hasError(
												formik.errors,
												'recursoAvaliacao.cdAudioDeficienteVisual',
											)}
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={CheckBox}
											subtitle="Prova em Vídeo em Libras"
											name="recursoAvaliacao.provaVideoLibras"
											checked={formik.values.recursoAvaliacao?.provaVideoLibras}
											error={hasError(
												formik.errors,
												'recursoAvaliacao.provaVideoLibras',
											)}
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={CheckBox}
											subtitle="Prova de Língua Portuguesa como segunda língua para surdos e deficientes auditivos"
											name="recursoAvaliacao.provaLinguaPortuguesa"
											checked={
												formik.values.recursoAvaliacao?.provaLinguaPortuguesa
											}
											error={hasError(
												formik.errors,
												'recursoAvaliacao.provaLinguaPortuguesa',
											)}
										/>
									</Col>
								</Row>
							</Section>
						</Content>
					</>
				)}
				<Row gutter={[0, 10]}>
					<Col span={7}>
						<Field
							as={CheckBox}
							subtitle="Cuidador"
							name="deficiencia.cuidador"
							checked={formik.values.deficiencia.cuidador}
							error={hasError(formik.errors, 'deficiencia.cuidador')}
						/>
						{formik.values.deficiencia?.cuidador && (
							<Field
								as={Select}
								title=""
								name="deficiencia.tipoCuidador"
								options={dataEnum.acompanhamentoDeficiente}
								onChange={(v: string) =>
									formik.setFieldValue('deficiencia.tipoCuidador', v)
								}
								error={hasError(formik.errors, 'deficiencia.tipoCuidador')}
							/>
						)}
					</Col>

					<Col span={7} offset={1}>
						<Field
							as={CheckBox}
							subtitle="Profissional Saúde"
							name="deficiencia.profSaude"
							checked={formik.values.deficiencia.profSaude}
							error={hasError(formik.errors, 'deficiencia.profSaude')}
						/>
						{formik.values.deficiencia?.profSaude && (
							<Field
								as={Select}
								title=""
								name="deficiencia.tipoProfSaude"
								options={dataEnum.acompanhamentoDeficiente}
								onChange={(v: string) =>
									formik.setFieldValue('deficiencia.tipoProfSaude', v)
								}
								error={hasError(formik.errors, 'deficiencia.tipoProfSaude')}
							/>
						)}
					</Col>

					<Col span={7} offset={1}>
						<Field
							as={CheckBox}
							subtitle="Mobilidade Reduzida"
							name="deficiencia.mobilidadeReduzida"
							checked={formik.values.deficiencia.mobilidadeReduzida}
							error={hasError(formik.errors, 'deficiencia.mobilidadeReduzida')}
						/>
						{formik.values.deficiencia?.mobilidadeReduzida && (
							<Field
								as={Select}
								title=""
								name="deficiencia.tipoMobilidadeReduzida"
								options={dataEnum.acompanhamentoDeficiente}
								onChange={(v: string) =>
									formik.setFieldValue('deficiencia.tipoMobilidadeReduzida', v)
								}
								error={hasError(
									formik.errors,
									'deficiencia.tipoMobilidadeReduzida',
								)}
							/>
						)}
					</Col>
				</Row>
			</FormBox>
		</>
	);
};

export default Deficiencia;
