import { ReducerAction } from 'store/modules/types';

import { Types, LibracaoCiliRequest } from './types';

export function libracaoCiliRequest(data: LibracaoCiliRequest): ReducerAction {
	return {
		type: Types.LIBERACAO_CILI_REQUEST,
		payload: data,
	};
}
export function libracaoCiliSuccess(payload: object): ReducerAction {
	return {
		type: Types.LIBERACAO_CILI_SUCCESS,
		payload,
	};
}
export function libracaoCiliFailure(payload: object): ReducerAction {
	return {
		type: Types.LIBERACAO_CILI_FAILURE,
		payload,
	};
}

export function libracaoCiliClear(): ReducerAction {
	return {
		type: Types.LIBERACAO_CILI_CLEAR,
		payload: null,
	};
}
