import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
	useContext,
} from 'react';
import { Field, FormikProps } from 'formik';
import { Col, Row, Modal, Alert } from 'antd';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';

import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import Button from 'components/Common/Button';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';

import { getApiKey } from 'pages/EducacaoUpdate/components/Funcoes/apiKey';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// TYPES
import { EnumDadosBasicos } from 'store/modules/api/educacaoUpdate/combos/dadosBasicos/types';

import hasError from 'utils/getFormErrors';
import FormBox from 'components/Common/Form/FormBox';
import { ApplicationState } from 'store';
import { comboMunicipiosSPRequest } from 'store/modules/api/educacaoUpdate/fichaAluno/comboMunicipiosSP/actions';
import SearchAutocomplete from '../components/SearchAutocomplete';
import Endereco from '../components/Endereco';
import { IFichaAluno } from '../form';

import { ContainerFichaCadastral } from '../styled';
import {
	Container,
	DividerBarra,
	Divider,
	Latitude,
	Longitude,
	Cidade,
	Uf,
} from './styled';

interface Props {
	formik: FormikProps<IFichaAluno>;
}

const containerStyle = {
	height: '600px',
};

interface latLng {
	lat: number | string;
	lng: number | string;
}

const libraries = ['places'] as Libraries;

const EnderecoResidencial: React.FC<Props> = ({ formik }) => {
	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const { parametrosSistema } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.user,
	);

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: getApiKey(parametrosSistema),
		libraries,
	});
	const [markers, setMarkers] = useState<latLng>({
		lat: '',
		lng: '',
	});
	const [logradouro, setLogradouro] = useState<string>('');
	const [logradouroLatLng, setlogradouroLatLng] = useState<latLng>({
		lat: -23.55052,
		lng: -46.633308,
	});
	const [zoomMap, setZoomMap] = useState<number>(14);
	const [inputLatLng, setInputLatLng] = useState<latLng>({
		lat: formik.values.enderecoResidencial.latitude,
		lng: formik.values.enderecoResidencial.longitude,
	});
	const [centerMap, setCenterMap] = useState<latLng>(logradouroLatLng);

	const [modalMap, setModalMap] = useState<boolean>(false);
	const [dataMunicipios, setDataMunicipios] = useState<
		Array<{ value: string; label: string }>
	>([]);
	const [showAlert, setShowAlert] = useState<boolean>(false);

	const { setFieldValue } = formik;

	const { municipios } = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.fichaAluno.comboMunicipiosSP.data,
	);

	const { latlng } = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.fichaAluno.cadastrarFichaAluno,
	);

	const { dadosBasicos } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.combos,
	);

	useEffect(() => {
		if (latlng) {
			setMarkers({
				lat: latlng.lat,
				lng: latlng.lng,
			});
			setlogradouroLatLng(latlng);
			setCenterMap(latlng);
		}
	}, [latlng]);

	useEffect(() => {
		const lista: Array<{ value: string; label: string }> = [];
		municipios?.map(item => {
			return lista.push({
				value: item.codigo,
				label: item.descricao,
			});
		});
		setDataMunicipios(lista);
	}, [municipios]);

	useEffect(() => {
		if (loadError) {
			setShowAlert(true);
		}
	}, [loadError]);

	const handleChangeMunicipio = useCallback(
		(v: any) => {
			const [result] = municipios.filter((nome: any) => {
				if (nome.codigo.toString() === v.toString()) {
					return nome.descricao;
				}
				return null;
			});
			formik.setFieldValue('enderecoResidencial.nomeCidade', result?.descricao);
			formik.setFieldValue(
				'enderecoResidencial.codigoMunicipioDNE',
				result?.codigo,
			);
		},
		[formik, municipios],
	);

	useEffect(() => {
		dispatch(comboMunicipiosSPRequest(token));
	}, [dispatch, token]);

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado) => {
			setFieldValue('enderecoResidencial.cep', res.cep);
			setFieldValue(
				'enderecoResidencial.logradouro',
				res.endereco.substring(0, 30),
			);
			handleChangeMunicipio(res.codigoLocalRel);
			setFieldValue('enderecoResidencial.bairro', res.bairro.substring(0, 20));
		},
		[handleChangeMunicipio, setFieldValue],
	);

	const setValueLogradouro = async (v: string) => {
		if (isLoaded) {
			try {
				const results = await getGeocode({
					address: v,
				});
				const latLng = await getLatLng(results[0]);
				const formattedAdress = results[0].formatted_address.replace(
					', Brasil',
					'',
				);
				setLogradouro(formattedAdress);
				setlogradouroLatLng(latLng);
				setZoomMap(18);
				setCenterMap(latLng);
				setMarkers(latLng);
			} catch (err) {
				console.log('ERROR', v);
			}
		}
	};

	useEffect(() => {
		setValueLogradouro(
			`${formik.values.enderecoResidencial.logradouro}, ${formik.values.enderecoResidencial.numero} - ${formik.values.enderecoResidencial.bairro} - ${formik.values.enderecoResidencial.nomeCidade} - ${formik.values.enderecoResidencial.cep}`,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		formik.values.enderecoResidencial.logradouro,
		formik.values.enderecoResidencial.numero,
		formik.values.enderecoResidencial.bairro,
		formik.values.enderecoResidencial.nomeCidade,
		formik.values.enderecoResidencial.cep,
		isLoaded,
	]);

	const mapRef = useRef();
	const onMapLoad = useCallback((map: any) => {
		mapRef.current = map;
	}, []);

	useEffect(() => {
		formik.setFieldValue('enderecoResidencial.latitude', inputLatLng.lat);
		formik.setFieldValue('enderecoResidencial.longitude', inputLatLng.lng);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputLatLng]);

	const handleOpenMap = () => {
		setModalMap(true);
	};

	const onMarkerClick = async (evt: any) => {
		if (!loadError) {
			const results = await getGeocode({ location: evt.latLng });
			setLogradouro(results[0].formatted_address);
			setlogradouroLatLng(evt.latLng);
			setZoomMap(18);
			setCenterMap(evt.latLng);
			setMarkers({
				lat: evt.latLng.lat(),
				lng: evt.latLng.lng(),
			});
		}
	};

	const getEditedDadosBasicosOptionList = useCallback(
		(
			objectPropNameToEdit:
				| 'sexos'
				| 'estadosCivis'
				| 'nacionalidades'
				| 'responsabilidades'
				| 'localizacoesDiferenciadas'
				| 'areasLogradouro',
		): { label: string; value: string }[] => {
			if (
				dadosBasicos.data &&
				objectPropNameToEdit &&
				dadosBasicos.data[objectPropNameToEdit]
			) {
				// Remove number e enum diferente do padrao.
				const {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					processoId,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					deficiencias,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					provasAmpliadas,
					...dadosBasicosEditedData
				} = dadosBasicos.data;

				return (
					dadosBasicosEditedData[
						objectPropNameToEdit as keyof typeof dadosBasicosEditedData
					].map((opt: EnumDadosBasicos) => {
						return { label: opt.descricao, value: opt.codigo.toString() };
					}) || []
				);
			}

			return [];
		},
		[dadosBasicos.data],
	);

	return (
		<>
			<ContainerFichaCadastral>
				<FormBox title="Endereço Residencial">
					<Row gutter={[0, 10]}>
						<Col span={12}>
							<CEP
								name="enderecoResidencial.cep"
								defaultValue={formik.values.enderecoResidencial.cep}
								formik={formik}
								result={handleCepSelecionado}
								required
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Field
								as={Select}
								title="Localização/Zona de Residência"
								name="enderecoResidencial.areaLogradouro"
								options={getEditedDadosBasicosOptionList('areasLogradouro')}
								onChange={(v: string) =>
									formik.setFieldValue('enderecoResidencial.areaLogradouro', v)
								}
								error={hasError(
									formik.errors,
									'enderecoResidencial.areaLogradouro',
								)}
								required
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Field
								as={Select}
								title="Localização Diferenciada"
								name="enderecoResidencial.localizacaoDiferenciada"
								options={getEditedDadosBasicosOptionList(
									'localizacoesDiferenciadas',
								)}
								onChange={(v: string) =>
									formik.setFieldValue(
										'enderecoResidencial.localizacaoDiferenciada',
										v,
									)
								}
								error={hasError(
									formik.errors,
									'enderecoResidencial.localizacaoDiferenciada',
								)}
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Endereco
								title="Endereço - N°"
								enderecoRua="enderecoResidencial.logradouro"
								numero="enderecoResidencial.numero"
								formik={formik}
								result={endereco => {
									formik.setFieldValue(
										'enderecoResidencial.logradouro',
										endereco.enderecoRua,
									);
									formik.setFieldValue(
										'enderecoResidencial.numero',
										endereco.numero,
									);
								}}
								error={hasError(
									formik.errors,
									'enderecoResidencial.logradouro' ||
										'enderecoResidencial.numero',
								)}
								required
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Field
								as={Input}
								title="Complemento"
								name="enderecoResidencial.complemento"
								maxLength={40}
								error={hasError(
									formik.errors,
									'enderecoResidencial.complemento',
								)}
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Field
								as={Input}
								title="Bairro"
								name="enderecoResidencial.bairro"
								maxLength={40}
								error={hasError(formik.errors, 'enderecoResidencial.bairro')}
								required
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Container>
								<Cidade>
									<Field
										as={Select}
										title="Cidade - UF"
										name="enderecoResidencial.nomeCidade"
										options={dataMunicipios}
										onChange={(v: string) => {
											handleChangeMunicipio(v);
										}}
										error={hasError(
											formik.errors,
											'enderecoResidencial.nomeCidade',
										)}
										required
									/>
								</Cidade>
								<Divider />
								<Uf>
									<Field
										as={Input}
										name="enderecoResidencial.ufCidade"
										value="SP"
										disabled
									/>
								</Uf>
							</Container>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={12}>
							<Container>
								<Latitude>
									<Field
										as={Input}
										title="Latitude/Longitude"
										name="enderecoResidencial.latitude"
										value={inputLatLng.lat}
										error={hasError(
											formik.errors,
											'enderecoResidencial.latitude',
										)}
										required
									/>
								</Latitude>
								<DividerBarra />
								<Longitude>
									<Field
										as={Input}
										name="enderecoResidencial.longitude"
										value={inputLatLng.lng}
										error={hasError(
											formik.errors,
											'enderecoResidencial.longitude',
										)}
									/>
								</Longitude>
							</Container>
						</Col>
						<Col span={4} offset={1}>
							<Button className="addButton" onClick={() => handleOpenMap()}>
								Geolocalizar
							</Button>
						</Col>
					</Row>
				</FormBox>
				<Modal
					title=""
					visible={modalMap}
					onCancel={() => {
						setModalMap(false);
					}}
					width={880}
					footer={null}
				>
					<>
						<Row gutter={[0, 10]} justify="center">
							<Col span={3}>
								<h3>Google Maps</h3>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								{isLoaded && (
									<>
										<SearchAutocomplete
											logradouro={logradouro}
											setLogradouro={setLogradouro}
										/>
										<GoogleMap
											mapContainerStyle={containerStyle}
											center={centerMap}
											zoom={zoomMap}
											options={{
												streetViewControl: true,
												fullscreenControl: true,
												mapTypeControl: false,
											}}
											onLoad={onMapLoad}
											onClick={(evt: any) => onMarkerClick(evt)}
										>
											<Marker position={logradouroLatLng} />
										</GoogleMap>
									</>
								)}
								{showAlert && (
									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Alert
												type="error"
												showIcon
												message="Google Maps não responde!"
												closable
												onClose={() => setShowAlert(false)}
											/>
										</Col>
									</Row>
								)}
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={4}>
								<Button
									className="addButton"
									onClick={() => {
										setModalMap(false);
										setInputLatLng(markers);
										formik.setFieldValue(
											'enderecoResidencial.latitude',
											markers.lat,
										);
										formik.setFieldValue(
											'enderecoResidencial.longitude',
											markers.lng,
										);
									}}
								>
									Confirmar
								</Button>
							</Col>
						</Row>
					</>
				</Modal>
			</ContainerFichaCadastral>
		</>
	);
};

export default EnderecoResidencial;
