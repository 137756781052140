import styled from 'styled-components';

export const Button = styled.button`
	background-color: transparent;
	border: unset;
	display: flex;
`;

export const Icon = styled.div`
	line-height: 23px;
	margin-right: 4px;
`;

export const Text = styled.div`
	font-weight: 700;
`;
