import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConsultarEstatisticaState } from './types';

export const INITIAL_STATE: IConsultarEstatisticaState = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IConsultarEstatisticaState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_ESTATISTICA_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_ESTATISTICA_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.CONSULTAR_ESTATISTICA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
