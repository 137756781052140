import React, { useCallback } from 'react';

import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// ROUTES
import { ROUTE_DETRAN_CRV } from 'routes/paths';

export interface FormOwnerData {
	typeService: 'DESB_JUDICIAL' | 'DESB_CAT_ALUGUEL' | 'DESB_LIC_OBITO';
	formNumber: string;
	formYear: string;
	plate: string;
	renavam: string;
	cpfcnpj: string;
	requestDate: string;
}
interface ConfirmacaoServicoDesbloqueioProps {
	formOwnerData: FormOwnerData;
}

export const ConfirmacaoServicoDesbloqueio = ({
	formOwnerData,
}: ConfirmacaoServicoDesbloqueioProps) => {
	const history = useHistory();

	const titleByService =
		(formOwnerData.typeService === 'DESB_JUDICIAL' &&
			'Resultado do pedido de Desbloqueio de veículo - bloqueio judicial') ||
		(formOwnerData.typeService === 'DESB_CAT_ALUGUEL' &&
			'Resultado do pedido de Desbloqueio de veículo - bloqueio diversos - categoria aluguel') ||
		(formOwnerData.typeService === 'DESB_LIC_OBITO' &&
			'Resultado do pedido de Licenciamento de veículo - ocorrência óbito') ||
		'';

	const handleButtonVoltar = useCallback(() => {
		history.push(ROUTE_DETRAN_CRV);
	}, [history]);

	return (
		<Row>
			<Col span={24}>
				<Section size="lg" title={titleByService} titleSize="sm">
					<Row>
						<Col span={22} offset={2}>
							<Row gutter={[0, 5]}>
								<Col span={5}>Número da Ficha:</Col>
								<Col>
									<b>{formOwnerData.formNumber}</b>
								</Col>
							</Row>
							<Row gutter={[0, 5]}>
								<Col span={5}>Ano da Ficha:</Col>
								<Col>
									<b>{formOwnerData.formYear}</b>
								</Col>
							</Row>
							<Row gutter={[0, 5]}>
								<Col span={5}>PLACA:</Col>
								<Col>
									<b>{formOwnerData.plate}</b>
								</Col>
							</Row>
							<Row gutter={[0, 5]}>
								<Col span={5}>Renavam:</Col>
								<Col>
									<b>{formOwnerData.renavam}</b>
								</Col>
							</Row>
							<Row gutter={[0, 5]}>
								<Col span={5}>CPF / CNPJ do Proprietário:</Col>
								<Col>
									<b>{formOwnerData.cpfcnpj}</b>
								</Col>
							</Row>
							<Row gutter={[0, 5]}>
								<Col span={5}>Data da Solicitação:</Col>
								<Col>
									<b>{formOwnerData.requestDate}</b>
								</Col>
							</Row>
						</Col>
					</Row>
				</Section>

				<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
			</Col>
		</Row>
	);
};
