import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarMotivoRequest } from './types';

export function atualizarMotivoRequest(
	data: AtualizarMotivoRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_MOTIVO,
		payload: data,
	};
}
export function atualizarMotivoSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_MOTIVO_SUCCESS,
		payload,
	};
}
export function atualizarMotivoFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_MOTIVO_FAILURE,
		payload: null,
	};
}
export function atualizarMotivoClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_MOTIVO_CLEAR,
		payload: null,
	};
}
