import { ReducerAction } from 'store/modules/types';

import { Types, RequestEnviaSMS } from './types';

export function enviaSMSRequest(data: RequestEnviaSMS): ReducerAction {
	return {
		type: Types.ENVIAR_SMS_REQUEST,
		payload: data,
	};
}
export function enviaSMSSuccess(payload: object): ReducerAction {
	return {
		type: Types.ENVIAR_SMS_SUCCESS,
		payload,
	};
}
export function enviaSMSFailure(payload: boolean): ReducerAction {
	return {
		type: Types.ENVIAR_SMS_FAILURE,
		payload,
	};
}
export function enviaSMSClear(): ReducerAction {
	return {
		type: Types.ENVIAR_SMS_CLEAR,
		payload: null,
	};
}
