// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function tiposResolucaoRequest() {
	return {
		type: Types.TIPOS_RESOLUCAO_REQUEST,
	};
}
export function tiposResolucaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.TIPOS_RESOLUCAO_SUCCESS,
		payload,
	};
}
export function tiposResolucaoFailure(payload: object): ReducerAction {
	return {
		type: Types.TIPOS_RESOLUCAO_FAILURE,
		payload,
	};
}
export function tiposResolucaoClear(): ReducerAction {
	return {
		type: Types.TIPOS_RESOLUCAO_CLEAR,
		payload: null,
	};
}
