export enum Types {
	GET_CREDENTIALS_REQUEST = '@E-CPF/GET_CREDENTIALS_REQUEST',
	GET_CREDENTIALS_SUCCESS = '@E-CPF/GET_CREDENTIALS_SUCCESS',
	GET_CREDENTIALS_FAILURE = '@E-CPF/GET_CREDENTIALS_FAILURE',
	GET_CREDENTIALS_CLEAR = '@E-CPF/GET_E_CPF_VALIDATION_CLEAR',
}

export interface GetCredentials {
	status: number;
	data: Data[] | null;
}

interface Data {
	status: string;
	email: string;
	cpf: string;
	name: string;
	notBefore: number;
	notAfter: number;
	subjectCN: string;
	issuerCN: string;
	certificateType: string;
	redirectUrl: string;
	aki: string;
	serialNumber: string;
	token: string;
	certificateB64: string;
	idScore: number;
}

export interface GetCredentialsRequest {
	token: string;
	api: string;
	apiToken: string;
}
