import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Tooltip } from 'antd';

import SimpleTable from 'components/Common/Table';
import { CadastrarContatoRequest } from 'store/modules/api/bolsaPovo/cadastrarContato/types';
import { Field, Form, Formik } from 'formik';
import LightButton from 'components/Common/Buttons/Light';
import { excluirContatoRequest } from 'store/modules/api/bolsaPovo/excluirContato/action';
// import { ApplicationState } from 'store';
import { useDispatch } from 'react-redux';
import Select, { OptionProps } from 'components/Common/Form/Select';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import dataEnum from 'store/modules/api/bolsaPovo/enum';
import getValidationsErrors from 'utils/getValidationsErrors';
import { atualizarContatoRequest } from 'store/modules/api/bolsaPovo/atualizarContato/action';
import { AtualizarContatoRequest } from 'store/modules/api/bolsaPovo/atualizarContato/types';
import { addNotifications } from 'store/modules/app/notifications/actions';
import { schemaContatos } from '../form';

interface Props {
	contatosEnviados: CadastrarContatoRequest[];
	edicao: number;
	setEdicao: (v: number) => void;
}

const CadastroContato: React.FC<Props> = ({
	contatosEnviados = [],
	edicao = 0,
	setEdicao,
}) => {
	const dispatch = useDispatch();

	const [
		cadastroEditaveis,
		setCadastroEditaveis,
	] = useState<CadastrarContatoRequest>({
		idCpf: '',
		idTipoContato: '',
		valor: '',
		id: 0,
	});

	// const { consultarTipoContato } = useSelector(
	// 	(state: ApplicationState) => state.api.bolsaPovo,
	// );

	useEffect(() => {
		if (
			edicao &&
			contatosEnviados.filter(
				(item: CadastrarContatoRequest) => item.id === edicao,
			).length
		) {
			const newData = contatosEnviados.filter(
				(item: CadastrarContatoRequest) => item.id === edicao,
			);
			setCadastroEditaveis(newData[0]);
		}
	}, [contatosEnviados, edicao]);

	const getTipoContato = (value: number) => {
		let retorno = '';
		const response = dataEnum.tiposContato.filter(
			(item: OptionProps) => item.value === value,
		);
		if (response.length) {
			retorno = response[0].label;
		}
		return retorno;
	};

	const handleSubmit = useCallback(
		async (contatoValues: CadastrarContatoRequest) => {
			const validacao = await getValidationsErrors(
				contatoValues,
				schemaContatos,
			);
			if (validacao !== true) {
				addNotifications({
					errors: [validacao.valores],
				});
			} else {
				setEdicao(0);
				const payload: AtualizarContatoRequest = {
					cpf: contatoValues.idCpf,
					idTipoContato: contatoValues.idTipoContato,
					valor: contatoValues.valor,
					id: contatoValues.id,
				};
				dispatch(atualizarContatoRequest(payload));
			}
		},
		[dispatch, setEdicao],
	);

	const headers = [
		{
			title: 'Tipo Contatos',
			dataIndex: 'idTipoContato',
			align: 'center',
			render: (text: number, record: CadastrarContatoRequest) => {
				if (edicao && record.id === edicao) {
					return (
						<Field
							as={Select}
							options={dataEnum.tiposContato ?? []}
							type="text"
							name="idTipoContato"
							defaultFirstOptionText="- SELECIONE O TIPO DE CONTATO -"
							onChange={(value: string) => {
								setCadastroEditaveis(prev => {
									return {
										...prev,
										idTipoContato: value,
										valor: '',
									};
								});
							}}
							// error={hasError(formik.errors, 'idTipoContato')}
							required
							disabled
						/>
					);
				}
				return getTipoContato(text);
			},
		},
		{
			title: 'Contato',
			dataIndex: 'valor',
			defaultSortOrder: 'descend',
			align: 'center',
			render: (text: string, record: CadastrarContatoRequest) => {
				if (
					cadastroEditaveis.id === edicao &&
					cadastroEditaveis.idTipoContato !== '' &&
					edicao &&
					record.id === edicao
				) {
					if (
						cadastroEditaveis.idTipoContato === 1 ||
						cadastroEditaveis.idTipoContato === 2
					) {
						return (
							<Field
								as={InputMask}
								mask="(99)9999-9999"
								name="valor"
								// error={hasError(formik.errors, 'valor')}
								required
							/>
						);
					}
					if (cadastroEditaveis.idTipoContato === 3) {
						return (
							<Field
								as={InputMask}
								mask="(99)99999-9999"
								name="valor"
								// error={hasError(formik.errors, 'valor')}
								required
							/>
						);
					}

					return (
						<Field
							as={Input}
							type="text"
							name="valor"
							disabled={
								!cadastroEditaveis.idTipoContato ||
								cadastroEditaveis.idTipoContato === null
							}
							// error={hasError(formik.errors, 'valor')}
							required
						/>
					);
				}
				return text;
			},
		},
		{
			dataIndex: '',
			title: 'Ação',
			align: 'center',
			width: '30%',
			render: (text: string, record: CadastrarContatoRequest) => {
				if (edicao && record.id === edicao) {
					return (
						<Row gutter={20} justify="center">
							<Col className="gutter-row">
								<Tooltip title="Cancelar atualização">
									<LightButton
										buttonColor="default"
										text="Cancelar"
										icon="back"
										buttonSize="sm"
										onClick={() => {
											setEdicao(0);
											setCadastroEditaveis({
												idCpf: '',
												idTipoContato: '',
												valor: '',
												id: 0,
											});
										}}
									/>
								</Tooltip>
							</Col>
							<Col className="gutter-row">
								<Tooltip title="Atualizar contato">
									<LightButton
										buttonColor="default"
										text="Atualizar"
										icon="check"
										buttonSize="sm"
										type="submit"
									/>
								</Tooltip>
							</Col>
						</Row>
					);
				}
				return (
					<Row gutter={20} justify="center">
						<Col className="gutter-row">
							<Tooltip title="Atualizar contato">
								<LightButton
									buttonColor="default"
									text="Editar"
									icon="edit"
									buttonSize="sm"
									disabled={edicao !== 0 && record.id !== edicao}
									onClick={() => setEdicao(Number(record.id))}
								/>
							</Tooltip>
						</Col>
						<Col className="gutter-row">
							<Tooltip title="Excluir contato">
								<LightButton
									buttonColor="default"
									text="Excluir"
									icon="erase"
									buttonSize="sm"
									disabled={edicao !== 0 && record.id !== edicao}
									onClick={() =>
										dispatch(
											excluirContatoRequest({
												id: Number(record.id) ?? '',
											}),
										)
									}
								/>
							</Tooltip>
						</Col>
					</Row>
				);
			},
		},
	];

	return (
		<>
			<Formik
				enableReinitialize
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={cadastroEditaveis}
				onSubmit={handleSubmit}
			>
				{formik => (
					<Form autoComplete="off">
						<SimpleTable
							headers={headers}
							body={contatosEnviados}
							messageNoResults="Faça a inclusão de ao meno um contato de telefone celular."
						/>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CadastroContato;
