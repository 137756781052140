export enum Types {
	CADASTRAR_STATUS = '@SGU/CADASTRAR_STATUS',
	CADASTRAR_STATUS_SUCCESS = '@SGU/CADASTRAR_STATUS_SUCCESS',
	CADASTRAR_STATUS_FAILURE = '@SGU/CADASTRAR_STATUS_FAILURE',
	CADASTRAR_STATUS_CLEAR = '@SGU/CADASTRAR_STATUS_CLEAR',
}

export interface CadastrarStatus {
	status: number;
	data: null | CadastrarStatusData;
}

export interface CadastrarStatusData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarStatusRequest {
	codigo?: number | string;
	descricao: string;
}
