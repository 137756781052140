import * as Yup from 'yup';

import { formatDate } from 'utils/genericFunctions';

import { IReenviarDocumentosRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/reenviarDocumentos/types';
import { HistoricoAnalise } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarAcompanhamento/types';

export interface DisplayData {
	numeroFicha: number;
	anoFicha: number;
	placa: string;
	renavam: number;
	opcaoServico: number;
	descricaoAndamento: string;
	dataSolicitacao: string;
	dataAtualizacaoRetaguarda: string;
	existeBloqueioAdministrativo: string;
	dataDesbloqueio?: string;
	origemServico?: string;
	dataEmissao?: string;
	unidadeTransito?: string;
}

export const initialValues: any = {
	anoFicha: '',
	documentos: [],
	numeroFicha: '',
	cpfUsuarioBU: '',
	codUnidadeTransito: 0,
};

export const schema = Yup.object<IReenviarDocumentosRequest>().shape({
	documentos: Yup.array().min(1, 'É necessário anexar um documento.'),
});

export const formatList = (lista: HistoricoAnalise[]) => {
	if (lista.length === 0) return lista;
	const formatedList = lista.map(analise => ({
		...analise,
		data: formatDate(analise.data),
	}));

	return formatedList;
};

export const formatArquivos = (arquivos: any[]) => {
	if (arquivos.length === 0) return arquivos;
	const formatedArquivos = arquivos.map(arquivo => ({
		conteudo: arquivo.conteudo,
		tipoArquivo: arquivo.tipoArquivo,
	}));
	return formatedArquivos;
};
