import React, {
	ChangeEvent,
	Suspense,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Error from 'components/Common/Notifications/FormError/index';

// REDUX
import {
	consultarVeiculoV120Request,
	consultarVeiculoV120Clear,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV120/actions';
import { ConsultarVeiculoV120Request } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV120/types';
import {
	primeiroEmplacamentoClear,
	primeiroEmplacamentoRequest,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/PrimeiroEmplacamento/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCrv/enum/consultaCategoria/actions';
import { ConsultarTaxaServicoRequest } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/types';
import {
	consultarTaxaServicoClear,
	consultarTaxaServicoRequest,
} from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/actions';

// UTILS
import { onlyNumbers } from 'utils/genericFunctions';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { idConsultaCategoria } from 'pages/DetranCrv/utils/constants/servicesResponse';

// ROUTES
import { ROUTE_DETRAN_CRV } from 'routes/paths';

// FORM
import { letrasNumeros } from 'utils/stringFunctions';
import {
	estatisticaIncluirClear,
	estatisticaIncluirRequest,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';
import {
	initialValues,
	IPrimeiroEmplacamento,
	schema,
	treatRequestSguStatistics,
} from './form';

let idSelectedCategory = 0;

const PrimeiroEmplacamento: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const [formData] = useState(initialValues);

	const [tipoRestricao, setTipoRestricao] = useState<IPrimeiroEmplacamento>({
		chassi: '',
		cpfCnpjProprietario: '',
		restricaoFinanceira: '',
	});
	const [notificationErrors, setNotificationErrors] = useState<string[]>([]);

	const { primeiroEmplacamento, consultarVeiculoV120 } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	const { consultaCategoria } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.Enum,
	);

	const { consultarTaxaServico } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	const handleClear = useCallback(() => {
		dispatch(consultarVeiculoV120Clear());
		dispatch(primeiroEmplacamentoClear());
		dispatch(clearNotifications());
		dispatch(estatisticaIncluirClear());
		setNotificationErrors([]);
		dispatch(consultarTaxaServicoClear());
	}, [dispatch]);

	useEffect(() => {
		dispatch(consultarVeiculoV120Clear());
		dispatch(consultaCategoriaRequest());
	}, [dispatch]);

	useEffect(() => {
		if (
			consultarVeiculoV120.status === 200 &&
			consultarVeiculoV120.data &&
			consultarTaxaServico.status === 0
		) {
			const payload = treatRequestSguStatistics(
				user,
				formData,
				consultarVeiculoV120.data,
			);

			dispatch(estatisticaIncluirRequest(payload));

			if (idSelectedCategory === idConsultaCategoria.idOficialCategory) {
				dispatch(primeiroEmplacamentoRequest(tipoRestricao));
				dispatch(consultarVeiculoV120Clear());
			} else {
				const dataConsultarTaxaServico: ConsultarTaxaServicoRequest = {
					codigoTaxaServico: '1',
					identificadorTaxa: tipoRestricao.cpfCnpjProprietario,
					despachante: '',
				};
				dispatch(consultarTaxaServicoRequest(dataConsultarTaxaServico));
			}
		}
	}, [
		consultarVeiculoV120.status,
		dispatch,
		consultarTaxaServico.status,
		consultarVeiculoV120,
		tipoRestricao,
		user,
		formData,
	]);

	useEffect(() => {
		if (
			consultarVeiculoV120.status === 200 &&
			consultarTaxaServico.status === 200
		) {
			dispatch(primeiroEmplacamentoRequest(tipoRestricao));
			dispatch(consultarVeiculoV120Clear());
		}
	}, [
		consultarVeiculoV120.status,
		dispatch,
		tipoRestricao,
		consultarTaxaServico.status,
	]);

	useEffect(() => {
		if (primeiroEmplacamento.status === 200) {
			history.push({
				pathname: '/detran-crv/primeiro-emplacamento/resultado',
				state: {
					chassi: tipoRestricao.chassi,
					cpfCnpjProprietario: tipoRestricao.cpfCnpjProprietario,
					tipoRestricao,
				},
			});
		}
	}, [primeiroEmplacamento, history, tipoRestricao]);

	const indicadorExigibilidade = [
		{
			label: 'NADA CONSTA',
			value: 'NADA_CONSTA',
		},
		{
			label: 'ALIENACAO FIDUCIARIA',
			value: 'ALIENACAO_FIDUCIARIA',
		},
		{
			label: 'ARRENDAMENTO',
			value: 'ARRENDAMENTO',
		},
		{
			label: 'PENHOR MERCANTIL',
			value: 'PENHOR_MERCANTIL',
		},
		{
			label: 'RESERVA DOMINIO',
			value: 'RESERVA_DOMINIO',
		},
	];

	useEffect(() => {
		if (
			primeiroEmplacamento.escolhaPlaca?.chassi &&
			primeiroEmplacamento.escolhaPlaca?.cpfCnpjProprietario
		) {
			initialValues.chassi = primeiroEmplacamento.escolhaPlaca?.chassi;
			initialValues.cpfCnpjProprietario = String(
				primeiroEmplacamento.escolhaPlaca?.cpfCnpjProprietario,
			);
		} else {
			initialValues.chassi = '';
			initialValues.cpfCnpjProprietario = '';
		}
	}, [primeiroEmplacamento]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			{notificationErrors.length > 0 && (
				<Error
					errors={notificationErrors}
					onClose={() => setNotificationErrors([])}
				/>
			)}
			<Section title="Pesquisar">
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={formData}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						handleClear();

						idSelectedCategory = Number(values.categoria) || 0;
						const payload: ConsultarVeiculoV120Request = {
							placa: '',
							chassi: values.chassi ? values.chassi.trim() : '',
							tipoConsulta: 'EMPLACAMENTO',
							categoria: '',
						};

						dispatch(consultarVeiculoV120Request(payload));
						setTipoRestricao({
							chassi: values.chassi?.trim() || '',
							cpfCnpjProprietario: values.cpfCnpjProprietario.replace(
								/\D/g,
								'',
							),
							restricaoFinanceira: values.restricaoFinanceira,
						});
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Col span={24}>
									<Row gutter={[10, 20]}>
										<Col span={14}>
											<Field
												required
												titleSize="lg"
												name="chassi"
												title="Chassi"
												as={Input}
												error={hasError(formik.errors, 'chassi')}
												maxLength={22}
												onChange={(v: ChangeEvent<HTMLInputElement>) => {
													if (errors.length > 0) {
														dispatch(clearNotifications());
													}
													formik.setFieldValue(
														'chassi',
														letrasNumeros(v.target.value),
													);
												}}
											/>
										</Col>
										<Col span={8}>
											<Field
												as={Select}
												title="Categoria"
												options={consultaCategoria.data}
												error={
													!!formik.values.categoria &&
													hasError(formik.errors, 'documentoCategoria')
												}
												name="categoria"
												onChange={(v: number) => {
													handleClear();
													formik.setFieldValue('categoria', v);
												}}
											/>
										</Col>
									</Row>
									<Row gutter={[10, 20]}>
										<Col span={14}>
											<Field
												required
												titleSize="lg"
												maxLength={14}
												// error={hasError(formik.errors, 'cpfCnpjProprietario')}
												name="cpfCnpjProprietario"
												title="CPF/CNPJ Proprietário"
												as={Input}
												onChange={(
													event: React.ChangeEvent<HTMLInputElement>,
												) => {
													if (errors.length > 0) {
														dispatch(clearNotifications());
													}
													const { value } = event.target;
													formik.setFieldValue(
														'cpfCnpjProprietario',
														onlyNumbers(value),
													);
												}}
												// onBlur={() => handleOnBlur(formik)}
												error={
													!!formik.values.cpfCnpjProprietario &&
													hasError(formik.errors, 'documentoCategoria')
												}
											/>
										</Col>
									</Row>
									<Row gutter={[10, 20]}>
										<Col span={24}>
											<Field
												required
												titleSize="lg"
												as={Select}
												size={25}
												defaultValue="NADA_CONSTA"
												name="restricaoFinanceira"
												title="Informe o Tipo de Restrição"
												subtitleColor="red"
												options={indicadorExigibilidade}
												error={!!formik.errors.restricaoFinanceira}
												onChange={(v: string) =>
													formik.setFieldValue('restricaoFinanceira', v)
												}
											/>
										</Col>
									</Row>

									<Row justify="center" align="top" gutter={[20, 10]}>
										<Col>
											<ButtonImage
												type="button"
												src="limpar"
												onClick={() => {
													handleClear();
													formik.handleReset();
												}}
											/>
										</Col>
										<Col>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>
									</Row>
								</Col>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</Suspense>
	);
};

export default PrimeiroEmplacamento;
