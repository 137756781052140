import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_CATEGORIA_REQUEST = '@cnh/CONSULTA_CATEGORIA_REQUEST',
	CONSULTA_CATEGORIA_SUCCESS = '@cnh/CONSULTA_CATEGORIA_SUCCESS',
	CONSULTA_CATEGORIA_FAILURE = '@cnh/CONSULTA_CATEGORIA_FAILURE',
}

interface DivisaoEquitativa {
	codigo: string;
	descricao: string;
	codigoDivisaoEquitativa: string;
}
export interface ConsultaCategoria {
	status: number;
	data: OptionProps[];
	divisaoEquitativa: DivisaoEquitativa[];
}

export interface ConsultaCategoriaRequest {
	funcionalidade: string;
}
