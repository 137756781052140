import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export function consultaEPortalAutomatizadoRequest(
	data: string,
): ReducerAction {
	return {
		type: Types.CONSULTA_E_PORTAL_AUTOMATIZADO_REQUEST,
		payload: data,
	};
}
export function consultaEPortalAutomatizadoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_E_PORTAL_AUTOMATIZADO_SUCCESS,
		payload,
	};
}
export function consultaEPortalAutomatizadoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_E_PORTAL_AUTOMATIZADO_FAILURE,
		payload,
	};
}
export function consultaEPortalAutomatizadoClear(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_E_PORTAL_AUTOMATIZADO_CLEAR,
		payload,
	};
}
