import { IAndamentoProcesso } from 'pages/DetranCrv/Processos/AndamentoProcesso/form';
import { Types } from './types';

export function andamentoProcessoRequest(payload: IAndamentoProcesso) {
	return {
		type: Types.ANDAMENTO_PROCESSO_REQUEST,
		payload,
	};
}
export function andamentoProcessoSuccess(payload: any) {
	return {
		type: Types.ANDAMENTO_PROCESSO_SUCCESS,
		payload,
	};
}
export function andamentoProcessoFailure(payload: []): any {
	return {
		type: Types.ANDAMENTO_PROCESSO_FAILURE,
		payload,
	};
}
export function andamentoProcessoClear(): any {
	return {
		type: Types.ANDAMENTO_PROCESSO_CLEAR,
	};
}
