export const razaoDoStatusEnum = {
	PROCESSO_ADMINISTRATIVO: 'Processo Administrativo',
	ARGUARDANDO_RESPOSTA_DO_CONSUMIDOR: 'Aguardando resposta do Consumidor',
	ARGUARDANDO_RESPOSTA_DO_FORNECEDOR: 'Aguardando resposta do Fornecedor',
};

export const situacaoAtualTnfEnum = {
	TNF_AGUARDANDO_RESPOSTA_DO_CONSUMIDOR:
		'TNF – Aguardando Resposta do Consumidor',
	TNF_AGUARDANDO_RESPOSTA_DO_FORNECEDOR:
		'TNF – Aguardando Resposta do Fornecedor',
};

export const typeConsultaAtendimentoEnum = {
	CONSULTA: 'Consulta',
};
