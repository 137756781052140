import JustificativaAtendimento from 'components/Pages/JustificativaAtendimento';
import EnvioConsultaDocumentos from '../VivaLeite/EnvioConsultaDocumentos';
import RecuperarAtendimento from '../VivaLeite/RecuperarAtendimento';
import AtualizacaoCadastral from '../VivaLeite/AtualizacaoCadastral';
import ResultadoAtualizacaoCadastral from '../VivaLeite/ResultadoAtualizacaoCadastral';
import ConsultaUsuario from '../VivaLeite/ConsultaUsuario';
import TermoAdvertencia from '../VivaLeite/TermoAdvertencia';
import ExcluirBeneficiario from '../VivaLeite/ExcluirBeneficiario';
import IncluirBeneficiario from '../VivaLeite/IncluirBeneficiario';
import EnvioNotasFiscais from '../VivaLeite/EnvioNotasFiscais';
import ImprimirDocumento from '../VivaLeite/ImprimirDocumento';

// PATHS
import {
	ROUTE_SEDS_ATUALIZACAO_CADASTRAL,
	ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO,
	ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO_ENVIO_DOCUMENTOS,
	ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO_EXCLUIR_BENEFICIARIO,
	ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO_IMPRIMIR_DOCUMENTO,
	ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO_INCLUIR_BENEFICIARIO,
	ROUTE_SEDS_IDENTIFICAR_USUARIO,
	ROUTE_SEDS_JUSTIFICATIVA_ATENDIMENTO,
	ROUTE_SEDS_PRESTACAO_DE_CONTAS,
	ROUTE_SEDS_PRESTACAO_DE_CONTAS_ENVIAR_NOTAS_FISCAIS,
	ROUTE_SEDS_PRESTACAO_DE_CONTAS_ENVIO_DOCUMENTOS,
	ROUTE_SEDS_PRESTACAO_DE_CONTAS_EXCLUIR_BENEFICIARIO,
	ROUTE_SEDS_PRESTACAO_DE_CONTAS_IMPRIMIR_DOCUMENTO,
	ROUTE_SEDS_PRESTACAO_DE_CONTAS_INCLUIR_BENEFICIARIO,
	ROUTE_SEDS_PRESTACAO_DE_CONTAS_TERMO_DE_ADVERTENCIA,
	ROUTE_SEDS_RECUPERAR_ATENDIMENTO,
} from './paths';

export const sedsRoute = {
	name: 'SEDS',
	route: '/seds',
};

export default [
	{
		path: ROUTE_SEDS_RECUPERAR_ATENDIMENTO,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Recuperar atendimento',
			},
		],
		Component: RecuperarAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_JUSTIFICATIVA_ATENDIMENTO,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Justificativa atendimento',
			},
		],
		Component: JustificativaAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_IDENTIFICAR_USUARIO,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Identificação do Usuário',
			},
		],
		Component: ConsultaUsuario,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_ATUALIZACAO_CADASTRAL,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Atualização Cadastral',
			},
		],
		Component: AtualizacaoCadastral,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Atualização Cadastral',
			},
		],
		Component: ResultadoAtualizacaoCadastral,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO_ENVIO_DOCUMENTOS,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Atualização Cadastral',
			},
		],
		Component: EnvioConsultaDocumentos,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO_INCLUIR_BENEFICIARIO,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Atualização Cadastral',
			},
		],
		Component: IncluirBeneficiario,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO_EXCLUIR_BENEFICIARIO,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Atualização Cadastral',
			},
		],
		Component: ExcluirBeneficiario,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_ATUALIZACAO_CADASTRAL_RESULTADO_IMPRIMIR_DOCUMENTO,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Atualização Cadastral',
			},
		],
		Component: ImprimirDocumento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_PRESTACAO_DE_CONTAS,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Prestação de Contas',
			},
		],
		Component: TermoAdvertencia,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_PRESTACAO_DE_CONTAS_TERMO_DE_ADVERTENCIA,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Prestação de Contas',
			},
		],
		Component: AtualizacaoCadastral,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_PRESTACAO_DE_CONTAS_EXCLUIR_BENEFICIARIO,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Prestação de Contas',
			},
		],
		Component: ExcluirBeneficiario,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_PRESTACAO_DE_CONTAS_INCLUIR_BENEFICIARIO,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Prestação de Contas',
			},
		],
		Component: IncluirBeneficiario,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_PRESTACAO_DE_CONTAS_ENVIAR_NOTAS_FISCAIS,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Prestação de Contas',
			},
		],
		Component: EnvioNotasFiscais,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_PRESTACAO_DE_CONTAS_IMPRIMIR_DOCUMENTO,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Prestação de Contas',
			},
		],
		Component: ImprimirDocumento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS_PRESTACAO_DE_CONTAS_ENVIO_DOCUMENTOS,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Prestação de Contas',
			},
		],
		Component: EnvioConsultaDocumentos,
		isAuthenticated: true,
		exact: true,
	},
];
