import produce from 'immer';

// TYPES
import { Types, horariosPsicologoPorData } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: horariosPsicologoPorData = {
	status: 0,
	data: null,
};

export default function horariosPsicologoPorDataReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): horariosPsicologoPorData {
	return produce(state, draft => {
		switch (action.type) {
			case Types.HORARIOS_PSICOLOGO_POR_DATA_REQUEST: {
				break;
			}
			case Types.HORARIOS_PSICOLOGO_POR_DATA_SUCCESS: {
				const horariosPorDataData = action.payload.data.horarios_disponiveis.map(
					(item: string) => {
						return {
							label: item.split('T')[1].split('-')[0].substring(0, 5),
							value: item,
						};
					},
				);
				draft.data = {
					enum: horariosPorDataData,
					idPsicologo: action.payload.data.id_medico,
				};
				break;
			}
			case Types.HORARIOS_PSICOLOGO_POR_DATA_FAILURE: {
				break;
			}
			case Types.HORARIOS_PSICOLOGO_POR_DATA_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				break;
			}

			default:
		}
	});
}
