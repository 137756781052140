import { combineReducers } from 'redux';

import cadastraAtendimentoPf from './cadastraAtendimentoPf/reducer';
import cadastraAtendimentoPj from './cadastraAtendimentoPj/reducer';
import consultaAtendimentoPf from './consultaAtendimentoPf/reducer';
import consultaAtendimentoPj from './consultaAtendimentoPj/reducer';
import respostaNaoResolvida from './respostaNaoResolvida/reducer';
import respostaResolvida from './respostaResolvida/reducer';

export default combineReducers({
	cadastraAtendimentoPf,
	cadastraAtendimentoPj,
	consultaAtendimentoPf,
	consultaAtendimentoPj,
	respostaNaoResolvida,
	respostaResolvida,
});
