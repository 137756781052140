import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_COR_REQUEST = '@DETRAN-CRV/CONSULTA_COR_REQUEST',
	CONSULTA_COR_SUCCESS = '@DETRAN-CRV/CONSULTA_COR_SUCCESS',
	CONSULTA_COR_FAILURE = '@DETRAN-CRV/CONSULTA_COR_FAILURE',
}

export interface consultaCor {
	status: number;
	data: OptionProps[];
}
