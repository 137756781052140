import * as Yup from 'yup';

// COMPONENTS
import { IFormStatusCondutor } from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor/form';

// REDUX
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { EventoContabilizacao } from 'store/modules/types';
import { GerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/types';
import { RenovacaoCnhData } from 'store/modules/api/detranCnh/eportal/consultaRenovacaoCnh/types';

// UTILS
import {
	formatDate,
	limparMascara,
	onlyLetters,
	removeAcentoCaracteresEspeciais,
	unformatDate,
	verifyStringIsAnInvalidPhrase,
} from 'utils/genericFunctions';
import { socialNameAndFiliationsPhrasesNotAllowed } from 'utils/constants/types';
import {
	sameThreeCharactersInSequenceNotAllowedRegex,
	userDataRegex,
} from 'utils/constants/regex';
import { letrasNumerosPontosHifen } from 'utils/stringFunctions';

export const initalValuesHabilitacao: IFormStatusCondutor = {
	status: '',
	statusMensagem: '',
	mensagemBloqueio: '',
	numeroRegistro: '',
	numeroPgu: '',
	renach: '',
	local: '',
	categoriaPretendida: '',
	cfcA: '',
	espelhoCnh: '',
	usoForm: '',
	situacaoAtual: '',
	cfcB: '',
	espelhoPid: '',
	dataPrimeiraHabilitacao: '',
	nome: '',
	dataValidadeCnh: '',
	nomePai: '',
	dataNascimento: '',
	nomeMae: '',
	sexo: '',
	nacionalidade: '',
	tipoDocumento: '',
	numeroRg: '',
	orgaoEmissor: '',
	localEmissao: '',
	cpf: '',
	rne1: '',
	rne2: '',
	rne3: '',
	naturalidade: '',
	identificacaoHabilitacao: '',
	pais: '',
	dataValidade: '',
	enderecoLogradouro: '',
	enderecoNumero: '',
	enderecoComplemento: '',
	enderecoCep: '',
	enderecoBairro: '',
	enderecoMunicipio: '',
	enderecoCodigoMunicipio: '',
	enderecoDescricaoMunicipio: '',
	mensagem: '',
	exameMedicoDataExameMedico: '',
	exameMedicoCategoriaPermitida: '',
	exameMedicoRestricaoMedica: '',
	exameMedicoCrm: '',
	exameMedicoCred1: '',
	exameMedicoCred2: '',
	exameMedicoRegiao: '',
	exameMedicoAptidaoMedica: '',
	examePsicotecnicoDataExamePsicotecnico: '',
	examePsicotecnicoLaudo: '',
	examePsicotecnicoResultado1: '',
	examePsicotecnicoResultado2: '',
	examePsicotecnicoCrp: '',
	examePsicotecnicoCred1: '',
	examePsicotecnicoCred2: '',
	examePsicotecnicoRegiao: '',
	examePsicotecnicoAptidaoPsicotecnica: '',
	dataExameTeorico: '',
	codigoExameTeorico: '',
	codigoEntidade: '',
	descricaoEntidade: '',
	dataExamePratico2Rodas: '',
	codigoExamePratico2Rodas: '',
	dataExamePratico4Rodas: '',
	codigoExamePratico4Rodas: '',
	dataUltimaEmissaoCnh: '',
	listaOcorrencias: [],
	listaCursos: [],
	cepPcon: '',
	cepPcad: '',
	mensagensAlerta: [],
};

export const initialValuesPreCadastro: GerarRenachForm = {
	usoFormularioEnum: 'MUDANCA_CATEGORIA',
	cpf: '',
	nome: '',
	nomeMae: '',
	nomePai: '',
	sexo: '',
	dataNascimento: '',
	tipoDocumentoEnum: '',
	numDocumento: '',
	orgaoExpDocumento: '',
	orgaoUfExpDocumento: '',
	nacionalidade: '',
	naturalidade: '',
	codigoNaturalidade: '',
	email: '',
	celularDDD: '',
	celular: '',
	telefone: '',
	logradouro: '',
	logradouroNumero: '',
	logradouroComplemento: '',
	logradouroBairro: '',
	logradouroCodMunicipio: '',
	logradouroCep: '',
	codCiretranCondutor: '',
	statusReusoEnum: '',
	flagAtividadeRemunerada: '',
	flagDeficienteFisico: '',
	flagSolicitaCNHDigital: '',
	codigoVerificador: '',
	categoriaAtual: '',
	categoriaPretendida: '',
	registro: '',
	numeroPgu: '',
	rneNumero: '',
	rneOrgao: '',
	rneUf: '',
	rneCodPaisCnhEstrangeira: '',
	rneDataValidadeCnhEstrangeira: '',
	rneDataPrimeiraHabilitacaoCnhEstrangeira: '',
	rneIdentificacaoCnhEstrangeira: '',
	flagMilitar: 'N',
	codEntidadeMilitar: '',
	numeroIdentMilitar: '',
	flagExamePosto: 'N',
	flagExameToxicologico: '',
	flagProcurador: '',
	codCiretranHabilitacao: '',
	cpfUsuario: '',
	codLocalidade: '',
	uf: '',
	confirmaEmail: '',
	logradouroDescMunicipio: '',
	enderecoRecebimentoCep: '',
	enderecoRecebimentoLogradouro: '',
	enderecoRecebimentoBairro: '',
	enderecoRecebimentoMunicipio: '',
	enderecoRecebimentoCodigoMunicipio: '',
	paisAfetivo1: '',
	paisAfetivo2: '',
	autorizaEmail: 'N',
	origemNomeSocial: '0',
	autorizaSMS: 'N',
	dataPrimeiraHabilitacao: '',
};

// TREAT TO MOUNT FORM
export function treatInitialValues(
	values: RenovacaoCnhData | null,
	dataPrimeiraHabilitacaoConsultarSegundaVia: string | null,
	codigoNaturalidadeIBGE: string | null,
	user: IUser,
	uf: string,
): {
	initalValuesHabilitacao: IFormStatusCondutor;
	initialValuesPreCadastro: GerarRenachForm;
} {
	if (values !== null) {
		// DADOS DA CONSULTA INICIAL
		const {
			mensagensAlerta,
			cpf,
			nome,
			nomePai,
			nomeMae,
			naturalidade,
			numeroRg,
			dataNascimento,
			sexo,
			nacionalidade,
			usoForm,
			numeroRegistro,
			numeroPgu,
			renach,
			espelhoCnh,
			dadosExameMedico,
			dataExameTeorico,
			dadosExamePsicotecnico,
			categoriaPretendida,
			local,
			cfcA,
			cfcB,
			dataValidadeCnh,
			situacaoAtual,
			dataExamePratico4Rodas,
			dataExamePratico2Rodas,
			endereco,
			cepPcon,
			flagAtividadeRemunerada,
			flagDeficienteFisico,
			localEmissao,
			orgaoEmissor,
			rne1,
			rne2,
			rne3,
			dataUltimaEmissaoCnh,
			dadosPcad,
			dataPrimeiraHabilitacao,
		} = values;

		const formattedDataHabilitacao = {
			...initalValuesHabilitacao,
			// --- DADOS HABILITAÇÃO --- //
			mensagensAlerta,

			// INFORMAÇÕES PESSOAIS
			cpf,
			nome,
			nomePai,
			nomeMae,
			naturalidade,
			numeroRg,
			dataNascimento: dataNascimento && formatDate(dataNascimento),
			sexo,
			nacionalidade,

			// INFORMAÇÕES HABILITAÇÃO
			usoForm,
			numeroRegistro,
			numeroPgu,
			renach,
			espelhoCnh,
			exameMedicoDataExameMedico:
				dadosExameMedico.dataExameMedico &&
				formatDate(dadosExameMedico.dataExameMedico),
			exameMedicoCrm: dadosExameMedico.crm,
			dataExameTeorico: dataExameTeorico && formatDate(dataExameTeorico),
			examePsicotecnicoCrp: dadosExamePsicotecnico.crp,
			examePsicotecnicoLaudo: dadosExamePsicotecnico.laudo1,
			examePsicotecnicoDataExamePsicotecnico: formatDate(
				dadosExamePsicotecnico.dataExamePsicotecnico,
			),
			categoriaPretendida:
				dadosPcad &&
				dadosPcad.categoriaPretendida &&
				dadosPcad.categoriaPretendida.trim() !== ''
					? dadosPcad.categoriaPretendida.trim()
					: categoriaPretendida?.trim() || situacaoAtual?.trim(),
			local,
			cfcA,
			exameMedicoCategoriaPermitida: dadosExameMedico.categoriaPermitida,
			exameMedicoCred1:
				dadosExameMedico.cred1 === ' '
					? dadosExameMedico.cred2
					: dadosExameMedico.cred1,
			examePsicotecnicoResultado2: dadosExamePsicotecnico.resultado2,
			dataPrimeiraHabilitacao:
				(dataPrimeiraHabilitacaoConsultarSegundaVia &&
					formatDate(dataPrimeiraHabilitacaoConsultarSegundaVia)) ||
				(dataPrimeiraHabilitacao && formatDate(dataPrimeiraHabilitacao)) ||
				'',
			dataValidadeCnh: dataValidadeCnh && formatDate(dataValidadeCnh),
			situacaoAtual,
			cfcB,
			exameMedicoRegiao: dadosExameMedico.regiao,
			dataExamePratico2Rodas:
				dataExamePratico2Rodas && formatDate(dataExamePratico2Rodas),
			dataExamePratico4Rodas:
				dataExamePratico4Rodas && formatDate(dataExamePratico4Rodas),
			exameMedicoCred2:
				dadosExameMedico.cred1 !== ' ' ? dadosExameMedico.cred2 : '',
			examePsicotecnicoRegiao: dadosExamePsicotecnico.regiao,
			dataUltimaEmissaoCnh:
				dataUltimaEmissaoCnh && formatDate(dataUltimaEmissaoCnh),

			// ENDEREÇO CADASTRADO
			enderecoCep: cepPcon || endereco.cep || '',
			enderecoLogradouro: endereco.logradouro || '',
			enderecoNumero: endereco.numero || '',
			enderecoComplemento: endereco.complemento || '',
			enderecoBairro: endereco.bairro || '',
			enderecoDescricaoMunicipio: endereco.descricaoMunicipio || '',

			// FORM
			localEmissao,
			orgaoEmissor,

			rne1:
				dadosPcad && dadosPcad.rne && dadosPcad.rne.trim() !== ''
					? dadosPcad.rne
					: rne1,
			rne2:
				dadosPcad &&
				dadosPcad.orgaoEmissorRne &&
				dadosPcad.orgaoEmissorRne.trim() !== ''
					? dadosPcad.orgaoEmissorRne
					: rne2,
			rne3:
				dadosPcad &&
				dadosPcad.localEmissaoRne &&
				dadosPcad.localEmissaoRne.trim() !== ''
					? dadosPcad.localEmissaoRne
					: rne3,
		};

		const formattedDataPreCadastro = {
			...initialValuesPreCadastro,
			usoFormularioEnum: 'MUDANCA_CATEGORIA',
			cpf: values.cpf,
			nome: removeAcentoCaracteresEspeciais(
				values.nome?.toUpperCase() || '',
			).trim(),
			nomeMae: removeAcentoCaracteresEspeciais(
				values.nomeMae?.toUpperCase() || '',
			).trim(),
			nomePai: removeAcentoCaracteresEspeciais(
				values.nomePai?.toUpperCase() || '',
			).trim(),
			sexo:
				(values?.sexo &&
					(((values.sexo.trim() === 'FEMININO' || values.sexo.trim() === 'F') &&
						'F') ||
						((values.sexo.trim() === 'MASCULINO' ||
							values.sexo.trim() === 'M') &&
							'M'))) ||
				'M',
			dataNascimento: dataNascimento && formatDate(dataNascimento),
			dataPrimeiraHabilitacao,
			tipoDocumentoEnum: values.tipoDocumento,
			numDocumento: values.numeroRg?.trim(),
			orgaoExpDocumento:
				dadosPcad &&
				dadosPcad.orgaoEmissor &&
				dadosPcad.orgaoEmissor?.trim() !== ''
					? dadosPcad.orgaoEmissor.toLocaleUpperCase()?.trim()
					: orgaoEmissor?.trim(),
			orgaoUfExpDocumento:
				dadosPcad &&
				dadosPcad.localEmissao &&
				dadosPcad.localEmissao?.trim() !== ''
					? dadosPcad?.localEmissao?.trim()
					: values.localEmissao?.trim(),
			nacionalidade: values.nacionalidade,
			codigoNaturalidade:
				(codigoNaturalidadeIBGE && Number(codigoNaturalidadeIBGE.trim())) || '',
			logradouro:
				values.endereco.logradouro &&
				removeAcentoCaracteresEspeciais(values.endereco.logradouro.trim()),
			logradouroNumero:
				values.endereco.numero && values.endereco.numero?.trim(),
			logradouroComplemento:
				values.endereco.complemento &&
				removeAcentoCaracteresEspeciais(values.endereco.complemento?.trim()),
			logradouroBairro:
				values.endereco.bairro &&
				removeAcentoCaracteresEspeciais(values.endereco.bairro?.trim()),
			logradouroCodMunicipio: values.endereco.codigoMunicipio,
			logradouroDescMunicipio: values.endereco.descricaoMunicipio,
			logradouroCep: values.endereco.cep,
			codCiretranCondutor: values.local || '',
			statusReusoEnum: 'SEM_REUSO',
			flagAtividadeRemunerada: flagAtividadeRemunerada ? 'S' : 'N',
			flagDeficienteFisico: flagDeficienteFisico ? 'S' : 'N',
			flagExameToxicologico: '',
			origemNomeSocial: '0',
			categoriaAtual:
				dadosPcad &&
				dadosPcad.categoriaAtual &&
				dadosPcad.categoriaAtual?.trim() !== ''
					? dadosPcad.categoriaAtual?.trim()
					: situacaoAtual,
			categoriaPretendida:
				dadosPcad &&
				dadosPcad.categoriaPretendida &&
				dadosPcad.categoriaPretendida?.trim() !== ''
					? dadosPcad.categoriaPretendida?.trim()
					: '',
			registro:
				dadosPcad &&
				dadosPcad.numRegistro &&
				dadosPcad.numRegistro?.trim() !== ''
					? dadosPcad.numRegistro?.trim()
					: numeroRegistro,
			numeroPgu:
				dadosPcad && dadosPcad.numPgu && dadosPcad.numPgu?.trim() !== ''
					? dadosPcad.numPgu?.trim()
					: numeroPgu,
			codCiretranHabilitacao: dadosPcad?.ciretran || '',
			cpfUsuario: user.cpf,
			codLocalidade: user.posto,
			uf,
			flagMilitar: 'N',
			rneNumero:
				dadosPcad && dadosPcad.rne && dadosPcad.rne?.trim() !== ''
					? dadosPcad.rne
					: rne1,
			rneOrgao:
				dadosPcad &&
				dadosPcad.orgaoEmissorRne &&
				dadosPcad.orgaoEmissorRne?.trim() !== ''
					? dadosPcad.orgaoEmissorRne
					: rne2,
			rneUf:
				dadosPcad &&
				dadosPcad.localEmissaoRne &&
				dadosPcad.localEmissaoRne?.trim() !== ''
					? dadosPcad.localEmissaoRne
					: rne3,
		};

		return {
			initalValuesHabilitacao: formattedDataHabilitacao,
			initialValuesPreCadastro: formattedDataPreCadastro,
		};
	}
	return {
		initalValuesHabilitacao,
		initialValuesPreCadastro,
	};
}

export function treatValuesGerarRenachIntegrado(
	values: GerarRenachIntegradoRequest,
	cpfUsuario: string,
): GerarRenachIntegradoRequest {
	const { logradouro } = values.candidato;

	const formattedData = {
		candidato: {
			...values.candidato,
			cpfUsuario,
			logradouro: letrasNumerosPontosHifen(logradouro),
		},
		dadosExameMedico: {
			codigoCiretranExameMedico: '',
			dataExame: '',
			horarioExame: '',
			idMedico: '',
			idMicroRegiaoMedico: '',
		},
		dadosExamePsicologo: {
			codigoCiretranExamePsicologo: '',
			dataExamePsicologico: '',
			horarioExamePsicologico: '',
			idMicroRegiaoPsicologo: '',
			idPsicologo: '',
		},
		evento: values.gerarRenachBase?.evento,
	};
	return formattedData;
}

// TREAT TO SEND RENACH
export function treatValues(
	evento: EventoContabilizacao,
	values: GerarRenachForm,
	user: IUser,
): GerarRenachIntegradoRequest {
	const formattedData = {
		candidato: {
			apresentaDocumentos: '',
			autorizaEmail: values.autorizaEmail || '',
			autorizaSMS: values.autorizaSMS || '',
			bairro:
				(values.logradouroBairro &&
					removeAcentoCaracteresEspeciais(values.logradouroBairro)) ||
				'',
			categoriaAtual: values.categoriaAtual?.trim(),
			categoriaPretendida: values.categoriaPretendida?.trim(),
			codigoUnidadeAtendimento: user.posto,
			celular: limparMascara(values.celular).substring(2, 11),
			cep: values.logradouroCep || '',
			cnhDigital: values.flagSolicitaCNHDigital === 'S' ? 'S' : 'N',
			codigoCiretranPostoExameMedico:
				values.flagExamePosto === 'S' ? user.posto : '',
			codigoEntidade: values.codEntidadeMilitar || '',
			codigoVerificador: values.codigoVerificador || '',
			coletaBiometrica: '',
			complemento:
				(values.logradouroComplemento &&
					removeAcentoCaracteresEspeciais(values.logradouroComplemento)) ||
				'',
			cpf: values.cpf,
			dataNascimento:
				values.dataNascimento && unformatDate(values.dataNascimento),
			dataPrimeiraHabilitacao: values.dataPrimeiraHabilitacao,
			dataValidadeCnhE: values.rneDataValidadeCnhEstrangeira || '',
			dddCelular: limparMascara(values.celular).substring(0, 2),
			dddTelefone: limparMascara(values.telefone).substring(0, 2),
			email: values.email || '',
			logradouro: removeAcentoCaracteresEspeciais(values.logradouro),
			nacionalidade: values.nacionalidade,
			naturalidade: values.codigoNaturalidade
				? values.codigoNaturalidade?.toString()
				: '',
			necessidadeVeiculoAdaptado:
				values.flagDeficienteFisico === 'S' ? 'S' : 'N',
			nome: removeAcentoCaracteresEspeciais(values.nome.toUpperCase()),
			nomeMae: removeAcentoCaracteresEspeciais(values.nomeMae.toUpperCase()),
			nomePai: removeAcentoCaracteresEspeciais(values.nomePai.toUpperCase()),
			nomeSocial:
				values.flagNomeSocial === 'S'
					? removeAcentoCaracteresEspeciais(
							values.nomeSocial ? values.nomeSocial.toUpperCase() : '',
					  )
					: '',
			nomeSocialValidadoBiometria: '',
			origemNomeSocial:
				(values.flagNomeSocial === 'S' && values.origemNomeSocial) || '',
			paisAfetivos1:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo1 ? values.paisAfetivo1.toUpperCase() : '',
					  )
					: '',
			paisAfetivos1ValidadoBiometria: '',
			paisAfetivos2:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo2 ? values.paisAfetivo2.toUpperCase() : '',
					  )
					: '',
			paisAfetivos2ValidadoBiometria: '',
			numCNHE: values.rneIdentificacaoCnhEstrangeira || '',
			numCarteiraMilitar: values.numeroIdentMilitar || '',
			numDocumento:
				(values.numDocumento && limparMascara(values.numDocumento.trim())) ||
				'',
			numPGU: values.numeroPgu || '',
			numRNE: values.rneNumero || '',
			numRegistro: values.registro ? values.registro?.trim() : '',
			numero:
				values.logradouroNumero !== 'S/N'
					? String(values.logradouroNumero)
					: '',
			orgaoDocumento: values.orgaoExpDocumento
				? onlyLetters(values.orgaoExpDocumento.toLocaleUpperCase())
				: '',
			orgaoRNE: values.rneOrgao || '',
			paisCNHE: values.rneCodPaisCnhEstrangeira || '',
			pretendeAtividadeRemunerada:
				values.flagAtividadeRemunerada === 'S' ? 'S' : 'N',
			sexo: values.sexo,
			telefone: limparMascara(values.telefone).substring(2, 10),
			testeAlfabetizacao: '',
			tipoDocumento: '1', // Fazer enum de acordo com chat da Carol p saber o tipo que enviar
			// tipoProcesso: values.tipoProcesso?.codigo || '',
			tipoProcesso: '4',
			uf: values.uf || '',
			ufDocumento: values.orgaoUfExpDocumento || '',
			ufRNE: values.rneUf || '',
		},
		dadosExameMedico: {
			codigoCiretranExameMedico: '',
			dataExame: '',
			horarioExame: '',
			idMedico: '',
			idMicroRegiaoMedico: '',
		},
		dadosExamePsicologo: {
			codigoCiretranExamePsicologo: '',
			dataExamePsicologico: '',
			horarioExamePsicologico: '',
			idMicroRegiaoPsicologo: '',
			idPsicologo: '',
		},
		gerarRenachBase: {
			evento: {
				id_atendimento: evento.id_atendimento,
				id_cidadao: evento.id_cidadao,
				ip: evento.ip,
				identificacao: evento.cpf && parseInt(limparMascara(values.cpf), 10),
				cpf: evento.cpf || '',
				canal: {
					id: 1,
					desc: 'Presencial',
					localidade: {
						desc: evento.canal?.localidade.desc || '',
						id: evento.canal?.localidade.id || 0,
					},
				},
			},
			usoFormularioEnum: 'MUDANCA_CATEGORIA',
			cpf: values.cpf,
			nome: removeAcentoCaracteresEspeciais(values.nome.toUpperCase()),
			nomeMae: removeAcentoCaracteresEspeciais(values.nomeMae.toUpperCase()),
			nomePai: removeAcentoCaracteresEspeciais(values.nomePai.toUpperCase()),
			sexo: values.sexo,
			dataNascimento: unformatDate(values.dataNascimento),
			tipoDocumentoEnum: values.tipoDocumentoEnum,
			numDocumento:
				(values.numDocumento && limparMascara(values.numDocumento.trim())) ||
				'',
			orgaoExpDocumento: values.orgaoExpDocumento
				? onlyLetters(values.orgaoExpDocumento.toLocaleUpperCase())
				: '',
			orgaoUfExpDocumento: values.orgaoUfExpDocumento || '',
			nacionalidade: values.nacionalidade,
			naturalidade:
				values.naturalidade && values.naturalidade != null
					? values.naturalidade.toString()
					: '',
			codigoNaturalidade: values.codigoNaturalidade,
			email: values.email,
			celularDDD: limparMascara(values.celular).substring(0, 2),
			celular: limparMascara(values.celular).substring(2, 11),
			logradouro: values.logradouro
				? removeAcentoCaracteresEspeciais(
						values.logradouro.trim(),
				  ).toUpperCase()
				: '',
			logradouroNumero:
				values.logradouroNumero !== 'S/N'
					? String(values.logradouroNumero)
					: null,
			logradouroComplemento: values.logradouroComplemento
				? removeAcentoCaracteresEspeciais(
						values.logradouroComplemento.trim(),
				  ).toUpperCase()
				: '',
			logradouroBairro: values.logradouroBairro
				? removeAcentoCaracteresEspeciais(
						values.logradouroBairro.trim(),
				  ).toUpperCase()
				: '',
			logradouroCodMunicipio: values.logradouroCodMunicipio || '',
			logradouroCep: values.logradouroCep || '',
			codCiretranCondutor:
				(values.codCiretranCondutor != null &&
					values.codCiretranCondutor.toString()) ||
				'',
			statusReusoEnum: 'SEM_REUSO',
			flagAtividadeRemunerada: values.flagAtividadeRemunerada === 'S',
			flagDeficienteFisico: values.flagDeficienteFisico === 'S',
			flagSolicitaCNHDigital: values.flagSolicitaCNHDigital === 'S',
			codigoVerificador: values.codigoVerificador,
			categoriaAtual: values.categoriaAtual.trim(),
			categoriaPretendida: values.categoriaPretendida.trim(),
			registro: values.registro ? values.registro.trim() : '',
			numeroPgu: values.numeroPgu || '',
			rneNumero: values.rneNumero || '',
			rneOrgao: values.rneOrgao ? onlyLetters(values.rneOrgao) : '',
			rneUf: values.rneUf !== undefined ? values.rneUf : null,
			rneCodPaisCnhEstrangeira:
				values.rneCodPaisCnhEstrangeira !== undefined
					? values.rneCodPaisCnhEstrangeira
					: '',
			rneDataValidadeCnhEstrangeira:
				values.rneDataValidadeCnhEstrangeira !== undefined
					? values.rneDataValidadeCnhEstrangeira
					: null,
			rneDataPrimeiraHabilitacaoCnhEstrangeira:
				values.rneDataPrimeiraHabilitacaoCnhEstrangeira !== undefined
					? values.rneDataPrimeiraHabilitacaoCnhEstrangeira
					: null,
			rneIdentificacaoCnhEstrangeira:
				values.rneIdentificacaoCnhEstrangeira !== undefined
					? values.rneIdentificacaoCnhEstrangeira
					: null,
			flagMilitar: values.flagMilitar === 'S',
			codEntidadeMilitar: values.codEntidadeMilitar || '',
			numeroIdentMilitar: values.numeroIdentMilitar || '',
			flagExamePosto: values.flagExamePosto === 'S',
			flagExameToxicologico: values.flagExameToxicologico === 'S',
			flagProcurador: false,
			codCiretranHabilitacao:
				(values.codCiretranHabilitacao != null &&
					values.codCiretranHabilitacao.toString()) ||
				'',
			cpfUsuario: user.cpf,
			codLocalidade: user.posto,
			nomeSocial:
				values.flagNomeSocial === 'S'
					? removeAcentoCaracteresEspeciais(
							values.nomeSocial ? values.nomeSocial.toUpperCase() : '',
					  )
					: '',
			paisAfetivo1:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo1 ? values.paisAfetivo1.toUpperCase() : '',
					  )
					: '',
			paisAfetivo2:
				values.flagFiliacaoAfetiva === 'S'
					? removeAcentoCaracteresEspeciais(
							values.paisAfetivo2 ? values.paisAfetivo2.toUpperCase() : '',
					  )
					: '',
		},
	};
	return formattedData;
}

export const schema = Yup.object<GerarRenachForm>().shape(
	{
		categoriaPretendida: Yup.string().required(
			'Campo Obrigatório: Categoria Pretendida.',
		),
		flagAtividadeRemunerada: Yup.string().required(
			'Campo Obrigatório: Pretende exercer atividade remunerada.',
		),
		flagExamePosto: Yup.string().required(
			'Campo Obrigatório: Realizar exame dentro do Posto.',
		),
		flagMilitar: Yup.string().required(
			'Campo Obrigatório: É militar e vai utilizar o CFC da corporação.',
		),
		flagExameToxicologico: Yup.string().when(['categoriaPretendida'], {
			is: categoriaPretendida =>
				categoriaPretendida !== '' &&
				categoriaPretendida !== 'A' &&
				categoriaPretendida !== 'B' &&
				categoriaPretendida !== 'AB' &&
				categoriaPretendida !== 'X' &&
				categoriaPretendida !== 'XB',
			then: Yup.string().required(
				'Campo Obrigatório: Realizou exames Toxicológicos?.',
			),
		}),
		nome: Yup.string().required('Campo Obrigatório: Nome.'),
		cpf: Yup.string().required('Campo Obrigatório: CPF.'),
		nomeMae: Yup.string().required('Campo Obrigatório: Nome da Mãe.'),
		sexo: Yup.string().required('Campo Obrigatório: Sexo.'),
		nacionalidade: Yup.string().required('Campo Obrigatório: Nacionalidade.'),
		uf: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '1',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),
		logradouroCep: Yup.string().required('Campo Obrigatório: CEP.'),
		logradouroNumero: Yup.string().required('Campo Obrigatório: Número.'),
		logradouroBairro: Yup.string().required('Campo Obrigatório: Bairro.'),
		dataNascimento: Yup.string().required(
			'Campo Obrigatório: Data de Nascimento.',
		),
		codigoNaturalidade: Yup.string().required(
			'Campo Obrigatório: Naturalidade.',
		),
		logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
		logradouroCodMunicipio: Yup.string().required(
			'Campo Obrigatório: Município.',
		),
		enderecoRecebimentoCep: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - CEP.',
		),
		enderecoRecebimentoNumero: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Número.',
		),
		enderecoRecebimentoBairro: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Bairro.',
		),
		enderecoRecebimentoLogradouro: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Logradouro.',
		),
		enderecoRecebimentoMunicipio: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Município.',
		),
		email: Yup.string()
			.when(['autorizaEmail'], {
				is: autorizaEmail => autorizaEmail === 'S' || autorizaEmail === true,
				then: Yup.string().required('Campo Obrigatório: Email.'),
			})
			.matches(userDataRegex.emailAddressUpdated, {
				message: 'Formato de e-mail inválido.',
				excludeEmptyString: true,
			})
			.test(
				'same-email',
				'A confirmação do e-mail é diferente do e-mail informado.',
				function verify(value) {
					return value !== '' ? this.parent.confirmaEmail === value : true;
				},
			),
		flagSolicitaCNHDigital: Yup.string().required(
			'Campo Obrigatório: Via Impressa ou Digital (Impressa + Digital).',
		),
		flagNomeSocial: Yup.string().required(
			'Campo Obrigatório: Deseja incluir nome social na CNH?.',
		),
		flagFiliacaoAfetiva: Yup.string().required(
			'Campo Obrigatório: Deseja incluir filiação afetiva na CNH?.',
		),
		nomeSocial: Yup.string()
			.when(['flagNomeSocial'], {
				is: flagNomeSocial => flagNomeSocial === 'S',
				then: Yup.string().required('Campo Obrigatório: Nome Social.'),
			})
			.test(
				'same-name',
				'O nome social não pode ser igual ao nome civil.',
				function verify(value) {
					return value !== '' && this.parent.flagNomeSocial === 'S'
						? this.parent.nome !== value
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-social-name',
				"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		paisAfetivo1: Yup.string()
			.when(['flagFiliacaoAfetiva'], {
				is: flagFiliacaoAfetiva => flagFiliacaoAfetiva === 'S',
				then: Yup.string().required('Campo Obrigatório: Filiação Afetiva 1.'),
			})
			.test(
				'same-name-filiacoes',
				'O nome da filiação afetiva 1 não pode ser igual ao nome da filiação afetiva 2.',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? this.parent.paisAfetivo2 !== value
						: true;
				},
			)
			.test(
				'same-name-filiacao1',
				'A filiação afetiva 1 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-1',
				"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		paisAfetivo2: Yup.string()
			.test(
				'same-name-filiacao2',
				'A filiação afetiva 2 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-2',
				"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		celular: Yup.string()
			.when(['telefone'], {
				is: telefone => telefone === '',
				then: Yup.string().required('Campo Obrigatório: Celular.'),
			})
			.when(['autorizaSMS'], {
				is: autorizaSMS => autorizaSMS === 'S' || autorizaSMS === true,
				then: Yup.string().required('Campo Obrigatório: Celular.'),
			})
			.matches(userDataRegex.cellPhoneNumber, {
				message: 'Campo Inválido: Celular.',
				excludeEmptyString: true,
			}),
		telefone: Yup.string()
			.when(['celular'], {
				is: celular => celular === '',
				then: Yup.string().required('Campo Obrigatório: Telefone.'),
			})
			.matches(userDataRegex.phoneNumber, {
				message: 'Campo Inválido: Telefone.',
				excludeEmptyString: true,
			}),
		numDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: RG.'),
		}),
		orgaoExpDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
		}),
		orgaoUfExpDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),
		rneNumero: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: RNE.'),
		}),
		rneOrgao: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
		}),
	},
	[['telefone', 'celular']],
);
