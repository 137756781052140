import React, { Suspense, useCallback, useEffect } from 'react';
import { Row, Col, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import SimpleTable from 'components/Common/Table';
import { FcSearch } from 'react-icons/fc';
import { ButtonIcon } from 'components/Common/ButtonIcon';
import { multasRenainfOrgaoRequest } from 'store/modules/api/detranCrv/MultasRenainf/multasRenainfOrgao/actions';
import { useHistory } from 'react-router-dom';
import { multasRenainfPlacaClear } from 'store/modules/api/detranCrv/MultasRenainf/multasRenainfPlaca/actions';
import Button from 'components/Common/Button';

const MultasRenainfPorPlacaResultado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const dadosRenainfPlaca = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.multasRenainf.multasRenainfPlaca.data
				?.dadosRenainfPlaca,
	);

	const { multasRenainfOrgao } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.multasRenainf,
	);

	const { salvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento,
	);

	const handleSearch = useCallback(
		(
			codigoOrgaoAutuador: string,
			numeroInfracao: string,
			codigoInfracao: string,
		) => {
			const body = {
				evento: {
					id_atendimento: salvarAtendimento.data?.idAtendimento
						? salvarAtendimento.data.idAtendimento
						: '',
					id_cidadao: salvarAtendimento.data?.idCidadao
						? salvarAtendimento.data.idCidadao
						: '',
					cpf: salvarAtendimento.data?.cpf ? salvarAtendimento.data.cpf : '',
				},
				codigoOrgaoAutuador,
				numeroInfracao,
				codigoInfracao,
			};
			dispatch(multasRenainfOrgaoRequest(body));
		},
		[dispatch, salvarAtendimento.data],
	);

	useEffect(() => {
		if (multasRenainfOrgao.status === 200 && multasRenainfOrgao.data) {
			history.push('/detran-crv/multas-renainf-orgao-autuador/resultado');
		}
	}, [multasRenainfOrgao, history]);

	const columns = [
		{
			dataIndex: '',
			title: '',
			align: 'center',
			render: (record: any) => (
				<ButtonIcon
					size="lg"
					onClick={() => {
						handleSearch(
							record.orgaoAutuador,
							record.numeroAutoInfracao,
							record.codigoInfracao,
						);
					}}
				>
					<FcSearch />
				</ButtonIcon>
			),
		},
		{
			dataIndex: 'orgaoAutuador',
			title: 'Orgão Autuador',
			align: 'center',
		},
		{
			dataIndex: 'numeroAutoInfracao',
			title: 'Auto de Infração',
			align: 'center',
		},
		{
			dataIndex: 'codigoInfracao',
			title: 'Infração',
			align: 'center',
		},
		{
			dataIndex: 'dataHoraIfracao',
			title: 'Data da Infração',
			render: (dataHoraIfracao: number) => {
				const data = dataHoraIfracao
					? new Date(dataHoraIfracao).toLocaleDateString('pt-BR')
					: '';
				return data;
			},
			align: 'center',
		},
		{
			dataIndex: 'exigibilidade',
			title: 'Exigibilidade',
			align: 'center',
			render: (text: boolean) => {
				return text === true ? 'Sim' : 'Não';
			},
		},
	];

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Row>
				<Col span={24}>
					<Section size="sm" title="DADOS DA CONSULTA">
						<Row gutter={[0, 10]}>
							<Col span={4}>Placa</Col>
							<Col span={4}>
								<strong>{dadosRenainfPlaca?.placa}</strong>
							</Col>

							<Col span={4}>UF de Emplacamento</Col>
							<Col span={4}>
								<strong>{dadosRenainfPlaca?.ufEmplacamento}</strong>
							</Col>

							<Col span={4}>Indicador Exigibilidade</Col>
							<Col span={4}>
								<strong>{dadosRenainfPlaca?.indicador}</strong>
							</Col>
						</Row>
					</Section>

					<Section size="sm" title="QUANTIDADE DE OCORRÊNCIAS">
						<Row gutter={[0, 10]}>
							<Col span={6}>Quantidade de Ocorrências</Col>

							<Col span={18}>
								<strong>{dadosRenainfPlaca?.quantidadeOcorrencia}</strong>
							</Col>
						</Row>
					</Section>

					<Section size="sm" title="OCORRÊNCIAS">
						<SimpleTable
							headers={columns}
							body={dadosRenainfPlaca?.ocorrencias}
							size="sm"
							striped
							bold
							hover={false}
							pagination={false}
						/>
					</Section>

					<Row justify="space-between" align="bottom">
						<Col>
							<ButtonVoltar
								onClick={() => dispatch(multasRenainfPlacaClear())}
							/>
						</Col>

						<Col>
							<Button className="no-print" onClick={() => window.print()}>
								Imprimir
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Suspense>
	);
};

export default MultasRenainfPorPlacaResultado;
