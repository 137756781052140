export enum Types {
	EMITIR_SEGUNDA_VIA_PLACA_REQUEST = '@DETRAN-CRV/EMITIR_SEGUNDA_VIA_PLACA_REQUEST',
	EMITIR_SEGUNDA_VIA_PLACA_SUCCESS = '@DETRAN-CRV/EMITIR_SEGUNDA_VIA_PLACA_SUCCESS',
	EMITIR_SEGUNDA_VIA_PLACA_FAILURE = '@DETRAN-CRV/EMITIR_SEGUNDA_VIA_PLACA_FAILURE',
	EMITIR_SEGUNDA_VIA_PLACA_CLEAR = '@DETRAN-CRV/EMITIR_SEGUNDA_VIA_PLACA_CLEAR',
}

export interface emitirSegundaViaPlaca {
	status: number;
	data: IResponseEmitirSegundaViaPlaca | null;
}

export interface IResponseEmitirSegundaViaPlaca {
	anoFicha: number;
	chassi: string;
	numeroficha: number;
	oficio: string;
	placa: string;
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	ip: string;
	cpf: string;
	identificacao: string;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}
export interface IRequestEmitirSegundaViaPlaca {
	evento: IEvento;
	chassi: string;
	placa: string;
	motivoAutorizacaoEmplacamento: string;
	motivoSegundaViaPlaca: string;
	municipioDestinoLacracao: {
		codigo: number;
		descricao: string;
	};
	municipioEstampador: {
		codigo: number;
		descricao: string;
	};
	tipoPlaca: string;
	tipoVeiculoOficial: string | null;
	ufDestinoLacracao: string;
	ufEstampador: string;
	unidadeTransitoAutoriza: {
		codigo: number;
	};
}
