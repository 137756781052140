import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { AssuntosRequest, Types } from './types';

// ACTIONS
import { assuntosSuccess, assuntosFailure } from './actions';

function* assuntosRequest(request: ReducerAction) {
	const { payload }: { payload: AssuntosRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`areas/${CHANNEL}/assuntos`,
		payload,
	);

	if (response.status === 200) {
		yield put(assuntosSuccess(response));
	} else {
		yield put(assuntosFailure(response));
	}
}

export default all([takeLatest(Types.ASSUNTOS_REQUEST, assuntosRequest)]);
