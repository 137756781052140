import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_BENEFICIARIOS = '@vivaLeite/CONSULTA_BENEFICIARIOS',
	CONSULTA_BENEFICIARIOS_SUCCESS = '@vivaLeite/CONSULTA_BENEFICIARIOS_SUCCESS',
	CONSULTA_BENEFICIARIOS_FAILURE = '@vivaLeite/CONSULTA_BENEFICIARIOS_FAILURE',

	REMOVE_BENEFICIARIOS = '@vivaLeite/REMOVE_BENEFICIARIOS',
	REMOVE_BENEFICIARIOS_SUCCESS = '@vivaLeite/REMOVE_BENEFICIARIOS_SUCCESS',
	REMOVE_BENEFICIARIOS_FAILURE = '@vivaLeite/REMOVE_BENEFICIARIOS_FAILURE',

	CONSULTA_MOTIVOS_CORTE = '@vivaLeite/CONSULTA_MOTIVOS_CORTE',
	CONSULTA_MOTIVOS_CORTE_SUCCESS = '@vivaLeite/CONSULTA_MOTIVOS_CORTE_SUCCESS',
	CONSULTA_MOTIVOS_CORTE_FAILURE = '@vivaLeite/CONSULTA_MOTIVOS_CORTE_FAILURE',

	CONSULTA_COTAS_ENTIDADE = '@vivaLeite/CONSULTA_COTAS_ENTIDADE',
	CONSULTA_COTAS_ENTIDADE_SUCCESS = '@vivaLeite/CONSULTA_COTAS_ENTIDADE_SUCCESS',
	CONSULTA_COTAS_ENTIDADE_FAILURE = '@vivaLeite/CONSULTA_COTAS_ENTIDADE_FAILURE',

	CONSULTA_BENEFICIARIOS_INCLUSAO = '@vivaLeite/CONSULTA_BENEFICIARIOS_INCLUSAO',
	CONSULTA_BENEFICIARIOS_INCLUSAO_SUCCESS = '@vivaLeite/CONSULTA_BENEFICIARIOS_INCLUSAO_SUCCESS',
	CONSULTA_BENEFICIARIOS_INCLUSAO_FAILURE = '@vivaLeite/CONSULTA_BENEFICIARIOS_INCLUSAO_FAILURE',

	CADASTRA_BENEFICIARIO = '@vivaLeite/CADASTRA_BENEFICIARIO',
	CADASTRA_BENEFICIARIO_SUCCESS = '@vivaLeite/CADASTRA_BENEFICIARIO_SUCCESS',
	CADASTRA_BENEFICIARIO_FAILURE = '@vivaLeite/CADASTRA_BENEFICIARIO_FAILURE',

	BENEFICIARIO_CLEAR = '@vivaLeite/BENEFICIARIO_CLEAR',

	FAMILIAR_CLEAR = '@vivaLeite/FAMILIAR_CLEAR',

	NOTIFICATION_BENEFICIARIOS_VIVA_LEITE_CLEAR = '@vivaLeite/NOTIFICATION_BENEFICIARIOS_VIVA_LEITE_CLEAR',
}

export interface IBeneficiariosState {
	statusTransacaoBeneficiarios: string;
	beneficiariosData: IBeneficiario[];
	selectMotivosCorte: OptionProps[];
	cotasEntidadesData: ICotasEntidades;
	dadosFamilia: IDadosFamilia;
}

export interface IBeneficiario {
	cod_pan_beneficio: number;
	idoso?: string;
	responsavel_crianca?: string;
}

interface ICotasEntidades {
	programa_crianca: ICotas;
	programa_idoso: ICotas;
}

interface ICotas {
	cota: number;
	cadastradas: number;
	vagas: number;
}

interface IDadosFamilia {
	cod_pessoa_cadunico_resp: string;
	responsavel: string;
	nis_responsavel: string;
	data_nasc_responsavel: string;
	cod_pessoa_cadunico_cri: string;
	crianca: string;
	data_nasc_crianca: string;
	nis_crianca: string;
	cod_familia_cadunico: string;
	renda_media_familia: string;
	cod_pessoa_cadunico_idoso: string;
	data_nasc_idoso: string;
	idoso: string;
	nis_idoso: string;
}

export interface IRequestConsultaBeneficiario {
	servico: string;
	origem: string;
	num_pan: number;
	etapa: string;
	cod_programa: number;
}

export interface IRequestSelectMotivosCorte {
	servico: string;
	origem: string;
	cod_programa: number;
}

export interface IRequestExcluir {
	servico: string;
	origem: string;
	num_pan: number;
	cod_programa: number;
	etapa: string;
	exclusoes: IObjectExcluir[];
}

export interface IObjectExcluir {
	cod_pan_beneficio: number;
	codigo_motivo: number;
	outros_motivos?: string;
}

export interface IRequestCotas {
	servico: string;
	origem: string;
	num_pan: number | string;
}

export interface IRequestIncluirBeneficiarioBusca {
	servico: string;
	origem: string;
	num_pan: number;
	cod_programa: number;
	etapa: string;
	nis: string;
}

export interface IRequestIncluirBeneficiario {
	servico: string;
	origem: string;
	num_pan: number;
	cod_programa: number;
	etapa: string;
	cod_pessoa_cadunico?: string;
	cod_familia_cadunico?: string;
	cod_pessoa_cadunico_cri?: string;
	nome_responsavel_idoso?: string;
}
