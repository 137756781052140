import { IParametroData } from '../parametros/types';

export enum Types {
	CONSULTA_PREFEITURAS = '@prefeituras/CONSULTA_PREFEITURAS',
	CONSULTA_PREFEITURAS_SUCCESS = '@prefeituras/CONSULTA_PREFEITURAS_SUCCESS',
	CONSULTA_PREFEITURAS_FAILURE = '@prefeituras/CONSULTA_PREFEITURAS_FAILURE',

	CADASTRA_PREFEITURAS = '@prefeituras/CADASTRA_PREFEITURAS',
	CADASTRA_PREFEITURAS_SUCCESS = '@prefeituras/CADASTRA_PREFEITURAS_SUCCESS',
	CADASTRA_PREFEITURAS_FAILURE = '@prefeituras/CADASTRA_PREFEITURAS_FAILURE',

	ALTERA_PREFEITURAS = '@prefeituras/ALTERA_PREFEITURAS',
	ALTERA_PREFEITURAS_SUCCESS = '@prefeituras/ALTERA_PREFEITURAS_SUCCESS',
	ALTERA_PREFEITURAS_FAILURE = '@prefeituras/ALTERA_PREFEITURAS_FAILURE',

	REMOVE_PREFEITURAS = '@prefeituras/REMOVE_PREFEITURAS',
	REMOVE_PREFEITURAS_SUCCESS = '@prefeituras/REMOVE_PREFEITURAS_SUCCESS',
	REMOVE_PREFEITURAS_FAILURE = '@prefeituras/REMOVE_PREFEITURAS_FAILURE',

	CONSULTA_PREFEITURAS_SERVICOS = '@prefeituras/CONSULTA_PREFEITURAS_SERVICOS',
	CONSULTA_PREFEITURAS_SERVICOS_SUCCESS = '@prefeituras/CONSULTA_PREFEITURAS_SERVICOS_SUCCESS',
	CONSULTA_PREFEITURAS_SERVICOS_FAILURE = '@prefeituras/CONSULTA_PREFEITURAS_SERVICOS_FAILURE',

	CADASTRA_PREFEITURAS_SERVICOS = '@prefeituras/CADASTRA_PREFEITURAS_SERVICOS',
	CADASTRA_PREFEITURAS_SERVICOS_SUCCESS = '@prefeituras/CADASTRA_PREFEITURAS_SERVICOS_SUCCESS',
	CADASTRA_PREFEITURAS_SERVICOS_FAILURE = '@prefeituras/CADASTRA_PREFEITURAS_SERVICOS_FAILURE',

	ALTERA_PREFEITURAS_SERVICOS = '@prefeituras/ALTERA_PREFEITURAS_SERVICOS',
	ALTERA_PREFEITURAS_SERVICOS_SUCCESS = '@prefeituras/ALTERA_PREFEITURAS_SERVICOS_SUCCESS',
	ALTERA_PREFEITURAS_SERVICOS_FAILURE = '@prefeituras/ALTERA_PREFEITURAS_SERVICOS_FAILURE',

	REMOVE_PREFEITURAS_SERVICOS = '@prefeituras/REMOVE_PREFEITURAS_SERVICOS',
	REMOVE_PREFEITURAS_SERVICOS_SUCCESS = '@prefeituras/REMOVE_PREFEITURAS_SERVICOS_SUCCESS',
	REMOVE_PREFEITURAS_SERVICOS_FAILURE = '@prefeituras/REMOVE_PREFEITURAS_SERVICOS_FAILURE',

	CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS = '@prefeituras/CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS',
	CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS = '@prefeituras/CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS',
	CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE = '@prefeituras/CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE',

	CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS = '@prefeituras/CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS',
	CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS = '@prefeituras/CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS',
	CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE = '@prefeituras/CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE',

	ALTERA_PREFEITURAS_SERVICOS_PARAMETROS = '@prefeituras/ALTERA_PREFEITURAS_SERVICOS_PARAMETROS',
	ALTERA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS = '@prefeituras/ALTERA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS',
	ALTERA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE = '@prefeituras/ALTERA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE',

	REMOVE_PREFEITURAS_SERVICOS_PARAMETROS = '@prefeituras/REMOVE_PREFEITURAS_SERVICOS_PARAMETROS',
	REMOVE_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS = '@prefeituras/REMOVE_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS',
	REMOVE_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE = '@prefeituras/REMOVE_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE',

	REDIRECT_PREFEITURAS_CLEAR = '@prefeituras/REDIRECT_PREFEITURAS_CLEAR',
	NEXT_STEP_PREFEITURAS_CLEAR = '@prefeituras/NEXT_STEP_PREFEITURAS_CLEAR',
}

export interface PrefeiturasServicosMunicipaisState {
	prefeiturasData: IPrefeituraData[];
	prefeiturasServicosData: IPrefeituraServicoData[];
	prefeiturasServicosParametrosData: IPrefeiturasServicosParametrosData[];
	totalRegistros: number;
	nextStep: boolean;
	codigoRedirect: string;
	redirect: boolean;
	situation: string;
}

export interface IPrefeituraData {
	codigo: number;
	codigo_orgao: string;
	descricao: string;
	descricao_orgao: string;
	nome: string;
}

export interface IPrefeituraServicoData {
	codigo: number;
	codigo_prefeitura: number;
	codigo_servico: number;
	contabiliza: boolean;
	endpoint: string;
	primeiro_servico: boolean;
	proximo_servico?: null | number;
}

export interface IPrefeiturasServicosParametrosData {
	grupo_obrigatorio?: string;
	parametro: IParametroData;
}
