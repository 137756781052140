import React from 'react';

import { Row, Col } from 'antd';
import Input from 'components/Common/Form/Input/Input';
import CheckBox from 'components/Common/Form/Checkbox';
import { Container } from './styles';

interface IObservacoes {
	exigObservacao: string;
	exigObservacao01: string;
	exigObservacao02: string;
	exigObservacao03: string;
	exigObservacao04: string;
	exigObservacao05: string;
	exigObservacao06: string;
	exigObservacao07: string;
	exigObservacao08: string;
	exigObservacao09: string;
	exigObservacao10: string;
}

interface IData {
	data: IObservacoes;
}

const Observacoes: React.FC<IData> = ({ data }) => {
	return (
		<Container>
			<Row gutter={[15, 5]}>
				<Col span={24}>
					<CheckBox
						name="observacoes"
						checked={!!data?.exigObservacao?.trim()}
						onChange={() => {}}
						disabled
						subtitle="Observações"
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Input
						name="exigObservacao01"
						value={data?.exigObservacao01}
						disabled
					/>
				</Col>
				<Col span={24}>
					<Input
						name="exigObservacao02"
						value={data?.exigObservacao02}
						disabled
					/>
				</Col>
				<Col span={24}>
					<Input
						name="exigObservacao03"
						value={data?.exigObservacao03}
						disabled
					/>
				</Col>
				<Col span={24}>
					<Input
						name="exigObservacao04"
						value={data?.exigObservacao04}
						disabled
					/>
				</Col>
				<Col span={24}>
					<Input
						name="exigObservacao05"
						value={data?.exigObservacao05}
						disabled
					/>
				</Col>
				<Col span={24}>
					<Input
						name="exigObservacao06"
						value={data?.exigObservacao06}
						disabled
					/>
				</Col>
				<Col span={24}>
					<Input
						name="exigObservacao07"
						value={data?.exigObservacao07}
						disabled
					/>
				</Col>
				<Col span={24}>
					<Input
						name="exigObservacao08"
						value={data?.exigObservacao08}
						disabled
					/>
				</Col>
				<Col span={24}>
					<Input
						name="exigObservacao09"
						value={data?.exigObservacao09}
						disabled
					/>
				</Col>
				<Col span={24}>
					<Input
						name="exigObservacao10"
						value={data?.exigObservacao10}
						disabled
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default Observacoes;
