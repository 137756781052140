import styled, { css } from 'styled-components';
import { darken } from 'polished';

interface Props {
	color: 'green' | 'red';
}

export const Title = styled.h4`
	font-size: calc(0.9rem + ${props => props.theme.fontScale}%);
	font-weight: 500;
`;

export const QuestionTitle = styled.span`
	font-size: calc(1rem + ${props => props.theme.fontScale}%);
	font-weight: 500;
	color: #cc3333;
	text-align: center;
`;

export const Button = styled.button<Props>`
	border: none;
	color: #fff;
	border-radius: 4px;
	padding: 8px 40px;
	font-size: calc(0.9rem + ${props => props.theme.fontScale}%);
	font-weight: 500;
	background-color: #5cb85c;
	text-transform: uppercase;
	transition: background-color 0.2s ease;

	&:first-child {
		margin-right: 10px;
	}

	&:hover {
		background-color: ${darken(0.2, '#5cb85c')};
	}

	${props =>
		props.color === 'red' &&
		css`
			background-color: #d9534f;

			&:hover {
				background-color: ${darken(0.2, '#d9534f')};
			}
		`};
`;
