// ENTITIES
import { IProtocoloFic as IRequestProtocoloFic } from 'pages/iirgd/RgFicDigital/StatusCadastro/Buttons/ImprimirProtocolo/functions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IProtocoloFic } from './types';

export function protocoloFicRequest(
	payload: IRequestProtocoloFic,
): ReducerAction {
	return {
		type: Types.PROTOCOLO_FIC_REQUEST,
		payload,
	};
}
export function protocoloFicSuccess(payload: IProtocoloFic): ReducerAction {
	return {
		type: Types.PROTOCOLO_FIC_SUCCESS,
		payload,
	};
}
export function protocoloFicFailure(): ReducerAction {
	return {
		type: Types.PROTOCOLO_FIC_FAILURE,
		payload: null,
	};
}
export function protocoloFicClear(): ReducerAction {
	return {
		type: Types.PROTOCOLO_FIC_CLEAR,
		payload: null,
	};
}
