import { IPrimeiroEmplacamento } from 'pages/DetranCrv/SolicitarDocumentos/PrimeiroEmplacamento/form';
import { Types } from './types';

export function primeiroEmplacamentoRequest(payload: IPrimeiroEmplacamento) {
	return {
		type: Types.PRIMEIRO_EMPLACAMENTO_REQUEST,
		payload,
	};
}
export function primeiroEmplacamentoSuccess(payload: any) {
	return {
		type: Types.PRIMEIRO_EMPLACAMENTO_SUCCESS,
		payload,
	};
}
export function primeiroEmplacamentoFailure(payload: []): any {
	return {
		type: Types.PRIMEIRO_EMPLACAMENTO_FAILURE,
		payload,
	};
}

export function primeiroEmplacamentoClear(): any {
	return {
		type: Types.PRIMEIRO_EMPLACAMENTO_CLEAR,
	};
}

export function primeiroRedirectEscolhaRequest(payload: {
	chassi: string;
	cpfCnpjProprietario: number | string;
}) {
	return {
		type: Types.PRIMEIRO_REDIRECT_ESCOLHA_REQUEST,
		payload,
	};
}
