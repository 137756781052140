// RENOVAÇÃO
import Renovacao from './renovacao/types';

// SEGUNDA VIA
import SegundaVia from './segundaVia/types';

// DETRAN CNH
import CnhDefinitiva from './cnhDefinitiva/types';

// PRIMEIRA HABILITAÇÃO
import PrimeiraHabilitacao from './primeiraHabilitacao/types';

// PERMISSIONÁRIO PONTUADO
import PermissionarioPontuado from './permissionarioPontuado/types';

// ADIÇÃO CATEGORIA
import AdicaoCategoria from './adicaoCategoria/types';

// MUDANÇA CATEGORIA
import MudancaCategoria from './mudancaCategoria/types';

// PERMISSAO INTERNACIONAL - PID
import PermissaoInternacional from './permissaoInternacional/types';

// PERMISSAO INTERNACIONAL AUTOMATIZADO- PID
import PermissaoInternacionalAutomatizado from './permissaoInternacionalAutomatizado/types';

// REABILITAÇÃO
import Reabilitacao from './reabilitacao/types';

// DIVISAO EQUITATIVA
import DivisaoEquitativa from './divisaoEquitativa/types';

// REABILITAÇÃO
import Condutor from './condutor/types';

// CNH DIGITAL
import CnhDigital from './cnhDigital/types';

// TRANSFERÊNCIA MUNICIPAL
import TransferenciaMunicipal from './transferenciaMunicipal/types';

// ENUM
import EnumServices from './enumServices/types';

// BIOMETRIA
import Biometria from './biometria/types';

// DADOS DOMÍNIO
import DadosDominio from './dadosDominio/types';

// AGENDAMENTO MÉDICO E PSICOLÓGICO
import AgendamentoMedicoPsicologico from './agendamentoMedicoPsicologico/types';

// IMPRIMIR
import Imprimir from './imprimir/types';

// CONSULTA
import Consulta from './consulta/types';

// SOCKETS
import Sockets from './sockets/types';

// PORTAL DETRAN
import PortalDetran from './portalDetran/types';

// EPORTAL
import Eportal from './eportal/types';

// POSTO ATENDIMENTO
import PostoAtendimento from './postoAtendimento/types';

// RELATORIO
import Relatorio from './relatorio/types';

// IIRGDDetran
import IIRGDDetran from './IIRGDDetran/types';

// CIDADAO
import Cidadao from './cidadao/types';

// EVENTOS SESSAO
import Evento from './evento/types';

// RENACH INTEGRADO
import RenachIntegrado from './renachIntegrado/types';

// UTILS
import Utils from './utils/types';

// ALTERAÇÃO ENDEREÇO
import AlteracaoEndereco from './alteracaoEndereco/types';

// CONSULTAR SOLICITAÇÃO EMISSÃO PID
import SolicitacaoEmissaoPID from './solicitacaoEmissaoPID/types';

// NOME SOCIAL
import NomeSocial from './nomeSocial/types';

export enum Types {
	DETRAN_CNH_CLEAR = '@cnh/DETRAN_CNH_CLEAR',
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	ip: string;
	cpf: string;
	identificacao: string;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface DetranCnhState {
	renovacao: Renovacao;
	segundaVia: SegundaVia;
	cnhDefinitiva: CnhDefinitiva;
	primeiraHabilitacao: PrimeiraHabilitacao;
	permissionarioPontuado: PermissionarioPontuado;
	adicaoCategoria: AdicaoCategoria;
	mudancaCategoria: MudancaCategoria;
	PermissaoInternacional: PermissaoInternacional;
	permissaoInternacionalAutomatizado: PermissaoInternacionalAutomatizado;
	permissaoInternacional: PermissaoInternacional;
	reabilitacao: Reabilitacao;
	divisaoEquitativa: DivisaoEquitativa;
	condutor: Condutor;
	cnhDigital: CnhDigital;
	transferenciaMunicipal: TransferenciaMunicipal;
	enumServices: EnumServices;
	biometria: Biometria;
	dadosDominio: DadosDominio;
	agendamentoMedicoPsicologico: AgendamentoMedicoPsicologico;
	imprimir: Imprimir;
	consulta: Consulta;
	sockets: Sockets;
	portalDetran: PortalDetran;
	eportal: Eportal;
	postoAtendimento: PostoAtendimento;
	relatorio: Relatorio;
	IIRGDDetran: IIRGDDetran;
	cidadao: Cidadao;
	evento: Evento;
	renachIntegrado: RenachIntegrado;
	utils: Utils;
	alteracaoEndereco: AlteracaoEndereco;
	solicitacaoEmissaoPID: SolicitacaoEmissaoPID;
	nomeSocial: NomeSocial;
}
