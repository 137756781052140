import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_VEICULO_API } from 'services/rotasp/path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaRestricaoVeiculo } from './types';

// ACTIONS
import {
	consultarRestricaoVeiculoSuccess,
	consultarRestricaoVeiculoFailure,
} from './actions';

function* consultarRestricaoVeiculo(request: ReducerAction) {
	const {
		payload,
		token,
	}: { token?: string; payload: IRequestConsultaRestricaoVeiculo } = request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_VEICULO_API,
		'v2/restricoes',
		payload,
	);

	if (response.status === 200) {
		yield put(consultarRestricaoVeiculoSuccess(response));
	} else {
		yield put(consultarRestricaoVeiculoFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_RESTRICAO_VEICULO, consultarRestricaoVeiculo),
]);
