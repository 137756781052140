// TYPES
import { ReducerAction } from 'store/modules/types';
import { RespostaNaoResolvidaRequest, Types } from './types';

export function respostaNaoResolvidaRequest(
	payload: RespostaNaoResolvidaRequest,
): ReducerAction {
	return {
		type: Types.RESPOSTA_NAO_RESOLVIDA_REQUEST,
		payload,
	};
}
export function respostaNaoResolvidaSuccess(payload: object): ReducerAction {
	return {
		type: Types.RESPOSTA_NAO_RESOLVIDA_SUCCESS,
		payload,
	};
}
export function respostaNaoResolvidaFailure(payload: object): ReducerAction {
	return {
		type: Types.RESPOSTA_NAO_RESOLVIDA_FAILURE,
		payload,
	};
}
export function respostaNaoResolvidaClear(): ReducerAction {
	return {
		type: Types.RESPOSTA_NAO_RESOLVIDA_CLEAR,
		payload: null,
	};
}
