import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaFichaAluno, ConsultaFichaAlunoData } from './types';

export const INITIAL_STATE: ConsultaFichaAluno = {
	status: 0,
	data: null,
};

export default function consultarFichaAluno(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaFichaAluno {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_FICHA_ALUNO_REQUEST: {
				break;
			}
			case Types.CONSULTA_FICHA_ALUNO_SUCCESS: {
				const dadosFichaAluno: ConsultaFichaAlunoData = {
					...action.payload.response.data,
					dadosPessoais: {
						...action.payload.response.data.dadosPessoais,
						numRA:
							action.payload.response.data.numeroRA ||
							action.payload.request.numeroRA ||
							'',
						digitoRA:
							action.payload.response.data.digitoRA ||
							action.payload.request.digitoRA ||
							'',
						siglaUFRA:
							action.payload.response.data.siglaUFRA ||
							action.payload.request.siglaUFRA ||
							'',
					},
				};
				draft.data = dadosFichaAluno;
				draft.status = action.payload.response.status;
				break;
			}
			case Types.CONSULTA_FICHA_ALUNO_FAILURE: {
				draft.status = 400;
				break;
			}
			case Types.CONSULTA_FICHA_ALUNO_CLEAN: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
	});
}
