export enum Types {
	CONSULTAR_DARE_GUIA_REQUEST = '@dare/CONSULTAR_DARE_GUIA_REQUEST',
	CONSULTAR_DARE_CPF_REQUEST = '@dare/CONSULTAR_DARE_CPF_REQUEST',
	CONSULTAR_DARE_RG_REQUEST = '@dare/CONSULTAR_DARE_CPF_RG',
	CONSULTAR_DARE_SUCCESS = '@dare/CONSULTAR_DARE_SUCCESS',
	CONSULTAR_DARERG_SUCCESS = '@dare/CONSULTAR_DARERG_SUCCESS',
	CONSULTAR_DARE_CLEAR = '@dare/CONSULTAR_DARE_CLEAR',
}

export interface IConsultarDareState {
	status: number;
	data: IConsultarDare[] | null;
	tipo: 'cpf' | 'dare' | null;
}

export interface IConsultarDare {
	key?: string;
	id: string;
	razaoSocialGuiaMae: string;
	orgao?: string | number | null;
	dataGeracao: string;
	dataVencimento: string;
	loginUsuarioEmissor: number;
	codigoGuiaMae: number;
	identificacaoEmissor: number;
	identificacaoContribuinte: number;
	cpf: string;
	nomeCidadao: string;
	idCidadao: string;
	enderecoContribuinte: string;
	emailTo: string;
	telefoneDdd: string;
	telefoneNum: string;
	numeroControleGuiaMae: number;
	numeroSequencialGuiaMae: number;
	codigoBarraGuiaMae: string;
	codigoBarraGuiaMaeCalc: string;
	valorTotalReceita: number;
	valorTotalJurosMora: number;
	valorTotalMulta: number;
	valorTotalAcrescimoFinanceiro: number;
	valorTotalHonorariosAdvocaticios: number;
	valorTotal: number;
	idAtendimento: string;
	dataAgendamento: string;
	numProtocolo: string;
	agendamentoLocal: string;
	agendamentoEndereco: string;
	emissaoAutomatica: boolean | string;
	origem: number;
	status: number;
	statusDescricao: string;
	dataInclusao: string;
	dataAlteracao: string;
	ipUsuario: string;
	taxasRes: ITaxaConsultarDare[];
	pagamento?: PagamentoDare;
	imprimirDare: boolean | string;
	boletoRes: IBoletoDare;
	cili: boolean | string;
	dataVinculoRg: string;
	statusCode: number;
}

interface PagamentoDare {
	id: string;
	codigoBanco: number;
	codigoAgencia: string;
	codigoBarras: string;
	dataArrecadacao: string;
	dataRecebimento: string;
	dataVencimento: string;
	formaArrecadacao: number;
	formaPagamento: number;
	identificadorRegistro: number;
	numeroAutenticacao: string;
	numeroControle: number;
	numeroControleGuia: string;
	tipoDocumento: string;
	numeroReferencia: number;
	sequencialArquivo: number;
	sequencialRegistro: number;
	tipoArquivoOrigem: number;
	tipoReceita: number;
	tipoTransacao: number;
	valorAcrescimoFinanceiro: number;
	valorHonorariosAdvocaticios: number;
	valorJurosMora: number;
	valorRecebido: number;
	valorReceita: number;
	valorTotal: number;
	ativo: string;
	direcaoOrdenacao: number;
	excluido: string;
	numeroPagina: number;
	tamanhoPagina: number;
	origem: string;
	codigoIdentificadorEmissor: number;
	servicoCodigo: number;
	servicoDescricao: string;
	cpf: number;
	origemDescricao: string;
	cili: string;
	reprocessamentoFila: string;
	statusCode: number;
}

export interface ITaxaConsultarDare {
	id: string;
	razaoSocial: string;
	dataInclusao: string;
	codigoEnvio: number;
	sequenciaOrdenacao: number;
	dataNascimento: string;
	identificador: string;
	identificadorTipo: number;
	identificacaoEmissor: number;
	codigoReceita: number;
	referencia: string;
	identificadorRegistro: number;
	codigoGuiaFilhote: number;
	numeroControleGuiaFilhote: string;
	codigoBarraGuiaFilhote: string;
	valorReceita: number;
	valorJurosMora: number;
	valorMulta: number;
	valorAcrescimoFinanceiro: number;
	valorHonorariosAdvocaticios: number;
	valorTotal: number;
	postoCodigo: string;
	servicoTipo: number;
	servicoDescricao: string;
	telefoneDdd: string;
	telefoneNum: string;
	status: number;
	statusDescricao: string;
	principal: string;
	situacaoGuia: string;
	postoDescricao: string;
	dataQueima: string;
	dataAlteracao: string;
	indicadorImpressao: string;
	envioPelosCorreios: false;
	statusCode: number;
	dataVinculoRg: string;
	statusCili: string;
	statusCiliDescricao: string;
	emissaoAutomatica: boolean;
}

export interface IBoletoDare {
	boleto: string;
	boletoBase64: string;
	dataInclusao: string;
	id: string;
	statusCode: number;
}

export interface IDependenteDare {
	nome: string;
	grauParentesco: string;
	dataNascimento: string;
	numeroRg: string;
	segundaVia: boolean;
}
