import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_IIRGD } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, LibracaoCiliRequest } from './types';

// ACTIONS
import { libracaoCiliSuccess, libracaoCiliFailure } from './actions';

function* libracaoCiliRequest(request: ReducerAction) {
	const { payload }: { payload: LibracaoCiliRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		`emissao-carteira-identidade/${CHANNEL}/cili-simplificada`,
		payload,
	);

	if (response.status === 200 || response.status === 204) {
		yield put(libracaoCiliSuccess(response));
	} else {
		yield put(libracaoCiliFailure(response));
	}
}

export default all([
	takeLatest(Types.LIBERACAO_CILI_REQUEST, libracaoCiliRequest),
]);
