import React from 'react';

import { Row, Col } from 'antd';

// REDUX
import { useLocation } from 'react-router-dom';

// COMPONENTS
import Section from 'components/Common/Section';

// UTILS
import { patternFormat } from 'utils/genericFunctions';

const DadosMutuario: React.FC = () => {
	const location: any = useLocation();
	const { state: MutuarioState } = location;

	return (
		<>
			{MutuarioState && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Section size="lg" title="Dados do mutuário" titleSize="sm">
							<Row gutter={[0, 5]}>
								<Col span={3}>CPF:</Col>

								<Col>
									<b>{MutuarioState.cpf}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={3}>Nome:</Col>

								<Col>
									<b>{MutuarioState.nome.toUpperCase()}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={3}>E-mail:</Col>

								<Col>
									<b>{MutuarioState.email.toUpperCase()}</b>
								</Col>
							</Row>

							<Row gutter={[0, 5]}>
								<Col span={12}>
									<Row>
										<Col span={6}>Celular:</Col>

										<Col>
											<b>
												{MutuarioState.celular ? (
													<>
														{MutuarioState.celular.length === 11
															? patternFormat(
																	MutuarioState.celular,
																	'(##) #####-####',
															  )
															: patternFormat(
																	MutuarioState.celular,
																	'(##) ####-####',
															  )}
													</>
												) : (
													'(___) _____-_____'
												)}
											</b>
										</Col>
									</Row>
								</Col>

								<Col span={12}>
									<Row>
										<Col span={6}>Telefone:</Col>

										<Col>
											<b>
												{MutuarioState.telefoneResidencial
													? patternFormat(
															MutuarioState.telefoneResidencial,
															'(##) ####-####',
													  )
													: '(___) _____-_____'}
											</b>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row>
								<Col span={12}>
									<Row>
										<Col span={6}>Tel. Comercial:</Col>

										<Col>
											<b>
												{MutuarioState.telefoneComercial
													? patternFormat(
															MutuarioState.telefoneComercial,
															'(##) ####-####',
													  )
													: '(___) _____-_____'}
											</b>
										</Col>
									</Row>
								</Col>

								<Col span={12}>
									<Row>
										<Col span={6}>Tel. Contato:</Col>

										<Col>
											<b>
												{MutuarioState.telefoneContato
													? patternFormat(
															MutuarioState.telefoneContato,
															'(##) ####-####',
													  )
													: '(___) _____-_____'}
											</b>
										</Col>
									</Row>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			)}
		</>
	);
};

export default DadosMutuario;
