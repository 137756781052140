import * as Yup from 'yup';

export interface IFormPropostaAcordo {
	novaDataPagamento: string;
	novoValorEntrada: number | string;
	entradaAcordoPadrao: number | string;
	novoPrazoAcordo: string;
	prazoAcordoPadrao: string;
}

export const initialValues: IFormPropostaAcordo = {
	novaDataPagamento: '',
	novoValorEntrada: '',
	entradaAcordoPadrao: '',
	novoPrazoAcordo: '',
	prazoAcordoPadrao: '',
};

// function validaPeriodo5Dias(data: string): boolean {
// 	const date = new Date();

// 	const dataHoje = new Date(
// 		date.getFullYear(),
// 		date.getMonth(),
// 		date.getDate(),
// 	);

// 	const dataSplitted = data.split('/');
// 	const novaDataPagamento = new Date(
// 		Number(dataSplitted[2]),
// 		Number(dataSplitted[1]) - 1,
// 		Number(dataSplitted[0]),
// 	);

// 	const dataLimite = new Date(
// 		date.getFullYear(),
// 		date.getMonth(),
// 		date.getDate(),
// 	);
// 	dataLimite.setDate(dataLimite.getDate() + 5);

// 	if (novaDataPagamento < dataHoje) return false;
// 	if (novaDataPagamento > dataLimite) return false;
// 	if (!(novaDataPagamento >= dataHoje && novaDataPagamento <= dataLimite))
// 		return false;

// 	return true;
// }

export const schema = Yup.object<IFormPropostaAcordo>().shape({
	// required: Yup.string().when(
	// 	['novaDataPagamento', 'novoValorEntrada', 'novoPrazoAcordo'],
	// 	{
	// 		is: (novaDataPagamento, novoValorEntrada, novoPrazoAcordo) =>
	// 			novaDataPagamento === '' &&
	// 			novoValorEntrada === '' &&
	// 			novoPrazoAcordo === '',
	// 		then: Yup.string().required(
	// 			'Campo Obrigatório: Preencha pelo menos uma das opções para a nova simulação.',
	// 		),
	// 	},
	// ),
	// novaDataPagamento: Yup.string()
	// 	.test({
	// 		name: 'validaPeriodo5Dias',
	// 		exclusive: true,
	// 		message: 'Campo Inválido: Data de pagamento do acordo.',
	// 		test: value => validaPeriodo5Dias(value),
	// 	})
	// 	.nullable(),
	// valorEntrada: Yup.string()
	// 	.when(['novoValorEntrada', 'entradaAcordoPadrao'], {
	// 		is: (novoValorEntrada, entradaAcordoPadrao) =>
	// 			!(Number(novoValorEntrada) > entradaAcordoPadrao),
	// 		then: Yup.string().required(
	// 			'Campo Inválido: Valor da amortização inicial deve ser superior ao do acordo padrão.',
	// 		),
	// 	})
	// 	.nullable(),
	prazoAcordo: Yup.string()
		.when(['novoPrazoAcordo', 'prazoAcordoPadrao'], {
			is: (novoPrazoAcordo, prazoAcordoPadrao) =>
				!(Number(novoPrazoAcordo) < Number(prazoAcordoPadrao)),
			then: Yup.string().required(
				'Campo Inválido: Novo prazo deve ser menor que o do acordo padrão.',
			),
		})
		.nullable(),
});
