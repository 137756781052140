import styled, { css } from 'styled-components';
import { rgba } from 'polished';

export const Container = styled.div`
	width: 100%;

	${props => css`
		border: 1px solid ${props.theme.colors.border};
	`};

	&:hover {
		background-color: #f5f5f5;

		${props =>
			props.theme.title !== 'light' &&
			css`
				background-color: ${rgba(0, 0, 0, 0.3)};
			`};
	}

	& + div {
		margin-top: -1px;
	}
`;

export const Button = styled.button`
	${props => css`
		background-color: transparent;
		border: unset;
		color: ${rgba(props.theme.typography.primary, 0.6)};
		cursor: pointer;
		display: block;
		font-size: calc(0.95rem + ${props.theme.fontScale}%);
		font-weight: 700;
		line-height: 20px;
		padding: 8px;
		text-align: left;
		width: 100%;
	`};
`;
