import { ReducerAction } from 'store/modules/types';

import { Types, IRequestAgendarExames, PreSaveData } from './types';

export function agendarExamesRequest(
	data: IRequestAgendarExames,
): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_REQUEST,
		payload: data,
	};
}
export function agendarExamesSuccess(payload: object): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_SUCCESS,
		payload,
	};
}
export function agendarExamesFailure(payload: object): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_FAILURE,
		payload,
	};
}
export function agendarExamesPreSave(data: PreSaveData): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_PRE_SAVE,
		payload: data,
	};
}
export function agendarExamesPreSaveClear(): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_PRE_SAVE_CLEAR,
		payload: null,
	};
}
export function agendarExamesClear(): ReducerAction {
	return {
		type: Types.AGENDAR_EXAMES_CLEAR,
		payload: null,
	};
}
