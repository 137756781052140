import * as Yup from 'yup';

export interface IFormTransferirPontuacao {
	codigoAit?: string;
	condutorNome: string;
	condutorCpf: string;
	condutorCnh: string;
	condutorCnhUf: string;
	condutorCnhCategoria: string;
	fotoCnh: string;
	fotoRg: string;
	fotoCpf: string;
	fotoContratoSocial: string;
	fotoFormulario: string;
	fotoProprietarioCnh: string;
	fotoProprietarioCpf: string;
	fotoProprietarioRg: string;
}

export const InitialValues: IFormTransferirPontuacao = {
	condutorNome: '',
	condutorCpf: '',
	condutorCnh: '',
	condutorCnhUf: '',
	condutorCnhCategoria: '',
	fotoCnh: '',
	fotoRg: '',
	fotoCpf: '',
	fotoContratoSocial: '',
	fotoFormulario: '',
	fotoProprietarioCnh: '',
	fotoProprietarioCpf: '',
	fotoProprietarioRg: '',
};

export const schema = Yup.object<IFormTransferirPontuacao>().shape({
	condutorNome: Yup.string().required('Campo Obrigatório: Nome.'),
	condutorCpf: Yup.string()
		.required('Campo Obrigatório: CPF.')
		.test({
			name: 'endpoint_required',
			message: 'Campo CPF: Tamanho mínimo de 11 caracteres.',
			test: (cpf: any) => cpf === '' || cpf.length === 11,
		}),
	condutorCnh: Yup.string().required('Campo Obrigatório: Número CNH.'),
	condutorCnhUf: Yup.string().required('Campo Obrigatório: Estado.'),
	condutorCnhCategoria: Yup.string().required(
		'Campo Obrigatório: Categoria CNH.',
	),
	fotoCnh: Yup.string().required('Campo Obrigatório: CNH.'),
	fotoRg: Yup.string().required('Campo Obrigatório: RG.'),
	fotoCpf: Yup.string().required('Campo Obrigatório: CPF.'),
	fotoFormulario: Yup.string().required('Campo Obrigatório: Formulário.'),
	fotoProprietarioCnh: Yup.string().required(
		'Campo Obrigatório: CNH do Proprietário.',
	),
	fotoProprietarioCpf: Yup.string().required(
		'Campo Obrigatório: CPF do Proprietário.',
	),
	fotoProprietarioRg: Yup.string().required(
		'Campo Obrigatório: RG do Proprietário.',
	),
});
