import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaAtendimentoPjRequest, Types } from './types';

// ACTIONS
import {
	consultaAtendimentoPjSuccess,
	consultaAtendimentoPjFailure,
} from './actions';

function* consultaAtendimentoPjRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultaAtendimentoPjRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`atendimentos-cip/${CHANNEL}/consumidores-pj/consulta-cip`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaAtendimentoPjSuccess(response));
	} else {
		yield put(consultaAtendimentoPjFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_ATENDIMENTO_PJ_REQUEST,
		consultaAtendimentoPjRequest,
	),
]);
