import React from 'react';

import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ColorBox from 'components/Common/ColorBox';

import { RiAlertFill } from 'react-icons/ri';
import { AlertArea } from './styled';

interface AlertaProps {
	nomeCondutor: string;
	servico: string;
	onClickLink: () => void;
}

export function AlertaCnhVencida({
	nomeCondutor,
	servico,
	onClickLink,
}: AlertaProps) {
	return (
		<Section title="Status Condutor">
			<h3 style={{ textTransform: 'uppercase' }}>{nomeCondutor}</h3>
			<AlertArea>
				<ColorBox bgColor="vermelho" type="error">
					<span className="alert-area__title-box-area">
						<RiAlertFill color="#c33" size={25} />
						<h3>Atenção</h3>
					</span>
					<span className="alert-area__message-box-area">
						A CNH pesquisada está vencida. Clique em Prosseguir para realizar
						{` ${servico}`}
					</span>
					<div className="buttons-area">
						<ButtonImage type="button" src="prosseguir" onClick={onClickLink} />
					</div>
				</ColorBox>
			</AlertArea>
		</Section>
	);
}
