import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	participacoesAudienciaSuccess,
	participacoesAudienciaFailure,
} from './actions';

function* participacoesAudienciaRequest() {
	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`dominios-crm/${CHANNEL}/participacoes-audiencia`,
		{},
	);

	if (response.status === 200) {
		yield put(participacoesAudienciaSuccess(response));
	} else {
		yield put(participacoesAudienciaFailure(response));
	}
}

export default all([
	takeLatest(
		Types.PARTICIPACOES_AUDIENCIA_REQUEST,
		participacoesAudienciaRequest,
	),
]);
