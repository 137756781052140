import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';

// ROUTES
import {
	ROUTE_DETRAN_CNH_RENOVACAO_AGENDAMENTO_PSICOLOGICO,
	ROUTE_DETRAN_CNH_RENOVACAO_RENACH,
} from 'pages/DetranCnh/routes/paths';
import { useHistory } from 'react-router-dom';
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultaCategoriaRequest } from 'store/modules/api/detranCnh/consulta/categoria/actions';

// FORM
import AgendamentoMedicoRenachIntegrado from 'pages/DetranCnh/components/pages/AgendamentoMedicoRenachIntegrado';
import { initialValues, dadosCidadao } from './form';

const AgendamentoMedicoRenovacao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(true);

	const consultaCategoria = useSelector(
		(state: ApplicationState) => state.api.detranCnh.consulta.consultaCategoria,
	);

	const { saveGerarRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	// MANTER OCULTO ATÉ ALINHAMENTO COM O 2.0 PARA APLICAR O MESMO FLUXO EM AMBOS (VOLTAR PARA A TELA ANTERIOR).
	// const handleButtonBack = useCallback(() => {
	// 	dispatch(cadastroEnderecosClear());
	// 	dispatch(capturarBiometriaClear());
	// 	dispatch(consultarDigitaisClear());
	// 	dispatch(confrontarDigitaisCnhClear());
	// 	dispatch(estatisticaIncluirClear());
	// 	dispatch(gerarRenachIntegradoClear());
	// 	dispatch(consultarInaptoClear());
	// 	dispatch(dadosCidadaoClear());
	// 	history.goBack();
	// }, [dispatch, history]);

	useEffect(() => {
		dispatch(consultaCategoriaRequest({ funcionalidade: 'renovacao' }));
	}, [dispatch]);

	useEffect(() => {
		if (saveGerarRenach.data?.gerarRenachBase) {
			const {
				cpf,
				logradouroCep,
				codCiretranHabilitacao,
				categoriaPretendida,
			} = saveGerarRenach.data.gerarRenachBase;

			initialValues.categoria_pretendida =
				consultaCategoria.divisaoEquitativa?.filter(
					divisaoEquitativa => divisaoEquitativa.codigo === categoriaPretendida,
				)[0]?.codigoDivisaoEquitativa || categoriaPretendida;
			initialValues.cpf_cidadao = Number(cpf);
			initialValues.cep_cidadao = Number(logradouroCep);
			initialValues.codigo_ciretran_exame_medico = Number(
				codCiretranHabilitacao,
			);
		}
	}, [consultaCategoria.divisaoEquitativa, saveGerarRenach]);

	useEffect(() => {
		if (saveGerarRenach.data?.gerarRenachBase) {
			const {
				cpf,
				nome,
				nomeSocial,
				nomePai,
				nomeMae,
				numDocumento,
				dataNascimento,
				sexo,
				nacionalidade,
				codigoNaturalidade,
				categoriaPretendida,
				logradouroCep,
				flagDeficienteFisico,
			} = saveGerarRenach.data.gerarRenachBase;

			// DADOS CIDADÃO
			dadosCidadao.nome = nomeSocial || nome;
			dadosCidadao.nomeMae = nomeMae;
			dadosCidadao.nomePai = nomePai;
			dadosCidadao.numeroRg = numDocumento;
			dadosCidadao.cpf = cpf;
			dadosCidadao.sexo = sexo;
			dadosCidadao.nacionalidade = nacionalidade;
			dadosCidadao.cep = logradouroCep;
			dadosCidadao.dataNascimento = dataNascimento;
			dadosCidadao.naturalidade = codigoNaturalidade || '';
			dadosCidadao.categoriaPretendida =
				consultaCategoria.divisaoEquitativa?.filter(
					divisaoEquitativa => divisaoEquitativa.codigo === categoriaPretendida,
				)[0]?.codigoDivisaoEquitativa || categoriaPretendida;
			dadosCidadao.isPortadorNecessidadesEspeciais =
				!!flagDeficienteFisico || flagDeficienteFisico === 'S';

			setIsLoading(false);
		}
	}, [consultaCategoria.divisaoEquitativa, saveGerarRenach.data]);

	return (
		<>
			{!isLoading && (
				<>
					<AgendamentoMedicoRenachIntegrado
						dadosAgendamento={initialValues}
						dadosCidadao={dadosCidadao}
						atendimentoPsicologico={
							!!saveGerarRenach.data?.gerarRenachBase?.flagAtividadeRemunerada
						}
						redirecionamento={
							saveGerarRenach.data?.gerarRenachBase?.flagAtividadeRemunerada
								? ROUTE_DETRAN_CNH_RENOVACAO_AGENDAMENTO_PSICOLOGICO
								: ROUTE_DETRAN_CNH_RENOVACAO_RENACH
						}
					/>
					<Row justify="space-between" align="middle">
						<Col>
							{/* MANTER OCULTO ATÉ ALINHAMENTO COM O 2.0 PARA APLICAR O MESMO FLUXO EM AMBOS (VOLTAR PARA A TELA ANTERIOR).
								<ButtonVoltar
									navigate={false}
									onClick={() => {
									handleButtonBack();
									}}
								/> 
							*/}
						</Col>
						<Col>
							<ButtonImage
								type="button"
								src="outro-servico-detran"
								onClick={() => history.push(ROUTE_DETRAN_CNH)}
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};
export default AgendamentoMedicoRenovacao;
