import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DARE } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	IRequestDareGuia,
	IRequestDareCpf,
	consultarDareSuccess,
	consultarDareClear,
} from './actions';

interface IRequestPesquisaDareGuia {
	numeroControleGuiaMae: string;
	orgao: number;
}

interface IRequestPesquisaDareCpf {
	cpf: string;
	orgao: number;
}

function* consultarDareGuiaRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestDareGuia } = request;

	yield put(consultarDareClear());

	const params: IRequestPesquisaDareGuia = {
		numeroControleGuiaMae: payload.dare,
		orgao: payload.orgao,
	};

	const response: ApiResponse = yield call(
		getApi,
		PATH_DARE,
		`dare/${CHANNEL}/consultarDareNumeroGuiaMae`,
		params,
	);

	if (response.status === 200) {
		yield put(consultarDareSuccess(response.status, response.data));
	} else {
		yield put(consultarDareSuccess(204, null));
	}
}

function* consultarDareCpfRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestDareCpf } = request;

	yield put(consultarDareClear());

	const params: IRequestPesquisaDareCpf = {
		cpf: payload.cpf,
		orgao: payload.orgao,
	};

	const response: ApiResponse = yield call(
		getApi,
		PATH_DARE,
		`dare/${CHANNEL}/consultarDareRGPorCPF`,
		params,
	);

	if (response.status === 200) {
		yield put(consultarDareSuccess(response.status, response.data));
	} else {
		yield put(consultarDareSuccess(204, null));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_DARE_GUIA_REQUEST, consultarDareGuiaRequest),
	takeLatest(Types.CONSULTAR_DARE_CPF_REQUEST, consultarDareCpfRequest),
]);
