import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultarCadastroBinRenavam } from './types';

export function consultarCadastroBinRenavamRequest(
	data: IRequestConsultarCadastroBinRenavam,
): ReducerAction {
	return {
		type: Types.CONSULTAR_CADASTRO_BIN_RENAVAM_REQUEST,
		payload: data,
	};
}
export function consultarCadastroBinRenavamSuccess(payload: object) {
	return {
		type: Types.CONSULTAR_CADASTRO_BIN_RENAVAM_SUCCESS,
		payload,
	};
}
export function consultarCadastroBinRenavamFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_CADASTRO_BIN_RENAVAM_FAILURE,
		payload,
	};
}
export function consultarCadastroBinRenavamClear(): any {
	return {
		type: Types.CONSULTAR_CADASTRO_BIN_RENAVAM_CLEAR,
	};
}
