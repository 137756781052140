import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, PreCadastroCidadao } from './types';

export const INITIAL_STATE: PreCadastroCidadao = {
	status: 0,
	data: null,
	errorMessage: '',
	request: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): PreCadastroCidadao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PRE_CADASTRO_CIDADAO_REQUEST: {
				draft.request = action.payload;
				draft.status = 100;
				break;
			}

			case Types.PRE_CADASTRO_CIDADAO_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = action.payload.status;
				break;
			}

			case Types.PRE_CADASTRO_CIDADAO_FAILURE: {
				draft.errorMessage = action.payload;
				draft.status = 400;
				break;
			}

			case Types.PRE_CADASTRO_CIDADAO_CLEAR: {
				draft.request = INITIAL_STATE.request;
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
