import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
	.ant-popover-inner-content {
		p {
			margin-bottom: 0px;
		}

		p:not(:last-child) {
			margin-bottom: 5px;
		}
	}
`;
