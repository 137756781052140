import styled from 'styled-components';

export const AlertArea = styled.div`
	.buttons-area {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: nowrap;

		button {
			margin-right: 10px;
		}
	}

	.alert-area__title-box-area {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		h3 {
			padding-left: 5px;
			color: #c33;
		}
	}

	.alert-area__message-box-area {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		padding: 5px 0px;

		.message-box-area__red {
			display: contents;
			color: #c33;
		}
		.message-box-area__strong-red {
			display: contents;
			color: #c33;
			font-weight: bolder;
		}
		.message-box-area__strong-black {
			display: contents;
			font-weight: bolder;
		}
	}
`;
