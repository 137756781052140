import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// ROUTES
import { ROUTE_DETRAN_CNH_SEGUNDA_VIA_STATUS_CONDUTOR } from 'pages/DetranCnh/routes/paths';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import {
	consultaESegundaViaRequest,
	consultaESegundaViaClear,
} from 'store/modules/api/detranCnh/segundaVia/consultaEPortalSegundaVia/actions';
import {
	relatorioAgendamentoMedicoPsicologicoClear,
	relatorioAgendamentoMedicoPsicologicoRequest,
} from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';
import {
	consultaProcessoAbertoClear,
	consultaProcessoAbertoRequest,
} from 'store/modules/api/detranCnh/renachIntegrado/consultaProcessoAberto/actions';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import { eventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/actions';

// UTILS
import { threatValuesStartSessionEventRequest } from 'utils/functions/threatEventsRequest';
import { CONSULTA_EPORTAL_SEGUNDA_VIA_ERROR_RESPONSE_SERVICE_MESSAGE } from 'pages/DetranCnh/utils/constants/serviceResponseMessages';
import { treatAgendamentoMedicoPsicologicoRequestInPesquisar } from 'pages/DetranCnh/utils/threatServicesRequest';

// COMPONENTS
import Pesquisa from 'pages/DetranCnh/components/pages/PreCadastro/Pesquisa';
import ModalProcurador from 'pages/DetranCnh/components/pages/ModalProcurador';

// FORM
import {
	treatValues,
	initialValues,
} from 'pages/DetranCnh/components/pages/PreCadastro/Pesquisa/form';
import { IFormProcurador } from 'pages/DetranCnh/components/pages/ModalProcurador/form';

// PATHS
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// TYPES
import { ConsultaRenovacaoRequest } from 'store/modules/api/detranCnh/eportal/consultaRenovacaoCnh/types';

let valuesRequest: ConsultaRenovacaoRequest = {
	...initialValues,
	senha: '',
	usuario: '',
};

const PesquisaSegundaVia: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [modalProcurador, setModalProcurador] = useState('N');
	const [openModalConfirm, setOpenModalConfirm] = useState(false);

	const { consultaEPortalSegundaVia } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.segundaVia,
	);

	const { consultaProcessoAberto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { relatorio } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.agendamentoMedicoPsicologico,
	);

	const { mainframe } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const handleClear = useCallback(() => {
		dispatch(clearNotifications());
		dispatch(consultaESegundaViaClear());
		dispatch(consultaProcessoAbertoClear());
		dispatch(relatorioAgendamentoMedicoPsicologicoClear());
	}, [dispatch]);

	const handleSearchUser = useCallback(
		(values: IFormProcurador) => {
			setModalProcurador(values.flagProcurador);
			dispatch(consultaESegundaViaRequest(valuesRequest));
			setOpenModalConfirm(false);
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: any) => {
			handleClear();

			valuesRequest = treatValues(values, {
				usuario: mainframe.idUsuario,
				senha: mainframe.senhaMainFrame,
			});

			const valuesStartSessionEvent = threatValuesStartSessionEventRequest(
				atendimento,
				loginUnico,
			);

			dispatch(eventoInicioSessaoRequest(valuesStartSessionEvent));
			setOpenModalConfirm(true);
		},
		[
			atendimento,
			dispatch,
			handleClear,
			loginUnico,
			mainframe.idUsuario,
			mainframe.senhaMainFrame,
		],
	);

	useEffect(() => {
		if (
			(consultaEPortalSegundaVia.status === 200 &&
				consultaEPortalSegundaVia.data?.cpf &&
				consultaProcessoAberto.status === 0) ||
			(consultaEPortalSegundaVia.status === 400 &&
				consultaEPortalSegundaVia.dataError?.mensagem.trim().toLowerCase() ===
					CONSULTA_EPORTAL_SEGUNDA_VIA_ERROR_RESPONSE_SERVICE_MESSAGE.CONDUTOR_PROCESSO_ABERTO.toLowerCase() &&
				consultaEPortalSegundaVia.requestData?.cpf &&
				consultaProcessoAberto.status === 0)
		) {
			dispatch(
				consultaProcessoAbertoRequest({
					cpf:
						consultaEPortalSegundaVia.data?.cpf ||
						consultaEPortalSegundaVia.requestData?.cpf ||
						'',
					ident: 0,
				}),
			);
		}
	}, [
		consultaEPortalSegundaVia.data,
		consultaEPortalSegundaVia.status,
		consultaEPortalSegundaVia.dataError,
		consultaProcessoAberto.status,
		dispatch,
		history,
		consultaEPortalSegundaVia.requestData,
	]);

	useEffect(() => {
		if (
			(relatorio.data || !relatorio.data) &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			consultaProcessoAberto.data?.message
		) {
			addNotifications({
				errors: [consultaProcessoAberto.data.message],
			});
		}
	}, [consultaProcessoAberto.data, dispatch, relatorio.data]);

	useEffect(() => {
		if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			(consultaProcessoAberto.data.dataExame ||
				consultaProcessoAberto.data.dataExamePsicologo)
		) {
			const requestData = treatAgendamentoMedicoPsicologicoRequestInPesquisar(
				consultaProcessoAberto.data,
			);

			dispatch(relatorioAgendamentoMedicoPsicologicoRequest(requestData));
		} else if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) === 0
		) {
			history.push({
				pathname: ROUTE_DETRAN_CNH_SEGUNDA_VIA_STATUS_CONDUTOR,
				state: { flagProcurador: modalProcurador },
			});
		}
	}, [
		consultaProcessoAberto.data,
		consultaProcessoAberto.status,
		dispatch,
		history,
		modalProcurador,
	]);

	return (
		<>
			<Pesquisa
				handleSubmit={handleSubmit}
				handleClear={handleClear}
				relatorio={relatorio}
				routeBackButton={ROUTE_DETRAN_CNH}
			/>
			<ModalProcurador
				handleSubmit={handleSearchUser}
				modalVisible={openModalConfirm}
				onCancel={() => setOpenModalConfirm(false)}
			/>
		</>
	);
};
export default PesquisaSegundaVia;
