import React from 'react';

import { Col, Row } from 'antd';

// STYLED
import { Container, Title, Description, FormRow } from './styled';

interface Props {
	title: string;
	description: string;
}

const CardReimpressao: React.FC<Props> = ({ title, description, children }) => (
	<Container>
		<Row>
			<Col>
				<Title>{title}</Title>
			</Col>
		</Row>

		<Row>
			<Col>
				<Description>{description}</Description>
			</Col>
		</Row>

		<FormRow>{children}</FormRow>
	</Container>
);

export default CardReimpressao;
