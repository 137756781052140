import { takeLatest, call, put, all } from 'redux-saga/effects';
import { putApi } from 'services/_api';
import { CHANNEL, PATH_MP } from 'services/_path';
import { v4 } from 'uuid';
import { Types } from './types';
import {
	atualizaSolicitacaoPortalSuccess,
	atualizaSolicitacaoPortalFailure,
} from './actions';

function* atualizaSolicitacaoPortalRequest({ payload, idSolicitacao }: any) {
	const body = { ...payload, idAtendimento: v4() };
	const response = yield call(
		putApi,
		PATH_MP,
		`portal/${CHANNEL}/solicitacao/${idSolicitacao}`,
		body,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(atualizaSolicitacaoPortalSuccess(response));
	} else if (response.error) {
		yield put(atualizaSolicitacaoPortalFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.ATUALIZA_SOLICITACAO_PORTAL_REQUEST,
		atualizaSolicitacaoPortalRequest,
	),
]);
