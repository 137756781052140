import React, { useState, useEffect } from 'react';

import { FormikProps, Field } from 'formik';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import moment from 'moment';
import 'moment/locale/pt-br';

// REDUX
import { ApplicationState } from 'store';
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import FormBox from 'components/Common/Form/FormBox';
import Select from 'components/Common/Form/Select';
import DatePicker from 'components/Common/Form/Input/DatePicker';
import TimePicker from 'components/Common/Form/Input/TimePicker';

// FORM
import { IFormCadastroRg } from '../../form';

interface Props {
	formik: FormikProps<IFormCadastroRg>;
}

const EntregaRG: React.FC<Props> = ({ formik }) => {
	const { setFieldValue } = formik;

	const [retiradaDisabled, setRetiradaDisabled] = useState<boolean>(true);
	const [kitFamiliaDisabled, setKitFamiliaDisabled] = useState<boolean>(true);

	const [selectedData, setSelectedData] = useState<string>('');
	const [selectedHora, setSelectedHora] = useState<string>('');

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	// Aplica os valores iniciais nos campos.
	// Obs.: sem esse recurso às vezes os campos ficam sem o valor inicial (?)
	useEffect(() => {
		setFieldValue('entrega', formik.values.entrega);
		setFieldValue('data', formik.values.data);
		setFieldValue('hora', formik.values.hora);

		setSelectedData(formik.values.data);
		setSelectedHora(formik.values.hora);
	}, [
		formik.values.data,
		formik.values.entrega,
		formik.values.hora,
		setFieldValue,
	]);

	useEffect(() => {
		if (formik.values.dni === '10977') {
			setFieldValue('entrega', '');
		}
	}, [formik.values.dni, setFieldValue]);

	// Manipula os campos para Data/Hora de retirada.
	useEffect(() => {
		if (formik.values.entrega === 'P') {
			setRetiradaDisabled(true);
			return;
		}

		setRetiradaDisabled(true);
	}, [formik.values.entrega, setRetiradaDisabled]);

	// Manipula o campo "Kit família? Para quantas Carteiras de Identidades?".
	useEffect(() => {
		if (formik.values.entrega === 'KC') {
			setKitFamiliaDisabled(false);
			return;
		}

		setKitFamiliaDisabled(true);
	}, [formik.values.entrega, setKitFamiliaDisabled]);

	return (
		<>
			<FormBox title="Entrega da Carteira de Identidade">
				<Row gutter={[0, 10]}>
					<Col span={11}>
						<Field
							as={Select}
							title="Como será a entrega"
							titleSize="lg"
							size={100}
							titleQuestion
							name="entrega"
							options={
								(cadastroRg.tipo.primeiraVia && cadastroRg.info.maior) ||
								formik.values.dni === '10977'
									? data.tipoEntrega.filter(tipo => tipo.value === 'P')
									: data.tipoEntrega
							}
							onChange={(v: string) => {
								setFieldValue('entrega', v);

								if (v === 'P') {
									// Entrega realizada no "No posto".
									setRetiradaDisabled(true);

									setFieldValue('kitFamilia', '');
									setFieldValue('data', '');
									setFieldValue('hora', '');
									setKitFamiliaDisabled(true);
								} else if (v === 'KC') {
									// Entrega "Na residência em kit (Início/Principal)".
									setKitFamiliaDisabled(false);

									setFieldValue('data', '');
									setFieldValue('hora', '');
									setSelectedData('');
									setSelectedHora('');
									setRetiradaDisabled(true);
								} else {
									setFieldValue('kitFamilia', '');
									setKitFamiliaDisabled(true);

									setFieldValue('data', '');
									setFieldValue('hora', '');
									setSelectedData('');
									setSelectedHora('');
									setRetiradaDisabled(true);
								}
							}}
							required
							error={!!formik.errors.entrega}
						/>
					</Col>

					<Col span={12} offset={1}>
						<Field
							as={Select}
							title="Kit família? Para quantas Carteiras de Identidade"
							titleSize="xl"
							size={80}
							titleQuestion
							name="qtdKitFamilia"
							options={data.qtdKitFamilia}
							onChange={(v: string) => setFieldValue('qtdKitFamilia', v)}
							required={!kitFamiliaDisabled}
							disabled={kitFamiliaDisabled}
							error={!!formik.errors.qtdKitFamilia}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={11}>
						<Field
							as={DatePicker}
							title="Previsão para retirada"
							titleSize="lg"
							size={100}
							name="data"
							defaultValue={formik.values.data}
							value={selectedData}
							showToday={false}
							required={!retiradaDisabled}
							disabled={retiradaDisabled}
							onChange={(date: string) => {
								setFieldValue('data', date);
								setSelectedData(date);
							}}
							error={!!formik.errors.data}
							disabledDate={(current: moment.Moment) => {
								return current < moment().add(-1, 'day');
							}}
						/>
					</Col>

					<Col span={12} offset={1}>
						<Field
							as={TimePicker}
							title="Horário para retirada"
							titleSize="xl"
							size={80}
							name="hora"
							defaultValue={formik.values.hora}
							value={selectedHora}
							required={!retiradaDisabled}
							disabled={retiradaDisabled}
							onChange={(time: string) => {
								setFieldValue('hora', time);
								setSelectedHora(time);
							}}
							error={!!formik.errors.hora}
						/>
					</Col>
				</Row>
			</FormBox>

			<Row gutter={[0, 30]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default EntregaRG;
