import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_TIPO_REQUEST = '@DETRAN-CRV/CONSULTA_TIPO_REQUEST',
	CONSULTA_TIPO_SUCCESS = '@DETRAN-CRV/CONSULTA_TIPO_SUCCESS',
	CONSULTA_TIPO_FAILURE = '@DETRAN-CRV/CONSULTA_TIPO_FAILURE',
}

export interface consultaTipo {
	status: number;
	data: OptionProps[];
}
