import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaCnhDefinitiva } from './types';

// ACTIONS
import {
	consultaCnhDefinitivaSuccess,
	consultaCnhDefinitivaFailure,
} from './actions';

function* consultaCnhDefinitivaRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaCnhDefinitiva } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`eportal/${CHANNEL}/pesquisa/cnh-definitiva`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaCnhDefinitivaSuccess({ response, request: payload }));
	} else {
		yield put(
			consultaCnhDefinitivaFailure({
				response: response.data,
				request: payload,
			}),
		);
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_CNH_DEFINITIVA_REQUEST,
		consultaCnhDefinitivaRequest,
	),
]);
