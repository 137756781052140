import React, { useState, useCallback, useEffect } from 'react';

import { ConfigProvider, DatePicker as Picker } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/locale/pt_BR';

// STYLED
import { Box } from './styled';
import { Container, Title, Content, SubTitle, Hint } from '../styled';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

interface Props {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | number;
	titleQuestion?: boolean;
	subtitle?: string;
	hint?: string;
	name: string;
	defaultValue?: string;
	value?: string;
	showToday?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	size?: number;
	error?: boolean;
	placeholder?: string;
	disabledDate?: (current: moment.Moment) => boolean;
	onChange: (date: string) => void;
}

const DatePicker: React.FC<Props> = ({
	title,
	titleSize = 'md',
	titleQuestion = false,
	subtitle,
	hint,
	name,
	defaultValue = '',
	value = '',
	showToday = true,
	required = false,
	disabled = false,
	readOnly = false,
	size = 100,
	error,
	placeholder,
	disabledDate,
	onChange,
}) => {
	const [selectedValue, setSelectedValue] = useState<string>(value);

	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		setSelectedValue(value);
	}, [value]);

	const handleInputFocus = useCallback(() => {
		setIsFocused(true);
	}, []);

	const handleInputBlur = useCallback(() => {
		setIsFocused(false);
	}, []);

	const handleChange = useCallback(
		(e: moment.Moment | null, dateString: string) => {
			setSelectedValue(dateString);
			onChange(dateString);
		},
		[onChange],
	);

	return (
		<Container>
			{title && (
				<Title size={titleSize} required={required}>
					<label htmlFor={name}>{`${title}${titleQuestion ? '?' : ':'}`}</label>
				</Title>
			)}

			<Content>
				<Box
					size={size}
					isErrored={!!error}
					isFocused={isFocused}
					isDisabled={disabled}
					isReadOnly={readOnly}
				>
					<ConfigProvider locale={locale}>
						<Picker
							name={name}
							id={name}
							defaultValue={
								defaultValue
									? moment(defaultValue, dateFormatList[0])
									: undefined
							}
							value={
								selectedValue
									? moment(selectedValue, dateFormatList[0])
									: undefined
							}
							format={dateFormatList}
							placeholder={placeholder || ''}
							showToday={showToday}
							onFocus={handleInputFocus}
							onBlur={handleInputBlur}
							inputReadOnly={readOnly}
							disabled={disabled}
							onChange={handleChange}
							disabledDate={disabledDate}
						/>
					</ConfigProvider>

					{subtitle && (
						<SubTitle>
							<span>{subtitle}</span>
						</SubTitle>
					)}
				</Box>

				{hint && <Hint>{hint}</Hint>}
			</Content>
		</Container>
	);
};

export default DatePicker;
