export enum Types {
	PROTOCOLO_FIC_DIGITAL_REQUEST = '@iirgd/PROTOCOLO_FIC_DIGITAL_REQUEST',
	PROTOCOLO_FIC_DIGITAL_SUCCESS = '@iirgd/PROTOCOLO_FIC_DIGITAL_SUCCESS',
	PROTOCOLO_FIC_DIGITAL_FAILURE = '@iirgd/PROTOCOLO_FIC_DIGITAL_FAILURE',
	PROTOCOLO_FIC_DIGITAL_CLEAR = '@iirgd/PROTOCOLO_FIC_DIGITAL_CLEAR',
}

export interface IProtocoloFicDigitalState {
	status: number;
	data: IProtocoloFicDigital | null;
}

export interface IProtocoloFicDigital {
	arquivoPdf: string;
}
