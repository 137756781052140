export enum Types {
	CONSULTAR_ENDERECO = '@MP/CONSULTAR_ENDERECO',
	CONSULTAR_ENDERECO_SUCCESS = '@MP/CONSULTAR_ENDERECO_SUCCESS',
	CONSULTAR_ENDERECO_FAILURE = '@MP/CONSULTAR_ENDERECO_FAILURE',
	LIMPAR_CONSULTAR_ENDERECO = '@MP/LIMPAR_CONSULTAR_ENDERECO',
}

export interface ConsultarEndereco {
	status: number;
	data: Tabelas | null;
}

export interface IRequestConsultarEnderecoNovo {
	idTipoEndereco?: string;
	cpf: string;
}

interface Errors {
	idErro: string;
	dsErro: string;
	idControle: string;
	indiceControle: string;
}

export interface Tabelas {
	cpf: string;
	idTipoEndereco: string;
	cep: string;
	tipoLogradouro: string;
	logradouro: string;
	numero: string;
	referencia: string;
	semNumero: string;
	complemento: string;
	bairro: string;
	municipio: string;
	uf: string;
	dataAtualizacao: string;
	email: string;
	foneCelular: string;
	foneFixo: string;
	latitude: string;
	longitude: string;
	tipo: string;
	idIbge: string;
	ip1: string;
	ip2: string;
	agentBroseSo: string;
	pagina: string;
	so: string;
	bro: string;
	usuarioAtualizacao: string;
}

export interface ConsultarEnderecoNovo {
	cabecalho: {
		nomeTabela: string;
		versao: string;
		dataProcessamento: string;
		descricao: string;
		observacao: string;
		autor: string;
		qtdRegistros: string;
	};
	retorno: {
		idRetorno: string;
		dsRetorno: string;
	};
	erros: Errors[];
	tabelas: Tabelas[];
}
