import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaReabilitacao } from './types';

// ACTIONS
import {
	consultaReabilitacaoSuccess,
	consultaReabilitacaoFailure,
} from './actions';

function* consultaReabilitacao(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaReabilitacao } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}/consultaReabilitacao`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaReabilitacaoSuccess(response));
	} else if (response.error) {
		yield put(consultaReabilitacaoFailure(response.error));
	}
}

export default all([
	takeLatest(Types.CONSULTA_REABILITACAO, consultaReabilitacao),
]);
