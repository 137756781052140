import { ConsultaFornecedorData } from '../consultaFornecedor/types';

export enum Types {
	FORNECEDOR_SELECIONADO_REQUEST = '@procon/FORNECEDOR_SELECIONADO_REQUEST',
	FORNECEDOR_SELECIONADO_CLEAR = '@procon/FORNECEDOR_SELECIONADO_CLEAR',
}

export interface FornecedorSelecionado {
	data: null | ConsultaFornecedorData;
}
