import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import {
	consultaProcessoAbertoClear,
	consultaProcessoAbertoRequest,
} from 'store/modules/api/detranCnh/renachIntegrado/consultaProcessoAberto/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';

import { ConsultaProcessoAbertoRequest } from 'store/modules/api/detranCnh/renachIntegrado/consultaProcessoAberto/types';
import { eventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/actions';

// COMPONENTS COMMON
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';
import Section from 'components/Common/Section';
import CPF from 'components/Common/Form/Fields/CPF';
import ButtonImage from 'components/Common/ButtonImage';
import Alert from 'components/Common/Notifications/Alert';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { threatValuesStartSessionEventRequest } from 'utils/functions/threatEventsRequest';

// ROUTES
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// FORM
import { Formik, Form, FormikProps } from 'formik';
import { initialValues, schema } from './form';

const ConsultarCancelarProcessoAberto: React.FC = () => {
	const dispatch = useDispatch();
	const [alert, setAlert] = useState<boolean>(false);

	const { consultaProcessoAberto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const handleClear = useCallback(
		(formik?: FormikProps<ConsultaProcessoAbertoRequest>) => {
			formik?.resetForm();
			dispatch(clearNotifications());
			dispatch(consultaProcessoAbertoClear());
		},
		[dispatch],
	);

	const handleButtonCancelar = useCallback(() => {
		if (consultaProcessoAberto.data) {
			const valuesCancelarProcessoAberto = {
				cpf: consultaProcessoAberto.data.cpfCidadao,
				ident: consultaProcessoAberto.data.ident,
			};

			dispatch(
				consultaProcessoAbertoRequest({
					...valuesCancelarProcessoAberto,
				}),
			);

			setAlert(true);

			handleClear();
		}
	}, [dispatch, consultaProcessoAberto, handleClear]);

	const altertComponent = useCallback(() => {
		if (consultaProcessoAberto.status) {
			return (
				<Alert
					type="info"
					message="Registro excluído com sucesso"
					onClose={() => setAlert(false)}
				/>
			);
		}

		return null;
	}, [consultaProcessoAberto]);

	const handleSubmit = useCallback(
		(values: ConsultaProcessoAbertoRequest) => {
			const cpfFormatado = values.cpf?.replace(/[.-]/g, '');

			handleClear();

			const valuesConsultarCancelarProcessoAberto = {
				cpf: cpfFormatado || '',
				ident: 0,
			};

			dispatch(
				consultaProcessoAbertoRequest({
					...valuesConsultarCancelarProcessoAberto,
				}),
			);

			const valuesStartSessionEvent = threatValuesStartSessionEventRequest(
				atendimento,
				loginUnico,
			);

			dispatch(eventoInicioSessaoRequest(valuesStartSessionEvent));
		},
		[atendimento, dispatch, handleClear, loginUnico],
	);

	useEffect(() => {
		handleClear();
		setAlert(false);
	}, [handleClear]);

	return (
		<>
			{alert && altertComponent()}
			<Section title="Pesquisar">
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={values => getValidationsErrors(values, schema)}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row gutter={[0, 10]}>
										<Col span={12}>
											<CPF
												title="CPF"
												subtitle="(Digite apenas números)"
												numero="cpf"
												formik={formik}
												error={!!formik.errors.cpf}
												disabled={consultaProcessoAberto.status === 100}
											/>
										</Col>
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => handleClear(formik)}
												disabled={consultaProcessoAberto.status === 100}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage
												type="submit"
												src="pesquisar"
												disabled={consultaProcessoAberto.status === 100}
											/>
										</Col>

										{consultaProcessoAberto?.status === 200 &&
											consultaProcessoAberto.data && (
												<Col style={{ marginLeft: '4.16666667%' }}>
													<Button
														onClick={handleButtonCancelar}
														disabled={consultaProcessoAberto.data.ident === '0'}
													>
														Cancelar
													</Button>
												</Col>
											)}

										{consultaProcessoAberto?.status === 200 &&
											consultaProcessoAberto.data &&
											consultaProcessoAberto.data.ident !== '0' && (
												<Section title="Retorno ECNH">
													<Col offset={1}>
														<div>
															<b>categoriaPretendida: </b>
															{consultaProcessoAberto.data
																?.categoriaPretendida &&
																consultaProcessoAberto.data.categoriaPretendida}
														</div>
														<div>
															<b>ciretranCandidato: </b>
															{consultaProcessoAberto.data?.ciretranCandidato
																? consultaProcessoAberto.data.ciretranCandidato
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>codigoInterno: </b>
															{consultaProcessoAberto.data?.codigoInterno
																? consultaProcessoAberto.data.codigoInterno
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>cpfCidadao: </b>
															{consultaProcessoAberto.data?.cpfCidadao
																? consultaProcessoAberto.data.cpfCidadao
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>crm: </b>
															{consultaProcessoAberto.data?.crm
																? consultaProcessoAberto.data.crm
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>crp: </b>
															{consultaProcessoAberto.data?.crp
																? consultaProcessoAberto.data.crp
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>dataExame: </b>
															{consultaProcessoAberto.data?.dataExame
																? consultaProcessoAberto.data.dataExame
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>dataExamePsicologo: </b>
															{consultaProcessoAberto.data?.dataExamePsicologo
																? consultaProcessoAberto.data.dataExamePsicologo
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>dataGeracaoRenach: </b>
															{consultaProcessoAberto.data?.dataGeracaoRenach
																? consultaProcessoAberto.data.dataGeracaoRenach
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>dataRealizacaoExame: </b>
															{consultaProcessoAberto.data?.dataRealizacaoExame
																? consultaProcessoAberto.data
																		.dataRealizacaoExame
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>dataRealizacaoExamePsicologo: </b>
															{consultaProcessoAberto.data
																?.dataRealizacaoExamePsicologo
																? consultaProcessoAberto.data
																		.dataRealizacaoExamePsicologo
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>enderecoMedico: </b>
															{consultaProcessoAberto.data?.enderecoMedico
																? consultaProcessoAberto.data.enderecoMedico
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>enderecoPsicologo: </b>
															{consultaProcessoAberto.data?.enderecoPsicologo
																? consultaProcessoAberto.data.enderecoPsicologo
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>horarioExame: </b>
															{consultaProcessoAberto.data?.horarioExame
																? consultaProcessoAberto.data.horarioExame
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>horarioExamePsicologo: </b>
															{consultaProcessoAberto.data
																?.horarioExamePsicologo
																? consultaProcessoAberto.data
																		.horarioExamePsicologo
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>horarioRealizacaoExame: </b>
															{consultaProcessoAberto.data
																?.horarioRealizacaoExame
																? consultaProcessoAberto.data
																		.horarioRealizacaoExame
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>horarioRealizacaoExamePsicologo: </b>
															{consultaProcessoAberto.data
																?.horarioRealizacaoExamePsicologo
																? consultaProcessoAberto.data
																		.horarioRealizacaoExamePsicologo
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>ident: </b>
															{consultaProcessoAberto.data?.ident
																? consultaProcessoAberto.data.ident
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>message: </b>
															{consultaProcessoAberto.data?.message
																? consultaProcessoAberto.data.message
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>nomeCidadao: </b>
															{consultaProcessoAberto.data?.nomeCidadao
																? consultaProcessoAberto.data.nomeCidadao
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>nomeMedico: </b>
															{consultaProcessoAberto.data?.nomeMedico
																? consultaProcessoAberto.data.nomeMedico
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>nomePsicologo: </b>
															{consultaProcessoAberto.data?.nomePsicologo
																? consultaProcessoAberto.data.nomePsicologo
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>nomeSocialCidadao: </b>
															{consultaProcessoAberto.data?.nomeSocialCidadao
																? consultaProcessoAberto.data.nomeSocialCidadao
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>paisAfetivos1: </b>
															{consultaProcessoAberto.data?.paisAfetivos1
																? consultaProcessoAberto.data.paisAfetivos1
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>paisAfetivos2: </b>
															{consultaProcessoAberto.data?.paisAfetivos2
																? consultaProcessoAberto.data.paisAfetivos2
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>pretendeAtividadeRemunerada: </b>
															{consultaProcessoAberto.data
																?.pretendeAtividadeRemunerada
																? consultaProcessoAberto.data
																		.pretendeAtividadeRemunerada
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>renach: </b>
															{consultaProcessoAberto.data?.renach
																? consultaProcessoAberto.data.renach
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>restricoesExameMedico: </b>
															{consultaProcessoAberto.data
																?.restricoesExameMedico
																? consultaProcessoAberto.data
																		.restricoesExameMedico
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>resultadoExameMedico: </b>
															{consultaProcessoAberto.data?.resultadoExameMedico
																? consultaProcessoAberto.data
																		.resultadoExameMedico
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>resultadoExamePsicologico: </b>
															{consultaProcessoAberto.data
																?.resultadoExamePsicologico
																? consultaProcessoAberto.data
																		.resultadoExamePsicologico
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>statusExameMedico: </b>
															{consultaProcessoAberto.data?.statusExameMedico
																? consultaProcessoAberto.data.statusExameMedico
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>statusExamePsicologico: </b>
															{consultaProcessoAberto.data
																?.statusExamePsicologico
																? consultaProcessoAberto.data
																		.statusExamePsicologico
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>telefones: </b>
															{consultaProcessoAberto.data?.telefones
																? consultaProcessoAberto.data.telefones
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>telefonesPsicologo: </b>
															{consultaProcessoAberto.data?.telefonesPsicologo
																? consultaProcessoAberto.data.telefonesPsicologo
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>tipoProcessoCodigo: </b>
															{consultaProcessoAberto.data?.tipoProcesso?.codigo
																? consultaProcessoAberto.data.tipoProcesso
																		.codigo
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>tipoProcessoDescricao: </b>
															{consultaProcessoAberto.data?.tipoProcesso
																?.descricao
																? consultaProcessoAberto.data.tipoProcesso
																		.descricao
																: 'Informação não encontrada'}
														</div>
													</Col>
												</Section>
											)}

										{consultaProcessoAberto?.status === 400 && (
											<Section title="Retorno ECNH">
												<Col offset={1}>
													<div>
														<b>Erro ECNH: </b>
														1500 - cpf informado é inválido.
													</div>
												</Col>
											</Section>
										)}

										{consultaProcessoAberto?.status === 200 &&
											consultaProcessoAberto?.data?.ident === '0' && (
												<Section title="Retorno ECNH">
													<Col offset={1}>
														<div>
															<b>Aviso ECNH: </b>
															Sem processo aberto ou pendente
														</div>
													</Col>
												</Section>
											)}
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>
			<ButtonVoltar
				route={ROUTE_DETRAN_CNH}
				onClick={() => {
					dispatch(detranCnhClear());
				}}
			/>
		</>
	);
};

export default ConsultarCancelarProcessoAberto;
