import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import data from 'store/modules/api/iirgd/enum';

// UTILS
import { limparMascara } from 'utils/genericFunctions';

// FORM
import { IFormInclusaoObsPrimeiraViaMaior } from '../../../InclusaoObservacoes/Formularios/form';

// STYLED
import { Title, Label, Value } from '../../styled';

export const Questionario: React.FC = () => {
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	const [
		obsPrimeiraVia,
		setObsPrimeiraVia,
	] = useState<IFormInclusaoObsPrimeiraViaMaior | null>(null);

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	useEffect(() => {
		const { form } = cadastroRg;
		const { observacoes } = form;

		if (observacoes.primeiraVia.maior) {
			setObsPrimeiraVia(observacoes.primeiraVia.maior);
		}

		setHasLoaded(true);
	}, [cadastroRg]);

	return (
		<>
			{hasLoaded && (
				<>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Title>Questionário</Title>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col offset={4}>
							<Label align="left">1) Quanto tempo mora em São Paulo?</Label>
						</Col>
						<Col>
							<Value>
								{obsPrimeiraVia?.resideSpAnos !== '' && (
									<span>
										{limparMascara(obsPrimeiraVia?.resideSpAnos)} anos
									</span>
								)}

								{obsPrimeiraVia?.resideSpMeses !== '' && (
									<span style={{ marginLeft: '5px' }}>
										e {obsPrimeiraVia?.resideSpMeses} meses
									</span>
								)}
							</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col offset={4}>
							<Label align="left">2) Mora com quem?</Label>
						</Col>
						<Col>
							<Value>{obsPrimeiraVia?.moraCom}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col offset={4}>
							<Label align="left">3) Tem parentes em São Paulo?</Label>
						</Col>
						<Col>
							<Value>
								{
									data.simNaoBoolean.find(
										v => v.value === obsPrimeiraVia?.temParentesSp,
									)?.label
								}
								{obsPrimeiraVia?.temParentesSp === true && (
									<span style={{ marginLeft: '5px' }}>
										({obsPrimeiraVia.grauParentescoSp})
									</span>
								)}
							</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col offset={4}>
							<Label align="left">4) Qual ocupação possui em São Paulo?</Label>
						</Col>
						<Col>
							<Value>{obsPrimeiraVia?.ocupacaoPrincipalSp}</Value>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};
