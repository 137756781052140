import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, SimularEftuarAcordo } from './types';

export const INITIAL_STATE: SimularEftuarAcordo = {
	status: 0,
	data: null,
	request: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): SimularEftuarAcordo {
	return produce(state, draft => {
		switch (action.type) {
			case Types.SIMULAR_EFETUAR_ACORDO_REQUEST: {
				draft.status = 100;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.SIMULAR_EFETUAR_ACORDO_SUCCESS: {
				draft.status = action.payload?.status ?? 200;
				draft.data = action.payload;
				break;
			}
			case Types.SIMULAR_EFETUAR_ACORDO_FAILURE: {
				draft.status = action.payload?.data?.status ?? 400;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.SIMULAR_EFETUAR_ACORDO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}

		return draft;
	});
}
