import produce from 'immer';

// TYPES
import { Types, ConsultaTransferenciaEndereco } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultaTransferenciaEndereco = {
	status: 0,
	data: null,
	requestData: null,
};

export default function consultaTransferenciaEnderecoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaTransferenciaEndereco {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_TRANSFERENCIA_ENDERECO: {
				break;
			}

			case Types.CONSULTA_TRANSFERENCIA_ENDERECO_SUCCESS: {
				draft.status = action.payload.response.status;
				draft.data = action.payload.response.data;
				draft.requestData = action.payload.payload;
				break;
			}

			case Types.CONSULTA_TRANSFERENCIA_ENDERECO_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTA_TRANSFERENCIA_ENDERECO_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				draft.requestData = INITIAL_STATE.requestData;
				break;
			}

			default:
		}
	});
}
