export enum Types {
	PTRES_REQUEST = '@DETRAN-CRV/PTRES_REQUEST',
	PTRES_SUCCESS = '@DETRAN-CRV/PTRES_SUCCESS',
	PTRES_FAILURE = '@DETRAN-CRV/PTRES_FAILURE',
	PTRES_CLEAR = '@DETRAN-CRV/PTRES_CLEAR',
}

export interface PTRES {
	status: number;
	mensagem: string;
	data: IPTRES;
}

export interface IPTRES {
	tipoCliente: number;
	qtdeOcorrencias: string;
	placa: string;
	renavam: string;
	uf: string;
	situacaoVeiculo: string;
	lotacao: number;
	cpfCnpj?: string;
	anoFabricacao: number;
	anoModelo: number;
	numMotor: string;
	procedencia: string;
	chassi: string;
	chassiRemarcado: {
		codigo: string;
		descricao: string;
	};
	marca: {
		codigo: string;
		descricao: string;
	};
	cor: {
		codigo: string;
		descricao: string;
	};
	combustivel: {
		codigo: string;
		descricao: string;
	};
	municipio: {
		codigo: string;
		descricao: string;
	};
	restricoes: [
		{
			restricao: string;
		},
	];
}

export interface IPTRESRequest {
	tipoPesquisa: number;
	chassi: string;
	placa: string;
	renavam: string;
	ufVeiculo: string;
	marcaVeiculo: string | null;
	anoFabricacao: string | null;
}
