import produce from 'immer';

// TYPES
import { Types, ConsultarMotivos } from './types';
import { ReducerAction } from '../../../../../types';

export const INITIAL_STATE: ConsultarMotivos = {
	status: 0,
	data: [],
	totalRegistrosConsulta: 0,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarMotivos {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_MOTIVOS: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			case Types.CONSULTAR_MOTIVOS_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.totalRegistrosConsulta = action.payload.headers['x-total-count'];
				break;
			}

			case Types.CONSULTAR_MOTIVOS_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTAR_MOTIVOS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			default:
		}
	});
}
