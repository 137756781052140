import * as Yup from 'yup';

export interface IConsultarCadastroBinRenavam {
	chassi: string;
	placa: string;
	renavam: number | null | string;
	selecao?: 'chassi' | 'placa' | 'renavam' | string;
	input?: string | number;
}

export const initialValues: IConsultarCadastroBinRenavam = {
	chassi: '',
	placa: '',
	renavam: null,
	input: '',
	selecao: '',
};

export function treatValues(
	values: IConsultarCadastroBinRenavam,
): IConsultarCadastroBinRenavam {
	const formattedData = {
		chassi: values.chassi,
		placa: values.placa,
		renavam: values.renavam ? values.renavam : 0,
	};

	return formattedData;
}

export const schema = Yup.object<IConsultarCadastroBinRenavam>().shape({
	placaRenavamChassi: Yup.string().when(['placa', 'renavam', 'chassi'], {
		is: (placa, renavam, chassi) =>
			placa === '' && (renavam === null || renavam === '') && chassi === '',
		then: Yup.string().required(`Placa ou Renavam ou Chassi são OBRIGATÓRIOS`),
	}),
});
