import { Types, EscolasProximasRequest } from './types';

export function escolasProximasRequest(
	payload: EscolasProximasRequest,
	token: string,
) {
	return {
		type: Types.ESCOLAS_PROXIMAS_REQUEST,
		token,
		payload,
	};
}

export function escolasProximasSuccess(payload: object) {
	return {
		type: Types.ESCOLAS_PROXIMAS_SUCCESS,
		payload,
	};
}

export function escolasProximasFailure(payload: boolean) {
	return {
		type: Types.ESCOLAS_PROXIMAS_FAILURE,
		payload,
	};
}
