import { combineReducers } from 'redux';

import atualizarParametroSistema from './atualizarParametroSistema/reducer';
import atualizarParametroSistemaRedis from './atualizarParametroSistemaRedis/reducer';
import cadastrarParametroSistema from './cadastrarParametroSistema/reducer';
import cadastrarParametroSistemaRedis from './cadastrarParametroSistemaRedis/reducer';
import consultarParametrosSistema from './consultarParametrosSistema/reducer';
import consultarParametrosSistemaRedis from './consultarParametrosSistemaRedis/reducer';
import excluirParametroSistema from './excluirParametroSistema/reducer';
import excluirParametroSistemaRedis from './excluirParametroSistemaRedis/reducer';

export default combineReducers({
	atualizarParametroSistema,
	atualizarParametroSistemaRedis,
	cadastrarParametroSistema,
	cadastrarParametroSistemaRedis,
	consultarParametrosSistema,
	consultarParametrosSistemaRedis,
	excluirParametroSistema,
	excluirParametroSistemaRedis,
});
