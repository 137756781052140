import { IFormStatusCondutor } from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor/form';
import { FormPrimeiraHabilitacao } from 'pages/DetranCnh/PrimeiraHabilitacao/StatusCondutor/form';
import { FormCnhEstrangeiro } from 'pages/DetranCnh/CnhEstrangeiro/StatusCondutor/form';
import { GerarRenachForm } from '../../sockets/gerarRenach/types';
import { ConsultaCnhEstrangeiroData } from '../../eportal/consultaCnhEstrangeiro/types';

export enum Types {
	DADOS_PRE_CADASTRO_FORM_SAVE_REQUEST = '@cnh/DADOS_PRE_CADASTRO_FORM_SAVE_REQUEST',
	DADOS_PRE_CADASTRO_FORM_SAVE_SUCCESS = '@cnh/DADOS_PRE_CADASTRO_FORM_SAVE_SUCCESS',
	DADOS_PRE_CADASTRO_FORM_SAVE_FAILURE = '@cnh/DADOS_PRE_CADASTRO_FORM_SAVE_FAILURE',
	DADOS_PRE_CADASTRO_FORM_SAVE_CLEAR = '@cnh/DADOS_PRE_CADASTRO_FORM_SAVE_CLEAR',
}

export interface DadosPreCadastroFormSave {
	dataPreCadastro?: null | GerarRenachForm;
	dataPreCadastroHabilitacao?: null | IFormStatusCondutor;
	dataPreCadastroPrimeiraHabilitacao?: null | FormPrimeiraHabilitacao;
	dataPreCadastroCnhEstrangeiro?: null | FormCnhEstrangeiro;
	dataPreCadastroHabilitacaoCnhEstrangeiro?: null | ConsultaCnhEstrangeiroData;
}

export interface DadosPreCadastroFormSaveRequest {
	dataPreCadastroCnhEstrangeiro?: FormCnhEstrangeiro;
	dataPreCadastroHabilitacaoCnhEstrangeiro?: ConsultaCnhEstrangeiroData;
	dataPreCadastroPrimeiraHabilitacao?: FormPrimeiraHabilitacao;
	dataPreCadastro?: GerarRenachForm;
	dataPreCadastroHabilitacao?: IFormStatusCondutor;
}
