import * as Yup from 'yup';
import { validacaoData, verificarMaioridade } from 'utils/genericFunctions';

export interface AtestadoNominal {
	tipoDocApresentado: string;
	ufRg: string;
	numeroDocumento: string;
	dataExpedicao: string;
	nome: string;
	nomePai: string;
	nomeMae: string;
	sexo: string;
	dataNascimento: string;
	requerente: boolean;
	nomeProcurador: string;
	docProcurador: string;
	posto: string;
	ipEstacao: string;
	usuario: string;
	ufRgSp?: string;
}

export const InitialValues = {
	tipoDocApresentado: '',
	ufRg: '',
	numeroDocumento: '',
	dataExpedicao: '',
	nome: '',
	nomePai: '',
	nomeMae: '',
	sexo: 'M',
	dataNascimento: '',
	requerente: false,
	nomeProcurador: '',
	docProcurador: '',
	posto: '',
	ipEstacao: '',
	usuario: '',
	dc: '',
};

export const schema = Yup.object<AtestadoNominal>().shape({
	tipoDocApresentado: Yup.string().required(
		`Campo Obrigatório: Tipo Documento.`,
	),
	ufRgSp: Yup.string()
		.when(['tipoDocApresentado', 'ufRg'], {
			is: (tipoDocApresentado, ufRg) =>
				tipoDocApresentado === 'RG' && (ufRg === 'sp' || ufRg === 'SP'),
			then: Yup.string().required(`Para RG de SP, utilize a pesquisa Numeral.`),
		})
		.when(['tipoDocApresentado', 'ufRg'], {
			is: (tipoDocApresentado, ufRg) =>
				!ufRg &&
				(tipoDocApresentado === 'CNH' ||
					tipoDocApresentado === 'RG' ||
					tipoDocApresentado === 'FUNC'),
			then: Yup.string().required(`Campo Obrigatório: UF.`),
		}),
	numeroDocumento: Yup.string().required(`Campo Obrigatório: Documento.`),
	dataExpedicao: Yup.string()
		.required(`Campo Obrigatório: Data de Expedição.`)
		// eslint-disable-next-line func-names
		.test(
			'test-name',
			value => `Data Expedição: '${value.value}' não é uma data válida.`,
			(value: any) => (value === '' ? true : validacaoData(value)),
		),
	nome: Yup.string().required(`Campo Obrigatório: Nome.`),
	dataNascimento: Yup.string()
		.required(`Campo Obrigatório: Data de Nascimento.`)
		// eslint-disable-next-line func-names
		.test(
			'test-name',
			() =>
				`Atenção, a emissão de atestado só é permitida para maiores de 18 anos!`,
			(value: any) =>
				value === '' || !validacaoData(value)
					? true
					: verificarMaioridade(value),
		)
		.test(
			'test-name',
			value => `Data de Nascimento: '${value.value}' não é uma data válida.`,
			(value: any) => (value === '' ? true : validacaoData(value)),
		),
	sexo: Yup.string().required(`Campo Obrigatório: Sexo.`),
	docProcurador: Yup.string().when(['requerente'], {
		is: requerente => requerente === true,
		then: Yup.string().required(`Por favor informe o RG do(a) Procurador(a).`),
	}),
});
