import * as Yup from 'yup';
import { ConsultaConsumidorData } from 'store/modules/api/procon/consumidores/consultaConsumidor/types';
import { CadastroConsumidorForm } from 'store/modules/api/procon/consumidores/cadastro/types';
import { formatDate } from 'utils/genericFunctions';

export const initialValues: CadastroConsumidorForm = {
	cpf: '',
	confirmaEmail: '',
	email: '',
	nomeCompleto: '',
	telefoneCelular: '',
	telefoneFixo: '',
	confirmaTelefoneCelular: '',
	confirmaTelefoneFixo: '',
	rg: '',
	orgaoEmissorDoRg: '',
	ufEmissor: '',
	dataDeNascimento: '',
	sexo: '',
	bairro: '',
	cep: '',
	logradouro: '',
	estado: '',
	municipioId: '',
	municipioDescricao: '',
	numero: '',
	complemento: '',
	pessoaComDeficiencia: false,
	tipoDeAcao: 'add',
};

export function inputInitialValues(
	data: ConsultaConsumidorData,
): CadastroConsumidorForm {
	const {
		cpf,
		email,
		nomeCompleto,
		rg,
		orgaoEmissor,
		ufEmissor,
		dataDeNascimento,
		sexo,
		endereco,
		pessoaComDeficiencia,
		telefoneCelular,
		telefoneFixo,
	} = data;
	return {
		cpf: cpf || '',
		email: email || '',
		confirmaEmail: '',
		nomeCompleto: nomeCompleto || '',
		telefoneCelular: telefoneCelular || '',
		confirmaTelefoneCelular: '',
		telefoneFixo: telefoneFixo || '',
		confirmaTelefoneFixo: '',
		rg: rg || '',
		orgaoEmissorDoRg: orgaoEmissor || '',
		ufEmissor: Number(ufEmissor) || '',
		dataDeNascimento: dataDeNascimento ? formatDate(dataDeNascimento) : '',
		sexo: Number(sexo),
		bairro: endereco.bairro || '',
		cep: endereco.cep || '',
		logradouro: endereco.logradouro || '',
		estado: Number(endereco.estado) || '',
		municipioId: endereco.municipioId || '',
		municipioDescricao: endereco.municipio || '',
		numero: endereco.numero || '',
		complemento: '',
		pessoaComDeficiencia,
	};
}

export function convertEmptyToNull(values: object): any {
	if (!values) {
		return null;
	}
	const newData: any = {};

	Object.entries(values).forEach(([key, value]) => {
		newData[key] = value === '' || value === 0 ? null : value;
	});

	return newData;
}

export function convertToLowerCase(values: object): any {
	if (!values) {
		return null;
	}

	const newData: any = {};

	Object.entries(values).forEach(([key, value]) => {
		newData[key] = Number.isNaN(value) ? value : String(value).toLowerCase();
	});

	return newData;
}

export const schema = Yup.object<CadastroConsumidorForm>().shape({
	cpf: Yup.string().required('Campo Obrigatório: CPF.'),
	nomeCompleto: Yup.string().required('Campo Obrigatório: Nome.'),
	email: Yup.string().required('Campo Obrigatório: Email.'),
	confirmaEmail: Yup.string()
		.required('Campo Obrigatório: Confirme o Email.')
		.oneOf([Yup.ref('email')], 'Os Emails não coincidem'),
	telefoneCelular: Yup.string().required(
		'Campo Obrigatório: Telefone Celular.',
	),
	confirmaTelefoneCelular: Yup.string()
		.required('Campo Obrigatório: Confirme o Telefone Celular.')
		.oneOf(
			[Yup.ref('telefoneCelular')],
			'Os Telefones Celulares não coincidem',
		),
	confirmaTelefoneFixo: Yup.string().oneOf(
		[Yup.ref('telefoneFixo')],
		'Os Telefones Fixos não coincidem',
	),
	dataDeNascimento: Yup.string().required(
		'Campo Obrigatório: Data de Nascimento.',
	),
	sexo: Yup.string().required('Campo Obrigatório: Sexo.'),
	// rg: Yup.string().required('Campo Obrigatório: RG.'),
	// orgaoEmissorDoRg: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
	// ufEmissor: Yup.string().required('Campo Obrigatório: UF.'),
	bairro: Yup.string().required('Campo Obrigatório: Bairro.'),
	cep: Yup.string().required('Campo Obrigatório: CEP.'),
	logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
	estado: Yup.string().required('Campo Obrigatório: Estado.'),
	municipioId: Yup.string().required('Campo Obrigatório: Município.'),
	numero: Yup.string().required('Campo Obrigatório: Número.'),
	// validacaoDocumento: Yup.string().when(
	// 	['tipoDeAcao', 'rg', 'orgaoEmissorDoRg', 'ufEmissor'],
	// 	{
	// 		is: (tipoDeAcao, rg, orgaoEmissorDoRg, ufEmissor) =>
	// 			tipoDeAcao === 'add' &&
	// 			((rg !== '' && orgaoEmissorDoRg === '' && ufEmissor === '') ||
	// 				(rg === '' && orgaoEmissorDoRg !== '' && ufEmissor === '') ||
	// 				(rg === '' && orgaoEmissorDoRg === '' && ufEmissor !== '')),
	// 		then: Yup.string().required(
	// 			'Campos Obrigatórios: RG, Órgão Emissor e UF Emissor.',
	// 		),
	// 	},
	// ),
	// validacaoTelefoneCelular: Yup.string().when(
	// 	['tipoDeAcao', 'telefoneCelular'],
	// 	{
	// 		is: (tipoDeAcao, telefoneCelular) =>
	// 			tipoDeAcao === 'add' && telefoneCelular === '',
	// 		then: Yup.string().required('Campo Obrigatório: Telefone Celular.'),
	// 	},
	// ),
	// confirmaTelefoneCelular: Yup.string().when(['tipoDeAcao'], {
	// 	is: tipoDeAcao => tipoDeAcao === 'add',
	// 	then: Yup.string()
	// 		.required('Campo Obrigatório: Confirme o Telefone Celular.')
	// 		.oneOf([Yup.ref('telefoneCelular')], 'Os Celulares não coincidem'),
	// }),
});

export const schemaCheck = Yup.object<CadastroConsumidorForm>().shape({});
