import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_MP } from 'services/_path';
import { Types } from './types';
import {
	listarSolicitacaoPortalSuccess,
	listarSolicitacaoPortalFailure,
} from './actions';

function* listarSolicitacaoPortalRequest({ payload }: any) {
	const response: ApiResponse = yield call(
		getApi,
		PATH_MP,
		`portal/${CHANNEL}/solicitacao/${payload.cpf}`,
	);
	if (response.status === 200) {
		yield put(listarSolicitacaoPortalSuccess(response));
	} else if (response.error) {
		yield put(listarSolicitacaoPortalFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.LISTAR_SOLICITACAO_PORTAL_REQUEST,
		listarSolicitacaoPortalRequest,
	),
]);
