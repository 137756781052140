import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../../types';
import { Types, VivaLeiteState } from './types';

// BENEFICIÁRIOS
import beneficiarios from './beneficiarios/reducer';

// DOCUMENTOS
import documentos from './documentos/reducer';

// NOTAS FISCAIS
import notasFiscais from './notasFiscais/reducer';

// USUARIO
import usuario from './usuario/reducer';

const allReducers = combineReducers({
	beneficiarios,
	documentos,
	notasFiscais,
	usuario,
});

const combineReducer = (state: VivaLeiteState, action: ReducerAction) => {
	if (action.type === Types.SEDS_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
