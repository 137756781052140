import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_MUNICIPIOS_REQUEST = '@cnh/CONSULTA_MUNICIPIOS_REQUEST',
	CONSULTA_MUNICIPIOS_SUCCESS = '@cnh/CONSULTA_MUNICIPIOS_SUCCESS',
	CONSULTA_MUNICIPIOS_FAILURE = '@cnh/CONSULTA_MUNICIPIOS_FAILURE',
	CONSULTA_MUNICIPIOS_CLEAR = '@cnh/CONSULTA_MUNICIPIOS_CLEAR',
}

export interface ConsultaMunicipios {
	status: number;
	data: null | OptionProps[];
}

export interface ConsultaMunicipiosRequest {
	uf?: string;
	codigo?: number;
}
