import { ReducerAction } from 'store/modules/types';
import {
	Types,
	IRequestEmitirAutorizacaoPrevia,
	IResponseEmitirAutorizacaoPrevia,
} from './types';

export function emitirAutorizacaoPreviaRequest(
	payload: IRequestEmitirAutorizacaoPrevia,
): ReducerAction {
	return {
		type: Types.EMITIR_AUTORIZACAO_PREVIA_REQUEST,
		payload,
	};
}
export function emitirAutorizacaoPreviaSuccess(
	payload: IResponseEmitirAutorizacaoPrevia,
): ReducerAction {
	return {
		type: Types.EMITIR_AUTORIZACAO_PREVIA_SUCCESS,
		payload,
	};
}
export function emitirAutorizacaoPreviaFailure(): ReducerAction {
	return {
		type: Types.EMITIR_AUTORIZACAO_PREVIA_FAILURE,
		payload: null,
	};
}
export function emitirAutorizacaoPreviaClear(): ReducerAction {
	return {
		type: Types.EMITIR_AUTORIZACAO_PREVIA_CLEAR,
		payload: null,
	};
}
