import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 35px;

	button {
		margin: 0 15px;
	}
`;
