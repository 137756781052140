import { all } from 'redux-saga/effects';

import aac from './aac/combineSaga';
import biometria from './biometria/combineSaga';
import cdhu from './cdhu/combineSaga';
import dare from './dare/combineSaga';
import iirgd from './iirgd/combineSaga';
import rfb from './rfb/combineSaga';
import sgu from './sgu/combineSaga';
import eCpf from './eCpf/combineSaga';
import prefeituras from './prefeituras/combineSaga';
import educacao from './educacao/combineSaga';
import detranCrv from './detranCrv/combineSaga';
import contabilizacao from './contabilizacao/combineSaga';
import mp from './mp/combineSaga';
import seds from './seds/combineSaga';
import servicosMunicipais from './servicosMunicipais/combineSaga';
import detranCnh from './detranCnh/combineSaga';
import educacaoUpdate from './educacaoUpdate/combineSaga';
import potencial from './potencial/combineSaga';
import utils from './utils/combineSaga';
import procon from './procon/combineSaga';
import sguService from './sguService/combineSaga';
import relatorios from './relatorios/combineSaga';
import gerarDare from './gerarDare/saga';
import bolsaPovo from './bolsaPovo/combineSaga';

export default all([
	aac,
	biometria,
	cdhu,
	dare,
	iirgd,
	rfb,
	sgu,
	eCpf,
	prefeituras,
	educacao,
	detranCrv,
	contabilizacao,
	mp,
	seds,
	servicosMunicipais,
	detranCnh,
	educacaoUpdate,
	potencial,
	utils,
	procon,
	gerarDare,
	sguService,
	relatorios,
	bolsaPovo,
]);
