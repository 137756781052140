import React, { useCallback, useEffect, useState } from 'react';

import { Modal } from 'antd';

// STYLED
import CreateGlobalStyle from './reset';
import { Embed } from './styled';

interface Props {
	title: string;
	source: string;
	renderMode?: 'modal' | 'popup';
	onClose?: () => void;
}

const PDFViewer: React.FC<Props> = ({
	title,
	source,
	renderMode = 'modal',
	onClose,
}) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const renderPopup = useCallback(() => {
		const win = window.open(
			'',
			'_blank',
			'location=yes,height=auto,width=auto,scrollbars=yes,status=false',
		);

		let html = '';
		html += '<html>';
		html += '<body style="margin: 0 !important">';
		html += `<embed width="100%" height="100%" src="data:application/pdf;base64,${source}" type="application/pdf" />`;
		html += '</body>';
		html += '</html>';

		setTimeout(() => {
			if (win) win.document.write(html);
		}, 100);
	}, [source]);

	useEffect(() => {
		if (source) {
			if (renderMode === 'popup') renderPopup();
			if (renderMode === 'modal') setIsVisible(true);

			return;
		}

		setIsVisible(false);
	}, [source, renderMode, renderPopup]);

	const handleOnCancel = useCallback(() => {
		setIsVisible(false);

		if (onClose) onClose();
	}, [onClose]);

	return (
		<>
			<Modal
				title={title}
				visible={isVisible}
				wrapClassName="react-pdf-modal"
				style={{ animationDuration: '0s' }}
				footer={null}
				onCancel={handleOnCancel}
			>
				<Embed title={title} src={`data:application/pdf;base64, ${source}`} />
			</Modal>

			<CreateGlobalStyle />
		</>
	);
};

export default PDFViewer;
