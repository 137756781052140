import { takeLatest, put, all } from 'redux-saga/effects';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, SaveTaxaServicoRequest } from './types';

// ACTIONS
import { saveTaxaServicoSuccess, saveTaxaServicoFailure } from './actions';

function* saveTaxaServico(request: ReducerAction) {
	const { payload }: { payload: SaveTaxaServicoRequest } = request;

	const response = payload;

	if (response.codigoTaxaServico !== '') {
		yield put(saveTaxaServicoSuccess(response));
	} else {
		yield put(saveTaxaServicoFailure(response));
	}
}

export default all([takeLatest(Types.SAVE_TAXA_SERVICO, saveTaxaServico)]);
