// ENTITIES
import { ConsultaPesquisaCidadao } from '../consultaPesquisaCidadao/types';

export enum Types {
	CONSULTA_PESQUISA_CRIMINAL_TEMPORALIDADE = '@iirgd/CONSULTA_PESQUISA_CRIMINAL_TEMPORALIDADE',
	CONSULTA_PESQUISA_CRIMINAL_RESTRICAO = '@iirgd/CONSULTA_PESQUISA_CRIMINAL_RESTRICAO',

	CONSULTA_PESQUISA_CRIMINAL_REQUEST = '@iirgd/CONSULTA_PESQUISA_CRIMINAL_REQUEST',
	CONSULTA_PESQUISA_CRIMINAL_SUCCESS = '@iirgd/CONSULTA_PESQUISA_CRIMINAL_SUCCESS',
	CONSULTA_PESQUISA_CRIMINAL_FAILURE = '@iirgd/CONSULTA_PESQUISA_CRIMINAL_FAILURE',
	CONSULTA_PESQUISA_CRIMINAL_CLEAR = '@iirgd/CONSULTA_PESQUISA_CRIMINAL_CLEAR',
}

export interface IConsultaPesquisaCriminalState {
	status: number;
	data: IConsultaPesquisaCriminal[];
	restricaoCriminal: boolean;
	temporalidade: boolean;
}

export interface IConsultaPesquisaCriminal extends ConsultaPesquisaCidadao {}
