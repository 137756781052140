import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_AAC } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	enviarCentralNominalSuccess,
	enviarCentralNominalFailure,
} from './actions';

function* enviarCentralNominalRequest(request: ReducerAction) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_AAC,
		`aac/${CHANNEL}/enviar-central-nominal`,
		payload,
	);

	if (response.status === 200) {
		yield put(enviarCentralNominalSuccess(response));
	} else if (response.error) {
		yield put(enviarCentralNominalFailure());
	}
}

export default all([
	takeLatest(Types.ENVIAR_CENTRAL_NOMINAL_REQUEST, enviarCentralNominalRequest),
]);
