import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultaDocumentosProduzidosSuccess,
	consultaDocumentosProduzidosFailure,
} from './actions';

function* consultaDocumentosProduzidosRequest(request: ReducerAction) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`documentos-produzidos/${CHANNEL}/atendimentos/documentos-produzidos`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaDocumentosProduzidosSuccess(response));
	} else {
		yield put(consultaDocumentosProduzidosFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_DOCUMENTOS_PRODUZIDOS_REQUEST,
		consultaDocumentosProduzidosRequest,
	),
]);
