import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export function consultaCidadesUFRequest(data: string): ReducerAction {
	return {
		type: Types.CONSULTA_CIDADES_UF_REQUEST,
		payload: data,
	};
}
export function consultaCidadesUFSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_CIDADES_UF_SUCCESS,
		payload,
	};
}
export function consultaCidadesUFFailure(payload: boolean): ReducerAction {
	return {
		type: Types.CONSULTA_CIDADES_UF_FAILURE,
		payload,
	};
}
export function consultaCidadesUFClear(): ReducerAction {
	return {
		type: Types.CONSULTA_CIDADES_UF_CLEAR,
		payload: null,
	};
}
