import React, { Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';
import { andamentoProcessoClear } from 'store/modules/api/detranCrv/Processos/AndamentoProcesso/actions';

const AndamentoProcessoResultado: React.FC = () => {
	const dispatch = useDispatch();
	const data = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.processos.andamentoProcesso.data?.ficha,
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Row>
				<Col span={24}>
					<Section size="sm" title="CONSULTAR FICHA CADASTRAL">
						<Row gutter={[0, 10]}>
							<Col span={6}>N° da Ficha</Col>
							<Col span={6}>
								<strong>{data?.numeroFicha}</strong>
							</Col>
							<Col span={4}>Ano Ficha</Col>
							<Col span={8}>
								<strong>{data?.anoFicha}</strong>
							</Col>
							<Col span={6}>Renavam</Col>
							<Col span={6}>
								<strong>{data?.renavam}</strong>
							</Col>
							<Col span={4}> Chassi</Col>
							<Col span={8}>
								<strong>{data?.chassi}</strong>
							</Col>
							<Col span={6}>Placa</Col>
							<Col span={6}>
								<strong>{data?.placa}</strong>
							</Col>
							<Col span={4}>Município</Col>
							<Col span={8}>
								<strong>{data?.municipio.nome}</strong>
							</Col>
							<Col span={6}>Despachante</Col>
							<Col span={6}>
								<strong>{data?.codigoSSP}</strong>
							</Col>
							<Col span={4}>Opção</Col>
							<Col span={8}>
								<strong>{data?.opcao}</strong>
							</Col>
							<Col span={6}>Status Registro</Col>
							<Col span={6}>
								<strong>{data?.status}</strong>
							</Col>
							<Col span={4} />
							<Col span={8}>
								<strong />
							</Col>
							<Col span={6}>Retorno Consistência</Col>
							<Col span={18}>
								<strong>{data?.retornoConsistencia}</strong>
							</Col>
							<Col span={6}>Retorno da BIN</Col>
							<Col span={18}>
								<strong>{data?.retornoBin}</strong>
							</Col>
							<Col span={24}>
								<Section size="sm" title="DATAS">
									<Row gutter={[0, 10]}>
										<Col span={18}>Inclusão</Col>
										<Col span={6}>
											<strong>
												{data?.dataInclusaoFicha &&
													new Date(data?.dataInclusaoFicha).toLocaleDateString(
														'pt-BR',
													)}
											</strong>
										</Col>
										<Col span={18}>Emissão Documento</Col>
										<Col span={6}>
											<strong>
												{data?.dataEmissaoDocumento &&
													new Date(
														data?.dataEmissaoDocumento,
													).toLocaleDateString('pt-BR')}
											</strong>
										</Col>
										<Col span={18}>Inclusão Histórico</Col>
										<Col span={6}>
											<strong>
												{data?.dataInclusaohistorico &&
													new Date(
														data?.dataInclusaohistorico,
													).toLocaleDateString('pt-BR')}
											</strong>
										</Col>
									</Row>
								</Section>
							</Col>
						</Row>
					</Section>
					<Row justify="space-around" align="middle">
						<Col>
							<ButtonVoltar
								onClick={() => dispatch(andamentoProcessoClear())}
							/>
						</Col>
						<Col>
							<Button className="no-print" onClick={() => window.print()}>
								{' '}
								Imprimir
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Suspense>
	);
};

export default AndamentoProcessoResultado;
