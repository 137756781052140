import { ReducerAction } from 'store/modules/types';

import { Types, IRequestRecurso } from './types';

export function protocolarRecursoRequest(data: IRequestRecurso): ReducerAction {
	return {
		type: Types.PROTOCOLAR_RECURSO,
		payload: data,
	};
}
export function protocolarRecursoSuccess(payload: object): ReducerAction {
	return {
		type: Types.PROTOCOLAR_RECURSO_SUCCESS,
		payload,
	};
}
export function protocolarRecursoFailure(payload: object): ReducerAction {
	return {
		type: Types.PROTOCOLAR_RECURSO_FAILURE,
		payload,
	};
}
