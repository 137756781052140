import { DisponibilzarDareRequest, Types } from './types';

export function disponibilzarDareRequest(payload: DisponibilzarDareRequest) {
	return {
		type: Types.DISPONIBILIZAR_DARE_REQUEST,
		payload,
	};
}
export function disponibilzarDareSuccess(payload: any) {
	return {
		type: Types.DISPONIBILIZAR_DARE_SUCCESS,
		payload,
	};
}
export function disponibilzarDareFailure(payload: []): any {
	return {
		type: Types.DISPONIBILIZAR_DARE_FAILURE,
		payload,
	};
}
export function disponibilzarDareClear(): any {
	return {
		type: Types.DISPONIBILIZAR_DARE_CLEAR,
	};
}
