export enum Types {
	CONSULTAR_PROCESSO_REQUEST = '@rfb/integrador/CONSULTAR_PROCESSO_REQUEST',
	CONSULTAR_PROCESSO_SUCCESS = '@rfb/integrador/CONSULTAR_PROCESSO_SUCCESS',
	CONSULTAR_PROCESSO_FAILURE = '@rfb/integrador/CONSULTAR_PROCESSO_FAILURE',
	CONSULTAR_PROCESSO_CLEAR = '@rfb/integrador/CONSULTAR_PROCESSO_CLEAR',
}

export interface ConsultarProcessoState {
	status: number;
	data: ConsultarProcesso[] | null;
}

export interface ConsultarProcessoRequest {
	cpfCidadao?: string;
	protocoloRFB?: string;
	protocoloOIC?: string;
}

export interface ConsultarProcesso {
	protocoloRFB: string;
	cpfCidadao: string;
	protocoloOIC: string;
	nome: string;
	nomeSocial: string;
	dataNascimento: string;
	codigoMunicipioNaturalidade: number;
	nomeMunicipioNaturalidade: string;
	ufNaturalidade: string;
	sexo: string;
	logradouro: string;
	numeroEndereco: string;
	complementoEndereco: string;
	bairroEndereco: string;
	codigoMunicipioResidencia: string;
	nomeMunicipioResidencia: string;
	ufResidencia: string;
	cepResidencia: string;
	dataHoraProtocoloRFB: string;
	codigoMunicipioEmissao: number;
	nomeMunicipioEmissao: string;
	ufEmissao: string;
	observacao: string;
	indicadorConsistenciaBiografica: true;
	codigoConsistenciaBiometrica: number;
	codigoSituacao: number;
	hashCodeEmissao: string;
	observacaoBiometria: string;
	dataEmissao: string;
	observacaoIdentidade: string;
	indicadorMaeRegistro: boolean;
	paisNaturalidade: {
		codigo: string;
		designacao: string;
		abreviatura: string;
	};
	localidadeResidenciaExterior: string;
	paisNacionalidade: {
		codigo: string;
		designacao: string;
		abreviatura: string;
	};
	paisResidencia: {
		codigo: string;
		designacao: string;
		abreviatura: string;
	};
	indicadorDeficienciaFisica: boolean;
	indicadorDeficienciaAuditiva: boolean;
	indicadorDeficienciaIntelectual: boolean;
	indicadorDeficienciaVisual: boolean;
	indicadorAutismo: boolean;
	tituloEleitor: string;
	codigoTipoSanguineo: number;
	indicadorDoadorOrgaos: boolean;
	codigoEstadoCivil: number;
	certidaoNascimentoCasamento: string;
	averbacaoDivorcio: string;
	cnh: string;
	categoriaCnh: string;
	hashIdentidade: string;
	hashFoto: string;
	pisPasep: string;
	nis: string;
	nit: string;
	carteiraTrabalho: string;
	cartaoNacionalSaude: string;
	motivoSituacao: string;
	codigoAtoCadastral: string;
	contatos: Array<{
		codigo: number;
		tipoContato: number;
		ddi: number;
		telefone: number;
		contato: string;
		observacao: string;
	}>;
	filiacao: Array<{
		codigo: string;
		nome: string;
	}>;
}
