import { combineReducers } from 'redux';

import consultaRenovacaoCnh from './consultaRenovacaoCnh/reducer';
import consultaPcon from './consultaPcon/reducer';
import consultaCnhEstrangeiro from './consultaCnhEstrangeiro/reducer';
import consultaMudancaCategoria from './consultaMudancaCategoria/reducer';
import consultaPermissionarioPontuado from './consultaPermissionarioPontuado/reducer';
import consultaExtratoResumoCondutor from './consultaExtratoResumoCondutor/reducer';
import loginSoap from './loginSoap/reducer';
import consultaGfor from './consultaGfor/reducer';
import logonRest from './logonRest/reducer';
import alteraSenhaDetran from './alteraSenhaDetran/reducer';

export default combineReducers({
	consultaRenovacaoCnh,
	consultaPcon,
	consultaCnhEstrangeiro,
	consultaMudancaCategoria,
	consultaPermissionarioPontuado,
	consultaExtratoResumoCondutor,
	loginSoap,
	consultaGfor,
	logonRest,
	alteraSenhaDetran,
});
