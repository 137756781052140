import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { ConsultarNaturalidadesRequest, Types } from './types';
import {
	consultarNaturalidadesSuccess,
	consultarNaturalidadesFailure,
} from './actions';

// UTILS

function* consultarNaturalidades(request: ReducerAction) {
	const { payload }: { payload: ConsultarNaturalidadesRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarNaturalidadesRequest;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`naturalidades`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarNaturalidadesSuccess(response));
	} else {
		yield put(consultarNaturalidadesFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_NATURALIDADES_REQUEST, consultarNaturalidades),
]);
