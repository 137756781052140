export enum Types {
	ATUALIZAR_MOTIVO = '@sgu/ATUALIZAR_MOTIVO',
	ATUALIZAR_MOTIVO_SUCCESS = '@sgu/ATUALIZAR_MOTIVO_SUCCESS',
	ATUALIZAR_MOTIVO_FAILURE = '@sgu/ATUALIZAR_MOTIVO_FAILURE',
	ATUALIZAR_MOTIVO_CLEAR = '@sgu/ATUALIZAR_MOTIVO_CLEAR',
}

export interface AtualizarMotivo {
	status: number;
	data: null | MotivoData;
}

interface MotivoData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarMotivoRequest {
	id?: number | string;
	codigo: number | string;
	descricao: string;
}
