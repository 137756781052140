import { ReducerAction } from 'store/modules/types';

import { Types, CancelarSolicitacaoEmissaoPIDRequest } from './types';

export function cancelarSolicitacaoEmissaoPIDRequest(
	data: CancelarSolicitacaoEmissaoPIDRequest,
): ReducerAction {
	return {
		type: Types.CANCELAR_SOLICITACAO_EMISSAO_PID_REQUEST,
		payload: data,
	};
}

export function cancelarSolicitacaoEmissaoPIDSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CANCELAR_SOLICITACAO_EMISSAO_PID_SUCCESS,
		payload,
	};
}

export function cancelarSolicitacaoEmissaoPIDFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CANCELAR_SOLICITACAO_EMISSAO_PID_FAILURE,
		payload,
	};
}

export function cancelarSolicitacaoEmissaoPIDClear(): ReducerAction {
	return {
		type: Types.CANCELAR_SOLICITACAO_EMISSAO_PID_CLEAR,
		payload: null,
	};
}
