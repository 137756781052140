import React, { Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';

import { consultarAutorizacaoPreviaClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarAutorizacaoPrevia/actions';
import { consultarDetalhesAutorizacaoPreviaClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/consultarDetalhesAutorizacaoPrevia/actions';

const ConsultarAutorizacaoPreviaResultado: React.FC = () => {
	const dispatch = useDispatch();
	const data = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.solicitarDocumentos.consultarDetalhesAutorizacaoPrevia
				.data?.consultaAutorizacaoPrevia,
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Consultar Autorização Prévia" titleSize="sm">
				<Row>
					<Col span={24}>
						<Section size="lg" title="Autorização" titleSize="sm">
							<Row gutter={[0, 10]}>
								<Col span={6}>Placa</Col>
								<Col span={6}>
									<strong>{data?.placa}</strong>
								</Col>
								<Col span={6}>Chassi</Col>
								<Col span={6}>
									<strong>{data?.chassi}</strong>
								</Col>
								<Col span={6}>Renavam</Col>
								<Col span={6}>
									<strong>{data?.renavam}</strong>
								</Col>
								<Col span={6}>UF</Col>
								<Col span={6}>
									<strong>{data?.uf}</strong>
								</Col>
								<Col span={6}>Ano Modelo</Col>
								<Col span={6}>
									<strong>{data?.anoModelo}</strong>
								</Col>
								<Col span={6}>Ano Fabr.</Col>
								<Col span={6}>
									<strong>{data?.anoFabricacao}</strong>
								</Col>
								<Col span={6}>Número Autorização</Col>
								<Col span={6}>
									<strong>{data?.ocorrencias[0].numeroAutorizacao}</strong>
								</Col>
								<Col span={6}>Situação da Autorização</Col>
								<Col span={6}>
									<strong>{data?.ocorrencias[0].situacaoAutorizacao}</strong>
								</Col>
								<Col span={6}>Tipo de Autorização</Col>
								<Col span={6}>
									<strong>{data?.ocorrencias[0].tipoAutorizacao}</strong>
								</Col>
								<Col span={6}>Código da Autorização</Col>
								<Col span={6}>
									<strong>{data?.ocorrencias[0].codigoAutorizacao}</strong>
								</Col>
								<Col span={6}>Data de Inclusão no SERPRO</Col>
								<Col span={6}>
									<strong>
										{data?.ocorrencias[0].dataHoraRegistroSerpro
											? new Date(
													data?.ocorrencias[0].dataHoraRegistroSerpro,
											  ).toLocaleDateString('pt-BR')
											: ''}
									</strong>
								</Col>
								<Col span={6}>Data da Autorização Detran</Col>
								<Col span={6}>
									<strong>
										{data?.dataAutorizacaoDetran
											? new Date(
													data?.dataAutorizacaoDetran,
											  ).toLocaleDateString('pt-BR')
											: ''}
									</strong>
								</Col>
								<Col span={6}>Validade da Autorização</Col>
								<Col span={6}>
									<strong>
										{data?.dataValidadeAutorizacao
											? new Date(
													data?.dataValidadeAutorizacao,
											  ).toLocaleDateString('pt-BR')
											: ''}
									</strong>
								</Col>
								<Col span={6}>Sistema Consumidor</Col>
								<Col span={6}>
									<strong>{data?.ocorrencias[0].sistemaConsumidor}</strong>
								</Col>
								<Col span={6}>CPF Responsável</Col>
								<Col span={6}>
									<strong>{data?.cpfResponsavel}</strong>
								</Col>
								<Col span={6}>Nome do Responsável</Col>
								<Col span={6}>
									<strong>{data?.nomeResponsavel}</strong>
								</Col>
								<Col span={6}>Observação</Col>
								<Col span={18}>
									<strong>{data?.observacao}</strong>
								</Col>
							</Row>
						</Section>

						<Section size="lg" title="Dados do Proprietário" titleSize="sm">
							<Row>
								<Col span={6}>Marca</Col>
								<Col span={6}>
									<strong>{data?.marca.descricao}</strong>
								</Col>
								<Col span={6}>Tipo</Col>
								<Col span={6}>
									<strong>{data?.tipo.descricao}</strong>
								</Col>
								<Col span={6}>Espécie</Col>
								<Col span={6}>
									<strong>{data?.especie.descricao}</strong>
								</Col>
								<Col span={6}>Carroceria</Col>
								<Col span={6}>
									<strong>{data?.carroceria.descricao}</strong>
								</Col>
								<Col span={6}>Potência</Col>
								<Col span={6}>
									<strong>{data?.potencia}</strong>
								</Col>
								<Col span={6}>Cilindrada</Col>
								<Col span={6}>
									<strong>{data?.cilindrada}</strong>
								</Col>
								<Col span={6}>Capacidade Passageiro</Col>
								<Col span={6}>
									<strong>{data?.capacidadePassageiro}</strong>
								</Col>
								<Col span={6}>Cor</Col>
								<Col span={6}>
									<strong>{data?.cor.descricao}</strong>
								</Col>
								<Col span={6}>Combustível</Col>
								<Col span={6}>
									<strong>{data?.combustivel.descricao}</strong>
								</Col>
								<Col span={6}>CMT</Col>
								<Col span={6}>
									<strong>{data?.cmt}</strong>
								</Col>
								<Col span={6}>PBT</Col>
								<Col span={6}>
									<strong>{data?.pbt}</strong>
								</Col>
								<Col span={6}>Capacidade de Carga</Col>
								<Col span={6}>
									<strong>{data?.capacidadeCarga}</strong>
								</Col>
							</Row>
						</Section>

						<Section
							size="lg"
							title="Modificação / Transformação"
							titleSize="sm"
						>
							<Row>
								<Col span={6}>Marca</Col>
								<Col span={6}>
									<strong>{data?.marca.descricao}</strong>
								</Col>
								<Col span={6}>Tipo</Col>
								<Col span={6}>
									<strong>{data?.tipo.descricao}</strong>
								</Col>
								<Col span={6}>Espécie</Col>
								<Col span={6}>
									<strong>{data?.especie.descricao}</strong>
								</Col>
								<Col span={6}>Carroceria</Col>
								<Col span={6}>
									<strong>{data?.carroceria.descricao}</strong>
								</Col>
								<Col span={6}>Potência</Col>
								<Col span={6}>
									<strong>{data?.potencia}</strong>
								</Col>
								<Col span={6}>Cilindrada</Col>
								<Col span={6}>
									<strong>{data?.cilindrada}</strong>
								</Col>
								<Col span={6}>Capacidade Passageiro</Col>
								<Col span={6}>
									<strong>
										{data?.modificacaoTransformacao.capacidadePassageiro}
									</strong>
								</Col>
								<Col span={6}>Cor</Col>
								<Col span={6}>
									<strong>{data?.cor.descricao}</strong>
								</Col>

								<Col span={6}>Combustível</Col>
								<Col span={6}>
									<strong>{data?.combustivel.descricao}</strong>
								</Col>
								<Col span={6}>CMT</Col>
								<Col span={6}>
									<strong>{data?.cmt}</strong>
								</Col>
								<Col span={6}>PBT</Col>
								<Col span={6}>
									<strong>{data?.pbt}</strong>
								</Col>
								<Col span={6}>Capacidade de Carga</Col>
								<Col span={6}>
									<strong>
										{data?.modificacaoTransformacao.capacidadeCarga}
									</strong>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			</Section>

			<Row justify="space-around" align="middle">
				<Col>
					<ButtonVoltar
						onClick={() => {
							dispatch(consultarAutorizacaoPreviaClear());
							dispatch(consultarDetalhesAutorizacaoPreviaClear());
						}}
					/>
				</Col>

				<Col style={{ marginTop: '13px' }}>
					<Button className="no-print" onClick={() => window.print()}>
						Imprimir
					</Button>
				</Col>
			</Row>
		</Suspense>
	);
};

export default ConsultarAutorizacaoPreviaResultado;
