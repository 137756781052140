import { ReducerAction } from 'store/modules/types';

import { CadastrarNaturalidadeRequest, Types } from './types';

export function cadastrarNaturalidadeRequest(
	payload: CadastrarNaturalidadeRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_NATURALIDADE,
		payload,
	};
}
export function cadastrarNaturalidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_NATURALIDADE_SUCCESS,
		payload,
	};
}
export function cadastrarNaturalidadeFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_NATURALIDADE_FAILURE,
		payload: null,
	};
}
export function cadastrarNaturalidadeClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_NATURALIDADE_CLEAR,
		payload: null,
	};
}
