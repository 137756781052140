import { ReducerAction } from 'store/modules/types';

import { Types, IRequestRegioes } from './types';

export function regioesRequest(data: IRequestRegioes): ReducerAction {
	return {
		type: Types.REGIOES,
		payload: data,
	};
}
export function regioesSuccess(payload: object): ReducerAction {
	return {
		type: Types.REGIOES_SUCCESS,
		payload,
	};
}
export function regioesFailure(payload: boolean): ReducerAction {
	return {
		type: Types.REGIOES_FAILURE,
		payload,
	};
}
