import { takeLatest, call, put, all } from 'redux-saga/effects';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_EDUCACAO_SEDUC_INSCRICOES_API } from 'services/rotasp/path';
import { CadastrarInscricaoRequest, Types } from './types';

// ACTIONS
import {
	cadastrarInscricaoSuccess,
	cadastrarInscricaoFailure,
} from './actions';

function* cadastrarInscricaoRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: CadastrarInscricaoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_EDUCACAO_SEDUC_INSCRICOES_API,
		`inscricoes`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarInscricaoSuccess(response));
	} else if (response.status === 204) {
		yield put(cadastrarInscricaoFailure(response.message));
	} else if (response.error) {
		yield put(cadastrarInscricaoFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CADASTRAR_INSCRICAO_REQUEST, cadastrarInscricaoRequest),
]);
