import * as Yup from 'yup';

// REDUX
import { ConsultaReabilitacaoRequest } from 'store/modules/api/detranCnh/reabilitacao/consultaReabilitacao/types';

// UTILS
import { formatDateToStringEnUs, limparMascara } from 'utils/genericFunctions';
import { minCharacters } from 'utils/yupValidations';

export interface IFormPesquisaReabilitacaoCassacaoCrime {
	cpf: string;
	numeroPgu?: string;
}

interface ICredentials {
	usuario: string;
	senha: string;
	ip: string;
}

export const initialValues: IFormPesquisaReabilitacaoCassacaoCrime = {
	numeroPgu: '',
	cpf: '',
};

export function treatValues(
	values: IFormPesquisaReabilitacaoCassacaoCrime,
	credentials: ICredentials,
): ConsultaReabilitacaoRequest {
	const dataAtual = new Date();
	const horaAtual = dataAtual.getHours();
	const minutoAtual = dataAtual.getMinutes();

	let formattedData: ConsultaReabilitacaoRequest = {
		usuario: credentials.usuario,
		senha: credentials.senha,
		cpf: limparMascara(values.cpf),
		data: formatDateToStringEnUs(dataAtual),
		hora: `${horaAtual}:${minutoAtual}:00`,
		sequencia: '',
		enderecoIp: '',
	};

	if (values.numeroPgu) {
		formattedData = {
			...formattedData,
			numeroPgu: values.numeroPgu,
		};

		return formattedData;
	}

	return formattedData;
}

export const schema =
	Yup.object<IFormPesquisaReabilitacaoCassacaoCrime>().shape({
		cpf: Yup.string().required('Campo Obrigatório: CPF.'),
		numeroPgu: Yup.string().test({
			name: 'minCharacters',
			exclusive: true,
			message: 'PGU deve conter 9 dígitos',
			test: value => minCharacters(9, value),
		}),
	});
