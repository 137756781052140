import styled, { css } from 'styled-components';
import { rgba } from 'polished';

interface TitleProps {
	required: boolean;
	align?: 'start' | 'end' | 'textEnd';
	size: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	setBeforeLeftPosition?: number;
}

interface BoxProps {
	size?: number;
	isErrored: boolean;
	isFocused: boolean;
	isDisabled: boolean;
	isReadOnly: boolean;
}

export const Container = styled.div`
	display: flex;
	align-items: baseline;

	& + div {
		margin-top: 5px;
	}
`;

export const Title = styled.div<TitleProps>`
	margin-right: 10px;
	width: auto;

	${props =>
		props.size === 'sm' &&
		css`
			min-width: 80px;
		`};

	${props =>
		props.size === 'md' &&
		css`
			min-width: 120px;
		`};

	${props =>
		props.size === 'lg' &&
		css`
			min-width: 160px;
		`};

	${props =>
		props.size === 'xl' &&
		css`
			min-width: 200px;
		`};

	${props =>
		props.size === 'auto' &&
		css`
			min-width: auto;
		`};

	${props =>
		!!Number(props.size) &&
		css`
			min-width: ${`${props.size}px`};
		`};

	label {
		align-items: flex-start;
		display: flex;
		font-size: calc(0.75rem + ${props => props.theme.fontScale}%);
		font-weight: 500;
		justify-content: flex-end;

		${props =>
			props.align === 'start' &&
			css`
				justify-content: flex-start;
			`};

		${props =>
			props.align === 'textEnd' &&
			css`
				text-align: end;
			`};

		${props =>
			props.align === 'end' &&
			css`
				text-align: end;
			`};

		${props =>
			props.required &&
			css`
				::before {
					content: '*';
					color: ${props.theme.typography.error};
					font-weight: 700;
					left: ${`${props.setBeforeLeftPosition}px`};
					position: relative;
				}
			`};
	}
`;

export const Content = styled.div`
	width: 100%;
`;

export const Box = styled.div<BoxProps>`
	display: flex;
	width: 100%;

	input {
		height: 25px;
	}

	input:disabled {
		color: rgba(0, 0, 0, 0.25) !important;
		background-color: ${props => props.theme.colors.disabled.input} !important;
	}

	textarea:disabled {
		color: rgba(0, 0, 0, 0.25) !important;
		background-color: ${props => props.theme.colors.disabled.input} !important;
	}

	input,
	textarea {
		background-color: ${props => props.theme.colors.background};
		background-image: none;
		border: 1px solid ${props => props.theme.colors.border};
		border-radius: 2px;
		color: ${props => props.theme.typography.primary};
		font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
		padding: 2px 4px;
		text-transform: uppercase;
		transition: unset;
		width: ${props => props.size}%;

		&:focus {
			box-shadow: 0px 0px 5px #85b2cb, inset 0 2px 2px #8f8f8f;
		}

		&:hover {
			border: 1px solid ${props => props.theme.colors.border};

			${props =>
				props.isErrored &&
				css`
					border: 1px solid rgb(204, 51, 51);
					box-shadow: unset;
				`};
		}

		${props =>
			props.isErrored &&
			css`
				border: 1px solid rgb(204, 51, 51) !important;
				box-shadow: 0px 1px 1px 0px rgb(204, 51, 51) inset !important;
			`};

		${props =>
			props.isReadOnly &&
			css`
				box-shadow: unset !important;
				cursor: default;

				&:focus {
					box-shadow: unset;
				}

				&:hover {
					border: unset;
				}
			`};

		${props =>
			props.isReadOnly &&
			css`
				border: none;
				background-color: unset;
			`};

		${props =>
			props.isDisabled &&
			css`
				background-color: ${props.theme.colors.background} !important;
				border: 1px solid ${rgba(props.theme.colors.border, 0.7)} !important;
				box-shadow: unset !important;
				color: ${rgba(props.theme.typography.primary, 0.5)} !important;

				&:hover {
					cursor: no-drop;
				}
			`};
	}
`;

export const SubTitle = styled.div`
	align-items: center;
	display: flex;
	max-width: fit-content;
	width: 100%;

	span {
		font-size: calc(0.71rem + ${props => props.theme.fontScale}%);
		margin: auto 0px auto 10px;
	}
`;

export const Hint = styled.div`
	color: red;
	font-size: calc(0.68rem + ${props => props.theme.fontScale}%);
	margin-left: 1px;
`;
