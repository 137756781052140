import React from 'react';

import { Row, Col } from 'antd';

// REDUX
import { IRegistroBloqueioDetran } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarBloqueioDetranRenajud/types';
import Section from 'components/Common/Section';

interface Props {
	data: IRegistroBloqueioDetran;
}

const Bloqueio: React.FC<Props> = ({ data }) => {
	return (
		<>
			<Section size="sm" bgColor="cinza-claro">
				<Row gutter={[0, 10]}>
					<Col span={6}>Tipo de Bloqueio</Col>

					<Col span={6}>
						<strong>{data.tipoBloqueio}</strong>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={6}>Data de Inclusão do Bloqueio</Col>

					<Col span={6}>
						<strong>
							{data.dataInclusao &&
								new Date(data.dataInclusao).toLocaleDateString('pt-BR')}
						</strong>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={6}>Número do Protocolo</Col>

					<Col span={6}>
						<strong>{data.numeroProtocolo}</strong>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={6}>Ano Protocolo</Col>

					<Col span={6}>
						<strong>{data.anoProtocolo}</strong>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={6}>Número de Processo</Col>

					<strong>{data.numeroProcesso}</strong>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={6}>Ano de Processo</Col>

					<Col span={6}>
						<strong>{data.anoProcesso}</strong>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={6}>Número de Ofício</Col>

					<Col span={6}>
						<strong>{data.numeroOficio}</strong>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={6}>Município de Bloqueio</Col>

					<Col span={6}>
						<strong>{data.municipioBloqueio.nome}</strong>
					</Col>
				</Row>

				<Row>
					<Col span={6}>Motivo de Bloqueio</Col>

					<Col span={6}>
						<strong>{data.motivoBloqueio}</strong>
					</Col>
				</Row>
			</Section>
		</>
	);
};

export default Bloqueio;
