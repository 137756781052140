import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_IIRGD } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaDBCINRequest, Types } from './types';

// ACTIONS
import {
	consultaDBCINIirgdSuccess,
	consultaDBCINIirgdFailure,
} from './actions';

function* consultaDBCINIirgdRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultaDBCINRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		`emissao-carteira-identidade/${CHANNEL}/pesquisa-cin`,
		payload,
		{},
		true,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultaDBCINIirgdSuccess(response));
	} else {
		yield put(consultaDBCINIirgdFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTA_DBCIN_IIRGD_REQUEST, consultaDBCINIirgdRequest),
]);
