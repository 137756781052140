import React, { Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';
import { ConsultarNumeroMotorBinClear } from 'store/modules/api/detranCrv/ConsultarVeiculos/ConsultarNumeroMotorBin/actions';

const ConsultarNumeroMotorBinResultado: React.FC = () => {
	const dispatch = useDispatch();
	const data = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.consultarVeiculos.consultarNumeroMotorBin.data
				?.dadosMotor,
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Row>
				<Col span={24}>
					<Section size="sm" title="CONSULTAR FICHA CADASTRAL">
						<Row gutter={[0, 10]}>
							<Col span={18}>Número do Motor</Col>
							<Col span={6}>
								<strong>{data?.numeroMotor}</strong>
							</Col>
							<Col span={18}>Placa</Col>
							<Col span={6}>
								<strong>{data?.documentoPesquisado}</strong>
							</Col>
							<Col span={24}>
								<strong>{data?.situacaoMotor}</strong>
							</Col>
						</Row>
					</Section>
					<Row justify="space-around" align="middle">
						<Col>
							<ButtonVoltar
								onClick={() => dispatch(ConsultarNumeroMotorBinClear())}
							/>
						</Col>
						<Col>
							<Button className="no-print" onClick={() => window.print()}>
								{' '}
								Imprimir
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Suspense>
	);
};

export default ConsultarNumeroMotorBinResultado;
