import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarMensagemRequest } from './types';

export function atualizarMensagemRequest(
	data: AtualizarMensagemRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_MENSAGEM_REQUEST,
		payload: data,
	};
}
export function atualizarMensagemSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_MENSAGEM_SUCCESS,
		payload,
	};
}
export function atualizarMensagemFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_MENSAGEM_FAILURE,
		payload: null,
	};
}
export function atualizarMensagemClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_MENSAGEM_CLEAR,
		payload: null,
	};
}
