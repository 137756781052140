import { ConsultaAtendimentoPfRequest } from 'store/modules/api/procon/atendimentoCip/consultaAtendimentoPf/types';
import { ConsultaAtendimentosData } from 'store/modules/api/procon/atendimentos/consultaAtendimentos/types';

const getFirstValidInfoPayloadRequestConsultaAtendimentoPf = (
	data: ConsultaAtendimentoPfRequest,
) => {
	let payload: ConsultaAtendimentoPfRequest = {
		atendimentoId: '',
		protocolo: '',
		numeroFa: '',
	};

	if (data.atendimentoId) {
		payload = {
			...payload,
			atendimentoId: data.atendimentoId,
		};
		return payload;
	}

	if (data.protocolo) {
		payload = { ...payload, protocolo: data.protocolo };
		return payload;
	}

	if (data.numeroFa) {
		payload = { ...payload, numeroFa: data.numeroFa };
		return payload;
	}

	return null;
};

export const threatDataConsultaAtendimentoPfRequest = (
	requestData: ConsultaAtendimentosData,
): ConsultaAtendimentoPfRequest | null => {
	const valuesToThreat = {
		atendimentoId: requestData.atendimentoId,
		protocolo: requestData.protocolo,
		numeroFa: requestData.numeroFa || '',
	};

	const threatedValues =
		getFirstValidInfoPayloadRequestConsultaAtendimentoPf(valuesToThreat);

	return threatedValues;
};
