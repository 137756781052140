import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaFornecedorRequest, Types } from './types';

// ACTIONS
import {
	consultaFornecedorSuccess,
	consultaFornecedorFailure,
} from './actions';

function* consultaFornecedorRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultaFornecedorRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`fornecedores/${CHANNEL}/fornecedor`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultaFornecedorSuccess(response));
	} else {
		yield put(consultaFornecedorFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTA_FORNECEDOR_REQUEST, consultaFornecedorRequest),
]);
