import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultarLocalidadeRequest, Types } from './types';

// ACTIONS
import {
	consultarLocalidadeSuccess,
	consultarLocalidadeFailure,
} from './actions';

function* consultarLocalidade(request: ReducerAction) {
	const { payload }: { payload: ConsultarLocalidadeRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`localidades/${payload.id}`,
	);

	if (response.status === 200) {
		yield put(consultarLocalidadeSuccess({ response, type: payload.type }));
	} else {
		yield put(consultarLocalidadeFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_LOCALIDADE_REQUEST, consultarLocalidade),
]);
