import { ReducerAction } from 'store/modules/types';

import { RequestConsultarMenu, Types } from './types';

export function consultarMenuRequest(
	payload: RequestConsultarMenu,
): ReducerAction {
	return {
		type: Types.CONSULTAR_MENU,
		payload,
	};
}
export function consultarMenuSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_MENU_SUCCESS,
		payload,
	};
}
export function consultarMenuFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_MENU_FAILURE,
		payload: null,
	};
}
export function consultarMenuClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_MENU_CLEAR,
		payload: null,
	};
}
