import { ConsultaAtendimentosData } from 'store/modules/api/procon/atendimentos/consultaAtendimentos/types';
import { ConsultaAtendimentosDeConsultaRequest } from '../../../../../store/modules/api/procon/atendimentosDeConsulta/consultaAtendimentosDeConsulta/types';

const getFirstValidInfoPayloadRequestConsultaAtendimentosDeConsulta = (
	data: ConsultaAtendimentosDeConsultaRequest,
) => {
	let payload: ConsultaAtendimentosDeConsultaRequest = {
		atendimentoId: '',
		protocolo: '',
		numeroFa: '',
	};

	if (data.atendimentoId) {
		payload = {
			...payload,
			atendimentoId: data.atendimentoId,
		};
		return payload;
	}

	if (data.protocolo) {
		payload = { ...payload, protocolo: data.protocolo };
		return payload;
	}

	if (data.numeroFa) {
		payload = { ...payload, numeroFa: data.numeroFa };
		return payload;
	}

	return null;
};

export const threatDataConsultaAtendimentosDeConsultaRequest = (
	requestData: ConsultaAtendimentosData,
): ConsultaAtendimentosDeConsultaRequest | null => {
	const valuesToThreat = {
		atendimentoId: requestData.atendimentoId,
		protocolo: requestData.protocolo,
		numeroFa: requestData.numeroFa || '',
	};

	const threatedValues =
		getFirstValidInfoPayloadRequestConsultaAtendimentosDeConsulta(
			valuesToThreat,
		);

	return threatedValues;
};
