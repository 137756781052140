import React, { useCallback, useEffect, useState } from 'react';

import { Col, Input, Row } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	IConsultarDare,
	// ITaxaConsultarDare,
} from 'store/modules/api/dare/consultarDare/types';

// COMPONENTS
import SimpleTable from 'components/Common/Table';

// UTILS
import { isEqualDates, unformatDate, formatDate } from 'utils/genericFunctions';
import { formatCurrency } from 'utils/numberFunctions';

// COMPONENTS
import { dataIgualHoje } from 'utils/dateFunctions';
import DetalheFilhote from './DetalheFilhote';
import Selecionar from './DetalheFilhote/Selecionar';

interface PesquisaDare {
	numeroControleGuiaMae: string;
	dataGeracao: string;
	valorTotal: string;
	origem: string;
	statusDescricao: string;
}

export const handleOrigem = (id: number): string => {
	switch (id) {
		case 0:
			return 'PORTAL';
		case 1:
			return 'BALCÃO ÚNICO';
		case 2:
			return 'PROCESSAMENTO EM LOTE';
		case 3:
			return 'TOTEM';
		case 4:
			return 'INTRANET';
		case 5:
			return 'MOBILE';
		case 6:
			return 'IIRGD';

		default:
			return '';
	}
};

const Resultado: React.FC = () => {
	const { consultarDare } = useSelector(
		(state: ApplicationState) => state.api.dare,
	);
	const [resultadoDare, setResultadoDare] = useState<IConsultarDare[] | null>(
		consultarDare.data ?? null,
	);

	useEffect(() => {
		if (!consultarDare.data) {
			setResultadoDare(null);
		}
	}, [consultarDare.data]);

	const handleRowClassName = (record: IConsultarDare) => {
		const date = new Date();

		if (
			record.dataVinculoRg &&
			isEqualDates(
				`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
					2,
					'0',
				)}-${String(date.getDate()).padStart(2, '0')}`,
				unformatDate(record.dataVinculoRg.split(' ')[0]),
			)
		)
			return 'green';

		return '';
	};

	const [searchValue, setSearchValue] = useState<PesquisaDare>({
		numeroControleGuiaMae: '',
		dataGeracao: '',
		valorTotal: '',
		origem: '',
		statusDescricao: '',
	});

	const verificaPesquisaVazia = useCallback(() => {
		let isEmpty = true;
		const arraySearchValue = Object.values(searchValue);
		for (let index = 0; index < arraySearchValue.length; index++) {
			const element = arraySearchValue[index];
			if (element !== '') {
				isEmpty = false;
				break;
			}
		}

		return isEmpty;
	}, [searchValue]);

	const checarDareInutilizada = useCallback(
		(record: IConsultarDare, tipo: 'mae' | 'filhote') => {
			let verificador = 0;
			for (let i = 0; i < record.taxasRes.length; i++) {
				const element = record.taxasRes[i];

				if (tipo === 'mae') {
					if (element.status === 3) {
						verificador += 1;
					}
				} else if (tipo === 'filhote') {
					if (element.status === 3 && !dataIgualHoje(element.dataVinculoRg)) {
						verificador += 1;
					}
				}
			}
			if (verificador === record.taxasRes.length) {
				return true;
			}
			return false;
		},
		[],
	);

	const handleUpdateData = useCallback(() => {
		if (!consultarDare.data) {
			return null;
		}

		if (verificaPesquisaVazia()) {
			return setResultadoDare(consultarDare.data);
		}

		const getDescrição = (item: IConsultarDare) => {
			if (checarDareInutilizada(item, 'mae')) {
				return 'INUTILIZADA';
			}
			return item.statusDescricao;
		};

		const newData: IConsultarDare[] | null = consultarDare.data.filter(
			(item: IConsultarDare) => {
				if (
					item.numeroControleGuiaMae
						.toString()
						.indexOf(searchValue.numeroControleGuiaMae) !== -1 &&
					formatDate(item.dataGeracao).indexOf(searchValue.dataGeracao) !==
						-1 &&
					item.valorTotal
						.toString()
						.indexOf(searchValue.valorTotal.replace(',', '.')) !== -1 &&
					handleOrigem(item.origem)
						.toLocaleUpperCase()
						.indexOf(searchValue.origem.toLocaleUpperCase()) !== -1 &&
					getDescrição(item)
						.toString()
						.toLocaleUpperCase()
						.indexOf(searchValue.statusDescricao.toLocaleUpperCase()) !== -1
				) {
					return item;
				}
				return null;
			},
		);
		return setResultadoDare(newData);
	}, [
		checarDareInutilizada,
		consultarDare.data,
		searchValue.dataGeracao,
		searchValue.numeroControleGuiaMae,
		searchValue.origem,
		searchValue.statusDescricao,
		searchValue.valorTotal,
		verificaPesquisaVazia,
	]);

	useEffect(() => {
		handleUpdateData();
	}, [handleUpdateData, searchValue]);

	const handleDareSearch = useCallback((pesquisa: string, campo: string) => {
		setSearchValue((prev: PesquisaDare) => ({ ...prev, [campo]: pesquisa }));
	}, []);

	const columns = [
		{
			dataIndex: 'numeroControleGuiaMae',
			align: 'center',
			title: 'Número',
			sorter: (
				a: { numeroControleGuiaMae: number },
				b: { numeroControleGuiaMae: number },
			) => a.numeroControleGuiaMae - b.numeroControleGuiaMae,
			children: [
				{
					title: (
						<Input
							value={searchValue.numeroControleGuiaMae.toLocaleUpperCase()}
							onChange={(e: { target: { value: string } }) =>
								handleDareSearch(
									e.target.value.toLocaleUpperCase(),
									'numeroControleGuiaMae',
								)
							}
						/>
					),
					dataIndex: 'numeroControleGuiaMae',
					align: 'center',
				},
			],
		},
		{
			dataIndex: 'dataGeracao',
			title: 'Data Geração',
			align: 'center',
			children: [
				{
					title: (
						<Input
							value={searchValue.dataGeracao.toLocaleUpperCase()}
							onChange={(e: { target: { value: string } }) =>
								handleDareSearch(e.target.value, 'dataGeracao')
							}
						/>
					),
					dataIndex: 'dataGeracao',
					render: (text: string, record: IConsultarDare) => {
						return formatDate(record.dataGeracao);
					},
					align: 'center',
				},
			],
			sorter: (a: { dataGeracao: string }, b: { dataGeracao: string }) =>
				new Date(a.dataGeracao).getTime() - new Date(b.dataGeracao).getTime(),
		},
		{
			dataIndex: 'valorTotal',
			width: '15%',
			sorter: (a: { valorTotal: number }, b: { valorTotal: number }) =>
				a.valorTotal - b.valorTotal,
			title: 'Valor Total',
			align: 'center',
			children: [
				{
					render: (text: string, record: IConsultarDare) => {
						return formatCurrency(record.valorTotal);
					},
					title: (
						<Input
							value={searchValue.valorTotal.toLocaleUpperCase()}
							onChange={(e: { target: { value: string } }) =>
								handleDareSearch(e.target.value, 'valorTotal')
							}
						/>
					),
					dataIndex: 'valorTotal',
					align: 'center',
				},
			],
		},
		{
			dataIndex: 'origem',
			align: 'center',
			sorter: (a: { origem: number }, b: { origem: number }) =>
				handleOrigem(a.origem) > handleOrigem(b.origem),
			title: 'Origem',
			children: [
				{
					render: (text: string, record: IConsultarDare) => {
						return handleOrigem(record.origem);
					},
					title: (
						<Input
							value={searchValue.origem.toLocaleUpperCase()}
							onChange={(e: { target: { value: string } }) =>
								handleDareSearch(e.target.value, 'origem')
							}
						/>
					),
					dataIndex: 'origem',
					align: 'center',
				},
			],
		},
		{
			dataIndex: 'statusDescricao',
			align: 'center',
			title: 'Status',
			width: '15%',
			sorter: (
				a: { statusDescricao: string },
				b: { statusDescricao: string },
			) => a.statusDescricao > b.statusDescricao,
			children: [
				{
					render: (text: string, record: IConsultarDare) => {
						const isInutilizada = checarDareInutilizada(record, 'mae');
						if (isInutilizada && text) {
							return 'INUTILIZADA';
						}
						return text ? text.toLocaleUpperCase() : '';
					},
					title: (
						<Input
							value={searchValue.statusDescricao.toLocaleUpperCase()}
							onChange={(e: { target: { value: string } }) =>
								handleDareSearch(e.target.value, 'statusDescricao')
							}
						/>
					),
					dataIndex: 'statusDescricao',
					align: 'center',
				},
			],
		},
		{
			width: '15%',
			dataIndex: '',
			title: 'Vincular com RGE/CIN',
			align: 'center',
			render: (text: string, record: IConsultarDare) => {
				const isDisabled = checarDareInutilizada(record, 'filhote');
				return <Selecionar listaDare={record} isDisabled={isDisabled} />;
			},
		},
	];

	return (
		<>
			{resultadoDare && (
				<Row>
					<Col span={24}>
						<SimpleTable
							headers={columns}
							body={resultadoDare}
							total={resultadoDare.length}
							expandable={{
								expandedRowRender: (record: IConsultarDare, index: number) => (
									<DetalheFilhote record={record} index={index} />
								),
							}}
							size="sm"
							striped
							bold
							hover={false}
							pagination
							rowClassName={handleRowClassName}
						/>
					</Col>
				</Row>
			)}
		</>
	);
};

export default Resultado;
