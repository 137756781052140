import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { toSelect } from 'utils/genericFunctions';
import { Types, consultarMunicipioPorUF } from './types';

export const INITIAL_STATE: consultarMunicipioPorUF = {
	status: 0,
	data: [],
	dataOutrosEstados: [],
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultarMunicipioPorUF {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_MUNICIPIO_POR_UF_REQUEST: {
				break;
			}

			case Types.CONSULTAR_MUNICIPIO_POR_UF_SUCCESS: {
				draft.status = action.payload.status;

				if (action.payload.estado === 'SP') {
					const select = toSelect(
						action.payload.data.municipios,
						'nome',
						'codigo',
					);
					draft.data = select;
				} else {
					const select = toSelect(
						action.payload.data.municipios,
						'nome',
						'codigo',
					);
					draft.dataOutrosEstados = select;
				}
				break;
			}

			case Types.CONSULTAR_MUNICIPIO_POR_UF_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			default:
		}
		return draft;
	});
}
