import { ReducerAction } from 'store/modules/types';

import { Types, IRequestGerarRenachPrimeiraHabilitacao } from './types';

export function gerarRenachPrimeiraHabilitacaoRequest(
	data: IRequestGerarRenachPrimeiraHabilitacao,
): ReducerAction {
	return {
		type: Types.GERAR_RENACH_PRIMEIRA_HABILITACAO,
		payload: data,
	};
}
export function gerarRenachPrimeiraHabilitacaoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.GERAR_RENACH_PRIMEIRA_HABILITACAO_SUCCESS,
		payload,
	};
}
export function gerarRenachPrimeiraHabilitacaoFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.GERAR_RENACH_PRIMEIRA_HABILITACAO_FAILURE,
		payload,
	};
}
