import React, { useCallback, useEffect } from 'react';

import { Row, Col } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultarVeiculoV110Request } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import Radio from 'components/Common/Form/Radio';
import Input from 'components/Common/Form/Input/Input';
import Placa from 'components/Common/Form/Fields/Placa';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { onlyNumbers, onlyNumbersLettersSpace } from 'utils/genericFunctions';

// FORM
import { IConsultarBaixaVeiculo, initialValues, schema } from './form';

const ConsultarVeiculo: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { consultarVeiculoV110 } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	useEffect(() => {
		if (consultarVeiculoV110.status === 200) {
			history.push('/detran-crv/baixar-veiculo/emitir');
		}
	}, [consultarVeiculoV110, history]);

	const handleValidate = useCallback((formValues: IConsultarBaixaVeiculo) => {
		return getValidationsErrors(formValues, schema);
	}, []);

	const handleSubmit = useCallback(
		(formValues: IConsultarBaixaVeiculo) => {
			const { chassi, placa, numeroEspelho } = formValues;

			dispatch(
				consultarVeiculoV110Request({
					chassi,
					placa,
					tipoConsulta: 'BAIXA_CRV',
					numeroEspelho,
				}),
			);
		},
		[dispatch],
	);

	return (
		<>
			<Row>
				<Col span={24}>
					<Section title="Pesquisar">
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={handleValidate}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row>
										<Col span={11} offset={5}>
											<Row gutter={[0, 10]}>
												<Col style={{ marginLeft: '132px' }}>
													<Field
														as={Radio}
														name="tipoPesquisa"
														options={[
															{ label: 'Chassi', value: 'Chassi' },
															{ label: 'Placa', value: 'Placa' },
														]}
														defaultValue={initialValues.tipoPesquisa}
														onChange={(e: RadioChangeEvent) => {
															const { value } = e.target;

															formik.setFieldValue('tipoPesquisa', value);
															formik.setFieldValue('chassi', '');
															formik.setFieldValue('placa', '');
														}}
													/>
												</Col>
											</Row>

											<Row gutter={[0, 10]}>
												<Col span={24}>
													{formik.values.tipoPesquisa === 'Chassi' ? (
														<Field
															as={Input}
															title="Documento"
															name="chassi"
															maxLength={22}
															size={96}
															required
															onChange={(
																v: React.ChangeEvent<HTMLInputElement>,
															) => {
																formik.setFieldValue(
																	'chassi',
																	onlyNumbersLettersSpace(v.target.value),
																);
															}}
															error={!!formik.errors.chassi}
														/>
													) : (
														<Placa
															title="Documento"
															name="placa"
															size={96}
															required
															formik={formik}
														/>
													)}
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<Field
														as={Input}
														required
														titleSize="lg"
														maxLength={12}
														name="numeroEspelho"
														title="Numero do Espelho"
														onChange={(
															v: React.ChangeEvent<HTMLInputElement>,
														) => {
															formik.setFieldValue(
																'numeroEspelho',
																onlyNumbers(v.target.value),
															);
														}}
														size={96}
														error={!!formik.errors.numeroEspelho}
													/>
												</Col>
											</Row>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={24} />
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => {
													formik.handleReset();
													dispatch(clearNotifications());
												}}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>
									</Row>
								</Form>
							)}
						</Formik>
					</Section>

					<ButtonVoltar route="/detran-crv" />
				</Col>
			</Row>
		</>
	);
};

export default ConsultarVeiculo;
