import styled from 'styled-components';

interface ConsultaSituacaoAtual {
	dataEmissaoAtestado: string;
	idIdentificacao: string;
	nome: string;
	posto: string;
	rg: string;
	tipoLiberacao: string;
	usuario: string;
	usuarioCentral: string;
	usuarioPosto: string;
}

interface IProps {
	color?: string[];
}

export const Container = styled.div<IProps>`
	table {
		display: table;
		border-collapse: separate;
		box-sizing: border-box;
		border-spacing: 2px;
		border-collapse: separate;
		border: 5px;
		text-align: center;
		tr {
			th {
				padding: 4px 10px !important;
				text-align: center;
				background: white;
				border: 1px solid #ccc;
				font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
				font-weight: 500;
			}
			td {
				text-align: center;
				background: white;
				border: 1px solid #ccc;
				font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
				color: red;
			}
		}
	}
`;
export const Teste = styled.div`
	tr {
		background: white;
		border: 1px solid #ccc;
		text-align: center;
		th {
			text-align: center;
			background: white;
			border: 1px solid #ccc;
		}
		td {
			text-align: center;
			background: white;
			border: 1px solid #ccc;
		}
	}
`;
