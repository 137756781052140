import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ExcluirUnidadeRequest } from './types';

// ACTIONS
import { excluirUnidadeSuccess, excluirUnidadeFailure } from './actions';

function* excluirUnidade(request: ReducerAction) {
	const { payload }: { payload: ExcluirUnidadeRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_SGU,
		`unidades/${payload.id}`,
	);

	if (response.status === 200) {
		yield put(excluirUnidadeSuccess(response));
	} else {
		yield put(excluirUnidadeFailure());
	}
}

export default all([takeLatest(Types.EXCLUIR_UNIDADE, excluirUnidade)]);
