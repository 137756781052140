export enum Types {
	ATUALIZAR_UNIDADE = '@SGU/ATUALIZAR_UNIDADE',
	ATUALIZAR_UNIDADE_SUCCESS = '@SGU/ATUALIZAR_UNIDADE_SUCCESS',
	ATUALIZAR_UNIDADE_FAILURE = '@SGU/ATUALIZAR_UNIDADE_FAILURE',
	ATUALIZAR_UNIDADE_CLEAR = '@SGU/ATUALIZAR_UNIDADE_CLEAR',
}

export interface AtualizarUnidade {
	status: number;
	data: null | UnidadeData;
}

interface UnidadeData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarUnidadeRequest {
	id?: number | string;
	codigo: number | string;
	nome: string;
	sigla: string;
	tipo?: string;
}
