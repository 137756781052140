import * as Yup from 'yup';

interface IBuscarEndereco {
	uf: string;
	cidade: string;
	logradouro: string;
	numero: string;
}

export const initialValues: IBuscarEndereco = {
	uf: '',
	cidade: '',
	logradouro: '',
	numero: '',
};

const schema = Yup.object<IBuscarEndereco>().shape({
	uf: Yup.string().required('Campo Obrigatório: UF.'),
	cidade: Yup.string().required('Campo Obrigatório: Cidade.'),
	logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
});

export default async function getValidationsErrors(values: IBuscarEndereco) {
	try {
		await schema.validate(values, {
			abortEarly: false,
		});

		return true;
	} catch (err) {
		let errors: any = {};

		err.inner.forEach((error: any) => {
			errors = { ...errors, [error.path]: error.message };
		});

		return errors;
	}
}
