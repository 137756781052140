import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	consultaPedidosConsumidorSuccess,
	consultaPedidosConsumidorFailure,
} from './actions';

function* consultaPedidosConsumidorRequest() {
	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`dominios-crm/${CHANNEL}/pedidos-consumidor`,
		{},
	);

	if (response.status === 200) {
		yield put(consultaPedidosConsumidorSuccess(response));
	} else {
		yield put(consultaPedidosConsumidorFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_PEDIDOS_CONSUMIDOR_REQUEST,
		consultaPedidosConsumidorRequest,
	),
]);
