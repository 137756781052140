import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';

// REDUX
import { ApplicationState } from 'store';
import { consultaDocumentoInfracaoClear } from 'store/modules/api/prefeituras/jalesTransito/documentoInfracao/actions';

import PDFViewer from 'components/Common/PDFViewer';
import { PersonalizedLink } from './styled';

const ConsultaInfracoes = () => {
	const dispatch = useDispatch();
	const [documenteVisible, setDocumenteVisible] = useState(false);

	const { documentoInfracao } = useSelector(
		(state: ApplicationState) => state.api.prefeituras.jalesTransito,
	);

	const handleOnReturn = useCallback(() => {
		dispatch(consultaDocumentoInfracaoClear());
	}, [dispatch]);

	return (
		<>
			<Section title={documentoInfracao.data?.codigoAit}>
				<Row gutter={[0, 16]}>
					<Col>
						<Button type="button" onClick={() => setDocumenteVisible(true)}>
							2ª Via
						</Button>
					</Col>
					{documentoInfracao.data?.urlProtocolar !== '' && (
						<Col offset={1}>
							<PersonalizedLink to="infracoes/recurso">
								<Button type="button">Protocolar Recurso</Button>
							</PersonalizedLink>
						</Col>
					)}
					{documentoInfracao.data?.urlTransferir !== '' && (
						<Col offset={1}>
							<Button type="button">
								<PersonalizedLink to="infracoes/transferencia">
									Transferir Pontuação
								</PersonalizedLink>
							</Button>
						</Col>
					)}
				</Row>
			</Section>
			<ButtonVoltar onClick={handleOnReturn} />
			{documenteVisible && documentoInfracao.data && (
				<PDFViewer
					title="2ª Via"
					source={documentoInfracao.data.documento}
					onClose={() => setDocumenteVisible(false)}
				/>
			)}
		</>
	);
};

export default ConsultaInfracoes;
