import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_BOLSA_POVO } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ExcluirContatoRequest, Types } from './types';

// ACTIONS
import { excluirContatoSuccess, excluirContatoFailure } from './action';

function* excluirContatoRequest(request: ReducerAction) {
	const { payload }: { payload: ExcluirContatoRequest } = request;
	const response: ApiResponse = yield call(
		postApi,
		PATH_BOLSA_POVO,
		`bolsa-povo/${CHANNEL}/deletar-contato`,
		payload,
	);

	if (response.status === 200) {
		yield put(excluirContatoSuccess(response));
	} else if (response.error) {
		yield put(excluirContatoFailure());
	}
}

export default all([
	takeLatest(Types.EXCLUIR_CONTRATO_REQUEST, excluirContatoRequest),
]);
