import { ReducerAction } from 'store/modules/types';
import { Types, IRequestGetCpfPermitido } from './types';

export function getCpfPermitidoRequest(payload: IRequestGetCpfPermitido) {
	return {
		type: Types.GET_CPF_PERMITIDO_REQUEST,
		payload,
	};
}
export function getCpfPermitidoSuccess(payload: object): ReducerAction {
	return {
		type: Types.GET_CPF_PERMITIDO_SUCCESS,
		payload,
	};
}
export function getCpfPermitidoFailure(): ReducerAction {
	return {
		type: Types.GET_CPF_PERMITIDO_FAILURE,
		payload: null,
	};
}

export function getCpfPermitidoClear(): any {
	return {
		type: Types.GET_CPF_PERMITIDO_CLEAR,
	};
}
