import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestHorariosPorData } from './types';

// ACTIONS
import { horariosPorDataSuccess, horariosPorDataFailure } from './actions';

function* horariosPorData(request: ReducerAction) {
	const { payload }: { payload: IRequestHorariosPorData } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`divisao-equitativa/${CHANNEL}/horarios-por-data`,
		payload,
	);

	if (response.status === 200) {
		yield put(horariosPorDataSuccess(response));
	} else if (response.error) {
		yield put(horariosPorDataFailure(response.error));
	}
}

export default all([takeLatest(Types.HORARIOS_POR_DATA, horariosPorData)]);
