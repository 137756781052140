export enum Types {
	PRIMEIRO_EMPLACAMENTO_EMISSAO_REQUEST = '@DETRAN-CRV/PRIMEIRO_EMPLACAMENTO_EMISSAO_REQUEST',
	PRIMEIRO_EMPLACAMENTO_EMISSAO_SUCCESS = '@DETRAN-CRV/PRIMEIRO_EMPLACAMENTO_EMISSAO_SUCCESS',
	PRIMEIRO_EMPLACAMENTO_EMISSAO_FAILURE = '@DETRAN-CRV/PRIMEIRO_EMPLACAMENTO_EMISSAO_FAILURE',
}

export interface primeiroEmplacamentoEmissao {
	status: number;
	data: ficha | null;
	tipoRestricao: string;
}

export interface IRequestWithHeader {
	body: IRequestEmitirPrimeiroEmplacamento;
	headers?: any;
}
interface ficha {
	chassi: string;
	placa: string;
	anoFicha: number;
	numeroficha: number;
	planilhaRenavam: string;
}

export interface IEvento {
	cpf: string;
	id_atendimento?: string;
	id_cidadao?: string;
	idAtendimento?: string;
	idCidadao?: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface IRequestEmitirPrimeiroEmplacamento {
	evento: IEvento;
	anoFabricacao: number;
	anoModelo: number;
	capacidadeCarga: number;
	capacidadePassageiro: number;
	capacidadeVolTanquesSuplementar: number;
	chassi: string;
	chassiRemarcado: boolean;
	cilindrada: number;
	cmt: number;
	eixo: number;
	placa: string;
	pbt: number;
	potencia: number;
	procedencia: string;
	quantidadeTanquesSuplementar: number;
	renavam: number;
	carroceria: {
		longId: number;
		descricao: string;
	};
	categoria: {
		descricao: string;
		longId: number;
	};
	combustivel: {
		longId: number;
		descricao: string;
	};
	cor: {
		longId: number;
		descricao: string;
	};
	especie: {
		longId: number;
		descricao: string;
	};
	marca: {
		longId: number;
		descricao: string;
	};
	municipio: {
		codigo: number;
		nome: string;
	};
	tipo: {
		longId: number;
		descricao: string;
	};
	gravame: {
		arrendatarioFinanceira: string;
		dataVigencia: string;
		financeira: number;
		numeroContrato: string;
		restricaoFinanceira: string;
	};
	proprietario: {
		nomeProprietario: string;
		cepProprietario: number;
		bairro: string;
		enderecoProprietario: string;
		numero: number;
		complemento: string;
		telefone: number;
		identificacao: string;
		cpfCnpjProprietario: number;
		rgProprietario: number;
		orgaoExpeditorProprietario: string;
		ufProprietario: string;
		nomeProprietarioAnterior: string;
		municipioProprietario: {
			codigo: number;
			nome: string;
		};
	};
	numNotaFiscal: number;
	valorNotaFiscal: number;
	dataEmissaoNotaFiscal: string;
	taxaPrimeiroEmplacamento: {
		autenticacaoDigitalDut: string;
		codBanco: string;
		dataContabil: string;
		nsuProdesp: string;
	};
	taxaPlacaAtribuida: {
		autenticacaoDigitalPlacaAtribuida: string;
		codBanco: string;
		dataContabil: string;
		nsuProdesp: string;
	};
	documentos: any;
	emailCidadao: string;
	dddTelefoneCidadao: number;
	telefoneCidadao: number;
	servicoDigital: boolean;
}
