import { ReducerAction } from 'store/modules/types';

import { Types, TrocarSenhaUsuarioRequest } from './types';

export function trocarSenhaUsuarioRequest(
	data: TrocarSenhaUsuarioRequest,
): ReducerAction {
	return {
		type: Types.TROCAR_SENHA_USUARIO,
		payload: data,
	};
}
export function trocarSenhaUsuarioSuccess(payload: object): ReducerAction {
	return {
		type: Types.TROCAR_SENHA_USUARIO_SUCCESS,
		payload,
	};
}
export function trocarSenhaUsuarioFailure(): ReducerAction {
	return {
		type: Types.TROCAR_SENHA_USUARIO_FAILURE,
		payload: null,
	};
}
export function trocarSenhaUsuarioClear(): ReducerAction {
	return {
		type: Types.TROCAR_SENHA_USUARIO_CLEAR,
		payload: null,
	};
}
