import * as Yup from 'yup';

export interface IConfirmacaoAtendimento {
	todosDedos: boolean;
	coletarAssinatura: boolean;
	justificativaAssinatura: string;
	coletarFoto: boolean;
	justificativaFoto: string;
}

export const initialValues: IConfirmacaoAtendimento = {
	todosDedos: true,
	coletarAssinatura: false,
	justificativaAssinatura: '',
	coletarFoto: false,
	justificativaFoto: '',
};

export const schema = Yup.object<IConfirmacaoAtendimento>().shape({
	justificativaAssinatura: Yup.string().when('coletarAssinatura', {
		is: coletarAssinatura => coletarAssinatura,
		then: Yup.string().required(
			'Campo Obrigatório: Justificativa da nova assinatura.',
		),
	}),
	justificativaFoto: Yup.string().when('coletarFoto', {
		is: coletarFoto => coletarFoto,
		then: Yup.string().required(
			'Campo Obrigatório: Justificativa da nova foto.',
		),
	}),
});

export default async function getValidationsErrors(
	values: IConfirmacaoAtendimento,
) {
	try {
		await schema.validate(values, {
			abortEarly: false,
		});

		return true;
	} catch (err) {
		let errors: any = {};

		err.inner.forEach((error: any) => {
			errors = { ...errors, [error.path]: error.message };
		});

		return errors;
	}
}
