import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RequestPesquisaEnderecoRecebimento } from './types';

// ACTIONS
import {
	pesquisaEnderecoRecebimentoSuccess,
	pesquisaEnderecoRecebimentoFailure,
} from './actions';

function* pesquisaEnderecoRecebimento(request: ReducerAction) {
	const { payload }: { payload: RequestPesquisaEnderecoRecebimento } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`condutor/${CHANNEL}/enderecos`,
		payload,
	);

	if (response.status === 200) {
		yield put(pesquisaEnderecoRecebimentoSuccess(response));
	} else if (response.error) {
		yield put(pesquisaEnderecoRecebimentoFailure(response.error));
	}
}

export default all([
	takeLatest(
		Types.PESQUISA_ENDERECO_RECEBIMENTO_REQUEST,
		pesquisaEnderecoRecebimento,
	),
]);
