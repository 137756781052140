import React, { Suspense, useEffect } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';
import { multasRenainfOrgaoRequest } from 'store/modules/api/detranCrv/MultasRenainf/multasRenainfOrgao/actions';
import { initialValues, schema, treatValues } from './form';

const MultasRenainfPorOrgao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { multasRenainfOrgao } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.multasRenainf,
	);

	const { data } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	useEffect(() => {
		if (multasRenainfOrgao.status === 200) {
			history.push('/detran-crv/multas-renainf-orgao-autuador/resultado');
		}
	}, [multasRenainfOrgao, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Consultar RENAINF por Orgão Autuador">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						const formatValuesReq = treatValues(data, values);
						dispatch(multasRenainfOrgaoRequest(formatValuesReq));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[0, 20]}>
									<Col span={24}>
										<Field
											titleSize="lg"
											required
											maxLength={30}
											size={25}
											name="codigoOrgaoAutuador"
											title="Código do Órgão Autuador"
											as={Input}
											error={hasError(formik.errors, 'codigoOrgaoAutuador')}
										/>
									</Col>
									<Col span={24}>
										<Field
											titleSize="lg"
											size={25}
											required
											maxLength={30}
											name="numeroInfracao"
											title="Número do Auto de Infração"
											as={Input}
											error={hasError(formik.errors, 'numeroInfracao')}
										/>
									</Col>
									<Col span={24}>
										<Field
											titleSize="lg"
											size={25}
											required
											maxLength={30}
											name="codigoInfracao"
											title="Código da Infração"
											as={Input}
											error={hasError(formik.errors, 'codigoInfracao')}
										/>
									</Col>
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={formik.handleReset}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
		</Suspense>
	);
};

export default MultasRenainfPorOrgao;
