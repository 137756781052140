import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RequestConsultaEPortal } from './types';

// ACTIONS
import { consultaEPortalSuccess, consultaEPortalFailure } from './actions';

function* consultaEPortal(request: ReducerAction) {
	const { payload }: { payload: RequestConsultaEPortal } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`eportal/${CHANNEL}/pesquisa/pid`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaEPortalSuccess(response));
	} else if (response.error) {
		yield put(consultaEPortalFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTA_E_PORTAL_REQUEST, consultaEPortal),
]);
