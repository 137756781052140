import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, RemoverInscricao } from './types';

export const INITIAL_STATE: RemoverInscricao = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): RemoverInscricao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.REMOVER_INSCRICAO_REQUEST: {
				break;
			}
			case Types.REMOVER_INSCRICAO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.REMOVER_INSCRICAO_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			default:
		}
		return draft;
	});
}
