import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_NATURALIDADE_REQUEST = '@cnh/CONSULTA_NATURALIDADE_REQUEST',
	CONSULTA_NATURALIDADE_SUCCESS = '@cnh/CONSULTA_NATURALIDADE_SUCCESS',
	CONSULTA_NATURALIDADE_NO_CONTENT = '@cnh/CONSULTA_NATURALIDADE_NO_CONTENT',
	CONSULTA_NATURALIDADE_FAILURE = '@cnh/CONSULTA_NATURALIDADE_FAILURE',
}

export interface consultaNaturalidade {
	status: number;
	data: null | OptionProps[];
}

export interface IRequestConsultaNaturalidade {
	uf: string;
}

// interface INaturalidades {
// 	codigo: number;
// 	descricao: string;
// 	uf: string;
// }
