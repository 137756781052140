import { store } from 'store';

import { ReducerAction } from 'store/modules/types';
import { clearAuthToken } from 'store/modules/app/authToken/actions';
import { Types, ILoginRequest } from './types';

export function logInRequest(payload: ILoginRequest): ReducerAction {
	return {
		type: Types.LOG_IN_REQUEST,
		payload,
	};
}

export function logInSuccess(payload: []): ReducerAction {
	return {
		type: Types.LOG_IN_SUCCESS,
		payload,
	};
}

export function logInFailure(payload: []): ReducerAction {
	return {
		type: Types.LOG_IN_FAILURE,
		payload,
	};
}

export function logInChangePassword(payload: {
	status: boolean;
	message: string;
}): ReducerAction {
	return {
		type: Types.LOG_IN_CHANGE_PASSWORD,
		payload,
	};
}

export function logOffRequest(): ReducerAction {
	return {
		type: Types.LOG_OFF_REQUEST,
		payload: null,
	};
}

export function logOffAndReloadRequest(): ReducerAction {
	return {
		type: Types.LOG_OFF_AND_RELOAD_REQUEST,
		payload: null,
	};
}

export function logOffAndReloadRequestApi() {
	store.dispatch({
		type: Types.LOG_OFF_AND_RELOAD_REQUEST,
		payload: null,
	});
}

export function extenderTempoParaInatividade() {
	store.dispatch({
		type: Types.EXTENDER_TEMPO_PARA_INATIVIDADE,
		payload: null,
	});
}

export function logout() {
	store.dispatch({
		type: Types.LOG_OFF_REQUEST,
		payload: null,
	});
}

export function limpaLogin(): ReducerAction {
	clearAuthToken();
	return {
		type: Types.LIMPA_LOGIN,
		payload: null,
	};
}

export function limpaLoginSemLimparAuthToken(): ReducerAction {
	return {
		type: Types.LIMPA_LOGIN,
		payload: null,
	};
}

export function fixLogIn(payload: any): ReducerAction {
	return {
		type: Types.FIX_LOG_IN,
		payload,
	};
}

export function lockUrl(payload: boolean): ReducerAction {
	return {
		type: Types.LOCKED_URL_REQUEST,
		payload,
	};
}
