import { unformatDate } from './genericFunctions';

export const formatDateISOToFullUTC = (dateTime: Date): string => {
	const addZero = (num: number): string => {
		return num < 10 ? `0${num}` : `${num}`;
	};

	const day = addZero(dateTime.getDate());

	const month = addZero(dateTime.getMonth() + 1);

	const year = dateTime.getFullYear();

	let hours = dateTime.getHours();

	const minutes = addZero(dateTime.getMinutes());

	const seconds = addZero(dateTime.getSeconds());

	const ampm = hours >= 12 ? 'PM' : 'AM';

	hours %= 12;

	hours = hours || 12;

	return `${day}/${month}/${year} ${addZero(
		hours,
	)}:${minutes}:${seconds} ${ampm}`;
};

export function formatDateToMileseconds(value: string): number {
	const date = unformatDate(value);
	return new Date(`${date} 00:00`).getTime();
}

export function dataIgualHoje(value: string): boolean {
	if (!value) return false;
	const hoje = new Date();
	const data = new Date(value);
	if (
		hoje.getDay() === data.getDay() &&
		hoje.getMonth() === data.getMonth() &&
		hoje.getFullYear() === data.getFullYear()
	) {
		return true;
	}
	return false;
}

export function comparaData(
	dataInicio: string,
	dataFim: string,
	comparacao: 'maior' | 'menor' | 'maiorIgual',
): boolean {
	const dataInformada = new Date(unformatDate(dataInicio));
	const dataAComparar = new Date(unformatDate(dataFim));

	if (comparacao === 'maiorIgual' && dataInformada >= dataAComparar) {
		return true;
	}

	if (comparacao === 'maior' && dataInformada > dataAComparar) {
		return true;
	}
	if (comparacao === 'menor' && dataInformada < dataAComparar) {
		return true;
	}
	return false;
}

export function getTimestamp(): number {
	return new Date().getTime();
}
