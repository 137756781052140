// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	IConsultarTemporalidadeState,
	Types,
	IRequestConsultarTemporalidade,
} from './types';

export function consultarTemporalidadeRequest(
	data: IRequestConsultarTemporalidade,
): ReducerAction {
	return {
		type: Types.CONSULTAR_TEMPORALIDADE_REQUEST,
		payload: data,
	};
}
export function consultarTemporalidadeSuccess(
	payload: IConsultarTemporalidadeState,
): ReducerAction {
	return {
		type: Types.CONSULTAR_TEMPORALIDADE_SUCCESS,
		payload,
	};
}
export function consultarTemporalidadeFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_TEMPORALIDADE_FAILURE,
		payload: null,
	};
}
export function consultarTemporalidadeClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_TEMPORALIDADE_CLEAR,
		payload: null,
	};
}
