import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarInscricao } from './types';

export const INITIAL_STATE: CadastrarInscricao = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): CadastrarInscricao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CADASTRAR_INSCRICAO_REQUEST: {
				break;
			}
			case Types.CADASTRAR_INSCRICAO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CADASTRAR_INSCRICAO_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CADASTRAR_INSCRICAO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
		return draft;
	});
}
