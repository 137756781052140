export enum Types {
	CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_REQUEST = '@cnh/CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_REQUEST',
	CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_SUCCESS = '@cnh/CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_RENOVACAO_SUCCESS',
	CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_FAILURE = '@cnh/CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_RENOVACAO_FAILURE',
	CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_CLEAR = '@cnh/CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_RENOVACAO_CLEAR',
}

export interface consultarDisponibilidadeMedicoPosto {
	status: number;
	data: null | ConsultarDisponibilidadeMedicoPostoData;
}

export interface ConsultarDisponibilidadeMedicoPostoData {
	codigoUnidadeTransito: string;
	nomeMunicipio: string;
	nomeUnidadeTransito: string;
	realizaExameMedicoUnidade: string;
}

export interface ConsultarDisponibilidadeMedicoPostoRequest {
	codigoPosto: string;
}
