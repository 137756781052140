import React from 'react';
import { Formik, Form, Field } from 'formik';
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Divider } from 'antd';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';

// REDUX
import { capturarBiometriaClear } from 'store/modules/api/biometria/capturarBiometria/actions';
import { consultarDigitaisClear } from 'store/modules/api/utils/consultarDigitais/actions';
import { confrontarDigitaisCnhClear } from 'store/modules/api/utils/confrontarDigitaisCnh/actions';
import { cadastroEnderecosClear } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/actions';
import { validaTokenCnhDigitalClear } from 'store/modules/api/detranCnh/cnhDigital/validaToken/actions';
import { consultaTokenCnhDigitalClear } from 'store/modules/api/detranCnh/cnhDigital/tokenDigital/actions';
import { estatisticaIncluirClear } from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { gerarRenachIntegradoClear } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/actions';
import { consultarInaptoClear } from 'store/modules/api/detranCnh/sockets/consultarInapto/actions';
import { dadosCidadaoClear } from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/actions';
import { verificarVersaoServicoBiometriaClear } from 'store/modules/api/biometria/verificarVersaoServicoBiometria/actions';

// UTILS
import EnumCNH from 'store/modules/api/detranCnh/enum';

interface IProps {
	renderData: GerarRenachForm;
	handleSubmit: (values: GerarRenachForm) => void;
	setPreCadastro: (v: any | null) => void;
}

const ExibicaoPreCadastro: React.FC<IProps> = ({
	renderData,
	handleSubmit,
	setPreCadastro,
}) => {
	const dispatch = useDispatch();

	const { consultaCategoria } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.consulta,
	);

	const handleReturnButton = () => {
		dispatch(cadastroEnderecosClear());
		dispatch(capturarBiometriaClear());
		dispatch(consultarDigitaisClear());
		dispatch(confrontarDigitaisCnhClear());
		dispatch(validaTokenCnhDigitalClear());
		dispatch(consultaTokenCnhDigitalClear());
		dispatch(estatisticaIncluirClear());
		dispatch(gerarRenachIntegradoClear());
		dispatch(consultarInaptoClear());
		dispatch(dadosCidadaoClear());
		dispatch(verificarVersaoServicoBiometriaClear());
		setPreCadastro(null);
	};

	return (
		<Row>
			<Col span={24}>
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={renderData}
					onSubmit={handleSubmit}
				>
					<Form autoComplete="off">
						<Row>
							<Col span={12}>
								<h1 style={{ marginTop: 25 }}>Dados do Pré-Cadastro</h1>
							</Col>
						</Row>
						<Divider
							style={{
								marginTop: 0,
								marginBottom: 10,
								backgroundColor: '#000',
								opacity: 0.6,
							}}
						/>
						{renderData.usoFormularioEnum === 'MUDANCA_CATEGORIA' && (
							<Row gutter={[0, 10]} style={{ marginLeft: '130px' }}>
								<Col span={12}>
									<p>
										<strong>Categoria Pretendida:</strong>&nbsp;
										{
											consultaCategoria.data?.filter(
												categoria =>
													categoria.value ===
													renderData.categoriaPretendida.trim(),
											)[0]?.label
										}
									</p>
								</Col>
							</Row>
						)}

						<Row gutter={[0, 10]} style={{ marginLeft: '130px' }}>
							<Col span={12}>
								<p>
									<strong>Pretende exercer atividade remunerada?</strong>
								</p>
								<p>
									{renderData.flagAtividadeRemunerada === 'S' ? 'Sim' : 'Não'}
								</p>
							</Col>
							{/* <Col offset={1} span={11}>
								<p>
									<strong>Tem necessidade de veículo adaptado?</strong>
								</p>
								<p>{renderData.flagDeficienteFisico === 'S' ? 'Sim' : 'Não'}</p>
							</Col> */}
						</Row>

						<Row gutter={[0, 10]} style={{ marginLeft: '130px' }}>
							{renderData.categoriaPretendida !== 'A' &&
							renderData.categoriaPretendida !== 'B' &&
							renderData.categoriaPretendida !== 'AB' &&
							renderData.categoriaPretendida !== 'X' &&
							renderData.categoriaPretendida !== 'XB' ? (
								<>
									<Col span={12}>
										<p>
											<strong>Realizou exames Toxicológicos?</strong>
										</p>
										<p>
											{renderData.flagExameToxicologico === 'S' ? 'Sim' : 'Não'}
										</p>
									</Col>
									<Col offset={1} span={11}>
										<p>
											<strong>Exame dentro do posto?</strong>
										</p>
										<p>{renderData.flagExamePosto === 'S' ? 'Sim' : 'Não'}</p>
									</Col>
								</>
							) : (
								<Col span={12}>
									<p>
										<strong>Exame dentro do posto?</strong>
									</p>
									<p>{renderData.flagExamePosto === 'S' ? 'Sim' : 'Não'}</p>
								</Col>
							)}
						</Row>

						{/* <Row gutter={[0, 10]} style={{ marginLeft: '130px' }}>
									<Col span={12}>
										<p>
											<strong>
												É militar e vai utilizar o CFC da corporação?
											</strong>
										</p>
										<p>{renderData.flagMilitar === 'S' ? 'Sim' : 'Não'}</p>
									</Col>
								</Row>

								{formik.values.flagMilitar === 'sim' && (
									<Row gutter={[0, 20]}>
										<Col span={12}>
											<Field
												as={Input}
												title="Nome da entidade Militar"
												value={
													EnumCNH.entidadeMilitarEnum.filter(
														codigoEntidade =>
															codigoEntidade.value ===
															renderData.codEntidadeMilitar,
													)[0]?.label
												}
												readOnly
											/>
										</Col>
										<Col offset={1} span={11}>
											<Field
												as={Input}
												title="Nº Carteira Militar"
												name="numCarteiraMilitar"
												readOnly
											/>
										</Col>
									</Row>
								)} */}

						<Row gutter={[0, 10]}>
							<Col span={12}>
								<Row>
									<Col span={24}>
										<Field as={Input} title="Nome" name="nome" readOnly />
									</Col>
								</Row>

								{renderData.nomeSocial && (
									<Row>
										<Col span={24}>
											<Field
												as={Input}
												title="Nome Social"
												name="nomeSocial"
												readOnly
											/>
										</Col>
									</Row>
								)}

								<Row>
									<Col span={24}>
										<Field as={Input} title="CPF" name="cpf" readOnly />
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field as={Input} title="RG" name="numDocumento" readOnly />
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field as={Input} title="RNE" name="rneNumero" readOnly />
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Nome do Pai"
											name="nomePai"
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Nome da Mãe"
											name="nomeMae"
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Filiação Afetiva 1"
											name="paisAfetivo1"
											readOnly
										/>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Filiação Afetiva 2"
											name="paisAfetivo2"
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Sexo"
											value={
												EnumCNH.sexoEnum.filter(
													sexo => sexo.value === renderData.sexo,
												)[0]?.label
											}
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Nacionalidade"
											value={
												EnumCNH.nacionalidadeEnum.filter(
													nacionalidade =>
														nacionalidade.value === renderData.nacionalidade,
												)[0]?.label
											}
											readOnly
										/>
									</Col>
								</Row>
								{renderData.nacionalidade.toString() === '1' && (
									<Row>
										<Col span={24}>
											<Field as={Input} title="UF" name="uf" readOnly />
										</Col>
									</Row>
								)}
								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="CEP"
											name="logradouroCep"
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Número"
											name="logradouroNumero"
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Bairro"
											name="logradouroBairro"
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={InputMask}
											title="Telefone"
											name="telefone"
											mask="(99)9999-9999"
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={InputMask}
											title="Celular"
											name="celular"
											mask="(99)99999-9999"
											readOnly
										/>
									</Col>
								</Row>
							</Col>

							<Col offset={1} span={11}>
								<Row gutter={[0, 50]}>
									<Col span={24} />
								</Row>

								<Row>
									<Col span={11}>
										<Field
											as={Input}
											title="Órgão Emissor"
											name="orgaoExpDocumento"
											readOnly
										/>
									</Col>
									<Col offset={1} span={12}>
										<Field
											as={Input}
											title="UF"
											name="orgaoUfExpDocumento"
											titleSize="sm"
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={11}>
										<Field
											as={Input}
											title="Órgão Emissor"
											name="rneOrgao"
											readOnly
										/>
									</Col>
									<Col offset={1} span={12}>
										<Field
											as={Input}
											title="UF"
											name="rneUf"
											titleSize="sm"
											readOnly
										/>
									</Col>
								</Row>

								<Row gutter={[0, 50]}>
									<Col span={24} />
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={InputMask}
											title="Data de Nascimento"
											name="dataNascimento"
											mask="99/99/9999"
											readOnly
										/>
									</Col>
								</Row>

								<Row gutter={[0, 25]}>
									<Col span={24} />
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Naturalidade"
											name="naturalidade"
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Logradouro"
											name="logradouro"
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Complemento"
											name="logradouroComplemento"
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Município"
											name="logradouroDescMunicipio"
											readOnly
										/>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field as={Input} title="E-mail" name="email" readOnly />
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Field
											as={Input}
											title="Confirmar e-mail"
											name="confirmaEmail"
											readOnly
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row gutter={[0, 20]}>
							<Col span={24} />
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={12}>
								<Field
									as={Input}
									title="Autorizo o envio de SMS pelo DETRAN"
									value={renderData.autorizaSMS === 'S' ? 'Sim' : 'Não'}
									titleSize={210}
									readOnly
								/>
							</Col>
							<Col offset={1} span={11}>
								<Field
									as={Input}
									title="Autorizo o envio de E-mail pelo DETRAN"
									value={renderData.autorizaEmail === 'S' ? 'Sim' : 'Não'}
									titleSize={220}
									readOnly
								/>
							</Col>
						</Row>

						<Row>
							<Col span={12}>
								<h1 style={{ marginTop: 25 }}>Endereço de Recebimento</h1>
							</Col>
						</Row>
						<Divider
							style={{
								marginTop: 0,
								marginBottom: 10,
								backgroundColor: '#000',
								opacity: 0.6,
							}}
						/>

						<Section title="ENDEREÇO RECEBIMENTO CNH" size="sm">
							<Row>
								<Col span={12}>
									<Row>
										<Col span={24}>
											<Field
												as={Input}
												title="CEP"
												name="enderecoRecebimentoCep"
												readOnly
											/>
										</Col>
									</Row>

									<Row>
										<Col span={24}>
											<Field
												as={Input}
												title="Número"
												name="enderecoRecebimentoNumero"
												readOnly
											/>
										</Col>
									</Row>

									<Row>
										<Col span={24}>
											<Field
												as={Input}
												title="Bairro"
												name="enderecoRecebimentoBairro"
												readOnly
											/>
										</Col>
									</Row>
								</Col>

								<Col offset={1} span={11}>
									<Row>
										<Col span={24}>
											<Field
												as={Input}
												title="Logradouro"
												name="enderecoRecebimentoLogradouro"
												readOnly
											/>
										</Col>
									</Row>

									<Row>
										<Col span={24}>
											<Field
												as={Input}
												title="Complemento"
												name="enderecoRecebimentoComplemento"
												readOnly
											/>
										</Col>
									</Row>

									<Row>
										<Col span={24}>
											<Field
												as={Input}
												title="Município"
												name="enderecoRecebimentoMunicipio"
												readOnly
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Section>

						<Row gutter={[0, 20]}>
							<Col span={24} />
						</Row>

						<Row justify="center" align="middle">
							<Col>
								<ButtonImage
									type="button"
									src="retornar"
									onClick={() => handleReturnButton()}
								/>
							</Col>
							<Col offset={1}>
								<ButtonImage type="submit" src="salvar" />
							</Col>
						</Row>
					</Form>
				</Formik>
			</Col>
		</Row>
	);
};

export default ExibicaoPreCadastro;
