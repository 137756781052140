import * as Yup from 'yup';

export interface IRegistrarAutorizacaoPrevia {
	tipoPesquisa: 'Chassi' | 'Placa';
	chassi: string;
	placa: string;
}

export const initialValues: IRegistrarAutorizacaoPrevia = {
	tipoPesquisa: 'Chassi',
	chassi: '',
	placa: '',
};

export const schema = Yup.object<IRegistrarAutorizacaoPrevia>().shape({
	chassi: Yup.string().when('tipoPesquisa', {
		is: tipoPesquisa => tipoPesquisa === 'Chassi',
		then: Yup.string()
			.required('Campo Obrigatório: Documento / Chassi.')
			.max(22, 'Campo Chassi: Tamanho máximo de 22 caracteres.'),
	}),
	placa: Yup.string().when('tipoPesquisa', {
		is: tipoPesquisa => tipoPesquisa === 'Placa',
		then: Yup.string()
			.required('Campo Obrigatório: Documento / Placa.')
			.max(22, 'Campo Placa: Tamanho máximo de 22 caracteres.'),
	}),
});
