import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export function consultaToxicologicoRequest(data: string): ReducerAction {
	return {
		type: Types.CONSULTA_TOXICOLOGICO_REQUEST,
		payload: data,
	};
}
export function consultaToxicologicoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_TOXICOLOGICO_SUCCESS,
		payload,
	};
}
export function consultaToxicologicoFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_TOXICOLOGICO_FAILURE,
		payload,
	};
}
export function consultaToxicologicoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_TOXICOLOGICO_CLEAR,
		payload: null,
	};
}
