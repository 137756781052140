import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultaEPortalAutomatizadoSuccess,
	consultaEPortalAutomatizadoFailure,
} from './actions';

function* consultaEPortalAutomatizado(request: ReducerAction) {
	const { payload }: { payload: string } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CNH,
		`pid/${CHANNEL}/consulta/solicitacao/emissao/${payload}`,
	);

	if (response.status === 200) {
		yield put(consultaEPortalAutomatizadoSuccess(response));
	} else if (response.error) {
		yield put(consultaEPortalAutomatizadoFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_E_PORTAL_AUTOMATIZADO_REQUEST,
		consultaEPortalAutomatizado,
	),
]);
