import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, consultarVeiculoV110 } from './types';

export const INITIAL_STATE: consultarVeiculoV110 = {
	status: 0,
	data: null,
	message: '',
	numeroEspelho: '',
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultarVeiculoV110 {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_VEICULO_V110_REQUEST: {
				draft.numeroEspelho = action.payload.numeroEspelho;
				break;
			}

			case Types.CONSULTAR_VEICULO_V110_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_VEICULO_V110_FAILURE: {
				const mensagem: string = action.payload[0];
				draft.message = mensagem;
				break;
			}
			case Types.CONSULTAR_VEICULO_V110_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.message = INITIAL_STATE.message;
				break;
			}

			default:
		}
		return draft;
	});
}
