import React, { useCallback, useEffect, useState } from 'react';

import { Upload as UploadAntDesign, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { FormikProps } from 'formik';

import { Container, Title, Content, Box, SubTitle, Hint } from './styled';

interface UploadProps {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	titleQuestion?: boolean;
	subtitle?: string;
	hint?: string;
	name: string;
	required?: boolean;
	disabled?: boolean;
	position?: 'column' | 'row';
	size?: number;
	error?: boolean;
	formik?: FormikProps<any>;
	clear?: boolean;
	accept?: string;
	type?: string;
}

const Input: React.FC<UploadProps> = ({
	title,
	titleSize = 'md',
	titleQuestion = false,
	subtitle,
	hint,
	name,
	required = false,
	disabled = false,
	position = 'column',
	size,
	error,
	formik,
	clear = false,
	accept = '',
	type = '',
	...rest
}) => {
	const [files, setFiles] = useState<any[]>([]);

	const fileToBase64 = (file: any) => {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = event => {
				if (event?.target?.result !== null && event?.target?.result) {
					resolve(event.target.result);
				}
			};
		});
	};

	const handleChange = useCallback(
		async (info: any) => {
			let itemFileList = [...info.fileList];

			itemFileList = itemFileList.slice(-1);

			setFiles(itemFileList);
			if (info.file.status !== 'removed') {
				const b64File = await fileToBase64(info.file).then(result => {
					return result;
				});
				const b64 = b64File as string;
				const b64String = b64
					.toString()
					.replace(/(data:)+[\w/\-?=%.]+(;base64,)/, '');

				formik?.setFieldValue(name, b64String);

				if (type !== '') {
					const b64TypeArray = b64.toString().split(';base64')[0].split('/');
					const b64Type = b64TypeArray[b64TypeArray.length - 1];
					formik?.setFieldValue(type, b64Type);
				}

				return;
			}

			// RESETA O ARQUIVO NO ESTADO E NO OBJETO NO FORMIK
			setFiles([]);
			formik?.setFieldValue(name, '');
		},
		[formik, name, type],
	);

	useEffect(() => {
		if (clear) {
			setFiles([]);
		}
	}, [clear]);

	const propsUpload = {
		name,
		disabled,
		id: name,
		multiple: false,
		onRemove: () => {
			setFiles([]);
		},
		beforeUpload: (file: any) => {
			setFiles([file]);
			return false;
		},
		onChange: (info: any) => handleChange(info),
	};

	return (
		<Container>
			{title && (
				<Title size={titleSize} required={required}>
					<label htmlFor={name}>{`${title}${titleQuestion ? '?' : ':'}`}</label>
				</Title>
			)}

			<Content>
				<Box size={size} isErrored={!!error} position={position}>
					<UploadAntDesign
						fileList={files}
						accept={accept}
						{...propsUpload}
						{...rest}
					>
						<Button icon={<UploadOutlined />}>Escolha o arquivo</Button>
					</UploadAntDesign>

					{subtitle && (
						<SubTitle>
							<span>{subtitle}</span>
						</SubTitle>
					)}
				</Box>

				{hint && <Hint>{hint}</Hint>}
			</Content>
		</Container>
	);
};

export default Input;
