import React, { useCallback } from 'react';

import RecuperaAtendimento from 'components/Pages/RecuperaAtendimento';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ReconhecimentoPaternidade: React.FC = () => {
	const history = useHistory();

	const { redirecionamento } = useSelector(
		(state: ApplicationState) => state.atendimento,
	);

	const handleClick = useCallback(() => {
		if (redirecionamento) {
			history.push(redirecionamento);
		}
	}, [redirecionamento, history]);

	return <RecuperaAtendimento onClick={() => handleClick()} />;
};

export default ReconhecimentoPaternidade;
