import produce from 'immer';
import { Types, AtendimentoState } from './types';
import { ReducerAction } from '../types';

export const INITIAL_STATE: AtendimentoState = {
	atendimentoData: {
		cpf: '',
		estadoPonto: '',
		estadoUsuario: '',
		idPonto: 0,
		id_atendimento: '',
		id_cidadao: '',
		msg: '',
		nomePonto: '',
	},
	atendimentoUserData: {
		Id: '',
		Nome: '',
		Sexo: '',
		NomeMae: '',
		NomePai: '',
		CPF: '',
		RG: '',
		DigitoRG: '',
		UFRG: '',
		Email: '',
		Nascimento: '',
		TipoRegistro: '',
		Enderecos: [],
		Telefones: [],
	},
	redirecionamento: null,
	descricaoServico: null,
};

export default function atendimento(
	state = INITIAL_STATE,
	action: ReducerAction,
): AtendimentoState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_ATENDIMENTO: {
				break;
			}
			case Types.CONSULTA_ATENDIMENTO_SUCCESS: {
				draft.atendimentoData = action.payload.data;
				break;
			}
			case Types.CONSULTA_ATENDIMENTO_FAILURE: {
				break;
			}

			case Types.CONSULTA_ATENDIMENTO_USER_DATA: {
				break;
			}
			case Types.CONSULTA_ATENDIMENTO_USER_DATA_SUCCESS: {
				draft.atendimentoUserData = action.payload.data;
				break;
			}
			case Types.CONSULTA_ATENDIMENTO_USER_DATA_FAILURE: {
				break;
			}

			case Types.REDIRECIONAMENTO_ATENDIMENTO: {
				draft.redirecionamento = action.payload.route;
				draft.descricaoServico = action.payload.descricaoServico || null;
				break;
			}
			// case Types.REDIRECIONAMENTO_ATENDIMENTO: {
			// 	if (action.payload.data) {
			// 		draft.redirecionamento = ROUTE_DETRAN_CRV_CONSULTA_VEICULO_BASE_ESTADUAL;
			// 	} else {
			// 		draft.redirecionamento = ROUTE_DETRAN_CRV_RECUPERAR_ATENDIMENTO;
			// 	}
			// 	break;
			// }
			case Types.REDIRECIONAMENTO_ATENDIMENTO_CLEAR: {
				draft.redirecionamento = null;
				break;
			}
			default:
		}
	});
}
