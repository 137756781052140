// TYPES
import { ReducerAction } from 'store/modules/types';
import { PesquisaFornecedorRequest, Types } from './types';

export function pesquisaFornecedorRequest(
	payload: PesquisaFornecedorRequest,
): ReducerAction {
	return {
		type: Types.PESQUISA_FORNECEDOR_REQUEST,
		payload,
	};
}
export function pesquisaFornecedorSuccess(payload: object): ReducerAction {
	return {
		type: Types.PESQUISA_FORNECEDOR_SUCCESS,
		payload,
	};
}
export function pesquisaFornecedorFailure(payload: object): ReducerAction {
	return {
		type: Types.PESQUISA_FORNECEDOR_FAILURE,
		payload,
	};
}
export function pesquisaFornecedorClear(): ReducerAction {
	return {
		type: Types.PESQUISA_FORNECEDOR_CLEAR,
		payload: null,
	};
}
