import React from 'react';

import { v4 } from 'uuid';

import { Breadcrumb as BreadcrumbAD } from 'antd';
import { Link } from 'react-router-dom';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import DateTimeClock from '../DateTimeClock';

// STYLED
import { Container, Span } from './styled';

const BreadCrumb: React.FC = () => {
	const { data } = useSelector(
		(state: ApplicationState) => state.app.breadcrumb,
	);

	return (
		<>
			{data && (
				<Container className="no-print">
					<BreadcrumbAD separator="/">
						{data.map(item => (
							<BreadcrumbAD.Item key={v4()}>
								{item.route ? (
									<Link to={item.route}>{item.name}</Link>
								) : (
									<Span>{item.name}</Span>
								)}
							</BreadcrumbAD.Item>
						))}
					</BreadcrumbAD>

					<DateTimeClock />
				</Container>
			)}
		</>
	);
};

export default BreadCrumb;
