import React, { Suspense, useEffect, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import Select, { OptionProps } from 'components/Common/Form/Select';
import Section from 'components/Common/Section';
import { consultarVeiculoBaseEstadualRequest } from 'store/modules/api/detranCrv/ConsultarVeiculos/consultarVeiculoBaseEstadual/actions';
import { useDispatch, useSelector } from 'react-redux';
import { consultarMunicipioPorUfRequest } from 'store/modules/api/detranCrv/enum/consultarMunicipioPorUF/actions';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';
import { initialValues, schema, treatValues } from './form';

const ConsultaVeiculoBaseEstadual: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { consultarMunicipioPorUF } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.Enum,
	);
	const { consultarVeiculoBaseEstadual } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarVeiculos,
	);

	const [listaMunicipios, setListaMunicipios] = useState(
		consultarMunicipioPorUF.data,
	);

	useEffect(() => {
		dispatch(consultarMunicipioPorUfRequest('SP'));
	}, [dispatch]);

	useEffect(() => {
		if (consultarMunicipioPorUF.status === 200) {
			setListaMunicipios(consultarMunicipioPorUF.data);
		}

		if (consultarVeiculoBaseEstadual.status === 200) {
			history.push('/detran-crv/consulta-veiculo-base-estadual/resultado');
		}
	}, [consultarMunicipioPorUF, consultarVeiculoBaseEstadual, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						const formatValuesReq = treatValues(values);
						dispatch(consultarVeiculoBaseEstadualRequest(formatValuesReq));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[0, 20]}>
									<Col span={8}>
										<Field
											maxLength={7}
											name="placa"
											title="Placa"
											as={Input}
											error={hasError(formik.errors, 'placa')}
										/>
									</Col>
									<Col span={8}>
										<Field
											maxLength={11}
											name="renavam"
											title="Renavam"
											as={Input}
											error={hasError(formik.errors, 'renavam')}
										/>
									</Col>
									<Col span={24}>
										<Field
											as={Select}
											size={25}
											defaultFirstOption
											name="municipio.codigo"
											title="Município"
											subtitle="Obrigatório somente se placa de duas letras."
											subtitleColor="red"
											options={listaMunicipios}
											error={!!formik.errors.municipio}
											onChange={(v: string) => {
												formik.setFieldValue('municipio.codigo', v);
												const teste = listaMunicipios.filter(
													(item: OptionProps) => {
														if (item.value === v) {
															return item.label;
														}
														return '';
													},
												);
												formik.setFieldValue('municipio.nome', teste[0].label);
											}}
										/>
									</Col>
									<Col span={16}>
										<Field
											size={60}
											maxLength={22}
											name="chassi"
											title="Chassi"
											as={Input}
											error={hasError(formik.errors, 'chassi')}
										/>
									</Col>
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={formik.handleReset}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
		</Suspense>
	);
};

export default ConsultaVeiculoBaseEstadual;
