// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function confrontarDigitaisRequest(
	numeroRg: string,
	posicaoDedo: string,
	digital: string,
): ReducerAction {
	return {
		type: Types.CONFRONTAR_DIGITAIS_REQUEST,
		payload: {
			numeroRg,
			posicaoDedo,
			digital,
		},
	};
}
export function confrontarDigitaisSuccess(): ReducerAction {
	return {
		type: Types.CONFRONTAR_DIGITAIS_SUCCESS,
		payload: null,
	};
}
export function confrontarDigitaisFailure(): ReducerAction {
	return {
		type: Types.CONFRONTAR_DIGITAIS_FAILURE,
		payload: null,
	};
}
export function confrontarDigitaisClear(): ReducerAction {
	return {
		type: Types.CONFRONTAR_DIGITAIS_CLEAR,
		payload: null,
	};
}
