import { combineReducers } from 'redux';

import consultaPrimeiraHabilitacao from './consultaPrimeiraHabilitacao/reducer';
import gerarPreCadastro from './gerarPreCadastro/reducer';
import gerarRenachPrimeiraHabilitacao from './gerarRenachPrimeiraHabilitacao/reducer';

export default combineReducers({
	consultaPrimeiraHabilitacao,
	gerarPreCadastro,
	gerarRenachPrimeiraHabilitacao,
});
