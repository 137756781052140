export enum Types {
	CONSULTA_ATENDIMENTO_PF_REQUEST = '@procon/CONSULTA_ATENDIMENTO_PF_REQUEST',
	CONSULTA_ATENDIMENTO_PF_SUCCESS = '@procon/CONSULTA_ATENDIMENTO_PF_SUCCESS',
	CONSULTA_ATENDIMENTO_PF_FAILURE = '@procon/CONSULTA_ATENDIMENTO_PF_FAILURE',
	CONSULTA_ATENDIMENTO_PF_CLEAR = '@procon/CONSULTA_ATENDIMENTO_PF_CLEAR',
}

export interface ConsultaAtendimentoPf {
	status: null | number;
	data: null | ConsultaAtendimentoPfData;
}

export interface ConsultaAtendimentoPfData {
	atendimentoId: string;
	protocolo: string;
	consumidor?: {
		cpf?: string;
		cnpj?: string;
		email: string;
		nome: string;
		telefoneFixo: string;
		telefoneCelular: string;
		endereco: {
			bairro: string;
			cep: string;
			complemento: string;
			estado: string;
			logradouro: string;
			municipio: string;
			municipioId: string;
			numero: string;
		};
		rg: string;
		orgaoEmissorDoRg: string;
		ufDeEmissaoDoRg: string;
		dataDeNascimento: string;
		idade: number;
		sexo: string;
	};
	tipoDoConsumidor: string;
	localizacaoAtual: string;
	numeroDoProtocolo: string;
	razaoDoStatus: string;
	tipoDeAtendimento: string;
	dataDaSolicitacao: string;
	participacaoEmAudiencia: string;
	fornecedor: {
		cnpj: string;
		cpf: string;
		email: string;
		endereco: {
			bairro: string;
			cep: string;
			complemento: string;
			estado: string;
			logradouro: string;
			municipio: string;
			municipioId: string;
			numero: string;
			cidade: string;
		};
		telefone: string;
		razaoSocial: string;
		nomeFantasia: string;
		website: string;
	};
	tipoDoFornecedor: string;
	area: string;
	assunto: string;
	grupoDeProblema: string;
	problema: string;
	prazoDeAtendimento: string;
	procureiOFornecedorParaSolucionarAQuestao: string;
	descricaoDoPrimeiroContatoComFornecedor: string;
	formaDeAquisicao: string;
	tipoDeContratacao: string;
	dataDaContratacao: string;
	nomeDoServicoOuProduto: string;
	detalhesDoServicoOuProduto: string;
	tipoDoDocumentoDeCompra: string;
	numeroDoDocumentoDeCompra: string;
	dataDaOcorrenciaOuServico: string;
	dataDeCancelamentoDesistenciaOuNegativa: string;
	formaDePagamento: string;
	valorDaCompra: number;
	descricaoDoProblema: string;
	pedidoDoConsumidor: string;
	formaDeDevolucao: string;
	detalhesDaReclamacao: string;
	respostaDoConsumidor: string;
	respostaDoFornecedor: string;
	ultimaAtualizacao: string;
	anexos: string[];
	rgDoResponsavelOuProcurador: string;
	nomeDoResponsavelOuProcurador: string;
	cpfDoProcuradorOuResponsavel: string;
	idDoBalcaoUnico: number;
	compraAgencia: string;
	compraBancoEmissor: string;
	compraBandeiraDoCartao: string;
	compraChavePix: string;
	compraConta: string;
	compraIntermediadoraDePagamento: string;
	compraNumeroDoCartao: string;
	compraTipoDeChavePix: 0;
	devolucaoAgencia: string;
	devolucaoBancoEmissorDoCartao: string;
	devolucaoBandeiraDoCartao: string;
	devolucaoChavePix: string;
	devolucaoConta: string;
	devolucaoNomeDoBanco: string;
	devolucaoNumeroDeParcelas: 0;
	devolucaoNumeroDoCartao: string;
	devolucaoTipoDeChavePix: 0;
	devolucaoValorDaParcela: 0;
	interacao: string;
	interacaoValor: number;
	numeroFa?: string;
}

export interface ConsultaAtendimentoPfRequest {
	atendimentoId: string;
	protocolo: string;
	numeroFa?: string;
}
