export enum Types {
	CONSULTAR_MUNICIPIO_CODIGO_PRODESP_REQUEST = '@DETRAN-CRV/CONSULTAR_MUNICIPIO_CODIGO_PRODESP_REQUEST',
	CONSULTAR_MUNICIPIO_CODIGO_PRODESP_SUCCESS = '@DETRAN-CRV/CONSULTAR_MUNICIPIO_CODIGO_PRODESP_SUCCESS',
	CONSULTAR_MUNICIPIO_CODIGO_PRODESP_FAILURE = '@DETRAN-CRV/CONSULTAR_MUNICIPIO_CODIGO_PRODESP_FAILURE',
	CONSULTAR_MUNICIPIO_CODIGO_PRODESP_CLEAR = '@DETRAN-CRV/CONSULTAR_MUNICIPIO_CODIGO_PRODESP_CLEAR',
}

export interface consultarMunicipioCodigoProdesp {
	status: number;
	data: DataConsultarMunicipioCodigoProdesp | null;
}

interface DataConsultarMunicipioCodigoProdesp {
	codigoMunicipio: number;
	nomeOficial: string;
	nomeAbreviado: string;
	codigoPRODESP: number;
	codigoIbge: number;
	cep: number;
	codigoUF: number;
	nomeUF: string;
	siglaUF: string;
}

export interface ConsultarMunicipioCodigoProdespRequest {
	evento: {
		id_atendimento: string;
		id_cidadao: string;
		cpf: string;
		identificacao: string;
		ip: string;
		canal: {
			id: number;
			desc: string;
			estacao: {
				id: number;
				desc: string;
			};
			localidade: {
				id: number;
				desc: string;
			};
		};
	} | null;
	codigoMunicipioCorreio: string;
}
