import produce from 'immer';
import { Types, IUsuarioState } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: IUsuarioState = {
	usuarioData: [],
	atualizacaoCadastralData: {
		dados_visualizar: {
			nome_responsavel: '',
			rg_responsavel: '',
			cpf_responsavel: '',
			endereco_responsavel: '',
			complemento_responsavel: '',
			bairro_responsavel: '',
			cep_responsavel: '',
			municipio_responsavel: '',
			responsavel_vice: '',
			municipio_endereco_entrega: '',
			distrito_endereco_entrega: '',
			zona_endereco_entrega: '',
		},
		dados_atualizar: {
			numero_endereco_responsavel: '',
			telefone_1_responsavel: '',
			telefone_2_responsavel: '',
			celular_responsavel: '',
			id_responsavel: '',
			entrega_segunda: '',
			entrega_terca: '',
			entrega_quarta: '',
			entrega_quinta: '',
			entrega_sexta: '',
			entrega_sabado: '',
			horario_entrega_usina: '',
			horario_entrega_beneficiarios: '',
			email_2_endereco_entrega: '',
			enderecos_entrega: [],
		},
	},
	selectedUser: {
		numeroPan: 0,
		presidente: '',
		razaoSocial: '',
		responsavel: '',
		situacaoResponsaveis: '',
		status: '',
	},
	selectedCPF: '',
	statusTransacaoUsuario: '',
	alertas: [],
	redirectUrlUsuario: '',
	servicos: [],
};

export default function vivaLeiteUsuario(
	state = INITIAL_STATE,
	action: ReducerAction,
): IUsuarioState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_USUARIO: {
				break;
			}
			case Types.CONSULTA_USUARIO_SUCCESS: {
				draft.usuarioData = action.payload.response.data.entidades;
				draft.selectedCPF = action.payload.cpf;
				break;
			}
			case Types.CONSULTA_USUARIO_FAILURE: {
				draft.usuarioData = INITIAL_STATE.usuarioData;
				break;
			}

			case Types.SELECIONA_USUARIO: {
				draft.selectedUser = action.payload;
				break;
			}

			case Types.CONSULTA_ATUALIZACAO_CADASTRAL: {
				break;
			}
			case Types.CONSULTA_ATUALIZACAO_CADASTRAL_SUCCESS: {
				const atualizacaoCadastral = {
					...action.payload.data.dados_cadastrais,
				};

				if (atualizacaoCadastral.dados_atualizar) {
					const horario_entrega_usina = action.payload.data.dados_cadastrais.dados_atualizar.horario_entrega_usina.split(
						':',
					);
					const horario_entrega_beneficiarios = action.payload.data.dados_cadastrais.dados_atualizar.horario_entrega_beneficiarios.split(
						':',
					);
					atualizacaoCadastral.dados_atualizar.horario_entrega_usina = `${`00${horario_entrega_usina[0]}`.slice(
						-2,
					)}:${`00${horario_entrega_usina[1]}`.slice(-2)}`;

					atualizacaoCadastral.dados_atualizar.horario_entrega_beneficiarios = `${`00${horario_entrega_beneficiarios[0]}`.slice(
						-2,
					)}:${`00${horario_entrega_beneficiarios[1]}`.slice(-2)}`;
				}

				draft.atualizacaoCadastralData = atualizacaoCadastral;
				break;
			}
			case Types.CONSULTA_ATUALIZACAO_CADASTRAL_FAILURE: {
				draft.alertas = INITIAL_STATE.alertas;
				break;
			}

			case Types.CONSULTA_ALERTAS: {
				break;
			}
			case Types.CONSULTA_ALERTAS_SUCCESS: {
				draft.alertas = action.payload.data.alertas;
				break;
			}
			case Types.CONSULTA_ALERTAS_FAILURE: {
				draft.atualizacaoCadastralData = INITIAL_STATE.atualizacaoCadastralData;
				break;
			}

			case Types.CONSULTA_SERVICOS_ENTIDADES: {
				break;
			}
			case Types.CONSULTA_SERVICOS_ENTIDADES_SUCCESS: {
				draft.servicos = action.payload.data.servicos;
				break;
			}
			case Types.CONSULTA_SERVICOS_ENTIDADES_FAILURE: {
				draft.atualizacaoCadastralData = INITIAL_STATE.atualizacaoCadastralData;
				break;
			}

			case Types.ATUALIZA_ATUALIZACAO_CADASTRAL: {
				break;
			}
			case Types.ATUALIZA_ATUALIZACAO_CADASTRAL_SUCCESS: {
				draft.atualizacaoCadastralData.dados_atualizar =
					action.payload.dados_atualizar;
				draft.statusTransacaoUsuario =
					action.payload.response.data.status_transacao;
				draft.redirectUrlUsuario = action.payload.redirectUrl;
				break;
			}
			case Types.ATUALIZA_ATUALIZACAO_CADASTRAL_FAILURE: {
				break;
			}

			case Types.REDIRECT_USUARIO_VIVA_LEITE_CLEAR: {
				draft.redirectUrlUsuario = '';
				break;
			}

			case Types.USUARIO_CLEAR: {
				draft.usuarioData = [];
				draft.selectedCPF = '';
				break;
			}

			case Types.NOTIFICATION_USUARIO_VIVA_LEITE_CLEAR: {
				draft.statusTransacaoUsuario = '';
				break;
			}

			default:
		}
	});
}
