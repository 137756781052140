import produce from 'immer';

// UTILS
import { toSelect } from 'utils/genericFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, EventosEspeciais } from './types';

export const INITIAL_STATE: EventosEspeciais = {
	status: 0,
	data: [],
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): EventosEspeciais {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_EVENTOS_ESPECIAIS_REQUEST: {
				break;
			}
			case Types.CONSULTA_EVENTOS_ESPECIAIS_SUCCESS: {
				draft.status = action.payload.status;
				const select = toSelect(action.payload.data, 'nome', 'id');
				draft.data = select;
				break;
			}
			case Types.CONSULTA_EVENTOS_ESPECIAIS_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CONSULTA_EVENTOS_ESPECIAIS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
