import { all } from 'redux-saga/effects';

import alterarObservacoes from './alterarObservacoes/saga';
import consultaReimpressaoFic from './consultaReimpressaoFic/saga';
import cadastroRg from './cadastroRg/saga';
import consultaPesquisaCidadao from './consultaPesquisaCidadao/saga';
import consultaPesquisaCirg from './consultaPesquisaCirg/saga';
import consultaPesquisaCriminal from './consultaPesquisaCriminal/saga';
import consultaPesquisaObservacao from './consultaPesquisaObservacao/saga';
import enviarSms from './enviarSms/saga';
import protocoloFic from './protocoloFic/saga';
import protocoloFicDigital from './protocoloFicDigital/saga';
import protocoloReuso from './protocoloReuso/saga';
import contabilizacaoIirgd from './contabilizacaoIirgd/saga';
import contabilizacaoEmissaoCarteira from './contabilizacaoEmissaoCarteira/saga';
import autorizacaoEspecial from './autorizacaoEspecial/combineSaga';
import consultaPesquisaCivil from './consultaPesquisaCivil/saga';
import logon from './logon/saga';
import alteraSenha from './alteraSenha/saga';
import pesquisaCicp from './pesquisaCicp/saga';
import consultaCIN from './consultaCIN/saga';
import ciliSimplificada from './ciliSimplificada/saga';
import consultaDBCIN from './consultaDBCIN/saga';

import integrador from './integrador/combineSaga';
import liberacaoCili from './liberacaoCili/saga';

export default all([
	alterarObservacoes,
	cadastroRg,
	consultaPesquisaCidadao,
	consultaPesquisaCirg,
	consultaPesquisaCriminal,
	consultaPesquisaObservacao,
	consultaReimpressaoFic,
	enviarSms,
	protocoloFic,
	protocoloFicDigital,
	protocoloReuso,
	autorizacaoEspecial,
	contabilizacaoIirgd,
	contabilizacaoEmissaoCarteira,
	consultaPesquisaCivil,
	logon,
	alteraSenha,
	pesquisaCicp,
	consultaCIN,
	ciliSimplificada,
	consultaDBCIN,
	integrador,
	liberacaoCili,
]);
