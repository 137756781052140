import AtendimentoCip from './atendimentoCip/types';
import Interacoes from './interacoes/types';
import Consumidores from './consumidores/types';
import Fornecedores from './fornecedores/types';
import DominiosCrm from './dominiosCrm/types';
import Dominios from './dominios/types';
import Atendimentos from './atendimentos/types';
import Areas from './areas/types';
import GrupoDeProblemas from './grupoDeProblemas/types';
import EnumEventosEspeciais from './eventosEspeciais/types';
import AtendimentosDeConsulta from './atendimentosDeConsulta/types';
import AnexosConsumidor from './anexosConsumidor/types';
import DocumentosProduzidos from './documentosProduzidos/types';
import ProcessosAdministrativos from './processosAdministrativos/types';

export enum Types {
	PROCON_CLEAR = '@procon/PROCON_CLEAR',
}

export interface ProconState {
	atendimentoCip: AtendimentoCip;
	interacoes: Interacoes;
	consumidores: Consumidores;
	fornecedores: Fornecedores;
	dominiosCrm: DominiosCrm;
	dominios: Dominios;
	atendimentos: Atendimentos;
	areas: Areas;
	grupoDeProblemas: GrupoDeProblemas;
	eventosEspeciais: EnumEventosEspeciais;
	atendimentosDeConsulta: AtendimentosDeConsulta;
	anexosConsumidor: AnexosConsumidor;
	documentosProduzidos: DocumentosProduzidos;
	processosAdministrativos: ProcessosAdministrativos;
}
