import React, { useEffect } from 'react';

import { Modal } from 'antd';

interface Props {
	showModal: boolean;
	onOk: () => void;
	onCancel: () => void;
}

const SolicitarInscricaoCPF: React.FC<Props> = ({
	showModal = false,
	onOk,
	onCancel,
}) => {
	// Manipula a visibilidade do Modal.
	useEffect(() => {
		if (showModal === true) {
			Modal.confirm({
				title: 'CPF não foi preenchido',
				content: (
					<>
						<div>Caso queira acrescentar clique em CPF.</div>
						<div>Caso contrário clique em OK.</div>
					</>
				),
				onOk() {
					onOk();
				},
				onCancel() {
					onCancel();
				},
				cancelText: 'CPF',
				okText: 'OK',
				style: {
					animationDuration: '0s',
				},
			});
		}
	}, [showModal, onOk, onCancel]);

	return <></>;
};

export default SolicitarInscricaoCPF;
