import React, { Suspense, useCallback, useContext, useEffect } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import InputMask from 'components/Common/Form/Input/InputMask';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Placa from 'components/Common/Form/Fields/Placa';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

// REDUX
import { cancelarIntencaoVendaRequest } from 'store/modules/api/detranCrv/IntencaoDeVenda/Cancelar/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';

// TYPES
import { CancelarIntencaoVendaRequest } from 'store/modules/api/detranCrv/IntencaoDeVenda/Cancelar/types';

// ROUTES
import { ROUTE_DETRAN_CRV } from 'routes/paths';

// FORM
import { initialValues, schema, treatValues } from './form';

const CancelarIntencaoVenda: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);

	const { cancelarIntencaoVenda } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.intencaoVenda,
	);

	const handleClear = useCallback(
		(formik: FormikProps<CancelarIntencaoVendaRequest>) => {
			dispatch(clearNotifications());
			formik.handleReset();
		},
		[dispatch],
	);

	useEffect(() => {
		if (cancelarIntencaoVenda.status === 200) {
			history.push('/detran-crv/cancelar-intencao-venda/resultado');
		}
	}, [cancelarIntencaoVenda, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Cancelar ATPV-e">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						const formatValues = treatValues(values);

						dispatch(cancelarIntencaoVendaRequest(token, formatValues));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Placa
											title="Placa"
											titleSize="lg"
											name="placa"
											defaultValue={initialValues.placa}
											size={80}
											required
											formik={formik}
										/>
									</Col>
									<Col span={12}>
										<Field
											titleSize="lg"
											required
											name="renavam"
											title="Renavam"
											mask="99999999999"
											as={InputMask}
											maskChar=""
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue('renavam', v.target.value);
											}}
											error={hasError(formik.errors, 'renavam')}
										/>
									</Col>
								</Row>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={12}>
										<Field
											titleSize="lg"
											required
											name="chassi"
											title="Chassi"
											as={InputMask}
											mask="**********************"
											maskChar=""
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue('chassi', v.target.value);
											}}
											error={hasError(formik.errors, 'chassi')}
										/>
									</Col>
									<Col span={12}>
										<Field
											titleSize="lg"
											required
											name="numeroATPVE"
											title="Número ATPV-e"
											as={InputMask}
											mask="999999999999999"
											maskChar=""
											onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
												formik.setFieldValue('numeroATPVE', v.target.value);
											}}
											error={hasError(formik.errors, 'numeroATPVE')}
										/>
									</Col>
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="btn-limpar-check"
											onClick={() => handleClear(formik)}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="btn-enviar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</Suspense>
	);
};

export default CancelarIntencaoVenda;
