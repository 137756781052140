import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_STATUS = '@SGU/CONSULTAR_STATUS',
	CONSULTAR_STATUS_SUCCESS = '@SGU/CONSULTAR_STATUS_SUCCESS',
	CONSULTAR_STATUS_FAILURE = '@SGU/CONSULTAR_STATUS_FAILURE',
	CONSULTAR_STATUS_CLEAR = '@SGU/CONSULTAR_STATUS_CLEAR',
}

export interface ConsultarStatus {
	status: number;
	data: null | ConsultarStatusData[];
	totalRegistrosConsulta: number;
	enum: OptionProps[];
}

export interface ConsultarStatusData {
	codigo: number;
	descricao: string;
}

export interface ConsultarStatusRequest {
	registro_inicial?: number;
	limite?: number;
	current?: number;
	descricao?: string;
}
