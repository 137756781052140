export default {
	tipoLogradouro: [
		{
			value: 'ALAMEDA',
			label: 'ALAMEDA',
		},
		{
			value: 'ÁREA',
			label: 'ÁREA',
		},
		{
			value: 'AVENIDA',
			label: 'AVENIDA',
		},
		{
			value: 'CAMPO',
			label: 'CAMPO',
		},
		{
			value: 'CHÁCARA',
			label: 'CHÁCARA',
		},
		{
			value: 'COLÔNIA',
			label: 'COLÔNIA',
		},
		{
			value: 'CONDOMÍNIO',
			label: 'CONDOMÍNIO',
		},
		{
			value: 'CONJUNTO',
			label: 'CONJUNTO',
		},
		{
			value: 'DISTRITO',
			label: 'DISTRITO',
		},
		{
			value: 'ESPLANADA',
			label: 'ESPLANADA',
		},
		{
			value: 'ESTAÇÃO',
			label: 'ESTAÇÃO',
		},
		{
			value: 'ESTRADA',
			label: 'ESTRADA',
		},
		{
			value: 'FAVELA',
			label: 'FAVELA',
		},
		{
			value: 'FAZENDA',
			label: 'FAZENDA',
		},
		{
			value: 'FEIRA',
			label: 'FEIRA',
		},
		{
			value: 'JARDIM',
			label: 'JARDIM',
		},
		{
			value: 'LADEIRA',
			label: 'LADEIRA',
		},
		{
			value: 'LAGO',
			label: 'LAGO',
		},
		{
			value: 'LARGO',
			label: 'LARGO',
		},
		{
			value: 'LOTEAMENTO',
			label: 'LOTEAMENTO',
		},
		{
			value: 'MORRO',
			label: 'MORRO',
		},
		{
			value: 'NÚCLEO',
			label: 'NÚCLEO',
		},
		{
			value: 'PARQUE',
			label: 'PARQUE',
		},
		{
			value: 'PASSARELA',
			label: 'PASSARELA',
		},
		{
			value: 'PÁTIO',
			label: 'PÁTIO',
		},
		{
			value: 'PRAÇA',
			label: 'PRAÇA',
		},
		{
			value: 'QUADRA',
			label: 'QUADRA',
		},
		{
			value: 'RECANTO',
			label: 'RECANTO',
		},
		{
			value: 'RESIDENCIAL',
			label: 'RESIDENCIAL',
		},
		{
			value: 'RODOVIA',
			label: 'RODOVIA',
		},
		{
			value: 'RUA',
			label: 'RUA',
		},
		{
			value: 'SETOR',
			label: 'SETOR',
		},
		{
			value: 'SÍTIO',
			label: 'SÍTIO',
		},
		{
			value: 'TRAVESSA',
			label: 'TRAVESSA',
		},
		{
			value: 'TRECHO',
			label: 'TRECHO',
		},
		{
			value: 'TREVO',
			label: 'TREVO',
		},
		{
			value: 'VALE',
			label: 'VALE',
		},
		{
			value: 'VEREDA',
			label: 'VEREDA',
		},
		{
			value: 'VIA',
			label: 'VIA',
		},
		{
			value: 'VIADUTO',
			label: 'VIADUTO',
		},
		{
			value: 'VIELA',
			label: 'VIELA',
		},
		{
			value: 'VILA',
			label: 'VILA',
		},
	],

	tipoEndereco: [
		{
			value: '1',
			label: 'Assentamento',
		},
		{
			value: '2',
			label: 'Área Rural',
		},
		{
			value: '3',
			label: 'Aldeia',
		},
		{
			value: '4',
			label: 'Condomínio',
		},
		{
			value: '5',
			label: 'Comunidade/Cortiço/Favela',
		},
		{
			value: '6',
			label: 'Casa (imóvel único no endereço de fácil acesso)',
		},
	],

	tiposContato: [
		{
			value: 1,
			label: 'Telefone Residencial',
		},
		{
			value: 2,
			label: 'Telefone Comercial',
		},
		{
			value: 3,
			label: 'Celular',
		},
		{
			value: 4,
			label: 'E-mail Pessoal',
		},
		{
			value: 5,
			label: 'E-mail Comercial',
		},
	],
};
