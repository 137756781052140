import axios from 'axios';
import { store } from 'store';

// REDUX
import { setLoader } from 'store/modules/app/loader/actions';

// UTILS
import handleFailed from 'utils/getValidations';
import { clearNotificationsValidate } from 'store/modules/app/notifications/actions';
import { handleTempoInatividade } from './utils';

// SERVICES
import { authToken } from './_token';
import { PATH_SGU_SERVICE } from './_path';
import { ResponseError } from './_api';

const { REACT_APP_BASE_URL } = process.env;

const api = axios.create({
	baseURL: REACT_APP_BASE_URL,
});

api.interceptors.response.use(
	response => response,
	async error => {
		const config = error?.config;

		if (error?.response?.status === 401 && !config?.sent) {
			config.sent = true;
			const result = await authToken();
			config.headers = {
				...config.headers,
				Authorization: `${result}`,
			};

			return api(config);
		}

		return Promise.reject(error);
	},
);

export interface ApiResponse {
	headers?: any;
	status: number;
	data?: any;
	error?: boolean;
	message?: any;
}

export async function getApi(
	method: string,
	params?: object,
): Promise<ApiResponse> {
	setLoader(true);
	clearNotificationsValidate();
	const state = store.getState();

	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	const token: string | ResponseError = state.app.authToken.authorization || '';

	try {
		const response = await api.get(`/${PATH_SGU_SERVICE}/${method}`, {
			params,
			headers: {
				Authorization: `${token}`,
			},
		});

		return response;
	} catch (error) {
		const errorResponse = await handleFailed(error);

		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			setLoader(false);
		}, 1000);
	}
}

export async function postApi(
	method: string,
	body: any,
	semNotificacao?: boolean,
	withoutLoadingRequest?: boolean,
	clearNotifications = true,
): Promise<ApiResponse> {
	if (!withoutLoadingRequest) {
		setLoader(true);
	}
	const state = store.getState();

	if (clearNotifications) {
		clearNotificationsValidate();
	}

	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	const token: string | ResponseError = state.app.authToken.authorization || '';

	try {
		const response = await api.post(`/${PATH_SGU_SERVICE}/${method}`, body, {
			headers: {
				Authorization: token,
			},
		});

		return response;
	} catch (error) {
		const errorResponse = await handleFailed(error, !!semNotificacao);

		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			if (!withoutLoadingRequest) {
				setLoader(false);
			}
		}, 1000);
	}
}

export async function putApi(method: string, body: any): Promise<ApiResponse> {
	setLoader(true);
	clearNotificationsValidate();
	const state = store.getState();

	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	const token: string | ResponseError = state.app.authToken.authorization || '';

	try {
		const response = await api.put(`/${PATH_SGU_SERVICE}/${method}`, body, {
			headers: {
				Authorization: token,
			},
		});

		if (response.status === 200 && response.data.mensagem_erro) {
			const errorResponse = await handleFailed(response.data.mensagem_erro);
			return { status: 0, error: true, message: errorResponse };
		}

		return response;
	} catch (error) {
		const errorResponse = await handleFailed(error);

		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			setLoader(false);
		}, 1000);
	}
}

export async function deleteApi(method: string): Promise<ApiResponse> {
	setLoader(true);
	clearNotificationsValidate();
	const state = store.getState();

	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	const token: string | ResponseError = state.app.authToken.authorization || '';

	try {
		const response = await api.delete(`/${PATH_SGU_SERVICE}/${method}`, {
			headers: {
				Authorization: token,
			},
		});

		if (response.status === 200 && response.data.mensagem_erro) {
			const errorResponse = await handleFailed(response.data.mensagem_erro);
			return { status: 0, error: true, message: errorResponse };
		}

		return response;
	} catch (error) {
		const errorResponse = await handleFailed(error);

		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			setLoader(false);
		}, 1000);
	}
}

export default api;
