import React, { useEffect, useMemo } from 'react';

import { Row, Col } from 'antd';

// REDUX
import { useDispatch } from 'react-redux';
import { proconClear } from 'store/modules/api/procon/actions';

// COMPONENTS
import ServiceList from 'components/Common/ServiceList';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import TypographyCommon from 'components/Common/Typography';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_MENU_ORGAOS } from 'routes/paths';
import { showInside } from 'store/modules/app/notifications/actions';
import {
	ROUTE_PROCON_CADASTRAR_CONSULTA,
	ROUTE_PROCON_CADASTRAR_RECLAMACAO,
	ROUTE_PROCON_PESQUISA_ATENDIMENTO,
} from '../routes/paths';

const MenuProcon: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		showInside(false);
		dispatch(proconClear());
	}, [dispatch]);

	const services = useMemo(
		() => [
			{
				name: 'Cadastrar Consulta',
				path: ROUTE_PROCON_CADASTRAR_CONSULTA,
			},
			{
				name: 'Cadastrar Reclamação',
				path: ROUTE_PROCON_CADASTRAR_RECLAMACAO,
			},
			{
				name: 'Pesquisa Atendimento - Manifestação',
				path: ROUTE_PROCON_PESQUISA_ATENDIMENTO,
			},
		],
		[],
	);

	return (
		<Row gutter={[0, 0]}>
			<Col span={15}>
				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 0]} align="middle" justify="start">
					<Col className="gutter-row" span={24}>
						<TypographyCommon
							text="Escolha o Serviço"
							borderBottom
							type="h5"
							fontWeight="bold"
							uppercase
						/>
					</Col>
				</Row>

				<Row gutter={[0, 0]}>
					<Col span={24}>{services && <ServiceList data={services} />}</Col>
				</Row>

				<Row gutter={[0, 20]}>
					<Col span={24}>
						<ButtonVoltar
							navigate={false}
							onClick={() => {
								history.push(ROUTE_MENU_ORGAOS);
							}}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default MenuProcon;
