import produce from 'immer';
// import { formatDate, toSelect } from 'utils/genericFunctions';

import { Types, gerarRenach } from './types';
import { ReducerAction } from '../../../../types';

// UTILS

export const INITIAL_STATE: gerarRenach = {
	status: 20,
	data: null,
};

export default function gerarRenachReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): gerarRenach {
	return produce(state, draft => {
		switch (action.type) {
			case Types.GERAR_RENACH_REABILITACAO_CATEGORIA: {
				break;
			}
			case Types.GERAR_RENACH_REABILITACAO_CATEGORIA_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.response.data;
				break;
			}
			case Types.GERAR_RENACH_REABILITACAO_CATEGORIA_FAILURE: {
				break;
			}

			default:
		}
	});
}
