import React from 'react';
import { Field } from 'formik';
import { Row, Col } from 'antd';

// COMPONENTS
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';

// REDUX
// import { useDispatch } from 'react-redux';
// import { consultarTaxaServicoClear } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/actions';

// UTILS
import { completaZeroEsquerda, formatDate } from 'utils/genericFunctions';

interface TaxaServico {
	codigoTaxaServico?: string;
	cpfCnpj?: string;
	identificadorTaxa?: string;
	dataContabil?: string;
	codBanco?: string;
	nsuProdesp?: string;
}

interface IPropos {
	dataConsultarTaxaServico: TaxaServico;
}

const TaxaServico: React.FC<IPropos> = ({ dataConsultarTaxaServico }) => {
	// const dispatch = useDispatch();

	// useEffect(() => {
	// 	dispatch(consultarTaxaServicoClear());
	// }, [dispatch]);

	const { codBanco, dataContabil, nsuProdesp } = dataConsultarTaxaServico;

	return (
		<Section size="lg" title="Taxa / Autenticação" titleSize="sm">
			<Row justify="space-between">
				<Col span={7}>
					<Field
						as={Input}
						readOnly
						title="COD Banco"
						titleSize={130}
						name="codBanco"
						value={completaZeroEsquerda(codBanco || '', 3)}
					/>
				</Col>

				<Col span={8}>
					<Field
						as={ValidDataInput}
						readOnly
						title="Data Contabil"
						titleSize="sm"
						name="dataContabil"
						type="text"
						mask="99/99/9999"
						value={formatDate(dataContabil || '')}
					/>
				</Col>

				<Col span={7}>
					<Field
						as={Input}
						readOnly
						title="NSU Prodesp"
						titleSize={105}
						name="nsuProdesp"
						size={90}
						value={completaZeroEsquerda(nsuProdesp || '', 9)}
					/>
				</Col>
			</Row>
		</Section>
	);
};

export default TaxaServico;
