import { ReducerAction } from 'store/modules/types';

import { ConsultaPontosRequest, Types } from './types';

export function consultaPontosRequest(
	payload: ConsultaPontosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_PONTOS_REQUEST,
		payload,
	};
}
export function consultaPontosSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_PONTOS_SUCCESS,
		payload,
	};
}
export function consultaPontosFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_PONTOS_FAILURE,
		payload,
	};
}
export function consultaPontosClear(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_PONTOS_CLEAR,
		payload,
	};
}
