import { ReducerAction } from 'store/modules/types';

import { DomainServiceNaturalidadesRequest, Types } from './types';

export function domainServiceNaturalidadesRequest(
	data: DomainServiceNaturalidadesRequest,
): ReducerAction {
	return {
		type: Types.DOMAIN_SERVICE_NATURALIDADES_REQUEST,
		payload: data,
	};
}
export function domainServiceNaturalidadesSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.DOMAIN_SERVICE_NATURALIDADES_SUCCESS,
		payload,
	};
}
export function domainServiceNaturalidadesFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.DOMAIN_SERVICE_NATURALIDADES_FAILURE,
		payload,
	};
}
export function domainServiceNaturalidadesClear(): { type: string } {
	return {
		type: Types.DOMAIN_SERVICE_NATURALIDADES_CLEAR,
	};
}
