import { combineReducers } from 'redux';

import preCadastroCidadao from './preCadastroCidadao/reducer';
import domainService from './domainService/combineReducers';
import estatisticasIncluir from './estatisticasIncluir/reducer';
import alteraSenhaUsuario from './alteraSenhaUsuario/reducer';
import salvaSenhaUsuario from './salvaSenhaUsuario/reducer';

export default combineReducers({
	preCadastroCidadao,
	domainService,
	estatisticasIncluir,
	alteraSenhaUsuario,
	salvaSenhaUsuario,
});
