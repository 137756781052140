import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// REDUX
import {
	consultaMudancaCategoriaClear,
	consultaMudancaCategoriaRequest,
} from 'store/modules/api/detranCnh/eportal/consultaMudancaCategoria/actions';

import {
	consultaProcessoAbertoClear,
	consultaProcessoAbertoRequest,
} from 'store/modules/api/detranCnh/renachIntegrado/consultaProcessoAberto/actions';

import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import {
	relatorioAgendamentoMedicoPsicologicoClear,
	relatorioAgendamentoMedicoPsicologicoRequest,
} from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';
import { eventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/actions';

// COMPONENTS
import Pesquisa from 'pages/DetranCnh/components/pages/PreCadastro/Pesquisa';

// FORM
import {
	IFormPesquisaRenovacao,
	treatValues,
} from 'pages/DetranCnh/components/pages/PreCadastro/Pesquisa/form';

// UTILS
import { threatValuesStartSessionEventRequest } from 'utils/functions/threatEventsRequest';
import { treatAgendamentoMedicoPsicologicoRequestInPesquisar } from 'pages/DetranCnh/utils/threatServicesRequest';

// PATHS
import { ROUTE_DETRAN_CNH } from 'routes/paths';
import { CONSULTA_MUDANCA_ERROR_RESPONSE_SERVICE_MESSAGE } from 'pages/DetranCnh/utils/constants/serviceResponseMessages';
import { ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_STATUS_CONDUTOR } from 'pages/DetranCnh/routes/paths';

const PesquisaAdicaoMudancaCategoria: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { consultaMudancaCategoria } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.eportal,
	);

	const { mainframe, user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { consultaProcessoAberto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { relatorio } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.agendamentoMedicoPsicologico,
	);

	const handleClear = useCallback(() => {
		dispatch(clearNotifications());
		dispatch(consultaMudancaCategoriaClear());
		dispatch(consultaProcessoAbertoClear());
		dispatch(relatorioAgendamentoMedicoPsicologicoClear());
	}, [dispatch]);

	const handleSubmit = useCallback(
		(values: IFormPesquisaRenovacao) => {
			handleClear();

			const newValues = treatValues(values, {
				usuario: mainframe.idUsuario,
				senha: mainframe.senhaMainFrame,
			});
			const valuesStartSessionEvent = threatValuesStartSessionEventRequest(
				atendimento,
				loginUnico,
			);
			dispatch(eventoInicioSessaoRequest(valuesStartSessionEvent));
			dispatch(consultaMudancaCategoriaRequest(newValues));
		},
		[
			atendimento,
			dispatch,
			handleClear,
			loginUnico,
			mainframe.idUsuario,
			mainframe.senhaMainFrame,
		],
	);

	// CONSULTA PROCESSO ABERTO
	useEffect(() => {
		if (
			(consultaMudancaCategoria.status === 200 &&
				consultaMudancaCategoria.data?.cpf &&
				consultaProcessoAberto.status === 0) ||
			(consultaMudancaCategoria.status === 400 &&
				consultaMudancaCategoria.dataError?.mensagem.trim().toLowerCase() ===
					CONSULTA_MUDANCA_ERROR_RESPONSE_SERVICE_MESSAGE.CONDUTOR_PROCESSO_ABERTO.toLowerCase() &&
				consultaMudancaCategoria.requestData?.cpf &&
				consultaProcessoAberto.status === 0)
		) {
			dispatch(
				consultaProcessoAbertoRequest({
					cpf:
						consultaMudancaCategoria.data?.cpf ||
						consultaMudancaCategoria.requestData?.cpf ||
						'',
					ident: 0,
				}),
			);
		}
	}, [
		consultaMudancaCategoria.data,
		consultaMudancaCategoria.dataError,
		consultaMudancaCategoria.requestData,
		consultaMudancaCategoria.status,
		consultaProcessoAberto.status,
		dispatch,
		user.cpf,
	]);

	// NOTIFICA CASO O RETORNO DA CONSULTA NÃO SATISFAÇA A REGRA DE NEGÓCIO
	useEffect(() => {
		if (
			(relatorio.data || !relatorio.data) &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			consultaProcessoAberto.data?.message
		) {
			addNotifications({
				errors: [consultaProcessoAberto.data.message],
			});
		}
	}, [consultaProcessoAberto.data, dispatch, relatorio.data]);

	// IMPRESSÃO DE RELATÓRIOS
	useEffect(() => {
		if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			(consultaProcessoAberto.data.dataExame ||
				consultaProcessoAberto.data.dataExamePsicologo)
		) {
			const requestData = treatAgendamentoMedicoPsicologicoRequestInPesquisar(
				consultaProcessoAberto.data,
			);

			dispatch(relatorioAgendamentoMedicoPsicologicoRequest(requestData));
		} else if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) === 0
		) {
			history.push(ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_STATUS_CONDUTOR);
		}
	}, [
		consultaProcessoAberto.data,
		consultaProcessoAberto.status,
		dispatch,
		history,
	]);

	useEffect(() => {
		handleClear();
	}, [handleClear]);

	return (
		<Pesquisa
			handleSubmit={handleSubmit}
			handleClear={handleClear}
			relatorio={relatorio}
			routeBackButton={ROUTE_DETRAN_CNH}
		/>
	);
};
export default PesquisaAdicaoMudancaCategoria;
