import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_EDUCACAO_SEDUC_DOMINIOS_API } from 'services/rotasp/path';
import { ReducerActionRotaSP } from 'store/modules/types';
import { ComboMunicipiosSPRequestData, Types } from './types';
import { comboMunicipiosSPSuccess, comboMunicipiosSPFailure } from './actions';

function* ComboMunicipiosSPRequest(request: ReducerActionRotaSP) {
	const { token }: { token?: string } = request;
	const payload: ComboMunicipiosSPRequestData = {
		uf: 'SP',
	};

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_EDUCACAO_SEDUC_DOMINIOS_API,
		'municipios',
		payload,
	);
	if (response.status === 200) {
		yield put(comboMunicipiosSPSuccess(response));
	} else {
		yield put(comboMunicipiosSPFailure(response));
	}
}

export default all([
	takeLatest(Types.COMBO_MUNICIPIOS_SP_REQUEST, ComboMunicipiosSPRequest),
]);
