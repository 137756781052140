import { consultarBloqueioDetranRenajud } from './ConsultarRestricoes/consultarBloqueioDetranRenajud/types';
import { consultarRestricaoVeiculo } from './ConsultarRestricoes/consultarRestricaoVeiculo/types';
import { consultarTaxaServico } from './ConsultarRestricoes/consultarTaxaServico/types';
import { saveTaxaServico } from './ConsultarRestricoes/saveTaxaServico/types';
import { consultarBaseOutrosEstados } from './ConsultarVeiculos/consultarBaseOutrosEstados/types';
import { consultarCadastroBinRenavam } from './ConsultarVeiculos/consultarCadastroBinRenavam/types';
import { consultarNumeroMotorBin } from './ConsultarVeiculos/ConsultarNumeroMotorBin/types';
import { consultarVeiculoBaseEstadual } from './ConsultarVeiculos/consultarVeiculoBaseEstadual/types';
import { consultaCarroceria } from './enum/consultaCarroceria/types';
import { consultaCategoria } from './enum/consultaCategoria/types';
import { consultaCombustivel } from './enum/consultaCombustivel/types';
import { consultaCor } from './enum/consultaCor/types';
import { consultaEspecie } from './enum/consultaEspecie/types';
import { consultarMunicipioPorUF } from './enum/consultarMunicipioPorUF/types';
import { consultaTipo } from './enum/consultaTipo/types';
import { consultarTipoModificacaoAlteracao } from './enum/consultarTipoModificacaoAlteracao/types';
import { multasRenainfOrgao } from './MultasRenainf/multasRenainfOrgao/types';
import { multasRenainfPlaca } from './MultasRenainf/multasRenainfPlaca/types';
import { andamentoProcesso } from './Processos/AndamentoProcesso/types';
import { consultarFichaCadastral } from './Processos/ConsultarFichaCadastral/types';
import { excluirFichaCadastral } from './Processos/ExcluirFichaCadastral/types';
import { impressaoAlteracaoEndereco } from './Processos/ImpressaoAlteracaoEndereco/types';
import { impressaoComunicacaoVenda } from './Processos/ImpressaoComunicacaoVenda/types';
import { impressaoLicenciamentoVeiculos } from './Processos/ImpressaoLicenciamentoVeiculos/types';
import { impressaoOficioSegundaViaPlaca } from './Processos/ImpressaoOficioSegundaViaPlaca/types';
import { impressaoRenavam } from './Processos/ImpressaoRenavam/types';
import { impressaoSegundaViaLicenciamento } from './Processos/ImpressaoSegundaViaLicenciamento/types';
import { impressaoTrocaVoluntariaPlaca } from './Processos/ImpressaoTrocaVoluntariaPlaca/types';
import { cancelarAutorizacaoPrevia } from './SolicitarDocumentos/CancelarAutorizacaoPrevia/types';
import { confirmarPlaca } from './SolicitarDocumentos/confirmarPlaca/types';
import { consultaPlacaDisponivel } from './SolicitarDocumentos/consultaPlacaDisponivel/types';
import { consultarAutorizacaoPrevia } from './SolicitarDocumentos/ConsultarAutorizacaoPrevia/types';
import { consultarDetalhesAutorizacaoPrevia } from './SolicitarDocumentos/consultarDetalhesAutorizacaoPrevia/types';
import { consultarVeiculoV110 } from './SolicitarDocumentos/ConsultarVeiculoV110/types';
import { consultarVeiculoV120 } from './SolicitarDocumentos/ConsultarVeiculoV120/types';
import { consultaUnidadeTransito } from './SolicitarDocumentos/consultaUnidadeTransito/types';
import { emitirSegundaViaCrv } from './SolicitarDocumentos/emitirSegundaViaCRV/types';
import { escolherPlaca } from './SolicitarDocumentos/EscolherPlaca/types';
import { imprimirCrvle } from './SolicitarDocumentos/imprimirCrvle/types';
import { licenciamentoVeiculo } from './SolicitarDocumentos/LicenciamentoVeiculo/types';
import { primeiroEmplacamento } from './SolicitarDocumentos/PrimeiroEmplacamento/types';
import { primeiroEmplacamentoEmissao } from './SolicitarDocumentos/primeiroEmplacamentoEmissao/types';
import { transferenciaPropietario } from './SolicitarDocumentos/TransferenciaPropietario/types';
import { emitirAutorizacaoPrevia } from './SolicitarDocumentos/emitirAutorizacaoPrevia/types';
import { consultaMarcaModelo } from './enum/consultaMarcaModelo/types';
import { emitirBaixaVeiculo } from './SolicitarDocumentos/emitirBaixaVeiculo/types';
import { emitirAlteracaoDadosCadastrais } from './SolicitarDocumentos/emitirAlteracaoDadosCadastrais/types';
import { emitirModificacaoDadosCadastrais } from './SolicitarDocumentos/emitirModificacaoDadosCadastrais/types';
import { emitirTransferenciasMunicipiosEstados } from './SolicitarDocumentos/emitirTransferenciasMunicipiosEstados/types';
import { emitirTransferenciasProprietarios } from './SolicitarDocumentos/emitirTransferenciasProprietarios/types';
import { emitirTransferenciaMunicipio } from './SolicitarDocumentos/emitirTransferenciaMunicipio/types';
import { emitirTrocaVoluntariaPlaca } from './SolicitarDocumentos/emitirTrocaVoluntariaPlaca/types';
import { emitirEscolhaPlacaPersonalizada } from './SolicitarDocumentos/emitirEscolhaPlacaPersonalizada/types';
import { emitirSegundaViaPlaca } from './SolicitarDocumentos/emitirSegundaViaPlaca/types';
import { consultarVeiculoSegundaViaPlaca } from './SolicitarDocumentos/consultarVeiculoSegundaViaPlaca/types';
import { consultarUnidadesTransito } from './enum/consultaUnidadeTransito/types';
import { consultarMunicipioCodigoProdesp } from './enum/consultarMunicipioCodigoProdesp/types';
import { ImpressaoIntencaoVenda } from './IntencaoDeVenda/Imprimir/types';
import { CancelarIntencaoVenda } from './IntencaoDeVenda/Cancelar/types';
import { IncluirIntencaoVenda } from './IntencaoDeVenda/Incluir/types';
import { CancelarPlacaPrimeiroEmplacamento } from './SolicitarDocumentos/cancelarPlacaPrimeiroEmplacamento/types';
import { ConsultarIntencaoVenda } from './IntencaoDeVenda/Consultar/types';
import { tipoArquivo } from './SolicitarDocumentos/tipoArquivoEmissao/types';
import { ConsultarAcompanhamento } from './SolicitarDocumentos/ConsultarAcompanhamento/types';
import { DownloadDocumentos } from './SolicitarDocumentos/DownloadDocumentos/types';
import { ReenviarDocumentos } from './SolicitarDocumentos/reenviarDocumentos/types';
import { ConsultaRestricoes } from './SolicitarDocumentos/desbloqueio/consultaRestricoes/types';
import { RegistraSolicitacoes } from './SolicitarDocumentos/desbloqueio/registraSolicitacoes/types';
import { PEPM } from './ConsultarResumoExtrato/pepm/types';
import { PGAT } from './ConsultarResumoExtrato/pgat/types';
import { PCOV } from './ConsultarResumoExtrato/pcov/types';
import { TXUT } from './ConsultarResumoExtrato/txut/types';
import { PTRES } from './ConsultarResumoExtrato/ptres/types';
import { PTREOE } from './ConsultarResumoExtrato/ptreoe/types';
import { PEBE } from './ConsultarResumoExtrato/pebe/types';

export enum Types {
	DETRAN_CRV_CLEAR = '@DETRAN-CRV/DETRAN_CRV_CLEAR',
}
export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	ip: string;
	cpf: string;
	identificacao: string;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}
export interface DetranCrvState {
	consultarVeiculos: {
		consultarVeiculoBaseEstadual: consultarVeiculoBaseEstadual;
		consultarCadastroBinRenavam: consultarCadastroBinRenavam;
		consultarBaseOutrosEstados: consultarBaseOutrosEstados;
		consultarNumeroMotorBin: consultarNumeroMotorBin;
	};
	consultarRestricoes: {
		consultarBloqueioDetranRenajud: consultarBloqueioDetranRenajud;
		consultarRestricaoVeiculo: consultarRestricaoVeiculo;
		consultarTaxaServico: consultarTaxaServico;
		saveTaxaServico: saveTaxaServico;
	};
	consultarResumoExtrato: {
		pepm: PEPM;
		pgat: PGAT;
		pcov: PCOV;
		txut: TXUT;
		ptres: PTRES;
		ptreoe: PTREOE;
		pebe: PEBE;
	};
	Enum: {
		consultarMunicipioPorUF: consultarMunicipioPorUF;
		consultaCarroceria: consultaCarroceria;
		consultaTipo: consultaTipo;
		consultaCategoria: consultaCategoria;
		consultaCor: consultaCor;
		consultaCombustivel: consultaCombustivel;
		consultaEspecie: consultaEspecie;
		consultarTipoModificacaoAlteracao: consultarTipoModificacaoAlteracao;
		consultaMarcaModelo: consultaMarcaModelo;
		consultarUnidadesTransito: consultarUnidadesTransito;
		consultarMunicipioCodigoProdesp: consultarMunicipioCodigoProdesp;
	};
	multasRenainf: {
		multasRenainfOrgao: multasRenainfOrgao;
		multasRenainfPlaca: multasRenainfPlaca;
	};
	processos: {
		consultarFichaCadastral: consultarFichaCadastral;
		excluirFichaCadastral: excluirFichaCadastral;
		andamentoProcesso: andamentoProcesso;
		impressaoRenavam: impressaoRenavam;
		impressaoLicenciamentoVeiculos: impressaoLicenciamentoVeiculos;
		impressaoAlteracaoEndereco: impressaoAlteracaoEndereco;
		impressaoSegundaViaLicenciamento: impressaoSegundaViaLicenciamento;
		impressaoComunicacaoVenda: impressaoComunicacaoVenda;
		impressaoOficioSegundaViaPlaca: impressaoOficioSegundaViaPlaca;
		impressaoTrocaVoluntariaPlaca: impressaoTrocaVoluntariaPlaca;
	};
	solicitarDocumentos: {
		escolherPlaca: escolherPlaca;
		primeiroEmplacamento: primeiroEmplacamento;
		licenciamentoVeiculo: licenciamentoVeiculo;
		transferenciaPropietario: transferenciaPropietario;
		cancelarAutorizacaoPrevia: cancelarAutorizacaoPrevia;
		consultarAutorizacaoPrevia: consultarAutorizacaoPrevia;
		consultarDetalhesAutorizacaoPrevia: consultarDetalhesAutorizacaoPrevia;
		consultarVeiculoV110: consultarVeiculoV110;
		consultaPlacaDisponivel: consultaPlacaDisponivel;
		consultaUnidadeTransito: consultaUnidadeTransito;
		confirmarPlaca: confirmarPlaca;
		imprimirCrvle: imprimirCrvle;
		emitirAutorizacaoPrevia: emitirAutorizacaoPrevia;
		consultarVeiculoV120: consultarVeiculoV120;
		primeiroEmplacamentoEmissao: primeiroEmplacamentoEmissao;
		emitirSegundaViaCrv: emitirSegundaViaCrv;
		emitirBaixaVeiculo: emitirBaixaVeiculo;
		emitirAlteracaoDadosCadastrais: emitirAlteracaoDadosCadastrais;
		emitirModificacaoDadosCadastrais: emitirModificacaoDadosCadastrais;
		emitirTransferenciasMunicipiosEstados: emitirTransferenciasMunicipiosEstados;
		emitirTransferenciasProprietarios: emitirTransferenciasProprietarios;
		emitirTransferenciaMunicipio: emitirTransferenciaMunicipio;
		emitirTrocaVoluntariaPlaca: emitirTrocaVoluntariaPlaca;
		emitirEscolhaPlacaPersonalizada: emitirEscolhaPlacaPersonalizada;
		emitirSegundaViaPlaca: emitirSegundaViaPlaca;
		consultarVeiculoSegundaViaPlaca: consultarVeiculoSegundaViaPlaca;
		cancelarPlacaPrimeiroEmplacamento: CancelarPlacaPrimeiroEmplacamento;
		tipoArquivoEmissao: tipoArquivo;
		consultarAcompanhamento: ConsultarAcompanhamento;
		downloadDocumentos: DownloadDocumentos;
		reenviarDocumentos: ReenviarDocumentos;
		consultaRestricoes: ConsultaRestricoes;
		registraSolicitacoes: RegistraSolicitacoes;
	};
	intencaoVenda: {
		imprimirIntencaoVenda: ImpressaoIntencaoVenda;
		cancelarIntencaoVenda: CancelarIntencaoVenda;
		incluirIntencaoVenda: IncluirIntencaoVenda;
		consultarIntencaoVenda: ConsultarIntencaoVenda;
	};
}
