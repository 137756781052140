export enum Types {
	BUSCA_PAGINADA_REQUEST = '@procon/BUSCA_PAGINADA_REQUEST',
	BUSCA_PAGINADA_SUCCESS = '@procon/BUSCA_PAGINADA_SUCCESS',
	BUSCA_PAGINADA_FAILURE = '@procon/BUSCA_PAGINADA_FAILURE',
	BUSCA_PAGINADA_CLEAR = '@procon/BUSCA_PAGINADA_CLEAR',
}

export interface BuscaPaginada {
	status: null | number;
	data: null | BuscaPaginadaData;
}

interface BuscaPaginadaData {
	total: number;
	paginaAtual: number;
	qtdItensPorPagina: number;
	data: Consumidor[];
}

export interface Consumidor {
	idConsumidor: number;
	cpf: string;
	cpfFormatado: string;
	nome: string;
	dataNascimento: string;
	dataNascimentoFormatada: string;
	podeEditar: boolean;
	caracteristicas: {
		destaque: boolean;
	};
}

export interface BuscaPaginadaRequest {
	nome?: string;
	inicio: number;
	qtdItensPorPagina: number;
}
