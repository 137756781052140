// TYPES
import { ReducerAction } from 'store/modules/types';
import { ProblemasRequest, Types } from './types';

export function problemasRequest(payload: ProblemasRequest): ReducerAction {
	return {
		type: Types.PROBLEMAS_REQUEST,
		payload,
	};
}
export function problemasSuccess(payload: object): ReducerAction {
	return {
		type: Types.PROBLEMAS_SUCCESS,
		payload,
	};
}
export function problemasFailure(payload: object): ReducerAction {
	return {
		type: Types.PROBLEMAS_FAILURE,
		payload,
	};
}
export function problemasClear(): ReducerAction {
	return {
		type: Types.PROBLEMAS_CLEAR,
		payload: null,
	};
}
