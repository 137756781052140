export enum Types {
	ESCOLHER_PLACA_REQUEST = '@DETRAN-CRV/ESCOLHER_PLACA_REQUEST',
	ESCOLHER_PLACA_SUCCESS = '@DETRAN-CRV/ESCOLHER_PLACA_SUCCESS',
	ESCOLHER_PLACA_FAILURE = '@DETRAN-CRV/ESCOLHER_PLACA_FAILURE',
	ESCOLHER_PLACA_CLEAR = '@DETRAN-CRV/ESCOLHER_PLACA_CLEAR',
	CONFIRMAR_PLACA_ESCOLHIDA = '@DETRAN-CRV/CONFIRMAR_PLACA_ESCOLHIDA',
}

export interface escolherPlaca {
	status: number;
	data: placa | null;
	placaEscolhida?: string;
}

export interface placa {
	resultadoEscolhaPlacaAtribuida: {
		chassi: string;
		cpfCnpjProprietario: number;
		placa: string;
		possuiPlaca: boolean;
		quantidadeAlteracoesRestante: number;
	};
}
