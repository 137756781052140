import { combineReducers } from 'redux';

import atualizarMenus from './atualizarMenus/reducer';
import cadastrarMenus from './cadastrarMenus/reducer';
import consultarMenus from './consultarMenus/reducer';
import excluirMenus from './excluirMenus/reducer';
import consultarMenu from './consultarMenu/reducer';

export default combineReducers({
	atualizarMenus,
	cadastrarMenus,
	consultarMenus,
	excluirMenus,
	consultarMenu,
});
