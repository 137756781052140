import { limparMascara } from 'utils/genericFunctions';
import { razaoDoStatusEnum, situacaoAtualTnfEnum } from '../../constants/enum';

export const verifyIsAdministrativeProcess = (razaoDoStatus: string) => {
	const status =
		(razaoDoStatus && limparMascara(razaoDoStatus.trim().toLowerCase())) || '';

	if (
		status ===
		limparMascara(razaoDoStatusEnum.PROCESSO_ADMINISTRATIVO.toLowerCase())
	) {
		return true;
	}
	return false;
};

export const verifyRazaoStatusIsAwaitingConsumerResponse = (
	razaoDoStatus: string,
) => {
	const status =
		(razaoDoStatus && limparMascara(razaoDoStatus.trim().toLowerCase())) || '';

	if (
		status ===
		limparMascara(
			razaoDoStatusEnum.ARGUARDANDO_RESPOSTA_DO_CONSUMIDOR.toLowerCase(),
		)
	) {
		return true;
	}
	return false;
};

export const verifyRazaoStatusIsAwaitingProviderResponse = (
	razaoDoStatus: string,
) => {
	const status =
		(razaoDoStatus && limparMascara(razaoDoStatus.trim().toLowerCase())) || '';

	if (
		status ===
		limparMascara(
			razaoDoStatusEnum.ARGUARDANDO_RESPOSTA_DO_FORNECEDOR.toLowerCase(),
		)
	) {
		return true;
	}
	return false;
};

export const verifySituationIsTnfAwaitingConsumerResponse = (
	situacao: string,
) => {
	const situation =
		(situacao && limparMascara(situacao.trim().toLowerCase())) || '';

	if (
		situation ===
		limparMascara(
			situacaoAtualTnfEnum.TNF_AGUARDANDO_RESPOSTA_DO_CONSUMIDOR.toLowerCase(),
		)
	) {
		return true;
	}

	return false;
};

export const verifySituationIsTnfAwaitingProviderResponse = (
	situacao: string,
) => {
	const situation =
		(situacao && limparMascara(situacao.trim().toLowerCase())) || '';

	if (
		situation ===
		limparMascara(
			situacaoAtualTnfEnum.TNF_AGUARDANDO_RESPOSTA_DO_FORNECEDOR.toLowerCase(),
		)
	) {
		return true;
	}

	return false;
};
