export enum Types {
	EMITIR_BAIXA_VEICULO_REQUEST = '@DETRAN-CRV/EMITIR_BAIXA_VEICULO_REQUEST',
	EMITIR_BAIXA_VEICULO_SUCCESS = '@DETRAN-CRV/EMITIR_BAIXA_VEICULO_SUCCESS',
	EMITIR_BAIXA_VEICULO_FAILURE = '@DETRAN-CRV/EMITIR_BAIXA_VEICULO_FAILURE',
	EMITIR_BAIXA_VEICULO_CLEAR = '@DETRAN-CRV/EMITIR_BAIXA_VEICULO_CLEAR',
}

export interface emitirBaixaVeiculo {
	data: IResponseEmitirBaixaVeiculo | null;
	status: number;
}

export interface IResponseEmitirBaixaVeiculo {
	anoFicha: number;
	chassi: string;
	numeroficha: number;
	placa: string;
	planilhaRenavam: string;
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	ip: string;
	cpf: string;
	identificacao: string;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export type IProcedencia = 'IMPORTADA' | 'NACIONAL' | '';

export interface IRequestEmitirBaixaVeiculo {
	evento: IEvento;
	anoModelo: number;
	capacidadeCarga: number | string;
	capacidadePassageiro: number;
	carroceria: {
		longId: number;
		descricao: string;
	};
	categoria: {
		descricao: string;
		longId: number;
	};
	chassi: string;
	chassiRemarcado: boolean;
	cilindrada: number;
	cmt: number;
	combustivel: {
		longId: number;
		descricao: string;
	};
	cor: {
		longId: number;
		descricao: string;
	};
	eixo: number;
	especie: {
		longId: number;
		descricao: string;
	};
	marca: {
		longId: number;
		descricao: string;
	};
	municipio: {
		codigo: number;
		nome: string;
	};
	numeroEspelho: number | string;
	pbt: number;
	placa: string;
	potencia: number;
	procedencia: IProcedencia;
	proprietario:
		| IProprietarioFisicoEmitirBaixaVeiculo
		| IProprietarioJuridicoEmitirBaixaVeiculo;
	protocoloRemarcacao: number | string;
	tipo: {
		longId: number;
		descricao: string;
	};
}

export interface IProprietarioFisicoEmitirBaixaVeiculo {
	bairro: string;
	cepProprietario: number;
	complemento: string;
	enderecoProprietario: string;
	municipioProprietario: {
		codigo: number;
		nome: string;
	};
	nomeProprietario: string;
	numero: number;
	orgaoExpeditorProprietario: string;
	rgProprietario: string;
	telefone: number | string;
	ufProprietario: string;
}

export interface IProprietarioJuridicoEmitirBaixaVeiculo {
	bairro: string;
	cepProprietario: number;
	complemento: string;
	enderecoProprietario: string;
	municipioProprietario: {
		codigo: number;
		nome: string;
	};
	nomeProprietario: string;
	numero: number;
	telefone: number | string;
}
