import React, { useCallback, useEffect } from 'react';

import { Row, Col } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultarVeiculoSegundaViaPlacaRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/consultarVeiculoSegundaViaPlaca/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import Radio from 'components/Common/Form/Radio';
import Placa from 'components/Common/Form/Fields/Placa';
import Input from 'components/Common/Form/Input/Input';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { IConsultarSegundaViaPlaca, initialValues, schema } from './form';

const SegundaViaPlaca: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { consultarVeiculoSegundaViaPlaca } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	useEffect(() => {
		if (consultarVeiculoSegundaViaPlaca.data) {
			history.push('/detran-crv/segunda-via-placa/emitir');
		}
	}, [consultarVeiculoSegundaViaPlaca, history]);

	const handleValidate = useCallback(
		(formValues: IConsultarSegundaViaPlaca) => {
			return getValidationsErrors(formValues, schema);
		},
		[],
	);

	const handleSubmit = useCallback(
		(formValues: IConsultarSegundaViaPlaca) => {
			const { tipoPesquisa, chassi, placa } = formValues;

			if (tipoPesquisa === 'Chassi') {
				dispatch(
					consultarVeiculoSegundaViaPlacaRequest({
						chassi,
					}),
				);
				return;
			}

			dispatch(
				consultarVeiculoSegundaViaPlacaRequest({
					placa,
				}),
			);
		},
		[dispatch],
	);

	return (
		<>
			<Row>
				<Col span={24}>
					<Section title="Pesquisar">
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={handleValidate}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row>
										<Col span={11} offset={5}>
											<Row gutter={[0, 10]}>
												<Col style={{ marginLeft: '132px' }}>
													<Field
														as={Radio}
														name="tipoPesquisa"
														options={[
															{ label: 'Chassi', value: 'Chassi' },
															{ label: 'Placa', value: 'Placa' },
														]}
														defaultValue={initialValues.tipoPesquisa}
														onChange={(e: RadioChangeEvent) => {
															if (errors.length > 0) {
																dispatch(clearNotifications());
															}
															const { value } = e.target;

															formik.setFieldValue('tipoPesquisa', value);
															formik.setFieldValue('chassi', '');
															formik.setFieldValue('placa', '');
														}}
													/>
												</Col>
											</Row>

											<Row>
												<Col span={24}>
													{formik.values.tipoPesquisa === 'Chassi' ? (
														<Field
															as={Input}
															title="Documento"
															name="chassi"
															maxLength={22}
															size={96}
															required
															error={!!formik.errors.chassi}
															onChange={(
																e: React.ChangeEvent<HTMLInputElement>,
															) => {
																if (errors.length > 0) {
																	dispatch(clearNotifications());
																}
																const { value } = e.target;

																formik.setFieldValue('chassi', value);
															}}
														/>
													) : (
														<Placa
															title="Documento"
															name="placa"
															size={96}
															required
															formik={formik}
															onChange={(
																e: React.ChangeEvent<HTMLInputElement>,
															) => {
																if (errors.length > 0) {
																	dispatch(clearNotifications());
																}
																const { value } = e.target;

																formik.setFieldValue('placa', value);
															}}
														/>
													)}
												</Col>
											</Row>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={24} />
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => {
													formik.handleReset();
													dispatch(clearNotifications());
												}}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>
									</Row>
								</Form>
							)}
						</Formik>
					</Section>

					<ButtonVoltar />
				</Col>
			</Row>
		</>
	);
};

export default SegundaViaPlaca;
