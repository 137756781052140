// TYPES
import { ReducerAction } from 'store/modules/types';
import { CadastraFornecedorRequest, Types } from './types';

export function cadastraFornecedorRequest(
	payload: CadastraFornecedorRequest,
): ReducerAction {
	return {
		type: Types.CADASTRA_FORNECEDOR_REQUEST,
		payload,
	};
}
export function cadastraFornecedorSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRA_FORNECEDOR_SUCCESS,
		payload,
	};
}
export function cadastraFornecedorFailure(payload: object): ReducerAction {
	return {
		type: Types.CADASTRA_FORNECEDOR_FAILURE,
		payload,
	};
}
export function cadastraFornecedorClear(): ReducerAction {
	return {
		type: Types.CADASTRA_FORNECEDOR_CLEAR,
		payload: null,
	};
}
