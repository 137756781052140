// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaDocumentosProduzidosRequest, Types } from './types';

export function consultaDocumentosProduzidosRequest(
	payload: ConsultaDocumentosProduzidosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_DOCUMENTOS_PRODUZIDOS_REQUEST,
		payload,
	};
}
export function consultaDocumentosProduzidosSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_DOCUMENTOS_PRODUZIDOS_SUCCESS,
		payload,
	};
}
export function consultaDocumentosProduzidosFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_DOCUMENTOS_PRODUZIDOS_FAILURE,
		payload,
	};
}
export function consultaDocumentosProduzidosClear(): ReducerAction {
	return {
		type: Types.CONSULTA_DOCUMENTOS_PRODUZIDOS_CLEAR,
		payload: null,
	};
}
