import {
	IRequestCotas,
	IRequestIncluirBeneficiario,
	IRequestIncluirBeneficiarioBusca,
} from 'store/modules/api/seds/vivaLeite/beneficiarios/types';
import * as Yup from 'yup';

export interface IBuscaBeneficiario {
	cod_programa: number | string;
	nis: string;
	nome_responsavel_idoso?: string;
}

interface IEntidadeCotas {
	cota: number | null;
	vagas: number | null;
	labelNis: string;
}

interface IDadosBeneficiarios {
	cod_programa: number;
	labelNome: string;
	nome: string;
	dataNascimento: string;
	nis: string;
	renda: string;
}

export const InitialValues: IBuscaBeneficiario = {
	cod_programa: '',
	nis: '',
	nome_responsavel_idoso: '',
};

export const InitialConsultaCotas: IRequestCotas = {
	servico: 'ATUALIZACAO CADASTRAL',
	origem: 'PORTAL',
	num_pan: 0,
};

export const InitialBuscaDadosBeneficiarios: IRequestIncluirBeneficiarioBusca = {
	servico: 'ATUALIZACAO CADASTRAL',
	origem: 'PORTAL',
	num_pan: 0,
	cod_programa: 0,
	etapa: 'busca_familia',
	nis: '',
};

export const InitialIncluirBeneficiarios: IRequestIncluirBeneficiario = {
	servico: 'ATUALIZACAO CADASTRAL',
	origem: 'PORTAL',
	num_pan: 0,
	cod_programa: 0,
	etapa: 'efetiva_inclusao',
};

export const InitialEntidadeCotas: IEntidadeCotas = {
	cota: null,
	vagas: null,
	labelNis: 'Nis',
};

export const InitialDadosBeneficiarios: IDadosBeneficiarios = {
	cod_programa: 0,
	labelNome: 'Nome:',
	nome: '',
	dataNascimento: '',
	nis: '',
	renda: '',
};

export const selectPrograma = [
	{ label: 'CRIANÇA', value: 1 },
	{ label: 'IDOSO', value: 2 },
];

export const schema = Yup.object<IBuscaBeneficiario>().shape({
	cod_programa: Yup.number()
		.required('Selecione o programa')
		.nullable()
		.transform((value: string, originalValue: string) =>
			originalValue.toString().trim() === '' ? null : value,
		),
	nis: Yup.string().required('Campo Obrigatório: NIS.'),
});
