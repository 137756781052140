export enum Types {
	CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_REQUEST = '@DETRAN-CRV/CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_REQUEST',
	CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_SUCCESS = '@DETRAN-CRV/CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_SUCCESS',
	CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_FAILURE = '@DETRAN-CRV/CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_FAILURE',
	CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_CLEAR = '@DETRAN-CRV/CANCELAR_PLACA_PRIMEIRO_EMPLACAMENTO_CLEAR',
}

export interface CancelarPlacaPrimeiroEmplacamento {
	status: number;
	data: cancelar | null;
}

interface cancelar {
	resultadoCancelamentoAutorizacao: 'string';
}

export interface CancelarPlacaPrimeiroEmplacamentoRequest {
	chassi: string;
	cpfCnpjProprietario: string;
}
