// TYPES
import { ReducerAction } from 'store/modules/types';
import { IRequestEmissaoNominal, DataEmissaoAtestado, Types } from './types';

export function emissaoNominalRequest(
	payload: IRequestEmissaoNominal,
): ReducerAction {
	return {
		type: Types.EMISSAO_NOMINAL_REQUEST,
		payload,
	};
}

export function emissaoNominalSuccess(
	payload: DataEmissaoAtestado,
): ReducerAction {
	return {
		type: Types.EMISSAO_NOMINAL_SUCCESS,
		payload,
	};
}

export function emissaoNominalFailure(): ReducerAction {
	return {
		type: Types.EMISSAO_NOMINAL_FAILURE,
		payload: null,
	};
}

export function limpaEmissaoNominal(): ReducerAction {
	return {
		type: Types.LIMPA_EMISSAO_NOMINAL,
		payload: null,
	};
}
