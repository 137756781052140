import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, consultaCanais } from './types';

export const INITIAL_STATE: consultaCanais = {
	status: 0,
	data: null,
};

export default function consultaCanaisReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaCanais {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_CANAIS: {
				break;
			}
			case Types.CONSULTA_CANAIS_SUCCESS: {
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTA_CANAIS_FAILURE: {
				break;
			}

			default:
		}
	});
}
