import { Types } from './types';

export function consultaUnidadeTransitoRequest(payload: any) {
	return {
		type: Types.CONSULTA_UNIDADE_TRANSITO_REQUEST,
		payload,
	};
}
export function consultaUnidadeTransitoSuccess(payload: any) {
	return {
		type: Types.CONSULTA_UNIDADE_TRANSITO_SUCCESS,
		payload,
	};
}
export function consultaUnidadeTransitoFailure(payload: []): any {
	return {
		type: Types.CONSULTA_UNIDADE_TRANSITO_FAILURE,
		payload,
	};
}
export function consultaUnidadeTransitoClear(): any {
	return {
		type: Types.CONSULTA_UNIDADE_TRANSITO_CLEAR,
	};
}
