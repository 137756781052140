import styled from 'styled-components';

// IMAGES
import maos from 'assets/images/maos.png';
import assinatura from 'assets/images/assinatura_caneta.jpg';
import foto from 'assets/images/foto.png';

const imageSize = '80px';

export const Item = styled.div`
	border: 1px solid #999;
	padding: 10px 10px 0px 10px;
	width: 100%;

	& + div {
		margin-top: 10px;
	}
`;

export const Label = styled.p`
	color: #000;
	font-weight: 700;
	text-align: center;
`;

export const ImageBox = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 10px 0px;
	width: 100%;
`;

export const Maos = styled.div`
	background-image: url(${maos});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	height: ${imageSize};
	width: 100%;
`;

export const Assinatura = styled.div`
	background-image: url(${assinatura});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	height: ${imageSize};
	width: 100%;
`;

export const Foto = styled.div`
	background-image: url(${foto});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	height: ${imageSize};
	width: 100%;
`;

export const ButtonDiv = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 15px !important;
	width: 100%;
`;
