import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// ANT DESIGN
import { Col, Row } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { consultaFornecedorClear } from 'store/modules/api/procon/fornecedores/consultaFornecedor/actions';

// COMPONENTS
import { FormikProps } from 'formik';
import LightButton from 'components/Common/Buttons/Light';
import TypographyCommon from 'components/Common/Typography';
import { fornecedorSelecionadoRequest } from 'store/modules/api/procon/fornecedores/selecionado/actions';

interface Props {
	formikParent: FormikProps<any>;
	setShowModal: (v: 'none' | 'fornecedor' | 'consumidor') => void;
	typeRegistration: 'consulta' | 'reclamacao';
}

const ConsultaFornecedor: React.FC<Props> = ({
	formikParent,
	setShowModal,
	typeRegistration,
}) => {
	const dispatch = useDispatch();

	const fornecedor = useSelector(
		(state: ApplicationState) =>
			state.api.procon.fornecedores.consultaFornecedor.data,
	);

	function formatAddress() {
		let formmatedAddress = '';
		if (fornecedor?.logradouro) {
			formmatedAddress += `${fornecedor.logradouro}, `;
		}
		if (fornecedor?.numero) {
			formmatedAddress += `${fornecedor.numero}, `;
		}
		if (fornecedor?.bairro) {
			formmatedAddress += `${fornecedor.bairro}, `;
		}
		if (fornecedor?.cidade) {
			formmatedAddress += `${fornecedor.cidade}, `;
		}
		if (fornecedor?.estado) {
			formmatedAddress += `${fornecedor.estado}`;
		}
		return formmatedAddress;
	}

	const handleSubmit = useCallback(() => {
		if (fornecedor) {
			dispatch(fornecedorSelecionadoRequest(fornecedor));
			formikParent.setFieldValue('fornecedorId', fornecedor.fornededorId);
			if (typeRegistration === 'consulta') {
				formikParent.setFieldValue('nomeDoFornecedor', fornecedor.razaoSocial);
			} else {
				formikParent.setFieldValue('fornecedorNome', fornecedor.razaoSocial);
			}
			setShowModal('none');
			dispatch(consultaFornecedorClear());
		}
	}, [fornecedor, formikParent, typeRegistration, setShowModal, dispatch]);

	return (
		<>
			<Row gutter={[0, 0]} align="middle" justify="start">
				<Col span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Fornecedor"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>

			<Row gutter={[0, 0]} align="middle" justify="start">
				<Col span={24} style={{ height: '40px' }}>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<TypographyCommon
							text="Nome Fornecedor:"
							fontWeight="bold"
							uppercase
						/>
					</Row>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<TypographyCommon
							text={fornecedor?.razaoSocial || 'Não informado'}
							uppercase
						/>
					</Row>
				</Col>
			</Row>

			<br />

			<Row gutter={[0, 0]} align="middle" justify="start">
				<Col span={5} style={{ height: '40px' }}>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<TypographyCommon text="CPF/CNPJ:" fontWeight="bold" uppercase />
					</Row>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<Col span={23}>
							<TypographyCommon
								text={fornecedor?.cnpj || fornecedor?.cpf || 'Não informado'}
								uppercase
							/>
						</Col>
					</Row>
				</Col>
				<Col span={10} style={{ height: '40px' }}>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<TypographyCommon
							text="Nome Fantasia:"
							fontWeight="bold"
							uppercase
						/>
					</Row>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<Col span={23}>
							<TypographyCommon
								text={fornecedor?.nomeFantasia || 'Não informado'}
								uppercase
							/>
						</Col>
					</Row>
				</Col>
				<Col span={9} style={{ height: '40px' }}>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<TypographyCommon text="Web Site:" fontWeight="bold" uppercase />
					</Row>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<Col span={24}>
							<TypographyCommon
								text={fornecedor?.website || 'Não informado'}
								uppercase
							/>
						</Col>
					</Row>
				</Col>
			</Row>

			<br />

			<Row gutter={[0, 0]} align="middle" justify="start">
				<Col span={5} style={{ height: '40px' }}>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<TypographyCommon text="Telefone:" fontWeight="bold" uppercase />
					</Row>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<Col span={24}>
							<TypographyCommon
								text={fornecedor?.telefone || 'Não informado'}
								uppercase
							/>
						</Col>
					</Row>
				</Col>
				<Col span={19} style={{ height: '40px' }}>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<TypographyCommon text="E-mail:" fontWeight="bold" uppercase />
					</Row>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<Col span={24}>
							<TypographyCommon
								text={fornecedor?.email || 'Não informado'}
								uppercase
							/>
						</Col>
					</Row>
				</Col>
			</Row>

			<br />

			<Row gutter={[0, 0]} align="middle" justify="start">
				<Col span={24} style={{ height: '40px' }}>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<TypographyCommon text="Endereço:" fontWeight="bold" uppercase />
					</Row>
					<Row gutter={[0, 0]} align="middle" justify="start">
						<Col span={24}>
							<TypographyCommon
								text={formatAddress() || 'Não informado'}
								uppercase
							/>
						</Col>
					</Row>
				</Col>
			</Row>

			<br />

			<Row gutter={[0, 0]} align="middle" justify="start">
				<Col span="24">
					<Row align="middle" justify="center">
						<LightButton
							id="btn_modal_consulta_selecionar_fornecedor"
							onClick={handleSubmit}
							type="button"
							buttonColor="default"
							text="Selecionar"
							icon="check"
							buttonSize="sm"
							buttonWidth={140}
						/>
					</Row>
				</Col>
			</Row>
		</>
	);
};
export default ConsultaFornecedor;
