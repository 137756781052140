import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { getApi, postApi, deleteApi, putApi } from 'services/_api';
import { PATH_PREFEITURAS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultaParametrosSuccess,
	consultaParametrosFailure,
	cadastraParametrosSuccess,
	cadastraParametrosFailure,
	alteraParametrosSuccess,
	alteraParametrosFailure,
	removeParametrosSuccess,
	removeParametrosFailure,
	IRequestConsultaParametros,
	IRequestCadastraParametro,
} from './actions';

function* consultaParametros(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaParametros } = request;

	const response = yield call(getApi, PATH_PREFEITURAS, 'parametros', payload);

	if (response.status === 200) {
		yield put(consultaParametrosSuccess(response));
	} else if (response.status === 204) {
		yield put(consultaParametrosFailure([]));
	} else if (response.error) {
		yield put(consultaParametrosFailure(response.message));
	}
}

function* cadastraParametros(request: ReducerAction) {
	const { payload }: { payload: IRequestCadastraParametro } = request;

	const response = yield call(postApi, PATH_PREFEITURAS, 'parametros', payload);

	if (response.status === 201) {
		yield put(cadastraParametrosSuccess(response));
	} else if (response.error) {
		yield put(cadastraParametrosFailure(response.message));
	}
}

function* alteraParametros(request: ReducerAction) {
	const { payload }: { payload: IRequestCadastraParametro } = request;

	const body = { ...payload, codigo: null };
	const response = yield call(
		putApi,
		PATH_PREFEITURAS,
		`parametros/${payload.codigo}`,
		body,
	);

	if (response.status === 200) {
		yield put(alteraParametrosSuccess(response));
	} else if (response.error) {
		yield put(alteraParametrosFailure(response.message));
	}
}

function* removeParametros(request: ReducerAction) {
	const { payload }: { payload: number } = request;

	const response = yield call(
		deleteApi,
		PATH_PREFEITURAS,
		`parametros/${payload}`,
	);

	if (response.status === 200) {
		yield put(removeParametrosSuccess(payload));
	} else if (response.error) {
		yield put(removeParametrosFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTA_PARAMETROS, consultaParametros),
	takeLatest(Types.CADASTRA_PARAMETROS, cadastraParametros),
	takeLatest(Types.REMOVE_PARAMETROS, removeParametros),
	takeLatest(Types.ALTERA_PARAMETROS, alteraParametros),
]);
