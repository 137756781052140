import produce from 'immer';

import { Types, ConsultaToxicologico } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultaToxicologico = {
	status: 0,
	data: null,
	dataError: null,
};

export default function consultaNomeSocialFiliacaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaToxicologico {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_TOXICOLOGICO_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.CONSULTA_TOXICOLOGICO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTA_TOXICOLOGICO_FAILURE: {
				draft.dataError = action.payload.data;
				draft.status = 400;
				break;
			}
			case Types.CONSULTA_TOXICOLOGICO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}
			default:
		}
	});
}
