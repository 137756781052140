export enum Types {
	SAVE_TAXA_SERVICO = '@DETRAN-CRV/SAVE_TAXA_SERVICO',
	SAVE_TAXA_SERVICO_SUCCESS = '@DETRAN-CRV/SAVE_TAXA_SERVICO_SUCCESS',
	SAVE_TAXA_SERVICO_FAILURE = '@DETRAN-CRV/SAVE_TAXA_SERVICO_FAILURE',
	SAVE_TAXA_SERVICO_CLEAR = '@DETRAN-CRV/SAVE_TAXA_SERVICO_CLEAR',
}

export interface saveTaxaServico {
	status: number;
	data: DataSaveTaxaServico | null;
}

export interface DataSaveTaxaServico {
	codBanco: string;
	codigoTaxaServico: string;
	cpfCnpj: string;
	dataContabil: string;
	identificadorTaxa: string;
	nsuProdesp: string;
	pixIdFimAFim?: string;
	pixIdTransacao?: string;
	renavam?: string;
}

export interface SaveTaxaServicoRequest {
	codBanco: string;
	codigoTaxaServico: string;
	cpfCnpj: string;
	dataContabil: string;
	identificadorTaxa: string;
	nsuProdesp: string;
	pixIdFimAFim?: string;
	pixIdTransacao?: string;
	renavam?: string;
}
