import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch } from 'react-redux';
import { cdhuClear } from 'store/modules/api/cdhu/actions';
import { redirecionamentoAtendimento } from 'store/modules/atendimento/actions';

// PATHS
import {
	ROUTE_CDHU_RECUPERAR_ATENDIMENTO,
	ROUTE_CDHU_SOLICITANTE_PESQUISAR,
} from '../routes/paths';

const MenuCdhu: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(cdhuClear());
		dispatch(
			redirecionamentoAtendimento({ route: ROUTE_CDHU_SOLICITANTE_PESQUISAR }),
		);
		history.push(ROUTE_CDHU_RECUPERAR_ATENDIMENTO);
	}, [dispatch, history]);

	return <></>;
};

export default MenuCdhu;
