import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import { consultaESegundaViaRequest } from 'store/modules/api/detranCnh/segundaVia/consultaEPortalSegundaVia/actions';

// UTILS
import { ROUTE_DETRAN_CNH_DIGITAL_STATUS_CONDUTOR } from 'pages/DetranCnh/routes/paths';

// FORM
import Pesquisa from 'pages/DetranCnh/components/pages/PreCadastro/Pesquisa';
import {
	IFormPesquisaRenovacao,
	treatValues,
} from 'pages/DetranCnh/components/pages/PreCadastro/Pesquisa/form';
import { ROUTE_DETRAN_CNH } from 'routes/paths';
import { threatValuesStartSessionEventRequest } from 'utils/functions/threatEventsRequest';
import { eventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/actions';

const PesquisaCnhDigital: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { mainframe } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { consultaEPortalSegundaVia } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.segundaVia,
	);

	const { pesquisarContato } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.portalDetran,
	);

	const handleSubmit = useCallback(
		(values: IFormPesquisaRenovacao) => {
			const newValues = treatValues(values, {
				usuario: mainframe.idUsuario,
				senha: mainframe.senhaMainFrame,
			});

			const valuesStartSessionEvent = threatValuesStartSessionEventRequest(
				atendimento,
				loginUnico,
			);
			dispatch(eventoInicioSessaoRequest(valuesStartSessionEvent));

			dispatch(consultaESegundaViaRequest({ ...newValues }));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, mainframe.idUsuario, mainframe.senhaMainFrame],
	);

	useEffect(() => {
		dispatch(detranCnhClear());
	}, [dispatch]);

	useEffect(() => {
		if (
			consultaEPortalSegundaVia.status === 200 &&
			pesquisarContato.status !== 100
		) {
			history.push(ROUTE_DETRAN_CNH_DIGITAL_STATUS_CONDUTOR);
		}
	}, [consultaEPortalSegundaVia, dispatch, history, pesquisarContato.status]);

	return (
		<Pesquisa handleSubmit={handleSubmit} routeBackButton={ROUTE_DETRAN_CNH} />
	);
};

export default PesquisaCnhDigital;
