// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export interface IRequestEnvioSms {
	numeroRg: string;
	usuario: string;
	senha: string;
}

export function enviarSmsRequest(payload: IRequestEnvioSms): ReducerAction {
	return {
		type: Types.ENVIAR_SMS_REQUEST,
		payload,
	};
}
export function enviarSmsSuccess(payload: number): ReducerAction {
	return {
		type: Types.ENVIAR_SMS_SUCCESS,
		payload,
	};
}
export function enviarSmsFailure(): ReducerAction {
	return {
		type: Types.ENVIAR_SMS_FAILURE,
		payload: null,
	};
}

export function enviarSmsClear(): ReducerAction {
	return {
		type: Types.ENVIAR_SMS_CLEAR,
		payload: null,
	};
}
