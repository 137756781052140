import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarOrgaoRequest } from './types';

export function atualizarOrgaoRequest(
	data: AtualizarOrgaoRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_ORGAO,
		payload: data,
	};
}
export function atualizarOrgaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_ORGAO_SUCCESS,
		payload,
	};
}
export function atualizarOrgaoFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_ORGAO_FAILURE,
		payload: null,
	};
}
export function atualizarOrgaoClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_ORGAO_CLEAR,
		payload: null,
	};
}
