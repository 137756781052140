import * as Yup from 'yup';

// REDUX
import { ConsultaRenovacaoRequest } from 'store/modules/api/detranCnh/eportal/consultaRenovacaoCnh/types';

// UTILS
import { unformatDate, validaCpf } from 'utils/genericFunctions';

interface ICredentials {
	usuario: string;
	senha: string;
}
export interface IFormPesquisaRenovacao {
	tipoDocumento: 'CPF' | 'RG' | 'REGISTRO' | 'PGU';
	cpf: string;
	numeroRg: string;
	numeroRegistro: string;
	numeroPgu: string;
	nome: string;
	dataNascimento: string;
	buscaPorNome?: boolean;
}

export const initialValues: IFormPesquisaRenovacao = {
	tipoDocumento: 'CPF',
	cpf: '',
	numeroRg: '',
	numeroRegistro: '',
	numeroPgu: '',
	nome: '',
	dataNascimento: '',
	buscaPorNome: false,
};

export function treatValues(
	values: IFormPesquisaRenovacao,
	credentials: ICredentials,
): ConsultaRenovacaoRequest {
	let formattedData: ConsultaRenovacaoRequest = {
		usuario: credentials.usuario,
		senha: credentials.senha,
	};

	if (values.buscaPorNome) {
		formattedData = {
			...formattedData,
			nome: values.nome,
			dataNascimento: unformatDate(values.dataNascimento),
		};

		return formattedData;
	}

	switch (values.tipoDocumento) {
		case 'CPF':
			formattedData = {
				...formattedData,
				cpf: values.cpf.replace(/[.-]+/g, ''),
			};
			break;
		case 'RG':
			formattedData = {
				...formattedData,
				numeroRg: values.numeroRg,
			};
			break;
		case 'REGISTRO':
			formattedData = {
				...formattedData,
				numeroRegistro: values.numeroRegistro,
			};
			break;
		case 'PGU':
			formattedData = {
				...formattedData,
				numeroPgu: values.numeroPgu,
			};
			break;
		default:
	}
	return formattedData;
}

export const schema = Yup.object<IFormPesquisaRenovacao>().shape({
	cpf: Yup.string()
		.when(
			['tipoDocumento', 'buscaPorNome'],
			(tipoDocumento: string, buscaPorNome: boolean, schema2: any) => {
				return schema2.test({
					test: (cpf: string) =>
						buscaPorNome || tipoDocumento !== 'CPF' || cpf !== '',
					message: 'Campo Obrigatório: Documento.',
				});
			},
		)
		.test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF digitado inválido.',
			test: value => validaCpf(value),
		}),
	numeroRg: Yup.string().when(
		['tipoDocumento', 'buscaPorNome'],
		(tipoDocumento: string, buscaPorNome: boolean, schema2: any) => {
			return schema2.test({
				test: (numeroRg: string) =>
					buscaPorNome || tipoDocumento !== 'RG' || numeroRg !== '',
				message: 'Campo Obrigatório: Documento.',
			});
		},
	),
	numeroRegistro: Yup.string().when(
		['tipoDocumento', 'buscaPorNome'],
		(tipoDocumento: string, buscaPorNome: boolean, schema2: any) => {
			return schema2.test({
				test: (numeroRegistro: string) =>
					buscaPorNome || tipoDocumento !== 'REGISTRO' || numeroRegistro !== '',
				message: 'Campo Obrigatório: Documento.',
			});
		},
	),
	numeroPgu: Yup.string().when(
		['tipoDocumento', 'buscaPorNome'],
		(tipoDocumento: string, buscaPorNome: boolean, schema2: any) => {
			return schema2.test({
				test: (numeroPgu: string) =>
					buscaPorNome || tipoDocumento !== 'PGU' || numeroPgu !== '',
				message: 'Campo Obrigatório: Documento.',
			});
		},
	),
	nome: Yup.string().when(
		'buscaPorNome',
		(buscaPorNome: boolean, schema2: any) => {
			return schema2.test({
				test: (nome: string) => !buscaPorNome || nome !== '',
				message: 'Campo Obrigatório: Nome.',
			});
		},
	),
	dataNascimento: Yup.string().when(
		'buscaPorNome',
		(buscaPorNome: boolean, schema2: any) => {
			return schema2.test({
				test: (dataNascimento: string) =>
					!buscaPorNome || dataNascimento !== '',
				message: 'Campo Obrigatório: Data de Nascimento.',
			});
		},
	),
});
