import { combineReducers } from 'redux';

import consultaAtendimentos from './consultaAtendimentos/reducer';
import sinalizaResolucaoConsultaAtendimento from './sinalizaResolucaoConsultaAtendimento/reducer';
import cancelaConsultaAtendimento from './cancelaConsultaAtendimento/reducer';

export default combineReducers({
	consultaAtendimentos,
	sinalizaResolucaoConsultaAtendimento,
	cancelaConsultaAtendimento,
});
