import * as Yup from 'yup';

// TYPES
import { IRequestTransferenciasProprietarios } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirTransferenciasProprietarios/types';
import { IEvento } from 'store/modules/api/detranCrv/types';
// UTILS
import {
	limparMascara,
	unformatDate,
	separarDDD,
} from 'utils/genericFunctions';
import { ICaracteristicaVeiculo } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/types';
import { formatRg } from '../../formFunctions';

interface IComunicacaoVenda {
	dataVenda: string;
}

interface IDocumentos {
	conteudo: string;
	nomeDocumento: string;
	tipoArquivo: string;
}
export interface IEmiteTransferenciaVeiculo {
	evento: IEvento;
	renavam: string;
	placa: string;
	protocoloRemacarcao: string;
	protocoloRemarcacao: string;
	codigoMunicipio: number | string;
	descricaoMunicipio: string;
	numeroEspelhoAnterior: string;
	chassi: string;
	chassiRemarcado: boolean;
	longIdMarca: number | string;
	descricaoMarca: string;
	anoFabricacao: number | string;
	anoModelo: number | string;
	longIdTipo: number | string;
	descricaoTipo: string;
	longIdCarroceria: number | string;
	descricaoCarroceria: string;
	longIdCor: number | string;
	descricaoCor: string;
	longIdCategoria: number | string;
	descricaoCategoria: string;
	longIdCombustivel: number | string;
	descricaoCombustivel: string;
	longIdEspecie: number | string;
	descricaoEspecie: string;
	capacidadePassageiros: number | string;
	capacidadeCarga: number | string;
	potencia: number | string;
	cilindrada: number | string;
	cmt: number | string;
	pbt: number | string;
	eixos: number | string;
	dataVenda: string;
	procedencia: string;
	nomeProprietario: string;
	cepProprietario: string;
	bairroProprietario: string;
	enderecoProprietario: string;
	numeroProprietario: string;
	complementoProprietario: string;
	telefone: string;
	codigoMunicipioProprietario: number | string;
	descricaoMunicipioProprietario: string;
	codigoMunicipioPlacaAnterior: number | string;
	descricaoMunicipioPlacaAnterior: string;
	nomeProprietarioAnterior: string;
	placaAnterior: string;
	ufPlacaAnterior: string;
	identificacao: string;
	rgProprietario: string;
	rgDigito: string;
	orgaoExpedidorProprietario: string;
	ufProprietario: string;
	cpfCnpjProprietario: string;
	ufEspelhoAnterior: string;
	valorVenda: string;
	arrendatarioFinanceira: string;
	codigoFinanceira: string | number;
	numeroContrato: string;
	restricao: string;
	dataVigencia: string;
	autenticacaoDigitalDut: string;
	codBanco: string;
	dataContabil: string;
	nsuProdesp: string;
	autenticacaoDigitalEmpl: string;
	taxaAutenticacaoDigital: string;
	comunicacaoVenda: IComunicacaoVenda;
	documentos: any;
	servicoDigital: boolean;
	emailCidadao: string;
	dddTelefoneCidadao: string;
}

export const initialValues: IEmiteTransferenciaVeiculo = {
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
		identificacao: '',
		ip: '',
		canal: {
			id: 0,
			desc: '',
			localidade: {
				id: 0,
				desc: '',
			},
		},
	},
	renavam: '',
	protocoloRemacarcao: '',
	placa: '',
	protocoloRemarcacao: '',
	codigoMunicipio: '',
	descricaoMunicipio: '',
	numeroEspelhoAnterior: '',
	chassi: '',
	chassiRemarcado: false,
	longIdMarca: '',
	descricaoMarca: '',
	anoModelo: '',
	longIdTipo: '',
	descricaoTipo: '',
	longIdCarroceria: '',
	descricaoCarroceria: '',
	longIdCor: '',
	descricaoCor: '',
	longIdCategoria: '',
	descricaoCategoria: '',
	longIdCombustivel: '',
	descricaoCombustivel: '',
	longIdEspecie: '',
	descricaoEspecie: '',
	capacidadePassageiros: '',
	capacidadeCarga: '',
	potencia: '',
	cilindrada: '',
	cmt: '',
	pbt: '',
	eixos: '',
	procedencia: '',
	nomeProprietario: '',
	cepProprietario: '',
	bairroProprietario: '',
	enderecoProprietario: '',
	numeroProprietario: '',
	complementoProprietario: '',
	telefone: '',
	codigoMunicipioProprietario: '',
	descricaoMunicipioProprietario: '',
	codigoMunicipioPlacaAnterior: '',
	descricaoMunicipioPlacaAnterior: '',
	rgProprietario: '',
	rgDigito: '',
	orgaoExpedidorProprietario: '',
	ufProprietario: '',
	anoFabricacao: '',
	dataVenda: '',
	nomeProprietarioAnterior: '',
	placaAnterior: '',
	ufPlacaAnterior: '',
	identificacao: 'FISICA',
	cpfCnpjProprietario: '',
	ufEspelhoAnterior: '',
	valorVenda: '',
	arrendatarioFinanceira: '',
	codigoFinanceira: '',
	numeroContrato: '',
	restricao: '',
	dataVigencia: '',
	autenticacaoDigitalDut: '',
	codBanco: '',
	dataContabil: '',
	nsuProdesp: '',
	autenticacaoDigitalEmpl: '',
	taxaAutenticacaoDigital: '',
	comunicacaoVenda: {
		dataVenda: '',
	},
	documentos: [],
	servicoDigital: true,
	emailCidadao: '',
	dddTelefoneCidadao: '',
};

export const schema = Yup.object<IEmiteTransferenciaVeiculo>().shape({
	placa: Yup.string().required('Campo Obrigatório: Placa.'),
	municipio: Yup.string().when(['codigoMunicipio', 'descricaoMunicipio'], {
		is: (codigoMunicipio, descricaoMunicipio) =>
			[codigoMunicipio, descricaoMunicipio].includes(''),
		then: Yup.string().required('Campo Obrigatório: Município.'),
	}),
	codigoMunicipioProprietario: Yup.string().when('identificacao', {
		is: identificacao => identificacao === 'FISICA',
		then: Yup.string().required('Campo Obrigatório: Município Proprietário.'),
	}),
	numeroEspelhoAnterior: Yup.string().required(
		'Campo Obrigatório: Espelho Anterior.',
	),
	chassi: Yup.string().required('Campo Obrigatório: Chassi.'),
	chassiRemarcado: Yup.string().required(
		'Campo Obrigatório: Chassi Remarcado.',
	),
	marca: Yup.string().when(['descricaoMarca', 'longIdMarca'], {
		is: (descricaoMarca, longIdMarca) =>
			[descricaoMarca, longIdMarca].includes(''),
		then: Yup.string().required('Campo Obrigatório: Marca.'),
	}),
	anoModelo: Yup.string().required('Campo Obrigatório: Ano Modelo.'),
	anoFabricacao: Yup.string().required('Campo Obrigatório: Ano Fabricação.'),
	tipo: Yup.string().when(['descricaoTipo', 'longIdTipo'], {
		is: (descricaoTipo, longIdTipo) => [descricaoTipo, longIdTipo].includes(''),
		then: Yup.string().required('Campo Obrigatório: Tipo.'),
	}),
	carroceria: Yup.string().when(['descricaoCarroceria', 'longIdCarroceria'], {
		is: (descricaoCarroceria, longIdCarroceria) =>
			[descricaoCarroceria, longIdCarroceria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Carroceria.'),
	}),
	cor: Yup.string().when(['descricaoCor', 'longIdCor'], {
		is: (descricaoCor, longIdCor) => [descricaoCor, longIdCor].includes(''),
		then: Yup.string().required('Campo Obrigatório: Cor.'),
	}),
	categoria: Yup.string().when(['descricaoCategoria', 'longIdCategoria'], {
		is: (descricaoCategoria, longIdCategoria) =>
			[descricaoCategoria, longIdCategoria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Categoria.'),
	}),
	combustivel: Yup.string().when(
		['descricaoCombustivel', 'longIdCombustivel'],
		{
			is: (descricaoCombustivel, longIdCombustivel) =>
				[descricaoCombustivel, longIdCombustivel].includes(''),
			then: Yup.string().required('Campo Obrigatório: Combustível.'),
		},
	),
	especie: Yup.string().when(['descricaoEspecie', 'longIdEspecie'], {
		is: (descricaoEspecie, longIdEspecie) =>
			[descricaoEspecie, longIdEspecie].includes(''),
		then: Yup.string().required('Campo Obrigatório: Espécie.'),
	}),
	capacidadePassageiros: Yup.string().required(
		'Campo Obrigatório: Capacidade Passageiros.',
	),
	capacidadeCarga: Yup.string().required(
		'Campo Obrigatório: Capacidade Carga.',
	),
	potencia: Yup.string().required('Campo Obrigatório: Potência.'),
	cilindrada: Yup.string().required('Campo Obrigatório: Cilindrada.'),
	cmt: Yup.string().required('Campo Obrigatório: CMT.'),
	pbt: Yup.string().required('Campo Obrigatório: PBT.'),
	eixos: Yup.string().required('Campo Obrigatório: Eixos.'),
	procedencia: Yup.string().required(
		'Campo Obrigatório: Procedência do veículo.',
	),
	nomeProprietario: Yup.string().required(
		'Campo Obrigatório: Nome do Proprietário.',
	),
	cepProprietario: Yup.string().required(
		'Campo Obrigatório: Cep do Imóvel do Proprietário.',
	),
	// codigoMunicipioProprietario: Yup.string().required(
	// 	'Campo Obrigatório: Município Proprietário.',
	// ),
	enderecoProprietario: Yup.string().required('Campo Obrigatório: Endereço.'),
	numeroProprietario: Yup.string().required('Campo Obrigatório: Número.'),
	cpfCnpjProprietario: Yup.string().required(
		'Campo Obrigatório: CPF/CNPJ Proprietário.',
	),
	rgProprietario: Yup.string().when('identificacao', {
		is: identificacao => identificacao === 'FISICA',
		then: Yup.string().required(`Campo Obrigatório: RG do Proprietário Atual.`),
	}),
	ufProprietario: Yup.string().when('identificacao', {
		is: identificacao => identificacao === 'FISICA',
		then: Yup.string().required(`Campo Obrigatório: UF do Proprietário Atual.`),
	}),
	orgaoExpedidorProprietario: Yup.string().when('identificacao', {
		is: identificacao => identificacao === 'FISICA',
		then: Yup.string().required(`Campo Obrigatório: Orgão Expeditor.`),
	}),
	// comunicacaoVenda: Yup.object().shape({
	dataVenda: Yup.string().required('Campo Obrigatório: Data da Venda.'),
	// }),
	valorVenda: Yup.string().required('Campo Obrigatório: Valor da Venda.'),
	emailCidadao: Yup.string().when('servicoDigital', {
		is: servicoDigital => servicoDigital === true,
		then: Yup.string().required(`Campo Obrigatório: E-mail.`),
	}),
	dddTelefoneCidadao: Yup.string().when('servicoDigital', {
		is: servicoDigital => servicoDigital === true,
		then: Yup.string()
			.test(
				'len',
				'Insira o DDD e o número do telefone.',
				(val: any) => val.replace(/\D/g, '').length === 11,
			)
			.required(`Campo Obrigatório: Telefone de Contato.`),
	}),
	documentos: Yup.string().required(`Campo Obrigatório: Anexo de documento.`),
	// documentos: Yup.string().when('servicoDigital', {
	// 	is: servicoDigital => servicoDigital === true,
	// 	then: Yup.string().required(`Campo Obrigatório: Anexo de documento.`),
	// }),
});

export function treatValues(
	formValues: IEmiteTransferenciaVeiculo,
	veiculo: ICaracteristicaVeiculo,
	evento: IEvento,
): IRequestTransferenciasProprietarios {
	const {
		anoModelo,
		capacidadeCarga,
		capacidadePassageiros,
		chassi,
		chassiRemarcado,
		cilindrada,
		cmt,
		eixos,
		numeroEspelhoAnterior,
		pbt,
		placa,
		potencia,
		procedencia,
		longIdCarroceria,
		descricaoCarroceria,
		longIdCategoria,
		descricaoCategoria,
		longIdCombustivel,
		descricaoCombustivel,
		longIdCor,
		descricaoCor,
		longIdEspecie,
		descricaoEspecie,
		longIdMarca,
		descricaoMarca,
		longIdTipo,
		descricaoTipo,
		nomeProprietario,
		cepProprietario,
		enderecoProprietario,
		numeroProprietario,
		complementoProprietario,
		bairroProprietario,
		telefone,
		codigoMunicipioProprietario,
		descricaoMunicipioProprietario,
		codigoMunicipioPlacaAnterior,
		descricaoMunicipioPlacaAnterior,
		nomeProprietarioAnterior,
		placaAnterior,
		ufPlacaAnterior,
		rgProprietario,
		rgDigito,
		orgaoExpedidorProprietario,
		ufProprietario,
		identificacao,
		cpfCnpjProprietario,
		anoFabricacao,
		renavam,
		protocoloRemacarcao,
		ufEspelhoAnterior,
		valorVenda,
		comunicacaoVenda,
		arrendatarioFinanceira,
		dataVigencia,
		codigoFinanceira,
		numeroContrato,
		restricao,
		taxaAutenticacaoDigital,
		documentos,
		emailCidadao,
		dddTelefoneCidadao,
		servicoDigital,
	} = formValues;

	const { ddd, numTelefone } = separarDDD(dddTelefoneCidadao);

	const formatedRg = formatRg(rgProprietario, rgDigito);

	const requestValues: IRequestTransferenciasProprietarios = {
		evento: {
			id_atendimento: evento.id_atendimento,
			id_cidadao: evento.id_cidadao,
			ip: evento.ip,
			identificacao: evento.identificacao,
			cpf: evento.cpf || '',
			canal: {
				id: 1,
				desc: 'Presencial',
				localidade: {
					desc: evento.canal?.localidade.desc || '',
					id: evento.canal?.localidade.id || 0,
				},
			},
		},
		dadosTransferencia: {
			protocoloRemacarcao: protocoloRemacarcao
				? Number(protocoloRemacarcao)
				: 0,
			renavam: Number(renavam),
			anoFabricacao: Number(anoFabricacao),
			anoModelo: Number(anoModelo),
			capacidadeCarga: capacidadeCarga ? Number(capacidadeCarga) : 0.0,
			capacidadePassageiros: capacidadePassageiros
				? Number(capacidadePassageiros)
				: 0,
			chassi,
			chassiRemarcado,
			cilindrada: cilindrada ? Number(cilindrada) : 0,
			cmt: cmt ? Number(cmt) : 0.0,
			eixos: eixos ? Number(eixos) : 0,
			numeroEspelhoAnterior: numeroEspelhoAnterior
				? Number(numeroEspelhoAnterior)
				: 0,
			pbt: pbt ? Number(pbt) : 0.0,
			placa,
			potencia: potencia ? Number(potencia) : 0,
			procedencia,
			// protocoloRemarcacao: protocoloRemarcacao
			// 	? Number(protocoloRemarcacao)
			// 	: 0,
			carroceria: {
				longId: Number(longIdCarroceria),
				descricao: descricaoCarroceria,
			},
			categoria: {
				longId: Number(longIdCategoria),
				descricao: descricaoCategoria,
			},
			combustivel: {
				longId: Number(longIdCombustivel),
				descricao: descricaoCombustivel,
			},
			cor: {
				longId: Number(longIdCor),
				descricao: descricaoCor,
			},
			dataVenda: comunicacaoVenda.dataVenda,
			// comunicacaoVenda: {
			// 	dataVenda: formatDate(dataVenda),
			// },
			especie: {
				longId: Number(longIdEspecie),
				descricao: descricaoEspecie,
			},
			gravame: {
				arrendatarioFinanceira,
				dataVigencia: unformatDate(dataVigencia),
				financeira: Number(codigoFinanceira),
				numeroContrato,
				restricaoFinanceira: restricao,
			},
			marca: {
				longId: Number(longIdMarca),
				descricao: descricaoMarca,
			},
			municipio: {
				codigo: Number(codigoMunicipioProprietario),
				nome: descricaoMunicipioProprietario,
			},
			municipioPlacaAnterior: {
				codigo: Number(codigoMunicipioPlacaAnterior),
				nome: descricaoMunicipioPlacaAnterior,
			},
			tipo: {
				longId: Number(longIdTipo),
				descricao: descricaoTipo,
			},
			proprietario: {
				nomeProprietario,
				cepProprietario: Number(limparMascara(cepProprietario)),
				enderecoProprietario,
				numero: Number(numeroProprietario),
				complemento: complementoProprietario,
				bairro: bairroProprietario,
				telefone: telefone ? Number(limparMascara(telefone)) : 0,
				identificacao,
				nomeProprietarioAnterior,
				cpfCnpjProprietario: Number(limparMascara(cpfCnpjProprietario)),
				municipioProprietario: {
					codigo: Number(codigoMunicipioProprietario),
					nome: descricaoMunicipioProprietario,
				},
			},
			ufPlacaAnterior,
			ufEspelhoAnterior,
			placaAnterior,
			taxaAutenticacaoDigital,
			valorVenda: parseFloat(valorVenda),
			documentos,
			emailCidadao,
			dddTelefoneCidadao: ddd !== '' ? Number(ddd) : 0,
			telefoneCidadao: numTelefone !== '' ? Number(numTelefone) : 0,
			servicoDigital,
		},
	};

	if (identificacao === 'FISICA') {
		requestValues.dadosTransferencia.proprietario.rgProprietario = formatedRg;
		requestValues.dadosTransferencia.proprietario.orgaoExpedidorProprietario =
			orgaoExpedidorProprietario;
		requestValues.dadosTransferencia.proprietario.ufProprietario =
			ufProprietario;
	}

	return requestValues;
}
