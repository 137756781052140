import { all } from 'redux-saga/effects';

import agendarExameMedicoSaga from './agendarExameMedico/saga';
import agendarExamesSaga from './agendarExames/saga';
import agendarExamesRenachIntegradoSaga from './agendarExamesRenachIntegrado/saga';
import agendarExamesRenovacaoSaga from './agendarExamesRenovacao/saga';
import consultaSaga from './consultaDivisaoEquitativa/saga';
import dadosCidadaoSaga from './dadosCidadao/saga';
import datasExameSaga from './datasExame/saga';
import datasExamePsicologicoSaga from './datasExamePsicologico/saga';
import horariosPorDataSaga from './horariosPorData/saga';
import horariosPsicologoPorDataSaga from './horariosPsicologoPorData/saga';
import microRegioesSaga from './microRegioes/saga';
import regiaoMicroRegiaoPorCepSaga from './regiaoMicroRegiaoPorCep/saga';
import regioesSaga from './regioes/saga';
import consultaAgendamentosSaga from './consultaAgendamentos/saga';
import cancelarAgendamentoPsicologoSaga from './cancelarAgendamentoPsicologo/saga';

export default all([
	agendarExameMedicoSaga,
	agendarExamesSaga,
	agendarExamesRenachIntegradoSaga,
	agendarExamesRenovacaoSaga,
	consultaSaga,
	dadosCidadaoSaga,
	datasExameSaga,
	datasExamePsicologicoSaga,
	horariosPorDataSaga,
	horariosPsicologoPorDataSaga,
	microRegioesSaga,
	regiaoMicroRegiaoPorCepSaga,
	regioesSaga,
	consultaAgendamentosSaga,
	cancelarAgendamentoPsicologoSaga,
]);
