export enum Types {
	ALTERA_PREFEITURA_SERVICOS_CANAIS = '@canais/ALTERA_PREFEITURA_SERVICOS_CANAIS',
	ALTERA_PREFEITURA_SERVICOS_CANAIS_SUCCESS = '@canais/ALTERA_PREFEITURA_SERVICOS_CANAIS_SUCCESS',
	ALTERA_PREFEITURA_SERVICOS_CANAIS_FAILURE = '@canais/ALTERA_PREFEITURA_SERVICOS_CANAIS_FAILURE',
	ALTERA_PREFEITURA_SERVICOS_CANAIS_CLEAR = '@canais/ALTERA_PREFEITURA_SERVICOS_CANAIS_CLEAR',
}

export interface alteraPrefeiturasServicosCanais {
	status: number;
	data: string | null;
}

export interface IRequestAlteraPrefeiturasServicosCanais {
	idPrefeituraServico: number;
	body?: any;
	situation?: string;
}
