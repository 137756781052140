import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, DadosAgendamentoState } from './types';

export const INITIAL_STATE: DadosAgendamentoState = {
	numeroProtocolo: '',
	senhaAtendimento: '',
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): DadosAgendamentoState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.DADOS_AGENDAMENTO_REQUEST: {
				draft.numeroProtocolo = action.payload.numeroProtocolo;
				draft.senhaAtendimento = action.payload.senhaAtendimento;
				break;
			}
			case Types.DADOS_AGENDAMENTO_CLEAR: {
				draft.numeroProtocolo = INITIAL_STATE.numeroProtocolo;
				draft.senhaAtendimento = INITIAL_STATE.senhaAtendimento;
				break;
			}

			default:
		}
		return draft;
	});
}
