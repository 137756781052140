import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, EmissaoAtestadoAntecedentes } from './types';

export const INITIAL_STATE: EmissaoAtestadoAntecedentes = {
	status: 0,
	data: {
		pdfBase64: '',
		nomeSocial: '',
		codigo: '',
		mensagem: '',
	},
	pesquisa: {
		dataExpedicaoRg: '',
		dataNascimento: '',
		numeroRg: '',
		digitoRg: '',
		nome: '',
		nomeMae: '',
		nomePai: '',
		sexo: '',
		tipoDocApresentado: '',
		nomeProcurador: '',
		docProcurador: '',
		numeroDocumento: '',
		ipEstacao: '',
		posto: '',
		usuario: '',
		tipoEmissao: '',
		origem: '',
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): EmissaoAtestadoAntecedentes {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EMISSAO_ATESTADO_ANTECEDENTES_REQUEST: {
				draft.pesquisa = action.payload;
				break;
			}

			case Types.EMISSAO_ATESTADO_ANTECEDENTES_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}

			case Types.EMISSAO_ATESTADO_ANTECEDENTES_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.LIMPA_ATESTADO_ANTECEDENTES: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.pesquisa = INITIAL_STATE.pesquisa;
				break;
			}

			default:
		}

		return draft;
	});
}
