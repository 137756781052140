import React, { ChangeEvent, Suspense, useEffect, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { RadioProps } from 'antd/lib/radio';
import { Field, Form, Formik, FormikProps } from 'formik';

import { escolherPlacaClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/EscolherPlaca/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';
import {
	consultaPlacaDisponivelRequest,
	consultaPlacaDisponivelClear,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/consultaPlacaDisponivel/actions';
import { saveTaxaServicoRequest } from 'store/modules/api/detranCrv/ConsultarRestricoes/saveTaxaServico/actions';
import { SaveTaxaServicoRequest } from 'store/modules/api/detranCrv/ConsultarRestricoes/saveTaxaServico/types';
import { emitirEscolhaPlacaPersonalizadaRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirEscolhaPlacaPersonalizada/actions';
import { consultarTaxaServicoClear } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/actions';

import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';
import Radio from 'components/Common/Form/Radio';

import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import { stringArrayToEnum } from 'utils/genericFunctions';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

import { initialValues, placaSchema, treatValues, schema } from './form';

const EscolherPlacaResultado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const data = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.solicitarDocumentos.escolherPlaca.data
				?.resultadoEscolhaPlacaAtribuida,
	);

	const { consultaPlacaDisponivel, emitirEscolhaPlacaPersonalizada } =
		useSelector(
			(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
		);

	const { salvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento,
	);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { saveTaxaServico, consultarTaxaServico } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	const [placaSelection, setPlacaSelection] = useState<boolean>(false);
	const [placaOptions, setPlacaOptions] = useState<RadioProps[]>([]);
	const [placaOnSubmit, setPlacaOnSubmit] = useState<boolean>(false);

	const cancelButton = () => {
		dispatch(escolherPlacaClear());
		dispatch(consultaPlacaDisponivelClear());
		dispatch(consultarTaxaServicoClear());
		setPlacaSelection(false);
		history.push('/detran-crv/escolher-placa');
	};

	useEffect(() => {
		if (emitirEscolhaPlacaPersonalizada.status === 200 && placaOnSubmit) {
			if (consultarTaxaServico.status === 200 && consultarTaxaServico.data) {
				const saveTaxaServicoPlacaPersonalizada: SaveTaxaServicoRequest = {
					codBanco: consultarTaxaServico.data.codBanco,
					codigoTaxaServico: consultarTaxaServico.data.codigoTaxaServico,
					cpfCnpj: consultarTaxaServico.data.cpfCnpj,
					dataContabil: consultarTaxaServico.data.dataContabil,
					identificadorTaxa: consultarTaxaServico.data.identificadorTaxa,
					nsuProdesp: consultarTaxaServico.data.nsuProdesp,
				};

				dispatch(saveTaxaServicoRequest(saveTaxaServicoPlacaPersonalizada));
			}
			history.push('/detran-crv/escolher-placa/resultado/confirmacao');
		}
	}, [
		placaOnSubmit,
		saveTaxaServico,
		consultarTaxaServico,
		history,
		dispatch,
		emitirEscolhaPlacaPersonalizada.status,
	]);

	useEffect(() => {
		if (
			consultaPlacaDisponivel.status === 200 &&
			consultaPlacaDisponivel.data
		) {
			setPlacaSelection(true);
			const newOptions = stringArrayToEnum(consultaPlacaDisponivel.data.placas);
			setPlacaOptions(newOptions);
		}
	}, [consultaPlacaDisponivel]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section size="sm" title="DADOS DO VEÍCULO">
				<Row gutter={[0, 20]}>
					<Col span={10}>
						<Input disabled name="chassi" title="Chassi" value={data?.chassi} />
					</Col>
					<Col span={14}>
						<Input
							disabled
							titleSize="xl"
							name="cpfCnpjProprietario"
							title="CPF/CNPJ Proprietário"
							value={data?.cpfCnpjProprietario}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={10}>
						<Input
							disabled
							name="placa"
							title="Placa Selecionada"
							value={data?.placa}
						/>
					</Col>
					<Col span={14}>
						<Input
							titleSize="xl"
							disabled
							name="quantidadeAlteracoesRestante"
							title="Quantidade de Alterações Restantes"
							value={data?.quantidadeAlteracoesRestante}
						/>
					</Col>
				</Row>
			</Section>
			<Section size="sm" title="ESCOLHER A PLACA">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, placaSchema)}
					onSubmit={async values => {
						const body = {
							dadosVeiculo: {
								chassiVeiculo: data?.chassi ? data?.chassi : '',
								cpfCnpjVeiculo: data?.cpfCnpjProprietario
									? data?.cpfCnpjProprietario
									: null,
							},
							dadosPlaca: {
								complementoPlaca: values.complementoPlaca,
								letrasPlaca: values.letrasPlaca,
							},
						};
						dispatch(consultaPlacaDisponivelRequest(body));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={8}>
										<Field
											required
											name="letrasPlaca"
											title="Letras"
											maxLength={3}
											as={Input}
											error={
												// hasError(formik.errors, 'letrasPlaca') ||
												hasError(formik.errors, 'letrasComplemento')
											}
										/>
									</Col>
									<Col span={8}>
										<Field
											required
											name="complementoPlaca"
											title="Complemento"
											as={Input}
											maxLength={4}
											error={
												// hasError(formik.errors, 'complementoPlaca') ||
												hasError(formik.errors, 'letrasComplemento')
											}
										/>
									</Col>
									<Col span={8} style={{ margin: 'auto' }}>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
									<Col span={24} style={{ textAlign: 'center' }}>
										<strong>
											A placa deve conter três letras de BGA a GKI, ou de SSR a
											SSZ, ou de STA a SWZ (opcional), e/ou uma letra e três
											números - NLNN (opcional).
										</strong>
										{data?.quantidadeAlteracoesRestante !== undefined &&
											data?.quantidadeAlteracoesRestante >= 2 && (
												<p>
													<strong style={{ color: 'red' }}>
														Veículo já possui placa selecionada. Se escolher uma
														nova placa, a anterior ficará indisponível por 30
														dias
													</strong>
												</p>
											)}
									</Col>
									{!placaSelection && (
										<Col>
											<Button onClick={() => cancelButton()}> Cancelar </Button>
										</Col>
									)}
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			{placaSelection && (
				<>
					<Section size="sm" title="PLACAS">
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={{ placa: '' }}
							validate={values => getValidationsErrors(values, schema)}
							onSubmit={async values => {
								const sguData: SguData = {
									salvarAtendimento,
									login,
								};
								const evento = getEventoConsultar(sguData);

								const valuesFormat = {
									evento,
									cpfCnpjProprietario: data?.cpfCnpjProprietario
										? data?.cpfCnpjProprietario
										: 0,
									chassi: data?.chassi ? data?.chassi : '',
									placa: values.placa,
								};
								const formatValues = treatValues(valuesFormat);

								setPlacaOnSubmit(true);
								dispatch(emitirEscolhaPlacaPersonalizadaRequest(formatValues));
							}}
						>
							{(formik: FormikProps<any>) => {
								return (
									<Form>
										<Row gutter={[0, 20]} justify="center">
											<Col span={4}>Placas</Col>
											<Col span={20}>
												<Field
													size={100}
													wrap
													name="placa"
													as={Radio}
													options={placaOptions}
													error={hasError(formik.errors, 'placa')}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														const { value } = event.target;
														formik.setFieldValue('placa', value);
													}}
												/>
											</Col>
										</Row>
										<Row
											gutter={[0, 20]}
											justify="center"
											style={{ marginTop: '10px' }}
										>
											<Col span={8} style={{ textAlign: 'center' }}>
												<Section>
													<div>
														<h3>SP - MUNICIPIO</h3>
														<h3>{formik.values.placa}</h3>
													</div>
												</Section>
											</Col>
										</Row>
										<Row justify="center" align="middle" gutter={[10, 10]}>
											<Col>
												{/* <Button onClick={() => dispatch(escolherPlacaClear())}>
													{' '}
													Cancelar{' '}
												</Button> */}
												<Button onClick={() => cancelButton()}>
													{' '}
													Cancelar{' '}
												</Button>
											</Col>
											<Col>
												<Button type="submit"> Enviar </Button>
											</Col>
										</Row>
									</Form>
								);
							}}
						</Formik>
					</Section>
				</>
			)}
			<Row align="middle">
				<Col>
					<ButtonVoltar onClick={() => cancelButton()} />
				</Col>
			</Row>
		</Suspense>
	);
};

export default EscolherPlacaResultado;
