export enum Types {
	PROTOCOLO_REUSO_REQUEST = '@iirgd/PROTOCOLO_REUSO_REQUEST',
	PROTOCOLO_REUSO_SUCCESS = '@iirgd/PROTOCOLO_REUSO_SUCCESS',
	PROTOCOLO_REUSO_FAILURE = '@iirgd/PROTOCOLO_REUSO_FAILURE',
	PROTOCOLO_REUSO_CLEAR = '@iirgd/PROTOCOLO_REUSO_CLEAR',
}

export interface IProtocoloReusoState {
	status: number;
	data: IProtocoloReuso | null;
}

export interface IProtocoloReuso {
	arquivoPDF: string;
}
