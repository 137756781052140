import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import { formasDevolucaoSuccess, formasDevolucaoFailure } from './actions';

function* formasDevolucaoRequest() {
	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`dominios-crm/${CHANNEL}/formas-devolucao`,
		{},
	);

	if (response.status === 200) {
		yield put(formasDevolucaoSuccess(response));
	} else {
		yield put(formasDevolucaoFailure(response));
	}
}

export default all([
	takeLatest(Types.FORMAS_DEVOLUCAO_REQUEST, formasDevolucaoRequest),
]);
