import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { PATH_EDUCACAO_SEDUC_DOMINIOS_API } from 'services/rotasp/path';
import { ApiResponse, getApi } from 'services/rotasp/api';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import { dadosBasicosSuccess, dadosBasicosFailure } from './actions';

function* dadosBasicosRequest(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: {} } = request;
	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_EDUCACAO_SEDUC_DOMINIOS_API,
		`dados-basicos`,
		payload,
	);
	if (response.status === 200) {
		yield put(dadosBasicosSuccess(response));
	} else if (response.error) {
		yield put(dadosBasicosFailure(response.message));
	}
}

export default all([
	takeLatest(Types.DADOS_BASICOS_REQUEST, dadosBasicosRequest),
]);
