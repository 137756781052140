import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { FormikProps, Field } from 'formik';

// COMPONENTS
import InputMask from 'components/Common/Form/Input/InputMask';

// UTILS
import hasError from 'utils/getFormErrors';

interface Props {
	title: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	name: string;
	defaultValue?: string;
	size?: number;
	disabled?: boolean;
	required?: boolean;
	readOnly?: boolean;
	maskType: 'cpf' | 'cnpj' | undefined;
	formik: FormikProps<any>;
	errorName?: string;
}

const CpfCnpj: React.FC<Props> = ({
	title,
	titleSize = 'md',
	name,
	defaultValue = '',
	size = 100,
	disabled = false,
	required = false,
	readOnly = false,
	maskType = undefined,
	formik,
	errorName = '',
}) => {
	const { setFieldValue, errors } = formik;

	const [mask, setMask] = useState<string>('');
	const [selectedCpfCnpj, setSelectedCpfCnpj] = useState<string>(defaultValue);

	useEffect(() => {
		if (maskType === 'cpf') {
			setMask('999.999.999-99');
			return;
		}

		if (maskType === 'cnpj') {
			setMask('99.999.999/9999-99');
			return;
		}

		setMask('');
	}, [maskType]);

	const handleOnChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;

			setSelectedCpfCnpj(value);
			setFieldValue(name, value);
		},
		[setSelectedCpfCnpj, setFieldValue, name],
	);

	return (
		<Field
			as={InputMask}
			title={title}
			titleSize={titleSize}
			name={name}
			value={selectedCpfCnpj}
			mask={mask}
			size={size}
			disabled={disabled || maskType === undefined}
			required={required}
			readOnly={readOnly}
			error={hasError(errors, errorName || name)}
			onChange={handleOnChange}
		/>
	);
};

export default CpfCnpj;
