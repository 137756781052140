import { ReducerAction } from 'store/modules/types';

import {
	SalvaSenhaUsuarioRequest,
	SalvaSenhaUsuarioResponse,
	Types,
} from './types';

export function salvaSenhaUsuarioRequest(
	data: SalvaSenhaUsuarioRequest,
): ReducerAction {
	return {
		type: Types.SALVA_SENHA_USUARIO_REQUEST,
		payload: data,
	};
}
export function salvaSenhaUsuarioSuccess(
	payload: SalvaSenhaUsuarioResponse,
): ReducerAction {
	return {
		type: Types.SALVA_SENHA_USUARIO_SUCCESS,
		payload,
	};
}
export function salvaSenhaUsuarioFailure(payload: string): ReducerAction {
	return {
		type: Types.SALVA_SENHA_USUARIO_FAILURE,
		payload,
	};
}
export function salvaSenhaUsuarioClear(): { type: string } {
	return {
		type: Types.SALVA_SENHA_USUARIO_CLEAR,
	};
}
