import React from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

// REDUX
import data from 'store/modules/api/mp/enum';

// COMPONENTS
import FormBox from 'components/Common/Form/FormBox';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask/index';

// FORM
import hasError from 'utils/getFormErrors';
import Radio from 'components/Common/Form/Radio';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import { onlyLetters, verificarMaioridade } from 'utils/genericFunctions';
import { ICadastroSolicitante } from '../form';
import DadosSolicitante from '../DadosSolicitante';

interface Props {
	formik: FormikProps<ICadastroSolicitante>;
	step: number;
	formDisabled: boolean;
}

const DadosInteressado: React.FC<Props> = ({ formik, step, formDisabled }) => {
	const { setFieldValue } = formik;

	return (
		<>
			<FormBox
				title={`Dados do Interessado\\Filho ${
					formik.values.tipoSolicitante === 1 ? ' - Solicitante' : ''
				}`}
			>
				<Row gutter={[0, 10]}>
					<Col span={16}>
						<Field
							as={Input}
							title="Nome Completo"
							required
							maxLength={60}
							value={onlyLetters(formik.values.filho.nome)}
							name="filho.nome"
							error={hasError(formik.errors, 'filho.nome')}
							readOnly={step === 2}
							disabled={formDisabled}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={10}>
						<Field
							required
							title="Data de Nascimento"
							name="filho.dtNascimento"
							type="text"
							mask="99/99/9999"
							subtitle={step === 2 ? '' : '(DD/MM/AAAA)'}
							size={60}
							as={ValidDataInput}
							error={hasError(formik.errors, 'filho.dtNascimento')}
							formik={formik}
							onChange={(v: string) => setFieldValue('filho.dtNascimento', v)}
							readOnly={step === 2}
							disabled={formDisabled}
						/>
					</Col>
					<Col span={6}>
						{step === 1 && (
							<Field
								required
								as={Radio}
								titleSize="sm"
								options={data.sexo}
								title="Sexo"
								defaultValue={formik.values.filho.sexo}
								name="filho.sexo"
								error={hasError(formik.errors, 'filho.sexo')}
								disabled={formDisabled}
							/>
						)}
						{step === 2 && (
							<Field
								required
								as={Input}
								title="Sexo"
								titleSize="sm"
								name="filho.sexo"
								disabled={formDisabled}
								value={
									formik.values.filho.sexo === 'M' ? 'MASCULINO' : 'FEMININO'
								}
								readOnly={step === 2}
							/>
						)}
					</Col>
					{((!verificarMaioridade(formik.values.filho.dtNascimento) &&
						step === 1) ||
						verificarMaioridade(formik.values.filho.dtNascimento)) && (
						<Col span={8}>
							<Field
								required={
									verificarMaioridade(formik.values.filho.dtNascimento) ||
									formik.values.tipoSolicitante === 1
								}
								title="CPF"
								name="filho.cpf"
								as={InputMask}
								mask="999.999.999-99"
								error={hasError(formik.errors, 'filho.cpf')}
								disabled={
									!verificarMaioridade(formik.values.filho.dtNascimento) ||
									formDisabled
								}
								readOnly={step === 2}
							/>
						</Col>
					)}
				</Row>
				{(formik.values.tipoSolicitante === 1 ||
					(verificarMaioridade(formik.values.filho.dtNascimento) &&
						formik.values.tipoSolicitante === 3) ||
					(verificarMaioridade(formik.values.filho.dtNascimento) &&
						formik.values.tipoSolicitante === 2)) && (
					// eslint-disable-next-line react/jsx-indent
					<DadosSolicitante
						formik={formik}
						step={step}
						solicitante="filho"
						formDisabled={formDisabled}
					/>
				)}
			</FormBox>
			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default DadosInteressado;
