import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaAgendamentosRequest } from './types';

export function consultaAgendamentosRequest(
	data: ConsultaAgendamentosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_AGENDAMENTOS_REQUEST,
		payload: data,
	};
}
export function consultaAgendamentosSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_AGENDAMENTOS_SUCCESS,
		payload,
	};
}
export function consultaAgendamentosFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_AGENDAMENTOS_FAILURE,
		payload,
	};
}
export function consultaAgendamentosClear(): ReducerAction {
	return {
		type: Types.CONSULTA_AGENDAMENTOS_CLEAR,
		payload: null,
	};
}
