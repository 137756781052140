import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

// LOGOS

// import logoPoupaTempoLight from 'assets/images/logo.png';
import logoPoupaTempo from 'assets/images/marca_poupatempo_balcao_unico.png';

import logoPoupaTempoDark from 'assets/images/logoInverted.png';

import logo_educacao from 'assets/images/logos/logo_secretaria_edu.png';

import logoPoliciaCivilLight from 'assets/images/logos/logo_policiaCivil.png';
import logoPoliciaCivilDark from 'assets/images/logos/logo_policiaCivil_invertido.png';

import logoMinisterioPublicoLight from 'assets/images/logos/logo_ministerioPublico.png';

import logoDetranLight from 'assets/images/logos/logo_detran.png';
import logoDetranDark from 'assets/images/logos/logo_detran_invertido.png';

import logoSedsLight from 'assets/images/logos/logoSeds.png';
import logoPrefeiturasLight from 'assets/images/logos/logoPrefeitura.png';

import logoCdhuLight from 'assets/images/logos/logo_cdhu.png';

import logoProconLight from 'assets/images/logos/logoProcon.png';

import logoPoupatempoLight from 'assets/images/logos/logoPoupatempo.png';

import { useHistory } from 'react-router-dom';

interface Props {
	src: number;
}

const Logo: React.FC<Props> = ({ src = 0 }) => {
	const { title } = useContext(ThemeContext);
	const history = useHistory();

	let logo;

	if (title === 'light') {
		switch (src) {
			case 0: {
				logo = logoPoupaTempo;
				break;
			}

			case 1: {
				logo = logoPoliciaCivilLight;
				break;
			}

			case 2: {
				logo = logoDetranLight;
				break;
			}

			case 3: {
				logo = logoMinisterioPublicoLight;
				break;
			}

			case 4: {
				logo = logoCdhuLight;
				break;
			}

			case 5: {
				logo = logoDetranLight;
				break;
			}

			case 6: {
				logo = logo_educacao;
				break;
			}

			case 7: {
				logo = logoSedsLight;
				break;
			}

			case 8: {
				logo = logoProconLight;
				break;
			}

			case 10: {
				logo = logoPrefeiturasLight;
				break;
			}

			case 12: {
				logo = logoPoupatempoLight;
				break;
			}

			case 13: {
				logo = logoPoupatempoLight;
				break;
			}
			default:
		}
	} else if (title === 'dark') {
		switch (src) {
			case 0: {
				logo = logoPoupaTempoDark;
				break;
			}

			case 1: {
				logo = logoPoliciaCivilDark;
				break;
			}

			case 2: {
				logo = logoDetranDark;
				break;
			}

			case 3: {
				logo = logoMinisterioPublicoLight;
				break;
			}

			case 4: {
				logo = logoCdhuLight;
				break;
			}

			case 5: {
				logo = logoDetranDark;
				break;
			}

			case 6: {
				logo = logo_educacao;
				break;
			}

			case 7: {
				logo = logoSedsLight;
				break;
			}

			case 10: {
				logo = logoPrefeiturasLight;
				break;
			}

			case 12: {
				logo = logoPoupatempoLight;
				break;
			}

			case 13: {
				logo = logoPoupatempoLight;
				break;
			}
			default:
		}
	}

	return (
		<div
			style={{ margin: 'auto 0', cursor: 'pointer' }}
			onClick={() => history.push('/menu-orgaos')}
		>
			<img src={logo} alt="Logo Poupa Tempo" style={{ maxHeight: '56px' }} />
		</div>
	);
};

export default Logo;
