import { takeLatest, put, all } from 'redux-saga/effects';

import { ReducerAction } from 'store/modules/types';
import { Types, IDadosAluno } from './types';
import { mantemDadosAlunoSuccess, mantemDadosAlunoFailure } from './actions';

function* mantemDadosAlunoRequest(request: ReducerAction) {
	const { payload }: { payload: IDadosAluno } = request;
	const response = {
		data: {
			aluno: {
				numeroRA: payload.data.aluno.numeroRA,
				digitoRA: payload.data.aluno.digitoRA,
				siglaUFRA: payload.data.aluno.siglaUFRA,
			},
		},
	};

	if (payload.data.aluno.numeroRA !== undefined) {
		yield put(mantemDadosAlunoSuccess(response));
	}
	if (payload.data.aluno.numeroRA === undefined) {
		yield put(mantemDadosAlunoFailure());
	}
}

export default all([
	takeLatest(Types.MANTEM_DADOS_ALUNO_REQUEST, mantemDadosAlunoRequest),
]);
