import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function consultarTiposLogradourosRequest() {
	return {
		type: Types.CONSULTAR_TIPOS_LOGRADOUROS_REQUEST,
	};
}
export function consultarTiposLogradourosSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_TIPOS_LOGRADOUROS_SUCCESS,
		payload,
	};
}
export function consultarTiposLogradourosFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_TIPOS_LOGRADOUROS_FAILURE,
		payload: null,
	};
}

export function limparconsultarTiposLogradouros(): any {
	return {
		type: Types.LIMPAR_CONSULTAR_TIPOS_LOGRADOUROS,
	};
}
