import { CancelarIntencaoVendaRequest, Types } from './types';

export function cancelarIntencaoVendaRequest(
	token: string,
	payload: CancelarIntencaoVendaRequest,
) {
	return {
		type: Types.CANCELAR_INTENCAO_VENDA_REQUEST,
		token,
		payload,
	};
}
export function cancelarIntencaoVendaSuccess(payload: any) {
	return {
		type: Types.CANCELAR_INTENCAO_VENDA_SUCCESS,
		payload,
	};
}
export function cancelarIntencaoVendaFailure(payload: []): any {
	return {
		type: Types.CANCELAR_INTENCAO_VENDA_FAILURE,
		payload,
	};
}
export function cancelarIntencaoVendaClear(): any {
	return {
		type: Types.CANCELAR_INTENCAO_VENDA_CLEAR,
	};
}
