import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, GerarRenachSegundaViaRequest } from './types';

// ACTIONS
import {
	gerarRenachSegundaViaSuccess,
	gerarRenachSegundaViaFailure,
} from './actions';

function* gerarRenachSegundaVia(request: ReducerAction) {
	const { payload }: { payload: GerarRenachSegundaViaRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`sockets/${CHANNEL}/gerar-renach-segunda-via`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(gerarRenachSegundaViaSuccess({ response, request: payload }));
	} else if (response.error) {
		yield put(gerarRenachSegundaViaFailure(response.error));
	}
}

export default all([
	takeLatest(Types.GERAR_RENACH_SEGUNDA_VIA_REQUEST, gerarRenachSegundaVia),
]);
