import { ReducerAction } from 'store/modules/types';

import { ConsultarParametrosLocalidadeRequest, Types } from './types';

export function consultarParametrosLocalidadeRequest(
	payload: ConsultarParametrosLocalidadeRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_PARAMETROS_LOCALIDADE,
		payload,
	};
}
export function consultarParametrosLocalidadeSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_PARAMETROS_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function consultarParametrosLocalidadeFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_PARAMETROS_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function consultarParametrosLocalidadeClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_PARAMETROS_LOCALIDADE_CLEAR,
		payload: null,
	};
}
