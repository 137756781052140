export enum Types {
	CONSULTAR_CADASTRO_BIN_RENAVAM_REQUEST = '@DETRAN-CRV/CONSULTAR_CADASTRO_BIN_RENAVAM_REQUEST',
	CONSULTAR_CADASTRO_BIN_RENAVAM_SUCCESS = '@DETRAN-CRV/CONSULTAR_CADASTRO_BIN_RENAVAM_SUCCESS',
	CONSULTAR_CADASTRO_BIN_RENAVAM_FAILURE = '@DETRAN-CRV/CONSULTAR_CADASTRO_BIN_RENAVAM_FAILURE',
	CONSULTAR_CADASTRO_BIN_RENAVAM_CLEAR = '@DETRAN-CRV/CONSULTAR_CADASTRO_BIN_RENAVAM_CLEAR',
}

export interface consultarCadastroBinRenavam {
	status: number;
	data: {
		dadosBinRenavam: {
			anoFabricacao: number;
			anoModelo: number;
			capacidadePassageiro: number;
			capacidadeCarga: number;
			chassi: string;
			cilindrada: number;
			cmt: number;
			eixo: number;
			numeroMotor: string;
			pbt: number;
			placa: string;
			potencia: number;
			procedencia: string;
			renavam: number;
			situacaoVeiculo: string;
			tipoRemarcacao: string;
			uf: string;
			cpfCnpjFaturado: number;
			cpfCnpjProprietario: number;
			carroceria: {
				longId: number;
				descricao: string;
			};
			combustivel: {
				longId: number;
				descricao: string;
			};
			cor: {
				longId: number;
				descricao: string;
			};
			especie: {
				longId: number;
				descricao: string;
			};
			marca: {
				longId: number;
				descricao: string;
			};
			municipio: {
				codigo: number;
				nome: string;
			};
			tipo: {
				longId: number;
				descricao: string;
			};
			restricoes: string[];
			gravame: {
				tipoTransacao: string;
				restricao: string;
				tipoRestricao: string;
				agenteFinanceiro: string;
				nomeFinanciado: string;
				cpfCnpjFinanciado: number;
				codigoFinanceira: number;
				arrendatarioFinanceira: string;
				numeroContrato: string;
				dataInclusao: string;
				dataVigencia: string;
			};
		};
	};
}

export interface IRequestConsultarCadastroBinRenavam {
	chassi: string;
	placa: string;
	renavam: string | number | null;
}
