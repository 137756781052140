export enum Types {
	CONSULTA_CEP_REQUEST = '@cnh/CONSULTA_CEP_REQUEST',
	CONSULTA_CEP_SUCCESS = '@cnh/CONSULTA_CEP_SUCCESS',
	CONSULTA_CEP_FAILURE = '@cnh/CONSULTA_CEP_FAILURE',
	CONSULTA_CEP_CLEAR = '@cnh/CONSULTA_CEP_CLEAR',
}

export interface ConsultaCEP {
	status: number;
	data: null | ConsultaCEPData;
}

export interface ConsultaCEPData {
	cep: string;
	bairro: string;
	tipoLogradouro: string;
	endereco: string;
	uf: string;
	municipio: string;
	numeroIBGE: number;
	codigoMunicipio: number;
	codigoUnidadeTransito: number;
}

export interface RequestConsultaCEP {
	cep: string;
}
