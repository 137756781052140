import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarMensagemRequest } from './types';

// ACTIONS
import { cadastrarMensagemSuccess, cadastrarMensagemFailure } from './actions';

function* cadastrarMensagem(request: ReducerAction) {
	const { payload }: { payload: CadastrarMensagemRequest } = request;

	const body = { ...payload };

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU,
		`/mensagens`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarMensagemSuccess(response));
	} else {
		yield put(cadastrarMensagemFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_MENSAGEM_REQUEST, cadastrarMensagem),
]);
