import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_UTILS, CHANNEL } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import { paisSuccess, paisFailure } from './actions';

function* paisRequest(request: ReducerAction) {
	const { payload }: { payload: string } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_UTILS,
		`endereco/${CHANNEL}/pais/${payload}`,
	);

	if (response.status === 200 || response.status === 202) {
		yield put(paisSuccess(response.data));
	} else {
		yield put(paisFailure([]));
	}
}

export default all([takeLatest(Types.PAIS, paisRequest)]);
