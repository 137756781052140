import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, GeraSenha } from './types';

export const INITIAL_STATE: GeraSenha = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): GeraSenha {
	return produce(state, draft => {
		switch (action.type) {
			case Types.GERA_SENHA_REQUEST: {
				break;
			}

			case Types.GERA_SENHA_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = action.payload.status;
				break;
			}

			case Types.GERA_SENHA_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.GERA_SENHA_CLEAR: {
				draft.status = 0;
				draft.data = null;
				break;
			}

			default:
		}
	});
}
