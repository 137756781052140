export enum Types {
	CONSULTAR_TEMPORALIDADE_REQUEST = '@cnh/CONSULTAR_TEMPORALIDADE_REQUEST',
	CONSULTAR_TEMPORALIDADE_SUCCESS = '@cnh/CONSULTAR_TEMPORALIDADE_SUCCESS',
	CONSULTAR_TEMPORALIDADE_FAILURE = '@cnh/CONSULTAR_TEMPORALIDADE_FAILURE',
	CONSULTAR_TEMPORALIDADE_CLEAR = '@cnh/CONSULTAR_TEMPORALIDADE_CLEAR',
}

export interface IConsultarTemporalidadeState {
	status: number;
	data: IConsultarTemporalidade | null;
}

interface IConsultarTemporalidade {
	dadosBiometricos: DadosBiometricos[];
	valida: boolean;
}

interface DadosBiometricos {
	posicaoDedo: string;
}

export interface IRequestConsultarTemporalidade {
	cpf: string;
}
