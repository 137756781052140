import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_CDHU_MUTUARIOS_API } from 'services/rotasp/path';

// UTILS
import { treatObjNullValues } from 'utils/genericFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { SimularEftuarAcordoRequest, Types } from './types';

// ACTIONS
import {
	simularEfetuarAcordoSuccess,
	simularEfetuarAcordoFailure,
} from './actions';

function* simularAcordoRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: SimularEftuarAcordoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_CDHU_MUTUARIOS_API,
		`acordos`,
		payload,
	);

	console.log('Dados de retorno: ', response);

	if (response.status === 200) {
		const data = treatObjNullValues(response.data);

		yield put(simularEfetuarAcordoSuccess(data));
	} else {
		yield put(simularEfetuarAcordoFailure(response));
	}
}

export default all([
	takeLatest(Types.SIMULAR_EFETUAR_ACORDO_REQUEST, simularAcordoRequest),
]);
