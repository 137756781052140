import styled, { css } from 'styled-components';

import { Table } from 'antd';

type PropsTitle = {
	underline?: boolean;
};

export const Title = styled.h4<PropsTitle>`
	font-size: calc(1.2rem + ${props => props.theme.fontScale}%);
	font-weight: 500;
	text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`;

export const LabelTickets = styled.h4<PropsTitle>`
	font-size: calc(0.7rem + ${props => props.theme.fontScale}%);
	font-weight: 500;
	text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
	padding-bottom: 10px;
	color: ${props => props.theme.typography.primary};
`;

export const LabelTotalTicketsPoints = styled.h4<PropsTitle>`
	font-size: calc(0.7rem + ${props => props.theme.fontScale}%);
	font-weight: 500;
	text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
	padding-top: 10px;
	color: #ff0000;
`;

export const CustomizedTable = styled(Table)`
	.ant-table-wrapper {
		width: 100%;
	}

	.ant-table {
		color: ${props => props.theme.typography.primary};

		${props =>
			props.theme.title === 'light'
				? css`
						background-color: #ffff;
				  `
				: css`
						background-color: #000000;
				  `}

		table {
			border-spacing: 2px;

			.ant-table-thead {
				tr {
					th {
						background: none;
						white-space: nowrap;
						text-align: center;
						padding: 5px;
						border: 1px solid #d0c2c9;
						color: ${props => props.theme.typography.primary};
						font-weight: 500;

						${props =>
							props.theme.title === 'light'
								? css`
										background-color: #e1dfe0;
								  `
								: css`
										background-color: #000000;
								  `}
					}
				}
			}

			.ant-table-tbody {
				${props =>
					props.theme.title === 'light'
						? css`
								background-color: #ffff;
						  `
						: css`
								background-color: #000000;
						  `}
				td {
					padding: 0;
					text-align: center;
					color: ${props => props.theme.typography.primary};
					border-bottom: none;
				}
			}

			.ant-table-tbody > tr.ant-table-row:hover > td {
				background: none !important;
			}
		}
	}
`;
