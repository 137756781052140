// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaAnexosConsumidorRequest, Types } from './types';

export function consultaAnexosConsumidorRequest(
	payload: ConsultaAnexosConsumidorRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_ANEXOS_CONSUMIDOR_REQUEST,
		payload,
	};
}
export function consultaAnexosConsumidorSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_ANEXOS_CONSUMIDOR_SUCCESS,
		payload,
	};
}
export function consultaAnexosConsumidorFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_ANEXOS_CONSUMIDOR_FAILURE,
		payload,
	};
}
export function consultaAnexosConsumidorClear(): ReducerAction {
	return {
		type: Types.CONSULTA_ANEXOS_CONSUMIDOR_CLEAR,
		payload: null,
	};
}
