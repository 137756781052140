import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_ATPVE_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, IncluirIntencaoVendaRequest } from './types';

// ACTIONS
import {
	incluirIntencaoVendaSuccess,
	incluirIntencaoVendaFailure,
} from './actions';

function* incluirIntencaoVendaRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IncluirIntencaoVendaRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_ATPVE_API,
		`intencoes-vendas`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(incluirIntencaoVendaSuccess(response));
	} else {
		yield put(incluirIntencaoVendaFailure(response));
	}
}

export default all([
	takeLatest(Types.INCLUIR_INTENCAO_VENDA_REQUEST, incluirIntencaoVendaRequest),
]);
