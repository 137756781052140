import { ReducerAction } from 'store/modules/types';

import { Types, ConsultarMensagensErroRequest } from './types';

export function consultarMensagensErroRequest(
	data: ConsultarMensagensErroRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_MENSAGENS_ERRO_REQUEST,
		payload: data,
	};
}
export function consultarMensagensErroSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_MENSAGENS_ERRO_SUCCESS,
		payload,
	};
}
export function consultarMensagensErroFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_MENSAGENS_ERRO_FAILURE,
		payload: null,
	};
}
export function consultarMensagensErroClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_MENSAGENS_ERRO_CLEAR,
		payload: null,
	};
}
