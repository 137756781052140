// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, CadastrarSolicitanteRequest,  } from './types';

export function cadastrarSolicitanteRequest(
	token: string,
	payload: CadastrarSolicitanteRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_SOLICITANTE_REQUEST,
		token,
		payload,
	};
}
export function cadastrarSolicitanteSuccess(payload: any): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_SOLICITANTE_SUCCESS,
		payload,
	};
}
export function cadastrarSolicitanteFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_SOLICITANTE_FAILURE,
		payload: null,
	};
}
export function cadastrarSolicitanteClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_SOLICITANTE_CLEAR,
		payload: null,
	};
}
