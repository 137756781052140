import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, ListarResponsaveis } from './types';

export const INITIAL_STATE: ListarResponsaveis = {
	status: 0,
	data: null,
	consultaData: null,
};

export default function listarResponsaveis(
	state = INITIAL_STATE,
	action: ReducerAction,
): ListarResponsaveis {
	return produce(state, draft => {
		switch (action.type) {
			case Types.LISTAR_RESPONSAVEIS_REQUEST: {
				draft.consultaData = action.payload;
				break;
			}
			case Types.LISTAR_RESPONSAVEIS_SUCCESS: {
				draft.data = action.payload.response.data;
				draft.status = action.payload.response.status;
				break;
			}
			case Types.LISTAR_RESPONSAVEIS_FAILURE: {
				draft.status = 400;
				break;
			}
			case Types.LISTAR_RESPONSAVEIS_CLEAN: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.consultaData = INITIAL_STATE.consultaData;
				break;
			}
			default:
		}
	});
}
