import * as Yup from 'yup';

// REDUX
import { CadastraAtendimentoPfRequest } from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPf/types';
import { CadastraAtendimentoPjRequest } from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPj/types';

// ENUMS
import { Consts } from 'store/modules/api/procon/enum';

export const initialValues: CadastraAtendimentoPfRequest = {
	fornecedorId: '',
	consumidorId: '',
	eventoEspecialId: Consts.EVENTO_ESPECIAL_DEFAULT,
	areaId: '',
	assuntoId: '',
	grupoDeProblemaId: '',
	problemaId: '',
	procureiOFornecedorParaResolverAQuestao: false,
	descricaoDoPrimeiroContatoComFornecedor: '',
	participacaoEmAudiencia: '',
	formaDeAquisicao: '',
	tipoDeContratacao: '',
	dataDaContratacao: '',
	nomeDoProdutoOuServico: '',
	detalhesDoProdutoOuServico: '',
	tipoDoDocumento: '',
	numeroDoDocumento: '',
	dataDaOcorrenciaOuDoServico: '',
	dataDeCancelamentoDesistenciaOuNegativa: '',
	formaDePagamento: '',
	valorDaCompra: '',
	detalhesDaReclamacao: '',
	pedido: '',
	formaDeDevolucao: '',
	anexos: [],
	nomeDoResponsavelOuProcurador: '',
	cpfDoProcuradorOuResponsavel: '',
	rgDoResponsavelOuProcurador: '',
	cnpjDoConsumidor: '',
	dataDaOcorrenciaOuServico: '',
	tipoConsumidor: null,
	consumidorNome: '',
	fornecedorNome: '',
	temProcurador: false,
	isEventoSazonal: false,
	novoFornecedor: {
		tipo: '',
		cnpj: '',
		cpf: '',
		email: '',
		razaoSocial: '',
		nomeFantasia: '',
		website: '',
		bairro: '',
		cep: '',
		complemento: '',
		estado: 0,
		logradouro: '',
		municipioId: '',
		municipioDescricao: '',
		numero: '',
		telefone: '',
		celular: '',
	},
	// NOVOS CAMPOS (COMPRA / DEVOLUÇÃO)
	compraAgencia: '',
	compraBancoEmissor: '',
	compraBandeiraDoCartao: '',
	compraChavePix: '',
	compraConta: '',
	compraIntermediadoraDePagamento: '',
	compraNumeroDoCartao: '',
	compraTipoDeChavePix: '',
	devolucaoAgencia: '',
	devolucaoBancoEmissorDoCartao: '',
	devolucaoBandeiraDoCartao: '',
	devolucaoChavePix: '',
	devolucaoConta: '',
	devolucaoNomeDoBanco: '',
	devolucaoNumeroDeParcelas: 0,
	devolucaoNumeroDoCartao: '',
	devolucaoTipoDeChavePix: '',
	devolucaoValorDaParcela: 0,
	idDoBalcaoUnico: '',
	descricaoDoPedidoDoConsumidor: '',
};

export const initialValuesPj: CadastraAtendimentoPjRequest = {
	cnpjDoConsumidor: '',
	fornecedorId: '',
	consumidorId: '',
	eventoEspecialId: Consts.EVENTO_ESPECIAL_DEFAULT,
	areaId: '',
	assuntoId: '',
	grupoDeProblemaId: '',
	problemaId: '',
	procureiOFornecedorParaResolverAQuestao: true,
	participacaoEmAudiencia: 0,
	formaDeAquisicao: 0,
	tipoDeContratacao: 0,
	dataDaContratacao: '',
	nomeDoProdutoOuServico: '',
	detalhesDoProdutoOuServico: '',
	tipoDoDocumento: 0,
	numeroDoDocumento: '',
	dataDaOcorrenciaOuDoServico: '',
	dataDeCancelamentoDesistenciaOuNegativa: '',
	formaDePagamento: 0,
	valorDaCompra: 0,
	detalhesDaReclamacao: '',
	pedido: 0,
	formaDeDevolucao: 0,
	anexos: [],
	nomeDoResponsavelOuProcurador: '',
	cpfDoProcuradorOuResponsavel: '',
	rgDoResponsavelOuProcurador: '',
	compraAgencia: '',
	compraBancoEmissor: '',
	compraBandeiraDoCartao: '',
	compraChavePix: '',
	compraConta: '',
	compraIntermediadoraDePagamento: '',
	compraNumeroDoCartao: '',
	compraTipoDeChavePix: 0,
	devolucaoAgencia: '',
	devolucaoBancoEmissorDoCartao: '',
	devolucaoBandeiraDoCartao: '',
	devolucaoChavePix: '',
	devolucaoConta: '',
	devolucaoNomeDoBanco: '',
	devolucaoNumeroDeParcelas: 0,
	devolucaoNumeroDoCartao: '',
	devolucaoTipoDeChavePix: 0,
	devolucaoValorDaParcela: 0,
	novoFornecedor: {
		tipo: '',
		cnpj: '',
		cpf: '',
		email: '',
		razaoSocial: '',
		nomeFantasia: '',
		website: '',
		bairro: '',
		cep: '',
		complemento: '',
		estado: 0,
		logradouro: '',
		municipioId: '',
		municipioDescricao: '',
		numero: '',
		telefone: '',
		celular: '',
	},
	idDoBalcaoUnico: '',
	descricaoDoPedidoDoConsumidor: '',
	temProcurador: false,
	isEventoSazonal: false,
	dataDaOcorrenciaOuServico: '',
	descricaoDoPrimeiroContatoComFornecedor: '',
	tipoConsumidor: null,
	consumidorNome: '',
	fornecedorNome: '',
};

export function convertEmptyToNull(values: object): any {
	if (!values) {
		return null;
	}
	const newData: any = {};

	Object.entries(values).forEach(([key, value]) => {
		newData[key] = value === '' || value === 0 ? null : value;
	});

	return newData;
}

export function creditCardLabel(cardNumber: string): string {
	if (!cardNumber) {
		return '';
	}
	const newCardNumber: string = `${cardNumber.substring(
		0,
		7,
	)}XX XXXX${cardNumber.substring(14)}`;

	return newCardNumber;
}

export const schema = Yup.object<CadastraAtendimentoPfRequest>().shape({
	validacaoFornecedor: Yup.string().when(['fornecedorId', 'novoFornecedor'], {
		is: (fornecedorId, novoFornecedor) =>
			fornecedorId === '' && novoFornecedor.razaoSocial === '',
		then: Yup.string().required('Campo Obrigatório: Fornecedor.'),
	}),
	consumidorId: Yup.string().required('Campo Obrigatório: Consumidor.'),
	areaId: Yup.string().required(
		'Campo Obrigatório: Classificação da Solicitação: "Área."',
	),
	assuntoId: Yup.string().when(['areaId'], {
		is: areaId => areaId !== '',
		then: Yup.string().required(
			'Campo Obrigatório: Classificação da Solicitação: "Assunto."',
		),
	}),
	grupoDeProblemaId: Yup.string().when(['areaId', 'assuntoId'], {
		is: (areaId, assuntoId) => areaId !== '' && assuntoId !== '',
		then: Yup.string().required(
			'Campo Obrigatório: Classificação da Solicitação: "Grupo de Problema."',
		),
	}),
	problemaId: Yup.string().when(['areaId', 'assuntoId', 'grupoDeProblemaId'], {
		is: (areaId, assuntoId, grupoDeProblemaId) =>
			areaId !== '' && assuntoId !== '' && grupoDeProblemaId !== '',
		then: Yup.string().required(
			'Campo Obrigatório: Classificação da Solicitação: "Problema."',
		),
	}),
	eventoEspecialId: Yup.string().required(
		'Campo Obrigatório: Evento Especial/Sazional.',
	),
	descricaoDoPrimeiroContatoComFornecedor: Yup.string().when(
		['procureiOFornecedorParaResolverAQuestao'],
		{
			is: procureiOFornecedorParaResolverAQuestao =>
				procureiOFornecedorParaResolverAQuestao !== false,
			then: Yup.string().min(
				50,
				'Campo Obrigatório: Informe como foi o seu contato com a empresa e o número do protocolo.',
			),
		},
	),
	participacaoEmAudiencia: Yup.string().required(
		'Campo Obrigatório: Participação em Audiência.',
	),
	formaDeAquisicao: Yup.string().required(
		'Campo Obrigatório: Forma de Aquisição.',
	),
	// tipoDeContratacao: Yup.string().required(
	// 	'Campo Obrigatório: Tipo de Contratação.',
	// ),
	validacaoTipoDeContratacao: Yup.string().when(
		['formaDeAquisicao', 'tipoDeContratacao'],
		{
			is: (formaDeAquisicao, tipoDeContratacao) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				tipoDeContratacao === '',
			then: Yup.string().required('Campo Obrigatório: Tipo de Contratação.'),
		},
	),
	// dataDaContratacao: Yup.string().required(
	// 	'Campo Obrigatório: Data da Contratação.',
	// ),
	validacaoDataDaContratacao: Yup.string().when(
		['formaDeAquisicao', 'dataDaContratacao'],
		{
			is: (formaDeAquisicao, dataDaContratacao) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				dataDaContratacao === '',
			then: Yup.string().required('Campo Obrigatório: Data da Contratação.'),
		},
	),
	// nomeDoProdutoOuServico: Yup.string().min(
	// 	3,
	// 	'Campo Obrigatório: Nome do Produto ou Serviço. (Preencher no Mínimo 3 caracteres)',
	// ),
	validacaoNomeDoProdutoOuServico: Yup.string().when(
		['formaDeAquisicao', 'nomeDoProdutoOuServico'],
		{
			is: (formaDeAquisicao, nomeDoProdutoOuServico) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				nomeDoProdutoOuServico === '',
			then: Yup.string().min(
				3,
				'Campo Obrigatório: Nome do Produto ou Serviço. (Preencher no Mínimo 3 caracteres).',
			),
		},
	),
	// detalhesDoProdutoOuServico: Yup.string().min(
	// 	50,
	// 	'Campo Obrigatório: Descreva detalhes do produto ou serviço. (Preencher no Mínimo 50 caracteres)',
	// ),
	validacaoDetalhesDoProdutoOuServico: Yup.string().when(
		['formaDeAquisicao', 'detalhesDoProdutoOuServico'],
		{
			is: (formaDeAquisicao, detalhesDoProdutoOuServico) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				detalhesDoProdutoOuServico === '',
			then: Yup.string().min(
				50,
				'Campo Obrigatório: Descreva detalhes do produto ou serviço. (Preencher no Mínimo 50 caracteres).',
			),
		},
	),
	descricaoDoPedidoDoConsumidor: Yup.string().when(['pedido'], {
		is: pedido => pedido === '39' || pedido === 39,
		then: Yup.string().min(
			50,
			'Campo Obrigatório: Descreva o pedido do consumidor. (Preencher no Mínimo 50 caracteres)',
		),
	}),
	// tipoDoDocumento: Yup.string().required(
	// 	'Campo Obrigatório: Tipo de Documento.',
	// ),
	validacaoTipoDoDocumento: Yup.string().when(
		['formaDeAquisicao', 'tipoDoDocumento'],
		{
			is: (formaDeAquisicao, tipoDoDocumento) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				tipoDoDocumento === '',
			then: Yup.string().required('Campo Obrigatório: Tipo de Documento.'),
		},
	),
	// numeroDoDocumento: Yup.string().min(
	// 	1,
	// 	'Campo Obrigatório: Número do Documento.',
	// ),
	validacaoNumeroDoDocumento: Yup.string().when(
		['formaDeAquisicao', 'numeroDoDocumento'],
		{
			is: (formaDeAquisicao, numeroDoDocumento) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				numeroDoDocumento === '',
			then: Yup.string().min(1, 'Campo Obrigatório: Número do Documento.'),
		},
	),
	dataDaOcorrenciaOuDoServico: Yup.string().when(['formaDeAquisicao'], {
		is: formaDeAquisicao => formaDeAquisicao !== 4 && formaDeAquisicao !== '4',
		then: Yup.string().required('Campo Obrigatório: Data da Ocorrência.'),
	}),
	dataDeCancelamentoDesistenciaOuNegativa: Yup.string().when(
		['formaDeAquisicao'],
		{
			is: formaDeAquisicao =>
				formaDeAquisicao !== 4 && formaDeAquisicao !== '4',
			then: Yup.string().required(
				'Campo Obrigatório: Data de Cancelamento, Desistência ou Negativa.',
			),
		},
	),
	// formaDePagamento: Yup.string().required(
	// 	'Campo Obrigatório: Formas de Pagamento.',
	// ),
	validacaoFormaDePagamento: Yup.string().when(
		['formaDeAquisicao', 'formaDePagamento'],
		{
			is: (formaDeAquisicao, formaDePagamento) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				formaDePagamento === '',
			then: Yup.string().required('Campo Obrigatório: Formas de Pagamento.'),
		},
	),
	// valorDaCompra: Yup.string().required(
	// 	'Campo Obrigatório: Valor Da contratação.',
	// ),
	validacaoValorDaCompra: Yup.string().when(
		['formaDeAquisicao', 'valorDaCompra'],
		{
			is: (formaDeAquisicao, valorDaCompra) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				valorDaCompra === '',
			then: Yup.string().required('Campo Obrigatório: Valor da contratação.'),
		},
	),
	detalhesDaReclamacao: Yup.string().min(
		50,
		'Campo Obrigatório: Descreva detalhes da reclamação. (Preencher no Mínimo 50 caracteres)',
	),
	pedido: Yup.string().required('Campo Obrigatório: Pedido.'),
	validacaoFormaDeDevolucao: Yup.string().when(['pedido', 'formaDeDevolucao'], {
		is: (pedido, formaDeDevolucao) =>
			(pedido === '5' || pedido === '34') && formaDeDevolucao === '',
		then: Yup.string().required('Campo Obrigatório: Formas de Devolução.'),
	}),
	validacaoDevolucaoCreditoEmContaNomeDoBanco: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoNomeDoBanco'],
		{
			is: (pedido, formaDeDevolucao, devolucaoNomeDoBanco) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 1 &&
				devolucaoNomeDoBanco === '',
			then: Yup.string().required('Campo Obrigatório: Banco Emissor.'),
		},
	),
	validacaoDevolucaoCreditoEmContaAgencia: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoAgencia'],
		{
			is: (pedido, formaDeDevolucao, devolucaoAgencia) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 1 &&
				devolucaoAgencia === '',
			then: Yup.string().required('Campo Obrigatório: Agência.'),
		},
	),
	validacaoDevolucaoCreditoEmContaConta: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoConta'],
		{
			is: (pedido, formaDeDevolucao, devolucaoConta) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 1 &&
				devolucaoConta === '',
			then: Yup.string().required('Campo Obrigatório: Conta.'),
		},
	),
	validacaoDevolucaoCreditoEmContaBancoEmissorDoCartao: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoBancoEmissorDoCartao'],
		{
			is: (pedido, formaDeDevolucao, devolucaoBancoEmissorDoCartao) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 2 &&
				devolucaoBancoEmissorDoCartao === '',
			then: Yup.string().required('Campo Obrigatório: Banco Emissor.'),
		},
	),
	validacaoDevolucaoCreditoEmContaBandeiraDoCartao: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoBandeiraDoCartao'],
		{
			is: (pedido, formaDeDevolucao, devolucaoBandeiraDoCartao) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 2 &&
				devolucaoBandeiraDoCartao === '',
			then: Yup.string().required('Campo Obrigatório: Bandeira do Cartão.'),
		},
	),
	validacaoDevolucaoCreditoEmContaNumeroDoCartao: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoNumeroDoCartao'],
		{
			is: (pedido, formaDeDevolucao, devolucaoNumeroDoCartao) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 2 &&
				devolucaoNumeroDoCartao === '',
			then: Yup.string().required('Campo Obrigatório: Número do Cartão.'),
		},
	),
	validacaoDevolucaoCreditoEmContaNumeroDeParcelas: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoNumeroDeParcelas'],
		{
			is: (pedido, formaDeDevolucao, devolucaoNumeroDeParcelas) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 2 &&
				devolucaoNumeroDeParcelas === '',
			then: Yup.string().required('Campo Obrigatório: Número de Parcelas.'),
		},
	),
	validacaoDevolucaoCreditoEmContaValorDaParcela: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoValorDaParcela'],
		{
			is: (pedido, formaDeDevolucao, devolucaoValorDaParcela) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 2 &&
				devolucaoValorDaParcela === '',
			then: Yup.string().required('Campo Obrigatório: Valor das Parcelas.'),
		},
	),
	validacaoDevolucaoCreditoEmContaTipoDeChavePix: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoTipoDeChavePix'],
		{
			is: (pedido, formaDeDevolucao, devolucaoTipoDeChavePix) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 4 &&
				devolucaoTipoDeChavePix === '',
			then: Yup.string().required('Campo Obrigatório: Tipo de Chave PIX.'),
		},
	),
	validacaoDevolucaoCreditoEmContaChavePix: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoChavePix'],
		{
			is: (pedido, formaDeDevolucao, devolucaoChavePix) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 4 &&
				devolucaoChavePix === '',
			then: Yup.string().required('Campo Obrigatório: Chave PIX.'),
		},
	),
	validacaoCompraCreditoEmContaBancoEmissor: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraBancoEmissor'],
		{
			is: (pedido, formaDePagamento, compraBancoEmissor) =>
				(pedido !== '5' || pedido !== '34') &&
				(formaDePagamento === '472890000' ||
					formaDePagamento === '472890001' ||
					formaDePagamento === '472890002' ||
					formaDePagamento === '472890009') &&
				compraBancoEmissor === '',
			then: Yup.string().required('Campo Obrigatório: Banco Emissor.'),
		},
	),
	validacaoCompraCreditoEmContaBandeiraDoCartao: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraBandeiraDoCartao'],
		{
			is: (pedido, formaDePagamento, compraBandeiraDoCartao) =>
				(pedido !== '5' || pedido !== '34') &&
				formaDePagamento === '472890001' &&
				compraBandeiraDoCartao === '',
			then: Yup.string().required('Campo Obrigatório: Bandeira do Cartão.'),
		},
	),
	validacaoCompraCreditoEmContaNumeroDoCartao: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraBandeiraDoCartao'],
		{
			is: (pedido, formaDePagamento, compraNumeroDoCartao) =>
				(pedido !== '5' || pedido !== '34') &&
				formaDePagamento === '472890001' &&
				compraNumeroDoCartao === '',
			then: Yup.string().required('Campo Obrigatório: Número do Cartão.'),
		},
	),
	validacaoCompraCreditoEmContaAgencia: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraAgencia'],
		{
			is: (pedido, formaDePagamento, compraAgencia) =>
				(pedido !== '5' || pedido !== '34') &&
				(formaDePagamento === '472890002' ||
					formaDePagamento === '472890009') &&
				compraAgencia === '',
			then: Yup.string().required('Campo Obrigatório: Agência.'),
		},
	),
	validacaoCompraCreditoEmContaConta: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraConta'],
		{
			is: (pedido, formaDePagamento, compraConta) =>
				(pedido !== '5' || pedido !== '34') &&
				(formaDePagamento === '472890002' ||
					formaDePagamento === '472890009') &&
				compraConta === '',
			then: Yup.string().required('Campo Obrigatório: Conta.'),
		},
	),
	validacaoCompraCreditoEmContaIntermediadoraDePagamento: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraIntermediadoraDePagamento'],
		{
			is: (pedido, formaDePagamento, compraIntermediadoraDePagamento) =>
				(pedido !== '5' || pedido !== '34') &&
				formaDePagamento === '472890004' &&
				compraIntermediadoraDePagamento === '',
			then: Yup.string().required(
				'Campo Obrigatório: Intermediadora de Pagamento.',
			),
		},
	),
	validacaoCompraCreditoEmContaTipoDeChavePix: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraTipoDeChavePix'],
		{
			is: (pedido, formaDePagamento, compraTipoDeChavePix) =>
				(pedido !== '5' || pedido !== '34') &&
				formaDePagamento === '472890006' &&
				compraTipoDeChavePix === '',
			then: Yup.string().required('Campo Obrigatório: Tipo de Chave PIX.'),
		},
	),
	validacaoCompraCreditoEmContaChavePix: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraChavePix'],
		{
			is: (pedido, formaDePagamento, compraChavePix) =>
				(pedido !== '5' || pedido !== '34') &&
				formaDePagamento === '472890006' &&
				compraChavePix === '',
			then: Yup.string().required('Campo Obrigatório: Chave PIX.'),
		},
	),
	temProcuradorEanexo: Yup.string().when(['temProcurador', 'anexos'], {
		is: (temProcurador, anexos) => temProcurador && anexos.length === 0,
		then: Yup.string().required(
			'Campo Obrigatório: É obrigatório anexar o documento do procurador ou responsável legal.',
		),
	}),
});

export const schemaPj = Yup.object<CadastraAtendimentoPjRequest>().shape({
	validacaoFornecedor: Yup.string().when(['fornecedorId', 'novoFornecedor'], {
		is: (fornecedorId, novoFornecedor) =>
			fornecedorId === '' && novoFornecedor.razaoSocial === '',
		then: Yup.string().required('Campo Obrigatório: Fornecedor.'),
	}),
	consumidorId: Yup.string().required('Campo Obrigatório: Consumidor.'),
	cnpjDoConsumidor: Yup.string().required(
		'Campo Obrigatório: CNPJ do Consumidor.',
	),
	areaId: Yup.string().required(
		'Campo Obrigatório: Classificação da Solicitação: "Área."',
	),
	assuntoId: Yup.string().when(['areaId'], {
		is: areaId => areaId !== '',
		then: Yup.string().required(
			'Campo Obrigatório: Classificação da Solicitação: "Assunto."',
		),
	}),
	grupoDeProblemaId: Yup.string().when(['areaId', 'assuntoId'], {
		is: (areaId, assuntoId) => areaId !== '' && assuntoId !== '',
		then: Yup.string().required(
			'Campo Obrigatório: Classificação da Solicitação: "Grupo de Problema."',
		),
	}),
	problemaId: Yup.string().when(['areaId', 'assuntoId', 'grupoDeProblemaId'], {
		is: (areaId, assuntoId, grupoDeProblemaId) =>
			areaId !== '' && assuntoId !== '' && grupoDeProblemaId !== '',
		then: Yup.string().required(
			'Campo Obrigatório: Classificação da Solicitação: "Problema."',
		),
	}),
	eventoEspecialId: Yup.string().required(
		'Campo Obrigatório: Evento Especial/Sazional.',
	),
	descricaoDoPrimeiroContatoComFornecedor: Yup.string().when(
		['procureiOFornecedorParaResolverAQuestao'],
		{
			is: procureiOFornecedorParaResolverAQuestao =>
				procureiOFornecedorParaResolverAQuestao !== false,
			then: Yup.string().required(
				'Campo Obrigatório: Informe como foi o seu contato com a empresa e o número do protocolo.',
			),
		},
	),
	participacaoEmAudiencia: Yup.string().required(
		'Campo Obrigatório: Participação em Audiência.',
	),
	formaDeAquisicao: Yup.string().required(
		'Campo Obrigatório: Forma de Aquisição.',
	),
	// tipoDeContratacao: Yup.string().required(
	// 	'Campo Obrigatório: Tipo de Contrataçao.',
	// ),
	validacaoTipoDeContratacao: Yup.string().when(
		['formaDeAquisicao', 'tipoDeContratacao'],
		{
			is: (formaDeAquisicao, tipoDeContratacao) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				tipoDeContratacao === '',
			then: Yup.string().required('Campo Obrigatório: Tipo de Contratação.'),
		},
	),
	// dataDaContratacao: Yup.string().required(
	// 	'Campo Obrigatório: Data da Contratação.',
	// ),
	validacaoDataDaContratacao: Yup.string().when(
		['formaDeAquisicao', 'dataDaContratacao'],
		{
			is: (formaDeAquisicao, dataDaContratacao) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				dataDaContratacao === '',
			then: Yup.string().required('Campo Obrigatório: Data da Contratação.'),
		},
	),
	// nomeDoProdutoOuServico: Yup.string().min(
	// 	3,
	// 	'Campo Obrigatório: Nome do Produto ou serviço. (Preencher no Mínimo 3 caracteres)',
	// ),
	validacaoNomeDoProdutoOuServico: Yup.string().when(
		['formaDeAquisicao', 'nomeDoProdutoOuServico'],
		{
			is: (formaDeAquisicao, nomeDoProdutoOuServico) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				nomeDoProdutoOuServico === '',
			then: Yup.string().min(
				3,
				'Campo Obrigatório: Nome do Produto ou Serviço. (Preencher no Mínimo 3 caracteres).',
			),
		},
	),
	// detalhesDoProdutoOuServico: Yup.string().min(
	// 	50,
	// 	'Campo Obrigatório: Descreva detalhes do produto ou serviço. (Preencher no Mínimo 50 caracteres)',
	// ),
	validacaoDetalhesDoProdutoOuServico: Yup.string().when(
		['formaDeAquisicao', 'detalhesDoProdutoOuServico'],
		{
			is: (formaDeAquisicao, detalhesDoProdutoOuServico) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				detalhesDoProdutoOuServico === '',
			then: Yup.string().min(
				50,
				'Campo Obrigatório: Descreva detalhes do produto ou serviço. (Preencher no Mínimo 50 caracteres).',
			),
		},
	),
	descricaoDoPedidoDoConsumidor: Yup.string().when(['pedido'], {
		is: pedido => pedido === '39' || pedido === 39,
		then: Yup.string().min(
			50,
			'Campo Obrigatório: Descreva o pedido do consumidor. (Preencher no Mínimo 50 caracteres)',
		),
	}),
	// tipoDoDocumento: Yup.string().required(
	// 	'Campo Obrigatório: Tipo de Documento.',
	// ),
	validacaoTipoDoDocumento: Yup.string().when(
		['formaDeAquisicao', 'tipoDoDocumento'],
		{
			is: (formaDeAquisicao, tipoDoDocumento) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				tipoDoDocumento === '',
			then: Yup.string().required('Campo Obrigatório: Tipo de Documento.'),
		},
	),
	// numeroDoDocumento: Yup.string().min(
	// 	1,
	// 	'Campo Obrigatório: Número do Documento.',
	// ),
	validacaoNumeroDoDocumento: Yup.string().when(
		['formaDeAquisicao', 'numeroDoDocumento'],
		{
			is: (formaDeAquisicao, numeroDoDocumento) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				numeroDoDocumento === '',
			then: Yup.string().min(1, 'Campo Obrigatório: Número do Documento.'),
		},
	),
	dataDaOcorrenciaOuDoServico: Yup.string().required(
		'Campo Obrigatório: Data da Ocorrência.',
	),
	dataDeCancelamentoDesistenciaOuNegativa: Yup.string().required(
		'Campo Obrigatório: Data de Cancelamento, Desistência ou Negativa.',
	),
	// formaDePagamento: Yup.string().required(
	// 	'Campo Obrigatório: Formas de Pagamento.',
	// ),
	validacaoFormaDePagamento: Yup.string().when(
		['formaDeAquisicao', 'formaDePagamento'],
		{
			is: (formaDeAquisicao, formaDePagamento) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				formaDePagamento === '',
			then: Yup.string().required('Campo Obrigatório: Formas de Pagamento.'),
		},
	),
	// valorDaCompra: Yup.string().required(
	// 	'Campo Obrigatório: Valor Da contratação.',
	// ),
	validacaoValorDaCompra: Yup.string().when(
		['formaDeAquisicao', 'valorDaCompra'],
		{
			is: (formaDeAquisicao, valorDaCompra) =>
				formaDeAquisicao !== 4 &&
				formaDeAquisicao !== '4' &&
				valorDaCompra === '',
			then: Yup.string().required('Campo Obrigatório: Valor da contratação.'),
		},
	),
	detalhesDaReclamacao: Yup.string().min(
		50,
		'Campo Obrigatório: Descreva detalhes da reclamação. (Preencher no Mínimo 50 caracteres)',
	),
	pedido: Yup.string().required('Campo Obrigatório: Pedido.'),
	validacaoFormaDeDevolucao: Yup.string().when(['pedido', 'formaDeDevolucao'], {
		is: (pedido, formaDeDevolucao) =>
			(pedido === '5' || pedido === '34') && formaDeDevolucao === '',
		then: Yup.string().required('Campo Obrigatório: Formas de Devolução.'),
	}),
	validacaoDevolucaoCreditoEmContaNomeDoBanco: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoNomeDoBanco'],
		{
			is: (pedido, formaDeDevolucao, devolucaoNomeDoBanco) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 1 &&
				devolucaoNomeDoBanco === '',
			then: Yup.string().required('Campo Obrigatório: Banco Emissor.'),
		},
	),
	validacaoDevolucaoCreditoEmContaAgencia: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoAgencia'],
		{
			is: (pedido, formaDeDevolucao, devolucaoAgencia) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 1 &&
				devolucaoAgencia === '',
			then: Yup.string().required('Campo Obrigatório: Agência.'),
		},
	),
	validacaoDevolucaoCreditoEmContaConta: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoConta'],
		{
			is: (pedido, formaDeDevolucao, devolucaoConta) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 1 &&
				devolucaoConta === '',
			then: Yup.string().required('Campo Obrigatório: Conta.'),
		},
	),
	validacaoDevolucaoCreditoEmContaBancoEmissorDoCartao: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoBancoEmissorDoCartao'],
		{
			is: (pedido, formaDeDevolucao, devolucaoBancoEmissorDoCartao) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 2 &&
				devolucaoBancoEmissorDoCartao === '',
			then: Yup.string().required('Campo Obrigatório: Banco Emissor.'),
		},
	),
	validacaoDevolucaoCreditoEmContaBandeiraDoCartao: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoBandeiraDoCartao'],
		{
			is: (pedido, formaDeDevolucao, devolucaoBandeiraDoCartao) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 2 &&
				devolucaoBandeiraDoCartao === '',
			then: Yup.string().required('Campo Obrigatório: Bandeira do Cartão.'),
		},
	),
	validacaoDevolucaoCreditoEmContaNumeroDoCartao: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoNumeroDoCartao'],
		{
			is: (pedido, formaDeDevolucao, devolucaoNumeroDoCartao) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 2 &&
				devolucaoNumeroDoCartao === '',
			then: Yup.string().required('Campo Obrigatório: Número do Cartão.'),
		},
	),
	validacaoDevolucaoCreditoEmContaNumeroDeParcelas: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoNumeroDeParcelas'],
		{
			is: (pedido, formaDeDevolucao, devolucaoNumeroDeParcelas) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 2 &&
				devolucaoNumeroDeParcelas === '',
			then: Yup.string().required('Campo Obrigatório: Número de Parcelas.'),
		},
	),
	validacaoDevolucaoCreditoEmContaValorDaParcela: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoValorDaParcela'],
		{
			is: (pedido, formaDeDevolucao, devolucaoValorDaParcela) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 2 &&
				devolucaoValorDaParcela === '',
			then: Yup.string().required('Campo Obrigatório: Valor das Parcelas.'),
		},
	),
	validacaoDevolucaoCreditoEmContaTipoDeChavePix: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoTipoDeChavePix'],
		{
			is: (pedido, formaDeDevolucao, devolucaoTipoDeChavePix) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 4 &&
				devolucaoTipoDeChavePix === '',
			then: Yup.string().required('Campo Obrigatório: Tipo de Chave PIX.'),
		},
	),
	validacaoDevolucaoCreditoEmContaChavePix: Yup.string().when(
		['pedido', 'formaDeDevolucao', 'devolucaoChavePix'],
		{
			is: (pedido, formaDeDevolucao, devolucaoChavePix) =>
				(pedido === '5' || pedido === '34') &&
				formaDeDevolucao === 4 &&
				devolucaoChavePix === '',
			then: Yup.string().required('Campo Obrigatório: Chave PIX.'),
		},
	),
	validacaoCompraCreditoEmContaBancoEmissor: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraBancoEmissor'],
		{
			is: (pedido, formaDePagamento, compraBancoEmissor) =>
				(pedido !== '5' || pedido !== '34') &&
				(formaDePagamento === '472890000' ||
					formaDePagamento === '472890001' ||
					formaDePagamento === '472890002' ||
					formaDePagamento === '472890009') &&
				compraBancoEmissor === '',
			then: Yup.string().required('Campo Obrigatório: Banco Emissor.'),
		},
	),
	validacaoCompraCreditoEmContaBandeiraDoCartao: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraBandeiraDoCartao'],
		{
			is: (pedido, formaDePagamento, compraBandeiraDoCartao) =>
				(pedido !== '5' || pedido !== '34') &&
				formaDePagamento === '472890001' &&
				compraBandeiraDoCartao === '',
			then: Yup.string().required('Campo Obrigatório: Bandeira do Cartão.'),
		},
	),
	validacaoCompraCreditoEmContaNumeroDoCartao: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraBandeiraDoCartao'],
		{
			is: (pedido, formaDePagamento, compraNumeroDoCartao) =>
				(pedido !== '5' || pedido !== '34') &&
				formaDePagamento === '472890001' &&
				compraNumeroDoCartao === '',
			then: Yup.string().required('Campo Obrigatório: Número do Cartão.'),
		},
	),
	validacaoCompraCreditoEmContaAgencia: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraAgencia'],
		{
			is: (pedido, formaDePagamento, compraAgencia) =>
				(pedido !== '5' || pedido !== '34') &&
				(formaDePagamento === '472890002' ||
					formaDePagamento === '472890009') &&
				compraAgencia === '',
			then: Yup.string().required('Campo Obrigatório: Agência.'),
		},
	),
	validacaoCompraCreditoEmContaConta: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraConta'],
		{
			is: (pedido, formaDePagamento, compraConta) =>
				(pedido !== '5' || pedido !== '34') &&
				(formaDePagamento === '472890002' ||
					formaDePagamento === '472890009') &&
				compraConta === '',
			then: Yup.string().required('Campo Obrigatório: Conta.'),
		},
	),
	validacaoCompraCreditoEmContaIntermediadoraDePagamento: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraIntermediadoraDePagamento'],
		{
			is: (pedido, formaDePagamento, compraIntermediadoraDePagamento) =>
				(pedido !== '5' || pedido !== '34') &&
				formaDePagamento === '472890004' &&
				compraIntermediadoraDePagamento === '',
			then: Yup.string().required(
				'Campo Obrigatório: Intermediadora de Pagamento.',
			),
		},
	),
	validacaoCompraCreditoEmContaTipoDeChavePix: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraTipoDeChavePix'],
		{
			is: (pedido, formaDePagamento, compraTipoDeChavePix) =>
				(pedido !== '5' || pedido !== '34') &&
				formaDePagamento === '472890006' &&
				compraTipoDeChavePix === '',
			then: Yup.string().required('Campo Obrigatório: Tipo de Chave PIX.'),
		},
	),
	validacaoCompraCreditoEmContaChavePix: Yup.string().when(
		['pedido', 'formaDePagamento', 'compraChavePix'],
		{
			is: (pedido, formaDePagamento, compraChavePix) =>
				(pedido !== '5' || pedido !== '34') &&
				formaDePagamento === '472890006' &&
				compraChavePix === '',
			then: Yup.string().required('Campo Obrigatório: Chave PIX.'),
		},
	),
	temProcuradorEanexo: Yup.string().when(['temProcurador', 'anexos'], {
		is: (temProcurador, anexos) => temProcurador && anexos.length === 0,
		then: Yup.string().required(
			'Campo Obrigatório: É obrigatório anexar o documento do procurador ou responsável legal.',
		),
	}),
});
