import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	pesquisaMunicipiosSuccess,
	pesquisaMunicipiosFailure,
} from './actions';

function* pesquisaMunicipiosRequest(request: ReducerAction) {
	const { payload }: { payload: string } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`municipios-ibge/${payload}`,
	);

	if (response.status === 200) {
		yield put(pesquisaMunicipiosSuccess(response));
	} else {
		yield put(pesquisaMunicipiosFailure());
	}
}

export default all([
	takeLatest(Types.PESQUISA_MUNICIPIOS_REQUEST, pesquisaMunicipiosRequest),
]);
