import React, { useEffect } from 'react';

import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch } from 'react-redux';
import { sedsClear } from 'store/modules/api/seds/vivaLeite/actions';

// COMPONENTS
import ServiceList from 'components/Common/ServiceList';
import ButtonVoltar from 'components/Common/ButtonVoltar';

const MenuVivaLeite: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const services = [
		{
			name: 'Viva Leite',
			path: 'vivaleite/identificarUsuario',
		},
	];

	useEffect(() => {
		dispatch(sedsClear());
	}, [dispatch]);

	return (
		<Row>
			<Col span={24}>
				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h1>Escolha o serviço:</h1>
					</Col>
				</Row>

				<Row>
					<Col span={24}>{services && <ServiceList data={services} />}</Col>
				</Row>

				<Row>
					<Col span={24}>
						<ButtonVoltar
							navigate={false}
							onClick={() => {
								history.push('/menu-orgaos');
							}}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default MenuVivaLeite;
