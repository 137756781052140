import React, { useCallback } from 'react';

import { Field, FormikProps } from 'formik';

import { NumberFormatValues } from 'react-number-format';

// COMPONENTS
import InputCurrency from 'components/Common/Form/Input/Currency';

// UTILS
import hasError from 'utils/getFormErrors';

interface Props {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | number;
	subtitle?: string;
	name: string;
	defaultValue?: number | string;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	formik: FormikProps<any>;
	size?: number;
	error?: boolean;
	maxLength?: number;
}

const Money: React.FC<Props> = ({
	name,
	title,
	titleSize,
	subtitle,
	defaultValue = 0,
	required = false,
	disabled = false,
	readOnly = false,
	size = 100,
	formik,
	error = false,
	maxLength,
}) => {
	const { setFieldValue, errors } = formik;

	const handleChange = useCallback(
		(values: NumberFormatValues) => {
			setFieldValue(name, values.floatValue);
		},
		[setFieldValue, name],
	);

	return (
		<>
			<Field
				as={InputCurrency}
				size={size}
				name={name}
				title={title}
				titleSize={titleSize}
				subtitle={subtitle}
				defaultValue={defaultValue}
				required={required}
				disabled={disabled}
				readOnly={readOnly}
				error={hasError(errors, name) || error}
				onChange={handleChange}
				maxLength={maxLength}
			/>
		</>
	);
};

export default Money;
