export enum Types {
	CONSULTA_FAZENDA_CPF_CEP_SEFAZ = '@cnh/CONSULTA_FAZENDA_CPF_CEP_SEFAZ',
	CONSULTA_FAZENDA_CPF_CEP_SEFAZ_SUCCESS = '@cnh/CONSULTA_FAZENDA_CPF_CEP_SEFAZ_SUCCESS',
	CONSULTA_FAZENDA_CPF_CEP_SEFAZ_FAILURE = '@cnh/CONSULTA_FAZENDA_CPF_CEP_SEFAZ_FAILURE',
}

export interface ConsultaFazendaSefazCep {
	status: number;
	data: FazendaSefazCep | null;
}

export interface FazendaSefazCep {
	codigo: string;
}

export interface ConsultaFazendaSefazCepRequest {
	cep: string;
	cpf_cnpj: string;
}
