import styled, { css } from 'styled-components';

interface NumeroProps {
	hasDigito: boolean;
}

export const Container = styled.div`
	display: flex;
	width: 100%;
`;

export const Numero = styled.div<NumeroProps>`
	${props =>
		props.hasDigito
			? css`
					width: 262px;
			  `
			: css`
					width: 100%;
			  `};
`;

export const Digito = styled.div`
	width: 30px;
`;

export const Divider = styled.div`
	margin: auto 3px;

	::before {
		content: '-';
	}
`;
