import * as Yup from 'yup';
import { ConsultaAtendimentosForm } from 'store/modules/api/procon/atendimentos/consultaAtendimentos/types';

export const initialValues: ConsultaAtendimentosForm = {
	cpf: '',
};

export const schema = Yup.object<ConsultaAtendimentosForm>().shape({
	cpf: Yup.string()
		.required('Campo Obrigatório: CPF.')
		.min(11, `O formato do CPF é inválido.`),
});
