export enum Types {
	CONSULTA_REIMPRESSAO_FIC_REQUEST = '@iirgd/CONSULTA_REIMPRESSAO_FIC_REQUEST',
	CONSULTA_REIMPRESSAO_FIC_SUCCESS = '@iirgd/CONSULTA_REIMPRESSAO_FIC_SUCCESS',
	CONSULTA_REIMPRESSAO_FIC_FAILURE = '@iirgd/CONSULTA_REIMPRESSAO_FIC_FAILURE',
	CONSULTA_REIMPRESSAO_FIC_CLEAR = '@iirgd/CONSULTA_REIMPRESSAO_FIC_CLEAR',
}

export interface ConsultaReimpressaoFicState {
	status: number;
	data: ConsultaReimpressaoFic;
	numeroRg: string;
}

export interface RequestConsultaReimpressaoFic {
	posto: {
		codigo: number;
		digito: number;
		nome: string;
		usuario: string;
	};
	numeroRg: string;
	digitoRg: string;
	ip?: string;
	senha: string;
}

export interface ConsultaReimpressaoFic {
	arquivoPdf: string;
	listErros: string[];
}
