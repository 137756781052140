export enum Types {
	IMPRESSAO_TROCA_VOLUNTARIA_PLACA_REQUEST = '@DETRAN-CRV/IMPRESSAO_TROCA_VOLUNTARIA_PLACA_REQUEST',
	IMPRESSAO_TROCA_VOLUNTARIA_PLACA_SUCCESS = '@DETRAN-CRV/IMPRESSAO_TROCA_VOLUNTARIA_PLACA_SUCCESS',
	IMPRESSAO_TROCA_VOLUNTARIA_PLACA_FAILURE = '@DETRAN-CRV/IMPRESSAO_TROCA_VOLUNTARIA_PLACA_FAILURE',
	IMPRESSAO_TROCA_VOLUNTARIA_PLACA_CLEAR = '@DETRAN-CRV/IMPRESSAO_TROCA_VOLUNTARIA_PLACA_CLEAR',
}

export interface impressaoTrocaVoluntariaPlaca {
	status: number;
	data: ficha | null;
}

interface ficha {
	anoFicha: number;
	chassi: string;
	numeroficha: number;
	placa: string;
	fichaCadastral: string;
}

export interface IEvento {
	cpf: string;
	id_atendimento?: string;
	id_cidadao?: string;
	idAtendimento?: string;
	idCidadao?: string;
	identificacao?: string;
	ip?: string;
	canal: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}
