export enum Types {
	CONSULTAR_BASE_OUTROS_ESTADOS_REQUEST = '@DETRAN-CRV/CONSULTAR_BASE_OUTROS_ESTADOS_REQUEST',
	CONSULTAR_BASE_OUTROS_ESTADOS_SUCCESS = '@DETRAN-CRV/CONSULTAR_BASE_OUTROS_ESTADOS_SUCCESS',
	CONSULTAR_BASE_OUTROS_ESTADOS_FAILURE = '@DETRAN-CRV/CONSULTAR_BASE_OUTROS_ESTADOS_FAILURE',
	CONSULTAR_BASE_OUTROS_ESTADOS_CLEAR = '@DETRAN-CRV/CONSULTAR_BASE_OUTROS_ESTADOS_CLEAR',
}

export interface consultarBaseOutrosEstados {
	status: number;
	data: IDadosConsultaBaseOutrosEstados;
}

export interface IDadosConsultaBaseOutrosEstados {
	dadosBaseEstadualOutrosEstados: {
		anoFabricacao: number;
		anoModelo: number;
		capacidadeCarga: number;
		capacidadePassageiro: number;
		chassi: string;
		cmt: number;
		eixo: number;
		numeroMotor: string;
		pbt: number;
		placa: string;
		procedencia: string;
		situacaoVeiculo: string;
		ufEmplacamento: string;
		ultimaAtualizacao: number;
		categoria: {
			descricao: string;
			longId: number;
		};
		combustivel: {
			longId: number;
			descricao: string;
		};
		cor: {
			longId: number;
			descricao: string;
		};
		especie: {
			longId: number;
			descricao: string;
		};
		marca: {
			longId: number;
			descricao: string;
		};
		municipioEmplacamento: {
			codigo: number;
			nome: string;
		};
		tipo: {
			longId: number;
			descricao: string;
		};
		dadosProprietario: {
			nomeProprietario: string;
		};
		multa: {
			debitoIpvaLicenciamento: string;
			debitosMultas: string;
			valorDebitoIpva: number;
			valorDebitoLicenciamento: number;
			valorDebitoMulta: number;
			valorDpvat: number;
		};
		restricoes: string[];
		gravame: {
			tipoTransacao: string;
			restricao: string;
			tipoRestricao: string;
			agenteFinanceiro: string;
			nomeFinanciado: string;
			cpfCnpjFinanciado: number;
			codigoFinanceira: number;
			arrendatarioFinanceira: string;
			numeroContrato: string;
			dataInclusao: string;
			dataVigencia: string;
		};
	};
}

export interface IRequestConsultaBaseOutrosEstados {
	chassi: string;
	placa?: string;
	uf: string;
}
