import React from 'react';

import { Row, Col } from 'antd';

// COMPONENTS
import Section from 'components/Common/Section';

import Proposta from './Proposta';
import Resultado from './Resultado';

const SimulacaoAcordo: React.FC = () => {
	return (
		<>
			<Row>
				<Col span={24}>
					<Section size="lg" title="Simular novo acordo" titleSize="sm">
						<Row gutter={[0, 10]} justify="center">
							<Col>
								<h5>
									O valor da parcela inicial só pode ser múltiplo do valor da
									prestação com inclusão de juros e correção monetária.
								</h5>
							</Col>
						</Row>

						<Row gutter={[0, 5]}>
							<Col />
						</Row>

						<Row gutter={[0, 10]} justify="center">
							<Col span={11}>
								<Proposta />
							</Col>

							<Col span={11} offset={1}>
								<Resultado />
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default SimulacaoAcordo;
