/* eslint-disable no-unused-expressions */
import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, PainelDeControle } from './types';

export const INITIAL_STATE: PainelDeControle = {
	usuarioPendente: {
		status: 0,
		resultado: [],
	},
	situacaoAtual: {
		status: 0,
		resultado: [],
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): PainelDeControle {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_SITUACAO_ATUAL_REQUEST: {
				draft.situacaoAtual.status = 0;
				break;
			}

			case Types.CONSULTA_SITUACAO_ATUAL_SUCCESS: {
				draft.situacaoAtual.status = 200;
				draft.situacaoAtual.resultado = action.payload;

				break;
			}

			case Types.CONSULTA_SITUACAO_ATUAL_FAILURE: {
				draft.situacaoAtual.status = 400;
				draft.situacaoAtual.resultado = INITIAL_STATE.situacaoAtual.resultado;
				break;
			}
			case Types.CONSULTA_SITUACAO_ATUAL_CLEAR: {
				draft.situacaoAtual.status = 0;
				draft.situacaoAtual.resultado = INITIAL_STATE.situacaoAtual.resultado;
				break;
			}

			case Types.ATENDIMENTO_ENCERRADO: {
				const filterAtendimento = state.usuarioPendente.resultado.filter(
					item => item.id !== action.payload,
				);

				draft.usuarioPendente.resultado = [...filterAtendimento];

				break;
			}

			case Types.LIMPA_STATE: {
				return INITIAL_STATE;
			}

			default:
		}

		return draft;
	});
}
