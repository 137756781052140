import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarUnidadeRequest } from './types';

export function cadastrarUnidadeRequest(
	data: CadastrarUnidadeRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_UNIDADE,
		payload: data,
	};
}
export function cadastrarUnidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_UNIDADE_SUCCESS,
		payload,
	};
}
export function cadastrarUnidadeFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_UNIDADE_FAILURE,
		payload: null,
	};
}
export function cadastrarUnidadeClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_UNIDADE_CLEAR,
		payload: null,
	};
}
