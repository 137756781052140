// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { ApiResponse } from 'services/_api';
import { Types, ConsultarCpfCin } from './types';

export function consultarCpfCinRequest(
	token: string,
	data: string,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_CIN_CPF_REQUEST,
		token,
		payload: data,
	};
}
export function consultarCpfCinSuccess(
	payload: ConsultarCpfCin,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_CIN_CPF_SUCCESS,
		payload,
	};
}
export function consultarCpfCinFailure(
	payload: ApiResponse,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_CIN_CPF_FAILURE,
		payload,
	};
}
export function consultarCpfCinClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_CIN_CPF_CLEAR,
		payload: null,
	};
}
