import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_CONTABILIZA } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ICadastroEventoRequest, Types } from './types';

// ACTIONS
import { cadastroEventoSuccess, cadastroEventoFailure } from './actions';

function* cadastroEventoRequest(request: ReducerAction) {
	const { payload }: { payload: ICadastroEventoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_CONTABILIZA,
		'eventos',
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastroEventoSuccess({ request: payload, response }));
	} else {
		yield put(cadastroEventoFailure(response));
	}
}

export default all([
	takeLatest(Types.CADASTRO_EVENTO_REQUEST, cadastroEventoRequest),
]);
