import { Types, IReenviarDocumentosRequest } from './types';

export function reenviarDocumentosRequest(payload: IReenviarDocumentosRequest) {
	return {
		type: Types.REENVIAR_DOCUMENTOS_REQUEST,
		payload,
	};
}
export function reenviarDocumentosSuccess(payload: any) {
	return {
		type: Types.REENVIAR_DOCUMENTOS_SUCCESS,
		payload,
	};
}
export function reenviarDocumentosFailure(payload: any) {
	return {
		type: Types.REENVIAR_DOCUMENTOS_FAILURE,
		payload,
	};
}
export function reenviarDocumentosClear(): any {
	return {
		type: Types.REENVIAR_DOCUMENTOS_CLEAR,
	};
}
