import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, Logon } from './types';

export const INITIAL_STATE: Logon = {
	status: 0,
	mensagem: '',
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): Logon {
	return produce(state, draft => {
		switch (action.type) {
			case Types.LOGON_REQUEST: {
				draft.status = 100;
				draft.mensagem = INITIAL_STATE.mensagem;
				break;
			}

			case Types.LOGON_SUCCESS: {
				draft.status = 200;
				draft.mensagem = action.payload.mensagem ? action.payload.mensagem : '';
				break;
			}

			case Types.LOGON_FAILURE: {
				draft.status = 400;
				draft.mensagem = action.payload.data.data.mensagem
					? action.payload.data.data.mensagem.trim()
					: '';
				break;
			}
			case Types.LOGON_CLEAR: {
				draft.status = 0;
				draft.mensagem = INITIAL_STATE.mensagem;
				break;
			}

			default:
		}
	});
}
