import { IParametrosLocalidade } from 'store/modules/api/sgu/loginUnico/types';

export const getApiKey = (parametros: IParametrosLocalidade[]) => {
	let api = 'NO API KEY';
	if (!parametros.length) {
		return api;
	}
	if (
		parametros.filter(
			(item: IParametrosLocalidade) => item.sigla === 'KEY_API_GOOGLE_MAPS',
		)
	) {
		const filterValue = parametros.filter(
			(item: IParametrosLocalidade) => item.sigla === 'KEY_API_GOOGLE_MAPS',
		);
		if (filterValue[0]?.valor) {
			api = filterValue[0]?.valor;
		}
		return api;
	}
	return api;
};
