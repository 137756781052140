import React, { useCallback } from 'react';

import { Col, Row } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';

const GerarBoletoAgrupado: React.FC = () => {
	const { gerarBoletoAgrupado } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	const handleClick = useCallback(() => {
		if (gerarBoletoAgrupado.data) {
			const { urlBoletoGerado } = gerarBoletoAgrupado.data;

			window.open(urlBoletoGerado, '_blank')?.focus();
		}
	}, [gerarBoletoAgrupado.data]);

	return (
		<>
			{gerarBoletoAgrupado.data && gerarBoletoAgrupado.form && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Section
							size="lg"
							title="Serviços / Boleto agrupado"
							titleSize="sm"
						>
							<Row align="middle">
								<Col span={7} offset={1}>
									<Row>
										<Col span={11}>
											<b>Total de prestações:</b>
										</Col>

										<Col>{1}</Col>
									</Row>
								</Col>

								<Col span={7} offset={1}>
									<Row>
										<Col span={17}>
											<b>Data de vencimento informada:</b>
										</Col>

										<Col>{gerarBoletoAgrupado.data.dataVencimento}</Col>
									</Row>
								</Col>

								<Col span={7} offset={1}>
									<Row justify="center">
										<Col>
											<ButtonImage src="gerar-boleto" onClick={handleClick} />
										</Col>
									</Row>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			)}
		</>
	);
};

export default GerarBoletoAgrupado;
