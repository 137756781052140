export enum Types {
	CONSULTA_DOCUMENTO_INFRACAO = '@jalesTransito/CONSULTA_DOCUMENTO_INFRACAO',
	CONSULTA_DOCUMENTO_INFRACAO_SUCCESS = '@jalesTransito/CONSULTA_DOCUMENTO_INFRACAO_SUCCESS',
	CONSULTA_DOCUMENTO_INFRACAO_FAILURE = '@jalesTransito/CONSULTA_DOCUMENTO_INFRACAO_FAILURE',
	CONSULTA_DOCUMENTO_INFRACAO_CLEAR = '@jalesTransito/CONSULTA_DOCUMENTO_INFRACAO_CLEAR',
}

export interface documentoInfracao {
	status: number;
	data: IDocumentoInfracao | null;
}

interface IDocumentoInfracao {
	codigoAit: string;
	documento: string;
	urlPagar: string;
	urlProtocolar: string;
	urlTransferir: string;
}
