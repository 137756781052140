import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { toSelect } from 'utils/genericFunctions';
import { Types, consultaCombustivel } from './types';

export const INITIAL_STATE: consultaCombustivel = {
	status: 0,
	data: [],
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaCombustivel {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_COMBUSTIVEL_REQUEST: {
				break;
			}

			case Types.CONSULTA_COMBUSTIVEL_SUCCESS: {
				draft.status = action.payload.status;

				const select = toSelect(
					action.payload.data.combustiveis,
					'descricao',
					'longId',
				);

				select.unshift({
					value: 0,
					label: 'COMBUSTÍVEL NÃO ENCONTRADO',
				});

				draft.data = select;

				break;
			}

			case Types.CONSULTA_COMBUSTIVEL_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			default:
		}
		return draft;
	});
}
