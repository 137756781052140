import * as Yup from 'yup';

export interface IImpressaoLicenciamentoVeiculos {
	numeroFicha: string;
	anoFicha: string;
}

export const initialValues: IImpressaoLicenciamentoVeiculos = {
	numeroFicha: '',
	anoFicha: '',
};

export const schema = Yup.object<IImpressaoLicenciamentoVeiculos>().shape({
	numeroFicha: Yup.string().required(`Campo Obrigatório: Número da Ficha.`),
	anoFicha: Yup.string().required(`Campo Obrigatório: Ano da Ficha Cadastral.`),
});
