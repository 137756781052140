// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { ApiResponse } from 'services/_api';
import { Types, ConsultarProcesso, ConsultarProcessoRequest } from './types';

export function consultarProcessoRequest(
	token: string,
	data: ConsultarProcessoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PROCESSO_REQUEST,
		token,
		payload: data,
	};
}
export function consultarProcessoSuccess(
	payload: ConsultarProcesso,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PROCESSO_SUCCESS,
		payload,
	};
}
export function consultarProcessoFailure(
	payload: ApiResponse,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PROCESSO_FAILURE,
		payload,
	};
}
export function consultarProcessoClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_PROCESSO_CLEAR,
		payload: null,
	};
}
