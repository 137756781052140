import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_BOLSA_POVO } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	consultarTipoContatoSuccess,
	consultarTipoContatoFailure,
} from './action';

function* consultarTipoContatoRequest() {
	const response: ApiResponse = yield call(
		postApi,
		PATH_BOLSA_POVO,
		`bolsa-povo/${CHANNEL}/consultar-tipo-contato`,
		{},
	);

	if (response.status === 200) {
		yield put(consultarTipoContatoSuccess(response));
	} else if (response.error) {
		yield put(consultarTipoContatoFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_TIPO_CONTRATO_REQUEST,
		consultarTipoContatoRequest,
	),
]);
