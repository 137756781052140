import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_EDUCACAO } from 'services/_path';
import { Types } from './types';
import { ensinoSuccess, ensinoFailure } from './actions';

function* ensinoRequest() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_EDUCACAO,
		`combo/${CHANNEL}/ensino`,
	);
	if (response.status === 200) {
		yield put(ensinoSuccess(response));
	} else if (response.error) {
		yield put(ensinoFailure(response.message));
	}
}

export default all([takeLatest(Types.ENSINO_REQUEST, ensinoRequest)]);
