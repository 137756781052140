import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	HORARIOS_POR_DATA = '@cnh/HORARIOS_POR_DATA',
	HORARIOS_POR_DATA_SUCCESS = '@cnh/HORARIOS_POR_DATA_SUCCESS',
	HORARIOS_POR_DATA_FAILURE = '@cnh/HORARIOS_POR_DATA_FAILURE',
	HORARIOS_POR_DATA_CLEAR = '@cnh/HORARIOS_POR_DATA_CLEAR',
}

export interface horariosPorData {
	status: number;
	data: IDadosHorariosPorData | null;
}

interface IDadosHorariosPorData {
	enum: OptionProps[] | [];
	idMedico: number;
}

export interface IRequestHorariosPorData {
	data_exame: string;
	id_medico: number;
}
