import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaNaoProcessadaState } from './types';

export const INITIAL_STATE: ConsultaNaoProcessadaState = {
	consultaNaoProcessadaData: [],
	totalRegistrosNaoProcessada: 0,
};

export default function contabilizacao(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaNaoProcessadaState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_NAO_PROCESSADA: {
				break;
			}
			case Types.CONSULTA_NAO_PROCESSADA_SUCCESS: {
				draft.totalRegistrosNaoProcessada = action.payload.total_registros;
				draft.consultaNaoProcessadaData = action.payload.evento_contabilizacoes;
				break;
			}
			case Types.CONSULTA_NAO_PROCESSADA_FAILURE: {
				draft.consultaNaoProcessadaData = [];
				break;
			}
			default:
		}
	});
}
