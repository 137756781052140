// PATHS
import {
	ROUTE_DETRAN_CRV_CONSULTAR_BASE_OUTROS_ESTADOS,
	ROUTE_DETRAN_CRV_CONSULTAR_BASE_OUTROS_ESTADOS_RESULTADO,
	ROUTE_DETRAN_CRV_CONSULTAR_NUMERO_MOTOR_BIN,
	ROUTE_DETRAN_CRV_CONSULTAR_NUMERO_MOTOR_BIN_RESULTADO,
	ROUTE_DETRAN_CRV_CONSULTA_CADASTRO_BIN_RENAVAM,
	ROUTE_DETRAN_CRV_CONSULTA_CADASTRO_BIN_RENAVAM_RESULTADO,
	ROUTE_DETRAN_CRV_CONSULTA_VEICULO_BASE_ESTADUAL,
	ROUTE_DETRAN_CRV_CONSULTA_VEICULO_BASE_ESTADUAL_RESULTADO,
} from '../routes/paths';
// // BASE ESTADUAL
import ConsultaVeiculoBaseEstadual from './BaseEstadual';
import ConsultaVeiculoBaseEstadualResultado from './BaseEstadual/ResultadoConsulta';
// // CONSULTAR BASE OUTROS ESTADOS
import ConsultarBaseOutrosEstados from './ConsultarBaseOutrosEstados';
import ConsultarBaseOutrosEstadosResultado from './ConsultarBaseOutrosEstados/ResultadoConsulta';
// // CADASTRO BIN RENAVAM
import ConsultarCadastroBinRenavam from './ConsultarCadastroBinRenavam';
import ConsultarCadastroBinRenavamResultado from './ConsultarCadastroBinRenavam/ResultadoConsulta';
// // CONSULTAR NUMERO MOTOR BIN
import ConsultarNumeroMotorBin from './ConsultarNumeroMotorBin';
import ConsultarNumeroMotorBinResultado from './ConsultarNumeroMotorBin/ResultadoConsulta';

export const detranCrvRoute = {
	name: 'DETRAN CRV',
	route: '/detran-crv',
};

export default [
	{
		path: ROUTE_DETRAN_CRV_CONSULTA_VEICULO_BASE_ESTADUAL,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Veículo na Base Estadual',
			},
		],
		Component: ConsultaVeiculoBaseEstadual,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CONSULTA_VEICULO_BASE_ESTADUAL_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Veículo na Base Estadual',
			},
		],
		Component: ConsultaVeiculoBaseEstadualResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CONSULTA_CADASTRO_BIN_RENAVAM,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Cadastro BIN/RENAVAM',
			},
		],
		Component: ConsultarCadastroBinRenavam,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CONSULTA_CADASTRO_BIN_RENAVAM_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Cadastro BIN/RENAVAM',
			},
		],
		Component: ConsultarCadastroBinRenavamResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CONSULTAR_BASE_OUTROS_ESTADOS,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Base de Outros Estados',
			},
		],
		Component: ConsultarBaseOutrosEstados,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CONSULTAR_BASE_OUTROS_ESTADOS_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Base de Outros Estados',
			},
		],
		Component: ConsultarBaseOutrosEstadosResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CONSULTAR_NUMERO_MOTOR_BIN,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Número de Motor na BIN',
			},
		],
		Component: ConsultarNumeroMotorBin,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_CONSULTAR_NUMERO_MOTOR_BIN_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Número de Motor na BIN',
			},
		],
		Component: ConsultarNumeroMotorBinResultado,
		isAuthenticated: true,
		exact: true,
	},
];
