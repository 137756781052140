export enum Types {
	PGAT_REQUEST = '@DETRAN-CRV/PGAT_REQUEST',
	PGAT_SUCCESS = '@DETRAN-CRV/PGAT_SUCCESS',
	PGAT_FAILURE = '@DETRAN-CRV/PGAT_FAILURE',
	PGAT_CLEAR = '@DETRAN-CRV/PGAT_CLEAR',
}

export interface PGAT {
	status: number;
	mensagem: string;
	data: IPGAT;
}

export interface IPGAT {
	codigoRetorno: string;
	tipoCliente: number;
	tipoPesquisa: {
		codigo: string;
		descricao: string;
	};
	chassi: string;
	placa: string;
	contrato: string;
	nomeAgente: string;
	cpfCnpjAgente: string;
	nomeFinanciado: string;
	cpfCnpjFinanciado: string;
	dataInclusao: string;
	tipoRestricao: {
		codigo: string;
		descricao: string;
	};
}

export interface IPGATRequest {
	tipoPesquisa: number;
	chassi: string;
	placa: string;
}
