import React, { useEffect, useState, useCallback } from 'react';

import { Row, Col } from 'antd';

// ROUTES
import { Redirect, useHistory } from 'react-router-dom';
import {
	ROUTE_AAC_PESQUISA_ANTECEDENTES_NOMINAL,
	ROUTE_AAC_PESQUISA_CENTRAL_NOMINAL,
} from 'pages/iirgd/Aac/routes/paths';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import {
	emissaoAtestadoAntecedentesRequest,
	limpaAtestadoAntecedentes,
} from 'store/modules/api/aac/emissaoAtestadoAntecedentes/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
import PDFViewer from 'components/Common/PDFViewer';
import Divider from 'components/Common/Divider';

import { convertDate, unformatDate } from 'utils/genericFunctions';
import handleFailed from 'utils/getValidations';

// STYLED
import { RequestEmissaoAtestadoAntecedentes } from 'store/modules/api/aac/emissaoAtestadoAntecedentes/types';
import data from 'store/modules/api/iirgd/enum';
import NavMenu from '../../components/NavMenu';
import { Container, Content } from './styled';

interface AtestadoNominal {
	name: string;
	value: string;
	renderizar: boolean;
}

const getTipoDocumento = (value: string): string => {
	const docEncontrado = data.tipoDocumentoAac.filter(
		(item: { value: string; label: string }) => {
			return value === item.value;
		},
	);

	if (docEncontrado[0]) {
		return docEncontrado[0].value.toUpperCase();
	}

	return '';
};

const NominalResultado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { pesquisaNominal, emissaoAtestadoAntecedentes } = useSelector(
		(state: ApplicationState) => state.api.aac,
	);

	const [protocoloBase64, setProtocoloBase64] = useState<string>('');

	useEffect(() => {
		if (
			emissaoAtestadoAntecedentes.status !== 200 &&
			emissaoAtestadoAntecedentes.data.mensagem
		) {
			handleFailed({
				mensagem_erro: emissaoAtestadoAntecedentes.data.mensagem,
			});
		} else if (
			emissaoAtestadoAntecedentes.status === 200 &&
			!emissaoAtestadoAntecedentes.data.pdfBase64 &&
			emissaoAtestadoAntecedentes.data.pdfBase64 === ''
		) {
			history.push(ROUTE_AAC_PESQUISA_CENTRAL_NOMINAL);
		} else if (
			emissaoAtestadoAntecedentes.status === 200 &&
			emissaoAtestadoAntecedentes.data.pdfBase64 &&
			emissaoAtestadoAntecedentes.data.pdfBase64 !== ''
		) {
			setProtocoloBase64(emissaoAtestadoAntecedentes.data.pdfBase64);
			dispatch(limpaAtestadoAntecedentes());
		}
	}, [emissaoAtestadoAntecedentes, history, dispatch]);

	const arrayAtestadoNominal: AtestadoNominal[] = [
		{
			name: 'Documento:',
			value: `${pesquisaNominal.numeroDocumento} ${
				pesquisaNominal.tipoDocApresentado === 'RG' ||
				pesquisaNominal.tipoDocApresentado === 'CNH'
					? `- ${pesquisaNominal.ufRg.toUpperCase()} - ${getTipoDocumento(
							pesquisaNominal.tipoDocApresentado,
					  )} `
					: `- ${getTipoDocumento(pesquisaNominal.tipoDocApresentado)} `
			} `,
			renderizar: true,
		},
		{
			name: 'Data de Expedição:',
			value: pesquisaNominal.dataExpedicao,
			renderizar: true,
		},
		{
			name: 'Nome:',
			value: pesquisaNominal.nome.toUpperCase(),
			renderizar: true,
		},
		{
			name: 'Pai:',
			value: pesquisaNominal.nomePai.toUpperCase(),
			renderizar: true,
		},
		{
			name: 'Mãe:',
			value: pesquisaNominal.nomeMae.toUpperCase(),
			renderizar: true,
		},
		{
			name: 'Sexo:',
			value: pesquisaNominal.sexo === 'M' ? 'MASCULINO' : 'FEMININO',
			renderizar: true,
		},
		{
			name: 'Data de Nascimento:',
			value: pesquisaNominal.dataNascimento,
			renderizar: true,
		},
		{
			name: 'Procurador:',
			value: pesquisaNominal.nomeProcurador
				? pesquisaNominal.nomeProcurador
				: 'NÃO',
			renderizar: true,
		},
		{
			name: 'Documento:',
			value: pesquisaNominal.docProcurador ? pesquisaNominal.docProcurador : '',
			renderizar: !!pesquisaNominal.docProcurador,
		},
	];

	const handleSubmitForm = useCallback(() => {
		const numeroDocumentoTreated = `${pesquisaNominal.numeroDocumento}${
			(pesquisaNominal.ufRg && ` - ${pesquisaNominal.ufRg}`) ||
			(getTipoDocumento(pesquisaNominal.tipoDocApresentado) === 'CIN' &&
				` - CIN`) ||
			''
		}`;

		const formData: RequestEmissaoAtestadoAntecedentes = {
			dataExpedicaoRg: convertDate(pesquisaNominal.dataExpedicao),
			dataNascimento: null,
			dataNascimentoTs: `${unformatDate(
				pesquisaNominal.dataNascimento,
			)} 12:00:00`,
			ipEstacao: pesquisaNominal.ipEstacao,
			nome: pesquisaNominal.nome,
			nomeMae: pesquisaNominal.nomeMae === 'NC' ? '' : pesquisaNominal.nomeMae,
			nomePai: pesquisaNominal.nomePai === 'NC' ? '' : pesquisaNominal.nomePai,
			numeroDocumento: numeroDocumentoTreated,
			posto: pesquisaNominal.posto,
			sexo: pesquisaNominal.sexo,
			tipoDocApresentado: getTipoDocumento(pesquisaNominal.tipoDocApresentado),
			usuario: pesquisaNominal.usuario,
			docProcurador: pesquisaNominal.docProcurador.toUpperCase() ?? '',
			nomeProcurador: pesquisaNominal.nomeProcurador.toUpperCase() ?? '',
			numeroRg: 0,
			digitoRg: '',
			origem: 'BU',
			tipoEmissao: 'NOMINAL',
			nomeSocial: '',
		};

		dispatch(emissaoAtestadoAntecedentesRequest(formData));
	}, [dispatch, pesquisaNominal]);

	return (
		<>
			<Row>
				<Col span={24}>
					<NavMenu />

					<Divider mtSize={15} mbSize={15} />
				</Col>
			</Row>
			{pesquisaNominal ? (
				<Section title="Pesquisa Nominal">
					<Container>
						<span>ATENÇÃO:</span>{' '}
						<label>
							Confira com quem solicita o atestado se as informações abaixo
							estão corretas
						</label>
					</Container>
					<Content>
						<ul>
							{arrayAtestadoNominal.map((item: AtestadoNominal) => {
								if (!item.renderizar) {
									return null;
								}
								return (
									<li key={item.name + Math.random()}>
										<div>
											<span>{item.name}</span>
										</div>
										<span>{item.value}</span>
									</li>
								);
							})}
						</ul>
					</Content>
					<Row align="middle" className="no-print">
						<Col span={5} offset={5}>
							<Button
								onClick={() =>
									history.push(ROUTE_AAC_PESQUISA_ANTECEDENTES_NOMINAL)
								}
							>
								Retornar e Corrigir
							</Button>
						</Col>
						<Col span={6}>
							<Button onClick={() => window.print()}>
								Imprimir para conferência
							</Button>
						</Col>
						<Col span={6}>
							<Button onClick={() => handleSubmitForm()}>
								Imprimir o Atestado
							</Button>
						</Col>
					</Row>
				</Section>
			) : (
				<Redirect exact to="/iirgd/aac/nominal" />
			)}
			{protocoloBase64 && (
				<PDFViewer
					title="Atestado de Antecedentes"
					source={protocoloBase64}
					onClose={() => setProtocoloBase64('')}
				/>
			)}
		</>
	);
};

export default NominalResultado;
