import { all } from 'redux-saga/effects';

import buscaPaginadaSaga from './buscaPaginada/saga';
import consultaConsumidor from './consultaConsumidor/saga';
import cadastro from './cadastro/saga';
import atualizacao from './atualizacao/saga';

export default all([
	buscaPaginadaSaga,
	consultaConsumidor,
	cadastro,
	atualizacao,
]);
