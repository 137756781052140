import { ReducerAction } from 'store/modules/types';

import { ConsultarParametrosSistemaRequest, Types } from './types';

export function consultarParametrosSistemaRequest(
	data: ConsultarParametrosSistemaRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_PARAMETROS_SISTEMA,
		payload: data,
	};
}
export function consultarParametrosSistemaSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_PARAMETROS_SISTEMA_SUCCESS,
		payload,
	};
}
export function consultarParametrosSistemaFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_PARAMETROS_SISTEMA_FAILURE,
		payload: null,
	};
}
export function consultarParametrosSistemaClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_PARAMETROS_SISTEMA_CLEAR,
		payload: null,
	};
}
