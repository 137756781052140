export default {
	tipoDocumentoEnumPGURegistro: [
		{
			value: 'PGU',
			label: 'PGU',
		},
		{
			value: 'REGISTRO',
			label: 'REGISTRO',
		},
	],
	tipoDocumentoEnumSemPGU: [
		{
			value: 'CPF',
			label: 'CPF',
		},
		{
			value: 'RG',
			label: 'RG',
		},
		{
			value: 'REGISTRO',
			label: 'REGISTRO',
		},
	],
	tipoDocumentoEnum: [
		{
			value: 'CPF',
			label: 'CPF',
		},
		{
			value: 'RG',
			label: 'RG',
		},
		{
			value: 'REGISTRO',
			label: 'REGISTRO',
		},
		{
			value: 'PGU',
			label: 'PGU',
		},
	],
	sexoEnum: [
		{
			value: 'M',
			label: 'MASCULINO',
		},
		{
			value: 'F',
			label: 'FEMININO',
		},
	],
	sexoEnumPrimeiraHabilitacao: [
		{
			value: 'MASCULINO',
			label: 'Masculino',
		},
		{
			value: 'FEMININO',
			label: 'Feminino',
		},
	],
	nacionalidadeEnum: [
		{
			value: '1',
			label: 'BRASILEIRO',
		},
		{
			value: '2',
			label: 'BRASILEIRO NATURALIZADO',
		},
		{
			value: '3',
			label: 'ESTRANGEIRO',
		},
		{
			value: '4',
			label: 'BRASILEIRO NASC. NO EXTERIOR',
		},
	],
	nacionalidadeEnumPrimeiraHabilitacao: [
		{
			value: '1',
			label: 'BRASILEIRO',
		},
		{
			value: '2',
			label: 'BRASILEIRO NATURALIZADO',
		},
		{
			value: '3',
			label: 'ESTRANGEIRO',
		},
		{
			value: '4',
			label: 'BRASILEIRO NASC. NO EXTERIOR',
		},
	],
	ufEnum: [
		{
			label: 'AC',
			value: 'AC',
		},
		{
			label: 'AL',
			value: 'AL',
		},
		{
			label: 'AM',
			value: 'AM',
		},
		{
			label: 'AP',
			value: 'AP',
		},
		{
			label: 'BA',
			value: 'BA',
		},
		{
			label: 'CE',
			value: 'CE',
		},
		{
			label: 'DF',
			value: 'DF',
		},
		{
			label: 'ES',
			value: 'ES',
		},
		{
			label: 'GO',
			value: 'GO',
		},
		{
			label: 'MA',
			value: 'MA',
		},
		{
			label: 'MG',
			value: 'MG',
		},
		{
			label: 'MS',
			value: 'MS',
		},
		{
			label: 'MT',
			value: 'MT',
		},
		{
			label: 'PA',
			value: 'PA',
		},
		{
			label: 'PB',
			value: 'PB',
		},
		{
			label: 'PE',
			value: 'PE',
		},
		{
			label: 'PI',
			value: 'PI',
		},
		{
			label: 'PR',
			value: 'PR',
		},
		{
			label: 'RJ',
			value: 'RJ',
		},
		{
			label: 'RN',
			value: 'RN',
		},
		{
			label: 'RO',
			value: 'RO',
		},
		{
			label: 'RR',
			value: 'RR',
		},
		{
			label: 'RS',
			value: 'RS',
		},
		{
			label: 'SC',
			value: 'SC',
		},
		{
			label: 'SE',
			value: 'SE',
		},
		{
			label: 'SP',
			value: 'SP',
		},
		{
			label: 'TO',
			value: 'TO',
		},
	],
	entidadeMilitarEnum: [
		{
			value: '90700',
			label: 'Exército',
		},
		{
			value: '90803',
			label: 'Marinha',
		},
		{
			value: '90906',
			label: 'Aeronáutica',
		},
		{
			value: '91005',
			label: 'Polícia Militar',
		},
		{
			value: '91108',
			label: 'Polícia Civil',
		},
	],
	justificativaMudancaFisica: [
		{
			value:
				'Cidadão mudou fisionomia, acidente, cirurgia.  A foto atual não reflete o cidadão em virtude da idade (Criança / Idoso) etc.',
			label: 'Fisionomia',
		},
		{
			value:
				'Problemas diversos com as digitais (Perda de dedos, impossibilidade de match contra base AFIS devido idade e/ou devido à dificuldade de leitura de digitais (Não lê).',
			label: 'Digital',
		},
		{
			value: 'Falha na impressão da FIC.',
			label: 'Impressão da FIC',
		},
		{
			value:
				'Falha no Leitor Biométrico, Sistema Inoperante, Mesa / posto sem coleta Biométrica etc.',
			label: 'Biometria indisponível',
		},
	],
	justificativaMudancaAssinatura: [
		{
			value: 'Casou, Separou-se, Nome Social, Mudança de Sexo.',
			label: 'Situação civil',
		},
		{
			value: 'Inclusão de nome de pai ou da mãe.',
			label: 'Filiação',
		},
		{
			value: 'Mudou a forma de assinar, não consegue assinar igual etc.',
			label: 'Atualização da assinatura',
		},
		{
			value: 'Falha na impressão da FIC.',
			label: 'Impressão da FIC',
		},
	],
	simNaoBoolean: [
		{
			value: true,
			label: 'Sim',
		},
		{
			value: false,
			label: 'Não',
		},
	],
	categorias: [
		{
			value: 'A',
			label: 'A - Moto',
		},
		{
			value: 'B',
			label: 'B - Automóvel',
		},
		{
			value: 'C',
			label: 'C - Caminhão',
		},
		{
			value: 'D',
			label: 'D - Ônibus',
		},
		{
			value: 'E',
			label: 'E - Veíc. Acoplado',
		},
		{
			value: 'AB',
			label: 'AB',
		},
		{
			value: 'AC',
			label: 'AC',
		},
		{
			value: 'AD',
			label: 'AD',
		},
		{
			value: 'AE',
			label: 'AE',
		},
		{
			value: 'X',
			label: 'ACC - Moto 50 cc',
		},
	],
	radioSimNao: [
		{
			label: 'Sim',
			value: 'S',
		},
		{
			label: 'Não',
			value: 'N',
		},
	],
	radioSimNaoBoolean: [
		{
			label: 'Sim',
			value: true,
		},
		{
			label: 'Não',
			value: false,
		},
	],
};
