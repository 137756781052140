import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestTipoArquivo } from './types';
import { tipoArquivoSuccess, tipoArquivoFailure } from './actions';

function* tipoArquivoRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestTipoArquivo } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`tipo-arquivo/${CHANNEL}/tipo-arquivo-emissao`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(tipoArquivoSuccess(response));
	} else if (response.error) {
		yield put(tipoArquivoFailure(response.message));
	}
}

export default all([
	takeLatest(Types.TIPO_ARQUIVO_REQUEST, tipoArquivoRequest),
]);
