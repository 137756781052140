import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import { listaPaisesSuccess, listaPaisesFailure } from './actions';

function* listaPaises() {
	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`primeira-habilitacao/${CHANNEL}/lista-paises`,
		{},
	);
	if (response.status === 200) {
		yield put(listaPaisesSuccess(response));
	} else if (response.error) {
		yield put(listaPaisesFailure(response.error));
	}
}

export default all([takeLatest(Types.LISTA_PAISES, listaPaises)]);
