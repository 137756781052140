export enum Types {
	CONSULTA_FICHA_ALUNO_REQUEST = '@EDUCACAO/CONSULTA_FICHA_ALUNO_REQUEST',
	CONSULTA_FICHA_ALUNO_SUCCESS = '@EDUCACAO/CONSULTA_FICHA_ALUNO_SUCCESS',
	CONSULTA_FICHA_ALUNO_FAILURE = '@EDUCACAO/CONSULTA_FICHA_ALUNO_FAILURE',
	CONSULTA_FICHA_ALUNO_CLEAN = '@EDUCACAO/CONSULTA_FICHA_ALUNO_CLEAN',
}

export interface ConsultaFichaAluno {
	status: number;
	data: ConsultaFichaAlunoData | null;
}

export interface ConsultaFichaAlunoData {
	sucesso: string;
	processoId: string;
	dataAlteracaoFicha: string;
	operador: string;
	justificativaDocumentos: string;
	dadosPessoais: DadosPessoais;
	documentos: Documentos;
	certidaoAntiga: CertidaoAntiga;
	certidaoNova: CertidaoNova;
	enderecoResidencial: EnderecoResidencial;
	telefones: Telefone[];
	deficiencia: Deficiencia;
	recursoAvaliacao: RecursoAvaliacao;
	listaNecessidadesEspeciais: NecessidadesEspeciais[];
	irmaos: Irmao[];
	enderecoIndicativo: EnderecoIndicativo;
}

interface EnderecoIndicativo {
	logradouro: string;
	numero: string;
	bairro: string;
	nomeCidade: string;
	ufCidade: string;
	latitude: string;
	longitude: string;
	cep: string;
}

interface DadosPessoais {
	numRA: string;
	digitoRA: string;
	siglaUFRA: string;
	nomeAluno: string;
	dataNascimento: string;
	corRaca: string;
	descCorRaca: string;
	codSexo: string;
	sexo: string;
	nomeMae: string;
	nomePai: string;
	nomeSocial: string;
	nomeAfetivo: string;
	filiacao3: string;
	email: string;
	emailGoogle: string;
	emailMicrosoft: string;
	nacionalidade: string;
	descricaoNacionalidade: string;
	codPaisOrigem: string;
	nomePaisOrigem: string;
	dataEntradaPais: string;
	bolsaFamilia: boolean;
	quilombola: boolean;
	possuiInternet: boolean;
	possuiNotebookSmartphoneTablet: boolean;
	gemeo: boolean;
	nomeMunicipioNascimento: string;
	ufMunicipioNascimento: string;
	alunoFalecido: boolean;
	dataFalecimento: string;
	codMunicipioNascimentoDNE: string;
	doadorOrgaos: boolean;
	tipoSanguineo: string;
}

interface Documentos {
	codigoINEP: string;
	cpf: string;
	numDocumentoCivil: string;
	digitoDocumentoCivil: string;
	ufDocumentoCivil: string;
	dataEmissaoDocumentoCivil: string;
	dataEmissaoCertidao: string;
	codJustificativa: string;
	numeroCns: string;
	numeroNis: string;
	tipoCertidao: string;
}

interface CertidaoAntiga {
	numCertidao: string;
	livro: string;
	folha: string;
	municipioComarca: string;
	ufComarca: string;
	distritoCertidao: string;
	tipoCertidao: string;
}

interface CertidaoNova {
	certMatr01: string;
	certMatr02: string;
	certMatr03: string;
	certMatr04: string;
	certMatr05: string;
	certMatr06: string;
	certMatr07: string;
	certMatr08: string;
	certMatr09: string;
}

interface EnderecoResidencial {
	logradouro: string;
	numero: string;
	areaLogradouro: string;
	complemento: string;
	bairro: string;
	nomeCidade: string;
	ufCidade: string;
	latitude: string;
	longitude: string;
	cep: string;
	codMunicipioDNE: string;
	codArea: string;
	codLocalizacao: string;
	localizacaoDiferenciada: string;
}

export interface Telefone {
	dddNumero: string;
	numero: string;
	tipoTelefone: string;
	descTipoTelefone: string;
	complemento: string;
	sms: boolean;
}

interface Deficiencia {
	professorAuxiliar: boolean;
	dataInicioProfessorAuxiliar: string;
	dataFimProfessorAuxiliar: string;
	dataInicioMobilidadeReduzida: string;
	dataFimMobilidadeReduzida: string;
	mobilidadeReduzida: boolean;
	tipoMobilidadeReduzida: string;
	profissionalApoioEscolarVO: {
		profissionalApoioEscolar: boolean;
		atividadeDiaria: boolean;
		atividadeEscolar: boolean;
		apoioHigiene: boolean;
		apoioLocomocao: boolean;
		apoioAlimentacao: boolean;
		dataInicioVigenciaAtividadeDiaria: string;
		dataFimVigenciaAtividadeDiaria: string;
		dataInicioVigenciaAtividadeEscolar: string;
		dataFimVigenciaAtividadeEscolar: string;
	};
}

interface RecursoAvaliacao {
	guiaInterprete: boolean;
	interpreteLibras: boolean;
	leituraLabial: boolean;
	nenhum: boolean;
	provaAmpliada: boolean;
	tamanhoFonte: string;
	provaBraile: boolean;
	auxilioTranscricao: boolean;
	auxilioLeitor: boolean;
	provaVideoLibras: boolean;
	cdAudioDefVisual: boolean;
	provaLinguaPortuguesa: boolean;
}

export interface NecessidadesEspeciais {
	codigoNecessidadeEspecial: string;
	nomeNecessidadeEspecial: string;
}

export interface Irmao {
	nomeAluno: string;
	dataNascimento: string;
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
	gemeo: boolean;
}

export interface IRequestConsultaFichaAluno {
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
}
