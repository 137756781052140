import React from 'react';

import { Row, Col } from 'antd';

// UTILS
import hasError from 'utils/getFormErrors';
import { onlyNumbersLettersSpace } from 'utils/genericFunctions';

// COMMONS
import Section from 'components/Common/Section';
import TextArea from 'components/Common/Form/Input/TextArea';

// FORM
import { FormikProps, Field } from 'formik';
import { ICadastroSolicitante } from '../form';

// STYLES
import { TEXT } from './styled';

interface Props {
	formik: FormikProps<ICadastroSolicitante>;
	step: number;
	formDisabled: boolean;
}

const Detalhes: React.FC<Props> = ({ formik, formDisabled }) => {
	return (
		<>
			<Section title="Mais Detalhes" size="sm">
				<Row gutter={[0, 10]}>
					<TEXT>
						Informações que possam contribuir para a localização do genitor
						(pai), como lugares frequentados, nome e telefone de amigos e
						parentes, empresa em que supostamente trabalha, nome do pai, local
						onde vota, outros.
					</TEXT>
				</Row>

				<Field
					disabled={formDisabled}
					as={TextArea}
					name="filho.genitor.outrasInformacoes"
					onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
						formik.setFieldValue(
							'filho.genitor.outrasInformacoes',
							onlyNumbersLettersSpace(v.target.value),
						);
					}}
					error={hasError(formik.errors, 'filho.genitor.outrasInformacoes')}
				/>
			</Section>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default Detalhes;
