export enum Types {
	COMBO_MUNICIPIOS_REQUEST = '@EDUCACAO/COMBO_MUNICIPIOS_REQUEST',
	COMBO_MUNICIPIOS_SUCCESS = '@EDUCACAO/COMBO_MUNICIPIOS_SUCCESS',
	COMBO_MUNICIPIOS_FAILURE = '@EDUCACAO/COMBO_MUNICIPIOS_FAILURE',
}

export interface ComboMunicipios {
	status: number;
	data: ComboMunicipioData;
}

interface ComboMunicipioData {
	processoId: string;
	municipios: IMunicipios[];
}

interface IMunicipios {
	codigo: string;
	descricao: string;
}

export interface ComboMunicipiosRequestData {
	uf: string;
	codDiretoria?: string;
}
