import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../types';
import { Types, ECpfState } from './types';

import getToken from './getToken/reducer';
import getECpfValidation from './getECpfValidation/reducer';
import getCredentials from './getCredentials/reducer';
import certificateValidate from './certificateValidate/reducer';
import changeUrl from './changeUrl/reducer';

const allReducers = combineReducers({
	getToken,
	getECpfValidation,
	getCredentials,
	certificateValidate,
	changeUrl,
});

const combineReducer = (state: ECpfState, action: ReducerAction) => {
	if (action.type === Types.E_CPF_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
