import {
	IConfirmacaoAtendimento,
	initialValues,
} from '../components/pages/ConfirmacaoAtendimento/form';

export function hasUpdateInAttendencyModal(
	valuesModal: IConfirmacaoAtendimento,
) {
	if (
		valuesModal.todosDedos !== initialValues.todosDedos ||
		valuesModal.coletarFoto !== initialValues.coletarFoto ||
		valuesModal.coletarAssinatura !== initialValues.coletarAssinatura
	) {
		return true;
	}
	return false;
}
