export enum Types {
	EMITIR_MODIFICACAO_DADOS_CADASTRAIS_REQUEST = '@DETRAN-CRV/EMITIR_MODIFICACAO_DADOS_CADASTRAIS_REQUEST',
	EMITIR_MODIFICACAO_DADOS_CADASTRAIS_SUCCESS = '@DETRAN-CRV/EMITIR_MODIFICACAO_DADOS_CADASTRAIS_SUCCESS',
	EMITIR_MODIFICACAO_DADOS_CADASTRAIS_FAILURE = '@DETRAN-CRV/EMITIR_MODIFICACAO_DADOS_CADASTRAIS_FAILURE',
	EMITIR_MODIFICACAO_DADOS_CADASTRAIS_CLEAR = '@DETRAN-CRV/EMITIR_MODIFICACAO_DADOS_CADASTRAIS_CLEAR',
}

export interface emitirModificacaoDadosCadastrais {
	status: number;
	data: IResponseEmitirModificacaoDadosCadastrais | null;
}

export interface IRequestWithHeader {
	body: IRequestEmitirModificacaoDadosCadastrais;
	headers?: any;
}

export interface IResponseEmitirModificacaoDadosCadastrais {
	chassi: string;
	placa: string;
	anoFicha: number;
	numeroficha: number;
	planilhaRenavam: string;
}

export type IProcedencia = 'IMPORTADA' | 'NACIONAL' | '';

export interface IRequestEmitirModificacaoDadosCadastrais {
	anoModelo: number;
	capacidadePassageiro: number;
	capacidadeCarga: number;
	chassiRemarcado: boolean;
	chassi: string;
	numeroEspelho: number;
	cilindrada: number;
	cmt: number;
	eixo: number;
	pbt: number;
	placa: string;
	placaAnterior: string;
	potencia: number;
	procedencia: IProcedencia;
	servicoDigital: boolean;
	documentos: any;
	carroceria: {
		longId: number;
		descricao: string;
	};
	categoria: {
		descricao: string;
		longId: number;
	};
	combustivel: {
		longId: number;
		descricao: string;
	};
	cor: {
		longId: number;
		descricao: string;
	};
	especie: {
		longId: number;
		descricao: string;
	};
	marca: {
		longId: number;
		descricao: string;
	};
	municipio: {
		codigo: number;
		nome: string;
	};
	tipo: {
		longId: number;
		descricao: string;
	};
	gravame: {
		arrendatarioFinanceira: string;
		dataVigencia: string;
		financeira: number;
		numeroContrato: string;
		restricaoFinanceira: string;
	};
	proprietario: {
		nomeProprietario: string;
		cepProprietario: number;
		bairro: string;
		enderecoProprietario: string;
		numero: number;
		complemento: string;
		telefone: number;
		identificacao: string;
		cpfCnpjProprietario: number;
		rgProprietario: number;
		orgaoExpeditorProprietario: string;
		ufProprietario: string;
		nomeProprietarioAnterior: string;
		municipioProprietario: {
			codigo: number;
			nome: string;
		};
	};
	modificacaoDadosCadastrais: {
		acessibilidadeCondutor: boolean;
		acessibilidadePassageiro: boolean;
		alongamentoChassi: boolean;
		blindagem: boolean;
		cabineDupla: boolean;
		cabineEstendida: boolean;
		cabineSuplementar: boolean;
		capacidadeVolumeTanqueSuplementar: number;
		certificadoSegVeicular: number;
		csvAno: number;
		distanciaEixo: number;
		eixo: string | null;
		encurtamentoChassi: boolean;
		freios: boolean;
		iluminacaoFrontalSinalizacao: boolean;
		insulfilme: boolean;
		parachoque: boolean;
		plataforma: boolean;
		potenciaCilindrada: boolean;
		quantidadeTanqueSuplementar: number;
		rebaixamento: boolean;
		reencarrocamento: boolean;
		rodaPneu: boolean;
		suspensao: boolean;
		suspensaoPneumatica: boolean;
		tanqueSuplementar: boolean;
		tetoSolar: boolean;
		tipoAcessibilidadePassageiro: string;
		valorSuspensao: number;
		visual: boolean;
	};
	taxaAutenticacaoDigital: string;
}
