import { CapturarBiometriaState } from './capturarBiometria/types';
import { VerificarVersaoServicoBiometria } from './verificarVersaoServicoBiometria/types';

export enum Types {
	BIOMETRIA_CLEAR = '@biometria/BIOMETRIA_CLEAR',
}

export interface BiometriaState {
	capturarBiometria: CapturarBiometriaState;
	verificarVersaoServicoBiometria: VerificarVersaoServicoBiometria;
}
