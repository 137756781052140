/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Col, Row } from 'antd';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import SimpleTable from 'components/Common/Table';
import Alert from 'components/Common/Notifications/Alert';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	consultaAlunoClear,
	consultaAlunoRequest,
} from 'store/modules/api/educacaoUpdate/fichaAluno/consultaAluno/actions';
import {
	consultaFichaAlunoClean,
	consultaFichaAlunoRequest,
} from 'store/modules/api/educacaoUpdate/fichaAluno/consultaFichaAluno/actions';
import {
	clearNotifications,
	addNotifications,
} from 'store/modules/app/notifications/actions';
import { dadosBasicosRequest } from 'store/modules/api/educacaoUpdate/combos/dadosBasicos/actions';
import { eixoRequest } from 'store/modules/api/educacaoUpdate/combos/eixo/actions';
import { tiposEnsinoRequest } from 'store/modules/api/educacaoUpdate/combos/tiposEnsino/actions';
import { baixarArquivosClear } from 'store/modules/api/utils/arquivos/baixarArquivos/actions';

import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { ApplicationState } from 'store';
import { FaSistrix } from 'react-icons/fa';
import { ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_FICHA_ALUNO } from 'pages/EducacaoUpdate/routes/paths';
import DatePicker from 'components/Common/Form/Input/DatePicker';
import { letrasAcentos } from 'utils/stringFunctions';
import { convertDate } from 'utils/genericFunctions';
import {
	IFormConsultarAluno,
	IConsultarFichaAluno,
	initialValues,
	schema,
} from './form';

const defaultMessageErrorUtilsService =
	'Ocorreu um erro ao carregar os serviços, realize novamente a pesquisa.';

const Pesquisar: React.FC = () => {
	// eslint-disable-next-line no-undef
	const { token } = useContext<IAuthContext>(AuthContext);
	const [notificationAlunoNaoEncontrado, setNotificationAlunoNaoEncontrado] =
		useState<boolean>(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const { fichaAluno } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate,
	);

	const dadosBasicos = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.combos.dadosBasicos,
	);

	const tiposEnsino = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.combos.tiposEnsino,
	);

	const eixo = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.combos.eixo,
	);

	const getUtilsData = useCallback(() => {
		dispatch(dadosBasicosRequest(token));
		dispatch(eixoRequest(token));
		dispatch(tiposEnsinoRequest(token));
	}, [dispatch, token]);

	const handleSubmit = useCallback(
		(values: IFormConsultarAluno) => {
			const normalizeValues = {
				dataNascimento: convertDate(values.dataNascimento) || '',
				nomeAluno:
					values.filtrosNomes.nomeAluno
						.normalize('NFD')
						?.replace(/[\u0300-\u036f]/g, '')
						?.trim() || '',
				nomeMae:
					values.filtrosNomes.nomeMae
						.normalize('NFD')
						?.replace(/[\u0300-\u036f]/g, '')
						?.trim() || '',
				nomePai:
					values.filtrosNomes.nomePai
						.normalize('NFD')
						?.replace(/[\u0300-\u036f]/g, '')
						?.trim() || '',
			};
			getUtilsData();
			dispatch(consultaAlunoRequest(token, normalizeValues));
		},
		[dispatch, getUtilsData, token],
	);

	useEffect(() => {
		dispatch(consultaFichaAlunoClean());
		dispatch(consultaAlunoClear());
		dispatch(baixarArquivosClear());
	}, [dispatch]);

	useEffect(() => {
		if (fichaAluno.consultaAluno.status === 200) {
			if (
				fichaAluno.consultaAluno.data &&
				fichaAluno.consultaAluno.data.alunos.length === 1
			) {
				const dataConsultaFichaRequest = {
					numeroRA: fichaAluno.consultaAluno.data.alunos[0].numRA,
					digitoRA: fichaAluno.consultaAluno.data.alunos[0].digitoRA,
					siglaUFRA: fichaAluno.consultaAluno.data.alunos[0].siglaUFRA,
				};
				dispatch(consultaFichaAlunoRequest(token, dataConsultaFichaRequest));
			}
		} else if (fichaAluno.consultaAluno.status === 204) {
			setNotificationAlunoNaoEncontrado(true);
		}
	}, [
		dispatch,
		fichaAluno.consultaAluno.data,
		fichaAluno.consultaAluno.status,
		token,
	]);

	useEffect(() => {
		setTimeout(() => {
			if (fichaAluno.consultaFichaAluno.status === 200) {
				if (
					eixo.status === 200 &&
					dadosBasicos.status === 200 &&
					tiposEnsino.status === 200
				) {
					history.push(ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_FICHA_ALUNO);
				} else if (
					(tiposEnsino.status !== 0 &&
						tiposEnsino.status !== 100 &&
						tiposEnsino.status !== 200) ||
					(eixo.status !== 0 && eixo.status !== 100 && eixo.status !== 200) ||
					(dadosBasicos.status !== 0 &&
						dadosBasicos.status !== 100 &&
						dadosBasicos.status !== 200)
				) {
					addNotifications({
						errors: [`${defaultMessageErrorUtilsService}`],
					});
				}
			} else if (fichaAluno.consultaFichaAluno.status === 204) {
				setNotificationAlunoNaoEncontrado(true);
			}
		}, 1000);
	}, [
		dadosBasicos.status,
		eixo.status,
		fichaAluno.consultaFichaAluno.status,
		history,
		tiposEnsino.status,
	]);

	const filtraAlunoSelecionado = (record: any) => {
		const consultaFicha = {
			numeroRA: record.numeroRA,
			digitoRA: record.digitoRA,
			siglaUFRA: record.siglaUFRA,
		};
		dispatch(consultaFichaAlunoRequest(token, consultaFicha));
		history.push(ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_FICHA_ALUNO);
	};

	const headers = [
		{
			title: 'Nome',
			dataIndex: 'nome',
		},
		{
			title: 'Número RA',
			dataIndex: 'numeroRA',
		},
		{
			title: 'Dígito RA',
			dataIndex: 'digitoRA',
		},
		{
			title: 'UF RA',
			dataIndex: 'siglaUFRA',
		},
		{
			title: 'Alterar',
			dataIndex: 'type',
			render: (text: any, record: IConsultarFichaAluno) => {
				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<FaSistrix
							size={20}
							onClick={() => filtraAlunoSelecionado(record)}
						/>
					</div>
				);
			},
		},
	];

	return (
		<>
			<Section title="Pesquisar Aluno">
				{notificationAlunoNaoEncontrado && (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Alert
								type="warning"
								message="Aluno não encontrado!"
								onClose={() => setNotificationAlunoNaoEncontrado(false)}
							/>
						</Col>
					</Row>
				)}
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => handleSubmit(values)}
				>
					{formik => (
						<Form autoComplete="off">
							<Row gutter={[0, 10]}>
								<Col span={20}>
									<Field
										as={Input}
										type="text"
										title="Nome Completo"
										name="filtrosNomes.nomeAluno"
										error={hasError(formik.errors, 'filtrosNomes.nomeAluno')}
										maxLength={100}
										onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
											formik.setFieldValue(
												'filtrosNomes.nomeAluno',
												letrasAcentos(v.target.value),
											);
										}}
										required
									/>
								</Col>
							</Row>
							<Row gutter={[0, 10]}>
								<Col span={20}>
									<Field
										as={Input}
										type="text"
										title="Nome Mãe"
										name="filtrosNomes.nomeMae"
										error={hasError(formik.errors, 'filtrosNomes.nomeMae')}
										maxLength={100}
										onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
											formik.setFieldValue(
												'filtrosNomes.nomeMae',
												letrasAcentos(v.target.value),
											);
										}}
										required
									/>
								</Col>
							</Row>
							<Row gutter={[0, 10]}>
								<Col span={20}>
									<Field
										as={Input}
										type="text"
										title="Nome Pai"
										name="filtrosNomes.nomePai"
										error={hasError(formik.errors, 'filtrosNomes.nomePai')}
										maxLength={100}
										onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
											formik.setFieldValue(
												'filtrosNomes.nomePai',
												letrasAcentos(v.target.value),
											);
										}}
									/>
								</Col>
							</Row>
							<Row gutter={[0, 10]}>
								<Col span={6}>
									<Field
										style={{ minWidth: '80px' }}
										title="Data de Nascimento"
										name="dataNascimento"
										type="text"
										mask="99/99/9999"
										higherCurrent
										as={DatePicker}
										error={hasError(formik.errors, 'dataNascimento')}
										formik={formik}
										onChange={(v: string) =>
											formik.setFieldValue('dataNascimento', v)
										}
										required
									/>
								</Col>
							</Row>
							<Row gutter={[0, 10]} justify="center">
								<Col style={{ padding: '5px 10px' }}>
									<ButtonImage
										onClick={() => {
											formik.handleReset();
											dispatch(clearNotifications());
											dispatch(consultaAlunoClear());
										}}
										type="button"
										src="limpar"
									/>
								</Col>
								<Col style={{ padding: '5px 10px' }}>
									<ButtonImage type="submit" src="pesquisar" />
								</Col>
								{(fichaAluno.consultaAluno.status === 204 ||
									fichaAluno.consultaAluno.status === 400) && (
									<Col style={{ padding: '5px 10px' }}>
										<ButtonImage
											type="button"
											src="novo-cadastro"
											onClick={() => {
												if (
													tiposEnsino.status === 200 &&
													eixo.status === 200 &&
													dadosBasicos.status === 200
												) {
													history.push(
														ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_FICHA_ALUNO,
													);
												} else if (
													(tiposEnsino.status !== 0 &&
														tiposEnsino.status !== 100 &&
														tiposEnsino.status !== 200) ||
													(eixo.status !== 0 &&
														eixo.status !== 100 &&
														eixo.status !== 200) ||
													(dadosBasicos.status !== 0 &&
														dadosBasicos.status !== 100 &&
														dadosBasicos.status !== 200)
												) {
													addNotifications({
														errors: [`${defaultMessageErrorUtilsService}`],
													});
												}
											}}
										/>
									</Col>
								)}
							</Row>
						</Form>
					)}
				</Formik>
			</Section>
			<ButtonVoltar />
			{fichaAluno.consultaAluno.status === 200 &&
				fichaAluno.consultaAluno.data &&
				fichaAluno.consultaAluno.data.alunos.length > 1 && (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<SimpleTable
								headers={headers}
								body={fichaAluno.consultaAluno.data.alunos}
							/>
						</Col>
					</Row>
				)}
		</>
	);
};

export default Pesquisar;
