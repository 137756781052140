// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	IConsultarRelatorioMensalDare,
	IConsultarRelatorioMensalDareRequest,
	Types,
} from './types';

export function consultarRelatorioMensalDareRequest(
	payload: IConsultarRelatorioMensalDareRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_RELATORIO_MENSAL_DARE_REQUEST,
		payload,
	};
}

export function consultarRelatorioMensalDareSuccess(
	status: number,
	data: IConsultarRelatorioMensalDare[] | null,
): ReducerAction {
	return {
		type: Types.CONSULTAR_RELATORIO_MENSAL_DARE_SUCCESS,
		payload: {
			status,
			data,
		},
	};
}

export function consultarRelatorioMensalDareClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_RELATORIO_MENSAL_DARE_CLEAR,
		payload: null,
	};
}

export function consultarRelatorioMensalDareFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_RELATORIO_MENSAL_DARE_FAILURE,
		payload: null,
	};
}
