import { all } from 'redux-saga/effects';

import consultaUsuariosSaga from './consultarUsuarios/saga';
import consultaUsuarioSaga from './consultarUsuario/saga';
import cadastrarUsuarioSaga from './cadastrarUsuario/saga';
import cadastrarUsuarioMAinframeSaga from './cadastrarUsuarioMainframe/saga';
import atualizarUsuario from './atualizarUsuario/saga';
import excluirLocalidadesRelacionadas from './excluirLocalidadesRelacionadas/saga';
import excluirUsuario from './excluirUsuario/saga';
import trocarSenhaUsuario from './trocarSenhaUsuario/saga';
import excluirUsuarioMainframe from './excluirUsuarioMainframe/saga';
import resetarSenhaUsuarioMainframe from './resetarSenhaUsuarioMainframe/saga';

export default all([
	consultaUsuariosSaga,
	consultaUsuarioSaga,
	cadastrarUsuarioSaga,
	cadastrarUsuarioMAinframeSaga,
	atualizarUsuario,
	excluirUsuario,
	excluirLocalidadesRelacionadas,
	trocarSenhaUsuario,
	excluirUsuarioMainframe,
	resetarSenhaUsuarioMainframe,
]);
