import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// REDUX
import data from 'store/modules/api/prefeituras/enum';
import { ApplicationState } from 'store';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Upload from 'components/Common/Upload';
import InputMask from 'components/Common/Form/Input/InputMask';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { limparMascara } from 'utils/genericFunctions';

// FORM
import { Formik, Form, Field } from 'formik';
import { Col, Row } from 'antd';
import { transferirPontuacaoRequest } from 'store/modules/api/prefeituras/jalesTransito/transferirPontuacao/actions';
import { IFormTransferirPontuacao, InitialValues, schema } from './form';

import { Content } from './styled';

const TransferirPontuacao = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { documentoInfracao, transferirPontuacao } = useSelector(
		(state: ApplicationState) => state.api.prefeituras.jalesTransito,
	);

	const handleSubmit = useCallback(
		(values: IFormTransferirPontuacao) => {
			if (documentoInfracao.data) {
				values.condutorCpf = limparMascara(values.condutorCpf);
				dispatch(
					transferirPontuacaoRequest({
						...values,
						codigoAit: documentoInfracao.data.codigoAit,
					}),
				);
			}
		},
		[dispatch, documentoInfracao.data],
	);

	useEffect(() => {
		if (transferirPontuacao.status === 200) {
			history.push(
				`/prefeitura/jales/consulta/infracoes/resultado/transferencia`,
			);
			// dispatch(clearRedirectJalesTransito());
		}
	}, [dispatch, history, transferirPontuacao]);

	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={InitialValues}
						validate={values => getValidationsErrors(values, schema)}
						onSubmit={handleSubmit}
					>
						{formik => {
							return (
								<Form>
									<Content>
										<Section title="Condutor">
											<Row gutter={[0, 10]}>
												<Col span={12}>
													<Field
														title="Nome"
														name="condutorNome"
														as={Input}
														required
														error={hasError(formik.errors, 'condutorNome')}
													/>
												</Col>
												<Col span={12}>
													<Field
														title="CPF"
														name="condutorCpf"
														as={InputMask}
														mask="999.999.999-99"
														required
														error={hasError(formik.errors, 'condutorCpf')}
													/>
												</Col>
											</Row>
											<Row gutter={[0, 10]}>
												<Col span={12}>
													<Field
														title="CNH"
														name="condutorCnh"
														as={Input}
														required
														error={hasError(formik.errors, 'condutorCnh')}
													/>
												</Col>
												<Col span={12}>
													<Field
														title="Estado"
														name="condutorCnhUf"
														as={Select}
														options={data.estados}
														defaultValue="SP"
														onChange={(v: string) => {
															formik.setFieldValue('condutorCnhUf', v);
														}}
														required
														error={hasError(formik.errors, 'condutorCnhUf')}
													/>
												</Col>
											</Row>
											<Row gutter={[0, 10]}>
												<Col span={12}>
													<Field
														title="Categoria CNH"
														name="condutorCnhCategoria"
														as={Input}
														required
														error={hasError(
															formik.errors,
															'condutorCnhCategoria',
														)}
													/>
												</Col>
											</Row>
										</Section>

										<Section title="Fotos">
											<Row gutter={[0, 10]}>
												<Col span={24} md={12}>
													<Upload
														title="CNH"
														name="fotoCnh"
														formik={formik}
														required
														error={hasError(formik.errors, 'fotoCnh')}
													/>
													<Upload
														title="CPF"
														name="fotoCpf"
														formik={formik}
														required
														error={hasError(formik.errors, 'fotoCpf')}
													/>
													<Upload
														title="Teste do Formulário"
														name="fotoFormulario"
														formik={formik}
														required
														error={hasError(formik.errors, 'fotoFormulario')}
													/>
													<Upload
														title="CPF do Proprietário"
														name="fotoProprietarioCpf"
														formik={formik}
														required
														error={hasError(
															formik.errors,
															'fotoProprietarioCpf',
														)}
													/>
												</Col>

												<Col span={24} md={12}>
													<Upload
														title="RG"
														name="fotoRg"
														formik={formik}
														required
														error={hasError(formik.errors, 'fotoRg')}
													/>
													<Upload
														title="Contrato Social"
														name="fotoContratoSocial"
														formik={formik}
													/>
													<Upload
														title="CNH do Proprietário"
														name="fotoProprietarioCnh"
														formik={formik}
														required
														error={hasError(
															formik.errors,
															'fotoProprietarioCnh',
														)}
													/>
													<Upload
														title="RG do Proprietário"
														name="fotoProprietarioRg"
														formik={formik}
														required
														error={hasError(
															formik.errors,
															'fotoProprietarioRg',
														)}
													/>
												</Col>
											</Row>
										</Section>
										<ButtonImage type="submit" src="salvar" />
									</Content>
								</Form>
							);
						}}
					</Formik>
				</Col>
			</Row>
			<ButtonVoltar />
		</>
	);
};

export default TransferirPontuacao;
