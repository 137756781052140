export enum Types {
	CADASTRAR_MENSAGEM_REQUEST = '@SGU/CADASTRAR_MENSAGEM_REQUEST',
	CADASTRAR_MENSAGEM_SUCCESS = '@SGU/CADASTRAR_MENSAGEM_SUCCESS',
	CADASTRAR_MENSAGEM_FAILURE = '@SGU/CADASTRAR_MENSAGEM_FAILURE',
	CADASTRAR_MENSAGEM_CLEAR = '@SGU/CADASTRAR_MENSAGEM_CLEAR',
}

export interface CadastrarMensagem {
	status: number;
	data: null | MensagemData;
}

interface MensagemData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarMensagemRequest {
	search?: string;
	id?: number | string;
	de: string;
	para: string;
	regex: string;
}
