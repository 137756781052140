export enum Types {
	CONSULTA_PONTOS_REQUEST = '@cnh/CONSULTA_PONTOS_REQUEST',
	CONSULTA_PONTOS_SUCCESS = '@cnh/CONSULTA_PONTOS_SUCCESS',
	CONSULTA_PONTOS_FAILURE = '@cnh/CONSULTA_PONTOS_FAILURE',
	CONSULTA_PONTOS_CLEAR = '@cnh/CONSULTA_PONTOS_CLEAR',
}

export interface Multas {
	placa: string;
	descricaoEnq: string;
	autoInfracao: string;
	data: string;
	local: string;
	municipio: string;
	pontos: string;
	portaria: string;
	orgaoAtuador: string;
}

export interface ConsultaPontos {
	data: ConsultaPontosData | null;
	status: number;
}

export interface ConsultaPontosData {
	numeroPGU: string;
	nome: string;
	numeroRegistro: number;
	municipioCnh: string;
	cpf: string;
	quantidadeMultas: number;
	infracao: Multas[];
}

export interface ConsultaPontosRequest {
	tipoCnh?: string;
	numRegistro?: string;
	cpf?: string;
	dataNascimento: string;
	flagDozeMeses: boolean;
}
