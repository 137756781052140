// ROUTES
import { ROUTE_IIRGD } from 'routes/paths';

import JustificativaAtendimento from 'components/Pages/JustificativaAtendimento';
import {
	ROUTE_IIRGD_DADOS_AGENDAMENTO,
	ROUTE_IIRGD_RECUPERAR_ATENDIMENTO,
	ROUTE_IIRGD_REIMPRESSAO_PROTOCOLO,
	ROUTE_IIRGD_DARE_RETAGUARDA,
	ROUTE_IIRGD_HABILITAR_RG_NATURALIZADO,
	ROUTE_IIRGD_JUSTIFICATIVA_ATENDIMENTO,
} from './paths';
import { ROUTE_IIRGD_PESQUISA_ANTECEDENTE } from '../Aac/routes/paths';
import { ROUTE_IIRGD_PESQUISAR_DBCIN } from '../ConsultarDBCIN/routes/paths';

// RG FIC Digital
import rgFicDigitalRoutes from '../RgFicDigital/routes';
import aacRoutes from '../Aac/routes';
import CINRoutes from '../EmitirCIN/routes';
import consultarCpfRoutes from '../ConsultarCpf/routes';
import consultarDBCINRoutes from '../ConsultarDBCIN/routes';
import RecuperarAtendimento from '../RecuperarAtendimento';
import ReimpressaoProtocolo from '../ReimpressaoProtocolo';
import Aac from '../Aac';

import DareRetaguarda from '../DareRetaguarda';
import HabilitarRgNaturalizado from '../HabilitarRgNaturalizado/Formulario';
import DadosDoAgendamento from '../DadosAgendamento';
import PesquisarDBCIN from '../ConsultarDBCIN/PesquisarDBCIN';

export const iirgdRoute = {
	name: 'IIRGD',
	route: ROUTE_IIRGD,
};

const routes = [
	{
		path: ROUTE_IIRGD_DADOS_AGENDAMENTO,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Agendamento',
			},
		],
		Component: DadosDoAgendamento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_RECUPERAR_ATENDIMENTO,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Recuperar atendimento',
			},
		],
		Component: RecuperarAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_JUSTIFICATIVA_ATENDIMENTO,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Justificativa atendimento',
			},
		],
		Component: JustificativaAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_REIMPRESSAO_PROTOCOLO,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Reimpressão de Protocolo',
			},
		],
		Component: ReimpressaoProtocolo,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_PESQUISA_ANTECEDENTE,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Atestado de Antecedentes Criminais',
			},
		],
		Component: Aac,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_DARE_RETAGUARDA,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'DARE',
			},
		],
		Component: DareRetaguarda,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_HABILITAR_RG_NATURALIZADO,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Supervisor',
			},
		],
		Component: HabilitarRgNaturalizado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD_PESQUISAR_DBCIN,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Consultar DBCIN',
			},
		],
		Component: PesquisarDBCIN,
		isAuthenticated: true,
		exact: true,
	},
];

const iirgdRoutes = routes.concat(
	rgFicDigitalRoutes,
	aacRoutes,
	CINRoutes,
	consultarCpfRoutes,
	consultarDBCINRoutes,
);

export default iirgdRoutes;
