import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_CDHU_MUTUARIOS_API } from 'services/rotasp/path';

// UTILS
import { firstLastName } from 'utils/genericFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarSolicitanteRequest } from './types';

// ACTIONS
import {
	cadastrarSolicitanteSuccess,
	cadastrarSolicitanteFailure,
} from './actions';

function* cadastrarSolicitanteRequest(request: ReducerAction) {
	const { token, payload }: { token?: string; payload: CadastrarSolicitanteRequest } = request;

	const {
		cpf,
		nome,
		email,
		celular,
		telefoneComercial,
		telefoneContato,
		telefoneResidencial,
	} = payload;


	const { primeiroNome, ultimoNome } = firstLastName(nome || '');

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_CDHU_MUTUARIOS_API,
		`solicitante`,
		{
			primeiroNome,
			sobrenome: ultimoNome,
			cpf,
			email,
			telCelular: celular,
			telComercial: telefoneComercial,
			telDeContato: telefoneContato,
			telResidencial: telefoneResidencial,
		}
	);

	if (response.status === 201 || response.status === 200) {
		yield put(cadastrarSolicitanteSuccess(response.data));
	} else {
		yield put(cadastrarSolicitanteFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_SOLICITANTE_REQUEST, cadastrarSolicitanteRequest),
]);
