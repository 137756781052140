import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, ConsultaMunicipio } from './types';

export const INITIAL_STATE: ConsultaMunicipio = {
	status: 0,
	municipios: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaMunicipio {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_MUNICIPIO_REQUEST: {
				draft.status = INITIAL_STATE.status;
				draft.municipios = INITIAL_STATE.municipios;
				break;
			}

			case Types.CONSULTA_MUNICIPIO_SUCCESS: {
				draft.municipios = action.payload;
				draft.status = action.payload.status;
				break;
			}

			case Types.CONSULTA_MUNICIPIO_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CONSULTA_MUNICIPIO_CLEAR: {
				draft.status = 0;
				draft.municipios = null;
				break;
			}

			default:
		}
	});
}
