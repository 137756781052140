import * as Yup from 'yup';

// TYPES
import {
	IProcedencia,
	IRequestTransferenciasMunicipiosEstados,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirTransferenciasMunicipiosEstados/types';

// UTILS
import {
	limparMascara,
	formatDateISO,
	separarDDD,
	completaZeroEsquerda,
} from 'utils/genericFunctions';
import { ICaracteristicaVeiculo } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/types';
import { caracteristicas } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV120/types';

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}
export interface IEmiteTransferenciaVeiculo {
	evento: IEvento;
	renavam: string;
	placa: string;
	protocoloRemacarcao: string;
	protocoloRemarcacao: string;
	codigoMunicipio: number | string;
	descricaoMunicipio: string;
	numeroEspelhoAnterior: string;
	chassi: string;
	chassiRemarcado: boolean;
	longIdMarca: number | string;
	descricaoMarca: string;
	anoFabricacao: number | string;
	anoModelo: number | string;
	longIdTipo: number | string;
	descricaoTipo: string;
	longIdCarroceria: number | string;
	descricaoCarroceria: string;
	longIdCor: number | string;
	descricaoCor: string;
	longIdCategoria: number | string;
	descricaoCategoria: string;
	longIdCombustivel: number | string;
	descricaoCombustivel: string;
	longIdEspecie: number | string;
	descricaoEspecie: string;
	capacidadePassageiros: number | string;
	capacidadeCarga: number | string;
	potencia: number | string;
	cilindrada: number | string;
	cmt: number | string;
	pbt: number | string;
	eixos: number | string;
	dataVenda: string;
	procedencia: IProcedencia;
	nomeProprietario: string;
	cepProprietario: string;
	bairroProprietario: string;
	enderecoProprietario: string;
	numeroProprietario: string;
	complementoProprietario: string;
	telefone: string;
	codigoMunicipioProprietario: number | string;
	descricaoMunicipioProprietario: string;
	codigoMunicipioPlacaAnterior: number | string;
	descricaoMunicipioPlacaAnterior: string;
	nomeProprietarioAnterior: string;
	placaAnterior: string;
	ufPlacaAnterior: string;
	identificacao: string;
	rgProprietario: string;
	orgaoExpedidorProprietario: string;
	ufProprietario: string;
	cpfCnpjProprietario: string;
	ufEspelhoAnterior: string;
	valorVenda: string;
	arrendatarioFinanceira: string;
	codigoFinanceira: string | number;
	numeroContrato: string;
	restricao: string;
	dataVigencia: string;
	autenticacaoDigitalDut: string;
	codBanco: string;
	dataContabil: string;
	nsuProdesp: string;
	documentos: any;
	servicoDigital: boolean;
	emailCidadao: string;
	dddTelefoneCidadao: string;
	taxaAutenticacaoDigital: string;
}

export const initialValues: IEmiteTransferenciaVeiculo = {
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
		identificacao: '',
		ip: '',
		canal: {
			id: 0,
			desc: '',
			localidade: {
				id: 0,
				desc: '',
			},
		},
	},
	renavam: '',
	protocoloRemacarcao: '',
	placa: '',
	protocoloRemarcacao: '',
	codigoMunicipio: '',
	descricaoMunicipio: '',
	numeroEspelhoAnterior: '',
	chassi: '',
	chassiRemarcado: false,
	longIdMarca: '',
	descricaoMarca: '',
	anoModelo: '',
	longIdTipo: '',
	descricaoTipo: '',
	longIdCarroceria: '',
	descricaoCarroceria: '',
	longIdCor: '',
	descricaoCor: '',
	longIdCategoria: '',
	descricaoCategoria: '',
	longIdCombustivel: '',
	descricaoCombustivel: '',
	longIdEspecie: '',
	descricaoEspecie: '',
	capacidadePassageiros: '',
	capacidadeCarga: '',
	potencia: '',
	cilindrada: '',
	cmt: '',
	pbt: '',
	eixos: '',
	procedencia: '',
	nomeProprietario: '',
	cepProprietario: '',
	bairroProprietario: '',
	enderecoProprietario: '',
	numeroProprietario: '',
	complementoProprietario: '',
	telefone: '',
	codigoMunicipioProprietario: '',
	descricaoMunicipioProprietario: '',
	codigoMunicipioPlacaAnterior: '',
	descricaoMunicipioPlacaAnterior: '',
	rgProprietario: '',
	orgaoExpedidorProprietario: '',
	ufProprietario: '',
	anoFabricacao: '',
	dataVenda: '',
	nomeProprietarioAnterior: '',
	placaAnterior: '',
	ufPlacaAnterior: '',
	identificacao: 'FISICA',
	cpfCnpjProprietario: '',
	ufEspelhoAnterior: '',
	valorVenda: '',
	arrendatarioFinanceira: '',
	codigoFinanceira: '',
	numeroContrato: '',
	restricao: '',
	dataVigencia: '',
	autenticacaoDigitalDut: '',
	codBanco: '',
	dataContabil: '',
	nsuProdesp: '',
	documentos: [],
	servicoDigital: true,
	emailCidadao: '',
	dddTelefoneCidadao: '',
	taxaAutenticacaoDigital: '',
};

export const schema = Yup.object<IEmiteTransferenciaVeiculo>().shape({
	placa: Yup.string().required('Campo Obrigatório: Placa.'),
	municipio: Yup.string().when(['codigoMunicipio', 'descricaoMunicipio'], {
		is: (codigoMunicipio, descricaoMunicipio) =>
			[codigoMunicipio, descricaoMunicipio].includes(''),
		then: Yup.string().required('Campo Obrigatório: Município.'),
	}),
	validacaoIdentificacao: Yup.string().when(['identificacao'], {
		is: identificacao =>
			!identificacao || (identificacao && identificacao === ''),
		then: Yup.string().required(
			'Campo Obrigatório: Identificação do Contribuinte.',
		),
	}),
	codigoMunicipioProprietario: Yup.string().required(
		'Campo Obrigatório: Município Proprietário.',
	),
	numeroEspelhoAnterior: Yup.string().required(
		'Campo Obrigatório: Espelho Anterior.',
	),
	chassi: Yup.string().required('Campo Obrigatório: Chassi.'),
	chassiRemarcado: Yup.string().required(
		'Campo Obrigatório: Chassi Remarcado.',
	),
	marca: Yup.string().when(['descricaoMarca', 'longIdMarca'], {
		is: (descricaoMarca, longIdMarca) =>
			[descricaoMarca, longIdMarca].includes(''),
		then: Yup.string().required('Campo Obrigatório: Marca.'),
	}),
	anoModelo: Yup.string().required('Campo Obrigatório: Ano Modelo.'),
	anoFabricacao: Yup.string().required('Campo Obrigatório: Ano Fabricação.'),
	tipo: Yup.string().when(['descricaoTipo', 'longIdTipo'], {
		is: (descricaoTipo, longIdTipo) => [descricaoTipo, longIdTipo].includes(''),
		then: Yup.string().required('Campo Obrigatório: Tipo.'),
	}),
	carroceria: Yup.string().when(['descricaoCarroceria', 'longIdCarroceria'], {
		is: (descricaoCarroceria, longIdCarroceria) =>
			[descricaoCarroceria, longIdCarroceria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Carroceria.'),
	}),
	cor: Yup.string().when(['descricaoCor', 'longIdCor'], {
		is: (descricaoCor, longIdCor) => [descricaoCor, longIdCor].includes(''),
		then: Yup.string().required('Campo Obrigatório: Cor.'),
	}),
	categoria: Yup.string().when(['descricaoCategoria', 'longIdCategoria'], {
		is: (descricaoCategoria, longIdCategoria) =>
			[descricaoCategoria, longIdCategoria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Categoria.'),
	}),
	combustivel: Yup.string().when(
		['descricaoCombustivel', 'longIdCombustivel'],
		{
			is: (descricaoCombustivel, longIdCombustivel) =>
				[descricaoCombustivel, longIdCombustivel].includes(''),
			then: Yup.string().required('Campo Obrigatório: Combustível.'),
		},
	),
	especie: Yup.string().when(['descricaoEspecie', 'longIdEspecie'], {
		is: (descricaoEspecie, longIdEspecie) =>
			[descricaoEspecie, longIdEspecie].includes(''),
		then: Yup.string().required('Campo Obrigatório: Espécie.'),
	}),
	capacidadePassageiros: Yup.string().required(
		'Campo Obrigatório: Capacidade Passageiros.',
	),
	capacidadeCarga: Yup.string().required(
		'Campo Obrigatório: Capacidade Carga.',
	),
	potencia: Yup.string().required('Campo Obrigatório: Potência.'),
	cilindrada: Yup.string().required('Campo Obrigatório: Cilindrada.'),
	cmt: Yup.string().required('Campo Obrigatório: CMT.'),
	pbt: Yup.string().required('Campo Obrigatório: PBT.'),
	eixos: Yup.string().required('Campo Obrigatório: Eixos.'),
	procedencia: Yup.string().required(
		'Campo Obrigatório: Procedência do veículo.',
	),
	validacaoOrgaoExpedidorProprietario: Yup.string().when(
		['orgaoExpedidorProprietario', 'identificacao'],
		{
			is: (orgaoExpedidorProprietario, identificacao) =>
				[orgaoExpedidorProprietario].includes('') && identificacao === 'FISICA',
			then: Yup.string().required('Campo Obrigatório: Orgão Expedidor.'),
		},
	),
	validacaoRgProprietario: Yup.string().when(
		['rgProprietario', 'identificacao'],
		{
			is: (rgProprietario, identificacao) =>
				[rgProprietario].includes('') && identificacao !== 'JURIDICA',
			then: Yup.string().required(
				'Campo Obrigatório: RG do Proprietário Atual.',
			),
		},
	),
	validacaoUfRgProprietario: Yup.string().when(
		['ufProprietario', 'identificacao'],
		{
			is: (ufProprietario, identificacao) =>
				[ufProprietario].includes('') && identificacao === 'FISICA',
			then: Yup.string().required(
				'Campo Obrigatório: UF do Proprietário Atual.',
			),
		},
	),
	nomeProprietario: Yup.string().required(
		'Campo Obrigatório: Nome do Proprietário.',
	),
	cepProprietario: Yup.string().required(
		'Campo Obrigatório: Cep do Imóvel do Proprietário.',
	),
	bairroProprietario: Yup.string().required('Campo Obrigatório: Bairro.'),
	enderecoProprietario: Yup.string().required('Campo Obrigatório: Endereço.'),
	numeroProprietario: Yup.string().required('Campo Obrigatório: Número.'),
	cpfCnpjProprietario: Yup.string().required(
		'Campo Obrigatório: CPF/CNPJ Proprietário.',
	),
	validacaoNomeProprietarioAnterior: Yup.string().when(
		'nomeProprietarioAnterior',
		{
			is: nomeProprietarioAnterior => [nomeProprietarioAnterior].includes(''),
			then: Yup.string().required(
				'Campo Obrigatório: Nome do Proprietário Anterior.',
			),
		},
	),
	validacaoMunicipioPlacaAnterior: Yup.string().when(
		['codigoMunicipioPlacaAnterior', 'descricaoMunicipioPlacaAnterior'],
		{
			is: (codigoMunicipioPlacaAnterior, descricaoMunicipioPlacaAnterior) =>
				[
					codigoMunicipioPlacaAnterior,
					descricaoMunicipioPlacaAnterior,
				].includes(''),
			then: Yup.string().required(
				'Campo Obrigatório: Município Placa Anterior.',
			),
		},
	),
	validacaoPlacaAnterior: Yup.string().when('placaAnterior', {
		is: placaAnterior => [placaAnterior].includes(''),
		then: Yup.string().required('Campo Obrigatório: Placa Anterior.'),
	}),
	validacaoUfPlacaAnterior: Yup.string().when('ufPlacaAnterior', {
		is: ufPlacaAnterior => [ufPlacaAnterior].includes(''),
		then: Yup.string().required('Campo Obrigatório: UF da Placa Anterior.'),
	}),
	validacaoEmailCidadao: Yup.string().when('emailCidadao', {
		is: emailCidadao => [emailCidadao].includes(''),
		then: Yup.string().required('Campo Obrigatório: E-mail.'),
	}),
	dataVenda: Yup.string().required('Campo Obrigatório: Data da Venda.'),
	valorVenda: Yup.string().required('Campo Obrigatório: Valor da Venda.'),
	emailCidadao: Yup.string().when('servicoDigital', {
		is: servicoDigital => servicoDigital === true,
		then: Yup.string().required(`Campo Obrigatório: E-mail.`),
	}),
	dddTelefoneCidadao: Yup.string().when('servicoDigital', {
		is: servicoDigital => servicoDigital === true,
		then: Yup.string()
			.test(
				'len',
				'Insira o DDD e o número do telefone.',
				(val: any) => val.replace(/\D/g, '').length === 11,
			)
			.required(`Campo Obrigatório: Telefone de Contato.`),
	}),
	documentos: Yup.string().when('servicoDigital', {
		is: servicoDigital => servicoDigital === true,
		then: Yup.string().required(`Campo Obrigatório: Anexo de documento.`),
	}),
});

export function treatValues(
	formValues: IEmiteTransferenciaVeiculo,
	veiculo: ICaracteristicaVeiculo,
	evento: IEvento,
	// codigoMunicipioProdesp: number,
): IRequestTransferenciasMunicipiosEstados {
	const {
		anoModelo,
		capacidadeCarga,
		capacidadePassageiros,
		chassi,
		chassiRemarcado,
		cilindrada,
		cmt,
		eixos,
		numeroEspelhoAnterior,
		pbt,
		placa,
		potencia,
		procedencia,
		longIdCarroceria,
		descricaoCarroceria,
		longIdCategoria,
		descricaoCategoria,
		longIdCombustivel,
		descricaoCombustivel,
		longIdCor,
		descricaoCor,
		longIdEspecie,
		descricaoEspecie,
		longIdMarca,
		descricaoMarca,
		longIdTipo,
		descricaoTipo,
		nomeProprietario,
		cepProprietario,
		enderecoProprietario,
		numeroProprietario,
		complementoProprietario,
		bairroProprietario,
		telefone,
		codigoMunicipioProprietario,
		descricaoMunicipioProprietario,
		codigoMunicipioPlacaAnterior,
		descricaoMunicipioPlacaAnterior,
		nomeProprietarioAnterior,
		placaAnterior,
		ufPlacaAnterior,
		rgProprietario,
		orgaoExpedidorProprietario,
		ufProprietario,
		identificacao,
		cpfCnpjProprietario,
		anoFabricacao,
		renavam,
		protocoloRemacarcao,
		ufEspelhoAnterior,
		valorVenda,
		dataVenda,
		arrendatarioFinanceira,
		dataVigencia,
		codigoFinanceira,
		numeroContrato,
		restricao,
		// autenticacaoDigitalDut,
		codBanco,
		dataContabil,
		nsuProdesp,
		documentos,
		emailCidadao,
		dddTelefoneCidadao,
		servicoDigital,
	} = formValues;

	const { ddd, numTelefone } = separarDDD(dddTelefoneCidadao);
	const requestValues: IRequestTransferenciasMunicipiosEstados = {
		evento: {
			id_atendimento: evento.id_atendimento,
			id_cidadao: evento.id_cidadao,
			ip: evento.ip,
			identificacao: evento.identificacao,
			cpf: evento.cpf || '',
			canal: {
				id: 1,
				desc: 'Presencial',
				localidade: {
					desc: evento.canal?.localidade.desc || '',
					id: evento.canal?.localidade.id || 0,
				},
			},
		},
		dadosTransferencia: {
			protocoloRemacarcao: protocoloRemacarcao
				? Number(protocoloRemacarcao)
				: 0,
			renavam: Number(renavam),
			anoFabricacao: Number(anoFabricacao),
			anoModelo: Number(anoModelo),
			capacidadeCarga: capacidadeCarga ? Number(capacidadeCarga) : 0,
			capacidadePassageiros: Number(capacidadePassageiros),
			chassi,
			chassiRemarcado,
			cilindrada: Number(cilindrada),
			cmt: Number(cmt),
			eixos: Number(eixos),
			numeroEspelhoAnterior: numeroEspelhoAnterior
				? Number(numeroEspelhoAnterior)
				: 0,
			pbt: Number(pbt),
			placa,
			potencia: Number(potencia),
			procedencia,
			carroceria: {
				longId: Number(longIdCarroceria),
				descricao: descricaoCarroceria,
			},
			categoria: {
				longId: Number(longIdCategoria),
				descricao: descricaoCategoria,
			},
			combustivel: {
				longId: Number(longIdCombustivel),
				descricao: descricaoCombustivel,
			},
			cor: {
				longId: Number(longIdCor),
				descricao: descricaoCor,
			},
			dataVenda: formatDateISO(dataVenda),
			especie: {
				longId: Number(longIdEspecie),
				descricao: descricaoEspecie,
			},
			gravame: {
				arrendatarioFinanceira,
				dataVigencia: formatDateISO(dataVigencia),
				financeira: Number(codigoFinanceira),
				numeroContrato,
				restricaoFinanceira: restricao,
			},
			marca: {
				longId: Number(longIdMarca),
				descricao: descricaoMarca,
			},
			municipio: {
				codigo: Number(codigoMunicipioProprietario),
				nome: descricaoMunicipioProprietario,
			},
			municipioPlacaAnterior: {
				codigo: Number(codigoMunicipioPlacaAnterior),
				nome: descricaoMunicipioPlacaAnterior,
			},
			tipo: {
				longId: Number(longIdTipo),
				descricao: descricaoTipo,
			},
			proprietario: {
				nomeProprietario,
				cepProprietario: Number(limparMascara(cepProprietario)),
				enderecoProprietario,
				numero: Number(numeroProprietario),
				complemento: complementoProprietario,
				bairro: bairroProprietario,
				telefone: telefone ? Number(limparMascara(telefone)) : 0,
				identificacao,
				nomeProprietarioAnterior,
				cpfCnpjProprietario: Number(limparMascara(cpfCnpjProprietario)),
				municipioProprietario: {
					// codigo: Number(codigoMunicipioProdesp),
					codigo: Number(codigoMunicipioProprietario),
					nome: descricaoMunicipioProprietario,
				},
			},
			ufPlacaAnterior,
			ufEspelhoAnterior,
			placaAnterior,
			valorVenda: parseFloat(valorVenda),
			documentos,
			emailCidadao,
			dddTelefoneCidadao: ddd ? Number(ddd) : 0,
			telefoneCidadao: numTelefone ? Number(numTelefone) : 0,
			servicoDigital,
		},
		taxa: {
			// autenticacaoDigitalDut,
			codBanco: completaZeroEsquerda(codBanco, 3),
			dataContabil: formatDateISO(dataContabil),
			nsuProdesp: completaZeroEsquerda(nsuProdesp, 9),
		},
	};

	if (identificacao === 'FISICA' && limparMascara(rgProprietario)) {
		requestValues.dadosTransferencia.proprietario.rgProprietario = Number(
			limparMascara(rgProprietario),
		);
		requestValues.dadosTransferencia.proprietario.orgaoExpedidorProprietario =
			orgaoExpedidorProprietario;
		requestValues.dadosTransferencia.proprietario.ufProprietario =
			ufProprietario;
	}

	return requestValues;
}

export function treatValues2(
	formValues: IEmiteTransferenciaVeiculo,
	veiculo: caracteristicas,
	evento: IEvento,
): IRequestTransferenciasMunicipiosEstados {
	const {
		anoModelo,
		capacidadeCarga,
		capacidadePassageiros,
		chassi,
		chassiRemarcado,
		cilindrada,
		cmt,
		eixos,
		numeroEspelhoAnterior,
		pbt,
		placa,
		potencia,
		procedencia,
		longIdCarroceria,
		descricaoCarroceria,
		longIdCategoria,
		descricaoCategoria,
		longIdCombustivel,
		descricaoCombustivel,
		longIdCor,
		descricaoCor,
		longIdEspecie,
		descricaoEspecie,
		longIdMarca,
		descricaoMarca,
		longIdTipo,
		descricaoTipo,
		nomeProprietario,
		cepProprietario,
		enderecoProprietario,
		numeroProprietario,
		complementoProprietario,
		bairroProprietario,
		telefone,
		codigoMunicipioProprietario,
		descricaoMunicipioProprietario,
		codigoMunicipioPlacaAnterior,
		descricaoMunicipioPlacaAnterior,
		nomeProprietarioAnterior,
		placaAnterior,
		ufPlacaAnterior,
		rgProprietario,
		orgaoExpedidorProprietario,
		ufProprietario,
		identificacao,
		cpfCnpjProprietario,
		anoFabricacao,
		renavam,
		protocoloRemacarcao,
		ufEspelhoAnterior,
		valorVenda,
		dataVenda,
		arrendatarioFinanceira,
		dataVigencia,
		codigoFinanceira,
		numeroContrato,
		restricao,
		// autenticacaoDigitalDut,
		codBanco,
		dataContabil,
		nsuProdesp,
		documentos,
		emailCidadao,
		dddTelefoneCidadao,
		servicoDigital,
	} = formValues;

	const { ddd, numTelefone } = separarDDD(dddTelefoneCidadao);
	const requestValues: IRequestTransferenciasMunicipiosEstados = {
		evento: {
			id_atendimento: evento.id_atendimento,
			id_cidadao: evento.id_cidadao,
			ip: evento.ip,
			identificacao: evento.identificacao,
			cpf: evento.cpf || '',
			canal: {
				id: 1,
				desc: 'Presencial',
				localidade: {
					desc: evento.canal?.localidade.desc || '',
					id: evento.canal?.localidade.id || 0,
				},
			},
		},
		dadosTransferencia: {
			protocoloRemacarcao: protocoloRemacarcao
				? Number(protocoloRemacarcao)
				: 0,
			renavam: Number(renavam),
			anoFabricacao: Number(anoFabricacao),
			anoModelo: Number(anoModelo),
			capacidadeCarga: capacidadeCarga ? Number(capacidadeCarga) : 0,
			capacidadePassageiros: Number(capacidadePassageiros),
			chassi,
			chassiRemarcado,
			cilindrada: Number(cilindrada),
			cmt: Number(cmt),
			eixos: Number(eixos),
			numeroEspelhoAnterior: numeroEspelhoAnterior
				? Number(numeroEspelhoAnterior)
				: 0,
			pbt: Number(pbt),
			placa,
			potencia: Number(potencia),
			procedencia,
			carroceria: {
				longId: Number(longIdCarroceria),
				descricao: descricaoCarroceria,
			},
			categoria: {
				longId: Number(longIdCategoria),
				descricao: descricaoCategoria,
			},
			combustivel: {
				longId: Number(longIdCombustivel),
				descricao: descricaoCombustivel,
			},
			cor: {
				longId: Number(longIdCor),
				descricao: descricaoCor,
			},
			dataVenda: formatDateISO(dataVenda),
			especie: {
				longId: Number(longIdEspecie),
				descricao: descricaoEspecie,
			},
			gravame: {
				arrendatarioFinanceira,
				dataVigencia: formatDateISO(dataVigencia),
				financeira: Number(codigoFinanceira),
				numeroContrato,
				restricaoFinanceira: restricao,
			},
			marca: {
				longId: Number(longIdMarca),
				descricao: descricaoMarca,
			},
			municipio: {
				codigo: Number(codigoMunicipioProprietario),
				nome: descricaoMunicipioProprietario,
			},
			municipioPlacaAnterior: {
				codigo: Number(codigoMunicipioPlacaAnterior),
				nome: descricaoMunicipioPlacaAnterior,
			},
			tipo: {
				longId: Number(longIdTipo),
				descricao: descricaoTipo,
			},
			proprietario: {
				nomeProprietario,
				cepProprietario: Number(limparMascara(cepProprietario)),
				enderecoProprietario,
				numero: Number(numeroProprietario),
				complemento: complementoProprietario,
				bairro: bairroProprietario,
				telefone: telefone ? Number(limparMascara(telefone)) : 0,
				identificacao,
				nomeProprietarioAnterior,
				cpfCnpjProprietario: Number(limparMascara(cpfCnpjProprietario)) || 0,
				municipioProprietario: {
					// codigo: Number(codigoMunicipioProdesp),
					codigo: Number(codigoMunicipioProprietario),
					nome: descricaoMunicipioProprietario,
				},
			},
			ufPlacaAnterior,
			ufEspelhoAnterior,
			placaAnterior,
			valorVenda: parseFloat(valorVenda),
			documentos,
			emailCidadao,
			dddTelefoneCidadao: ddd !== '' ? Number(ddd) : 0,
			telefoneCidadao: numTelefone !== '' ? Number(numTelefone) : 0,
			servicoDigital,
		},
		taxa: {
			// autenticacaoDigitalDut,
			codBanco: completaZeroEsquerda(codBanco, 3),
			dataContabil: formatDateISO(dataContabil),
			nsuProdesp: completaZeroEsquerda(nsuProdesp, 9),
		},
	};

	if (
		(identificacao === ' ' || identificacao === 'FISICA') &&
		limparMascara(rgProprietario)
	) {
		requestValues.dadosTransferencia.proprietario.rgProprietario = Number(
			limparMascara(rgProprietario),
		);
		requestValues.dadosTransferencia.proprietario.orgaoExpedidorProprietario =
			orgaoExpedidorProprietario;
		requestValues.dadosTransferencia.proprietario.ufProprietario =
			ufProprietario;
	}

	return requestValues;
}
