import { ReducerAction } from 'store/modules/types';

import { Types, IRequestPreCadastroRenovacaoCnh } from './types';

export function confirmaPreCadastroRenovacaoRequest(
	data: IRequestPreCadastroRenovacaoCnh,
): ReducerAction {
	return {
		type: Types.CONFIRMA_PRE_CADASTRO_RENOVACAO,
		payload: data,
	};
}
export function confirmaPreCadastroRenovacaoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONFIRMA_PRE_CADASTRO_RENOVACAO_FAILURE,
		payload,
	};
}
export function confirmaPreCadastroRenovacaoFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.CONFIRMA_PRE_CADASTRO_RENOVACAO_FAILURE,
		payload,
	};
}
