// TYPES
import { ReducerAction } from 'store/modules/types';
import { IConsultarDare, Types } from './types';

export interface IRequestDareGuia {
	dare: string;
	orgao: number;
}

export interface IRequestDareCpf {
	cpf: string;
	orgao: number;
}

export interface IRequestTaxasDare {
	taxasCorreio: boolean;
	numeroRg: string;
	nome: string;
}

export interface IRequestGerarDare {
	cpf: string;
	enderecoContribuinte: string;
	postoCodigo: number;
	postoDescricao: string;
	ipUsuario: string;
	emailTo: string;
	taxasReq: IRequestTaxasDare[];
}

export function consultarDareGuiaRequest(
	payload: IRequestDareGuia,
): ReducerAction {
	return {
		type: Types.CONSULTAR_DARE_GUIA_REQUEST,
		payload,
	};
}

export function consultarDareCpfRequest(
	payload: IRequestDareCpf,
): ReducerAction {
	return {
		type: Types.CONSULTAR_DARE_CPF_REQUEST,
		payload,
	};
}

export function consultarDareSuccess(
	status: number,
	data: IConsultarDare[] | null,
): ReducerAction {
	return {
		type: Types.CONSULTAR_DARE_SUCCESS,
		payload: {
			status,
			data,
		},
	};
}

export function consultarDareClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_DARE_CLEAR,
		payload: null,
	};
}
