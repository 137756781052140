import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { Types, CadastrarSiglaRequest } from './types';

// ACTIONS
import { cadastrarSiglaSuccess, cadastrarSiglaFailure } from './actions';

function* cadastrarSigla(request: ReducerAction) {
	const { payload }: { payload: CadastrarSiglaRequest } = request;

	let body = { ...payload };

	delete body.id;

	body = cleanEmpty(body) as CadastrarSiglaRequest;

	const response: ApiResponse = yield call(postApi, PATH_SGU, `siglas`, body);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarSiglaSuccess(response));
	} else {
		yield put(cadastrarSiglaFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_SIGLA_REQUEST, cadastrarSigla)]);
