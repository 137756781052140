import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// UTILS
import { formatDate, treatObjNullValues } from 'utils/genericFunctions';
import {
	isRgAcervo,
	isRgPrimeiraVia,
	isRgSegundaVia,
} from 'pages/iirgd/RgFicDigital/utils/rgFicDigitalFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';
import { IConsultaPesquisaCidadaoSelecionado } from '../consultaPesquisaCidadao/types';

// ACTIONS
import {
	consultaPesquisaCirgSuccess,
	consultaPesquisaCirgFailure,
	consultaPesquisaCirgMaeSuccess,
	consultaPesquisaCirgMaeFailure,
} from './actions';
import {
	cadastroRgConfigAcervo,
	cadastroRgConfigEdicao,
	cadastroRgDataNascimento,
	cadastroRgOriginal,
	cadastroRgPrimeiraVia,
	cadastroRgSegundaVia,
} from '../cadastroRg/actions';
import { consultaPesquisaObservacaoClear } from '../consultaPesquisaObservacao/actions';

// UTILS
import { formatarCadastroRgOriginal } from './functions';

function* consultaPesquisaCirgRequest(request: ReducerAction) {
	const { payload } = request;
	const { selecionado } = payload;
	const pesquisaCivil: IConsultaPesquisaCidadaoSelecionado = selecionado;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		'consultaPesquisaCIRG',
		{
			numeroRg: pesquisaCivil.numeroRg,
			usuario: payload.usuario,
			senha: payload.senha,
		},
	);

	if (response.status === 200) {
		if (response.data && response.data.dataNascimento) {
			// Tratativa para tratar valores NULL.
			response.data = treatObjNullValues(response.data);

			/**
			 * RG - Tipos de Lotes:
			 * Primeira via => `000001` (Menor)
			 * Primeira via => `000010` (Maior)
			 * Primeira via => `10` (Maior)
			 * Segunda via  => `000002` (Menor / Maior)
			 * Segunda via  => `2` (Acervo) (?)
			 */

			if (response.data && response.data.lote) {
				if (isRgPrimeiraVia(response.data.lote)) {
					yield put(cadastroRgPrimeiraVia());
					yield put(cadastroRgConfigEdicao());
				} else if (isRgSegundaVia(response.data.lote)) {
					yield put(cadastroRgSegundaVia());

					if (isRgAcervo(response.data.lote)) {
						yield put(cadastroRgConfigAcervo());
					} else {
						yield put(cadastroRgConfigEdicao());
					}
				}
			}

			yield put(consultaPesquisaObservacaoClear());

			const dataNascimento = formatDate(response.data.dataNascimento);

			yield put(cadastroRgDataNascimento(dataNascimento));
		} else if (payload.data_nascimento_formatada) {
			const dataNascimento = formatDate(payload.data_nascimento_formatada);

			yield put(cadastroRgDataNascimento(dataNascimento));
		}

		const original = formatarCadastroRgOriginal(response.data);

		yield put(cadastroRgOriginal(original));
		yield put(consultaPesquisaCirgSuccess(response.data[0]));
	} else if (response.error) {
		yield put(consultaPesquisaCirgFailure());
	}
}

function* consultaPesquisaCirgMaeRequest(request: ReducerAction) {
	const { payload } = request;
	const {
		numeroRg,
		usuario,
		senha,
	}: { numeroRg: string; usuario: string; senha: string } = payload;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		'consultaPesquisaCIRG',
		{
			numeroRg,
			usuario,
			senha,
		},
	);

	if (response.status === 200) {
		yield put(consultaPesquisaCirgMaeSuccess(response.data));
	} else if (response.error) {
		yield put(consultaPesquisaCirgMaeFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTA_PESQUISA_CIRG_REQUEST, consultaPesquisaCirgRequest),
	takeLatest(
		Types.CONSULTA_PESQUISA_CIRG_MAE_REQUEST,
		consultaPesquisaCirgMaeRequest,
	),
]);
