import { combineReducers } from 'redux';

import imprimirIntencaoVenda from './Imprimir/reducer';
import cancelarIntencaoVenda from './Cancelar/reducer';
import incluirIntencaoVenda from './Incluir/reducer';
import consultarIntencaoVenda from './Consultar/reducer';

export default combineReducers({
	imprimirIntencaoVenda,
	cancelarIntencaoVenda,
	incluirIntencaoVenda,
	consultarIntencaoVenda,
});
