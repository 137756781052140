export enum Types {
	GERAR_OCORRENCIA_REQUEST = '@cdhu/GERAR_OCORRENCIA_REQUEST',
	GERAR_OCORRENCIA_SUCCESS = '@cdhu/GERAR_OCORRENCIA_SUCCESS',
	GERAR_OCORRENCIA_FAILURE = '@cdhu/GERAR_OCORRENCIA_FAILURE',
	GERAR_OCORRENCIA_CLEAR = '@cdhu/GERAR_OCORRENCIA_CLEAR',

	CALCULO_BOLETO_AGRUPADO_REQUEST = '@cdhu/CALCULO_BOLETO_AGRUPADO_REQUEST',
	CALCULO_BOLETO_AGRUPADO_CLEAR = '@cdhu/CALCULO_BOLETO_AGRUPADO_CLEAR',
}

export interface GerarOcorrencia {
	status: number;
	data: GerarOcorrenciaData | null;
	form: GerarOcorrenciaRequest | null;
	calculoBoletoAgrupado: CalculoBoletoAgrupadoRequest | null;
}

export interface GerarOcorrenciaData {
  acordo: boolean;
  boleto: boolean;
  boletoAgrupado: boolean;
  carne: boolean;
  ocorrenciaId: string;
  titulo: string;
  urlProcedimento: string;
  urlContratoInfoFinanceira: string;
  urlMunicipioInscricoesAbertas: string;

  debitos: string;
  fichaFinanceira: string;
  situacaoGeraldaPrestacao: string;
  statusField: string;
}

export interface GerarOcorrenciaRequest {
  alterarDadosMutuario?: boolean;
  alterarDadosSolicitante: boolean;
  servicoEnum: string; // BOLETO_AGRUPADO
  componenteContratoId: string;
  contratoCDHUId: string;
  descricao: string;
  emailMutuario: string;
  emailSolicitante: string;
  formaRetorno: string; // TELEFONE,
  motivoSolicitar2ViaBoleto: string; // PROBLEMAS_COM_CORREIO,
  motivoSolicitarBoletoAgrupado: string; // BOLETO_VENCIDO,
  motivoSolicitarCarne: string; // ACORDO,
  mutuarioId: string;
  origem: string; // TELEFONE,
  solicitanteId: string;
  telCelularMutuario: string;
  telCelularSolicitante: string;
  telComercialMutuario: string;
  telComercialSolicitante: string;
  telContatoMutuario?: string;
  telContatoSolicitante: string;
  telResidencialMutuario: string;
  telResidencialSolicitante: string;
  tipoOcorrencia: string; // DENUNCIA,
  tipoVinculoCDHU: string; // AGENTE_MUNICIPAL
	
	numeroContrato?: string;
}
export interface CalculoBoletoAgrupadoRequest {
	prds: boolean;
	parcelasEmAtraso: boolean;
	dataVencimento: string;
}
