export enum Types {
	ATUALIZAR_CONTRATO_REQUEST = '@BOLSA-POVO/ATUALIZAR_CONTRATO_REQUEST',
	ATUALIZAR_CONTRATO_SUCCESS = '@BOLSA-POVO/ATUALIZAR_CONTRATO_SUCCESS',
	ATUALIZAR_CONTRATO_FAILURE = '@BOLSA-POVO/ATUALIZAR_CONTRATO_FAILURE',
	LIMPAR_ATUALIZAR_CONTRATO = '@BOLSA-POVO/LIMPAR_ATUALIZAR_CONTRATO',
}

export interface AtualizarContato {
	status: number;
	form: AtualizarContatoRequest;
}

export interface AtualizarContatoRequest {
	cpf: string;
	idTipoContato: number | null | string;
	valor: string;
	id?: number;
}
