import { ReducerAction } from 'store/modules/types';

import { Types, ConsultarMunicipiosRequest } from './types';

export function consultarMunicipiosRequest(
	data: ConsultarMunicipiosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_REQUEST,
		payload: data,
	};
}
export function consultarMunicipiosSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_SUCCESS,
		payload,
	};
}
export function consultarMunicipiosFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_FAILURE,
		payload: null,
	};
}
export function consultarMunicipiosClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_CLEAR,
		payload: null,
	};
}
