export enum Types {
	GET_E_CPF_TOKEN_REQUEST = '@E-CPF/GET_E_CPF_TOKEN_REQUEST',
	GET_E_CPF_TOKEN_SUCCESS = '@E-CPF/GET_E_CPF_TOKEN_SUCCESS',
	GET_E_CPF_TOKEN_FAILURE = '@E-CPF/GET_E_CPF_TOKEN_FAILURE',
	GET_E_CPF_TOKEN_CLEAR = '@E-CPF/GET_E_CPF_TOKEN_CLEAR',
}

export interface GetToken {
	status: number;
	data: string;
}
