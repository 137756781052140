import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultaAdicaoCategoria } from './types';

export function consultaAdicaoCategoria(
	data: IRequestConsultaAdicaoCategoria,
): ReducerAction {
	return {
		type: Types.CONSULTA_ADICAO_CATEGORIA,
		payload: data,
	};
}
export function consultaAdicaoCategoriaSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_ADICAO_CATEGORIA_SUCCESS,
		payload,
	};
}
export function consultaAdicaoCategoriaFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_ADICAO_CATEGORIA_FAILURE,
		payload: null,
	};
}
