import React, { Suspense, useEffect } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import getValidationsErrors from 'utils/getValidationsErrors';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';
import Radio from 'components/Common/Form/Radio';
import { consultarVeiculoV110Request } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Placa from 'components/Common/Form/Fields/Placa';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ConsultarVeiculoV110Request } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/types';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { initialValues, schema } from './form';

const LicenciamentoVeiculo: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { consultarVeiculoV110 } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	useEffect(() => {
		if (consultarVeiculoV110.status === 200) {
			history.push('/detran-crv/licenciamento-veiculo/resultado');
		}
	}, [consultarVeiculoV110, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						const body: ConsultarVeiculoV110Request = {
							placa: values.placa,
							chassi: values.chassi,
							tipoConsulta: 'LICENCIAMENTO',
						};
						dispatch(consultarVeiculoV110Request(body));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form autoComplete="off">
								<Row>
									<Col span={11} offset={5}>
										<Row gutter={[0, 10]}>
											<Col style={{ marginLeft: '132px' }}>
												<Field
													as={Radio}
													name="tipoPesquisa"
													options={[
														{ label: 'Chassi', value: 'Chassi' },
														{ label: 'Placa', value: 'Placa' },
													]}
													defaultValue={initialValues.tipoPesquisa}
													onChange={(e: RadioChangeEvent) => {
														const { value } = e.target;

														formik.setFieldValue('tipoPesquisa', value);
														formik.setFieldValue('chassi', '');
														formik.setFieldValue('placa', '');
													}}
												/>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												{formik.values.tipoPesquisa === 'Chassi' ? (
													<Field
														as={Input}
														title="Documento"
														name="chassi"
														maxLength={22}
														size={96}
														required
														error={!!formik.errors.chassi}
													/>
												) : (
													<Placa
														title="Documento"
														titleSize="xl"
														name="placa"
														size={96}
														required
														formik={formik}
													/>
												)}
											</Col>
										</Row>
									</Col>
								</Row>

								<Row gutter={[0, 20]}>
									<Col span={24} />
								</Row>

								<Row justify="center">
									<Col>
										<ButtonImage
											src="limpar"
											onClick={() => {
												formik.handleReset();
												dispatch(clearNotifications());
											}}
										/>
									</Col>

									<Col offset={1}>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar />
		</Suspense>
	);
};

export default LicenciamentoVeiculo;
