import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RequestConsultaTokenCnhDigital } from './types';

// ACTIONS
import {
	consultaTokenCnhDigitalSuccess,
	consultaTokenCnhDigitalFailure,
} from './actions';

function* consultaTokenCnhDigital(request: ReducerAction) {
	const { payload }: { payload: RequestConsultaTokenCnhDigital } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`redis/${CHANNEL}/gera/token`,
		payload,
	);

	if (
		response.status === 200 ||
		response.status === 201 ||
		response.status === 204
	) {
		yield put(consultaTokenCnhDigitalSuccess({ response, request: payload }));
	} else if (response.error) {
		yield put(consultaTokenCnhDigitalFailure(response.error));
	}
}

export default all([
	takeLatest(Types.CONSULTA_TOKEN_CNH_DIGITAL_REQUEST, consultaTokenCnhDigital),
]);
