import { all } from 'redux-saga/effects';

import consultaAtendimentos from './consultaAtendimentos/saga';
import sinalizaResolucaoConsultaAtendimento from './sinalizaResolucaoConsultaAtendimento/saga';
import cancelaConsultaAtendimento from './cancelaConsultaAtendimento/saga';

export default all([
	consultaAtendimentos,
	sinalizaResolucaoConsultaAtendimento,
	cancelaConsultaAtendimento,
]);
