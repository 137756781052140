import { IConsultarDare } from 'store/modules/api/dare/consultarDare/types';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export interface IRequestAtualizarDare {
	numeroDare: string;
	rgAtendimento: string;
	isento: string;
	entregaCorreio: string;
	// ENVIAR O STATUS PARA GRAVAR NO CADASTRORG.DARE.STATUS
	dare?: IConsultarDare;
}

export function atualizarDareRequest(
	payload: IRequestAtualizarDare,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_DARE_REQUEST,
		payload,
	};
}
export function atualizarDareSuccess(payload: IConsultarDare): ReducerAction {
	return {
		type: Types.ATUALIZAR_DARE_SUCCESS,
		payload,
	};
}
export function atualizarDareFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_DARE_FAILURE,
		payload: null,
	};
}
export function atualizarDareClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_DARE_CLEAR,
		payload: null,
	};
}
