// MUTUÁRIO
import { ConsultarMutuario } from './consultarMutuario/types';

// PRESTAÇÕES ATRASADAS
import { ConsultarPrestacoesAtrasadas } from './consultarPrestacoesAtrasadas/types';

// BOLETO AGRUPADO
import { GerarBoletoAgrupado } from './gerarBoletoAgrupado/types';

// OCORRÊNCIA
import { GerarOcorrencia } from './gerarOcorrencia/types';

// SOLICITANTE
import { ConsultarSolicitante } from './consultarSolicitante/types';
import { CadastrarSolicitante } from './cadastrarSolicitante/types';

// ACORDO
import { SimularEftuarAcordo } from './simularEfetuarAcordo/types';

export enum Types {
	CDHU_CLEAR = '@cdhu/CDHU_CLEAR',
}

export interface CdhuState {
	// MUTUÁRIO
	consultarMutuario: ConsultarMutuario;

	// PRESTAÇÕES ATRASADAS
	consultarPrestacoesAtrasadas: ConsultarPrestacoesAtrasadas;

	// BOLETO AGRUPADO
	gerarBoletoAgrupado: GerarBoletoAgrupado;

	// OCORRÊNCIA
	gerarOcorrencia: GerarOcorrencia;

	// SOLICITANTE
	cadastrarSolicitante: CadastrarSolicitante;
	consultarSolicitante: ConsultarSolicitante;

	// ACORDO
	simularEfetuarAcordo: SimularEftuarAcordo;
}
