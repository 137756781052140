import React, { useEffect, useState } from 'react';
import AgendamentoPsicologicoRenachIntegrado from 'pages/DetranCnh/components/pages/AgendamentoPsicologicoRenachIntegrado';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// FORM
import { ROUTE_DETRAN_CNH_SEGUNDA_VIA_RENACH } from 'pages/DetranCnh/routes/paths';
import { initialValues, dadosCidadao } from './form';

const AgendamentoPsicologicoSegundaVia: React.FC = () => {
	// const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);

	const { saveGerarRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const consultaCategoria = useSelector(
		(state: ApplicationState) => state.api.detranCnh.consulta.consultaCategoria,
	);

	useEffect(() => {
		if (saveGerarRenach.data?.gerarRenachBase) {
			const {
				cpf,
				logradouroCep,
				categoriaPretendida,
				codCiretranHabilitacao,
			} = saveGerarRenach.data?.gerarRenachBase;

			initialValues.categoria_pretendida =
				consultaCategoria.divisaoEquitativa.filter(
					divisaoEquitativa => divisaoEquitativa.codigo === categoriaPretendida,
				)[0].codigoDivisaoEquitativa;
			initialValues.cpf_cidadao = Number(cpf);
			initialValues.cep_cidadao = Number(logradouroCep);
			initialValues.codigo_ciretran_exame_psicologo = Number(
				codCiretranHabilitacao,
			);
			// initialValues.renach = data.numRenachGerado;
		}
	}, [
		consultaCategoria,
		consultaCategoria.divisaoEquitativa,
		saveGerarRenach.data,
	]);

	useEffect(() => {
		if (saveGerarRenach.data?.gerarRenachBase) {
			const {
				cpf,
				nome,
				nomeSocial,
				nomePai,
				nomeMae,
				numDocumento,
				dataNascimento,
				sexo,
				nacionalidade,
				codigoNaturalidade,
				categoriaPretendida,
				logradouroCep,
				flagDeficienteFisico,
				rneNumero,
			} = saveGerarRenach.data?.gerarRenachBase;

			// DADOS CIDADÃO
			dadosCidadao.nome = nomeSocial || nome;
			dadosCidadao.nomeMae = nomeMae;
			dadosCidadao.nomePai = nomePai;
			dadosCidadao.numeroRg = numDocumento;
			dadosCidadao.cpf = cpf;
			dadosCidadao.sexo = sexo;
			dadosCidadao.nacionalidade = nacionalidade;
			dadosCidadao.cep = logradouroCep;
			dadosCidadao.dataNascimento = dataNascimento;
			dadosCidadao.naturalidade = codigoNaturalidade || '';
			dadosCidadao.categoriaPretendida =
				consultaCategoria.divisaoEquitativa.filter(
					divisaoEquitativa => divisaoEquitativa.codigo === categoriaPretendida,
				)[0].codigoDivisaoEquitativa;
			dadosCidadao.isPortadorNecessidadesEspeciais =
				!!flagDeficienteFisico || flagDeficienteFisico === 'S';
			dadosCidadao.rne = rneNumero || '';

			setIsLoading(false);
		}
	}, [consultaCategoria.divisaoEquitativa, saveGerarRenach.data]);

	return (
		<>
			{!isLoading && (
				<AgendamentoPsicologicoRenachIntegrado
					dadosCidadao={dadosCidadao}
					dadosAgendamento={initialValues}
					redirecionamento={ROUTE_DETRAN_CNH_SEGUNDA_VIA_RENACH}
				/>
			)}
		</>
	);
};
export default AgendamentoPsicologicoSegundaVia;
