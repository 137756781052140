import { combineReducers } from 'redux';

import consultarMunicipioPorUF from './consultarMunicipioPorUF/reducer';
import consultaCarroceria from './consultaCarroceria/reducer';
import consultaTipo from './consultaTipo/reducer';
import consultaCategoria from './consultaCategoria/reducer';
import consultaCor from './consultaCor/reducer';
import consultaCombustivel from './consultaCombustivel/reducer';
import consultaEspecie from './consultaEspecie/reducer';
import consultarTipoModificacaoAlteracao from './consultarTipoModificacaoAlteracao/reducer';
import consultaMarcaModelo from './consultaMarcaModelo/reducer';
import consultarUnidadesTransito from './consultaUnidadeTransito/reducer';
import consultarMunicipioCodigoProdesp from './consultarMunicipioCodigoProdesp/reducer';

export default combineReducers({
	consultarMunicipioPorUF,
	consultaCarroceria,
	consultaTipo,
	consultaCategoria,
	consultaCor,
	consultaCombustivel,
	consultaEspecie,
	consultarTipoModificacaoAlteracao,
	consultaMarcaModelo,
	consultarUnidadesTransito,
	consultarMunicipioCodigoProdesp,
});
