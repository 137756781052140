import { combineReducers } from 'redux';

import atualizarMotivo from './atualizarMotivo/reducer';
import cadastrarMotivo from './cadastrarMotivo/reducer';
import consultarMotivos from './consultarMotivos/reducer';
import excluirMotivo from './excluirMotivo/reducer';

export default combineReducers({
	atualizarMotivo,
	cadastrarMotivo,
	consultarMotivos,
	excluirMotivo,
});
