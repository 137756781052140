// PATHS
import {
	ROUTE_DETRAN_CRV_MULTAS_RENAINF_ORGAO_AUTUADOR,
	ROUTE_DETRAN_CRV_MULTAS_RENAINF_ORGAO_AUTUADOR_RESULTADO,
	ROUTE_DETRAN_CRV_MULTAS_RENAINF_PLACA,
	ROUTE_DETRAN_CRV_MULTAS_RENAINF_PLACA_RESULTADO,
} from '../routes/paths';
// // POR ORGÃO AUTUADOR
import MultasRenainfPorOrgao from './PorOrgaoAutuador';
import MultasRenainfPorOrgaoResultado from './PorOrgaoAutuador/ResultadoConsulta';
// // POR PLACA
import MultasRenainfPorPlaca from './PorPlaca';
import MultasRenainfPorPlacaResultado from './PorPlaca/ResultadoConsulta';

export const detranCrvRoute = {
	name: 'DETRAN CRV',
	route: '/detran-crv',
};

export default [
	{
		path: ROUTE_DETRAN_CRV_MULTAS_RENAINF_PLACA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar RENAINF por Placa',
			},
		],
		Component: MultasRenainfPorPlaca,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_MULTAS_RENAINF_PLACA_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar RENAINF por Placa',
			},
		],
		Component: MultasRenainfPorPlacaResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_MULTAS_RENAINF_ORGAO_AUTUADOR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar RENAINF por Orgão Autuador',
			},
		],
		Component: MultasRenainfPorOrgao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV_MULTAS_RENAINF_ORGAO_AUTUADOR_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar RENAINF por Orgão Autuador',
			},
		],
		Component: MultasRenainfPorOrgaoResultado,
		isAuthenticated: true,
		exact: true,
	},
];
