import * as Yup from 'yup';

export interface NovoMenuOrgaoFormData {
	idOrgao: string | number;
	idLocal: string | number;
	hasMainFrame: boolean;
	senhaMainframeConferida: boolean;
	idMainframe: string;
	senhaMainframe: string;
	posto: string | number;
	digito_posto: string;
	novaSenhaMainframe: string;
	confirmaNovaSenhaMainframe: string;
	nomePosto: string;
	validaIdMainframe?: string;
}

export const novoInitialValuesMenuOrgaoFormData: NovoMenuOrgaoFormData = {
	idOrgao: '',
	idLocal: '',
	hasMainFrame: false,
	senhaMainframeConferida: false,
	idMainframe: '',
	senhaMainframe: '',
	posto: '',
	digito_posto: '',
	novaSenhaMainframe: '',
	confirmaNovaSenhaMainframe: '',
	nomePosto: '',
};

export const schema = Yup.object().shape({
	idLocal: Yup.string().required(`Campo Obrigatório: Localidade.`),
	idOrgao: Yup.string().required(`Campo Obrigatório: Órgão.`),
	validaIdMainframe: Yup.string().when(['idOrgao', 'idMainframe', 'idLocal'], {
		is: (idOrgao, idMainframe, idLocal) =>
			idLocal &&
			(idOrgao === '1' || idOrgao === 1 || idOrgao === '2' || idOrgao === 2) &&
			(idMainframe === '' || idMainframe === null || idMainframe === undefined),
		then: Yup.string().required(`Campo Obrigatório: Mainframe.`),
	}),
	novaSenhaMainframe: Yup.string().when(
		['hasMainFrame', 'idMainframe', 'senhaMainframe'],
		{
			is: (hasMainFrame, idMainframe, senhaMainframe) =>
				hasMainFrame &&
				idMainframe &&
				(senhaMainframe === '' || senhaMainframe === null),
			then: Yup.string().required(`Campo Obrigatório: Senha Mainframe.`),
		},
	),
});

export const redirect = (idOrgao: string | number) => {
	let url = '';

	switch (Number(idOrgao)) {
		case 1:
			url = '/iirgd';
			break;
		case 2:
			url = '/detran-cnh';
			break;
		case 3:
			url = '/ministerio-publico';
			break;
		case 4:
			url = '/cdhu';
			break;
		case 5:
			url = '/detran-crv';
			break;
		case 6:
			url = '/secretaria-educacao';
			break;
		case 8:
			url = '/procon';
			break;
		case 7:
			url = '/seds';
			break;
		case 10:
			url = '/prefeitura';
			break;
		case 12:
			url = '/poupatempo';
			break;
		case 13:
			url = '/bolsa-povo';
			break;
		default:
			url = '';
	}

	return url;
};
