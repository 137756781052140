import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarMunicipioCodigoProdespRequest } from './types';

export function consultarMunicipioCodigoProdespRequest(data: ConsultarMunicipioCodigoProdespRequest
	): ReducerAction {
	return {
		type: Types.CONSULTAR_MUNICIPIO_CODIGO_PRODESP_REQUEST,
		payload: data,
	};
}

export function consultarMunicipioCodigoProdespSuccess(payload: object) {
	return {
		type: Types.CONSULTAR_MUNICIPIO_CODIGO_PRODESP_SUCCESS,
		payload,
	};
}

export function consultarMunicipioCodigoProdespFailure(payload: []): any {
	return {
		type: Types.CONSULTAR_MUNICIPIO_CODIGO_PRODESP_FAILURE,
		payload,
	};
}
export function consultarMunicipioCodigoProdesClear(): any {
	return {
		type: Types.CONSULTAR_MUNICIPIO_CODIGO_PRODESP_CLEAR,
	};
}
