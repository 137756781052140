export enum Types {
	CONSULTAR_TIPOS = '@SGU/CONSULTAR_TIPOS',
	CONSULTAR_TIPOS_SUCCESS = '@SGU/CONSULTAR_TIPOS_SUCCESS',
	CONSULTAR_TIPOS_FAILURE = '@SGU/CONSULTAR_TIPOS_FAILURE',
	CONSULTAR_TIPOS_CLEAR = '@SGU/CONSULTAR_TIPOS_CLEAR',
}

export interface ConsultarTipos {
	status: number;
	data: null | ConsultarTiposData[];
}

export interface ConsultarTiposData {
	codigo: string;
	descricao: string;
}
