import styled from 'styled-components';

export const Container = styled.div`
	align-items: center;
	display: flex;
	width: 100%;
`;

export const Content = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
`;

export const Box = styled.div`
	align-items: center;
	display: flex;

	& + div {
		margin-left: 25px;
	}

	p {
		color: #000;
		margin-right: 5px;
	}
`;
