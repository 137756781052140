import produce from 'immer';

import { Types, GerarRenach } from './types';
import { ReducerAction } from '../../../../types';

// UTILS

export const INITIAL_STATE: GerarRenach = {
	status: 0,
	data: null,
	requestData: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): GerarRenach {
	return produce(state, draft => {
		switch (action.type) {
			case Types.GERAR_RENACH_REQUEST: {
				break;
			}
			case Types.GERAR_RENACH_SUCCESS: {
				draft.data = action.payload.response.data;
				draft.status = action.payload.response.status;
				draft.requestData = action.payload.request;
				break;
			}
			case Types.GERAR_RENACH_FAILURE: {
				break;
			}

			default:
		}
	});
}
