import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types, ConsultarVeiculoV110Request } from './types';
import {
	consultarVeiculoV110Success,
	consultarVeiculoV110Failure,
} from './actions';

function* consultarVeiculoV110Request(request: {
	type: string;
	payload: ConsultarVeiculoV110Request;
}) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consultar-veiculo-v110/${payload.tipoConsulta}`,
		{
			chassi: payload.chassi,
			placa: payload.placa,
		},
	);

	if (response.status === 200 || response.status === 201) {
		response.data = {
			caracteristicaVeiculo: {
				...response.data.caracteristicaVeiculo,
				chassi: response.data.caracteristicaVeiculo.chassi,
			},
		};

		yield put(consultarVeiculoV110Success(response));
	} else if (response.error) {
		yield put(consultarVeiculoV110Failure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_VEICULO_V110_REQUEST, consultarVeiculoV110Request),
]);
