import * as Yup from 'yup';

// UTILS
import { limparMascara } from 'utils/genericFunctions';

// REDUX
import { PesquisaFornecedorForm } from 'store/modules/api/procon/fornecedores/consultaFornecedor/types';

// FORM
import { FormikProps } from 'formik';

export interface CadastraFornecedorConsultaForm {
	razaoSocial: string;
	cnpj: string;
}

export interface CadastraFornecedorReclamacaoForm {
	tipo?: string;
	cnpj: string | null;
	cpf: string | null;
	email: string | null;
	razaoSocial: string | null;
	nomeFantasia: string | null;
	website: string | null;
	bairro: string | null;
	cep: string | null;
	complemento: string | null;
	estado: number | null;
	logradouro: string | null;
	municipioId: string | null;
	municipioDescricao: string | null;
	numero: string | null;
	telefone: string | null;
	celular: string | null;
}

export const initialValuesConsulta: CadastraFornecedorConsultaForm = {
	razaoSocial: '',
	cnpj: '',
};

export const initialValues: CadastraFornecedorReclamacaoForm = {
	tipo: 'CNPJ',
	cnpj: '',
	cpf: '',
	email: '',
	razaoSocial: '',
	nomeFantasia: '',
	website: '',
	bairro: '',
	cep: '',
	complemento: '',
	estado: null,
	logradouro: '',
	municipioId: '',
	municipioDescricao: '',
	numero: '',
	telefone: '',
	celular: '',
};

export function treatValues(
	values: CadastraFornecedorReclamacaoForm,
	formikPesquisa: FormikProps<PesquisaFornecedorForm>,
): CadastraFornecedorReclamacaoForm {
	const { telefone, celular } = values;

	delete values.tipo;

	values.cnpj = formikPesquisa.values.cnpj || '';

	values.cpf = formikPesquisa.values.cpf || '';

	values.telefone = telefone ? limparMascara(telefone) : telefone;

	values.celular = celular ? limparMascara(celular) : celular;

	return values;
}

export const schemaConsulta =
	Yup.object<CadastraFornecedorConsultaForm>().shape({
		razaoSocial: Yup.string().required('Campo Obrigatório: Razão Social.'),
	});

export const schema = Yup.object<CadastraFornecedorReclamacaoForm>().shape({
	validacaoCPF: Yup.string().when(['tipo', 'cpf'], {
		is: (tipo, cpf) => tipo === 'CPF' && cpf.length < 11,
		then: Yup.string().required('Campo Obrigatório: CPF.'),
	}),
	validacaoCNPJ: Yup.string().when(['tipo', 'cnpj'], {
		is: (tipo, cnpj) => tipo === 'CNPJ' && cnpj.length < 14,
		then: Yup.string().required('Campo Obrigatório: CNPJ.'),
	}),
	// email: Yup.string().required('Campo Obrigatório: E-mail.'),
	razaoSocial: Yup.string().required('Campo Obrigatório: Razão Social.'),
	// nomeFantasia: Yup.string().required('Campo Obrigatório: Nome Fantasia.'),
	// website: Yup.string().required('Campo Obrigatório: Web Site.'),
	// bairro: Yup.string().required('Campo Obrigatório: Bairro.'),
	// cep: Yup.string().required('Campo Obrigatório: CEP.'),
	// estado: Yup.number().required('Campo Obrigatório: Estado.'),
	// logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
	// municipioId: Yup.string().required('Campo Obrigatório: Município Id.'),
	// municipioDescricao: Yup.string().required('Campo Obrigatório: Município.'),
	// numero: Yup.string().required('Campo Obrigatório: Número.'),
	// telefone: Yup.string().required('Campo Obrigatório: Telefone.'),
	// celular: Yup.string().required('Campo Obrigatório: Celular.'),
	validacaoEmailOuEndereco: Yup.string().when(
		[
			'email',
			'bairro',
			'cep',
			'estado',
			'logradouro',
			'municipioId',
			'municipioDescricao',
			'numero',
		],
		{
			is: (
				email,
				bairro,
				cep,
				estado,
				logradouro,
				municipioId,
				municipioDescricao,
				numero,
			) =>
				email === '' &&
				bairro === '' &&
				cep === '' &&
				(estado === '' || estado === null) &&
				logradouro === '' &&
				municipioId === '' &&
				municipioDescricao === '' &&
				numero === '',
			then: Yup.string().required('Campos Obrigatórios: E-mail ou Endereço.'),
		},
	),
});
