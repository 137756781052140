export enum Types {
	CADASTRAR_USUARIO_MAINFRAME_REQUEST = '@SGU/CADASTRAR_USUARIO_MAINFRAME_REQUEST',
	CADASTRAR_USUARIO_MAINFRAME_SUCCESS = '@SGU/CADASTRAR_USUARIO_MAINFRAME_SUCCESS',
	CADASTRAR_USUARIO_MAINFRAME_FAILURE = '@SGU/CADASTRAR_USUARIO_MAINFRAME_FAILURE',
	CADASTRAR_USUARIO_MAINFRAME_CLEAR = '@SGU/CADASTRAR_USUARIO_MAINFRAME_CLEAR',
}

export interface CadastrarUsuarioMainframe {
	status: number;
	data: UsuarioMainframe | null;
}

export interface UsuarioMainframe {
	dataCadastro: string;
	idUsuarioMainframe: string;
	localidade: { id: number; descricao: string };
	orgao: { codigo: number; descricao: string };
}

export interface CadastrarUsuarioMainframeRequest {
	idOrgao: number;
	idLocalidadeUsuario: number;
	usuario: string;
	id: number;
}
