import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarMunicipiosRequest } from './types';

export function cadastrarMunicipiosRequest(
	data: ConsultarMunicipiosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_REQUEST,
		payload: data,
	};
}

export function cadastrarMunicipiosSuccess(payload: object) {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_SUCCESS,
		payload,
	};
}

export function cadastrarMunicipiosFailure(payload: object) {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_FAILURE,
		payload,
	};
}
