import * as Yup from 'yup';

export interface IIpressaoRenavam {
	numeroFicha: string | number;
	anoFicha: string | number;
}

export const initialValues: IIpressaoRenavam = {
	numeroFicha: '',
	anoFicha: '',
};

export const schema = Yup.object<IIpressaoRenavam>().shape({
	numeroFicha: Yup.string().required(`Campo Obrigatório: Número da Ficha.`),
	anoFicha: Yup.string().required(`Campo Obrigatório: Ano da Ficha Cadastral.`),
});
