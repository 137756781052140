import { ReducerAction } from 'store/modules/types';

import {
	IUsuario,
	Types,
	IRequestUsuario,
	IRequestBuscaDadosCadastrais,
	IRequestAtualizaDadosCadastrais,
	IRequestAlertas,
} from './types';

export function consultaUsuario(data: IRequestUsuario): ReducerAction {
	return {
		type: Types.CONSULTA_USUARIO,
		payload: data,
	};
}
export function consultaUsuarioSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_USUARIO_SUCCESS,
		payload,
	};
}
export function consultaUsuarioFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_USUARIO_FAILURE,
		payload: null,
	};
}

export function selecionaUsuario(data: IUsuario): ReducerAction {
	return {
		type: Types.SELECIONA_USUARIO,
		payload: data,
	};
}

export function consultaAtualizacaoCadastral(
	data: IRequestBuscaDadosCadastrais,
): ReducerAction {
	return {
		type: Types.CONSULTA_ATUALIZACAO_CADASTRAL,
		payload: data,
	};
}
export function consultaAtualizacaoCadastralSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_ATUALIZACAO_CADASTRAL_SUCCESS,
		payload,
	};
}
export function consultaAtualizacaoCadastralFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_ATUALIZACAO_CADASTRAL_FAILURE,
		payload: null,
	};
}

export function atualizaAtualizacaoCadastral(
	data: IRequestAtualizaDadosCadastrais,
): ReducerAction {
	return {
		type: Types.ATUALIZA_ATUALIZACAO_CADASTRAL,
		payload: data,
	};
}
export function atualizaAtualizacaoCadastralSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.ATUALIZA_ATUALIZACAO_CADASTRAL_SUCCESS,
		payload,
	};
}
export function atualizaAtualizacaoCadastralFailure(): ReducerAction {
	return {
		type: Types.ATUALIZA_ATUALIZACAO_CADASTRAL_FAILURE,
		payload: null,
	};
}

export function consultaAlertas(data: IRequestAlertas): ReducerAction {
	return {
		type: Types.CONSULTA_ALERTAS,
		payload: data,
	};
}
export function consultaAlertasSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_ALERTAS_SUCCESS,
		payload,
	};
}
export function consultaAlertasFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_ALERTAS_FAILURE,
		payload: null,
	};
}

export function consultaServicosEntidade(data: IRequestAlertas): ReducerAction {
	return {
		type: Types.CONSULTA_SERVICOS_ENTIDADES,
		payload: data,
	};
}
export function consultaServicosEntidadeSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_SERVICOS_ENTIDADES_SUCCESS,
		payload,
	};
}
export function consultaServicosEntidadeFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_SERVICOS_ENTIDADES_FAILURE,
		payload: null,
	};
}

export function clearRedirectUsuarioVivaLeite(): ReducerAction {
	return {
		type: Types.REDIRECT_USUARIO_VIVA_LEITE_CLEAR,
		payload: null,
	};
}

export function clearUsuarioVivaLeite(): ReducerAction {
	return {
		type: Types.USUARIO_CLEAR,
		payload: null,
	};
}

export function clearNotificationsUsuarioVivaLeite(): ReducerAction {
	return {
		type: Types.NOTIFICATION_USUARIO_VIVA_LEITE_CLEAR,
		payload: null,
	};
}
