import produce from 'immer';
import { noRepeat } from 'utils/genericFunctions';
import { Types, SGUState, OptionsMainframe } from './types';
import { ReducerAction } from '../types';

export const INITIAL_STATE: SGUState = {
	pesquisaNaturalidade: {
		status: 0,
		naturalidades: [],
	},
	listaCep: [],
	consultaMainframe: { status: 0, options: [], data: [], dataRequest: null },
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): SGUState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PESQUISA_NATURALIDADE_REQUEST: {
				draft.pesquisaNaturalidade.status = 100;
				break;
			}
			case Types.PESQUISA_NATURALIDADE_SUCCESS: {
				if (action.payload) {
					draft.pesquisaNaturalidade = {
						status: action.payload.status,
						naturalidades: action.payload,
					};
				} else {
					draft.pesquisaNaturalidade = {
						status: 0,
						naturalidades: [],
					};
				}
				draft.pesquisaNaturalidade.status = 200;
				break;
			}
			case Types.PESQUISA_NATURALIDADE_FAILURE: {
				draft.pesquisaNaturalidade = action.payload;
				draft.pesquisaNaturalidade.status = 400;
				break;
			}
			case Types.PESQUISA_NATURALIDADE_CLEAR: {
				draft.pesquisaNaturalidade = INITIAL_STATE.pesquisaNaturalidade;
				break;
			}

			case Types.LISTA_CEP: {
				break;
			}
			case Types.LISTA_CEP_SUCCESS: {
				draft.listaCep = action.payload;
				break;
			}
			case Types.LISTA_CEP_FAILURE: {
				draft.listaCep = action.payload;
				break;
			}
			case Types.CONSULTA_MAINFRAME: {
				const data = {
					options: INITIAL_STATE.consultaMainframe.options,
					data: INITIAL_STATE.consultaMainframe.data,
					status: 100,
					dataRequest: INITIAL_STATE.consultaMainframe.dataRequest,
				};
				draft.consultaMainframe = data;
				break;
			}
			case Types.CONSULTA_MAINFRAME_SUCCESS: {
				const newData: OptionsMainframe[] = [];
				action.payload.response.data.map((item: any) => {
					return newData.push({
						label:
							item.idUsuarioMainframe === null ? '' : item.idUsuarioMainframe,
						value:
							item.idUsuarioMainframe === null ? '' : item.idUsuarioMainframe,
						codigoLocalidade: action.payload.request.codigoLocalidade,
						senha: item.senha,
					});
				});

				// CHECK IF THERE IS ANY OPTION ON SAME DEPARTMENT
				const repeatIndex = draft.consultaMainframe.options?.findIndex(
					option => {
						return option.orgao === action.payload.request.orgao;
					},
				);

				if (repeatIndex > -1) {
					draft.consultaMainframe.options[repeatIndex].data.push(...newData);
					draft.consultaMainframe.options[repeatIndex].data = noRepeat(
						'label',
						draft.consultaMainframe.options[repeatIndex].data,
					);
				} else {
					draft?.consultaMainframe?.options.push({
						orgao: action.payload.request.orgao,
						data: [...newData],
					});
				}
				const data = {
					options: draft.consultaMainframe.options,
					data: action.payload.response.data,
					status: action.payload.response.status,
					dataRequest: action.payload.request,
				};
				draft.consultaMainframe = data;
				break;
			}
			case Types.CONSULTA_MAINFRAME_FAILURE: {
				draft.consultaMainframe = action.payload;
				break;
			}
			case Types.LIMPA_MAINFRAME: {
				return INITIAL_STATE;
			}

			default:
		}

		return draft;
	});
}
