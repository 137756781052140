import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AdminState } from './types';

import consultaMunicipio from './consultaMunicipio/reducer';

const allReducers = combineReducers({
	consultaMunicipio,
});

const combineReducer = (state: AdminState, action: ReducerAction) => {
	if (action.type === Types.ADMIN_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
