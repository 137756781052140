import { ReducerAction } from 'store/modules/types';

import { Types, RequestConsultaPrimeiraHabilitacao } from './types';

export function consultaPrimeiraHabilitacaoRequest(
	data: RequestConsultaPrimeiraHabilitacao,
): ReducerAction {
	return {
		type: Types.CONSULTA_PRIMEIRA_HABILITACAO_REQUEST,
		payload: data,
	};
}
export function consultaPrimeiraHabilitacaoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_PRIMEIRA_HABILITACAO_SUCCESS,
		payload,
	};
}
export function consultaPrimeiraHabilitacaoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_PRIMEIRA_HABILITACAO_FAILURE,
		payload,
	};
}

export function consultaPrimeiraHabilitacaoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_PRIMEIRA_HABILITACAO_CLEAR,
		payload: null,
	};
}
