import { ReducerAction } from 'store/modules/types';

import { Types, ConsultarSufixosRequest } from './types';

export function consultarSulfixosRequest(
	data: ConsultarSufixosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_SUFIXO,
		payload: data,
	};
}
export function consultarSulfixosSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_SUFIXO_SUCCESS,
		payload,
	};
}
export function consultarSulfixosFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_SUFIXO_FAILURE,
		payload: null,
	};
}

export function consultarSulfixosClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_SUFIXO_CLEAR,
		payload: null,
	};
}
