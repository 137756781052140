import React, { useCallback, useEffect, useState } from 'react';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from 'store';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import PDFViewer from 'components/Common/PDFViewer';
import { imprimirDareRequest } from 'store/modules/api/dare/imprimirDare/actions';

export const ImprimirDare: React.FC = () => {
	const dispatch = useDispatch();
	const { gerarDare } = useSelector((state: ApplicationState) => state.api);
	const { atualizarDare, imprimirDare } = useSelector(
		(state: ApplicationState) => state.api.dare,
	);

	const [PDFTitle, setPDFTitle] = useState<string>('');
	const [protocoloBase64, setProtocoloBase64] = useState<string>('');

	const handleOpenImpressaoDare = useCallback(() => {
		setPDFTitle(`Número do documento: ${atualizarDare.numeroDare}`);
		setProtocoloBase64(imprimirDare.data.arquivoPDF);
	}, [atualizarDare.numeroDare, imprimirDare.data.arquivoPDF]);

	const handleImprimir = useCallback(() => {
		if (gerarDare.data) {
			const { numeroControleGuiaMae, boletoRes } = gerarDare.data;

			setPDFTitle(`Número do documento: ${numeroControleGuiaMae}`);
			setProtocoloBase64(boletoRes.boletoBase64);
		}
		if (atualizarDare.data && atualizarDare.numeroDare) {
			if (imprimirDare.status === 200 && imprimirDare.data.arquivoPDF) {
				handleOpenImpressaoDare();
			} else {
				dispatch(imprimirDareRequest({ numeroDare: atualizarDare.numeroDare }));
			}
		}
	}, [
		gerarDare.data,
		atualizarDare.data,
		atualizarDare.numeroDare,
		imprimirDare,
		dispatch,
		handleOpenImpressaoDare,
	]);

	useEffect(() => {
		if (imprimirDare.status === 200 && imprimirDare.data.arquivoPDF) {
			handleOpenImpressaoDare();
		}
	}, [handleOpenImpressaoDare, imprimirDare]);

	const handleOnClose = useCallback(() => {
		setPDFTitle('');
		setProtocoloBase64('');
	}, []);

	return (
		<>
			<ButtonImage
				type="button"
				src="btn-imprimir-dare"
				onClick={handleImprimir}
			/>
			{PDFTitle && protocoloBase64 && (
				<PDFViewer
					title={PDFTitle}
					source={protocoloBase64}
					onClose={handleOnClose}
				/>
			)}
		</>
	);
};
