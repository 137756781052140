import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultarCondutorRequest, Types } from './types';

// ACTIONS
import { consultarCondutorSuccess, consultarCondutorFailure } from './actions';

function* consultarCondutor(request: ReducerAction) {
	const { payload }: { payload: ConsultarCondutorRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`sockets/${CHANNEL}/consulta/condutor`,
		payload,
	);

	if (response.status === 200 || response.status === 204) {
		yield put(consultarCondutorSuccess(response));
	} else if (response.error) {
		yield put(consultarCondutorFailure(response.error));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_CONDUTOR_REQUEST, consultarCondutor),
]);
