export enum Types {
	EXCLUIR_MENUS = '@sgu/EXCLUIR_MENUS',
	EXCLUIR_MENUS_SUCCESS = '@sgu/EXCLUIR_MENUS_SUCCESS',
	EXCLUIR_MENUS_FAILURE = '@sgu/EXCLUIR_MENUS_FAILURE',
	EXCLUIR_MENUS_CLEAR = '@SGU/EXCLUIR_MENUS_CLEAR',
}

export interface ExcluirMenus {
	status: number;
	data: null | MenusData;
}

interface MenusData {
	status: string;
	statusMensagem: string;
}

export interface ExcluirMenusRequest {
	id: number | null;
}
