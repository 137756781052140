import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU_SERVICE, CHANNEL } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarMunicipiosRequest } from './types';

// ACTIONS
import {
	cadastrarMunicipiosSuccess,
	cadastrarMunicipiosFailure,
} from './actions';

function* consutarMunicipios(request: ReducerAction) {
	const { payload }: { payload: ConsultarMunicipiosRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU_SERVICE,
		`domain-service/${CHANNEL}/municipio`,
		{
			codigo: payload.uf,
		},
	);

	if (response.status === 200) {
		yield put(cadastrarMunicipiosSuccess(response));
	} else if (response.error || response.status === 204) {
		yield put(cadastrarMunicipiosFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_MUNICIPIOS_REQUEST, consutarMunicipios),
]);
