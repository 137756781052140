import { all } from 'redux-saga/effects';
import consultaProcessoAbertoSaga from './consultaProcessoAberto/saga';
import gerarRenachIntegradoSaga from './gerarRenachIntegrado/saga';
import alterarCadastro from './alterarCadastro/saga';

export default all([
	consultaProcessoAbertoSaga,
	gerarRenachIntegradoSaga,
	alterarCadastro,
]);
