import { combineReducers } from 'redux';

import consultarSufixos from './consultarSulfixos/reducer';
import cadastrarSufixo from './cadastrarSufixo/reducer';
import excluirSufixo from './excluirSufixo/reducer';

export default combineReducers({
	consultarSufixos,
	cadastrarSufixo,
	excluirSufixo,
});
