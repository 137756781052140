import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
  consultarMunicipioCodigoProdespSuccess,
  consultarMunicipioCodigoProdespFailure,
} from './actions';


function* consultarMunicipioCodigoProdesp({ payload }: any) {
	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`consultar-municipio/${CHANNEL}/`,
		payload
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarMunicipioCodigoProdespSuccess(response));
	} else if (response.error) {
		yield put(consultarMunicipioCodigoProdespFailure(response.message));
	}
}


export default all([
	takeLatest(
		Types.CONSULTAR_MUNICIPIO_CODIGO_PRODESP_REQUEST,
		consultarMunicipioCodigoProdesp,
	),
]);
