import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { toSelect } from 'utils/genericFunctions';
import { Types, consultarUnidadesTransito } from './types';

export const INITIAL_STATE: consultarUnidadesTransito = {
	status: 0,
	dataUnidades: [],
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultarUnidadesTransito {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_UNIDADE_TRANSITO_REQUEST: {
				break;
			}

			case Types.CONSULTAR_UNIDADE_TRANSITO_SUCCESS: {
				draft.status = action.payload.status;

				const select = toSelect(
					action.payload.data.unidades_transito,
					'nome',
					'codigo',
				);
				draft.dataUnidades = select;
				break;
			}

			case Types.CONSULTAR_UNIDADE_TRANSITO_FAILURE: {
				draft.status = action.payload.status;
				draft.dataUnidades = action.payload.data;
				break;
			}

			case Types.CONSULTAR_UNIDADE_TRANSITO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.dataUnidades = INITIAL_STATE.dataUnidades;
				break;
			}

			default:
		}
		return draft;
	});
}
