import React from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import data from 'store/modules/api/detranCrv/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import Placa from 'components/Common/Form/Fields/Placa';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';

// UTILS
import hasError from 'utils/getFormErrors';
import {
	onlyLetters,
	onlyNumbers,
	onlyNumbersLettersSpace,
} from 'utils/genericFunctions';

// FORM
import { IEmitirSegundaViaCrv, initialValues } from '../form';

interface Props {
	formik: FormikProps<IEmitirSegundaViaCrv>;
}

const DadosVeiculo: React.FC<Props> = ({ formik }) => {
	const {
		consultaCategoria,
		consultaCarroceria,
		consultaCombustivel,
		consultaCor,
		consultaEspecie,
		consultarMunicipioPorUF,
		consultaTipo,
	} = useSelector((state: ApplicationState) => state.api.detranCrv.Enum);

	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section size="lg" title="Dados do Veículo" titleSize="sm">
						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Placa
									title="Placa"
									titleSize="lg"
									name="placa"
									defaultValue={initialValues.placa}
									size={80}
									required
									disabled
									formik={formik}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Input}
									title="Protocolo Remarcação"
									titleSize="lg"
									name="protocoloRemarcacao"
									size={92}
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'protocoloRemarcacao',
											onlyNumbersLettersSpace(v.target.value),
										);
									}}
									error={!!formik.errors.protocoloRemarcacao}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Select}
									title="Município"
									titleSize="lg"
									name="codigoMunicipio"
									options={consultarMunicipioPorUF.data}
									onChange={(v: number) => {
										formik.setFieldValue('codigoMunicipio', v);

										const selectedItem = consultarMunicipioPorUF.data.filter(
											item => v === item.value,
										);

										formik.setFieldValue(
											'descricaoMunicipio',
											selectedItem[0].label,
										);
									}}
									size={80}
									required
									error={hasError(formik.errors, 'municipio')}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Input}
									title="Renavam"
									titleSize="lg"
									name="renavam"
									size={92}
									required
									disabled
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'renavam',
											onlyNumbersLettersSpace(v.target.value),
										);
									}}
									error={!!formik.errors.renavam}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Chassi"
									titleSize="lg"
									name="chassi"
									maxLength={22}
									size={80}
									required
									disabled
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'chassi',
											onlyNumbersLettersSpace(v.target.value),
										);
									}}
									error={!!formik.errors.chassi}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Select}
									title="Chassi Remarcado"
									titleSize="lg"
									name="chassiRemarcado"
									options={data.simNaoBoolean}
									size={92}
									required
									disabled
									error={!!formik.errors.chassiRemarcado}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Marca"
									titleSize="lg"
									name="descricaoMarca"
									size={80}
									required
									disabled
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'descricaoMarca',
											onlyLetters(v.target.value),
										);
									}}
									error={hasError(formik.errors, 'marca')}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={InputMask}
									title="Ano Modelo"
									titleSize="lg"
									name="anoModelo"
									mask="9999"
									size={92}
									required
									disabled
									error={!!formik.errors.anoModelo}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Select}
									title="Tipo"
									titleSize="lg"
									name="longIdTipo"
									options={consultaTipo.data}
									onChange={(v: number) => {
										formik.setFieldValue('longIdTipo', v);

										const selectedItem = consultaTipo.data.filter(
											item => v === item.value,
										);

										formik.setFieldValue(
											'descricaoTipo',
											selectedItem[0].label,
										);
									}}
									size={80}
									required
									disabled
									error={hasError(formik.errors, 'tipo')}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Select}
									title="Carroceria"
									titleSize="lg"
									name="longIdCarroceria"
									options={consultaCarroceria.data}
									onChange={(v: number) => {
										formik.setFieldValue('longIdCarroceria', v);

										const selectedItem = consultaCarroceria.data.filter(
											item => v === item.value,
										);

										formik.setFieldValue(
											'descricaoCarroceria',
											selectedItem[0].label,
										);
									}}
									size={92}
									required
									disabled
									error={hasError(formik.errors, 'carroceria')}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Select}
									title="Cor"
									titleSize="lg"
									name="longIdCor"
									options={consultaCor.data}
									onChange={(v: number) => {
										formik.setFieldValue('longIdCor', v);

										const selectedItem = consultaCor.data.filter(
											item => v === item.value,
										);

										formik.setFieldValue('descricaoCor', selectedItem[0].label);
									}}
									size={80}
									required
									disabled
									error={hasError(formik.errors, 'cor')}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Select}
									title="Categoria"
									titleSize="lg"
									name="longIdCategoria"
									options={consultaCategoria.data}
									onChange={(v: number) => {
										formik.setFieldValue('longIdCategoria', v);

										const selectedItem = consultaCategoria.data.filter(
											item => v === item.value,
										);

										formik.setFieldValue(
											'descricaoCategoria',
											selectedItem[0].label,
										);
									}}
									size={92}
									required
									disabled
									error={hasError(formik.errors, 'categoria')}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Select}
									title="Combustível"
									titleSize="lg"
									name="longIdCombustivel"
									options={consultaCombustivel.data}
									onChange={(v: number) => {
										formik.setFieldValue('longIdCombustivel', v);

										const selectedItem = consultaCombustivel.data.filter(
											item => v === item.value,
										);

										formik.setFieldValue(
											'descricaoCombustivel',
											selectedItem[0].label,
										);
									}}
									size={80}
									required
									disabled
									error={hasError(formik.errors, 'combustivel')}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Select}
									title="Espécie"
									titleSize="lg"
									name="longIdEspecie"
									options={consultaEspecie.data}
									onChange={(v: number) => {
										formik.setFieldValue('longIdEspecie', v);

										const selectedItem = consultaEspecie.data.filter(
											item => v === item.value,
										);

										formik.setFieldValue(
											'descricaoEspecie',
											selectedItem[0].label,
										);
									}}
									size={92}
									required
									disabled
									error={hasError(formik.errors, 'especie')}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={InputMask}
									title="Capacidade passageiros"
									titleSize="lg"
									name="capacidadePassageiro"
									mask="9"
									size={80}
									required
									disabled
									error={!!formik.errors.capacidadePassageiro}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Input}
									title="Capacidade carga"
									titleSize="lg"
									name="capacidadeCarga"
									size={92}
									required
									disabled
									error={!!formik.errors.capacidadeCarga}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Potência"
									titleSize="lg"
									name="potencia"
									size={80}
									required
									disabled
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'potencia',
											onlyNumbers(v.target.value),
										);
									}}
									error={!!formik.errors.potencia}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Input}
									title="Cilindrada"
									titleSize="lg"
									name="cilindrada"
									size={92}
									required
									disabled
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'cilindrada',
											onlyNumbers(v.target.value),
										);
									}}
									error={!!formik.errors.cilindrada}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="CMT"
									titleSize="lg"
									name="cmt"
									size={80}
									required
									disabled
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'cmt',
											onlyNumbersLettersSpace(v.target.value),
										);
									}}
									error={!!formik.errors.cmt}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Input}
									title="PBT"
									titleSize="lg"
									name="pbt"
									size={92}
									required
									disabled
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'pbt',
											onlyNumbersLettersSpace(v.target.value),
										);
									}}
									error={!!formik.errors.pbt}
								/>
							</Col>
						</Row>

						<Row>
							<Col span={11}>
								<Field
									as={InputMask}
									title="Eixos"
									titleSize="lg"
									name="eixos"
									mask="99"
									maskChar=""
									size={80}
									required
									disabled
									error={!!formik.errors.eixos}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Select}
									title="Procedência do veículo"
									titleSize="lg"
									name="procedencia"
									options={data.procedenciaVeiculo}
									onChange={(v: string) =>
										formik.setFieldValue('procedencia', v)
									}
									size={92}
									required
									disabled
									error={!!formik.errors.procedencia}
								/>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default DadosVeiculo;
