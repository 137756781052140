import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function consultaNacionalidadesRequest(): ReducerAction {
	return {
		type: Types.CONSULTA_NACIONALIDADES_REQUEST,
		payload: null,
	};
}

export function consultaNacionalidadesSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_NACIONALIDADES_SUCCESS,
		payload,
	};
}

export function consultaNacionalidadesFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_NACIONALIDADES_FAILURE,
		payload: null,
	};
}
