import { combineReducers } from 'redux';

import consultaErro from './consultaErro/reducer';
import consultaHistorico from './consultaHistorico/reducer';
import consultaNaoProcessada from './consultaNaoProcessada/reducer';
import cadastroEvento from './cadastroEvento/reducer';

export default combineReducers({
	consultaErro,
	consultaHistorico,
	consultaNaoProcessada,
	cadastroEvento,
});
