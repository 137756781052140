import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { BuscaPaginadaRequest, Types } from './types';

// ACTIONS
import { buscaPaginadaSuccess, buscaPaginadaFailure } from './actions';

function* buscaPaginadaRequest(request: ReducerAction) {
	const { payload }: { payload: BuscaPaginadaRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`consumidores/${CHANNEL}/busca-paginada`,
		payload,
	);

	if (response.status === 200) {
		yield put(buscaPaginadaSuccess(response));
	} else {
		yield put(buscaPaginadaFailure(response));
	}
}

export default all([
	takeLatest(Types.BUSCA_PAGINADA_REQUEST, buscaPaginadaRequest),
]);
