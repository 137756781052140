import produce from 'immer';

// TYPES
import { Types, DadosCidadao } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: DadosCidadao = {
	status: 0,
	data: null,
	requestData: null,
};

export default function dadosCidadaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): DadosCidadao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.DADOS_CIDADAO_REQUEST: {
				break;
			}

			case Types.DADOS_CIDADAO_SUCCESS: {
				draft.data = action.payload.response.data;
				draft.requestData = action.payload.request;
				draft.status = action.payload.response.status;
				break;
			}

			case Types.DADOS_CIDADAO_FAILURE: {
				break;
			}

			case Types.DADOS_CIDADAO_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				break;
			}

			default:
		}
	});
}
