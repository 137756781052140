import React, { useEffect, useState } from 'react';

import { Row, Col, Alert } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import { showInside } from 'store/modules/app/notifications/actions';
import {
	limpaLoginSemLimparAuthToken,
	logOffRequest,
} from 'store/modules/api/sgu/loginUnico/actions';
import { logonRestClear } from 'store/modules/api/detranCnh/eportal/logonRest/actions';
import { logonClear } from 'store/modules/api/iirgd/logon/actions';

import { salvaSenhaUsuarioClear } from 'store/modules/api/sguService/salvaSenhaUsuario/actions';
import { alteraSenhaDetranClear } from 'store/modules/api/detranCnh/eportal/alteraSenhaDetran/actions';
import { alteraSenhaClear } from 'store/modules/api/iirgd/alteraSenha/actions';
import { enderecoIpRequest } from 'store/modules/api/utils/enderecoIp/actions';
import MenuItem from './menu-item';
import CertificadoDigital from './certificadoDigital';
import { Container, Content } from './styled';

export const orgaosWithMainframe: number[] = [1, 2];

const MenuOrgaos: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { authToken } = useSelector((state: ApplicationState) => state.app);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { logon, alteraSenha } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const { logonRest, alteraSenhaDetran } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.eportal,
	);

	const { changeUrl } = useSelector(
		(state: ApplicationState) => state.api.eCpf,
	);

	useEffect(() => {
		dispatch(limpaLoginSemLimparAuthToken());
		dispatch(logonRestClear({}));
		dispatch(logonClear({}));
		dispatch(salvaSenhaUsuarioClear());
		dispatch(alteraSenhaDetranClear({}));
		dispatch(alteraSenhaClear({}));
		showInside(false);
	}, [dispatch]);

	const [changePasswordAlert, setChangePasswordAlert] =
		useState<boolean>(false);

	const [certificateVisible, setCertificateVisible] = useState<boolean>(false);
	const [certificateValid, setCertificateValid] = useState<boolean>(false);
	const [startECpf, setStartECpf] = useState<boolean>(false);
	const [filterOrgaos, setFilterOrgaos] = useState<any>([]);

	useEffect(() => {
		setChangePasswordAlert(logon.mensagem === 'NOVAPASSWORD');
	}, [logon]);

	useEffect(() => {
		setChangePasswordAlert(logonRest.mensagem === 'NOVAPASSWORD');
	}, [logonRest]);

	useEffect(() => {
		if (alteraSenha.status === 400) {
			setChangePasswordAlert(false);
		}
	}, [alteraSenha, dispatch]);

	useEffect(() => {
		if (alteraSenhaDetran.status === 400) {
			setChangePasswordAlert(false);
		}
	}, [alteraSenhaDetran, dispatch]);

	useEffect(() => {
		const { expireIn, authorization } = authToken;

		if (expireIn && authorization) {
			const dateNow = new Date();
			const dateExpireIn = new Date(expireIn);

			if (dateNow > dateExpireIn) {
				dispatch(logOffRequest());
				history.push('/');
			}

			return;
		}

		dispatch(logOffRequest());
		history.push('/');
	}, [authToken, dispatch, history]);

	useEffect(() => {
		dispatch(enderecoIpRequest());
	}, [dispatch]);

	useEffect(() => {
		if (certificateValid && certificateVisible && changeUrl.data)
			history.push('/detran-cnh');
	}, [certificateValid, certificateVisible, changeUrl.data, history]);

	useEffect(() => {
		if (user.orgaos) {
			const filteredOrgaos = user.orgaos.filter((item: any) => item.id !== 15);
			setFilterOrgaos(filteredOrgaos);
		}
	}, [user.orgaos]);

	return (
		<Container>
			<Row justify="start">
				<Col>
					<h1>Selecione o órgão</h1>
				</Col>
			</Row>
			{changePasswordAlert && (
				<Row>
					<Col span={24}>
						<Alert
							message=""
							description="Senha do usuário necessita ser alterada. A mesma deve conter letras e números e o limite de 10 caracteres"
							type="info"
							closable
							showIcon
							onClose={() => {}}
						/>
					</Col>
				</Row>
			)}
			<Content>
				{filterOrgaos.map((item: any) => {
					return (
						<MenuItem
							key={item.id}
							menuInfo={item}
							setCertificateVisible={setCertificateVisible}
							certificateValid={certificateValid}
							setCertificateValid={setCertificateValid}
							setStartECpf={setStartECpf}
						/>
					);
				})}
			</Content>
			<CertificadoDigital
				certificateVisible={certificateVisible}
				setCertificateVisible={setCertificateVisible}
				setCertificateValid={setCertificateValid}
				startECpf={startECpf}
			/>
		</Container>
	);
};

export default MenuOrgaos;
