export enum Types {
	ATUALIZAR_PARAMETRO_LOCALIDADE = '@SGU/ATUALIZAR_PARAMETRO_LOCALIDADE',
	ATUALIZAR_PARAMETRO_LOCALIDADE_SUCCESS = '@SGU/ATUALIZAR_PARAMETRO_LOCALIDADE_SUCCESS',
	ATUALIZAR_PARAMETRO_LOCALIDADE_FAILURE = '@SGU/ATUALIZAR_PARAMETRO_LOCALIDADE_FAILURE',
	ATUALIZAR_PARAMETRO_LOCALIDADE_CLEAR = '@SGU/ATUALIZAR_PARAMETRO_LOCALIDADE_CLEAR',
}

export interface AtualizarParametroLocalidade {
	status: number;
	data: null | ParametroLocalidadeData;
}

interface ParametroLocalidadeData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarParametroLocalidadeRequest {
	id?: number | string;
	descricao: string;
	sigla: string;
	valor: string;
	idLocalidade: string | number;
}
