import { ConsultaPlacaDisponivelRequest } from 'pages/DetranCrv/SolicitarDocumentos/EscolherPlaca/ResultadoConsulta/form';
import { Types } from './types';

export function consultaPlacaDisponivelRequest(
	payload: ConsultaPlacaDisponivelRequest,
) {
	return {
		type: Types.CONSULTA_PLACA_DISPONIVEL_REQUEST,
		payload,
	};
}
export function consultaPlacaDisponivelSuccess(payload: any) {
	return {
		type: Types.CONSULTA_PLACA_DISPONIVEL_SUCCESS,
		payload,
	};
}
export function consultaPlacaDisponivelFailure(payload: []): any {
	return {
		type: Types.CONSULTA_PLACA_DISPONIVEL_FAILURE,
		payload,
	};
}
export function consultaPlacaDisponivelClear(): any {
	return {
		type: Types.CONSULTA_PLACA_DISPONIVEL_CLEAR,
	};
}
