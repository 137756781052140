import { ReducerAction } from 'store/modules/types';

import { Types, IPTREOERequest } from './types';

export function consultarPTREOERequest(data: IPTREOERequest): ReducerAction {
	return {
		type: Types.PTREOE_REQUEST,
		payload: data,
	};
}

export function consultarPTREOESuccess(payload: object) {
	return {
		type: Types.PTREOE_SUCCESS,
		payload,
	};
}

export function consultarPTREOEFailure(payload: object): ReducerAction {
	return {
		type: Types.PTREOE_FAILURE,
		payload,
	};
}

export function consultarPTREOEClear(): any {
	return {
		type: Types.PTREOE_CLEAR,
	};
}
