export enum Types {
	ALTERA_SENHA_USUARIO_REQUEST = '@sgu-service/ALTERA_SENHA_USUARIO_REQUEST',
	ALTERA_SENHA_USUARIO_SUCCESS = '@sgu-service/ALTERA_SENHA_USUARIO_SUCCESS',
	ALTERA_SENHA_USUARIO_FAILURE = '@sgu-service/ALTERA_SENHA_USUARIO_FAILURE',
	ALTERA_SENHA_USUARIO_CLEAR = '@sgu-service/ALTERA_SENHA_USUARIO_CLEAR',
}

export interface AlteraSenhaUsuario {
	status: number;
	data: null | string;
	form: null | Form;
}

export interface Form {
	usuario: string;
	novaSenha: string;
}

export interface AlteraSenhaUsuarioRequest {
	cpf: string;
	senhaAtual: string;
	senhaNova: string;
}
