/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Row, Col } from 'antd';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import DadosContato from 'pages/DetranCnh/components/pages/DadosContato';
import DadosHabilitacao from 'pages/DetranCnh/components/pages/PreCadastroNew/DadosHabilitacao';
import EnderecoRecebimento from 'pages/DetranCnh/components/pages/EnderecoRecebimento';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { cadastroEnderecosRequest } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/actions';
import { PreCadastroCidadaoRequest } from 'store/modules/api/sguService/preCadastroCidadao/types';
import { preCadastroCidadaoRequest } from 'store/modules/api/sguService/preCadastroCidadao/actions';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

import Alert from 'components/Common/Notifications/Alert';
import RenachGerado from '../RenachGerado';
import {
	// initalValuesHabilitacao,
	// initialValuesPreCadastro,
	initialValuesDadosHabilitacao,
	treatValues,
	treatValuesPreCadastro,
	schema,
	initialValuesEndereco,
} from './form';

const StatusCondutorPermissaoInternacional: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [confirmacaoEndereco, setConfirmacaoEndereco] = useState(false);
	const [confirmacaoPreCadastro, setConfirmacaoPreCadastro] = useState(false);
	const [formValues, setFormValues] = useState(initialValuesDadosHabilitacao);
	// const [dataEnderecoRecebimento, setDataEnderecoRecebimento] = useState(
	// 	initialValuesEndereco,
	// );
	const [mensagemAlert, setMensagemAlert] = useState('');

	// const { consultaPermissaoInternacional, gerarRenach } = useSelector(
	// 	(state: ApplicationState) => state.api.detranCnh.permissaoInternacional,
	// );

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { preCadastroCidadao } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const { consultaEPortal } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.permissaoInternacional,
	);

	const { cadastroEnderecos, pesquisaEnderecosRecebimento } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { consultaCEP } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.consulta,
	);

	const { detailsStartSection } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.evento.eventoInicioSessao,
	);

	// const { geraSenha } = useSelector(
	// 	(state: ApplicationState) => state.api.relatorios,
	// );

	const [isLoading, setIsLoading] = useState(true);
	// const [modalAtendimento, setModalAtendimento] = useState(false);
	const [mensagemPromocaoSenha, setMensagemPromocaoSenha] = useState<any>('');
	// const [dadosPreCadastro, setDadosPreCadastro] = useState(
	// 	initialValuesDadosHabilitacaoPreCadastro,
	// );

	const [preCadastro, setPreCadastro] =
		useState<null | PreCadastroCidadaoRequest>(null);

	const handleSubmit = useCallback(
		(values: any) => {
			let enderecoUf = null;

			if (consultaCEP.data) {
				enderecoUf = consultaCEP.data.uf;
			}
			const requestCadastroEndereco = treatValues(values);
			const requestPreCadastro = treatValuesPreCadastro(
				atendimento,
				loginUnico,
				values,
				enderecoUf,
			);
			setPreCadastro(requestPreCadastro);
			dispatch(cadastroEnderecosRequest(requestCadastroEndereco));
		},
		[atendimento, dispatch, consultaCEP, loginUnico],
	);

	useEffect(() => {
		if (cadastroEnderecos.status === 200) {
			if (preCadastro) {
				// setPreCadastro(
				// 	...preCadastro,
				// 	(preCadastro.EnderecoUF = consultaCEP.data.uf),
				// );
				preCadastroCidadaoRequest(preCadastro);
				dispatch(preCadastroCidadaoRequest(preCadastro));
			}
		}
	}, [cadastroEnderecos, dispatch, preCadastro]);

	useEffect(() => {
		if (preCadastroCidadao.status === 400) {
			if (preCadastroCidadao.errorMessage) {
				setMensagemPromocaoSenha(preCadastroCidadao.errorMessage[0]);
				setConfirmacaoEndereco(false);
				setConfirmacaoPreCadastro(false);
				// setConfirmacaoPreCadastro(true);
			}
		} else if (preCadastroCidadao.data?.mensagem === 'OK') {
			setConfirmacaoEndereco(false);
			setConfirmacaoPreCadastro(preCadastroCidadao.status === 201);
			setMensagemPromocaoSenha(
				'O código para o Portal foi gerado e enviado para o e-mail do cidadão.',
			);
			// const numRg = preCadastro?.rg ? preCadastro?.rg.split('-')[0] : '';
			// const digRg = preCadastro?.rg ? preCadastro?.rg.split('-')[1] : '';

			// const cpf = preCadastro?.cpf ? preCadastro?.cpf.split('-')[0] : '';
			// const cpfDig = preCadastro?.cpf ? preCadastro?.cpf.split('-')[1] : '';

			// const body: GeraSenhaRequest = {
			// 	nome: preCadastro?.nome || '',
			// 	numRg,
			// 	digRg,
			// 	cpf,
			// 	cpfDig,
			// 	senha: preCadastroCidadao.data?.token ?? '',
			// };
			// dispatch(geraSenhaRequest(body));
		}
	}, [
		preCadastro,
		preCadastroCidadao,
		mensagemPromocaoSenha,
		confirmacaoEndereco,
		confirmacaoPreCadastro,
	]);

	useEffect(() => {
		if (pesquisaEnderecosRecebimento.data) {
			const { cpf, enderecoCadastro, enderecosRecebimento, mensagem } =
				pesquisaEnderecosRecebimento.data;

			initialValuesEndereco.cpf = cpf;
			initialValuesEndereco.enderecoCadastro = enderecoCadastro;
			initialValuesEndereco.enderecosRecebimento = enderecosRecebimento;
			initialValuesEndereco.mensagem = mensagem;
			// setDataEnderecoRecebimento(initialValuesEndereco);
		}
	}, [pesquisaEnderecosRecebimento.data]);

	useEffect(() => {
		if (consultaEPortal.data) {
			if (
				consultaEPortal.data.mensagemEmissaoPid &&
				consultaEPortal.data.mensagemEmissaoPid !== ''
			) {
				setMensagemAlert(consultaEPortal.data.mensagemEmissaoPid);
			}
			const {
				mensagem,
				cpf,
				nome,
				nomePai,
				nomeMae,
				naturalidade,
				numeroRg,
				dataNascimento,
				sexo,
				nacionalidade,
				usoForm,
				numeroRegistro,
				numeroPgu,
				renach,
				espelhoCnh,
				dadosExameMedico,
				dataExameTeorico,
				dadosExamePsicotecnico,
				categoriaPretendida,
				local,
				cfcA,
				cfcB,
				dataPrimeiraHabilitacao,
				dataUltEmissaoCnh,
				dataValidadeCnh,
				situacaoAtual,
				dataExamePratico2Rodas,
				dataExamePratico4Rodas,
				endereco,
				flagAtividadeRemunerada,
				flagDeficienteFisico,
				localEmissao,
				orgaoEmissor,
				rne1,
				rne2,
				rne3,
			} = consultaEPortal.data;

			// --- DADOS HABILITAÇÃO --- //

			// MENSAGENS
			initialValuesDadosHabilitacao.mensagem = mensagem;

			// INFORMAÇÕES PESSOAIS
			initialValuesDadosHabilitacao.cpf = cpf;
			initialValuesDadosHabilitacao.nome = nome;
			initialValuesDadosHabilitacao.nomePai = nomePai;
			initialValuesDadosHabilitacao.nomeMae = nomeMae;
			initialValuesDadosHabilitacao.naturalidade = naturalidade;
			initialValuesDadosHabilitacao.numeroRg = numeroRg;
			initialValuesDadosHabilitacao.dataNascimento = dataNascimento;
			initialValuesDadosHabilitacao.sexo = sexo;
			initialValuesDadosHabilitacao.nacionalidade = nacionalidade;

			// INFORMAÇÕES HABILITAÇÃO
			initialValuesDadosHabilitacao.usoForm = usoForm;
			initialValuesDadosHabilitacao.numeroRegistro = numeroRegistro;
			initialValuesDadosHabilitacao.numeroPgu = numeroPgu;
			initialValuesDadosHabilitacao.renach = renach;
			initialValuesDadosHabilitacao.espelhoCnh = espelhoCnh;
			initialValuesDadosHabilitacao.dataUltEmissaoCnh = dataUltEmissaoCnh;
			initialValuesDadosHabilitacao.dadosExameMedico.dataExameMedico =
				dadosExameMedico.dataExameMedico;
			initialValuesDadosHabilitacao.dadosExameMedico.restricaoMedica =
				dadosExameMedico.restricaoMedica;
			initialValuesDadosHabilitacao.dadosExameMedico.crm = dadosExameMedico.crm;
			initialValuesDadosHabilitacao.dataExameTeorico = dataExameTeorico;
			initialValuesDadosHabilitacao.dadosExamePsicotecnico.crp =
				dadosExamePsicotecnico.crp;
			initialValuesDadosHabilitacao.dadosExamePsicotecnico.laudo1 =
				dadosExamePsicotecnico.laudo1;
			initialValuesDadosHabilitacao.dadosExamePsicotecnico.exame1 =
				dadosExamePsicotecnico.exame1;
			initialValuesDadosHabilitacao.categoriaPretendida = categoriaPretendida;
			initialValuesDadosHabilitacao.local = local;
			initialValuesDadosHabilitacao.cfcA = cfcA;
			initialValuesDadosHabilitacao.dadosExameMedico.categoriaPermitida =
				dadosExameMedico.categoriaPermitida;
			initialValuesDadosHabilitacao.dadosExameMedico.cred1 =
				dadosExameMedico.cred1;
			initialValuesDadosHabilitacao.dadosExamePsicotecnico.resultado2 =
				dadosExamePsicotecnico.resultado2;
			initialValuesDadosHabilitacao.dataPrimeiraHabilitacao =
				dataPrimeiraHabilitacao;
			initialValuesDadosHabilitacao.dataValidadeCnh = dataValidadeCnh;
			initialValuesDadosHabilitacao.situacaoAtual = situacaoAtual;
			initialValuesDadosHabilitacao.cfcB = cfcB;
			initialValuesDadosHabilitacao.dadosExameMedico.regiao =
				dadosExameMedico.regiao;
			initialValuesDadosHabilitacao.dataExamePratico4Rodas =
				dataExamePratico4Rodas;
			initialValuesDadosHabilitacao.dataExamePratico2Rodas =
				dataExamePratico2Rodas;
			initialValuesDadosHabilitacao.dadosExamePsicotecnico.cred2 =
				dadosExamePsicotecnico.cred2;
			initialValuesDadosHabilitacao.dadosExamePsicotecnico.regiao =
				dadosExamePsicotecnico.regiao;

			// ENDEREÇO CADASTRADO
			initialValuesDadosHabilitacao.endereco.logradouro = endereco.logradouro;
			initialValuesDadosHabilitacao.endereco.numero = endereco.numero;
			initialValuesDadosHabilitacao.endereco.complemento = endereco.complemento;
			initialValuesDadosHabilitacao.endereco.bairro = endereco.bairro;
			initialValuesDadosHabilitacao.endereco.descricaoMunicipio =
				endereco.descricaoMunicipio;
			initialValuesDadosHabilitacao.endereco.cep = endereco.cep;
			initialValuesDadosHabilitacao.endereco.numero = endereco.numero;

			// FORM
			initialValuesDadosHabilitacao.localEmissao = localEmissao;
			initialValuesDadosHabilitacao.orgaoEmissor = orgaoEmissor;

			initialValuesDadosHabilitacao.rne1 = rne1;
			initialValuesDadosHabilitacao.rne2 = rne2;
			initialValuesDadosHabilitacao.rne3 = rne3;

			// --- DADOS PRÉ CADASTRO --- //

			initialValuesDadosHabilitacao.flagAtividadeRemunerada =
				flagAtividadeRemunerada;
			initialValuesDadosHabilitacao.flagDeficienteFisico = flagDeficienteFisico;

			// PCad
			initialValuesDadosHabilitacao.rne2 = rne2;
			initialValuesDadosHabilitacao.rne3 = rne3;
			initialValuesDadosHabilitacao.dataPrimeiraHabilitacao =
				dataPrimeiraHabilitacao;

			// TIPO DOCUMENTO
			initialValuesDadosHabilitacao.tipoDocumento = '1';

			// setDadosPreCadastro(initialValues);
			setFormValues(initialValuesDadosHabilitacao);

			setIsLoading(false);
		}
	}, [consultaEPortal.data, history, user.cpf]);

	return (
		// chamar a section aqui e colocar os componentes de contato e endereço aqui.
		// desacoplar e deixar o status do condutor responsavel apenas pelo bloco de status do condutor
		<>
			{!isLoading && (
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={formValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							<Section title="Status do Condutor">
								<h3 style={{ textTransform: 'uppercase' }}>
									{formik.values.nome}
								</h3>
								{!confirmacaoEndereco &&
								!confirmacaoPreCadastro &&
								mensagemAlert !== '' ? (
									<Alert
										type="error"
										showIcon
										message={mensagemAlert}
										closable
										onClose={() => {}}
									/>
								) : (
									''
								)}

								{preCadastroCidadao.status === 400 ? (
									<Alert
										type="error"
										showIcon
										message="Houve um erro ao gerar o Pre Cadastro do cidadão"
										closable
										onClose={() => {}}
									/>
								) : (
									''
								)}

								{!confirmacaoEndereco && !confirmacaoPreCadastro ? (
									<>
										<DadosHabilitacao consultaRenovacaoCnh={formik.values} />
										<DadosContato formik={formik} />
										<EnderecoRecebimento
											cpf={formik.values.cpf}
											formik={formik}
										/>
										<Row justify="center">
											<Col>
												<ButtonImage type="submit" src="salvar" />
											</Col>
										</Row>
									</>
								) : (
									<>
										<RenachGerado
											renderData={{
												mensagemPromocaoSenha,
												tipoProcessoSessao: 'PERMISSAO_INTERNACIONAL',
												dataInicioSessao: detailsStartSection?.dataInicio || '',
											}}
										/>
									</>
								)}
							</Section>
							<Row justify="space-between" align="middle">
								<Col>
									<ButtonVoltar route="/detran-cnh" />
								</Col>
								<Col>
									<ButtonImage
										type="button"
										src="outro-servico-detran"
										onClick={() => history.push('/detran-cnh')}
									/>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};
export default StatusCondutorPermissaoInternacional;
