export enum Types {
	ALTERA_SENHA_DETRAN_REQUEST = '@iirgd/ALTERA_SENHA_DETRAN_REQUEST',
	ALTERA_SENHA_DETRAN_SUCCESS = '@iirgd/ALTERA_SENHA_DETRAN_SUCCESS',
	ALTERA_SENHA_DETRAN_FAILURE = '@iirgd/ALTERA_SENHA_DETRAN_FAILURE',
	ALTERA_SENHA_DETRAN_CLEAR = '@iirgd/ALTERA_SENHA_DETRAN_CLEAR',
}

export interface AlteraSenhaDetran {
	status: number;
	mensagem: string;
	form: AlteraSenhaDetranRequest | null;
}

export interface AlteraSenhaDetranRequest {
	usuario: string;
	senhaAtual: string;
	senhaNova: string;
	senhaNovaConfirmacao: string;
	enderecoIp: string;
}
