import * as Yup from 'yup';

// REDUX
import { ConsultarMunicipioCodigoProdespRequest } from 'store/modules/api/detranCrv/enum/consultarMunicipioCodigoProdesp/types';
import { IncluirIntencaoVendaRequest } from 'store/modules/api/detranCrv/IntencaoDeVenda/Incluir/types';
import { AtendimentoState } from 'store/modules/api/sgu/atendimento/types';
import { ILoginUnicoState } from 'store/modules/api/sgu/loginUnico/types';

// UTILS
import { limparMascara } from 'utils/genericFunctions';

interface IncluirIntencaoVenda {
	codigoDespachante: string;
	compradorBairro: string;
	compradorCodigoBairro: number;
	compradorCep: string;
	compradorCodigoMunicipio: string;
	compradorDescricaoMunicipio: string;
	compradorComplemento: string;
	compradorEmail: string;
	compradorLogradouro: string;
	compradorNome: string;
	compradorNumero: string;
	compradorUf: string;
	compradorValorVenda: number | string;
	compradorDocumentoNumeroIdentificacao: string;
	proprietarioVendedorEmail: string;
	proprietarioVendedorDocumentoNumeroIdentificacao: string;
	veiculoChassi: string;
	veiculoHodometro: number | string;
	veiculoPlaca: string;
	veiculoRenavam: string;
}

export function treatValuesCodigoProdesp(
	atendimento: AtendimentoState,
	loginUnico: ILoginUnicoState,
	codMunicipioCep: string | number,
): ConsultarMunicipioCodigoProdespRequest {
	const cpf = loginUnico.login.user.cpf
		? limparMascara(loginUnico.login.user.cpf)
		: '';

	return {
		evento: {
			id_atendimento: atendimento.salvarAtendimento.data?.idAtendimento ?? '',
			id_cidadao: atendimento.salvarAtendimento.data?.idCidadao ?? '',
			cpf,
			identificacao: '',
			ip: loginUnico.login.user.ip,
			canal: {
				id: 1,
				desc: 'Presencial',
				estacao: {
					id: 0,
					desc: '',
				},
				localidade: {
					id: Number(loginUnico.login.user.posto),
					desc: loginUnico.login.user.nomePosto,
				},
			},
		},
		codigoMunicipioCorreio: codMunicipioCep.toString(),
	};
}

export const initialValues: IncluirIntencaoVenda = {
	codigoDespachante: '',
	compradorBairro: '',
	compradorCodigoBairro: 0,
	compradorCep: '',
	compradorCodigoMunicipio: '',
	compradorDescricaoMunicipio: '',
	compradorComplemento: '',
	compradorEmail: '',
	compradorLogradouro: '',
	compradorNome: '',
	compradorNumero: '',
	compradorUf: '',
	compradorValorVenda: '',
	compradorDocumentoNumeroIdentificacao: '',
	proprietarioVendedorEmail: '',
	proprietarioVendedorDocumentoNumeroIdentificacao: '',
	veiculoChassi: '',
	veiculoHodometro: '',
	veiculoPlaca: '',
	veiculoRenavam: '',
};

export const schema = Yup.object<IncluirIntencaoVenda>().shape({
	veiculoChassi: Yup.string().required(`Campo Obrigatório: Chassi.`),
	veiculoHodometro: Yup.string().required(`Campo Obrigatório: Hodômetro.`),
	veiculoPlaca: Yup.string().required(`Campo Obrigatório: Placa.`),
	veiculoRenavam: Yup.string().required(`Campo Obrigatório: Renavam.`),
	// compradorBairro: Yup.string().required(
	// 	`Campo Obrigatório: Bairro Comprador.`,
	// ),
	compradorDescricaoMunicipio: Yup.string().required(
		`Campo Obrigatório: Bairro Comprador.`,
	),
	compradorCep: Yup.string().required(`Campo Obrigatório: CEP Comprador.`),
	compradorCodigoMunicipio: Yup.string().required(
		`Campo Obrigatório: Munícipio Comprador.`,
	),
	compradorEmail: Yup.string()
		// .email(`Campo Inválido: E-mail Comprador.`)
		.required(`Campo Obrigatório: E-mail Comprador.`),
	compradorLogradouro: Yup.string().required(
		`Campo Obrigatório: Logradouro Comprador.`,
	),
	compradorNome: Yup.string().required(`Campo Obrigatório: Nome do Comprador.`),
	compradorNumero: Yup.string().required(
		`Campo Obrigatório: Número Comprador.`,
	),
	compradorUf: Yup.string().required(`Campo Obrigatório: UF Comprador.`),
	compradorValorVenda: Yup.string().required(`Campo Obrigatório: Valor Venda.`),
	compradorDocumentoNumeroIdentificacao: Yup.string().required(
		`Campo Obrigatório: CPF / CNPJ Comprador.`,
	),
	proprietarioVendedorEmail: Yup.string()
		// .email(`Campo Inválido: E-mail Vendedor.`)
		.required(`Campo Obrigatório: E-mail Vendedor.`),
	proprietarioVendedorDocumentoNumeroIdentificacao: Yup.string().required(
		`Campo Obrigatório: CPF / CNPJ Proprietário.`,
	),
});

export function formataObjetoIncluir(
	values: IncluirIntencaoVenda,
	codigoMunicipioProdesp: number,
): IncluirIntencaoVendaRequest {
	const {
		codigoDespachante,
		compradorBairro,
		// compradorCodigoBairro,
		compradorCep,
		// compradorCodigoMunicipio,
		compradorComplemento,
		compradorEmail,
		compradorLogradouro,
		compradorNome,
		compradorNumero,
		compradorUf,
		compradorValorVenda,
		compradorDocumentoNumeroIdentificacao,
		proprietarioVendedorEmail,
		proprietarioVendedorDocumentoNumeroIdentificacao,
		veiculoChassi,
		veiculoHodometro,
		veiculoPlaca,
		veiculoRenavam,
	} = values;

	const compradorValorVendaformatted = Number(compradorValorVenda) * 100;

	const body: IncluirIntencaoVendaRequest = {
		codigoDespachante,
		comprador: {
			bairro: compradorBairro.toString(),
			cep: compradorCep,
			codigoMunicipio: codigoMunicipioProdesp.toString(),
			complemento: compradorComplemento,
			email: compradorEmail,
			logradouro: compradorLogradouro,
			nome: compradorNome,
			numero: compradorNumero,
			uf: compradorUf,
			valorVenda: Number(compradorValorVendaformatted),
			documento: {
				numeroIdentificacao: compradorDocumentoNumeroIdentificacao,
				tipo:
					limparMascara(compradorDocumentoNumeroIdentificacao).length === 11
						? '1'
						: '2',
			},
		},
		proprietarioVendedor: {
			email: proprietarioVendedorEmail,
			documento: {
				numeroIdentificacao: proprietarioVendedorDocumentoNumeroIdentificacao,
				tipo:
					limparMascara(proprietarioVendedorDocumentoNumeroIdentificacao)
						.length === 11
						? '1'
						: '2',
			},
		},
		veiculo: {
			chassi: veiculoChassi?.trim() || '',
			hodometro: Number(veiculoHodometro),
			placa: veiculoPlaca,
			renavam: veiculoRenavam,
		},
	};

	return body;
}
