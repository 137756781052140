import * as Yup from 'yup';

// TYPES
import {
	IProcedencia,
	IRequestEmitirAlteracaoDadosCadastrais,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirAlteracaoDadosCadastrais/types';
import { IEvento } from 'store/modules/api/detranCrv/types';
// UTILS
import {
	concatenatesTaxaServicoPix,
	formatDateISO,
	limparMascara,
} from 'utils/genericFunctions';
import { IRequestEmitirModificacaoDadosCadastrais } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirModificacaoDadosCadastrais/types';

import { userDataRegex } from 'utils/constants/regex';
import { formatRg } from '../../formFunctions';

interface ITaxaServico {
	codBanco: string;
	dataContabil: string;
	nsuProdesp: string;
}
export interface IEmitirAlteracaoCadastral {
	evento: IEvento;
	acessibilidadeCondutorModificacao: boolean;
	acessibilidadePassageiroModificacao: boolean;
	alongamentoChassiModificacao: boolean;
	anoFabricacao: number | string;
	anoModelo: number | string;
	arrendatarioFinanceiraGravame: string;
	bairroProprietario: string;
	blindagemModificacao: boolean;
	cabineDuplaModificacao: boolean;
	cabineEstendidaModificacao: boolean;
	cabineSuplementarModificacao: boolean;
	capacidadeCarga: number | string;
	capacidadePassageiro: number | string;
	capacidadeVolumeTanqueSuplementarModificacao: number | string;
	cepProprietario: number | string;
	certificadoSegVeicularModificacao: number | string;
	chassi: string;
	chassiRemarcado: boolean;
	cilindrada: number | string;
	cmt: number | string;
	codigoMunicipio: number | string;
	codigoMunicipioPlacaAnterior: number | string;
	codigoMunicipioProprietario: number | string;
	complementoProprietario: string;
	cpfCnpjProprietario: string;
	csvAnoModificacao: number | string;
	dataVigenciaGravame: string;
	descricaoCarroceria: string;
	descricaoCategoria: string;
	descricaoCombustivel: string;
	descricaoCor: string;
	descricaoEspecie: string;
	descricaoMarca: string;
	descricaoTipo: string;
	distanciaEixoModificacao: number | string;
	eixo: number | string;
	eixoModificacao: 'AUTO_DIRECIONAL' | 'PNEUMATICO' | 'DIRECIONAL' | string;
	encurtamentoChassiModificacao: boolean;
	enderecoProprietario: string;
	financeiraGravame: number | string;
	freiosModificacao: boolean;
	identificacao: string;
	iluminacaoFrontalSinalizacaoModificacao: boolean;
	insulfilmeModificacao: boolean;
	longIdCarroceria: number | string;
	longIdCategoria: number | string;
	longIdCombustivel: number | string;
	longIdCor: number | string;
	longIdEspecie: number | string;
	longIdMarca: number | string;
	longIdTipo: number | string;
	nomeMunicipio: string;
	nomeMunicipioPlacaAnterior: string;
	nomeMunicipioProprietario: string;
	nomeProprietario: string;
	nomeProprietarioAnterior: string;
	numeroContratoGravame: string;
	numeroEspelho: number | string;
	numeroProprietario: number | string;
	orgaoExpeditorProprietario: string;
	parachoqueModificacao: boolean;
	pbt: number | string;
	placa: string;
	placaAnterior: string;
	plataformaModificacao: boolean;
	potencia: number | string;
	potenciaCilindradaModificacao: boolean;
	procedencia: IProcedencia;
	quantidadeTanqueSuplementarModificacao: number | string;
	rebaixamentoModificacao: boolean;
	reencarrocamentoModificacao: boolean;
	restricaoFinanceiraGravame: string;
	rgProprietario: string;
	rgDigito: string;
	rodaPneuModificacao: boolean;
	suspensaoModificacao: boolean;
	suspensaoPneumaticaModificacao: boolean;
	tanqueSuplementarModificacao: boolean;
	taxaAutenticacaoDigital: string;
	telefone: string;
	tetoSolarModificacao: boolean;
	tipoAcessibilidadePassageiroModificacao: string;
	tipoPesquisa: 'alteracao' | 'modificacao';
	ufPlacaAnterior: string;
	ufProprietario: string;
	valorSuspensaoModificacao: number | string;
	visualModificacao: boolean;
	documentos: any;
	servicoDigital: boolean;
	dddTelefoneCidadao: number | string;
	emailCidadao: string;
	telefoneCidadao: number | string;
	codBanco: string;
	dataContabil: string;
	nsuProdesp: string;
}

export const initialValues: IEmitirAlteracaoCadastral = {
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
		identificacao: '',
		ip: '',
		canal: {
			id: 0,
			desc: '',
			localidade: {
				id: 0,
				desc: '',
			},
		},
	},
	acessibilidadeCondutorModificacao: false,
	acessibilidadePassageiroModificacao: false,
	alongamentoChassiModificacao: false,
	anoFabricacao: '',
	anoModelo: '',
	arrendatarioFinanceiraGravame: '',
	bairroProprietario: '',
	blindagemModificacao: false,
	cabineDuplaModificacao: false,
	cabineEstendidaModificacao: false,
	cabineSuplementarModificacao: false,
	capacidadeCarga: '',
	capacidadePassageiro: '',
	capacidadeVolumeTanqueSuplementarModificacao: '',
	cepProprietario: '',
	certificadoSegVeicularModificacao: '',
	chassi: '',
	chassiRemarcado: false,
	cilindrada: '',
	cmt: '',
	codigoMunicipio: '',
	codigoMunicipioPlacaAnterior: '',
	codigoMunicipioProprietario: '',
	complementoProprietario: '',
	cpfCnpjProprietario: '',
	csvAnoModificacao: '',
	dataVigenciaGravame: '',
	descricaoCarroceria: '',
	descricaoCategoria: '',
	descricaoCombustivel: '',
	descricaoCor: '',
	descricaoEspecie: '',
	descricaoMarca: '',
	descricaoTipo: '',
	distanciaEixoModificacao: '',
	eixo: '',
	eixoModificacao: '',
	encurtamentoChassiModificacao: false,
	enderecoProprietario: '',
	financeiraGravame: '',
	freiosModificacao: false,
	identificacao: '',
	iluminacaoFrontalSinalizacaoModificacao: false,
	insulfilmeModificacao: false,
	longIdCarroceria: '',
	longIdCategoria: '',
	longIdCombustivel: '',
	longIdCor: '',
	longIdEspecie: '',
	longIdMarca: '',
	longIdTipo: '',
	nomeMunicipio: '',
	nomeMunicipioPlacaAnterior: '',
	nomeMunicipioProprietario: '',
	nomeProprietario: '',
	nomeProprietarioAnterior: '',
	numeroContratoGravame: '',
	numeroEspelho: '',
	numeroProprietario: '',
	orgaoExpeditorProprietario: '',
	parachoqueModificacao: false,
	pbt: '',
	placa: '',
	placaAnterior: '',
	plataformaModificacao: false,
	potencia: '',
	potenciaCilindradaModificacao: false,
	procedencia: '',
	quantidadeTanqueSuplementarModificacao: '',
	rebaixamentoModificacao: false,
	reencarrocamentoModificacao: false,
	restricaoFinanceiraGravame: '',
	rgProprietario: '',
	rgDigito: '',
	rodaPneuModificacao: false,
	suspensaoModificacao: false,
	suspensaoPneumaticaModificacao: false,
	tanqueSuplementarModificacao: false,
	taxaAutenticacaoDigital: '',
	telefone: '',
	tetoSolarModificacao: false,
	tipoAcessibilidadePassageiroModificacao: '',
	tipoPesquisa: 'alteracao',
	ufPlacaAnterior: '',
	ufProprietario: '',
	valorSuspensaoModificacao: '',
	visualModificacao: false,
	documentos: [],
	servicoDigital: true,
	dddTelefoneCidadao: '',
	emailCidadao: '',
	telefoneCidadao: '',
	codBanco: '',
	dataContabil: '',
	nsuProdesp: '',
};

export const schema = Yup.object<IEmitirAlteracaoCadastral>().shape({
	placa: Yup.string().required('Campo Obrigatório: Placa.'),
	municipio: Yup.string().when(['codigoMunicipio', 'descricaoMunicipio'], {
		is: (codigoMunicipio, descricaoMunicipio) =>
			[codigoMunicipio, descricaoMunicipio].includes(''),
		then: Yup.string().required('Campo Obrigatório: Município.'),
	}),
	chassi: Yup.string().required('Campo Obrigatório: Chassi.'),
	chassiRemarcado: Yup.string().required(
		'Campo Obrigatório: Chassi Remarcado.',
	),
	numeroEspelho: Yup.string().required('Campo Obrigatório: Número do Espelho.'),
	marca: Yup.string().when(['descricaoMarca', 'longIdMarca'], {
		is: (descricaoMarca, longIdMarca) =>
			[descricaoMarca, longIdMarca].includes(''),
		then: Yup.string().required('Campo Obrigatório: Marca.'),
	}),
	anoModelo: Yup.string().required('Campo Obrigatório: Ano Modelo.'),
	tipo: Yup.string().when(['descricaoTipo', 'longIdTipo'], {
		is: (descricaoTipo, longIdTipo) => [descricaoTipo, longIdTipo].includes(''),
		then: Yup.string().required('Campo Obrigatório: Tipo.'),
	}),
	carroceria: Yup.string().when(['descricaoCarroceria', 'longIdCarroceria'], {
		is: (descricaoCarroceria, longIdCarroceria) =>
			[descricaoCarroceria, longIdCarroceria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Carroceria.'),
	}),
	cor: Yup.string().when(['descricaoCor', 'longIdCor'], {
		is: (descricaoCor, longIdCor) => [descricaoCor, longIdCor].includes(''),
		then: Yup.string().required('Campo Obrigatório: Cor.'),
	}),
	categoria: Yup.string().when(['descricaoCategoria', 'longIdCategoria'], {
		is: (descricaoCategoria, longIdCategoria) =>
			[descricaoCategoria, longIdCategoria].includes(''),
		then: Yup.string().required('Campo Obrigatório: Categoria.'),
	}),
	combustivel: Yup.string().when(
		['descricaoCombustivel', 'longIdCombustivel'],
		{
			is: (descricaoCombustivel, longIdCombustivel) =>
				[descricaoCombustivel, longIdCombustivel].includes(''),
			then: Yup.string().required('Campo Obrigatório: Combustível.'),
		},
	),
	especie: Yup.string().when(['descricaoEspecie', 'longIdEspecie'], {
		is: (descricaoEspecie, longIdEspecie) =>
			[descricaoEspecie, longIdEspecie].includes(''),
		then: Yup.string().required('Campo Obrigatório: Espécie.'),
	}),
	capacidadePassageiro: Yup.string().required(
		'Campo Obrigatório: Capacidade Passageiros.',
	),
	capacidadeCarga: Yup.string().required(
		'Campo Obrigatório: Capacidade Carga.',
	),
	potencia: Yup.string().required('Campo Obrigatório: Potência.'),
	cilindrada: Yup.string().required('Campo Obrigatório: Cilindrada.'),
	cmt: Yup.string().required('Campo Obrigatório: CMT.'),
	pbt: Yup.string().required('Campo Obrigatório: PBT.'),
	eixo: Yup.string().required('Campo Obrigatório: Eixos.'),
	procedencia: Yup.string().required(
		'Campo Obrigatório: Procedência do veículo.',
	),
	nomeProprietario: Yup.string().required('Campo Obrigatório: Nome.'),
	cepProprietario: Yup.string().required('Campo Obrigatório: CEP do imóvel.'),
	bairroProprietario: Yup.string().required('Campo Obrigatório: Bairro.'),
	enderecoProprietario: Yup.string().required('Campo Obrigatório: Endereço.'),
	numeroProprietario: Yup.string().required('Campo Obrigatório: Número.'),

	rgProprietario: Yup.string().when(['identificacao'], {
		is: identificacao => identificacao === 'FISICA',
		then: Yup.string().required(`Campo Obrigatório: RG do Proprietário Atual.`),
	}),
	orgaoExpeditorProprietario: Yup.string().when(['identificacao'], {
		is: identificacao => identificacao === 'FISICA',
		then: Yup.string().required(`Campo Obrigatório: Orgão Expedidor.`),
	}),
	ufProprietario: Yup.string().when(['identificacao'], {
		is: identificacao => identificacao === 'FISICA',
		then: Yup.string().required(`Campo Obrigatório: UF.`),
	}),
	nomeProprietarioAnterior: Yup.string().required(
		'Campo Obrigatório: Nome Proprietário Anterior.',
	),
	placaAnterior: Yup.string().required('Campo Obrigatório: Placa Anterior.'),

	quantidadeTanqueSuplementarModificacao: Yup.string().when(
		['tipoPesquisa', 'tanqueSuplementarModificacao'],
		{
			is: (tipoPesquisa, tanqueSuplementarModificacao) =>
				tipoPesquisa === 'modificacao' && tanqueSuplementarModificacao,
			then: Yup.string().required(
				'Campo Obrigatório: Quantidade Tanque Suplementar.',
			),
		},
	),
	capacidadeVolumeTanqueSuplementarModificacao: Yup.string().when(
		['tipoPesquisa', 'tanqueSuplementarModificacao'],
		{
			is: (tipoPesquisa, tanqueSuplementarModificacao) =>
				tipoPesquisa === 'modificacao' && tanqueSuplementarModificacao,
			then: Yup.string().required(
				'Campo Obrigatório: Quantidade Tanque Suplementar.',
			),
		},
	),
	valorSuspensaoModificacao: Yup.string().when(
		['tipoPesquisa', 'tanqueSuplementarModificacao'],
		{
			is: (tipoPesquisa, suspensaoModificacao) =>
				tipoPesquisa === 'modificacao' && suspensaoModificacao,
			then: Yup.string().required('Campo Obrigatório: Valor Suspensão.'),
		},
	),
	tipoAcessibilidadePassageiroModificacao: Yup.string().when(
		['tipoPesquisa', 'acessibilidadePassageiroModificacao'],
		{
			is: (tipoPesquisa, acessibilidadePassageiroModificacao) =>
				tipoPesquisa === 'modificacao' && acessibilidadePassageiroModificacao,
			then: Yup.string().required(
				'Campo Obrigatório: Tipo de Acessibilidade de Passageiro.',
			),
		},
	),
	emailCidadao: Yup.string()
		.matches(userDataRegex.emailAddress, {
			message: 'Campo Inválido: E-mail',
			excludeEmptyString: true,
		})
		.required('Campo Obrigatório: E-mail de Contato'),
	telefoneCidadao: Yup.string().required(
		'Campo Obrigatório: Telefone de Contato',
	),
});

export function treatValues(
	evento: IEvento,
	formValues: IEmitirAlteracaoCadastral,
	taxaServico?: ITaxaServico,
): IRequestEmitirAlteracaoDadosCadastrais {
	const {
		anoModelo,
		arrendatarioFinanceiraGravame,
		bairroProprietario,
		capacidadeCarga,
		capacidadePassageiro,
		cepProprietario,
		chassi,
		chassiRemarcado,
		cilindrada,
		cmt,
		codigoMunicipioProprietario,
		complementoProprietario,
		cpfCnpjProprietario,
		dataVigenciaGravame,
		descricaoCarroceria,
		descricaoCategoria,
		descricaoCombustivel,
		descricaoCor,
		descricaoEspecie,
		descricaoMarca,
		descricaoTipo,
		eixo,
		enderecoProprietario,
		financeiraGravame,
		identificacao,
		longIdCarroceria,
		longIdCategoria,
		longIdCombustivel,
		longIdCor,
		longIdEspecie,
		longIdMarca,
		longIdTipo,
		nomeMunicipioProprietario,
		nomeProprietario,
		nomeProprietarioAnterior,
		numeroContratoGravame,
		numeroEspelho,
		numeroProprietario,
		orgaoExpeditorProprietario,
		pbt,
		placa,
		placaAnterior,
		potencia,
		procedencia,
		restricaoFinanceiraGravame,
		rgProprietario,
		rgDigito,
		telefone,
		ufProprietario,
		documentos,
		servicoDigital,
		emailCidadao,
		telefoneCidadao,
	} = formValues;

	const formatedRg = formatRg(rgProprietario, rgDigito);

	const formattedEvento = {
		id_atendimento: evento.id_atendimento,
		id_cidadao: evento.id_cidadao,
		ip: evento.ip,
		identificacao: evento.identificacao,
		cpf: evento.cpf || '',
		canal: {
			id: 1,
			desc: 'Presencial',
			localidade: {
				desc: evento.canal?.localidade.desc || '',
				id: evento.canal?.localidade.id || 0,
			},
		},
	};

	const requestValues = {
		evento: formattedEvento,
		telefoneCidadao: Number(
			limparMascara(telefoneCidadao.toString()).substring(2, 11),
		),
		dddTelefoneCidadao: Number(
			limparMascara(telefoneCidadao.toString()).substring(0, 2),
		),
		emailCidadao,
		anoModelo: Number(anoModelo),
		capacidadeCarga: capacidadeCarga ? Number(capacidadeCarga) : 0.0,
		capacidadePassageiro: Number(capacidadePassageiro),
		servicoDigital,
		documentos,
		carroceria: {
			descricao: descricaoCarroceria,
			longId: Number(longIdCarroceria),
		},
		categoria: {
			descricao: descricaoCategoria,
			longId: Number(longIdCategoria),
		},
		chassi,
		chassiRemarcado,
		cilindrada: Number(cilindrada),
		cmt: Number(cmt),
		combustivel: {
			descricao: descricaoCombustivel,
			longId: Number(longIdCombustivel),
		},
		cor: {
			descricao: descricaoCor,
			longId: Number(longIdCor),
		},
		eixo: Number(eixo),
		especie: {
			descricao: descricaoEspecie,
			longId: Number(longIdEspecie),
		},
		gravame: {
			arrendatarioFinanceira: arrendatarioFinanceiraGravame,
			dataVigencia: dataVigenciaGravame
				? formatDateISO(dataVigenciaGravame)
				: '',
			financeira: Number(financeiraGravame),
			numeroContrato: numeroContratoGravame,
			restricaoFinanceira: restricaoFinanceiraGravame,
		},
		marca: {
			descricao: descricaoMarca,
			longId: Number(longIdMarca),
		},
		municipio: {
			codigo: Number(codigoMunicipioProprietario),
			nome: nomeMunicipioProprietario,
		},
		numeroEspelho: Number(numeroEspelho),
		pbt: Number(pbt),
		placa,
		placaAnterior,
		potencia: Number(potencia),
		procedencia,
		proprietario: {
			bairro: bairroProprietario,
			cepProprietario: Number(cepProprietario),
			complemento: complementoProprietario,
			cpfCnpjProprietario: Number(limparMascara(cpfCnpjProprietario)) || 0,
			enderecoProprietario,
			identificacao,
			municipioProprietario: {
				codigo: Number(codigoMunicipioProprietario),
				nome: nomeMunicipioProprietario,
			},
			nomeProprietario,
			nomeProprietarioAnterior,
			numero: Number(numeroProprietario),
			orgaoExpeditorProprietario,
			rgProprietario: Number(formatedRg),
			telefone: Number(limparMascara(telefone)),
			ufProprietario,
		},
		taxaAutenticacaoDigital:
			concatenatesTaxaServicoPix(
				taxaServico?.codBanco || '',
				taxaServico?.dataContabil || '',
				taxaServico?.nsuProdesp || '',
			) || '',
		tipo: {
			descricao: descricaoTipo,
			longId: Number(longIdTipo),
		},
	};

	return requestValues;
}

export function treatValuesModificar(
	evento: IEvento,
	formValues: IEmitirAlteracaoCadastral,
	taxaServico?: ITaxaServico,
): IRequestEmitirModificacaoDadosCadastrais {
	const {
		anoModelo,
		capacidadeCarga,
		capacidadePassageiro,
		chassi,
		chassiRemarcado,
		numeroEspelho,
		cilindrada,
		cmt,
		eixo,
		pbt,
		placa,
		placaAnterior,
		potencia,
		procedencia,
		longIdCarroceria,
		descricaoCarroceria,
		longIdCategoria,
		descricaoCategoria,
		longIdCombustivel,
		descricaoCombustivel,
		longIdCor,
		descricaoCor,
		longIdEspecie,
		descricaoEspecie,
		longIdMarca,
		descricaoMarca,
		longIdTipo,
		descricaoTipo,
		nomeProprietario,
		cepProprietario,
		enderecoProprietario,
		numeroProprietario,
		complementoProprietario,
		bairroProprietario,
		telefone,
		codigoMunicipioProprietario,
		nomeMunicipioProprietario,
		rgProprietario,
		rgDigito,
		orgaoExpeditorProprietario,
		ufProprietario,
		identificacao,
		arrendatarioFinanceiraGravame,
		dataVigenciaGravame,
		financeiraGravame,
		numeroContratoGravame,
		restricaoFinanceiraGravame,
		cpfCnpjProprietario,
		nomeProprietarioAnterior,
		acessibilidadeCondutorModificacao,
		acessibilidadePassageiroModificacao,
		alongamentoChassiModificacao,
		blindagemModificacao,
		cabineDuplaModificacao,
		cabineEstendidaModificacao,
		cabineSuplementarModificacao,
		capacidadeVolumeTanqueSuplementarModificacao,
		certificadoSegVeicularModificacao,
		csvAnoModificacao,
		distanciaEixoModificacao,
		eixoModificacao,
		encurtamentoChassiModificacao,
		freiosModificacao,
		iluminacaoFrontalSinalizacaoModificacao,
		insulfilmeModificacao,
		parachoqueModificacao,
		plataformaModificacao,
		potenciaCilindradaModificacao,
		quantidadeTanqueSuplementarModificacao,
		rebaixamentoModificacao,
		reencarrocamentoModificacao,
		rodaPneuModificacao,
		suspensaoModificacao,
		suspensaoPneumaticaModificacao,
		tanqueSuplementarModificacao,
		tetoSolarModificacao,
		tipoAcessibilidadePassageiroModificacao,
		valorSuspensaoModificacao,
		visualModificacao,
		documentos,
		servicoDigital,
	} = formValues;

	const formattedEvento = {
		id_atendimento: evento.id_atendimento,
		id_cidadao: evento.id_cidadao,
		ip: evento.ip,
		identificacao: evento.identificacao,
		cpf: evento.cpf || '',
		canal: {
			id: 1,
			desc: 'Presencial',
			localidade: {
				desc: evento.canal?.localidade.desc || '',
				id: evento.canal?.localidade.id || 0,
			},
		},
	};

	const formatedRg = formatRg(rgProprietario, rgDigito);

	const requestValues = {
		evento: formattedEvento,
		servicoDigital,
		documentos,
		anoModelo: Number(anoModelo),
		capacidadePassageiro: Number(capacidadePassageiro),
		capacidadeCarga: capacidadeCarga ? Number(capacidadeCarga) : 0.0,
		chassiRemarcado,
		chassi,
		numeroEspelho: Number(numeroEspelho),
		cilindrada: Number(cilindrada),
		cmt: Number(cmt),
		eixo: Number(eixo),
		pbt: Number(pbt),
		placa,
		placaAnterior,
		potencia: Number(potencia),
		procedencia,
		carroceria: {
			longId: Number(longIdCarroceria),
			descricao: descricaoCarroceria,
		},
		categoria: {
			longId: Number(longIdCategoria),
			descricao: descricaoCategoria,
		},
		combustivel: {
			longId: Number(longIdCombustivel),
			descricao: descricaoCombustivel,
		},
		cor: {
			longId: Number(longIdCor),
			descricao: descricaoCor,
		},
		especie: {
			longId: Number(longIdEspecie),
			descricao: descricaoEspecie,
		},
		marca: {
			longId: Number(longIdMarca),
			descricao: descricaoMarca,
		},
		municipio: {
			codigo: Number(codigoMunicipioProprietario),
			nome: nomeMunicipioProprietario,
		},
		tipo: {
			longId: Number(longIdTipo),
			descricao: descricaoTipo,
		},
		gravame: {
			arrendatarioFinanceira: arrendatarioFinanceiraGravame,
			dataVigencia: dataVigenciaGravame
				? formatDateISO(dataVigenciaGravame)
				: '',
			financeira: Number(financeiraGravame),
			numeroContrato: numeroContratoGravame,
			restricaoFinanceira: restricaoFinanceiraGravame,
		},
		proprietario: {
			nomeProprietario,
			cepProprietario: Number(cepProprietario),
			bairro: bairroProprietario,
			enderecoProprietario,
			numero: Number(numeroProprietario),
			complemento: complementoProprietario,
			telefone: Number(limparMascara(telefone)),
			identificacao,
			cpfCnpjProprietario: Number(cpfCnpjProprietario),
			nomeProprietarioAnterior,
			rgProprietario: Number(formatedRg),
			orgaoExpeditorProprietario,
			ufProprietario,
			municipioProprietario: {
				codigo: Number(codigoMunicipioProprietario),
				nome: nomeMunicipioProprietario,
			},
		},
		taxaAutenticacaoDigital: concatenatesTaxaServicoPix(
			taxaServico?.codBanco || '',
			taxaServico?.dataContabil || '',
			taxaServico?.nsuProdesp || '',
		),

		modificacaoDadosCadastrais: {
			acessibilidadeCondutor: acessibilidadeCondutorModificacao,
			acessibilidadePassageiro: acessibilidadePassageiroModificacao,
			alongamentoChassi: alongamentoChassiModificacao,
			blindagem: blindagemModificacao,
			cabineDupla: cabineDuplaModificacao,
			cabineEstendida: cabineEstendidaModificacao,
			cabineSuplementar: cabineSuplementarModificacao,
			capacidadeVolumeTanqueSuplementar: Number(
				capacidadeVolumeTanqueSuplementarModificacao,
			),
			certificadoSegVeicular: Number(certificadoSegVeicularModificacao),
			csvAno: Number(csvAnoModificacao),
			distanciaEixo: Number(distanciaEixoModificacao),
			eixo: eixoModificacao === '' ? null : eixoModificacao,
			encurtamentoChassi: encurtamentoChassiModificacao,
			freios: freiosModificacao,
			iluminacaoFrontalSinalizacao: iluminacaoFrontalSinalizacaoModificacao,
			insulfilme: insulfilmeModificacao,
			parachoque: parachoqueModificacao,
			plataforma: plataformaModificacao,
			potenciaCilindrada: potenciaCilindradaModificacao,
			quantidadeTanqueSuplementar: Number(
				quantidadeTanqueSuplementarModificacao,
			),
			rebaixamento: rebaixamentoModificacao,
			reencarrocamento: reencarrocamentoModificacao,
			rodaPneu: rodaPneuModificacao,
			suspensao: suspensaoModificacao,
			suspensaoPneumatica: suspensaoPneumaticaModificacao,
			tanqueSuplementar: tanqueSuplementarModificacao,
			tetoSolar: tetoSolarModificacao,
			tipoAcessibilidadePassageiro: tipoAcessibilidadePassageiroModificacao,
			valorSuspensao: Number(valorSuspensaoModificacao),
			visual: visualModificacao,
		},
	};

	return requestValues;
}
