import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirLocalidadesRelacionadasRequest } from './types';

export function excluirLocalidadesRelacionadasRequest(
	data: ExcluirLocalidadesRelacionadasRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_LOCALIDADES_RELACIONADAS,
		payload: data,
	};
}
export function excluirLocalidadesRelacionadasSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.EXCLUIR_LOCALIDADES_RELACIONADAS_SUCCESS,
		payload,
	};
}
export function excluirLocalidadesRelacionadasFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_LOCALIDADES_RELACIONADAS_FAILURE,
		payload: null,
	};
}
export function excluirLocalidadesRelacionadasClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_LOCALIDADES_RELACIONADAS_CLEAR,
		payload: null,
	};
}
