import { combineReducers } from 'redux';

import atualizarMensagemErro from './atualizarMensagemErro/reducer';
import cadastrarMensagemErro from './cadastrarMensagemErro/reducer';
import consultarMensagensErro from './consultarMensagensErro/reducer';
import excluirMensagemErro from './excluirMensagemErro/reducer';

export default combineReducers({
	atualizarMensagemErro,
	cadastrarMensagemErro,
	consultarMensagensErro,
	excluirMensagemErro,
});
