import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
// import ColorBox from 'components/Common/ColorBox';
import PDFViewer from 'components/Common/PDFViewer';
import Alert from 'components/Common/Notifications/Alert';

// REDUX
import { ApplicationState } from 'store';
import {
	imprimirRenachClear,
	imprimirRenachRequest,
} from 'store/modules/api/detranCnh/imprimir/renach/actions';
import { anamneseRequest } from 'store/modules/api/detranCnh/relatorio/anamnese/actions';
import {
	preCadastroCidadaoClear,
	preCadastroCidadaoRequest,
} from 'store/modules/api/sguService/preCadastroCidadao/actions';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import {
	relatorioAgendamentoMedicoPsicologicoClear,
	relatorioAgendamentoMedicoPsicologicoRequest,
} from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';
import { eventoTerminoSessaoRequest } from 'store/modules/api/detranCnh/evento/terminoSessao/actions';

// UTILS
import { threatValuesEndSessionEventRequest } from 'utils/functions/threatEventsRequest';
import {
	BiometricsProcessMessages,
	getBiometricsProcessMessages,
} from 'pages/DetranCnh/utils/functions/biometrics';
import { BoxRenach, MessageRed } from './styles';
import { formatarPreCadastro } from './functions';

interface IProps {
	renderData: {
		nome?: string;
		renach?: string;
		tipoProcessoSessao?: string;
		dataInicioSessao?: string;
	};
}

const RenachGerado: React.FC<IProps> = ({ renderData }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [pdfFile, setPdfFile] = useState('');
	const [pdfFile2, setPdfFile2] = useState('');
	const [pdfFile3, setPdfFile3] = useState('');
	const [messagesBiometrics, setMessagesBiometrics] =
		useState<BiometricsProcessMessages>({
			messageAlert: '',
			processMessages: [],
		});

	const { requestData } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets.gerarRenach,
	);

	const userLogin = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.user,
	);

	const { imprimirRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.imprimir,
	);

	const { agendarExames } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.divisaoEquitativa,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { relatorio } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.agendamentoMedicoPsicologico,
	);

	const { anamnese } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.relatorio,
	);

	const { preCadastroCidadao } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	// const { geraSenha } = useSelector(
	// 	(state: ApplicationState) => state.api.relatorios,
	// );

	const { eventoTerminoSessao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.evento,
	);

	const { consultarDigitais, confrontarDigitaisCnh } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { capturarBiometria } = useSelector(
		(state: ApplicationState) => state.api.biometria,
	);

	// const [mensagemPromocaoSenha, setMensagemPromocaoSenha] =
	// 	useState<string>('');

	const { login, user } = loginUnico;

	useEffect(() => {
		if (requestData !== null && preCadastroCidadao.status === 0) {
			const body = formatarPreCadastro(
				atendimento,
				login,
				user.nome,
				requestData,
			);
			dispatch(preCadastroCidadaoRequest(body));
		}
	}, [
		dispatch,
		login,
		user.nome,
		atendimento,
		requestData,
		preCadastroCidadao.status,
	]);

	useEffect(() => {
		dispatch(preCadastroCidadaoClear());
	}, [dispatch]);

	// useEffect(() => {
	// 	if (preCadastroCidadao.status === 400) {
	// 		setMensagemPromocaoSenha(preCadastroCidadao.errorMessage);
	// 	} else if (preCadastroCidadao.status === 201) {
	// 		setMensagemPromocaoSenha(
	// 			'O código para o Portal foi gerado e enviado para o e-mail do cidadão.',
	// 		);
	// 	}
	// }, [preCadastroCidadao]);

	useEffect(() => {
		if (imprimirRenach.status === 200 && imprimirRenach.data) {
			window.open(
				imprimirRenach.data.url,
				'_blank',
				'location=yes,height=auto,width=auto,scrollbars=yes,status=false',
			);
		}
	}, [imprimirRenach]);

	useEffect(() => {
		if (relatorio.status === 200 && relatorio.data?.arquivoPDF) {
			setPdfFile(relatorio.data.arquivoPDF);
		}
	}, [relatorio]);

	// useEffect(() => {
	// 	if (geraSenha.status === 200 && geraSenha.data?.arquivoPDF) {
	// 		setPdfFile2(geraSenha.data.arquivoPDF);
	// 	}
	// }, [geraSenha]);

	useEffect(() => {
		if (anamnese.status === 200 && anamnese.data) {
			setPdfFile3(anamnese.data);
		}
	}, [anamnese]);

	useEffect(() => {
		const valuesEndSessionEvent = threatValuesEndSessionEventRequest(
			atendimento,
			loginUnico,
			renderData.tipoProcessoSessao || 'DETRAN_CNH_RENOVACAO',
			renderData.dataInicioSessao || '',
		);

		if (eventoTerminoSessao.status === 0) {
			dispatch(eventoTerminoSessaoRequest(valuesEndSessionEvent));
		}
	}, [
		atendimento,
		dispatch,
		eventoTerminoSessao.status,
		loginUnico,
		renderData.dataInicioSessao,
		renderData.tipoProcessoSessao,
	]);

	useEffect(() => {
		if (consultarDigitais.status !== 0 && consultarDigitais.status !== 100) {
			const messages = getBiometricsProcessMessages(
				renderData.tipoProcessoSessao || 'DETRAN_CNH_RENOVACAO',
				consultarDigitais,
				confrontarDigitaisCnh,
				capturarBiometria,
			);

			if (messages.processMessages.length || messages.messageAlert !== '') {
				setMessagesBiometrics(messages);
			}
		}
	}, [
		capturarBiometria,
		confrontarDigitaisCnh,
		consultarDigitais,
		renderData.tipoProcessoSessao,
	]);

	useEffect(() => {
		dispatch(relatorioAgendamentoMedicoPsicologicoClear());
		dispatch(imprimirRenachClear());

		return () => {
			dispatch(relatorioAgendamentoMedicoPsicologicoClear());
			dispatch(imprimirRenachClear());
		};
	}, [dispatch]);

	return (
		<>
			<Section title="Status do Condutor">
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h3 style={{ textTransform: 'uppercase' }}>{renderData.nome}</h3>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						{messagesBiometrics.messageAlert !== '' && (
							<Alert
								key={messagesBiometrics.messageAlert}
								type="info"
								message={messagesBiometrics.messageAlert}
								onClose={() =>
									setMessagesBiometrics(state => ({
										...state,
										messageAlert: '',
									}))
								}
							/>
						)}
					</Col>
				</Row>

				<Row gutter={[0, 10]} align="middle" justify="center">
					<Col span={15}>
						<BoxRenach>
							<h2>Informações salvas com sucesso</h2>
							<Row>
								<Col span={24}>
									<p>
										O número do RENACH é: <strong>{renderData.renach}</strong>
									</p>
								</Col>
								{messagesBiometrics.processMessages.length > 0 ? (
									messagesBiometrics.processMessages.map(item => {
										if (item.includes('Encaminhar o cidadão')) {
											return (
												<Col key={item} span={24}>
													<MessageRed>{item}</MessageRed>
												</Col>
											);
										}
										return (
											<Col key={item} span={24}>
												<p>{item}</p>
											</Col>
										);
									})
								) : (
									<>
										<Col span={24}>
											<MessageRed>
												Encaminhar o cidadão para a estação biométrica para
												completar seu cadastro.
											</MessageRed>
										</Col>
										<Col span={24}>
											<p>
												A continuidade deste processo só poderá ser realizada
												neste Posto de Atendimento.
											</p>
										</Col>
									</>
								)}
							</Row>

							<Row align="middle" justify="center">
								<Col>
									<ButtonImage
										type="button"
										src="btn-imprimir-renach"
										onClick={() =>
											dispatch(
												imprimirRenachRequest({
													cpfCidadao: requestData?.cpf || '',
													cpfUsuarioLogado: requestData?.cpfUsuario || '',
												}),
											)
										}
									/>
								</Col>
							</Row>

							{requestData && requestData.usoFormularioEnum === 'RENOVACAO' && (
								<Row align="middle" justify="center">
									<Col>
										<ButtonImage
											type="button"
											src="btn-imprimir-anamnese"
											onClick={() =>
												dispatch(
													anamneseRequest({
														cpf: requestData?.cpf || '',
														nome: renderData.nome || '',
														renach: renderData.renach || '',
														codLocalidade: requestData
															? Number(requestData.codLocalidade)
															: 0,
														nomeLocalidade: userLogin.nomePosto,
														tipoProcesso: 'RENOVAÇÃO',
													}),
												)
											}
										/>
									</Col>
								</Row>
							)}
							{/* {requestData?.flagExamePosto} */}
							{(requestData &&
								(requestData.flagAtividadeRemunerada ||
									!requestData.flagExamePosto)) ||
							(requestData &&
								(requestData.usoFormularioEnum === 'PRIMEIRA_HABILITACAO' ||
									requestData.usoFormularioEnum === 'CNH_ESTRANGEIRA')) ? (
								// eslint-disable-next-line react/jsx-indent
								<Row align="middle" justify="center">
									<Col>
										<ButtonImage
											type="button"
											src="btn-imprimir-agendamento-medico-psicologico"
											onClick={() => {
												if (agendarExames.data) {
													dispatch(
														relatorioAgendamentoMedicoPsicologicoRequest({
															categoriaPretendida:
																agendarExames.data.categoria_pretendida,
															cpfCidadao: agendarExames.data.cpf_cidadao,
															crm: agendarExames.data.crm,
															crp: agendarExames.data.crp,
															dataExame: agendarExames.data.data_exame,
															dataExamePsicologo:
																agendarExames.data.data_exame_psicologo,
															enderecoMedico:
																agendarExames.data.endereco_medico,
															enderecoPsicologo:
																agendarExames.data.endereco_psicologo,
															horarioExame: agendarExames.data.horario_exame,
															horarioExamePsicologo:
																agendarExames.data.horario_exame_psicologo,
															nomeCidadao:
																requestData.nomeSocial ||
																agendarExames.data.nome_cidadao,
															nomeMedico: agendarExames.data.nome_medico,
															nomePsicologo: agendarExames.data.nome_psicologo,
															telefones: agendarExames.data.telefones,
															telefonesPsicologo:
																agendarExames.data.telefones_psicologo,
															tipoProcesso: agendarExames.data.tipo_processo,
															flagHabilitaAgendamentoMedico:
																requestData?.usoFormularioEnum ===
																	'PRIMEIRA_HABILITACAO' ||
																requestData.usoFormularioEnum ===
																	'CNH_ESTRANGEIRA'
																	? true
																	: !requestData?.flagExamePosto,
															flagHabilitaAgendamentoPsicologico:
																requestData?.usoFormularioEnum ===
																	'PRIMEIRA_HABILITACAO' ||
																requestData.usoFormularioEnum ===
																	'CNH_ESTRANGEIRA' ||
																requestData.usoFormularioEnum === 'CASSACAO' ||
																requestData.usoFormularioEnum ===
																	'PERMISSIONARIO'
																	? true
																	: !!requestData?.flagAtividadeRemunerada,
														}),
													);
												}
											}}
										/>
									</Col>
								</Row>
							) : (
								''
							)}

							<Row align="middle" justify="center">
								<Col>
									<ButtonImage
										type="button"
										src="finalizar-processo"
										onClick={() => history.push('/detran-cnh')}
									/>
								</Col>
							</Row>
						</BoxRenach>
					</Col>
					{/*
					<Col span={8} offset={1}>
						<ColorBox
							minHeight={200}
							bgColor="azul"
							title
							header
							successTitle={mensagemPromocaoSenha}
						>
							{preCadastroCidadao.status === 201 && (
								<Row
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
									}}
								>
									<Col span={24}>
										<ButtonImage
											type="button"
											src="imprimir"
											onClick={() => {
												dispatch(
													geraSenhaRequest({
														nome:
															requestData?.nomeSocial &&
															requestData?.nomeSocial !== ''
																? requestData.nomeSocial
																: requestData?.nome || '',
														numRg:
															requestData && requestData.numDocumento
																? requestData.numDocumento.trim()
																: '0',
														cpf: requestData ? requestData.cpf : '',
														senha: preCadastroCidadao.data?.token || '',
													}),
												);
											}}
										/>
									</Col>
								</Row>
							)}
						</ColorBox>
					</Col> */}
				</Row>

				{pdfFile && (
					<PDFViewer
						title="Impressão Segunda Via CNH"
						source={pdfFile}
						renderMode="popup"
						onClose={() => setPdfFile('')}
					/>
				)}

				{pdfFile2 && (
					<PDFViewer
						title="Impressão Gerar Senha"
						source={pdfFile2}
						renderMode="popup"
						onClose={() => setPdfFile2('')}
					/>
				)}

				{pdfFile3 && (
					<PDFViewer
						title="Impressão Anamnese"
						source={pdfFile3}
						renderMode="popup"
						onClose={() => setPdfFile3('')}
					/>
				)}
			</Section>

			<Row justify="space-between" align="middle">
				<Col>
					<ButtonVoltar
						navigate={false}
						onClick={() => {
							dispatch(detranCnhClear());
							history.push('/detran-cnh');
						}}
					/>
				</Col>
				<Col>
					<ButtonImage
						type="button"
						src="outro-servico-detran"
						onClick={() => {
							dispatch(detranCnhClear());
							history.push('/detran-cnh');
						}}
					/>
				</Col>
			</Row>
		</>
	);
};

export default RenachGerado;
