export enum Types {
	EVENTO_TERMINO_REQUEST = '@MP/EVENTO_TERMINO_REQUEST',
	EVENTO_TERMINO_SUCCESS = '@MP/EVENTO_TERMINO_SUCCESS',
	EVENTO_TERMINO_FAILURE = '@MP/EVENTO_TERMINO_FAILURE',
	EVENTO_TERMINO_CLEAR = '@MP/EVENTO_TERMINO_CLEAR',
}

export interface EventoTermino {
	status: number;
	data: EventoTerminoErrorData | null;
}

export interface EventoTerminoRequest {
	id_atendimento: string;
	id_cidadao: string;
	canal: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
	atendente: {
		nome: string;
		id_atendente: string;
		id_posto: string;
		desc_posto: string;
	};
	servico: {
		data_inicio: string;
		data_termino: string;
	};
}

export interface EventoTerminoErrorData {
	transacao: string;
	codigo: number;
	mensagem: string;
	mensagens: string[];
}
