import { ReducerAction } from 'store/modules/types';
import {
	Types,
	RequestEmitirEscolhaPlacaPersonalizada,
	emitirEscolhaPlacaPersonalizada,
} from './types';

export function emitirEscolhaPlacaPersonalizadaRequest(
	payload: RequestEmitirEscolhaPlacaPersonalizada,
): ReducerAction {
	return {
		type: Types.EMITIR_ESCOLHA_PLACA_PERSONALIZADA_REQUEST,
		payload,
	};
}
export function emitirEscolhaPlacaPersonalizadaSuccess(
	payload: emitirEscolhaPlacaPersonalizada,
): ReducerAction {
	return {
		type: Types.EMITIR_ESCOLHA_PLACA_PERSONALIZADA_SUCCESS,
		payload,
	};
}
export function emitirEscolhaPlacaPersonalizadaFailure(): ReducerAction {
	return {
		type: Types.EMITIR_ESCOLHA_PLACA_PERSONALIZADA_FAILURE,
		payload: null,
	};
}
export function emitirEscolhaPlacaPersonalizadaClear(): ReducerAction {
	return {
		type: Types.EMITIR_ESCOLHA_PLACA_PERSONALIZADA_CLEAR,
		payload: null,
	};
}
