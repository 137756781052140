import React, { ChangeEvent, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { Field } from 'formik';
import Button from 'components/Common/Button';
import InputMask from 'components/Common/Form/Input/InputMask';
import { useDispatch, useSelector } from 'react-redux';
import {
	consultaTokenCnhDigitalClear,
	consultaTokenCnhDigitalRequest,
} from 'store/modules/api/detranCnh/cnhDigital/tokenDigital/actions';
import { ApplicationState } from 'store';
import { enviaSMSPositusRequest } from 'store/modules/api/detranCnh/cnhDigital/smsPositus/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { treatValuesGenerateToken } from './form';

interface GerarTokenSmsBoxProps {
	codigoPais: string;
	cpf: string;
	ddd: string;
	celular: string;
	email: string;
	infoMessage?: string;
	externalValidation?: () => Promise<boolean>;
	tokenValue: (code: string) => void;
	setMensagensInfo?: (
		values: (oldState: Array<string>) => Array<string>,
	) => void | Array<string>;
}

const GerarTokenSmsBox = ({
	codigoPais,
	cpf,
	ddd,
	celular,
	email,
	infoMessage,
	externalValidation,
	tokenValue,
	setMensagensInfo,
}: GerarTokenSmsBoxProps) => {
	const dispatch = useDispatch();

	const [token, setToken] = useState<string>('');

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { consultaTokenCnhDigital } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cnhDigital,
	);

	const { validaTokenCnhDigital } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cnhDigital,
	);

	useEffect(() => {
		if (
			validaTokenCnhDigital.status === 200 &&
			validaTokenCnhDigital.data?.valido &&
			setMensagensInfo
		) {
			setMensagensInfo(() => {
				return [];
			});
			dispatch(clearNotifications());
		}
	}, [dispatch, setMensagensInfo, validaTokenCnhDigital]);

	const handleGenerateToken = () => {
		const requestGeraToken = treatValuesGenerateToken({
			cpf,
			ddd,
			celular,
			email,
			duracao:
				user.parametrosSistema?.filter(
					params => params.sigla === 'DETRAN_SMS_EXPIRE',
				)[0]?.valor || '30',
		});
		dispatch(consultaTokenCnhDigitalRequest(requestGeraToken));
		return null;
	};

	const validateData = async () => {
		const response = externalValidation && (await externalValidation());

		if (response) {
			handleGenerateToken();
		}

		return null;
	};

	useEffect(() => {
		consultaTokenCnhDigitalClear();
	}, []);

	useEffect(() => {
		if (
			consultaTokenCnhDigital.status === 200 ||
			consultaTokenCnhDigital.status === 201
		) {
			if (consultaTokenCnhDigital.data) {
				const tokenGerado = {
					codigoPais: codigoPais || '55',
					ddd,
					numero: celular,
					mensagem: consultaTokenCnhDigital.data.token,
				};
				dispatch(enviaSMSPositusRequest(tokenGerado));
			}
		}
	}, [celular, codigoPais, consultaTokenCnhDigital, ddd, dispatch]);

	useEffect(() => {
		if (setMensagensInfo && consultaTokenCnhDigital.status === 201) {
			setMensagensInfo(() => {
				return ['Código Enviado com sucesso.'];
			});
			window.scrollTo(0, 0);
		}
	}, [consultaTokenCnhDigital, setMensagensInfo]);

	return (
		<Row>
			<Col span={24}>
				<Row>
					<Col span={24}>
						<p style={{ color: '#006ED7' }}>
							{infoMessage || (
								<>
									Para solicitar a CNH Digital é necessário confirmar o telefone
									celular. <br /> Clique em Gerar Código Verificador e informe o
									código enviado para o telefone celular. Este código é
									necessário para prosseguir com a solicitação.
								</>
							)}
						</p>
					</Col>
				</Row>
				<Row>
					<Col span={6}>
						<Field
							as={InputMask}
							name="codigoVerificador"
							title="Código Verificador"
							mask="*-*-*-*"
							titleAlign="start"
							titleSize="110"
							value={token}
							onChange={(v: ChangeEvent<HTMLInputElement>) => {
								setToken(
									v.currentTarget.value.replace(/[-_ ]+/gi, '').toUpperCase(),
								);
								tokenValue(
									v.currentTarget.value.replace(/[-_ ]+/gi, '').toUpperCase(),
								);
							}}
						/>
					</Col>
					<Col offset={1} span={10}>
						<Button
							type="button"
							onClick={() =>
								validateData ? validateData() : handleGenerateToken()
							}
						>
							Gerar Código Verificador
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

GerarTokenSmsBox.defaultProps = {
	externalValidation: false,
	setMensagensInfo: false,
	infoMessage: null,
};

export default GerarTokenSmsBox;
