import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// ANT DESIGN
import { Col, Row, Tooltip, Modal } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import {
	consultaFornecedorRequest,
	consultaFornecedorClear,
} from 'store/modules/api/procon/fornecedores/consultaFornecedor/actions';
import { PesquisaFornecedorForm } from 'store/modules/api/procon/fornecedores/consultaFornecedor/types';
import {
	pesquisaFornecedorRequest,
	pesquisaFornecedorClear,
} from 'store/modules/api/procon/fornecedores/pesquisaFornecedor/actions';
import { PesquisaFornecedorData } from 'store/modules/api/procon/fornecedores/pesquisaFornecedor/types';
import { CadastraAtendimentoPfRequest } from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPf/types';
import { CadastroConsultaForm } from 'store/modules/api/procon/atendimentosDeConsulta/cadastro/types';

// COMPONENTS
import { FcSearch } from 'react-icons/fc';
import { UlTableIcons } from 'components/Common/Table/styled';
import Error from 'components/Common/Notifications/FormError/index';
import SimpleTable from 'components/Common/Table';
import LightButton from 'components/Common/Buttons/Light';
import Section from 'components/Common/Section';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import TypographyCommon from 'components/Common/Typography';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { Field, Form, Formik, FormikProps } from 'formik';
import {
	clearNotifications,
	addNotifications,
	showInside,
} from 'store/modules/app/notifications/actions';
import { limparMascara } from 'utils/genericFunctions';
import { initialValues, schema } from './form';

// COMPONENTS
import ConsultaFornecedor from '../ConsultaFornecedor';
import CadastroFornecedor from '../CadastroFornecedor';

// STYLED
import { stylePaddingCols } from './styled';

interface Props {
	formikParent: FormikProps<
		CadastraAtendimentoPfRequest | CadastroConsultaForm | any
	>;
	setShowModal: (v: 'none' | 'fornecedor' | 'consumidor') => void;
	typeRegistration: 'consulta' | 'reclamacao';
}

const PesquisarFornecedor: React.FC<Props> = ({
	formikParent,
	setShowModal,
	typeRegistration,
}) => {
	const dispatch = useDispatch();

	const [type, setType] = useState<'add' | 'check' | null>('check');

	const [addDisabledCadastro, setAddDisabledCadastro] = useState<boolean>(true);

	const [addDisabledPesquisa, setAddDisabledPesquisa] =
		useState<boolean>(false);

	const [isSubmited, setIsSubmited] = useState<boolean>(false);

	const [tableVisible, setTableVisible] = useState(false);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const pesquisaFornecedor = useSelector(
		(state: ApplicationState) =>
			state.api.procon.fornecedores.pesquisaFornecedor.data,
	);

	const pesquisaFornecedorStatus = useSelector(
		(state: ApplicationState) =>
			state.api.procon.fornecedores.pesquisaFornecedor.status,
	);

	const fornecedorStatus = useSelector(
		(state: ApplicationState) =>
			state.api.procon.fornecedores.consultaFornecedor.status,
	);

	const handleRowClick = useCallback(
		(values: PesquisaFornecedorForm) => {
			setIsSubmited(true);

			setAddDisabledPesquisa(true);

			if (
				(values.cnpj && values.cnpj.length > 0) ||
				(values.cpf && values.cpf.length > 0) ||
				(values.termo && values.termo.length > 0)
			) {
				dispatch(pesquisaFornecedorClear());
			}

			if (values.cnpj && values.cnpj !== '' && values.cnpj.length >= 14) {
				setTableVisible(false);
				dispatch(
					consultaFornecedorRequest({ cnpj: limparMascara(values.cnpj) }),
				);
			} else if (
				values.cpf &&
				values.cpf !== '' &&
				limparMascara(values.cpf).length === 11
			) {
				setTableVisible(false);
				dispatch(consultaFornecedorRequest({ cpf: limparMascara(values.cpf) }));
			} else if (values.termo && values.termo !== '') {
				setTableVisible(true);
				dispatch(pesquisaFornecedorRequest({ termo: values.termo }));
			}
		},
		[dispatch, setIsSubmited],
	);

	const handleClearFornecedor = useCallback(
		(formik: FormikProps<PesquisaFornecedorForm>) => {
			setType('check');
			setAddDisabledPesquisa(false);
			dispatch(pesquisaFornecedorClear());
			dispatch(consultaFornecedorClear());
			dispatch(clearNotifications());
			setAddDisabledCadastro(true);
			formik.resetForm();
		},
		[dispatch],
	);

	const handleCadastrar = useCallback(
		(formik: FormikProps<PesquisaFornecedorForm>) => {
			if (formik.values.termo && typeRegistration === 'consulta') {
				Modal.confirm({
					okText: 'Sim',
					cancelText: 'Não',
					okButtonProps: { id: 'confirm-razao-social-fornecedor' },
					content: (
						<>
							<div>
								Deseja utilizar esse nome {`"${formik.values.termo}"`} como novo
								fornecedor?
							</div>
						</>
					),
					onOk() {
						formikParent.setFieldValue('nomeDoFornecedor', formik.values.termo);
						setShowModal('none');
					},
					onCancel() {
						setType('add');
					},
				});
			} else {
				setType('add');
			}

			if (notifications.errors.length) {
				dispatch(clearNotifications());
			}

			dispatch(consultaFornecedorClear());

			formik.setFieldValue('cpf', '');
		},
		[
			dispatch,
			formikParent,
			notifications.errors.length,
			setShowModal,
			typeRegistration,
		],
	);

	const headers = [
		{
			title: 'CPF/CNPJ',
			dataIndex: '',
			render: (text: PesquisaFornecedorData) => (
				<span>{!text.cpf ? text.cnpj : text.cpf}</span>
			),
		},
		{
			title: 'Nome/Nome Fantasia',
			dataIndex: 'razaoSocial',
		},
		{
			title: 'Endereço',
			dataIndex: '',
			render: (text: PesquisaFornecedorData) => {
				function formatAddress() {
					let formmatedAddress = '';
					if (text.logradouro) {
						formmatedAddress += `${text.logradouro}, `;
					}
					if (text.numero) {
						formmatedAddress += `${text.numero}, `;
					}
					if (text.bairro) {
						formmatedAddress += `${text.bairro}, `;
					}
					if (text.cidade) {
						formmatedAddress += `${text.cidade}, `;
					}
					if (text.estado) {
						formmatedAddress += `${text.estado}`;
					}
					return formmatedAddress;
				}
				const endercoFormatado = formatAddress();

				return <span>{endercoFormatado}</span>;
			},
		},
		{
			title: 'Observações',
			dataIndex: 'observacao',
		},
		{
			title: 'Ações',
			dataIndex: '',
			align: 'center',
			render: (text: PesquisaFornecedorData) => (
				<UlTableIcons>
					<Tooltip title="Visualizar Fornecedor">
						<FcSearch
							onClick={() =>
								handleRowClick({
									cnpj: !text.cnpj ? text.cpf : text.cnpj,
								})
							}
						/>
					</Tooltip>
				</UlTableIcons>
			),
		},
	];

	useEffect(() => {
		if (pesquisaFornecedor.length > 0) {
			setType('check');
		}

		if (pesquisaFornecedorStatus === 200 && pesquisaFornecedor.length <= 0) {
			addNotifications({
				errors: ['Não foi encontrado fornecedor com os parâmetros informados.'],
			});
		}

		if (pesquisaFornecedorStatus !== 0 || fornecedorStatus !== 0) {
			setAddDisabledCadastro(false);
		}

		if (!isSubmited) {
			setAddDisabledCadastro(true);
		}
	}, [
		dispatch,
		isSubmited,
		pesquisaFornecedor,
		pesquisaFornecedorStatus,
		fornecedorStatus,
	]);

	useEffect(() => {
		dispatch(clearNotifications());
		showInside(true);
	}, [dispatch]);

	useEffect(() => {}, []);

	return (
		<>
			{notifications.errors &&
				notifications.errors.length > 0 &&
				notifications.showInside && (
					<>
						<Error errors={notifications.errors} />
						<br />
					</>
				)}

			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={initialValues}
				validate={values => getValidationsErrors(values, schema)}
				onSubmit={handleRowClick}
				enableReinitialize
			>
				{formik => (
					<Form autoComplete="off">
						<Row
							gutter={[0, 0]}
							align="middle"
							justify="end"
							style={{ paddingBottom: '6px' }}
						>
							<LightButton
								type="button"
								disabled={addDisabledCadastro}
								buttonColor="default"
								text="Novo Cadastro"
								icon="add"
								buttonSize="sm"
								onClick={() =>
									!addDisabledCadastro ? handleCadastrar(formik) : {}
								}
							/>
						</Row>

						<Section
							bgColor="cinza-escuro"
							style={{
								border: '1px grey solid',
								borderRadius: '4px',
								marginTop: '0px',
							}}
						>
							<TypographyCommon
								text="Pesquisa Fornecedor"
								borderBottom
								type="h5"
								fontWeight="bold"
								uppercase
							/>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={5} style={stylePaddingCols}>
									<TypographyCommon
										text="CPF:"
										subtitle=" (XXX.XXX.XXX-XX)"
										fontWeight="bold"
										required
										uppercase
									/>
								</Col>
								<Col span={6} style={stylePaddingCols}>
									<TypographyCommon
										text="CNPJ:"
										subtitle=" (XX.XXX.XXX/XXXX-XX)"
										fontWeight="bold"
										required
										uppercase
									/>
								</Col>
								<Col span={7} style={stylePaddingCols}>
									<TypographyCommon
										text="Razão Social ou Nome Fantasia:"
										fontWeight="bold"
										required
										uppercase
									/>
								</Col>
							</Row>
							<Row gutter={[0, 20]} align="middle" justify="start">
								<Col span={5} style={stylePaddingCols}>
									<Field
										as={InputMask}
										name="cpf"
										value={formik.values.cpf}
										mask="999.999.999-99"
										formatChars={{
											'9': '[0-9]',
											'*': '[0-9]',
										}}
										maskChar=""
										error={hasError(formik.errors, 'validacaoFormulario')}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											formik.setFieldValue(
												'cpf',
												limparMascara(e.currentTarget.value),
											);
											formik.setFieldValue('termo', '');
											formik.setFieldValue('cnpj', '');
										}}
										disabled={addDisabledPesquisa}
									/>
								</Col>
								<Col span={6} style={stylePaddingCols}>
									<Field
										as={InputMask}
										name="cnpj"
										value={formik.values.cnpj}
										mask="99.999.999/9999-99"
										formatChars={{
											'9': '[0-9]',
											'*': '[0-9]',
										}}
										maskChar=""
										error={hasError(formik.errors, 'validacaoFormulario')}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											formik.setFieldValue(
												'cnpj',
												limparMascara(e.currentTarget.value),
											);
											formik.setFieldValue('termo', '');
											formik.setFieldValue('cpf', '');
										}}
										disabled={addDisabledPesquisa}
									/>
								</Col>
								<Col span={7} style={stylePaddingCols}>
									<Field
										as={Input}
										name="termo"
										maxLength="100"
										error={hasError(formik.errors, 'validacaoFormulario')}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											formik.setFieldValue('termo', e.currentTarget.value);
											formik.setFieldValue('cpf', '');
											formik.setFieldValue('cnpj', '');
										}}
										disabled={addDisabledPesquisa}
									/>
								</Col>
								<Col span={6}>
									<Row align="middle" justify="space-between">
										<LightButton
											id="btn_modal_pesquisar_fornecedor"
											type="submit"
											buttonColor="default"
											text="Pesquisar"
											icon="search"
											buttonSize="sm"
											buttonWidth={110}
											title="Pesquisar Fornecedor"
											disabled={addDisabledPesquisa}
										/>
										<LightButton
											id="btn_modal_limpar_fornecedor"
											type="button"
											buttonColor="default"
											text="Limpar"
											onClick={() => handleClearFornecedor(formik)}
											icon="erase"
											buttonSize="sm"
											buttonWidth={80}
											title="Limpar"
										/>
									</Row>
								</Col>
							</Row>

							{!tableVisible && fornecedorStatus === 200 ? (
								<>
									<br />
									<ConsultaFornecedor
										formikParent={formikParent}
										setShowModal={setShowModal}
										typeRegistration={typeRegistration}
									/>
								</>
							) : (
								''
							)}

							{type === 'add' && (
								<>
									<br />
									<CadastroFornecedor
										formikParent={formikParent}
										formikPesquisa={formik}
										setType={setType}
										setShowModal={setShowModal}
										typeRegistration={typeRegistration}
										setAddDisabledPesquisa={setAddDisabledPesquisa}
									/>
								</>
							)}
						</Section>
					</Form>
				)}
			</Formik>

			{type === 'check' && tableVisible && (
				<>
					<br />
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<SimpleTable
								headers={headers}
								body={pesquisaFornecedor ?? []}
								showSizeChanger
								onClick={handleRowClick}
								messageNoResults="Não foi localizado nenhum fornecedor com os dados informados"
								striped
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default PesquisarFornecedor;
