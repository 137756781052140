import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ExcluirUsuarioMainframeRequest } from './types';

// ACTIONS
import {
	excluirUsuarioMainframeSuccess,
	excluirUsuarioMainframeFailure,
} from './actions';

function* excluirUsuarioMainframe(request: ReducerAction) {
	const { payload }: { payload: ExcluirUsuarioMainframeRequest } = request;
	const { id, idLocalidade, usuario } = payload;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_SGU,
		`/usuarios/${id}/localidades/${idLocalidade}/usuario-mainframe/${usuario}`,
	);

	if (response.status === 200 || response.status === 201) {
		response.data = {
			id: usuario,
		};
		yield put(excluirUsuarioMainframeSuccess(response));
	} else {
		yield put(excluirUsuarioMainframeFailure());
	}
}

export default all([
	takeLatest(Types.EXCLUIR_USUARIO_MAINFRAME_REQUEST, excluirUsuarioMainframe),
]);
