import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaTransferenciaMunicipal } from './types';

// ACTIONS
import {
	consultaTransferenciaMunicipalSuccess,
	consultaTransferenciaMunicipalFailure,
} from './actions';

function* consultaTransferenciaMunicipal(request: ReducerAction) {
	const {
		payload,
	}: { payload: IRequestConsultaTransferenciaMunicipal } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}/consultaSegundaViaCnh`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaTransferenciaMunicipalSuccess(response));
	} else if (response.error) {
		yield put(consultaTransferenciaMunicipalFailure(response.error));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_TRANSFERENCIA_MUNICIPAL,
		consultaTransferenciaMunicipal,
	),
]);
