import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types, ConsultarVeiculoV120Request } from './types';
import {
	consultarVeiculoV120Success,
	consultarVeiculoV120Failure,
} from './actions';

function* consultarVeiculoV120Request(request: {
	type: string;
	payload: ConsultarVeiculoV120Request;
}) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consultar-veiculo-v120/${payload.tipoConsulta}`,
		{
			chassi: payload.chassi,
			placa: payload.placa,
		},
	);
	if (response.status === 200 || response.status === 201) {
		yield put(consultarVeiculoV120Success(response));
	} else if (response.error) {
		yield put(consultarVeiculoV120Failure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_VEICULO_V120_REQUEST, consultarVeiculoV120Request),
]);
