export enum Types {
	CONSULTA_USUARIO = '@vivaLeite/CONSULTA_USUARIO',
	CONSULTA_USUARIO_SUCCESS = '@vivaLeite/CONSULTA_USUARIO_SUCCESS',
	CONSULTA_USUARIO_FAILURE = '@vivaLeite/CONSULTA_USUARIO_FAILURE',

	SELECIONA_USUARIO = '@vivaLeite/SELECIONA_USUARIO',

	CONSULTA_ATUALIZACAO_CADASTRAL = '@vivaLeite/CONSULTA_ATUALIZACAO_CADASTRAL',
	CONSULTA_ATUALIZACAO_CADASTRAL_SUCCESS = '@vivaLeite/CONSULTA_ATUALIZACAO_CADASTRAL_SUCCESS',
	CONSULTA_ATUALIZACAO_CADASTRAL_FAILURE = '@vivaLeite/CONSULTA_ATUALIZACAO_CADASTRAL_FAILURE',

	ATUALIZA_ATUALIZACAO_CADASTRAL = '@vivaLeite/ATUALIZA_ATUALIZACAO_CADASTRAL',
	ATUALIZA_ATUALIZACAO_CADASTRAL_SUCCESS = '@vivaLeite/ATUALIZA_ATUALIZACAO_CADASTRAL_SUCCESS',
	ATUALIZA_ATUALIZACAO_CADASTRAL_FAILURE = '@vivaLeite/ATUALIZA_ATUALIZACAO_CADASTRAL_FAILURE',

	CONSULTA_ALERTAS = '@vivaLeite/CONSULTA_ALERTAS',
	CONSULTA_ALERTAS_SUCCESS = '@vivaLeite/CONSULTA_ALERTAS_SUCCESS',
	CONSULTA_ALERTAS_FAILURE = '@vivaLeite/CONSULTA_ALERTAS_FAILURE',

	CONSULTA_SERVICOS_ENTIDADES = '@vivaLeite/CONSULTA_SERVICOS_ENTIDADES',
	CONSULTA_SERVICOS_ENTIDADES_SUCCESS = '@vivaLeite/CONSULTA_SERVICOS_ENTIDADES_SUCCESS',
	CONSULTA_SERVICOS_ENTIDADES_FAILURE = '@vivaLeite/CONSULTA_SERVICOS_ENTIDADES_FAILURE',

	USUARIO_CLEAR = '@vivaLeite/USUARIO_CLEAR',

	REDIRECT_USUARIO_VIVA_LEITE_CLEAR = '@vivaLeite/REDIRECT_USUARIO_VIVA_LEITE_CLEAR',

	NOTIFICATION_USUARIO_VIVA_LEITE_CLEAR = '@vivaLeite/NOTIFICATION_USUARIO_VIVA_LEITE_CLEAR',
}

export interface IUsuarioState {
	usuarioData: IUsuario[];
	atualizacaoCadastralData: IAtualizacaoCadastral;
	selectedUser: IUsuario;
	statusTransacaoUsuario: string;
	selectedCPF: string | number;
	alertas: string[];
	redirectUrlUsuario: string;
	servicos: Servicos[];
}

export interface IUsuario {
	numeroPan: number;
	presidente: string;
	razaoSocial: string;
	responsavel: string;
	situacaoResponsaveis: string;
	status: string;
}

export interface IAtualizacaoCadastral {
	dados_visualizar: IDadosVisualizar;
	dados_atualizar: IDadosAtualizar;
}

export interface IDadosVisualizar {
	nome_responsavel: string;
	rg_responsavel: string;
	cpf_responsavel: string;
	endereco_responsavel: string;
	complemento_responsavel: string;
	bairro_responsavel: string;
	cep_responsavel: string;
	municipio_responsavel: string;
	responsavel_vice: string;
	municipio_endereco_entrega: string;
	distrito_endereco_entrega: string;
	zona_endereco_entrega: string;
}

export interface IDadosAtualizar {
	numero_endereco_responsavel: string;
	telefone_1_responsavel: string;
	telefone_2_responsavel: string;
	celular_responsavel: string;
	id_responsavel: string;
	entrega_segunda: string;
	entrega_terca: string;
	entrega_quarta: string;
	entrega_quinta: string;
	entrega_sexta: string;
	entrega_sabado: string;
	horario_entrega_usina: string;
	horario_entrega_beneficiarios: string;
	email_2_endereco_entrega: string;
	enderecos_entrega: IEndrerecosEntrega[];
}

export interface IEndrerecosEntrega {
	codigo_endereco: number;
	cep: string;
	telefone: string;
	endereco: string;
	numero: string;
	complemento: string;
	bairro: string;
	ponto_referencial: string;
}

interface Servicos {
	cod_servico: number;
	descricao: string;
	permissao: 'S' | 'N';
}

export interface IRequestUsuario {
	cpf: string;
}
export interface IRequestBuscaDadosCadastrais {
	servico: string;
	origem: string;
	num_pan: number;
	etapa: string;
}

export interface IRequestAtualizaDadosCadastrais
	extends IRequestBuscaDadosCadastrais {
	dados_atualizar: IDadosAtualizar;
	redirectUrlUsuario?: string;
}

export interface IRequestAlertas {
	servico: string;
	origem: string;
	num_pan: number | string;
}
