import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_BOLSA_POVO } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, Tabelas } from './types';

// ACTIONS
import { consultarEnderecoSuccess, consultarEnderecoFailure } from './action';

function* consultarEndereco(request: ReducerAction) {
	const { payload }: { payload: Tabelas } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_BOLSA_POVO,
		`cidadao/${CHANNEL}/consulta-enderecos`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultarEnderecoSuccess(response.data.tabelas[0]));
	} else if (response.error) {
		yield put(consultarEnderecoFailure());
	}
}

export default all([takeLatest(Types.CONSULTAR_ENDERECO, consultarEndereco)]);
