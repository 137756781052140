import React, { Suspense, useEffect } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { andamentoProcessoRequest } from 'store/modules/api/detranCrv/Processos/AndamentoProcesso/actions';
import { useHistory } from 'react-router-dom';
import { initialValues, schema } from './form';

const AndamentoProcesso: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { andamentoProcesso } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.processos,
	);

	useEffect(() => {
		if (andamentoProcesso.status === 200) {
			history.push('/detran-crv/andamento-processo/resultado');
		}
	}, [andamentoProcesso, history]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						dispatch(
							andamentoProcessoRequest({
								numeroFicha: values.numeroFicha,
								anoFicha: values.anoFicha,
							}),
						);
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={14}>
										<Field
											required
											titleSize="lg"
											maxLength={7}
											name="numeroFicha"
											title="Número da Ficha"
											as={Input}
											error={hasError(formik.errors, 'numeroFicha')}
										/>
									</Col>
									<Col span={10} />
									<Col span={14}>
										<Field
											required
											titleSize="lg"
											maxLength={4}
											name="anoFicha"
											title="Ano da Ficha Cadastral"
											as={Input}
											error={hasError(formik.errors, 'anoFicha')}
										/>
									</Col>
									<Col span={8} />
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={formik.handleReset}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
		</Suspense>
	);
};

export default AndamentoProcesso;
