import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;

	h1 {
		margin: 30px 0 10px 0;
		@media (max-width: 767.98px) {
			text-align: center;
		}

		/* Small devices (landscape phones, 576px and up) */
		@media (max-width: 575.98px) {
			text-align: center;
		}
	}
`;

export const TooltipInput = styled.div`
	display: flex;
	justify-content: space-between;
	align-content: center;
`;

export const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	form {
		width: 33%;
		/* // Medium devices (tablets, 768px and up) */
		@media (max-width: 768.98px) {
			width: 50%;
		}

		/* Small devices (landscape phones, 576px and up) */
		@media (max-width: 575.98px) {
			width: 100%;
			justify-content: space-around;
		}

		/* // Large devices (desktops, 992px and up) */
		@media (max-width: 991.98px) {
		}

		/* // Extra large devices (large desktops, 1200px and up) */
		@media (max-width: 1199.98px) {
		}
	}
`;

export const Box = styled.div`
	text-align: center;
	margin: 15px;

	.img {
		width: 130px;
		height: 130px;
	}

	.imageInput {
		padding: 0;
		cursor: pointer;
		width: 130px;
		border: 3px solid #eee;
		border-radius: 10px;

		&:hover,
		:focus {
			border: 3px solid #85b2cb !important;
		}
	}

	button {
		border: 0;
		background-color: transparent;
		margin: 0 auto;
		&:hover {
			box-shadow: none;
		}
	}
`;

export const BoxContent = styled.div`
	width: 200px;
	margin: 0 auto;

	p {
		text-align: left;
		margin: 10px 0;
	}

	button {
		margin: 10px auto;
		border: 1px solid;
		background-color: #ffffff;
		&:hover {
			box-shadow: unset;
		}
	}
`;
