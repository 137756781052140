import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	IRequestGetCpfPermitido,
	Evento,
} from 'store/modules/api/bolsaPovo/getCpfPermitido/types';
import {
	getCpfPermitidoRequest,
	getCpfPermitidoClear,
} from 'store/modules/api/bolsaPovo/getCpfPermitido/action';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { limparConsultarContato } from 'store/modules/api/bolsaPovo/consultarContato/action';

// ROUTES
import { ROUTE_BOLSA_POVO_ALTERACAO_ENDERECO } from 'pages/BolsaPovo/routes/paths';
import { ROUTE_BOLSA_POVO } from 'routes/paths';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import CPF from 'components/Common/Form/Fields/CPF';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { limparMascara } from 'utils/genericFunctions';

// FORM
import { initialValues, schema } from './form';

type TypeAccounting = {
	atualizacaoCadastralSolicitacao: {
		id_servico: string | number;
		desc_servico: string;
		tipo: string;
	};
};

const PesquisaEndereco: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const [filtered, setFiltered] = useState<boolean>(false);

	useEffect(() => {
		dispatch(limparConsultarContato());
		dispatch(getCpfPermitidoClear());
	}, [dispatch]);

	const { getCpfPermitido } = useSelector(
		(state: ApplicationState) => state.api.bolsaPovo,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.salvarAtendimento.data,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const loginUnico = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const handleTipoContabilizacao = useMemo(() => {
		return {
			atualizacaoCadastralSolicitacao: {
				id_servico: 23,
				desc_servico: 'seds_bolsapovo_atualizacao_cadastral_solicitacao',
				tipo: 'seds_bolsapovo_atualizacao_cadastral_solicitacao',
			},
		};
	}, []);

	const handleSubmit = useCallback(
		(values: IRequestGetCpfPermitido) => {
			setFiltered(true);

			const tipoContabilizacao: TypeAccounting['atualizacaoCadastralSolicitacao'] =
				handleTipoContabilizacao.atualizacaoCadastralSolicitacao;

			const evento: Evento = {
				id_atendimento: dadosAtendimento?.idAtendimento || '',
				id_cidadao: dadosAtendimento?.idCidadao || '',
				cpf: Number(limparMascara(user.cpf)),
				identificacao: Number(limparMascara(values.cpf)),
				ip: loginUnico.login.user.ip || '',
				tipo: tipoContabilizacao.tipo || '',
				id_servico: tipoContabilizacao.id_servico || '',
				desc_servico: tipoContabilizacao.desc_servico || '',
				canal: {
					id: loginUnico.login.user.idCanal,
					desc: loginUnico.login.user.descCanal,
					estacao: {
						id: 0,
						desc: '',
					},
					localidade: {
						id: Number(user.posto),
						desc: user.nomePosto,
					},
				},
			};

			const valuesRequest = {
				cpf: limparMascara(values.cpf),
				evento,
			};

			dispatch(getCpfPermitidoRequest(valuesRequest));
		},
		[
			setFiltered,
			dadosAtendimento,
			dispatch,
			loginUnico,
			user,
			handleTipoContabilizacao,
		],
	);

	useEffect(() => {
		if (filtered && getCpfPermitido.status === 200) {
			setFiltered(false);
			history.push(ROUTE_BOLSA_POVO_ALTERACAO_ENDERECO);
		}
	}, [setFiltered, filtered, getCpfPermitido.status, history]);

	return (
		<>
			<Section title="Pesquisar">
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={values => getValidationsErrors(values, schema)}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row gutter={[0, 10]}>
										<Col span={11}>
											<Field
												as={CPF}
												title="CPF"
												subtitle="(Apenas números)"
												numero="cpf"
												formik={formik}
												titleSize="sm"
												required
												error={!!formik.errors.cpf}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={24} />
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => {
													dispatch(getCpfPermitidoClear());
													dispatch(clearNotifications());
													setFiltered(false);
													formik.handleReset();
												}}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>

			<ButtonVoltar route={ROUTE_BOLSA_POVO} />
		</>
	);
};

export default PesquisaEndereco;
