import { all } from 'redux-saga/effects';

import consultaNacionalidadesSaga from './nacionalidades/saga';
import consultaPaisesSaga from './paises/saga';
import consultaEntidadeMilitarSaga from './entidadeMilitar/saga';
import consultaCategoriaSaga from './categoria/saga';
import consultaCEPSaga from './cep/saga';

export default all([
	consultaNacionalidadesSaga,
	consultaPaisesSaga,
	consultaEntidadeMilitarSaga,
	consultaCategoriaSaga,
	consultaCEPSaga,
]);
