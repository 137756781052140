import produce from 'immer';

// // UTILS
// import { formatDate } from 'utils/genericFunctions';

// TYPES
import { Types, ConsultaPermissionarioPontuado } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultaPermissionarioPontuado = {
	status: 0,
	data: null,
	dataError: null,
	requestData: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaPermissionarioPontuado {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PERMISSIONARIO_PONTUADO_REQUEST: {
				draft.status = 100;
				break;
			}

			case Types.CONSULTA_PERMISSIONARIO_PONTUADO_SUCCESS: {
				draft.requestData = action.payload.request;
				draft.data = action.payload.response.data;
				draft.dataError = INITIAL_STATE.dataError;
				draft.status = action.payload.response.status;
				break;
			}

			case Types.CONSULTA_PERMISSIONARIO_PONTUADO_FAILURE: {
				draft.requestData = action.payload.request;
				draft.data = INITIAL_STATE.data;
				draft.dataError = action.payload.response.data;
				draft.status = action.payload.response.status;
				break;
			}

			case Types.CONSULTA_PERMISSIONARIO_PONTUADO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;
				draft.requestData = INITIAL_STATE.requestData;
				break;
			}

			default:
		}
	});
}
