import * as Yup from 'yup';

import { limparMascara } from 'utils/genericFunctions';
import { IRequestAlteraEndereco } from 'store/modules/api/bolsaPovo/alteraEndereco/types';
import { CadastrarContatoForm } from 'store/modules/api/bolsaPovo/cadastrarContato/types';
import { validateEmail } from 'utils/yupValidations';

interface DadosAtendente {
	nome: string;
	cpf: string;
}

export const initialValues: IRequestAlteraEndereco = {
	idCpf: '',
	dsCep: '',
	dsCodigoIbge: '',
	dsEndereco: '',
	dsBairro: '',
	dsMunicipio: '',
	dsNumero: '',
	dsComplemento: '',
	dsUf: '',
	idEnderecoTipo: '',
	dsTelefoneContato: '',
	dsCelular: '',
	dsEmail: '',
	dsAtendenteCpf: '',
	dsAtendenteNome: '',
};

export const initialValuesCadastro: CadastrarContatoForm = {
	idTipoContato: '',
	valor: '',
};

export function treatValues(
	values: IRequestAlteraEndereco,
	dadosAtendente: DadosAtendente,
	idCpf: string,
): IRequestAlteraEndereco {
	const requestValues = {
		idCpf: limparMascara(idCpf),
		dsCep: values.dsCep,
		dsCodigoIbge: '',
		dsEndereco: values.dsEndereco,
		dsBairro: values.dsBairro,
		dsMunicipio: values.dsMunicipio,
		dsNumero: values.dsNumero,
		dsComplemento: values.dsComplemento,
		dsUf: values.dsUf,
		idEnderecoTipo: values.idEnderecoTipo,
		dsTelefoneContato: limparMascara(values.dsTelefoneContato),
		dsCelular: limparMascara(values.dsCelular),
		dsEmail: values.dsEmail,
		dsAtendenteCpf: dadosAtendente.cpf,
		dsAtendenteNome: dadosAtendente.nome,
	};

	return requestValues;
}

export const schemaContatos = Yup.object<CadastrarContatoForm>().shape({
	idTipoContato: Yup.string().required(
		'Campo Obrigatório: Por favor selecione o tipo de contato.',
	),
	valores: Yup.string()
		.when(['idTipoContato', 'valor'], {
			is: (idTipoContato, valor) =>
				(idTipoContato === '1' ||
					idTipoContato === 1 ||
					idTipoContato === 2 ||
					idTipoContato === '2') &&
				limparMascara(valor).length < 10,
			then: Yup.string().required(
				'Campo Inválido: O formato do telefone está incorreto.',
			),
		})
		.when(['idTipoContato', 'valor'], {
			is: (idTipoContato, valor) =>
				(idTipoContato === '3' || idTipoContato === 3) &&
				limparMascara(valor).length < 11,
			then: Yup.string().required(
				'Campo Inválido: O formato do telefone está incorreto.',
			),
		})
		.when(['idTipoContato', 'valor'], {
			is: (idTipoContato, valor) =>
				(idTipoContato === '4' ||
					idTipoContato === 4 ||
					idTipoContato === '5' ||
					idTipoContato === 5) &&
				!validateEmail(valor),
			then: Yup.string().required(
				'Campo Inválido: O formato do e-mail está incorreto.',
			),
		}),
});
