import { IConfirmacaoAtendimento } from 'pages/DetranCnh/components/pages/ConfirmacaoAtendimento/form';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';

export const treatDataPreCadastroFormWithOrWithoutModalAttendency = (
	dataPreCadastro: GerarRenachForm,
	dataModalAttendency?: IConfirmacaoAtendimento,
) => {
	const dataWithOrWithoutModalAttendencyData: GerarRenachForm =
		(dataModalAttendency && {
			...dataPreCadastro,
			dadosModalAtendimento: dataModalAttendency,
		}) || {
			...dataPreCadastro,
		};

	return dataWithOrWithoutModalAttendencyData;
};
