export enum Types {
	CONSULTA_DBCIN_IIRGD_REQUEST = '@iirgd/CONSULTA_DBCIN_IIRGD_REQUEST',
	CONSULTA_DBCIN_IIRGD_SUCCESS = '@iirgd/CONSULTA_DBCIN_IIRGD_SUCCESS',
	CONSULTA_DBCIN_IIRGD_FAILURE = '@iirgd/CONSULTA_DBCIN_IIRGD_FAILURE',
	CONSULTA_DBCIN_IIRGD_CLEAR = '@iirgd/CONSULTA_DBCIN_IIRGD_CLEAR',
}

export interface ConsultaDBCIN {
	status: number;
	data: ConsultaDBCINData | null;
	request: ConsultaDBCINRequest | null;
}

export interface ConsultaDBCINData {
	messageClassName: string;
	postoEmissor: string;
	dataInclusao: string;
	dataAlteracao: string;
	dataEmissao: string;
	tipoGrafico: string;
	cinImagem: string;
	cinQrCode: string;
	codMrzPassaporte: string;
	prtValidImagem: string;
	indicadorConsistenciaBiografica: string;
	indicadorConsistenciaBiometrica: string;
	situacao: string;
	situacaoDescricao: string;
	situacaoSerpro: string;
	codigoHashRfb: string;
	protocoloRfb: string;
	dataProtocoloRfb: string;
	cpf: string;
	registroNumero: string;
	registroDigito: string;
	registroSequencia: string;
	usuarioAlteracao: string;
	usuarioAlteracaoCpf: string;
	usuarioInclusao: string;
	usuarioInclusaoCpf: string;
	observacao: string;
	status: string;
	statusMensagem: string;
	interMensagem: string;
	messageType: string;
}

export interface ConsultaDBCINRequest {
	cpf: string;
	digitoCpf: string;
	registroNumero: string;
	registroDigito: string;
	registroSequencia: string;
	usuario: string;
	senha: string;
}
