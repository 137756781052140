export enum Types {
	CONSULTAR_VEICULO_V120_REQUEST = '@DETRAN-CRV/CONSULTAR_VEICULO_V120_REQUEST',
	CONSULTAR_VEICULO_V120_SUCCESS = '@DETRAN-CRV/CONSULTAR_VEICULO_V120_SUCCESS',
	CONSULTAR_VEICULO_V120_FAILURE = '@DETRAN-CRV/CONSULTAR_VEICULO_V120_FAILURE',
	CONSULTAR_VEICULO_V120_CLEAR = '@DETRAN-CRV/CONSULTAR_VEICULO_V120_CLEAR',
}

export interface consultarVeiculoV120 {
	status: number;
	data: caracteristicas | null;
	placaPesquisada: string;
	categoria?: string;
}

export interface ConsultarVeiculoV120Request {
	chassi: string;
	placa: string;
	tipoConsulta?:
		| 'ALTERACAO_MODIFICACAO'
		| 'LICENCIAMENTO'
		| 'TRANSFERENCIA'
		| 'EMPLACAMENTO';
	selecao?: 'chassi' | 'placa';
	categoria?: string;
}

export type IProcedencia = 'IMPORTADA' | 'NACIONAL';

export interface caracteristicas {
	caracteristicaVeiculo: {
		placa: string;
		placaAnterior: string;
		ufPlaca: string;
		ufPlacaAnterior: string;
		municipioPlacaAnterior: {
			codigo: number;
			nome: string;
		};
		ufEspelhoAnterior: string;
		renavam: number;
		chassi: string;
		chassiRemarcado: true;
		anoFabricacao: number;
		anoModelo: number;
		capacidadePassageiro: number;
		capacidadeCarga: number;
		potencia: number;
		cilindrada: number;
		cmt: number;
		pbt: number;
		procedencia: IProcedencia;
		eixo: number;
		carroceria: {
			longId: number;
			descricao: string;
		};
		categoria: {
			descricao: string;
			longId: number;
		};
		combustivel: {
			longId: number;
			descricao: string;
		};
		cor: {
			longId: number;
			descricao: string;
		};
		especie: {
			longId: number;
			descricao: string;
		};
		marca: {
			longId: number;
			descricao: string;
		};
		municipio: {
			codigo: number;
			nome: string;
		};
		tipo: {
			longId: number;
			descricao: string;
		};
		gravame: {
			restricao: string;
			codigoFinanceira: number;
			arrendatarioFinanceira: string;
			numeroContrato: string;
			dataVigencia: string;
		};
		comunicacaoVenda: {
			dataVenda: string;
		};
		dadosProprietario: {
			nomeProprietario: string;
			identificacao: string;
			cpfCnpjProprietario: number;
			bairroProprietario: string;
			cepProprietario: string;
			complementoLogradouroProprietario: string;
			enderecoProprietario: string;
			numeroLogradouroProprietario: string;
			municipioProprietario: string;
		};
	};
}
