import { all } from 'redux-saga/effects';

import atendimentoCipSaga from './atendimentoCip/combineSaga';
import interacoesSaga from './interacoes/combineSaga';
import consumidoresSaga from './consumidores/combineSaga';
import fornecedoresSaga from './fornecedores/combineSaga';
import dominiosCrmSaga from './dominiosCrm/combineSaga';
import dominiosSaga from './dominios/combineSaga';
import atendimentosSaga from './atendimentos/combineSaga';
import areasSaga from './areas/combineSaga';
import grupoDeProblemasSaga from './grupoDeProblemas/combineSaga';
import eventosEspeciaisSaga from './eventosEspeciais/combineSaga';
import atendimentosDeConsultaSaga from './atendimentosDeConsulta/combineSaga';
import anexosConsumidorSaga from './anexosConsumidor/combineSaga';
import documentosProduzidosSaga from './documentosProduzidos/combineSaga';
import processosAdministrativos from './processosAdministrativos/combineSaga';

export default all([
	atendimentoCipSaga,
	interacoesSaga,
	consumidoresSaga,
	fornecedoresSaga,
	dominiosCrmSaga,
	dominiosSaga,
	atendimentosSaga,
	areasSaga,
	grupoDeProblemasSaga,
	eventosEspeciaisSaga,
	atendimentosDeConsultaSaga,
	anexosConsumidorSaga,
	documentosProduzidosSaga,
	processosAdministrativos,
]);
