export enum Types {
	EXCLUIR_CONTRATO_REQUEST = '@BOLSA-POVO/EXCLUIR_CONTRATO_REQUEST',
	EXCLUIR_CONTRATO_SUCCESS = '@BOLSA-POVO/EXCLUIR_CONTRATO_SUCCESS',
	EXCLUIR_CONTRATO_FAILURE = '@BOLSA-POVO/EXCLUIR_CONTRATO_FAILURE',
	LIMPAR_EXCLUIR_CONTRATO = '@BOLSA-POVO/LIMPAR_EXCLUIR_CONTRATO',
}

export interface ExcluirContato {
	status: number;
	form: ExcluirContatoRequest;
}

export interface ExcluirContatoRequest {
	id: number;
}
