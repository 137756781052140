import React from 'react';

import { Row, Col } from 'antd';
import Input from 'components/Common/Form/Input/Input';
import CheckBox from 'components/Common/Form/Checkbox';
import { Container } from './styles';

interface ICertidaoCriminais {
	exigCertidaoCriminais: string;
	exigCertidaoCriminais01: string;
	exigCertidaoCriminais02: string;
}

interface IData {
	data: ICertidaoCriminais;
}

const CertidaoCriminais: React.FC<IData> = ({ data }) => {
	return (
		<Container>
			<Row gutter={[15, 5]}>
				<Col span={24}>
					<CheckBox
						name="certidaoCriminais"
						checked={!!data?.exigCertidaoCriminais?.trim()}
						onChange={() => {}}
						disabled
						subtitle="Certidão Positiva da situação do cumprimento da(s) pena(s) do(s) processo(s) relacionado(s) da V. Execuções Criminais de:"
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input
						name="exigCertidaoCriminais01"
						value={data?.exigCertidaoCriminais01}
						disabled
					/>
				</Col>
				<Col span={18}>
					<Input
						name="exigCertidaoCriminais02"
						value={data?.exigCertidaoCriminais02}
						disabled
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default CertidaoCriminais;
