import React from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';

// UTILS
import hasError from 'utils/getFormErrors';

// FORM
import { onlyAlfaNumerico } from 'utils/genericFunctions';
import { IFormCadastroRg } from '../../../../form';

interface Props {
	formik: FormikProps<IFormCadastroRg>;
}

const IdentidadeProfissional: React.FC<Props> = ({ formik }) => {
	const { errors } = formik;

	return (
		<Section title="Identidade Profissional" size="sm">
			<Row gutter={[0, 10]}>
				<Col span={8}>
					<Field
						as={Input}
						title="1. Número"
						name="identProf1"
						maxLength={10}
						value={onlyAlfaNumerico(formik.values.identProf1)}
						required={
							formik.values.identProf1 ||
							formik.values.orgaoEmissor1 ||
							formik.values.identProf2 ||
							formik.values.orgaoEmissor2 ||
							formik.values.identProf3 ||
							formik.values.orgaoEmissor3
						}
						error={
							hasError(errors, 'identProf_1') ||
							!!formik.errors.identProf1 ||
							hasError(errors, 'identProf_menor')
						}
					/>
				</Col>

				<Col span={10}>
					<Field
						as={Input}
						title="Órgão ou Entidade"
						titleSize="lg"
						name="orgaoEmissor1"
						maxLength={10}
						required={
							formik.values.identProf1 ||
							formik.values.orgaoEmissor1 ||
							formik.values.identProf2 ||
							formik.values.orgaoEmissor2 ||
							formik.values.identProf3 ||
							formik.values.orgaoEmissor3
						}
						error={
							hasError(errors, 'identProf_1') ||
							!!formik.errors.orgaoEmissor1 ||
							hasError(errors, 'identProf_menor')
						}
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={8}>
					<Field
						as={Input}
						title="2. Número"
						name="identProf2"
						maxLength={10}
						value={onlyAlfaNumerico(formik.values.identProf2)}
						required={
							formik.values.identProf2 ||
							formik.values.orgaoEmissor2 ||
							formik.values.identProf3 ||
							formik.values.orgaoEmissor3
						}
						error={
							hasError(errors, 'identProf_2') ||
							!!formik.errors.identProf2 ||
							hasError(errors, 'identProf_menor')
						}
						disabled={
							formik.values.identProf1 === '' &&
							formik.values.orgaoEmissor1 === '' &&
							formik.values.identProf2 === ''
						}
					/>
				</Col>

				<Col span={10}>
					<Field
						as={Input}
						title="Órgão ou Entidade"
						titleSize="lg"
						name="orgaoEmissor2"
						maxLength={10}
						required={
							formik.values.identProf2 ||
							formik.values.orgaoEmissor2 ||
							formik.values.identProf3 ||
							formik.values.orgaoEmissor3
						}
						disabled={
							formik.values.identProf1 === '' &&
							formik.values.orgaoEmissor1 === '' &&
							formik.values.orgaoEmissor2 === ''
						}
						error={
							hasError(errors, 'identProf_2') ||
							!!formik.errors.orgaoEmissor2 ||
							hasError(errors, 'identProf_menor')
						}
					/>
				</Col>
			</Row>

			<Row>
				<Col span={8}>
					<Field
						as={Input}
						title="3. Número"
						name="identProf3"
						maxLength={10}
						value={onlyAlfaNumerico(formik.values.identProf3)}
						required={formik.values.identProf3 || formik.values.orgaoEmissor3}
						error={
							hasError(errors, 'identProf_3') ||
							!!formik.errors.identProf3 ||
							hasError(errors, 'identProf_menor')
						}
						disabled={
							(formik.values.identProf1 === '' &&
								formik.values.orgaoEmissor1 === '' &&
								formik.values.identProf3 === '') ||
							(formik.values.identProf2 === '' &&
								formik.values.orgaoEmissor2 === '' &&
								formik.values.identProf3 === '')
						}
					/>
				</Col>

				<Col span={10}>
					<Field
						as={Input}
						title="Órgão ou Entidade"
						titleSize="lg"
						name="orgaoEmissor3"
						maxLength={10}
						disabled={
							(formik.values.identProf1 === '' &&
								formik.values.orgaoEmissor1 === '' &&
								formik.values.orgaoEmissor3 === '') ||
							(formik.values.identProf2 === '' &&
								formik.values.orgaoEmissor2 === '' &&
								formik.values.orgaoEmissor3 === '')
						}
						required={formik.values.identProf3 || formik.values.orgaoEmissor3}
						error={
							hasError(errors, 'identProf_3') ||
							!!formik.errors.orgaoEmissor3 ||
							hasError(errors, 'identProf_menor')
						}
					/>
				</Col>
			</Row>
		</Section>
	);
};

export default IdentidadeProfissional;
