import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	flex-direction: column;

	button {
		align-self: center;
		margin-top: 14px;
	}
`;
