import { combineReducers } from 'redux';

import consultaSegundaViaCnh from './consultaSegundaViaCnh/reducer';
import gerarRenachSegundaVia from './gerarRenachSegundaVia/reducer';
import consultaEPortalSegundaVia from './consultaEPortalSegundaVia/reducer';

export default combineReducers({
	consultaSegundaViaCnh,
	gerarRenachSegundaVia,
	consultaEPortalSegundaVia,
});
