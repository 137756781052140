import { RequestEmitirEscolhaPlacaPersonalizada } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirEscolhaPlacaPersonalizada/types';
import * as Yup from 'yup';

export interface ConsultaPlacaDisponivelRequest {
	dadosVeiculo?: {
		chassiVeiculo: string;
		cpfCnpjVeiculo: number | null;
	};
	dadosPlaca: {
		complementoPlaca: string;
		letrasPlaca: string;
	};
}

export const initialValues = {
	complementoPlaca: '',
	letrasPlaca: '',
};

export function treatValues(
	values: RequestEmitirEscolhaPlacaPersonalizada,
): RequestEmitirEscolhaPlacaPersonalizada {
	const formattedData = {
		evento: values.evento,
		cpfCnpjProprietario: values.cpfCnpjProprietario,
		chassi: values?.chassi ? values?.chassi : '',
		placa: values.placa,
	};

	return formattedData;
}

export const schema = Yup.object<ConsultaPlacaDisponivelRequest>().shape({});

export const placaSchema = Yup.object().shape({
	letrasComplemento: Yup.string().when(['letrasPlaca', 'complementoPlaca'], {
		is: (letrasPlaca, complementoPlaca) =>
			letrasPlaca === '' && complementoPlaca === '',
		then: Yup.string().required(`INFORMAR LETRAS E/OU NUMEROS`),
	}),
	// letrasPlaca: Yup.string().matches(
	// 	/^$|(([bB][g-zG-Z][a-zA-Z])|([c-fC-F][a-zA-Z][a-zA-Z])|([gG][a-jA-J][a-zA-Z])|([gG][kK][a-iA-I]))$/,
	// 	'A placa deve conter três letras de BGA a GKI',
	// ),
	// complementoPlaca: Yup.string().matches(
	// 	/^$|[0-9][a-jA-J][0-9]{2}$/,
	// 	'Para o complemento da placa NLNN - há uma regra: as letras liberadas são: de A até J.',
	// ),
});
