export enum Types {
	EXCLUIR_TIPOS = '@SGU/EXCLUIR_TIPOS',
	EXCLUIR_TIPOS_SUCCESS = '@SGU/EXCLUIR_TIPOS_SUCCESS',
	EXCLUIR_TIPOS_FAILURE = '@SGU/EXCLUIR_TIPOS_FAILURE',
	EXCLUIR_TIPOS_CLEAR = '@SGU/EXCLUIR_TIPOS_CLEAR',
}

export interface ExcluirTipos {
	status: number;
	data: null | ExcluirTiposData;
}

export interface ExcluirTiposData {
	status: string;
	statusMensagem: string;
}

export interface ExcluirTiposRequest {
	id?: number | string;
	descricao: string;
}
