/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';

import { Row, Col } from 'antd';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import icon_alerta from 'assets/images/icons/icon_alerta.gif';

import { clearNotifications } from 'store/modules/app/notifications/actions';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import { relatorioAgendamentoMedicoPsicologicoClear } from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';
import { formatDate } from 'utils/genericFunctions';
import {
	ROUTE_DETRAN_CNH_RENOVACAO_PESQUISA,
	// ROUTE_DETRAN_CNH_SEGUNDA_VIA_STATUS_CONDUTOR,
} from '../../../routes/paths';

import { BoxRenach } from './styles';

// import ColorBox from 'components/Common/ColorBox';
// import { DadosContatoRequest } from './form';

interface IProps {
	rengeVencimento: number;
	dateValidate?: string;
	nomeCidadao: string;
	setIsRedirectToStatus: (val: boolean) => void | boolean;
}

const RedirecionamentoRenovacaoCNHVencidaSegundaVia: React.FC<IProps> = ({
	rengeVencimento,
	dateValidate = '',
	nomeCidadao = '',
	setIsRedirectToStatus,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [mensageText, setMensageText] = useState({
		textMensage: '',
		isBtnSegundaVia: false,
		isCnhValidate: false,
	});

	useEffect(() => {
		setIsRedirectToStatus(false);
		if (rengeVencimento <= 30 && rengeVencimento > 0) {
			setMensageText({
				textMensage:
					'A 2a via será emitida com a validade atual. É recomendável aguardar o vencimento e então solicitar a Renovação.',
				isBtnSegundaVia: true,
				isCnhValidate: true,
			});
		} else if (rengeVencimento <= 0) {
			setMensageText({
				textMensage:
					'IMPOSSIBILIDADE DE EMISSÃO DE 2VIA DE CNH. CONTATAR O DENATRAN– Portaria Contran 208/2021',
				isBtnSegundaVia: false,
				isCnhValidate: false,
			});
		}
	}, [rengeVencimento, setIsRedirectToStatus]);

	return (
		<Section title="Status do Condutor">
			<h3 style={{ textTransform: 'uppercase' }}>{nomeCidadao}</h3>
			<BoxRenach>
				<Row gutter={[0, 20]}>
					<Col span={1}>
						<img src={icon_alerta} alt="icone de alerta" />
					</Col>
					<Col span={3}>
						<h2 style={{ color: '#C33' }}>Atenção</h2>
					</Col>
				</Row>

				{mensageText.isCnhValidate &&
				rengeVencimento <= 30 &&
				rengeVencimento > 0 ? (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<h3
								style={{
									color: '#000',
									fontWeight: 'normal',
									marginBottom: '5px',
								}}
							>
								Validade desta CNH ={' '}
								<span style={{ color: '#ff0000', fontWeight: 'bold' }}>
									{dateValidate && formatDate(dateValidate)}
								</span>
							</h3>
							<h3
								style={{
									color: '#000',
									fontWeight: 'normal',
									marginBottom: '5px',
								}}
							>
								Faltam{' '}
								<span style={{ color: '#ff0000', fontWeight: 'bold' }}>
									{rengeVencimento}
								</span>{' '}
								para vencer a CNH.
							</h3>
							<h3 style={{ color: '#000', fontWeight: 'normal' }}>
								{mensageText.textMensage}
							</h3>
						</Col>
					</Row>
				) : (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<h3
								style={{
									color: '#000',
									fontWeight: 'normal',
									marginBottom: '5px',
								}}
							>
								{mensageText.textMensage}
							</h3>
						</Col>
					</Row>
				)}
				<Row align="middle" justify="center">
					<Col>
						<ButtonImage
							type="button"
							src="btn-prosseguir-renovacao"
							onClick={() => {
								dispatch(clearNotifications());
								dispatch(detranCnhClear());
								dispatch(relatorioAgendamentoMedicoPsicologicoClear());
								history.push(ROUTE_DETRAN_CNH_RENOVACAO_PESQUISA);
							}}
						/>
					</Col>
					<Col offset={1}>
						{mensageText.isBtnSegundaVia && (
							<ButtonImage
								type="button"
								src="btn-prosseguir-segunda-via"
								onClick={() => {
									setIsRedirectToStatus(true);
								}}
							/>
						)}
					</Col>
				</Row>
			</BoxRenach>
		</Section>
	);
};

export default RedirecionamentoRenovacaoCNHVencidaSegundaVia;
