import { Types } from './types';

export function consultaCarroceriaRequest() {
	return {
		type: Types.CONSULTA_CARROCERIA_REQUEST,
	};
}
export function consultaCarroceriaSuccess(payload: any) {
	return {
		type: Types.CONSULTA_CARROCERIA_SUCCESS,
		payload,
	};
}
export function consultaCarroceriaFailure(payload: []): any {
	return {
		type: Types.CONSULTA_CARROCERIA_FAILURE,
		payload,
	};
}
