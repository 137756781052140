import React, { ButtonHTMLAttributes, useEffect, useRef } from 'react';

import btn_imprimir from 'assets/images/button/btn_imprimir.gif';
import btn_imprimir_protocolo from 'assets/images/button/btn_imprimir_protocolo.gif';
import btn_gerar_dare from 'assets/images/button/btn_gerar_dare.gif';
import btn_gerar_fic from 'assets/images/button/btn_gerar_fic.gif';
import btn_gerar_protocolo from 'assets/images/button/btn_gerar_protocolo.gif';
import btn_gerar_qrcode from 'assets/images/button/btn_gerar_qrcode.gif';
import btn_reimprimir from 'assets/images/button/btn_reimprimir.gif';
import btn_pesquisar from 'assets/images/button/btn_pesquisar.gif';
import btn_entrar from 'assets/images/button/btn_entrar.gif';
import btn_recuperar_atendimento from 'assets/images/button/btn_recuperar_atendimento.gif';
import btn_seguir from 'assets/images/button/btn_seguir.gif';
import btn_avancar from 'assets/images/button/btn_avancar.gif';
import btn_dare_retaguarda from 'assets/images/button/btn_dare_retaguarda.gif';
import btn_limpar from 'assets/images/button/btn_limpar.gif';
import btn_pesquisar_restricoes from 'assets/images/button/btn_pesquisar_restricoes.gif';
import btn_selecionar from 'assets/images/button/btn_selecionar.gif';
import btn_reeditar_cadastro from 'assets/images/button/btn_reeditar_cadastro.gif';
import btn_salvar_cadastro from 'assets/images/button/btn_salvar_cadastro.gif';
import btn_rg_novo from 'assets/images/button/btn_rg_novo.gif';
import btn_rg_acervo from 'assets/images/button/btn_rg_acervo.gif';
import btn_outro_servico_iirgd from 'assets/images/button/btn_outro_servico_iirgd.gif';
import btn_outro_servico_detran from 'assets/images/button/btn_outro_servico_detran.gif';
import btn_finalizar_processo from 'assets/images/button/btn_finalizar_processo.gif';
import btn_retornar from 'assets/images/button/btn_retornar.gif';
import btn_encerrar from 'assets/images/button/btn_encerrar.gif';
import btn_captura_biometrica from 'assets/images/button/btn_captura_biometrica.gif';
import btn_envio_sms from 'assets/images/button/btn_envio_sms.gif';
import btn_adicionar from 'assets/images/button/btn_adicionar.png';
import btn_salvar from 'assets/images/button/btn_salvar.gif';
import btn_novo_cadastro from 'assets/images/button/btn_novo_cadastro.gif';
import btn_enviar_mp from 'assets/images/button/btn_enviar_mp.gif';
import btn_close from 'assets/images/button/btn_close.png';
import btn_atualizar from 'assets/images/button/btn_atualizar.gif';
import btn_prosseguir from 'assets/images/button/btn_prosseguir.gif';
import btn_calcular from 'assets/images/button/btn_calcular.gif';
import btn_procedimento from 'assets/images/button/btn_procedimento.gif';
import btn_informacao from 'assets/images/button/btn_informacao.gif';
import btn_gerar_boleto from 'assets/images/button/btn_gerar_boleto.gif';
import btn_efetivar from 'assets/images/button/btn_efetivar.gif';
import btn_simular from 'assets/images/button/btn_simular.gif';
import btn_termos from 'assets/images/button/btn_termos.gif';
import btn_confirmar_precadastro from 'assets/images/button/btn_confirmar_precadastro.gif';
import btn_imprimir_agendamento_medico_psicologico from 'assets/images/button/btn_imprimir_agendamento_medico_psicologico.gif';
import btn_imprimir_agendamento_psicologico from 'assets/images/button/btn_imprimir_agendamento_psicologico.png';
import btn_imprimir_dare from 'assets/images/button/btn_imprimir_dare.gif';
import btn_pag_pinpad from 'assets/images/button/btn_pag_pinpad.gif';
import btn_imprimir_renach from 'assets/images/button/btn_imprimir_renach.gif';
import btn_imprimir_anamnese from 'assets/images/button/btn_imprimir_anamnese.gif';
import btn_alterar_renach from 'assets/images/button/btn_alterar-renach.gif';
import btn_habilitar from 'assets/images/button/btn_habilitar.gif';
import btn_prosseguir_renovacao from 'assets/images/button/btn_prosseguir_renovacao.png';
import btn_prosseguir_segunda_via from 'assets/images/button/btn_prosseguir_segunda_via.png';
import btn_script_preatendimento from 'assets/images/button/btn_script-preatendimento.gif';
import btn_ok from 'assets/images/button/btn_ok.png';
import btn_enviar from 'assets/images/button/btn_enviar.png';
import btn_limpar_check from 'assets/images/button/btn_limpar_check.png';
import btn_incluir_check from 'assets/images/button/btn_incluir_check.png';
import btn_cancelar_check from 'assets/images/button/btn_cancelar_check.png';
import btn_rge_cin from 'assets/images/button/btn_rge_cin.gif';
import btn_rgecin_acervo from 'assets/images/button/btn_rgecin_acervo.gif';

// STYLED
import { Button } from './styled';

export type SourceButtonImage =
	| 'entrar'
	| 'pesquisar'
	| 'imprimir'
	| 'imprimir-protocolo'
	| 'gerar-dare'
	| 'gerar-fic'
	| 'gerar-protocolo'
	| 'gerar-qrcode'
	| 'reimprimir'
	| 'retornar'
	| 'recuperar'
	| 'seguir'
	| 'avancar'
	| 'dare-retaguarda'
	| 'limpar'
	| 'pesquisar-restricoes'
	| 'selecionar'
	| 'reeditar-cadastro'
	| 'salvar-cadastro'
	| 'rg-novo'
	| 'rg-acervo'
	| 'outro-servico-iirgd'
	| 'outro-servico-detran'
	| 'encerrar'
	| 'finalizar-processo'
	| 'captura-biometrica'
	| 'envio-sms'
	| 'adicionar'
	| 'salvar'
	| 'novo-cadastro'
	| 'enviar-mp'
	| 'close'
	| 'atualizar'
	| 'prosseguir'
	| 'calcular'
	| 'procedimento'
	| 'informacao'
	| 'gerar-boleto'
	| 'efetivar'
	| 'simular'
	| 'termos'
	| 'btn-confirmar-precadastro'
	| 'btn-imprimir-agendamento-medico-psicologico'
	| 'btn-imprimir-agendamento-psicologico'
	| 'btn-imprimir-dare'
	| 'btn-pag-pinpad'
	| 'btn-imprimir-renach'
	| 'btn-imprimir-anamnese'
	| 'btn-alterar-renach'
	| 'btn-habilitar'
	| 'btn-prosseguir-renovacao'
	| 'btn-prosseguir-segunda-via'
	| 'btn-script-preatendimento'
	| 'btn-ok'
	| 'btn-enviar'
	| 'btn-limpar-check'
	| 'btn-incluir-check'
	| 'btn-cancelar-check'
	| 'btn-rge-cin'
	| 'btn-rgecin-acervo';
export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	type?: 'submit' | 'reset' | 'button' | undefined;
	src: SourceButtonImage;
	disabled?: boolean;
	setFocus?: () => boolean;
}

const ButtonImage: React.FC<Props> = ({
	type = 'button',
	src,
	disabled = false,
	setFocus,
	...rest
}) => {
	let btn;

	switch (src) {
		case 'entrar': {
			btn = btn_entrar;
			break;
		}
		case 'pesquisar': {
			btn = btn_pesquisar;
			break;
		}
		case 'imprimir': {
			btn = btn_imprimir;
			break;
		}
		case 'imprimir-protocolo': {
			btn = btn_imprimir_protocolo;
			break;
		}
		case 'gerar-dare': {
			btn = btn_gerar_dare;
			break;
		}
		case 'gerar-fic': {
			btn = btn_gerar_fic;
			break;
		}
		case 'gerar-protocolo': {
			btn = btn_gerar_protocolo;
			break;
		}
		case 'gerar-qrcode': {
			btn = btn_gerar_qrcode;
			break;
		}
		case 'reimprimir': {
			btn = btn_reimprimir;
			break;
		}
		case 'retornar': {
			btn = btn_retornar;
			break;
		}
		case 'recuperar': {
			btn = btn_recuperar_atendimento;
			break;
		}
		case 'seguir': {
			btn = btn_seguir;
			break;
		}
		case 'avancar': {
			btn = btn_avancar;
			break;
		}
		case 'dare-retaguarda': {
			btn = btn_dare_retaguarda;
			break;
		}
		case 'limpar': {
			btn = btn_limpar;
			break;
		}
		case 'pesquisar-restricoes': {
			btn = btn_pesquisar_restricoes;
			break;
		}
		case 'selecionar': {
			btn = btn_selecionar;
			break;
		}
		case 'reeditar-cadastro': {
			btn = btn_reeditar_cadastro;
			break;
		}
		case 'salvar-cadastro': {
			btn = btn_salvar_cadastro;
			break;
		}
		case 'rg-novo': {
			btn = btn_rg_novo;
			break;
		}
		case 'rg-acervo': {
			btn = btn_rg_acervo;
			break;
		}
		case 'outro-servico-iirgd': {
			btn = btn_outro_servico_iirgd;
			break;
		}
		case 'outro-servico-detran': {
			btn = btn_outro_servico_detran;
			break;
		}
		case 'encerrar': {
			btn = btn_encerrar;
			break;
		}
		case 'finalizar-processo': {
			btn = btn_finalizar_processo;
			break;
		}
		case 'captura-biometrica': {
			btn = btn_captura_biometrica;
			break;
		}
		case 'envio-sms': {
			btn = btn_envio_sms;
			break;
		}
		case 'adicionar': {
			btn = btn_adicionar;
			break;
		}
		case 'salvar': {
			btn = btn_salvar;
			break;
		}
		case 'novo-cadastro': {
			btn = btn_novo_cadastro;
			break;
		}
		case 'enviar-mp': {
			btn = btn_enviar_mp;
			break;
		}
		case 'close': {
			btn = btn_close;
			break;
		}
		case 'atualizar': {
			btn = btn_atualizar;
			break;
		}
		case 'prosseguir': {
			btn = btn_prosseguir;
			break;
		}
		case 'calcular': {
			btn = btn_calcular;
			break;
		}
		case 'procedimento': {
			btn = btn_procedimento;
			break;
		}
		case 'informacao': {
			btn = btn_informacao;
			break;
		}
		case 'gerar-boleto': {
			btn = btn_gerar_boleto;
			break;
		}
		case 'efetivar': {
			btn = btn_efetivar;
			break;
		}
		case 'simular': {
			btn = btn_simular;
			break;
		}
		case 'termos': {
			btn = btn_termos;
			break;
		}
		case 'btn-confirmar-precadastro': {
			btn = btn_confirmar_precadastro;
			break;
		}
		case 'btn-imprimir-agendamento-medico-psicologico': {
			btn = btn_imprimir_agendamento_medico_psicologico;
			break;
		}
		case 'btn-imprimir-agendamento-psicologico': {
			btn = btn_imprimir_agendamento_psicologico;
			break;
		}
		case 'btn-imprimir-dare': {
			btn = btn_imprimir_dare;
			break;
		}
		case 'btn-pag-pinpad': {
			btn = btn_pag_pinpad;
			break;
		}
		case 'btn-imprimir-renach': {
			btn = btn_imprimir_renach;
			break;
		}
		case 'btn-imprimir-anamnese': {
			btn = btn_imprimir_anamnese;
			break;
		}
		case 'btn-alterar-renach': {
			btn = btn_alterar_renach;
			break;
		}
		case 'btn-habilitar': {
			btn = btn_habilitar;
			break;
		}
		case 'btn-prosseguir-renovacao': {
			btn = btn_prosseguir_renovacao;
			break;
		}
		case 'btn-prosseguir-segunda-via': {
			btn = btn_prosseguir_segunda_via;
			break;
		}
		case 'btn-script-preatendimento': {
			btn = btn_script_preatendimento;
			break;
		}
		case 'btn-ok': {
			btn = btn_ok;
			break;
		}
		case 'btn-enviar': {
			btn = btn_enviar;
			break;
		}
		case 'btn-limpar-check': {
			btn = btn_limpar_check;
			break;
		}
		case 'btn-incluir-check': {
			btn = btn_incluir_check;
			break;
		}
		case 'btn-cancelar-check': {
			btn = btn_cancelar_check;
			break;
		}
		case 'btn-rge-cin': {
			btn = btn_rge_cin;
			break;
		}
		case 'btn-rgecin-acervo': {
			btn = btn_rgecin_acervo;
			break;
		}

		default:
	}

	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (setFocus) {
			const focus = setFocus();

			if (focus === true) {
				buttonRef.current?.focus();
			}
		}
	}, [setFocus]);

	return (
		<Button
			id={src}
			type={type}
			disabled={disabled}
			onClick={rest.onClick}
			ref={buttonRef}
		>
			<img src={btn} alt={btn} />
		</Button>
	);
};

export default ButtonImage;
