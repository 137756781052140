import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { detranCrvClear } from 'store/modules/api/detranCrv/actions';

// COMPONENTS
import RecuperaAtendimento from 'components/Pages/RecuperaAtendimento';

const RecuperarAtendimento: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { redirecionamento } = useSelector(
		(state: ApplicationState) => state.atendimento,
	);

	const handleClick = useCallback(() => {
		dispatch(detranCrvClear());

		if (redirecionamento) {
			history.push(redirecionamento);
		}
	}, [dispatch, redirecionamento, history]);

	return <RecuperaAtendimento onClick={handleClick} />;
};

export default RecuperarAtendimento;
