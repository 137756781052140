import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, TrocarSenhaUsuario } from './types';

export const INITIAL_STATE: TrocarSenhaUsuario = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): TrocarSenhaUsuario {
	return produce(state, draft => {
		switch (action.type) {
			case Types.TROCAR_SENHA_USUARIO: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.TROCAR_SENHA_USUARIO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.TROCAR_SENHA_USUARIO_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.TROCAR_SENHA_USUARIO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
