import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarTiposRequest } from './types';

// ACTIONS
import { cadastrarTiposSuccess, cadastrarTiposFailure } from './actions';

function* cadastrarTipos(request: ReducerAction) {
	const { payload }: { payload: CadastrarTiposRequest } = request;

	const body = { ...payload };

	delete body.codigo;

	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU,
		`tipos-localidade`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarTiposSuccess(response));
	} else {
		yield put(cadastrarTiposFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_TIPOS, cadastrarTipos)]);
