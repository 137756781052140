import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import {
	imprimirRenachClear,
	imprimirRenachRequest,
} from 'store/modules/api/detranCnh/imprimir/renach/actions';
import {
	anamneseClear,
	anamneseRequest,
} from 'store/modules/api/detranCnh/relatorio/anamnese/actions';
import { enumFormUse } from 'utils/constants/types';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import {
	relatorioAgendamentoMedicoPsicologicoClear,
	relatorioAgendamentoMedicoPsicologicoRequest,
} from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';
import {
	consultaCondutorRequest,
	consultaCondutorClear,
} from 'store/modules/api/detranCnh/condutor/consultaCondutor/actions';
import {
	consultaProcessoAbertoRequest,
	consultaProcessoAbertoClear,
} from 'store/modules/api/detranCnh/renachIntegrado/consultaProcessoAberto/actions';

// COMPONENTS
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage, { SourceButtonImage } from 'components/Common/ButtonImage';
import CardReimpressao from 'components/Common/CardReimpressao';
import Error from 'components/Common/Notifications/FormError/index';
import PDFViewer from 'components/Common/PDFViewer';

// UTILS
import {
	limparMascara,
	showComponent,
	validaCpf,
} from 'utils/genericFunctions';
import { ROUTE_DETRAN_CNH_ALTERAR_RENACH } from 'pages/DetranCnh/routes/paths';
import { useHistory } from 'react-router-dom';

let cpf: string;

interface TypeBtnImprimirRenach {
	typeBtn: SourceButtonImage;
	typeFlagExameMedico: boolean;
	typeFlagExamePsico: boolean;
	isVisible: boolean;
}

const ReimpressaoRenach: React.FC = () => {
	const [numeroCpf, setNumeroCpf] = useState<string>('');
	const [notification, setNotification] = useState<string>('');
	const [pdfAnamnese, setPdfAnamnese] = useState('');
	const [pdfExame, setPdfExame] = useState('');
	const [clicked, setClicked] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();

	const { user, login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { imprimirRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.imprimir,
	);

	const { consultaCondutor } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { consultaProcessoAberto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { anamnese } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.relatorio,
	);

	const { relatorio } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.agendamentoMedicoPsicologico,
	);

	const handleClearForm = useCallback(
		(clearCPF?: boolean) => {
			if (clearCPF) {
				setNumeroCpf('');
			}
			dispatch(consultaCondutorClear());
			dispatch(consultaProcessoAbertoClear());
			dispatch(clearNotifications());
			dispatch(anamneseClear());
			dispatch(imprimirRenachClear());
			dispatch(relatorioAgendamentoMedicoPsicologicoClear());
			setNotification('');
			setPdfAnamnese('');
			setPdfExame('');
			setClicked(false);
		},
		[dispatch],
	);

	const [typeButtonImprimirExame, setTypeButtonImprimirExame] =
		useState<TypeBtnImprimirRenach>({
			typeBtn: 'btn-imprimir-agendamento-medico-psicologico',
			typeFlagExameMedico: false,
			typeFlagExamePsico: false,
			isVisible: false,
		});

	const handleChange = useCallback((change: ChangeEvent<HTMLInputElement>) => {
		const { value } = change.target;

		setNumeroCpf(value);
	}, []);

	useEffect(() => {
		if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			consultaCondutor.status === 200
		) {
			if (
				(consultaProcessoAberto.data.nomeMedico === undefined ||
					consultaProcessoAberto.data.nomeMedico === '') &&
				(consultaProcessoAberto.data.nomePsicologo === undefined ||
					consultaProcessoAberto.data.nomePsicologo === '')
			) {
				setTypeButtonImprimirExame({
					typeBtn: 'btn-imprimir-agendamento-medico-psicologico',
					typeFlagExameMedico: false,
					typeFlagExamePsico: false,
					isVisible: false,
				});
			} else if (
				consultaProcessoAberto.data.nomeMedico !== undefined &&
				consultaProcessoAberto.data.nomeMedico !== '' &&
				consultaProcessoAberto.data.nomePsicologo !== undefined &&
				consultaProcessoAberto.data.nomePsicologo !== ''
			) {
				setTypeButtonImprimirExame({
					typeBtn: 'btn-imprimir-agendamento-medico-psicologico',
					typeFlagExameMedico: true,
					typeFlagExamePsico: true,
					isVisible: true,
				});
			} else if (
				(consultaProcessoAberto.data.nomeMedico === undefined ||
					consultaProcessoAberto.data.nomeMedico === '') &&
				consultaProcessoAberto.data.nomePsicologo !== undefined &&
				consultaProcessoAberto.data.nomePsicologo !== ''
			) {
				setTypeButtonImprimirExame({
					typeBtn: 'btn-imprimir-agendamento-psicologico',
					typeFlagExameMedico: false,
					typeFlagExamePsico: true,
					isVisible: true,
				});
			} else if (
				consultaProcessoAberto.data.nomeMedico !== undefined &&
				consultaProcessoAberto.data.nomeMedico !== '' &&
				(consultaProcessoAberto.data.nomePsicologo === undefined ||
					consultaProcessoAberto.data.nomePsicologo === '')
			) {
				setTypeButtonImprimirExame({
					typeBtn: 'btn-imprimir-agendamento-medico-psicologico',
					typeFlagExameMedico: true,
					typeFlagExamePsico: false,
					isVisible: true,
				});
			}
		}
	}, [
		consultaCondutor.status,
		consultaProcessoAberto.data,
		consultaProcessoAberto.status,
	]);

	useEffect(() => {
		setTimeout(() => {
			if (relatorio.status === 200 && relatorio.data?.arquivoPDF) {
				setPdfExame(relatorio.data.arquivoPDF);
				dispatch(relatorioAgendamentoMedicoPsicologicoClear());
			}
		}, 200);
	}, [dispatch, relatorio]);

	const handleClick = useCallback(() => {
		handleClearForm();
		cpf = limparMascara(numeroCpf);
		if (cpf.length <= 0) {
			setNotification('Campo Obrigatório: CPF.');
		} else if (!validaCpf(cpf)) {
			setNotification('CPF digitado inválido.');
		} else {
			dispatch(consultaProcessoAbertoRequest({ cpf, ident: 0 }));
			setClicked(true);
		}
	}, [dispatch, handleClearForm, numeroCpf]);

	const handleValidateTypeProcess = () => {
		if (consultaCondutor.status === 200 && consultaCondutor.data) {
			if (
				!showComponent(
					[
						enumFormUse.PRIMEIRA_HABILITACAO,
						enumFormUse.RENOVACAO,
						enumFormUse.ADICAO_MUDANCA,
						enumFormUse.CASSACAO,
						enumFormUse.PERMISSIONARIO_PONTUADO,
						enumFormUse.CNH_ESTRANGEIRA,
						enumFormUse.SEGUNDA_VIA,
						enumFormUse.CNH_DEFINITIVA,
					],
					consultaCondutor.data.usoFormulario?.trim().replaceAll('0', '') || '',
				)
			) {
				setNotification('Alteração não autorizada.');
				return false;
			}
			if (consultaCondutor.data.codigoPoupatempo !== login.user.posto) {
				setNotification(
					'As informações não podem ser acessadas, o candidato consultado pertence a outra Unidade de Trânsito/Poupatempo.',
				);
				return false;
			}
		}
		if (consultaCondutor.status !== 0 && consultaCondutor.status !== 200) {
			setNotification('Nenhum registro encontrado.');
			return false;
		}
		return true;
	};

	useEffect(() => {
		setTimeout(() => {
			if (
				consultaProcessoAberto.status === 200 &&
				consultaProcessoAberto.data?.codigoInterno &&
				parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
				consultaCondutor.status === 200 &&
				imprimirRenach.status === 200 &&
				imprimirRenach.data
			) {
				window.open(imprimirRenach.data.url);
				dispatch(imprimirRenachClear());
			}
		}, 200);
	}, [
		consultaCondutor.status,
		consultaProcessoAberto.data,
		consultaProcessoAberto.status,
		dispatch,
		imprimirRenach,
	]);

	useEffect(() => {
		setTimeout(() => {
			if (
				consultaProcessoAberto.status === 200 &&
				consultaProcessoAberto.data?.codigoInterno &&
				parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
				consultaCondutor.status === 200 &&
				anamnese.status === 200 &&
				anamnese.data
			) {
				setPdfAnamnese(anamnese.data);
			}
		}, 200);
	}, [
		anamnese,
		consultaCondutor.status,
		consultaProcessoAberto.data,
		consultaProcessoAberto.status,
	]);

	useEffect(() => {
		if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
			clicked
		) {
			dispatch(
				consultaCondutorRequest({
					cpf: consultaProcessoAberto.data.cpfCidadao,
				}),
			);
		} else if (
			consultaProcessoAberto.status === 200 &&
			consultaProcessoAberto.data?.codigoInterno &&
			parseInt(consultaProcessoAberto.data.codigoInterno, 10) === 0
		) {
			addNotifications({
				errors: [consultaProcessoAberto.data.message],
			});
		}
	}, [
		consultaProcessoAberto.data,
		consultaProcessoAberto.status,
		clicked,
		dispatch,
	]);

	useEffect(() => {
		dispatch(consultaCondutorClear());
		dispatch(anamneseClear());
		dispatch(imprimirRenachClear());
		dispatch(relatorioAgendamentoMedicoPsicologicoClear());
		dispatch(consultaProcessoAbertoClear());
		return () => {
			dispatch(anamneseClear());
			dispatch(imprimirRenachClear());
		};
	}, [dispatch]);

	return (
		<CardReimpressao
			title="Reimprimir Renach"
			description="Digite apenas números:"
		>
			{notification.length > 0 ? (
				<Error errors={notification} onClose={() => setNotification('')} />
			) : (
				''
			)}

			<Row align="middle">
				<Col span={12}>
					<InputMask
						name="numeroCpf"
						title="CPF"
						titleSize="auto"
						mask="99999999999"
						value={numeroCpf}
						onChange={handleChange}
					/>
				</Col>

				<Col offset={1} span={11}>
					<ButtonImage src="pesquisar" onClick={handleClick} />
				</Col>
			</Row>
			{consultaProcessoAberto.status === 200 &&
				consultaProcessoAberto.data?.codigoInterno &&
				parseInt(consultaProcessoAberto.data.codigoInterno, 10) !== 0 &&
				consultaCondutor.status === 200 && (
					<>
						<Row align="middle" justify="center">
							<Col>
								<ButtonImage
									src="btn-imprimir-renach"
									onClick={() =>
										dispatch(
											imprimirRenachRequest({
												cpfCidadao: cpf,
												cpfUsuarioLogado: user.cpf,
											}),
										)
									}
								/>
							</Col>
						</Row>
						<Row align="middle" justify="center">
							<Col>
								<ButtonImage
									type="button"
									src="btn-imprimir-anamnese"
									onClick={() =>
										dispatch(
											anamneseRequest({
												cpf: consultaProcessoAberto.data?.cpfCidadao || '',
												nome: consultaProcessoAberto.data?.nomeCidadao || '',
												renach: consultaProcessoAberto.data?.renach || '',
												codLocalidade: Number(login.user.idLocal) || 0,
												nomeLocalidade: login.user.nomePosto,
												tipoProcesso:
													consultaProcessoAberto.data?.tipoProcesso.descricao ||
													'',
											}),
										)
									}
								/>
							</Col>
						</Row>
						{consultaProcessoAberto.data && typeButtonImprimirExame.isVisible && (
							<Row align="middle" justify="center">
								<Col>
									<ButtonImage
										type="button"
										src={typeButtonImprimirExame.typeBtn}
										onClick={() => {
											if (consultaProcessoAberto.data) {
												dispatch(
													relatorioAgendamentoMedicoPsicologicoRequest({
														categoriaPretendida:
															consultaProcessoAberto.data.categoriaPretendida,
														cpfCidadao:
															`00000000000${consultaProcessoAberto.data.cpfCidadao}`.slice(
																-11,
															),
														crm: consultaProcessoAberto.data.crm,
														crp: consultaProcessoAberto.data.crp,
														dataExame:
															(consultaProcessoAberto.data.dataExame &&
																new Date(
																	`${consultaProcessoAberto.data.dataExame} ${consultaProcessoAberto.data.horarioExame}`,
																).toISOString()) ||
															'',
														dataExamePsicologo:
															(consultaProcessoAberto.data.dataExamePsicologo &&
																new Date(
																	`${consultaProcessoAberto.data.dataExamePsicologo} ${consultaProcessoAberto.data.horarioExamePsicologo}`,
																).toISOString()) ||
															'',
														enderecoMedico:
															consultaProcessoAberto.data.enderecoMedico,
														enderecoPsicologo:
															consultaProcessoAberto.data.enderecoPsicologo,
														horarioExame:
															(consultaProcessoAberto.data.dataExame &&
																new Date(
																	`${consultaProcessoAberto.data.dataExame} ${consultaProcessoAberto.data.horarioExame}`,
																).toISOString()) ||
															'',
														horarioExamePsicologo:
															(consultaProcessoAberto.data.dataExamePsicologo &&
																new Date(
																	`${consultaProcessoAberto.data.dataExamePsicologo} ${consultaProcessoAberto.data.horarioExamePsicologo}`,
																).toISOString()) ||
															'',
														nomeCidadao:
															consultaProcessoAberto.data.nomeSocialCidadao ||
															consultaProcessoAberto.data.nomeCidadao,
														nomeMedico: consultaProcessoAberto.data.nomeMedico,
														nomePsicologo:
															consultaProcessoAberto.data.nomePsicologo,
														telefones: consultaProcessoAberto.data.telefones,
														telefonesPsicologo:
															consultaProcessoAberto.data.telefonesPsicologo,
														tipoProcesso:
															consultaProcessoAberto.data.tipoProcesso.descricao.toUpperCase(),
														flagHabilitaAgendamentoMedico:
															typeButtonImprimirExame.typeFlagExameMedico,
														flagHabilitaAgendamentoPsicologico:
															typeButtonImprimirExame.typeFlagExamePsico,
													}),
												);
											}
										}}
									/>
								</Col>
							</Row>
						)}
						<Row align="middle" justify="center" gutter={[0, 8]}>
							<Col>
								<ButtonImage
									src="btn-alterar-renach"
									onClick={() =>
										handleValidateTypeProcess() &&
										history.push(ROUTE_DETRAN_CNH_ALTERAR_RENACH)
									}
								/>
							</Col>
						</Row>
						{pdfAnamnese && (
							<PDFViewer
								title="Impressão Anamnese"
								source={pdfAnamnese}
								renderMode="popup"
								onClose={() => setPdfAnamnese('')}
							/>
						)}
						{pdfExame && pdfExame !== '' && (
							<PDFViewer
								title="Impressão Segunda Via CNH"
								source={pdfExame}
								renderMode="popup"
								onClose={() => setPdfExame('')}
							/>
						)}
					</>
				)}
			<Row justify="center" align="middle">
				<Col>
					<ButtonImage
						src="limpar"
						onClick={() => handleClearForm(true)}
						disabled={numeroCpf === ''}
					/>
				</Col>
			</Row>
		</CardReimpressao>
	);
};

export default ReimpressaoRenach;
