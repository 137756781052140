export enum Types {
	SCRIPT_PRE_ATENDIMENTO_REQUEST = '@iirgd/SCRIPT_PRE_ATENDIMENTO_REQUEST',
	SCRIPT_PRE_ATENDIMENTO_CLEAR = '@iirgd/SCRIPT_PRE_ATENDIMENTO_CLEAR',
}

export interface ScriptPreAtendimento {
	preAtendimentoValido?: boolean;
	teveRg: boolean | null;
	ondeMorava: 'São Paulo' | 'Outro Estado' | null;
	possuiDocumento: boolean | null;
	ufRg: string | null;
	numeroRg: string;
	dataExpedicaoRg: string | null;
	rgOuAcervo: boolean | null;
	teveAlteracao: boolean | null;
	adocao: boolean | null;
	tranGenero: boolean | null;
	paternidadeReconhecida: boolean | null;
	outros: boolean | null;
	alteracaoConfirmada?: boolean | null;
}

export interface ScriptPreAtendimentoForm {
	preAtendimentoValido?: boolean | null;
	teveRg: boolean | null;
	ondeMorava: 'São Paulo' | 'Outro Estado' | null;
	possuiDocumento: boolean | null;
	ufRg: string | null;
	numeroRg: string;
	digitoRg?: string;
	dataExpedicaoRg: string | null;
	rgOuAcervo: boolean | null;
	teveAlteracao: boolean | null;
	adocao: boolean | null;
	tranGenero: boolean | null;
	paternidadeReconhecida: boolean | null;
	outros: boolean | null;
	alteracaoConfirmada?: boolean | null;
}

export const SCRIPT_POSSUI_RG_EM_OUTRO_ESTADO = 'Outro Estado';
export const SCRIPT_POSSUI_RG_EM_SAO_PAULO = 'São Paulo';
