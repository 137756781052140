export const PATH_BIOMETRIA = 'api';
export const PATH_CDHU = 'ppt4g-rest-cdhu/api/v1';
export const PATH_CONTABILIZA = 'ppt4g-rest-contabiliza-eventos/api/v1';
export const PATH_DARE = 'ppt4g-rest-dare/api/v1';
export const PATH_IIRGD = 'ppt4g-rest-iirgd/api/v1';
export const PATH_MP = 'ppt4g-rest-mp/api/v1';
export const PATH_JALES = 'ppt4g-rest-jales/api/v1';
export const PATH_REUSO_RG = 'ppt4g-rest-reuso-rg/api/v1';
export const PATH_RFB = 'ppt4g-rest-rfb/api/v1';
export const PATH_PREFEITURAS = 'ppt4g-rest-prefeituras-api/api/v1';
export const PATH_SERVICOS_PREFEITURAS =
	'ppt4g-rest-servicos-prefeituras/api/v1';
export const PATH_VIVA_LEITE = 'ppt4g-rest-vivaleite/api/v1';
export const PATH_DETRAN_CRV = 'ppt4g-rest-detran-crv/api/v1';
export const PATH_DETRAN_CNH = 'ppt4g-rest-detran-cnh/api/v1';
export const PATH_SGU_SERVICE = 'ppt4g-rest-sgu-service/api/v1';
export const PATH_SGU_SERVICE_AUTH = 'ppt4g-rest-sgu-service/auth/api/v1';
export const PATH_SGU = 'ppt4g-rest-sgu/api/v1';
export const PATH_AAC = 'ppt4g-rest-aac/api/v1';
export const PATH_RELATORIOS = 'ppt4g-rest-relatorios/api/v1';
export const PATH_E_CPF = '';
export const PATH_EDUCACAO = 'ppt4g-rest-educacao/api/v1';
export const PATH_UTILS = 'ppt4g-rest-utils/api/v1';
export const PATH_POTENCIAL = 'ppt4g-rest-potencial/api/v1';
export const PATH_PROCON = 'ppt4g-rest-procon/api/v1';
export const PATH_BOLSA_POVO = 'ppt4g-rest-sua-vida/api/v1';

export const CHANNEL = 'balcaounico';
export const CHANNEL_DETRAN = 'detran-cnh';
