import { IEvento } from '../../types';

export enum Types {
	PESQUISAR_USUARIO_PORTAL = '@cnh/PESQUISAR_USUARIO_PORTAL',
	PESQUISAR_USUARIO_PORTAL_SUCCESS = '@cnh/PESQUISAR_USUARIO_PORTAL_SUCCESS',
	PESQUISAR_USUARIO_PORTAL_FAILURE = '@cnh/PESQUISAR_USUARIO_PORTAL_FAILURE',
}

export interface pesquisarUsuarioPortal {
	status: number;
	data: IDadosPesquisarUsuarioPortal | null;
}

export interface IDadosPesquisarUsuarioPortal {
	codigoAreaCelular: string;
	email: string;
	emailCNHDigital: string;
	nomeCompleto: string;
	telefoneCelular: string;
	uid: string;
	codigo: string;
	mensagem: string;
	cpf: string;
	registro: string;
	codigoSeguranca: string;
}

export interface IRequestPesquisarUsuarioPortal {
	evento?: IEvento;
	cpf: string;
}
