import { ConsultarVeiculoV110Request } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/types';
import * as Yup from 'yup';

export interface LicenciamentoVeiculo {
	tipoPesquisa: 'Chassi' | 'Placa';
	chassi: string;
	placa: string;
}

export const initialValues: LicenciamentoVeiculo = {
	tipoPesquisa: 'Chassi',
	chassi: '',
	placa: '',
};

export const schema = Yup.object<ConsultarVeiculoV110Request>().shape({
	placaRenavamChassi: Yup.string()
		.when(['placa', 'selecao'], {
			is: (placa, chassi, selecao) => placa !== '' && selecao === 'placa',
			then: Yup.string().required(`Placa é OBRIGATÓRIO`),
		})
		.when(['chassi', 'selecao'], {
			is: (chassi, selecao) => chassi === '' && selecao === 'chassi',
			then: Yup.string().required(`Chassi é OBRIGATÓRIO`),
		}),
});
