import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarSiglaRequest } from './types';

export function atualizarSiglaRequest(
	data: AtualizarSiglaRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_SIGLA_REQUEST,
		payload: data,
	};
}
export function atualizarSiglaSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_SIGLA_SUCCESS,
		payload,
	};
}
export function atualizarSiglaFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_SIGLA_FAILURE,
		payload: null,
	};
}
export function atualizarSiglaClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_SIGLA_CLEAR,
		payload: null,
	};
}
