export enum Types {
	CONSULTA_NOME_SOCIAL_REQUEST = '@cnh/CONSULTA_NOME_SOCIAL_REQUEST',
	CONSULTA_NOME_SOCIAL_SUCCESS = '@cnh/CONSULTA_NOME_SOCIAL_SUCCESS',
	CONSULTA_NOME_SOCIAL_FAILURE = '@cnh/CONSULTA_NOME_SOCIAL_FAILURE',
	CONSULTA_NOME_SOCIAL_CLEAR = '@cnh/CONSULTA_NOME_SOCIAL_CLEAR',
}

export interface consultaNomeSocial {
	status: number;
	data: ConsultaNomeSocialData | null;
}

export interface ConsultaNomeSocialData {
	cpf: string;
	argumento: string;
	registro: string;
	pgu: string;
	renach: string;
	nome: string;
	nomeSocial: string;
	pai: string;
	mae: string;
	filiacao1: string;
	filiacao2: string;
	s1: string;
	s2: string;
}

export interface RequestConsultaNomeSocial {
	nome?: string;
	nomeSocial?: string;
	cpf?: string;
	registro?: string;
	renach?: string;
	usuario?: string;
	senha?: string;
}
