import { ReducerAction } from 'store/modules/types';
import { ImprimirDareState, RequestImprimirDare, Types } from './types';

export function imprimirDareRequest(
	payload: RequestImprimirDare,
): ReducerAction {
	return {
		type: Types.IMPRIMIR_DARE_REQUEST,
		payload,
	};
}
export function imprimirDareSuccess(payload: ImprimirDareState): ReducerAction {
	return {
		type: Types.IMPRIMIR_DARE_SUCCESS,
		payload,
	};
}
export function imprimirDareFailure(): ReducerAction {
	return {
		type: Types.IMPRIMIR_DARE_FAILURE,
		payload: null,
	};
}
export function imprimirDareClear(): ReducerAction {
	return {
		type: Types.IMPRIMIR_DARE_CLEAR,
		payload: null,
	};
}
