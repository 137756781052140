export enum Types {
	CONSULTA_NAO_PROCESSADA = '@contabilizacao/CONSULTA_NAO_PROCESSADA',
	CONSULTA_NAO_PROCESSADA_SUCCESS = '@contabilizacao/CONSULTA_NAO_PROCESSADA_SUCCESS',
	CONSULTA_NAO_PROCESSADA_FAILURE = '@contabilizacao/CONSULTA_NAO_PROCESSADA_FAILURE',
}

export interface ConsultaNaoProcessadaState {
	consultaNaoProcessadaData: IConsultaNaoProcessada[];
	totalRegistrosNaoProcessada: number;
}

export interface IConsultaNaoProcessadaRequest {
	status_processamento?: 0 | 1 | string;
	data_evento_de?: string;
	data_evento_ate?: string;
	data_criacao_de?: string;
	data_criacao_ate?: string;
	data_modificacao_de?: string;
	data_modificacao_ate?: string;
	id_atendimento?: string;
	id_cidadao?: string;
	canal?: string;
	pagina_inicio: number;
	limite: number;
}

export interface IConsultaNaoProcessada {
	codigo: number;
	codigo_status_processamento: number;
	data_evento: string;
	id_atendimento: string;
	tipo: string;
	id_cidadao: string;
	canal: ICanal;
}

export interface ICanal {
	id: string;
	descricao: string;
}

interface ICanalJson {
	id: string;
	desc: string;
}

export interface IJson {
	canal: ICanalJson;
	id_atendimento: string;
	id_cidadao: string;
	dados: {
		data_hora: string;
	};
}
