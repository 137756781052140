import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_RELATORIOS } from 'services/_path';

// STORE
import { ReducerAction } from 'store/modules/types';

// UTILS
import { convertDate } from 'utils/genericFunctions';

// ACTIONS
import {
	consultaAtestadoNominalSuccess,
	consultaAtestadoNominalFailure,
} from './actions';

// TYPES
import { Types } from './types';
import { PesquisaNominal } from '../pesquisaNominal/types';

function* consultaAtestadoNominalRequest(request: ReducerAction) {
	const { payload }: { payload: PesquisaNominal } = request;
	const body = {
		dataExpedicaoRg: convertDate(payload.dataExpedicao),
		dataNascimento: convertDate(payload.dataNascimento),
		dc: payload.dc,
		docProcurador: payload.docProcurador.toUpperCase(),
		ipEstacao: payload.ipEstacao,
		nome: payload.nome,
		nomeMae: payload.nomeMae,
		nomePai: payload.nomePai,
		nomeProcurador: payload.nomeProcurador.toUpperCase(),
		numeroRg: payload.numeroDocumento,
		posto: payload.posto,
		sexo: payload.sexo,
		tipoDocApresentado: payload.tipoDocApresentado,
		uf: payload.ufRg,
		usuario: payload.usuario,
	};

	const response: ApiResponse = yield call(
		postApi,
		PATH_RELATORIOS,
		`aac/${CHANNEL}/consultaAtestadoNominal`,
		body,
	);

	if (response.status === 200) {
		yield put(consultaAtestadoNominalSuccess(response.data));
	} else if (response.error) {
		yield put(consultaAtestadoNominalFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_ATESTADO_NOMINAL_REQUEST,
		consultaAtestadoNominalRequest,
	),
]);
