import { ReducerAction } from 'store/modules/types';

import { Types, IRequestGerarRenach } from './types';

export function gerarRenachRequest(data: IRequestGerarRenach): ReducerAction {
	return {
		type: Types.GERAR_RENACH_RENOVACAO,
		payload: data,
	};
}
export function gerarRenachSuccess(payload: object): ReducerAction {
	return {
		type: Types.GERAR_RENACH_RENOVACAO_SUCCESS,
		payload,
	};
}
export function gerarRenachFailure(payload: boolean): ReducerAction {
	return {
		type: Types.GERAR_RENACH_RENOVACAO_FAILURE,
		payload,
	};
}
