import styled, { css } from 'styled-components';

interface LabelProps {
	align?: string;
}

export const Title = styled.div`
	border-bottom: 2px solid #a8a8a8;
	color: #000;
	font-size: calc(0.9rem + ${props => props.theme.fontScale}%);
	font-weight: 700;
	padding-bottom: 5px;
`;
export const H2 = styled.h2`
	font-size: calc(1.2rem + ${props => props.theme.fontScale}%);
	font-weight: 700;
`;

export const Label = styled.div<LabelProps>`
	align-items: center;
	color: #000;
	display: flex;
	font-size: calc(0.75rem + ${props => props.theme.fontScale}%);
	font-weight: 700;
	justify-content: flex-end;
	height: 100%;
	margin-right: 10px;
	text-align: right;
	text-transform: uppercase;

	${props =>
		props.align === 'left' &&
		css`
			text-align: left;
		`};
`;

export const Value = styled.div`
	align-items: center;
	color: #000;
	display: flex;
	font-size: calc(0.75rem + ${props => props.theme.fontScale}%);
	height: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
	white-space: nowrap;
`;
