import { limparMascara } from 'utils/genericFunctions';

export const formatRg = (rg: string, digito: string) => {
	const hasDigito = digito && digito !== '';

	if (hasDigito) {
		return limparMascara(rg + digito);
	}

	return limparMascara(rg);
};
