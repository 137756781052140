import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	/* margin-bottom: 10px; */
	p {
		min-width: 90px;
		font-weight: 600;
		margin: 0 15px 0 0;
		text-align: right;
	}
	span {
		display: block;
	}
`;

export const P = styled.p`
	margin: 0 !important;
	text-align: left !important;
	font-weight: 400 !important;
`;
