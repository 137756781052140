export const REABILITACAO_ERROR_RESPONSE_SERVICE_MESSAGE = {
	CONDUTOR_PROCESSO_ABERTO: 'Condutor com Processo Aberto',
};

export const CONSULTA_RENOVACAO_ERROR_RESPONSE_SERVICE_MESSAGE = {
	CONDUTOR_PROCESSO_ABERTO: 'Condutor com Processo Aberto',
};

export const CONSULTA_EPORTAL_SEGUNDA_VIA_ERROR_RESPONSE_SERVICE_MESSAGE = {
	CONDUTOR_PROCESSO_ABERTO: 'Condutor com Processo Aberto',
};

export const CONSULTA_CNH_DEFINITIVA_ERROR_RESPONSE_SERVICE_MESSAGE = {
	CONDUTOR_PROCESSO_ABERTO: 'Condutor com Processo Aberto',
};

export const CONSULTA_MUDANCA_ERROR_RESPONSE_SERVICE_MESSAGE = {
	CONDUTOR_PROCESSO_ABERTO: 'Condutor com Processo Aberto',
};

export const CONSULTA_PERMISSIONARIO_PONTUADO_ERROR_RESPONSE_SERVICE_MESSAGE = {
	CONDUTOR_PROCESSO_ABERTO: 'Condutor com Processo Aberto',
};
