import produce from 'immer';
// import { formatDate, toSelect } from 'utils/genericFunctions';

import { Types, emissaoPid } from './types';
import { ReducerAction } from '../../../../types';

// UTILS

export const INITIAL_STATE: emissaoPid = {
	status: 0,
	data: null,
};

export default function emissaoPidReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): emissaoPid {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EMISSAO_PID: {
				break;
			}
			case Types.EMISSAO_PID_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.EMISSAO_PID_FAILURE: {
				break;
			}

			default:
		}
	});
}
