import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	Types,
	IRequestConsultaPermissaoInternacionalAutomatizado,
} from './types';

// ACTIONS
import {
	consultaPermissaoInternacionalAutomatizadoSuccess,
	consultaPermissaoInternacionalAutomatizadoFailure,
} from './actions';

function* consultaPermissaoInternacionalAutomatizado(request: ReducerAction) {
	const {
		payload,
	}: { payload: IRequestConsultaPermissaoInternacionalAutomatizado } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`eportal/${CHANNEL}/pesquisa/pid`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaPermissaoInternacionalAutomatizadoSuccess(response));
	} else if (response.error) {
		yield put(
			consultaPermissaoInternacionalAutomatizadoFailure(response.error),
		);
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO,
		consultaPermissaoInternacionalAutomatizado,
	),
]);
