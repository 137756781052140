export enum Types {
	LOGIN_SOAP_REQUEST = '@cnh/LOGIN_SOAP_REQUEST',
	LOGIN_SOAP_SUCCESS = '@cnh/LOGIN_SOAP_SUCCESS',
	LOGIN_SOAP_FAILURE = '@cnh/LOGIN_SOAP_FAILURE',
	LOGIN_SOAP_CLEAR = '@cnh/LOGIN_SOAP_CLEAR',
}

export interface LoginSoap {
	status: number;
}

export interface LoginSoapRequest {
	usuario: string;
	senha: string;
}
