import { ReducerActionRotaSP } from 'store/modules/types';
import { FichaAlunoEnvio, LatLngSelecionada, Types } from './types';

export function cadastrarFichaAlunoRequest(
	token: string,
	payload: FichaAlunoEnvio,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_FICHA_ALUNO_REQUEST,
		token,
		payload,
	};
}

export function latLngSelecionada(payload: LatLngSelecionada) {
	return {
		type: Types.LAT_LNG_SELECIONADA,
		payload,
	};
}

export function cadastrarFichaAlunoSuccess(payload: object) {
	return {
		type: Types.CADASTRAR_FICHA_ALUNO_SUCCESS,
		payload,
	};
}

export function cadastrarFichaAlunoFailure(payload: boolean) {
	return {
		type: Types.CADASTRAR_FICHA_ALUNO_FAILURE,
		payload,
	};
}

export function cadastrarFichaAlunoClear() {
	return {
		type: Types.CADASTRAR_FICHA_ALUNO_CLEAR,
		payload: null,
	};
}
