import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { PATH_DETRAN_CRV, CHANNEL } from 'services/_path';
import { Types } from './types';
import {
	consultarBaseOutrosEstadosSuccess,
	consultarBaseOutrosEstadosFailure,
} from './actions';

function* consultarBaseOutrosEstadosRequest({ payload }: any) {
	const body = {
		chassi: payload.chassi,
		placa: payload.placa,
		uf: payload.uf,
	};
	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consultar-veiculo-base-outros-estados`,
		body,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(consultarBaseOutrosEstadosSuccess(response));
	} else if (response.error) {
		yield put(consultarBaseOutrosEstadosFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_BASE_OUTROS_ESTADOS_REQUEST,
		consultarBaseOutrosEstadosRequest,
	),
]);
