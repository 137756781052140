import { all } from 'redux-saga/effects';

import atualizarLocalidadeSaga from './atualizarLocalidade/saga';
import cadastrarLocalidadeSaga from './cadastrarLocalidade/saga';
import consultarLocalidadeSaga from './consultarLocalidade/saga';
import consultarLocalidadesSaga from './consultarLocalidades/saga';
import excluirLocalidadeSaga from './excluirLocalidade/saga';
import tiposLocalidadesSaga from './tipos/combineSaga';

export default all([
	atualizarLocalidadeSaga,
	cadastrarLocalidadeSaga,
	consultarLocalidadeSaga,
	consultarLocalidadesSaga,
	excluirLocalidadeSaga,
	tiposLocalidadesSaga,
]);
