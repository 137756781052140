import * as Yup from 'yup';

interface IBuscarMunicipioPais {
	pesquisa: string;
}

export const initialValues: IBuscarMunicipioPais = {
	pesquisa: '',
};

const schema = Yup.object<IBuscarMunicipioPais>().shape({
	pesquisa: Yup.string().required('Campo Obrigatório: Município.'),
});

export default async function getValidationsErrors(
	values: IBuscarMunicipioPais,
) {
	try {
		await schema.validate(values, {
			abortEarly: false,
		});

		return true;
	} catch (err) {
		let errors: any = {};

		err.inner.forEach((error: any) => {
			errors = { ...errors, [error.path]: error.message };
		});

		return errors;
	}
}
