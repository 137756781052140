import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaRenovacaoRequest } from './types';

// ACTIONS
import {
	consultaRenovacaoCnhSuccess,
	consultaRenovacaoCnhFailure,
} from './actions';

function* consultaRenovacaoCnh(request: ReducerAction) {
	const { payload }: { payload: ConsultaRenovacaoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`eportal/${CHANNEL}/pesquisa/renovacao-cnh`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaRenovacaoCnhSuccess({ response, request: payload }));
	} else {
		yield put(
			consultaRenovacaoCnhFailure({
				response: response.data,
				request: payload,
			}),
		);
	}
}

export default all([
	takeLatest(Types.CONSULTA_RENOVACAO_CNH_REQUEST, consultaRenovacaoCnh),
]);
