export enum Types {
	DOMAIN_SERVICE_MUNICIPIOS_REQUEST = '@sgu-service/DOMAIN_SERVICE_MUNICIPIOS_REQUEST',
	DOMAIN_SERVICE_MUNICIPIOS_SUCCESS = '@sgu-service/DOMAIN_SERVICE_MUNICIPIOS_SUCCESS',
	DOMAIN_SERVICE_MUNICIPIOS_FAILURE = '@sgu-service/DOMAIN_SERVICE_MUNICIPIOS_FAILURE',
	DOMAIN_SERVICE_MUNICIPIOS_CLEAR = '@sgu-service/DOMAIN_SERVICE_MUNICIPIOS_CLEAR',
}

export interface DomainServiceMunicipios {
	status: number;
	data: DomainServiceMunicipiosResponse[] | null;
}

export interface DomainServiceMunicipiosResponse {
	codigo: number;
	nome: string;
	uf: string;
}

export interface DomainServiceMunicipiosRequest {
	codigo: string;
}
