export interface IVisualizarFicha {
	aluno: Aluno;
	idProcesso: number;
	mensagem: string;
	dataAlteracao: string;
	dadosPessoais: IDadosPessoais;
	documentos: IDocumentos;
	justificativaDocumentos?: string;
	certidaoAntiga: ICertidaoAntiga;
	certidaoNova: ICertidaoNova;
	inscricoes: IInscricao;
	dadosRequisicaoInscricao: IDataRequestInscricao;
}

export interface IRequestExcluirInscricao {
	aluno: Aluno;
	anoLetivo: number;
	codigoEscola: number;
	tipoInscricao: number;
}

interface Aluno {
	numeroRA?: string;
	digitoRA?: string;
	siglaUFRA?: string;
}

export interface IDadosPessoais {
	numeroRA?: string;
	digitoRA?: string;
	siglaUFRA?: string;
	nomeAluno: string;
	dataNascimento: string;
	corRaca: number | string;
	descricaoCorRaca?: string;
	codigoSexo?: number | string;
	sexo: string | number;
	nomeMae: string;
	nomePai?: string;
	nomeSocial?: string;
	nomeAfetivo?: string;
	email?: string;
	nacionalidade: number | string;
	descricaoNacionalidade?: string;
	codigoPaisOrigem: number | string;
	nomePaisOrigem: string;
	dataEntradaPais?: string;
	bolsaFamilia: string;
	quilombola?: string;
	nomeMunicipioNascimento: string;
	ufMunicipioNascimento: string;
	alunoFalecido?: string;
	dataFaleciomento?: string;
	codigoMunicipioNascimentoDNE: number | string;
	codigoBolsaFamilia?: number | boolean;
	possuiInternet?: string;
	possuiNotebookSmartphoneTablet?: string;
}

interface IDocumentos {
	codigoINEP?: number | string;
	cpf: string;
	numeroDocumentoCivil?: string;
	digitoDocumentoCivil?: string;
	ufDocumentoCivil?: string;
	dataEmissaoDocumentoCivil?: string;
	dataEmissaoCertidao?: string;
	tipoCertidao: string;
	numeroNIS?: string;
	numeroRG?: string;
	digitoRG?: string;
	ufRG?: string;
	justificativaDocumentos?: string;
	dataEmissaoRG?: string;
}

interface ICertidaoAntiga {
	numeroCertidao: string;
	numeroLivroRegistro: string;
	numeroFolhaRegistro: string;
	nomeMunicipioComarca: string;
	ufComarca: string;
	distritoNascimento: string;
	// Envio
	folha: string;
	livro: string;
	municipioComarca: string;
	distritoCertidao: string;
	dataEmissaoCertidao: string;
}

interface ICertidaoNova {
	certificadoMatricula01: string;
	certificadoMatricula02: string;
	certificadoMatricula03: string;
	certificadoMatricula04: string;
	certificadoMatricula05: string;
	certificadoMatricula06: string;
	certificadoMatricula07: string;
	certificadoMatricula08: string;
	certificadoMatricula09: string;
}

export interface IInscricao {
	codigoEscola: number | string;
	codigoEscolaAlocacao: number;
	codigoSerieAno: number;
	codigoTipoEnsino: number;
	data: string;
	descricaoTipo: string;
	municipioEscola: string;
	nomeEscola: string;
	redeEnsino: string;
	situacao: string;
	tipo: number | string;
}

export interface IDataRequestInscricao {
	numeroRA: number;
	digitoRA: number;
	siglaUF: string;
	anoLetivo: number;
}

export const initialValues: IVisualizarFicha = {
	aluno: {
		numeroRA: '',
		digitoRA: '',
		siglaUFRA: '',
	},
	idProcesso: 0,
	mensagem: '',
	dataAlteracao: '',
	dadosPessoais: {
		numeroRA: '',
		digitoRA: '',
		siglaUFRA: '',
		nomeAluno: '',
		dataNascimento: '',
		corRaca: 0,
		descricaoCorRaca: '',
		codigoSexo: 0,
		sexo: '',
		nomeMae: '',
		nomePai: '',
		nomeSocial: '',
		nomeAfetivo: '',
		email: '',
		nacionalidade: 0,
		descricaoNacionalidade: '',
		codigoPaisOrigem: 0,
		nomePaisOrigem: '',
		dataEntradaPais: '',
		bolsaFamilia: '0',
		quilombola: '0',
		nomeMunicipioNascimento: '',
		ufMunicipioNascimento: '',
		alunoFalecido: '',
		dataFaleciomento: '',
		codigoMunicipioNascimentoDNE: 0,
		codigoBolsaFamilia: 0,
		possuiInternet: '',
		possuiNotebookSmartphoneTablet: '',
	},
	documentos: {
		codigoINEP: 0,
		cpf: '',
		numeroDocumentoCivil: '',
		digitoDocumentoCivil: '',
		ufDocumentoCivil: '',
		dataEmissaoDocumentoCivil: '',
		dataEmissaoCertidao: '',
		tipoCertidao: '',
		dataEmissaoRG: '',
	},
	justificativaDocumentos: '',
	certidaoAntiga: {
		numeroCertidao: '',
		numeroLivroRegistro: '',
		numeroFolhaRegistro: '',
		nomeMunicipioComarca: '',
		ufComarca: '',
		distritoNascimento: '',
		folha: '',
		livro: '',
		municipioComarca: '',
		distritoCertidao: '',
		dataEmissaoCertidao: '',
	},
	certidaoNova: {
		certificadoMatricula01: '',
		certificadoMatricula02: '',
		certificadoMatricula03: '',
		certificadoMatricula04: '',
		certificadoMatricula05: '',
		certificadoMatricula06: '',
		certificadoMatricula07: '',
		certificadoMatricula08: '',
		certificadoMatricula09: '',
	},
	inscricoes: {
		codigoEscola: 0,
		codigoEscolaAlocacao: 0,
		codigoSerieAno: 0,
		codigoTipoEnsino: 0,
		data: '',
		descricaoTipo: '',
		municipioEscola: '',
		nomeEscola: '',
		redeEnsino: '',
		situacao: '',
		tipo: 0,
	},
	dadosRequisicaoInscricao: {
		numeroRA: 0,
		digitoRA: 0,
		siglaUF: '',
		anoLetivo: 0,
	},
};
