import { IImpressaoComunicacaoVenda } from 'pages/DetranCrv/Processos/ImpressaoComunicacaoVenda/Consulta/form';
import { Types } from './types';

export function impressaoComunicacaoVendaRequest(
	payload: IImpressaoComunicacaoVenda,
) {
	return {
		type: Types.IMPRESSAO_COMUNICACAO_VENDA_REQUEST,
		payload,
	};
}
export function impressaoComunicacaoVendaSuccess(payload: any) {
	return {
		type: Types.IMPRESSAO_COMUNICACAO_VENDA_SUCCESS,
		payload,
	};
}
export function impressaoComunicacaoVendaFailure(payload: []): any {
	return {
		type: Types.IMPRESSAO_COMUNICACAO_VENDA_FAILURE,
		payload,
	};
}
export function impressaoComunicacaoVendaClear(): any {
	return {
		type: Types.IMPRESSAO_COMUNICACAO_VENDA_CLEAR,
	};
}
