import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../types';
import { IirgdState, Types } from './types';

import alterarObservacoes from './alterarObservacoes/reducer';
import cadastroRg from './cadastroRg/reducer';
import consultaPesquisaCidadao from './consultaPesquisaCidadao/reducer';
import consultaPesquisaCirg from './consultaPesquisaCirg/reducer';
import consultaPesquisaCriminal from './consultaPesquisaCriminal/reducer';
import consultaPesquisaObservacao from './consultaPesquisaObservacao/reducer';
import consultaReimpressaoFic from './consultaReimpressaoFic/reducer';
import enviarSms from './enviarSms/reducer';
import protocoloFic from './protocoloFic/reducer';
import protocoloFicDigital from './protocoloFicDigital/reducer';
import protocoloReuso from './protocoloReuso/reducer';
import contabilizacaoIirgd from './contabilizacaoIirgd/reducer';
import contabilizacaoEmissaoCarteira from './contabilizacaoEmissaoCarteira/reducer';
import autorizacaoEspecial from './autorizacaoEspecial/combineReducers';
import consultaPesquisaCivil from './consultaPesquisaCivil/reducer';
import scriptPreAtendimento from './scriptPreAtendimento/reducer';
import logon from './logon/reducer';
import alteraSenha from './alteraSenha/reducer';
import pesquisaCicp from './pesquisaCicp/reducer';
import consultaCIN from './consultaCIN/reducer';
import consultaDBCIN from './consultaDBCIN/reducer';
import integrador from './integrador/combineReducers';
import liberacaoCili from './liberacaoCili/reducer';

const allReducers = combineReducers({
	alterarObservacoes,
	cadastroRg,
	consultaPesquisaCidadao,
	consultaPesquisaCirg,
	consultaPesquisaCriminal,
	consultaPesquisaObservacao,
	consultaReimpressaoFic,
	enviarSms,
	protocoloFic,
	protocoloFicDigital,
	protocoloReuso,
	autorizacaoEspecial,
	contabilizacaoIirgd,
	contabilizacaoEmissaoCarteira,
	consultaPesquisaCivil,
	scriptPreAtendimento,
	logon,
	alteraSenha,
	pesquisaCicp,
	consultaCIN,
	consultaDBCIN,
	integrador,
	liberacaoCili,
});

const combineReducer = (state: IirgdState, action: ReducerAction) => {
	if (action.type === Types.IIRGD_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
