import React from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

// FORM
import Section from 'components/Common/Section';
import CheckBox from 'components/Common/Form/Checkbox';
import { ICadastroSolicitante } from '../form';

interface Props {
	formik: FormikProps<ICadastroSolicitante>;
	step: number;
	formDisabled: boolean;
}

const Termos: React.FC<Props> = ({ formik, step, formDisabled }) => {
	return (
		<>
			<Section title="Termos" size="sm">
				<Field
					as={CheckBox}
					disabled={step === 2 || formDisabled}
					name="aceiteTermo"
					subtitle="CONCORDO EXPRESSAMENTE com o reconhecimento da paternidade a ser realizado pelo genitor acima referido e com a consequente averbação no registro de nascimento."
					checked={formik.values.aceiteTermo}
					error={!formik.errors.aceiteTermo}
				/>
				<Field
					as={CheckBox}
					disabled={step === 2 || formDisabled}
					name="aceiteTermoDeclaracaoPobreza"
					subtitle="DECLARO que, por ser pobre, nos termos da lei, não possuo condições financeiras para arcar com as custas do processo e com as despesas para proceder à averbação."
					checked={formik.values.aceiteTermoDeclaracaoPobreza}
					error={!!formik.errors.aceiteTermoDeclaracaoPobreza}
				/>
				<Field
					as={CheckBox}
					disabled={step === 2 || formDisabled}
					name="aceiteAcrescNomePai"
					subtitle="Se houver reconhecimento de paternidade, solicito que o sobrenome do pai seja acrescentado ao nome do filho."
					checked={formik.values.aceiteAcrescNomePai}
					error={!!formik.errors.aceiteAcrescNomePai}
				/>
			</Section>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default Termos;
