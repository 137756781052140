import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarPerfilRequest } from './types';

// ACTIONS
import { cadastrarPerfilSuccess, cadastrarPerfilFailure } from './action';

function* cadastrarPerfil(request: ReducerAction) {
	const { payload }: { payload: CadastrarPerfilRequest } = request;

	const body = { ...payload };

	const response: ApiResponse = yield call(postApi, PATH_SGU, `perfis`, body);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarPerfilSuccess(response));
	} else {
		yield put(cadastrarPerfilFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_PERFIL, cadastrarPerfil)]);
