import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	ASSUNTOS_PROBLEMAS__REQUEST = '@procon/ASSUNTOS_PROBLEMAS__REQUEST',
	ASSUNTOS_PROBLEMAS__SUCCESS = '@procon/ASSUNTOS_PROBLEMAS__SUCCESS',
	ASSUNTOS_PROBLEMAS__FAILURE = '@procon/ASSUNTOS_PROBLEMAS__FAILURE',
	ASSUNTOS_PROBLEMAS__CLEAR = '@procon/ASSUNTOS_PROBLEMAS__CLEAR',
}

export interface AssuntosProblemas {
	status: null | number;
	data: OptionProps[];
}

export interface AssuntosProblemasRequest {
	assuntoId: string;
}
