export enum Types {
	CADASTRO_CONSUMIDOR_REQUEST = '@procon/CADASTRO_CONSUMIDOR_REQUEST',
	CADASTRO_CONSUMIDOR_SUCCESS = '@procon/CADASTRO_CONSUMIDOR_SUCCESS',
	CADASTRO_CONSUMIDOR_FAILURE = '@procon/CADASTRO_CONSUMIDOR_FAILURE',
	CADASTRO_CONSUMIDOR_CLEAR = '@procon/CADASTRO_CONSUMIDOR_CLEAR',
}

export interface CadastroConsumidor {
	status: null | number;
	data: null | CadastroConsumidorData;
}

interface CadastroConsumidorData {
	mensagem: string;
	data: {
		id: number;
	};
}

export interface CadastroConsumidorRequest {
	idConsumidor?: number;
	possuiDeficiencia?: boolean;
	outroTipoDeficiencia?: string;
	idTipoDeficiencia?: number;
	nomeCompleto: string;
	cpf: string;
	dataDeNascimento: string;
	orgaoEmissorDoRg: string | null;
	rg: string | null;
	sexo: string | number;
	email: string;
	telefoneCelular: string | null;
	telefoneFixo: string | null;
	ufEmissor: string | number | null;
	bairro: string;
	cep: string;
	logradouro: string;
	estado: number | string;
	municipioId: string;
	numero: string;
	complemento: string;
	pessoaComDeficiencia?: boolean;
}

export interface CadastroConsumidorForm extends CadastroConsumidorRequest {
	confirmaEmail?: string;
	confirmaTelefoneCelular?: string | null;
	confirmaTelefoneFixo?: string | null;
	municipioDescricao?: string;
	tipoDeAcao?: string;
}
