import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_EVENTOS_ESPECIAIS_REQUEST = '@procon/CONSULTA_EVENTOS_ESPECIAIS_REQUEST',
	CONSULTA_EVENTOS_ESPECIAIS_SUCCESS = '@procon/CONSULTA_EVENTOS_ESPECIAIS_SUCCESS',
	CONSULTA_EVENTOS_ESPECIAIS_FAILURE = '@procon/CONSULTA_EVENTOS_ESPECIAIS_FAILURE',
	CONSULTA_EVENTOS_ESPECIAIS_CLEAR = '@procon/CONSULTA_EVENTOS_ESPECIAIS_CLEAR',
}

export interface EventosEspeciais {
	status: number;
	data: OptionProps[];
}

export const ID_PRAZO_PADRAO = '49724238-1b3b-eb11-a813-000d3ac179a6';
export const LABEL_PRAZO_PADRAO = 'Prazo padrão';
