import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, deleteApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { IExcluirFichaCadastral } from 'pages/DetranCrv/Processos/ExcluirFichaCadastral/form';
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';
import {
	excluirFichaCadastralSuccess,
	excluirFichaCadastralFailure,
} from './actions';

function* excluirFichaCadastralRequest(request: ReducerAction) {
	const { payload }: { payload: IExcluirFichaCadastral } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_DETRAN_CRV,
		`processos/${CHANNEL}/fichas-cadastrais/${payload.numeroFicha}/${payload.anoFicha}`,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(excluirFichaCadastralSuccess(response));
	} else if (response.error) {
		yield put(excluirFichaCadastralFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.EXCLUIR_FICHA_CADASTRAL_REQUEST,
		excluirFichaCadastralRequest,
	),
]);
