// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaFornecedorData } from '../consultaFornecedor/types';
import { Types } from './types';

export function fornecedorSelecionadoRequest(
	payload: ConsultaFornecedorData,
): ReducerAction {
	return {
		type: Types.FORNECEDOR_SELECIONADO_REQUEST,
		payload,
	};
}
export function fornecedorSelecionadoClear(): ReducerAction {
	return {
		type: Types.FORNECEDOR_SELECIONADO_CLEAR,
		payload: null,
	};
}
