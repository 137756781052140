import { ReducerAction } from 'store/modules/types';
import { Types, AlteraTransferenciaEnderecoRequest } from './types';

export function alteraTransferenciaEnderecoRequest(
	data: AlteraTransferenciaEnderecoRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_TRANSFERENCIA_ENDERECO,
		payload: data,
	};
}
export function alteraTransferenciaEnderecoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.ALTERA_TRANSFERENCIA_ENDERECO_SUCCESS,
		payload,
	};
}
export function alteraTransferenciaEnderecoFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.ALTERA_TRANSFERENCIA_ENDERECO_FAILURE,
		payload,
	};
}
