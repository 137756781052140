import * as Yup from 'yup';

export interface EmiteLicenciamento {
	chassi: string;
	placa: string;
	municipio: {
		codigo: number | null;
		nome: string;
	};
	renavam: number | string | null;
	autenticacao: any;
}

export const schema = Yup.object<EmiteLicenciamento>().shape({
	placa: Yup.string().required(`Campo Obrigatório: Interessado : Placa.`),
	chassi: Yup.string().required(`Campo Obrigatório: Interessado : Chassi.`),
	renavam: Yup.string().required(`Campo Obrigatório: Interessado : Renavam.`),
	autenticacao: Yup.string().required(
		`Campo Obrigatório: Interessado : Autenticação Digital.`,
	),
});
