import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_CEP_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import { enderecosSuccess, enderecosFailure } from './actions';

function* enderecos(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: any } = request;

	const instance = payload.instanceRequest;

	delete payload.instanceRequest;

	const defaulErrorMessageCorporativeCEP =
		'O CEP informado não foi encontrado. Por gentileza, revise a informação ou verifique se o CEP existe junto a base dos correios.';

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_CEP_API,
		`v2/enderecos`,
		payload,
		defaulErrorMessageCorporativeCEP,
	);

	if (response.status === 200) {
		yield put(
			enderecosSuccess({
				response: response.data,
				instanceRequest: instance,
			}),
		);
	} else {
		yield put(
			enderecosFailure({
				response: response.message,
				instanceRequest: instance,
			}),
		);
	}
}

export default all([takeLatest(Types.ENDERECOS_REQUEST, enderecos)]);
