export enum Types {
	CONSULTAR_DIGITAIS_REQUEST = '@utils/CONSULTAR_DIGITAIS_REQUEST',
	CONSULTAR_DIGITAIS_SUCCESS = '@utils/CONSULTAR_DIGITAIS_SUCCESS',
	CONSULTAR_DIGITAIS_FAILURE = '@utils/CONSULTAR_DIGITAIS_FAILURE',
	CONSULTAR_DIGITAIS_CLEAR = '@utils/CONSULTAR_DIGITAIS_CLEAR',
}
export interface ConsultarDigitaisRequest {
	evento: {
		id_atendimento: string;
		id_cidadao: string;
		cpf: string;
		identificacao: string;
		ip: string;
		canal: {
			id: number;
			desc: string;
			estacao?: {
				id?: number;
				desc?: string;
			};
			localidade: {
				id: number;
				desc: string;
			};
		};
	};
	idAtendimento: string;
	cpf: string;
	rgSp: string;
	dataNascimento: string;
	// finalidade: 'REUSO_IIRGD';
	finalidade: string;
	incluirPendentes: boolean;
}
export interface ConsultarDigitaisState {
	status: number;
	data: ConsultaDigitais | null;
	mensagens: string[] | null;
}

interface ConsultaDigitais {
	dadosBiometricos: DadosBiometricos[];
	valida: boolean;
	origem: string;
	motivo: string;
}

export interface DadosBiometricos {
	posicaoDedo: string;
	indice: string;
	sigla: string;
}

export type ConsultarDigitaisUserErrorMessages = 'pessoa-desconhecida';

export type ConsultarDigitaisUserReasonMessages = 'fora-da-temporalidade';
