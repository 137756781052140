import React, { useCallback, useEffect, useState } from 'react';

import { Field, FormikProps } from 'formik';
import { Row, Col, Upload, Modal, Divider } from 'antd';

// COMPONENTS
import FormBox from 'components/Common/Form/FormBox';
import { InboxOutlined } from '@ant-design/icons';

// FORM
import Section from 'components/Common/Section';
import SimpleTable from 'components/Common/Table';
import Button from 'components/Common/Button';
import Select from 'components/Common/Form/Select';
import hasError from 'utils/getFormErrors';
import Error from 'components/Common/Notifications/FormError/index';
import { ICadastroSolicitante } from '../form';
import { Container, TEXT } from './styled';

interface Props {
	formik: FormikProps<ICadastroSolicitante>;
	step: number;
	formDisabled: boolean;
}

const Documentos: React.FC<Props> = ({ formik, step, formDisabled }) => {
	const { Dragger } = Upload;

	const [tableData, setTableData] = useState<any>(formik.values.anexos);
	const [modalFile, setModalFile] = useState<boolean>(false);
	const [errors, setErros] = useState<any>([]);

	useEffect(() => {
		setTableData(formik.values.anexos);
	}, [formik.values.anexos, tableData]);

	const fileToBase64 = (file: any) => {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = event => {
				if (event?.target?.result !== null && event?.target?.result) {
					resolve(event.target.result);
				}
			};
		});
	};

	const handleChange = useCallback(
		async (info: any) => {
			if (info.file.size > 2000000) {
				setErros(['Tamanho máximo permitido para o documento 2 (em MB).']);
			} else if (info.file.type !== 'application/pdf') {
				setErros(['Tipo de arquivo inválido.']);
			} else if (formik.values.anexos.length >= 10) {
				setErros(['Máximo de 10 arquivos permitidos.']);
			} else {
				const b64File = await fileToBase64(info.file).then(result => {
					return result;
				});
				const b64 = b64File as string;
				const b64String = b64
					.toString()
					.replace('data:application/pdf;base64,', '');

				formik.setFieldValue('anexos', [
					...formik.values.anexos,
					{
						nome: info.file.name,
						tipo: formik.values.anexosTipo,
						conteudo: b64String,
					},
				]);

				// RESETA O SELECT
				formik.setFieldValue('anexosTipo', '');

				// FECHA O MODAL
				setModalFile(false);
			}
		},
		[formik],
	);

	const props = {
		name: 'file',
		multiple: true,
		beforeUpload: (file: any) => {
			return false;
		},
		onChange: (info: any) => handleChange(info),
	};

	const handleRemove = useCallback(
		async index => {
			const allFiles = [...tableData];
			allFiles.splice(index, 1);
			formik.setFieldValue('anexos', allFiles);
		},
		[formik, tableData],
	);

	const headers = [
		{
			key: '1',
			title: 'Nº',
			dataIndex: 'contador',
			render: (text: any, record: any, index: any) => index + 1,
		},
		{
			key: '2',
			title: 'Nome',
			dataIndex: 'nome',
		},
		{
			key: '3',
			title: 'Tipo',
			dataIndex: 'tipo',
		},
		{
			key: '4',
			title: '',
			dataIndex: 'type',
			render: (text: any, record: any, index: any) =>
				step === 2 ? (
					''
				) : (
					<Button
						disabled={step === 2}
						onClick={() => handleRemove(index)}
						style={{ cursor: 'not-allowed' }}
					>
						Excluir
					</Button>
				),
		},
	];

	const enumAnexos = [
		{
			value: 'FORMULARIO',
			label: 'Formulário',
		},
		{
			value: 'DOCUMENTO_PESSOAL',
			label: 'Documento Pessoal',
		},
		{
			value: 'OUTROS',
			label: 'Outros',
		},
	];

	return (
		<Container>
			<FormBox title="Documentos">
				<Row gutter={[0, 10]}>
					<TEXT>
						OBRIGATÓRIO: Anexar cópia da certidão de nascimento do INTERESSADO.
						Na impossibilidade, anexar cópia de outro documento de
						identificação. Se o interessado for menor, anexar cópia do documento
						de identifcação da MÃE ou do RESPONSÁVEL LEGAL. Se possível, anexar
						cópia do documento de identificação do PAI.
					</TEXT>
					<Col span={24}>
						<Section
							title="Anexar - Máx. 10 arquivos com até 2 megabytes cada - Tipo PDF"
							size="sm"
						>
							<Row gutter={[0, 20]} justify="center">
								<Col>
									{step !== 2 && (
										<Button
											disabled={formDisabled}
											className="addButton"
											onClick={() => setModalFile(true)}
										>
											+ {'           '} Incluir anexo
										</Button>
									)}
								</Col>
								<Col span={24}>
									<SimpleTable
										pageSize={10}
										headers={headers}
										body={tableData}
										messageNoResults="Nenhum registro encontrado"
									/>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			</FormBox>

			<Modal
				title="Selecione o Documento"
				visible={modalFile}
				onCancel={() => {
					setModalFile(false);
					setErros([]);
				}}
			>
				{errors.length > 0 && (
					<Error errors={errors} onClose={() => setErros([])} />
				)}
				<Field
					as={Select}
					required
					title="Tipo de Documento"
					name="anexosTipo"
					options={enumAnexos}
					onChange={(v: string) => formik.setFieldValue('anexosTipo', v)}
					error={hasError(formik.errors, 'anexosTipo')}
				/>
				<Divider />

				<Dragger
					{...props}
					disabled={formik.values.anexosTipo === ''}
					accept="application/pdf"
					showUploadList={false}
				>
					<p className="ant-upload-drag-icon">
						<InboxOutlined />
					</p>
					<p className="ant-upload-text">
						{formik.values.anexosTipo === ''
							? 'Selecione o tipo de Documento'
							: 'Clique ou arraste o arquivo para incluir o Anexo.'}
					</p>
				</Dragger>
			</Modal>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</Container>
	);
};

export default Documentos;
