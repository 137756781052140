import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, consultarTaxaServico } from './types';

export const INITIAL_STATE: consultarTaxaServico = {
	status: 0,
	data: null,
	dataError: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultarTaxaServico {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_TAXA_SERVICO: {
				draft.status = 100;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}

			case Types.CONSULTAR_TAXA_SERVICO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}

			case Types.CONSULTAR_TAXA_SERVICO_FAILURE: {
				draft.status = action.payload.data.status;
				draft.dataError = action.payload.data.data;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.CONSULTAR_TAXA_SERVICO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}

			default:
		}
		return draft;
	});
}
