import React, { useCallback } from 'react';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ThemeState } from 'store/modules/app/theme/types';
import {
	increaseFontSize,
	decreaseFontSize,
} from 'store/modules/app/theme/actions';

// STYLED
import { Container, IncreaseFont, DecreaseFont, Text } from './styled';

const FontSize: React.FC = () => {
	const dispatch = useDispatch();

	const { fontSize } = useSelector((state: ThemeState) => state);

	const handleIncreaseFont = useCallback(() => {
		if (fontSize !== 20) {
			dispatch(increaseFontSize());
		}
	}, [fontSize, dispatch]);

	const handleDecreaseFont = useCallback(() => {
		if (fontSize !== -20) {
			dispatch(decreaseFontSize());
		}
	}, [fontSize, dispatch]);

	return (
		<>
			<Container>
				<IncreaseFont onClick={handleIncreaseFont} />

				<DecreaseFont onClick={handleDecreaseFont} />

				<Text>Tamanho do texto</Text>
			</Container>
		</>
	);
};

export default FontSize;
