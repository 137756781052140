import React, { useCallback, useEffect, useState } from 'react';

import { Modal, Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

import data from 'store/modules/api/iirgd/enum';

// ASSETS
import icon from 'assets/images/icons/icon_book.gif';

// COMPONENTS
import Section from 'components/Common/Section';
import Select, { OptionProps } from 'components/Common/Form/Select';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';
import Error from 'components/Common/Notifications/FormError';
import SimpleTable from 'components/Common/Table';

// SERVICES
import { getApi } from 'services/_sgu';
import { CHANNEL } from 'services/_path';

// FORM
import { ApplicationState } from 'store';
import {
	consultaMunicipioClear,
	consultaMunicipioRequest,
} from 'store/modules/api/sgu/admin/consultaMunicipio/actions';

// UTILS
import { toSelect } from 'utils/genericFunctions';
import {
	listaCepClear,
	listaCepRequest,
} from 'store/modules/api/utils/endereco/lista-cep/actions';
import { ListaCepRequest } from 'store/modules/api/utils/endereco/lista-cep/types';
import getValidationsErrors, { initialValues } from './form';

// STYLED
import CreateGlobalStyle from '../reset';
import { Container, ButtonDiv, Button } from '../styled';

export interface IBuscarEnderecoResultado {
	cep: string;
	tipoLogradouro?: string;
	logradouro: string;
	endereco?: string;
	complemento: string;
	bairro: string;
	numeroIBGE: number;
	codigoMunicipio: number | string;
	codigoNaturalidade: number | string;
	municipio: string;
	uf: string;
}

interface Props {
	disabled?: boolean;
	result: (data: IBuscarEnderecoResultado) => void;
}

const BuscarEndereco: React.FC<Props> = ({ disabled = false, result }) => {
	const dispatch = useDispatch();

	const [showModal, setShowModal] = useState<boolean>(false);
	const [notificationErrors, setNotificationErrors] = useState<string[]>([]);

	const [selectedCidade, setSelectedCidade] = useState<string>('');
	const [cidades, setCidades] = useState<OptionProps[]>([]);

	const { listaCep } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { consultaMunicipio } = useSelector(
		(state: ApplicationState) => state.api.sgu.admin,
	);

	const handleShowModal = useCallback(() => setShowModal(true), [setShowModal]);

	const handleHideModal = useCallback(() => {
		setShowModal(false);

		dispatch(listaCepClear());
		dispatch(consultaMunicipioClear());
		setSelectedCidade('');
		setCidades([]);
	}, [dispatch]);

	useEffect(() => {
		dispatch(listaCepClear());
	}, [dispatch]);

	const handleValidate = useCallback(
		async values => {
			setNotificationErrors([]);

			const errors = await getValidationsErrors(values);

			if (typeof errors === 'boolean') {
				return errors;
			}

			const formikErrors: string[] = Object.keys(errors).map(value => {
				return errors[value];
			});

			setNotificationErrors(formikErrors);

			return errors;
		},
		[setNotificationErrors],
	);

	const [selectedUf, setSelectedUf] = useState<string>('');
	const handleUfChange = useCallback(
		(uf: string) => {
			dispatch(consultaMunicipioRequest({ uf }));
			setSelectedUf(uf);
		},
		[dispatch],
	);

	useEffect(() => {
		if (consultaMunicipio?.municipios?.length && selectedUf) {
			const filteredCidades = consultaMunicipio?.municipios.filter(cidade => {
				return cidade.uf === selectedUf;
			});

			setCidades(toSelect(filteredCidades, 'nome', 'codigo'));
		}
	}, [consultaMunicipio, selectedUf]);

	const handleSubmit = useCallback(
		values => {
			setNotificationErrors([]);
			const body: ListaCepRequest = {
				logradouro: values.logradouro,
				cidade: '',
				uf: values.uf,
			};

			values.cidade = cidades.filter((item: OptionProps) => {
				if (item.value === values.cidade) {
					body.cidade = item.label;
					return body.cidade;
				}
				return '';
			});
			if (values.numero) {
				body.numero = values.numero.replace(/[_.-]+/g, '');
			}
			dispatch(listaCepRequest(body));
		},
		[cidades, dispatch],
	);

	const handleSelectedCep = useCallback(
		async (res: IBuscarEnderecoResultado) => {
			const response: any = await getApi(
				`domain-service/${CHANNEL}/naturalidade`,
				{
					idIbge: res.numeroIBGE,
				},
			);

			if (response.status === 200) {
				res.codigoMunicipio = `${response.data[0].id}${response.data[0].digito}`;
			}

			result(res);
			handleHideModal();
		},
		[result, handleHideModal],
	);

	return (
		<Container>
			<Button
				type="button"
				icon={icon}
				disabled={disabled}
				onClick={handleShowModal}
			/>

			<Modal
				title="Buscar Endereço"
				visible={showModal}
				onCancel={handleHideModal}
				width={670}
				footer={null}
				style={{ animationDuration: '0s' }}
				destroyOnClose
			>
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={handleValidate}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							{notificationErrors.length > 0 && (
								<Error errors={notificationErrors} />
							)}

							<Section title="Pesquisa" size="sm">
								<Row gutter={[0, 10]}>
									<Col span={9}>
										<Field
											as={Select}
											title="UF"
											titleSize="sm"
											name="uf"
											options={data.uf}
											onChange={(v: string) => {
												formik.setFieldValue('uf', v);
												handleUfChange(v);
											}}
											error={!!formik.errors.uf}
										/>
									</Col>

									<Col span={15}>
										<Field
											as={Select}
											disabled={!selectedUf || !cidades.length}
											title="Cidade"
											titleSize="sm"
											name="cidade"
											defaultFirstOptionText="Escolha UF"
											options={cidades}
											value={selectedCidade}
											onChange={(v: string) => {
												setSelectedCidade(v);
												formik.setFieldValue('cidade', v);
											}}
											error={!!formik.errors.cidade}
										/>
									</Col>
								</Row>

								<Row>
									<Col span={12}>
										<Field
											as={Input}
											title="Logradouro"
											titleSize="sm"
											name="logradouro"
											error={!!formik.errors.logradouro}
										/>
									</Col>

									<Col span={6}>
										<Field
											as={InputMask}
											title="Número"
											titleSize="sm"
											name="numero"
											mask="99999"
											error={!!formik.errors.numero}
										/>
									</Col>

									<Col span={6}>
										<ButtonDiv>
											<ButtonImage type="submit" src="pesquisar" />
										</ButtonDiv>
									</Col>
								</Row>
							</Section>

							<Row gutter={[0, 10]}>
								<Col span={24} />
							</Row>

							{listaCep?.data?.length && (
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<SimpleTable
											headers={[
												{
													title: 'CEP',
													dataIndex: 'cep',
												},
												{
													title: 'Logradouro',
													dataIndex: 'endereco',
												},
												{
													title: 'Cidade',
													dataIndex: 'localidade',
												},
												{
													title: 'UF',
													dataIndex: 'uf',
												},
											]}
											body={listaCep?.data ?? []}
											onClick={handleSelectedCep}
										/>
									</Col>
								</Row>
							)}
						</Form>
					)}
				</Formik>
			</Modal>

			<CreateGlobalStyle />
		</Container>
	);
};

export default BuscarEndereco;
