import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, ChangeUrl } from './types';

export const INITIAL_STATE: ChangeUrl = {
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ChangeUrl {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CHANGE_URL_REQUEST: {
				draft.data = true;
				break;
			}
			case Types.CHANGE_URL_CLEAR: {
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
		return draft;
	});
}
