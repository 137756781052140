import {
	sameThreeCharactersInSequenceNotAllowedRegex,
	userDataRegex,
} from 'utils/constants/regex';
import { socialNameAndFiliationsPhrasesNotAllowed } from 'utils/constants/types';
import { verifyStringIsAnInvalidPhrase } from 'utils/genericFunctions';
import * as Yup from 'yup';

export interface CnhEstrangeiroModalInfo {
	type: string;
	message: string;
}

export interface IFormStatusCondutor {
	cpf: string;
	dataCadastro: string;
	horaCadastro: string;
	usuarioCadastro: string;
	categoria: string;
	ciretran: string;
	identidade: string;
	identidadeUf: string;
	nomeMae: string;
	nomePai: string;
	identificacao: string;
	nacionalidade: string;
	dataNascimento: string;
	codNaturalidade: string;
	naturalidade: string;
	nome: string;
	numeroRg: string;
	orgaoEmissor: string;
	orgaoEmissorUf: string;
	pais1: string;
	pais2: string;
	numeroPgu: string;
	categoriaPretendida: string;
	dataPrimeiraHabilitacao: string;
	processo: string;
	numeroRegistro: string;
	rne: string;
	rneOrgaoEmissor: string;
	sexo: string;
	dataValidade: string;
	endereco: {
		codigo: string;
		logradouro: string;
		numero: string;
		complemento: string;
		cep: string;
		bairro: string;
		descricaoMunicipio: string;
		codigoMunicipio: string;
	};
}

interface FormCnhEstrangeiroBase {
	usoFormularioEnum: string;
	autorizaEnvioEmail: string;
	autorizaEnvioSms: string;
	bairro: string;
	categoriaAtual: string;
	categoriaPretendida: string;
	celular?: string;
	cep: string;
	codEntidade: string;
	complemento: string;
	cpf: string;
	confirmaEmail: string;
	codigoUnidadeTransito: number;
	dataNascimento: string;
	dataCadastro: string;
	dataValidadeCnhEstrangeira?: string;
	dataPrimeiraHabilitacao?: string;
	email?: string;
	enderecoRecebimentoBairro?: string;
	enderecoRecebimentoCep?: string;
	enderecoRecebimentoLogradouro?: string;
	enderecoRecebimentoMunicipio?: string;
	enderecoRecebimentoCodigoMunicipio?: string;
	enderecoRecebimentoNumero?: string;
	flagNomeSocial?: string;
	flagFiliacaoAfetiva?: string;
	flagExameNoPosto: string;
	flagAtividadeRemunerada: string | boolean;
	flagSolicitaCNHDigital: string | boolean;
	flagDeficienteFisico: string;
	flagExameToxicologico?: string;
	// LOGRADOURO
	logradouro: string;
	logradouroCodMunicipio: string;
	municipio: string;
	militar: string;
	nacionalidade: string;
	naturalidade: string;
	nome: string;
	nomeSocial?: string;
	nomeMae: string;
	nomePai: string;
	numero_carteira_militar: string;
	numRg?: string;
	numero: string;
	numeroPgu: string;
	numeroCnhEstrangeira?: string;
	orgaoExpDocumento?: string;
	orgaoUfExpDocumento?: string;
	processo: string;
	paisAfetivos1?: string;
	paisAfetivos2?: string;
	paisCnhEstrangeiro?: string;
	rneNumero?: string;
	rneUf?: string;
	rneOrgao?: string;
	status: string;
	sexo: string;
	telefone?: string;
	tipo_documento: string;
	uf?: string;
	codigoVerificador?: string;
}

export interface FormCnhEstrangeiro extends FormCnhEstrangeiroBase {
	tipoProcesso: string;
	tipoRenach?: string;
	numeroRenach?: string;
	numeroCiretran?: string;
	numeroRegistro?: string;
}

export const radioTipoCnhEstrangeiro = [
	{
		label: 'CNH Via Impressa',
		value: 'N',
	},
	{
		label: 'CNH Via Digital (Impressa + Digital)',
		value: 'S',
	},
];

export const initialValues: FormCnhEstrangeiro = {
	cpf: '',
	bairro: '',
	categoriaPretendida: '',
	cep: '',
	numero: '',
	complemento: '',
	logradouro: '',
	municipio: '',
	logradouroCodMunicipio: '',
	codEntidade: '',
	dataNascimento: '',
	email: '',
	nacionalidade: '',
	naturalidade: '',
	categoriaAtual: '',
	nome: '',
	nomeMae: '',
	nomePai: '',
	numero_carteira_militar: '',
	numRg: '',
	orgaoExpDocumento: '',
	sexo: '',
	tipo_documento: '',
	orgaoUfExpDocumento: '',
	rneUf: '',
	rneOrgao: '',
	rneNumero: '',
	status: '',
	processo: '',
	celular: '',
	telefone: '',
	confirmaEmail: '',
	militar: 'N',
	flagExameNoPosto: 'N',
	flagAtividadeRemunerada: '',
	flagSolicitaCNHDigital: '',
	flagDeficienteFisico: '',
	flagExameToxicologico: 'N',
	paisAfetivos1: '',
	paisAfetivos2: '',
	paisCnhEstrangeiro: '',
	dataValidadeCnhEstrangeira: '',
	numeroCnhEstrangeira: '',
	dataPrimeiraHabilitacao: '',
	numeroPgu: '',
	uf: '',
	codigoVerificador: '',
	autorizaEnvioEmail: 'N',
	autorizaEnvioSms: 'N',
	codigoUnidadeTransito: 0,
	dataCadastro: '',
	tipoProcesso: '',
	usoFormularioEnum: '',
};

export const schemaDefault = Yup.object<FormCnhEstrangeiro>().shape(
	{
		categoriaPretendida: Yup.string().required(
			'Campo Obrigatório: Categoria Pretendida.',
		),
		flagAtividadeRemunerada: Yup.string().required(
			'Campo Obrigatório: Pretende exercer atividade remunerada.',
		),
		flagDeficienteFisico: Yup.string().required(
			'Campo Obrigatório: Tem necessidade de veículo adaptado.',
		),
		flagExameToxicologico: Yup.string().when(['categoriaPretendida'], {
			is: categoriaPretendida =>
				categoriaPretendida !== '' &&
				categoriaPretendida !== 'A' &&
				categoriaPretendida !== 'B' &&
				categoriaPretendida !== 'AB' &&
				categoriaPretendida !== 'X' &&
				categoriaPretendida !== 'XB',
			then: Yup.string().required(
				'Campo Obrigatório: Realizou exames Toxicológicos?.',
			),
		}),
		nome: Yup.string().required('Campo Obrigatório: Nome.'),
		nomeSocial: Yup.string()
			.when(['flagNomeSocial'], {
				is: flagNomeSocial => flagNomeSocial === 'S',
				then: Yup.string().required('Campo Obrigatório: Nome Social.'),
			})
			.test(
				'same-name',
				'O nome social não pode ser igual ao nome civil.',
				function verify(value) {
					return value !== '' && this.parent.flagNomeSocial === 'S'
						? this.parent.nome !== value
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-social-name',
				"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		flagNomeSocial: Yup.string().required(
			'Campo Obrigatório: Deseja incluir nome social na CNH?.',
		),
		nacionalidade: Yup.string().required('Campo Obrigatório: Nacionalidade.'),
		uf: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '1',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),
		naturalidade: Yup.string().required('Campo Obrigatório: Naturalidade.'),
		numRg: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: RG.'),
		}),
		orgaoExpDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
		}),
		orgaoUfExpDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),
		rneNumero: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: RNE.'),
		}),
		rneOrgao: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
		}),
		paisCnhEstrangeiro: Yup.string().required(
			'Campo Obrigatório: País CNH Estrangeira.',
		),
		dataValidadeCnhEstrangeira: Yup.string()
			.required('Campo Obrigatório: Validade CNH Estrangeira.')
			.matches(userDataRegex.dateWithoutTime, {
				message: 'Validade CNH Estrangeira: Não é uma data válida.',
				excludeEmptyString: true,
			}),
		numeroCnhEstrangeira: Yup.string().required(
			'Campo Obrigatório: Nº CNH Estrangeira.',
		),
		dataPrimeiraHabilitacao: Yup.string()
			.required('Campo Obrigatório: Data Primeira Habilitação.')
			.matches(userDataRegex.dateWithoutTime, {
				message: 'Data Primeira Habilitação: Não é uma data válida.',
				excludeEmptyString: true,
			}),
		nomeMae: Yup.string().required('Campo Obrigatório: Nome da Mãe.'),
		paisAfetivos1: Yup.string()
			.when(['flagFiliacaoAfetiva'], {
				is: flagFiliacaoAfetiva => flagFiliacaoAfetiva === 'S',
				then: Yup.string().required('Campo Obrigatório: Filiação Afetiva 1.'),
			})
			.test(
				'same-name-filiacoes',
				'O nome da filiação afetiva 1 não pode ser igual ao nome da filiação afetiva 2.',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? this.parent.paisAfetivos2 !== value
						: true;
				},
			)
			.test(
				'same-name-filiacao1',
				'A filiação afetiva 1 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-1',
				"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		paisAfetivos2: Yup.string()
			.test(
				'same-name-filiacao2',
				'A filiação afetiva 2 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-2',
				"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		flagFiliacaoAfetiva: Yup.string().required(
			'Campo Obrigatório: Deseja incluir filiação afetiva na CNH?.',
		),
		sexo: Yup.string().required('Campo Obrigatório: Sexo.'),
		dataNascimento: Yup.string()
			.required('Campo Obrigatório: Data de Nascimento.')
			.matches(userDataRegex.dateWithoutTime, {
				message: 'Data de Nascimento: Não é uma data válida.',
				excludeEmptyString: true,
			}),
		cep: Yup.string().required('Campo Obrigatório: CEP.'),
		logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
		numero: Yup.string().required('Campo Obrigatório: Número.'),
		bairro: Yup.string().required('Campo Obrigatório: Bairro.'),
		municipio: Yup.string().required('Campo Obrigatório: Município.'),
		telefone: Yup.string()
			.when(['celular'], {
				is: celular => celular === '',
				then: Yup.string().required('Campo Obrigatório: Telefone.'),
			})
			.matches(userDataRegex.phoneNumber, {
				message: 'Campo Inválido: Telefone.',
				excludeEmptyString: true,
			}),
		email: Yup.string()
			.when(['autorizaEnvioEmail'], {
				is: autorizaEnvioEmail => autorizaEnvioEmail === 'S',
				then: Yup.string().required('Campo Obrigatório: Email.'),
			})
			.matches(userDataRegex.emailAddress, {
				message: 'Formato de e-mail inválido.',
				excludeEmptyString: true,
			})
			.test(
				'same-email',
				'A confirmação do e-mail é diferente do e-mail informado.',
				function verify(value) {
					return value !== '' ? this.parent.confirmaEmail === value : true;
				},
			),
		celular: Yup.string()
			.when(['telefone'], {
				is: telefone => telefone === '',
				then: Yup.string().required('Campo Obrigatório: Celular.'),
			})
			.matches(userDataRegex.cellPhoneNumber, {
				message: 'Campo Inválido: Celular.',
				excludeEmptyString: true,
			}),
		flagSolicitaCNHDigital: Yup.string().required(
			'Campo Obrigatório: CNH Via Impressa ou CNH Via Digital (Impressa + Digital).',
		),
		enderecoRecebimentoCep: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - CEP.',
		),
		enderecoRecebimentoNumero: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Número.',
		),
		enderecoRecebimentoBairro: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Bairro.',
		),
		enderecoRecebimentoLogradouro: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Logradouro.',
		),
		enderecoRecebimentoMunicipio: Yup.string().required(
			'Campo Obrigatório: Endereço de Recebimento - Município.',
		),
	},
	[['telefone', 'celular']],
);

export const schemaGenerateToken = Yup.object<FormCnhEstrangeiro>().shape({
	celular: Yup.string()
		.required('Campo Obrigatório: Celular.')
		.matches(userDataRegex.cellPhoneNumber, {
			message: 'Campo Inválido: Celular.',
			excludeEmptyString: true,
		}),
	email: Yup.string()
		.when(['autorizaEnvioEmail'], {
			is: autorizaEnvioEmail => autorizaEnvioEmail === 'S',
			then: Yup.string().required('Campo Obrigatório: Email.'),
		})
		.matches(userDataRegex.emailAddress, {
			message: 'Formato de e-mail inválido.',
			excludeEmptyString: true,
		})
		.test(
			'same-email',
			'A confirmação do e-mail é diferente do e-mail informado.',
			function verify(value) {
				return value !== '' ? this.parent.confirmaEmail === value : true;
			},
		),
});
