import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Divider, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, FormikProps } from 'formik';

// REDUX
import { regioesRequest } from 'store/modules/api/detranCnh/divisaoEquitativa/regioes/actions';
import {
	microRegioesClear,
	microRegioesRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/microRegioes/actions';
import {
	horariosPorDataClear,
	horariosPorDataRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/horariosPorData/actions';
import {
	datasExameClear,
	datasExameRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/datasExame/actions';
import { agendarExamesRenachIntegradoClear } from 'store/modules/api/detranCnh/divisaoEquitativa/agendarExamesRenachIntegrado/actions';
import {
	regiaoMicroRegiaoPorCepClear,
	regiaoMicroRegiaoPorCepRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/regiaoMicroRegiaoPorCep/actions';
import { gerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/actions';
import { DadosExameMedico } from 'store/modules/api/detranCnh/sockets/saveGerarRenach/types';
import { saveGerarRenachRequest } from 'store/modules/api/detranCnh/sockets/saveGerarRenach/actions';
import {
	estatisticaIncluirClear,
	estatisticaIncluirRequest,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { cadastroEnderecosClear } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/actions';
import { capturarBiometriaClear } from 'store/modules/api/biometria/capturarBiometria/actions';
import { consultarDigitaisClear } from 'store/modules/api/utils/consultarDigitais/actions';
import { confrontarDigitaisCnhClear } from 'store/modules/api/utils/confrontarDigitaisCnh/actions';
import { consultarInaptoClear } from 'store/modules/api/detranCnh/sockets/consultarInapto/actions';
import { dadosCidadaoClear } from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/actions';
import { verificarVersaoServicoBiometriaClear } from 'store/modules/api/biometria/verificarVersaoServicoBiometria/actions';
import { enumFormUseUrlStatusCondutor } from 'utils/constants/types';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import Select from 'components/Common/Form/Select';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import Alert from 'components/Common/Notifications/Alert';
import Section from 'components/Common/Section';
import ColorBox from 'components/Common/ColorBox';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { treatRequestSguStatisticsGerarRenachIntegrado } from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';
import { formatDate } from 'utils/genericFunctions';
import { DIVISAO_EQUITATIVA_PARAMS } from 'pages/DetranCnh/utils/constants/services';

// FORM
import DadosCidadao from '../DadosCidadao';
import { IDadosCidadao } from '../DadosCidadao/form';
import {
	ICadastraExameMedico,
	treatValuesGerarRenach,
	schema,
	treatValuesGerarRenachWithPsicologico,
} from './form';

interface IProps {
	dadosAgendamento: ICadastraExameMedico;
	dadosCidadao: IDadosCidadao;
	redirecionamento?: string;
	atendimentoPsicologico?: boolean;
}

const AgendamentoMedicoRenachIntegrado: React.FC<IProps> = ({
	dadosAgendamento,
	dadosCidadao,
	redirecionamento,
	atendimentoPsicologico = true,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		regiaoMicroRegiaoPorCep,
		regioes,
		microRegioes,
		datasExame,
		horariosPorData,
	} = useSelector(
		(state: ApplicationState) => state.api.detranCnh.divisaoEquitativa,
	);

	const { gerarRenachIntegrado } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { saveGerarRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { consultaToxicologico } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.cidadao,
	);

	const { estatisticasIncluir } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const { consultarInapto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const [dadosAgendamentoMedico, setDadosAgendamentoMedico] =
		useState<DadosExameMedico | null>(null);

	const [dadosAgendamentoForm, setDadosAgendamentoForm] =
		useState(dadosAgendamento);

	const handleSubmit = useCallback(
		(values: ICadastraExameMedico) => {
			if (datasExame.data?.idMedico) {
				if (saveGerarRenach.data) {
					const {
						codigo_ciretran_exame_medico,
						data_exame,
						horario_exame,
						id_micro_regiao_medico,
					} = values;

					const sendValues: DadosExameMedico = {
						codigoCiretranExameMedico: codigo_ciretran_exame_medico
							? codigo_ciretran_exame_medico.toString()
							: '',
						dataExame: data_exame,
						horarioExame: horario_exame,
						idMedico: datasExame.data.idMedico
							? datasExame.data.idMedico.toString()
							: '',
						idMicroRegiaoMedico: id_micro_regiao_medico
							? id_micro_regiao_medico.toString()
							: '',
					};

					if (
						saveGerarRenach.data.candidato.tipoProcesso === '5' ||
						saveGerarRenach.data.candidato.tipoProcesso === '7' ||
						saveGerarRenach.data.candidato.tipoProcesso === '1' ||
						saveGerarRenach.data.candidato.tipoProcesso === '9'
					) {
						if (redirecionamento) {
							const renachPsicologico = treatValuesGerarRenachWithPsicologico(
								saveGerarRenach.data,
								sendValues,
							);
							dispatch(saveGerarRenachRequest(renachPsicologico));
							history.push(redirecionamento);
						}
					} else {
						if (
							saveGerarRenach.data.candidato.pretendeAtividadeRemunerada ===
								'S' &&
							redirecionamento
						) {
							const renachMedicoPsicologico =
								treatValuesGerarRenachWithPsicologico(
									saveGerarRenach.data,
									sendValues,
								);
							dispatch(saveGerarRenachRequest(renachMedicoPsicologico));
							history.push(redirecionamento);
						}
						if (
							saveGerarRenach.data.candidato.pretendeAtividadeRemunerada ===
								'N' &&
							redirecionamento
						) {
							setDadosAgendamentoMedico(sendValues);
						}
					}
				}
			}
		},
		[
			datasExame.data,
			dispatch,
			history,
			redirecionamento,
			saveGerarRenach.data,
		],
	);

	// REGISTRA ESTATISTICA SGU ANTES DE GERAR O RENACH - CAMPOS SELECIONADOS
	useEffect(() => {
		if (
			dadosAgendamentoMedico &&
			saveGerarRenach.data &&
			estatisticasIncluir.status === 0 &&
			gerarRenachIntegrado.status === 0
		) {
			const renachMedicoPsicologico = treatValuesGerarRenachWithPsicologico(
				saveGerarRenach.data,
				dadosAgendamentoMedico,
			);
			const payload = treatRequestSguStatisticsGerarRenachIntegrado(
				user,
				dadosAtendimento,
				renachMedicoPsicologico,
				'SELECTED_FIELDS',
			);
			dispatch(estatisticaIncluirRequest(payload));
		}
	}, [
		dadosAgendamentoMedico,
		dadosAtendimento,
		dispatch,
		estatisticasIncluir.status,
		gerarRenachIntegrado.status,
		saveGerarRenach.data,
		user,
	]);

	// RENACH
	useEffect(() => {
		if (
			estatisticasIncluir.status !== 0 &&
			estatisticasIncluir.status !== 100 &&
			saveGerarRenach.data !== null &&
			dadosAgendamentoMedico &&
			gerarRenachIntegrado.status === 0
		) {
			const renach = treatValuesGerarRenach(
				saveGerarRenach.data,
				dadosAgendamentoMedico,
				user.cpf,
			);
			dispatch(gerarRenachIntegradoRequest(renach));
			dispatch(estatisticaIncluirClear());
		}
	}, [
		dadosAgendamentoMedico,
		dispatch,
		estatisticasIncluir.status,
		gerarRenachIntegrado.status,
		saveGerarRenach.data,
		user.cpf,
	]);

	// REGISTRA ESTATISTICA SGU APÓS GERAR O RENACH - NUMERO RENACH OU MSG ERRO.
	useEffect(() => {
		if (
			dadosAgendamentoMedico &&
			saveGerarRenach.data &&
			estatisticasIncluir.status === 0 &&
			gerarRenachIntegrado.status !== 0 &&
			gerarRenachIntegrado.status !== 100 &&
			(gerarRenachIntegrado.data || gerarRenachIntegrado.dataError)
		) {
			const renachMedicoPsicologico = treatValuesGerarRenachWithPsicologico(
				saveGerarRenach.data,
				dadosAgendamentoMedico,
			);
			const payload = treatRequestSguStatisticsGerarRenachIntegrado(
				user,
				dadosAtendimento,
				renachMedicoPsicologico,
				'GENERATED_RENACH',
				gerarRenachIntegrado,
			);
			dispatch(estatisticaIncluirRequest(payload));
		}
	}, [
		dadosAgendamentoMedico,
		dadosAtendimento,
		dispatch,
		estatisticasIncluir.status,
		gerarRenachIntegrado,
		saveGerarRenach.data,
		user,
	]);

	const handleModalSubmit = useCallback(
		(values: ICadastraExameMedico) => {
			if (
				consultaToxicologico.data &&
				consultaToxicologico.data.dataLimiteExameMedico
			) {
				const limitDate = new Date(
					consultaToxicologico.data.dataLimiteExameMedico,
				);
				const exameDate = new Date(values.data_exame);
				const diffTime = limitDate.getTime() - exameDate.getTime();
				const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

				if (diffDays < 0) {
					Modal.confirm({
						okText: 'Prosseguir com a data',
						cancelText: 'Nova data',
						title: 'Confirmar Agendamento?',
						content: `Data de agendamento do exame médico fora da validade para utilização do exame toxicológico. Verifique outra data para que seja possível realizar o exame médico até ${formatDate(
							consultaToxicologico.data.dataLimiteExameMedico,
						)} ou verifique com a clínica a antecipação do exame médico.`,
						onOk() {
							handleSubmit(values);
						},
						onCancel() {},
					});
				} else {
					handleSubmit(values);
				}
			} else {
				handleSubmit(values);
			}
		},
		[consultaToxicologico.data, handleSubmit],
	);

	useEffect(() => {
		if (gerarRenachIntegrado.status === 200 && redirecionamento) {
			dispatch(estatisticaIncluirClear());
			history.push(redirecionamento);
		} else if (
			gerarRenachIntegrado.status !== 0 &&
			gerarRenachIntegrado.status !== 100 &&
			gerarRenachIntegrado.status !== 200 &&
			gerarRenachIntegrado.requestData?.candidato.tipoProcesso
		) {
			const urlRedirect =
				enumFormUseUrlStatusCondutor.filter(
					item =>
						Number(item.idTypeProcess) ===
						Number(gerarRenachIntegrado.requestData?.candidato.tipoProcesso),
				)[0].url || '';

			if (urlRedirect && urlRedirect !== '') {
				dispatch(cadastroEnderecosClear());
				dispatch(capturarBiometriaClear());
				dispatch(consultarDigitaisClear());
				dispatch(confrontarDigitaisCnhClear());
				dispatch(estatisticaIncluirClear());
				dispatch(consultarInaptoClear());
				dispatch(dadosCidadaoClear());
				dispatch(verificarVersaoServicoBiometriaClear());
				history.push(urlRedirect);
			}
		}
	}, [dispatch, gerarRenachIntegrado, history, redirecionamento]);

	const clearFieldsFormikAndData = useCallback(
		(
			type: 'regiao' | 'microRegiao' | 'data' | 'codigo_ciretran' | 'all',
			formik?: FormikProps<ICadastraExameMedico>,
		) => {
			switch (type) {
				case 'regiao':
					formik?.setFieldValue(`id_micro_regiao_medico`, '');
					formik?.setFieldValue(`data_exame`, '');
					formik?.setFieldValue(`horario_exame`, '');
					dispatch(microRegioesClear());
					dispatch(datasExameClear());
					dispatch(horariosPorDataClear());
					break;

				case 'microRegiao':
					formik?.setFieldValue(`data_exame`, '');
					formik?.setFieldValue(`horario_exame`, '');
					dispatch(datasExameClear());
					dispatch(horariosPorDataClear());
					break;

				case 'codigo_ciretran':
					formik?.setFieldValue(`data_exame`, '');
					formik?.setFieldValue(`horario_exame`, '');
					dispatch(datasExameClear());
					dispatch(horariosPorDataClear());
					break;

				case 'data':
					formik?.setFieldValue(`horario_exame`, '');
					dispatch(horariosPorDataClear());
					break;

				case 'all':
					formik?.setFieldValue(`regiao`, '');
					formik?.setFieldValue(`id_micro_regiao_medico`, '');
					formik?.setFieldValue(`data_exame`, '');
					formik?.setFieldValue(`horario_exame`, '');
					dispatch(regiaoMicroRegiaoPorCepClear());
					dispatch(microRegioesClear());
					dispatch(datasExameClear());
					dispatch(horariosPorDataClear());
					break;

				default:
			}
		},
		[dispatch],
	);

	const getRegiaoMicroRegiaoCep = useCallback(
		(cep: string) => {
			dispatch(
				regiaoMicroRegiaoPorCepRequest({
					cpf_cidadao: Number(dadosCidadao.cpf),
					tipo_exame: 'MEDICO',
					is_portador_necessidades_especiais:
						dadosCidadao.isPortadorNecessidadesEspeciais,
					cep_cidadao: Number(cep),
					usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
				}),
			);
		},
		[dadosCidadao.cpf, dadosCidadao.isPortadorNecessidadesEspeciais, dispatch],
	);

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado, formik: FormikProps<ICadastraExameMedico>) => {
			formik.setFieldValue(`cep`, res.cep);
			formik.setFieldValue(`logradouro`, res.endereco.substring(0, 100));
			formik.setFieldValue(`bairro`, res.bairro.substring(0, 60));
			formik.setFieldValue(`municipio`, res.municipio);
			if (res.cep.length === 8) {
				clearFieldsFormikAndData('all', formik);
				getRegiaoMicroRegiaoCep(res.cep);
			} else {
				clearFieldsFormikAndData('all', formik);
			}
		},
		[clearFieldsFormikAndData, getRegiaoMicroRegiaoCep],
	);

	const loadCleanChanges = useCallback(
		(
			item: 'regiao' | 'microRegiao' | 'data' | 'hora' | 'codigo_ciretran',
			selectedValue: number,
			formik?: FormikProps<ICadastraExameMedico>,
		) => {
			switch (item) {
				case 'regiao':
					clearFieldsFormikAndData(item, formik);
					if (selectedValue) {
						dispatch(
							microRegioesRequest({
								cpf_cidadao: Number(dadosCidadao.cpf),
								tipo_exame: 'MEDICO',
								is_portador_necessidades_especiais:
									dadosCidadao.isPortadorNecessidadesEspeciais,
								id_regiao_medico: selectedValue,
								id_regiao_psicologo: selectedValue,
								usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
							}),
						);
					}
					break;
				case 'microRegiao':
					clearFieldsFormikAndData(item, formik);
					if (selectedValue && saveGerarRenach.data) {
						dispatch(
							datasExameRequest({
								codigo_ciretran: 18,
								is_portador_necessidades_especiais:
									dadosCidadao.isPortadorNecessidadesEspeciais,
								cep_cidadao: Number(dadosCidadao.cep),
								cpf_cidadao: Number(dadosCidadao.cpf),
								usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
								id_micro_regiao: selectedValue,
							}),
						);
					}
					break;
				case 'codigo_ciretran':
					clearFieldsFormikAndData(item, formik);
					if (selectedValue) {
						if (selectedValue === 18) {
							dispatch(
								regioesRequest({
									evento: {
										id_atendimento: dadosAtendimento?.data?.idAtendimento || '',
										id_cidadao: dadosAtendimento?.data?.idCidadao || '',
										cpf: user.cpf,
										identificacao: dadosCidadao.cpf,
										ip: user.ip,
										canal: {
											id: 1,
											desc: 'Presencial',
											localidade: {
												id: Number(user.posto),
												desc: user.nomePosto,
											},
										},
									},
									cpf_cidadao: Number(dadosCidadao.cpf),
									tipo_exame: 'MEDICO',
									is_portador_necessidades_especiais:
										dadosCidadao.isPortadorNecessidadesEspeciais,
									usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
								}),
							);
						} else {
							dispatch(
								datasExameRequest({
									codigo_ciretran: selectedValue,
									is_portador_necessidades_especiais:
										dadosCidadao.isPortadorNecessidadesEspeciais,
									cep_cidadao: Number(dadosCidadao.cep),
									cpf_cidadao: Number(dadosCidadao.cpf),
									usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
								}),
							);
						}
					}

					break;
				case 'data':
					clearFieldsFormikAndData(item, formik);
					if (selectedValue) {
						dispatch(
							horariosPorDataRequest({
								data_exame: selectedValue.toString(),
								id_medico: Number(datasExame.data?.idMedico),
							}),
						);
					}
					break;
				case 'hora':
					break;
				default:
					break;
			}
		},
		[
			clearFieldsFormikAndData,
			saveGerarRenach.data,
			dispatch,
			dadosCidadao.cpf,
			dadosCidadao.isPortadorNecessidadesEspeciais,
			dadosCidadao.cep,
			dadosAtendimento,
			user.cpf,
			user.ip,
			user.posto,
			user.nomePosto,
			datasExame.data,
		],
	);

	useEffect(() => {
		if (
			saveGerarRenach.data?.gerarRenachBase?.codCiretranHabilitacao === '18' ||
			saveGerarRenach.data?.gerarRenachBase?.codCiretranHabilitacao === '018'
		) {
			dispatch(
				regioesRequest({
					evento: {
						id_atendimento: dadosAtendimento?.data?.idAtendimento || '',
						id_cidadao: dadosAtendimento?.data?.idCidadao || '',
						cpf: user.cpf,
						identificacao: dadosCidadao.cpf,
						ip: user.ip,
						canal: {
							id: 1,
							desc: 'Presencial',
							localidade: {
								id: Number(user.posto),
								desc: user.nomePosto,
							},
						},
					},
					cpf_cidadao: Number(dadosCidadao.cpf),
					tipo_exame: 'MEDICO',
					is_portador_necessidades_especiais:
						dadosCidadao.isPortadorNecessidadesEspeciais,
					usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
				}),
			);
		} else if (saveGerarRenach.data?.gerarRenachBase !== null) {
			dispatch(
				datasExameRequest({
					codigo_ciretran: Number(
						saveGerarRenach.data?.gerarRenachBase?.codCiretranHabilitacao,
					),
					is_portador_necessidades_especiais:
						dadosCidadao.isPortadorNecessidadesEspeciais,
					cep_cidadao: Number(dadosCidadao.cep),
					cpf_cidadao: Number(dadosCidadao.cpf),
					usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
				}),
			);
		}
	}, [dadosCidadao, user, dispatch, saveGerarRenach.data, dadosAtendimento]);

	useEffect(() => {
		if (
			regiaoMicroRegiaoPorCep.status === 200 ||
			regiaoMicroRegiaoPorCep.status === 204
		) {
			if (
				regiaoMicroRegiaoPorCep.data.regiao?.length &&
				regiaoMicroRegiaoPorCep.data.micro_regiao?.length
			) {
				loadCleanChanges(
					'regiao',
					Number(regiaoMicroRegiaoPorCep.data.regiao[0].value),
				);
				loadCleanChanges(
					'microRegiao',
					Number(regiaoMicroRegiaoPorCep.data.micro_regiao[0].value),
				);

				setTimeout(() => {
					setDadosAgendamentoForm({
						...dadosAgendamento,
						id_micro_regiao_medico:
							regiaoMicroRegiaoPorCep.data.micro_regiao[0].value,
						regiao: regiaoMicroRegiaoPorCep.data.regiao[0].value,
					});
				}, 300);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [regiaoMicroRegiaoPorCep]);

	useEffect(() => {
		return () => {
			clearFieldsFormikAndData('all');
			if (atendimentoPsicologico) {
				dispatch(agendarExamesRenachIntegradoClear());
			}
		};
	}, [atendimentoPsicologico, clearFieldsFormikAndData, dispatch]);

	return (
		<Row>
			<Col span={24}>
				<Row>
					<Col span={24}>
						{consultarInapto.status === 200 &&
							consultarInapto.data &&
							(consultarInapto.data.crpPsicologo ||
								consultarInapto.data.crmMedico) && (
								<Alert
									key="warning-inapto"
									type="warning"
									message="Inapto Anterior"
									onClose={() => {}}
								/>
							)}
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Section title="Status do Condutor">
							<DadosCidadao
								dadosCidadao={dadosCidadao}
								limiteExame={
									consultaToxicologico.data &&
									consultaToxicologico.data.dataLimiteExameMedico
										? formatDate(
												consultaToxicologico.data.dataLimiteExameMedico,
										  )
										: undefined
								}
							/>

							<Row gutter={[0, 40]}>
								<Col span={24} />
							</Row>
							<Row>
								<Col span={24}>
									<h1 style={{ opacity: 0.7 }}>Agendamento Médico</h1>
								</Col>
							</Row>
							<Divider
								style={{ marginTop: 0, backgroundColor: '#000', opacity: 0.6 }}
							/>
							<Row>
								<Col span={24}>
									{!datasExame.enumUnidades ||
										(datasExame.enumUnidades &&
											datasExame.enumUnidades.unidades_disponiveis.length <=
												0) ||
										datasExame.data === null ||
										(datasExame.data !== null &&
											datasExame.data.enum.length <= 0 && (
												<div style={{ marginTop: '20px' }}>
													<ColorBox bgColor="vermelho">
														<Row>
															<Col>
																<strong>
																	NÃO CONSTA GRADE DISPONÌVEL PARA AGENDAMENTO!
																</strong>
															</Col>
														</Row>
													</ColorBox>
												</div>
											))}

									<Formik
										validateOnChange={false}
										validateOnBlur={false}
										initialValues={dadosAgendamentoForm}
										validate={values => getValidationsErrors(values, schema)}
										onSubmit={handleModalSubmit}
										enableReinitialize
									>
										{formik => (
											<Form autoComplete="off">
												{saveGerarRenach.data?.gerarRenachBase &&
													saveGerarRenach.data?.gerarRenachBase
														.codCiretranHabilitacao !== '18' &&
													saveGerarRenach.data?.gerarRenachBase
														.codCiretranHabilitacao !== '018' &&
													datasExame.enumUnidades.unidades_disponiveis.length >
														0 && (
														<Row gutter={[0, 10]}>
															<Col span={8}>
																<Field
																	as={Select}
																	title="Unidades Disponíveis"
																	name="codigo_ciretran"
																	options={
																		datasExame.enumUnidades.unidades_disponiveis
																	}
																	onChange={(v: number) => {
																		formik.setFieldValue('codigo_ciretran', v);
																		loadCleanChanges('codigo_ciretran', v);
																	}}
																	titleSize="md"
																	size={80}
																	error={!!formik.errors.codigo_ciretran}
																	titleAlign="start"
																/>
															</Col>
														</Row>
													)}
												{((saveGerarRenach.data?.gerarRenachBase &&
													saveGerarRenach.data?.gerarRenachBase
														?.codCiretranHabilitacao === '18') ||
													saveGerarRenach.data?.gerarRenachBase
														?.codCiretranHabilitacao === '018' ||
													formik.values.codigo_ciretran === 18) && (
													<Row gutter={[0, 10]}>
														<Col span={8}>
															<Field
																as={Select}
																title="Região"
																name="regiao"
																options={
																	regiaoMicroRegiaoPorCep.data.regiao?.length
																		? regiaoMicroRegiaoPorCep.data.regiao
																		: regioes.data
																}
																defaultFirstOptionText={
																	regiaoMicroRegiaoPorCep.data.regiao?.length
																		? regiaoMicroRegiaoPorCep.data.regiao[0]
																				.label
																		: '- SELECIONE -'
																}
																onChange={(v: number) => {
																	formik.setFieldValue('regiao', v);
																	loadCleanChanges('regiao', v, formik);
																}}
																titleSize="md"
																size={80}
																error={!!formik.errors.regiao}
															/>
														</Col>
														<Col span={8}>
															<Field
																as={Select}
																title="Macrorregião"
																name="id_micro_regiao_medico"
																options={
																	microRegioes.data.length
																		? microRegioes.data
																		: []
																}
																onChange={(v: number) => {
																	formik.setFieldValue(
																		'id_micro_regiao_medico',
																		v,
																	);
																	loadCleanChanges('microRegiao', v, formik);
																}}
																titleSize="md"
																size={80}
																error={!!formik.errors.id_micro_regiao_medico}
															/>
														</Col>
													</Row>
												)}

												<Row gutter={[0, 10]}>
													{((formik.values.codigo_ciretran &&
														formik.values.codigo_ciretran !== 0) ||
														(formik.values.id_micro_regiao_medico &&
															formik.values.id_micro_regiao_medico !== '') ||
														datasExame.data?.enum.length) && (
														<>
															<Col span={8}>
																<Field
																	as={Select}
																	title="Data"
																	name="data_exame"
																	options={
																		datasExame.data?.enum.length
																			? datasExame.data.enum
																			: []
																	}
																	onChange={(v: number) => {
																		formik.setFieldValue('data_exame', v);
																		loadCleanChanges('data', v, formik);
																	}}
																	titleSize="md"
																	size={80}
																	error={!!formik.errors.data_exame}
																/>
															</Col>
															<Col span={8}>
																<Field
																	as={Select}
																	title="Hora"
																	name="horario_exame"
																	options={horariosPorData.data?.enum || []}
																	onChange={(v: number) => {
																		formik.setFieldValue('horario_exame', v);
																		loadCleanChanges('hora', v);
																	}}
																	titleSize="md"
																	size={80}
																	error={!!formik.errors.horario_exame}
																/>
															</Col>
														</>
													)}
													{((saveGerarRenach.data?.gerarRenachBase &&
														saveGerarRenach.data?.gerarRenachBase
															?.codCiretranHabilitacao === '18') ||
														saveGerarRenach.data?.gerarRenachBase
															?.codCiretranHabilitacao === '018' ||
														formik.values.codigo_ciretran === 18) && (
														<Col span={8}>
															<CEP
																name="cep_cidadao"
																formik={formik}
																titleSize="sm"
																size={80}
																isCalledInService="DETRAN_CNH"
																result={(res: IBuscarCepResultado) =>
																	handleCepSelecionado(res, formik)
																}
															/>
														</Col>
													)}
												</Row>

												<Row gutter={[0, 20]}>
													<Col span={24} />
												</Row>

												<Row justify="center">
													<Col>
														<ButtonImage
															type="submit"
															src="salvar"
															disabled={
																datasExame.data === null ||
																(datasExame.data !== null &&
																	datasExame.data.enum.length <= 0)
															}
														/>
													</Col>
												</Row>
											</Form>
										)}
									</Formik>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AgendamentoMedicoRenachIntegrado;
