import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, BaixarArquivos } from './types';

export const INITIAL_STATE: BaixarArquivos = {
	status: 0,
	data: {
		arquivo: null,
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): BaixarArquivos {
	return produce(state, draft => {
		switch (action.type) {
			case Types.BAIXAR_ARQUIVOS_REQUEST: {
				draft.status = 100;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.BAIXAR_ARQUIVOS_SUCCESS: {
				draft.data.arquivo = action.payload.response;
				draft.status = 200;
				break;
			}

			case Types.BAIXAR_ARQUIVOS_FAILURE: {
				draft.data = INITIAL_STATE.data;
				draft.status = 400;
				break;
			}
			case Types.BAIXAR_ARQUIVOS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
