import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Row, Col } from 'antd';

import getValidationsErrors from 'utils/getValidationsErrors';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

import { IReenviarDocumentosRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/reenviarDocumentos/types';
import {
	reenviarDocumentosRequest,
	reenviarDocumentosClear,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/reenviarDocumentos/actions';
import {
	consultarAcompanhamentoClear,
	consultarAcompanhamentoRequest,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarAcompanhamento/actions';
import { HistoricoAnalise } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarAcompanhamento/types';

import Alert from 'components/Common/Notifications/Alert';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import UploadArquivo from 'pages/DetranCrv/components/pages/UploadArquivo';
import { ContentButton } from 'pages/iirgd/Aac/Numeral/Consultar/styled';

import { ROUTE_DETRAN_REENVIAR_DOCUMENTOS_CONSULTAR } from '../../../routes/paths';

import DadosProcesso from './DadosProcesso';
import ListaAnalises from './ListaAnalises';

import { initialValues, schema, formatList, formatArquivos } from './form';

const ResultadoReenviarDocumentos: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [showMessage, setShowMessage] = useState(false);

	const [listaAnalises, setListaAnalises] = useState<HistoricoAnalise[] | []>(
		[],
	);

	const [canUpload, setCanUpload] = useState(true);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);
	const { salvarAtendimento } = atendimento;
	const { login } = loginUnico;

	const sguData: SguData = useMemo(() => {
		return {
			salvarAtendimento,
			login,
		};
	}, [login, salvarAtendimento]);

	const evento = getEventoConsultar(sguData);

	const {
		data: acompanhamentoData,
		status: acompanhamentoStatus,
		form,
	} = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.solicitarDocumentos.consultarAcompanhamento,
	);

	const { reenviarDocumentos } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const handleSubmit = useCallback(
		(formValues: any) => {
			const listaArquivos = formatArquivos(formValues.documentos);
			const values: IReenviarDocumentosRequest = {
				arquivos: listaArquivos,
				anoFicha: acompanhamentoData?.anoFicha.toString() || '',
				numeroFicha: acompanhamentoData?.numeroFicha.toString() || '',
				codUnidadeTransito: evento.canal.localidade.id,
				cpfUsuarioBU: evento.cpf,
			};
			dispatch(reenviarDocumentosRequest(values));
		},
		[acompanhamentoData, dispatch, evento],
	);

	useEffect(() => {
		if (acompanhamentoStatus === 200) {
			const listaAnalise = formatList(
				acompanhamentoData?.historicoAnalise
					? acompanhamentoData?.historicoAnalise
					: [],
			);
			if (listaAnalise.length > 0) {
				setCanUpload(listaAnalise[0].status === 'Reprovado');
				setListaAnalises(listaAnalise);
			}
		}
	}, [acompanhamentoData, acompanhamentoStatus]);

	useEffect(() => {
		if (reenviarDocumentos.status === 200 && form) {
			dispatch(consultarAcompanhamentoRequest(form));
			dispatch(reenviarDocumentosClear());
			setShowMessage(true);
		}
	}, [reenviarDocumentos, dispatch, history, form, evento]);

	return (
		<>
			{showMessage && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Alert
							type="success"
							message="Sucesso. O conjunto de documentos para o processo foi atualizado."
						/>
					</Col>
				</Row>
			)}
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={initialValues}
				validate={values => getValidationsErrors(values, schema)}
				onSubmit={handleSubmit}
			>
				{formik => (
					<Form autoComplete="off">
						<DadosProcesso data={acompanhamentoData} />
						<ListaAnalises lista={listaAnalises} />
						{canUpload && (
							<div style={{ marginTop: '15px' }}>
								<UploadArquivo
									formik={formik}
									evento={evento}
									tipoArquivo=""
									showList={false}
								/>
								<ContentButton justify="center">
									<ButtonImage type="submit" src="atualizar" />
								</ContentButton>
							</div>
						)}
					</Form>
				)}
			</Formik>
			<ButtonVoltar
				navigate={false}
				onClick={() => {
					dispatch(reenviarDocumentosClear());
					dispatch(consultarAcompanhamentoClear());
					history.push(ROUTE_DETRAN_REENVIAR_DOCUMENTOS_CONSULTAR);
				}}
			/>
		</>
	);
};

export default ResultadoReenviarDocumentos;
