import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultaTipoRequest } from 'store/modules/api/detranCrv/enum/consultaTipo/actions';
import { consultaEspecieRequest } from 'store/modules/api/detranCrv/enum/consultaEspecie/actions';
import { consultaCarroceriaRequest } from 'store/modules/api/detranCrv/enum/consultaCarroceria/actions';
import { consultaCorRequest } from 'store/modules/api/detranCrv/enum/consultaCor/actions';
import { consultaCombustivelRequest } from 'store/modules/api/detranCrv/enum/consultaCombustivel/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCrv/enum/consultaCategoria/actions';
import { consultarMunicipioPorUfRequest } from 'store/modules/api/detranCrv/enum/consultarMunicipioPorUF/actions';
import { emitirSegundaViaPlacaRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirSegundaViaPlaca/actions';
import { consultarVeiculoSegundaViaPlacaClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/consultarVeiculoSegundaViaPlaca/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import { completaZeroEsquerda } from 'utils/genericFunctions';
import getValidationsErrors from 'utils/getValidationsErrors';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

// COMPONENTS
import DadosVeiculo from './DadosVeiculo';
import DadosProprietario from './DadosProprietario';
import AutorizacaoEstampagem from './AutorizacaoEstampagem';
import DadosSegundaVia from './DadosSegundaVia';

// FORM
import {
	IEmitirSegundaViaPlaca,
	initialValues,
	schema,
	treatValues,
} from './form';

const EmitirSegundaViaPlaca: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		consultaCategoria,
		consultaCarroceria,
		consultaCombustivel,
		consultaCor,
		consultaEspecie,
		consultarMunicipioPorUF,
		consultaTipo,
	} = useSelector((state: ApplicationState) => state.api.detranCrv.Enum);

	const { consultarVeiculoSegundaViaPlaca, emitirSegundaViaPlaca } =
		useSelector(
			(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
		);

	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (!consultarVeiculoSegundaViaPlaca.data) {
			history.push('/detran-crv/segunda-via-placa/consulta');
		}
	}, [consultarVeiculoSegundaViaPlaca.data, history]);

	useEffect(() => {
		if (
			consultarVeiculoSegundaViaPlaca.data &&
			consultaTipo.data.length > 0 &&
			consultaEspecie.data.length > 0 &&
			consultaCarroceria.data.length > 0 &&
			consultaCor.data.length > 0 &&
			consultaCombustivel.data.length > 0 &&
			consultarMunicipioPorUF.data.length > 0 &&
			consultaCategoria.data.length > 0 &&
			consultarMunicipioPorUF.data.length > 0
		) {
			const {
				placa,
				renavam,
				chassi,
				marca,
				municipio,
				anoModelo,
				tipo,
				carroceria,
				cor,
				categoria,
				combustivel,
				especie,
				capacidadePassageiro,
				capacidadeCarga,
				potencia,
				cilindrada,
				cmt,
				pbt,
				eixo,
				procedencia,
				proprietario,
			} = consultarVeiculoSegundaViaPlaca.data;

			// Dados do Veículo
			initialValues.placa = placa;
			initialValues.chassi = chassi;
			initialValues.renavam = renavam;

			initialValues.longIdMarca = marca.longId;
			initialValues.descricaoMarca = marca.descricao;

			initialValues.codigoMunicipio = municipio.codigo;
			initialValues.descricaoMunicipio = municipio.nome;

			initialValues.anoModelo = anoModelo;

			initialValues.longIdTipo = tipo.longId;
			initialValues.descricaoTipo = tipo.descricao;

			initialValues.longIdCarroceria = carroceria.longId;
			initialValues.descricaoCarroceria = carroceria.descricao;

			initialValues.longIdCor = cor.longId;
			initialValues.descricaoCor = cor.descricao;

			initialValues.longIdCategoria = categoria.longId;
			initialValues.descricaoCategoria = categoria.descricao;

			initialValues.longIdCombustivel = combustivel.longId;
			initialValues.descricaoCombustivel = combustivel.descricao;

			initialValues.longIdEspecie = especie.longId;
			initialValues.descricaoEspecie = especie.descricao;

			initialValues.capacidadePassageiro = capacidadePassageiro;
			initialValues.capacidadeCarga = capacidadeCarga
				? Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(capacidadeCarga)
				: Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(0);
			initialValues.potencia = potencia;
			initialValues.cilindrada = cilindrada;
			initialValues.cmt = cmt
				? Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(cmt)
				: Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(0);
			initialValues.pbt = pbt
				? Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(pbt)
				: Intl.NumberFormat('en-IN', {
						style: 'decimal',
						minimumFractionDigits: 1,
				  }).format(0);
			initialValues.eixos = eixo;

			initialValues.procedencia = procedencia;

			// Dados do Proprietário
			initialValues.nomeProprietario =
				proprietario.nomeProprietario?.trim().substring(0, 60) || '';
			initialValues.cepProprietario = completaZeroEsquerda(
				proprietario.cepProprietario,
				8,
			);
			initialValues.bairroProprietario =
				proprietario.bairro?.trim().substring(0, 70) || '';
			initialValues.enderecoProprietario =
				proprietario.enderecoProprietario?.trim().substring(0, 70) || '';
			initialValues.numeroProprietario = String(proprietario.numero);
			initialValues.complementoProprietario =
				proprietario.complemento?.trim().substring(0, 6) || '';

			const selectedMunicipio = consultarMunicipioPorUF.data.filter(
				item => item.label === proprietario.municipioProprietario.nome,
			);

			initialValues.codigoMunicipioProprietario = selectedMunicipio[0].value;
			initialValues.descricaoMunicipioProprietario = selectedMunicipio[0].label;

			initialValues.identificacao = proprietario.identificacao?.trim() || '';

			if (initialValues.identificacao === 'FISICA') {
				initialValues.cpfProprietario = completaZeroEsquerda(
					proprietario.cpfCnpjProprietario,
					11,
				);
			} else if (initialValues.identificacao === 'JURIDICA') {
				initialValues.cnpjProprietario = completaZeroEsquerda(
					proprietario.cpfCnpjProprietario,
					14,
				);
			}

			setHasLoaded(true);
		}
	}, [
		consultarVeiculoSegundaViaPlaca.data,
		consultaTipo.data,
		consultaEspecie.data,
		consultaCarroceria.data,
		consultaCor.data,
		consultaCombustivel.data,
		consultaCategoria.data,
		consultarMunicipioPorUF.data,
		history,
	]);

	// Enum: Municípios - SP.
	useEffect(() => {
		if (consultarMunicipioPorUF.data.length === 0) {
			dispatch(consultarMunicipioPorUfRequest('SP'));
		}
	}, [consultarMunicipioPorUF, dispatch]);

	// Enum: Tipo de Veículo.
	useEffect(() => {
		if (consultaTipo.data.length === 0) {
			dispatch(consultaTipoRequest());
		}
	}, [consultaTipo, dispatch]);

	// Enum: Espécie.
	useEffect(() => {
		if (consultaEspecie.data.length === 0) {
			dispatch(consultaEspecieRequest());
		}
	}, [consultaEspecie, dispatch]);

	// Enum: Carroceria.
	useEffect(() => {
		if (consultaCarroceria.data.length === 0) {
			dispatch(consultaCarroceriaRequest());
		}
	}, [consultaCarroceria, dispatch]);

	// Enum: Cor.
	useEffect(() => {
		if (consultaCor.data.length === 0) {
			dispatch(consultaCorRequest());
		}
	}, [consultaCor, dispatch]);

	// Enum: Combustível.
	useEffect(() => {
		if (consultaCombustivel.data.length === 0) {
			dispatch(consultaCombustivelRequest());
		}
	}, [consultaCombustivel, dispatch]);

	// Enum: Categoria.
	useEffect(() => {
		if (consultaCategoria.data.length === 0) {
			dispatch(consultaCategoriaRequest());
		}
	}, [consultaCategoria, dispatch]);

	useEffect(() => {
		if (emitirSegundaViaPlaca.data) {
			history.push('/detran-crv/segunda-via-placa/resultado');
		}
	}, [emitirSegundaViaPlaca.data, history]);

	const handleValidate = useCallback((formValues: IEmitirSegundaViaPlaca) => {
		return getValidationsErrors(formValues, schema);
	}, []);

	const { salvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento,
	);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const handleSubmit = useCallback(
		(formValues: IEmitirSegundaViaPlaca) => {
			const sguData: SguData = {
				salvarAtendimento,
				login,
			};
			const evento = getEventoConsultar(sguData);

			const values = treatValues(evento, formValues);

			dispatch(emitirSegundaViaPlacaRequest(values));
		},
		[dispatch, login, salvarAtendimento],
	);

	const handleButtonCancelar = useCallback(() => {
		dispatch(consultarVeiculoSegundaViaPlacaClear());

		history.push('/detran-crv/segunda-via-placa/consultar');
	}, [dispatch, history]);

	const handleButtonVoltar = useCallback(() => {
		dispatch(consultarVeiculoSegundaViaPlacaClear());

		history.push('/detran-crv/segunda-via-placa/consultar');
	}, [dispatch, history]);

	return (
		<>
			{hasLoaded && (
				<>
					<Row>
						<Col span={24}>
							<Section size="lg" title="Segunda Via de Placa" titleSize="sm">
								<Formik
									validateOnChange={false}
									validateOnBlur={false}
									initialValues={initialValues}
									validate={handleValidate}
									onSubmit={handleSubmit}
								>
									{formik => (
										<Form autoComplete="off">
											<DadosVeiculo formik={formik} />

											<DadosProprietario
												initialValues={initialValues}
												formik={formik}
											/>

											<AutorizacaoEstampagem formik={formik} />

											<DadosSegundaVia formik={formik} />

											<Row gutter={[0, 20]}>
												<Col span={24} />
											</Row>

											<Row justify="center">
												<Col>
													<Button onClick={handleButtonCancelar}>
														Cancelar
													</Button>
												</Col>

												<Col offset={1}>
													<Button type="submit">Enviar</Button>
												</Col>
											</Row>
										</Form>
									)}
								</Formik>
							</Section>

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default EmitirSegundaViaPlaca;
