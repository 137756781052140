// TYPES
import { ReducerAction } from 'store/modules/types';
import { ApiResponse } from 'services/_api';
import { Types, ConsultarCpfCin } from './types';

export function consultarCpfCinRequest(payload: string): ReducerAction {
	return {
		type: Types.CONSULTAR_CIN_CPF_REQUEST,
		payload,
	};
}
export function consultarCpfCinSuccess(
	payload: ConsultarCpfCin,
): ReducerAction {
	return {
		type: Types.CONSULTAR_CIN_CPF_SUCCESS,
		payload,
	};
}
export function consultarCpfCinFailure(payload: ApiResponse): ReducerAction {
	return {
		type: Types.CONSULTAR_CIN_CPF_FAILURE,
		payload,
	};
}
export function consultarCpfCinClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_CIN_CPF_CLEAR,
		payload: null,
	};
}
