import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaCnhEstrangeiroRequest } from './types';

export function consultaCnhEstrangeiroRequest(
	data: ConsultaCnhEstrangeiroRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_CNH_ESTRANGEIRO_REQUEST,
		payload: data,
	};
}
export function consultaCnhEstrangeiroSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_CNH_ESTRANGEIRO_SUCCESS,
		payload,
	};
}
export function consultaCnhEstrangeiroFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_CNH_ESTRANGEIRO_FAILURE,
		payload,
	};
}
export function consultaCnhEstrangeiroClear(): ReducerAction {
	return {
		type: Types.CONSULTA_CNH_ESTRANGEIRO_CLEAR,
		payload: null,
	};
}
