// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	IConsultarEstatistica,
	IConsultarEstatisticaRequest,
	Types,
} from './types';

export function consultarEstatisticaRequest(
	payload: IConsultarEstatisticaRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_ESTATISTICA_REQUEST,
		payload,
	};
}

export function consultarEstatisticaSuccess(
	status: number,
	data: IConsultarEstatistica[] | null,
): ReducerAction {
	return {
		type: Types.CONSULTAR_ESTATISTICA_SUCCESS,
		payload: {
			status,
			data,
		},
	};
}

export function consultarEstatisticaClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_ESTATISTICA_CLEAR,
		payload: null,
	};
}

export function consultarEstatisticaFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_ESTATISTICA_FAILURE,
		payload: null,
	};
}
