import { Types } from './types';

export function impressaoTermoPaternidadeRequest(payload: any) {
	return {
		type: Types.IMPRESSAO_TERMO_PATERNIDADE_REQUEST,
		payload,
	};
}
export function impressaoTermoPaternidadeSuccess(payload: any) {
	return {
		type: Types.IMPRESSAO_TERMO_PATERNIDADE_SUCCESS,
		payload,
	};
}
export function impressaoTermoPaternidadeFailure(payload: []): any {
	return {
		type: Types.IMPRESSAO_TERMO_PATERNIDADE_FAILURE,
		payload,
	};
}
export function impressaoTermoPaternidadeClear(): any {
	return {
		type: Types.IMPRESSAO_TERMO_PATERNIDADE_CLEAR,
	};
}
