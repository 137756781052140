import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RequestConsultaEPortalSegundaVia } from './types';

// ACTIONS
import {
	consultaESegundaViaSuccess,
	consultaESegundaViaFailure,
} from './actions';

function* consultaEPortal(request: ReducerAction) {
	const { payload }: { payload: RequestConsultaEPortalSegundaVia } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`eportal/${CHANNEL}/pesquisa/segunda-via`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaESegundaViaSuccess({ response, request: payload }));
	} else {
		yield put(
			consultaESegundaViaFailure({
				response: response.data,
				request: payload,
			}),
		);
	}
}

export default all([
	takeLatest(Types.CONSULTA_E_PORTAL_SEGUNDA_VIA_REQUEST, consultaEPortal),
]);
