import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_UTILS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultarFotosRgClear,
	consultarFotosRgSuccess,
	consultarFotosRgFailure,
} from './actions';

function* consultarFotosRgRequest(request: ReducerAction) {
	const { payload } = request;

	yield put(consultarFotosRgClear());

	const response: ApiResponse = yield call(
		getApi,
		PATH_UTILS,
		`biometria/${CHANNEL}/foto/${payload}`,
	);

	if (response.status === 200) {
		yield put(consultarFotosRgSuccess(response.data));
	} else {
		yield put(consultarFotosRgFailure(response.data));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_FOTOS_RG_REQUEST, consultarFotosRgRequest),
]);
