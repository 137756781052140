export enum Types {
	CONSULTA_ALUNO_REQUEST = '@EDUCACAO/CONSULTA_ALUNO_REQUEST',
	CONSULTA_ALUNO_SUCCESS = '@EDUCACAO/CONSULTA_ALUNO_SUCCESS',
	CONSULTA_ALUNO_FAILURE = '@EDUCACAO/CONSULTA_ALUNO_FAILURE',
	CONSULTA_ALUNO_CLEAR = '@EDUCACAO/CONSULTA_ALUNO_CLEAR',
}

export interface ConsultaAluno {
	status: number;
	data: IDadosAluno | null;
	request: ConsultaAlunoRequest | null;
}

export interface IDadosAluno {
	processoId: string;
	alunos: IAlunos[];
}

interface IAlunos {
	numRA: string;
	digitoRA: string;
	siglaUFRA: string;
	nomeAluno: string;
	nomeSocial: string;
	nomeMae: string;
	dataNascimento: string;
	nomePai: string;
}
export interface ConsultaAlunoRequest {
	dataNascimento: string;
	nomeAluno: string;
	nomeMae: string;
	nomePai: string;
	numRG?: string;
	digitoRG?: string;
	ufrg?: string;
	cpf?: string;
	numNIS?: string;
	numINEP?: string;
	numCertidaoNova?: string;
	numCertidaoNasc?: string;
	livroCertidaoNasc?: string;
	folhaCertidaoNasc?: string;
	distritoCertidao?: string;
	municipioComarca?: string;
	ufComarca?: string;
	dataEmissaoCertidao?: string;
	codMunicComarcaDNE?: string;
}
