import { IEvento } from '../types';

export enum Types {
	CONSULTAR_ACOMPANHAMENTO_REQUEST = '@DETRAN-CRV/CONSULTAR_ACOMPANHAMENTO_REQUEST',
	CONSULTAR_ACOMPANHAMENTO_SUCCESS = '@DETRAN-CRV/CONSULTAR_ACOMPANHAMENTO_SUCCESS',
	CONSULTAR_ACOMPANHAMENTO_FAILURE = '@DETRAN-CRV/CONSULTAR_ACOMPANHAMENTO_FAILURE',
	CONSULTAR_ACOMPANHAMENTO_CLEAR = '@DETRAN-CRV/CONSULTAR_ACOMPANHAMENTO_CLEAR',
}

export interface ConsultarAcompanhamento {
	status: number;
	data: ConsultarAcompanhamentoResponse | null;
	mensagem?: string;
	form: ConsultarAcompanhamentoRequest | null;
}

export interface ConsultarAcompanhamentoResponse {
	placa: string;
	renavam: number;
	codigoAndamento: string;
	descricaoAndamento: string;
	dataEmissao: string;
	numeroFicha: number;
	anoFicha: number;
	opcaoServico: number;
	ufOrigemVeiculo: string;
	codigoSegurancaCrv: string;
	dataSolicitacao: string;
	dataAtualizacaoRetaguarda: string;
	existeBloqueioAdministrativo: string;
	dataDesbloqueioAdministrativo: string;
	unidadeRetirada: {
		codigoUnidadeTransito: number;
		nomeUnidadeTransito: string;
	};
	historicoAnalise: HistoricoAnalise[];
}

export interface HistoricoAnalise {
	data: string;
	local: string;
	cpfUsuario: string;
	status: string;
	motivoReprovacao: string;
	documentosPendente: string;
}

export interface ConsultarAcompanhamentoRequest {
	evento?: IEvento;
	placa: string;
	renavam: string;
	documento: string;
	processo?: string | null;
}
