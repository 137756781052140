import { ReducerAction } from 'store/modules/types';
import { Types, IRequestWithHeader } from './types';

export function emitirTransferenciasProprietariosRequest(
	payload: IRequestWithHeader,
): ReducerAction {
	return {
		type: Types.EMITIR_TRANSFERENCIAS_PROPRIETARIOS_REQUEST,
		payload,
	};
}
export function emitirTransferenciasProprietariosSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.EMITIR_TRANSFERENCIAS_PROPRIETARIOS_SUCCESS,
		payload,
	};
}
export function emitirTransferenciasProprietariosFailure(): ReducerAction {
	return {
		type: Types.EMITIR_TRANSFERENCIAS_PROPRIETARIOS_FAILURE,
		payload: null,
	};
}
export function emitirTransferenciasProprietariosClear(): ReducerAction {
	return {
		type: Types.EMITIR_TRANSFERENCIAS_PROPRIETARIOS_CLEAR,
		payload: null,
	};
}
