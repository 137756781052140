// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import {
	GerarOcorrenciaData,
	GerarOcorrenciaRequest,
	CalculoBoletoAgrupadoRequest,
	Types,
} from './types';

export function gerarOcorrenciaRequest(
	token: string,
	payload: GerarOcorrenciaRequest,
): ReducerActionRotaSP {
	return {
		type: Types.GERAR_OCORRENCIA_REQUEST,
		token,
		payload,
	};
}
export function gerarOcorrenciaSuccess(
	data: GerarOcorrenciaData,
	form: GerarOcorrenciaRequest,
): ReducerActionRotaSP {
	return {
		type: Types.GERAR_OCORRENCIA_SUCCESS,
		payload: {
			data,
			form,
		},
	};
}
export function gerarOcorrenciaFailure(): ReducerActionRotaSP {
	return {
		type: Types.GERAR_OCORRENCIA_FAILURE,
		payload: null,
	};
}
export function gerarOcorrenciaClear(): ReducerActionRotaSP {
	return {
		type: Types.GERAR_OCORRENCIA_CLEAR,
		payload: null,
	};
}

export function calculoBoletoAgrupadoRequest(
	token: string,
	payload: CalculoBoletoAgrupadoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CALCULO_BOLETO_AGRUPADO_REQUEST,
		token,
		payload,
	};
}
export function calculoBoletoAgrupadoClear(): ReducerActionRotaSP {
	return {
		type: Types.CALCULO_BOLETO_AGRUPADO_CLEAR,
		payload: null,
	};
}
