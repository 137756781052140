import React from 'react';
import { ComboFinalizacao } from '../Buttons/ComboFinalizacao';

export const PrimeiraVia: React.FC = () => {
	return (
		<>
			<ComboFinalizacao />
		</>
	);
};
