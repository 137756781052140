import React, { ChangeEvent, useEffect, useState } from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

// REDUX
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import FormBox from 'components/Common/Form/FormBox';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask/index';
import Select from 'components/Common/Form/Select';
import TooltipInfo from 'components/Common/Tooltips/Info';

// UTILS
import hasError from 'utils/getFormErrors';
import { letrasNumerosPontosHifen } from 'utils/stringFunctions';

// FORM
import ValidData from 'components/Common/Form/Input/ValidData';
import { IFormCadastroRg } from '../../form';

// STYLED
import { SlashDivider } from './styled';

interface Props {
	formik: FormikProps<IFormCadastroRg>;
	bloqueioPortaria606: boolean;
}

const TooltipContent = (
	<>
		<p>
			1) Comarcas de (Rio de Janeiro, Goiania, Guarulhos, Belem, Matias Olimpio
			ou Esperantina) E (Ano do Documento anterior a 2009);
		</p>
		<p>
			2) Comarca de São Paulo-SP e Cartório de Santo Amaro e Ano do Documento
			anterior a 2009;
		</p>
		<p>
			3) Todos os cartórios do Brasil cujo Ano do Documento for de 2009 em
			diante.
		</p>
	</>
);

const DocumentoApresentado: React.FC<Props> = ({
	formik,
	bloqueioPortaria606,
}) => {
	const { setFieldValue } = formik;

	const [isRequired, setIsRequired] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	// Manipula a obrigatoriedade dos campos.
	useEffect(() => {
		const { dbas } = formik.values;

		if (dbas === 'CN' || dbas === 'CC') {
			setIsRequired(true);
			setIsDisabled(false);
			return;
		}

		setIsDisabled(true);
		setIsRequired(false);
	}, [formik.values]);

	return (
		<>
			<FormBox title="Documento apresentado">
				<Row gutter={[0, 10]}>
					<Col span={18}>
						<Field
							as={Select}
							title="Tipo de Documento"
							name="dbas"
							options={data.tipoDocumento}
							onChange={(v: string) => {
								if (v.toString() !== 'CN' && v.toString() !== 'CC') {
									formik.setFieldValue('comarca', '');
									formik.setFieldValue('cartorio', '');
									formik.setFieldValue('livro', '');
									formik.setFieldValue('folha', '');
									formik.setFieldValue('numero', '');
									formik.setFieldValue('por', '');
								} else {
									formik.setFieldValue('pMinLei2', '');
									formik.setFieldValue('pMinLei1', '');
								}
								setFieldValue('dbas', v);
							}}
							required
							error={!!formik.errors.dbas}
							disabled={bloqueioPortaria606}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={9}>
						<Field
							as={Input}
							title="Comarca"
							disabled={isDisabled || bloqueioPortaria606}
							name="comarca"
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								formik.setFieldValue(
									'comarca',
									letrasNumerosPontosHifen(e.target.value),
								)
							}
							maxLength={25}
							size={78}
							required={isRequired}
							error={!!formik.errors.comarca}
						/>
					</Col>
					<Col span={9}>
						<Field
							as={Input}
							disabled={isDisabled || bloqueioPortaria606}
							title="Cartório"
							name="cartorio"
							maxLength={25}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								formik.setFieldValue(
									'cartorio',
									letrasNumerosPontosHifen(e.target.value),
								);
							}}
							required={isRequired}
							error={!!formik.errors.cartorio}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={8}>
						<Field
							as={InputMask}
							title="Livro"
							disabled={isDisabled || bloqueioPortaria606}
							maskChar=""
							name="livro"
							mask="****"
							required={isRequired}
							error={!!formik.errors.livro}
						/>
					</Col>
					<Col span={1}>
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								height: '100%',
								marginLeft: '6px',
							}}
						>
							<TooltipInfo
								title="O campo Livro aceita 1000 a 9000 (sem letra) se:"
								content={TooltipContent}
							/>
						</div>
					</Col>

					<Col span={7}>
						<Field
							as={InputMask}
							title="Folha"
							disabled={isDisabled || bloqueioPortaria606}
							name="folha"
							mask="****"
							maskChar=""
							required={isRequired}
							error={!!formik.errors.folha}
						/>
					</Col>

					<Col span={8}>
						<Field
							as={InputMask}
							title="Número"
							name="numero"
							mask="******"
							maskChar=""
							size={76}
							disabled={isDisabled || bloqueioPortaria606}
							required={isRequired}
							error={!!formik.errors.numero}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={ValidData}
							title="Data Documento"
							subtitle="(DD/MM/AAAA)"
							name="dataDocumento"
							mask="99/99/9999"
							size={47}
							disabled={bloqueioPortaria606}
							required={formik.values.dbas}
							error={hasError(formik.errors, 'dataDocto')}
							formik={formik}
							onChange={(v: string) => formik.setFieldValue('dataDocumento', v)}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Section title="Se Naturalidade Estrangeira:" size="sm">
							<Row>
								<Col span={6}>
									<Field
										as={InputMask}
										title="Port Min-Lei"
										titleSize={85}
										name="pMinLei1"
										mask="99999"
										maskChar=""
										required={
											formik.values.dbas !== 'CN' &&
											formik.values.dbas !== 'CC' &&
											formik.values.dbas !== ''
										}
										disabled={
											formik.values.dbas === 'CN' ||
											formik.values.dbas === 'CC' ||
											formik.values.dbas === '' ||
											bloqueioPortaria606
										}
										error={hasError(formik.errors, 'pMinLei')}
									/>
								</Col>

								<SlashDivider>
									<div>/</div>
								</SlashDivider>

								<Col span={9}>
									<Field
										as={InputMask}
										subtitle="(Apenas números)"
										name="pMinLei2"
										mask="9999"
										maskChar=""
										size={20}
										disabled={
											formik.values.dbas === 'CN' ||
											formik.values.dbas === 'CC' ||
											formik.values.dbas === '' ||
											bloqueioPortaria606
										}
										error={hasError(formik.errors, 'pMinLei')}
									/>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			</FormBox>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default DocumentoApresentado;
