import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_UTILS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RequestEnviaSMSPositus } from './types';

// ACTIONS
import { enviaSMSPositusSuccess, enviaSMSPositusFailure } from './actions';

function* enviaSMSPositus(request: ReducerAction) {
	const { payload }: { payload: RequestEnviaSMSPositus } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_UTILS,
		`positus/${CHANNEL}/sms`,
		payload,
	);

	if (
		response.status === 200 ||
		response.status === 201 ||
		response.status === 204
	) {
		yield put(enviaSMSPositusSuccess({ response, request: payload }));
	} else if (response.error) {
		yield put(enviaSMSPositusFailure(response.error));
	}
}

export default all([
	takeLatest(Types.ENVIAR_SMS_POSITUS_REQUEST, enviaSMSPositus),
]);
