export interface IFormProcurador {
	flagProcurador: string;
}

export const initialValues: IFormProcurador = {
	flagProcurador: 'N',
};

export const radioSimNao = [
	{
		label: 'Sim',
		value: 'S',
	},
	{
		label: 'Não',
		value: 'N',
	},
];
