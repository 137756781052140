import * as Yup from 'yup';
import { validaCpfCnpj } from 'utils/genericFunctions';

// REDUX
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';
import { placa } from 'store/modules/api/detranCrv/SolicitarDocumentos/EscolherPlaca/types';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';

export interface IEscolherPlaca {
	chassi: string;
	cpfCnpjProprietario: string;
	categoria?: string | null;
}

export const initialValues: IEscolherPlaca = {
	chassi: '',
	cpfCnpjProprietario: '',
	categoria: '',
};

export const schema = Yup.object<IEscolherPlaca>().shape({
	chassi: Yup.string().required(`Campo Obrigatório: Chassi.`),
	cpfCnpjProprietario: Yup.string()
		.required(`Campo Obrigatório: CPF ou CNPJ do Proprietário.`)
		.test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF/CNPJ inválido.',
			test: value => value === '' || validaCpfCnpj(value),
		}),
	categoria: Yup.string().required(`Campo Obrigatório: Categoria.`),
	documentoCategoria: Yup.string().when(['cpfCnpjProprietario', 'categoria'], {
		is: (cpfCnpjProprietario, categoria) =>
			cpfCnpjProprietario.length <= 11 && categoria.toString() === '3',
		then: Yup.string().required(
			'Categoria oficial somente permitido para pessoa jurídica (CNPJ).',
		),
	}),
});

export const treatRequestSguStatistics = (
	user: IUser,
	formData: IEscolherPlaca,
	data: placa | null,
): IncluirRequest => {
	const payload: IncluirRequest = {
		observacao: `Chassi: ${
			formData?.chassi.trim() ||
			data?.resultadoEscolhaPlacaAtribuida?.chassi?.trim() ||
			'N/A'
		}, Placa: ${
			data?.resultadoEscolhaPlacaAtribuida?.placa?.trim() || 'N/A'
		}, Renavam Gerada ${'N/A'}`,
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento: formData?.cpfCnpjProprietario?.trim() || '',
		codigoLocalidade: (user?.posto && Number(user?.posto)) || 0,
		idOrgao: 5,
		cpf: user?.cpf,
		descricaoServico: 'Escolher Placa',
		idMotivo: 16,
	};

	return payload;
};
