export default {
	codigoSexo: [
		{
			value: 1,
			label: 'MASCULINO',
		},
		{
			value: 2,
			label: 'FEMININO',
		},
	],
	corRaca: [
		{
			value: 4,
			label: 'Amarela',
		},
		{
			value: 1,
			label: 'Branca',
		},
		{
			value: 5,
			label: 'Indígena',
		},
		{
			value: 3,
			label: 'Parda',
		},
		{
			value: 2,
			label: 'Preta',
		},
		{
			value: 6,
			label: 'Não Declarada',
		},
	],
	participaBolsaFamilia: [
		{
			label: 'Sim',
			value: 1,
		},
		{
			label: 'Não',
			value: 0,
		},
	],
	nacionalidade: [
		{
			value: 1,
			label: 'Brasileiro',
		},
		{
			value: 2,
			label: 'Estrangeiro',
		},
		{
			value: 3,
			label: 'Brasileiro Nascido no Exterior',
		},
	],
	tipoCertidao: [
		{
			label: 'NOVA',
			value: 'NOVA',
		},
		{
			label: 'ANTIGA',
			value: 'ANTIGA',
		},
	],
	tamanhoFonte: [
		{
			label: 16,
			value: 16,
		},
		{
			label: 20,
			value: 20,
		},
		{
			label: 24,
			value: 24,
		},
	],

	comboUF: [
		{
			label: 'Acre',
			value: 'AC',
		},
		{
			label: 'Alagoas',
			value: 'AL',
		},
		{
			label: 'Amapá',
			value: 'AP',
		},
		{
			label: 'Amazonas',
			value: 'AM',
		},
		{
			label: 'Bahia',
			value: 'BA',
		},
		{
			label: 'Ceará',
			value: 'CE',
		},
		{
			label: 'Distrito Federal',
			value: 'DF',
		},
		{
			label: 'Espírito Santo',
			value: 'ES',
		},
		{
			label: 'Goiás',
			value: 'GO',
		},
		{
			label: 'Maranhão',
			value: 'MA',
		},
		{
			label: 'Mato Grosso',
			value: 'MT',
		},
		{
			label: 'Mato Grosso do Sul',
			value: 'MS',
		},
		{
			label: 'Minas Gerais',
			value: 'MG',
		},
		{
			label: 'Pará',
			value: 'PA',
		},
		{
			label: 'Paraíba',
			value: 'PB',
		},
		{
			label: 'Paraná',
			value: 'PR',
		},
		{
			label: 'Pernambuco',
			value: 'PE',
		},
		{
			label: 'Piauí',
			value: 'PI',
		},
		{
			label: 'Rio de Janeiro',
			value: 'RJ',
		},
		{
			label: 'Rio Grande do Norte',
			value: 'RN',
		},
		{
			label: 'Rio Grande do Sul',
			value: 'RS',
		},
		{
			label: 'Rondônia',
			value: 'RO',
		},
		{
			label: 'Roraima',
			value: 'RR',
		},
		{
			label: 'Santa Catarina',
			value: 'SC',
		},
		{
			label: 'São Paulo',
			value: 'SP',
		},
		{
			label: 'Sergipe',
			value: 'SE',
		},
		{
			label: 'Tocantins',
			value: 'TO',
		},
	],
	quilombola: [
		{
			label: 'Sim',
			value: 'S',
		},
		{
			label: 'Não',
			value: 'N',
		},
	],
	possuiInternet: [
		{
			label: 'Sim',
			value: 'S',
		},
		{
			label: 'Não',
			value: 'N',
		},
	],
	possuiNotebookSmartphoneTablet: [
		{
			label: 'Sim',
			value: 'S',
		},
		{
			label: 'Não',
			value: 'N',
		},
	],
	escalaProvaAmpliada: [
		{
			label: '16',
			value: 16,
		},
		{
			label: '20',
			value: 20,
		},
		{
			label: '24',
			value: 24,
		},
	],
	acompanhamentoDeficiente: [
		{
			label: 'Permanente',
			value: 'P',
		},
		{
			label: 'Temporario',
			value: 'T',
		},
	],
	areaLogradouro: [
		{
			label: 'URBANA',
			value: '0',
		},
		{
			label: 'RURAL',
			value: '1',
		},
	],
	localizacaoDiferenciada: [
		{
			label: 'Área de assentamento',
			value: 1,
		},
		{
			label: 'Terra indígena',
			value: 2,
		},
		{
			label: 'Área onde se localizada em Comunidade remanescente de Quilimbos',
			value: 3,
		},
		{
			label: 'Não está em área de localização diferenciada',
			value: 7,
		},
	],
	tipo: [
		{
			label: 'Residencial',
			value: 1,
		},
		{
			label: 'Comercial',
			value: 2,
		},
		{
			label: 'Celular',
			value: 3,
		},
		{
			label: 'Recados',
			value: 4,
		},
	],
	sms: [
		{
			label: 'SIM',
			value: '1',
		},
		{
			label: 'NAO',
			value: '0',
		},
	],
	parentesco: [
		{
			label: 'PADRASTO',
			value: 1,
		},
		{
			label: 'AVÔ',
			value: 2,
		},
		{
			label: 'AVÓ',
			value: 3,
		},
		{
			label: 'MADASTRA',
			value: 4,
		},
		{
			label: 'IRMÃ',
			value: 5,
		},
		{
			label: 'MÃE',
			value: 6,
		},
		{
			label: 'PAI',
			value: 7,
		},
		{
			label: 'TIO',
			value: 8,
		},
		{
			label: 'TIA',
			value: 9,
		},
		{
			label: 'IRMÃO',
			value: 10,
		},
		{
			label: 'CÔNJUGUE',
			value: 1,
		},
		{
			label: 'GUARDIÃO',
			value: 12,
		},
	],
	estadoCivil: [
		{
			label: 'SOLTEIRO',
			value: 1,
		},
		{
			label: 'CASADO',
			value: 2,
		},
		{
			label: 'VIÚVO',
			value: 3,
		},
		{
			label: 'DIVORCIADO',
			value: 4,
		},
		{
			label: 'SEPARADO',
			value: 5,
		},
	],
};
