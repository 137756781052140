import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IncluirExameMedicoRequest } from './types';

// ACTIONS
import {
	incluirExameMedicoSuccess,
	incluirExameMedicoFailure,
} from './actions';

function* incluirExameMedico(request: ReducerAction) {
	const { payload }: { payload: IncluirExameMedicoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`condutor/${CHANNEL}/incluir-exame-medico-posto`,
		payload,
	);

	if (response.status === 200) {
		yield put(incluirExameMedicoSuccess(response));
	} else if (response.error) {
		yield put(incluirExameMedicoFailure());
	}
}

export default all([
	takeLatest(Types.INCLUIR_EXAME_MEDICO_REQUEST, incluirExameMedico),
]);
