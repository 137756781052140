import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from 'use-places-autocomplete';

import { useDispatch } from 'react-redux';
import { latLngSelecionada } from 'store/modules/api/educacaoUpdate/fichaAluno/cadastrarFichaAluno/actions';

const center = {
	lat: -23.55052,
	lng: -46.633308,
};

interface Props {
	logradouro: string;
	setLogradouro: (v: any) => void;
}

const SearchAutocomplete: React.FC<Props> = ({ logradouro, setLogradouro }) => {
	const {
		ready,
		suggestions: { status, data },
		setValue,
	} = usePlacesAutocomplete({
		requestOptions: {
			location: {
				lat: () => center.lat,
				lng: () => center.lng,
				equals: () => false,
				toJSON: (): { lat: number; lng: number } => {
					return { lat: center.lat, lng: center.lng };
				},
				toUrlValue: () => '',
			},
			radius: 200 * 1000,
		},
	});
	const [options, setOptions] = useState<{ value: string }[]>([]);

	const dispatch = useDispatch();

	const renderSuggestions = () => {
		if (status === 'OK') {
			const suggestions = data.map((item): any => {
				return { value: item.description };
			});
			setOptions(suggestions);
		}
	};

	const onSelect = async (e: string) => {
		try {
			const results = await getGeocode({ address: e });
			const { lat, lng } = await getLatLng(results[0]);

			const latLngSelecionadaDispatch = { lat, lng };

			dispatch(latLngSelecionada(latLngSelecionadaDispatch));
		} catch (error) {
			console.log('Error!');
		}
	};

	return (
		<>
			<AutoComplete
				value={logradouro}
				options={options}
				style={{ width: '100%' }}
				onSelect={onSelect}
				onSearch={renderSuggestions}
				onChange={(v: any) => setLogradouro(v)}
			>
				<Input
					name="logradouro"
					size="large"
					placeholder="Digite o endereço"
					disabled={!ready}
					defaultValue={logradouro}
					onChange={e => {
						setValue(e.target.value);
					}}
				/>
			</AutoComplete>
		</>
	);
};

export default SearchAutocomplete;
