import { all } from 'redux-saga/effects';

import consultarSufixosSaga from './consultarSulfixos/saga';
import cadastrarSufixoSaga from './cadastrarSufixo/saga';
import excluirSufixoSaga from './excluirSufixo/saga';

export default all([
	consultarSufixosSaga,
	cadastrarSufixoSaga,
	excluirSufixoSaga,
]);
