import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ExcluirSufixoRequest } from './types';

// ACTIONS
import { excluirSufixoSuccess, excluirSufixoFailure } from './action';

function* excluirSufixo(request: ReducerAction) {
	const { payload }: { payload: ExcluirSufixoRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_SGU,
		`/sufixos/${payload.descricao}`,
	);

	if (response.status === 200) {
		yield put(excluirSufixoSuccess(response));
	} else if (response.error) {
		yield put(excluirSufixoFailure());
	}
}

export default all([takeLatest(Types.EXCLUIR_SUFIXO, excluirSufixo)]);
