import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarSufixoRequest } from './types';

export function cadastrarSufixoRequest(
	data: CadastrarSufixoRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_SUFIXO,
		payload: data,
	};
}
export function cadastrarSufixoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_SUFIXO_SUCCESS,
		payload,
	};
}
export function cadastrarSufixoFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_SUFIXO_FAILURE,
		payload: null,
	};
}
export function cadastrarSufixoClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_SUFIXO_CLEAR,
		payload: null,
	};
}
