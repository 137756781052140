export enum Types {
	COMPROVANTE_INSCRICAO_REQUEST = '@EDUCACAO/COMPROVANTE_INSCRICAO_REQUEST',
	COMPROVANTE_INSCRICAO_SUCCESS = '@EDUCACAO/COMPROVANTE_INSCRICAO_SUCCESS',
	COMPROVANTE_INSCRICAO_FAILURE = '@EDUCACAO/COMPROVANTE_INSCRICAO_FAILURE',
}

export interface ComprovanteInscricao {
	status: number;
	data: null | ComprovanteInscricaoResponse;
}

interface ComprovanteInscricaoResponse {
	processoId: number;
	urlArquivoPdf: string;
}

export interface ComprovanteInscricaoRequest {
	numeroRA: string;
	digitoRA?: string;
	siglaUfRA?: string;
	anoLetivo: number;
	tipoInscricao: string;
}
