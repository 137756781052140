// ROUTES
import { ROUTE_IIRGD_PESQUISAR_DBCIN } from './paths';

import PesquisarDBCIN from '../PesquisarDBCIN';

const iirgdRoute = {
	name: 'IIRGD',
	route: '/iirgd',
};

const consultarDBCINRoute = {
	name: 'Consultar DBCIN',
};

export default [
	{
		path: ROUTE_IIRGD_PESQUISAR_DBCIN,
		breadcrumb: [iirgdRoute, consultarDBCINRoute],
		Component: PesquisarDBCIN,
		exact: true,
		isAuthenticated: true,
	},
];
