export enum Types {
	PESQUISA_CITA_AAC_REQUEST = '@aac/PESQUISA_CITA_AAC_REQUEST',
	PESQUISA_CITA_AAC_SUCCESS = '@aac/PESQUISA_CITA_AAC_SUCCESS',
	PESQUISA_CITA_AAC_FAILURE = '@aac/PESQUISA_CITA_AAC_FAILURE',
	LIMPA_PESQUISA_CITA_AAC = '@aac/LIMPA_PESQUISA_CITA_AAC',
}

export interface pesquisaCitaAAC {
	status: number;
	data: DataPesquisaCITA;
	rgPesquisado: string | null;
}

export interface DataPesquisaCITA {
	numeroRg: string;
	digito_rg: string;
	nome: string;
	nome_social: string;
	nomeSocial: string;
	nomePai: string;
	nomeMae: string;
	sexo: string;
	dataNascimento: string;
	maior: boolean;
	codigo_naturalidade: string;
	cod_naturalidade_digito: string;
	municipio_naturalidade: string;
	pidt: string;
	digito_pidt: string;
	dbas: string;
	lote: string;
	comarca: string;
	cartorio: string;
	livro: string;
	folha: string;
	numero: string;
	estadoCivil: string;
	cutis: string;
	cabelos: string;
	olhos: string;
	altura_m: string;
	altura_cm: string;
	grau_instrucao: string;
	profissao: string;
	endereco_residencia: string;
	complemento_residencia: string;
	numero_residencia: string;
	bairro_residencia: string;
	codigo_municipio_residencia: string;
	cod_municipio_residencia_digito: string;
	desc_municipio_residencia: string;
	cep_residencia: string;
	endereco_trabalho: string;
	complemento_trabalho: string;
	bairro_trabalho: string;
	numero_trabalho: string;
	codigo_municipio_trabalho: string;
	cod_municipio_trabalho_digito: string;
	desc_municipio_trabalho: string;
	cep_trabalho: string;
	solicitacao: string;
	fone_residencia_ddd: string;
	fone_residencia_pre: string;
	fone_residencia_sul: string;
	fone_comercial_ddd: string;
	fone_comercial_pre: string;
	foneComercial_sul: string;
	celular_ddd: string;
	celular_pre: string;
	celular_sul: string;
	operadora: string;
	email: string;
	email_provedor: string;
	identificado_assina: string;
	tipo_sanguineo: string;
	p_min_lei3: string;
	cid1: string;
	cid2: string;
	cid3: string;
	cid4: string;
	pis: string;
	ctps_num: string;
	ctps_serie: string;
	ctps_serie_uf: string;
	certidao_militar: string;
	nasc_saude: string;
	cpf_inscricao: string;
	pasep: string;
	dni: string;
	titulo_eleitor: string;
	cnh: string;
	ident_prof1: string;
	orgao_emissor1: string;
	ident_prof2: string;
	orgao_emissor2: string;
	ident_prof3: string;
	orgao_emissor3: string;
	indicadorDeficienciaFisica?: boolean;
	indicadorDeficienciaAuditiva?: boolean;
	indicadorDeficienciaIntelectual?: boolean;
	indicadorDeficienciaVisual?: boolean;
	indicadorAutismo?: boolean;
}

export interface IRequestPesquisaCita {
	numeroRg: string;
	usuario: string;
	senha: string;
}
