// TYPES
import { ReducerAction } from 'store/modules/types';
import { ICadastroEventoRequest, Types } from './types';

export function cadastroEventoRequest(
	payload: ICadastroEventoRequest,
): ReducerAction {
	return {
		type: Types.CADASTRO_EVENTO_REQUEST,
		payload,
	};
}
export function cadastroEventoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRO_EVENTO_SUCCESS,
		payload,
	};
}
export function cadastroEventoFailure(payload: object): ReducerAction {
	return {
		type: Types.CADASTRO_EVENTO_FAILURE,
		payload,
	};
}
export function cadastroEventoClear(): ReducerAction {
	return {
		type: Types.CADASTRO_EVENTO_CLEAR,
		payload: null,
	};
}
