import { Types } from './types';

export function eixoRequest(token: string) {
	return {
		type: Types.EIXO_REQUEST,
		token,
	};
}

export function eixoSuccess(payload: object) {
	return {
		type: Types.EIXO_SUCCESS,
		payload,
	};
}

export function eixoFailure(payload: boolean) {
	return {
		type: Types.EIXO_FAILURE,
		payload,
	};
}
