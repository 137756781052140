import { ReducerAction } from 'store/modules/types';
import { Types, RequestConsultaCEP } from './types';

export function consultaCEPRequest(data: RequestConsultaCEP): ReducerAction {
	return {
		type: Types.CONSULTA_CEP_REQUEST,
		payload: data,
	};
}

export function consultaCEPSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_CEP_SUCCESS,
		payload,
	};
}

export function consultaCEPFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_CEP_FAILURE,
		payload,
	};
}

export function consultaCEPClear(): ReducerAction {
	return {
		type: Types.CONSULTA_CEP_CLEAR,
		payload: null,
	};
}
