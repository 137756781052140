import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ReducerAction } from 'store/modules/types';
import { ApiResponse, deleteApi } from 'services/rotasp/api';
import { PATH_EDUCACAO_SEDUC_RESPONSAVEIS_API } from 'services/rotasp/path';
import { RemoverResponsavelData, Types } from './types';

import {
	removerResponsavelSuccess,
	removerResponsavelFailure,
} from './actions';

function* removerResponsavelRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: RemoverResponsavelData } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		token || '',
		PATH_EDUCACAO_SEDUC_RESPONSAVEIS_API,
		`responsaveis`,
		payload,
	);
	if (response.status === 200) {
		yield put(removerResponsavelSuccess(response));
	} else {
		yield put(removerResponsavelFailure(response.message));
	}
}

export default all([
	takeLatest(Types.REMOVER_RESPONSAVEL_REQUEST, removerResponsavelRequest),
]);
