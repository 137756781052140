import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_JALES } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestInfracoes } from './types';

// ACTIONS
import { consultaInfracoesSuccess, consultaInfracoesFailure } from './actions';

function* consultaInfracoes(request: ReducerAction) {
	const { payload }: { payload: IRequestInfracoes } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_JALES,
		`transito/${CHANNEL}/infracoes`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaInfracoesSuccess(response));
	} else if (response.status === 204) {
		yield put(
			consultaInfracoesFailure({
				mensagem: 'Não há resultados para os parâmetros informados! ',
			}),
		);
	}
}

export default all([takeLatest(Types.CONSULTA_INFRACOES, consultaInfracoes)]);
