import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_RFB } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, EmitirCinRequest } from './types';

// ACTIONS
import { emitirCinSuccess, emitirCinFailure } from './actions';

function* emitirCinRequest(request: ReducerAction) {
	const { payload }: { payload: EmitirCinRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_RFB,
		`cin/${CHANNEL}/emitir`,
		payload,
		undefined,
		true,
	);

	if (response.status === 200) {
		yield put(emitirCinSuccess(response.data));
	} else {
		yield put(emitirCinFailure(response));
	}
}

export default all([takeLatest(Types.EMITIR_CIN_REQUEST, emitirCinRequest)]);
