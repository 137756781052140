import React, { ReactNode, useCallback, useEffect } from 'react';

import {
	RouteProps as ReactDOMRouteProps,
	Route as ReactDOMRoute,
	Redirect,
	useLocation,
	useHistory,
} from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { setBreadcrumb } from 'store/modules/app/breadcrumb/actions';
import { IBreadcrumb } from 'store/modules/app/breadcrumb/types';

import { logOffAndReloadRequest } from 'store/modules/api/sgu/loginUnico/actions';
import packageJson from '../../package.json';

interface Props extends ReactDOMRouteProps {
	isAuthenticated?: boolean;
	name?: string;
	breadcrumb?: IBreadcrumb[];
	component: React.ComponentType;
}

const Route: React.FC<Props> = ({
	isAuthenticated = false,
	breadcrumb,
	component: Component,
	...rest
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	useEffect(() => {
		if (breadcrumb) {
			dispatch(setBreadcrumb(breadcrumb, true));
			return;
		}

		dispatch(setBreadcrumb(null, false));
	}, [breadcrumb, dispatch]);

	// BUILD UPDATE
	const currentVersionDate = packageJson.buildDate;

	const locationURL = useLocation();

	useEffect(() => {
		async function fetechMetaJson() {
			const metaRoute = '/meta.json';

			const myHeaders = new Headers();

			myHeaders.append('cache', 'no-store');
			myHeaders.append('cache-control', 'no-cache');
			myHeaders.append('pragma', 'no-cache');

			if ('caches' in window) {
				// Service worker cache should be cleared with caches.delete()
				caches.keys().then(names => {
					// Delete all the cache files
					names.forEach(name => {
						caches.delete(name);
					});
				});
			}

			const myInit = {
				method: 'GET',
				headers: myHeaders,
			};
			const response = await fetch(metaRoute, myInit)
				.then(rs => rs.json())
				.then(meta => {
					return meta;
				});

			const latestVersionDate = response.buildDate;

			if (latestVersionDate > currentVersionDate) {
				if ('caches' in window) {
					// Service worker cache should be cleared with caches.delete()
					caches.keys().then(names => {
						// Delete all the cache files
						names.forEach(name => {
							caches.delete(name);
						});
					});
				}
				// delete browser cache and hard reload
				// localStorage.clear();
				dispatch(logOffAndReloadRequest());
			}
		}
		fetechMetaJson();
	}, [currentVersionDate, dispatch, history, locationURL]);
	// BUILD UPDATE ENDING

	const handleRoute = useCallback(
		(location): ReactNode => {
			if (isAuthenticated === !!user?.cpf && !!Component) {
				return <Component />;
			}
			if (isAuthenticated === !!user?.cpf && Component) {
				dispatch(logOffAndReloadRequest());
				return (
					<Redirect
						to={{
							pathname: '/',
							state: { from: location },
						}}
					/>
				);
			}
			return (
				<Redirect
					to={{
						pathname: isAuthenticated ? '/' : '/menu-orgaos',
						state: { from: location },
					}}
				/>
			);
		},
		[Component, dispatch, isAuthenticated, user],
	);

	return (
		<ReactDOMRoute
			{...rest}
			render={({ location }) => {
				const reactNodeReturn: ReactNode = handleRoute(location);
				return reactNodeReturn;
				// return isAuthenticated === !!user?.cpf ? (
				// 	<Component />
				// ) : (
				// 	<Redirect
				// 		to={{
				// 			pathname: isAuthenticated ? '/' : '/menu-orgaos',
				// 			state: { from: location },
				// 		}}
				// 	/>
				// );
			}}
		/>
	);
};

export default Route;
