import React from 'react';

// ANTD
import { Col, Row } from 'antd';

// COMPONENTS
import TypographyCommon from 'components/Common/Typography';

// PATHS
import Section from 'components/Common/Section';

// REDUX
import { ConsultaExtratoResumoCondutorData } from 'store/modules/api/detranCnh/eportal/consultaExtratoResumoCondutor/types';

// UTILS
import { isFilledValue } from 'utils/stringFunctions';

interface Props {
	consultaExtratoResumoCondutor:
		| ConsultaExtratoResumoCondutorData['pcon']['restricaoMedica']
		| null
		| undefined;
}

const Restricoes: React.FC<Props> = ({
	consultaExtratoResumoCondutor = null,
}) => {
	return (
		<>
			<Section title="Restrições (PCON)">
				<Row gutter={[0, 0]} align="top">
					<Col span={24}>
						<TypographyCommon
							text={isFilledValue(consultaExtratoResumoCondutor)}
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
				</Row>
			</Section>
		</>
	);
};
export default Restricoes;
