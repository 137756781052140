import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types } from './types';
import {
	consultaCombustivelSuccess,
	consultaCombustivelFailure,
} from './actions';

function* consultaCombustivelRequest() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consulta-combustivel`,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultaCombustivelSuccess(response));
	} else if (response.error) {
		yield put(consultaCombustivelFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTA_COMBUSTIVEL_REQUEST, consultaCombustivelRequest),
]);
