export enum Types {
	EVENTO_INICIO_SESSAO_REQUEST = '@cnh/EVENTO_INICIO_REQUEST',
	EVENTO_INICIO_SESSAO_SUCCESS = '@cnh/EVENTO_INICIO_SUCCESS',
	EVENTO_INICIO_SESSAO_FAILURE = '@cnh/EVENTO_INICIO_FAILURE',
	EVENTO_INICIO_SESSAO_CLEAR = '@cnh/EVENTO_INICIO_CLEAR',
}

export interface EventoInicioSessao {
	status: number;
	data: null;
	detailsStartSection: EventoInicioSessaoDetalhes | null;
}

export interface EventoInicioSessaoDetalhes {
	dataInicio: string;
}

export interface EventoInicioSessaoRequest {
	dataPayload: {
		id_atendimento: string;
		id_cidadao: string;
		canal: {
			id: number;
			desc: string;
			localidade: {
				id: number;
				desc: string;
			};
		};
		atendente: {
			nome: string;
			id_atendente: string;
			id_posto: string;
			desc_posto: string;
		};
	};
	detailsStartSection: EventoInicioSessaoDetalhes;
}
