import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { useHistory } from 'react-router-dom';

// ANTD
import { Row, Col } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultaTipoRequest } from 'store/modules/api/detranCrv/enum/consultaTipo/actions';
import { consultaEspecieRequest } from 'store/modules/api/detranCrv/enum/consultaEspecie/actions';
import { consultaCarroceriaRequest } from 'store/modules/api/detranCrv/enum/consultaCarroceria/actions';
import { consultaCorRequest } from 'store/modules/api/detranCrv/enum/consultaCor/actions';
import { consultaCombustivelRequest } from 'store/modules/api/detranCrv/enum/consultaCombustivel/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCrv/enum/consultaCategoria/actions';
import { consultarMunicipioPorUfRequest } from 'store/modules/api/detranCrv/enum/consultarMunicipioPorUF/actions';
import { emitirTransferenciasMunicipiosEstadosRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirTransferenciasMunicipiosEstados/actions';
import { consultarVeiculoV110Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import { consultarVeiculoV120Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV120/actions';
import { tipoArquivoClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/tipoArquivoEmissao/actions';

// COMPONENTS COMMON
import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import UploadArquivo from 'pages/DetranCrv/components/pages/UploadArquivo';

// UTILS
import { fillCpfCnpj, completaZeroEsquerda } from 'utils/genericFunctions';
import getValidationsErrors from 'utils/getValidationsErrors';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

// ROUTES
import {
	ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_CONSULTAR,
	ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_RESULTADO,
} from 'pages/DetranCrv/routes/paths';

// COMPONENTS
import TaxaServico from 'pages/DetranCrv/components/pages/TaxaServico';
import { Formik, Form } from 'formik';
import DadosVeiculo from './DadosVeiculo';
import DadosProprietario from './DadosProprietario';
import Espelho from './Espelho';
import Gravames from './Gravames';

// FORM
import {
	IEmiteTransferenciaVeiculo,
	initialValues,
	schema,
	treatValues,
	treatValues2,
} from './form';

const EmitirTransferenciaMunicipioEstado: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const {
		consultaCategoria,
		consultaCarroceria,
		consultaCombustivel,
		consultaCor,
		consultaEspecie,
		consultarMunicipioPorUF,
		consultaTipo,
	} = useSelector((state: ApplicationState) => state.api.detranCrv.Enum);

	const {
		consultarVeiculoV110,
		consultarVeiculoV120,
		emitirTransferenciasMunicipiosEstados,
	} = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { consultarTaxaServico } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	const { salvarAtendimento } = atendimento;
	const { login } = loginUnico;
	const sguData: SguData = useMemo(() => {
		return {
			salvarAtendimento,
			login,
		};
	}, [login, salvarAtendimento]);
	const evento = useMemo(() => {
		const proprietario =
			consultarVeiculoV110.data?.caracteristicaVeiculo.proprietario;
		const ident =
			(proprietario &&
				fillCpfCnpj(
					proprietario.identificacao,
					proprietario.cpfCnpjProprietario,
				)) ||
			'0';
		return getEventoConsultar(sguData, ident);
	}, [consultarVeiculoV110.data, sguData]);

	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (!consultarVeiculoV110.data && !consultarVeiculoV120.data) {
			history.push(ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_CONSULTAR);
		}
	}, [consultarVeiculoV110.data, consultarVeiculoV120.data, history]);

	useEffect(() => {
		if (emitirTransferenciasMunicipiosEstados.status === 200) {
			history.push(ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_RESULTADO);
		}
	}, [emitirTransferenciasMunicipiosEstados, history]);

	useEffect(() => {
		if (
			(consultarVeiculoV110.data || consultarVeiculoV120.data) &&
			consultaTipo.data.length > 0 &&
			consultaEspecie.data.length > 0 &&
			consultaCarroceria.data.length > 0 &&
			consultaCor.data.length > 0 &&
			consultaCombustivel.data.length > 0 &&
			consultarMunicipioPorUF.data?.length > 0 &&
			consultaCategoria.data.length > 0 &&
			consultarMunicipioPorUF.data.length > 0
		) {
			if (consultarVeiculoV110.data) {
				const {
					ufEspelhoAnterior,
					placa,
					renavam,
					chassi,
					marca,
					municipio,
					municipioPlacaAnterior,
					anoModelo,
					anoFabricacao,
					tipo,
					carroceria,
					cor,
					categoria,
					combustivel,
					especie,
					capacidadePassageiro,
					capacidadeCarga,
					potencia,
					cilindrada,
					cmt,
					pbt,
					eixos,
					ufPlacaAnterior,
					placaAnterior,
					procedencia,
					gravame,
					proprietario,
					tipoDocumentoLojaComprador,
					documentoLojaComprador,
					nomeLojaComprador,
					logradouroLojaComprador,
					numeroLojaComprador,
					complementoLojaComprador,
					bairroLojaComprador,
					municipioLojaComprador,
					cepLojaComprador,
				} = consultarVeiculoV110.data.caracteristicaVeiculo;

				// Espelho Anterior
				initialValues.ufEspelhoAnterior = ufEspelhoAnterior;

				// Dados do Veículo
				initialValues.placa = placa;
				initialValues.chassi = chassi ? chassi.trim() : '';
				initialValues.renavam = renavam.toString();
				initialValues.longIdMarca = marca.longId;
				initialValues.descricaoMarca = marca.descricao;

				initialValues.codigoMunicipio = municipio.codigo;
				initialValues.descricaoMunicipio = municipio.nome;

				initialValues.anoFabricacao = anoFabricacao;
				initialValues.anoModelo = anoModelo;

				initialValues.longIdTipo = tipo.longId;
				initialValues.descricaoTipo = tipo.descricao;

				initialValues.longIdCarroceria = carroceria.longId;
				initialValues.descricaoCarroceria = carroceria.descricao;

				initialValues.longIdCor = cor.longId;
				initialValues.descricaoCor = cor.descricao;

				initialValues.longIdCategoria = categoria.longId;
				initialValues.descricaoCategoria = categoria.descricao;

				initialValues.longIdCombustivel = combustivel.longId;
				initialValues.descricaoCombustivel = combustivel.descricao;

				initialValues.longIdEspecie = especie.longId;
				initialValues.descricaoEspecie = especie.descricao;

				initialValues.capacidadePassageiros = capacidadePassageiro;
				initialValues.capacidadeCarga = Intl.NumberFormat('en-IN', {
					style: 'decimal',
					minimumFractionDigits: 2,
				}).format(capacidadeCarga);

				initialValues.potencia = potencia;
				initialValues.cilindrada = cilindrada;
				initialValues.cmt = Intl.NumberFormat('en-IN', {
					style: 'decimal',
					minimumFractionDigits: 2,
				}).format(cmt);

				initialValues.pbt = Intl.NumberFormat('en-IN', {
					style: 'decimal',
					minimumFractionDigits: 2,
				}).format(pbt || 0);

				initialValues.eixos = eixos;

				initialValues.procedencia = procedencia;

				// Dados do Proprietário
				if (consultarVeiculoV110.data?.caracteristicaVeiculo?.indicadorRenave) {
					if (
						consultarVeiculoV110.data?.caracteristicaVeiculo
							?.indicadorRenave === 1 ||
						consultarVeiculoV110.data?.caracteristicaVeiculo
							?.indicadorRenave === 2 ||
						consultarVeiculoV110.data?.caracteristicaVeiculo
							?.indicadorRenave === 3
					) {
						initialValues.nomeProprietario =
							nomeLojaComprador?.trim().substring(0, 60) || '';

						initialValues.cepProprietario = completaZeroEsquerda(
							cepLojaComprador,
							8,
						);

						initialValues.bairroProprietario =
							bairroLojaComprador?.trim().substring(0, 70) || '';
						initialValues.identificacao =
							tipoDocumentoLojaComprador === 1
								? 'FISICA'
								: 'JURIDICA' || 'FISICA';
						initialValues.cpfCnpjProprietario = documentoLojaComprador
							? fillCpfCnpj(tipoDocumentoLojaComprador, documentoLojaComprador)
							: '';
						initialValues.enderecoProprietario =
							logradouroLojaComprador?.trim().substring(0, 70) || '';
						initialValues.numeroProprietario =
							String(numeroLojaComprador)?.trim().substring(0, 5) || '';
						initialValues.complementoProprietario =
							complementoLojaComprador?.trim().substring(0, 6) || '';

						if (municipioLojaComprador) {
							const selectedMunicipio = consultarMunicipioPorUF.data.filter(
								item => item.value === municipioLojaComprador,
							);

							initialValues.codigoMunicipioProprietario =
								selectedMunicipio[0]?.value;
							initialValues.descricaoMunicipioProprietario =
								selectedMunicipio[0]?.label;
						}

						initialValues.codigoMunicipioPlacaAnterior =
							municipioPlacaAnterior.codigo;
						initialValues.descricaoMunicipioPlacaAnterior =
							municipioPlacaAnterior.nome;

						initialValues.nomeProprietarioAnterior =
							proprietario.nomeProprietarioAnterior;
						initialValues.ufPlacaAnterior = ufPlacaAnterior;
						initialValues.placaAnterior = placaAnterior;
					}
				} else if (
					consultarVeiculoV110.data?.caracteristicaVeiculo?.comunicacaoVenda
				) {
					initialValues.nomeProprietario =
						proprietario.nomeProprietario?.trim().substring(0, 60) || '';
					initialValues.cepProprietario =
						proprietario.cepProprietario?.trim() || '';
					initialValues.bairroProprietario =
						proprietario.bairroProprietario?.trim().substring(0, 70) || '';
					initialValues.identificacao = proprietario.identificacao || 'FISICA';
					initialValues.cpfCnpjProprietario =
						fillCpfCnpj(
							proprietario.identificacao,
							proprietario.cpfCnpjProprietario,
						) || '';
					initialValues.enderecoProprietario =
						proprietario.enderecoProprietario?.trim().substring(0, 70) || '';
					initialValues.numeroProprietario =
						proprietario.numeroLogradouroProprietario?.trim().substring(0, 5) ||
						'';
					initialValues.complementoProprietario =
						proprietario.complementoLogradouroProprietario
							?.trim()
							.substring(0, 6) || '';

					if (proprietario.municipioProprietario) {
						const selectedMunicipio = consultarMunicipioPorUF.data.filter(
							item => item.value === Number(proprietario.municipioProprietario),
						);

						initialValues.codigoMunicipioProprietario =
							selectedMunicipio[0]?.value;
						initialValues.descricaoMunicipioProprietario =
							selectedMunicipio[0]?.label;
					}

					initialValues.codigoMunicipioPlacaAnterior =
						municipioPlacaAnterior.codigo;
					initialValues.descricaoMunicipioPlacaAnterior =
						municipioPlacaAnterior.nome;

					initialValues.nomeProprietarioAnterior =
						proprietario.nomeProprietarioAnterior;
					initialValues.placaAnterior = placaAnterior;
					initialValues.ufPlacaAnterior = ufPlacaAnterior;
				}

				// Gravame
				initialValues.arrendatarioFinanceira = gravame.arrendatarioFinanceira;
				initialValues.codigoFinanceira = gravame.codigoFinanceira;
				initialValues.numeroContrato = gravame.numeroContrato;
				initialValues.codigoFinanceira = gravame.codigoFinanceira;
				initialValues.restricao = gravame.restricao;

				// Taxa
				initialValues.taxaAutenticacaoDigital = '';
				initialValues.codBanco = consultarTaxaServico.data?.codBanco || '';
				initialValues.dataContabil =
					consultarTaxaServico.data?.dataContabil || '';
				initialValues.nsuProdesp = consultarTaxaServico.data?.nsuProdesp || '';

				setHasLoaded(true);
			} else if (consultarVeiculoV120.data) {
				const {
					ufEspelhoAnterior,
					placa,
					renavam,
					chassi,
					marca,
					municipioPlacaAnterior,
					anoModelo,
					anoFabricacao,
					tipo,
					carroceria,
					cor,
					categoria,
					combustivel,
					especie,
					capacidadePassageiro,
					capacidadeCarga,
					potencia,
					cilindrada,
					cmt,
					pbt,
					ufPlacaAnterior,
					placaAnterior,
					procedencia,
					eixo,
					dadosProprietario,
					gravame,
				} = consultarVeiculoV120.data.caracteristicaVeiculo;

				// Espelho Anterior
				initialValues.ufEspelhoAnterior = ufEspelhoAnterior;

				// Dados do Veículo
				initialValues.placa = placa;
				initialValues.chassi = chassi ? chassi.trim() : '';
				initialValues.renavam = renavam.toString();
				initialValues.longIdMarca = marca.longId;
				initialValues.descricaoMarca = marca.descricao;

				initialValues.anoFabricacao = anoFabricacao;
				initialValues.anoModelo = anoModelo;

				initialValues.longIdTipo = tipo.longId;
				initialValues.descricaoTipo = tipo.descricao;

				initialValues.longIdCarroceria = carroceria.longId;
				initialValues.descricaoCarroceria = carroceria.descricao;

				initialValues.longIdCor = cor.longId;
				initialValues.descricaoCor = cor.descricao;

				initialValues.longIdCategoria = categoria.longId;
				initialValues.descricaoCategoria = categoria.descricao;

				initialValues.longIdCombustivel = combustivel.longId;
				initialValues.descricaoCombustivel = combustivel.descricao;

				initialValues.longIdEspecie = especie.longId;
				initialValues.descricaoEspecie = especie.descricao;

				initialValues.capacidadePassageiros = capacidadePassageiro;
				initialValues.capacidadeCarga = Intl.NumberFormat('en-IN', {
					style: 'decimal',
					minimumFractionDigits: 1,
				}).format(capacidadeCarga);
				initialValues.potencia = potencia;
				initialValues.cilindrada = cilindrada;
				initialValues.cmt = Intl.NumberFormat('en-IN', {
					style: 'decimal',
					minimumFractionDigits: 1,
				}).format(cmt);

				initialValues.pbt = Intl.NumberFormat('en-IN', {
					style: 'decimal',
					minimumFractionDigits: 2,
				}).format(pbt || 0);

				initialValues.eixos = eixo;

				initialValues.procedencia = procedencia;

				// Dados do Proprietário
				initialValues.nomeProprietario =
					dadosProprietario.nomeProprietario?.trim().substring(0, 60) || '';
				initialValues.cepProprietario =
					dadosProprietario.cepProprietario?.trim() || '';
				initialValues.bairroProprietario =
					dadosProprietario.bairroProprietario?.trim().substring(0, 70) || '';
				initialValues.identificacao =
					!dadosProprietario?.identificacao ||
					(dadosProprietario?.identificacao &&
						dadosProprietario.identificacao === ' ')
						? 'FISICA'
						: dadosProprietario?.identificacao || '';
				initialValues.cpfCnpjProprietario = fillCpfCnpj(
					dadosProprietario.identificacao,
					dadosProprietario.cpfCnpjProprietario,
				).toString();
				initialValues.enderecoProprietario =
					dadosProprietario.enderecoProprietario?.trim().substring(0, 70) || '';
				initialValues.numeroProprietario =
					dadosProprietario.numeroLogradouroProprietario
						?.trim()
						.substring(0, 5) || '';
				initialValues.complementoProprietario =
					dadosProprietario.complementoLogradouroProprietario
						?.trim()
						.substring(0, 6) || '';

				if (dadosProprietario.municipioProprietario) {
					const selectedMunicipio = consultarMunicipioPorUF.data.filter(
						item => item.label === dadosProprietario.municipioProprietario,
					);

					initialValues.codigoMunicipioProprietario =
						selectedMunicipio[0]?.value;
					initialValues.descricaoMunicipioProprietario =
						selectedMunicipio[0]?.label;
				}

				initialValues.codigoMunicipioPlacaAnterior =
					municipioPlacaAnterior.codigo;
				initialValues.descricaoMunicipioPlacaAnterior =
					municipioPlacaAnterior.nome;

				initialValues.ufPlacaAnterior = ufPlacaAnterior;
				initialValues.placaAnterior = placaAnterior;
				initialValues.eixos = eixo;

				// Gravame
				initialValues.arrendatarioFinanceira = gravame.arrendatarioFinanceira;
				initialValues.codigoFinanceira = gravame.codigoFinanceira;
				initialValues.numeroContrato = gravame.numeroContrato;
				initialValues.codigoFinanceira = gravame.codigoFinanceira;
				initialValues.restricao = gravame.restricao;

				// Taxa
				initialValues.taxaAutenticacaoDigital = '';
				initialValues.codBanco = consultarTaxaServico.data?.codBanco || '';
				initialValues.dataContabil =
					consultarTaxaServico.data?.dataContabil || '';
				initialValues.nsuProdesp = consultarTaxaServico.data?.nsuProdesp || '';

				setHasLoaded(true);
			}
		}
	}, [
		consultarVeiculoV110.data,
		consultaTipo.data,
		consultaEspecie.data,
		consultaCarroceria.data,
		consultaCor.data,
		consultaCombustivel.data,
		consultaCategoria.data,
		consultarMunicipioPorUF.data,
		history,
		consultarMunicipioPorUF,
		consultarVeiculoV120.data,
		emitirTransferenciasMunicipiosEstados,
		consultarTaxaServico.data,
	]);

	// Enum: Municípios - SP.
	useEffect(() => {
		if (
			consultarMunicipioPorUF.data &&
			consultarMunicipioPorUF.data.length === 0
		) {
			dispatch(consultarMunicipioPorUfRequest('SP'));
		}
	}, [consultarMunicipioPorUF, dispatch]);

	// Enum: Municípios - Outrs estados.
	useEffect(() => {
		if (consultarVeiculoV120.data) {
			dispatch(
				consultarMunicipioPorUfRequest(
					consultarVeiculoV120.data.caracteristicaVeiculo.ufPlacaAnterior,
				),
			);
		}
	}, [consultarVeiculoV120.data, dispatch]);

	// Enum: Tipo de Veículo.
	useEffect(() => {
		if (consultaTipo.data.length === 0) {
			dispatch(consultaTipoRequest());
		}
	}, [consultaTipo, dispatch]);

	// Enum: Espécie.
	useEffect(() => {
		if (consultaEspecie.data.length === 0) {
			dispatch(consultaEspecieRequest());
		}
	}, [consultaEspecie, dispatch]);

	// Enum: Carroceria.
	useEffect(() => {
		if (consultaCarroceria.data.length === 0) {
			dispatch(consultaCarroceriaRequest());
		}
	}, [consultaCarroceria, dispatch]);

	// Enum: Cor.
	useEffect(() => {
		if (consultaCor.data.length === 0) {
			dispatch(consultaCorRequest());
		}
	}, [consultaCor, dispatch]);

	// Enum: Combustível.
	useEffect(() => {
		if (consultaCombustivel.data.length === 0) {
			dispatch(consultaCombustivelRequest());
		}
	}, [consultaCombustivel, dispatch]);

	// Enum: Categoria.
	useEffect(() => {
		if (consultaCategoria.data.length === 0) {
			dispatch(consultaCategoriaRequest());
		}
	}, [consultaCategoria, dispatch]);

	const handleValidate = useCallback(
		(formValues: IEmiteTransferenciaVeiculo) => {
			return getValidationsErrors(formValues, schema);
		},
		[],
	);

	const handleSubmit = useCallback(
		(formValues: IEmiteTransferenciaVeiculo) => {
			const headers = {
				codUnidade: evento.canal.localidade.id.toString(),
				cpfUsuario: evento.cpf,
			};
			if (consultarVeiculoV110.data) {
				const body = treatValues(formValues, consultarVeiculoV110.data, evento);

				dispatch(
					emitirTransferenciasMunicipiosEstadosRequest({ body, headers }),
				);
			} else if (consultarVeiculoV120.data) {
				const body = treatValues2(
					formValues,
					consultarVeiculoV120.data,
					evento,
				);

				dispatch(
					emitirTransferenciasMunicipiosEstadosRequest({ body, headers }),
				);
			}
		},
		[evento, consultarVeiculoV110.data, consultarVeiculoV120.data, dispatch],
	);

	const handleButtonCancelar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());

		dispatch(consultarVeiculoV120Clear());

		dispatch(tipoArquivoClear());

		history.push(ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_CONSULTAR);
	}, [dispatch, history]);

	const handleButtonVoltar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());

		dispatch(consultarVeiculoV120Clear());

		dispatch(tipoArquivoClear());

		history.push(ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_CONSULTAR);
	}, [dispatch, history]);

	return (
		<>
			{hasLoaded && (
				<>
					<Row>
						<Col span={24}>
							<Section size="lg" title="Transferência Município / Estado">
								<Formik
									validateOnChange={false}
									validateOnBlur={false}
									initialValues={initialValues}
									validate={handleValidate}
									onSubmit={handleSubmit}
								>
									{formik => (
										<Form autoComplete="off">
											<Espelho formik={formik} />

											<DadosVeiculo formik={formik} />

											<Gravames formik={formik} />

											<DadosProprietario formik={formik} isOpenDocument />

											<UploadArquivo
												formik={formik}
												evento={evento}
												tipoArquivo="3"
												sizeMB={10000000}
											/>

											{consultarTaxaServico.data && (
												<Section size="sm">
													<TaxaServico
														dataConsultarTaxaServico={consultarTaxaServico.data}
													/>
												</Section>
											)}

											<Row gutter={[0, 20]}>
												<Col span={24} />
											</Row>

											<Row justify="center">
												<Col>
													<Button onClick={handleButtonCancelar}>
														Cancelar
													</Button>
												</Col>

												<Col offset={1}>
													<Button type="submit">Enviar</Button>
												</Col>
											</Row>
										</Form>
									)}
								</Formik>
							</Section>

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default EmitirTransferenciaMunicipioEstado;
