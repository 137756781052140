import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import { Form, Formik, Field } from 'formik';
import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import {
	ROUTE_IIRGD_DARE_PESQUISA,
	ROUTE_IIRGD_CADASTRO,
	ROUTE_IIRGD_EDICAO,
	ROUTE_IIRGD_CONFERENCIA_DADOS,
} from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import {
	cadastroRgConfigRedicao,
	cadastroRgObsPrimeiraViaMenor,
} from 'store/modules/api/iirgd/cadastroRg/actions';
import { alterarObservacoesValuesTemp } from 'store/modules/api/iirgd/alterarObservacoes/actions';
import { consultaPesquisaObservacaoClear } from 'store/modules/api/iirgd/consultaPesquisaObservacao/actions';
import { dareClear } from 'store/modules/api/dare/actions';
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import FormBox from 'components/Common/Form/FormBox';
import CheckBox from 'components/Common/Form/Checkbox';
import Select from 'components/Common/Form/Select';
import InputMask from 'components/Common/Form/Input/InputMask';
import TextArea from 'components/Common/Form/Input/TextArea';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import { formatDate, onlyAlfaNumerico } from 'utils/genericFunctions';
import { RequestAlterarObservacoes } from 'store/modules/api/iirgd/alterarObservacoes/types';
import Input from 'components/Common/Form/Input/Input';
import RG from 'components/Common/Form/Fields/RG';
import { SCRIPT_POSSUI_RG_EM_OUTRO_ESTADO } from 'store/modules/api/iirgd/scriptPreAtendimento/types';
import { ConsultarProcesso } from 'store/modules/api/iirgd/integrador/consultarProcesso/types';
import { setFormValuesByKeys } from '../../../../utils/rgFicDigitalFunctions';

// FORM
import { initialValues, schema } from './form';

import {
	getObsValueRequest,
	IFormInclusaoObsPrimeiraViaMenor,
	threatDataCadastroRgObsPrimeiraViaMenor,
} from '../../form';

// COMPONENTS
import { Header } from '../../../Header';
import DocumentoResponsavel from '../../Componentes/DocumentoResponsavel';

// STYLED
import { SlashDivider } from './styled';

export const FormPrimeiraViaMenor: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [formData, setFormData] =
		useState<IFormInclusaoObsPrimeiraViaMenor | null>(null);

	const [menorDezesseis, setMenorDesesseis] = useState<boolean>(false);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { consultarProcesso } = useSelector(
		(state: ApplicationState) => state.api.iirgd.integrador,
	);

	const {
		alterarObservacoes,
		cadastroRg,
		consultaPesquisaObservacao,
		consultaPesquisaCirg,
		scriptPreAtendimento,
	} = useSelector((state: ApplicationState) => state.api.iirgd);

	const [checkedGuardaTutela, setCheckedGuardaTutela] =
		useState<boolean>(false);
	const [procEnabled, setProcEnabled] = useState<boolean>(false);

	const situacaoCin = (codigoSituacao: ConsultarProcesso[]) => {
		let situacao = false;
		codigoSituacao.forEach(item => {
			if (item.codigoSituacao === 3) {
				situacao = true;
			}
		});

		return situacao;
	};

	useEffect(() => {
		setFormData(oldForm => {
			if (oldForm) {
				return {
					...oldForm,
					isencaoPrimeiraViaCin:
						cadastroRg.form.principal?.dni === '10977' &&
						!situacaoCin(consultarProcesso.data || []),
				};
			}
			return null;
		});
	}, [cadastroRg.form.principal, consultarProcesso.data]);

	useEffect(() => {
		const formPrimeiraVia =
			alterarObservacoes.temp !== null
				? alterarObservacoes.temp
				: consultaPesquisaObservacao.result;
		const { menor } = cadastroRg.form.observacoes.primeiraVia;

		if (cadastroRg.info.idade < 16) {
			setMenorDesesseis(true);
		} else {
			setMenorDesesseis(false);
		}

		if (formPrimeiraVia) {
			const formDataTemp = setFormValuesByKeys(
				formPrimeiraVia,
			) as IFormInclusaoObsPrimeiraViaMenor;
			setFormData({
				...formDataTemp,
				dataExpedicaoDocumentoResponsavel:
					formDataTemp.dataExpedicaoDocumentoResponsavel
						? formatDate(formDataTemp.dataExpedicaoDocumentoResponsavel)
						: '',
				rgForaSpEmissao: formDataTemp.rgForaSpEmissao
					? formatDate(formDataTemp.rgForaSpEmissao)
					: '',
				tutelaData: formDataTemp.tutelaData
					? formatDate(formDataTemp.tutelaData)
					: '',
				serie:
					formDataTemp.tipoDocumentoResponsavel === 'CTPS' &&
					formDataTemp.numeroDocumentoResponsavel
						? formDataTemp.numeroDocumentoResponsavel.substring(
								formDataTemp.numeroDocumentoResponsavel.length - 4,
								formDataTemp.numeroDocumentoResponsavel.length,
						  )
						: formDataTemp.numeroDocumentoResponsavel,
				numeroDocumentoResponsavel:
					formDataTemp.tipoDocumentoResponsavel === 'CTPS' &&
					formDataTemp.numeroDocumentoResponsavel
						? formDataTemp.numeroDocumentoResponsavel.substring(
								0,
								formDataTemp.numeroDocumentoResponsavel.length - 4,
						  )
						: formDataTemp.numeroDocumentoResponsavel,
			});

			if (formPrimeiraVia?.termoTutela) {
				setCheckedGuardaTutela(true);
				setProcEnabled(true);
			}
		} else if (menor && !scriptPreAtendimento.preAtendimentoValido) {
			setFormData(
				setFormValuesByKeys(menor) as IFormInclusaoObsPrimeiraViaMenor,
			);
		} else if (menor && scriptPreAtendimento.preAtendimentoValido) {
			setFormData({
				...(setFormValuesByKeys(menor) as IFormInclusaoObsPrimeiraViaMenor),
				rgForaSp:
					scriptPreAtendimento.ondeMorava === SCRIPT_POSSUI_RG_EM_OUTRO_ESTADO,
				rgForaSpEmissao: scriptPreAtendimento.dataExpedicaoRg
					? scriptPreAtendimento.dataExpedicaoRg
					: '',
				rgForaSpNum: scriptPreAtendimento.numeroRg
					? scriptPreAtendimento.numeroRg
					: '',
				rgForaSpUf: scriptPreAtendimento.ufRg ? scriptPreAtendimento.ufRg : '',
			});
		} else if (scriptPreAtendimento.preAtendimentoValido) {
			setFormData({
				...initialValues,
				rgForaSp:
					scriptPreAtendimento.ondeMorava === SCRIPT_POSSUI_RG_EM_OUTRO_ESTADO,
				rgForaSpEmissao: scriptPreAtendimento.dataExpedicaoRg
					? scriptPreAtendimento.dataExpedicaoRg
					: '',
				rgForaSpNum: scriptPreAtendimento.numeroRg
					? scriptPreAtendimento.numeroRg
					: '',
				rgForaSpUf: scriptPreAtendimento.ufRg ? scriptPreAtendimento.ufRg : '',
			});
		} else {
			setFormData(initialValues);
		}
	}, [
		alterarObservacoes.temp,
		cadastroRg,
		consultaPesquisaObservacao,
		scriptPreAtendimento,
	]);

	const handleRedirecionamento = useCallback(() => {
		if (cadastroRg.form.principal) {
			const { entrega } = cadastroRg.form.principal;

			if (entrega === 'P' || (entrega === 'K' && cadastroRg.tipo.primeiraVia)) {
				history.push(ROUTE_IIRGD_CONFERENCIA_DADOS);
				return;
			}

			dispatch(dareClear());

			// Redirecionamento para DARE.
			// Irá gerar taxa de correios.
			history.push(ROUTE_IIRGD_DARE_PESQUISA);
		}
	}, [
		cadastroRg.form.principal,
		cadastroRg.tipo.primeiraVia,
		dispatch,
		history,
	]);

	const handleValidate = useCallback(
		values => {
			return getValidationsErrors(values, schema(menorDezesseis));
		},
		[menorDezesseis],
	);

	const handleSubmit = useCallback(
		formValues => {
			dispatch(clearNotifications());

			if (cadastroRg.form.principal) {
				const values: RequestAlterarObservacoes = getObsValueRequest(
					formValues,
					cadastroRg,
					login,
					consultaPesquisaCirg.data?.lote
						? consultaPesquisaCirg.data?.lote
						: '',
					cadastroRg.form.principal?.dni === '10977' &&
						!situacaoCin(consultarProcesso.data || []),
					cadastroRg.form.principal?.dni === '10977' &&
						situacaoCin(consultarProcesso.data || []),
				);

				const dataCadastroRgObsPrimeiraViaMenor =
					threatDataCadastroRgObsPrimeiraViaMenor({
						...formValues,
						isencaoPrimeiraViaCin:
							cadastroRg.form.principal?.dni === '10977' &&
							!situacaoCin(consultarProcesso.data || []),
					});

				dispatch(alterarObservacoesValuesTemp(values));
				dispatch(
					cadastroRgObsPrimeiraViaMenor(dataCadastroRgObsPrimeiraViaMenor),
				);

				handleRedirecionamento();
			}
		},
		[
			dispatch,
			cadastroRg,
			login,
			consultaPesquisaCirg.data,
			consultarProcesso.data,
			handleRedirecionamento,
		],
	);

	const handleButtonVoltar = useCallback(() => {
		dispatch(clearNotifications());
		dispatch(consultaPesquisaObservacaoClear());
		dispatch(cadastroRgConfigRedicao());

		if (cadastroRg.config.novo) {
			history.push(ROUTE_IIRGD_CADASTRO);
			return;
		}

		history.push(ROUTE_IIRGD_EDICAO);
	}, [dispatch, cadastroRg.config, history]);

	return (
		<>
			{formData && (
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={formData}
					validate={handleValidate}
					onSubmit={handleSubmit}
					enableReinitialize
				>
					{formik => (
						<Form autoComplete="off">
							<Section title="Inclusão de Observações">
								<Header />

								<FormBox title="Declarações do Cidadão">
									<Row gutter={[0, 10]}>
										<Col>
											<Field
												as={CheckBox}
												name="rgForaSp"
												subtitle="Não possui Carteira de Identidade no Estado de São Paulo"
												checked={formik.values.rgForaSp}
												error={!!formik.errors.rgForaSp}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24} />
									</Row>

									<Section title="Outros Documentos" size="sm">
										<Row>
											<Col span={8}>
												<Field
													as={Select}
													title="Carteira de Identidade no Estado de"
													name="rgForaSpUf"
													id="rgForaSpUf"
													options={data.uf}
													onChange={(uf: string) =>
														formik.setFieldValue('rgForaSpUf', uf)
													}
													required={
														formik.values.rgForaSpEmissao ||
														formik.values.rgForaSpNum
													}
													error={hasError(formik.errors, 'rgUf')}
												/>
											</Col>

											{formik.values.rgForaSpUf === 'SP' ? (
												<Col span={7} offset={1}>
													<RG
														title="Número Carteira de Identidade"
														numero="rgForaSpNum"
														formik={formik}
														result={res => {
															formik.setFieldValue('rgForaSpNum', res.numeroRg);
														}}
														defaultNumero={formik.values.rgForaSpNum}
														required={
															!!formik.values.rgForaSpEmissao ||
															!!formik.values.rgForaSpUf
														}
														error={hasError(formik.errors, 'rgForaSpNum')}
													/>
												</Col>
											) : (
												<Col span={7} offset={1}>
													<Field
														as={Input}
														title="Número Carteira de Identidade"
														name="rgForaSpNum"
														maxLength="15"
														value={onlyAlfaNumerico(formik.values.rgForaSpNum)}
														defaultValue={formik.values.rgForaSpNum}
														required={
															formik.values.rgForaSpEmissao ||
															formik.values.rgForaSpUf
														}
														error={hasError(formik.errors, 'rgForaSpNum')}
													/>
												</Col>
											)}

											<Col offset={1} span={7}>
												<Field
													as={ValidDataInput}
													title="Expedido em"
													name="rgForaSpEmissao"
													mask="99/99/9999"
													type="text"
													formik={formik}
													onChange={(v: string) =>
														formik.setFieldValue('rgForaSpEmissao', v)
													}
													required={
														formik.values.rgForaSpNum ||
														formik.values.rgForaSpUf
													}
													error={hasError(formik.errors, 'rgEmi')}
												/>
											</Col>
										</Row>
									</Section>

									<Row gutter={[0, 20]}>
										<Col span={24} />
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24}>
											<Field
												as={TextArea}
												title="Observações"
												titleSize="sm"
												name="observacao"
												value={
													formik.values.observacao
														? formik.values.observacao
														: ''
												}
												maxLength={338}
												error={!!formik.errors.observacao}
											/>
										</Col>
									</Row>
								</FormBox>

								<Row gutter={[0, 10]}>
									<Col span={24} />
								</Row>

								{menorDezesseis && <DocumentoResponsavel formik={formik} />}

								<Row gutter={[0, 10]}>
									<Col span={24} />
								</Row>

								<FormBox title="Complementação do IIRGD">
									<Row gutter={[0, 10]}>
										<Col span={11}>
											<Field
												as={CheckBox}
												name="termoTutela"
												subtitle="Termo de Guarda/Tutela/Conselho"
												checked={
													formik.values.termoTutela || checkedGuardaTutela
												}
												onChange={(change: ChangeEvent<HTMLInputElement>) => {
													const { checked } = change.target;

													formik.setFieldValue('termoTutela', checked);

													setCheckedGuardaTutela(checked);
													setProcEnabled(true);

													if (!checked) {
														formik.setFieldValue('tutelaProc', '');
														formik.setFieldValue('tutelaAno', '');
														formik.setFieldValue('tutelaData', '');

														setProcEnabled(false);
													}
												}}
												error={!!formik.errors.termoTutela}
											/>
										</Col>

										<Col span={5}>
											<Field
												as={InputMask}
												title="Proc."
												titleSize={50}
												maskChar=""
												name="tutelaProc"
												mask="9999999999"
												required={procEnabled}
												disabled={!procEnabled}
												error={hasError(formik.errors, 'proc')}
											/>
										</Col>

										<SlashDivider>
											<div>/</div>
										</SlashDivider>

										<Col span={3}>
											<Field
												as={InputMask}
												name="tutelaAno"
												maskChar=""
												mask="9999"
												required={procEnabled}
												disabled={!procEnabled}
												error={hasError(formik.errors, 'proc')}
											/>
										</Col>

										<Col span={4}>
											<Field
												as={ValidDataInput}
												title="de"
												titleSize={30}
												titleAlign="end"
												name="tutelaData"
												type="text"
												mask="99/99/9999"
												maskChar=""
												required={procEnabled}
												disabled={!procEnabled}
												formik={formik}
												error={
													hasError(formik.errors, 'proc') ||
													hasError(formik.errors, 'validaDataProcesso')
												}
											/>
										</Col>
									</Row>

									{cadastroRg.form.principal?.dni === '10977' && (
										<Row gutter={[0, 10]}>
											<Col span={11}>
												<Field
													as={CheckBox}
													name="isencaoPrimeiraViaCin"
													subtitle="Isenção da Taxa da CIN"
													disabled
													checked={
														cadastroRg.form.principal?.dni === '10977' &&
														!situacaoCin(consultarProcesso.data || [])
													}
													onChange={(change: ChangeEvent<HTMLInputElement>) => {
														const { checked } = change.target;

														formik.setFieldValue(
															'isencaoPrimeiraViaCin',
															checked,
														);
													}}
													error={!!formik.errors.termoTutela}
												/>
											</Col>
										</Row>
									)}
									{/* <Row gutter={[0, 10]}>
										<Col span={11}>
											<Field
												as={CheckBox}
												name="temCin"
												subtitle="Tem CIN?"
												disabled
												checked={cadastroRg.form.principal?.dni === '10977'}
												onChange={(change: ChangeEvent<HTMLInputElement>) => {
													const { checked } = change.target;

													formik.setFieldValue('temCin', checked);
												}}
												error={!!formik.errors.temCin}
											/>
										</Col>
									</Row> */}
								</FormBox>

								<Row gutter={[0, 10]}>
									<Col span={24} />
								</Row>

								<Row gutter={[0, 30]}>
									<Col span={24} />
								</Row>

								<Row gutter={[0, 10]} justify="center">
									<Col>
										<ButtonImage type="submit" src="avancar" />
									</Col>
								</Row>
							</Section>

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};
