export enum Types {
	EVENTO_CONFRONTO_SESSAO_REQUEST = '@cnh/EVENTO_CONFRONTO_SESSAO_REQUEST',
	EVENTO_CONFRONTO_SESSAO_SUCCESS = '@cnh/EVENTO_CONFRONTO_SESSAO_SUCCESS',
	EVENTO_CONFRONTO_SESSAO_FAILURE = '@cnh/EVENTO_CONFRONTO_SESSAO_FAILURE',
	EVENTO_CONFRONTO_SESSAO_CLEAR = '@cnh/EVENTO_CONFRONTO_SESSAO_CLEAR',
}

export interface EventoConfrontoSessao {
	status: number;
	data: null;
}

export interface EventoConfrontoSessaoRequest {
	id_atendimento: string;
	id_cidadao: string;
	canal: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
	atendente: {
		nome: string;
		id_atendente: string;
		id_posto: string;
		desc_posto: string;
	};
	reuso: {
		confronto_realizado: boolean;
		dedos: string;
	};
}
