import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { PATH_DETRAN_CRV, CHANNEL } from 'services/_path';
import { Types } from './types';
import {
	ConsultarNumeroMotorBinSuccess,
	ConsultarNumeroMotorBinFailure,
} from './actions';

function* ConsultarNumeroMotorBinRequest({ payload }: any) {
	const tipo = payload.selecao;

	delete payload.selecao;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consulta-motor-bin/${tipo}`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(ConsultarNumeroMotorBinSuccess(response));
	} else if (response.error) {
		yield put(ConsultarNumeroMotorBinFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_NUMERO_MOTOR_BIN_REQUEST,
		ConsultarNumeroMotorBinRequest,
	),
]);
