import React, { useCallback } from 'react';

// ICONS
import { LeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { FcSearch } from 'react-icons/fc';
import {
	FaEraser,
	FaPlusCircle,
	FaSave,
	FaArrowLeft,
	FaArrowRight,
	FaPaperclip,
	FaThumbsUp,
	FaThumbsDown,
	FaPrint,
	FaEdit,
	FaCheck,
	FaPlus,
	FaMinus,
} from 'react-icons/fa';

interface Props {
	buttonIcon:
		| 'search'
		| 'erase'
		| 'add'
		| 'save'
		| 'back'
		| 'next'
		| 'return'
		| 'clip'
		| 'like'
		| 'unlike'
		| 'refresh'
		| 'print'
		| 'edit'
		| 'plus'
		| 'minus'
		| 'check';
}

const Icon: React.FC<Props> = ({ buttonIcon }) => {
	const handleIconRender = useCallback(() => {
		if (buttonIcon) {
			switch (buttonIcon) {
				case 'search':
					return <FcSearch />;
				case 'erase':
					return <FaEraser />;
				case 'add':
					return <FaPlusCircle />;
				case 'save':
					return <FaSave />;
				case 'back':
					return <FaArrowLeft />;
				case 'next':
					return <FaArrowRight />;
				case 'return':
					return <LeftOutlined />;
				case 'clip':
					return <FaPaperclip />;
				case 'like':
					return <FaThumbsUp />;
				case 'unlike':
					return <FaThumbsDown />;
				case 'refresh':
					return <ReloadOutlined />;
				case 'print':
					return <FaPrint />;
				case 'edit':
					return <FaEdit />;
				case 'check':
					return <FaCheck />;
				case 'plus':
					return <FaPlus />;
				case 'minus':
					return <FaMinus />;
				default:
					break;
			}
		}

		return '';
	}, [buttonIcon]);

	return <>{handleIconRender()}</>;
};

export default Icon;
