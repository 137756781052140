import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarMenusRequest } from './types';

export function atualizarMenusRequest(
	data: AtualizarMenusRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_MENUS,
		payload: data,
	};
}
export function atualizarMenusSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_MENUS_SUCCESS,
		payload,
	};
}
export function atualizarMenusFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_MENUS_FAILURE,
		payload: null,
	};
}
export function atualizarMenusClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_MENUS_CLEAR,
		payload: null,
	};
}
