import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { DisponibilzarDare, Types } from './types';

export const INITIAL_STATE: DisponibilzarDare = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): DisponibilzarDare {
	return produce(state, draft => {
		switch (action.type) {
			case Types.DISPONIBILIZAR_DARE_REQUEST: {
				break;
			}

			case Types.DISPONIBILIZAR_DARE_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.DISPONIBILIZAR_DARE_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.DISPONIBILIZAR_DARE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;

				break;
			}

			default:
		}
		return draft;
	});
}
