import { ReducerAction } from 'store/modules/types';

import {
	Types,
	IRequestConsultaPermissaoInternacionalAutomatizado,
} from './types';

export function consultaPermissaoInternacionalAutomatizadoRequest(
	data: IRequestConsultaPermissaoInternacionalAutomatizado,
): ReducerAction {
	return {
		type: Types.CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO,
		payload: data,
	};
}
export function consultaPermissaoInternacionalAutomatizadoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_SUCCESS,
		payload,
	};
}
export function consultaPermissaoInternacionalAutomatizadoFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_FAILURE,
		payload,
	};
}
