import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_LOCALIDADES_ASSOCIADA_REQUEST = '@SGU/CONSULTAR_LOCALIDADES_ASSOCIADA_REQUEST',
	CONSULTAR_LOCALIDADES_ASSOCIADA_SUCCESS = '@SGU/CONSULTAR_LOCALIDADES_ASSOCIADA_SUCCESS',
	CONSULTAR_LOCALIDADES_ASSOCIADA_FAILURE = '@SGU/CONSULTAR_LOCALIDADES_ASSOCIADA_FAILURE',
	CONSULTAR_LOCALIDADES_ASSOCIADA_CLEAR = '@SGU/CONSULTAR_LOCALIDADES_ASSOCIADA_CLEAR',
}

export interface ConsultarLocalidadesAssociada {
	status: number;
	data: LocalidadesAssociadaData | null;
	enum: OptionProps[];
}

export interface LocalidadesAssociadaData {
	id: number;
	codigo: number;
	digito: number;
	nome: string;
	orgao: ChildrenObject;
	unidade: ChildrenObject;
	tipo: ChildrenObject;
	funcionalidades: ChildrenObject[];
}

export interface ChildrenObject {
	id: number;
	descricao: string;
}

export interface ConsultarLocalidadesAssociadasRequest {
	id: number | string;
	type?: 'edit' | 'delete' | 'show';
}
