import { ReducerAction } from 'store/modules/types';
import { Types, AtualizarContatoRequest } from './types';

export function atualizarContatoRequest(
	payload: AtualizarContatoRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_CONTRATO_REQUEST,
		payload,
	};
}
export function atualizarContatoSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_CONTRATO_SUCCESS,
		payload,
	};
}
export function atualizarContatoFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_CONTRATO_FAILURE,
		payload: null,
	};
}

export function limparAtualizarContato(): any {
	return {
		type: Types.LIMPAR_ATUALIZAR_CONTRATO,
	};
}
