import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarUsuarioRequest } from './types';

export function atualizarUsuarioRequest(
	data: AtualizarUsuarioRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_USUARIO_REQUEST,
		payload: data,
	};
}
export function atualizarUsuarioSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_USUARIO_SUCCESS,
		payload,
	};
}
export function atualizarUsuarioFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_USUARIO_FAILURE,
		payload: null,
	};
}
export function atualizarUsuarioClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_USUARIO_CLEAR,
		payload: null,
	};
}
