import { ReducerAction } from 'store/modules/types';

import { GerarRenachSemPcadRequest, Types } from './types';

export function gerarRenachSemPcadRequest(
	payload: GerarRenachSemPcadRequest,
): ReducerAction {
	return {
		type: Types.GERAR_RENACH_SEM_PCAD_REQUEST,
		payload,
	};
}
export function gerarRenachSemPcadSuccess(payload: object): ReducerAction {
	return {
		type: Types.GERAR_RENACH_SEM_PCAD_SUCCESS,
		payload,
	};
}
export function gerarRenachSemPcadFailure(payload: boolean): ReducerAction {
	return {
		type: Types.GERAR_RENACH_SEM_PCAD_FAILURE,
		payload,
	};
}
