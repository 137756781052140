import { combineReducers } from 'redux';

import breadcrumb from './breadcrumb/reducer';
import loader from './loader/reducer';
import notifications from './notifications/reducer';
import theme from './theme/reducer';
import authToken from './authToken/reducer';

export default combineReducers({
	breadcrumb,
	loader,
	notifications,
	theme,
	authToken,
});
