import { ReducerAction } from 'store/modules/types';

import { Types, IPGATRequest } from './types';

export function consultarPGATRequest(data: IPGATRequest): ReducerAction {
	return {
		type: Types.PGAT_REQUEST,
		payload: data,
	};
}

export function consultarPGATSuccess(payload: object) {
	return {
		type: Types.PGAT_SUCCESS,
		payload,
	};
}

export function consultarPGATFailure(payload: object): ReducerAction {
	return {
		type: Types.PGAT_FAILURE,
		payload,
	};
}

export function consultarPGATClear(): any {
	return {
		type: Types.PGAT_CLEAR,
	};
}
