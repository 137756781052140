import produce from 'immer';

import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, IncluirIntencaoVenda } from './types';

export const INITIAL_STATE: IncluirIntencaoVenda = {
	status: 0,
	data: null,
	dataError: null,
};

export default function debitosRestricoesVeiculo(
	state = INITIAL_STATE,
	action: ReducerActionRotaSP,
): IncluirIntencaoVenda {
	return produce(state, draft => {
		switch (action.type) {
			case Types.INCLUIR_INTENCAO_VENDA_REQUEST: {
				draft.status = 100;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;

				break;
			}

			case Types.INCLUIR_INTENCAO_VENDA_SUCCESS: {
				draft.status = action.payload?.status ?? 200;
				draft.data = action.payload?.data;
				draft.dataError = INITIAL_STATE.dataError;

				break;
			}

			case Types.INCLUIR_INTENCAO_VENDA_FAILURE: {
				draft.status = action.payload?.data?.status ?? 400;
				draft.dataError = action.payload?.data;
				draft.data = INITIAL_STATE.data;

				break;
			}

			case Types.INCLUIR_INTENCAO_VENDA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;

				break;
			}

			default:
		}

		return draft;
	});
}
