export enum Types {
	CONSULTAR_CIN_CPF_REQUEST = '@rfb/CONSULTAR_CIN_CPF_REQUEST',
	CONSULTAR_CIN_CPF_SUCCESS = '@rfb/CONSULTAR_CIN_CPF_SUCCESS',
	CONSULTAR_CIN_CPF_FAILURE = '@rfb/CONSULTAR_CIN_CPF_FAILURE',
	CONSULTAR_CIN_CPF_CLEAR = '@rfb/CONSULTAR_CIN_CPF_CLEAR',
}

export interface ConsultarCpfCinState {
	status: number;
	data: ConsultarCpfCin | null;
	dataError: ConsultarCpfCinDataError | null;
}

export interface ConsultarCpfCin {
	cpfId: string;
	nomeContribuinte: string;
	nomeSocial: string;
	codSitCad: string;
	indResExt: string;
	codPaisRes: string;
	nomePaisRes: string;
	nomeMae: string;
	dtNasc: string;
	codSexo: string;
	codNatOcup: string;
	codOcup: string;
	anoExercicio: string;
	tipoLogradouro: string;
	logradouro: string;
	nroLogradouro: string;
	complemento: string;
	bairro: string;
	codMunDomic: string;
	ufMunDomic: string;
	cep: string;
	telefone: string;
	email: string;
	anoObito: string;
	codMunNat: string;
	ufMunNat: string;
	codPaisNac: string;
	nomePaisNasc: string;
	codUA: string;
	indEstrangeiro: string;
	dtInscricao: string;
	dtUltAtualiz: string;
}

export interface ConsultarCpfCinDataError {
	transacao: string;
	codigo: number;
	mensagem: string;
	mensagens: string[];
}
