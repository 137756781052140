import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultaBaseOutrosEstados } from './types';

export function consultarBaseOutrosEstadosRequest(
	data: IRequestConsultaBaseOutrosEstados,
): ReducerAction {
	return {
		type: Types.CONSULTAR_BASE_OUTROS_ESTADOS_REQUEST,
		payload: data,
	};
}
export function consultarBaseOutrosEstadosSuccess(payload: object) {
	return {
		type: Types.CONSULTAR_BASE_OUTROS_ESTADOS_SUCCESS,
		payload,
	};
}
export function consultarBaseOutrosEstadosFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_BASE_OUTROS_ESTADOS_FAILURE,
		payload: null,
	};
}
export function consultarBaseOutrosEstadosClear(): any {
	return {
		type: Types.CONSULTAR_BASE_OUTROS_ESTADOS_CLEAR,
	};
}
