import { ReducerAction } from 'store/modules/types';

import { ConsultarCadastroCidadaoRequest, Types } from './types';

export function consultarCadastroCidadaoRequest(
	payload: ConsultarCadastroCidadaoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_CADASTRO_CIDADAO_REQUEST,
		payload,
	};
}
export function consultarCadastroCidadaoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_CADASTRO_CIDADAO_SUCCESS,
		payload,
	};
}
export function consultarCadastroCidadaoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_CADASTRO_CIDADAO_FAILURE,
		payload,
	};
}
export function consultarCadastroCidadaoClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_CADASTRO_CIDADAO_CLEAR,
		payload: null,
	};
}
