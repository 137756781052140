import { ApiResponse } from 'services/_api';
import { Types } from './types';

export function enviaSolicitacaoMPRequest(payload: any) {
	return {
		type: Types.ENVIA_SOLICITACAO_MP_REQUEST,
		payload,
	};
}
export function enviaSolicitacaoMPSuccess(payload: ApiResponse) {
	return {
		type: Types.ENVIA_SOLICITACAO_MP_SUCCESS,
		payload,
	};
}
export function enviaSolicitacaoMPFailure(payload: []): any {
	return {
		type: Types.ENVIA_SOLICITACAO_MP_FAILURE,
		payload,
	};
}
export function LimparSolicitacaoMP() {
	return {
		type: Types.LIMPAR_SOLICITACAO_MP,
	};
}
