import produce from 'immer';
import { toSelect } from 'utils/genericFunctions';

import { Types, listaPaises } from './types';
import { ReducerAction } from '../../../../types';

// UTILS

export const INITIAL_STATE: listaPaises = {
	status: 0,
	data: null,
};

export default function listaPaisesReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): listaPaises {
	return produce(state, draft => {
		switch (action.type) {
			case Types.LISTA_PAISES: {
				break;
			}
			case Types.LISTA_PAISES_SUCCESS: {
				const lista = toSelect(action.payload.data.paises, 'nome', 'codigo');

				draft.data = [...lista];
				break;
			}
			case Types.LISTA_PAISES_FAILURE: {
				break;
			}

			default:
		}
	});
}
