// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaAtendimentoPjRequest, Types } from './types';

export function consultaAtendimentoPjRequest(
	payload: ConsultaAtendimentoPjRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_PJ_REQUEST,
		payload,
	};
}
export function consultaAtendimentoPjSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_PJ_SUCCESS,
		payload,
	};
}
export function consultaAtendimentoPjFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_PJ_FAILURE,
		payload,
	};
}
export function consultaAtendimentoPjClear(): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTO_PJ_CLEAR,
		payload: null,
	};
}
