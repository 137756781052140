// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function tiposDocumentosCompraRequest() {
	return {
		type: Types.TIPOS_DOCUMENTOS_COMPRA_REQUEST,
	};
}
export function tiposDocumentosCompraSuccess(payload: object): ReducerAction {
	return {
		type: Types.TIPOS_DOCUMENTOS_COMPRA_SUCCESS,
		payload,
	};
}
export function tiposDocumentosCompraFailure(payload: object): ReducerAction {
	return {
		type: Types.TIPOS_DOCUMENTOS_COMPRA_FAILURE,
		payload,
	};
}
export function tiposDocumentosCompraClear(): ReducerAction {
	return {
		type: Types.TIPOS_DOCUMENTOS_COMPRA_CLEAR,
		payload: null,
	};
}
