export enum Types {
	CONSULTAR_LOCALIDADE_REQUEST = '@SGU/CONSULTAR_LOCALIDADE_REQUEST',
	CONSULTAR_LOCALIDADE_SUCCESS = '@SGU/CONSULTAR_LOCALIDADE_SUCCESS',
	CONSULTAR_LOCALIDADE_FAILURE = '@SGU/CONSULTAR_LOCALIDADE_FAILURE',
	CONSULTAR_LOCALIDADE_CLEAR = '@SGU/CONSULTAR_LOCALIDADE_CLEAR',
}

export interface ConsultarLocalidade {
	status: number;
	data: LocalidadeData | null;
	type: 'edit' | 'delete' | 'show' | null;
}

export interface LocalidadeData {
	id: number;
	codigo: number;
	digito: number;
	nome: string;
	orgao: ChildrenObject;
	unidade: ChildrenObject;
	tipo: ChildrenObject;
	funcionalidades: ChildrenObject[];
}

export interface ChildrenObject {
	id: number;
	descricao: string;
}

export interface ConsultarLocalidadeRequest {
	id: number | string;
	type?: 'edit' | 'delete' | 'show';
}
