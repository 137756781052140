import { takeLatest, put, all } from 'redux-saga/effects';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { DadosPreCadastroFormSaveRequest, Types } from './types';

// ACTIONS
import { dadosPreCadastroFormSaveSuccess } from './actions';

function* dadosPreCadastroFormSave(request: ReducerAction) {
	const { payload }: { payload: DadosPreCadastroFormSaveRequest } = request;

	if (
		payload.dataPreCadastro?.cpf ||
		payload.dataPreCadastroHabilitacao?.cpf ||
		payload.dataPreCadastroPrimeiraHabilitacao?.cpf ||
		payload.dataPreCadastroCnhEstrangeiro?.cpf
	) {
		yield put(
			dadosPreCadastroFormSaveSuccess({
				dataPreCadastro: payload.dataPreCadastro,
				dataPreCadastroHabilitacao: payload.dataPreCadastroHabilitacao,
				dataPreCadastroPrimeiraHabilitacao:
					payload.dataPreCadastroPrimeiraHabilitacao,
				dataPreCadastroCnhEstrangeiro: payload.dataPreCadastroCnhEstrangeiro,
				dataPreCadastroHabilitacaoCnhEstrangeiro:
					payload.dataPreCadastroHabilitacaoCnhEstrangeiro,
			}),
		);
	}
}

export default all([
	takeLatest(
		Types.DADOS_PRE_CADASTRO_FORM_SAVE_REQUEST,
		dadosPreCadastroFormSave,
	),
]);
