import React, { useCallback, useEffect } from 'react';

import { Row, Col } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import {
	consultaNomeSocialClear,
	consultaNomeSociallRequest,
} from 'store/modules/api/detranCnh/nomeSocial/consultaNomeSocial/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';

import { RequestConsultaNomeSocial } from 'store/modules/api/detranCnh/nomeSocial/consultaNomeSocial/types';
import { eventoInicioSessaoRequest } from 'store/modules/api/detranCnh/evento/inicioSessao/actions';

// COMPONENTS COMMON
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import CPF from 'components/Common/Form/Fields/CPF';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { threatValuesStartSessionEventRequest } from 'utils/functions/threatEventsRequest';

// ROUTES
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// FORM
import { Formik, Form, FormikProps } from 'formik';
import { initialValues, schema } from './form';

const ConsultaNomeSocial: React.FC = () => {
	const dispatch = useDispatch();

	const { consultaNomeSocial } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.nomeSocial,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { mainframe } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const handleClear = useCallback(
		(formik?: FormikProps<RequestConsultaNomeSocial>) => {
			formik?.resetForm();
			dispatch(clearNotifications());
			dispatch(consultaNomeSocialClear({}));
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: RequestConsultaNomeSocial) => {
			const cpfFormatado = values.cpf?.replace(/[.-]/g, '');

			handleClear();

			const valuesConsultaNomeSocial = {
				cpf: cpfFormatado,
				usuario: mainframe.idUsuario,
				senha: mainframe.senhaMainFrame,
			};

			dispatch(consultaNomeSociallRequest({ ...valuesConsultaNomeSocial }));

			const valuesStartSessionEvent = threatValuesStartSessionEventRequest(
				atendimento,
				loginUnico,
			);

			dispatch(eventoInicioSessaoRequest(valuesStartSessionEvent));
		},
		[
			atendimento,
			dispatch,
			handleClear,
			loginUnico,
			mainframe.idUsuario,
			mainframe.senhaMainFrame,
		],
	);

	useEffect(() => {
		handleClear();
	}, [handleClear]);

	return (
		<>
			<Section title="Pesquisar">
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={values => getValidationsErrors(values, schema)}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row gutter={[0, 10]}>
										<Col span={12}>
											<CPF
												title="CPF"
												subtitle="(Digite apenas números)"
												numero="cpf"
												formik={formik}
												error={!!formik.errors.cpf}
												disabled={consultaNomeSocial.status === 100}
											/>
										</Col>
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => handleClear(formik)}
												disabled={consultaNomeSocial.status === 100}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage
												type="submit"
												src="pesquisar"
												disabled={consultaNomeSocial.status === 100}
											/>
										</Col>

										{consultaNomeSocial?.status === 200 &&
											consultaNomeSocial.data && (
												<Section title="Retorno PNSF">
													<Col offset={1}>
														<div>
															<b>Nome Social: </b>
															{consultaNomeSocial.data.nomeSocial
																? consultaNomeSocial.data.nomeSocial
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>Filiação 1: </b>
															{consultaNomeSocial.data.filiacao1
																? consultaNomeSocial.data.filiacao1
																: 'Informação não encontrada'}
														</div>
														<div>
															<b>Filiação 2: </b>
															{consultaNomeSocial.data.filiacao1
																? consultaNomeSocial.data.filiacao1
																: 'Informação não encontrada'}
														</div>
													</Col>
												</Section>
											)}

										{consultaNomeSocial?.status === 400 && (
											<Section title="Retorno PNSF">
												<Col offset={1}>
													<div>
														<b>Erro PNSF: </b>
														CPF INEXISTENTE NA BASE ESTADUAL. VERIFIQUE.........
													</div>
												</Col>
											</Section>
										)}
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>
			<ButtonVoltar
				route={ROUTE_DETRAN_CNH}
				onClick={() => {
					dispatch(detranCnhClear());
				}}
			/>
		</>
	);
};

export default ConsultaNomeSocial;
