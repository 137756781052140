import { ReducerActionRotaSP } from 'store/modules/types';

import {
	CadastrarResponsavelData,
	LatLngSelecionada,
	Types,
	Responsavel,
} from './types';

export function cadastrarResponsavelRequest(
	token: string,
	payload: CadastrarResponsavelData,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_RESPONSAVEL_REQUEST,
		token,
		payload,
	};
}

export function latLngSelecionada(payload: LatLngSelecionada) {
	return {
		type: Types.LAT_LNG_SELECIONADA,
		payload,
	};
}

export function cadastrarResponsavelSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_RESPONSAVEL_SUCCESS,
		payload,
	};
}

export function cadastrarResponsavelFailure(
	payload: boolean,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_RESPONSAVEL_FAILURE,
		payload,
	};
}

export function cadastrarResponsavelClear() {
	return {
		type: Types.CADASTRAR_RESPONSAVEL_CLEAR,
		payload: null,
	};
}

export function preCadastroResponsavelRequest(payload: Responsavel) {
	return {
		type: Types.PRE_CADASTRO_RESPONSAVEL_REQUEST,
		payload,
	};
}

export function limpaPreCadastroResponsavelRequest() {
	return {
		type: Types.PRE_CADASTRO_RESPONSAVEL_CLEAR,
	};
}
