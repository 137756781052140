// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function sexosRequest(): ReducerAction {
	return {
		type: Types.SEXOS_REQUEST,
		payload: null,
	};
}
export function sexosSuccess(payload: object): ReducerAction {
	return {
		type: Types.SEXOS_SUCCESS,
		payload,
	};
}
export function sexosFailure(payload: object): ReducerAction {
	return {
		type: Types.SEXOS_FAILURE,
		payload,
	};
}
export function sexosClear(): ReducerAction {
	return {
		type: Types.SEXOS_CLEAR,
		payload: null,
	};
}
