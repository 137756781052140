import * as Yup from 'yup';
import { onlyLetters, minCharacters } from 'utils/yupValidations';
import { limparMascara } from 'utils/genericFunctions';

import {
	CadastrarResponsavelData,
	Responsavel,
} from 'store/modules/api/educacaoUpdate/responsavel/cadastroResponsavel/types';
import {
	IDocumentosAluno,
	IAluno,
} from 'store/modules/api/educacaoUpdate/types';
import { RemoverResponsavelRequest } from 'store/modules/api/educacaoUpdate/responsavel/removerResponsavel/types';

export const initialValues: Responsavel = {
	cpf: '',
	nome: '',
	tipoOrigem: '',
	nrRG: '',
	digitoRG: '',
	ufRG: '',
	codResponsabilidade: '',
	codSexo: '',
	codEstadoCivil: '',
	emailResponsavel: '',
	dataNascimento: '',
	cidadeNascimento: '',
	ufNascimento: '',
	nomePaisNascimento: '',
	codPaisNascimento: '',
	rnm: '',
	enderecoResidencial: {
		logradouro: '',
		numero: '',
		areaLogradouro: '',
		complemento: '',
		bairro: '',
		cep: '',
		nomeCidade: '',
		ufCidade: '',
		latitude: '',
		longitude: '',
		// codLocalizacaoDiferenciada: '',
	},
	telefones: [],
};

const documentosAluno: IDocumentosAluno = {
	nrRG: '',
	ufRG: '',
	cpf: '',
};

export const schema = Yup.object<Responsavel>().shape({
	cpf: Yup.string().min(11, `Campo Obrigatório: CPF.`),
	nome: Yup.string()
		.required(`Campo Obrigatório: Nome`)
		.test({
			name: 'onlyLetters',
			exclusive: true,
			message: 'Campo Nome: Apenas letras são permitidas.',
			test: value => onlyLetters(value),
		})
		.test({
			name: 'minCharacters',
			exclusive: true,
			message: 'Nome deve conter no mínimo 8 caracteres',
			test: value => minCharacters(8, value),
		}),
	tipoOrigem: Yup.string().required(`Campo Obrigatório: Nacionalidade`),
	nrRG: Yup.string().when('tipoOrigem', {
		is: tipoOrigem => tipoOrigem !== '2',
		then: Yup.string().required(`Campo Obrigatório: RG`),
	}),
	ufRG: Yup.string().when('tipoOrigem', {
		is: tipoOrigem => tipoOrigem !== '2',
		then: Yup.string().required(`Campo Obrigatório: UF RG`),
	}),
	codResponsabilidade: Yup.string().required(`Campo Obrigatório: Parentesco`),
	codSexo: Yup.string().required(`Campo Obrigatório: Sexo`),
	codEstadoCivil: Yup.string().required(`Campo Obrigatório: Estado Civil`),
	emailResponsavel: Yup.string().required(`Campo Obrigatório: E-mail`),
	dataNascimento: Yup.string().required(
		`Campo Obrigatório: Data de Nascimento`,
	),
	cidadeNascimento: Yup.string().when('tipoOrigem', {
		is: tipoOrigem => tipoOrigem === '1',
		then: Yup.string().required(`Campo Obrigatório: Município de Nascimento`),
	}),
	ufNascimento: Yup.string().when('tipoOrigem', {
		is: tipoOrigem => tipoOrigem === '1',
		then: Yup.string().required(`Campo Obrigatório: UF de Nascimento`),
	}),
	nomePaisNascimento: Yup.string().when('tipoOrigem', {
		is: tipoOrigem => tipoOrigem === '2',
		then: Yup.string().required(`Campo Obrigatório: País de Origem`),
	}),
	rnm: Yup.string().when('tipoOrigem', {
		is: tipoOrigem => tipoOrigem === '2',
		then: Yup.string().required(`Campo Obrigatório: RNM`),
	}),
	enderecoResidencial: Yup.object().shape({
		cep: Yup.string().required(`Campo Obrigatório: CEP`),
		areaLogradouro: Yup.string().required(
			`Campo Obrigatório: Localização/Zona de Residência`,
		),
		logradouro: Yup.string().required(`Campo Obrigatório: Endereço`),
		numero: Yup.string().required(`Campo Obrigatório: N°`),
		bairro: Yup.string().required(`Campo Obrigatório: Bairro`),
		nomeCidade: Yup.string().required(`Campo Obrigatório: Cidade - UF`),
		latitude: Yup.string().required(`Campo Obrigatório: Latitude`),
		longitude: Yup.string().required(`Campo Obrigatório: Longitude`),
	}),
	inLstTelefone: Yup.array().min(
		1,
		'É necessário adicionar ao menos 1 número de telefone',
	),
});

export const getRequestResponse = (codigo: number) => {
	switch (codigo) {
		case 400:
			return true;
		case 401:
			return true;
		case 403:
			return true;
		case 404:
			return true;
		case 500:
			return true;
		default:
			return false;
	}
};

const formatResponsavel = (inResponsavel: Responsavel) => {
	const endereco = {
		...inResponsavel.enderecoResidencial,
		ufCidade: 'SP',
	};
	const responsavel = {
		...inResponsavel,
		enderecoResidencial: endereco,
		cpf: limparMascara(inResponsavel.cpf),
		rnm: limparMascara(inResponsavel.rnm),
		dataNascimento: inResponsavel.dataNascimento.split(' ')[0],
	};
	if (inResponsavel.tipoOrigem !== '2') {
		delete responsavel.codPaisNascimento;
	}
	if (
		inResponsavel.tipoOrigem === '3' &&
		responsavel.cidadeNascimento &&
		responsavel.ufNascimento
	) {
		responsavel.cidadeNascimento = '';
		responsavel.ufNascimento = '';
	}
	if (inResponsavel.telefones.length > 0) {
		const tempList = inResponsavel.telefones.map((telefone: any) => {
			return {
				dddNumero: telefone.ddd || '',
				numero: telefone.numero || '',
				tipoTelefone: Number(telefone.tipo) || '',
				complemento: telefone.complemento || '',
				// sms: telefone.sms || '',
			};
		});
		responsavel.telefones = tempList;
	}
	return responsavel;
};

const formatAluno = (inAluno: IAluno) => {
	const aluno = {
		...inAluno,
		numeroRA: (inAluno && inAluno.numeroRA.replace(/\D/g, '')) || '',
	};
	return aluno;
};

export const formatValuesCreate = (
	alunoData: IAluno,
	responsavelData: Responsavel,
): CadastrarResponsavelData => {
	const responsavel = formatResponsavel(responsavelData);
	const aluno = formatAluno(alunoData);
	return {
		documentosAluno,
		aluno,
		responsavel,
	};
};

export const formatValuesUpdate = (
	alunoData: IAluno,
	responsavelData: Responsavel,
): CadastrarResponsavelData => {
	const responsavel = formatResponsavel(responsavelData);
	const aluno = formatAluno(alunoData);
	return {
		documentosAluno,
		aluno,
		responsavel,
	};
};

export const formatValuesRemove = (
	alunoData: IAluno,
	responsavelData: Responsavel,
): RemoverResponsavelRequest => {
	const responsavel = formatResponsavel(responsavelData);
	const aluno = formatAluno(alunoData);
	return {
		responsavel: {
			cpf: responsavel.cpf,
		},
		aluno: {
			numeroRA: aluno.numeroRA,
			digitoRA: aluno.digitoRA,
			siglaUFRA: aluno.siglaUFRA,
		},
		documentosAluno: {
			numeroCpf: documentosAluno.cpf,
			numeroRg: documentosAluno.nrRG,
			siglaUfRg: documentosAluno.ufRG,
		},
	};
};
