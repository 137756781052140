export enum Types {
	MANTEM_DADOS_ALUNO_REQUEST = '@EDUCACAO/MANTEM_DADOS_ALUNO_REQUEST',
	MANTEM_DADOS_ALUNO_SUCCESS = '@EDUCACAO/MANTEM_DADOS_ALUNO_SUCCESS',
	MANTEM_DADOS_ALUNO_FAILURE = '@EDUCACAO/MANTEM_DADOS_ALUNO_FAILURE',
}

export interface IDadosAluno {
	data: IMantemDadosAluno;
}

export interface IMantemDadosAluno {
	aluno: {
		digitoRA: string;
		numeroRA: string;
		siglaUFRA: string;
	};
}
