export enum Types {
	ATUALIZAR_TIPOS = '@SGU/ATUALIZAR_TIPOS',
	ATUALIZAR_TIPOS_SUCCESS = '@SGU/ATUALIZAR_TIPOS_SUCCESS',
	ATUALIZAR_TIPOS_FAILURE = '@SGU/ATUALIZAR_TIPOS_FAILURE',
	ATUALIZAR_TIPOS_CLEAR = '@SGU/ATUALIZAR_TIPOS_CLEAR',
}

export interface AtualizarTipos {
	status: number;
	data: null | AtualizarTiposData;
}

export interface AtualizarTiposData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarTiposRequest {
	id?: number | string;
	descricao: string;
}
