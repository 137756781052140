import React, { useEffect, useState } from 'react';

import { Row, Col } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import FormBox from 'components/Common/Form/FormBox';

// UTILS
import { limparMascara, patternFormat } from 'utils/genericFunctions';

const Atendimento: React.FC = () => {
	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const [rg, setRg] = useState<string | null>(null);

	const [taxaCorreios, setTaxaCorreios] = useState<boolean>(false);
	const [taxaCorreiosDesc, setTaxaCorreiosDesc] = useState<string>('');

	const [taxaEmissao, setTaxaEmissao] = useState<boolean>(false);

	const [isento, setIsento] = useState<boolean>(false);

	const [kitFamilia, setKitFamilia] = useState<boolean>(false);
	const [kitFamiliaDesc, setKitFamiliaDesc] = useState<string>('');

	useEffect(() => {
		if (cadastroRg.form.principal) {
			const { numeroRg } = cadastroRg.form.principal;

			setRg(numeroRg);
		}
	}, [cadastroRg.form.principal]);

	useEffect(() => {
		if (cadastroRg.form.principal) {
			const { entrega } = cadastroRg.form.principal;

			if (entrega !== 'P') {
				setTaxaCorreios(true);

				if (['R', 'KC', 'K'].includes(entrega.toString())) {
					if (entrega === 'R') {
						setTaxaCorreiosDesc('- Correios (Entrega em  Residência)');
					} else if (entrega === 'KC') {
						setTaxaCorreiosDesc('- Correios (Entrega em  Residência)');

						setKitFamilia(true);
						setKitFamiliaDesc('- Kit Família (RGE/CIN Principal)');
					} else if (entrega === 'K') {
						setKitFamilia(true);
						setKitFamiliaDesc('- Kit Família (Continuação)');
					}
				} else if (entrega === 'T') {
					setTaxaCorreiosDesc('- Correios (Entrega no Trabalho)');
				} else if (typeof entrega === 'number') {
					setTaxaCorreiosDesc('- Correios (Entrega em  Residência)');

					setKitFamilia(true);
					setKitFamiliaDesc('- Kit Família (RGE/CIN Principal)');
				}
			}
		}

		if (cadastroRg.form.observacoes) {
			const { primeiraVia, segundaVia } = cadastroRg.form.observacoes;

			if (segundaVia) {
				const { declaracaoPobreza, isento2Via062, isento2Via10952 } =
					segundaVia;

				if (
					[declaracaoPobreza, isento2Via062, isento2Via10952].includes(true)
				) {
					setIsento(true);
				} else {
					setTaxaEmissao(true);
				}
			} else if (primeiraVia) {
				setIsento(true);
			}
		}
	}, [cadastroRg.form]);

	return (
		<>
			{rg && (
				<Row>
					<Col span={24}>
						<FormBox
							title={`Atendimento iniciado para o RGE/CIN ${
								limparMascara(rg).length === 10
									? patternFormat(rg, '##.###.###-##')
									: patternFormat(rg, '##.###.###-#')
							}`}
							size="sm"
						>
							<Row>
								<Col span={24}>
									{isento && <p>- Isento</p>}

									{kitFamilia && <p>{kitFamiliaDesc}</p>}

									{taxaEmissao && <p>- Taxa de Segunda Via</p>}

									{taxaCorreios && <p>{taxaCorreiosDesc}</p>}
								</Col>
							</Row>
						</FormBox>
					</Col>
				</Row>
			)}
		</>
	);
};

export default Atendimento;
