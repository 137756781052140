import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Row, Col, Empty } from 'antd';
import { v4 } from 'uuid';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';

import { threatValuesEndSessionEventRequest } from 'utils/functions/threatEventsRequest';
import { eventoTerminoSessaoRequest } from 'store/modules/api/detranCnh/evento/terminoSessao/actions';
import { usoFormularioEnumTypes } from 'utils/constants/types';
import { Multas } from 'store/modules/api/detranCnh/sockets/consultaPontos/types';
import {
	Title,
	CustomizedTable,
	LabelTickets,
	LabelTotalTicketsPoints,
} from './styled';

interface Multa extends Multas {
	key?: string;
}

interface ILocation {
	state: {
		flagDozeMeses: boolean;
	};
}

const headerTableStatus = [
	{
		title: 'Data/Hora',
		dataIndex: 'data',
		key: 'data',
	},
	{
		title: 'Auto de Infração',
		dataIndex: 'autoInfracao',
		key: 'autoInfracao',
	},
	{
		title: 'Orgão Autuador',
		dataIndex: 'orgaoAtuador',
		key: 'orgaoAtuador',
	},
	{
		title: 'Município',
		dataIndex: 'municipio',
		key: 'municipio',
	},
	{
		title: 'Placa do Veículo',
		dataIndex: 'placa',
		key: 'placa',
	},
	{
		title: 'Infração',
		dataIndex: 'descricaoEnq',
		key: 'descricaoEnq',
	},
	{
		title: 'Local',
		dataIndex: 'local',
		key: 'local',
	},
	{
		title: 'Pontos',
		dataIndex: 'pontos',
		key: 'pontos',
	},
];

const StatusCondutorConsultaPontuacao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location: ILocation = useLocation();

	const [tickets, setTickets] = useState<Multa[]>([]);
	const [flagDozeMesesPesquisa, setFlagDozeMesesPesquisa] =
		useState<boolean>(false);

	const { data: dataConsultaPontos } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets.consultaPontos,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { detailsStartSection } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.evento.eventoInicioSessao,
	);

	useEffect(() => {
		if (dataConsultaPontos?.infracao) {
			const ticketsEdited: Multa[] = [];

			dataConsultaPontos?.infracao.map(ticket => {
				const hora = new Date(ticket.data).toLocaleDateString('pt-BR', {
					hour: '2-digit',
					minute: '2-digit',
				});

				ticketsEdited.push({
					...ticket,
					data: hora,
					key: v4(),
				});

				return null;
			});

			setTickets(ticketsEdited);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const editDataRow = (value: Multas[]) => {
	// 	const ticketsEdited: Multas[] = [];

	// 	value.map(ticket => {
	// 		const hora = new Date(ticket.dataHora).toLocaleDateString('pt-BR', {
	// 			hour: '2-digit',
	// 			minute: '2-digit',
	// 			second: '2-digit',
	// 		});

	// 		ticketsEdited.push({
	// 			...ticket,
	// 			dataHora: hora,
	// 			key: v4(),
	// 		});

	// 		return null;
	// 	});

	// 	setTickets(ticketsEdited);
	// };

	const getTotalPoints = (): string => {
		let totalPoints = 0;

		tickets.map(ticket => {
			totalPoints += Number(ticket.pontos) || 0;

			return null;
		});

		return totalPoints.toString();
	};

	useEffect(() => {
		if (dataConsultaPontos) {
			const valuesEndSessionEvent = threatValuesEndSessionEventRequest(
				atendimento,
				loginUnico,
				usoFormularioEnumTypes.CONSULTA_PONTOS,
				detailsStartSection?.dataInicio || '',
			);

			dispatch(eventoTerminoSessaoRequest(valuesEndSessionEvent));

			// if (dataConsultaPontos.infracoes) {
			// 	editDataRow(dataConsultaPontos.infracoes);
			// }
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataConsultaPontos]);

	useEffect(() => {
		if (location.state) {
			setFlagDozeMesesPesquisa(location.state.flagDozeMeses === true);
		}
	}, [location.state]);

	return (
		<Row>
			<Col span={24}>
				<Section title="Status do Condutor">
					<Row>
						<Title>{dataConsultaPontos?.nome}</Title>
					</Row>
					<Section bgColor="cinza-claro">
						<Row>
							<Col span={24}>
								{flagDozeMesesPesquisa ? (
									<Title underline>
										Dados da Consulta de Pontos dos Últimos 12 meses
									</Title>
								) : (
									<Title underline>Dados da Consulta Histórico de Pontos</Title>
								)}
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Input
									name="municipio"
									readOnly
									title="Município"
									value={dataConsultaPontos?.municipioCnh}
									titleAlign="start"
									titleSize={60}
									className="municipio_class"
								/>
								<Input
									name="cpf"
									readOnly
									title="CPF"
									value={dataConsultaPontos?.cpf}
									titleAlign="start"
									titleSize={30}
								/>
							</Col>

							<Col span={11} offset={1}>
								<Input
									name="numeropgu"
									readOnly
									title="Número PGU"
									value={Number(dataConsultaPontos?.numeroPGU).toString() || ''}
									titleAlign="start"
									titleSize={80}
								/>
								<Input
									name="registro"
									readOnly
									title="Registro"
									value={dataConsultaPontos?.numeroRegistro}
									titleAlign="start"
									titleSize={50}
								/>
							</Col>
						</Row>
					</Section>
					<Section>
						<Row justify="center">
							{dataConsultaPontos?.infracao ? (
								<LabelTickets>
									Total de infrações registradas:{' '}
									{dataConsultaPontos?.infracao.length}
								</LabelTickets>
							) : (
								<LabelTickets>Total de infrações registradas: 0</LabelTickets>
							)}
						</Row>

						<CustomizedTable
							columns={headerTableStatus}
							dataSource={tickets}
							pagination={{
								defaultPageSize: 5,
								position: ['bottomCenter'],
							}}
							rowKey="key"
							locale={{
								emptyText: <Empty description="Nenhum registro encontrado" />,
							}}
						/>
					</Section>
					{dataConsultaPontos?.infracao && (
						<Row justify="start">
							{/* {dataConsultaPontos?.infracoes.length ? ( */}
							<LabelTotalTicketsPoints>
								Pontuação Total: {getTotalPoints()} Pontos
							</LabelTotalTicketsPoints>
							{/* ) : null} */}
						</Row>
					)}
				</Section>
				<Row justify="space-between" align="middle">
					<Col>
						<ButtonVoltar route="/detran-cnh" />
					</Col>
					<Col>
						<ButtonImage
							type="button"
							src="outro-servico-detran"
							onClick={() => history.push('/detran-cnh')}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default StatusCondutorConsultaPontuacao;
