// TYPES
import { ReducerAction } from 'store/modules/types';
import { IDependenteDare, Types } from './types';

export interface IRequestDareGuia {
	dare: string;
	numeroRg: string;
}

export interface IRequestDareCpf {
	cpf: string;
	numeroRg: string;
}

export interface IRequestTaxasDare {
	taxasCorreio: boolean;
	numeroRg: string;
	nome: string;
}

export interface IRequestGerarDare {
	cpf: string;
	enderecoContribuinte: string;
	postoCodigo: number;
	postoDescricao: string;
	ipUsuario: string;
	emailTo: string;
	taxasReq: IRequestTaxasDare[];
}

export function dependentesDareCpfPrincipal(payload: string): ReducerAction {
	return {
		type: Types.DEPENDENTES_DARE_CPF_PRINCIPAL,
		payload,
	};
}
export function dependentesDareAdicionar(
	payload: IDependenteDare[],
): ReducerAction {
	return {
		type: Types.DEPENDENTES_DARE_ADICIONAR,
		payload,
	};
}
export function dependentesDareEditando(
	index: number,
	dependente: IDependenteDare,
): ReducerAction {
	return {
		type: Types.DEPENDENTES_DARE_EDITANDO,
		payload: {
			index,
			dependente,
		},
	};
}
export function dependentesDareEditandoClear(): ReducerAction {
	return {
		type: Types.DEPENDENTES_DARE_EDITANDO_CLEAR,
		payload: null,
	};
}
export function dependentesDareExcluir(payload: number): ReducerAction {
	return {
		type: Types.DEPENDENTES_DARE_EXCLUIR,
		payload,
	};
}
export function dependentesDareClear(): ReducerAction {
	return {
		type: Types.DEPENDENTES_DARE_CLEAR,
		payload: null,
	};
}
