import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { aacClear } from 'store/modules/api/aac/actions';
import { biometriaClear } from 'store/modules/api/biometria/actions';
import { cdhuClear } from 'store/modules/api/cdhu/actions';
import { dareClear } from 'store/modules/api/dare/actions';
import { iirgdClear } from 'store/modules/api/iirgd/actions';

// COMPONENTS
import RecuperaAtendimento from 'components/Pages/RecuperaAtendimento';
import { utilsClear } from 'store/modules/api/utils/actions';

const RecuperarAtendimento: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { redirecionamento } = useSelector(
		(state: ApplicationState) => state.atendimento,
	);

	const handleClick = useCallback(() => {
		dispatch(aacClear());
		dispatch(biometriaClear());
		dispatch(cdhuClear());
		dispatch(dareClear());
		dispatch(iirgdClear());
		dispatch(utilsClear());

		if (redirecionamento) {
			history.push(redirecionamento);
		}
	}, [dispatch, redirecionamento, history]);

	return <RecuperaAtendimento onClick={handleClick} />;
};

export default RecuperarAtendimento;
