export enum Types {
	CONSULTA_INFRACOES = '@jalesTransito/CONSULTA_INFRACOES',
	CONSULTA_INFRACOES_SUCCESS = '@jalesTransito/CONSULTA_INFRACOES_SUCCESS',
	CONSULTA_INFRACOES_FAILURE = '@jalesTransito/CONSULTA_INFRACOES_FAILURE',
	CONSULTA_INFRACOES_CLEAR = '@jalesTransito/CONSULTA_INFRACOES_CLEAR',
}

export interface infracoes {
	status: number;
	data: IInfracoes[] | null;
}

interface IInfracoes {
	codigoAit: string;
	urlDocumento: string;
	urlPagar: string;
	urlProtocolar: string;
	urlTransferir: string;
	tipoRecurso: string;
}

export interface IRequestInfracoes {
	evento?: any;
	placa: string;
	renavan: string;
}
