import React from 'react';

import { Row, Col } from 'antd';
import Input from 'components/Common/Form/Input/Input';
import { Container } from './styles';

interface IProps {
	exigenciaIP01: string;
	exigenciaIP02: string;
	exigenciaIP03: string;
	exigenciaIP04: string;
	exigenciaIP05: string;
	exigenciaIP06: string;
	exigenciaIP07: string;
	exigenciaIP08: string;
	exigenciaIP09: string;
	exigenciaIP10: string;
	exigDelegacia01: string;
	exigDelegacia02: string;
	exigDelegacia03: string;
	exigDelegacia04: string;
	exigDelegacia05: string;
	exigDelegacia06: string;
	exigDelegacia07: string;
	exigDelegacia08: string;
	exigDelegacia09: string;
	exigDelegacia10: string;
	exigInstauracao01: string;
	exigInstauracao02: string;
	exigInstauracao03: string;
	exigInstauracao04: string;
	exigInstauracao05: string;
	exigInstauracao06: string;
	exigInstauracao07: string;
	exigInstauracao08: string;
	exigInstauracao09: string;
	exigInstauracao10: string;
}
interface IData {
	data: IProps;
}

const IPN: React.FC<IData> = ({ data }) => {
	return (
		<Container>
			<Row gutter={15}>
				<Col span={6}>
					<h5>IP Nº</h5>
				</Col>
				<Col span={12}>
					<h5>Delegacia</h5>
				</Col>
				<Col span={6}>
					<h5>Data Instauração</h5>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigenciaIP01" value={data?.exigenciaIP01} disabled />
				</Col>
				<Col span={12}>
					<Input
						name="exigDelegacia01"
						value={data?.exigDelegacia01}
						disabled
					/>
				</Col>
				<Col span={6}>
					<Input
						name="exigInstauracao01"
						value={data?.exigInstauracao01}
						disabled
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigenciaIP02" value={data?.exigenciaIP02} disabled />
				</Col>
				<Col span={12}>
					<Input
						name="exigDelegacia02"
						value={data?.exigDelegacia02}
						disabled
					/>
				</Col>
				<Col span={6}>
					<Input
						name="exigInstauracao02"
						value={data?.exigInstauracao02}
						disabled
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigenciaIP03" value={data?.exigenciaIP03} disabled />
				</Col>
				<Col span={12}>
					<Input
						name="exigDelegacia03"
						value={data?.exigDelegacia03}
						disabled
					/>
				</Col>
				<Col span={6}>
					<Input
						name="exigInstauracao03"
						value={data?.exigInstauracao03}
						disabled
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigenciaIP04" value={data?.exigenciaIP04} disabled />
				</Col>
				<Col span={12}>
					<Input
						name="exigDelegacia04"
						value={data?.exigDelegacia04}
						disabled
					/>
				</Col>
				<Col span={6}>
					<Input
						name="exigInstauracao04"
						value={data?.exigInstauracao04}
						disabled
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigenciaIP05" value={data?.exigenciaIP05} disabled />
				</Col>
				<Col span={12}>
					<Input
						name="exigDelegacia05"
						value={data?.exigDelegacia05}
						disabled
					/>
				</Col>
				<Col span={6}>
					<Input
						name="exigInstauracao05"
						value={data?.exigInstauracao05}
						disabled
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigenciaIP06" value={data?.exigenciaIP06} disabled />
				</Col>
				<Col span={12}>
					<Input
						name="exigDelegacia06"
						value={data?.exigDelegacia06}
						disabled
					/>
				</Col>
				<Col span={6}>
					<Input
						name="exigInstauracao06"
						value={data?.exigInstauracao06}
						disabled
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigenciaIP07" value={data?.exigenciaIP07} disabled />
				</Col>
				<Col span={12}>
					<Input
						name="exigDelegacia07"
						value={data?.exigDelegacia07}
						disabled
					/>
				</Col>
				<Col span={6}>
					<Input
						name="exigInstauracao07"
						value={data?.exigInstauracao07}
						disabled
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigenciaIP08" value={data?.exigenciaIP08} disabled />
				</Col>
				<Col span={12}>
					<Input
						name="exigDelegacia08"
						value={data?.exigDelegacia08}
						disabled
					/>
				</Col>
				<Col span={6}>
					<Input
						name="exigInstauracao08"
						value={data?.exigInstauracao08}
						disabled
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigenciaIP09" value={data?.exigenciaIP09} disabled />
				</Col>
				<Col span={12}>
					<Input
						name="exigDelegacia09"
						value={data?.exigDelegacia09}
						disabled
					/>
				</Col>
				<Col span={6}>
					<Input
						name="exigInstauracao09"
						value={data?.exigInstauracao09}
						disabled
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={6}>
					<Input name="exigenciaIP10" value={data?.exigenciaIP10} disabled />
				</Col>
				<Col span={12}>
					<Input
						name="exigDelegacia10"
						value={data?.exigDelegacia10}
						disabled
					/>
				</Col>
				<Col span={6}>
					<Input
						name="exigInstauracao10"
						value={data?.exigInstauracao10}
						disabled
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default IPN;
