import produce from 'immer';

// TYPES
import { Types, AgendarExames } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: AgendarExames = {
	status: 0,
	data: null,
	preSaveData: null,
	error: null,
};

export default function agendarExamesReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): AgendarExames {
	return produce(state, draft => {
		switch (action.type) {
			case Types.AGENDAR_EXAMES_REQUEST: {
				break;
			}
			case Types.AGENDAR_EXAMES_SUCCESS: {
				draft.data = action.payload.data;
				draft.status = action.payload.status;
				break;
			}
			case Types.AGENDAR_EXAMES_FAILURE: {
				if (!action.payload.data.data) {
					draft.error = action.payload.data;
				} else {
					draft.error = action.payload.data.data;
				}
				if (!action.payload.status) {
					draft.status = action.payload.data.status;
				} else {
					draft.status = action.payload.status;
				}
				break;
			}
			case Types.AGENDAR_EXAMES_PRE_SAVE: {
				draft.preSaveData = action.payload;
				break;
			}
			case Types.AGENDAR_EXAMES_PRE_SAVE_CLEAR: {
				draft.preSaveData = INITIAL_STATE.preSaveData;
				break;
			}
			case Types.AGENDAR_EXAMES_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				draft.error = INITIAL_STATE.error;
				break;
			}
			default:
		}
	});
}
