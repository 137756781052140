import React from 'react';

import { Row, Col, Divider } from 'antd';

// COMPONENTS
import Section from 'components/Common/Section';

// FORM
import EnumCNH from 'store/modules/api/detranCnh/enum';
import { DadosHabilitacaoCnhDigital } from '../../StatusCondutor/form';

interface IProps {
	consultaCnhDigital: DadosHabilitacaoCnhDigital;
}

const DadosHabilitacao: React.FC<IProps> = ({ consultaCnhDigital }) => {
	return (
		<>
			<Section bgColor="cinza-claro" style={{ marginTop: 10 }}>
				<Row>
					<Col span={24}>
						<h1>Dados da Habilitação</h1>
					</Col>
				</Row>
				<Divider style={{ marginTop: 0, backgroundColor: '#000' }} />
				<Row>
					<Col span={24}>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<strong style={{ opacity: 0.8 }}>
											Informações Pessoais
										</strong>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Row>
											<Col span={8}>
												<p>
													<strong>CPF: </strong>
													<span>{consultaCnhDigital.cpf}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>RG: </strong>
													<span>{consultaCnhDigital.numeroRg}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>RNE: </strong>
													<span>{consultaCnhDigital.numeroRne}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Nome do pai: </strong>
													<span>{consultaCnhDigital.nomePai}</span>
												</p>
											</Col>
											<Col offset={8} span={8}>
												<p>
													<strong>Data de nasc: </strong>
													<span>{consultaCnhDigital.dataNascimento}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Nome da mãe: </strong>
													<span>{consultaCnhDigital.nomeMae}</span>
												</p>
											</Col>
											<Col offset={8} span={8}>
												<p>
													<strong>Sexo: </strong>
													<span>
														{
															EnumCNH.sexoEnum.filter(
																sexo => sexo.value === consultaCnhDigital.sexo,
															)[0]?.label
														}
													</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Naturalidade: </strong>
													<span>{consultaCnhDigital.naturalidade}</span>
												</p>
											</Col>
											<Col span={8} offset={8}>
												<p>
													<strong>Nacionalidade: </strong>
													<span>
														{
															EnumCNH.nacionalidadeEnum.filter(
																nacionalidade =>
																	nacionalidade.value ===
																	consultaCnhDigital.nacionalidade,
															)[0]?.label
														}
													</span>
												</p>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row gutter={[0, 20]}>
							<Col span={24} />
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<strong style={{ opacity: 0.8 }}>
											Informações Habilitação
										</strong>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Row>
											<Col span={8}>
												<p>
													<strong>Uso do formulário: </strong>
													<span>{consultaCnhDigital.usoForm}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Registro nº: </strong>
													<span>{consultaCnhDigital.numeroRegistro}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>Categoria Pretendida: </strong>
													<span>{consultaCnhDigital.categoriaPretendida}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>1ª habilitação: </strong>
													<span>
														{consultaCnhDigital.dataPrimeiraHabilitacao}
													</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>PGU nº: </strong>
													<span>{consultaCnhDigital.numeroPgu}</span>
												</p>
											</Col>
											<Col offset={8} span={8}>
												<p>
													<strong>Validade da CNH: </strong>
													<span>{consultaCnhDigital.dataValidadeCnh}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Renach nº: </strong>
													<span>{consultaCnhDigital.numeroRenach}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>Local nº: </strong>
													<span>{consultaCnhDigital.numeroLocalRenach}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>Categoria Atual: </strong>
													<span>{consultaCnhDigital.categoriaAtual}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Espelho da CNH nº: </strong>
													<span>{consultaCnhDigital.numeroEspelhoCnh}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>CFC-A: </strong>
													<span>{consultaCnhDigital.cfcA}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>CFC-B: </strong>
													<span>{consultaCnhDigital.cfcB}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<p>
													<strong>Última Emissão da CNH: </strong>
													<span>{consultaCnhDigital.dataUltimaEmissaoCnh}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Exame Médico: </strong>
													<span>{consultaCnhDigital.dataExameMedico}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>Apto para categoria: </strong>
													<span>{consultaCnhDigital.aptoCategoria}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>Restrições médicas: </strong>
													<span>{consultaCnhDigital.restricoesMedicas}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>CRM: </strong>
													<span>{consultaCnhDigital.crm}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>CRED: </strong>
													<span>{consultaCnhDigital.numeroCred}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>Região: </strong>
													<span>{consultaCnhDigital.regiao}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Exame Teórico: </strong>
													<span>{consultaCnhDigital.dataExameTeorico}</span>
												</p>
											</Col>
											<Col offset={8} span={6}>
												<p>
													<strong>Exame Prático: </strong>
													<span>
														{`${
															consultaCnhDigital.dataExamePratico4Rodas !== ''
																? `${consultaCnhDigital.dataExamePratico4Rodas} (4 RODAS)`
																: ''
														} ${
															consultaCnhDigital.dataExamePratico2Rodas !== ''
																? `${consultaCnhDigital.dataExamePratico2Rodas} (2 RODAS)`
																: ''
														}`}
													</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>CRP: </strong>
													<span>{consultaCnhDigital.crp}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>CRED 1: </strong>
													<span>{consultaCnhDigital.numeroCred1}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>CRED 2: </strong>
													<span>{consultaCnhDigital.numeroCred2}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Laudo: </strong>
													<span>{consultaCnhDigital.laudo}</span>
												</p>
											</Col>
											<Col offset={8} span={8}>
												<p>
													<strong>Cód. Região: </strong>
													<span>{consultaCnhDigital.codRegiao}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Exame Psicotécnico: </strong>
													<span>
														{consultaCnhDigital.dataExamePsicotecnico}
													</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>Info Resultado: </strong>
													<span>{consultaCnhDigital.infoResultado}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>Aptidão: </strong>
													<span>{consultaCnhDigital.aptidaoPsicotecnica}</span>
												</p>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24} />
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<strong style={{ opacity: 0.8 }}>Endereço Cadastrado</strong>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Row>
									<Col span={8}>
										<p>
											<strong>CEP: </strong>
											<span>{consultaCnhDigital.enderecoCep}</span>
										</p>
									</Col>
									<Col span={16}>
										<p>
											<strong>Logradouro: </strong>
											<span>{consultaCnhDigital.enderecoLogradouro}</span>
										</p>
									</Col>
								</Row>
								<Row>
									<Col span={8}>
										<p>
											<strong>Número: </strong>
											<span>{consultaCnhDigital.enderecoNumero}</span>
										</p>
									</Col>
									<Col span={16}>
										<p>
											<strong>Complemento: </strong>
											<span>{consultaCnhDigital.enderecoComplemento}</span>
										</p>
									</Col>
								</Row>
								<Row>
									<Col span={8}>
										<p>
											<strong>Bairro: </strong>
											<span>{consultaCnhDigital.enderecoBairro}</span>
										</p>
									</Col>
									<Col span={16}>
										<p>
											<strong>Município: </strong>
											<span>{consultaCnhDigital.enderecoDescMunicipio}</span>
										</p>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Section>
		</>
	);
};

export default DadosHabilitacao;
