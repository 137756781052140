import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_TIPO_CONTRATO_REQUEST = '@BOLSA-POVO/CONSULTAR_TIPO_CONTRATO_REQUEST',
	CONSULTAR_TIPO_CONTRATO_SUCCESS = '@BOLSA-POVO/CONSULTAR_TIPO_CONTRATO_SUCCESS',
	CONSULTAR_TIPO_CONTRATO_FAILURE = '@BOLSA-POVO/CONSULTAR_TIPO_CONTRATO_FAILURE',
	LIMPAR_CONSULTAR_TIPO_CONTRATO = '@BOLSA-POVO/LIMPAR_CONSULTAR_TIPO_CONTRATO',
}

export interface ConsultarTipoContato {
	status: number;
	data: OptionProps[];
}
