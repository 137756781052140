import * as Yup from 'yup';
// 272.336.710-01
export interface FormLogin {
	cpf: string;
	senha: string;
	alteraSenha?: boolean;
	senhaNova?: string;
	confirmacaoSenha?: string;
	validaNovaSenha?: string;
	validacaoLogin?: boolean;
}

export const initialValues: FormLogin = {
	cpf: '',
	senha: '',
};

export const schema = Yup.object<FormLogin>().shape({
	validacaoLogin: Yup.string().when(['cpf', 'senha'], {
		is: (cpf, senha) => cpf === '' && senha === '',
		then: Yup.string().required(`Campo Obrigatório: Usuário e Senha.`),
	}),
	cpf: Yup.string().required(`Campo Obrigatório: Usuário.`),
	senha: Yup.string().required(
		`Senha: Erro de Validação: O Valor é menor do que o mínimo permitido de '3'`,
	),
	validaNovaSenha: Yup.string()
		.when(['alteraSenha', 'senhaNova', 'confirmacaoSenha'], {
			is: (alteraSenha, senhaNova, confirmacaoSenha) =>
				alteraSenha &&
				confirmacaoSenha === '' &&
				senhaNova === '' &&
				senhaNova !== confirmacaoSenha,
			then: Yup.string().required(
				`Campo Obrigatório: Senha e Confirmação da nova senha.`,
			),
		})
		.when(['alteraSenha', 'senhaNova', 'confirmacaoSenha'], {
			is: (alteraSenha, senhaNova, confirmacaoSenha) =>
				alteraSenha &&
				confirmacaoSenha === '' &&
				senhaNova !== '' &&
				senhaNova !== confirmacaoSenha,
			then: Yup.string().required(
				`Campo Obrigatório: Confirmação da nova senha.`,
			),
		})
		.when(['alteraSenha', 'senhaNova', 'confirmacaoSenha'], {
			is: (alteraSenha, senhaNova, confirmacaoSenha) =>
				alteraSenha &&
				confirmacaoSenha !== '' &&
				senhaNova === '' &&
				senhaNova !== confirmacaoSenha,
			then: Yup.string().required(`Campo Obrigatório: Nova Senha.`),
		})
		.when(['alteraSenha', 'senhaNova', 'confirmacaoSenha'], {
			is: (alteraSenha, senhaNova, confirmacaoSenha) =>
				alteraSenha &&
				confirmacaoSenha !== '' &&
				senhaNova !== '' &&
				senhaNova !== confirmacaoSenha,
			then: Yup.string().required(
				`Senha: Erro de Validação: As novas senhas devem ser iguais.`,
			),
		}),
});
