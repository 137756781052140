import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarContatoRequest } from './types';

export function cadastrarContatoRequest(
	payload: CadastrarContatoRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_CONTRATO_REQUEST,
		payload,
	};
}
export function cadastrarContatoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_CONTRATO_SUCCESS,
		payload,
	};
}
export function cadastrarContatoFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_CONTRATO_FAILURE,
		payload: null,
	};
}

export function limparCadastrarContato(): any {
	return {
		type: Types.LIMPAR_CADASTRAR_CONTRATO,
	};
}
