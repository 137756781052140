import { all } from 'redux-saga/effects';

import alterarFichaAlunoSaga from './alterarFichaAluno/saga';
import cadastrarFichaAlunoSaga from './cadastrarFichaAluno/saga';
import consultaAlunoSaga from './consultaAluno/saga';
import consultaFichaAlunoSaga from './consultaFichaAluno/saga';
import comboPaisesSaga from './comboPaises/saga';
import comboMunicipiosSaga from './comboMunicipios/saga';
import comboMunicipiosSPSaga from './comboMunicipiosSP/saga';

export default all([
	alterarFichaAlunoSaga,
	cadastrarFichaAlunoSaga,
	consultaAlunoSaga,
	consultaFichaAlunoSaga,
	comboPaisesSaga,
	comboMunicipiosSaga,
	comboMunicipiosSPSaga,
]);
