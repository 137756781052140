// ENTITIES
import {
	IFormPesquisaRg,
	RequestPesquisarRg,
} from 'pages/iirgd/RgFicDigital/PesquisaRg/Formulario/form';

// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	ConsultaPesquisaCidadao,
	IConsultaPesquisaCidadaoSelecionado,
	Types,
} from './types';

export function consultaPesquisaCidadaoRequest(
	data: RequestPesquisarRg,
	form: IFormPesquisaRg,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIDADAO_REQUEST,
		payload: {
			data,
			form,
		},
	};
}
export function consultaPesquisaCidadaoSuccess(payload: {
	pessoa: ConsultaPesquisaCidadao[];
	contadorCirg: number;
	contadorCino: number;
	contadorCinn: number;
}): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIDADAO_SUCCESS,
		payload,
	};
}
export function consultaPesquisaCidadaoFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIDADAO_FAILURE,
		payload: null,
	};
}

export function consultaPesquisaCidadaoSelecionado(
	payload: IConsultaPesquisaCidadaoSelecionado,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIDADAO_SELECIONADO,
		payload,
	};
}
export function consultaPesquisaCidadaoSelecionadoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIDADAO_SELECIONADO_CLEAR,
		payload: null,
	};
}

export function consultaPesquisaCidadaoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIDADAO_CLEAR,
		payload: null,
	};
}

export function consultaPesquisaCidadaoFormPesquisaMae(
	form: IFormPesquisaRg,
): ReducerAction {
	return {
		type: Types.CONSULTA_PESQUISA_CIDADAO_FORM_PESQUISA_MAE_REQUEST,
		payload: {
			form,
		},
	};
}
