import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, ExcluirParametroSistemaRedis } from './types';

export const INITIAL_STATE: ExcluirParametroSistemaRedis = {
	status: 0,
};

export default function excluirParametroSistemaRedisReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ExcluirParametroSistemaRedis {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EXCLUIR_PARAMETRO_SISTEMA_REDIS: {
				draft.status = INITIAL_STATE.status;
				break;
			}

			case Types.EXCLUIR_PARAMETRO_SISTEMA_REDIS_SUCCESS: {
				draft.status = action.payload.status;
				break;
			}

			case Types.EXCLUIR_PARAMETRO_SISTEMA_REDIS_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.EXCLUIR_PARAMETRO_SISTEMA_REDIS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				break;
			}

			default:
		}
	});
}
