import { all } from 'redux-saga/effects';

import pesquisarUsuarioPortalSaga from './pesquisarUsuarioPortal/saga';
import consultaCadastroCnhSaga from './consultaCadastroCnh/saga';
import consultaTokenCnhDigitalSaga from './tokenDigital/saga';
import enviaSMSSaga from './smsSalesForce/saga';
import enviaSMSPositusSaga from './smsPositus/saga';
import validaTokenCnhDigitalSaga from './validaToken/saga';

export default all([
	pesquisarUsuarioPortalSaga,
	consultaCadastroCnhSaga,
	consultaTokenCnhDigitalSaga,
	enviaSMSSaga,
	validaTokenCnhDigitalSaga,
	enviaSMSPositusSaga,
]);
