import { all } from 'redux-saga/effects';

import desbloqueioCartao from './desbloqueioCartao/saga';
import alteraEndereco from './alteraEndereco/saga';
import consultarTipoContato from './consultarTipoContato/saga';
import cadastrarContato from './cadastrarContato/saga';
import consultarContato from './consultarContato/saga';
import excluirContato from './excluirContato/saga';
import atualizarContato from './atualizarContato/saga';
import consultarEndereco from './consultarEndereco/saga';
import editaCadastraEndereco from './editaCadastraEndereco/saga';
import consultarTiposLogradouros from './consultarTiposLogradouros/saga';
import consultarTiposResidencias from './consultarTiposResidencias/saga';
import getCpfPermitido from './getCpfPermitido/saga';

export default all([
	desbloqueioCartao,
	alteraEndereco,
	consultarTipoContato,
	cadastrarContato,
	consultarContato,
	excluirContato,
	atualizarContato,
	consultarEndereco,
	editaCadastraEndereco,
	consultarTiposLogradouros,
	consultarTiposResidencias,
	getCpfPermitido,
]);
