export enum Types {
	CONSULTAR_PRESTACOES_ATRASADAS_REQUEST = '@cdhu/CONSULTAR_PRESTACOES_ATRASADAS_REQUEST',
	CONSULTAR_PRESTACOES_ATRASADAS_SUCCESS = '@cdhu/CONSULTAR_PRESTACOES_ATRASADAS_SUCCESS',
	CONSULTAR_PRESTACOES_ATRASADAS_FAILURE = '@cdhu/CONSULTAR_PRESTACOES_ATRASADAS_FAILURE',
	CONSULTAR_PRESTACOES_ATRASADAS_CLEAR = '@cdhu/CONSULTAR_PRESTACOES_ATRASADAS_CLEAR',
}

export interface ConsultarPrestacoesAtrasadas {
	status: number;
	data: ConsultaPrestacoesAtrasadasData | null;
}

interface PagPrestacao {
	pagPrestOrdem: string;
	pagPrestValor: string;
}

export interface PrestacoesAtField {
	dataVencimentoField: string;
	numeroPrestacaoField: string;
	valorAtualizadoField: string;
	valorBonusField: string;
	valorCrMonetariaField: string;
	valorJurosField: string;
	valorPrestacaoField: string;
}

export interface PrestacoesPRD {
	dataVencimentoField: string;
	numeroPrestacaoField: string;
	valorAtualizadoField: string;
	valorBonusField: string;
	valorCrMonetariaField: string;
	valorJurosField: string;
	valorPrestacaoField: string;
}

export interface ConsultaPrestacoesAtrasadasData {
	codigoEmpreendimentoField: string;
	dataCalculoField: string;
	enderecoField: string;
	nomeEmpreendimentoField: string;
	nomeField: string;
	ocorrenciaId: string;
	pagPrestacao: PagPrestacao[];
	pagValorTotal: string;
	pagValorTotalPRD: string;
	prestacoesAtField: PrestacoesAtField[];
	prestacoesPRD: PrestacoesPRD[];
	quantidadeField: string;
	quantidadePRDField: string;
	statusField: number;
	mensagemField: string;
}

export interface ConsultaPrestacoesAtrasadasRequest {
	numeroContrato: number;
	ocorrenciaId?: string;
	dataLimite: string;
}
