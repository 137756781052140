import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_VIVA_LEITE } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestIncluirNotasFiscais } from './types';

// ACTIONS
import {
	cadastraNotasFiscaisFailure,
	cadastraNotasFiscaisSuccess,
} from './actions';

const evento = {
	id_atendimento: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
	id_cidadao: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
	cpf: '94177708534',
	identificacao: '94177708534',
	ip: '192.168.1.1',
	canal: {
		id: 1,
		desc: 'Presencial',
		localidade: {
			id: 900,
			desc: 'SE',
		},
	},
};

function* cadastraNotasFiscais(request: ReducerAction) {
	const { payload }: { payload: IRequestIncluirNotasFiscais } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_VIVA_LEITE,
		`vivaleite/${CHANNEL}/notas-fiscais`,
		{
			...payload,
			evento,
		},
	);

	if (response.status === 200) {
		yield put(cadastraNotasFiscaisSuccess(response));
	} else if (response.error) {
		yield put(cadastraNotasFiscaisFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRA_NOTAS_FISCAIS, cadastraNotasFiscais),
]);
