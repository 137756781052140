import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Col, Row, Modal } from 'antd';
import { Formik, Form, Field, FormikProps } from 'formik';

import Section from 'components/Common/Section';

import { ApplicationState } from 'store';
import {
	clearBeneficiarioVivaLeite,
	consultaBeneficiario,
	consultaMotivosCorte,
	removeBeneficiarios,
	clearNotificationsBeneficiariosVivaLeite,
} from 'store/modules/api/seds/vivaLeite/beneficiarios/actions';
import { clearNotificationsUsuarioVivaLeite } from 'store/modules/api/seds/vivaLeite/usuario/actions';

import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';
import Select from 'components/Common/Form/Select';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import SimpleTable from 'components/Common/Table';
import Padrao from '../components/pages/Padrao';

import {
	InitialConsultaBeneficiario,
	InitialConsultaMotivosCorte,
	InitialValues,
	schema,
	selectPrograma,
	ICodPrograma,
	treatValues,
	IExclusaoBeneficiarios,
} from './form';
import ExcluirBeneficiariosTable from './Table';

export interface IDataMotivo {
	codigo_motivo: number;
	outros_motivos?: string;
}

export interface IOutrosMotivos {
	outros_motivos: string[];
}

export interface IObjetoExcluirBeneficiario {
	servico: string;
	origem: string;
	num_pan: number;
	cod_programa: number;
	etapa: string;
	exclusoes: IExclusaoBeneficiarios[];
}

const ExcluirBeneficiarios = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const routeMatch = useRouteMatch();
	const formikRef = useRef<FormikProps<ICodPrograma>>(null);

	const {
		beneficiariosData,
		selectMotivosCorte,
		statusTransacaoBeneficiarios,
	} = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.beneficiarios,
	);
	const { selectedUser, statusTransacaoUsuario } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.usuario,
	);

	const [selectedCodigo, setSelectedCodigo] = useState<number[]>([]);
	const [urlServico, setUrlServico] = useState<string>('');
	const [modalExcluir, setModalExcluir] = useState<boolean>(false);
	const [
		requestExcluirData,
		setRequestExcluirData,
	] = useState<IObjetoExcluirBeneficiario>({
		...InitialConsultaBeneficiario,
		exclusoes: [],
	});

	const handleConfirmDelete = useCallback(
		(value: ICodPrograma) => {
			const exclusoes = treatValues(selectedCodigo, value.exclusoes);
			const servico =
				urlServico === 'resultado'
					? 'ATUALIZACAO CADASTRAL'
					: 'PRESTACAO DE CONTAS';

			const requestData = { ...InitialConsultaBeneficiario };
			requestData.servico = servico;
			requestData.num_pan = selectedUser.numeroPan;
			requestData.cod_programa = Number(value.cod_programa);
			requestData.etapa = 'efetiva_exclusao';

			setModalExcluir(true);
			setRequestExcluirData({ ...requestData, exclusoes });
		},
		[selectedCodigo, selectedUser.numeroPan, urlServico],
	);

	const handleDelete = useCallback(() => {
		const request = { ...requestExcluirData };

		request.exclusoes.forEach(item => {
			delete item.nome;
		});

		dispatch(removeBeneficiarios(request));
		setSelectedCodigo([]);
		setRequestExcluirData({ ...requestExcluirData, exclusoes: [] });
		setModalExcluir(false);
		formikRef.current?.setFieldValue('exclusoes', []);
		formikRef.current?.setFieldValue('selectedItems', []);
	}, [dispatch, requestExcluirData]);

	const handleNext = useCallback(() => {
		if (urlServico === 'resultado') {
			history.push(
				'/seds/vivaleite/atualizacaoCadastral/resultado/incluirBeneficiario',
			);
			return;
		}

		history.push('/seds/vivaleite/prestacaoDeContas/incluirBeneficiario');
	}, [history, urlServico]);

	const handleChangeProgram = useCallback(
		(value: number, formik: FormikProps<any>) => {
			formik.setFieldValue('cod_programa', value);

			const servico =
				urlServico === 'resultado'
					? 'ATUALIZACAO CADASTRAL'
					: 'PRESTACAO DE CONTAS';

			const requestData = InitialConsultaBeneficiario;
			requestData.servico = servico;
			requestData.num_pan = selectedUser.numeroPan;
			requestData.cod_programa = value;
			requestData.etapa = 'lista_beneficiarios';

			const requestSelect = InitialConsultaMotivosCorte;
			requestSelect.cod_programa = value;

			dispatch(consultaBeneficiario(requestData));
			dispatch(consultaMotivosCorte(requestSelect));
		},
		[dispatch, selectedUser.numeroPan, urlServico],
	);

	const handleCleanStatus = useCallback(() => {
		if (statusTransacaoUsuario !== '') {
			dispatch(clearNotificationsUsuarioVivaLeite());
		}

		if (statusTransacaoBeneficiarios !== '') {
			dispatch(clearNotificationsBeneficiariosVivaLeite());
		}
	}, [dispatch, statusTransacaoBeneficiarios, statusTransacaoUsuario]);

	const headers = [
		{
			title: 'Código',
			dataIndex: 'cod_pan_beneficio',
		},
		{
			title: 'Nome',
			dataIndex: 'nome',
		},
		{
			title: 'Motivo',
			dataIndex: '',
			render: (text: any) => {
				let motivo = '';

				if (text.codigo_motivo === 9) {
					motivo = text.outros_motivos;
				} else {
					const repeatIndex = selectMotivosCorte.findIndex(newItem => {
						return newItem.value === text.codigo_motivo;
					});
					if (repeatIndex > -1) {
						motivo = selectMotivosCorte[repeatIndex].label;
					}
				}

				return <span>{motivo}</span>;
			},
		},
	];

	useEffect(() => {
		const urlPath = routeMatch.path.split('/');

		setUrlServico(urlPath[urlPath.length - 2]);
	}, [routeMatch]);

	useEffect(() => {
		dispatch(clearBeneficiarioVivaLeite());
	}, [dispatch]);

	useEffect(() => {
		return function cleanup() {
			handleCleanStatus();
		};
	}, [handleCleanStatus]);

	return (
		<Padrao
			statusTransacao={
				statusTransacaoBeneficiarios === ''
					? statusTransacaoUsuario
					: statusTransacaoBeneficiarios
			}
			onCleanStatus={handleCleanStatus}
		>
			<>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Section size="sm" titleBold={false} title="EXCLUIR BENEFICIÁRIO">
							<Row gutter={[0, 10]}>
								<Col span={24}>
									<Formik
										innerRef={formikRef}
										validateOnChange={false}
										validateOnBlur={false}
										initialValues={InitialValues}
										validate={values => getValidationsErrors(values, schema)}
										onSubmit={handleConfirmDelete}
									>
										{formik => {
											return (
												<Form>
													<Row gutter={[0, 10]}>
														<Col span={14}>
															<Field
																title="Incluir Documento"
																name="cod_programa"
																as={Select}
																options={selectPrograma}
																onChange={(v: number) =>
																	handleChangeProgram(v, formik)
																}
																titleSize="lg"
																error={hasError(formik.errors, 'cod_programa')}
															/>
														</Col>
													</Row>
													<Row gutter={[0, 10]} justify="end" align="bottom">
														<Col span={24}>
															<ExcluirBeneficiariosTable
																formik={formik}
																selectCodigo={(v: number[]) =>
																	setSelectedCodigo(v)
																}
																beneficiariosData={beneficiariosData}
																selectMotivosCorte={selectMotivosCorte}
															/>
														</Col>
													</Row>
													<Row gutter={[0, 10]} justify="end">
														<Col>
															<Button type="submit">Excluir</Button>
														</Col>
													</Row>
												</Form>
											);
										}}
									</Formik>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
				<Row gutter={[0, 10]} justify="space-between" align="bottom">
					<Col>
						<ButtonVoltar />
					</Col>
					<Col>
						<ButtonImage src="prosseguir" type="button" onClick={handleNext} />
					</Col>
				</Row>
				<Modal
					title="Confirmar Exclusão"
					visible={modalExcluir}
					onOk={handleDelete}
					onCancel={() => setModalExcluir(false)}
				>
					<p>
						Confira os dados dos beneficiários e em seguida, clique em
						Prosseguir para confirmar as exclusões:
					</p>
					<SimpleTable
						headers={headers}
						body={requestExcluirData.exclusoes}
						striped
						pagination={false}
					/>
				</Modal>
			</>
		</Padrao>
	);
};

export default ExcluirBeneficiarios;
