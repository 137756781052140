import { takeLatest, put, all, call } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_AAC } from 'services/_path';

// STORE
import { ReducerAction } from 'store/modules/types';

// ACTIONS
import {
	consultaSituacaoAtualSuccess,
	consultaSituacaoAtualFailure,
} from './actions';

// TYPES
import { Types, RequestConsultaSituacaoAtual } from './types';

function* consultaSituacaoAtualRequest(request: ReducerAction) {
	const { payload }: { payload: RequestConsultaSituacaoAtual } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_AAC,
		`aac/${CHANNEL}/consulta-situacao-atual`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaSituacaoAtualSuccess(response.data));
	} else if (response.error) {
		yield put(consultaSituacaoAtualFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_SITUACAO_ATUAL_REQUEST,
		consultaSituacaoAtualRequest,
	),
]);
