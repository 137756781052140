import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, LogonRequest } from './types';

// ACTIONS
import { logonSuccess, logonFailure } from './actions';

function* logonRequest(request: ReducerAction) {
	const { payload }: { payload: LogonRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		`logon`,
		payload,
	);

	if (response.status === 200 || response.status === 204) {
		yield put(logonSuccess(response.data));
	} else {
		yield put(logonFailure(response));
	}
}

export default all([takeLatest(Types.LOGON_REQUEST, logonRequest)]);
