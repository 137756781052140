// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	ConsultaReimpressaoFic,
	RequestConsultaReimpressaoFic,
	Types,
} from './types';

export function consultaReimpressaoFicRequest(
	data: RequestConsultaReimpressaoFic,
): ReducerAction {
	return {
		type: Types.CONSULTA_REIMPRESSAO_FIC_REQUEST,
		payload: data,
	};
}
export function consultaReimpressaoFicSuccess(
	payload: ConsultaReimpressaoFic,
): ReducerAction {
	return {
		type: Types.CONSULTA_REIMPRESSAO_FIC_SUCCESS,
		payload,
	};
}
export function consultaReimpressaoFicFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_REIMPRESSAO_FIC_FAILURE,
		payload: null,
	};
}
export function consultaReimpressaoFicClear(): ReducerAction {
	return {
		type: Types.CONSULTA_REIMPRESSAO_FIC_CLEAR,
		payload: null,
	};
}
