export enum Types {
	EXCLUIR_FICHA_CADASTRAL_REQUEST = '@DETRAN-CRV/EXCLUIR_FICHA_CADASTRAL_REQUEST',
	EXCLUIR_FICHA_CADASTRAL_SUCCESS = '@DETRAN-CRV/EXCLUIR_FICHA_CADASTRAL_SUCCESS',
	EXCLUIR_FICHA_CADASTRAL_FAILURE = '@DETRAN-CRV/EXCLUIR_FICHA_CADASTRAL_FAILURE',
	EXCLUIR_FICHA_CADASTRAL_CLEAR = '@DETRAN-CRV/EXCLUIR_FICHA_CADASTRAL_CLEAR',
}

export interface excluirFichaCadastral {
	status: number;
	data: ficha | null;
}

interface ficha {
	ficha: {
		anoFicha: number;
		chassi: string;
		codigoSSP: number;
		dataEmissaoDocumento: string;
		dataHoraExlcusao: string;
		dataInclusaoFicha: string;
		dataInclusaohistorico: string;
		municipio: {
			codigo: number;
			nome: string;
		};
		numeroFicha: number;
		opcao: string;
		placa: string;
		planilhaRenavam: string[];
		renavam: number;
		retornoBin: string;
		retornoConsistencia: string;
		status: string;
	};
}
