import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_AAC } from 'services/_path';

// STORE
import { ReducerAction } from 'store/modules/types';
import handleFailed from 'utils/getValidations';

// ACTIONS
import {
	emissaoAtestadoAntecedentesSuccess,
	emissaoAtestadoAntecedentesFailure,
} from './actions';

// TYPES
import { Types, RequestEmissaoAtestadoAntecedentes } from './types';

function* consultaAtestadoNumeralRequest(request: ReducerAction) {
	const { payload }: { payload: RequestEmissaoAtestadoAntecedentes } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_AAC,
		`aac/${CHANNEL}/emissao-atestado-antecedentes`,
		payload,
		{},
		true,
	);

	if (response.status === 200) {
		yield put(emissaoAtestadoAntecedentesSuccess(response.data));
	} else if (
		(response.data?.status === 400 || response.data?.status === 404) &&
		(response.data?.data?.codigo === 'CO6' ||
			response.data?.data?.codigo === 'CO1' ||
			response.data?.data?.codigo === 'CO2' ||
			response.data?.data?.codigo === 'C13' ||
			response.data?.data?.codigo === 'C17' ||
			response.data?.data?.codigo === 'C89')
	) {
		yield put(
			emissaoAtestadoAntecedentesSuccess({
				pdfBase64: '',
				nomeSocial: '',
				codigo: response.data?.data?.codigo,
				mensagem: response.data?.data?.mensagem,
			}),
		);
	} else if (response.error) {
		handleFailed({
			mensagem_erro: response.data?.data?.mensagem,
		});
		yield put(emissaoAtestadoAntecedentesFailure());
	}
}

export default all([
	takeLatest(
		Types.EMISSAO_ATESTADO_ANTECEDENTES_REQUEST,
		consultaAtestadoNumeralRequest,
	),
]);
