import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, impressaoTrocaVoluntariaPlaca } from './types';

export const INITIAL_STATE: impressaoTrocaVoluntariaPlaca = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): impressaoTrocaVoluntariaPlaca {
	return produce(state, draft => {
		switch (action.type) {
			case Types.IMPRESSAO_TROCA_VOLUNTARIA_PLACA_REQUEST: {
				break;
			}

			case Types.IMPRESSAO_TROCA_VOLUNTARIA_PLACA_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.IMPRESSAO_TROCA_VOLUNTARIA_PLACA_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.IMPRESSAO_TROCA_VOLUNTARIA_PLACA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
