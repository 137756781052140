export enum Types {
	CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_REQUEST = '@DETRAN-CRV/CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_REQUEST',
	CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_SUCCESS = '@DETRAN-CRV/CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_SUCCESS',
	CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_FAILURE = '@DETRAN-CRV/CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_FAILURE',
	CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_CLEAR = '@DETRAN-CRV/CONSULTAR_DETALHES_AUTORIZACAO_PREVIA_CLEAR',
}

export interface consultarDetalhesAutorizacaoPrevia {
	status: number;
	data: consultaAutorizacaoPrevia | null;
}

interface consultaAutorizacaoPrevia {
	consultaAutorizacaoPrevia: {
		anoFabricacao: 0;
		anoModelo: 0;
		capacidadeCarga: 0;
		capacidadePassageiro: 0;
		carroceria: {
			longId: 0;
			descricao: 'string';
		};
		chassi: 'string';
		cilindrada: 0;
		cmt: 0;
		combustivel: {
			longId: 0;
			descricao: 'string';
		};
		cor: {
			longId: 0;
			descricao: 'string';
		};
		cpfResponsavel: 0;
		dataAutorizacaoDetran: '2021-02-03T07:09:32.977Z';
		dataValidadeAutorizacao: '2021-02-03T07:09:32.977Z';
		especie: {
			longId: 0;
			descricao: 'string';
		};
		marca: {
			longId: 0;
			descricao: 'string';
		};
		nomeResponsavel: 'string';
		observacao: 'string';
		pbt: 0;
		placa: 'string';
		potencia: 0;
		renavam: 0;
		tipo: {
			longId: 0;
			descricao: 'string';
		};
		uf: 'string';
		modificacaoTransformacao: {
			capacidadeCarga: 0;
			capacidadePassageiro: 0;
		};
		ocorrencias: [
			{
				codigoAutorizacao: 0;
				dataHoraRegistroSerpro: '2021-02-03T07:09:32.977Z';
				numeroAutorizacao: 'string';
				sistemaConsumidor: 'string';
				situacaoAutorizacao: 'string';
				tipoAutorizacao: 'modificacao';
			},
		];
	};
}
