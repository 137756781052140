import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ExcluirOrgaoRequest } from './types';

// ACTIONS
import { excluirOrgaoSuccess, excluirOrgaoFailure } from './actions';

function* excluirOrgao(request: ReducerAction) {
	const { payload }: { payload: ExcluirOrgaoRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		PATH_SGU,
		`orgaos/${payload.codigo}`,
	);

	if (response.status === 200) {
		yield put(excluirOrgaoSuccess(response));
	} else {
		yield put(excluirOrgaoFailure());
	}
}

export default all([takeLatest(Types.EXCLUIR_ORGAO, excluirOrgao)]);
