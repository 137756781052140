import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { CHANNEL } from 'services/_path';

const CertificadoDigitalIframe: React.FC = () => {
	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const [url, setUrl] = useState('');

	useEffect(() => {
		document.onreadystatechange = () => {
			if (document.readyState === 'complete') {
				const response = {
					cn: '<CN DO CERTIFICADO>',
					message: 'MENSAGEM DO SERVIDOR',
				};
				window.parent.postMessage(response, '*');
			}
		};
	}, []);

	useEffect(() => {
		if (window.location.origin.indexOf('localhost:3000') !== -1) {
			setUrl(
				`https://homologa.poupatempo4g.sp.gov.br/ppt4g-rest-utils/auth/api/v1/mtls/${CHANNEL}/client-certificate/validate/${login.user.cpf}`,
			);
		} else if (
			window.location.origin.indexOf('poupatempo4g.des.sp.gov.br') !== -1
		) {
			setUrl(
				`https://poupatempo4g.des.sp.gov.br/ppt4g-rest-utils/auth/api/v1/mtls/${CHANNEL}/client-certificate/validate/${login.user.cpf}`,
			);
		} else if (
			window.location.origin.indexOf('homologa.poupatempo4g.sp.gov.br') !== -1
		) {
			setUrl(
				`https://homologa.poupatempo4g.sp.gov.br/ppt4g-rest-utils/auth/api/v1/mtls/${CHANNEL}/client-certificate/validate/${login.user.cpf}`,
			);
		} else {
			setUrl(
				`https://poupatempo4g.sp.gov.br/ppt4g-rest-utils/auth/api/v1/mtls/${CHANNEL}/client-certificate/validate/${login.user.cpf}`,
			);
		}
	}, [login.user.cpf]);

	return (
		<iframe
			title="iframeCertificate"
			id="iframeCertificate"
			style={{ display: 'none' }}
			src={url}
		/>
	);
};

export default CertificadoDigitalIframe;
