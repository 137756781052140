// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaAtendimentosDeConsultaRequest, Types } from './types';

export function consultaAtendimentosDeConsultaRequest(
	payload: ConsultaAtendimentosDeConsultaRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTOS_DE_CONSULTA_REQUEST,
		payload,
	};
}
export function consultaAtendimentosDeConsultaSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTOS_DE_CONSULTA_SUCCESS,
		payload,
	};
}
export function consultaAtendimentosDeConsultaFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTOS_DE_CONSULTA_FAILURE,
		payload,
	};
}
export function consultaAtendimentosDeConsultaClear(): ReducerAction {
	return {
		type: Types.CONSULTA_ATENDIMENTOS_DE_CONSULTA_CLEAR,
		payload: null,
	};
}
