import { ReducerAction } from 'store/modules/types';

import { Types, CadastroEnderecosRequest } from './types';

export function cadastroEnderecosRequest(
	data: CadastroEnderecosRequest,
): ReducerAction {
	return {
		type: Types.CADASTRO_ENDERECOS_REQUEST,
		payload: data,
	};
}
export function cadastroEnderecosSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRO_ENDERECOS_SUCCESS,
		payload,
	};
}
export function cadastroEnderecosFailure(): ReducerAction {
	return {
		type: Types.CADASTRO_ENDERECOS_FAILURE,
		payload: null,
	};
}
export function cadastroEnderecosClear(): ReducerAction {
	return {
		type: Types.CADASTRO_ENDERECOS_CLEAR,
		payload: null,
	};
}
