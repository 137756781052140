import * as Yup from 'yup';

import {
	ConsultaTransferenciaEnderecoRequest,
	TransferenciaEndereco,
} from 'store/modules/api/detranCnh/alteracaoEndereco/consultaTransferenciaEndereco/types';
import { AlteraTransferenciaEnderecoRequest } from 'store/modules/api/detranCnh/alteracaoEndereco/alteraTransferenciaEndereco/types';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { onlyZeros } from 'utils/genericFunctions';

export const initialValues: TransferenciaEndereco = {
	cpf: '',
	cep: '',
	logradouro: '',
	numero: '',
	complemento: '',
	bairro: '',
	codigoMunicipioIBGE: '',
	ciretran: '',
	taxaPaga: '',
	dataInicioBloqueio: '',
	dataInicioCassacao: '',
	dataInicioCrimeTransito: '',
	dataInicioSuspensao: '',
	dataTerminoCassacao: '',
	dataTerminoCrimeTransito: '',
	dataTerminoSuspensao: '',
	nomeMunicipio: '',
};

export const initialValuesAlterarEndereco: AlteraTransferenciaEnderecoRequest =
	{
		apresentaDocumentos: 'N',
		bairro: '',
		cep: '',
		codigoMunicipioIBGE: '',
		complemento: '',
		cpf: '',
		cpfUsuario: '',
		logradouro: '',
		numero: '',
		numPgu: '0',
		semNumero: '',
		codigoUnidadeAtendimento: '',
	};

// TREAT TO MOUNT FORM
export function treatInitialValues(
	values: TransferenciaEndereco,
): TransferenciaEndereco {
	const formattedData = {
		cpf: String(values.cpf),
		cep: values.cep,
		logradouro: values.logradouro,
		numero: values.numero,
		complemento: values.complemento,
		bairro: values.bairro,
		codigoMunicipioIBGE: values.codigoMunicipioIBGE,
		ciretran: values.ciretran,
		taxaPaga: values.taxaPaga,
		dataInicioBloqueio: values.dataInicioBloqueio,
		dataInicioCassacao: values.dataInicioCassacao,
		dataInicioCrimeTransito: values.dataInicioCrimeTransito,
		dataInicioSuspensao: values.dataInicioSuspensao,
		dataTerminoCassacao: values.dataTerminoCassacao,
		dataTerminoCrimeTransito: values.dataTerminoCrimeTransito,
		dataTerminoSuspensao: values.dataTerminoSuspensao,
		nomeMunicipio: values.nomeMunicipio,
	};
	return formattedData;
}

// TREAT TO SEND FORM
export function treatValues(
	values: AlteraTransferenciaEnderecoRequest,
	dataConsulta: TransferenciaEndereco,
	requestDataConsulta: ConsultaTransferenciaEnderecoRequest,
	cpfUsuario: string,
	codigoUnidadeAtendimento: string,
	user: IUser,
	idAtendimento: string,
	idCidadao: string,
): AlteraTransferenciaEnderecoRequest {
	const formattedData = {
		apresentaDocumentos: values.apresentaDocumentos,
		bairro: values.bairro,
		cep: values.cep,
		codigoMunicipioIBGE: values.codigoMunicipioIBGE,
		complemento: values.complemento,
		cpf: dataConsulta.cpf,
		cpfUsuario,
		logradouro: values.logradouro,
		numero: values.semNumero === 'S' ? '0' : values.numero,
		numPgu: requestDataConsulta.numPgu?.toString() || '',
		codigoUnidadeAtendimento,
		evento: {
			id_atendimento: idAtendimento,
			id_cidadao: idCidadao,
			cpf: user.cpf,
			identificacao: dataConsulta.cpf,
			ip: user.ip,
			canal: {
				id: 1,
				desc: 'Presencial',
				estacao: {
					id: Number(user.posto),
					desc: user.nomePosto,
				},
				localidade: {
					id: Number(user.posto),
					desc: user.nomePosto,
				},
			},
		},
	};
	return formattedData;
}

export const schema = Yup.object<AlteraTransferenciaEnderecoRequest>().shape({
	// cep: Yup.string().required('Campo Obrigatório: CEP.'),
	logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
	bairro: Yup.string().required('Campo Obrigatório: Bairro.'),
	// codigoMunicipioIBGE: Yup.string().required('Campo Obrigatório: Município.'),
	numeroSemNumero: Yup.string()
		.when(['semNumero', 'numero'], {
			is: (semNumero, numero) =>
				(semNumero.toString() === 'N' || semNumero.toString() === '') &&
				numero.toString() === '',
			then: Yup.string().required('Campo Obrigatório: Número ou Sem Número'),
		})
		.when(['numero'], {
			is: numero => numero.toString() !== '' && onlyZeros(numero.toString()),
			then: Yup.string().required('Campo Inválido: Número.'),
		}),
});
