export enum Types {
	EXCLUIR_MUNICIPIO_REQUEST = '@SGU/EXCLUIR_MUNICIPIO_REQUEST',
	EXCLUIR_MUNICIPIO_SUCCESS = '@SGU/EXCLUIR_MUNICIPIO_SUCCESS',
	EXCLUIR_MUNICIPIO_FAILURE = '@SGU/EXCLUIR_MUNICIPIO_FAILURE',
	EXCLUIR_MUNICIPIO_CLEAR = '@SGU/EXCLUIR_MUNICIPIO_CLEAR',
}

export interface ExcluirMunicipio {
	status: number;
	data: null | MunicipioData;
}

interface MunicipioData {
	status: string;
	statusMensagem: string;
}

export interface ExcluirMunicipioRequest {
	codigo?: string | number;
	descricao: string;
	idUf: string | number;
}
