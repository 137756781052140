export enum Types {
	ALTERAR_CADASTRO_REQUEST = '@cnh/ALTERAR_CADASTRO_REQUEST',
	ALTERAR_CADASTRO_SUCCESS = '@cnh/ALTERAR_CADASTRO_SUCCESS',
	ALTERAR_CADASTRO_FAILURE = '@cnh/ALTERAR_CADASTRO_FAILURE',
	ALTERAR_CADASTRO_CLEAR = '@cnh/ALTERAR_CADASTRO_CLEAR',
}

export interface AlterarCadastro {
	status: number;
	data: AlterarCadastroData | null;
	dataError: AlterarCadastroDataError | null;
	request: AlterarCadastroRequest | null;
}

export interface AlterarCadastroData {
	categoriaPretendida: string;
	codigoInterno: string;
	cpfCidadao: string;
	crm: string;
	crp: string;
	dataExame: string;
	dataExamePsicologo: string;
	enderecoMedico: string;
	enderecoPsicologo: string;
	horarioExame: string;
	horarioExamePsicologo: string;
	message: string;
	nomeCidadao: string;
	nomeMedico: string;
	nomePsicologo: string;
	nomeSocialCidadao: string;
	paisAfetivos1: string;
	paisAfetivos2: string;
	renach: string;
	retornoBCA: string;
	telefones: string;
	telefonesPsicologo: string;
	tipoProcesso: {
		codigo: string;
		descricao: string;
	};
}

export interface AlterarCadastroRequest {
	candidato: {
		autorizaEmail: string;
		autorizaSMS: string;
		bairro: string;
		celular: string;
		cep: string;
		cnhDigital: string;
		codigoCiretranPostoExameMedico: string;
		codigoUnidadeAtendimento: string;
		codigoVerificador: string;
		complemento: string;
		cpf: string;
		dataNascimento: string;
		dataPrimeiraHabilitacao: string;
		dataValidadeCnhE: string;
		dddCelular: string;
		dddTelefone: string;
		email: string;
		logradouro: string;
		nacionalidade: string;
		naturalidade: string;
		necessidadeVeiculoAdaptado: string;
		nome: string;
		nomeMae: string;
		nomePai: string;
		numCNHE: string;
		numDocumento: string;
		numPGU: string;
		numRNE: string;
		numRegistro: string;
		numero: string;
		orgaoDocumento: string;
		orgaoRNE: string;
		paisCNHE: string;
		pretendeAtividadeRemunerada: string;
		renach: string;
		sexo: string;
		telefone: string;
		tipoDocumento: string;
		uf: string;
		ufDocumento: string;
		ufRNE: string;
	};
	dadosExameMedico: {
		codigoCiretranExameMedico: string;
		dataExame: string;
		horarioExame: string;
		idMedico: string;
		idMicroRegiaoMedico: string;
	};
	dadosExamePsicologo: {
		codigoCiretranExamePsicologo: string;
		dataExamePsicologico: string;
		horarioExamePsicologico: string;
		idMicroRegiaoPsicologo: string;
		idPsicologo: string;
	};
}

interface AlterarCadastroDataError {
	transacao: string;
	codigo: number;
	mensagem: string;
}
