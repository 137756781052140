export enum Types {
	CADASTRA_FORNECEDOR_REQUEST = '@procon/CADASTRA_FORNECEDOR_REQUEST',
	CADASTRA_FORNECEDOR_SUCCESS = '@procon/CADASTRA_FORNECEDOR_SUCCESS',
	CADASTRA_FORNECEDOR_FAILURE = '@procon/CADASTRA_FORNECEDOR_FAILURE',
	CADASTRA_FORNECEDOR_CLEAR = '@procon/CADASTRA_FORNECEDOR_CLEAR',
}

export interface CadastraFornecedor {
	status: null | number;
	data: null | CadastraFornecedorData;
}

interface CadastraFornecedorData {
	mensagem: string;
	data: CadastraFornecedorInputData;
}

interface CadastraFornecedorInputData {
	id: string;
}

export interface CadastraFornecedorRequest {
	codigo: string;
	tipoFornecedor: string;
	nome: string;
	nomeFantasia: string;
	webSite: string;
	email: string;
	email1: string;
	email2: string;
	telefone: string;
	descricaoTelefone: string;
	fone1: string;
	descricaoTelefone1: string;
	fone2: string;
	descricaoTelefone2: string;
	fone3: string;
	descricaoTelefone3: string;
	numDocumento: string;
	cpfCnpj: string;
	inscricaoEstadual: string;
	slcOutrosDocumentos: string;
	outrosDocumentos: string;
	codigoEndereco: string;
	idMunicipio: string;
	enderecoPrincipalCep: string;
	enderecoPrincipalLogradouro: string;
	enderecoPrincipalNumero: string;
	enderecoPrincipalComplemento: string;
	enderecoPrincipalBairro: string;
	enderecoPrincipalMunicipio: string;
	enderecoPrincipalUf: string;
	codigoEnderecoSecundario: string;
	idMunicipioSecundario: string;
	enderecoSecundarioCEP: string;
	enderecoSecundarioLogradouro: string;
	enderecoSecundarioNumero: string;
	enderecoSecundarioComplemento: string;
	enderecoSecuindarioBairro: string;
	enderecoSecundarioMunicipio: string;
	enderecoSecundariolUF: string;
	observacao: string;
	bitPrioritario: string;
	usuarioCriacao: string;
	usuarioAlteracao: string;
	usuarioLogado: string;
	mesmoEnderecoCorrespondencia: string;
}

export interface CadastraFornecedorForm {
	codigo: string;
	tipoFornecedor: string;
	nome: string;
	nomeFantasia: string;
	webSite: string;
	email: string;
	email1: string;
	email2: string;
	telefone: string;
	descricaoTelefone: string;
	fone1: string;
	descricaoTelefone1: string;
	fone2: string;
	descricaoTelefone2: string;
	fone3: string;
	descricaoTelefone3: string;
	numDocumento: string;
	cpfCnpj: string;
	inscricaoEstadual: string;
	slcOutrosDocumentos: string;
	outrosDocumentos: string;
	codigoEndereco: string;
	idMunicipio: string;
	enderecoPrincipalCep: string;
	enderecoPrincipalLogradouro: string;
	enderecoPrincipalNumero: string;
	enderecoPrincipalComplemento: string;
	enderecoPrincipalBairro: string;
	enderecoPrincipalMunicipio: string;
	enderecoPrincipalUf: string;
	codigoEnderecoSecundario: string;
	idMunicipioSecundario: string;
	enderecoSecundarioCEP: string;
	enderecoSecundarioLogradouro: string;
	enderecoSecundarioNumero: string;
	enderecoSecundarioComplemento: string;
	enderecoSecuindarioBairro: string;
	enderecoSecundarioMunicipio: string;
	enderecoSecundariolUF: string;
	observacao: string;
	bitPrioritario: string;
	usuarioCriacao: string;
	usuarioAlteracao: string;
	usuarioLogado: string;
	mesmoEnderecoCorrespondencia: string;
}
