import { Types, ConsultarVeiculoV110Request } from './types';

export function consultarVeiculoV110Request(
	payload: ConsultarVeiculoV110Request,
) {
	return {
		type: Types.CONSULTAR_VEICULO_V110_REQUEST,
		payload,
	};
}
export function consultarVeiculoV110Success(payload: any) {
	return {
		type: Types.CONSULTAR_VEICULO_V110_SUCCESS,
		payload,
	};
}
export function consultarVeiculoV110Failure(payload: []): any {
	return {
		type: Types.CONSULTAR_VEICULO_V110_FAILURE,
		payload,
	};
}
export function consultarVeiculoV110Clear(): any {
	return {
		type: Types.CONSULTAR_VEICULO_V110_CLEAR,
	};
}
