import produce from 'immer';

// UTILS
import { toSelect } from 'utils/genericFunctions';

// TYPES
import { Types, microRegioes } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: microRegioes = {
	status: 0,
	data: [],
};

export default function microRegioesReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): microRegioes {
	return produce(state, draft => {
		switch (action.type) {
			case Types.MICRO_REGIOES: {
				break;
			}

			case Types.MICRO_REGIOES_SUCCESS: {
				if (action.payload.data.micro_regioes_medico) {
					const microRegioesData = toSelect(
						action.payload.data.micro_regioes_medico,
						'nome',
						'long_id',
					);

					draft.data = microRegioesData;
				} else {
					const microRegioesData = toSelect(
						action.payload.data.micro_regioes_psicologo,
						'nome',
						'long_id',
					);

					draft.data = microRegioesData;
				}
				break;
			}

			case Types.MICRO_REGIOES_FAILURE: {
				break;
			}

			case Types.MICRO_REGIOES_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				break;
			}

			default:
		}
	});
}
