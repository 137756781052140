import produce from 'immer';

// TYPES
import { Types, consultaReabilitacao } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaReabilitacao = {
	status: 0,
	data: null,
	dataError: null,
	requestData: null,
};

export default function consultaReabilitacaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaReabilitacao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_REABILITACAO: {
				draft.requestData = action.payload.request;
				draft.dataError = INITIAL_STATE.dataError;
				draft.data = INITIAL_STATE.data;
				draft.status = 100;
				break;
			}

			case Types.CONSULTA_REABILITACAO_SUCCESS: {
				draft.requestData = action.payload.request;
				draft.data = action.payload.response.data;
				draft.dataError = INITIAL_STATE.dataError;
				draft.status = action.payload.response.status;
				break;
			}

			case Types.CONSULTA_REABILITACAO_FAILURE: {
				draft.requestData = action.payload.request;
				draft.data = INITIAL_STATE.data;
				draft.dataError = action.payload.response.data;
				draft.status = action.payload.response.status;
				break;
			}

			case Types.CONSULTA_REABILITACAO_CLEAR: {
				draft.requestData = INITIAL_STATE.requestData;
				draft.data = INITIAL_STATE.data;
				draft.dataError = INITIAL_STATE.dataError;
				draft.status = INITIAL_STATE.status;
				break;
			}

			default:
		}
	});
}
