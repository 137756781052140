import { all } from 'redux-saga/effects';
import eventoInicioSessaoSaga from './inicioSessao/saga';
import eventoTerminoSessaoSaga from './terminoSessao/saga';
import eventoConfrontoSessaoSaga from './confronto/saga';

export default all([
	eventoInicioSessaoSaga,
	eventoTerminoSessaoSaga,
	eventoConfrontoSessaoSaga,
]);
