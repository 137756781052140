import { lazy } from 'react';

// PATHS
import {
	ROUTE_SGU_RELATORIO_ESTATISTICA,
	ROUTE_SGU_RELATORIO_QUEIMA_DARE,
	ROUTE_SGU_RELATORIO_USUARIO_POR_LOCALIDADE,
	ROUTE_SGU_RELATORIO_SITUACAO_DARE,
	ROUTE_SGU_RELATORIO_RELATORIO_MENSAL_DARE,
} from './paths';

// COMPONENTS
const RelacaoUsuarios = lazy(() => import('../RelacaoUsuarios'));
const Estatistica = lazy(() => import('../Estatistica'));
const QueimaDare = lazy(() => import('../QueimaDare'));
const ConsultaStatusDare = lazy(() => import('../ConsultaStatusDare'));
const RelatorioMensalDare = lazy(() => import('../RelatorioMensalDare'));

export const SGURoute = {
	name: 'Poupatempo - SGU',
	route: '/poupatempo',
};

const relatoriosRoutes = [
	{
		path: ROUTE_SGU_RELATORIO_USUARIO_POR_LOCALIDADE,
		breadcrumb: [
			SGURoute,
			{
				name: 'Relatórios - Relação de Usuários ',
			},
		],
		Component: RelacaoUsuarios,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_RELATORIO_ESTATISTICA,
		breadcrumb: [
			SGURoute,
			{
				name: 'Relatórios - Estatística ',
			},
		],
		Component: Estatistica,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_RELATORIO_QUEIMA_DARE,
		breadcrumb: [
			SGURoute,
			{
				name: 'Relatórios - Queima da DARE ',
			},
		],
		Component: QueimaDare,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_RELATORIO_SITUACAO_DARE,
		breadcrumb: [
			SGURoute,
			{
				name: 'Relatórios - Consulta Status DARE',
			},
		],
		Component: ConsultaStatusDare,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_RELATORIO_RELATORIO_MENSAL_DARE,
		breadcrumb: [
			SGURoute,
			{
				name: 'Relatórios - Relatório Mensal DARE',
			},
		],
		Component: RelatorioMensalDare,
		isAuthenticated: true,
		exact: true,
	},
];

export default relatoriosRoutes;
