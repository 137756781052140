import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DARE } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { DisponibilzarDareRequest, Types } from './types';
import { disponibilzarDareSuccess, disponibilzarDareFailure } from './actions';

function* disponibilzarDareRequest(request: ReducerAction) {
	const { payload }: { payload: DisponibilzarDareRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DARE,
		`/potencial/${CHANNEL}/envia-dare`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(disponibilzarDareSuccess(response));
	} else if (response.error) {
		yield put(disponibilzarDareFailure(response.message));
	}
}

export default all([
	takeLatest(Types.DISPONIBILIZAR_DARE_REQUEST, disponibilzarDareRequest),
]);
