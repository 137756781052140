import { ReducerAction } from 'store/modules/types';
import { IParametroData } from 'store/modules/api/servicosMunicipais/parametros/types';

import { Types } from './types';

export interface IConsultaServicosRequest {
	servico?: string;
}

export interface IConsultaParametrosServicosRequest {
	codigo: any;
}

export interface ICadastraServicosRequest {
	codigo?: number;
	codigo_contabilizacao: string;
	descricao: string;
	descricao_contabilizacao: string;
	tipo_contabilizacao: string;
}

export interface IAlteraParametrosServicosRequest {
	body: IParametroData[];
	codigo?: number;
	situation: string;
}

export function consultaServicos(
	data: IConsultaServicosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_SERVICOS,
		payload: data,
	};
}
export function consultaServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_SERVICOS_SUCCESS,
		payload,
	};
}
export function consultaServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_SERVICOS_FAILURE,
		payload,
	};
}

export function consultaParametrosServicos(
	data: IConsultaParametrosServicosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_PARAMETROS_SERVICOS,
		payload: data,
	};
}
export function consultaParametrosServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PARAMETROS_SERVICOS_SUCCESS,
		payload,
	};
}
export function consultaParametrosServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PARAMETROS_SERVICOS_FAILURE,
		payload,
	};
}

export function cadastraServicos(
	data: ICadastraServicosRequest,
): ReducerAction {
	return {
		type: Types.CADASTRA_SERVICOS,
		payload: data,
	};
}
export function cadastraServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.CADASTRA_SERVICOS_SUCCESS,
		payload,
	};
}
export function cadastraServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.CADASTRA_SERVICOS_FAILURE,
		payload,
	};
}

export function alteraServicos(data: ICadastraServicosRequest): ReducerAction {
	return {
		type: Types.ALTERA_SERVICOS,
		payload: data,
	};
}
export function alteraServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_SERVICOS_SUCCESS,
		payload,
	};
}
export function alteraServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_SERVICOS_FAILURE,
		payload,
	};
}

export function alteraParametrosServicos(
	data: IAlteraParametrosServicosRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_PARAMETROS_SERVICOS,
		payload: data,
	};
}
export function alteraParametrosServicosSuccess(
	payload: IAlteraParametrosServicosRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_PARAMETROS_SERVICOS_SUCCESS,
		payload,
	};
}
export function alteraParametrosServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_PARAMETROS_SERVICOS_FAILURE,
		payload,
	};
}

export function removeServicos(data: number): ReducerAction {
	return {
		type: Types.REMOVE_SERVICOS,
		payload: data,
	};
}
export function removeServicosSuccess(payload: number): ReducerAction {
	return {
		type: Types.REMOVE_SERVICOS_SUCCESS,
		payload,
	};
}
export function removeServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.REMOVE_SERVICOS_FAILURE,
		payload,
	};
}

export function clearRedirect(): ReducerAction {
	return {
		type: Types.REDIRECT_SERVICOS_CLEAR,
		payload: null,
	};
}

export function nextStepClear(): ReducerAction {
	return {
		type: Types.NEXT_STEP_SERVICOS_CLEAR,
		payload: null,
	};
}
