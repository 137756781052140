import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// ANT DESIGN
import { Col, Row } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { estadosRequest } from 'store/modules/api/procon/dominiosCrm/estados/actions';
import {
	clearNotifications,
	showInside,
} from 'store/modules/app/notifications/actions';
import {
	municipiosClear,
	municipiosRequest,
} from 'store/modules/api/procon/dominiosCrm/municipios/actions';
import { CadastraAtendimentoPfRequest } from 'store/modules/api/procon/atendimentoCip/cadastraAtendimentoPf/types';
import { PesquisaFornecedorForm } from 'store/modules/api/procon/fornecedores/consultaFornecedor/types';
import { CadastroConsultaForm } from 'store/modules/api/procon/atendimentosDeConsulta/cadastro/types';
import { pesquisaFornecedorClear } from 'store/modules/api/procon/fornecedores/pesquisaFornecedor/actions';
import { consultaFornecedorClear } from 'store/modules/api/procon/fornecedores/consultaFornecedor/actions';

// COMPONENTS
import LightButton from 'components/Common/Buttons/Light';
import InputMask from 'components/Common/Form/Input/InputMask';
import TypographyCommon from 'components/Common/Typography';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

// ENUM
import EnumProcon from 'store/modules/api/procon/enum';

// COMPONENTS
import CPF from 'components/Common/Form/Fields/CPF';

// FORM
import { Field, Form, Formik, FormikProps } from 'formik';
import Radio from 'components/Common/Form/Radio';
import {
	letrasAcentos,
	letrasAcentosNumeros,
	letrasNumeros,
} from 'utils/stringFunctions';
import {
	CadastraFornecedorConsultaForm,
	CadastraFornecedorReclamacaoForm,
	initialValues,
	initialValuesConsulta,
	schema,
	schemaConsulta,
	treatValues,
} from './form';

// COMPONENTS
import ConsultaMunicipio from '../../CadastrarReclamacao/components/consultaMunicipio';

interface Props {
	formikParent: FormikProps<
		CadastraAtendimentoPfRequest | CadastroConsultaForm
	>;
	formikPesquisa: FormikProps<PesquisaFornecedorForm>;
	setType: (v: 'add' | 'check' | null) => void;
	setShowModal: (v: 'none' | 'fornecedor' | 'consumidor') => void;
	typeRegistration: 'consulta' | 'reclamacao';
	setAddDisabledPesquisa: (v: boolean) => void;
}
const CadastroFornecedor: React.FC<Props> = ({
	formikParent,
	formikPesquisa,
	setType,
	setShowModal,
	typeRegistration,
	setAddDisabledPesquisa,
}) => {
	const dispatch = useDispatch();

	const [formData, setFormData] = useState(initialValues);

	useEffect(() => {
		dispatch(municipiosClear());
		showInside(true);
	}, [dispatch]);

	const estados = useSelector(
		(state: ApplicationState) => state.api.procon.dominiosCrm.estados.data,
	);

	const resultadosPesquisa = useSelector(
		(state: ApplicationState) => state.api.procon.dominiosCrm.municipios,
	);

	const handleOnChangeCep = useCallback(
		(
			res: IBuscarCepResultado,
			formik: FormikProps<CadastraFornecedorReclamacaoForm>,
		) => {
			dispatch(municipiosClear());
			setFormData({
				...formik.values,
				municipioDescricao: res.municipio,
				municipioId: res.codigoMunicipio.toString(),
				cep: res.cep,
				logradouro: res.endereco.substring(0, 100),
				bairro: res.bairro.substring(0, 60),
				estado:
					Number(
						estados.find(
							item => res.estado.toUpperCase() === item.label.toUpperCase(),
						)?.value,
					) || 0,
			});
			if (res.municipio) {
				dispatch(municipiosRequest({ filtro: res.municipio }));
			}
		},
		[dispatch, estados],
	);

	useEffect(() => {
		if (resultadosPesquisa.status === 200) {
			if (
				resultadosPesquisa.data.length > 0 &&
				resultadosPesquisa.data[0].value &&
				formData.municipioId !== resultadosPesquisa.data[0].value
			) {
				setFormData(prev => {
					return {
						...prev,
						municipioId: resultadosPesquisa.data[0].value.toString(),
					};
				});
			}
		}
	}, [
		formData.municipioId,
		resultadosPesquisa.data,
		resultadosPesquisa.status,
	]);

	const handleSubmit = useCallback(
		(values: CadastraFornecedorReclamacaoForm) => {
			const formattedValues = treatValues(values, formikPesquisa);

			formikParent.setFieldValue('novoFornecedor', { ...formattedValues });
			formikParent.setFieldValue('fornecedorId', '');
			formikParent.setFieldValue('fornecedorNome', formattedValues.razaoSocial);

			setShowModal('none');
		},
		[formikParent, formikPesquisa, setShowModal],
	);

	const handleCancelar = useCallback(() => {
		setType(null);
		setAddDisabledPesquisa(false);
		formikPesquisa.resetForm();
		dispatch(pesquisaFornecedorClear());
		dispatch(consultaFornecedorClear());
		dispatch(clearNotifications());
	}, [dispatch, formikPesquisa, setAddDisabledPesquisa, setType]);

	const handleSubmitConsulta = useCallback(
		(values: CadastraFornecedorConsultaForm) => {
			formikParent.setFieldValue('nomeDoFornecedor', values.razaoSocial);

			setShowModal('none');
		},
		[formikParent, setShowModal],
	);

	const handleInitialValueRazaoSocial = useCallback(
		(
			formik:
				| FormikProps<CadastraFornecedorConsultaForm>
				| FormikProps<CadastraFornecedorReclamacaoForm>,
		) => {
			if (formikPesquisa.values.termo && !formik.values.razaoSocial) {
				formik.setFieldValue('razaoSocial', formikPesquisa.values.termo);
				return true;
			}
			return false;
		},
		[formikPesquisa],
	);

	const handleInitialValueCnpj = useCallback(
		(
			formik:
				| FormikProps<CadastraFornecedorConsultaForm>
				| FormikProps<CadastraFornecedorReclamacaoForm>,
		) => {
			if (formikPesquisa.values.cnpj && !formik.values.cnpj) {
				formik.setFieldValue('cnpj', formikPesquisa.values.cnpj);
				return true;
			}
			return false;
		},
		[formikPesquisa],
	);

	useEffect(() => {
		if (!estados.length) dispatch(estadosRequest());
	}, [dispatch, estados]);

	useEffect(
		() => () => {
			setFormData(initialValues);
		},
		[dispatch, setFormData],
	);

	return (
		<>
			<Row gutter={[0, 0]} align="middle" justify="start">
				<Col span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Fornecedor"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>

			{typeRegistration === 'consulta' && (
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValuesConsulta}
					validate={values => getValidationsErrors(values, schemaConsulta)}
					onSubmit={handleSubmitConsulta}
				>
					{formik => (
						<Form autoComplete="off">
							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={24} style={{ height: '50px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Razão Social:"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={Input}
												value={
													handleInitialValueRazaoSocial(formik)
														? formikPesquisa.values.termo
														: formik.values.razaoSocial
												}
												name="razaoSocial"
												error={hasError(formikParent.errors, 'razaoSocial')}
												required
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="center">
								<Col span={24}>
									<Row gutter={[0, 0]} align="middle" justify="center">
										<Col>
											<LightButton
												type="button"
												text="Cancelar"
												buttonColor="default"
												buttonSize="sm"
												buttonWidth={140}
												onClick={() => handleCancelar()}
											/>
										</Col>
										<Col offset={1}>
											<LightButton
												type="submit"
												text="Salvar"
												buttonColor="default"
												buttonSize="sm"
												buttonWidth={140}
												icon="add"
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			)}

			{typeRegistration === 'reclamacao' && (
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={formData}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={24} style={{ height: '50px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Tipo:"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={Radio}
												name="tipo"
												options={EnumProcon.radioCadastroFornecedor}
												error={hasError(formik.errors, 'tipo')}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col
									span={formik.values.tipo === 'CNPJ' ? 12 : 24}
									style={{ height: '50px' }}
								>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Razão Social:"
											fontWeight="bold"
											required
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={formik.values.tipo === 'CNPJ' ? 23 : 24}>
											<Field
												as={Input}
												name="razaoSocial"
												value={
													handleInitialValueRazaoSocial(formik)
														? formikPesquisa.values.termo
														: formik.values.razaoSocial
												}
												error={hasError(formik.errors, 'razaoSocial')}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'razaoSocial',
														letrasAcentosNumeros(v.target.value),
													);
												}}
												required
											/>
										</Col>
									</Row>
								</Col>
								{formik.values.tipo === 'CNPJ' && (
									<Col span={12} style={{ height: '50px' }}>
										<Row gutter={[0, 0]} align="middle" justify="start">
											<TypographyCommon
												text="Nome Fantasia:"
												fontWeight="bold"
												uppercase
											/>
										</Row>
										<Row gutter={[6, 0]} align="middle" justify="start">
											<Col span={24}>
												<Field
													as={Input}
													name="nomeFantasia"
													error={hasError(formik.errors, 'nomeFantasia')}
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'nomeFantasia',
															letrasAcentosNumeros(v.target.value),
														);
													}}
													required
												/>
											</Col>
										</Row>
									</Col>
								)}
							</Row>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col
									span={formik.values.tipo === '' ? 24 : 12}
									style={{ height: '50px' }}
								>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="E-mail:"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={formik.values.tipo === '' ? 24 : 23}>
											<Field
												as={Input}
												name="email"
												error={hasError(
													formik.errors,
													'validacaoEmailOuEndereco',
												)}
												required
											/>
										</Col>
									</Row>
								</Col>
								{formik.values.tipo === 'CPF' && (
									<Col span={12} style={{ height: '50px' }}>
										<Row gutter={[0, 0]} align="middle" justify="start">
											<TypographyCommon
												text="CPF:"
												fontWeight="bold"
												uppercase
											/>
										</Row>
										<Row gutter={[6, 0]} align="middle" justify="start">
											<Col span={24}>
												<Field
													as={CPF}
													numero="cpf"
													formik={formik}
													error={hasError(formik.errors, 'validacaoCPF')}
													removeContainer
												/>
											</Col>
										</Row>
									</Col>
								)}
								{formik.values.tipo === 'CNPJ' && (
									<Col span={12} style={{ height: '50px' }}>
										<Row gutter={[0, 0]} align="middle" justify="start">
											<TypographyCommon
												text="CNPJ:"
												fontWeight="bold"
												required
												uppercase
											/>
										</Row>
										<Row gutter={[6, 0]} align="middle" justify="start">
											<Col span={24}>
												<Field
													as={InputMask}
													name="cnpj"
													mask="99.999.999/0001-99"
													maskChar=""
													value={
														handleInitialValueCnpj(formik)
															? formikPesquisa.values.cnpj
															: formik.values.cnpj
													}
													error={hasError(formik.errors, 'validacaoCNPJ')}
												/>
											</Col>
										</Row>
									</Col>
								)}
							</Row>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={12} style={{ height: '50px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Telefone:"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={23}>
											<Field
												as={InputMask}
												name="telefone"
												value={formik.values.telefone}
												mask="(99)9999-9999"
												maskChar=""
												error={hasError(formik.errors, 'telefone')}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'telefone',
														e.currentTarget.value,
													);
												}}
											/>
										</Col>
									</Row>
								</Col>
								<Col span={12} style={{ height: '50px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Celular:"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={InputMask}
												name="celular"
												value={formik.values.celular}
												mask="(99)99999-9999"
												maskChar=""
												error={hasError(formik.errors, 'celular')}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'celular',
														e.currentTarget.value,
													);
												}}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={18} style={{ height: '50px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Website:"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={23}>
											<Field
												as={Input}
												name="website"
												error={hasError(formik.errors, 'website')}
											/>
										</Col>
									</Row>
								</Col>
								<Col span={6} style={{ height: '50px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon text="CEP:" fontWeight="bold" uppercase />
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={CEP}
												title=""
												name="cep"
												formik={formik}
												defaultValue={formik.values.cep}
												result={(res: IBuscarCepResultado) =>
													handleOnChangeCep(res, formik)
												}
												error={hasError(
													formik.errors,
													'validacaoEmailOuEndereco',
												)}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={24} style={{ height: '50px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Logradouro:"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={Input}
												name="logradouro"
												maxLength="250"
												error={hasError(
													formik.errors,
													'validacaoEmailOuEndereco',
												)}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'logradouro',
														letrasAcentos(v.target.value),
													);
												}}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={6} style={{ height: '45px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Número:"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={23}>
											<Field
												as={Input}
												name="numero"
												maxLength="6"
												error={hasError(
													formik.errors,
													'validacaoEmailOuEndereco',
												)}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'numero',
														letrasNumeros(v.target.value),
													);
												}}
											/>
										</Col>
									</Row>
								</Col>
								<Col span={18} style={{ height: '45px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Complemento:"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={24}>
											<Field
												as={Input}
												name="complemento"
												maxLength="110"
												error={hasError(formik.errors, 'complemento')}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'complemento',
														letrasAcentos(v.target.value),
													);
												}}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="start">
								<Col span={6} style={{ height: '50px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Bairro:"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={23}>
											<Field
												as={Input}
												name="bairro"
												maxLength="55"
												error={hasError(
													formik.errors,
													'validacaoEmailOuEndereco',
												)}
												onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
													formik.setFieldValue(
														'bairro',
														letrasAcentos(v.target.value),
													);
												}}
											/>
										</Col>
									</Row>
								</Col>
								<Col span={9} style={{ height: '50px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Estado:"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={23}>
											<Field
												as={Select}
												name="estado"
												options={estados}
												error={hasError(
													formik.errors,
													'validacaoEmailOuEndereco',
												)}
												disabled
											/>
										</Col>
									</Row>
								</Col>
								<Col span={8} style={{ height: '50px' }}>
									<Row gutter={[0, 0]} align="middle" justify="start">
										<TypographyCommon
											text="Município:"
											fontWeight="bold"
											uppercase
										/>
									</Row>
									<Row gutter={[6, 0]} align="middle" justify="start">
										<Col span={23}>
											<Field
												as={Input}
												name="municipioDescricao"
												error={hasError(
													formik.errors,
													'validacaoEmailOuEndereco',
												)}
												disabled
											/>
										</Col>
									</Row>
								</Col>
								<Col
									span={1}
									style={{
										paddingTop: '34px',
									}}
								>
									<Row gutter={[6, 0]} align="middle" justify="center">
										<Col span={24}>
											<ConsultaMunicipio formikParent={formik} />
										</Col>
									</Row>
								</Col>
							</Row>

							<br />

							<Row gutter={[0, 0]} align="middle" justify="center">
								<Col span={24}>
									<Row gutter={[0, 0]} align="middle" justify="center">
										<Col>
											<LightButton
												type="button"
												text="Cancelar"
												buttonColor="default"
												buttonSize="sm"
												buttonWidth={140}
												onClick={() => setType(null)}
											/>
										</Col>
										<Col offset={1}>
											<LightButton
												type="submit"
												text="Salvar"
												buttonColor="default"
												buttonSize="sm"
												buttonWidth={140}
												icon="add"
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};
export default CadastroFornecedor;
