export enum Types {
	PESQUISAR_CONTATO = '@cnh/PESQUISAR_CONTATO',
	PESQUISAR_CONTATO_SUCCESS = '@cnh/PESQUISAR_CONTATO_SUCCESS',
	PESQUISAR_CONTATO_FAILURE = '@cnh/PESQUISAR_CONTATO_FAILURE',
	PESQUISAR_CONTATO_CLEAR = '@cnh/PESQUISAR_CONTATO_CLEAR',
}

export interface PesquisarContato {
	status: number;
	data: null | PesquisarContatoData;
}

export interface PesquisarContatoData {
	cpf: string;
	codigoAreaCelular: string;
	telefoneCelular: string;
	email: string;
	emailCNHDigital: string;
	nomeCompleto: string;
	codigoPais?: string;
}

export interface PesquisarContatoRequest {
	evento: {
		tipo: string;
		id_servico: number;
		desc_servico: string;
		id_atendimento: string;
		id_cidadao: string;
		cpf: string;
		identificacao: string;
		ip: string;
		id_orgao: number;
		desc_orgao: string;
		canal: {
			id: number;
			desc: string;
			estacao: {
				id: number;
				desc: string;
			};
			localidade: {
				id: number;
				desc: string;
			};
		};
		atendente: {
			nome: string;
			id_atendente: string;
			id_posto: string;
			desc_posto: string;
		};
	};
	cpf: string;
}
