import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_CATEGORIA_REQUEST = '@DETRAN-CRV/CONSULTA_CATEGORIA_REQUEST',
	CONSULTA_CATEGORIA_SUCCESS = '@DETRAN-CRV/CONSULTA_CATEGORIA_SUCCESS',
	CONSULTA_CATEGORIA_FAILURE = '@DETRAN-CRV/CONSULTA_CATEGORIA_FAILURE',
}

export interface consultaCategoria {
	status: number;
	data: OptionProps[];
}
