import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { ApplicationState } from 'store';
import {
	SinalizaResolucaoConsultaAtendimentoForm,
	SinalizaResolucaoConsultaAtendimentoRequest,
} from 'store/modules/api/procon/atendimentos/sinalizaResolucaoConsultaAtendimento/types';
import {
	CancelaConsultaAtendimentoForm,
	CancelaConsultaAtendimentoRequest,
} from 'store/modules/api/procon/atendimentos/cancelaConsultaAtendimento/types';
import { sinalizaResolucaoConsultaAtendimentoRequest } from 'store/modules/api/procon/atendimentos/sinalizaResolucaoConsultaAtendimento/actions';
import { cancelaConsultaAtendimentoRequest } from 'store/modules/api/procon/atendimentos/cancelaConsultaAtendimento/actions';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';

// ANT DESIGN
import { Col, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';

// COMPONENTS
import { Form, Formik, Field } from 'formik';
import TextArea from 'components/Common/Form/Input/TextArea';
import LightButton from 'components/Common/Buttons/Light';
import TypographyCommon from 'components/Common/Typography';

// FORM
import { getTimestamp } from 'utils/dateFunctions';
import { limparMascara } from 'utils/genericFunctions';
import {
	ICadastroEventoRequest,
	IDadosRequest,
} from 'store/modules/api/contabilizacao/cadastroEvento/types';
import { cadastroEventoRequest } from 'store/modules/api/contabilizacao/cadastroEvento/actions';
import {
	initialValuesResolvido,
	schemaResolvido,
	initialValuesCancelar,
	schemaCancelar,
} from './form';

type TypeDataAtendimento = {
	protocolo: string;
	atendimentoId: string;
};

type TypeAccounting = {
	resolvido: {
		id_servico: string | number;
		desc_servico: string;
		tipo: string;
	};
	cancelado: {
		id_servico: string | number;
		desc_servico: string;
		tipo: string;
	};
};

interface Props {
	visivel: boolean;
	resolvido: boolean | null;
	setShowAlert: (value: React.SetStateAction<boolean>) => void;
	setShowModal: (value: React.SetStateAction<boolean>) => void;
	dataAtendimento: TypeDataAtendimento | null;
}

const ResolucaoConsultaModal: React.FC<Props> = ({
	visivel = false,
	resolvido = null,
	setShowAlert,
	setShowModal,
	dataAtendimento,
}) => {
	const dispatch = useDispatch();

	const ref = useRef<any>();

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.salvarAtendimento.data,
	);

	const { consultaAtendimentos } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentos,
	);

	const { sinalizaResolucaoConsultaAtendimento, cancelaConsultaAtendimento } =
		useSelector((state: ApplicationState) => state.api.procon.atendimentos);

	const requestResolvido = useCallback(
		(values: SinalizaResolucaoConsultaAtendimentoForm) => {
			const payload: SinalizaResolucaoConsultaAtendimentoRequest = {
				atendimentoId: dataAtendimento?.atendimentoId || '',
				justificativa: values.justificativa || '',
			};

			dispatch(sinalizaResolucaoConsultaAtendimentoRequest(payload));
		},

		[dataAtendimento, dispatch],
	);

	const requestCancelar = useCallback(
		(values: CancelaConsultaAtendimentoForm) => {
			const payload: CancelaConsultaAtendimentoRequest = {
				atendimentoId: dataAtendimento?.atendimentoId || '',
				justificativa: values.justificativa || '',
			};

			dispatch(cancelaConsultaAtendimentoRequest(payload));
		},

		[dataAtendimento, dispatch],
	);

	const handleTipoContabilizacao = useMemo(() => {
		return {
			resolvido: {
				id_servico: 13,
				desc_servico: 'manifestacao conclusao resolver',
				tipo: 'procon_manifestacao_conclusao_resolver',
			},
			cancelado: {
				id_servico: 14,
				desc_servico: 'manifestacao conclusao cancelar',
				tipo: 'procon_manifestacao_conclusao_cancelar',
			},
		};
	}, []);

	const handleContabilizar = useCallback(() => {
		const typeRegistration = resolvido ? 'resolvido' : 'cancelado';

		const tipoContabilizacao: TypeAccounting['resolvido' | 'cancelado'] =
			handleTipoContabilizacao[typeRegistration];

		const dados: IDadosRequest = {
			data_hora: getTimestamp().toString() || '',
			id_servico: tipoContabilizacao.id_servico || '',
			desc_servico: tipoContabilizacao.desc_servico || '',
			identificacao: Number(limparMascara(consultaAtendimentos.consulta)) || 0,
			cpf: limparMascara(login.user.cpf) || '',
			ip: login.user.ip || '',
			id_orgao: 390,
			desc_orgao: 'PROCON',
			id_localidade: login.user.idLocal,
			desc_localidade: login.user.nomePosto || '',
		};

		const contabilizacao: ICadastroEventoRequest = {
			id_atendimento: dadosAtendimento?.idAtendimento || '',
			tipo: tipoContabilizacao.tipo || '',
			id_cidadao: dadosAtendimento?.idCidadao || '',
			data: getTimestamp() || 0,
			dados: JSON.stringify(dados) || '',
			canal: {
				id: login.user.idCanal.toString() || '',
				descricao: login.user.descCanal || '',
			},
		};

		dispatch(cadastroEventoRequest(contabilizacao));
	}, [
		handleTipoContabilizacao,
		login,
		dadosAtendimento,
		dispatch,
		consultaAtendimentos,
		resolvido,
	]);

	const handleSubmit = useCallback(
		(values: any) =>
			resolvido ? requestResolvido(values) : requestCancelar(values),
		[resolvido, requestResolvido, requestCancelar],
	);

	const handleCancel = useCallback(() => {
		setShowModal(false);
		ref.current?.resetForm();
	}, [setShowModal]);

	useEffect(() => {
		if (sinalizaResolucaoConsultaAtendimento.status === 200) {
			handleContabilizar();
		}

		if (cancelaConsultaAtendimento.status === 200) {
			handleContabilizar();
		}
	}, [
		cancelaConsultaAtendimento.status,
		handleContabilizar,
		resolvido,
		sinalizaResolucaoConsultaAtendimento.status,
	]);

	useEffect(() => {
		if (
			sinalizaResolucaoConsultaAtendimento.status === 200 ||
			sinalizaResolucaoConsultaAtendimento.status === 201
		) {
			setShowAlert(true);
		}

		if (
			cancelaConsultaAtendimento.status === 200 ||
			cancelaConsultaAtendimento.status === 201
		) {
			setShowAlert(true);
		}

		setShowModal(false);
	}, [
		setShowAlert,
		setShowModal,
		sinalizaResolucaoConsultaAtendimento,
		cancelaConsultaAtendimento,
	]);

	return (
		<>
			<Modal
				visible={visivel}
				onCancel={handleCancel}
				width={800}
				title={
					resolvido ? (
						<TypographyCommon
							text="Consulta Resolvida:"
							type="h5"
							fontWeight="bold"
							color="#2ea44f"
							uppercase
						/>
					) : (
						<TypographyCommon
							text="Cancelar Consulta:"
							type="h5"
							fontWeight="bold"
							color="#cb2431"
							uppercase
						/>
					)
				}
				footer={[]}
			>
				{resolvido ? (
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValuesResolvido}
						validate={values => getValidationsErrors(values, schemaResolvido)}
						onSubmit={handleSubmit}
						innerRef={ref}
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[0, 0]} align="top" justify="start">
									<Col span={24}>
										<TypographyCommon
											text="Justificativa:"
											subtitle=" (Quantidade Mínima 50 Caracteres e Máxima 500)"
											fontWeight="bold"
											uppercase
										/>
									</Col>
									<Col span={24}>
										<Field
											as={TextArea}
											name="justificativa"
											formik={formik}
											maxLength="500"
											error={hasError(formik.errors, 'justificativa')}
										/>
									</Col>
								</Row>
								<br />
								<Row key="footer" gutter={[0, 0]} align="top" justify="center">
									<Col span={5}>
										<Row align="top" justify="center">
											<LightButton
												type="reset"
												buttonColor="default"
												text="Voltar"
												buttonSize="sm"
												buttonWidth={130}
												onClick={handleCancel}
											/>
										</Row>
									</Col>
									<Col span={5}>
										<Row align="top" justify="center">
											<LightButton
												type="submit"
												buttonColor="green"
												text="Resolvido"
												icon="like"
												buttonSize="sm"
												buttonWidth={140}
												disabled={
													sinalizaResolucaoConsultaAtendimento.status === 200 ||
													cancelaConsultaAtendimento.status === 200
												}
											/>
										</Row>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				) : (
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValuesCancelar}
						validate={values => getValidationsErrors(values, schemaCancelar)}
						onSubmit={handleSubmit}
						innerRef={ref}
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[0, 0]} align="top" justify="start">
									<Col span={24}>
										<TypographyCommon
											text="Justificativa:"
											subtitle=" (Quantidade Mínima 50 Caracteres e Máxima 500)"
											fontWeight="bold"
											uppercase
										/>
									</Col>
									<Col span={24}>
										<Field
											as={TextArea}
											name="justificativa"
											formik={formik}
											maxLength="500"
											error={hasError(formik.errors, 'justificativa')}
										/>
									</Col>
								</Row>
								<br />
								<Row key="footer" gutter={[0, 0]} align="top" justify="center">
									<Col span={5}>
										<Row align="top" justify="center">
											<LightButton
												type="reset"
												buttonColor="default"
												text="Voltar"
												buttonSize="sm"
												buttonWidth={130}
												onClick={handleCancel}
											/>
										</Row>
									</Col>
									<Col span={5}>
										<Row align="top" justify="center">
											<LightButton
												type="submit"
												buttonColor="red"
												text="Cancelar Consulta"
												icon="unlike"
												buttonSize="sm"
												buttonWidth={160}
												disabled={
													cancelaConsultaAtendimento.status === 200 ||
													sinalizaResolucaoConsultaAtendimento.status === 200
												}
											/>
										</Row>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				)}
			</Modal>
		</>
	);
};
export default ResolucaoConsultaModal;
