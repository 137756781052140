export enum Types {
	EXCLUIR_USUARIO = '@SGU/EXCLUIR_USUARIO',
	EXCLUIR_USUARIO_SUCCESS = '@SGU/EXCLUIR_USUARIO_SUCCESS',
	EXCLUIR_USUARIO_FAILURE = '@SGU/EXCLUIR_USUARIO_FAILURE',
	EXCLUIR_USUARIO_CLEAR = '@SGU/EXCLUIR_USUARIO_CLEAR',
}

export interface ExcluirUsuario {
	status: number;
	data: null | IExcluirUsuario;
}

export interface IExcluirUsuario {
	status: string;
	statusMensagem: string;
}

export interface ExcluirUsuarioRequest {
	id?: number | string;
}
