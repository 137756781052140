import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { IRequestWithHeader, Types } from './types';

// ACTIONS
import {
	emitirTrocaVoluntariaPlacaSuccess,
	emitirTrocaVoluntariaPlacaFailure,
} from './actions';

function* emitirTrocaVoluntariaPlacaRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestWithHeader } = request;
	const { body, headers } = payload;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/emissao/trocas-voluntarias-placas`,
		body,
		headers,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(emitirTrocaVoluntariaPlacaSuccess(response.data));
	} else if (response.error) {
		yield put(emitirTrocaVoluntariaPlacaFailure());
	}
}

export default all([
	takeLatest(
		Types.EMITIR_TROCA_VOLUNTARIA_PLACA_REQUEST,
		emitirTrocaVoluntariaPlacaRequest,
	),
]);
