import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100%;
	.ant-col {
		margin: 0 3px;
	}
`;

export const Divider = styled.div`
	margin: auto 3px;

	::before {
		content: '-';
	}
`;
