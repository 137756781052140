import { EventoCipPFPJ } from '../combineTypes';

export enum Types {
	CADASTRA_ATENDIMENTO_PJ_REQUEST = '@procon/CADASTRA_ATENDIMENTO_PJ_REQUEST',
	CADASTRA_ATENDIMENTO_PJ_SUCCESS = '@procon/CADASTRA_ATENDIMENTO_PJ_SUCCESS',
	CADASTRA_ATENDIMENTO_PJ_FAILURE = '@procon/CADASTRA_ATENDIMENTO_PJ_FAILURE',
	CADASTRA_ATENDIMENTO_PJ_CLEAR = '@procon/CADASTRA_ATENDIMENTO_PJ_CLEAR',
}

export interface CadastraAtendimentoPj {
	status: null | number;
	data: null | CadastraAtendimentoPjData;
	dadosAtendimento: null | CadastraAtendimentoPjRequest;
}

interface CadastraAtendimentoPjData {
	protocolo: string;
	numeroFa: string;
	atendimentoId: string;
}

export interface Evento {
	tipo: string;
	id_servico: string;
	desc_servico: string;
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string | number;
	ip: string;
	id_orgao: string;
	desc_orgao: string;
	canal?: {
		id: number;
		desc: string;
		estacao: {
			id: number;
			desc: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
	atendente?: {
		nome: string;
		id_atendente: string;
		id_posto: string;
		desc_posto: string;
	};
}

export interface NovoFornecedor {
	tipo?: string | undefined;
	cnpj: string | null;
	cpf: string | null;
	email: string | null;
	razaoSocial: string | null;
	nomeFantasia: string | null;
	website: string | null;
	bairro: string | null;
	cep: string | null;
	complemento: string | null;
	estado: number | null;
	logradouro: string | null;
	municipioId: string | null;
	municipioDescricao: string | null;
	numero: string | null;
	telefone: string | null;
	celular: string | null;
}

export interface CadastraAtendimentoPjRequest {
	cnpjDoConsumidor: string;
	fornecedorId: string;
	consumidorId: string;
	eventoEspecialId: string;
	areaId: string;
	assuntoId: string;
	grupoDeProblemaId: string;
	problemaId: string;
	procureiOFornecedorParaResolverAQuestao: boolean;
	participacaoEmAudiencia: number;
	formaDeAquisicao: number;
	tipoDeContratacao: number;
	dataDaContratacao: string;
	nomeDoProdutoOuServico: string;
	detalhesDoProdutoOuServico: string;
	tipoDoDocumento: number;
	numeroDoDocumento: string;
	dataDaOcorrenciaOuDoServico: string;
	dataDeCancelamentoDesistenciaOuNegativa: string;
	formaDePagamento: number;
	valorDaCompra: number;
	detalhesDaReclamacao: string;
	pedido: number;
	formaDeDevolucao: number;
	anexos: Anexos[];
	nomeDoResponsavelOuProcurador: string;
	cpfDoProcuradorOuResponsavel: string;
	rgDoResponsavelOuProcurador: string;
	compraAgencia: string;
	compraBancoEmissor: string;
	compraBandeiraDoCartao: string;
	compraChavePix: string;
	compraConta: string;
	compraIntermediadoraDePagamento: string;
	compraNumeroDoCartao: string;
	compraTipoDeChavePix: number;
	devolucaoAgencia: string;
	devolucaoBancoEmissorDoCartao: string;
	devolucaoBandeiraDoCartao: string;
	devolucaoChavePix: string;
	devolucaoConta: string;
	devolucaoNomeDoBanco: string;
	devolucaoNumeroDeParcelas: number;
	devolucaoNumeroDoCartao: string;
	devolucaoTipoDeChavePix: number;
	devolucaoValorDaParcela: number;
	novoFornecedor?: NovoFornecedor;
	idDoBalcaoUnico: string;
	descricaoDoPedidoDoConsumidor: string;
	evento?: EventoCipPFPJ;
	dataDaOcorrenciaOuServico?: string;
	descricaoDoPrimeiroContatoComFornecedor?: string;
	// PF
	temProcurador?: boolean;
	isEventoSazonal?: boolean;
	// VALIDAÇÃO
	consumidorNome?: string;
	fornecedorNome?: string;
	tipoConsumidor?: 'PF' | 'PJ' | null;
}

interface Anexos {
	nome: string;
	base64: string;
}
