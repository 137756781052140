export enum Types {
	INCLUIR_EXAME_MEDICO_REQUEST = '@cnh/INCLUIR_EXAME_MEDICO_REQUEST',
	INCLUIR_EXAME_MEDICO_SUCCESS = '@cnh/INCLUIR_EXAME_MEDICO_SUCCESS',
	INCLUIR_EXAME_MEDICO_FAILURE = '@cnh/INCLUIR_EXAME_MEDICO_FAILURE',
	INCLUIR_EXAME_MEDICO_CLEAR = '@cnh/INCLUIR_EXAME_MEDICO_CLEAR',
}

export interface IncluirExameMedico {
	status: number;
	data: IncluirExameMedicoData | null;
}

export interface IncluirExameMedicoData {
	codigoInterno: string;
	message: string;
	timestamp: string;
	status: number;
	error: string;
	path: string;
}

export interface IncluirExameMedicoRequest {
	cpf: string;
	codigoCiretranPostoExameMedico: string;
}
