import { ReducerAction } from 'store/modules/types';
import { ConsultarFuncionalidadesRequest, Types } from './types';

export function consultarFuncionalidadeRequest(
	payload: ConsultarFuncionalidadesRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_FUNCIONALIDADES_REQUEST,
		payload,
	};
}
export function consultarFuncionalidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_FUNCIONALIDADES_SUCCESS,
		payload,
	};
}
export function consultarFuncionalidadeFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_FUNCIONALIDADES_FAILURE,
		payload: null,
	};
}
export function consultarFuncionalidadeClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_FUNCIONALIDADES_CLEAR,
		payload: null,
	};
}
