import React, { useEffect } from 'react';

import { Field, FormikProps } from 'formik';

// COMPONENTS
import Input from 'components/Common/Form/Input/Input';
import { Title } from 'components/Common/Form/Input/styled';
import TooltipInfo from 'components/Common/Tooltips/Info';

// FORM
import { formatacaoCid } from 'utils/stringFunctions';
import { IFormCadastroRg } from '../../../form';

// STYLED
import { Container, Content, Box } from './styled';

interface Props {
	formik: FormikProps<IFormCadastroRg>;
}

const TooltipContent = (
	<>
		<p>
			Classificação Internacional de Doenças e Problemas Relacionados à Saúde.
		</p>
		<p>Deverá ser preenchido de acordo com o laudo apresentado pelo cidadão.</p>
	</>
);

interface Cid {
	cid1: string;
	cid2: string;
	cid3: string;
	cid4: string;
}

const CID: React.FC<Props> = ({ formik }) => {
	const { cid1, cid2, cid3 } = formik.values;
	const { setFieldValue } = formik;

	useEffect(() => {
		if (!cid1) {
			setFieldValue('cid2', '');
			setFieldValue('cid3', '');
			setFieldValue('cid4', '');
		}
	}, [cid1, setFieldValue]);

	useEffect(() => {
		if (!cid2) {
			setFieldValue('cid3', '');
			setFieldValue('cid4', '');
		}
	}, [cid2, setFieldValue]);

	useEffect(() => {
		if (!cid3) {
			setFieldValue('cid4', '');
		}
	}, [cid3, setFieldValue]);

	return (
		<Container>
			<Title size={35} required={false}>
				<label>CID:</label>
			</Title>

			<Content>
				<Box>
					<TooltipInfo title="CID" content={TooltipContent} />
				</Box>
				<Box>
					<p>1.</p>

					<Field
						name="cid1"
						as={Input}
						size={140}
						maxLength="7"
						value={formatacaoCid(formik.values.cid1)}
						error={!!formik.errors.cid1}
					/>
				</Box>

				<Box>
					<p>2.</p>

					<Field
						name="cid2"
						error={!!formik.errors.cid2}
						as={Input}
						size={140}
						maxLength="7"
						value={formatacaoCid(formik.values.cid2)}
						disabled={!formik.values.cid1}
					/>
				</Box>

				<Box>
					<p>3.</p>

					<Field
						name="cid3"
						error={!!formik.errors.cid3}
						as={Input}
						size={140}
						maxLength="7"
						value={formatacaoCid(formik.values.cid3)}
						disabled={!formik.values.cid2}
					/>
				</Box>

				<Box>
					<p>4.</p>

					<Field
						name="cid4"
						error={!!formik.errors.cid4}
						as={Input}
						size={140}
						maxLength="7"
						value={formatacaoCid(formik.values.cid4)}
						disabled={!formik.values.cid3}
					/>
				</Box>
			</Content>
		</Container>
	);
};

export default CID;
