import React, { useCallback } from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

// REDUX
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import FormBox from 'components/Common/Form/FormBox';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask/index';
import Select from 'components/Common/Form/Select';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import BuscarEndereco, {
	IBuscarEnderecoResultado,
} from 'components/Common/SearchButtons/Endereco';
import Municipio from 'components/Common/Form/Fields/Municipio';

// UTILS
import { patternFormat, completaZeroEsquerda } from 'utils/genericFunctions';

// FORM
import { IFormCadastroRg } from '../../../form';

interface Props {
	formik: FormikProps<IFormCadastroRg>;
	bloqueioPortaria606: boolean;
}

const DadosAdicionaisCidadao: React.FC<Props> = ({
	formik,
	bloqueioPortaria606,
}) => {
	const { setFieldValue } = formik;

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado) => {
			setFieldValue('cepResidencia', res.cep);
			setFieldValue('enderecoResidencia', res.endereco.substring(0, 22));
			setFieldValue('bairroResidencia', res.bairro.substring(0, 14));
			setFieldValue(
				'codigoMunicipioResidencia',
				res.codigoNaturalidade
					? patternFormat(
							completaZeroEsquerda(res.codigoNaturalidade, 6),
							'#####-#',
					  )
					: '',
			);
			setFieldValue('descMunicipioResidencia', res.municipio);
			setFieldValue('ufResidencia', res.uf);
		},
		[setFieldValue],
	);

	const handleEnderecoSelecionado = useCallback(
		(res: IBuscarEnderecoResultado) => {
			if (res.cep) setFieldValue('cepResidencia', res.cep);
			if (res.endereco || res.tipoLogradouro)
				setFieldValue(
					'enderecoResidencia',
					`${res.tipoLogradouro ? res.tipoLogradouro : ''} ${
						res.endereco ? res.endereco.substring(0, 22) : ''
					}`,
				);
			setFieldValue(
				'bairroResidencia',
				res.bairro ? res.bairro.substring(0, 14) : '',
			);
			if (res.codigoMunicipio)
				setFieldValue(
					'codigoMunicipioResidencia',
					res.codigoMunicipio
						? patternFormat(
								completaZeroEsquerda(res.codigoMunicipio, 6),
								'#####-#',
						  )
						: '',
				);
			setFieldValue(
				'descMunicipioResidencia',
				res.municipio ? res.municipio : '',
			);
			setFieldValue('ufResidencia', res.uf ? res.uf : '');

			// APAGAR NÚMERO E COMPLEMENTO APÓS PREENCHER COM NOVO ENDEREÇO
			setFieldValue('numeroResidencia', '');
			setFieldValue('complementoResidencia', '');
		},
		[setFieldValue],
	);

	return (
		<>
			<FormBox title="Dados adicionais do cidadão">
				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={Select}
							titleSize="sm"
							title="Estado Cívil"
							name="estadoCivil"
							options={data.estadoCivil}
							onChange={(v: string) => setFieldValue('estadoCivil', v)}
							required
							disabled={bloqueioPortaria606 === true}
							error={!!formik.errors.estadoCivil}
						/>
					</Col>

					<Col span={12}>
						<Field
							as={Select}
							title="Cutis"
							name="cutis"
							options={data.cutis}
							onChange={(v: string) => setFieldValue('cutis', v)}
							required
							error={!!formik.errors.cutis}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={Select}
							titleSize="sm"
							title="Cabelos"
							name="cabelos"
							options={data.corCabelo}
							onChange={(v: string) => setFieldValue('cabelos', v)}
							required
							error={!!formik.errors.cabelos}
						/>
					</Col>

					<Col span={12}>
						<Field
							as={Select}
							title="Olhos"
							name="olhos"
							options={data.corOlhos}
							onChange={(v: string) => setFieldValue('olhos', v)}
							required
							error={!!formik.errors.olhos}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={InputMask}
							titleSize="sm"
							title="Altura"
							subtitle="(Apenas números)"
							name="altura"
							mask="9,99"
							size={26}
							required
							error={!!formik.errors.altura}
						/>
					</Col>

					<Col span={12}>
						<Field
							as={Select}
							title="Grau de instrução"
							name="grauInstrucao"
							options={data.grauEscolaridade}
							onChange={(v: string) => {
								if (v && v !== 'N') {
									setFieldValue('identificadoAssina', true);
								}
								setFieldValue('grauInstrucao', v);
							}}
							required
							error={!!formik.errors.grauInstrucao}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={Input}
							titleSize="sm"
							title="Profissão"
							name="profissao"
							maxLength={12}
							error={!!formik.errors.profissao}
						/>
					</Col>

					<Col span={11}>
						<CEP
							name="cepResidencia"
							defaultValue={formik.values.cepResidencia}
							required
							formik={formik}
							result={handleCepSelecionado}
							instanceRequest="iirgd-dados-adicionais"
						/>
					</Col>

					<Col span={1}>
						<BuscarEndereco result={handleEnderecoSelecionado} />
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={Input}
							titleSize="sm"
							title="Logradouro"
							name="enderecoResidencia"
							maxLength={22}
							required
							error={!!formik.errors.enderecoResidencia}
						/>
					</Col>

					<Col span={8}>
						<Field
							as={InputMask}
							title="Número"
							name="numeroResidencia"
							mask="99999"
							maskChar=""
							required
							error={!!formik.errors.numeroResidencia}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={Input}
							title="Bairro"
							titleSize="sm"
							name="bairroResidencia"
							maxLength={14}
							error={!!formik.errors.bairroResidencia}
						/>
					</Col>

					<Col span={12}>
						<Municipio
							codigoMunicipio="codigoMunicipioResidencia"
							municipio="descMunicipioResidencia"
							sizeInputMunicipio={160}
							sizeInputCodigoMunicipio={100}
							codigoValue={formik.values.codigoMunicipioResidencia}
							required
							formik={formik}
							disabled
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Input}
							titleSize="sm"
							title="Complemento"
							name="complementoResidencia"
							maxLength={8}
							error={!!formik.errors.complementoResidencia}
						/>
					</Col>
				</Row>
			</FormBox>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default DadosAdicionaisCidadao;
