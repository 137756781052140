import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarTaxaServicoRequest } from './types';

export function consultarTaxaServicoRequest(
	data: ConsultarTaxaServicoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_TAXA_SERVICO,
		payload: data,
	};
}

export function consultarTaxaServicoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_TAXA_SERVICO_SUCCESS,
		payload,
	};
}

export function consultarTaxaServicoFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_TAXA_SERVICO_FAILURE,
		payload,
	};
}

export function consultarTaxaServicoClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_TAXA_SERVICO_CLEAR,
		payload: null,
	};
}
