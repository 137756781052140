import { ReducerAction } from 'store/modules/types';
import { Types, IRequestEmitirBaixaVeiculo } from './types';

export function emitirBaixaVeiculoRequest(
	payload: IRequestEmitirBaixaVeiculo,
): ReducerAction {
	return {
		type: Types.EMITIR_BAIXA_VEICULO_REQUEST,
		payload,
	};
}

export function emitirBaixaVeiculoSuccess(payload: object): ReducerAction {
	return {
		type: Types.EMITIR_BAIXA_VEICULO_SUCCESS,
		payload,
	};
}

export function emitirBaixaVeiculoFailure(): ReducerAction {
	return {
		type: Types.EMITIR_BAIXA_VEICULO_FAILURE,
		payload: null,
	};
}

export function emitirBaixaVeiculoClear(): ReducerAction {
	return {
		type: Types.EMITIR_BAIXA_VEICULO_CLEAR,
		payload: null,
	};
}
