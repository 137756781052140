import React from 'react';

import { Row, Col } from 'antd';
import TypographyCommon from 'components/Common/Typography';

const AcordoFooter: React.FC = () => {
	return (
		<>
			<Row gutter={[0, 10]} style={{ marginTop: '80px' }}>
				<Col span={11} style={{ alignSelf: 'center' }}>
					<Row justify="center">
						<p>
							<span style={{ marginLeft: '8px', marginRight: '4px' }}>
								_____________________________________________________
							</span>
						</p>
					</Row>
					<Row justify="center">
						<TypographyCommon
							text="CDHU"
							type="label"
							fontWeight="normal"
							labelSize={12}
							textCenter
							uppercase
						/>
					</Row>
				</Col>
				<Col span={1} />
				<Col span={11} style={{ alignSelf: 'center' }}>
					<Row justify="center">
						<p>
							<span style={{ marginLeft: '4px', marginRight: '8px' }}>
								_____________________________________________________
							</span>
						</p>
					</Row>
					<Row justify="center">
						<TypographyCommon
							text="Assinatura"
							type="label"
							fontWeight="normal"
							labelSize={12}
							textCenter
						/>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default AcordoFooter;
