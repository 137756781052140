import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types } from './types';
import {
	consultarVeiculoBaseEstadualSuccess,
	consultarVeiculoBaseEstadualFailure,
} from './actions';

function* consultarVeiculoBaseEstadualRequest({ payload }: any) {
	const body = {
		chassi: payload.chassi,
		placa: payload.placa,
		renavam: payload.renavam,
		municipio: {
			codigo: payload.municipio.codigo,
			nome: payload.municipio.nome,
		},
	};
	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consulta-veiculo-base-estadual`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarVeiculoBaseEstadualSuccess(response));
	} else if (response.error) {
		yield put(consultarVeiculoBaseEstadualFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_VEICULO_BASE_ESTADUAL_REQUEST,
		consultarVeiculoBaseEstadualRequest,
	),
]);
