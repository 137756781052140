// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	ConsultarDigitaisState,
	ConsultarDigitaisRequest,
	Types,
} from './types';

export function consultarDigitaisRequest(
	payload: ConsultarDigitaisRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_DIGITAIS_REQUEST,
		payload,
	};
}
export function consultarDigitaisSuccess(
	payload: ConsultarDigitaisState,
): ReducerAction {
	return {
		type: Types.CONSULTAR_DIGITAIS_SUCCESS,
		payload,
	};
}
export function consultarDigitaisFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_DIGITAIS_FAILURE,
		payload,
	};
}
export function consultarDigitaisClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_DIGITAIS_CLEAR,
		payload: null,
	};
}
