// import { IRequestCadastraExameMedico } from 'store/modules/detranCnh/divisaoEquitativa/types';
import { DIVISAO_EQUITATIVA_PARAMS } from 'pages/DetranCnh/utils/constants/services';
import * as Yup from 'yup';

export interface ICadastraExamePsicologico {
	cpf_cidadao: number;
	tipo_processo: string;
	cep_cidadao: number;
	codigo_ciretran_exame_medico: number | string;
	categoria_pretendida: string;
	id_medico: number;
	codigo_ciretran_exame_psicologo: number | string;
	id_psicologo: number;
	data_exame_psicologico: string;
	horario_exame_psicologico: string;
	renach: string;
	id_micro_regiao_psicologo: number | string;
	usuario: string;
	regiao?: string;
	data_exame_medico?: string;
	horario_exame_medico?: string;
}

export const dadosAgendamento: ICadastraExamePsicologico = {
	cpf_cidadao: 0,
	tipo_processo: '',
	cep_cidadao: 0,
	codigo_ciretran_exame_medico: '',
	categoria_pretendida: '',
	id_medico: 0,
	codigo_ciretran_exame_psicologo: '',
	id_psicologo: 0,
	data_exame_psicologico: '',
	horario_exame_psicologico: '',
	renach: '',
	id_micro_regiao_psicologo: '',
	usuario: DIVISAO_EQUITATIVA_PARAMS.USUARIO_AUTENTICACAO,
};

export const schema = Yup.object<ICadastraExamePsicologico>().shape({
	regiao: Yup.string().when('codigo_ciretran', {
		is: codigo_ciretran => codigo_ciretran === '18',
		then: Yup.string().required('Campo Obrigatório: Região.'),
	}),
	id_micro_regiao_psicologo: Yup.string().when('codigo_ciretran', {
		is: codigo_ciretran => codigo_ciretran === '18',
		then: Yup.string().required('Campo Obrigatório: Micro Região.'),
	}),
	data_exame_psicologico: Yup.string().required('Campo Obrigatório: Data.'),
	horario_exame_psicologico: Yup.string().required('Campo Obrigatório: Hora.'),
});
