import React, {
	// ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Field, Form, Formik } from 'formik';
import { Col, Row, Modal } from 'antd';

// import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
// import Checkbox from 'components/Common/Form/Checkbox';
import ValidDataInput from 'components/Common/Form/Input/ValidData';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from 'store';

import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import {
	desbloqueiaCartaoRequest,
	limpardesbloqueiaCartao,
} from 'store/modules/api/bolsaPovo/desbloqueioCartao/action';
import { clearNotifications } from 'store/modules/app/notifications/actions';

import { IRequestValidacaoCidadao } from 'store/modules/api/bolsaPovo/desbloqueioCartao/types';
import InputMask from 'components/Common/Form/Input/InputMask';
// import { ROUTE_MENU_ORGAOS } from 'routes/paths';
import { limparAlteraEndereco } from 'store/modules/api/bolsaPovo/alteraEndereco/action';
import { limparAtualizarContato } from 'store/modules/api/bolsaPovo/atualizarContato/action';
import { limparCadastrarContato } from 'store/modules/api/bolsaPovo/cadastrarContato/action';
import { limparConsultarContato } from 'store/modules/api/bolsaPovo/consultarContato/action';
import { onlyNumericalString } from 'utils/genericFunctions';
import { ContainerBolsaPovo, ContainerButtons } from './styled';
import { initialValues, schema, treatValues } from './form';
import { ROUTE_BOLSA_POVO_ALTERA_ENDERECO } from '../routes/paths';

const DesbloqueioCartao: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [disableModal, setDisableModal] = useState(false);
	const [cpfCidadao, setCpfCidadao] = useState('');

	const { desbloqueiaCartao } = useSelector(
		(state: ApplicationState) => state.api.bolsaPovo,
	);

	const { salvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento,
	);

	const { nome } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.user,
	);

	const { cpf, ip, nomePosto, posto } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.user,
	);

	const { data: dataRecuperarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.recuperarAtendimento,
	);

	useEffect(() => {
		dispatch(limparCadastrarContato());
		dispatch(limparConsultarContato());
		dispatch(limparAlteraEndereco());
		dispatch(limparAtualizarContato());
	}, [dispatch]);

	useEffect(() => {
		if (desbloqueiaCartao.status === 201 || desbloqueiaCartao.status === 200) {
			history.push({
				pathname: ROUTE_BOLSA_POVO_ALTERA_ENDERECO,
				state: cpfCidadao,
			});
		} else if (desbloqueiaCartao.status === 400) {
			setDisableModal(true);
		}
	}, [cpfCidadao, desbloqueiaCartao, history]);

	const handleSubmit = useCallback(
		(values: IRequestValidacaoCidadao) => {
			Modal.confirm({
				visible: disableModal,
				okText: 'Sim',
				cancelText: 'Não',
				title: 'Confirma o desbloqueio do cartão ?',
				content:
					'Ao confirmar será redirecionado para a página de atualização de endereço',
				onOk() {
					// dispatch(limpardesbloqueiaCartao());
					const evento = {
						id_atendimento: salvarAtendimento.data?.idAtendimento || '',
						id_cidadao:
							dataRecuperarAtendimento?.idCidadao ||
							'00000000-0000-0000-0000-000000000000',
						cpf,
						identificacao: values.idCpf
							? onlyNumericalString(values.idCpf)
							: '0',
						ip,
						canal: {
							id: 1,
							desc: 'Presencial',
							localidade: {
								id: parseInt(posto, 10),
								desc: nomePosto,
							},
						},
					};

					const dadosAtendente = {
						nome,
						cpf,
					};

					const valuesFormated = treatValues(evento, values, dadosAtendente);
					setCpfCidadao(valuesFormated.idCpf);
					dispatch(desbloqueiaCartaoRequest(valuesFormated));
				},
				onCancel() {},
			});
		},
		[
			disableModal,
			salvarAtendimento,
			dataRecuperarAtendimento,
			cpf,
			ip,
			posto,
			nomePosto,
			nome,
			dispatch,
		],
	);

	return (
		<Section title="Validação dos dados para desbloqueio">
			<ContainerBolsaPovo>
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => handleSubmit(values)}
				>
					{formik => (
						<Form autoComplete="off">
							<Row gutter={[0, 10]}>
								<Col span={3} />
								<Col span={5}>
									<Field
										as={InputMask}
										mask="999.999.999-99"
										type="text"
										title="CPF do beneficiário"
										name="idCpf"
										error={hasError(formik.errors, 'idCpf')}
										required
									/>
								</Col>
								{/* <Col span={5}>
									<Field
										as={Input}
										type="text"
										title="Nome do beneficiário"
										name="dsNome"
										error={hasError(formik.errors, 'dsNome')}
										required
									/>
								</Col> */}
								{/* <Col span={7}>
									<Field
										as={Input}
										type="text"
										title="Primeiro nome da mãe"
										name="dsPrimeiroNomeMae"
										error={hasError(formik.errors, 'dsPrimeiroNomeMae')}
										required={!formik.values.dsNaoPossuiNomeMae}
									/>
									<Field
										subtitle="Não possui nome da mãe"
										name="dsNaoPossuiNomeMae"
										as={Checkbox}
										checked={formik.values.dsNaoPossuiNomeMae}
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											const { checked } = e.target;

											formik.setFieldValue('dsNaoPossuiNomeMae', !!checked);
										}}
									/>
								</Col> */}
								<Col span={4}>
									<Field
										title="Nascimento"
										name="dtNascimento"
										type="text"
										mask="99/99/9999"
										as={ValidDataInput}
										// defaultValue={initialValues.dtNascimento || ''}
										formik={formik}
										// onChange={(v: string) =>
										// 	formik.setFieldValue('dtNascimento', v)
										// }
										required
										error={hasError(formik.errors, 'dtNascimento')}
									/>
								</Col>
								<Col span={8}>
									<Field
										as={InputMask}
										type="text"
										title="Número cartão - Quatro últimos digitos"
										name="DsNumeroCartaoQuatroUltimosDigitos"
										mask="9999"
										error={hasError(
											formik.errors,
											'DsNumeroCartaoQuatroUltimosDigitos',
										)}
										required
									/>
								</Col>
								<Col span={3} />
							</Row>
							<ContainerButtons>
								<Row gutter={[0, 10]}>
									<Col style={{ padding: '5px 10px' }}>
										<Button
											type="button"
											onClick={() => {
												dispatch(limpardesbloqueiaCartao());
												formik.handleReset();
												dispatch(clearNotifications());
											}}
										>
											Limpar
										</Button>
									</Col>
									<Col style={{ padding: '5px 10px' }}>
										<Button type="submit">Validar</Button>
									</Col>
									{/* <Col style={{ padding: '5px 10px' }}>
										<Button
											type="button"
											onClick={() => {
												history.push(ROUTE_MENU_ORGAOS);
											}}
										>
											Finalizar atendimento
										</Button>
									</Col> */}
									{/* <Col style={{ padding: '5px 10px' }}>
										<Button
											type="button"
											onClick={() => {
												history.push({
													pathname: ROUTE_BOLSA_POVO_ALTERACAO_ENDERECO,
													state: cpfCidadao,
												});
											}}
										>
											Atualizar Endereço de Correspondência
										</Button>
									</Col> */}
								</Row>
							</ContainerButtons>
						</Form>
					)}
				</Formik>
			</ContainerBolsaPovo>
		</Section>
	);
};

export default DesbloqueioCartao;
