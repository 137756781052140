export enum Types {
	CONSULTA_TRANSFERENCIA_ENDERECO = '@cnh/CONSULTA_TRANSFERENCIA_ENDERECO',
	CONSULTA_TRANSFERENCIA_ENDERECO_SUCCESS = '@cnh/CONSULTA_TRANSFERENCIA_ENDERECO_SUCCESS',
	CONSULTA_TRANSFERENCIA_ENDERECO_FAILURE = '@cnh/CONSULTA_TRANSFERENCIA_ENDERECO_FAILURE',
	CONSULTA_TRANSFERENCIA_ENDERECO_CLEAR = '@cnh/CONSULTA_TRANSFERENCIA_ENDERECO_CLEAR',
}

export interface ConsultaTransferenciaEndereco {
	status: number;
	data: TransferenciaEndereco | null;
	requestData: ConsultaTransferenciaEnderecoRequest | null;
}

export interface TransferenciaEndereco {
	cpf: string;
	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	codigoMunicipioIBGE: string;
	ciretran: string;
	taxaPaga: string;
	dataInicioBloqueio: string;
	dataInicioCassacao: string;
	dataInicioCrimeTransito: string;
	dataInicioSuspensao: string;
	dataTerminoCassacao: string;
	dataTerminoCrimeTransito: string;
	dataTerminoSuspensao: string;
	nomeMunicipio: string;
}

export interface ConsultaTransferenciaEnderecoRequest {
	cpf: string;
	numRegistro: string | number;
	numPgu: string | number;
}
