import styled from 'styled-components';

// IMAGES
import light from 'assets/images/contrast/light.gif';
import dark from 'assets/images/contrast/dark.gif';

export const Container = styled.div`
	align-items: center;
	display: flex;
	height: 16px;
`;

export const Light = styled.button`
	background-image: url(${light});
	border: unset;
	margin-right: 4px;
	height: 16px;
	width: 16px;
`;

export const Dark = styled.button`
	background-image: url(${dark});
	border: unset;
	margin-right: 4px;
	height: 16px;
	width: 16px;
`;

export const Text = styled.div`
	font-size: 0.75rem;
	margin: auto 0;
`;
