import { Types } from './types';

export function ensinoRequest() {
	return {
		type: Types.ENSINO_REQUEST,
	};
}

export function ensinoSuccess(payload: object) {
	return {
		type: Types.ENSINO_SUCCESS,
		payload,
	};
}

export function ensinoFailure(payload: boolean) {
	return {
		type: Types.ENSINO_FAILURE,
		payload,
	};
}
