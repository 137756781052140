import { ReducerAction, ReducerActionRotaSP } from 'store/modules/types';

import { Types, IRequestConsultaFichaAluno } from './types';

export function consultaFichaAlunoRequest(
	token: string,
	data: IRequestConsultaFichaAluno,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_FICHA_ALUNO_REQUEST,
		token,
		payload: data,
	};
}

export function consultaFichaAlunoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_FICHA_ALUNO_SUCCESS,
		payload,
	};
}

export function consultaFichaAlunoFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_FICHA_ALUNO_FAILURE,
		payload: null,
	};
}

export function consultaFichaAlunoClean() {
	return {
		type: Types.CONSULTA_FICHA_ALUNO_CLEAN,
		payload: null,
	};
}
