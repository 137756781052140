import produce from 'immer';

// TYPES
import { Types, BreadcrumbState } from './types';
import { ReducerAction } from '../../types';

export const INITIAL_STATE: BreadcrumbState = {
	data: null,
};

export default function app(
	state = INITIAL_STATE,
	action: ReducerAction,
): BreadcrumbState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.SET_BREADCRUMB: {
				if (action.payload.data) {
					draft.data = Object.assign([], action.payload.data);

					if (draft.data !== null && action.payload.exibirPaginaInicial) {
						const routePaginaInicial = {
							name: 'Página Inicial',
							route: '/menu-orgaos',
						};

						if (draft.data[0] !== routePaginaInicial) {
							draft.data.unshift({
								name: 'Página Inicial',
								route: '/menu-orgaos',
							});
						}
					}
				} else {
					draft.data = null;
				}

				break;
			}

			default:
		}
	});
}
