// TYPES
import { ReducerAction } from 'store/modules/types';
import { CadastroConsultaRequest, Types } from './types';

export function cadastroConsultaRequest(
	payload: CadastroConsultaRequest,
): ReducerAction {
	return {
		type: Types.CADASTRO_CONSULTA_REQUEST,
		payload,
	};
}
export function cadastroConsultaSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRO_CONSULTA_SUCCESS,
		payload,
	};
}
export function cadastroConsultaFailure(payload: object): ReducerAction {
	return {
		type: Types.CADASTRO_CONSULTA_FAILURE,
		payload,
	};
}
export function cadastroConsultaClear(): ReducerAction {
	return {
		type: Types.CADASTRO_CONSULTA_CLEAR,
		payload: null,
	};
}
