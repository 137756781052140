export enum Types {
	PESQUISA_ENDERECO_RECEBIMENTO_REQUEST = '@cnh/PESQUISA_ENDERECO_RECEBIMENTO_REQUEST',
	PESQUISA_ENDERECO_RECEBIMENTO_SUCCESS = '@cnh/PESQUISA_ENDERECO_RECEBIMENTO_SUCCESS',
	PESQUISA_ENDERECO_RECEBIMENTO_FAILURE = '@cnh/PESQUISA_ENDERECO_RECEBIMENTO_FAILURE',
	PESQUISA_ENDERECO_RECEBIMENTO_CLEAR = '@cnh/PESQUISA_ENDERECO_RECEBIMENTO_CLEAR',
}

export interface pesquisaEnderecosRecebimento {
	status: number;
	data: null | PesquisaEnderecoRecebimentoData;
}

export interface PesquisaEnderecoRecebimentoData {
	cpf: string;
	codigo: string;
	mensagem: string;
	enderecoCadastro: {
		logradouro: string;
		numero: string;
		complemento: string;
		bairro: string;
		cep: string;
		codigoMunicipio: string;
		nomeMunicipio: string;
	};
	enderecosRecebimento: EnderecosRecebimento[];
}

export interface EnderecosRecebimento {
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cep: string;
	codigoMunicipio: string;
	nomeMunicipio: string;
}

export interface RequestPesquisaEnderecoRecebimento {
	cpf: string;
}
