import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { ConsultarUnidadesTransitoRequest, Types } from './types';
import {
	consultarUnidadeTransitoSuccess,
	consultarUnidadeTransitoFailure,
} from './actions';

function* consultarUnidadeTransitoRequest(request: {
	type: string;
	payload: ConsultarUnidadesTransitoRequest;
}) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consulta-unidade-transito`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarUnidadeTransitoSuccess(response));
	} else if (response.error) {
		yield put(consultarUnidadeTransitoFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_UNIDADE_TRANSITO_REQUEST,
		consultarUnidadeTransitoRequest,
	),
]);
