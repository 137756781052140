export enum Types {
	CONSULTAR_SIGLAS_REQUEST = '@SGU/CONSULTAR_SIGLAS_REQUEST',
	CONSULTAR_SIGLAS_SUCCESS = '@SGU/CONSULTAR_SIGLAS_SUCCESS',
	CONSULTAR_SIGLAS_FAILURE = '@SGU/CONSULTAR_SIGLAS_FAILURE',
	CONSULTAR_SIGLAS_CLEAR = '@SGU/CONSULTAR_SIGLAS_CLEAR',
}

export interface ConsultarSiglas {
	status: number;
	data: Sigla[];
	totalRegistrosConsulta: number;
}

export interface Sigla {
	id: string | number;
	sigla: string;
	idOrgao: string | number;
	descricao: string | number;
}

export interface ConsultarSiglasRequest {
	registro_inicial?: number;
	limite?: number;
	current?: number;
	id?: string | number;
	sigla?: string;
	idOrgao?: string | number;
}
