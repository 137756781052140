import React from 'react';

import { Row, Col, Divider } from 'antd';

// REDUX
import EnumCNH from 'store/modules/api/detranCnh/enum';

// COMPONENTS
import Section from 'components/Common/Section';

// FORM
import { formatDate } from 'utils/genericFunctions';
import { IFormStatusCondutor } from '../../StatusCondutor/form';

interface IProps {
	consultaCnhEstrangeiro: IFormStatusCondutor;
}

const DadosHabilitacao: React.FC<IProps> = ({ consultaCnhEstrangeiro }) => {
	return (
		<>
			<Section bgColor="cinza-claro">
				<Row>
					<Col span={24}>
						<h1>Dados da Habilitação</h1>
					</Col>
				</Row>
				<Divider style={{ marginTop: 0, backgroundColor: '#000' }} />
				<Row>
					<Col span={24}>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<strong style={{ opacity: 0.8 }}>
											Informações Pessoais
										</strong>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Row>
											<Col span={24}>
												<p>
													<strong>CPF: </strong>
													<span>{consultaCnhEstrangeiro.cpf}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>RG: </strong>
													<span>{consultaCnhEstrangeiro.identidade}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>Orgão Emissor: </strong>
													<span>{consultaCnhEstrangeiro.orgaoEmissor}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>UF: </strong>
													<span>{consultaCnhEstrangeiro.identidadeUf}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>RNE: </strong>
													<span>{consultaCnhEstrangeiro.rne}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>Orgão Emissor: </strong>
													<span>{consultaCnhEstrangeiro.rneOrgaoEmissor}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>UF: </strong>
													<span>EX</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Nome do pai: </strong>
													<span>{consultaCnhEstrangeiro.nomePai}</span>
												</p>
											</Col>
											<Col span={8} offset={8}>
												<p>
													<strong>Data de nasc: </strong>
													<span>
														{formatDate(consultaCnhEstrangeiro.dataNascimento)}
													</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Nome da mãe: </strong>
													<span>{consultaCnhEstrangeiro.nomeMae}</span>
												</p>
											</Col>
											<Col span={8} offset={8}>
												<p>
													<strong>Sexo: </strong>
													<span>{consultaCnhEstrangeiro.sexo}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Naturalidade: </strong>
													<span>{consultaCnhEstrangeiro.naturalidade}</span>
												</p>
											</Col>
											<Col offset={8} span={8}>
												<p>
													<strong>Nacionalidade: </strong>
													<span>
														{
															EnumCNH.nacionalidadeEnum.filter(
																nacionalidade =>
																	nacionalidade.value ===
																	consultaCnhEstrangeiro.nacionalidade,
															)[0]?.label
														}
													</span>
												</p>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row gutter={[0, 20]}>
							<Col span={24} />
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<strong style={{ opacity: 0.8 }}>
											Informações Habilitação
										</strong>
									</Col>
								</Row>
								<Row gutter={[0, 0]}>
									<Col span={24}>
										<Row>
											<Col span={8}>
												<p>
													<strong>Nº CNH Estrangeira: </strong>
													<span>{consultaCnhEstrangeiro.identificacao}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>País da CNH Estrangeira: </strong>
													<span>{consultaCnhEstrangeiro.pais2}</span>
												</p>
											</Col>
										</Row>
										<Row>
											<Col span={8}>
												<p>
													<strong>Validade da CNH Estrangeira: </strong>
													<span>
														{formatDate(consultaCnhEstrangeiro.dataValidade)}
													</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>Categoria Atual: </strong>
													<span>{consultaCnhEstrangeiro.categoria}</span>
												</p>
											</Col>
											<Col span={8}>
												<p>
													<strong>1º habilitação: </strong>
													<span>
														{formatDate(
															consultaCnhEstrangeiro.dataPrimeiraHabilitacao,
														)}
													</span>
												</p>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24} />
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<strong style={{ opacity: 0.8 }}>Endereço Cadastrado</strong>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Row>
									<Col span={8}>
										<p>
											<strong>CEP: </strong>
											<span>{consultaCnhEstrangeiro.endereco.cep}</span>
										</p>
									</Col>
									<Col span={8}>
										<p>
											<strong>Logradouro: </strong>
											<span>{consultaCnhEstrangeiro.endereco.logradouro}</span>
										</p>
									</Col>
								</Row>
								<Row>
									<Col span={8}>
										<p>
											<strong>Número: </strong>
											<span>{consultaCnhEstrangeiro.endereco.numero}</span>
										</p>
									</Col>
									<Col span={8}>
										<p>
											<strong>Complemento: </strong>
											<span>{consultaCnhEstrangeiro.endereco.complemento}</span>
										</p>
									</Col>
								</Row>
								<Row>
									<Col span={8}>
										<p>
											<strong>Bairro: </strong>
											<span>{consultaCnhEstrangeiro.endereco.bairro}</span>
										</p>
									</Col>
									<Col span={8}>
										<p>
											<strong>Município: </strong>
											<span>
												{consultaCnhEstrangeiro.endereco.descricaoMunicipio}
											</span>
										</p>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Section>
		</>
	);
};

export default DadosHabilitacao;
