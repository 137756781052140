import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_sgu';

// TYPES
import { CHANNEL } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaUsuariosMainframeRequest } from './types';

// ACTIONS
import {
	consultaUsuariosMainframeSuccess,
	consultaUsuariosMainframeFailure,
} from './actions';

function* consultaUsuariosMainframe(request: ReducerAction) {
	const { payload }: { payload: ConsultaUsuariosMainframeRequest } = request;
	const { cpf, codigoLocalidade } = payload;

	const response: ApiResponse = yield call(
		postApi,
		`usuario/${CHANNEL}/busca/usuario-mainframe`,
		{
			cpf,
			idLocalidade: codigoLocalidade,
		},
	);

	if (response.status === 200) {
		yield put(consultaUsuariosMainframeSuccess(response));
	} else if (response.error) {
		yield put(consultaUsuariosMainframeFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_USUARIOS_MAINFRAME_REQUEST,
		consultaUsuariosMainframe,
	),
]);
