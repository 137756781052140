// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IProtocoloFicDigital } from './types';

export interface IRequestFicDigital {
	numRg: string;
	digRg: string;
	codigoPosto: string;
	dataRetiradaDoc?: string;
}

export function protocoloFicDigitalRequest(
	payload: IRequestFicDigital,
): ReducerAction {
	return {
		type: Types.PROTOCOLO_FIC_DIGITAL_REQUEST,
		payload,
	};
}
export function protocoloFicDigitalSuccess(
	payload: IProtocoloFicDigital,
): ReducerAction {
	return {
		type: Types.PROTOCOLO_FIC_DIGITAL_SUCCESS,
		payload,
	};
}
export function protocoloFicDigitalFailure(): ReducerAction {
	return {
		type: Types.PROTOCOLO_FIC_DIGITAL_FAILURE,
		payload: null,
	};
}
export function protocoloFicDigitalClear(): ReducerAction {
	return {
		type: Types.PROTOCOLO_FIC_DIGITAL_CLEAR,
		payload: null,
	};
}
