import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { CancelarAutorizacaoPrevia } from 'pages/DetranCrv/SolicitarDocumentos/CancelarAutorizacaoPrevia/form';
import { Types } from './types';
import {
	consultarAutorizacaoPreviaSuccess,
	consultarAutorizacaoPreviaFailure,
} from './actions';

function* consultarAutorizacaoPreviaRequest(request: {
	type: string;
	payload: CancelarAutorizacaoPrevia;
}) {
	const { payload } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/autorizacoes-previas`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(consultarAutorizacaoPreviaSuccess(response));
	} else if (response.error) {
		yield put(consultarAutorizacaoPreviaFailure(response.message));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_AUTORIZACAO_PREVIA_REQUEST,
		consultarAutorizacaoPreviaRequest,
	),
]);
