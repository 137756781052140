/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';

// ROUTES
import { useHistory } from 'react-router-dom';

// ANTD
import { Row, Col } from 'antd';

// COMPONENTS COMMON
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import Alert from 'components/Common/Notifications/Alert';

// COMPONENTS
import DadosContato from 'pages/DetranCnh/components/pages/DadosContato';
import DadosCondutor from 'pages/DetranCnh/components/pages/DadosCondutor';
import EnderecoRecebimento from 'pages/DetranCnh/components/pages/EnderecoRecebimento';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { cadastroEnderecosRequest } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/actions';
import { gerarRenachRequest } from 'store/modules/api/detranCnh/permissaoInternacionalAutomatizado/emissaoPid/actions';
import { RequestEmissaoPid } from 'store/modules/api/detranCnh/permissaoInternacionalAutomatizado/emissaoPid/types';
import {
	incluirExameMedicoClear,
	incluirExameMedicoRequest,
} from 'store/modules/api/detranCnh/condutor/incluirExameMedicoDentroPosto/actions';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { Form, Formik } from 'formik';
import RenachGerado from '../RenachGerado';
import {
	initialValuesDadosHabilitacao,
	treatValues,
	treatValuesEmissaoPid,
	schema,
	initialValuesEndereco,
} from './form';

const StatusCondutorPermissaoInternacionalAutomatizado: React.FC = () => {
	const history = useHistory();

	const dispatch = useDispatch();

	const [confirmacaoEndereco, setConfirmacaoEndereco] = useState(false);

	const [isLoading, setIsLoading] = useState(true);

	const [formValues, setFormValues] = useState(initialValuesDadosHabilitacao);

	const [mensagemAlert, setMensagemAlert] = useState('');

	const [dataEmissaoPid, setDataEmissaoPid] =
		useState<null | RequestEmissaoPid>(null);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { incluirExameMedico } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { consultaEPortalAutomatizado, emissaoPid } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.permissaoInternacionalAutomatizado,
	);

	const { cadastroEnderecos, pesquisaEnderecosRecebimento } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const userLogin = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.user,
	);

	const { gerarRenach } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const handleSubmit = useCallback(
		(values: any) => {
			const requestCadastroEndereco = treatValues(values);

			const requestEmissaoPid = treatValuesEmissaoPid(
				atendimento,
				loginUnico,
				values,
			);

			setDataEmissaoPid(requestEmissaoPid);

			dispatch(cadastroEnderecosRequest(requestCadastroEndereco));
		},
		[atendimento, loginUnico, dispatch],
	);

	useEffect(() => {
		dispatch(incluirExameMedicoClear());
	}, [dispatch]);

	useEffect(() => {
		if (cadastroEnderecos.status === 200 || cadastroEnderecos.status === 201) {
			if (dataEmissaoPid) {
				dispatch(gerarRenachRequest(dataEmissaoPid));
			}
		}
	}, [cadastroEnderecos, dispatch, dataEmissaoPid]);

	useEffect(() => {
		if (
			(gerarRenach.status === 200 || gerarRenach.status === 201) &&
			gerarRenach.data?.numRenachGerado?.trim() !== ''
		) {
			if (gerarRenach.requestData !== null) {
				if (
					gerarRenach.requestData.flagExamePosto &&
					incluirExameMedico.status === 0
				) {
					const requestIncluirExameMedico = {
						cpf: gerarRenach.requestData.cpf,
						codigoCiretranPostoExameMedico: userLogin.posto.toString(),
					};

					dispatch(incluirExameMedicoRequest(requestIncluirExameMedico));
				}
			}
		}
	}, [
		dispatch,
		gerarRenach.data,
		gerarRenach.status,
		userLogin,
		incluirExameMedico,
		gerarRenach.requestData,
	]);

	useEffect(() => {
		if (
			(cadastroEnderecos.status === 200 || cadastroEnderecos.status === 201) &&
			emissaoPid.status === 201
		) {
			setConfirmacaoEndereco(true);
		}
	}, [cadastroEnderecos, dispatch, emissaoPid]);

	useEffect(() => {
		if (pesquisaEnderecosRecebimento.data) {
			const { cpf, enderecoCadastro, enderecosRecebimento, mensagem } =
				pesquisaEnderecosRecebimento.data;

			initialValuesEndereco.cpf = cpf;
			initialValuesEndereco.enderecoCadastro = enderecoCadastro;
			initialValuesEndereco.enderecosRecebimento = enderecosRecebimento;
			initialValuesEndereco.mensagem = mensagem;
			// setDataEnderecoRecebimento(initialValuesEndereco);
		}
	}, [pesquisaEnderecosRecebimento.data]);

	useEffect(() => {
		if (consultaEPortalAutomatizado.data) {
			if (
				consultaEPortalAutomatizado.data.message &&
				consultaEPortalAutomatizado.data.message !== ''
			) {
				setMensagemAlert(consultaEPortalAutomatizado.data.message);
			}
			const {
				cpf,
				registro,
				nome,
				ciretran,
				categoria,
				cep,
				logradouro,
				numero,
				complemento,
				bairro,
				codigoMunicipioIBGE,
				municipio,
				nomeMunicipio,
				codigoInterno,
				message,
				dataValidadeCnh,
			} = consultaEPortalAutomatizado.data;

			// --- DADOS HABILITAÇÃO --- //

			// INFORMAÇÕES CONDUTOR
			initialValuesDadosHabilitacao.cpf = cpf;
			initialValuesDadosHabilitacao.registro = registro;
			initialValuesDadosHabilitacao.nome = nome;
			initialValuesDadosHabilitacao.ciretran = ciretran;
			initialValuesDadosHabilitacao.categoria = categoria;
			initialValuesDadosHabilitacao.cep = cep;
			initialValuesDadosHabilitacao.logradouro = logradouro;
			initialValuesDadosHabilitacao.numero = numero;
			initialValuesDadosHabilitacao.complemento = complemento;
			initialValuesDadosHabilitacao.bairro = bairro;
			initialValuesDadosHabilitacao.codigoMunicipioIBGE = codigoMunicipioIBGE;
			initialValuesDadosHabilitacao.municipio = municipio;
			initialValuesDadosHabilitacao.nomeMunicipio = nomeMunicipio;
			initialValuesDadosHabilitacao.codigoInterno = codigoInterno;
			initialValuesDadosHabilitacao.message = message;
			initialValuesDadosHabilitacao.dataValidadeCnh = dataValidadeCnh;

			// ENDEREÇO CADASTRADO
			initialValuesDadosHabilitacao.endereco.logradouro = logradouro;
			initialValuesDadosHabilitacao.endereco.numero = numero;
			initialValuesDadosHabilitacao.endereco.complemento = complemento;
			initialValuesDadosHabilitacao.endereco.bairro = bairro;
			initialValuesDadosHabilitacao.endereco.descricaoMunicipio = nomeMunicipio;
			initialValuesDadosHabilitacao.endereco.cep = cep;

			setFormValues(initialValuesDadosHabilitacao);

			setIsLoading(false);
		}
	}, [consultaEPortalAutomatizado.data, history, user.cpf]);

	return (
		<>
			{!isLoading && (
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={formValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							<Section title="Status do Condutor">
								<h3 style={{ textTransform: 'uppercase' }}>
									{formik.values.nome}
								</h3>

								{confirmacaoEndereco &&
								mensagemAlert !== '' &&
								mensagemAlert !== 'CONDUTOR APTO PARA SOLICITACAO DE PID' ? (
									<Alert
										type="error"
										showIcon
										message={mensagemAlert}
										closable
										onClose={() => {}}
									/>
								) : (
									''
								)}

								{!confirmacaoEndereco && consultaEPortalAutomatizado.data ? (
									<>
										<DadosCondutor
											dadosCondutor={consultaEPortalAutomatizado.data}
										/>

										<DadosContato formik={formik} />

										<EnderecoRecebimento
											cpf={formik.values.cpf}
											formik={formik}
										/>

										<Row justify="center">
											<Col>
												<ButtonImage type="submit" src="salvar" />
											</Col>
										</Row>
									</>
								) : (
									<>
										<RenachGerado />
									</>
								)}
							</Section>

							<Row justify="space-between" align="middle">
								<Col>
									<ButtonVoltar route="/detran-cnh" />
								</Col>

								<Col>
									<ButtonImage
										type="button"
										src="outro-servico-detran"
										onClick={() => history.push('/detran-cnh')}
									/>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default StatusCondutorPermissaoInternacionalAutomatizado;
