import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { Types, IRequestWithHeaderLocalidadeCreate } from './types';

// ACTIONS
import {
	cadastrarLocalidadeSuccess,
	cadastrarLocalidadeFailure,
} from './actions';

function* cadastrarLocalidade(request: {
	type: string;
	payload: IRequestWithHeaderLocalidadeCreate;
}) {
	const { payload }: { payload: IRequestWithHeaderLocalidadeCreate } = request;

	const { body, headers } = { ...payload };

	delete body.id;
	delete body.idUnidade;

	// ID USUÁRIO MOCADO
	const response: ApiResponse = yield call(
		postApi,
		PATH_SGU,
		`localidades`,
		body,
		headers,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarLocalidadeSuccess(response));
	} else {
		yield put(cadastrarLocalidadeFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_LOCALIDADE_REQUEST, cadastrarLocalidade),
]);
