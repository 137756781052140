import React, { useCallback } from 'react';

import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultarVeiculoV110Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import { emitirAutorizacaoPreviaClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/emitirAutorizacaoPrevia/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import { formatTimestampToDate } from 'utils/genericFunctions';

const ResultadoAutorizacao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { emitirAutorizacaoPrevia } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const handleTipoAutorizacao = useCallback(
		(tipoAutorizacao: string): string => {
			switch (tipoAutorizacao) {
				case 'FABRICACAO':
					return 'FABRICAÇÃO';

				case 'MODIFICACAO':
					return 'MODIFICAÇÃO';

				case 'PRECARIO':
					return 'PRECÁRIO';

				case 'SINISTRO':
					return 'SINISTRO';

				case 'TRANSFORMACAO':
					return 'TRANSFORMAÇÃO';

				default:
					return '';
			}
		},
		[],
	);

	const handleClickImprimir = useCallback(() => {
		window.print();
	}, []);

	const handleButtonVoltar = useCallback(() => {
		dispatch(consultarVeiculoV110Clear());
		dispatch(emitirAutorizacaoPreviaClear());

		history.push('/detran-crv/registrar-autorizacao-previa');
	}, [dispatch, history]);

	return (
		<>
			{emitirAutorizacaoPrevia.data && (
				<>
					<Row>
						<Col span={24}>
							<Section size="lg" title="Autorização Prévia" titleSize="sm">
								<Row justify="center">
									<Col span={18}>
										<Row gutter={[0, 5]}>
											<Col span={17}>Placa:</Col>

											<Col>
												<b>{emitirAutorizacaoPrevia.data.placa}</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={17}>Chassi:</Col>

											<Col>
												<b>
													{emitirAutorizacaoPrevia.data?.chassi?.trim() || ''}
												</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={17}>Número Autorização:</Col>

											<Col>
												<b>{emitirAutorizacaoPrevia.data.numeroAutorizacao}</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={17}>Tipo de Autorização:</Col>

											<Col>
												<b>
													{handleTipoAutorizacao(
														emitirAutorizacaoPrevia.data.tipoAutorizacao,
													)}
												</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={17}>Autorização:</Col>

											<Col>
												<b>{emitirAutorizacaoPrevia.data.autorizacao}</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={17}>Validade da Autorização:</Col>

											<Col>
												<b>
													{formatTimestampToDate(
														emitirAutorizacaoPrevia.data
															.dataValidadeAutorizacao,
													)}
												</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={17}>Situação da Autorização:</Col>

											<Col>
												<b>
													{emitirAutorizacaoPrevia.data.situacaoAutorizacao}
												</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={17}>Data de Inclusão no SERPRO:</Col>

											<Col>
												<b>
													{formatTimestampToDate(
														emitirAutorizacaoPrevia.data.dataRegistroCerpro,
													)}
												</b>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row gutter={[0, 20]} className="no-print">
									<Col span={24} />
								</Row>

								<Row justify="center" className="no-print">
									<Col>
										<ButtonImage src="imprimir" onClick={handleClickImprimir} />
									</Col>
								</Row>
							</Section>

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default ResultadoAutorizacao;
