import { all } from 'redux-saga/effects';

import consultaPrimeiraHabilitacaoSaga from './consultaPrimeiraHabilitacao/saga';
import gerarPreCadastroSaga from './gerarPreCadastro/saga';
import gerarRenachPrimeiraHabilitacaoSaga from './gerarRenachPrimeiraHabilitacao/saga';

export default all([
	consultaPrimeiraHabilitacaoSaga,
	gerarPreCadastroSaga,
	gerarRenachPrimeiraHabilitacaoSaga,
]);
