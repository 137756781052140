export enum Types {
	ATUALIZAR_MENUS = '@sgu/ATUALIZAR_MENUS',
	ATUALIZAR_MENUS_SUCCESS = '@sgu/ATUALIZAR_MENUS_SUCCESS',
	ATUALIZAR_MENUS_FAILURE = '@sgu/ATUALIZAR_MENUS_FAILURE',
	ATUALIZAR_MENUS_CLEAR = '@sgu/ATUALIZAR_MENUS_CLEAR',
}

export interface AtualizarMenus {
	status: number;
	data: null | MenusData;
}

interface MenusData {
	codigo: number;
	mensagem: string;
}

export interface AtualizarMenusRequest {
	idMenu?: number;
	idOrgao: number;
	descricao: string;
	nrOrdem: number;
	siglaMenu: string;
	idUsuarioCadastro: number;
	localidades: number[];
}
