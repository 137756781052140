import { ReducerAction } from 'store/modules/types';

import { Types } from './types';

export function paisRequest(payload: string): ReducerAction {
	return {
		type: Types.PAIS,
		payload,
	};
}
export function paisSuccess(payload: []): ReducerAction {
	return {
		type: Types.PAIS_SUCCESS,
		payload,
	};
}
export function paisFailure(payload: []): ReducerAction {
	return {
		type: Types.PAIS_FAILURE,
		payload,
	};
}

export function paisClear(): { type: string } {
	return {
		type: Types.PAIS_CLEAR,
	};
}
