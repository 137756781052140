export enum Types {
	GET_E_CPF_VALIDATION_REQUEST = '@E-CPF/GET_E_CPF_VALIDATION_REQUEST',
	GET_E_CPF_VALIDATION_SUCCESS = '@E-CPF/GET_E_CPF_VALIDATION_SUCCESS',
	GET_E_CPF_VALIDATION_FAILURE = '@E-CPF/GET_E_CPF_VALIDATION_FAILURE',
	GET_E_CPF_VALIDATION_CLEAR = '@E-CPF/GET_E_CPF_VALIDATION_CLEAR',
}

export interface GetECpfValidation {
	status: number;
	data: Data[] | null;
}

interface Data {
	status: string;
	email: string;
	cpf: string;
	name: string;
	notBefore: number;
	notAfter: number;
	subjectCN: string;
	issuerCN: string;
	certificateType: string;
	redirectUrl: string;
	aki: string;
	serialNumber: string;
	token: string;
	certificateB64: string;
	idScore: number;
}

export interface GetECpfValidationRequest {
	token: string;
	credential: string;
	apiToken: string;
}
