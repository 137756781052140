export enum Types {
	CONSULTA_TOXICOLOGICO_REQUEST = '@cnh/CONSULTA_TOXICOLOGICO_REQUEST',
	CONSULTA_TOXICOLOGICO_SUCCESS = '@cnh/CONSULTA_TOXICOLOGICO_SUCCESS',
	CONSULTA_TOXICOLOGICO_FAILURE = '@cnh/CONSULTA_TOXICOLOGICO_FAILURE',
	CONSULTA_TOXICOLOGICO_CLEAR = '@cnh/CONSULTA_TOXICOLOGICO_CLEAR',
}

export interface ConsultaToxicologico {
	status: number;
	data: null | ConsultaToxicologicoData;
	dataError: ConsultaToxicologicoDataError | null;
}

export interface ConsultaToxicologicoData {
	codigoInterno: string;
	cpf: string;
	dataColeta: string;
	dataValidade: string;
	message: string;
	resultado: string;
	dataLimiteExameMedico: string;
}

export interface ConsultaToxicologicoDataError {
	status: number;
	timestamp: string;
	error: string;
	message: string;
	codigoInterno: string;
	path: string;
}
