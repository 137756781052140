export enum Types {
	EMITIR_TRANSFERENCIAS_MUNICIPIOS_ESTADOS_REQUEST = '@DETRAN-CRV/EMITIR_TRANSFERENCIAS_MUNICIPIOS_ESTADOS_REQUEST',
	EMITIR_TRANSFERENCIAS_MUNICIPIOS_ESTADOS_SUCCESS = '@DETRAN-CRV/EMITIR_TRANSFERENCIAS_MUNICIPIOS_ESTADOS_SUCCESS',
	EMITIR_TRANSFERENCIAS_MUNICIPIOS_ESTADOS_FAILURE = '@DETRAN-CRV/EMITIR_TRANSFERENCIAS_MUNICIPIOS_ESTADOS_FAILURE',
	EMITIR_TRANSFERENCIAS_MUNICIPIOS_ESTADOS_CLEAR = '@DETRAN-CRV/EMITIR_TRANSFERENCIAS_MUNICIPIOS_ESTADOS_CLEAR',

	SALVAR_DADOS_FORM = '@DETRAN-CRV/SALVAR_DADOS_FORM',
}

export interface IRequestWithHeader {
	body: IRequestTransferenciasMunicipiosEstados;
	headers?: any;
}

export interface emitirTransferenciasMunicipiosEstados {
	status: number;
	data: IResponseTransferenciasaMunicipioEstados | null;
	form: IFromRequestTransferenciaMunicipioEstado | null;
}

export interface IFromRequestTransferenciaMunicipioEstado {
	autenticacaoDigitalDut?: string;
	dataContabil?: string;
	codBanco?: string;
	nsuProdesp?: string;
}

export interface IResponseTransferenciasaMunicipioEstados {
	chassi: string;
	placa: string;
	anoFicha: number;
	numeroficha: number;
	planilhaRenavam: string;
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export type IProcedencia = 'IMPORTADA' | 'NACIONAL' | '';

export interface IRequestTransferenciasMunicipiosEstados {
	evento: IEvento;
	dadosTransferencia: {
		anoFabricacao: number;
		anoModelo: number;
		capacidadeCarga: number;
		capacidadePassageiros: number;
		carroceria: {
			longId: number;
			descricao: string;
		};
		categoria: {
			descricao: string;
			longId: number;
		};
		chassi: string;
		chassiRemarcado: boolean;
		cilindrada: number;
		cmt: number;
		combustivel: {
			longId: number;
			descricao: string;
		};
		cor: {
			longId: number;
			descricao: string;
		};
		dataVenda: string;
		eixos: number;
		especie: {
			longId: number;
			descricao: string;
		};
		gravame: {
			arrendatarioFinanceira: string;
			dataVigencia: string;
			financeira: number;
			numeroContrato: string;
			restricaoFinanceira: string;
		};
		marca: {
			longId: number;
			descricao: string;
		};
		municipio: {
			codigo: number;
			nome: string;
		};
		municipioPlacaAnterior: {
			codigo: number;
			nome: string;
		};
		numeroEspelhoAnterior: number;
		pbt: number;
		placa: string;
		placaAnterior: string;
		potencia: number;
		procedencia: IProcedencia;
		proprietario: {
			bairro: string;
			cepProprietario: number;
			complemento: string;
			cpfCnpjProprietario: number;
			enderecoProprietario: string;
			identificacao: string;
			municipioProprietario: {
				codigo: number;
				nome: string;
			};
			nomeProprietario: string;
			nomeProprietarioAnterior: string;
			numero: number;
			orgaoExpedidorProprietario?: string;
			rgProprietario?: number;
			ufProprietario?: string;
			telefone: number;
		};
		protocoloRemacarcao: number;
		renavam: number;
		tipo: {
			longId: number;
			descricao: string;
		};
		ufEspelhoAnterior: string;
		ufPlacaAnterior: string;
		valorVenda: number;
		documentos: any;
		emailCidadao: string;
		dddTelefoneCidadao: number;
		telefoneCidadao: number;
		servicoDigital: boolean;
	};
	taxa: {
		autenticacaoDigitalDut?: string;
		codBanco?: string;
		dataContabil?: string;
		nsuProdesp?: string;
	};
}
