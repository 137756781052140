import React, {
	ChangeEvent,
	Suspense,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Row, Col, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import Radio from 'components/Common/Form/Radio';
import { RadioProps } from 'antd/lib/radio';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';
import Select from 'components/Common/Form/Select';

// ROUTES
import {
	ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO,
	ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_CONFIRMACAO,
} from 'pages/DetranCrv/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router-dom';
import { consultaCategoriaRequest } from 'store/modules/api/detranCrv/enum/consultaCategoria/actions';
import {
	consultarVeiculoV120Clear,
	consultarVeiculoV120Request,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV120/actions';
import { ConsultarVeiculoV120Request } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV120/types';
import { cancelarPlacaPrimeiroEmplacamentoRequest } from 'store/modules/api/detranCrv/SolicitarDocumentos/cancelarPlacaPrimeiroEmplacamento/actions';
import { primeiroEmplacamentoClear } from 'store/modules/api/detranCrv/SolicitarDocumentos/PrimeiroEmplacamento/actions';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import { stringArrayToEnum } from 'utils/genericFunctions';

// FORM
import { schema } from './form';
import { IPrimeiroEmplacamento } from '../form';

const PrimeiroEmplacamentoResultado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [historyState, setHistoryState] = useState<IPrimeiroEmplacamento>({
		chassi: '',
		cpfCnpjProprietario: '',
		restricaoFinanceira: '',
	});

	const {
		primeiroEmplacamento,
		consultarVeiculoV120,
		cancelarPlacaPrimeiroEmplacamento,
	} = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const isPersonalizada: any =
		primeiroEmplacamento.data?.placaPersonalizada &&
		primeiroEmplacamento.data?.placaPersonalizada.length > 0;

	const [placaOptions, setPlacaOptions] = useState<RadioProps[]>([]);

	useEffect(() => {
		if (history.location.state) {
			setHistoryState(history.location.state as any);
		}
		if (consultarVeiculoV120.status === 200) {
			history.push({
				pathname: ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_CONFIRMACAO,
				state: historyState,
			});
		}
		if (cancelarPlacaPrimeiroEmplacamento.status === 200) {
			history.push(ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO);
		}
	}, [
		cancelarPlacaPrimeiroEmplacamento.status,
		consultarVeiculoV120,
		history,
		historyState,
	]);

	useEffect(() => {
		if (
			primeiroEmplacamento.status === 200 &&
			primeiroEmplacamento.data &&
			primeiroEmplacamento.data.placasSugeridas
		) {
			const newOptions = stringArrayToEnum(
				primeiroEmplacamento.data.placasSugeridas,
			);
			setPlacaOptions(newOptions);
			dispatch(consultaCategoriaRequest());
		}
	}, [dispatch, primeiroEmplacamento]);

	const { consultaCategoria } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.Enum,
	);

	const [listaCategorias, setListaCategorias] = useState(
		consultaCategoria.data,
	);

	useEffect(() => {
		if (consultaCategoria.status === 200) {
			setListaCategorias(consultaCategoria.data);
		}
	}, [consultaCategoria]);

	const handleClear = useCallback(() => {
		dispatch(consultarVeiculoV120Clear());
		dispatch(primeiroEmplacamentoClear());
		setHistoryState({
			chassi: '',
			cpfCnpjProprietario: '',
			restricaoFinanceira: '',
		});
	}, [dispatch]);

	const handleCancelPlaca = () => {
		if (
			primeiroEmplacamento.data?.cpfCnpjProprietario &&
			primeiroEmplacamento.data?.cpfCnpjProprietario
		) {
			dispatch(
				cancelarPlacaPrimeiroEmplacamentoRequest({
					chassi: primeiroEmplacamento.data?.chassi,
					cpfCnpjProprietario:
						historyState.cpfCnpjProprietario ||
						primeiroEmplacamento.data?.cpfCnpjProprietario,
				}),
			);
		}
	};

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<>
				<Section size="sm" title="VEÍCULO">
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={{ placa: '', categoria: '' }}
						validate={values =>
							getValidationsErrors(values, schema(isPersonalizada))
						}
						onSubmit={async values => {
							const payload: ConsultarVeiculoV120Request = {
								placa: values.placa,
								chassi: primeiroEmplacamento.data?.chassi
									? primeiroEmplacamento.data?.chassi?.trim() || ''
									: '',
								tipoConsulta: 'EMPLACAMENTO',
								categoria: values.categoria,
							};
							dispatch(consultarVeiculoV120Request(payload));
							// history.push('/detran-crv/escolher-placa/resultado/confirmacao');
						}}
					>
						{(formik: FormikProps<any>) => {
							return (
								<Form>
									<div
										style={{
											justifyContent: 'center',
											marginTop: '-10px',
											marginBottom: '10px',
										}}
									>
										<Row>
											<Col span={24}>
												<Input
													titleSize="lg"
													disabled
													size={30}
													name="chassi"
													title="Chassi"
													value={primeiroEmplacamento.data?.chassi}
												/>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Input
													titleSize="lg"
													disabled
													size={30}
													name="cpfCnpjVeiculo"
													title="CPF/CNPJ Proprietário"
													value={
														historyState.cpfCnpjProprietario ||
														primeiroEmplacamento.data?.cpfCnpjProprietario
													}
												/>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Input
													titleSize="lg"
													disabled
													size={30}
													name="tipoRestricao"
													title="Restrição Financeira"
													value={primeiroEmplacamento.restricaoFinanceira}
												/>
											</Col>
										</Row>
										{primeiroEmplacamento.data &&
											primeiroEmplacamento.data.placasSugeridas && (
												<>
													<Row>
														<Col span={24}>
															<Field
																titleSize="lg"
																as={Select}
																size={30}
																name="categoria"
																title="Categorias"
																options={listaCategorias}
																error={hasError(formik.errors, 'categoria')}
																onChange={(v: string) => {
																	formik.setFieldValue('categoria', v);
																}}
															/>
														</Col>
													</Row>

													<Row>
														<Col
															span={4}
															style={{
																textAlign: 'right',
																paddingRight: '10px',
															}}
														>
															Placas:
														</Col>
														<Col span={20} style={{ marginBottom: '10px' }}>
															<Field
																size={100}
																wrap
																name="placa"
																as={Radio}
																maxOptionsWidth={90}
																options={placaOptions}
																error={
																	!isPersonalizada &&
																	hasError(formik.errors, 'placa')
																}
																onChange={(
																	event: ChangeEvent<HTMLInputElement>,
																) => {
																	const { value } = event.target;
																	formik.setFieldValue('placa', value);
																}}
															/>
														</Col>
													</Row>
												</>
											)}
									</div>
									<Row gutter={[0, 20]} justify="center">
										{primeiroEmplacamento.data &&
											primeiroEmplacamento.data.placaPersonalizada && (
												<Col span={22}>
													<p style={{ color: 'red' }}>
														O sistema verificou que esse veiculo já possui uma
														placa atribuída. Para continuar usando a placa
														abaixo clique em &quot;Utilizar Placa&quot;. Clicar
														em &quot;Cancelar&quot; ira remover a atribuição da
														placa.
													</p>
												</Col>
											)}
										<Col
											span={8}
											style={{ textAlign: 'center', marginTop: '10px' }}
										>
											<Section>
												<div>
													<h3>UF - MUNICIPIO</h3>
													{formik.values.placa && (
														<h3>{formik.values.placa}</h3>
													)}
													{primeiroEmplacamento.data?.placaPersonalizada && (
														<h3>
															{primeiroEmplacamento.data?.placaPersonalizada}
														</h3>
													)}
												</div>
											</Section>
										</Col>
									</Row>

									<Row justify="center" align="middle" gutter={[10, 10]}>
										<Col>
											{primeiroEmplacamento.data &&
												primeiroEmplacamento.data.placasSugeridas && (
													<Button
														onClick={() => {
															dispatch(primeiroEmplacamentoClear());
															history.push(ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO);
														}}
													>
														Cancelar
													</Button>
												)}
											{primeiroEmplacamento.data &&
												primeiroEmplacamento.data.placaPersonalizada && (
													<Button onClick={() => handleCancelPlaca()}>
														Cancelar
													</Button>
												)}
										</Col>
										<Col>
											<Button type="submit"> Utilizar Placa </Button>
										</Col>
									</Row>
								</Form>
							);
						}}
					</Formik>
				</Section>
			</>
			<Row align="middle">
				<Col>
					<ButtonVoltar onClick={() => handleClear()} />
				</Col>
			</Row>
		</Suspense>
	);
};

export default PrimeiroEmplacamentoResultado;
