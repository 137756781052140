import styled, { css } from 'styled-components';

interface NumeroProps {
	hasDigito: boolean;
	marginLeft: boolean;
}

export const Container = styled.div`
	display: flex;
	width: 100%;
`;

export const Numero = styled.div<NumeroProps>`
	${props =>
		props.hasDigito
			? css`
					width: 262px;
			  `
			: css`
					width: 100%;
			  `};

	${props =>
		props.marginLeft &&
		css`
			margin-left: 10px;
		`};
`;

export const Digito = styled.div`
	width: 30px;
`;

export const Divider = styled.div`
	margin: 0px 3px;

	::before {
		content: '-';
	}
`;
