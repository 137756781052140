import React, { Suspense } from 'react';
import { Col, Row, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { impressaoIntencaoVendaClear } from 'store/modules/api/detranCrv/IntencaoDeVenda/Imprimir/actions';
import { ROUTE_DETRAN_CRV } from 'routes/paths';

const CancelarIntencaoVendaResultado: React.FC = () => {
	const dispatch = useDispatch();
	const { cancelarIntencaoVenda } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.intencaoVenda,
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Resultado Cancelar ATPV-e">
				{cancelarIntencaoVenda.status === 200 ? (
					<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
						<Col span={14}>
							<p style={{ textAlign: 'start' }}>
								CANCELAMENTO REALIZADO COM SUCESSO
							</p>
						</Col>
					</Row>
				) : (
					<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
						<Col span={14}>
							<p style={{ textAlign: 'start' }}>
								HOUVE UM PROBLEMA COM O CANCELAMENTO
							</p>
						</Col>
					</Row>
				)}
			</Section>
			<ButtonVoltar
				onClick={() => dispatch(impressaoIntencaoVendaClear())}
				route={ROUTE_DETRAN_CRV}
			/>
		</Suspense>
	);
};

export default CancelarIntencaoVendaResultado;
