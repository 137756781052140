import React from 'react';

// ANTD
import { Row, Col } from 'antd';

// COMPONENTS
import TypographyCommon from 'components/Common/Typography';

// UTILS
import { isFilledValue } from 'utils/stringFunctions';
import { formatDate } from 'utils/genericFunctions';

// REDUX
import { PGAT } from 'store/modules/api/detranCrv/ConsultarResumoExtrato/pgat/types';

interface Props {
	data: PGAT;
}

const ExtratoPGAT: React.FC<Props> = ({ data }) => {
	return (
		<>
			{data.status === 200 && (
				<>
					<Row gutter={[0, 0]} align="top">
						<Col span={6}>
							<TypographyCommon
								text="Chassi:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.chassi)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text="Placa:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.placa)}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={6}>
							<TypographyCommon
								text="Agente Financeiro:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.nomeAgente)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text="CNPJ/CPF Agente:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.cpfCnpjAgente)}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={6}>
							<TypographyCommon
								text="Nome Financiado:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.nomeFinanciado)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text="CNPJ/CPF Financiado:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.cpfCnpjFinanciado)}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={6}>
							<TypographyCommon
								text="N° do Contrato:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(data.data?.contrato)}
								type="label"
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text="Tipo Restrição:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue([
									data.data?.tipoRestricao?.codigo,
									data.data?.tipoRestricao?.descricao,
								])}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>

					<Row gutter={[0, 0]} align="top">
						<Col span={6}>
							<TypographyCommon
								text="Data Inclusão:"
								type="label"
								fontWeight="bold"
								uppercase
								labelSize={11}
							/>
						</Col>
						<Col span={6}>
							<TypographyCommon
								text={isFilledValue(formatDate(data.data?.dataInclusao))}
								type="label"
								labelSize={11}
							/>
						</Col>
					</Row>
				</>
			)}

			{data.status === 406 && (
				<Row gutter={[0, 0]} align="top">
					<Col span={24}>
						<TypographyCommon
							text={data.mensagem}
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
				</Row>
			)}
		</>
	);
};

export default ExtratoPGAT;
