import { combineReducers } from 'redux';

import atualizarSigla from './atualizarSigla/reducer';
import cadastrarSigla from './cadastrarSigla/reducer';
import consultarSiglas from './consultarSiglas/reducer';
import excluirSigla from './excluirSigla/reducer';

export default combineReducers({
	atualizarSigla,
	cadastrarSigla,
	consultarSiglas,
	excluirSigla,
});
