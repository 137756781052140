import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	${props =>
		props &&
		css`
			a {
				color: #000000;
				font-size: calc(0.85rem + ${props.theme.fontScale}%);
				font-weight: 500;
				margin: 24px 24px 0 24px;
			}
		`}
`;
