import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse } from 'services/_api';
import { CHANNEL } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { getApi } from 'services/_sgu';
import { ConsultaMunicipioRequest, Types } from './types';

// ACTIONS
import { consultaMunicipioSuccess, consultaMunicipioFailure } from './actions';

function* consultaMunicipioRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultaMunicipioRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		`domain-service/${CHANNEL}/municipio`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaMunicipioSuccess(response.data));
	} else {
		yield put(consultaMunicipioFailure([]));
	}
}

export default all([
	takeLatest(Types.CONSULTA_MUNICIPIO_REQUEST, consultaMunicipioRequest),
]);
