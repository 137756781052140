export enum Types {
	CADASTRAR_PARAMETRO_SISTEMA_REDIS = '@SGU/CADASTRAR_PARAMETRO_SISTEMA_REDIS',
	CADASTRAR_PARAMETRO_SISTEMA_REDIS_SUCCESS = '@SGU/CADASTRAR_PARAMETRO_SISTEMA_REDIS_SUCCESS',
	CADASTRAR_PARAMETRO_SISTEMA_REDIS_FAILURE = '@SGU/CADASTRAR_PARAMETRO_SISTEMA_REDIS_FAILURE',
	CADASTRAR_PARAMETRO_SISTEMA_REDIS_CLEAR = '@SGU/CADASTRAR_PARAMETRO_SISTEMA_REDIS_CLEAR',
}

export interface CadastrarParametroSistemaRedis {
	status: number;
}

export interface CadastrarParametroSistemaRedisRequest {
	chave: string;
	valor: string;
}
