import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, SolicitaCnhDigitalRequest } from './types';

// ACTIONS
import {
	solicitaCnhDigitalSuccess,
	solicitaCnhDigitalFailure,
} from './actions';

function* solicitaCnhDigital(request: ReducerAction) {
	const { payload }: { payload: SolicitaCnhDigitalRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`sockets/${CHANNEL}/solicita/cnh-digital`,
		payload,
	);

	if (response.status === 200) {
		yield put(solicitaCnhDigitalSuccess(response));
	} else {
		yield put(solicitaCnhDigitalFailure(response));
	}
}

export default all([
	takeLatest(Types.SOLICITA_CNH_DIGITAL_REQUEST, solicitaCnhDigital),
]);
