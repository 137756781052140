import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { ConsultarMotivosRequest, Types } from './types';

// ACTIONS
import { consultarMotivosSuccess, consultarMotivosFailure } from './actions';

function* consultarMotivos(request: ReducerAction) {
	const { payload }: { payload: ConsultarMotivosRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarMotivosRequest;

	const response: ApiResponse = yield call(getApi, PATH_SGU, `motivos`, body);

	if (response.status === 200) {
		yield put(consultarMotivosSuccess(response));
	} else {
		yield put(consultarMotivosFailure());
	}
}

export default all([takeLatest(Types.CONSULTAR_MOTIVOS, consultarMotivos)]);
