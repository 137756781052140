import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU_SERVICE } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConsultarRelatorioMensalDareRequest } from './types';
import {
	consultarRelatorioMensalDareClear,
	consultarRelatorioMensalDareFailure,
	consultarRelatorioMensalDareSuccess,
} from './actions';

function* consultarRelatorioMensalDare(request: ReducerAction) {
	const { payload }: { payload: IConsultarRelatorioMensalDareRequest } =
		request;

	yield put(consultarRelatorioMensalDareClear());

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU_SERVICE,
		`/estatistica/relatorio-dare`,
		{
			dataInicio: payload.dataInicial,
			dataFim: payload.dataFinal,
		},
	);

	if (response.status === 200) {
		yield put(
			consultarRelatorioMensalDareSuccess(response.status, response.data),
		);
	} else {
		yield put(consultarRelatorioMensalDareFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_RELATORIO_MENSAL_DARE_REQUEST,
		consultarRelatorioMensalDare,
	),
]);
