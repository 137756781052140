import { ReducerAction } from 'store/modules/types';

import { Types, ExcluirParametroSistemaRequest } from './types';

export function excluirParametroSistemaRequest(
	data: ExcluirParametroSistemaRequest,
): ReducerAction {
	return {
		type: Types.EXCLUIR_PARAMETRO_SISTEMA,
		payload: data,
	};
}
export function excluirParametroSistemaSuccess(payload: object): ReducerAction {
	return {
		type: Types.EXCLUIR_PARAMETRO_SISTEMA_SUCCESS,
		payload,
	};
}
export function excluirParametroSistemaFailure(): ReducerAction {
	return {
		type: Types.EXCLUIR_PARAMETRO_SISTEMA_FAILURE,
		payload: null,
	};
}
export function excluirParametroSistemaClear(): ReducerAction {
	return {
		type: Types.EXCLUIR_PARAMETRO_SISTEMA_CLEAR,
		payload: null,
	};
}
