import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_PARAMETROS_LOCALIDADE = '@SGU/CONSULTAR_PARAMETROS_LOCALIDADE',
	CONSULTAR_PARAMETROS_LOCALIDADE_SUCCESS = '@SGU/CONSULTAR_PARAMETROS_LOCALIDADE_SUCCESS',
	CONSULTAR_PARAMETROS_LOCALIDADE_FAILURE = '@SGU/CONSULTAR_PARAMETROS_LOCALIDADE_FAILURE',
	CONSULTAR_PARAMETROS_LOCALIDADE_CLEAR = '@SGU/CONSULTAR_PARAMETROS_LOCALIDADE_CLEAR',
}

export interface ConsultarParametrosLocalidade {
	status: number;
	data: ParametrosLocalidade[] | null;
	enum: OptionProps[];
	totalRegistrosConsulta: number;
}

export interface ParametrosLocalidade {
	id: number;
	descricao: string;
	sigla: string;
	valor: string;
	idLocalidade: string;
}

export interface ConsultarParametrosLocalidadeRequest {
	registroInicial?: number;
	limite?: number;
	current?: number;
	codigo?: number | string;
	descricao?: string;
	sigla?: string;
	valor?: string;
	id?: string | number;
	idLocalidade?: number | string;
	idOrgao?: number | string;
}
