import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarSiglaRequest } from './types';

export function cadastrarSiglaRequest(
	data: CadastrarSiglaRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_SIGLA_REQUEST,
		payload: data,
	};
}
export function cadastrarSiglaSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_SIGLA_SUCCESS,
		payload,
	};
}
export function cadastrarSiglaFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_SIGLA_FAILURE,
		payload: null,
	};
}
export function cadastrarSiglaClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_SIGLA_CLEAR,
		payload: null,
	};
}
