import { all } from 'redux-saga/effects';

import cadastraAtendimentoPf from './cadastraAtendimentoPf/saga';
import cadastraAtendimentoPj from './cadastraAtendimentoPj/saga';
import consultaAtendimentoPf from './consultaAtendimentoPf/saga';
import consultaAtendimentoPj from './consultaAtendimentoPj/saga';
import respostaNaoResolvida from './respostaNaoResolvida/saga';
import respostaResolvida from './respostaResolvida/saga';

export default all([
	cadastraAtendimentoPf,
	cadastraAtendimentoPj,
	consultaAtendimentoPf,
	consultaAtendimentoPj,
	respostaNaoResolvida,
	respostaResolvida,
]);
