import { all } from 'redux-saga/effects';

import listarSolicitacaoPortal from './listarSolicitacaoPortal/saga';
import enviarSolicitacoesPortal from './enviarSolicitacoesPortal/saga';
import atualizaSolicitacaoPortal from './atualizaSolicitacaoPortal/saga';
import enviaSolicitacaoMP from './enviaSolicitacaoMP/saga';
import impressaoTermoPaternidade from './impressaoTermoPaternidade/saga';
import impressaoGeraSenha from './impressaoGeraSenha/saga';
import eventoInicio from './eventoInicio/saga';
import eventoTermino from './eventoTermino/saga';

export default all([
	listarSolicitacaoPortal,
	enviarSolicitacoesPortal,
	atualizaSolicitacaoPortal,
	enviaSolicitacaoMP,
	impressaoTermoPaternidade,
	impressaoGeraSenha,
	eventoInicio,
	eventoTermino,
]);
