import * as Yup from 'yup';

// UTILS
import { onlyLetters } from 'utils/yupValidations';

export interface IFormDadosSolicitante {
	cpf: string;
	nome: string;
	email: string;
	celular: string;
	telefoneResidencial: string;
	telefoneComercial: string;
	telefoneContato: string;
}

export const getInitialValues = (
	values: any,
	isSolcitante: boolean = false,
) => {
	const {
		cpf,
		nome,
		email,
		telefoneCelular,
		telefoneResidencial,
		telefoneComercial,
		telefoneContato,
	} = values;

	const initialValues: IFormDadosSolicitante = {
		cpf: cpf || '',
		nome: nome || '',
		email: email || '',
		celular: isSolcitante ? telefoneCelular : values.celular,
		telefoneResidencial: isSolcitante ? telefoneResidencial : values.telefone1,
		telefoneComercial: isSolcitante ? telefoneComercial : values.telefone2,
		telefoneContato: isSolcitante ? telefoneContato : values.telefone3,
	};

	return initialValues;
};

export const schema = Yup.object<IFormDadosSolicitante>().shape({
	nome: Yup.string()
		.max(44, 'Campo Nome: Tamanho máximo de 44 caracteres.')
		.test({
			name: 'onlyLetters',
			exclusive: true,
			message: 'Campo Nome: Apenas letras são permitidas.',
			test: value => onlyLetters(value),
		}),
	email: Yup.string()
		.required('Campo Obrigatório: E-mail.')
		.max(50, 'Campo E-mail: Tamanho máximo de 50 caracteres.'),
	telefones: Yup.string().when(
		['celular', 'telefoneResidencial', 'telefoneComercial', 'telefoneContato'],
		{
			is: (celular, telefoneResidencial, telefoneComercial, telefoneContato) =>
				!celular &&
				!telefoneResidencial &&
				!telefoneComercial &&
				!telefoneContato,
			then: Yup.string().required('Preencha pelo menos um número de telefone.'),
		},
	),
});
