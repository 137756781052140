import React from 'react';

// ANTD
import { Col } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

// STORES
import { PesquisaNominal } from 'store/modules/api/aac/pesquisaNominal/types';
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import Radio from 'components/Common/Form/Radio';
import Input from 'components/Common/Form/Input/Input';

// UTILS
import hasError from 'utils/getFormErrors';
import { nomeIirgd, onlyNumbersAndLetters } from 'utils/genericFunctions';

// FORM
import { Field, FormikProps } from 'formik';
import { FormEmissaoNumeral } from '../../Numeral/Resultado/form';

interface Props {
	formik: FormikProps<FormEmissaoNumeral> | FormikProps<PesquisaNominal>;
}

const Procurador: React.FC<Props> = ({ formik }) => {
	const { errors, setFieldValue } = formik;
	return (
		<>
			<Col span={10}>
				<Field
					as={Radio}
					title="Requerente"
					size={2000}
					name="requerente"
					options={data.requerente}
					defaultValue={formik.values.requerente}
					value={formik.values.requerente}
					customChange={(e: RadioChangeEvent) => {
						if (!e.target.value) {
							setFieldValue('nomeProcurador', '');
							setFieldValue('docProcurador', '');
						}
					}}
				/>
			</Col>
			{formik.values.requerente && (
				<>
					<Col span={24}>
						<Field
							title="Nome do Procurador"
							name="nomeProcurador"
							size={80}
							titleSize="lg"
							maxLength={50}
							value={nomeIirgd(formik.values.nomeProcurador)}
							as={Input}
							error={hasError(errors, 'nomeProcurador')}
						/>
					</Col>
					<Col span={12}>
						<Field
							title="Doc. Apresentado"
							name="docProcurador"
							as={Input}
							titleSize="lg"
							maxLength={18}
							onChange={(e: { target: { value: string } }) => {
								formik.setFieldValue(
									'docProcurador',
									onlyNumbersAndLetters(e.target.value.toLocaleUpperCase()),
								);
							}}
							error={hasError(errors, 'docProcurador')}
						/>
					</Col>
				</>
			)}
		</>
	);
};

export default Procurador;
