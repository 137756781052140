import { ReducerAction } from 'store/modules/types';

import { Types, EventoTerminoSessaoRequest } from './types';

export function eventoTerminoSessaoRequest(
	data: EventoTerminoSessaoRequest,
): ReducerAction {
	return {
		type: Types.EVENTO_TERMINO_SESSAO_REQUEST,
		payload: data,
	};
}
export function eventoTerminoSessaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.EVENTO_TERMINO_SESSAO_SUCCESS,
		payload,
	};
}
export function eventoTerminoSessaoFailure(payload: object): ReducerAction {
	return {
		type: Types.EVENTO_TERMINO_SESSAO_FAILURE,
		payload,
	};
}
export function eventoTerminoSessaoClear(): ReducerAction {
	return {
		type: Types.EVENTO_TERMINO_SESSAO_CLEAR,
		payload: null,
	};
}
