export enum Types {
	GET_CPF_PERMITIDO_REQUEST = '@BOLSA-POVO/GET_CPF_PERMITIDO_REQUEST',
	GET_CPF_PERMITIDO_SUCCESS = '@BOLSA-POVO/GET_CPF_PERMITIDO_SUCCESS',
	GET_CPF_PERMITIDO_FAILURE = '@BOLSA-POVO/GET_CPF_PERMITIDO_FAILURE',
	GET_CPF_PERMITIDO_CLEAR = '@BOLSA-POVO/GET_CPF_PERMITIDO_CLEAR',
}

export interface GetCpfPermitido {
	status: number;
	data: Tabelas | null;
	request: string | null;
}

export interface IRequestGetCpfPermitido {
	cpf: string;
	evento: {
		id_atendimento: string;
		id_cidadao: string;
		cpf: number;
		identificacao: number;
		canal: {
			id: number;
			desc: string;
			estacao: {
				id: number;
				desc: string;
			};
			localidade: {
				id: number;
				desc: string;
			};
		};
	};
}

interface Errors {
	idErro: string;
	dsErro: string;
	idControle: string;
	indiceControle: string;
}

export interface Tabelas {
	cpf: string;
	idTipoEndereco: string;
	cep: string;
	tipoLogradouro: string;
	logradouro: string;
	numero: string;
	referencia: string;
	semNumero: string;
	complemento: string;
	bairro: string;
	municipio: string;
	uf: string;
	dataAtualizacao: string;
	email: string;
	foneCelular: string;
	foneFixo: string;
	latitude: string;
	longitude: string;
	tipo: string;
	idIbge: string;
	ip1: string;
	ip2: string;
	agentBroseSo: string;
	pagina: string;
	so: string;
	bro: string;
	usuarioAtualizacao: string;
}

export interface GetCpfPermitidoData {
	cabecalho: {
		nomeTabela: string;
		versao: string;
		dataProcessamento: string;
		descricao: string;
		observacao: string;
		autor: string;
		qtdRegistros: string;
	};
	retorno: {
		idRetorno: string;
		dsRetorno: string;
	};
	erros: Errors[];
	tabelas: Tabelas[];
}

export interface Evento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: number;
	identificacao: number;
	ip: string;
	tipo: string;
	id_servico: string | number;
	desc_servico: string;
	canal: {
		id: number;
		desc: string;
		estacao: {
			id: number;
			desc: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
}
