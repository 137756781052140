import React, { useCallback } from 'react';

// REDUX
import { useDispatch } from 'react-redux';
import { cadastroRgFinalizado } from 'store/modules/api/iirgd/cadastroRg/actions';
import { protocoloFicClear } from 'store/modules/api/iirgd/protocoloFic/actions';
import { protocoloFicDigitalClear } from 'store/modules/api/iirgd/protocoloFicDigital/actions';
import { protocoloReusoClear } from 'store/modules/api/iirgd/protocoloReuso/actions';
// import { IParametrosLocalidade } from 'store/modules/api/sgu/loginUnico/types';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
// import { Modal } from 'antd';

// UTILS
// import { HABILITA_CIN } from 'store/modules/api/sgu/loginUnico/constants';

export const FinalizarProcesso: React.FC = () => {
	const dispatch = useDispatch();

	// const verifyLocationIsAllowedToCIN = useCallback(() => {
	// 	let isAllowed = false;
	// 	loginUnico.login.user.parametrosLocalidade.forEach(
	// 		(item: IParametrosLocalidade) => {
	// 			if (
	// 				item.sigla.toLowerCase() === HABILITA_CIN.toLowerCase() &&
	// 				item.valor.toLowerCase() === 'true'
	// 			) {
	// 				isAllowed = true;
	// 			}
	// 		},
	// 	);
	// 	return isAllowed;
	// }, [loginUnico.login.user.parametrosLocalidade]);

	// const cadastroRg.form.principal = useCallback(() => {
	// 	if (
	// 		cadastroRg.info.idade <= 16 &&
	// 		cadastroRg.tipo.primeiraVia &&
	// 		cadastroRg.form.principal?.cpf &&
	// 		verifyLocationIsAllowedToCIN()
	// 	) {
	// 		return true;
	// 	}
	// 	return false;
	// }, [
	// 	cadastroRg.form.principal,
	// 	cadastroRg.info.idade,
	// 	cadastroRg.tipo.primeiraVia,
	// 	verifyLocationIsAllowedToCIN,
	// ]);

	const handleNormalFlux = useCallback(() => {
		dispatch(cadastroRgFinalizado(true));
		dispatch(protocoloFicClear());
		dispatch(protocoloFicDigitalClear());
		dispatch(protocoloReusoClear());
		// dispatch(consultarMunicipiosIbgeClear());
	}, [dispatch]);

	return (
		<ButtonImage
			type="button"
			src="finalizar-processo"
			onClick={handleNormalFlux}
		/>
	);
};
