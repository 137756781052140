import React, { useCallback, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { ApplicationState } from 'store';
import { tiposResolucaoRequest } from 'store/modules/api/procon/dominiosCrm/tiposResolucao/actions';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import { limparMascara } from 'utils/genericFunctions';

// ANT DESIGN
import { Col, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';

// FORM
import {
	RespostaResolvidaForm,
	RespostaResolvidaRequest,
} from 'store/modules/api/procon/atendimentoCip/respostaResolvida/types';
import {
	RespostaNaoResolvidaForm,
	RespostaNaoResolvidaRequest,
} from 'store/modules/api/procon/atendimentoCip/respostaNaoResolvida/types';
import { respostaResolvidaRequest } from 'store/modules/api/procon/atendimentoCip/respostaResolvida/actions';
import { respostaNaoResolvidaRequest } from 'store/modules/api/procon/atendimentoCip/respostaNaoResolvida/actions';

// COMPONENTS
import { Form, Formik, Field } from 'formik';
import TextArea from 'components/Common/Form/Input/TextArea';
import Select from 'components/Common/Form/Select';
import LightButton from 'components/Common/Buttons/Light';
import TypographyCommon from 'components/Common/Typography';
import Anexos from 'pages/procon/components/Anexos';

// FORM
import {
	initialValuesResolvido,
	schemaResolvido,
	initialValuesNaoResolvido,
	schemaNaoResolvido,
} from './form';

type TypeDataAtendimento = {
	protocolo: string;
	atendimentoId: string;
};

interface Props {
	visivel: boolean;
	resolvido: boolean | null;
	setShowAlert: (value: React.SetStateAction<boolean>) => void;
	setShowModal: (value: React.SetStateAction<boolean>) => void;
	dataAtendimento: TypeDataAtendimento | null;
}

const ResolucaoAtendimentoModal: React.FC<Props> = ({
	visivel = false,
	resolvido = null,
	setShowAlert,
	setShowModal,
	dataAtendimento,
}) => {
	const dispatch = useDispatch();

	const ref = useRef<any>();

	const dadosAtendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.salvarAtendimento.data,
	);

	const loginUnico = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { tiposResolucao } = useSelector(
		(state: ApplicationState) => state.api.procon.dominiosCrm,
	);

	const { respostaResolvida, respostaNaoResolvida } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentoCip,
	);

	const { consultaAtendimentos } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentos,
	);

	const requestResolvido = useCallback(
		(values: RespostaResolvidaForm) => {
			const evento = {
				id_atendimento: dadosAtendimento?.idAtendimento || '',
				id_cidadao: dadosAtendimento?.idCidadao || '',
				cpf: limparMascara(user.cpf) || '',
				identificacao:
					Number(limparMascara(consultaAtendimentos.consulta)) || '',
				ip: user.ip || '',
				canal: {
					id: loginUnico.login.user.idCanal || 0,
					desc: loginUnico.login.user.descCanal || '',
					localidade: {
						id: 8000,
						desc: user.nomePosto || '',
					},
				},
			};

			const payload: RespostaResolvidaRequest = {
				evento,
				atendimentoId:
					(dataAtendimento?.protocolo ? '' : dataAtendimento?.atendimentoId) ||
					'',
				protocolo: dataAtendimento?.protocolo || '',
				tipoDaResolucao: values.tipoDaResolucao || 0,
				textoDaJustificativa: values.justificativa || '',
			};

			dispatch(respostaResolvidaRequest(payload));
		},

		[
			dataAtendimento,
			dadosAtendimento,
			user,
			loginUnico,
			consultaAtendimentos,
			dispatch,
		],
	);

	const requestNaoResolvido = useCallback(
		(values: RespostaNaoResolvidaForm) => {
			const evento = {
				id_atendimento: dadosAtendimento?.idAtendimento || '',
				id_cidadao: dadosAtendimento?.idCidadao || '',
				cpf: user.cpf || '',
				identificacao:
					Number(limparMascara(consultaAtendimentos.consulta)) || '',
				ip: user.ip || '',
				canal: {
					id: loginUnico.login.user.idCanal || 0,
					desc: loginUnico.login.user.descCanal || '',
					localidade: {
						id: Number(user.posto) || 0,
						desc: user.nomePosto || '',
					},
				},
			};

			const payload: RespostaNaoResolvidaRequest = {
				evento,
				atendimentoId:
					(dataAtendimento?.protocolo ? '' : dataAtendimento?.atendimentoId) ||
					'',
				protocolo: dataAtendimento?.protocolo || '',
				textoDaJustificativa: values.justificativa,
				anexos: values.anexos,
			};

			dispatch(respostaNaoResolvidaRequest(payload));
		},

		[
			dataAtendimento,
			dadosAtendimento,
			user,
			loginUnico,
			consultaAtendimentos,
			dispatch,
		],
	);

	const handleSubmit = useCallback(
		(values: any) =>
			resolvido ? requestResolvido(values) : requestNaoResolvido(values),
		[resolvido, requestResolvido, requestNaoResolvido],
	);

	const handleCancel = useCallback(() => {
		setShowModal(false);
		ref.current?.resetForm();
	}, [setShowModal]);

	useEffect(() => {
		dispatch(tiposResolucaoRequest());
	}, [dispatch]);

	useEffect(() => {
		if (respostaResolvida.status === 200 || respostaResolvida.status === 201) {
			setShowAlert(true);
		}

		if (
			respostaNaoResolvida.status === 200 ||
			respostaNaoResolvida.status === 201
		) {
			setShowAlert(true);
		}

		setShowModal(false);
	}, [setShowAlert, setShowModal, respostaResolvida, respostaNaoResolvida]);

	return (
		<>
			<Modal
				visible={visivel}
				onCancel={handleCancel}
				width={800}
				title={
					resolvido ? (
						<TypographyCommon
							text="Reclamação Resolvida:"
							type="h5"
							fontWeight="bold"
							color="#2ea44f"
							uppercase
						/>
					) : (
						<TypographyCommon
							text="Reclamação Não Resolvida:"
							type="h5"
							fontWeight="bold"
							color="#cb2431"
							uppercase
						/>
					)
				}
				footer={[]}
			>
				{resolvido ? (
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValuesResolvido}
						validate={values => getValidationsErrors(values, schemaResolvido)}
						onSubmit={handleSubmit}
						innerRef={ref}
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[0, 0]} align="top" justify="start">
									<Col span={24}>
										<TypographyCommon
											text="Resolução:"
											fontWeight="bold"
											uppercase
										/>
									</Col>
									<Col span={24}>
										<Field
											as={Select}
											name="tipoDaResolucao"
											options={tiposResolucao.data}
											onChange={(value: number) => {
												formik.setFieldValue('tipoDaResolucao', value);
											}}
											error={hasError(formik.errors, 'tipoDaResolucao')}
										/>
									</Col>
								</Row>
								<Row gutter={[0, 0]} align="top" justify="start">
									<Col span={24}>
										<TypographyCommon
											text="Justificativa:"
											subtitle=" (Quantidade Mínima 50 Caracteres e Máxima 500)"
											fontWeight="bold"
											uppercase
										/>
									</Col>
									<Col span={24}>
										<Field
											as={TextArea}
											name="justificativa"
											formik={formik}
											maxLength="500"
											error={hasError(formik.errors, 'justificativa')}
										/>
									</Col>
								</Row>
								<br />
								<Row key="footer" gutter={[0, 0]} align="top" justify="center">
									<Col span={5}>
										<Row align="top" justify="center">
											<LightButton
												type="reset"
												buttonColor="default"
												text="Cancelar"
												buttonSize="sm"
												buttonWidth={130}
												onClick={handleCancel}
											/>
										</Row>
									</Col>
									<Col span={5}>
										<Row align="top" justify="center">
											<LightButton
												type="submit"
												buttonColor="green"
												text="Resolvido"
												icon="like"
												buttonSize="sm"
												buttonWidth={140}
											/>
										</Row>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				) : (
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValuesNaoResolvido}
						validate={values =>
							getValidationsErrors(values, schemaNaoResolvido)
						}
						onSubmit={handleSubmit}
						innerRef={ref}
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[0, 0]} align="top" justify="start">
									<Col span={24}>
										<TypographyCommon
											text="Justificativa:"
											subtitle=" (Quantidade Mínima 50 Caracteres e Máxima 500)"
											fontWeight="bold"
											uppercase
										/>
									</Col>
									<Col span={24}>
										<Field
											as={TextArea}
											name="justificativa"
											formik={formik}
											maxLength="500"
											error={hasError(formik.errors, 'justificativa')}
										/>
									</Col>
								</Row>
								<br />
								<Anexos formik={formik} />
								<Row key="footer" gutter={[0, 0]} align="top" justify="center">
									<Col span={5}>
										<Row align="top" justify="center">
											<LightButton
												type="reset"
												buttonColor="default"
												text="Cancelar"
												buttonSize="sm"
												buttonWidth={130}
												onClick={handleCancel}
											/>
										</Row>
									</Col>
									<Col span={5}>
										<Row align="top" justify="center">
											<LightButton
												type="submit"
												buttonColor="red"
												text="Não Resolvido"
												icon="unlike"
												buttonSize="sm"
												buttonWidth={140}
											/>
										</Row>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				)}
			</Modal>
		</>
	);
};
export default ResolucaoAtendimentoModal;
