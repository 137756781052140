import * as Yup from 'yup';
import { ConsultaConsumidorRequest } from 'store/modules/api/procon/consumidores/consultaConsumidor/types';

export const initialValues: ConsultaConsumidorRequest = {
	servico: 'consulta',
	cpf: '',
	evento: {
		id_atendimento: '',
		id_cidadao: '',
		cpf: '',
		identificacao: '',
		ip: '',
		canal: {
			id: 0,
			desc: '',
			estacao: {
				id: '',
				desc: '',
			},
			localidade: {
				id: 0,
				desc: '',
			},
		},
		atendente: {
			nome: '',
			id_atendente: '',
			id_posto: '',
			desc_posto: '',
		},
	},
};

export const schema = Yup.object<ConsultaConsumidorRequest>().shape({
	cpf: Yup.string().required('Campo Obrigatório: CPF.'),
});
