export enum Types {
	IMPRIMIR_CRLVE_REQUEST = '@DETRAN-CRV/IMPRIMIR_CRLVE_REQUEST',
	IMPRIMIR_CRLVE_SUCCESS = '@DETRAN-CRV/IMPRIMIR_CRLVE_SUCCESS',
	IMPRIMIR_CRLVE_FAILURE = '@DETRAN-CRV/IMPRIMIR_CRLVE_FAILURE',
	IMPRIMIR_CRLVE_CLEAR = '@DETRAN-CRV/IMPRIMIR_CRLVE_CLEAR',
}

export interface imprimirCrvle {
	status: number;
	data: impressao | null;
	placaEscolhida?: string;
}

export interface impressao {
	pdf: string;
}
