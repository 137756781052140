export enum Types {
	ATUALIZAR_USUARIO_REQUEST = '@SGU/ATUALIZAR_USUARIO_REQUEST',
	ATUALIZAR_USUARIO_SUCCESS = '@SGU/ATUALIZAR_USUARIO_SUCCESS',
	ATUALIZAR_USUARIO_FAILURE = '@SGU/ATUALIZAR_USUARIO_FAILURE',
	ATUALIZAR_USUARIO_CLEAR = '@SGU/ATUALIZAR_USUARIO_CLEAR',
}

export interface AtualizarUsuario {
	status: number;
	data: null | UsuarioData;
}

interface UsuarioData {
	codigo: string;
	mensagem: string;
}

export interface AtualizarUsuarioRequest {
	id?: number | string;
	nome: string;
	email: string;
	endereco: string;
	cpf: string;
	rg: string;
	digitoRg: string;
	uf: string;
	ddCelular: number | null;
	nrCelular: string;
	ddComercial: number | null;
	nrComercial: string;
	ddResidencial: number | null;
	nrResidencial: string;
	perfil: number;
	status: number;
}
