import { ReducerAction } from 'store/modules/types';

import { SolicitaCnhDigitalRequest, Types } from './types';

export function solicitaCnhDigitalRequest(
	payload: SolicitaCnhDigitalRequest,
): ReducerAction {
	return {
		type: Types.SOLICITA_CNH_DIGITAL_REQUEST,
		payload,
	};
}
export function solicitaCnhDigitalSuccess(payload: object): ReducerAction {
	return {
		type: Types.SOLICITA_CNH_DIGITAL_SUCCESS,
		payload,
	};
}
export function solicitaCnhDigitalFailure(payload: object): ReducerAction {
	return {
		type: Types.SOLICITA_CNH_DIGITAL_FAILURE,
		payload,
	};
}
export function solicitaCnhDigitalClear(): ReducerAction {
	return {
		type: Types.SOLICITA_CNH_DIGITAL_CLEAR,
		payload: null,
	};
}
