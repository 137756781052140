import styled, { css } from 'styled-components';

interface ContainerProps {
	buttonSize: 'sm' | 'md' | 'lg';
	isDisabled: boolean;
	buttonWidth?: string;
}

export interface ButtonProps {
	buttonColor: 'default' | 'green' | 'blue' | 'red';
	buttonStyle: 'light' | 'solid' | 'outline' | 'link';
	buttonSize: 'sm' | 'md' | 'lg';
	isDisabled: boolean;
}

interface TextProps {
	buttonSize: 'sm' | 'md' | 'lg';
}

export const Container = styled.div<ContainerProps>`
	button {
		appearance: none;
		border: 1px solid;
		border-radius: 4px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		line-height: 20px;
		position: relative;
		user-select: none;
		transition-duration: 0.5s;
		vertical-align: middle;
		white-space: nowrap;

		${props => css`
			${props.buttonSize === 'sm' &&
			css`
				padding: 4px 12px;
			`};

			${props.buttonSize === 'md' &&
			css`
				padding: 6px 16px;
			`};

			${props.buttonSize === 'lg' &&
			css`
				padding: 8px 24px;
			`};

			${props.buttonWidth !== undefined &&
			props.buttonWidth !== '' &&
			css`
				width: ${props.buttonWidth};
			`};
		`};

		&:hover {
			${props => css`
				${props.isDisabled &&
				css`
					cursor: not-allowed;
				`};
			`};
		}

		svg {
			position: relative;

			${props => css`
				${props.buttonSize === 'sm' &&
				css`
					margin-right: 6px;
					font-size: calc(0.7rem + ${props.theme.fontScale}%);
					top: 4px;
				`};

				${props.buttonSize === 'md' &&
				css`
					margin-right: 8px;
					font-size: calc(0.75rem + ${props.theme.fontScale}%);
					top: 4px;
				`};

				${props.buttonSize === 'lg' &&
				css`
					margin-right: 8px;
					font-size: calc(0.8rem + ${props.theme.fontScale}%);
					top: 2px;
				`};
			`};
		}
	}
`;

export const Text = styled.div<TextProps>`
	color: inherit;
	font-weight: 500;
	letter-spacing: 0.01rem;

	${props => css`
		${props.buttonSize === 'sm' &&
		css`
			font-size: calc(0.75rem + ${props.theme.fontScale}%);
		`};

		${props.buttonSize === 'md' &&
		css`
			font-size: calc(0.8rem + ${props.theme.fontScale}%);
		`};

		${props.buttonSize === 'lg' &&
		css`
			font-size: calc(0.85rem + ${props.theme.fontScale}%);
		`};
	`};
`;
