import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaGforRequest } from './types';

export function consultaGforRequest(data: ConsultaGforRequest): ReducerAction {
	return {
		type: Types.CONSULTA_GFOR_REQUEST,
		payload: data,
	};
}
export function consultaGforSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_GFOR_SUCCESS,
		payload,
	};
}
export function consultaGforFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_GFOR_FAILURE,
		payload,
	};
}
export function consultaGforClear(): ReducerAction {
	return {
		type: Types.CONSULTA_GFOR_CLEAR,
		payload: null,
	};
}
