import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { consultarMutuarioRequest } from 'store/modules/api/cdhu/consultarMutuario/actions';

// PATH_SGU
import { ROUTE_CDHU_MUTUARIO_DADOS } from 'pages/cdhu/routes/paths';

// COMPONENTS
import Section from 'components/Common/Section';
import CPF from 'components/Common/Form/Fields/CPF';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { IFormConsultarMutuario, initialValues, schema } from './form';

const PesquisarMutuario: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [hasSubmit, setHasSubmit] = useState<boolean>(false);

	const { consultarMutuario, consultarPrestacoesAtrasadas } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	useEffect(() => {
		if (
			hasSubmit &&
			consultarMutuario.status === 200 &&
			consultarPrestacoesAtrasadas.status === 200
		) {
			history.push(ROUTE_CDHU_MUTUARIO_DADOS);
		}
	}, [hasSubmit, consultarMutuario, consultarPrestacoesAtrasadas, history]);

	const handleValidate = useCallback((formValues: IFormConsultarMutuario) => {
		return getValidationsErrors(formValues, schema);
	}, []);

	const handleSubmit = useCallback(
		(values: IFormConsultarMutuario) => {
			setHasSubmit(true);

			if (values.numContrato) {
				dispatch(
					consultarMutuarioRequest(token, {
						numeroContrato: Number(values.numContrato),
					}),
				);
				return;
			}

			dispatch(consultarMutuarioRequest(token, { cpf: values.cpf }));
		},
		[dispatch, token],
	);

	return (
		<>
			<Section size="lg" title="Pesquisar mutuário" titleSize="sm">
				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={handleValidate}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row gutter={[0, 10]}>
										<Col span={14}>
											<CPF
												title="CPF"
												titleSize="lg"
												subtitle="(Apenas números)"
												numero="cpf"
												formik={formik}
											/>
										</Col>

										<Col span={7}>
											<Field
												as={InputMask}
												title="Número do contrato"
												name="numContrato"
												mask="9999999"
											/>
										</Col>
									</Row>

									<Row gutter={[0, 20]}>
										<Col span={24} />
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage
												src="limpar"
												onClick={() => {
													formik.handleReset();
													dispatch(clearNotifications());
												}}
											/>
										</Col>

										<Col offset={1}>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>

			<ButtonVoltar />
		</>
	);
};

export default PesquisarMutuario;
