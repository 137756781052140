import React, { useState, useEffect, useCallback } from 'react';

import { Form, Formik, Field, FormikProps } from 'formik';
import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import {
	ROUTE_IIRGD_CADASTRO,
	ROUTE_IIRGD_EDICAO,
	ROUTE_IIRGD_CONFERENCIA_DADOS,
} from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { alterarObservacoesValuesTemp } from 'store/modules/api/iirgd/alterarObservacoes/actions';
import {
	cadastroRgConfigRedicao,
	cadastroRgObsPrimeiraViaMaior,
} from 'store/modules/api/iirgd/cadastroRg/actions';
import { consultaPesquisaObservacaoClear } from 'store/modules/api/iirgd/consultaPesquisaObservacao/actions';
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import FormBox from 'components/Common/Form/FormBox';
import CheckBox from 'components/Common/Form/Checkbox';
import Select from 'components/Common/Form/Select';
import InputMask from 'components/Common/Form/Input/InputMask';
import Input from 'components/Common/Form/Input/Input';
import Radio from 'components/Common/Form/Radio';
import TextArea from 'components/Common/Form/Input/TextArea';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import hasError from 'utils/getFormErrors';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import {
	formatDate,
	onlyAlfaNumerico,
	treatObjNullValues,
} from 'utils/genericFunctions';
import { RadioChangeEvent } from 'antd/lib/radio';
import { RequestAlterarObservacoes } from 'store/modules/api/iirgd/alterarObservacoes/types';
import RG from 'components/Common/Form/Fields/RG';
import { SCRIPT_POSSUI_RG_EM_OUTRO_ESTADO } from 'store/modules/api/iirgd/scriptPreAtendimento/types';
import { ConsultarProcesso } from 'store/modules/api/iirgd/integrador/consultarProcesso/types';
import { initialValues, schema } from './form';

import {
	getObsValueRequest,
	IFormInclusaoObsPrimeiraViaMaior,
	threatDataCadastroRgObsPrimeiraViaMaior,
} from '../../form';

import { setFormValuesByKeys } from '../../../../utils/rgFicDigitalFunctions';

// COMPONENTS
import { Header } from '../../../Header';

export const FormPrimeiraViaMaior: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const [formData, setFormData] =
		useState<IFormInclusaoObsPrimeiraViaMaior | null>(null);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { scriptPreAtendimento } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const { consultarProcesso } = useSelector(
		(state: ApplicationState) => state.api.iirgd.integrador,
	);

	const {
		alterarObservacoes,
		cadastroRg,
		consultaPesquisaObservacao,
		consultaPesquisaCirg,
		consultaPesquisaCriminal,
	} = useSelector((state: ApplicationState) => state.api.iirgd);

	const situacaoCin = (codigoSituacao: ConsultarProcesso[]) => {
		let situacao = false;
		codigoSituacao.forEach(item => {
			if (item.codigoSituacao === 3) {
				situacao = true;
			}
		});

		return situacao;
	};

	useEffect(() => {
		setFormData(oldForm => {
			if (oldForm) {
				return {
					...oldForm,
					isencaoPrimeiraViaCin:
						cadastroRg.form.principal?.dni === '10977' &&
						!situacaoCin(consultarProcesso.data || []),
				};
			}
			return null;
		});
	}, [cadastroRg.form.principal, consultarProcesso.data]);

	useEffect(() => {
		// Quando o cidadão já possui Dados CITA.
		if (
			consultaPesquisaObservacao.result &&
			consultaPesquisaObservacao.result.observacaoCadastrada &&
			alterarObservacoes?.temp === null
		) {
			setFormData(
				setFormValuesByKeys({
					...consultaPesquisaObservacao.result,
					resideSpMeses: consultaPesquisaObservacao.result.resideSpMeses,
					rgForaSpEmissao: consultaPesquisaObservacao.result.rgForaSpEmissao
						? formatDate(consultaPesquisaObservacao.result.rgForaSpEmissao)
						: '',
					cr: consultaPesquisaCriminal.restricaoCriminal,
				}) as IFormInclusaoObsPrimeiraViaMaior,
			);
		} else if (alterarObservacoes?.temp !== null) {
			setFormData(
				setFormValuesByKeys({
					...alterarObservacoes?.temp,
					rgForaSpEmissao: alterarObservacoes?.temp.rgForaSpEmissao
						? formatDate(alterarObservacoes?.temp.rgForaSpEmissao)
						: '',
					cr: consultaPesquisaCriminal.restricaoCriminal,
				}) as IFormInclusaoObsPrimeiraViaMaior,
			);
		}

		// Quando já existe um formulário em andamento.
		else if (cadastroRg.form.observacoes.primeiraVia.maior) {
			const formPrimeiraVia = cadastroRg.form.observacoes.primeiraVia.maior;

			setFormData({
				...(setFormValuesByKeys(
					formPrimeiraVia,
				) as IFormInclusaoObsPrimeiraViaMaior),
				cr: consultaPesquisaCriminal.restricaoCriminal,
			});
		} else if (scriptPreAtendimento.preAtendimentoValido) {
			setFormData({
				...initialValues,
				rgForaSp:
					scriptPreAtendimento.ondeMorava === SCRIPT_POSSUI_RG_EM_OUTRO_ESTADO,
				rgForaSpEmissao: scriptPreAtendimento.dataExpedicaoRg
					? scriptPreAtendimento.dataExpedicaoRg
					: '',
				rgForaSpNum: scriptPreAtendimento.numeroRg
					? scriptPreAtendimento.numeroRg
					: '',
				rgForaSpUf: scriptPreAtendimento.ufRg ? scriptPreAtendimento.ufRg : '',
				cr: consultaPesquisaCriminal.restricaoCriminal,
			});
		} else {
			setFormData({
				...initialValues,
				cr: consultaPesquisaCriminal.restricaoCriminal,
			});
		}
	}, [
		consultaPesquisaObservacao,
		cadastroRg,
		alterarObservacoes,
		consultaPesquisaCriminal.restricaoCriminal,
		scriptPreAtendimento,
	]);

	const handleValidate = useCallback(values => {
		return getValidationsErrors(treatObjNullValues(values), schema);
	}, []);

	const handleSubmit = useCallback(
		formValues => {
			dispatch(clearNotifications());

			if (cadastroRg.form.principal) {
				const values: RequestAlterarObservacoes = getObsValueRequest(
					formValues,
					cadastroRg,
					login,
					consultaPesquisaCirg.data?.lote
						? consultaPesquisaCirg.data?.lote
						: '',
					cadastroRg.form.principal?.dni === '10977' &&
						!situacaoCin(consultarProcesso.data || []),
					cadastroRg.form.principal?.dni === '10977' &&
						situacaoCin(consultarProcesso.data || []),
				);

				const dataCadastroRgObsPrimeiraViaMaior =
					threatDataCadastroRgObsPrimeiraViaMaior({
						...formValues,
						isencaoPrimeiraViaCin:
							cadastroRg.form.principal?.dni === '10977' &&
							!situacaoCin(consultarProcesso.data || []),
					});

				dispatch(alterarObservacoesValuesTemp(values));
				dispatch(
					cadastroRgObsPrimeiraViaMaior(dataCadastroRgObsPrimeiraViaMaior),
				);

				history.push(ROUTE_IIRGD_CONFERENCIA_DADOS);
			}
		},
		[
			dispatch,
			cadastroRg,
			login,
			consultaPesquisaCirg.data,
			consultarProcesso.data,
			history,
		],
	);

	const handleButtonVoltar = useCallback(() => {
		dispatch(clearNotifications());
		dispatch(consultaPesquisaObservacaoClear());
		dispatch(cadastroRgConfigRedicao());

		if (cadastroRg.config.novo) {
			history.push(ROUTE_IIRGD_CADASTRO);
			return;
		}

		history.push(ROUTE_IIRGD_EDICAO);
	}, [dispatch, cadastroRg.config, history]);

	const handleParentesSp = useCallback(
		(
			e: RadioChangeEvent,
			formik: FormikProps<IFormInclusaoObsPrimeiraViaMaior>,
		) => {
			const { value } = e.target;
			const { setFieldValue } = formik;
			setFieldValue('temParentesSp', value);
			if (!value) {
				setFieldValue('grauParentescoSp', '');
			}
		},
		[],
	);

	return (
		<>
			{formData && (
				<Formik
					validateOnChange={false}
					validateOnBlur={notifications.errors.length > 0}
					initialValues={formData}
					validate={handleValidate}
					onSubmit={handleSubmit}
					enableReinitialize
				>
					{formik => (
						<Form autoComplete="off">
							<Section title="Inclusão de Observações">
								<Header />

								<FormBox title="Outros Documentos">
									<Row gutter={[0, 10]}>
										<Col>
											<div style={{ marginLeft: '18px' }}>
												<Field
													as={CheckBox}
													name="rgForaSp"
													subtitle="Não possui Carteira de Identidade no Estado de São Paulo"
													checked={formik.values.rgForaSp}
													error={!!formik.errors.rgForaSp}
												/>
											</div>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24} />
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={8}>
											<Field
												as={Select}
												title="Carteira de Identidade no Estado de"
												name="rgForaSpUf"
												id="rgForaSpUf"
												required={
													formik.values.rgForaSpEmissao ||
													formik.values.rgForaSpNum
												}
												options={data.uf}
												onChange={(uf: string) =>
													formik.setFieldValue('rgForaSpUf', uf)
												}
												error={hasError(formik.errors, 'rgUf')}
											/>
										</Col>

										{formik.values.rgForaSpUf === 'SP' ? (
											<Col span={7} offset={1}>
												<RG
													title="Número Carteira de Identidade"
													numero="rgForaSpNum"
													formik={formik}
													result={res => {
														formik.setFieldValue('rgForaSpNum', res.numeroRg);
													}}
													defaultNumero={formik.values.rgForaSpNum}
													required={
														!!formik.values.rgForaSpEmissao ||
														!!formik.values.rgForaSpUf
													}
													error={hasError(formik.errors, 'rgForaSpNum')}
												/>
											</Col>
										) : (
											<Col span={7} offset={1}>
												<Field
													as={Input}
													title="Número Carteira de Identidade"
													name="rgForaSpNum"
													maxLength="15"
													value={onlyAlfaNumerico(formik.values.rgForaSpNum)}
													defaultValue={formik.values.rgForaSpNum}
													required={
														formik.values.rgForaSpEmissao ||
														formik.values.rgForaSpUf
													}
													error={hasError(formik.errors, 'rgForaSpNum')}
												/>
											</Col>
										)}

										<Col offset={1} span={7}>
											<Field
												as={ValidDataInput}
												title="Expedido em"
												name="rgForaSpEmissao"
												type="text"
												mask="99/99/9999"
												formik={formik}
												onChange={(v: string) =>
													formik.setFieldValue('rgForaSpEmissao', v)
												}
												required={
													formik.values.rgForaSpNum || formik.values.rgForaSpUf
												}
												error={
													hasError(formik.errors, 'rgExp') ||
													hasError(formik.errors, 'validaDataRG')
												}
											/>
										</Col>
									</Row>
								</FormBox>

								<Row gutter={[0, 10]}>
									<Col span={24} />
								</Row>

								<FormBox title="Preenchimento complementar">
									<Row gutter={[0, 10]}>
										<Col span={9}>
											<Field
												as={InputMask}
												title="Quanto tempo mora em São Paulo"
												titleSize={250}
												titleQuestion
												maskChar=""
												subtitle="(anos)"
												name="resideSpAnos"
												mask="99"
												error={!!formik.errors.resideSpAnos}
											/>
										</Col>

										<Col span={6}>
											<div style={{ marginLeft: '15px' }}>
												<Field
													as={Select}
													subtitle="(meses)"
													name="resideSpMeses"
													id="resideSpMeses"
													defaultFirstOption
													options={data.qtdMesesSemZero}
													onChange={(v: number) =>
														formik.setFieldValue('resideSpMeses', v)
													}
													size={60}
													error={!!formik.errors.resideSpMeses}
												/>
											</div>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24} />
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={22}>
											<Field
												as={Input}
												title="Mora com quem"
												titleSize={250}
												titleQuestion
												maxLength="15"
												name="moraCom"
												required
												defaultValue={formik.values.moraCom}
												value={formik.values.moraCom}
												error={!!formik.errors.moraCom}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24} />
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={9}>
											<Field
												as={Radio}
												title="Tem parentes em São Paulo"
												titleSize={250}
												titleQuestion
												name="temParentesSp"
												options={data.simNaoBoolean}
												onChange={(e: RadioChangeEvent) =>
													handleParentesSp(e, formik)
												}
												defaultValue={formik.values.temParentesSp}
												value={formik.values.temParentesSp}
												error={!!formik.errors.temParentesSp}
											/>
										</Col>

										<Col span={12} offset={1}>
											<Field
												as={Input}
												title="Grau de parentesco"
												name="grauParentescoSp"
												maxLength={15}
												disabled={!formik.values.temParentesSp}
												required={formik.values.temParentesSp}
												error={!!formik.errors.grauParentescoSp}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24} />
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={22}>
											<Field
												as={Input}
												title="Qual ocupação possui em São Paulo"
												titleSize={250}
												titleQuestion
												name="ocupacaoPrincipalSp"
												maxLength={12}
												required
												error={!!formik.errors.ocupacaoPrincipalSp}
											/>
										</Col>
									</Row>

									<Row>
										<Col span={24}>
											<div style={{ marginLeft: '260px' }}>
												<Field
													as={CheckBox}
													name="cr"
													subtitle="CR"
													checked={formik.values.cr}
													disabled
													error={!!formik.errors.cr}
												/>
											</div>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24} />
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={22}>
											<Field
												as={TextArea}
												title="Observações"
												titleSize={250}
												value={
													formik.values.observacao
														? formik.values.observacao
														: ''
												}
												name="observacao"
												maxLength={338}
												error={!!formik.errors.observacao}
											/>
										</Col>
									</Row>
								</FormBox>

								{cadastroRg.form.principal?.dni === '10977' && (
									<FormBox title="Complementação do IIRGD">
										<Row gutter={[0, 10]}>
											<Col span={11}>
												<Field
													as={CheckBox}
													name="isencaoPrimeiraViaCin"
													subtitle="Isenção da Taxa da CIN"
													disabled
													checked={
														cadastroRg.form.principal?.dni === '10977' &&
														!situacaoCin(consultarProcesso.data || [])
													}
													onChange={(
														change: React.ChangeEvent<HTMLInputElement>,
													) => {
														const { checked } = change.target;

														formik.setFieldValue(
															'isencaoPrimeiraViaCin',
															checked,
														);
													}}
													error={!!formik.errors.isencaoPrimeiraViaCin}
												/>
											</Col>
										</Row>
										{/* <Row gutter={[0, 10]}>
										<Col span={11}>
											<Field
												as={CheckBox}
												name="temCin"
												subtitle="Tem CIN?"
												disabled
												checked={cadastroRg.form.principal?.dni === '10977'}
												onChange={(
													change: React.ChangeEvent<HTMLInputElement>,
												) => {
													const { checked } = change.target;

													formik.setFieldValue('temCin', checked);
												}}
												error={!!formik.errors.temCin}
											/>
										</Col>
									</Row> */}
									</FormBox>
								)}

								<Row gutter={[0, 30]}>
									<Col span={24} />
								</Row>

								<Row gutter={[0, 10]} justify="center">
									<Col>
										<ButtonImage type="submit" src="avancar" />
									</Col>
								</Row>
							</Section>

							<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};
