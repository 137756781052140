import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarPrestacoesAtrasadas } from './types';

export const INITIAL_STATE: ConsultarPrestacoesAtrasadas = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarPrestacoesAtrasadas {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_PRESTACOES_ATRASADAS_REQUEST: {
				draft.status = 100;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CONSULTAR_PRESTACOES_ATRASADAS_SUCCESS: {
				draft.status = action.payload?.status ?? 200;
				draft.data = action.payload;
				break;
			}
			case Types.CONSULTAR_PRESTACOES_ATRASADAS_FAILURE: {
				draft.status = action.payload?.data?.status ?? 400;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CONSULTAR_PRESTACOES_ATRASADAS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}

		return draft;
	});
}
