// TYPES
import { PesquisaNominal } from '../pesquisaNominal/types';

export enum Types {
	EMISSAO_NOMINAL_REQUEST = '@aac/EMISSAO_NOMINAL_REQUEST',
	EMISSAO_NOMINAL_SUCCESS = '@aac/EMISSAO_NOMINAL_SUCCESS',
	EMISSAO_NOMINAL_FAILURE = '@aac/EMISSAO_NOMINAL_FAILURE',
	LIMPA_EMISSAO_NOMINAL = '@aac/LIMPA_EMISSAO_NOMINAL',
}

export interface emissaoNominal {
	status: number;
	data: DataEmissaoAtestado;
	file: File;
}

export interface DataEmissaoAtestado {
	atestadoEmitido: boolean;
	codigoRetorno: string;
	id: string;
	mensagemRetorno: string;
}

interface File {
	mime: string;
	data: string;
}

export interface IRequestEmissaoNominal extends PesquisaNominal {
	codigoRetorno: string;
}
