import produce from 'immer';

import { Types, ConsultaPesquisaNomeSocialFiliacao } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: ConsultaPesquisaNomeSocialFiliacao = {
	status: 0,
	data: null,
};

export default function consultaNomeSocialFiliacaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultaPesquisaNomeSocialFiliacao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_REQUEST: {
				break;
			}
			case Types.CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_FAILURE: {
				draft.status = action.payload.data.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTA_PESQUISA_NOME_SOCIAL_FILIACAO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
	});
}
