import React, { useCallback, useEffect, useState } from 'react';
import {
	ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_AGENDAMENTO_MEDICO,
	ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_AGENDAMENTO_PSICOLOGICO,
	ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_RENACH,
	ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_PESQUISA,
} from 'pages/DetranCnh/routes/paths';

import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import StatusCondutor from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor';

import { AlertaCnhVencida } from 'pages/DetranCnh/components/pages/AlertaCnhVencida';
import LeituraDigitais from 'pages/DetranCnh/components/pages/LeituraDigitais';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { DadosCidadaoRequest } from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/types';
import {
	cadastroEnderecosClear,
	cadastroEnderecosRequest,
} from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/actions';
import { CadastroEnderecosRequest } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/types';
import {
	dadosCidadaoClear,
	dadosCidadaoRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/actions';
import {
	consultarInaptoClear,
	consultarInaptoRequest,
} from 'store/modules/api/detranCnh/sockets/consultarInapto/actions';
import {
	domainServiceNaturalidadesClear,
	domainServiceNaturalidadesRequest,
} from 'store/modules/api/sguService/domainService/naturalidade/actions';
import {
	consultaNomeSocialFiliacaoClear,
	consultaNomeSocialFiliacaoRequest,
} from 'store/modules/api/detranCnh/condutor/consultaNomeSocialFiliacao/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCnh/consulta/categoria/actions';
import {
	consultaPesquisaNomeSocialFiliacaoClear,
	consultaPesquisaNomeSocialFiliacaoRequest,
} from 'store/modules/api/detranCnh/IIRGDDetran/consultaPesquisaNomeSocialFiliacao/actions';
import {
	consultarDigitaisClear,
	consultarDigitaisRequest,
} from 'store/modules/api/utils/consultarDigitais/actions';
import {
	consultaSegundaViaReabilitacaoClear,
	consultaSegundaViaReabilitacaoRequest,
} from 'store/modules/api/detranCnh/reabilitacao/consultaSegundaViaReabilitacao/actions';
import { consultaCidadesUFRequest } from 'store/modules/api/utils/consultaCidadesUF/actions';
import { GerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/types';
import {
	gerarRenachIntegradoClear,
	gerarRenachIntegradoRequest,
} from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/actions';
import { saveGerarRenachRequest } from 'store/modules/api/detranCnh/sockets/saveGerarRenach/actions';
import { capturarBiometriaClear } from 'store/modules/api/biometria/capturarBiometria/actions';
import {
	estatisticaIncluirClear,
	estatisticaIncluirRequest,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import { dadosPreCadastroFormSaveRequest } from 'store/modules/api/detranCnh/utils/dadosPreCadastroFormSave/actions';
import { addNotifications } from 'store/modules/app/notifications/actions';
import { confrontarDigitaisCnhClear } from 'store/modules/api/utils/confrontarDigitaisCnh/actions';
import { verificarVersaoServicoBiometriaClear } from 'store/modules/api/biometria/verificarVersaoServicoBiometria/actions';

// UTILS
import { limparMascara, unformatDate } from 'utils/genericFunctions';
import { hasUpdateInAttendencyModal } from 'pages/DetranCnh/utils/validations';
import {
	threatConsultarDigitaisRequest,
	treatValuesCadastroEnderecoRequest,
} from 'pages/DetranCnh/utils/threatServicesRequest';
import { treatRequestSguStatisticsGerarRenachIntegrado } from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';
import { CNH_PROCESS_VALIDATION } from 'pages/DetranCnh/utils/constants/messages';
import { getOriginOfSocialName } from 'pages/DetranCnh/utils/functions/socialName';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

// FORM
import {
	IConfirmacaoAtendimento,
	initialValues,
} from 'pages/DetranCnh/components/pages/ConfirmacaoAtendimento/form';
import {
	initalValuesHabilitacao,
	initialValuesPreCadastro,
	schema,
	treatInitialValues,
	treatValues,
	treatValuesGerarRenachIntegrado,
} from './form';

const StatusCondutorAdicaoMudancaCategoria: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const loginDataMainframe = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.mainframe,
	);

	const { consultaMudancaCategoria } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.eportal,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { user, mainframe } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { consultarDigitais } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { consultarInapto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const { cadastroEnderecos, consultaNomeSocialFiliacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { consultaPesquisaNomeSocialFiliacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.IIRGDDetran,
	);

	const { dadosCidadao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.divisaoEquitativa,
	);

	const { consultaNaturalidades } = useSelector(
		(state: ApplicationState) => state.api.sguService.domainService,
	);

	const { consultaSegundaViaReabilitacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.reabilitacao,
	);

	const { gerarRenachIntegrado } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { dadosPreCadastroFormSave } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.utils,
	);

	const [modalAtendimento, setModalAtendimento] =
		useState<IConfirmacaoAtendimento>(initialValues);

	const [dadosPreCadastro, setDadosPreCadastro] = useState(
		initialValuesPreCadastro,
	);

	const [dadosHabilitacao, setDadosHabilitacao] = useState(
		initalValuesHabilitacao,
	);

	// CONTABILIZAÇÃO
	const { sgu } = useSelector((state: ApplicationState) => state.api);

	const [requestData, setRequestData] = useState<null | {
		endereco: CadastroEnderecosRequest;
		cidadao: DadosCidadaoRequest;
	}>(null);

	const [preCadastro, setPreCadastro] =
		useState<null | GerarRenachIntegradoRequest>(null);

	const [diasFaltantesVencimentoCnh, setDiasFaltanteVencimentoCnh] =
		useState<number>(0);

	const [nextPage, setNextPage] = useState(false);

	const [biometria, setBiometria] = useState<
		'SHOW' | 'SUCCESS' | 'ERROR' | null
	>(null);

	const verifyCnhExpirationDate = (cnhExpirationDate: string) => {
		const currentDate = new Date();
		const cnhDate = new Date(unformatDate(`${cnhExpirationDate}`));
		const diffTime = cnhDate.getTime() - currentDate.getTime();
		const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

		setDiasFaltanteVencimentoCnh(diffDays);
	};

	const handleSubmit = useCallback(
		(values: GerarRenachForm) => {
			if (!values.codCiretranCondutor || !values.codCiretranHabilitacao) {
				addNotifications({
					errors: CNH_PROCESS_VALIDATION.CIRETRAN_INVALIDA,
				});
				return;
			}

			const sguData: SguData = {
				salvarAtendimento: sgu.atendimento.salvarAtendimento,
				login: sgu.loginUnico.login,
			};
			const evento = getEventoConsultar(sguData, values.cpf);

			const renach = treatValues(evento, values, user);
			const endereco = treatValuesCadastroEnderecoRequest(values);

			const cidadao: DadosCidadaoRequest = {
				cidadao: {
					autoriza_envio_email: values.autorizaSMS === 'S',
					autoriza_envio_sms: values.autorizaEmail === 'S',
					cpf: Number(values.cpf),
					celular: limparMascara(values.celular).substring(2, 11),
					ddd_celular: limparMascara(values.celular).substring(0, 2),
					ddd_telefone: limparMascara(values.telefone).substring(0, 2),
					telefone: limparMascara(values.telefone).substring(2, 11),
					email: values.email || '',
					necessidade_veiculo_adaptado: false,
					nome: values.nomeSocial?.toUpperCase() || values.nome.toUpperCase(),
					pretende_atividade_remunerada: values.flagAtividadeRemunerada === 'S',
				},
			};

			setModalAtendimento({
				...modalAtendimento,
				isOpenModal: true,
			});

			setDadosPreCadastro(values);
			setPreCadastro(renach);
			setRequestData({ endereco, cidadao });

			dispatch(saveGerarRenachRequest(renach));
			dispatch(gerarRenachIntegradoClear());
		},
		[sgu.atendimento, sgu.loginUnico, user, modalAtendimento, dispatch],
	);

	const clearInitialStatesWithoutNewCallServices = useCallback(() => {
		dispatch(cadastroEnderecosClear());
		dispatch(capturarBiometriaClear());
		dispatch(verificarVersaoServicoBiometriaClear());
		dispatch(consultarDigitaisClear());
		dispatch(confrontarDigitaisCnhClear());
		dispatch(estatisticaIncluirClear());
		dispatch(consultarInaptoClear());
		dispatch(dadosCidadaoClear());
	}, [dispatch]);

	// LEITURA DIGITAIS
	useEffect(() => {
		if (
			consultarDigitais?.data &&
			consultarDigitais?.status === 200 &&
			consultarDigitais?.data?.valida &&
			consultarDigitais?.data?.dadosBiometricos &&
			consultarDigitais?.data?.dadosBiometricos.length > 0
		) {
			setBiometria('SHOW');
		} else if (
			consultarDigitais?.status !== 0 &&
			consultarDigitais?.status !== 100 &&
			requestData !== null
		) {
			dispatch(cadastroEnderecosRequest(requestData.endereco));
		}
	}, [consultarDigitais, dispatch, requestData, setBiometria]);

	useEffect(() => {
		if (biometria === 'SUCCESS' && requestData !== null) {
			dispatch(cadastroEnderecosRequest(requestData.endereco));
		} else if (biometria === 'ERROR' && requestData !== null) {
			dispatch(cadastroEnderecosRequest(requestData.endereco));
		}
	}, [biometria, dispatch, requestData]);

	// REGISTRA ESTATISTICA SGU APÓS GERAR O RENACH - NUMERO RENACH OU MSG ERRO.
	useEffect(() => {
		if (
			preCadastro &&
			gerarRenachIntegrado.status !== 0 &&
			gerarRenachIntegrado.status !== 100 &&
			(gerarRenachIntegrado.data || gerarRenachIntegrado.dataError)
		) {
			const payload = treatRequestSguStatisticsGerarRenachIntegrado(
				user,
				dadosAtendimento,
				preCadastro,
				'GENERATED_RENACH',
				gerarRenachIntegrado,
			);
			dispatch(estatisticaIncluirRequest(payload));
		}
	}, [dadosAtendimento, dispatch, gerarRenachIntegrado, preCadastro, user]);

	// REDIRECIONAMENTO
	useEffect(() => {
		if (
			(gerarRenachIntegrado.status === 200 ||
				gerarRenachIntegrado.status === 201) &&
			gerarRenachIntegrado.data?.renach
		) {
			dispatch(estatisticaIncluirClear());
			history.push(ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_RENACH);
		} else if (
			gerarRenachIntegrado.status !== 0 &&
			gerarRenachIntegrado.status !== 100 &&
			gerarRenachIntegrado.status !== 200 &&
			gerarRenachIntegrado.status !== 201
		) {
			clearInitialStatesWithoutNewCallServices();
		}
	}, [
		gerarRenachIntegrado.status,
		gerarRenachIntegrado.data,
		history,
		dispatch,
		clearInitialStatesWithoutNewCallServices,
	]);

	useEffect(() => {
		if (
			cadastroEnderecos.status === 200 &&
			preCadastro !== null &&
			requestData !== null &&
			requestData.cidadao
		) {
			dispatch(dadosCidadaoRequest(requestData.cidadao));
			setBiometria(null);
		}
	}, [cadastroEnderecos.status, dispatch, preCadastro, requestData]);

	useEffect(() => {
		if (
			dadosCidadao.status === 200 &&
			preCadastro !== null &&
			requestData !== null
		) {
			let tipoExame = 'AMBOS';
			if (
				preCadastro.gerarRenachBase?.flagAtividadeRemunerada &&
				!preCadastro.gerarRenachBase?.flagExamePosto
			) {
				tipoExame = 'AMBOS';
			} else if (
				!preCadastro.gerarRenachBase?.flagAtividadeRemunerada &&
				!preCadastro.gerarRenachBase?.flagExamePosto
			) {
				tipoExame = 'MEDICO';
			} else if (
				preCadastro.gerarRenachBase?.flagAtividadeRemunerada &&
				preCadastro.gerarRenachBase?.flagExamePosto
			) {
				tipoExame = 'PSICOLOGO';
			}
			const inapto = {
				cpf: requestData.cidadao.cidadao.cpf.toString(),
				tipoExame,
				flagDeficienteFisico:
					requestData.cidadao.cidadao.necessidade_veiculo_adaptado,
			};
			setTimeout(() => {
				dispatch(consultarInaptoRequest(inapto));
			}, 300);
		}
	}, [dadosCidadao.status, dispatch, preCadastro, requestData]);

	useEffect(() => {
		if (
			consultarInapto.status !== 0 &&
			consultarInapto.status !== 100 &&
			preCadastro?.gerarRenachBase
		) {
			if (
				(!preCadastro.gerarRenachBase.flagAtividadeRemunerada ||
					preCadastro.gerarRenachBase.flagAtividadeRemunerada === 'N') &&
				(preCadastro.gerarRenachBase.flagExamePosto ||
					preCadastro.gerarRenachBase.flagExamePosto === 'S')
			) {
				if (
					cadastroEnderecos.status === 200 &&
					preCadastro !== null &&
					preCadastro.candidato.cpf &&
					gerarRenachIntegrado.status === 0
				) {
					const payload = treatRequestSguStatisticsGerarRenachIntegrado(
						user,
						dadosAtendimento,
						preCadastro,
						'SELECTED_FIELDS',
					);
					dispatch(estatisticaIncluirRequest(payload));

					const renachIntegradoFormated = treatValuesGerarRenachIntegrado(
						preCadastro,
						user.cpf,
					);

					dispatch(gerarRenachIntegradoRequest(renachIntegradoFormated));
				}
			} else if (
				preCadastro.gerarRenachBase.flagAtividadeRemunerada &&
				!preCadastro.gerarRenachBase.flagExamePosto
			) {
				dispatch(estatisticaIncluirClear());
				history.push(
					ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_AGENDAMENTO_MEDICO,
				);
			} else if (
				!preCadastro.gerarRenachBase.flagAtividadeRemunerada &&
				!preCadastro.gerarRenachBase.flagExamePosto
			) {
				dispatch(estatisticaIncluirClear());
				history.push(
					ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_AGENDAMENTO_MEDICO,
				);
			} else if (
				preCadastro.gerarRenachBase.flagAtividadeRemunerada &&
				preCadastro.gerarRenachBase.flagExamePosto
			) {
				dispatch(estatisticaIncluirClear());
				history.push(
					ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_AGENDAMENTO_PSICOLOGICO,
				);
			}
		}
	}, [
		consultarInapto.status,
		dispatch,
		history,
		preCadastro,
		cadastroEnderecos.status,
		user,
		dadosAtendimento,
		gerarRenachIntegrado.status,
		user.cpf,
	]);

	const handleSubmitGerarPreCadastro = useCallback(() => {
		if (requestData !== null) {
			if (dadosPreCadastro) {
				// SALVA OS DADOS PREENCHIDOS NO FORM E MODAL CONFIRMACAO DE ATENDIMENTO.
				dispatch(
					dadosPreCadastroFormSaveRequest({
						dataPreCadastro: {
							...dadosPreCadastro,
							dadosModalAtendimento: modalAtendimento,
						},
						dataPreCadastroHabilitacao: dadosHabilitacao,
					}),
				);
			}

			if (dadosPreCadastro && !hasUpdateInAttendencyModal(modalAtendimento)) {
				const requestValues = threatConsultarDigitaisRequest(
					dadosAtendimento,
					dadosPreCadastro,
					user,
					'REUSO_CNH',
				);

				dispatch(consultarDigitaisRequest(requestValues));
			} else {
				dispatch(cadastroEnderecosRequest(requestData.endereco));
			}
		}
	}, [
		requestData,
		dadosPreCadastro,
		modalAtendimento,
		dispatch,
		dadosHabilitacao,
		dadosAtendimento,
		user,
	]);

	useEffect(() => {
		if (
			consultaMudancaCategoria.data &&
			consultaMudancaCategoria.data.cpf &&
			consultaSegundaViaReabilitacao.status === 0
		) {
			dispatch(
				consultaSegundaViaReabilitacaoRequest({
					cpfCidadao: consultaMudancaCategoria.data.cpf,
				}),
			);
		}
	}, [
		dispatch,
		consultaMudancaCategoria.data,
		consultaSegundaViaReabilitacao.status,
	]);

	useEffect(() => {
		if (
			consultaMudancaCategoria.data &&
			consultaSegundaViaReabilitacao.status === 200 &&
			consultaSegundaViaReabilitacao.data &&
			dadosPreCadastro.cpf === '' &&
			user
		) {
			const newFormValues = treatInitialValues(
				consultaMudancaCategoria.data,
				consultaSegundaViaReabilitacao.data.dataPrimeiraHabilitacao || null,
				null,
				user,
				'',
			);

			setDadosPreCadastro(newFormValues.initialValuesPreCadastro);
			setDadosHabilitacao(newFormValues.initalValuesHabilitacao);
		}
	}, [
		consultaMudancaCategoria.data,
		consultaSegundaViaReabilitacao.data,
		consultaSegundaViaReabilitacao.status,
		dadosPreCadastro.cpf,
		user,
	]);

	// CONSULTA DOMAIN SERVICES PARA VALIDAR UF DA NATURALIDADE PELO CÓDIGO IBGE
	useEffect(() => {
		if (
			consultaSegundaViaReabilitacao.status === 200 &&
			consultaSegundaViaReabilitacao.data &&
			consultaSegundaViaReabilitacao.data.naturalidade &&
			consultaSegundaViaReabilitacao.data.nacionalidade &&
			!dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf
		) {
			if (
				consultaNaturalidades.status === 0 &&
				consultaSegundaViaReabilitacao.data.nacionalidade.toString() === '1'
			) {
				dispatch(
					domainServiceNaturalidadesRequest({
						idIbge: consultaSegundaViaReabilitacao.data.naturalidade.trim(),
					}),
				);
			} else if (
				dadosPreCadastro.naturalidade === '' &&
				consultaSegundaViaReabilitacao.data.nacionalidade.toString() !== '1'
			) {
				setDadosHabilitacao(oldFormValues => ({
					...oldFormValues,
					naturalidade: consultaSegundaViaReabilitacao.data?.naturalidade || '',
				}));
				setDadosPreCadastro(oldFormValues => ({
					...oldFormValues,
					naturalidade: consultaSegundaViaReabilitacao.data?.naturalidade || '',
					codigoNaturalidade:
						consultaSegundaViaReabilitacao.data?.naturalidade || '',
				}));
			}
		}
	}, [
		consultaNaturalidades.status,
		consultaSegundaViaReabilitacao.data,
		consultaSegundaViaReabilitacao.status,
		dadosPreCadastro.naturalidade,
		dadosPreCadastroFormSave.dataPreCadastroHabilitacao,
		dispatch,
	]);

	useEffect(() => {
		if (
			consultaNaturalidades.status === 200 &&
			consultaNaturalidades.data &&
			consultaNaturalidades.data[0].municipioIBGE?.uf &&
			(!dadosPreCadastroFormSave.dataPreCadastro?.cpf ||
				!dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf)
		) {
			setDadosHabilitacao(oldFormValues => ({
				...oldFormValues,
				naturalidade:
					(consultaNaturalidades.data &&
						consultaNaturalidades.data[0]?.municipioIBGE?.descricao) ||
					'',
				uf:
					(consultaNaturalidades.data &&
						consultaNaturalidades.data[0]?.municipioIBGE?.uf) ||
					'',
			}));
			setDadosPreCadastro(oldFormValues => ({
				...oldFormValues,
				naturalidade:
					(consultaNaturalidades.data &&
						consultaNaturalidades.data[0]?.municipioIBGE?.descricao) ||
					'',
				codigoNaturalidade:
					(consultaNaturalidades.data &&
						Number(consultaNaturalidades.data[0]?.municipioIBGE?.id)) ||
					'',
				uf:
					(consultaNaturalidades.data &&
						consultaNaturalidades.data[0]?.municipioIBGE?.uf) ||
					'',
			}));

			dispatch(
				consultaCidadesUFRequest(
					consultaNaturalidades.data[0].municipioIBGE.uf,
				),
			);
		}
	}, [
		consultaNaturalidades.data,
		consultaNaturalidades.status,
		dispatch,
		dadosPreCadastroFormSave,
	]);

	// GET CODIGO CIRETRAN
	const consultaCodCiretran = useCallback(async () => {
		const resDetranEndereco: ApiResponse = await getApi(
			PATH_DETRAN_CNH,
			`consulta/${CHANNEL}?cep=${consultaMudancaCategoria.data?.endereco.cep}`,
		);

		if (resDetranEndereco.status === 200) {
			const endereco = resDetranEndereco.data;

			setDadosPreCadastro(oldFormValues => ({
				...oldFormValues,
				codCiretranHabilitacao:
					endereco?.codigoUnidadeTransito?.toString() || '',
				codCiretranCondutor: endereco?.codigoUnidadeTransito?.toString() || '',
			}));
		}
	}, [consultaMudancaCategoria.data]);

	useEffect(() => {
		if (
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf &&
			consultaMudancaCategoria.data &&
			consultaMudancaCategoria.data.endereco?.cep &&
			dadosPreCadastro.cpf &&
			dadosPreCadastro.cpf !== ''
		) {
			consultaCodCiretran();
		}
	}, [
		consultaCodCiretran,
		consultaMudancaCategoria.data,
		dadosPreCadastro.cpf,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (dadosHabilitacao?.dataValidadeCnh) {
			verifyCnhExpirationDate(dadosHabilitacao.dataValidadeCnh);
		}
	}, [dadosHabilitacao]);

	// CONSULTA NOME SOCIAL CONDUTOR BASE PNSF
	useEffect(() => {
		if (
			consultaMudancaCategoria.data &&
			consultaMudancaCategoria.data.cpf &&
			loginDataMainframe &&
			dadosPreCadastro.cpf &&
			dadosPreCadastro.cpf !== '' &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			dispatch(
				consultaNomeSocialFiliacaoRequest({
					cpf: consultaMudancaCategoria.data?.cpf,
					usuario: loginDataMainframe.idUsuario,
					senha: loginDataMainframe.senhaMainFrame,
				}),
			);
		}
	}, [
		dispatch,
		consultaMudancaCategoria,
		loginDataMainframe,
		user,
		dadosPreCadastro.cpf,
		dadosPreCadastroFormSave,
	]);

	// CONSULTA NOME SOCIAL CONDUTOR BASE IIRGD
	useEffect(() => {
		if (
			consultaMudancaCategoria.data &&
			consultaMudancaCategoria.data.cpf &&
			consultaNomeSocialFiliacao.status === 200 &&
			consultaNomeSocialFiliacao.data &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			const { nomeSocial, filiacao1, filiacao2 } =
				consultaNomeSocialFiliacao.data;

			setDadosPreCadastro(oldFormValues => ({
				...oldFormValues,
				nomeSocial: nomeSocial?.trim() || '',
				paisAfetivos1: filiacao1?.trim() || '',
				paisAfetivos2: filiacao2?.trim() || '',
				origemNomeSocial: getOriginOfSocialName(
					consultaNomeSocialFiliacao,
					null,
				),
			}));
		}

		if (
			consultaMudancaCategoria.data?.cpf &&
			consultaPesquisaNomeSocialFiliacao.status === 0 &&
			((consultaNomeSocialFiliacao.status !== 0 &&
				consultaNomeSocialFiliacao.status !== 200) ||
				(consultaNomeSocialFiliacao.status === 200 &&
					!(
						consultaNomeSocialFiliacao.data &&
						consultaNomeSocialFiliacao.data.nomeSocial &&
						consultaNomeSocialFiliacao.data.nomeSocial.trim()
					)))
		) {
			dispatch(
				consultaPesquisaNomeSocialFiliacaoRequest({
					usuario: mainframe.idUsuario,
					terminal: user.ip,
					cpf: consultaMudancaCategoria.data.cpf,
				}),
			);
		}
	}, [
		dispatch,
		consultaMudancaCategoria,
		mainframe,
		user,
		consultaNomeSocialFiliacao.status,
		consultaNomeSocialFiliacao.data,
		consultaPesquisaNomeSocialFiliacao.status,
		consultaNomeSocialFiliacao,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			consultaPesquisaNomeSocialFiliacao.status === 200 &&
			consultaPesquisaNomeSocialFiliacao.data &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			setDadosPreCadastro(oldData => ({
				...oldData,
				nomeSocial:
					consultaPesquisaNomeSocialFiliacao.data &&
					consultaPesquisaNomeSocialFiliacao.data.nomeSocial
						? consultaPesquisaNomeSocialFiliacao.data.nomeSocial.trim()
						: '',
				origemNomeSocial: getOriginOfSocialName(
					null,
					consultaPesquisaNomeSocialFiliacao,
				),
			}));
		}
	}, [consultaPesquisaNomeSocialFiliacao, dadosPreCadastroFormSave]);

	// GET CATEGORIAS
	useEffect(() => {
		dispatch(consultaCategoriaRequest({ funcionalidade: 'mudanca' }));
		dispatch(cadastroEnderecosClear());
	}, [dispatch]);

	// RECARREGA OS DADOS DO FORM COM OS DADOS SALVOS NA STORE.
	useEffect(() => {
		if (dadosPreCadastroFormSave.dataPreCadastro?.cpf) {
			setDadosPreCadastro(dadosPreCadastroFormSave.dataPreCadastro);
		}
	}, [dispatch, dadosPreCadastroFormSave]);

	// RECARREGA OS DADOS DO FORM COM OS DADOS SALVOS NA STORE.
	useEffect(() => {
		if (dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf) {
			setDadosHabilitacao(dadosPreCadastroFormSave.dataPreCadastroHabilitacao);
		}
	}, [dispatch, dadosPreCadastroFormSave]);

	useEffect(() => {
		clearInitialStatesWithoutNewCallServices();

		if (!dadosPreCadastroFormSave.dataPreCadastro?.cpf) {
			dispatch(consultaNomeSocialFiliacaoClear());
			dispatch(consultaPesquisaNomeSocialFiliacaoClear());
			dispatch(consultaSegundaViaReabilitacaoClear());
			dispatch(domainServiceNaturalidadesClear());
			setBiometria(null);
		}
	}, [
		dispatch,
		dadosPreCadastroFormSave,
		clearInitialStatesWithoutNewCallServices,
	]);

	return (
		<>
			{diasFaltantesVencimentoCnh < 0 &&
				!nextPage &&
				!dadosPreCadastroFormSave.dataPreCadastro?.cpf && (
					<AlertaCnhVencida
						nomeCondutor={dadosHabilitacao.nome}
						servico="a Adição/Mudança de Categoria."
						onClickLink={() => setNextPage(true)}
					/>
				)}
			{(nextPage ||
				(!nextPage && dadosPreCadastroFormSave.dataPreCadastro?.cpf) ||
				diasFaltantesVencimentoCnh >= 0) &&
				biometria !== 'SHOW' && (
					<StatusCondutor
						schema={schema}
						dadosHabilitacao={dadosHabilitacao}
						dadosPreCadastro={dadosPreCadastro}
						preCadastro={preCadastro !== null}
						setPreCadastro={setPreCadastro}
						modalAtendimento={modalAtendimento.isOpenModal}
						setModalAtendimento={setModalAtendimento}
						handleSubmit={handleSubmit}
						handleSubmitGerarPreCadastro={handleSubmitGerarPreCadastro}
					/>
				)}
			{biometria === 'SHOW' && (
				<LeituraDigitais
					setBiometria={setBiometria}
					cpf={preCadastro?.candidato.cpf || ''}
				/>
			)}
			<Row justify="space-between" align="middle">
				<Col>
					<ButtonVoltar
						navigate={false}
						onClick={() => {
							dispatch(detranCnhClear());
							history.push(ROUTE_DETRAN_CNH_ADICAO_MUDANCA_CATEGORIA_PESQUISA);
						}}
					/>
				</Col>
				<Col>
					<ButtonImage
						type="button"
						src="outro-servico-detran"
						onClick={() => {
							dispatch(detranCnhClear());

							history.push('/detran-cnh');
						}}
					/>
				</Col>
			</Row>
		</>
	);
};
export default StatusCondutorAdicaoMudancaCategoria;
