import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ScriptPreAtendimento } from './types';

export const INITIAL_STATE: ScriptPreAtendimento = {
	teveRg: null,
	ondeMorava: null,
	possuiDocumento: null,
	ufRg: null,
	numeroRg: '',
	dataExpedicaoRg: null,
	teveAlteracao: null,
	adocao: null,
	tranGenero: null,
	paternidadeReconhecida: null,
	outros: null,
	preAtendimentoValido: false,
	rgOuAcervo: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ScriptPreAtendimento {
	return produce(state, draft => {
		switch (action.type) {
			case Types.SCRIPT_PRE_ATENDIMENTO_REQUEST: {
				draft.teveRg = action.payload.teveRg;
				draft.ondeMorava = action.payload.ondeMorava;
				draft.possuiDocumento = action.payload.possuiDocumento;
				draft.ufRg = action.payload.ufRg;
				draft.numeroRg = action.payload.numeroRg;
				draft.dataExpedicaoRg = action.payload.dataExpedicaoRg;
				draft.teveAlteracao = action.payload.teveAlteracao;
				draft.adocao = action.payload.adocao;
				draft.tranGenero = action.payload.tranGenero;
				draft.paternidadeReconhecida = action.payload.paternidadeReconhecida;
				draft.outros = action.payload.outros;
				draft.preAtendimentoValido = action.payload.preAtendimentoValido;
				draft.rgOuAcervo = action.payload.rgOuAcervo;
				break;
			}
			case Types.SCRIPT_PRE_ATENDIMENTO_CLEAR: {
				draft.teveRg = INITIAL_STATE.teveRg;
				draft.ondeMorava = INITIAL_STATE.ondeMorava;
				draft.possuiDocumento = INITIAL_STATE.possuiDocumento;
				draft.ufRg = INITIAL_STATE.ufRg;
				draft.numeroRg = INITIAL_STATE.numeroRg;
				draft.dataExpedicaoRg = INITIAL_STATE.dataExpedicaoRg;
				draft.teveAlteracao = INITIAL_STATE.teveAlteracao;
				draft.adocao = INITIAL_STATE.adocao;
				draft.tranGenero = INITIAL_STATE.tranGenero;
				draft.paternidadeReconhecida = INITIAL_STATE.paternidadeReconhecida;
				draft.outros = INITIAL_STATE.outros;
				draft.preAtendimentoValido = INITIAL_STATE.preAtendimentoValido;
				draft.rgOuAcervo = INITIAL_STATE.rgOuAcervo;
				break;
			}

			default:
		}
		return draft;
	});
}
