import { ILogin } from 'store/modules/api/sgu/loginUnico/types';

// REDUX
import data from 'store/modules/api/iirgd/enum';

import {
	ICadastroRgState,
	ICadastroRgObservacoes,
} from 'store/modules/api/iirgd/cadastroRg/types';

// UTILS
import {
	calcularIdade,
	extrairNumeroDigito,
	formatDateISO,
	limparMascara,
	unformatDate,
} from 'utils/genericFunctions';
import { ConsultaPesquisaCirgState } from 'store/modules/api/iirgd/consultaPesquisaCirg/types';
import { IConsultaPesquisaCidadaoState } from 'store/modules/api/iirgd/consultaPesquisaCidadao/types';
import { IConfrontarDigitaisState } from 'store/modules/api/utils/confrontarDigitais/types';
import { CapturarBiometriaState } from 'store/modules/api/biometria/capturarBiometria/types';
import { DadosAgendamentoState } from 'store/modules/api/sgu/atendimento/dadosAgendamento/types';
import { ConsultarDigitaisState } from 'store/modules/api/utils/consultarDigitais/types';

export interface IProtocoloFic {
	liberacaoSimplificada: boolean;
	viaRg: string;
	reusoParcial: boolean;
	reusoTotal: boolean;
	tipoCili?: 'A' | 'B' | 'C' | string | null;
	posto: {
		codigo: string;
		digito: string;
		nome: string;
		usuario: string;
	};
	cidadao: {
		numRg: string;
		digRg: string;
		nome: string;
		nomePai: string;
		nomeMae: string;
		sexo: string;
		tipoDocumento: string;
		dataNascimento: string;
		naturalidade: {
			codigoFormatado: string | number;
			nome: string;
		};
		cpf: string;
		cpfDig: string;
		pis: string;
		pasepCompleto: string;
		comarca: string;
		cartorio: string;
		livro: string;
		folha: string;
		numero: string;
		numeroDare: string;
		dataDocumento: string;
		portMinLei1: string;
		portMinLei2: string;
		estadoCivil: string;
		cutis: string;
		cabelos: string;
		olhos: string;
		altura: string;
		grauInstrucao: string;
		profissao: string;
		endResidencia: string;
		numResidencia: string;
		complResidencia: string;
		bairroResidencia: string;
		municipioResidencia: {
			codigoFormatado: string | number;
			nome: string;
		};
		endTrabalho: string;
		numTrabalho: string;
		complTrabalho: string;
		bairroTrabalho: string;
		municipioTrabalho: {
			codigoFormatado: string | number;
			nome: string;
		};
		dataRetiradaDoc: string;
		hrRetiradaDoc: string;
		telefone: string;
		telefoneComercial: string;
		celular: string;
		email: string;
		sedex: string | null;
		tipoSanguineo: string;
		observacoesRG: Observacoes;
		nomeSocial: string;
		cid1: string;
		cid2: string;
		cid3: string;
		cid4: string;
		dni: string | null;
		tituloEleitor: string;
		ctps: string;
		ctpsSerie: string;
		ctpsSerieUf: string;
		certidaoMilitar: string;
		cnh: string;
		categoriaCnh: string;
		doador: boolean;
		cns: string;
		numeroIdentidadeProfissional1: string;
		orgaoEntidadeProfissional1: string;
		numeroIdentidadeProfissional2: string;
		orgaoEntidadeProfissional2: string;
		numeroIdentidadeProfissional3: string;
		orgaoEntidadeProfissional3: string;
		entrega: string | number;
		apresentouCertidao: boolean | null;
		alterouNome?: string | null;
		numProtAgendamento: string;
		senhaAgendamento: string;
	};
	multifiliacao: boolean;
	atendimentoCin: boolean;
}

export interface Observacoes {
	rgForaSp: boolean | string;
	rgForaSpUf: string;
	rgForaSpNum: string;
	rgForaSpDataEmissao: string;
	ctpsNum: string;
	ctpsSerie: string;
	ctpsSerieUf: string;
	observacoes: string;
	declaracaoPobreza: boolean | string;
	solicitaBloqueio: boolean | string;
	termoRecolhimentoRG: boolean | string;
	rgRecolhidoDataEmissao: string;
	rgRecolhidoSetorIdent: string;
	rgRecolhidoPor: string;
	grauParentescoSp: string;
	moraCom: string;
	ocupacaoPrincipalSp: string;
	resideSpAnos: string;
	resideSpMeses: string | number;
	temParentesSp: boolean | string;
	isento2Via_10952: boolean | string;
	isento2Via_062: boolean | string;
	termoTutela: boolean | string;
	tutelaProc: string;
	tutelaAno: string;
	tutelaData: string;
	pgtoTx2via: boolean | string | null;
	pgtoTxCorreio: boolean | string | null;
	restricaoCriminal: boolean;
	dtExpResponsavel?: string;
	numDocResponsavel?: string;
	tpDocResponsavel?: string;
	ufDocResponsavel?: string;
	envioPinpad?: boolean;
	isencaoPrimeiraViaCin?: boolean;
}

export function formatarObservacoesProtocoloFIC(
	observacoes: ICadastroRgObservacoes,
	envioPinpad: string,
): Observacoes {
	const tpDocResponsavel = (value: string) => {
		if (value === 'PASSAPOR') return 'PASSAPORTE';
		if (value === 'CFUNCION') return 'FUNCIONAL';
		if (value === 'T REFUG') return 'TERMO DE REFUGIADO';
		return value || '';
	};
	const verificaTutela = (value: string) => {
		if (!value) {
			return '';
		}
		if (value.indexOf('-') === -1) {
			return unformatDate(value);
		}
		return unformatDate(value) ?? '';
	};
	if (observacoes.primeiraVia.menor) {
		const { menor } = observacoes.primeiraVia;

		return {
			rgForaSp:
				menor.rgForaSp === false || menor.rgForaSp === '' ? 'false' : 'true',
			rgForaSpUf: menor.rgForaSpUf,
			rgForaSpNum: menor.rgForaSpNum ? menor.rgForaSpNum.toUpperCase() : '',
			rgForaSpDataEmissao: menor.rgForaSpEmissao
				? unformatDate(menor.rgForaSpEmissao)
				: '',
			ctpsNum: '',
			ctpsSerie: '',
			ctpsSerieUf: '',
			observacoes: menor.observacao ? menor.observacao.toUpperCase() : '',
			declaracaoPobreza: '',
			solicitaBloqueio: '',
			termoRecolhimentoRG: '',
			rgRecolhidoDataEmissao: '',
			rgRecolhidoSetorIdent: '',
			rgRecolhidoPor: '',
			isento2Via_10952: '',
			isento2Via_062: '',
			termoTutela: menor.termoTutela,
			tutelaProc: menor.tutelaProc,
			tutelaAno: menor.tutelaAno,
			tutelaData: verificaTutela(menor.tutelaData),
			pgtoTx2via: null,
			pgtoTxCorreio: false,
			restricaoCriminal: false,
			moraCom: '',
			ocupacaoPrincipalSp: '',
			resideSpAnos: '',
			resideSpMeses: '',
			temParentesSp: '',
			grauParentescoSp: '',
			tpDocResponsavel: menor?.tipoDocumentoResponsavel
				? tpDocResponsavel(menor?.tipoDocumentoResponsavel)
				: '',
			ufDocResponsavel: menor.ufDocumentoResponsavel,
			numDocResponsavel: menor.numeroDocumentoResponsavel
				? menor.numeroDocumentoResponsavel.toString().toLocaleUpperCase()
				: '',
			dtExpResponsavel: menor.dataExpedicaoDocumentoResponsavel
				? unformatDate(menor.dataExpedicaoDocumentoResponsavel)
				: '',
			envioPinpad: !!envioPinpad,
			isencaoPrimeiraViaCin: !!menor.isencaoPrimeiraViaCin,
		};
	}

	if (observacoes.primeiraVia.maior) {
		const { maior } = observacoes.primeiraVia;

		return {
			rgForaSp:
				maior.rgForaSp === '' || maior.rgForaSp === false ? 'false' : 'true',
			rgForaSpUf: maior.rgForaSpUf,
			rgForaSpNum: maior.rgForaSpNum,
			rgForaSpDataEmissao: unformatDate(maior.rgForaSpEmissao),
			ctpsNum: '',
			ctpsSerie: '',
			ctpsSerieUf: '',
			observacoes: maior.observacao ? maior.observacao.toUpperCase() : '',
			declaracaoPobreza: '',
			solicitaBloqueio: '',
			termoRecolhimentoRG: '',
			rgRecolhidoDataEmissao: '',
			rgRecolhidoSetorIdent: '',
			rgRecolhidoPor: '',
			isento2Via_10952: '',
			isento2Via_062: '',
			termoTutela: '',
			tutelaProc: '',
			tutelaAno: '',
			tutelaData: '',
			pgtoTx2via: null,
			pgtoTxCorreio: false,
			restricaoCriminal: !!maior.cr,
			moraCom: maior.moraCom ? maior.moraCom.toLocaleUpperCase() : '',
			grauParentescoSp: maior.grauParentescoSp
				? maior.grauParentescoSp.toLocaleUpperCase()
				: '',
			ocupacaoPrincipalSp: maior.ocupacaoPrincipalSp
				? maior.ocupacaoPrincipalSp.toLocaleUpperCase()
				: '',
			temParentesSp: maior.temParentesSp ?? false,
			resideSpAnos: maior.resideSpAnos ?? '',
			resideSpMeses: maior.resideSpMeses ?? '',
			isencaoPrimeiraViaCin: !!maior.isencaoPrimeiraViaCin,
		};
	}

	if (observacoes.segundaVia) {
		return {
			rgForaSp: false,
			rgForaSpUf: '',
			rgForaSpNum: '',
			rgForaSpDataEmissao: '',
			ctpsNum: observacoes.segundaVia.ctpsObs,
			ctpsSerie: observacoes.segundaVia.srObs,
			ctpsSerieUf: observacoes.segundaVia.estObs,
			observacoes: observacoes.segundaVia.observacao
				? observacoes.segundaVia.observacao.toUpperCase()
				: '',
			declaracaoPobreza: observacoes.segundaVia.declaracaoPobreza,
			solicitaBloqueio: observacoes.segundaVia.solicitarBloqueio,
			termoRecolhimentoRG: observacoes.segundaVia.termoRecolhimentoRg,
			rgRecolhidoDataEmissao: unformatDate(
				observacoes.segundaVia.rgRecolhidoEmissao,
			),
			rgRecolhidoSetorIdent: observacoes.segundaVia.rgRecolhidoIdentific,
			rgRecolhidoPor: observacoes.segundaVia.rgRecolhidoPor
				? observacoes.segundaVia.rgRecolhidoPor.toLocaleUpperCase()
				: '',
			isento2Via_10952: observacoes.segundaVia.isento2Via10952,
			isento2Via_062: observacoes.segundaVia.isento2Via062,
			termoTutela: observacoes.segundaVia.termoTutela,
			tutelaProc: observacoes.segundaVia.tutelaProc,
			tutelaAno: observacoes.segundaVia.tutelaAno,
			tutelaData: verificaTutela(observacoes.segundaVia.tutelaData),
			pgtoTx2via: false,
			pgtoTxCorreio: false,
			restricaoCriminal: !!observacoes.segundaVia.cr,
			moraCom: '',
			ocupacaoPrincipalSp: '',
			resideSpAnos: '',
			resideSpMeses: '',
			temParentesSp: '',
			grauParentescoSp: '',
			tpDocResponsavel: observacoes.segundaVia?.tipoDocumentoResponsavel
				? tpDocResponsavel(observacoes.segundaVia?.tipoDocumentoResponsavel)
				: '',
			ufDocResponsavel: observacoes.segundaVia.ufDocumentoResponsavel
				? observacoes.segundaVia.ufDocumentoResponsavel
				: '',
			numDocResponsavel: observacoes.segundaVia.numeroDocumentoResponsavel
				? observacoes.segundaVia.numeroDocumentoResponsavel.toLocaleUpperCase()
				: '',
			dtExpResponsavel: observacoes.segundaVia.dataExpedicaoDocumentoResponsavel
				? unformatDate(observacoes.segundaVia.dataExpedicaoDocumentoResponsavel)
				: '',
			envioPinpad: !!envioPinpad,
			isencaoPrimeiraViaCin: !!observacoes.segundaVia.isencaoPrimeiraViaCin,
		};
	}

	return {
		rgForaSp: false,
		rgForaSpUf: '',
		rgForaSpNum: '',
		rgForaSpDataEmissao: '',
		ctpsNum: '',
		ctpsSerie: '',
		ctpsSerieUf: '',
		observacoes: '',
		declaracaoPobreza: '',
		solicitaBloqueio: '',
		termoRecolhimentoRG: '',
		rgRecolhidoDataEmissao: '',
		rgRecolhidoSetorIdent: '',
		rgRecolhidoPor: '',
		pgtoTx2via: false,
		isento2Via_10952: '',
		isento2Via_062: '',
		termoTutela: '',
		tutelaProc: '',
		tutelaAno: '',
		tutelaData: '',
		pgtoTxCorreio: false,
		restricaoCriminal: false,
		moraCom: '',
		ocupacaoPrincipalSp: '',
		resideSpAnos: '',
		resideSpMeses: '',
		temParentesSp: '',
		grauParentescoSp: '',
		envioPinpad: !!envioPinpad,
	};
}

export function getTipoSanguineo(value: string): string {
	const filteredValues: {
		value: string;
		label: string;
	}[] = data.tipoSanguineo.filter(
		(item: {
			value: string;
			label: string;
		}): {
			value: string;
			label: string;
		} | null => {
			if (value === item.value) {
				return item;
			}
			return null;
		},
	);

	return filteredValues[0].label;
}

// STATUS DARE
// EMITIDO("0","EMITIDO"),
// PAGAMENTO_POR_QUITACAO_INDIVIDUAL("1","PAGO"),
// PAGAMENTO_VIA_PROCESSAMENTO_LOTE("2","PAGO"),
// QUEIMA_INUTILIZACAO("3","INUTILIZADA"),
// REJEITADO_PELO_USUARIO("4","REJEITADO PELO USUÁRIO"),
// DADOS_INCORRETOS("5","DADOS INCORRETOS AO GERAR DARE"),
// CILI_EFETUADA_VIA_REPROCESSAMENTO("6","PAGO"),
// ERRO_NA_QUEIMA("7","ERRO DE CONEXÃO NA QUEIMA"),
// ERRO_DE_EMISSAO("8","ERRO DE EMISSÃO"),
// 	ERRO_CONEXAO_CHAMADA_CILI("9", "ERRO DE CONEXAO COM CHAMADA CILI");

export function getPagamentoTaxas(cadastroRg: ICadastroRgState): {
	pgtoTx2via: null | boolean;
	pgtoTxCorreio: null | boolean;
} {
	let pgtoTxCorreio = null;
	const hasTaxaCorreio = cadastroRg.dare?.taxasRes.filter(
		(item: any) => item.servicoTipo === 1300058 || item.servicoTipo === 1200058,
	);
	if (
		cadastroRg &&
		cadastroRg.dare &&
		hasTaxaCorreio?.length &&
		cadastroRg.form.principal?.entrega !== 'K'
	) {
		pgtoTxCorreio =
			cadastroRg.dare.status === 1 ||
			cadastroRg.dare.status === 2 ||
			cadastroRg.dare.status === 6;
	}
	let pgtoTx2via = null;
	if (
		cadastroRg.dare &&
		cadastroRg.form.observacoes.segundaVia &&
		!cadastroRg.form.observacoes.segundaVia.declaracaoPobreza &&
		!cadastroRg.form.observacoes.segundaVia.isento2Via10952 &&
		!cadastroRg.form.observacoes.segundaVia.isento2Via062
	) {
		if (
			cadastroRg.dare.status === 1 ||
			cadastroRg.dare.status === 2 ||
			cadastroRg.dare.status === 6
		) {
			pgtoTx2via = true;
		} else {
			pgtoTx2via = false;
		}
	}

	return { pgtoTx2via, pgtoTxCorreio };
}

function formataCpf(cpf: string, field: string): string {
	if (!cpf) {
		return '';
	}
	if (cpf && limparMascara(cpf).length === 11) {
		const cpfSemMascara = limparMascara(cpf);
		if (field === 'num') {
			return cpfSemMascara.substring(0, 9);
		}
		if (field === 'dig') {
			return cpfSemMascara.substring(9, 11);
		}
	}
	return '';
}

export function getSedex(
	cirg: ConsultaPesquisaCirgState,
	cadastroRG: ICadastroRgState,
): string {
	if (cadastroRG.form.principal?.entrega === 'P') {
		return '';
	}
	if (cadastroRG.form.principal?.sedex) {
		return cadastroRG.form.principal?.sedex;
	}
	return '';
}

// CILI SIMPLICADA

// 1) afis === "*" & mudanças nas perguntas (na Temporalidade e COM Nova Coleta) - A
// 2) afis === "-" & 80 > idade > 6 (Fora da Temporalidade e Idade entre 06 e 80 anos) - B
// 3) afis ==== "*" & sem altearação nas perguntas & erro na validação qtdefalha === 3 (na Temporalidade e SEM Match) - C

export function getTipoCili(
	consultaPesquisaCidadao: IConsultaPesquisaCidadaoState,
	cadastroRg: ICadastroRgState,
	confrontarDigitais: IConfrontarDigitaisState,
	biometria: CapturarBiometriaState,
	consultarDigitais: ConsultarDigitaisState,
	restricaoCriminal: boolean,
): string | null {
	if (restricaoCriminal) {
		return null;
	}
	if (
		consultaPesquisaCidadao.selecionado &&
		(consultaPesquisaCidadao.selecionado.afis ||
			consultaPesquisaCidadao.selecionado.afis === '' ||
			consultaPesquisaCidadao.selecionado.afis === ' ')
	) {
		// TIPO A
		if (
			consultaPesquisaCidadao.selecionado.afis === '*' &&
			cadastroRg.perguntas.teveAlteracao
		) {
			if (cadastroRg.form.principal?.dni === '10977') {
				return 'B';
			}
			return 'A';
		}
		// TIPO B
		if (
			(consultaPesquisaCidadao.selecionado.afis === '-' ||
				consultaPesquisaCidadao.selecionado.afis === '' ||
				consultaPesquisaCidadao.selecionado.afis === ' ') &&
			cadastroRg.form.principal?.dataNascimento &&
			calcularIdade(cadastroRg.form.principal?.dataNascimento) >= 6 &&
			calcularIdade(cadastroRg.form.principal?.dataNascimento) <= 80
		) {
			return 'B';
		}
		// TIPO C
		if (
			(consultaPesquisaCidadao.selecionado.afis === '*' &&
				biometria.qtdeFalha === 3 &&
				confrontarDigitais.resultado === false) ||
			(consultaPesquisaCidadao.selecionado.afis === '*' &&
				consultarDigitais?.data &&
				consultarDigitais?.status === 200 &&
				!consultarDigitais?.data.dadosBiometricos.length) ||
			(consultaPesquisaCidadao.selecionado.afis === '*' &&
				confrontarDigitais &&
				confrontarDigitais.qtdeFalha === 3 &&
				!confrontarDigitais.resultado)
		) {
			if (cadastroRg.form.principal?.dni === '10977') {
				return 'B';
			}
			return 'C';
		}
		if (
			cadastroRg.form.principal?.dni === '10977' &&
			cadastroRg.form.principal?.dataNascimento &&
			calcularIdade(cadastroRg.form.principal?.dataNascimento) >= 6 &&
			calcularIdade(cadastroRg.form.principal?.dataNascimento) <= 80
		) {
			return 'B';
		}
	} else if (
		cadastroRg.form.principal?.dni === '10977' &&
		cadastroRg.form.principal?.dataNascimento &&
		calcularIdade(cadastroRg.form.principal?.dataNascimento) >= 6 &&
		calcularIdade(cadastroRg.form.principal?.dataNascimento) <= 80
	) {
		return 'B';
	}

	return null;
}

export function formatarProtocoloFIC(
	login: ILogin,
	cadastroRG: ICadastroRgState,
	sedex: string,
	envioPinpad: string,
	restricaoCriminal: boolean,
	tipoCili: string | null,
	resultadoDigitais: boolean,
	dadosAgendamento: DadosAgendamentoState,
	codigoRetorno: boolean,
): IProtocoloFic | null {
	const { tipo, form } = cadastroRG;
	const { principal, observacoes } = form;

	let body: IProtocoloFic | null = null;

	if (principal) {
		body = {
			liberacaoSimplificada: codigoRetorno,
			viaRg: tipo.primeiraVia ? '1' : '2',
			reusoParcial:
				tipo.reusoParcial &&
				cadastroRG.perguntas.teveAlteracao === false &&
				resultadoDigitais,
			reusoTotal:
				tipo.reusoTotal &&
				cadastroRG.perguntas.teveAlteracao === false &&
				resultadoDigitais,
			posto: {
				codigo: login.user.posto,
				digito: login.user.digito_posto,
				nome: login.user.nomePosto,
				usuario: login.mainframe.idUsuario,
			},
			cidadao: {
				numRg: extrairNumeroDigito(principal.numeroRg, 'num'),
				digRg: extrairNumeroDigito(
					principal.numeroRg,
					'dig',
				).toLocaleUpperCase(),
				nome: principal.nome,
				nomePai: principal.nomePai ? principal.nomePai.toLocaleUpperCase() : '',
				nomeMae: principal.nomeMae ? principal.nomeMae.toLocaleUpperCase() : '',
				sexo: principal.sexo,
				tipoDocumento: principal.dbas,
				dataNascimento: unformatDate(principal.dataNascimento),
				naturalidade: {
					codigoFormatado: principal.codigoNaturalidade,
					nome: principal.municipioNaturalidade,
				},
				cpf: formataCpf(principal.cpf, 'num'),
				cpfDig: formataCpf(principal.cpf, 'dig'),
				pis: principal.pis,
				pasepCompleto: principal.pasep,
				comarca: principal.comarca.toLocaleUpperCase() ?? '',
				cartorio: principal.cartorio.toLocaleUpperCase() ?? '',
				livro: principal.livro.toLocaleUpperCase() ?? '',
				folha: principal.folha.toLocaleUpperCase() ?? '',
				numero: principal.numero,
				dataDocumento: unformatDate(principal.dataDocumento),
				portMinLei1: principal.pMinLei1,
				portMinLei2: principal.pMinLei2,
				estadoCivil: principal.estadoCivil
					? principal.estadoCivil.toLocaleUpperCase()
					: '',
				cutis: principal.cutis ? principal.cutis.toLocaleUpperCase() : '',
				cabelos: principal.cabelos ? principal.cabelos.toLocaleUpperCase() : '',
				olhos: principal.olhos ? principal.olhos.toLocaleUpperCase() : '',
				altura: principal.altura,
				grauInstrucao: principal.grauInstrucao
					? principal.grauInstrucao.toLocaleUpperCase()
					: '',
				profissao: principal.profissao
					? principal.profissao.toLocaleUpperCase()
					: '',
				endResidencia: principal.enderecoResidencia
					? principal.enderecoResidencia.toLocaleUpperCase()
					: principal.enderecoResidencia,
				numResidencia: principal.numeroResidencia,
				complResidencia: principal.complementoResidencia
					? principal.complementoResidencia.toLocaleUpperCase()
					: principal.complementoResidencia,
				bairroResidencia: principal.bairroResidencia
					? principal.bairroResidencia.toLocaleUpperCase()
					: principal.bairroResidencia,
				municipioResidencia: {
					codigoFormatado: principal.codigoMunicipioResidencia,
					nome: principal.descMunicipioResidencia
						? principal.descMunicipioResidencia.toLocaleUpperCase()
						: '',
				},
				numTrabalho: principal.numeroTrabalho,
				endTrabalho: principal.enderecoTrabalho
					? principal.enderecoTrabalho.toLocaleUpperCase()
					: principal.enderecoTrabalho,
				complTrabalho: principal.complementoTrabalho
					? principal.complementoTrabalho.toLocaleUpperCase()
					: principal.complementoTrabalho,
				bairroTrabalho: principal.bairroTrabalho
					? principal.bairroTrabalho.toLocaleUpperCase()
					: principal.bairroTrabalho,
				municipioTrabalho: {
					nome: principal.descMunicipioTrabalho
						? principal.descMunicipioTrabalho.toLocaleUpperCase()
						: principal.descMunicipioTrabalho,
					codigoFormatado: principal.codigoMunicipioTrabalho,
				},
				dataRetiradaDoc: unformatDate(principal.data),
				hrRetiradaDoc: formatDateISO(principal.data, principal.hora),
				telefone: principal.foneResidencia,
				telefoneComercial: principal.foneComercial,
				celular: principal.celular,
				email: principal.email,
				sedex,
				tipoSanguineo: principal.tipoSanguineo
					? getTipoSanguineo(principal.tipoSanguineo)
					: '',
				observacoesRG: {
					...formatarObservacoesProtocoloFIC(observacoes, envioPinpad),
					...getPagamentoTaxas(cadastroRG),
					restricaoCriminal,
				},
				nomeSocial: principal.nomeSocial
					? principal.nomeSocial.toLocaleUpperCase()
					: '',
				cid1: principal.cid1 ? principal.cid1.toLocaleUpperCase() : '',
				cid2: principal.cid2 ? principal.cid2.toLocaleUpperCase() : '',
				cid3: principal.cid3 ? principal.cid3.toLocaleUpperCase() : '',
				cid4: principal.cid4 ? principal.cid4.toLocaleUpperCase() : '',
				dni: principal.dni,
				tituloEleitor: principal.tituloEleitor,
				ctps: principal.ctpsNum,
				ctpsSerie: principal.ctpsSerie,
				ctpsSerieUf: principal.ctpsSerieUf,
				certidaoMilitar: principal.certidaoMilitar,
				categoriaCnh: principal.categoriaCnh,
				doador: principal.doador,
				cnh: principal.cnh,
				cns: principal.nascSaude,
				numeroIdentidadeProfissional1: principal.identProf1.toUpperCase(),
				orgaoEntidadeProfissional1: principal.orgaoEmissor1.toUpperCase(),
				numeroIdentidadeProfissional2: principal.identProf2.toUpperCase(),
				orgaoEntidadeProfissional2: principal.orgaoEmissor2.toUpperCase(),
				numeroIdentidadeProfissional3: principal.identProf3.toUpperCase(),
				orgaoEntidadeProfissional3: principal.orgaoEmissor3.toUpperCase(),
				entrega: principal.entrega,
				// PORTARIA 606
				apresentouCertidao: cadastroRG.portaria606 === false,
				// DARE
				numeroDare: cadastroRG.dare?.numeroControleGuiaMae
					? String(cadastroRG.dare?.numeroControleGuiaMae)
					: '',
				numProtAgendamento: dadosAgendamento.numeroProtocolo,
				senhaAgendamento: dadosAgendamento.senhaAtendimento,
			},
			multifiliacao: principal.multifiliacao,
			atendimentoCin: principal.dni === '10977',
		};
	}

	// if (body?.cidadao.observacoesRG.restricaoCriminal) {
	// 	body.tipoCili = null;
	// }

	return body;
}
