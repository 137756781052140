import { usoFormularioEnumTypes } from './types';

export const enumTypeServiceDetranCnhEventSession = [
	{ type: usoFormularioEnumTypes.CASSACAO, value: 'DETRAN_CNH_CASSACAO' },
	{ type: usoFormularioEnumTypes.CASSACAO_CRIME, value: 'DETRAN_CNH_CASSACAO' },
	{ type: usoFormularioEnumTypes.SEGUNDA_VIA, value: 'DETRAN_CNH_SEGUNDA_VIA' },
	{
		type: usoFormularioEnumTypes.MUDANCA_CATEGORIA,
		value: 'DETRAN_CNH_MUDANCA_CATEGORIA',
	},
	// 'DETRAN_CNH_DEFINITIVA_REUSO';
	// 'DETRAN_CNH_SEGUNDA_VIA_REUSO',
	{
		type: usoFormularioEnumTypes.ADICAO_CATEGORIA,
		value: 'DETRAN_CNH_ADICAO_CATEGORIA',
	},
	{
		type: usoFormularioEnumTypes.PERMISSAO_INTERNACIONAL,
		value: 'DETRAN_CNH_PID',
	},
	{
		type: usoFormularioEnumTypes.CNH_ESTRANGEIRA,
		value: 'DETRAN_CNH_ESTRANGEIRO',
	},
	{
		type: usoFormularioEnumTypes.PERMISSIONARIO,
		value: 'DETRAN_CNH_PERMISSIONARIO',
	},
	{
		type: usoFormularioEnumTypes.PERMISSIONARIO_PONTUADO,
		value: 'DETRAN_CNH_PERMISSIONARIO',
	},
	{ type: usoFormularioEnumTypes.RENOVACAO, value: 'DETRAN_CNH_RENOVACAO' },
	{
		type: usoFormularioEnumTypes.CONSULTA_PONTOS,
		value: 'DETRAN_CNH_CONSULTA_PONTOS',
	},
	{ type: usoFormularioEnumTypes.CNH_DIGITAL, value: 'DETRAN_CNH_DIGITAL' },
	{
		type: usoFormularioEnumTypes.CNH_DEFINITIVA,
		value: 'DETRAN_CNH_DEFINITIVA',
	},
	{
		type: usoFormularioEnumTypes.PRIMEIRA_HABILITACAO,
		value: 'DETRAN_CNH_PRIMEIRA_HABILITACAO',
	},
];
