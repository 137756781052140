import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

import { Types, protocolarRecurso } from './types';

export const INITIAL_STATE: protocolarRecurso = {
	status: 0,
	data: null,
};

export default function protocolarRecursoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): protocolarRecurso {
	return produce(state, draft => {
		switch (action.type) {
			case Types.PROTOCOLAR_RECURSO: {
				break;
			}
			case Types.PROTOCOLAR_RECURSO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.PROTOCOLAR_RECURSO_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
