import { takeLatest, call, put, all } from 'redux-saga/effects';
import { postApi, ApiResponse } from 'services/_api';
import { PATH_DETRAN_CRV, CHANNEL } from 'services/_path';
import { Types } from './types';
import {
	consultarCadastroBinRenavamSuccess,
	consultarCadastroBinRenavamFailure,
} from './actions';

function* consultarCadastroBinRenavamRequest({ payload }: any) {
	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consultar-veiculo-bin-renavam`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(consultarCadastroBinRenavamSuccess(response));
	} else if (response.error) {
		yield put(consultarCadastroBinRenavamFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_CADASTRO_BIN_RENAVAM_REQUEST,
		consultarCadastroBinRenavamRequest,
	),
]);
