export enum Types {
	ATUALIZAR_PARAMETRO_SISTEMA_REDIS = '@SGU/ATUALIZAR_PARAMETRO_SISTEMA_REDIS',
	ATUALIZAR_PARAMETRO_SISTEMA_REDIS_SUCCESS = '@SGU/ATUALIZAR_PARAMETRO_SISTEMA_REDIS_SUCCESS',
	ATUALIZAR_PARAMETRO_SISTEMA_REDIS_FAILURE = '@SGU/ATUALIZAR_PARAMETRO_SISTEMA_REDIS_FAILURE',
	ATUALIZAR_PARAMETRO_SISTEMA_REDIS_CLEAR = '@SGU/ATUALIZAR_PARAMETRO_SISTEMA_REDIS_CLEAR',
}

export interface AtualizarParametroSistemaRedis {
	status: number;
}

export interface AtualizarParametroSistemaRedisRequest {
	chave: string;
	valor: string;
}
