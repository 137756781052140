import { ReducerAction } from 'store/modules/types';

import { Types, IncluirExameMedicoRequest } from './types';

export function incluirExameMedicoRequest(
	data: IncluirExameMedicoRequest,
): ReducerAction {
	return {
		type: Types.INCLUIR_EXAME_MEDICO_REQUEST,
		payload: data,
	};
}
export function incluirExameMedicoSuccess(payload: object): ReducerAction {
	return {
		type: Types.INCLUIR_EXAME_MEDICO_SUCCESS,
		payload,
	};
}
export function incluirExameMedicoFailure(): ReducerAction {
	return {
		type: Types.INCLUIR_EXAME_MEDICO_FAILURE,
		payload: null,
	};
}
export function incluirExameMedicoClear(): ReducerAction {
	return {
		type: Types.INCLUIR_EXAME_MEDICO_CLEAR,
		payload: null,
	};
}
