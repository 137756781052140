import { ReducerAction } from 'store/modules/types';

import { Types, ConsultarMotivosRequest } from './types';

export function consultarMotivosRequest(
	payload: ConsultarMotivosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_MOTIVOS,
		payload,
	};
}
export function consultarMotivosSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_MOTIVOS_SUCCESS,
		payload,
	};
}
export function consultarMotivosFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_MOTIVOS_FAILURE,
		payload: null,
	};
}
export function consultarMotivosClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_MOTIVOS_CLEAR,
		payload: null,
	};
}
