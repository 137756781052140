export default {
	title: 'light',
	fontScale: 0,
	typography: {
		primary: '#000000',
		secondary: '#666666',
		disabled: '#808080',
		error: '#ff0000',
	},
	colors: {
		background: '#ffffff',
		border: '#a8a8a8',
		disabled: {
			input: 'rgba(239, 239, 239, 0.3)',
		},
	},
	header: {
		backgroundColor: {
			primary: '#e1dfe0',
			secondary: '#ffffff',
		},
	},
	footer: {
		backgroundColor: '#666666',
	},
};
