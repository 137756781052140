// TYPES
import { ReducerAction } from 'store/modules/types';
import { CadastraAtendimentoPjRequest, Types } from './types';

export function cadastraAtendimentoPjRequest(
	payload: CadastraAtendimentoPjRequest,
): ReducerAction {
	return {
		type: Types.CADASTRA_ATENDIMENTO_PJ_REQUEST,
		payload,
	};
}
export function cadastraAtendimentoPjSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRA_ATENDIMENTO_PJ_SUCCESS,
		payload,
	};
}
export function cadastraAtendimentoPjFailure(payload: object): ReducerAction {
	return {
		type: Types.CADASTRA_ATENDIMENTO_PJ_FAILURE,
		payload,
	};
}
export function cadastraAtendimentoPjClear(): ReducerAction {
	return {
		type: Types.CADASTRA_ATENDIMENTO_PJ_CLEAR,
		payload: null,
	};
}
