// ROUTES
import { ROUTE_DETRAN_CRV } from 'routes/paths';

// HOME
export const ROUTE_DETRAN_CRV_RECUPERAR_ATENDIMENTO = `${ROUTE_DETRAN_CRV}/recuperar-atendimento`;
export const ROUTE_DETRAN_CRV_JUSTIFICATIVA_ATENDIMENTO = `${ROUTE_DETRAN_CRV}/recuperar-atendimento/justificativa`;

export const ROUTE_DETRAN_CRV_CONSULTAR_BLOQUEIO_DETRAN_RENAJUD = `${ROUTE_DETRAN_CRV}/consultar-bloqueio-detran-renajud`;
export const ROUTE_DETRAN_CRV_CONSULTAR_BLOQUEIO_DETRAN_RENAJUD_RESULTADO = `${ROUTE_DETRAN_CRV}/consultar-bloqueio-detran-renajud/resultado`;

// SCANNER
export const ROUTE_DETRAN_CRV_MENU_SCANNER = `${ROUTE_DETRAN_CRV}/menu-scanner`;

// CONSULTA RESUMO - EXTRATO - BASE ESTADUAL
export const ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_BASE_ESTADUAL_CONSULTAR = `${ROUTE_DETRAN_CRV}/consulta-resumo-extrato/base-estadual/consultar`;
export const ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_BASE_ESTADUAL_RESULTADO = `${ROUTE_DETRAN_CRV}/consulta-resumo-extrato/base-estadual/resultado`;

// CONSULTA RESUMO - EXTRATO - OUTRAS BASES
export const ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_OUTRAS_BASES_CONSULTAR = `${ROUTE_DETRAN_CRV}/consulta-resumo-extrato/outras-bases/consultar`;
export const ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_OUTRAS_BASES_RESULTADO = `${ROUTE_DETRAN_CRV}/consulta-resumo-extrato/outras-bases/resultado`;

// CONSULTA VEÍCULOS
export const ROUTE_DETRAN_CRV_CONSULTA_VEICULO_BASE_ESTADUAL = `${ROUTE_DETRAN_CRV}/consulta-veiculo-base-estadual`;
export const ROUTE_DETRAN_CRV_CONSULTA_VEICULO_BASE_ESTADUAL_RESULTADO = `${ROUTE_DETRAN_CRV}/consulta-veiculo-base-estadual/resultado`;

export const ROUTE_DETRAN_CRV_CONSULTA_CADASTRO_BIN_RENAVAM = `${ROUTE_DETRAN_CRV}/consulta-cadastro-bin-renavam`;
export const ROUTE_DETRAN_CRV_CONSULTA_CADASTRO_BIN_RENAVAM_RESULTADO = `${ROUTE_DETRAN_CRV}/consulta-cadastro-bin-renavam/resultado`;

export const ROUTE_DETRAN_CRV_CONSULTAR_BASE_OUTROS_ESTADOS = `${ROUTE_DETRAN_CRV}/consultar-base-outros-estados`;
export const ROUTE_DETRAN_CRV_CONSULTAR_BASE_OUTROS_ESTADOS_RESULTADO = `${ROUTE_DETRAN_CRV}/consultar-base-outros-estados/resultado`;

export const ROUTE_DETRAN_CRV_CONSULTAR_NUMERO_MOTOR_BIN = `${ROUTE_DETRAN_CRV}/consultar-numero-motor-bin`;
export const ROUTE_DETRAN_CRV_CONSULTAR_NUMERO_MOTOR_BIN_RESULTADO = `${ROUTE_DETRAN_CRV}/consultar-numero-motor-bin/resultado`;

// INTENÇÃO DE VENDA
export const ROUTE_DETRAN_CRV_CONSULTAR_INTENCAO_VENDA_CONSULTA = `${ROUTE_DETRAN_CRV}/consultar-intencao-venda/consulta`;
export const ROUTE_DETRAN_CRV_CONSULTAR_INTENCAO_VENDA_RESULTADO = `${ROUTE_DETRAN_CRV}/consultar-intencao-venda/resultado`;
export const ROUTE_DETRAN_CRV_INCLUIR_INTENCAO_VENDA_CONSULTA = `${ROUTE_DETRAN_CRV}/incluir-intencao-venda/consulta`;
export const ROUTE_DETRAN_CRV_INCLUIR_INTENCAO_VENDA_RESULTADO = `${ROUTE_DETRAN_CRV}/incluir-intencao-venda/resultado`;
export const ROUTE_DETRAN_CRV_CANCELAR_INTENCAO_VENDA_CONSULTA = `${ROUTE_DETRAN_CRV}/cancelar-intencao-venda/consulta`;
export const ROUTE_DETRAN_CRV_CANCELAR_INTENCAO_VENDA_RESULTADO = `${ROUTE_DETRAN_CRV}/cancelar-intencao-venda/resultado`;

export const ROUTE_DETRAN_CRV_IMPRIMIR_INTENCAO_VENDA_CONSULTA = `${ROUTE_DETRAN_CRV}/imprimir-intencao-venda/consulta`;
export const ROUTE_DETRAN_CRV_IMPRIMIR_INTENCAO_VENDA_RESULTADO = `${ROUTE_DETRAN_CRV}/imprimir-intencao-venda/resultado`;

// MULTAS RENAINF
export const ROUTE_DETRAN_CRV_MULTAS_RENAINF_PLACA = `${ROUTE_DETRAN_CRV}/multas-renainf-placa`;
export const ROUTE_DETRAN_CRV_MULTAS_RENAINF_PLACA_RESULTADO = `${ROUTE_DETRAN_CRV}/multas-renainf-placa/resultado`;

export const ROUTE_DETRAN_CRV_MULTAS_RENAINF_ORGAO_AUTUADOR = `${ROUTE_DETRAN_CRV}/multas-renainf-orgao-autuador`;
export const ROUTE_DETRAN_CRV_MULTAS_RENAINF_ORGAO_AUTUADOR_RESULTADO = `${ROUTE_DETRAN_CRV}/multas-renainf-orgao-autuador/resultado`;

// PROCESSOS
export const ROUTE_DETRAN_CRV_CONSULTAR_FICHA_CADASTRAL = `${ROUTE_DETRAN_CRV}/consultar-ficha-cadastral`;
export const ROUTE_DETRAN_CRV_CONSULTAR_FICHA_CADASTRAL_RESULTADO = `${ROUTE_DETRAN_CRV}/consultar-ficha-cadastral/resultado`;

export const ROUTE_DETRAN_CRV_EXCLUIR_FICHA_CADASTRAL = `${ROUTE_DETRAN_CRV}/excluir-ficha-cadastral`;
export const ROUTE_DETRAN_CRV_EXCLUIR_FICHA_CADASTRAL_RESULTADO = `${ROUTE_DETRAN_CRV}/excluir-ficha-cadastral/resultado`;

export const ROUTE_DETRAN_CRV_IMPRESSAO_RENAVAM_CONSULTA = `${ROUTE_DETRAN_CRV}/impressao-renavam/consulta`;
export const ROUTE_DETRAN_CRV_IMPRESSAO_RENAVAM_RESULTADO = `${ROUTE_DETRAN_CRV}/impressao-renavam/resultado`;

export const ROUTE_DETRAN_CRV_IMPRESSAO_LICENCIAMENTO_VEICULOS_CONSULTA = `${ROUTE_DETRAN_CRV}/impressao-licenciamento-veiculos/consulta`;
export const ROUTE_DETRAN_CRV_IMPRESSAO_LICENCIAMENTO_VEICULOS_RESULTADO = `${ROUTE_DETRAN_CRV}/impressao-licenciamento-veiculos/resultado`;

export const ROUTE_DETRAN_CRV_IMPRESSAO_ALTERACAO_ENDERECO = `${ROUTE_DETRAN_CRV}/impressao-alteracao-endereco`;

export const ROUTE_DETRAN_CRV_IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_CONSULTA = `${ROUTE_DETRAN_CRV}/impressao-segunda-via-licenciamento/consulta`;
export const ROUTE_DETRAN_CRV_IMPRESSAO_SEGUNDA_VIA_LICENCIAMENTO_RESULTADO = `${ROUTE_DETRAN_CRV}/impressao-segunda-via-licenciamento/resultado`;

export const ROUTE_DETRAN_CRV_IMPRESSAO_COMUNICACAO_VENDA_CONSULTA = `${ROUTE_DETRAN_CRV}/impressao-comunicacao-venda/consulta`;
export const ROUTE_DETRAN_CRV_IMPRESSAO_COMUNICACAO_VENDA_RESULTADO = `${ROUTE_DETRAN_CRV}/impressao-comunicacao-venda/resultado`;

export const ROUTE_DETRAN_CRV_IMPRESSAO_OFICIO_SEGUNDA_VIA_PLACA = `${ROUTE_DETRAN_CRV}/impressao-oficio-segunda-via-placa`;

export const ROUTE_DETRAN_CRV_IMPRESSAO_TROCA_VOLUNTARIA_PLACA = `${ROUTE_DETRAN_CRV}/impressao-troca-voluntaria-placa`;

export const ROUTE_DETRAN_CRV_ANDAMENTO_PROCESSO = `${ROUTE_DETRAN_CRV}/andamento-processo`;
export const ROUTE_DETRAN_CRV_ANDAMENTO_PROCESSO_RESULTADO = `${ROUTE_DETRAN_CRV}/andamento-processo/resultado`;

// SOLICITAR DOCUMENTOS
export const ROUTE_DETRAN_ESCOLHER_PLACA = `${ROUTE_DETRAN_CRV}/escolher-placa`;
export const ROUTE_DETRAN_ESCOLHER_PLACA_RESULTADO = `${ROUTE_DETRAN_CRV}/escolher-placa/resultado`;
export const ROUTE_DETRAN_ESCOLHER_PLACA_CONFIRMACAO = `${ROUTE_DETRAN_CRV}/escolher-placa/resultado/confirmacao`;

export const ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO = `${ROUTE_DETRAN_CRV}/primeiro-emplacamento`;
export const ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_RESULTADO = `${ROUTE_DETRAN_CRV}/primeiro-emplacamento/resultado`;
export const ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_CONFIRMACAO = `${ROUTE_DETRAN_CRV}/primeiro-emplacamento/confirmacao`;
export const ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_IMPRESSAO = `${ROUTE_DETRAN_CRV}/primeiro-emplacamento/impressao`;

export const ROUTE_DETRAN_REGISTRAR_AUTORIZACAO_PREVIA = `${ROUTE_DETRAN_CRV}/registrar-autorizacao-previa`;
export const ROUTE_DETRAN_AUTORIZACAO_PREVIA = `${ROUTE_DETRAN_CRV}/autorizacao-previa`;
export const ROUTE_DETRAN_RESULTADO_AUTORIZACAO_PREVIA = `${ROUTE_DETRAN_CRV}/resultado-autorizacao-previa`;

export const ROUTE_DETRAN_LICENCIAMENTO_VEICULO = `${ROUTE_DETRAN_CRV}/licenciamento-veiculo`;
export const ROUTE_DETRAN_LICENCIAMENTO_VEICULO_RESULTADO = `${ROUTE_DETRAN_CRV}/licenciamento-veiculo/resultado`;
export const ROUTE_DETRAN_LICENCIAMENTO_VEICULO_IMPRESSAO = `${ROUTE_DETRAN_CRV}/licenciamento-veiculo/impressao`;

export const ROUTE_DETRAN_TRANSFERENCIA_PROPRIETARIO_CONSULTAR = `${ROUTE_DETRAN_CRV}/transferencia-proprietario/consultar`;
export const ROUTE_DETRAN_TRANSFERENCIA_PROPRIETARIO_CONFIRMACAO = `${ROUTE_DETRAN_CRV}/transferencia-proprietario/confirmacao`;
export const ROUTE_DETRAN_TRANSFERENCIA_PROPRIETARIO_RESULTADO = `${ROUTE_DETRAN_CRV}/transferencia-proprietario/resultado`;

export const ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_CONSULTAR = `${ROUTE_DETRAN_CRV}/transferencia-municipio-estado/consultar`;
export const ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_CONFIRMACAO = `${ROUTE_DETRAN_CRV}/transferencia-municipio-estado/confirmacao`;
export const ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_RESULTADO = `${ROUTE_DETRAN_CRV}/transferencia-municipio-estado/resultado`;

export const ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_CONSULTAR = `${ROUTE_DETRAN_CRV}/transferencia-municipio/consultar`;
export const ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_CONFIRMACAO = `${ROUTE_DETRAN_CRV}/transferencia-municipio/confirmacao`;
export const ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_RESULTADO = `${ROUTE_DETRAN_CRV}/transferencia-municipio/resultado`;

export const ROUTE_DETRAN_SEGUNDA_VIA_CRV_CONSULTAR = `${ROUTE_DETRAN_CRV}/segunda-via-crv/consultar`;
export const ROUTE_DETRAN_SEGUNDA_VIA_CRV_EMITIR = `${ROUTE_DETRAN_CRV}/segunda-via-crv/emitir`;
export const ROUTE_DETRAN_SEGUNDA_VIA_CRV_RESULTADO = `${ROUTE_DETRAN_CRV}/segunda-via-crv/resultado`;

export const ROUTE_DETRAN_BAIXAR_VEICULO_CONSULTAR = `${ROUTE_DETRAN_CRV}/baixar-veiculo/consultar`;
export const ROUTE_DETRAN_BAIXAR_VEICULO_EMITIR = `${ROUTE_DETRAN_CRV}/baixar-veiculo/emitir`;
export const ROUTE_DETRAN_BAIXAR_VEICULO_RESULTADO = `${ROUTE_DETRAN_CRV}/baixar-veiculo/resultado`;

export const ROUTE_DETRAN_ALERACAO_DADOS_CADASTRAIS_CONSULTAR = `${ROUTE_DETRAN_CRV}/alteracao-dados-cadastrais/consultar`;
export const ROUTE_DETRAN_ALERACAO_DADOS_CADASTRAIS_EMITIR = `${ROUTE_DETRAN_CRV}/alteracao-dados-cadastrais/emitir`;
export const ROUTE_DETRAN_ALERACAO_DADOS_CADASTRAIS_RESULTADO = `${ROUTE_DETRAN_CRV}/alteracao-dados-cadastrais/resultado`;

export const ROUTE_DETRAN_SEGUNDA_VIA_PLACA_CONSULTAR = `${ROUTE_DETRAN_CRV}/segunda-via-placa/consultar`;
export const ROUTE_DETRAN_SEGUNDA_VIA_PLACA_EMITIR = `${ROUTE_DETRAN_CRV}/segunda-via-placa/emitir`;
export const ROUTE_DETRAN_SEGUNDA_VIA_PLACA_RESULTADO = `${ROUTE_DETRAN_CRV}/segunda-via-placa/resultado`;

export const ROUTE_DETRAN_CANCELAR_AUTORIZACAO_PREVIA = `${ROUTE_DETRAN_CRV}/cancelar-autorizacao-previa`;

export const ROUTE_DETRAN_CONSULTAR_AUTORIZACAO_PREVIA = `${ROUTE_DETRAN_CRV}/consultar-autorizacao-previa`;
export const ROUTE_DETRAN_CONSULTAR_AUTORIZACAO_PREVIA_RESULTADO = `${ROUTE_DETRAN_CRV}/consultar-autorizacao-previa/resultado`;

export const ROUTE_DETRAN_TROCA_VOLUNTARIA_PLACA_CONSULTAR = `${ROUTE_DETRAN_CRV}/troca-voluntaria-placa/consultar`;
export const ROUTE_DETRAN_TROCA_VOLUNTARIA_PLACA_EMITIR = `${ROUTE_DETRAN_CRV}/troca-voluntaria-placa/emitir`;
export const ROUTE_DETRAN_TROCA_VOLUNTARIA_PLACA_RESULTADO = `${ROUTE_DETRAN_CRV}/troca-voluntaria-placa/resultado`;

export const ROUTE_DETRAN_REENVIAR_DOCUMENTOS_CONSULTAR = `${ROUTE_DETRAN_CRV}/reenviar-documento/consultar`;
export const ROUTE_DETRAN_REENVIAR_DOCUMENTOS_RESULTADO = `${ROUTE_DETRAN_CRV}/reenviar-documento/resultado`;

export const ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_CATEGORIA_ALUGUEL_CONSULTAR = `${ROUTE_DETRAN_CRV}/desbloqueio-veiculo/categoria-aluguel/consultar`;
export const ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_CATEGORIA_ALUGUEL_DESBLOQUEAR = `${ROUTE_DETRAN_CRV}/desbloqueio-veiculo/categoria-aluguel/desbloqueio`;
export const ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_CATEGORIA_ALUGUEL_CONFIRMACAO = `${ROUTE_DETRAN_CRV}/desbloqueio-veiculo/categoria-aluguel/confirmacao`;

export const ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_BLOQUEIO_JUDICIAL_CONSULTAR = `${ROUTE_DETRAN_CRV}/desbloqueio-veiculo/bloqueio-judicial/consultar`;
export const ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_BLOQUEIO_JUDICIAL_DESBLOQUEAR = `${ROUTE_DETRAN_CRV}/desbloqueio-veiculo/bloqueio-judicial/desbloqueio`;
export const ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_BLOQUEIO_JUDICIAL_CONFIRMACAO = `${ROUTE_DETRAN_CRV}/desbloqueio-veiculo/bloqueio-judicial/confirmacao`;

export const ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_CONSULTAR = `${ROUTE_DETRAN_CRV}/licenciamento-veiculo/ocorrencia-obito/consultar`;
export const ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_DESBLOQUEAR = `${ROUTE_DETRAN_CRV}/licenciamento-veiculo/ocorrencia-obito/desbloqueio`;
export const ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_CONFIRMACAO = `${ROUTE_DETRAN_CRV}/licenciamento-veiculo/ocorrencia-obito/confirmacao`;

export const ROUTE_DETRAN_IMPRIMIR_CRLVE = `${ROUTE_DETRAN_CRV}/imprimir-crlve`;
