export default {
	docApresentado: [
		{
			value: 'RG',
			label: 'RG',
		},
		{
			value: 'OUTROS',
			label: 'OUTROS',
		},
	],
	tipoCnh: [
		{
			value: 'ACC',
			label: 'ACC',
		},
		{
			value: 'A',
			label: 'A',
		},
		{
			value: 'B',
			label: 'B',
		},
		{
			value: 'C',
			label: 'C',
		},
		{
			value: 'D',
			label: 'D',
		},
		{
			value: 'E',
			label: 'E',
		},
		{
			value: 'AB',
			label: 'AB',
		},
		{
			value: 'AC',
			label: 'AC',
		},
		{
			value: 'AD',
			label: 'AD',
		},
		{
			value: 'AE',
			label: 'AE',
		},
	],
	tipoDocumentoAac: [
		{
			value: 'RG',
			label: 'RG',
		},
		{
			value: 'CNH',
			label: 'CNH',
		},
		{
			value: 'FUNC',
			label: 'FUNCIONAL',
		},
		{
			value: 'CTPS',
			label: 'CARTEIRA DE TRABALHO',
		},
		{
			value: 'PAS',
			label: 'PASSAPORTE',
		},
		{
			value: 'CIN',
			label: 'CIN',
		},
		{
			value: 'CRNM',
			label: 'CRNM',
		},
		{
			value: 'RNE',
			label: 'RNE',
		},
		{
			value: 'PRNE',
			label: 'PROTOCOLO RNE',
		},
		{
			value: 'ON',
			label: 'OUTRAS NACIONALIDADES',
		},
		{
			value: 'TREF',
			label: 'TERMO DE REFUGIADO',
		},
		{
			value: 'OUTROS',
			label: 'OUTROS',
		},
	],
	tipoDocumento: [
		{
			value: 'CN',
			label: 'Certidão de Nascimento',
		},
		{
			value: 'CC',
			label: 'Certidão de Casamento',
		},
		{
			value: 'NZ',
			label: 'Naturalizado mediante Portaria Ministerial',
		},
		{
			value: 'NL',
			label: 'Naturalizado mediante Lei',
		},
		{
			value: 'TI',
			label: 'Título de igualdade Nat e Nac Portuguesa',
		},
		{
			value: 'TN',
			label: 'Título de igualdade Nat Portuguesa e Nac não',
		},
	],
	estadoCivil: [
		{
			value: 'C',
			label: 'Casado',
		},
		{
			value: 'D',
			label: 'Desquitado',
		},
		{
			value: 'I',
			label: 'Divorciado',
		},
		{
			value: 'J',
			label: 'Separado',
		},
		{
			value: 'S',
			label: 'Solteiro',
		},
		{
			value: 'V',
			label: 'Viúvo',
		},
		{
			value: 'T',
			label: 'Convivente',
		},
	],
	cutis: [
		{
			value: 'A',
			label: 'Amarela',
		},
		{
			value: 'B',
			label: 'Branca',
		},
		{
			value: 'N',
			label: 'Negra',
		},
		{
			value: 'R',
			label: 'Parda',
		},
		{
			value: 'V',
			label: 'Vermelha',
		},
		{
			value: 'L',
			label: 'Albino',
		},
	],
	corCabelo: [
		{
			value: 'B',
			label: 'Brancos',
		},
		{
			value: 'C',
			label: 'Cast. Claros',
		},
		{
			value: 'E',
			label: 'Cast. Escuros',
		},
		{
			value: 'G',
			label: 'Grisalhos',
		},
		{
			value: 'L',
			label: 'Loiros',
		},
		{
			value: 'P',
			label: 'Pretos',
		},
		{
			value: 'R',
			label: 'Ruivos',
		},
		{
			value: 'V',
			label: 'Calvo',
		},
	],
	corOlhos: [
		{
			value: 'A',
			label: 'Azuis',
		},
		{
			value: 'C',
			label: 'Cast. Claros',
		},
		{
			value: 'E',
			label: 'Cast. Escuros',
		},
		{
			value: 'P',
			label: 'Pretos',
		},
		{
			value: 'V',
			label: 'Verdes',
		},
		{
			value: 'Z',
			label: 'Cinzas',
		},
		{
			value: 'B',
			label: 'Bicolores',
		},
	],
	grauEscolaridade: [
		{
			value: 'N',
			label: 'Não Alfabetizado',
		},
		{
			value: 'P',
			label: '1º grau',
		},
		{
			value: 'S',
			label: '2º grau',
		},
		{
			value: 'U',
			label: 'Superior',
		},
	],
	solicitacaoAviso: [
		{
			value: '1',
			label: 'Sem Aviso',
		},
		{
			value: '2',
			label: 'SMS',
		},
	],
	operadoraCelular: [
		{
			value: 'CLARO',
			label: 'Claro',
		},
		{
			value: 'CTBC TELEC',
			label: 'CTBC Telecom',
		},
		{
			value: 'NEXTEL',
			label: 'Nextel',
		},
		{
			value: 'OI',
			label: 'Oi',
		},
		{
			value: 'VIVO',
			label: 'Vivo',
		},
		{
			value: 'SERCOMTEL',
			label: 'Sercomtel',
		},
		{
			value: 'TIM',
			label: 'TIM',
		},
		{
			value: 'PORTO SEG',
			label: 'Porto Seguro',
		},
		{
			value: '',
			label: 'Sem Informação',
		},
	],
	assinaraCiFic: [
		{
			value: true,
			label: 'Sim',
		},
		{
			value: false,
			label: 'Não',
		},
	],
	motivoFaltaAssinatura: [
		{
			value: '1',
			label: 'Não Alfabetizado',
		},
		{
			value: '2',
			label: 'Impossibilidade Permanente',
		},
		{
			value: '3',
			label: 'Impossibilidade Temporária',
		},
	],
	imprimir65Anos: [
		{
			value: true,
			label: 'Sim',
		},
		{
			value: false,
			label: 'Não',
		},
	],
	tipoSanguineo: [
		{
			value: 'AP',
			label: 'A POSITIVO',
		},
		{
			value: 'AN',
			label: 'A NEGATIVO',
		},
		{
			value: 'BP',
			label: 'B POSITIVO',
		},
		{
			value: 'BN',
			label: 'B NEGATIVO',
		},
		{
			value: 'ABP',
			label: 'AB POSITIVO',
		},
		{
			value: 'ABN',
			label: 'AB NEGATIVO',
		},
		{
			value: 'OP',
			label: 'O POSITIVO',
		},
		{
			value: 'ON',
			label: 'O NEGATIVO',
		},
	],
	uf: [
		{
			label: 'AC',
			value: 'AC',
		},
		{
			label: 'AL',
			value: 'AL',
		},
		{
			label: 'AM',
			value: 'AM',
		},
		{
			label: 'AP',
			value: 'AP',
		},
		{
			label: 'BA',
			value: 'BA',
		},
		{
			label: 'CE',
			value: 'CE',
		},
		{
			label: 'DF',
			value: 'DF',
		},
		{
			label: 'ES',
			value: 'ES',
		},
		{
			label: 'GO',
			value: 'GO',
		},
		{
			label: 'MA',
			value: 'MA',
		},
		{
			label: 'MG',
			value: 'MG',
		},
		{
			label: 'MS',
			value: 'MS',
		},
		{
			label: 'MT',
			value: 'MT',
		},
		{
			label: 'PA',
			value: 'PA',
		},
		{
			label: 'PB',
			value: 'PB',
		},
		{
			label: 'PE',
			value: 'PE',
		},
		{
			label: 'PI',
			value: 'PI',
		},
		{
			label: 'PR',
			value: 'PR',
		},
		{
			label: 'RJ',
			value: 'RJ',
		},
		{
			label: 'RN',
			value: 'RN',
		},
		{
			label: 'RO',
			value: 'RO',
		},
		{
			label: 'RR',
			value: 'RR',
		},
		{
			label: 'RS',
			value: 'RS',
		},
		{
			label: 'SC',
			value: 'SC',
		},
		{
			label: 'SE',
			value: 'SE',
		},
		{
			label: 'SP',
			value: 'SP',
		},
		{
			label: 'TO',
			value: 'TO',
		},
	],
	cidades: [
		{
			estado: 'SP',
			value: 'PIRACICABA',
			label: 'PIRACICABA',
		},
		{
			estado: 'SP',
			value: 'RIO CLARO',
			label: 'RIO CLARO',
		},
		{
			estado: 'SP',
			value: 'SAO PAULO',
			label: 'SAO PAULO',
		},
	],
	tipoEntrega: [
		{
			value: 'P',
			label: 'No posto',
		},
		{
			value: 'R',
			label: 'Na residência (Individual)',
		},
		{
			value: 'T',
			label: 'No trabalho',
		},
		{
			value: 'KC',
			label: 'Na residência em Kit (Início/Principal)',
		},
		{
			value: 'K',
			label: 'Na residência em Kit (Continuação/Complemento)',
		},
	],
	qtdKitFamilia: [
		{
			value: 2,
			label: '2',
		},
		{
			value: 3,
			label: '3',
		},
		{
			value: 4,
			label: '4',
		},
		{
			value: 5,
			label: '5',
		},
		{
			value: 6,
			label: '6',
		},
		{
			value: 7,
			label: '7',
		},
		{
			value: 8,
			label: '8',
		},
		{
			value: 9,
			label: '9',
		},
	],
	sexo: [
		{
			value: 'F',
			label: 'Feminino',
		},
		{
			value: 'M',
			label: 'Masculino',
		},
	],
	requerente: [
		{
			value: false,
			label: 'O Próprio',
		},
		{
			value: true,
			label: 'Procurador',
		},
	],
	envioAviso: [
		{
			value: '1',
			label: 'Sem Aviso',
		},
		{
			value: '2',
			label: 'SMS',
		},
	],
	simNao: [
		{
			value: 'S',
			label: 'Sim',
		},
		{
			value: 'N',
			label: 'Não',
		},
	],
	simNaoBoolean: [
		{
			value: true,
			label: 'Sim',
		},
		{
			value: false,
			label: 'Não',
		},
	],
	simNaoString: [
		{
			value: 'true',
			label: 'Sim',
		},
		{
			value: 'false',
			label: 'Não',
		},
	],
	qtdMeses: [
		{
			value: 0,
			label: '0',
		},
		{
			value: 1,
			label: '1',
		},
		{
			value: 2,
			label: '2',
		},
		{
			value: 3,
			label: '3',
		},
		{
			value: 4,
			label: '4',
		},
		{
			value: 5,
			label: '5',
		},
		{
			value: 6,
			label: '6',
		},
		{
			value: 7,
			label: '7',
		},
		{
			value: 8,
			label: '8',
		},
		{
			value: 9,
			label: '9',
		},
		{
			value: 10,
			label: '10',
		},
		{
			value: 11,
			label: '11',
		},
	],
	qtdMesesSemZero: [
		{
			value: 1,
			label: '1',
		},
		{
			value: 2,
			label: '2',
		},
		{
			value: 3,
			label: '3',
		},
		{
			value: 4,
			label: '4',
		},
		{
			value: 5,
			label: '5',
		},
		{
			value: 6,
			label: '6',
		},
		{
			value: 7,
			label: '7',
		},
		{
			value: 8,
			label: '8',
		},
		{
			value: 9,
			label: '9',
		},
		{
			value: 10,
			label: '10',
		},
		{
			value: 11,
			label: '11',
		},
	],
	justificativaMudancaAssinatura: [
		{
			value: 'Casou, Separou-se, Nome Social, Mudança de Sexo.',
			label: 'Situação civil',
		},
		{
			value: 'Inclusão de nome de pai ou da mãe.',
			label: 'Filiação',
		},
		{
			value: 'Mudou a forma de assinar, não consegue assinar igual etc.',
			label: 'Atualização da assinatura',
		},
		{
			value: 'Falha na impressão da FIC.',
			label: 'Impressão da FIC',
		},
	],
	justificativaMudancaFisica: [
		{
			value:
				'Cidadão mudou fisionomia, acidente, cirurgia.  A foto atual não reflete o cidadão em virtude da idade (Criança / Idoso) etc.',
			label: 'Fisionomia',
		},
		{
			value:
				'Problemas diversos com as digitais (Perda de dedos, impossibilidade de match contra base AFIS devido idade e/ou devido à dificuldade de leitura de digitais (Não lê).',
			label: 'Digital',
		},
		{
			value: 'Falha na impressão da FIC.',
			label: 'Impressão da FIC',
		},
		{
			value:
				'Falha no Leitor Biométrico, Sistema Inoperante, Mesa / posto sem coleta Biométrica etc.',
			label: 'Biometria indisponível',
		},
	],
	tipoDocs: [
		{
			value: 'RG',
			label: 'RG',
		},
		{
			value: 'CNH',
			label: 'CNH',
		},
		{
			value: 'CFUNCION',
			label: 'Carteira Funcional',
		},
		{
			value: 'CTPS',
			label: 'CTPS',
		},
		{
			value: 'CRNM',
			label: 'CRNM',
		},
		{
			value: 'PASSAPOR',
			label: 'Passaporte',
		},
		{
			value: 'PRT CRNM',
			label: 'Protocolo CRNM',
		},
		{
			value: 'RNE',
			label: 'RNE',
		},
		{
			value: 'T REFUG',
			label: 'Termo de Refugiado',
		},
		{
			value: 'OUTROS',
			label: 'Outros',
		},
	],
};
