import { RadioProps } from 'components/Common/Form/Radio';
import { OptionProps } from 'components/Common/Form/Select';
import { ROUTE_MENU_ORGAOS, pathsMenu } from 'routes/paths';

export function completaZeroEsquerda(
	value: string | number,
	size: number,
): string {
	let newValue = value;

	newValue = newValue.toString();
	newValue = newValue.trim();

	if (newValue && newValue.length < size) {
		for (let i = newValue.length; i < size; i++) {
			newValue = `0${newValue}`;
		}
	}

	return newValue;
}

export function limparMascara(value: string | undefined): string {
	if (value) return value.replace(/[^A-Za-z0-9]+/g, '');

	return '';
}

export function onlyZeros(value: string): boolean {
	return !/[^0]/.test(value);
}

export function validaCpf(cpfFormatted: string) {
	const cpf = limparMascara(cpfFormatted);
	let soma;
	let resto;
	soma = 0;
	if (cpf.length > 0 && cpf.length < 9) {
		return false;
	}
	if (cpf === '00000000000') {
		return false;
	}
	for (let i = 1; i <= 9; i++) {
		soma += Number(cpf.substring(i - 1, i)) * (11 - i);
	}
	resto = (soma * 10) % 11;
	if (resto === 10 || resto === 11) {
		resto = 0;
	}
	if (resto !== Number(cpf.substring(9, 10))) {
		return false;
	}
	soma = 0;
	for (let i = 1; i <= 10; i++) {
		soma += Number(cpf.substring(i - 1, i)) * (12 - i);
	}
	resto = (soma * 10) % 11;
	if (resto === 10 || resto === 11) {
		resto = 0;
	}
	if (resto !== Number(cpf.substring(10, 11))) {
		return false;
	}
	return true;
}

export function validaCpfCnpj(value: string) {
	if (value.length === 11) {
		const digitos = Array.from(value).map(Number);

		// Verifica o primeiro dígito verificador
		let soma = 0;
		for (let i = 0; i < 9; i++) {
			soma += digitos[i] * (10 - i);
		}
		const resto = soma % 11;
		const digito1 = resto < 2 ? 0 : 11 - resto;

		if (digito1 !== digitos[9]) {
			return false;
		}

		// Verifica o segundo dígito verificador
		soma = 0;
		for (let i = 0; i < 10; i++) {
			soma += digitos[i] * (11 - i);
		}
		const resto2 = soma % 11;
		const digito2 = resto2 < 2 ? 0 : 11 - resto2;

		return digito2 === digitos[10];
	}
	if (value.length > 11) {
		if (value.length !== 14) {
			return false;
		}

		const digitos = Array.from(value).map(Number);

		// Verifica o primeiro dígito verificador
		let soma = 0;
		const pesos1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
		for (let i = 0; i < 12; i++) {
			soma += digitos[i] * pesos1[i];
		}
		const resto1 = soma % 11;
		const digito1 = resto1 < 2 ? 0 : 11 - resto1;

		if (digito1 !== digitos[12]) {
			return false;
		}

		// Verifica o segundo dígito verificador
		soma = 0;
		const pesos2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
		for (let i = 0; i < 13; i++) {
			soma += digitos[i] * pesos2[i];
		}
		const resto2 = soma % 11;
		const digito2 = resto2 < 2 ? 0 : 11 - resto2;

		return digito2 === digitos[13];
	}
	return false;
}

export function extrairNumeroDigito(
	value: string | number | undefined,
	type: 'num' | 'dig',
): string {
	if (!value) return '';

	const arrValue = String(value).split('-');

	if (type === 'num') return arrValue[0];

	return arrValue[1];
}

export function extrairUltimoDigInteiro(
	value: string,
	type: 'num' | 'dig',
): string {
	const arrValue = value.split('');
	const digValue = arrValue[arrValue.length - 1];
	const numberValue = value.substring(0, arrValue.length - 1);

	if (type === 'num') return numberValue;

	return digValue;
}

export function formatDate(date: string | undefined, time?: boolean): string {
	if (!date || typeof date !== 'string') return '';

	const isFormated = date.includes('/');
	if (isFormated) return date;

	const splitedDate = date.split('-');

	if (time)
		return `${splitedDate[2].substring(0, 2)}/${splitedDate[1]}/${
			splitedDate[0]
		} ${String(new Date(date).getUTCHours()).padStart(2, '0')}:${String(
			new Date(date).getUTCMinutes(),
		).padStart(2, '0')}`;

	return `${splitedDate[2].substring(0, 2)}/${splitedDate[1]}/${
		splitedDate[0]
	}`;
}

export function formatDateISOWithOutUTC(
	date: string | undefined,
	time?: boolean,
): string {
	if (!date || typeof date !== 'string') return '';

	const isFormated = date.includes('/');
	if (isFormated) return date;

	const splitedDate = date.split('-');

	if (time)
		return `${splitedDate[2].substring(0, 2)}/${splitedDate[1]}/${
			splitedDate[0]
		} ${String(new Date(date).getHours()).padStart(2, '0')}:${String(
			new Date(date).getUTCMinutes(),
		).padStart(2, '0')}`;

	return `${splitedDate[2].substring(0, 2)}/${splitedDate[1]}/${
		splitedDate[0]
	}`;
}

export function unformatDate(date: string | undefined): string {
	if (!date) return '';
	const result = date.includes('/');
	if (!result) {
		const hasTimeStamp = date.includes('T');
		if (hasTimeStamp) {
			const splitedDate = date.split('T');
			return splitedDate[0];
		}
		return date;
	}
	const splitedDate = date.split('/');

	return `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`;
}

export function unformatDateTime(date: string | undefined): string {
	if (!date) return '';

	const splitedDate = date.split('/');

	return `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]} 12:00:00`;
}

export function formatDateToString(date: Date): string {
	const dd = String(date.getDate()).padStart(2, '0');
	const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
	const yyyy = date.getFullYear();

	const formatDateString = `${dd}/${mm}/${yyyy}`;
	return formatDateString;
}

export function formatDateToStringEnUs(date: Date): string {
	const dd = String(date.getDate()).padStart(2, '0');
	const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
	const yyyy = date.getFullYear();

	const formatDateString = `${yyyy}-${mm}-${dd}`;
	return formatDateString;
}

export function formatDateEnUs(data: string) {
	const day = data.substring(0, 2);
	const month = data.substring(3, 5);
	const year = data.substring(6, 10);
	const format = `${month}/${day}/${year}`;
	const formatedDate = new Date(format);
	return formatedDate;
}

export function formatDateBrToUs(data: string) {
	const day = data.split('-')[0];
	const month = data.split('-')[1];
	const year = data.split('-')[2];
	const format = `${year}-${month}-${day}`;
	const formatedDate = new Date(format);
	return formatedDate;
}

export async function formatDateISOAsync(date: string, time: string = '') {
	if (!date) return '';

	return new Date(
		`${unformatDate(date)} ${time ? `${time}:00` : '00:00:00'}`,
	).toISOString();
}

export function formatDateISO(date: string, time: string = '') {
	if (!date) return '';

	return new Date(
		`${unformatDate(date)} ${time ? `${time}:00` : '00:00:00'}`,
	).toISOString();
}

export function formatDateISOWithTimeZone(date: Date) {
	if (!date) return '';

	const tzOffset = -date.getTimezoneOffset();
	const diff = tzOffset >= 0 ? '+' : '-';
	const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
	return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
		date.getDate(),
	)}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
		date.getSeconds(),
	)}${diff}${pad(tzOffset / 60)}:${pad(tzOffset % 60)}`;
}

export function formatTimestampToDate(timestamp: number | string): string {
	const date = new Date(timestamp).toLocaleString('pt-BR');

	return date.split(' ')[0];
}

export function convertDate(date: string): string {
	const splitedDate = date.split('/');

	return `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`;
}

export function handlePosto(value: any): string {
	const iirgd = value
		.find((item: any) => item.nome === 'IIRGD')
		.localidades.map((item: any) => item.codigo);
	return iirgd[0];
}

export function patternFormat(
	value: string | number | undefined,
	pattern: string,
): string {
	if (value && String(value).length > 0) {
		let i = 0;
		const v = limparMascara(String(value)).toString();

		return pattern.replace(/#/g, () => v[i++] || '');
	}

	return '';
}

export function calcularIdade(dataNascimento: string): number {
	const dataAtual = new Date();
	const anoAtual = dataAtual.getFullYear();
	const mesAtual = dataAtual.getMonth();
	const diaAtual = dataAtual.getDate();

	const splitDataNascimento = dataNascimento.split('/');
	const anoNascimento = Number(splitDataNascimento[2]);
	const mesNascimento = Number(splitDataNascimento[1]);
	const diaNascimento = Number(splitDataNascimento[0]);

	let idade = anoAtual - anoNascimento;

	if (mesAtual < mesNascimento - 1) {
		idade--;
	}

	if (mesNascimento - 1 === mesAtual && diaAtual < diaNascimento) {
		idade--;
	}

	return idade;
}

export function verificarMaioridade(dataNascimento: string): boolean {
	const dataAtual = new Date();
	const anoAtual = dataAtual.getFullYear();
	const mesAtual = dataAtual.getMonth();
	const diaAtual = dataAtual.getDate();

	const splitDataNascimento = dataNascimento.split('/');
	const anoNascimento = Number(splitDataNascimento[2]);
	const mesNascimento = Number(splitDataNascimento[1]);
	const diaNascimento = Number(splitDataNascimento[0]);

	let idade = anoAtual - anoNascimento;

	if (mesAtual < mesNascimento - 1) {
		idade--;
	}

	if (mesNascimento - 1 === mesAtual && diaAtual < diaNascimento) {
		idade--;
	}

	return idade >= 18;
}

export function treatObjNullValues(data: object): object {
	const newData: any = {};

	Object.entries(data).forEach(([key, value]) => {
		newData[key] = value !== null ? value : '';
	});

	return newData;
}

export function validacaoData(data: string): boolean {
	if (!data) return false;

	const ExpReg = new RegExp(
		'(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}',
	);

	const ardt = data.split('/');

	if (data.search(ExpReg) === -1) {
		return false;
	}

	if (
		(Number(ardt[1]) === 4 ||
			Number(ardt[1]) === 6 ||
			Number(ardt[1]) === 9 ||
			Number(ardt[1]) === 11) &&
		Number(ardt[0]) > 30
	) {
		return false;
	}

	if (Number(ardt[1]) === 2) {
		if (Number(ardt[0]) > 28 && Number(ardt[2]) % 4 !== 0) return false;
		if (Number(ardt[0]) > 29 && Number(ardt[2]) % 4 === 0) return false;
	}

	return true;
}

export function toTitleCase(string: string): string {
	return string
		.toLowerCase()
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
}

export function onlyLetters(string: string): string {
	if (string) return string.replace(/[^-~a-zÀ-ÖØ-öø-ÿ ]/gi, '');
	return '';
}

export function onlyLettersAndSpace(string: string): string {
	if (string) {
		const stringEdited = string
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '');
		return stringEdited.replace(/[^a-z ]/gi, '');
	}
	return '';
}

export function onlyAlfaNumerico(string: string): string {
	if (string) return string.replace(/[^-~a-zÀ-ÖØ-öø-ÿ0-9 ]/gi, '');
	return '';
}

export function onlyNumbers(string: string): string {
	if (string) return string.replace(/[^0-9]/gi, '');
	return '';
}

export function onlyNumbersAndLetters(string: string): string {
	if (string) return string.replace(/[^0-9a-z]/gi, '');
	return '';
}

export function onlyNumbersLettersSpace(string: string): string {
	if (string) {
		const stringEdited = string
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '');
		return stringEdited.replace(/[^0-9a-z ]/gi, '');
	}
	return '';
}

export function onlyNumericalString(string: string): string {
	if (string) {
		return string.replace(/[^0-9]/gi, '');
	}
	return '0';
}

export function namesIirgd(string: string): string {
	if (string) return string.replace(/[^-~a-zÀ-ÖØ-öø-ÿ'´, ]/gi, '');
	return '';
}

export function nomeIirgd(string: string): string {
	if (string) return string.replace(/[^-~a-zÀ-ÖØ-öø-ÿ0-9-´ ]/gi, '');
	return '';
}

export function nomePaisIirgd(string: string): string {
	if (string) return string.replace(/[^-~a-zÀ-ÖØ-öø-ÿ0-9-´, ]/gi, '');
	return '';
}

export function namesIirgd2(string: string): string {
	if (string)
		return string.replace(
			/^[A-ZÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-ZÀ-Ÿ][A-zÀ-ÿ']+$/gi,
			'',
		);
	return '';
}

export function isEqualDates(dateA: string, dateB: string): boolean {
	const firstDate = unformatDate(formatDate(dateA));
	const secondDate = unformatDate(formatDate(dateB));

	return new Date(firstDate).getTime() === new Date(secondDate).getTime();
}

export function toSelect(
	items: any[],
	label: string,
	value: string | number,
): OptionProps[] {
	const formattedSelect: OptionProps[] = [];
	items.forEach(item => {
		if (item.flHabilitado) {
			formattedSelect.push({
				label: item[label],
				value: item[value],
				flHabilitado: item.flHabilitado,
			});
		} else {
			formattedSelect.push({ label: item[label], value: item[value] });
		}
	});

	return formattedSelect;
}

export function firstLastName(nomeCompleto: string): {
	primeiroNome: string;
	ultimoNome: string;
} {
	const splitedName = nomeCompleto.split(' ');

	return {
		primeiroNome: splitedName[0],
		ultimoNome: splitedName[splitedName.length - 1],
	};
}

export function stringArrayToEnum(values: string[]): RadioProps[] {
	const newArray: RadioProps[] = [];

	values.forEach(element => {
		newArray.push({ value: element, label: element });
	});

	return newArray;
}

export function fillCpfCnpj(
	type: string | number,
	value: string | number,
): string {
	if (!type || !value) {
		return '';
	}

	let newValue = '';

	if (type === 'FISICA' || type === 1) {
		if (value.toString().length === 11) return value.toString();
		if (value.toString().length > 11) return value.toString();
		newValue = '0'.repeat(11 - value.toString().length) + value;
	} else {
		if (value.toString().length === 14) return value.toString();
		newValue = '0'.repeat(14 - value.toString().length) + value;
	}

	return newValue;
}

export function cleanEmpty(data: object) {
	Object.keys(data).forEach(key => {
		const newKey = key as keyof typeof data;

		if (
			data[newKey] === null ||
			data[newKey] === undefined ||
			data[newKey] === ''
		) {
			delete data[newKey];
		}
	});
	return data;
}

export function validatePlaca(value: string): boolean {
	if (!value) return true;

	const ExpReg = new RegExp(
		'^$|(([bB][g-zG-Z][a-zA-Z])|([c-fC-F][a-zA-Z][a-zA-Z])|([gG][a-jA-J][a-zA-Z])|([gG][kK][a-iA-I]))',
	);

	const validate: boolean = ExpReg.test(value);

	return !validate;
}

export function validateComplementoPlaca(value: string): boolean {
	if (!value) return true;

	const ExpReg = new RegExp('^$|[0-9][a-zA-Z][0-9]{2}');

	const validate: boolean = ExpReg.test(value);

	return !validate;
}

export function removeAcentoCedilha(value: string): string {
	if (!value) return '';

	return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function removeAcentoCaracteresEspeciais(value: string): string {
	if (!value) return '';

	return value.normalize('NFD').replace(/[^a-zA-Z 0-9]+/g, '');
}

export function noRepeat<T>(uniqueKey: keyof T, objects: T[]): T[] {
	const ids = objects.map(object => object[uniqueKey]);
	return objects.filter(
		(object, index) => !ids.includes(object[uniqueKey], index + 1),
	);
}

export function separarDDD(telefone: string): any {
	if (telefone === '' || telefone.length < 9) {
		return { ddd: '', numTelefone: '' };
	}

	const strClean = telefone.replace(/\D/g, '');
	const ddd = strClean.substring(0, 2);
	const numTelefone = strClean.substring(2);

	return { ddd, numTelefone };
}

/*
	1 para cnh vencida no prazo de até 30 dias (IMPOSSIBILIDADE DE EMISSÃO DE 2VIA DE CNH. CONTATAR O DENATRAN– Portaria Contran 208/2021)
	2 para cnh vencida fora do prazo (A CNH pesquisada está vencida.)
	3 para cnh dentro do prazo de validade (redirecionamento status do condutor)
*/
export function validateCnh(dataValidadeCnh: string): number {
	const currentDate = new Date();
	const cnhDate = new Date(unformatDate(`${dataValidadeCnh}`));
	const diffTime = cnhDate.getTime() - currentDate.getTime();
	const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

	if (diffDays > 30) {
		return 3;
	}
	if (diffDays < 30) {
		if (cnhDate.getFullYear() === currentDate.getFullYear()) {
			return 2;
		}
		return 1;
	}
	return 3;
}

export function getDaysToExpirate(
	expirationDate: string,
	dateToCompare?: string,
) {
	const date =
		dateToCompare && dateToCompare !== ''
			? new Date(unformatDateTime(`${dateToCompare}`))
			: new Date(
					unformatDateTime(
						`${new Date().getFullYear()}-${
							new Date().getMonth() + 1
						}-${new Date().getDate()}`,
					),
			  );
	const cnhDate = new Date(unformatDateTime(`${expirationDate}`));
	const diffTime = cnhDate.getTime() - date.getTime();
	const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

	return diffDays;
}

export function dateMoreDays(dateDDMMYYYY: string, daysToIncrement: number) {
	const dateToIncrement =
		dateDDMMYYYY && convertDate(dateDDMMYYYY).replaceAll('-', '/');

	const dateToValidate = (dateToIncrement && dateToIncrement.split('/')) || [];

	if (
		dateToValidate &&
		dateToValidate[0].length === 4 &&
		dateToValidate[1].length === 2 &&
		dateToValidate[2].length === 2
	) {
		const dateToCompare = new Date(dateToIncrement);
		const previsao = dateToCompare.setDate(
			dateToCompare.getDate() + daysToIncrement,
		);
		const dateToUse = new Date(previsao);

		return `${dateToUse.getDate() < 10 ? '0' : ''}${dateToUse.getDate()}/${
			dateToUse.getMonth() + 1 < 10 ? '0' : ''
		}${dateToUse.getMonth() + 1}/${dateToUse.getFullYear()}`;
	}
	return '';
}

export const showComponent = (list: string[], value: string) => {
	return list.includes(value);
};

export function verifyIsUndefinedReturnEmpty(value: any): string {
	if (!value) return '';

	return typeof value === 'undefined' ? '' : value;
}

export function verifyStringIsAnInvalidPhrase(
	stringToCompare: string,
	arrayInvalidPhrases: string[],
) {
	if (
		!stringToCompare ||
		!arrayInvalidPhrases ||
		(arrayInvalidPhrases && !arrayInvalidPhrases.length)
	)
		return false;

	const string = removeAcentoCedilha(stringToCompare.toLowerCase());
	const result = arrayInvalidPhrases.filter(
		item => removeAcentoCedilha(item.toLocaleLowerCase()) === string,
	).length;

	return result > 0;
}

export const getBackPathMenu = (pathName: string): string => {
	if (!pathName || pathName === '') {
		return ROUTE_MENU_ORGAOS;
	}

	return (
		pathsMenu.filter(item => pathName.includes(item))[0] || ROUTE_MENU_ORGAOS
	);
};

export function completaEspacoVazioDireita(
	value: string,
	total: number,
): string {
	let newValue = value;

	newValue = newValue.toString();
	newValue = newValue.trim();

	if (newValue && newValue.length < total) {
		for (let i = newValue.length; i < total; i++) {
			newValue = `${newValue} `;
		}
	}

	return newValue;
}

export function concatenatesTaxaServicoPix(
	codBanco: string,
	dataContabil: string,
	nsuProdesp: string,
): string {
	if (codBanco === '' && dataContabil === '' && nsuProdesp === '') {
		return '';
	}

	const codBancoformat = completaZeroEsquerda(codBanco, 3);
	const dateContabilformat = dataContabil.substring(2, 10).replace(/-/g, '');
	const nsuProdespformat = completaZeroEsquerda(nsuProdesp, 9);

	const stringTaxaServicoConcatenated = completaEspacoVazioDireita(
		`${codBancoformat}${dateContabilformat}${nsuProdespformat}`,
		64,
	);

	return stringTaxaServicoConcatenated;
}
