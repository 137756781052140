import styled, { css } from 'styled-components';
import { rgba } from 'polished';

interface BoxProps {
	size?: number;
	isErrored: boolean;
	isFocused: boolean;
	isDisabled: boolean;
	isReadOnly: boolean;
}

export const Box = styled.div<BoxProps>`
	display: flex;
	width: 100%;

	.ant-picker {
		background-color: ${props => props.theme.colors.background};
		background-image: none;
		border: 1px solid ${props => props.theme.colors.border};
		border-radius: 2px;
		height: 25px;
		padding: 2px 4px;
		text-transform: uppercase;
		transition: unset;
		width: ${props => props.size}%;

		${props =>
			props.isErrored &&
			css`
				border: 1px solid rgb(204, 51, 51);
				box-shadow: 0px 1px 1px 0px rgb(204, 51, 51) inset;
			`};

		&:focus {
			box-shadow: 0px 0px 5px #85b2cb, inset 0 2px 2px #8f8f8f;
		}

		&:hover {
			border: 1px solid ${props => props.theme.colors.border};

			${props =>
				props.isErrored &&
				css`
					border: 1px solid rgb(204, 51, 51);
					box-shadow: unset;
				`};
		}

		${props =>
			props.isReadOnly &&
			css`
				box-shadow: unset !important;
				cursor: default;

				&:focus {
					box-shadow: unset;
				}

				&:hover {
					border: unset;
				}
			`};

		${props =>
			props.isReadOnly &&
			css`
				border: none;
				background-color: unset;
			`};

		${props =>
			props.isDisabled &&
			css`
				background-color: #fff !important;
				border: 1px solid ${rgba(props.theme.colors.border, 0.4)} !important;

				&:hover {
					cursor: no-drop;
				}
			`};

		.ant-picker-input {
			input {
				color: ${props => props.theme.typography.primary};
				font-size: calc(0.8rem + ${props => props.theme.fontScale}%);

				${props =>
					props.isDisabled &&
					css`
						color: ${rgba(props.theme.typography.primary, 0.7)} !important;
					`};
			}
		}
	}
`;
