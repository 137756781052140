import styled, { css } from 'styled-components';

interface ContentProps {
	size: 'sm' | 'md';
	overflow: 'x' | 'y' | undefined;
	height: string | undefined;
}

interface ContainerProps {
	fullHeight?: boolean;
}

export const Container = styled.div<ContainerProps>`
	border: 1px solid ${props => props.theme.colors.border};
	margin: 1px 0;
	${props => css`
		${props.fullHeight &&
		css`
			height: 100%;
		`};
	`};
`;

export const Title = styled.div`
	border-bottom: 1px solid ${props => props.theme.colors.border};
	padding: 5px 10px 5px 10px;

	${props => css`
		${props.theme.title === 'light' &&
		css`
			background-color: #dcdcdc;
		`};

		${props.theme.title === 'dark' &&
		css`
			background-color: ${props.theme.header.backgroundColor.primary};
		`};
	`};

	h5 {
		color: ${props => props.theme.typography.primary};
		font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
		font-weight: 500;
		margin-bottom: 0;
	}
`;

export const Content = styled.div<ContentProps>`
	${props => css`
		${props.overflow === 'y' &&
		css`
			overflow-y: auto;
		`};

		${props.overflow === 'x' &&
		css`
			overflow-x: auto;
		`};
	`};

	${props => css`
		${props.height !== undefined &&
		css`
			height: ${props.height};
		`};
	`};

	${props => css`
		${props.size === 'sm' &&
		css`
			padding: 8px;
		`};

		${props.size === 'md' &&
		css`
			padding: 16px;
		`};
	`};
`;
