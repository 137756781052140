import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConfrontarDigitaisCnhState } from './types';

export const INITIAL_STATE: IConfrontarDigitaisCnhState = {
	qtdeFalha: 0,
	resultado: false,
	dedoSorteio: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IConfrontarDigitaisCnhState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONFRONTAR_DIGITAIS_CNH_REQUEST: {
				draft.dedoSorteio =
					draft.dedoSorteio && draft.dedoSorteio?.length > 0
						? [...draft.dedoSorteio, action.payload.dedoSorteio]
						: [action.payload.dedoSorteio];
				break;
			}
			case Types.CONFRONTAR_DIGITAIS_CNH_SUCCESS: {
				draft.qtdeFalha = 0;
				draft.resultado = true;
				break;
			}
			case Types.CONFRONTAR_DIGITAIS_CNH_FAILURE: {
				draft.qtdeFalha += 1;
				draft.resultado = false;
				break;
			}
			case Types.CONFRONTAR_DIGITAIS_CNH_CLEAR: {
				draft.qtdeFalha = INITIAL_STATE.qtdeFalha;
				draft.resultado = INITIAL_STATE.resultado;
				draft.dedoSorteio = INITIAL_STATE.dedoSorteio;
				break;
			}

			default:
		}
		return draft;
	});
}
