import { ReducerAction } from 'store/modules/types';
import {
	Types,
	IRequestWithHeader,
	IFromRequestTransferenciaMunicipioEstado,
	IResponseTransferenciasaMunicipioEstados,
} from './types';

export function salvarDadosFormTransferenciasMunicipiosEstados(
	payload: IFromRequestTransferenciaMunicipioEstado,
): ReducerAction {
	return {
		type: Types.SALVAR_DADOS_FORM,
		payload,
	};
}

export function emitirTransferenciasMunicipiosEstadosRequest(
	payload: IRequestWithHeader,
): ReducerAction {
	return {
		type: Types.EMITIR_TRANSFERENCIAS_MUNICIPIOS_ESTADOS_REQUEST,
		payload,
	};
}

export function emitirTransferenciasMunicipiosEstadosSuccess(
	payload: IResponseTransferenciasaMunicipioEstados,
): ReducerAction {
	return {
		type: Types.EMITIR_TRANSFERENCIAS_MUNICIPIOS_ESTADOS_SUCCESS,
		payload,
	};
}

export function emitirTransferenciasMunicipiosEstadosFailure(): ReducerAction {
	return {
		type: Types.EMITIR_TRANSFERENCIAS_MUNICIPIOS_ESTADOS_FAILURE,
		payload: null,
	};
}

export function emitirTransferenciasMunicipiosEstadosClear(): ReducerAction {
	return {
		type: Types.EMITIR_TRANSFERENCIAS_MUNICIPIOS_ESTADOS_CLEAR,
		payload: null,
	};
}
