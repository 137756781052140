import produce from 'immer';

// TYPES
import { Types, consultaPermissaoInternacionalAutomatizado } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaPermissaoInternacionalAutomatizado = {
	status: 0,
	data: null,
};

export default function consultaPermissaoInternacionalAutomatizadoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaPermissaoInternacionalAutomatizado {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO: {
				break;
			}

			case Types.CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTA_PERMISSAO_INTERNACIONAL_AUTOMATIZADO_FAILURE: {
				break;
			}

			default:
		}
	});
}
