export enum Types {
	INCLUIR_ESTATISTICA_REQUEST = '@estatistica/INCLUIR_ESTATISTICA_REQUEST',
	INCLUIR_ESTATISTICA_SUCCESS = '@estatistica/INCLUIR_ESTATISTICA_SUCCESS',
	INCLUIR_ESTATISTICA_FAILURE = '@estatistica/INCLUIR_ESTATISTICA_FAILURE',
	INCLUIR_ESTATISTICA_CLEAR = '@estatistica/INCLUIR_ESTATISTICA_CLEAR',
}

export interface IncluirEstatistica {
	status: number;
	data: IncluirEstatisticaData | null;
}

interface IncluirEstatisticaData {
	codigo: number;
	mensagem: string;
}

export interface IncluirEstatisticaRequest {
	idCidadao: string;
	descricaoSistemOrigem: string;
	tipoDocumento: string;
	numeroDocumento: string;
	codigoLocalidade: number;
	idOrgao: number;
	cpf: string;
	descricaoServico: string;
	flagConfronto: string;
	descricaoDedosEnviados: string;
	idMotivo: number;
	observacao: string;
	numeroVia: number;
	numeroProcesso: string;
	totalizador: string;
}
