import produce from 'immer';

// UTILS
import { formatDate } from 'utils/genericFunctions';

// TYPES
import { Types, consultaMudancaCategoria } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaMudancaCategoria = {
	status: 0,
	data: null,
};

export default function mudancaCategoria(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaMudancaCategoria {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_MUDANCA_CATEGORIA: {
				break;
			}

			case Types.CONSULTA_MUDANCA_CATEGORIA_SUCCESS: {
				const mudanca = { ...action.payload.data.dadosMudanca[0] };

				if (mudanca.dataNascimento !== '') {
					mudanca.dataNascimento = formatDate(mudanca.dataNascimento);
				}

				draft.data = mudanca;
				break;
			}

			case Types.CONSULTA_MUDANCA_CATEGORIA_FAILURE: {
				break;
			}

			default:
		}
	});
}
