import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DARE } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultarStatusDareSuccess,
	consultarStatusDareFailure,
	consultarStatusDareClear,
	IRequestStatusDare,
} from './actions';

function* consultarStatusDareRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestStatusDare } = request;

	yield put(consultarStatusDareClear());

	const response: ApiResponse = yield call(
		postApi,
		PATH_DARE,
		`dare/${CHANNEL}/consulta/status-dare/${payload.rg}`,
		{},
	);

	if (response.status === 200) {
		yield put(consultarStatusDareSuccess(response.status, response.data));
	} else {
		yield put(consultarStatusDareFailure(204, null));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_STATUS_DARE_REQUEST, consultarStatusDareRequest),
]);
