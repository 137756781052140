// import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_TIPOS_LOGRADOUROS_REQUEST = '@BOLSA-POVO/CONSULTAR_TIPOS_LOGRADOUROS_REQUEST',
	CONSULTAR_TIPOS_LOGRADOUROS_SUCCESS = '@BOLSA-POVO/CONSULTAR_TIPOS_LOGRADOUROS_SUCCESS',
	CONSULTAR_TIPOS_LOGRADOUROS_FAILURE = '@BOLSA-POVO/CONSULTAR_TIPOS_LOGRADOUROS_FAILURE',
	LIMPAR_CONSULTAR_TIPOS_LOGRADOUROS = '@BOLSA-POVO/LIMPAR_CONSULTAR_TIPOS_LOGRADOUROS',
}

export interface ConsultarTiposLogradouros {
	status: number;
	data: TiposLogradouros | null;
}

export interface TiposLogradouros {
	valores: string[];
}
