import React from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';

// REDUX
// import { useSelector } from 'react-redux';
// import { ApplicationState } from 'store';
import dataCrv from 'store/modules/api/detranCrv/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import ValidDataInput from 'components/Common/Form/Input/ValidData';

// UTILS
import hasError from 'utils/getFormErrors';
import { onlyNumbers, onlyNumbersLettersSpace } from 'utils/genericFunctions';

// FORM
import { IEmiteTransferenciaVeiculo } from '../form';

interface Props {
	formik: FormikProps<IEmiteTransferenciaVeiculo>;
}

const Gravames: React.FC<Props> = ({ formik }) => {
	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section size="lg" title="Gravames" titleSize="sm">
						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Select}
									title="Restrição Financeira"
									titleSize="lg"
									name="restricao"
									options={dataCrv.restricaoFinanceira}
									onChange={(v: number) => {
										formik.setFieldValue('restricao', v);
									}}
									size={80}
									required
									error={hasError(formik.errors, 'restricao')}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Financeira"
									titleSize="lg"
									name="codigoFinanceira"
									size={80}
									maxLength={22}
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'codigoFinanceira',
											onlyNumbers(e.target.value),
										);
									}}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Input}
									title="Arrendatário/Financeira"
									titleSize="lg"
									name="arrendatarioFinanceira"
									size={92}
									maxLength={30}
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'arrendatarioFinanceira',
											onlyNumbersLettersSpace(e.target.value),
										);
									}}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Input}
									title="Número do Contrato"
									titleSize="lg"
									name="numeroContrato"
									size={80}
									maxLength={20}
									onChange={(e: { target: { value: string } }) => {
										formik.setFieldValue(
											'numeroContrato',
											onlyNumbers(e.target.value),
										);
									}}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={ValidDataInput}
									title="Data Vigência Contrato"
									titleSize="lg"
									name="dataVigencia"
									type="text"
									mask="99/99/9999"
									size={92}
									formik={formik}
									onChange={(v: string) =>
										formik.setFieldValue('dataVigencia', v)
									}
								/>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default Gravames;
