import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, consultarMunicipioCodigoProdesp } from './types';

export const INITIAL_STATE: consultarMunicipioCodigoProdesp = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultarMunicipioCodigoProdesp {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_MUNICIPIO_CODIGO_PRODESP_REQUEST: {
				draft.status = 100;
				break;
			}

			case Types.CONSULTAR_MUNICIPIO_CODIGO_PRODESP_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_MUNICIPIO_CODIGO_PRODESP_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.CONSULTAR_MUNICIPIO_CODIGO_PRODESP_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
