// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaFornecedorRequest, Types } from './types';

export function consultaFornecedorRequest(
	payload: ConsultaFornecedorRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_FORNECEDOR_REQUEST,
		payload,
	};
}
export function consultaFornecedorSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_FORNECEDOR_SUCCESS,
		payload,
	};
}
export function consultaFornecedorFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_FORNECEDOR_FAILURE,
		payload,
	};
}
export function consultaFornecedorClear(): ReducerAction {
	return {
		type: Types.CONSULTA_FORNECEDOR_CLEAR,
		payload: null,
	};
}
