import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarAcompanhamentoRequest } from './types';
import {
	consultarAcompanhamentoSuccess,
	consultarAcompanhamentoFailure,
} from './actions';

function* consultarAcompanhamentoRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultarAcompanhamentoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`acompanhamento/${CHANNEL}/veiculo`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarAcompanhamentoSuccess(response));
	} else if (response.error) {
		yield put(consultarAcompanhamentoFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_ACOMPANHAMENTO_REQUEST,
		consultarAcompanhamentoRequest,
	),
]);
