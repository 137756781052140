export enum Types {
	IMPRESSAO_INTENCAO_VENDA_REQUEST = '@DETRAN-CRV/IMPRESSAO_INTENCAO_VENDA_REQUEST',
	IMPRESSAO_INTENCAO_VENDA_SUCCESS = '@DETRAN-CRV/IMPRESSAO_INTENCAO_VENDA_SUCCESS',
	IMPRESSAO_INTENCAO_VENDA_FAILURE = '@DETRAN-CRV/IMPRESSAO_INTENCAO_VENDA_FAILURE',
	IMPRESSAO_INTENCAO_VENDA_CLEAR = '@DETRAN-CRV/IMPRESSAO_INTENCAO_VENDA_CLEAR',
}

export interface ImpressaoIntencaoVenda {
	status: number;
	data: ficha | null;
	dataFailure: null | string;
	instanceRequest: string | null;
	placa: string;
	renavam: string;
}

interface ficha {
	pdfAtpvBase64?: string;
	messages?: [string];
}

export interface ImpressaoIntencaoVendaRequest {
	placa: string;
	renavam: string;
	instanceRequest: string | null;
}
