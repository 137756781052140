import React from 'react';

import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ColorBox from 'components/Common/ColorBox';

import { RiAlertFill } from 'react-icons/ri';
import { AlertArea } from './styled';

interface AlertaUsuarioProps {
	diasVencimentoCnh: Number;
	nomeCondutor: string;
	validadeCnh: string;
	onClickProsseguirSegundaVia: () => void;
	onClickProsseguirRenovacao: () => void;
}

export function AlertaUsuarioCnhValidade({
	diasVencimentoCnh,
	nomeCondutor,
	validadeCnh,
	onClickProsseguirSegundaVia,
	onClickProsseguirRenovacao,
}: AlertaUsuarioProps) {
	return (
		<Section title="Status Condutor">
			<h3 style={{ textTransform: 'uppercase' }}>{nomeCondutor}</h3>
			<AlertArea>
				<ColorBox bgColor="vermelho" type="error">
					<span className="alert-area__title-box-area">
						<RiAlertFill color="#c33" size={25} />
						<h3>Atenção</h3>
					</span>
					<span className="alert-area__message-box-area">
						Validade desta CNH ={' '}
						<span className="message-box-area__strong-red"> {validadeCnh}</span>
						. Faltam
						<span className="message-box-area__strong-red">
							{' '}
							{diasVencimentoCnh}{' '}
						</span>{' '}
						dias para vencer a CNH.
					</span>
					<span className="alert-area__message-box-area">
						A renovação antecipada só deve ser{' '}
						<span className="message-box-area__red">
							solicitada antes dos 30 dias que antecedem o vencimento da CNH
						</span>
						<span className="message-box-area__strong-black">
							{' '}
							com motivo justificado.
						</span>
					</span>
					<span className="alert-area__message-box-area">
						<span className="message-box-area__strong-black">
							Exemplos: Condutor viajará para fora do país (apresenta
							comprovante, como passagem etc.);Condutor tinha em sua CNH
							restrição de lentes corretivas e informa ter feito cirurgia
							corretiva (na retirada, alteração ou exclusão de restrição
							médica).
						</span>
					</span>
					<span className="alert-area__message-box-area">
						<span className="message-box-area__red">Solicite </span>
						2a via da CNH que sairá com a mesma validade da atual, ou caso haja
						justificativa para continuidade do serviço de renovação, prossiga
						<span className="message-box-area__red"> com </span>o processo.
					</span>
					<div className="buttons-area">
						<ButtonImage
							type="button"
							src="btn-prosseguir-renovacao"
							onClick={onClickProsseguirRenovacao}
						/>
						<ButtonImage
							type="button"
							src="btn-prosseguir-segunda-via"
							onClick={onClickProsseguirSegundaVia}
						/>
					</div>
				</ColorBox>
			</AlertArea>
		</Section>
	);
}
