import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarStatusRequest } from './types';

export function atualizarStatusRequest(
	data: AtualizarStatusRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_STATUS,
		payload: data,
	};
}
export function atualizarStatusSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_STATUS_SUCCESS,
		payload,
	};
}
export function atualizarStatusFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_STATUS_FAILURE,
		payload: null,
	};
}
export function atualizarStatusClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_STATUS_CLEAR,
		payload: null,
	};
}
