import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Formik, Form, Field, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultarVeiculoV110Request,
	consultarVeiculoV110Clear,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import {
	consultarTaxaServicoClear,
	consultarTaxaServicoRequest,
} from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { ConsultarTaxaServicoRequest } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/types';
import { consultaCategoriaRequest } from 'store/modules/api/detranCrv/enum/consultaCategoria/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import Radio from 'components/Common/Form/Radio';
import Input from 'components/Common/Form/Input/Input';
import Placa from 'components/Common/Form/Fields/Placa';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Select from 'components/Common/Form/Select';
// import Error from 'components/Common/Notifications/FormError/index';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { onlyNumbersAndLetters } from 'utils/genericFunctions';

// ROUTES
import { ROUTE_DETRAN_CRV } from 'routes/paths';

// FORM
import {
	IAlteracaoModificacao,
	initialValues,
	schema,
	treatRequestSguStatistics,
} from './form';

const AlteracaoDadosCadastrais: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { consultarVeiculoV110 } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { consultarTaxaServico } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	const { consultaCategoria } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.Enum,
	);

	const [formData, setFormData] = useState(initialValues);
	const [categoriaOficial, setCategoriaOficial] = useState<string | number>('');

	useEffect(() => {
		dispatch(consultaCategoriaRequest());
	}, [dispatch]);

	useEffect(() => {
		if (
			consultarVeiculoV110.data &&
			categoriaOficial === 3 &&
			consultarTaxaServico.status === 0
		) {
			const payload = treatRequestSguStatistics(
				consultarVeiculoV110.data,
				user,
				formData,
			);
			dispatch(estatisticaIncluirRequest(payload));
			history.push('/detran-crv/alteracao-dados-cadastrais/emitir');
		} else if (
			consultarVeiculoV110.data &&
			categoriaOficial !== 3 &&
			consultarTaxaServico.status === 0
		) {
			const dataConsultarTaxaServico: ConsultarTaxaServicoRequest = {
				codigoTaxaServico: '6',
				identificadorTaxa:
					consultarVeiculoV110.data.caracteristicaVeiculo.renavam.toString() ||
					'',
				despachante: '',
			};

			dispatch(consultarTaxaServicoRequest(dataConsultarTaxaServico));
		}
	}, [
		consultarVeiculoV110,
		categoriaOficial,
		consultarTaxaServico.status,
		history,
		dispatch,
		user,
		formData,
	]);

	useEffect(() => {
		if (consultarTaxaServico.status === 200 && consultarVeiculoV110.data) {
			const payload = treatRequestSguStatistics(
				consultarVeiculoV110.data,
				user,
				formData,
			);
			dispatch(estatisticaIncluirRequest(payload));
			history.push('/detran-crv/alteracao-dados-cadastrais/emitir');
		}
	}, [
		consultarTaxaServico.status,
		consultarVeiculoV110.data,
		dispatch,
		formData,
		history,
		user,
	]);

	const handleValidate = useCallback((formValues: IAlteracaoModificacao) => {
		return getValidationsErrors(formValues, schema);
	}, []);

	const handleClear = useCallback(
		(formik?: FormikProps<IAlteracaoModificacao>) => {
			if (formik) {
				formik.handleReset();
			}
			dispatch(consultarVeiculoV110Clear());
			dispatch(consultarTaxaServicoClear());
			dispatch(clearNotifications());
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(formValues: IAlteracaoModificacao) => {
			handleClear();
			setFormData(formValues);
			const { chassi, placa } = formValues;

			dispatch(
				consultarVeiculoV110Request({
					chassi,
					placa,
					tipoConsulta: 'ALTERACAO_MODIFICACAO',
				}),
			);
		},
		[dispatch, handleClear],
	);

	return (
		<>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={handleValidate}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form autoComplete="off">
							<Row>
								<Col span={11} offset={5}>
									<Row gutter={[0, 10]}>
										<Col style={{ marginLeft: '132px' }}>
											<Field
												as={Radio}
												name="tipoPesquisa"
												options={[
													{ label: 'Chassi', value: 'Chassi' },
													{ label: 'Placa', value: 'Placa' },
												]}
												defaultValue={initialValues.tipoPesquisa}
												onChange={(e: RadioChangeEvent) => {
													const { value } = e.target;

													formik.setFieldValue('tipoPesquisa', value);
													formik.setFieldValue('chassi', '');
													formik.setFieldValue('placa', '');
												}}
											/>
										</Col>
									</Row>

									<Row>
										<Col span={24}>
											{formik.values.tipoPesquisa === 'Chassi' ? (
												<Field
													as={Input}
													title="Documento"
													name="chassi"
													maxLength={22}
													size={96}
													required
													onChange={(
														v: React.ChangeEvent<HTMLInputElement>,
													) => {
														formik.setFieldValue(
															'chassi',
															onlyNumbersAndLetters(v.target.value),
														);
													}}
													error={!!formik.errors.chassi}
												/>
											) : (
												<Placa
													title="Documento"
													name="placa"
													size={96}
													required
													formik={formik}
												/>
											)}
										</Col>
										<Col span={24}>
											<Field
												as={Select}
												title="Categoria"
												options={consultaCategoria.data}
												error={
													!!formik.values.categoria &&
													hasError(formik.errors, 'documentoCategoria')
												}
												name="categoria"
												onChange={(v: number) => {
													formik.setFieldValue('categoria', v);
													setCategoriaOficial(v);
													dispatch(consultarVeiculoV110Clear());
													dispatch(consultarTaxaServicoClear());
													// handleCategoria(v);
												}}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row gutter={[0, 20]}>
								<Col span={24} />
							</Row>

							<Row justify="center">
								<Col>
									<ButtonImage
										src="limpar"
										onClick={() => {
											handleClear(formik);
										}}
									/>
								</Col>

								<Col offset={1}>
									<ButtonImage type="submit" src="pesquisar" />
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</Section>

			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</>
	);
};

export default AlteracaoDadosCadastrais;
