import produce from 'immer';

// TYPES
import { Types, ConsultarMenus } from './types';
import { ReducerAction } from '../../../../../types';

export const INITIAL_STATE: ConsultarMenus = {
	status: 0,
	data: null,
	totalRegistrosConsulta: 0,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarMenus {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_MENUS: {
				break;
			}

			case Types.CONSULTAR_MENUS_SUCCESS: {
				draft.data = action.payload.data;
				draft.totalRegistrosConsulta = action.payload.headers['x-total-count'];
				break;
			}

			case Types.CONSULTAR_MENUS_FAILURE: {
				break;
			}

			case Types.CONSULTAR_MENUS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.totalRegistrosConsulta = INITIAL_STATE.totalRegistrosConsulta;
				break;
			}

			default:
		}
	});
}
