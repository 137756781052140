export enum Types {
	EXCLUIR_SUFIXO = '@sgu/EXCLUIR_SUFIXO',
	EXCLUIR_SUFIXO_SUCCESS = '@sgu/EXCLUIR_SUFIXO_SUCCESS',
	EXCLUIR_SUFIXO_FAILURE = '@sgu/EXCLUIR_SUFIXO_FAILURE',
	EXCLUIR_SUFIXO_CLEAR = '@sgu/EXCLUIR_SUFIXO_CLEAR',
}

export interface ExcluirSufixo {
	status: number;
	data: null | ExcluirSufixoData;
}

export interface ExcluirSufixoData {
	status: string;
	statusMensagem: string;
}

export interface ExcluirSufixoRequest {
	descricao: string | number;
}
