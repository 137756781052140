export enum Types {
	ATUALIZACAO_CONSUMIDOR_REQUEST = '@procon/ATUALIZACAO_CONSUMIDOR_REQUEST',
	ATUALIZACAO_CONSUMIDOR_SUCCESS = '@procon/ATUALIZACAO_CONSUMIDOR_SUCCESS',
	ATUALIZACAO_CONSUMIDOR_FAILURE = '@procon/ATUALIZACAO_CONSUMIDOR_FAILURE',
	ATUALIZACAO_CONSUMIDOR_CLEAR = '@procon/ATUALIZACAO_CONSUMIDOR_CLEAR',
}

export interface AtualizacaoConsumidor {
	status: null | number;
	data: null | AtualizacaoConsumidorData;
}

interface AtualizacaoConsumidorData {
	mensagem: string;
	data: {
		id: number;
	};
}

export interface AtualizacaoConsumidorRequest {
	consumidorId: string;
	consumidorAtualizacao: {
		nomeCompleto?: string;
		cpf?: string;
		rg?: string | null;
		orgaoEmissorDoRg?: string | null;
		ufEmissor?: number | string | null;
		dataDeNascimento?: string;
		sexo?: number | string;
		pessoaComDeficiencia?: boolean;
		bairro?: string;
		cep?: string;
		logradouro?: string;
		numero?: string;
		complemento?: string;
		municipioId?: string;
		estado?: number | string;
		telefoneCelular?: string | null;
		email?: string;
	};
}
