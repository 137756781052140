import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// ANTD
import { Col, Row } from 'antd';

// COMPONENTS COMMON
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import CPF from 'components/Common/Form/Fields/CPF';
import TypographyCommon from 'components/Common/Typography';

// REDUX
import { ApplicationState } from 'store';
import {
	ConsultaExtratoResumoCondutorForm,
	ConsultaExtratoResumoCondutorRequest,
	IConsultaResumoEventoRequest,
} from 'store/modules/api/detranCnh/eportal/consultaExtratoResumoCondutor/types';
import {
	consultaExtratoResumoCondutorClear,
	consultaExtratoResumoCondutorRequest,
} from 'store/modules/api/detranCnh/eportal/consultaExtratoResumoCondutor/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import { limparMascara } from 'utils/genericFunctions';

// PATHS
import { ROUTE_DETRAN_CNH_CONSULTA_EXTRATO_RESUMO_CONDUTOR } from 'pages/DetranCnh/routes/paths';
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// FORM
import { Formik, Form, Field, FormikProps } from 'formik';
import { initalValues, schema } from './form';

const PesquisaExtratoResumoCondutor: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const stylePaddingCols = { padding: '0 8px 0 0' };

	const [disabled, setDisabled] = useState<boolean>(false);

	const consultaExtratoResumoCondutor = useSelector(
		(state: ApplicationState) =>
			state.api.detranCnh.eportal.consultaExtratoResumoCondutor,
	);

	const loginDataMainframe = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login.mainframe,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.salvarAtendimento.data,
	);

	const loginUnico = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const handleSubmit = useCallback(
		(values: ConsultaExtratoResumoCondutorForm) => {
			setDisabled(true);

			const evento: IConsultaResumoEventoRequest = {
				// id_orgão: 338,
				id_servico: 192,
				desc_servico: 'consulta resumo extrato cnh',
				tipo: 'detran_cnh_resumo_extrato',
				id_atendimento: dadosAtendimento?.idAtendimento || '',
				id_cidadao: dadosAtendimento?.idCidadao || '',
				cpf:
					dadosAtendimento?.cpf ||
					limparMascara(loginUnico.login.user.cpf) ||
					'',
				identificacao: dadosAtendimento?.idAtendimento || '',
				ip: loginUnico.login.user.ip || '',
				canal: {
					id: loginUnico.login.user.idCanal,
					desc: loginUnico.login.user.descCanal,
					estacao: {
						id: Number(loginUnico.login.user.posto),
						desc: loginUnico.login.user.nomePosto,
					},
					localidade: {
						id: Number(loginUnico.login.user.posto),
						desc: loginUnico.login.user.nomePosto,
					},
				},
			};

			const payload: ConsultaExtratoResumoCondutorRequest = {
				pgu: '',
				cpf: limparMascara(values.cpf),
				numeroRg: '',
				numeroRegistro: '',
				nome: '',
				dataNascimento: '',
				usuario: loginDataMainframe.idUsuario,
				senha: loginDataMainframe.senhaMainFrame,
				evento,
			};

			dispatch(consultaExtratoResumoCondutorRequest(payload));
		},
		[dispatch, loginDataMainframe, dadosAtendimento, loginUnico],
	);

	const handleClear = useCallback(
		(formik: FormikProps<ConsultaExtratoResumoCondutorForm>) => {
			formik.resetForm();

			dispatch(consultaExtratoResumoCondutorClear());

			dispatch(clearNotifications());

			setDisabled(false);
		},
		[dispatch],
	);

	useEffect(() => {
		if (consultaExtratoResumoCondutor.status === 200) {
			history.push(ROUTE_DETRAN_CNH_CONSULTA_EXTRATO_RESUMO_CONDUTOR, {
				consultaExtratoResumoCondutor,
			});

			dispatch(consultaExtratoResumoCondutorClear());
		}
	}, [consultaExtratoResumoCondutor, history, dispatch]);

	return (
		<>
			<Row gutter={[0, 0]} align="middle" justify="start">
				<Col span={24}>
					<TypographyCommon
						text="Pesquisar"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>

			<Section
				bgColor="cinza-escuro"
				style={{
					border: '1px grey solid',
					borderRadius: '4px',
					marginTop: '10px',
				}}
			>
				<Row gutter={[0, 0]}>
					<Col span={24}>
						<TypographyCommon
							text="Digite o CPF do cidadão para verificar se existe o cadastro:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initalValues}
							validate={values => getValidationsErrors(values, schema)}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row gutter={[0, 20]} align="middle" justify="start">
										<Col span={30}>
											<div style={stylePaddingCols}>
												<Field
													as={CPF}
													title="CPF"
													numero="cpf"
													formik={formik}
													titleSize="auto"
													disabled={disabled}
													error={hasError(formik.errors, 'cpf')}
												/>
											</div>
										</Col>

										<Col>
											<div style={stylePaddingCols}>
												<ButtonImage type="submit" src="pesquisar" />
											</div>
										</Col>

										<Col>
											<div style={stylePaddingCols}>
												<ButtonImage
													type="reset"
													src="limpar"
													onClick={() => handleClear(formik)}
													disabled={formik.values.cpf === ''}
												/>
											</div>
										</Col>
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Section>

			<Row justify="space-between" align="middle">
				<Col>
					<ButtonVoltar route={ROUTE_DETRAN_CNH} />
				</Col>
			</Row>
		</>
	);
};
export default PesquisaExtratoResumoCondutor;
