// ROUTES
import { ROUTE_POUPATEMPO } from 'routes/paths';

// HOME
export const ROUTE_POUPATEMPO_RECUPERAR_ATENDIMENTO = `${ROUTE_POUPATEMPO}/recuperar-atendimento`;
export const ROUTE_POUPATEMPO_JUSTIFICATIVA_ATENDIMENTO = `${ROUTE_POUPATEMPO}/recuperar-atendimento/justificativa`;

// SGU
export const ROUTE_POUPATEMPO_SGU = `${ROUTE_POUPATEMPO}/sgu`;

// CONTABILIZAÇÃO
export const ROUTE_POUPATEMPO_CONTABILIZACAO = `${ROUTE_POUPATEMPO}/contabilizacao`;

// SERVIÇOS MUNICIPAIS
export const ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS = `${ROUTE_POUPATEMPO}/servicos-municipais`;

export const ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PARAMETROS = `${ROUTE_POUPATEMPO}/servicos-municipais/parametros`;
export const ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PARAMETROS_ADD = `${ROUTE_POUPATEMPO}/servicos-municipais/parametros/add`;
export const ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PARAMETROS_CODIGO = `${ROUTE_POUPATEMPO}/servicos-municipais/parametros/`;

export const ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_SERVICOS = `${ROUTE_POUPATEMPO}/servicos-municipais/servicos`;
export const ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_SERVICOS_ADD = `${ROUTE_POUPATEMPO}/servicos-municipais/servicos/add`;
export const ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_SERVICOS_CODIGO = `${ROUTE_POUPATEMPO}/servicos-municipais/servicos/`;

export const ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PREFEITURAS = `${ROUTE_POUPATEMPO}/servicos-municipais/prefeituras`;
export const ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PREFEITURAS_ADD = `${ROUTE_POUPATEMPO}/servicos-municipais/prefeituras/add`;
export const ROUTE_POUPATEMPO_SERVICOS_MUNICIPAIS_PREFEITURAS_CODIGO = `${ROUTE_POUPATEMPO}/servicos-municipais/prefeituras/`;
