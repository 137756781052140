import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultaRecilagem } from './types';

export function consultaReciclagemRequest(
	data: IRequestConsultaRecilagem,
): ReducerAction {
	return {
		type: Types.CONSULTA_RECICLAGEM,
		payload: data,
	};
}
export function consultaReciclagemSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_RECICLAGEM_SUCCESS,
		payload,
	};
}
export function consultaReciclagemFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_RECICLAGEM_FAILURE,
		payload: null,
	};
}
