import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { AlterarRenachComRegistroRequest, Types } from './types';

// ACTIONS
import {
	alterarRenachComRegistroSuccess,
	alterarRenachComRegistroFailure,
} from './actions';

function* alterarRenachComRegistro(request: ReducerAction) {
	const { payload }: { payload: AlterarRenachComRegistroRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`sockets/${CHANNEL}/altera-renach/com-registro`,
		payload,
	);

	if (response.status === 200) {
		yield put(alterarRenachComRegistroSuccess(response));
	} else {
		yield put(alterarRenachComRegistroFailure(response));
	}
}

export default all([
	takeLatest(
		Types.ALTERAR_RENACH_COM_REGISTRO_REQUEST,
		alterarRenachComRegistro,
	),
]);
