import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_ORGAOS_REQUEST = '@SGU/CONSULTAR_ORGAOS_REQUEST',
	CONSULTAR_ORGAOS_SUCCESS = '@SGU/CONSULTAR_ORGAOS_SUCCESS',
	CONSULTAR_ORGAOS_FAILURE = '@SGU/CONSULTAR_ORGAOS_FAILURE',
	CONSULTAR_ORGAOS_CLEAR = '@SGU/CONSULTAR_ORGAOS_CLEAR',
}

export interface ConsultarOrgao {
	status: number;
	data: Orgao[] | null;
	enum: OptionProps[];
	totalRegistrosConsulta: number;
}

export interface Orgao {
	codigo?: string | number;
	descricao: string;
	codigoSefaz: string | number;
	mainframe: boolean;
	flHabilitado: boolean;
}

export interface ConsultarOrgaosRequest {
	registroInicial?: number;
	limite?: number;
	current?: number;
	codigo?: string | number;
	codigoSefaz?: string | number;
	descricao?: string;
	flHabilitado?: boolean;
}
