import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarUsuarioMainframeRequest } from './types';

export function cadastrarUsuarioMainframeRequest(
	data: CadastrarUsuarioMainframeRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_USUARIO_MAINFRAME_REQUEST,
		payload: data,
	};
}
export function cadastrarUsuarioMainframeSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRAR_USUARIO_MAINFRAME_SUCCESS,
		payload,
	};
}
export function cadastrarUsuarioMainframeFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_USUARIO_MAINFRAME_FAILURE,
		payload: null,
	};
}
export function cadastrarUsuarioMainframeClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_USUARIO_MAINFRAME_CLEAR,
		payload: null,
	};
}
