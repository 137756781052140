import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarParametroSistemaRedisRequest } from './types';

export function cadastrarParametroSistemaRedisRequest(
	data: CadastrarParametroSistemaRedisRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_PARAMETRO_SISTEMA_REDIS,
		payload: data,
	};
}
export function cadastrarParametroSistemaRedisSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRAR_PARAMETRO_SISTEMA_REDIS_SUCCESS,
		payload,
	};
}
export function cadastrarParametroSistemaRedisFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_PARAMETRO_SISTEMA_REDIS_FAILURE,
		payload: null,
	};
}
export function cadastrarParametroSistemaRedisClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_PARAMETRO_SISTEMA_REDIS_CLEAR,
		payload: null,
	};
}
