import { lazy } from 'react';

// PATHS
import {
	ROUTE_SGU_ADMIN_FUNCIONALIDADE_CADASTRAR,
	ROUTE_SGU_ADMIN_FUNCIONALIDADE_EDITAR,
} from './paths';

// COMPONENTS
const LocalidadesFormData = lazy(() => import('../FormData'));

export const SGURoute = {
	name: 'Poupatempo - SGU',
	route: '/poupatempo',
};

const funcionalidadesRoutes = [
	{
		path: ROUTE_SGU_ADMIN_FUNCIONALIDADE_CADASTRAR,
		breadcrumb: [
			SGURoute,
			{
				name: 'Funcionalidades - Cadastro ',
			},
		],
		Component: LocalidadesFormData,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SGU_ADMIN_FUNCIONALIDADE_EDITAR,
		breadcrumb: [
			SGURoute,
			{
				name: 'Funcionalidades - Editar ',
			},
		],
		Component: LocalidadesFormData,
		isAuthenticated: true,
		exact: true,
	},
];

export default funcionalidadesRoutes;
