// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaProcessoAdministrativoRequest, Types } from './types';

export function consultaProcessoAdministrativoRequest(
	payload: ConsultaProcessoAdministrativoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_PROCESSO_ADMINISTRATIVO_REQUEST,
		payload,
	};
}
export function consultaProcessoAdministrativoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_PROCESSO_ADMINISTRATIVO_SUCCESS,
		payload,
	};
}
export function consultaProcessoAdministrativoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_PROCESSO_ADMINISTRATIVO_FAILURE,
		payload,
	};
}
export function consultaProcessoAdministrativoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_PROCESSO_ADMINISTRATIVO_CLEAR,
		payload: null,
	};
}
