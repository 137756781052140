import { ReducerAction } from 'store/modules/types';

import { Types, AtualizarMunicipioRequest } from './types';

export function atualizarMunicipioRequest(
	data: AtualizarMunicipioRequest,
): ReducerAction {
	return {
		type: Types.ATUALIZAR_MUNICIPIO_REQUEST,
		payload: data,
	};
}
export function atualizarMunicipioSuccess(payload: object): ReducerAction {
	return {
		type: Types.ATUALIZAR_MUNICIPIO_SUCCESS,
		payload,
	};
}
export function atualizarMunicipioFailure(): ReducerAction {
	return {
		type: Types.ATUALIZAR_MUNICIPIO_FAILURE,
		payload: null,
	};
}
export function atualizarMunicipioClear(): ReducerAction {
	return {
		type: Types.ATUALIZAR_MUNICIPIO_CLEAR,
		payload: null,
	};
}
