import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_CIDADES_UF_REQUEST = '@cnh/CONSULTA_CIDADES_UF_REQUEST',
	CONSULTA_CIDADES_UF_SUCCESS = '@cnh/CONSULTA_CIDADES_UF_SUCCESS',
	CONSULTA_CIDADES_UF_FAILURE = '@cnh/CONSULTA_CIDADES_UF_FAILURE',
	CONSULTA_CIDADES_UF_CLEAR = '@cnh/CONSULTA_CIDADES_UF_CLEAR',
}

export interface ConsultaCidadesUFState {
	status: number;
	data: OptionProps[];
}
