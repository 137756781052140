// TYPES
import { ReducerAction } from 'store/modules/types';
import { ReducerActionRotaSP } from 'store/modules/types';
import { IFormConsultarSolicitante } from 'pages/cdhu/Solicitante/Pesquisar/form';
import { ConsultarSolicitanteData, Types } from './types';

export function tipoVinculoSolicitante(
	payload: number | string,
): ReducerAction {
	return {
		type: Types.TIPO_VINCULO_SOLICITANTE,
		payload,
	};
}

export function consultarSolicitanteRequest(
	token: string,
	payload: IFormConsultarSolicitante,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_SOLICITANTE_REQUEST,
		token,
		payload,
	};
}
export function consultarSolicitanteSuccess(
	payload: ConsultarSolicitanteData,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_SOLICITANTE_SUCCESS,
		payload,
	};
}
export function consultarSolicitanteFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_SOLICITANTE_FAILURE,
		payload: null,
	};
}
export function consultarSolicitanteClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_SOLICITANTE_CLEAR,
		payload: null,
	};
}
