import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Modal, Row } from 'antd';

import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
import ButtonVoltar from 'components/Common/ButtonVoltar';

import eloam from 'assets/images/button/btn_logo_eloam.png';
import czur from 'assets/images/button/btn_logo_czur.png';

import { getBackPathMenu } from 'utils/genericFunctions';
import ScannerEloam from '../Eloam';
import ScannerCzur from '../Czur';

const MenuScanner: React.FC<{ voltar?: boolean }> = ({ voltar = true }) => {
	const history = useHistory();

	const [modelo, setModelo] = useState('');
	const [modalScanner, setModalScanner] = useState(false);

	useEffect(() => {}, []);

	return (
		<>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<h2>Selecione o modelo de scanner</h2>
				</Col>
			</Row>
			<Section>
				<Row gutter={[10, 20]} style={{}} key="sectionRow">
					<Col
						span={12}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Button
							type="button"
							onClick={() => {
								setModalScanner(true);
								setModelo('eloam');
							}}
							style={{
								height: '100px',
								width: '100px',
								border: 'none',
								backgroundColor: 'transparent',
								background: 'none',
								boxShadow: 'none',
							}}
						>
							<img src={eloam} alt="Eloam" style={{}} />
						</Button>
					</Col>
					<Col
						span={12}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Button
							type="button"
							onClick={() => {
								setModalScanner(true);
								setModelo('czur');
							}}
							style={{
								height: '100px',
								width: '100px',
								border: 'none',
								backgroundColor: 'transparent',
								background: 'none',
								boxShadow: 'none',
							}}
						>
							<img src={czur} alt="Czur" style={{ userSelect: 'none' }} />
						</Button>
					</Col>
				</Row>
			</Section>
			{voltar && (
				<ButtonVoltar
					navigate={false}
					onClick={() =>
						history.push(getBackPathMenu(history.location.pathname))
					}
				/>
			)}
			{modalScanner && (
				<Modal
					title="Digitalização de Documentos"
					visible={modalScanner}
					footer={[]}
					width="90%"
					style={{ top: '2px' }}
					// onOk={handleDelete}
					onCancel={() => setModalScanner(false)}
				>
					{modelo === 'eloam' && (
						<ScannerEloam setModalScanner={setModalScanner} />
					)}
					{modelo === 'czur' && (
						<ScannerCzur setModalScanner={setModalScanner} />
					)}
				</Modal>
			)}
		</>
	);
};

export default MenuScanner;
