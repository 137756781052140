import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Col, Row } from 'antd';
import { Formik, Form, Field, FormikProps } from 'formik';

import Section from 'components/Common/Section';

import { ApplicationState } from 'store';
import {
	cadastraNotasFiscais,
	clearRedirectNotasFiscaisVivaLeite,
} from 'store/modules/api/seds/vivaLeite/notasFiscais/actions';
import enumData from 'store/modules/api/seds/enum';

import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Select from 'components/Common/Form/Select';
import InputMask from 'components/Common/Form/Input/InputMask';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

import Padrao from '../components/pages/Padrao';

import {
	InitialValues,
	schema,
	IFormNotasFiscais,
	InitialIncluirNotasFiscais,
} from './form';

export interface IDataMotivo {
	codigo_motivo: number;
	outros_motivos?: string;
}

export interface IOutrosMotivos {
	outros_motivos: string[];
}

const EnvioNotasFiscais = () => {
	const { redirectUrlNotasFiscais } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.notasFiscais,
	);
	const { selectedUser } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.usuario,
	);

	const dispatch = useDispatch();
	const history = useHistory();

	const handleSubmit = useCallback(
		(value: IFormNotasFiscais) => {
			let danfes =
				value.danfes?.filter(
					(n: string | number) => n !== undefined && n !== '',
				) || [];

			danfes = danfes.map(n => Number(n));

			const requestData = InitialIncluirNotasFiscais;
			requestData.num_pan = selectedUser.numeroPan;
			requestData.mes = Number(value.mes);
			requestData.ano = Number(value.ano);
			requestData.danfes = danfes as number[];

			dispatch(cadastraNotasFiscais(requestData));
		},
		[dispatch, selectedUser.numeroPan],
	);

	const handleOnBlur = useCallback(
		(item: number, formik: FormikProps<IFormNotasFiscais>) => {
			let numberDanfe = formik.values.danfes[item] as string;

			numberDanfe = numberDanfe.replace(/[_]+/g, '');

			if (numberDanfe.length < 9) {
				formik.setFieldValue(`danfes[${item}]`, '');
			}
		},
		[],
	);

	useEffect(() => {
		if (redirectUrlNotasFiscais === 'imprimirDocumento') {
			dispatch(clearRedirectNotasFiscaisVivaLeite());
			history.push('/seds/vivaleite/prestacaoDeContas/imprimirDocumento');
		}
	}, [dispatch, history, redirectUrlNotasFiscais, selectedUser.numeroPan]);

	return (
		<Padrao>
			<>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={InitialValues}
							validate={values => getValidationsErrors(values, schema)}
							onSubmit={handleSubmit}
						>
							{formik => {
								return (
									<Form>
										<Section
											size="sm"
											titleBold={false}
											title="ENVIO DE NOTAS FISCAIS (Nº DANFES)"
										>
											<Row gutter={[0, 10]}>
												<Col span={10}>
													<Row gutter={[0, 10]}>
														<Col span={24}>
															<Field
																title="Mês de Referência"
																name="mes"
																as={Select}
																options={enumData.mes}
																onChange={(v: number) =>
																	formik.setFieldValue('mes', v)
																}
																error={hasError(formik.errors, 'mes')}
															/>
														</Col>
													</Row>
													<Row gutter={[0, 10]}>
														<Col span={24}>
															<Field
																title="Ano Referência"
																name="ano"
																as={Select}
																options={enumData.anoReferencia}
																onChange={(v: number) =>
																	formik.setFieldValue('ano', v)
																}
																error={hasError(formik.errors, 'ano')}
															/>
														</Col>
													</Row>
												</Col>

												<Col span={14}>
													<Row gutter={[0, 10]}>
														<Col span={12}>
															<Row gutter={[0, 10]}>
																<Col span={24}>
																	<Field
																		title="1º"
																		name="danfes[0]"
																		as={InputMask}
																		mask="999999999"
																		onBlur={() => handleOnBlur(0, formik)}
																	/>
																</Col>
															</Row>

															<Row gutter={[0, 10]}>
																<Col span={24}>
																	<Field
																		title="2º"
																		name="danfes[1]"
																		as={InputMask}
																		mask="999999999"
																		onBlur={() => handleOnBlur(1, formik)}
																	/>
																</Col>
															</Row>

															<Row gutter={[0, 10]}>
																<Col span={24}>
																	<Field
																		title="3º"
																		name="danfes[2]"
																		as={InputMask}
																		mask="999999999"
																		onBlur={() => handleOnBlur(2, formik)}
																	/>
																</Col>
															</Row>

															<Row gutter={[0, 10]}>
																<Col span={24}>
																	<Field
																		title="4º"
																		name="danfes[3]"
																		as={InputMask}
																		mask="999999999"
																		onBlur={() => handleOnBlur(3, formik)}
																	/>
																</Col>
															</Row>

															<Row gutter={[0, 10]}>
																<Col span={24}>
																	<Field
																		title="5º"
																		name="danfes[4]"
																		as={InputMask}
																		mask="999999999"
																		onBlur={() => handleOnBlur(4, formik)}
																	/>
																</Col>
															</Row>

															<Row gutter={[0, 10]}>
																<Col span={24}>
																	<Field
																		title="6º"
																		name="danfes[5]"
																		as={InputMask}
																		mask="999999999"
																		onBlur={() => handleOnBlur(5, formik)}
																	/>
																</Col>
															</Row>

															<Row gutter={[0, 10]}>
																<Col span={24}>
																	<Field
																		title="7º"
																		name="danfes[6]"
																		as={InputMask}
																		mask="999999999"
																		onBlur={() => handleOnBlur(6, formik)}
																	/>
																</Col>
															</Row>
														</Col>
													</Row>
												</Col>
											</Row>
										</Section>

										<Row
											gutter={[0, 10]}
											justify="space-between"
											align="bottom"
										>
											<Col>
												<ButtonVoltar />
											</Col>
											<Col>
												<ButtonImage src="prosseguir" type="submit" />
											</Col>
										</Row>
									</Form>
								);
							}}
						</Formik>
					</Col>
				</Row>
			</>
		</Padrao>
	);
};

export default EnvioNotasFiscais;
