import { IFormConsultarSolicitante } from 'pages/cdhu/Solicitante/Pesquisar/form';

export enum Types {
	TIPO_VINCULO_SOLICITANTE = '@cdhu/TIPO_VINCULO_SOLICITANTE',

	CONSULTAR_SOLICITANTE_REQUEST = '@cdhu/CONSULTAR_SOLICITANTE_REQUEST',
	CONSULTAR_SOLICITANTE_SUCCESS = '@cdhu/CONSULTAR_SOLICITANTE_SUCCESS',
	CONSULTAR_SOLICITANTE_FAILURE = '@cdhu/CONSULTAR_SOLICITANTE_FAILURE',
	CONSULTAR_SOLICITANTE_CLEAR = '@cdhu/CONSULTAR_SOLICITANTE_CLEAR',
}

export interface ConsultarSolicitante {
	tipoVinculo: number | string | null;
	status: number;
	data: ConsultarSolicitanteData | null;
	form: IFormConsultarSolicitante | null;
}

export interface ConsultarSolicitanteData {
	dataCriacao: string; // yyyy-MM-ddTHH:mm:ssZ,
	dataModificacao: string; // yyyy-MM-ddTHH:mm:ssZ,
	id: string;
	logicalName: string;
	nome: string;
	bloqueio: true,
	cep: string;
	cpf: string;
	cpfConfirmadoPorDocumento: string;
	cpfConjuge: string;
	cpfConjugeAtual: string;
	cidade: string;
	cidadeAtual: string;
	dataNascimento: string; // yyyy-MM-ddTHH:mm:ssZ,
	dataNascimentoConjuge: string; // yyyy-MM-ddTHH:mm:ssZ,
	dataNascimentoConjugeAtual: string; // yyyy-MM-ddTHH:mm:ssZ,
	deficiencia: string;
	descricao: string;
	email: string; // XXXX@XXXX.XXX,
	estadoCivil: string;
	estadoCivilAtual: string;
	finalizarEntrevista: true,
	finalizarProjeto: true,
	frequentaEscola: string;
	grauDeInstrucao: string;
	idade: number;
	mesmoEstadoCivil: true,
	nisPisPasep: string;
	nOrdem: string;
	nacionalidade: string;
	nacionalidadeConjuge: string;
	nacionalidadeConjugeAtual: string;
	nomeConjuge: string;
	nomeConjugeAtual: string;
	nomeDaMae: string;
	primeiroNome: string;
	profissaoCBO: string;
	profissaoDoConjugeAtualCBO: string;
	profissaoDoConjugeCBO: string;
	profissaoSistemaLegado: string;
	rg: string;
	rgConjuge: string;
	rgConjugeAtual: string;
	rgRne: string;
	regimeDeBens: string;
	regimeDeBensAtual: string;
	relacaoParentesco: string;
	sexo: string;
	sistemaDeOrigem: string;
	sobrenome: string;
	telefoneCelular: string; // (XX)XXXXX-XXXX
	telefoneComercial: string; // (XX)XXXXX-XXXX
	telefoneDeContato: string; // (XX)XXXXX-XXXX
	telefoneResidencial: string; // (XX)XXXXX-XXXX
	ufDeNascimento: string;
	ufRg: string;
	ufRgConjuge: string;
	ufRgConjugeAtual: string;
	usoPermanente: string;
	mensagemField: string;
	statusField: number;
}

export interface ConsultarSolicitanteRequest {
	cpf: String;
}
