import React, { ChangeEvent, useState, useEffect, useCallback } from 'react';
import { Field, Form, FormikProps } from 'formik';
import { Col, Row } from 'antd';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Radio from 'components/Common/Form/Radio';
import Select from 'components/Common/Form/Select';
// import TaxaServico from 'pages/DetranCrv/components/pages/TaxaServico';

// UTILS
import hasError from 'utils/getFormErrors';
import dataCrv from 'store/modules/api/detranCrv/enum';
import { onlyNumbers, onlyNumbersLettersSpace } from 'utils/genericFunctions';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { consultarVeiculoV110Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import { consultarVeiculoV120Clear } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV120/actions';
import { consultarRestricaoVeiculoClear } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarRestricaoVeiculo/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCrv/enum/consultaCategoria/actions';
import { consultarTaxaServicoClear } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/actions';

// FORM
import { ITransferenciaMunicipioEstado } from 'pages/DetranCrv/SolicitarDocumentos/TransferenciaMunicipioEstado/Consultar/form';

interface PesquisaPadraoCrvProps {
	formik: FormikProps<any>;
	setNotificationErrors: (v: string[]) => void;
	notificationErrors: string[];
	handleSubmit?: (v: ITransferenciaMunicipioEstado) => void;
	segundaVia?: boolean;
	primeiroEmplacamento?: boolean;
	setCategoriaOficial?: (v: string | number) => void;
}

const PesquisaPadraoCrv: React.FC<PesquisaPadraoCrvProps> = ({
	formik,
	setNotificationErrors,
	notificationErrors = [],
	segundaVia = false,
	primeiroEmplacamento = false,
	setCategoriaOficial = () => {},
}) => {
	const dispatch = useDispatch();

	const { consultaCategoria } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.Enum,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	const { restricaoFinanceira } = dataCrv;

	const [lenght, setLenght] = useState<number>(22);

	useEffect(() => {
		dispatch(consultaCategoriaRequest());
	}, [dispatch]);

	const Options = [
		{
			value: 'chassi',
			label: 'Chassi',
		},
		{
			value: 'placa',
			label: 'Placa',
		},
	];

	const handleChange = useCallback((value: string) => {
		switch (value) {
			case 'chassi':
				setLenght(22);
				break;
			case 'placa':
				setLenght(7);
				break;

			default:
				break;
		}
	}, []);

	// const handleOnBlur = useCallback(() => {
	// 	const { documento, categoria } = formik.values;
	// 	setNotificationErrors([]);
	// 	if (documento !== '' && documento.length >= 11 && categoria !== 3) {
	// 		const dataConsultarTaxaServico: ConsultarTaxaServicoRequest = {
	// 			codigoTaxaServico: '1',
	// 			identificadorTaxa: documento,
	// 			despachante: '',
	// 		};

	// 		dispatch(consultarTaxaServicoRequest(dataConsultarTaxaServico));
	// 	} else {
	// 		setNotificationErrors(['Número do documento inválido']);
	// 	}
	// }, [dispatch, formik.values, setNotificationErrors]);

	// const handleCategoria = useCallback((v: number) => {
	// 	setIsCategoriaOficial(v === 3);
	// }, []);

	const handleClearConsultas = useCallback(() => {
		dispatch(clearNotifications());
		setNotificationErrors([]);
		dispatch(consultarVeiculoV110Clear());
		dispatch(consultarVeiculoV120Clear());
		dispatch(consultarRestricaoVeiculoClear());
		dispatch(consultarTaxaServicoClear());
	}, [dispatch, setNotificationErrors]);

	const handleClear = useCallback(() => {
		if (formik) {
			formik.handleReset();
		}
		dispatch(clearNotifications());
		setNotificationErrors([]);
		dispatch(consultarVeiculoV110Clear());
		dispatch(consultarVeiculoV120Clear());
		dispatch(consultarRestricaoVeiculoClear());
		dispatch(consultarTaxaServicoClear());
	}, [dispatch, formik, setNotificationErrors]);

	return (
		<Form>
			<Row>
				<Col span={20} offset={2}>
					{!primeiroEmplacamento && (
						<Row gutter={[0, 20]}>
							<Col style={{ marginLeft: '170px' }}>
								<Field
									name="selecao"
									as={Radio}
									options={Options}
									defaultValue="chassi"
									error={hasError(formik.errors, 'selecao')}
									onChange={(event: ChangeEvent<HTMLInputElement>) => {
										const { value } = event.target;
										formik.handleReset();
										formik.setFieldValue('selecao', value);
										handleChange(value);
										if (notificationErrors.length > 0) {
											setNotificationErrors([]);
										}
										if (errors.length > 0) {
											dispatch(clearNotifications());
										}
									}}
								/>
							</Col>
						</Row>
					)}

					<Row>
						<Col span={12}>
							<Field
								title="Documento"
								titleSize="lg"
								maxLength={lenght}
								name={primeiroEmplacamento ? 'chassi' : formik.values.selecao}
								as={Input}
								size={96}
								required
								onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
									formik.setFieldValue(
										`${formik.values.selecao}`,
										onlyNumbersLettersSpace(v.target.value),
									);
									if (notificationErrors.length > 0) {
										setNotificationErrors([]);
									}
									if (errors.length > 0) {
										dispatch(clearNotifications());
									}
								}}
								error={hasError(formik.errors, 'placaRenavamChassi')}
							/>
						</Col>
						<Col span={12}>
							<Field
								as={Select}
								title="Categoria"
								options={consultaCategoria.data}
								error={
									!!formik.values.categoria &&
									hasError(formik.errors, 'documentoCategoria')
								}
								name="categoria"
								onChange={(v: number) => {
									formik.setFieldValue('categoria', v);
									setCategoriaOficial(v);
									handleClearConsultas();
									if (notificationErrors.length > 0) {
										setNotificationErrors([]);
									}
									if (errors.length > 0) {
										dispatch(clearNotifications());
									}
									// handleCategoria(v);
								}}
							/>
						</Col>
					</Row>

					<Row>
						<Col span={12}>
							<Field
								titleSize="lg"
								as={Select}
								size={96}
								defaultValue="NADA_CONSTA"
								name="restricaoFinanceira"
								title="Informe o Tipo de Restrição"
								subtitleColor="red"
								options={restricaoFinanceira}
								error={!!formik.errors.restricaoFinanceira}
								onChange={(v: string) => {
									formik.setFieldValue('restricaoFinanceira', v);
									if (notificationErrors.length > 0) {
										setNotificationErrors([]);
									}
									if (errors.length > 0) {
										dispatch(clearNotifications());
									}
								}}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<Field
								titleSize="lg"
								maxLength={14}
								name="documento"
								title="CPF/CNPJ Proprietário"
								onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
									formik.setFieldValue(
										'documento',
										onlyNumbers(v.target.value),
									);
									if (notificationErrors.length > 0) {
										setNotificationErrors([]);
									}
									if (errors.length > 0) {
										dispatch(clearNotifications());
									}
								}}
								// onBlur={handleOnBlur}
								size={96}
								as={Input}
								error={
									!!formik.values.documento &&
									hasError(formik.errors, 'documentoCategoria')
								}
							/>
						</Col>
					</Row>
					{!segundaVia && !primeiroEmplacamento && (
						<Row>
							<Col span={12}>
								<Field
									titleSize="lg"
									maxLength={12}
									name="espelho"
									title="Numero do Espelho"
									onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
										formik.setFieldValue(
											'espelho',
											onlyNumbers(v.target.value),
										);
										if (notificationErrors.length > 0) {
											setNotificationErrors([]);
										}
										if (errors.length > 0) {
											dispatch(clearNotifications());
										}
									}}
									size={96}
									as={Input}
									error={hasError(formik.errors, 'espelho')}
								/>
							</Col>
						</Row>
					)}
				</Col>
			</Row>

			{/* <TaxaServico dataConsultarTaxaServico={consultarTaxaServico.data} /> */}

			<Row
				style={{ marginTop: '20px' }}
				justify="center"
				align="top"
				gutter={[20, 10]}
			>
				<Col>
					<ButtonImage
						type="button"
						src="limpar"
						onClick={() => {
							handleClear();
						}}
					/>
				</Col>
				<Col>
					<ButtonImage type="submit" src="pesquisar" />
				</Col>
			</Row>
		</Form>
	);
};

export default PesquisaPadraoCrv;
