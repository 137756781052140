import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaRecilagem } from './types';

// ACTIONS
import {
	consultaReciclagemSuccess,
	consultaReciclagemFailure,
} from './actions';

function* consultaReciclagem(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaRecilagem } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`condutor/${CHANNEL}/consulta-reciclagem`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaReciclagemSuccess(response));
	} else if (response.error) {
		yield put(consultaReciclagemFailure());
	}
}

export default all([takeLatest(Types.CONSULTA_RECICLAGEM, consultaReciclagem)]);
