import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_DOCUMENTOS = '@vivaLeite/CONSULTA_DOCUMENTOS',
	CONSULTA_DOCUMENTOS_SUCCESS = '@vivaLeite/CONSULTA_DOCUMENTOS_SUCCESS',
	CONSULTA_DOCUMENTOS_FAILURE = '@vivaLeite/CONSULTA_DOCUMENTOS_FAILURE',

	CADASTRA_DOCUMENTO = '@vivaLeite/CADASTRA_DOCUMENTO',
	CADASTRA_DOCUMENTO_SUCCESS = '@vivaLeite/CADASTRA_DOCUMENTO_SUCCESS',
	CADASTRA_DOCUMENTO_FAILURE = '@vivaLeite/CADASTRA_DOCUMENTO_FAILURE',

	CONSULTA_STATUS_DOCUMENTOS = '@vivaLeite/CONSULTA_STATUS_DOCUMENTOS',
	CONSULTA_STATUS_DOCUMENTOS_SUCCESS = '@vivaLeite/CONSULTA_STATUS_DOCUMENTOS_SUCCESS',
	CONSULTA_STATUS_DOCUMENTOS_FAILURE = '@vivaLeite/CONSULTA_STATUS_DOCUMENTOS_FAILURE',

	CONSULTA_TERMO_ADVERTENCIA = '@vivaLeite/CONSULTA_TERMO_ADVERTENCIA',
	CONSULTA_TERMO_ADVERTENCIA_SUCCESS = '@vivaLeite/CONSULTA_TERMO_ADVERTENCIA_SUCCESS',
	CONSULTA_TERMO_ADVERTENCIA_FAILURE = '@vivaLeite/CONSULTA_TERMO_ADVERTENCIA_FAILURE',

	IMPRIME_TERMO_ADVERTENCIA = '@vivaLeite/IMPRIME_TERMO_ADVERTENCIA',
	IMPRIME_TERMO_ADVERTENCIA_SUCCESS = '@vivaLeite/IMPRIME_TERMO_ADVERTENCIA_SUCCESS',
	IMPRIME_TERMO_ADVERTENCIA_FAILURE = '@vivaLeite/IMPRIME_TERMO_ADVERTENCIA_FAILURE',

	CADASTRA_TERMO_ADVERTENCIA = '@vivaLeite/CADASTRA_TERMO_ADVERTENCIA',
	CADASTRA_TERMO_ADVERTENCIA_SUCCESS = '@vivaLeite/CADASTRA_TERMO_ADVERTENCIA_SUCCESS',
	CADASTRA_TERMO_ADVERTENCIA_FAILURE = '@vivaLeite/CADASTRA_TERMO_ADVERTENCIA_FAILURE',

	CONSULTA_IMPRESSAO_DOCUMENTOS = '@vivaLeite/CONSULTA_IMPRESSAO_DOCUMENTOS',
	CONSULTA_IMPRESSAO_DOCUMENTOS_SUCCESS = '@vivaLeite/CONSULTA_IMPRESSAO_DOCUMENTOS_SUCCESS',
	CONSULTA_IMPRESSAO_DOCUMENTOS_FAILURE = '@vivaLeite/CONSULTA_IMPRESSAO_DOCUMENTOS_FAILURE',

	CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS = '@vivaLeite/CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS',
	CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS_SUCCESS = '@vivaLeite/CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS_SUCCESS',
	CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS_FAILURE = '@vivaLeite/CONSULTA_EFETIVA_IMPRESSAO_DOCUMENTOS_FAILURE',

	NOTIFICATION_DOCUMENTOS_VIVA_LEITE_CLEAR = '@vivaLeite/NOTIFICATION_DOCUMENTOS_VIVA_LEITE_CLEAR',

	PDF_TERMO_ADVERTENCIA_CLEAR = '@vivaLeite/PDF_TERMO_ADVERTENCIA_CLEAR',

	PDF_IMPRIMIR_CLEAR = '@vivaLeite/PDF_IMPRIMIR_CLEAR',
}

export interface IDocumentosState {
	statusTransacaoDocumentos: string;
	selectListaDocumento: OptionProps[];
	listaStatusDocumentos: IStatusDocumentos[];
	termoAdvertencia: ITermoAdvertencia;
	pdfTermoAdvertencia: string;
	selectImpressaoDocumentos: OptionProps[];
	pdfImpressao: string;
}

export interface IStatusDocumentos {
	data_envio: string;
	descricao_documento: string;
	status: string;
	motivo_indeferimento: string;
}

interface ITermoAdvertencia {
	codigo_ciencia: number;
	titulo: string;
	introducao: string;
	pendencias: string[];
	texto_ciencia: string;
}

export interface IRequestDocumentos {
	servico: string;
	origem: string;
	etapa: string;
}

export interface IRequestTypeDocumento {
	servico: string;
	origem: string;
	etapa: string;
	num_pan: number;
}

export interface IRequestCadastraDocumento extends IRequestTypeDocumento {
	codigo_documento: number | string;
	outro_documento: string;
	documento: string;
}

export interface IRequestStatusDocumentos {
	servico: string;
	origem: string;
	num_pan: number;
}

export interface IRequestTermoAdvertencia {
	servico: string;
	origem: string;
	num_pan: number;
	etapa: string;
	cpf: string;
}

export interface IRequestImpressao {
	servico: string;
	origem: string;
	num_pan: number;
	etapa: string;
	codigo_documento: number;
	cpf: string;
}

export interface IRequestCadastraTermoAdvertencia {
	servico: string;
	origem: string;
	num_pan: number;
	etapa: string;
	cod_termo_advertencia: number;
}

export interface IRequestImpressaoDocumentos {
	servico: string;
	origem: string;
	num_pan: number;
	etapa: string;
	codigo_documento?: number;
	cpf: string;
}
