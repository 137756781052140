import React, { Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Button from 'components/Common/Button';
import { consultarCadastroBinRenavamClear } from 'store/modules/api/detranCrv/ConsultarVeiculos/consultarCadastroBinRenavam/actions';
import { completaZeroEsquerda } from 'utils/genericFunctions';

const ConsultarCadastroBinRenavamResultado: React.FC = () => {
	const dispatch = useDispatch();
	const { dadosBinRenavam } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.consultarVeiculos.consultarCadastroBinRenavam.data,
	);
	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Row>
				<Col span={24}>
					<Section size="sm" title="IDENTIFICAÇÃO DO VEÍCULO NA BIN">
						<Row gutter={[0, 10]}>
							<Col span={4}>Placa</Col>
							<Col span={8}>
								<strong>{dadosBinRenavam.placa}</strong>
							</Col>
							<Col span={4}>Município</Col>
							<Col span={8}>
								<strong>{`${dadosBinRenavam.municipio.codigo} - ${dadosBinRenavam.municipio.nome}`}</strong>
							</Col>
							<Col span={4}>Chassi</Col>
							<Col span={8}>
								<strong>{dadosBinRenavam.chassi}</strong>
							</Col>
							<Col span={4}>UF</Col>
							<Col span={8}>
								<strong>{dadosBinRenavam.uf}</strong>
							</Col>
							<Col span={4}>Renavam</Col>
							<Col span={8}>
								<strong>
									{dadosBinRenavam.renavam
										? completaZeroEsquerda(dadosBinRenavam.renavam, 11)
										: ''}
								</strong>
							</Col>
							<Col span={4}>Nº do Motor</Col>
							<Col span={8}>
								<strong>{dadosBinRenavam.numeroMotor}</strong>
							</Col>
							<Col span={4}>Situação Veículo</Col>
							<Col span={8}>
								<strong>{dadosBinRenavam.situacaoVeiculo}</strong>
							</Col>
							<Col span={4}>Procedência Veículo</Col>
							<Col span={8}>
								<strong>{dadosBinRenavam.procedencia}</strong>
							</Col>
							{/* <Col span={4}>CPF/CNPJ Proprietário</Col>
							<Col span={8}>
								<strong>{dadosBinRenavam.cpfCnpjProprietario}</strong>
							</Col> */}
							<Col span={4} />
							<Col span={8}>
								<strong />
							</Col>
							<Col span={4}>CPF/CNPJ Faturado</Col>
							<Col span={8}>
								<strong>{dadosBinRenavam.cpfCnpjFaturado}</strong>
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="CARACTERÍSTICAS DO VEÍCULO">
						<Row gutter={[0, 10]}>
							<Col span={4}>Tipo</Col>
							<Col span={4}>
								<strong>
									{dadosBinRenavam.tipo.longId} -{' '}
									{dadosBinRenavam.tipo.descricao}
								</strong>
							</Col>
							<Col span={4}>Combustível</Col>
							<Col span={4}>
								<strong>
									{dadosBinRenavam.combustivel.longId} -{' '}
									{dadosBinRenavam.combustivel.descricao}
								</strong>
							</Col>
							<Col span={8} />

							<Col span={4}>Cor</Col>
							<Col span={4}>
								<strong>
									{dadosBinRenavam.cor.longId} - {dadosBinRenavam.cor.descricao}
								</strong>
							</Col>
							<Col span={4}>Marca</Col>
							<Col span={12}>
								<strong>
									{dadosBinRenavam.marca.longId} -{' '}
									{dadosBinRenavam.marca.descricao}
								</strong>
							</Col>

							<Col span={4}>Ano Modelo</Col>
							<Col span={4}>
								<strong>{dadosBinRenavam.anoModelo}</strong>
							</Col>
							<Col span={4}>Ano Fabr.</Col>
							<Col span={12}>
								<strong>{dadosBinRenavam.anoFabricacao}</strong>
							</Col>

							<Col span={4}>Espécie</Col>
							<Col span={4}>
								<strong>
									{dadosBinRenavam.especie.longId} -{' '}
									{dadosBinRenavam.especie.descricao}
								</strong>
							</Col>
							<Col span={4}>Capacidade Passageiro</Col>
							<Col span={12}>
								<strong>{dadosBinRenavam.capacidadePassageiro}</strong>
							</Col>

							<Col span={4}>Carroceria</Col>
							<Col span={4}>
								<strong>
									{dadosBinRenavam.carroceria.longId} -{' '}
									{dadosBinRenavam.carroceria.descricao}
								</strong>
							</Col>
							<Col span={4}>Cilindrada</Col>
							<Col span={4}>
								<strong>{dadosBinRenavam.cilindrada} CC</strong>
							</Col>
							<Col span={4}>Potência</Col>
							<Col span={4}>
								<strong>{dadosBinRenavam.potencia} CV</strong>
							</Col>

							<Col span={4}>Capacidade Carga</Col>
							<Col span={4}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 1,
									}).format(dadosBinRenavam.capacidadeCarga)}
								</strong>
							</Col>
							<Col span={4}>Eixos</Col>
							<Col span={4}>
								<strong>{dadosBinRenavam.eixo}</strong>
							</Col>
							<Col span={2}>CMT</Col>
							<Col span={2}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 1,
									}).format(dadosBinRenavam.cmt)}
								</strong>
							</Col>
							<Col span={2}>PBT</Col>
							<Col span={2}>
								<strong>
									{Intl.NumberFormat('en-IN', {
										style: 'decimal',
										minimumFractionDigits: 1,
									}).format(dadosBinRenavam.pbt)}
								</strong>
							</Col>
							<Col span={4}>Tipo Remarcação de Chassi</Col>
							<Col span={4}>
								<strong>{dadosBinRenavam.tipoRemarcacao}</strong>
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="RESTRIÇÕES">
						<Row>
							<Col span={4}>Restrição</Col>
							<Col span={20}>
								<strong>{dadosBinRenavam.restricoes}</strong>
							</Col>
						</Row>
					</Section>
					<Section size="sm" title="GRAVAMES">
						<Row gutter={[0, 10]}>
							<Col span={6}>Tipo Transação</Col>
							<Col span={6}>
								<strong>{dadosBinRenavam.gravame.tipoTransacao}</strong>
							</Col>
							<Col span={6}>Restrição Financeira</Col>
							<Col span={6}>
								<strong>{dadosBinRenavam.gravame.restricao}</strong>
							</Col>
							<Col span={6}>Agente Financeiro</Col>
							<Col span={6}>
								<strong>{dadosBinRenavam.gravame.agenteFinanceiro}</strong>
							</Col>

							<Col span={6} />
							<Col span={6}>
								<strong />
							</Col>
							<Col span={6}>Nome Financiado</Col>
							<Col span={6}>
								<strong>{dadosBinRenavam.gravame.nomeFinanciado}</strong>
							</Col>
							<Col span={6} />
							<Col span={6}>
								<strong />
							</Col>
							<Col span={6}>CNPJ/CPF Financ</Col>
							<Col span={6}>
								<strong>
									{dadosBinRenavam.gravame.cpfCnpjFinanciado
										? dadosBinRenavam.gravame.cpfCnpjFinanciado
										: ''}
								</strong>
							</Col>
							<Col span={6}>Nº do Contrato</Col>
							<Col span={6}>
								<strong>{dadosBinRenavam.gravame.numeroContrato}</strong>
							</Col>
							<Col span={6}>Data Inclusão</Col>
							<Col span={6}>
								<strong>
									{dadosBinRenavam.gravame.dataInclusao &&
										new Date(
											dadosBinRenavam.gravame.dataInclusao,
										).toLocaleDateString('pt-BR')}
								</strong>
							</Col>
							<Col span={6}>Data Vigência</Col>
							<Col span={6}>
								<strong>
									{dadosBinRenavam.gravame.dataVigencia &&
										new Date(
											dadosBinRenavam.gravame.dataVigencia,
										).toLocaleDateString('pt-BR')}
								</strong>
							</Col>
						</Row>
					</Section>
					<Row justify="space-around" align="middle">
						<Col>
							<ButtonVoltar
								onClick={() => dispatch(consultarCadastroBinRenavamClear())}
							/>
						</Col>
						<Col>
							<Button className="no-print" onClick={() => window.print()}>
								{' '}
								Imprimir
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Suspense>
	);
};

export default ConsultarCadastroBinRenavamResultado;
