import styled from 'styled-components';

export const ContainerBolsaPovo = styled.div`
	.ant-col {
		/* .ant-radio-inner {
			font-size: calc(0.75rem + 0%);
		} */
		> div {
			flex-direction: column;
			margin-right: 10px;

			label {
				justify-content: flex-start;
			}
		}
	}
`;

export const ContainerButtons = styled.div`
	.ant-row {
		justify-content: center;
	}
`;
