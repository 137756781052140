import styled, { css } from 'styled-components';

interface TitleProps {
	required: boolean;
	size: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
}

interface BoxProps {
	size?: number;
	isErrored: boolean;
	position: 'column' | 'row';
}

export const Container = styled.div`
	display: flex;
	align-items: center;

	& + div {
		margin-top: 5px;
	}
`;

export const Title = styled.div<TitleProps>`
	margin-right: 10px;
	width: auto;

	${props =>
		props.size === 'sm' &&
		css`
			min-width: 80px;
		`};

	${props =>
		props.size === 'md' &&
		css`
			min-width: 120px;
		`};

	${props =>
		props.size === 'lg' &&
		css`
			min-width: 160px;
		`};

	${props =>
		props.size === 'xl' &&
		css`
			min-width: 200px;
		`};

	${props =>
		props.size === 'xl' &&
		css`
			min-width: auto;
		`};

	${props =>
		!!Number(props.size) &&
		css`
			min-width: ${`${props.size}px`};
		`};

	label {
		align-items: center;
		display: flex;
		font-size: calc(0.75rem + ${props => props.theme.fontScale}%);
		font-weight: 500;
		justify-content: flex-end;

		${props =>
			props.required &&
			css`
				::before {
					content: '*';
					color: ${props.theme.typography.error};
					font-size: calc(1rem + ${props.theme.fontScale}%);
					font-weight: 700;
					left: -4px;
					position: relative;
				}
			`};
	}
`;

export const Content = styled.div`
	width: 100%;
`;

export const Box = styled.div<BoxProps>`
	display: flex;
	width: 100%;

	item-name.ant-upload-list-item-name-icon-count-1 {
		max-width: calc(100% - 120px);
	}

	> span {
		max-width: 181.33px;
		width: 100%;

		${props =>
			props.position === 'row' &&
			css`
				display: flex;
			`};

		> .ant-upload.ant-upload-select.ant-upload-select-text {
			width: 100%;

			${props =>
				props.isErrored &&
				css`
					border: 1px solid rgb(204, 51, 51);
					box-shadow: 0px 1px 1px 0px rgb(204, 51, 51) inset;
				`};

			button {
				width: 100%;
			}
		}

		> .ant-upload-list.ant-upload-list-text {
			width: 100%;
		}
	}
`;

export const SubTitle = styled.div`
	align-items: center;
	display: flex;
	max-width: fit-content;
	width: 100%;

	span {
		font-size: calc(0.71rem + ${props => props.theme.fontScale}%);
		margin: auto 0px auto 10px;
	}
`;

export const Hint = styled.div`
	color: red;
	font-size: calc(0.68rem + ${props => props.theme.fontScale}%);
	margin-left: 1px;
`;
