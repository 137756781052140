import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	consultarParametrosSistemaRedisSuccess,
	consultarParametrosSistemaRedisFailure,
} from './actions';

function* consultarParametrosSistemaRedis() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`parametros-sistema-redis`,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarParametrosSistemaRedisSuccess(response));
	} else {
		yield put(consultarParametrosSistemaRedisFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_PARAMETROS_SISTEMA_REDIS,
		consultarParametrosSistemaRedis,
	),
]);
