import * as Yup from 'yup';

// REDUX
import { CancelarSolicitacaoEmissaoPIDRequest } from 'store/modules/api/detranCnh/solicitacaoEmissaoPID/cancelarSolicitacao/types';

// UTILS
import { limparMascara, validaCpf } from 'utils/genericFunctions';
import { treatEventoRequestData } from 'pages/DetranCnh/utils/threatServicesRequest';

// TYPES
import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';

export interface CancelarSolicitacaoEmissaoPIDForm {
	cpf: string;
	numeroRegistro: string;
}

export const initalValues: CancelarSolicitacaoEmissaoPIDForm = {
	cpf: '',
	numeroRegistro: '',
};

export function treatCancelarSolicitacaoEmissaoPIDRequest(
	values: CancelarSolicitacaoEmissaoPIDForm,
	dadosAtendimento: SalvarAtendimento,
	user: IUser,
): CancelarSolicitacaoEmissaoPIDRequest {
	return {
		evento: treatEventoRequestData(values.cpf, dadosAtendimento, user) || '',
		cpfCondutor: limparMascara(values.cpf) || '',
		numRegistro: limparMascara(values.numeroRegistro) || '',
		tipoServico: '3',
	};
}

export const schema = Yup.object<CancelarSolicitacaoEmissaoPIDForm>().shape({
	cpf: Yup.string()
		.required('Campo Obrigatório: CPF.')
		.test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF digitado inválido.',
			test: value => validaCpf(value),
		}),
	numeroRegistro: Yup.string().required('Campo Obrigatório: N° Registro.'),
});
