import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { IRequestEmitirAutorizacaoPrevia, Types } from './types';

// ACTIONS
import {
	emitirAutorizacaoPreviaSuccess,
	emitirAutorizacaoPreviaFailure,
} from './actions';

function* emitirAutorizacaoPreviaRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestEmitirAutorizacaoPrevia } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`servicos/${CHANNEL}/emissao/autorizacoes-previas`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(emitirAutorizacaoPreviaSuccess(response.data));
	} else if (response.error) {
		yield put(emitirAutorizacaoPreviaFailure());
	}
}

export default all([
	takeLatest(
		Types.EMITIR_AUTORIZACAO_PREVIA_REQUEST,
		emitirAutorizacaoPreviaRequest,
	),
]);
