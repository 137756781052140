import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_IIRGD } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastroAutorizacaoEspecialRequest } from './types';

// ACTIONS
import {
	cadastroAutorizacaoEspecialSuccess,
	cadastroAutorizacaoEspecialFailure,
} from './actions';

function* cadastroAutorizacaoEspecialRequest(request: ReducerAction) {
	const { payload }: { payload: CadastroAutorizacaoEspecialRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_IIRGD,
		'CadastroAutorizacaoEspecial',
		payload,
	);

	if (response.status === 200) {
		yield put(
			cadastroAutorizacaoEspecialSuccess({ response, request: payload }),
		);
	} else if (response.error) {
		yield put(cadastroAutorizacaoEspecialFailure());
	}
}

export default all([
	takeLatest(
		Types.CADASTRO_AUTORIZACAO_ESPECIAL_REQUEST,
		cadastroAutorizacaoEspecialRequest,
	),
]);
