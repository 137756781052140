export enum Types {
	CANCELAR_AGENDAMENTO_PSICOLOGO_REQUEST = '@cnh/CANCELAR_AGENDAMENTO_PSICOLOGO_REQUEST',
	CANCELAR_AGENDAMENTO_PSICOLOGO_SUCCESS = '@cnh/CANCELAR_AGENDAMENTO_PSICOLOGO_SUCCESS',
	CANCELAR_AGENDAMENTO_PSICOLOGO_FAILURE = '@cnh/CANCELAR_AGENDAMENTO_PSICOLOGO_FAILURE',
	CANCELAR_AGENDAMENTO_PSICOLOGO_CLEAR = '@cnh/CANCELAR_AGENDAMENTO_PSICOLOGO_CLEAR',
}

export interface CancelarAgendamentoPsicologo {
	status: number;
	data: CancelarAgendamentoPsicologoData | null;
}

export interface CancelarAgendamentoPsicologoRequest {
	cpf: string;
	renach: string;
	usuario: 'PORTAL';
}

export interface CancelarAgendamentoPsicologoData {
	codigoRetorno: number;
	cpf: string;
	mensagem: string;
}
