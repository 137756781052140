import React from 'react';

// ANTD
import { Col, Row } from 'antd';

// COMPONENTS
import TypographyCommon from 'components/Common/Typography';

// PATHS
import Section from 'components/Common/Section';

// REDUX
import { ConsultaExtratoResumoCondutorData } from 'store/modules/api/detranCnh/eportal/consultaExtratoResumoCondutor/types';

// UTILS
import { isFilledValue } from 'utils/stringFunctions';
import { formatDate } from 'utils/genericFunctions';

interface Props {
	consultaExtratoResumoCondutor:
		| ConsultaExtratoResumoCondutorData['ptox']
		| null
		| undefined;
	mensagemPtox:
		| ConsultaExtratoResumoCondutorData['mensagemPtox']
		| null
		| undefined;
}

const ExameToxicologico: React.FC<Props> = ({
	consultaExtratoResumoCondutor = null,
	mensagemPtox = null,
}) => {
	return (
		<>
			<Section title="Exame Toxicológico (PTOX)">
				{consultaExtratoResumoCondutor && (
					<>
						<Row gutter={[0, 0]} align="top">
							<Col span={5}>
								<TypographyCommon
									text="Data da Coleta:"
									type="label"
									fontWeight="bold"
									uppercase
								/>
							</Col>
							<Col span={19}>
								<TypographyCommon
									text={isFilledValue(
										formatDate(consultaExtratoResumoCondutor?.dataColeta),
										'Data não informada',
									)}
									type="label"
								/>
							</Col>
						</Row>
						<Row gutter={[0, 0]} align="top">
							<Col span={5}>
								<TypographyCommon
									text="Data Limite para Utilização:"
									type="label"
									fontWeight="bold"
									uppercase
								/>
							</Col>
							<Col span={19}>
								<TypographyCommon
									text={isFilledValue(
										formatDate(
											consultaExtratoResumoCondutor?.dataLimiteUtilizacao,
										),
										'Data não informada',
									)}
									type="label"
								/>
							</Col>
						</Row>
						<Row gutter={[0, 0]} align="top">
							<Col span={5}>
								<TypographyCommon
									text="Data da Validade:"
									type="label"
									fontWeight="bold"
									uppercase
								/>
							</Col>
							<Col span={19}>
								<TypographyCommon
									text={isFilledValue(
										formatDate(consultaExtratoResumoCondutor?.dataValidade),
										`Data não informada`,
									)}
									type="label"
								/>
							</Col>
						</Row>
						<Row gutter={[0, 0]} align="top">
							<Col span={5}>
								<TypographyCommon
									text="Resultado:"
									type="label"
									fontWeight="bold"
									uppercase
								/>
							</Col>
							<Col span={19}>
								<TypographyCommon
									text={isFilledValue(consultaExtratoResumoCondutor?.resultado)}
									type="label"
								/>
							</Col>
						</Row>
					</>
				)}

				{mensagemPtox && (
					<Row gutter={[0, 0]} align="top">
						<Col span={24}>
							<TypographyCommon
								text={isFilledValue(mensagemPtox)}
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Col>
					</Row>
				)}
			</Section>
		</>
	);
};
export default ExameToxicologico;
