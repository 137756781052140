export enum Types {
	CANCELAR_AUTORIZACAO_PREVIA_REQUEST = '@DETRAN-CRV/CANCELAR_AUTORIZACAO_PREVIA_REQUEST',
	CANCELAR_AUTORIZACAO_PREVIA_SUCCESS = '@DETRAN-CRV/CANCELAR_AUTORIZACAO_PREVIA_SUCCESS',
	CANCELAR_AUTORIZACAO_PREVIA_FAILURE = '@DETRAN-CRV/CANCELAR_AUTORIZACAO_PREVIA_FAILURE',
	CANCELAR_AUTORIZACAO_PREVIA_CLEAR = '@DETRAN-CRV/CANCELAR_AUTORIZACAO_PREVIA_CLEAR',
}

export interface cancelarAutorizacaoPrevia {
	status: number;
	data: cancelar | null;
}

interface cancelar {
	resultadoCancelamentoAutorizacao: 'string';
}
