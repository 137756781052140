import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';
import {
	ROUTE_DETRAN_CNH_SEGUNDA_VIA_AGENDAMENTO_PSICOLOGICO,
	ROUTE_DETRAN_CNH_SEGUNDA_VIA_PESQUISA,
	ROUTE_DETRAN_CNH_SEGUNDA_VIA_RENACH,
} from 'pages/DetranCnh/routes/paths';
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// ANTD
import { Row, Col } from 'antd';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';

// COMPONENTS COMMON
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';

// COMPONENTS
import StatusCondutor from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor';
import LeituraDigitais from 'pages/DetranCnh/components/pages/LeituraDigitais';
import RedirecionamentoRenovacaoCNHVencidaSegundaVia from 'pages/DetranCnh/components/pages/RedirecionamentoCNHVencidaSegundaVia';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { GerarRenachForm } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { DadosCidadaoRequest } from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/types';
import {
	cadastroEnderecosClear,
	cadastroEnderecosRequest,
} from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/actions';
import { CadastroEnderecosRequest } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/types';
import {
	dadosCidadaoClear,
	dadosCidadaoRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/actions';
import {
	consultarInaptoClear,
	consultarInaptoRequest,
} from 'store/modules/api/detranCnh/sockets/consultarInapto/actions';
import {
	consultaSegundaViaReabilitacaoClear,
	consultaSegundaViaReabilitacaoRequest,
} from 'store/modules/api/detranCnh/reabilitacao/consultaSegundaViaReabilitacao/actions';
import {
	consultaNomeSocialFiliacaoClear,
	consultaNomeSocialFiliacaoRequest,
} from 'store/modules/api/detranCnh/condutor/consultaNomeSocialFiliacao/actions';
import {
	consultaPesquisaNomeSocialFiliacaoClear,
	consultaPesquisaNomeSocialFiliacaoRequest,
} from 'store/modules/api/detranCnh/IIRGDDetran/consultaPesquisaNomeSocialFiliacao/actions';
import {
	consultarDigitaisClear,
	consultarDigitaisRequest,
} from 'store/modules/api/utils/consultarDigitais/actions';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import { confrontarDigitaisCnhClear } from 'store/modules/api/utils/confrontarDigitaisCnh/actions';
import { capturarBiometriaClear } from 'store/modules/api/biometria/capturarBiometria/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCnh/consulta/categoria/actions';
import {
	estatisticaIncluirClear,
	estatisticaIncluirRequest,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { GerarRenachIntegradoRequest } from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/types';
import {
	gerarRenachIntegradoClear,
	gerarRenachIntegradoRequest,
} from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/actions';
import { consultaCidadesUFRequest } from 'store/modules/api/utils/consultaCidadesUF/actions';
import { saveGerarRenachRequest } from 'store/modules/api/detranCnh/sockets/saveGerarRenach/actions';
import {
	domainServiceNaturalidadesClear,
	domainServiceNaturalidadesRequest,
} from 'store/modules/api/sguService/domainService/naturalidade/actions';
import { addNotifications } from 'store/modules/app/notifications/actions';
import { dadosPreCadastroFormSaveRequest } from 'store/modules/api/detranCnh/utils/dadosPreCadastroFormSave/actions';
import { verificarVersaoServicoBiometriaClear } from 'store/modules/api/biometria/verificarVersaoServicoBiometria/actions';

// UTILS
import {
	threatConsultarDigitaisRequest,
	treatValuesCadastroEnderecoRequest,
} from 'pages/DetranCnh/utils/threatServicesRequest';
import {
	formatDate,
	getDaysToExpirate,
	limparMascara,
} from 'utils/genericFunctions';
import { hasUpdateInAttendencyModal } from 'pages/DetranCnh/utils/validations';
import { treatRequestSguStatisticsGerarRenachSegundaVia } from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';
import { getOriginOfSocialName } from 'pages/DetranCnh/utils/functions/socialName';
import { CNH_PROCESS_VALIDATION } from 'pages/DetranCnh/utils/constants/messages';
import { treatDataPreCadastroFormWithOrWithoutModalAttendency } from 'pages/DetranCnh/utils/functions/dataPreCadastroForm';
import { getEventoConsultar, SguData } from 'utils/eventBuilder';

// FORM
import {
	IConfirmacaoAtendimento,
	initialValues,
} from 'pages/DetranCnh/components/pages/ConfirmacaoAtendimento/form';
import { treatValuesGerarRenachIntegrado } from 'pages/DetranCnh/CnhDefinitiva/StatusCondutor/form';
import { IFormStatusCondutor } from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor/form';
import {
	initalValuesHabilitacao,
	initialValuesPreCadastro,
	schema,
	treatInitialValues,
	treatValues,
} from './form';

interface CustomizedLocationState {
	flagProcurador: string;
}

const StatusCondutorSegundaVia: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const historyState = history.location.state as CustomizedLocationState;

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { user, mainframe } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { consultarDigitais } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { consultarInapto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const { consultaEPortalSegundaVia } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.segundaVia,
	);

	const { gerarRenachIntegrado } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { cadastroEnderecos, consultaNomeSocialFiliacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { consultaPesquisaNomeSocialFiliacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.IIRGDDetran,
	);

	const { dadosCidadao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.divisaoEquitativa,
	);

	const { consultaNaturalidades } = useSelector(
		(state: ApplicationState) => state.api.sguService.domainService,
	);

	const { consultaSegundaViaReabilitacao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.reabilitacao,
	);

	const { dadosPreCadastroFormSave } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.utils,
	);

	// CONTABILIZAÇÃO
	const { sgu } = useSelector((state: ApplicationState) => state.api);

	const [modalAtendimento, setModalAtendimento] =
		useState<IConfirmacaoAtendimento>(initialValues);

	const [dadosPreCadastro, setDadosPreCadastro] = useState(
		initialValuesPreCadastro,
	);

	const [dadosHabilitacao, setDadosHabilitacao] = useState(
		initalValuesHabilitacao,
	);

	const [requestData, setRequestData] = useState<null | {
		endereco: CadastroEnderecosRequest;
		cidadao: DadosCidadaoRequest;
	}>(null);

	const [preCadastro, setPreCadastro] =
		useState<null | GerarRenachIntegradoRequest>(null);

	const [biometria, setBiometria] = useState<
		'SHOW' | 'SUCCESS' | 'ERROR' | null
	>(null);

	const [isValidateCnh, setIsValidateCnh] = useState(31);
	const [isRedirectToStatus, setIsRedirectToStatus] = useState(false);

	const saveDataPreCadastroForm = useCallback(
		(
			dataPreCadastro: GerarRenachForm,
			dataHabilitacao: IFormStatusCondutor,
			dataModalAttendency?: IConfirmacaoAtendimento,
		) => {
			// SALVA OS DADOS PREENCHIDOS NO FORM COM OU SEM O MODAL CONFIRMACAO DE ATENDIMENTO.
			dispatch(
				dadosPreCadastroFormSaveRequest({
					dataPreCadastro: treatDataPreCadastroFormWithOrWithoutModalAttendency(
						dataPreCadastro,
						dataModalAttendency,
					),
					dataPreCadastroHabilitacao: dataHabilitacao,
				}),
			);
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: GerarRenachForm) => {
			if (!values.codCiretranCondutor || !values.codCiretranHabilitacao) {
				addNotifications({
					errors: CNH_PROCESS_VALIDATION.CIRETRAN_INVALIDA,
				});
				return;
			}

			const sguData: SguData = {
				salvarAtendimento: sgu.atendimento.salvarAtendimento,
				login: sgu.loginUnico.login,
			};
			const evento = getEventoConsultar(sguData, values.cpf);

			const renach = treatValues(
				evento,
				values,
				user,
				dadosPreCadastro,
				(historyState?.flagProcurador && historyState.flagProcurador === 'S') ||
					dadosPreCadastroFormSave.dataPreCadastro?.flagProcurador === true
					? 'S'
					: 'N',
			);
			const endereco = treatValuesCadastroEnderecoRequest(values);

			const cidadao: DadosCidadaoRequest = {
				cidadao: {
					autoriza_envio_email: values.autorizaSMS === 'S',
					autoriza_envio_sms: values.autorizaEmail === 'S',
					cpf: Number(values.cpf),
					celular: limparMascara(values.celular).substring(2, 11),
					ddd_celular: limparMascara(values.celular).substring(0, 2),
					ddd_telefone: limparMascara(values.telefone).substring(0, 2),
					telefone: limparMascara(values.telefone).substring(2, 11),
					email: values.email || '',
					necessidade_veiculo_adaptado: false,
					nome: values.nomeSocial?.toUpperCase() || values.nome.toUpperCase(),
					pretende_atividade_remunerada: values.flagAtividadeRemunerada === 'S',
				},
			};

			saveDataPreCadastroForm(values, dadosHabilitacao);
			setDadosPreCadastro(values);
			setPreCadastro(renach);
			setRequestData({ endereco, cidadao });

			dispatch(saveGerarRenachRequest(renach));
			dispatch(gerarRenachIntegradoClear());

			if (
				!historyState?.flagProcurador ||
				(historyState?.flagProcurador && historyState.flagProcurador === 'N')
			) {
				setModalAtendimento({
					...modalAtendimento,
					isOpenModal: true,
				});
			} else {
				dispatch(cadastroEnderecosRequest(endereco));
			}
		},
		[
			sgu.atendimento,
			sgu.loginUnico,
			user,
			dadosPreCadastro,
			modalAtendimento,
			dispatch,
			historyState,
			dadosHabilitacao,
			saveDataPreCadastroForm,
			dadosPreCadastroFormSave.dataPreCadastro,
		],
	);

	useEffect(() => {
		if (
			consultaEPortalSegundaVia.data &&
			consultaEPortalSegundaVia.data.dataValidadeCnh
		) {
			const validaCnh = getDaysToExpirate(
				consultaEPortalSegundaVia.data.dataValidadeCnh,
			);
			setIsValidateCnh(validaCnh - 1);
		}
	}, [consultaEPortalSegundaVia.data]);

	// GET CATEGORIAS
	useEffect(() => {
		if (!dadosPreCadastroFormSave.dataPreCadastro?.cpf) {
			dispatch(consultaCategoriaRequest({ funcionalidade: 'mudanca' }));
			dispatch(cadastroEnderecosClear());
		}
	}, [dispatch, dadosPreCadastroFormSave]);

	// BUSCA CÓDIGO NATURALIDADE
	useEffect(() => {
		if (
			consultaEPortalSegundaVia.data &&
			consultaEPortalSegundaVia.data.cpf &&
			consultaSegundaViaReabilitacao.status === 0
		) {
			dispatch(
				consultaSegundaViaReabilitacaoRequest({
					cpfCidadao: consultaEPortalSegundaVia.data.cpf,
				}),
			);
		}
	}, [
		dispatch,
		consultaEPortalSegundaVia.data,
		consultaSegundaViaReabilitacao.status,
	]);

	// LEITURA DIGITAIS
	useEffect(() => {
		if (
			consultarDigitais?.data &&
			consultarDigitais?.status === 200 &&
			consultarDigitais?.data?.valida &&
			consultarDigitais?.data?.dadosBiometricos &&
			consultarDigitais?.data?.dadosBiometricos.length > 0
		) {
			setBiometria('SHOW');
		} else if (
			consultarDigitais?.status !== 0 &&
			consultarDigitais?.status !== 100 &&
			requestData !== null
		) {
			dispatch(cadastroEnderecosRequest(requestData.endereco));
		}
	}, [consultarDigitais, dispatch, requestData, setBiometria]);

	useEffect(() => {
		if (biometria === 'SUCCESS' && requestData !== null) {
			dispatch(cadastroEnderecosRequest(requestData.endereco));
		} else if (biometria === 'ERROR' && requestData !== null) {
			dispatch(cadastroEnderecosRequest(requestData.endereco));
		}
	}, [biometria, dispatch, requestData]);

	const handleAfterModal = useCallback(
		(dataModalAtendimento: IConfirmacaoAtendimento) => {
			if (requestData) {
				if (dadosPreCadastro) {
					saveDataPreCadastroForm(
						dadosPreCadastro,
						dadosHabilitacao,
						dataModalAtendimento,
					);
				}

				if (
					preCadastro &&
					preCadastro.gerarRenachBase &&
					!hasUpdateInAttendencyModal(dataModalAtendimento)
				) {
					const requestValues = threatConsultarDigitaisRequest(
						dadosAtendimento,
						dadosPreCadastro,
						user,
						'REUSO_CNH',
					);

					dispatch(consultarDigitaisRequest(requestValues));
				} else if (requestData !== null) {
					dispatch(cadastroEnderecosRequest(requestData.endereco));
				}
			}

			setModalAtendimento(dataModalAtendimento);
		},
		[
			requestData,
			dadosPreCadastro,
			preCadastro,
			dispatch,
			dadosHabilitacao,
			dadosAtendimento,
			user,
			saveDataPreCadastroForm,
		],
	);

	// CONSULTA DOMAIN SERVICES PARA VALIDAR UF DA NATURALIDADE PELO CÓDIGO IBGE
	useEffect(() => {
		if (
			consultaSegundaViaReabilitacao.data &&
			consultaSegundaViaReabilitacao.data.naturalidade &&
			consultaSegundaViaReabilitacao.data.nacionalidade?.toString() === '1' &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			dispatch(
				domainServiceNaturalidadesRequest({
					idIbge: consultaSegundaViaReabilitacao.data.naturalidade.trim(),
				}),
			);
		}
	}, [consultaSegundaViaReabilitacao.data, dispatch, dadosPreCadastroFormSave]);

	// GET COMBO DE NATURALIDADE DE ACORDO COM A UF DO IBGE
	useEffect(() => {
		if (
			consultaNaturalidades.status === 200 &&
			consultaNaturalidades.data &&
			consultaNaturalidades.data[0].municipioIBGE.uf &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			dispatch(
				consultaCidadesUFRequest(
					consultaNaturalidades.data[0].municipioIBGE.uf,
				),
			);
		}
	}, [
		consultaNaturalidades.status,
		consultaNaturalidades.data,
		dispatch,
		dadosPreCadastroFormSave,
	]);

	// GET CODIGO CIRETRAN
	const consultaCodCiretran = useCallback(async () => {
		const resDetranEndereco: ApiResponse = await getApi(
			PATH_DETRAN_CNH,
			`consulta/${CHANNEL}?cep=${consultaEPortalSegundaVia.data?.endereco.cep}`,
		);

		if (resDetranEndereco.status === 200) {
			const endereco = resDetranEndereco.data;

			setDadosPreCadastro(oldFormValues => ({
				...oldFormValues,
				codCiretranHabilitacao: endereco.codigoUnidadeTransito,
				codCiretranCondutor: endereco.codigoUnidadeTransito,
			}));
		}
	}, [consultaEPortalSegundaVia.data]);

	const clearInitialStatesWithoutNewCallServices = useCallback(() => {
		dispatch(cadastroEnderecosClear());
		dispatch(capturarBiometriaClear());
		dispatch(consultarDigitaisClear());
		dispatch(confrontarDigitaisCnhClear());
		dispatch(estatisticaIncluirClear());
		dispatch(consultarInaptoClear());
		dispatch(dadosCidadaoClear());
		dispatch(verificarVersaoServicoBiometriaClear());
	}, [dispatch]);

	// RECARREGA OS DADOS DO FORM COM OS DADOS SALVOS NA STORE.
	useEffect(() => {
		if (dadosPreCadastroFormSave.dataPreCadastro?.cpf) {
			setDadosPreCadastro(dadosPreCadastroFormSave.dataPreCadastro);
		}
	}, [dispatch, dadosPreCadastroFormSave]);

	// RECARREGA OS DADOS DO FORM COM OS DADOS SALVOS NA STORE.
	useEffect(() => {
		if (dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf) {
			setDadosHabilitacao(dadosPreCadastroFormSave.dataPreCadastroHabilitacao);
		}
	}, [dispatch, dadosPreCadastroFormSave]);

	useEffect(() => {
		clearInitialStatesWithoutNewCallServices();

		if (!dadosPreCadastroFormSave.dataPreCadastro?.cpf) {
			dispatch(consultaNomeSocialFiliacaoClear());
			dispatch(consultaPesquisaNomeSocialFiliacaoClear());
			dispatch(consultaSegundaViaReabilitacaoClear());
			dispatch(domainServiceNaturalidadesClear());
			setBiometria(null);
		}
	}, [
		dispatch,
		dadosPreCadastroFormSave,
		clearInitialStatesWithoutNewCallServices,
	]);

	// FORMATA DADOS DA CONSULTA PARA PREENCHER O FORMULÁRIO DE STATUS DO CONDUTOR
	useEffect(() => {
		if (
			consultaSegundaViaReabilitacao.data &&
			user &&
			!dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf
		) {
			const uf =
				(consultaNaturalidades.data &&
					consultaNaturalidades.data[0].municipioIBGE.uf) ||
				'';
			const codigoNaturalidade =
				(consultaNaturalidades.data &&
					consultaNaturalidades.data[0].municipioIBGE.id.toString()) ||
				'';
			const { dataPrimeiraHabilitacao } = consultaSegundaViaReabilitacao.data;

			const newFormValues = treatInitialValues(
				consultaEPortalSegundaVia.data,
				dataPrimeiraHabilitacao,
				codigoNaturalidade,
				user,
				uf,
				(historyState?.flagProcurador && historyState.flagProcurador === 'S') ||
					dadosPreCadastroFormSave.dataPreCadastro?.flagProcurador === true
					? 'S'
					: 'N',
			);

			setDadosPreCadastro(newFormValues.initialValuesPreCadastro);
			setDadosHabilitacao(newFormValues.initalValuesHabilitacao);
		}
	}, [
		consultaEPortalSegundaVia.data,
		consultaSegundaViaReabilitacao.data,
		consultaNaturalidades.data,
		user,
		dadosPreCadastroFormSave,
		historyState,
	]);

	useEffect(() => {
		if (
			consultaEPortalSegundaVia.data &&
			consultaEPortalSegundaVia.data.cpf &&
			mainframe &&
			dadosPreCadastro.cpf &&
			dadosPreCadastro.cpf !== '' &&
			!dadosPreCadastroFormSave.dataPreCadastro?.cpf
		) {
			dispatch(
				consultaNomeSocialFiliacaoRequest({
					cpf: consultaEPortalSegundaVia.data.cpf,
					usuario: mainframe.idUsuario,
					senha: mainframe.senhaMainFrame,
				}),
			);
		}
	}, [
		consultaEPortalSegundaVia.data,
		dadosPreCadastro.cpf,
		dispatch,
		mainframe,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			consultaNomeSocialFiliacao.status === 200 &&
			consultaNomeSocialFiliacao.data &&
			!dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf
		) {
			const { nomeSocial, filiacao1, filiacao2 } =
				consultaNomeSocialFiliacao.data;

			setDadosPreCadastro(oldFormValues => ({
				...oldFormValues,
				nomeSocial: nomeSocial?.trim() || '',
				paisAfetivos1: filiacao1?.trim() || '',
				paisAfetivos2: filiacao2?.trim() || '',
				origemNomeSocial: getOriginOfSocialName(
					consultaNomeSocialFiliacao,
					null,
				),
			}));
		}

		if (
			consultaEPortalSegundaVia.data?.cpf &&
			consultaPesquisaNomeSocialFiliacao.status === 0 &&
			((consultaNomeSocialFiliacao.status !== 0 &&
				consultaNomeSocialFiliacao.status !== 200) ||
				(consultaNomeSocialFiliacao.status === 200 &&
					!consultaNomeSocialFiliacao.data?.nomeSocial?.trim())) &&
			dadosPreCadastro.cpf &&
			dadosPreCadastro.cpf !== ''
		) {
			dispatch(
				consultaPesquisaNomeSocialFiliacaoRequest({
					usuario: mainframe.idUsuario,
					terminal: user.ip,
					cpf: consultaEPortalSegundaVia.data.cpf,
				}),
			);
		}
	}, [
		consultaEPortalSegundaVia.data,
		consultaNomeSocialFiliacao,
		consultaPesquisaNomeSocialFiliacao.status,
		dadosPreCadastro.cpf,
		dispatch,
		mainframe.idUsuario,
		user.ip,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			consultaPesquisaNomeSocialFiliacao.status === 200 &&
			consultaPesquisaNomeSocialFiliacao.data &&
			!dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf
		) {
			setDadosPreCadastro(oldData => ({
				...oldData,
				nomeSocial:
					consultaPesquisaNomeSocialFiliacao.data?.nomeSocial.trim() || '',
				origemNomeSocial: getOriginOfSocialName(
					null,
					consultaPesquisaNomeSocialFiliacao,
				),
			}));
		}
	}, [consultaPesquisaNomeSocialFiliacao, dadosPreCadastroFormSave]);

	useEffect(() => {
		if (
			consultaEPortalSegundaVia.data &&
			consultaEPortalSegundaVia.data.endereco?.cep &&
			dadosPreCadastro.cpf &&
			dadosPreCadastro.cpf !== '' &&
			!dadosPreCadastroFormSave.dataPreCadastroHabilitacao?.cpf
		) {
			consultaCodCiretran();
		}
	}, [
		consultaCodCiretran,
		consultaEPortalSegundaVia.data,
		dadosPreCadastro.cpf,
		dadosPreCadastroFormSave,
	]);

	useEffect(() => {
		if (
			cadastroEnderecos.status === 200 &&
			preCadastro !== null &&
			requestData !== null
		) {
			dispatch(dadosCidadaoRequest(requestData.cidadao));
			setBiometria(null);
		}
	}, [cadastroEnderecos.status, dispatch, preCadastro, requestData]);

	useEffect(() => {
		if (
			dadosCidadao.status === 200 &&
			consultaEPortalSegundaVia.data &&
			preCadastro?.candidato &&
			requestData !== null
		) {
			if (
				preCadastro.candidato.pretendeAtividadeRemunerada === 'S' &&
				!consultaEPortalSegundaVia.data?.flagAtividadeRemunerada
			) {
				const inapto = {
					cpf: requestData.cidadao.cidadao.cpf.toString(),
					tipoExame: 'PSICOLOGO',
					flagDeficienteFisico:
						requestData.cidadao.cidadao.necessidade_veiculo_adaptado,
				};

				dispatch(consultarInaptoRequest(inapto));
			} else if (
				preCadastro.candidato.cpf &&
				gerarRenachIntegrado.status === 0
			) {
				const payload = treatRequestSguStatisticsGerarRenachSegundaVia(
					user,
					dadosAtendimento,
					consultaEPortalSegundaVia.data,
					preCadastro,
					'SELECTED_FIELDS',
				);
				dispatch(estatisticaIncluirRequest(payload));

				const renachIntegradoFormated = treatValuesGerarRenachIntegrado(
					preCadastro,
					user.cpf,
				);

				dispatch(gerarRenachIntegradoRequest(renachIntegradoFormated));
			}
		}
	}, [
		consultaEPortalSegundaVia.data,
		dadosCidadao.status,
		dispatch,
		preCadastro,
		requestData,
		user,
		dadosAtendimento,
		gerarRenachIntegrado.status,
	]);

	useEffect(() => {
		if (consultarInapto.status !== 0 && consultarInapto.status !== 100) {
			dispatch(estatisticaIncluirClear());
			history.push(ROUTE_DETRAN_CNH_SEGUNDA_VIA_AGENDAMENTO_PSICOLOGICO);
		}
	}, [consultarInapto.status, dispatch, history]);

	// REGISTRA ESTATISTICA SGU APÓS GERAR O RENACH - NUMERO RENACH OU MSG ERRO.
	useEffect(() => {
		if (
			preCadastro &&
			consultaEPortalSegundaVia.status === 200 &&
			consultaEPortalSegundaVia.data &&
			gerarRenachIntegrado.status !== 0 &&
			gerarRenachIntegrado.status !== 100 &&
			(gerarRenachIntegrado.data || gerarRenachIntegrado.dataError)
		) {
			const payload = treatRequestSguStatisticsGerarRenachSegundaVia(
				user,
				dadosAtendimento,
				consultaEPortalSegundaVia.data,
				preCadastro,
				'GENERATED_RENACH',
				gerarRenachIntegrado,
			);
			dispatch(estatisticaIncluirRequest(payload));
		}
	}, [
		dadosAtendimento,
		dispatch,
		consultaEPortalSegundaVia.status,
		consultaEPortalSegundaVia.data,
		gerarRenachIntegrado,
		preCadastro,
		user,
	]);

	useEffect(() => {
		if (
			(gerarRenachIntegrado.status === 200 ||
				gerarRenachIntegrado.status === 201) &&
			gerarRenachIntegrado.data
		) {
			dispatch(estatisticaIncluirClear());
			history.push(ROUTE_DETRAN_CNH_SEGUNDA_VIA_RENACH);
		} else if (
			gerarRenachIntegrado.status !== 0 &&
			gerarRenachIntegrado.status !== 100 &&
			gerarRenachIntegrado.status !== 200 &&
			gerarRenachIntegrado.status !== 201
		) {
			clearInitialStatesWithoutNewCallServices();
		}
	}, [
		clearInitialStatesWithoutNewCallServices,
		dispatch,
		gerarRenachIntegrado.data,
		gerarRenachIntegrado.status,
		history,
	]);

	return (
		<>
			{consultaEPortalSegundaVia.data &&
				isValidateCnh <= 30 &&
				!isRedirectToStatus && (
					<RedirecionamentoRenovacaoCNHVencidaSegundaVia
						rengeVencimento={isValidateCnh}
						dateValidate={consultaEPortalSegundaVia.data.dataValidadeCnh}
						nomeCidadao={consultaEPortalSegundaVia.data.nome}
						setIsRedirectToStatus={setIsRedirectToStatus}
					/>
				)}

			{(isValidateCnh > 30 || isRedirectToStatus) && biometria !== 'SHOW' && (
				<StatusCondutor
					schema={schema}
					dadosHabilitacao={{
						...consultaEPortalSegundaVia.data,
						...dadosHabilitacao,
						listaCursos:
							consultaEPortalSegundaVia.data?.cursos?.map(curso => ({
								codigoCurso: curso.codigo,
								descricaoCurso: curso.descricao,
								dataValidade: curso.dataValidadade,
							})) || [],
						examePsicotecnicoDataExamePsicotecnico:
							formatDate(
								consultaEPortalSegundaVia.data?.dadosExamePsicotecnico.exame1,
							) || '',
						examePsicotecnicoLaudo:
							consultaEPortalSegundaVia.data?.dadosExamePsicotecnico.laudo1 ||
							'',
					}}
					dadosPreCadastro={dadosPreCadastro}
					setPreCadastro={setPreCadastro}
					modalAtendimento={modalAtendimento.isOpenModal}
					handleSubmit={handleSubmit}
					handleAfterModal={handleAfterModal}
				/>
			)}

			{biometria === 'SHOW' && (
				<LeituraDigitais
					setBiometria={setBiometria}
					cpf={dadosPreCadastro?.cpf || ''}
				/>
			)}

			<Row justify="space-between" align="middle">
				<Col>
					<ButtonVoltar
						navigate={false}
						onClick={() => {
							dispatch(detranCnhClear());
							history.push(ROUTE_DETRAN_CNH_SEGUNDA_VIA_PESQUISA);
						}}
					/>
				</Col>

				<Col>
					<ButtonImage
						type="button"
						src="outro-servico-detran"
						onClick={() => history.push(ROUTE_DETRAN_CNH)}
					/>
				</Col>
			</Row>
		</>
	);
};

export default StatusCondutorSegundaVia;
