import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestGerarPreCadastro } from './types';

// ACTIONS
import { gerarPreCadastroSuccess, gerarPreCadastroFailure } from './actions';

function* gerarPreCadastro(request: ReducerAction) {
	const { payload }: { payload: IRequestGerarPreCadastro } = request;

	const requestData = { ...payload };

	delete requestData.exame_dentro_do_posto;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`primeira-habilitacao/${CHANNEL}/gerar-pre-cadastro`,
		requestData,
	);

	if (response.status === 200) {
		yield put(gerarPreCadastroSuccess({ response, payload }));
	} else if (response.error) {
		yield put(gerarPreCadastroFailure(response.error));
	}
}

export default all([takeLatest(Types.GERAR_PRE_CADASTRO, gerarPreCadastro)]);
