import * as Yup from 'yup';

import {
	IFormPreCadastro,
	IFormStatusCondutor,
} from 'pages/DetranCnh/components/pages/PreCadastro/StatusCondutor/form';
import { IDadosPermissaoInternacional } from 'store/modules/api/detranCnh/permissaoInternacional/confirmaPreCadastroPermissaoInternacional/types';
import { PesquisaEnderecoRecebimentoData } from 'store/modules/api/detranCnh/condutor/pesquisaEnderecosRecebimento/types';
// import { consultaCEPData } from 'store/modules/api/detranCnh/dadosDominio/cep/types';
import { limparMascara, unformatDate } from 'utils/genericFunctions';
import { CadastroEnderecosRequest } from 'store/modules/api/detranCnh/condutor/cadastroEnderecos/types';

import { AtendimentoState } from 'store/modules/api/sgu/atendimento/types';
import { ILoginUnicoState } from 'store/modules/api/sgu/loginUnico/types';
import { PreCadastroCidadaoRequest } from 'store/modules/api/sguService/preCadastroCidadao/types';

export const initalValuesHabilitacao: IFormStatusCondutor = {
	servico: 'permissaoInternacional',
	status: '',
	statusMensagem: '',
	mensagemBloqueio: '',
	numeroRegistro: '',
	numeroPgu: '',
	renach: '',
	local: '',
	categoriaPretendida: '',
	cfcA: '',
	espelhoCnh: '',
	usoForm: '',
	situacaoAtual: '',
	cfcB: '',
	espelhoPid: '',
	dataPrimeiraHabilitacao: '',
	nome: '',
	dataValidadeCnh: '',
	nomePai: '',
	dataNascimento: '',
	nomeMae: '',
	sexo: '',
	nacionalidade: '',
	tipoDocumento: '',
	numeroRg: '',
	orgaoEmissor: '',
	localEmissao: '',
	cpf: '',
	rne1: '',
	rne2: '',
	rne3: '',
	naturalidade: '',
	identificacaoHabilitacao: '',
	pais: '',
	dataValidade: '',
	enderecoLogradouro: '',
	enderecoNumero: '',
	enderecoComplemento: '',
	enderecoCep: '',
	enderecoBairro: '',
	enderecoMunicipio: '',
	enderecoCodigoMunicipio: '',
	enderecoDescricaoMunicipio: '',
	mensagem: '',
	exameMedicoDataExameMedico: '',
	exameMedicoCategoriaPermitida: '',
	exameMedicoRestricaoMedica: '',
	exameMedicoCrm: '',
	exameMedicoCred1: '',
	exameMedicoCred2: '',
	exameMedicoRegiao: '',
	exameMedicoAptidaoMedica: '',
	examePsicotecnicoDataExamePsicotecnico: '',
	examePsicotecnicoLaudo: '',
	examePsicotecnicoResultado1: '',
	examePsicotecnicoResultado2: '',
	examePsicotecnicoCrp: '',
	examePsicotecnicoCred1: '',
	examePsicotecnicoCred2: '',
	examePsicotecnicoRegiao: '',
	examePsicotecnicoAptidaoPsicotecnica: '',
	dataExameTeorico: '',
	codigoExameTeorico: '',
	codigoEntidade: '',
	descricaoEntidade: '',
	dataExamePratico2Rodas: '',
	codigoExamePratico2Rodas: '',
	dataExamePratico4Rodas: '',
	codigoExamePratico4Rodas: '',
	dataUltimaEmissaoCnh: '',
	listaOcorrencias: [],
	listaCursos: [],
	cepPcon: '',
	cepPcad: '',
	mensagensAlerta: '',
	nomeSocial: '',
	filiacao1: '',
	filiacao2: '',
	autorizaEmail: false,
	autorizaSMS: false,
};

export const initialValuesPreCadastro: IFormPreCadastro = {
	servico: 'permissaoInternacional',
	flagAtividadeRemunerada: '',
	flagDeficienteFisico: '',
	flagExameToxicologico: '',
	flagMilitarCfcCorporacao: 'N',
	flagExameDentroDoPosto: 'S',
	codigoEntidade: '',
	numCarteiraMilitar: '',
	nome: '',
	cpf: '',
	numeroRg: '',
	rne1: '',
	rne2: '',
	rne3: '',
	nomePai: '',
	nomeMae: '',
	sexo: '',
	nacionalidade: '',
	uf: '',
	cep: '',
	enderecoNumero: '',
	enderecoBairro: '',
	telefone: '',
	celular: '',
	orgaoEmissor: '',
	localEmissao: '',
	dataNascimento: '',
	naturalidade: '',
	enderecoLogradouro: '',
	enderecoComplemento: '',
	enderecoCodigoMunicipio: '',
	enderecoDescricaoMunicipio: '',
	email: '',
	confirmaEmail: '',
	autorizaSMS: '',
	autorizaEmail: '',
	categoriaPretendida: '',
	cnhDigital: '',
	codigoVerificador: '',
	codigoPoupatempo: '',
	cpfUsuario: '',
	categoriaAtual: '',
	dataPrimeiraHabilitacao: '',
	numeroPgu: '',
	numeroRegistro: '',
	tipoDocumento: '1',
	enderecoRecebimentoNumero: '',
	enderecoRecebimentoBairro: '',
	enderecoRecebimentoCep: '',
	enderecoRecebimentoLogradouro: '',
	enderecoRecebimentoComplemento: '',
	enderecoRecebimentoMunicipio: '',
};

export const initialValuesDadosHabilitacao: IDadosPermissaoInternacional = {
	status: '',
	statusMensagem: '',
	mensagemBloqueio: '',
	numeroRegistro: '',
	numeroPgu: '',
	renach: '',
	local: '',
	categoriaPretendida: '',
	cfcA: '',
	espelhoCnh: '',
	usoForm: '',
	situacaoAtual: '',
	cfcB: '',
	espelhoPid: '',
	dataPrimeiraHabilitacao: '',
	dataUltEmissaoCnh: '',
	nome: '',
	dataValidadeCnh: '',
	nomePai: '',
	dataNascimento: '',
	nomeMae: '',
	sexo: '',
	nacionalidade: '',
	tipoDocumento: '',
	numeroRg: '',
	orgaoEmissor: '',
	localEmissao: '',
	cpf: '',
	rne1: '',
	rne2: '',
	rne3: '',
	naturalidade: '',
	identificacaoHabilitacao: '',
	pais: '',
	dataValidade: '',
	endereco: {
		logradouro: '',
		numero: '',
		complemento: '',
		cep: '',
		bairro: '',
		municipio: '',
		codigoMunicipio: '',
		descricaoMunicipio: '',
	},
	mensagem: '',
	dadosExameMedico: {
		dataExameMedico: '',
		categoriaPermitida: '',
		restricaoMedica: '',
		crm: '',
		cred1: '',
		cred2: '',
		regiao: '',
		aptidaoMedica: '',
	},
	dadosExamePsicotecnico: {
		aptidaoPsicotecnica: '',
		cred1: '',
		cred2: '',
		crp: '',
		exame1: '',
		laudo1: '',
		regiao: '',
		resultado1: '',
		resultado2: '',
	},
	dataExameTeorico: '',
	codigoExameTeorico: '',
	codigoEntidade: '',
	descricaoEntidade: '',
	dataExamePratico2Rodas: '',
	codigoExamePratico2Rodas: '',
	dataExamePratico4Rodas: '',
	codigoExamePratico4Rodas: '',
	dadosPcad: {
		cpf: '',
		nome: '',
		numeroRg: '',
		orgaoEmissor: '',
		localEmissao: '',
		dataNascimento: '',
		rne: '',
		orgaoEmissorRne: '',
		localEmissaoRne: '',
		codigoPais: '',
		descricaoPais: '',
		validadeHabilitacaoEstrangeira: '',
		sexo: '',
		numeroRegistro: '',
		numeroPgu: '',
		nacionalidade: '',
		naturalidade: '',
		nomePai: '',
		nomeMae: '',
		enderecoPcad: {
			logradouro: '',
			cep: '',
			bairro: '',
			municipio: '',
		},
		processo: '',
		ciretran: '',
		categoriaAtual: '',
		categoriaPretendida: '',
		dataCadastro: '',
		horaCadastro: '',
		usuarioCadastro: '',
	},
	listaOcorrencias: {
		ocorrencia: [],
	},
	listaCursos: {
		curso: [],
	},
	cepPcon: '',
	cepPcad: '',
	mensagensAlerta: {
		mensagem: '',
	},
	flagAtividadeRemunerada: '',
	flagDeficienteFisico: '',
};

export const initialValuesEndereco: PesquisaEnderecoRecebimentoData = {
	cpf: '',
	codigo: '',
	mensagem: '',
	enderecoCadastro: {
		logradouro: '',
		numero: '',
		complemento: '',
		bairro: '',
		cep: '',
		codigoMunicipio: '',
		nomeMunicipio: '',
	},
	enderecosRecebimento: [],
};

export const radioSimNao = [
	{
		label: 'Sim',
		value: 'S',
	},
	{
		label: 'Não',
		value: 'N',
	},
];

export const radioTipoRenovacao = [
	{
		label: 'Renovação Via Impressa',
		value: 'S',
	},
	{
		label: 'Renovação Digital (Impressa + Digital)',
		value: 'N',
	},
];

export function treatValues(values: any): CadastroEnderecosRequest {
	const formattedData = {
		cpf: values.cpf,
		enderecoRecebimento: {
			logradouro: values.enderecoRecebimentoLogradouro,
			numero: values.enderecoRecebimentoNumero,
			complemento: values.enderecoRecebimentoComplemento,
			bairro: values.enderecoRecebimentoBairro,
			cep: values.enderecoRecebimentoCep,
			codigoMunicipio: values.enderecoRecebimentoCodigoMunicipio,
			nomeMunicipio: values.enderecoRecebimentoMunicipio,
		},
	};

	return formattedData;
}

export function treatValuesPreCadastro(
	atendimento: AtendimentoState,
	loginUnico: ILoginUnicoState,
	values: any,
	consultaCEP: string | null,
): PreCadastroCidadaoRequest {
	const cpfAtendende = loginUnico.login.user.cpf
		? limparMascara(loginUnico.login.user.cpf)
		: '';
	console.log('O que chega na função do treatValues: ', values);
	return {
		evento: {
			id_atendimento: atendimento.salvarAtendimento.data?.idAtendimento ?? '',
			id_cidadao: atendimento.salvarAtendimento.data?.idCidadao ?? '',
			cpf: cpfAtendende,
			identificacao: values.cpf ? limparMascara(values.cpf.trim()) : '',
			ip: loginUnico.login.user.ip,
			canal: {
				id: 1,
				desc: 'Presencial',
				estacao: {
					id: 0,
					desc: '',
				},
				localidade: {
					id: Number(loginUnico.login.user.posto),
					desc: loginUnico.login.user.nomePosto,
				},
			},
			atendente: {
				nome: loginUnico.user.nome,
				id_atendente: '',
				id_posto: loginUnico.login.user.posto,
				desc_posto: loginUnico.login.user.nomePosto,
			},
		},
		rg: '',
		ufRg: null,
		cpf: values.cpf ? limparMascara(values.cpf.trim()) : '',
		cns: '',
		nome: values.nome.trim() ?? '',
		firstName: values.nome.trim() ?? '',
		dataNascimento: values.dataNascimento
			? new Date(unformatDate(values.dataNascimento)).getTime().toString()
			: '',
		nomeMae: values.nomeMae.trim() ?? '',
		foneResidencialDdd: limparMascara(values.codigoTelefone),
		foneResidencial: limparMascara(values.telefone),
		foneComercialDdd: '',
		foneComercial: '',
		foneCelularDdd: limparMascara(values.codigoAreaCelular),
		foneCelular: limparMascara(values.telefoneCelular),
		email: values.email ?? '',
		autorizaEmail: values.authEmail,
		autorizaSMS: values.authSms,
		// "P" FIXO
		origem: 'P',
		statusUpdateSenha: '',
		senha: '',
		businessCategory: '',
		// 10 com biometria - 100 sem biometria
		presencial: 10,
		sexo: values.sexo ?? '',
		// 200 FIXO - DETRAN
		orgao: 200,
		idCidadao: atendimento.salvarAtendimento.data?.idCidadao ?? '',
		idAtendimento: atendimento.salvarAtendimento.data?.idAtendimento ?? '',
		endereco: values.enderecoRecebimentoLogradouro ?? '',
		cep: values.enderecoRecebimentoCep ?? '',
		enderecoUf: consultaCEP,
		enderecoBairro: values.enderecoRecebimentoBairro ?? '',
		enderecoCidade: values.enderecoRecebimentoMunicipio ?? '',
		enderecoNum: values.enderecoRecebimentoNumero ?? '',
		enderecoCompl: values.enderecoRecebimentoComplemento ?? '',
	};
}

// export function treatValues(
// 	values: IFormPreCadastro,
// ): IRequestPreCadastroPermissaoInternacional {
// 	const formattedData = {
// 		bairro: values.enderecoRecebimentoBairro,
// 		categoriaAtual: values.categoriaAtual,
// 		categoriaPretendida: values.categoriaPretendida,
// 		celular: limparMascara(values.celular).substr(2, 9),
// 		cep: values.enderecoRecebimentoCep,
// 		cnhDigital: values.cnhDigital,
// 		codigoEntidade: values.codigoEntidade,
// 		codigoPoupatempo: values.codigoPoupatempo,
// 		codigoVerificador: values.codigoVerificador,
// 		complemento: values.enderecoRecebimentoComplemento,
// 		cpf: values.cpf,
// 		cpfUsuario: values.cpfUsuario,
// 		dataNascimento: values.dataNascimento,
// 		dataPrimeiraHabilitacao: values.dataPrimeiraHabilitacao,
// 		dddCelular: limparMascara(values.celular).substr(0, 2),
// 		email: values.email,
// 		logradouro: values.enderecoRecebimentoLogradouro,
// 		nacionalidade: values.nacionalidade,
// 		naturalidade: values.naturalidade,
// 		necessidadeVeiculoAdaptado: values.flagDeficienteFisico,
// 		nome: values.nome,
// 		nomeMae: values.nomeMae,
// 		nomePai: values.nomePai,
// 		numCarteiraMilitar: values.numCarteiraMilitar,
// 		numDocumento: values.numeroRg,
// 		numPGU: values.numeroPgu,
// 		numRNE: values.rne1,
// 		numRegistro: values.numeroRegistro,
// 		numero: values.enderecoRecebimentoNumero,
// 		orgaoDocumento: values.orgaoEmissor,
// 		orgaoRNE: values.rne2,
// 		pretendeAtividadeRemunerada: values.flagAtividadeRemunerada,
// 		sexo: values.sexo,
// 		tipoDocumento: values.tipoDocumento,
// 		ufDocumento: values.localEmissao,
// 		ufRNE: values.rne3,
// 	};

// 	return formattedData;
// }

export const schema = Yup.object<IFormPreCadastro>().shape({
	// flagAtividadeRemunerada: Yup.string().required(
	// 	'Campo Obrigatório: Pretende exercer atividade remunerada.',
	// ),
	// flagDeficienteFisico: Yup.string().required(
	// 	'Campo Obrigatório: Tem necessidade de veículo adaptado.',
	// ),
	// flagExameToxicologico: Yup.string().when(
	// 	'categoriaPretendida',
	// 	(categoriaPretendida: string) => {
	// 		if (
	// 			categoriaPretendida === 'C' ||
	// 			categoriaPretendida === 'D' ||
	// 			categoriaPretendida === 'E'
	// 		) {
	// 			return Yup.string()
	// 				.required('Campo Obrigatório: Realizou exames Toxicológicos.')
	// 				.test({
	// 					name: 'exameToxicologico',
	// 					message:
	// 						'De acordo com a Resolução Contran nº 583/16, deve ser providenciado antecipadamente, para apresentação ao médico de trânsito, o exame toxicológico para os processos de Habilitação que envolvem as categorias C, D e E.',
	// 					test: (value: any) => {
	// 						console.log('value', value);
	// 						return value !== 'N';
	// 					},
	// 				});
	// 		}
	// 		return Yup.string();
	// 	},
	// ),
	// flagMilitarCfcCorporacao: Yup.string().required(
	// 	'Campo Obrigatório: Usar documento de militar.',
	// ),
	// flagExameDentroDoPosto: Yup.string().required(
	// 	'Campo Obrigatório: Realizar exame dentro do Posto.',
	// ),
	// nome: Yup.string().required('Campo Obrigatório: Nome.'),
	// cpf: Yup.string().required('Campo Obrigatório: CPF.'),
	// numeroRg: Yup.string().required('Campo Obrigatório: RG.'),
	// nomePai: Yup.string().required('Campo Obrigatório: Nome do Pai.'),
	// nomeMae: Yup.string().required('Campo Obrigatório: Nome da Mãe.'),
	// sexo: Yup.string().required('Campo Obrigatório: Sexo.'),
	// nacionalidade: Yup.string().required('Campo Obrigatório: Nacionalidade.'),
	// uf: Yup.string().required('Campo Obrigatório: UF.'),
	// cep: Yup.string().required('Campo Obrigatório: CEP.'),
	enderecoRecebimentoLogradouro: Yup.string().required(
		'Campo Obrigatório: Logradouro.',
	),
	// enderecoRecebimentoComplemento: Yup.string().required(
	// 	'Campo Obrigatório: Complemento.',
	// ),
	enderecoRecebimentoNumero: Yup.string().required(
		'Campo Obrigatório: Número.',
	),
	enderecoRecebimentoBairro: Yup.string().required(
		'Campo Obrigatório: Bairro.',
	),
	enderecoRecebimentoCep: Yup.string().required('Campo Obrigatório: CEP.'),
	// telefone: Yup.string().when('celular', (celular: string) => {
	// 	if (celular === '') {
	// 		return Yup.string().required('Campo Obrigatório: Telefone ou Celular.');
	// 	}
	// }),
	// celular: Yup.string().required('Campo Obrigatório: Celular.'),
	// orgaoEmissor: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
	// localEmissao: Yup.string().required('Campo Obrigatório: UF.'),
	// dataNascimento: Yup.string().required(
	// 	'Campo Obrigatório: Data de Nascimento.',
	// ),
	// naturalidade: Yup.string().required('Campo Obrigatório: Naturalidade.'),
	// enderecoLogradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
	// enderecoDescricaoMunicipio: Yup.string().required(
	// 	'Campo Obrigatório: Município.',
	// ),
	// enderecoRecebimentoCep: Yup.string().required(
	// 	'Campo Obrigatório: Endereço de Recebimento - CEP.',
	// ),
	// enderecoRecebimentoNumero: Yup.string().required(
	// 	'Campo Obrigatório: Endereço de Recebimento - Número.',
	// ),
	// enderecoRecebimentoBairro: Yup.string().required(
	// 	'Campo Obrigatório: Endereço de Recebimento - Bairro.',
	// ),
	// enderecoRecebimentoLogradouro: Yup.string().required(
	// 	'Campo Obrigatório: Endereço de Recebimento - Logradouro.',
	// ),
	// enderecoRecebimentoMunicipio: Yup.string().required(
	// 	'Campo Obrigatório: Endereço de Recebimento - Município.',
	// ),
	// email: Yup.string().required('Campo Obrigatório: E-mail.'),
	// confirmaEmail: Yup.string()
	// 	.oneOf([Yup.ref('confirmaEmail')], 'Os e-mails não coincidem')
	// 	.required('Campo Obrigatório: Confirma e-mail.'),
	// // autorizaSMS: Yup.string().required('Campo Obrigatório: Autoriza SMS.'),
	// // autorizaEmail: Yup.string().required('Campo Obrigatório: Autoriza e-mail.'),
	// cnhDigital: Yup.string().required('Campo Obrigatório: CNH Digital.'),
});
