export enum Types {
	CONSULTA_ADICAO_CATEGORIA = '@cnh/CONSULTA_ADICAO_CATEGORIA',
	CONSULTA_ADICAO_CATEGORIA_SUCCESS = '@cnh/CONSULTA_ADICAO_CATEGORIA_SUCCESS',
	CONSULTA_ADICAO_CATEGORIA_FAILURE = '@cnh/CONSULTA_ADICAO_CATEGORIA_FAILURE',
}

export interface consultaAdicaoCategoria {
	status: number;
	data: IDadosAdicaoCategoria | null;
}

export interface IDadosAdicaoCategoria {
	status: string;
	statusMensagem: string;
	mensagemBloqueio: string;
	numeroRegistro: string;
	numeroPgu: string;
	renach: string;
	local: string;
	categoriaPretendida: string;
	cfcA: string;
	espelhoCnh: string;
	usoForm: string;
	situacaoAtual: string;
	cfcB: string;
	espelhoPid: string;
	dataPrimeiraHabilitacao: string;
	nome: string;
	dataValidadeCnh: string;
	nomePai: string;
	dataNascimento: string;
	nomeMae: string;
	sexo: string;
	nacionalidade: string;
	tipoDocumento: string;
	numeroRg: string;
	orgaoEmissor: string;
	localEmissao: string;
	cpf: string;
	rne1: string;
	rne2: string;
	rne3: string;
	naturalidade: string;
	identificacaoHabilitacao: string;
	pais: string;
	dataValidade: string;
	endereco: {
		codigoEndereco: string;
		logradouro: string;
		numero: string;
		complemento: string;
		cep: string;
		bairro: string;
		municipio: string;
		codigoMunicipio: string;
		descricaoMunicipio: string;
	};
	mensagem: string;
	dadosExameMedico: {
		dataExameMedico: string;
		categoriaPermitida: string;
		restricaoMedica: string;
		crm: string;
		cred1: string;
		cred2: string;
		regiao: string;
		aptidaoMedica: string;
	};
	dadosExamePsicotecnico: {
		dataExamePsicotecnico: string;
		laudo: string;
		resultado1: string;
		resultado2: string;
		crp: string;
		cred1: string;
		cred2: string;
		regiao: string;
		aptidaoPsicotecnica: string;
	};
	dataExameTeorico: string;
	codigoExameTeorico: string;
	codigoEntidade: string;
	descricaoEntidade: string;
	dataExamePratico2Rodas: string;
	codigoExamePratico2Rodas: string;
	dataExamePratico4Rodas: string;
	codigoExamePratico4Rodas: string;
	dadosPcad: {
		cpf: string;
		nome: string;
		numeroRg: string;
		orgaoEmissor: string;
		localEmissao: string;
		dataNascimento: string;
		rne: string;
		orgaoEmissorRne: string;
		localEmissaoRne: string;
		codigoPais: string;
		descricaoPais: string;
		validadeHabilitacaoEstrangeira: string;
		sexo: string;
		numeroRegistro: string;
		numeroPgu: string;
		nacionalidade: string;
		naturalidade: string;
		nomePai: string;
		nomeMae: string;
		enderecoPcad: {
			logradouro: string;
			cep: string;
			bairro: string;
			municipio: string;
		};
		processo: string;
		ciretran: string;
		categoriaAtual: string;
		categoriaPretendida: string;
		dataCadastro: string;
		horaCadastro: string;
		usuarioCadastro: string;
	};
	dadosPcan: {
		numeroRenach: string;
		numeroProcesso: string;
		local: string;
		dataPrimeiraHabilitacao: string;
		cfcA1: string;
		cfcA2: string;
		cfcB2Rodas1: string;
		cfcB2Rodas2: string;
		cfcB2Rodas3: string;
		cfcB4Rodas1: string;
		cfcB4Rodas2: string;
		cfcB4Rodas3: string;
		categoriaPretendida: string;
		escolha: string;
		categoriaAtual1: string;
		categoriaAtual2: string;
		codigoEntidade: string;
		numeroRegistro: string;
		dataCadastro: string;
		nome: string;
		sexo: string;
		dataNascimento: string;
		nacionalidade: string;
		codigoNaturalidade: string;
		descricaoNaturalidade: string;
		endereco: {
			logradouro: string;
			numero: string;
			complemento: string;
			cep: string;
			bairro: string;
			codigoMunicipio: string;
			descricaoMunicipio: string;
		};
		numeroRg: string;
		orgaoEmissor: string;
		uf: string;
		cpf: string;
		nomePai: string;
		nomeMae: string;
		mensagem: string;
		dadosExameMedico: {
			dataExameMedico: string;
			categoriaPermitida: string;
			restricaoMedica: string;
			aptidoInaptoTemporario: string;
			cred: string;
			crm: string;
			regiao: string;
		};
		dadosExamePsicotecnico: {
			examePsicotecnico1: string;
			examePsicotecnico2: string;
			laudo: string;
			resultado1: string;
			resultado2: string;
			aptoInaptoTemporario: string;
			cred: string;
			crp: string;
			regiao: string;
		};
		exameTeorico: string;
		examePratico2Rodas: string;
		examePratico4Rodas: string;
		examinadorTeorico1: string;
		examinadorTeorico2: string;
		examinadorPratico2Rodas1: string;
		examinadorPratico2Rodas2: string;
		examinadorPratico4Rodas1: string;
		examinadorPratico4Rodas2: string;
		unidade: string;
		atividadeRemunerada: string;
	};
	cepPcon: string;
	cepPcad: string;
	mensagensAlerta: {
		mensagem: string;
	};
	flagAtividadeRemunerada: string;
	flagDeficienteFisico: string;
}

export interface IRequestConsultaAdicaoCategoria {
	// evento: IEvento;
	cpf?: string;
	numeroRg?: string;
	numeroRegistro?: string;
	numeroPgu?: string;
	nome?: string;
	dataNascimento?: string;
	usuario: string;
	senha: string;
}

export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		estacao: {
			id: number;
			desc: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
}
