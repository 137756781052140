import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_SGU_SERVICE } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	listaMotivoJustificativaSuccess,
	listaMotivoJustificativaFailure,
} from './actions';

function* listaMotivoJustificativaRequest() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU_SERVICE,
		`atendimento/${CHANNEL}/lista-motivo-justificativa`,
	);

	if (response.status === 200) {
		yield put(listaMotivoJustificativaSuccess(response));
	} else {
		yield put(listaMotivoJustificativaFailure());
	}
}

export default all([
	takeLatest(
		Types.LISTA_MOTIVO_JUSTIFICATIVA_REQUEST,
		listaMotivoJustificativaRequest,
	),
]);
