import styled from 'styled-components';

export const ContainerAlteracaoEndereco = styled.div`
	> .ant-row {
		padding-top: 25px;
		padding-bottom: 25px;
		justify-content: center;
		> .ant-col {
			background: forestgreen;
			flex-wrap: wrap;
			padding: 20px 0;
		}
		h1 {
			color: white;
		}
		span {
			color: white;
			font-size: 16px;
		}
		button {
			margin: 10px auto;
		}
	}
`;

export const ContainerButtons = styled.div`
	> .ant-row {
		margin-top: 25px;
		margin-bottom: 25px;
		justify-content: flex-end;
	}
`;
