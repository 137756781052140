export enum Types {
	PESQUISA_NOMINAL_REQUEST = '@aac/PESQUISA_NOMINAL_REQUEST',
	LIMPA_PESQUISA_NOMINAL = '@aac/LIMPA_PESQUISA_NOMINAL',
}

export interface PesquisaNominal {
	tipoDocApresentado: string;
	docProcurador: string;
	ufRg: string;
	numeroDocumento: string;
	dataExpedicao: string;
	nome: string;
	nomePai: string;
	nomeMae: string;
	sexo: string;
	dataNascimento: string;
	requerente: boolean;
	nomeProcurador: string;
	posto: string;
	ipEstacao: string;
	usuario: string;
	dc: string;
}
