import * as Yup from 'yup';

// UTILS
import { isRgSegundaVia } from 'pages/iirgd/RgFicDigital/utils/rgFicDigitalFunctions';
import { calcularIdade, formatDate, nomeIirgd } from 'utils/genericFunctions';

export interface KitFamiliaDARE {
	numeroRg: string;
	loteRg: string | undefined;
	nome: string;
	dataNascimento: string;
	grauParentesco: string;
	segundaVia: boolean;
	sexo?: 'M' | 'F';
}

export const initialValues: KitFamiliaDARE = {
	numeroRg: '',
	loteRg: '',
	nome: '',
	dataNascimento: '',
	grauParentesco: '',
	segundaVia: true,
};

export const schema = Yup.object<KitFamiliaDARE>().shape({
	numeroRg: Yup.string().required('Campo Obrigatório: RG.'),
	loteRg: Yup.string().when(['numeroRg'], {
		is: numeroRg => numeroRg !== '',
		then: Yup.string().test({
			name: 'isRgSegundaVia',
			exclusive: true,
			message: 'Campo Inválido: O RG informado não é de segunda via.',
			test: value => isRgSegundaVia(value),
		}),
	}),
	nome: Yup.string()
		.required('Campo Obrigatório: Nome.')
		.max(60, 'Campo Nome: Tamanho máximo de 60 caracteres.')
		.test({
			name: 'nomeIirgd',
			exclusive: true,
			message: 'Campo Nome: Apenas letras são permitidas.',
			test: value => nomeIirgd(value) !== '',
		}),
	dataNascimento: Yup.string().required(
		'Campo Obrigatório: Data de Nascimento.',
	),
	grauParentesco: Yup.string().required(
		'Campo Obrigatório: Grau de parentesco.',
	),
	isento2Via: Yup.string().when(['dataNascimento', 'sexo'], {
		is: (dataNascimento, sexo) =>
			(sexo.toLocaleUpperCase() === 'M' &&
				calcularIdade(formatDate(dataNascimento)) >= 65) ||
			(sexo.toLocaleUpperCase() === 'F' &&
				calcularIdade(formatDate(dataNascimento)) >= 60),
		then: Yup.string().required(
			'Isento da taxa de 2ª via conforme lei estadual 10.952 de 07/11/2001. (Idoso, Idosa ou Desempregado)',
		),
	}),
});
