import React, { ChangeEvent, useCallback, useEffect } from 'react';

import { Row, Col, Divider } from 'antd';
import * as Yup from 'yup';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import EnumCNH from 'store/modules/api/detranCnh/enum';
import { consultaMunicipiosRequest } from 'store/modules/api/detranCnh/condutor/consultaMunicipios/actions';
import { consultaNacionalidadesRequest } from 'store/modules/api/detranCnh/consulta/nacionalidades/actions';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import CEPLogradouro from 'pages/DetranCnh/components/common/Fields/CEPLogradouro';
import Input from 'components/Common/Form/Input/Input';
import Radio from 'components/Common/Form/Radio';
import Select, { OptionProps } from 'components/Common/Form/Select';
import InputMask from 'components/Common/Form/Input/InputMask';
import EnderecoRecebimento from 'pages/DetranCnh/components/pages/EnderecoRecebimento';
import Checkbox from 'components/Common/Form/Checkbox';

// UTILS
import {
	limparMascara,
	onlyLetters,
	onlyNumbers,
	onlyNumbersAndLetters,
	onlyNumbersLettersSpace,
	removeAcentoCaracteresEspeciais,
	showComponent,
} from 'utils/genericFunctions';
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { Formik, Form, Field, FormikProps } from 'formik';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import GerarTokenSmsBox from 'pages/DetranCnh/components/common/GerarTokenSmsBox';
import { FormikFildObserver } from 'utils/formikObserver';
import { consultaPaisesRequest } from 'store/modules/api/detranCnh/consulta/paises/actions';
import { usoFormularioEnumTypes, enumFormUse } from 'utils/constants/types';
import { consultaToxicologicoRequest } from 'store/modules/api/detranCnh/cidadao/consultaToxicologico/actions';
import { IBuscarCepResultadoEndLogradouro } from 'pages/DetranCnh/components/common/Fields/CEPLogradouro/form';
import {
	FormCnhEstrangeiro,
	radioTipoCnhEstrangeiro,
	schemaGenerateToken,
} from '../../StatusCondutor/form';

interface DadosPreCadastroProps {
	handleSubmit: (values: any) => void;
	schema: Yup.ObjectSchema<
		Yup.Shape<FormCnhEstrangeiro | undefined, FormCnhEstrangeiro>,
		object
	>;
	renderData: FormCnhEstrangeiro;
	clearNotification: () => void;
	setMensagensInfo: (
		values: (oldState: Array<string>) => Array<string>,
	) => void | Array<string>;
	// eslint-disable-next-line react/require-default-props
	withTitle?: boolean;
	// eslint-disable-next-line react/require-default-props
	listaMunicipios?: OptionProps[] | null;
}

const DadosPreCadastro = ({
	handleSubmit,
	setMensagensInfo,
	clearNotification,
	renderData,
	schema,
	withTitle,
	listaMunicipios = null,
}: DadosPreCadastroProps) => {
	const dispatch = useDispatch();

	const { consultaPaises, consultaNacionalidades, consultaCategoria } =
		useSelector((state: ApplicationState) => state.api.detranCnh.consulta);

	const { consultaMunicipios } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const dadosMunicipio = useSelector(
		(state: ApplicationState) =>
			state.api.sguService.domainService.consultaMunicipios,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	const habilitaNomeSocial = (
		formik: FormikProps<FormCnhEstrangeiro>,
		valueFlag: React.ChangeEvent<HTMLInputElement>,
	) => {
		formik.setFieldValue('flagNomeSocial', valueFlag.target.value);

		if (valueFlag.target.value === 'N' && renderData.nomeSocial === '') {
			formik.setFieldValue('nomeSocial', '');
		}
	};

	const habilitaFiliacaoAfetiva = (
		formik: FormikProps<FormCnhEstrangeiro>,
		valueFlag: React.ChangeEvent<HTMLInputElement>,
	) => {
		formik.setFieldValue('flagFiliacaoAfetiva', valueFlag.target.value);

		if (
			valueFlag.target.value === 'N' &&
			(renderData.paisAfetivos1 === '' || renderData.paisAfetivos2 === '')
		) {
			formik.setFieldValue('paisAfetivos1', '');
			formik.setFieldValue('paisAfetivos2', '');
		}
	};

	const handleFormChange = useCallback(() => {
		if (errors.length > 0) {
			dispatch(clearNotifications());
		}
	}, [dispatch, errors.length]);

	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultadoEndLogradouro, formik: FormikProps<any>) => {
			formik.setFieldValue(`codigoUnidadeTransito`, res.codigoUnidadeTransito);
			formik.setFieldValue(`cep`, res.cep);
			formik.setFieldValue(
				`logradouro`,
				removeAcentoCaracteresEspeciais(res.endereco.substring(0, 40)),
			);
			formik.setFieldValue(
				`bairro`,
				removeAcentoCaracteresEspeciais(res.bairro.substring(0, 13)),
			);
			formik.setFieldValue(`logradouroCodMunicipio`, res.codigoMunicipio);
			formik.setFieldValue(
				`municipio`,
				removeAcentoCaracteresEspeciais(res.municipio),
			);
		},
		[],
	);

	const handleChangeNationality = (
		formik: FormikProps<FormCnhEstrangeiro>,
		value: string,
	) => {
		formik.setFieldValue('nacionalidade', value);

		if (value.toString() === '3') {
			dispatch(consultaPaisesRequest());
			formik.setFieldValue('rneUf', 'EX');
			formik.setFieldValue('naturalidade', '');
		} else {
			formik.setFieldValue('rneNumero', '');
			formik.setFieldValue('rneOrgao', '');
			formik.setFieldValue('rneUf', '');
			formik.setFieldValue('uf', '');
			formik.setFieldValue('naturalidade', '');
		}
	};

	const validateFieldsGenerateToken = async (
		formik: FormikProps<FormCnhEstrangeiro>,
	) => {
		clearNotification();
		if (
			formik.values.autorizaEnvioEmail !== 'S' ||
			formik.values.autorizaEnvioSms !== 'S'
		) {
			setMensagensInfo((oldState: Array<string>) => {
				return [
					...oldState,
					'Obrigatório o preenchimento da autorização de envio de SMS e E-mail para validação do código.',
				];
			});
			window.scrollTo(0, 0);
			return false;
		}

		if (
			Object.keys(
				await getValidationsErrors(formik.values, schemaGenerateToken),
			).length === 0
		) {
			return true;
		}

		return false;
	};

	useEffect(() => {
		dispatch(consultaNacionalidadesRequest());

		if (renderData.nacionalidade !== '1') {
			dispatch(consultaPaisesRequest());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (
			dadosMunicipio.status === 200 &&
			dadosMunicipio.data &&
			dadosMunicipio.data[0] &&
			!consultaMunicipios.data
		) {
			dispatch(consultaMunicipiosRequest({ uf: dadosMunicipio.data[0].uf }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dadosMunicipio]);

	return (
		<>
			{withTitle && (
				<Row style={{ marginTop: 15 }}>
					<Col span={10}>
						<h2>Dados do Pré-Cadastro</h2>
						<Divider style={{ margin: 0, backgroundColor: '#000' }} />
					</Col>
				</Row>
			)}

			<Row>
				<Col span={24}>
					<p>
						<strong>
							Atualize as informações abaixo e em seguida clique em
							&quot;Salvar&quot;:
						</strong>
					</p>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Formik
						enableReinitialize
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={renderData}
						validate={values =>
							getValidationsErrors(values, schema, 'usoFormularioEnum')
						}
						onSubmit={handleSubmit}
					>
						{formik => (
							<>
								<Form autoComplete="off">
									{showComponent(
										[usoFormularioEnumTypes.ALTERAR_RENACH],
										renderData.tipoProcesso ?? '',
									) && (
										<Row>
											<Col span={9}>
												<Field
													as={Input}
													title="Tipo de Processo"
													name="tipoRenach"
													titleAlign="start"
													titleSize="120"
													error={!!formik.errors.usoFormularioEnum}
													disabled
												/>
											</Col>
											<Col offset={3} span={5}>
												<Field
													as={Input}
													title="Nº Renach"
													name="numeroRenach"
													titleAlign="start"
													titleSize="70"
													error={!!formik.errors.nome}
													disabled
												/>
											</Col>
											<Col offset={1} span={5}>
												<Field
													as={Input}
													title="Categoria Pretendida"
													name="categoriaPretendida"
													maxLength="45"
													titleAlign="start"
													titleSize="120"
													error={!!formik.errors.categoriaPretendida}
													disabled
												/>
											</Col>
										</Row>
									)}
									{!showComponent(
										[usoFormularioEnumTypes.ALTERAR_RENACH],
										renderData.tipoProcesso ?? '',
									) && (
										<Row>
											<Col span={10}>
												<Field
													as={Select}
													title="Categoria Pretendida"
													name="categoriaPretendida"
													titleSize="120"
													titleAlign="start"
													options={consultaCategoria.data || []}
													onChange={(v: string) => {
														if (
															!showComponent(['A', 'B', 'AB', 'X', 'XB', ''], v)
														) {
															dispatch(
																consultaToxicologicoRequest(formik.values.cpf),
															);
														}

														formik.setFieldValue('categoriaPretendida', v);
													}}
													error={!!formik.errors.categoriaPretendida}
												/>
											</Col>
										</Row>
									)}

									<Row>
										<Col span={8}>
											<Field
												as={Radio}
												title="Pretende exercer atividade remunerada"
												name="flagAtividadeRemunerada"
												options={EnumCNH.radioSimNao}
												titleQuestion
												titleSize="auto"
												directionRow
												error={!!formik.errors.flagAtividadeRemunerada}
												disabled={
													renderData.tipoProcesso ===
														usoFormularioEnumTypes.ALTERAR_RENACH &&
													renderData.usoFormularioEnum ===
														usoFormularioEnumTypes.PRIMEIRA_HABILITACAO &&
													renderData.categoriaAtual === 'A'
												}
											/>
										</Col>
										{/* <Col span={11}>
											{!showComponent(
												[enumFormUse.SEGUNDA_VIA, enumFormUse.CNH_DEFINITIVA],
												formik.values.usoFormularioEnum,
											) && (
												<Field
													as={Radio}
													title="Tem necessidade de veículo adaptado"
													name="flagDeficienteFisico"
													options={EnumCNH.radioSimNao}
													titleQuestion
													titleSize="auto"
													directionRow
													error={!!formik.errors.flagDeficienteFisico}
												/>
											)}
										</Col> */}
									</Row>
									{!showComponent(
										[usoFormularioEnumTypes.ALTERAR_RENACH],
										renderData.tipoProcesso ?? '',
									) && (
										<Row>
											{!showComponent(
												['A', 'B', 'AB', 'X', 'XB', ''],
												formik.values.categoriaPretendida,
											) ? (
												<>
													<Col span={8}>
														<Field
															as={Radio}
															title="Exame dentro do posto"
															name="flagExameNoPosto"
															options={EnumCNH.radioSimNao}
															defaultValue={renderData.flagExameNoPosto}
															titleQuestion
															directionRow
															titleSize="auto"
															error={formik.errors.flagExameNoPosto}
															disabled={renderData.flagExameNoPosto === 'N'}
														/>
													</Col>
													<Col span={8}>
														<Field
															as={Radio}
															title="Realizou exames Toxicológicos"
															name="flagExameToxicologico"
															options={EnumCNH.radioSimNao}
															titleQuestion
															directionRow
															titleSize="auto"
															onChange={(e: ChangeEvent<HTMLInputElement>) => {
																if (e.target.value === 'N') {
																	addNotifications({
																		errors: [
																			'De acordo com a Resolução Contran nº 843/2021, deve ser providenciado antecipadamente, para apresentação ao médico de trânsito, o exame toxicológico para os processos de Habilitação que envolvem as categorias C, D e E.',
																		],
																	});
																} else {
																	dispatch(
																		consultaToxicologicoRequest(
																			formik.values.cpf,
																		),
																	);
																}
																formik.setFieldValue(
																	'flagExameToxicologico',
																	e.target.value,
																);
															}}
															error={!!formik.errors.flagExameToxicologico}
														/>
													</Col>
												</>
											) : (
												<Col span={8}>
													<Field
														as={Radio}
														title="Exame dentro do posto"
														name="flagExameNoPosto"
														options={EnumCNH.radioSimNao}
														titleQuestion
														directionRow
														titleSize="auto"
														error={!!formik.errors.flagExameNoPosto}
														disabled={renderData.flagExameNoPosto === 'N'}
													/>
												</Col>
											)}
										</Row>
									)}
									<Row>
										<Col span={12}>
											<Field
												as={Input}
												value={onlyLetters(formik.values.nome)}
												title="Nome"
												name="nome"
												maxLength="45"
												titleAlign="start"
												titleSize="120"
												error={!!formik.errors.nome}
												onChange={(v: ChangeEvent<HTMLInputElement>) =>
													formik.setFieldValue(
														'nome',
														removeAcentoCaracteresEspeciais(
															v.target.value.toUpperCase(),
														),
													)
												}
											/>
											<Field
												as={Input}
												value={onlyLetters(formik.values.nomeSocial || '')}
												title="Nome Social"
												name="nomeSocial"
												error={!!formik.errors.nomeSocial}
												maxLength="45"
												titleAlign="start"
												titleSize="120"
												disabled={
													!(
														!showComponent(
															[usoFormularioEnumTypes.ALTERAR_RENACH],
															renderData.tipoProcesso,
														) ||
														(!showComponent(
															[usoFormularioEnumTypes.ALTERAR_RENACH],
															renderData.tipoProcesso,
														) &&
															formik.values.flagNomeSocial !== 'S')
													)
												}
												onChange={(v: ChangeEvent<HTMLInputElement>) =>
													formik.setFieldValue(
														'nomeSocial',
														v.target.value.toUpperCase(),
													)
												}
											/>
										</Col>
										{!showComponent(
											[usoFormularioEnumTypes.ALTERAR_RENACH],
											renderData.tipoProcesso ?? '',
										) && (
											<Col offset={1} span={10}>
												<Field
													as={Radio}
													title="Deseja utilizar o nome social na CNH"
													name="flagNomeSocial"
													options={EnumCNH.radioSimNao}
													titleQuestion
													directionRow
													titleSize="auto"
													error={!!formik.errors.flagNomeSocial}
													onChange={(
														valueFlag: React.ChangeEvent<HTMLInputElement>,
													) => habilitaNomeSocial(formik, valueFlag)}
												/>
											</Col>
										)}
									</Row>
									<Row>
										<Col span={8}>
											<Field
												as={Input}
												title="CPF"
												name="cpf"
												titleAlign="start"
												titleSize="120"
												disabled
												error={!!formik.errors.cpf}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={10}>
											<Field
												as={Select}
												title="Nacionalidade"
												name="nacionalidade"
												titleAlign="start"
												titleSize="120"
												options={consultaNacionalidades.data || []}
												onChange={(v: string) =>
													handleChangeNationality(formik, v)
												}
												defaultFirstOption={false}
												error={!!formik.errors.nacionalidade}
											/>
										</Col>
									</Row>
									<Row>
										{formik.values.nacionalidade !== '1' ? (
											<Col span={10}>
												<Field
													as={Select}
													title="Naturalidade"
													name="naturalidade"
													titleSize="120"
													titleAlign="start"
													options={consultaPaises.data || []}
													onChange={(v: number) =>
														formik.setFieldValue('naturalidade', v)
													}
													defaultFirstOption={false}
													error={!!formik.errors.naturalidade}
												/>
											</Col>
										) : (
											<>
												<Col span={8}>
													<Field
														as={Select}
														title="UF"
														name="uf"
														titleSize="120"
														titleAlign="start"
														options={EnumCNH.ufEnum}
														onChange={(v: string) => {
															dispatch(consultaMunicipiosRequest({ uf: v }));
															formik.setFieldValue('uf', v);
															formik.setFieldValue('naturalidade', '');
														}}
														error={!!formik.errors.uf}
													/>
												</Col>
												<Col offset={5} span={10}>
													<Field
														as={Select}
														title="Naturalidade"
														name="naturalidade"
														value={
															formik.values.naturalidade
																? Number(formik.values.naturalidade)
																: ''
														}
														titleSize="120"
														titleAlign="start"
														options={
															listaMunicipios || consultaMunicipios.data || []
														}
														onChange={(v: number) =>
															formik.setFieldValue('naturalidade', v)
														}
														error={!!formik.errors.naturalidade}
													/>
												</Col>
											</>
										)}
									</Row>
									<Row align="middle">
										<Col span={12}>
											<Field
												as={Input}
												title="RG"
												name="numRg"
												subtitle="(Não inclua o DÍGITO nem digite pontos ou hífen)"
												maxLength="15"
												titleAlign="start"
												titleSize="120"
												size={80}
												value={onlyNumbersAndLetters(formik.values.numRg || '')}
												onChange={(v: ChangeEvent<HTMLInputElement>) =>
													formik.setFieldValue(
														'numRg',
														v.target.value.toUpperCase(),
													)
												}
												error={!!formik.errors.numRg}
											/>
										</Col>
										<Col offset={1} span={5}>
											<Field
												as={Input}
												title="Órgão Emissor"
												name="orgaoExpDocumento"
												maxLength="10"
												titleAlign="start"
												titleSize="120"
												value={onlyLetters(
													formik.values.orgaoExpDocumento || '',
												)}
												error={!!formik.errors.orgaoExpDocumento}
											/>
										</Col>
										<Col offset={1} span={4}>
											<Field
												as={Select}
												title="UF"
												name="orgaoUfExpDocumento"
												options={EnumCNH.ufEnum}
												onChange={(v: number) =>
													formik.setFieldValue('orgaoUfExpDocumento', v)
												}
												titleSize="20"
												error={!!formik.errors.orgaoUfExpDocumento}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={12}>
											<Field
												as={Input}
												title="RNE"
												name="rneNumero"
												maxLength="15"
												titleAlign="start"
												titleSize="120"
												size="80"
												value={onlyNumbersAndLetters(
													formik.values.rneNumero || '',
												)}
												onChange={(v: ChangeEvent<HTMLInputElement>) =>
													formik.setFieldValue(
														'rneNumero',
														v.target.value.toUpperCase(),
													)
												}
												disabled={formik.values.nacionalidade !== '3'}
												subtitle="(NÃO digite pontos nem hífen)"
											/>
										</Col>
										<Col offset={1} span={5}>
											<Field
												as={Input}
												title="Órgão Emissor"
												name="rneOrgao"
												maxLength="10"
												titleSize="120"
												titleAlign="start"
												value={onlyLetters(formik.values.rneOrgao || '')}
												disabled={formik.values.nacionalidade !== '3'}
											/>
										</Col>
										<Col offset={1} span={4}>
											<Field
												as={Input}
												title="UF"
												name="rneUf"
												maxLength="2"
												titleAlign="start"
												titleSize="20"
												disabled
											/>
										</Col>
									</Row>
									{(renderData &&
										showComponent(
											[usoFormularioEnumTypes.CNH_ESTRANGEIRA],
											renderData.usoFormularioEnum || '',
										)) ||
										(renderData.usoFormularioEnum === '5' && (
											<>
												<Row align="middle">
													<Col span={10}>
														<Field
															as={Select}
															title="País CNH Estrangeira"
															name="paisCnhEstrangeiro"
															titleAlign="start"
															titleSize="120"
															options={consultaPaises.data || []}
															onChange={(v: number | string) => {
																formik.setFieldValue('paisCnhEstrangeiro', v);
															}}
															error={!!formik.errors.paisCnhEstrangeiro}
														/>
													</Col>
													<Col offset={3} span={10}>
														<Field
															as={InputMask}
															title="Validade CNH Estrangeira"
															name="dataValidadeCnhEstrangeira"
															mask="99/99/9999"
															subtitle="(DD/MM/AAAA)"
															titleAlign="start"
															titleSize="160"
															error={!!formik.errors.dataValidadeCnhEstrangeira}
														/>
													</Col>
												</Row>
												<Row align="middle">
													<Col span={10}>
														<Field
															as={Input}
															title="Nº CNH Estrangeira"
															name="numeroCnhEstrangeira"
															maxLength="30"
															titleAlign="start"
															titleSize="120"
															error={!!formik.errors.numeroCnhEstrangeira}
														/>
													</Col>
													<Col offset={3} span={10}>
														<Field
															as={InputMask}
															title="Data Primeira Habilitação"
															name="dataPrimeiraHabilitacao"
															mask="99/99/9999"
															subtitle="(DD/MM/AAAA)"
															titleAlign="start"
															titleSize="160"
															error={!!formik.errors.dataPrimeiraHabilitacao}
														/>
													</Col>
												</Row>
											</>
										))}
									<Row>
										<Col span={12}>
											<Field
												as={Input}
												title="Nome do Pai"
												name="nomePai"
												maxLength="45"
												titleAlign="start"
												titleSize="120"
												value={onlyLetters(formik.values.nomePai)}
												onChange={(v: ChangeEvent<HTMLInputElement>) =>
													formik.setFieldValue(
														'nomePai',
														removeAcentoCaracteresEspeciais(
															v.target.value.toUpperCase(),
														),
													)
												}
												error={!!formik.errors.nomePai}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={12}>
											<Field
												as={Input}
												title="Nome da Mãe"
												name="nomeMae"
												maxLength="45"
												titleAlign="start"
												titleSize="120"
												value={onlyLetters(formik.values.nomeMae)}
												onChange={(v: ChangeEvent<HTMLInputElement>) =>
													formik.setFieldValue(
														'nomeMae',
														removeAcentoCaracteresEspeciais(
															v.target.value.toUpperCase(),
														),
													)
												}
												error={!!formik.errors.nomeMae}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={12}>
											<Field
												as={Input}
												title="Filiação Afetiva 1"
												name="paisAfetivos1"
												error={!!formik.errors.paisAfetivos1}
												maxLength="45"
												titleAlign="start"
												titleSize="120"
												value={onlyLetters(formik.values.paisAfetivos1 || '')}
												disabled={
													!(
														(showComponent(
															[usoFormularioEnumTypes.ALTERAR_RENACH],
															renderData.tipoProcesso,
														) &&
															renderData.paisAfetivos1 &&
															renderData.paisAfetivos1 !== '') ||
														(!showComponent(
															[usoFormularioEnumTypes.ALTERAR_RENACH],
															renderData.tipoProcesso,
														) &&
															formik.values.flagFiliacaoAfetiva !== 'S')
													)
												}
											/>
											<Field
												as={Input}
												title="Filiação Afetiva 2"
												name="paisAfetivos2"
												error={!!formik.errors.paisAfetivos2}
												maxLength="45"
												titleAlign="start"
												titleSize="120"
												value={onlyLetters(formik.values.paisAfetivos2 || '')}
												disabled={
													!(
														(showComponent(
															[usoFormularioEnumTypes.ALTERAR_RENACH],
															renderData.tipoProcesso,
														) &&
															renderData.paisAfetivos2 &&
															renderData.paisAfetivos2 !== '') ||
														(!showComponent(
															[usoFormularioEnumTypes.ALTERAR_RENACH],
															renderData.tipoProcesso,
														) &&
															formik.values.flagFiliacaoAfetiva !== 'S')
													)
												}
											/>
										</Col>
										{!showComponent(
											[usoFormularioEnumTypes.ALTERAR_RENACH],
											renderData.tipoProcesso ?? '',
										) && (
											<Col span={8} offset={1}>
												<Field
													as={Radio}
													title="Deseja incluir filiação afetiva na CNH?"
													name="flagFiliacaoAfetiva"
													options={EnumCNH.radioSimNao}
													titleQuestion
													directionRow
													titleSize="auto"
													error={!!formik.errors.flagFiliacaoAfetiva}
													onChange={(
														valueFlag: React.ChangeEvent<HTMLInputElement>,
													) => habilitaFiliacaoAfetiva(formik, valueFlag)}
												/>
											</Col>
										)}
									</Row>
									<Row>
										<Col span={8}>
											<Field
												as={Select}
												title="Sexo"
												name="sexo"
												titleAlign="start"
												titleSize="120"
												options={EnumCNH.sexoEnum}
												onChange={(v: number) =>
													formik.setFieldValue('sexo', v)
												}
												defaultFirstOption={false}
												error={!!formik.errors.sexo}
											/>
										</Col>
										<Col offset={5} span={10}>
											<Field
												as={InputMask}
												title="Data de Nascimento"
												name="dataNascimento"
												mask="99/99/9999"
												subtitle="(DD/MM/AAAA)"
												titleAlign="start"
												titleSize="120"
												error={!!formik.errors.dataNascimento}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<CEPLogradouro
												name="cep"
												formik={formik}
												titleSize={120}
												titleAlign="start"
												defaultValue={renderData.cep}
												resultEndLogradouro={(
													res: IBuscarCepResultadoEndLogradouro,
												) => handleCepSelecionado(res, formik)}
											/>
										</Col>
										<Col offset={5} span={10}>
											<Field
												as={Input}
												title="Logradouro"
												name="logradouro"
												maxLength="30"
												titleSize="120"
												titleAlign="start"
												value={onlyNumbersLettersSpace(
													formik.values.logradouro,
												)}
												error={!!formik.errors.logradouro}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Field
												as={Input}
												title="Número"
												name="numero"
												maxLength="6"
												titleAlign="start"
												titleSize="120"
												value={onlyNumbers(formik.values.numero || '')}
												disabled={formik.values.numero === '000000'}
												error={!!formik.errors.numero}
											/>
										</Col>
										<Col offset={1} span={1}>
											<Field
												subtitle="S/N"
												name="numero"
												as={Checkbox}
												checked={formik.values.numero === '000000'}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													const { checked } = e.target;

													formik.setFieldValue(
														'numero',
														checked ? '000000' : '',
													);
												}}
											/>
										</Col>
										<Col offset={3} span={10}>
											<Field
												as={Input}
												title="Complemento"
												name="complemento"
												maxLength="20"
												titleAlign="start"
												titleSize="120"
												value={onlyNumbersLettersSpace(
													formik.values.complemento || '',
												)}
												error={formik.errors.complemento}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Field
												as={Input}
												title="Bairro"
												name="bairro"
												maxLength="20"
												titleAlign="start"
												titleSize="120"
												value={onlyNumbersLettersSpace(formik.values.bairro)}
												error={!!formik.errors.bairro}
											/>
										</Col>
										<Col offset={5} span={10}>
											<Field
												as={Input}
												title="Município"
												name="municipio"
												titleSize="120"
												titleAlign="start"
												disabled
												error={!!formik.errors.municipio}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Field
												as={InputMask}
												title="Telefone"
												name="telefone"
												mask="(99)9999-9999"
												titleAlign="start"
												titleSize="120"
												error={!!formik.errors.telefone}
											/>
										</Col>
										<Col offset={5} span={10}>
											<Field
												as={Input}
												title="E-mail"
												name="email"
												maxLength="60"
												titleSize="120"
												titleAlign="start"
												onChange={(v: ChangeEvent<HTMLInputElement>) =>
													formik.setFieldValue(
														'email',
														v.target.value.toUpperCase(),
													)
												}
												error={!!formik.errors.email}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={8}>
											<Field
												as={InputMask}
												title="Celular"
												name="celular"
												mask="(99)99999-9999"
												titleAlign="start"
												titleSize="120"
												error={!!formik.errors.celular}
											/>
										</Col>
										<Col offset={5} span={10}>
											<Field
												as={Input}
												title="Confirmar e-mail"
												name="confirmaEmail"
												maxLength="60"
												titleSize="120"
												titleAlign="start"
												onChange={(v: ChangeEvent<HTMLInputElement>) =>
													formik.setFieldValue(
														'confirmaEmail',
														v.target.value.toUpperCase(),
													)
												}
												error={!!formik.errors.email}
											/>
										</Col>
									</Row>

									<Row gutter={[10, 10]}>
										<Col>
											<Field
												subtitle="Autorizo o envio de SMS pelo DETRAN"
												name="autorizaEnvioSms"
												as={Checkbox}
												checked={formik.values.autorizaEnvioSms === 'S'}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													const { checked } = e.target;

													formik.setFieldValue(
														'autorizaEnvioSms',
														checked ? 'S' : 'N',
													);
												}}
											/>
										</Col>
										<Col>
											<Field
												subtitle="Autorizo o envio de E-mail pelo DETRAN"
												name="autorizaEnvioEmail"
												as={Checkbox}
												checked={formik.values.autorizaEnvioEmail === 'S'}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													const { checked } = e.target;

													formik.setFieldValue(
														'autorizaEnvioEmail',
														checked ? 'S' : 'N',
													);
												}}
											/>
										</Col>
									</Row>
									{!showComponent(
										[
											enumFormUse.PRIMEIRA_HABILITACAO,
											enumFormUse.CNH_ESTRANGEIRA,
										],
										renderData.usoFormularioEnum ?? '',
									) && (
										<>
											<Row gutter={[0, 30]}>
												<Col>
													<Field
														as={Radio}
														name="flagSolicitaCNHDigital"
														options={radioTipoCnhEstrangeiro}
														onChange={(e: ChangeEvent<HTMLInputElement>) => {
															const { value } = e.target;

															formik.setFieldValue(
																'flagSolicitaCNHDigital',
																value,
															);
														}}
														titleQuestion
														directionRow
														titleSize="auto"
														error={!!formik.errors.flagSolicitaCNHDigital}
													/>
												</Col>
											</Row>

											{formik.values.flagSolicitaCNHDigital === 'S' && (
												<>
													<Divider
														style={{ marginTop: 10, backgroundColor: '#000' }}
													/>
													<GerarTokenSmsBox
														codigoPais={renderData.nacionalidade}
														cpf={formik.values.cpf}
														ddd={limparMascara(formik.values.celular).substring(
															0,
															2,
														)}
														celular={limparMascara(
															formik.values.celular,
														).substring(2, 11)}
														email={formik.values.email || ''}
														tokenValue={(code: string) =>
															formik.setFieldValue('codigoVerificador', code)
														}
														externalValidation={() =>
															validateFieldsGenerateToken(formik)
														}
														setMensagensInfo={setMensagensInfo}
													/>
												</>
											)}
										</>
									)}
									{!showComponent(
										[usoFormularioEnumTypes.ALTERAR_RENACH],
										renderData.tipoProcesso ?? '',
									) && (
										<Row>
											<Col>
												<EnderecoRecebimento
													formik={formik}
													cpf={formik.values.cpf}
												/>
											</Col>
										</Row>
									)}
									<Row justify="center">
										<Col offset={1}>
											<ButtonImage type="submit" src="salvar" />
										</Col>
									</Row>
								</Form>
								<FormikFildObserver
									dirty={formik.dirty}
									values={formik.values}
									onChange={handleFormChange}
								/>
							</>
						)}
					</Formik>
				</Col>
			</Row>
		</>
	);
};

export default DadosPreCadastro;
