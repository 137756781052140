import * as Yup from 'yup';

export interface IImpressaoAlteracaoEndereco {
	numeroFicha: string;
	anoFicha: string;
}

export const initialValues: IImpressaoAlteracaoEndereco = {
	numeroFicha: '',
	anoFicha: '',
};

export const schema = Yup.object<IImpressaoAlteracaoEndereco>().shape({
	numeroFicha: Yup.string().required(`Campo Obrigatório: Número da Ficha.`),
	anoFicha: Yup.string().required(`Campo Obrigatório: Ano da Ficha Cadastral.`),
});
