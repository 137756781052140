export enum Types {
	CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES = '@servicosPrefeituras/CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES',
	CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_SUCCESS = '@servicosPrefeituras/CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_SUCCESS',
	CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_FAILURE = '@servicosPrefeituras/CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_FAILURE',

	REMOVE_PREFEITURAS_SERVICOS = '@servicosPrefeituras/REMOVE_PREFEITURAS_SERVICOS',
}

export interface ServicosPrefeiturasState {
	servicosPrefeiturasData: IServicosPrefeiturasData;
}

export interface IServicosPrefeiturasData {
	pdf?: string;
	dados_imovel?: IDadosImovel;
	identificacao?: string;
	parcelas?: IParcelas[];
	proximo_prefeitura_servico?: number;
}

interface IDadosImovel {
	bairro: string;
	cep: string;
	cidade: string;
	estado: string;
	logradouro: string;
	numero: string;
	tipo_logradouro: string;
}

interface IParcelas {
	data_vencimento: string;
	descricao_parcela: string;
	numero_parcela: string;
}
