import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_RELATORIOS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	IRequestFicDigital,
	protocoloFicDigitalSuccess,
	protocoloFicDigitalFailure,
} from './actions';

function* protocoloFicDigitalRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestFicDigital } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_RELATORIOS,
		'/protocolo/:canal/digital',
		payload,
	);

	if (response.status === 200) {
		yield put(protocoloFicDigitalSuccess(response.data));
	} else if (response.error) {
		yield put(protocoloFicDigitalFailure());
	}
}

export default all([
	takeLatest(Types.PROTOCOLO_FIC_DIGITAL_REQUEST, protocoloFicDigitalRequest),
]);
