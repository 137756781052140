import { ReducerAction } from 'store/modules/types';

import { Types, GerarRenachSegundaViaRequest } from './types';

export function gerarRenachSegundaViaRequest(
	payload: GerarRenachSegundaViaRequest,
): ReducerAction {
	return {
		type: Types.GERAR_RENACH_SEGUNDA_VIA_REQUEST,
		payload,
	};
}
export function gerarRenachSegundaViaSuccess(payload: object): ReducerAction {
	return {
		type: Types.GERAR_RENACH_SEGUNDA_VIA_SUCCESS,
		payload,
	};
}
export function gerarRenachSegundaViaFailure(payload: boolean): ReducerAction {
	return {
		type: Types.GERAR_RENACH_SEGUNDA_VIA_FAILURE,
		payload,
	};
}
