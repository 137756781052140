// TYPES
import { ReducerAction } from 'store/modules/types';
import { SalvarAtendimentoRequest, Types } from './types';

export function salvarAtendimentoRequest(
	payload: SalvarAtendimentoRequest,
): ReducerAction {
	return {
		type: Types.SALVAR_ATENDIMENTO_REQUEST,
		payload,
	};
}
export function salvarAtendimentoSuccess(payload: string): ReducerAction {
	return {
		type: Types.SALVAR_ATENDIMENTO_SUCCESS,
		payload,
	};
}
export function salvarAtendimentoFailure(): ReducerAction {
	return {
		type: Types.SALVAR_ATENDIMENTO_FAILURE,
		payload: null,
	};
}
export function salvarAtendimentoClear(): ReducerAction {
	return {
		type: Types.SALVAR_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
