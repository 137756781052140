import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVCE
import { getApi, postApi, deleteApi, putApi } from 'services/_api';
import { PATH_PREFEITURAS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultaServicosSuccess,
	consultaServicosFailure,
	removeServicosSuccess,
	removeServicosFailure,
	cadastraServicosSuccess,
	cadastraServicosFailure,
	alteraServicosSuccess,
	alteraServicosFailure,
	consultaParametrosServicosSuccess,
	consultaParametrosServicosFailure,
	alteraParametrosServicosSuccess,
	alteraParametrosServicosFailure,
	IConsultaServicosRequest,
	IConsultaParametrosServicosRequest,
	ICadastraServicosRequest,
	IAlteraParametrosServicosRequest,
} from './actions';

function* consultaServicos(request: ReducerAction) {
	const { payload }: { payload: IConsultaServicosRequest } = request;

	const response = yield call(getApi, PATH_PREFEITURAS, 'servicos', payload);

	if (response.status === 200) {
		yield put(consultaServicosSuccess(response));
	} else if (response.error) {
		yield put(consultaServicosFailure(response.message));
	}
}

function* consultaParametrosServicos(request: ReducerAction) {
	const { payload }: { payload: IConsultaParametrosServicosRequest } = request;

	const response = yield call(
		getApi,
		PATH_PREFEITURAS,
		`servicos/${payload.codigo}/parametros`,
		{},
	);

	if (response.status === 200) {
		yield put(consultaParametrosServicosSuccess(response));
	} else if (response.status === 204) {
		yield put(consultaParametrosServicosFailure([]));
	} else if (response.error) {
		yield put(consultaParametrosServicosFailure(response.message));
	}
}

function* cadastraServicos(request: ReducerAction) {
	const { payload }: { payload: ICadastraServicosRequest } = request;

	const response = yield call(postApi, PATH_PREFEITURAS, 'servicos', payload);

	if (response.status === 201) {
		yield put(cadastraServicosSuccess(response));
	} else if (response.error) {
		yield put(cadastraServicosFailure(response.message));
	}
}

function* removeServicos(request: ReducerAction) {
	const { payload }: { payload: number } = request;

	const response = yield call(
		deleteApi,
		PATH_PREFEITURAS,
		`servicos/${payload}`,
	);

	if (response.status === 200) {
		yield put(removeServicosSuccess(payload));
	} else if (response.error) {
		yield put(removeServicosFailure(response.message));
	}
}

function* alteraServicos(request: ReducerAction) {
	const { payload }: { payload: ICadastraServicosRequest } = request;

	const body = { ...payload, codigo: null };
	const response = yield call(
		putApi,
		PATH_PREFEITURAS,
		`servicos/${payload.codigo}`,
		body,
	);

	if (response.status === 200) {
		yield put(alteraServicosSuccess(response));
	} else if (response.error) {
		yield put(alteraServicosFailure(response.message));
	}
}

function* alteraParametrosServicos(request: ReducerAction) {
	const { payload }: { payload: IAlteraParametrosServicosRequest } = request;

	const response = yield call(
		putApi,
		PATH_PREFEITURAS,
		`servicos/${payload.codigo}/parametros`,
		payload.body,
	);

	if (response.status === 200) {
		yield put(alteraParametrosServicosSuccess(payload));
	} else if (response.error) {
		yield put(alteraParametrosServicosFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTA_SERVICOS, consultaServicos),
	takeLatest(Types.REMOVE_SERVICOS, removeServicos),
	takeLatest(Types.CADASTRA_SERVICOS, cadastraServicos),
	takeLatest(Types.ALTERA_SERVICOS, alteraServicos),
	takeLatest(Types.ALTERA_PARAMETROS_SERVICOS, alteraParametrosServicos),
	takeLatest(Types.CONSULTA_PARAMETROS_SERVICOS, consultaParametrosServicos),
]);
