import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styled';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({ children, ...rest }) => (
	<Container disabled={rest.disabled} type="button" {...rest}>
		{children}
	</Container>
);

export default Button;
