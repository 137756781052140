export enum Types {
	PESQUISA_FORNECEDOR_REQUEST = '@procon/PESQUISA_FORNECEDOR_REQUEST',
	PESQUISA_FORNECEDOR_SUCCESS = '@procon/PESQUISA_FORNECEDOR_SUCCESS',
	PESQUISA_FORNECEDOR_FAILURE = '@procon/PESQUISA_FORNECEDOR_FAILURE',
	PESQUISA_FORNECEDOR_CLEAR = '@procon/PESQUISA_FORNECEDOR_CLEAR',
}

export interface PesquisaFornecedor {
	status: null | number;
	data: PesquisaFornecedorData[];
}

export interface PesquisaFornecedorData {
	fornededorId: string;
	tipoDeFornecedor: string;
	razaoSocial: string;
	nomeFantasia: string;
	cnpj: string;
	cpf: string;
	inscricaoEstadual: string;
	website: string;
	email: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
	estado: string;
	observacao: string;
}

export interface PesquisaFornecedorRequest {
	termo: string;
}
