export enum Types {
	CONSULTAR_MENSAGENS_REQUEST = '@SGU/CONSULTAR_MENSAGENS_REQUEST',
	CONSULTAR_MENSAGENS_SUCCESS = '@SGU/CONSULTAR_MENSAGENS_SUCCESS',
	CONSULTAR_MENSAGENS_FAILURE = '@SGU/CONSULTAR_MENSAGENS_FAILURE',
	CONSULTAR_MENSAGENS_CLEAR = '@SGU/CONSULTAR_MENSAGENS_CLEAR',
}

export interface ConsultarMensagens {
	status: number;
	data: null | Mensagem;
	totalRegistrosConsulta: number;
}

export interface Mensagem {
	id?: string | number;
	de: string;
	para: string;
	regex: string;
}

export interface ConsultarMensagensRequest {
	registroInicial?: number;
	limite?: number;
	current?: number;
	search?: string | number;
}
