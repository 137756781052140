import { DadosAgendamentoState } from 'store/modules/api/sgu/atendimento/dadosAgendamento/types';
import * as Yup from 'yup';

export interface DadosAgendamentoValues extends DadosAgendamentoState {
	inputsValidation?: boolean;
}

export const initialValues: DadosAgendamentoValues = {
	numeroProtocolo: '',
	senhaAtendimento: '',
};

export function treatValues(
	values: DadosAgendamentoValues,
): DadosAgendamentoValues {
	const formattedData = {
		numeroProtocolo: values.numeroProtocolo || '',
		senhaAtendimento: values.senhaAtendimento || '',
	};

	return formattedData;
}

export const schema = Yup.object<DadosAgendamentoValues>().shape({
	inputsValidation: Yup.string().when(['numeroProtocolo', 'senhaAtendimento'], {
		is: (numeroProtocolo, senhaAtendimento) =>
			numeroProtocolo === '' && senhaAtendimento === '',
		then: Yup.string().required(
			'Campo obrigatório: Número do Protocolo ou Senha.',
		),
	}),
});
