import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, EstatisticaState } from './types';

import incluirEstatistica from './incluir/reducer';

const allReducers = combineReducers({
	incluirEstatistica,
});

const combineReducer = (state: EstatisticaState, action: ReducerAction) => {
	if (action.type === Types.ESTATISTICA_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
