// ROUTES
import { ROUTE_BOLSA_POVO } from 'routes/paths';

// HOME
export const ROUTE_BOLSA_POVO_RECUPERAR_ATENDIMENTO = `${ROUTE_BOLSA_POVO}/recuperar-atendimento`;
export const ROUTE_BOLSA_POVO_JUSTIFICATIVA_ATENDIMENTO = `${ROUTE_BOLSA_POVO}/recuperar-atendimento/justificativa`;

// OPÇÔES
export const ROUTE_BOLSA_POVO_DESBLOQUEIO_CARTAO = `${ROUTE_BOLSA_POVO}/desbloqueio-cartao`;
export const ROUTE_BOLSA_POVO_ALTERA_ENDERECO = `${ROUTE_BOLSA_POVO}/altera-endereco`;
export const ROUTE_BOLSA_POVO_ALTERACAO_ENDERECO = `${ROUTE_BOLSA_POVO}/alteracao-endereco`;
export const ROUTE_BOLSA_POVO_PESQUISA_ALTERACAO_ENDERECO = `${ROUTE_BOLSA_POVO}/pesquisa-alteracao-endereco`;
export const ROUTE_BOLSA_POVO_CONFIRMACAO = `${ROUTE_BOLSA_POVO}/confirmacao`;
