import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty, limparMascara } from 'utils/genericFunctions';
import { ConsultarUsuariosRequest, Types } from './types';
import { consultarUsuariosSuccess, consultarUsuariosFailure } from './actions';

function* consultarUsuariosRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultarUsuariosRequest } = request;

	let body = { ...payload };

	if (body.tipoDocumento === 'cpf') {
		body.cpf = limparMascara(body.cpf);
		delete body.nome;
	} else {
		delete body.cpf;
	}

	delete body.tipoDocumento;

	delete body.current;

	body = cleanEmpty(body) as ConsultarUsuariosRequest;

	const response: ApiResponse = yield call(getApi, PATH_SGU, `usuarios`, body);
	if (response.status === 200 || response.status === 201) {
		yield put(consultarUsuariosSuccess(response));
	} else if (response.error) {
		yield put(consultarUsuariosFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_USUARIOS_REQUEST, consultarUsuariosRequest),
]);
