export enum Types {
	MULTAS_RENAINF_PLACA_REQUEST = '@DETRAN-CRV/MULTAS_RENAINF_PLACA_REQUEST',
	MULTAS_RENAINF_PLACA_SUCCESS = '@DETRAN-CRV/MULTAS_RENAINF_PLACA_SUCCESS',
	MULTAS_RENAINF_PLACA_FAILURE = '@DETRAN-CRV/MULTAS_RENAINF_PLACA_FAILURE',
	MULTAS_RENAINF_PLACA_CLEAR = '@DETRAN-CRV/MULTAS_RENAINF_PLACA_CLEAR',
}
export interface multasRenainfPlaca {
	status: number;
	data: IDadosRenainfPlaca | null;
}

interface IDadosRenainfPlaca {
	dadosRenainfPlaca: {
		indicador: string;
		placa: string;
		quantidadeOcorrencia: number;
		ufEmplacamento: string;
		aceiteUfJurisdicaoSuspensaoCancelamento: string;
		cnhCondutor: number;
		cnhInfrator: number;
		codigoInfracao: string;
		dataAceiteUfHabilitacao: string;
		dataCadastroInfracao: string;
		dataEmissao: string;
		dataEmissaoPenalidade: string;
		dataHoraInfracao: string;
		dataNotificacaoInfracao: string;
		dataPagamentoInfracao: string;
		dataRegistroPagamento: string;
		dataRegistroSuspensaoCancelamento: string;
		indicadorExigibilidade: string;
		limitePermitido: number;
		localInfracao: string;
		marca: {
			longId: number;
			descricao: string;
		};
		medicaoConsiderada: number;
		medicaoReal: number;
		municipioEmplacamento: {
			codigo: number;
			nome: string;
		};
		municipioInfracao: {
			codigo: number;
			nome: string;
		};
		numeroAutoInfracao: string;
		orgaoAutuador: string;
		origemSuspensaoCancelamento: number;
		tipoInfracao: string;
		tipoSuspensaoCancelamento: number;
		ufJurisdicaoVeiculo: string;
		ufOrgaoAutuador: string;
		ufPagamento: string;
		unidadeMedida: string;
		valorInfracao: number;
		valorPago: number;
		ocorrencias: [
			{
				longId: number;
				descricao: string;
			},
		];
	};
}
export interface IRequestConsultaRenainfPlaca {
	evento: IEvento;
	placa: string;
	ufPlaca: string;
	dataInicio: string;
	dataFim: string;
	indicador: string;
}
export interface IEvento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	idAtendimento?: string;
	idCidadao?: string;
}
