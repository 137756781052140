import React from 'react';

import { Row, Col } from 'antd';
import { Field, FormikProps } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultarMunicipioPorUfRequest } from 'store/modules/api/detranCrv/enum/consultarMunicipioPorUF/actions';
import { consultarUnidadeTransitoRequest } from 'store/modules/api/detranCrv/enum/consultaUnidadeTransito/actions';
import data from 'store/modules/api/detranCrv/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';

// UTILS
import hasError from 'utils/getFormErrors';

// FORM
import { IEmitirSegundaViaPlaca } from '../form';

interface Props {
	formik: FormikProps<IEmitirSegundaViaPlaca>;
}

const DadosSegundaVia: React.FC<Props> = ({ formik }) => {
	const dispatch = useDispatch();
	const { dataUnidades } = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.Enum.consultarUnidadesTransito,
	);

	const dataUfSp = useSelector(
		(state: ApplicationState) =>
			state.api.detranCrv.Enum.consultarMunicipioPorUF.data,
	);

	return (
		<>
			<Row>
				<Col span={24}>
					<Section
						size="lg"
						title="Dados para a Segunda Via da Placa"
						titleSize="sm"
					>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Field
									as={Input}
									title="Autenticação Digital (emplacamento)"
									titleSize="lg"
									name="autenticacaoDigital"
									maxLength={60}
									size={87}
									error={!!formik.errors.autenticacaoDigital}
								/>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Select}
									disabled
									title="UF destino da lacração"
									titleSize="lg"
									name="ufDestinoLacracao"
									options={data.uf}
									onChange={(v: number) => {
										formik.setFieldValue('ufDestinoLacracao', v);
										dispatch(consultarMunicipioPorUfRequest(v.toString()));
										const selectedItem = data.uf.filter(
											item => v.toString() === item.value,
										);

										formik.setFieldValue(
											'ufDestinoLacracao',
											selectedItem[0].value,
										);
									}}
									size={92}
									required
									error={hasError(formik.errors, 'ufDestinoLacracao')}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Select}
									title="Município destino da lacração"
									titleSize="lg"
									name="codigoMunicipioLacracao"
									options={dataUfSp}
									onChange={(v: number) => {
										formik.setFieldValue('codigoMunicipioLacracao', v);

										// CHAMA SERVIÇO DE UNIDADE DE TRANSITO
										dispatch(
											consultarUnidadeTransitoRequest({
												codigo_municipio: v,
												codigo_unidade_transito: 0,
											}),
										);
										const selectedItem = dataUfSp.filter(
											item => v === item.value,
										);

										formik.setFieldValue(
											'codigoMunicipioLacracao',
											selectedItem[0].value ? selectedItem[0].value : '',
										);

										formik.setFieldValue(
											'descricaoMunicipioLacracao',
											selectedItem[0].label,
										);
									}}
									size={92}
									required
									error={hasError(formik.errors, 'codigoMunicipioLacracao')}
								/>
							</Col>
						</Row>

						<Row gutter={[0, 10]}>
							<Col span={11}>
								<Field
									as={Select}
									title="Unidade de Trânsito que autoriza"
									titleSize="lg"
									defaultFirstOptionText="SELECIONE O MUNICIPIO"
									name="unidadeTransitoAutoriza"
									options={dataUnidades || []}
									onChange={(v: number) => {
										formik.setFieldValue('unidadeTransitoAutoriza', v);

										const selectedItem = dataUnidades.filter(
											item => v === item.value,
										);

										formik.setFieldValue(
											'unidadeTransitoAutoriza',
											selectedItem[0].value,
										);
									}}
									size={92}
									required
									error={hasError(formik.errors, 'unidadeTransitoAutoriza')}
								/>
							</Col>

							<Col span={11}>
								<Field
									as={Select}
									title="Motivo da solicitação"
									titleSize="lg"
									name="motivoSegundaViaPlaca"
									options={data.motivoSegundaViaPlaca}
									onChange={(v: number) => {
										formik.setFieldValue('motivoSegundaViaPlaca', v);

										const selectedItem = data.motivoSegundaViaPlaca.filter(
											item => v.toString() === item.value,
										);

										formik.setFieldValue(
											'motivoSegundaViaPlaca',
											selectedItem[0].value ? selectedItem[0].value : '',
										);
									}}
									size={92}
									required
									error={hasError(formik.errors, 'codigoMunicipioLacracao')}
								/>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default DadosSegundaVia;
