import React, { Suspense, useEffect } from 'react';

import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { multasRenainfPlacaRequest } from 'store/modules/api/detranCrv/MultasRenainf/multasRenainfPlaca/actions';
import data from 'store/modules/api/detranCrv/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';
import Placa from 'components/Common/Form/Fields/Placa';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { formatDateISO } from 'utils/genericFunctions';

// FORM
import { multasRenainfOrgaoRequest } from 'store/modules/api/detranCrv/MultasRenainf/multasRenainfOrgao/actions';
import { initialValues, schema } from './form';

const MultasRenainfPorPlaca: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { multasRenainfPlaca, multasRenainfOrgao } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.multasRenainf,
	);

	const { salvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento,
	);

	useEffect(() => {
		if (
			multasRenainfPlaca.status === 200 &&
			multasRenainfPlaca.data?.dadosRenainfPlaca.quantidadeOcorrencia === 1
		) {
			const newOrgao =
				multasRenainfPlaca.data?.dadosRenainfPlaca.orgaoAutuador.split(' ');
			const newCodigo =
				multasRenainfPlaca.data?.dadosRenainfPlaca.codigoInfracao.split(' ');

			const body = {
				evento: {
					id_atendimento: salvarAtendimento.data?.idAtendimento
						? salvarAtendimento.data.idAtendimento
						: '',
					id_cidadao: salvarAtendimento.data?.idCidadao
						? salvarAtendimento.data.idCidadao
						: '',
					cpf: salvarAtendimento.data?.cpf ? salvarAtendimento.data.cpf : '',
				},
				codigoOrgaoAutuador: newOrgao[0],
				numeroInfracao:
					multasRenainfPlaca.data?.dadosRenainfPlaca.numeroAutoInfracao,
				codigoInfracao: newCodigo[0],
			};
			// const formatValuesReq = treatValues(recuperarAtendimento.data, body);
			dispatch(multasRenainfOrgaoRequest(body));
		} else if (multasRenainfPlaca.status === 200) {
			history.push('/detran-crv/multas-renainf-placa/resultado');
		}
	}, [multasRenainfPlaca, history, dispatch, salvarAtendimento.data]);

	useEffect(() => {
		if (multasRenainfOrgao.status === 200) {
			history.push('/detran-crv/multas-renainf-orgao-autuador/resultado');
		}
	}, [multasRenainfOrgao, history]);

	const indicadorExigibilidade = [
		{
			label: 'MULTAS_COBRANCA',
			value: 'MULTAS_COBRANCA',
		},
		{
			label: 'TODAS_MULTAS',
			value: 'TODAS_MULTAS',
		},
	];

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Consultar RENAINF por Placa">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						const body = {
							evento: {
								id_atendimento: salvarAtendimento.data?.idAtendimento
									? salvarAtendimento.data.idAtendimento
									: '',
								id_cidadao: salvarAtendimento.data?.idCidadao
									? salvarAtendimento.data.idCidadao
									: '',
								cpf: salvarAtendimento.data?.cpf
									? salvarAtendimento.data.cpf
									: '',
							},
							dataFim: formatDateISO(values.dataFim),
							dataInicio: formatDateISO(values.dataInicio),
							indicador: values.indicador,
							placa: values.placa,
							ufPlaca: values.ufPlaca,
						};
						dispatch(multasRenainfPlacaRequest(body));
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[0, 20]}>
									<Col span={12}>
										<Placa
											title="Placa"
											titleSize="lg"
											name="placa"
											defaultValue={initialValues.placa}
											size={92}
											required
											formik={formik}
										/>
									</Col>
									<Col span={12}>
										<Field
											as={Select}
											titleSize="lg"
											size={80}
											defaultFirstOption
											name="ufPlaca"
											title="Estado"
											subtitleColor="red"
											options={data.uf}
											error={!!formik.errors.ufPlaca}
											onChange={(v: string) =>
												formik.setFieldValue('ufPlaca', v)
											}
										/>
									</Col>
									<Col span={12}>
										<Field
											required
											titleSize="lg"
											id="dataExpedicaoRg"
											title="Período de Consulta: Início"
											name="dataInicio"
											type="text"
											mask="99/99/9999"
											subtitle="(DD/MM/AAAA)"
											size={40}
											as={ValidDataInput}
											error={hasError(formik.errors, 'dataInicio')}
											formik={formik}
											onChange={(v: string) =>
												formik.setFieldValue('dataInicio', v)
											}
										/>
									</Col>
									<Col span={12}>
										<Field
											required
											titleSize="lg"
											id="dataExpedicaoRg"
											title="Período de Consulta: Fim"
											name="dataFim"
											type="text"
											mask="99/99/9999"
											subtitle="(DD/MM/AAAA)"
											size={40}
											as={ValidDataInput}
											error={hasError(formik.errors, 'dataFim')}
											formik={formik}
											onChange={(v: string) =>
												formik.setFieldValue('dataFim', v)
											}
										/>
									</Col>
									<Col span={24}>
										<Field
											required
											titleSize="lg"
											as={Select}
											size={25}
											defaultFirstOption
											name="indicador"
											title="Indicador de Exigibilidade"
											subtitleColor="red"
											options={indicadorExigibilidade}
											error={!!formik.errors.indicador}
											onChange={(v: string) =>
												formik.setFieldValue('indicador', v)
											}
										/>
									</Col>
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={formik.handleReset}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<Row justify="space-between" align="bottom">
				<Col>
					<ButtonVoltar route="/detran-crv" />
				</Col>
			</Row>
		</Suspense>
	);
};

export default MultasRenainfPorPlaca;
