import React, { useCallback } from 'react';

// ANTD
import { Col, Row } from 'antd';

// COMPONENTS
import TypographyCommon from 'components/Common/Typography';

// PATHS
import Section from 'components/Common/Section';

// REDUX
import { ConsultaExtratoResumoCondutorData } from 'store/modules/api/detranCnh/eportal/consultaExtratoResumoCondutor/types';

// UTILS
import { isFilledValue } from 'utils/stringFunctions';

import { formatDate } from 'utils/genericFunctions';

interface Props {
	consultaExtratoResumoCondutorPCON:
		| ConsultaExtratoResumoCondutorData['pcon']
		| null
		| undefined;
	consultaExtratoResumoCondutorGFOR:
		| ConsultaExtratoResumoCondutorData['gfor']
		| null
		| undefined;
	tipoProcessoGfor:
		| ConsultaExtratoResumoCondutorData['tipoProcessoGfor']
		| null
		| undefined;
}

const CNH: React.FC<Props> = ({
	consultaExtratoResumoCondutorPCON = null,
	consultaExtratoResumoCondutorGFOR = null,
	tipoProcessoGfor = null,
}) => {
	const handleIsPaidActivity = useCallback(() => {
		return consultaExtratoResumoCondutorPCON?.flagAtividadeRemunerada;
	}, [consultaExtratoResumoCondutorPCON]);

	return (
		<>
			<Section title="CNH (PCON/GFOR)">
				<Row gutter={[0, 0]} align="top">
					<Col span={8}>
						<TypographyCommon
							text="Habilitado para Categoria:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text={isFilledValue(
								consultaExtratoResumoCondutorPCON?.categoriaChn,
							)}
							type="label"
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text="Data da Habilitação:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text={isFilledValue(
								formatDate(consultaExtratoResumoCondutorPCON?.dataHabilitacao),
							)}
							type="label"
						/>
					</Col>
				</Row>
				<Row gutter={[0, 0]} align="top">
					<Col span={8}>
						<TypographyCommon
							text="Exame de Sanidade Física e Mental Válido Até:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text={isFilledValue(
								formatDate(consultaExtratoResumoCondutorPCON?.dataValidade),
							)}
							type="label"
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text="Local:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text={isFilledValue(consultaExtratoResumoCondutorPCON?.local)}
							type="label"
						/>
					</Col>
				</Row>
				<Row gutter={[0, 0]} align="top">
					<Col span={8}>
						<TypographyCommon
							text="Data da Solicitação da Emissão:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text={isFilledValue(
								formatDate(consultaExtratoResumoCondutorPCON?.dataEmissao),
							)}
							type="label"
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text="Município:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text={isFilledValue([
								consultaExtratoResumoCondutorPCON?.codigoMunicipio,
								consultaExtratoResumoCondutorPCON?.municipioDescricao,
							])}
							type="label"
						/>
					</Col>
				</Row>
				<Row gutter={[0, 0]} align="top">
					<Col span={8}>
						<TypographyCommon
							text="Último Processo:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={16}>
						<TypographyCommon
							text={isFilledValue(tipoProcessoGfor)}
							type="label"
						/>
					</Col>
				</Row>
				<Row gutter={[0, 0]} align="top">
					<Col span={8}>
						<TypographyCommon
							text="Usuário Renach Gerado:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text={isFilledValue(consultaExtratoResumoCondutorGFOR?.usuario)}
							type="label"
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text="Data Renach Gerado:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={5}>
						<TypographyCommon
							text={isFilledValue([
								formatDate(consultaExtratoResumoCondutorGFOR?.dataAbertura),
								consultaExtratoResumoCondutorGFOR?.horaAbertura,
							])}
							type="label"
						/>
					</Col>
				</Row>
				<Row gutter={[0, 0]} align="top">
					<Col span={8}>
						<TypographyCommon
							text="Origem:"
							type="label"
							fontWeight="bold"
							uppercase
						/>
					</Col>
					<Col span={16}>
						<TypographyCommon
							text={isFilledValue(consultaExtratoResumoCondutorGFOR?.gerado)}
							type="label"
						/>
					</Col>
				</Row>
				{handleIsPaidActivity() && (
					<Row gutter={[0, 0]} align="top">
						<Col span={24}>
							<TypographyCommon
								text="Exerce Atividade Remunerada"
								type="label"
								fontWeight="bold"
								uppercase
							/>
						</Col>
					</Row>
				)}
			</Section>
		</>
	);
};
export default CNH;
