import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { EnviaRespostasAtendimentoRequest, Types } from './types';

// ACTIONS
import {
	enviaRespostasAtendimentoSuccess,
	enviaRespostasAtendimentoFailure,
} from './actions';

function* enviaRespostasAtendimentoRequest(request: ReducerAction) {
	const { payload }: { payload: EnviaRespostasAtendimentoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`interacoes/${CHANNEL}/atendimentos/interacoes-respostas`,
		payload,
	);

	if (response.status === 200) {
		yield put(enviaRespostasAtendimentoSuccess(response));
	} else {
		yield put(enviaRespostasAtendimentoFailure(response));
	}
}

export default all([
	takeLatest(
		Types.ENVIA_RESPOSTAS_ATENDIMENTO_REQUEST,
		enviaRespostasAtendimentoRequest,
	),
]);
