import { takeLatest, put, all } from 'redux-saga/effects';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { GerarRenachIntegradoRequest, Types } from './types';

// ACTIONS
import { saveGerarRenachSuccess, saveGerarRenachFailure } from './actions';

function* saveGerarRenach(request: ReducerAction) {
	const { payload }: { payload: GerarRenachIntegradoRequest } = request;

	const response = payload;

	if (payload.candidato?.cpf !== '') {
		yield put(saveGerarRenachSuccess(response));
	} else if (payload === null) {
		yield put(saveGerarRenachFailure());
	}
}

export default all([
	takeLatest(Types.SAVE_GERAR_RENACH_REQUEST, saveGerarRenach),
]);
