export enum Types {
	CONSULTAR_MUNICIPIOS_REQUEST = '@cnh/CONSULTAR_MUNICIPIOS_REQUEST',
	CONSULTAR_MUNICIPIOS_SUCCESS = '@cnh/CONSULTAR_MUNICIPIOS_SUCCESS',
	CONSULTAR_MUNICIPIOS_FAILURE = '@cnh/CONSULTAR_MUNICIPIOS_FAILURE',
}

export interface ConsultarMunicipios {
	status: number;
	data: null | ConsultarMunicipiosData;
}

export interface ConsultarMunicipiosRequest {
	canal?: string;
	paginaInicial?: number;
	limite?: number;
	codigo?: string | number;
	nome?: string;
	uf?: string;
}

interface ConsultarMunicipiosData {
	idProcesso: number;
	mensagem: string;
	municipios: IMunicipios[];
	paginaInicio: number;
	limite: number;
}

interface IMunicipios {
	codigo: number;
	nome: string;
	uf: string;
}
