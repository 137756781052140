import { ReducerAction } from 'store/modules/types';

import { Types, ConsultarMunicipiosIbgeRequest } from './types';

export function consultarMunicipiosIbgeRequest(
	data: ConsultarMunicipiosIbgeRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_IBGE_REQUEST,
		payload: data,
	};
}
export function consultarMunicipiosIbgeSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_IBGE_SUCCESS,
		payload,
	};
}
export function consultarMunicipiosIbgeFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_IBGE_FAILURE,
		payload: null,
	};
}
export function consultarMunicipiosIbgeClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_IBGE_CLEAR,
		payload: null,
	};
}
