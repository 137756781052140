import produce from 'immer';

// TYPES
import { Types, agendarExamesRenovacao } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: agendarExamesRenovacao = {
	status: 0,
	data: null,
};

export default function agendarExamesRenovacaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): agendarExamesRenovacao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.AGENDAR_EXAMES_RENOVACAO: {
				break;
			}

			case Types.AGENDAR_EXAMES_RENOVACAO_SUCCESS: {
				draft.data = action.payload.data;
				break;
			}

			case Types.AGENDAR_EXAMES_RENOVACAO_FAILURE: {
				break;
			}

			default:
		}
	});
}
