import { ReducerAction } from 'store/modules/types';

import { Types, IRequestWithHeaderLocalidadeCreate } from './types';

export function cadastrarLocalidadeRequest(
	payload: IRequestWithHeaderLocalidadeCreate,
) {
	return {
		type: Types.CADASTRAR_LOCALIDADE_REQUEST,
		payload,
	};
}
export function cadastrarLocalidadeSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_LOCALIDADE_SUCCESS,
		payload,
	};
}
export function cadastrarLocalidadeFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_LOCALIDADE_FAILURE,
		payload: null,
	};
}
export function cadastrarLocalidadeClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_LOCALIDADE_CLEAR,
		payload: null,
	};
}
