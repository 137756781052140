import React, { ChangeEvent, Suspense, useCallback, useEffect } from 'react';

import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { consultarBloqueioDetranRenajudRequest } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarBloqueioDetranRenajud/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import Radio from 'components/Common/Form/Radio';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

// FORM
import {
	IConsultaBloqueioDetranRenajud,
	initialValues,
	schema,
	treatValues,
} from './form';

const ConsultarBloqueioDetranRenajud: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { consultarBloqueioDetranRenajud } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	useEffect(() => {
		if (consultarBloqueioDetranRenajud.status === 200) {
			history.push('/detran-crv/consultar-bloqueio-detran-renajud/resultado');
		}
	}, [consultarBloqueioDetranRenajud.status, history]);

	const handleValidate = useCallback(
		(values: IConsultaBloqueioDetranRenajud) =>
			getValidationsErrors(values, schema),
		[],
	);

	const handleSubmit = useCallback(
		(values: IConsultaBloqueioDetranRenajud) => {
			const formatValuesReq = treatValues(values);
			dispatch(consultarBloqueioDetranRenajudRequest(formatValuesReq));
		},
		[dispatch],
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={handleValidate}
					onSubmit={handleSubmit}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[0, 20]}>
									<Col span={24}>
										<p>
											<strong>Chassi</strong>
										</p>
										<Field
											size={20}
											maxLength={22}
											name="chassi"
											as={Input}
											error={hasError(formik.errors, 'chassi')}
										/>
									</Col>
									<Col span={24}>
										<Field
											name="selecao"
											as={Radio}
											options={[
												{
													value: '1',
													label: 'Bloqueios Ativos DETRAN',
												},
												{
													value: '2',
													label: 'Bloqueios Judiciais RENAJUD',
												},
											]}
											defaultValue="1"
											error={hasError(formik.errors, 'selecao')}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												const { value } = event.target;
												formik.setFieldValue('selecao', value);
											}}
										/>
									</Col>
								</Row>
								<Row justify="center" align="top" gutter={[20, 10]}>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={formik.handleReset}
										/>
									</Col>
									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
		</Suspense>
	);
};

export default ConsultarBloqueioDetranRenajud;
