export default {
	tipoVinculo: [
		{
			value: 808620009,
			label: 'Agente Municipal',
		},
		{
			value: 808620000,
			label: 'Cidadão sem Vínculo',
		},
		{
			value: 808620002,
			label: 'Componente do Contrato/Termo',
		},
		{
			value: 808620008,
			label: 'Membro da Família',
		},
		{
			value: 808620001,
			label: 'Mutuário/Beneficiário',
		},
		{
			value: 808620006,
			label: 'Ocupante Cliente Potencial',
		},
		{
			value: '808620007',
			label: 'Ocupante Irregular',
		},
		{
			value: '808620003',
			label: 'Representante Legal',
		},
		{
			value: '10',
			label: 'Responsável Financeiro',
		},
	],
	servicos: [
		{
			value: 'boletoAgrupado',
			label: 'Boleto Agrupado',
		},
		{
			value: 'acordo',
			label: 'Acordo',
		},
		{
			value: 'transfTitularidade',
			label: 'Transferência de Titularidade',
		},
		{
			value: 'seguroHabitacional',
			label: 'Seguro Habitacional',
		},
		{
			value: 'inscricoesProgHabit',
			label: 'Inscrições e Programas Habitacionais',
		},
		{
			value: 'situacaoFinanceira',
			label: 'Situação Financeira',
		},
		{
			value: 'saldoDevedor',
			label: 'Saldo Devedor',
		},
	],
	subservicos: {
		transferenciaTitularidade: [
			{
				value: 'transfTitularidadeInfo',
				label: 'Informação',
			},
		],
		seguroHabitacional: [
			{
				value: 'seguroHabitacionalInfo',
				label: 'Informação',
			},
		],
		inscricoesProgHabit: [
			{
				value: 'inscricoesProgHabitInfo',
				label: 'Informação',
			},
		],
		situacaoFinanceira: [
			{
				value: 'situacaoFinanceiraInfo',
				label: 'Informação',
			},
		],
		saldoDevedor: [
			{
				value: 'saldoDevedorInfo',
				label: 'Informação',
			},
		],
	},
	motivosBoletoAgrupado: [
		{
			value: 808620000,
			label: 'Boleto Vencido',
		},
		{
			value: 808620001,
			label: 'Prestação Bloqueada',
		},
		{
			value: 808620002,
			label: 'Data de Vencimento Inadequada',
		},
	],
};
