import { combineReducers } from 'redux';

import consultaInteracoes from './consultaInteracoes/reducer';
import enviaInteracoes from './enviaInteracoes/reducer';
import enviaRespostas from './enviaRespostas/reducer';

export default combineReducers({
	consultaInteracoes,
	enviaInteracoes,
	enviaRespostas,
});
