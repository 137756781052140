import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_RELATORIOS } from 'services/_path';
import { Types } from './types';
import {
	impressaoGeraSenhaSuccess,
	impressaoGeraSenhaFailure,
} from './actions';

function* impressaoGeraSenhaRequest({ payload }: any) {
	const response: ApiResponse = yield call(
		postApi,
		PATH_RELATORIOS,
		`protocolo/${CHANNEL}/gera-senha`,
		payload,
	);
	if (response.status === 200) {
		yield put(impressaoGeraSenhaSuccess(response));
	} else if (response.error) {
		yield put(impressaoGeraSenhaFailure(response.message));
	}
}

export default all([
	takeLatest(Types.IMPRESSAO_GERA_SENHA_REQUEST, impressaoGeraSenhaRequest),
]);
