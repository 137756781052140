import React from 'react';

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

// STYLED
import { Container, Content, SubTitle } from './styled';

interface CheckBoxProps {
	subtitle?: string;
	name: string;
	defaultChecked?: boolean;
	checked: boolean;
	indeterminate?: boolean;
	disabled?: boolean;
	autoFocus?: boolean;
	error?: boolean;
	strong?: boolean;
	marginLeft?: number;
	onChange: (e: CheckboxChangeEvent) => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({
	subtitle,
	name,
	defaultChecked = false,
	checked,
	disabled = false,
	strong = true,
	marginLeft = 0,
	onChange,
}) => {
	return (
		<Container>
			<Content marginLeft={marginLeft}>
				<Checkbox
					id={name}
					defaultChecked={defaultChecked}
					checked={checked}
					disabled={disabled}
					onChange={onChange}
					onKeyDown={e => {
						e.key === 'Enter' && e.preventDefault();
					}}
				>
					<SubTitle htmlFor={name} disabled={disabled} strong={strong}>
						{subtitle}
					</SubTitle>
				</Checkbox>
			</Content>
		</Container>
	);
};

export default CheckBox;
