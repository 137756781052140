import { ReducerAction } from 'store/modules/types';
import { Types, ConsultaTransferenciaEnderecoRequest } from './types';

export function consultaTransferenciaEnderecoRequest(
	data: ConsultaTransferenciaEnderecoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_TRANSFERENCIA_ENDERECO,
		payload: data,
	};
}
export function consultaTransferenciaEnderecoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_TRANSFERENCIA_ENDERECO_SUCCESS,
		payload,
	};
}
export function consultaTransferenciaEnderecoFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.CONSULTA_TRANSFERENCIA_ENDERECO_FAILURE,
		payload,
	};
}
export function consultaTransferenciaEnderecoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_TRANSFERENCIA_ENDERECO_CLEAR,
		payload: null,
	};
}
