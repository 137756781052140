import React, { useCallback, useEffect, useState } from 'react';

import { Col, Row, Modal } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD_CONFERENCIA_DADOS } from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { IConsultarDare } from 'store/modules/api/dare/consultarDare/types';
import {
	atualizarDareRequest,
	// atualizarDareSuccess,
	IRequestAtualizarDare,
} from 'store/modules/api/dare/atualizarDare/actions';
// import { cadastroRgVincularDare } from 'store/modules/api/iirgd/cadastroRg/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';

interface Props {
	listaDare: IConsultarDare;
	isDisabled: boolean;
}

const Selecionar: React.FC<Props> = ({ listaDare, isDisabled }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [dareSelecionada, setDareSelecionada] = useState<
		IConsultarDare | IRequestAtualizarDare | null
	>(null);

	const { atualizarDare } = useSelector(
		(state: ApplicationState) => state.api.dare,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	useEffect(() => {
		if (atualizarDare.status === 200 || atualizarDare.status === 201) {
			history.push(ROUTE_IIRGD_CONFERENCIA_DADOS);
		}
	}, [atualizarDare.status, dareSelecionada, dispatch, history]);

	const [isento, setIsento] = useState<boolean>(false);

	useEffect(() => {
		if (cadastroRg.form.observacoes) {
			const { primeiraVia, segundaVia } = cadastroRg.form.observacoes;

			if (segundaVia) {
				const { declaracaoPobreza, isento2Via062, isento2Via10952 } =
					segundaVia;

				if (
					[declaracaoPobreza, isento2Via062, isento2Via10952].includes(true)
				) {
					setIsento(true);
				} else {
					setIsento(false);
				}
			} else if (primeiraVia) {
				setIsento(true);
			}
		}
	}, [cadastroRg.form.observacoes]);

	const handleSubmit = useCallback(() => {
		const body: IRequestAtualizarDare = {
			numeroDare: listaDare.numeroControleGuiaMae.toString(),
			rgAtendimento: cadastroRg.form.principal?.numeroRg
				? cadastroRg.form.principal?.numeroRg
				: '',
			isento: isento ? 'true' : 'false',
			entregaCorreio:
				cadastroRg.form.principal?.entrega !== 'P' &&
				cadastroRg.form.principal?.entrega !== 'K'
					? 'true'
					: 'false',
			dare: listaDare,
		};

		setDareSelecionada(body);
		dispatch(atualizarDareRequest(body));
	}, [cadastroRg, dispatch, isento, listaDare]);

	const handleVincularDare = useCallback(
		(dareControleMae: number, rg: string) => {
			if (errors?.length) dispatch(clearNotifications());

			Modal.confirm({
				title: 'Confirmar Vinculação!',
				centered: true,
				closable: true,
				content: (
					<Row style={{ textAlign: 'end' }}>
						<Col span={24}>
							<h5>Deseja Vincular a Dare Nº:</h5>
						</Col>
						<Col span={24}>
							<p>{dareControleMae}</p>
						</Col>
						<Col span={24}>
							<h5>Com o RGE CIN de Nº:</h5>
						</Col>
						<Col span={24}>
							<p>{rg}</p>
						</Col>
					</Row>
				),
				okText: 'Confirmar',
				cancelText: 'Cancelar',
				onOk() {
					handleSubmit();
				},
				onCancel() {},
			});
		},
		[dispatch, errors, handleSubmit],
	);

	const handleClick = useCallback(() => {
		if (cadastroRg.form.principal) {
			const { numeroRg } = cadastroRg.form.principal;
			handleVincularDare(listaDare.numeroControleGuiaMae, numeroRg);
		}
	}, [
		cadastroRg.form.principal,
		handleVincularDare,
		listaDare.numeroControleGuiaMae,
	]);

	return (
		<>
			<Row justify="center">
				<Col>
					<ButtonImage
						src="selecionar"
						onClick={handleClick}
						disabled={isDisabled}
					/>
				</Col>
			</Row>
		</>
	);
};

export default Selecionar;
