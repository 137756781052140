import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import { tiposResolucaoSuccess, tiposResolucaoFailure } from './actions';

function* tiposResolucaoRequest() {
	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`dominios-crm/${CHANNEL}/tipos-resolucao`,
		{},
	);

	if (response.status === 200) {
		yield put(tiposResolucaoSuccess(response));
	} else {
		yield put(tiposResolucaoFailure(response));
	}
}

export default all([
	takeLatest(Types.TIPOS_RESOLUCAO_REQUEST, tiposResolucaoRequest),
]);
