import produce from 'immer';
// TYPES
import { Types, AtualizarContato } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: AtualizarContato = {
	status: 0,
	data: null,
};

export default function atualizarContato(
	state = INITIAL_STATE,
	action: ReducerAction,
): AtualizarContato {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ATUALIZAR_CONTATO: {
				break;
			}

			case Types.ATUALIZAR_CONTATO_SUCCESS: {
				draft.status = action.payload.response.status;
				draft.data = action.payload.response.data;
				break;
			}

			case Types.ATUALIZAR_CONTATO_FAILURE: {
				draft.status = 400;
				draft.data = action.payload;
				break;
			}

			default:
		}
	});
}
