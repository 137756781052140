import * as Yup from 'yup';

export interface IConsultarBaseOutrosEstados {
	chassi: string;
	uf: string;
	placa?: string;
}

export const initialValues: IConsultarBaseOutrosEstados = {
	chassi: '',
	uf: '',
};

export function treatValues(
	values: IConsultarBaseOutrosEstados,
): IConsultarBaseOutrosEstados {
	const formattedData = {
		chassi: values.chassi?.trim() || '',
		placa: values.placa?.trim() || '',
		uf: values.uf?.trim() || '',
	};

	return formattedData;
}

export const schema = Yup.object<IConsultarBaseOutrosEstados>().shape({
	chassi: Yup.string().required(`Campo Obrigatório: Chassi.`),
	uf: Yup.string().required(`Campo Obrigatório: UF.`),
});
