import JustificativaAtendimento from 'components/Pages/JustificativaAtendimento';
import CadastroConsulta from '../CadastrarConsulta/CadastroConsulta';

import RecuperarAtendimento from '../RecuperarAtendimento';
import CadastroConsultaSucesso from '../CadastrarConsulta/Sucesso';
import CadastrarReclamacao from '../CadastrarReclamacao';
import ConsultaAtendimentos from '../Atendimentos/ConsultaAtendimentos';
import VisualizarAtendimento from '../Atendimentos/VisualizarAtendimento';

// PATHS
import {
	ROUTE_PROCON_RECUPERAR_ATENDIMENTO,
	ROUTE_PROCON_JUSTIFICATIVA_ATENDIMENTO,
	ROUTE_PROCON_CADASTRAR_CONSULTA,
	ROUTE_PROCON_CADASTRAR_RECLAMACAO_CADASTRO_CONSULTA,
	ROUTE_PROCON_PESQUISA_ATENDIMENTO_MANIFESTACAO,
	ROUTE_PROCON_CADASTRAR_RECLAMACAO,
	ROUTE_PROCON_CADASTRAR_RECLAMACAO_CONFIRMACAO,
	ROUTE_PROCON_CADASTRAR_CONSULTA_SUCESSO,
	ROUTE_PROCON_PESQUISA_ATENDIMENTO,
} from './paths';
import ConfirmacaoReclamacao from '../CadastrarReclamacao/Confirmacao';

export const proconRoute = {
	name: 'Procon',
	route: '/procon',
};

export default [
	{
		path: ROUTE_PROCON_RECUPERAR_ATENDIMENTO,
		breadcrumb: [
			proconRoute,
			{
				name: 'Recuperar atendimento',
			},
		],
		Component: RecuperarAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PROCON_JUSTIFICATIVA_ATENDIMENTO,
		breadcrumb: [
			proconRoute,
			{
				name: 'Justificativa atendimento',
			},
		],
		Component: JustificativaAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PROCON_CADASTRAR_RECLAMACAO_CADASTRO_CONSULTA,
		breadcrumb: [
			proconRoute,
			{
				name: 'Cadastrar Consulta',
			},
		],
		Component: CadastroConsulta,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PROCON_CADASTRAR_CONSULTA_SUCESSO,
		breadcrumb: [
			proconRoute,
			{
				name: 'Cadastrar Consulta',
			},
		],
		Component: CadastroConsultaSucesso,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PROCON_PESQUISA_ATENDIMENTO,
		breadcrumb: [
			proconRoute,
			{
				name: 'Pesquisa de Atendimento (Manifestação)',
			},
		],
		Component: ConsultaAtendimentos,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PROCON_PESQUISA_ATENDIMENTO_MANIFESTACAO,
		breadcrumb: [
			proconRoute,
			{
				name: 'Pesquisa de Atendimento (Manifestação) / Visualizar',
			},
		],
		Component: VisualizarAtendimento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PROCON_CADASTRAR_CONSULTA,
		breadcrumb: [
			proconRoute,
			{
				name: 'Cadastrar Consulta',
			},
		],
		Component: CadastroConsulta,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PROCON_CADASTRAR_RECLAMACAO,
		breadcrumb: [
			proconRoute,
			{
				name: 'Cadastro de Reclamação',
			},
		],
		Component: CadastrarReclamacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PROCON_CADASTRAR_RECLAMACAO_CONFIRMACAO,
		breadcrumb: [
			proconRoute,
			{
				name: 'Cadastro de Reclamação',
			},
		],
		Component: ConfirmacaoReclamacao,
		isAuthenticated: true,
		exact: true,
	},
];
