import React, {
	useCallback,
	ChangeEvent,
	useState,
	useEffect,
	useContext,
} from 'react';
import { FormikProps, Field } from 'formik';

// COMPONENTS
import InputMask from 'components/Common/Form/Input/InputMask';

// UTILS
import hasError from 'utils/getFormErrors';
import { treatRequestSguCorporativeZipCode } from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { enderecosRequest } from 'store/modules/api/utils/enderecos/actions';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// FORM
import {
	initialResultValuesEndRecebimento,
	IBuscarCepResultadoEndRecebimento,
} from './form';

interface Props {
	title?: string;
	name: string;
	defaultValue?: string;
	required?: boolean;
	size?: number;
	formik: FormikProps<any>;
	readOnly?: boolean;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	titleAlign?: 'start' | 'end';
	renderValue?: string | null;
	resultEndRecebimento: (data: IBuscarCepResultadoEndRecebimento) => void;
	maskChar?: string;
	disabled?: boolean;
	noAbrevLogradouro?: boolean;
	errorName?: string;
	instanceRequest?: string;
}

const CEPEnderecoRecebimento: React.FC<Props> = ({
	title = 'CEP',
	name,
	defaultValue = '',
	required = false,
	size = 100,
	formik,
	resultEndRecebimento,
	readOnly = false,
	titleSize = 'md',
	titleAlign = 'end',
	renderValue = null,
	maskChar = '',
	disabled = false,
	noAbrevLogradouro = false,
	errorName = '',
	instanceRequest = '',
	// necessário utilizar o instanceRequest caso use mais de uma vez o componente na mesma tela.
}) => {
	const { errors } = formik;

	const dispatch = useDispatch();

	const { token } = useContext<IAuthContext>(AuthContext);

	const [selectedCep, setSelectedCep] = useState<string>(defaultValue);
	const border = useState<boolean>(readOnly);

	const { enderecos } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const registerSGUStatistics = useCallback(
		(
			cepProccess: string,
			message: string,
			enderecosDataFailure: string | null,
		) => {
			const payload = treatRequestSguCorporativeZipCode(
				user,
				dadosAtendimento,
				message,
				cepProccess,
				enderecosDataFailure,
			);

			dispatch(estatisticaIncluirRequest(payload));
		},
		[dadosAtendimento, dispatch, user],
	);

	const getEndereco = useCallback(
		(enderecoData: any) => {
			const logradouro = noAbrevLogradouro
				? (enderecoData?.endereco && `${enderecoData?.endereco || ''}`) || ''
				: `${
						enderecoData?.tipoLogradouroAbrev
							? `${enderecoData?.tipoLogradouroAbrev} ${
									enderecoData?.endereco || ''
							  }`
							: (enderecoData?.endereco && `${enderecoData?.endereco || ''}`) ||
							  ''
				  }`;

			return logradouro;
		},
		[noAbrevLogradouro],
	);

	const handleOnChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;

			setSelectedCep(value);
		},
		[setSelectedCep],
	);

	const handleOnBlur = useCallback(async () => {
		const cep = selectedCep.replace(/[_.-]+/g, '');

		if (cep.length !== 8) {
			getEndereco({
				...initialResultValuesEndRecebimento,
			});

			return;
		}

		dispatch(enderecosRequest(token, { instanceRequest, cep }));
	}, [selectedCep, dispatch, token, instanceRequest, getEndereco]);

	useEffect(() => {
		if (
			enderecos.status === 200 &&
			enderecos.data &&
			enderecos.data[0]?.cep &&
			enderecos.instanceRequest === instanceRequest
		) {
			const endereco = enderecos.data[0];

			resultEndRecebimento({
				cep: endereco?.cep || '',
				endereco: (endereco && getEndereco(endereco)) || '',
				bairro: endereco?.bairro || '',
				numeroIBGE: endereco?.numeroIBGE || 0,
				codigoMunicipio: endereco?.codigoMunicipio || 0,
				municipio: endereco?.municipio || '',
				uf: endereco?.uf || '',
				codigoBairro: endereco?.codigoBairro || 0,
				estado: endereco?.estado || '',
				tipoLogradouro: endereco?.tipoLogradouro || '',
				enderecoAbrev: endereco?.enderecoAbrev || '',
				complemento: endereco?.complemento || '',
				tipoLogradouroAbrev: endereco?.tipoLogradouroAbrev || '',
				localidade: endereco?.localidade || '',
				logradouro: endereco?.logradouro || '',
				cdTipoCEP: endereco?.cdTipoCEP || 0,
				tipoCEP: endereco?.tipoCEP || '',
				tipoLocalidade: endereco?.tipoLocalidade || '',
				codigoLocalRel: endereco?.codigoLocalRel || 0,
				latitude: endereco?.estado || '',
				longitude: endereco?.estado || '',
				codigoDne: endereco?.codigoDne || 0,
				tipoLogradouroDne: endereco?.tipoLogradouroDne || 0,
				tipoLogradouroAbrevDNE: endereco?.tipoLogradouroAbrevDNE || '',
			});
		} else if (
			enderecos.status !== 0 &&
			enderecos.status !== 100 &&
			enderecos.instanceRequest === instanceRequest
		) {
			const cep = selectedCep.replace(/[_.-]+/g, '');

			if (
				cep &&
				enderecos.status !== 200 &&
				(!enderecos.data || (enderecos.data && !enderecos.data[0].cep))
			) {
				const invalidCEPMessage =
					'Dados do CEP não foram retornados pelo serviço de CEP Corporativo';
				registerSGUStatistics(cep, invalidCEPMessage, enderecos.dataFailure);
			}

			resultEndRecebimento({
				...initialResultValuesEndRecebimento,
				cep,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		enderecos.data,
		enderecos.status,
		enderecos.instanceRequest,
		instanceRequest,
		registerSGUStatistics,
		getEndereco,
	]);

	useEffect(() => {
		setSelectedCep(defaultValue);
	}, [defaultValue]);

	return (
		<Field
			as={InputMask}
			title={title}
			titleSize={titleSize}
			titleAlign={titleAlign}
			name={name}
			value={renderValue || selectedCep}
			mask="99999-999"
			required={required}
			onChange={handleOnChange}
			onBlur={handleOnBlur}
			error={hasError(errors, errorName || name)}
			style={{ border: border ? '' : 'none' }}
			readOnly={readOnly}
			size={size}
			maskChar={maskChar}
			disabled={disabled}
		/>
	);
};

export default CEPEnderecoRecebimento;
