import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
	.ant-modal-mask {
		animation-duration: 0s !important;
	}

	.ant-modal-wrap {
		.ant-modal {
			color: inherit !important;
			font-size: unset;

			.ant-modal-content {
				.ant-modal-body {
					font-size: unset;
					padding: 16px;
				}
			}
		}
	}
`;
