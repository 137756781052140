export enum Types {
	PRIMEIRO_EMPLACAMENTO_REQUEST = '@DETRAN-CRV/PRIMEIRO_EMPLACAMENTO_REQUEST',
	PRIMEIRO_EMPLACAMENTO_SUCCESS = '@DETRAN-CRV/PRIMEIRO_EMPLACAMENTO_SUCCESS',
	PRIMEIRO_EMPLACAMENTO_FAILURE = '@DETRAN-CRV/PRIMEIRO_EMPLACAMENTO_FAILURE',
	PRIMEIRO_REDIRECT_ESCOLHA_REQUEST = '@DETRAN-CRV/PRIMEIRO_REDIRECT_ESCOLHA_REQUEST',
	PRIMEIRO_EMPLACAMENTO_CLEAR = '@DETRAN-CRV/PRIMEIRO_EMPLACAMENTO_CLEAR',
}

export interface primeiroEmplacamento {
	status: number;
	data: ficha | null;
	restricaoFinanceira: string;
	escolhaPlaca?: {
		chassi: string;
		cpfCnpjProprietario: string;
	};
}
interface ficha {
	chassi: string;
	cpfCnpjProprietario: string;
	placasSugeridas: string[];
	possuiTaxaPlacaPaga: boolean;
	placaPersonalizada: string;
}
