import { IImpressaoLicenciamentoVeiculos } from 'pages/DetranCrv/Processos/ImpressaoLicenciamentoVeiculos/form';
import { Types } from './types';

export function impressaoLicenciamentoVeiculosRequest(
	payload: IImpressaoLicenciamentoVeiculos,
) {
	return {
		type: Types.IMPRESSAO_LICENCIAMENTO_VEICULOS_REQUEST,
		payload,
	};
}
export function impressaoLicenciamentoVeiculosSuccess(payload: any) {
	return {
		type: Types.IMPRESSAO_LICENCIAMENTO_VEICULOS_SUCCESS,
		payload,
	};
}
export function impressaoLicenciamentoVeiculosFailure(payload: []): any {
	return {
		type: Types.IMPRESSAO_LICENCIAMENTO_VEICULOS_FAILURE,
		payload,
	};
}

export function impressaoLicenciamentoVeiculosClear(): any {
	return {
		type: Types.IMPRESSAO_LICENCIAMENTO_VEICULOS_CLEAR,
	};
}
