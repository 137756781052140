import { Types } from './types';

export function consultaEspecieRequest() {
	return {
		type: Types.CONSULTA_ESPECIE_REQUEST,
	};
}
export function consultaEspecieSuccess(payload: any) {
	return {
		type: Types.CONSULTA_ESPECIE_SUCCESS,
		payload,
	};
}
export function consultaEspecieFailure(payload: []): any {
	return {
		type: Types.CONSULTA_ESPECIE_FAILURE,
		payload,
	};
}
