import styled from 'styled-components';

export const Container = styled.div`
	background-color: ${props => props.theme.footer.backgroundColor};
	bottom: 0;
	display: flex;
	height: 5vh;
	justify-content: center;
	left: 0;
	min-height: 45px;
	width: 100%;
`;

export const Content = styled.div`
	color: #fff;
	font-size: calc(0.95rem + ${props => props.theme.fontScale}%);
	margin: auto;
	text-align: center;
`;

export const Version = styled.p`
	display: none;
`;
