export enum Types {
	CONSULTAR_TAXA_SERVICO = '@DETRAN-CRV/CONSULTAR_TAXA_SERVICO',
	CONSULTAR_TAXA_SERVICO_SUCCESS = '@DETRAN-CRV/CONSULTAR_TAXA_SERVICO_SUCCESS',
	CONSULTAR_TAXA_SERVICO_FAILURE = '@DETRAN-CRV/CONSULTAR_TAXA_SERVICO_FAILURE',
	CONSULTAR_TAXA_SERVICO_CLEAR = '@DETRAN-CRV/CONSULTAR_TAXA_SERVICO_CLEAR',
}

export interface consultarTaxaServico {
	status: number;
	data: DataConsultarTaxaServico | null;
	dataError: ConsultarTaxaServicoDataError | null;
}

export interface ConsultarTaxaServicoDataError {
	transacao: string;
	codigo: number;
	mensagem: string;
	mensagens: [string];
}

export interface DataConsultarTaxaServico {
	codBanco: string;
	codigoTaxaServico: string;
	cpfCnpj: string;
	dataContabil: string;
	identificadorTaxa: string;
	nsuProdesp: string;
	pixIdFimAFim: string;
	pixIdTransacao: string;
	renavam: string;
}

export interface ConsultarTaxaServicoRequest {
	codigoTaxaServico: string;
	identificadorTaxa: string;
	despachante: string;
}
