import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AlteraTransferenciaEnderecoRequest } from './types';

// ACTIONS
import {
	alteraTransferenciaEnderecoSuccess,
	alteraTransferenciaEnderecoFailure,
} from './actions';

function* alteraTransferenciaEndereco(request: ReducerAction) {
	const { payload }: { payload: AlteraTransferenciaEnderecoRequest } = request;

	const response: ApiResponse = yield call(
		putApi,
		PATH_DETRAN_CNH,
		`transferencia-endereco/${CHANNEL}/altera`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(alteraTransferenciaEnderecoSuccess(response));
	} else if (response.error) {
		yield put(alteraTransferenciaEnderecoFailure(response.error));
	}
}

export default all([
	takeLatest(Types.ALTERA_TRANSFERENCIA_ENDERECO, alteraTransferenciaEndereco),
]);
