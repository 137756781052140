import { ReducerAction } from 'store/modules/types';

import { ConsultarLocalidadesAssociadasRequest, Types } from './types';

export function consultarLocalidadesAssociadaRequest(
	payload: ConsultarLocalidadesAssociadasRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_LOCALIDADES_ASSOCIADA_REQUEST,
		payload,
	};
}
export function consultarLocalidadesAssociadaSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_LOCALIDADES_ASSOCIADA_SUCCESS,
		payload,
	};
}
export function consultarLocalidadesAssociadaFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_LOCALIDADES_ASSOCIADA_FAILURE,
		payload: null,
	};
}
export function consultarLocalidadesAssociadaClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_LOCALIDADES_ASSOCIADA_CLEAR,
		payload: null,
	};
}
