import { ReducerAction } from 'store/modules/types';

import { Types, IRequestAgendarExameMedico } from './types';

export function agendarExameMedicoRequest(
	data: IRequestAgendarExameMedico,
): ReducerAction {
	return {
		type: Types.AGENDAR_EXAME_MEDICO,
		payload: data,
	};
}
export function agendarExameMedicoSuccess(payload: object): ReducerAction {
	return {
		type: Types.AGENDAR_EXAME_MEDICO_SUCCESS,
		payload,
	};
}
export function agendarExameMedicoFailure(payload: boolean): ReducerAction {
	return {
		type: Types.AGENDAR_EXAME_MEDICO_FAILURE,
		payload,
	};
}
