export enum Types {
	COMBO_PAISES_REQUEST = '@EDUCACAO/COMBO_PAISES_REQUEST',
	COMBO_PAISES_SUCCESS = '@EDUCACAO/COMBO_PAISES_SUCCESS',
	COMBO_PAISES_FAILURE = '@EDUCACAO/COMBO_PAISES_FAILURE',
}

export interface IComboPaises {
	status: number;
	data: IComboPaisResponse;
}

interface IComboPaisResponse {
	processoId: string;
	paises: IPaises[];
}

interface IPaises {
	codigo: string;
	descricao: string;
}
