import React, { useEffect, useRef } from 'react';

// MODEL
import { IButtonProps } from '../model';

// COMPONENTS
import Icon from '../components/Icon';

// STYLED
import { Button } from './styled';
import { Container, Text } from '../styled';

const OutlineButton: React.FC<IButtonProps> = ({
	type = 'button',
	buttonColor = 'default',
	buttonSize = 'md',
	icon,
	text,
	disabled = false,
	setFocus,
	...rest
}) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (setFocus) {
			const focus = setFocus();

			if (focus === true) {
				buttonRef.current?.focus();
			}
		}
	}, [setFocus]);

	return (
		<Container buttonSize={buttonSize} isDisabled={disabled}>
			<Button type={type} ref={buttonRef} buttonColor={buttonColor} {...rest}>
				{icon ? <Icon buttonIcon={icon} /> : ''}

				<Text buttonSize={buttonSize}>{text}</Text>
			</Button>
		</Container>
	);
};

export default OutlineButton;
