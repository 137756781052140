import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaAtendimentosDeConsultaRequest, Types } from './types';

// ACTIONS
import {
	consultaAtendimentosDeConsultaSuccess,
	consultaAtendimentosDeConsultaFailure,
} from './actions';

function* consultaAtendimentosDeConsulta(request: ReducerAction) {
	const { payload }: { payload: ConsultaAtendimentosDeConsultaRequest } =
		request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`atendimentos-de-consulta/${CHANNEL}/consulta-atendimentos-de-consulta`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaAtendimentosDeConsultaSuccess(response));
	} else {
		yield put(consultaAtendimentosDeConsultaFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_ATENDIMENTOS_DE_CONSULTA_REQUEST,
		consultaAtendimentosDeConsulta,
	),
]);
