import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_RFB } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarCpfRequest } from './types';

// ACTIONS
import { consultarCpfSuccess, consultarCpfFailure } from './actions';

function* consultarCpfRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultarCpfRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_RFB,
		`/receita-federal/${CHANNEL}/consulta/cpf`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultarCpfSuccess(response.data));
	} else {
		yield put(consultarCpfFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_CPF_REQUEST, consultarCpfRequest),
]);
