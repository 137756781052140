// ROUTES
import { iirgdRoute } from 'pages/iirgd/routes/';
import { mpRoute } from 'pages/mp/routes';
import { detranCrvRoute } from 'pages/DetranCrv/routes';
import { secretariaEducacaoRoute } from 'pages/EducacaoUpdate/routes';
import { cdhuRoute } from 'pages/cdhu/routes';
import { sedsRoute } from 'pages/seds/routes';
import { detranCnhRoute } from 'pages/DetranCnh/routes';
import { poupatempoRoute } from 'pages/Poupatempo/routes';
import { prefeituraRoute } from 'pages/Prefeitura/routes';
import { bolsaPovoRoute } from 'pages/BolsaPovo/routes';

// ROUTE PATHS
import { proconRoute } from 'pages/procon/routes';
import MenuIirgd from 'pages/iirgd/Menu';
import MenuOrgaos from 'pages/MenuOrgaos';
import Login from 'pages/Login';

// Educação
import Educacao from 'pages/EducacaoUpdate/Menu';

// MP
import Mp from 'pages/mp/Menu';

// DETRAN - CRV
import MenuDetranCrv from 'pages/DetranCrv/Menu';

// DETRAN - CNH
import MenuDetranCnh from 'pages/DetranCnh/Menu';

// DETRAN - CNH
import MenuCdhu from 'pages/cdhu/Menu';

// SEDS
import MenuSeds from 'pages/seds/VivaLeite/Menu';

// POUPATEMPO
import Poupatempo from 'pages/Poupatempo/Menu';

// BOLSA DO POVO
import BolsaPovo from 'pages/BolsaPovo/Menu';

// PREFEITURA
import Prefeitura from 'pages/Prefeitura/Menu';

// PROCON
import Procon from 'pages/procon/Menu';

import {
	ROUTE_LOGIN,
	ROUTE_MENU_ORGAOS,
	ROUTE_IIRGD,
	ROUTE_MINISTERIO_PUBLICO,
	ROUTE_DETRAN_CRV,
	ROUTE_SECRETARIA_EDUCACAO,
	ROUTE_DETRAN_CNH,
	ROUTE_CDHU,
	ROUTE_SEDS,
	ROUTE_POUPATEMPO,
	ROUTE_PREFEITURA,
	ROUTE_BOLSA_POVO,
	ROUTE_PROCON,
} from './paths';

export default [
	{
		path: ROUTE_LOGIN,
		Component: Login,
		isAuthenticated: false,
		exact: true,
	},
	{
		path: ROUTE_MENU_ORGAOS,
		Component: MenuOrgaos,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_IIRGD,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Escolha o Serviço',
			},
		],
		Component: MenuIirgd,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_MINISTERIO_PUBLICO,
		breadcrumb: [
			mpRoute,
			{
				name: 'Escolha o Serviço',
			},
		],
		Component: Mp,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CRV,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Escolha o Serviço',
			},
		],
		Component: MenuDetranCrv,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SECRETARIA_EDUCACAO,
		breadcrumb: [
			secretariaEducacaoRoute,
			{
				name: 'Escolha o Serviço',
			},
		],
		Component: Educacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CNH,
		breadcrumb: [
			detranCnhRoute,
			{
				name: 'Escolha o Serviço',
			},
		],
		Component: MenuDetranCnh,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_CDHU,
		breadcrumb: [
			cdhuRoute,
			{
				name: 'Escolha o Serviço',
			},
		],
		Component: MenuCdhu,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_SEDS,
		breadcrumb: [
			sedsRoute,
			{
				name: 'Escolha o Serviço',
			},
		],
		Component: MenuSeds,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_POUPATEMPO,
		breadcrumb: [
			poupatempoRoute,
			{
				name: 'Escolha o Serviço',
			},
		],
		Component: Poupatempo,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PREFEITURA,
		breadcrumb: [
			prefeituraRoute,
			{
				name: 'Escolha o Serviço',
			},
		],
		Component: Prefeitura,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_BOLSA_POVO,
		breadcrumb: [
			bolsaPovoRoute,
			{
				name: 'Escolha o Serviço',
			},
		],
		Component: BolsaPovo,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_PROCON,
		breadcrumb: [
			proconRoute,
			{
				name: 'Escolha o Serviço',
			},
		],
		Component: Procon,
		isAuthenticated: true,
		exact: true,
	},
];
