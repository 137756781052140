import { ReducerAction } from 'store/modules/types';
import { ConsultarUsuariosRequest, Types } from './types';

export function consultarUsuariosRequest(
	payload: ConsultarUsuariosRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_USUARIOS_REQUEST,
		payload,
	};
}
export function consultarUsuariosSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_USUARIOS_SUCCESS,
		payload,
	};
}
export function consultarUsuariosFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTAR_USUARIOS_FAILURE,
		payload,
	};
}
export function consultarUsuariosClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_USUARIOS_CLEAR,
		payload: null,
	};
}
