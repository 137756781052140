import { ConsultarIntencaoVendaRequest, Types } from './types';

export function consultarIntencaoVendaRequest(
	payload: ConsultarIntencaoVendaRequest,
) {
	return {
		type: Types.CONSULTAR_INTENCAO_VENDA_REQUEST,
		payload,
	};
}
export function consultarIntencaoVendaSuccess(payload: any) {
	return {
		type: Types.CONSULTAR_INTENCAO_VENDA_SUCCESS,
		payload,
	};
}
export function consultarIntencaoVendaFailure(payload: []): any {
	return {
		type: Types.CONSULTAR_INTENCAO_VENDA_FAILURE,
		payload,
	};
}
export function consultarIntencaoVendaClear(): any {
	return {
		type: Types.CONSULTAR_INTENCAO_VENDA_CLEAR,
	};
}
