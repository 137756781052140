// ADMINISTRAR

// PAGES
import Motivos from './motivos/types';
import Municipios from './municipios/types';
import Naturalidades from './naturalidades/types';
import Orgaos from './orgaos/types';
import Perfis from './perfis/types';
import Status from './status/types';
import Siglas from './siglas/types';
import Localidades from './localidades/types';
import LocalidadesAssociada from './localidadesAssociadas/types';
import Sufixos from './sufixos/types';
import Menus from './menus/types';
import Unidades from './unidades/types';
import Funcionalidades from './funcionalidades/types';
import Mensagens from './mensagens/types';
import Parametros from './parametros/types';

export enum Types {
	SGU_CLEAR = '@sgu/SGU_CLEAR',
}

export interface AdministrarState {
	motivos: Motivos;
	municipios: Municipios;
	naturalidades: Naturalidades;
	orgaos: Orgaos;
	perfis: Perfis;
	status: Status;
	siglas: Siglas;
	localidades: Localidades;
	localidadesAssociada: LocalidadesAssociada;
	sufixos: Sufixos;
	menus: Menus;
	unidades: Unidades;
	funcionalidades: Funcionalidades;
	mensagens: Mensagens;
	parametros: Parametros;
}
