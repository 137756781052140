import React from 'react';

// REDUX
import { TitleSectionAtendimento } from 'store/modules/api/procon/enum';
import { ConsultaCipDataPfPj } from 'store/modules/api/procon/atendimentos/types';

// ANT DESIGN
import { Col, Row } from 'antd';

// COMPONENTS
import LightButton from 'components/Common/Buttons/Light';
import TypographyCommon from 'components/Common/Typography';

// UTILS
import { formatDate } from 'utils/genericFunctions';
import { formatCurrency } from 'utils/numberFunctions';

// STYLED
import { styleCols } from '../styled';

interface Props {
	handleBackSection: React.MouseEventHandler<HTMLButtonElement>;
	handleNextSection: React.MouseEventHandler<HTMLButtonElement>;
	formData: ConsultaCipDataPfPj | null;
}

const Atendimento: React.FC<Props> = ({
	handleNextSection,
	handleBackSection,
	formData,
}) => {
	return (
		<>
			<Row gutter={[0, 0]} align="top" justify="start">
				<Col className="gutter-row" span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Dados do Atendimento"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									formData?.tipoDoConsumidor === 'Pessoa Jurídica'
										? 'CNPJ'
										: 'CPF'
								}
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.tipoDoConsumidor === 'Pessoa Jurídica'
										? formData?.consumidorPj?.cnpj
										: formData?.consumidor?.cpf) || 'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="Protocolo:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.protocolo || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon text="Status:" fontWeight="bold" uppercase />
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.razaoDoStatus || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Data da Solicitação:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									formatDate(formData?.dataDaSolicitacao) || 'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Tipo de Atendimento:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.tipoDeAtendimento || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Participação de Audiência:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.participacaoEmAudiencia || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Prazo de Atendimento:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									formatDate(formData?.prazoDeAtendimento) || 'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Última Atualização:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.ultimaAtualizacao || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col
					className="gutter-row"
					span={formData?.tipoDoConsumidor === 'Pessoa Jurídica' ? 12 : 6}
				>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									formData?.tipoDoConsumidor === 'Pessoa Jurídica'
										? 'Razão Social'
										: 'Consumidor'
								}
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.consumidor && formData?.consumidor.nome) ||
									(formData?.consumidorPj &&
										formData?.consumidorPj.razaoSocial) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				{formData?.tipoDoConsumidor !== 'Pessoa Jurídica' && (
					<Col className="gutter-row" span={6}>
						<div style={styleCols}>
							<Row gutter={16} align="top" justify="start">
								<TypographyCommon text="RG:" fontWeight="bold" uppercase />
							</Row>
							<Row gutter={16} align="top" justify="start">
								<TypographyCommon
									text={
										(formData?.consumidor && formData?.consumidor.rg) ||
										'Não informado'
									}
									capitalize
									labelSize={13.2}
								/>
							</Row>
						</div>
					</Col>
				)}
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Tipo de Consumidor:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.tipoDoConsumidor || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Forma de Aquisição:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.formaDeAquisicao || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Tipo de Contratação:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.tipoDeContratacao || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Data da Contratação:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									formatDate(formData?.dataDaContratacao) || 'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Data da Ocorrência ou Serviço:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									formatDate(formData?.dataDaOcorrenciaOuServico) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Data de Cancelamento, Desistência ou Negativa:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									formatDate(
										formData?.dataDeCancelamentoDesistenciaOuNegativa,
									) || 'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={12}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Nome do Serviço ou Produto:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.nomeDoServicoOuProduto || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={12}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Classificação Atual:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.area || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={24}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Detalhes do Serviço ou Produto:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.detalhesDoServicoOuProduto || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<Row gutter={16} align="top" justify="start">
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Tipo de Documento:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.tipoDoDocumentoDeCompra || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Número do Documento:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.numeroDoDocumentoDeCompra || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Forma de Pagamento:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={formData?.formaDePagamento || 'Não informado'}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" span={6}>
					<div style={styleCols}>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text="Valor da Compra:"
								fontWeight="bold"
								uppercase
							/>
						</Row>
						<Row gutter={16} align="top" justify="start">
							<TypographyCommon
								text={
									(formData?.valorDaCompra &&
										formatCurrency(formData?.valorDaCompra.toString())) ||
									'Não informado'
								}
								capitalize
								labelSize={13.2}
							/>
						</Row>
					</div>
				</Col>
			</Row>
			<br />
			<Row gutter={16} align="top" justify="space-between" className="no-print">
				<Col className="gutter-row">
					<LightButton
						type="button"
						buttonColor="default"
						text="Voltar"
						icon="back"
						buttonSize="sm"
						buttonWidth={100}
						onClick={handleBackSection}
					/>
				</Col>
				<Col className="gutter-row">
					<LightButton
						type="button"
						buttonColor="default"
						text="Imprimir"
						icon="print"
						buttonSize="sm"
						buttonWidth={100}
						onClick={() => window.print()}
					/>
				</Col>
				<Col className="gutter-row">
					<Row align="top" justify="end">
						<LightButton
							type="button"
							buttonColor="default"
							text={TitleSectionAtendimento.FORNECEDOR}
							icon="next"
							buttonSize="sm"
							buttonWidth={190}
							leftOrRight="right"
							onClick={handleNextSection}
						/>
					</Row>
				</Col>
			</Row>
		</>
	);
};
export default Atendimento;
