import React, { useEffect } from 'react';

import { Col, Row } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// UTILS
import hasError from 'utils/getFormErrors';

// COMPONENTS
import TypographyCommon from 'components/Common/Typography';
import Select from 'components/Common/Form/Select';

// FORM
import { Field, FormikProps } from 'formik';
import { consultaAreasRequest } from 'store/modules/api/procon/areas/consultaAreas/actions';
import { assuntosRequest } from 'store/modules/api/procon/areas/assuntos/actions';
import { assuntosProblemasRequest } from 'store/modules/api/procon/grupoDeProblemas/assuntos/actions';
import { problemasRequest } from 'store/modules/api/procon/grupoDeProblemas/problemas/actions';

interface Props {
	// TO DO: JJ Colocar consulta na tipagem
	formik: FormikProps<any>;
}

const DadosSolicitacoes: React.FC<Props> = ({ formik }) => {
	const dispatch = useDispatch();

	const { assuntos, consultaAreas } = useSelector(
		(state: ApplicationState) => state.api.procon.areas,
	);

	const { problemas, assuntosProblemas } = useSelector(
		(state: ApplicationState) => state.api.procon.grupoDeProblemas,
	);

	useEffect(() => {
		dispatch(consultaAreasRequest());
	}, [dispatch]);

	return (
		<>
			<Row gutter={[0, 10]} align="top" justify="start">
				<Col className="gutter-row" span={24}>
					<TypographyCommon
						text="Classificação da Solicitação"
						borderBottom
						type="h5"
						fontWeight="bold"
						required
						uppercase
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]} align="top">
				<Col span={24}>
					<Field
						as={Select}
						name="areaId"
						options={consultaAreas.data}
						titleSize="md"
						onChange={(v: string) => {
							formik.setFieldValue('areaId', v);
							formik.setFieldValue('assuntoId', '');
							formik.setFieldValue('grupoDeProblemaId', '');
							formik.setFieldValue('problemaId', '');
							if (v) {
								dispatch(assuntosRequest({ areaId: v }));
							}
						}}
						error={hasError(formik.errors, 'areaId')}
						required
						defaultFirstOptionText="- SELECIONE A ÁREA -"
					/>
				</Col>
				{formik.values.areaId && (
					<Col span={24}>
						<Field
							as={Select}
							name="assuntoId"
							options={assuntos.data}
							titleSize="md"
							onChange={(v: string) => {
								formik.setFieldValue('assuntoId', v);
								formik.setFieldValue('grupoDeProblemaId', '');
								formik.setFieldValue('problemaId', '');
								if (v) {
									dispatch(
										assuntosProblemasRequest({
											assuntoId: v,
										}),
									);
								}
							}}
							error={hasError(formik.errors, 'assuntoId')}
							required
							defaultFirstOptionText="- SELECIONE O ASSUNTO -"
						/>
					</Col>
				)}
				{formik.values.assuntoId && (
					<Col span={24}>
						<Field
							as={Select}
							name="grupoDeProblemaId"
							options={assuntosProblemas.data}
							titleSize="md"
							onChange={(v: string) => {
								formik.setFieldValue('grupoDeProblemaId', v);
								formik.setFieldValue('problemaId', '');
								if (v) {
									dispatch(
										problemasRequest({
											grupoDeProblemaId: v,
										}),
									);
								}
							}}
							error={hasError(formik.errors, 'grupoDeProblemaId')}
							required
							defaultFirstOptionText="- SELECIONE O GRUPO DE PROBLEMA -"
						/>
					</Col>
				)}
				{formik.values.grupoDeProblemaId && (
					<Col span={24}>
						<Field
							as={Select}
							name="problemaId"
							options={problemas.data}
							titleSize="md"
							onChange={(v: number) => formik.setFieldValue('problemaId', v)}
							error={hasError(formik.errors, 'problemaId')}
							required
							defaultFirstOptionText="- SELECIONE O PROBLEMA -"
						/>
					</Col>
				)}
			</Row>
		</>
	);
};

export default DadosSolicitacoes;
