import produce from 'immer';

// TYPES
import { Types, AtualizarStatus } from './types';
import { ReducerAction } from '../../../../../types';

export const INITIAL_STATE: AtualizarStatus = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): AtualizarStatus {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ATUALIZAR_STATUS: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.ATUALIZAR_STATUS_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.ATUALIZAR_STATUS_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.ATUALIZAR_STATUS_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
