// TYPES
import { Types, IBreadcrumb } from './types';
import { ReducerAction } from '../../types';

export function setBreadcrumb(
	data: IBreadcrumb[] | null,
	exibirPaginaInicial: boolean,
): ReducerAction {
	return {
		type: Types.SET_BREADCRUMB,
		payload: {
			data,
			exibirPaginaInicial,
		},
	};
}
