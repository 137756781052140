/* eslint-disable array-callback-return */
import React, {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Col, Row, Modal } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from 'store';
import data from 'store/modules/enum';
import hasError from 'utils/getFormErrors';
import {
	CadastrarContatoForm,
	CadastrarContatoRequest,
} from 'store/modules/api/bolsaPovo/cadastrarContato/types';
import {
	cadastrarContatoRequest,
	limparCadastrarContato,
} from 'store/modules/api/bolsaPovo/cadastrarContato/action';
import {
	consultarContatoRequest,
	limparConsultarContato,
} from 'store/modules/api/bolsaPovo/consultarContato/action';
import { IRequestEditarCadastrarEndereco } from 'store/modules/api/bolsaPovo/editaCadastraEndereco/types';
import { Tabelas } from 'store/modules/api/bolsaPovo/getCpfPermitido/types';
import { limparExcluirContato } from 'store/modules/api/bolsaPovo/excluirContato/action';
import {
	editaCadastraEnderecoRequest,
	limparEditaCadastraEndereco,
} from 'store/modules/api/bolsaPovo/editaCadastraEndereco/action';
import { limparAtualizarContato } from 'store/modules/api/bolsaPovo/atualizarContato/action';
import { addNotifications } from 'store/modules/app/notifications/actions';
import { consultarTiposLogradourosRequest } from 'store/modules/api/bolsaPovo/consultarTiposLogradouros/action';
import { consultarTiposResidenciasRequest } from 'store/modules/api/bolsaPovo/consultarTiposResidencias/action';
import getValidationsErrors from 'utils/getValidationsErrors';

// ENUM
import dataEnum from 'store/modules/api/bolsaPovo/enum';

// ROUTES
import { ROUTE_BOLSA_POVO } from 'routes/paths';

// COMPONENTS
import Alert from 'components/Common/Notifications/Alert';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import Select from 'components/Common/Form/Select';
import Section from 'components/Common/Section';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import Checkbox from 'components/Common/Form/Checkbox';
import OutlineButton from 'components/Common/Buttons/Outline';
import {
	onlyLetters,
	onlyNumbers,
	onlyNumbersAndLetters,
} from 'utils/genericFunctions';
import { ROUTE_BOLSA_POVO_PESQUISA_ALTERACAO_ENDERECO } from '../routes/paths';
import CadastroContato from './CadastroContato';

// GENERICS

// FORM
import {
	initialValuesNovo,
	schemaEndereco,
	treatValuesNovo,
	initialValuesCadastro,
	initialValuesDataCep,
	schemaContatos,
} from './form';

// ROUTES

const AlteracaoEndereco: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [initialValuesCadastroState, setInitialValuesCadastroState] =
		useState<CadastrarContatoForm>(initialValuesCadastro);

	const [initialValuesEnderecoState, setInitialValuesEnderecoState] =
		useState<IRequestEditarCadastrarEndereco>(initialValuesNovo);

	const [contatosEnviados, setContatosEnviados] = useState<
		CadastrarContatoRequest[]
	>([]);

	const [edicao, setEdicao] = useState<number>(0);
	const [showAlert, setShowAlert] = useState<boolean>(false);
	const [showAddressAlert, setShowAddressAlert] = useState<boolean>(false);
	const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isUpdateTableContato, setIsUpdateTableContato] = useState(false);
	const [dataResCEP, setDataResCEP] =
		useState<IBuscarCepResultado>(initialValuesDataCep);

	const {
		cadastrarContato,
		consultarContato,
		excluirContato,
		atualizarContato,
		alteraEndereco,
		editaCadastraEndereco,
		consultarTiposLogradouros,
		consultarTiposResidencias,
		getCpfPermitido,
	} = useSelector((state: ApplicationState) => state.api.bolsaPovo);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	useEffect(() => {
		dispatch(consultarTiposLogradourosRequest());
		dispatch(consultarTiposResidenciasRequest());
		dispatch(limparCadastrarContato());
		dispatch(limparConsultarContato());
		dispatch(limparEditaCadastraEndereco());
		dispatch(limparAtualizarContato());
	}, [dispatch]);

	useEffect(() => {
		if (getCpfPermitido.request) {
			setContatosEnviados([]);
			dispatch(consultarContatoRequest({ cpf: getCpfPermitido.request }));
		}
	}, [getCpfPermitido.request, dispatch]);

	useEffect(() => {
		if (
			editaCadastraEndereco.status === 200 ||
			editaCadastraEndereco.status === 201
		) {
			setIsModalOpenConfirmation(false);
			dispatch(limparEditaCadastraEndereco());
			setIsModalOpen(true);
		} else if (
			editaCadastraEndereco.status === 500 ||
			editaCadastraEndereco.status === 400
		) {
			setIsModalOpenConfirmation(false);
			setIsModalOpen(false);
		}
	}, [dispatch, editaCadastraEndereco.status]);

	useEffect(() => {
		if (alteraEndereco.status === 200) {
			setShowAddressAlert(true);
		}
	}, [alteraEndereco.status]);

	const handleSubmit = useCallback(
		(values: Tabelas) => {
			setContatosEnviados([]);
			consultarContato.data?.map(contato => {
				setContatosEnviados(prev => [
					...prev,
					{
						id: contato.id,
						idCpf: contato.cpf,
						idTipoContato: contato.idTipoContato,
						valor: contato.valor,
					},
				]);
			});

			if (showAlert) setShowAlert(false);

			if (contatosEnviados.length === 0) {
				addNotifications({
					errors: ['É Obrigatório Informar ao menos um celular.'],
				});
			} else {
				if (contatosEnviados.length >= 0) {
					const validaExistenciaCelular = contatosEnviados.filter(
						contatoCelular => {
							return contatoCelular.idTipoContato === 3;
						},
					);
					if (validaExistenciaCelular.length === 0) {
						addNotifications({
							errors: ['É Obrigatório Informar ao menos um celular.'],
						});
					} else {
						values.foneCelular = validaExistenciaCelular[0].valor || '';

						// const emailMap = consultarContato.data?.filter(contatoEmail => {
						// 	return contatoEmail.idTipoContato === 4;
						// });
						// values.email = emailMap[0].valor != null ? emailMap[0].valor : '';

						// const foneResidencialMap = contatosEnviados.filter(
						// 	contatoFone => {
						// 		return contatoFone.idTipoContato === 1;
						// 	},
						// );
						// values.foneFixo = foneResidencialMap[0].valor || '';
					}
				}

				const valuesFormated = treatValuesNovo(
					values,
					getCpfPermitido.request || '',
					user,
					dadosAtendimento?.data?.idAtendimento || '',
					dadosAtendimento?.data?.idCidadao || '',
				);
				setIsModalOpenConfirmation(true);
				setInitialValuesEnderecoState(valuesFormated);
			}
		},
		[
			showAlert,
			consultarContato.data,
			getCpfPermitido.request,
			contatosEnviados,
			user,
			dadosAtendimento,
		],
	);

	const handleSubmitDipatchAlterarEndereco = useCallback(() => {
		dispatch(editaCadastraEnderecoRequest(initialValuesEnderecoState));
	}, [dispatch, initialValuesEnderecoState]);

	const handleSubmitEnderecoAlterado = useCallback(() => {
		setIsModalOpen(false);
		history.push(ROUTE_BOLSA_POVO);
	}, [history]);

	const updateTableContato = useCallback(() => {
		if (!isUpdateTableContato && consultarContato.data) {
			consultarContato.data.map(contato => {
				setContatosEnviados(prev => [
					...prev,
					{
						id: contato.id,
						idCpf: contato.cpf,
						idTipoContato: contato.idTipoContato,
						valor: contato.valor,
					},
				]);
			});
			setIsUpdateTableContato(true);
		}
	}, [consultarContato.data, isUpdateTableContato]);

	// INITIAL STATE TABLE CONTATOS
	useEffect(() => {
		if (
			(consultarContato.status === 200 || consultarContato.status === 201) &&
			consultarContato.data
		) {
			updateTableContato();
		}
	}, [consultarContato.status, consultarContato.data, updateTableContato]);

	const handleSubmitContato = useCallback(
		(contatoValues: CadastrarContatoForm) => {
			if (showAlert) setShowAlert(false);
			setEdicao(0);
			if (
				contatosEnviados.length === 4 &&
				contatosEnviados.filter(item => item.idTipoContato === 3).length ===
					0 &&
				contatoValues.idTipoContato !== 3
			) {
				addNotifications({
					errors: [
						'O máximo permitido são 5 contatos e é obrigatório Informar ao menos um celular.',
					],
				});
			} else if (contatosEnviados.length === 5) {
				addNotifications({
					errors: ['O máximo permitido são 5 contatos.'],
				});
			} else {
				dispatch(
					cadastrarContatoRequest({
						idCpf: getCpfPermitido.request || '',
						idTipoContato: contatoValues.idTipoContato,
						valor: contatoValues.valor,
					}),
				);
			}
		},
		[contatosEnviados, dispatch, getCpfPermitido.request, showAlert],
	);

	useEffect(() => {
		if (
			cadastrarContato.status === 200 &&
			cadastrarContato &&
			cadastrarContato.form
		) {
			dispatch(consultarContatoRequest({ cpf: getCpfPermitido.request || '' }));
		}
	}, [cadastrarContato, dispatch, getCpfPermitido.request]);

	useEffect(() => {
		if (
			consultarContato.status === 200 &&
			cadastrarContato.status === 200 &&
			consultarContato.data?.length &&
			contatosEnviados.length
		) {
			if (contatosEnviados.length !== consultarContato.data?.length) {
				setContatosEnviados([]);
				consultarContato.data.map(contato => {
					setContatosEnviados(prev => [
						...prev,
						{
							id: contato.id,
							idCpf: contato.cpf,
							idTipoContato: contato.idTipoContato,
							valor: contato.valor,
						},
					]);
				});
				setInitialValuesCadastroState(prev => {
					return { idTipoContato: '', valor: prev.valor === '' ? ' ' : '' };
				});
				dispatch(limparCadastrarContato());
				dispatch(limparConsultarContato());
			}
		}
	}, [
		cadastrarContato.status,
		consultarContato.data,
		consultarContato.status,
		contatosEnviados,
		dispatch,
		updateTableContato,
	]);

	const handleUpdateContato = useCallback(() => {
		const newContatos = [...contatosEnviados];
		newContatos.filter(item => {
			if (item.id === atualizarContato.form?.id) {
				item.valor = atualizarContato.form?.valor;
			}
			return item;
		});

		setContatosEnviados(newContatos);
		dispatch(limparAtualizarContato());
		dispatch(limparConsultarContato());
	}, [atualizarContato, contatosEnviados, dispatch]);

	useEffect(() => {
		if (
			atualizarContato.status === 200 &&
			atualizarContato.form &&
			consultarContato.status === 200 &&
			consultarContato.data?.length
		) {
			handleUpdateContato();
		}
	}, [atualizarContato, consultarContato, handleUpdateContato]);

	const handleExcluirContato = useCallback(() => {
		const oldContatos = [...contatosEnviados];
		if (showAlert) setShowAlert(false);

		const indexToDelete = contatosEnviados.findIndex(
			(item: CadastrarContatoRequest) => item.id === excluirContato.form.id,
		);

		if (indexToDelete !== -1) {
			oldContatos.splice(indexToDelete, 1);
		}
		dispatch(limparExcluirContato());
		dispatch(consultarContatoRequest({ cpf: getCpfPermitido.request || '' }));
		return setContatosEnviados(oldContatos);
	}, [
		contatosEnviados,
		dispatch,
		excluirContato.form.id,
		getCpfPermitido.request,
		showAlert,
	]);

	useEffect(() => {
		if (excluirContato.status === 200 && excluirContato.form) {
			handleExcluirContato();
		}
	}, [
		cadastrarContato,
		consultarContato.data,
		consultarContato.status,
		dispatch,
		excluirContato.form,
		excluirContato.status,
		handleExcluirContato,
	]);

	const getInput = (
		idTipoContato: number,
		formik: FormikProps<CadastrarContatoForm>,
	) => {
		let input = (
			<Field
				as={Input}
				type="text"
				name="valor"
				disabled
				placeholder="Escolha uma opção do tipo de contato"
				error={hasError(formik.errors, 'valores')}
				required
			/>
		);
		switch (idTipoContato) {
			case 1:
			case 2:
				input = (
					<Field
						as={InputMask}
						type="text"
						mask="(99)9999-9999"
						name="valor"
						maskChar=""
						error={hasError(formik.errors, 'valores')}
						required
						disabled={edicao}
					/>
				);

				break;
			case 3:
				input = (
					<Field
						as={InputMask}
						type="text"
						maskChar=""
						mask="(99)99999-9999"
						name="valor"
						error={hasError(formik.errors, 'valores')}
						required
					/>
				);

				break;
			case 4:
			case 5:
				input = (
					<Field
						as={Input}
						type="text"
						name="valor"
						disabled={
							!formik.values.idTipoContato ||
							formik.values.idTipoContato === null
						}
						error={hasError(formik.errors, 'valores')}
						required
					/>
				);

				break;
			default:
				input = (
					<Field
						as={Input}
						type="text"
						name="valor"
						disabled
						placeholder="Escolha uma opção do tipo de contato"
						error={hasError(formik.errors, 'valores')}
						required
					/>
				);
				break;
		}
		return input;
	};

	return (
		<>
			<h3
				style={{ textAlign: 'center', fontSize: '26px', padding: '20px 0px' }}
			>
				Informar meu endereço de correspondência - Bolsa do Povo
			</h3>
			<span style={{ color: '#ff0000', textAlign: 'center' }}>
				Esse endereço será utilizado para entrega do cartão do Bolsa do Povo,
				por isso deve ser um endereço de sua completa confiança
			</span>
			<Section>
				<Section title="Dados de contato" style={{ marginBottom: '50px' }}>
					{showAlert && (
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Alert
									type="info"
									showIcon
									message="Contato Atualizado com Sucesso."
									closable
									onClose={() => setShowAlert(false)}
								/>
							</Col>
						</Row>
					)}
					<span style={{ color: '#ff0000', textAlign: 'center' }}>
						Obrigatório Informar ao menos um celular.
					</span>
					<Formik
						enableReinitialize
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValuesCadastroState}
						validate={values => getValidationsErrors(values, schemaContatos)}
						onSubmit={values => handleSubmitContato(values)}
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[20, 10]} align="middle">
									<Col span={10}>
										<Field
											as={Select}
											options={dataEnum.tiposContato}
											type="text"
											title="Tipo Contato"
											name="idTipoContato"
											defaultFirstOptionText="- SELECIONE O TIPO DE CONTATO -"
											onChange={(value: string) => {
												if (showAlert) setShowAlert(false);
												formik.setFieldValue('idTipoContato', value);
												formik.setFieldValue('valor', '');
											}}
											error={hasError(formik.errors, 'idTipoContato')}
											required
											disabled={edicao}
										/>
									</Col>
									<Col span={10}>
										{getInput(Number(formik.values.idTipoContato), formik)}
									</Col>
									<Col span={4}>
										<OutlineButton
											style={{ cursor: 'pointer' }}
											type="submit"
											text="Incluir"
											disabled={edicao >= 0}
										/>
									</Col>
								</Row>
								<CadastroContato
									contatosEnviados={contatosEnviados || []}
									edicao={edicao}
									setEdicao={setEdicao}
								/>
							</Form>
						)}
					</Formik>
				</Section>

				<Section title="Dados de endereço de correspondência">
					<Formik
						enableReinitialize
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValuesNovo}
						validate={values => getValidationsErrors(values, schemaEndereco)}
						onSubmit={async values => handleSubmit(values)}
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[0, 20]}>
									<Col span={4}>
										<CEP
											title="CEP"
											noAbrevLogradouro
											titleSize={25}
											name="cep"
											defaultValue={formik.values.cep}
											formik={formik}
											result={(res: IBuscarCepResultado) => {
												setDataResCEP(res);
												formik.setFieldValue('cep', res.cep);
												if (showAddressAlert) {
													setShowAddressAlert(false);
													dispatch(limparEditaCadastraEndereco());
												}

												formik.setFieldValue(
													'bairro',
													res.bairro.substring(0, 70),
												);

												formik.setFieldValue(
													'logradouro',
													res.endereco.substring(0, 70),
												);
												formik.setFieldValue(
													'tipoLogradouro',
													res.tipoLogradouro.substring(0, 70),
												);
												formik.setFieldValue('uf', res.uf);
												formik.setFieldValue('idIbge', res.codigoMunicipio);
												formik.setFieldValue('municipio', res.municipio);
											}}
										/>
									</Col>
									<Col span={5}>
										<Field
											as={Select}
											title="Tipo logradouro"
											titleSize={95}
											name="tipoLogradouro"
											options={consultarTiposLogradouros?.data || []}
											// options={[]}
											onChange={(v: string) =>
												formik.setFieldValue('tipoLogradouro', v)
											}
											error={hasError(formik.errors, 'tipoLogradouro')}
											disabled={dataResCEP?.tipoLogradouro !== ''}
										/>
									</Col>
									<Col span={9}>
										<Field
											as={Input}
											titleSize={100}
											type="text"
											title="Endereço"
											name="logradouro"
											value={onlyLetters(formik.values.logradouro)}
											disabled={dataResCEP?.endereco !== ''}
											error={hasError(formik.errors, 'logradouro')}
										/>
									</Col>
									<Col span={3}>
										<Field
											as={Input}
											titleSize={60}
											type="text"
											title="Número"
											name="numero"
											maxLength="6"
											value={onlyNumbers(formik.values.numero)}
											error={hasError(formik.errors, 'NumeroSemNumero')}
											disabled={formik.values.semNumero === 'S'}
										/>
									</Col>
									<Col style={{ marginLeft: '20px' }}>
										<Field
											titleSize={30}
											subtitle="S/N"
											name="semNumero"
											as={Checkbox}
											checked={formik.values.semNumero === 'S'}
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												const { checked } = e.target;

												formik.setFieldValue('semNumero', checked ? 'S' : 'N');
												if (checked) {
													formik.setFieldValue('numero', '');
												}
											}}
											error={hasError(formik.errors, 'NumeroSemNumero')}
										/>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={7}>
										<Field
											as={Input}
											titleSize={80}
											type="text"
											title="Complemento"
											name="complemento"
											value={onlyNumbersAndLetters(formik.values.complemento)}
											error={hasError(formik.errors, 'complemento')}
										/>
									</Col>
									<Col span={8}>
										<Field
											as={Input}
											titleSize={60}
											type="text"
											title="Bairro"
											name="bairro"
											value={onlyLetters(formik.values.bairro)}
											disabled={dataResCEP?.bairro !== ''}
											error={hasError(formik.errors, 'bairro')}
										/>
									</Col>
									<Col span={6}>
										<Field
											as={Input}
											titleSize={50}
											type="text"
											title="Cidade"
											name="municipio"
											value={onlyLetters(formik.values.municipio)}
											disabled={dataResCEP?.municipio !== ''}
											error={hasError(formik.errors, 'municipio')}
										/>
									</Col>
									<Col span={3}>
										<Field
											titleSize={30}
											as={Select}
											defaultFirstOption={false}
											options={data.uf}
											type="text"
											title="UF"
											name="uf"
											onChange={(value: string) => {
												formik.setFieldValue('uf', value);
											}}
											disabled={dataResCEP?.uf !== ''}
											error={hasError(formik.errors, 'uf')}
										/>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={14}>
										<Field
											as={Select}
											titleSize={90}
											title="Tipo Residência"
											name="idTipoEndereco"
											options={consultarTiposResidencias?.data || []}
											onChange={(v: string) =>
												formik.setFieldValue('idTipoEndereco', v)
											}
											error={hasError(formik.errors, 'idTipoEndereco')}
										/>
									</Col>
									<Col span={10}>
										<Field
											as={Input}
											type="text"
											title="Referência"
											name="referencia"
											value={onlyLetters(formik.values.referencia)}
											error={hasError(formik.errors, 'referencia')}
										/>
									</Col>
								</Row>
								<Row justify="end" style={{ marginTop: '20px' }}>
									<Col span={4} offset={1}>
										<OutlineButton text="Atualizar endereço" type="submit" />
									</Col>
									<Col span={1} />
								</Row>
							</Form>
						)}
					</Formik>
				</Section>
				<Row style={{ marginTop: '10px' }}>
					<Col span={2}>
						<ButtonVoltar
							route={ROUTE_BOLSA_POVO_PESQUISA_ALTERACAO_ENDERECO}
						/>
					</Col>
				</Row>
			</Section>
			<Modal
				title="Atualização de endereço"
				visible={isModalOpenConfirmation}
				onOk={handleSubmitDipatchAlterarEndereco}
				okText="Sim"
				onCancel={() => setIsModalOpenConfirmation(false)}
			>
				<p>Tem certeza que deseja atualizar o endereço?</p>
			</Modal>
			<Modal
				title="Atualização de endereço"
				visible={isModalOpen}
				closable={false}
				onOk={handleSubmitEnderecoAlterado}
				okText="Finalizar atendimento"
				cancelButtonProps={{ hidden: true }}
			>
				<p>O endereço foi alterado com sucesso.</p>
			</Modal>
		</>
	);
};

export default AlteracaoEndereco;
