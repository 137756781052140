import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	consultaNacionalidadesSuccess,
	consultaNacionalidadesFailure,
} from './actions';

function* consultaNacionalidades() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}/nacionalidades`,
	);

	if (response.status === 200) {
		yield put(consultaNacionalidadesSuccess(response));
	} else if (response.error) {
		yield put(consultaNacionalidadesFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTA_NACIONALIDADES_REQUEST, consultaNacionalidades),
]);
