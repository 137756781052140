import { ReducerAction } from 'store/modules/types';

import { Types, IRequestPontuacao } from './types';

export function transferirPontuacaoRequest(
	data: IRequestPontuacao,
): ReducerAction {
	return {
		type: Types.TRANSFERIR_PONTUACAO,
		payload: data,
	};
}
export function transferirPontuacaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.TRANSFERIR_PONTUACAO_SUCCESS,
		payload,
	};
}
export function transferirPontuacaoFailure(payload: object): ReducerAction {
	return {
		type: Types.TRANSFERIR_PONTUACAO_FAILURE,
		payload,
	};
}
