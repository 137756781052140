import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_sgu';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { IncluirEstatisticaRequest, Types } from './types';

// ACTIONS
import {
	incluirEstatisticaSuccess,
	incluirEstatisticaFailure,
} from './actions';

function* incluirEstatisticaRequest(request: ReducerAction) {
	const { payload }: { payload: IncluirEstatisticaRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		`estatistica/incluir`,
		payload,
	);

	if (response.status === 200) {
		yield put(incluirEstatisticaSuccess(response.data));
	} else {
		yield put(incluirEstatisticaFailure());
	}
}

export default all([
	takeLatest(Types.INCLUIR_ESTATISTICA_REQUEST, incluirEstatisticaRequest),
]);
