export enum Types {
	CONSULTA_ATENDIMENTOS_REQUEST = '@procon/CONSULTA_ATENDIMENTOS_REQUEST',
	CONSULTA_ATENDIMENTOS_SUCCESS = '@procon/CONSULTA_ATENDIMENTOS_SUCCESS',
	CONSULTA_ATENDIMENTOS_FAILURE = '@procon/CONSULTA_ATENDIMENTOS_FAILURE',
	CONSULTA_ATENDIMENTOS_CLEAR = '@procon/CONSULTA_ATENDIMENTOS_CLEAR',
}

export interface ConsultaAtendimentos {
	status: null | number;
	data: null | ConsultaAtendimentosData[];
	consulta: string;
}

export interface ConsultaAtendimentosData {
	atendimentoId: string;
	protocolo: string;
	nomeFantasiaDoFornecedor: string;
	nomeFornecedor: string;
	cnpjDoFornecedor: string;
	tipo: string;
	razaoDoStatus: string;
	descricaoDoProblema: string;
	numeroFa?: string;
	dataDeSolicitacao: string;
	tipoDeConsumidor: string;
	tipoDeConsumidorValor: number;
	situacaoAtual: string;
}

interface Evento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string | number;
	ip: string;
	canal: {
		id: number;
		desc: string;
		estacao: {
			id: number;
			desc: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface ConsultaAtendimentosRequest {
	evento: Evento;
	cpf: string;
}

export interface ConsultaAtendimentosForm {
	cpf: string;
}
