import React from 'react';

import { Row, Col, Modal } from 'antd';
import { Formik, Form, Field } from 'formik';

import ButtonImage from 'components/Common/ButtonImage';
import Radio from 'components/Common/Form/Radio';

import hasError from 'utils/getFormErrors';
import { IFormProcurador, initialValues, radioSimNao } from './form';

interface IProps {
	handleSubmit: (values: IFormProcurador) => void;
	modalVisible: boolean;
	onCancel: () => void;
}

const ModalProcurador: React.FC<IProps> = ({
	handleSubmit,
	modalVisible,
	onCancel,
}) => {
	return (
		<>
			<Modal
				visible={modalVisible}
				footer={null}
				onCancel={onCancel}
				width={500}
				style={{
					animationDuration: '0s',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				className="modal-confirm-procurador"
				destroyOnClose
			>
				<Row>
					<Col span={24}>
						<Formik
							enableReinitialize
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							onSubmit={handleSubmit}
						>
							{formik => (
								<Form autoComplete="off">
									<Row
										className="modal-confirm-procurador__content"
										justify="center"
									>
										<Col span={24} flex="center">
											<Field
												as={Radio}
												title="Você é procurador de algum cidadão"
												name="flagProcurador"
												options={radioSimNao}
												titleQuestion
												directionRow
												titleSize="auto"
												error={hasError(formik.errors, 'flagProcurador')}
												styleContainer={{ alignItems: 'center' }}
											/>
										</Col>
										<Col span={24} flex="center">
											<ButtonImage type="submit" src="prosseguir" />
										</Col>
									</Row>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Modal>
		</>
	);
};

export default ModalProcurador;
