export enum Types {
	CONSULTAR_MUNICIPIOS_REQUEST = '@SGU/CONSULTAR_MUNICIPIOS_REQUEST',
	CONSULTAR_MUNICIPIOS_SUCCESS = '@SGU/CONSULTAR_MUNICIPIOS_SUCCESS',
	CONSULTAR_MUNICIPIOS_FAILURE = '@SGU/CONSULTAR_MUNICIPIOS_FAILURE',
	CONSULTAR_MUNICIPIOS_CLEAR = '@SGU/CONSULTAR_MUNICIPIOS_CLEAR',
}

export interface ConsultarMunicipios {
	status: number;
	data: null | Municipio[];
	totalRegistrosConsulta: number;
}

export interface Municipio {
	codigo: string | number;
	sigla: string;
	idMunicipio: string | number;
	nome: string;
}

export interface ConsultarMunicipiosRequest {
	registroInicial?: number;
	limite?: number;
	current?: number;
	codigo?: string | number;
	descricao?: string;
	idUf?: string | number;
}
