import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_BOLSA_POVO } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestValidacaoCidadao } from './types';

// ACTIONS
import { desbloqueiaCartaoSuccess, desbloqueiaCartaoFailure } from './action';

function* desbloqueiaCartao(request: ReducerAction) {
	const { payload }: { payload: IRequestValidacaoCidadao } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_BOLSA_POVO,
		`bolsa-povo/${CHANNEL}/desbloqueia-cartao`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(desbloqueiaCartaoSuccess(response));
	} else {
		yield put(desbloqueiaCartaoFailure());
	}
}

export default all([takeLatest(Types.DESBLOQUEIO_CARTAO, desbloqueiaCartao)]);
