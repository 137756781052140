export enum Types {
	LOGON_REQUEST = '@iirgd/LOGON_REQUEST',
	LOGON_SUCCESS = '@iirgd/LOGON_SUCCESS',
	LOGON_FAILURE = '@iirgd/LOGON_FAILURE',
	LOGON_CLEAR = '@iirgd/LOGON_CLEAR',
}

export interface Logon {
	status: number;
	mensagem: string;
}

export interface LogonRequest {
	usuario: string;
	senha: string;
}
