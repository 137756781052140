export enum Types {
	CONSULTA_FORNECEDOR_REQUEST = '@procon/CONSULTA_FORNECEDOR_REQUEST',
	CONSULTA_FORNECEDOR_SUCCESS = '@procon/CONSULTA_FORNECEDOR_SUCCESS',
	CONSULTA_FORNECEDOR_FAILURE = '@procon/CONSULTA_FORNECEDOR_FAILURE',
	CONSULTA_FORNECEDOR_CLEAR = '@procon/CONSULTA_FORNECEDOR_CLEAR',
}

export interface ConsultaFornecedor {
	status: null | number;
	data: null | ConsultaFornecedorData;
}

export interface ConsultaFornecedorData {
	fornededorId: string;
	tipoDeFornecedor: string;
	razaoSocial: string;
	nomeFantasia: string;
	cnpj: string;
	cpf: string;
	inscricaoEstadual: string;
	website: string;
	email: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
	estado: string;
	telefone: string;
}

export interface ConsultaFornecedorRequest {
	cnpj?: string;
	cpf?: string;
}

export interface PesquisaFornecedorForm {
	cnpj?: string;
	cpf?: string;
	termo?: string;
}
