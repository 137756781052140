import { all } from 'redux-saga/effects';

import atualizarStatusSaga from './atualizarStatus/saga';
import consultarStatusSaga from './consultarStatus/saga';
import cadastrarStatus from './cadastrarStatus/saga';
import excluirStatusSaga from './excluirStatus/saga';

export default all([
	atualizarStatusSaga,
	consultarStatusSaga,
	cadastrarStatus,
	excluirStatusSaga,
]);
