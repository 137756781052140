export const ROUTE_LOGIN = '/';
export const ROUTE_MENU_ORGAOS = '/menu-orgaos';
export const ROUTE_IIRGD = '/iirgd';
export const ROUTE_MINISTERIO_PUBLICO = '/ministerio-publico';
export const ROUTE_DETRAN_CRV = '/detran-crv';
export const ROUTE_SECRETARIA_EDUCACAO = '/secretaria-educacao';
export const ROUTE_DETRAN_CNH = '/detran-cnh';
export const ROUTE_CDHU = '/cdhu';
export const ROUTE_SEDS = '/seds';
export const ROUTE_POUPATEMPO = '/poupatempo';
export const ROUTE_PREFEITURA = '/prefeitura';
export const ROUTE_SGU = '/sgu';
export const ROUTE_BOLSA_POVO = '/bolsa-povo';
export const ROUTE_PROCON = '/procon';

export const pathsMenu = [
	ROUTE_MENU_ORGAOS,
	ROUTE_IIRGD,
	ROUTE_MINISTERIO_PUBLICO,
	ROUTE_DETRAN_CRV,
	ROUTE_SECRETARIA_EDUCACAO,
	ROUTE_DETRAN_CNH,
	ROUTE_CDHU,
	ROUTE_SEDS,
	ROUTE_POUPATEMPO,
	ROUTE_PREFEITURA,
	ROUTE_SGU,
	ROUTE_BOLSA_POVO,
	ROUTE_PROCON,
];
