import * as Yup from 'yup';

import {
	limparMascara,
	formatDate,
	formatDateToString,
	onlyNumbersLettersSpace,
	onlyNumbers,
	showComponent,
	verificarMaioridade,
	verifyStringIsAnInvalidPhrase,
	onlyLetters,
	unformatDate,
	formatDateISOWithOutUTC,
} from 'utils/genericFunctions';
import {
	// ILoginUnicoState,
	IUser,
} from 'store/modules/api/sgu/loginUnico/types';
import { AlterarRenachComRegistroRequest } from 'store/modules/api/detranCnh/sockets/alterarRenachComRegistro/types';
import { AlterarRenachSemRegistroRequest } from 'store/modules/api/detranCnh/sockets/alterarRenachSemRegistro/types';
import { FormCnhEstrangeiro } from 'pages/DetranCnh/components/pages/PreCadastroEstrangeiro/StatusCondutor/form';
import { PesquisarContatoData } from 'store/modules/api/detranCnh/portalDetran/pesquisarContato/types';
import { AtualizarContatoRequest } from 'store/modules/api/detranCnh/portalDetran/atualizarContato/types';
import {
	socialNameAndFiliationsPhrasesNotAllowed,
	usoFormularioEnumTypes,
	enumFormUse,
} from 'utils/constants/types';
import { ConsultaCEPData } from 'store/modules/api/detranCnh/consulta/cep/types';
import {
	sameThreeCharactersInSequenceNotAllowedRegex,
	userDataRegex,
} from 'utils/constants/regex';
import { ConsultaCondutorResponse } from 'store/modules/api/detranCnh/condutor/consultaCondutor/types';
import { AlterarCadastroRequest } from 'store/modules/api/detranCnh/renachIntegrado/alterarCadastro/types';
import { ConsultaProcessoAbertoData } from 'store/modules/api/detranCnh/renachIntegrado/consultaProcessoAberto/types';
import { ICadastraExameMedico } from '../AgendamentoMedico/form';
import { ICadastraExamePsicologico } from '../AgendamentoPsicologico/form';

// TREAT TO MOUNT FORM
export function treatInitialValues(
	valuesConsultaProcessoAbertoData: ConsultaProcessoAbertoData | null,
	values: ConsultaCondutorResponse | null,
	uf: string | null,
	endereco: ConsultaCEPData | null,
	pesquisarContatoValues: PesquisarContatoData | null,
): FormCnhEstrangeiro {
	let numero = '';

	if (values?.numero) {
		numero = values.numero;
		if (values.numero === '0') numero = '000000';
	}
	// console.log("rneUF naturalidade: ", values?.nacionalidade.trim())
	// console.log("rneUF: ", x)
	const formattedData = {
		tipoProcesso: usoFormularioEnumTypes.ALTERAR_RENACH,
		tipoRenach:
			valuesConsultaProcessoAbertoData?.tipoProcesso?.descricao?.trim() || '',
		numeroRenach: values?.renach.trim() || '',
		numeroRegistro: values?.numRegistro || '',
		usoFormularioEnum: values?.usoFormulario?.trim().replaceAll('0', '') || '',
		cpf: values?.cpf ? `00000000000${values?.cpf?.trim()}`.slice(-11) : '',
		bairro: values?.bairro.trim() || '',
		categoriaPretendida: values?.categoriaPretendida.trim() || '',
		cep: endereco?.cep.trim() || '',
		numero,
		complemento: values?.complemento?.trim() || '',
		logradouro: values?.logradouro.trim() || '',
		municipio: values?.municipio.trim() || '',
		codEntidade: '',
		dataNascimento: values?.dataNascimento
			? formatDate(values.dataNascimento.trim())
			: '',
		email: pesquisarContatoValues?.email.trim() || values?.email?.trim() || '',
		nacionalidade: values?.nacionalidade.trim() || '',
		naturalidade: values?.naturalidade.trim() || '',
		categoriaAtual: values?.categoriaAtual?.trim() || '',
		numeroCiretran: values?.ciretranDomicilio?.trim() || '',
		codCiretranHabilitacao: values?.ciretranDomicilio?.trim() || '',
		nome: values?.nome.trim() || '',
		nomeSocial: values?.nomeSocial?.trim() || '',
		nomeMae: values?.nomeMae?.trim() || '',
		nomePai: values?.nomePai?.trim() || '',
		numero_carteira_militar: '',
		numRg: values?.numDocumento?.trim() || '',
		orgaoExpDocumento: values?.orgaoDocumento?.trim() || '',
		sexo: values?.sexo?.trim() || 'M',
		tipo_documento: values?.tipoDocumento || '',
		orgaoUfExpDocumento: values?.ufDocumento?.trim() || '',
		rneUf:
			values?.nacionalidade.trim() === '1'
				? values?.ufRNE || ''
				: values?.ufRNE || 'EX',
		rneOrgao: values?.orgaoRNE?.trim() || '',
		rneNumero: values?.numRNE?.trim() || '',
		status: '',
		processo: '',
		celular:
			(pesquisarContatoValues &&
				pesquisarContatoValues.codigoAreaCelular &&
				pesquisarContatoValues.telefoneCelular &&
				`${`(${limparMascara(
					pesquisarContatoValues.codigoAreaCelular.trim(),
				)})${limparMascara(
					pesquisarContatoValues.telefoneCelular.trim(),
				)}`}`) ||
			'',
		telefone: '',
		confirmaEmail: '',
		militar: 'N',
		flagExameNoPosto: 'N',
		flagAtividadeRemunerada: values?.pretendeAtividadeRemunerada?.trim() || '',
		flagSolicitaCNHDigital: 'N',
		flagDeficienteFisico: values?.necessidadeVeiculoAdaptado?.trim() || '',
		flagExameToxicologico: '',
		paisAfetivos1: values?.paisAfetivos1?.trim() || '',
		paisAfetivos2: values?.paisAfetivos2?.trim() || '',
		paisCnhEstrangeiro: values?.paisCNHE?.trim() || '',
		dataValidadeCnhEstrangeira: values?.validadeCnhE
			? formatDate(values.validadeCnhE.trim())
			: '',
		numeroCnhEstrangeira: values?.numCNHE?.trim() || '',
		dataPrimeiraHabilitacao: formatDate(
			values?.dataPrimeiraHabilitacao?.trim() || '',
		),
		numeroPgu: values?.numPGU?.trim() || '',
		uf: uf || '',
		codigoVerificador: '',
		autorizaEnvioEmail: 'N',
		autorizaEnvioSms: 'N',
		codigoUnidadeTransito: endereco?.codigoUnidadeTransito || 0,
		dataCadastro: formatDateToString(new Date()),
		logradouroCodMunicipio: endereco?.codigoMunicipio.toString().trim() || '',
	};
	return formattedData;
}

// TREAT TO UPDATE CNH DIGITAL
export function treatValuesUpdateDataCnhDigital(
	values: FormCnhEstrangeiro,
	user: IUser,
	idAtendimento: string,
	idCidadao: string,
): AtualizarContatoRequest {
	return {
		evento: {
			id_atendimento: idAtendimento,
			id_cidadao: idCidadao,
			cpf: user.cpf,
			identificacao: values.cpf,
			ip: user.ip,
			canal: {
				id: 1,
				desc: 'Presencial',
				localidade: {
					id: Number(user.posto),
					desc: user.nomePosto,
				},
			},
		},
		cpf: values.cpf.toString(),
		codigoAreaCelular: values.celular
			? limparMascara(values.celular).substring(0, 2)
			: '',
		telefoneCelular: values.celular
			? limparMascara(values.celular).substring(2, 11)
			: '',
		email: values.email || '',
		emailCNHDigital: values.email || '',
		nomeCompleto: values.nome,
	};
}

// TREAT TO SEND FORM
export function treatValues(
	values: FormCnhEstrangeiro,
	user: IUser,
): AlterarRenachSemRegistroRequest | AlterarRenachComRegistroRequest {
	console.log('Values renach alterar: ', values);
	const formattedData = {
		cpf: values.cpf,
		sp: null,
		renach:
			values.numeroRenach && values.numeroRenach !== ''
				? values.numeroRenach.substring(2, 11)
				: '',
		numeroRegistro: values.numeroRegistro || '',
		numeroPGU: values.numeroPgu,
		nome: values.nome,
		nomeMae: values.nomeMae,
		nomePai: values.nomePai,
		nomeSocial: values.nomeSocial || '',
		dataNascimento:
			values.dataNascimento && unformatDate(values.dataNascimento),
		sexo: values.sexo,
		tipoDocumento: values.tipo_documento,
		numeroDocumento: values.numRg || '',
		orgaoExpedidordocumento: values.orgaoExpDocumento
			? onlyLetters(values.orgaoExpDocumento.trim())
			: '',
		ufDocumento: values.orgaoUfExpDocumento || '',
		nacionalidade: values.nacionalidade,
		codigoNaturalidade: values.naturalidade,
		logradouro: onlyNumbersLettersSpace(values.logradouro),
		numeroLogradouro: onlyNumbers(values.numero),
		complementoLogradouro: onlyNumbersLettersSpace(values.complemento),
		bairro: onlyNumbersLettersSpace(values.bairro),
		codigoMunicipio: values?.logradouroCodMunicipio.toString().trim() || '',
		cep: values.cep,
		usoFormularioEnum: values.usoFormularioEnum || '',
		codigoCiretran: values.numeroCiretran || '',
		numeroRNE: values.rneNumero || '',
		orgaoExpedidorRNE: values.rneOrgao
			? onlyLetters(values.rneOrgao.trim())
			: '',
		ufRNE: values.rneUf || '',
		codigoPaisCNHEstrangeiro: values.paisCnhEstrangeiro || '',
		dataValidadeCNHEstrangeiro: values.dataValidadeCnhEstrangeira
			? unformatDate(values.dataValidadeCnhEstrangeira)
			: null,
		dataPrimeiroHabilitacao: values.dataPrimeiraHabilitacao
			? unformatDate(values.dataPrimeiraHabilitacao)
			: null,
		identificacaoCNHEstrangeiro: values.numeroCnhEstrangeira || '',
		atividadeRemunerada: values.flagAtividadeRemunerada === 'S',
		veiculoAdaptado: values.flagDeficienteFisico === 'S',
		email: values.email || '',
		ddd: values.celular ? limparMascara(values.celular).substring(0, 2) : '',
		celular: values.celular
			? limparMascara(values.celular).substring(2, 11)
			: '',
		cnhDigital: values.flagSolicitaCNHDigital === 'S',
		codigoVerificador: values.codigoVerificador || '',
		cpfUsuario: user.cpf,
		paisAfetivos1: values.paisAfetivos1 || '',
		paisAfetivos2: values.paisAfetivos2 || '',
	};
	return formattedData;
}

export function treatValuesIntegrado(
	values: FormCnhEstrangeiro,
	user: IUser,
	exameMedico?: ICadastraExameMedico,
	examePsicologico?: ICadastraExamePsicologico,
): AlterarCadastroRequest {
	const horaFormatM =
		exameMedico?.horario_exame &&
		formatDateISOWithOutUTC(exameMedico?.horario_exame, true).split(' ')[1];

	const horaFormatP =
		examePsicologico?.horario_exame_psicologico &&
		formatDateISOWithOutUTC(
			examePsicologico.horario_exame_psicologico,
			true,
		).split(' ')[1];

	const formattedData = {
		candidato: {
			autorizaEmail: values.autorizaEnvioEmail === 'S' ? 'S' : 'N',
			autorizaSMS: values.autorizaEnvioSms === 'S' ? 'S' : 'N',
			bairro: onlyNumbersLettersSpace(values.bairro),
			celular: values.celular
				? limparMascara(values.celular).substring(2, 11)
				: '',
			cep: values.cep,
			cnhDigital: values.flagSolicitaCNHDigital === 'S' ? 'S' : 'N',
			codigoCiretranPostoExameMedico: '',
			codigoUnidadeAtendimento: user.posto,
			codigoVerificador: values.codigoVerificador || '',
			complemento: onlyNumbersLettersSpace(values.complemento),
			cpf: values.cpf,
			cpfUsuario: user.cpf,
			dataNascimento:
				values.dataNascimento && unformatDate(values.dataNascimento),
			dataValidadeCnhE: values.dataValidadeCnhEstrangeira
				? unformatDate(values.dataValidadeCnhEstrangeira)
				: '',
			dataPrimeiraHabilitacao: values.dataPrimeiraHabilitacao
				? unformatDate(values.dataPrimeiraHabilitacao)
				: '',
			dddCelular: values.celular
				? limparMascara(values.celular).substring(0, 2)
				: '',
			dddTelefone: values.telefone
				? limparMascara(values.telefone).substring(0, 2)
				: '',
			email: values.email || '',
			logradouro: onlyNumbersLettersSpace(values.logradouro),
			nacionalidade: values.nacionalidade,
			naturalidade: values.naturalidade,
			necessidadeVeiculoAdaptado:
				values.flagDeficienteFisico === 'S' ? 'S' : 'N',
			nome: values.nome,
			nomeMae: values.nomeMae,
			nomePai: values.nomePai,
			numCNHE: values.numeroCnhEstrangeira || '',
			numDocumento: values.numRg || '',
			numPGU: values.numeroPgu || '',
			numRNE: values.rneNumero || '',
			numRegistro: values.numeroRegistro || '',
			numero: values.numero,
			orgaoDocumento: values.orgaoExpDocumento
				? onlyLetters(values.orgaoExpDocumento.trim())
				: '',
			orgaoRNE: values.rneOrgao ? onlyLetters(values.rneOrgao.trim()) : '',
			paisCNHE: values.paisCnhEstrangeiro || '',
			pretendeAtividadeRemunerada:
				values.flagAtividadeRemunerada === 'S' ? 'S' : 'N',
			renach: values.numeroRenach ? values.numeroRenach : '',
			sexo: values.sexo,
			telefone: values.telefone
				? limparMascara(values.telefone).substring(2, 10)
				: '',
			tipoDocumento: values.tipo_documento,
			uf: values.uf || '',
			ufDocumento: values.orgaoUfExpDocumento || '',
			ufRNE: values.rneUf || '',
		},
		dadosExameMedico: {
			codigoCiretranExameMedico: exameMedico?.codigo_ciretran?.toString() || '',
			dataExame: exameMedico ? unformatDate(exameMedico.data_exame) : '',
			horarioExame: horaFormatM || '',
			idMedico: exameMedico ? `${exameMedico.id_medico}` : '',
			idMicroRegiaoMedico: exameMedico
				? `${exameMedico.id_micro_regiao_medico}`
				: '',
		},
		dadosExamePsicologo: {
			codigoCiretranExamePsicologo:
				examePsicologico?.codigo_ciretran_exame_psicologo?.toString() || '',
			dataExamePsicologico: examePsicologico
				? unformatDate(examePsicologico.data_exame_psicologico)
				: '',
			horarioExamePsicologico: horaFormatP || '',
			idMicroRegiaoPsicologo: examePsicologico
				? `${examePsicologico.id_micro_regiao_psicologo}`
				: '',
			idPsicologo: examePsicologico ? `${examePsicologico.id_psicologo}` : '',
		},
	};
	return formattedData;
}

export const schema = Yup.object<FormCnhEstrangeiro>().shape(
	{
		categoriaPretendida: Yup.string().required(
			'Campo Obrigatório: Categoria Pretendida.',
		),
		flagAtividadeRemunerada: Yup.string().required(
			'Campo Obrigatório: Pretende exercer atividade remunerada.',
		),
		flagDeficienteFisico: Yup.string().required(
			'Campo Obrigatório: Tem necessidade de veículo adaptado.',
		),
		nome: Yup.string().required('Campo Obrigatório: Nome.'),
		nomeSocial: Yup.string()
			.when(['flagNomeSocial'], {
				is: flagNomeSocial => flagNomeSocial === 'S',
				then: Yup.string().required('Campo Obrigatório: Nome Social.'),
			})
			.test(
				'same-name',
				'O nome social não pode ser igual ao nome civil.',
				function verify(value) {
					return value !== '' && this.parent.flagNomeSocial === 'S'
						? this.parent.nome !== value
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-social-name',
				"O campo Nome Social não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		nacionalidade: Yup.string().required('Campo Obrigatório: Nacionalidade.'),
		uf: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '1',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),
		naturalidade: Yup.string().required('Campo Obrigatório: Naturalidade.'),
		numRg: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: RG.'),
		}),
		orgaoExpDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
		}),
		orgaoUfExpDocumento: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() !== '3',
			then: Yup.string().required('Campo Obrigatório: UF.'),
		}),
		rneNumero: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: RNE.'),
		}),
		rneOrgao: Yup.string().when(['nacionalidade'], {
			is: nacionalidade => nacionalidade.toString() === '3',
			then: Yup.string().required('Campo Obrigatório: Órgão Emissor.'),
		}),
		paisCnhEstrangeiro: Yup.string().when(
			['nacionalidade', 'usoFormularioEnum'],
			{
				is: (nacionalidade, usoFormularioEnum) =>
					nacionalidade.toString() === '3' &&
					usoFormularioEnum === enumFormUse.CNH_ESTRANGEIRA,
				then: Yup.string().required('Campo Obrigatório: País CNH Estrangeira.'),
			},
		),
		dataValidadeCnhEstrangeira: Yup.string()
			.when(['nacionalidade', 'usoFormularioEnum'], {
				is: (nacionalidade, usoFormularioEnum) =>
					nacionalidade.toString() === '3' &&
					usoFormularioEnum === enumFormUse.CNH_ESTRANGEIRA,
				then: Yup.string().required(
					'Campo Obrigatório: Validade CNH Estrangeira.',
				),
			})
			.matches(userDataRegex.dateWithoutTime, {
				message: 'Validade CNH Estrangeira: Não é uma data válida.',
				excludeEmptyString: true,
			}),
		numeroCnhEstrangeira: Yup.string().when(
			['nacionalidade', 'usoFormularioEnum'],
			{
				is: (nacionalidade, usoFormularioEnum) =>
					nacionalidade.toString() === '3' &&
					usoFormularioEnum === enumFormUse.CNH_ESTRANGEIRA,
				then: Yup.string().required('Campo Obrigatório: Nº CNH Estrangeira.'),
			},
		),
		dataPrimeiraHabilitacao: Yup.string()
			.when(['nacionalidade', 'usoFormularioEnum'], {
				is: (nacionalidade, usoFormularioEnum) =>
					nacionalidade.toString() === '3' &&
					usoFormularioEnum === enumFormUse.CNH_ESTRANGEIRA,
				then: Yup.string().required(
					'Campo Obrigatório: Data Primeira Habilitação.',
				),
			})
			.matches(userDataRegex.dateWithoutTime, {
				message: 'Data Primeira Habilitação: Não é uma data válida.',
				excludeEmptyString: true,
			}),
		nomeMae: Yup.string().required('Campo Obrigatório: Nome da Mãe.'),
		paisAfetivos1: Yup.string()
			.when(['flagFiliacaoAfetiva'], {
				is: flagFiliacaoAfetiva => flagFiliacaoAfetiva === 'S',
				then: Yup.string().required('Campo Obrigatório: Filiação Afetiva 1.'),
			})
			.test(
				'same-name-filiacoes',
				'O nome da filiação afetiva 1 não pode ser igual ao nome da filiação afetiva 2.',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? this.parent.paisAfetivos2 !== value
						: true;
				},
			)
			.test(
				'same-name-filiacao1',
				'A filiação afetiva 1 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-1',
				"O campo filiação afetiva 1 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		paisAfetivos2: Yup.string()
			.test(
				'same-name-filiacao2',
				'A filiação afetiva 2 não pode ser igual ao nome do pai e (ou) mãe biológico(a).',
				function verify(value) {
					return value !== '' && this.parent.flagFiliacaoAfetiva === 'S'
						? !(this.parent.nomePai === value || this.parent.nomeMae === value)
						: true;
				},
			)
			.matches(sameThreeCharactersInSequenceNotAllowedRegex, {
				message:
					"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				excludeEmptyString: true,
			})
			.test(
				'verify-filiation-name-2',
				"O campo filiação afetiva 2 não permite os valores 'Nada Consta', 'Não Consta', 'N/A', 'Não se aplica', 'Não declarado', 'Sequências de 'X'', 'Não informado', 'Não existe'.",
				function verify(value) {
					return !(
						value &&
						verifyStringIsAnInvalidPhrase(
							value,
							socialNameAndFiliationsPhrasesNotAllowed,
						)
					);
				},
			),
		sexo: Yup.string().required('Campo Obrigatório: Sexo.'),
		dataNascimento: Yup.string()
			.required('Campo Obrigatório: Data de Nascimento.')
			.matches(userDataRegex.dateWithoutTime, {
				message: 'Data de Nascimento: Não é uma data válida.',
				excludeEmptyString: true,
			})
			.test(
				'verifica-maior-idade',
				'Data de Nascimento: Não é uma data válida.',
				function compareDate(value) {
					if (value) {
						return verificarMaioridade(value);
					}
					return true;
				},
			),
		cep: Yup.string().required('Campo Obrigatório: CEP.'),
		logradouro: Yup.string().required('Campo Obrigatório: Logradouro.'),
		numero: Yup.string().required('Campo Obrigatório: Número.'),
		bairro: Yup.string().required('Campo Obrigatório: Bairro.'),
		municipio: Yup.string().required('Campo Obrigatório: Município.'),
		telefone: Yup.string()
			.when(['celular', 'flagSolicitaCNHDigital', 'usoFormularioEnum'], {
				is: (celular, flagSolicitaCNHDigital, usoFormularioEnum) =>
					(celular === '' &&
						showComponent(
							[
								enumFormUse.RENOVACAO,
								enumFormUse.ADICAO_MUDANCA,
								enumFormUse.CASSACAO,
								enumFormUse.PERMISSIONARIO_PONTUADO,
								enumFormUse.CNH_ESTRANGEIRA,
							],
							usoFormularioEnum,
						)) ||
					(celular === '' && flagSolicitaCNHDigital === 'S'),
				then: Yup.string().required('Campo Obrigatório: Telefone.'),
			})
			.matches(userDataRegex.cellPhoneOrPhoneNumberWithAreaCode, {
				message: 'Campo Inválido: Telefone.',
				excludeEmptyString: true,
			}),
		email: Yup.string()
			.when(['autorizaEnvioEmail'], {
				is: autorizaEnvioEmail => autorizaEnvioEmail === 'S',
				then: Yup.string().required('Campo Obrigatório: Email.'),
			})
			.matches(userDataRegex.emailAddress, {
				message: 'Formato de e-mail inválido.',
				excludeEmptyString: true,
			})
			.test(
				'same-email',
				'A confirmação do e-mail é diferente do e-mail informado.',
				function verify(value) {
					return value !== '' ? this.parent.confirmaEmail === value : true;
				},
			),
		celular: Yup.string()
			.when(
				[
					'telefone',
					'flagSolicitaCNHDigital',
					'autorizaEnvioSms',
					'usoFormularioEnum',
				],
				{
					is: (
						telefone,
						flagSolicitaCNHDigital,
						autorizaEnvioSms,
						usoFormularioEnum,
					) =>
						(telefone === '' &&
							showComponent(
								[
									enumFormUse.RENOVACAO,
									enumFormUse.ADICAO_MUDANCA,
									enumFormUse.CASSACAO,
									enumFormUse.PERMISSIONARIO_PONTUADO,
								],
								usoFormularioEnum,
							)) ||
						(telefone === '' && flagSolicitaCNHDigital === 'S') ||
						autorizaEnvioSms === 'S',
					then: Yup.string().required('Campo Obrigatório: Celular.'),
				},
			)
			.matches(userDataRegex.cellPhoneNumber, {
				message: 'Campo Inválido: Celular.',
				excludeEmptyString: true,
			}),
		flagSolicitaCNHDigital: Yup.string().required(
			'Campo Obrigatório: CNH Via Impressa ou CNH Via Digital (Impressa + Digital).',
		),
	},
	[['telefone', 'celular']],
);
