import { Types } from './types';

export function licenciamentoVeiculoRequest(payload: any) {
	return {
		type: Types.LICENCIAMENTO_VEICULO_REQUEST,
		payload,
	};
}
export function licenciamentoVeiculoSuccess(payload: any) {
	return {
		type: Types.LICENCIAMENTO_VEICULO_SUCCESS,
		payload,
	};
}
export function licenciamentoVeiculoFailure(payload: []): any {
	return {
		type: Types.LICENCIAMENTO_VEICULO_FAILURE,
		payload,
	};
}
export function licenciamentoVeiculoClear(): any {
	return {
		type: Types.LICENCIAMENTO_VEICULO_CLEAR,
	};
}
