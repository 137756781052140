import { ReducerAction } from 'store/modules/types';

import { Types, CadastrarMenusRequest } from './types';

export function cadastrarMenusRequest(
	data: CadastrarMenusRequest,
): ReducerAction {
	return {
		type: Types.CADASTRAR_MENUS,
		payload: data,
	};
}
export function cadastrarMenusSuccess(payload: object): ReducerAction {
	return {
		type: Types.CADASTRAR_MENUS_SUCCESS,
		payload,
	};
}
export function cadastrarMenusFailure(): ReducerAction {
	return {
		type: Types.CADASTRAR_MENUS_FAILURE,
		payload: null,
	};
}
export function cadastrarMenusClear(): ReducerAction {
	return {
		type: Types.CADASTRAR_MENUS_CLEAR,
		payload: null,
	};
}
