export enum Types {
	LOG_IN_REQUEST = '@login/LOG_IN_REQUEST',
	LOG_IN_SUCCESS = '@login/LOG_IN_SUCCESS',
	LOG_IN_FAILURE = '@login/LOG_IN_FAILURE',
	LOG_IN_CHANGE_PASSWORD = '@login/LOG_IN_CHANGE_PASSWORD',

	FIX_LOG_IN = '@login/FIX_LOG_IN',
	LIMPA_LOGIN = '@login/LIMPA_LOGIN',

	LOG_OFF_REQUEST = '@login/LOG_OFF_REQUEST',
	LOG_OFF_AND_RELOAD_REQUEST = '@login/LOG_OFF_AND_RELOAD_REQUEST',

	LOCKED_URL_REQUEST = '@login/LOCKED_URL_REQUEST',

	EXTENDER_TEMPO_PARA_INATIVIDADE = '@login/EXTENDER_TEMPO_PARA_INATIVIDADE',
}

export interface ILoginUnicoState {
	login: ILogin;
	renovacaoMainframe: string;
	resetSenha: { status: boolean; message: string };
	user: {
		idUsuario: string;
		cpf: string;
		nome: string;
		orgaos: any;
		login?: boolean;
		parametrosSistema: IParametrosLocalidade[];
		permisaoUsuario?: {
			isTemAcessoADM: boolean;
			isTemAcessoGerenciarRelatorio: boolean;
		};
		perfil: any;
	};
	reload: boolean;
	tempoLogado: number;
	lockedUrl: boolean;
}

export interface IParametrosLocalidade {
	id: number;
	descricao: string;
	sigla: string;
	valor: string;
}

export interface IUser {
	cpf: string;
	idLocal: number;
	idOrgao: number;
	idCanal: number;
	descCanal: string;
	posto: string;
	digito_posto: string;
	nomePosto: string;
	ip: string;
	parametrosLocalidade: IParametrosLocalidade[] | [];
	idLocalidade: number;
}

export interface ILogin {
	mainframe: {
		idUsuario: string;
		senhaMainFrame: string;
	};
	user: IUser;
}

export interface ILoginRequest {
	cpf: string;
	senha: string;
}
