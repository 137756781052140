import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_RFB } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarCinRequest } from './types';

// ACTIONS
import { cadastrarCinSuccess, cadastrarCinFailure } from './actions';

function* cadastrarCinRequest(request: ReducerAction) {
	const { payload }: { payload: CadastrarCinRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_RFB,
		`cin/${CHANNEL}/cadastrar`,
		payload,
		undefined,
		true,
	);

	if (response.status === 200) {
		yield put(cadastrarCinSuccess(response.data));
	} else {
		yield put(cadastrarCinFailure(response));
	}
}

export default all([
	takeLatest(Types.CADASTRAR_CIN_REQUEST, cadastrarCinRequest),
]);
