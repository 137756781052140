// import { IEvento } from '../types';

export enum Types {
	REENVIAR_DOCUMENTOS_REQUEST = '@DETRAN-CRV/REENVIAR_DOCUMENTOS_REQUEST',
	REENVIAR_DOCUMENTOS_SUCCESS = '@DETRAN-CRV/REENVIAR_DOCUMENTOS_SUCCESS',
	REENVIAR_DOCUMENTOS_FAILURE = '@DETRAN-CRV/REENVIAR_DOCUMENTOS_FAILURE',
	REENVIAR_DOCUMENTOS_CLEAR = '@DETRAN-CRV/REENVIAR_DOCUMENTOS_CLEAR',
}

export interface ReenviarDocumentos {
	status: number;
	data: IReenviarDocumentosResponse | null;
	mensagem?: string;
}

export interface IReenviarDocumentosResponse {
	chassi: string;
	placa: string;
	renavam: string;
	numeroFicha: string;
	anoFicha: string;
	codigo: string;
	mensagem: string;
}

export interface IReenviarDocumentosRequest {
	anoFicha: string;
	arquivos: Arquivo[];
	numeroFicha: string;
	cpfUsuarioBU: string;
	codUnidadeTransito: number;
}

export interface Arquivo {
	conteudo: string;
	tipoArquivo: string;
}
