import styled from 'styled-components';

export const NumberSpan = styled.span`
	color: gray;
	font-weight: 600;
	font-size: 35px;
`;

export const Container = styled.div`
	.ant-steps-navigation .ant-steps-item::before {
		background-color: red;
	}
`;

export const Option = styled.div`
	text-align: center;
	&:hover {
		cursor: pointer;
	}
`;

export const Box = styled.div`
	margin: 20px 0;
	border: solid 1px #ccc;
	text-align: center;
`;
