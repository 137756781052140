export enum Types {
	ENVIAR_CENTRAL_NUMERAL_REQUEST = '@aac/ENVIAR_CENTRAL_NUMERAL_REQUEST',
	ENVIAR_CENTRAL_NUMERAL_SUCCESS = '@aac/ENVIAR_CENTRAL_NUMERAL_SUCCESS',
	ENVIAR_CENTRAL_NUMERAL_FAILURE = '@aac/ENVIAR_CENTRAL_NUMERAL_FAILURE',
	ENVIAR_CENTRAL_NUMERAL_CLEAN = '@aac/ENVIAR_CENTRAL_NUMERAL_CLEAN',
}

export interface EnviarCentralNumeral {
	status: number;
	data: DataEnviarCentralNumeral;
}

export interface DataEnviarCentralNumeral {
	atestadoEmitido?: string;
	codigoRetorno?: string;
	mensagemRetorno?: string;
	codigoValidacao?: string;
	dataEmissaoAtestado?: number;
	codigo?: number;
	mensagem?: string;
}

export interface RequestEnviarCentralNumeral {
	codigoRetorno: string;
	dc: string;
	dataExpedicaoRg: string;
	dataNascimento: string | null;
	docProcurador: string;
	ipEstacao: string;
	nome: string;
	nomeSocial: string;
	nomeMae: string;
	nomePai: string;
	nomeProcurador: string;
	numeroRg: number;
	posto: string;
	sexo: string;
	status: string;
	tipoDocApresentado: string;
	usuario: string;
	tipoEncaminhamento: string;
	origem: string;
}
