// import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	IMPRIMIR_RENACH_REQUEST = '@cnh/IMPRIMIR_RENACH_REQUEST',
	IMPRIMIR_RENACH_SUCCESS = '@cnh/IMPRIMIR_RENACH_SUCCESS',
	IMPRIMIR_RENACH_FAILURE = '@cnh/IMPRIMIR_RENACH_FAILURE',
	IMPRIMIR_RENACH_CLEAR = '@cnh/IMPRIMIR_RENACH_CLEAR',
}

export interface ImprimirRenach {
	status: number;
	data: null | ImprimirRenachData;
}

interface ImprimirRenachData {
	url: string;
}

export interface ImprimirRenachRequest {
	cpfCidadao: string;
	cpfUsuarioLogado: string;
}
