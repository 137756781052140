import { combineReducers } from 'redux';

import consultaPermissaoInternacionalAutomatizado from './consultaPermissaoInternacionalAutomatizado/reducer';
import consultaEPortalAutomatizado from './consultaEPortalAutomatizado/reducer';
import gerarRenachAutomatizado from './gerarRenachAutomatizado/reducer';
import emissaoPid from './emissaoPid/reducer';

export default combineReducers({
	consultaPermissaoInternacionalAutomatizado,
	consultaEPortalAutomatizado,
	gerarRenachAutomatizado,
	emissaoPid,
});
