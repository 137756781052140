export enum Types {
	IMPRESSAO_ALTERACAO_ENDERECO_REQUEST = '@DETRAN-CRV/IMPRESSAO_ALTERACAO_ENDERECO_REQUEST',
	IMPRESSAO_ALTERACAO_ENDERECO_SUCCESS = '@DETRAN-CRV/IMPRESSAO_ALTERACAO_ENDERECO_SUCCESS',
	IMPRESSAO_ALTERACAO_ENDERECO_FAILURE = '@DETRAN-CRV/IMPRESSAO_ALTERACAO_ENDERECO_FAILURE',
}

export interface impressaoAlteracaoEndereco {
	status: number;
	data: ficha | null;
}

interface ficha {
	anoFicha: number;
	chassi: string;
	numeroficha: number;
	placa: string;
	fichaCadastral: string;
}
