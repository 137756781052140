import { combineReducers } from 'redux';

import atualizarUnidade from './atualizarUnidade/reducer';
import cadastrarUnidade from './cadastrarUnidade/reducer';
import consultarUnidades from './consultarUnidades/reducer';
import excluirUnidade from './excluirUnidade/reducer';

export default combineReducers({
	atualizarUnidade,
	cadastrarUnidade,
	consultarUnidades,
	excluirUnidade,
});
