// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaDBCINRequest, Types } from './types';

export function consultaDBCINIirgdRequest(
	payload: ConsultaDBCINRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_DBCIN_IIRGD_REQUEST,
		payload,
	};
}
export function consultaDBCINIirgdSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_DBCIN_IIRGD_SUCCESS,
		payload,
	};
}
export function consultaDBCINIirgdFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_DBCIN_IIRGD_FAILURE,
		payload,
	};
}
export function consultaDBCINIirgdClear(): ReducerAction {
	return {
		type: Types.CONSULTA_DBCIN_IIRGD_CLEAR,
		payload: null,
	};
}
