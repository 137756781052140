import { EventoContabilizacao } from 'store/modules/types';

export enum Types {
	DESBLOQUEIO_CARTAO = '@MP/DESBLOQUEIO_CARTAO',
	DESBLOQUEIO_CARTAO_SUCCESS = '@MP/DESBLOQUEIO_CARTAO_SUCCESS',
	DESBLOQUEIO_CARTAO_FAILURE = '@MP/DESBLOQUEIO_CARTAO_FAILURE',
	LIMPAR_DESBLOQUEIO_CARTAO = '@MP/LIMPAR_DESBLOQUEIO_CARTAO',
}

export interface DesbloqueiaCartao {
	status: number;
	data: IRequestValidacaoCidadao | null;
}
export interface IRequestValidacaoCidadao {
	evento: EventoContabilizacao;
	idCpf: string;
	dsNome: string;
	dsPrimeiroNomeMae: string;
	dsNaoPossuiNomeMae: string | boolean;
	DsNumeroCartaoQuatroUltimosDigitos: string;
	dtNascimento: string;
	dsAtendenteCpf: string;
	dsAtendenteNome: string;
}
