import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaReabilitacaoRequest } from './types';

export function consultaReabilitacaoRequest(
	data: ConsultaReabilitacaoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_REABILITACAO,
		payload: data,
	};
}
export function consultaReabilitacaoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_REABILITACAO_SUCCESS,
		payload,
	};
}
export function consultaReabilitacaoFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_REABILITACAO_FAILURE,
		payload,
	};
}
export function consultaReabilitacaoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_REABILITACAO_CLEAR,
		payload: null,
	};
}
