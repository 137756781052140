// ROUTES
import { ROUTE_IIRGD } from 'routes/paths';
import Formulario from '../Formulario';

import { ROUTE_IIRGD_CONSULTAR_CPF } from './paths';

const iirgdRoute = {
	name: 'IIRGD',
	route: ROUTE_IIRGD,
};

export default [
	{
		path: ROUTE_IIRGD_CONSULTAR_CPF,
		breadcrumb: [
			iirgdRoute,
			{
				name: 'Consultar CPF',
			},
		],
		Component: Formulario,
		isAuthenticated: true,
		exact: true,
	},
];
