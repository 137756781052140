import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { Types } from './types';

// ACTIONS
import { consultarTiposSuccess, consultarTiposFailure } from './actions';

function* consultarTipos() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`tipos-localidade`,
	);

	if (response.status === 200) {
		yield put(consultarTiposSuccess(response));
	} else {
		yield put(consultarTiposFailure());
	}
}

export default all([takeLatest(Types.CONSULTAR_TIPOS, consultarTipos)]);
