import { store } from '../../../index';

// TYPES
import { Types } from './types';

export function setLoader(payload: boolean) {
	store.dispatch({
		type: Types.SET_LOADER,
		payload,
	});
}
