import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DARE } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { RequestImprimirDare, Types } from './types';
import { imprimirDareSuccess, imprimirDareFailure } from './actions';

function* imprimirDareRequest(request: ReducerAction) {
	const { payload }: { payload: RequestImprimirDare } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_DARE,
		`dare/${CHANNEL}/imprime/${payload.numeroDare}`,
	);

	if (response.status === 201 || response.status === 200) {
		yield put(imprimirDareSuccess(response.data));
	} else if (response.error) {
		yield put(imprimirDareFailure());
	}
}

export default all([
	takeLatest(Types.IMPRIMIR_DARE_REQUEST, imprimirDareRequest),
]);
