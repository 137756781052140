import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CRV } from 'services/_path';
import { Types } from './types';
import { consultaTipoSuccess, consultaTipoFailure } from './actions';

function* consultaTipoRequest() {
	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CRV,
		`consultas/${CHANNEL}/consulta-tipo`,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultaTipoSuccess(response));
	} else if (response.error) {
		yield put(consultaTipoFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTA_TIPO_REQUEST, consultaTipoRequest),
]);
