import {
	IRequestConsultaBeneficiario,
	IRequestSelectMotivosCorte,
} from 'store/modules/api/seds/vivaLeite/beneficiarios/types';
import * as Yup from 'yup';

export interface ICodPrograma {
	cod_programa: number | string;
	exclusoes: IFormExclusaoBeneficiarios[];
	selectedItems?: [];
}

export interface IExclusaoBeneficiarios {
	cod_pan_beneficio: number;
	codigo_motivo: number;
	outros_motivos?: string;
	nome?: string;
}

export interface IFormExclusaoBeneficiarios {
	cod_pan_beneficio?: number;
	codigo_motivo: number | string;
	outros_motivos?: string;
}

export const InitialConsultaBeneficiario: IRequestConsultaBeneficiario = {
	servico: 'PRESTACAO CONTAS',
	origem: 'PORTAL',
	num_pan: 0,
	cod_programa: 0,
	etapa: 'lista_beneficiarios',
};

export const InitialConsultaMotivosCorte: IRequestSelectMotivosCorte = {
	servico: 'PRESTACAO CONTAS',
	origem: 'PORTAL',
	cod_programa: 0,
};

export const InitialExclusaoBeneficiarios: IExclusaoBeneficiarios[] = [];

export const InitialValues: ICodPrograma = {
	cod_programa: '',
	exclusoes: [],
	selectedItems: [],
};

export const selectPrograma = [
	{ label: 'CRIANÇA', value: 1 },
	{ label: 'IDOSO', value: 2 },
];

export function treatValues(
	selectedItems: number[],
	objectExclusao: IFormExclusaoBeneficiarios[],
): IExclusaoBeneficiarios[] {
	const exclusoes: IExclusaoBeneficiarios[] = [];

	// Valida para envio somente os códigos que estão selecionados
	selectedItems.forEach(item => {
		const repeatIndex = objectExclusao.findIndex(newItem => {
			return item === newItem?.cod_pan_beneficio;
		});

		if (repeatIndex > -1) {
			exclusoes.push(objectExclusao[repeatIndex] as IExclusaoBeneficiarios);
		}
	});

	return exclusoes;
}

export const schema = Yup.object<ICodPrograma>().shape({
	cod_programa: Yup.number()
		.required('Selecione o programa')
		.nullable()
		.transform((value: string, originalValue: string) =>
			originalValue.toString().trim() === '' ? null : value,
		),
	selectedItems: Yup.array()
		.min(1, 'Exclusões não informadas')
		.when(
			'exclusoes',
			(exclusoes: IFormExclusaoBeneficiarios[], schema2: any) => {
				return schema2.test({
					test: (selectedItems: number[]) => {
						let returnValue = true;
						selectedItems.forEach((item: number) => {
							if (exclusoes.length < 1) {
								returnValue = false;
							} else {
								const repeatIndex = exclusoes.findIndex(newItem => {
									return item === newItem?.cod_pan_beneficio;
								});
								if (
									repeatIndex <= -1 ||
									exclusoes[repeatIndex].codigo_motivo === ''
								) {
									returnValue = false;
								}
							}
						});
						return returnValue;
					},
					message: 'Insira um motivo para todos os beneficiários excluídos',
				});
			},
		)
		.when(
			'exclusoes',
			(exclusoes: IFormExclusaoBeneficiarios[], schema2: any) => {
				return schema2.test({
					test: (selectedItems: number[]) => {
						let returnValue = true;
						selectedItems.forEach((item: number) => {
							if (exclusoes.length > 0) {
								const repeatIndex = exclusoes.findIndex(newItem => {
									return item === newItem?.cod_pan_beneficio;
								});
								if (repeatIndex > -1) {
									if (
										exclusoes[repeatIndex].codigo_motivo === 9 &&
										(exclusoes[repeatIndex].outros_motivos === '' ||
											!exclusoes[repeatIndex].outros_motivos)
									) {
										returnValue = false;
									}
								}
							}
						});
						return returnValue;
					},
					message:
						'Insira uma descrição para todos os beneficiários excluídos com o motivo OUTRO',
				});
			},
		),
});
