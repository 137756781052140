import styled from 'styled-components';

export const BoxRenach = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	justify-content: center;
	width: 100%;
	background-color: #e1f2e1;
	border: solid 1px black;
	border-radius: 5px;

	> h2 {
		color: #5b855a;
		margin-bottom: 20px;
	}
`;

export const MessageRed = styled.p`
	color: red;
	font-size: 15px;
	font-weight: bold;
`;
