import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { ConsultarUsuarioRequest, Types } from './types';
import { consultarUsuarioSuccess, consultarUsuarioFailure } from './actions';

function* consultarUsuarioRequest(request: ReducerAction) {
	const { payload }: { payload: ConsultarUsuarioRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`usuarios/${payload.id}`,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(consultarUsuarioSuccess(response));
	} else if (response.error) {
		yield put(consultarUsuarioFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_USUARIO_REQUEST, consultarUsuarioRequest),
]);
