import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, AlterarCadastro } from './types';

export const INITIAL_STATE: AlterarCadastro = {
	status: 0,
	data: null,
	dataError: null,
	request: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): AlterarCadastro {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ALTERAR_CADASTRO_REQUEST: {
				draft.status = 100;
				break;
			}

			case Types.ALTERAR_CADASTRO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.request = action.payload.request;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}

			case Types.ALTERAR_CADASTRO_FAILURE: {
				draft.status = action.payload.status;
				draft.data = INITIAL_STATE.data;
				draft.dataError = action.payload.data;
				break;
			}

			case Types.ALTERAR_CADASTRO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.request = INITIAL_STATE.request;
				draft.dataError = INITIAL_STATE.dataError;
				break;
			}
			default:
		}
		return draft;
	});
}
