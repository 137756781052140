import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { treatObjNullValues } from 'utils/genericFunctions';
import {
	Types,
	IConsultaPesquisaObservacaoState,
	IConsultaPesquisaObservacao,
} from './types';

export const INITIAL_STATE: IConsultaPesquisaObservacaoState = {
	result: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IConsultaPesquisaObservacaoState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PESQUISA_OBSERVACAO_REQUEST: {
				break;
			}
			case Types.CONSULTA_PESQUISA_OBSERVACAO_SUCCESS: {
				const newObs: IConsultaPesquisaObservacao = treatObjNullValues(
					action.payload,
				) as IConsultaPesquisaObservacao;
				draft.result = newObs;
				break;
			}
			case Types.CONSULTA_PESQUISA_OBSERVACAO_FAILURE: {
				draft.result = INITIAL_STATE.result;
				break;
			}

			case Types.CONSULTA_PESQUISA_OBSERVACAO_CLEAR: {
				draft.result = INITIAL_STATE.result;
				break;
			}

			default:
		}
		return draft;
	});
}
