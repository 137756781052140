export enum Types {
	SET_BREADCRUMB = '@app/SET_BREADCRUMB',
}

export interface IBreadcrumb {
	name: string;
	route?: string;
}

export interface BreadcrumbState {
	data: IBreadcrumb[] | null;
}
