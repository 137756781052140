import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CancelarSolicitacaoEmissaoPID } from './types';

export const INITIAL_STATE: CancelarSolicitacaoEmissaoPID = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): CancelarSolicitacaoEmissaoPID {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CANCELAR_SOLICITACAO_EMISSAO_PID_REQUEST: {
				draft.status = 100;
				break;
			}

			case Types.CANCELAR_SOLICITACAO_EMISSAO_PID_SUCCESS: {
				draft.data = action.payload.response.data;
				draft.status = action.payload.response.status;
				break;
			}

			case Types.CANCELAR_SOLICITACAO_EMISSAO_PID_FAILURE: {
				draft.status = 400;
				break;
			}

			case Types.CANCELAR_SOLICITACAO_EMISSAO_PID_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
	});
}
