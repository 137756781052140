export enum Types {
	RESPOSTA_RESOLVIDA_REQUEST = '@procon/RESPOSTA_RESOLVIDA_REQUEST',
	RESPOSTA_RESOLVIDA_SUCCESS = '@procon/RESPOSTA_RESOLVIDA_SUCCESS',
	RESPOSTA_RESOLVIDA_FAILURE = '@procon/RESPOSTA_RESOLVIDA_FAILURE',
	RESPOSTA_RESOLVIDA_CLEAR = '@procon/RESPOSTA_RESOLVIDA_CLEAR',
}

export interface RespostaResolvida {
	status: null | number;
	data: null | RespostaResolvidaData;
}

interface RespostaResolvidaData {}

interface Evento {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	idAtendimento?: string;
	idCidadao?: string;
	identificacao: string | number;
	ip: string;
	canal?: {
		id: number;
		desc: string;
		estacao?: {
			id: string;
			desc: string;
		};
		localidade: {
			id: number;
			desc: string;
		};
	};
}

export interface RespostaResolvidaRequest {
	evento: Evento;
	atendimentoId: string;
	protocolo: string;
	tipoDaResolucao: number;
	textoDaJustificativa: string;
}

export interface RespostaResolvidaForm {
	tipoDaResolucao: number | undefined;
	justificativa: string;
}
