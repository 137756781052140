export enum Types {
	EMITIR_ESCOLHA_PLACA_PERSONALIZADA_REQUEST = '@DETRAN-CRV/EMITIR_ESCOLHA_PLACA_PERSONALIZADA_REQUEST',
	EMITIR_ESCOLHA_PLACA_PERSONALIZADA_SUCCESS = '@DETRAN-CRV/EMITIR_ESCOLHA_PLACA_PERSONALIZADA_SUCCESS',
	EMITIR_ESCOLHA_PLACA_PERSONALIZADA_FAILURE = '@DETRAN-CRV/EMITIR_ESCOLHA_PLACA_PERSONALIZADA_FAILURE',
	EMITIR_ESCOLHA_PLACA_PERSONALIZADA_CLEAR = '@DETRAN-CRV/EMITIR_ESCOLHA_PLACA_PERSONALIZADA_CLEAR',
}

export interface emitirEscolhaPlacaPersonalizada {
	status: number;
	data: ResponseEmitirEscolhaPlacaPersonalizada | null;
}

export interface ResponseEmitirEscolhaPlacaPersonalizada {
	chassi: string;
	placa: string;
}

export interface RequestEmitirEscolhaPlacaPersonalizada {
	evento: {
		id_atendimento: string;
		id_cidadao: string;
		cpf: string;
		identificacao: string;
		ip: string;
		canal: {
			id: number;
			desc: string;
			estacao?: {
				id: number;
				desc: string;
			};
			localidade: {
				id: number;
				desc: string;
			};
		};
	};
	cpfCnpjProprietario: number;
	chassi: string;
	placa: string;
}
