import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_PREFEITURAS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultaPrefeiturasServicosCanaisSuccess,
	consultaPrefeiturasServicosCanaisFailure,
	IPrefeiturasServicosCanais,
} from './actions';

function* consultaPrefeiturasServicosCanais(request: ReducerAction) {
	const { payload }: { payload: IPrefeiturasServicosCanais } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_PREFEITURAS,
		`prefeituras-servicos/${payload.idPrefeituraServico}/canais`,
		{},
		// payload.body,
	);

	if (response.status === 200) {
		yield put(consultaPrefeiturasServicosCanaisSuccess(response));
	} else if (response.error) {
		yield put(consultaPrefeiturasServicosCanaisFailure(response.message));
	} else if (response.status === 204) {
		yield put(consultaPrefeiturasServicosCanaisFailure([]));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_PREFEITURA_SERVICOS_CANAIS,
		consultaPrefeiturasServicosCanais,
	),
]);
