import React, { Suspense, useEffect, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import Section from 'components/Common/Section';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import PDFViewer from 'components/Common/PDFViewer';
import { impressaoOficioSegundaViaPlacaRequest } from 'store/modules/api/detranCrv/Processos/ImpressaoOficioSegundaViaPlaca/actions';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { initialValues, schema } from './form';

const ImpressaoOficioSegundaViaPlaca: React.FC = () => {
	const dispatch = useDispatch();

	const { impressaoOficioSegundaViaPlaca } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.processos,
	);

	const [results, setResults] = useState<boolean>(false);
	const [showPrint, setShowPrint] = useState<boolean>(false);

	useEffect(() => {
		if (impressaoOficioSegundaViaPlaca.status === 200) {
			setResults(true);
		}
	}, [impressaoOficioSegundaViaPlaca]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => {
						dispatch(
							impressaoOficioSegundaViaPlacaRequest({
								numeroFicha: values.numeroFicha,
								anoFicha: values.anoFicha,
							}),
						);
					}}
				>
					{(formik: FormikProps<any>) => {
						return (
							<Form>
								<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
									<Col span={14}>
										<Field
											titleSize="lg"
											required={!results}
											maxLength={7}
											readOnly={results}
											name="numeroFicha"
											title="Número da Ficha"
											as={Input}
											error={hasError(formik.errors, 'numeroFicha')}
										/>
									</Col>
									<Col span={10} />
									<Col span={14}>
										<Field
											titleSize="lg"
											required={!results}
											readOnly={results}
											maxLength={results ? '' : 4}
											name="anoFicha"
											title="Ano da Ficha Cadastral"
											as={Input}
											error={hasError(formik.errors, 'anoFicha')}
										/>
									</Col>
									<Col span={8} />
								</Row>
								<Row
									justify={results ? 'end' : 'center'}
									align="top"
									gutter={[20, 10]}
								>
									{!results ? (
										<>
											<Col>
												<ButtonImage
													type="button"
													src="limpar"
													onClick={formik.handleReset}
												/>
											</Col>
											<Col>
												<ButtonImage type="submit" src="pesquisar" />
											</Col>
										</>
									) : (
										<Col>
											<ButtonImage
												onClick={() => setShowPrint(true)}
												src="imprimir"
											/>
										</Col>
									)}
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar />
			{impressaoOficioSegundaViaPlaca.data?.oficio && showPrint && (
				<PDFViewer
					title="Impressão Ofício Segunda Via Placa"
					source={impressaoOficioSegundaViaPlaca.data?.oficio}
					onClose={() => setShowPrint(false)}
				/>
			)}
		</Suspense>
	);
};

export default ImpressaoOficioSegundaViaPlaca;
