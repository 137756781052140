export enum Types {
	CONSULTA_GFOR_REQUEST = '@cnh/CONSULTA_GFOR_REQUEST',
	CONSULTA_GFOR_SUCCESS = '@cnh/CONSULTA_GFOR_SUCCESS',
	CONSULTA_GFOR_FAILURE = '@cnh/CONSULTA_GFOR_FAILURE',
	CONSULTA_GFOR_CLEAR = '@cnh/CONSULTA_GFOR_CLEAR',
}

export interface ConsultaGfor {
	status: number;
	data: ConsultaGforData | null;
	cpf: string | null;
}

export interface ConsultaGforData {
	cpf: string;
	renach: string;
	numeroRegistro: string;
	numeroPgu: string;
	nome: string;
	tipoRenach: string;
	nomePai: string;
	nomeMae: string;
	numeroRg: string;
	dataNascimento: string;
	rne: string;
	sexo: string;
	nacionalidade: string;
	codigoNaturalidade: string;
	descricaoNaturalidade: string;
	codigoCiretran: string;
	descricaoCiretran: string;
	atividadeRemunerada: string;
	deficienteFisico: string;
	categoriaPretendida: string;
	categoriaAtual: string;
	dataPrimeiraHabilitacao: string;
	obs: string;
	mensagem1: string;
	mensagem2: string;
	mensagem3: string;
	endereco: {
		codigo: string;
		logradouro: string;
		numero: string;
		complemento: string;
		cep: string;
		bairro: string;
		descricaoMunicipio: string;
		codigoMunicipio: string;
	};
	dadosExameMedico: {
		dataExameMedico: string;
		exameMedico: string;
		categoriaPermitida: string;
		restricaoMedica: string;
		aptidoInaptoTemporario: string;
		resultado: string;
		cred1: string;
		cred2: string;
		crm: string;
		cpf: string;
		regiao: string;
		aptidaoMedica: string;
		aptoAte: string;
		exame: string;
	};
	dadosExamePsicotecnico: {
		dataExamePsicotecnico: string;
		cpf: string;
		exame1: string;
		exame2: string;
		laudo1: string;
		laudo2: string;
		resultado1: string;
		resultado2: string;
		examePsicotecnico1: string;
		examePsicotecnico2: string;
		aptidoInaptoTemporario: string;
		aptidaoPsicotecnica: string;
		cred1: string;
		cred2: string;
		crp: string;
		regiao: string;
		aptoAte: string;
	};
	dadosRenach: {
		cpfAutor: string;
		dataCriacao: string;
		horaCriacao: string;
	};
	dadosInapto: {
		argumento: string;
		ciretran: string;
		cpf: string;
		data: string;
		nome: string;
		documentoMedico: string;
		nomeMedico: string;
		mensagem: string;
		crm: string;
		renach: string;
		linha1: string;
		linha2: string;
		linha3: string;
		linha4: string;
		linha5: string;
	};
}

export interface ConsultaGforRequest {
	opcao: string;
	cpf: string;
	numeroRegistro?: string;
	usuario: string;
	senha: string;
	enderecoIp?: string;
}
