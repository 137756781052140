import styled from 'styled-components';

export const Container = styled.div`
	margin: 20px 0 20px 0;
	span {
		font-weight: 500;
		font-size: 20px;
		color: red;
		padding-top: 30px;
		margin-left: 50px;
	}
	label {
		font-weight: 500;
		font-size: 18px;
		padding-top: 20px;
	}
`;

export const Content = styled.div`
	margin-bottom: 50px;
	ul {
		list-style-type: none;
		li {
			display: flex;
			align-items: center;
			margin-top: 10px;
			div {
				display: flex;
				width: 210px;
				span {
					font-size: 13px;
					margin-left: 60px;
					font-weight: 900;
				}
			}
			span {
				margin-left: 15px;
				font-weight: 400;
				font-size: 14px;
			}
		}
	}
`;
