import React, { useState, useCallback, useEffect, ChangeEvent } from 'react';

import { Field } from 'formik';

// COMPONENTS
import InputMask from 'components/Common/Form/Input/InputMask';

// SERVICES
import { getApi } from 'services/_sgu';
import { ApplicationState } from 'store';
import {
	paisClear,
	paisRequest,
} from 'store/modules/api/utils/endereco/pais/actions';

// UTILS
import { completaZeroEsquerda, limparMascara } from 'utils/genericFunctions';
import hasError from 'utils/getFormErrors';
import { CHANNEL } from 'services/_path';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import { useDispatch, useSelector } from 'react-redux';

interface IBuscarMunicipioResultado {
	codigoMunicipio: string;
	municipio: string;
}

interface Props {
	size?: number;
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	subtitle?: string;
	name: string;
	formik: any;
	codigoValue: string | number;
	required?: boolean;
	disabled?: boolean;
	reset?: boolean;
	onFocus?: () => void;
	setFocus?: () => void;
	result: (data: IBuscarMunicipioResultado) => void;
	type: string;
}

const CodigoMunicipio: React.FC<Props> = ({
	size = 47,
	title,
	titleSize,
	subtitle,
	name,
	formik,
	codigoValue,
	required = false,
	disabled = false,
	reset = false,
	onFocus,
	setFocus,
	result,
	type,
}) => {
	const { errors } = formik;
	const dispatch = useDispatch();

	const [selectedCodigo, setSelectedCodigo] = useState<string>('');
	const [resetCodigo, setResetCodigo] = useState<boolean>(reset);
	const { pais } = useSelector((state: ApplicationState) => state.api.utils);

	useEffect(() => {
		setSelectedCodigo(String(codigoValue));
	}, [codigoValue]);

	useEffect(() => {
		if (resetCodigo) {
			setSelectedCodigo('');
			setResetCodigo(false);
		}
	}, [reset, resetCodigo]);

	const buscarNaturalidade = useCallback(
		async (codMunicipio: string) => {
			const id = limparMascara(codMunicipio).slice(0, 5);

			const response: any = await getApi(
				`domain-service/${CHANNEL}/naturalidade`,
				{
					id,
				},
			);

			if (response.status === 200) {
				const naturalidade = response.data[0];

				if (
					(!naturalidade.municipioIBGE ||
						naturalidade.municipioIBGE.id === 0) &&
					type === 'Naturalidade' &&
					!(
						naturalidade.municipioIBGE.id === 99000 &&
						naturalidade.municipioIBGE.digito === 2
					) &&
					selectedCodigo !== '99000-2' &&
					selectedCodigo.slice(0, 5) !== '99000' &&
					formik.dni === '10977'
				) {
					addNotifications({
						errors: [
							'Naturalidade do cidadão Inválida - Município não registrado no IBGE. Consulte o Supervisor!',
						],
					});

					result({
						codigoMunicipio: '',
						municipio: '',
					});
					return;
				}
				if (naturalidade.descricao && type !== 'Naturalidade') {
					dispatch(paisRequest(naturalidade.descricao.toLocaleUpperCase()));
				}

				result({
					codigoMunicipio: `${completaZeroEsquerda(naturalidade.id, 5)}-${
						naturalidade.digito
					}`,
					municipio: naturalidade.descricao,
				});
			} else {
				addNotifications({
					errors: [
						'Serviço indisponível ou erro de conexão. Entre em contato com o suporte.',
					],
				});
				result({
					codigoMunicipio: '',
					municipio: '',
				});
			}
		},
		[dispatch, result, selectedCodigo, type, formik],
	);

	useEffect(() => {
		if (
			pais.status !== 0 &&
			pais.data &&
			(pais.data.length < 1 || pais.data[0].sigla === '' || !pais.data[0].sigla)
		) {
			addNotifications({
				errors: [
					'A emissão da CIN não poderá ser realizada neste momento no estado de São Paulo. Nacionalidade (País) do cidadão não possui código ICAO correspondente. Para prosseguir, selecione a opção RG Estadual no campo DOCUMENTO A EMITIR.',
				],
			});
		}
	}, [pais]);

	useEffect(() => {
		dispatch(paisClear());
	}, [dispatch]);

	const handleOnChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;

			dispatch(clearNotifications());

			setSelectedCodigo(value);
			if (limparMascara(value).length === 6 && setFocus) {
				setFocus();
			}
		},
		[setFocus, dispatch],
	);

	const handleOnFocus = useCallback(async () => {
		if (onFocus) onFocus();
	}, [onFocus]);

	const handleOnBlur = useCallback(async () => {
		if (limparMascara(selectedCodigo).length > 0) {
			buscarNaturalidade(selectedCodigo);
		} else {
			result({
				codigoMunicipio: '',
				municipio: '',
			});
		}
	}, [selectedCodigo, buscarNaturalidade, result]);

	return (
		<Field
			as={InputMask}
			title={title}
			titleSize={titleSize}
			subtitle={subtitle}
			name={name}
			mask="99999-9"
			value={selectedCodigo}
			onChange={handleOnChange}
			onFocus={handleOnFocus}
			onBlur={handleOnBlur}
			required={required}
			disabled={disabled}
			size={size}
			error={hasError(errors, name)}
		/>
	);
};

export default CodigoMunicipio;
