import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, emissaoNominal } from './types';

export const INITIAL_STATE: emissaoNominal = {
	status: 0,
	data: {
		atestadoEmitido: false,
		codigoRetorno: '',
		id: '',
		mensagemRetorno: '',
	},
	file: {
		mime: 'application/pdf',
		data: '',
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): emissaoNominal {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EMISSAO_NOMINAL_REQUEST: {
				break;
			}

			case Types.EMISSAO_NOMINAL_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				draft.file = action.payload.file;
				break;
			}

			case Types.EMISSAO_NOMINAL_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.file = INITIAL_STATE.file;
				break;
			}

			case Types.LIMPA_EMISSAO_NOMINAL: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.file = INITIAL_STATE.file;
				break;
			}

			default:
		}

		return draft;
	});
}
