import styled, { css } from 'styled-components';

interface ContentButtonProps {
	justify?: 'start' | 'center' | 'end';
}

export const Content = styled.div`
	display: flex;
	padding-left: 87px;
	margin-bottom: 10px;
`;

export const ContentButton = styled.div<ContentButtonProps>`
	display: flex;
	margin: 0 10px;

	${props =>
		props.justify === 'start' &&
		css`
			justify-content: flex-start;
			margin-left: 50px;
		`};
	${props =>
		props.justify === 'center' &&
		css`
			justify-content: center;
		`};
	${props =>
		props.justify === 'end' &&
		css`
			justify-content: flex-end;
		`};
	align-items: center;
	padding: 30px 0 30px 0;

	button {
		margin: 0 10px;
	}
`;
