import { ComboMunicipiosRequestData, Types } from './types';

export function comboMunicipiosRequest(
	token: string,
	payload: ComboMunicipiosRequestData,
) {
	return {
		type: Types.COMBO_MUNICIPIOS_REQUEST,
		payload,
		token,
	};
}

export function comboMunicipiosSuccess(payload: object) {
	return {
		type: Types.COMBO_MUNICIPIOS_SUCCESS,
		payload,
	};
}

export function comboMunicipiosFailure(payload: object) {
	return {
		type: Types.COMBO_MUNICIPIOS_FAILURE,
		payload,
	};
}
