import produce from 'immer';

// import { formatDate } from 'utils/genericFunctions';
import { Types, consultaPrimeiraHabilitacao } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: consultaPrimeiraHabilitacao = {
	status: 0,
	data: null,
	dataError: null,
	cpf: null,
};

export default function consultaPrimeiraHabilitacaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): consultaPrimeiraHabilitacao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PRIMEIRA_HABILITACAO_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.CONSULTA_PRIMEIRA_HABILITACAO_SUCCESS: {
				if (action.payload.response.status === 204) {
					draft.data = INITIAL_STATE.data;
					draft.dataError = INITIAL_STATE.dataError;
					draft.status = action.payload.response.status;
					draft.cpf = action.payload.request.cpf;
					return;
				}
				draft.dataError = INITIAL_STATE.dataError;
				draft.data = action.payload.response.data;
				draft.status = action.payload.response.status;
				draft.cpf = action.payload.request.cpf;
				break;
			}
			case Types.CONSULTA_PRIMEIRA_HABILITACAO_FAILURE: {
				draft.data = INITIAL_STATE.data;
				draft.dataError = action.payload.response.data;
				draft.status = action.payload.response.status;
				draft.cpf = action.payload.request.cpf;
				break;
			}

			case Types.CONSULTA_PRIMEIRA_HABILITACAO_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				draft.cpf = INITIAL_STATE.cpf;
				break;
			}
			default:
		}
	});
}
