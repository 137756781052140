import { all } from 'redux-saga/effects';

import parametrosSaga from './parametros/saga';
import servicosSaga from './servicos/saga';
import prefeiturasSaga from './prefeituras/saga';
import servicosPrefeiturasSaga from './servicosPrefeituras/saga';
import canais from './canais/combineSaga';
import prefeiturasServicosCanais from './prefeiturasServicosCanais/combineSaga';

export default all([
	parametrosSaga,
	servicosSaga,
	prefeiturasSaga,
	servicosPrefeiturasSaga,
	canais,
	prefeiturasServicosCanais,
]);
