import produce from 'immer';

// UTILS
import { formatDate, toSelect } from 'utils/genericFunctions';

// TYPES
import { Types, datasExamePsicologico } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: datasExamePsicologico = {
	status: 0,
	data: null,
	enumUnidades: {
		id_medico: null,
		unidades_disponiveis: [],
	},
};

export default function datasExamePsicologicoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): datasExamePsicologico {
	return produce(state, draft => {
		switch (action.type) {
			case Types.DATAS_EXAME_PSICOLOGICO_REQUEST: {
				break;
			}

			case Types.DATAS_EXAME_PSICOLOGICO_SUCCESS: {
				if (
					action.payload.data.datas_disponiveis &&
					action.payload.data.datas_disponiveis.length &&
					action.payload.data.id_psicologo !== undefined
				) {
					const datasDisponiveisData =
						action.payload.data.datas_disponiveis.map((item: string) => {
							return {
								label: formatDate(item.split('T')[0]),
								value: item,
							};
						});

					draft.data = {
						enum: datasDisponiveisData,
						idPsicologo: action.payload.data.id_psicologo,
					};
				}

				if (
					action.payload.data.unidades_disponiveis &&
					action.payload.data.unidades_disponiveis.length
				) {
					const lista = toSelect(
						action.payload.data.unidades_disponiveis,
						'nome_unidade',
						'codigo_ciretran',
					);

					draft.enumUnidades = {
						id_medico: action.payload.data.id_medico || null,
						unidades_disponiveis: lista,
					};
				}

				break;
			}

			case Types.DATAS_EXAME_PSICOLOGICO_ENUM_SUCCESS: {
				const lista = toSelect(
					action.payload.data.unidades_disponiveis,
					'nome_unidade',
					'codigo_ciretran',
				);

				draft.enumUnidades = {
					id_medico: action.payload.data.id_medico,
					unidades_disponiveis: lista,
				};
				break;
			}

			case Types.DATAS_EXAME_PSICOLOGICO_FAILURE: {
				break;
			}

			case Types.DATAS_EXAME_PSICOLOGICO_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				break;
			}

			default:
		}
	});
}
