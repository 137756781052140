import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_CIN } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { ConsultarProcessoRequest, Types } from './types';

// ACTIONS
import { consultarProcessoSuccess, consultarProcessoFailure } from './actions';

function* consultarProcessoRequest(request: ReducerActionRotaSP) {
	const {
		payload,
		token,
	}: { payload: ConsultarProcessoRequest; token?: string } = request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_CIN,
		`cin/processos?cpfCidadao=${payload.cpfCidadao}`,
	);

	if (response.status === 200) {
		yield put(consultarProcessoSuccess(response.data));
	} else {
		yield put(consultarProcessoFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_PROCESSO_REQUEST, consultarProcessoRequest),
]);
