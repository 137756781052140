import produce from 'immer';

// // UTILS
// import { formatDate } from 'utils/genericFunctions';

// TYPES
import { Types, LoginSoap } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: LoginSoap = {
	status: 0,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): LoginSoap {
	return produce(state, draft => {
		switch (action.type) {
			case Types.LOGIN_SOAP_REQUEST: {
				draft.status = 100;
				break;
			}

			case Types.LOGIN_SOAP_SUCCESS: {
				draft.status = 200;
				break;
			}

			case Types.LOGIN_SOAP_FAILURE: {
				draft.status = 400;
				break;
			}
			case Types.LOGIN_SOAP_CLEAR: {
				draft.status = 0;
				break;
			}

			default:
		}
	});
}
