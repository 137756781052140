import React from 'react';

import { Row, Col } from 'antd';

// ROUTES
import { Switch } from 'react-router-dom';
import Route from 'routes/Route';

// COMPONENTS
import Divider from 'components/Common/Divider';

// MENU ROUTES
import routes from './routes';

// AAC COMPONENTS
import NavMenu from './components/NavMenu';

const Aac: React.FC = () => {
	return (
		<>
			<Row>
				<Col span={24}>
					<NavMenu />

					<Divider mtSize={15} mbSize={15} />
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Switch>
						{routes.map(
							(
								{ path, breadcrumb, Component, isAuthenticated, exact },
								idx,
							) => {
								return (
									<Route
										key={String(idx)}
										path={path}
										breadcrumb={breadcrumb}
										component={Component}
										isAuthenticated={isAuthenticated}
										exact={exact}
									/>
								);
							},
						)}
					</Switch>
				</Col>
			</Row>
		</>
	);
};
export default Aac;
