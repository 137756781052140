import * as Yup from 'yup';

export interface IConsultaInscricao {
	numeroRA: string;
	digitoRA: string;
	siglaUF: string;
	anoLetivo: string;
}

export const initialValues: IConsultaInscricao = {
	numeroRA: '',
	digitoRA: '',
	siglaUF: 'AC',
	anoLetivo: '',
};

export interface IInscricaoDataRetorno {
	inscricoes: {
		codigoEscola: number;
		nomeEscola: string;
		codigoTipoEnsino: number;
		codigoSerieAno: number;
		tipo: number;
		descricaoTipo: string;
		data: string;
		codigoEscolaAlocacao: number;
		nomeEscolaAlocacao: string;
		numeroClasseAlocacao: string;
		dataAlocacao: string;
		redeEnsino: string;
		redeEnsinoEscolaAlocacao: string;
		municipioEscola: string;
		municipioEscolaAlocacao: string;
		situacao: string;
	};
}

export const initialValuesDataRetorno: IInscricaoDataRetorno = {
	inscricoes: {
		codigoEscola: 0,
		nomeEscola: '',
		codigoTipoEnsino: 0,
		codigoSerieAno: 0,
		tipo: 0,
		descricaoTipo: '',
		data: '',
		codigoEscolaAlocacao: 0,
		nomeEscolaAlocacao: '',
		numeroClasseAlocacao: '',
		dataAlocacao: '',
		redeEnsino: '',
		redeEnsinoEscolaAlocacao: '',
		municipioEscola: '',
		municipioEscolaAlocacao: '',
		situacao: '',
	},
};

export const schema = Yup.object<IConsultaInscricao>().shape({
	numeroRA: Yup.string().required('Necessário preencher o número do RA'),
	digitoRA: Yup.string().required('Necessário preencher o dígito do RA'),
	siglaUF: Yup.string().required('Necessário preencher a UF'),
	anoLetivo: Yup.string().required('Necessário preencher o ano letivo'),
});
