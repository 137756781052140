import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaRenovacaoRequest } from './types';

export function consultaRenovacaoCnhRequest(
	data: ConsultaRenovacaoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_RENOVACAO_CNH_REQUEST,
		payload: data,
	};
}
export function consultaRenovacaoCnhSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_RENOVACAO_CNH_SUCCESS,
		payload,
	};
}
export function consultaRenovacaoCnhFailure(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_RENOVACAO_CNH_FAILURE,
		payload,
	};
}
export function consultaRenovacaoCnhClear(): ReducerAction {
	return {
		type: Types.CONSULTA_RENOVACAO_CNH_CLEAR,
		payload: null,
	};
}
