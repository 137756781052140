import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_AAC } from 'services/_path';

// STORE
import { ReducerAction } from 'store/modules/types';

// ACTIONS
import { emissaoNumeralSuccess, emissaoNumeralFailure } from './actions';

// TYPES
import { Types, IRequestEmissaoNumeral } from './types';

function* emissaoNumeralRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestEmissaoNumeral } = request;

	const body = {
		codigoRetorno: payload.codigoRetorno,
		dataExpedicaoRg: payload.dataExpedicaoRg,
		dataNascimento: payload.dataNascimento,
		dc: payload.digitoRg,
		docProcurador: payload.docProcurador,
		ipEstacao: payload.ipEstacao,
		nome: payload.nome,
		nomeMae: payload.nomeMae,
		nomePai: payload.nomePai,
		nomeProcurador: payload.nomeProcurador,
		numeroRg: payload.numeroRg,
		posto: payload.posto,
		sexo: payload.sexo,
		tipoDocApresentado: payload.tipoDocApresentado,
		uf: 'SP',
		usuario: payload.usuario,
	};

	const response: ApiResponse = yield call(
		postApi,
		PATH_AAC,
		`aac/${CHANNEL}/emissaoAtestadoNumeral`,
		body,
	);

	if (response.status === 200) {
		yield put(emissaoNumeralSuccess(response.data));
	} else if (response.error) {
		yield put(emissaoNumeralFailure());
	}
}

export default all([
	takeLatest(Types.EMISSAO_NUMERAL_REQUEST, emissaoNumeralRequest),
]);
