import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaCategoriaRequest } from './types';

export function consultaCategoriaRequest(
	payload: ConsultaCategoriaRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_CATEGORIA_REQUEST,
		payload,
	};
}
export function consultaCategoriaSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTA_CATEGORIA_SUCCESS,
		payload,
	};
}
export function consultaCategoriaFailure(payload: boolean): ReducerAction {
	return {
		type: Types.CONSULTA_CATEGORIA_FAILURE,
		payload,
	};
}
