import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarDisponibilidadeMedicoPostoRequest } from './types';

// ACTIONS
import {
	consultarDisponibilidadeMedicoPostoSuccess,
	consultarDisponibilidadeMedicoPostoFailure,
} from './actions';

function* consultarDisponibilidadeMedicoPosto(request: ReducerAction) {
	const { payload }: { payload: ConsultarDisponibilidadeMedicoPostoRequest } =
		request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CNH,
		`posto-atendimento/${CHANNEL}/exame-medico/${payload.codigoPosto}`,
	);

	if (response.status === 200) {
		yield put(
			consultarDisponibilidadeMedicoPostoSuccess({ response, payload }),
		);
	} else {
		yield put(consultarDisponibilidadeMedicoPostoFailure(response));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_DISPONIBILIDADE_MEDICO_POSTO_REQUEST,
		consultarDisponibilidadeMedicoPosto,
	),
]);
