// ROUTES
import { ROUTE_SECRETARIA_EDUCACAO } from 'routes/paths';

// HOME
export const ROUTE_SECRETARIA_EDUCACAO_RECUPERAR_ATENDIMENTO = `${ROUTE_SECRETARIA_EDUCACAO}/recuperar-atendimento`;
export const ROUTE_SECRETARIA_EDUCACAO_JUSTIFICATIVA_ATENDIMENTO = `${ROUTE_SECRETARIA_EDUCACAO}/recuperar-atendimento/justificativa`;

// INSCRICAO
export const ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_PESQUISA_ALUNO = `${ROUTE_SECRETARIA_EDUCACAO}/inscricao/pesquisar-aluno`;
export const ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_FICHA_ALUNO = `${ROUTE_SECRETARIA_EDUCACAO}/inscricao/ficha-aluno`;
export const ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_INSCRICAO_ALUNO = `${ROUTE_SECRETARIA_EDUCACAO}/inscricao/inscricao-aluno`;

export const ROUTE_SECRETARIA_EDUCACAO_CANCELAMENTO_PESQUISAR_ALUNO = `${ROUTE_SECRETARIA_EDUCACAO}/cancelamento/pesquisar-aluno`;
export const ROUTE_SECRETARIA_EDUCACAO_CANCELAMENTO_FICHA_ALUNO = `${ROUTE_SECRETARIA_EDUCACAO}/cancelamento/ficha-aluno`;

export const ROUTE_SECRETARIA_EDUCACAO_CADASTRO_RESPONSAVEIS_PESQUISA = `${ROUTE_SECRETARIA_EDUCACAO}/cadastro-responsaveis/pesquisar-responsaveis`;
export const ROUTE_SECRETARIA_EDUCACAO_CADASTRO_RESPONSAVEL_FICHA = `${ROUTE_SECRETARIA_EDUCACAO}/cadastro-responsaveis/ficha-responsavel`;
