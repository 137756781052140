// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastroAutorizacaoEspecialRequest } from './types';

export function cadastroAutorizacaoEspecialRequest(
	payload: CadastroAutorizacaoEspecialRequest,
): ReducerAction {
	return {
		type: Types.CADASTRO_AUTORIZACAO_ESPECIAL_REQUEST,
		payload,
	};
}
export function cadastroAutorizacaoEspecialSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRO_AUTORIZACAO_ESPECIAL_SUCCESS,
		payload,
	};
}
export function cadastroAutorizacaoEspecialFailure(): ReducerAction {
	return {
		type: Types.CADASTRO_AUTORIZACAO_ESPECIAL_FAILURE,
		payload: null,
	};
}

export function cadastroAutorizacaoEspecialClear(): ReducerAction {
	return {
		type: Types.CADASTRO_AUTORIZACAO_ESPECIAL_CLEAR,
		payload: null,
	};
}
