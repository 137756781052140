export enum Types {
	CADASTRO_ENDERECOS_REQUEST = '@cnh/CADASTRO_ENDERECOS_REQUEST',
	CADASTRO_ENDERECOS_SUCCESS = '@cnh/CADASTRO_ENDERECOS_SUCCESS',
	CADASTRO_ENDERECOS_FAILURE = '@cnh/CADASTRO_ENDERECOS_FAILURE',
	CADASTRO_ENDERECOS_CLEAR = '@cnh/CADASTRO_ENDERECOS_CLEAR',
}

export interface CadastroEnderecos {
	status: number;
	data: CadastraEnderecoData | null;
}

export interface CadastraEnderecoData {
	cpf: string;
	codigo: string;
	mensagem: string;
}

export interface CadastroEnderecosRequest {
	cpf: string;
	enderecoRecebimento: {
		logradouro: string;
		numero: string;
		complemento: string;
		bairro: string;
		cep: string;
		codigoMunicipio: string;
		nomeMunicipio: string;
	};
}
