import {
	IRequestEmitirPrimeiroEmplacamento,
	IEvento,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/primeiroEmplacamentoEmissao/types';
import {
	completaZeroEsquerda,
	formatDateISO,
	limparMascara,
	separarDDD,
} from 'utils/genericFunctions';
import * as Yup from 'yup';

export interface IEmitirPrimeiroEmplacamento {
	anoFabricacao: number | string;
	anoModelo: number | string;
	capacidadeCarga: number | string;
	capacidadePassageiro: number | string;
	capacidadeVolTanquesSuplementar: number | string;
	chassi: string;
	chassiRemarcado: boolean;
	cilindrada: number | string;
	cmt: number | string;
	eixo: number | string;
	placa: string;
	pbt: number | string;
	potencia: number | string;
	procedencia: string;
	quantidadeTanquesSuplementar: number | string;
	renavam: number | string;
	longIdCarroceria: number | string;
	descricaoCarroceria: string;
	descricaoCategoria: string;
	longIdCategoria: number | string;
	longIdCombustivel: number | string;
	descricaoCombustivel: string;
	longIdCor: number | string;
	descricaoCor: string;
	longIdEspecie: number | string;
	descricaoEspecie: string;
	longIdMarca: number | string;
	descricaoMarca: string;
	codigoMunicipio: number | string;
	nomeMunicipio: string;
	longIdTipo: number | string;
	descricaoTipo: string;
	arrendatarioFinanceiraGravame: string;
	dataVigenciaGravame: string;
	financeiraGravame: number | string;
	numeroContratoGravame: string;
	restricaoFinanceiraGravame: string;
	nomeProprietario: string;
	cepProprietario: number | string;
	bairroProprietario: string;
	enderecoProprietario: string;
	numeroProprietario: number | string;
	complementoProprietario: string;
	telefoneProprietario: number | string;
	identificacaoProprietario: string;
	cpfCnpjProprietario: number | string;
	rgProprietario: string;
	orgaoExpeditorProprietario: string;
	ufProprietario: string;
	nomeProprietarioAnterior: string;
	codigoMunicipioProprietario: number | string;
	nomeMunicipioProprietario: string;
	numNotaFiscal: number | string;
	valorNotaFiscal: number | string;
	dataEmissaoNotaFiscal: string;
	autenticacaoDigitalDutTaxaPrimeiroEmplacamento: string;
	codBancoTaxaPrimeiroEmplacamento: string;
	dataContabilTaxaPrimeiroEmplacamento: string;
	nsuProdespTaxaPrimeiroEmplacamento: string;
	autenticacaoDigitalPlacaAtribuidaTaxaPlacaAtribuida: string;
	codBancoTaxaPlacaAtribuida: string;
	dataContabilTaxaPlacaAtribuida: string;
	nsuProdespTaxaPlacaAtribuida: string;
	// possuiTaxaPaga: boolean;
	documentos: any;
	servicoDigital: boolean;
	emailCidadao: string;
	dddTelefoneCidadao: string;
}

export const emitirPrimeiroEmplacamentoInitialValues: IEmitirPrimeiroEmplacamento =
	{
		anoFabricacao: '',
		anoModelo: '',
		capacidadeCarga: '',
		capacidadePassageiro: '',
		capacidadeVolTanquesSuplementar: '',
		chassi: '',
		chassiRemarcado: false,
		cilindrada: '',
		cmt: '',
		eixo: '',
		placa: '',
		pbt: '',
		potencia: '',
		procedencia: '',
		quantidadeTanquesSuplementar: '',
		renavam: '',
		longIdCarroceria: '',
		descricaoCarroceria: '',
		descricaoCategoria: '',
		longIdCategoria: '',
		longIdCombustivel: '',
		descricaoCombustivel: '',
		longIdCor: '',
		descricaoCor: '',
		longIdEspecie: '',
		descricaoEspecie: '',
		longIdMarca: '',
		descricaoMarca: '',
		codigoMunicipio: '',
		nomeMunicipio: '',
		longIdTipo: '',
		descricaoTipo: '',
		arrendatarioFinanceiraGravame: '',
		dataVigenciaGravame: '',
		financeiraGravame: '',
		numeroContratoGravame: '',
		restricaoFinanceiraGravame: '',
		nomeProprietario: '',
		cepProprietario: '',
		bairroProprietario: '',
		enderecoProprietario: '',
		numeroProprietario: '',
		complementoProprietario: '',
		telefoneProprietario: '',
		identificacaoProprietario: '',
		cpfCnpjProprietario: '',
		rgProprietario: '',
		orgaoExpeditorProprietario: '',
		ufProprietario: '',
		nomeProprietarioAnterior: '',
		codigoMunicipioProprietario: '',
		nomeMunicipioProprietario: '',
		numNotaFiscal: '',
		valorNotaFiscal: '',
		dataEmissaoNotaFiscal: '',
		autenticacaoDigitalDutTaxaPrimeiroEmplacamento: '',
		codBancoTaxaPrimeiroEmplacamento: '',
		dataContabilTaxaPrimeiroEmplacamento: '',
		nsuProdespTaxaPrimeiroEmplacamento: '',
		autenticacaoDigitalPlacaAtribuidaTaxaPlacaAtribuida: '',
		codBancoTaxaPlacaAtribuida: '',
		dataContabilTaxaPlacaAtribuida: '',
		nsuProdespTaxaPlacaAtribuida: '',
		// possuiTaxaPaga: false,
		documentos: [],
		servicoDigital: true,
		emailCidadao: '',
		dddTelefoneCidadao: '',
	};
export const schema = Yup.object<any>().shape({
	placa: Yup.string().required(`Campo Obrigatório: Placa.`),
	capacidadeCarga: Yup.string().required(
		`Campo Obrigatório: Capacidade de Carga.`,
	),
	chassi: Yup.string().required(`Campo Obrigatório: Chassi.`),
	cmt: Yup.string().required(`Campo Obrigatório: CMT.`),
	pbt: Yup.string().required(`Campo Obrigatório: PBT.`),
	procedencia: Yup.string().required(`Campo Obrigatório: Procedência.`),
	restricaoFinanceiraGravame: Yup.string().required(
		`Campo Obrigatório: Restrição financeira`,
	),
	longIdCarroceria: Yup.string().required(`Campo Obrigatório: Carroceria`),
	codigoMunicipio: Yup.string().required(
		`Campo Obrigatório: Município Veículo.`,
	),
	nomeProprietario: Yup.string().required(
		`Campo Obrigatório: Nome Proprietário.`,
	),
	cepProprietario: Yup.string().required(
		`Campo Obrigatório: CEP Proprietário.`,
	),
	bairroProprietario: Yup.string().required(
		`Campo Obrigatório: Bairro Proprietário.`,
	),
	enderecoProprietario: Yup.string().required(
		`Campo Obrigatório: Endereço Proprietário.`,
	),
	numeroProprietario: Yup.string().required(
		`Campo Obrigatório: Número endereço Proprietário.`,
	),
	identificacaoProprietario: Yup.string().required(
		`Campo Obrigatório: Identificação.`,
	),
	cpfCnpjProprietario: Yup.string().when('identificacaoProprietario', {
		is: identificacaoProprietario => identificacaoProprietario === 'FISICA',
		then: Yup.string().min(11, 'CPF/CNPJ do Proprietário.'),
		otherwise: Yup.string().min(14, 'CPF/CNPJ do Proprietário.'),
	}),
	nomeProprietarioAnterior: Yup.string().required(
		`Campo Obrigatório: Nome do Proprietário Anterior.`,
	),
	codigoMunicipioProprietario: Yup.string().required(
		`Campo Obrigatório: Município do endereço Proprietário.`,
	),
	// nomeMunicipioProprietario: '',
	rgProprietario: Yup.string().when('identificacaoProprietario', {
		is: identificacaoProprietario => identificacaoProprietario === 'FISICA',
		then: Yup.string().required('Campo Obrigatório: RG.'),
	}),
	orgaoExpeditorProprietario: Yup.string().when('identificacaoProprietario', {
		is: identificacaoProprietario => identificacaoProprietario === 'FISICA',
		then: Yup.string().required('Campo Obrigatório: Orgão Expeditor RG.'),
	}),
	ufProprietario: Yup.string().when('identificacaoProprietario', {
		is: identificacaoProprietario => identificacaoProprietario === 'FISICA',
		then: Yup.string().required('Campo Obrigatório: UF RG.'),
	}),
	numNotaFiscal: Yup.string().required(
		`Campo Obrigatório: Número da Nota Fiscal.`,
	),
	valorNotaFiscal: Yup.string().required(
		`Campo Obrigatório: Valor da Nota Fiscal.`,
	),
	dataEmissaoNotaFiscal: Yup.string().required(
		`Campo Obrigatório: Data da emissão da Nota Fiscal.`,
	),
	emailCidadao: Yup.string().when('servicoDigital', {
		is: servicoDigital => servicoDigital,
		then: Yup.string().required(`Campo Obrigatório: E-mail.`),
	}),
	dddTelefoneCidadao: Yup.string().when('servicoDigital', {
		is: servicoDigital => servicoDigital,
		then: Yup.string()
			.test(
				'len',
				'Insira o DDD e o número do telefone.',
				(val: any) => val.replace(/\D/g, '').length === 11,
			)
			.required(`Campo Obrigatório: Telefone de Contato.`),
	}),
	// documentos: Yup.array().when('servicoDigital', {
	// 	is: servicoDigital => servicoDigital,
	// 	then: Yup.array().min(
	// 		1,
	// 		'Para Serviço Digital é necessário anexar um arquivo.',
	// 	),
	// }),
	documentos: Yup.array().min(
		1,
		'Para Serviço Digital é necessário anexar um arquivo.',
	),
});

export function formatValues(
	formValues: IEmitirPrimeiroEmplacamento,
	evento: IEvento,
): IRequestEmitirPrimeiroEmplacamento {
	const {
		anoFabricacao,
		anoModelo,
		capacidadeCarga,
		capacidadePassageiro,
		chassi,
		chassiRemarcado,
		cilindrada,
		cmt,
		eixo,
		pbt,
		placa,
		potencia,
		procedencia,
		longIdCarroceria,
		descricaoCarroceria,
		longIdCategoria,
		descricaoCategoria,
		longIdCombustivel,
		descricaoCombustivel,
		longIdCor,
		descricaoCor,
		longIdEspecie,
		descricaoEspecie,
		longIdMarca,
		descricaoMarca,
		longIdTipo,
		descricaoTipo,
		nomeProprietario,
		cepProprietario,
		enderecoProprietario,
		numeroProprietario,
		complementoProprietario,
		bairroProprietario,
		telefoneProprietario,
		codigoMunicipioProprietario,
		nomeMunicipioProprietario,
		rgProprietario,
		orgaoExpeditorProprietario,
		ufProprietario,
		identificacaoProprietario,
		arrendatarioFinanceiraGravame,
		dataVigenciaGravame,
		financeiraGravame,
		numeroContratoGravame,
		restricaoFinanceiraGravame,
		cpfCnpjProprietario,
		nomeProprietarioAnterior,
		quantidadeTanquesSuplementar,
		capacidadeVolTanquesSuplementar,
		renavam,
		// autenticacaoDigitalPlacaAtribuidaTaxaPlacaAtribuida,
		codBancoTaxaPlacaAtribuida,
		dataContabilTaxaPlacaAtribuida,
		nsuProdespTaxaPlacaAtribuida,
		codBancoTaxaPrimeiroEmplacamento,
		dataContabilTaxaPrimeiroEmplacamento,
		nsuProdespTaxaPrimeiroEmplacamento,
		numNotaFiscal,
		valorNotaFiscal,
		dataEmissaoNotaFiscal,
		// autenticacaoDigitalDutTaxaPrimeiroEmplacamento,
		// possuiTaxaPaga,
		documentos,
		emailCidadao,
		dddTelefoneCidadao,
		servicoDigital,
	} = formValues;

	const { ddd, numTelefone } = separarDDD(dddTelefoneCidadao);

	const formattedEvento = {
		id_atendimento: evento.id_atendimento,
		id_cidadao: evento.id_cidadao,
		ip: evento.ip,
		identificacao: evento.identificacao,
		cpf: evento.cpf || '',
		canal: {
			id: 1,
			desc: 'Presencial',
			localidade: {
				desc: evento.canal?.localidade.desc || '',
				id: evento.canal?.localidade.id || 0,
			},
		},
	};

	const requestValues = {
		evento: formattedEvento,
		anoFabricacao: Number(anoFabricacao),
		anoModelo: Number(anoModelo),
		capacidadeCarga: capacidadeCarga ? Number(capacidadeCarga) : 0.0,
		capacidadePassageiro: Number(capacidadePassageiro),
		capacidadeVolTanquesSuplementar: Number(capacidadeVolTanquesSuplementar),
		chassi,
		chassiRemarcado,
		cilindrada: Number(cilindrada),
		cmt: Number(cmt),
		eixo: Number(eixo),
		placa,
		pbt: Number(pbt),
		potencia: Number(potencia),
		procedencia,
		quantidadeTanquesSuplementar: Number(quantidadeTanquesSuplementar),
		renavam: Number(renavam),
		carroceria: {
			longId: Number(longIdCarroceria),
			descricao: descricaoCarroceria,
		},
		categoria: {
			longId: Number(longIdCategoria),
			descricao: descricaoCategoria,
		},
		combustivel: {
			longId: Number(longIdCombustivel),
			descricao: descricaoCombustivel,
		},
		cor: {
			longId: Number(longIdCor),
			descricao: descricaoCor,
		},
		especie: {
			longId: Number(longIdEspecie),
			descricao: descricaoEspecie,
		},
		marca: {
			longId: Number(longIdMarca),
			descricao: descricaoMarca,
		},
		municipio: {
			codigo: Number(codigoMunicipioProprietario),
			nome: nomeMunicipioProprietario,
		},
		tipo: {
			longId: Number(longIdTipo),
			descricao: descricaoTipo,
		},
		gravame: {
			arrendatarioFinanceira: arrendatarioFinanceiraGravame,
			dataVigencia: formatDateISO(dataVigenciaGravame),
			financeira: Number(financeiraGravame),
			numeroContrato: numeroContratoGravame,
			restricaoFinanceira: restricaoFinanceiraGravame,
		},
		proprietario: {
			nomeProprietario,
			cepProprietario: Number(cepProprietario),
			bairro: bairroProprietario,
			enderecoProprietario,
			numero: Number(numeroProprietario),
			complemento: complementoProprietario,
			telefone: Number(telefoneProprietario),
			identificacao: identificacaoProprietario,
			cpfCnpjProprietario: Number(cpfCnpjProprietario),
			rgProprietario: Number(limparMascara(rgProprietario)),
			orgaoExpeditorProprietario,
			ufProprietario,
			nomeProprietarioAnterior,
			municipioProprietario: {
				codigo: Number(codigoMunicipioProprietario),
				nome: nomeMunicipioProprietario,
			},
		},
		numNotaFiscal: Number(numNotaFiscal),
		valorNotaFiscal: Number(valorNotaFiscal),
		dataEmissaoNotaFiscal: formatDateISO(dataEmissaoNotaFiscal),
		taxaPrimeiroEmplacamento: {
			// Como saber qual taxa foi paga?
			autenticacaoDigitalDut: '',
			codBanco: completaZeroEsquerda(codBancoTaxaPrimeiroEmplacamento, 3),
			dataContabil: formatDateISO(dataContabilTaxaPrimeiroEmplacamento),
			nsuProdesp: completaZeroEsquerda(nsuProdespTaxaPrimeiroEmplacamento, 9),
		},
		taxaPlacaAtribuida: {
			// Como saber qual taxa foi paga?
			autenticacaoDigitalPlacaAtribuida: '',
			codBanco: completaZeroEsquerda(codBancoTaxaPlacaAtribuida, 3),
			dataContabil: formatDateISO(dataContabilTaxaPlacaAtribuida),
			nsuProdesp: completaZeroEsquerda(nsuProdespTaxaPlacaAtribuida, 9),
		},
		documentos,
		emailCidadao,
		dddTelefoneCidadao: ddd !== '' ? Number(ddd) : 0,
		telefoneCidadao: numTelefone !== '' ? Number(numTelefone) : 0,
		servicoDigital,
	};

	// if (possuiTaxaPaga) {
	// 	requestValues.taxaPrimeiroEmplacamento.codBanco =
	// 		codBancoTaxaPlacaAtribuida;
	// 	requestValues.taxaPrimeiroEmplacamento.dataContabil = formatDateISO(
	// 		dataContabilTaxaPlacaAtribuida,
	// 	);
	// 	requestValues.taxaPrimeiroEmplacamento.nsuProdesp =
	// 		nsuProdespTaxaPlacaAtribuida;
	// } else {
	// 	requestValues.taxaPlacaAtribuida.codBanco = codBancoTaxaPlacaAtribuida;
	// 	requestValues.taxaPlacaAtribuida.dataContabil = formatDateISO(
	// 		dataContabilTaxaPlacaAtribuida,
	// 	);
	// 	requestValues.taxaPlacaAtribuida.nsuProdesp = nsuProdespTaxaPlacaAtribuida;
	// }

	return requestValues;
}
