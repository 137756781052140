import { ReducerAction } from 'store/modules/types';

import { GerarRenachRequest, Types } from './types';

export function gerarRenachRequest(payload: GerarRenachRequest): ReducerAction {
	return {
		type: Types.GERAR_RENACH_REQUEST,
		payload,
	};
}
export function gerarRenachSuccess(payload: object): ReducerAction {
	return {
		type: Types.GERAR_RENACH_SUCCESS,
		payload,
	};
}
export function gerarRenachFailure(payload: boolean): ReducerAction {
	return {
		type: Types.GERAR_RENACH_FAILURE,
		payload,
	};
}
