import { all } from 'redux-saga/effects';

import atualizarOrgaoSaga from './atualizarOrgao/saga';
// import buscarOrgaosSaga from './buscarOrgaos/saga';
import cadastrarOrgaoSaga from './cadastrarOrgao/saga';
import consultarOrgaoSaga from './consultarOrgao/saga';
import excluirOrgaoSaga from './excluirOrgao/saga';

export default all([
	atualizarOrgaoSaga,
	// buscarOrgaosSaga,
	cadastrarOrgaoSaga,
	consultarOrgaoSaga,
	excluirOrgaoSaga,
]);
