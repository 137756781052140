import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/_api';
import { PATH_CONTABILIZA } from 'services/_path';
import { ReducerAction } from 'store/modules/types';
import { Types, EventoInicioRequest } from './types';
import { eventoInicioSuccess, eventoInicioFailure } from './actions';

function* eventoInicioRequest(request: ReducerAction) {
	const { payload }: { payload: EventoInicioRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_CONTABILIZA,
		`evento/mp/inicio`,
		payload.dataPayload,
		undefined,
		true,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(
			eventoInicioSuccess({
				response,
				request: payload.detailsStartSection,
			}),
		);
	} else {
		yield put(
			eventoInicioFailure({
				response,
				request: payload.detailsStartSection,
			}),
		);
	}
}

export default all([
	takeLatest(Types.EVENTO_INICIO_REQUEST, eventoInicioRequest),
]);
