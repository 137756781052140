import { all } from 'redux-saga/effects';

import consultaPermissaoInternacionalAutomatizadoSaga from './consultaPermissaoInternacionalAutomatizado/saga';
import consultaEPortalAutomatizadoSaga from './consultaEPortalAutomatizado/saga';
import gerarRenachAutomatizadoSaga from './gerarRenachAutomatizado/saga';
import emissaoPidSaga from './emissaoPid/saga';

export default all([
	consultaPermissaoInternacionalAutomatizadoSaga,
	consultaEPortalAutomatizadoSaga,
	gerarRenachAutomatizadoSaga,
	emissaoPidSaga,
]);
