// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConsultarCpf, ConsultarCpfRequest } from './types';

export function consultarCpfRequest(
	payload: ConsultarCpfRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_CPF_REQUEST,
		payload,
	};
}
export function consultarCpfSuccess(payload: IConsultarCpf): ReducerAction {
	return {
		type: Types.CONSULTAR_CPF_SUCCESS,
		payload,
	};
}
export function consultarCpfFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_CPF_FAILURE,
		payload: null,
	};
}
export function consultarCpfClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_CPF_CLEAR,
		payload: null,
	};
}
