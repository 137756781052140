import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, RequestConsultaCEP } from './types';

// ACTIONS
import { consultaCEPSuccess, consultaCEPFailure } from './actions';

function* cep(request: ReducerAction) {
	const { payload }: { payload: RequestConsultaCEP } = request;

	const response: ApiResponse = yield call(
		getApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}?cep=${payload.cep}`,
	);

	if (response.status === 200) {
		yield put(consultaCEPSuccess(response));
	} else {
		yield put(consultaCEPFailure(response));
	}
}

export default all([takeLatest(Types.CONSULTA_CEP_REQUEST, cep)]);
