import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';

import { Formik, Form, Field } from 'formik';
import { Modal, Row, Col } from 'antd';

// REDUX
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import Radio from 'components/Common/Form/Radio';
import Select from 'components/Common/Form/Select';

import Button from 'components/Common/Button';
import Error from 'components/Common/Notifications/FormError';

// FORM
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { FormikFildObserver } from 'utils/formikObserver';
import getValidationsErrors, { IConfirmacaoAtendimento } from './form';

// STYLED
import {
	Item,
	Label,
	ImageBox,
	Maos,
	Assinatura,
	Foto,
	ButtonDiv,
} from './styled';

interface Props {
	showModal: boolean;
	response: (data: IConfirmacaoAtendimento) => void;
}

const ConfirmacaoAtendimento: React.FC<Props> = ({
	showModal = false,
	response,
}) => {
	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const [modalVisible, setModalVisible] = useState<boolean>(false);
	const [notificationErrors, setNotificationErrors] = useState<string[]>([]);
	const dispatch = useDispatch();

	// Manipula a visibilidade do Modal.
	useEffect(() => {
		setModalVisible(showModal);
	}, [showModal]);

	const handleValidate = useCallback(
		async values => {
			setNotificationErrors([]);

			const errors = await getValidationsErrors(values);

			if (typeof errors === 'boolean') {
				return errors;
			}

			const formikErrors: string[] = Object.keys(errors).map(value => {
				return errors[value];
			});

			setNotificationErrors(formikErrors);

			return errors;
		},
		[setNotificationErrors],
	);

	const handleFormChange = useCallback(() => {
		if (setNotificationErrors.length > 0) {
			setNotificationErrors([]);
			dispatch(clearNotifications());
		}
	}, [dispatch, setNotificationErrors]);

	return (
		<Modal
			centered
			visible={modalVisible}
			closable={false}
			footer={null}
			style={{ animationDuration: '0s' }}
		>
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={cadastroRg.perguntas}
				validate={handleValidate}
				onSubmit={async (formValues, { resetForm }) => {
					setNotificationErrors([]);
					response(formValues);
					resetForm();
				}}
			>
				{formik => (
					<>
						<Form autoComplete="off">
							{notificationErrors.length > 0 && (
								<Error
									errors={notificationErrors}
									onClose={() => {
										setNotificationErrors([]);
									}}
								/>
							)}

							<Item>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Label>O cidadão tem todos os dedos das mãos?</Label>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={24}>
										<ImageBox>
											<Maos />
										</ImageBox>
									</Col>
								</Row>

								<Row justify="center" gutter={[0, 10]}>
									<Col>
										<Field
											as={Radio}
											name="todosDedos"
											options={data.simNaoBoolean}
											defaultValue={cadastroRg.perguntas.todosDedos}
										/>
									</Col>
								</Row>
							</Item>

							<Item>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Label>É necessário coletar nova assinatura?</Label>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={24}>
										<ImageBox>
											<Assinatura />
										</ImageBox>
									</Col>
								</Row>

								<Row gutter={[0, 10]} justify="center">
									<Col>
										<Field
											as={Radio}
											name="coletarAssinatura"
											options={data.simNaoBoolean}
											defaultValue={cadastroRg.perguntas.coletarAssinatura}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												const { value } = event.target;

												formik.setFieldValue('coletarAssinatura', value);
												if (!value) {
													formik.setFieldValue('justificativaAssinatura', '');
												}
											}}
											disabled={formik.values.coletarFoto}
										/>
									</Col>
								</Row>

								{formik.values.coletarAssinatura && (
									<Row gutter={[0, 10]} justify="center">
										<Col span={12}>
											<Field
												as={Select}
												name="justificativaAssinatura"
												defaultValue={
													cadastroRg.perguntas.justificativaAssinatura ?? ''
												}
												options={data.justificativaMudancaAssinatura}
												onChange={(v: string) => {
													formik.setFieldValue('justificativaAssinatura', v);
												}}
												required
											/>
										</Col>
									</Row>
								)}
							</Item>

							<Item>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Label>É necessário coletar nova foto?</Label>
									</Col>
								</Row>

								<Row gutter={[0, 10]}>
									<Col span={24}>
										<ImageBox>
											<Foto />
										</ImageBox>
									</Col>
								</Row>

								<Row gutter={[0, 10]} justify="center">
									<Col>
										<Field
											as={Radio}
											name="coletarFoto"
											options={data.simNaoBoolean}
											defaultValue={cadastroRg.perguntas.coletarFoto}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												const { value } = event.target;

												formik.setFieldValue('coletarFoto', value);
												if (!value) {
													formik.setFieldValue('justificativaFoto', '');
												}
											}}
											disabled={formik.values.coletarAssinatura}
										/>
									</Col>
								</Row>

								{formik.values.coletarFoto && (
									<Row gutter={[0, 10]} justify="center">
										<Col span={12}>
											<Field
												as={Select}
												name="justificativaFoto"
												options={data.justificativaMudancaFisica}
												defaultValue={
													cadastroRg.perguntas.justificativaFoto ?? ''
												}
												onChange={(v: string) => {
													formik.setFieldValue('justificativaFoto', v);
												}}
												required
												// error={!!formik.errors.justificativaFoto}
											/>
										</Col>
									</Row>
								)}
							</Item>

							{(formik.values.justificativaAssinatura ||
								formik.values.justificativaFoto) && (
								<>
									<Row gutter={[0, 10]}>
										<Col span={24} />
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24}>
											<p>
												<strong>Justificativa:</strong>
											</p>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24}>
											{formik.values.justificativaAssinatura && (
												<p>{formik.values.justificativaAssinatura}</p>
											)}

											{formik.values.justificativaFoto && (
												<p>{formik.values.justificativaFoto}</p>
											)}
										</Col>
									</Row>
								</>
							)}

							<ButtonDiv>
								<Button type="submit">Finalizar</Button>
							</ButtonDiv>
						</Form>
						<FormikFildObserver
							dirty={formik.dirty}
							values={formik.values}
							onChange={handleFormChange}
						/>
					</>
				)}
			</Formik>
		</Modal>
	);
};
export default ConfirmacaoAtendimento;
