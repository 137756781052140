import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../types';
import { Types, PotencialState } from './types';

import disponibilzarDare from './disponibilzarDare/reducer';

const allReducers = combineReducers({
	disponibilzarDare,
});

const combineReducer = (state: PotencialState, action: ReducerAction) => {
	if (action.type === Types.POTENCIAL_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
