import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row, Modal } from 'antd';
import { Field, Formik, Form, FormikProps } from 'formik';

import { useHistory, useRouteMatch } from 'react-router-dom';

import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import Section from 'components/Common/Section';
import Upload from 'components/Common/Upload';
import getValidationsErrors from 'utils/getValidationsErrors';
import Alert from 'components/Common/Notifications/Alert';

import { ApplicationState } from 'store';

import { IRequestTypeDocumento } from 'store/modules/api/seds/vivaLeite/documentos/types';

// ACTIONS
import {
	cadastraDocumento,
	clearNotificationsDocumentosVivaLeite,
	consultaDocumentos,
	consultaStatusDocumentos,
} from 'store/modules/api/seds/vivaLeite/documentos/actions';
import { clearUsuarioVivaLeite } from 'store/modules/api/seds/vivaLeite/usuario/actions';

import hasError from 'utils/getFormErrors';
import SimpleTable from 'components/Common/Table';
import ButtonVoltar from 'components/Common/ButtonVoltar';

import {
	IEnvioConsultaDocumentos,
	InitialBuscaDocumentos,
	InitialBuscaStatusDocumentos,
	InitialValues,
	schema,
} from './form';
import Padrao from '../components/pages/Padrao';

const EnvioConsultaDocumentos = () => {
	const {
		selectListaDocumento,
		listaStatusDocumentos,
		statusTransacaoDocumentos,
	} = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.documentos,
	);
	const { selectedUser } = useSelector(
		(state: ApplicationState) => state.api.seds.vivaLeite.usuario,
	);

	const dispatch = useDispatch();
	const history = useHistory();
	const routeMatch = useRouteMatch();

	const [urlServico, setUrlServico] = useState<string>('');
	const [encerrar, setEncerrar] = useState(false);
	const [codigoDocumento, setCodigoDocumento] = useState(0);
	const [termoRequired, setTermoRequired] = useState(false);
	const [clear, setClear] = useState(false);

	const handleOnChange = useCallback(
		(value: number, formik: FormikProps<IEnvioConsultaDocumentos>) => {
			formik.setFieldValue('codigo_documento', value);

			if (value !== 7) {
				formik.setFieldValue('outro_documento', '');
			}
		},
		[],
	);

	const handleSubmit = useCallback(
		(values: IEnvioConsultaDocumentos, { resetForm }) => {
			const servico =
				urlServico === 'resultado'
					? 'ATUALIZACAO CADASTRAL'
					: 'PRESTACAO DE CONTAS';
			const requestData: IRequestTypeDocumento = {
				...InitialBuscaDocumentos,
				servico,
				num_pan: selectedUser.numeroPan,
				etapa: 'efetiva_envio_documento',
			};

			const updatedValues = { ...values };
			delete updatedValues.type;

			dispatch(cadastraDocumento({ ...updatedValues, ...requestData }));

			resetForm({ values: { ...InitialValues } });

			setCodigoDocumento(Number(values.codigo_documento));
		},
		[dispatch, selectedUser.numeroPan, urlServico],
	);

	const handleEncerrar = useCallback(() => {
		if (!encerrar) {
			setTermoRequired(true);
			return;
		}
		Modal.confirm({
			title: 'Deseja realizar um novo atendimento para o mesmo CPF?',
			okText: 'Sim',
			cancelText: 'Não',
			maskClosable: true,
			onOk() {
				history.push('/seds/vivaleite/identificarUsuario');
			},
			onCancel() {
				dispatch(clearUsuarioVivaLeite());
				history.push('/');
			},
		});
	}, [dispatch, encerrar, history]);

	const handleCleanStatus = useCallback(() => {
		if (statusTransacaoDocumentos !== '') {
			dispatch(clearNotificationsDocumentosVivaLeite());
		}
	}, [dispatch, statusTransacaoDocumentos]);

	const headers = [
		{
			title: 'Descrição',
			dataIndex: 'descricao_documento',
		},
		{
			title: 'Status',
			dataIndex: 'status',
		},
		{
			title: 'Motivo Indeferimento',
			dataIndex: 'motivo_indeferimento',
		},
		{
			title: 'Data Envio',
			dataIndex: 'data_envio',
			render: (data_envio: string) => {
				if (data_envio) {
					const parseToDate = new Date(data_envio.replace(/-/g, '/'));
					return parseToDate.toLocaleDateString('pt-BR');
				}
				return '';
			},
		},
	];

	useEffect(() => {
		const requestData = InitialBuscaDocumentos;
		const servico =
			urlServico === 'resultado'
				? 'ATUALIZACAO CADASTRAL'
				: 'PRESTACAO DE CONTAS';

		requestData.servico = servico;
		requestData.etapa = 'busca_lista_documentos';

		dispatch(consultaDocumentos(requestData));
	}, [dispatch, urlServico]);

	useEffect(() => {
		const servico =
			urlServico === 'resultado'
				? 'ATUALIZACAO CADASTRAL'
				: 'PRESTACAO DE CONTAS';

		const requestData = InitialBuscaStatusDocumentos;
		requestData.servico = servico;
		requestData.num_pan = selectedUser.numeroPan;

		dispatch(consultaStatusDocumentos(requestData));
	}, [dispatch, selectedUser.numeroPan, urlServico]);

	useEffect(() => {
		if (statusTransacaoDocumentos !== '') {
			const servico =
				urlServico === 'resultado'
					? 'ATUALIZACAO CADASTRAL'
					: 'PRESTACAO DE CONTAS';

			const requestData = InitialBuscaStatusDocumentos;
			requestData.servico = servico;
			requestData.num_pan = selectedUser.numeroPan;

			setClear(true);
			setTimeout(() => setClear(false), 1000);
			dispatch(consultaStatusDocumentos(requestData));
		}
	}, [dispatch, selectedUser.numeroPan, statusTransacaoDocumentos, urlServico]);

	useEffect(() => {
		const urlPath = routeMatch.path.split('/');

		setUrlServico(urlPath[urlPath.length - 2]);
	}, [routeMatch]);

	useEffect(() => {
		if (statusTransacaoDocumentos !== '' && codigoDocumento === 4) {
			setEncerrar(true);
		}
	}, [codigoDocumento, statusTransacaoDocumentos]);

	useEffect(() => {
		return function cleanup() {
			handleCleanStatus();
		};
	}, [handleCleanStatus]);

	return (
		<>
			{termoRequired && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Alert
							type="error"
							message="É obrigatório enviar o Termo de Responsabilidade"
							onClose={() => setTermoRequired(false)}
						/>
					</Col>
				</Row>
			)}
			<Padrao
				statusTransacao={statusTransacaoDocumentos}
				onCleanStatus={handleCleanStatus}
			>
				<>
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Section
								size="sm"
								titleBold={false}
								title="ENVIO E CONSULTA DE OFÍCIOS E DOCUMENTOS"
							>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<b>* Somente enviar arquivos no formato PDF</b>
									</Col>
								</Row>
								<Row gutter={[0, 10]}>
									<Col span={24}>
										<Formik
											validateOnChange={false}
											validateOnBlur={false}
											initialValues={InitialValues}
											validate={values => getValidationsErrors(values, schema)}
											onSubmit={handleSubmit}
										>
											{formik => {
												return (
													<Form>
														<Row gutter={[0, 10]}>
															<Col span={14}>
																<Field
																	title="Incluir Documento"
																	name="codigo_documento"
																	as={Select}
																	options={selectListaDocumento}
																	onChange={(v: number) =>
																		handleOnChange(v, formik)
																	}
																	titleSize="lg"
																	error={hasError(
																		formik.errors,
																		'codigo_documento',
																	)}
																	required
																/>
															</Col>
															<Col span={9} offset={1}>
																<Upload
																	name="documento"
																	formik={formik}
																	required
																	error={
																		hasError(formik.errors, 'documento') ||
																		hasError(formik.errors, 'type')
																	}
																	position="row"
																	clear={clear}
																	type="type"
																	accept=".pdf"
																/>
															</Col>
														</Row>
														<Row gutter={[0, 10]}>
															<Col span={14}>
																<Field
																	title="Descrição Documento"
																	name="outro_documento"
																	as={Input}
																	titleSize="lg"
																	disabled={
																		formik.values.codigo_documento !== 7
																	}
																	error={hasError(
																		formik.errors,
																		'outro_documento',
																	)}
																/>
															</Col>
															<Col offset={1}>
																<ButtonImage src="salvar" type="submit" />
															</Col>
														</Row>
													</Form>
												);
											}}
										</Formik>
									</Col>
								</Row>
								<Row gutter={[0, 10]} justify="end" align="bottom">
									<Col span={24}>
										<SimpleTable
											headers={headers}
											body={listaStatusDocumentos}
											striped
											pagination={false}
										/>
									</Col>
								</Row>
							</Section>
						</Col>
					</Row>

					<Row gutter={[0, 10]} justify="space-between" align="bottom">
						<Col>
							<ButtonVoltar />
						</Col>
						<Col>
							{urlServico !== 'resultado' && (
								<ButtonImage
									src="encerrar"
									type="button"
									onClick={handleEncerrar}
								/>
							)}
						</Col>
					</Row>
				</>
			</Padrao>
		</>
	);
};

export default EnvioConsultaDocumentos;
