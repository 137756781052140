import produce from 'immer';

import { Types, EventoConfrontoSessao } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: EventoConfrontoSessao = {
	status: 0,
	data: null,
};

export default function eventoConfrontoSessaoReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): EventoConfrontoSessao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EVENTO_CONFRONTO_SESSAO_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.EVENTO_CONFRONTO_SESSAO_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = null;
				break;
			}
			case Types.EVENTO_CONFRONTO_SESSAO_FAILURE: {
				draft.status = action.payload.data.status;
				draft.data = action.payload.data;
				break;
			}
			case Types.EVENTO_CONFRONTO_SESSAO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
	});
}
