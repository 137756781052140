import styled from 'styled-components';

// IMAGES
import icon_circulo_vermelho from 'assets/images/icons/icon_circulo_vermelho.gif';
import icon_circulo_amarelo from 'assets/images/icons/icon_circulo_amarelo.gif';

export const Title = styled.h5`
	align-content: center;
	display: flex;
	line-height: 24px;
`;

export const RedIcon = styled.span`
	background-image: url(${icon_circulo_vermelho});
	background-position: center;
	border-radius: 50%;
	display: inline-block;
	height: 24px;
	margin: 0 6px;
	width: 24px;
`;

export const YellowIcon = styled.span`
	background-image: url(${icon_circulo_amarelo});
	background-position: center;
	border-radius: 50%;
	display: inline-block;
	height: 24px;
	margin: 0 6px;
	width: 24px;
`;
