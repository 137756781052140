import { ReducerAction } from 'store/modules/types';

import { Types, RequestConsultaNomeSocialFiliacao } from './types';

export function consultaNomeSocialFiliacaoRequest(
	data: RequestConsultaNomeSocialFiliacao,
): ReducerAction {
	return {
		type: Types.CONSULTA_NOME_SOCIAL_FILIACAO_REQUEST,
		payload: data,
	};
}
export function consultaNomeSocialFiliacaoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_NOME_SOCIAL_FILIACAO_SUCCESS,
		payload,
	};
}
export function consultaNomeSocialFiliacaoFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.CONSULTA_NOME_SOCIAL_FILIACAO_FAILURE,
		payload,
	};
}
export function consultaNomeSocialFiliacaoClear(): ReducerAction {
	return {
		type: Types.CONSULTA_NOME_SOCIAL_FILIACAO_CLEAR,
		payload: null,
	};
}
