import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_PROCON } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { EnviaInteracoesAtendimentoRequest, Types } from './types';

// ACTIONS
import {
	enviaInteracoesAtendimentoSuccess,
	enviaInteracoesAtendimentoFailure,
} from './actions';

function* enviaInteracoesAtendimentoRequest(request: ReducerAction) {
	const { payload }: { payload: EnviaInteracoesAtendimentoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_PROCON,
		`interacoes/${CHANNEL}/atendimentos/interacoes`,
		payload,
	);

	if (response.status === 200) {
		yield put(enviaInteracoesAtendimentoSuccess(response));
	} else {
		yield put(enviaInteracoesAtendimentoFailure(response));
	}
}

export default all([
	takeLatest(
		Types.ENVIA_INTERACOES_ATENDIMENTO_REQUEST,
		enviaInteracoesAtendimentoRequest,
	),
]);
