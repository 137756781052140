import React from 'react';

// COMPONENTS
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';

// FORM
import { Field, FormikProps } from 'formik';
import { Col, Row } from 'antd';

// UTILS
import { onlyLetters, onlyNumbersAndLetters } from 'utils/genericFunctions';

interface BoxFormInputsNomeCpfDDDTelefoneEmailProps {
	formik: FormikProps<any>;
	showInputCpf?: boolean;
}

export const BoxFormInputsNomeCpfDDDTelefoneEmail = ({
	formik,
	showInputCpf,
}: BoxFormInputsNomeCpfDDDTelefoneEmailProps) => {
	return (
		<Row gutter={[0, 6]}>
			<Col span={24}>
				<Row>
					<Col span={18}>
						<Field
							as={Input}
							title="Nome"
							name="nome"
							titleAlign="start"
							titleSize="100"
							size={96}
							maxLength={60}
							required
							error={!!formik.errors.nome}
							onChange={(e: React.FormEvent<HTMLInputElement>) => {
								formik.setFieldValue(
									'nome',
									onlyLetters(e.currentTarget.value).toUpperCase() || '',
								);
							}}
						/>
					</Col>
				</Row>
				<Row>
					{showInputCpf && (
						<Col span={8}>
							<Field
								as={Input}
								title="CPF"
								name="cpf"
								titleAlign="start"
								titleSize="100"
								size={96}
								maxLength={11}
								required
								error={!!formik.errors.cpf}
								onChange={(e: React.FormEvent<HTMLInputElement>) => {
									formik.setFieldValue(
										'cpf',
										onlyNumbersAndLetters(
											e.currentTarget.value,
										).toUpperCase() || '',
									);
								}}
							/>
						</Col>
					)}
					<Col span={8} offset={showInputCpf ? 1 : 0}>
						<Field
							as={InputMask}
							title="Telefone"
							name="telefone"
							titleAlign="start"
							titleSize="100"
							size={96}
							required
							mask={
								formik?.values?.telefone[4] === '9'
									? '(99)99999-9999'
									: '(99)9999-9999'
							}
							error={!!formik.errors.telefone}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={18}>
						<Field
							as={Input}
							title="E-mail"
							name="email"
							titleAlign="start"
							titleSize="100"
							size={96}
							maxLength={50}
							required
							error={!!formik.errors.email}
							onChange={(e: React.FormEvent<HTMLInputElement>) => {
								formik.setFieldValue(
									'email',
									e.currentTarget.value.toUpperCase() || '',
								);
							}}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

BoxFormInputsNomeCpfDDDTelefoneEmail.defaultProps = {
	showInputCpf: false,
};
