import React, { useCallback } from 'react';

import { Col, Row } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ITaxaConsultarDare } from 'store/modules/api/dare/consultarDare/types';

// COMPONENTS
import SimpleTable from 'components/Common/Table';

// UTILS
import {
	formatDate,
	isEqualDates,
	limparMascara,
	patternFormat,
} from 'utils/genericFunctions';
import { formatCurrency } from 'utils/numberFunctions';

export const Resultado: React.FC = () => {
	const { consultarDareRetaguarda } = useSelector(
		(state: ApplicationState) => state.api.dare,
	);

	const handleRowClassName = useCallback((row: ITaxaConsultarDare) => {
		const date = new Date();

		if (
			isEqualDates(
				`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
				row.dataVinculoRg,
			)
		)
			return 'green';

		return '';
	}, []);

	const columns = [
		{
			dataIndex: 'numeroControleGuiaFilhote',
			title: 'Número',
			align: 'center',
			sorter: (recordA: ITaxaConsultarDare, recordB: ITaxaConsultarDare) => {
				const fieldA = Number(recordA.numeroControleGuiaFilhote);
				const fieldB = Number(recordB.numeroControleGuiaFilhote);

				return fieldA - fieldB;
			},
			render: (text: ITaxaConsultarDare, record: ITaxaConsultarDare) => {
				return record.numeroControleGuiaFilhote.substring(0, 15);
			},
		},
		{
			dataIndex: 'numeroControleGuiaFilhote',
			title: 'Filhote',
			align: 'center',
			sorter: (recordA: ITaxaConsultarDare, recordB: ITaxaConsultarDare) => {
				return recordB.numeroControleGuiaFilhote.localeCompare(
					recordA.numeroControleGuiaFilhote,
				);
			},
		},
		{
			dataIndex: 'identificador',
			title: 'RG',
			align: 'center',
			sorter: (recordA: ITaxaConsultarDare, recordB: ITaxaConsultarDare) => {
				const fieldA = Number(limparMascara(recordA.identificador));
				const fieldB = Number(limparMascara(recordB.identificador));

				return fieldA - fieldB;
			},
			render: (text: ITaxaConsultarDare, record: ITaxaConsultarDare) => {
				if (record.identificador) {
					return patternFormat(record.identificador, '##.###.###-#');
				}

				return '__.___.___-__';
			},
		},
		{
			dataIndex: 'data',
			title: 'Data',
			align: 'center',
			render: (text: ITaxaConsultarDare, record: ITaxaConsultarDare) => {
				if (record.status === 0) {
					return formatDate(record.dataVinculoRg);
				}

				return '__/__/____';
			},
		},
		{
			dataIndex: 'valorTotal',
			title: 'Valor',
			align: 'center',
			render: (text: ITaxaConsultarDare, record: ITaxaConsultarDare) => {
				return formatCurrency(record.valorTotal);
			},
		},
		{
			dataIndex: 'status',
			title: 'Situação',
			align: 'center',
			render: (text: ITaxaConsultarDare, record: ITaxaConsultarDare) => {
				if (record.status === 0) return 'EMITIDO';
				if (record.status === 1) return 'PAGO';
				if (record.status === 2) return 'PAGO';
				if (record.status === 3) return 'INUTILIZADA';
				if (record.status === 4) return 'REJEITADO PELO USUÁRIO';
				if (record.status === 5) return 'DADOS INCORRETOS AO GERAR DARE';
				if (record.status === 6) return 'PAGO';
				if (record.status === 8) return 'ERRO DE EMISSÃO';
				if (record.status === 9) return 'ERRO DE CONEXÃO COM A CHAMADA CILI';

				return '';
			},
		},
	];

	return (
		<>
			{consultarDareRetaguarda.taxasRes && (
				<>
					<Row gutter={[0, 10]}>
						<Col span={24} />
					</Row>

					<Row>
						<Col span={24}>
							<SimpleTable
								headers={columns}
								body={consultarDareRetaguarda.taxasRes}
								size="lg"
								striped
								bold
								hover={false}
								rowClassName={handleRowClassName}
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};
