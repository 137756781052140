import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_AAC } from 'services/_path';

// STORE
import { ReducerAction } from 'store/modules/types';

// ACTIONS
import {
	emissaoAtestadoLiberacaoPositivaSuccess,
	emissaoAtestadoLiberacaoPositivaFailure,
} from './actions';

// TYPES
import { RequestEmissaoAtestadoLiberacaoPositiva, Types } from './types';

function* emissaoAtestadoPainelRequest(request: ReducerAction) {
	const { payload }: { payload: RequestEmissaoAtestadoLiberacaoPositiva } =
		request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_AAC,
		`aac/${CHANNEL}/emissao-atestado-positiva`,
		payload,
	);

	if (response.status === 200) {
		yield put(emissaoAtestadoLiberacaoPositivaSuccess(response.data));
	} else if (response.error) {
		yield put(emissaoAtestadoLiberacaoPositivaFailure());
	}
}

export default all([
	takeLatest(
		Types.EMISSAO_ATESTADO_LIBERACAO_POSITIVA_REQUEST,
		emissaoAtestadoPainelRequest,
	),
]);
