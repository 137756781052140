import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ImprimirDareState } from './types';

export const INITIAL_STATE: ImprimirDareState = {
	status: 0,
	data: {
		arquivoPDF: '',
		mensagem: '',
		codigo: 0,
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ImprimirDareState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.IMPRIMIR_DARE_REQUEST: {
				break;
			}
			case Types.IMPRIMIR_DARE_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}
			case Types.IMPRIMIR_DARE_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.IMPRIMIR_DARE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
