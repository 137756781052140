import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, EnviarCentralNumeral } from './types';

export const INITIAL_STATE: EnviarCentralNumeral = {
	status: 0,
	data: {
		atestadoEmitido: '',
		codigoRetorno: '',
		mensagemRetorno: '',
		codigoValidacao: '',
		dataEmissaoAtestado: 0,
		mensagem: '',
	},
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): EnviarCentralNumeral {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ENVIAR_CENTRAL_NUMERAL_REQUEST: {
				break;
			}

			case Types.ENVIAR_CENTRAL_NUMERAL_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.ENVIAR_CENTRAL_NUMERAL_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.ENVIAR_CENTRAL_NUMERAL_CLEAN: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}

		return draft;
	});
}
