import { all } from 'redux-saga/effects';

import atualizarParametroSistemaSaga from './atualizarParametroSistema/saga';
import atualizarParametroSistemaRedisSaga from './atualizarParametroSistemaRedis/saga';
import cadastrarParametroSistemaSaga from './cadastrarParametroSistema/saga';
import cadastrarParametroSistemaRedisSaga from './cadastrarParametroSistemaRedis/saga';
import consultarParametrosSistemaSaga from './consultarParametrosSistema/saga';
import consultarParametrosSistemaRedisSaga from './consultarParametrosSistemaRedis/saga';
import excluirParametroSistemaSaga from './excluirParametroSistema/saga';
import excluirParametroSistemaRedisSaga from './excluirParametroSistemaRedis/saga';

export default all([
	atualizarParametroSistemaSaga,
	atualizarParametroSistemaRedisSaga,
	cadastrarParametroSistemaSaga,
	cadastrarParametroSistemaRedisSaga,
	consultarParametrosSistemaSaga,
	consultarParametrosSistemaRedisSaga,
	excluirParametroSistemaSaga,
	excluirParametroSistemaRedisSaga,
]);
