import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import { Field, FormikProps } from 'formik';
import { Row, Col } from 'antd';

// FORM
import hasError from 'utils/getFormErrors';

// COMPONENTS
import InputMask from '../../Input/InputMask';

// STYLED
import { Container, Numero, Digito, Divider } from './styled';
import { SubTitle } from '../../Input/styled';

interface Result {
	numeroRg: string;
	digitoRg: string;
}

interface Props {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | number;
	titleAlign?: 'start' | 'end' | number;
	subtitle?: string;
	numero: string;
	digito?: string;
	defaultNumero?: string;
	defaultDigito?: string;
	valueNumero?: string;
	valueDigito?: string;
	autoFocus?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	size?: number;
	reset?: boolean;
	formik: FormikProps<any>;
	error?: boolean | string;
	onFocus?: () => void;
	result: (res: Result) => void;
	isAcervo?: boolean;
	isSubtitleInLine?: boolean;
}

const RG: React.FC<Props> = ({
	title,
	titleSize,
	titleAlign,
	subtitle,
	numero,
	digito = '',
	defaultNumero = '',
	defaultDigito = '',
	autoFocus = false,
	required = false,
	disabled = false,
	readOnly = false,
	size = 100,
	reset = false,
	formik,
	error = false,
	onFocus,
	result,
	isAcervo = false,
	isSubtitleInLine = false,
}) => {
	const { errors, setFieldValue } = formik;
	const [selectedNumero, setSelectedNumero] = useState<string>(defaultNumero);
	const [selectedDigito, setSelectedDigito] = useState<string>(defaultDigito);

	useEffect(() => {
		if (defaultNumero) {
			setFieldValue(numero, defaultNumero);
			setSelectedNumero(defaultNumero);
			return;
		}

		setFieldValue(numero, '');
		setSelectedNumero('');
	}, [defaultNumero, numero, setFieldValue]);

	useEffect(() => {
		if (defaultDigito) {
			setFieldValue(digito, defaultDigito);
			setSelectedDigito(defaultDigito);
			return;
		}

		setFieldValue(digito, '');
		setSelectedDigito('');
	}, [defaultDigito, digito, setFieldValue]);

	useEffect(() => {
		if (reset) {
			setSelectedNumero('');
			setSelectedDigito('');
			setFieldValue(numero, '');

			if (digito) setSelectedDigito('');
		}
	}, [reset, digito, setFieldValue, numero]);

	const onResult = useCallback(
		(numRG: string, digRG: string) => {
			result({ numeroRg: numRG, digitoRg: digRG });
		},
		[result],
	);

	const handleOnFocus = useCallback(() => {
		if (onFocus) onFocus();
	}, [onFocus]);

	const handleOnChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;
			const numeroRG: any = value.replace(/[_.-]+/g, '');

			const pesos = [9, 8, 7, 6, 5, 4, 3, 2];

			setSelectedNumero(numeroRG);

			if (numeroRG.length === 8) {
				const newValue = [...numeroRG];

				const soma = newValue.reduce((total, amount, index) => {
					return total + parseInt(amount, 10) * pesos[index];
				}, 0);

				const resto = soma % 11;

				if (resto < 10) {
					setSelectedNumero(`${numeroRG}-${resto.toString()}`);
					onResult(`${numeroRG}-${resto.toString()}`, '');
				} else {
					setSelectedNumero(`${numeroRG}-X`);
					onResult(`${numeroRG}-X`, '');
				}
			} else if (numeroRG.length === 9) {
				onResult(`${numeroRG.substring(0, 8)}-${numeroRG.substring(8, 9)}`, '');
			} else if (numeroRG) {
				onResult(`${numeroRG}-`, '');
			} else {
				onResult('', '');
			}
		},
		[setSelectedNumero, onResult],
	);

	const handleOnChangeNumero = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;
			const numeroRG: any = value.replace(/[_.-]+/g, '');

			const pesos = [9, 8, 7, 6, 5, 4, 3, 2];

			setSelectedNumero(numeroRG);

			if (numeroRG.length === 8) {
				const newValue = [...numeroRG];

				const soma = newValue.reduce((total, amount, index) => {
					return total + parseInt(amount, 10) * pesos[index];
				}, 0);

				const resto = soma % 11;

				if (resto < 10) {
					setSelectedDigito(resto.toString());
					onResult(numeroRG, resto.toString());
				} else {
					setSelectedDigito('X');
					onResult(numeroRG, 'X');
				}
			} else {
				setSelectedDigito('');
				onResult(numeroRG, '');
			}
		},
		[setSelectedNumero, setSelectedDigito, onResult],
	);

	const handleOnChangeDigito = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;

			setSelectedDigito(value);
			onResult(selectedNumero, value);
		},
		[setSelectedDigito, onResult, selectedNumero],
	);

	return (
		<>
			{readOnly ? (
				<Row>
					<Col span={24}>
						<>
							{digito ? (
								<Field
									as={InputMask}
									title={title}
									titleSize={titleSize}
									name={numero}
									mask={isAcervo ? '99.999.999-*' : '99.999.999-**'}
									maskChar=""
									value={`${selectedNumero}-${selectedDigito}`}
									required={required}
									titleAlign={titleAlign}
									readOnly
								/>
							) : (
								<Field
									as={InputMask}
									title={title}
									titleSize={titleSize}
									name={numero}
									mask={isAcervo ? '99.999.999-*' : '99.999.999-**'}
									maskChar=""
									value={selectedNumero}
									required={required}
									titleAlign={titleAlign}
									readOnly
									size={size}
								/>
							)}
						</>
					</Col>
				</Row>
			) : (
				<Row>
					<Col
						span={
							(isSubtitleInLine && subtitle && 15) ||
							(subtitle && !isSubtitleInLine && 10) ||
							24
						}
					>
						<Container>
							{digito ? (
								<>
									<Numero hasDigito>
										<Field
											id="rg"
											as={InputMask}
											title={title}
											titleSize={titleSize}
											name={numero}
											mask="99.999.999"
											value={selectedNumero}
											autoFocus={autoFocus}
											required={required}
											disabled={disabled}
											onChange={handleOnChangeNumero}
											onFocus={handleOnFocus}
											titleAlign={titleAlign}
											error={
												hasError(errors, numero) ||
												hasError(errors, digito) ||
												error
											}
										/>
									</Numero>
									<Divider />
									<Digito>
										<Field
											as={InputMask}
											name={digito}
											mask="**"
											maskChar=""
											value={selectedDigito}
											disabled={disabled}
											onChange={handleOnChangeDigito}
											titleAlign={titleAlign}
											error={
												hasError(errors, digito) ||
												hasError(errors, numero) ||
												error
											}
										/>
									</Digito>
								</>
							) : (
								<Numero hasDigito={false}>
									<Field
										as={InputMask}
										title={title}
										titleSize={titleSize}
										name={numero}
										mask={isAcervo ? '99.999.999-*' : '99.999.999-**'}
										value={selectedNumero}
										autoFocus={autoFocus}
										required={required}
										disabled={disabled}
										onChange={handleOnChange}
										onFocus={handleOnFocus}
										size={size}
										titleAlign={titleAlign}
										error={hasError(errors, numero) || error}
									/>
								</Numero>
							)}
						</Container>
					</Col>

					{subtitle && !isSubtitleInLine && (
						<Col span={9}>
							<div style={{ display: 'flex', height: '100%' }}>
								<SubTitle>
									<span>{subtitle}</span>
								</SubTitle>
							</div>
						</Col>
					)}

					{subtitle && isSubtitleInLine && (
						<Col span={21} offset={3}>
							<div style={{ display: 'flex', height: '100%' }}>
								<SubTitle>
									<span>{subtitle}</span>
								</SubTitle>
							</div>
						</Col>
					)}
				</Row>
			)}
		</>
	);
};

export default RG;
