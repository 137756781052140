import { EventoInicioRequest, Types } from './types';

export function eventoInicioRequest(payload: EventoInicioRequest) {
	return {
		type: Types.EVENTO_INICIO_REQUEST,
		payload,
	};
}
export function eventoInicioSuccess(payload: object) {
	return {
		type: Types.EVENTO_INICIO_SUCCESS,
		payload,
	};
}
export function eventoInicioFailure(payload: object) {
	return {
		type: Types.EVENTO_INICIO_FAILURE,
		payload,
	};
}
export function eventoInicioClear(): any {
	return {
		type: Types.EVENTO_INICIO_CLEAR,
	};
}
