import { ReducerAction } from 'store/modules/types';

import { Types, ConsultaPermissionarioPontuadoRequest } from './types';

export function consultaPermissionarioPontuadoRequest(
	data: ConsultaPermissionarioPontuadoRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_PERMISSIONARIO_PONTUADO_REQUEST,
		payload: data,
	};
}
export function consultaPermissionarioPontuadoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_PERMISSIONARIO_PONTUADO_SUCCESS,
		payload,
	};
}
export function consultaPermissionarioPontuadoFailure(payload: object): any {
	return {
		type: Types.CONSULTA_PERMISSIONARIO_PONTUADO_FAILURE,
		payload,
	};
}

export function consultaPermissionarioPontuadoClear(): any {
	return {
		type: Types.CONSULTA_PERMISSIONARIO_PONTUADO_CLEAR,
	};
}
