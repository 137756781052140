import { ReducerAction } from 'store/modules/types';

import { Types, IRequestConsultaMudancaCategoria } from './types';

export function consultaMudancaCategoria(
	data: IRequestConsultaMudancaCategoria,
): ReducerAction {
	return {
		type: Types.CONSULTA_MUDANCA_CATEGORIA,
		payload: data,
	};
}
export function consultaMudancaCategoriaSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTA_MUDANCA_CATEGORIA_SUCCESS,
		payload,
	};
}
export function consultaMudancaCategoriaFailure(
	payload: boolean,
): ReducerAction {
	return {
		type: Types.CONSULTA_MUDANCA_CATEGORIA_FAILURE,
		payload,
	};
}
