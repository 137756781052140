export enum Types {
	CADASTRAR_INSCRICAO_REQUEST = '@EDUCACAO/CADASTRAR_INSCRICAO_REQUEST',
	CADASTRAR_INSCRICAO_SUCCESS = '@EDUCACAO/CADASTRAR_INSCRICAO_SUCCESS',
	CADASTRAR_INSCRICAO_FAILURE = '@EDUCACAO/CADASTRAR_INSCRICAO_FAILURE',
	CADASTRAR_INSCRICAO_CLEAR = '@EDUCACAO/CADASTRAR_INSCRICAO_CLEAR',
}

export interface CadastrarInscricao {
	status: number;
	data: null | CadastrarInscricaoData;
}

export interface CadastrarInscricaoData {
	processoId: string;
	mensagem: string;
}

interface Aluno {
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
}

export interface Inscricao {
	anoLetivo: string;
	codigoEscola: string;
	codigoUnidade: string;
	fase: string;
	interesseIntegral: boolean;
	interesseEspanhol: boolean;
	atendimentoNoturno: boolean;
	codigoObservacaoOpcaoNoturno: string;
	codigoEscolaNovotecOpcao1: string;
	codigoUnidadeNovotecOpcao1: string;
	interesseCentroIdiomas: boolean;
	aceiteEscolaPEIDistante: boolean;
	codigoEixoCELOpcao1: string;
	codigoEscolaCELOpcao1: string;
	codigoUnidadeCELOpcao1: string;
	interesseNovoTec: boolean;
	codigoEixoNovotecOpcao1: string;
	codigoEixoNovotecOpcao2: string;
	codigoEixoNovotecOpcao3: string;
}

interface NivelEnsino {
	codigoTipoEnsino: string;
	codigoSerieAno: string;
}

export interface CadastrarInscricaoRequest {
	aluno: Aluno;
	inscricao: Inscricao;
	nivelEnsino: NivelEnsino;
}
