import React from 'react';
import { Empty } from 'antd';
import { Container } from './styled';

interface IProps {
	image?: 'PRESENTED_IMAGE_SIMPLE' | 'PRESENTED_IMAGE_DEFAULT';
	description: string;
}

const EmptyData: React.FC<IProps> = ({
	image = 'PRESENTED_IMAGE_DEFAULT',
	description,
}) => {
	return (
		<Container>
			<Empty image={Empty[image]} description={description} />
		</Container>
	);
};

export default EmptyData;
