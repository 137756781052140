import React from 'react';
import packageJson from '../../../package.json';

// STYLED
import { Container, Content, Version } from './styled';

const Footer: React.FC = () => {
	const version = '1.3.10';

	return (
		<Container className="no-print">
			<Content>&reg; Balcão Único - 4ª Geração - Versão: {version}</Content>
			<Version>{packageJson.buildDate.toString()}</Version>
		</Container>
	);
};

export default Footer;
