import produce from 'immer';

// UTILS
import { toSelect } from 'utils/genericFunctions';

// TYPES
import { Types, RegiaoMicroRegiaoPorCep } from './types';
import { ReducerAction } from '../../../../types';

export const INITIAL_STATE: RegiaoMicroRegiaoPorCep = {
	status: 0,
	data: {
		regiao: [],
		micro_regiao: [],
	},
};

export default function regiaoMicroRegiaoPorCepReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): RegiaoMicroRegiaoPorCep {
	return produce(state, draft => {
		switch (action.type) {
			case Types.REGIAO_MICRO_REGIAO_POR_CEP: {
				break;
			}

			case Types.REGIAO_MICRO_REGIAO_POR_CEP_SUCCESS: {
				const regioesData = toSelect(
					[action.payload.data.regiao],
					'nome',
					'long_id',
				);
				const microRegioesData = toSelect(
					[action.payload.data.micro_regiao],
					'nome',
					'long_id',
				);

				draft.data = {
					regiao: regioesData,
					micro_regiao: microRegioesData,
				};
				draft.status = action.payload.status;

				break;
			}

			case Types.REGIAO_MICRO_REGIAO_POR_CEP_FAILURE: {
				draft.status = 400;
				draft.data = action.payload;
				break;
			}

			case Types.REGIAO_MICRO_REGIAO_POR_CEP_CLEAR: {
				draft.data = INITIAL_STATE.data;
				draft.status = INITIAL_STATE.status;
				break;
			}

			default:
		}
	});
}
