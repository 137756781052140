import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

import { Col, Row, Skeleton } from 'antd';

// COMPONENTS
import Service from './Service';

interface ServiceProps {
	name: string;
	path: string;
	descricaoServico?: string;
	recuperaAtendimento?: boolean;
	dadosAgendamentoIIRGD?: boolean;
}

interface ServiceCombo {
	title: string;
	services: ServiceProps[];
}

interface Props {
	data: ServiceProps[] | ServiceCombo[];
}

const ServiceList: React.FC<Props> = ({ data }) => {
	const [services, setServices] = useState<ServiceProps[] | null>(null);
	const [servicesCombo, setServicesCombo] = useState<ServiceCombo[] | null>(
		null,
	);
	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);
	const handleTypeOfService = useCallback(
		(array: any): array is ServiceProps => {
			return 'name' in array;
		},
		[],
	);

	const handleTypeOfServiceCombo = useCallback(
		(array: any): array is ServiceCombo => {
			return 'title' in array;
		},
		[],
	);

	useEffect(() => {
		if (handleTypeOfService(data[0])) {
			setServices(data as ServiceProps[]);
			return;
		}

		if (handleTypeOfServiceCombo(data[0])) {
			setServicesCombo(data as ServiceCombo[]);
		}
	}, [handleTypeOfService, handleTypeOfServiceCombo, data]);

	return (
		<>
			<Suspense fallback={<Skeleton paragraph={{ rows: data.length }} />}>
				{services && (
					<Row>
						<Col span={24}>
							{services.map((serviceItem, serviceIndex) => {
								if (!user.perfil.isTemAcessoSGU && serviceItem.name === 'SGU') {
									return null;
								}
								return (
									<Service
										key={String(serviceIndex)}
										id={serviceIndex.toString()}
										name={serviceItem.name}
										path={serviceItem.path}
										descricaoServico={serviceItem.descricaoServico}
										recuperaAtendimento={serviceItem.recuperaAtendimento}
										dadosAgendamentoIIRGD={serviceItem.dadosAgendamentoIIRGD}
									/>
								);
							})}
						</Col>
					</Row>
				)}

				{servicesCombo &&
					servicesCombo.map((comboItem, comboIndex) => (
						<Row
							gutter={
								comboIndex + 1 !== servicesCombo.length ? [0, 20] : undefined
							}
							key={String(comboIndex)}
						>
							<Col span={24}>
								<Row gutter={[0, 5]}>
									<Col span={24}>
										<h3>{comboItem.title}</h3>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										{comboItem.services.map((serviceItem, serviceIndex) => (
											<Service
												key={String(serviceIndex)}
												id={serviceIndex.toString()}
												name={serviceItem.name}
												path={serviceItem.path}
												descricaoServico={serviceItem.descricaoServico}
												recuperaAtendimento={serviceItem.recuperaAtendimento}
											/>
										))}
									</Col>
								</Row>
							</Col>
						</Row>
					))}
			</Suspense>
		</>
	);
};

export default ServiceList;
