import { takeLatest, call, put, all } from 'redux-saga/effects';

// GENERICS
import { cleanEmpty } from 'utils/genericFunctions';

// SERVICES
import { ApiResponse, getApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarMensagensErroRequest } from './types';

// ACTIONS
import {
	consultarMensagensErroSuccess,
	consultarMensagensErroFailure,
} from './actions';

function* consultarMensagensErro(request: ReducerAction) {
	const { payload }: { payload: ConsultarMensagensErroRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarMensagensErroRequest;

	const response: ApiResponse = yield call(
		getApi,
		PATH_SGU,
		`mensagens-erro`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarMensagensErroSuccess(response));
	} else {
		yield put(consultarMensagensErroFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_MENSAGENS_ERRO_REQUEST, consultarMensagensErro),
]);
