import * as Yup from 'yup';

export interface IFormJalesTransito {
	placa: string;
	renavan: string;
}

export const InitialValues: IFormJalesTransito = {
	placa: '',
	renavan: '',
};

export const schema = Yup.object<IFormJalesTransito>().shape({
	placa: Yup.string().required('Campo Obrigatório: Placa.'),
	renavan: Yup.string().required('Campo Obrigatório: Renavam.'),
});
