// TYPES
import { ReducerAction } from 'store/modules/types';
import { EnviaInteracoesAtendimentoRequest, Types } from './types';

export function enviaInteracoesAtendimentoRequest(
	payload: EnviaInteracoesAtendimentoRequest,
): ReducerAction {
	return {
		type: Types.ENVIA_INTERACOES_ATENDIMENTO_REQUEST,
		payload,
	};
}
export function enviaInteracoesAtendimentoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.ENVIA_INTERACOES_ATENDIMENTO_SUCCESS,
		payload,
	};
}
export function enviaInteracoesAtendimentoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.ENVIA_INTERACOES_ATENDIMENTO_FAILURE,
		payload,
	};
}
export function enviaInteracoesAtendimentoClear(): ReducerAction {
	return {
		type: Types.ENVIA_INTERACOES_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
