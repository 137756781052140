import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { CHANNEL, PATH_DETRAN_CNH } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaMudancaCategoria } from './types';

// ACTIONS
import {
	consultaMudancaCategoriaSuccess,
	consultaMudancaCategoriaFailure,
} from './actions';

function* consultaMudancaCategoria(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaMudancaCategoria } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CNH,
		`consulta/${CHANNEL}/consultaMudancaCategoria`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultaMudancaCategoriaSuccess(response));
	} else if (response.error) {
		yield put(consultaMudancaCategoriaFailure(response.error));
	}
}

export default all([
	takeLatest(Types.CONSULTA_MUDANCA_CATEGORIA, consultaMudancaCategoria),
]);
