import { caracteristicas } from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV120/types';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';
import { validaCpfCnpj } from 'utils/genericFunctions';
import * as Yup from 'yup';

export interface IPrimeiroEmplacamento {
	chassi: string;
	cpfCnpjProprietario: string;
	restricaoFinanceira: string;
	categoria?: number | string;
}

export const initialValues: IPrimeiroEmplacamento = {
	chassi: '',
	cpfCnpjProprietario: '',
	restricaoFinanceira: 'NADA_CONSTA',
	categoria: '',
};

export const schema = Yup.object<IPrimeiroEmplacamento>().shape({
	chassi: Yup.string().required(`Campo Obrigatório: Chassi.`),
	cpfCnpjProprietario: Yup.string()
		.required(`Campo Obrigatório: CPF ou CNPJ do Proprietário.`)
		.test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF/CNPJ inválido.',
			test: value => validaCpfCnpj(value),
		}),
	categoria: Yup.string().required(`Campo Obrigatório: Categoria.`),
	documentoCategoria: Yup.string().when(['cpfCnpjProprietario', 'categoria'], {
		is: (cpfCnpjProprietario, categoria) =>
			cpfCnpjProprietario.length <= 11 && categoria.toString() === '3',
		then: Yup.string().required(
			'Categoria oficial somente permitido para pessoa jurídica (CNPJ).',
		),
	}),
	restricaoFinanceira: Yup.string().required(
		`Campo Obrigatório: Tipo de Restrição.`,
	),
});

export const treatRequestSguStatistics = (
	user: IUser,
	formData: IPrimeiroEmplacamento,
	data: caracteristicas | null,
): IncluirRequest => {
	const payload: IncluirRequest = {
		observacao: `Chassi: ${
			formData?.chassi || data?.caracteristicaVeiculo.chassi || 'N/A'
		}, Placa: ${
			data?.caracteristicaVeiculo.placa || 'N/A'
		}, Tipo de Processo: ${'N/A'}, Renavam Gerada ${'N/A'}`,
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento: formData?.cpfCnpjProprietario || '',
		codigoLocalidade: (user?.posto && Number(user.posto)) || 0,
		idOrgao: 5,
		cpf: user.cpf,
		descricaoServico: 'Primeiro Emplacamento',
		idMotivo: 16,
	};

	return payload;
};
