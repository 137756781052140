import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarFotosRgState } from './types';

export const INITIAL_STATE: ConsultarFotosRgState = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ConsultarFotosRgState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_FOTOS_RG_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.CONSULTAR_FOTOS_RG_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}
			case Types.CONSULTAR_FOTOS_RG_FAILURE: {
				draft.status = 400;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CONSULTAR_FOTOS_RG_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
