import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTA_AREAS_REQUEST = '@procon/CONSULTA_AREAS_REQUEST',
	CONSULTA_AREAS_SUCCESS = '@procon/CONSULTA_AREAS_SUCCESS',
	CONSULTA_AREAS_FAILURE = '@procon/CONSULTA_AREAS_FAILURE',
	CONSULTA_AREAS_CLEAR = '@procon/CONSULTA_AREAS_CLEAR',
}

export interface ConsultaAreas {
	status: null | number;
	data: OptionProps[];
}
