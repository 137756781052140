export enum Types {
	LISTAR_SOLICITACAO_PORTAL_REQUEST = '@MP/LISTAR_SOLICITACAO_PORTAL_REQUEST',
	LISTAR_SOLICITACAO_PORTAL_SUCCESS = '@MP/LISTAR_SOLICITACAO_PORTAL_SUCCESS',
	LISTAR_SOLICITACAO_PORTAL_FAILURE = '@MP/LISTAR_SOLICITACAO_PORTAL_FAILURE',
	LIMPAR_LISTA_PORTAL = '@MP/LIMPAR_LISTA_PORTAL',
}

export interface listarSolicitacaoPortal {
	status: number;
	data: DataConsultaAtestado[];
}

export interface DataConsultaAtestado {
	idSolicitacao: number;
	tipoSolicitante?: number;
	filho: {
		nome: string;
		dtNascimento: number;
		cpf: string;
		email: string;
		sexo: string;
		endereco: {
			logradouro: string;
			numero: string;
			bairro: string;
			cep: string;
			cidade: string;
			uf: string;
		};
		rg: {
			numero: string;
			digito: string;
			uf: string;
		};
		telefones: [
			{
				ddd: string;
				numero: string;
				tipo: number;
			},
		];
		genitor: {
			nome: string;
			sexo: string;
			dtNascimento: number;
			rg: {
				numero: string;
				digito: string;
				uf: string;
			};
			endereco: {
				logradouro: string;
				numero: string;
				bairro: string;
				cep: string;
				cidade: string;
				uf: string;
			};
			telefones: [
				{
					ddd: string;
					numero: string;
					tipo: number;
				},
			];
			cidadeNasc: string;
			ufNasc: string;
			nomeMae: string;
			cpf: string;
			email: string;
		};
	};
	responsavel: {
		nome: string;
		cpf: string;
		email: string;
		dtNascimento: number;
		sexo: string;
		rg: {
			numero: string;
			digito: string;
			uf: string;
		};
		endereco: {
			logradouro: string;
			numero: string;
			bairro: string;
			cep: string;
			cidade: string;
			uf: string;
		};
		telefones: [
			{
				ddd: string;
				numero: string;
				tipo: number;
			},
			{
				ddd: string;
				numero: string;
				tipo: number;
			},
			{
				ddd: string;
				numero: string;
				tipo: number;
			},
		];
	};
	dadosGolden?: DadosGolden;
	destinatarioSolicitacao: string;
	aceiteTermoDeclaracaoPobreza: boolean;
	aceiteTermo: boolean;
	aceiteAcrescNomePai: boolean;
	idAtendente: string;
	dataCriacao: number;
	dataModificacao: number;
	status: string;
	origem: string;
	idCidadao: string;
	idAtendimento: string;
	emailContato: string;
	idPosto?: number;
	mensagem?: string;
	nomePosto?: string;
	protocolo?: string;
	statusCode?: number | string | null;
}

interface Telefone {
	ddd: string;
	numero: string;
}

export interface DadosGolden {
	cpf: string;
	dataNascimento: string;
	digitoRg: string;
	email: string;
	id: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	rg: string;
	sexo: string;
	ufRg: string;
	endereco: {
		logradouro: string;
		numero: string;
		bairro: string;
		cep: string;
		cidade: string;
		uf: string;
	};
	telefones: Telefone[];
}
