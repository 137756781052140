import { ReducerActionRotaSP } from 'store/modules/types';
import { RemoverResponsavelRequest, Types } from './types';

export function removerResponsavelRequest(
	token: string,
	payload: RemoverResponsavelRequest,
): ReducerActionRotaSP {
	return {
		type: Types.REMOVER_RESPONSAVEL_REQUEST,
		token,
		payload,
	};
}

export function removerResponsavelSuccess(payload: object) {
	return {
		type: Types.REMOVER_RESPONSAVEL_SUCCESS,
		payload,
	};
}

export function removerResponsavelFailure(payload: boolean) {
	return {
		type: Types.REMOVER_RESPONSAVEL_FAILURE,
		payload,
	};
}

export function removerResponsavelClear() {
	return {
		type: Types.REMOVER_RESPONSAVEL_CLEAR,
		payload: null,
	};
}
