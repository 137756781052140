import produce from 'immer';
// import { formatDate, toSelect } from 'utils/genericFunctions';

import { Types, gerarPreCadastro } from './types';
import { ReducerAction } from '../../../../types';

// UTILS

export const INITIAL_STATE: gerarPreCadastro = {
	status: 0,
	data: null,
	dataRequest: null,
};

export default function gerarPreCadastroReducer(
	state = INITIAL_STATE,
	action: ReducerAction,
): gerarPreCadastro {
	return produce(state, draft => {
		switch (action.type) {
			case Types.GERAR_PRE_CADASTRO: {
				break;
			}
			case Types.GERAR_PRE_CADASTRO_SUCCESS: {
				draft.data = action.payload.response.data;
				draft.dataRequest = action.payload.payload;

				break;
			}
			case Types.GERAR_PRE_CADASTRO_FAILURE: {
				break;
			}

			default:
		}
	});
}
