import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function consultarTipoContatoRequest() {
	return {
		type: Types.CONSULTAR_TIPO_CONTRATO_REQUEST,
	};
}
export function consultarTipoContatoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_TIPO_CONTRATO_SUCCESS,
		payload,
	};
}
export function consultarTipoContatoFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_TIPO_CONTRATO_FAILURE,
		payload: null,
	};
}

export function limparConsultarTipoContato(): any {
	return {
		type: Types.LIMPAR_CONSULTAR_TIPO_CONTRATO,
	};
}
