import { ApiResponse } from 'services/_api';
import { ReducerAction } from 'store/modules/types';

import { ConsultaMunicipioRequest, Types } from './types';

export function consultaMunicipioRequest(
	data: ConsultaMunicipioRequest,
): ReducerAction {
	return {
		type: Types.CONSULTA_MUNICIPIO_REQUEST,
		payload: data,
	};
}
export function consultaMunicipioSuccess(payload: ApiResponse): ReducerAction {
	return {
		type: Types.CONSULTA_MUNICIPIO_SUCCESS,
		payload,
	};
}
export function consultaMunicipioFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_MUNICIPIO_FAILURE,
		payload,
	};
}
export function consultaMunicipioClear(): { type: string } {
	return {
		type: Types.CONSULTA_MUNICIPIO_CLEAR,
	};
}
