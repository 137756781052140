import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AtualizarMenusRequest } from './types';

// ACTIONS
import { atualizarMenusSuccess, atualizarMenusFailure } from './action';

function* atualizarMenus(request: ReducerAction) {
	const { payload }: { payload: AtualizarMenusRequest } = request;

	const response: ApiResponse = yield call(
		putApi,
		PATH_SGU,
		`menu/${payload.idMenu}`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(atualizarMenusSuccess(response));
	} else if (response.error) {
		yield put(atualizarMenusFailure());
	}
}

export default all([takeLatest(Types.ATUALIZAR_MENUS, atualizarMenus)]);
