import { combineReducers } from 'redux';

import consultaNacionalidades from './nacionalidades/reducer';
import consultaPaises from './paises/reducer';
import consultaEntidadeMilitar from './entidadeMilitar/reducer';
import consultaCategoria from './categoria/reducer';
import consultaCEP from './cep/reducer';

export default combineReducers({
	consultaNacionalidades,
	consultaPaises,
	consultaEntidadeMilitar,
	consultaCategoria,
	consultaCEP,
});
