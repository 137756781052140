export enum Types {
	BAIXAR_ARQUIVOS_REQUEST = '@utils/BAIXAR_ARQUIVOS_REQUEST',
	BAIXAR_ARQUIVOS_SUCCESS = '@utils/BAIXAR_ARQUIVOS_SUCCESS',
	BAIXAR_ARQUIVOS_FAILURE = '@utils/BAIXAR_ARQUIVOS_FAILURE',
	BAIXAR_ARQUIVOS_CLEAR = '@utils/BAIXAR_ARQUIVOS_CLEAR',
}

export interface BaixarArquivos {
	status: number;
	data: BaixarArquivosData;
}

interface BaixarArquivosData {
	arquivo: Blob | null;
}

export interface BaixarArquivosRequest {
	url: string;
}
