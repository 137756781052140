import { ReducerAction } from 'store/modules/types';

import { Types, ConsultarPerfisRequest } from './types';

export function consultarPerfilsRequest(
	data: ConsultarPerfisRequest,
): ReducerAction {
	return {
		type: Types.CONSULTAR_PERFIS_REQUEST,
		payload: data,
	};
}
export function consultarPerfilsSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_PERFIS_SUCCESS,
		payload,
	};
}
export function consultarPerfilsFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_PERFIS_FAILURE,
		payload: null,
	};
}
export function consultarPerfilsClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_PERFIS_CLEAR,
		payload: null,
	};
}
