export enum Types {
	ENVIAR_CENTRAL_NOMINAL_REQUEST = '@aac/ENVIAR_CENTRAL_NOMINAL_REQUEST',
	ENVIAR_CENTRAL_NOMINAL_SUCCESS = '@aac/ENVIAR_CENTRAL_NOMINAL_SUCCESS',
	ENVIAR_CENTRAL_NOMINAL_FAILURE = '@aac/ENVIAR_CENTRAL_NOMINAL_FAILURE',
	ENVIAR_CENTRAL_NOMINAL_CLEAN = '@aac/ENVIAR_CENTRAL_NOMINAL_CLEAN',
}

export interface enviarCentralNominal {
	status: number;
	data: DataEnviarCentralNominal;
}
export interface DataEnviarCentralNominal {
	atestadoEmitido?: string;
	codigoRetorno?: string;
	mensagemRetorno?: string;
	codigoValidacao?: string;
	id?: string;
	codigo?: number;
	mensagem?: string;
}

export interface EnviarCentralNominalRequest {
	codigoRetorno: string;
	numeroRg?: string;
	numeroDocumento?: string;
	nome: string;
	nomeSocial: string;
	nomePai: string;
	nomeMae: string;
	sexo: string;
	dataNascimento: string | null;
	posto: string;
	ipEstacao: string;
	usuario: string;
	dataExpedicaoRg: string;
	tipoDocApresentado: string;
	nomeProcurador: string;
	status: string;
	docProcurador: string;
	tipoEncaminhamento: string;
	origem: string;
}
