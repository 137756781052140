import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/_api';
import { PATH_SGU } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AtualizarMotivoRequest } from './types';

// ACTIONS
import { atualizarMotivoSuccess, atualizarMotivoFailure } from './actions';

function* atualizarMotivo(request: ReducerAction) {
	const { payload }: { payload: AtualizarMotivoRequest } = request;

	const body = { ...payload };

	delete body.id;

	const response: ApiResponse = yield call(
		putApi,
		PATH_SGU,
		`motivos/${payload.id}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarMotivoSuccess(response));
	} else {
		yield put(atualizarMotivoFailure());
	}
}

export default all([takeLatest(Types.ATUALIZAR_MOTIVO, atualizarMotivo)]);
