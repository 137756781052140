import { ReducerActionRotaSP } from 'store/modules/types';

import { BaixarArquivosRequest, Types } from './types';

export function baixarArquivosRequest(
	token: string,
	data: BaixarArquivosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.BAIXAR_ARQUIVOS_REQUEST,
		token,
		payload: data,
	};
}
export function baixarArquivosSuccess(payload: any): ReducerActionRotaSP {
	return {
		type: Types.BAIXAR_ARQUIVOS_SUCCESS,
		payload,
	};
}
export function baixarArquivosFailure(payload: any): ReducerActionRotaSP {
	return {
		type: Types.BAIXAR_ARQUIVOS_FAILURE,
		payload,
	};
}

export function baixarArquivosClear(): { type: string } {
	return {
		type: Types.BAIXAR_ARQUIVOS_CLEAR,
	};
}
