import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultarEnderecoNovo } from './types';

export function consultarEnderecoRequest(
	payload: IRequestConsultarEnderecoNovo,
) {
	return {
		type: Types.CONSULTAR_ENDERECO,
		payload,
	};
}
export function consultarEnderecoSuccess(payload: object): ReducerAction {
	return {
		type: Types.CONSULTAR_ENDERECO_SUCCESS,
		payload,
	};
}
export function consultarEnderecoFailure(): ReducerAction {
	return {
		type: Types.CONSULTAR_ENDERECO_FAILURE,
		payload: null,
	};
}

export function limparConsultarEndereco(): any {
	return {
		type: Types.LIMPAR_CONSULTAR_ENDERECO,
	};
}
