import React from 'react';

// STYLED
import { Label } from './styled';

interface Props {
	bgColor: string;
	color: string;
	text: string;
}

const Badge: React.FC<Props> = ({ bgColor, color, text }) => {
	return (
		<Label bgColor={bgColor} color={color}>
			{text}
		</Label>
	);
};

export default Badge;
