import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_RELATORIOS } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	IRequestReuso,
	protocoloReusoSuccess,
	protocoloReusoFailure,
} from './actions';

function* protocoloReusoRequest(request: ReducerAction) {
	const { payload }: { payload: IRequestReuso } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_RELATORIOS,
		'/protocolo/:canal/reuso',
		payload,
	);

	if (response.status === 200) {
		yield put(protocoloReusoSuccess(response.data));
	} else if (response.error) {
		yield put(protocoloReusoFailure());
	}
}

export default all([
	takeLatest(Types.PROTOCOLO_REUSO_REQUEST, protocoloReusoRequest),
]);
